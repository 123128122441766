import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Container, FormControl, InputGroup } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { Domain, DomainQuery, DomainQueryVariables, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'
import ChangeDomainImage from './ChangeDomainImage'
import { Mutations } from '../../queries/mutation'
import ChangeDomainOrientation from './ChangeDomainOrientation'
import ChangeObjectVisibility from './ChangeObjectVisibility'
import ChangeReservations from './ChangeReservations'
// import DomainIntegrationsTable from '../domainIntegration/DomainIntegrationsTable'
import DomainComponents from './DomainComponents'
// import ServiceAccountList from '../user/ServiceAccountList'
// import DomainAccountsTable from './DomainAccountsTable'
import SafetyRespectControl from './SafetyRespectControl'
import ParentDomainControl from './ParentDomainControl'
import DomainInvitationLink from './DomainInvitationLink'

const DomainCard = () => {
    const { domainId } = useParams<{ domainId: string }>()
    const { data, loading, error } = useQuery<DomainQuery, DomainQueryVariables>(Queries.domain, {
        variables: { filter: { GlobalId: domainId } },
        context: { xDomainId: domainId },
    })

    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (loading)
        return <Loading />
    if (error)
        return <Error error={error} />

    const { domain } = data

    const divStyle = {
        color: 'black'
    };

    return (
        <Container>
            <ChangeDomainImage domain={domain} />

            <h2>{domain.Name}</h2>
            <h4 style={ divStyle }>Id: {domain.GlobalId}</h4>
            <h4>&nbsp;</h4>

            <ChangeDomainOrientation domain={domain} />
            <ChangeObjectVisibility domain={domain} />
            <ChangeReservations domain={domain} />

            <DomainInvitationLink domain={domain} />
            <EmailDomain domain={domain} />

            {/* <DomainIntegrationsTable domain={domain} /> */}
            <DomainComponents domain={domain} />

            {/* <ServiceAccountList domain={domain} /> */}
            {/* <DomainAccountsTable domainId={domain.GlobalId} /> */}

            <SafetyRespectControl domain={domain} />
            <ParentDomainControl domain={domain} />
        </Container>
    )
}

const EmailDomain = ({ domain }: { domain: Domain }) => {
    const [emailDomain, setEailDomain] = useState(domain.EmailDomain || '')
    const [touched, setTouched] = useState(false)
    const [saved, setSaved] = useState(false)

    useEffect(() => {
        if (emailDomain !== domain.EmailDomain)
            setTouched(true)
        else
            setTouched(false)

        return () => { setTouched(false) }
    }, [emailDomain])

    useEffect(() => {
        if (saved)
            setTimeout(() => {
                setSaved(false)
            }, 2000);
    }, [saved])

    const [mutation, { loading, error }] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, {
        context: { xDomainId: domain.GlobalId },
    })

    const save = async () => {
        await mutation({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    EmailDomain: emailDomain
                }
            }
        })
        setTouched(false)
        setSaved(true)
    }
    return (
        <Alert variant="info">
            <Alert.Heading>E-postdomän</Alert.Heading>
            <p>
                Detta kan användas för att automatiskt skapa upp användare som loggar in med office 365
            </p>

            <p>
                När en ny användare loggar in via office365, med en e-post som matchar den nedan angivna kommer användaren automatiskt skapas upp med rollen <i>Domänanvändare</i>
            </p>

            <p>
                Användaren kommer att kunna använda appen direkt efter inloggning
            </p>

            {error && <Error error={error} type="mutation" />}

            {saved && <Alert variant={'success'}>
                Ändringarna sparade
            </Alert>}

            <hr />

            <div className="d-flex justify-content-end">

                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Ange i formatet: @domain.se"
                        aria-label="@domain.se"
                        aria-describedby="basic-addon2"
                        value={emailDomain}
                        onChange={(e) => setEailDomain(e.target.value)}
                    />
                    <InputGroup.Append>

                        <Button disabled={!touched && !loading} onClick={save} variant="outline-secondary">
                            {loading ? <Loading button /> : 'Spara'}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>


            </div>
        </Alert>
    )
}

export default DomainCard