import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { CustomerProject } from "../../types"
import { useMe } from "../hooks/permissionHook"
import * as en from 'linq'
import './index.scss'
import { QuickRentInfoOverview } from './QuickRentInfo'

const MyProjects = ({domainId}: {domainId: string}) => {

    const { me, loading, error } = useMe(domainId)

    const [projects, setProjects] = useState<CustomerProject[]>([])

    useEffect(() => {
        if (!me) {
            setProjects([])
            return
        }
        let projects = en.from(me.favoriteProjects).where((x: any) => !x.Deactivated && x.ProjectId).distinct(x => x.ProjectNo).toArray()
        setProjects(projects)
    }, [me])
    if (!me) {
        return null
    }

    return (
        <Container>
            {loading && <span>loading</span>}
            {error && <span>error</span>}
            <div className="shadow rounded-container" style={{ padding: 0 }}>
                <div className="padding">
                    <h1>Mina projekt</h1>
                </div>
                <div className={'projects'}>
                    {projects.map((x, i) => <QuickRentInfoOverview domainId={x.DomainId} last={i === projects.length - 1} customerProject={x} key={x.GlobalId} />)}
                </div>
            </div>
        </Container>

    )
}
export default MyProjects