import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button, CardGroup } from 'react-bootstrap'
import { Redirect } from 'react-router'

import { useMyPanels } from './Panels'
import './index.scss'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import Link from '../generic/Link'
import { useMe } from '../hooks/permissionHook'
import { User } from "../../types"
import { roledefinitions } from '../../config'


const ControlPanel = (props) => {
    const { me } = useMe()
    const { panels, loading, error } = useMyPanels()

    if (loading)
        return (<div><Loading /></div>)

    if (error) 
        return (<div><Error error={error} />{showDevDiv(me)}</div>)
    
    if ([roledefinitions.customeradmin.toString(), roledefinitions.customeruser.toString()].includes(me.role)) {
        // console.log(`user is customer with role ${me.role} on ${me.DomainIds ? me.DomainIds[0] : ''}`)
        return <Redirect to={`/guest/${me.DomainIds ? me.DomainIds[0] : ''}`} />
    }

    const groups = chunkify(panels, 3)

    return (
        <div>
            {error && <Error error={error} />}
            {loading && <Loading />}

            {groups.map((group, i) => (
                <CardGroup key={i}>
                    {group.map((panel, i) => (
                        <Card className="shadow" key={i} text="primary" style={{ width: '18rem' }}>
                            {panel.image}
                            <Card.Body>
                                <Card.Title>{panel.title}</Card.Title>
                                <Card.Text>
                                    {panel.text}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Link type="inline" to={panel.url}><Button variant="primary">Gå dit</Button></Link>
                            </Card.Footer>
                        </Card>
                    ))}
                </CardGroup>
            ))}

            {showDevDiv(me)}
        </div>
    )
}

const chunkify = (arr: any[], numberInEach: Number): any[][] => {
    let out = [[]], i = 0;

    arr.forEach(item => {
        if (!out[i]) out[i] = []
        let current = out[i]
        current.push(item)

        if (current.length >= numberInEach)
            i++
    })

    return out
}

const showDevDiv = (me: User) => {
    return (me && me.role === roledefinitions.globaladmin) && (
        <div className={'rounded-container shadow'}>
            <h2>Inställningar för utveckling</h2>
            <DevSettings />
        </div>
    )
}

export const DevSettings = () => {
    const [graphqlendpoint, setgraphqlendpoint] = useState(localStorage.getItem('graphqlendpoint') || 'https://edge-api-staging.azurewebsites.net/')
    const [wsendpoint, setwsendpoint] = useState(localStorage.getItem('wsendpoint') || 'wss://edge-api-staging.azurewebsites.net/graphql')

    if (window.location.hostname !== 'localhost') return null

    const save = () => {
        localStorage.setItem('graphqlendpoint', graphqlendpoint)
        localStorage.setItem('wsendpoint', wsendpoint)

        window.location.reload();
    }

    const reset = () => {
        localStorage.removeItem('graphqlendpoint')
        localStorage.removeItem('wsendpoint')

        window.location.reload();
    }

    const localhost = () => {
        localStorage.setItem('graphqlendpoint', 'http://localhost:8080/')
        localStorage.setItem('wsendpoint', 'ws://localhost:8080/')

        window.location.reload();
    }

    return (
        <Container>
            <h3>Här kan du ställa in inställningar för din utvecklingsmiljö</h3>

            <Button variant={'dark'} onClick={localhost}>Använd localhost</Button>
            <Button variant={'dark'} onClick={reset}>Använd rentalainen</Button>

            <div>
                <b>graphqlendpoint</b><br />
                <input type="text" style={{ width: '400px' }} value={graphqlendpoint} onChange={(e) => setgraphqlendpoint(e.target.value)} />
            </div>
            <p />
            <div>
                <b>wsendpoint</b><br />
                <input type="text" style={{ width: '400px' }} value={wsendpoint} onChange={(e) => setwsendpoint(e.target.value)} />
            </div>


            <Button onClick={save}>Spara</Button>

        </Container>
    )
}

export default ControlPanel