import { useQuery, useMutation } from '@apollo/client'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import { Queries } from '../../queries/query'
import { Mutations } from '../../queries/mutation'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { UserForm } from './userForm'
import { User, RemoveUserMutation, RemoveUserMutationVariables, ImpersonateUserMutation, ImpersonateUserMutationVariables,  } from '../../types'
import { ReadableRoles } from '../../config'

const UserCard = () => {
    const { userId, domainId } = useParams<{ userId: string, domainId: string }>()
    const { data, loading, error } = useQuery(Queries.user, { 
        skip: !userId, 
        variables: { filter: { DomainId: domainId, GlobalId: userId } },
        context: { xDomainId: domainId },
    })
    
    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (loading) return <Loading />

    if (error) return <Error error={error} />

    if (!domainId) return <Error error="domainId saknas i anropet" type="mutation" />

    if (!loading && (!data || !data.user)) return <Error error="Posten kunde inte hittas" type="notFound" />
    
    const { user } = data
    return (
        <Container>
            <h1>{user.name}</h1>

            <div className="shadow rounded-container buttons">
                <Impersonate user={user} domainId={domainId} />
                <DeleteUser user={user} domainId={domainId} />
            </div>

        <UserForm user={data ? data.user : null} domainId={domainId} />

        </Container>
    )
}

const DeleteUser = ({ domainId, user }: { domainId: string, user: User }) => {
    const [mutation, { error, loading }] = useMutation<RemoveUserMutation, RemoveUserMutationVariables>(Mutations.removeUser, { 
        refetchQueries: ['usersMany'],
        variables: { filter: { DomainId: domainId } },
        awaitRefetchQueries: true,
        context: { xDomainId: domainId },
    })
    
    const history = useHistory()

    const remove = async () => {
        if (!user.GlobalId) {
            alert("inget id hittades")
            return
        }

        if (user.role === 'serviceaccount') {
            alert(`Går inte att radera konto med roll ${ReadableRoles.serviceaccount}`)
            return
        }

        try {
            await mutation({
                variables: { filter: { GlobalId: user.GlobalId, DomainId: domainId } }
            })
            history.goBack()
        } catch (e) { }

    }

    return (
        <button onClick={remove} className="rounded-button red">Ta bort {user.name}</button>
    )
}

const Impersonate = ({ domainId, user }: { domainId: string, user: User }) => {
    const [mutation, { error, loading }] = useMutation<ImpersonateUserMutation, ImpersonateUserMutationVariables>(Mutations.impersonateUser, { 
        refetchQueries: ['me'], 
        awaitRefetchQueries: true,
        context: { xDomainId: user.DomainId },
    })

    const save = () => {
        if (!user.GlobalId) {
            alert("inget id hittades")
            return
        }

        if (user.role === 'serviceaccount') {
            alert(`Går inte att radera konto med roll ${ReadableRoles.serviceaccount}`)
            return
        }

        mutation({ variables: { Email: user.email } })
    }

    return (
        <button onClick={save} className="rounded-button blue">Impersonate</button>
    )
}

export default UserCard