import { useMutation } from '@apollo/client'
import { v4 } from 'uuid'
import { Mutations } from '../../queries/mutation'
import { Queries } from '../../queries/query'
import { User, FilterCompanyInput, UpsertUserMutation, UpsertUserMutationVariables } from '../../types'
import Form from '../Form'
import Loading from '../generic/Loading'
import Error from '../generic/Error'
import { ReadableRoles, roledefinitions } from '../../config'

export const UserForm = ({ domainId, user }: { domainId: string, user?: User }) => {
    const [mutation, { loading, error }] = useMutation<UpsertUserMutation, UpsertUserMutationVariables>(Mutations.upsertUser, { 
        refetchQueries: ['usersPage'],
        context: { xDomainId: domainId },
    })
    if (!domainId) return <Error error="domainId saknas i anropet" type="mutation" />
    if (loading) return <Loading />
    if (error) return <Error error={error} />

    // const save = async (values: User) => {
    //     console.log('values', values)

    //     try {
    //         await mutation({
    //             variables: {
    //                 record: {
    //                     GlobalId: user ? user.GlobalId : '',
    //                     DomainId: domainId,
    //                     email: values.email,
    //                     name: values.name,
    //                     role: values.role,
    //                 }
    //             }
    //         })
    //     } catch (e) { }
    // }

    return (
        <div className="rounded-container shadow">
            <Form
                submitDisabled = { !!user ? user.role === 'serviceaccount' : false }
                onSubmit={async (record: User) => {
                    // console.log("user:", user, "record:", record)

                    if (['globaladmin', 'serviceaccount'].includes(record.role)) {                       
                        alert(`Det går inte att ändra konto till roll ${record.role}`)
                        record.role = user.role
                        return
                    }

                    await mutation({
                        variables: {
                            record: {
                                ...record,
                                GlobalId: !user || !user.GlobalId ? v4() : user.GlobalId,
                                DomainId: domainId,
                            }
                        },
                        context: { xDomainId: domainId },
                    }).catch(e => { })
                }}
                values={user}
                fields={[                    
                    { name: 'DomainId', label: "Domän", defaultValue: domainId, readonly: true },
                    // { name: 'GlobalId', label: "GlobalId", defaultValue: v4(), readonly: true },
                    { name: 'name', label: "Namn", required: true, readonly:  !!user ? user.role === 'serviceaccount' : false  },
                    { name: 'email', label: "E-Post", required: true, readonly:  !!user ? user.role === 'serviceaccount' : false },
                    { name: 'active', label: 'Aktiv', type: 'boolean', required: false, readonly: false},
                    {
                        name: 'role', label: "Roll", required: true, values: Object.keys(ReadableRoles).map(x => ({
                            label: ReadableRoles[x],
                            value: x
                        })),
                        type: 'staticPicker',
                        readonly:  !!user ? ['globaladmin', 'serviceaccount'].includes(user.role) : false
                    },
                    {
                        name: 'CustomerId',
                        type: 'dynamicPicker',
                        query: Queries.companiesMany,
                        labelprop: 'Name',
                        valueprop: 'RegistrationNo',
                        filter: () => ({
                            DomainId: domainId,
                            NOT: { RegistrationNo: "" }
                        } as FilterCompanyInput),
                        label: "Kund",
                        required: (state) => {
                            if (state.role === roledefinitions.customeradmin || state.role === roledefinitions.customeruser) return true
                            return false
                        },
                        hidden: (state) => {
                            if (state.role === roledefinitions.customeradmin || state.role === roledefinitions.customeruser) return false
                            return true
                        }
                    },
                ]}
            />
        </div>
    )
}