import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { CustomerDomain, CustomerDomainQuery, CustomerDomainQueryVariables, DomainsManyQuery, DomainsManyQueryVariables, MutationToggleSupplierArgs, ToggleSuplierResponse, } from '../../types'
import { useMe } from '../hooks/permissionHook'
import { Mutations } from '../../queries/mutation'
import { roledefinitions } from '../../config'
import moment from 'moment'
import Link from '../generic/Link'
import { colors } from '../generic/colors'
import PageWrapper from '../generic/PageWrapper'
import SearchableTable from '../table/SearchableTable'
import StaticTable from '../table/StaticTable'
import DetailsComponent from '../generic/DetailsComponent'
import DomainPicker from './DomainPicker'

const CustomerDomainCard = () => {
    const { customerDomainId } = useParams<{ customerDomainId: string }>()
    const { data, loading, error } = useQuery<CustomerDomainQuery, CustomerDomainQueryVariables>(Queries.customerDomain, { 
        variables: { filter: { GlobalId: customerDomainId } },
        context: { xDomainId: customerDomainId },
    })
    const { me } = useMe()

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    const { customerDomain } = data

    if (!customerDomain) return <Error error={'404 not found' + customerDomainId} />

    return (

        <PageWrapper>

            <DetailsComponent Headline={customerDomain.Name} sections={[
                {
                    data: [
                        { title: 'Organisationsnummer', value: customerDomain.RegistrationNo },
                    ]
                },
                {
                    title: 'Fakturauppgifter',
                    data: [
                        { title: 'Adress', value: customerDomain.InvoiceAddress },
                        { title: 'Postnummer', value: customerDomain.InvoicePostalCode },
                        { title: 'Ort', value: customerDomain.InvoiceCity },
                        { title: 'Fakturamärkning', value: customerDomain.InvoiceNote },
                        { title: 'E-Post', value: customerDomain.InvoiceEmail },
                    ]
                }
            ]} />

            <SupplierList customerDomain={customerDomain} />

            <StaticTable
                title={`Användare i ${customerDomain.Name}`}
                data={customerDomain.users}
                columns={[
                    {
                        Header: "Namn",
                        id: '_NAME_',
                        accessor: (row: any) => <Link to={`/users/${row._id}`} type="inline">{row.name || 'Namn saknas'}</Link>
                    },
                    {
                        Header: "E-Post",
                        id: '_EMAIL_',
                        accessor: "email"
                    },
                    {
                        Header: "Roll",
                        id: '_ROLE_',
                        accessor: "role"
                    },
                ]}
            />

        </PageWrapper>
    )
}


const SupplierList = ({ customerDomain }: { customerDomain: CustomerDomain }) => {
    const { data, loading, error, refetch } = useQuery<DomainsManyQuery, DomainsManyQueryVariables>(Queries.domainsMany, { skip: (!customerDomain || !customerDomain.DomainIds || customerDomain.DomainIds.length < 1), variables: { filter: { IN: customerDomain.DomainIds.map(x => ({ GlobalId: x })) } } })
    if (loading) return <Loading />

    let domains = data && data.domainsMany ? data.domainsMany : []

    return (
        <div className="shadow rounded-container">
            <h3>Leverantörer</h3>

            <AddSuppliersForm CustomerId={customerDomain.RegistrationNo} refetch={refetch} />

            <div className="suppliers">
                {domains.map((d, i) => <Link type={'inline'} key={i} to={`/suppliers/${d.GlobalId}`}><img className="domain-image small" src={d.ImageURL} alt={d.Name} /></Link>)}
            </div>
        </div>
    )
}

const AddSuppliersForm = ({ CustomerId, refetch }: { CustomerId: string, refetch: () => void }) => {
    const { me } = useMe()
    const [open, setOpen] = useState<boolean>(false)
    const [selectedSupplier, setSelectedSupplier] = useState<any>(null)
    const [mutation, { loading, error }] = useMutation<{ toggleSupplier: ToggleSuplierResponse }, MutationToggleSupplierArgs>(Mutations.toggleSupplier, { 
        refetchQueries: ['customerDomain', 'domains']
    })
    const [errormessage, setErrormessage] = useState<string>(null)

    const add = async () => {

        let rec = await mutation({
            variables: {
                record: {
                    CustomerId,
                    DomainId: selectedSupplier.GlobalId
                }
            },
            context: { xDomainId: selectedSupplier.GlobalId },
        })

        console.log('rec', rec)

        if (rec.data.toggleSupplier.Status === 'Ok')
            setOpen(false)

        if (rec.data.toggleSupplier.Error)
            setErrormessage(rec.data.toggleSupplier.Error)

        refetch()
    }

    if (!me || me.role !== roledefinitions.globaladmin) return null

    return (
        <div>
            <Button onClick={() => setOpen(true)} variant="success">Lägg till leverantör</Button>

            <Modal animation={false} show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: colors.primary.main.color }}>Lägg till leverantör</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errormessage && <p style={{
                        padding: '10px',
                        backgroundColor: colors.errorColor,
                        color: 'white'
                    }}>{errormessage}</p>}
                    <DomainPicker value={selectedSupplier} onValueChanged={v => setSelectedSupplier(v)} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => setOpen(false)}>
                        Stäng
                     </Button>

                    <Button disabled={!selectedSupplier} variant="success" onClick={add}>
                        Lägg till
                     </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CustomerDomainCard