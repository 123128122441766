
import { roledefinitions } from '../../config'
import * as en from 'linq'
// import { useState, useEffect } from 'react'
import { useMe } from '../hooks/permissionHook'
import imgConstructions from '../../images/constructions.png'
import imgObjects from '../../images/objects.jpg'
import imgCustomerDomains from '../../images/customerDomains.jpg' 
import imgGetSupport from '../../images/get-support.jpg'
// import { useState, useEffect } from 'react'
import { Domain } from '../../types'
import imgComingSoon from '../../images/comingSoon.jpg'
// import imgDashboard from '../../images/dashboard-alt-icon.png'
// import imgInvitations from '../../images/invitations.jpg'
// import imgSysInt from '../../images/systemIntegrations.jpg'
// import imgUsers from '../../images/users.png'

import { Card } from 'react-bootstrap'

export type guestPanelsPayload = {
    domainId: string
    loading?: boolean
    error?: any
    panels: panel[]
}

export const useGuestPanels = (domainId: string): guestPanelsPayload => {
    const { me, loading, error } = useMe(domainId)
    let panels = []

    if (me && me.role)
        panels = en.from(allPanels(domainId))
            .where(x => x.enabled)
            .where(x => en.from(x.roles).any(role => role === me.role)).toArray()

    return {
        domainId: domainId,
        loading,
        panels,
        error
    }
}

export type panel = {
    title: string,
    image: JSX.Element,
    text: string,
    url: string,
    roles: roledefinitions[],
    enabled: boolean,
    orientation: string[],
    useAbsoluteLink: boolean,
}

const supportPanel: panel = {
    title: "Support",
    roles: [roledefinitions.globaladmin],
    image: <Card.Img variant="top" src={imgGetSupport} />, 
    text: "Få hjälp på det du behöver", 
    url: "/support",
    enabled: false,
    orientation: [null, 'sales', 'rental'],
    useAbsoluteLink: false
}

const comingSoonPanel: panel = {
    title: 'Coming Soon',
    roles: [roledefinitions.customeradmin, roledefinitions.customeruser, roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
    image: <Card.Img variant="top" src={imgComingSoon} />,
    text: 'coming soon',
    url: '/',
    enabled: true,
    orientation: [null, 'sales', 'rental'],
    useAbsoluteLink: false
}

const allPanels = (domainId: string): panel[] => { return [
    // {
    //     title: "Användare",
    //     roles: [roledefinitions.globaladmin, roledefinitions.domainadmin, roledefinitions.customeradmin],
    //     image: <Card.Img variant="top" src={imgUsers} />, text: "Lägg till, ta bort och administra användare", url: "/users"
    // },
    // {
    //     title: "Domäner",
    //     roles: [roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={imgGetSupport} />, text: "Hantera allt som har med domäner att göra", url: "/domains"
    // },
    // {
    //     title: "Inbjudningar",
    //     roles: [roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={ imgInvitation } />, text: "Hantera ibjudningar", url: "/manage-invitations"
    // },
    // {
    //     title: "Kunddomäner",
    //     roles: [roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={ imgCustomerDomains } />, text: "Hantera bland annat leverantörer. Gäller endast ej migrerade domäner.", url: "/customer-domains"
    // },
    {
        title: 'Maskinkatalog',
        roles: [roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
        image: <Card.Img variant="top" src={imgObjects} />,
        text: 'Hantera bilder på objekttyper',
        url: '/object-list',
        enabled: false,
        orientation: [null, 'rental'],
        useAbsoluteLink: false,
    },
    {
        title: 'Maskinhyra',
        roles: [roledefinitions.customeradmin, roledefinitions.customeruser, roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
        image: <Card.Img variant="top" src={imgConstructions} />,
        text: 'Överblick över uthyrningarna',
        url: `../mina-sidor/domains/${domainId}/on-rent`,
        enabled: true,
        orientation: [null, 'rental'],
        useAbsoluteLink: true,
    },
    // {
    //     title: 'Övervakningsdashboard',
    //     roles: [roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={ imgDashboard } />, text: 'Överblick över alla navkopplingar', url: '/heartbeat-overview'
    // },
    supportPanel,
    comingSoonPanel,
]}
