import { useSubscription } from '@apollo/client'
import React, { useCallback, useEffect, useState } from 'react'
import { Subscriptions } from '../../queries/subscription'
import { DomainIntegration, DomainIntegrationUpsertedSubscriptionVariables, DomainIntegrationUpsertedSubscription } from '../../types'
import { colors } from '../generic/colors'
import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineSeries,
    DiscreteColorLegend
} from 'react-vis'
import moment from 'moment'
import * as en from 'linq'
import './index.scss'

const Heartbeat = ({
    domainId,
    domainIntegration,
    hideLegend
}: {
    domainId: string,
    domainIntegration: DomainIntegration,
    hideLegend?: boolean
}) => {
    const { data, error } = useSubscription<DomainIntegrationUpsertedSubscription, DomainIntegrationUpsertedSubscriptionVariables>(Subscriptions.DomainIntegrationUpserted, { 
        variables: { filter: { DomainId: domainId, GlobalId: domainIntegration.GlobalId }
        },
    })
    const [time, setTime] = useState<Date>(new Date())
    const [maxcount, setMaxcount] = useState<number>()
    const [heartbeats, setHeartbeats] = useState<DomainIntegration[]>([domainIntegration])
    const [online, setOnline] = useState<boolean>(domainIntegration.Online)
    const [latest, setLatest] = useState<DomainIntegration>(domainIntegration)

    useEffect(() => {
        if (!data || !data.DomainIntegrationUpserted) return

        setOnline(data.DomainIntegrationUpserted.Online)
        addHeartbeat(data.DomainIntegrationUpserted)

        setLatest(data.DomainIntegrationUpserted)
    }, [data])

    useEffect(() => {
        const max = en.from(heartbeats).max(x => {
            return en.from([
                x.NoOfReqsInInboundQueue,
                x.NoOfReqsInOutboundQueue,
                x.NoOfUnprocessableItems
            ]).max()
        })
        setMaxcount(max > 50 ? max : 50)
    }, [heartbeats])

    useEffect(() => {
        if (error)
            console.log('error', error)
    }, [error])

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date())
        }, 3600)

        return () => clearInterval(timer)
    }, [])

    useEffect(() => {
        addHeartbeat()

    }, [time])

    const addHeartbeat = (heartbeat?: DomainIntegration) => {
        const prev = heartbeats[heartbeats.length - 1]

        let b = [...heartbeats, heartbeat || {
            ...prev,
            LatestHeartBeat: new Date(),
        }]

        if (b.length > 10)
            b.shift()

        setHeartbeats(b)
    }

    return (
        <div className="heartbeat" style={{ paddingBottom: hideLegend ? 0 : 130 }}>
            <h4>{domainIntegration.Name || 'Företagsnamn saknas'}</h4>
            <div className="online-badge">
                <span>
                    {online ? <span style={{ color: 'green' }}>0nline</span> : <span style={{ color: colors.errorColor }}>0ffline</span>}
                </span>
                <span>
                    {!online && <span>Senast online {moment(latest.LatestHeartBeat).fromNow()} </span>}
                </span>
            </div>
            <XYPlot width={600} height={300} yDomain={[0, maxcount]} animated>
                <HorizontalGridLines />
                <VerticalGridLines />
                <XAxis title="Sekunder" />
                <YAxis title="Antal" />
                <LineSeries
                    title='Records i outbound kö'
                    data={heartbeats.map(h => ({
                        x: h.LatestHeartBeat ? moment(h.LatestHeartBeat).diff(time, 'seconds') : 0,
                        y: h.NoOfReqsInOutboundQueue || 0
                    }))}
                />
                <LineSeries
                    title='Records i inbound kö'
                    data={heartbeats.map(h => ({
                        x: h.LatestHeartBeat ? moment(h.LatestHeartBeat).diff(time, 'seconds') : 0,
                        y: h.NoOfReqsInInboundQueue || 0
                    }))}
                />
                <LineSeries
                    title='Records i fail mode'
                    color={colors.errorColor}
                    data={heartbeats.map(h => ({
                        x: h.LatestHeartBeat ? moment(h.LatestHeartBeat).diff(time, 'seconds') : 0,
                        y: h.NoOfUnprocessableItems || 0,
                    }))}
                />
                {!hideLegend && <DiscreteColorLegend
                    items={[
                        { title: 'Records i outbound kö' },
                        { title: 'Records i inbound kö' },
                        { title: 'Records i fail mode', color: colors.errorColor },
                    ]}
                />}
            </XYPlot>

        </div>
    )
}


export default Heartbeat