import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { Mutations } from '../../queries/mutation'
import { Queries } from '../../queries/query'
import { Domain, DomainsManyQuery, DomainsManyQueryVariables, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'



const ParentDomainControl = ({ domain }: {domain: Domain}) => {

    const [parentid, setParentid] = useState<string>(domain.ParentDomainId || "")
    const { data, loading, error } = useQuery<DomainsManyQuery, DomainsManyQueryVariables>(Queries.domainsMany, { 
        skip: !domain.ParentDomainId, 
        variables: { filter: { ParentDomainId: parentid, NOT: { GlobalId: domain.GlobalId } } },
        context: { xDomainId: parentid },
    })
    const [mutation, mutationstate] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, {
        context: { xDomainId: parentid },
    })
    console.log('ParentDomainControl')

    const save = () => {
        mutation({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    ParentDomainId: parentid
                }
            }
        })
    }

    return (
        <Alert variant="info">
            <Alert.Heading>Syskondomäner</Alert.Heading>
            <p>Detta värde kan anges på andra domäner för att sätta ett släktskap</p>
            <p>Kan användas av domänanvändare för att byta domän fritt mellan alla syskon</p>

            <div>
                <Form.Label style={{ color: '#333' }}>{"ID"}</Form.Label>
                <br />
                <input
                    placeholder="saknas"
                    value={parentid}
                    onChange={e => setParentid(e.target.value)}
                />
                {mutationstate.error && <Error error={mutationstate.error} type={'mutation'} />}

                {(parentid !== domain.ParentDomainId) && (
                    <div>
                        {mutationstate.loading && <Loading />}
                        <button disabled={loading} onClick={save}>Spara ändring</button>
                    </div>
                )}
            </div>
            <div>
                <p><b>Syskon:</b></p>
                {loading && <Loading />}
                {error && <Error error={error} />}
                {(data && data.domainsMany && (
                    <div>
                        {data.domainsMany.map(domain => (
                            <div key={domain.GlobalId}>
                                {domain.Name}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </Alert>
    )
}


export default ParentDomainControl