import React from 'react'
import { InputGroup, Dropdown } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { Mutations } from '../../queries/mutation'
import { Domain, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'
import Error from '../generic/Error'

const ChangeReservations = ({ domain }: { domain: Domain }) => {
    const [save, { loading, error }] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, {
        context: { xDomainId: domain.GlobalId },
    })
    
    if (!domain) return <Error type='notFound' />


    const update = (EnableObjectsLimited) => {
        save({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    EnableObjectsLimited
                }
            }
        })
    }

    let label = domain.EnableObjectsLimited ? labels.true : labels.false || 'inget valt'

    return (
        <div>
            {error && <Error error={error} type='mutation' />}
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Objektlistning</InputGroup.Text>
                </InputGroup.Prepend>

                <Dropdown>
                    <Dropdown.Toggle variant={domain.EnableObjectsLimited ? 'success' : 'danger'} id="dropdown-basic">
                        {label}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => update(true)}>{labels.true}</Dropdown.Item>
                        <Dropdown.Item onClick={() => update(false)}>{labels.false}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>

        </div>
    )
}

const labels = {
    undefined: 'Inget aktivt val, sökning inte tillåtet',
    true: 'Anonym sökning är tillåten',
    false: 'Anonym sökning är inte tillåtet',
}


export default ChangeReservations