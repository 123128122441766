import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Container, Row, Col, Card, Button, CardGroup } from 'react-bootstrap'
import { useParams } from 'react-router'

import { DomainsLimitedQuery, DomainsLimitedQueryVariables } from '../../types'
import { Queries } from '../../queries/query'

import './index.scss'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import GuestComponents from './GuestComponents'

const GuestCard = (props) => {
    const { domainId } = useParams<{ domainId: string }>()
    const { data, loading, error } = useQuery<DomainsLimitedQuery, DomainsLimitedQueryVariables>(Queries.domainsLimited, {
        variables: { filter: { GlobalId: domainId } },
        context: { xDomainId: domainId },
    })

    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (loading)
        return <Loading />
    if (error)
        return <Error error={error} />

    const { domainsLimited } = data

    return (
        <Container>
            <GuestComponents domainId={domainId} />
        </Container>
    )

}

export default GuestCard