import React, { useState } from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import { useParams } from 'react-router'
import shortid from 'shortid'
import './index.scss'
import { colors } from '../generic/colors'
import Link from '../generic/Link'
import { Queries } from '../../queries/query'
import { DomainIntegration } from '../../types'
import Error from '../generic/Error'
import SearchableTable from '../table/SearchableTable'
import { DomainIntegrationForm } from './DomainIntegrationForm'

const DomainIntegrationList = () => {
	const { domainId } = useParams<{ domainId: string }>()
	const [modalVisible, setModalVisible] = useState(false)
	const handleClose = () => setModalVisible(false)
	
	if (!domainId) return <Error error='the domain Id cannot be null' />

	return (
		<Container>
			<h1>Anslutningar</h1>
			<h3>Domän-Id: {domainId}</h3>
			<SearchableTable
				domainId={domainId}
				disableSearch
				query={Queries.domainIntegrationsMany}
				filter={{
					DomainId: domainId
				}}
				cols={[
					{
						Header: 'Typ',
						Cell: ({ row: { original } }: { row: { original: DomainIntegration } }) => {
							return <Link type="inline" to={`/${original.GlobalId}`}>{original.Type}</Link>
						},
					},
					{
						Header: "Url",
						Cell: ({ row: { original } }: { row: { original: DomainIntegration } }) => {
							return <Link type="inline" to={`/${original.GlobalId}`} ellipsis={ true } >{original.Url}</Link>
						},
					},
					{
						Header: "Aktiv",
						accessor: (row: any) => row.Active ? <span style={{ color: 'green' }}>Ja</span> : <span style={{ color: 'red' }}>Nej</span>
					},
				]}
			>
				<Button key={shortid} variant="primary" onClick={() => setModalVisible(true)}>
					Lägg till anslutning
				</Button>
			</SearchableTable>


			<Modal animation={false} show={modalVisible} onHide={handleClose} dialogClassName="wide-modal">
				<Modal.Header closeButton>
					<Modal.Title style={{ color: colors.secondary.main.color }}>Lägg till anslutning</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DomainIntegrationForm domainId={domainId} />
				</Modal.Body>
			</Modal>
		</Container>
	)
}



export default DomainIntegrationList