import React, { useEffect, useState } from 'react'
import './index.scss'

const Pagination = ({
    pageOptions,
    page,
    pageCount,
    selectedFlatRows,
    pageIndex,
    pageSize,
    selectedRowIds,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    totalCount
}: {
    totalCount: number
    pageOptions: number[],
    page: any[],
    pageCount: number,
    selectedFlatRows: any,
    pageIndex: number,
    pageSize: number,
    selectedRowIds: number[],
    gotoPage: (page: number) => void,
    previousPage: () => void,
    nextPage: () => void,
    setPageSize: (size: number) => void,
    canPreviousPage: boolean,
    canNextPage: boolean
}) => {

    const [pageButtons, setPageButtons] = useState<{ page: number, label: string }[]>([])

    useEffect(() => {
        setPageButtons(pageOptions.map(x => ({ page: x, label: (x + 1).toString() })))
    }, [pageOptions])



    return (
        <div className="pagination">
            <div className="info">
                <b>Totalt</b>{' '}<span>{totalCount} rader</span><br />
            </div>
            <div className={`pages`}>
                Sida{' '}
                <select value={pageIndex} onChange={e => gotoPage(parseInt(e.target.value))}>
                    {pageButtons.map((p, i) => (
                        <option key={i} value={p.page}>{p.label}</option>
                    ))}
                </select>
                {' '}av{' '}{pageCount}

            </div>
            <div className="buttons">
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
            </div>
        </div>
    )
}


export default Pagination