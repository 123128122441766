import React, { useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import './index.scss'
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import { getRoutes } from '../../navigation';
import { Helmet } from 'react-helmet'

const BreadcrumbContainer = ({ minified }: { minified?: boolean }) => {
    const routes = getRoutes()
    let location = useLocation();

    const [activeLabel, setActiveLabel] = useState<string>()

    useEffect(() => {
        if (location.pathname && location.pathname !== '/') {
            let route = routes[location.pathname]
            if (route && typeof (route) === 'string')
                setActiveLabel(route)
        } else setActiveLabel(null)
    }, [location])


    if (minified) return null
    return (
        <Route path="*" render={props => {

            if (props.match.url.indexOf('/accept-invitation') > -1) return null

            let parts = props.location.pathname.split("/");
            if (parts.length === 0) return null

            return (
                <div className="breadcrumbs-wrapper">
                    {activeLabel && <Helmet>
                        <title>{activeLabel}</title>
                        <meta name="description" content="Nested component" />
                    </Helmet>}
                    <Breadcrumbs mappedRoutes={routes} />
                </div>
            )
        }} />
    )
}

export default BreadcrumbContainer