import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import './index.scss'
import logowhite from './navet-white.png'
import logo from './navet.png'
import AuthContext from '../../auth/authContext';
import {
	Link, useRouteMatch, useLocation
} from "react-router-dom";

const MenuBar: FunctionComponent<{
	sticky: boolean,
	minified?: boolean
}> = ({ sticky, minified }) => {
	const [isSticky, setIsSticky] = useState<boolean>(sticky)
	const route = useRouteMatch()
	const location = useLocation()

	useEffect(() => {
		if (location.pathname === '/' || location.pathname.indexOf('/accept-invitation') > -1) {
			setIsSticky(sticky)
		}
		else setIsSticky(true)
	}, [route, sticky, location])

	const { authenticationState, login } = useContext(AuthContext)

	if (minified) return null
	return (
		<div>
			<nav className={isSticky ? "navbar navbar-sticky" : "navbar"}>
				<Link to="/" className="navbar--logo-holder">
					<img src={isSticky ? logo : logowhite} alt="logo" className="navbar--logo" />
				</Link>

				{authenticationState === 'Authenticated' && (

					<Link to="/mina-sidor">
						<div className={`navbar--link ${isSticky ? 'rounded-button blue' : 'rounded-button'}`}>
							Mina sidor
						</div>
					</Link>

				)}

				{authenticationState === 'Unauthenticated' && (
					<div onClick={login} className={`navbar--link ${isSticky ? 'rounded-button blue' : 'rounded-button'}`}>
						Logga in
					</div>
				)}

			</nav>

		</div>
	)

}

export default MenuBar