import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Queries } from '../../queries/query'
import { FilterObjectInput, ObjectQuery, ObjectQueryVariables, RentalLine } from '../../types'
import { asMoney } from '../../utils'
import DetailsComponent from '../generic/DetailsComponent'
import moment from 'moment'
import OnRentChildren from './OnRentChildren'
import ObjectCard from '../Object/ObjectCard'
import RentallineTags from './RentallineTags'

const RentalCard = ({ domainId, rentalLine }: { domainId: string, rentalLine: RentalLine }) => {
    const [object, setObject] = useState<Object>(null)
    let [objectFilter, setObjectFilter] = useState<FilterObjectInput>(null)

    if (objectFilter)
        objectFilter.DomainId = domainId
    else
        objectFilter = {DomainId: domainId}

    useEffect(() => {
        if (!rentalLine || !rentalLine.DomainId || !rentalLine.No || rentalLine.Type !== 6) {
            setObjectFilter(null)
        }
        else setObjectFilter({
            DomainId: rentalLine.DomainId,
            ObjectNo: rentalLine.No
        })
    }, [rentalLine])

    const objectquery = useQuery<ObjectQuery, ObjectQueryVariables>(Queries.object, {
        skip: !objectFilter,
        variables: {
            filter: objectFilter
        },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (!objectquery || !objectquery.data || !objectquery.data.object) {
            setObject(null)
            return
        }
        setObject(objectquery.data.object)
    }, [objectquery])

    return (
        <Container>
            <DetailsComponent
                DomainId={rentalLine.DomainId}
                Headline={rentalLine.Description}
                subtitle={(
                    <div>
                        {rentalLine.Tags && rentalLine.Tags.map((x, i) => <span key={i} className="tag">{x}</span>)}
                    </div>
                )}
                sections={[
                    {
                        title: 'Hyrinformation',
                        data: [
                            { title: 'Nummer', value: rentalLine.No },
                            { title: 'Hyrdatum', value: rentalLine.OnRentDate ? moment(rentalLine.OnRentDate).format('YYYY-MM-DD') : '' },
                            { title: 'Förväntat returdatum', value: rentalLine.UserExpectedReturnDate ? moment(rentalLine.UserExpectedReturnDate).format('YYYY-MM-DD') : '' },
                            { title: 'Pris/dag', value: asMoney(rentalLine.DayNetPriceEdge) },
                            { title: 'Antal', value: rentalLine.RemainingQty },
                            { title: 'Pristermin', value: rentalLine.PriceTermCode },
                            { title: 'Baskalender', value: rentalLine.BaseCalendarCode },
                            { title: 'Avställd', value: rentalLine.Vaccation ? 'Ja' : 'Nej' },
                            { title: 'Projectnummer', value: rentalLine.CustomerProject },
                            { title: 'Typ', value: rentalLine.TypeOPTION },
                        ]
                    }
                ]}
            >
                <RentallineTags domainId={domainId} rentalLine={rentalLine} />
            </DetailsComponent>

            <OnRentChildren domainId={domainId} rentalLine={rentalLine} />
            {object && <ObjectCard passedObject={object} />}


        </Container>
    )
}


export default RentalCard