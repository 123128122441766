
import { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import * as en from 'linq'
import { roledefinitions } from '../../config'
import { useMe } from '../hooks/permissionHook'
import { Domain } from '../../types'
import imgComingSoon from '../../images/comingSoon.jpg'
import imgConstructions from '../../images/constructions.png'
import imgCustomerDomains from '../../images/customerDomains.jpg' 
import imgDashboard from '../../images/dashboard-alt-icon.png'
import imgGetSupport from '../../images/get-support.jpg'
import imgInvitations from '../../images/invitations.jpg'
import imgObjects from '../../images/objects.jpg'
import imgSysInt from '../../images/systemIntegrations.jpg'
import imgUsers from '../../images/users.png'

export type domainPanelsPayload = {
    domainId: string,
    loading?: boolean,
    error?: any,
    panels: panel[]
}

export const useDomainPanels = (domain: Domain): domainPanelsPayload => {
    const { me, loading, error } = useMe(domain.DomainId)
    let panels = []

    if (me && me.role)
        panels = en.from(allPanels)
                    .where(x => x.enabled)
                    .where(x => x.orientation.includes(domain.DomainMainOrientation))
                    .where(x => en.from(x.roles).any(role => role === me.role)).toArray()

    return {
        domainId: domain.DomainId,
        loading,
        panels,
        error
    }
}

export type panel = {
    title: string,
    image: JSX.Element,
    text: string,
    url: string,
    roles: roledefinitions[],
    enabled: boolean,
    orientation: string[],
    useAbsoluteLink: boolean,
}

const supportPanel: panel = {
    title: "Support",
    roles: [roledefinitions.globaladmin],
    image: <Card.Img variant="top" src={imgGetSupport} />, 
    text: "Få hjälp på det du behöver", 
    url: "/support",
    enabled: false,
    orientation: [null, 'sales', 'rental'],
    useAbsoluteLink: false
}

const comingSoonPanel: panel = {
    title: 'Coming Soon',
    roles: [roledefinitions.customeradmin, roledefinitions.customeruser, roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
    image: <Card.Img variant="top" src={imgComingSoon} />,
    text: 'coming soon',
    url: '/',
    enabled: false,
    orientation: [null, 'sales', 'rental'],
    useAbsoluteLink: false
}

const allPanels: panel[] = [
    {
        title: "Användare",
        roles: [roledefinitions.globaladmin, roledefinitions.domainadmin, roledefinitions.customeradmin],
        image: <Card.Img variant="top" src={imgUsers} />, 
        text: "Lägg till, ta bort och administrera användare", 
        url: "/users",
        enabled: true,
        orientation: [null, 'sales', 'rental'],
        useAbsoluteLink: false
    },
    {
        title: "Anslutningar",
        roles: [roledefinitions.globaladmin, roledefinitions.domainadmin],
        image: <Card.Img variant="top" src={imgSysInt} />, 
        text: "Hantera anslutningar till bland andra BC, NAV, mm", 
        url: "/integrations",
        enabled: true,
        orientation: [null, 'sales', 'rental'],
        useAbsoluteLink: false
    },
    {
        title: "Inbjudningar",
        roles: [roledefinitions.globaladmin, roledefinitions.domainadmin],
        image: <Card.Img variant="top" src={ imgInvitations } />, 
        text: "Hantera inbjudningar", 
        url: "/invitations",
        enabled: true,
        orientation: ['rental'],
        useAbsoluteLink: false
    },
    {
        title: "Kunddomäner",
        roles: [roledefinitions.globaladmin, roledefinitions.domainadmin],
        image: <Card.Img variant="top" src={ imgCustomerDomains } />,
        text: "Hantera bland annat leverantörer", 
        url: "/customer-domains",
        enabled: true,
        orientation: ['rental'],
        useAbsoluteLink: false
    },
    {
        title: 'Maskinkatalog',
        roles: [roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
        image: <Card.Img variant="top" src={imgObjects} />,
        text: 'Hantera bilder på objekttyper',
        url: '/object-list',
        enabled: true,
        orientation: ['rental'],
        useAbsoluteLink: false
    },
    {
        title: 'Maskinhyra',
        roles: [roledefinitions.customeradmin, roledefinitions.customeruser, roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
        image: <Card.Img variant="top" src={imgConstructions} />,
        text: 'Överblick över uthyrningarna',
        url: '/on-rent',
        enabled: true,
        orientation: ['rental'],
        useAbsoluteLink: false
    },
    supportPanel,
    comingSoonPanel
]

