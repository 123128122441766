import gql from 'graphql-tag'

export const Fragments = {
    document: gql`
        fragment document on Document {
            discriminator
			TargetId
			DomainId
			Name
			GlobalId
			DocumentTypeOPTION
			DocumentSubTypeOPTION			
        }
    `,
    documentWithLink: gql`
        fragment documentWithLink on Document {
            discriminator
			TargetId
			DomainId
			Name
			GlobalId
			DocumentTypeOPTION
			DocumentSubTypeOPTION		
            OriginalFileName	
			image {
				link
			}
			files {
				name
				link				
			}			
        }
    `,
    customerProject: gql`
        fragment customerProject on CustomerProject {            
            SyncedToNav SyncProblems discriminator
            CustomerNo
			Name
			GlobalId
			DomainId
			ProjectNo		
			ProjectId	
			YourReference
			CustomerId
        }
    `,
    invitation: gql`
        fragment invitation on Invitation  {            
            GlobalId SyncedToNav SyncProblems discriminator
            UserEmail
            Status
            Deactivated
            Role
            UserName
            Type
            CreatedOn            
            IOSAppCode
            IOSAppLink
            AndroidAppLink
            CreatedByUserEmail
            CreateByUserName
            InvitationCode
            ProjectNos
            DomainId
            RegistrationNo
            CustomerName            
            CustomerId            
            IsOnAppStore
            IsOnPlayStore
            IOSAppCodeCount
            IOSAppCodes {
                code
                used
            }
        }
    `,
    invitationLimited: gql`
        fragment invitationLimited on InvitationLimited  {            
            CompanyName
            RegistrationNo
        }
    `,
    company: gql`
        fragment company on Company {
            Name
            SyncedToNav SyncProblems discriminator
            No
            Address
            CompanyImageEtag
            CompanyImageURL
            City
            PostCode
            RegistrationNo
            EMail
            KeyValue
            GlobalId
            DomainId
            CustomerId
        }
    `,
    domain: gql`
        fragment domain on Domain {
            SyncedToNav SyncProblems discriminator			
            Name
			DomainMainOrientation
			ReservationsEnabled
			ReturnReservationsEnabled
			Description
			EnableObjectsLimited
			GlobalId
			ParentDomainId
			VisibleInSafetyRespect
			IsDemo
			ImageURL
			AlternativeName
			FlagImageUrl
        }
    `,
    domainLimited: gql`
		fragment domainLimited on DomainLimited {
			GlobalId
			Name
			ImageURL
			AlternativeName
			FlagImageUrl
			ReservationsEnabled
			ReturnReservationsEnabled
		}
    `,
    domainIntegration: gql`
        fragment domainIntegration on DomainIntegration {
            discriminator SyncedToNav SyncProblems
			Name
			Url
			Type
			AuthType
            DisableOrderPolling
            PreviousMagentoOrderId
            PreviousDekraPollingDateTime
			Company
			DomainId
			commonAuthority
			resource
			GlobalId
			Active			
			grant_type
			resource
			Active
            SchemaVersion
            Online
            LatestHeartBeat
            FirstHeartBeat
            HeartBeartIntervalMinutes
            NoOfReqsInOutboundQueue
            NoOfReqsInInboundQueue
            NoOfUnprocessableItems
            HeartbeatMonitoringEnabled
        }
    `,
    user: gql`
        fragment user on User {
            SyncedToNav SyncProblems discriminator			
            name
			email
            active
			PendingAccessRequest
			role
			CustomerId
			CustomerName			
			DomainId
			ProjectNos
			EmailOnExpiredObjects
			PushNotificationOnShipments
            GlobalId			
        }
    `,
    me: gql`
        fragment me on User {
            SyncedToNav SyncProblems discriminator
			name	
			email
            active
			PendingAccessRequest
			role		  
			CustomerId            
			CustomerName
            UserAcceptedInvitationOn
            UserInvitationId
			DomainId
			ProjectNos
            DomainIds
			EmailOnExpiredObjects
            ImpersonateUserId
			PushNotificationOnShipments
            favoriteProjects {
                Deactivated
                ProjectNo
                ProjectId
                CustomerNo					
                GlobalId
            }
			domain {
				Name
				DomainMainOrientation				
			}		
        }
    `,
    rentalPostedCollHeader: gql`
    fragment rentalPostedCollHeader  on RentalPostedCollHeader  {
            SyncedToNav SyncProblems	 discriminator					
            No
            GlobalId
            DomainId
            CustomerProject
            PostingDate
            ShiptoContact
        }
    `,
    rentalPostedShptHeader: gql`
    fragment rentalPostedShptHeader  on RentalPostedShptHeader  {
            SyncedToNav SyncProblems discriminator						
            CustomerProject
            No
            PostingDate
            ShiptoContact
            GlobalId
            DomainId
        }
    `,
    functionTestHeader: gql`
        fragment functionTestHeader  on FunctionTestHeader  {
            SyncedToNav SyncProblems discriminator
            GlobalId
            DomainId
            DocumentNo
            Status
            ResponsibilityCenter
            StatusOPTION            
            TypeOfWash
            ObjectNo
            ObjectDescription
        }
    `,
    functionTestLine: gql`
        fragment functionTestLine  on FunctionTestLine  {
            SyncedToNav SyncProblems discriminator
            GlobalId
            LineNo
            DocumentNo
            Date
            TestIssue       
            AdditionalInstructions
            CurrentStatus
            Mandatory
            ToCorrect
        }
    `,

    customerDomain: gql`
        fragment customerDomain  on CustomerDomain  {
            SyncedToNav SyncProblems discriminator GlobalId			
			RegistrationNo
			Name
			DomainIds
			AdminUserGlobalId		
			EmailDomain
			VerifiedOn
			VerifiedByUserName
			VerifiedByUserEmail
			ImageURL
			TrialStartedOn
			TrialEndsOn
			TrialLengthDays
			TrailActive		
            InvoiceAddress
            InvoicePostalCode
            InvoiceCity
            InvoiceNote
            InvoiceEmail	
            users {
                SyncedToNav SyncProblems discriminator			
                name	
                email		
                PendingAccessRequest
                role		  
                CustomerId
                CustomerName			
                DomainId
                ProjectNos
                EmailOnExpiredObjects
                PushNotificationOnShipments
                GlobalId		
            }
        }
    `,

    reservationRequestHeader: gql`
        fragment reservationRequestHeader   on ReservationRequestHeader   {
            SyncedToNav SyncProblems discriminator						
            GroupGUID
            Header
            TypeOfRequest
            Confirmed
            User
            UserName
            ProjectId
            RequestDate
            DomainId
            DeliveryDate
            Address
            City
            ExpectedReturnDate
            Comment
            DomainName
        }
    `,


    reservationRequestLine: gql`
        fragment reservationRequestLine    on ReservationRequestLine    {
            SyncedToNav SyncProblems discriminator						
            GroupGUID                        
            Header
            TypeOfRequest
            Confirmed
            User
            ProjectId
            RequestDate
            UserName            
            Type
            No
            Description
            Quantity
            Comment
        }
    `,


    returnRequestHeader: gql`
        fragment returnRequestHeader   on ReturnRequestHeader   {
            SyncedToNav SyncProblems discriminator						
            GroupGUID
            Header
            TypeOfRequest
            Confirmed
            User
            UserName
            DomainName
            ProjectNo
            PickUpDate
            Address
            City
            Comment
        }
    `,


    returnRequestLine: gql`
        fragment returnRequestLine    on ReturnRequestLine    {
            SyncedToNav SyncProblems discriminator						
            GroupGUID                        
            LineGUID
            Header
            TypeOfRequest
            Confirmed
            Executed
            RequestDate
            User
            UserName                        
            No
            Description
            Quantity
            Comment
        }
    `,

    objectFamily: gql`
        fragment objectFamily on ObjectFamily {
            discriminator SyncedToNav SyncProblems
            GlobalId
            DomainId
            Family
            ImgUrlSmall
            ImgUrlLarge
        }
    `,

    object: gql`
        fragment object on Object {
            discriminator
            GlobalId			
            DomainId
            CustomerId
            ObjectNo
            ObjectType
            Description
            ManufacturerCode
            ManufacturerModelCode
            Length
            Width
            Height
            LengthMeasure
            WidthMeasure
            HeightMeasure
            GPSCoordinates
            PSI
        }
    `,

    objectType: gql`
        fragment objectType on ObjectType {
            discriminator SyncedToNav SyncProblems
            GlobalId			
            DomainId
            CustomerId
            ProjectId
            NotAvailableForReservation
            ObjectType
            Description
            PictureURL
            Description3		
        }
    `,

    objectGroup: gql`
        fragment objectGroup     on ObjectGroup     {
            SyncedToNav SyncProblems discriminator						
            ObjectGroup                        
            Description
            ImgUrlSmall
        }
    `,

    rentalPostedCollLine: gql`
        fragment rentalPostedCollLine      on RentalPostedCollLine      {
            SyncedToNav SyncProblems discriminator						
			DocumentNo
			No
			Type
			ProjectId
			TypeOPTION
			Description
			Quantity
			ShipmentDate
			OnRentDate
			OffRentDate
			GlobalId
			DomainId
        }
        `,
    rentalPostedShptLine: gql`
    fragment rentalPostedShptLine      on RentalPostedShptLine      {
        SyncedToNav SyncProblems discriminator						
        DocumentNo
        No
        Type
        TypeOPTION
        Description
        ProjectId
        Quantity
        OnRentDate
        GlobalId
        DomainId
        ShipmentDate
    }
    `,
    rentalLine: gql`
        fragment rentalLine on RentalLine {
            discriminator SyncedToNav SyncProblems						
            Quantity	
            Tags
            ActualGlobalId
            GroupOnNumber		
            ReturnReservationActive
            OnRentDate			
            CustomerProject			
            Description
            DomainId
            InvoicedToDate
            PriceTermCode
            BaseCalendarCode
            Type
            GlobalId
            TypeOPTION
            KITMotherLineNo			
            ContractNo
            RemainingQty            
            DayNetPriceEdge
            PeriodLineAmountEdge
            NextInvoicePeriodStart
            RentalDays
            Vaccation
            RentalSale
            UserExpectedReturnDate            
            RemainingQty
            LineNo
            FreeDays
            OffRentDateFlow
            No		
            ImageUrl
            CustomerNo	        
            ProjectId    
        }
    `,

    invoiceHeader: gql`
        fragment invoiceHeader on InvoiceHeader {
            discriminator SyncedToNav SyncProblems
            GlobalId
            PostingDescription
            Closed
            EQMCombineCustomerProj
            No			
            BilltoCustomerNo
            BilltoName
            DomainId
            ProjectId
            OrderDate
            Amount
            AmountIncludingVAT
            DueDate
        }
    `,

}