import React, { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import shortid from 'shortid'
import { DevSettings } from '../profile/DevSettings'


const Error = ({ error, type, refetch }: {
    error?: any,
    type?: 'notFound' | 'unauthorized' | 'api' | 'custom' | 'mutation' | 'query',
    refetch?: () => void
}) => {

    const [dismissed, setDismissed] = useState(false)
    if (dismissed) return null
    switch (type) {
        case 'notFound': return <Alert variant="primary" dismissible onClose={() => setDismissed(true)}>
            <Alert.Heading>Målet du söker kan inte hittas</Alert.Heading>
        </Alert>

        case 'mutation':
            let messages = ''
            if (error.networkError && error.networkError.result && error.networkError.result.errors) {
                messages = error.networkError.result.errors.map(e => <p key={shortid()}>{e.message}</p>)
            }

            if (!error.message && !messages) messages = JSON.stringify(error)

            return <Alert variant="danger" dismissible onClose={() => setDismissed(true)}>
                <Alert.Heading>Ett fel har uppstått</Alert.Heading>
                {messages}
                {error.message && error.message}
            </Alert>

        case 'custom': return <Alert variant="primary" dismissible onClose={() => setDismissed(true)}>
            <Alert.Heading>{error}</Alert.Heading>
        </Alert>

        case 'unauthorized': return <Alert variant="danger" dismissible onClose={() => setDismissed(true)}>
            <Alert.Heading>Du har inte rättigheter att nå sidan</Alert.Heading>
        </Alert>

        case 'query': return (
            <Alert variant="danger" dismissible onClose={() => setDismissed(true)}>
                <Alert.Heading>Ett fel har uppstått</Alert.Heading>
                <p>{error.graphQLErrors.map((x, i) => <span key={i}>{x.message}</span>)}</p>
                <p>{JSON.stringify(error)}</p>

                <RenderUseLocalhostButton />
            </Alert>
        )

        default: return (
            <Alert variant="danger" dismissible onClose={() => setDismissed(true)}>
                <Alert.Heading>Ett fel har uppstått</Alert.Heading>
                <p>{JSON.stringify(error)}</p>

                <RenderUseLocalhostButton />
            </Alert>
        )
    }

}


const RenderUseLocalhostButton = () => {
    if (window.location.hostname !== 'localhost') return null

    return <DevSettings />
}

export default Error