import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import { CalculateRentallineInputValues, RentalLine, RentalLineQuery, RentalLineQueryVariables, RentalLinesCalculatedQuery, RentalLinesCalculatedQueryVariables } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import RentalCard from './RentalCard'
import './index.scss'
import moment from 'moment'
import { asMoney } from '../../utils'
import Link from '../generic/Link'

const RentalGroup = () => {
    const { domainId, customerProjectId, GroupOnNumber, rentalLineId } = useParams<{ domainId: string, customerProjectId: string, GroupOnNumber: string, rentalLineId: string }>()

    const [lines, setLines] = useState<RentalLine[]>([])
    const { data, loading, error } = useQuery<RentalLinesCalculatedQuery, RentalLinesCalculatedQueryVariables>(Queries.rentalLinesCalculated, {
        skip: !!rentalLineId,
        variables: {
            filter: {
                DomainId: domainId,
                GroupOnNumber: GroupOnNumber,
                ProjectId: customerProjectId,
            }, calculationType: CalculateRentallineInputValues.OnRent
        },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (data && data.rentalLinesCalculated) setLines(data.rentalLinesCalculated)
    }, [data])

    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (rentalLineId) return <SingleRentalLine domainId={domainId} GlobalId={rentalLineId} />

    return (
        <Container>
            {loading && <Loading />}
            {error && <Error error={error} />}
            {lines.length === 1 && <SingleRentalLine domainId={domainId} GlobalId={lines[0].ActualGlobalId || lines[0].GlobalId} />}
            {lines.length !== 1 && (
                <div className="shadow rounded-container">
                    <h1>Individer</h1>
                    {lines.map(item => (
                        <div key={item.GlobalId} className="rentalline">
                            <Link type="inline" to={`/${item.GlobalId}`}><h3>{item.Description}</h3></Link>
                            <RenderRow value={item.CustomerProject} header="Projekt" />
                            <RenderRow value={item.RemainingQty} header="Antal" />
                            <RenderRow value={moment(item.OnRentDate).format('YYYY-MM-DD')} header="Hyrdatum" />
                            <RenderRow value={item.UserExpectedReturnDate ? moment(item.UserExpectedReturnDate).format('YYYY-MM-DD') : '-'} header="Förväntat returdatum" />
                            <RenderRow value={asMoney(item.DayNetPriceEdge)} header="Pris/Dag" />
                            <RenderRow value={item.Vaccation ? 'Ja' : 'Nej'} header="Avställd" />
                        </div>
                    ))}
                </div>
            )}
        </Container>
    )
}

const RenderRow = ({ value, header }: { value: any, header: string }) => {
    return (
        <div className="details-row">
            <span>{header}</span>
            <span>{value}</span>
        </div>
    )
}


const SingleRentalLine = ({ domainId, GlobalId }: { domainId: string, GlobalId: string }) => {

    const [line, setLine] = useState<RentalLine>()
    const { data, loading, error } = useQuery<RentalLineQuery, RentalLineQueryVariables>(Queries.rentalLine, { 
        variables: { 
            filter: { GlobalId, DomainId: domainId } 
        },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (data && data.rentalLine) setLine(data.rentalLine)
    }, [data])

    if (!line) return <Loading />
    return <RentalCard domainId={domainId} rentalLine={line} />
}

export default RentalGroup