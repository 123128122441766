import React from 'react'
import { Container } from 'react-bootstrap'
import { Mutations } from '../../queries/mutation'
import { Queries } from '../../queries/query'
import { Domain } from '../../types'
import Link from '../generic/Link'
import SearchableTable from '../table/SearchableTable'
import { CreateNewDomainButton } from './DomainForm'

const DomainList = () => {
    return (
        <Container>
            <SearchableTable
                domainId=''
                deleteManyMutation={Mutations.removeDomainMany}
                query={Queries.domainsPage}
                cols={[
                    {
                        Header: 'Namn',
                        accessor: 'Name',
                        Cell: ({ row: { original } }: { row: { original: Domain } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.Name}</Link>
                        },
                    },
                    {
                        accessor: 'GlobalId',
                        Header: 'GlobalId',
                        Cell: ({ row: { original } }: { row: { original: Domain } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.GlobalId}</Link>
                        },
                    },
                ]}
            >
                <CreateNewDomainButton />
            </SearchableTable>
        </Container>
    )
}

export default DomainList