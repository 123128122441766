import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  EmailAddress: any;
  UnsignedInt: any;
};

export type AcceptInivitationResponse = {
  __typename?: 'AcceptInivitationResponse';
  status?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  UserAcceptedInvitationOn?: Maybe<Scalars['DateTime']>;
  UserInvitationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  IOSAppLink?: Maybe<Scalars['String']>;
  AndroidAppLink?: Maybe<Scalars['String']>;
  IOSAppCode?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
};

export type AccessRequest = {
  __typename?: 'AccessRequest';
  discriminator?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  Type?: Maybe<AccessRequestTypeEnum>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  TargetName?: Maybe<Scalars['String']>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  Status?: Maybe<AccessRequestStatusEnum>;
  Approved?: Maybe<Scalars['Boolean']>;
  ApprovedOn?: Maybe<Scalars['DateTime']>;
  RequestedOn?: Maybe<Scalars['DateTime']>;
  ApprovedByUserGlobalId?: Maybe<Scalars['String']>;
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Company>;
  domain?: Maybe<Domain>;
  user?: Maybe<User>;
};

export type AccessRequestConnection = {
  __typename?: 'AccessRequestConnection';
  count: Scalars['Int'];
  edges: Array<AccessRequestConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AccessRequestConnectionEdge = {
  __typename?: 'AccessRequestConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: AccessRequest;
};

export type AccessRequestDeleteResponse = {
  __typename?: 'AccessRequestDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<AccessRequest>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AccessRequestManyResult = {
  __typename?: 'AccessRequestManyResult';
  records?: Maybe<Array<Maybe<AccessRequest>>>;
  pageData?: Maybe<PageData>;
};

export enum AccessRequestStatusEnum {
  New = 'new',
  Approved = 'approved',
  Rejected = 'rejected',
  Canceled = 'canceled',
  Unauthorized = 'unauthorized'
}

export enum AccessRequestTypeEnum {
  Domain = 'domain',
  Company = 'company'
}

export type AccumulatedRentCost = {
  __typename?: 'AccumulatedRentCost';
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type AccumulatedRentCostFilter = {
  ProjectId: Scalars['String'];
};

export type AdditionalItem = {
  __typename?: 'AdditionalItem';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Type?: Maybe<AdditionalItemTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  AdditionalItemNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  QuantityOption?: Maybe<AdditionalItemQuantityOptionEnum>;
  QuantityOptionOPTION?: Maybe<Scalars['String']>;
  QuantityRatio?: Maybe<Scalars['Float']>;
  MinRequiredQty?: Maybe<Scalars['Float']>;
  UseZeroPrice?: Maybe<Scalars['Boolean']>;
  Active?: Maybe<Scalars['Boolean']>;
  Price?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type AdditionalItemConnection = {
  __typename?: 'AdditionalItemConnection';
  count: Scalars['Int'];
  edges: Array<AdditionalItemConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdditionalItemConnectionEdge = {
  __typename?: 'AdditionalItemConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: AdditionalItem;
};

export type AdditionalItemDeleteResponse = {
  __typename?: 'AdditionalItemDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<AdditionalItem>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdditionalItemManyResult = {
  __typename?: 'AdditionalItemManyResult';
  records?: Maybe<Array<Maybe<AdditionalItem>>>;
  pageData?: Maybe<PageData>;
};

export enum AdditionalItemQuantityOptionEnum {
  Multiple = 'Multiple',
  FixedQuantity = 'FixedQuantity'
}

export enum AdditionalItemTypeEnum {
  Empty = 'Empty',
  GlAccount = 'GLAccount',
  Item = 'Item',
  Resource = 'Resource',
  FixedAsset = 'FixedAsset',
  ChargeItem = 'ChargeItem'
}

export type Address = {
  __typename?: 'Address';
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PlaceofExport?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  CustomerGlobalId?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  count: Scalars['Int'];
  edges: Array<AddressConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AddressConnectionEdge = {
  __typename?: 'AddressConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Address;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Address>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressManyResult = {
  __typename?: 'AddressManyResult';
  records?: Maybe<Array<Maybe<Address>>>;
  pageData?: Maybe<PageData>;
};

export enum AggregateAccessRequestInput {
  /** Aggregate result on UserEmail */
  UserEmail = 'UserEmail',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on TargetName */
  TargetName = 'TargetName',
  /** Aggregate result on ProjectNos */
  ProjectNos = 'ProjectNos',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on Approved */
  Approved = 'Approved',
  /** Aggregate result on ApprovedOn */
  ApprovedOn = 'ApprovedOn',
  /** Aggregate result on RequestedOn */
  RequestedOn = 'RequestedOn',
  /** Aggregate result on ApprovedByUserGlobalId */
  ApprovedByUserGlobalId = 'ApprovedByUserGlobalId',
  /** Aggregate result on DomainIds */
  DomainIds = 'DomainIds',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateAdditionalItemInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on AdditionalItemNo */
  AdditionalItemNo = 'AdditionalItemNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on QuantityOption */
  QuantityOption = 'QuantityOption',
  /** Aggregate result on QuantityOptionOPTION */
  QuantityOptionOption = 'QuantityOptionOPTION',
  /** Aggregate result on QuantityRatio */
  QuantityRatio = 'QuantityRatio',
  /** Aggregate result on MinRequiredQty */
  MinRequiredQty = 'MinRequiredQty',
  /** Aggregate result on UseZeroPrice */
  UseZeroPrice = 'UseZeroPrice',
  /** Aggregate result on Active */
  Active = 'Active',
  /** Aggregate result on Price */
  Price = 'Price',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateAddressInput {
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PlaceofExport */
  PlaceofExport = 'PlaceofExport',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on TelexAnswerBack */
  TelexAnswerBack = 'TelexAnswerBack',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ServiceZoneCode */
  ServiceZoneCode = 'ServiceZoneCode',
  /** Aggregate result on ShipmentDays */
  ShipmentDays = 'ShipmentDays',
  /** Aggregate result on SharkFreightInstrToWsh */
  SharkFreightInstrToWsh = 'SharkFreightInstrToWsh',
  /** Aggregate result on Tour */
  Tour = 'Tour',
  /** Aggregate result on TourNo */
  TourNo = 'TourNo',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on MagentoAddressId */
  MagentoAddressId = 'MagentoAddressId',
  /** Aggregate result on CustomerGlobalId */
  CustomerGlobalId = 'CustomerGlobalId',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on Fax */
  Fax = 'Fax',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateAgreementInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on RecName */
  RecName = 'RecName',
  /** Aggregate result on RecId */
  RecId = 'RecId',
  /** Aggregate result on AgreementType */
  AgreementType = 'AgreementType',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on FromDate */
  FromDate = 'FromDate',
  /** Aggregate result on ToDate */
  ToDate = 'ToDate',
  /** Aggregate result on SignatureType */
  SignatureType = 'SignatureType',
  /** Aggregate result on Signature */
  Signature = 'Signature',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateAnnotationInput {
  /** Aggregate result on ObjectTypeCode */
  ObjectTypeCode = 'ObjectTypeCode',
  /** Aggregate result on NoteText */
  NoteText = 'NoteText',
  /** Aggregate result on OwnerId */
  OwnerId = 'OwnerId',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on OwnerEmail */
  OwnerEmail = 'OwnerEmail',
  /** Aggregate result on OwnerName */
  OwnerName = 'OwnerName',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateApplicationModuleInput {
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on ShortName */
  ShortName = 'ShortName',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ShortDescription */
  ShortDescription = 'ShortDescription',
  /** Aggregate result on Domains */
  Domains = 'Domains',
  /** Aggregate result on DefaultWebLink */
  DefaultWebLink = 'DefaultWebLink',
  /** Aggregate result on DefaultAppNavStackName */
  DefaultAppNavStackName = 'DefaultAppNavStackName',
  /** Aggregate result on SearchOrder */
  SearchOrder = 'SearchOrder',
  /** Aggregate result on NavigationOrder */
  NavigationOrder = 'NavigationOrder',
  /** Aggregate result on Searchable */
  Searchable = 'Searchable',
  /** Aggregate result on ShowOnWebHeader */
  ShowOnWebHeader = 'ShowOnWebHeader',
  /** Aggregate result on ShowOnAppMainTab */
  ShowOnAppMainTab = 'ShowOnAppMainTab',
  /** Aggregate result on VisibleForCustomer */
  VisibleForCustomer = 'VisibleForCustomer',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateBcMappingFieldInput {
  /** Aggregate result on NBAEDirection */
  NbaeDirection = 'NBAEDirection',
  /** Aggregate result on NBAEDirectionOPTION */
  NbaeDirectionOption = 'NBAEDirectionOPTION',
  /** Aggregate result on NBAETableNo */
  NbaeTableNo = 'NBAETableNo',
  /** Aggregate result on NBAEEventType */
  NbaeEventType = 'NBAEEventType',
  /** Aggregate result on NBAEEventTypeOPTION */
  NbaeEventTypeOption = 'NBAEEventTypeOPTION',
  /** Aggregate result on NBAESequenceNo */
  NbaeSequenceNo = 'NBAESequenceNo',
  /** Aggregate result on NBAELineNo */
  NbaeLineNo = 'NBAELineNo',
  /** Aggregate result on NBAEPKField */
  NbaepkField = 'NBAEPKField',
  /** Aggregate result on NBAEIgnore */
  NbaeIgnore = 'NBAEIgnore',
  /** Aggregate result on NBAESortingOrder */
  NbaeSortingOrder = 'NBAESortingOrder',
  /** Aggregate result on NBAEOutFromDataType */
  NbaeOutFromDataType = 'NBAEOutFromDataType',
  /** Aggregate result on NBAEOutFromDataTypeOPTION */
  NbaeOutFromDataTypeOption = 'NBAEOutFromDataTypeOPTION',
  /** Aggregate result on NBAEOutFromDataSubType */
  NbaeOutFromDataSubType = 'NBAEOutFromDataSubType',
  /** Aggregate result on NBAEOutToJSONElement */
  NbaeOutToJsonElement = 'NBAEOutToJSONElement',
  /** Aggregate result on NBAEInFromDataType */
  NbaeInFromDataType = 'NBAEInFromDataType',
  /** Aggregate result on NBAEInFromDataTypeOPTION */
  NbaeInFromDataTypeOption = 'NBAEInFromDataTypeOPTION',
  /** Aggregate result on NBAEInFromDataSubType */
  NbaeInFromDataSubType = 'NBAEInFromDataSubType',
  /** Aggregate result on NBAEInOperator */
  NbaeInOperator = 'NBAEInOperator',
  /** Aggregate result on NBAEInOperatorOPTION */
  NbaeInOperatorOption = 'NBAEInOperatorOPTION',
  /** Aggregate result on NBAEInToFieldNo */
  NbaeInToFieldNo = 'NBAEInToFieldNo',
  /** Aggregate result on NBAEInToFieldName */
  NbaeInToFieldName = 'NBAEInToFieldName',
  /** Aggregate result on NBAEInValidate */
  NbaeInValidate = 'NBAEInValidate',
  /** Aggregate result on NBAEInTranslatesFromField */
  NbaeInTranslatesFromField = 'NBAEInTranslatesFromField',
  /** Aggregate result on NBAELineFieldNo */
  NbaeLineFieldNo = 'NBAELineFieldNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateBcMappingTableInput {
  /** Aggregate result on NBAEDirection */
  NbaeDirection = 'NBAEDirection',
  /** Aggregate result on NBAEDirectionOPTION */
  NbaeDirectionOption = 'NBAEDirectionOPTION',
  /** Aggregate result on NBAETableNo */
  NbaeTableNo = 'NBAETableNo',
  /** Aggregate result on NBAEEventType */
  NbaeEventType = 'NBAEEventType',
  /** Aggregate result on NBAEEventTypeOPTION */
  NbaeEventTypeOption = 'NBAEEventTypeOPTION',
  /** Aggregate result on NBAESequenceNo */
  NbaeSequenceNo = 'NBAESequenceNo',
  /** Aggregate result on NBAETableName */
  NbaeTableName = 'NBAETableName',
  /** Aggregate result on NBAEIgnore */
  NbaeIgnore = 'NBAEIgnore',
  /** Aggregate result on NBAEEntityName */
  NbaeEntityName = 'NBAEEntityName',
  /** Aggregate result on NBAEDescription */
  NbaeDescription = 'NBAEDescription',
  /** Aggregate result on NBAEProcessingOrder */
  NbaeProcessingOrder = 'NBAEProcessingOrder',
  /** Aggregate result on NBAEProcessOnReceipt */
  NbaeProcessOnReceipt = 'NBAEProcessOnReceipt',
  /** Aggregate result on NBAEProcessAsTransactions */
  NbaeProcessAsTransactions = 'NBAEProcessAsTransactions',
  /** Aggregate result on NBAEFields */
  NbaeFields = 'NBAEFields',
  /** Aggregate result on NBAEFilters */
  NbaeFilters = 'NBAEFilters',
  /** Aggregate result on NBAERelations */
  NbaeRelations = 'NBAERelations',
  /** Aggregate result on NBAEDelay */
  NbaeDelay = 'NBAEDelay',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateBcSettingsInput {
  /** Aggregate result on NBAECode */
  NbaeCode = 'NBAECode',
  /** Aggregate result on NBAELineNo */
  NbaeLineNo = 'NBAELineNo',
  /** Aggregate result on NBAESettingName */
  NbaeSettingName = 'NBAESettingName',
  /** Aggregate result on NBAESettingType */
  NbaeSettingType = 'NBAESettingType',
  /** Aggregate result on NBAESettingTypeOPTION */
  NbaeSettingTypeOption = 'NBAESettingTypeOPTION',
  /** Aggregate result on NBAESettingValue */
  NbaeSettingValue = 'NBAESettingValue',
  /** Aggregate result on NBAEIgnore */
  NbaeIgnore = 'NBAEIgnore',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateBulkItemGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on MagentoAttribOptionId */
  MagentoAttribOptionId = 'MagentoAttribOptionId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCauseOfAbsenceInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on EmployeeNoFilter */
  EmployeeNoFilter = 'EmployeeNoFilter',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on TotalAbsenceBase */
  TotalAbsenceBase = 'TotalAbsenceBase',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCompanyInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on PrimaryKey */
  PrimaryKey = 'PrimaryKey',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on PhoneNo2 */
  PhoneNo2 = 'PhoneNo2',
  /** Aggregate result on TelexNo */
  TelexNo = 'TelexNo',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on DocumentSendingProfile */
  DocumentSendingProfile = 'DocumentSendingProfile',
  /** Aggregate result on OurAccountNo */
  OurAccountNo = 'OurAccountNo',
  /** Aggregate result on TerritoryCode */
  TerritoryCode = 'TerritoryCode',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on ChainName */
  ChainName = 'ChainName',
  /** Aggregate result on BudgetedAmount */
  BudgetedAmount = 'BudgetedAmount',
  /** Aggregate result on CreditLimitLCY */
  CreditLimitLcy = 'CreditLimitLCY',
  /** Aggregate result on CustomerPostingGroup */
  CustomerPostingGroup = 'CustomerPostingGroup',
  /** Aggregate result on GiroNo */
  GiroNo = 'GiroNo',
  /** Aggregate result on BankName */
  BankName = 'BankName',
  /** Aggregate result on BankBranchNo */
  BankBranchNo = 'BankBranchNo',
  /** Aggregate result on BankAccountNo */
  BankAccountNo = 'BankAccountNo',
  /** Aggregate result on PaymentRoutingNo */
  PaymentRoutingNo = 'PaymentRoutingNo',
  /** Aggregate result on CustomsPermitNo */
  CustomsPermitNo = 'CustomsPermitNo',
  /** Aggregate result on CustomsPermitDate */
  CustomsPermitDate = 'CustomsPermitDate',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on RegistrationNo */
  RegistrationNo = 'RegistrationNo',
  /** Aggregate result on TelexAnswerBack */
  TelexAnswerBack = 'TelexAnswerBack',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on PrimaryContactNo */
  PrimaryContactNo = 'PrimaryContactNo',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on BlockedOPTION */
  BlockedOption = 'BlockedOPTION',
  /** Aggregate result on IBAN */
  Iban = 'IBAN',
  /** Aggregate result on SWIFTCode */
  SwiftCode = 'SWIFTCode',
  /** Aggregate result on IndustrialClassification */
  IndustrialClassification = 'IndustrialClassification',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on ICInboxType */
  IcInboxType = 'ICInboxType',
  /** Aggregate result on ICInboxDetails */
  IcInboxDetails = 'ICInboxDetails',
  /** Aggregate result on SystemIndicator */
  SystemIndicator = 'SystemIndicator',
  /** Aggregate result on CustomSystemIndicatorText */
  CustomSystemIndicatorText = 'CustomSystemIndicatorText',
  /** Aggregate result on SystemIndicatorStyle */
  SystemIndicatorStyle = 'SystemIndicatorStyle',
  /** Aggregate result on AllowBlankPaymentInfo */
  AllowBlankPaymentInfo = 'AllowBlankPaymentInfo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on CheckAvailPeriodCalc */
  CheckAvailPeriodCalc = 'CheckAvailPeriodCalc',
  /** Aggregate result on CheckAvailTimeBucket */
  CheckAvailTimeBucket = 'CheckAvailTimeBucket',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on CalConvergenceTimeFrame */
  CalConvergenceTimeFrame = 'CalConvergenceTimeFrame',
  /** Aggregate result on PlusGiroNo */
  PlusGiroNo = 'PlusGiroNo',
  /** Aggregate result on RegisteredOffice */
  RegisteredOffice = 'RegisteredOffice',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on UseExtraNavetPermissions */
  UseExtraNavetPermissions = 'UseExtraNavetPermissions',
  /** Aggregate result on BlockObjectTypeModify */
  BlockObjectTypeModify = 'BlockObjectTypeModify',
  /** Aggregate result on UseStandardPriceCalculation */
  UseStandardPriceCalculation = 'UseStandardPriceCalculation',
  /** Aggregate result on UseStavdalResourcePrice */
  UseStavdalResourcePrice = 'UseStavdalResourcePrice',
  /** Aggregate result on CombineShipments */
  CombineShipments = 'CombineShipments',
  /** Aggregate result on GLN */
  Gln = 'GLN',
  /** Aggregate result on ProductionDatabase */
  ProductionDatabase = 'ProductionDatabase',
  /** Aggregate result on MarkedAsTest */
  MarkedAsTest = 'MarkedAsTest',
  /** Aggregate result on DontAskAgain */
  DontAskAgain = 'DontAskAgain',
  /** Aggregate result on TestEmail */
  TestEmail = 'TestEmail',
  /** Aggregate result on BaseisInitiated */
  BaseisInitiated = 'BaseisInitiated',
  /** Aggregate result on PlusGiroRefAccountNo */
  PlusGiroRefAccountNo = 'PlusGiroRefAccountNo',
  /** Aggregate result on CompanyImageEtag */
  CompanyImageEtag = 'CompanyImageEtag',
  /** Aggregate result on CompanyImageDocGlobalId */
  CompanyImageDocGlobalId = 'CompanyImageDocGlobalId',
  /** Aggregate result on CompanyImageURL */
  CompanyImageUrl = 'CompanyImageURL',
  /** Aggregate result on Address_Visit */
  AddressVisit = 'Address_Visit',
  /** Aggregate result on Address2_Visit */
  Address2Visit = 'Address2_Visit',
  /** Aggregate result on City_Visit */
  CityVisit = 'City_Visit',
  /** Aggregate result on PostCode_Visit */
  PostCodeVisit = 'PostCode_Visit',
  /** Aggregate result on EmailDomain */
  EmailDomain = 'EmailDomain',
  /** Aggregate result on ScriveField */
  ScriveField = 'ScriveField',
  /** Aggregate result on InvoiceEmail */
  InvoiceEmail = 'InvoiceEmail',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on ActiveInEdge */
  ActiveInEdge = 'ActiveInEdge',
  /** Aggregate result on IsProspect */
  IsProspect = 'IsProspect',
  /** Aggregate result on Analytics */
  Analytics = 'Analytics',
  /** Aggregate result on MagentoGroupId */
  MagentoGroupId = 'MagentoGroupId',
  /** Aggregate result on StoreId */
  StoreId = 'StoreId',
  /** Aggregate result on WebsiteId */
  WebsiteId = 'WebsiteId',
  /** Aggregate result on MagentoAddressId */
  MagentoAddressId = 'MagentoAddressId',
  /** Aggregate result on Fax */
  Fax = 'Fax',
  /** Aggregate result on IsWebCRM */
  IsWebCrm = 'IsWebCRM',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on LatestInvoiceDate */
  LatestInvoiceDate = 'LatestInvoiceDate',
  /** Aggregate result on RevenueThisYear */
  RevenueThisYear = 'RevenueThisYear',
  /** Aggregate result on RevenueLastYear */
  RevenueLastYear = 'RevenueLastYear',
  /** Aggregate result on RevenueYearBefLastYear */
  RevenueYearBefLastYear = 'RevenueYearBefLastYear',
  /** Aggregate result on IsPublicRealbridge */
  IsPublicRealbridge = 'IsPublicRealbridge',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on StatisticsGroup */
  StatisticsGroup = 'StatisticsGroup',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on FinChargeTermsCode */
  FinChargeTermsCode = 'FinChargeTermsCode',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PlaceofExport */
  PlaceofExport = 'PlaceofExport',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on CollectionMethod */
  CollectionMethod = 'CollectionMethod',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on InvoiceCopies */
  InvoiceCopies = 'InvoiceCopies',
  /** Aggregate result on LastStatementNo */
  LastStatementNo = 'LastStatementNo',
  /** Aggregate result on PrintStatements */
  PrintStatements = 'PrintStatements',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on Priority */
  Priority = 'Priority',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on Balance */
  Balance = 'Balance',
  /** Aggregate result on BalanceLCY */
  BalanceLcy = 'BalanceLCY',
  /** Aggregate result on NetChange */
  NetChange = 'NetChange',
  /** Aggregate result on NetChangeLCY */
  NetChangeLcy = 'NetChangeLCY',
  /** Aggregate result on SalesLCY */
  SalesLcy = 'SalesLCY',
  /** Aggregate result on ProfitLCY */
  ProfitLcy = 'ProfitLCY',
  /** Aggregate result on InvDiscountsLCY */
  InvDiscountsLcy = 'InvDiscountsLCY',
  /** Aggregate result on PmtDiscountsLCY */
  PmtDiscountsLcy = 'PmtDiscountsLCY',
  /** Aggregate result on BalanceDue */
  BalanceDue = 'BalanceDue',
  /** Aggregate result on BalanceDueLCY */
  BalanceDueLcy = 'BalanceDueLCY',
  /** Aggregate result on Payments */
  Payments = 'Payments',
  /** Aggregate result on InvoiceAmounts */
  InvoiceAmounts = 'InvoiceAmounts',
  /** Aggregate result on CrMemoAmounts */
  CrMemoAmounts = 'CrMemoAmounts',
  /** Aggregate result on FinanceChargeMemoAmounts */
  FinanceChargeMemoAmounts = 'FinanceChargeMemoAmounts',
  /** Aggregate result on PaymentsLCY */
  PaymentsLcy = 'PaymentsLCY',
  /** Aggregate result on InvAmountsLCY */
  InvAmountsLcy = 'InvAmountsLCY',
  /** Aggregate result on CrMemoAmountsLCY */
  CrMemoAmountsLcy = 'CrMemoAmountsLCY',
  /** Aggregate result on FinChargeMemoAmountsLCY */
  FinChargeMemoAmountsLcy = 'FinChargeMemoAmountsLCY',
  /** Aggregate result on OutstandingOrders */
  OutstandingOrders = 'OutstandingOrders',
  /** Aggregate result on ShippedNotInvoiced */
  ShippedNotInvoiced = 'ShippedNotInvoiced',
  /** Aggregate result on ApplicationMethod */
  ApplicationMethod = 'ApplicationMethod',
  /** Aggregate result on ApplicationMethodOPTION */
  ApplicationMethodOption = 'ApplicationMethodOPTION',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on DontDebitPlasticTax */
  DontDebitPlasticTax = 'DontDebitPlasticTax',
  /** Aggregate result on DontDebitEnvironmentFees */
  DontDebitEnvironmentFees = 'DontDebitEnvironmentFees',
  /** Aggregate result on ShipmentDays */
  ShipmentDays = 'ShipmentDays',
  /** Aggregate result on EnvironmentShipmentDays */
  EnvironmentShipmentDays = 'EnvironmentShipmentDays',
  /** Aggregate result on CustomerCategory */
  CustomerCategory = 'CustomerCategory',
  /** Aggregate result on CustomerClassification */
  CustomerClassification = 'CustomerClassification',
  /** Aggregate result on MagentoCustomer */
  MagentoCustomer = 'MagentoCustomer',
  /** Aggregate result on Allwebitemsisvisible */
  Allwebitemsisvisible = 'Allwebitemsisvisible',
  /** Aggregate result on SharkFreightInstrToWsh */
  SharkFreightInstrToWsh = 'SharkFreightInstrToWsh',
  /** Aggregate result on SharkPrioritizeCode */
  SharkPrioritizeCode = 'SharkPrioritizeCode',
  /** Aggregate result on SharkPrioritizeCodeOPTION */
  SharkPrioritizeCodeOption = 'SharkPrioritizeCodeOPTION',
  /** Aggregate result on SharkInvoiceValue */
  SharkInvoiceValue = 'SharkInvoiceValue',
  /** Aggregate result on SharkFreightPrice */
  SharkFreightPrice = 'SharkFreightPrice',
  /** Aggregate result on SharkTelephoneInstruction */
  SharkTelephoneInstruction = 'SharkTelephoneInstruction',
  /** Aggregate result on NoBackOrder */
  NoBackOrder = 'NoBackOrder',
  /** Aggregate result on Tour */
  Tour = 'Tour',
  /** Aggregate result on TourNo */
  TourNo = 'TourNo',
  /** Aggregate result on OnlyActualFreightPrice */
  OnlyActualFreightPrice = 'OnlyActualFreightPrice',
  /** Aggregate result on InvoiceDocumentType */
  InvoiceDocumentType = 'InvoiceDocumentType',
  /** Aggregate result on InvoiceDocumentTypeOPTION */
  InvoiceDocumentTypeOption = 'InvoiceDocumentTypeOPTION',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on DocumentCode */
  DocumentCode = 'DocumentCode',
  /** Aggregate result on CustomerPriceGroups */
  CustomerPriceGroups = 'CustomerPriceGroups',
  /** Aggregate result on PaymentMethodWeb */
  PaymentMethodWeb = 'PaymentMethodWeb',
  /** Aggregate result on QBSId */
  QbsId = 'QBSId',
  /** Aggregate result on CRMClassification */
  CrmClassification = 'CRMClassification',
  /** Aggregate result on PEBShiptoCode */
  PebShiptoCode = 'PEBShiptoCode',
  /** Aggregate result on PEBDirectDebitPackType */
  PebDirectDebitPackType = 'PEBDirectDebitPackType',
  /** Aggregate result on PEBDirectDebitPackTypeOPTION */
  PebDirectDebitPackTypeOption = 'PEBDirectDebitPackTypeOPTION',
  /** Aggregate result on PEBDirectDebitPaymentNo */
  PebDirectDebitPaymentNo = 'PEBDirectDebitPaymentNo',
  /** Aggregate result on PEBDirectDebitApprovalStat */
  PebDirectDebitApprovalStat = 'PEBDirectDebitApprovalStat',
  /** Aggregate result on PEBDirectDebitApprovalStatOPTION */
  PebDirectDebitApprovalStatOption = 'PEBDirectDebitApprovalStatOPTION',
  /** Aggregate result on PEBDDApprovalStatus */
  PebddApprovalStatus = 'PEBDDApprovalStatus',
  /** Aggregate result on PEBDDApprovalStatusOPTION */
  PebddApprovalStatusOption = 'PEBDDApprovalStatusOPTION',
  /** Aggregate result on PEBRegistrationNo */
  PebRegistrationNo = 'PEBRegistrationNo',
  /** Aggregate result on PEBDocumentCode */
  PebDocumentCode = 'PEBDocumentCode',
  /** Aggregate result on EQMInvoiceDocType */
  EqmInvoiceDocType = 'EQMInvoiceDocType',
  /** Aggregate result on EQMCustomerTemplate */
  EqmCustomerTemplate = 'EQMCustomerTemplate',
  /** Aggregate result on EQMCustRentalDiscGroup */
  EqmCustRentalDiscGroup = 'EQMCustRentalDiscGroup',
  /** Aggregate result on EQMCustomerType */
  EqmCustomerType = 'EQMCustomerType',
  /** Aggregate result on EQMCustomerTypeOPTION */
  EqmCustomerTypeOption = 'EQMCustomerTypeOPTION',
  /** Aggregate result on EQMEditableRentalInsurance */
  EqmEditableRentalInsurance = 'EQMEditableRentalInsurance',
  /** Aggregate result on EQMRentalInsurance */
  EqmRentalInsurance = 'EQMRentalInsurance',
  /** Aggregate result on EQMCustRentalPriceGroup */
  EqmCustRentalPriceGroup = 'EQMCustRentalPriceGroup',
  /** Aggregate result on EQMHowInvoiceRentalContr */
  EqmHowInvoiceRentalContr = 'EQMHowInvoiceRentalContr',
  /** Aggregate result on EQMHowInvoiceRentalContrOPTION */
  EqmHowInvoiceRentalContrOption = 'EQMHowInvoiceRentalContrOPTION',
  /** Aggregate result on EQMCustomerDeposit */
  EqmCustomerDeposit = 'EQMCustomerDeposit',
  /** Aggregate result on EQMCheckListCode */
  EqmCheckListCode = 'EQMCheckListCode',
  /** Aggregate result on EQMCustomerCategory */
  EqmCustomerCategory = 'EQMCustomerCategory',
  /** Aggregate result on EQMCustomerCategoryOPTION */
  EqmCustomerCategoryOption = 'EQMCustomerCategoryOPTION',
  /** Aggregate result on EQMPopupNote */
  EqmPopupNote = 'EQMPopupNote',
  /** Aggregate result on EQMCombineRentalInvoice */
  EqmCombineRentalInvoice = 'EQMCombineRentalInvoice',
  /** Aggregate result on EQMCombineMethod */
  EqmCombineMethod = 'EQMCombineMethod',
  /** Aggregate result on EQMCombineMethodOPTION */
  EqmCombineMethodOption = 'EQMCombineMethodOPTION',
  /** Aggregate result on EQMRentalInvDayofMonth */
  EqmRentalInvDayofMonth = 'EQMRentalInvDayofMonth',
  /** Aggregate result on EQMCustomerClassification */
  EqmCustomerClassification = 'EQMCustomerClassification',
  /** Aggregate result on EQMManuallyBlocked */
  EqmManuallyBlocked = 'EQMManuallyBlocked',
  /** Aggregate result on EQMOrganizationNo */
  EqmOrganizationNo = 'EQMOrganizationNo',
  /** Aggregate result on EQMRentalInvoiceSequence */
  EqmRentalInvoiceSequence = 'EQMRentalInvoiceSequence',
  /** Aggregate result on EQMEnvironmentalFeeCode */
  EqmEnvironmentalFeeCode = 'EQMEnvironmentalFeeCode',
  /** Aggregate result on EQMNotEditable */
  EqmNotEditable = 'EQMNotEditable',
  /** Aggregate result on EQMPaidDeposits */
  EqmPaidDeposits = 'EQMPaidDeposits',
  /** Aggregate result on EQMPaidDepositsLCY */
  EqmPaidDepositsLcy = 'EQMPaidDepositsLCY',
  /** Aggregate result on EQMJobNo */
  EqmJobNo = 'EQMJobNo',
  /** Aggregate result on EQMJobTaskNo */
  EqmJobTaskNo = 'EQMJobTaskNo',
  /** Aggregate result on EQMInvoiceFee */
  EqmInvoiceFee = 'EQMInvoiceFee',
  /** Aggregate result on EQMInvoiceFeeOPTION */
  EqmInvoiceFeeOption = 'EQMInvoiceFeeOPTION',
  /** Aggregate result on EQMPrepaymentLCY */
  EqmPrepaymentLcy = 'EQMPrepaymentLCY',
  /** Aggregate result on EQMPrepaymentPeriod */
  EqmPrepaymentPeriod = 'EQMPrepaymentPeriod',
  /** Aggregate result on EQMMaxRentalValue */
  EqmMaxRentalValue = 'EQMMaxRentalValue',
  /** Aggregate result on EQMMaxGrantedDeposit */
  EqmMaxGrantedDeposit = 'EQMMaxGrantedDeposit',
  /** Aggregate result on EQMCatValidtoDate */
  EqmCatValidtoDate = 'EQMCatValidtoDate',
  /** Aggregate result on EQMNoofRentalQuotes */
  EqmNoofRentalQuotes = 'EQMNoofRentalQuotes',
  /** Aggregate result on EQMNoofOpenRntlContr */
  EqmNoofOpenRntlContr = 'EQMNoofOpenRntlContr',
  /** Aggregate result on EQMNoofOpenRentalLines */
  EqmNoofOpenRentalLines = 'EQMNoofOpenRentalLines',
  /** Aggregate result on EQMNoofRentalOrders */
  EqmNoofRentalOrders = 'EQMNoofRentalOrders',
  /** Aggregate result on EQMNoofRentalCreditMemos */
  EqmNoofRentalCreditMemos = 'EQMNoofRentalCreditMemos',
  /** Aggregate result on EQMNoofPstdRntlShpt */
  EqmNoofPstdRntlShpt = 'EQMNoofPstdRntlShpt',
  /** Aggregate result on EQMNoofPstdRntlInv */
  EqmNoofPstdRntlInv = 'EQMNoofPstdRntlInv',
  /** Aggregate result on EQMNoofPstdRntlCrM */
  EqmNoofPstdRntlCrM = 'EQMNoofPstdRntlCrM',
  /** Aggregate result on EQMNoofWorkshopQuotes */
  EqmNoofWorkshopQuotes = 'EQMNoofWorkshopQuotes',
  /** Aggregate result on EQMNoofWorkshopOrders */
  EqmNoofWorkshopOrders = 'EQMNoofWorkshopOrders',
  /** Aggregate result on EQMNoofWrkshpCrMemos */
  EqmNoofWrkshpCrMemos = 'EQMNoofWrkshpCrMemos',
  /** Aggregate result on EQMNoofPstdWrkshpShpt */
  EqmNoofPstdWrkshpShpt = 'EQMNoofPstdWrkshpShpt',
  /** Aggregate result on EQMNoofPstdWrkshpInv */
  EqmNoofPstdWrkshpInv = 'EQMNoofPstdWrkshpInv',
  /** Aggregate result on EQMNoofPstdWrkshpCrM */
  EqmNoofPstdWrkshpCrM = 'EQMNoofPstdWrkshpCrM',
  /** Aggregate result on EQMBillToNoofRntlQte */
  EqmBillToNoofRntlQte = 'EQMBillToNoofRntlQte',
  /** Aggregate result on EQMBillToNoofORContr */
  EqmBillToNoofOrContr = 'EQMBillToNoofORContr',
  /** Aggregate result on EQMBillToNoofRntlOrds */
  EqmBillToNoofRntlOrds = 'EQMBillToNoofRntlOrds',
  /** Aggregate result on EQMBillToNoofRCrM */
  EqmBillToNoofRCrM = 'EQMBillToNoofRCrM',
  /** Aggregate result on EQMBillToNoofPstdRS */
  EqmBillToNoofPstdRs = 'EQMBillToNoofPstdRS',
  /** Aggregate result on EQMBillToNoofPstdRI */
  EqmBillToNoofPstdRi = 'EQMBillToNoofPstdRI',
  /** Aggregate result on EQMBillToNoofPRCrM */
  EqmBillToNoofPrCrM = 'EQMBillToNoofPRCrM',
  /** Aggregate result on EQMBillToNoofWQte */
  EqmBillToNoofWQte = 'EQMBillToNoofWQte',
  /** Aggregate result on EQMBillToNoofWrkshpO */
  EqmBillToNoofWrkshpO = 'EQMBillToNoofWrkshpO',
  /** Aggregate result on EQMBillToNoofWCrM */
  EqmBillToNoofWCrM = 'EQMBillToNoofWCrM',
  /** Aggregate result on EQMBillToNoofPstdWS */
  EqmBillToNoofPstdWs = 'EQMBillToNoofPstdWS',
  /** Aggregate result on EQMBillToNoofPWInv */
  EqmBillToNoofPwInv = 'EQMBillToNoofPWInv',
  /** Aggregate result on EQMBillToNoofPWCrM */
  EqmBillToNoofPwCrM = 'EQMBillToNoofPWCrM',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMRentalInsuranceOptions */
  EqmRentalInsuranceOptions = 'EQMRentalInsuranceOptions',
  /** Aggregate result on EQMRentalInsuranceOptionsOPTION */
  EqmRentalInsuranceOptionsOption = 'EQMRentalInsuranceOptionsOPTION',
  /** Aggregate result on EQMPONoMandatory */
  EqmpoNoMandatory = 'EQMPONoMandatory',
  /** Aggregate result on EQMNoofRentPickOrders */
  EqmNoofRentPickOrders = 'EQMNoofRentPickOrders',
  /** Aggregate result on EQMNoofRentDeliverOrders */
  EqmNoofRentDeliverOrders = 'EQMNoofRentDeliverOrders',
  /** Aggregate result on EQMNoofRentCollOrders */
  EqmNoofRentCollOrders = 'EQMNoofRentCollOrders',
  /** Aggregate result on EQMNoofPstdPickOrders */
  EqmNoofPstdPickOrders = 'EQMNoofPstdPickOrders',
  /** Aggregate result on EQMNoofPstdDelOrders */
  EqmNoofPstdDelOrders = 'EQMNoofPstdDelOrders',
  /** Aggregate result on EQMNoofPstdCollOrders */
  EqmNoofPstdCollOrders = 'EQMNoofPstdCollOrders',
  /** Aggregate result on EQMStairPriceFilter */
  EqmStairPriceFilter = 'EQMStairPriceFilter',
  /** Aggregate result on EQMStairDiscountFilter */
  EqmStairDiscountFilter = 'EQMStairDiscountFilter',
  /** Aggregate result on HowtoInvoiceRentalContract */
  HowtoInvoiceRentalContract = 'HowtoInvoiceRentalContract',
  /** Aggregate result on HowtoInvoiceRentalContractOPTION */
  HowtoInvoiceRentalContractOption = 'HowtoInvoiceRentalContractOPTION',
  /** Aggregate result on EQMShortTermRental */
  EqmShortTermRental = 'EQMShortTermRental',
  /** Aggregate result on EQMCombineInvoiceSubProj */
  EqmCombineInvoiceSubProj = 'EQMCombineInvoiceSubProj',
  /** Aggregate result on EQMTermofContract */
  EqmTermofContract = 'EQMTermofContract',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateConsignmentHeaderInput {
  /** Aggregate result on TableID */
  TableId = 'TableID',
  /** Aggregate result on UniqueID */
  UniqueId = 'UniqueID',
  /** Aggregate result on ConsignmentNo */
  ConsignmentNo = 'ConsignmentNo',
  /** Aggregate result on ConsignmentType */
  ConsignmentType = 'ConsignmentType',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on DocumentTable */
  DocumentTable = 'DocumentTable',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on PickupName */
  PickupName = 'PickupName',
  /** Aggregate result on PickupAddress */
  PickupAddress = 'PickupAddress',
  /** Aggregate result on PickupAddress2 */
  PickupAddress2 = 'PickupAddress2',
  /** Aggregate result on PickupPostCode */
  PickupPostCode = 'PickupPostCode',
  /** Aggregate result on PickupCity */
  PickupCity = 'PickupCity',
  /** Aggregate result on PickupCounty */
  PickupCounty = 'PickupCounty',
  /** Aggregate result on PickupCountryRegionCode */
  PickupCountryRegionCode = 'PickupCountryRegionCode',
  /** Aggregate result on PickupContactNo */
  PickupContactNo = 'PickupContactNo',
  /** Aggregate result on PickupContactName */
  PickupContactName = 'PickupContactName',
  /** Aggregate result on PickupContactPhoneNo */
  PickupContactPhoneNo = 'PickupContactPhoneNo',
  /** Aggregate result on PickupContactMobPhoneNo */
  PickupContactMobPhoneNo = 'PickupContactMobPhoneNo',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on ShiptoContactNo */
  ShiptoContactNo = 'ShiptoContactNo',
  /** Aggregate result on ShiptoContactName */
  ShiptoContactName = 'ShiptoContactName',
  /** Aggregate result on ShiptoContactPhoneNo */
  ShiptoContactPhoneNo = 'ShiptoContactPhoneNo',
  /** Aggregate result on ShiptoContactMobPhoneNo */
  ShiptoContactMobPhoneNo = 'ShiptoContactMobPhoneNo',
  /** Aggregate result on EQMRentalContractNo */
  EqmRentalContractNo = 'EQMRentalContractNo',
  /** Aggregate result on EQMProjectNo */
  EqmProjectNo = 'EQMProjectNo',
  /** Aggregate result on EQMPickOrderNo */
  EqmPickOrderNo = 'EQMPickOrderNo',
  /** Aggregate result on EQMShipOrderNo */
  EqmShipOrderNo = 'EQMShipOrderNo',
  /** Aggregate result on ReturnOrder */
  ReturnOrder = 'ReturnOrder',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on TransportRequestSent */
  TransportRequestSent = 'TransportRequestSent',
  /** Aggregate result on TransportRequestSentBy */
  TransportRequestSentBy = 'TransportRequestSentBy',
  /** Aggregate result on TransportComment */
  TransportComment = 'TransportComment',
  /** Aggregate result on ReadyToFetchDate */
  ReadyToFetchDate = 'ReadyToFetchDate',
  /** Aggregate result on ReadyToFetchTime */
  ReadyToFetchTime = 'ReadyToFetchTime',
  /** Aggregate result on LatestAtRecieverDate */
  LatestAtRecieverDate = 'LatestAtRecieverDate',
  /** Aggregate result on LatestAtRecieverTime */
  LatestAtRecieverTime = 'LatestAtRecieverTime',
  /** Aggregate result on AtReceiverOnTime */
  AtReceiverOnTime = 'AtReceiverOnTime',
  /** Aggregate result on TotalEstimatedVolume */
  TotalEstimatedVolume = 'TotalEstimatedVolume',
  /** Aggregate result on TransportConfirmed */
  TransportConfirmed = 'TransportConfirmed',
  /** Aggregate result on TransportConfirmedBy */
  TransportConfirmedBy = 'TransportConfirmedBy',
  /** Aggregate result on TypeOfVehicle */
  TypeOfVehicle = 'TypeOfVehicle',
  /** Aggregate result on Freightisdebited */
  Freightisdebited = 'Freightisdebited',
  /** Aggregate result on EQMCollectionOrderNo */
  EqmCollectionOrderNo = 'EQMCollectionOrderNo',
  /** Aggregate result on TotalGrossWeight */
  TotalGrossWeight = 'TotalGrossWeight',
  /** Aggregate result on TotalNetWeight */
  TotalNetWeight = 'TotalNetWeight',
  /** Aggregate result on TotalVolume */
  TotalVolume = 'TotalVolume',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateConsignmentLineInput {
  /** Aggregate result on ConsignmentNo */
  ConsignmentNo = 'ConsignmentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on OriginalTableID */
  OriginalTableId = 'OriginalTableID',
  /** Aggregate result on OriginalUniqueID */
  OriginalUniqueId = 'OriginalUniqueID',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on TotalGrossWeight */
  TotalGrossWeight = 'TotalGrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on TotalNetWeight */
  TotalNetWeight = 'TotalNetWeight',
  /** Aggregate result on Volume */
  Volume = 'Volume',
  /** Aggregate result on TotalVolume */
  TotalVolume = 'TotalVolume',
  /** Aggregate result on TextBlob */
  TextBlob = 'TextBlob',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateContactInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on SearchName */
  SearchName = 'SearchName',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on TelexNo */
  TelexNo = 'TelexNo',
  /** Aggregate result on TerritoryCode */
  TerritoryCode = 'TerritoryCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on TelexAnswerBack */
  TelexAnswerBack = 'TelexAnswerBack',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on Image */
  Image = 'Image',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on CompanyNo */
  CompanyNo = 'CompanyNo',
  /** Aggregate result on CompanyName */
  CompanyName = 'CompanyName',
  /** Aggregate result on LookupContactNo */
  LookupContactNo = 'LookupContactNo',
  /** Aggregate result on FirstName */
  FirstName = 'FirstName',
  /** Aggregate result on MiddleName */
  MiddleName = 'MiddleName',
  /** Aggregate result on Surname */
  Surname = 'Surname',
  /** Aggregate result on JobTitle */
  JobTitle = 'JobTitle',
  /** Aggregate result on Initials */
  Initials = 'Initials',
  /** Aggregate result on ExtensionNo */
  ExtensionNo = 'ExtensionNo',
  /** Aggregate result on MobilePhoneNo */
  MobilePhoneNo = 'MobilePhoneNo',
  /** Aggregate result on Pager */
  Pager = 'Pager',
  /** Aggregate result on OrganizationalLevelCode */
  OrganizationalLevelCode = 'OrganizationalLevelCode',
  /** Aggregate result on ExcludefromSegment */
  ExcludefromSegment = 'ExcludefromSegment',
  /** Aggregate result on ExternalID */
  ExternalId = 'ExternalID',
  /** Aggregate result on CorrespondenceType */
  CorrespondenceType = 'CorrespondenceType',
  /** Aggregate result on SalutationCode */
  SalutationCode = 'SalutationCode',
  /** Aggregate result on SearchEMail */
  SearchEMail = 'SearchEMail',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on EMail2 */
  EMail2 = 'EMail2',
  /** Aggregate result on XrmId */
  XrmId = 'XrmId',
  /** Aggregate result on EQMEvaluationStatus */
  EqmEvaluationStatus = 'EQMEvaluationStatus',
  /** Aggregate result on EQMCheckListCode */
  EqmCheckListCode = 'EQMCheckListCode',
  /** Aggregate result on EQMCustomerCategory2 */
  EqmCustomerCategory2 = 'EQMCustomerCategory2',
  /** Aggregate result on EQMIdentificationNo */
  EqmIdentificationNo = 'EQMIdentificationNo',
  /** Aggregate result on EQMContactType */
  EqmContactType = 'EQMContactType',
  /** Aggregate result on EQMNotEditable */
  EqmNotEditable = 'EQMNotEditable',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on SalespersonFilter */
  SalespersonFilter = 'SalespersonFilter',
  /** Aggregate result on CampaignFilter */
  CampaignFilter = 'CampaignFilter',
  /** Aggregate result on ActionTakenFilter */
  ActionTakenFilter = 'ActionTakenFilter',
  /** Aggregate result on SalesCycleFilter */
  SalesCycleFilter = 'SalesCycleFilter',
  /** Aggregate result on SalesCycleStageFilter */
  SalesCycleStageFilter = 'SalesCycleStageFilter',
  /** Aggregate result on ProbabilityFilter */
  ProbabilityFilter = 'ProbabilityFilter',
  /** Aggregate result on CompletedFilter */
  CompletedFilter = 'CompletedFilter',
  /** Aggregate result on EstimatedValueFilter */
  EstimatedValueFilter = 'EstimatedValueFilter',
  /** Aggregate result on CalcdCurrentValueFilter */
  CalcdCurrentValueFilter = 'CalcdCurrentValueFilter',
  /** Aggregate result on ChancesofSuccessFilter */
  ChancesofSuccessFilter = 'ChancesofSuccessFilter',
  /** Aggregate result on TaskStatusFilter */
  TaskStatusFilter = 'TaskStatusFilter',
  /** Aggregate result on TaskClosedFilter */
  TaskClosedFilter = 'TaskClosedFilter',
  /** Aggregate result on PriorityFilter */
  PriorityFilter = 'PriorityFilter',
  /** Aggregate result on TeamFilter */
  TeamFilter = 'TeamFilter',
  /** Aggregate result on CloseOpportunityFilter */
  CloseOpportunityFilter = 'CloseOpportunityFilter',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on NextTaskDate */
  NextTaskDate = 'NextTaskDate',
  /** Aggregate result on LastDateAttempted */
  LastDateAttempted = 'LastDateAttempted',
  /** Aggregate result on DateofLastInteraction */
  DateofLastInteraction = 'DateofLastInteraction',
  /** Aggregate result on NoofJobResponsibilities */
  NoofJobResponsibilities = 'NoofJobResponsibilities',
  /** Aggregate result on NoofIndustryGroups */
  NoofIndustryGroups = 'NoofIndustryGroups',
  /** Aggregate result on NoofBusinessRelations */
  NoofBusinessRelations = 'NoofBusinessRelations',
  /** Aggregate result on NoofMailingGroups */
  NoofMailingGroups = 'NoofMailingGroups',
  /** Aggregate result on NoofInteractions */
  NoofInteractions = 'NoofInteractions',
  /** Aggregate result on CostLCY */
  CostLcy = 'CostLCY',
  /** Aggregate result on DurationMin */
  DurationMin = 'DurationMin',
  /** Aggregate result on NoofOpportunities */
  NoofOpportunities = 'NoofOpportunities',
  /** Aggregate result on EstimatedValueLCY */
  EstimatedValueLcy = 'EstimatedValueLCY',
  /** Aggregate result on CalcdCurrentValueLCY */
  CalcdCurrentValueLcy = 'CalcdCurrentValueLCY',
  /** Aggregate result on OpportunityEntryExists */
  OpportunityEntryExists = 'OpportunityEntryExists',
  /** Aggregate result on TaskEntryExists */
  TaskEntryExists = 'TaskEntryExists',
  /** Aggregate result on LinkedToCustomerNo */
  LinkedToCustomerNo = 'LinkedToCustomerNo',
  /** Aggregate result on Lead */
  Lead = 'Lead',
  /** Aggregate result on LinkedTo */
  LinkedTo = 'LinkedTo',
  /** Aggregate result on LastCustomerLedgerDate */
  LastCustomerLedgerDate = 'LastCustomerLedgerDate',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCountryRegionInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on ISOCode */
  IsoCode = 'ISOCode',
  /** Aggregate result on ISONumericCode */
  IsoNumericCode = 'ISONumericCode',
  /** Aggregate result on EUCountryRegionCode */
  EuCountryRegionCode = 'EUCountryRegionCode',
  /** Aggregate result on IntrastatCode */
  IntrastatCode = 'IntrastatCode',
  /** Aggregate result on AddressFormat */
  AddressFormat = 'AddressFormat',
  /** Aggregate result on AddressFormatOPTION */
  AddressFormatOption = 'AddressFormatOPTION',
  /** Aggregate result on ContactAddressFormat */
  ContactAddressFormat = 'ContactAddressFormat',
  /** Aggregate result on ContactAddressFormatOPTION */
  ContactAddressFormatOption = 'ContactAddressFormatOPTION',
  /** Aggregate result on VATScheme */
  VatScheme = 'VATScheme',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on CountyName */
  CountyName = 'CountyName',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCurrencyExchangeRateInput {
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on StartingDate */
  StartingDate = 'StartingDate',
  /** Aggregate result on ExchangeRateAmount */
  ExchangeRateAmount = 'ExchangeRateAmount',
  /** Aggregate result on AdjustmentExchRateAmount */
  AdjustmentExchRateAmount = 'AdjustmentExchRateAmount',
  /** Aggregate result on RelationalCurrencyCode */
  RelationalCurrencyCode = 'RelationalCurrencyCode',
  /** Aggregate result on RelationalExchRateAmount */
  RelationalExchRateAmount = 'RelationalExchRateAmount',
  /** Aggregate result on FixExchRateAmount */
  FixExchRateAmount = 'FixExchRateAmount',
  /** Aggregate result on FixExchRateAmountOPTION */
  FixExchRateAmountOption = 'FixExchRateAmountOPTION',
  /** Aggregate result on RelationalAdjmtExchRateAmt */
  RelationalAdjmtExchRateAmt = 'RelationalAdjmtExchRateAmt',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCurrencyInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastDateAdjusted */
  LastDateAdjusted = 'LastDateAdjusted',
  /** Aggregate result on ISOCode */
  IsoCode = 'ISOCode',
  /** Aggregate result on ISONumericCode */
  IsoNumericCode = 'ISONumericCode',
  /** Aggregate result on UnrealizedGainsAcc */
  UnrealizedGainsAcc = 'UnrealizedGainsAcc',
  /** Aggregate result on RealizedGainsAcc */
  RealizedGainsAcc = 'RealizedGainsAcc',
  /** Aggregate result on UnrealizedLossesAcc */
  UnrealizedLossesAcc = 'UnrealizedLossesAcc',
  /** Aggregate result on RealizedLossesAcc */
  RealizedLossesAcc = 'RealizedLossesAcc',
  /** Aggregate result on InvoiceRoundingPrecision */
  InvoiceRoundingPrecision = 'InvoiceRoundingPrecision',
  /** Aggregate result on InvoiceRoundingType */
  InvoiceRoundingType = 'InvoiceRoundingType',
  /** Aggregate result on InvoiceRoundingTypeOPTION */
  InvoiceRoundingTypeOption = 'InvoiceRoundingTypeOPTION',
  /** Aggregate result on AmountRoundingPrecision */
  AmountRoundingPrecision = 'AmountRoundingPrecision',
  /** Aggregate result on UnitAmountRoundingPrecision */
  UnitAmountRoundingPrecision = 'UnitAmountRoundingPrecision',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on AmountDecimalPlaces */
  AmountDecimalPlaces = 'AmountDecimalPlaces',
  /** Aggregate result on UnitAmountDecimalPlaces */
  UnitAmountDecimalPlaces = 'UnitAmountDecimalPlaces',
  /** Aggregate result on CustomerFilter */
  CustomerFilter = 'CustomerFilter',
  /** Aggregate result on VendorFilter */
  VendorFilter = 'VendorFilter',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on CustLedgEntriesinFilter */
  CustLedgEntriesinFilter = 'CustLedgEntriesinFilter',
  /** Aggregate result on CustomerBalance */
  CustomerBalance = 'CustomerBalance',
  /** Aggregate result on CustomerOutstandingOrders */
  CustomerOutstandingOrders = 'CustomerOutstandingOrders',
  /** Aggregate result on CustomerShippedNotInvoiced */
  CustomerShippedNotInvoiced = 'CustomerShippedNotInvoiced',
  /** Aggregate result on CustomerBalanceDue */
  CustomerBalanceDue = 'CustomerBalanceDue',
  /** Aggregate result on VendorLedgEntriesinFilter */
  VendorLedgEntriesinFilter = 'VendorLedgEntriesinFilter',
  /** Aggregate result on VendorBalance */
  VendorBalance = 'VendorBalance',
  /** Aggregate result on VendorOutstandingOrders */
  VendorOutstandingOrders = 'VendorOutstandingOrders',
  /** Aggregate result on VendorAmtRcdNotInvoiced */
  VendorAmtRcdNotInvoiced = 'VendorAmtRcdNotInvoiced',
  /** Aggregate result on VendorBalanceDue */
  VendorBalanceDue = 'VendorBalanceDue',
  /** Aggregate result on CustomerBalanceLCY */
  CustomerBalanceLcy = 'CustomerBalanceLCY',
  /** Aggregate result on VendorBalanceLCY */
  VendorBalanceLcy = 'VendorBalanceLCY',
  /** Aggregate result on RealizedGLGainsAccount */
  RealizedGlGainsAccount = 'RealizedGLGainsAccount',
  /** Aggregate result on RealizedGLLossesAccount */
  RealizedGlLossesAccount = 'RealizedGLLossesAccount',
  /** Aggregate result on ApplnRoundingPrecision */
  ApplnRoundingPrecision = 'ApplnRoundingPrecision',
  /** Aggregate result on EMUCurrency */
  EmuCurrency = 'EMUCurrency',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on ResidualGainsAccount */
  ResidualGainsAccount = 'ResidualGainsAccount',
  /** Aggregate result on ResidualLossesAccount */
  ResidualLossesAccount = 'ResidualLossesAccount',
  /** Aggregate result on ConvLCYRndgDebitAcc */
  ConvLcyRndgDebitAcc = 'ConvLCYRndgDebitAcc',
  /** Aggregate result on ConvLCYRndgCreditAcc */
  ConvLcyRndgCreditAcc = 'ConvLCYRndgCreditAcc',
  /** Aggregate result on MaxVATDifferenceAllowed */
  MaxVatDifferenceAllowed = 'MaxVATDifferenceAllowed',
  /** Aggregate result on VATRoundingType */
  VatRoundingType = 'VATRoundingType',
  /** Aggregate result on VATRoundingTypeOPTION */
  VatRoundingTypeOption = 'VATRoundingTypeOPTION',
  /** Aggregate result on PaymentTolerancePercent */
  PaymentTolerancePercent = 'PaymentTolerancePercent',
  /** Aggregate result on MaxPaymentToleranceAmount */
  MaxPaymentToleranceAmount = 'MaxPaymentToleranceAmount',
  /** Aggregate result on Symbol */
  Symbol = 'Symbol',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on PEBNoofForwardAgreement */
  PebNoofForwardAgreement = 'PEBNoofForwardAgreement',
  /** Aggregate result on PEBCountryCodeISOAccNo */
  PebCountryCodeIsoAccNo = 'PEBCountryCodeISOAccNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCustomerDomainInput {
  /** Aggregate result on RegistrationNo */
  RegistrationNo = 'RegistrationNo',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on AdminUserGlobalId */
  AdminUserGlobalId = 'AdminUserGlobalId',
  /** Aggregate result on EmailDomain */
  EmailDomain = 'EmailDomain',
  /** Aggregate result on ImageURL */
  ImageUrl = 'ImageURL',
  /** Aggregate result on DomainImageBase64 */
  DomainImageBase64 = 'DomainImageBase64',
  /** Aggregate result on DomainImageBase64Name */
  DomainImageBase64Name = 'DomainImageBase64Name',
  /** Aggregate result on DomainMainOrientation */
  DomainMainOrientation = 'DomainMainOrientation',
  /** Aggregate result on CreatedOn */
  CreatedOn = 'CreatedOn',
  /** Aggregate result on VerifiedOn */
  VerifiedOn = 'VerifiedOn',
  /** Aggregate result on VerifiedByUserEmail */
  VerifiedByUserEmail = 'VerifiedByUserEmail',
  /** Aggregate result on VerifiedByUserName */
  VerifiedByUserName = 'VerifiedByUserName',
  /** Aggregate result on DomainIds */
  DomainIds = 'DomainIds',
  /** Aggregate result on TrialStartedOn */
  TrialStartedOn = 'TrialStartedOn',
  /** Aggregate result on TrialEndsOn */
  TrialEndsOn = 'TrialEndsOn',
  /** Aggregate result on TrialLengthDays */
  TrialLengthDays = 'TrialLengthDays',
  /** Aggregate result on TrailActive */
  TrailActive = 'TrailActive',
  /** Aggregate result on InvoiceAddress */
  InvoiceAddress = 'InvoiceAddress',
  /** Aggregate result on InvoicePostalCode */
  InvoicePostalCode = 'InvoicePostalCode',
  /** Aggregate result on InvoiceCity */
  InvoiceCity = 'InvoiceCity',
  /** Aggregate result on InvoiceNote */
  InvoiceNote = 'InvoiceNote',
  /** Aggregate result on InvoiceEmail */
  InvoiceEmail = 'InvoiceEmail',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCustomerPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on ReceivablesAccount */
  ReceivablesAccount = 'ReceivablesAccount',
  /** Aggregate result on ServiceChargeAcc */
  ServiceChargeAcc = 'ServiceChargeAcc',
  /** Aggregate result on PaymentDiscDebitAcc */
  PaymentDiscDebitAcc = 'PaymentDiscDebitAcc',
  /** Aggregate result on InvoiceRoundingAccount */
  InvoiceRoundingAccount = 'InvoiceRoundingAccount',
  /** Aggregate result on AdditionalFeeAccount */
  AdditionalFeeAccount = 'AdditionalFeeAccount',
  /** Aggregate result on InterestAccount */
  InterestAccount = 'InterestAccount',
  /** Aggregate result on DebitCurrApplnRndgAcc */
  DebitCurrApplnRndgAcc = 'DebitCurrApplnRndgAcc',
  /** Aggregate result on CreditCurrApplnRndgAcc */
  CreditCurrApplnRndgAcc = 'CreditCurrApplnRndgAcc',
  /** Aggregate result on DebitRoundingAccount */
  DebitRoundingAccount = 'DebitRoundingAccount',
  /** Aggregate result on CreditRoundingAccount */
  CreditRoundingAccount = 'CreditRoundingAccount',
  /** Aggregate result on PaymentDiscCreditAcc */
  PaymentDiscCreditAcc = 'PaymentDiscCreditAcc',
  /** Aggregate result on PaymentToleranceDebitAcc */
  PaymentToleranceDebitAcc = 'PaymentToleranceDebitAcc',
  /** Aggregate result on PaymentToleranceCreditAcc */
  PaymentToleranceCreditAcc = 'PaymentToleranceCreditAcc',
  /** Aggregate result on AddFeeperLineAccount */
  AddFeeperLineAccount = 'AddFeeperLineAccount',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ViewAllAccountsonLookup */
  ViewAllAccountsonLookup = 'ViewAllAccountsonLookup',
  /** Aggregate result on EQMDepositReceiveablesAcc */
  EqmDepositReceiveablesAcc = 'EQMDepositReceiveablesAcc',
  /** Aggregate result on EQMDepositAccount */
  EqmDepositAccount = 'EQMDepositAccount',
  /** Aggregate result on EQMPrepaymentReceivableAcc */
  EqmPrepaymentReceivableAcc = 'EQMPrepaymentReceivableAcc',
  /** Aggregate result on EQMPrepaymentAccount */
  EqmPrepaymentAccount = 'EQMPrepaymentAccount',
  /** Aggregate result on EQMBankTransferReceiveables */
  EqmBankTransferReceiveables = 'EQMBankTransferReceiveables',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCustomerProjectInput {
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on ContactNo */
  ContactNo = 'ContactNo',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on StatusChangedBy */
  StatusChangedBy = 'StatusChangedBy',
  /** Aggregate result on CustomerProjectDimension */
  CustomerProjectDimension = 'CustomerProjectDimension',
  /** Aggregate result on DimensionConstructProject */
  DimensionConstructProject = 'DimensionConstructProject',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on CombineRentalInvoice */
  CombineRentalInvoice = 'CombineRentalInvoice',
  /** Aggregate result on CombineMethod */
  CombineMethod = 'CombineMethod',
  /** Aggregate result on CombineInvoiceSubProject */
  CombineInvoiceSubProject = 'CombineInvoiceSubProject',
  /** Aggregate result on SubProjectMandatory */
  SubProjectMandatory = 'SubProjectMandatory',
  /** Aggregate result on CustomerRentalPriceGroup */
  CustomerRentalPriceGroup = 'CustomerRentalPriceGroup',
  /** Aggregate result on CustomerRentPriceGroup */
  CustomerRentPriceGroup = 'CustomerRentPriceGroup',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on CustomerSalesPriceGroup */
  CustomerSalesPriceGroup = 'CustomerSalesPriceGroup',
  /** Aggregate result on CustomerSalesDiscGroup */
  CustomerSalesDiscGroup = 'CustomerSalesDiscGroup',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on InsuranceCode */
  InsuranceCode = 'InsuranceCode',
  /** Aggregate result on CustomerRentalDiscGroup */
  CustomerRentalDiscGroup = 'CustomerRentalDiscGroup',
  /** Aggregate result on CombineInvperRespCenter */
  CombineInvperRespCenter = 'CombineInvperRespCenter',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ServiceZoneCode */
  ServiceZoneCode = 'ServiceZoneCode',
  /** Aggregate result on Incentive */
  Incentive = 'Incentive',
  /** Aggregate result on ReturnConfirmationperEMail */
  ReturnConfirmationperEMail = 'ReturnConfirmationperEMail',
  /** Aggregate result on Inactive */
  Inactive = 'Inactive',
  /** Aggregate result on ExpectedTurnover */
  ExpectedTurnover = 'ExpectedTurnover',
  /** Aggregate result on ShiptoInstruction */
  ShiptoInstruction = 'ShiptoInstruction',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on FromDate */
  FromDate = 'FromDate',
  /** Aggregate result on ToDate */
  ToDate = 'ToDate',
  /** Aggregate result on LockPrices */
  LockPrices = 'LockPrices',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on EquipmentListperEMail */
  EquipmentListperEMail = 'EquipmentListperEMail',
  /** Aggregate result on EmailEquipmentList */
  EmailEquipmentList = 'EmailEquipmentList',
  /** Aggregate result on EmailFrequency */
  EmailFrequency = 'EmailFrequency',
  /** Aggregate result on InternalComment */
  InternalComment = 'InternalComment',
  /** Aggregate result on PopUp */
  PopUp = 'PopUp',
  /** Aggregate result on InactivateNoSaleMail */
  InactivateNoSaleMail = 'InactivateNoSaleMail',
  /** Aggregate result on ContactName */
  ContactName = 'ContactName',
  /** Aggregate result on ContactPhoneNo */
  ContactPhoneNo = 'ContactPhoneNo',
  /** Aggregate result on CustomerProjectRegistrationNo */
  CustomerProjectRegistrationNo = 'CustomerProjectRegistrationNo',
  /** Aggregate result on Deactivated */
  Deactivated = 'Deactivated',
  /** Aggregate result on Finished */
  Finished = 'Finished',
  /** Aggregate result on LocationManagerContact */
  LocationManagerContact = 'LocationManagerContact',
  /** Aggregate result on ProjectManager */
  ProjectManager = 'ProjectManager',
  /** Aggregate result on Deliverycontact */
  Deliverycontact = 'Deliverycontact',
  /** Aggregate result on ProjectManagerEmail */
  ProjectManagerEmail = 'ProjectManagerEmail',
  /** Aggregate result on DeliveryContactEmail */
  DeliveryContactEmail = 'DeliveryContactEmail',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on LocationId */
  LocationId = 'LocationId',
  /** Aggregate result on ContactGraphId */
  ContactGraphId = 'ContactGraphId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on ExternallyCreated */
  ExternallyCreated = 'ExternallyCreated',
  /** Aggregate result on IsUsingDomainIds */
  IsUsingDomainIds = 'IsUsingDomainIds',
  /** Aggregate result on RentIsActive */
  RentIsActive = 'RentIsActive',
  /** Aggregate result on IsPublicRealbridge */
  IsPublicRealbridge = 'IsPublicRealbridge',
  /** Aggregate result on SendToEquipmentLoop */
  SendToEquipmentLoop = 'SendToEquipmentLoop',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateCustomerSubProjectInput {
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on ContactNo */
  ContactNo = 'ContactNo',
  /** Aggregate result on ContactName */
  ContactName = 'ContactName',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on SubProjectNo */
  SubProjectNo = 'SubProjectNo',
  /** Aggregate result on CustomerProjectDimension */
  CustomerProjectDimension = 'CustomerProjectDimension',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on CombineRentalInvoice */
  CombineRentalInvoice = 'CombineRentalInvoice',
  /** Aggregate result on CombineMethod */
  CombineMethod = 'CombineMethod',
  /** Aggregate result on CombineMethodOPTION */
  CombineMethodOption = 'CombineMethodOPTION',
  /** Aggregate result on CustomerRentalPriceGroup */
  CustomerRentalPriceGroup = 'CustomerRentalPriceGroup',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on CustomerSalesPriceGroup */
  CustomerSalesPriceGroup = 'CustomerSalesPriceGroup',
  /** Aggregate result on CustomerSalesDiscGroup */
  CustomerSalesDiscGroup = 'CustomerSalesDiscGroup',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on InsuranceCode */
  InsuranceCode = 'InsuranceCode',
  /** Aggregate result on CustomerRentalDiscGroup */
  CustomerRentalDiscGroup = 'CustomerRentalDiscGroup',
  /** Aggregate result on CombineInvperRespCenter */
  CombineInvperRespCenter = 'CombineInvperRespCenter',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ServiceZoneCode */
  ServiceZoneCode = 'ServiceZoneCode',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on LocationId */
  LocationId = 'LocationId',
  /** Aggregate result on ContactGraphId */
  ContactGraphId = 'ContactGraphId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDeliverytimeInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on MagentoAttribOptionId */
  MagentoAttribOptionId = 'MagentoAttribOptionId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDevopsProjectInput {
  /** Aggregate result on name */
  Name = 'name',
  /** Aggregate result on url */
  Url = 'url',
  /** Aggregate result on state */
  State = 'state',
  /** Aggregate result on revision */
  Revision = 'revision',
  /** Aggregate result on visibility */
  Visibility = 'visibility',
  /** Aggregate result on lastUpdateTime */
  LastUpdateTime = 'lastUpdateTime',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDevopsTeamInput {
  /** Aggregate result on name */
  Name = 'name',
  /** Aggregate result on path */
  Path = 'path',
  /** Aggregate result on isFolder */
  IsFolder = 'isFolder',
  /** Aggregate result on hasChildren */
  HasChildren = 'hasChildren',
  /** Aggregate result on children */
  Children = 'children',
  /** Aggregate result on isPublic */
  IsPublic = 'isPublic',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDevopsWorkItemInput {
  /** Aggregate result on rev */
  Rev = 'rev',
  /** Aggregate result on fields */
  Fields = 'fields',
  /** Aggregate result on _links */
  Links = '_links',
  /** Aggregate result on url */
  Url = 'url',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDimensionInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on CodeCaption */
  CodeCaption = 'CodeCaption',
  /** Aggregate result on FilterCaption */
  FilterCaption = 'FilterCaption',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on ConsolidationCode */
  ConsolidationCode = 'ConsolidationCode',
  /** Aggregate result on MaptoICDimensionCode */
  MaptoIcDimensionCode = 'MaptoICDimensionCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDimensionValueInput {
  /** Aggregate result on DimensionCode */
  DimensionCode = 'DimensionCode',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on DimensionValueType */
  DimensionValueType = 'DimensionValueType',
  /** Aggregate result on DimensionValueTypeOPTION */
  DimensionValueTypeOption = 'DimensionValueTypeOPTION',
  /** Aggregate result on Totaling */
  Totaling = 'Totaling',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on ConsolidationCode */
  ConsolidationCode = 'ConsolidationCode',
  /** Aggregate result on Indentation */
  Indentation = 'Indentation',
  /** Aggregate result on GlobalDimensionNo */
  GlobalDimensionNo = 'GlobalDimensionNo',
  /** Aggregate result on MaptoICDimensionCode */
  MaptoIcDimensionCode = 'MaptoICDimensionCode',
  /** Aggregate result on MaptoICDimensionValueCode */
  MaptoIcDimensionValueCode = 'MaptoICDimensionValueCode',
  /** Aggregate result on DimensionValueID */
  DimensionValueId = 'DimensionValueID',
  /** Aggregate result on EQMCostCenterDimension */
  EqmCostCenterDimension = 'EQMCostCenterDimension',
  /** Aggregate result on EQMObjectDimension */
  EqmObjectDimension = 'EQMObjectDimension',
  /** Aggregate result on EQMObjectTypeDimension */
  EqmObjectTypeDimension = 'EQMObjectTypeDimension',
  /** Aggregate result on EQMObjectGroupDimension */
  EqmObjectGroupDimension = 'EQMObjectGroupDimension',
  /** Aggregate result on EQMRelatedSalesCostDim */
  EqmRelatedSalesCostDim = 'EQMRelatedSalesCostDim',
  /** Aggregate result on EQMRentalContractDimension */
  EqmRentalContractDimension = 'EQMRentalContractDimension',
  /** Aggregate result on EQMCustomerDimension */
  EqmCustomerDimension = 'EQMCustomerDimension',
  /** Aggregate result on EQMCustomerProjectDimension */
  EqmCustomerProjectDimension = 'EQMCustomerProjectDimension',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDiscussionPostInput {
  /** Aggregate result on FromEmail */
  FromEmail = 'FromEmail',
  /** Aggregate result on FromName */
  FromName = 'FromName',
  /** Aggregate result on CreatedOn */
  CreatedOn = 'CreatedOn',
  /** Aggregate result on Text */
  Text = 'Text',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on Tags */
  Tags = 'Tags',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on IsFromCustomer */
  IsFromCustomer = 'IsFromCustomer',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDocumentClassificationInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDocumentInput {
  /** Aggregate result on _SignInitiatingUserId */
  SignInitiatingUserId = '_SignInitiatingUserId',
  /** Aggregate result on SignInitiatingUserName */
  SignInitiatingUserName = 'SignInitiatingUserName',
  /** Aggregate result on SignInitiatingUserGlobalId */
  SignInitiatingUserGlobalId = 'SignInitiatingUserGlobalId',
  /** Aggregate result on SignRequestSentToUserName */
  SignRequestSentToUserName = 'SignRequestSentToUserName',
  /** Aggregate result on SignRequestSentToUserEmail */
  SignRequestSentToUserEmail = 'SignRequestSentToUserEmail',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on GUID */
  Guid = 'GUID',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on RecName */
  RecName = 'RecName',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on DocumentSubType */
  DocumentSubType = 'DocumentSubType',
  /** Aggregate result on DocumentSubTypeOPTION */
  DocumentSubTypeOption = 'DocumentSubTypeOPTION',
  /** Aggregate result on Signed */
  Signed = 'Signed',
  /** Aggregate result on SignedByUserGlobalId */
  SignedByUserGlobalId = 'SignedByUserGlobalId',
  /** Aggregate result on SignedMethod */
  SignedMethod = 'SignedMethod',
  /** Aggregate result on SignedOn */
  SignedOn = 'SignedOn',
  /** Aggregate result on OriginalFileName */
  OriginalFileName = 'OriginalFileName',
  /** Aggregate result on SignedFileName */
  SignedFileName = 'SignedFileName',
  /** Aggregate result on SignedPrintedName */
  SignedPrintedName = 'SignedPrintedName',
  /** Aggregate result on DocumentETag */
  DocumentETag = 'DocumentETag',
  /** Aggregate result on Base64WriteOnly */
  Base64WriteOnly = 'Base64WriteOnly',
  /** Aggregate result on Classification */
  Classification = 'Classification',
  /** Aggregate result on Created */
  Created = 'Created',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDocumentRequestInput {
  /** Aggregate result on GUID */
  Guid = 'GUID',
  /** Aggregate result on RecName */
  RecName = 'RecName',
  /** Aggregate result on TargetID */
  TargetId = 'TargetID',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on DocumentSubType */
  DocumentSubType = 'DocumentSubType',
  /** Aggregate result on DocumentSubTypeOPTION */
  DocumentSubTypeOption = 'DocumentSubTypeOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDomainInput {
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ImgURL */
  ImgUrl = 'ImgURL',
  /** Aggregate result on AppBackgroundImgURL */
  AppBackgroundImgUrl = 'AppBackgroundImgURL',
  /** Aggregate result on AdminUserEmail */
  AdminUserEmail = 'AdminUserEmail',
  /** Aggregate result on EmailDomain */
  EmailDomain = 'EmailDomain',
  /** Aggregate result on IsDemo */
  IsDemo = 'IsDemo',
  /** Aggregate result on EnableObjectsLimited */
  EnableObjectsLimited = 'EnableObjectsLimited',
  /** Aggregate result on EnableParkingSpotsLimited */
  EnableParkingSpotsLimited = 'EnableParkingSpotsLimited',
  /** Aggregate result on ImageEtag */
  ImageEtag = 'ImageEtag',
  /** Aggregate result on ImageDocGlobalId */
  ImageDocGlobalId = 'ImageDocGlobalId',
  /** Aggregate result on ImageURL */
  ImageUrl = 'ImageURL',
  /** Aggregate result on DomainImageBase64 */
  DomainImageBase64 = 'DomainImageBase64',
  /** Aggregate result on DomainImageBase64Name */
  DomainImageBase64Name = 'DomainImageBase64Name',
  /** Aggregate result on DomainMainOrientation */
  DomainMainOrientation = 'DomainMainOrientation',
  /** Aggregate result on WebhookURL */
  WebhookUrl = 'WebhookURL',
  /** Aggregate result on ParentDomainId */
  ParentDomainId = 'ParentDomainId',
  /** Aggregate result on AlternativeName */
  AlternativeName = 'AlternativeName',
  /** Aggregate result on FlagImageUrl */
  FlagImageUrl = 'FlagImageUrl',
  /** Aggregate result on VisibleInSafetyRespect */
  VisibleInSafetyRespect = 'VisibleInSafetyRespect',
  /** Aggregate result on ReservationsEnabled */
  ReservationsEnabled = 'ReservationsEnabled',
  /** Aggregate result on ReturnReservationsEnabled */
  ReturnReservationsEnabled = 'ReturnReservationsEnabled',
  /** Aggregate result on SendToEquipmentLoop */
  SendToEquipmentLoop = 'SendToEquipmentLoop',
  /** Aggregate result on primaryColors */
  PrimaryColors = 'primaryColors',
  /** Aggregate result on secondaryColors */
  SecondaryColors = 'secondaryColors',
  /** Aggregate result on other */
  Other = 'other',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDomainIntegrationInput {
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Url */
  Url = 'Url',
  /** Aggregate result on Username */
  Username = 'Username',
  /** Aggregate result on Password */
  Password = 'Password',
  /** Aggregate result on AuthType */
  AuthType = 'AuthType',
  /** Aggregate result on client_id */
  ClientId = 'client_id',
  /** Aggregate result on client_secret */
  ClientSecret = 'client_secret',
  /** Aggregate result on grant_type */
  GrantType = 'grant_type',
  /** Aggregate result on commonAuthority */
  CommonAuthority = 'commonAuthority',
  /** Aggregate result on tenant */
  Tenant = 'tenant',
  /** Aggregate result on ConnectionType */
  ConnectionType = 'ConnectionType',
  /** Aggregate result on resource */
  Resource = 'resource',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on SubType */
  SubType = 'SubType',
  /** Aggregate result on OrganizationId */
  OrganizationId = 'OrganizationId',
  /** Aggregate result on Active */
  Active = 'Active',
  /** Aggregate result on MetaDataJson */
  MetaDataJson = 'MetaDataJson',
  /** Aggregate result on DisableOrderPolling */
  DisableOrderPolling = 'DisableOrderPolling',
  /** Aggregate result on PreviousMagentoOrderId */
  PreviousMagentoOrderId = 'PreviousMagentoOrderId',
  /** Aggregate result on PreviousDekraPollingDateTime */
  PreviousDekraPollingDateTime = 'PreviousDekraPollingDateTime',
  /** Aggregate result on SchemaVersion */
  SchemaVersion = 'SchemaVersion',
  /** Aggregate result on Online */
  Online = 'Online',
  /** Aggregate result on AutoSyncUnsentRecords */
  AutoSyncUnsentRecords = 'AutoSyncUnsentRecords',
  /** Aggregate result on LatestHeartBeat */
  LatestHeartBeat = 'LatestHeartBeat',
  /** Aggregate result on FirstHeartBeat */
  FirstHeartBeat = 'FirstHeartBeat',
  /** Aggregate result on HeartBeartIntervalMinutes */
  HeartBeartIntervalMinutes = 'HeartBeartIntervalMinutes',
  /** Aggregate result on NoOfReqsInOutboundQueue */
  NoOfReqsInOutboundQueue = 'NoOfReqsInOutboundQueue',
  /** Aggregate result on NoOfReqsInInboundQueue */
  NoOfReqsInInboundQueue = 'NoOfReqsInInboundQueue',
  /** Aggregate result on NoOfUnprocessableItems */
  NoOfUnprocessableItems = 'NoOfUnprocessableItems',
  /** Aggregate result on HeartbeatMonitoringEnabled */
  HeartbeatMonitoringEnabled = 'HeartbeatMonitoringEnabled',
  /** Aggregate result on OAuth2_URL */
  OAuth2Url = 'OAuth2_URL',
  /** Aggregate result on OAuth2_Grant_Type */
  OAuth2GrantType = 'OAuth2_Grant_Type',
  /** Aggregate result on OAuth2_Scope */
  OAuth2Scope = 'OAuth2_Scope',
  /** Aggregate result on OAuth2_Client_Id */
  OAuth2ClientId = 'OAuth2_Client_Id',
  /** Aggregate result on OAuth2_Client_Secret */
  OAuth2ClientSecret = 'OAuth2_Client_Secret',
  /** Aggregate result on OAuth2_Redirect_URL */
  OAuth2RedirectUrl = 'OAuth2_Redirect_URL',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateDomainSettingsInput {
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Value */
  Value = 'Value',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmBaseCalendarChangeInput {
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on RecurringSystem */
  RecurringSystem = 'RecurringSystem',
  /** Aggregate result on RecurringSystemOPTION */
  RecurringSystemOption = 'RecurringSystemOPTION',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on Day */
  Day = 'Day',
  /** Aggregate result on DayOPTION */
  DayOption = 'DayOPTION',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Nonworking */
  Nonworking = 'Nonworking',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmBaseCalendarInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on CustomizedChangesExist */
  CustomizedChangesExist = 'CustomizedChangesExist',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmCollectionReturnChargesInput {
  /** Aggregate result on ExtDocumentNo */
  ExtDocumentNo = 'ExtDocumentNo',
  /** Aggregate result on ExtLineNo */
  ExtLineNo = 'ExtLineNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Unitprice */
  Unitprice = 'Unitprice',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on ReqAction */
  ReqAction = 'ReqAction',
  /** Aggregate result on ReqApproved */
  ReqApproved = 'ReqApproved',
  /** Aggregate result on ApprovedBy */
  ApprovedBy = 'ApprovedBy',
  /** Aggregate result on UseatFunctionalTest */
  UseatFunctionalTest = 'UseatFunctionalTest',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on FunctionalTestHeaderNo */
  FunctionalTestHeaderNo = 'FunctionalTestHeaderNo',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on NBAESMotherMarkedForReturn */
  NbaesMotherMarkedForReturn = 'NBAESMotherMarkedForReturn',
  /** Aggregate result on NBAESObjectNo */
  NbaesObjectNo = 'NBAESObjectNo',
  /** Aggregate result on NBAESObjectDescription */
  NbaesObjectDescription = 'NBAESObjectDescription',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmCommentLineInput {
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on TableLineNo */
  TableLineNo = 'TableLineNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on TableSubtype */
  TableSubtype = 'TableSubtype',
  /** Aggregate result on TableSubtypeOPTION */
  TableSubtypeOption = 'TableSubtypeOPTION',
  /** Aggregate result on TableName */
  TableName = 'TableName',
  /** Aggregate result on TableNameOPTION */
  TableNameOption = 'TableNameOPTION',
  /** Aggregate result on LinkedtoObjectNo */
  LinkedtoObjectNo = 'LinkedtoObjectNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmCustomizedCalendarChangeInput {
  /** Aggregate result on SourceType */
  SourceType = 'SourceType',
  /** Aggregate result on SourceTypeOPTION */
  SourceTypeOption = 'SourceTypeOPTION',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on AdditionalSourceCode */
  AdditionalSourceCode = 'AdditionalSourceCode',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on RecurringSystem */
  RecurringSystem = 'RecurringSystem',
  /** Aggregate result on RecurringSystemOPTION */
  RecurringSystemOption = 'RecurringSystemOPTION',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on Day */
  Day = 'Day',
  /** Aggregate result on DayOPTION */
  DayOption = 'DayOPTION',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Nonworking */
  Nonworking = 'Nonworking',
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmFncTestReturnChargesInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Unitprice */
  Unitprice = 'Unitprice',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on ReqAction */
  ReqAction = 'ReqAction',
  /** Aggregate result on ReqApproved */
  ReqApproved = 'ReqApproved',
  /** Aggregate result on ApprovedBy */
  ApprovedBy = 'ApprovedBy',
  /** Aggregate result on UseatFunctionalTest */
  UseatFunctionalTest = 'UseatFunctionalTest',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on TransferredToContract */
  TransferredToContract = 'TransferredToContract',
  /** Aggregate result on TransferredBy */
  TransferredBy = 'TransferredBy',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmLocCostCenterCombInput {
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on CostCenterCode */
  CostCenterCode = 'CostCenterCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmManufacturerInput {
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmManufacturerModelInput {
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on ModelCode */
  ModelCode = 'ModelCode',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on CounterReporting */
  CounterReporting = 'CounterReporting',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on ItemDescription */
  ItemDescription = 'ItemDescription',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Capacity */
  Capacity = 'Capacity',
  /** Aggregate result on CapacityMeasure */
  CapacityMeasure = 'CapacityMeasure',
  /** Aggregate result on Effect */
  Effect = 'Effect',
  /** Aggregate result on EffectMeasure */
  EffectMeasure = 'EffectMeasure',
  /** Aggregate result on Length */
  Length = 'Length',
  /** Aggregate result on LengthMeasure */
  LengthMeasure = 'LengthMeasure',
  /** Aggregate result on Width */
  Width = 'Width',
  /** Aggregate result on WidthMeasure */
  WidthMeasure = 'WidthMeasure',
  /** Aggregate result on Height */
  Height = 'Height',
  /** Aggregate result on HeightMeasure */
  HeightMeasure = 'HeightMeasure',
  /** Aggregate result on Weight */
  Weight = 'Weight',
  /** Aggregate result on WeightMeasure */
  WeightMeasure = 'WeightMeasure',
  /** Aggregate result on CylinderVolume */
  CylinderVolume = 'CylinderVolume',
  /** Aggregate result on CylinderMeasure */
  CylinderMeasure = 'CylinderMeasure',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectTypeDescription */
  ObjectTypeDescription = 'ObjectTypeDescription',
  /** Aggregate result on AttachmentNo */
  AttachmentNo = 'AttachmentNo',
  /** Aggregate result on AttachmentNoSeries */
  AttachmentNoSeries = 'AttachmentNoSeries',
  /** Aggregate result on NoofObjects */
  NoofObjects = 'NoofObjects',
  /** Aggregate result on ManufacturerId */
  ManufacturerId = 'ManufacturerId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on SRALink */
  SraLink = 'SRALink',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectCardFlowInput {
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on UserSecurityID */
  UserSecurityId = 'UserSecurityID',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on DateFilter2 */
  DateFilter2 = 'DateFilter2',
  /** Aggregate result on ServiceItemNo */
  ServiceItemNo = 'ServiceItemNo',
  /** Aggregate result on LinkedtoObjectNo */
  LinkedtoObjectNo = 'LinkedtoObjectNo',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowCostofSale */
  FlowCostofSale = 'FlowCostofSale',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowComponentCost */
  FlowComponentCost = 'FlowComponentCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowPurchaseAddCost */
  FlowPurchaseAddCost = 'FlowPurchaseAddCost',
  /** Aggregate result on FlowBookValue */
  FlowBookValue = 'FlowBookValue',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowAppreciation */
  FlowAppreciation = 'FlowAppreciation',
  /** Aggregate result on FlowWriteDown */
  FlowWriteDown = 'FlowWriteDown',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowAcquisitionCost */
  FlowAcquisitionCost = 'FlowAcquisitionCost',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on Invoiceddays */
  Invoiceddays = 'Invoiceddays',
  /** Aggregate result on FlowLinkedOtherCost */
  FlowLinkedOtherCost = 'FlowLinkedOtherCost',
  /** Aggregate result on FlowLinkedLeasingCost */
  FlowLinkedLeasingCost = 'FlowLinkedLeasingCost',
  /** Aggregate result on FlowLinkedRentalIncome */
  FlowLinkedRentalIncome = 'FlowLinkedRentalIncome',
  /** Aggregate result on FlowLinkedServiceCost */
  FlowLinkedServiceCost = 'FlowLinkedServiceCost',
  /** Aggregate result on FlowLinkedGuaranteeCost */
  FlowLinkedGuaranteeCost = 'FlowLinkedGuaranteeCost',
  /** Aggregate result on FlowLinkedIntRentalCost */
  FlowLinkedIntRentalCost = 'FlowLinkedIntRentalCost',
  /** Aggregate result on FlowLinkedHireCost */
  FlowLinkedHireCost = 'FlowLinkedHireCost',
  /** Aggregate result on FlowLinkedHireDiscount */
  FlowLinkedHireDiscount = 'FlowLinkedHireDiscount',
  /** Aggregate result on FlowLinkedServiceAgrCost */
  FlowLinkedServiceAgrCost = 'FlowLinkedServiceAgrCost',
  /** Aggregate result on FlowLinkedDepreciation */
  FlowLinkedDepreciation = 'FlowLinkedDepreciation',
  /** Aggregate result on FlowLinkedUppreciation */
  FlowLinkedUppreciation = 'FlowLinkedUppreciation',
  /** Aggregate result on FlowLinkedDownpreciation */
  FlowLinkedDownpreciation = 'FlowLinkedDownpreciation',
  /** Aggregate result on FlowLinkedServiceIncome */
  FlowLinkedServiceIncome = 'FlowLinkedServiceIncome',
  /** Aggregate result on FlowLinkedSrvAgrIncome */
  FlowLinkedSrvAgrIncome = 'FlowLinkedSrvAgrIncome',
  /** Aggregate result on FlowLinkedIntRentalIncome */
  FlowLinkedIntRentalIncome = 'FlowLinkedIntRentalIncome',
  /** Aggregate result on FlowLinkedOtherIncome */
  FlowLinkedOtherIncome = 'FlowLinkedOtherIncome',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectComponentsInput {
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on LevelofAspiration */
  LevelofAspiration = 'LevelofAspiration',
  /** Aggregate result on LevelofAspirationOPTION */
  LevelofAspirationOption = 'LevelofAspirationOPTION',
  /** Aggregate result on PurchaseNo */
  PurchaseNo = 'PurchaseNo',
  /** Aggregate result on FromService */
  FromService = 'FromService',
  /** Aggregate result on Ordered */
  Ordered = 'Ordered',
  /** Aggregate result on FatherObjectSold */
  FatherObjectSold = 'FatherObjectSold',
  /** Aggregate result on Typeold */
  Typeold = 'Typeold',
  /** Aggregate result on TypeoldOPTION */
  TypeoldOption = 'TypeoldOPTION',
  /** Aggregate result on LineCost */
  LineCost = 'LineCost',
  /** Aggregate result on Assembled */
  Assembled = 'Assembled',
  /** Aggregate result on NonBillable */
  NonBillable = 'NonBillable',
  /** Aggregate result on PostReclass */
  PostReclass = 'PostReclass',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectCountHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on DocumentStatus */
  DocumentStatus = 'DocumentStatus',
  /** Aggregate result on DocumentStatusOPTION */
  DocumentStatusOption = 'DocumentStatusOPTION',
  /** Aggregate result on DateCounted */
  DateCounted = 'DateCounted',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectCountLineInput {
  /** Aggregate result on CountNo */
  CountNo = 'CountNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on ObjectTypeNo */
  ObjectTypeNo = 'ObjectTypeNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on DateCounted */
  DateCounted = 'DateCounted',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on DocumentStatus */
  DocumentStatus = 'DocumentStatus',
  /** Aggregate result on DocumentStatusOPTION */
  DocumentStatusOption = 'DocumentStatusOPTION',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on Selected */
  Selected = 'Selected',
  /** Aggregate result on Corrected */
  Corrected = 'Corrected',
  /** Aggregate result on CurrentLocationCode */
  CurrentLocationCode = 'CurrentLocationCode',
  /** Aggregate result on RentalStatus */
  RentalStatus = 'RentalStatus',
  /** Aggregate result on RentalStatusOPTION */
  RentalStatusOption = 'RentalStatusOPTION',
  /** Aggregate result on RentalContractNo */
  RentalContractNo = 'RentalContractNo',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on LineMessage */
  LineMessage = 'LineMessage',
  /** Aggregate result on CountedObjectNo */
  CountedObjectNo = 'CountedObjectNo',
  /** Aggregate result on MemoText */
  MemoText = 'MemoText',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectGroupCardInput {
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on GenProdPostingGrpIntRent */
  GenProdPostingGrpIntRent = 'GenProdPostingGrpIntRent',
  /** Aggregate result on GenProdPostingGrpExtRent */
  GenProdPostingGrpExtRent = 'GenProdPostingGrpExtRent',
  /** Aggregate result on ReRentPostingGroup */
  ReRentPostingGroup = 'ReRentPostingGroup',
  /** Aggregate result on NoofObjects */
  NoofObjects = 'NoofObjects',
  /** Aggregate result on NoofObjectType */
  NoofObjectType = 'NoofObjectType',
  /** Aggregate result on ObjectNos */
  ObjectNos = 'ObjectNos',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on FAClassCode */
  FaClassCode = 'FAClassCode',
  /** Aggregate result on FASubclassCode */
  FaSubclassCode = 'FASubclassCode',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepreciationMethod */
  DepreciationMethod = 'DepreciationMethod',
  /** Aggregate result on DepreciationMethodOPTION */
  DepreciationMethodOption = 'DepreciationMethodOPTION',
  /** Aggregate result on FAPostingGroup */
  FaPostingGroup = 'FAPostingGroup',
  /** Aggregate result on GenProdPostingGrpHire */
  GenProdPostingGrpHire = 'GenProdPostingGrpHire',
  /** Aggregate result on GenProdPostingGrpLeasing */
  GenProdPostingGrpLeasing = 'GenProdPostingGrpLeasing',
  /** Aggregate result on RentalSplitPostingGroup */
  RentalSplitPostingGroup = 'RentalSplitPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on GenProdPostingGrpWriteUp */
  GenProdPostingGrpWriteUp = 'GenProdPostingGrpWriteUp',
  /** Aggregate result on NoofDepreciationYears */
  NoofDepreciationYears = 'NoofDepreciationYears',
  /** Aggregate result on NoofDepreciationMonths */
  NoofDepreciationMonths = 'NoofDepreciationMonths',
  /** Aggregate result on ServiceItemGroup */
  ServiceItemGroup = 'ServiceItemGroup',
  /** Aggregate result on CreditonEarlyOffRent */
  CreditonEarlyOffRent = 'CreditonEarlyOffRent',
  /** Aggregate result on StdRentalChargeDays */
  StdRentalChargeDays = 'StdRentalChargeDays',
  /** Aggregate result on StdRentalChargeDaysOPTION */
  StdRentalChargeDaysOption = 'StdRentalChargeDaysOPTION',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on FixedAssetSetupGroup */
  FixedAssetSetupGroup = 'FixedAssetSetupGroup',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on ObjectGroupNoConcern */
  ObjectGroupNoConcern = 'ObjectGroupNoConcern',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Description3 */
  Description3 = 'Description3',
  /** Aggregate result on Description4 */
  Description4 = 'Description4',
  /** Aggregate result on StraightLine */
  StraightLine = 'StraightLine',
  /** Aggregate result on WarrantyDuration */
  WarrantyDuration = 'WarrantyDuration',
  /** Aggregate result on DecliningBalance */
  DecliningBalance = 'DecliningBalance',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on TaxDepreciationMethod */
  TaxDepreciationMethod = 'TaxDepreciationMethod',
  /** Aggregate result on TaxDepreciationMethodOPTION */
  TaxDepreciationMethodOption = 'TaxDepreciationMethodOPTION',
  /** Aggregate result on TaxFAPostingGroup */
  TaxFaPostingGroup = 'TaxFAPostingGroup',
  /** Aggregate result on TaxNoofDepreciationYears */
  TaxNoofDepreciationYears = 'TaxNoofDepreciationYears',
  /** Aggregate result on TaxNoofDepreciationMonths */
  TaxNoofDepreciationMonths = 'TaxNoofDepreciationMonths',
  /** Aggregate result on TaxStraightLine */
  TaxStraightLine = 'TaxStraightLine',
  /** Aggregate result on TaxDecliningBalance */
  TaxDecliningBalance = 'TaxDecliningBalance',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on DateFilter2 */
  DateFilter2 = 'DateFilter2',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on InvoicedDays */
  InvoicedDays = 'InvoicedDays',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ImgUrlSmall */
  ImgUrlSmall = 'ImgUrlSmall',
  /** Aggregate result on ImgUrlLarge */
  ImgUrlLarge = 'ImgUrlLarge',
  /** Aggregate result on ObjectGroupFamily */
  ObjectGroupFamily = 'ObjectGroupFamily',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectGroupFlowInput {
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on UserSecurityID */
  UserSecurityId = 'UserSecurityID',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowPurchaseAddCost */
  FlowPurchaseAddCost = 'FlowPurchaseAddCost',
  /** Aggregate result on FlowBookValue */
  FlowBookValue = 'FlowBookValue',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowAcquisitionCost */
  FlowAcquisitionCost = 'FlowAcquisitionCost',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on InvoicedDays */
  InvoicedDays = 'InvoicedDays',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectServiceIntervalInput {
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ServiceTypeCode */
  ServiceTypeCode = 'ServiceTypeCode',
  /** Aggregate result on ServiceInitiator */
  ServiceInitiator = 'ServiceInitiator',
  /** Aggregate result on ServiceInitiatorOPTION */
  ServiceInitiatorOption = 'ServiceInitiatorOPTION',
  /** Aggregate result on ServiceCounter */
  ServiceCounter = 'ServiceCounter',
  /** Aggregate result on ServiceDate */
  ServiceDate = 'ServiceDate',
  /** Aggregate result on ServiceDurationDays */
  ServiceDurationDays = 'ServiceDurationDays',
  /** Aggregate result on AssociatedServicePack */
  AssociatedServicePack = 'AssociatedServicePack',
  /** Aggregate result on CustomerDebit */
  CustomerDebit = 'CustomerDebit',
  /** Aggregate result on DateCompleted */
  DateCompleted = 'DateCompleted',
  /** Aggregate result on TransferedtoOrder */
  TransferedtoOrder = 'TransferedtoOrder',
  /** Aggregate result on TransferedtoWSOrder */
  TransferedtoWsOrder = 'TransferedtoWSOrder',
  /** Aggregate result on TransferedtoWSPurchase */
  TransferedtoWsPurchase = 'TransferedtoWSPurchase',
  /** Aggregate result on TransferedtoEQMService */
  TransferedtoEqmService = 'TransferedtoEQMService',
  /** Aggregate result on TransferredtoWorkOrder */
  TransferredtoWorkOrder = 'TransferredtoWorkOrder',
  /** Aggregate result on ServiceMargin */
  ServiceMargin = 'ServiceMargin',
  /** Aggregate result on ServiceInitiator1 */
  ServiceInitiator1 = 'ServiceInitiator1',
  /** Aggregate result on ServiceInitiator1OPTION */
  ServiceInitiator1Option = 'ServiceInitiator1OPTION',
  /** Aggregate result on Repetitive */
  Repetitive = 'Repetitive',
  /** Aggregate result on Interval */
  Interval = 'Interval',
  /** Aggregate result on DateInterval */
  DateInterval = 'DateInterval',
  /** Aggregate result on ServiceCounterMargin */
  ServiceCounterMargin = 'ServiceCounterMargin',
  /** Aggregate result on ServiceDateMargin */
  ServiceDateMargin = 'ServiceDateMargin',
  /** Aggregate result on ServiceInitiator2 */
  ServiceInitiator2 = 'ServiceInitiator2',
  /** Aggregate result on ServiceInitiator2OPTION */
  ServiceInitiator2Option = 'ServiceInitiator2OPTION',
  /** Aggregate result on ServiceCounter2 */
  ServiceCounter2 = 'ServiceCounter2',
  /** Aggregate result on ServiceCounterMargin2 */
  ServiceCounterMargin2 = 'ServiceCounterMargin2',
  /** Aggregate result on Interval2 */
  Interval2 = 'Interval2',
  /** Aggregate result on RepairCode */
  RepairCode = 'RepairCode',
  /** Aggregate result on PostedDocumentType */
  PostedDocumentType = 'PostedDocumentType',
  /** Aggregate result on PostedDocumentTypeOPTION */
  PostedDocumentTypeOption = 'PostedDocumentTypeOPTION',
  /** Aggregate result on PostedDocumentNo */
  PostedDocumentNo = 'PostedDocumentNo',
  /** Aggregate result on ServiceDateMarginBlocked */
  ServiceDateMarginBlocked = 'ServiceDateMarginBlocked',
  /** Aggregate result on ControlTypeCode */
  ControlTypeCode = 'ControlTypeCode',
  /** Aggregate result on Certification */
  Certification = 'Certification',
  /** Aggregate result on ObjectId */
  ObjectId = 'ObjectId',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on ObjectTypeId */
  ObjectTypeId = 'ObjectTypeId',
  /** Aggregate result on ManufacturerId */
  ManufacturerId = 'ManufacturerId',
  /** Aggregate result on ManufacturerModelId */
  ManufacturerModelId = 'ManufacturerModelId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on ObjectGroupId */
  ObjectGroupId = 'ObjectGroupId',
  /** Aggregate result on ServiceCode */
  ServiceCode = 'ServiceCode',
  /** Aggregate result on MarginDate */
  MarginDate = 'MarginDate',
  /** Aggregate result on ObjectDescription */
  ObjectDescription = 'ObjectDescription',
  /** Aggregate result on ObjectRentalStatus */
  ObjectRentalStatus = 'ObjectRentalStatus',
  /** Aggregate result on ObjectRentalStatusOPTION */
  ObjectRentalStatusOption = 'ObjectRentalStatusOPTION',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on ActionType */
  ActionType = 'ActionType',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on DekraInspectionResult */
  DekraInspectionResult = 'DekraInspectionResult',
  /** Aggregate result on DekraInspectionResultOPTION */
  DekraInspectionResultOption = 'DekraInspectionResultOPTION',
  /** Aggregate result on DekraInspection */
  DekraInspection = 'DekraInspection',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectSrvIntervalAggInput {
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ServiceTypeCode */
  ServiceTypeCode = 'ServiceTypeCode',
  /** Aggregate result on ServiceInitiator */
  ServiceInitiator = 'ServiceInitiator',
  /** Aggregate result on ServiceInitiatorOPTION */
  ServiceInitiatorOption = 'ServiceInitiatorOPTION',
  /** Aggregate result on ServiceCounter */
  ServiceCounter = 'ServiceCounter',
  /** Aggregate result on ServiceDate */
  ServiceDate = 'ServiceDate',
  /** Aggregate result on ServiceDurationDays */
  ServiceDurationDays = 'ServiceDurationDays',
  /** Aggregate result on AssociatedServicePack */
  AssociatedServicePack = 'AssociatedServicePack',
  /** Aggregate result on CustomerDebit */
  CustomerDebit = 'CustomerDebit',
  /** Aggregate result on DateCompleted */
  DateCompleted = 'DateCompleted',
  /** Aggregate result on TransferedtoOrder */
  TransferedtoOrder = 'TransferedtoOrder',
  /** Aggregate result on TransferedtoWSOrder */
  TransferedtoWsOrder = 'TransferedtoWSOrder',
  /** Aggregate result on TransferedtoWSPurchase */
  TransferedtoWsPurchase = 'TransferedtoWSPurchase',
  /** Aggregate result on TransferedtoEQMService */
  TransferedtoEqmService = 'TransferedtoEQMService',
  /** Aggregate result on TransferredtoWorkOrder */
  TransferredtoWorkOrder = 'TransferredtoWorkOrder',
  /** Aggregate result on ServiceMargin */
  ServiceMargin = 'ServiceMargin',
  /** Aggregate result on Repetitive */
  Repetitive = 'Repetitive',
  /** Aggregate result on Interval */
  Interval = 'Interval',
  /** Aggregate result on DateInterval */
  DateInterval = 'DateInterval',
  /** Aggregate result on ServiceCounterMargin */
  ServiceCounterMargin = 'ServiceCounterMargin',
  /** Aggregate result on ServiceDateMargin */
  ServiceDateMargin = 'ServiceDateMargin',
  /** Aggregate result on ServiceInitiator2 */
  ServiceInitiator2 = 'ServiceInitiator2',
  /** Aggregate result on ServiceInitiator2OPTION */
  ServiceInitiator2Option = 'ServiceInitiator2OPTION',
  /** Aggregate result on ServiceCounter2 */
  ServiceCounter2 = 'ServiceCounter2',
  /** Aggregate result on ServiceCounterMargin2 */
  ServiceCounterMargin2 = 'ServiceCounterMargin2',
  /** Aggregate result on Interval2 */
  Interval2 = 'Interval2',
  /** Aggregate result on RepairCode */
  RepairCode = 'RepairCode',
  /** Aggregate result on PostedDocumentType */
  PostedDocumentType = 'PostedDocumentType',
  /** Aggregate result on PostedDocumentTypeOPTION */
  PostedDocumentTypeOption = 'PostedDocumentTypeOPTION',
  /** Aggregate result on PostedDocumentNo */
  PostedDocumentNo = 'PostedDocumentNo',
  /** Aggregate result on ServiceDateMarginBlocked */
  ServiceDateMarginBlocked = 'ServiceDateMarginBlocked',
  /** Aggregate result on ControlTypeCode */
  ControlTypeCode = 'ControlTypeCode',
  /** Aggregate result on ObjectId */
  ObjectId = 'ObjectId',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on ObjectTypeId */
  ObjectTypeId = 'ObjectTypeId',
  /** Aggregate result on ManufacturerId */
  ManufacturerId = 'ManufacturerId',
  /** Aggregate result on ManufacturerModelId */
  ManufacturerModelId = 'ManufacturerModelId',
  /** Aggregate result on ObjectGroupId */
  ObjectGroupId = 'ObjectGroupId',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on AssociatedServicePackId */
  AssociatedServicePackId = 'AssociatedServicePackId',
  /** Aggregate result on TransferredtoOrderId */
  TransferredtoOrderId = 'TransferredtoOrderId',
  /** Aggregate result on TransferredtoWSOrderId */
  TransferredtoWsOrderId = 'TransferredtoWSOrderId',
  /** Aggregate result on TransferredtoWSPurchId */
  TransferredtoWsPurchId = 'TransferredtoWSPurchId',
  /** Aggregate result on TransferredtoEQMServiceId */
  TransferredtoEqmServiceId = 'TransferredtoEQMServiceId',
  /** Aggregate result on TransferredtoWorkOrderId */
  TransferredtoWorkOrderId = 'TransferredtoWorkOrderId',
  /** Aggregate result on RepairId */
  RepairId = 'RepairId',
  /** Aggregate result on PostedDocumentId */
  PostedDocumentId = 'PostedDocumentId',
  /** Aggregate result on ControlTypeId */
  ControlTypeId = 'ControlTypeId',
  /** Aggregate result on ServiceTypeId */
  ServiceTypeId = 'ServiceTypeId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectStatusInput {
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Descripton */
  Descripton = 'Descripton',
  /** Aggregate result on Answer */
  Answer = 'Answer',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectTypeCardInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on FAClassCode */
  FaClassCode = 'FAClassCode',
  /** Aggregate result on FASubclassCode */
  FaSubclassCode = 'FASubclassCode',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepreciationMethod */
  DepreciationMethod = 'DepreciationMethod',
  /** Aggregate result on FAPostingGroup */
  FaPostingGroup = 'FAPostingGroup',
  /** Aggregate result on ObjectNos */
  ObjectNos = 'ObjectNos',
  /** Aggregate result on CostingMethod */
  CostingMethod = 'CostingMethod',
  /** Aggregate result on StandardCost */
  StandardCost = 'StandardCost',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on IndirectCost */
  IndirectCost = 'IndirectCost',
  /** Aggregate result on LastDirectCost */
  LastDirectCost = 'LastDirectCost',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceProfitCalculation */
  PriceProfitCalculation = 'PriceProfitCalculation',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on PriceIncludesVAT */
  PriceIncludesVat = 'PriceIncludesVAT',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on NoofDepreciationYears */
  NoofDepreciationYears = 'NoofDepreciationYears',
  /** Aggregate result on NoofDepreciationMonths */
  NoofDepreciationMonths = 'NoofDepreciationMonths',
  /** Aggregate result on CounterType */
  CounterType = 'CounterType',
  /** Aggregate result on WarrantyDiscParts */
  WarrantyDiscParts = 'WarrantyDiscParts',
  /** Aggregate result on WarrantyDiscLabor */
  WarrantyDiscLabor = 'WarrantyDiscLabor',
  /** Aggregate result on DefaultWarrantyDuration */
  DefaultWarrantyDuration = 'DefaultWarrantyDuration',
  /** Aggregate result on ServiceItemGroup */
  ServiceItemGroup = 'ServiceItemGroup',
  /** Aggregate result on ResponseTimeHours */
  ResponseTimeHours = 'ResponseTimeHours',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on GenProdPostingGrpWriteUp */
  GenProdPostingGrpWriteUp = 'GenProdPostingGrpWriteUp',
  /** Aggregate result on SaleDiscGroup */
  SaleDiscGroup = 'SaleDiscGroup',
  /** Aggregate result on RentalDiscGroup */
  RentalDiscGroup = 'RentalDiscGroup',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on VATBusPostingGrPrice */
  VatBusPostingGrPrice = 'VATBusPostingGrPrice',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on GenProdPostingGrpHire */
  GenProdPostingGrpHire = 'GenProdPostingGrpHire',
  /** Aggregate result on GenProdPostingGrpLeasing */
  GenProdPostingGrpLeasing = 'GenProdPostingGrpLeasing',
  /** Aggregate result on RentalSplitPostingGroup */
  RentalSplitPostingGroup = 'RentalSplitPostingGroup',
  /** Aggregate result on SalesPostingGroup */
  SalesPostingGroup = 'SalesPostingGroup',
  /** Aggregate result on GenProdPostingGrpExtRent */
  GenProdPostingGrpExtRent = 'GenProdPostingGrpExtRent',
  /** Aggregate result on GenProdPostingGrpIntRent */
  GenProdPostingGrpIntRent = 'GenProdPostingGrpIntRent',
  /** Aggregate result on VATProdPostingGrpRental */
  VatProdPostingGrpRental = 'VATProdPostingGrpRental',
  /** Aggregate result on ReRentPostingGroup */
  ReRentPostingGroup = 'ReRentPostingGroup',
  /** Aggregate result on ReplacementPrice */
  ReplacementPrice = 'ReplacementPrice',
  /** Aggregate result on Capacity */
  Capacity = 'Capacity',
  /** Aggregate result on Effect */
  Effect = 'Effect',
  /** Aggregate result on CylinderVolume */
  CylinderVolume = 'CylinderVolume',
  /** Aggregate result on AutomaticExtendedTexts */
  AutomaticExtendedTexts = 'AutomaticExtendedTexts',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on CapacityMeasure */
  CapacityMeasure = 'CapacityMeasure',
  /** Aggregate result on EffectMeasure */
  EffectMeasure = 'EffectMeasure',
  /** Aggregate result on CylinderMeasure */
  CylinderMeasure = 'CylinderMeasure',
  /** Aggregate result on Document */
  Document = 'Document',
  /** Aggregate result on WebPage */
  WebPage = 'WebPage',
  /** Aggregate result on ExpAvailableAfterEndDate */
  ExpAvailableAfterEndDate = 'ExpAvailableAfterEndDate',
  /** Aggregate result on CreditonEarlyReturn */
  CreditonEarlyReturn = 'CreditonEarlyReturn',
  /** Aggregate result on StdRentalChargeDays */
  StdRentalChargeDays = 'StdRentalChargeDays',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on DefaultFunctionalTest */
  DefaultFunctionalTest = 'DefaultFunctionalTest',
  /** Aggregate result on DefaultMaintenance */
  DefaultMaintenance = 'DefaultMaintenance',
  /** Aggregate result on Length */
  Length = 'Length',
  /** Aggregate result on LengthMeasure */
  LengthMeasure = 'LengthMeasure',
  /** Aggregate result on Width */
  Width = 'Width',
  /** Aggregate result on WidthMeasure */
  WidthMeasure = 'WidthMeasure',
  /** Aggregate result on Height */
  Height = 'Height',
  /** Aggregate result on HeightMeasure */
  HeightMeasure = 'HeightMeasure',
  /** Aggregate result on Weight */
  Weight = 'Weight',
  /** Aggregate result on WeightMeasure */
  WeightMeasure = 'WeightMeasure',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on QuantityHoursFullRentalDay */
  QuantityHoursFullRentalDay = 'QuantityHoursFullRentalDay',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on ObjectGroupId */
  ObjectGroupId = 'ObjectGroupId',
  /** Aggregate result on ManufacturerId */
  ManufacturerId = 'ManufacturerId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on NBAConnectedToHierarchy */
  NbaConnectedToHierarchy = 'NBAConnectedToHierarchy',
  /** Aggregate result on NBAMappingNo */
  NbaMappingNo = 'NBAMappingNo',
  /** Aggregate result on NBAMappingDescription */
  NbaMappingDescription = 'NBAMappingDescription',
  /** Aggregate result on NBARamirentGroupCode */
  NbaRamirentGroupCode = 'NBARamirentGroupCode',
  /** Aggregate result on NBAESInspectionInterval */
  NbaesInspectionInterval = 'NBAESInspectionInterval',
  /** Aggregate result on NBAESManufacturerModel */
  NbaesManufacturerModel = 'NBAESManufacturerModel',
  /** Aggregate result on NBAESBonusGroup */
  NbaesBonusGroup = 'NBAESBonusGroup',
  /** Aggregate result on NBAESExcludeFromVaction */
  NbaesExcludeFromVaction = 'NBAESExcludeFromVaction',
  /** Aggregate result on ObjectTypeNoConcern */
  ObjectTypeNoConcern = 'ObjectTypeNoConcern',
  /** Aggregate result on DepositAmount */
  DepositAmount = 'DepositAmount',
  /** Aggregate result on HireSplit */
  HireSplit = 'HireSplit',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Description3 */
  Description3 = 'Description3',
  /** Aggregate result on Description4 */
  Description4 = 'Description4',
  /** Aggregate result on FixedCostPrice */
  FixedCostPrice = 'FixedCostPrice',
  /** Aggregate result on RentalSplit */
  RentalSplit = 'RentalSplit',
  /** Aggregate result on StraightLine */
  StraightLine = 'StraightLine',
  /** Aggregate result on WarrantyDuration */
  WarrantyDuration = 'WarrantyDuration',
  /** Aggregate result on DecliningBalance */
  DecliningBalance = 'DecliningBalance',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on RetentionLevel */
  RetentionLevel = 'RetentionLevel',
  /** Aggregate result on TaxDepreciationMethod */
  TaxDepreciationMethod = 'TaxDepreciationMethod',
  /** Aggregate result on TaxFAPostingGroup */
  TaxFaPostingGroup = 'TaxFAPostingGroup',
  /** Aggregate result on TaxNoofDepreciationYears */
  TaxNoofDepreciationYears = 'TaxNoofDepreciationYears',
  /** Aggregate result on TaxNoofDepreciationMonths */
  TaxNoofDepreciationMonths = 'TaxNoofDepreciationMonths',
  /** Aggregate result on TaxStraightLine */
  TaxStraightLine = 'TaxStraightLine',
  /** Aggregate result on TaxDecliningBalance */
  TaxDecliningBalance = 'TaxDecliningBalance',
  /** Aggregate result on PriceScheduleCode */
  PriceScheduleCode = 'PriceScheduleCode',
  /** Aggregate result on EstimatedDailyHours */
  EstimatedDailyHours = 'EstimatedDailyHours',
  /** Aggregate result on MandatoryCounteronReturn */
  MandatoryCounteronReturn = 'MandatoryCounteronReturn',
  /** Aggregate result on CounterType2 */
  CounterType2 = 'CounterType2',
  /** Aggregate result on MandatoryCounter2onReturn */
  MandatoryCounter2onReturn = 'MandatoryCounter2onReturn',
  /** Aggregate result on EstimatedCounterperDay */
  EstimatedCounterperDay = 'EstimatedCounterperDay',
  /** Aggregate result on EstimatedCounter2perDay */
  EstimatedCounter2perDay = 'EstimatedCounter2perDay',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DateFilter2 */
  DateFilter2 = 'DateFilter2',
  /** Aggregate result on Inventory */
  Inventory = 'Inventory',
  /** Aggregate result on InventoryObject */
  InventoryObject = 'InventoryObject',
  /** Aggregate result on InventoryRental */
  InventoryRental = 'InventoryRental',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on CommentObjectGroup */
  CommentObjectGroup = 'CommentObjectGroup',
  /** Aggregate result on FixedAssetSetupGroup */
  FixedAssetSetupGroup = 'FixedAssetSetupGroup',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on InvoicedDays */
  InvoicedDays = 'InvoicedDays',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on RentalReservationsQty */
  RentalReservationsQty = 'RentalReservationsQty',
  /** Aggregate result on RentalDeliveriesQty */
  RentalDeliveriesQty = 'RentalDeliveriesQty',
  /** Aggregate result on ExpectedRentalReturnsQty */
  ExpectedRentalReturnsQty = 'ExpectedRentalReturnsQty',
  /** Aggregate result on RentalReturnsQty */
  RentalReturnsQty = 'RentalReturnsQty',
  /** Aggregate result on PictureURL */
  PictureUrl = 'PictureURL',
  /** Aggregate result on PSIDocumentURL */
  PsiDocumentUrl = 'PSIDocumentURL',
  /** Aggregate result on NotAvailableForReservation */
  NotAvailableForReservation = 'NotAvailableForReservation',
  /** Aggregate result on ExpirationDateMandatory */
  ExpirationDateMandatory = 'ExpirationDateMandatory',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectTypeComponentsInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineDiscountPercent */
  LineDiscountPercent = 'LineDiscountPercent',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on LevelofAspiration */
  LevelofAspiration = 'LevelofAspiration',
  /** Aggregate result on LevelofAspirationOPTION */
  LevelofAspirationOption = 'LevelofAspirationOPTION',
  /** Aggregate result on EntryType */
  EntryType = 'EntryType',
  /** Aggregate result on EntryTypeOPTION */
  EntryTypeOption = 'EntryTypeOPTION',
  /** Aggregate result on NonBillable */
  NonBillable = 'NonBillable',
  /** Aggregate result on InsertAutomatically */
  InsertAutomatically = 'InsertAutomatically',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectTypeFlowInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on UserSecurityID */
  UserSecurityId = 'UserSecurityID',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowPurchaseAddCost */
  FlowPurchaseAddCost = 'FlowPurchaseAddCost',
  /** Aggregate result on FlowBookValue */
  FlowBookValue = 'FlowBookValue',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowAcquisitionCost */
  FlowAcquisitionCost = 'FlowAcquisitionCost',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on InvoicedDays */
  InvoicedDays = 'InvoicedDays',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectTypePriceTermInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on PercentofMonthlyPrice */
  PercentofMonthlyPrice = 'PercentofMonthlyPrice',
  /** Aggregate result on FixedTermPrice */
  FixedTermPrice = 'FixedTermPrice',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on PriceonExtraHours */
  PriceonExtraHours = 'PriceonExtraHours',
  /** Aggregate result on AllowLineDisconExHours */
  AllowLineDisconExHours = 'AllowLineDisconExHours',
  /** Aggregate result on PriceReportedHours */
  PriceReportedHours = 'PriceReportedHours',
  /** Aggregate result on FreeRentalHours */
  FreeRentalHours = 'FreeRentalHours',
  /** Aggregate result on MinimumReportedHours */
  MinimumReportedHours = 'MinimumReportedHours',
  /** Aggregate result on MinRepHoursPeriod */
  MinRepHoursPeriod = 'MinRepHoursPeriod',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmObjectTypeServiceIntervalInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ServiceTypeCode */
  ServiceTypeCode = 'ServiceTypeCode',
  /** Aggregate result on ServiceInitiator */
  ServiceInitiator = 'ServiceInitiator',
  /** Aggregate result on ServiceInitiatorOPTION */
  ServiceInitiatorOption = 'ServiceInitiatorOPTION',
  /** Aggregate result on ServiceCounter */
  ServiceCounter = 'ServiceCounter',
  /** Aggregate result on ServiceDate */
  ServiceDate = 'ServiceDate',
  /** Aggregate result on ServiceDurationDays */
  ServiceDurationDays = 'ServiceDurationDays',
  /** Aggregate result on AssociatedServicePack */
  AssociatedServicePack = 'AssociatedServicePack',
  /** Aggregate result on CustomerDebit */
  CustomerDebit = 'CustomerDebit',
  /** Aggregate result on ServiceMargin */
  ServiceMargin = 'ServiceMargin',
  /** Aggregate result on Repetitive */
  Repetitive = 'Repetitive',
  /** Aggregate result on Interval */
  Interval = 'Interval',
  /** Aggregate result on DateInterval */
  DateInterval = 'DateInterval',
  /** Aggregate result on ServiceCounterMargin */
  ServiceCounterMargin = 'ServiceCounterMargin',
  /** Aggregate result on ServiceDateMargin */
  ServiceDateMargin = 'ServiceDateMargin',
  /** Aggregate result on ServiceInitiator2 */
  ServiceInitiator2 = 'ServiceInitiator2',
  /** Aggregate result on ServiceInitiator2OPTION */
  ServiceInitiator2Option = 'ServiceInitiator2OPTION',
  /** Aggregate result on ServiceCounter2 */
  ServiceCounter2 = 'ServiceCounter2',
  /** Aggregate result on ServiceCounterMargin2 */
  ServiceCounterMargin2 = 'ServiceCounterMargin2',
  /** Aggregate result on Interval2 */
  Interval2 = 'Interval2',
  /** Aggregate result on RepairCode */
  RepairCode = 'RepairCode',
  /** Aggregate result on ServiceDateMarginBlocked */
  ServiceDateMarginBlocked = 'ServiceDateMarginBlocked',
  /** Aggregate result on ControlTypeCode */
  ControlTypeCode = 'ControlTypeCode',
  /** Aggregate result on Certification */
  Certification = 'Certification',
  /** Aggregate result on ServiceCode */
  ServiceCode = 'ServiceCode',
  /** Aggregate result on ActionType */
  ActionType = 'ActionType',
  /** Aggregate result on DekraInspection */
  DekraInspection = 'DekraInspection',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmReRentCardInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SearchDescription */
  SearchDescription = 'SearchDescription',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on RentalStatus */
  RentalStatus = 'RentalStatus',
  /** Aggregate result on RentalStatusOPTION */
  RentalStatusOption = 'RentalStatusOPTION',
  /** Aggregate result on Counter */
  Counter = 'Counter',
  /** Aggregate result on Counter2 */
  Counter2 = 'Counter2',
  /** Aggregate result on PurchaseDocument */
  PurchaseDocument = 'PurchaseDocument',
  /** Aggregate result on PostedPurchaseInvoice */
  PostedPurchaseInvoice = 'PostedPurchaseInvoice',
  /** Aggregate result on PostedPurchaseCrMemo */
  PostedPurchaseCrMemo = 'PostedPurchaseCrMemo',
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on ManufacturerModelCode */
  ManufacturerModelCode = 'ManufacturerModelCode',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on RentalContractOpen */
  RentalContractOpen = 'RentalContractOpen',
  /** Aggregate result on RentalContractAll */
  RentalContractAll = 'RentalContractAll',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on VendorName */
  VendorName = 'VendorName',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on CreateDate */
  CreateDate = 'CreateDate',
  /** Aggregate result on ReRentCost */
  ReRentCost = 'ReRentCost',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on ReceivedDate */
  ReceivedDate = 'ReceivedDate',
  /** Aggregate result on ReturnedDate */
  ReturnedDate = 'ReturnedDate',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on ObjectTypeId */
  ObjectTypeId = 'ObjectTypeId',
  /** Aggregate result on ObjectGroupId */
  ObjectGroupId = 'ObjectGroupId',
  /** Aggregate result on ManufacturerId */
  ManufacturerId = 'ManufacturerId',
  /** Aggregate result on ManufacturerModelId */
  ManufacturerModelId = 'ManufacturerModelId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmRentalGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmRentalKitComponentsInput {
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on ContractType */
  ContractType = 'ContractType',
  /** Aggregate result on ContractTypeOPTION */
  ContractTypeOption = 'ContractTypeOPTION',
  /** Aggregate result on ExtLineNo */
  ExtLineNo = 'ExtLineNo',
  /** Aggregate result on KITNo */
  KitNo = 'KITNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ChildItem */
  ChildItem = 'ChildItem',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Price */
  Price = 'Price',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmRentalKitInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on KitType */
  KitType = 'KitType',
  /** Aggregate result on KitTypeOPTION */
  KitTypeOption = 'KitTypeOPTION',
  /** Aggregate result on KITPrice */
  KitPrice = 'KITPrice',
  /** Aggregate result on PriceTerm */
  PriceTerm = 'PriceTerm',
  /** Aggregate result on ShowinContract */
  ShowinContract = 'ShowinContract',
  /** Aggregate result on PricePriority */
  PricePriority = 'PricePriority',
  /** Aggregate result on PricePriorityOPTION */
  PricePriorityOption = 'PricePriorityOPTION',
  /** Aggregate result on PostingPriority */
  PostingPriority = 'PostingPriority',
  /** Aggregate result on PostingPriorityOPTION */
  PostingPriorityOption = 'PostingPriorityOPTION',
  /** Aggregate result on SpecifyKitComponents */
  SpecifyKitComponents = 'SpecifyKitComponents',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on BaseCalendar */
  BaseCalendar = 'BaseCalendar',
  /** Aggregate result on BaseUnitofMeasure */
  BaseUnitofMeasure = 'BaseUnitofMeasure',
  /** Aggregate result on DepositAmount */
  DepositAmount = 'DepositAmount',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on IgnoreVaryingCompQty */
  IgnoreVaryingCompQty = 'IgnoreVaryingCompQty',
  /** Aggregate result on CreditonEarlyOffRent */
  CreditonEarlyOffRent = 'CreditonEarlyOffRent',
  /** Aggregate result on DebitforReturnDate */
  DebitforReturnDate = 'DebitforReturnDate',
  /** Aggregate result on IgnoreKITLineinDispatch */
  IgnoreKitLineinDispatch = 'IgnoreKITLineinDispatch',
  /** Aggregate result on SuspendUpdateComponents */
  SuspendUpdateComponents = 'SuspendUpdateComponents',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on OnAccountBilling */
  OnAccountBilling = 'OnAccountBilling',
  /** Aggregate result on UnitofMeasureId */
  UnitofMeasureId = 'UnitofMeasureId',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on NBAESBonusGroup */
  NbaesBonusGroup = 'NBAESBonusGroup',
  /** Aggregate result on UseforReporting */
  UseforReporting = 'UseforReporting',
  /** Aggregate result on DefaultKIT */
  DefaultKit = 'DefaultKIT',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on RentalItemDiscGroup */
  RentalItemDiscGroup = 'RentalItemDiscGroup',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmRentalKitLinesInput {
  /** Aggregate result on KITNo */
  KitNo = 'KITNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ChildNo */
  ChildNo = 'ChildNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Price */
  Price = 'Price',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmRentalLineDiscountInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on SalesCode */
  SalesCode = 'SalesCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on StartingDate */
  StartingDate = 'StartingDate',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on SalesType */
  SalesType = 'SalesType',
  /** Aggregate result on SalesTypeOPTION */
  SalesTypeOption = 'SalesTypeOPTION',
  /** Aggregate result on MinimumQuantity */
  MinimumQuantity = 'MinimumQuantity',
  /** Aggregate result on EndingDate */
  EndingDate = 'EndingDate',
  /** Aggregate result on CodeType */
  CodeType = 'CodeType',
  /** Aggregate result on CodeTypeOPTION */
  CodeTypeOption = 'CodeTypeOPTION',
  /** Aggregate result on UsageType */
  UsageType = 'UsageType',
  /** Aggregate result on UsageTypeOPTION */
  UsageTypeOption = 'UsageTypeOPTION',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on NBAESDescription */
  NbaesDescription = 'NBAESDescription',
  /** Aggregate result on DiscountType */
  DiscountType = 'DiscountType',
  /** Aggregate result on DiscountTypeOPTION */
  DiscountTypeOption = 'DiscountTypeOPTION',
  /** Aggregate result on StairDiscountCode */
  StairDiscountCode = 'StairDiscountCode',
  /** Aggregate result on StepwiseDiscount */
  StepwiseDiscount = 'StepwiseDiscount',
  /** Aggregate result on UseStepwiseDisc */
  UseStepwiseDisc = 'UseStepwiseDisc',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on FrameAgreementNo */
  FrameAgreementNo = 'FrameAgreementNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmRentalPriceInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on SalesCode */
  SalesCode = 'SalesCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on StartingDate */
  StartingDate = 'StartingDate',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceIncludesVAT */
  PriceIncludesVat = 'PriceIncludesVAT',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on VATBusPostingGrPrice */
  VatBusPostingGrPrice = 'VATBusPostingGrPrice',
  /** Aggregate result on CodeType */
  CodeType = 'CodeType',
  /** Aggregate result on CodeTypeOPTION */
  CodeTypeOption = 'CodeTypeOPTION',
  /** Aggregate result on SalesType */
  SalesType = 'SalesType',
  /** Aggregate result on SalesTypeOPTION */
  SalesTypeOption = 'SalesTypeOPTION',
  /** Aggregate result on MinimumQuantity */
  MinimumQuantity = 'MinimumQuantity',
  /** Aggregate result on EndingDate */
  EndingDate = 'EndingDate',
  /** Aggregate result on RentalType */
  RentalType = 'RentalType',
  /** Aggregate result on RentalTypeOPTION */
  RentalTypeOption = 'RentalTypeOPTION',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on StairCode */
  StairCode = 'StairCode',
  /** Aggregate result on ReturnPriceDisc */
  ReturnPriceDisc = 'ReturnPriceDisc',
  /** Aggregate result on ReturnStairCode */
  ReturnStairCode = 'ReturnStairCode',
  /** Aggregate result on UsageType */
  UsageType = 'UsageType',
  /** Aggregate result on UsageTypeOPTION */
  UsageTypeOption = 'UsageTypeOPTION',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on NBAESDescription */
  NbaesDescription = 'NBAESDescription',
  /** Aggregate result on PriceonExtraHours */
  PriceonExtraHours = 'PriceonExtraHours',
  /** Aggregate result on PriceScheduleCode */
  PriceScheduleCode = 'PriceScheduleCode',
  /** Aggregate result on AllowLineDisconExHours */
  AllowLineDisconExHours = 'AllowLineDisconExHours',
  /** Aggregate result on PriceReportedHours */
  PriceReportedHours = 'PriceReportedHours',
  /** Aggregate result on FreeRentalHours */
  FreeRentalHours = 'FreeRentalHours',
  /** Aggregate result on MinimumReportedHours */
  MinimumReportedHours = 'MinimumReportedHours',
  /** Aggregate result on MinRepHoursPeriod */
  MinRepHoursPeriod = 'MinRepHoursPeriod',
  /** Aggregate result on ofSalesPrice */
  OfSalesPrice = 'ofSalesPrice',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on MinRentalPeriod */
  MinRentalPeriod = 'MinRentalPeriod',
  /** Aggregate result on StairPrice1 */
  StairPrice1 = 'StairPrice1',
  /** Aggregate result on StairPrice2 */
  StairPrice2 = 'StairPrice2',
  /** Aggregate result on StairPrice3 */
  StairPrice3 = 'StairPrice3',
  /** Aggregate result on StairPrice4 */
  StairPrice4 = 'StairPrice4',
  /** Aggregate result on StairPrice5 */
  StairPrice5 = 'StairPrice5',
  /** Aggregate result on MultipleRentalPrices */
  MultipleRentalPrices = 'MultipleRentalPrices',
  /** Aggregate result on FrameAgreementNo */
  FrameAgreementNo = 'FrameAgreementNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmReturnChargesInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ReqAction */
  ReqAction = 'ReqAction',
  /** Aggregate result on UseatFunctionalTest */
  UseatFunctionalTest = 'UseatFunctionalTest',
  /** Aggregate result on WorkType */
  WorkType = 'WorkType',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmServiceCardInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on ObjectSerialNo */
  ObjectSerialNo = 'ObjectSerialNo',
  /** Aggregate result on FunctionalTestsFlow */
  FunctionalTestsFlow = 'FunctionalTestsFlow',
  /** Aggregate result on WorkshopOrdersFlow */
  WorkshopOrdersFlow = 'WorkshopOrdersFlow',
  /** Aggregate result on WorkshopPurchasesFlow */
  WorkshopPurchasesFlow = 'WorkshopPurchasesFlow',
  /** Aggregate result on WorkshopPurchLinesFlow */
  WorkshopPurchLinesFlow = 'WorkshopPurchLinesFlow',
  /** Aggregate result on PlannedStartDate */
  PlannedStartDate = 'PlannedStartDate',
  /** Aggregate result on PlannedStartTime */
  PlannedStartTime = 'PlannedStartTime',
  /** Aggregate result on PlannedEndDate */
  PlannedEndDate = 'PlannedEndDate',
  /** Aggregate result on PlannedEndTime */
  PlannedEndTime = 'PlannedEndTime',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on StartTime */
  StartTime = 'StartTime',
  /** Aggregate result on EndDate */
  EndDate = 'EndDate',
  /** Aggregate result on EndTime */
  EndTime = 'EndTime',
  /** Aggregate result on PostedFunctionalTestsFlow */
  PostedFunctionalTestsFlow = 'PostedFunctionalTestsFlow',
  /** Aggregate result on PostedWorkshopOrdersFlow */
  PostedWorkshopOrdersFlow = 'PostedWorkshopOrdersFlow',
  /** Aggregate result on PostedWorkshopPurchFlow */
  PostedWorkshopPurchFlow = 'PostedWorkshopPurchFlow',
  /** Aggregate result on AvoidCalendarEntry */
  AvoidCalendarEntry = 'AvoidCalendarEntry',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ReturnDocumentNo */
  ReturnDocumentNo = 'ReturnDocumentNo',
  /** Aggregate result on ContractLineNo */
  ContractLineNo = 'ContractLineNo',
  /** Aggregate result on CollectionDocumentNo */
  CollectionDocumentNo = 'CollectionDocumentNo',
  /** Aggregate result on RepairCode */
  RepairCode = 'RepairCode',
  /** Aggregate result on MainResource */
  MainResource = 'MainResource',
  /** Aggregate result on MainCustomer */
  MainCustomer = 'MainCustomer',
  /** Aggregate result on MainVendor */
  MainVendor = 'MainVendor',
  /** Aggregate result on Manufacturer */
  Manufacturer = 'Manufacturer',
  /** Aggregate result on ManufacturerModel */
  ManufacturerModel = 'ManufacturerModel',
  /** Aggregate result on EmployeeNo */
  EmployeeNo = 'EmployeeNo',
  /** Aggregate result on InternalCustomerNo */
  InternalCustomerNo = 'InternalCustomerNo',
  /** Aggregate result on CurrentLocationCode */
  CurrentLocationCode = 'CurrentLocationCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on PurchaseDocumentType */
  PurchaseDocumentType = 'PurchaseDocumentType',
  /** Aggregate result on PurchaseDocumentTypeOPTION */
  PurchaseDocumentTypeOption = 'PurchaseDocumentTypeOPTION',
  /** Aggregate result on PurchaseDocumentNo */
  PurchaseDocumentNo = 'PurchaseDocumentNo',
  /** Aggregate result on PurchaseLineNo */
  PurchaseLineNo = 'PurchaseLineNo',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on ReceivingRespCenter */
  ReceivingRespCenter = 'ReceivingRespCenter',
  /** Aggregate result on ReceivingCostCenterCode */
  ReceivingCostCenterCode = 'ReceivingCostCenterCode',
  /** Aggregate result on ReceivingLocationCode */
  ReceivingLocationCode = 'ReceivingLocationCode',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on FinishedBy */
  FinishedBy = 'FinishedBy',
  /** Aggregate result on CreatedDate */
  CreatedDate = 'CreatedDate',
  /** Aggregate result on CreatedTime */
  CreatedTime = 'CreatedTime',
  /** Aggregate result on StartedBy */
  StartedBy = 'StartedBy',
  /** Aggregate result on NoofServiceOrders */
  NoofServiceOrders = 'NoofServiceOrders',
  /** Aggregate result on NoofPostedServiceOrders */
  NoofPostedServiceOrders = 'NoofPostedServiceOrders',
  /** Aggregate result on ControlTestsFlow */
  ControlTestsFlow = 'ControlTestsFlow',
  /** Aggregate result on PostedControlTestFlow */
  PostedControlTestFlow = 'PostedControlTestFlow',
  /** Aggregate result on FunctionalTestPictures */
  FunctionalTestPictures = 'FunctionalTestPictures',
  /** Aggregate result on ControlTypeCode */
  ControlTypeCode = 'ControlTypeCode',
  /** Aggregate result on WorkOrderFlow */
  WorkOrderFlow = 'WorkOrderFlow',
  /** Aggregate result on NoofReturnCharges */
  NoofReturnCharges = 'NoofReturnCharges',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmServiceTypeInput {
  /** Aggregate result on ServiceType */
  ServiceType = 'ServiceType',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ParallelwithRental */
  ParallelwithRental = 'ParallelwithRental',
  /** Aggregate result on NeedsWorkshopTime */
  NeedsWorkshopTime = 'NeedsWorkshopTime',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmTransferHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on TransferfromCode */
  TransferfromCode = 'TransferfromCode',
  /** Aggregate result on TransferfromName */
  TransferfromName = 'TransferfromName',
  /** Aggregate result on TransferfromName2 */
  TransferfromName2 = 'TransferfromName2',
  /** Aggregate result on TransferfromAddress */
  TransferfromAddress = 'TransferfromAddress',
  /** Aggregate result on TransferfromAddress2 */
  TransferfromAddress2 = 'TransferfromAddress2',
  /** Aggregate result on TransferfromPostCode */
  TransferfromPostCode = 'TransferfromPostCode',
  /** Aggregate result on TransferfromCity */
  TransferfromCity = 'TransferfromCity',
  /** Aggregate result on TransferfromCounty */
  TransferfromCounty = 'TransferfromCounty',
  /** Aggregate result on TrsffromCountryRegionCode */
  TrsffromCountryRegionCode = 'TrsffromCountryRegionCode',
  /** Aggregate result on TransfertoCode */
  TransfertoCode = 'TransfertoCode',
  /** Aggregate result on TransfertoName */
  TransfertoName = 'TransfertoName',
  /** Aggregate result on TransfertoName2 */
  TransfertoName2 = 'TransfertoName2',
  /** Aggregate result on TransfertoAddress */
  TransfertoAddress = 'TransfertoAddress',
  /** Aggregate result on TransfertoAddress2 */
  TransfertoAddress2 = 'TransfertoAddress2',
  /** Aggregate result on TransfertoPostCode */
  TransfertoPostCode = 'TransfertoPostCode',
  /** Aggregate result on TransfertoCity */
  TransfertoCity = 'TransfertoCity',
  /** Aggregate result on TransfertoCounty */
  TransfertoCounty = 'TransfertoCounty',
  /** Aggregate result on TrsftoCountryRegionCode */
  TrsftoCountryRegionCode = 'TrsftoCountryRegionCode',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on ReceiptDate */
  ReceiptDate = 'ReceiptDate',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on InTransitCode */
  InTransitCode = 'InTransitCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on LastShipmentNo */
  LastShipmentNo = 'LastShipmentNo',
  /** Aggregate result on LastReceiptNo */
  LastReceiptNo = 'LastReceiptNo',
  /** Aggregate result on TransferfromContact */
  TransferfromContact = 'TransferfromContact',
  /** Aggregate result on TransfertoContact */
  TransfertoContact = 'TransfertoContact',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on TransferOrderReference */
  TransferOrderReference = 'TransferOrderReference',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AssignedEmployeeNo */
  AssignedEmployeeNo = 'AssignedEmployeeNo',
  /** Aggregate result on EntryExitPoint */
  EntryExitPoint = 'EntryExitPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on DirectTransfer */
  DirectTransfer = 'DirectTransfer',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on RentalDispatchType */
  RentalDispatchType = 'RentalDispatchType',
  /** Aggregate result on RentalDispatchTypeOPTION */
  RentalDispatchTypeOption = 'RentalDispatchTypeOPTION',
  /** Aggregate result on RentalDispatchOrderNo */
  RentalDispatchOrderNo = 'RentalDispatchOrderNo',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on PostingfromWhseRef */
  PostingfromWhseRef = 'PostingfromWhseRef',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on CompletelyReceived */
  CompletelyReceived = 'CompletelyReceived',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on HasShippedLines */
  HasShippedLines = 'HasShippedLines',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on ReadyForPostShipment */
  ReadyForPostShipment = 'ReadyForPostShipment',
  /** Aggregate result on ReadyForPostReceipt */
  ReadyForPostReceipt = 'ReadyForPostReceipt',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmTransferLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on QtytoReceive */
  QtytoReceive = 'QtytoReceive',
  /** Aggregate result on QuantityShipped */
  QuantityShipped = 'QuantityShipped',
  /** Aggregate result on QuantityReceived */
  QuantityReceived = 'QuantityReceived',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on OutstandingQtyBase */
  OutstandingQtyBase = 'OutstandingQtyBase',
  /** Aggregate result on QtytoShipBase */
  QtytoShipBase = 'QtytoShipBase',
  /** Aggregate result on QtyShippedBase */
  QtyShippedBase = 'QtyShippedBase',
  /** Aggregate result on QtytoReceiveBase */
  QtytoReceiveBase = 'QtytoReceiveBase',
  /** Aggregate result on QtyReceivedBase */
  QtyReceivedBase = 'QtyReceivedBase',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on OutstandingQuantity */
  OutstandingQuantity = 'OutstandingQuantity',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on InTransitCode */
  InTransitCode = 'InTransitCode',
  /** Aggregate result on QtyinTransitBase */
  QtyinTransitBase = 'QtyinTransitBase',
  /** Aggregate result on TransferfromCode */
  TransferfromCode = 'TransferfromCode',
  /** Aggregate result on TransfertoCode */
  TransfertoCode = 'TransfertoCode',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on ReceiptDate */
  ReceiptDate = 'ReceiptDate',
  /** Aggregate result on DerivedFromLineNo */
  DerivedFromLineNo = 'DerivedFromLineNo',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on QtyinTransit */
  QtyinTransit = 'QtyinTransit',
  /** Aggregate result on ReservedQuantityInbnd */
  ReservedQuantityInbnd = 'ReservedQuantityInbnd',
  /** Aggregate result on ReservedQuantityOutbnd */
  ReservedQuantityOutbnd = 'ReservedQuantityOutbnd',
  /** Aggregate result on ReservedQtyInbndBase */
  ReservedQtyInbndBase = 'ReservedQtyInbndBase',
  /** Aggregate result on ReservedQtyOutbndBase */
  ReservedQtyOutbndBase = 'ReservedQtyOutbndBase',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on ReservedQuantityShipped */
  ReservedQuantityShipped = 'ReservedQuantityShipped',
  /** Aggregate result on ReservedQtyShippedBase */
  ReservedQtyShippedBase = 'ReservedQtyShippedBase',
  /** Aggregate result on DirectTransfer */
  DirectTransfer = 'DirectTransfer',
  /** Aggregate result on ChgCurrLocationOnly */
  ChgCurrLocationOnly = 'ChgCurrLocationOnly',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on AllocateObjectNo */
  AllocateObjectNo = 'AllocateObjectNo',
  /** Aggregate result on AllocateOwnershipStatus */
  AllocateOwnershipStatus = 'AllocateOwnershipStatus',
  /** Aggregate result on AllocateOwnershipStatusOPTION */
  AllocateOwnershipStatusOption = 'AllocateOwnershipStatusOPTION',
  /** Aggregate result on AllocateCreateFA */
  AllocateCreateFa = 'AllocateCreateFA',
  /** Aggregate result on AllocateSerialNo */
  AllocateSerialNo = 'AllocateSerialNo',
  /** Aggregate result on QuantitytoAllocate */
  QuantitytoAllocate = 'QuantitytoAllocate',
  /** Aggregate result on RentalDispatchType */
  RentalDispatchType = 'RentalDispatchType',
  /** Aggregate result on RentalDispatchTypeOPTION */
  RentalDispatchTypeOption = 'RentalDispatchTypeOPTION',
  /** Aggregate result on RentalDispatchOrderNo */
  RentalDispatchOrderNo = 'RentalDispatchOrderNo',
  /** Aggregate result on RentalDispatchOrderLineNo */
  RentalDispatchOrderLineNo = 'RentalDispatchOrderLineNo',
  /** Aggregate result on CustomerDamage */
  CustomerDamage = 'CustomerDamage',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on WhseInbndOtsdgQtyBase */
  WhseInbndOtsdgQtyBase = 'WhseInbndOtsdgQtyBase',
  /** Aggregate result on WhseOutbndOtsdgQtyBase */
  WhseOutbndOtsdgQtyBase = 'WhseOutbndOtsdgQtyBase',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on CompletelyReceived */
  CompletelyReceived = 'CompletelyReceived',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on TransferfromBinCode */
  TransferfromBinCode = 'TransferfromBinCode',
  /** Aggregate result on TransferToBinCode */
  TransferToBinCode = 'TransferToBinCode',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmTypeFunctionalTestInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on TestIssue */
  TestIssue = 'TestIssue',
  /** Aggregate result on AdditionalInstructions */
  AdditionalInstructions = 'AdditionalInstructions',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on NAAllowed */
  NaAllowed = 'NAAllowed',
  /** Aggregate result on ObjectTypeId */
  ObjectTypeId = 'ObjectTypeId',
  /** Aggregate result on NBAESHeadline */
  NbaesHeadline = 'NBAESHeadline',
  /** Aggregate result on NBAESSkillReq */
  NbaesSkillReq = 'NBAESSkillReq',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmWorkHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on FunctionalTestNo */
  FunctionalTestNo = 'FunctionalTestNo',
  /** Aggregate result on NoFailedTestIssues */
  NoFailedTestIssues = 'NoFailedTestIssues',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on InternalCustomerNo */
  InternalCustomerNo = 'InternalCustomerNo',
  /** Aggregate result on ContactNo */
  ContactNo = 'ContactNo',
  /** Aggregate result on ResourceNo */
  ResourceNo = 'ResourceNo',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on EQMServiceCardNo */
  EqmServiceCardNo = 'EQMServiceCardNo',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on ObjectSerialNo */
  ObjectSerialNo = 'ObjectSerialNo',
  /** Aggregate result on ObjectCounter */
  ObjectCounter = 'ObjectCounter',
  /** Aggregate result on ExternalWorkDescription */
  ExternalWorkDescription = 'ExternalWorkDescription',
  /** Aggregate result on InternalWorkDescription */
  InternalWorkDescription = 'InternalWorkDescription',
  /** Aggregate result on FromContractNo */
  FromContractNo = 'FromContractNo',
  /** Aggregate result on FromContractLineNo */
  FromContractLineNo = 'FromContractLineNo',
  /** Aggregate result on FromCustomerNo */
  FromCustomerNo = 'FromCustomerNo',
  /** Aggregate result on FromCustomerProjectNo */
  FromCustomerProjectNo = 'FromCustomerProjectNo',
  /** Aggregate result on FromCollectionDocNo */
  FromCollectionDocNo = 'FromCollectionDocNo',
  /** Aggregate result on FromCollectionLineNo */
  FromCollectionLineNo = 'FromCollectionLineNo',
  /** Aggregate result on FromPostedCollDocNo */
  FromPostedCollDocNo = 'FromPostedCollDocNo',
  /** Aggregate result on FromPostedCollLineNo */
  FromPostedCollLineNo = 'FromPostedCollLineNo',
  /** Aggregate result on FromReturnDocumentNo */
  FromReturnDocumentNo = 'FromReturnDocumentNo',
  /** Aggregate result on FromCostCenter */
  FromCostCenter = 'FromCostCenter',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on AssignedEmployeeNo */
  AssignedEmployeeNo = 'AssignedEmployeeNo',
  /** Aggregate result on RepairCode */
  RepairCode = 'RepairCode',
  /** Aggregate result on NextCertification */
  NextCertification = 'NextCertification',
  /** Aggregate result on NextService */
  NextService = 'NextService',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on ChargeType */
  ChargeType = 'ChargeType',
  /** Aggregate result on ChargeTypeOPTION */
  ChargeTypeOption = 'ChargeTypeOPTION',
  /** Aggregate result on ChargeDocType */
  ChargeDocType = 'ChargeDocType',
  /** Aggregate result on ChargeDocTypeOPTION */
  ChargeDocTypeOption = 'ChargeDocTypeOPTION',
  /** Aggregate result on ChargeDocNo */
  ChargeDocNo = 'ChargeDocNo',
  /** Aggregate result on ReceivingRespCenter */
  ReceivingRespCenter = 'ReceivingRespCenter',
  /** Aggregate result on ReceivingCostCenterCode */
  ReceivingCostCenterCode = 'ReceivingCostCenterCode',
  /** Aggregate result on ReceivingLocationCode */
  ReceivingLocationCode = 'ReceivingLocationCode',
  /** Aggregate result on CombineInvoices */
  CombineInvoices = 'CombineInvoices',
  /** Aggregate result on CombineMethod */
  CombineMethod = 'CombineMethod',
  /** Aggregate result on CombineMethodOPTION */
  CombineMethodOption = 'CombineMethodOPTION',
  /** Aggregate result on CombineInvperRespCenter */
  CombineInvperRespCenter = 'CombineInvperRespCenter',
  /** Aggregate result on PlannedStartDate */
  PlannedStartDate = 'PlannedStartDate',
  /** Aggregate result on PlannedStartTime */
  PlannedStartTime = 'PlannedStartTime',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on StartTime */
  StartTime = 'StartTime',
  /** Aggregate result on CompletedDate */
  CompletedDate = 'CompletedDate',
  /** Aggregate result on CompletedTime */
  CompletedTime = 'CompletedTime',
  /** Aggregate result on ApprovedbyUser */
  ApprovedbyUser = 'ApprovedbyUser',
  /** Aggregate result on AllowPartialInvoicing */
  AllowPartialInvoicing = 'AllowPartialInvoicing',
  /** Aggregate result on ExternalCustomerPriceGroup */
  ExternalCustomerPriceGroup = 'ExternalCustomerPriceGroup',
  /** Aggregate result on ExternalCustomerDiscGroup */
  ExternalCustomerDiscGroup = 'ExternalCustomerDiscGroup',
  /** Aggregate result on InternalCustomerPriceGroup */
  InternalCustomerPriceGroup = 'InternalCustomerPriceGroup',
  /** Aggregate result on InternalCustomerDiscGroup */
  InternalCustomerDiscGroup = 'InternalCustomerDiscGroup',
  /** Aggregate result on CustomerDamage */
  CustomerDamage = 'CustomerDamage',
  /** Aggregate result on Pictures */
  Pictures = 'Pictures',
  /** Aggregate result on UnpostedWorkOrderLines */
  UnpostedWorkOrderLines = 'UnpostedWorkOrderLines',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateEqmWorkLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on LineStatus */
  LineStatus = 'LineStatus',
  /** Aggregate result on LineStatusOPTION */
  LineStatusOption = 'LineStatusOPTION',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on OverruledUnitPrice */
  OverruledUnitPrice = 'OverruledUnitPrice',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on ExternalCustomerPriceGroup */
  ExternalCustomerPriceGroup = 'ExternalCustomerPriceGroup',
  /** Aggregate result on ExternalCustomerDiscGroup */
  ExternalCustomerDiscGroup = 'ExternalCustomerDiscGroup',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on ChargeType */
  ChargeType = 'ChargeType',
  /** Aggregate result on ChargeTypeOPTION */
  ChargeTypeOption = 'ChargeTypeOPTION',
  /** Aggregate result on ChargeDocType */
  ChargeDocType = 'ChargeDocType',
  /** Aggregate result on ChargeDocTypeOPTION */
  ChargeDocTypeOption = 'ChargeDocTypeOPTION',
  /** Aggregate result on ChargeDocNo */
  ChargeDocNo = 'ChargeDocNo',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on Transferred */
  Transferred = 'Transferred',
  /** Aggregate result on TransferredtoDocNo */
  TransferredtoDocNo = 'TransferredtoDocNo',
  /** Aggregate result on TransferredtoDocLineNo */
  TransferredtoDocLineNo = 'TransferredtoDocLineNo',
  /** Aggregate result on PostedSalesLine */
  PostedSalesLine = 'PostedSalesLine',
  /** Aggregate result on InternalCustomerPriceGroup */
  InternalCustomerPriceGroup = 'InternalCustomerPriceGroup',
  /** Aggregate result on InternalCustomerDiscGroup */
  InternalCustomerDiscGroup = 'InternalCustomerDiscGroup',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on CreatedfromAPI */
  CreatedfromApi = 'CreatedfromAPI',
  /** Aggregate result on PatchedfromAPI */
  PatchedfromApi = 'PatchedfromAPI',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateErrandInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Header */
  Header = 'Header',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on ErrandType */
  ErrandType = 'ErrandType',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on Email */
  Email = 'Email',
  /** Aggregate result on Dim1 */
  Dim1 = 'Dim1',
  /** Aggregate result on Dim2 */
  Dim2 = 'Dim2',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on ActionBlob */
  ActionBlob = 'ActionBlob',
  /** Aggregate result on MainSource */
  MainSource = 'MainSource',
  /** Aggregate result on CreatedFromRecord */
  CreatedFromRecord = 'CreatedFromRecord',
  /** Aggregate result on TimeCost */
  TimeCost = 'TimeCost',
  /** Aggregate result on Cost */
  Cost = 'Cost',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateErrandLineInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ConnectedToRecord */
  ConnectedToRecord = 'ConnectedToRecord',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateErrorReportInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on ReasonText */
  ReasonText = 'ReasonText',
  /** Aggregate result on DateTime */
  DateTime = 'DateTime',
  /** Aggregate result on Caller */
  Caller = 'Caller',
  /** Aggregate result on CallerPhone */
  CallerPhone = 'CallerPhone',
  /** Aggregate result on CallerEmail */
  CallerEmail = 'CallerEmail',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ActionCode */
  ActionCode = 'ActionCode',
  /** Aggregate result on ActionText */
  ActionText = 'ActionText',
  /** Aggregate result on CurrentHandler */
  CurrentHandler = 'CurrentHandler',
  /** Aggregate result on CurrentHandlerPhone */
  CurrentHandlerPhone = 'CurrentHandlerPhone',
  /** Aggregate result on CurrentHandlerEmail */
  CurrentHandlerEmail = 'CurrentHandlerEmail',
  /** Aggregate result on TurnedOverTo */
  TurnedOverTo = 'TurnedOverTo',
  /** Aggregate result on Recived */
  Recived = 'Recived',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFeedbackInput {
  /** Aggregate result on Text */
  Text = 'Text',
  /** Aggregate result on ByUserEmail */
  ByUserEmail = 'ByUserEmail',
  /** Aggregate result on ByUserName */
  ByUserName = 'ByUserName',
  /** Aggregate result on FileGlobalIds */
  FileGlobalIds = 'FileGlobalIds',
  /** Aggregate result on CreatedOn */
  CreatedOn = 'CreatedOn',
  /** Aggregate result on Processed */
  Processed = 'Processed',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFeemappingInput {
  /** Aggregate result on LCYPrice */
  LcyPrice = 'LCYPrice',
  /** Aggregate result on LCYPriceIncVat */
  LcyPriceIncVat = 'LCYPriceIncVat',
  /** Aggregate result on EURPrice */
  EurPrice = 'EURPrice',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Unit */
  Unit = 'Unit',
  /** Aggregate result on Fee */
  Fee = 'Fee',
  /** Aggregate result on PriceType */
  PriceType = 'PriceType',
  /** Aggregate result on TypeOfFee */
  TypeOfFee = 'TypeOfFee',
  /** Aggregate result on FeeItemNumber */
  FeeItemNumber = 'FeeItemNumber',
  /** Aggregate result on BaseQuantity */
  BaseQuantity = 'BaseQuantity',
  /** Aggregate result on WebDescription */
  WebDescription = 'WebDescription',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFieldOrderInput {
  /** Aggregate result on FieldOrderCode */
  FieldOrderCode = 'FieldOrderCode',
  /** Aggregate result on ExecutionTime */
  ExecutionTime = 'ExecutionTime',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on WorkDescription */
  WorkDescription = 'WorkDescription',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on AssignmentType */
  AssignmentType = 'AssignmentType',
  /** Aggregate result on AssignmentTypeOPTION */
  AssignmentTypeOption = 'AssignmentTypeOPTION',
  /** Aggregate result on AssignedTo */
  AssignedTo = 'AssignedTo',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PostedInvoiceNo */
  PostedInvoiceNo = 'PostedInvoiceNo',
  /** Aggregate result on ReversedVAT */
  ReversedVat = 'ReversedVAT',
  /** Aggregate result on ContactNo */
  ContactNo = 'ContactNo',
  /** Aggregate result on ContactPhone */
  ContactPhone = 'ContactPhone',
  /** Aggregate result on Instructions */
  Instructions = 'Instructions',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on NoteFromPreformer */
  NoteFromPreformer = 'NoteFromPreformer',
  /** Aggregate result on ObjectDescription */
  ObjectDescription = 'ObjectDescription',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFieldOrderLineInput {
  /** Aggregate result on FieldOrderCode */
  FieldOrderCode = 'FieldOrderCode',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFileInput {
  /** Aggregate result on link */
  Link = 'link',
  /** Aggregate result on name */
  Name = 'name',
  /** Aggregate result on type */
  Type = 'type',
  /** Aggregate result on etag */
  Etag = 'etag',
  /** Aggregate result on base64 */
  Base64 = 'base64',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFunctionTestHeaderInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Resource */
  Resource = 'Resource',
  /** Aggregate result on CreateDate */
  CreateDate = 'CreateDate',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on FinishDate */
  FinishDate = 'FinishDate',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ApprovalStatus */
  ApprovalStatus = 'ApprovalStatus',
  /** Aggregate result on ApprovalStatusOPTION */
  ApprovalStatusOption = 'ApprovalStatusOPTION',
  /** Aggregate result on EQMServiceHeader */
  EqmServiceHeader = 'EQMServiceHeader',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on FromContractNo */
  FromContractNo = 'FromContractNo',
  /** Aggregate result on FromCustomerNo */
  FromCustomerNo = 'FromCustomerNo',
  /** Aggregate result on FromReturnDocumentNo */
  FromReturnDocumentNo = 'FromReturnDocumentNo',
  /** Aggregate result on FromCollectionDocNo */
  FromCollectionDocNo = 'FromCollectionDocNo',
  /** Aggregate result on CostCenter */
  CostCenter = 'CostCenter',
  /** Aggregate result on DimCode */
  DimCode = 'DimCode',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on StartTime */
  StartTime = 'StartTime',
  /** Aggregate result on FinishTime */
  FinishTime = 'FinishTime',
  /** Aggregate result on Signature */
  Signature = 'Signature',
  /** Aggregate result on FinishedBy */
  FinishedBy = 'FinishedBy',
  /** Aggregate result on TypeOfWash */
  TypeOfWash = 'TypeOfWash',
  /** Aggregate result on ExternalPart */
  ExternalPart = 'ExternalPart',
  /** Aggregate result on FncFailed */
  FncFailed = 'FncFailed',
  /** Aggregate result on HourMeterIndicator */
  HourMeterIndicator = 'HourMeterIndicator',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on FromCustomerName */
  FromCustomerName = 'FromCustomerName',
  /** Aggregate result on ObjectDescription */
  ObjectDescription = 'ObjectDescription',
  /** Aggregate result on WarrantyDate */
  WarrantyDate = 'WarrantyDate',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on SignatureFromEdge */
  SignatureFromEdge = 'SignatureFromEdge',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateFunctionTestLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on TestIssue */
  TestIssue = 'TestIssue',
  /** Aggregate result on CurrentStatus */
  CurrentStatus = 'CurrentStatus',
  /** Aggregate result on AdditionalInstructions */
  AdditionalInstructions = 'AdditionalInstructions',
  /** Aggregate result on SpecificMeasure */
  SpecificMeasure = 'SpecificMeasure',
  /** Aggregate result on DirectMeasure */
  DirectMeasure = 'DirectMeasure',
  /** Aggregate result on InspectionTimeInterval */
  InspectionTimeInterval = 'InspectionTimeInterval',
  /** Aggregate result on ToCorrect */
  ToCorrect = 'ToCorrect',
  /** Aggregate result on WorkShopOrderNo */
  WorkShopOrderNo = 'WorkShopOrderNo',
  /** Aggregate result on WorkShopOrderFinished */
  WorkShopOrderFinished = 'WorkShopOrderFinished',
  /** Aggregate result on Headline */
  Headline = 'Headline',
  /** Aggregate result on NoteFunctionTest */
  NoteFunctionTest = 'NoteFunctionTest',
  /** Aggregate result on NoteDate */
  NoteDate = 'NoteDate',
  /** Aggregate result on NoteOwner */
  NoteOwner = 'NoteOwner',
  /** Aggregate result on MoveNote */
  MoveNote = 'MoveNote',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on StartTime */
  StartTime = 'StartTime',
  /** Aggregate result on Mandatory */
  Mandatory = 'Mandatory',
  /** Aggregate result on TimeEstimation */
  TimeEstimation = 'TimeEstimation',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on TestedApproved */
  TestedApproved = 'TestedApproved',
  /** Aggregate result on TestedExecuted */
  TestedExecuted = 'TestedExecuted',
  /** Aggregate result on ExecutedBy */
  ExecutedBy = 'ExecutedBy',
  /** Aggregate result on ApprovedBy */
  ApprovedBy = 'ApprovedBy',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateGenBusinessPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on DefVATBusPostingGroup */
  DefVatBusPostingGroup = 'DefVATBusPostingGroup',
  /** Aggregate result on AutoInsertDefault */
  AutoInsertDefault = 'AutoInsertDefault',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateGenJournalLineInput {
  /** Aggregate result on JournalTemplateName */
  JournalTemplateName = 'JournalTemplateName',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on AccountType */
  AccountType = 'AccountType',
  /** Aggregate result on AccountTypeOPTION */
  AccountTypeOption = 'AccountTypeOPTION',
  /** Aggregate result on AccountNo */
  AccountNo = 'AccountNo',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on DebitAmount */
  DebitAmount = 'DebitAmount',
  /** Aggregate result on CreditAmount */
  CreditAmount = 'CreditAmount',
  /** Aggregate result on AmountLCY */
  AmountLcy = 'AmountLCY',
  /** Aggregate result on BalanceLCY */
  BalanceLcy = 'BalanceLCY',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on SalesPurchLCY */
  SalesPurchLcy = 'SalesPurchLCY',
  /** Aggregate result on ProfitLCY */
  ProfitLcy = 'ProfitLCY',
  /** Aggregate result on InvDiscountLCY */
  InvDiscountLcy = 'InvDiscountLCY',
  /** Aggregate result on BilltoPaytoNo */
  BilltoPaytoNo = 'BilltoPaytoNo',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on SalespersPurchCode */
  SalespersPurchCode = 'SalespersPurchCode',
  /** Aggregate result on PendingApproval */
  PendingApproval = 'PendingApproval',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on VATAmount */
  VatAmount = 'VATAmount',
  /** Aggregate result on VATPosting */
  VatPosting = 'VATPosting',
  /** Aggregate result on VATPostingOPTION */
  VatPostingOption = 'VATPostingOPTION',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on AppliestoID */
  AppliestoId = 'AppliestoID',
  /** Aggregate result on BusinessUnitCode */
  BusinessUnitCode = 'BusinessUnitCode',
  /** Aggregate result on JournalBatchName */
  JournalBatchName = 'JournalBatchName',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on RecurringMethod */
  RecurringMethod = 'RecurringMethod',
  /** Aggregate result on RecurringMethodOPTION */
  RecurringMethodOption = 'RecurringMethodOPTION',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on RecurringFrequency */
  RecurringFrequency = 'RecurringFrequency',
  /** Aggregate result on AllocatedAmtLCY */
  AllocatedAmtLcy = 'AllocatedAmtLCY',
  /** Aggregate result on GenPostingType */
  GenPostingType = 'GenPostingType',
  /** Aggregate result on GenPostingTypeOPTION */
  GenPostingTypeOption = 'GenPostingTypeOPTION',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on AllowApplication */
  AllowApplication = 'AllowApplication',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on BalGenPostingType */
  BalGenPostingType = 'BalGenPostingType',
  /** Aggregate result on BalGenPostingTypeOPTION */
  BalGenPostingTypeOption = 'BalGenPostingTypeOPTION',
  /** Aggregate result on BalGenBusPostingGroup */
  BalGenBusPostingGroup = 'BalGenBusPostingGroup',
  /** Aggregate result on BalGenProdPostingGroup */
  BalGenProdPostingGroup = 'BalGenProdPostingGroup',
  /** Aggregate result on BalVATCalculationType */
  BalVatCalculationType = 'BalVATCalculationType',
  /** Aggregate result on BalVATCalculationTypeOPTION */
  BalVatCalculationTypeOption = 'BalVATCalculationTypeOPTION',
  /** Aggregate result on BalVAT */
  BalVat = 'BalVAT',
  /** Aggregate result on BalVATAmount */
  BalVatAmount = 'BalVATAmount',
  /** Aggregate result on BankPaymentType */
  BankPaymentType = 'BankPaymentType',
  /** Aggregate result on BankPaymentTypeOPTION */
  BankPaymentTypeOption = 'BankPaymentTypeOPTION',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on BalVATBaseAmount */
  BalVatBaseAmount = 'BalVATBaseAmount',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on PrintPostedDocuments */
  PrintPostedDocuments = 'PrintPostedDocuments',
  /** Aggregate result on CheckPrinted */
  CheckPrinted = 'CheckPrinted',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on SourceType */
  SourceType = 'SourceType',
  /** Aggregate result on SourceTypeOPTION */
  SourceTypeOption = 'SourceTypeOPTION',
  /** Aggregate result on SourceNo */
  SourceNo = 'SourceNo',
  /** Aggregate result on PostingNoSeries */
  PostingNoSeries = 'PostingNoSeries',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on UseTax */
  UseTax = 'UseTax',
  /** Aggregate result on BalTaxAreaCode */
  BalTaxAreaCode = 'BalTaxAreaCode',
  /** Aggregate result on BalTaxLiable */
  BalTaxLiable = 'BalTaxLiable',
  /** Aggregate result on BalTaxGroupCode */
  BalTaxGroupCode = 'BalTaxGroupCode',
  /** Aggregate result on BalUseTax */
  BalUseTax = 'BalUseTax',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on BalVATBusPostingGroup */
  BalVatBusPostingGroup = 'BalVATBusPostingGroup',
  /** Aggregate result on BalVATProdPostingGroup */
  BalVatProdPostingGroup = 'BalVATProdPostingGroup',
  /** Aggregate result on AdditionalCurrencyPosting */
  AdditionalCurrencyPosting = 'AdditionalCurrencyPosting',
  /** Aggregate result on AdditionalCurrencyPostingOPTION */
  AdditionalCurrencyPostingOption = 'AdditionalCurrencyPostingOPTION',
  /** Aggregate result on FAAddCurrencyFactor */
  FaAddCurrencyFactor = 'FAAddCurrencyFactor',
  /** Aggregate result on SourceCurrencyCode */
  SourceCurrencyCode = 'SourceCurrencyCode',
  /** Aggregate result on SourceCurrencyAmount */
  SourceCurrencyAmount = 'SourceCurrencyAmount',
  /** Aggregate result on SourceCurrVATBaseAmount */
  SourceCurrVatBaseAmount = 'SourceCurrVATBaseAmount',
  /** Aggregate result on SourceCurrVATAmount */
  SourceCurrVatAmount = 'SourceCurrVATAmount',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on VATAmountLCY */
  VatAmountLcy = 'VATAmountLCY',
  /** Aggregate result on VATBaseAmountLCY */
  VatBaseAmountLcy = 'VATBaseAmountLCY',
  /** Aggregate result on BalVATAmountLCY */
  BalVatAmountLcy = 'BalVATAmountLCY',
  /** Aggregate result on BalVATBaseAmountLCY */
  BalVatBaseAmountLcy = 'BalVATBaseAmountLCY',
  /** Aggregate result on ReversingEntry */
  ReversingEntry = 'ReversingEntry',
  /** Aggregate result on AllowZeroAmountPosting */
  AllowZeroAmountPosting = 'AllowZeroAmountPosting',
  /** Aggregate result on ShiptoOrderAddressCode */
  ShiptoOrderAddressCode = 'ShiptoOrderAddressCode',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on BalVATDifference */
  BalVatDifference = 'BalVATDifference',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on ICDirection */
  IcDirection = 'ICDirection',
  /** Aggregate result on ICDirectionOPTION */
  IcDirectionOption = 'ICDirectionOPTION',
  /** Aggregate result on ICPartnerGLAccNo */
  IcPartnerGlAccNo = 'ICPartnerGLAccNo',
  /** Aggregate result on ICPartnerTransactionNo */
  IcPartnerTransactionNo = 'ICPartnerTransactionNo',
  /** Aggregate result on SelltoBuyfromNo */
  SelltoBuyfromNo = 'SelltoBuyfromNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on FinancialVoid */
  FinancialVoid = 'FinancialVoid',
  /** Aggregate result on CopyVATSetuptoJnlLines */
  CopyVatSetuptoJnlLines = 'CopyVATSetuptoJnlLines',
  /** Aggregate result on VATBaseBeforePmtDisc */
  VatBaseBeforePmtDisc = 'VATBaseBeforePmtDisc',
  /** Aggregate result on OrigPmtDiscPossible */
  OrigPmtDiscPossible = 'OrigPmtDiscPossible',
  /** Aggregate result on OrigPmtDiscPossibleLCY */
  OrigPmtDiscPossibleLcy = 'OrigPmtDiscPossibleLCY',
  /** Aggregate result on JobQueueStatus */
  JobQueueStatus = 'JobQueueStatus',
  /** Aggregate result on JobQueueStatusOPTION */
  JobQueueStatusOption = 'JobQueueStatusOPTION',
  /** Aggregate result on JobQueueEntryID */
  JobQueueEntryId = 'JobQueueEntryID',
  /** Aggregate result on IncomingDocumentEntryNo */
  IncomingDocumentEntryNo = 'IncomingDocumentEntryNo',
  /** Aggregate result on CreditorNo */
  CreditorNo = 'CreditorNo',
  /** Aggregate result on PaymentReference */
  PaymentReference = 'PaymentReference',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on AppliestoExtDocNo */
  AppliestoExtDocNo = 'AppliestoExtDocNo',
  /** Aggregate result on KeepDescription */
  KeepDescription = 'KeepDescription',
  /** Aggregate result on RecipientBankAccount */
  RecipientBankAccount = 'RecipientBankAccount',
  /** Aggregate result on MessagetoRecipient */
  MessagetoRecipient = 'MessagetoRecipient',
  /** Aggregate result on ExportedtoPaymentFile */
  ExportedtoPaymentFile = 'ExportedtoPaymentFile',
  /** Aggregate result on HasPaymentExportError */
  HasPaymentExportError = 'HasPaymentExportError',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobUnitPriceLCY */
  JobUnitPriceLcy = 'JobUnitPriceLCY',
  /** Aggregate result on JobTotalPriceLCY */
  JobTotalPriceLcy = 'JobTotalPriceLCY',
  /** Aggregate result on JobQuantity */
  JobQuantity = 'JobQuantity',
  /** Aggregate result on JobUnitCostLCY */
  JobUnitCostLcy = 'JobUnitCostLCY',
  /** Aggregate result on JobLineDiscount */
  JobLineDiscount = 'JobLineDiscount',
  /** Aggregate result on JobLineDiscAmountLCY */
  JobLineDiscAmountLcy = 'JobLineDiscAmountLCY',
  /** Aggregate result on JobUnitOfMeasureCode */
  JobUnitOfMeasureCode = 'JobUnitOfMeasureCode',
  /** Aggregate result on JobLineType */
  JobLineType = 'JobLineType',
  /** Aggregate result on JobLineTypeOPTION */
  JobLineTypeOption = 'JobLineTypeOPTION',
  /** Aggregate result on JobUnitPrice */
  JobUnitPrice = 'JobUnitPrice',
  /** Aggregate result on JobTotalPrice */
  JobTotalPrice = 'JobTotalPrice',
  /** Aggregate result on JobUnitCost */
  JobUnitCost = 'JobUnitCost',
  /** Aggregate result on JobTotalCost */
  JobTotalCost = 'JobTotalCost',
  /** Aggregate result on JobLineDiscountAmount */
  JobLineDiscountAmount = 'JobLineDiscountAmount',
  /** Aggregate result on JobLineAmount */
  JobLineAmount = 'JobLineAmount',
  /** Aggregate result on JobTotalCostLCY */
  JobTotalCostLcy = 'JobTotalCostLCY',
  /** Aggregate result on JobLineAmountLCY */
  JobLineAmountLcy = 'JobLineAmountLCY',
  /** Aggregate result on JobCurrencyFactor */
  JobCurrencyFactor = 'JobCurrencyFactor',
  /** Aggregate result on JobCurrencyCode */
  JobCurrencyCode = 'JobCurrencyCode',
  /** Aggregate result on JobPlanningLineNo */
  JobPlanningLineNo = 'JobPlanningLineNo',
  /** Aggregate result on JobRemainingQty */
  JobRemainingQty = 'JobRemainingQty',
  /** Aggregate result on DirectDebitMandateID */
  DirectDebitMandateId = 'DirectDebitMandateID',
  /** Aggregate result on DataExchEntryNo */
  DataExchEntryNo = 'DataExchEntryNo',
  /** Aggregate result on PayerInformation */
  PayerInformation = 'PayerInformation',
  /** Aggregate result on TransactionInformation */
  TransactionInformation = 'TransactionInformation',
  /** Aggregate result on DataExchLineNo */
  DataExchLineNo = 'DataExchLineNo',
  /** Aggregate result on AppliedAutomatically */
  AppliedAutomatically = 'AppliedAutomatically',
  /** Aggregate result on LinkedTableID */
  LinkedTableId = 'LinkedTableID',
  /** Aggregate result on LinkedSystemID */
  LinkedSystemId = 'LinkedSystemID',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on DeferralLineNo */
  DeferralLineNo = 'DeferralLineNo',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on ProdOrderNo */
  ProdOrderNo = 'ProdOrderNo',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on FAPostingType */
  FaPostingType = 'FAPostingType',
  /** Aggregate result on FAPostingTypeOPTION */
  FaPostingTypeOption = 'FAPostingTypeOPTION',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on SalvageValue */
  SalvageValue = 'SalvageValue',
  /** Aggregate result on NoofDepreciationDays */
  NoofDepreciationDays = 'NoofDepreciationDays',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DeprAcquisitionCost */
  DeprAcquisitionCost = 'DeprAcquisitionCost',
  /** Aggregate result on MaintenanceCode */
  MaintenanceCode = 'MaintenanceCode',
  /** Aggregate result on InsuranceNo */
  InsuranceNo = 'InsuranceNo',
  /** Aggregate result on BudgetedFANo */
  BudgetedFaNo = 'BudgetedFANo',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on FAReclassificationEntry */
  FaReclassificationEntry = 'FAReclassificationEntry',
  /** Aggregate result on FAErrorEntryNo */
  FaErrorEntryNo = 'FAErrorEntryNo',
  /** Aggregate result on IndexEntry */
  IndexEntry = 'IndexEntry',
  /** Aggregate result on SourceLineNo */
  SourceLineNo = 'SourceLineNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on CheckExported */
  CheckExported = 'CheckExported',
  /** Aggregate result on CheckTransmitted */
  CheckTransmitted = 'CheckTransmitted',
  /** Aggregate result on ReverseDateCalculation */
  ReverseDateCalculation = 'ReverseDateCalculation',
  /** Aggregate result on AccountId */
  AccountId = 'AccountId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on AppliestoInvoiceId */
  AppliestoInvoiceId = 'AppliestoInvoiceId',
  /** Aggregate result on ContactGraphId */
  ContactGraphId = 'ContactGraphId',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on JournalBatchId */
  JournalBatchId = 'JournalBatchId',
  /** Aggregate result on PaymentMethodId */
  PaymentMethodId = 'PaymentMethodId',
  /** Aggregate result on BalanceAccountId */
  BalanceAccountId = 'BalanceAccountId',
  /** Aggregate result on VendorId */
  VendorId = 'VendorId',
  /** Aggregate result on SourcePostingDate */
  SourcePostingDate = 'SourcePostingDate',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on PEBDirectDebitPackNo */
  PebDirectDebitPackNo = 'PEBDirectDebitPackNo',
  /** Aggregate result on PEBInformationType */
  PebInformationType = 'PEBInformationType',
  /** Aggregate result on PEBInformationTypeOPTION */
  PebInformationTypeOption = 'PEBInformationTypeOPTION',
  /** Aggregate result on PEBLastGLEntryNo */
  PebLastGlEntryNo = 'PEBLastGLEntryNo',
  /** Aggregate result on PEBLastGLRegEntryNo */
  PebLastGlRegEntryNo = 'PEBLastGLRegEntryNo',
  /** Aggregate result on PEBInformation */
  PebInformation = 'PEBInformation',
  /** Aggregate result on PEBSIEImportCode */
  PebsieImportCode = 'PEBSIEImportCode',
  /** Aggregate result on PEBOCRNo */
  PebocrNo = 'PEBOCRNo',
  /** Aggregate result on PEBExtDocNonotMandat */
  PebExtDocNonotMandat = 'PEBExtDocNonotMandat',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMSkipExtraPost */
  EqmSkipExtraPost = 'EQMSkipExtraPost',
  /** Aggregate result on EQMDepositPosting */
  EqmDepositPosting = 'EQMDepositPosting',
  /** Aggregate result on EQMCustomerDepositNo */
  EqmCustomerDepositNo = 'EQMCustomerDepositNo',
  /** Aggregate result on EQMDisposalType */
  EqmDisposalType = 'EQMDisposalType',
  /** Aggregate result on EQMDisposalTypeOPTION */
  EqmDisposalTypeOption = 'EQMDisposalTypeOPTION',
  /** Aggregate result on EQMDepositEntry */
  EqmDepositEntry = 'EQMDepositEntry',
  /** Aggregate result on EQMAdvancePaymentEntry */
  EqmAdvancePaymentEntry = 'EQMAdvancePaymentEntry',
  /** Aggregate result on EQMAdvPaymentAmountLCY */
  EqmAdvPaymentAmountLcy = 'EQMAdvPaymentAmountLCY',
  /** Aggregate result on EQMRentalEntry */
  EqmRentalEntry = 'EQMRentalEntry',
  /** Aggregate result on EQMWorkshopEntry */
  EqmWorkshopEntry = 'EQMWorkshopEntry',
  /** Aggregate result on EQMObjectErrorEntryNo */
  EqmObjectErrorEntryNo = 'EQMObjectErrorEntryNo',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMDepositPayment */
  EqmDepositPayment = 'EQMDepositPayment',
  /** Aggregate result on EQMTransactionID */
  EqmTransactionId = 'EQMTransactionID',
  /** Aggregate result on EQMFANo */
  EqmfaNo = 'EQMFANo',
  /** Aggregate result on EQMFADepreciationBookCode */
  EqmfaDepreciationBookCode = 'EQMFADepreciationBookCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateGenProductPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on DefVATProdPostingGroup */
  DefVatProdPostingGroup = 'DefVATProdPostingGroup',
  /** Aggregate result on AutoInsertDefault */
  AutoInsertDefault = 'AutoInsertDefault',
  /** Aggregate result on EQMPercentofMonthlyPrice */
  EqmPercentofMonthlyPrice = 'EQMPercentofMonthlyPrice',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateInspectionInput {
  /** Aggregate result on ID */
  Id = 'ID',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on InspectionDate */
  InspectionDate = 'InspectionDate',
  /** Aggregate result on ObjectStatus */
  ObjectStatus = 'ObjectStatus',
  /** Aggregate result on ObjectStatusOPTION */
  ObjectStatusOption = 'ObjectStatusOPTION',
  /** Aggregate result on Document */
  Document = 'Document',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on UserEmail */
  UserEmail = 'UserEmail',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateInspectionUserInput {
  /** Aggregate result on SalesPerson */
  SalesPerson = 'SalesPerson',
  /** Aggregate result on UserEmail */
  UserEmail = 'UserEmail',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateIntegrationCollectionInput {
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on Active */
  Active = 'Active',
  /** Aggregate result on LatestHeartBeat */
  LatestHeartBeat = 'LatestHeartBeat',
  /** Aggregate result on FirstHeartBeat */
  FirstHeartBeat = 'FirstHeartBeat',
  /** Aggregate result on HeartBeartIntervalMinutes */
  HeartBeartIntervalMinutes = 'HeartBeartIntervalMinutes',
  /** Aggregate result on HeartbeatMonitoringEnabled */
  HeartbeatMonitoringEnabled = 'HeartbeatMonitoringEnabled',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateInventoryPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateInvitationInput {
  /** Aggregate result on UserEmail */
  UserEmail = 'UserEmail',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on Role */
  Role = 'Role',
  /** Aggregate result on UserName */
  UserName = 'UserName',
  /** Aggregate result on CreatedOn */
  CreatedOn = 'CreatedOn',
  /** Aggregate result on ExpiresOn */
  ExpiresOn = 'ExpiresOn',
  /** Aggregate result on AcceptedOn */
  AcceptedOn = 'AcceptedOn',
  /** Aggregate result on AcceptedByUserEmail */
  AcceptedByUserEmail = 'AcceptedByUserEmail',
  /** Aggregate result on CreatedByUserEmail */
  CreatedByUserEmail = 'CreatedByUserEmail',
  /** Aggregate result on CreateByUserName */
  CreateByUserName = 'CreateByUserName',
  /** Aggregate result on InvitationCode */
  InvitationCode = 'InvitationCode',
  /** Aggregate result on RegistrationNo */
  RegistrationNo = 'RegistrationNo',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on ProjectNos */
  ProjectNos = 'ProjectNos',
  /** Aggregate result on Deactivated */
  Deactivated = 'Deactivated',
  /** Aggregate result on IOSAppLink */
  IosAppLink = 'IOSAppLink',
  /** Aggregate result on AndroidAppLink */
  AndroidAppLink = 'AndroidAppLink',
  /** Aggregate result on IOSAppCode */
  IosAppCode = 'IOSAppCode',
  /** Aggregate result on IOSAppCodes */
  IosAppCodes = 'IOSAppCodes',
  /** Aggregate result on IsOnAppStore */
  IsOnAppStore = 'IsOnAppStore',
  /** Aggregate result on IsOnPlayStore */
  IsOnPlayStore = 'IsOnPlayStore',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateInvoiceHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPostingGroup */
  CustomerPostingGroup = 'CustomerPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on SelltoCustomerName */
  SelltoCustomerName = 'SelltoCustomerName',
  /** Aggregate result on SelltoCustomerName2 */
  SelltoCustomerName2 = 'SelltoCustomerName2',
  /** Aggregate result on SelltoAddress */
  SelltoAddress = 'SelltoAddress',
  /** Aggregate result on SelltoAddress2 */
  SelltoAddress2 = 'SelltoAddress2',
  /** Aggregate result on SelltoCity */
  SelltoCity = 'SelltoCity',
  /** Aggregate result on SelltoContact */
  SelltoContact = 'SelltoContact',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on SelltoPostCode */
  SelltoPostCode = 'SelltoPostCode',
  /** Aggregate result on SelltoCounty */
  SelltoCounty = 'SelltoCounty',
  /** Aggregate result on SelltoCountryRegionCode */
  SelltoCountryRegionCode = 'SelltoCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on PreAssignedNoSeries */
  PreAssignedNoSeries = 'PreAssignedNoSeries',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on OrderNoSeries */
  OrderNoSeries = 'OrderNoSeries',
  /** Aggregate result on PreAssignedNo */
  PreAssignedNo = 'PreAssignedNo',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on InvoiceDiscountCalculation */
  InvoiceDiscountCalculation = 'InvoiceDiscountCalculation',
  /** Aggregate result on InvoiceDiscountValue */
  InvoiceDiscountValue = 'InvoiceDiscountValue',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on PrepaymentInvoice */
  PrepaymentInvoice = 'PrepaymentInvoice',
  /** Aggregate result on PrepaymentOrderNo */
  PrepaymentOrderNo = 'PrepaymentOrderNo',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on WorkDescription */
  WorkDescription = 'WorkDescription',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on PaymentServiceSetID */
  PaymentServiceSetId = 'PaymentServiceSetID',
  /** Aggregate result on DocumentExchangeIdentifier */
  DocumentExchangeIdentifier = 'DocumentExchangeIdentifier',
  /** Aggregate result on DocumentExchangeStatus */
  DocumentExchangeStatus = 'DocumentExchangeStatus',
  /** Aggregate result on DocExchOriginalIdentifier */
  DocExchOriginalIdentifier = 'DocExchOriginalIdentifier',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on DirectDebitMandateID */
  DirectDebitMandateId = 'DirectDebitMandateID',
  /** Aggregate result on CustLedgerEntryNo */
  CustLedgerEntryNo = 'CustLedgerEntryNo',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on SelltoContactNo */
  SelltoContactNo = 'SelltoContactNo',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on OpportunityNo */
  OpportunityNo = 'OpportunityNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on GetShipmentUsed */
  GetShipmentUsed = 'GetShipmentUsed',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on EQMShipmentType */
  EqmShipmentType = 'EQMShipmentType',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMCustomerType */
  EqmCustomerType = 'EQMCustomerType',
  /** Aggregate result on EQMRentalDocument */
  EqmRentalDocument = 'EQMRentalDocument',
  /** Aggregate result on EQMTransferMethod */
  EqmTransferMethod = 'EQMTransferMethod',
  /** Aggregate result on EQMWorkshopDocument */
  EqmWorkshopDocument = 'EQMWorkshopDocument',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMCombineCustomerProj */
  EqmCombineCustomerProj = 'EQMCombineCustomerProj',
  /** Aggregate result on EQMServiceTermNo */
  EqmServiceTermNo = 'EQMServiceTermNo',
  /** Aggregate result on EQMInvoiceDocType */
  EqmInvoiceDocType = 'EQMInvoiceDocType',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMJobNo */
  EqmJobNo = 'EQMJobNo',
  /** Aggregate result on EQMJobTaskNo */
  EqmJobTaskNo = 'EQMJobTaskNo',
  /** Aggregate result on EQMWSObjectCounter */
  EqmwsObjectCounter = 'EQMWSObjectCounter',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMServiceStartDate */
  EqmServiceStartDate = 'EQMServiceStartDate',
  /** Aggregate result on EQMServiceEndDate */
  EqmServiceEndDate = 'EQMServiceEndDate',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMObjectWarrantyDate */
  EqmObjectWarrantyDate = 'EQMObjectWarrantyDate',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMWSEstimatedStartDate */
  EqmwsEstimatedStartDate = 'EQMWSEstimatedStartDate',
  /** Aggregate result on EQMWSEstimatedStartTime */
  EqmwsEstimatedStartTime = 'EQMWSEstimatedStartTime',
  /** Aggregate result on EQMWSEstimatedEndDate */
  EqmwsEstimatedEndDate = 'EQMWSEstimatedEndDate',
  /** Aggregate result on EQMWSEstimatedEndTime */
  EqmwsEstimatedEndTime = 'EQMWSEstimatedEndTime',
  /** Aggregate result on EQMServiceStartTime */
  EqmServiceStartTime = 'EQMServiceStartTime',
  /** Aggregate result on EQMServiceEndTime */
  EqmServiceEndTime = 'EQMServiceEndTime',
  /** Aggregate result on EQMWSObjectCounter2 */
  EqmwsObjectCounter2 = 'EQMWSObjectCounter2',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on LastEmailSentTime */
  LastEmailSentTime = 'LastEmailSentTime',
  /** Aggregate result on LastEmailSentStatus */
  LastEmailSentStatus = 'LastEmailSentStatus',
  /** Aggregate result on SentasEmail */
  SentasEmail = 'SentasEmail',
  /** Aggregate result on LastEmailNotifCleared */
  LastEmailNotifCleared = 'LastEmailNotifCleared',
  /** Aggregate result on Closed */
  Closed = 'Closed',
  /** Aggregate result on RemainingAmount */
  RemainingAmount = 'RemainingAmount',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on Cancelled */
  Cancelled = 'Cancelled',
  /** Aggregate result on Corrective */
  Corrective = 'Corrective',
  /** Aggregate result on EQMLinkedCreditMemos */
  EqmLinkedCreditMemos = 'EQMLinkedCreditMemos',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateInvoiceLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on ShipmentNo */
  ShipmentNo = 'ShipmentNo',
  /** Aggregate result on ShipmentLineNo */
  ShipmentLineNo = 'ShipmentLineNo',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on InvDiscountAmount */
  InvDiscountAmount = 'InvDiscountAmount',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxCategory */
  TaxCategory = 'TaxCategory',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATClauseCode */
  VatClauseCode = 'VATClauseCode',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on LineDiscountCalculation */
  LineDiscountCalculation = 'LineDiscountCalculation',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobContractEntryNo */
  JobContractEntryNo = 'JobContractEntryNo',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ApplfromItemEntry */
  ApplfromItemEntry = 'ApplfromItemEntry',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on Pricedescription */
  Pricedescription = 'Pricedescription',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMRental */
  EqmRental = 'EQMRental',
  /** Aggregate result on EQMRentalFromDate */
  EqmRentalFromDate = 'EQMRentalFromDate',
  /** Aggregate result on EQMRentalToDate */
  EqmRentalToDate = 'EQMRentalToDate',
  /** Aggregate result on EQMRentalLineNo */
  EqmRentalLineNo = 'EQMRentalLineNo',
  /** Aggregate result on EQMRentalDays */
  EqmRentalDays = 'EQMRentalDays',
  /** Aggregate result on EQMAttachedtoNo */
  EqmAttachedtoNo = 'EQMAttachedtoNo',
  /** Aggregate result on EQMAttachedtoLineNo */
  EqmAttachedtoLineNo = 'EQMAttachedtoLineNo',
  /** Aggregate result on EQMRentalFreeDays */
  EqmRentalFreeDays = 'EQMRentalFreeDays',
  /** Aggregate result on EQMLineUpdated */
  EqmLineUpdated = 'EQMLineUpdated',
  /** Aggregate result on EQMRentalSale */
  EqmRentalSale = 'EQMRentalSale',
  /** Aggregate result on EQMRentalQuantity */
  EqmRentalQuantity = 'EQMRentalQuantity',
  /** Aggregate result on EQMRentalNo */
  EqmRentalNo = 'EQMRentalNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMRentalFromDateTime */
  EqmRentalFromDateTime = 'EQMRentalFromDateTime',
  /** Aggregate result on EQMRentalToDateTime */
  EqmRentalToDateTime = 'EQMRentalToDateTime',
  /** Aggregate result on EQMUnitPrice */
  EqmUnitPrice = 'EQMUnitPrice',
  /** Aggregate result on EQMLineDiscount */
  EqmLineDiscount = 'EQMLineDiscount',
  /** Aggregate result on EQMLineDiscountAmount */
  EqmLineDiscountAmount = 'EQMLineDiscountAmount',
  /** Aggregate result on EQMLineAmount */
  EqmLineAmount = 'EQMLineAmount',
  /** Aggregate result on EQMReturnFromDate */
  EqmReturnFromDate = 'EQMReturnFromDate',
  /** Aggregate result on EQMReturnToDate */
  EqmReturnToDate = 'EQMReturnToDate',
  /** Aggregate result on EQMReturnWholePeriod */
  EqmReturnWholePeriod = 'EQMReturnWholePeriod',
  /** Aggregate result on EQMReturnRentalDays */
  EqmReturnRentalDays = 'EQMReturnRentalDays',
  /** Aggregate result on EQMReturnFreeDays */
  EqmReturnFreeDays = 'EQMReturnFreeDays',
  /** Aggregate result on EQMTransferThisLine */
  EqmTransferThisLine = 'EQMTransferThisLine',
  /** Aggregate result on EQMReturnQuantity */
  EqmReturnQuantity = 'EQMReturnQuantity',
  /** Aggregate result on EQMRentalDuration */
  EqmRentalDuration = 'EQMRentalDuration',
  /** Aggregate result on EQMServiceUpwrite */
  EqmServiceUpwrite = 'EQMServiceUpwrite',
  /** Aggregate result on EQMRentalLineType */
  EqmRentalLineType = 'EQMRentalLineType',
  /** Aggregate result on EQMUseOfFixPrice */
  EqmUseOfFixPrice = 'EQMUseOfFixPrice',
  /** Aggregate result on EQMUpdateRentalLine */
  EqmUpdateRentalLine = 'EQMUpdateRentalLine',
  /** Aggregate result on EQMWorkshop */
  EqmWorkshop = 'EQMWorkshop',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMWorkshopGuarantee */
  EqmWorkshopGuarantee = 'EQMWorkshopGuarantee',
  /** Aggregate result on EQMWorkshopType */
  EqmWorkshopType = 'EQMWorkshopType',
  /** Aggregate result on EQMSkipObjectIntoInv */
  EqmSkipObjectIntoInv = 'EQMSkipObjectIntoInv',
  /** Aggregate result on EQMRefBasisEntryNo */
  EqmRefBasisEntryNo = 'EQMRefBasisEntryNo',
  /** Aggregate result on EQMAmountCost */
  EqmAmountCost = 'EQMAmountCost',
  /** Aggregate result on EQMStairPriceCode */
  EqmStairPriceCode = 'EQMStairPriceCode',
  /** Aggregate result on EQMStairPriceStep */
  EqmStairPriceStep = 'EQMStairPriceStep',
  /** Aggregate result on EQMRentalKITMother */
  EqmRentalKitMother = 'EQMRentalKITMother',
  /** Aggregate result on EQMRentalKITMotherLineNo */
  EqmRentalKitMotherLineNo = 'EQMRentalKITMotherLineNo',
  /** Aggregate result on EQMDepositPosting */
  EqmDepositPosting = 'EQMDepositPosting',
  /** Aggregate result on EQMReRentObject */
  EqmReRentObject = 'EQMReRentObject',
  /** Aggregate result on EQMUsedFractionCalculation */
  EqmUsedFractionCalculation = 'EQMUsedFractionCalculation',
  /** Aggregate result on EQMRentalHeaderLine */
  EqmRentalHeaderLine = 'EQMRentalHeaderLine',
  /** Aggregate result on EQMSerialNo */
  EqmSerialNo = 'EQMSerialNo',
  /** Aggregate result on EQMRefOrigDoc */
  EqmRefOrigDoc = 'EQMRefOrigDoc',
  /** Aggregate result on EQMRefOrigLineNo */
  EqmRefOrigLineNo = 'EQMRefOrigLineNo',
  /** Aggregate result on EQMManuallyChanged */
  EqmManuallyChanged = 'EQMManuallyChanged',
  /** Aggregate result on EQMReclassOrderedBy */
  EqmReclassOrderedBy = 'EQMReclassOrderedBy',
  /** Aggregate result on EQMReclassOrderNo */
  EqmReclassOrderNo = 'EQMReclassOrderNo',
  /** Aggregate result on EQMExtraHoursCharged */
  EqmExtraHoursCharged = 'EQMExtraHoursCharged',
  /** Aggregate result on EQMExtraHoursReturnNo */
  EqmExtraHoursReturnNo = 'EQMExtraHoursReturnNo',
  /** Aggregate result on EQMRentalSplitCostCenter */
  EqmRentalSplitCostCenter = 'EQMRentalSplitCostCenter',
  /** Aggregate result on EQMRentalSplit */
  EqmRentalSplit = 'EQMRentalSplit',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMRePurchase */
  EqmRePurchase = 'EQMRePurchase',
  /** Aggregate result on EQMInvoiceFee */
  EqmInvoiceFee = 'EQMInvoiceFee',
  /** Aggregate result on EQMPriceScheduleCode */
  EqmPriceScheduleCode = 'EQMPriceScheduleCode',
  /** Aggregate result on EQMAdvancePaymentEntry */
  EqmAdvancePaymentEntry = 'EQMAdvancePaymentEntry',
  /** Aggregate result on EQMRentalInsurance */
  EqmRentalInsurance = 'EQMRentalInsurance',
  /** Aggregate result on EQMObjectInsurance */
  EqmObjectInsurance = 'EQMObjectInsurance',
  /** Aggregate result on EQMServiceMotherLineNo */
  EqmServiceMotherLineNo = 'EQMServiceMotherLineNo',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMPriceReportedHours */
  EqmPriceReportedHours = 'EQMPriceReportedHours',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemCategoryInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on ParentCategory */
  ParentCategory = 'ParentCategory',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Indentation */
  Indentation = 'Indentation',
  /** Aggregate result on PresentationOrder */
  PresentationOrder = 'PresentationOrder',
  /** Aggregate result on HasChildren */
  HasChildren = 'HasChildren',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on DefGenProdPostingGroup */
  DefGenProdPostingGroup = 'DefGenProdPostingGroup',
  /** Aggregate result on DefInventoryPostingGroup */
  DefInventoryPostingGroup = 'DefInventoryPostingGroup',
  /** Aggregate result on DefTaxGroupCode */
  DefTaxGroupCode = 'DefTaxGroupCode',
  /** Aggregate result on DefCostingMethod */
  DefCostingMethod = 'DefCostingMethod',
  /** Aggregate result on DefCostingMethodOPTION */
  DefCostingMethodOption = 'DefCostingMethodOPTION',
  /** Aggregate result on DefVATProdPostingGroup */
  DefVatProdPostingGroup = 'DefVATProdPostingGroup',
  /** Aggregate result on DefReRentRProdPostGrp */
  DefReRentRProdPostGrp = 'DefReRentRProdPostGrp',
  /** Aggregate result on DefReRentSProdPostGrp */
  DefReRentSProdPostGrp = 'DefReRentSProdPostGrp',
  /** Aggregate result on EQMDefGenRntlPostGrp */
  EqmDefGenRntlPostGrp = 'EQMDefGenRntlPostGrp',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemDiscGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on No2 */
  No2 = 'No2',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SearchDescription */
  SearchDescription = 'SearchDescription',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on BaseUnitofMeasure */
  BaseUnitofMeasure = 'BaseUnitofMeasure',
  /** Aggregate result on PriceUnitConversion */
  PriceUnitConversion = 'PriceUnitConversion',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on ShelfNo */
  ShelfNo = 'ShelfNo',
  /** Aggregate result on ItemDiscGroup */
  ItemDiscGroup = 'ItemDiscGroup',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on StatisticsGroup */
  StatisticsGroup = 'StatisticsGroup',
  /** Aggregate result on CommissionGroup */
  CommissionGroup = 'CommissionGroup',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceProfitCalculation */
  PriceProfitCalculation = 'PriceProfitCalculation',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on CostingMethod */
  CostingMethod = 'CostingMethod',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on StandardCost */
  StandardCost = 'StandardCost',
  /** Aggregate result on LastDirectCost */
  LastDirectCost = 'LastDirectCost',
  /** Aggregate result on IndirectCost */
  IndirectCost = 'IndirectCost',
  /** Aggregate result on CostisAdjusted */
  CostisAdjusted = 'CostisAdjusted',
  /** Aggregate result on AllowOnlineAdjustment */
  AllowOnlineAdjustment = 'AllowOnlineAdjustment',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on ReorderPoint */
  ReorderPoint = 'ReorderPoint',
  /** Aggregate result on MaximumInventory */
  MaximumInventory = 'MaximumInventory',
  /** Aggregate result on ReorderQuantity */
  ReorderQuantity = 'ReorderQuantity',
  /** Aggregate result on AlternativeItemNo */
  AlternativeItemNo = 'AlternativeItemNo',
  /** Aggregate result on UnitListPrice */
  UnitListPrice = 'UnitListPrice',
  /** Aggregate result on DutyDue */
  DutyDue = 'DutyDue',
  /** Aggregate result on DutyCode */
  DutyCode = 'DutyCode',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on Durability */
  Durability = 'Durability',
  /** Aggregate result on FreightType */
  FreightType = 'FreightType',
  /** Aggregate result on TariffNo */
  TariffNo = 'TariffNo',
  /** Aggregate result on DutyUnitConversion */
  DutyUnitConversion = 'DutyUnitConversion',
  /** Aggregate result on CountryRegionPurchasedCode */
  CountryRegionPurchasedCode = 'CountryRegionPurchasedCode',
  /** Aggregate result on BudgetQuantity */
  BudgetQuantity = 'BudgetQuantity',
  /** Aggregate result on BudgetedAmount */
  BudgetedAmount = 'BudgetedAmount',
  /** Aggregate result on BudgetProfit */
  BudgetProfit = 'BudgetProfit',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on BlockReason */
  BlockReason = 'BlockReason',
  /** Aggregate result on LastDateTimeModified */
  LastDateTimeModified = 'LastDateTimeModified',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on PriceIncludesVAT */
  PriceIncludesVat = 'PriceIncludesVAT',
  /** Aggregate result on VATBusPostingGrPrice */
  VatBusPostingGrPrice = 'VATBusPostingGrPrice',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on CountryRegionofOriginCode */
  CountryRegionofOriginCode = 'CountryRegionofOriginCode',
  /** Aggregate result on AutomaticExtTexts */
  AutomaticExtTexts = 'AutomaticExtTexts',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on VATProductPostingGroup */
  VatProductPostingGroup = 'VATProductPostingGroup',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on StockoutWarning */
  StockoutWarning = 'StockoutWarning',
  /** Aggregate result on PreventNegativeInventory */
  PreventNegativeInventory = 'PreventNegativeInventory',
  /** Aggregate result on ApplicationWkshUserID */
  ApplicationWkshUserId = 'ApplicationWkshUserID',
  /** Aggregate result on AssemblyPolicy */
  AssemblyPolicy = 'AssemblyPolicy',
  /** Aggregate result on GTIN */
  Gtin = 'GTIN',
  /** Aggregate result on DefaultDeferralTemplateCode */
  DefaultDeferralTemplateCode = 'DefaultDeferralTemplateCode',
  /** Aggregate result on LowLevelCode */
  LowLevelCode = 'LowLevelCode',
  /** Aggregate result on LotSize */
  LotSize = 'LotSize',
  /** Aggregate result on SerialNos */
  SerialNos = 'SerialNos',
  /** Aggregate result on LastUnitCostCalcDate */
  LastUnitCostCalcDate = 'LastUnitCostCalcDate',
  /** Aggregate result on RolledupMaterialCost */
  RolledupMaterialCost = 'RolledupMaterialCost',
  /** Aggregate result on RolledupCapacityCost */
  RolledupCapacityCost = 'RolledupCapacityCost',
  /** Aggregate result on Scrap */
  Scrap = 'Scrap',
  /** Aggregate result on InventoryValueZero */
  InventoryValueZero = 'InventoryValueZero',
  /** Aggregate result on DiscreteOrderQuantity */
  DiscreteOrderQuantity = 'DiscreteOrderQuantity',
  /** Aggregate result on MinimumOrderQuantity */
  MinimumOrderQuantity = 'MinimumOrderQuantity',
  /** Aggregate result on MaximumOrderQuantity */
  MaximumOrderQuantity = 'MaximumOrderQuantity',
  /** Aggregate result on SafetyStockQuantity */
  SafetyStockQuantity = 'SafetyStockQuantity',
  /** Aggregate result on OrderMultiple */
  OrderMultiple = 'OrderMultiple',
  /** Aggregate result on SafetyLeadTime */
  SafetyLeadTime = 'SafetyLeadTime',
  /** Aggregate result on FlushingMethod */
  FlushingMethod = 'FlushingMethod',
  /** Aggregate result on ReplenishmentSystem */
  ReplenishmentSystem = 'ReplenishmentSystem',
  /** Aggregate result on RoundingPrecision */
  RoundingPrecision = 'RoundingPrecision',
  /** Aggregate result on SalesUnitofMeasure */
  SalesUnitofMeasure = 'SalesUnitofMeasure',
  /** Aggregate result on PurchUnitofMeasure */
  PurchUnitofMeasure = 'PurchUnitofMeasure',
  /** Aggregate result on TimeBucket */
  TimeBucket = 'TimeBucket',
  /** Aggregate result on ReorderingPolicy */
  ReorderingPolicy = 'ReorderingPolicy',
  /** Aggregate result on IncludeInventory */
  IncludeInventory = 'IncludeInventory',
  /** Aggregate result on ManufacturingPolicy */
  ManufacturingPolicy = 'ManufacturingPolicy',
  /** Aggregate result on ReschedulingPeriod */
  ReschedulingPeriod = 'ReschedulingPeriod',
  /** Aggregate result on LotAccumulationPeriod */
  LotAccumulationPeriod = 'LotAccumulationPeriod',
  /** Aggregate result on DampenerPeriod */
  DampenerPeriod = 'DampenerPeriod',
  /** Aggregate result on DampenerQuantity */
  DampenerQuantity = 'DampenerQuantity',
  /** Aggregate result on OverflowLevel */
  OverflowLevel = 'OverflowLevel',
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on CategoryCode */
  CategoryCode = 'CategoryCode',
  /** Aggregate result on CreatedFromNonstockItem */
  CreatedFromNonstockItem = 'CreatedFromNonstockItem',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ServiceItemGroup */
  ServiceItemGroup = 'ServiceItemGroup',
  /** Aggregate result on ItemTrackingCode */
  ItemTrackingCode = 'ItemTrackingCode',
  /** Aggregate result on LotNos */
  LotNos = 'LotNos',
  /** Aggregate result on ExpirationCalculation */
  ExpirationCalculation = 'ExpirationCalculation',
  /** Aggregate result on WarehouseClassCode */
  WarehouseClassCode = 'WarehouseClassCode',
  /** Aggregate result on SpecialEquipmentCode */
  SpecialEquipmentCode = 'SpecialEquipmentCode',
  /** Aggregate result on PutawayTemplateCode */
  PutawayTemplateCode = 'PutawayTemplateCode',
  /** Aggregate result on PutawayUnitofMeasureCode */
  PutawayUnitofMeasureCode = 'PutawayUnitofMeasureCode',
  /** Aggregate result on PhysInvtCountingPeriodCode */
  PhysInvtCountingPeriodCode = 'PhysInvtCountingPeriodCode',
  /** Aggregate result on LastCountingPeriodUpdate */
  LastCountingPeriodUpdate = 'LastCountingPeriodUpdate',
  /** Aggregate result on UseCrossDocking */
  UseCrossDocking = 'UseCrossDocking',
  /** Aggregate result on NextCountingStartDate */
  NextCountingStartDate = 'NextCountingStartDate',
  /** Aggregate result on NextCountingEndDate */
  NextCountingEndDate = 'NextCountingEndDate',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on UnitofMeasureId */
  UnitofMeasureId = 'UnitofMeasureId',
  /** Aggregate result on TaxGroupId */
  TaxGroupId = 'TaxGroupId',
  /** Aggregate result on EQMItemNoConcern */
  EqmItemNoConcern = 'EQMItemNoConcern',
  /** Aggregate result on EQMDiamondBladeItem */
  EqmDiamondBladeItem = 'EQMDiamondBladeItem',
  /** Aggregate result on EQMDiamondPostingGroup */
  EqmDiamondPostingGroup = 'EQMDiamondPostingGroup',
  /** Aggregate result on EQMDimensionRentalType */
  EqmDimensionRentalType = 'EQMDimensionRentalType',
  /** Aggregate result on EQMMeasurement */
  EqmMeasurement = 'EQMMeasurement',
  /** Aggregate result on EQMReRentRProdPostGrp */
  EqmReRentRProdPostGrp = 'EQMReRentRProdPostGrp',
  /** Aggregate result on EQMReRentSProdPostGrp */
  EqmReRentSProdPostGrp = 'EQMReRentSProdPostGrp',
  /** Aggregate result on EQMObjectBOMPostingGroup */
  EqmObjectBomPostingGroup = 'EQMObjectBOMPostingGroup',
  /** Aggregate result on EQMVATRntlProdPostGrp */
  EqmvatRntlProdPostGrp = 'EQMVATRntlProdPostGrp',
  /** Aggregate result on EQMDepositAmount */
  EqmDepositAmount = 'EQMDepositAmount',
  /** Aggregate result on EQMReplacementPrice */
  EqmReplacementPrice = 'EQMReplacementPrice',
  /** Aggregate result on EQMFixedAssetSetupGroup */
  EqmFixedAssetSetupGroup = 'EQMFixedAssetSetupGroup',
  /** Aggregate result on EQMDescription3 */
  EqmDescription3 = 'EQMDescription3',
  /** Aggregate result on EQMDescription4 */
  EqmDescription4 = 'EQMDescription4',
  /** Aggregate result on EQMRentalInsurance */
  EqmRentalInsurance = 'EQMRentalInsurance',
  /** Aggregate result on EQMBlockedRental */
  EqmBlockedRental = 'EQMBlockedRental',
  /** Aggregate result on EQMExpAvailAfterEndDate */
  EqmExpAvailAfterEndDate = 'EQMExpAvailAfterEndDate',
  /** Aggregate result on EQMCreditonEarlyReturn */
  EqmCreditonEarlyReturn = 'EQMCreditonEarlyReturn',
  /** Aggregate result on EQMStdRentalChargeDays */
  EqmStdRentalChargeDays = 'EQMStdRentalChargeDays',
  /** Aggregate result on EQMDebitonReturnDate */
  EqmDebitonReturnDate = 'EQMDebitonReturnDate',
  /** Aggregate result on EQMBaseCalendarCode */
  EqmBaseCalendarCode = 'EQMBaseCalendarCode',
  /** Aggregate result on EQMGenRentalPostingGroup */
  EqmGenRentalPostingGroup = 'EQMGenRentalPostingGroup',
  /** Aggregate result on EQMRentalUnitPrice */
  EqmRentalUnitPrice = 'EQMRentalUnitPrice',
  /** Aggregate result on EQMDefaultRentalItem */
  EqmDefaultRentalItem = 'EQMDefaultRentalItem',
  /** Aggregate result on EQMPriceTermCode */
  EqmPriceTermCode = 'EQMPriceTermCode',
  /** Aggregate result on EQMRentalItemDiscGroup */
  EqmRentalItemDiscGroup = 'EQMRentalItemDiscGroup',
  /** Aggregate result on EQMDimensionRentalGroup */
  EqmDimensionRentalGroup = 'EQMDimensionRentalGroup',
  /** Aggregate result on EQMGenRepurchasePostGrp */
  EqmGenRepurchasePostGrp = 'EQMGenRepurchasePostGrp',
  /** Aggregate result on EQMSquareMeter */
  EqmSquareMeter = 'EQMSquareMeter',
  /** Aggregate result on EQMFilterLocationCode */
  EqmFilterLocationCode = 'EQMFilterLocationCode',
  /** Aggregate result on RoutingNo */
  RoutingNo = 'RoutingNo',
  /** Aggregate result on ProductionBOMNo */
  ProductionBomNo = 'ProductionBOMNo',
  /** Aggregate result on SingleLevelMaterialCost */
  SingleLevelMaterialCost = 'SingleLevelMaterialCost',
  /** Aggregate result on SingleLevelCapacityCost */
  SingleLevelCapacityCost = 'SingleLevelCapacityCost',
  /** Aggregate result on SingleLevelSubcontrdCost */
  SingleLevelSubcontrdCost = 'SingleLevelSubcontrdCost',
  /** Aggregate result on SingleLevelCapOvhdCost */
  SingleLevelCapOvhdCost = 'SingleLevelCapOvhdCost',
  /** Aggregate result on SingleLevelMfgOvhdCost */
  SingleLevelMfgOvhdCost = 'SingleLevelMfgOvhdCost',
  /** Aggregate result on OverheadRate */
  OverheadRate = 'OverheadRate',
  /** Aggregate result on RolledupSubcontractedCost */
  RolledupSubcontractedCost = 'RolledupSubcontractedCost',
  /** Aggregate result on RolledupMfgOvhdCost */
  RolledupMfgOvhdCost = 'RolledupMfgOvhdCost',
  /** Aggregate result on RolledupCapOverheadCost */
  RolledupCapOverheadCost = 'RolledupCapOverheadCost',
  /** Aggregate result on OrderTrackingPolicy */
  OrderTrackingPolicy = 'OrderTrackingPolicy',
  /** Aggregate result on Critical */
  Critical = 'Critical',
  /** Aggregate result on CommonItemNo */
  CommonItemNo = 'CommonItemNo',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DropShipmentFilter */
  DropShipmentFilter = 'DropShipmentFilter',
  /** Aggregate result on BinFilter */
  BinFilter = 'BinFilter',
  /** Aggregate result on VariantFilter */
  VariantFilter = 'VariantFilter',
  /** Aggregate result on LotNoFilter */
  LotNoFilter = 'LotNoFilter',
  /** Aggregate result on SerialNoFilter */
  SerialNoFilter = 'SerialNoFilter',
  /** Aggregate result on EQMRentalSaleFilter */
  EqmRentalSaleFilter = 'EQMRentalSaleFilter',
  /** Aggregate result on ProductionForecastName */
  ProductionForecastName = 'ProductionForecastName',
  /** Aggregate result on ComponentForecast */
  ComponentForecast = 'ComponentForecast',
  /** Aggregate result on AssemblyBOM */
  AssemblyBom = 'AssemblyBOM',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on CostisPostedtoGL */
  CostisPostedtoGl = 'CostisPostedtoGL',
  /** Aggregate result on Inventory */
  Inventory = 'Inventory',
  /** Aggregate result on NetInvoicedQty */
  NetInvoicedQty = 'NetInvoicedQty',
  /** Aggregate result on NetChange */
  NetChange = 'NetChange',
  /** Aggregate result on PurchasesQty */
  PurchasesQty = 'PurchasesQty',
  /** Aggregate result on SalesQty */
  SalesQty = 'SalesQty',
  /** Aggregate result on PositiveAdjmtQty */
  PositiveAdjmtQty = 'PositiveAdjmtQty',
  /** Aggregate result on NegativeAdjmtQty */
  NegativeAdjmtQty = 'NegativeAdjmtQty',
  /** Aggregate result on PurchasesLCY */
  PurchasesLcy = 'PurchasesLCY',
  /** Aggregate result on SalesLCY */
  SalesLcy = 'SalesLCY',
  /** Aggregate result on PositiveAdjmtLCY */
  PositiveAdjmtLcy = 'PositiveAdjmtLCY',
  /** Aggregate result on NegativeAdjmtLCY */
  NegativeAdjmtLcy = 'NegativeAdjmtLCY',
  /** Aggregate result on COGSLCY */
  Cogslcy = 'COGSLCY',
  /** Aggregate result on QtyonPurchOrder */
  QtyonPurchOrder = 'QtyonPurchOrder',
  /** Aggregate result on QtyonSalesOrder */
  QtyonSalesOrder = 'QtyonSalesOrder',
  /** Aggregate result on TransferredQty */
  TransferredQty = 'TransferredQty',
  /** Aggregate result on TransferredLCY */
  TransferredLcy = 'TransferredLCY',
  /** Aggregate result on ReservedQtyonInventory */
  ReservedQtyonInventory = 'ReservedQtyonInventory',
  /** Aggregate result on ReservedQtyonPurchOrders */
  ReservedQtyonPurchOrders = 'ReservedQtyonPurchOrders',
  /** Aggregate result on ReservedQtyonSalesOrders */
  ReservedQtyonSalesOrders = 'ReservedQtyonSalesOrders',
  /** Aggregate result on ResQtyonOutboundTransfer */
  ResQtyonOutboundTransfer = 'ResQtyonOutboundTransfer',
  /** Aggregate result on ResQtyonInboundTransfer */
  ResQtyonInboundTransfer = 'ResQtyonInboundTransfer',
  /** Aggregate result on ResQtyonSalesReturns */
  ResQtyonSalesReturns = 'ResQtyonSalesReturns',
  /** Aggregate result on ResQtyonPurchReturns */
  ResQtyonPurchReturns = 'ResQtyonPurchReturns',
  /** Aggregate result on CostofOpenProductionOrders */
  CostofOpenProductionOrders = 'CostofOpenProductionOrders',
  /** Aggregate result on ResQtyonAssemblyOrder */
  ResQtyonAssemblyOrder = 'ResQtyonAssemblyOrder',
  /** Aggregate result on ResQtyonAsmComp */
  ResQtyonAsmComp = 'ResQtyonAsmComp',
  /** Aggregate result on QtyonAssemblyOrder */
  QtyonAssemblyOrder = 'QtyonAssemblyOrder',
  /** Aggregate result on QtyonAsmComponent */
  QtyonAsmComponent = 'QtyonAsmComponent',
  /** Aggregate result on QtyonJobOrder */
  QtyonJobOrder = 'QtyonJobOrder',
  /** Aggregate result on ResQtyonJobOrder */
  ResQtyonJobOrder = 'ResQtyonJobOrder',
  /** Aggregate result on ScheduledReceiptQty */
  ScheduledReceiptQty = 'ScheduledReceiptQty',
  /** Aggregate result on ScheduledNeedQty */
  ScheduledNeedQty = 'ScheduledNeedQty',
  /** Aggregate result on ReservedQtyonProdOrder */
  ReservedQtyonProdOrder = 'ReservedQtyonProdOrder',
  /** Aggregate result on ResQtyonProdOrderComp */
  ResQtyonProdOrderComp = 'ResQtyonProdOrderComp',
  /** Aggregate result on ResQtyonReqLine */
  ResQtyonReqLine = 'ResQtyonReqLine',
  /** Aggregate result on PlanningTransferShipQty */
  PlanningTransferShipQty = 'PlanningTransferShipQty',
  /** Aggregate result on PlanningWorksheetQty */
  PlanningWorksheetQty = 'PlanningWorksheetQty',
  /** Aggregate result on StockkeepingUnitExists */
  StockkeepingUnitExists = 'StockkeepingUnitExists',
  /** Aggregate result on SubstitutesExist */
  SubstitutesExist = 'SubstitutesExist',
  /** Aggregate result on QtyinTransit */
  QtyinTransit = 'QtyinTransit',
  /** Aggregate result on TransOrdReceiptQty */
  TransOrdReceiptQty = 'TransOrdReceiptQty',
  /** Aggregate result on TransOrdShipmentQty */
  TransOrdShipmentQty = 'TransOrdShipmentQty',
  /** Aggregate result on QtyAssignedtoship */
  QtyAssignedtoship = 'QtyAssignedtoship',
  /** Aggregate result on QtyPicked */
  QtyPicked = 'QtyPicked',
  /** Aggregate result on QtyonServiceOrder */
  QtyonServiceOrder = 'QtyonServiceOrder',
  /** Aggregate result on ResQtyonServiceOrders */
  ResQtyonServiceOrders = 'ResQtyonServiceOrders',
  /** Aggregate result on QtyonPurchReturn */
  QtyonPurchReturn = 'QtyonPurchReturn',
  /** Aggregate result on QtyonSalesReturn */
  QtyonSalesReturn = 'QtyonSalesReturn',
  /** Aggregate result on NoofSubstitutes */
  NoofSubstitutes = 'NoofSubstitutes',
  /** Aggregate result on LastPhysInvtDate */
  LastPhysInvtDate = 'LastPhysInvtDate',
  /** Aggregate result on IdentifierCode */
  IdentifierCode = 'IdentifierCode',
  /** Aggregate result on EQMAvailabletoTake */
  EqmAvailabletoTake = 'EQMAvailabletoTake',
  /** Aggregate result on EQMUseAdvancedMgtCalendar */
  EqmUseAdvancedMgtCalendar = 'EQMUseAdvancedMgtCalendar',
  /** Aggregate result on EQMRentalReservationsQty */
  EqmRentalReservationsQty = 'EQMRentalReservationsQty',
  /** Aggregate result on EQMRentalDeliveriesQty */
  EqmRentalDeliveriesQty = 'EQMRentalDeliveriesQty',
  /** Aggregate result on EQMExpRentalReturnsQty */
  EqmExpRentalReturnsQty = 'EQMExpRentalReturnsQty',
  /** Aggregate result on EQMRentalReturnsQty */
  EqmRentalReturnsQty = 'EQMRentalReturnsQty',
  /** Aggregate result on EQMQtyonRentalReRent */
  EqmQtyonRentalReRent = 'EQMQtyonRentalReRent',
  /** Aggregate result on EQMQtyonRent */
  EqmQtyonRent = 'EQMQtyonRent',
  /** Aggregate result on PlanningIssuesQty */
  PlanningIssuesQty = 'PlanningIssuesQty',
  /** Aggregate result on PlanningReceiptQty */
  PlanningReceiptQty = 'PlanningReceiptQty',
  /** Aggregate result on PlannedOrderReceiptQty */
  PlannedOrderReceiptQty = 'PlannedOrderReceiptQty',
  /** Aggregate result on FPOrderReceiptQty */
  FpOrderReceiptQty = 'FPOrderReceiptQty',
  /** Aggregate result on RelOrderReceiptQty */
  RelOrderReceiptQty = 'RelOrderReceiptQty',
  /** Aggregate result on PlanningReleaseQty */
  PlanningReleaseQty = 'PlanningReleaseQty',
  /** Aggregate result on PlannedOrderReleaseQty */
  PlannedOrderReleaseQty = 'PlannedOrderReleaseQty',
  /** Aggregate result on PurchReqReceiptQty */
  PurchReqReceiptQty = 'PurchReqReceiptQty',
  /** Aggregate result on PurchReqReleaseQty */
  PurchReqReleaseQty = 'PurchReqReleaseQty',
  /** Aggregate result on ProdForecastQuantityBase */
  ProdForecastQuantityBase = 'ProdForecastQuantityBase',
  /** Aggregate result on QtyonProdOrder */
  QtyonProdOrder = 'QtyonProdOrder',
  /** Aggregate result on QtyonComponentLines */
  QtyonComponentLines = 'QtyonComponentLines',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on SynkedToMagento */
  SynkedToMagento = 'SynkedToMagento',
  /** Aggregate result on MagentoSyncProblems */
  MagentoSyncProblems = 'MagentoSyncProblems',
  /** Aggregate result on AttributeSetId */
  AttributeSetId = 'AttributeSetId',
  /** Aggregate result on Visibility */
  Visibility = 'Visibility',
  /** Aggregate result on TypeId */
  TypeId = 'TypeId',
  /** Aggregate result on Website */
  Website = 'Website',
  /** Aggregate result on CalculatedInventory */
  CalculatedInventory = 'CalculatedInventory',
  /** Aggregate result on CalculatedInventoryUOM */
  CalculatedInventoryUom = 'CalculatedInventoryUOM',
  /** Aggregate result on CalculatedInventoryUpdatedAd */
  CalculatedInventoryUpdatedAd = 'CalculatedInventoryUpdatedAd',
  /** Aggregate result on ActiveWeb */
  ActiveWeb = 'ActiveWeb',
  /** Aggregate result on TempOutOfStock */
  TempOutOfStock = 'TempOutOfStock',
  /** Aggregate result on OutletSE */
  OutletSe = 'OutletSE',
  /** Aggregate result on OutletDateFromSE */
  OutletDateFromSe = 'OutletDateFromSE',
  /** Aggregate result on OutletDateToSE */
  OutletDateToSe = 'OutletDateToSE',
  /** Aggregate result on CampaignSE */
  CampaignSe = 'CampaignSE',
  /** Aggregate result on CampaignDateFromSE */
  CampaignDateFromSe = 'CampaignDateFromSE',
  /** Aggregate result on CampaignDateToSE */
  CampaignDateToSe = 'CampaignDateToSE',
  /** Aggregate result on DeliveryInformation */
  DeliveryInformation = 'DeliveryInformation',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on IsBulky */
  IsBulky = 'IsBulky',
  /** Aggregate result on OrderItem */
  OrderItem = 'OrderItem',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on PackageHeight */
  PackageHeight = 'PackageHeight',
  /** Aggregate result on PackageWidth */
  PackageWidth = 'PackageWidth',
  /** Aggregate result on PackageLength */
  PackageLength = 'PackageLength',
  /** Aggregate result on VendorName */
  VendorName = 'VendorName',
  /** Aggregate result on DescriptionNo */
  DescriptionNo = 'DescriptionNo',
  /** Aggregate result on BrandDescription */
  BrandDescription = 'BrandDescription',
  /** Aggregate result on DeliveryTime */
  DeliveryTime = 'DeliveryTime',
  /** Aggregate result on HomeDelivery */
  HomeDelivery = 'HomeDelivery',
  /** Aggregate result on ServicePointDelivery */
  ServicePointDelivery = 'ServicePointDelivery',
  /** Aggregate result on BlockedNO */
  BlockedNo = 'BlockedNO',
  /** Aggregate result on ExpectedInStockDate */
  ExpectedInStockDate = 'ExpectedInStockDate',
  /** Aggregate result on BulkGroup */
  BulkGroup = 'BulkGroup',
  /** Aggregate result on IsOutlet */
  IsOutlet = 'IsOutlet',
  /** Aggregate result on IsCampaign */
  IsCampaign = 'IsCampaign',
  /** Aggregate result on GiftcardType */
  GiftcardType = 'GiftcardType',
  /** Aggregate result on TaxClassId */
  TaxClassId = 'TaxClassId',
  /** Aggregate result on UrlKey */
  UrlKey = 'UrlKey',
  /** Aggregate result on ShowInFieldOrder */
  ShowInFieldOrder = 'ShowInFieldOrder',
  /** Aggregate result on ItemType */
  ItemType = 'ItemType',
  /** Aggregate result on ItemTypeOPTION */
  ItemTypeOption = 'ItemTypeOPTION',
  /** Aggregate result on SalesOrderMultiple */
  SalesOrderMultiple = 'SalesOrderMultiple',
  /** Aggregate result on EcoLabel */
  EcoLabel = 'EcoLabel',
  /** Aggregate result on MainGroupCode */
  MainGroupCode = 'MainGroupCode',
  /** Aggregate result on ItemGroupCode */
  ItemGroupCode = 'ItemGroupCode',
  /** Aggregate result on OldItemNo */
  OldItemNo = 'OldItemNo',
  /** Aggregate result on OldItemNo2 */
  OldItemNo2 = 'OldItemNo2',
  /** Aggregate result on RKVItem */
  RkvItem = 'RKVItem',
  /** Aggregate result on ToExpire */
  ToExpire = 'ToExpire',
  /** Aggregate result on AcquisitionItem */
  AcquisitionItem = 'AcquisitionItem',
  /** Aggregate result on MagentoItem */
  MagentoItem = 'MagentoItem',
  /** Aggregate result on AlwaysAvailableOnWeb */
  AlwaysAvailableOnWeb = 'AlwaysAvailableOnWeb',
  /** Aggregate result on QBSId */
  QbsId = 'QBSId',
  /** Aggregate result on ObjectGroupFamily */
  ObjectGroupFamily = 'ObjectGroupFamily',
  /** Aggregate result on EdgeSmallImageUrl */
  EdgeSmallImageUrl = 'EdgeSmallImageUrl',
  /** Aggregate result on EdgeLargelImageUrl */
  EdgeLargelImageUrl = 'EdgeLargelImageUrl',
  /** Aggregate result on CalculationPrice */
  CalculationPrice = 'CalculationPrice',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemReferenceInput {
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on ReferenceType */
  ReferenceType = 'ReferenceType',
  /** Aggregate result on ReferenceTypeOPTION */
  ReferenceTypeOption = 'ReferenceTypeOPTION',
  /** Aggregate result on ReferenceTypeNo */
  ReferenceTypeNo = 'ReferenceTypeNo',
  /** Aggregate result on ReferenceNo */
  ReferenceNo = 'ReferenceNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on DiscontinueBarCode */
  DiscontinueBarCode = 'DiscontinueBarCode',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on ExternalNo */
  ExternalNo = 'ExternalNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemRestrictionInput {
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemUnitOfMeasureInput {
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on QtyRoundingPrecision */
  QtyRoundingPrecision = 'QtyRoundingPrecision',
  /** Aggregate result on Length */
  Length = 'Length',
  /** Aggregate result on Width */
  Width = 'Width',
  /** Aggregate result on Height */
  Height = 'Height',
  /** Aggregate result on Cubage */
  Cubage = 'Cubage',
  /** Aggregate result on Weight */
  Weight = 'Weight',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateItemVendorInput {
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateJobTaskInput {
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on JobTaskType */
  JobTaskType = 'JobTaskType',
  /** Aggregate result on JobTaskTypeOPTION */
  JobTaskTypeOption = 'JobTaskTypeOPTION',
  /** Aggregate result on WIPTotal */
  WipTotal = 'WIPTotal',
  /** Aggregate result on WIPTotalOPTION */
  WipTotalOption = 'WIPTotalOPTION',
  /** Aggregate result on JobPostingGroup */
  JobPostingGroup = 'JobPostingGroup',
  /** Aggregate result on WIPMethod */
  WipMethod = 'WIPMethod',
  /** Aggregate result on Totaling */
  Totaling = 'Totaling',
  /** Aggregate result on NewPage */
  NewPage = 'NewPage',
  /** Aggregate result on NoofBlankLines */
  NoofBlankLines = 'NoofBlankLines',
  /** Aggregate result on Indentation */
  Indentation = 'Indentation',
  /** Aggregate result on RecognizedSalesAmount */
  RecognizedSalesAmount = 'RecognizedSalesAmount',
  /** Aggregate result on RecognizedCostsAmount */
  RecognizedCostsAmount = 'RecognizedCostsAmount',
  /** Aggregate result on RecognizedSalesGLAmount */
  RecognizedSalesGlAmount = 'RecognizedSalesGLAmount',
  /** Aggregate result on RecognizedCostsGLAmount */
  RecognizedCostsGlAmount = 'RecognizedCostsGLAmount',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PostingDateFilter */
  PostingDateFilter = 'PostingDateFilter',
  /** Aggregate result on PlanningDateFilter */
  PlanningDateFilter = 'PlanningDateFilter',
  /** Aggregate result on ScheduleTotalCost */
  ScheduleTotalCost = 'ScheduleTotalCost',
  /** Aggregate result on ScheduleTotalPrice */
  ScheduleTotalPrice = 'ScheduleTotalPrice',
  /** Aggregate result on UsageTotalCost */
  UsageTotalCost = 'UsageTotalCost',
  /** Aggregate result on UsageTotalPrice */
  UsageTotalPrice = 'UsageTotalPrice',
  /** Aggregate result on ContractTotalCost */
  ContractTotalCost = 'ContractTotalCost',
  /** Aggregate result on ContractTotalPrice */
  ContractTotalPrice = 'ContractTotalPrice',
  /** Aggregate result on ContractInvoicedPrice */
  ContractInvoicedPrice = 'ContractInvoicedPrice',
  /** Aggregate result on ContractInvoicedCost */
  ContractInvoicedCost = 'ContractInvoicedCost',
  /** Aggregate result on OutstandingOrders */
  OutstandingOrders = 'OutstandingOrders',
  /** Aggregate result on AmtRcdNotInvoiced */
  AmtRcdNotInvoiced = 'AmtRcdNotInvoiced',
  /** Aggregate result on RemainingTotalCost */
  RemainingTotalCost = 'RemainingTotalCost',
  /** Aggregate result on RemainingTotalPrice */
  RemainingTotalPrice = 'RemainingTotalPrice',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on EndDate */
  EndDate = 'EndDate',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on ClosedOn */
  ClosedOn = 'ClosedOn',
  /** Aggregate result on FirstSavedOn */
  FirstSavedOn = 'FirstSavedOn',
  /** Aggregate result on DevopsId */
  DevopsId = 'DevopsId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateKliHeaderInput {
  /** Aggregate result on ErrandNo */
  ErrandNo = 'ErrandNo',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on NameDescription */
  NameDescription = 'NameDescription',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on Reason */
  Reason = 'Reason',
  /** Aggregate result on Credit */
  Credit = 'Credit',
  /** Aggregate result on Timemin */
  Timemin = 'Timemin',
  /** Aggregate result on Cost */
  Cost = 'Cost',
  /** Aggregate result on QAdistdat */
  QAdistdat = 'QAdistdat',
  /** Aggregate result on FinnishedDate */
  FinnishedDate = 'FinnishedDate',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on CorectivAction */
  CorectivAction = 'CorectivAction',
  /** Aggregate result on NewInvoice */
  NewInvoice = 'NewInvoice',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on CurrentOwnerEmail */
  CurrentOwnerEmail = 'CurrentOwnerEmail',
  /** Aggregate result on Closed */
  Closed = 'Closed',
  /** Aggregate result on CostPerMin */
  CostPerMin = 'CostPerMin',
  /** Aggregate result on ReasonText */
  ReasonText = 'ReasonText',
  /** Aggregate result on Action */
  Action = 'Action',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ImpactOnStock */
  ImpactOnStock = 'ImpactOnStock',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on InternCost */
  InternCost = 'InternCost',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on CreatedDate */
  CreatedDate = 'CreatedDate',
  /** Aggregate result on ModifiedBy */
  ModifiedBy = 'ModifiedBy',
  /** Aggregate result on ModifiedDate */
  ModifiedDate = 'ModifiedDate',
  /** Aggregate result on Guarantee */
  Guarantee = 'Guarantee',
  /** Aggregate result on ReasonCodeDesc */
  ReasonCodeDesc = 'ReasonCodeDesc',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on Workplace */
  Workplace = 'Workplace',
  /** Aggregate result on Caller */
  Caller = 'Caller',
  /** Aggregate result on CallerPhone */
  CallerPhone = 'CallerPhone',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on ModelYear */
  ModelYear = 'ModelYear',
  /** Aggregate result on Pirority */
  Pirority = 'Pirority',
  /** Aggregate result on RecType */
  RecType = 'RecType',
  /** Aggregate result on RecTypeOPTION */
  RecTypeOption = 'RecTypeOPTION',
  /** Aggregate result on RevisitNeeded */
  RevisitNeeded = 'RevisitNeeded',
  /** Aggregate result on MachineReplaced */
  MachineReplaced = 'MachineReplaced',
  /** Aggregate result on AnalysisCode */
  AnalysisCode = 'AnalysisCode',
  /** Aggregate result on AnalysisText */
  AnalysisText = 'AnalysisText',
  /** Aggregate result on MainErrand */
  MainErrand = 'MainErrand',
  /** Aggregate result on LinkErrand */
  LinkErrand = 'LinkErrand',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on SendToRepairer */
  SendToRepairer = 'SendToRepairer',
  /** Aggregate result on FollowUp */
  FollowUp = 'FollowUp',
  /** Aggregate result on ErrandDescription */
  ErrandDescription = 'ErrandDescription',
  /** Aggregate result on FollowUpPreformed */
  FollowUpPreformed = 'FollowUpPreformed',
  /** Aggregate result on CorectivActionPreformed */
  CorectivActionPreformed = 'CorectivActionPreformed',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on LastStatusBeforeClose */
  LastStatusBeforeClose = 'LastStatusBeforeClose',
  /** Aggregate result on LastStatusBeforeCloseOPTION */
  LastStatusBeforeCloseOption = 'LastStatusBeforeCloseOPTION',
  /** Aggregate result on WorkplaceContact */
  WorkplaceContact = 'WorkplaceContact',
  /** Aggregate result on WorkplaceContactPhone */
  WorkplaceContactPhone = 'WorkplaceContactPhone',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on TableNo */
  TableNo = 'TableNo',
  /** Aggregate result on ReasonTextBlob */
  ReasonTextBlob = 'ReasonTextBlob',
  /** Aggregate result on ActionTextBlob */
  ActionTextBlob = 'ActionTextBlob',
  /** Aggregate result on FixedByExternalRepairer */
  FixedByExternalRepairer = 'FixedByExternalRepairer',
  /** Aggregate result on TurnaroundTime */
  TurnaroundTime = 'TurnaroundTime',
  /** Aggregate result on ReportedToWorkingEnvDpt */
  ReportedToWorkingEnvDpt = 'ReportedToWorkingEnvDpt',
  /** Aggregate result on SafteyOfficerInformed */
  SafteyOfficerInformed = 'SafteyOfficerInformed',
  /** Aggregate result on NewRiskAnalysis */
  NewRiskAnalysis = 'NewRiskAnalysis',
  /** Aggregate result on AnalysisTextBLOB */
  AnalysisTextBlob = 'AnalysisTextBLOB',
  /** Aggregate result on FollowUpBLOB */
  FollowUpBlob = 'FollowUpBLOB',
  /** Aggregate result on CorectivActionBLOB */
  CorectivActionBlob = 'CorectivActionBLOB',
  /** Aggregate result on AccidentComment */
  AccidentComment = 'AccidentComment',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on CreditNo */
  CreditNo = 'CreditNo',
  /** Aggregate result on CreditPosted */
  CreditPosted = 'CreditPosted',
  /** Aggregate result on CreditApproved */
  CreditApproved = 'CreditApproved',
  /** Aggregate result on CreditApprovedBy */
  CreditApprovedBy = 'CreditApprovedBy',
  /** Aggregate result on NoOfLinkedErrands */
  NoOfLinkedErrands = 'NoOfLinkedErrands',
  /** Aggregate result on VendorName */
  VendorName = 'VendorName',
  /** Aggregate result on TurnOverToEmail */
  TurnOverToEmail = 'TurnOverToEmail',
  /** Aggregate result on CreatedExternal */
  CreatedExternal = 'CreatedExternal',
  /** Aggregate result on Template */
  Template = 'Template',
  /** Aggregate result on CurrentHandler */
  CurrentHandler = 'CurrentHandler',
  /** Aggregate result on CurrentHandlerEmail */
  CurrentHandlerEmail = 'CurrentHandlerEmail',
  /** Aggregate result on CurrentHandlerPhone */
  CurrentHandlerPhone = 'CurrentHandlerPhone',
  /** Aggregate result on CustomerDamage */
  CustomerDamage = 'CustomerDamage',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateKliReasonCodeInput {
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on RecType */
  RecType = 'RecType',
  /** Aggregate result on RecTypeOPTION */
  RecTypeOption = 'RecTypeOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateLeadInput {
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on TelexNo */
  TelexNo = 'TelexNo',
  /** Aggregate result on TerritoryCode */
  TerritoryCode = 'TerritoryCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on PictureURL */
  PictureUrl = 'PictureURL',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on CompanyNo */
  CompanyNo = 'CompanyNo',
  /** Aggregate result on CompanyName */
  CompanyName = 'CompanyName',
  /** Aggregate result on FirstName */
  FirstName = 'FirstName',
  /** Aggregate result on Surname */
  Surname = 'Surname',
  /** Aggregate result on JobTitle */
  JobTitle = 'JobTitle',
  /** Aggregate result on Initials */
  Initials = 'Initials',
  /** Aggregate result on MobilePhoneNo */
  MobilePhoneNo = 'MobilePhoneNo',
  /** Aggregate result on Subject */
  Subject = 'Subject',
  /** Aggregate result on OwnerEmail */
  OwnerEmail = 'OwnerEmail',
  /** Aggregate result on OwnerName */
  OwnerName = 'OwnerName',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateLocationInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on DefaultBinCode */
  DefaultBinCode = 'DefaultBinCode',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on PhoneNo2 */
  PhoneNo2 = 'PhoneNo2',
  /** Aggregate result on TelexNo */
  TelexNo = 'TelexNo',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on UseAsInTransit */
  UseAsInTransit = 'UseAsInTransit',
  /** Aggregate result on RequirePutaway */
  RequirePutaway = 'RequirePutaway',
  /** Aggregate result on RequirePick */
  RequirePick = 'RequirePick',
  /** Aggregate result on CrossDockDueDateCalc */
  CrossDockDueDateCalc = 'CrossDockDueDateCalc',
  /** Aggregate result on UseCrossDocking */
  UseCrossDocking = 'UseCrossDocking',
  /** Aggregate result on RequireReceive */
  RequireReceive = 'RequireReceive',
  /** Aggregate result on RequireShipment */
  RequireShipment = 'RequireShipment',
  /** Aggregate result on BinMandatory */
  BinMandatory = 'BinMandatory',
  /** Aggregate result on DirectedPutawayandPick */
  DirectedPutawayandPick = 'DirectedPutawayandPick',
  /** Aggregate result on DefaultBinSelection */
  DefaultBinSelection = 'DefaultBinSelection',
  /** Aggregate result on DefaultBinSelectionOPTION */
  DefaultBinSelectionOption = 'DefaultBinSelectionOPTION',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on PutawayTemplateCode */
  PutawayTemplateCode = 'PutawayTemplateCode',
  /** Aggregate result on UsePutawayWorksheet */
  UsePutawayWorksheet = 'UsePutawayWorksheet',
  /** Aggregate result on PickAccordingtoFEFO */
  PickAccordingtoFefo = 'PickAccordingtoFEFO',
  /** Aggregate result on AllowBreakbulk */
  AllowBreakbulk = 'AllowBreakbulk',
  /** Aggregate result on BinCapacityPolicy */
  BinCapacityPolicy = 'BinCapacityPolicy',
  /** Aggregate result on BinCapacityPolicyOPTION */
  BinCapacityPolicyOption = 'BinCapacityPolicyOPTION',
  /** Aggregate result on OpenShopFloorBinCode */
  OpenShopFloorBinCode = 'OpenShopFloorBinCode',
  /** Aggregate result on ToProductionBinCode */
  ToProductionBinCode = 'ToProductionBinCode',
  /** Aggregate result on FromProductionBinCode */
  FromProductionBinCode = 'FromProductionBinCode',
  /** Aggregate result on AdjustmentBinCode */
  AdjustmentBinCode = 'AdjustmentBinCode',
  /** Aggregate result on AlwaysCreatePutawayLine */
  AlwaysCreatePutawayLine = 'AlwaysCreatePutawayLine',
  /** Aggregate result on AlwaysCreatePickLine */
  AlwaysCreatePickLine = 'AlwaysCreatePickLine',
  /** Aggregate result on SpecialEquipment */
  SpecialEquipment = 'SpecialEquipment',
  /** Aggregate result on SpecialEquipmentOPTION */
  SpecialEquipmentOption = 'SpecialEquipmentOPTION',
  /** Aggregate result on ReceiptBinCode */
  ReceiptBinCode = 'ReceiptBinCode',
  /** Aggregate result on ShipmentBinCode */
  ShipmentBinCode = 'ShipmentBinCode',
  /** Aggregate result on CrossDockBinCode */
  CrossDockBinCode = 'CrossDockBinCode',
  /** Aggregate result on ToAssemblyBinCode */
  ToAssemblyBinCode = 'ToAssemblyBinCode',
  /** Aggregate result on FromAssemblyBinCode */
  FromAssemblyBinCode = 'FromAssemblyBinCode',
  /** Aggregate result on AsmtoOrderShptBinCode */
  AsmtoOrderShptBinCode = 'AsmtoOrderShptBinCode',
  /** Aggregate result on ToJobBinCode */
  ToJobBinCode = 'ToJobBinCode',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on UseADCS */
  UseAdcs = 'UseADCS',
  /** Aggregate result on EQMInternalCustomerNo */
  EqmInternalCustomerNo = 'EQMInternalCustomerNo',
  /** Aggregate result on EQMReclassJnlTemplName */
  EqmReclassJnlTemplName = 'EQMReclassJnlTemplName',
  /** Aggregate result on EQMReclassJnlBatchName */
  EqmReclassJnlBatchName = 'EQMReclassJnlBatchName',
  /** Aggregate result on EQMMaintenanceLocation */
  EqmMaintenanceLocation = 'EQMMaintenanceLocation',
  /** Aggregate result on EQMHUB */
  Eqmhub = 'EQMHUB',
  /** Aggregate result on ExternalLocationId */
  ExternalLocationId = 'ExternalLocationId',
  /** Aggregate result on ExternalLocationCode */
  ExternalLocationCode = 'ExternalLocationCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateMagentoOrderStatusInput {
  /** Aggregate result on EntityId */
  EntityId = 'EntityId',
  /** Aggregate result on IncrementId */
  IncrementId = 'IncrementId',
  /** Aggregate result on StoreId */
  StoreId = 'StoreId',
  /** Aggregate result on WebsiteId */
  WebsiteId = 'WebsiteId',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on CreatedAt */
  CreatedAt = 'CreatedAt',
  /** Aggregate result on UpdatedAt */
  UpdatedAt = 'UpdatedAt',
  /** Aggregate result on CheckCount */
  CheckCount = 'CheckCount',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateMagentoSettingsInput {
  /** Aggregate result on QuestCustomerNo */
  QuestCustomerNo = 'QuestCustomerNo',
  /** Aggregate result on QuestCustomerName */
  QuestCustomerName = 'QuestCustomerName',
  /** Aggregate result on QuestCustomerGlobalId */
  QuestCustomerGlobalId = 'QuestCustomerGlobalId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateManufacturerInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on MagentoAttribOptionId */
  MagentoAttribOptionId = 'MagentoAttribOptionId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateNavUserInput {
  /** Aggregate result on UserSecurityID */
  UserSecurityId = 'UserSecurityID',
  /** Aggregate result on UserName */
  UserName = 'UserName',
  /** Aggregate result on FullName */
  FullName = 'FullName',
  /** Aggregate result on State */
  State = 'State',
  /** Aggregate result on StateOPTION */
  StateOption = 'StateOPTION',
  /** Aggregate result on ExpiryDate */
  ExpiryDate = 'ExpiryDate',
  /** Aggregate result on WindowsSecurityID */
  WindowsSecurityId = 'WindowsSecurityID',
  /** Aggregate result on ChangePassword */
  ChangePassword = 'ChangePassword',
  /** Aggregate result on LicenseType */
  LicenseType = 'LicenseType',
  /** Aggregate result on LicenseTypeOPTION */
  LicenseTypeOption = 'LicenseTypeOPTION',
  /** Aggregate result on AuthenticationEmail */
  AuthenticationEmail = 'AuthenticationEmail',
  /** Aggregate result on EdgeCostCenter */
  EdgeCostCenter = 'EdgeCostCenter',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectCommentLineInput {
  /** Aggregate result on TableName */
  TableName = 'TableName',
  /** Aggregate result on TableNameOPTION */
  TableNameOption = 'TableNameOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectFamilyInput {
  /** Aggregate result on Family */
  Family = 'Family',
  /** Aggregate result on ImgUrlSmall */
  ImgUrlSmall = 'ImgUrlSmall',
  /** Aggregate result on ImgUrlLarge */
  ImgUrlLarge = 'ImgUrlLarge',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectGroupInput {
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on GenProdPostingGrpIntRent */
  GenProdPostingGrpIntRent = 'GenProdPostingGrpIntRent',
  /** Aggregate result on GenProdPostingGrpExtRent */
  GenProdPostingGrpExtRent = 'GenProdPostingGrpExtRent',
  /** Aggregate result on ObjectNos */
  ObjectNos = 'ObjectNos',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on FAClassCode */
  FaClassCode = 'FAClassCode',
  /** Aggregate result on FASubclassCode */
  FaSubclassCode = 'FASubclassCode',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepreciationMethod */
  DepreciationMethod = 'DepreciationMethod',
  /** Aggregate result on DepreciationMethodOPTION */
  DepreciationMethodOption = 'DepreciationMethodOPTION',
  /** Aggregate result on FAPostingGroup */
  FaPostingGroup = 'FAPostingGroup',
  /** Aggregate result on GenProdPostingGrpHire */
  GenProdPostingGrpHire = 'GenProdPostingGrpHire',
  /** Aggregate result on GenProdPostingGrpLeasing */
  GenProdPostingGrpLeasing = 'GenProdPostingGrpLeasing',
  /** Aggregate result on RentalSplitPostingGroup */
  RentalSplitPostingGroup = 'RentalSplitPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on GenProdPostingGrpWriteUp */
  GenProdPostingGrpWriteUp = 'GenProdPostingGrpWriteUp',
  /** Aggregate result on NoofDepreciationYears */
  NoofDepreciationYears = 'NoofDepreciationYears',
  /** Aggregate result on NoofDepreciationMonths */
  NoofDepreciationMonths = 'NoofDepreciationMonths',
  /** Aggregate result on ServiceItemGroup */
  ServiceItemGroup = 'ServiceItemGroup',
  /** Aggregate result on CreditonEarlyOffRent */
  CreditonEarlyOffRent = 'CreditonEarlyOffRent',
  /** Aggregate result on StdRentalChargeDays */
  StdRentalChargeDays = 'StdRentalChargeDays',
  /** Aggregate result on StdRentalChargeDaysOPTION */
  StdRentalChargeDaysOption = 'StdRentalChargeDaysOPTION',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on ObjectGroupNoConcern */
  ObjectGroupNoConcern = 'ObjectGroupNoConcern',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Description3 */
  Description3 = 'Description3',
  /** Aggregate result on Description4 */
  Description4 = 'Description4',
  /** Aggregate result on StraightLine */
  StraightLine = 'StraightLine',
  /** Aggregate result on WarrantyDuration */
  WarrantyDuration = 'WarrantyDuration',
  /** Aggregate result on DecliningBalance */
  DecliningBalance = 'DecliningBalance',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on TaxDepreciationMethod */
  TaxDepreciationMethod = 'TaxDepreciationMethod',
  /** Aggregate result on TaxDepreciationMethodOPTION */
  TaxDepreciationMethodOption = 'TaxDepreciationMethodOPTION',
  /** Aggregate result on TaxFAPostingGroup */
  TaxFaPostingGroup = 'TaxFAPostingGroup',
  /** Aggregate result on TaxNoofDepreciationYears */
  TaxNoofDepreciationYears = 'TaxNoofDepreciationYears',
  /** Aggregate result on TaxNoofDepreciationMonths */
  TaxNoofDepreciationMonths = 'TaxNoofDepreciationMonths',
  /** Aggregate result on TaxStraightLine */
  TaxStraightLine = 'TaxStraightLine',
  /** Aggregate result on TaxDecliningBalance */
  TaxDecliningBalance = 'TaxDecliningBalance',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on DateFilter2 */
  DateFilter2 = 'DateFilter2',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on InvoicedDays */
  InvoicedDays = 'InvoicedDays',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ImgUrlSmall */
  ImgUrlSmall = 'ImgUrlSmall',
  /** Aggregate result on ImgUrlLarge */
  ImgUrlLarge = 'ImgUrlLarge',
  /** Aggregate result on ObjectGroupFamily */
  ObjectGroupFamily = 'ObjectGroupFamily',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectInput {
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on RentalCustomerNo */
  RentalCustomerNo = 'RentalCustomerNo',
  /** Aggregate result on SalesCustomerNo */
  SalesCustomerNo = 'SalesCustomerNo',
  /** Aggregate result on FixedAssetNo */
  FixedAssetNo = 'FixedAssetNo',
  /** Aggregate result on CurrentLocationCode */
  CurrentLocationCode = 'CurrentLocationCode',
  /** Aggregate result on CreateDate */
  CreateDate = 'CreateDate',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on EndDate */
  EndDate = 'EndDate',
  /** Aggregate result on StatusInventory */
  StatusInventory = 'StatusInventory',
  /** Aggregate result on ServiceCustomerNo */
  ServiceCustomerNo = 'ServiceCustomerNo',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on GenProdPostingGrpWriteUp */
  GenProdPostingGrpWriteUp = 'GenProdPostingGrpWriteUp',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on GenProdPostingGrpIntRent */
  GenProdPostingGrpIntRent = 'GenProdPostingGrpIntRent',
  /** Aggregate result on GenProdPostingGrpExtRent */
  GenProdPostingGrpExtRent = 'GenProdPostingGrpExtRent',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on PostedReceiptNo */
  PostedReceiptNo = 'PostedReceiptNo',
  /** Aggregate result on PostedInvoiceNo */
  PostedInvoiceNo = 'PostedInvoiceNo',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on CostingMethod */
  CostingMethod = 'CostingMethod',
  /** Aggregate result on StandardCost */
  StandardCost = 'StandardCost',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on IndirectCost */
  IndirectCost = 'IndirectCost',
  /** Aggregate result on LastDirectCost */
  LastDirectCost = 'LastDirectCost',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceProfitCalculation */
  PriceProfitCalculation = 'PriceProfitCalculation',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on PriceIncludesVAT */
  PriceIncludesVat = 'PriceIncludesVAT',
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on RentalStatus */
  RentalStatus = 'RentalStatus',
  /** Aggregate result on BlockedforRental */
  BlockedforRental = 'BlockedforRental',
  /** Aggregate result on ServiceItemGroup */
  ServiceItemGroup = 'ServiceItemGroup',
  /** Aggregate result on ServiceItemNo */
  ServiceItemNo = 'ServiceItemNo',
  /** Aggregate result on DefaultContractValue */
  DefaultContractValue = 'DefaultContractValue',
  /** Aggregate result on DefaultContractDiscount */
  DefaultContractDiscount = 'DefaultContractDiscount',
  /** Aggregate result on OwningLocationCode */
  OwningLocationCode = 'OwningLocationCode',
  /** Aggregate result on GenProdPostingGrpHire */
  GenProdPostingGrpHire = 'GenProdPostingGrpHire',
  /** Aggregate result on GenProdPostingGrpLeasing */
  GenProdPostingGrpLeasing = 'GenProdPostingGrpLeasing',
  /** Aggregate result on RentalSplitPostingGroup */
  RentalSplitPostingGroup = 'RentalSplitPostingGroup',
  /** Aggregate result on SoldFor */
  SoldFor = 'SoldFor',
  /** Aggregate result on SoldDate */
  SoldDate = 'SoldDate',
  /** Aggregate result on UseFixedAsset */
  UseFixedAsset = 'UseFixedAsset',
  /** Aggregate result on CounterType */
  CounterType = 'CounterType',
  /** Aggregate result on Counter */
  Counter = 'Counter',
  /** Aggregate result on FirstRented */
  FirstRented = 'FirstRented',
  /** Aggregate result on LastRented */
  LastRented = 'LastRented',
  /** Aggregate result on WarrantyDiscParts */
  WarrantyDiscParts = 'WarrantyDiscParts',
  /** Aggregate result on WarrantyDiscLabor */
  WarrantyDiscLabor = 'WarrantyDiscLabor',
  /** Aggregate result on DefaultWarrantyDuration */
  DefaultWarrantyDuration = 'DefaultWarrantyDuration',
  /** Aggregate result on ResponseTimeHours */
  ResponseTimeHours = 'ResponseTimeHours',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on InPurchaseQuote */
  InPurchaseQuote = 'InPurchaseQuote',
  /** Aggregate result on InPurchaseOrder */
  InPurchaseOrder = 'InPurchaseOrder',
  /** Aggregate result on PurchaseInvoiced */
  PurchaseInvoiced = 'PurchaseInvoiced',
  /** Aggregate result on PurchaseReceived */
  PurchaseReceived = 'PurchaseReceived',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on VATBusPostingGrPrice */
  VatBusPostingGrPrice = 'VATBusPostingGrPrice',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on CreatedbyUser */
  CreatedbyUser = 'CreatedbyUser',
  /** Aggregate result on BlockedforSale */
  BlockedforSale = 'BlockedforSale',
  /** Aggregate result on BlockedforPurchase */
  BlockedforPurchase = 'BlockedforPurchase',
  /** Aggregate result on BlockedforReservation */
  BlockedforReservation = 'BlockedforReservation',
  /** Aggregate result on OwnershipStatus */
  OwnershipStatus = 'OwnershipStatus',
  /** Aggregate result on InclinInvValueReport */
  InclinInvValueReport = 'InclinInvValueReport',
  /** Aggregate result on StdCostAmount */
  StdCostAmount = 'StdCostAmount',
  /** Aggregate result on PurchasedFor */
  PurchasedFor = 'PurchasedFor',
  /** Aggregate result on ObtainedDiscount */
  ObtainedDiscount = 'ObtainedDiscount',
  /** Aggregate result on NetCostAmountAdditional */
  NetCostAmountAdditional = 'NetCostAmountAdditional',
  /** Aggregate result on DiscountGiven */
  DiscountGiven = 'DiscountGiven',
  /** Aggregate result on ReplacementPrice */
  ReplacementPrice = 'ReplacementPrice',
  /** Aggregate result on LinkedtoObjectNo */
  LinkedtoObjectNo = 'LinkedtoObjectNo',
  /** Aggregate result on Capacity */
  Capacity = 'Capacity',
  /** Aggregate result on Effect */
  Effect = 'Effect',
  /** Aggregate result on CylinderVolume */
  CylinderVolume = 'CylinderVolume',
  /** Aggregate result on AutomaticExtendedTexts */
  AutomaticExtendedTexts = 'AutomaticExtendedTexts',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on CapacityMeasure */
  CapacityMeasure = 'CapacityMeasure',
  /** Aggregate result on EffectMeasure */
  EffectMeasure = 'EffectMeasure',
  /** Aggregate result on CylinderMeasure */
  CylinderMeasure = 'CylinderMeasure',
  /** Aggregate result on EngineNo */
  EngineNo = 'EngineNo',
  /** Aggregate result on MobilePhoneNo */
  MobilePhoneNo = 'MobilePhoneNo',
  /** Aggregate result on RadioCode */
  RadioCode = 'RadioCode',
  /** Aggregate result on ChassisNo */
  ChassisNo = 'ChassisNo',
  /** Aggregate result on ConstructionYear */
  ConstructionYear = 'ConstructionYear',
  /** Aggregate result on RegistrationNo */
  RegistrationNo = 'RegistrationNo',
  /** Aggregate result on DateofPermit */
  DateofPermit = 'DateofPermit',
  /** Aggregate result on InSalesOrder */
  InSalesOrder = 'InSalesOrder',
  /** Aggregate result on InSalesQuote */
  InSalesQuote = 'InSalesQuote',
  /** Aggregate result on InSalesInvoice */
  InSalesInvoice = 'InSalesInvoice',
  /** Aggregate result on SoldonInvoiceNo */
  SoldonInvoiceNo = 'SoldonInvoiceNo',
  /** Aggregate result on ExpAvailableAfterEndDate */
  ExpAvailableAfterEndDate = 'ExpAvailableAfterEndDate',
  /** Aggregate result on CreditonEarlyReturn */
  CreditonEarlyReturn = 'CreditonEarlyReturn',
  /** Aggregate result on DefaultMaintenance */
  DefaultMaintenance = 'DefaultMaintenance',
  /** Aggregate result on InDelivery */
  InDelivery = 'InDelivery',
  /** Aggregate result on AdditionalObjectCost */
  AdditionalObjectCost = 'AdditionalObjectCost',
  /** Aggregate result on AdditionalObjectCostDisc */
  AdditionalObjectCostDisc = 'AdditionalObjectCostDisc',
  /** Aggregate result on AdditionalObjectSalesPrice */
  AdditionalObjectSalesPrice = 'AdditionalObjectSalesPrice',
  /** Aggregate result on Length */
  Length = 'Length',
  /** Aggregate result on LengthMeasure */
  LengthMeasure = 'LengthMeasure',
  /** Aggregate result on Width */
  Width = 'Width',
  /** Aggregate result on WidthMeasure */
  WidthMeasure = 'WidthMeasure',
  /** Aggregate result on Height */
  Height = 'Height',
  /** Aggregate result on HeightMeasure */
  HeightMeasure = 'HeightMeasure',
  /** Aggregate result on Weight */
  Weight = 'Weight',
  /** Aggregate result on WeightMeasure */
  WeightMeasure = 'WeightMeasure',
  /** Aggregate result on InSalesCreditMemo */
  InSalesCreditMemo = 'InSalesCreditMemo',
  /** Aggregate result on StdRentalChargeDays */
  StdRentalChargeDays = 'StdRentalChargeDays',
  /** Aggregate result on DebitonReturnDay */
  DebitonReturnDay = 'DebitonReturnDay',
  /** Aggregate result on PriceTerm */
  PriceTerm = 'PriceTerm',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on PolicyNo */
  PolicyNo = 'PolicyNo',
  /** Aggregate result on LeasingContractNo */
  LeasingContractNo = 'LeasingContractNo',
  /** Aggregate result on BaseCalendar */
  BaseCalendar = 'BaseCalendar',
  /** Aggregate result on NewOwnershipstatus */
  NewOwnershipstatus = 'NewOwnershipstatus',
  /** Aggregate result on NewStartDate */
  NewStartDate = 'NewStartDate',
  /** Aggregate result on CreateFixedAsset */
  CreateFixedAsset = 'CreateFixedAsset',
  /** Aggregate result on MaintenanceDescription */
  MaintenanceDescription = 'MaintenanceDescription',
  /** Aggregate result on Installment */
  Installment = 'Installment',
  /** Aggregate result on InstallmentDescription */
  InstallmentDescription = 'InstallmentDescription',
  /** Aggregate result on ProductCode */
  ProductCode = 'ProductCode',
  /** Aggregate result on BonusTypeCode */
  BonusTypeCode = 'BonusTypeCode',
  /** Aggregate result on FireInspection */
  FireInspection = 'FireInspection',
  /** Aggregate result on RecurringInspection */
  RecurringInspection = 'RecurringInspection',
  /** Aggregate result on RecurringInspection2 */
  RecurringInspection2 = 'RecurringInspection2',
  /** Aggregate result on InternalInspection */
  InternalInspection = 'InternalInspection',
  /** Aggregate result on FireInspectionNext */
  FireInspectionNext = 'FireInspectionNext',
  /** Aggregate result on ExcludeVacation */
  ExcludeVacation = 'ExcludeVacation',
  /** Aggregate result on RecurringInspectionNext */
  RecurringInspectionNext = 'RecurringInspectionNext',
  /** Aggregate result on RecurringInspection2Next */
  RecurringInspection2Next = 'RecurringInspection2Next',
  /** Aggregate result on InternalInspectionNext */
  InternalInspectionNext = 'InternalInspectionNext',
  /** Aggregate result on InspectionPeriodold */
  InspectionPeriodold = 'InspectionPeriodold',
  /** Aggregate result on InspectionPeriod */
  InspectionPeriod = 'InspectionPeriod',
  /** Aggregate result on InspectionDate */
  InspectionDate = 'InspectionDate',
  /** Aggregate result on NextInspectionDate */
  NextInspectionDate = 'NextInspectionDate',
  /** Aggregate result on InspectionSerieNo */
  InspectionSerieNo = 'InspectionSerieNo',
  /** Aggregate result on RentalSplit */
  RentalSplit = 'RentalSplit',
  /** Aggregate result on OwnerStatus */
  OwnerStatus = 'OwnerStatus',
  /** Aggregate result on CEDocument */
  CeDocument = 'CEDocument',
  /** Aggregate result on EngineCertification */
  EngineCertification = 'EngineCertification',
  /** Aggregate result on LastInventoriedDate */
  LastInventoriedDate = 'LastInventoriedDate',
  /** Aggregate result on InventoryInfo */
  InventoryInfo = 'InventoryInfo',
  /** Aggregate result on FinanceDate */
  FinanceDate = 'FinanceDate',
  /** Aggregate result on AmortizationRate */
  AmortizationRate = 'AmortizationRate',
  /** Aggregate result on ContractNumber */
  ContractNumber = 'ContractNumber',
  /** Aggregate result on PurschaseValue */
  PurschaseValue = 'PurschaseValue',
  /** Aggregate result on ContractInfo */
  ContractInfo = 'ContractInfo',
  /** Aggregate result on ServiceAgreement */
  ServiceAgreement = 'ServiceAgreement',
  /** Aggregate result on Finance */
  Finance = 'Finance',
  /** Aggregate result on RentalAmount */
  RentalAmount = 'RentalAmount',
  /** Aggregate result on StatusInventoryBeforeLost */
  StatusInventoryBeforeLost = 'StatusInventoryBeforeLost',
  /** Aggregate result on Bitmap1 */
  Bitmap1 = 'Bitmap1',
  /** Aggregate result on Bitmap2 */
  Bitmap2 = 'Bitmap2',
  /** Aggregate result on Bitmap3 */
  Bitmap3 = 'Bitmap3',
  /** Aggregate result on Bitmap4 */
  Bitmap4 = 'Bitmap4',
  /** Aggregate result on Bitmap5 */
  Bitmap5 = 'Bitmap5',
  /** Aggregate result on Bitmap6 */
  Bitmap6 = 'Bitmap6',
  /** Aggregate result on Bitmap7 */
  Bitmap7 = 'Bitmap7',
  /** Aggregate result on Bitmap8 */
  Bitmap8 = 'Bitmap8',
  /** Aggregate result on Bitmap9 */
  Bitmap9 = 'Bitmap9',
  /** Aggregate result on Bitmap10 */
  Bitmap10 = 'Bitmap10',
  /** Aggregate result on Bitmap11 */
  Bitmap11 = 'Bitmap11',
  /** Aggregate result on Bitmap12 */
  Bitmap12 = 'Bitmap12',
  /** Aggregate result on Bitmap13 */
  Bitmap13 = 'Bitmap13',
  /** Aggregate result on Bitmap14 */
  Bitmap14 = 'Bitmap14',
  /** Aggregate result on Bitmap15 */
  Bitmap15 = 'Bitmap15',
  /** Aggregate result on Bitmap16 */
  Bitmap16 = 'Bitmap16',
  /** Aggregate result on Bitmap17 */
  Bitmap17 = 'Bitmap17',
  /** Aggregate result on Bitmap18 */
  Bitmap18 = 'Bitmap18',
  /** Aggregate result on Bitmap19 */
  Bitmap19 = 'Bitmap19',
  /** Aggregate result on Bitmap20 */
  Bitmap20 = 'Bitmap20',
  /** Aggregate result on Bitmap21 */
  Bitmap21 = 'Bitmap21',
  /** Aggregate result on Bitmap22 */
  Bitmap22 = 'Bitmap22',
  /** Aggregate result on Bitmap23 */
  Bitmap23 = 'Bitmap23',
  /** Aggregate result on Bitmap24 */
  Bitmap24 = 'Bitmap24',
  /** Aggregate result on Bitmap25 */
  Bitmap25 = 'Bitmap25',
  /** Aggregate result on Bitmap26 */
  Bitmap26 = 'Bitmap26',
  /** Aggregate result on Bitmap27 */
  Bitmap27 = 'Bitmap27',
  /** Aggregate result on Bitmap28 */
  Bitmap28 = 'Bitmap28',
  /** Aggregate result on Bitmap29 */
  Bitmap29 = 'Bitmap29',
  /** Aggregate result on Bitmap30 */
  Bitmap30 = 'Bitmap30',
  /** Aggregate result on Bitmap31 */
  Bitmap31 = 'Bitmap31',
  /** Aggregate result on Bitmap32 */
  Bitmap32 = 'Bitmap32',
  /** Aggregate result on ObjectNoConcern */
  ObjectNoConcern = 'ObjectNoConcern',
  /** Aggregate result on PreviousInventoryStatus */
  PreviousInventoryStatus = 'PreviousInventoryStatus',
  /** Aggregate result on QuantityHoursFullRentalDay */
  QuantityHoursFullRentalDay = 'QuantityHoursFullRentalDay',
  /** Aggregate result on DepositAmount */
  DepositAmount = 'DepositAmount',
  /** Aggregate result on HireSplit */
  HireSplit = 'HireSplit',
  /** Aggregate result on WorkOrderCustomerNo */
  WorkOrderCustomerNo = 'WorkOrderCustomerNo',
  /** Aggregate result on Description3 */
  Description3 = 'Description3',
  /** Aggregate result on Description4 */
  Description4 = 'Description4',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on OldObjectNo */
  OldObjectNo = 'OldObjectNo',
  /** Aggregate result on ChangedbyUserID */
  ChangedbyUserId = 'ChangedbyUserID',
  /** Aggregate result on ChangedDate */
  ChangedDate = 'ChangedDate',
  /** Aggregate result on PriceScheduleCode */
  PriceScheduleCode = 'PriceScheduleCode',
  /** Aggregate result on MandatoryCounteronReturn */
  MandatoryCounteronReturn = 'MandatoryCounteronReturn',
  /** Aggregate result on RegStatusRental */
  RegStatusRental = 'RegStatusRental',
  /** Aggregate result on WarrantyDuration */
  WarrantyDuration = 'WarrantyDuration',
  /** Aggregate result on WarrantyDate */
  WarrantyDate = 'WarrantyDate',
  /** Aggregate result on TrackingCode */
  TrackingCode = 'TrackingCode',
  /** Aggregate result on VATProdPostingGrpRental */
  VatProdPostingGrpRental = 'VATProdPostingGrpRental',
  /** Aggregate result on ServiceStatus */
  ServiceStatus = 'ServiceStatus',
  /** Aggregate result on LastServiceDate */
  LastServiceDate = 'LastServiceDate',
  /** Aggregate result on LastInspectionDate */
  LastInspectionDate = 'LastInspectionDate',
  /** Aggregate result on LastInspDate */
  LastInspDate = 'LastInspDate',
  /** Aggregate result on TerminateFixedAsset */
  TerminateFixedAsset = 'TerminateFixedAsset',
  /** Aggregate result on CounterReporting */
  CounterReporting = 'CounterReporting',
  /** Aggregate result on EstimatedDailyHours */
  EstimatedDailyHours = 'EstimatedDailyHours',
  /** Aggregate result on ManufacturerModelCode */
  ManufacturerModelCode = 'ManufacturerModelCode',
  /** Aggregate result on EstimatedCounterperDay */
  EstimatedCounterperDay = 'EstimatedCounterperDay',
  /** Aggregate result on CounterType2 */
  CounterType2 = 'CounterType2',
  /** Aggregate result on Counter2 */
  Counter2 = 'Counter2',
  /** Aggregate result on EstimatedCounter2perDay */
  EstimatedCounter2perDay = 'EstimatedCounter2perDay',
  /** Aggregate result on MandatoryCounter2onReturn */
  MandatoryCounter2onReturn = 'MandatoryCounter2onReturn',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on DateFilter2 */
  DateFilter2 = 'DateFilter2',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on TimeFilter */
  TimeFilter = 'TimeFilter',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on Inventory */
  Inventory = 'Inventory',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CurrentActiveContractNo */
  CurrentActiveContractNo = 'CurrentActiveContractNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on CommentObjectGroup */
  CommentObjectGroup = 'CommentObjectGroup',
  /** Aggregate result on CommentObjectType */
  CommentObjectType = 'CommentObjectType',
  /** Aggregate result on CommentsinContract */
  CommentsinContract = 'CommentsinContract',
  /** Aggregate result on InventoryRental */
  InventoryRental = 'InventoryRental',
  /** Aggregate result on QtyonPurchOrder */
  QtyonPurchOrder = 'QtyonPurchOrder',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowComponentCost */
  FlowComponentCost = 'FlowComponentCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowPurchaseAddCost */
  FlowPurchaseAddCost = 'FlowPurchaseAddCost',
  /** Aggregate result on FlowTotalPurchaseCost */
  FlowTotalPurchaseCost = 'FlowTotalPurchaseCost',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on Invoiceddays */
  Invoiceddays = 'Invoiceddays',
  /** Aggregate result on FlowLinkedSumCost */
  FlowLinkedSumCost = 'FlowLinkedSumCost',
  /** Aggregate result on FlowLinkedSalesDiscount */
  FlowLinkedSalesDiscount = 'FlowLinkedSalesDiscount',
  /** Aggregate result on FlowLinkedOtherCost */
  FlowLinkedOtherCost = 'FlowLinkedOtherCost',
  /** Aggregate result on FlowLinkedLeasingCost */
  FlowLinkedLeasingCost = 'FlowLinkedLeasingCost',
  /** Aggregate result on FlowLinkedRentalIncome */
  FlowLinkedRentalIncome = 'FlowLinkedRentalIncome',
  /** Aggregate result on FlowLinkedRentalDiscount */
  FlowLinkedRentalDiscount = 'FlowLinkedRentalDiscount',
  /** Aggregate result on FlowLinkedPurchaseCharge */
  FlowLinkedPurchaseCharge = 'FlowLinkedPurchaseCharge',
  /** Aggregate result on FlowLinkedServiceCost */
  FlowLinkedServiceCost = 'FlowLinkedServiceCost',
  /** Aggregate result on FlowLinkedGuaranteeCost */
  FlowLinkedGuaranteeCost = 'FlowLinkedGuaranteeCost',
  /** Aggregate result on FlowLinkedIntRentalCost */
  FlowLinkedIntRentalCost = 'FlowLinkedIntRentalCost',
  /** Aggregate result on FlowLinkedHireCost */
  FlowLinkedHireCost = 'FlowLinkedHireCost',
  /** Aggregate result on FlowLinkedHireDiscount */
  FlowLinkedHireDiscount = 'FlowLinkedHireDiscount',
  /** Aggregate result on FlowLinkedServiceAgrCost */
  FlowLinkedServiceAgrCost = 'FlowLinkedServiceAgrCost',
  /** Aggregate result on FlowLinkedDepreciation */
  FlowLinkedDepreciation = 'FlowLinkedDepreciation',
  /** Aggregate result on FlowLinkedUppreciation */
  FlowLinkedUppreciation = 'FlowLinkedUppreciation',
  /** Aggregate result on FlowLinkedDownpreciation */
  FlowLinkedDownpreciation = 'FlowLinkedDownpreciation',
  /** Aggregate result on FlowLinkedPurchaseDiscount */
  FlowLinkedPurchaseDiscount = 'FlowLinkedPurchaseDiscount',
  /** Aggregate result on FlowLinkedSalesCharge */
  FlowLinkedSalesCharge = 'FlowLinkedSalesCharge',
  /** Aggregate result on FlowLinkedServiceIncome */
  FlowLinkedServiceIncome = 'FlowLinkedServiceIncome',
  /** Aggregate result on FlowLinkedSrvAgrIncome */
  FlowLinkedSrvAgrIncome = 'FlowLinkedSrvAgrIncome',
  /** Aggregate result on FlowLinkedIntRentalIncome */
  FlowLinkedIntRentalIncome = 'FlowLinkedIntRentalIncome',
  /** Aggregate result on FlowLinkedValueChange */
  FlowLinkedValueChange = 'FlowLinkedValueChange',
  /** Aggregate result on FlowLinkedRentalDays */
  FlowLinkedRentalDays = 'FlowLinkedRentalDays',
  /** Aggregate result on FlowLinkedRentalHours */
  FlowLinkedRentalHours = 'FlowLinkedRentalHours',
  /** Aggregate result on FlowLinkedInvoicedDays */
  FlowLinkedInvoicedDays = 'FlowLinkedInvoicedDays',
  /** Aggregate result on FlowLinkedGainbySale */
  FlowLinkedGainbySale = 'FlowLinkedGainbySale',
  /** Aggregate result on FlowLinkedLossbySale */
  FlowLinkedLossbySale = 'FlowLinkedLossbySale',
  /** Aggregate result on FlowLinkedOtherIncome */
  FlowLinkedOtherIncome = 'FlowLinkedOtherIncome',
  /** Aggregate result on PostedWorkOrderFlow */
  PostedWorkOrderFlow = 'PostedWorkOrderFlow',
  /** Aggregate result on PostedWorkPurchaseFlow */
  PostedWorkPurchaseFlow = 'PostedWorkPurchaseFlow',
  /** Aggregate result on PostedWorkOrderLineFlow */
  PostedWorkOrderLineFlow = 'PostedWorkOrderLineFlow',
  /** Aggregate result on PostedWorkPurchLineFlow */
  PostedWorkPurchLineFlow = 'PostedWorkPurchLineFlow',
  /** Aggregate result on PostedWorkshopEntriesFlow */
  PostedWorkshopEntriesFlow = 'PostedWorkshopEntriesFlow',
  /** Aggregate result on LastPriceAgreement */
  LastPriceAgreement = 'LastPriceAgreement',
  /** Aggregate result on PSILink */
  PsiLink = 'PSILink',
  /** Aggregate result on CaravanList */
  CaravanList = 'CaravanList',
  /** Aggregate result on TechnicalInfo */
  TechnicalInfo = 'TechnicalInfo',
  /** Aggregate result on FunctionalTests */
  FunctionalTests = 'FunctionalTests',
  /** Aggregate result on LastRentalStatusChage */
  LastRentalStatusChage = 'LastRentalStatusChage',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on ObjectTypeNoConcern */
  ObjectTypeNoConcern = 'ObjectTypeNoConcern',
  /** Aggregate result on NoofRentalQuoteLines */
  NoofRentalQuoteLines = 'NoofRentalQuoteLines',
  /** Aggregate result on NoofOpenRentalLines */
  NoofOpenRentalLines = 'NoofOpenRentalLines',
  /** Aggregate result on NoofClosedRentalLines */
  NoofClosedRentalLines = 'NoofClosedRentalLines',
  /** Aggregate result on NoofDefaultRentalPrices */
  NoofDefaultRentalPrices = 'NoofDefaultRentalPrices',
  /** Aggregate result on WarrantyDateFlow */
  WarrantyDateFlow = 'WarrantyDateFlow',
  /** Aggregate result on GPSCoordinates */
  GpsCoordinates = 'GPSCoordinates',
  /** Aggregate result on PSI */
  Psi = 'PSI',
  /** Aggregate result on AdditionalObjectStatus */
  AdditionalObjectStatus = 'AdditionalObjectStatus',
  /** Aggregate result on AdditionalObjectStatusOPTION */
  AdditionalObjectStatusOption = 'AdditionalObjectStatusOPTION',
  /** Aggregate result on ManufactureDate */
  ManufactureDate = 'ManufactureDate',
  /** Aggregate result on LastInspectionBy */
  LastInspectionBy = 'LastInspectionBy',
  /** Aggregate result on LastInspectionByEmail */
  LastInspectionByEmail = 'LastInspectionByEmail',
  /** Aggregate result on PurchaseDate */
  PurchaseDate = 'PurchaseDate',
  /** Aggregate result on FirstUseDate */
  FirstUseDate = 'FirstUseDate',
  /** Aggregate result on IsElectric */
  IsElectric = 'IsElectric',
  /** Aggregate result on OffRentDateEdge */
  OffRentDateEdge = 'OffRentDateEdge',
  /** Aggregate result on Broken */
  Broken = 'Broken',
  /** Aggregate result on RealBridgeSoldToCustomerNo */
  RealBridgeSoldToCustomerNo = 'RealBridgeSoldToCustomerNo',
  /** Aggregate result on RealBridgeCustomerNo */
  RealBridgeCustomerNo = 'RealBridgeCustomerNo',
  /** Aggregate result on RealBridgeProjectNo */
  RealBridgeProjectNo = 'RealBridgeProjectNo',
  /** Aggregate result on DekraLno */
  DekraLno = 'DekraLno',
  /** Aggregate result on SentToDekra */
  SentToDekra = 'SentToDekra',
  /** Aggregate result on CustomerRef */
  CustomerRef = 'CustomerRef',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on CustomerEmail */
  CustomerEmail = 'CustomerEmail',
  /** Aggregate result on CustomerPhone */
  CustomerPhone = 'CustomerPhone',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on CurrentPlacement */
  CurrentPlacement = 'CurrentPlacement',
  /** Aggregate result on ActualPlacement */
  ActualPlacement = 'ActualPlacement',
  /** Aggregate result on ObjStatus */
  ObjStatus = 'ObjStatus',
  /** Aggregate result on InspectionMonth */
  InspectionMonth = 'InspectionMonth',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectReturnHeaderInput {
  /** Aggregate result on ReturnType */
  ReturnType = 'ReturnType',
  /** Aggregate result on ReturnTypeOPTION */
  ReturnTypeOption = 'ReturnTypeOPTION',
  /** Aggregate result on OffRentNo */
  OffRentNo = 'OffRentNo',
  /** Aggregate result on OffRentDate */
  OffRentDate = 'OffRentDate',
  /** Aggregate result on OffRentTime */
  OffRentTime = 'OffRentTime',
  /** Aggregate result on ReturnTime */
  ReturnTime = 'ReturnTime',
  /** Aggregate result on DebitonReturnDay */
  DebitonReturnDay = 'DebitonReturnDay',
  /** Aggregate result on ReturnQuantity */
  ReturnQuantity = 'ReturnQuantity',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on NumberofRentFreeDays */
  NumberofRentFreeDays = 'NumberofRentFreeDays',
  /** Aggregate result on NumberofRentFreeHours */
  NumberofRentFreeHours = 'NumberofRentFreeHours',
  /** Aggregate result on ReasonforRentFree */
  ReasonforRentFree = 'ReasonforRentFree',
  /** Aggregate result on CreditonEarlyReturn */
  CreditonEarlyReturn = 'CreditonEarlyReturn',
  /** Aggregate result on QuantitytoSell */
  QuantitytoSell = 'QuantitytoSell',
  /** Aggregate result on QuantitytoDamage */
  QuantitytoDamage = 'QuantitytoDamage',
  /** Aggregate result on ReceivingLocation */
  ReceivingLocation = 'ReceivingLocation',
  /** Aggregate result on CounteronDelivery */
  CounteronDelivery = 'CounteronDelivery',
  /** Aggregate result on CounteronReturn */
  CounteronReturn = 'CounteronReturn',
  /** Aggregate result on Counter2onReturn */
  Counter2onReturn = 'Counter2onReturn',
  /** Aggregate result on Counter2onDelivery */
  Counter2onDelivery = 'Counter2onDelivery',
  /** Aggregate result on QuantityHoursFullRentalDay */
  QuantityHoursFullRentalDay = 'QuantityHoursFullRentalDay',
  /** Aggregate result on ReturnQuantityTime */
  ReturnQuantityTime = 'ReturnQuantityTime',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ChargeCustomer */
  ChargeCustomer = 'ChargeCustomer',
  /** Aggregate result on CurrentPlacement */
  CurrentPlacement = 'CurrentPlacement',
  /** Aggregate result on EdgePost */
  EdgePost = 'EdgePost',
  /** Aggregate result on OffRentUpdated */
  OffRentUpdated = 'OffRentUpdated',
  /** Aggregate result on EQMServiceDoc */
  EqmServiceDoc = 'EQMServiceDoc',
  /** Aggregate result on ReturnNo */
  ReturnNo = 'ReturnNo',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Unitprice */
  Unitprice = 'Unitprice',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on ExtDocNo */
  ExtDocNo = 'ExtDocNo',
  /** Aggregate result on ExtLineNo */
  ExtLineNo = 'ExtLineNo',
  /** Aggregate result on DeletedManually */
  DeletedManually = 'DeletedManually',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on ReqRefill */
  ReqRefill = 'ReqRefill',
  /** Aggregate result on ReqApproved */
  ReqApproved = 'ReqApproved',
  /** Aggregate result on ApprovedbyUserID */
  ApprovedbyUserId = 'ApprovedbyUserID',
  /** Aggregate result on CodeType */
  CodeType = 'CodeType',
  /** Aggregate result on CodeTypeOPTION */
  CodeTypeOption = 'CodeTypeOPTION',
  /** Aggregate result on MotherLineRegUpdateLine */
  MotherLineRegUpdateLine = 'MotherLineRegUpdateLine',
  /** Aggregate result on MotherLineType */
  MotherLineType = 'MotherLineType',
  /** Aggregate result on MotherLineTypeOPTION */
  MotherLineTypeOption = 'MotherLineTypeOPTION',
  /** Aggregate result on ReturnDate */
  ReturnDate = 'ReturnDate',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on Maintenance */
  Maintenance = 'Maintenance',
  /** Aggregate result on SubmittedByUserName */
  SubmittedByUserName = 'SubmittedByUserName',
  /** Aggregate result on SubmittedByUserEmail */
  SubmittedByUserEmail = 'SubmittedByUserEmail',
  /** Aggregate result on PushNotificationSent */
  PushNotificationSent = 'PushNotificationSent',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectReturnLineInput {
  /** Aggregate result on ReturnNo */
  ReturnNo = 'ReturnNo',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ObjectNo */
  ObjectNo = 'ObjectNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Unitprice */
  Unitprice = 'Unitprice',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on ExtDocNo */
  ExtDocNo = 'ExtDocNo',
  /** Aggregate result on ExtLineNo */
  ExtLineNo = 'ExtLineNo',
  /** Aggregate result on DeletedManually */
  DeletedManually = 'DeletedManually',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on ReqRefill */
  ReqRefill = 'ReqRefill',
  /** Aggregate result on ReqApproved */
  ReqApproved = 'ReqApproved',
  /** Aggregate result on ApprovedbyUserID */
  ApprovedbyUserId = 'ApprovedbyUserID',
  /** Aggregate result on CodeType */
  CodeType = 'CodeType',
  /** Aggregate result on CodeTypeOPTION */
  CodeTypeOption = 'CodeTypeOPTION',
  /** Aggregate result on MotherLineRegUpdateLine */
  MotherLineRegUpdateLine = 'MotherLineRegUpdateLine',
  /** Aggregate result on MotherLineType */
  MotherLineType = 'MotherLineType',
  /** Aggregate result on MotherLineTypeOPTION */
  MotherLineTypeOption = 'MotherLineTypeOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateObjectTypeInput {
  /** Aggregate result on ObjectType */
  ObjectType = 'ObjectType',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ObjectGroup */
  ObjectGroup = 'ObjectGroup',
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on FAClassCode */
  FaClassCode = 'FAClassCode',
  /** Aggregate result on FASubclassCode */
  FaSubclassCode = 'FASubclassCode',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepreciationMethod */
  DepreciationMethod = 'DepreciationMethod',
  /** Aggregate result on FAPostingGroup */
  FaPostingGroup = 'FAPostingGroup',
  /** Aggregate result on ObjectNos */
  ObjectNos = 'ObjectNos',
  /** Aggregate result on CostingMethod */
  CostingMethod = 'CostingMethod',
  /** Aggregate result on StandardCost */
  StandardCost = 'StandardCost',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on IndirectCost */
  IndirectCost = 'IndirectCost',
  /** Aggregate result on LastDirectCost */
  LastDirectCost = 'LastDirectCost',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceProfitCalculation */
  PriceProfitCalculation = 'PriceProfitCalculation',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on PriceIncludesVAT */
  PriceIncludesVat = 'PriceIncludesVAT',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on NoofDepreciationYears */
  NoofDepreciationYears = 'NoofDepreciationYears',
  /** Aggregate result on NoofDepreciationMonths */
  NoofDepreciationMonths = 'NoofDepreciationMonths',
  /** Aggregate result on CounterType */
  CounterType = 'CounterType',
  /** Aggregate result on WarrantyDiscParts */
  WarrantyDiscParts = 'WarrantyDiscParts',
  /** Aggregate result on WarrantyDiscLabor */
  WarrantyDiscLabor = 'WarrantyDiscLabor',
  /** Aggregate result on DefaultWarrantyDuration */
  DefaultWarrantyDuration = 'DefaultWarrantyDuration',
  /** Aggregate result on ServiceItemGroup */
  ServiceItemGroup = 'ServiceItemGroup',
  /** Aggregate result on ResponseTimeHours */
  ResponseTimeHours = 'ResponseTimeHours',
  /** Aggregate result on InventoryPostingGroup */
  InventoryPostingGroup = 'InventoryPostingGroup',
  /** Aggregate result on GenProdPostingGrpWriteUp */
  GenProdPostingGrpWriteUp = 'GenProdPostingGrpWriteUp',
  /** Aggregate result on SaleDiscGroup */
  SaleDiscGroup = 'SaleDiscGroup',
  /** Aggregate result on RentalDiscGroup */
  RentalDiscGroup = 'RentalDiscGroup',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on LastTimeModified */
  LastTimeModified = 'LastTimeModified',
  /** Aggregate result on VATBusPostingGrPrice */
  VatBusPostingGrPrice = 'VATBusPostingGrPrice',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on GenProdPostingGrpHire */
  GenProdPostingGrpHire = 'GenProdPostingGrpHire',
  /** Aggregate result on GenProdPostingGrpLeasing */
  GenProdPostingGrpLeasing = 'GenProdPostingGrpLeasing',
  /** Aggregate result on RentalSplitPostingGroup */
  RentalSplitPostingGroup = 'RentalSplitPostingGroup',
  /** Aggregate result on SalesPostingGroup */
  SalesPostingGroup = 'SalesPostingGroup',
  /** Aggregate result on GenProdPostingGrpExtRent */
  GenProdPostingGrpExtRent = 'GenProdPostingGrpExtRent',
  /** Aggregate result on GenProdPostingGrpIntRent */
  GenProdPostingGrpIntRent = 'GenProdPostingGrpIntRent',
  /** Aggregate result on ReplacementPrice */
  ReplacementPrice = 'ReplacementPrice',
  /** Aggregate result on Capacity */
  Capacity = 'Capacity',
  /** Aggregate result on Effect */
  Effect = 'Effect',
  /** Aggregate result on CylinderVolume */
  CylinderVolume = 'CylinderVolume',
  /** Aggregate result on AutomaticExtendedTexts */
  AutomaticExtendedTexts = 'AutomaticExtendedTexts',
  /** Aggregate result on Note */
  Note = 'Note',
  /** Aggregate result on CapacityMeasure */
  CapacityMeasure = 'CapacityMeasure',
  /** Aggregate result on EffectMeasure */
  EffectMeasure = 'EffectMeasure',
  /** Aggregate result on CylinderMeasure */
  CylinderMeasure = 'CylinderMeasure',
  /** Aggregate result on Document */
  Document = 'Document',
  /** Aggregate result on WebPage */
  WebPage = 'WebPage',
  /** Aggregate result on ExpAvailableAfterEndDate */
  ExpAvailableAfterEndDate = 'ExpAvailableAfterEndDate',
  /** Aggregate result on CreditonEarlyReturn */
  CreditonEarlyReturn = 'CreditonEarlyReturn',
  /** Aggregate result on StdRentalChargeDays */
  StdRentalChargeDays = 'StdRentalChargeDays',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on DefaultMaintenance */
  DefaultMaintenance = 'DefaultMaintenance',
  /** Aggregate result on Length */
  Length = 'Length',
  /** Aggregate result on LengthMeasure */
  LengthMeasure = 'LengthMeasure',
  /** Aggregate result on Width */
  Width = 'Width',
  /** Aggregate result on WidthMeasure */
  WidthMeasure = 'WidthMeasure',
  /** Aggregate result on Height */
  Height = 'Height',
  /** Aggregate result on HeightMeasure */
  HeightMeasure = 'HeightMeasure',
  /** Aggregate result on Weight */
  Weight = 'Weight',
  /** Aggregate result on WeightMeasure */
  WeightMeasure = 'WeightMeasure',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on QuantityHoursFullRentalDay */
  QuantityHoursFullRentalDay = 'QuantityHoursFullRentalDay',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on ObjectTypeNoConcern */
  ObjectTypeNoConcern = 'ObjectTypeNoConcern',
  /** Aggregate result on DepositAmount */
  DepositAmount = 'DepositAmount',
  /** Aggregate result on HireSplit */
  HireSplit = 'HireSplit',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Description3 */
  Description3 = 'Description3',
  /** Aggregate result on Description4 */
  Description4 = 'Description4',
  /** Aggregate result on FixedCostPrice */
  FixedCostPrice = 'FixedCostPrice',
  /** Aggregate result on RentalSplit */
  RentalSplit = 'RentalSplit',
  /** Aggregate result on StraightLine */
  StraightLine = 'StraightLine',
  /** Aggregate result on WarrantyDuration */
  WarrantyDuration = 'WarrantyDuration',
  /** Aggregate result on DecliningBalance */
  DecliningBalance = 'DecliningBalance',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on RetentionLevel */
  RetentionLevel = 'RetentionLevel',
  /** Aggregate result on TaxDepreciationMethod */
  TaxDepreciationMethod = 'TaxDepreciationMethod',
  /** Aggregate result on TaxFAPostingGroup */
  TaxFaPostingGroup = 'TaxFAPostingGroup',
  /** Aggregate result on TaxNoofDepreciationYears */
  TaxNoofDepreciationYears = 'TaxNoofDepreciationYears',
  /** Aggregate result on TaxNoofDepreciationMonths */
  TaxNoofDepreciationMonths = 'TaxNoofDepreciationMonths',
  /** Aggregate result on TaxStraightLine */
  TaxStraightLine = 'TaxStraightLine',
  /** Aggregate result on TaxDecliningBalance */
  TaxDecliningBalance = 'TaxDecliningBalance',
  /** Aggregate result on PriceScheduleCode */
  PriceScheduleCode = 'PriceScheduleCode',
  /** Aggregate result on EstimatedDailyHours */
  EstimatedDailyHours = 'EstimatedDailyHours',
  /** Aggregate result on MandatoryCounteronReturn */
  MandatoryCounteronReturn = 'MandatoryCounteronReturn',
  /** Aggregate result on CounterType2 */
  CounterType2 = 'CounterType2',
  /** Aggregate result on MandatoryCounter2onReturn */
  MandatoryCounter2onReturn = 'MandatoryCounter2onReturn',
  /** Aggregate result on EstimatedCounterperDay */
  EstimatedCounterperDay = 'EstimatedCounterperDay',
  /** Aggregate result on EstimatedCounter2perDay */
  EstimatedCounter2perDay = 'EstimatedCounter2perDay',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DateFilter2 */
  DateFilter2 = 'DateFilter2',
  /** Aggregate result on Inventory */
  Inventory = 'Inventory',
  /** Aggregate result on InventoryRental */
  InventoryRental = 'InventoryRental',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on CommentObjectGroup */
  CommentObjectGroup = 'CommentObjectGroup',
  /** Aggregate result on FlowSumCost */
  FlowSumCost = 'FlowSumCost',
  /** Aggregate result on FlowSalesDiscount */
  FlowSalesDiscount = 'FlowSalesDiscount',
  /** Aggregate result on FlowSalesAmount */
  FlowSalesAmount = 'FlowSalesAmount',
  /** Aggregate result on FlowOtherCost */
  FlowOtherCost = 'FlowOtherCost',
  /** Aggregate result on FlowLeasingCost */
  FlowLeasingCost = 'FlowLeasingCost',
  /** Aggregate result on FlowOtherIncome */
  FlowOtherIncome = 'FlowOtherIncome',
  /** Aggregate result on FlowRentalIncome */
  FlowRentalIncome = 'FlowRentalIncome',
  /** Aggregate result on FlowRentalDiscount */
  FlowRentalDiscount = 'FlowRentalDiscount',
  /** Aggregate result on FlowPurchaseCharge */
  FlowPurchaseCharge = 'FlowPurchaseCharge',
  /** Aggregate result on FlowServiceCost */
  FlowServiceCost = 'FlowServiceCost',
  /** Aggregate result on FlowGuaranteeCost */
  FlowGuaranteeCost = 'FlowGuaranteeCost',
  /** Aggregate result on FlowInternalRentalCost */
  FlowInternalRentalCost = 'FlowInternalRentalCost',
  /** Aggregate result on FlowHireCost */
  FlowHireCost = 'FlowHireCost',
  /** Aggregate result on FlowHireDiscount */
  FlowHireDiscount = 'FlowHireDiscount',
  /** Aggregate result on FlowServiceAgreementCost */
  FlowServiceAgreementCost = 'FlowServiceAgreementCost',
  /** Aggregate result on FlowDepreciation */
  FlowDepreciation = 'FlowDepreciation',
  /** Aggregate result on FlowUppreciation */
  FlowUppreciation = 'FlowUppreciation',
  /** Aggregate result on FlowDownpreciation */
  FlowDownpreciation = 'FlowDownpreciation',
  /** Aggregate result on FlowGainbySale */
  FlowGainbySale = 'FlowGainbySale',
  /** Aggregate result on FlowLossbySale */
  FlowLossbySale = 'FlowLossbySale',
  /** Aggregate result on FlowPurchaseDiscount */
  FlowPurchaseDiscount = 'FlowPurchaseDiscount',
  /** Aggregate result on FlowSalesCharge */
  FlowSalesCharge = 'FlowSalesCharge',
  /** Aggregate result on FlowServiceIncome */
  FlowServiceIncome = 'FlowServiceIncome',
  /** Aggregate result on FlowServiceAgreementIncome */
  FlowServiceAgreementIncome = 'FlowServiceAgreementIncome',
  /** Aggregate result on FlowInternalRentalIncome */
  FlowInternalRentalIncome = 'FlowInternalRentalIncome',
  /** Aggregate result on FlowValueChange */
  FlowValueChange = 'FlowValueChange',
  /** Aggregate result on FlowRentalDays */
  FlowRentalDays = 'FlowRentalDays',
  /** Aggregate result on FlowRentalHours */
  FlowRentalHours = 'FlowRentalHours',
  /** Aggregate result on InvoicedDays */
  InvoicedDays = 'InvoicedDays',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on RentalReservationsQty */
  RentalReservationsQty = 'RentalReservationsQty',
  /** Aggregate result on RentalDeliveriesQty */
  RentalDeliveriesQty = 'RentalDeliveriesQty',
  /** Aggregate result on ExpectedRentalReturnsQty */
  ExpectedRentalReturnsQty = 'ExpectedRentalReturnsQty',
  /** Aggregate result on RentalReturnsQty */
  RentalReturnsQty = 'RentalReturnsQty',
  /** Aggregate result on PictureURL */
  PictureUrl = 'PictureURL',
  /** Aggregate result on PSIDocumentURL */
  PsiDocumentUrl = 'PSIDocumentURL',
  /** Aggregate result on NotAvailableForReservation */
  NotAvailableForReservation = 'NotAvailableForReservation',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateOcrAnalysisInput {
  /** Aggregate result on language */
  Language = 'language',
  /** Aggregate result on textAngle */
  TextAngle = 'textAngle',
  /** Aggregate result on orientation */
  Orientation = 'orientation',
  /** Aggregate result on regions */
  Regions = 'regions',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePaymentMethodInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on DirectDebit */
  DirectDebit = 'DirectDebit',
  /** Aggregate result on DirectDebitPmtTermsCode */
  DirectDebitPmtTermsCode = 'DirectDebitPmtTermsCode',
  /** Aggregate result on PmtExportLineDefinition */
  PmtExportLineDefinition = 'PmtExportLineDefinition',
  /** Aggregate result on BankDataConversionPmtType */
  BankDataConversionPmtType = 'BankDataConversionPmtType',
  /** Aggregate result on UseforInvoicing */
  UseforInvoicing = 'UseforInvoicing',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on AMCBankPmtType */
  AmcBankPmtType = 'AMCBankPmtType',
  /** Aggregate result on EQMCashPayment */
  EqmCashPayment = 'EQMCashPayment',
  /** Aggregate result on EQMPaymentType */
  EqmPaymentType = 'EQMPaymentType',
  /** Aggregate result on EQMPaymentTypeOPTION */
  EqmPaymentTypeOption = 'EQMPaymentTypeOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePaymentTermsInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on DueDateCalculation */
  DueDateCalculation = 'DueDateCalculation',
  /** Aggregate result on DiscountDateCalculation */
  DiscountDateCalculation = 'DiscountDateCalculation',
  /** Aggregate result on DiscountPercent */
  DiscountPercent = 'DiscountPercent',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on CalcPmtDisconCrMemos */
  CalcPmtDisconCrMemos = 'CalcPmtDisconCrMemos',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePlatformFeatureInput {
  /** Aggregate result on name */
  Name = 'name',
  /** Aggregate result on group */
  Group = 'group',
  /** Aggregate result on basic */
  Basic = 'basic',
  /** Aggregate result on premium */
  Premium = 'premium',
  /** Aggregate result on sorting */
  Sorting = 'sorting',
  /** Aggregate result on enterprise */
  Enterprise = 'enterprise',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePostCodeInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on SearchCity */
  SearchCity = 'SearchCity',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on TimeZone */
  TimeZone = 'TimeZone',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateProjectInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on SearchDescription */
  SearchDescription = 'SearchDescription',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on CreationDate */
  CreationDate = 'CreationDate',
  /** Aggregate result on StartingDate */
  StartingDate = 'StartingDate',
  /** Aggregate result on EndingDate */
  EndingDate = 'EndingDate',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on PersonResponsible */
  PersonResponsible = 'PersonResponsible',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on JobPostingGroup */
  JobPostingGroup = 'JobPostingGroup',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on BlockedOPTION */
  BlockedOption = 'BlockedOPTION',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on Image */
  Image = 'Image',
  /** Aggregate result on WIPMethod */
  WipMethod = 'WIPMethod',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on WIPPostingDate */
  WipPostingDate = 'WIPPostingDate',
  /** Aggregate result on InvoiceCurrencyCode */
  InvoiceCurrencyCode = 'InvoiceCurrencyCode',
  /** Aggregate result on ExchCalculationCost */
  ExchCalculationCost = 'ExchCalculationCost',
  /** Aggregate result on ExchCalculationCostOPTION */
  ExchCalculationCostOption = 'ExchCalculationCostOPTION',
  /** Aggregate result on ExchCalculationPrice */
  ExchCalculationPrice = 'ExchCalculationPrice',
  /** Aggregate result on ExchCalculationPriceOPTION */
  ExchCalculationPriceOption = 'ExchCalculationPriceOPTION',
  /** Aggregate result on AllowScheduleContractLines */
  AllowScheduleContractLines = 'AllowScheduleContractLines',
  /** Aggregate result on Complete */
  Complete = 'Complete',
  /** Aggregate result on ApplyUsageLink */
  ApplyUsageLink = 'ApplyUsageLink',
  /** Aggregate result on WIPPostingMethod */
  WipPostingMethod = 'WIPPostingMethod',
  /** Aggregate result on WIPPostingMethodOPTION */
  WipPostingMethodOption = 'WIPPostingMethodOPTION',
  /** Aggregate result on OverBudget */
  OverBudget = 'OverBudget',
  /** Aggregate result on ProjectManager */
  ProjectManager = 'ProjectManager',
  /** Aggregate result on BusinessArea */
  BusinessArea = 'BusinessArea',
  /** Aggregate result on Inactive */
  Inactive = 'Inactive',
  /** Aggregate result on NotChargeable */
  NotChargeable = 'NotChargeable',
  /** Aggregate result on ResourceFilter */
  ResourceFilter = 'ResourceFilter',
  /** Aggregate result on PostingDateFilter */
  PostingDateFilter = 'PostingDateFilter',
  /** Aggregate result on ResourceGrFilter */
  ResourceGrFilter = 'ResourceGrFilter',
  /** Aggregate result on PlanningDateFilter */
  PlanningDateFilter = 'PlanningDateFilter',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on ScheduledResQty */
  ScheduledResQty = 'ScheduledResQty',
  /** Aggregate result on ScheduledResGrQty */
  ScheduledResGrQty = 'ScheduledResGrQty',
  /** Aggregate result on TotalWIPCostAmount */
  TotalWipCostAmount = 'TotalWIPCostAmount',
  /** Aggregate result on TotalWIPCostGLAmount */
  TotalWipCostGlAmount = 'TotalWIPCostGLAmount',
  /** Aggregate result on WIPEntriesExist */
  WipEntriesExist = 'WIPEntriesExist',
  /** Aggregate result on WIPGLPostingDate */
  WipglPostingDate = 'WIPGLPostingDate',
  /** Aggregate result on RecogSalesAmount */
  RecogSalesAmount = 'RecogSalesAmount',
  /** Aggregate result on RecogSalesGLAmount */
  RecogSalesGlAmount = 'RecogSalesGLAmount',
  /** Aggregate result on RecogCostsAmount */
  RecogCostsAmount = 'RecogCostsAmount',
  /** Aggregate result on RecogCostsGLAmount */
  RecogCostsGlAmount = 'RecogCostsGLAmount',
  /** Aggregate result on TotalWIPSalesAmount */
  TotalWipSalesAmount = 'TotalWIPSalesAmount',
  /** Aggregate result on TotalWIPSalesGLAmount */
  TotalWipSalesGlAmount = 'TotalWIPSalesGLAmount',
  /** Aggregate result on WIPCompletionCalculated */
  WipCompletionCalculated = 'WIPCompletionCalculated',
  /** Aggregate result on NextInvoiceDate */
  NextInvoiceDate = 'NextInvoiceDate',
  /** Aggregate result on WIPWarnings */
  WipWarnings = 'WIPWarnings',
  /** Aggregate result on AppliedCostsGLAmount */
  AppliedCostsGlAmount = 'AppliedCostsGLAmount',
  /** Aggregate result on AppliedSalesGLAmount */
  AppliedSalesGlAmount = 'AppliedSalesGLAmount',
  /** Aggregate result on CalcRecogSalesAmount */
  CalcRecogSalesAmount = 'CalcRecogSalesAmount',
  /** Aggregate result on CalcRecogCostsAmount */
  CalcRecogCostsAmount = 'CalcRecogCostsAmount',
  /** Aggregate result on CalcRecogSalesGLAmount */
  CalcRecogSalesGlAmount = 'CalcRecogSalesGLAmount',
  /** Aggregate result on CalcRecogCostsGLAmount */
  CalcRecogCostsGlAmount = 'CalcRecogCostsGLAmount',
  /** Aggregate result on WIPCompletionPosted */
  WipCompletionPosted = 'WIPCompletionPosted',
  /** Aggregate result on MonthlyValue */
  MonthlyValue = 'MonthlyValue',
  /** Aggregate result on EstimatedValue */
  EstimatedValue = 'EstimatedValue',
  /** Aggregate result on LinkedTo */
  LinkedTo = 'LinkedTo',
  /** Aggregate result on JobTaskType */
  JobTaskType = 'JobTaskType',
  /** Aggregate result on SyncJobTastTypeWithDevops */
  SyncJobTastTypeWithDevops = 'SyncJobTastTypeWithDevops',
  /** Aggregate result on DevopsTeamId */
  DevopsTeamId = 'DevopsTeamId',
  /** Aggregate result on DevopsProjectId */
  DevopsProjectId = 'DevopsProjectId',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchCrMemoHeaderInput {
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on PaytoName */
  PaytoName = 'PaytoName',
  /** Aggregate result on PaytoName2 */
  PaytoName2 = 'PaytoName2',
  /** Aggregate result on PaytoAddress */
  PaytoAddress = 'PaytoAddress',
  /** Aggregate result on PaytoAddress2 */
  PaytoAddress2 = 'PaytoAddress2',
  /** Aggregate result on PaytoCity */
  PaytoCity = 'PaytoCity',
  /** Aggregate result on PaytoContact */
  PaytoContact = 'PaytoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscountPercent */
  PaymentDiscountPercent = 'PaymentDiscountPercent',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on VendorPostingGroup */
  VendorPostingGroup = 'VendorPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on PurchaserCode */
  PurchaserCode = 'PurchaserCode',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on VendorCrMemoNo */
  VendorCrMemoNo = 'VendorCrMemoNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on BuyfromVendorName */
  BuyfromVendorName = 'BuyfromVendorName',
  /** Aggregate result on BuyfromVendorName2 */
  BuyfromVendorName2 = 'BuyfromVendorName2',
  /** Aggregate result on BuyfromAddress */
  BuyfromAddress = 'BuyfromAddress',
  /** Aggregate result on BuyfromAddress2 */
  BuyfromAddress2 = 'BuyfromAddress2',
  /** Aggregate result on BuyfromCity */
  BuyfromCity = 'BuyfromCity',
  /** Aggregate result on BuyfromContact */
  BuyfromContact = 'BuyfromContact',
  /** Aggregate result on PaytoPostCode */
  PaytoPostCode = 'PaytoPostCode',
  /** Aggregate result on PaytoCounty */
  PaytoCounty = 'PaytoCounty',
  /** Aggregate result on PaytoCountryRegionCode */
  PaytoCountryRegionCode = 'PaytoCountryRegionCode',
  /** Aggregate result on BuyfromPostCode */
  BuyfromPostCode = 'BuyfromPostCode',
  /** Aggregate result on BuyfromCounty */
  BuyfromCounty = 'BuyfromCounty',
  /** Aggregate result on BuyfromCountryRegionCode */
  BuyfromCountryRegionCode = 'BuyfromCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on OrderAddressCode */
  OrderAddressCode = 'OrderAddressCode',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on PreAssignedNoSeries */
  PreAssignedNoSeries = 'PreAssignedNoSeries',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on PreAssignedNo */
  PreAssignedNo = 'PreAssignedNo',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATBaseDiscountPercent */
  VatBaseDiscountPercent = 'VATBaseDiscountPercent',
  /** Aggregate result on PrepmtCrMemoNoSeries */
  PrepmtCrMemoNoSeries = 'PrepmtCrMemoNoSeries',
  /** Aggregate result on PrepaymentCreditMemo */
  PrepaymentCreditMemo = 'PrepaymentCreditMemo',
  /** Aggregate result on PrepaymentOrderNo */
  PrepaymentOrderNo = 'PrepaymentOrderNo',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on Paid */
  Paid = 'Paid',
  /** Aggregate result on RemainingAmount */
  RemainingAmount = 'RemainingAmount',
  /** Aggregate result on VendorLedgerEntryNo */
  VendorLedgerEntryNo = 'VendorLedgerEntryNo',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on Cancelled */
  Cancelled = 'Cancelled',
  /** Aggregate result on Corrective */
  Corrective = 'Corrective',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on BuyfromContactNo */
  BuyfromContactNo = 'BuyfromContactNo',
  /** Aggregate result on PaytoContactNo */
  PaytoContactNo = 'PaytoContactNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on ReturnOrderNo */
  ReturnOrderNo = 'ReturnOrderNo',
  /** Aggregate result on ReturnOrderNoSeries */
  ReturnOrderNoSeries = 'ReturnOrderNoSeries',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on PEBNoteofGoods */
  PebNoteofGoods = 'PEBNoteofGoods',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMObjectPurchase */
  EqmObjectPurchase = 'EQMObjectPurchase',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceTermNo */
  EqmServiceTermNo = 'EQMServiceTermNo',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMServiceStatusOPTION */
  EqmServiceStatusOption = 'EQMServiceStatusOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchCrMemoLineInput {
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on DirectUnitCost */
  DirectUnitCost = 'DirectUnitCost',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VATPercent */
  VatPercent = 'VATPercent',
  /** Aggregate result on LineDiscountPercent */
  LineDiscountPercent = 'LineDiscountPercent',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on UnitPriceLCY */
  UnitPriceLcy = 'UnitPriceLCY',
  /** Aggregate result on AllowCrMemooiceDisc */
  AllowCrMemooiceDisc = 'AllowCrMemooiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on IndirectCostPercent */
  IndirectCostPercent = 'IndirectCostPercent',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on CrMemoDiscountAmount */
  CrMemoDiscountAmount = 'CrMemoDiscountAmount',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on UseTax */
  UseTax = 'UseTax',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ICItemReferenceNo */
  IcItemReferenceNo = 'ICItemReferenceNo',
  /** Aggregate result on PmtDiscountAmount */
  PmtDiscountAmount = 'PmtDiscountAmount',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobLineType */
  JobLineType = 'JobLineType',
  /** Aggregate result on JobLineTypeOPTION */
  JobLineTypeOption = 'JobLineTypeOPTION',
  /** Aggregate result on JobUnitPrice */
  JobUnitPrice = 'JobUnitPrice',
  /** Aggregate result on JobTotalPrice */
  JobTotalPrice = 'JobTotalPrice',
  /** Aggregate result on JobLineAmount */
  JobLineAmount = 'JobLineAmount',
  /** Aggregate result on JobLineDiscountAmount */
  JobLineDiscountAmount = 'JobLineDiscountAmount',
  /** Aggregate result on JobLineDiscountPercent */
  JobLineDiscountPercent = 'JobLineDiscountPercent',
  /** Aggregate result on JobUnitPriceLCY */
  JobUnitPriceLcy = 'JobUnitPriceLCY',
  /** Aggregate result on JobTotalPriceLCY */
  JobTotalPriceLcy = 'JobTotalPriceLCY',
  /** Aggregate result on JobLineAmountLCY */
  JobLineAmountLcy = 'JobLineAmountLCY',
  /** Aggregate result on JobLineDiscAmountLCY */
  JobLineDiscAmountLcy = 'JobLineDiscAmountLCY',
  /** Aggregate result on JobCurrencyFactor */
  JobCurrencyFactor = 'JobCurrencyFactor',
  /** Aggregate result on JobCurrencyCode */
  JobCurrencyCode = 'JobCurrencyCode',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on ProdOrderNo */
  ProdOrderNo = 'ProdOrderNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on FAPostingType */
  FaPostingType = 'FAPostingType',
  /** Aggregate result on FAPostingTypeOPTION */
  FaPostingTypeOption = 'FAPostingTypeOPTION',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on SalvageValue */
  SalvageValue = 'SalvageValue',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DeprAcquisitionCost */
  DeprAcquisitionCost = 'DeprAcquisitionCost',
  /** Aggregate result on MaintenanceCode */
  MaintenanceCode = 'MaintenanceCode',
  /** Aggregate result on InsuranceNo */
  InsuranceNo = 'InsuranceNo',
  /** Aggregate result on BudgetedFANo */
  BudgetedFaNo = 'BudgetedFANo',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ItemReferenceNo */
  ItemReferenceNo = 'ItemReferenceNo',
  /** Aggregate result on ItemReferenceUnitofMeasure */
  ItemReferenceUnitofMeasure = 'ItemReferenceUnitofMeasure',
  /** Aggregate result on ItemReferenceType */
  ItemReferenceType = 'ItemReferenceType',
  /** Aggregate result on ItemReferenceTypeOPTION */
  ItemReferenceTypeOption = 'ItemReferenceTypeOPTION',
  /** Aggregate result on ItemReferenceTypeNo */
  ItemReferenceTypeNo = 'ItemReferenceTypeNo',
  /** Aggregate result on ReturnShipmentNo */
  ReturnShipmentNo = 'ReturnShipmentNo',
  /** Aggregate result on ReturnShipmentLineNo */
  ReturnShipmentLineNo = 'ReturnShipmentLineNo',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on PEBChargeType */
  PebChargeType = 'PEBChargeType',
  /** Aggregate result on PEBChargeTypeOPTION */
  PebChargeTypeOption = 'PEBChargeTypeOPTION',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMObjectCostAssignment */
  EqmObjectCostAssignment = 'EQMObjectCostAssignment',
  /** Aggregate result on EQMFixedAssetSetupGroup */
  EqmFixedAssetSetupGroup = 'EQMFixedAssetSetupGroup',
  /** Aggregate result on EQMFixedAssetNo */
  EqmFixedAssetNo = 'EQMFixedAssetNo',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMReRentNo */
  EqmReRentNo = 'EQMReRentNo',
  /** Aggregate result on EQMSerialNo */
  EqmSerialNo = 'EQMSerialNo',
  /** Aggregate result on EQMObjectBinCode */
  EqmObjectBinCode = 'EQMObjectBinCode',
  /** Aggregate result on EQMManufacturerCode */
  EqmManufacturerCode = 'EQMManufacturerCode',
  /** Aggregate result on EQMManufacturerModelCode */
  EqmManufacturerModelCode = 'EQMManufacturerModelCode',
  /** Aggregate result on ExternalLineNo */
  ExternalLineNo = 'ExternalLineNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchInvHeaderInput {
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on PaytoName */
  PaytoName = 'PaytoName',
  /** Aggregate result on PaytoName2 */
  PaytoName2 = 'PaytoName2',
  /** Aggregate result on PaytoAddress */
  PaytoAddress = 'PaytoAddress',
  /** Aggregate result on PaytoAddress2 */
  PaytoAddress2 = 'PaytoAddress2',
  /** Aggregate result on PaytoCity */
  PaytoCity = 'PaytoCity',
  /** Aggregate result on PaytoContact */
  PaytoContact = 'PaytoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscountPercent */
  PaymentDiscountPercent = 'PaymentDiscountPercent',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on VendorPostingGroup */
  VendorPostingGroup = 'VendorPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on PurchaserCode */
  PurchaserCode = 'PurchaserCode',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on VendorOrderNo */
  VendorOrderNo = 'VendorOrderNo',
  /** Aggregate result on VendorInvoiceNo */
  VendorInvoiceNo = 'VendorInvoiceNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on BuyfromVendorName */
  BuyfromVendorName = 'BuyfromVendorName',
  /** Aggregate result on BuyfromVendorName2 */
  BuyfromVendorName2 = 'BuyfromVendorName2',
  /** Aggregate result on BuyfromAddress */
  BuyfromAddress = 'BuyfromAddress',
  /** Aggregate result on BuyfromAddress2 */
  BuyfromAddress2 = 'BuyfromAddress2',
  /** Aggregate result on BuyfromCity */
  BuyfromCity = 'BuyfromCity',
  /** Aggregate result on BuyfromContact */
  BuyfromContact = 'BuyfromContact',
  /** Aggregate result on PaytoPostCode */
  PaytoPostCode = 'PaytoPostCode',
  /** Aggregate result on PaytoCounty */
  PaytoCounty = 'PaytoCounty',
  /** Aggregate result on PaytoCountryRegionCode */
  PaytoCountryRegionCode = 'PaytoCountryRegionCode',
  /** Aggregate result on BuyfromPostCode */
  BuyfromPostCode = 'BuyfromPostCode',
  /** Aggregate result on BuyfromCounty */
  BuyfromCounty = 'BuyfromCounty',
  /** Aggregate result on BuyfromCountryRegionCode */
  BuyfromCountryRegionCode = 'BuyfromCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on OrderAddressCode */
  OrderAddressCode = 'OrderAddressCode',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on PreAssignedNoSeries */
  PreAssignedNoSeries = 'PreAssignedNoSeries',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on OrderNoSeries */
  OrderNoSeries = 'OrderNoSeries',
  /** Aggregate result on PreAssignedNo */
  PreAssignedNo = 'PreAssignedNo',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATBaseDiscountPercent */
  VatBaseDiscountPercent = 'VATBaseDiscountPercent',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on PrepaymentInvoice */
  PrepaymentInvoice = 'PrepaymentInvoice',
  /** Aggregate result on PrepaymentOrderNo */
  PrepaymentOrderNo = 'PrepaymentOrderNo',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on CreditorNo */
  CreditorNo = 'CreditorNo',
  /** Aggregate result on PaymentReference */
  PaymentReference = 'PaymentReference',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on Closed */
  Closed = 'Closed',
  /** Aggregate result on RemainingAmount */
  RemainingAmount = 'RemainingAmount',
  /** Aggregate result on VendorLedgerEntryNo */
  VendorLedgerEntryNo = 'VendorLedgerEntryNo',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on Cancelled */
  Cancelled = 'Cancelled',
  /** Aggregate result on Corrective */
  Corrective = 'Corrective',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on BuyfromContactNo */
  BuyfromContactNo = 'BuyfromContactNo',
  /** Aggregate result on PaytoContactNo */
  PaytoContactNo = 'PaytoContactNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on DraftInvoiceSystemId */
  DraftInvoiceSystemId = 'DraftInvoiceSystemId',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on PEBNoteofGoods */
  PebNoteofGoods = 'PEBNoteofGoods',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMObjectPurchase */
  EqmObjectPurchase = 'EQMObjectPurchase',
  /** Aggregate result on EQMWorkshopDocument */
  EqmWorkshopDocument = 'EQMWorkshopDocument',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceTermNo */
  EqmServiceTermNo = 'EQMServiceTermNo',
  /** Aggregate result on EQMWSObjectCounter */
  EqmwsObjectCounter = 'EQMWSObjectCounter',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMServiceStartDate */
  EqmServiceStartDate = 'EQMServiceStartDate',
  /** Aggregate result on EQMServiceEndDate */
  EqmServiceEndDate = 'EQMServiceEndDate',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMServiceStatusOPTION */
  EqmServiceStatusOption = 'EQMServiceStatusOPTION',
  /** Aggregate result on EQMObjectWarrantyDate */
  EqmObjectWarrantyDate = 'EQMObjectWarrantyDate',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMWSEstimatedStartDate */
  EqmwsEstimatedStartDate = 'EQMWSEstimatedStartDate',
  /** Aggregate result on EQMWSEstimatedStartTime */
  EqmwsEstimatedStartTime = 'EQMWSEstimatedStartTime',
  /** Aggregate result on EQMWSEstimatedEndDate */
  EqmwsEstimatedEndDate = 'EQMWSEstimatedEndDate',
  /** Aggregate result on EQMWSEstimatedEndTime */
  EqmwsEstimatedEndTime = 'EQMWSEstimatedEndTime',
  /** Aggregate result on EQMerviceStartTime */
  EqMerviceStartTime = 'EQMerviceStartTime',
  /** Aggregate result on EQMServiceEndTime */
  EqmServiceEndTime = 'EQMServiceEndTime',
  /** Aggregate result on EQMObjWorkPurchFlow */
  EqmObjWorkPurchFlow = 'EQMObjWorkPurchFlow',
  /** Aggregate result on EQMWSObjectCounter2 */
  EqmwsObjectCounter2 = 'EQMWSObjectCounter2',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchInvLineInput {
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on DirectUnitCost */
  DirectUnitCost = 'DirectUnitCost',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VATPercent */
  VatPercent = 'VATPercent',
  /** Aggregate result on LineDiscountPercent */
  LineDiscountPercent = 'LineDiscountPercent',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on UnitPriceLCY */
  UnitPriceLcy = 'UnitPriceLCY',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on IndirectCostPercent */
  IndirectCostPercent = 'IndirectCostPercent',
  /** Aggregate result on ReceiptNo */
  ReceiptNo = 'ReceiptNo',
  /** Aggregate result on ReceiptLineNo */
  ReceiptLineNo = 'ReceiptLineNo',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on InvDiscountAmount */
  InvDiscountAmount = 'InvDiscountAmount',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on UseTax */
  UseTax = 'UseTax',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ICCrossReferenceNo */
  IcCrossReferenceNo = 'ICCrossReferenceNo',
  /** Aggregate result on PmtDiscountAmount */
  PmtDiscountAmount = 'PmtDiscountAmount',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobLineType */
  JobLineType = 'JobLineType',
  /** Aggregate result on JobLineTypeOPTION */
  JobLineTypeOption = 'JobLineTypeOPTION',
  /** Aggregate result on JobUnitPrice */
  JobUnitPrice = 'JobUnitPrice',
  /** Aggregate result on JobTotalPrice */
  JobTotalPrice = 'JobTotalPrice',
  /** Aggregate result on JobLineAmount */
  JobLineAmount = 'JobLineAmount',
  /** Aggregate result on JobLineDiscountAmount */
  JobLineDiscountAmount = 'JobLineDiscountAmount',
  /** Aggregate result on JobLineDiscountPercent */
  JobLineDiscountPercent = 'JobLineDiscountPercent',
  /** Aggregate result on JobUnitPriceLCY */
  JobUnitPriceLcy = 'JobUnitPriceLCY',
  /** Aggregate result on JobTotalPriceLCY */
  JobTotalPriceLcy = 'JobTotalPriceLCY',
  /** Aggregate result on JobLineAmountLCY */
  JobLineAmountLcy = 'JobLineAmountLCY',
  /** Aggregate result on JobLineDiscAmountLCY */
  JobLineDiscAmountLcy = 'JobLineDiscAmountLCY',
  /** Aggregate result on JobCurrencyFactor */
  JobCurrencyFactor = 'JobCurrencyFactor',
  /** Aggregate result on JobCurrencyCode */
  JobCurrencyCode = 'JobCurrencyCode',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on ProdOrderNo */
  ProdOrderNo = 'ProdOrderNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on FAPostingType */
  FaPostingType = 'FAPostingType',
  /** Aggregate result on FAPostingTypeOPTION */
  FaPostingTypeOption = 'FAPostingTypeOPTION',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on SalvageValue */
  SalvageValue = 'SalvageValue',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DeprAcquisitionCost */
  DeprAcquisitionCost = 'DeprAcquisitionCost',
  /** Aggregate result on MaintenanceCode */
  MaintenanceCode = 'MaintenanceCode',
  /** Aggregate result on InsuranceNo */
  InsuranceNo = 'InsuranceNo',
  /** Aggregate result on BudgetedFANo */
  BudgetedFaNo = 'BudgetedFANo',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on ItemReferenceNo */
  ItemReferenceNo = 'ItemReferenceNo',
  /** Aggregate result on ItemReferenceUnitofMeasure */
  ItemReferenceUnitofMeasure = 'ItemReferenceUnitofMeasure',
  /** Aggregate result on ItemReferenceType */
  ItemReferenceType = 'ItemReferenceType',
  /** Aggregate result on ItemReferenceTypeOPTION */
  ItemReferenceTypeOption = 'ItemReferenceTypeOPTION',
  /** Aggregate result on ItemReferenceTypeNo */
  ItemReferenceTypeNo = 'ItemReferenceTypeNo',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on PEBChargeType */
  PebChargeType = 'PEBChargeType',
  /** Aggregate result on PEBChargeTypeOPTION */
  PebChargeTypeOption = 'PEBChargeTypeOPTION',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMObjectCostAssignment */
  EqmObjectCostAssignment = 'EQMObjectCostAssignment',
  /** Aggregate result on EQMFixedAssetSetupGroup */
  EqmFixedAssetSetupGroup = 'EQMFixedAssetSetupGroup',
  /** Aggregate result on EQMFixedAssetNo */
  EqmFixedAssetNo = 'EQMFixedAssetNo',
  /** Aggregate result on EQMWorkshop */
  EqmWorkshop = 'EQMWorkshop',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMReRentNo */
  EqmReRentNo = 'EQMReRentNo',
  /** Aggregate result on EQMSerialNo */
  EqmSerialNo = 'EQMSerialNo',
  /** Aggregate result on EQMObjectBinCode */
  EqmObjectBinCode = 'EQMObjectBinCode',
  /** Aggregate result on EQMManufacturerCode */
  EqmManufacturerCode = 'EQMManufacturerCode',
  /** Aggregate result on EQMManufacturerModelCode */
  EqmManufacturerModelCode = 'EQMManufacturerModelCode',
  /** Aggregate result on RoutingNo */
  RoutingNo = 'RoutingNo',
  /** Aggregate result on OperationNo */
  OperationNo = 'OperationNo',
  /** Aggregate result on WorkCenterNo */
  WorkCenterNo = 'WorkCenterNo',
  /** Aggregate result on ProdOrderLineNo */
  ProdOrderLineNo = 'ProdOrderLineNo',
  /** Aggregate result on OverheadRate */
  OverheadRate = 'OverheadRate',
  /** Aggregate result on RoutingReferenceNo */
  RoutingReferenceNo = 'RoutingReferenceNo',
  /** Aggregate result on ExternalLineNo */
  ExternalLineNo = 'ExternalLineNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchRcptHeaderInput {
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on PaytoName */
  PaytoName = 'PaytoName',
  /** Aggregate result on PaytoName2 */
  PaytoName2 = 'PaytoName2',
  /** Aggregate result on PaytoAddress */
  PaytoAddress = 'PaytoAddress',
  /** Aggregate result on PaytoAddress2 */
  PaytoAddress2 = 'PaytoAddress2',
  /** Aggregate result on PaytoCity */
  PaytoCity = 'PaytoCity',
  /** Aggregate result on PaytoContact */
  PaytoContact = 'PaytoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscountPercent */
  PaymentDiscountPercent = 'PaymentDiscountPercent',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on VendorPostingGroup */
  VendorPostingGroup = 'VendorPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on PurchaserCode */
  PurchaserCode = 'PurchaserCode',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on VendorOrderNo */
  VendorOrderNo = 'VendorOrderNo',
  /** Aggregate result on VendorShipmentNo */
  VendorShipmentNo = 'VendorShipmentNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on BuyfromVendorName */
  BuyfromVendorName = 'BuyfromVendorName',
  /** Aggregate result on BuyfromVendorName2 */
  BuyfromVendorName2 = 'BuyfromVendorName2',
  /** Aggregate result on BuyfromAddress */
  BuyfromAddress = 'BuyfromAddress',
  /** Aggregate result on BuyfromAddress2 */
  BuyfromAddress2 = 'BuyfromAddress2',
  /** Aggregate result on BuyfromCity */
  BuyfromCity = 'BuyfromCity',
  /** Aggregate result on BuyfromContact */
  BuyfromContact = 'BuyfromContact',
  /** Aggregate result on PaytoPostCode */
  PaytoPostCode = 'PaytoPostCode',
  /** Aggregate result on PaytoCounty */
  PaytoCounty = 'PaytoCounty',
  /** Aggregate result on PaytoCountryRegionCode */
  PaytoCountryRegionCode = 'PaytoCountryRegionCode',
  /** Aggregate result on BuyfromPostCode */
  BuyfromPostCode = 'BuyfromPostCode',
  /** Aggregate result on BuyfromCounty */
  BuyfromCounty = 'BuyfromCounty',
  /** Aggregate result on BuyfromCountryRegionCode */
  BuyfromCountryRegionCode = 'BuyfromCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on OrderAddressCode */
  OrderAddressCode = 'OrderAddressCode',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on OrderNoSeries */
  OrderNoSeries = 'OrderNoSeries',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATBaseDiscountPercent */
  VatBaseDiscountPercent = 'VATBaseDiscountPercent',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on BuyfromContactNo */
  BuyfromContactNo = 'BuyfromContactNo',
  /** Aggregate result on PaytoContactno */
  PaytoContactno = 'PaytoContactno',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on RequestedReceiptDate */
  RequestedReceiptDate = 'RequestedReceiptDate',
  /** Aggregate result on PromisedReceiptDate */
  PromisedReceiptDate = 'PromisedReceiptDate',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on PEBNoteofGoods */
  PebNoteofGoods = 'PEBNoteofGoods',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMObjectPurchase */
  EqmObjectPurchase = 'EQMObjectPurchase',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMServiceStatusOPTION */
  EqmServiceStatusOption = 'EQMServiceStatusOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchRcptLineInput {
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on DirectUnitCost */
  DirectUnitCost = 'DirectUnitCost',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VATPercent */
  VatPercent = 'VATPercent',
  /** Aggregate result on LineDiscountPercent */
  LineDiscountPercent = 'LineDiscountPercent',
  /** Aggregate result on UnitPriceLCY */
  UnitPriceLcy = 'UnitPriceLCY',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ItemRcptEntryNo */
  ItemRcptEntryNo = 'ItemRcptEntryNo',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on IndirectCostPercent */
  IndirectCostPercent = 'IndirectCostPercent',
  /** Aggregate result on QtyRcdNotInvoiced */
  QtyRcdNotInvoiced = 'QtyRcdNotInvoiced',
  /** Aggregate result on QuantityInvoiced */
  QuantityInvoiced = 'QuantityInvoiced',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on SalesOrderNo */
  SalesOrderNo = 'SalesOrderNo',
  /** Aggregate result on SalesOrderLineNo */
  SalesOrderLineNo = 'SalesOrderLineNo',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on UseTax */
  UseTax = 'UseTax',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ICItemReferenceNo */
  IcItemReferenceNo = 'ICItemReferenceNo',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobLineType */
  JobLineType = 'JobLineType',
  /** Aggregate result on JobLineTypeOPTION */
  JobLineTypeOption = 'JobLineTypeOPTION',
  /** Aggregate result on JobUnitPrice */
  JobUnitPrice = 'JobUnitPrice',
  /** Aggregate result on JobTotalPrice */
  JobTotalPrice = 'JobTotalPrice',
  /** Aggregate result on JobLineAmount */
  JobLineAmount = 'JobLineAmount',
  /** Aggregate result on JobLineDiscountAmount */
  JobLineDiscountAmount = 'JobLineDiscountAmount',
  /** Aggregate result on JobLineDiscountPercent */
  JobLineDiscountPercent = 'JobLineDiscountPercent',
  /** Aggregate result on JobUnitPriceLCY */
  JobUnitPriceLcy = 'JobUnitPriceLCY',
  /** Aggregate result on JobTotalPriceLCY */
  JobTotalPriceLcy = 'JobTotalPriceLCY',
  /** Aggregate result on JobLineAmountLCY */
  JobLineAmountLcy = 'JobLineAmountLCY',
  /** Aggregate result on JobLineDiscAmountLCY */
  JobLineDiscAmountLcy = 'JobLineDiscAmountLCY',
  /** Aggregate result on JobCurrencyFactor */
  JobCurrencyFactor = 'JobCurrencyFactor',
  /** Aggregate result on JobCurrencyCode */
  JobCurrencyCode = 'JobCurrencyCode',
  /** Aggregate result on ProdOrderNo */
  ProdOrderNo = 'ProdOrderNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on QtyInvoicedBase */
  QtyInvoicedBase = 'QtyInvoicedBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on FAPostingType */
  FaPostingType = 'FAPostingType',
  /** Aggregate result on FAPostingTypeOPTION */
  FaPostingTypeOption = 'FAPostingTypeOPTION',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on SalvageValue */
  SalvageValue = 'SalvageValue',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DeprAcquisitionCost */
  DeprAcquisitionCost = 'DeprAcquisitionCost',
  /** Aggregate result on MaintenanceCode */
  MaintenanceCode = 'MaintenanceCode',
  /** Aggregate result on InsuranceNo */
  InsuranceNo = 'InsuranceNo',
  /** Aggregate result on BudgetedFANo */
  BudgetedFaNo = 'BudgetedFANo',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on SpecialOrderSalesNo */
  SpecialOrderSalesNo = 'SpecialOrderSalesNo',
  /** Aggregate result on SpecialOrderSalesLineNo */
  SpecialOrderSalesLineNo = 'SpecialOrderSalesLineNo',
  /** Aggregate result on ItemReferenceNo */
  ItemReferenceNo = 'ItemReferenceNo',
  /** Aggregate result on ItemReferenceUnitofMeasure */
  ItemReferenceUnitofMeasure = 'ItemReferenceUnitofMeasure',
  /** Aggregate result on ItemReferenceType */
  ItemReferenceType = 'ItemReferenceType',
  /** Aggregate result on ItemReferenceTypeOPTION */
  ItemReferenceTypeOption = 'ItemReferenceTypeOPTION',
  /** Aggregate result on ItemReferenceTypeNo */
  ItemReferenceTypeNo = 'ItemReferenceTypeNo',
  /** Aggregate result on RequestedReceiptDate */
  RequestedReceiptDate = 'RequestedReceiptDate',
  /** Aggregate result on PromisedReceiptDate */
  PromisedReceiptDate = 'PromisedReceiptDate',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on PlannedReceiptDate */
  PlannedReceiptDate = 'PlannedReceiptDate',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on ItemChargeBaseAmount */
  ItemChargeBaseAmount = 'ItemChargeBaseAmount',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on DocumentId */
  DocumentId = 'DocumentId',
  /** Aggregate result on OverReceiptQuantity */
  OverReceiptQuantity = 'OverReceiptQuantity',
  /** Aggregate result on OverReceiptCode */
  OverReceiptCode = 'OverReceiptCode',
  /** Aggregate result on OverReceiptCode2 */
  OverReceiptCode2 = 'OverReceiptCode2',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on PEBChargeType */
  PebChargeType = 'PEBChargeType',
  /** Aggregate result on PEBChargeTypeOPTION */
  PebChargeTypeOption = 'PEBChargeTypeOPTION',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMNo */
  EqmNo = 'EQMNo',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMObjectBinCode */
  EqmObjectBinCode = 'EQMObjectBinCode',
  /** Aggregate result on EQMManufacturerCode */
  EqmManufacturerCode = 'EQMManufacturerCode',
  /** Aggregate result on EQMManufacturerModelCode */
  EqmManufacturerModelCode = 'EQMManufacturerModelCode',
  /** Aggregate result on RoutingNo */
  RoutingNo = 'RoutingNo',
  /** Aggregate result on OperationNo */
  OperationNo = 'OperationNo',
  /** Aggregate result on WorkCenterNo */
  WorkCenterNo = 'WorkCenterNo',
  /** Aggregate result on ProdOrderLineNo */
  ProdOrderLineNo = 'ProdOrderLineNo',
  /** Aggregate result on OverheadRate */
  OverheadRate = 'OverheadRate',
  /** Aggregate result on RoutingReferenceNo */
  RoutingReferenceNo = 'RoutingReferenceNo',
  /** Aggregate result on ExternalLineNo */
  ExternalLineNo = 'ExternalLineNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchaseHeaderInput {
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on PaytoName */
  PaytoName = 'PaytoName',
  /** Aggregate result on PaytoName2 */
  PaytoName2 = 'PaytoName2',
  /** Aggregate result on PaytoAddress */
  PaytoAddress = 'PaytoAddress',
  /** Aggregate result on PaytoAddress2 */
  PaytoAddress2 = 'PaytoAddress2',
  /** Aggregate result on PaytoCity */
  PaytoCity = 'PaytoCity',
  /** Aggregate result on PaytoContact */
  PaytoContact = 'PaytoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on VendorPostingGroup */
  VendorPostingGroup = 'VendorPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on PurchaserCode */
  PurchaserCode = 'PurchaserCode',
  /** Aggregate result on OrderClass */
  OrderClass = 'OrderClass',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on Receive */
  Receive = 'Receive',
  /** Aggregate result on Invoice */
  Invoice = 'Invoice',
  /** Aggregate result on PrintPostedDocuments */
  PrintPostedDocuments = 'PrintPostedDocuments',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on ReceivingNo */
  ReceivingNo = 'ReceivingNo',
  /** Aggregate result on PostingNo */
  PostingNo = 'PostingNo',
  /** Aggregate result on LastReceivingNo */
  LastReceivingNo = 'LastReceivingNo',
  /** Aggregate result on LastPostingNo */
  LastPostingNo = 'LastPostingNo',
  /** Aggregate result on VendorOrderNo */
  VendorOrderNo = 'VendorOrderNo',
  /** Aggregate result on VendorShipmentNo */
  VendorShipmentNo = 'VendorShipmentNo',
  /** Aggregate result on VendorInvoiceNo */
  VendorInvoiceNo = 'VendorInvoiceNo',
  /** Aggregate result on VendorCrMemoNo */
  VendorCrMemoNo = 'VendorCrMemoNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on BuyfromVendorName */
  BuyfromVendorName = 'BuyfromVendorName',
  /** Aggregate result on BuyfromVendorName2 */
  BuyfromVendorName2 = 'BuyfromVendorName2',
  /** Aggregate result on BuyfromAddress */
  BuyfromAddress = 'BuyfromAddress',
  /** Aggregate result on BuyfromAddress2 */
  BuyfromAddress2 = 'BuyfromAddress2',
  /** Aggregate result on BuyfromCity */
  BuyfromCity = 'BuyfromCity',
  /** Aggregate result on BuyfromContact */
  BuyfromContact = 'BuyfromContact',
  /** Aggregate result on PaytoPostCode */
  PaytoPostCode = 'PaytoPostCode',
  /** Aggregate result on PaytoCounty */
  PaytoCounty = 'PaytoCounty',
  /** Aggregate result on PaytoCountryRegionCode */
  PaytoCountryRegionCode = 'PaytoCountryRegionCode',
  /** Aggregate result on BuyfromPostCode */
  BuyfromPostCode = 'BuyfromPostCode',
  /** Aggregate result on BuyfromCounty */
  BuyfromCounty = 'BuyfromCounty',
  /** Aggregate result on BuyfromCountryRegionCode */
  BuyfromCountryRegionCode = 'BuyfromCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on OrderAddressCode */
  OrderAddressCode = 'OrderAddressCode',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on PostingNoSeries */
  PostingNoSeries = 'PostingNoSeries',
  /** Aggregate result on ReceivingNoSeries */
  ReceivingNoSeries = 'ReceivingNoSeries',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on AppliestoID */
  AppliestoId = 'AppliestoID',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on InvoiceDiscountCalculation */
  InvoiceDiscountCalculation = 'InvoiceDiscountCalculation',
  /** Aggregate result on InvoiceDiscountCalculationOPTION */
  InvoiceDiscountCalculationOption = 'InvoiceDiscountCalculationOPTION',
  /** Aggregate result on InvoiceDiscountValue */
  InvoiceDiscountValue = 'InvoiceDiscountValue',
  /** Aggregate result on SendICDocument */
  SendIcDocument = 'SendICDocument',
  /** Aggregate result on ICStatus */
  IcStatus = 'ICStatus',
  /** Aggregate result on ICStatusOPTION */
  IcStatusOption = 'ICStatusOPTION',
  /** Aggregate result on BuyfromICPartnerCode */
  BuyfromIcPartnerCode = 'BuyfromICPartnerCode',
  /** Aggregate result on PaytoICPartnerCode */
  PaytoIcPartnerCode = 'PaytoICPartnerCode',
  /** Aggregate result on ICDirection */
  IcDirection = 'ICDirection',
  /** Aggregate result on ICDirectionOPTION */
  IcDirectionOption = 'ICDirectionOPTION',
  /** Aggregate result on PrepaymentNo */
  PrepaymentNo = 'PrepaymentNo',
  /** Aggregate result on LastPrepaymentNo */
  LastPrepaymentNo = 'LastPrepaymentNo',
  /** Aggregate result on PrepmtCrMemoNo */
  PrepmtCrMemoNo = 'PrepmtCrMemoNo',
  /** Aggregate result on LastPrepmtCrMemoNo */
  LastPrepmtCrMemoNo = 'LastPrepmtCrMemoNo',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on CompressPrepayment */
  CompressPrepayment = 'CompressPrepayment',
  /** Aggregate result on PrepaymentDueDate */
  PrepaymentDueDate = 'PrepaymentDueDate',
  /** Aggregate result on PrepmtCrMemoNoSeries */
  PrepmtCrMemoNoSeries = 'PrepmtCrMemoNoSeries',
  /** Aggregate result on PrepmtPostingDescription */
  PrepmtPostingDescription = 'PrepmtPostingDescription',
  /** Aggregate result on PrepmtPmtDiscountDate */
  PrepmtPmtDiscountDate = 'PrepmtPmtDiscountDate',
  /** Aggregate result on PrepmtPaymentTermsCode */
  PrepmtPaymentTermsCode = 'PrepmtPaymentTermsCode',
  /** Aggregate result on PrepmtPaymentDiscount */
  PrepmtPaymentDiscount = 'PrepmtPaymentDiscount',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on JobQueueStatus */
  JobQueueStatus = 'JobQueueStatus',
  /** Aggregate result on JobQueueStatusOPTION */
  JobQueueStatusOption = 'JobQueueStatusOPTION',
  /** Aggregate result on JobQueueEntryID */
  JobQueueEntryId = 'JobQueueEntryID',
  /** Aggregate result on IncomingDocumentEntryNo */
  IncomingDocumentEntryNo = 'IncomingDocumentEntryNo',
  /** Aggregate result on CreditorNo */
  CreditorNo = 'CreditorNo',
  /** Aggregate result on PaymentReference */
  PaymentReference = 'PaymentReference',
  /** Aggregate result on JournalTemplName */
  JournalTemplName = 'JournalTemplName',
  /** Aggregate result on ARcdNotInvExVATLCY */
  ARcdNotInvExVatlcy = 'ARcdNotInvExVATLCY',
  /** Aggregate result on AmtRcdNotInvoicedLCY */
  AmtRcdNotInvoicedLcy = 'AmtRcdNotInvoicedLCY',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on NoofArchivedVersions */
  NoofArchivedVersions = 'NoofArchivedVersions',
  /** Aggregate result on DocNoOccurrence */
  DocNoOccurrence = 'DocNoOccurrence',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on BuyfromContactNo */
  BuyfromContactNo = 'BuyfromContactNo',
  /** Aggregate result on PaytoContactNo */
  PaytoContactNo = 'PaytoContactNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on PartiallyInvoiced */
  PartiallyInvoiced = 'PartiallyInvoiced',
  /** Aggregate result on CompletelyReceived */
  CompletelyReceived = 'CompletelyReceived',
  /** Aggregate result on PostingfromWhseRef */
  PostingfromWhseRef = 'PostingfromWhseRef',
  /** Aggregate result on RequestedReceiptDate */
  RequestedReceiptDate = 'RequestedReceiptDate',
  /** Aggregate result on PromisedReceiptDate */
  PromisedReceiptDate = 'PromisedReceiptDate',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on VendorAuthorizationNo */
  VendorAuthorizationNo = 'VendorAuthorizationNo',
  /** Aggregate result on ReturnShipmentNo */
  ReturnShipmentNo = 'ReturnShipmentNo',
  /** Aggregate result on ReturnShipmentNoSeries */
  ReturnShipmentNoSeries = 'ReturnShipmentNoSeries',
  /** Aggregate result on Ship */
  Ship = 'Ship',
  /** Aggregate result on LastReturnShipmentNo */
  LastReturnShipmentNo = 'LastReturnShipmentNo',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on PendingApprovals */
  PendingApprovals = 'PendingApprovals',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on PEBGrossInvoiceAmount */
  PebGrossInvoiceAmount = 'PEBGrossInvoiceAmount',
  /** Aggregate result on PEBNoteofGoods */
  PebNoteofGoods = 'PEBNoteofGoods',
  /** Aggregate result on PEBInwardRegistrated */
  PebInwardRegistrated = 'PEBInwardRegistrated',
  /** Aggregate result on PEBOCRNo */
  PebocrNo = 'PEBOCRNo',
  /** Aggregate result on PEBRecipientBankAccount */
  PebRecipientBankAccount = 'PEBRecipientBankAccount',
  /** Aggregate result on PEBOfwhichVATAmount */
  PebOfwhichVatAmount = 'PEBOfwhichVATAmount',
  /** Aggregate result on PEBInwardVAT */
  PebInwardVat = 'PEBInwardVAT',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMContractType */
  EqmContractType = 'EQMContractType',
  /** Aggregate result on EQMContractTypeOPTION */
  EqmContractTypeOption = 'EQMContractTypeOPTION',
  /** Aggregate result on EQMObjectPurchase */
  EqmObjectPurchase = 'EQMObjectPurchase',
  /** Aggregate result on EQMWorkshopDocument */
  EqmWorkshopDocument = 'EQMWorkshopDocument',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceTermNo */
  EqmServiceTermNo = 'EQMServiceTermNo',
  /** Aggregate result on EQMWSObjectCounter */
  EqmwsObjectCounter = 'EQMWSObjectCounter',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMServiceStartDate */
  EqmServiceStartDate = 'EQMServiceStartDate',
  /** Aggregate result on EQMServiceEndDate */
  EqmServiceEndDate = 'EQMServiceEndDate',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMServiceStatusOPTION */
  EqmServiceStatusOption = 'EQMServiceStatusOPTION',
  /** Aggregate result on EQMObjectWarrantyDate */
  EqmObjectWarrantyDate = 'EQMObjectWarrantyDate',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMWSEstimatedStartDate */
  EqmwsEstimatedStartDate = 'EQMWSEstimatedStartDate',
  /** Aggregate result on EQMWSEstimatedStartTime */
  EqmwsEstimatedStartTime = 'EQMWSEstimatedStartTime',
  /** Aggregate result on EQMWSEstimatedEndDate */
  EqmwsEstimatedEndDate = 'EQMWSEstimatedEndDate',
  /** Aggregate result on EQMWSEstimatedEndTime */
  EqmwsEstimatedEndTime = 'EQMWSEstimatedEndTime',
  /** Aggregate result on EQMServiceStartTime */
  EqmServiceStartTime = 'EQMServiceStartTime',
  /** Aggregate result on EQMServiceEndTime */
  EqmServiceEndTime = 'EQMServiceEndTime',
  /** Aggregate result on EQMObjWorkPurchFlow */
  EqmObjWorkPurchFlow = 'EQMObjWorkPurchFlow',
  /** Aggregate result on EQMRentalShipmentNo */
  EqmRentalShipmentNo = 'EQMRentalShipmentNo',
  /** Aggregate result on EQMRentalCollectionNo */
  EqmRentalCollectionNo = 'EQMRentalCollectionNo',
  /** Aggregate result on EQMWSObjectCounter2 */
  EqmwsObjectCounter2 = 'EQMWSObjectCounter2',
  /** Aggregate result on EQMPostedRentalShipmentNo */
  EqmPostedRentalShipmentNo = 'EQMPostedRentalShipmentNo',
  /** Aggregate result on EQMPostedRentalCollNo */
  EqmPostedRentalCollNo = 'EQMPostedRentalCollNo',
  /** Aggregate result on EQMControlTypeCode */
  EqmControlTypeCode = 'EQMControlTypeCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchaseLineInput {
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on BuyfromVendorNo */
  BuyfromVendorNo = 'BuyfromVendorNo',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ExpectedReceiptDate */
  ExpectedReceiptDate = 'ExpectedReceiptDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on OutstandingQuantity */
  OutstandingQuantity = 'OutstandingQuantity',
  /** Aggregate result on QtytoInvoice */
  QtytoInvoice = 'QtytoInvoice',
  /** Aggregate result on QtytoReceive */
  QtytoReceive = 'QtytoReceive',
  /** Aggregate result on DirectUnitCost */
  DirectUnitCost = 'DirectUnitCost',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on UnitPriceLCY */
  UnitPriceLcy = 'UnitPriceLCY',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on IndirectCost */
  IndirectCost = 'IndirectCost',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on OutstandingAmount */
  OutstandingAmount = 'OutstandingAmount',
  /** Aggregate result on QtyRcdNotInvoiced */
  QtyRcdNotInvoiced = 'QtyRcdNotInvoiced',
  /** Aggregate result on AmtRcdNotInvoiced */
  AmtRcdNotInvoiced = 'AmtRcdNotInvoiced',
  /** Aggregate result on QuantityReceived */
  QuantityReceived = 'QuantityReceived',
  /** Aggregate result on QuantityInvoiced */
  QuantityInvoiced = 'QuantityInvoiced',
  /** Aggregate result on ReceiptNo */
  ReceiptNo = 'ReceiptNo',
  /** Aggregate result on ReceiptLineNo */
  ReceiptLineNo = 'ReceiptLineNo',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on InvDiscountAmount */
  InvDiscountAmount = 'InvDiscountAmount',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on SalesOrderNo */
  SalesOrderNo = 'SalesOrderNo',
  /** Aggregate result on SalesOrderLineNo */
  SalesOrderLineNo = 'SalesOrderLineNo',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on EntryPoint */
  EntryPoint = 'EntryPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on UseTax */
  UseTax = 'UseTax',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on OutstandingAmountLCY */
  OutstandingAmountLcy = 'OutstandingAmountLCY',
  /** Aggregate result on AmtRcdNotInvoicedLCY */
  AmtRcdNotInvoicedLcy = 'AmtRcdNotInvoicedLCY',
  /** Aggregate result on ReservedQuantity */
  ReservedQuantity = 'ReservedQuantity',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on InvDiscAmounttoInvoice */
  InvDiscAmounttoInvoice = 'InvDiscAmounttoInvoice',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepmtLineAmount */
  PrepmtLineAmount = 'PrepmtLineAmount',
  /** Aggregate result on PrepmtAmtInv */
  PrepmtAmtInv = 'PrepmtAmtInv',
  /** Aggregate result on PrepmtAmtInclVAT */
  PrepmtAmtInclVat = 'PrepmtAmtInclVAT',
  /** Aggregate result on PrepaymentAmount */
  PrepaymentAmount = 'PrepaymentAmount',
  /** Aggregate result on PrepmtVATBaseAmt */
  PrepmtVatBaseAmt = 'PrepmtVATBaseAmt',
  /** Aggregate result on PrepaymentVAT */
  PrepaymentVat = 'PrepaymentVAT',
  /** Aggregate result on PrepmtVATCalcType */
  PrepmtVatCalcType = 'PrepmtVATCalcType',
  /** Aggregate result on PrepmtVATCalcTypeOPTION */
  PrepmtVatCalcTypeOption = 'PrepmtVATCalcTypeOPTION',
  /** Aggregate result on PrepaymentVATIdentifier */
  PrepaymentVatIdentifier = 'PrepaymentVATIdentifier',
  /** Aggregate result on PrepaymentTaxAreaCode */
  PrepaymentTaxAreaCode = 'PrepaymentTaxAreaCode',
  /** Aggregate result on PrepaymentTaxLiable */
  PrepaymentTaxLiable = 'PrepaymentTaxLiable',
  /** Aggregate result on PrepaymentTaxGroupCode */
  PrepaymentTaxGroupCode = 'PrepaymentTaxGroupCode',
  /** Aggregate result on PrepmtAmttoDeduct */
  PrepmtAmttoDeduct = 'PrepmtAmttoDeduct',
  /** Aggregate result on PrepmtAmtDeducted */
  PrepmtAmtDeducted = 'PrepmtAmtDeducted',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on PrepmtAmountInvInclVAT */
  PrepmtAmountInvInclVat = 'PrepmtAmountInvInclVAT',
  /** Aggregate result on PrepmtAmountInvLCY */
  PrepmtAmountInvLcy = 'PrepmtAmountInvLCY',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PrepmtVATAmountInvLCY */
  PrepmtVatAmountInvLcy = 'PrepmtVATAmountInvLCY',
  /** Aggregate result on PrepaymentVATDifference */
  PrepaymentVatDifference = 'PrepaymentVATDifference',
  /** Aggregate result on PrepmtVATDifftoDeduct */
  PrepmtVatDifftoDeduct = 'PrepmtVATDifftoDeduct',
  /** Aggregate result on PrepmtVATDiffDeducted */
  PrepmtVatDiffDeducted = 'PrepmtVATDiffDeducted',
  /** Aggregate result on ICItemReferenceNo */
  IcItemReferenceNo = 'ICItemReferenceNo',
  /** Aggregate result on OutstandingAmtExVATLCY */
  OutstandingAmtExVatlcy = 'OutstandingAmtExVATLCY',
  /** Aggregate result on ARcdNotInvExVATLCY */
  ARcdNotInvExVatlcy = 'ARcdNotInvExVATLCY',
  /** Aggregate result on PmtDiscountAmount */
  PmtDiscountAmount = 'PmtDiscountAmount',
  /** Aggregate result on PrepmtPmtDiscountAmount */
  PrepmtPmtDiscountAmount = 'PrepmtPmtDiscountAmount',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobLineType */
  JobLineType = 'JobLineType',
  /** Aggregate result on JobLineTypeOPTION */
  JobLineTypeOption = 'JobLineTypeOPTION',
  /** Aggregate result on JobUnitPrice */
  JobUnitPrice = 'JobUnitPrice',
  /** Aggregate result on JobTotalPrice */
  JobTotalPrice = 'JobTotalPrice',
  /** Aggregate result on JobLineAmount */
  JobLineAmount = 'JobLineAmount',
  /** Aggregate result on JobLineDiscountAmount */
  JobLineDiscountAmount = 'JobLineDiscountAmount',
  /** Aggregate result on JobLineDiscount */
  JobLineDiscount = 'JobLineDiscount',
  /** Aggregate result on JobUnitPriceLCY */
  JobUnitPriceLcy = 'JobUnitPriceLCY',
  /** Aggregate result on JobTotalPriceLCY */
  JobTotalPriceLcy = 'JobTotalPriceLCY',
  /** Aggregate result on JobLineAmountLCY */
  JobLineAmountLcy = 'JobLineAmountLCY',
  /** Aggregate result on JobLineDiscAmountLCY */
  JobLineDiscAmountLcy = 'JobLineDiscAmountLCY',
  /** Aggregate result on JobCurrencyFactor */
  JobCurrencyFactor = 'JobCurrencyFactor',
  /** Aggregate result on JobCurrencyCode */
  JobCurrencyCode = 'JobCurrencyCode',
  /** Aggregate result on JobPlanningLineNo */
  JobPlanningLineNo = 'JobPlanningLineNo',
  /** Aggregate result on JobRemainingQty */
  JobRemainingQty = 'JobRemainingQty',
  /** Aggregate result on JobRemainingQtyBase */
  JobRemainingQtyBase = 'JobRemainingQtyBase',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on ReturnsDeferralStartDate */
  ReturnsDeferralStartDate = 'ReturnsDeferralStartDate',
  /** Aggregate result on ProdOrderNo */
  ProdOrderNo = 'ProdOrderNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on QtyRoundingPrecision */
  QtyRoundingPrecision = 'QtyRoundingPrecision',
  /** Aggregate result on QtyRoundingPrecisionBase */
  QtyRoundingPrecisionBase = 'QtyRoundingPrecisionBase',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on OutstandingQtyBase */
  OutstandingQtyBase = 'OutstandingQtyBase',
  /** Aggregate result on QtytoInvoiceBase */
  QtytoInvoiceBase = 'QtytoInvoiceBase',
  /** Aggregate result on QtytoReceiveBase */
  QtytoReceiveBase = 'QtytoReceiveBase',
  /** Aggregate result on QtyRcdNotInvoicedBase */
  QtyRcdNotInvoicedBase = 'QtyRcdNotInvoicedBase',
  /** Aggregate result on QtyReceivedBase */
  QtyReceivedBase = 'QtyReceivedBase',
  /** Aggregate result on QtyInvoicedBase */
  QtyInvoicedBase = 'QtyInvoicedBase',
  /** Aggregate result on ReservedQtyBase */
  ReservedQtyBase = 'ReservedQtyBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on FAPostingType */
  FaPostingType = 'FAPostingType',
  /** Aggregate result on FAPostingTypeOPTION */
  FaPostingTypeOption = 'FAPostingTypeOPTION',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on SalvageValue */
  SalvageValue = 'SalvageValue',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DeprAcquisitionCost */
  DeprAcquisitionCost = 'DeprAcquisitionCost',
  /** Aggregate result on MaintenanceCode */
  MaintenanceCode = 'MaintenanceCode',
  /** Aggregate result on InsuranceNo */
  InsuranceNo = 'InsuranceNo',
  /** Aggregate result on BudgetedFANo */
  BudgetedFaNo = 'BudgetedFANo',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on SpecialOrder */
  SpecialOrder = 'SpecialOrder',
  /** Aggregate result on SpecialOrderSalesNo */
  SpecialOrderSalesNo = 'SpecialOrderSalesNo',
  /** Aggregate result on SpecialOrderSalesLineNo */
  SpecialOrderSalesLineNo = 'SpecialOrderSalesLineNo',
  /** Aggregate result on ItemReferenceNo */
  ItemReferenceNo = 'ItemReferenceNo',
  /** Aggregate result on ItemReferenceUnitofMeasure */
  ItemReferenceUnitofMeasure = 'ItemReferenceUnitofMeasure',
  /** Aggregate result on ItemReferenceType */
  ItemReferenceType = 'ItemReferenceType',
  /** Aggregate result on ItemReferenceTypeOPTION */
  ItemReferenceTypeOption = 'ItemReferenceTypeOPTION',
  /** Aggregate result on ItemReferenceTypeNo */
  ItemReferenceTypeNo = 'ItemReferenceTypeNo',
  /** Aggregate result on WhseOutstandingQtyBase */
  WhseOutstandingQtyBase = 'WhseOutstandingQtyBase',
  /** Aggregate result on CompletelyReceived */
  CompletelyReceived = 'CompletelyReceived',
  /** Aggregate result on RequestedReceiptDate */
  RequestedReceiptDate = 'RequestedReceiptDate',
  /** Aggregate result on PromisedReceiptDate */
  PromisedReceiptDate = 'PromisedReceiptDate',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on InboundWhseHandlingTime */
  InboundWhseHandlingTime = 'InboundWhseHandlingTime',
  /** Aggregate result on PlannedReceiptDate */
  PlannedReceiptDate = 'PlannedReceiptDate',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on AllowItemChargeAssignment */
  AllowItemChargeAssignment = 'AllowItemChargeAssignment',
  /** Aggregate result on QtytoAssign */
  QtytoAssign = 'QtytoAssign',
  /** Aggregate result on QtyAssigned */
  QtyAssigned = 'QtyAssigned',
  /** Aggregate result on ReturnQtytoShip */
  ReturnQtytoShip = 'ReturnQtytoShip',
  /** Aggregate result on ReturnQtytoShipBase */
  ReturnQtytoShipBase = 'ReturnQtytoShipBase',
  /** Aggregate result on ReturnQtyShippedNotInvd */
  ReturnQtyShippedNotInvd = 'ReturnQtyShippedNotInvd',
  /** Aggregate result on RetQtyShpdNotInvdBase */
  RetQtyShpdNotInvdBase = 'RetQtyShpdNotInvdBase',
  /** Aggregate result on ReturnShpdNotInvd */
  ReturnShpdNotInvd = 'ReturnShpdNotInvd',
  /** Aggregate result on ReturnShpdNotInvdLCY */
  ReturnShpdNotInvdLcy = 'ReturnShpdNotInvdLCY',
  /** Aggregate result on ReturnQtyShipped */
  ReturnQtyShipped = 'ReturnQtyShipped',
  /** Aggregate result on ReturnQtyShippedBase */
  ReturnQtyShippedBase = 'ReturnQtyShippedBase',
  /** Aggregate result on ReturnShipmentNo */
  ReturnShipmentNo = 'ReturnShipmentNo',
  /** Aggregate result on ReturnShipmentLineNo */
  ReturnShipmentLineNo = 'ReturnShipmentLineNo',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on Subtype */
  Subtype = 'Subtype',
  /** Aggregate result on SubtypeOPTION */
  SubtypeOption = 'SubtypeOPTION',
  /** Aggregate result on CopiedFromPostedDoc */
  CopiedFromPostedDoc = 'CopiedFromPostedDoc',
  /** Aggregate result on PriceCalculationMethod */
  PriceCalculationMethod = 'PriceCalculationMethod',
  /** Aggregate result on PriceCalculationMethodOPTION */
  PriceCalculationMethodOption = 'PriceCalculationMethodOPTION',
  /** Aggregate result on AttachedDocCount */
  AttachedDocCount = 'AttachedDocCount',
  /** Aggregate result on OverReceiptQuantity */
  OverReceiptQuantity = 'OverReceiptQuantity',
  /** Aggregate result on OverReceiptCode */
  OverReceiptCode = 'OverReceiptCode',
  /** Aggregate result on OverReceiptApprovalStatus */
  OverReceiptApprovalStatus = 'OverReceiptApprovalStatus',
  /** Aggregate result on OverReceiptApprovalStatusOPTION */
  OverReceiptApprovalStatusOption = 'OverReceiptApprovalStatusOPTION',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on NBAPreAssignedObjectNo */
  NbaPreAssignedObjectNo = 'NBAPreAssignedObjectNo',
  /** Aggregate result on PEBChargeType */
  PebChargeType = 'PEBChargeType',
  /** Aggregate result on PEBChargeTypeOPTION */
  PebChargeTypeOption = 'PEBChargeTypeOPTION',
  /** Aggregate result on PEBIsDescription2 */
  PebIsDescription2 = 'PEBIsDescription2',
  /** Aggregate result on PEBNotincludedincalc */
  PebNotincludedincalc = 'PEBNotincludedincalc',
  /** Aggregate result on PEBNotincludedinVATcalc */
  PebNotincludedinVaTcalc = 'PEBNotincludedinVATcalc',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMContractType */
  EqmContractType = 'EQMContractType',
  /** Aggregate result on EQMContractTypeOPTION */
  EqmContractTypeOption = 'EQMContractTypeOPTION',
  /** Aggregate result on EQMType */
  EqmType = 'EQMType',
  /** Aggregate result on EQMTypeOPTION */
  EqmTypeOption = 'EQMTypeOPTION',
  /** Aggregate result on EQMNo */
  EqmNo = 'EQMNo',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMObjectCostAssignment */
  EqmObjectCostAssignment = 'EQMObjectCostAssignment',
  /** Aggregate result on EQMFixedAssetSetupGroup */
  EqmFixedAssetSetupGroup = 'EQMFixedAssetSetupGroup',
  /** Aggregate result on EQMFixedAssetNo */
  EqmFixedAssetNo = 'EQMFixedAssetNo',
  /** Aggregate result on EQMORIGPurchLineNo */
  EqmorigPurchLineNo = 'EQMORIGPurchLineNo',
  /** Aggregate result on EQMWorkshop */
  EqmWorkshop = 'EQMWorkshop',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMReRentNo */
  EqmReRentNo = 'EQMReRentNo',
  /** Aggregate result on EQMSerialNo */
  EqmSerialNo = 'EQMSerialNo',
  /** Aggregate result on EQMObjectBinCode */
  EqmObjectBinCode = 'EQMObjectBinCode',
  /** Aggregate result on EQMManufacturerCode */
  EqmManufacturerCode = 'EQMManufacturerCode',
  /** Aggregate result on EQMManufacturerModelCode */
  EqmManufacturerModelCode = 'EQMManufacturerModelCode',
  /** Aggregate result on EQMAllocateManufacturerCode */
  EqmAllocateManufacturerCode = 'EQMAllocateManufacturerCode',
  /** Aggregate result on EQMAllocateManuModelCode */
  EqmAllocateManuModelCode = 'EQMAllocateManuModelCode',
  /** Aggregate result on EQMItemtoObjectFlow */
  EqmItemtoObjectFlow = 'EQMItemtoObjectFlow',
  /** Aggregate result on RoutingNo */
  RoutingNo = 'RoutingNo',
  /** Aggregate result on OperationNo */
  OperationNo = 'OperationNo',
  /** Aggregate result on WorkCenterNo */
  WorkCenterNo = 'WorkCenterNo',
  /** Aggregate result on Finished */
  Finished = 'Finished',
  /** Aggregate result on ProdOrderLineNo */
  ProdOrderLineNo = 'ProdOrderLineNo',
  /** Aggregate result on OverheadRate */
  OverheadRate = 'OverheadRate',
  /** Aggregate result on MPSOrder */
  MpsOrder = 'MPSOrder',
  /** Aggregate result on PlanningFlexibility */
  PlanningFlexibility = 'PlanningFlexibility',
  /** Aggregate result on PlanningFlexibilityOPTION */
  PlanningFlexibilityOption = 'PlanningFlexibilityOPTION',
  /** Aggregate result on SafetyLeadTime */
  SafetyLeadTime = 'SafetyLeadTime',
  /** Aggregate result on RoutingReferenceNo */
  RoutingReferenceNo = 'RoutingReferenceNo',
  /** Aggregate result on ExternalLineNo */
  ExternalLineNo = 'ExternalLineNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregatePurchasingInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on SpecialOrder */
  SpecialOrder = 'SpecialOrder',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateQuoteHeaderInput {
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on Version */
  Version = 'Version',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on StartDate */
  StartDate = 'StartDate',
  /** Aggregate result on EndDate */
  EndDate = 'EndDate',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on SalesPerson */
  SalesPerson = 'SalesPerson',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on ExternalText */
  ExternalText = 'ExternalText',
  /** Aggregate result on InternalText */
  InternalText = 'InternalText',
  /** Aggregate result on ProjectSum */
  ProjectSum = 'ProjectSum',
  /** Aggregate result on LicenseSum */
  LicenseSum = 'LicenseSum',
  /** Aggregate result on LicenseFee */
  LicenseFee = 'LicenseFee',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateQuoteLineInput {
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on Version */
  Version = 'Version',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on Task */
  Task = 'Task',
  /** Aggregate result on TaskGrouping */
  TaskGrouping = 'TaskGrouping',
  /** Aggregate result on Days */
  Days = 'Days',
  /** Aggregate result on Sum */
  Sum = 'Sum',
  /** Aggregate result on InvoicedSum */
  InvoicedSum = 'InvoicedSum',
  /** Aggregate result on OnOrderSum */
  OnOrderSum = 'OnOrderSum',
  /** Aggregate result on LinkedTo */
  LinkedTo = 'LinkedTo',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateQuoteTaskGroupInput {
  /** Aggregate result on Group */
  Group = 'Group',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalHeaderInput {
  /** Aggregate result on ContractType */
  ContractType = 'ContractType',
  /** Aggregate result on ContractTypeOPTION */
  ContractTypeOption = 'ContractTypeOPTION',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ChangeStatus */
  ChangeStatus = 'ChangeStatus',
  /** Aggregate result on ChangeStatusOPTION */
  ChangeStatusOption = 'ChangeStatusOPTION',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on ContactName */
  ContactName = 'ContactName',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on BreakInvoiceatExpDate */
  BreakInvoiceatExpDate = 'BreakInvoiceatExpDate',
  /** Aggregate result on Printed */
  Printed = 'Printed',
  /** Aggregate result on PrintedTime */
  PrintedTime = 'PrintedTime',
  /** Aggregate result on InvoiceFrequencyNOTINUSE */
  InvoiceFrequencyNotinuse = 'InvoiceFrequencyNOTINUSE',
  /** Aggregate result on InvoiceFrequencyNOTINUSEOPTION */
  InvoiceFrequencyNotinuseoption = 'InvoiceFrequencyNOTINUSEOPTION',
  /** Aggregate result on LastInvoiceDate */
  LastInvoiceDate = 'LastInvoiceDate',
  /** Aggregate result on NextInvoiceDate */
  NextInvoiceDate = 'NextInvoiceDate',
  /** Aggregate result on ContractDate */
  ContractDate = 'ContractDate',
  /** Aggregate result on StartRentalPeriodDate */
  StartRentalPeriodDate = 'StartRentalPeriodDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on TermofContract */
  TermofContract = 'TermofContract',
  /** Aggregate result on AnnualAmount */
  AnnualAmount = 'AnnualAmount',
  /** Aggregate result on AmountperPeriod */
  AmountperPeriod = 'AmountperPeriod',
  /** Aggregate result on CombineInvoices */
  CombineInvoices = 'CombineInvoices',
  /** Aggregate result on Prepaid */
  Prepaid = 'Prepaid',
  /** Aggregate result on NextInvoicePeriod */
  NextInvoicePeriod = 'NextInvoicePeriod',
  /** Aggregate result on RentalZoneCode */
  RentalZoneCode = 'RentalZoneCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on CombineMethod */
  CombineMethod = 'CombineMethod',
  /** Aggregate result on CombineMethodOPTION */
  CombineMethodOption = 'CombineMethodOPTION',
  /** Aggregate result on CancelReasonCode */
  CancelReasonCode = 'CancelReasonCode',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on QuoteType */
  QuoteType = 'QuoteType',
  /** Aggregate result on QuoteTypeOPTION */
  QuoteTypeOption = 'QuoteTypeOPTION',
  /** Aggregate result on ContractGroupCode */
  ContractGroupCode = 'ContractGroupCode',
  /** Aggregate result on RentalOrderType */
  RentalOrderType = 'RentalOrderType',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on TemplateNo */
  TemplateNo = 'TemplateNo',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on CustomerRentalPriceGroup */
  CustomerRentalPriceGroup = 'CustomerRentalPriceGroup',
  /** Aggregate result on CustomerSalesPriceGroup */
  CustomerSalesPriceGroup = 'CustomerSalesPriceGroup',
  /** Aggregate result on CustomerSalesDiscGroup */
  CustomerSalesDiscGroup = 'CustomerSalesDiscGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on Probability */
  Probability = 'Probability',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on NextInvoicePeriodStart */
  NextInvoicePeriodStart = 'NextInvoicePeriodStart',
  /** Aggregate result on NextInvoicePeriodEnd */
  NextInvoicePeriodEnd = 'NextInvoicePeriodEnd',
  /** Aggregate result on FromQuoteNo */
  FromQuoteNo = 'FromQuoteNo',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ContactNo */
  ContactNo = 'ContactNo',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on TempInvoiceDate */
  TempInvoiceDate = 'TempInvoiceDate',
  /** Aggregate result on TermCode */
  TermCode = 'TermCode',
  /** Aggregate result on Contractterminatedcomplete */
  Contractterminatedcomplete = 'Contractterminatedcomplete',
  /** Aggregate result on NextRentalInvoiceFrom */
  NextRentalInvoiceFrom = 'NextRentalInvoiceFrom',
  /** Aggregate result on NextRentalInvoiceTo */
  NextRentalInvoiceTo = 'NextRentalInvoiceTo',
  /** Aggregate result on TempReservation */
  TempReservation = 'TempReservation',
  /** Aggregate result on TempDelivery */
  TempDelivery = 'TempDelivery',
  /** Aggregate result on TempInvoice */
  TempInvoice = 'TempInvoice',
  /** Aggregate result on TempDocumentType */
  TempDocumentType = 'TempDocumentType',
  /** Aggregate result on TempDocumentTypeOPTION */
  TempDocumentTypeOption = 'TempDocumentTypeOPTION',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on InvoicingStatus */
  InvoicingStatus = 'InvoicingStatus',
  /** Aggregate result on InvoicingStatusOPTION */
  InvoicingStatusOption = 'InvoicingStatusOPTION',
  /** Aggregate result on CustomerProjectDimension */
  CustomerProjectDimension = 'CustomerProjectDimension',
  /** Aggregate result on SalesHeaderNoSale */
  SalesHeaderNoSale = 'SalesHeaderNoSale',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on DoNotShowDialogPrompt */
  DoNotShowDialogPrompt = 'DoNotShowDialogPrompt',
  /** Aggregate result on RentalStartingFee */
  RentalStartingFee = 'RentalStartingFee',
  /** Aggregate result on TempStartRentalPeriod */
  TempStartRentalPeriod = 'TempStartRentalPeriod',
  /** Aggregate result on FrequenceFixDaysNOTINUSE */
  FrequenceFixDaysNotinuse = 'FrequenceFixDaysNOTINUSE',
  /** Aggregate result on CustomerRentalDiscGroup */
  CustomerRentalDiscGroup = 'CustomerRentalDiscGroup',
  /** Aggregate result on InvoiceSequence */
  InvoiceSequence = 'InvoiceSequence',
  /** Aggregate result on SelltoCustomerTemplateCode */
  SelltoCustomerTemplateCode = 'SelltoCustomerTemplateCode',
  /** Aggregate result on SelltoContactNo */
  SelltoContactNo = 'SelltoContactNo',
  /** Aggregate result on BilltoCustomerTemplateCode */
  BilltoCustomerTemplateCode = 'BilltoCustomerTemplateCode',
  /** Aggregate result on ShiptoPhoneNo */
  ShiptoPhoneNo = 'ShiptoPhoneNo',
  /** Aggregate result on ShiptoMobilePhoneNo */
  ShiptoMobilePhoneNo = 'ShiptoMobilePhoneNo',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on LocationManagerContactNo */
  LocationManagerContactNo = 'LocationManagerContactNo',
  /** Aggregate result on ProjectManagerContactNo */
  ProjectManagerContactNo = 'ProjectManagerContactNo',
  /** Aggregate result on DeliveryContactNo */
  DeliveryContactNo = 'DeliveryContactNo',
  /** Aggregate result on FixedPriceContract */
  FixedPriceContract = 'FixedPriceContract',
  /** Aggregate result on UniqueID */
  UniqueId = 'UniqueID',
  /** Aggregate result on CreatedTime */
  CreatedTime = 'CreatedTime',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on CustomerDepositCode */
  CustomerDepositCode = 'CustomerDepositCode',
  /** Aggregate result on CustomerDepositAmttoPay */
  CustomerDepositAmttoPay = 'CustomerDepositAmttoPay',
  /** Aggregate result on GrantedDeposit */
  GrantedDeposit = 'GrantedDeposit',
  /** Aggregate result on RentalInvoiceDayofMonth */
  RentalInvoiceDayofMonth = 'RentalInvoiceDayofMonth',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on MemoText */
  MemoText = 'MemoText',
  /** Aggregate result on JournalEntryNo */
  JournalEntryNo = 'JournalEntryNo',
  /** Aggregate result on UseRentalJnlNos */
  UseRentalJnlNos = 'UseRentalJnlNos',
  /** Aggregate result on CustomerType */
  CustomerType = 'CustomerType',
  /** Aggregate result on CustomerTypeOPTION */
  CustomerTypeOption = 'CustomerTypeOPTION',
  /** Aggregate result on FullyReturned */
  FullyReturned = 'FullyReturned',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on InvoiceDocType */
  InvoiceDocType = 'InvoiceDocType',
  /** Aggregate result on InvoiceDocTypeOPTION */
  InvoiceDocTypeOption = 'InvoiceDocTypeOPTION',
  /** Aggregate result on RentalAmountExclVat */
  RentalAmountExclVat = 'RentalAmountExclVat',
  /** Aggregate result on RentalLineDiscAmount */
  RentalLineDiscAmount = 'RentalLineDiscAmount',
  /** Aggregate result on RentalTotalAmountExclVat */
  RentalTotalAmountExclVat = 'RentalTotalAmountExclVat',
  /** Aggregate result on RentalAmountInclVat */
  RentalAmountInclVat = 'RentalAmountInclVat',
  /** Aggregate result on RentalVatAmount */
  RentalVatAmount = 'RentalVatAmount',
  /** Aggregate result on SalesAmountExclVat */
  SalesAmountExclVat = 'SalesAmountExclVat',
  /** Aggregate result on SalesLineDiscAmount */
  SalesLineDiscAmount = 'SalesLineDiscAmount',
  /** Aggregate result on SalesTotalAmountExclVat */
  SalesTotalAmountExclVat = 'SalesTotalAmountExclVat',
  /** Aggregate result on SalesAmountInclVat */
  SalesAmountInclVat = 'SalesAmountInclVat',
  /** Aggregate result on SalesVatAmount */
  SalesVatAmount = 'SalesVatAmount',
  /** Aggregate result on SalesProfit */
  SalesProfit = 'SalesProfit',
  /** Aggregate result on SalesQtyToInvoice */
  SalesQtyToInvoice = 'SalesQtyToInvoice',
  /** Aggregate result on RentalInsurancAmtExclVat */
  RentalInsurancAmtExclVat = 'RentalInsurancAmtExclVat',
  /** Aggregate result on RentalInsurancAmtInclVat */
  RentalInsurancAmtInclVat = 'RentalInsurancAmtInclVat',
  /** Aggregate result on RentalContractCost */
  RentalContractCost = 'RentalContractCost',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepaymentPeriod */
  PrepaymentPeriod = 'PrepaymentPeriod',
  /** Aggregate result on PrepmtDocNo */
  PrepmtDocNo = 'PrepmtDocNo',
  /** Aggregate result on PrepmtDocType */
  PrepmtDocType = 'PrepmtDocType',
  /** Aggregate result on PrepmtDocTypeOPTION */
  PrepmtDocTypeOption = 'PrepmtDocTypeOPTION',
  /** Aggregate result on CompressPrepayment */
  CompressPrepayment = 'CompressPrepayment',
  /** Aggregate result on UsePrepmtExpirationDate */
  UsePrepmtExpirationDate = 'UsePrepmtExpirationDate',
  /** Aggregate result on ReceivingRespCenter */
  ReceivingRespCenter = 'ReceivingRespCenter',
  /** Aggregate result on ReceivingCostCenter */
  ReceivingCostCenter = 'ReceivingCostCenter',
  /** Aggregate result on QuoteExpiryDate */
  QuoteExpiryDate = 'QuoteExpiryDate',
  /** Aggregate result on QuoteStatus */
  QuoteStatus = 'QuoteStatus',
  /** Aggregate result on QuoteStatusOPTION */
  QuoteStatusOption = 'QuoteStatusOPTION',
  /** Aggregate result on CustomerPONo */
  CustomerPoNo = 'CustomerPONo',
  /** Aggregate result on NextInvoiceDueDate */
  NextInvoiceDueDate = 'NextInvoiceDueDate',
  /** Aggregate result on TypeFilter */
  TypeFilter = 'TypeFilter',
  /** Aggregate result on TypeFilterOPTION */
  TypeFilterOption = 'TypeFilterOPTION',
  /** Aggregate result on ReasonCodeFilter */
  ReasonCodeFilter = 'ReasonCodeFilter',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on ContractLineAmount */
  ContractLineAmount = 'ContractLineAmount',
  /** Aggregate result on NoofPostedInvoices */
  NoofPostedInvoices = 'NoofPostedInvoices',
  /** Aggregate result on NoofUnpostedInvoices */
  NoofUnpostedInvoices = 'NoofUnpostedInvoices',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on ContractInvoiceAmount */
  ContractInvoiceAmount = 'ContractInvoiceAmount',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on NoofPickOrder */
  NoofPickOrder = 'NoofPickOrder',
  /** Aggregate result on NoofShipmentOrder */
  NoofShipmentOrder = 'NoofShipmentOrder',
  /** Aggregate result on NoofCollectionOrder */
  NoofCollectionOrder = 'NoofCollectionOrder',
  /** Aggregate result on NoofWorkOrder */
  NoofWorkOrder = 'NoofWorkOrder',
  /** Aggregate result on LocationManagerName */
  LocationManagerName = 'LocationManagerName',
  /** Aggregate result on ProjectManagerName */
  ProjectManagerName = 'ProjectManagerName',
  /** Aggregate result on DeliveryContactName */
  DeliveryContactName = 'DeliveryContactName',
  /** Aggregate result on CustomerDepositAmount */
  CustomerDepositAmount = 'CustomerDepositAmount',
  /** Aggregate result on CustomerDepositOutstAmt */
  CustomerDepositOutstAmt = 'CustomerDepositOutstAmt',
  /** Aggregate result on RentalValue */
  RentalValue = 'RentalValue',
  /** Aggregate result on CPNextInvoiceAmount */
  CpNextInvoiceAmount = 'CPNextInvoiceAmount',
  /** Aggregate result on CustOrganizationNo */
  CustOrganizationNo = 'CustOrganizationNo',
  /** Aggregate result on TransactionNo */
  TransactionNo = 'TransactionNo',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on PrepmtPaidAmount */
  PrepmtPaidAmount = 'PrepmtPaidAmount',
  /** Aggregate result on PrepmtUnpaidAmount */
  PrepmtUnpaidAmount = 'PrepmtUnpaidAmount',
  /** Aggregate result on PrepmtPaidAmountInclVAT */
  PrepmtPaidAmountInclVat = 'PrepmtPaidAmountInclVAT',
  /** Aggregate result on PrepmtUnpaidAmtInclVAT */
  PrepmtUnpaidAmtInclVat = 'PrepmtUnpaidAmtInclVAT',
  /** Aggregate result on PrepmtInsuranceAmt */
  PrepmtInsuranceAmt = 'PrepmtInsuranceAmt',
  /** Aggregate result on PrepmtInsuranceAmtInclVAT */
  PrepmtInsuranceAmtInclVat = 'PrepmtInsuranceAmtInclVAT',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalLineInput {
  /** Aggregate result on ContractType */
  ContractType = 'ContractType',
  /** Aggregate result on ContractTypeOPTION */
  ContractTypeOption = 'ContractTypeOPTION',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on ContractStatus */
  ContractStatus = 'ContractStatus',
  /** Aggregate result on ContractStatusOPTION */
  ContractStatusOption = 'ContractStatusOPTION',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on StartRentalPeriod */
  StartRentalPeriod = 'StartRentalPeriod',
  /** Aggregate result on EndRentalPeriod */
  EndRentalPeriod = 'EndRentalPeriod',
  /** Aggregate result on ShipmentTime */
  ShipmentTime = 'ShipmentTime',
  /** Aggregate result on ReturnTime */
  ReturnTime = 'ReturnTime',
  /** Aggregate result on InvoicedToDate */
  InvoicedToDate = 'InvoicedToDate',
  /** Aggregate result on NextInvoiceDate */
  NextInvoiceDate = 'NextInvoiceDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on ExpirationDatesInGroup */
  ExpirationDatesInGroup = 'ExpirationDatesInGroup',
  /** Aggregate result on RentalPeriod */
  RentalPeriod = 'RentalPeriod',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on NextInvoicePeriodStart */
  NextInvoicePeriodStart = 'NextInvoicePeriodStart',
  /** Aggregate result on NextInvoicePeriodEnd */
  NextInvoicePeriodEnd = 'NextInvoicePeriodEnd',
  /** Aggregate result on RentalPeriodInvoiced */
  RentalPeriodInvoiced = 'RentalPeriodInvoiced',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on Credited */
  Credited = 'Credited',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on EntryStatus */
  EntryStatus = 'EntryStatus',
  /** Aggregate result on EntryStatusOPTION */
  EntryStatusOption = 'EntryStatusOPTION',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on RentalClosed */
  RentalClosed = 'RentalClosed',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on RentalPeriodNow */
  RentalPeriodNow = 'RentalPeriodNow',
  /** Aggregate result on RentalChargeDays */
  RentalChargeDays = 'RentalChargeDays',
  /** Aggregate result on RentalChargeDaysOPTION */
  RentalChargeDaysOption = 'RentalChargeDaysOPTION',
  /** Aggregate result on DeliveredTime */
  DeliveredTime = 'DeliveredTime',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on ReturnCompleted */
  ReturnCompleted = 'ReturnCompleted',
  /** Aggregate result on TransferdToOrder */
  TransferdToOrder = 'TransferdToOrder',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Counter */
  Counter = 'Counter',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on PriceTerm */
  PriceTerm = 'PriceTerm',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on UnitPriceSale */
  UnitPriceSale = 'UnitPriceSale',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on ReservedSeasonCalendar */
  ReservedSeasonCalendar = 'ReservedSeasonCalendar',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on ShipmentNo */
  ShipmentNo = 'ShipmentNo',
  /** Aggregate result on ShipmentLineNo */
  ShipmentLineNo = 'ShipmentLineNo',
  /** Aggregate result on Counter2 */
  Counter2 = 'Counter2',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on EstimatedCounterperDay */
  EstimatedCounterperDay = 'EstimatedCounterperDay',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on CustomerSubProject */
  CustomerSubProject = 'CustomerSubProject',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on EstimatedCounter2perDay */
  EstimatedCounter2perDay = 'EstimatedCounter2perDay',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on WorkInprogress */
  WorkInprogress = 'WorkInprogress',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on PctofSalesPrice */
  PctofSalesPrice = 'PctofSalesPrice',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepmtLineAmount */
  PrepmtLineAmount = 'PrepmtLineAmount',
  /** Aggregate result on PrepmtAmtInv */
  PrepmtAmtInv = 'PrepmtAmtInv',
  /** Aggregate result on PrepmtAmtInclVAT */
  PrepmtAmtInclVat = 'PrepmtAmtInclVAT',
  /** Aggregate result on PrepaymentPeriod */
  PrepaymentPeriod = 'PrepaymentPeriod',
  /** Aggregate result on PrepmtUseExpirationDate */
  PrepmtUseExpirationDate = 'PrepmtUseExpirationDate',
  /** Aggregate result on PrepmtSalesLineNo */
  PrepmtSalesLineNo = 'PrepmtSalesLineNo',
  /** Aggregate result on PrepmtInvoiceStatus */
  PrepmtInvoiceStatus = 'PrepmtInvoiceStatus',
  /** Aggregate result on PrepmtInvoiceStatusOPTION */
  PrepmtInvoiceStatusOption = 'PrepmtInvoiceStatusOPTION',
  /** Aggregate result on PrepmtCrMemoStatus */
  PrepmtCrMemoStatus = 'PrepmtCrMemoStatus',
  /** Aggregate result on PrepmtCrMemoStatusOPTION */
  PrepmtCrMemoStatusOption = 'PrepmtCrMemoStatusOPTION',
  /** Aggregate result on PrepmtDocNo */
  PrepmtDocNo = 'PrepmtDocNo',
  /** Aggregate result on PrepmtInsuranceAmount */
  PrepmtInsuranceAmount = 'PrepmtInsuranceAmount',
  /** Aggregate result on PrepmtAmtInOrder */
  PrepmtAmtInOrder = 'PrepmtAmtInOrder',
  /** Aggregate result on PrepmtAmtInOrderInclVAT */
  PrepmtAmtInOrderInclVat = 'PrepmtAmtInOrderInclVAT',
  /** Aggregate result on PrepmtInsuranceAmtInOrder */
  PrepmtInsuranceAmtInOrder = 'PrepmtInsuranceAmtInOrder',
  /** Aggregate result on PrepmtAmttoDeduct */
  PrepmtAmttoDeduct = 'PrepmtAmttoDeduct',
  /** Aggregate result on PrepmtAmtDeducted */
  PrepmtAmtDeducted = 'PrepmtAmtDeducted',
  /** Aggregate result on PrepmtAmttoDeductVAT */
  PrepmtAmttoDeductVat = 'PrepmtAmttoDeductVAT',
  /** Aggregate result on PrepmtAmtDeductedVAT */
  PrepmtAmtDeductedVat = 'PrepmtAmtDeductedVAT',
  /** Aggregate result on PrepmtRentalLineNo */
  PrepmtRentalLineNo = 'PrepmtRentalLineNo',
  /** Aggregate result on PrepmtInsuranceToDeduct */
  PrepmtInsuranceToDeduct = 'PrepmtInsuranceToDeduct',
  /** Aggregate result on PrepmtInsuranceDeducted */
  PrepmtInsuranceDeducted = 'PrepmtInsuranceDeducted',
  /** Aggregate result on PrepmtInsuranceAmtInclVAT */
  PrepmtInsuranceAmtInclVat = 'PrepmtInsuranceAmtInclVAT',
  /** Aggregate result on PrepmtInsAmtOrderIncVAT */
  PrepmtInsAmtOrderIncVat = 'PrepmtInsAmtOrderIncVAT',
  /** Aggregate result on PrepmtDimensionSetID */
  PrepmtDimensionSetId = 'PrepmtDimensionSetID',
  /** Aggregate result on PrepmtVATBusPostingGroup */
  PrepmtVatBusPostingGroup = 'PrepmtVATBusPostingGroup',
  /** Aggregate result on PrepmtVATProdPostingGrp */
  PrepmtVatProdPostingGrp = 'PrepmtVATProdPostingGrp',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on OldAttachedtoLineNo */
  OldAttachedtoLineNo = 'OldAttachedtoLineNo',
  /** Aggregate result on AttachedtoSwitchLineNo */
  AttachedtoSwitchLineNo = 'AttachedtoSwitchLineNo',
  /** Aggregate result on AttachedStandByLineNo */
  AttachedStandByLineNo = 'AttachedStandByLineNo',
  /** Aggregate result on StandbyStatus */
  StandbyStatus = 'StandbyStatus',
  /** Aggregate result on StandbyStatusOPTION */
  StandbyStatusOption = 'StandbyStatusOPTION',
  /** Aggregate result on QtyonAttachedtoLineNo */
  QtyonAttachedtoLineNo = 'QtyonAttachedtoLineNo',
  /** Aggregate result on KITMother */
  KitMother = 'KITMother',
  /** Aggregate result on KITFactor */
  KitFactor = 'KITFactor',
  /** Aggregate result on KITDontShow */
  KitDontShow = 'KITDontShow',
  /** Aggregate result on KITMotherLineNo */
  KitMotherLineNo = 'KITMotherLineNo',
  /** Aggregate result on KITFactorPrice */
  KitFactorPrice = 'KITFactorPrice',
  /** Aggregate result on KITOriginalPrice */
  KitOriginalPrice = 'KITOriginalPrice',
  /** Aggregate result on KITQuantity */
  KitQuantity = 'KITQuantity',
  /** Aggregate result on KITLineAmountNet */
  KitLineAmountNet = 'KITLineAmountNet',
  /** Aggregate result on KITLineAmountGross */
  KitLineAmountGross = 'KITLineAmountGross',
  /** Aggregate result on KITOriginalSqMeter */
  KitOriginalSqMeter = 'KITOriginalSqMeter',
  /** Aggregate result on SquareMeterperKIT */
  SquareMeterperKit = 'SquareMeterperKIT',
  /** Aggregate result on KITPriceperSqMeter */
  KitPriceperSqMeter = 'KITPriceperSqMeter',
  /** Aggregate result on KITChange */
  KitChange = 'KITChange',
  /** Aggregate result on TotalKITPrice */
  TotalKitPrice = 'TotalKITPrice',
  /** Aggregate result on DefaultKITPrice */
  DefaultKitPrice = 'DefaultKITPrice',
  /** Aggregate result on DefaultKITPriceMarked */
  DefaultKitPriceMarked = 'DefaultKITPriceMarked',
  /** Aggregate result on DefaultKITFactorPrice */
  DefaultKitFactorPrice = 'DefaultKITFactorPrice',
  /** Aggregate result on KITComponentUnitPrice */
  KitComponentUnitPrice = 'KITComponentUnitPrice',
  /** Aggregate result on KITComponentRentalPrice */
  KitComponentRentalPrice = 'KITComponentRentalPrice',
  /** Aggregate result on KITLineDiscount */
  KitLineDiscount = 'KITLineDiscount',
  /** Aggregate result on KITLineDiscountAmount */
  KitLineDiscountAmount = 'KITLineDiscountAmount',
  /** Aggregate result on OldTmplMotherLineNo */
  OldTmplMotherLineNo = 'OldTmplMotherLineNo',
  /** Aggregate result on OnlyonFirstOrderInvoice */
  OnlyonFirstOrderInvoice = 'OnlyonFirstOrderInvoice',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on ReturnDateBuffer */
  ReturnDateBuffer = 'ReturnDateBuffer',
  /** Aggregate result on OffRentDateBuffer */
  OffRentDateBuffer = 'OffRentDateBuffer',
  /** Aggregate result on ReturnQuantityBuffer */
  ReturnQuantityBuffer = 'ReturnQuantityBuffer',
  /** Aggregate result on OffRentQuantityBuffer */
  OffRentQuantityBuffer = 'OffRentQuantityBuffer',
  /** Aggregate result on DebitonReturnDateBuffer */
  DebitonReturnDateBuffer = 'DebitonReturnDateBuffer',
  /** Aggregate result on CounteronReturnBuffer */
  CounteronReturnBuffer = 'CounteronReturnBuffer',
  /** Aggregate result on ReturnReminderBuffer */
  ReturnReminderBuffer = 'ReturnReminderBuffer',
  /** Aggregate result on UpdateLineBuffer */
  UpdateLineBuffer = 'UpdateLineBuffer',
  /** Aggregate result on ReturnNoBuffer */
  ReturnNoBuffer = 'ReturnNoBuffer',
  /** Aggregate result on ReturnPoleSplitBuffer */
  ReturnPoleSplitBuffer = 'ReturnPoleSplitBuffer',
  /** Aggregate result on ReturnCompleteBuffer */
  ReturnCompleteBuffer = 'ReturnCompleteBuffer',
  /** Aggregate result on InvoicedToDateBuffer */
  InvoicedToDateBuffer = 'InvoicedToDateBuffer',
  /** Aggregate result on ReceiveLocationCodeBuffer */
  ReceiveLocationCodeBuffer = 'ReceiveLocationCodeBuffer',
  /** Aggregate result on ReturnTimeBuffer */
  ReturnTimeBuffer = 'ReturnTimeBuffer',
  /** Aggregate result on DepositAmountCardBuffer */
  DepositAmountCardBuffer = 'DepositAmountCardBuffer',
  /** Aggregate result on DepositAmountCashBuffer */
  DepositAmountCashBuffer = 'DepositAmountCashBuffer',
  /** Aggregate result on ReclassQuantityBuffer */
  ReclassQuantityBuffer = 'ReclassQuantityBuffer',
  /** Aggregate result on ReclassDateBuffer */
  ReclassDateBuffer = 'ReclassDateBuffer',
  /** Aggregate result on CorrFromDateBuffer */
  CorrFromDateBuffer = 'CorrFromDateBuffer',
  /** Aggregate result on CorrInvToDateBuffer */
  CorrInvToDateBuffer = 'CorrInvToDateBuffer',
  /** Aggregate result on CorrReturnDateBuffer */
  CorrReturnDateBuffer = 'CorrReturnDateBuffer',
  /** Aggregate result on CorrCloseRentalBuffer */
  CorrCloseRentalBuffer = 'CorrCloseRentalBuffer',
  /** Aggregate result on CorrCloseRentalBufferOPTION */
  CorrCloseRentalBufferOption = 'CorrCloseRentalBufferOPTION',
  /** Aggregate result on CorrUpdEntryBasisBuffer */
  CorrUpdEntryBasisBuffer = 'CorrUpdEntryBasisBuffer',
  /** Aggregate result on CorrQuantityBuffer */
  CorrQuantityBuffer = 'CorrQuantityBuffer',
  /** Aggregate result on CorrReturnQuantityBuffer */
  CorrReturnQuantityBuffer = 'CorrReturnQuantityBuffer',
  /** Aggregate result on ReclassFromContractBuffer */
  ReclassFromContractBuffer = 'ReclassFromContractBuffer',
  /** Aggregate result on ReclassToContractBuffer */
  ReclassToContractBuffer = 'ReclassToContractBuffer',
  /** Aggregate result on MaintenanceBuffer */
  MaintenanceBuffer = 'MaintenanceBuffer',
  /** Aggregate result on DepositAmountTnsfBuffer */
  DepositAmountTnsfBuffer = 'DepositAmountTnsfBuffer',
  /** Aggregate result on OffRentTimeBuffer */
  OffRentTimeBuffer = 'OffRentTimeBuffer',
  /** Aggregate result on OffRentNoBuffer */
  OffRentNoBuffer = 'OffRentNoBuffer',
  /** Aggregate result on CorrQtyShippedtoBuffer */
  CorrQtyShippedtoBuffer = 'CorrQtyShippedtoBuffer',
  /** Aggregate result on TempEntryBuffer */
  TempEntryBuffer = 'TempEntryBuffer',
  /** Aggregate result on ReturntoSellBuffer */
  ReturntoSellBuffer = 'ReturntoSellBuffer',
  /** Aggregate result on ReturntoDamageBuffer */
  ReturntoDamageBuffer = 'ReturntoDamageBuffer',
  /** Aggregate result on Counter2onReturnBuffer */
  Counter2onReturnBuffer = 'Counter2onReturnBuffer',
  /** Aggregate result on CorrOnRentDateBuffer */
  CorrOnRentDateBuffer = 'CorrOnRentDateBuffer',
  /** Aggregate result on CorrNextInvDateBuffer */
  CorrNextInvDateBuffer = 'CorrNextInvDateBuffer',
  /** Aggregate result on CollectionDocNoBuffer */
  CollectionDocNoBuffer = 'CollectionDocNoBuffer',
  /** Aggregate result on CollectionLineNoBuffer */
  CollectionLineNoBuffer = 'CollectionLineNoBuffer',
  /** Aggregate result on SourceCodeBuffer */
  SourceCodeBuffer = 'SourceCodeBuffer',
  /** Aggregate result on DeliverQuantityNowBuffer */
  DeliverQuantityNowBuffer = 'DeliverQuantityNowBuffer',
  /** Aggregate result on ShipmentDateBuffer */
  ShipmentDateBuffer = 'ShipmentDateBuffer',
  /** Aggregate result on OnRentDateBuffer */
  OnRentDateBuffer = 'OnRentDateBuffer',
  /** Aggregate result on LocationCodeBuffer */
  LocationCodeBuffer = 'LocationCodeBuffer',
  /** Aggregate result on ConsumptionatReturnBuffer */
  ConsumptionatReturnBuffer = 'ConsumptionatReturnBuffer',
  /** Aggregate result on CreditonEarlyBuffer */
  CreditonEarlyBuffer = 'CreditonEarlyBuffer',
  /** Aggregate result on StartRentalPeriodBuffer */
  StartRentalPeriodBuffer = 'StartRentalPeriodBuffer',
  /** Aggregate result on LineNoBuffer */
  LineNoBuffer = 'LineNoBuffer',
  /** Aggregate result on ConsumpatDeliveryBuffer */
  ConsumpatDeliveryBuffer = 'ConsumpatDeliveryBuffer',
  /** Aggregate result on DepositAmountBackBuffer */
  DepositAmountBackBuffer = 'DepositAmountBackBuffer',
  /** Aggregate result on DispatchTypeBuffer */
  DispatchTypeBuffer = 'DispatchTypeBuffer',
  /** Aggregate result on DispatchTypeBufferOPTION */
  DispatchTypeBufferOption = 'DispatchTypeBufferOPTION',
  /** Aggregate result on PstDispatchDocNoBuffer */
  PstDispatchDocNoBuffer = 'PstDispatchDocNoBuffer',
  /** Aggregate result on PstDispatchLineNoBuffer */
  PstDispatchLineNoBuffer = 'PstDispatchLineNoBuffer',
  /** Aggregate result on PostedInDocumentBuffer */
  PostedInDocumentBuffer = 'PostedInDocumentBuffer',
  /** Aggregate result on EQMServiceDocBuffer */
  EqmServiceDocBuffer = 'EQMServiceDocBuffer',
  /** Aggregate result on UnitPriceBuffer */
  UnitPriceBuffer = 'UnitPriceBuffer',
  /** Aggregate result on LineDiscountBuffer */
  LineDiscountBuffer = 'LineDiscountBuffer',
  /** Aggregate result on LineMarkedBuffer */
  LineMarkedBuffer = 'LineMarkedBuffer',
  /** Aggregate result on PostingDateBuffer */
  PostingDateBuffer = 'PostingDateBuffer',
  /** Aggregate result on UseInOpenPeriodBuffer */
  UseInOpenPeriodBuffer = 'UseInOpenPeriodBuffer',
  /** Aggregate result on AttachedtoLineNoBuffer */
  AttachedtoLineNoBuffer = 'AttachedtoLineNoBuffer',
  /** Aggregate result on CounteronShipmentBuffer */
  CounteronShipmentBuffer = 'CounteronShipmentBuffer',
  /** Aggregate result on Counter2onShipmentBuffer */
  Counter2onShipmentBuffer = 'Counter2onShipmentBuffer',
  /** Aggregate result on NoofRentFreeDaysBuffer */
  NoofRentFreeDaysBuffer = 'NoofRentFreeDaysBuffer',
  /** Aggregate result on NoofRentFreeHourBuffer */
  NoofRentFreeHourBuffer = 'NoofRentFreeHourBuffer',
  /** Aggregate result on ReasonforRentFreeBuffer */
  ReasonforRentFreeBuffer = 'ReasonforRentFreeBuffer',
  /** Aggregate result on ReceiveRespCenterBuffer */
  ReceiveRespCenterBuffer = 'ReceiveRespCenterBuffer',
  /** Aggregate result on FullyConsumedAccBuffer */
  FullyConsumedAccBuffer = 'FullyConsumedAccBuffer',
  /** Aggregate result on ReceivingBinCodeBuffer */
  ReceivingBinCodeBuffer = 'ReceivingBinCodeBuffer',
  /** Aggregate result on SuspendLineNoBuffer */
  SuspendLineNoBuffer = 'SuspendLineNoBuffer',
  /** Aggregate result on UnitPriceNew */
  UnitPriceNew = 'UnitPriceNew',
  /** Aggregate result on PriceTermCodeNew */
  PriceTermCodeNew = 'PriceTermCodeNew',
  /** Aggregate result on UnitPriceprDayNew */
  UnitPriceprDayNew = 'UnitPriceprDayNew',
  /** Aggregate result on UnitPriceprMonthNew */
  UnitPriceprMonthNew = 'UnitPriceprMonthNew',
  /** Aggregate result on LineAmountNew */
  LineAmountNew = 'LineAmountNew',
  /** Aggregate result on RentalPeriodNew */
  RentalPeriodNew = 'RentalPeriodNew',
  /** Aggregate result on LineDiscountNew */
  LineDiscountNew = 'LineDiscountNew',
  /** Aggregate result on LineDiscountAmountNew */
  LineDiscountAmountNew = 'LineDiscountAmountNew',
  /** Aggregate result on StairPriceCodeNew */
  StairPriceCodeNew = 'StairPriceCodeNew',
  /** Aggregate result on StairDiscountCodeNew */
  StairDiscountCodeNew = 'StairDiscountCodeNew',
  /** Aggregate result on BaseCalendarCodeNew */
  BaseCalendarCodeNew = 'BaseCalendarCodeNew',
  /** Aggregate result on StairPrice1New */
  StairPrice1New = 'StairPrice1New',
  /** Aggregate result on StairPrice2New */
  StairPrice2New = 'StairPrice2New',
  /** Aggregate result on StairPrice3New */
  StairPrice3New = 'StairPrice3New',
  /** Aggregate result on StairPrice4New */
  StairPrice4New = 'StairPrice4New',
  /** Aggregate result on StairPrice5New */
  StairPrice5New = 'StairPrice5New',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on Preliminary */
  Preliminary = 'Preliminary',
  /** Aggregate result on ReferenceTableID */
  ReferenceTableId = 'ReferenceTableID',
  /** Aggregate result on ReferenceUniqueID */
  ReferenceUniqueId = 'ReferenceUniqueID',
  /** Aggregate result on UniqueID */
  UniqueId = 'UniqueID',
  /** Aggregate result on CreatedTime */
  CreatedTime = 'CreatedTime',
  /** Aggregate result on ReplacementUnitPrice */
  ReplacementUnitPrice = 'ReplacementUnitPrice',
  /** Aggregate result on ReRentObject */
  ReRentObject = 'ReRentObject',
  /** Aggregate result on ReRentCost */
  ReRentCost = 'ReRentCost',
  /** Aggregate result on UnitPriceprDay */
  UnitPriceprDay = 'UnitPriceprDay',
  /** Aggregate result on UnitPriceprMonth */
  UnitPriceprMonth = 'UnitPriceprMonth',
  /** Aggregate result on AllocateObjectNo */
  AllocateObjectNo = 'AllocateObjectNo',
  /** Aggregate result on DepositOrigUnitPrice */
  DepositOrigUnitPrice = 'DepositOrigUnitPrice',
  /** Aggregate result on MarkDepositEntry */
  MarkDepositEntry = 'MarkDepositEntry',
  /** Aggregate result on DepositUnitPrice */
  DepositUnitPrice = 'DepositUnitPrice',
  /** Aggregate result on DepositPostingNo */
  DepositPostingNo = 'DepositPostingNo',
  /** Aggregate result on DepositAmount */
  DepositAmount = 'DepositAmount',
  /** Aggregate result on ReplacementPrice */
  ReplacementPrice = 'ReplacementPrice',
  /** Aggregate result on DepositReturnPostingNo */
  DepositReturnPostingNo = 'DepositReturnPostingNo',
  /** Aggregate result on AgreedToDate */
  AgreedToDate = 'AgreedToDate',
  /** Aggregate result on DepositPostingType */
  DepositPostingType = 'DepositPostingType',
  /** Aggregate result on DepositPostingTypeOPTION */
  DepositPostingTypeOption = 'DepositPostingTypeOPTION',
  /** Aggregate result on DontTransfertoOrder */
  DontTransfertoOrder = 'DontTransfertoOrder',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on DeliveredBy */
  DeliveredBy = 'DeliveredBy',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on SaleDocumentNo */
  SaleDocumentNo = 'SaleDocumentNo',
  /** Aggregate result on SaleDocumentType */
  SaleDocumentType = 'SaleDocumentType',
  /** Aggregate result on SaleDocumentTypeOPTION */
  SaleDocumentTypeOption = 'SaleDocumentTypeOPTION',
  /** Aggregate result on SaleLineNo */
  SaleLineNo = 'SaleLineNo',
  /** Aggregate result on SaleReserve */
  SaleReserve = 'SaleReserve',
  /** Aggregate result on SaleReserveOPTION */
  SaleReserveOption = 'SaleReserveOPTION',
  /** Aggregate result on ReturnQuantityHours */
  ReturnQuantityHours = 'ReturnQuantityHours',
  /** Aggregate result on ShipmentDateTime */
  ShipmentDateTime = 'ShipmentDateTime',
  /** Aggregate result on EndRentalDateTime */
  EndRentalDateTime = 'EndRentalDateTime',
  /** Aggregate result on ExpectedReturnDate */
  ExpectedReturnDate = 'ExpectedReturnDate',
  /** Aggregate result on DoShipmentNow */
  DoShipmentNow = 'DoShipmentNow',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on QtyHours */
  QtyHours = 'QtyHours',
  /** Aggregate result on OnRentTime */
  OnRentTime = 'OnRentTime',
  /** Aggregate result on OffRentTime */
  OffRentTime = 'OffRentTime',
  /** Aggregate result on OnRentDateTime */
  OnRentDateTime = 'OnRentDateTime',
  /** Aggregate result on OffRentDateTime */
  OffRentDateTime = 'OffRentDateTime',
  /** Aggregate result on ReturnQuantityChargeHours */
  ReturnQuantityChargeHours = 'ReturnQuantityChargeHours',
  /** Aggregate result on TerminPeriod */
  TerminPeriod = 'TerminPeriod',
  /** Aggregate result on LineAmountPeriod */
  LineAmountPeriod = 'LineAmountPeriod',
  /** Aggregate result on IgnorCalendarCheck */
  IgnorCalendarCheck = 'IgnorCalendarCheck',
  /** Aggregate result on MeasurementatDelivery */
  MeasurementatDelivery = 'MeasurementatDelivery',
  /** Aggregate result on MeasurementatReturn */
  MeasurementatReturn = 'MeasurementatReturn',
  /** Aggregate result on DiamondMotherLineNo */
  DiamondMotherLineNo = 'DiamondMotherLineNo',
  /** Aggregate result on DiamondBladeMotherRef */
  DiamondBladeMotherRef = 'DiamondBladeMotherRef',
  /** Aggregate result on ReturnAdditionMotherRef */
  ReturnAdditionMotherRef = 'ReturnAdditionMotherRef',
  /** Aggregate result on StairPriceCode */
  StairPriceCode = 'StairPriceCode',
  /** Aggregate result on ExtraHoursReturnTime */
  ExtraHoursReturnTime = 'ExtraHoursReturnTime',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on TmpAccumEntry */
  TmpAccumEntry = 'TmpAccumEntry',
  /** Aggregate result on MarkReclassLineNoRef */
  MarkReclassLineNoRef = 'MarkReclassLineNoRef',
  /** Aggregate result on MarkReclassQuantity */
  MarkReclassQuantity = 'MarkReclassQuantity',
  /** Aggregate result on PostingPriority */
  PostingPriority = 'PostingPriority',
  /** Aggregate result on PostingPriorityOPTION */
  PostingPriorityOption = 'PostingPriorityOPTION',
  /** Aggregate result on ReclassFromLocationCode */
  ReclassFromLocationCode = 'ReclassFromLocationCode',
  /** Aggregate result on ReclassOrderedBy */
  ReclassOrderedBy = 'ReclassOrderedBy',
  /** Aggregate result on MarkReclassLine */
  MarkReclassLine = 'MarkReclassLine',
  /** Aggregate result on FilterReclassLine */
  FilterReclassLine = 'FilterReclassLine',
  /** Aggregate result on ReclassFromContractNo */
  ReclassFromContractNo = 'ReclassFromContractNo',
  /** Aggregate result on ReclassToContractNo */
  ReclassToContractNo = 'ReclassToContractNo',
  /** Aggregate result on ReclassToLocationCode */
  ReclassToLocationCode = 'ReclassToLocationCode',
  /** Aggregate result on ReclassOrderNo */
  ReclassOrderNo = 'ReclassOrderNo',
  /** Aggregate result on ReclassDocNo */
  ReclassDocNo = 'ReclassDocNo',
  /** Aggregate result on WorkshopObjectNo */
  WorkshopObjectNo = 'WorkshopObjectNo',
  /** Aggregate result on DiamondBladeItem */
  DiamondBladeItem = 'DiamondBladeItem',
  /** Aggregate result on DiamondBladeItemOPTION */
  DiamondBladeItemOption = 'DiamondBladeItemOPTION',
  /** Aggregate result on IgnoreVaryingCompQty */
  IgnoreVaryingCompQty = 'IgnoreVaryingCompQty',
  /** Aggregate result on ExploreLine */
  ExploreLine = 'ExploreLine',
  /** Aggregate result on EnvironmentalFee */
  EnvironmentalFee = 'EnvironmentalFee',
  /** Aggregate result on ExtraHoursCharged */
  ExtraHoursCharged = 'ExtraHoursCharged',
  /** Aggregate result on ExtraHoursReturnNo */
  ExtraHoursReturnNo = 'ExtraHoursReturnNo',
  /** Aggregate result on PriceonExtraHours */
  PriceonExtraHours = 'PriceonExtraHours',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on ReclassDocLineNo */
  ReclassDocLineNo = 'ReclassDocLineNo',
  /** Aggregate result on RentalSplitCostCenter */
  RentalSplitCostCenter = 'RentalSplitCostCenter',
  /** Aggregate result on RentalSplit */
  RentalSplit = 'RentalSplit',
  /** Aggregate result on PricePriority */
  PricePriority = 'PricePriority',
  /** Aggregate result on PricePriorityOPTION */
  PricePriorityOption = 'PricePriorityOPTION',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on TotalNetWeight */
  TotalNetWeight = 'TotalNetWeight',
  /** Aggregate result on RecursiveLevel */
  RecursiveLevel = 'RecursiveLevel',
  /** Aggregate result on Repurchase */
  Repurchase = 'Repurchase',
  /** Aggregate result on PriceScheduleCode */
  PriceScheduleCode = 'PriceScheduleCode',
  /** Aggregate result on CPNextInvoiceAmount */
  CpNextInvoiceAmount = 'CPNextInvoiceAmount',
  /** Aggregate result on CPAdvancePaymentStatus */
  CpAdvancePaymentStatus = 'CPAdvancePaymentStatus',
  /** Aggregate result on CPAdvancePaymentStatusOPTION */
  CpAdvancePaymentStatusOption = 'CPAdvancePaymentStatusOPTION',
  /** Aggregate result on CPAdvancePaymentDocNo */
  CpAdvancePaymentDocNo = 'CPAdvancePaymentDocNo',
  /** Aggregate result on InvoiceFee */
  InvoiceFee = 'InvoiceFee',
  /** Aggregate result on StairDiscountCode */
  StairDiscountCode = 'StairDiscountCode',
  /** Aggregate result on StairPriceDiscount */
  StairPriceDiscount = 'StairPriceDiscount',
  /** Aggregate result on ReceiveRespCenter */
  ReceiveRespCenter = 'ReceiveRespCenter',
  /** Aggregate result on ReceiveCostCenter */
  ReceiveCostCenter = 'ReceiveCostCenter',
  /** Aggregate result on AllowLineDisconExHours */
  AllowLineDisconExHours = 'AllowLineDisconExHours',
  /** Aggregate result on QuantitytoAllocate */
  QuantitytoAllocate = 'QuantitytoAllocate',
  /** Aggregate result on ServiceMotherLineNo */
  ServiceMotherLineNo = 'ServiceMotherLineNo',
  /** Aggregate result on AdditionalCharge */
  AdditionalCharge = 'AdditionalCharge',
  /** Aggregate result on AdjforAdditionalCharge */
  AdjforAdditionalCharge = 'AdjforAdditionalCharge',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on EQMServiceNoTemp */
  EqmServiceNoTemp = 'EQMServiceNoTemp',
  /** Aggregate result on PriceReportedHours */
  PriceReportedHours = 'PriceReportedHours',
  /** Aggregate result on FreeRentalHours */
  FreeRentalHours = 'FreeRentalHours',
  /** Aggregate result on DispatchTask */
  DispatchTask = 'DispatchTask',
  /** Aggregate result on DispatchTaskOPTION */
  DispatchTaskOption = 'DispatchTaskOPTION',
  /** Aggregate result on DispatchStatus */
  DispatchStatus = 'DispatchStatus',
  /** Aggregate result on DispatchStatusOPTION */
  DispatchStatusOption = 'DispatchStatusOPTION',
  /** Aggregate result on ScheduledDeliveryDate */
  ScheduledDeliveryDate = 'ScheduledDeliveryDate',
  /** Aggregate result on ScheduledDeliveryTime */
  ScheduledDeliveryTime = 'ScheduledDeliveryTime',
  /** Aggregate result on ScheduledPickupDate */
  ScheduledPickupDate = 'ScheduledPickupDate',
  /** Aggregate result on ScheduledPickupTime */
  ScheduledPickupTime = 'ScheduledPickupTime',
  /** Aggregate result on DeliveryDriver */
  DeliveryDriver = 'DeliveryDriver',
  /** Aggregate result on PickupDriver */
  PickupDriver = 'PickupDriver',
  /** Aggregate result on Driver */
  Driver = 'Driver',
  /** Aggregate result on SchedDate */
  SchedDate = 'SchedDate',
  /** Aggregate result on ActualDate */
  ActualDate = 'ActualDate',
  /** Aggregate result on SchedTime */
  SchedTime = 'SchedTime',
  /** Aggregate result on ActualTime */
  ActualTime = 'ActualTime',
  /** Aggregate result on ActualDeliveryDate */
  ActualDeliveryDate = 'ActualDeliveryDate',
  /** Aggregate result on ActualDeliveryTime */
  ActualDeliveryTime = 'ActualDeliveryTime',
  /** Aggregate result on ActualPickupDate */
  ActualPickupDate = 'ActualPickupDate',
  /** Aggregate result on ActualPickupTime */
  ActualPickupTime = 'ActualPickupTime',
  /** Aggregate result on ActualReturnDate */
  ActualReturnDate = 'ActualReturnDate',
  /** Aggregate result on ActualReturnTime */
  ActualReturnTime = 'ActualReturnTime',
  /** Aggregate result on MinimumReportedHours */
  MinimumReportedHours = 'MinimumReportedHours',
  /** Aggregate result on MinRepHoursPeriod */
  MinRepHoursPeriod = 'MinRepHoursPeriod',
  /** Aggregate result on StairPrice1 */
  StairPrice1 = 'StairPrice1',
  /** Aggregate result on StairPrice2 */
  StairPrice2 = 'StairPrice2',
  /** Aggregate result on StairPrice3 */
  StairPrice3 = 'StairPrice3',
  /** Aggregate result on DispatchDocType */
  DispatchDocType = 'DispatchDocType',
  /** Aggregate result on DispatchDocTypeOPTION */
  DispatchDocTypeOption = 'DispatchDocTypeOPTION',
  /** Aggregate result on DispatchDocNo */
  DispatchDocNo = 'DispatchDocNo',
  /** Aggregate result on DispatchLineNo */
  DispatchLineNo = 'DispatchLineNo',
  /** Aggregate result on StairPrice4 */
  StairPrice4 = 'StairPrice4',
  /** Aggregate result on StairPrice5 */
  StairPrice5 = 'StairPrice5',
  /** Aggregate result on MinimumRentalPeriod */
  MinimumRentalPeriod = 'MinimumRentalPeriod',
  /** Aggregate result on MinimumRentaltoDate */
  MinimumRentaltoDate = 'MinimumRentaltoDate',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on UserFilter */
  UserFilter = 'UserFilter',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DefaultKITPriceCompFlow */
  DefaultKitPriceCompFlow = 'DefaultKITPriceCompFlow',
  /** Aggregate result on AssignedtoKIT */
  AssignedtoKit = 'AssignedtoKIT',
  /** Aggregate result on OffRentDateFlow */
  OffRentDateFlow = 'OffRentDateFlow',
  /** Aggregate result on ReturnDateFlow */
  ReturnDateFlow = 'ReturnDateFlow',
  /** Aggregate result on QuantityShippedtoFlow */
  QuantityShippedtoFlow = 'QuantityShippedtoFlow',
  /** Aggregate result on QuantityReturnedFlow */
  QuantityReturnedFlow = 'QuantityReturnedFlow',
  /** Aggregate result on QuantityOffRentedFlow */
  QuantityOffRentedFlow = 'QuantityOffRentedFlow',
  /** Aggregate result on QuantityPstOffRentedFlow */
  QuantityPstOffRentedFlow = 'QuantityPstOffRentedFlow',
  /** Aggregate result on QuantityPstReturnedFlow */
  QuantityPstReturnedFlow = 'QuantityPstReturnedFlow',
  /** Aggregate result on QuantityPstShippedFlow */
  QuantityPstShippedFlow = 'QuantityPstShippedFlow',
  /** Aggregate result on QtyonPickOrder */
  QtyonPickOrder = 'QtyonPickOrder',
  /** Aggregate result on QtyonShipmentOrder */
  QtyonShipmentOrder = 'QtyonShipmentOrder',
  /** Aggregate result on QtyonCollectionOrder */
  QtyonCollectionOrder = 'QtyonCollectionOrder',
  /** Aggregate result on UserLocationFilter */
  UserLocationFilter = 'UserLocationFilter',
  /** Aggregate result on NoofWorkOrderLines */
  NoofWorkOrderLines = 'NoofWorkOrderLines',
  /** Aggregate result on NoofPickOrderLines */
  NoofPickOrderLines = 'NoofPickOrderLines',
  /** Aggregate result on NoofShipmentOrderLines */
  NoofShipmentOrderLines = 'NoofShipmentOrderLines',
  /** Aggregate result on NoofCollectionOrderLines */
  NoofCollectionOrderLines = 'NoofCollectionOrderLines',
  /** Aggregate result on NoofPostedPickOrderLines */
  NoofPostedPickOrderLines = 'NoofPostedPickOrderLines',
  /** Aggregate result on NoofPstdShptOrderLines */
  NoofPstdShptOrderLines = 'NoofPstdShptOrderLines',
  /** Aggregate result on NoofPstdCollOrderLines */
  NoofPstdCollOrderLines = 'NoofPstdCollOrderLines',
  /** Aggregate result on NoofRntlReservSummaries */
  NoofRntlReservSummaries = 'NoofRntlReservSummaries',
  /** Aggregate result on NoofRntlReservEntries */
  NoofRntlReservEntries = 'NoofRntlReservEntries',
  /** Aggregate result on QtyonRntlReservEntry */
  QtyonRntlReservEntry = 'QtyonRntlReservEntry',
  /** Aggregate result on QtyonRntlResShptEntry */
  QtyonRntlResShptEntry = 'QtyonRntlResShptEntry',
  /** Aggregate result on QtyonRntlResRetEntry */
  QtyonRntlResRetEntry = 'QtyonRntlResRetEntry',
  /** Aggregate result on QtyonBufferEntry */
  QtyonBufferEntry = 'QtyonBufferEntry',
  /** Aggregate result on MultipleRentalPeriodPrice */
  MultipleRentalPeriodPrice = 'MultipleRentalPeriodPrice',
  /** Aggregate result on MultipleRentalPeriodEntry */
  MultipleRentalPeriodEntry = 'MultipleRentalPeriodEntry',
  /** Aggregate result on LastErrorText */
  LastErrorText = 'LastErrorText',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on SaleReservedQuantity */
  SaleReservedQuantity = 'SaleReservedQuantity',
  /** Aggregate result on SaleReservedQtyBase */
  SaleReservedQtyBase = 'SaleReservedQtyBase',
  /** Aggregate result on KITNetWeight */
  KitNetWeight = 'KITNetWeight',
  /** Aggregate result on ReportedRentalHours */
  ReportedRentalHours = 'ReportedRentalHours',
  /** Aggregate result on EQMServiceDocNo */
  EqmServiceDocNo = 'EQMServiceDocNo',
  /** Aggregate result on DispatchDocument */
  DispatchDocument = 'DispatchDocument',
  /** Aggregate result on DispatchDocumentNo */
  DispatchDocumentNo = 'DispatchDocumentNo',
  /** Aggregate result on GroupOnNumber */
  GroupOnNumber = 'GroupOnNumber',
  /** Aggregate result on PeriodLineAmountEdge */
  PeriodLineAmountEdge = 'PeriodLineAmountEdge',
  /** Aggregate result on RentalDays */
  RentalDays = 'RentalDays',
  /** Aggregate result on RemainingQty */
  RemainingQty = 'RemainingQty',
  /** Aggregate result on FreeDays */
  FreeDays = 'FreeDays',
  /** Aggregate result on DayNetPriceEdge */
  DayNetPriceEdge = 'DayNetPriceEdge',
  /** Aggregate result on Vaccation */
  Vaccation = 'Vaccation',
  /** Aggregate result on Tags */
  Tags = 'Tags',
  /** Aggregate result on ReturnReservationActive */
  ReturnReservationActive = 'ReturnReservationActive',
  /** Aggregate result on UserExpectedReturnDate */
  UserExpectedReturnDate = 'UserExpectedReturnDate',
  /** Aggregate result on ImageUrl */
  ImageUrl = 'ImageUrl',
  /** Aggregate result on PSILink */
  PsiLink = 'PSILink',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalLineSnapshotInput {
  /** Aggregate result on ContractType */
  ContractType = 'ContractType',
  /** Aggregate result on ContractTypeOPTION */
  ContractTypeOption = 'ContractTypeOPTION',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on ContractStatus */
  ContractStatus = 'ContractStatus',
  /** Aggregate result on ContractStatusOPTION */
  ContractStatusOption = 'ContractStatusOPTION',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on StartRentalPeriod */
  StartRentalPeriod = 'StartRentalPeriod',
  /** Aggregate result on EndRentalPeriod */
  EndRentalPeriod = 'EndRentalPeriod',
  /** Aggregate result on ShipmentTime */
  ShipmentTime = 'ShipmentTime',
  /** Aggregate result on ReturnTime */
  ReturnTime = 'ReturnTime',
  /** Aggregate result on InvoicedToDate */
  InvoicedToDate = 'InvoicedToDate',
  /** Aggregate result on NextInvoiceDate */
  NextInvoiceDate = 'NextInvoiceDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on ExpirationDatesInGroup */
  ExpirationDatesInGroup = 'ExpirationDatesInGroup',
  /** Aggregate result on RentalPeriod */
  RentalPeriod = 'RentalPeriod',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on NextInvoicePeriodStart */
  NextInvoicePeriodStart = 'NextInvoicePeriodStart',
  /** Aggregate result on NextInvoicePeriodEnd */
  NextInvoicePeriodEnd = 'NextInvoicePeriodEnd',
  /** Aggregate result on RentalPeriodInvoiced */
  RentalPeriodInvoiced = 'RentalPeriodInvoiced',
  /** Aggregate result on UseHourlyRent */
  UseHourlyRent = 'UseHourlyRent',
  /** Aggregate result on Credited */
  Credited = 'Credited',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on EntryStatus */
  EntryStatus = 'EntryStatus',
  /** Aggregate result on EntryStatusOPTION */
  EntryStatusOption = 'EntryStatusOPTION',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on RentalClosed */
  RentalClosed = 'RentalClosed',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on RentalPeriodNow */
  RentalPeriodNow = 'RentalPeriodNow',
  /** Aggregate result on RentalChargeDays */
  RentalChargeDays = 'RentalChargeDays',
  /** Aggregate result on RentalChargeDaysOPTION */
  RentalChargeDaysOption = 'RentalChargeDaysOPTION',
  /** Aggregate result on DeliveredTime */
  DeliveredTime = 'DeliveredTime',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on ReturnCompleted */
  ReturnCompleted = 'ReturnCompleted',
  /** Aggregate result on TransferdToOrder */
  TransferdToOrder = 'TransferdToOrder',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on Counter */
  Counter = 'Counter',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on PriceTerm */
  PriceTerm = 'PriceTerm',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on UnitPriceSale */
  UnitPriceSale = 'UnitPriceSale',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on ReservedSeasonCalendar */
  ReservedSeasonCalendar = 'ReservedSeasonCalendar',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on ShipmentNo */
  ShipmentNo = 'ShipmentNo',
  /** Aggregate result on ShipmentLineNo */
  ShipmentLineNo = 'ShipmentLineNo',
  /** Aggregate result on Counter2 */
  Counter2 = 'Counter2',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on EstimatedCounterperDay */
  EstimatedCounterperDay = 'EstimatedCounterperDay',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on CustomerSubProject */
  CustomerSubProject = 'CustomerSubProject',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on EstimatedCounter2perDay */
  EstimatedCounter2perDay = 'EstimatedCounter2perDay',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on WorkInprogress */
  WorkInprogress = 'WorkInprogress',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on PctofSalesPrice */
  PctofSalesPrice = 'PctofSalesPrice',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepmtLineAmount */
  PrepmtLineAmount = 'PrepmtLineAmount',
  /** Aggregate result on PrepmtAmtInv */
  PrepmtAmtInv = 'PrepmtAmtInv',
  /** Aggregate result on PrepmtAmtInclVAT */
  PrepmtAmtInclVat = 'PrepmtAmtInclVAT',
  /** Aggregate result on PrepaymentPeriod */
  PrepaymentPeriod = 'PrepaymentPeriod',
  /** Aggregate result on PrepmtUseExpirationDate */
  PrepmtUseExpirationDate = 'PrepmtUseExpirationDate',
  /** Aggregate result on PrepmtSalesLineNo */
  PrepmtSalesLineNo = 'PrepmtSalesLineNo',
  /** Aggregate result on PrepmtInvoiceStatus */
  PrepmtInvoiceStatus = 'PrepmtInvoiceStatus',
  /** Aggregate result on PrepmtInvoiceStatusOPTION */
  PrepmtInvoiceStatusOption = 'PrepmtInvoiceStatusOPTION',
  /** Aggregate result on PrepmtCrMemoStatus */
  PrepmtCrMemoStatus = 'PrepmtCrMemoStatus',
  /** Aggregate result on PrepmtCrMemoStatusOPTION */
  PrepmtCrMemoStatusOption = 'PrepmtCrMemoStatusOPTION',
  /** Aggregate result on PrepmtDocNo */
  PrepmtDocNo = 'PrepmtDocNo',
  /** Aggregate result on PrepmtInsuranceAmount */
  PrepmtInsuranceAmount = 'PrepmtInsuranceAmount',
  /** Aggregate result on PrepmtAmtInOrder */
  PrepmtAmtInOrder = 'PrepmtAmtInOrder',
  /** Aggregate result on PrepmtAmtInOrderInclVAT */
  PrepmtAmtInOrderInclVat = 'PrepmtAmtInOrderInclVAT',
  /** Aggregate result on PrepmtInsuranceAmtInOrder */
  PrepmtInsuranceAmtInOrder = 'PrepmtInsuranceAmtInOrder',
  /** Aggregate result on PrepmtAmttoDeduct */
  PrepmtAmttoDeduct = 'PrepmtAmttoDeduct',
  /** Aggregate result on PrepmtAmtDeducted */
  PrepmtAmtDeducted = 'PrepmtAmtDeducted',
  /** Aggregate result on PrepmtAmttoDeductVAT */
  PrepmtAmttoDeductVat = 'PrepmtAmttoDeductVAT',
  /** Aggregate result on PrepmtAmtDeductedVAT */
  PrepmtAmtDeductedVat = 'PrepmtAmtDeductedVAT',
  /** Aggregate result on PrepmtRentalLineNo */
  PrepmtRentalLineNo = 'PrepmtRentalLineNo',
  /** Aggregate result on PrepmtInsuranceToDeduct */
  PrepmtInsuranceToDeduct = 'PrepmtInsuranceToDeduct',
  /** Aggregate result on PrepmtInsuranceDeducted */
  PrepmtInsuranceDeducted = 'PrepmtInsuranceDeducted',
  /** Aggregate result on PrepmtInsuranceAmtInclVAT */
  PrepmtInsuranceAmtInclVat = 'PrepmtInsuranceAmtInclVAT',
  /** Aggregate result on PrepmtInsAmtOrderIncVAT */
  PrepmtInsAmtOrderIncVat = 'PrepmtInsAmtOrderIncVAT',
  /** Aggregate result on PrepmtDimensionSetID */
  PrepmtDimensionSetId = 'PrepmtDimensionSetID',
  /** Aggregate result on PrepmtVATBusPostingGroup */
  PrepmtVatBusPostingGroup = 'PrepmtVATBusPostingGroup',
  /** Aggregate result on PrepmtVATProdPostingGrp */
  PrepmtVatProdPostingGrp = 'PrepmtVATProdPostingGrp',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on OldAttachedtoLineNo */
  OldAttachedtoLineNo = 'OldAttachedtoLineNo',
  /** Aggregate result on AttachedtoSwitchLineNo */
  AttachedtoSwitchLineNo = 'AttachedtoSwitchLineNo',
  /** Aggregate result on AttachedStandByLineNo */
  AttachedStandByLineNo = 'AttachedStandByLineNo',
  /** Aggregate result on StandbyStatus */
  StandbyStatus = 'StandbyStatus',
  /** Aggregate result on StandbyStatusOPTION */
  StandbyStatusOption = 'StandbyStatusOPTION',
  /** Aggregate result on QtyonAttachedtoLineNo */
  QtyonAttachedtoLineNo = 'QtyonAttachedtoLineNo',
  /** Aggregate result on KITMother */
  KitMother = 'KITMother',
  /** Aggregate result on KITFactor */
  KitFactor = 'KITFactor',
  /** Aggregate result on KITDontShow */
  KitDontShow = 'KITDontShow',
  /** Aggregate result on KITMotherLineNo */
  KitMotherLineNo = 'KITMotherLineNo',
  /** Aggregate result on KITFactorPrice */
  KitFactorPrice = 'KITFactorPrice',
  /** Aggregate result on KITOriginalPrice */
  KitOriginalPrice = 'KITOriginalPrice',
  /** Aggregate result on KITQuantity */
  KitQuantity = 'KITQuantity',
  /** Aggregate result on KITLineAmountNet */
  KitLineAmountNet = 'KITLineAmountNet',
  /** Aggregate result on KITLineAmountGross */
  KitLineAmountGross = 'KITLineAmountGross',
  /** Aggregate result on KITOriginalSqMeter */
  KitOriginalSqMeter = 'KITOriginalSqMeter',
  /** Aggregate result on SquareMeterperKIT */
  SquareMeterperKit = 'SquareMeterperKIT',
  /** Aggregate result on KITPriceperSqMeter */
  KitPriceperSqMeter = 'KITPriceperSqMeter',
  /** Aggregate result on KITChange */
  KitChange = 'KITChange',
  /** Aggregate result on TotalKITPrice */
  TotalKitPrice = 'TotalKITPrice',
  /** Aggregate result on DefaultKITPrice */
  DefaultKitPrice = 'DefaultKITPrice',
  /** Aggregate result on DefaultKITPriceMarked */
  DefaultKitPriceMarked = 'DefaultKITPriceMarked',
  /** Aggregate result on DefaultKITFactorPrice */
  DefaultKitFactorPrice = 'DefaultKITFactorPrice',
  /** Aggregate result on KITComponentUnitPrice */
  KitComponentUnitPrice = 'KITComponentUnitPrice',
  /** Aggregate result on KITComponentRentalPrice */
  KitComponentRentalPrice = 'KITComponentRentalPrice',
  /** Aggregate result on KITLineDiscount */
  KitLineDiscount = 'KITLineDiscount',
  /** Aggregate result on KITLineDiscountAmount */
  KitLineDiscountAmount = 'KITLineDiscountAmount',
  /** Aggregate result on OldTmplMotherLineNo */
  OldTmplMotherLineNo = 'OldTmplMotherLineNo',
  /** Aggregate result on OnlyonFirstOrderInvoice */
  OnlyonFirstOrderInvoice = 'OnlyonFirstOrderInvoice',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on ReturnDateBuffer */
  ReturnDateBuffer = 'ReturnDateBuffer',
  /** Aggregate result on OffRentDateBuffer */
  OffRentDateBuffer = 'OffRentDateBuffer',
  /** Aggregate result on ReturnQuantityBuffer */
  ReturnQuantityBuffer = 'ReturnQuantityBuffer',
  /** Aggregate result on OffRentQuantityBuffer */
  OffRentQuantityBuffer = 'OffRentQuantityBuffer',
  /** Aggregate result on DebitonReturnDateBuffer */
  DebitonReturnDateBuffer = 'DebitonReturnDateBuffer',
  /** Aggregate result on CounteronReturnBuffer */
  CounteronReturnBuffer = 'CounteronReturnBuffer',
  /** Aggregate result on ReturnReminderBuffer */
  ReturnReminderBuffer = 'ReturnReminderBuffer',
  /** Aggregate result on UpdateLineBuffer */
  UpdateLineBuffer = 'UpdateLineBuffer',
  /** Aggregate result on ReturnNoBuffer */
  ReturnNoBuffer = 'ReturnNoBuffer',
  /** Aggregate result on ReturnPoleSplitBuffer */
  ReturnPoleSplitBuffer = 'ReturnPoleSplitBuffer',
  /** Aggregate result on ReturnCompleteBuffer */
  ReturnCompleteBuffer = 'ReturnCompleteBuffer',
  /** Aggregate result on InvoicedToDateBuffer */
  InvoicedToDateBuffer = 'InvoicedToDateBuffer',
  /** Aggregate result on ReceiveLocationCodeBuffer */
  ReceiveLocationCodeBuffer = 'ReceiveLocationCodeBuffer',
  /** Aggregate result on ReturnTimeBuffer */
  ReturnTimeBuffer = 'ReturnTimeBuffer',
  /** Aggregate result on DepositAmountCardBuffer */
  DepositAmountCardBuffer = 'DepositAmountCardBuffer',
  /** Aggregate result on DepositAmountCashBuffer */
  DepositAmountCashBuffer = 'DepositAmountCashBuffer',
  /** Aggregate result on ReclassQuantityBuffer */
  ReclassQuantityBuffer = 'ReclassQuantityBuffer',
  /** Aggregate result on ReclassDateBuffer */
  ReclassDateBuffer = 'ReclassDateBuffer',
  /** Aggregate result on CorrFromDateBuffer */
  CorrFromDateBuffer = 'CorrFromDateBuffer',
  /** Aggregate result on CorrInvToDateBuffer */
  CorrInvToDateBuffer = 'CorrInvToDateBuffer',
  /** Aggregate result on CorrReturnDateBuffer */
  CorrReturnDateBuffer = 'CorrReturnDateBuffer',
  /** Aggregate result on CorrCloseRentalBuffer */
  CorrCloseRentalBuffer = 'CorrCloseRentalBuffer',
  /** Aggregate result on CorrCloseRentalBufferOPTION */
  CorrCloseRentalBufferOption = 'CorrCloseRentalBufferOPTION',
  /** Aggregate result on CorrUpdEntryBasisBuffer */
  CorrUpdEntryBasisBuffer = 'CorrUpdEntryBasisBuffer',
  /** Aggregate result on CorrQuantityBuffer */
  CorrQuantityBuffer = 'CorrQuantityBuffer',
  /** Aggregate result on CorrReturnQuantityBuffer */
  CorrReturnQuantityBuffer = 'CorrReturnQuantityBuffer',
  /** Aggregate result on ReclassFromContractBuffer */
  ReclassFromContractBuffer = 'ReclassFromContractBuffer',
  /** Aggregate result on ReclassToContractBuffer */
  ReclassToContractBuffer = 'ReclassToContractBuffer',
  /** Aggregate result on MaintenanceBuffer */
  MaintenanceBuffer = 'MaintenanceBuffer',
  /** Aggregate result on DepositAmountTnsfBuffer */
  DepositAmountTnsfBuffer = 'DepositAmountTnsfBuffer',
  /** Aggregate result on OffRentTimeBuffer */
  OffRentTimeBuffer = 'OffRentTimeBuffer',
  /** Aggregate result on OffRentNoBuffer */
  OffRentNoBuffer = 'OffRentNoBuffer',
  /** Aggregate result on CorrQtyShippedtoBuffer */
  CorrQtyShippedtoBuffer = 'CorrQtyShippedtoBuffer',
  /** Aggregate result on TempEntryBuffer */
  TempEntryBuffer = 'TempEntryBuffer',
  /** Aggregate result on ReturntoSellBuffer */
  ReturntoSellBuffer = 'ReturntoSellBuffer',
  /** Aggregate result on ReturntoDamageBuffer */
  ReturntoDamageBuffer = 'ReturntoDamageBuffer',
  /** Aggregate result on Counter2onReturnBuffer */
  Counter2onReturnBuffer = 'Counter2onReturnBuffer',
  /** Aggregate result on CorrOnRentDateBuffer */
  CorrOnRentDateBuffer = 'CorrOnRentDateBuffer',
  /** Aggregate result on CorrNextInvDateBuffer */
  CorrNextInvDateBuffer = 'CorrNextInvDateBuffer',
  /** Aggregate result on CollectionDocNoBuffer */
  CollectionDocNoBuffer = 'CollectionDocNoBuffer',
  /** Aggregate result on CollectionLineNoBuffer */
  CollectionLineNoBuffer = 'CollectionLineNoBuffer',
  /** Aggregate result on SourceCodeBuffer */
  SourceCodeBuffer = 'SourceCodeBuffer',
  /** Aggregate result on DeliverQuantityNowBuffer */
  DeliverQuantityNowBuffer = 'DeliverQuantityNowBuffer',
  /** Aggregate result on ShipmentDateBuffer */
  ShipmentDateBuffer = 'ShipmentDateBuffer',
  /** Aggregate result on OnRentDateBuffer */
  OnRentDateBuffer = 'OnRentDateBuffer',
  /** Aggregate result on LocationCodeBuffer */
  LocationCodeBuffer = 'LocationCodeBuffer',
  /** Aggregate result on ConsumptionatReturnBuffer */
  ConsumptionatReturnBuffer = 'ConsumptionatReturnBuffer',
  /** Aggregate result on CreditonEarlyBuffer */
  CreditonEarlyBuffer = 'CreditonEarlyBuffer',
  /** Aggregate result on StartRentalPeriodBuffer */
  StartRentalPeriodBuffer = 'StartRentalPeriodBuffer',
  /** Aggregate result on LineNoBuffer */
  LineNoBuffer = 'LineNoBuffer',
  /** Aggregate result on ConsumpatDeliveryBuffer */
  ConsumpatDeliveryBuffer = 'ConsumpatDeliveryBuffer',
  /** Aggregate result on DepositAmountBackBuffer */
  DepositAmountBackBuffer = 'DepositAmountBackBuffer',
  /** Aggregate result on DispatchTypeBuffer */
  DispatchTypeBuffer = 'DispatchTypeBuffer',
  /** Aggregate result on DispatchTypeBufferOPTION */
  DispatchTypeBufferOption = 'DispatchTypeBufferOPTION',
  /** Aggregate result on PstDispatchDocNoBuffer */
  PstDispatchDocNoBuffer = 'PstDispatchDocNoBuffer',
  /** Aggregate result on PstDispatchLineNoBuffer */
  PstDispatchLineNoBuffer = 'PstDispatchLineNoBuffer',
  /** Aggregate result on PostedInDocumentBuffer */
  PostedInDocumentBuffer = 'PostedInDocumentBuffer',
  /** Aggregate result on EQMServiceDocBuffer */
  EqmServiceDocBuffer = 'EQMServiceDocBuffer',
  /** Aggregate result on UnitPriceBuffer */
  UnitPriceBuffer = 'UnitPriceBuffer',
  /** Aggregate result on LineDiscountBuffer */
  LineDiscountBuffer = 'LineDiscountBuffer',
  /** Aggregate result on LineMarkedBuffer */
  LineMarkedBuffer = 'LineMarkedBuffer',
  /** Aggregate result on PostingDateBuffer */
  PostingDateBuffer = 'PostingDateBuffer',
  /** Aggregate result on UseInOpenPeriodBuffer */
  UseInOpenPeriodBuffer = 'UseInOpenPeriodBuffer',
  /** Aggregate result on AttachedtoLineNoBuffer */
  AttachedtoLineNoBuffer = 'AttachedtoLineNoBuffer',
  /** Aggregate result on CounteronShipmentBuffer */
  CounteronShipmentBuffer = 'CounteronShipmentBuffer',
  /** Aggregate result on Counter2onShipmentBuffer */
  Counter2onShipmentBuffer = 'Counter2onShipmentBuffer',
  /** Aggregate result on NoofRentFreeDaysBuffer */
  NoofRentFreeDaysBuffer = 'NoofRentFreeDaysBuffer',
  /** Aggregate result on NoofRentFreeHourBuffer */
  NoofRentFreeHourBuffer = 'NoofRentFreeHourBuffer',
  /** Aggregate result on ReasonforRentFreeBuffer */
  ReasonforRentFreeBuffer = 'ReasonforRentFreeBuffer',
  /** Aggregate result on ReceiveRespCenterBuffer */
  ReceiveRespCenterBuffer = 'ReceiveRespCenterBuffer',
  /** Aggregate result on FullyConsumedAccBuffer */
  FullyConsumedAccBuffer = 'FullyConsumedAccBuffer',
  /** Aggregate result on ReceivingBinCodeBuffer */
  ReceivingBinCodeBuffer = 'ReceivingBinCodeBuffer',
  /** Aggregate result on SuspendLineNoBuffer */
  SuspendLineNoBuffer = 'SuspendLineNoBuffer',
  /** Aggregate result on UnitPriceNew */
  UnitPriceNew = 'UnitPriceNew',
  /** Aggregate result on PriceTermCodeNew */
  PriceTermCodeNew = 'PriceTermCodeNew',
  /** Aggregate result on UnitPriceprDayNew */
  UnitPriceprDayNew = 'UnitPriceprDayNew',
  /** Aggregate result on UnitPriceprMonthNew */
  UnitPriceprMonthNew = 'UnitPriceprMonthNew',
  /** Aggregate result on LineAmountNew */
  LineAmountNew = 'LineAmountNew',
  /** Aggregate result on RentalPeriodNew */
  RentalPeriodNew = 'RentalPeriodNew',
  /** Aggregate result on LineDiscountNew */
  LineDiscountNew = 'LineDiscountNew',
  /** Aggregate result on LineDiscountAmountNew */
  LineDiscountAmountNew = 'LineDiscountAmountNew',
  /** Aggregate result on StairPriceCodeNew */
  StairPriceCodeNew = 'StairPriceCodeNew',
  /** Aggregate result on StairDiscountCodeNew */
  StairDiscountCodeNew = 'StairDiscountCodeNew',
  /** Aggregate result on BaseCalendarCodeNew */
  BaseCalendarCodeNew = 'BaseCalendarCodeNew',
  /** Aggregate result on StairPrice1New */
  StairPrice1New = 'StairPrice1New',
  /** Aggregate result on StairPrice2New */
  StairPrice2New = 'StairPrice2New',
  /** Aggregate result on StairPrice3New */
  StairPrice3New = 'StairPrice3New',
  /** Aggregate result on StairPrice4New */
  StairPrice4New = 'StairPrice4New',
  /** Aggregate result on StairPrice5New */
  StairPrice5New = 'StairPrice5New',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on Preliminary */
  Preliminary = 'Preliminary',
  /** Aggregate result on ReferenceTableID */
  ReferenceTableId = 'ReferenceTableID',
  /** Aggregate result on ReferenceUniqueID */
  ReferenceUniqueId = 'ReferenceUniqueID',
  /** Aggregate result on UniqueID */
  UniqueId = 'UniqueID',
  /** Aggregate result on CreatedTime */
  CreatedTime = 'CreatedTime',
  /** Aggregate result on ReplacementUnitPrice */
  ReplacementUnitPrice = 'ReplacementUnitPrice',
  /** Aggregate result on ReRentObject */
  ReRentObject = 'ReRentObject',
  /** Aggregate result on ReRentCost */
  ReRentCost = 'ReRentCost',
  /** Aggregate result on UnitPriceprDay */
  UnitPriceprDay = 'UnitPriceprDay',
  /** Aggregate result on UnitPriceprMonth */
  UnitPriceprMonth = 'UnitPriceprMonth',
  /** Aggregate result on AllocateObjectNo */
  AllocateObjectNo = 'AllocateObjectNo',
  /** Aggregate result on DepositOrigUnitPrice */
  DepositOrigUnitPrice = 'DepositOrigUnitPrice',
  /** Aggregate result on MarkDepositEntry */
  MarkDepositEntry = 'MarkDepositEntry',
  /** Aggregate result on DepositUnitPrice */
  DepositUnitPrice = 'DepositUnitPrice',
  /** Aggregate result on DepositPostingNo */
  DepositPostingNo = 'DepositPostingNo',
  /** Aggregate result on DepositAmount */
  DepositAmount = 'DepositAmount',
  /** Aggregate result on ReplacementPrice */
  ReplacementPrice = 'ReplacementPrice',
  /** Aggregate result on DepositReturnPostingNo */
  DepositReturnPostingNo = 'DepositReturnPostingNo',
  /** Aggregate result on AgreedToDate */
  AgreedToDate = 'AgreedToDate',
  /** Aggregate result on DepositPostingType */
  DepositPostingType = 'DepositPostingType',
  /** Aggregate result on DepositPostingTypeOPTION */
  DepositPostingTypeOption = 'DepositPostingTypeOPTION',
  /** Aggregate result on DontTransfertoOrder */
  DontTransfertoOrder = 'DontTransfertoOrder',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on DeliveredBy */
  DeliveredBy = 'DeliveredBy',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on SaleDocumentNo */
  SaleDocumentNo = 'SaleDocumentNo',
  /** Aggregate result on SaleDocumentType */
  SaleDocumentType = 'SaleDocumentType',
  /** Aggregate result on SaleDocumentTypeOPTION */
  SaleDocumentTypeOption = 'SaleDocumentTypeOPTION',
  /** Aggregate result on SaleLineNo */
  SaleLineNo = 'SaleLineNo',
  /** Aggregate result on SaleReserve */
  SaleReserve = 'SaleReserve',
  /** Aggregate result on SaleReserveOPTION */
  SaleReserveOption = 'SaleReserveOPTION',
  /** Aggregate result on ReturnQuantityHours */
  ReturnQuantityHours = 'ReturnQuantityHours',
  /** Aggregate result on ShipmentDateTime */
  ShipmentDateTime = 'ShipmentDateTime',
  /** Aggregate result on EndRentalDateTime */
  EndRentalDateTime = 'EndRentalDateTime',
  /** Aggregate result on ExpectedReturnDate */
  ExpectedReturnDate = 'ExpectedReturnDate',
  /** Aggregate result on DoShipmentNow */
  DoShipmentNow = 'DoShipmentNow',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on QtyHours */
  QtyHours = 'QtyHours',
  /** Aggregate result on OnRentTime */
  OnRentTime = 'OnRentTime',
  /** Aggregate result on OffRentTime */
  OffRentTime = 'OffRentTime',
  /** Aggregate result on OnRentDateTime */
  OnRentDateTime = 'OnRentDateTime',
  /** Aggregate result on OffRentDateTime */
  OffRentDateTime = 'OffRentDateTime',
  /** Aggregate result on ReturnQuantityChargeHours */
  ReturnQuantityChargeHours = 'ReturnQuantityChargeHours',
  /** Aggregate result on TerminPeriod */
  TerminPeriod = 'TerminPeriod',
  /** Aggregate result on LineAmountPeriod */
  LineAmountPeriod = 'LineAmountPeriod',
  /** Aggregate result on IgnorCalendarCheck */
  IgnorCalendarCheck = 'IgnorCalendarCheck',
  /** Aggregate result on MeasurementatDelivery */
  MeasurementatDelivery = 'MeasurementatDelivery',
  /** Aggregate result on MeasurementatReturn */
  MeasurementatReturn = 'MeasurementatReturn',
  /** Aggregate result on DiamondMotherLineNo */
  DiamondMotherLineNo = 'DiamondMotherLineNo',
  /** Aggregate result on DiamondBladeMotherRef */
  DiamondBladeMotherRef = 'DiamondBladeMotherRef',
  /** Aggregate result on ReturnAdditionMotherRef */
  ReturnAdditionMotherRef = 'ReturnAdditionMotherRef',
  /** Aggregate result on StairPriceCode */
  StairPriceCode = 'StairPriceCode',
  /** Aggregate result on ExtraHoursReturnTime */
  ExtraHoursReturnTime = 'ExtraHoursReturnTime',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on TmpAccumEntry */
  TmpAccumEntry = 'TmpAccumEntry',
  /** Aggregate result on MarkReclassLineNoRef */
  MarkReclassLineNoRef = 'MarkReclassLineNoRef',
  /** Aggregate result on MarkReclassQuantity */
  MarkReclassQuantity = 'MarkReclassQuantity',
  /** Aggregate result on PostingPriority */
  PostingPriority = 'PostingPriority',
  /** Aggregate result on PostingPriorityOPTION */
  PostingPriorityOption = 'PostingPriorityOPTION',
  /** Aggregate result on ReclassFromLocationCode */
  ReclassFromLocationCode = 'ReclassFromLocationCode',
  /** Aggregate result on ReclassOrderedBy */
  ReclassOrderedBy = 'ReclassOrderedBy',
  /** Aggregate result on MarkReclassLine */
  MarkReclassLine = 'MarkReclassLine',
  /** Aggregate result on FilterReclassLine */
  FilterReclassLine = 'FilterReclassLine',
  /** Aggregate result on ReclassFromContractNo */
  ReclassFromContractNo = 'ReclassFromContractNo',
  /** Aggregate result on ReclassToContractNo */
  ReclassToContractNo = 'ReclassToContractNo',
  /** Aggregate result on ReclassToLocationCode */
  ReclassToLocationCode = 'ReclassToLocationCode',
  /** Aggregate result on ReclassOrderNo */
  ReclassOrderNo = 'ReclassOrderNo',
  /** Aggregate result on ReclassDocNo */
  ReclassDocNo = 'ReclassDocNo',
  /** Aggregate result on WorkshopObjectNo */
  WorkshopObjectNo = 'WorkshopObjectNo',
  /** Aggregate result on DiamondBladeItem */
  DiamondBladeItem = 'DiamondBladeItem',
  /** Aggregate result on DiamondBladeItemOPTION */
  DiamondBladeItemOption = 'DiamondBladeItemOPTION',
  /** Aggregate result on IgnoreVaryingCompQty */
  IgnoreVaryingCompQty = 'IgnoreVaryingCompQty',
  /** Aggregate result on ExploreLine */
  ExploreLine = 'ExploreLine',
  /** Aggregate result on EnvironmentalFee */
  EnvironmentalFee = 'EnvironmentalFee',
  /** Aggregate result on ExtraHoursCharged */
  ExtraHoursCharged = 'ExtraHoursCharged',
  /** Aggregate result on ExtraHoursReturnNo */
  ExtraHoursReturnNo = 'ExtraHoursReturnNo',
  /** Aggregate result on PriceonExtraHours */
  PriceonExtraHours = 'PriceonExtraHours',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on ReclassDocLineNo */
  ReclassDocLineNo = 'ReclassDocLineNo',
  /** Aggregate result on RentalSplitCostCenter */
  RentalSplitCostCenter = 'RentalSplitCostCenter',
  /** Aggregate result on RentalSplit */
  RentalSplit = 'RentalSplit',
  /** Aggregate result on PricePriority */
  PricePriority = 'PricePriority',
  /** Aggregate result on PricePriorityOPTION */
  PricePriorityOption = 'PricePriorityOPTION',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on TotalNetWeight */
  TotalNetWeight = 'TotalNetWeight',
  /** Aggregate result on RecursiveLevel */
  RecursiveLevel = 'RecursiveLevel',
  /** Aggregate result on Repurchase */
  Repurchase = 'Repurchase',
  /** Aggregate result on PriceScheduleCode */
  PriceScheduleCode = 'PriceScheduleCode',
  /** Aggregate result on CPNextInvoiceAmount */
  CpNextInvoiceAmount = 'CPNextInvoiceAmount',
  /** Aggregate result on CPAdvancePaymentStatus */
  CpAdvancePaymentStatus = 'CPAdvancePaymentStatus',
  /** Aggregate result on CPAdvancePaymentStatusOPTION */
  CpAdvancePaymentStatusOption = 'CPAdvancePaymentStatusOPTION',
  /** Aggregate result on CPAdvancePaymentDocNo */
  CpAdvancePaymentDocNo = 'CPAdvancePaymentDocNo',
  /** Aggregate result on InvoiceFee */
  InvoiceFee = 'InvoiceFee',
  /** Aggregate result on StairDiscountCode */
  StairDiscountCode = 'StairDiscountCode',
  /** Aggregate result on StairPriceDiscount */
  StairPriceDiscount = 'StairPriceDiscount',
  /** Aggregate result on ReceiveRespCenter */
  ReceiveRespCenter = 'ReceiveRespCenter',
  /** Aggregate result on ReceiveCostCenter */
  ReceiveCostCenter = 'ReceiveCostCenter',
  /** Aggregate result on AllowLineDisconExHours */
  AllowLineDisconExHours = 'AllowLineDisconExHours',
  /** Aggregate result on QuantitytoAllocate */
  QuantitytoAllocate = 'QuantitytoAllocate',
  /** Aggregate result on ServiceMotherLineNo */
  ServiceMotherLineNo = 'ServiceMotherLineNo',
  /** Aggregate result on AdditionalCharge */
  AdditionalCharge = 'AdditionalCharge',
  /** Aggregate result on AdjforAdditionalCharge */
  AdjforAdditionalCharge = 'AdjforAdditionalCharge',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on EQMServiceNoTemp */
  EqmServiceNoTemp = 'EQMServiceNoTemp',
  /** Aggregate result on PriceReportedHours */
  PriceReportedHours = 'PriceReportedHours',
  /** Aggregate result on FreeRentalHours */
  FreeRentalHours = 'FreeRentalHours',
  /** Aggregate result on DispatchTask */
  DispatchTask = 'DispatchTask',
  /** Aggregate result on DispatchTaskOPTION */
  DispatchTaskOption = 'DispatchTaskOPTION',
  /** Aggregate result on DispatchStatus */
  DispatchStatus = 'DispatchStatus',
  /** Aggregate result on DispatchStatusOPTION */
  DispatchStatusOption = 'DispatchStatusOPTION',
  /** Aggregate result on ScheduledDeliveryDate */
  ScheduledDeliveryDate = 'ScheduledDeliveryDate',
  /** Aggregate result on ScheduledDeliveryTime */
  ScheduledDeliveryTime = 'ScheduledDeliveryTime',
  /** Aggregate result on ScheduledPickupDate */
  ScheduledPickupDate = 'ScheduledPickupDate',
  /** Aggregate result on ScheduledPickupTime */
  ScheduledPickupTime = 'ScheduledPickupTime',
  /** Aggregate result on DeliveryDriver */
  DeliveryDriver = 'DeliveryDriver',
  /** Aggregate result on PickupDriver */
  PickupDriver = 'PickupDriver',
  /** Aggregate result on Driver */
  Driver = 'Driver',
  /** Aggregate result on SchedDate */
  SchedDate = 'SchedDate',
  /** Aggregate result on ActualDate */
  ActualDate = 'ActualDate',
  /** Aggregate result on SchedTime */
  SchedTime = 'SchedTime',
  /** Aggregate result on ActualTime */
  ActualTime = 'ActualTime',
  /** Aggregate result on ActualDeliveryDate */
  ActualDeliveryDate = 'ActualDeliveryDate',
  /** Aggregate result on ActualDeliveryTime */
  ActualDeliveryTime = 'ActualDeliveryTime',
  /** Aggregate result on ActualPickupDate */
  ActualPickupDate = 'ActualPickupDate',
  /** Aggregate result on ActualPickupTime */
  ActualPickupTime = 'ActualPickupTime',
  /** Aggregate result on ActualReturnDate */
  ActualReturnDate = 'ActualReturnDate',
  /** Aggregate result on ActualReturnTime */
  ActualReturnTime = 'ActualReturnTime',
  /** Aggregate result on MinimumReportedHours */
  MinimumReportedHours = 'MinimumReportedHours',
  /** Aggregate result on MinRepHoursPeriod */
  MinRepHoursPeriod = 'MinRepHoursPeriod',
  /** Aggregate result on StairPrice1 */
  StairPrice1 = 'StairPrice1',
  /** Aggregate result on StairPrice2 */
  StairPrice2 = 'StairPrice2',
  /** Aggregate result on StairPrice3 */
  StairPrice3 = 'StairPrice3',
  /** Aggregate result on DispatchDocType */
  DispatchDocType = 'DispatchDocType',
  /** Aggregate result on DispatchDocTypeOPTION */
  DispatchDocTypeOption = 'DispatchDocTypeOPTION',
  /** Aggregate result on DispatchDocNo */
  DispatchDocNo = 'DispatchDocNo',
  /** Aggregate result on DispatchLineNo */
  DispatchLineNo = 'DispatchLineNo',
  /** Aggregate result on StairPrice4 */
  StairPrice4 = 'StairPrice4',
  /** Aggregate result on StairPrice5 */
  StairPrice5 = 'StairPrice5',
  /** Aggregate result on MinimumRentalPeriod */
  MinimumRentalPeriod = 'MinimumRentalPeriod',
  /** Aggregate result on MinimumRentaltoDate */
  MinimumRentaltoDate = 'MinimumRentaltoDate',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on UserFilter */
  UserFilter = 'UserFilter',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DefaultKITPriceCompFlow */
  DefaultKitPriceCompFlow = 'DefaultKITPriceCompFlow',
  /** Aggregate result on AssignedtoKIT */
  AssignedtoKit = 'AssignedtoKIT',
  /** Aggregate result on OffRentDateFlow */
  OffRentDateFlow = 'OffRentDateFlow',
  /** Aggregate result on ReturnDateFlow */
  ReturnDateFlow = 'ReturnDateFlow',
  /** Aggregate result on QuantityShippedtoFlow */
  QuantityShippedtoFlow = 'QuantityShippedtoFlow',
  /** Aggregate result on QuantityReturnedFlow */
  QuantityReturnedFlow = 'QuantityReturnedFlow',
  /** Aggregate result on QuantityOffRentedFlow */
  QuantityOffRentedFlow = 'QuantityOffRentedFlow',
  /** Aggregate result on QuantityPstOffRentedFlow */
  QuantityPstOffRentedFlow = 'QuantityPstOffRentedFlow',
  /** Aggregate result on QuantityPstReturnedFlow */
  QuantityPstReturnedFlow = 'QuantityPstReturnedFlow',
  /** Aggregate result on QuantityPstShippedFlow */
  QuantityPstShippedFlow = 'QuantityPstShippedFlow',
  /** Aggregate result on QtyonPickOrder */
  QtyonPickOrder = 'QtyonPickOrder',
  /** Aggregate result on QtyonShipmentOrder */
  QtyonShipmentOrder = 'QtyonShipmentOrder',
  /** Aggregate result on QtyonCollectionOrder */
  QtyonCollectionOrder = 'QtyonCollectionOrder',
  /** Aggregate result on UserLocationFilter */
  UserLocationFilter = 'UserLocationFilter',
  /** Aggregate result on NoofWorkOrderLines */
  NoofWorkOrderLines = 'NoofWorkOrderLines',
  /** Aggregate result on NoofPickOrderLines */
  NoofPickOrderLines = 'NoofPickOrderLines',
  /** Aggregate result on NoofShipmentOrderLines */
  NoofShipmentOrderLines = 'NoofShipmentOrderLines',
  /** Aggregate result on NoofCollectionOrderLines */
  NoofCollectionOrderLines = 'NoofCollectionOrderLines',
  /** Aggregate result on NoofPostedPickOrderLines */
  NoofPostedPickOrderLines = 'NoofPostedPickOrderLines',
  /** Aggregate result on NoofPstdShptOrderLines */
  NoofPstdShptOrderLines = 'NoofPstdShptOrderLines',
  /** Aggregate result on NoofPstdCollOrderLines */
  NoofPstdCollOrderLines = 'NoofPstdCollOrderLines',
  /** Aggregate result on NoofRntlReservSummaries */
  NoofRntlReservSummaries = 'NoofRntlReservSummaries',
  /** Aggregate result on NoofRntlReservEntries */
  NoofRntlReservEntries = 'NoofRntlReservEntries',
  /** Aggregate result on QtyonRntlReservEntry */
  QtyonRntlReservEntry = 'QtyonRntlReservEntry',
  /** Aggregate result on QtyonRntlResShptEntry */
  QtyonRntlResShptEntry = 'QtyonRntlResShptEntry',
  /** Aggregate result on QtyonRntlResRetEntry */
  QtyonRntlResRetEntry = 'QtyonRntlResRetEntry',
  /** Aggregate result on QtyonBufferEntry */
  QtyonBufferEntry = 'QtyonBufferEntry',
  /** Aggregate result on MultipleRentalPeriodPrice */
  MultipleRentalPeriodPrice = 'MultipleRentalPeriodPrice',
  /** Aggregate result on MultipleRentalPeriodEntry */
  MultipleRentalPeriodEntry = 'MultipleRentalPeriodEntry',
  /** Aggregate result on LastErrorText */
  LastErrorText = 'LastErrorText',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on SaleReservedQuantity */
  SaleReservedQuantity = 'SaleReservedQuantity',
  /** Aggregate result on SaleReservedQtyBase */
  SaleReservedQtyBase = 'SaleReservedQtyBase',
  /** Aggregate result on KITNetWeight */
  KitNetWeight = 'KITNetWeight',
  /** Aggregate result on ReportedRentalHours */
  ReportedRentalHours = 'ReportedRentalHours',
  /** Aggregate result on EQMServiceDocNo */
  EqmServiceDocNo = 'EQMServiceDocNo',
  /** Aggregate result on DispatchDocument */
  DispatchDocument = 'DispatchDocument',
  /** Aggregate result on DispatchDocumentNo */
  DispatchDocumentNo = 'DispatchDocumentNo',
  /** Aggregate result on GroupOnNumber */
  GroupOnNumber = 'GroupOnNumber',
  /** Aggregate result on PeriodLineAmountEdge */
  PeriodLineAmountEdge = 'PeriodLineAmountEdge',
  /** Aggregate result on RentalDays */
  RentalDays = 'RentalDays',
  /** Aggregate result on RemainingQty */
  RemainingQty = 'RemainingQty',
  /** Aggregate result on FreeDays */
  FreeDays = 'FreeDays',
  /** Aggregate result on DayNetPriceEdge */
  DayNetPriceEdge = 'DayNetPriceEdge',
  /** Aggregate result on Vaccation */
  Vaccation = 'Vaccation',
  /** Aggregate result on Tags */
  Tags = 'Tags',
  /** Aggregate result on ReturnReservationActive */
  ReturnReservationActive = 'ReturnReservationActive',
  /** Aggregate result on UserExpectedReturnDate */
  UserExpectedReturnDate = 'UserExpectedReturnDate',
  /** Aggregate result on ImageUrl */
  ImageUrl = 'ImageUrl',
  /** Aggregate result on PSILink */
  PsiLink = 'PSILink',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt',
  /** Aggregate result on SnapshotOn */
  SnapshotOn = 'SnapshotOn'
}

export enum AggregateRentalPostedCollHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ResourceNo */
  ResourceNo = 'ResourceNo',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ShippingHour */
  ShippingHour = 'ShippingHour',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on PickDate */
  PickDate = 'PickDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on OffRentDate */
  OffRentDate = 'OffRentDate',
  /** Aggregate result on ReturnDate */
  ReturnDate = 'ReturnDate',
  /** Aggregate result on CollectionStatus */
  CollectionStatus = 'CollectionStatus',
  /** Aggregate result on CollectionStatusOPTION */
  CollectionStatusOption = 'CollectionStatusOPTION',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on ReceivingLocationCode */
  ReceivingLocationCode = 'ReceivingLocationCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderDocumentType */
  OrderDocumentType = 'OrderDocumentType',
  /** Aggregate result on OrderDocumentTypeOPTION */
  OrderDocumentTypeOption = 'OrderDocumentTypeOPTION',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on PurchaseOrderNo */
  PurchaseOrderNo = 'PurchaseOrderNo',
  /** Aggregate result on Requestedby */
  Requestedby = 'Requestedby',
  /** Aggregate result on Receivedby */
  Receivedby = 'Receivedby',
  /** Aggregate result on ResponsibilityCenterCode */
  ResponsibilityCenterCode = 'ResponsibilityCenterCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on ShiptoPhoneNo */
  ShiptoPhoneNo = 'ShiptoPhoneNo',
  /** Aggregate result on ShiptoMobilePhoneNo */
  ShiptoMobilePhoneNo = 'ShiptoMobilePhoneNo',
  /** Aggregate result on Broker */
  Broker = 'Broker',
  /** Aggregate result on Carrier */
  Carrier = 'Carrier',
  /** Aggregate result on Driver */
  Driver = 'Driver',
  /** Aggregate result on BOLSignature */
  BolSignature = 'BOLSignature',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalPostedCollLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ExtRefNo */
  ExtRefNo = 'ExtRefNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ResourceNo */
  ResourceNo = 'ResourceNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on CustomerSubProject */
  CustomerSubProject = 'CustomerSubProject',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on PriceTerm */
  PriceTerm = 'PriceTerm',
  /** Aggregate result on BaseCalendar */
  BaseCalendar = 'BaseCalendar',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on RentalPeriod */
  RentalPeriod = 'RentalPeriod',
  /** Aggregate result on LoadSequenceNo */
  LoadSequenceNo = 'LoadSequenceNo',
  /** Aggregate result on PriceStairNo */
  PriceStairNo = 'PriceStairNo',
  /** Aggregate result on StairDiscountCode */
  StairDiscountCode = 'StairDiscountCode',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on PickDate */
  PickDate = 'PickDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on OffRentDate */
  OffRentDate = 'OffRentDate',
  /** Aggregate result on ReturnDate */
  ReturnDate = 'ReturnDate',
  /** Aggregate result on OffRentUpdated */
  OffRentUpdated = 'OffRentUpdated',
  /** Aggregate result on ReturnTime */
  ReturnTime = 'ReturnTime',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on QuantitySold */
  QuantitySold = 'QuantitySold',
  /** Aggregate result on QuantityDamaged */
  QuantityDamaged = 'QuantityDamaged',
  /** Aggregate result on QuantityOffRented */
  QuantityOffRented = 'QuantityOffRented',
  /** Aggregate result on ReceivingLocationCode */
  ReceivingLocationCode = 'ReceivingLocationCode',
  /** Aggregate result on CounteronShipment */
  CounteronShipment = 'CounteronShipment',
  /** Aggregate result on CounteronReturn */
  CounteronReturn = 'CounteronReturn',
  /** Aggregate result on Maintenance */
  Maintenance = 'Maintenance',
  /** Aggregate result on EQMServiceDoc */
  EqmServiceDoc = 'EQMServiceDoc',
  /** Aggregate result on EQMServiceID */
  EqmServiceId = 'EQMServiceID',
  /** Aggregate result on Counter2onShipment */
  Counter2onShipment = 'Counter2onShipment',
  /** Aggregate result on Counter2onReturn */
  Counter2onReturn = 'Counter2onReturn',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on MotherLineNo */
  MotherLineNo = 'MotherLineNo',
  /** Aggregate result on DiamondBladeItem */
  DiamondBladeItem = 'DiamondBladeItem',
  /** Aggregate result on DiamondBladeItemOPTION */
  DiamondBladeItemOption = 'DiamondBladeItemOPTION',
  /** Aggregate result on MeasurementatDelivery */
  MeasurementatDelivery = 'MeasurementatDelivery',
  /** Aggregate result on MeasurementatReturn */
  MeasurementatReturn = 'MeasurementatReturn',
  /** Aggregate result on FullyConsumedAccessory */
  FullyConsumedAccessory = 'FullyConsumedAccessory',
  /** Aggregate result on ScannedBy */
  ScannedBy = 'ScannedBy',
  /** Aggregate result on ScannedDate */
  ScannedDate = 'ScannedDate',
  /** Aggregate result on ScannedTime */
  ScannedTime = 'ScannedTime',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on NumberofRentFreeDays */
  NumberofRentFreeDays = 'NumberofRentFreeDays',
  /** Aggregate result on NumberofRentFreeHours */
  NumberofRentFreeHours = 'NumberofRentFreeHours',
  /** Aggregate result on ReasonforRentFree */
  ReasonforRentFree = 'ReasonforRentFree',
  /** Aggregate result on CreditonEarlyOffRent */
  CreditonEarlyOffRent = 'CreditonEarlyOffRent',
  /** Aggregate result on PriceonExtraHours */
  PriceonExtraHours = 'PriceonExtraHours',
  /** Aggregate result on PstDeliveryEntryNo */
  PstDeliveryEntryNo = 'PstDeliveryEntryNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on ReceivingBinCode */
  ReceivingBinCode = 'ReceivingBinCode',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on AppliedfromDeliverEntryNo */
  AppliedfromDeliverEntryNo = 'AppliedfromDeliverEntryNo',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on Level */
  Level = 'Level',
  /** Aggregate result on LineType */
  LineType = 'LineType',
  /** Aggregate result on LineTypeOPTION */
  LineTypeOption = 'LineTypeOPTION',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on PriceReportedHours */
  PriceReportedHours = 'PriceReportedHours',
  /** Aggregate result on FreeRentalHours */
  FreeRentalHours = 'FreeRentalHours',
  /** Aggregate result on MinimumReportedHours */
  MinimumReportedHours = 'MinimumReportedHours',
  /** Aggregate result on MinRepHoursPeriod */
  MinRepHoursPeriod = 'MinRepHoursPeriod',
  /** Aggregate result on StairPrice1 */
  StairPrice1 = 'StairPrice1',
  /** Aggregate result on StairPrice2 */
  StairPrice2 = 'StairPrice2',
  /** Aggregate result on StairPrice3 */
  StairPrice3 = 'StairPrice3',
  /** Aggregate result on StairPrice4 */
  StairPrice4 = 'StairPrice4',
  /** Aggregate result on StairPrice5 */
  StairPrice5 = 'StairPrice5',
  /** Aggregate result on MinimumRentalPeriod */
  MinimumRentalPeriod = 'MinimumRentalPeriod',
  /** Aggregate result on MinimumRentaltoDate */
  MinimumRentaltoDate = 'MinimumRentaltoDate',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalPostedShptHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ResourceNo */
  ResourceNo = 'ResourceNo',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on ShippingHour */
  ShippingHour = 'ShippingHour',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on PickDate */
  PickDate = 'PickDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderDocumentType */
  OrderDocumentType = 'OrderDocumentType',
  /** Aggregate result on OrderDocumentTypeOPTION */
  OrderDocumentTypeOption = 'OrderDocumentTypeOPTION',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on PurchaseOrderNo */
  PurchaseOrderNo = 'PurchaseOrderNo',
  /** Aggregate result on ResponsibilityCenterCode */
  ResponsibilityCenterCode = 'ResponsibilityCenterCode',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on ShiptoPhoneNo */
  ShiptoPhoneNo = 'ShiptoPhoneNo',
  /** Aggregate result on ShiptoMobilePhoneNo */
  ShiptoMobilePhoneNo = 'ShiptoMobilePhoneNo',
  /** Aggregate result on Broker */
  Broker = 'Broker',
  /** Aggregate result on Carrier */
  Carrier = 'Carrier',
  /** Aggregate result on Driver */
  Driver = 'Driver',
  /** Aggregate result on BOLSignature */
  BolSignature = 'BOLSignature',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalPostedShptLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on ExtRefNo */
  ExtRefNo = 'ExtRefNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ResourceNo */
  ResourceNo = 'ResourceNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on CustomerSubProject */
  CustomerSubProject = 'CustomerSubProject',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on PriceTerm */
  PriceTerm = 'PriceTerm',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on RentalPeriod */
  RentalPeriod = 'RentalPeriod',
  /** Aggregate result on LoadSequenceNo */
  LoadSequenceNo = 'LoadSequenceNo',
  /** Aggregate result on StairPriceCode */
  StairPriceCode = 'StairPriceCode',
  /** Aggregate result on StairDiscountCode */
  StairDiscountCode = 'StairDiscountCode',
  /** Aggregate result on TransId */
  TransId = 'TransId',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on PickDate */
  PickDate = 'PickDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on ExpirationDate */
  ExpirationDate = 'ExpirationDate',
  /** Aggregate result on CounteronShipment */
  CounteronShipment = 'CounteronShipment',
  /** Aggregate result on CounteronReturn */
  CounteronReturn = 'CounteronReturn',
  /** Aggregate result on EQMServiceDoc */
  EqmServiceDoc = 'EQMServiceDoc',
  /** Aggregate result on Counter2onShipment */
  Counter2onShipment = 'Counter2onShipment',
  /** Aggregate result on Counter2onReturn */
  Counter2onReturn = 'Counter2onReturn',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on MotherLineNo */
  MotherLineNo = 'MotherLineNo',
  /** Aggregate result on DiamondBladeItem */
  DiamondBladeItem = 'DiamondBladeItem',
  /** Aggregate result on DiamondBladeItemOPTION */
  DiamondBladeItemOption = 'DiamondBladeItemOPTION',
  /** Aggregate result on MeasurementatDelivery */
  MeasurementatDelivery = 'MeasurementatDelivery',
  /** Aggregate result on ScannedBy */
  ScannedBy = 'ScannedBy',
  /** Aggregate result on ScannedDate */
  ScannedDate = 'ScannedDate',
  /** Aggregate result on ScannedTime */
  ScannedTime = 'ScannedTime',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on OrderDocNo */
  OrderDocNo = 'OrderDocNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on QuantityCollected */
  QuantityCollected = 'QuantityCollected',
  /** Aggregate result on CollectionDocNo */
  CollectionDocNo = 'CollectionDocNo',
  /** Aggregate result on CollectionLineNo */
  CollectionLineNo = 'CollectionLineNo',
  /** Aggregate result on RentalInsurance */
  RentalInsurance = 'RentalInsurance',
  /** Aggregate result on PriceReportedHours */
  PriceReportedHours = 'PriceReportedHours',
  /** Aggregate result on FreeRentalHours */
  FreeRentalHours = 'FreeRentalHours',
  /** Aggregate result on MinimumReportedHours */
  MinimumReportedHours = 'MinimumReportedHours',
  /** Aggregate result on MinRepHoursPeriod */
  MinRepHoursPeriod = 'MinRepHoursPeriod',
  /** Aggregate result on StairPrice1 */
  StairPrice1 = 'StairPrice1',
  /** Aggregate result on StairPrice2 */
  StairPrice2 = 'StairPrice2',
  /** Aggregate result on StairPrice3 */
  StairPrice3 = 'StairPrice3',
  /** Aggregate result on StairPrice4 */
  StairPrice4 = 'StairPrice4',
  /** Aggregate result on StairPrice5 */
  StairPrice5 = 'StairPrice5',
  /** Aggregate result on MinimumRentalPeriod */
  MinimumRentalPeriod = 'MinimumRentalPeriod',
  /** Aggregate result on MinimumRentaltoDate */
  MinimumRentaltoDate = 'MinimumRentaltoDate',
  /** Aggregate result on UseAdvancedMgtCalendar */
  UseAdvancedMgtCalendar = 'UseAdvancedMgtCalendar',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalPriceTermsInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on ChargeFullPeriod */
  ChargeFullPeriod = 'ChargeFullPeriod',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on ReportedHours */
  ReportedHours = 'ReportedHours',
  /** Aggregate result on FixedDays */
  FixedDays = 'FixedDays',
  /** Aggregate result on SquareMeter */
  SquareMeter = 'SquareMeter',
  /** Aggregate result on FullTerm */
  FullTerm = 'FullTerm',
  /** Aggregate result on HalfTerm */
  HalfTerm = 'HalfTerm',
  /** Aggregate result on FixedBillingPeriod */
  FixedBillingPeriod = 'FixedBillingPeriod',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRentalReturnEntryInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on ReturnNo */
  ReturnNo = 'ReturnNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on ExtRentalLineNo */
  ExtRentalLineNo = 'ExtRentalLineNo',
  /** Aggregate result on EntryType */
  EntryType = 'EntryType',
  /** Aggregate result on EntryTypeOPTION */
  EntryTypeOption = 'EntryTypeOPTION',
  /** Aggregate result on ReturnType */
  ReturnType = 'ReturnType',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on PostedinDocument */
  PostedinDocument = 'PostedinDocument',
  /** Aggregate result on PostingTime */
  PostingTime = 'PostingTime',
  /** Aggregate result on TransferredtoOrder */
  TransferredtoOrder = 'TransferredtoOrder',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SerialNo */
  SerialNo = 'SerialNo',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on StartRentalPeriod */
  StartRentalPeriod = 'StartRentalPeriod',
  /** Aggregate result on OnRentDate */
  OnRentDate = 'OnRentDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on InvoicedToDate */
  InvoicedToDate = 'InvoicedToDate',
  /** Aggregate result on OffRentDate */
  OffRentDate = 'OffRentDate',
  /** Aggregate result on OffRentTime */
  OffRentTime = 'OffRentTime',
  /** Aggregate result on ReturnDate */
  ReturnDate = 'ReturnDate',
  /** Aggregate result on ReturnTime */
  ReturnTime = 'ReturnTime',
  /** Aggregate result on DebitonReturnDate */
  DebitonReturnDate = 'DebitonReturnDate',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on RemainingQuantity */
  RemainingQuantity = 'RemainingQuantity',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on TransactionNo */
  TransactionNo = 'TransactionNo',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ReceivingLocationCode */
  ReceivingLocationCode = 'ReceivingLocationCode',
  /** Aggregate result on UndoEntry */
  UndoEntry = 'UndoEntry',
  /** Aggregate result on DispatchType */
  DispatchType = 'DispatchType',
  /** Aggregate result on PstDispatchDocNo */
  PstDispatchDocNo = 'PstDispatchDocNo',
  /** Aggregate result on PstDispatchLineNo */
  PstDispatchLineNo = 'PstDispatchLineNo',
  /** Aggregate result on OrderDocNo */
  OrderDocNo = 'OrderDocNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on DoPostReturn */
  DoPostReturn = 'DoPostReturn',
  /** Aggregate result on DoPostOffRent */
  DoPostOffRent = 'DoPostOffRent',
  /** Aggregate result on OrigOffRentDate */
  OrigOffRentDate = 'OrigOffRentDate',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on KITDontShow */
  KitDontShow = 'KITDontShow',
  /** Aggregate result on KITMotherLineNo */
  KitMotherLineNo = 'KITMotherLineNo',
  /** Aggregate result on NumberofRentFreeDays */
  NumberofRentFreeDays = 'NumberofRentFreeDays',
  /** Aggregate result on NumberofRentFreeHours */
  NumberofRentFreeHours = 'NumberofRentFreeHours',
  /** Aggregate result on ReasonforRentFree */
  ReasonforRentFree = 'ReasonforRentFree',
  /** Aggregate result on CreditonEarlyReturn */
  CreditonEarlyReturn = 'CreditonEarlyReturn',
  /** Aggregate result on QuantitySold */
  QuantitySold = 'QuantitySold',
  /** Aggregate result on QuantityDamaged */
  QuantityDamaged = 'QuantityDamaged',
  /** Aggregate result on CounteronReturn */
  CounteronReturn = 'CounteronReturn',
  /** Aggregate result on Maintenance */
  Maintenance = 'Maintenance',
  /** Aggregate result on CounteronShipment */
  CounteronShipment = 'CounteronShipment',
  /** Aggregate result on Counter2onReturn */
  Counter2onReturn = 'Counter2onReturn',
  /** Aggregate result on Counter2onShipment */
  Counter2onShipment = 'Counter2onShipment',
  /** Aggregate result on ReturnQuantityTime */
  ReturnQuantityTime = 'ReturnQuantityTime',
  /** Aggregate result on TmpLocationCode */
  TmpLocationCode = 'TmpLocationCode',
  /** Aggregate result on AppliestoEntry */
  AppliestoEntry = 'AppliestoEntry',
  /** Aggregate result on CompleteReturn */
  CompleteReturn = 'CompleteReturn',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ChargeCustomer */
  ChargeCustomer = 'ChargeCustomer',
  /** Aggregate result on MeasurementatDelivery */
  MeasurementatDelivery = 'MeasurementatDelivery',
  /** Aggregate result on MeasurementatReturn */
  MeasurementatReturn = 'MeasurementatReturn',
  /** Aggregate result on DiamondBladeItem */
  DiamondBladeItem = 'DiamondBladeItem',
  /** Aggregate result on FullyConsumedAccessory */
  FullyConsumedAccessory = 'FullyConsumedAccessory',
  /** Aggregate result on FullReturnSale */
  FullReturnSale = 'FullReturnSale',
  /** Aggregate result on RentalSale */
  RentalSale = 'RentalSale',
  /** Aggregate result on QtyReserved */
  QtyReserved = 'QtyReserved',
  /** Aggregate result on QtytoCollect */
  QtytoCollect = 'QtytoCollect',
  /** Aggregate result on QuantityReturned */
  QuantityReturned = 'QuantityReturned',
  /** Aggregate result on HourlyRent */
  HourlyRent = 'HourlyRent',
  /** Aggregate result on QtyHours */
  QtyHours = 'QtyHours',
  /** Aggregate result on ShipmentTime */
  ShipmentTime = 'ShipmentTime',
  /** Aggregate result on OnRentTime */
  OnRentTime = 'OnRentTime',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on ReceivingBinCode */
  ReceivingBinCode = 'ReceivingBinCode',
  /** Aggregate result on PriceonExtraHours */
  PriceonExtraHours = 'PriceonExtraHours',
  /** Aggregate result on KITComponent */
  KitComponent = 'KITComponent',
  /** Aggregate result on EQMServiceDoc */
  EqmServiceDoc = 'EQMServiceDoc',
  /** Aggregate result on IgnoreVaryingCompQty */
  IgnoreVaryingCompQty = 'IgnoreVaryingCompQty',
  /** Aggregate result on QtyPstCollectedFlow */
  QtyPstCollectedFlow = 'QtyPstCollectedFlow',
  /** Aggregate result on PstdCollOrderDocNo */
  PstdCollOrderDocNo = 'PstdCollOrderDocNo',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceTermCode */
  PriceTermCode = 'PriceTermCode',
  /** Aggregate result on RentalClosed */
  RentalClosed = 'RentalClosed',
  /** Aggregate result on BaseCalendar */
  BaseCalendar = 'BaseCalendar',
  /** Aggregate result on LineAmountPeriod */
  LineAmountPeriod = 'LineAmountPeriod',
  /** Aggregate result on ProjectedReturnDate */
  ProjectedReturnDate = 'ProjectedReturnDate',
  /** Aggregate result on InvoiceToDate */
  InvoiceToDate = 'InvoiceToDate',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on DiamondQuantity */
  DiamondQuantity = 'DiamondQuantity',
  /** Aggregate result on DontTransfertoOrder */
  DontTransfertoOrder = 'DontTransfertoOrder',
  /** Aggregate result on RentalDays */
  RentalDays = 'RentalDays',
  /** Aggregate result on MonthPrice */
  MonthPrice = 'MonthPrice',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRequestHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on RequestType */
  RequestType = 'RequestType',
  /** Aggregate result on RequestTypeOPTION */
  RequestTypeOption = 'RequestTypeOPTION',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on SubProjectNo */
  SubProjectNo = 'SubProjectNo',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on ProjectName */
  ProjectName = 'ProjectName',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on Time */
  Time = 'Time',
  /** Aggregate result on DispatchHeader */
  DispatchHeader = 'DispatchHeader',
  /** Aggregate result on DispatchPosted */
  DispatchPosted = 'DispatchPosted',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on Email */
  Email = 'Email',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRequestLineInput {
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on RequestType */
  RequestType = 'RequestType',
  /** Aggregate result on RequestTypeOPTION */
  RequestTypeOption = 'RequestTypeOPTION',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on LineType */
  LineType = 'LineType',
  /** Aggregate result on LineTypeOPTION */
  LineTypeOption = 'LineTypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on SubProjectNo */
  SubProjectNo = 'SubProjectNo',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on ProjectName */
  ProjectName = 'ProjectName',
  /** Aggregate result on ManufacturerCode */
  ManufacturerCode = 'ManufacturerCode',
  /** Aggregate result on ManufacturerModelCode */
  ManufacturerModelCode = 'ManufacturerModelCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateReservationRequestHeaderInput {
  /** Aggregate result on GroupGUID */
  GroupGuid = 'GroupGUID',
  /** Aggregate result on Header */
  Header = 'Header',
  /** Aggregate result on TypeOfRequest */
  TypeOfRequest = 'TypeOfRequest',
  /** Aggregate result on Confirmed */
  Confirmed = 'Confirmed',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on UserName */
  UserName = 'UserName',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on DeliveryDate */
  DeliveryDate = 'DeliveryDate',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on ExpectedReturnDate */
  ExpectedReturnDate = 'ExpectedReturnDate',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on RequestDate */
  RequestDate = 'RequestDate',
  /** Aggregate result on DomainName */
  DomainName = 'DomainName',
  /** Aggregate result on Executed */
  Executed = 'Executed',
  /** Aggregate result on Pickup */
  Pickup = 'Pickup',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateReservationRequestLineInput {
  /** Aggregate result on GroupGUID */
  GroupGuid = 'GroupGUID',
  /** Aggregate result on LineGUID */
  LineGuid = 'LineGUID',
  /** Aggregate result on Header */
  Header = 'Header',
  /** Aggregate result on TypeOfRequest */
  TypeOfRequest = 'TypeOfRequest',
  /** Aggregate result on Confirmed */
  Confirmed = 'Confirmed',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on UserName */
  UserName = 'UserName',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on RequestDate */
  RequestDate = 'RequestDate',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on Executed */
  Executed = 'Executed',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateResourceInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on SearchName */
  SearchName = 'SearchName',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on SocialSecurityNo */
  SocialSecurityNo = 'SocialSecurityNo',
  /** Aggregate result on JobTitle */
  JobTitle = 'JobTitle',
  /** Aggregate result on Education */
  Education = 'Education',
  /** Aggregate result on ContractClass */
  ContractClass = 'ContractClass',
  /** Aggregate result on EmploymentDate */
  EmploymentDate = 'EmploymentDate',
  /** Aggregate result on ResourceGroupNo */
  ResourceGroupNo = 'ResourceGroupNo',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on BaseUnitofMeasure */
  BaseUnitofMeasure = 'BaseUnitofMeasure',
  /** Aggregate result on DirectUnitCost */
  DirectUnitCost = 'DirectUnitCost',
  /** Aggregate result on IndirectCost */
  IndirectCost = 'IndirectCost',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on PriceProfitCalculation */
  PriceProfitCalculation = 'PriceProfitCalculation',
  /** Aggregate result on PriceProfitCalculationOPTION */
  PriceProfitCalculationOption = 'PriceProfitCalculationOPTION',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on AutomaticExtTexts */
  AutomaticExtTexts = 'AutomaticExtTexts',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on ICPartnerPurchGLAccNo */
  IcPartnerPurchGlAccNo = 'ICPartnerPurchGLAccNo',
  /** Aggregate result on UseTimeSheet */
  UseTimeSheet = 'UseTimeSheet',
  /** Aggregate result on TimeSheetOwnerUserID */
  TimeSheetOwnerUserId = 'TimeSheetOwnerUserID',
  /** Aggregate result on TimeSheetApproverUserID */
  TimeSheetApproverUserId = 'TimeSheetApproverUserID',
  /** Aggregate result on ServiceZoneFilter */
  ServiceZoneFilter = 'ServiceZoneFilter',
  /** Aggregate result on DefaultQuantity */
  DefaultQuantity = 'DefaultQuantity',
  /** Aggregate result on Mounting */
  Mounting = 'Mounting',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on UnitofMeasureFilter */
  UnitofMeasureFilter = 'UnitofMeasureFilter',
  /** Aggregate result on ChargeableFilter */
  ChargeableFilter = 'ChargeableFilter',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Capacity */
  Capacity = 'Capacity',
  /** Aggregate result on QtyonOrderJob */
  QtyonOrderJob = 'QtyonOrderJob',
  /** Aggregate result on QtyQuotedJob */
  QtyQuotedJob = 'QtyQuotedJob',
  /** Aggregate result on UsageQty */
  UsageQty = 'UsageQty',
  /** Aggregate result on UsageCost */
  UsageCost = 'UsageCost',
  /** Aggregate result on UsagePrice */
  UsagePrice = 'UsagePrice',
  /** Aggregate result on SalesQty */
  SalesQty = 'SalesQty',
  /** Aggregate result on SalesCost */
  SalesCost = 'SalesCost',
  /** Aggregate result on SalesPrice */
  SalesPrice = 'SalesPrice',
  /** Aggregate result on QtyonAssemblyOrder */
  QtyonAssemblyOrder = 'QtyonAssemblyOrder',
  /** Aggregate result on QtyonServiceOrder */
  QtyonServiceOrder = 'QtyonServiceOrder',
  /** Aggregate result on InCustomerZone */
  InCustomerZone = 'InCustomerZone',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ShowInFieldOrder */
  ShowInFieldOrder = 'ShowInFieldOrder',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateResponsibilityCenterInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on EMailSignatureText */
  EMailSignatureText = 'EMailSignatureText',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on ContractGainLossAmount */
  ContractGainLossAmount = 'ContractGainLossAmount',
  /** Aggregate result on Longitud */
  Longitud = 'Longitud',
  /** Aggregate result on Latitud */
  Latitud = 'Latitud',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateReturnRequestHeaderInput {
  /** Aggregate result on GroupGUID */
  GroupGuid = 'GroupGUID',
  /** Aggregate result on Header */
  Header = 'Header',
  /** Aggregate result on TypeOfRequest */
  TypeOfRequest = 'TypeOfRequest',
  /** Aggregate result on Confirmed */
  Confirmed = 'Confirmed',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on UserName */
  UserName = 'UserName',
  /** Aggregate result on DomainName */
  DomainName = 'DomainName',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on PickUpDate */
  PickUpDate = 'PickUpDate',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Executed */
  Executed = 'Executed',
  /** Aggregate result on DropOff */
  DropOff = 'DropOff',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateReturnRequestLineInput {
  /** Aggregate result on GroupGUID */
  GroupGuid = 'GroupGUID',
  /** Aggregate result on LineGUID */
  LineGuid = 'LineGUID',
  /** Aggregate result on Header */
  Header = 'Header',
  /** Aggregate result on TypeOfRequest */
  TypeOfRequest = 'TypeOfRequest',
  /** Aggregate result on Confirmed */
  Confirmed = 'Confirmed',
  /** Aggregate result on User */
  User = 'User',
  /** Aggregate result on UserName */
  UserName = 'UserName',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on RequestDate */
  RequestDate = 'RequestDate',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on ProjectNo */
  ProjectNo = 'ProjectNo',
  /** Aggregate result on Executed */
  Executed = 'Executed',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRiskAnalysisHeaderInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Template */
  Template = 'Template',
  /** Aggregate result on WorkPlace */
  WorkPlace = 'WorkPlace',
  /** Aggregate result on CreatedDate */
  CreatedDate = 'CreatedDate',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on CreatedByPhone */
  CreatedByPhone = 'CreatedByPhone',
  /** Aggregate result on Customer */
  Customer = 'Customer',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on Database */
  Database = 'Database',
  /** Aggregate result on RecordID */
  RecordId = 'RecordID',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on CurrentHandler */
  CurrentHandler = 'CurrentHandler',
  /** Aggregate result on CurrentHandlerEmail */
  CurrentHandlerEmail = 'CurrentHandlerEmail',
  /** Aggregate result on CurrentHandlerPhone */
  CurrentHandlerPhone = 'CurrentHandlerPhone',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on FinishedByUser */
  FinishedByUser = 'FinishedByUser',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRiskAnalysisLineInput {
  /** Aggregate result on RiskAnalysisNo */
  RiskAnalysisNo = 'RiskAnalysisNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on RiskCode */
  RiskCode = 'RiskCode',
  /** Aggregate result on RiskCodeOPTION */
  RiskCodeOption = 'RiskCodeOPTION',
  /** Aggregate result on Risk */
  Risk = 'Risk',
  /** Aggregate result on Action */
  Action = 'Action',
  /** Aggregate result on Probability */
  Probability = 'Probability',
  /** Aggregate result on ProbabilityOPTION */
  ProbabilityOption = 'ProbabilityOPTION',
  /** Aggregate result on Consequence */
  Consequence = 'Consequence',
  /** Aggregate result on ConsequenceOPTION */
  ConsequenceOption = 'ConsequenceOPTION',
  /** Aggregate result on Mandatory */
  Mandatory = 'Mandatory',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateRiskAnalysisSignatureInput {
  /** Aggregate result on RiskAnalysisNo */
  RiskAnalysisNo = 'RiskAnalysisNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Signature */
  Signature = 'Signature',
  /** Aggregate result on TypeOfSignature */
  TypeOfSignature = 'TypeOfSignature',
  /** Aggregate result on TypeOfSignatureOPTION */
  TypeOfSignatureOption = 'TypeOfSignatureOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesHeaderArchiveInput {
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on SelltoCustomerGlobalId */
  SelltoCustomerGlobalId = 'SelltoCustomerGlobalId',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPostingGroup */
  CustomerPostingGroup = 'CustomerPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderClass */
  OrderClass = 'OrderClass',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on Ship */
  Ship = 'Ship',
  /** Aggregate result on Invoice */
  Invoice = 'Invoice',
  /** Aggregate result on PrintPostedDocuments */
  PrintPostedDocuments = 'PrintPostedDocuments',
  /** Aggregate result on ShippingNo */
  ShippingNo = 'ShippingNo',
  /** Aggregate result on PostingNo */
  PostingNo = 'PostingNo',
  /** Aggregate result on LastShippingNo */
  LastShippingNo = 'LastShippingNo',
  /** Aggregate result on LastPostingNo */
  LastPostingNo = 'LastPostingNo',
  /** Aggregate result on PrepaymentNo */
  PrepaymentNo = 'PrepaymentNo',
  /** Aggregate result on LastPrepaymentNo */
  LastPrepaymentNo = 'LastPrepaymentNo',
  /** Aggregate result on PrepmtCrMemoNo */
  PrepmtCrMemoNo = 'PrepmtCrMemoNo',
  /** Aggregate result on LastPrepmtCrMemoNo */
  LastPrepmtCrMemoNo = 'LastPrepmtCrMemoNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on CombineShipments */
  CombineShipments = 'CombineShipments',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on SelltoCustomerName */
  SelltoCustomerName = 'SelltoCustomerName',
  /** Aggregate result on SelltoCustomerName2 */
  SelltoCustomerName2 = 'SelltoCustomerName2',
  /** Aggregate result on SelltoAddress */
  SelltoAddress = 'SelltoAddress',
  /** Aggregate result on SelltoAddress2 */
  SelltoAddress2 = 'SelltoAddress2',
  /** Aggregate result on SelltoCity */
  SelltoCity = 'SelltoCity',
  /** Aggregate result on SelltoContact */
  SelltoContact = 'SelltoContact',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on SelltoPostCode */
  SelltoPostCode = 'SelltoPostCode',
  /** Aggregate result on SelltoCounty */
  SelltoCounty = 'SelltoCounty',
  /** Aggregate result on SelltoCountryRegionCode */
  SelltoCountryRegionCode = 'SelltoCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on PostingNoSeries */
  PostingNoSeries = 'PostingNoSeries',
  /** Aggregate result on ShippingNoSeries */
  ShippingNoSeries = 'ShippingNoSeries',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on AppliestoID */
  AppliestoId = 'AppliestoID',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on InvoiceDiscountCalculation */
  InvoiceDiscountCalculation = 'InvoiceDiscountCalculation',
  /** Aggregate result on InvoiceDiscountCalculationOPTION */
  InvoiceDiscountCalculationOption = 'InvoiceDiscountCalculationOPTION',
  /** Aggregate result on InvoiceDiscountValue */
  InvoiceDiscountValue = 'InvoiceDiscountValue',
  /** Aggregate result on SendICDocument */
  SendIcDocument = 'SendICDocument',
  /** Aggregate result on ICStatus */
  IcStatus = 'ICStatus',
  /** Aggregate result on ICStatusOPTION */
  IcStatusOption = 'ICStatusOPTION',
  /** Aggregate result on SelltoICPartnerCode */
  SelltoIcPartnerCode = 'SelltoICPartnerCode',
  /** Aggregate result on BilltoICPartnerCode */
  BilltoIcPartnerCode = 'BilltoICPartnerCode',
  /** Aggregate result on ICDirection */
  IcDirection = 'ICDirection',
  /** Aggregate result on ICDirectionOPTION */
  IcDirectionOption = 'ICDirectionOPTION',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on CompressPrepayment */
  CompressPrepayment = 'CompressPrepayment',
  /** Aggregate result on PrepaymentDueDate */
  PrepaymentDueDate = 'PrepaymentDueDate',
  /** Aggregate result on PrepmtCrMemoNoSeries */
  PrepmtCrMemoNoSeries = 'PrepmtCrMemoNoSeries',
  /** Aggregate result on PrepmtPostingDescription */
  PrepmtPostingDescription = 'PrepmtPostingDescription',
  /** Aggregate result on PrepmtPmtDiscountDate */
  PrepmtPmtDiscountDate = 'PrepmtPmtDiscountDate',
  /** Aggregate result on PrepmtPaymentTermsCode */
  PrepmtPaymentTermsCode = 'PrepmtPaymentTermsCode',
  /** Aggregate result on PrepmtPaymentDiscount */
  PrepmtPaymentDiscount = 'PrepmtPaymentDiscount',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on QuoteValidUntilDate */
  QuoteValidUntilDate = 'QuoteValidUntilDate',
  /** Aggregate result on QuoteSenttoCustomer */
  QuoteSenttoCustomer = 'QuoteSenttoCustomer',
  /** Aggregate result on QuoteAccepted */
  QuoteAccepted = 'QuoteAccepted',
  /** Aggregate result on QuoteAcceptedDate */
  QuoteAcceptedDate = 'QuoteAcceptedDate',
  /** Aggregate result on JobQueueStatus */
  JobQueueStatus = 'JobQueueStatus',
  /** Aggregate result on JobQueueStatusOPTION */
  JobQueueStatusOption = 'JobQueueStatusOPTION',
  /** Aggregate result on JobQueueEntryID */
  JobQueueEntryId = 'JobQueueEntryID',
  /** Aggregate result on IncomingDocumentEntryNo */
  IncomingDocumentEntryNo = 'IncomingDocumentEntryNo',
  /** Aggregate result on WorkDescription */
  WorkDescription = 'WorkDescription',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on PaymentServiceSetID */
  PaymentServiceSetId = 'PaymentServiceSetID',
  /** Aggregate result on DirectDebitMandateID */
  DirectDebitMandateId = 'DirectDebitMandateID',
  /** Aggregate result on DocNoOccurrence */
  DocNoOccurrence = 'DocNoOccurrence',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on SelltoCustomerTemplateCode */
  SelltoCustomerTemplateCode = 'SelltoCustomerTemplateCode',
  /** Aggregate result on SelltoContactNo */
  SelltoContactNo = 'SelltoContactNo',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on BilltoCustomerTemplateCode */
  BilltoCustomerTemplateCode = 'BilltoCustomerTemplateCode',
  /** Aggregate result on OpportunityNo */
  OpportunityNo = 'OpportunityNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on PostingfromWhseRef */
  PostingfromWhseRef = 'PostingfromWhseRef',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on Receive */
  Receive = 'Receive',
  /** Aggregate result on ReturnReceiptNo */
  ReturnReceiptNo = 'ReturnReceiptNo',
  /** Aggregate result on ReturnReceiptNoSeries */
  ReturnReceiptNoSeries = 'ReturnReceiptNoSeries',
  /** Aggregate result on LastReturnReceiptNo */
  LastReturnReceiptNo = 'LastReturnReceiptNo',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on GetShipmentUsed */
  GetShipmentUsed = 'GetShipmentUsed',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on SourceInvNo */
  SourceInvNo = 'SourceInvNo',
  /** Aggregate result on SourceInvVAT */
  SourceInvVat = 'SourceInvVAT',
  /** Aggregate result on SourceInvTotal */
  SourceInvTotal = 'SourceInvTotal',
  /** Aggregate result on NoteofGoods */
  NoteofGoods = 'NoteofGoods',
  /** Aggregate result on EQMShipmentType */
  EqmShipmentType = 'EQMShipmentType',
  /** Aggregate result on EQMShipmentTypeOPTION */
  EqmShipmentTypeOption = 'EQMShipmentTypeOPTION',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMCustomerType */
  EqmCustomerType = 'EQMCustomerType',
  /** Aggregate result on EQMCustomerTypeOPTION */
  EqmCustomerTypeOption = 'EQMCustomerTypeOPTION',
  /** Aggregate result on EQMRentalDocument */
  EqmRentalDocument = 'EQMRentalDocument',
  /** Aggregate result on EQMTransferMethod */
  EqmTransferMethod = 'EQMTransferMethod',
  /** Aggregate result on EQMTransferMethodOPTION */
  EqmTransferMethodOption = 'EQMTransferMethodOPTION',
  /** Aggregate result on EQMContraryDoc */
  EqmContraryDoc = 'EQMContraryDoc',
  /** Aggregate result on EQMWorkshopDocument */
  EqmWorkshopDocument = 'EQMWorkshopDocument',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceTermNo */
  EqmServiceTermNo = 'EQMServiceTermNo',
  /** Aggregate result on EQMCombineCustomerProject */
  EqmCombineCustomerProject = 'EQMCombineCustomerProject',
  /** Aggregate result on EQMInvoiceDocType */
  EqmInvoiceDocType = 'EQMInvoiceDocType',
  /** Aggregate result on EQMInvoiceDocTypeOPTION */
  EqmInvoiceDocTypeOption = 'EQMInvoiceDocTypeOPTION',
  /** Aggregate result on EQMCashSaleDocument */
  EqmCashSaleDocument = 'EQMCashSaleDocument',
  /** Aggregate result on EQMUseDeposit */
  EqmUseDeposit = 'EQMUseDeposit',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMJobNo */
  EqmJobNo = 'EQMJobNo',
  /** Aggregate result on EQMJobTaskNo */
  EqmJobTaskNo = 'EQMJobTaskNo',
  /** Aggregate result on EQMWSObjectCounter */
  EqmwsObjectCounter = 'EQMWSObjectCounter',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMServiceStartDate */
  EqmServiceStartDate = 'EQMServiceStartDate',
  /** Aggregate result on EQMServiceEndDate */
  EqmServiceEndDate = 'EQMServiceEndDate',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMServiceStatusOPTION */
  EqmServiceStatusOption = 'EQMServiceStatusOPTION',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMWSEstimatedStartDate */
  EqmwsEstimatedStartDate = 'EQMWSEstimatedStartDate',
  /** Aggregate result on EQMWSEstimatedStartTime */
  EqmwsEstimatedStartTime = 'EQMWSEstimatedStartTime',
  /** Aggregate result on EQMWSEstimatedEndDate */
  EqmwsEstimatedEndDate = 'EQMWSEstimatedEndDate',
  /** Aggregate result on EQMWSEstimatedEndTime */
  EqmwsEstimatedEndTime = 'EQMWSEstimatedEndTime',
  /** Aggregate result on EQMServiceStartTime */
  EqmServiceStartTime = 'EQMServiceStartTime',
  /** Aggregate result on EQMServiceEndTime */
  EqmServiceEndTime = 'EQMServiceEndTime',
  /** Aggregate result on EQMWSObjectCounter2 */
  EqmwsObjectCounter2 = 'EQMWSObjectCounter2',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on LastEmailSentTime */
  LastEmailSentTime = 'LastEmailSentTime',
  /** Aggregate result on LastEmailSentStatus */
  LastEmailSentStatus = 'LastEmailSentStatus',
  /** Aggregate result on LastEmailSentStatusOPTION */
  LastEmailSentStatusOption = 'LastEmailSentStatusOPTION',
  /** Aggregate result on SentasEmail */
  SentasEmail = 'SentasEmail',
  /** Aggregate result on LastEmailNotifCleared */
  LastEmailNotifCleared = 'LastEmailNotifCleared',
  /** Aggregate result on AmtShipNotInvLCY */
  AmtShipNotInvLcy = 'AmtShipNotInvLCY',
  /** Aggregate result on AmtShipNotInvLCYBase */
  AmtShipNotInvLcyBase = 'AmtShipNotInvLCYBase',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on NoofArchivedVersions */
  NoofArchivedVersions = 'NoofArchivedVersions',
  /** Aggregate result on ShippedNotInvoiced */
  ShippedNotInvoiced = 'ShippedNotInvoiced',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on Shipped */
  Shipped = 'Shipped',
  /** Aggregate result on LateOrderShipping */
  LateOrderShipping = 'LateOrderShipping',
  /** Aggregate result on EQMObjectWarrantyDate */
  EqmObjectWarrantyDate = 'EQMObjectWarrantyDate',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on BaseSubTotal */
  BaseSubTotal = 'BaseSubTotal',
  /** Aggregate result on BaseSubTotalInclTax */
  BaseSubTotalInclTax = 'BaseSubTotalInclTax',
  /** Aggregate result on ShippingDescription */
  ShippingDescription = 'ShippingDescription',
  /** Aggregate result on CustomerNote */
  CustomerNote = 'CustomerNote',
  /** Aggregate result on CustomerEmail */
  CustomerEmail = 'CustomerEmail',
  /** Aggregate result on BilltoEmail */
  BilltoEmail = 'BilltoEmail',
  /** Aggregate result on BilltoTelephone */
  BilltoTelephone = 'BilltoTelephone',
  /** Aggregate result on ShipToId */
  ShipToId = 'ShipToId',
  /** Aggregate result on ShiptoEmail */
  ShiptoEmail = 'ShiptoEmail',
  /** Aggregate result on ShiptoTelephone */
  ShiptoTelephone = 'ShiptoTelephone',
  /** Aggregate result on MagentoOrderStatus */
  MagentoOrderStatus = 'MagentoOrderStatus',
  /** Aggregate result on MagentoOrderState */
  MagentoOrderState = 'MagentoOrderState',
  /** Aggregate result on LineCount */
  LineCount = 'LineCount',
  /** Aggregate result on FreightIncluded */
  FreightIncluded = 'FreightIncluded',
  /** Aggregate result on DiscountIncluded */
  DiscountIncluded = 'DiscountIncluded',
  /** Aggregate result on DeliveryMethod */
  DeliveryMethod = 'DeliveryMethod',
  /** Aggregate result on ProcessedByEdge */
  ProcessedByEdge = 'ProcessedByEdge',
  /** Aggregate result on EdgeStartedPollingOn */
  EdgeStartedPollingOn = 'EdgeStartedPollingOn',
  /** Aggregate result on HandleRelationsInJSONStructure */
  HandleRelationsInJsonStructure = 'HandleRelationsInJSONStructure',
  /** Aggregate result on WebbComment */
  WebbComment = 'WebbComment',
  /** Aggregate result on SalesOrderEdgeGuid */
  SalesOrderEdgeGuid = 'SalesOrderEdgeGuid',
  /** Aggregate result on VersionNo */
  VersionNo = 'VersionNo',
  /** Aggregate result on DeletedReason */
  DeletedReason = 'DeletedReason',
  /** Aggregate result on IsConstructed */
  IsConstructed = 'IsConstructed',
  /** Aggregate result on PaymentMethodWeb */
  PaymentMethodWeb = 'PaymentMethodWeb',
  /** Aggregate result on SharkActualPrice */
  SharkActualPrice = 'SharkActualPrice',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesHeaderHistoryInput {
  /** Aggregate result on GUID */
  Guid = 'GUID',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesHeaderInput {
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on SelltoCustomerGlobalId */
  SelltoCustomerGlobalId = 'SelltoCustomerGlobalId',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPostingGroup */
  CustomerPostingGroup = 'CustomerPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderClass */
  OrderClass = 'OrderClass',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on Ship */
  Ship = 'Ship',
  /** Aggregate result on Invoice */
  Invoice = 'Invoice',
  /** Aggregate result on PrintPostedDocuments */
  PrintPostedDocuments = 'PrintPostedDocuments',
  /** Aggregate result on ShippingNo */
  ShippingNo = 'ShippingNo',
  /** Aggregate result on PostingNo */
  PostingNo = 'PostingNo',
  /** Aggregate result on LastShippingNo */
  LastShippingNo = 'LastShippingNo',
  /** Aggregate result on LastPostingNo */
  LastPostingNo = 'LastPostingNo',
  /** Aggregate result on PrepaymentNo */
  PrepaymentNo = 'PrepaymentNo',
  /** Aggregate result on LastPrepaymentNo */
  LastPrepaymentNo = 'LastPrepaymentNo',
  /** Aggregate result on PrepmtCrMemoNo */
  PrepmtCrMemoNo = 'PrepmtCrMemoNo',
  /** Aggregate result on LastPrepmtCrMemoNo */
  LastPrepmtCrMemoNo = 'LastPrepmtCrMemoNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on CombineShipments */
  CombineShipments = 'CombineShipments',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on SelltoCustomerName */
  SelltoCustomerName = 'SelltoCustomerName',
  /** Aggregate result on SelltoCustomerName2 */
  SelltoCustomerName2 = 'SelltoCustomerName2',
  /** Aggregate result on SelltoAddress */
  SelltoAddress = 'SelltoAddress',
  /** Aggregate result on SelltoAddress2 */
  SelltoAddress2 = 'SelltoAddress2',
  /** Aggregate result on SelltoCity */
  SelltoCity = 'SelltoCity',
  /** Aggregate result on SelltoContact */
  SelltoContact = 'SelltoContact',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on SelltoPostCode */
  SelltoPostCode = 'SelltoPostCode',
  /** Aggregate result on SelltoCounty */
  SelltoCounty = 'SelltoCounty',
  /** Aggregate result on SelltoCountryRegionCode */
  SelltoCountryRegionCode = 'SelltoCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on PostingNoSeries */
  PostingNoSeries = 'PostingNoSeries',
  /** Aggregate result on ShippingNoSeries */
  ShippingNoSeries = 'ShippingNoSeries',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on AppliestoID */
  AppliestoId = 'AppliestoID',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on InvoiceDiscountCalculation */
  InvoiceDiscountCalculation = 'InvoiceDiscountCalculation',
  /** Aggregate result on InvoiceDiscountCalculationOPTION */
  InvoiceDiscountCalculationOption = 'InvoiceDiscountCalculationOPTION',
  /** Aggregate result on InvoiceDiscountValue */
  InvoiceDiscountValue = 'InvoiceDiscountValue',
  /** Aggregate result on SendICDocument */
  SendIcDocument = 'SendICDocument',
  /** Aggregate result on ICStatus */
  IcStatus = 'ICStatus',
  /** Aggregate result on ICStatusOPTION */
  IcStatusOption = 'ICStatusOPTION',
  /** Aggregate result on SelltoICPartnerCode */
  SelltoIcPartnerCode = 'SelltoICPartnerCode',
  /** Aggregate result on BilltoICPartnerCode */
  BilltoIcPartnerCode = 'BilltoICPartnerCode',
  /** Aggregate result on ICDirection */
  IcDirection = 'ICDirection',
  /** Aggregate result on ICDirectionOPTION */
  IcDirectionOption = 'ICDirectionOPTION',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on CompressPrepayment */
  CompressPrepayment = 'CompressPrepayment',
  /** Aggregate result on PrepaymentDueDate */
  PrepaymentDueDate = 'PrepaymentDueDate',
  /** Aggregate result on PrepmtCrMemoNoSeries */
  PrepmtCrMemoNoSeries = 'PrepmtCrMemoNoSeries',
  /** Aggregate result on PrepmtPostingDescription */
  PrepmtPostingDescription = 'PrepmtPostingDescription',
  /** Aggregate result on PrepmtPmtDiscountDate */
  PrepmtPmtDiscountDate = 'PrepmtPmtDiscountDate',
  /** Aggregate result on PrepmtPaymentTermsCode */
  PrepmtPaymentTermsCode = 'PrepmtPaymentTermsCode',
  /** Aggregate result on PrepmtPaymentDiscount */
  PrepmtPaymentDiscount = 'PrepmtPaymentDiscount',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on QuoteValidUntilDate */
  QuoteValidUntilDate = 'QuoteValidUntilDate',
  /** Aggregate result on QuoteSenttoCustomer */
  QuoteSenttoCustomer = 'QuoteSenttoCustomer',
  /** Aggregate result on QuoteAccepted */
  QuoteAccepted = 'QuoteAccepted',
  /** Aggregate result on QuoteAcceptedDate */
  QuoteAcceptedDate = 'QuoteAcceptedDate',
  /** Aggregate result on JobQueueStatus */
  JobQueueStatus = 'JobQueueStatus',
  /** Aggregate result on JobQueueStatusOPTION */
  JobQueueStatusOption = 'JobQueueStatusOPTION',
  /** Aggregate result on JobQueueEntryID */
  JobQueueEntryId = 'JobQueueEntryID',
  /** Aggregate result on IncomingDocumentEntryNo */
  IncomingDocumentEntryNo = 'IncomingDocumentEntryNo',
  /** Aggregate result on WorkDescription */
  WorkDescription = 'WorkDescription',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on PaymentServiceSetID */
  PaymentServiceSetId = 'PaymentServiceSetID',
  /** Aggregate result on DirectDebitMandateID */
  DirectDebitMandateId = 'DirectDebitMandateID',
  /** Aggregate result on DocNoOccurrence */
  DocNoOccurrence = 'DocNoOccurrence',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on SelltoCustomerTemplateCode */
  SelltoCustomerTemplateCode = 'SelltoCustomerTemplateCode',
  /** Aggregate result on SelltoContactNo */
  SelltoContactNo = 'SelltoContactNo',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on BilltoCustomerTemplateCode */
  BilltoCustomerTemplateCode = 'BilltoCustomerTemplateCode',
  /** Aggregate result on OpportunityNo */
  OpportunityNo = 'OpportunityNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on PostingfromWhseRef */
  PostingfromWhseRef = 'PostingfromWhseRef',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on Receive */
  Receive = 'Receive',
  /** Aggregate result on ReturnReceiptNo */
  ReturnReceiptNo = 'ReturnReceiptNo',
  /** Aggregate result on ReturnReceiptNoSeries */
  ReturnReceiptNoSeries = 'ReturnReceiptNoSeries',
  /** Aggregate result on LastReturnReceiptNo */
  LastReturnReceiptNo = 'LastReturnReceiptNo',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on GetShipmentUsed */
  GetShipmentUsed = 'GetShipmentUsed',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on SourceInvNo */
  SourceInvNo = 'SourceInvNo',
  /** Aggregate result on SourceInvVAT */
  SourceInvVat = 'SourceInvVAT',
  /** Aggregate result on SourceInvTotal */
  SourceInvTotal = 'SourceInvTotal',
  /** Aggregate result on NoteofGoods */
  NoteofGoods = 'NoteofGoods',
  /** Aggregate result on EQMShipmentType */
  EqmShipmentType = 'EQMShipmentType',
  /** Aggregate result on EQMShipmentTypeOPTION */
  EqmShipmentTypeOption = 'EQMShipmentTypeOPTION',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMCustomerType */
  EqmCustomerType = 'EQMCustomerType',
  /** Aggregate result on EQMCustomerTypeOPTION */
  EqmCustomerTypeOption = 'EQMCustomerTypeOPTION',
  /** Aggregate result on EQMRentalDocument */
  EqmRentalDocument = 'EQMRentalDocument',
  /** Aggregate result on EQMTransferMethod */
  EqmTransferMethod = 'EQMTransferMethod',
  /** Aggregate result on EQMTransferMethodOPTION */
  EqmTransferMethodOption = 'EQMTransferMethodOPTION',
  /** Aggregate result on EQMContraryDoc */
  EqmContraryDoc = 'EQMContraryDoc',
  /** Aggregate result on EQMWorkshopDocument */
  EqmWorkshopDocument = 'EQMWorkshopDocument',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMServiceTermNo */
  EqmServiceTermNo = 'EQMServiceTermNo',
  /** Aggregate result on EQMCombineCustomerProject */
  EqmCombineCustomerProject = 'EQMCombineCustomerProject',
  /** Aggregate result on EQMInvoiceDocType */
  EqmInvoiceDocType = 'EQMInvoiceDocType',
  /** Aggregate result on EQMInvoiceDocTypeOPTION */
  EqmInvoiceDocTypeOption = 'EQMInvoiceDocTypeOPTION',
  /** Aggregate result on EQMCashSaleDocument */
  EqmCashSaleDocument = 'EQMCashSaleDocument',
  /** Aggregate result on EQMUseDeposit */
  EqmUseDeposit = 'EQMUseDeposit',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMJobNo */
  EqmJobNo = 'EQMJobNo',
  /** Aggregate result on EQMJobTaskNo */
  EqmJobTaskNo = 'EQMJobTaskNo',
  /** Aggregate result on EQMWSObjectCounter */
  EqmwsObjectCounter = 'EQMWSObjectCounter',
  /** Aggregate result on EQMServiceNo */
  EqmServiceNo = 'EQMServiceNo',
  /** Aggregate result on EQMServiceStartDate */
  EqmServiceStartDate = 'EQMServiceStartDate',
  /** Aggregate result on EQMServiceEndDate */
  EqmServiceEndDate = 'EQMServiceEndDate',
  /** Aggregate result on EQMServiceStatus */
  EqmServiceStatus = 'EQMServiceStatus',
  /** Aggregate result on EQMServiceStatusOPTION */
  EqmServiceStatusOption = 'EQMServiceStatusOPTION',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMWSEstimatedStartDate */
  EqmwsEstimatedStartDate = 'EQMWSEstimatedStartDate',
  /** Aggregate result on EQMWSEstimatedStartTime */
  EqmwsEstimatedStartTime = 'EQMWSEstimatedStartTime',
  /** Aggregate result on EQMWSEstimatedEndDate */
  EqmwsEstimatedEndDate = 'EQMWSEstimatedEndDate',
  /** Aggregate result on EQMWSEstimatedEndTime */
  EqmwsEstimatedEndTime = 'EQMWSEstimatedEndTime',
  /** Aggregate result on EQMServiceStartTime */
  EqmServiceStartTime = 'EQMServiceStartTime',
  /** Aggregate result on EQMServiceEndTime */
  EqmServiceEndTime = 'EQMServiceEndTime',
  /** Aggregate result on EQMWSObjectCounter2 */
  EqmwsObjectCounter2 = 'EQMWSObjectCounter2',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on LastEmailSentTime */
  LastEmailSentTime = 'LastEmailSentTime',
  /** Aggregate result on LastEmailSentStatus */
  LastEmailSentStatus = 'LastEmailSentStatus',
  /** Aggregate result on LastEmailSentStatusOPTION */
  LastEmailSentStatusOption = 'LastEmailSentStatusOPTION',
  /** Aggregate result on SentasEmail */
  SentasEmail = 'SentasEmail',
  /** Aggregate result on LastEmailNotifCleared */
  LastEmailNotifCleared = 'LastEmailNotifCleared',
  /** Aggregate result on AmtShipNotInvLCY */
  AmtShipNotInvLcy = 'AmtShipNotInvLCY',
  /** Aggregate result on AmtShipNotInvLCYBase */
  AmtShipNotInvLcyBase = 'AmtShipNotInvLCYBase',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on NoofArchivedVersions */
  NoofArchivedVersions = 'NoofArchivedVersions',
  /** Aggregate result on ShippedNotInvoiced */
  ShippedNotInvoiced = 'ShippedNotInvoiced',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on Shipped */
  Shipped = 'Shipped',
  /** Aggregate result on LateOrderShipping */
  LateOrderShipping = 'LateOrderShipping',
  /** Aggregate result on EQMObjectWarrantyDate */
  EqmObjectWarrantyDate = 'EQMObjectWarrantyDate',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on BaseSubTotal */
  BaseSubTotal = 'BaseSubTotal',
  /** Aggregate result on BaseSubTotalInclTax */
  BaseSubTotalInclTax = 'BaseSubTotalInclTax',
  /** Aggregate result on ShippingDescription */
  ShippingDescription = 'ShippingDescription',
  /** Aggregate result on CustomerNote */
  CustomerNote = 'CustomerNote',
  /** Aggregate result on CustomerEmail */
  CustomerEmail = 'CustomerEmail',
  /** Aggregate result on BilltoEmail */
  BilltoEmail = 'BilltoEmail',
  /** Aggregate result on BilltoTelephone */
  BilltoTelephone = 'BilltoTelephone',
  /** Aggregate result on ShipToId */
  ShipToId = 'ShipToId',
  /** Aggregate result on ShiptoEmail */
  ShiptoEmail = 'ShiptoEmail',
  /** Aggregate result on ShiptoTelephone */
  ShiptoTelephone = 'ShiptoTelephone',
  /** Aggregate result on MagentoOrderStatus */
  MagentoOrderStatus = 'MagentoOrderStatus',
  /** Aggregate result on MagentoOrderState */
  MagentoOrderState = 'MagentoOrderState',
  /** Aggregate result on LineCount */
  LineCount = 'LineCount',
  /** Aggregate result on FreightIncluded */
  FreightIncluded = 'FreightIncluded',
  /** Aggregate result on DiscountIncluded */
  DiscountIncluded = 'DiscountIncluded',
  /** Aggregate result on DeliveryMethod */
  DeliveryMethod = 'DeliveryMethod',
  /** Aggregate result on ProcessedByEdge */
  ProcessedByEdge = 'ProcessedByEdge',
  /** Aggregate result on EdgeStartedPollingOn */
  EdgeStartedPollingOn = 'EdgeStartedPollingOn',
  /** Aggregate result on HandleRelationsInJSONStructure */
  HandleRelationsInJsonStructure = 'HandleRelationsInJSONStructure',
  /** Aggregate result on WebbComment */
  WebbComment = 'WebbComment',
  /** Aggregate result on SalesOrderEdgeGuid */
  SalesOrderEdgeGuid = 'SalesOrderEdgeGuid',
  /** Aggregate result on PaymentMethodWeb */
  PaymentMethodWeb = 'PaymentMethodWeb',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on WebOrderNo */
  WebOrderNo = 'WebOrderNo',
  /** Aggregate result on AdditionalTelephone */
  AdditionalTelephone = 'AdditionalTelephone',
  /** Aggregate result on SellToAddressGlobalId */
  SellToAddressGlobalId = 'SellToAddressGlobalId',
  /** Aggregate result on SharkActualPrice */
  SharkActualPrice = 'SharkActualPrice',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesHeaderRequestInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPostingGroup */
  CustomerPostingGroup = 'CustomerPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderClass */
  OrderClass = 'OrderClass',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on Ship */
  Ship = 'Ship',
  /** Aggregate result on Invoice */
  Invoice = 'Invoice',
  /** Aggregate result on PrintPostedDocuments */
  PrintPostedDocuments = 'PrintPostedDocuments',
  /** Aggregate result on ShippingNo */
  ShippingNo = 'ShippingNo',
  /** Aggregate result on PostingNo */
  PostingNo = 'PostingNo',
  /** Aggregate result on LastShippingNo */
  LastShippingNo = 'LastShippingNo',
  /** Aggregate result on LastPostingNo */
  LastPostingNo = 'LastPostingNo',
  /** Aggregate result on PrepaymentNo */
  PrepaymentNo = 'PrepaymentNo',
  /** Aggregate result on LastPrepaymentNo */
  LastPrepaymentNo = 'LastPrepaymentNo',
  /** Aggregate result on PrepmtCrMemoNo */
  PrepmtCrMemoNo = 'PrepmtCrMemoNo',
  /** Aggregate result on LastPrepmtCrMemoNo */
  LastPrepmtCrMemoNo = 'LastPrepmtCrMemoNo',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on CombineShipments */
  CombineShipments = 'CombineShipments',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on SelltoCustomerName */
  SelltoCustomerName = 'SelltoCustomerName',
  /** Aggregate result on SelltoCustomerName2 */
  SelltoCustomerName2 = 'SelltoCustomerName2',
  /** Aggregate result on SelltoAddress */
  SelltoAddress = 'SelltoAddress',
  /** Aggregate result on SelltoAddress2 */
  SelltoAddress2 = 'SelltoAddress2',
  /** Aggregate result on SelltoCity */
  SelltoCity = 'SelltoCity',
  /** Aggregate result on SelltoContact */
  SelltoContact = 'SelltoContact',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on SelltoPostCode */
  SelltoPostCode = 'SelltoPostCode',
  /** Aggregate result on SelltoCounty */
  SelltoCounty = 'SelltoCounty',
  /** Aggregate result on SelltoCountryRegionCode */
  SelltoCountryRegionCode = 'SelltoCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on PostingNoSeries */
  PostingNoSeries = 'PostingNoSeries',
  /** Aggregate result on ShippingNoSeries */
  ShippingNoSeries = 'ShippingNoSeries',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on AppliestoID */
  AppliestoId = 'AppliestoID',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on InvoiceDiscountCalculation */
  InvoiceDiscountCalculation = 'InvoiceDiscountCalculation',
  /** Aggregate result on InvoiceDiscountCalculationOPTION */
  InvoiceDiscountCalculationOption = 'InvoiceDiscountCalculationOPTION',
  /** Aggregate result on InvoiceDiscountValue */
  InvoiceDiscountValue = 'InvoiceDiscountValue',
  /** Aggregate result on SendICDocument */
  SendIcDocument = 'SendICDocument',
  /** Aggregate result on ICStatus */
  IcStatus = 'ICStatus',
  /** Aggregate result on ICStatusOPTION */
  IcStatusOption = 'ICStatusOPTION',
  /** Aggregate result on SelltoICPartnerCode */
  SelltoIcPartnerCode = 'SelltoICPartnerCode',
  /** Aggregate result on BilltoICPartnerCode */
  BilltoIcPartnerCode = 'BilltoICPartnerCode',
  /** Aggregate result on ICDirection */
  IcDirection = 'ICDirection',
  /** Aggregate result on ICDirectionOPTION */
  IcDirectionOption = 'ICDirectionOPTION',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on CompressPrepayment */
  CompressPrepayment = 'CompressPrepayment',
  /** Aggregate result on PrepaymentDueDate */
  PrepaymentDueDate = 'PrepaymentDueDate',
  /** Aggregate result on PrepmtCrMemoNoSeries */
  PrepmtCrMemoNoSeries = 'PrepmtCrMemoNoSeries',
  /** Aggregate result on PrepmtPostingDescription */
  PrepmtPostingDescription = 'PrepmtPostingDescription',
  /** Aggregate result on PrepmtPmtDiscountDate */
  PrepmtPmtDiscountDate = 'PrepmtPmtDiscountDate',
  /** Aggregate result on PrepmtPaymentTermsCode */
  PrepmtPaymentTermsCode = 'PrepmtPaymentTermsCode',
  /** Aggregate result on PrepmtPaymentDiscount */
  PrepmtPaymentDiscount = 'PrepmtPaymentDiscount',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on JobQueueStatus */
  JobQueueStatus = 'JobQueueStatus',
  /** Aggregate result on JobQueueStatusOPTION */
  JobQueueStatusOption = 'JobQueueStatusOPTION',
  /** Aggregate result on JobQueueEntryID */
  JobQueueEntryId = 'JobQueueEntryID',
  /** Aggregate result on IncomingDocumentEntryNo */
  IncomingDocumentEntryNo = 'IncomingDocumentEntryNo',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on AuthorizationRequired */
  AuthorizationRequired = 'AuthorizationRequired',
  /** Aggregate result on CreditCardNo */
  CreditCardNo = 'CreditCardNo',
  /** Aggregate result on DirectDebitMandateID */
  DirectDebitMandateId = 'DirectDebitMandateID',
  /** Aggregate result on DocNoOccurrence */
  DocNoOccurrence = 'DocNoOccurrence',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on SelltoCustomerTemplateCode */
  SelltoCustomerTemplateCode = 'SelltoCustomerTemplateCode',
  /** Aggregate result on SelltoContactNo */
  SelltoContactNo = 'SelltoContactNo',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on BilltoCustomerTemplateCode */
  BilltoCustomerTemplateCode = 'BilltoCustomerTemplateCode',
  /** Aggregate result on OpportunityNo */
  OpportunityNo = 'OpportunityNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on ShippingAdvice */
  ShippingAdvice = 'ShippingAdvice',
  /** Aggregate result on ShippingAdviceOPTION */
  ShippingAdviceOption = 'ShippingAdviceOPTION',
  /** Aggregate result on PostingfromWhseRef */
  PostingfromWhseRef = 'PostingfromWhseRef',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on Receive */
  Receive = 'Receive',
  /** Aggregate result on ReturnReceiptNo */
  ReturnReceiptNo = 'ReturnReceiptNo',
  /** Aggregate result on ReturnReceiptNoSeries */
  ReturnReceiptNoSeries = 'ReturnReceiptNoSeries',
  /** Aggregate result on LastReturnReceiptNo */
  LastReturnReceiptNo = 'LastReturnReceiptNo',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on GetShipmentUsed */
  GetShipmentUsed = 'GetShipmentUsed',
  /** Aggregate result on AssignedUserID */
  AssignedUserId = 'AssignedUserID',
  /** Aggregate result on SourceInvNo */
  SourceInvNo = 'SourceInvNo',
  /** Aggregate result on SourceInvVAT */
  SourceInvVat = 'SourceInvVAT',
  /** Aggregate result on SourceInvTotal */
  SourceInvTotal = 'SourceInvTotal',
  /** Aggregate result on Ordertype */
  Ordertype = 'Ordertype',
  /** Aggregate result on OrdertypeOPTION */
  OrdertypeOption = 'OrdertypeOPTION',
  /** Aggregate result on OurReference */
  OurReference = 'OurReference',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on ConfirmedbyVendor */
  ConfirmedbyVendor = 'ConfirmedbyVendor',
  /** Aggregate result on DocumentMethod */
  DocumentMethod = 'DocumentMethod',
  /** Aggregate result on DocumentMethodOPTION */
  DocumentMethodOption = 'DocumentMethodOPTION',
  /** Aggregate result on InvoiceEmailAddress */
  InvoiceEmailAddress = 'InvoiceEmailAddress',
  /** Aggregate result on NAVStatus */
  NavStatus = 'NAVStatus',
  /** Aggregate result on NAVStatusOPTION */
  NavStatusOption = 'NAVStatusOPTION',
  /** Aggregate result on NAVImportedDate */
  NavImportedDate = 'NAVImportedDate',
  /** Aggregate result on NAVOrderNo */
  NavOrderNo = 'NAVOrderNo',
  /** Aggregate result on NAVErrorMessage */
  NavErrorMessage = 'NAVErrorMessage',
  /** Aggregate result on NoteofGoods */
  NoteofGoods = 'NoteofGoods',
  /** Aggregate result on LocationFilter */
  LocationFilter = 'LocationFilter',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesLineArchiveInput {
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on DocumentGlobalId */
  DocumentGlobalId = 'DocumentGlobalId',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on ItemGlobalId */
  ItemGlobalId = 'ItemGlobalId',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on OutstandingQuantity */
  OutstandingQuantity = 'OutstandingQuantity',
  /** Aggregate result on QtytoInvoice */
  QtytoInvoice = 'QtytoInvoice',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountPercent */
  LineDiscountPercent = 'LineDiscountPercent',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on OutstandingAmount */
  OutstandingAmount = 'OutstandingAmount',
  /** Aggregate result on QtyShippedNotInvoiced */
  QtyShippedNotInvoiced = 'QtyShippedNotInvoiced',
  /** Aggregate result on ShippedNotInvoiced */
  ShippedNotInvoiced = 'ShippedNotInvoiced',
  /** Aggregate result on QuantityShipped */
  QuantityShipped = 'QuantityShipped',
  /** Aggregate result on QuantityInvoiced */
  QuantityInvoiced = 'QuantityInvoiced',
  /** Aggregate result on ShipmentNo */
  ShipmentNo = 'ShipmentNo',
  /** Aggregate result on ShipmentLineNo */
  ShipmentLineNo = 'ShipmentLineNo',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on InvDiscountAmount */
  InvDiscountAmount = 'InvDiscountAmount',
  /** Aggregate result on PurchaseOrderNo */
  PurchaseOrderNo = 'PurchaseOrderNo',
  /** Aggregate result on PurchOrderLineNo */
  PurchOrderLineNo = 'PurchOrderLineNo',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxCategory */
  TaxCategory = 'TaxCategory',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATClauseCode */
  VatClauseCode = 'VATClauseCode',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on OutstandingAmountLCY */
  OutstandingAmountLcy = 'OutstandingAmountLCY',
  /** Aggregate result on ShippedNotInvoicedLCY */
  ShippedNotInvoicedLcy = 'ShippedNotInvoicedLCY',
  /** Aggregate result on ShippedNotInvLCYNoVAT */
  ShippedNotInvLcyNoVat = 'ShippedNotInvLCYNoVAT',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on InvDiscAmounttoInvoice */
  InvDiscAmounttoInvoice = 'InvDiscAmounttoInvoice',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepmtLineAmount */
  PrepmtLineAmount = 'PrepmtLineAmount',
  /** Aggregate result on PrepmtAmtInv */
  PrepmtAmtInv = 'PrepmtAmtInv',
  /** Aggregate result on PrepmtAmtInclVAT */
  PrepmtAmtInclVat = 'PrepmtAmtInclVAT',
  /** Aggregate result on PrepaymentAmount */
  PrepaymentAmount = 'PrepaymentAmount',
  /** Aggregate result on PrepmtVATBaseAmt */
  PrepmtVatBaseAmt = 'PrepmtVATBaseAmt',
  /** Aggregate result on PrepaymentVAT */
  PrepaymentVat = 'PrepaymentVAT',
  /** Aggregate result on PrepmtVATCalcType */
  PrepmtVatCalcType = 'PrepmtVATCalcType',
  /** Aggregate result on PrepmtVATCalcTypeOPTION */
  PrepmtVatCalcTypeOption = 'PrepmtVATCalcTypeOPTION',
  /** Aggregate result on PrepaymentVATIdentifier */
  PrepaymentVatIdentifier = 'PrepaymentVATIdentifier',
  /** Aggregate result on PrepaymentTaxAreaCode */
  PrepaymentTaxAreaCode = 'PrepaymentTaxAreaCode',
  /** Aggregate result on PrepaymentTaxLiable */
  PrepaymentTaxLiable = 'PrepaymentTaxLiable',
  /** Aggregate result on PrepaymentTaxGroupCode */
  PrepaymentTaxGroupCode = 'PrepaymentTaxGroupCode',
  /** Aggregate result on PrepmtAmttoDeduct */
  PrepmtAmttoDeduct = 'PrepmtAmttoDeduct',
  /** Aggregate result on PrepmtAmtDeducted */
  PrepmtAmtDeducted = 'PrepmtAmtDeducted',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on PrepmtAmountInvInclVAT */
  PrepmtAmountInvInclVat = 'PrepmtAmountInvInclVAT',
  /** Aggregate result on PrepmtAmountInvLCY */
  PrepmtAmountInvLcy = 'PrepmtAmountInvLCY',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PrepmtVATAmountInvLCY */
  PrepmtVatAmountInvLcy = 'PrepmtVATAmountInvLCY',
  /** Aggregate result on PrepaymentVATDifference */
  PrepaymentVatDifference = 'PrepaymentVATDifference',
  /** Aggregate result on PrepmtVATDifftoDeduct */
  PrepmtVatDifftoDeduct = 'PrepmtVATDifftoDeduct',
  /** Aggregate result on PrepmtVATDiffDeducted */
  PrepmtVatDiffDeducted = 'PrepmtVATDiffDeducted',
  /** Aggregate result on LineDiscountCalculation */
  LineDiscountCalculation = 'LineDiscountCalculation',
  /** Aggregate result on LineDiscountCalculationOPTION */
  LineDiscountCalculationOption = 'LineDiscountCalculationOPTION',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on QtytoAssembletoOrder */
  QtytoAssembletoOrder = 'QtytoAssembletoOrder',
  /** Aggregate result on QtytoAsmtoOrderBase */
  QtytoAsmtoOrderBase = 'QtytoAsmtoOrderBase',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobContractEntryNo */
  JobContractEntryNo = 'JobContractEntryNo',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on ReturnsDeferralStartDate */
  ReturnsDeferralStartDate = 'ReturnsDeferralStartDate',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on Planned */
  Planned = 'Planned',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on OutstandingQtyBase */
  OutstandingQtyBase = 'OutstandingQtyBase',
  /** Aggregate result on QtytoInvoiceBase */
  QtytoInvoiceBase = 'QtytoInvoiceBase',
  /** Aggregate result on QtytoShipBase */
  QtytoShipBase = 'QtytoShipBase',
  /** Aggregate result on QtyShippedNotInvdBase */
  QtyShippedNotInvdBase = 'QtyShippedNotInvdBase',
  /** Aggregate result on QtyShippedBase */
  QtyShippedBase = 'QtyShippedBase',
  /** Aggregate result on QtyInvoicedBase */
  QtyInvoicedBase = 'QtyInvoicedBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on OutofStockSubstitution */
  OutofStockSubstitution = 'OutofStockSubstitution',
  /** Aggregate result on OriginallyOrderedNo */
  OriginallyOrderedNo = 'OriginallyOrderedNo',
  /** Aggregate result on OriginallyOrderedVarCode */
  OriginallyOrderedVarCode = 'OriginallyOrderedVarCode',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on SpecialOrder */
  SpecialOrder = 'SpecialOrder',
  /** Aggregate result on SpecialOrderPurchaseNo */
  SpecialOrderPurchaseNo = 'SpecialOrderPurchaseNo',
  /** Aggregate result on SpecialOrderPurchLineNo */
  SpecialOrderPurchLineNo = 'SpecialOrderPurchLineNo',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on PlannedDeliveryDate */
  PlannedDeliveryDate = 'PlannedDeliveryDate',
  /** Aggregate result on PlannedShipmentDate */
  PlannedShipmentDate = 'PlannedShipmentDate',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on AllowItemChargeAssignment */
  AllowItemChargeAssignment = 'AllowItemChargeAssignment',
  /** Aggregate result on ReturnQtytoReceive */
  ReturnQtytoReceive = 'ReturnQtytoReceive',
  /** Aggregate result on ReturnQtytoReceiveBase */
  ReturnQtytoReceiveBase = 'ReturnQtytoReceiveBase',
  /** Aggregate result on ReturnQtyRcdNotInvd */
  ReturnQtyRcdNotInvd = 'ReturnQtyRcdNotInvd',
  /** Aggregate result on RetQtyRcdNotInvdBase */
  RetQtyRcdNotInvdBase = 'RetQtyRcdNotInvdBase',
  /** Aggregate result on ReturnRcdNotInvd */
  ReturnRcdNotInvd = 'ReturnRcdNotInvd',
  /** Aggregate result on ReturnRcdNotInvdLCY */
  ReturnRcdNotInvdLcy = 'ReturnRcdNotInvdLCY',
  /** Aggregate result on ReturnQtyReceived */
  ReturnQtyReceived = 'ReturnQtyReceived',
  /** Aggregate result on ReturnQtyReceivedBase */
  ReturnQtyReceivedBase = 'ReturnQtyReceivedBase',
  /** Aggregate result on ApplfromItemEntry */
  ApplfromItemEntry = 'ApplfromItemEntry',
  /** Aggregate result on BOMItemNo */
  BomItemNo = 'BOMItemNo',
  /** Aggregate result on ReturnReceiptNo */
  ReturnReceiptNo = 'ReturnReceiptNo',
  /** Aggregate result on ReturnReceiptLineNo */
  ReturnReceiptLineNo = 'ReturnReceiptLineNo',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on Subtype */
  Subtype = 'Subtype',
  /** Aggregate result on SubtypeOPTION */
  SubtypeOption = 'SubtypeOPTION',
  /** Aggregate result on Pricedescription */
  Pricedescription = 'Pricedescription',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on EQMCustomerProject */
  EqmCustomerProject = 'EQMCustomerProject',
  /** Aggregate result on ChargeType */
  ChargeType = 'ChargeType',
  /** Aggregate result on ChargeTypeOPTION */
  ChargeTypeOption = 'ChargeTypeOPTION',
  /** Aggregate result on TextconnectedtoLineNo */
  TextconnectedtoLineNo = 'TextconnectedtoLineNo',
  /** Aggregate result on ConnectedtoItemLine */
  ConnectedtoItemLine = 'ConnectedtoItemLine',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMRental */
  EqmRental = 'EQMRental',
  /** Aggregate result on EQMRentalFromDate */
  EqmRentalFromDate = 'EQMRentalFromDate',
  /** Aggregate result on EQMRentalToDate */
  EqmRentalToDate = 'EQMRentalToDate',
  /** Aggregate result on EQMRentalLineNo */
  EqmRentalLineNo = 'EQMRentalLineNo',
  /** Aggregate result on EQMRentalDays */
  EqmRentalDays = 'EQMRentalDays',
  /** Aggregate result on EQMAttachedtoNo */
  EqmAttachedtoNo = 'EQMAttachedtoNo',
  /** Aggregate result on EQMAttachedtoLineNo */
  EqmAttachedtoLineNo = 'EQMAttachedtoLineNo',
  /** Aggregate result on EQMRentalFreeDays */
  EqmRentalFreeDays = 'EQMRentalFreeDays',
  /** Aggregate result on EQMLineUpdated */
  EqmLineUpdated = 'EQMLineUpdated',
  /** Aggregate result on EQMRentalSale */
  EqmRentalSale = 'EQMRentalSale',
  /** Aggregate result on EQMRentalQuantity */
  EqmRentalQuantity = 'EQMRentalQuantity',
  /** Aggregate result on EQMRentalNo */
  EqmRentalNo = 'EQMRentalNo',
  /** Aggregate result on EQMReturnDocumentNo */
  EqmReturnDocumentNo = 'EQMReturnDocumentNo',
  /** Aggregate result on EQMReturnLineNo */
  EqmReturnLineNo = 'EQMReturnLineNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMRentalFromDateTime */
  EqmRentalFromDateTime = 'EQMRentalFromDateTime',
  /** Aggregate result on EQMRentalToDateTime */
  EqmRentalToDateTime = 'EQMRentalToDateTime',
  /** Aggregate result on EQMRentalDuration */
  EqmRentalDuration = 'EQMRentalDuration',
  /** Aggregate result on EQMServiceUpwrite */
  EqmServiceUpwrite = 'EQMServiceUpwrite',
  /** Aggregate result on EQMAllocateObjectNo */
  EqmAllocateObjectNo = 'EQMAllocateObjectNo',
  /** Aggregate result on EQMRentalLineType */
  EqmRentalLineType = 'EQMRentalLineType',
  /** Aggregate result on EQMRentalLineTypeOPTION */
  EqmRentalLineTypeOption = 'EQMRentalLineTypeOPTION',
  /** Aggregate result on EQMUseOfFixPrice */
  EqmUseOfFixPrice = 'EQMUseOfFixPrice',
  /** Aggregate result on EQMUpdateRentalLine */
  EqmUpdateRentalLine = 'EQMUpdateRentalLine',
  /** Aggregate result on EQMWorkshop */
  EqmWorkshop = 'EQMWorkshop',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMWorkshopGuarantee */
  EqmWorkshopGuarantee = 'EQMWorkshopGuarantee',
  /** Aggregate result on EQMWorkshopType */
  EqmWorkshopType = 'EQMWorkshopType',
  /** Aggregate result on EQMWorkshopTypeOPTION */
  EqmWorkshopTypeOption = 'EQMWorkshopTypeOPTION',
  /** Aggregate result on EQMSkipObjectIntoInv */
  EqmSkipObjectIntoInv = 'EQMSkipObjectIntoInv',
  /** Aggregate result on EQMPriceTermCode */
  EqmPriceTermCode = 'EQMPriceTermCode',
  /** Aggregate result on EQMRefBasisEntryNo */
  EqmRefBasisEntryNo = 'EQMRefBasisEntryNo',
  /** Aggregate result on EQMStairPriceCode */
  EqmStairPriceCode = 'EQMStairPriceCode',
  /** Aggregate result on EQMStairPriceStep */
  EqmStairPriceStep = 'EQMStairPriceStep',
  /** Aggregate result on EQMRentalKITMother */
  EqmRentalKitMother = 'EQMRentalKITMother',
  /** Aggregate result on EQMRentalKITMotherLineNo */
  EqmRentalKitMotherLineNo = 'EQMRentalKITMotherLineNo',
  /** Aggregate result on EQMDepositPosting */
  EqmDepositPosting = 'EQMDepositPosting',
  /** Aggregate result on EQMOldTmpRKITLineNo */
  EqmOldTmpRkitLineNo = 'EQMOldTmpRKITLineNo',
  /** Aggregate result on EQMContractType */
  EqmContractType = 'EQMContractType',
  /** Aggregate result on EQMContractTypeOPTION */
  EqmContractTypeOption = 'EQMContractTypeOPTION',
  /** Aggregate result on EQMQtyonAtttoLineNo */
  EqmQtyonAtttoLineNo = 'EQMQtyonAtttoLineNo',
  /** Aggregate result on EQMReRentObject */
  EqmReRentObject = 'EQMReRentObject',
  /** Aggregate result on EQMUsedFractionCalculation */
  EqmUsedFractionCalculation = 'EQMUsedFractionCalculation',
  /** Aggregate result on EQMRentalHeaderLine */
  EqmRentalHeaderLine = 'EQMRentalHeaderLine',
  /** Aggregate result on EQMSerialNo */
  EqmSerialNo = 'EQMSerialNo',
  /** Aggregate result on EQMReclassOrderedBy */
  EqmReclassOrderedBy = 'EQMReclassOrderedBy',
  /** Aggregate result on EQMReclassOrderNo */
  EqmReclassOrderNo = 'EQMReclassOrderNo',
  /** Aggregate result on EQMExtraHoursCharged */
  EqmExtraHoursCharged = 'EQMExtraHoursCharged',
  /** Aggregate result on EQMExtraHoursReturnNo */
  EqmExtraHoursReturnNo = 'EQMExtraHoursReturnNo',
  /** Aggregate result on EQMRentalSplitCostCenter */
  EqmRentalSplitCostCenter = 'EQMRentalSplitCostCenter',
  /** Aggregate result on EQMRentalSplit */
  EqmRentalSplit = 'EQMRentalSplit',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMRePurchase */
  EqmRePurchase = 'EQMRePurchase',
  /** Aggregate result on EQMInvoiceFee */
  EqmInvoiceFee = 'EQMInvoiceFee',
  /** Aggregate result on EQMPriceScheduleCode */
  EqmPriceScheduleCode = 'EQMPriceScheduleCode',
  /** Aggregate result on EQMRentalPrepaymentEntry */
  EqmRentalPrepaymentEntry = 'EQMRentalPrepaymentEntry',
  /** Aggregate result on EQMPrepmtAmtLCY */
  EqmPrepmtAmtLcy = 'EQMPrepmtAmtLCY',
  /** Aggregate result on EQMRentalInsurance */
  EqmRentalInsurance = 'EQMRentalInsurance',
  /** Aggregate result on EQMObjectInsurance */
  EqmObjectInsurance = 'EQMObjectInsurance',
  /** Aggregate result on EQMServiceMotherLineNo */
  EqmServiceMotherLineNo = 'EQMServiceMotherLineNo',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMPriceReportedHours */
  EqmPriceReportedHours = 'EQMPriceReportedHours',
  /** Aggregate result on EQMPriceReportedHoursOPTION */
  EqmPriceReportedHoursOption = 'EQMPriceReportedHoursOPTION',
  /** Aggregate result on EQMFACorrection */
  EqmfaCorrection = 'EQMFACorrection',
  /** Aggregate result on EQMPrepmtAmtInclVAT */
  EqmPrepmtAmtInclVat = 'EQMPrepmtAmtInclVAT',
  /** Aggregate result on ReservedQuantity */
  ReservedQuantity = 'ReservedQuantity',
  /** Aggregate result on ATOWhseOutstandingQty */
  AtoWhseOutstandingQty = 'ATOWhseOutstandingQty',
  /** Aggregate result on ATOWhseOutstdQtyBase */
  AtoWhseOutstdQtyBase = 'ATOWhseOutstdQtyBase',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ReservedQtyBase */
  ReservedQtyBase = 'ReservedQtyBase',
  /** Aggregate result on SubstitutionAvailable */
  SubstitutionAvailable = 'SubstitutionAvailable',
  /** Aggregate result on WhseOutstandingQty */
  WhseOutstandingQty = 'WhseOutstandingQty',
  /** Aggregate result on WhseOutstandingQtyBase */
  WhseOutstandingQtyBase = 'WhseOutstandingQtyBase',
  /** Aggregate result on QtytoAssign */
  QtytoAssign = 'QtytoAssign',
  /** Aggregate result on QtyAssigned */
  QtyAssigned = 'QtyAssigned',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on ProductType */
  ProductType = 'ProductType',
  /** Aggregate result on SalesOrderEdgeGuid */
  SalesOrderEdgeGuid = 'SalesOrderEdgeGuid',
  /** Aggregate result on VersionNo */
  VersionNo = 'VersionNo',
  /** Aggregate result on DocNoOccurrence */
  DocNoOccurrence = 'DocNoOccurrence',
  /** Aggregate result on SalesOrderLineEdgeGuid */
  SalesOrderLineEdgeGuid = 'SalesOrderLineEdgeGuid',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesLineInput {
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on DocumentGlobalId */
  DocumentGlobalId = 'DocumentGlobalId',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on ItemGlobalId */
  ItemGlobalId = 'ItemGlobalId',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on OutstandingQuantity */
  OutstandingQuantity = 'OutstandingQuantity',
  /** Aggregate result on QtytoInvoice */
  QtytoInvoice = 'QtytoInvoice',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountPercent */
  LineDiscountPercent = 'LineDiscountPercent',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on OutstandingAmount */
  OutstandingAmount = 'OutstandingAmount',
  /** Aggregate result on QtyShippedNotInvoiced */
  QtyShippedNotInvoiced = 'QtyShippedNotInvoiced',
  /** Aggregate result on ShippedNotInvoiced */
  ShippedNotInvoiced = 'ShippedNotInvoiced',
  /** Aggregate result on QuantityShipped */
  QuantityShipped = 'QuantityShipped',
  /** Aggregate result on QuantityInvoiced */
  QuantityInvoiced = 'QuantityInvoiced',
  /** Aggregate result on ShipmentNo */
  ShipmentNo = 'ShipmentNo',
  /** Aggregate result on ShipmentLineNo */
  ShipmentLineNo = 'ShipmentLineNo',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on InvDiscountAmount */
  InvDiscountAmount = 'InvDiscountAmount',
  /** Aggregate result on PurchaseOrderNo */
  PurchaseOrderNo = 'PurchaseOrderNo',
  /** Aggregate result on PurchOrderLineNo */
  PurchOrderLineNo = 'PurchOrderLineNo',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxCategory */
  TaxCategory = 'TaxCategory',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATClauseCode */
  VatClauseCode = 'VATClauseCode',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on OutstandingAmountLCY */
  OutstandingAmountLcy = 'OutstandingAmountLCY',
  /** Aggregate result on ShippedNotInvoicedLCY */
  ShippedNotInvoicedLcy = 'ShippedNotInvoicedLCY',
  /** Aggregate result on ShippedNotInvLCYNoVAT */
  ShippedNotInvLcyNoVat = 'ShippedNotInvLCYNoVAT',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on InvDiscAmounttoInvoice */
  InvDiscAmounttoInvoice = 'InvDiscAmounttoInvoice',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepmtLineAmount */
  PrepmtLineAmount = 'PrepmtLineAmount',
  /** Aggregate result on PrepmtAmtInv */
  PrepmtAmtInv = 'PrepmtAmtInv',
  /** Aggregate result on PrepmtAmtInclVAT */
  PrepmtAmtInclVat = 'PrepmtAmtInclVAT',
  /** Aggregate result on PrepaymentAmount */
  PrepaymentAmount = 'PrepaymentAmount',
  /** Aggregate result on PrepmtVATBaseAmt */
  PrepmtVatBaseAmt = 'PrepmtVATBaseAmt',
  /** Aggregate result on PrepaymentVAT */
  PrepaymentVat = 'PrepaymentVAT',
  /** Aggregate result on PrepmtVATCalcType */
  PrepmtVatCalcType = 'PrepmtVATCalcType',
  /** Aggregate result on PrepmtVATCalcTypeOPTION */
  PrepmtVatCalcTypeOption = 'PrepmtVATCalcTypeOPTION',
  /** Aggregate result on PrepaymentVATIdentifier */
  PrepaymentVatIdentifier = 'PrepaymentVATIdentifier',
  /** Aggregate result on PrepaymentTaxAreaCode */
  PrepaymentTaxAreaCode = 'PrepaymentTaxAreaCode',
  /** Aggregate result on PrepaymentTaxLiable */
  PrepaymentTaxLiable = 'PrepaymentTaxLiable',
  /** Aggregate result on PrepaymentTaxGroupCode */
  PrepaymentTaxGroupCode = 'PrepaymentTaxGroupCode',
  /** Aggregate result on PrepmtAmttoDeduct */
  PrepmtAmttoDeduct = 'PrepmtAmttoDeduct',
  /** Aggregate result on PrepmtAmtDeducted */
  PrepmtAmtDeducted = 'PrepmtAmtDeducted',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on PrepmtAmountInvInclVAT */
  PrepmtAmountInvInclVat = 'PrepmtAmountInvInclVAT',
  /** Aggregate result on PrepmtAmountInvLCY */
  PrepmtAmountInvLcy = 'PrepmtAmountInvLCY',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PrepmtVATAmountInvLCY */
  PrepmtVatAmountInvLcy = 'PrepmtVATAmountInvLCY',
  /** Aggregate result on PrepaymentVATDifference */
  PrepaymentVatDifference = 'PrepaymentVATDifference',
  /** Aggregate result on PrepmtVATDifftoDeduct */
  PrepmtVatDifftoDeduct = 'PrepmtVATDifftoDeduct',
  /** Aggregate result on PrepmtVATDiffDeducted */
  PrepmtVatDiffDeducted = 'PrepmtVATDiffDeducted',
  /** Aggregate result on LineDiscountCalculation */
  LineDiscountCalculation = 'LineDiscountCalculation',
  /** Aggregate result on LineDiscountCalculationOPTION */
  LineDiscountCalculationOption = 'LineDiscountCalculationOPTION',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on QtytoAssembletoOrder */
  QtytoAssembletoOrder = 'QtytoAssembletoOrder',
  /** Aggregate result on QtytoAsmtoOrderBase */
  QtytoAsmtoOrderBase = 'QtytoAsmtoOrderBase',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobContractEntryNo */
  JobContractEntryNo = 'JobContractEntryNo',
  /** Aggregate result on DeferralCode */
  DeferralCode = 'DeferralCode',
  /** Aggregate result on ReturnsDeferralStartDate */
  ReturnsDeferralStartDate = 'ReturnsDeferralStartDate',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on Planned */
  Planned = 'Planned',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on OutstandingQtyBase */
  OutstandingQtyBase = 'OutstandingQtyBase',
  /** Aggregate result on QtytoInvoiceBase */
  QtytoInvoiceBase = 'QtytoInvoiceBase',
  /** Aggregate result on QtytoShipBase */
  QtytoShipBase = 'QtytoShipBase',
  /** Aggregate result on QtyShippedNotInvdBase */
  QtyShippedNotInvdBase = 'QtyShippedNotInvdBase',
  /** Aggregate result on QtyShippedBase */
  QtyShippedBase = 'QtyShippedBase',
  /** Aggregate result on QtyInvoicedBase */
  QtyInvoicedBase = 'QtyInvoicedBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on OutofStockSubstitution */
  OutofStockSubstitution = 'OutofStockSubstitution',
  /** Aggregate result on OriginallyOrderedNo */
  OriginallyOrderedNo = 'OriginallyOrderedNo',
  /** Aggregate result on OriginallyOrderedVarCode */
  OriginallyOrderedVarCode = 'OriginallyOrderedVarCode',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on SpecialOrder */
  SpecialOrder = 'SpecialOrder',
  /** Aggregate result on SpecialOrderPurchaseNo */
  SpecialOrderPurchaseNo = 'SpecialOrderPurchaseNo',
  /** Aggregate result on SpecialOrderPurchLineNo */
  SpecialOrderPurchLineNo = 'SpecialOrderPurchLineNo',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on PlannedDeliveryDate */
  PlannedDeliveryDate = 'PlannedDeliveryDate',
  /** Aggregate result on PlannedShipmentDate */
  PlannedShipmentDate = 'PlannedShipmentDate',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on AllowItemChargeAssignment */
  AllowItemChargeAssignment = 'AllowItemChargeAssignment',
  /** Aggregate result on ReturnQtytoReceive */
  ReturnQtytoReceive = 'ReturnQtytoReceive',
  /** Aggregate result on ReturnQtytoReceiveBase */
  ReturnQtytoReceiveBase = 'ReturnQtytoReceiveBase',
  /** Aggregate result on ReturnQtyRcdNotInvd */
  ReturnQtyRcdNotInvd = 'ReturnQtyRcdNotInvd',
  /** Aggregate result on RetQtyRcdNotInvdBase */
  RetQtyRcdNotInvdBase = 'RetQtyRcdNotInvdBase',
  /** Aggregate result on ReturnRcdNotInvd */
  ReturnRcdNotInvd = 'ReturnRcdNotInvd',
  /** Aggregate result on ReturnRcdNotInvdLCY */
  ReturnRcdNotInvdLcy = 'ReturnRcdNotInvdLCY',
  /** Aggregate result on ReturnQtyReceived */
  ReturnQtyReceived = 'ReturnQtyReceived',
  /** Aggregate result on ReturnQtyReceivedBase */
  ReturnQtyReceivedBase = 'ReturnQtyReceivedBase',
  /** Aggregate result on ApplfromItemEntry */
  ApplfromItemEntry = 'ApplfromItemEntry',
  /** Aggregate result on BOMItemNo */
  BomItemNo = 'BOMItemNo',
  /** Aggregate result on ReturnReceiptNo */
  ReturnReceiptNo = 'ReturnReceiptNo',
  /** Aggregate result on ReturnReceiptLineNo */
  ReturnReceiptLineNo = 'ReturnReceiptLineNo',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on Subtype */
  Subtype = 'Subtype',
  /** Aggregate result on SubtypeOPTION */
  SubtypeOption = 'SubtypeOPTION',
  /** Aggregate result on Pricedescription */
  Pricedescription = 'Pricedescription',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on EQMCustomerProject */
  EqmCustomerProject = 'EQMCustomerProject',
  /** Aggregate result on ChargeType */
  ChargeType = 'ChargeType',
  /** Aggregate result on ChargeTypeOPTION */
  ChargeTypeOption = 'ChargeTypeOPTION',
  /** Aggregate result on TextconnectedtoLineNo */
  TextconnectedtoLineNo = 'TextconnectedtoLineNo',
  /** Aggregate result on ConnectedtoItemLine */
  ConnectedtoItemLine = 'ConnectedtoItemLine',
  /** Aggregate result on EQMObjectNo */
  EqmObjectNo = 'EQMObjectNo',
  /** Aggregate result on EQMContractNo */
  EqmContractNo = 'EQMContractNo',
  /** Aggregate result on EQMRental */
  EqmRental = 'EQMRental',
  /** Aggregate result on EQMRentalFromDate */
  EqmRentalFromDate = 'EQMRentalFromDate',
  /** Aggregate result on EQMRentalToDate */
  EqmRentalToDate = 'EQMRentalToDate',
  /** Aggregate result on EQMRentalLineNo */
  EqmRentalLineNo = 'EQMRentalLineNo',
  /** Aggregate result on EQMRentalDays */
  EqmRentalDays = 'EQMRentalDays',
  /** Aggregate result on EQMAttachedtoNo */
  EqmAttachedtoNo = 'EQMAttachedtoNo',
  /** Aggregate result on EQMAttachedtoLineNo */
  EqmAttachedtoLineNo = 'EQMAttachedtoLineNo',
  /** Aggregate result on EQMRentalFreeDays */
  EqmRentalFreeDays = 'EQMRentalFreeDays',
  /** Aggregate result on EQMLineUpdated */
  EqmLineUpdated = 'EQMLineUpdated',
  /** Aggregate result on EQMRentalSale */
  EqmRentalSale = 'EQMRentalSale',
  /** Aggregate result on EQMRentalQuantity */
  EqmRentalQuantity = 'EQMRentalQuantity',
  /** Aggregate result on EQMRentalNo */
  EqmRentalNo = 'EQMRentalNo',
  /** Aggregate result on EQMReturnDocumentNo */
  EqmReturnDocumentNo = 'EQMReturnDocumentNo',
  /** Aggregate result on EQMReturnLineNo */
  EqmReturnLineNo = 'EQMReturnLineNo',
  /** Aggregate result on EQMObjectType */
  EqmObjectType = 'EQMObjectType',
  /** Aggregate result on EQMObjectGroup */
  EqmObjectGroup = 'EQMObjectGroup',
  /** Aggregate result on EQMRentalFromDateTime */
  EqmRentalFromDateTime = 'EQMRentalFromDateTime',
  /** Aggregate result on EQMRentalToDateTime */
  EqmRentalToDateTime = 'EQMRentalToDateTime',
  /** Aggregate result on EQMRentalDuration */
  EqmRentalDuration = 'EQMRentalDuration',
  /** Aggregate result on EQMServiceUpwrite */
  EqmServiceUpwrite = 'EQMServiceUpwrite',
  /** Aggregate result on EQMAllocateObjectNo */
  EqmAllocateObjectNo = 'EQMAllocateObjectNo',
  /** Aggregate result on EQMRentalLineType */
  EqmRentalLineType = 'EQMRentalLineType',
  /** Aggregate result on EQMRentalLineTypeOPTION */
  EqmRentalLineTypeOption = 'EQMRentalLineTypeOPTION',
  /** Aggregate result on EQMUseOfFixPrice */
  EqmUseOfFixPrice = 'EQMUseOfFixPrice',
  /** Aggregate result on EQMUpdateRentalLine */
  EqmUpdateRentalLine = 'EQMUpdateRentalLine',
  /** Aggregate result on EQMWorkshop */
  EqmWorkshop = 'EQMWorkshop',
  /** Aggregate result on EQMWorkshopObjectNo */
  EqmWorkshopObjectNo = 'EQMWorkshopObjectNo',
  /** Aggregate result on EQMWorkshopGuarantee */
  EqmWorkshopGuarantee = 'EQMWorkshopGuarantee',
  /** Aggregate result on EQMWorkshopType */
  EqmWorkshopType = 'EQMWorkshopType',
  /** Aggregate result on EQMWorkshopTypeOPTION */
  EqmWorkshopTypeOption = 'EQMWorkshopTypeOPTION',
  /** Aggregate result on EQMSkipObjectIntoInv */
  EqmSkipObjectIntoInv = 'EQMSkipObjectIntoInv',
  /** Aggregate result on EQMPriceTermCode */
  EqmPriceTermCode = 'EQMPriceTermCode',
  /** Aggregate result on EQMRefBasisEntryNo */
  EqmRefBasisEntryNo = 'EQMRefBasisEntryNo',
  /** Aggregate result on EQMStairPriceCode */
  EqmStairPriceCode = 'EQMStairPriceCode',
  /** Aggregate result on EQMStairPriceStep */
  EqmStairPriceStep = 'EQMStairPriceStep',
  /** Aggregate result on EQMRentalKITMother */
  EqmRentalKitMother = 'EQMRentalKITMother',
  /** Aggregate result on EQMRentalKITMotherLineNo */
  EqmRentalKitMotherLineNo = 'EQMRentalKITMotherLineNo',
  /** Aggregate result on EQMDepositPosting */
  EqmDepositPosting = 'EQMDepositPosting',
  /** Aggregate result on EQMOldTmpRKITLineNo */
  EqmOldTmpRkitLineNo = 'EQMOldTmpRKITLineNo',
  /** Aggregate result on EQMContractType */
  EqmContractType = 'EQMContractType',
  /** Aggregate result on EQMContractTypeOPTION */
  EqmContractTypeOption = 'EQMContractTypeOPTION',
  /** Aggregate result on EQMQtyonAtttoLineNo */
  EqmQtyonAtttoLineNo = 'EQMQtyonAtttoLineNo',
  /** Aggregate result on EQMReRentObject */
  EqmReRentObject = 'EQMReRentObject',
  /** Aggregate result on EQMUsedFractionCalculation */
  EqmUsedFractionCalculation = 'EQMUsedFractionCalculation',
  /** Aggregate result on EQMRentalHeaderLine */
  EqmRentalHeaderLine = 'EQMRentalHeaderLine',
  /** Aggregate result on EQMSerialNo */
  EqmSerialNo = 'EQMSerialNo',
  /** Aggregate result on EQMReclassOrderedBy */
  EqmReclassOrderedBy = 'EQMReclassOrderedBy',
  /** Aggregate result on EQMReclassOrderNo */
  EqmReclassOrderNo = 'EQMReclassOrderNo',
  /** Aggregate result on EQMExtraHoursCharged */
  EqmExtraHoursCharged = 'EQMExtraHoursCharged',
  /** Aggregate result on EQMExtraHoursReturnNo */
  EqmExtraHoursReturnNo = 'EQMExtraHoursReturnNo',
  /** Aggregate result on EQMRentalSplitCostCenter */
  EqmRentalSplitCostCenter = 'EQMRentalSplitCostCenter',
  /** Aggregate result on EQMRentalSplit */
  EqmRentalSplit = 'EQMRentalSplit',
  /** Aggregate result on EQMReceivingRespCenter */
  EqmReceivingRespCenter = 'EQMReceivingRespCenter',
  /** Aggregate result on EQMReceivingCostCenterCode */
  EqmReceivingCostCenterCode = 'EQMReceivingCostCenterCode',
  /** Aggregate result on EQMRePurchase */
  EqmRePurchase = 'EQMRePurchase',
  /** Aggregate result on EQMInvoiceFee */
  EqmInvoiceFee = 'EQMInvoiceFee',
  /** Aggregate result on EQMPriceScheduleCode */
  EqmPriceScheduleCode = 'EQMPriceScheduleCode',
  /** Aggregate result on EQMRentalPrepaymentEntry */
  EqmRentalPrepaymentEntry = 'EQMRentalPrepaymentEntry',
  /** Aggregate result on EQMPrepmtAmtLCY */
  EqmPrepmtAmtLcy = 'EQMPrepmtAmtLCY',
  /** Aggregate result on EQMRentalInsurance */
  EqmRentalInsurance = 'EQMRentalInsurance',
  /** Aggregate result on EQMObjectInsurance */
  EqmObjectInsurance = 'EQMObjectInsurance',
  /** Aggregate result on EQMServiceMotherLineNo */
  EqmServiceMotherLineNo = 'EQMServiceMotherLineNo',
  /** Aggregate result on EQMRepairCode */
  EqmRepairCode = 'EQMRepairCode',
  /** Aggregate result on EQMPriceReportedHours */
  EqmPriceReportedHours = 'EQMPriceReportedHours',
  /** Aggregate result on EQMPriceReportedHoursOPTION */
  EqmPriceReportedHoursOption = 'EQMPriceReportedHoursOPTION',
  /** Aggregate result on EQMFACorrection */
  EqmfaCorrection = 'EQMFACorrection',
  /** Aggregate result on EQMPrepmtAmtInclVAT */
  EqmPrepmtAmtInclVat = 'EQMPrepmtAmtInclVAT',
  /** Aggregate result on ReservedQuantity */
  ReservedQuantity = 'ReservedQuantity',
  /** Aggregate result on ATOWhseOutstandingQty */
  AtoWhseOutstandingQty = 'ATOWhseOutstandingQty',
  /** Aggregate result on ATOWhseOutstdQtyBase */
  AtoWhseOutstdQtyBase = 'ATOWhseOutstdQtyBase',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ReservedQtyBase */
  ReservedQtyBase = 'ReservedQtyBase',
  /** Aggregate result on SubstitutionAvailable */
  SubstitutionAvailable = 'SubstitutionAvailable',
  /** Aggregate result on WhseOutstandingQty */
  WhseOutstandingQty = 'WhseOutstandingQty',
  /** Aggregate result on WhseOutstandingQtyBase */
  WhseOutstandingQtyBase = 'WhseOutstandingQtyBase',
  /** Aggregate result on QtytoAssign */
  QtytoAssign = 'QtytoAssign',
  /** Aggregate result on QtyAssigned */
  QtyAssigned = 'QtyAssigned',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on MagentoId */
  MagentoId = 'MagentoId',
  /** Aggregate result on ProductType */
  ProductType = 'ProductType',
  /** Aggregate result on SalesOrderLineEdgeGuid */
  SalesOrderLineEdgeGuid = 'SalesOrderLineEdgeGuid',
  /** Aggregate result on LinkedToGlobalId */
  LinkedToGlobalId = 'LinkedToGlobalId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesLineRequestInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on OutstandingQuantity */
  OutstandingQuantity = 'OutstandingQuantity',
  /** Aggregate result on QtytoInvoice */
  QtytoInvoice = 'QtytoInvoice',
  /** Aggregate result on QtytoShip */
  QtytoShip = 'QtytoShip',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on LineDiscountAmount */
  LineDiscountAmount = 'LineDiscountAmount',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on RecalculateInvoiceDisc */
  RecalculateInvoiceDisc = 'RecalculateInvoiceDisc',
  /** Aggregate result on OutstandingAmount */
  OutstandingAmount = 'OutstandingAmount',
  /** Aggregate result on QtyShippedNotInvoiced */
  QtyShippedNotInvoiced = 'QtyShippedNotInvoiced',
  /** Aggregate result on ShippedNotInvoiced */
  ShippedNotInvoiced = 'ShippedNotInvoiced',
  /** Aggregate result on QuantityShipped */
  QuantityShipped = 'QuantityShipped',
  /** Aggregate result on QuantityInvoiced */
  QuantityInvoiced = 'QuantityInvoiced',
  /** Aggregate result on ShipmentNo */
  ShipmentNo = 'ShipmentNo',
  /** Aggregate result on ShipmentLineNo */
  ShipmentLineNo = 'ShipmentLineNo',
  /** Aggregate result on Profit */
  Profit = 'Profit',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on InvDiscountAmount */
  InvDiscountAmount = 'InvDiscountAmount',
  /** Aggregate result on PurchaseOrderNo */
  PurchaseOrderNo = 'PurchaseOrderNo',
  /** Aggregate result on PurchOrderLineNo */
  PurchOrderLineNo = 'PurchOrderLineNo',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATClauseCode */
  VatClauseCode = 'VATClauseCode',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on OutstandingAmountLCY */
  OutstandingAmountLcy = 'OutstandingAmountLCY',
  /** Aggregate result on ShippedNotInvoicedLCY */
  ShippedNotInvoicedLcy = 'ShippedNotInvoicedLCY',
  /** Aggregate result on Reserve */
  Reserve = 'Reserve',
  /** Aggregate result on ReserveOPTION */
  ReserveOption = 'ReserveOPTION',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on SystemCreatedEntry */
  SystemCreatedEntry = 'SystemCreatedEntry',
  /** Aggregate result on LineAmount */
  LineAmount = 'LineAmount',
  /** Aggregate result on VATDifference */
  VatDifference = 'VATDifference',
  /** Aggregate result on InvDiscAmounttoInvoice */
  InvDiscAmounttoInvoice = 'InvDiscAmounttoInvoice',
  /** Aggregate result on VATIdentifier */
  VatIdentifier = 'VATIdentifier',
  /** Aggregate result on ICPartnerRefType */
  IcPartnerRefType = 'ICPartnerRefType',
  /** Aggregate result on ICPartnerRefTypeOPTION */
  IcPartnerRefTypeOption = 'ICPartnerRefTypeOPTION',
  /** Aggregate result on ICPartnerReference */
  IcPartnerReference = 'ICPartnerReference',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PrepmtLineAmount */
  PrepmtLineAmount = 'PrepmtLineAmount',
  /** Aggregate result on PrepmtAmtInv */
  PrepmtAmtInv = 'PrepmtAmtInv',
  /** Aggregate result on PrepmtAmtInclVAT */
  PrepmtAmtInclVat = 'PrepmtAmtInclVAT',
  /** Aggregate result on PrepaymentAmount */
  PrepaymentAmount = 'PrepaymentAmount',
  /** Aggregate result on PrepmtVATBaseAmt */
  PrepmtVatBaseAmt = 'PrepmtVATBaseAmt',
  /** Aggregate result on PrepaymentVAT */
  PrepaymentVat = 'PrepaymentVAT',
  /** Aggregate result on PrepmtVATCalcType */
  PrepmtVatCalcType = 'PrepmtVATCalcType',
  /** Aggregate result on PrepmtVATCalcTypeOPTION */
  PrepmtVatCalcTypeOption = 'PrepmtVATCalcTypeOPTION',
  /** Aggregate result on PrepaymentVATIdentifier */
  PrepaymentVatIdentifier = 'PrepaymentVATIdentifier',
  /** Aggregate result on PrepaymentTaxAreaCode */
  PrepaymentTaxAreaCode = 'PrepaymentTaxAreaCode',
  /** Aggregate result on PrepaymentTaxLiable */
  PrepaymentTaxLiable = 'PrepaymentTaxLiable',
  /** Aggregate result on PrepaymentTaxGroupCode */
  PrepaymentTaxGroupCode = 'PrepaymentTaxGroupCode',
  /** Aggregate result on PrepmtAmttoDeduct */
  PrepmtAmttoDeduct = 'PrepmtAmttoDeduct',
  /** Aggregate result on PrepmtAmtDeducted */
  PrepmtAmtDeducted = 'PrepmtAmtDeducted',
  /** Aggregate result on PrepaymentLine */
  PrepaymentLine = 'PrepaymentLine',
  /** Aggregate result on PrepmtAmountInvInclVAT */
  PrepmtAmountInvInclVat = 'PrepmtAmountInvInclVAT',
  /** Aggregate result on PrepmtAmountInvLCY */
  PrepmtAmountInvLcy = 'PrepmtAmountInvLCY',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on PrepmtVATAmountInvLCY */
  PrepmtVatAmountInvLcy = 'PrepmtVATAmountInvLCY',
  /** Aggregate result on PrepaymentVATDifference */
  PrepaymentVatDifference = 'PrepaymentVATDifference',
  /** Aggregate result on PrepmtVATDifftoDeduct */
  PrepmtVatDifftoDeduct = 'PrepmtVATDifftoDeduct',
  /** Aggregate result on PrepmtVATDiffDeducted */
  PrepmtVatDiffDeducted = 'PrepmtVATDiffDeducted',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on QtytoAssembletoOrder */
  QtytoAssembletoOrder = 'QtytoAssembletoOrder',
  /** Aggregate result on QtytoAsmtoOrderBase */
  QtytoAsmtoOrderBase = 'QtytoAsmtoOrderBase',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobContractEntryNo */
  JobContractEntryNo = 'JobContractEntryNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on Planned */
  Planned = 'Planned',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on OutstandingQtyBase */
  OutstandingQtyBase = 'OutstandingQtyBase',
  /** Aggregate result on QtytoInvoiceBase */
  QtytoInvoiceBase = 'QtytoInvoiceBase',
  /** Aggregate result on QtytoShipBase */
  QtytoShipBase = 'QtytoShipBase',
  /** Aggregate result on QtyShippedNotInvdBase */
  QtyShippedNotInvdBase = 'QtyShippedNotInvdBase',
  /** Aggregate result on QtyShippedBase */
  QtyShippedBase = 'QtyShippedBase',
  /** Aggregate result on QtyInvoicedBase */
  QtyInvoicedBase = 'QtyInvoicedBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on OutofStockSubstitution */
  OutofStockSubstitution = 'OutofStockSubstitution',
  /** Aggregate result on OriginallyOrderedNo */
  OriginallyOrderedNo = 'OriginallyOrderedNo',
  /** Aggregate result on OriginallyOrderedVarCode */
  OriginallyOrderedVarCode = 'OriginallyOrderedVarCode',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on SpecialOrder */
  SpecialOrder = 'SpecialOrder',
  /** Aggregate result on SpecialOrderPurchaseNo */
  SpecialOrderPurchaseNo = 'SpecialOrderPurchaseNo',
  /** Aggregate result on SpecialOrderPurchLineNo */
  SpecialOrderPurchLineNo = 'SpecialOrderPurchLineNo',
  /** Aggregate result on CompletelyShipped */
  CompletelyShipped = 'CompletelyShipped',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on PlannedDeliveryDate */
  PlannedDeliveryDate = 'PlannedDeliveryDate',
  /** Aggregate result on PlannedShipmentDate */
  PlannedShipmentDate = 'PlannedShipmentDate',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on AllowItemChargeAssignment */
  AllowItemChargeAssignment = 'AllowItemChargeAssignment',
  /** Aggregate result on ReturnQtytoReceive */
  ReturnQtytoReceive = 'ReturnQtytoReceive',
  /** Aggregate result on ReturnQtytoReceiveBase */
  ReturnQtytoReceiveBase = 'ReturnQtytoReceiveBase',
  /** Aggregate result on ReturnQtyRcdNotInvd */
  ReturnQtyRcdNotInvd = 'ReturnQtyRcdNotInvd',
  /** Aggregate result on RetQtyRcdNotInvdBase */
  RetQtyRcdNotInvdBase = 'RetQtyRcdNotInvdBase',
  /** Aggregate result on ReturnRcdNotInvd */
  ReturnRcdNotInvd = 'ReturnRcdNotInvd',
  /** Aggregate result on ReturnRcdNotInvdLCY */
  ReturnRcdNotInvdLcy = 'ReturnRcdNotInvdLCY',
  /** Aggregate result on ReturnQtyReceived */
  ReturnQtyReceived = 'ReturnQtyReceived',
  /** Aggregate result on ReturnQtyReceivedBase */
  ReturnQtyReceivedBase = 'ReturnQtyReceivedBase',
  /** Aggregate result on ApplfromItemEntry */
  ApplfromItemEntry = 'ApplfromItemEntry',
  /** Aggregate result on BOMItemNo */
  BomItemNo = 'BOMItemNo',
  /** Aggregate result on ReturnReceiptNo */
  ReturnReceiptNo = 'ReturnReceiptNo',
  /** Aggregate result on ReturnReceiptLineNo */
  ReturnReceiptLineNo = 'ReturnReceiptLineNo',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on PeriodicTemplateCode */
  PeriodicTemplateCode = 'PeriodicTemplateCode',
  /** Aggregate result on PeriodicStartingDate */
  PeriodicStartingDate = 'PeriodicStartingDate',
  /** Aggregate result on NAVStatus */
  NavStatus = 'NAVStatus',
  /** Aggregate result on NAVStatusOPTION */
  NavStatusOption = 'NAVStatusOPTION',
  /** Aggregate result on NAVImportedDate */
  NavImportedDate = 'NAVImportedDate',
  /** Aggregate result on NAVOrderNo */
  NavOrderNo = 'NAVOrderNo',
  /** Aggregate result on Rubrikrad */
  Rubrikrad = 'Rubrikrad',
  /** Aggregate result on EndofGroup */
  EndofGroup = 'EndofGroup',
  /** Aggregate result on ChargeType */
  ChargeType = 'ChargeType',
  /** Aggregate result on ChargeTypeOPTION */
  ChargeTypeOption = 'ChargeTypeOPTION',
  /** Aggregate result on TextconnectedtoLineNo */
  TextconnectedtoLineNo = 'TextconnectedtoLineNo',
  /** Aggregate result on ConnectedtoItemLine */
  ConnectedtoItemLine = 'ConnectedtoItemLine',
  /** Aggregate result on FreeTextLine */
  FreeTextLine = 'FreeTextLine',
  /** Aggregate result on ReservedQuantity */
  ReservedQuantity = 'ReservedQuantity',
  /** Aggregate result on ATOWhseOutstandingQty */
  AtoWhseOutstandingQty = 'ATOWhseOutstandingQty',
  /** Aggregate result on ATOWhseOutstdQtyBase */
  AtoWhseOutstdQtyBase = 'ATOWhseOutstdQtyBase',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ReservedQtyBase */
  ReservedQtyBase = 'ReservedQtyBase',
  /** Aggregate result on SubstitutionAvailable */
  SubstitutionAvailable = 'SubstitutionAvailable',
  /** Aggregate result on WhseOutstandingQty */
  WhseOutstandingQty = 'WhseOutstandingQty',
  /** Aggregate result on WhseOutstandingQtyBase */
  WhseOutstandingQtyBase = 'WhseOutstandingQtyBase',
  /** Aggregate result on QtytoAssign */
  QtytoAssign = 'QtytoAssign',
  /** Aggregate result on QtyAssigned */
  QtyAssigned = 'QtyAssigned',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on NAVErrorMessage */
  NavErrorMessage = 'NAVErrorMessage',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesPriceInput {
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on SalesType */
  SalesType = 'SalesType',
  /** Aggregate result on SalesTypeOPTION */
  SalesTypeOption = 'SalesTypeOPTION',
  /** Aggregate result on SalesCode */
  SalesCode = 'SalesCode',
  /** Aggregate result on StartingDate */
  StartingDate = 'StartingDate',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on MinimumQuantity */
  MinimumQuantity = 'MinimumQuantity',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on PriceIncludesVAT */
  PriceIncludesVat = 'PriceIncludesVAT',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on VATBusPostingGrPrice */
  VatBusPostingGrPrice = 'VATBusPostingGrPrice',
  /** Aggregate result on EndingDate */
  EndingDate = 'EndingDate',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on UnitPriceIncVAT */
  UnitPriceIncVat = 'UnitPriceIncVAT',
  /** Aggregate result on SalesOrderMultiple */
  SalesOrderMultiple = 'SalesOrderMultiple',
  /** Aggregate result on CarepaPurchPrice */
  CarepaPurchPrice = 'CarepaPurchPrice',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesShipmentHeaderInput {
  /** Aggregate result on MagentoOrder */
  MagentoOrder = 'MagentoOrder',
  /** Aggregate result on MagentoOrderType */
  MagentoOrderType = 'MagentoOrderType',
  /** Aggregate result on MagentoOrderTypeOPTION */
  MagentoOrderTypeOption = 'MagentoOrderTypeOPTION',
  /** Aggregate result on MagentoOrderId */
  MagentoOrderId = 'MagentoOrderId',
  /** Aggregate result on MagentoOrderEmail */
  MagentoOrderEmail = 'MagentoOrderEmail',
  /** Aggregate result on MagentoOrderMobileNo */
  MagentoOrderMobileNo = 'MagentoOrderMobileNo',
  /** Aggregate result on MagentoOrderLinesCount */
  MagentoOrderLinesCount = 'MagentoOrderLinesCount',
  /** Aggregate result on PEBNoteofGoods */
  PebNoteofGoods = 'PEBNoteofGoods',
  /** Aggregate result on ShippingAgentServiceCode */
  ShippingAgentServiceCode = 'ShippingAgentServiceCode',
  /** Aggregate result on EdgeRetryCount */
  EdgeRetryCount = 'EdgeRetryCount',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on BilltoName */
  BilltoName = 'BilltoName',
  /** Aggregate result on BilltoName2 */
  BilltoName2 = 'BilltoName2',
  /** Aggregate result on BilltoAddress */
  BilltoAddress = 'BilltoAddress',
  /** Aggregate result on BilltoAddress2 */
  BilltoAddress2 = 'BilltoAddress2',
  /** Aggregate result on BilltoCity */
  BilltoCity = 'BilltoCity',
  /** Aggregate result on BilltoContact */
  BilltoContact = 'BilltoContact',
  /** Aggregate result on YourReference */
  YourReference = 'YourReference',
  /** Aggregate result on ShiptoCode */
  ShiptoCode = 'ShiptoCode',
  /** Aggregate result on ShiptoName */
  ShiptoName = 'ShiptoName',
  /** Aggregate result on ShiptoName2 */
  ShiptoName2 = 'ShiptoName2',
  /** Aggregate result on ShiptoAddress */
  ShiptoAddress = 'ShiptoAddress',
  /** Aggregate result on ShiptoAddress2 */
  ShiptoAddress2 = 'ShiptoAddress2',
  /** Aggregate result on ShiptoCity */
  ShiptoCity = 'ShiptoCity',
  /** Aggregate result on ShiptoContact */
  ShiptoContact = 'ShiptoContact',
  /** Aggregate result on OrderDate */
  OrderDate = 'OrderDate',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on PostingDescription */
  PostingDescription = 'PostingDescription',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on DueDate */
  DueDate = 'DueDate',
  /** Aggregate result on PaymentDiscount */
  PaymentDiscount = 'PaymentDiscount',
  /** Aggregate result on PmtDiscountDate */
  PmtDiscountDate = 'PmtDiscountDate',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPostingGroup */
  CustomerPostingGroup = 'CustomerPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CurrencyFactor */
  CurrencyFactor = 'CurrencyFactor',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on SalespersonCode */
  SalespersonCode = 'SalespersonCode',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on NoPrinted */
  NoPrinted = 'NoPrinted',
  /** Aggregate result on OnHold */
  OnHold = 'OnHold',
  /** Aggregate result on AppliestoDocType */
  AppliestoDocType = 'AppliestoDocType',
  /** Aggregate result on AppliestoDocTypeOPTION */
  AppliestoDocTypeOption = 'AppliestoDocTypeOPTION',
  /** Aggregate result on AppliestoDocNo */
  AppliestoDocNo = 'AppliestoDocNo',
  /** Aggregate result on BalAccountNo */
  BalAccountNo = 'BalAccountNo',
  /** Aggregate result on Amount */
  Amount = 'Amount',
  /** Aggregate result on AmountIncludingVAT */
  AmountIncludingVat = 'AmountIncludingVAT',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on ReasonCode */
  ReasonCode = 'ReasonCode',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on EU3PartyTrade */
  Eu3PartyTrade = 'EU3PartyTrade',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on VATCountryRegionCode */
  VatCountryRegionCode = 'VATCountryRegionCode',
  /** Aggregate result on SelltoCustomerName */
  SelltoCustomerName = 'SelltoCustomerName',
  /** Aggregate result on SelltoCustomerName2 */
  SelltoCustomerName2 = 'SelltoCustomerName2',
  /** Aggregate result on SelltoAddress */
  SelltoAddress = 'SelltoAddress',
  /** Aggregate result on SelltoAddress2 */
  SelltoAddress2 = 'SelltoAddress2',
  /** Aggregate result on SelltoCity */
  SelltoCity = 'SelltoCity',
  /** Aggregate result on SelltoContact */
  SelltoContact = 'SelltoContact',
  /** Aggregate result on BilltoPostCode */
  BilltoPostCode = 'BilltoPostCode',
  /** Aggregate result on BilltoCounty */
  BilltoCounty = 'BilltoCounty',
  /** Aggregate result on BilltoCountryRegionCode */
  BilltoCountryRegionCode = 'BilltoCountryRegionCode',
  /** Aggregate result on SelltoPostCode */
  SelltoPostCode = 'SelltoPostCode',
  /** Aggregate result on SelltoCounty */
  SelltoCounty = 'SelltoCounty',
  /** Aggregate result on SelltoCountryRegionCode */
  SelltoCountryRegionCode = 'SelltoCountryRegionCode',
  /** Aggregate result on ShiptoPostCode */
  ShiptoPostCode = 'ShiptoPostCode',
  /** Aggregate result on ShiptoCounty */
  ShiptoCounty = 'ShiptoCounty',
  /** Aggregate result on ShiptoCountryRegionCode */
  ShiptoCountryRegionCode = 'ShiptoCountryRegionCode',
  /** Aggregate result on BalAccountType */
  BalAccountType = 'BalAccountType',
  /** Aggregate result on BalAccountTypeOPTION */
  BalAccountTypeOption = 'BalAccountTypeOPTION',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on DocumentDate */
  DocumentDate = 'DocumentDate',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on PackageTrackingNo */
  PackageTrackingNo = 'PackageTrackingNo',
  /** Aggregate result on PreAssignedNoSeries */
  PreAssignedNoSeries = 'PreAssignedNoSeries',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on OrderNoSeries */
  OrderNoSeries = 'OrderNoSeries',
  /** Aggregate result on PreAssignedNo */
  PreAssignedNo = 'PreAssignedNo',
  /** Aggregate result on UserID */
  UserId = 'UserID',
  /** Aggregate result on SourceCode */
  SourceCode = 'SourceCode',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATBaseDiscount */
  VatBaseDiscount = 'VATBaseDiscount',
  /** Aggregate result on PrepaymentNoSeries */
  PrepaymentNoSeries = 'PrepaymentNoSeries',
  /** Aggregate result on PrepaymentInvoice */
  PrepaymentInvoice = 'PrepaymentInvoice',
  /** Aggregate result on PrepaymentOrderNo */
  PrepaymentOrderNo = 'PrepaymentOrderNo',
  /** Aggregate result on QuoteNo */
  QuoteNo = 'QuoteNo',
  /** Aggregate result on WorkDescription */
  WorkDescription = 'WorkDescription',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on PaymentServiceSetID */
  PaymentServiceSetId = 'PaymentServiceSetID',
  /** Aggregate result on DocumentExchangeIdentifier */
  DocumentExchangeIdentifier = 'DocumentExchangeIdentifier',
  /** Aggregate result on DocumentExchangeStatus */
  DocumentExchangeStatus = 'DocumentExchangeStatus',
  /** Aggregate result on DocumentExchangeStatusOPTION */
  DocumentExchangeStatusOption = 'DocumentExchangeStatusOPTION',
  /** Aggregate result on DocExchOriginalIdentifier */
  DocExchOriginalIdentifier = 'DocExchOriginalIdentifier',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on DirectDebitMandateID */
  DirectDebitMandateId = 'DirectDebitMandateID',
  /** Aggregate result on Closed */
  Closed = 'Closed',
  /** Aggregate result on RemainingAmount */
  RemainingAmount = 'RemainingAmount',
  /** Aggregate result on CustLedgerEntryNo */
  CustLedgerEntryNo = 'CustLedgerEntryNo',
  /** Aggregate result on InvoiceDiscountAmount */
  InvoiceDiscountAmount = 'InvoiceDiscountAmount',
  /** Aggregate result on Cancelled */
  Cancelled = 'Cancelled',
  /** Aggregate result on Corrective */
  Corrective = 'Corrective',
  /** Aggregate result on CampaignNo */
  CampaignNo = 'CampaignNo',
  /** Aggregate result on SelltoContactNo */
  SelltoContactNo = 'SelltoContactNo',
  /** Aggregate result on BilltoContactNo */
  BilltoContactNo = 'BilltoContactNo',
  /** Aggregate result on OpportunityNo */
  OpportunityNo = 'OpportunityNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on GetShipmentUsed */
  GetShipmentUsed = 'GetShipmentUsed',
  /** Aggregate result on Preseason */
  Preseason = 'Preseason',
  /** Aggregate result on AdditionalTelephone */
  AdditionalTelephone = 'AdditionalTelephone',
  /** Aggregate result on AdditionalCustomerNote */
  AdditionalCustomerNote = 'AdditionalCustomerNote',
  /** Aggregate result on CustomerCommentExists */
  CustomerCommentExists = 'CustomerCommentExists',
  /** Aggregate result on WebOrderNo */
  WebOrderNo = 'WebOrderNo',
  /** Aggregate result on WebOrderEntityNo */
  WebOrderEntityNo = 'WebOrderEntityNo',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on EMailOrderConfirmation */
  EMailOrderConfirmation = 'EMailOrderConfirmation',
  /** Aggregate result on EMailSalesShipment */
  EMailSalesShipment = 'EMailSalesShipment',
  /** Aggregate result on EMailInvoice */
  EMailInvoice = 'EMailInvoice',
  /** Aggregate result on EMailShipmentNotification */
  EMailShipmentNotification = 'EMailShipmentNotification',
  /** Aggregate result on OrderCreatedBy */
  OrderCreatedBy = 'OrderCreatedBy',
  /** Aggregate result on OrderCreated */
  OrderCreated = 'OrderCreated',
  /** Aggregate result on OrderType */
  OrderType = 'OrderType',
  /** Aggregate result on OrderTypeOPTION */
  OrderTypeOption = 'OrderTypeOPTION',
  /** Aggregate result on ShipmentDocType */
  ShipmentDocType = 'ShipmentDocType',
  /** Aggregate result on ShipmentDocTypeOPTION */
  ShipmentDocTypeOption = 'ShipmentDocTypeOPTION',
  /** Aggregate result on GearsOrder */
  GearsOrder = 'GearsOrder',
  /** Aggregate result on NumberofCustomerPriceGroup */
  NumberofCustomerPriceGroup = 'NumberofCustomerPriceGroup',
  /** Aggregate result on EDISetupCode */
  EdiSetupCode = 'EDISetupCode',
  /** Aggregate result on InvoiceDocumentType */
  InvoiceDocumentType = 'InvoiceDocumentType',
  /** Aggregate result on InvoiceDocumentTypeOPTION */
  InvoiceDocumentTypeOption = 'InvoiceDocumentTypeOPTION',
  /** Aggregate result on InvoiceSentToCustomer */
  InvoiceSentToCustomer = 'InvoiceSentToCustomer',
  /** Aggregate result on InvoiceSentToCustomerDate */
  InvoiceSentToCustomerDate = 'InvoiceSentToCustomerDate',
  /** Aggregate result on InvoiceSentToCustomerTime */
  InvoiceSentToCustomerTime = 'InvoiceSentToCustomerTime',
  /** Aggregate result on NoteofGoods */
  NoteofGoods = 'NoteofGoods',
  /** Aggregate result on DocumentGlobalId */
  DocumentGlobalId = 'DocumentGlobalId',
  /** Aggregate result on FreightOnlyShipment */
  FreightOnlyShipment = 'FreightOnlyShipment',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalesShipmentLineInput {
  /** Aggregate result on MagentoOrderLineType */
  MagentoOrderLineType = 'MagentoOrderLineType',
  /** Aggregate result on MagentoOrderLineTypeOPTION */
  MagentoOrderLineTypeOption = 'MagentoOrderLineTypeOPTION',
  /** Aggregate result on MagentoOrderLineNo */
  MagentoOrderLineNo = 'MagentoOrderLineNo',
  /** Aggregate result on MagentoOrderLineQty */
  MagentoOrderLineQty = 'MagentoOrderLineQty',
  /** Aggregate result on SelltoCustomerNo */
  SelltoCustomerNo = 'SelltoCustomerNo',
  /** Aggregate result on DocumentNo */
  DocumentNo = 'DocumentNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on PostingGroup */
  PostingGroup = 'PostingGroup',
  /** Aggregate result on ShipmentDate */
  ShipmentDate = 'ShipmentDate',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on UnitofMeasure */
  UnitofMeasure = 'UnitofMeasure',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on UnitCostLCY */
  UnitCostLcy = 'UnitCostLCY',
  /** Aggregate result on VAT */
  Vat = 'VAT',
  /** Aggregate result on LineDiscount */
  LineDiscount = 'LineDiscount',
  /** Aggregate result on AllowInvoiceDisc */
  AllowInvoiceDisc = 'AllowInvoiceDisc',
  /** Aggregate result on GrossWeight */
  GrossWeight = 'GrossWeight',
  /** Aggregate result on NetWeight */
  NetWeight = 'NetWeight',
  /** Aggregate result on UnitsperParcel */
  UnitsperParcel = 'UnitsperParcel',
  /** Aggregate result on UnitVolume */
  UnitVolume = 'UnitVolume',
  /** Aggregate result on AppltoItemEntry */
  AppltoItemEntry = 'AppltoItemEntry',
  /** Aggregate result on ItemShptEntryNo */
  ItemShptEntryNo = 'ItemShptEntryNo',
  /** Aggregate result on ShortcutDimension1Code */
  ShortcutDimension1Code = 'ShortcutDimension1Code',
  /** Aggregate result on ShortcutDimension2Code */
  ShortcutDimension2Code = 'ShortcutDimension2Code',
  /** Aggregate result on CustomerPriceGroup */
  CustomerPriceGroup = 'CustomerPriceGroup',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on QtyShippedNotInvoiced */
  QtyShippedNotInvoiced = 'QtyShippedNotInvoiced',
  /** Aggregate result on QuantityInvoiced */
  QuantityInvoiced = 'QuantityInvoiced',
  /** Aggregate result on OrderNo */
  OrderNo = 'OrderNo',
  /** Aggregate result on OrderLineNo */
  OrderLineNo = 'OrderLineNo',
  /** Aggregate result on BilltoCustomerNo */
  BilltoCustomerNo = 'BilltoCustomerNo',
  /** Aggregate result on PurchaseOrderNo */
  PurchaseOrderNo = 'PurchaseOrderNo',
  /** Aggregate result on PurchOrderLineNo */
  PurchOrderLineNo = 'PurchOrderLineNo',
  /** Aggregate result on DropShipment */
  DropShipment = 'DropShipment',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATCalculationType */
  VatCalculationType = 'VATCalculationType',
  /** Aggregate result on VATCalculationTypeOPTION */
  VatCalculationTypeOption = 'VATCalculationTypeOPTION',
  /** Aggregate result on TransactionType */
  TransactionType = 'TransactionType',
  /** Aggregate result on TransportMethod */
  TransportMethod = 'TransportMethod',
  /** Aggregate result on AttachedtoLineNo */
  AttachedtoLineNo = 'AttachedtoLineNo',
  /** Aggregate result on ExitPoint */
  ExitPoint = 'ExitPoint',
  /** Aggregate result on Area */
  Area = 'Area',
  /** Aggregate result on TransactionSpecification */
  TransactionSpecification = 'TransactionSpecification',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on TaxGroupCode */
  TaxGroupCode = 'TaxGroupCode',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on BlanketOrderNo */
  BlanketOrderNo = 'BlanketOrderNo',
  /** Aggregate result on BlanketOrderLineNo */
  BlanketOrderLineNo = 'BlanketOrderLineNo',
  /** Aggregate result on VATBaseAmount */
  VatBaseAmount = 'VATBaseAmount',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on PostingDate */
  PostingDate = 'PostingDate',
  /** Aggregate result on DimensionSetID */
  DimensionSetId = 'DimensionSetID',
  /** Aggregate result on AuthorizedforCreditCard */
  AuthorizedforCreditCard = 'AuthorizedforCreditCard',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on JobContractEntryNo */
  JobContractEntryNo = 'JobContractEntryNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on BinCode */
  BinCode = 'BinCode',
  /** Aggregate result on QtyperUnitofMeasure */
  QtyperUnitofMeasure = 'QtyperUnitofMeasure',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on QuantityBase */
  QuantityBase = 'QuantityBase',
  /** Aggregate result on QtyInvoicedBase */
  QtyInvoicedBase = 'QtyInvoicedBase',
  /** Aggregate result on FAPostingDate */
  FaPostingDate = 'FAPostingDate',
  /** Aggregate result on DepreciationBookCode */
  DepreciationBookCode = 'DepreciationBookCode',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on CrossReferenceNo */
  CrossReferenceNo = 'CrossReferenceNo',
  /** Aggregate result on UnitofMeasureCrossRef */
  UnitofMeasureCrossRef = 'UnitofMeasureCrossRef',
  /** Aggregate result on CrossReferenceType */
  CrossReferenceType = 'CrossReferenceType',
  /** Aggregate result on CrossReferenceTypeOPTION */
  CrossReferenceTypeOption = 'CrossReferenceTypeOPTION',
  /** Aggregate result on CrossReferenceTypeNo */
  CrossReferenceTypeNo = 'CrossReferenceTypeNo',
  /** Aggregate result on ItemCategoryCode */
  ItemCategoryCode = 'ItemCategoryCode',
  /** Aggregate result on DuplicateinDepreciationBook */
  DuplicateinDepreciationBook = 'DuplicateinDepreciationBook',
  /** Aggregate result on PurchasingCode */
  PurchasingCode = 'PurchasingCode',
  /** Aggregate result on ProductGroupCode */
  ProductGroupCode = 'ProductGroupCode',
  /** Aggregate result on RequestedDeliveryDate */
  RequestedDeliveryDate = 'RequestedDeliveryDate',
  /** Aggregate result on PromisedDeliveryDate */
  PromisedDeliveryDate = 'PromisedDeliveryDate',
  /** Aggregate result on ShippingTime */
  ShippingTime = 'ShippingTime',
  /** Aggregate result on OutboundWhseHandlingTime */
  OutboundWhseHandlingTime = 'OutboundWhseHandlingTime',
  /** Aggregate result on ReturnReasonCode */
  ReturnReasonCode = 'ReturnReasonCode',
  /** Aggregate result on CustomerDiscGroup */
  CustomerDiscGroup = 'CustomerDiscGroup',
  /** Aggregate result on AutoAccGroup */
  AutoAccGroup = 'AutoAccGroup',
  /** Aggregate result on CreatedOnOrderBy */
  CreatedOnOrderBy = 'CreatedOnOrderBy',
  /** Aggregate result on ItemReorderingPolicy */
  ItemReorderingPolicy = 'ItemReorderingPolicy',
  /** Aggregate result on ItemReorderingPolicyOPTION */
  ItemReorderingPolicyOption = 'ItemReorderingPolicyOPTION',
  /** Aggregate result on PeabAccountNo */
  PeabAccountNo = 'PeabAccountNo',
  /** Aggregate result on FeeType */
  FeeType = 'FeeType',
  /** Aggregate result on FeeTypeOPTION */
  FeeTypeOption = 'FeeTypeOPTION',
  /** Aggregate result on ChargeType */
  ChargeType = 'ChargeType',
  /** Aggregate result on ChargeTypeOPTION */
  ChargeTypeOption = 'ChargeTypeOPTION',
  /** Aggregate result on DepruntilFAPostingDate */
  DepruntilFaPostingDate = 'DepruntilFAPostingDate',
  /** Aggregate result on UseDuplicationList */
  UseDuplicationList = 'UseDuplicationList',
  /** Aggregate result on Nonstock */
  Nonstock = 'Nonstock',
  /** Aggregate result on Correction */
  Correction = 'Correction',
  /** Aggregate result on AllowLineDisc */
  AllowLineDisc = 'AllowLineDisc',
  /** Aggregate result on PlannedDeliveryDate */
  PlannedDeliveryDate = 'PlannedDeliveryDate',
  /** Aggregate result on PlannedShipmentDate */
  PlannedShipmentDate = 'PlannedShipmentDate',
  /** Aggregate result on CreatedOnOrder */
  CreatedOnOrder = 'CreatedOnOrder',
  /** Aggregate result on Originaldeliverydate */
  Originaldeliverydate = 'Originaldeliverydate',
  /** Aggregate result on ApplfromItemEntry */
  ApplfromItemEntry = 'ApplfromItemEntry',
  /** Aggregate result on ItemChargeBaseAmount */
  ItemChargeBaseAmount = 'ItemChargeBaseAmount',
  /** Aggregate result on CalculatedPrice */
  CalculatedPrice = 'CalculatedPrice',
  /** Aggregate result on DisposableStockBalance */
  DisposableStockBalance = 'DisposableStockBalance',
  /** Aggregate result on Currentpricecalculation */
  Currentpricecalculation = 'Currentpricecalculation',
  /** Aggregate result on FeeAttachedtoLineNo */
  FeeAttachedtoLineNo = 'FeeAttachedtoLineNo',
  /** Aggregate result on CustomerOrderLineNo */
  CustomerOrderLineNo = 'CustomerOrderLineNo',
  /** Aggregate result on CustomerOrderedQuantity */
  CustomerOrderedQuantity = 'CustomerOrderedQuantity',
  /** Aggregate result on TotalQuantityShipped */
  TotalQuantityShipped = 'TotalQuantityShipped',
  /** Aggregate result on CurrentOutstandingQuantity */
  CurrentOutstandingQuantity = 'CurrentOutstandingQuantity',
  /** Aggregate result on TextconnectedtoLineNo */
  TextconnectedtoLineNo = 'TextconnectedtoLineNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSalespersonInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Commission */
  Commission = 'Commission',
  /** Aggregate result on Image */
  Image = 'Image',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on JobTitle */
  JobTitle = 'JobTitle',
  /** Aggregate result on SearchEMail */
  SearchEMail = 'SearchEMail',
  /** Aggregate result on EMail2 */
  EMail2 = 'EMail2',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on ActionTakenFilter */
  ActionTakenFilter = 'ActionTakenFilter',
  /** Aggregate result on ActionTakenFilterOPTION */
  ActionTakenFilterOption = 'ActionTakenFilterOPTION',
  /** Aggregate result on SalesCycleFilter */
  SalesCycleFilter = 'SalesCycleFilter',
  /** Aggregate result on SalesCycleStageFilter */
  SalesCycleStageFilter = 'SalesCycleStageFilter',
  /** Aggregate result on ProbabilityFilter */
  ProbabilityFilter = 'ProbabilityFilter',
  /** Aggregate result on CompletedFilter */
  CompletedFilter = 'CompletedFilter',
  /** Aggregate result on ContactFilter */
  ContactFilter = 'ContactFilter',
  /** Aggregate result on ContactCompanyFilter */
  ContactCompanyFilter = 'ContactCompanyFilter',
  /** Aggregate result on CampaignFilter */
  CampaignFilter = 'CampaignFilter',
  /** Aggregate result on EstimatedValueFilter */
  EstimatedValueFilter = 'EstimatedValueFilter',
  /** Aggregate result on CalcdCurrentValueFilter */
  CalcdCurrentValueFilter = 'CalcdCurrentValueFilter',
  /** Aggregate result on ChancesofSuccessFilter */
  ChancesofSuccessFilter = 'ChancesofSuccessFilter',
  /** Aggregate result on TaskStatusFilter */
  TaskStatusFilter = 'TaskStatusFilter',
  /** Aggregate result on TaskStatusFilterOPTION */
  TaskStatusFilterOption = 'TaskStatusFilterOPTION',
  /** Aggregate result on ClosedTaskFilter */
  ClosedTaskFilter = 'ClosedTaskFilter',
  /** Aggregate result on PriorityFilter */
  PriorityFilter = 'PriorityFilter',
  /** Aggregate result on PriorityFilterOPTION */
  PriorityFilterOption = 'PriorityFilterOPTION',
  /** Aggregate result on TeamFilter */
  TeamFilter = 'TeamFilter',
  /** Aggregate result on CloseOpportunityFilter */
  CloseOpportunityFilter = 'CloseOpportunityFilter',
  /** Aggregate result on NextTaskDate */
  NextTaskDate = 'NextTaskDate',
  /** Aggregate result on NoofOpportunities */
  NoofOpportunities = 'NoofOpportunities',
  /** Aggregate result on EstimatedValueLCY */
  EstimatedValueLcy = 'EstimatedValueLCY',
  /** Aggregate result on CalcdCurrentValueLCY */
  CalcdCurrentValueLcy = 'CalcdCurrentValueLCY',
  /** Aggregate result on NoofInteractions */
  NoofInteractions = 'NoofInteractions',
  /** Aggregate result on CostLCY */
  CostLcy = 'CostLCY',
  /** Aggregate result on DurationMin */
  DurationMin = 'DurationMin',
  /** Aggregate result on AvgEstimatedValueLCY */
  AvgEstimatedValueLcy = 'AvgEstimatedValueLCY',
  /** Aggregate result on AvgCalcdCurrentValueLCY */
  AvgCalcdCurrentValueLcy = 'AvgCalcdCurrentValueLCY',
  /** Aggregate result on OpportunityEntryExists */
  OpportunityEntryExists = 'OpportunityEntryExists',
  /** Aggregate result on TaskEntryExists */
  TaskEntryExists = 'TaskEntryExists',
  /** Aggregate result on EdgeRespCenter */
  EdgeRespCenter = 'EdgeRespCenter',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateServiceItemGroupInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on CreateServiceItem */
  CreateServiceItem = 'CreateServiceItem',
  /** Aggregate result on DefaultContractDiscountPercent */
  DefaultContractDiscountPercent = 'DefaultContractDiscountPercent',
  /** Aggregate result on DefaultServPriceGroupCode */
  DefaultServPriceGroupCode = 'DefaultServPriceGroupCode',
  /** Aggregate result on DefaultResponseTimeHours */
  DefaultResponseTimeHours = 'DefaultResponseTimeHours',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateShipmentMethodInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateShippingAgentInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on InternetAddress */
  InternetAddress = 'InternetAddress',
  /** Aggregate result on AccountNo */
  AccountNo = 'AccountNo',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on ShpfyTrackingCompany */
  ShpfyTrackingCompany = 'ShpfyTrackingCompany',
  /** Aggregate result on ShpfyTrackingCompanyOPTION */
  ShpfyTrackingCompanyOption = 'ShpfyTrackingCompanyOPTION',
  /** Aggregate result on DocumentType */
  DocumentType = 'DocumentType',
  /** Aggregate result on DocumentTypeOPTION */
  DocumentTypeOption = 'DocumentTypeOPTION',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSignRequestInput {
  /** Aggregate result on _initiatingUserId */
  InitiatingUserId = '_initiatingUserId',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on DocumentUri */
  DocumentUri = 'DocumentUri',
  /** Aggregate result on DocumentName */
  DocumentName = 'DocumentName',
  /** Aggregate result on DocumentEtag */
  DocumentEtag = 'DocumentEtag',
  /** Aggregate result on DocumentId */
  DocumentId = 'DocumentId',
  /** Aggregate result on SigningUserEmail */
  SigningUserEmail = 'SigningUserEmail',
  /** Aggregate result on SigningUserName */
  SigningUserName = 'SigningUserName',
  /** Aggregate result on PrintedName */
  PrintedName = 'PrintedName',
  /** Aggregate result on SignMethod */
  SignMethod = 'SignMethod',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on CreatedOn */
  CreatedOn = 'CreatedOn',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on TargetId */
  TargetId = 'TargetId',
  /** Aggregate result on TargetDiscriminator */
  TargetDiscriminator = 'TargetDiscriminator',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on Base64WriteOnly */
  Base64WriteOnly = 'Base64WriteOnly',
  /** Aggregate result on MobileBankIdSSN */
  MobileBankIdSsn = 'MobileBankIdSSN',
  /** Aggregate result on MobileBankIdUserVisibleData */
  MobileBankIdUserVisibleData = 'MobileBankIdUserVisibleData',
  /** Aggregate result on orderRef */
  OrderRef = 'orderRef',
  /** Aggregate result on autoStartToken */
  AutoStartToken = 'autoStartToken',
  /** Aggregate result on hintCode */
  HintCode = 'hintCode',
  /** Aggregate result on endUserIp */
  EndUserIp = 'endUserIp',
  /** Aggregate result on completionData */
  CompletionData = 'completionData',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSignatureInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateSpecialEquipmentInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateStockkeepingUnitInput {
  /** Aggregate result on ItemNo */
  ItemNo = 'ItemNo',
  /** Aggregate result on VariantCode */
  VariantCode = 'VariantCode',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Description2 */
  Description2 = 'Description2',
  /** Aggregate result on AssemblyBOM */
  AssemblyBom = 'AssemblyBOM',
  /** Aggregate result on ShelfNo */
  ShelfNo = 'ShelfNo',
  /** Aggregate result on UnitCost */
  UnitCost = 'UnitCost',
  /** Aggregate result on StandardCost */
  StandardCost = 'StandardCost',
  /** Aggregate result on LastDirectCost */
  LastDirectCost = 'LastDirectCost',
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on VendorItemNo */
  VendorItemNo = 'VendorItemNo',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on ReorderPoint */
  ReorderPoint = 'ReorderPoint',
  /** Aggregate result on MaximumInventory */
  MaximumInventory = 'MaximumInventory',
  /** Aggregate result on ReorderQuantity */
  ReorderQuantity = 'ReorderQuantity',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on Inventory */
  Inventory = 'Inventory',
  /** Aggregate result on QtyonPurchOrder */
  QtyonPurchOrder = 'QtyonPurchOrder',
  /** Aggregate result on QtyonSalesOrder */
  QtyonSalesOrder = 'QtyonSalesOrder',
  /** Aggregate result on DropShipmentFilter */
  DropShipmentFilter = 'DropShipmentFilter',
  /** Aggregate result on AssemblyPolicy */
  AssemblyPolicy = 'AssemblyPolicy',
  /** Aggregate result on AssemblyPolicyOPTION */
  AssemblyPolicyOption = 'AssemblyPolicyOPTION',
  /** Aggregate result on QtyonAssemblyOrder */
  QtyonAssemblyOrder = 'QtyonAssemblyOrder',
  /** Aggregate result on QtyonAsmComponent */
  QtyonAsmComponent = 'QtyonAsmComponent',
  /** Aggregate result on QtyonJobOrder */
  QtyonJobOrder = 'QtyonJobOrder',
  /** Aggregate result on TransferLevelCode */
  TransferLevelCode = 'TransferLevelCode',
  /** Aggregate result on LotSize */
  LotSize = 'LotSize',
  /** Aggregate result on DiscreteOrderQuantity */
  DiscreteOrderQuantity = 'DiscreteOrderQuantity',
  /** Aggregate result on MinimumOrderQuantity */
  MinimumOrderQuantity = 'MinimumOrderQuantity',
  /** Aggregate result on MaximumOrderQuantity */
  MaximumOrderQuantity = 'MaximumOrderQuantity',
  /** Aggregate result on SafetyStockQuantity */
  SafetyStockQuantity = 'SafetyStockQuantity',
  /** Aggregate result on OrderMultiple */
  OrderMultiple = 'OrderMultiple',
  /** Aggregate result on SafetyLeadTime */
  SafetyLeadTime = 'SafetyLeadTime',
  /** Aggregate result on ComponentsatLocation */
  ComponentsatLocation = 'ComponentsatLocation',
  /** Aggregate result on FlushingMethod */
  FlushingMethod = 'FlushingMethod',
  /** Aggregate result on FlushingMethodOPTION */
  FlushingMethodOption = 'FlushingMethodOPTION',
  /** Aggregate result on ReplenishmentSystem */
  ReplenishmentSystem = 'ReplenishmentSystem',
  /** Aggregate result on ReplenishmentSystemOPTION */
  ReplenishmentSystemOption = 'ReplenishmentSystemOPTION',
  /** Aggregate result on ScheduledReceiptQty */
  ScheduledReceiptQty = 'ScheduledReceiptQty',
  /** Aggregate result on ScheduledNeedQty */
  ScheduledNeedQty = 'ScheduledNeedQty',
  /** Aggregate result on BinFilter */
  BinFilter = 'BinFilter',
  /** Aggregate result on TimeBucket */
  TimeBucket = 'TimeBucket',
  /** Aggregate result on ReorderingPolicy */
  ReorderingPolicy = 'ReorderingPolicy',
  /** Aggregate result on ReorderingPolicyOPTION */
  ReorderingPolicyOption = 'ReorderingPolicyOPTION',
  /** Aggregate result on IncludeInventory */
  IncludeInventory = 'IncludeInventory',
  /** Aggregate result on ManufacturingPolicy */
  ManufacturingPolicy = 'ManufacturingPolicy',
  /** Aggregate result on ManufacturingPolicyOPTION */
  ManufacturingPolicyOption = 'ManufacturingPolicyOPTION',
  /** Aggregate result on ReschedulingPeriod */
  ReschedulingPeriod = 'ReschedulingPeriod',
  /** Aggregate result on LotAccumulationPeriod */
  LotAccumulationPeriod = 'LotAccumulationPeriod',
  /** Aggregate result on DampenerPeriod */
  DampenerPeriod = 'DampenerPeriod',
  /** Aggregate result on DampenerQuantity */
  DampenerQuantity = 'DampenerQuantity',
  /** Aggregate result on OverflowLevel */
  OverflowLevel = 'OverflowLevel',
  /** Aggregate result on TransferfromCode */
  TransferfromCode = 'TransferfromCode',
  /** Aggregate result on QtyinTransit */
  QtyinTransit = 'QtyinTransit',
  /** Aggregate result on TransOrdReceiptQty */
  TransOrdReceiptQty = 'TransOrdReceiptQty',
  /** Aggregate result on TransOrdShipmentQty */
  TransOrdShipmentQty = 'TransOrdShipmentQty',
  /** Aggregate result on QtyonServiceOrder */
  QtyonServiceOrder = 'QtyonServiceOrder',
  /** Aggregate result on SpecialEquipmentCode */
  SpecialEquipmentCode = 'SpecialEquipmentCode',
  /** Aggregate result on PutawayTemplateCode */
  PutawayTemplateCode = 'PutawayTemplateCode',
  /** Aggregate result on PutawayUnitofMeasureCode */
  PutawayUnitofMeasureCode = 'PutawayUnitofMeasureCode',
  /** Aggregate result on PhysInvtCountingPeriodCode */
  PhysInvtCountingPeriodCode = 'PhysInvtCountingPeriodCode',
  /** Aggregate result on LastCountingPeriodUpdate */
  LastCountingPeriodUpdate = 'LastCountingPeriodUpdate',
  /** Aggregate result on LastPhysInvtDate */
  LastPhysInvtDate = 'LastPhysInvtDate',
  /** Aggregate result on UseCrossDocking */
  UseCrossDocking = 'UseCrossDocking',
  /** Aggregate result on NextCountingStartDate */
  NextCountingStartDate = 'NextCountingStartDate',
  /** Aggregate result on NextCountingEndDate */
  NextCountingEndDate = 'NextCountingEndDate',
  /** Aggregate result on RoutingNo */
  RoutingNo = 'RoutingNo',
  /** Aggregate result on ProductionBOMNo */
  ProductionBomNo = 'ProductionBOMNo',
  /** Aggregate result on PlannedOrderReceiptQty */
  PlannedOrderReceiptQty = 'PlannedOrderReceiptQty',
  /** Aggregate result on FPOrderReceiptQty */
  FpOrderReceiptQty = 'FPOrderReceiptQty',
  /** Aggregate result on RelOrderReceiptQty */
  RelOrderReceiptQty = 'RelOrderReceiptQty',
  /** Aggregate result on PlannedOrderReleaseQty */
  PlannedOrderReleaseQty = 'PlannedOrderReleaseQty',
  /** Aggregate result on PurchReqReceiptQty */
  PurchReqReceiptQty = 'PurchReqReceiptQty',
  /** Aggregate result on PurchReqReleaseQty */
  PurchReqReleaseQty = 'PurchReqReleaseQty',
  /** Aggregate result on QtyonProdOrder */
  QtyonProdOrder = 'QtyonProdOrder',
  /** Aggregate result on QtyonComponentLines */
  QtyonComponentLines = 'QtyonComponentLines',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTariffNumberInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on SupplementaryUnits */
  SupplementaryUnits = 'SupplementaryUnits',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTaxGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTimeRegistrationInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on TimeSheetNo */
  TimeSheetNo = 'TimeSheetNo',
  /** Aggregate result on TimeSheetLineLineNo */
  TimeSheetLineLineNo = 'TimeSheetLineLineNo',
  /** Aggregate result on TimeSheetDetailLineNo */
  TimeSheetDetailLineNo = 'TimeSheetDetailLineNo',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on CauseofAbsenceCode */
  CauseofAbsenceCode = 'CauseofAbsenceCode',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on Hours */
  Hours = 'Hours',
  /** Aggregate result on Email */
  Email = 'Email',
  /** Aggregate result on Resource */
  Resource = 'Resource',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on StartDateFilter */
  StartDateFilter = 'StartDateFilter',
  /** Aggregate result on EndDateFilter */
  EndDateFilter = 'EndDateFilter',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTimeSheetDetailInput {
  /** Aggregate result on TimeSheetNo */
  TimeSheetNo = 'TimeSheetNo',
  /** Aggregate result on TimeSheetLineNo */
  TimeSheetLineNo = 'TimeSheetLineNo',
  /** Aggregate result on Date */
  Date = 'Date',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on ResourceNo */
  ResourceNo = 'ResourceNo',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on CauseofAbsenceCode */
  CauseofAbsenceCode = 'CauseofAbsenceCode',
  /** Aggregate result on ServiceOrderNo */
  ServiceOrderNo = 'ServiceOrderNo',
  /** Aggregate result on ServiceOrderLineNo */
  ServiceOrderLineNo = 'ServiceOrderLineNo',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on PostedQuantity */
  PostedQuantity = 'PostedQuantity',
  /** Aggregate result on AssemblyOrderNo */
  AssemblyOrderNo = 'AssemblyOrderNo',
  /** Aggregate result on AssemblyOrderLineNo */
  AssemblyOrderLineNo = 'AssemblyOrderLineNo',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on Posted */
  Posted = 'Posted',
  /** Aggregate result on ExcludeFromTotals */
  ExcludeFromTotals = 'ExcludeFromTotals',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on NotChargeable */
  NotChargeable = 'NotChargeable',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTimeSheetLineInput {
  /** Aggregate result on TimeSheetNo */
  TimeSheetNo = 'TimeSheetNo',
  /** Aggregate result on LineNo */
  LineNo = 'LineNo',
  /** Aggregate result on TimeSheetStartingDate */
  TimeSheetStartingDate = 'TimeSheetStartingDate',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on JobNo */
  JobNo = 'JobNo',
  /** Aggregate result on JobTaskNo */
  JobTaskNo = 'JobTaskNo',
  /** Aggregate result on CauseofAbsenceCode */
  CauseofAbsenceCode = 'CauseofAbsenceCode',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on WorkTypeCode */
  WorkTypeCode = 'WorkTypeCode',
  /** Aggregate result on ApproverID */
  ApproverId = 'ApproverID',
  /** Aggregate result on ServiceOrderNo */
  ServiceOrderNo = 'ServiceOrderNo',
  /** Aggregate result on ServiceOrderLineNo */
  ServiceOrderLineNo = 'ServiceOrderLineNo',
  /** Aggregate result on Chargeable */
  Chargeable = 'Chargeable',
  /** Aggregate result on AssemblyOrderNo */
  AssemblyOrderNo = 'AssemblyOrderNo',
  /** Aggregate result on AssemblyOrderLineNo */
  AssemblyOrderLineNo = 'AssemblyOrderLineNo',
  /** Aggregate result on Status */
  Status = 'Status',
  /** Aggregate result on StatusOPTION */
  StatusOption = 'StatusOPTION',
  /** Aggregate result on ApprovedBy */
  ApprovedBy = 'ApprovedBy',
  /** Aggregate result on ApprovalDate */
  ApprovalDate = 'ApprovalDate',
  /** Aggregate result on Posted */
  Posted = 'Posted',
  /** Aggregate result on UnitPrice */
  UnitPrice = 'UnitPrice',
  /** Aggregate result on NotChargeable */
  NotChargeable = 'NotChargeable',
  /** Aggregate result on TotalQuantity */
  TotalQuantity = 'TotalQuantity',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTransactionSpecificationInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Text */
  Text = 'Text',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTransactionTypeInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateTransportMethodInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateUnitOfMeasureInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on InternationalStandardCode */
  InternationalStandardCode = 'InternationalStandardCode',
  /** Aggregate result on Symbol */
  Symbol = 'Symbol',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on CoupledtoCRM */
  CoupledtoCrm = 'CoupledtoCRM',
  /** Aggregate result on ExternalCode */
  ExternalCode = 'ExternalCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateUserFavoritesInput {
  /** Aggregate result on userEmail */
  UserEmail = 'userEmail',
  /** Aggregate result on userGlobalId */
  UserGlobalId = 'userGlobalId',
  /** Aggregate result on favorites */
  Favorites = 'favorites',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateUserInput {
  /** Aggregate result on name */
  Name = 'name',
  /** Aggregate result on email */
  Email = 'email',
  /** Aggregate result on favoriteCompanies */
  FavoriteCompanies = 'favoriteCompanies',
  /** Aggregate result on favoriteProjects */
  FavoriteProjects = 'favoriteProjects',
  /** Aggregate result on active */
  Active = 'active',
  /** Aggregate result on pushTokens */
  PushTokens = 'pushTokens',
  /** Aggregate result on AppPushTokens */
  AppPushTokens = 'AppPushTokens',
  /** Aggregate result on Dyn365Id */
  Dyn365Id = 'Dyn365Id',
  /** Aggregate result on DynNAVId */
  DynNavId = 'DynNAVId',
  /** Aggregate result on role */
  Role = 'role',
  /** Aggregate result on DomainIds */
  DomainIds = 'DomainIds',
  /** Aggregate result on ProjectNos */
  ProjectNos = 'ProjectNos',
  /** Aggregate result on FilterProjectIds */
  FilterProjectIds = 'FilterProjectIds',
  /** Aggregate result on VerifiedIdentity */
  VerifiedIdentity = 'VerifiedIdentity',
  /** Aggregate result on IsDemo */
  IsDemo = 'IsDemo',
  /** Aggregate result on EmailOnExpiredObjects */
  EmailOnExpiredObjects = 'EmailOnExpiredObjects',
  /** Aggregate result on PushNotificationOnShipments */
  PushNotificationOnShipments = 'PushNotificationOnShipments',
  /** Aggregate result on NotificationsForProjects */
  NotificationsForProjects = 'NotificationsForProjects',
  /** Aggregate result on IdentityVerifiedByUser */
  IdentityVerifiedByUser = 'IdentityVerifiedByUser',
  /** Aggregate result on PendingAccessRequest */
  PendingAccessRequest = 'PendingAccessRequest',
  /** Aggregate result on CustomerName */
  CustomerName = 'CustomerName',
  /** Aggregate result on DefaultSignature */
  DefaultSignature = 'DefaultSignature',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on DefaultResponsibilityCenterMatrix */
  DefaultResponsibilityCenterMatrix = 'DefaultResponsibilityCenterMatrix',
  /** Aggregate result on NavUserName */
  NavUserName = 'NavUserName',
  /** Aggregate result on NavApiUserNameId */
  NavApiUserNameId = 'NavApiUserNameId',
  /** Aggregate result on NavApiPasswordId */
  NavApiPasswordId = 'NavApiPasswordId',
  /** Aggregate result on UserInvitationId */
  UserInvitationId = 'UserInvitationId',
  /** Aggregate result on UserAcceptedInvitationOn */
  UserAcceptedInvitationOn = 'UserAcceptedInvitationOn',
  /** Aggregate result on ImpersonateUserId */
  ImpersonateUserId = 'ImpersonateUserId',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateVatBusinessPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateVatProductPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateVendorBankAccountInput {
  /** Aggregate result on VendorNo */
  VendorNo = 'VendorNo',
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on TelexNo */
  TelexNo = 'TelexNo',
  /** Aggregate result on BankBranchNo */
  BankBranchNo = 'BankBranchNo',
  /** Aggregate result on BankAccountNo */
  BankAccountNo = 'BankAccountNo',
  /** Aggregate result on TransitNo */
  TransitNo = 'TransitNo',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on TelexAnswerBack */
  TelexAnswerBack = 'TelexAnswerBack',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on IBAN */
  Iban = 'IBAN',
  /** Aggregate result on SWIFTCode */
  SwiftCode = 'SWIFTCode',
  /** Aggregate result on BankClearingCode */
  BankClearingCode = 'BankClearingCode',
  /** Aggregate result on BankClearingStandard */
  BankClearingStandard = 'BankClearingStandard',
  /** Aggregate result on PEBPlusGiroNo */
  PebPlusGiroNo = 'PEBPlusGiroNo',
  /** Aggregate result on PEBPaymentMethod */
  PebPaymentMethod = 'PEBPaymentMethod',
  /** Aggregate result on PEBPaymentMethodOPTION */
  PebPaymentMethodOption = 'PEBPaymentMethodOPTION',
  /** Aggregate result on PEBGiroTypeCode */
  PebGiroTypeCode = 'PEBGiroTypeCode',
  /** Aggregate result on PEBBankGiroNo */
  PebBankGiroNo = 'PEBBankGiroNo',
  /** Aggregate result on PEBCodeforBankofSweden */
  PebCodeforBankofSweden = 'PEBCodeforBankofSweden',
  /** Aggregate result on PEBAccountPaymentwithAdvis */
  PebAccountPaymentwithAdvis = 'PEBAccountPaymentwithAdvis',
  /** Aggregate result on PEBChargePaidby */
  PebChargePaidby = 'PEBChargePaidby',
  /** Aggregate result on PEBChargePaidbyOPTION */
  PebChargePaidbyOption = 'PEBChargePaidbyOPTION',
  /** Aggregate result on PEBCategoryPurpose */
  PebCategoryPurpose = 'PEBCategoryPurpose',
  /** Aggregate result on PEBCategoryPurposeOPTION */
  PebCategoryPurposeOption = 'PEBCategoryPurposeOPTION',
  /** Aggregate result on PEBStatus */
  PebStatus = 'PEBStatus',
  /** Aggregate result on PEBStatusOPTION */
  PebStatusOption = 'PEBStatusOPTION',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateVendorInput {
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Name */
  Name = 'Name',
  /** Aggregate result on SearchName */
  SearchName = 'SearchName',
  /** Aggregate result on Name2 */
  Name2 = 'Name2',
  /** Aggregate result on Address */
  Address = 'Address',
  /** Aggregate result on Address2 */
  Address2 = 'Address2',
  /** Aggregate result on City */
  City = 'City',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on PhoneNo */
  PhoneNo = 'PhoneNo',
  /** Aggregate result on TelexNo */
  TelexNo = 'TelexNo',
  /** Aggregate result on OurAccountNo */
  OurAccountNo = 'OurAccountNo',
  /** Aggregate result on TerritoryCode */
  TerritoryCode = 'TerritoryCode',
  /** Aggregate result on GlobalDimension1Code */
  GlobalDimension1Code = 'GlobalDimension1Code',
  /** Aggregate result on GlobalDimension2Code */
  GlobalDimension2Code = 'GlobalDimension2Code',
  /** Aggregate result on BudgetedAmount */
  BudgetedAmount = 'BudgetedAmount',
  /** Aggregate result on VendorPostingGroup */
  VendorPostingGroup = 'VendorPostingGroup',
  /** Aggregate result on CurrencyCode */
  CurrencyCode = 'CurrencyCode',
  /** Aggregate result on LanguageCode */
  LanguageCode = 'LanguageCode',
  /** Aggregate result on StatisticsGroup */
  StatisticsGroup = 'StatisticsGroup',
  /** Aggregate result on PaymentTermsCode */
  PaymentTermsCode = 'PaymentTermsCode',
  /** Aggregate result on FinChargeTermsCode */
  FinChargeTermsCode = 'FinChargeTermsCode',
  /** Aggregate result on PurchaserCode */
  PurchaserCode = 'PurchaserCode',
  /** Aggregate result on ShipmentMethodCode */
  ShipmentMethodCode = 'ShipmentMethodCode',
  /** Aggregate result on ShippingAgentCode */
  ShippingAgentCode = 'ShippingAgentCode',
  /** Aggregate result on InvoiceDiscCode */
  InvoiceDiscCode = 'InvoiceDiscCode',
  /** Aggregate result on CountryRegionCode */
  CountryRegionCode = 'CountryRegionCode',
  /** Aggregate result on Blocked */
  Blocked = 'Blocked',
  /** Aggregate result on BlockedOPTION */
  BlockedOption = 'BlockedOPTION',
  /** Aggregate result on PaytoVendorNo */
  PaytoVendorNo = 'PaytoVendorNo',
  /** Aggregate result on Priority */
  Priority = 'Priority',
  /** Aggregate result on PaymentMethodCode */
  PaymentMethodCode = 'PaymentMethodCode',
  /** Aggregate result on LastModifiedDateTime */
  LastModifiedDateTime = 'LastModifiedDateTime',
  /** Aggregate result on LastDateModified */
  LastDateModified = 'LastDateModified',
  /** Aggregate result on ApplicationMethod */
  ApplicationMethod = 'ApplicationMethod',
  /** Aggregate result on ApplicationMethodOPTION */
  ApplicationMethodOption = 'ApplicationMethodOPTION',
  /** Aggregate result on PricesIncludingVAT */
  PricesIncludingVat = 'PricesIncludingVAT',
  /** Aggregate result on FaxNo */
  FaxNo = 'FaxNo',
  /** Aggregate result on TelexAnswerBack */
  TelexAnswerBack = 'TelexAnswerBack',
  /** Aggregate result on VATRegistrationNo */
  VatRegistrationNo = 'VATRegistrationNo',
  /** Aggregate result on GenBusPostingGroup */
  GenBusPostingGroup = 'GenBusPostingGroup',
  /** Aggregate result on Picture */
  Picture = 'Picture',
  /** Aggregate result on GLN */
  Gln = 'GLN',
  /** Aggregate result on PostCode */
  PostCode = 'PostCode',
  /** Aggregate result on County */
  County = 'County',
  /** Aggregate result on EMail */
  EMail = 'EMail',
  /** Aggregate result on HomePage */
  HomePage = 'HomePage',
  /** Aggregate result on NoSeries */
  NoSeries = 'NoSeries',
  /** Aggregate result on TaxAreaCode */
  TaxAreaCode = 'TaxAreaCode',
  /** Aggregate result on TaxLiable */
  TaxLiable = 'TaxLiable',
  /** Aggregate result on VATBusPostingGroup */
  VatBusPostingGroup = 'VATBusPostingGroup',
  /** Aggregate result on BlockPaymentTolerance */
  BlockPaymentTolerance = 'BlockPaymentTolerance',
  /** Aggregate result on ICPartnerCode */
  IcPartnerCode = 'ICPartnerCode',
  /** Aggregate result on Prepayment */
  Prepayment = 'Prepayment',
  /** Aggregate result on PartnerType */
  PartnerType = 'PartnerType',
  /** Aggregate result on PartnerTypeOPTION */
  PartnerTypeOption = 'PartnerTypeOPTION',
  /** Aggregate result on Image */
  Image = 'Image',
  /** Aggregate result on CreditorNo */
  CreditorNo = 'CreditorNo',
  /** Aggregate result on PreferredBankAccountCode */
  PreferredBankAccountCode = 'PreferredBankAccountCode',
  /** Aggregate result on CashFlowPaymentTermsCode */
  CashFlowPaymentTermsCode = 'CashFlowPaymentTermsCode',
  /** Aggregate result on PrimaryContactNo */
  PrimaryContactNo = 'PrimaryContactNo',
  /** Aggregate result on ResponsibilityCenter */
  ResponsibilityCenter = 'ResponsibilityCenter',
  /** Aggregate result on LocationCode */
  LocationCode = 'LocationCode',
  /** Aggregate result on LeadTimeCalculation */
  LeadTimeCalculation = 'LeadTimeCalculation',
  /** Aggregate result on BaseCalendarCode */
  BaseCalendarCode = 'BaseCalendarCode',
  /** Aggregate result on DocumentSendingProfile */
  DocumentSendingProfile = 'DocumentSendingProfile',
  /** Aggregate result on ValidateEUVatRegNo */
  ValidateEuVatRegNo = 'ValidateEUVatRegNo',
  /** Aggregate result on Id */
  Id = 'Id',
  /** Aggregate result on CurrencyId */
  CurrencyId = 'CurrencyId',
  /** Aggregate result on PaymentTermsId */
  PaymentTermsId = 'PaymentTermsId',
  /** Aggregate result on PaymentMethodId */
  PaymentMethodId = 'PaymentMethodId',
  /** Aggregate result on NocontrolofExtDocNo */
  NocontrolofExtDocNo = 'NocontrolofExtDocNo',
  /** Aggregate result on ExternalDocumentNo */
  ExternalDocumentNo = 'ExternalDocumentNo',
  /** Aggregate result on RegistrationNo */
  RegistrationNo = 'RegistrationNo',
  /** Aggregate result on DocumentCode */
  DocumentCode = 'DocumentCode',
  /** Aggregate result on DateFilter */
  DateFilter = 'DateFilter',
  /** Aggregate result on GlobalDimension1Filter */
  GlobalDimension1Filter = 'GlobalDimension1Filter',
  /** Aggregate result on GlobalDimension2Filter */
  GlobalDimension2Filter = 'GlobalDimension2Filter',
  /** Aggregate result on CurrencyFilter */
  CurrencyFilter = 'CurrencyFilter',
  /** Aggregate result on Comment */
  Comment = 'Comment',
  /** Aggregate result on Balance */
  Balance = 'Balance',
  /** Aggregate result on BalanceLCY */
  BalanceLcy = 'BalanceLCY',
  /** Aggregate result on NetChange */
  NetChange = 'NetChange',
  /** Aggregate result on NetChangeLCY */
  NetChangeLcy = 'NetChangeLCY',
  /** Aggregate result on PurchasesLCY */
  PurchasesLcy = 'PurchasesLCY',
  /** Aggregate result on InvDiscountsLCY */
  InvDiscountsLcy = 'InvDiscountsLCY',
  /** Aggregate result on PmtDiscountsLCY */
  PmtDiscountsLcy = 'PmtDiscountsLCY',
  /** Aggregate result on BalanceDue */
  BalanceDue = 'BalanceDue',
  /** Aggregate result on BalanceDueLCY */
  BalanceDueLcy = 'BalanceDueLCY',
  /** Aggregate result on Payments */
  Payments = 'Payments',
  /** Aggregate result on InvoiceAmounts */
  InvoiceAmounts = 'InvoiceAmounts',
  /** Aggregate result on CrMemoAmounts */
  CrMemoAmounts = 'CrMemoAmounts',
  /** Aggregate result on FinanceChargeMemoAmounts */
  FinanceChargeMemoAmounts = 'FinanceChargeMemoAmounts',
  /** Aggregate result on PaymentsLCY */
  PaymentsLcy = 'PaymentsLCY',
  /** Aggregate result on InvAmountsLCY */
  InvAmountsLcy = 'InvAmountsLCY',
  /** Aggregate result on CrMemoAmountsLCY */
  CrMemoAmountsLcy = 'CrMemoAmountsLCY',
  /** Aggregate result on FinChargeMemoAmountsLCY */
  FinChargeMemoAmountsLcy = 'FinChargeMemoAmountsLCY',
  /** Aggregate result on OutstandingOrders */
  OutstandingOrders = 'OutstandingOrders',
  /** Aggregate result on AmtRcdNotInvoiced */
  AmtRcdNotInvoiced = 'AmtRcdNotInvoiced',
  /** Aggregate result on DebitAmount */
  DebitAmount = 'DebitAmount',
  /** Aggregate result on CreditAmount */
  CreditAmount = 'CreditAmount',
  /** Aggregate result on DebitAmountLCY */
  DebitAmountLcy = 'DebitAmountLCY',
  /** Aggregate result on CreditAmountLCY */
  CreditAmountLcy = 'CreditAmountLCY',
  /** Aggregate result on ReminderAmounts */
  ReminderAmounts = 'ReminderAmounts',
  /** Aggregate result on ReminderAmountsLCY */
  ReminderAmountsLcy = 'ReminderAmountsLCY',
  /** Aggregate result on OutstandingOrdersLCY */
  OutstandingOrdersLcy = 'OutstandingOrdersLCY',
  /** Aggregate result on AmtRcdNotInvoicedLCY */
  AmtRcdNotInvoicedLcy = 'AmtRcdNotInvoicedLCY',
  /** Aggregate result on PmtDiscToleranceLCY */
  PmtDiscToleranceLcy = 'PmtDiscToleranceLCY',
  /** Aggregate result on PmtToleranceLCY */
  PmtToleranceLcy = 'PmtToleranceLCY',
  /** Aggregate result on Refunds */
  Refunds = 'Refunds',
  /** Aggregate result on RefundsLCY */
  RefundsLcy = 'RefundsLCY',
  /** Aggregate result on OtherAmounts */
  OtherAmounts = 'OtherAmounts',
  /** Aggregate result on OtherAmountsLCY */
  OtherAmountsLcy = 'OtherAmountsLCY',
  /** Aggregate result on OutstandingInvoices */
  OutstandingInvoices = 'OutstandingInvoices',
  /** Aggregate result on OutstandingInvoicesLCY */
  OutstandingInvoicesLcy = 'OutstandingInvoicesLCY',
  /** Aggregate result on PaytoNoOfArchivedDoc */
  PaytoNoOfArchivedDoc = 'PaytoNoOfArchivedDoc',
  /** Aggregate result on BuyfromNoOfArchivedDoc */
  BuyfromNoOfArchivedDoc = 'BuyfromNoOfArchivedDoc',
  /** Aggregate result on NoofPstdReceipts */
  NoofPstdReceipts = 'NoofPstdReceipts',
  /** Aggregate result on NoofPstdInvoices */
  NoofPstdInvoices = 'NoofPstdInvoices',
  /** Aggregate result on NoofPstdReturnShipments */
  NoofPstdReturnShipments = 'NoofPstdReturnShipments',
  /** Aggregate result on NoofPstdCreditMemos */
  NoofPstdCreditMemos = 'NoofPstdCreditMemos',
  /** Aggregate result on PaytoNoofOrders */
  PaytoNoofOrders = 'PaytoNoofOrders',
  /** Aggregate result on PaytoNoofInvoices */
  PaytoNoofInvoices = 'PaytoNoofInvoices',
  /** Aggregate result on PaytoNoofReturnOrders */
  PaytoNoofReturnOrders = 'PaytoNoofReturnOrders',
  /** Aggregate result on PaytoNoofCreditMemos */
  PaytoNoofCreditMemos = 'PaytoNoofCreditMemos',
  /** Aggregate result on PaytoNoofPstdReceipts */
  PaytoNoofPstdReceipts = 'PaytoNoofPstdReceipts',
  /** Aggregate result on PaytoNoofPstdInvoices */
  PaytoNoofPstdInvoices = 'PaytoNoofPstdInvoices',
  /** Aggregate result on PaytoNoofPstdReturnS */
  PaytoNoofPstdReturnS = 'PaytoNoofPstdReturnS',
  /** Aggregate result on PaytoNoofPstdCrMemos */
  PaytoNoofPstdCrMemos = 'PaytoNoofPstdCrMemos',
  /** Aggregate result on NoofQuotes */
  NoofQuotes = 'NoofQuotes',
  /** Aggregate result on NoofBlanketOrders */
  NoofBlanketOrders = 'NoofBlanketOrders',
  /** Aggregate result on NoofOrders */
  NoofOrders = 'NoofOrders',
  /** Aggregate result on NoofInvoices */
  NoofInvoices = 'NoofInvoices',
  /** Aggregate result on NoofReturnOrders */
  NoofReturnOrders = 'NoofReturnOrders',
  /** Aggregate result on NoofCreditMemos */
  NoofCreditMemos = 'NoofCreditMemos',
  /** Aggregate result on NoofOrderAddresses */
  NoofOrderAddresses = 'NoofOrderAddresses',
  /** Aggregate result on PaytoNoofQuotes */
  PaytoNoofQuotes = 'PaytoNoofQuotes',
  /** Aggregate result on PaytoNoofBlanketOrders */
  PaytoNoofBlanketOrders = 'PaytoNoofBlanketOrders',
  /** Aggregate result on NoofIncomingDocuments */
  NoofIncomingDocuments = 'NoofIncomingDocuments',
  /** Aggregate result on InwardRegBalanceLCY */
  InwardRegBalanceLcy = 'InwardRegBalanceLCY',
  /** Aggregate result on InwardRegNetChangeLCY */
  InwardRegNetChangeLcy = 'InwardRegNetChangeLCY',
  /** Aggregate result on StateCode */
  StateCode = 'StateCode',
  /** Aggregate result on StatusCode */
  StatusCode = 'StatusCode',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateVendorPostingGroupInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on PayablesAccount */
  PayablesAccount = 'PayablesAccount',
  /** Aggregate result on ServiceChargeAcc */
  ServiceChargeAcc = 'ServiceChargeAcc',
  /** Aggregate result on PaymentDiscDebitAcc */
  PaymentDiscDebitAcc = 'PaymentDiscDebitAcc',
  /** Aggregate result on InvoiceRoundingAccount */
  InvoiceRoundingAccount = 'InvoiceRoundingAccount',
  /** Aggregate result on DebitCurrApplnRndgAcc */
  DebitCurrApplnRndgAcc = 'DebitCurrApplnRndgAcc',
  /** Aggregate result on CreditCurrApplnRndgAcc */
  CreditCurrApplnRndgAcc = 'CreditCurrApplnRndgAcc',
  /** Aggregate result on DebitRoundingAccount */
  DebitRoundingAccount = 'DebitRoundingAccount',
  /** Aggregate result on CreditRoundingAccount */
  CreditRoundingAccount = 'CreditRoundingAccount',
  /** Aggregate result on PaymentDiscCreditAcc */
  PaymentDiscCreditAcc = 'PaymentDiscCreditAcc',
  /** Aggregate result on PaymentToleranceDebitAcc */
  PaymentToleranceDebitAcc = 'PaymentToleranceDebitAcc',
  /** Aggregate result on PaymentToleranceCreditAcc */
  PaymentToleranceCreditAcc = 'PaymentToleranceCreditAcc',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on ViewAllAccountsonLookup */
  ViewAllAccountsonLookup = 'ViewAllAccountsonLookup',
  /** Aggregate result on InwardRegBridgingDebt */
  InwardRegBridgingDebt = 'InwardRegBridgingDebt',
  /** Aggregate result on PEBInwardRegBridgingDebt */
  PebInwardRegBridgingDebt = 'PEBInwardRegBridgingDebt',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateWarehouseClassInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateWebBookingInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on BookingNo */
  BookingNo = 'BookingNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on Type */
  Type = 'Type',
  /** Aggregate result on TypeOPTION */
  TypeOption = 'TypeOPTION',
  /** Aggregate result on No */
  No = 'No',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Quantity */
  Quantity = 'Quantity',
  /** Aggregate result on DeliveryDate */
  DeliveryDate = 'DeliveryDate',
  /** Aggregate result on DeliveryAddress */
  DeliveryAddress = 'DeliveryAddress',
  /** Aggregate result on Contact */
  Contact = 'Contact',
  /** Aggregate result on ContactPhone */
  ContactPhone = 'ContactPhone',
  /** Aggregate result on ContactEMail */
  ContactEMail = 'ContactEMail',
  /** Aggregate result on Handled */
  Handled = 'Handled',
  /** Aggregate result on ContractNo */
  ContractNo = 'ContractNo',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateWebhookSubscriptionInput {
  /** Aggregate result on Resource */
  Resource = 'Resource',
  /** Aggregate result on SubscriptionId */
  SubscriptionId = 'SubscriptionId',
  /** Aggregate result on EventType */
  EventType = 'EventType',
  /** Aggregate result on Enabled */
  Enabled = 'Enabled',
  /** Aggregate result on CreatedBy */
  CreatedBy = 'CreatedBy',
  /** Aggregate result on LastUsed */
  LastUsed = 'LastUsed',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateWorkLogInput {
  /** Aggregate result on EntryNo */
  EntryNo = 'EntryNo',
  /** Aggregate result on CustomerNo */
  CustomerNo = 'CustomerNo',
  /** Aggregate result on CustomerProject */
  CustomerProject = 'CustomerProject',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on Hours */
  Hours = 'Hours',
  /** Aggregate result on SimplonaEmail */
  SimplonaEmail = 'SimplonaEmail',
  /** Aggregate result on NAVUser */
  NavUser = 'NAVUser',
  /** Aggregate result on WorkDateTime */
  WorkDateTime = 'WorkDateTime',
  /** Aggregate result on Debited */
  Debited = 'Debited',
  /** Aggregate result on DoNotDebit */
  DoNotDebit = 'DoNotDebit',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export enum AggregateWorkTypeInput {
  /** Aggregate result on Code */
  Code = 'Code',
  /** Aggregate result on Description */
  Description = 'Description',
  /** Aggregate result on UnitofMeasureCode */
  UnitofMeasureCode = 'UnitofMeasureCode',
  /** Aggregate result on GenProdPostingGroup */
  GenProdPostingGroup = 'GenProdPostingGroup',
  /** Aggregate result on VATProdPostingGroup */
  VatProdPostingGroup = 'VATProdPostingGroup',
  /** Aggregate result on GroupCountReadOnly */
  GroupCountReadOnly = 'GroupCountReadOnly',
  /** Aggregate result on TotalCountReadOnly */
  TotalCountReadOnly = 'TotalCountReadOnly',
  /** Aggregate result on KeyValue */
  KeyValue = 'KeyValue',
  /** Aggregate result on Company */
  Company = 'Company',
  /** Aggregate result on CompanyRegistrationNo */
  CompanyRegistrationNo = 'CompanyRegistrationNo',
  /** Aggregate result on CompanyRegistrationNoClean */
  CompanyRegistrationNoClean = 'CompanyRegistrationNoClean',
  /** Aggregate result on DomainId */
  DomainId = 'DomainId',
  /** Aggregate result on CustomerId */
  CustomerId = 'CustomerId',
  /** Aggregate result on ProjectId */
  ProjectId = 'ProjectId',
  /** Aggregate result on SignStatus */
  SignStatus = 'SignStatus',
  /** Aggregate result on SearchString */
  SearchString = 'SearchString',
  /** Aggregate result on SyncedToNav */
  SyncedToNav = 'SyncedToNav',
  /** Aggregate result on SyncCount */
  SyncCount = 'SyncCount',
  /** Aggregate result on SyncProblems */
  SyncProblems = 'SyncProblems',
  /** Aggregate result on SyncProblemOrgiginalEvent */
  SyncProblemOrgiginalEvent = 'SyncProblemOrgiginalEvent',
  /** Aggregate result on NavRecordId */
  NavRecordId = 'NavRecordId',
  /** Aggregate result on ConnectedToRecordId */
  ConnectedToRecordId = 'ConnectedToRecordId',
  /** Aggregate result on ModifiedOn */
  ModifiedOn = 'ModifiedOn',
  /** Aggregate result on ModifiedOnSales */
  ModifiedOnSales = 'ModifiedOnSales',
  /** Aggregate result on ModifiedOnMagento */
  ModifiedOnMagento = 'ModifiedOnMagento',
  /** Aggregate result on SentToMagento */
  SentToMagento = 'SentToMagento',
  /** Aggregate result on SentToWebhook */
  SentToWebhook = 'SentToWebhook',
  /** Aggregate result on SentToCoredination */
  SentToCoredination = 'SentToCoredination',
  /** Aggregate result on SentToEquipmentLoop */
  SentToEquipmentLoop = 'SentToEquipmentLoop',
  /** Aggregate result on ForceSave */
  ForceSave = 'ForceSave',
  /** Aggregate result on DoNotRetrySaveToNAV */
  DoNotRetrySaveToNav = 'DoNotRetrySaveToNAV',
  /** Aggregate result on GlobalId */
  GlobalId = 'GlobalId',
  /** Aggregate result on InitiatingUserEmail */
  InitiatingUserEmail = 'InitiatingUserEmail',
  /** Aggregate result on InitiatingUserName */
  InitiatingUserName = 'InitiatingUserName',
  /** Aggregate result on LastModifiedBy */
  LastModifiedBy = 'LastModifiedBy',
  /** Aggregate result on SystemCreatedAt */
  SystemCreatedAt = 'SystemCreatedAt',
  /** Aggregate result on SystemModifiedAt */
  SystemModifiedAt = 'SystemModifiedAt'
}

export type Agreement = {
  __typename?: 'Agreement';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  RecName?: Maybe<Scalars['String']>;
  RecId?: Maybe<Scalars['String']>;
  AgreementType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  SignatureType?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type AgreementConnection = {
  __typename?: 'AgreementConnection';
  count: Scalars['Int'];
  edges: Array<AgreementConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AgreementConnectionEdge = {
  __typename?: 'AgreementConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Agreement;
};

export type AgreementDeleteResponse = {
  __typename?: 'AgreementDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Agreement>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AgreementManyResult = {
  __typename?: 'AgreementManyResult';
  records?: Maybe<Array<Maybe<Agreement>>>;
  pageData?: Maybe<PageData>;
};

export type Annotation = {
  __typename?: 'Annotation';
  discriminator?: Maybe<Scalars['String']>;
  ObjectTypeCode?: Maybe<Scalars['String']>;
  NoteText?: Maybe<Scalars['String']>;
  OwnerId?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  OwnerName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnotationConnection = {
  __typename?: 'AnnotationConnection';
  count: Scalars['Int'];
  edges: Array<AnnotationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AnnotationConnectionEdge = {
  __typename?: 'AnnotationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Annotation;
};

export type AnnotationDeleteResponse = {
  __typename?: 'AnnotationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Annotation>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AnnotationManyResult = {
  __typename?: 'AnnotationManyResult';
  records?: Maybe<Array<Maybe<Annotation>>>;
  pageData?: Maybe<PageData>;
};

export enum AppPushTokenInput {
  NavetEdge = 'NavetEdge',
  Kranpunkten = 'Kranpunkten',
  MobilePackagesRunner = 'MobilePackagesRunner',
  InternalDemo = 'InternalDemo',
  ExternalDemo = 'ExternalDemo'
}

export type AppPushtoken = {
  __typename?: 'AppPushtoken';
  App?: Maybe<AppPushTokenInput>;
  Token?: Maybe<Scalars['String']>;
};

export type ApplicationModule = {
  __typename?: 'ApplicationModule';
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ShortName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ShortDescription?: Maybe<Scalars['String']>;
  DefaultWebLink?: Maybe<Scalars['String']>;
  DefaultAppNavStackName?: Maybe<Scalars['String']>;
  SearchOrder?: Maybe<Scalars['Float']>;
  NavigationOrder?: Maybe<Scalars['Float']>;
  Searchable?: Maybe<Scalars['Boolean']>;
  ShowOnWebHeader?: Maybe<Scalars['Boolean']>;
  ShowOnAppMainTab?: Maybe<Scalars['Boolean']>;
  VisibleForCustomer?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ApplicationModuleConnection = {
  __typename?: 'ApplicationModuleConnection';
  count: Scalars['Int'];
  edges: Array<ApplicationModuleConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ApplicationModuleConnectionEdge = {
  __typename?: 'ApplicationModuleConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ApplicationModule;
};

export type ApplicationModuleDeleteResponse = {
  __typename?: 'ApplicationModuleDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ApplicationModule>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApplicationModuleManyResult = {
  __typename?: 'ApplicationModuleManyResult';
  records?: Maybe<Array<Maybe<ApplicationModule>>>;
  pageData?: Maybe<PageData>;
};

export type AuthError = {
  __typename?: 'AuthError';
  error_description?: Maybe<Scalars['String']>;
  trace_id?: Maybe<Scalars['String']>;
  error_uri?: Maybe<Scalars['String']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<AuthError>;
  jwt?: Maybe<Scalars['String']>;
  ExpiresOn?: Maybe<Scalars['DateTime']>;
};

export type AutoComplete = {
  __typename?: 'AutoComplete';
  text?: Maybe<Scalars['String']>;
  queryPlusText?: Maybe<Scalars['String']>;
};

export type AutocompleteInput = {
  SearchString: Scalars['String'];
  discriminator?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
};

export type BcMappingField = {
  __typename?: 'BcMappingField';
  discriminator?: Maybe<Scalars['String']>;
  NBAEDirection?: Maybe<Scalars['Float']>;
  NBAEDirectionOPTION?: Maybe<Scalars['String']>;
  NBAETableNo?: Maybe<Scalars['Float']>;
  NBAEEventType?: Maybe<Scalars['Float']>;
  NBAEEventTypeOPTION?: Maybe<Scalars['String']>;
  NBAESequenceNo?: Maybe<Scalars['Float']>;
  NBAELineNo?: Maybe<Scalars['Float']>;
  NBAEPKField?: Maybe<Scalars['Boolean']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  NBAESortingOrder?: Maybe<Scalars['Float']>;
  NBAEOutFromDataType?: Maybe<Scalars['Float']>;
  NBAEOutFromDataTypeOPTION?: Maybe<Scalars['String']>;
  NBAEOutFromDataSubType?: Maybe<Scalars['String']>;
  NBAEOutToJSONElement?: Maybe<Scalars['String']>;
  NBAEInFromDataType?: Maybe<Scalars['Float']>;
  NBAEInFromDataTypeOPTION?: Maybe<Scalars['String']>;
  NBAEInFromDataSubType?: Maybe<Scalars['String']>;
  NBAEInOperator?: Maybe<Scalars['Float']>;
  NBAEInOperatorOPTION?: Maybe<Scalars['String']>;
  NBAEInToFieldNo?: Maybe<Scalars['Float']>;
  NBAEInToFieldName?: Maybe<Scalars['String']>;
  NBAEInValidate?: Maybe<Scalars['Boolean']>;
  NBAEInTranslatesFromField?: Maybe<Scalars['Float']>;
  NBAELineFieldNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type BcMappingFieldConnection = {
  __typename?: 'BcMappingFieldConnection';
  count: Scalars['Int'];
  edges: Array<BcMappingFieldConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type BcMappingFieldConnectionEdge = {
  __typename?: 'BcMappingFieldConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: BcMappingField;
};

export type BcMappingFieldDeleteResponse = {
  __typename?: 'BcMappingFieldDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<BcMappingField>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BcMappingFieldManyResult = {
  __typename?: 'BcMappingFieldManyResult';
  records?: Maybe<Array<Maybe<BcMappingField>>>;
  pageData?: Maybe<PageData>;
};

export type BcMappingTable = {
  __typename?: 'BcMappingTable';
  discriminator?: Maybe<Scalars['String']>;
  NBAEDirection?: Maybe<Scalars['Float']>;
  NBAEDirectionOPTION?: Maybe<Scalars['String']>;
  NBAETableNo?: Maybe<Scalars['Float']>;
  NBAEEventType?: Maybe<Scalars['Float']>;
  NBAEEventTypeOPTION?: Maybe<Scalars['String']>;
  NBAESequenceNo?: Maybe<Scalars['Float']>;
  NBAETableName?: Maybe<Scalars['String']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  NBAEEntityName?: Maybe<Scalars['String']>;
  NBAEDescription?: Maybe<Scalars['String']>;
  NBAEProcessingOrder?: Maybe<Scalars['Float']>;
  NBAEProcessOnReceipt?: Maybe<Scalars['Boolean']>;
  NBAEProcessAsTransactions?: Maybe<Scalars['Boolean']>;
  NBAEFields?: Maybe<Scalars['Float']>;
  NBAEFilters?: Maybe<Scalars['Float']>;
  NBAERelations?: Maybe<Scalars['Float']>;
  NBAEDelay?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type BcMappingTableConnection = {
  __typename?: 'BcMappingTableConnection';
  count: Scalars['Int'];
  edges: Array<BcMappingTableConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type BcMappingTableConnectionEdge = {
  __typename?: 'BcMappingTableConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: BcMappingTable;
};

export type BcMappingTableDeleteResponse = {
  __typename?: 'BcMappingTableDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<BcMappingTable>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BcMappingTableManyResult = {
  __typename?: 'BcMappingTableManyResult';
  records?: Maybe<Array<Maybe<BcMappingTable>>>;
  pageData?: Maybe<PageData>;
};

export type BcSettings = {
  __typename?: 'BcSettings';
  discriminator?: Maybe<Scalars['String']>;
  NBAECode?: Maybe<Scalars['String']>;
  NBAELineNo?: Maybe<Scalars['Float']>;
  NBAESettingName?: Maybe<Scalars['String']>;
  NBAESettingType?: Maybe<Scalars['Float']>;
  NBAESettingTypeOPTION?: Maybe<Scalars['String']>;
  NBAESettingValue?: Maybe<Scalars['String']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type BcSettingsConnection = {
  __typename?: 'BcSettingsConnection';
  count: Scalars['Int'];
  edges: Array<BcSettingsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type BcSettingsConnectionEdge = {
  __typename?: 'BcSettingsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: BcSettings;
};

export type BcSettingsDeleteResponse = {
  __typename?: 'BcSettingsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<BcSettings>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BcSettingsManyResult = {
  __typename?: 'BcSettingsManyResult';
  records?: Maybe<Array<Maybe<BcSettings>>>;
  pageData?: Maybe<PageData>;
};

export type BulkItemGroup = {
  __typename?: 'BulkItemGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type BulkItemGroupConnection = {
  __typename?: 'BulkItemGroupConnection';
  count: Scalars['Int'];
  edges: Array<BulkItemGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type BulkItemGroupConnectionEdge = {
  __typename?: 'BulkItemGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: BulkItemGroup;
};

export type BulkItemGroupDeleteResponse = {
  __typename?: 'BulkItemGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<BulkItemGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BulkItemGroupManyResult = {
  __typename?: 'BulkItemGroupManyResult';
  records?: Maybe<Array<Maybe<BulkItemGroup>>>;
  pageData?: Maybe<PageData>;
};

export enum CalculateRentallineInputValues {
  OnRent = 'onRent',
  Reservations = 'reservations',
  PreInvoice = 'preInvoice',
  ExpiredOnRent = 'expiredOnRent',
  OnRentChildren = 'onRentChildren',
  ReservationChildren = 'reservationChildren'
}

export type CauseOfAbsence = {
  __typename?: 'CauseOfAbsence';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  EmployeeNoFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  TotalAbsenceBase?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CauseOfAbsenceConnection = {
  __typename?: 'CauseOfAbsenceConnection';
  count: Scalars['Int'];
  edges: Array<CauseOfAbsenceConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CauseOfAbsenceConnectionEdge = {
  __typename?: 'CauseOfAbsenceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CauseOfAbsence;
};

export type CauseOfAbsenceDeleteResponse = {
  __typename?: 'CauseOfAbsenceDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CauseOfAbsence>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CauseOfAbsenceManyResult = {
  __typename?: 'CauseOfAbsenceManyResult';
  records?: Maybe<Array<Maybe<CauseOfAbsence>>>;
  pageData?: Maybe<PageData>;
};

export type ChangeObjectTypeImagePayload = {
  GlobalId: Scalars['String'];
  DomainImageBase64: Scalars['String'];
  DomainImageBase64Name: Scalars['String'];
};

export type ClearFunctionTestInput = {
  DomainId: Scalars['String'];
  olderThan?: Maybe<Scalars['DateTime']>;
  batchSize?: Maybe<Scalars['Int']>;
};

export type ClearFunctionalTestReusult = {
  __typename?: 'ClearFunctionalTestReusult';
  DateUsed?: Maybe<Scalars['DateTime']>;
  headersCleared?: Maybe<Scalars['Int']>;
  rowsCleared?: Maybe<Scalars['Int']>;
};

export type Company = {
  __typename?: 'Company';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  PrimaryKey?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  DocumentSendingProfile?: Maybe<Scalars['String']>;
  OurAccountNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  ChainName?: Maybe<Scalars['String']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  CreditLimitLCY?: Maybe<Scalars['Float']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  GiroNo?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  PaymentRoutingNo?: Maybe<Scalars['String']>;
  CustomsPermitNo?: Maybe<Scalars['String']>;
  CustomsPermitDate?: Maybe<Scalars['DateTime']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  IndustrialClassification?: Maybe<Scalars['String']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICInboxType?: Maybe<Scalars['String']>;
  ICInboxDetails?: Maybe<Scalars['String']>;
  SystemIndicator?: Maybe<Scalars['String']>;
  CustomSystemIndicatorText?: Maybe<Scalars['String']>;
  SystemIndicatorStyle?: Maybe<Scalars['String']>;
  AllowBlankPaymentInfo?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CheckAvailPeriodCalc?: Maybe<Scalars['String']>;
  CheckAvailTimeBucket?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  CalConvergenceTimeFrame?: Maybe<Scalars['String']>;
  PlusGiroNo?: Maybe<Scalars['String']>;
  RegisteredOffice?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  UseExtraNavetPermissions?: Maybe<Scalars['Boolean']>;
  BlockObjectTypeModify?: Maybe<Scalars['Boolean']>;
  UseStandardPriceCalculation?: Maybe<Scalars['Boolean']>;
  UseStavdalResourcePrice?: Maybe<Scalars['Boolean']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  GLN?: Maybe<Scalars['String']>;
  ProductionDatabase?: Maybe<Scalars['String']>;
  MarkedAsTest?: Maybe<Scalars['Boolean']>;
  DontAskAgain?: Maybe<Scalars['Boolean']>;
  TestEmail?: Maybe<Scalars['String']>;
  BaseisInitiated?: Maybe<Scalars['Boolean']>;
  PlusGiroRefAccountNo?: Maybe<Scalars['String']>;
  CompanyImageEtag?: Maybe<Scalars['String']>;
  CompanyImageDocGlobalId?: Maybe<Scalars['String']>;
  CompanyImageURL?: Maybe<Scalars['String']>;
  Address_Visit?: Maybe<Scalars['String']>;
  Address2_Visit?: Maybe<Scalars['String']>;
  City_Visit?: Maybe<Scalars['String']>;
  PostCode_Visit?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ScriveField?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  ActiveInEdge?: Maybe<Scalars['Boolean']>;
  IsProspect?: Maybe<Scalars['Boolean']>;
  Analytics?: Maybe<Scalars['Boolean']>;
  MagentoGroupId?: Maybe<Scalars['Float']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  Fax?: Maybe<Scalars['String']>;
  IsWebCRM?: Maybe<Scalars['Boolean']>;
  MagentoId?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LatestInvoiceDate?: Maybe<Scalars['DateTime']>;
  RevenueThisYear?: Maybe<Scalars['Float']>;
  RevenueLastYear?: Maybe<Scalars['Float']>;
  RevenueYearBefLastYear?: Maybe<Scalars['Float']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  FinChargeTermsCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PlaceofExport?: Maybe<Scalars['String']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  CollectionMethod?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  InvoiceCopies?: Maybe<Scalars['Float']>;
  LastStatementNo?: Maybe<Scalars['Float']>;
  PrintStatements?: Maybe<Scalars['Boolean']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  Priority?: Maybe<Scalars['Float']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  NetChangeLCY?: Maybe<Scalars['Float']>;
  SalesLCY?: Maybe<Scalars['Float']>;
  ProfitLCY?: Maybe<Scalars['Float']>;
  InvDiscountsLCY?: Maybe<Scalars['Float']>;
  PmtDiscountsLCY?: Maybe<Scalars['Float']>;
  BalanceDue?: Maybe<Scalars['Float']>;
  BalanceDueLCY?: Maybe<Scalars['Float']>;
  Payments?: Maybe<Scalars['Float']>;
  InvoiceAmounts?: Maybe<Scalars['Float']>;
  CrMemoAmounts?: Maybe<Scalars['Float']>;
  FinanceChargeMemoAmounts?: Maybe<Scalars['Float']>;
  PaymentsLCY?: Maybe<Scalars['Float']>;
  InvAmountsLCY?: Maybe<Scalars['Float']>;
  CrMemoAmountsLCY?: Maybe<Scalars['Float']>;
  FinChargeMemoAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ApplicationMethod?: Maybe<Scalars['Float']>;
  ApplicationMethodOPTION?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  DontDebitPlasticTax?: Maybe<Scalars['Boolean']>;
  DontDebitEnvironmentFees?: Maybe<Scalars['Boolean']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  EnvironmentShipmentDays?: Maybe<Scalars['String']>;
  CustomerCategory?: Maybe<Scalars['String']>;
  CustomerClassification?: Maybe<Scalars['String']>;
  MagentoCustomer?: Maybe<Scalars['Boolean']>;
  Allwebitemsisvisible?: Maybe<Scalars['Boolean']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  SharkPrioritizeCode?: Maybe<Scalars['Float']>;
  SharkPrioritizeCodeOPTION?: Maybe<Scalars['String']>;
  SharkInvoiceValue?: Maybe<Scalars['Float']>;
  SharkFreightPrice?: Maybe<Scalars['Float']>;
  SharkTelephoneInstruction?: Maybe<Scalars['Boolean']>;
  NoBackOrder?: Maybe<Scalars['Boolean']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  OnlyActualFreightPrice?: Maybe<Scalars['Boolean']>;
  InvoiceDocumentType?: Maybe<Scalars['Float']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  CustomerPriceGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  PaymentMethodWeb?: Maybe<CompanyPaymentMethodWebEnum>;
  QBSId?: Maybe<Scalars['String']>;
  CRMClassification?: Maybe<Scalars['Float']>;
  PEBShiptoCode?: Maybe<Scalars['String']>;
  PEBDirectDebitPackType?: Maybe<Scalars['Float']>;
  PEBDirectDebitPackTypeOPTION?: Maybe<Scalars['String']>;
  PEBDirectDebitPaymentNo?: Maybe<Scalars['String']>;
  PEBDirectDebitApprovalStat?: Maybe<Scalars['Float']>;
  PEBDirectDebitApprovalStatOPTION?: Maybe<Scalars['String']>;
  PEBDDApprovalStatus?: Maybe<Scalars['Float']>;
  PEBDDApprovalStatusOPTION?: Maybe<Scalars['String']>;
  PEBRegistrationNo?: Maybe<Scalars['String']>;
  PEBDocumentCode?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMCustomerTemplate?: Maybe<Scalars['String']>;
  EQMCustRentalDiscGroup?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMEditableRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMRentalInsurance?: Maybe<Scalars['String']>;
  EQMCustRentalPriceGroup?: Maybe<Scalars['String']>;
  EQMHowInvoiceRentalContr?: Maybe<Scalars['Float']>;
  EQMHowInvoiceRentalContrOPTION?: Maybe<Scalars['String']>;
  EQMCustomerDeposit?: Maybe<Scalars['String']>;
  EQMCheckListCode?: Maybe<Scalars['String']>;
  EQMCustomerCategory?: Maybe<Scalars['Float']>;
  EQMCustomerCategoryOPTION?: Maybe<Scalars['String']>;
  EQMPopupNote?: Maybe<Scalars['String']>;
  EQMCombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  EQMCombineMethod?: Maybe<Scalars['Float']>;
  EQMCombineMethodOPTION?: Maybe<Scalars['String']>;
  EQMRentalInvDayofMonth?: Maybe<Scalars['Float']>;
  EQMCustomerClassification?: Maybe<Scalars['String']>;
  EQMManuallyBlocked?: Maybe<Scalars['Boolean']>;
  EQMOrganizationNo?: Maybe<Scalars['String']>;
  EQMRentalInvoiceSequence?: Maybe<Scalars['String']>;
  EQMEnvironmentalFeeCode?: Maybe<Scalars['String']>;
  EQMNotEditable?: Maybe<Scalars['Boolean']>;
  EQMPaidDeposits?: Maybe<Scalars['Float']>;
  EQMPaidDepositsLCY?: Maybe<Scalars['Float']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMInvoiceFee?: Maybe<Scalars['Float']>;
  EQMInvoiceFeeOPTION?: Maybe<Scalars['String']>;
  EQMPrepaymentLCY?: Maybe<Scalars['Float']>;
  EQMPrepaymentPeriod?: Maybe<Scalars['String']>;
  EQMMaxRentalValue?: Maybe<Scalars['Float']>;
  EQMMaxGrantedDeposit?: Maybe<Scalars['Float']>;
  EQMCatValidtoDate?: Maybe<Scalars['DateTime']>;
  EQMNoofRentalQuotes?: Maybe<Scalars['Float']>;
  EQMNoofOpenRntlContr?: Maybe<Scalars['Float']>;
  EQMNoofOpenRentalLines?: Maybe<Scalars['Float']>;
  EQMNoofRentalOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentalCreditMemos?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlShpt?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlInv?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlCrM?: Maybe<Scalars['Float']>;
  EQMNoofWorkshopQuotes?: Maybe<Scalars['Float']>;
  EQMNoofWorkshopOrders?: Maybe<Scalars['Float']>;
  EQMNoofWrkshpCrMemos?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpShpt?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpInv?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofRntlQte?: Maybe<Scalars['Float']>;
  EQMBillToNoofORContr?: Maybe<Scalars['Float']>;
  EQMBillToNoofRntlOrds?: Maybe<Scalars['Float']>;
  EQMBillToNoofRCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdRS?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdRI?: Maybe<Scalars['Float']>;
  EQMBillToNoofPRCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofWQte?: Maybe<Scalars['Float']>;
  EQMBillToNoofWrkshpO?: Maybe<Scalars['Float']>;
  EQMBillToNoofWCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdWS?: Maybe<Scalars['Float']>;
  EQMBillToNoofPWInv?: Maybe<Scalars['Float']>;
  EQMBillToNoofPWCrM?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRentalInsuranceOptions?: Maybe<Scalars['Float']>;
  EQMRentalInsuranceOptionsOPTION?: Maybe<Scalars['String']>;
  EQMPONoMandatory?: Maybe<Scalars['Boolean']>;
  EQMNoofRentPickOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentDeliverOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentCollOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdPickOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdDelOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdCollOrders?: Maybe<Scalars['Float']>;
  EQMStairPriceFilter?: Maybe<Scalars['String']>;
  EQMStairDiscountFilter?: Maybe<Scalars['String']>;
  HowtoInvoiceRentalContract?: Maybe<Scalars['Float']>;
  HowtoInvoiceRentalContractOPTION?: Maybe<Scalars['String']>;
  EQMShortTermRental?: Maybe<Scalars['Boolean']>;
  EQMCombineInvoiceSubProj?: Maybe<Scalars['Boolean']>;
  EQMTermofContract?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  addresses?: Maybe<Array<Maybe<Address>>>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  count: Scalars['Int'];
  edges: Array<CompanyConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CompanyConnectionEdge = {
  __typename?: 'CompanyConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Company;
};

export type CompanyDeleteResponse = {
  __typename?: 'CompanyDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Company>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CompanyInvitationInput = {
  Role?: Maybe<InvitationInputRoleEnum>;
  RegistrationNo: Scalars['String'];
  SupplierId: Scalars['String'];
  CompanyName?: Maybe<Scalars['String']>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<InvitationTypeEnum>;
};

export type CompanyManyResult = {
  __typename?: 'CompanyManyResult';
  records?: Maybe<Array<Maybe<Company>>>;
  pageData?: Maybe<PageData>;
};

export enum CompanyPaymentMethodWebEnum {
  Empty = 'Empty',
  Cash = 'Cash',
  Invoice = 'Invoice',
  CashOrInvoice = 'CashOrInvoice'
}

export type ConsignmentHeader = {
  __typename?: 'ConsignmentHeader';
  discriminator?: Maybe<Scalars['String']>;
  TableID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  ConsignmentNo?: Maybe<Scalars['String']>;
  ConsignmentType?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentTable?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  PickupName?: Maybe<Scalars['String']>;
  PickupAddress?: Maybe<Scalars['String']>;
  PickupAddress2?: Maybe<Scalars['String']>;
  PickupPostCode?: Maybe<Scalars['String']>;
  PickupCity?: Maybe<Scalars['String']>;
  PickupCounty?: Maybe<Scalars['String']>;
  PickupCountryRegionCode?: Maybe<Scalars['String']>;
  PickupContactNo?: Maybe<Scalars['String']>;
  PickupContactName?: Maybe<Scalars['String']>;
  PickupContactPhoneNo?: Maybe<Scalars['String']>;
  PickupContactMobPhoneNo?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoContactNo?: Maybe<Scalars['String']>;
  ShiptoContactName?: Maybe<Scalars['String']>;
  ShiptoContactPhoneNo?: Maybe<Scalars['String']>;
  ShiptoContactMobPhoneNo?: Maybe<Scalars['String']>;
  EQMRentalContractNo?: Maybe<Scalars['String']>;
  EQMProjectNo?: Maybe<Scalars['String']>;
  EQMPickOrderNo?: Maybe<Scalars['String']>;
  EQMShipOrderNo?: Maybe<Scalars['String']>;
  ReturnOrder?: Maybe<Scalars['Boolean']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  TransportRequestSent?: Maybe<Scalars['String']>;
  TransportRequestSentBy?: Maybe<Scalars['String']>;
  TransportComment?: Maybe<Scalars['String']>;
  ReadyToFetchDate?: Maybe<Scalars['DateTime']>;
  ReadyToFetchTime?: Maybe<Scalars['String']>;
  LatestAtRecieverDate?: Maybe<Scalars['DateTime']>;
  LatestAtRecieverTime?: Maybe<Scalars['String']>;
  AtReceiverOnTime?: Maybe<Scalars['Boolean']>;
  TotalEstimatedVolume?: Maybe<Scalars['Float']>;
  TransportConfirmed?: Maybe<Scalars['Boolean']>;
  TransportConfirmedBy?: Maybe<Scalars['String']>;
  TypeOfVehicle?: Maybe<Scalars['String']>;
  Freightisdebited?: Maybe<Scalars['Boolean']>;
  EQMCollectionOrderNo?: Maybe<Scalars['String']>;
  TotalGrossWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  TotalVolume?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsignmentHeaderConnection = {
  __typename?: 'ConsignmentHeaderConnection';
  count: Scalars['Int'];
  edges: Array<ConsignmentHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ConsignmentHeaderConnectionEdge = {
  __typename?: 'ConsignmentHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ConsignmentHeader;
};

export type ConsignmentHeaderDeleteResponse = {
  __typename?: 'ConsignmentHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ConsignmentHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConsignmentHeaderManyResult = {
  __typename?: 'ConsignmentHeaderManyResult';
  records?: Maybe<Array<Maybe<ConsignmentHeader>>>;
  pageData?: Maybe<PageData>;
};

export type ConsignmentLine = {
  __typename?: 'ConsignmentLine';
  discriminator?: Maybe<Scalars['String']>;
  ConsignmentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  OriginalTableID?: Maybe<Scalars['Float']>;
  OriginalUniqueID?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  TotalGrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  Volume?: Maybe<Scalars['Float']>;
  TotalVolume?: Maybe<Scalars['Float']>;
  TextBlob?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsignmentLineConnection = {
  __typename?: 'ConsignmentLineConnection';
  count: Scalars['Int'];
  edges: Array<ConsignmentLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ConsignmentLineConnectionEdge = {
  __typename?: 'ConsignmentLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ConsignmentLine;
};

export type ConsignmentLineDeleteResponse = {
  __typename?: 'ConsignmentLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ConsignmentLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConsignmentLineManyResult = {
  __typename?: 'ConsignmentLineManyResult';
  records?: Maybe<Array<Maybe<ConsignmentLine>>>;
  pageData?: Maybe<PageData>;
};

export type Contact = {
  __typename?: 'Contact';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CompanyNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  LookupContactNo?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  Surname?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Initials?: Maybe<Scalars['String']>;
  ExtensionNo?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  Pager?: Maybe<Scalars['String']>;
  OrganizationalLevelCode?: Maybe<Scalars['String']>;
  ExcludefromSegment?: Maybe<Scalars['Boolean']>;
  ExternalID?: Maybe<Scalars['String']>;
  CorrespondenceType?: Maybe<Scalars['String']>;
  SalutationCode?: Maybe<Scalars['String']>;
  SearchEMail?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  EMail2?: Maybe<Scalars['String']>;
  XrmId?: Maybe<Scalars['String']>;
  EQMEvaluationStatus?: Maybe<Scalars['String']>;
  EQMCheckListCode?: Maybe<Scalars['String']>;
  EQMCustomerCategory2?: Maybe<Scalars['String']>;
  EQMIdentificationNo?: Maybe<Scalars['String']>;
  EQMContactType?: Maybe<Scalars['String']>;
  EQMNotEditable?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  SalespersonFilter?: Maybe<Scalars['String']>;
  CampaignFilter?: Maybe<Scalars['String']>;
  ActionTakenFilter?: Maybe<Scalars['String']>;
  SalesCycleFilter?: Maybe<Scalars['String']>;
  SalesCycleStageFilter?: Maybe<Scalars['Float']>;
  ProbabilityFilter?: Maybe<Scalars['Float']>;
  CompletedFilter?: Maybe<Scalars['Float']>;
  EstimatedValueFilter?: Maybe<Scalars['Float']>;
  CalcdCurrentValueFilter?: Maybe<Scalars['Float']>;
  ChancesofSuccessFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilter?: Maybe<Scalars['String']>;
  TaskClosedFilter?: Maybe<Scalars['Boolean']>;
  PriorityFilter?: Maybe<Scalars['String']>;
  TeamFilter?: Maybe<Scalars['String']>;
  CloseOpportunityFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NextTaskDate?: Maybe<Scalars['DateTime']>;
  LastDateAttempted?: Maybe<Scalars['DateTime']>;
  DateofLastInteraction?: Maybe<Scalars['DateTime']>;
  NoofJobResponsibilities?: Maybe<Scalars['Float']>;
  NoofIndustryGroups?: Maybe<Scalars['Float']>;
  NoofBusinessRelations?: Maybe<Scalars['Float']>;
  NoofMailingGroups?: Maybe<Scalars['Float']>;
  NoofInteractions?: Maybe<Scalars['Float']>;
  CostLCY?: Maybe<Scalars['Float']>;
  DurationMin?: Maybe<Scalars['Float']>;
  NoofOpportunities?: Maybe<Scalars['Float']>;
  EstimatedValueLCY?: Maybe<Scalars['Float']>;
  CalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  OpportunityEntryExists?: Maybe<Scalars['Boolean']>;
  TaskEntryExists?: Maybe<Scalars['Boolean']>;
  LinkedToCustomerNo?: Maybe<Scalars['String']>;
  Lead?: Maybe<Scalars['Boolean']>;
  LinkedTo?: Maybe<Scalars['String']>;
  LastCustomerLedgerDate?: Maybe<Scalars['DateTime']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  count: Scalars['Int'];
  edges: Array<ContactConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ContactConnectionEdge = {
  __typename?: 'ContactConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Contact;
};

export type ContactDeleteResponse = {
  __typename?: 'ContactDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Contact>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContactManyResult = {
  __typename?: 'ContactManyResult';
  records?: Maybe<Array<Maybe<Contact>>>;
  pageData?: Maybe<PageData>;
};

export type CountryRegion = {
  __typename?: 'CountryRegion';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ISOCode?: Maybe<Scalars['String']>;
  ISONumericCode?: Maybe<Scalars['String']>;
  EUCountryRegionCode?: Maybe<Scalars['String']>;
  IntrastatCode?: Maybe<Scalars['String']>;
  AddressFormat?: Maybe<Scalars['Float']>;
  AddressFormatOPTION?: Maybe<Scalars['String']>;
  ContactAddressFormat?: Maybe<Scalars['Float']>;
  ContactAddressFormatOPTION?: Maybe<Scalars['String']>;
  VATScheme?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CountyName?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryRegionConnection = {
  __typename?: 'CountryRegionConnection';
  count: Scalars['Int'];
  edges: Array<CountryRegionConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CountryRegionConnectionEdge = {
  __typename?: 'CountryRegionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CountryRegion;
};

export type CountryRegionDeleteResponse = {
  __typename?: 'CountryRegionDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CountryRegion>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CountryRegionManyResult = {
  __typename?: 'CountryRegionManyResult';
  records?: Maybe<Array<Maybe<CountryRegion>>>;
  pageData?: Maybe<PageData>;
};

export type CreateAccessRequestInput = {
  Type: AccessRequestTypeEnum;
  TargetId: Scalars['String'];
  TargetDiscriminator: Scalars['String'];
  TargetName: Scalars['String'];
  CustomerId: Scalars['String'];
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateSerciceAccountInput = {
  name?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
};

export type CreateWorkshopOrderInput = {
  FunctionalTestHeaderGlobalId: Scalars['String'];
  DomainId: Scalars['String'];
  Signature: Scalars['String'];
  UserEmail?: Maybe<Scalars['String']>;
};

export type CreateWorkshopOrderResponse = {
  __typename?: 'CreateWorkshopOrderResponse';
  Success?: Maybe<Scalars['Boolean']>;
  Error?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastDateAdjusted?: Maybe<Scalars['DateTime']>;
  ISOCode?: Maybe<Scalars['String']>;
  ISONumericCode?: Maybe<Scalars['String']>;
  UnrealizedGainsAcc?: Maybe<Scalars['String']>;
  RealizedGainsAcc?: Maybe<Scalars['String']>;
  UnrealizedLossesAcc?: Maybe<Scalars['String']>;
  RealizedLossesAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingPrecision?: Maybe<Scalars['Float']>;
  InvoiceRoundingType?: Maybe<Scalars['Float']>;
  InvoiceRoundingTypeOPTION?: Maybe<Scalars['String']>;
  AmountRoundingPrecision?: Maybe<Scalars['Float']>;
  UnitAmountRoundingPrecision?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  AmountDecimalPlaces?: Maybe<Scalars['String']>;
  UnitAmountDecimalPlaces?: Maybe<Scalars['String']>;
  CustomerFilter?: Maybe<Scalars['String']>;
  VendorFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  CustLedgEntriesinFilter?: Maybe<Scalars['Boolean']>;
  CustomerBalance?: Maybe<Scalars['Float']>;
  CustomerOutstandingOrders?: Maybe<Scalars['Float']>;
  CustomerShippedNotInvoiced?: Maybe<Scalars['Float']>;
  CustomerBalanceDue?: Maybe<Scalars['Float']>;
  VendorLedgEntriesinFilter?: Maybe<Scalars['Boolean']>;
  VendorBalance?: Maybe<Scalars['Float']>;
  VendorOutstandingOrders?: Maybe<Scalars['Float']>;
  VendorAmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  VendorBalanceDue?: Maybe<Scalars['Float']>;
  CustomerBalanceLCY?: Maybe<Scalars['Float']>;
  VendorBalanceLCY?: Maybe<Scalars['Float']>;
  RealizedGLGainsAccount?: Maybe<Scalars['String']>;
  RealizedGLLossesAccount?: Maybe<Scalars['String']>;
  ApplnRoundingPrecision?: Maybe<Scalars['Float']>;
  EMUCurrency?: Maybe<Scalars['Boolean']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  ResidualGainsAccount?: Maybe<Scalars['String']>;
  ResidualLossesAccount?: Maybe<Scalars['String']>;
  ConvLCYRndgDebitAcc?: Maybe<Scalars['String']>;
  ConvLCYRndgCreditAcc?: Maybe<Scalars['String']>;
  MaxVATDifferenceAllowed?: Maybe<Scalars['Float']>;
  VATRoundingType?: Maybe<Scalars['Float']>;
  VATRoundingTypeOPTION?: Maybe<Scalars['String']>;
  PaymentTolerancePercent?: Maybe<Scalars['Float']>;
  MaxPaymentToleranceAmount?: Maybe<Scalars['Float']>;
  Symbol?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  PEBNoofForwardAgreement?: Maybe<Scalars['String']>;
  PEBCountryCodeISOAccNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CurrencyConnection = {
  __typename?: 'CurrencyConnection';
  count: Scalars['Int'];
  edges: Array<CurrencyConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CurrencyConnectionEdge = {
  __typename?: 'CurrencyConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Currency;
};

export type CurrencyDeleteResponse = {
  __typename?: 'CurrencyDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Currency>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CurrencyExchangeRate = {
  __typename?: 'CurrencyExchangeRate';
  discriminator?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  ExchangeRateAmount?: Maybe<Scalars['Float']>;
  AdjustmentExchRateAmount?: Maybe<Scalars['Float']>;
  RelationalCurrencyCode?: Maybe<Scalars['String']>;
  RelationalExchRateAmount?: Maybe<Scalars['Float']>;
  FixExchRateAmount?: Maybe<Scalars['Float']>;
  FixExchRateAmountOPTION?: Maybe<Scalars['String']>;
  RelationalAdjmtExchRateAmt?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CurrencyExchangeRateConnection = {
  __typename?: 'CurrencyExchangeRateConnection';
  count: Scalars['Int'];
  edges: Array<CurrencyExchangeRateConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CurrencyExchangeRateConnectionEdge = {
  __typename?: 'CurrencyExchangeRateConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CurrencyExchangeRate;
};

export type CurrencyExchangeRateDeleteResponse = {
  __typename?: 'CurrencyExchangeRateDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CurrencyExchangeRate>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CurrencyExchangeRateManyResult = {
  __typename?: 'CurrencyExchangeRateManyResult';
  records?: Maybe<Array<Maybe<CurrencyExchangeRate>>>;
  pageData?: Maybe<PageData>;
};

export type CurrencyManyResult = {
  __typename?: 'CurrencyManyResult';
  records?: Maybe<Array<Maybe<Currency>>>;
  pageData?: Maybe<PageData>;
};

export type CustomerDomain = {
  __typename?: 'CustomerDomain';
  discriminator?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AdminUserGlobalId?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  DomainImageBase64?: Maybe<Scalars['String']>;
  DomainImageBase64Name?: Maybe<Scalars['String']>;
  DomainMainOrientation?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  VerifiedOn?: Maybe<Scalars['DateTime']>;
  VerifiedByUserEmail?: Maybe<Scalars['String']>;
  VerifiedByUserName?: Maybe<Scalars['String']>;
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  TrialStartedOn?: Maybe<Scalars['DateTime']>;
  TrialEndsOn?: Maybe<Scalars['DateTime']>;
  TrialLengthDays?: Maybe<Scalars['Float']>;
  TrailActive?: Maybe<Scalars['Boolean']>;
  InvoiceAddress?: Maybe<Scalars['String']>;
  InvoicePostalCode?: Maybe<Scalars['String']>;
  InvoiceCity?: Maybe<Scalars['String']>;
  InvoiceNote?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type CustomerDomainConnection = {
  __typename?: 'CustomerDomainConnection';
  count: Scalars['Int'];
  edges: Array<CustomerDomainConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomerDomainConnectionEdge = {
  __typename?: 'CustomerDomainConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CustomerDomain;
};

export type CustomerDomainDeleteResponse = {
  __typename?: 'CustomerDomainDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CustomerDomain>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerDomainLimited = {
  __typename?: 'CustomerDomainLimited';
  RegistrationNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type CustomerDomainManyResult = {
  __typename?: 'CustomerDomainManyResult';
  records?: Maybe<Array<Maybe<CustomerDomain>>>;
  pageData?: Maybe<PageData>;
};

export type CustomerPostingGroup = {
  __typename?: 'CustomerPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ReceivablesAccount?: Maybe<Scalars['String']>;
  ServiceChargeAcc?: Maybe<Scalars['String']>;
  PaymentDiscDebitAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingAccount?: Maybe<Scalars['String']>;
  AdditionalFeeAccount?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  DebitCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  CreditCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  DebitRoundingAccount?: Maybe<Scalars['String']>;
  CreditRoundingAccount?: Maybe<Scalars['String']>;
  PaymentDiscCreditAcc?: Maybe<Scalars['String']>;
  PaymentToleranceDebitAcc?: Maybe<Scalars['String']>;
  PaymentToleranceCreditAcc?: Maybe<Scalars['String']>;
  AddFeeperLineAccount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ViewAllAccountsonLookup?: Maybe<Scalars['Boolean']>;
  EQMDepositReceiveablesAcc?: Maybe<Scalars['String']>;
  EQMDepositAccount?: Maybe<Scalars['String']>;
  EQMPrepaymentReceivableAcc?: Maybe<Scalars['String']>;
  EQMPrepaymentAccount?: Maybe<Scalars['String']>;
  EQMBankTransferReceiveables?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerPostingGroupConnection = {
  __typename?: 'CustomerPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<CustomerPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomerPostingGroupConnectionEdge = {
  __typename?: 'CustomerPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CustomerPostingGroup;
};

export type CustomerPostingGroupDeleteResponse = {
  __typename?: 'CustomerPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CustomerPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerPostingGroupManyResult = {
  __typename?: 'CustomerPostingGroupManyResult';
  records?: Maybe<Array<Maybe<CustomerPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type CustomerProject = {
  __typename?: 'CustomerProject';
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  StatusChangedBy?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  DimensionConstructProject?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  CombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['String']>;
  CombineInvoiceSubProject?: Maybe<Scalars['Boolean']>;
  SubProjectMandatory?: Maybe<Scalars['Boolean']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  CustomerRentPriceGroup?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  InsuranceCode?: Maybe<Scalars['String']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  Incentive?: Maybe<Scalars['Boolean']>;
  ReturnConfirmationperEMail?: Maybe<Scalars['Boolean']>;
  Inactive?: Maybe<Scalars['Boolean']>;
  ExpectedTurnover?: Maybe<Scalars['Float']>;
  ShiptoInstruction?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  LockPrices?: Maybe<Scalars['Boolean']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  EquipmentListperEMail?: Maybe<Scalars['String']>;
  EmailEquipmentList?: Maybe<Scalars['String']>;
  EmailFrequency?: Maybe<Scalars['String']>;
  InternalComment?: Maybe<Scalars['String']>;
  PopUp?: Maybe<Scalars['String']>;
  InactivateNoSaleMail?: Maybe<Scalars['Boolean']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactPhoneNo?: Maybe<Scalars['String']>;
  CustomerProjectRegistrationNo?: Maybe<Scalars['String']>;
  Deactivated?: Maybe<Scalars['Boolean']>;
  Finished?: Maybe<Scalars['Boolean']>;
  LocationManagerContact?: Maybe<Scalars['String']>;
  ProjectManager?: Maybe<Scalars['String']>;
  Deliverycontact?: Maybe<Scalars['String']>;
  ProjectManagerEmail?: Maybe<Scalars['String']>;
  DeliveryContactEmail?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  ExternallyCreated?: Maybe<Scalars['Boolean']>;
  IsUsingDomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  RentIsActive?: Maybe<Scalars['Boolean']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  SendToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerProjectConnection = {
  __typename?: 'CustomerProjectConnection';
  count: Scalars['Int'];
  edges: Array<CustomerProjectConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomerProjectConnectionEdge = {
  __typename?: 'CustomerProjectConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CustomerProject;
};

export type CustomerProjectDeleteResponse = {
  __typename?: 'CustomerProjectDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CustomerProject>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerProjectManyResult = {
  __typename?: 'CustomerProjectManyResult';
  records?: Maybe<Array<Maybe<CustomerProject>>>;
  pageData?: Maybe<PageData>;
};

export type CustomerSubProject = {
  __typename?: 'CustomerSubProject';
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  CombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  InsuranceCode?: Maybe<Scalars['String']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerSubProjectConnection = {
  __typename?: 'CustomerSubProjectConnection';
  count: Scalars['Int'];
  edges: Array<CustomerSubProjectConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomerSubProjectConnectionEdge = {
  __typename?: 'CustomerSubProjectConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CustomerSubProject;
};

export type CustomerSubProjectDeleteResponse = {
  __typename?: 'CustomerSubProjectDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<CustomerSubProject>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerSubProjectManyResult = {
  __typename?: 'CustomerSubProjectManyResult';
  records?: Maybe<Array<Maybe<CustomerSubProject>>>;
  pageData?: Maybe<PageData>;
};

export type DailyRentalEvents = {
  __typename?: 'DailyRentalEvents';
  returnLines?: Maybe<Array<Maybe<RentalPostedCollLine>>>;
  shipmentLines?: Maybe<Array<Maybe<RentalPostedShptLine>>>;
};

export type DailyTransactionResponse = {
  __typename?: 'DailyTransactionResponse';
  usersNotified?: Maybe<Array<Maybe<User>>>;
};


export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DeletedRecord>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeletedRecord = {
  __typename?: 'DeletedRecord';
  GlobalId?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  discriminator?: Maybe<Scalars['String']>;
};

export type DeletedRecordRef = {
  __typename?: 'DeletedRecordRef';
  GlobalId?: Maybe<Scalars['String']>;
};

export type Deliverytime = {
  __typename?: 'Deliverytime';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliverytimeConnection = {
  __typename?: 'DeliverytimeConnection';
  count: Scalars['Int'];
  edges: Array<DeliverytimeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DeliverytimeConnectionEdge = {
  __typename?: 'DeliverytimeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Deliverytime;
};

export type DeliverytimeDeleteResponse = {
  __typename?: 'DeliverytimeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Deliverytime>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeliverytimeManyResult = {
  __typename?: 'DeliverytimeManyResult';
  records?: Maybe<Array<Maybe<Deliverytime>>>;
  pageData?: Maybe<PageData>;
};

export type DevopsProject = {
  __typename?: 'DevopsProject';
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['String']>;
  lastUpdateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DevopsProjectConnection = {
  __typename?: 'DevopsProjectConnection';
  count: Scalars['Int'];
  edges: Array<DevopsProjectConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DevopsProjectConnectionEdge = {
  __typename?: 'DevopsProjectConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DevopsProject;
};

export type DevopsProjectDeleteResponse = {
  __typename?: 'DevopsProjectDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DevopsProject>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DevopsProjectManyResult = {
  __typename?: 'DevopsProjectManyResult';
  records?: Maybe<Array<Maybe<DevopsProject>>>;
  pageData?: Maybe<PageData>;
};

export type DevopsTeam = {
  __typename?: 'DevopsTeam';
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DevopsTeamConnection = {
  __typename?: 'DevopsTeamConnection';
  count: Scalars['Int'];
  edges: Array<DevopsTeamConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DevopsTeamConnectionEdge = {
  __typename?: 'DevopsTeamConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DevopsTeam;
};

export type DevopsTeamDeleteResponse = {
  __typename?: 'DevopsTeamDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DevopsTeam>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DevopsTeamManyResult = {
  __typename?: 'DevopsTeamManyResult';
  records?: Maybe<Array<Maybe<DevopsTeam>>>;
  pageData?: Maybe<PageData>;
};

export type DevopsWorkItem = {
  __typename?: 'DevopsWorkItem';
  discriminator?: Maybe<Scalars['String']>;
  rev?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DevopsWorkItemConnection = {
  __typename?: 'DevopsWorkItemConnection';
  count: Scalars['Int'];
  edges: Array<DevopsWorkItemConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DevopsWorkItemConnectionEdge = {
  __typename?: 'DevopsWorkItemConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DevopsWorkItem;
};

export type DevopsWorkItemDeleteResponse = {
  __typename?: 'DevopsWorkItemDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DevopsWorkItem>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DevopsWorkItemManyResult = {
  __typename?: 'DevopsWorkItemManyResult';
  records?: Maybe<Array<Maybe<DevopsWorkItem>>>;
  pageData?: Maybe<PageData>;
};

export type Dimension = {
  __typename?: 'Dimension';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CodeCaption?: Maybe<Scalars['String']>;
  FilterCaption?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  ConsolidationCode?: Maybe<Scalars['String']>;
  MaptoICDimensionCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DimensionConnection = {
  __typename?: 'DimensionConnection';
  count: Scalars['Int'];
  edges: Array<DimensionConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DimensionConnectionEdge = {
  __typename?: 'DimensionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Dimension;
};

export type DimensionDeleteResponse = {
  __typename?: 'DimensionDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Dimension>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DimensionManyResult = {
  __typename?: 'DimensionManyResult';
  records?: Maybe<Array<Maybe<Dimension>>>;
  pageData?: Maybe<PageData>;
};

export type DimensionValue = {
  __typename?: 'DimensionValue';
  discriminator?: Maybe<Scalars['String']>;
  DimensionCode?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DimensionValueType?: Maybe<Scalars['Float']>;
  DimensionValueTypeOPTION?: Maybe<Scalars['String']>;
  Totaling?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  ConsolidationCode?: Maybe<Scalars['String']>;
  Indentation?: Maybe<Scalars['Float']>;
  GlobalDimensionNo?: Maybe<Scalars['Float']>;
  MaptoICDimensionCode?: Maybe<Scalars['String']>;
  MaptoICDimensionValueCode?: Maybe<Scalars['String']>;
  DimensionValueID?: Maybe<Scalars['Float']>;
  EQMCostCenterDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectTypeDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectGroupDimension?: Maybe<Scalars['Boolean']>;
  EQMRelatedSalesCostDim?: Maybe<Scalars['Boolean']>;
  EQMRentalContractDimension?: Maybe<Scalars['Boolean']>;
  EQMCustomerDimension?: Maybe<Scalars['Boolean']>;
  EQMCustomerProjectDimension?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DimensionValueConnection = {
  __typename?: 'DimensionValueConnection';
  count: Scalars['Int'];
  edges: Array<DimensionValueConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DimensionValueConnectionEdge = {
  __typename?: 'DimensionValueConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DimensionValue;
};

export type DimensionValueDeleteResponse = {
  __typename?: 'DimensionValueDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DimensionValue>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DimensionValueManyResult = {
  __typename?: 'DimensionValueManyResult';
  records?: Maybe<Array<Maybe<DimensionValue>>>;
  pageData?: Maybe<PageData>;
};

export type DiscussionPost = {
  __typename?: 'DiscussionPost';
  discriminator?: Maybe<Scalars['String']>;
  FromEmail?: Maybe<Scalars['String']>;
  FromName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  Text?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  IsFromCustomer?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DiscussionPostConnection = {
  __typename?: 'DiscussionPostConnection';
  count: Scalars['Int'];
  edges: Array<DiscussionPostConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DiscussionPostConnectionEdge = {
  __typename?: 'DiscussionPostConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DiscussionPost;
};

export type DiscussionPostDeleteResponse = {
  __typename?: 'DiscussionPostDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DiscussionPost>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DiscussionPostManyResult = {
  __typename?: 'DiscussionPostManyResult';
  records?: Maybe<Array<Maybe<DiscussionPost>>>;
  pageData?: Maybe<PageData>;
};

export type Document = {
  __typename?: 'Document';
  discriminator?: Maybe<Scalars['String']>;
  SignInitiatingUserName?: Maybe<Scalars['String']>;
  SignInitiatingUserGlobalId?: Maybe<Scalars['String']>;
  SignRequestSentToUserName?: Maybe<Scalars['String']>;
  SignRequestSentToUserEmail?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RecName?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentSubType?: Maybe<Scalars['Float']>;
  DocumentSubTypeOPTION?: Maybe<Scalars['String']>;
  Signed?: Maybe<Scalars['Boolean']>;
  SignedByUserGlobalId?: Maybe<Scalars['String']>;
  SignedMethod?: Maybe<Scalars['String']>;
  SignedOn?: Maybe<Scalars['DateTime']>;
  OriginalFileName?: Maybe<Scalars['String']>;
  SignedFileName?: Maybe<Scalars['String']>;
  SignedPrintedName?: Maybe<Scalars['String']>;
  DocumentETag?: Maybe<Scalars['String']>;
  Base64WriteOnly?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  Created?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<Maybe<StoredFile>>>;
  originalFile?: Maybe<StoredFile>;
  SignedByUser?: Maybe<User>;
  image?: Maybe<StoredFile>;
};

export type DocumentClassification = {
  __typename?: 'DocumentClassification';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DocumentClassificationConnection = {
  __typename?: 'DocumentClassificationConnection';
  count: Scalars['Int'];
  edges: Array<DocumentClassificationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentClassificationConnectionEdge = {
  __typename?: 'DocumentClassificationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DocumentClassification;
};

export type DocumentClassificationDeleteResponse = {
  __typename?: 'DocumentClassificationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DocumentClassification>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentClassificationManyResult = {
  __typename?: 'DocumentClassificationManyResult';
  records?: Maybe<Array<Maybe<DocumentClassification>>>;
  pageData?: Maybe<PageData>;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  count: Scalars['Int'];
  edges: Array<DocumentConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentConnectionEdge = {
  __typename?: 'DocumentConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Document;
};

export type DocumentDeleteResponse = {
  __typename?: 'DocumentDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Document>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentManyResult = {
  __typename?: 'DocumentManyResult';
  records?: Maybe<Array<Maybe<Document>>>;
  pageData?: Maybe<PageData>;
};

export type DocumentRequest = {
  __typename?: 'DocumentRequest';
  discriminator?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  RecName?: Maybe<Scalars['String']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentSubType?: Maybe<Scalars['Float']>;
  DocumentSubTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DocumentRequestConnection = {
  __typename?: 'DocumentRequestConnection';
  count: Scalars['Int'];
  edges: Array<DocumentRequestConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentRequestConnectionEdge = {
  __typename?: 'DocumentRequestConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DocumentRequest;
};

export type DocumentRequestDeleteResponse = {
  __typename?: 'DocumentRequestDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DocumentRequest>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentRequestManyResult = {
  __typename?: 'DocumentRequestManyResult';
  records?: Maybe<Array<Maybe<DocumentRequest>>>;
  pageData?: Maybe<PageData>;
};

export type Domain = {
  __typename?: 'Domain';
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ImgURL?: Maybe<Scalars['String']>;
  AppBackgroundImgURL?: Maybe<Scalars['String']>;
  AdminUserEmail?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  IsDemo?: Maybe<Scalars['Boolean']>;
  EnableObjectsLimited?: Maybe<Scalars['Boolean']>;
  EnableParkingSpotsLimited?: Maybe<Scalars['Boolean']>;
  ImageEtag?: Maybe<Scalars['String']>;
  ImageDocGlobalId?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  DomainImageBase64?: Maybe<Scalars['String']>;
  DomainImageBase64Name?: Maybe<Scalars['String']>;
  DomainMainOrientation?: Maybe<Scalars['String']>;
  WebhookURL?: Maybe<Scalars['String']>;
  ParentDomainId?: Maybe<Scalars['String']>;
  AlternativeName?: Maybe<Scalars['String']>;
  FlagImageUrl?: Maybe<Scalars['String']>;
  VisibleInSafetyRespect?: Maybe<Scalars['Boolean']>;
  ReservationsEnabled?: Maybe<Scalars['Boolean']>;
  ReturnReservationsEnabled?: Maybe<Scalars['Boolean']>;
  SendToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DomainConnection = {
  __typename?: 'DomainConnection';
  count: Scalars['Int'];
  edges: Array<DomainConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DomainConnectionEdge = {
  __typename?: 'DomainConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Domain;
};

export type DomainConnectionTestResult = {
  __typename?: 'DomainConnectionTestResult';
  ok?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type DomainDeleteResponse = {
  __typename?: 'DomainDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Domain>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DomainIntegration = {
  __typename?: 'DomainIntegration';
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  Username?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  AuthType?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  grant_type?: Maybe<Scalars['String']>;
  commonAuthority?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  ConnectionType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  SubType?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  MetaDataJson?: Maybe<Scalars['String']>;
  DisableOrderPolling?: Maybe<Scalars['Boolean']>;
  PreviousMagentoOrderId?: Maybe<Scalars['Float']>;
  PreviousDekraPollingDateTime?: Maybe<Scalars['String']>;
  SchemaVersion?: Maybe<DomainIntegrationSchemaVersionEnum>;
  Online?: Maybe<Scalars['Boolean']>;
  AutoSyncUnsentRecords?: Maybe<Scalars['Boolean']>;
  LatestHeartBeat?: Maybe<Scalars['DateTime']>;
  FirstHeartBeat?: Maybe<Scalars['DateTime']>;
  HeartBeartIntervalMinutes?: Maybe<Scalars['Float']>;
  NoOfReqsInOutboundQueue?: Maybe<Scalars['Float']>;
  NoOfReqsInInboundQueue?: Maybe<Scalars['Float']>;
  NoOfUnprocessableItems?: Maybe<Scalars['Float']>;
  HeartbeatMonitoringEnabled?: Maybe<Scalars['Boolean']>;
  OAuth2_URL?: Maybe<Scalars['String']>;
  OAuth2_Grant_Type?: Maybe<Scalars['String']>;
  OAuth2_Scope?: Maybe<Scalars['String']>;
  OAuth2_Client_Id?: Maybe<Scalars['String']>;
  OAuth2_Client_Secret?: Maybe<Scalars['String']>;
  OAuth2_Redirect_URL?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DomainIntegrationConnection = {
  __typename?: 'DomainIntegrationConnection';
  count: Scalars['Int'];
  edges: Array<DomainIntegrationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DomainIntegrationConnectionEdge = {
  __typename?: 'DomainIntegrationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DomainIntegration;
};

export type DomainIntegrationDeleteResponse = {
  __typename?: 'DomainIntegrationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DomainIntegration>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DomainIntegrationManyResult = {
  __typename?: 'DomainIntegrationManyResult';
  records?: Maybe<Array<Maybe<DomainIntegration>>>;
  pageData?: Maybe<PageData>;
};

export enum DomainIntegrationSchemaVersionEnum {
  Basic = 'Basic',
  Meta = 'Meta',
  Array = 'Array'
}

export type DomainInvitationInput = {
  DomainId?: Maybe<Scalars['String']>;
  DomainName?: Maybe<Scalars['String']>;
  IOSAppLink?: Maybe<Scalars['String']>;
  AndroidAppLink?: Maybe<Scalars['String']>;
  IOSAppCode?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
};

export type DomainLimited = {
  __typename?: 'DomainLimited';
  GlobalId?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  AlternativeName?: Maybe<Scalars['String']>;
  FlagImageUrl?: Maybe<Scalars['String']>;
  ReservationsEnabled?: Maybe<Scalars['Boolean']>;
  ReturnReservationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type DomainManyResult = {
  __typename?: 'DomainManyResult';
  records?: Maybe<Array<Maybe<Domain>>>;
  pageData?: Maybe<PageData>;
};

export type DomainSettings = {
  __typename?: 'DomainSettings';
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type DomainSettingsConnection = {
  __typename?: 'DomainSettingsConnection';
  count: Scalars['Int'];
  edges: Array<DomainSettingsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DomainSettingsConnectionEdge = {
  __typename?: 'DomainSettingsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: DomainSettings;
};

export type DomainSettingsDeleteResponse = {
  __typename?: 'DomainSettingsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<DomainSettings>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DomainSettingsManyResult = {
  __typename?: 'DomainSettingsManyResult';
  records?: Maybe<Array<Maybe<DomainSettings>>>;
  pageData?: Maybe<PageData>;
};


export type EmailRecipientInput = {
  Name?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

export type EqmBaseCalendar = {
  __typename?: 'EqmBaseCalendar';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CustomizedChangesExist?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmBaseCalendarChange = {
  __typename?: 'EqmBaseCalendarChange';
  discriminator?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  RecurringSystem?: Maybe<Scalars['Float']>;
  RecurringSystemOPTION?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Day?: Maybe<Scalars['Float']>;
  DayOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Nonworking?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmBaseCalendarChangeConnection = {
  __typename?: 'EqmBaseCalendarChangeConnection';
  count: Scalars['Int'];
  edges: Array<EqmBaseCalendarChangeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmBaseCalendarChangeConnectionEdge = {
  __typename?: 'EqmBaseCalendarChangeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmBaseCalendarChange;
};

export type EqmBaseCalendarChangeDeleteResponse = {
  __typename?: 'EqmBaseCalendarChangeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmBaseCalendarChange>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmBaseCalendarChangeManyResult = {
  __typename?: 'EqmBaseCalendarChangeManyResult';
  records?: Maybe<Array<Maybe<EqmBaseCalendarChange>>>;
  pageData?: Maybe<PageData>;
};

export type EqmBaseCalendarConnection = {
  __typename?: 'EqmBaseCalendarConnection';
  count: Scalars['Int'];
  edges: Array<EqmBaseCalendarConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmBaseCalendarConnectionEdge = {
  __typename?: 'EqmBaseCalendarConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmBaseCalendar;
};

export type EqmBaseCalendarDeleteResponse = {
  __typename?: 'EqmBaseCalendarDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmBaseCalendar>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmBaseCalendarManyResult = {
  __typename?: 'EqmBaseCalendarManyResult';
  records?: Maybe<Array<Maybe<EqmBaseCalendar>>>;
  pageData?: Maybe<PageData>;
};

export type EqmCollectionReturnCharges = {
  __typename?: 'EqmCollectionReturnCharges';
  discriminator?: Maybe<Scalars['String']>;
  ExtDocumentNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  FunctionalTestHeaderNo?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAESMotherMarkedForReturn?: Maybe<Scalars['Boolean']>;
  NBAESObjectNo?: Maybe<Scalars['String']>;
  NBAESObjectDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmCollectionReturnChargesConnection = {
  __typename?: 'EqmCollectionReturnChargesConnection';
  count: Scalars['Int'];
  edges: Array<EqmCollectionReturnChargesConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmCollectionReturnChargesConnectionEdge = {
  __typename?: 'EqmCollectionReturnChargesConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmCollectionReturnCharges;
};

export type EqmCollectionReturnChargesDeleteResponse = {
  __typename?: 'EqmCollectionReturnChargesDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmCollectionReturnCharges>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmCollectionReturnChargesManyResult = {
  __typename?: 'EqmCollectionReturnChargesManyResult';
  records?: Maybe<Array<Maybe<EqmCollectionReturnCharges>>>;
  pageData?: Maybe<PageData>;
};

export type EqmCommentLine = {
  __typename?: 'EqmCommentLine';
  discriminator?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  TableLineNo?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  TableSubtype?: Maybe<Scalars['Float']>;
  TableSubtypeOPTION?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['Float']>;
  TableNameOPTION?: Maybe<Scalars['String']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmCommentLineConnection = {
  __typename?: 'EqmCommentLineConnection';
  count: Scalars['Int'];
  edges: Array<EqmCommentLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmCommentLineConnectionEdge = {
  __typename?: 'EqmCommentLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmCommentLine;
};

export type EqmCommentLineDeleteResponse = {
  __typename?: 'EqmCommentLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmCommentLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmCommentLineManyResult = {
  __typename?: 'EqmCommentLineManyResult';
  records?: Maybe<Array<Maybe<EqmCommentLine>>>;
  pageData?: Maybe<PageData>;
};

export type EqmCustomizedCalendarChange = {
  __typename?: 'EqmCustomizedCalendarChange';
  discriminator?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['Float']>;
  SourceTypeOPTION?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  AdditionalSourceCode?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  RecurringSystem?: Maybe<Scalars['Float']>;
  RecurringSystemOPTION?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Day?: Maybe<Scalars['Float']>;
  DayOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Nonworking?: Maybe<Scalars['Boolean']>;
  EntryNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmCustomizedCalendarChangeConnection = {
  __typename?: 'EqmCustomizedCalendarChangeConnection';
  count: Scalars['Int'];
  edges: Array<EqmCustomizedCalendarChangeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmCustomizedCalendarChangeConnectionEdge = {
  __typename?: 'EqmCustomizedCalendarChangeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmCustomizedCalendarChange;
};

export type EqmCustomizedCalendarChangeDeleteResponse = {
  __typename?: 'EqmCustomizedCalendarChangeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmCustomizedCalendarChange>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmCustomizedCalendarChangeManyResult = {
  __typename?: 'EqmCustomizedCalendarChangeManyResult';
  records?: Maybe<Array<Maybe<EqmCustomizedCalendarChange>>>;
  pageData?: Maybe<PageData>;
};

export type EqmFncTestReturnCharges = {
  __typename?: 'EqmFncTestReturnCharges';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  TransferredToContract?: Maybe<Scalars['Boolean']>;
  TransferredBy?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmFncTestReturnChargesConnection = {
  __typename?: 'EqmFncTestReturnChargesConnection';
  count: Scalars['Int'];
  edges: Array<EqmFncTestReturnChargesConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmFncTestReturnChargesConnectionEdge = {
  __typename?: 'EqmFncTestReturnChargesConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmFncTestReturnCharges;
};

export type EqmFncTestReturnChargesDeleteResponse = {
  __typename?: 'EqmFncTestReturnChargesDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmFncTestReturnCharges>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmFncTestReturnChargesManyResult = {
  __typename?: 'EqmFncTestReturnChargesManyResult';
  records?: Maybe<Array<Maybe<EqmFncTestReturnCharges>>>;
  pageData?: Maybe<PageData>;
};

export type EqmLocCostCenterComb = {
  __typename?: 'EqmLocCostCenterComb';
  discriminator?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  CostCenterCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmLocCostCenterCombConnection = {
  __typename?: 'EqmLocCostCenterCombConnection';
  count: Scalars['Int'];
  edges: Array<EqmLocCostCenterCombConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmLocCostCenterCombConnectionEdge = {
  __typename?: 'EqmLocCostCenterCombConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmLocCostCenterComb;
};

export type EqmLocCostCenterCombDeleteResponse = {
  __typename?: 'EqmLocCostCenterCombDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmLocCostCenterComb>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmLocCostCenterCombManyResult = {
  __typename?: 'EqmLocCostCenterCombManyResult';
  records?: Maybe<Array<Maybe<EqmLocCostCenterComb>>>;
  pageData?: Maybe<PageData>;
};

export type EqmManufacturer = {
  __typename?: 'EqmManufacturer';
  discriminator?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmManufacturerConnection = {
  __typename?: 'EqmManufacturerConnection';
  count: Scalars['Int'];
  edges: Array<EqmManufacturerConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmManufacturerConnectionEdge = {
  __typename?: 'EqmManufacturerConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmManufacturer;
};

export type EqmManufacturerDeleteResponse = {
  __typename?: 'EqmManufacturerDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmManufacturer>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmManufacturerManyResult = {
  __typename?: 'EqmManufacturerManyResult';
  records?: Maybe<Array<Maybe<EqmManufacturer>>>;
  pageData?: Maybe<PageData>;
};

export type EqmManufacturerModel = {
  __typename?: 'EqmManufacturerModel';
  discriminator?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ModelCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CounterReporting?: Maybe<Scalars['Boolean']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  ItemNo?: Maybe<Scalars['String']>;
  ItemDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Capacity?: Maybe<Scalars['Float']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  Effect?: Maybe<Scalars['Float']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectTypeDescription?: Maybe<Scalars['String']>;
  AttachmentNo?: Maybe<Scalars['String']>;
  AttachmentNoSeries?: Maybe<Scalars['String']>;
  NoofObjects?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  SRALink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmManufacturerModelConnection = {
  __typename?: 'EqmManufacturerModelConnection';
  count: Scalars['Int'];
  edges: Array<EqmManufacturerModelConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmManufacturerModelConnectionEdge = {
  __typename?: 'EqmManufacturerModelConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmManufacturerModel;
};

export type EqmManufacturerModelDeleteResponse = {
  __typename?: 'EqmManufacturerModelDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmManufacturerModel>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmManufacturerModelManyResult = {
  __typename?: 'EqmManufacturerModelManyResult';
  records?: Maybe<Array<Maybe<EqmManufacturerModel>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectCardFlow = {
  __typename?: 'EqmObjectCardFlow';
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  DateFilter2?: Maybe<Scalars['DateTime']>;
  ServiceItemNo?: Maybe<Scalars['String']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowCostofSale?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowComponentCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowAppreciation?: Maybe<Scalars['Float']>;
  FlowWriteDown?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  Invoiceddays?: Maybe<Scalars['Float']>;
  FlowLinkedOtherCost?: Maybe<Scalars['Float']>;
  FlowLinkedLeasingCost?: Maybe<Scalars['Float']>;
  FlowLinkedRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedServiceCost?: Maybe<Scalars['Float']>;
  FlowLinkedGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedServiceAgrCost?: Maybe<Scalars['Float']>;
  FlowLinkedDepreciation?: Maybe<Scalars['Float']>;
  FlowLinkedUppreciation?: Maybe<Scalars['Float']>;
  FlowLinkedDownpreciation?: Maybe<Scalars['Float']>;
  FlowLinkedServiceIncome?: Maybe<Scalars['Float']>;
  FlowLinkedSrvAgrIncome?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedOtherIncome?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectCardFlowConnection = {
  __typename?: 'EqmObjectCardFlowConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectCardFlowConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectCardFlowConnectionEdge = {
  __typename?: 'EqmObjectCardFlowConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectCardFlow;
};

export type EqmObjectCardFlowDeleteResponse = {
  __typename?: 'EqmObjectCardFlowDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectCardFlow>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectCardFlowManyResult = {
  __typename?: 'EqmObjectCardFlowManyResult';
  records?: Maybe<Array<Maybe<EqmObjectCardFlow>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectComponents = {
  __typename?: 'EqmObjectComponents';
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  LevelofAspiration?: Maybe<Scalars['Float']>;
  LevelofAspirationOPTION?: Maybe<Scalars['String']>;
  PurchaseNo?: Maybe<Scalars['String']>;
  FromService?: Maybe<Scalars['Boolean']>;
  Ordered?: Maybe<Scalars['Boolean']>;
  FatherObjectSold?: Maybe<Scalars['Boolean']>;
  Typeold?: Maybe<Scalars['Float']>;
  TypeoldOPTION?: Maybe<Scalars['String']>;
  LineCost?: Maybe<Scalars['Float']>;
  Assembled?: Maybe<Scalars['Boolean']>;
  NonBillable?: Maybe<Scalars['Boolean']>;
  PostReclass?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectComponentsConnection = {
  __typename?: 'EqmObjectComponentsConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectComponentsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectComponentsConnectionEdge = {
  __typename?: 'EqmObjectComponentsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectComponents;
};

export type EqmObjectComponentsDeleteResponse = {
  __typename?: 'EqmObjectComponentsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectComponents>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectComponentsManyResult = {
  __typename?: 'EqmObjectComponentsManyResult';
  records?: Maybe<Array<Maybe<EqmObjectComponents>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectCountHeader = {
  __typename?: 'EqmObjectCountHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  DocumentStatus?: Maybe<Scalars['Float']>;
  DocumentStatusOPTION?: Maybe<Scalars['String']>;
  DateCounted?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectCountHeaderConnection = {
  __typename?: 'EqmObjectCountHeaderConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectCountHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectCountHeaderConnectionEdge = {
  __typename?: 'EqmObjectCountHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectCountHeader;
};

export type EqmObjectCountHeaderDeleteResponse = {
  __typename?: 'EqmObjectCountHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectCountHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectCountHeaderManyResult = {
  __typename?: 'EqmObjectCountHeaderManyResult';
  records?: Maybe<Array<Maybe<EqmObjectCountHeader>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectCountLine = {
  __typename?: 'EqmObjectCountLine';
  discriminator?: Maybe<Scalars['String']>;
  CountNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectTypeNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DateCounted?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  DocumentStatus?: Maybe<Scalars['Float']>;
  DocumentStatusOPTION?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Selected?: Maybe<Scalars['Boolean']>;
  Corrected?: Maybe<Scalars['Boolean']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['Float']>;
  RentalStatusOPTION?: Maybe<Scalars['String']>;
  RentalContractNo?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  LineMessage?: Maybe<Scalars['String']>;
  CountedObjectNo?: Maybe<Scalars['String']>;
  MemoText?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectCountLineConnection = {
  __typename?: 'EqmObjectCountLineConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectCountLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectCountLineConnectionEdge = {
  __typename?: 'EqmObjectCountLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectCountLine;
};

export type EqmObjectCountLineDeleteResponse = {
  __typename?: 'EqmObjectCountLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectCountLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectCountLineManyResult = {
  __typename?: 'EqmObjectCountLineManyResult';
  records?: Maybe<Array<Maybe<EqmObjectCountLine>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectGroupCard = {
  __typename?: 'EqmObjectGroupCard';
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  ReRentPostingGroup?: Maybe<Scalars['String']>;
  NoofObjects?: Maybe<Scalars['Float']>;
  NoofObjectType?: Maybe<Scalars['Float']>;
  ObjectNos?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['Float']>;
  DepreciationMethodOPTION?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['Float']>;
  StdRentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  FixedAssetSetupGroup?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupNoConcern?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  TaxDepreciationMethod?: Maybe<Scalars['Float']>;
  TaxDepreciationMethodOPTION?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectGroupCardConnection = {
  __typename?: 'EqmObjectGroupCardConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectGroupCardConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectGroupCardConnectionEdge = {
  __typename?: 'EqmObjectGroupCardConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectGroupCard;
};

export type EqmObjectGroupCardDeleteResponse = {
  __typename?: 'EqmObjectGroupCardDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectGroupCard>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectGroupCardManyResult = {
  __typename?: 'EqmObjectGroupCardManyResult';
  records?: Maybe<Array<Maybe<EqmObjectGroupCard>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectGroupFlow = {
  __typename?: 'EqmObjectGroupFlow';
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectGroupFlowConnection = {
  __typename?: 'EqmObjectGroupFlowConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectGroupFlowConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectGroupFlowConnectionEdge = {
  __typename?: 'EqmObjectGroupFlowConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectGroupFlow;
};

export type EqmObjectGroupFlowDeleteResponse = {
  __typename?: 'EqmObjectGroupFlowDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectGroupFlow>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectGroupFlowManyResult = {
  __typename?: 'EqmObjectGroupFlowManyResult';
  records?: Maybe<Array<Maybe<EqmObjectGroupFlow>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectServiceInterval = {
  __typename?: 'EqmObjectServiceInterval';
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  DateCompleted?: Maybe<Scalars['DateTime']>;
  TransferedtoOrder?: Maybe<Scalars['String']>;
  TransferedtoWSOrder?: Maybe<Scalars['String']>;
  TransferedtoWSPurchase?: Maybe<Scalars['String']>;
  TransferedtoEQMService?: Maybe<Scalars['String']>;
  TransferredtoWorkOrder?: Maybe<Scalars['String']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  ServiceInitiator1?: Maybe<Scalars['Float']>;
  ServiceInitiator1OPTION?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  PostedDocumentType?: Maybe<Scalars['Float']>;
  PostedDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PostedDocumentNo?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  Certification?: Maybe<Scalars['Boolean']>;
  ObjectId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ManufacturerModelId?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  ObjectGroupId?: Maybe<Scalars['Float']>;
  ServiceCode?: Maybe<Scalars['String']>;
  MarginDate?: Maybe<Scalars['DateTime']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  ObjectRentalStatus?: Maybe<Scalars['Float']>;
  ObjectRentalStatusOPTION?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  DekraInspectionResult?: Maybe<Scalars['Float']>;
  DekraInspectionResultOPTION?: Maybe<Scalars['String']>;
  DekraInspection?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectServiceIntervalConnection = {
  __typename?: 'EqmObjectServiceIntervalConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectServiceIntervalConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectServiceIntervalConnectionEdge = {
  __typename?: 'EqmObjectServiceIntervalConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectServiceInterval;
};

export type EqmObjectServiceIntervalDeleteResponse = {
  __typename?: 'EqmObjectServiceIntervalDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectServiceInterval>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectServiceIntervalManyResult = {
  __typename?: 'EqmObjectServiceIntervalManyResult';
  records?: Maybe<Array<Maybe<EqmObjectServiceInterval>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectSrvIntervalAgg = {
  __typename?: 'EqmObjectSrvIntervalAgg';
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  DateCompleted?: Maybe<Scalars['DateTime']>;
  TransferedtoOrder?: Maybe<Scalars['String']>;
  TransferedtoWSOrder?: Maybe<Scalars['String']>;
  TransferedtoWSPurchase?: Maybe<Scalars['String']>;
  TransferedtoEQMService?: Maybe<Scalars['String']>;
  TransferredtoWorkOrder?: Maybe<Scalars['String']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  PostedDocumentType?: Maybe<Scalars['Float']>;
  PostedDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PostedDocumentNo?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  ObjectId?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  ManufacturerModelId?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  AssociatedServicePackId?: Maybe<Scalars['String']>;
  TransferredtoOrderId?: Maybe<Scalars['String']>;
  TransferredtoWSOrderId?: Maybe<Scalars['String']>;
  TransferredtoWSPurchId?: Maybe<Scalars['String']>;
  TransferredtoEQMServiceId?: Maybe<Scalars['String']>;
  TransferredtoWorkOrderId?: Maybe<Scalars['String']>;
  RepairId?: Maybe<Scalars['String']>;
  PostedDocumentId?: Maybe<Scalars['String']>;
  ControlTypeId?: Maybe<Scalars['String']>;
  ServiceTypeId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectSrvIntervalAggConnection = {
  __typename?: 'EqmObjectSrvIntervalAggConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectSrvIntervalAggConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectSrvIntervalAggConnectionEdge = {
  __typename?: 'EqmObjectSrvIntervalAggConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectSrvIntervalAgg;
};

export type EqmObjectSrvIntervalAggDeleteResponse = {
  __typename?: 'EqmObjectSrvIntervalAggDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectSrvIntervalAgg>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectSrvIntervalAggManyResult = {
  __typename?: 'EqmObjectSrvIntervalAggManyResult';
  records?: Maybe<Array<Maybe<EqmObjectSrvIntervalAgg>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectStatus = {
  __typename?: 'EqmObjectStatus';
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Descripton?: Maybe<Scalars['String']>;
  Answer?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectStatusConnection = {
  __typename?: 'EqmObjectStatusConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectStatusConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectStatusConnectionEdge = {
  __typename?: 'EqmObjectStatusConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectStatus;
};

export type EqmObjectStatusDeleteResponse = {
  __typename?: 'EqmObjectStatusDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectStatus>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectStatusManyResult = {
  __typename?: 'EqmObjectStatusManyResult';
  records?: Maybe<Array<Maybe<EqmObjectStatus>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectTypeCard = {
  __typename?: 'EqmObjectTypeCard';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  CounterType?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  SaleDiscGroup?: Maybe<Scalars['String']>;
  RentalDiscGroup?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SalesPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  VATProdPostingGrpRental?: Maybe<Scalars['String']>;
  ReRentPostingGroup?: Maybe<Scalars['String']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['String']>;
  WebPage?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  DefaultFunctionalTest?: Maybe<Scalars['Boolean']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAConnectedToHierarchy?: Maybe<Scalars['String']>;
  NBAMappingNo?: Maybe<Scalars['String']>;
  NBAMappingDescription?: Maybe<Scalars['String']>;
  NBARamirentGroupCode?: Maybe<Scalars['String']>;
  NBAESInspectionInterval?: Maybe<Scalars['String']>;
  NBAESManufacturerModel?: Maybe<Scalars['String']>;
  NBAESBonusGroup?: Maybe<Scalars['String']>;
  NBAESExcludeFromVaction?: Maybe<Scalars['Boolean']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  FixedCostPrice?: Maybe<Scalars['Float']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RetentionLevel?: Maybe<Scalars['Float']>;
  TaxDepreciationMethod?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  CounterType2?: Maybe<Scalars['String']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  InventoryObject?: Maybe<Scalars['Float']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  FixedAssetSetupGroup?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  RentalReservationsQty?: Maybe<Scalars['Float']>;
  RentalDeliveriesQty?: Maybe<Scalars['Float']>;
  ExpectedRentalReturnsQty?: Maybe<Scalars['Float']>;
  RentalReturnsQty?: Maybe<Scalars['Float']>;
  PictureURL?: Maybe<Scalars['String']>;
  PSIDocumentURL?: Maybe<Scalars['String']>;
  NotAvailableForReservation?: Maybe<Scalars['Boolean']>;
  ExpirationDateMandatory?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectTypeCardConnection = {
  __typename?: 'EqmObjectTypeCardConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectTypeCardConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectTypeCardConnectionEdge = {
  __typename?: 'EqmObjectTypeCardConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectTypeCard;
};

export type EqmObjectTypeCardDeleteResponse = {
  __typename?: 'EqmObjectTypeCardDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectTypeCard>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectTypeCardManyResult = {
  __typename?: 'EqmObjectTypeCardManyResult';
  records?: Maybe<Array<Maybe<EqmObjectTypeCard>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectTypeComponents = {
  __typename?: 'EqmObjectTypeComponents';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  LevelofAspiration?: Maybe<Scalars['Float']>;
  LevelofAspirationOPTION?: Maybe<Scalars['String']>;
  EntryType?: Maybe<Scalars['Float']>;
  EntryTypeOPTION?: Maybe<Scalars['String']>;
  NonBillable?: Maybe<Scalars['Boolean']>;
  InsertAutomatically?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectTypeComponentsConnection = {
  __typename?: 'EqmObjectTypeComponentsConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectTypeComponentsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectTypeComponentsConnectionEdge = {
  __typename?: 'EqmObjectTypeComponentsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectTypeComponents;
};

export type EqmObjectTypeComponentsDeleteResponse = {
  __typename?: 'EqmObjectTypeComponentsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectTypeComponents>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectTypeComponentsManyResult = {
  __typename?: 'EqmObjectTypeComponentsManyResult';
  records?: Maybe<Array<Maybe<EqmObjectTypeComponents>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectTypeFlow = {
  __typename?: 'EqmObjectTypeFlow';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectTypeFlowConnection = {
  __typename?: 'EqmObjectTypeFlowConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectTypeFlowConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectTypeFlowConnectionEdge = {
  __typename?: 'EqmObjectTypeFlowConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectTypeFlow;
};

export type EqmObjectTypeFlowDeleteResponse = {
  __typename?: 'EqmObjectTypeFlowDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectTypeFlow>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectTypeFlowManyResult = {
  __typename?: 'EqmObjectTypeFlowManyResult';
  records?: Maybe<Array<Maybe<EqmObjectTypeFlow>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectTypePriceTerm = {
  __typename?: 'EqmObjectTypePriceTerm';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PercentofMonthlyPrice?: Maybe<Scalars['Float']>;
  FixedTermPrice?: Maybe<Scalars['Float']>;
  Note?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectTypePriceTermConnection = {
  __typename?: 'EqmObjectTypePriceTermConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectTypePriceTermConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectTypePriceTermConnectionEdge = {
  __typename?: 'EqmObjectTypePriceTermConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectTypePriceTerm;
};

export type EqmObjectTypePriceTermDeleteResponse = {
  __typename?: 'EqmObjectTypePriceTermDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectTypePriceTerm>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectTypePriceTermManyResult = {
  __typename?: 'EqmObjectTypePriceTermManyResult';
  records?: Maybe<Array<Maybe<EqmObjectTypePriceTerm>>>;
  pageData?: Maybe<PageData>;
};

export type EqmObjectTypeServiceInterval = {
  __typename?: 'EqmObjectTypeServiceInterval';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  Certification?: Maybe<Scalars['Boolean']>;
  ServiceCode?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  DekraInspection?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmObjectTypeServiceIntervalConnection = {
  __typename?: 'EqmObjectTypeServiceIntervalConnection';
  count: Scalars['Int'];
  edges: Array<EqmObjectTypeServiceIntervalConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmObjectTypeServiceIntervalConnectionEdge = {
  __typename?: 'EqmObjectTypeServiceIntervalConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmObjectTypeServiceInterval;
};

export type EqmObjectTypeServiceIntervalDeleteResponse = {
  __typename?: 'EqmObjectTypeServiceIntervalDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmObjectTypeServiceInterval>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmObjectTypeServiceIntervalManyResult = {
  __typename?: 'EqmObjectTypeServiceIntervalManyResult';
  records?: Maybe<Array<Maybe<EqmObjectTypeServiceInterval>>>;
  pageData?: Maybe<PageData>;
};

export type EqmReRentCard = {
  __typename?: 'EqmReRentCard';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['Float']>;
  RentalStatusOPTION?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  PurchaseDocument?: Maybe<Scalars['Float']>;
  PostedPurchaseInvoice?: Maybe<Scalars['Float']>;
  PostedPurchaseCrMemo?: Maybe<Scalars['Float']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  RentalContractOpen?: Maybe<Scalars['Float']>;
  RentalContractAll?: Maybe<Scalars['Float']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  VendorName?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ReceivedDate?: Maybe<Scalars['DateTime']>;
  ReturnedDate?: Maybe<Scalars['DateTime']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  ManufacturerModelId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmReRentCardConnection = {
  __typename?: 'EqmReRentCardConnection';
  count: Scalars['Int'];
  edges: Array<EqmReRentCardConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmReRentCardConnectionEdge = {
  __typename?: 'EqmReRentCardConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmReRentCard;
};

export type EqmReRentCardDeleteResponse = {
  __typename?: 'EqmReRentCardDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmReRentCard>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmReRentCardManyResult = {
  __typename?: 'EqmReRentCardManyResult';
  records?: Maybe<Array<Maybe<EqmReRentCard>>>;
  pageData?: Maybe<PageData>;
};

export type EqmRentalGroup = {
  __typename?: 'EqmRentalGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmRentalGroupConnection = {
  __typename?: 'EqmRentalGroupConnection';
  count: Scalars['Int'];
  edges: Array<EqmRentalGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmRentalGroupConnectionEdge = {
  __typename?: 'EqmRentalGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmRentalGroup;
};

export type EqmRentalGroupDeleteResponse = {
  __typename?: 'EqmRentalGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmRentalGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmRentalGroupManyResult = {
  __typename?: 'EqmRentalGroupManyResult';
  records?: Maybe<Array<Maybe<EqmRentalGroup>>>;
  pageData?: Maybe<PageData>;
};

export type EqmRentalKit = {
  __typename?: 'EqmRentalKIT';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  KitType?: Maybe<Scalars['Float']>;
  KitTypeOPTION?: Maybe<Scalars['String']>;
  KITPrice?: Maybe<Scalars['Float']>;
  PriceTerm?: Maybe<Scalars['String']>;
  ShowinContract?: Maybe<Scalars['Boolean']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  SpecifyKitComponents?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  DebitforReturnDate?: Maybe<Scalars['Boolean']>;
  IgnoreKITLineinDispatch?: Maybe<Scalars['Boolean']>;
  SuspendUpdateComponents?: Maybe<Scalars['Boolean']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  OnAccountBilling?: Maybe<Scalars['Boolean']>;
  UnitofMeasureId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAESBonusGroup?: Maybe<Scalars['String']>;
  UseforReporting?: Maybe<Scalars['Boolean']>;
  DefaultKIT?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RentalItemDiscGroup?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmRentalKitComponents = {
  __typename?: 'EqmRentalKITComponents';
  discriminator?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  KITNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ChildItem?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmRentalKitComponentsConnection = {
  __typename?: 'EqmRentalKITComponentsConnection';
  count: Scalars['Int'];
  edges: Array<EqmRentalKitComponentsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmRentalKitComponentsConnectionEdge = {
  __typename?: 'EqmRentalKITComponentsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmRentalKitComponents;
};

export type EqmRentalKitComponentsDeleteResponse = {
  __typename?: 'EqmRentalKITComponentsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmRentalKitComponents>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmRentalKitComponentsManyResult = {
  __typename?: 'EqmRentalKITComponentsManyResult';
  records?: Maybe<Array<Maybe<EqmRentalKitComponents>>>;
  pageData?: Maybe<PageData>;
};

export type EqmRentalKitConnection = {
  __typename?: 'EqmRentalKITConnection';
  count: Scalars['Int'];
  edges: Array<EqmRentalKitConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmRentalKitConnectionEdge = {
  __typename?: 'EqmRentalKITConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmRentalKit;
};

export type EqmRentalKitDeleteResponse = {
  __typename?: 'EqmRentalKITDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmRentalKit>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmRentalKitLines = {
  __typename?: 'EqmRentalKITLines';
  discriminator?: Maybe<Scalars['String']>;
  KITNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ChildNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmRentalKitLinesConnection = {
  __typename?: 'EqmRentalKITLinesConnection';
  count: Scalars['Int'];
  edges: Array<EqmRentalKitLinesConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmRentalKitLinesConnectionEdge = {
  __typename?: 'EqmRentalKITLinesConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmRentalKitLines;
};

export type EqmRentalKitLinesDeleteResponse = {
  __typename?: 'EqmRentalKITLinesDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmRentalKitLines>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmRentalKitLinesManyResult = {
  __typename?: 'EqmRentalKITLinesManyResult';
  records?: Maybe<Array<Maybe<EqmRentalKitLines>>>;
  pageData?: Maybe<PageData>;
};

export type EqmRentalKitManyResult = {
  __typename?: 'EqmRentalKITManyResult';
  records?: Maybe<Array<Maybe<EqmRentalKit>>>;
  pageData?: Maybe<PageData>;
};

export type EqmRentalLineDiscount = {
  __typename?: 'EqmRentalLineDiscount';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  SalesType?: Maybe<Scalars['Float']>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  UsageType?: Maybe<Scalars['Float']>;
  UsageTypeOPTION?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  NBAESDescription?: Maybe<Scalars['String']>;
  DiscountType?: Maybe<Scalars['Float']>;
  DiscountTypeOPTION?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StepwiseDiscount?: Maybe<Scalars['Float']>;
  UseStepwiseDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  FrameAgreementNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmRentalLineDiscountConnection = {
  __typename?: 'EqmRentalLineDiscountConnection';
  count: Scalars['Int'];
  edges: Array<EqmRentalLineDiscountConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmRentalLineDiscountConnectionEdge = {
  __typename?: 'EqmRentalLineDiscountConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmRentalLineDiscount;
};

export type EqmRentalLineDiscountDeleteResponse = {
  __typename?: 'EqmRentalLineDiscountDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmRentalLineDiscount>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmRentalLineDiscountManyResult = {
  __typename?: 'EqmRentalLineDiscountManyResult';
  records?: Maybe<Array<Maybe<EqmRentalLineDiscount>>>;
  pageData?: Maybe<PageData>;
};

export type EqmRentalPrice = {
  __typename?: 'EqmRentalPrice';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  SalesType?: Maybe<Scalars['Float']>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  RentalType?: Maybe<Scalars['Float']>;
  RentalTypeOPTION?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  StairCode?: Maybe<Scalars['String']>;
  ReturnPriceDisc?: Maybe<Scalars['Float']>;
  ReturnStairCode?: Maybe<Scalars['String']>;
  UsageType?: Maybe<Scalars['Float']>;
  UsageTypeOPTION?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  NBAESDescription?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  ofSalesPrice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  MinRentalPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MultipleRentalPrices?: Maybe<Scalars['Boolean']>;
  FrameAgreementNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmRentalPriceConnection = {
  __typename?: 'EqmRentalPriceConnection';
  count: Scalars['Int'];
  edges: Array<EqmRentalPriceConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmRentalPriceConnectionEdge = {
  __typename?: 'EqmRentalPriceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmRentalPrice;
};

export type EqmRentalPriceDeleteResponse = {
  __typename?: 'EqmRentalPriceDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmRentalPrice>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmRentalPriceManyResult = {
  __typename?: 'EqmRentalPriceManyResult';
  records?: Maybe<Array<Maybe<EqmRentalPrice>>>;
  pageData?: Maybe<PageData>;
};

export type EqmReturnCharges = {
  __typename?: 'EqmReturnCharges';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkType?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmReturnChargesConnection = {
  __typename?: 'EqmReturnChargesConnection';
  count: Scalars['Int'];
  edges: Array<EqmReturnChargesConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmReturnChargesConnectionEdge = {
  __typename?: 'EqmReturnChargesConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmReturnCharges;
};

export type EqmReturnChargesDeleteResponse = {
  __typename?: 'EqmReturnChargesDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmReturnCharges>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmReturnChargesManyResult = {
  __typename?: 'EqmReturnChargesManyResult';
  records?: Maybe<Array<Maybe<EqmReturnCharges>>>;
  pageData?: Maybe<PageData>;
};

export type EqmServiceCard = {
  __typename?: 'EqmServiceCard';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ObjectSerialNo?: Maybe<Scalars['String']>;
  FunctionalTestsFlow?: Maybe<Scalars['Float']>;
  WorkshopOrdersFlow?: Maybe<Scalars['Float']>;
  WorkshopPurchasesFlow?: Maybe<Scalars['Float']>;
  WorkshopPurchLinesFlow?: Maybe<Scalars['Float']>;
  PlannedStartDate?: Maybe<Scalars['DateTime']>;
  PlannedStartTime?: Maybe<Scalars['String']>;
  PlannedEndDate?: Maybe<Scalars['DateTime']>;
  PlannedEndTime?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['String']>;
  PostedFunctionalTestsFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopOrdersFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopPurchFlow?: Maybe<Scalars['Float']>;
  AvoidCalendarEntry?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ReturnDocumentNo?: Maybe<Scalars['String']>;
  ContractLineNo?: Maybe<Scalars['Float']>;
  CollectionDocumentNo?: Maybe<Scalars['String']>;
  RepairCode?: Maybe<Scalars['String']>;
  MainResource?: Maybe<Scalars['String']>;
  MainCustomer?: Maybe<Scalars['String']>;
  MainVendor?: Maybe<Scalars['String']>;
  Manufacturer?: Maybe<Scalars['String']>;
  ManufacturerModel?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  InternalCustomerNo?: Maybe<Scalars['String']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PurchaseDocumentType?: Maybe<Scalars['Float']>;
  PurchaseDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PurchaseDocumentNo?: Maybe<Scalars['String']>;
  PurchaseLineNo?: Maybe<Scalars['Float']>;
  NoSeries?: Maybe<Scalars['String']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenterCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  FinishedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  CreatedTime?: Maybe<Scalars['String']>;
  StartedBy?: Maybe<Scalars['String']>;
  NoofServiceOrders?: Maybe<Scalars['Float']>;
  NoofPostedServiceOrders?: Maybe<Scalars['Float']>;
  ControlTestsFlow?: Maybe<Scalars['Float']>;
  PostedControlTestFlow?: Maybe<Scalars['Float']>;
  FunctionalTestPictures?: Maybe<Scalars['Float']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  WorkOrderFlow?: Maybe<Scalars['Float']>;
  NoofReturnCharges?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmServiceCardConnection = {
  __typename?: 'EqmServiceCardConnection';
  count: Scalars['Int'];
  edges: Array<EqmServiceCardConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmServiceCardConnectionEdge = {
  __typename?: 'EqmServiceCardConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmServiceCard;
};

export type EqmServiceCardDeleteResponse = {
  __typename?: 'EqmServiceCardDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmServiceCard>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmServiceCardManyResult = {
  __typename?: 'EqmServiceCardManyResult';
  records?: Maybe<Array<Maybe<EqmServiceCard>>>;
  pageData?: Maybe<PageData>;
};

export type EqmServiceType = {
  __typename?: 'EqmServiceType';
  discriminator?: Maybe<Scalars['String']>;
  ServiceType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ParallelwithRental?: Maybe<Scalars['Boolean']>;
  NeedsWorkshopTime?: Maybe<Scalars['Boolean']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmServiceTypeConnection = {
  __typename?: 'EqmServiceTypeConnection';
  count: Scalars['Int'];
  edges: Array<EqmServiceTypeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmServiceTypeConnectionEdge = {
  __typename?: 'EqmServiceTypeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmServiceType;
};

export type EqmServiceTypeDeleteResponse = {
  __typename?: 'EqmServiceTypeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmServiceType>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmServiceTypeManyResult = {
  __typename?: 'EqmServiceTypeManyResult';
  records?: Maybe<Array<Maybe<EqmServiceType>>>;
  pageData?: Maybe<PageData>;
};

export type EqmTransferHeader = {
  __typename?: 'EqmTransferHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  TransferfromName?: Maybe<Scalars['String']>;
  TransferfromName2?: Maybe<Scalars['String']>;
  TransferfromAddress?: Maybe<Scalars['String']>;
  TransferfromAddress2?: Maybe<Scalars['String']>;
  TransferfromPostCode?: Maybe<Scalars['String']>;
  TransferfromCity?: Maybe<Scalars['String']>;
  TransferfromCounty?: Maybe<Scalars['String']>;
  TrsffromCountryRegionCode?: Maybe<Scalars['String']>;
  TransfertoCode?: Maybe<Scalars['String']>;
  TransfertoName?: Maybe<Scalars['String']>;
  TransfertoName2?: Maybe<Scalars['String']>;
  TransfertoAddress?: Maybe<Scalars['String']>;
  TransfertoAddress2?: Maybe<Scalars['String']>;
  TransfertoPostCode?: Maybe<Scalars['String']>;
  TransfertoCity?: Maybe<Scalars['String']>;
  TransfertoCounty?: Maybe<Scalars['String']>;
  TrsftoCountryRegionCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  InTransitCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  LastShipmentNo?: Maybe<Scalars['String']>;
  LastReceiptNo?: Maybe<Scalars['String']>;
  TransferfromContact?: Maybe<Scalars['String']>;
  TransfertoContact?: Maybe<Scalars['String']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  TransferOrderReference?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AssignedEmployeeNo?: Maybe<Scalars['String']>;
  EntryExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  DirectTransfer?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  RentalDispatchType?: Maybe<Scalars['Float']>;
  RentalDispatchTypeOPTION?: Maybe<Scalars['String']>;
  RentalDispatchOrderNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  LocationFilter?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  HasShippedLines?: Maybe<Scalars['Boolean']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  ReadyForPostShipment?: Maybe<Scalars['Boolean']>;
  ReadyForPostReceipt?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmTransferHeaderConnection = {
  __typename?: 'EqmTransferHeaderConnection';
  count: Scalars['Int'];
  edges: Array<EqmTransferHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmTransferHeaderConnectionEdge = {
  __typename?: 'EqmTransferHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmTransferHeader;
};

export type EqmTransferHeaderDeleteResponse = {
  __typename?: 'EqmTransferHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmTransferHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmTransferHeaderManyResult = {
  __typename?: 'EqmTransferHeaderManyResult';
  records?: Maybe<Array<Maybe<EqmTransferHeader>>>;
  pageData?: Maybe<PageData>;
};

export type EqmTransferLine = {
  __typename?: 'EqmTransferLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  QtytoReceive?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityReceived?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtytoReceiveBase?: Maybe<Scalars['Float']>;
  QtyReceivedBase?: Maybe<Scalars['Float']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  InTransitCode?: Maybe<Scalars['String']>;
  QtyinTransitBase?: Maybe<Scalars['Float']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  TransfertoCode?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  DerivedFromLineNo?: Maybe<Scalars['Float']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  ReservedQuantityInbnd?: Maybe<Scalars['Float']>;
  ReservedQuantityOutbnd?: Maybe<Scalars['Float']>;
  ReservedQtyInbndBase?: Maybe<Scalars['Float']>;
  ReservedQtyOutbndBase?: Maybe<Scalars['Float']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ReservedQuantityShipped?: Maybe<Scalars['Float']>;
  ReservedQtyShippedBase?: Maybe<Scalars['Float']>;
  DirectTransfer?: Maybe<Scalars['Boolean']>;
  ChgCurrLocationOnly?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  AllocateOwnershipStatus?: Maybe<Scalars['Float']>;
  AllocateOwnershipStatusOPTION?: Maybe<Scalars['String']>;
  AllocateCreateFA?: Maybe<Scalars['Boolean']>;
  AllocateSerialNo?: Maybe<Scalars['String']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  RentalDispatchType?: Maybe<Scalars['Float']>;
  RentalDispatchTypeOPTION?: Maybe<Scalars['String']>;
  RentalDispatchOrderNo?: Maybe<Scalars['String']>;
  RentalDispatchOrderLineNo?: Maybe<Scalars['Float']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  WhseInbndOtsdgQtyBase?: Maybe<Scalars['Float']>;
  WhseOutbndOtsdgQtyBase?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  TransferfromBinCode?: Maybe<Scalars['String']>;
  TransferToBinCode?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmTransferLineConnection = {
  __typename?: 'EqmTransferLineConnection';
  count: Scalars['Int'];
  edges: Array<EqmTransferLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmTransferLineConnectionEdge = {
  __typename?: 'EqmTransferLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmTransferLine;
};

export type EqmTransferLineDeleteResponse = {
  __typename?: 'EqmTransferLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmTransferLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmTransferLineManyResult = {
  __typename?: 'EqmTransferLineManyResult';
  records?: Maybe<Array<Maybe<EqmTransferLine>>>;
  pageData?: Maybe<PageData>;
};

export type EqmTypeFunctionalTest = {
  __typename?: 'EqmTypeFunctionalTest';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  TestIssue?: Maybe<Scalars['String']>;
  AdditionalInstructions?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  NAAllowed?: Maybe<Scalars['Boolean']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  NBAESHeadline?: Maybe<Scalars['Boolean']>;
  NBAESSkillReq?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmTypeFunctionalTestConnection = {
  __typename?: 'EqmTypeFunctionalTestConnection';
  count: Scalars['Int'];
  edges: Array<EqmTypeFunctionalTestConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmTypeFunctionalTestConnectionEdge = {
  __typename?: 'EqmTypeFunctionalTestConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmTypeFunctionalTest;
};

export type EqmTypeFunctionalTestDeleteResponse = {
  __typename?: 'EqmTypeFunctionalTestDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmTypeFunctionalTest>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmTypeFunctionalTestManyResult = {
  __typename?: 'EqmTypeFunctionalTestManyResult';
  records?: Maybe<Array<Maybe<EqmTypeFunctionalTest>>>;
  pageData?: Maybe<PageData>;
};

export type EqmWorkHeader = {
  __typename?: 'EqmWorkHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  FunctionalTestNo?: Maybe<Scalars['String']>;
  NoFailedTestIssues?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  InternalCustomerNo?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  EQMServiceCardNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectSerialNo?: Maybe<Scalars['String']>;
  ObjectCounter?: Maybe<Scalars['Float']>;
  ExternalWorkDescription?: Maybe<Scalars['String']>;
  InternalWorkDescription?: Maybe<Scalars['String']>;
  FromContractNo?: Maybe<Scalars['String']>;
  FromContractLineNo?: Maybe<Scalars['Float']>;
  FromCustomerNo?: Maybe<Scalars['String']>;
  FromCustomerProjectNo?: Maybe<Scalars['String']>;
  FromCollectionDocNo?: Maybe<Scalars['String']>;
  FromCollectionLineNo?: Maybe<Scalars['Float']>;
  FromPostedCollDocNo?: Maybe<Scalars['String']>;
  FromPostedCollLineNo?: Maybe<Scalars['Float']>;
  FromReturnDocumentNo?: Maybe<Scalars['String']>;
  FromCostCenter?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  AssignedEmployeeNo?: Maybe<Scalars['String']>;
  RepairCode?: Maybe<Scalars['String']>;
  NextCertification?: Maybe<Scalars['DateTime']>;
  NextService?: Maybe<Scalars['DateTime']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocType?: Maybe<Scalars['Float']>;
  ChargeDocTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocNo?: Maybe<Scalars['String']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenterCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CombineInvoices?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  PlannedStartDate?: Maybe<Scalars['DateTime']>;
  PlannedStartTime?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['String']>;
  CompletedDate?: Maybe<Scalars['DateTime']>;
  CompletedTime?: Maybe<Scalars['String']>;
  ApprovedbyUser?: Maybe<Scalars['String']>;
  AllowPartialInvoicing?: Maybe<Scalars['Boolean']>;
  ExternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  ExternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  InternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  InternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  Pictures?: Maybe<Scalars['Float']>;
  UnpostedWorkOrderLines?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmWorkHeaderConnection = {
  __typename?: 'EqmWorkHeaderConnection';
  count: Scalars['Int'];
  edges: Array<EqmWorkHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmWorkHeaderConnectionEdge = {
  __typename?: 'EqmWorkHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmWorkHeader;
};

export type EqmWorkHeaderDeleteResponse = {
  __typename?: 'EqmWorkHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmWorkHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmWorkHeaderManyResult = {
  __typename?: 'EqmWorkHeaderManyResult';
  records?: Maybe<Array<Maybe<EqmWorkHeader>>>;
  pageData?: Maybe<PageData>;
};

export type EqmWorkLine = {
  __typename?: 'EqmWorkLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LineStatus?: Maybe<Scalars['Float']>;
  LineStatusOPTION?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  OverruledUnitPrice?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ExternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  ExternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocType?: Maybe<Scalars['Float']>;
  ChargeDocTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocNo?: Maybe<Scalars['String']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  Transferred?: Maybe<Scalars['Boolean']>;
  TransferredtoDocNo?: Maybe<Scalars['String']>;
  TransferredtoDocLineNo?: Maybe<Scalars['Float']>;
  PostedSalesLine?: Maybe<Scalars['String']>;
  InternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  InternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EqmWorkLineConnection = {
  __typename?: 'EqmWorkLineConnection';
  count: Scalars['Int'];
  edges: Array<EqmWorkLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type EqmWorkLineConnectionEdge = {
  __typename?: 'EqmWorkLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EqmWorkLine;
};

export type EqmWorkLineDeleteResponse = {
  __typename?: 'EqmWorkLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<EqmWorkLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EqmWorkLineManyResult = {
  __typename?: 'EqmWorkLineManyResult';
  records?: Maybe<Array<Maybe<EqmWorkLine>>>;
  pageData?: Maybe<PageData>;
};

export type Errand = {
  __typename?: 'Errand';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<ErrandTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ErrandType?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Dim1?: Maybe<Scalars['String']>;
  Dim2?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Status?: Maybe<ErrandStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ActionBlob?: Maybe<Scalars['String']>;
  MainSource?: Maybe<Scalars['String']>;
  CreatedFromRecord?: Maybe<Scalars['String']>;
  TimeCost?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ErrandConnection = {
  __typename?: 'ErrandConnection';
  count: Scalars['Int'];
  edges: Array<ErrandConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ErrandConnectionEdge = {
  __typename?: 'ErrandConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Errand;
};

export type ErrandDeleteResponse = {
  __typename?: 'ErrandDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Errand>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ErrandLine = {
  __typename?: 'ErrandLine';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ConnectedToRecord?: Maybe<Scalars['String']>;
  Note?: Maybe<Scalars['String']>;
  Type?: Maybe<ErrandLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ErrandLineConnection = {
  __typename?: 'ErrandLineConnection';
  count: Scalars['Int'];
  edges: Array<ErrandLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ErrandLineConnectionEdge = {
  __typename?: 'ErrandLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ErrandLine;
};

export type ErrandLineDeleteResponse = {
  __typename?: 'ErrandLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ErrandLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ErrandLineManyResult = {
  __typename?: 'ErrandLineManyResult';
  records?: Maybe<Array<Maybe<ErrandLine>>>;
  pageData?: Maybe<PageData>;
};

export enum ErrandLineTypeEnum {
  Empty = 'Empty',
  Invoice = 'Invoice',
  Customer = 'Customer',
  SalesPerson = 'SalesPerson',
  Vendor = 'Vendor',
  Shipment = 'Shipment',
  Object = 'Object',
  ObjectType = 'ObjectType',
  CustomerProject = 'CustomerProject'
}

export type ErrandManyResult = {
  __typename?: 'ErrandManyResult';
  records?: Maybe<Array<Maybe<Errand>>>;
  pageData?: Maybe<PageData>;
};

export enum ErrandStatusEnum {
  Registered = 'Registered',
  Fixed = 'Fixed'
}

export enum ErrandTypeEnum {
  Empty = 'Empty',
  CustomerComplaint = 'CustomerComplaint',
  Accident = 'Accident',
  Incident = 'Incident',
  VendorComplaint = 'VendorComplaint',
  Other = 'Other',
  ObjectError = 'ObjectError'
}

export type ErrorReport = {
  __typename?: 'ErrorReport';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReasonText?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Caller?: Maybe<Scalars['String']>;
  CallerPhone?: Maybe<Scalars['String']>;
  CallerEmail?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ActionCode?: Maybe<Scalars['String']>;
  ActionText?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  TurnedOverTo?: Maybe<Scalars['String']>;
  Recived?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ErrorReportConnection = {
  __typename?: 'ErrorReportConnection';
  count: Scalars['Int'];
  edges: Array<ErrorReportConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ErrorReportConnectionEdge = {
  __typename?: 'ErrorReportConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ErrorReport;
};

export type ErrorReportDeleteResponse = {
  __typename?: 'ErrorReportDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ErrorReport>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ErrorReportManyResult = {
  __typename?: 'ErrorReportManyResult';
  records?: Maybe<Array<Maybe<ErrorReport>>>;
  pageData?: Maybe<PageData>;
};

export type Feedback = {
  __typename?: 'Feedback';
  discriminator?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  ByUserEmail?: Maybe<Scalars['String']>;
  ByUserName?: Maybe<Scalars['String']>;
  FileGlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  Processed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  count: Scalars['Int'];
  edges: Array<FeedbackConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FeedbackConnectionEdge = {
  __typename?: 'FeedbackConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Feedback;
};

export type FeedbackDeleteResponse = {
  __typename?: 'FeedbackDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Feedback>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FeedbackManyResult = {
  __typename?: 'FeedbackManyResult';
  records?: Maybe<Array<Maybe<Feedback>>>;
  pageData?: Maybe<PageData>;
};

export type Feemapping = {
  __typename?: 'Feemapping';
  discriminator?: Maybe<Scalars['String']>;
  LCYPrice?: Maybe<Scalars['Float']>;
  LCYPriceIncVat?: Maybe<Scalars['Float']>;
  EURPrice?: Maybe<Scalars['Float']>;
  Type?: Maybe<FeemappingTypeEnum>;
  Code?: Maybe<Scalars['String']>;
  Unit?: Maybe<Scalars['String']>;
  Fee?: Maybe<Scalars['String']>;
  PriceType?: Maybe<FeemappingPriceTypeEnum>;
  TypeOfFee?: Maybe<FeemappingTypeOfFeeEnum>;
  FeeItemNumber?: Maybe<Scalars['String']>;
  BaseQuantity?: Maybe<Scalars['Float']>;
  WebDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type FeemappingConnection = {
  __typename?: 'FeemappingConnection';
  count: Scalars['Int'];
  edges: Array<FeemappingConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FeemappingConnectionEdge = {
  __typename?: 'FeemappingConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Feemapping;
};

export type FeemappingDeleteResponse = {
  __typename?: 'FeemappingDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Feemapping>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FeemappingManyResult = {
  __typename?: 'FeemappingManyResult';
  records?: Maybe<Array<Maybe<Feemapping>>>;
  pageData?: Maybe<PageData>;
};

export enum FeemappingPriceTypeEnum {
  OneTime = 'OneTime',
  Quantity = 'Quantity',
  Weight = 'Weight'
}

export enum FeemappingTypeEnum {
  Empty = 'Empty',
  Item = 'Item',
  Resource = 'Resource'
}

export enum FeemappingTypeOfFeeEnum {
  Recycle = 'Recycle',
  Environment = 'Environment',
  PlasticTax = 'PlasticTax'
}

export type FieldOrder = {
  __typename?: 'FieldOrder';
  discriminator?: Maybe<Scalars['String']>;
  FieldOrderCode?: Maybe<Scalars['String']>;
  ExecutionTime?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  WorkDescription?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Status?: Maybe<FieldOrderStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  AssignmentType?: Maybe<FieldOrderAssignmentTypeEnum>;
  AssignmentTypeOPTION?: Maybe<Scalars['String']>;
  AssignedTo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostedInvoiceNo?: Maybe<Scalars['String']>;
  ReversedVAT?: Maybe<Scalars['Boolean']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactPhone?: Maybe<Scalars['String']>;
  Instructions?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  NoteFromPreformer?: Maybe<Scalars['String']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export enum FieldOrderAssignmentTypeEnum {
  Pool = 'Pool',
  Individual = 'Individual'
}

export type FieldOrderConnection = {
  __typename?: 'FieldOrderConnection';
  count: Scalars['Int'];
  edges: Array<FieldOrderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FieldOrderConnectionEdge = {
  __typename?: 'FieldOrderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: FieldOrder;
};

export type FieldOrderDeleteResponse = {
  __typename?: 'FieldOrderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<FieldOrder>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FieldOrderLine = {
  __typename?: 'FieldOrderLine';
  discriminator?: Maybe<Scalars['String']>;
  FieldOrderCode?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<FieldOrderLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type FieldOrderLineConnection = {
  __typename?: 'FieldOrderLineConnection';
  count: Scalars['Int'];
  edges: Array<FieldOrderLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FieldOrderLineConnectionEdge = {
  __typename?: 'FieldOrderLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: FieldOrderLine;
};

export type FieldOrderLineDeleteResponse = {
  __typename?: 'FieldOrderLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<FieldOrderLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FieldOrderLineManyResult = {
  __typename?: 'FieldOrderLineManyResult';
  records?: Maybe<Array<Maybe<FieldOrderLine>>>;
  pageData?: Maybe<PageData>;
};

export enum FieldOrderLineTypeEnum {
  Object = 'Object',
  Item = 'Item',
  Resource = 'Resource'
}

export type FieldOrderManyResult = {
  __typename?: 'FieldOrderManyResult';
  records?: Maybe<Array<Maybe<FieldOrder>>>;
  pageData?: Maybe<PageData>;
};

export enum FieldOrderStatusEnum {
  New = 'New',
  Ordered = 'Ordered',
  Ongoing = 'Ongoing',
  Done = 'Done',
  Canceled = 'Canceled',
  Closed = 'Closed'
}

export type File = {
  __typename?: 'File';
  discriminator?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  base64?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type FileConnection = {
  __typename?: 'FileConnection';
  count: Scalars['Int'];
  edges: Array<FileConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FileConnectionEdge = {
  __typename?: 'FileConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: File;
};

export type FileDeleteResponse = {
  __typename?: 'FileDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<File>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FileManyResult = {
  __typename?: 'FileManyResult';
  records?: Maybe<Array<Maybe<File>>>;
  pageData?: Maybe<PageData>;
};

export type FilterAccessRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  Type?: Maybe<AccessRequestTypeEnum>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  TargetName?: Maybe<Scalars['String']>;
  Status?: Maybe<AccessRequestStatusEnum>;
  Approved?: Maybe<Scalars['Boolean']>;
  ApprovedOn?: Maybe<Scalars['DateTime']>;
  RequestedOn?: Maybe<Scalars['DateTime']>;
  ApprovedByUserGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterAccessRequestInput>>;
  NOT?: Maybe<FilterAccessRequestInput>;
};

export type FilterAdditionalItemInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Type?: Maybe<AdditionalItemTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  AdditionalItemNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  QuantityOption?: Maybe<AdditionalItemQuantityOptionEnum>;
  QuantityOptionOPTION?: Maybe<Scalars['String']>;
  QuantityRatio?: Maybe<Scalars['Float']>;
  MinRequiredQty?: Maybe<Scalars['Float']>;
  UseZeroPrice?: Maybe<Scalars['Boolean']>;
  Active?: Maybe<Scalars['Boolean']>;
  Price?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterAdditionalItemInput>>;
  NOT?: Maybe<FilterAdditionalItemInput>;
};

export type FilterAddressInput = {
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PlaceofExport?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  CustomerGlobalId?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterAddressInput>>;
  NOT?: Maybe<FilterAddressInput>;
};

export type FilterAgreementInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  RecName?: Maybe<Scalars['String']>;
  RecId?: Maybe<Scalars['String']>;
  AgreementType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  SignatureType?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterAgreementInput>>;
  NOT?: Maybe<FilterAgreementInput>;
};

export type FilterAnnotationInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectTypeCode?: Maybe<Scalars['String']>;
  NoteText?: Maybe<Scalars['String']>;
  OwnerId?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  OwnerName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterAnnotationInput>>;
  NOT?: Maybe<FilterAnnotationInput>;
};

export type FilterApplicationModuleInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ShortName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ShortDescription?: Maybe<Scalars['String']>;
  DefaultWebLink?: Maybe<Scalars['String']>;
  DefaultAppNavStackName?: Maybe<Scalars['String']>;
  SearchOrder?: Maybe<Scalars['Float']>;
  NavigationOrder?: Maybe<Scalars['Float']>;
  Searchable?: Maybe<Scalars['Boolean']>;
  ShowOnWebHeader?: Maybe<Scalars['Boolean']>;
  ShowOnAppMainTab?: Maybe<Scalars['Boolean']>;
  VisibleForCustomer?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterApplicationModuleInput>>;
  NOT?: Maybe<FilterApplicationModuleInput>;
};

export type FilterBcMappingFieldInput = {
  discriminator?: Maybe<Scalars['String']>;
  NBAEDirection?: Maybe<Scalars['Float']>;
  NBAEDirectionOPTION?: Maybe<Scalars['String']>;
  NBAETableNo?: Maybe<Scalars['Float']>;
  NBAEEventType?: Maybe<Scalars['Float']>;
  NBAEEventTypeOPTION?: Maybe<Scalars['String']>;
  NBAESequenceNo?: Maybe<Scalars['Float']>;
  NBAELineNo?: Maybe<Scalars['Float']>;
  NBAEPKField?: Maybe<Scalars['Boolean']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  NBAESortingOrder?: Maybe<Scalars['Float']>;
  NBAEOutFromDataType?: Maybe<Scalars['Float']>;
  NBAEOutFromDataTypeOPTION?: Maybe<Scalars['String']>;
  NBAEOutFromDataSubType?: Maybe<Scalars['String']>;
  NBAEOutToJSONElement?: Maybe<Scalars['String']>;
  NBAEInFromDataType?: Maybe<Scalars['Float']>;
  NBAEInFromDataTypeOPTION?: Maybe<Scalars['String']>;
  NBAEInFromDataSubType?: Maybe<Scalars['String']>;
  NBAEInOperator?: Maybe<Scalars['Float']>;
  NBAEInOperatorOPTION?: Maybe<Scalars['String']>;
  NBAEInToFieldNo?: Maybe<Scalars['Float']>;
  NBAEInToFieldName?: Maybe<Scalars['String']>;
  NBAEInValidate?: Maybe<Scalars['Boolean']>;
  NBAEInTranslatesFromField?: Maybe<Scalars['Float']>;
  NBAELineFieldNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterBcMappingFieldInput>>;
  NOT?: Maybe<FilterBcMappingFieldInput>;
};

export type FilterBcMappingTableInput = {
  discriminator?: Maybe<Scalars['String']>;
  NBAEDirection?: Maybe<Scalars['Float']>;
  NBAEDirectionOPTION?: Maybe<Scalars['String']>;
  NBAETableNo?: Maybe<Scalars['Float']>;
  NBAEEventType?: Maybe<Scalars['Float']>;
  NBAEEventTypeOPTION?: Maybe<Scalars['String']>;
  NBAESequenceNo?: Maybe<Scalars['Float']>;
  NBAETableName?: Maybe<Scalars['String']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  NBAEEntityName?: Maybe<Scalars['String']>;
  NBAEDescription?: Maybe<Scalars['String']>;
  NBAEProcessingOrder?: Maybe<Scalars['Float']>;
  NBAEProcessOnReceipt?: Maybe<Scalars['Boolean']>;
  NBAEProcessAsTransactions?: Maybe<Scalars['Boolean']>;
  NBAEFields?: Maybe<Scalars['Float']>;
  NBAEFilters?: Maybe<Scalars['Float']>;
  NBAERelations?: Maybe<Scalars['Float']>;
  NBAEDelay?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterBcMappingTableInput>>;
  NOT?: Maybe<FilterBcMappingTableInput>;
};

export type FilterBcSettingsInput = {
  discriminator?: Maybe<Scalars['String']>;
  NBAECode?: Maybe<Scalars['String']>;
  NBAELineNo?: Maybe<Scalars['Float']>;
  NBAESettingName?: Maybe<Scalars['String']>;
  NBAESettingType?: Maybe<Scalars['Float']>;
  NBAESettingTypeOPTION?: Maybe<Scalars['String']>;
  NBAESettingValue?: Maybe<Scalars['String']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterBcSettingsInput>>;
  NOT?: Maybe<FilterBcSettingsInput>;
};

export type FilterBulkItemGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterBulkItemGroupInput>>;
  NOT?: Maybe<FilterBulkItemGroupInput>;
};

export type FilterCauseOfAbsenceInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  EmployeeNoFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  TotalAbsenceBase?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCauseOfAbsenceInput>>;
  NOT?: Maybe<FilterCauseOfAbsenceInput>;
};

export type FilterCompanyInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  PrimaryKey?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  DocumentSendingProfile?: Maybe<Scalars['String']>;
  OurAccountNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  ChainName?: Maybe<Scalars['String']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  CreditLimitLCY?: Maybe<Scalars['Float']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  GiroNo?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  PaymentRoutingNo?: Maybe<Scalars['String']>;
  CustomsPermitNo?: Maybe<Scalars['String']>;
  CustomsPermitDate?: Maybe<Scalars['DateTime']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  IndustrialClassification?: Maybe<Scalars['String']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICInboxType?: Maybe<Scalars['String']>;
  ICInboxDetails?: Maybe<Scalars['String']>;
  SystemIndicator?: Maybe<Scalars['String']>;
  CustomSystemIndicatorText?: Maybe<Scalars['String']>;
  SystemIndicatorStyle?: Maybe<Scalars['String']>;
  AllowBlankPaymentInfo?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CheckAvailPeriodCalc?: Maybe<Scalars['String']>;
  CheckAvailTimeBucket?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  CalConvergenceTimeFrame?: Maybe<Scalars['String']>;
  PlusGiroNo?: Maybe<Scalars['String']>;
  RegisteredOffice?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  UseExtraNavetPermissions?: Maybe<Scalars['Boolean']>;
  BlockObjectTypeModify?: Maybe<Scalars['Boolean']>;
  UseStandardPriceCalculation?: Maybe<Scalars['Boolean']>;
  UseStavdalResourcePrice?: Maybe<Scalars['Boolean']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  GLN?: Maybe<Scalars['String']>;
  ProductionDatabase?: Maybe<Scalars['String']>;
  MarkedAsTest?: Maybe<Scalars['Boolean']>;
  DontAskAgain?: Maybe<Scalars['Boolean']>;
  TestEmail?: Maybe<Scalars['String']>;
  BaseisInitiated?: Maybe<Scalars['Boolean']>;
  PlusGiroRefAccountNo?: Maybe<Scalars['String']>;
  CompanyImageEtag?: Maybe<Scalars['String']>;
  CompanyImageDocGlobalId?: Maybe<Scalars['String']>;
  CompanyImageURL?: Maybe<Scalars['String']>;
  Address_Visit?: Maybe<Scalars['String']>;
  Address2_Visit?: Maybe<Scalars['String']>;
  City_Visit?: Maybe<Scalars['String']>;
  PostCode_Visit?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ScriveField?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  ActiveInEdge?: Maybe<Scalars['Boolean']>;
  IsProspect?: Maybe<Scalars['Boolean']>;
  Analytics?: Maybe<Scalars['Boolean']>;
  MagentoGroupId?: Maybe<Scalars['Float']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  Fax?: Maybe<Scalars['String']>;
  IsWebCRM?: Maybe<Scalars['Boolean']>;
  MagentoId?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LatestInvoiceDate?: Maybe<Scalars['DateTime']>;
  RevenueThisYear?: Maybe<Scalars['Float']>;
  RevenueLastYear?: Maybe<Scalars['Float']>;
  RevenueYearBefLastYear?: Maybe<Scalars['Float']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  FinChargeTermsCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PlaceofExport?: Maybe<Scalars['String']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  CollectionMethod?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  InvoiceCopies?: Maybe<Scalars['Float']>;
  LastStatementNo?: Maybe<Scalars['Float']>;
  PrintStatements?: Maybe<Scalars['Boolean']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  Priority?: Maybe<Scalars['Float']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  NetChangeLCY?: Maybe<Scalars['Float']>;
  SalesLCY?: Maybe<Scalars['Float']>;
  ProfitLCY?: Maybe<Scalars['Float']>;
  InvDiscountsLCY?: Maybe<Scalars['Float']>;
  PmtDiscountsLCY?: Maybe<Scalars['Float']>;
  BalanceDue?: Maybe<Scalars['Float']>;
  BalanceDueLCY?: Maybe<Scalars['Float']>;
  Payments?: Maybe<Scalars['Float']>;
  InvoiceAmounts?: Maybe<Scalars['Float']>;
  CrMemoAmounts?: Maybe<Scalars['Float']>;
  FinanceChargeMemoAmounts?: Maybe<Scalars['Float']>;
  PaymentsLCY?: Maybe<Scalars['Float']>;
  InvAmountsLCY?: Maybe<Scalars['Float']>;
  CrMemoAmountsLCY?: Maybe<Scalars['Float']>;
  FinChargeMemoAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ApplicationMethod?: Maybe<Scalars['Float']>;
  ApplicationMethodOPTION?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  DontDebitPlasticTax?: Maybe<Scalars['Boolean']>;
  DontDebitEnvironmentFees?: Maybe<Scalars['Boolean']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  EnvironmentShipmentDays?: Maybe<Scalars['String']>;
  CustomerCategory?: Maybe<Scalars['String']>;
  CustomerClassification?: Maybe<Scalars['String']>;
  MagentoCustomer?: Maybe<Scalars['Boolean']>;
  Allwebitemsisvisible?: Maybe<Scalars['Boolean']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  SharkPrioritizeCode?: Maybe<Scalars['Float']>;
  SharkPrioritizeCodeOPTION?: Maybe<Scalars['String']>;
  SharkInvoiceValue?: Maybe<Scalars['Float']>;
  SharkFreightPrice?: Maybe<Scalars['Float']>;
  SharkTelephoneInstruction?: Maybe<Scalars['Boolean']>;
  NoBackOrder?: Maybe<Scalars['Boolean']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  OnlyActualFreightPrice?: Maybe<Scalars['Boolean']>;
  InvoiceDocumentType?: Maybe<Scalars['Float']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  PaymentMethodWeb?: Maybe<CompanyPaymentMethodWebEnum>;
  QBSId?: Maybe<Scalars['String']>;
  CRMClassification?: Maybe<Scalars['Float']>;
  PEBShiptoCode?: Maybe<Scalars['String']>;
  PEBDirectDebitPackType?: Maybe<Scalars['Float']>;
  PEBDirectDebitPackTypeOPTION?: Maybe<Scalars['String']>;
  PEBDirectDebitPaymentNo?: Maybe<Scalars['String']>;
  PEBDirectDebitApprovalStat?: Maybe<Scalars['Float']>;
  PEBDirectDebitApprovalStatOPTION?: Maybe<Scalars['String']>;
  PEBDDApprovalStatus?: Maybe<Scalars['Float']>;
  PEBDDApprovalStatusOPTION?: Maybe<Scalars['String']>;
  PEBRegistrationNo?: Maybe<Scalars['String']>;
  PEBDocumentCode?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMCustomerTemplate?: Maybe<Scalars['String']>;
  EQMCustRentalDiscGroup?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMEditableRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMRentalInsurance?: Maybe<Scalars['String']>;
  EQMCustRentalPriceGroup?: Maybe<Scalars['String']>;
  EQMHowInvoiceRentalContr?: Maybe<Scalars['Float']>;
  EQMHowInvoiceRentalContrOPTION?: Maybe<Scalars['String']>;
  EQMCustomerDeposit?: Maybe<Scalars['String']>;
  EQMCheckListCode?: Maybe<Scalars['String']>;
  EQMCustomerCategory?: Maybe<Scalars['Float']>;
  EQMCustomerCategoryOPTION?: Maybe<Scalars['String']>;
  EQMPopupNote?: Maybe<Scalars['String']>;
  EQMCombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  EQMCombineMethod?: Maybe<Scalars['Float']>;
  EQMCombineMethodOPTION?: Maybe<Scalars['String']>;
  EQMRentalInvDayofMonth?: Maybe<Scalars['Float']>;
  EQMCustomerClassification?: Maybe<Scalars['String']>;
  EQMManuallyBlocked?: Maybe<Scalars['Boolean']>;
  EQMOrganizationNo?: Maybe<Scalars['String']>;
  EQMRentalInvoiceSequence?: Maybe<Scalars['String']>;
  EQMEnvironmentalFeeCode?: Maybe<Scalars['String']>;
  EQMNotEditable?: Maybe<Scalars['Boolean']>;
  EQMPaidDeposits?: Maybe<Scalars['Float']>;
  EQMPaidDepositsLCY?: Maybe<Scalars['Float']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMInvoiceFee?: Maybe<Scalars['Float']>;
  EQMInvoiceFeeOPTION?: Maybe<Scalars['String']>;
  EQMPrepaymentLCY?: Maybe<Scalars['Float']>;
  EQMPrepaymentPeriod?: Maybe<Scalars['String']>;
  EQMMaxRentalValue?: Maybe<Scalars['Float']>;
  EQMMaxGrantedDeposit?: Maybe<Scalars['Float']>;
  EQMCatValidtoDate?: Maybe<Scalars['DateTime']>;
  EQMNoofRentalQuotes?: Maybe<Scalars['Float']>;
  EQMNoofOpenRntlContr?: Maybe<Scalars['Float']>;
  EQMNoofOpenRentalLines?: Maybe<Scalars['Float']>;
  EQMNoofRentalOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentalCreditMemos?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlShpt?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlInv?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlCrM?: Maybe<Scalars['Float']>;
  EQMNoofWorkshopQuotes?: Maybe<Scalars['Float']>;
  EQMNoofWorkshopOrders?: Maybe<Scalars['Float']>;
  EQMNoofWrkshpCrMemos?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpShpt?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpInv?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofRntlQte?: Maybe<Scalars['Float']>;
  EQMBillToNoofORContr?: Maybe<Scalars['Float']>;
  EQMBillToNoofRntlOrds?: Maybe<Scalars['Float']>;
  EQMBillToNoofRCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdRS?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdRI?: Maybe<Scalars['Float']>;
  EQMBillToNoofPRCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofWQte?: Maybe<Scalars['Float']>;
  EQMBillToNoofWrkshpO?: Maybe<Scalars['Float']>;
  EQMBillToNoofWCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdWS?: Maybe<Scalars['Float']>;
  EQMBillToNoofPWInv?: Maybe<Scalars['Float']>;
  EQMBillToNoofPWCrM?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRentalInsuranceOptions?: Maybe<Scalars['Float']>;
  EQMRentalInsuranceOptionsOPTION?: Maybe<Scalars['String']>;
  EQMPONoMandatory?: Maybe<Scalars['Boolean']>;
  EQMNoofRentPickOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentDeliverOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentCollOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdPickOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdDelOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdCollOrders?: Maybe<Scalars['Float']>;
  EQMStairPriceFilter?: Maybe<Scalars['String']>;
  EQMStairDiscountFilter?: Maybe<Scalars['String']>;
  HowtoInvoiceRentalContract?: Maybe<Scalars['Float']>;
  HowtoInvoiceRentalContractOPTION?: Maybe<Scalars['String']>;
  EQMShortTermRental?: Maybe<Scalars['Boolean']>;
  EQMCombineInvoiceSubProj?: Maybe<Scalars['Boolean']>;
  EQMTermofContract?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCompanyInput>>;
  NOT?: Maybe<FilterCompanyInput>;
};

export type FilterConsignmentHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  TableID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  ConsignmentNo?: Maybe<Scalars['String']>;
  ConsignmentType?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentTable?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  PickupName?: Maybe<Scalars['String']>;
  PickupAddress?: Maybe<Scalars['String']>;
  PickupAddress2?: Maybe<Scalars['String']>;
  PickupPostCode?: Maybe<Scalars['String']>;
  PickupCity?: Maybe<Scalars['String']>;
  PickupCounty?: Maybe<Scalars['String']>;
  PickupCountryRegionCode?: Maybe<Scalars['String']>;
  PickupContactNo?: Maybe<Scalars['String']>;
  PickupContactName?: Maybe<Scalars['String']>;
  PickupContactPhoneNo?: Maybe<Scalars['String']>;
  PickupContactMobPhoneNo?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoContactNo?: Maybe<Scalars['String']>;
  ShiptoContactName?: Maybe<Scalars['String']>;
  ShiptoContactPhoneNo?: Maybe<Scalars['String']>;
  ShiptoContactMobPhoneNo?: Maybe<Scalars['String']>;
  EQMRentalContractNo?: Maybe<Scalars['String']>;
  EQMProjectNo?: Maybe<Scalars['String']>;
  EQMPickOrderNo?: Maybe<Scalars['String']>;
  EQMShipOrderNo?: Maybe<Scalars['String']>;
  ReturnOrder?: Maybe<Scalars['Boolean']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  TransportRequestSent?: Maybe<Scalars['String']>;
  TransportRequestSentBy?: Maybe<Scalars['String']>;
  TransportComment?: Maybe<Scalars['String']>;
  ReadyToFetchDate?: Maybe<Scalars['DateTime']>;
  ReadyToFetchTime?: Maybe<Scalars['String']>;
  LatestAtRecieverDate?: Maybe<Scalars['DateTime']>;
  LatestAtRecieverTime?: Maybe<Scalars['String']>;
  AtReceiverOnTime?: Maybe<Scalars['Boolean']>;
  TotalEstimatedVolume?: Maybe<Scalars['Float']>;
  TransportConfirmed?: Maybe<Scalars['Boolean']>;
  TransportConfirmedBy?: Maybe<Scalars['String']>;
  TypeOfVehicle?: Maybe<Scalars['String']>;
  Freightisdebited?: Maybe<Scalars['Boolean']>;
  EQMCollectionOrderNo?: Maybe<Scalars['String']>;
  TotalGrossWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  TotalVolume?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterConsignmentHeaderInput>>;
  NOT?: Maybe<FilterConsignmentHeaderInput>;
};

export type FilterConsignmentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  ConsignmentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  OriginalTableID?: Maybe<Scalars['Float']>;
  OriginalUniqueID?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  TotalGrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  Volume?: Maybe<Scalars['Float']>;
  TotalVolume?: Maybe<Scalars['Float']>;
  TextBlob?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterConsignmentLineInput>>;
  NOT?: Maybe<FilterConsignmentLineInput>;
};

export type FilterContactInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CompanyNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  LookupContactNo?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  Surname?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Initials?: Maybe<Scalars['String']>;
  ExtensionNo?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  Pager?: Maybe<Scalars['String']>;
  OrganizationalLevelCode?: Maybe<Scalars['String']>;
  ExcludefromSegment?: Maybe<Scalars['Boolean']>;
  ExternalID?: Maybe<Scalars['String']>;
  CorrespondenceType?: Maybe<Scalars['String']>;
  SalutationCode?: Maybe<Scalars['String']>;
  SearchEMail?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  EMail2?: Maybe<Scalars['String']>;
  XrmId?: Maybe<Scalars['String']>;
  EQMEvaluationStatus?: Maybe<Scalars['String']>;
  EQMCheckListCode?: Maybe<Scalars['String']>;
  EQMCustomerCategory2?: Maybe<Scalars['String']>;
  EQMIdentificationNo?: Maybe<Scalars['String']>;
  EQMContactType?: Maybe<Scalars['String']>;
  EQMNotEditable?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  SalespersonFilter?: Maybe<Scalars['String']>;
  CampaignFilter?: Maybe<Scalars['String']>;
  ActionTakenFilter?: Maybe<Scalars['String']>;
  SalesCycleFilter?: Maybe<Scalars['String']>;
  SalesCycleStageFilter?: Maybe<Scalars['Float']>;
  ProbabilityFilter?: Maybe<Scalars['Float']>;
  CompletedFilter?: Maybe<Scalars['Float']>;
  EstimatedValueFilter?: Maybe<Scalars['Float']>;
  CalcdCurrentValueFilter?: Maybe<Scalars['Float']>;
  ChancesofSuccessFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilter?: Maybe<Scalars['String']>;
  TaskClosedFilter?: Maybe<Scalars['Boolean']>;
  PriorityFilter?: Maybe<Scalars['String']>;
  TeamFilter?: Maybe<Scalars['String']>;
  CloseOpportunityFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NextTaskDate?: Maybe<Scalars['DateTime']>;
  LastDateAttempted?: Maybe<Scalars['DateTime']>;
  DateofLastInteraction?: Maybe<Scalars['DateTime']>;
  NoofJobResponsibilities?: Maybe<Scalars['Float']>;
  NoofIndustryGroups?: Maybe<Scalars['Float']>;
  NoofBusinessRelations?: Maybe<Scalars['Float']>;
  NoofMailingGroups?: Maybe<Scalars['Float']>;
  NoofInteractions?: Maybe<Scalars['Float']>;
  CostLCY?: Maybe<Scalars['Float']>;
  DurationMin?: Maybe<Scalars['Float']>;
  NoofOpportunities?: Maybe<Scalars['Float']>;
  EstimatedValueLCY?: Maybe<Scalars['Float']>;
  CalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  OpportunityEntryExists?: Maybe<Scalars['Boolean']>;
  TaskEntryExists?: Maybe<Scalars['Boolean']>;
  LinkedToCustomerNo?: Maybe<Scalars['String']>;
  Lead?: Maybe<Scalars['Boolean']>;
  LinkedTo?: Maybe<Scalars['String']>;
  LastCustomerLedgerDate?: Maybe<Scalars['DateTime']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterContactInput>>;
  NOT?: Maybe<FilterContactInput>;
};

export type FilterCountryRegionInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ISOCode?: Maybe<Scalars['String']>;
  ISONumericCode?: Maybe<Scalars['String']>;
  EUCountryRegionCode?: Maybe<Scalars['String']>;
  IntrastatCode?: Maybe<Scalars['String']>;
  AddressFormat?: Maybe<Scalars['Float']>;
  AddressFormatOPTION?: Maybe<Scalars['String']>;
  ContactAddressFormat?: Maybe<Scalars['Float']>;
  ContactAddressFormatOPTION?: Maybe<Scalars['String']>;
  VATScheme?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CountyName?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCountryRegionInput>>;
  NOT?: Maybe<FilterCountryRegionInput>;
};

export type FilterCurrencyExchangeRateInput = {
  discriminator?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  ExchangeRateAmount?: Maybe<Scalars['Float']>;
  AdjustmentExchRateAmount?: Maybe<Scalars['Float']>;
  RelationalCurrencyCode?: Maybe<Scalars['String']>;
  RelationalExchRateAmount?: Maybe<Scalars['Float']>;
  FixExchRateAmount?: Maybe<Scalars['Float']>;
  FixExchRateAmountOPTION?: Maybe<Scalars['String']>;
  RelationalAdjmtExchRateAmt?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCurrencyExchangeRateInput>>;
  NOT?: Maybe<FilterCurrencyExchangeRateInput>;
};

export type FilterCurrencyInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastDateAdjusted?: Maybe<Scalars['DateTime']>;
  ISOCode?: Maybe<Scalars['String']>;
  ISONumericCode?: Maybe<Scalars['String']>;
  UnrealizedGainsAcc?: Maybe<Scalars['String']>;
  RealizedGainsAcc?: Maybe<Scalars['String']>;
  UnrealizedLossesAcc?: Maybe<Scalars['String']>;
  RealizedLossesAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingPrecision?: Maybe<Scalars['Float']>;
  InvoiceRoundingType?: Maybe<Scalars['Float']>;
  InvoiceRoundingTypeOPTION?: Maybe<Scalars['String']>;
  AmountRoundingPrecision?: Maybe<Scalars['Float']>;
  UnitAmountRoundingPrecision?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  AmountDecimalPlaces?: Maybe<Scalars['String']>;
  UnitAmountDecimalPlaces?: Maybe<Scalars['String']>;
  CustomerFilter?: Maybe<Scalars['String']>;
  VendorFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  CustLedgEntriesinFilter?: Maybe<Scalars['Boolean']>;
  CustomerBalance?: Maybe<Scalars['Float']>;
  CustomerOutstandingOrders?: Maybe<Scalars['Float']>;
  CustomerShippedNotInvoiced?: Maybe<Scalars['Float']>;
  CustomerBalanceDue?: Maybe<Scalars['Float']>;
  VendorLedgEntriesinFilter?: Maybe<Scalars['Boolean']>;
  VendorBalance?: Maybe<Scalars['Float']>;
  VendorOutstandingOrders?: Maybe<Scalars['Float']>;
  VendorAmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  VendorBalanceDue?: Maybe<Scalars['Float']>;
  CustomerBalanceLCY?: Maybe<Scalars['Float']>;
  VendorBalanceLCY?: Maybe<Scalars['Float']>;
  RealizedGLGainsAccount?: Maybe<Scalars['String']>;
  RealizedGLLossesAccount?: Maybe<Scalars['String']>;
  ApplnRoundingPrecision?: Maybe<Scalars['Float']>;
  EMUCurrency?: Maybe<Scalars['Boolean']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  ResidualGainsAccount?: Maybe<Scalars['String']>;
  ResidualLossesAccount?: Maybe<Scalars['String']>;
  ConvLCYRndgDebitAcc?: Maybe<Scalars['String']>;
  ConvLCYRndgCreditAcc?: Maybe<Scalars['String']>;
  MaxVATDifferenceAllowed?: Maybe<Scalars['Float']>;
  VATRoundingType?: Maybe<Scalars['Float']>;
  VATRoundingTypeOPTION?: Maybe<Scalars['String']>;
  PaymentTolerancePercent?: Maybe<Scalars['Float']>;
  MaxPaymentToleranceAmount?: Maybe<Scalars['Float']>;
  Symbol?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  PEBNoofForwardAgreement?: Maybe<Scalars['String']>;
  PEBCountryCodeISOAccNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCurrencyInput>>;
  NOT?: Maybe<FilterCurrencyInput>;
};

export type FilterCustomerDomainInput = {
  discriminator?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AdminUserGlobalId?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  DomainImageBase64?: Maybe<Scalars['String']>;
  DomainImageBase64Name?: Maybe<Scalars['String']>;
  DomainMainOrientation?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  VerifiedOn?: Maybe<Scalars['DateTime']>;
  VerifiedByUserEmail?: Maybe<Scalars['String']>;
  VerifiedByUserName?: Maybe<Scalars['String']>;
  TrialStartedOn?: Maybe<Scalars['DateTime']>;
  TrialEndsOn?: Maybe<Scalars['DateTime']>;
  TrialLengthDays?: Maybe<Scalars['Float']>;
  TrailActive?: Maybe<Scalars['Boolean']>;
  InvoiceAddress?: Maybe<Scalars['String']>;
  InvoicePostalCode?: Maybe<Scalars['String']>;
  InvoiceCity?: Maybe<Scalars['String']>;
  InvoiceNote?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCustomerDomainInput>>;
  NOT?: Maybe<FilterCustomerDomainInput>;
};

export type FilterCustomerPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ReceivablesAccount?: Maybe<Scalars['String']>;
  ServiceChargeAcc?: Maybe<Scalars['String']>;
  PaymentDiscDebitAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingAccount?: Maybe<Scalars['String']>;
  AdditionalFeeAccount?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  DebitCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  CreditCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  DebitRoundingAccount?: Maybe<Scalars['String']>;
  CreditRoundingAccount?: Maybe<Scalars['String']>;
  PaymentDiscCreditAcc?: Maybe<Scalars['String']>;
  PaymentToleranceDebitAcc?: Maybe<Scalars['String']>;
  PaymentToleranceCreditAcc?: Maybe<Scalars['String']>;
  AddFeeperLineAccount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ViewAllAccountsonLookup?: Maybe<Scalars['Boolean']>;
  EQMDepositReceiveablesAcc?: Maybe<Scalars['String']>;
  EQMDepositAccount?: Maybe<Scalars['String']>;
  EQMPrepaymentReceivableAcc?: Maybe<Scalars['String']>;
  EQMPrepaymentAccount?: Maybe<Scalars['String']>;
  EQMBankTransferReceiveables?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCustomerPostingGroupInput>>;
  NOT?: Maybe<FilterCustomerPostingGroupInput>;
};

export type FilterCustomerProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  StatusChangedBy?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  DimensionConstructProject?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  CombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['String']>;
  CombineInvoiceSubProject?: Maybe<Scalars['Boolean']>;
  SubProjectMandatory?: Maybe<Scalars['Boolean']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  CustomerRentPriceGroup?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  InsuranceCode?: Maybe<Scalars['String']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  Incentive?: Maybe<Scalars['Boolean']>;
  ReturnConfirmationperEMail?: Maybe<Scalars['Boolean']>;
  Inactive?: Maybe<Scalars['Boolean']>;
  ExpectedTurnover?: Maybe<Scalars['Float']>;
  ShiptoInstruction?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  LockPrices?: Maybe<Scalars['Boolean']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  EquipmentListperEMail?: Maybe<Scalars['String']>;
  EmailEquipmentList?: Maybe<Scalars['String']>;
  EmailFrequency?: Maybe<Scalars['String']>;
  InternalComment?: Maybe<Scalars['String']>;
  PopUp?: Maybe<Scalars['String']>;
  InactivateNoSaleMail?: Maybe<Scalars['Boolean']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactPhoneNo?: Maybe<Scalars['String']>;
  CustomerProjectRegistrationNo?: Maybe<Scalars['String']>;
  Deactivated?: Maybe<Scalars['Boolean']>;
  Finished?: Maybe<Scalars['Boolean']>;
  LocationManagerContact?: Maybe<Scalars['String']>;
  ProjectManager?: Maybe<Scalars['String']>;
  Deliverycontact?: Maybe<Scalars['String']>;
  ProjectManagerEmail?: Maybe<Scalars['String']>;
  DeliveryContactEmail?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  ExternallyCreated?: Maybe<Scalars['Boolean']>;
  RentIsActive?: Maybe<Scalars['Boolean']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  SendToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCustomerProjectInput>>;
  NOT?: Maybe<FilterCustomerProjectInput>;
};

export type FilterCustomerSubProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  CombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  InsuranceCode?: Maybe<Scalars['String']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterCustomerSubProjectInput>>;
  NOT?: Maybe<FilterCustomerSubProjectInput>;
};

export type FilterDeliverytimeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDeliverytimeInput>>;
  NOT?: Maybe<FilterDeliverytimeInput>;
};

export type FilterDevopsProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['String']>;
  lastUpdateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDevopsProjectInput>>;
  NOT?: Maybe<FilterDevopsProjectInput>;
};

export type FilterDevopsTeamInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDevopsTeamInput>>;
  NOT?: Maybe<FilterDevopsTeamInput>;
};

export type FilterDevopsWorkItemInput = {
  discriminator?: Maybe<Scalars['String']>;
  rev?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDevopsWorkItemInput>>;
  NOT?: Maybe<FilterDevopsWorkItemInput>;
};

export type FilterDimensionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CodeCaption?: Maybe<Scalars['String']>;
  FilterCaption?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  ConsolidationCode?: Maybe<Scalars['String']>;
  MaptoICDimensionCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDimensionInput>>;
  NOT?: Maybe<FilterDimensionInput>;
};

export type FilterDimensionValueInput = {
  discriminator?: Maybe<Scalars['String']>;
  DimensionCode?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DimensionValueType?: Maybe<Scalars['Float']>;
  DimensionValueTypeOPTION?: Maybe<Scalars['String']>;
  Totaling?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  ConsolidationCode?: Maybe<Scalars['String']>;
  Indentation?: Maybe<Scalars['Float']>;
  GlobalDimensionNo?: Maybe<Scalars['Float']>;
  MaptoICDimensionCode?: Maybe<Scalars['String']>;
  MaptoICDimensionValueCode?: Maybe<Scalars['String']>;
  DimensionValueID?: Maybe<Scalars['Float']>;
  EQMCostCenterDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectTypeDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectGroupDimension?: Maybe<Scalars['Boolean']>;
  EQMRelatedSalesCostDim?: Maybe<Scalars['Boolean']>;
  EQMRentalContractDimension?: Maybe<Scalars['Boolean']>;
  EQMCustomerDimension?: Maybe<Scalars['Boolean']>;
  EQMCustomerProjectDimension?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDimensionValueInput>>;
  NOT?: Maybe<FilterDimensionValueInput>;
};

export type FilterDiscussionPostInput = {
  discriminator?: Maybe<Scalars['String']>;
  FromEmail?: Maybe<Scalars['String']>;
  FromName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  Text?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  IsFromCustomer?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDiscussionPostInput>>;
  NOT?: Maybe<FilterDiscussionPostInput>;
};

export type FilterDocumentClassificationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDocumentClassificationInput>>;
  NOT?: Maybe<FilterDocumentClassificationInput>;
};

export type FilterDocumentInput = {
  discriminator?: Maybe<Scalars['String']>;
  SignInitiatingUserName?: Maybe<Scalars['String']>;
  SignInitiatingUserGlobalId?: Maybe<Scalars['String']>;
  SignRequestSentToUserName?: Maybe<Scalars['String']>;
  SignRequestSentToUserEmail?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RecName?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentSubType?: Maybe<Scalars['Float']>;
  DocumentSubTypeOPTION?: Maybe<Scalars['String']>;
  Signed?: Maybe<Scalars['Boolean']>;
  SignedByUserGlobalId?: Maybe<Scalars['String']>;
  SignedMethod?: Maybe<Scalars['String']>;
  SignedOn?: Maybe<Scalars['DateTime']>;
  OriginalFileName?: Maybe<Scalars['String']>;
  SignedFileName?: Maybe<Scalars['String']>;
  SignedPrintedName?: Maybe<Scalars['String']>;
  DocumentETag?: Maybe<Scalars['String']>;
  Base64WriteOnly?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  Created?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDocumentInput>>;
  NOT?: Maybe<FilterDocumentInput>;
};

export type FilterDocumentRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  RecName?: Maybe<Scalars['String']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentSubType?: Maybe<Scalars['Float']>;
  DocumentSubTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDocumentRequestInput>>;
  NOT?: Maybe<FilterDocumentRequestInput>;
};

export type FilterDomainInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ImgURL?: Maybe<Scalars['String']>;
  AppBackgroundImgURL?: Maybe<Scalars['String']>;
  AdminUserEmail?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  IsDemo?: Maybe<Scalars['Boolean']>;
  EnableObjectsLimited?: Maybe<Scalars['Boolean']>;
  EnableParkingSpotsLimited?: Maybe<Scalars['Boolean']>;
  ImageEtag?: Maybe<Scalars['String']>;
  ImageDocGlobalId?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  DomainImageBase64?: Maybe<Scalars['String']>;
  DomainImageBase64Name?: Maybe<Scalars['String']>;
  DomainMainOrientation?: Maybe<Scalars['String']>;
  WebhookURL?: Maybe<Scalars['String']>;
  ParentDomainId?: Maybe<Scalars['String']>;
  AlternativeName?: Maybe<Scalars['String']>;
  FlagImageUrl?: Maybe<Scalars['String']>;
  VisibleInSafetyRespect?: Maybe<Scalars['Boolean']>;
  ReservationsEnabled?: Maybe<Scalars['Boolean']>;
  ReturnReservationsEnabled?: Maybe<Scalars['Boolean']>;
  SendToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDomainInput>>;
  NOT?: Maybe<FilterDomainInput>;
};

export type FilterDomainIntegrationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  Username?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  AuthType?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  grant_type?: Maybe<Scalars['String']>;
  commonAuthority?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  ConnectionType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  SubType?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  MetaDataJson?: Maybe<Scalars['String']>;
  DisableOrderPolling?: Maybe<Scalars['Boolean']>;
  PreviousMagentoOrderId?: Maybe<Scalars['Float']>;
  PreviousDekraPollingDateTime?: Maybe<Scalars['String']>;
  SchemaVersion?: Maybe<DomainIntegrationSchemaVersionEnum>;
  Online?: Maybe<Scalars['Boolean']>;
  AutoSyncUnsentRecords?: Maybe<Scalars['Boolean']>;
  LatestHeartBeat?: Maybe<Scalars['DateTime']>;
  FirstHeartBeat?: Maybe<Scalars['DateTime']>;
  HeartBeartIntervalMinutes?: Maybe<Scalars['Float']>;
  NoOfReqsInOutboundQueue?: Maybe<Scalars['Float']>;
  NoOfReqsInInboundQueue?: Maybe<Scalars['Float']>;
  NoOfUnprocessableItems?: Maybe<Scalars['Float']>;
  HeartbeatMonitoringEnabled?: Maybe<Scalars['Boolean']>;
  OAuth2_URL?: Maybe<Scalars['String']>;
  OAuth2_Grant_Type?: Maybe<Scalars['String']>;
  OAuth2_Scope?: Maybe<Scalars['String']>;
  OAuth2_Client_Id?: Maybe<Scalars['String']>;
  OAuth2_Client_Secret?: Maybe<Scalars['String']>;
  OAuth2_Redirect_URL?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDomainIntegrationInput>>;
  NOT?: Maybe<FilterDomainIntegrationInput>;
};

export type FilterDomainSettingsInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterDomainSettingsInput>>;
  NOT?: Maybe<FilterDomainSettingsInput>;
};

export type FilterEqmBaseCalendarChangeInput = {
  discriminator?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  RecurringSystem?: Maybe<Scalars['Float']>;
  RecurringSystemOPTION?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Day?: Maybe<Scalars['Float']>;
  DayOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Nonworking?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmBaseCalendarChangeInput>>;
  NOT?: Maybe<FilterEqmBaseCalendarChangeInput>;
};

export type FilterEqmBaseCalendarInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CustomizedChangesExist?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmBaseCalendarInput>>;
  NOT?: Maybe<FilterEqmBaseCalendarInput>;
};

export type FilterEqmCollectionReturnChargesInput = {
  discriminator?: Maybe<Scalars['String']>;
  ExtDocumentNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  FunctionalTestHeaderNo?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAESMotherMarkedForReturn?: Maybe<Scalars['Boolean']>;
  NBAESObjectNo?: Maybe<Scalars['String']>;
  NBAESObjectDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmCollectionReturnChargesInput>>;
  NOT?: Maybe<FilterEqmCollectionReturnChargesInput>;
};

export type FilterEqmCommentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  TableLineNo?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  TableSubtype?: Maybe<Scalars['Float']>;
  TableSubtypeOPTION?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['Float']>;
  TableNameOPTION?: Maybe<Scalars['String']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmCommentLineInput>>;
  NOT?: Maybe<FilterEqmCommentLineInput>;
};

export type FilterEqmCustomizedCalendarChangeInput = {
  discriminator?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['Float']>;
  SourceTypeOPTION?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  AdditionalSourceCode?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  RecurringSystem?: Maybe<Scalars['Float']>;
  RecurringSystemOPTION?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Day?: Maybe<Scalars['Float']>;
  DayOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Nonworking?: Maybe<Scalars['Boolean']>;
  EntryNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmCustomizedCalendarChangeInput>>;
  NOT?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};

export type FilterEqmFncTestReturnChargesInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  TransferredToContract?: Maybe<Scalars['Boolean']>;
  TransferredBy?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmFncTestReturnChargesInput>>;
  NOT?: Maybe<FilterEqmFncTestReturnChargesInput>;
};

export type FilterEqmLocCostCenterCombInput = {
  discriminator?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  CostCenterCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmLocCostCenterCombInput>>;
  NOT?: Maybe<FilterEqmLocCostCenterCombInput>;
};

export type FilterEqmManufacturerInput = {
  discriminator?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmManufacturerInput>>;
  NOT?: Maybe<FilterEqmManufacturerInput>;
};

export type FilterEqmManufacturerModelInput = {
  discriminator?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ModelCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CounterReporting?: Maybe<Scalars['Boolean']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  ItemNo?: Maybe<Scalars['String']>;
  ItemDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Capacity?: Maybe<Scalars['Float']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  Effect?: Maybe<Scalars['Float']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectTypeDescription?: Maybe<Scalars['String']>;
  AttachmentNo?: Maybe<Scalars['String']>;
  AttachmentNoSeries?: Maybe<Scalars['String']>;
  NoofObjects?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  SRALink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmManufacturerModelInput>>;
  NOT?: Maybe<FilterEqmManufacturerModelInput>;
};

export type FilterEqmObjectCardFlowInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  DateFilter2?: Maybe<Scalars['DateTime']>;
  ServiceItemNo?: Maybe<Scalars['String']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowCostofSale?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowComponentCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowAppreciation?: Maybe<Scalars['Float']>;
  FlowWriteDown?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  Invoiceddays?: Maybe<Scalars['Float']>;
  FlowLinkedOtherCost?: Maybe<Scalars['Float']>;
  FlowLinkedLeasingCost?: Maybe<Scalars['Float']>;
  FlowLinkedRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedServiceCost?: Maybe<Scalars['Float']>;
  FlowLinkedGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedServiceAgrCost?: Maybe<Scalars['Float']>;
  FlowLinkedDepreciation?: Maybe<Scalars['Float']>;
  FlowLinkedUppreciation?: Maybe<Scalars['Float']>;
  FlowLinkedDownpreciation?: Maybe<Scalars['Float']>;
  FlowLinkedServiceIncome?: Maybe<Scalars['Float']>;
  FlowLinkedSrvAgrIncome?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedOtherIncome?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectCardFlowInput>>;
  NOT?: Maybe<FilterEqmObjectCardFlowInput>;
};

export type FilterEqmObjectComponentsInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  LevelofAspiration?: Maybe<Scalars['Float']>;
  LevelofAspirationOPTION?: Maybe<Scalars['String']>;
  PurchaseNo?: Maybe<Scalars['String']>;
  FromService?: Maybe<Scalars['Boolean']>;
  Ordered?: Maybe<Scalars['Boolean']>;
  FatherObjectSold?: Maybe<Scalars['Boolean']>;
  Typeold?: Maybe<Scalars['Float']>;
  TypeoldOPTION?: Maybe<Scalars['String']>;
  LineCost?: Maybe<Scalars['Float']>;
  Assembled?: Maybe<Scalars['Boolean']>;
  NonBillable?: Maybe<Scalars['Boolean']>;
  PostReclass?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectComponentsInput>>;
  NOT?: Maybe<FilterEqmObjectComponentsInput>;
};

export type FilterEqmObjectCountHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  DocumentStatus?: Maybe<Scalars['Float']>;
  DocumentStatusOPTION?: Maybe<Scalars['String']>;
  DateCounted?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectCountHeaderInput>>;
  NOT?: Maybe<FilterEqmObjectCountHeaderInput>;
};

export type FilterEqmObjectCountLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  CountNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectTypeNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DateCounted?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  DocumentStatus?: Maybe<Scalars['Float']>;
  DocumentStatusOPTION?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Selected?: Maybe<Scalars['Boolean']>;
  Corrected?: Maybe<Scalars['Boolean']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['Float']>;
  RentalStatusOPTION?: Maybe<Scalars['String']>;
  RentalContractNo?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  LineMessage?: Maybe<Scalars['String']>;
  CountedObjectNo?: Maybe<Scalars['String']>;
  MemoText?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectCountLineInput>>;
  NOT?: Maybe<FilterEqmObjectCountLineInput>;
};

export type FilterEqmObjectGroupCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  ReRentPostingGroup?: Maybe<Scalars['String']>;
  NoofObjects?: Maybe<Scalars['Float']>;
  NoofObjectType?: Maybe<Scalars['Float']>;
  ObjectNos?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['Float']>;
  DepreciationMethodOPTION?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['Float']>;
  StdRentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  FixedAssetSetupGroup?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupNoConcern?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  TaxDepreciationMethod?: Maybe<Scalars['Float']>;
  TaxDepreciationMethodOPTION?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectGroupCardInput>>;
  NOT?: Maybe<FilterEqmObjectGroupCardInput>;
};

export type FilterEqmObjectGroupFlowInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectGroupFlowInput>>;
  NOT?: Maybe<FilterEqmObjectGroupFlowInput>;
};

export type FilterEqmObjectServiceIntervalInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  DateCompleted?: Maybe<Scalars['DateTime']>;
  TransferedtoOrder?: Maybe<Scalars['String']>;
  TransferedtoWSOrder?: Maybe<Scalars['String']>;
  TransferedtoWSPurchase?: Maybe<Scalars['String']>;
  TransferedtoEQMService?: Maybe<Scalars['String']>;
  TransferredtoWorkOrder?: Maybe<Scalars['String']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  ServiceInitiator1?: Maybe<Scalars['Float']>;
  ServiceInitiator1OPTION?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  PostedDocumentType?: Maybe<Scalars['Float']>;
  PostedDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PostedDocumentNo?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  Certification?: Maybe<Scalars['Boolean']>;
  ObjectId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ManufacturerModelId?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  ObjectGroupId?: Maybe<Scalars['Float']>;
  ServiceCode?: Maybe<Scalars['String']>;
  MarginDate?: Maybe<Scalars['DateTime']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  ObjectRentalStatus?: Maybe<Scalars['Float']>;
  ObjectRentalStatusOPTION?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  DekraInspectionResult?: Maybe<Scalars['Float']>;
  DekraInspectionResultOPTION?: Maybe<Scalars['String']>;
  DekraInspection?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectServiceIntervalInput>>;
  NOT?: Maybe<FilterEqmObjectServiceIntervalInput>;
};

export type FilterEqmObjectSrvIntervalAggInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  DateCompleted?: Maybe<Scalars['DateTime']>;
  TransferedtoOrder?: Maybe<Scalars['String']>;
  TransferedtoWSOrder?: Maybe<Scalars['String']>;
  TransferedtoWSPurchase?: Maybe<Scalars['String']>;
  TransferedtoEQMService?: Maybe<Scalars['String']>;
  TransferredtoWorkOrder?: Maybe<Scalars['String']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  PostedDocumentType?: Maybe<Scalars['Float']>;
  PostedDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PostedDocumentNo?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  ObjectId?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  ManufacturerModelId?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  AssociatedServicePackId?: Maybe<Scalars['String']>;
  TransferredtoOrderId?: Maybe<Scalars['String']>;
  TransferredtoWSOrderId?: Maybe<Scalars['String']>;
  TransferredtoWSPurchId?: Maybe<Scalars['String']>;
  TransferredtoEQMServiceId?: Maybe<Scalars['String']>;
  TransferredtoWorkOrderId?: Maybe<Scalars['String']>;
  RepairId?: Maybe<Scalars['String']>;
  PostedDocumentId?: Maybe<Scalars['String']>;
  ControlTypeId?: Maybe<Scalars['String']>;
  ServiceTypeId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectSrvIntervalAggInput>>;
  NOT?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};

export type FilterEqmObjectStatusInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Descripton?: Maybe<Scalars['String']>;
  Answer?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectStatusInput>>;
  NOT?: Maybe<FilterEqmObjectStatusInput>;
};

export type FilterEqmObjectTypeCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  CounterType?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  SaleDiscGroup?: Maybe<Scalars['String']>;
  RentalDiscGroup?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SalesPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  VATProdPostingGrpRental?: Maybe<Scalars['String']>;
  ReRentPostingGroup?: Maybe<Scalars['String']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['String']>;
  WebPage?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  DefaultFunctionalTest?: Maybe<Scalars['Boolean']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAConnectedToHierarchy?: Maybe<Scalars['String']>;
  NBAMappingNo?: Maybe<Scalars['String']>;
  NBAMappingDescription?: Maybe<Scalars['String']>;
  NBARamirentGroupCode?: Maybe<Scalars['String']>;
  NBAESInspectionInterval?: Maybe<Scalars['String']>;
  NBAESManufacturerModel?: Maybe<Scalars['String']>;
  NBAESBonusGroup?: Maybe<Scalars['String']>;
  NBAESExcludeFromVaction?: Maybe<Scalars['Boolean']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  FixedCostPrice?: Maybe<Scalars['Float']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RetentionLevel?: Maybe<Scalars['Float']>;
  TaxDepreciationMethod?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  CounterType2?: Maybe<Scalars['String']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  InventoryObject?: Maybe<Scalars['Float']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  FixedAssetSetupGroup?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  RentalReservationsQty?: Maybe<Scalars['Float']>;
  RentalDeliveriesQty?: Maybe<Scalars['Float']>;
  ExpectedRentalReturnsQty?: Maybe<Scalars['Float']>;
  RentalReturnsQty?: Maybe<Scalars['Float']>;
  PictureURL?: Maybe<Scalars['String']>;
  PSIDocumentURL?: Maybe<Scalars['String']>;
  NotAvailableForReservation?: Maybe<Scalars['Boolean']>;
  ExpirationDateMandatory?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectTypeCardInput>>;
  NOT?: Maybe<FilterEqmObjectTypeCardInput>;
};

export type FilterEqmObjectTypeComponentsInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  LevelofAspiration?: Maybe<Scalars['Float']>;
  LevelofAspirationOPTION?: Maybe<Scalars['String']>;
  EntryType?: Maybe<Scalars['Float']>;
  EntryTypeOPTION?: Maybe<Scalars['String']>;
  NonBillable?: Maybe<Scalars['Boolean']>;
  InsertAutomatically?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectTypeComponentsInput>>;
  NOT?: Maybe<FilterEqmObjectTypeComponentsInput>;
};

export type FilterEqmObjectTypeFlowInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectTypeFlowInput>>;
  NOT?: Maybe<FilterEqmObjectTypeFlowInput>;
};

export type FilterEqmObjectTypePriceTermInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PercentofMonthlyPrice?: Maybe<Scalars['Float']>;
  FixedTermPrice?: Maybe<Scalars['Float']>;
  Note?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectTypePriceTermInput>>;
  NOT?: Maybe<FilterEqmObjectTypePriceTermInput>;
};

export type FilterEqmObjectTypeServiceIntervalInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  Certification?: Maybe<Scalars['Boolean']>;
  ServiceCode?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  DekraInspection?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmObjectTypeServiceIntervalInput>>;
  NOT?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};

export type FilterEqmReRentCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['Float']>;
  RentalStatusOPTION?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  PurchaseDocument?: Maybe<Scalars['Float']>;
  PostedPurchaseInvoice?: Maybe<Scalars['Float']>;
  PostedPurchaseCrMemo?: Maybe<Scalars['Float']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  RentalContractOpen?: Maybe<Scalars['Float']>;
  RentalContractAll?: Maybe<Scalars['Float']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  VendorName?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ReceivedDate?: Maybe<Scalars['DateTime']>;
  ReturnedDate?: Maybe<Scalars['DateTime']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  ManufacturerModelId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmReRentCardInput>>;
  NOT?: Maybe<FilterEqmReRentCardInput>;
};

export type FilterEqmRentalGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmRentalGroupInput>>;
  NOT?: Maybe<FilterEqmRentalGroupInput>;
};

export type FilterEqmRentalKitComponentsInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  KITNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ChildItem?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmRentalKitComponentsInput>>;
  NOT?: Maybe<FilterEqmRentalKitComponentsInput>;
};

export type FilterEqmRentalKitInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  KitType?: Maybe<Scalars['Float']>;
  KitTypeOPTION?: Maybe<Scalars['String']>;
  KITPrice?: Maybe<Scalars['Float']>;
  PriceTerm?: Maybe<Scalars['String']>;
  ShowinContract?: Maybe<Scalars['Boolean']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  SpecifyKitComponents?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  DebitforReturnDate?: Maybe<Scalars['Boolean']>;
  IgnoreKITLineinDispatch?: Maybe<Scalars['Boolean']>;
  SuspendUpdateComponents?: Maybe<Scalars['Boolean']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  OnAccountBilling?: Maybe<Scalars['Boolean']>;
  UnitofMeasureId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAESBonusGroup?: Maybe<Scalars['String']>;
  UseforReporting?: Maybe<Scalars['Boolean']>;
  DefaultKIT?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RentalItemDiscGroup?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmRentalKitInput>>;
  NOT?: Maybe<FilterEqmRentalKitInput>;
};

export type FilterEqmRentalKitLinesInput = {
  discriminator?: Maybe<Scalars['String']>;
  KITNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ChildNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmRentalKitLinesInput>>;
  NOT?: Maybe<FilterEqmRentalKitLinesInput>;
};

export type FilterEqmRentalLineDiscountInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  SalesType?: Maybe<Scalars['Float']>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  UsageType?: Maybe<Scalars['Float']>;
  UsageTypeOPTION?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  NBAESDescription?: Maybe<Scalars['String']>;
  DiscountType?: Maybe<Scalars['Float']>;
  DiscountTypeOPTION?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StepwiseDiscount?: Maybe<Scalars['Float']>;
  UseStepwiseDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  FrameAgreementNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmRentalLineDiscountInput>>;
  NOT?: Maybe<FilterEqmRentalLineDiscountInput>;
};

export type FilterEqmRentalPriceInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  SalesType?: Maybe<Scalars['Float']>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  RentalType?: Maybe<Scalars['Float']>;
  RentalTypeOPTION?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  StairCode?: Maybe<Scalars['String']>;
  ReturnPriceDisc?: Maybe<Scalars['Float']>;
  ReturnStairCode?: Maybe<Scalars['String']>;
  UsageType?: Maybe<Scalars['Float']>;
  UsageTypeOPTION?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  NBAESDescription?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  ofSalesPrice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  MinRentalPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MultipleRentalPrices?: Maybe<Scalars['Boolean']>;
  FrameAgreementNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmRentalPriceInput>>;
  NOT?: Maybe<FilterEqmRentalPriceInput>;
};

export type FilterEqmReturnChargesInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkType?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmReturnChargesInput>>;
  NOT?: Maybe<FilterEqmReturnChargesInput>;
};

export type FilterEqmServiceCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ObjectSerialNo?: Maybe<Scalars['String']>;
  FunctionalTestsFlow?: Maybe<Scalars['Float']>;
  WorkshopOrdersFlow?: Maybe<Scalars['Float']>;
  WorkshopPurchasesFlow?: Maybe<Scalars['Float']>;
  WorkshopPurchLinesFlow?: Maybe<Scalars['Float']>;
  PlannedStartDate?: Maybe<Scalars['DateTime']>;
  PlannedStartTime?: Maybe<Scalars['String']>;
  PlannedEndDate?: Maybe<Scalars['DateTime']>;
  PlannedEndTime?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['String']>;
  PostedFunctionalTestsFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopOrdersFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopPurchFlow?: Maybe<Scalars['Float']>;
  AvoidCalendarEntry?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ReturnDocumentNo?: Maybe<Scalars['String']>;
  ContractLineNo?: Maybe<Scalars['Float']>;
  CollectionDocumentNo?: Maybe<Scalars['String']>;
  RepairCode?: Maybe<Scalars['String']>;
  MainResource?: Maybe<Scalars['String']>;
  MainCustomer?: Maybe<Scalars['String']>;
  MainVendor?: Maybe<Scalars['String']>;
  Manufacturer?: Maybe<Scalars['String']>;
  ManufacturerModel?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  InternalCustomerNo?: Maybe<Scalars['String']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PurchaseDocumentType?: Maybe<Scalars['Float']>;
  PurchaseDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PurchaseDocumentNo?: Maybe<Scalars['String']>;
  PurchaseLineNo?: Maybe<Scalars['Float']>;
  NoSeries?: Maybe<Scalars['String']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenterCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  FinishedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  CreatedTime?: Maybe<Scalars['String']>;
  StartedBy?: Maybe<Scalars['String']>;
  NoofServiceOrders?: Maybe<Scalars['Float']>;
  NoofPostedServiceOrders?: Maybe<Scalars['Float']>;
  ControlTestsFlow?: Maybe<Scalars['Float']>;
  PostedControlTestFlow?: Maybe<Scalars['Float']>;
  FunctionalTestPictures?: Maybe<Scalars['Float']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  WorkOrderFlow?: Maybe<Scalars['Float']>;
  NoofReturnCharges?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmServiceCardInput>>;
  NOT?: Maybe<FilterEqmServiceCardInput>;
};

export type FilterEqmServiceTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  ServiceType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ParallelwithRental?: Maybe<Scalars['Boolean']>;
  NeedsWorkshopTime?: Maybe<Scalars['Boolean']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmServiceTypeInput>>;
  NOT?: Maybe<FilterEqmServiceTypeInput>;
};

export type FilterEqmTransferHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  TransferfromName?: Maybe<Scalars['String']>;
  TransferfromName2?: Maybe<Scalars['String']>;
  TransferfromAddress?: Maybe<Scalars['String']>;
  TransferfromAddress2?: Maybe<Scalars['String']>;
  TransferfromPostCode?: Maybe<Scalars['String']>;
  TransferfromCity?: Maybe<Scalars['String']>;
  TransferfromCounty?: Maybe<Scalars['String']>;
  TrsffromCountryRegionCode?: Maybe<Scalars['String']>;
  TransfertoCode?: Maybe<Scalars['String']>;
  TransfertoName?: Maybe<Scalars['String']>;
  TransfertoName2?: Maybe<Scalars['String']>;
  TransfertoAddress?: Maybe<Scalars['String']>;
  TransfertoAddress2?: Maybe<Scalars['String']>;
  TransfertoPostCode?: Maybe<Scalars['String']>;
  TransfertoCity?: Maybe<Scalars['String']>;
  TransfertoCounty?: Maybe<Scalars['String']>;
  TrsftoCountryRegionCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  InTransitCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  LastShipmentNo?: Maybe<Scalars['String']>;
  LastReceiptNo?: Maybe<Scalars['String']>;
  TransferfromContact?: Maybe<Scalars['String']>;
  TransfertoContact?: Maybe<Scalars['String']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  TransferOrderReference?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AssignedEmployeeNo?: Maybe<Scalars['String']>;
  EntryExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  DirectTransfer?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  RentalDispatchType?: Maybe<Scalars['Float']>;
  RentalDispatchTypeOPTION?: Maybe<Scalars['String']>;
  RentalDispatchOrderNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  LocationFilter?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  HasShippedLines?: Maybe<Scalars['Boolean']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  ReadyForPostShipment?: Maybe<Scalars['Boolean']>;
  ReadyForPostReceipt?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmTransferHeaderInput>>;
  NOT?: Maybe<FilterEqmTransferHeaderInput>;
};

export type FilterEqmTransferLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  QtytoReceive?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityReceived?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtytoReceiveBase?: Maybe<Scalars['Float']>;
  QtyReceivedBase?: Maybe<Scalars['Float']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  InTransitCode?: Maybe<Scalars['String']>;
  QtyinTransitBase?: Maybe<Scalars['Float']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  TransfertoCode?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  DerivedFromLineNo?: Maybe<Scalars['Float']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  ReservedQuantityInbnd?: Maybe<Scalars['Float']>;
  ReservedQuantityOutbnd?: Maybe<Scalars['Float']>;
  ReservedQtyInbndBase?: Maybe<Scalars['Float']>;
  ReservedQtyOutbndBase?: Maybe<Scalars['Float']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ReservedQuantityShipped?: Maybe<Scalars['Float']>;
  ReservedQtyShippedBase?: Maybe<Scalars['Float']>;
  DirectTransfer?: Maybe<Scalars['Boolean']>;
  ChgCurrLocationOnly?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  AllocateOwnershipStatus?: Maybe<Scalars['Float']>;
  AllocateOwnershipStatusOPTION?: Maybe<Scalars['String']>;
  AllocateCreateFA?: Maybe<Scalars['Boolean']>;
  AllocateSerialNo?: Maybe<Scalars['String']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  RentalDispatchType?: Maybe<Scalars['Float']>;
  RentalDispatchTypeOPTION?: Maybe<Scalars['String']>;
  RentalDispatchOrderNo?: Maybe<Scalars['String']>;
  RentalDispatchOrderLineNo?: Maybe<Scalars['Float']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  WhseInbndOtsdgQtyBase?: Maybe<Scalars['Float']>;
  WhseOutbndOtsdgQtyBase?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  TransferfromBinCode?: Maybe<Scalars['String']>;
  TransferToBinCode?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmTransferLineInput>>;
  NOT?: Maybe<FilterEqmTransferLineInput>;
};

export type FilterEqmTypeFunctionalTestInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  TestIssue?: Maybe<Scalars['String']>;
  AdditionalInstructions?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  NAAllowed?: Maybe<Scalars['Boolean']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  NBAESHeadline?: Maybe<Scalars['Boolean']>;
  NBAESSkillReq?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmTypeFunctionalTestInput>>;
  NOT?: Maybe<FilterEqmTypeFunctionalTestInput>;
};

export type FilterEqmWorkHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  FunctionalTestNo?: Maybe<Scalars['String']>;
  NoFailedTestIssues?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  InternalCustomerNo?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  EQMServiceCardNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectSerialNo?: Maybe<Scalars['String']>;
  ObjectCounter?: Maybe<Scalars['Float']>;
  ExternalWorkDescription?: Maybe<Scalars['String']>;
  InternalWorkDescription?: Maybe<Scalars['String']>;
  FromContractNo?: Maybe<Scalars['String']>;
  FromContractLineNo?: Maybe<Scalars['Float']>;
  FromCustomerNo?: Maybe<Scalars['String']>;
  FromCustomerProjectNo?: Maybe<Scalars['String']>;
  FromCollectionDocNo?: Maybe<Scalars['String']>;
  FromCollectionLineNo?: Maybe<Scalars['Float']>;
  FromPostedCollDocNo?: Maybe<Scalars['String']>;
  FromPostedCollLineNo?: Maybe<Scalars['Float']>;
  FromReturnDocumentNo?: Maybe<Scalars['String']>;
  FromCostCenter?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  AssignedEmployeeNo?: Maybe<Scalars['String']>;
  RepairCode?: Maybe<Scalars['String']>;
  NextCertification?: Maybe<Scalars['DateTime']>;
  NextService?: Maybe<Scalars['DateTime']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocType?: Maybe<Scalars['Float']>;
  ChargeDocTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocNo?: Maybe<Scalars['String']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenterCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CombineInvoices?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  PlannedStartDate?: Maybe<Scalars['DateTime']>;
  PlannedStartTime?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['String']>;
  CompletedDate?: Maybe<Scalars['DateTime']>;
  CompletedTime?: Maybe<Scalars['String']>;
  ApprovedbyUser?: Maybe<Scalars['String']>;
  AllowPartialInvoicing?: Maybe<Scalars['Boolean']>;
  ExternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  ExternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  InternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  InternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  Pictures?: Maybe<Scalars['Float']>;
  UnpostedWorkOrderLines?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmWorkHeaderInput>>;
  NOT?: Maybe<FilterEqmWorkHeaderInput>;
};

export type FilterEqmWorkLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LineStatus?: Maybe<Scalars['Float']>;
  LineStatusOPTION?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  OverruledUnitPrice?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ExternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  ExternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocType?: Maybe<Scalars['Float']>;
  ChargeDocTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocNo?: Maybe<Scalars['String']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  Transferred?: Maybe<Scalars['Boolean']>;
  TransferredtoDocNo?: Maybe<Scalars['String']>;
  TransferredtoDocLineNo?: Maybe<Scalars['Float']>;
  PostedSalesLine?: Maybe<Scalars['String']>;
  InternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  InternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterEqmWorkLineInput>>;
  NOT?: Maybe<FilterEqmWorkLineInput>;
};

export type FilterErrandInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<ErrandTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ErrandType?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Dim1?: Maybe<Scalars['String']>;
  Dim2?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Status?: Maybe<ErrandStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ActionBlob?: Maybe<Scalars['String']>;
  MainSource?: Maybe<Scalars['String']>;
  CreatedFromRecord?: Maybe<Scalars['String']>;
  TimeCost?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterErrandInput>>;
  NOT?: Maybe<FilterErrandInput>;
};

export type FilterErrandLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ConnectedToRecord?: Maybe<Scalars['String']>;
  Note?: Maybe<Scalars['String']>;
  Type?: Maybe<ErrandLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterErrandLineInput>>;
  NOT?: Maybe<FilterErrandLineInput>;
};

export type FilterErrorReportInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReasonText?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Caller?: Maybe<Scalars['String']>;
  CallerPhone?: Maybe<Scalars['String']>;
  CallerEmail?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ActionCode?: Maybe<Scalars['String']>;
  ActionText?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  TurnedOverTo?: Maybe<Scalars['String']>;
  Recived?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterErrorReportInput>>;
  NOT?: Maybe<FilterErrorReportInput>;
};

export type FilterFeedbackInput = {
  discriminator?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  ByUserEmail?: Maybe<Scalars['String']>;
  ByUserName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  Processed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFeedbackInput>>;
  NOT?: Maybe<FilterFeedbackInput>;
};

export type FilterFeemappingInput = {
  discriminator?: Maybe<Scalars['String']>;
  LCYPrice?: Maybe<Scalars['Float']>;
  LCYPriceIncVat?: Maybe<Scalars['Float']>;
  EURPrice?: Maybe<Scalars['Float']>;
  Type?: Maybe<FeemappingTypeEnum>;
  Code?: Maybe<Scalars['String']>;
  Unit?: Maybe<Scalars['String']>;
  Fee?: Maybe<Scalars['String']>;
  PriceType?: Maybe<FeemappingPriceTypeEnum>;
  TypeOfFee?: Maybe<FeemappingTypeOfFeeEnum>;
  FeeItemNumber?: Maybe<Scalars['String']>;
  BaseQuantity?: Maybe<Scalars['Float']>;
  WebDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFeemappingInput>>;
  NOT?: Maybe<FilterFeemappingInput>;
};

export type FilterFieldOrderInput = {
  discriminator?: Maybe<Scalars['String']>;
  FieldOrderCode?: Maybe<Scalars['String']>;
  ExecutionTime?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  WorkDescription?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Status?: Maybe<FieldOrderStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  AssignmentType?: Maybe<FieldOrderAssignmentTypeEnum>;
  AssignmentTypeOPTION?: Maybe<Scalars['String']>;
  AssignedTo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostedInvoiceNo?: Maybe<Scalars['String']>;
  ReversedVAT?: Maybe<Scalars['Boolean']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactPhone?: Maybe<Scalars['String']>;
  Instructions?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  NoteFromPreformer?: Maybe<Scalars['String']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFieldOrderInput>>;
  NOT?: Maybe<FilterFieldOrderInput>;
};

export type FilterFieldOrderLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  FieldOrderCode?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<FieldOrderLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFieldOrderLineInput>>;
  NOT?: Maybe<FilterFieldOrderLineInput>;
};

export type FilterFileInput = {
  discriminator?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  base64?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFileInput>>;
  NOT?: Maybe<FilterFileInput>;
};

export type FilterFunctionTestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FinishDate?: Maybe<Scalars['String']>;
  Status?: Maybe<FunctionTestHeaderStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<FunctionTestHeaderApprovalStatusEnum>;
  ApprovalStatusOPTION?: Maybe<Scalars['String']>;
  EQMServiceHeader?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  FromContractNo?: Maybe<Scalars['String']>;
  FromCustomerNo?: Maybe<Scalars['String']>;
  FromReturnDocumentNo?: Maybe<Scalars['String']>;
  FromCollectionDocNo?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  DimCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  FinishTime?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  FinishedBy?: Maybe<Scalars['String']>;
  TypeOfWash?: Maybe<Scalars['String']>;
  ExternalPart?: Maybe<Scalars['Boolean']>;
  FncFailed?: Maybe<Scalars['Boolean']>;
  HourMeterIndicator?: Maybe<Scalars['Float']>;
  Note?: Maybe<Scalars['String']>;
  FromCustomerName?: Maybe<Scalars['String']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  WarrantyDate?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  SignatureFromEdge?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFunctionTestHeaderInput>>;
  NOT?: Maybe<FilterFunctionTestHeaderInput>;
};

export type FilterFunctionTestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  ObjectNo?: Maybe<Scalars['String']>;
  TestIssue?: Maybe<Scalars['String']>;
  CurrentStatus?: Maybe<Scalars['String']>;
  AdditionalInstructions?: Maybe<Scalars['String']>;
  SpecificMeasure?: Maybe<Scalars['Boolean']>;
  DirectMeasure?: Maybe<Scalars['Boolean']>;
  InspectionTimeInterval?: Maybe<Scalars['Boolean']>;
  ToCorrect?: Maybe<Scalars['String']>;
  WorkShopOrderNo?: Maybe<Scalars['String']>;
  WorkShopOrderFinished?: Maybe<Scalars['Boolean']>;
  Headline?: Maybe<Scalars['Boolean']>;
  NoteFunctionTest?: Maybe<Scalars['String']>;
  NoteDate?: Maybe<Scalars['String']>;
  NoteOwner?: Maybe<Scalars['String']>;
  MoveNote?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  Mandatory?: Maybe<Scalars['Boolean']>;
  TimeEstimation?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  TestedApproved?: Maybe<Scalars['Boolean']>;
  TestedExecuted?: Maybe<Scalars['Boolean']>;
  ExecutedBy?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterFunctionTestLineInput>>;
  NOT?: Maybe<FilterFunctionTestLineInput>;
};

export type FilterGenBusinessPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefVATBusPostingGroup?: Maybe<Scalars['String']>;
  AutoInsertDefault?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterGenBusinessPostingGroupInput>>;
  NOT?: Maybe<FilterGenBusinessPostingGroupInput>;
};

export type FilterGenJournalLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  JournalTemplateName?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  AccountType?: Maybe<Scalars['Float']>;
  AccountTypeOPTION?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  VAT?: Maybe<Scalars['Float']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['Float']>;
  CreditAmount?: Maybe<Scalars['Float']>;
  AmountLCY?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  SalesPurchLCY?: Maybe<Scalars['Float']>;
  ProfitLCY?: Maybe<Scalars['Float']>;
  InvDiscountLCY?: Maybe<Scalars['Float']>;
  BilltoPaytoNo?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  SalespersPurchCode?: Maybe<Scalars['String']>;
  PendingApproval?: Maybe<Scalars['Boolean']>;
  SourceCode?: Maybe<Scalars['String']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  VATAmount?: Maybe<Scalars['Float']>;
  VATPosting?: Maybe<Scalars['Float']>;
  VATPostingOPTION?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  BusinessUnitCode?: Maybe<Scalars['String']>;
  JournalBatchName?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  RecurringMethod?: Maybe<Scalars['Float']>;
  RecurringMethodOPTION?: Maybe<Scalars['String']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  RecurringFrequency?: Maybe<Scalars['String']>;
  AllocatedAmtLCY?: Maybe<Scalars['Float']>;
  GenPostingType?: Maybe<Scalars['Float']>;
  GenPostingTypeOPTION?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  AllowApplication?: Maybe<Scalars['Boolean']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  BalGenPostingType?: Maybe<Scalars['Float']>;
  BalGenPostingTypeOPTION?: Maybe<Scalars['String']>;
  BalGenBusPostingGroup?: Maybe<Scalars['String']>;
  BalGenProdPostingGroup?: Maybe<Scalars['String']>;
  BalVATCalculationType?: Maybe<Scalars['Float']>;
  BalVATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  BalVAT?: Maybe<Scalars['Float']>;
  BalVATAmount?: Maybe<Scalars['Float']>;
  BankPaymentType?: Maybe<Scalars['Float']>;
  BankPaymentTypeOPTION?: Maybe<Scalars['String']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  BalVATBaseAmount?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  CheckPrinted?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['Float']>;
  SourceTypeOPTION?: Maybe<Scalars['String']>;
  SourceNo?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  BalTaxAreaCode?: Maybe<Scalars['String']>;
  BalTaxLiable?: Maybe<Scalars['Boolean']>;
  BalTaxGroupCode?: Maybe<Scalars['String']>;
  BalUseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BalVATBusPostingGroup?: Maybe<Scalars['String']>;
  BalVATProdPostingGroup?: Maybe<Scalars['String']>;
  AdditionalCurrencyPosting?: Maybe<Scalars['Float']>;
  AdditionalCurrencyPostingOPTION?: Maybe<Scalars['String']>;
  FAAddCurrencyFactor?: Maybe<Scalars['Float']>;
  SourceCurrencyCode?: Maybe<Scalars['String']>;
  SourceCurrencyAmount?: Maybe<Scalars['Float']>;
  SourceCurrVATBaseAmount?: Maybe<Scalars['Float']>;
  SourceCurrVATAmount?: Maybe<Scalars['Float']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  VATAmountLCY?: Maybe<Scalars['Float']>;
  VATBaseAmountLCY?: Maybe<Scalars['Float']>;
  BalVATAmountLCY?: Maybe<Scalars['Float']>;
  BalVATBaseAmountLCY?: Maybe<Scalars['Float']>;
  ReversingEntry?: Maybe<Scalars['Boolean']>;
  AllowZeroAmountPosting?: Maybe<Scalars['Boolean']>;
  ShiptoOrderAddressCode?: Maybe<Scalars['String']>;
  VATDifference?: Maybe<Scalars['Float']>;
  BalVATDifference?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  ICPartnerGLAccNo?: Maybe<Scalars['String']>;
  ICPartnerTransactionNo?: Maybe<Scalars['Float']>;
  SelltoBuyfromNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Boolean']>;
  FinancialVoid?: Maybe<Scalars['Boolean']>;
  CopyVATSetuptoJnlLines?: Maybe<Scalars['Boolean']>;
  VATBaseBeforePmtDisc?: Maybe<Scalars['Float']>;
  OrigPmtDiscPossible?: Maybe<Scalars['Float']>;
  OrigPmtDiscPossibleLCY?: Maybe<Scalars['Float']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  AppliestoExtDocNo?: Maybe<Scalars['String']>;
  KeepDescription?: Maybe<Scalars['Boolean']>;
  RecipientBankAccount?: Maybe<Scalars['String']>;
  MessagetoRecipient?: Maybe<Scalars['String']>;
  ExportedtoPaymentFile?: Maybe<Scalars['Boolean']>;
  HasPaymentExportError?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobQuantity?: Maybe<Scalars['Float']>;
  JobUnitCostLCY?: Maybe<Scalars['Float']>;
  JobLineDiscount?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobUnitOfMeasureCode?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobUnitCost?: Maybe<Scalars['Float']>;
  JobTotalCost?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobTotalCostLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  JobPlanningLineNo?: Maybe<Scalars['Float']>;
  JobRemainingQty?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DataExchEntryNo?: Maybe<Scalars['Float']>;
  PayerInformation?: Maybe<Scalars['String']>;
  TransactionInformation?: Maybe<Scalars['String']>;
  DataExchLineNo?: Maybe<Scalars['Float']>;
  AppliedAutomatically?: Maybe<Scalars['Boolean']>;
  LinkedTableID?: Maybe<Scalars['Float']>;
  LinkedSystemID?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  DeferralLineNo?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  NoofDepreciationDays?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  FAReclassificationEntry?: Maybe<Scalars['Boolean']>;
  FAErrorEntryNo?: Maybe<Scalars['Float']>;
  IndexEntry?: Maybe<Scalars['Boolean']>;
  SourceLineNo?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  CheckExported?: Maybe<Scalars['Boolean']>;
  CheckTransmitted?: Maybe<Scalars['Boolean']>;
  ReverseDateCalculation?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  AppliestoInvoiceId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  JournalBatchId?: Maybe<Scalars['String']>;
  PaymentMethodId?: Maybe<Scalars['String']>;
  BalanceAccountId?: Maybe<Scalars['String']>;
  VendorId?: Maybe<Scalars['String']>;
  SourcePostingDate?: Maybe<Scalars['DateTime']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBDirectDebitPackNo?: Maybe<Scalars['Float']>;
  PEBInformationType?: Maybe<Scalars['Float']>;
  PEBInformationTypeOPTION?: Maybe<Scalars['String']>;
  PEBLastGLEntryNo?: Maybe<Scalars['Float']>;
  PEBLastGLRegEntryNo?: Maybe<Scalars['Float']>;
  PEBInformation?: Maybe<Scalars['String']>;
  PEBSIEImportCode?: Maybe<Scalars['String']>;
  PEBOCRNo?: Maybe<Scalars['String']>;
  PEBExtDocNonotMandat?: Maybe<Scalars['Boolean']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMSkipExtraPost?: Maybe<Scalars['Boolean']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMCustomerDepositNo?: Maybe<Scalars['String']>;
  EQMDisposalType?: Maybe<Scalars['Float']>;
  EQMDisposalTypeOPTION?: Maybe<Scalars['String']>;
  EQMDepositEntry?: Maybe<Scalars['Boolean']>;
  EQMAdvancePaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMAdvPaymentAmountLCY?: Maybe<Scalars['Float']>;
  EQMRentalEntry?: Maybe<Scalars['Boolean']>;
  EQMWorkshopEntry?: Maybe<Scalars['Boolean']>;
  EQMObjectErrorEntryNo?: Maybe<Scalars['Float']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMDepositPayment?: Maybe<Scalars['Boolean']>;
  EQMTransactionID?: Maybe<Scalars['Float']>;
  EQMFANo?: Maybe<Scalars['String']>;
  EQMFADepreciationBookCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterGenJournalLineInput>>;
  NOT?: Maybe<FilterGenJournalLineInput>;
};

export type FilterGenProductPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefVATProdPostingGroup?: Maybe<Scalars['String']>;
  AutoInsertDefault?: Maybe<Scalars['Boolean']>;
  EQMPercentofMonthlyPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterGenProductPostingGroupInput>>;
  NOT?: Maybe<FilterGenProductPostingGroupInput>;
};

export type FilterInspectionInput = {
  discriminator?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  InspectionDate?: Maybe<Scalars['DateTime']>;
  ObjectStatus?: Maybe<Scalars['Float']>;
  ObjectStatusOPTION?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterInspectionInput>>;
  NOT?: Maybe<FilterInspectionInput>;
};

export type FilterInspectionUserInput = {
  discriminator?: Maybe<Scalars['String']>;
  SalesPerson?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterInspectionUserInput>>;
  NOT?: Maybe<FilterInspectionUserInput>;
};

export type FilterIntegrationCollectionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  LatestHeartBeat?: Maybe<Scalars['DateTime']>;
  FirstHeartBeat?: Maybe<Scalars['DateTime']>;
  HeartBeartIntervalMinutes?: Maybe<Scalars['Float']>;
  HeartbeatMonitoringEnabled?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterIntegrationCollectionInput>>;
  NOT?: Maybe<FilterIntegrationCollectionInput>;
};

export type FilterInventoryPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterInventoryPostingGroupInput>>;
  NOT?: Maybe<FilterInventoryPostingGroupInput>;
};

export type FilterInvitationInput = {
  discriminator?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  Status?: Maybe<InvitationStatusEnum>;
  Type?: Maybe<InvitationTypeEnum>;
  Role?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  ExpiresOn?: Maybe<Scalars['DateTime']>;
  AcceptedOn?: Maybe<Scalars['DateTime']>;
  AcceptedByUserEmail?: Maybe<Scalars['String']>;
  CreatedByUserEmail?: Maybe<Scalars['String']>;
  CreateByUserName?: Maybe<Scalars['String']>;
  InvitationCode?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Deactivated?: Maybe<Scalars['Boolean']>;
  IOSAppLink?: Maybe<Scalars['String']>;
  AndroidAppLink?: Maybe<Scalars['String']>;
  IOSAppCode?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterInvitationInput>>;
  NOT?: Maybe<FilterInvitationInput>;
};

export type FilterInvoiceHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DocumentExchangeIdentifier?: Maybe<Scalars['String']>;
  DocumentExchangeStatus?: Maybe<Scalars['String']>;
  DocExchOriginalIdentifier?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  CustLedgerEntryNo?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['String']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMCombineCustomerProj?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMInvoiceDocType?: Maybe<Scalars['String']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  EQMLinkedCreditMemos?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterInvoiceHeaderInput>>;
  NOT?: Maybe<FilterInvoiceHeaderInput>;
};

export type FilterInvoiceLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  LineDiscountCalculation?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMUnitPrice?: Maybe<Scalars['Float']>;
  EQMLineDiscount?: Maybe<Scalars['Float']>;
  EQMLineDiscountAmount?: Maybe<Scalars['Float']>;
  EQMLineAmount?: Maybe<Scalars['Float']>;
  EQMReturnFromDate?: Maybe<Scalars['DateTime']>;
  EQMReturnToDate?: Maybe<Scalars['DateTime']>;
  EQMReturnWholePeriod?: Maybe<Scalars['Boolean']>;
  EQMReturnRentalDays?: Maybe<Scalars['Float']>;
  EQMReturnFreeDays?: Maybe<Scalars['Float']>;
  EQMTransferThisLine?: Maybe<Scalars['Boolean']>;
  EQMReturnQuantity?: Maybe<Scalars['Float']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMRentalLineType?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMAmountCost?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMRefOrigDoc?: Maybe<Scalars['String']>;
  EQMRefOrigLineNo?: Maybe<Scalars['Float']>;
  EQMManuallyChanged?: Maybe<Scalars['Boolean']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMAdvancePaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterInvoiceLineInput>>;
  NOT?: Maybe<FilterInvoiceLineInput>;
};

export type FilterItemCategoryInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ParentCategory?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Indentation?: Maybe<Scalars['Float']>;
  PresentationOrder?: Maybe<Scalars['Float']>;
  HasChildren?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  DefGenProdPostingGroup?: Maybe<Scalars['String']>;
  DefInventoryPostingGroup?: Maybe<Scalars['String']>;
  DefTaxGroupCode?: Maybe<Scalars['String']>;
  DefCostingMethod?: Maybe<Scalars['Float']>;
  DefCostingMethodOPTION?: Maybe<Scalars['String']>;
  DefVATProdPostingGroup?: Maybe<Scalars['String']>;
  DefReRentRProdPostGrp?: Maybe<Scalars['String']>;
  DefReRentSProdPostGrp?: Maybe<Scalars['String']>;
  EQMDefGenRntlPostGrp?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemCategoryInput>>;
  NOT?: Maybe<FilterItemCategoryInput>;
};

export type FilterItemDiscGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemDiscGroupInput>>;
  NOT?: Maybe<FilterItemDiscGroupInput>;
};

export type FilterItemInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  No2?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  PriceUnitConversion?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  ShelfNo?: Maybe<Scalars['String']>;
  ItemDiscGroup?: Maybe<Scalars['String']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  CommissionGroup?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  CostingMethod?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  CostisAdjusted?: Maybe<Scalars['Boolean']>;
  AllowOnlineAdjustment?: Maybe<Scalars['Boolean']>;
  VendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  ReorderPoint?: Maybe<Scalars['Float']>;
  MaximumInventory?: Maybe<Scalars['Float']>;
  ReorderQuantity?: Maybe<Scalars['Float']>;
  AlternativeItemNo?: Maybe<Scalars['String']>;
  UnitListPrice?: Maybe<Scalars['Float']>;
  DutyDue?: Maybe<Scalars['Float']>;
  DutyCode?: Maybe<Scalars['String']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  Durability?: Maybe<Scalars['String']>;
  FreightType?: Maybe<Scalars['String']>;
  TariffNo?: Maybe<Scalars['String']>;
  DutyUnitConversion?: Maybe<Scalars['Float']>;
  CountryRegionPurchasedCode?: Maybe<Scalars['String']>;
  BudgetQuantity?: Maybe<Scalars['Float']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  BudgetProfit?: Maybe<Scalars['Float']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  BlockReason?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  CountryRegionofOriginCode?: Maybe<Scalars['String']>;
  AutomaticExtTexts?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  VATProductPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  StockoutWarning?: Maybe<Scalars['String']>;
  PreventNegativeInventory?: Maybe<Scalars['String']>;
  ApplicationWkshUserID?: Maybe<Scalars['String']>;
  AssemblyPolicy?: Maybe<Scalars['String']>;
  GTIN?: Maybe<Scalars['String']>;
  DefaultDeferralTemplateCode?: Maybe<Scalars['String']>;
  LowLevelCode?: Maybe<Scalars['Float']>;
  LotSize?: Maybe<Scalars['Float']>;
  SerialNos?: Maybe<Scalars['String']>;
  LastUnitCostCalcDate?: Maybe<Scalars['DateTime']>;
  RolledupMaterialCost?: Maybe<Scalars['Float']>;
  RolledupCapacityCost?: Maybe<Scalars['Float']>;
  Scrap?: Maybe<Scalars['Float']>;
  InventoryValueZero?: Maybe<Scalars['Boolean']>;
  DiscreteOrderQuantity?: Maybe<Scalars['Float']>;
  MinimumOrderQuantity?: Maybe<Scalars['Float']>;
  MaximumOrderQuantity?: Maybe<Scalars['Float']>;
  SafetyStockQuantity?: Maybe<Scalars['Float']>;
  OrderMultiple?: Maybe<Scalars['Float']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  FlushingMethod?: Maybe<Scalars['String']>;
  ReplenishmentSystem?: Maybe<Scalars['String']>;
  RoundingPrecision?: Maybe<Scalars['Float']>;
  SalesUnitofMeasure?: Maybe<Scalars['String']>;
  PurchUnitofMeasure?: Maybe<Scalars['String']>;
  TimeBucket?: Maybe<Scalars['String']>;
  ReorderingPolicy?: Maybe<Scalars['String']>;
  IncludeInventory?: Maybe<Scalars['Boolean']>;
  ManufacturingPolicy?: Maybe<Scalars['String']>;
  ReschedulingPeriod?: Maybe<Scalars['String']>;
  LotAccumulationPeriod?: Maybe<Scalars['String']>;
  DampenerPeriod?: Maybe<Scalars['String']>;
  DampenerQuantity?: Maybe<Scalars['Float']>;
  OverflowLevel?: Maybe<Scalars['Float']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  CategoryCode?: Maybe<Scalars['String']>;
  CreatedFromNonstockItem?: Maybe<Scalars['Boolean']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ItemTrackingCode?: Maybe<Scalars['String']>;
  LotNos?: Maybe<Scalars['String']>;
  ExpirationCalculation?: Maybe<Scalars['String']>;
  WarehouseClassCode?: Maybe<Scalars['String']>;
  SpecialEquipmentCode?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  PutawayUnitofMeasureCode?: Maybe<Scalars['String']>;
  PhysInvtCountingPeriodCode?: Maybe<Scalars['String']>;
  LastCountingPeriodUpdate?: Maybe<Scalars['String']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  NextCountingStartDate?: Maybe<Scalars['DateTime']>;
  NextCountingEndDate?: Maybe<Scalars['DateTime']>;
  Id?: Maybe<Scalars['String']>;
  UnitofMeasureId?: Maybe<Scalars['String']>;
  TaxGroupId?: Maybe<Scalars['String']>;
  EQMItemNoConcern?: Maybe<Scalars['String']>;
  EQMDiamondBladeItem?: Maybe<Scalars['String']>;
  EQMDiamondPostingGroup?: Maybe<Scalars['String']>;
  EQMDimensionRentalType?: Maybe<Scalars['String']>;
  EQMMeasurement?: Maybe<Scalars['Float']>;
  EQMReRentRProdPostGrp?: Maybe<Scalars['String']>;
  EQMReRentSProdPostGrp?: Maybe<Scalars['String']>;
  EQMObjectBOMPostingGroup?: Maybe<Scalars['String']>;
  EQMVATRntlProdPostGrp?: Maybe<Scalars['String']>;
  EQMDepositAmount?: Maybe<Scalars['Float']>;
  EQMReplacementPrice?: Maybe<Scalars['Float']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMDescription3?: Maybe<Scalars['String']>;
  EQMDescription4?: Maybe<Scalars['String']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMBlockedRental?: Maybe<Scalars['Boolean']>;
  EQMExpAvailAfterEndDate?: Maybe<Scalars['Boolean']>;
  EQMCreditonEarlyReturn?: Maybe<Scalars['Float']>;
  EQMStdRentalChargeDays?: Maybe<Scalars['String']>;
  EQMDebitonReturnDate?: Maybe<Scalars['Boolean']>;
  EQMBaseCalendarCode?: Maybe<Scalars['String']>;
  EQMGenRentalPostingGroup?: Maybe<Scalars['String']>;
  EQMRentalUnitPrice?: Maybe<Scalars['Float']>;
  EQMDefaultRentalItem?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRentalItemDiscGroup?: Maybe<Scalars['String']>;
  EQMDimensionRentalGroup?: Maybe<Scalars['String']>;
  EQMGenRepurchasePostGrp?: Maybe<Scalars['String']>;
  EQMSquareMeter?: Maybe<Scalars['Float']>;
  EQMFilterLocationCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  ProductionBOMNo?: Maybe<Scalars['String']>;
  SingleLevelMaterialCost?: Maybe<Scalars['Float']>;
  SingleLevelCapacityCost?: Maybe<Scalars['Float']>;
  SingleLevelSubcontrdCost?: Maybe<Scalars['Float']>;
  SingleLevelCapOvhdCost?: Maybe<Scalars['Float']>;
  SingleLevelMfgOvhdCost?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RolledupSubcontractedCost?: Maybe<Scalars['Float']>;
  RolledupMfgOvhdCost?: Maybe<Scalars['Float']>;
  RolledupCapOverheadCost?: Maybe<Scalars['Float']>;
  OrderTrackingPolicy?: Maybe<Scalars['String']>;
  Critical?: Maybe<Scalars['Boolean']>;
  CommonItemNo?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DropShipmentFilter?: Maybe<Scalars['Boolean']>;
  BinFilter?: Maybe<Scalars['String']>;
  VariantFilter?: Maybe<Scalars['String']>;
  LotNoFilter?: Maybe<Scalars['String']>;
  SerialNoFilter?: Maybe<Scalars['String']>;
  EQMRentalSaleFilter?: Maybe<Scalars['Boolean']>;
  ProductionForecastName?: Maybe<Scalars['String']>;
  ComponentForecast?: Maybe<Scalars['Boolean']>;
  AssemblyBOM?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CostisPostedtoGL?: Maybe<Scalars['Boolean']>;
  Inventory?: Maybe<Scalars['Float']>;
  NetInvoicedQty?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  PurchasesQty?: Maybe<Scalars['Float']>;
  SalesQty?: Maybe<Scalars['Float']>;
  PositiveAdjmtQty?: Maybe<Scalars['Float']>;
  NegativeAdjmtQty?: Maybe<Scalars['Float']>;
  PurchasesLCY?: Maybe<Scalars['Float']>;
  SalesLCY?: Maybe<Scalars['Float']>;
  PositiveAdjmtLCY?: Maybe<Scalars['Float']>;
  NegativeAdjmtLCY?: Maybe<Scalars['Float']>;
  COGSLCY?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  QtyonSalesOrder?: Maybe<Scalars['Float']>;
  TransferredQty?: Maybe<Scalars['Float']>;
  TransferredLCY?: Maybe<Scalars['Float']>;
  ReservedQtyonInventory?: Maybe<Scalars['Float']>;
  ReservedQtyonPurchOrders?: Maybe<Scalars['Float']>;
  ReservedQtyonSalesOrders?: Maybe<Scalars['Float']>;
  ResQtyonOutboundTransfer?: Maybe<Scalars['Float']>;
  ResQtyonInboundTransfer?: Maybe<Scalars['Float']>;
  ResQtyonSalesReturns?: Maybe<Scalars['Float']>;
  ResQtyonPurchReturns?: Maybe<Scalars['Float']>;
  CostofOpenProductionOrders?: Maybe<Scalars['Float']>;
  ResQtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  ResQtyonAsmComp?: Maybe<Scalars['Float']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonAsmComponent?: Maybe<Scalars['Float']>;
  QtyonJobOrder?: Maybe<Scalars['Float']>;
  ResQtyonJobOrder?: Maybe<Scalars['Float']>;
  ScheduledReceiptQty?: Maybe<Scalars['Float']>;
  ScheduledNeedQty?: Maybe<Scalars['Float']>;
  ReservedQtyonProdOrder?: Maybe<Scalars['Float']>;
  ResQtyonProdOrderComp?: Maybe<Scalars['Float']>;
  ResQtyonReqLine?: Maybe<Scalars['Float']>;
  PlanningTransferShipQty?: Maybe<Scalars['Float']>;
  PlanningWorksheetQty?: Maybe<Scalars['Float']>;
  StockkeepingUnitExists?: Maybe<Scalars['Boolean']>;
  SubstitutesExist?: Maybe<Scalars['Boolean']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  TransOrdReceiptQty?: Maybe<Scalars['Float']>;
  TransOrdShipmentQty?: Maybe<Scalars['Float']>;
  QtyAssignedtoship?: Maybe<Scalars['Float']>;
  QtyPicked?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  ResQtyonServiceOrders?: Maybe<Scalars['Float']>;
  QtyonPurchReturn?: Maybe<Scalars['Float']>;
  QtyonSalesReturn?: Maybe<Scalars['Float']>;
  NoofSubstitutes?: Maybe<Scalars['Float']>;
  LastPhysInvtDate?: Maybe<Scalars['DateTime']>;
  IdentifierCode?: Maybe<Scalars['String']>;
  EQMAvailabletoTake?: Maybe<Scalars['Float']>;
  EQMUseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  EQMRentalReservationsQty?: Maybe<Scalars['Float']>;
  EQMRentalDeliveriesQty?: Maybe<Scalars['Float']>;
  EQMExpRentalReturnsQty?: Maybe<Scalars['Float']>;
  EQMRentalReturnsQty?: Maybe<Scalars['Float']>;
  EQMQtyonRentalReRent?: Maybe<Scalars['Float']>;
  EQMQtyonRent?: Maybe<Scalars['Float']>;
  PlanningIssuesQty?: Maybe<Scalars['Float']>;
  PlanningReceiptQty?: Maybe<Scalars['Float']>;
  PlannedOrderReceiptQty?: Maybe<Scalars['Float']>;
  FPOrderReceiptQty?: Maybe<Scalars['Float']>;
  RelOrderReceiptQty?: Maybe<Scalars['Float']>;
  PlanningReleaseQty?: Maybe<Scalars['Float']>;
  PlannedOrderReleaseQty?: Maybe<Scalars['Float']>;
  PurchReqReceiptQty?: Maybe<Scalars['Float']>;
  PurchReqReleaseQty?: Maybe<Scalars['Float']>;
  ProdForecastQuantityBase?: Maybe<Scalars['Float']>;
  QtyonProdOrder?: Maybe<Scalars['Float']>;
  QtyonComponentLines?: Maybe<Scalars['Float']>;
  MagentoId?: Maybe<Scalars['Float']>;
  SynkedToMagento?: Maybe<Scalars['Boolean']>;
  MagentoSyncProblems?: Maybe<Scalars['String']>;
  AttributeSetId?: Maybe<Scalars['Float']>;
  Visibility?: Maybe<Scalars['Float']>;
  TypeId?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['Float']>;
  CalculatedInventory?: Maybe<Scalars['Float']>;
  CalculatedInventoryUOM?: Maybe<Scalars['String']>;
  CalculatedInventoryUpdatedAd?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
  ActiveWeb?: Maybe<Scalars['Boolean']>;
  TempOutOfStock?: Maybe<Scalars['Boolean']>;
  OutletSE?: Maybe<Scalars['Boolean']>;
  OutletDateFromSE?: Maybe<Scalars['DateTime']>;
  OutletDateToSE?: Maybe<Scalars['DateTime']>;
  CampaignSE?: Maybe<Scalars['Boolean']>;
  CampaignDateFromSE?: Maybe<Scalars['DateTime']>;
  CampaignDateToSE?: Maybe<Scalars['DateTime']>;
  DeliveryInformation?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  IsBulky?: Maybe<Scalars['Boolean']>;
  OrderItem?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  PackageHeight?: Maybe<Scalars['Float']>;
  PackageWidth?: Maybe<Scalars['Float']>;
  PackageLength?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
  DescriptionNo?: Maybe<Scalars['String']>;
  BrandDescription?: Maybe<Scalars['String']>;
  DeliveryTime?: Maybe<Scalars['String']>;
  HomeDelivery?: Maybe<Scalars['Boolean']>;
  ServicePointDelivery?: Maybe<Scalars['Boolean']>;
  BlockedNO?: Maybe<Scalars['Boolean']>;
  ExpectedInStockDate?: Maybe<Scalars['DateTime']>;
  BulkGroup?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsCampaign?: Maybe<Scalars['Boolean']>;
  GiftcardType?: Maybe<Scalars['String']>;
  TaxClassId?: Maybe<Scalars['String']>;
  UrlKey?: Maybe<Scalars['String']>;
  ShowInFieldOrder?: Maybe<Scalars['Boolean']>;
  ItemType?: Maybe<ItemItemTypeEnum>;
  ItemTypeOPTION?: Maybe<Scalars['String']>;
  SalesOrderMultiple?: Maybe<Scalars['Float']>;
  EcoLabel?: Maybe<Scalars['String']>;
  MainGroupCode?: Maybe<Scalars['String']>;
  ItemGroupCode?: Maybe<Scalars['String']>;
  OldItemNo?: Maybe<Scalars['String']>;
  OldItemNo2?: Maybe<Scalars['String']>;
  RKVItem?: Maybe<Scalars['Boolean']>;
  ToExpire?: Maybe<Scalars['Boolean']>;
  AcquisitionItem?: Maybe<Scalars['Boolean']>;
  MagentoItem?: Maybe<Scalars['Boolean']>;
  AlwaysAvailableOnWeb?: Maybe<Scalars['Boolean']>;
  QBSId?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  EdgeSmallImageUrl?: Maybe<Scalars['String']>;
  EdgeLargelImageUrl?: Maybe<Scalars['String']>;
  CalculationPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemInput>>;
  NOT?: Maybe<FilterItemInput>;
};

export type FilterItemReferenceInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  ReferenceType?: Maybe<Scalars['Float']>;
  ReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ReferenceTypeNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscontinueBarCode?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  ExternalNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemReferenceInput>>;
  NOT?: Maybe<FilterItemReferenceInput>;
};

export type FilterItemRestrictionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Type?: Maybe<ItemRestrictionTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemRestrictionInput>>;
  NOT?: Maybe<FilterItemRestrictionInput>;
};

export type FilterItemUnitOfMeasureInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QtyRoundingPrecision?: Maybe<Scalars['Float']>;
  Length?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  Height?: Maybe<Scalars['Float']>;
  Cubage?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemUnitOfMeasureInput>>;
  NOT?: Maybe<FilterItemUnitOfMeasureInput>;
};

export type FilterItemVendorInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterItemVendorInput>>;
  NOT?: Maybe<FilterItemVendorInput>;
};

export type FilterJobTaskInput = {
  discriminator?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  JobTaskType?: Maybe<Scalars['Float']>;
  JobTaskTypeOPTION?: Maybe<Scalars['String']>;
  WIPTotal?: Maybe<Scalars['Float']>;
  WIPTotalOPTION?: Maybe<Scalars['String']>;
  JobPostingGroup?: Maybe<Scalars['String']>;
  WIPMethod?: Maybe<Scalars['String']>;
  Totaling?: Maybe<Scalars['String']>;
  NewPage?: Maybe<Scalars['Boolean']>;
  NoofBlankLines?: Maybe<Scalars['Float']>;
  Indentation?: Maybe<Scalars['Float']>;
  RecognizedSalesAmount?: Maybe<Scalars['Float']>;
  RecognizedCostsAmount?: Maybe<Scalars['Float']>;
  RecognizedSalesGLAmount?: Maybe<Scalars['Float']>;
  RecognizedCostsGLAmount?: Maybe<Scalars['Float']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PostingDateFilter?: Maybe<Scalars['String']>;
  PlanningDateFilter?: Maybe<Scalars['String']>;
  ScheduleTotalCost?: Maybe<Scalars['Float']>;
  ScheduleTotalPrice?: Maybe<Scalars['Float']>;
  UsageTotalCost?: Maybe<Scalars['Float']>;
  UsageTotalPrice?: Maybe<Scalars['Float']>;
  ContractTotalCost?: Maybe<Scalars['Float']>;
  ContractTotalPrice?: Maybe<Scalars['Float']>;
  ContractInvoicedPrice?: Maybe<Scalars['Float']>;
  ContractInvoicedCost?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  RemainingTotalCost?: Maybe<Scalars['Float']>;
  RemainingTotalPrice?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  ClosedOn?: Maybe<Scalars['DateTime']>;
  FirstSavedOn?: Maybe<Scalars['DateTime']>;
  DevopsId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterJobTaskInput>>;
  NOT?: Maybe<FilterJobTaskInput>;
};

export type FilterKliHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  ErrandNo?: Maybe<Scalars['Float']>;
  User?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  NameDescription?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['Float']>;
  Timemin?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  QAdistdat?: Maybe<Scalars['DateTime']>;
  FinnishedDate?: Maybe<Scalars['DateTime']>;
  No?: Maybe<Scalars['String']>;
  CorectivAction?: Maybe<Scalars['String']>;
  NewInvoice?: Maybe<Scalars['Boolean']>;
  OrderNo?: Maybe<Scalars['String']>;
  CurrentOwnerEmail?: Maybe<Scalars['String']>;
  Closed?: Maybe<Scalars['Boolean']>;
  CostPerMin?: Maybe<Scalars['Float']>;
  ReasonText?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ImpactOnStock?: Maybe<Scalars['Boolean']>;
  CustomerNo?: Maybe<Scalars['String']>;
  InternCost?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDate?: Maybe<Scalars['String']>;
  Guarantee?: Maybe<Scalars['Boolean']>;
  ReasonCodeDesc?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Workplace?: Maybe<Scalars['String']>;
  Caller?: Maybe<Scalars['String']>;
  CallerPhone?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ModelYear?: Maybe<Scalars['String']>;
  Pirority?: Maybe<Scalars['Boolean']>;
  RecType?: Maybe<Scalars['Float']>;
  RecTypeOPTION?: Maybe<Scalars['String']>;
  RevisitNeeded?: Maybe<Scalars['Boolean']>;
  MachineReplaced?: Maybe<Scalars['Boolean']>;
  AnalysisCode?: Maybe<Scalars['String']>;
  AnalysisText?: Maybe<Scalars['String']>;
  MainErrand?: Maybe<Scalars['Boolean']>;
  LinkErrand?: Maybe<Scalars['Float']>;
  City?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  SendToRepairer?: Maybe<Scalars['String']>;
  FollowUp?: Maybe<Scalars['String']>;
  ErrandDescription?: Maybe<Scalars['String']>;
  FollowUpPreformed?: Maybe<Scalars['Boolean']>;
  CorectivActionPreformed?: Maybe<Scalars['Boolean']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  LastStatusBeforeClose?: Maybe<Scalars['Float']>;
  LastStatusBeforeCloseOPTION?: Maybe<Scalars['String']>;
  WorkplaceContact?: Maybe<Scalars['String']>;
  WorkplaceContactPhone?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  TableNo?: Maybe<Scalars['Float']>;
  ReasonTextBlob?: Maybe<Scalars['String']>;
  ActionTextBlob?: Maybe<Scalars['String']>;
  FixedByExternalRepairer?: Maybe<Scalars['Boolean']>;
  TurnaroundTime?: Maybe<Scalars['String']>;
  ReportedToWorkingEnvDpt?: Maybe<Scalars['Boolean']>;
  SafteyOfficerInformed?: Maybe<Scalars['Boolean']>;
  NewRiskAnalysis?: Maybe<Scalars['Boolean']>;
  AnalysisTextBLOB?: Maybe<Scalars['String']>;
  FollowUpBLOB?: Maybe<Scalars['String']>;
  CorectivActionBLOB?: Maybe<Scalars['String']>;
  AccidentComment?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  Note?: Maybe<Scalars['String']>;
  CreditNo?: Maybe<Scalars['String']>;
  CreditPosted?: Maybe<Scalars['Boolean']>;
  CreditApproved?: Maybe<Scalars['Boolean']>;
  CreditApprovedBy?: Maybe<Scalars['String']>;
  NoOfLinkedErrands?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
  TurnOverToEmail?: Maybe<Scalars['String']>;
  CreatedExternal?: Maybe<Scalars['Boolean']>;
  Template?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterKliHeaderInput>>;
  NOT?: Maybe<FilterKliHeaderInput>;
};

export type FilterKliReasonCodeInput = {
  discriminator?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RecType?: Maybe<Scalars['Float']>;
  RecTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterKliReasonCodeInput>>;
  NOT?: Maybe<FilterKliReasonCodeInput>;
};

export type FilterLeadInput = {
  discriminator?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  PictureURL?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CompanyNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Surname?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Initials?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  OwnerName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterLeadInput>>;
  NOT?: Maybe<FilterLeadInput>;
};

export type FilterLocationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultBinCode?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  UseAsInTransit?: Maybe<Scalars['Boolean']>;
  RequirePutaway?: Maybe<Scalars['Boolean']>;
  RequirePick?: Maybe<Scalars['Boolean']>;
  CrossDockDueDateCalc?: Maybe<Scalars['String']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  RequireReceive?: Maybe<Scalars['Boolean']>;
  RequireShipment?: Maybe<Scalars['Boolean']>;
  BinMandatory?: Maybe<Scalars['Boolean']>;
  DirectedPutawayandPick?: Maybe<Scalars['Boolean']>;
  DefaultBinSelection?: Maybe<Scalars['Float']>;
  DefaultBinSelectionOPTION?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  UsePutawayWorksheet?: Maybe<Scalars['Boolean']>;
  PickAccordingtoFEFO?: Maybe<Scalars['Boolean']>;
  AllowBreakbulk?: Maybe<Scalars['Boolean']>;
  BinCapacityPolicy?: Maybe<Scalars['Float']>;
  BinCapacityPolicyOPTION?: Maybe<Scalars['String']>;
  OpenShopFloorBinCode?: Maybe<Scalars['String']>;
  ToProductionBinCode?: Maybe<Scalars['String']>;
  FromProductionBinCode?: Maybe<Scalars['String']>;
  AdjustmentBinCode?: Maybe<Scalars['String']>;
  AlwaysCreatePutawayLine?: Maybe<Scalars['Boolean']>;
  AlwaysCreatePickLine?: Maybe<Scalars['Boolean']>;
  SpecialEquipment?: Maybe<Scalars['Float']>;
  SpecialEquipmentOPTION?: Maybe<Scalars['String']>;
  ReceiptBinCode?: Maybe<Scalars['String']>;
  ShipmentBinCode?: Maybe<Scalars['String']>;
  CrossDockBinCode?: Maybe<Scalars['String']>;
  ToAssemblyBinCode?: Maybe<Scalars['String']>;
  FromAssemblyBinCode?: Maybe<Scalars['String']>;
  AsmtoOrderShptBinCode?: Maybe<Scalars['String']>;
  ToJobBinCode?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  UseADCS?: Maybe<Scalars['Boolean']>;
  EQMInternalCustomerNo?: Maybe<Scalars['String']>;
  EQMReclassJnlTemplName?: Maybe<Scalars['String']>;
  EQMReclassJnlBatchName?: Maybe<Scalars['String']>;
  EQMMaintenanceLocation?: Maybe<Scalars['Boolean']>;
  EQMHUB?: Maybe<Scalars['Boolean']>;
  ExternalLocationId?: Maybe<Scalars['Float']>;
  ExternalLocationCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterLocationInput>>;
  NOT?: Maybe<FilterLocationInput>;
};

export type FilterMagentoOrderStatusInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntityId?: Maybe<Scalars['Float']>;
  IncrementId?: Maybe<Scalars['String']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['DateTime']>;
  UpdatedAt?: Maybe<Scalars['DateTime']>;
  CheckCount?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterMagentoOrderStatusInput>>;
  NOT?: Maybe<FilterMagentoOrderStatusInput>;
};

export type FilterMagentoSettingsInput = {
  discriminator?: Maybe<Scalars['String']>;
  QuestCustomerNo?: Maybe<Scalars['String']>;
  QuestCustomerName?: Maybe<Scalars['String']>;
  QuestCustomerGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterMagentoSettingsInput>>;
  NOT?: Maybe<FilterMagentoSettingsInput>;
};

export type FilterManufacturerInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterManufacturerInput>>;
  NOT?: Maybe<FilterManufacturerInput>;
};

export type FilterNavUserInput = {
  discriminator?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['Float']>;
  StateOPTION?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  WindowsSecurityID?: Maybe<Scalars['String']>;
  ChangePassword?: Maybe<Scalars['Boolean']>;
  LicenseType?: Maybe<Scalars['Float']>;
  LicenseTypeOPTION?: Maybe<Scalars['String']>;
  AuthenticationEmail?: Maybe<Scalars['String']>;
  EdgeCostCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterNavUserInput>>;
  NOT?: Maybe<FilterNavUserInput>;
};

export type FilterObjectCommentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['Float']>;
  TableNameOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Code?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectCommentLineInput>>;
  NOT?: Maybe<FilterObjectCommentLineInput>;
};

export type FilterObjectFamilyInput = {
  discriminator?: Maybe<Scalars['String']>;
  Family?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectFamilyInput>>;
  NOT?: Maybe<FilterObjectFamilyInput>;
};

export type FilterObjectGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['Float']>;
  DepreciationMethodOPTION?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['Float']>;
  StdRentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupNoConcern?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  TaxDepreciationMethod?: Maybe<Scalars['Float']>;
  TaxDepreciationMethodOPTION?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectGroupInput>>;
  NOT?: Maybe<FilterObjectGroupInput>;
};

export type FilterObjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  RentalCustomerNo?: Maybe<Scalars['String']>;
  SalesCustomerNo?: Maybe<Scalars['String']>;
  FixedAssetNo?: Maybe<Scalars['String']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StatusInventory?: Maybe<Scalars['String']>;
  ServiceCustomerNo?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  PostedReceiptNo?: Maybe<Scalars['String']>;
  PostedInvoiceNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['String']>;
  BlockedforRental?: Maybe<Scalars['Boolean']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ServiceItemNo?: Maybe<Scalars['String']>;
  DefaultContractValue?: Maybe<Scalars['Float']>;
  DefaultContractDiscount?: Maybe<Scalars['Float']>;
  OwningLocationCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SoldFor?: Maybe<Scalars['Float']>;
  SoldDate?: Maybe<Scalars['DateTime']>;
  UseFixedAsset?: Maybe<Scalars['Boolean']>;
  CounterType?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  FirstRented?: Maybe<Scalars['String']>;
  LastRented?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  InPurchaseQuote?: Maybe<Scalars['Boolean']>;
  InPurchaseOrder?: Maybe<Scalars['Boolean']>;
  PurchaseInvoiced?: Maybe<Scalars['Boolean']>;
  PurchaseReceived?: Maybe<Scalars['Boolean']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  CreatedbyUser?: Maybe<Scalars['String']>;
  BlockedforSale?: Maybe<Scalars['Boolean']>;
  BlockedforPurchase?: Maybe<Scalars['Boolean']>;
  BlockedforReservation?: Maybe<Scalars['Boolean']>;
  OwnershipStatus?: Maybe<Scalars['String']>;
  InclinInvValueReport?: Maybe<Scalars['Boolean']>;
  StdCostAmount?: Maybe<Scalars['Float']>;
  PurchasedFor?: Maybe<Scalars['Float']>;
  ObtainedDiscount?: Maybe<Scalars['Float']>;
  NetCostAmountAdditional?: Maybe<Scalars['Float']>;
  DiscountGiven?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  EngineNo?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  RadioCode?: Maybe<Scalars['String']>;
  ChassisNo?: Maybe<Scalars['String']>;
  ConstructionYear?: Maybe<Scalars['DateTime']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  DateofPermit?: Maybe<Scalars['DateTime']>;
  InSalesOrder?: Maybe<Scalars['Boolean']>;
  InSalesQuote?: Maybe<Scalars['Boolean']>;
  InSalesInvoice?: Maybe<Scalars['Boolean']>;
  SoldonInvoiceNo?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  InDelivery?: Maybe<Scalars['Boolean']>;
  AdditionalObjectCost?: Maybe<Scalars['Float']>;
  AdditionalObjectCostDisc?: Maybe<Scalars['Float']>;
  AdditionalObjectSalesPrice?: Maybe<Scalars['Float']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  InSalesCreditMemo?: Maybe<Scalars['Boolean']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDay?: Maybe<Scalars['Boolean']>;
  PriceTerm?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  PolicyNo?: Maybe<Scalars['String']>;
  LeasingContractNo?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  NewOwnershipstatus?: Maybe<Scalars['String']>;
  NewStartDate?: Maybe<Scalars['DateTime']>;
  CreateFixedAsset?: Maybe<Scalars['Boolean']>;
  MaintenanceDescription?: Maybe<Scalars['String']>;
  Installment?: Maybe<Scalars['Boolean']>;
  InstallmentDescription?: Maybe<Scalars['String']>;
  ProductCode?: Maybe<Scalars['String']>;
  BonusTypeCode?: Maybe<Scalars['String']>;
  FireInspection?: Maybe<Scalars['Float']>;
  RecurringInspection?: Maybe<Scalars['Float']>;
  RecurringInspection2?: Maybe<Scalars['Float']>;
  InternalInspection?: Maybe<Scalars['Float']>;
  FireInspectionNext?: Maybe<Scalars['String']>;
  ExcludeVacation?: Maybe<Scalars['Boolean']>;
  RecurringInspectionNext?: Maybe<Scalars['String']>;
  RecurringInspection2Next?: Maybe<Scalars['String']>;
  InternalInspectionNext?: Maybe<Scalars['String']>;
  InspectionPeriodold?: Maybe<Scalars['String']>;
  InspectionPeriod?: Maybe<Scalars['Float']>;
  InspectionDate?: Maybe<Scalars['DateTime']>;
  NextInspectionDate?: Maybe<Scalars['DateTime']>;
  InspectionSerieNo?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['String']>;
  OwnerStatus?: Maybe<Scalars['String']>;
  CEDocument?: Maybe<Scalars['Boolean']>;
  EngineCertification?: Maybe<Scalars['Boolean']>;
  LastInventoriedDate?: Maybe<Scalars['DateTime']>;
  InventoryInfo?: Maybe<Scalars['String']>;
  FinanceDate?: Maybe<Scalars['DateTime']>;
  AmortizationRate?: Maybe<Scalars['String']>;
  ContractNumber?: Maybe<Scalars['String']>;
  PurschaseValue?: Maybe<Scalars['Float']>;
  ContractInfo?: Maybe<Scalars['String']>;
  ServiceAgreement?: Maybe<Scalars['String']>;
  Finance?: Maybe<Scalars['String']>;
  RentalAmount?: Maybe<Scalars['Float']>;
  StatusInventoryBeforeLost?: Maybe<Scalars['String']>;
  Bitmap1?: Maybe<Scalars['String']>;
  Bitmap2?: Maybe<Scalars['String']>;
  Bitmap3?: Maybe<Scalars['String']>;
  Bitmap4?: Maybe<Scalars['String']>;
  Bitmap5?: Maybe<Scalars['String']>;
  Bitmap6?: Maybe<Scalars['String']>;
  Bitmap7?: Maybe<Scalars['String']>;
  Bitmap8?: Maybe<Scalars['String']>;
  Bitmap9?: Maybe<Scalars['String']>;
  Bitmap10?: Maybe<Scalars['String']>;
  Bitmap11?: Maybe<Scalars['String']>;
  Bitmap12?: Maybe<Scalars['String']>;
  Bitmap13?: Maybe<Scalars['String']>;
  Bitmap14?: Maybe<Scalars['String']>;
  Bitmap15?: Maybe<Scalars['String']>;
  Bitmap16?: Maybe<Scalars['String']>;
  Bitmap17?: Maybe<Scalars['String']>;
  Bitmap18?: Maybe<Scalars['String']>;
  Bitmap19?: Maybe<Scalars['String']>;
  Bitmap20?: Maybe<Scalars['String']>;
  Bitmap21?: Maybe<Scalars['String']>;
  Bitmap22?: Maybe<Scalars['String']>;
  Bitmap23?: Maybe<Scalars['String']>;
  Bitmap24?: Maybe<Scalars['String']>;
  Bitmap25?: Maybe<Scalars['String']>;
  Bitmap26?: Maybe<Scalars['String']>;
  Bitmap27?: Maybe<Scalars['String']>;
  Bitmap28?: Maybe<Scalars['String']>;
  Bitmap29?: Maybe<Scalars['String']>;
  Bitmap30?: Maybe<Scalars['String']>;
  Bitmap31?: Maybe<Scalars['String']>;
  Bitmap32?: Maybe<Scalars['String']>;
  ObjectNoConcern?: Maybe<Scalars['String']>;
  PreviousInventoryStatus?: Maybe<Scalars['String']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  WorkOrderCustomerNo?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  OldObjectNo?: Maybe<Scalars['String']>;
  ChangedbyUserID?: Maybe<Scalars['String']>;
  ChangedDate?: Maybe<Scalars['DateTime']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  RegStatusRental?: Maybe<Scalars['String']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  WarrantyDate?: Maybe<Scalars['DateTime']>;
  TrackingCode?: Maybe<Scalars['String']>;
  VATProdPostingGrpRental?: Maybe<Scalars['String']>;
  ServiceStatus?: Maybe<Scalars['String']>;
  LastServiceDate?: Maybe<Scalars['DateTime']>;
  LastInspectionDate?: Maybe<Scalars['DateTime']>;
  LastInspDate?: Maybe<Scalars['DateTime']>;
  TerminateFixedAsset?: Maybe<Scalars['Boolean']>;
  CounterReporting?: Maybe<Scalars['Boolean']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  CounterType2?: Maybe<Scalars['String']>;
  Counter2?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  DateFilter2?: Maybe<Scalars['DateTime']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  TimeFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  CurrentActiveContractNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  CommentObjectType?: Maybe<Scalars['Boolean']>;
  CommentsinContract?: Maybe<Scalars['Boolean']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowComponentCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowTotalPurchaseCost?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  Invoiceddays?: Maybe<Scalars['Float']>;
  FlowLinkedSumCost?: Maybe<Scalars['Float']>;
  FlowLinkedSalesDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedOtherCost?: Maybe<Scalars['Float']>;
  FlowLinkedLeasingCost?: Maybe<Scalars['Float']>;
  FlowLinkedRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedRentalDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowLinkedServiceCost?: Maybe<Scalars['Float']>;
  FlowLinkedGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedServiceAgrCost?: Maybe<Scalars['Float']>;
  FlowLinkedDepreciation?: Maybe<Scalars['Float']>;
  FlowLinkedUppreciation?: Maybe<Scalars['Float']>;
  FlowLinkedDownpreciation?: Maybe<Scalars['Float']>;
  FlowLinkedPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedSalesCharge?: Maybe<Scalars['Float']>;
  FlowLinkedServiceIncome?: Maybe<Scalars['Float']>;
  FlowLinkedSrvAgrIncome?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedValueChange?: Maybe<Scalars['Float']>;
  FlowLinkedRentalDays?: Maybe<Scalars['Float']>;
  FlowLinkedRentalHours?: Maybe<Scalars['Float']>;
  FlowLinkedInvoicedDays?: Maybe<Scalars['Float']>;
  FlowLinkedGainbySale?: Maybe<Scalars['Float']>;
  FlowLinkedLossbySale?: Maybe<Scalars['Float']>;
  FlowLinkedOtherIncome?: Maybe<Scalars['Float']>;
  PostedWorkOrderFlow?: Maybe<Scalars['Float']>;
  PostedWorkPurchaseFlow?: Maybe<Scalars['Float']>;
  PostedWorkOrderLineFlow?: Maybe<Scalars['Float']>;
  PostedWorkPurchLineFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopEntriesFlow?: Maybe<Scalars['Float']>;
  LastPriceAgreement?: Maybe<Scalars['Float']>;
  PSILink?: Maybe<Scalars['String']>;
  CaravanList?: Maybe<Scalars['String']>;
  TechnicalInfo?: Maybe<Scalars['String']>;
  FunctionalTests?: Maybe<Scalars['Float']>;
  LastRentalStatusChage?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  NoofRentalQuoteLines?: Maybe<Scalars['Float']>;
  NoofOpenRentalLines?: Maybe<Scalars['Float']>;
  NoofClosedRentalLines?: Maybe<Scalars['Float']>;
  NoofDefaultRentalPrices?: Maybe<Scalars['Float']>;
  WarrantyDateFlow?: Maybe<Scalars['DateTime']>;
  GPSCoordinates?: Maybe<Scalars['String']>;
  PSI?: Maybe<Scalars['String']>;
  AdditionalObjectStatus?: Maybe<Scalars['String']>;
  AdditionalObjectStatusOPTION?: Maybe<Scalars['String']>;
  ManufactureDate?: Maybe<Scalars['DateTime']>;
  LastInspectionBy?: Maybe<Scalars['String']>;
  LastInspectionByEmail?: Maybe<Scalars['String']>;
  PurchaseDate?: Maybe<Scalars['DateTime']>;
  FirstUseDate?: Maybe<Scalars['DateTime']>;
  IsElectric?: Maybe<Scalars['Boolean']>;
  OffRentDateEdge?: Maybe<Scalars['DateTime']>;
  Broken?: Maybe<Scalars['Boolean']>;
  RealBridgeSoldToCustomerNo?: Maybe<Scalars['String']>;
  RealBridgeCustomerNo?: Maybe<Scalars['String']>;
  RealBridgeProjectNo?: Maybe<Scalars['String']>;
  DekraLno?: Maybe<Scalars['String']>;
  SentToDekra?: Maybe<Scalars['Boolean']>;
  CustomerRef?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  CustomerPhone?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CurrentPlacement?: Maybe<Scalars['String']>;
  ActualPlacement?: Maybe<Scalars['String']>;
  ObjStatus?: Maybe<Scalars['Boolean']>;
  InspectionMonth?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectInput>>;
  NOT?: Maybe<FilterObjectInput>;
};

export type FilterObjectReturnHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  ReturnType?: Maybe<Scalars['Float']>;
  ReturnTypeOPTION?: Maybe<Scalars['String']>;
  OffRentNo?: Maybe<Scalars['String']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  OffRentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDay?: Maybe<Scalars['Boolean']>;
  ReturnQuantity?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  QuantitytoSell?: Maybe<Scalars['Float']>;
  QuantitytoDamage?: Maybe<Scalars['Float']>;
  ReceivingLocation?: Maybe<Scalars['String']>;
  CounteronDelivery?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  Counter2onDelivery?: Maybe<Scalars['Float']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  ReturnQuantityTime?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ChargeCustomer?: Maybe<Scalars['Boolean']>;
  CurrentPlacement?: Maybe<Scalars['String']>;
  EdgePost?: Maybe<Scalars['Boolean']>;
  OffRentUpdated?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  ReturnNo?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ExtDocNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  DeletedManually?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqRefill?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedbyUserID?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  MotherLineRegUpdateLine?: Maybe<Scalars['Boolean']>;
  MotherLineType?: Maybe<Scalars['Float']>;
  MotherLineTypeOPTION?: Maybe<Scalars['String']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  SubmittedByUserName?: Maybe<Scalars['String']>;
  SubmittedByUserEmail?: Maybe<Scalars['String']>;
  PushNotificationSent?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectReturnHeaderInput>>;
  NOT?: Maybe<FilterObjectReturnHeaderInput>;
};

export type FilterObjectReturnLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  ReturnNo?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ExtDocNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  DeletedManually?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqRefill?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedbyUserID?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  MotherLineRegUpdateLine?: Maybe<Scalars['Boolean']>;
  MotherLineType?: Maybe<Scalars['Float']>;
  MotherLineTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectReturnLineInput>>;
  NOT?: Maybe<FilterObjectReturnLineInput>;
};

export type FilterObjectTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  CounterType?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  SaleDiscGroup?: Maybe<Scalars['String']>;
  RentalDiscGroup?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SalesPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['String']>;
  WebPage?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  FixedCostPrice?: Maybe<Scalars['Float']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RetentionLevel?: Maybe<Scalars['Float']>;
  TaxDepreciationMethod?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  CounterType2?: Maybe<Scalars['String']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  RentalReservationsQty?: Maybe<Scalars['Float']>;
  RentalDeliveriesQty?: Maybe<Scalars['Float']>;
  ExpectedRentalReturnsQty?: Maybe<Scalars['Float']>;
  RentalReturnsQty?: Maybe<Scalars['Float']>;
  PictureURL?: Maybe<Scalars['String']>;
  PSIDocumentURL?: Maybe<Scalars['String']>;
  NotAvailableForReservation?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterObjectTypeInput>>;
  NOT?: Maybe<FilterObjectTypeInput>;
};

export type FilterOcrAnalysisInput = {
  discriminator?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  textAngle?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterOcrAnalysisInput>>;
  NOT?: Maybe<FilterOcrAnalysisInput>;
};

export type FilterPaymentMethodInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  DirectDebit?: Maybe<Scalars['Boolean']>;
  DirectDebitPmtTermsCode?: Maybe<Scalars['String']>;
  PmtExportLineDefinition?: Maybe<Scalars['String']>;
  BankDataConversionPmtType?: Maybe<Scalars['String']>;
  UseforInvoicing?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  AMCBankPmtType?: Maybe<Scalars['String']>;
  EQMCashPayment?: Maybe<Scalars['Boolean']>;
  EQMPaymentType?: Maybe<Scalars['Float']>;
  EQMPaymentTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPaymentMethodInput>>;
  NOT?: Maybe<FilterPaymentMethodInput>;
};

export type FilterPaymentTermsInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  DueDateCalculation?: Maybe<Scalars['String']>;
  DiscountDateCalculation?: Maybe<Scalars['String']>;
  DiscountPercent?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CalcPmtDisconCrMemos?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPaymentTermsInput>>;
  NOT?: Maybe<FilterPaymentTermsInput>;
};

export type FilterPlatformFeatureInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  basic?: Maybe<Scalars['Boolean']>;
  premium?: Maybe<Scalars['Boolean']>;
  sorting?: Maybe<Scalars['Float']>;
  enterprise?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPlatformFeatureInput>>;
  NOT?: Maybe<FilterPlatformFeatureInput>;
};

export type FilterPostCodeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  SearchCity?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPostCodeInput>>;
  NOT?: Maybe<FilterPostCodeInput>;
};

export type FilterProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  CreationDate?: Maybe<Scalars['DateTime']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  PersonResponsible?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  JobPostingGroup?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  WIPMethod?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  WIPPostingDate?: Maybe<Scalars['DateTime']>;
  InvoiceCurrencyCode?: Maybe<Scalars['String']>;
  ExchCalculationCost?: Maybe<Scalars['Float']>;
  ExchCalculationCostOPTION?: Maybe<Scalars['String']>;
  ExchCalculationPrice?: Maybe<Scalars['Float']>;
  ExchCalculationPriceOPTION?: Maybe<Scalars['String']>;
  AllowScheduleContractLines?: Maybe<Scalars['Boolean']>;
  Complete?: Maybe<Scalars['Boolean']>;
  ApplyUsageLink?: Maybe<Scalars['Boolean']>;
  WIPPostingMethod?: Maybe<Scalars['Float']>;
  WIPPostingMethodOPTION?: Maybe<Scalars['String']>;
  OverBudget?: Maybe<Scalars['Boolean']>;
  ProjectManager?: Maybe<Scalars['String']>;
  BusinessArea?: Maybe<Scalars['String']>;
  Inactive?: Maybe<Scalars['Boolean']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  ResourceFilter?: Maybe<Scalars['String']>;
  PostingDateFilter?: Maybe<Scalars['String']>;
  ResourceGrFilter?: Maybe<Scalars['String']>;
  PlanningDateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ScheduledResQty?: Maybe<Scalars['Float']>;
  ScheduledResGrQty?: Maybe<Scalars['Float']>;
  TotalWIPCostAmount?: Maybe<Scalars['Float']>;
  TotalWIPCostGLAmount?: Maybe<Scalars['Float']>;
  WIPEntriesExist?: Maybe<Scalars['Boolean']>;
  WIPGLPostingDate?: Maybe<Scalars['DateTime']>;
  RecogSalesAmount?: Maybe<Scalars['Float']>;
  RecogSalesGLAmount?: Maybe<Scalars['Float']>;
  RecogCostsAmount?: Maybe<Scalars['Float']>;
  RecogCostsGLAmount?: Maybe<Scalars['Float']>;
  TotalWIPSalesAmount?: Maybe<Scalars['Float']>;
  TotalWIPSalesGLAmount?: Maybe<Scalars['Float']>;
  WIPCompletionCalculated?: Maybe<Scalars['Boolean']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  WIPWarnings?: Maybe<Scalars['Boolean']>;
  AppliedCostsGLAmount?: Maybe<Scalars['Float']>;
  AppliedSalesGLAmount?: Maybe<Scalars['Float']>;
  CalcRecogSalesAmount?: Maybe<Scalars['Float']>;
  CalcRecogCostsAmount?: Maybe<Scalars['Float']>;
  CalcRecogSalesGLAmount?: Maybe<Scalars['Float']>;
  CalcRecogCostsGLAmount?: Maybe<Scalars['Float']>;
  WIPCompletionPosted?: Maybe<Scalars['Boolean']>;
  MonthlyValue?: Maybe<Scalars['Float']>;
  EstimatedValue?: Maybe<Scalars['Float']>;
  LinkedTo?: Maybe<Scalars['String']>;
  JobTaskType?: Maybe<Scalars['String']>;
  SyncJobTastTypeWithDevops?: Maybe<Scalars['Boolean']>;
  DevopsTeamId?: Maybe<Scalars['String']>;
  DevopsProjectId?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterProjectInput>>;
  NOT?: Maybe<FilterProjectInput>;
};

export type FilterPurchCrMemoHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VendorCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepaymentCreditMemo?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  Paid?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  VendorLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ReturnOrderNo?: Maybe<Scalars['String']>;
  ReturnOrderNoSeries?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchCrMemoHeaderInput>>;
  NOT?: Maybe<FilterPurchCrMemoHeaderInput>;
};

export type FilterPurchCrMemoLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowCrMemooiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  CrMemoDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchCrMemoLineInput>>;
  NOT?: Maybe<FilterPurchCrMemoLineInput>;
};

export type FilterPurchInvHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorInvoiceNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  VendorLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  DraftInvoiceSystemId?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMerviceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMObjWorkPurchFlow?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchInvHeaderInput>>;
  NOT?: Maybe<FilterPurchInvHeaderInput>;
};

export type FilterPurchInvLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceiptLineNo?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICCrossReferenceNo?: Maybe<Scalars['String']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchInvLineInput>>;
  NOT?: Maybe<FilterPurchInvLineInput>;
};

export type FilterPurchRcptHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorShipmentNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactno?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchRcptHeaderInput>>;
  NOT?: Maybe<FilterPurchRcptHeaderInput>;
};

export type FilterPurchRcptLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ItemRcptEntryNo?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  SalesOrderNo?: Maybe<Scalars['String']>;
  SalesOrderLineNo?: Maybe<Scalars['Float']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrderSalesNo?: Maybe<Scalars['String']>;
  SpecialOrderSalesLineNo?: Maybe<Scalars['Float']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedReceiptDate?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  ItemChargeBaseAmount?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
  OverReceiptQuantity?: Maybe<Scalars['Float']>;
  OverReceiptCode?: Maybe<Scalars['String']>;
  OverReceiptCode2?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchRcptLineInput>>;
  NOT?: Maybe<FilterPurchRcptLineInput>;
};

export type FilterPurchaseHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<PurchaseHeaderDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Receive?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  ReceivingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastReceivingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorShipmentNo?: Maybe<Scalars['String']>;
  VendorInvoiceNo?: Maybe<Scalars['String']>;
  VendorCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ReceivingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  BuyfromICPartnerCode?: Maybe<Scalars['String']>;
  PaytoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  JournalTemplName?: Maybe<Scalars['String']>;
  ARcdNotInvExVATLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PartiallyInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  VendorAuthorizationNo?: Maybe<Scalars['String']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentNoSeries?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  LastReturnShipmentNo?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  PendingApprovals?: Maybe<Scalars['Float']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBGrossInvoiceAmount?: Maybe<Scalars['Float']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  PEBInwardRegistrated?: Maybe<Scalars['Boolean']>;
  PEBOCRNo?: Maybe<Scalars['String']>;
  PEBRecipientBankAccount?: Maybe<Scalars['String']>;
  PEBOfwhichVATAmount?: Maybe<Scalars['Float']>;
  PEBInwardVAT?: Maybe<Scalars['Float']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMObjWorkPurchFlow?: Maybe<Scalars['Float']>;
  EQMRentalShipmentNo?: Maybe<Scalars['String']>;
  EQMRentalCollectionNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  EQMPostedRentalShipmentNo?: Maybe<Scalars['String']>;
  EQMPostedRentalCollNo?: Maybe<Scalars['String']>;
  EQMControlTypeCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchaseHeaderInput>>;
  NOT?: Maybe<FilterPurchaseHeaderInput>;
};

export type FilterPurchaseLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<PurchaseLineDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoReceive?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoiced?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityReceived?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceiptLineNo?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  SalesOrderNo?: Maybe<Scalars['String']>;
  SalesOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  OutstandingAmtExVATLCY?: Maybe<Scalars['Float']>;
  ARcdNotInvExVATLCY?: Maybe<Scalars['Float']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  PrepmtPmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscount?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  JobPlanningLineNo?: Maybe<Scalars['Float']>;
  JobRemainingQty?: Maybe<Scalars['Float']>;
  JobRemainingQtyBase?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QtyRoundingPrecision?: Maybe<Scalars['Float']>;
  QtyRoundingPrecisionBase?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoReceiveBase?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoicedBase?: Maybe<Scalars['Float']>;
  QtyReceivedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderSalesNo?: Maybe<Scalars['String']>;
  SpecialOrderSalesLineNo?: Maybe<Scalars['Float']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedReceiptDate?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  ReturnQtytoShip?: Maybe<Scalars['Float']>;
  ReturnQtytoShipBase?: Maybe<Scalars['Float']>;
  ReturnQtyShippedNotInvd?: Maybe<Scalars['Float']>;
  RetQtyShpdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnShpdNotInvd?: Maybe<Scalars['Float']>;
  ReturnShpdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyShipped?: Maybe<Scalars['Float']>;
  ReturnQtyShippedBase?: Maybe<Scalars['Float']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  CopiedFromPostedDoc?: Maybe<Scalars['Boolean']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AttachedDocCount?: Maybe<Scalars['Float']>;
  OverReceiptQuantity?: Maybe<Scalars['Float']>;
  OverReceiptCode?: Maybe<Scalars['String']>;
  OverReceiptApprovalStatus?: Maybe<Scalars['Float']>;
  OverReceiptApprovalStatusOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  NBAPreAssignedObjectNo?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  PEBIsDescription2?: Maybe<Scalars['Boolean']>;
  PEBNotincludedincalc?: Maybe<Scalars['Boolean']>;
  PEBNotincludedinVATcalc?: Maybe<Scalars['Boolean']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMType?: Maybe<Scalars['Float']>;
  EQMTypeOPTION?: Maybe<Scalars['String']>;
  EQMNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMORIGPurchLineNo?: Maybe<Scalars['Float']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  EQMAllocateManufacturerCode?: Maybe<Scalars['String']>;
  EQMAllocateManuModelCode?: Maybe<Scalars['String']>;
  EQMItemtoObjectFlow?: Maybe<Scalars['Float']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  Finished?: Maybe<Scalars['Boolean']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  MPSOrder?: Maybe<Scalars['Boolean']>;
  PlanningFlexibility?: Maybe<Scalars['Float']>;
  PlanningFlexibilityOPTION?: Maybe<Scalars['String']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchaseLineInput>>;
  NOT?: Maybe<FilterPurchaseLineInput>;
};

export type FilterPurchasingInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterPurchasingInput>>;
  NOT?: Maybe<FilterPurchasingInput>;
};

export type FilterQuoteHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  SalesPerson?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  ExternalText?: Maybe<Scalars['String']>;
  InternalText?: Maybe<Scalars['String']>;
  ProjectSum?: Maybe<Scalars['Float']>;
  LicenseSum?: Maybe<Scalars['Float']>;
  LicenseFee?: Maybe<Scalars['Float']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterQuoteHeaderInput>>;
  NOT?: Maybe<FilterQuoteHeaderInput>;
};

export type FilterQuoteLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Task?: Maybe<Scalars['String']>;
  TaskGrouping?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  Sum?: Maybe<Scalars['Float']>;
  InvoicedSum?: Maybe<Scalars['Float']>;
  OnOrderSum?: Maybe<Scalars['Float']>;
  LinkedTo?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterQuoteLineInput>>;
  NOT?: Maybe<FilterQuoteLineInput>;
};

export type FilterQuoteTaskGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Group?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterQuoteTaskGroupInput>>;
  NOT?: Maybe<FilterQuoteTaskGroupInput>;
};

export type FilterRentalHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ChangeStatus?: Maybe<Scalars['Float']>;
  ChangeStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  BreakInvoiceatExpDate?: Maybe<Scalars['Boolean']>;
  Printed?: Maybe<Scalars['String']>;
  PrintedTime?: Maybe<Scalars['String']>;
  InvoiceFrequencyNOTINUSE?: Maybe<Scalars['Float']>;
  InvoiceFrequencyNOTINUSEOPTION?: Maybe<Scalars['String']>;
  LastInvoiceDate?: Maybe<Scalars['String']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ContractDate?: Maybe<Scalars['DateTime']>;
  StartRentalPeriodDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  TermofContract?: Maybe<Scalars['String']>;
  AnnualAmount?: Maybe<Scalars['Float']>;
  AmountperPeriod?: Maybe<Scalars['Float']>;
  CombineInvoices?: Maybe<Scalars['Boolean']>;
  Prepaid?: Maybe<Scalars['Boolean']>;
  NextInvoicePeriod?: Maybe<Scalars['String']>;
  RentalZoneCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CancelReasonCode?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  QuoteType?: Maybe<Scalars['Float']>;
  QuoteTypeOPTION?: Maybe<Scalars['String']>;
  ContractGroupCode?: Maybe<Scalars['String']>;
  RentalOrderType?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  TemplateNo?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Probability?: Maybe<Scalars['Float']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  NextInvoicePeriodStart?: Maybe<Scalars['DateTime']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['DateTime']>;
  FromQuoteNo?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  TempInvoiceDate?: Maybe<Scalars['DateTime']>;
  TermCode?: Maybe<Scalars['String']>;
  Contractterminatedcomplete?: Maybe<Scalars['Boolean']>;
  NextRentalInvoiceFrom?: Maybe<Scalars['String']>;
  NextRentalInvoiceTo?: Maybe<Scalars['String']>;
  TempReservation?: Maybe<Scalars['Boolean']>;
  TempDelivery?: Maybe<Scalars['Boolean']>;
  TempInvoice?: Maybe<Scalars['Boolean']>;
  TempDocumentType?: Maybe<Scalars['Float']>;
  TempDocumentTypeOPTION?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  InvoicingStatus?: Maybe<Scalars['Float']>;
  InvoicingStatusOPTION?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  SalesHeaderNoSale?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['String']>;
  DoNotShowDialogPrompt?: Maybe<Scalars['Boolean']>;
  RentalStartingFee?: Maybe<Scalars['String']>;
  TempStartRentalPeriod?: Maybe<Scalars['String']>;
  FrequenceFixDaysNOTINUSE?: Maybe<Scalars['Float']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  InvoiceSequence?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  LocationManagerContactNo?: Maybe<Scalars['String']>;
  ProjectManagerContactNo?: Maybe<Scalars['String']>;
  DeliveryContactNo?: Maybe<Scalars['String']>;
  FixedPriceContract?: Maybe<Scalars['Boolean']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CustomerDepositCode?: Maybe<Scalars['String']>;
  CustomerDepositAmttoPay?: Maybe<Scalars['Float']>;
  GrantedDeposit?: Maybe<Scalars['Float']>;
  RentalInvoiceDayofMonth?: Maybe<Scalars['Float']>;
  CustomerProject?: Maybe<Scalars['String']>;
  MemoText?: Maybe<Scalars['String']>;
  JournalEntryNo?: Maybe<Scalars['Float']>;
  UseRentalJnlNos?: Maybe<Scalars['Boolean']>;
  CustomerType?: Maybe<Scalars['Float']>;
  CustomerTypeOPTION?: Maybe<Scalars['String']>;
  FullyReturned?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  InvoiceDocType?: Maybe<Scalars['Float']>;
  InvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  RentalAmountExclVat?: Maybe<Scalars['Float']>;
  RentalLineDiscAmount?: Maybe<Scalars['Float']>;
  RentalTotalAmountExclVat?: Maybe<Scalars['Float']>;
  RentalAmountInclVat?: Maybe<Scalars['Float']>;
  RentalVatAmount?: Maybe<Scalars['Float']>;
  SalesAmountExclVat?: Maybe<Scalars['Float']>;
  SalesLineDiscAmount?: Maybe<Scalars['Float']>;
  SalesTotalAmountExclVat?: Maybe<Scalars['Float']>;
  SalesAmountInclVat?: Maybe<Scalars['Float']>;
  SalesVatAmount?: Maybe<Scalars['Float']>;
  SalesProfit?: Maybe<Scalars['Float']>;
  SalesQtyToInvoice?: Maybe<Scalars['Float']>;
  RentalInsurancAmtExclVat?: Maybe<Scalars['Float']>;
  RentalInsurancAmtInclVat?: Maybe<Scalars['Float']>;
  RentalContractCost?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtDocType?: Maybe<Scalars['Float']>;
  PrepmtDocTypeOPTION?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  UsePrepmtExpirationDate?: Maybe<Scalars['Boolean']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenter?: Maybe<Scalars['String']>;
  QuoteExpiryDate?: Maybe<Scalars['DateTime']>;
  QuoteStatus?: Maybe<Scalars['Float']>;
  QuoteStatusOPTION?: Maybe<Scalars['String']>;
  CustomerPONo?: Maybe<Scalars['String']>;
  NextInvoiceDueDate?: Maybe<Scalars['DateTime']>;
  TypeFilter?: Maybe<Scalars['Float']>;
  TypeFilterOPTION?: Maybe<Scalars['String']>;
  ReasonCodeFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ContractLineAmount?: Maybe<Scalars['Float']>;
  NoofPostedInvoices?: Maybe<Scalars['Float']>;
  NoofUnpostedInvoices?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ContractInvoiceAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  NoofPickOrder?: Maybe<Scalars['Float']>;
  NoofShipmentOrder?: Maybe<Scalars['Float']>;
  NoofCollectionOrder?: Maybe<Scalars['Float']>;
  NoofWorkOrder?: Maybe<Scalars['Float']>;
  LocationManagerName?: Maybe<Scalars['String']>;
  ProjectManagerName?: Maybe<Scalars['String']>;
  DeliveryContactName?: Maybe<Scalars['String']>;
  CustomerDepositAmount?: Maybe<Scalars['Float']>;
  CustomerDepositOutstAmt?: Maybe<Scalars['Float']>;
  RentalValue?: Maybe<Scalars['Float']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CustOrganizationNo?: Maybe<Scalars['String']>;
  TransactionNo?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  PrepmtPaidAmount?: Maybe<Scalars['Float']>;
  PrepmtUnpaidAmount?: Maybe<Scalars['Float']>;
  PrepmtPaidAmountInclVAT?: Maybe<Scalars['Float']>;
  PrepmtUnpaidAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmt?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalHeaderInput>>;
  NOT?: Maybe<FilterRentalHeaderInput>;
};

export type FilterRentalLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ContractStatus?: Maybe<Scalars['Float']>;
  ContractStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['DateTime']>;
  EndRentalPeriod?: Maybe<Scalars['DateTime']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  NextInvoicePeriodStart?: Maybe<Scalars['String']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['String']>;
  RentalPeriodInvoiced?: Maybe<Scalars['Float']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  Credited?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  EntryStatus?: Maybe<Scalars['Float']>;
  EntryStatusOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  RentalPeriodNow?: Maybe<Scalars['Float']>;
  RentalChargeDays?: Maybe<Scalars['Float']>;
  RentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DeliveredTime?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  ReturnCompleted?: Maybe<Scalars['Boolean']>;
  TransferdToOrder?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['String']>;
  UnitPriceSale?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  ReservedSeasonCalendar?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  BinCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  WorkInprogress?: Maybe<Scalars['Boolean']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PctofSalesPrice?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtUseExpirationDate?: Maybe<Scalars['Boolean']>;
  PrepmtSalesLineNo?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatus?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtCrMemoStatus?: Maybe<Scalars['Float']>;
  PrepmtCrMemoStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtInsuranceAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrderInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeductVAT?: Maybe<Scalars['Float']>;
  PrepmtAmtDeductedVAT?: Maybe<Scalars['Float']>;
  PrepmtRentalLineNo?: Maybe<Scalars['Float']>;
  PrepmtInsuranceToDeduct?: Maybe<Scalars['Float']>;
  PrepmtInsuranceDeducted?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsAmtOrderIncVAT?: Maybe<Scalars['Float']>;
  PrepmtDimensionSetID?: Maybe<Scalars['Float']>;
  PrepmtVATBusPostingGroup?: Maybe<Scalars['String']>;
  PrepmtVATProdPostingGrp?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  OldAttachedtoLineNo?: Maybe<Scalars['Float']>;
  AttachedtoSwitchLineNo?: Maybe<Scalars['Float']>;
  AttachedStandByLineNo?: Maybe<Scalars['Float']>;
  StandbyStatus?: Maybe<Scalars['Float']>;
  StandbyStatusOPTION?: Maybe<Scalars['String']>;
  QtyonAttachedtoLineNo?: Maybe<Scalars['Float']>;
  KITMother?: Maybe<Scalars['String']>;
  KITFactor?: Maybe<Scalars['Float']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  KITFactorPrice?: Maybe<Scalars['Float']>;
  KITOriginalPrice?: Maybe<Scalars['Float']>;
  KITQuantity?: Maybe<Scalars['Float']>;
  KITLineAmountNet?: Maybe<Scalars['Float']>;
  KITLineAmountGross?: Maybe<Scalars['Float']>;
  KITOriginalSqMeter?: Maybe<Scalars['Float']>;
  SquareMeterperKIT?: Maybe<Scalars['Float']>;
  KITPriceperSqMeter?: Maybe<Scalars['Float']>;
  KITChange?: Maybe<Scalars['Float']>;
  TotalKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPriceMarked?: Maybe<Scalars['Boolean']>;
  DefaultKITFactorPrice?: Maybe<Scalars['Float']>;
  KITComponentUnitPrice?: Maybe<Scalars['Float']>;
  KITComponentRentalPrice?: Maybe<Scalars['Float']>;
  KITLineDiscount?: Maybe<Scalars['Float']>;
  KITLineDiscountAmount?: Maybe<Scalars['Float']>;
  OldTmplMotherLineNo?: Maybe<Scalars['Float']>;
  OnlyonFirstOrderInvoice?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  ReturnDateBuffer?: Maybe<Scalars['String']>;
  OffRentDateBuffer?: Maybe<Scalars['String']>;
  ReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  OffRentQuantityBuffer?: Maybe<Scalars['Float']>;
  DebitonReturnDateBuffer?: Maybe<Scalars['Boolean']>;
  CounteronReturnBuffer?: Maybe<Scalars['Float']>;
  ReturnReminderBuffer?: Maybe<Scalars['Float']>;
  UpdateLineBuffer?: Maybe<Scalars['Boolean']>;
  ReturnNoBuffer?: Maybe<Scalars['String']>;
  ReturnPoleSplitBuffer?: Maybe<Scalars['Boolean']>;
  ReturnCompleteBuffer?: Maybe<Scalars['Boolean']>;
  InvoicedToDateBuffer?: Maybe<Scalars['String']>;
  ReceiveLocationCodeBuffer?: Maybe<Scalars['String']>;
  ReturnTimeBuffer?: Maybe<Scalars['String']>;
  DepositAmountCardBuffer?: Maybe<Scalars['Float']>;
  DepositAmountCashBuffer?: Maybe<Scalars['Float']>;
  ReclassQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassDateBuffer?: Maybe<Scalars['String']>;
  CorrFromDateBuffer?: Maybe<Scalars['String']>;
  CorrInvToDateBuffer?: Maybe<Scalars['String']>;
  CorrReturnDateBuffer?: Maybe<Scalars['String']>;
  CorrCloseRentalBuffer?: Maybe<Scalars['Float']>;
  CorrCloseRentalBufferOPTION?: Maybe<Scalars['String']>;
  CorrUpdEntryBasisBuffer?: Maybe<Scalars['Boolean']>;
  CorrQuantityBuffer?: Maybe<Scalars['Float']>;
  CorrReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassFromContractBuffer?: Maybe<Scalars['Boolean']>;
  ReclassToContractBuffer?: Maybe<Scalars['Boolean']>;
  MaintenanceBuffer?: Maybe<Scalars['Boolean']>;
  DepositAmountTnsfBuffer?: Maybe<Scalars['Float']>;
  OffRentTimeBuffer?: Maybe<Scalars['String']>;
  OffRentNoBuffer?: Maybe<Scalars['String']>;
  CorrQtyShippedtoBuffer?: Maybe<Scalars['Float']>;
  TempEntryBuffer?: Maybe<Scalars['Boolean']>;
  ReturntoSellBuffer?: Maybe<Scalars['Float']>;
  ReturntoDamageBuffer?: Maybe<Scalars['Float']>;
  Counter2onReturnBuffer?: Maybe<Scalars['Float']>;
  CorrOnRentDateBuffer?: Maybe<Scalars['String']>;
  CorrNextInvDateBuffer?: Maybe<Scalars['String']>;
  CollectionDocNoBuffer?: Maybe<Scalars['String']>;
  CollectionLineNoBuffer?: Maybe<Scalars['Float']>;
  SourceCodeBuffer?: Maybe<Scalars['String']>;
  DeliverQuantityNowBuffer?: Maybe<Scalars['Float']>;
  ShipmentDateBuffer?: Maybe<Scalars['String']>;
  OnRentDateBuffer?: Maybe<Scalars['String']>;
  LocationCodeBuffer?: Maybe<Scalars['String']>;
  ConsumptionatReturnBuffer?: Maybe<Scalars['Float']>;
  CreditonEarlyBuffer?: Maybe<Scalars['Float']>;
  StartRentalPeriodBuffer?: Maybe<Scalars['String']>;
  LineNoBuffer?: Maybe<Scalars['Float']>;
  ConsumpatDeliveryBuffer?: Maybe<Scalars['Float']>;
  DepositAmountBackBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBufferOPTION?: Maybe<Scalars['String']>;
  PstDispatchDocNoBuffer?: Maybe<Scalars['String']>;
  PstDispatchLineNoBuffer?: Maybe<Scalars['Float']>;
  PostedInDocumentBuffer?: Maybe<Scalars['Boolean']>;
  EQMServiceDocBuffer?: Maybe<Scalars['String']>;
  UnitPriceBuffer?: Maybe<Scalars['Float']>;
  LineDiscountBuffer?: Maybe<Scalars['Float']>;
  LineMarkedBuffer?: Maybe<Scalars['Boolean']>;
  PostingDateBuffer?: Maybe<Scalars['String']>;
  UseInOpenPeriodBuffer?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNoBuffer?: Maybe<Scalars['Float']>;
  CounteronShipmentBuffer?: Maybe<Scalars['Float']>;
  Counter2onShipmentBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeDaysBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeHourBuffer?: Maybe<Scalars['Float']>;
  ReasonforRentFreeBuffer?: Maybe<Scalars['String']>;
  ReceiveRespCenterBuffer?: Maybe<Scalars['String']>;
  FullyConsumedAccBuffer?: Maybe<Scalars['Boolean']>;
  ReceivingBinCodeBuffer?: Maybe<Scalars['String']>;
  SuspendLineNoBuffer?: Maybe<Scalars['Boolean']>;
  UnitPriceNew?: Maybe<Scalars['Float']>;
  PriceTermCodeNew?: Maybe<Scalars['String']>;
  UnitPriceprDayNew?: Maybe<Scalars['Float']>;
  UnitPriceprMonthNew?: Maybe<Scalars['Float']>;
  LineAmountNew?: Maybe<Scalars['Float']>;
  RentalPeriodNew?: Maybe<Scalars['Float']>;
  LineDiscountNew?: Maybe<Scalars['Float']>;
  LineDiscountAmountNew?: Maybe<Scalars['Float']>;
  StairPriceCodeNew?: Maybe<Scalars['String']>;
  StairDiscountCodeNew?: Maybe<Scalars['String']>;
  BaseCalendarCodeNew?: Maybe<Scalars['String']>;
  StairPrice1New?: Maybe<Scalars['Float']>;
  StairPrice2New?: Maybe<Scalars['Float']>;
  StairPrice3New?: Maybe<Scalars['Float']>;
  StairPrice4New?: Maybe<Scalars['Float']>;
  StairPrice5New?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  Preliminary?: Maybe<Scalars['Boolean']>;
  ReferenceTableID?: Maybe<Scalars['Float']>;
  ReferenceUniqueID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  ReplacementUnitPrice?: Maybe<Scalars['Float']>;
  ReRentObject?: Maybe<Scalars['Boolean']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  UnitPriceprDay?: Maybe<Scalars['Float']>;
  UnitPriceprMonth?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  DepositOrigUnitPrice?: Maybe<Scalars['Float']>;
  MarkDepositEntry?: Maybe<Scalars['Boolean']>;
  DepositUnitPrice?: Maybe<Scalars['Float']>;
  DepositPostingNo?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  DepositReturnPostingNo?: Maybe<Scalars['String']>;
  AgreedToDate?: Maybe<Scalars['String']>;
  DepositPostingType?: Maybe<Scalars['Float']>;
  DepositPostingTypeOPTION?: Maybe<Scalars['String']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  DeliveredBy?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  SaleDocumentNo?: Maybe<Scalars['String']>;
  SaleDocumentType?: Maybe<Scalars['Float']>;
  SaleDocumentTypeOPTION?: Maybe<Scalars['String']>;
  SaleLineNo?: Maybe<Scalars['Float']>;
  SaleReserve?: Maybe<Scalars['Float']>;
  SaleReserveOPTION?: Maybe<Scalars['String']>;
  ReturnQuantityHours?: Maybe<Scalars['Float']>;
  ShipmentDateTime?: Maybe<Scalars['String']>;
  EndRentalDateTime?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['String']>;
  DoShipmentNow?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  OnRentTime?: Maybe<Scalars['String']>;
  OffRentTime?: Maybe<Scalars['String']>;
  OnRentDateTime?: Maybe<Scalars['String']>;
  OffRentDateTime?: Maybe<Scalars['String']>;
  ReturnQuantityChargeHours?: Maybe<Scalars['Float']>;
  TerminPeriod?: Maybe<Scalars['Float']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  IgnorCalendarCheck?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondMotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeMotherRef?: Maybe<Scalars['Float']>;
  ReturnAdditionMotherRef?: Maybe<Scalars['Float']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  ExtraHoursReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  TmpAccumEntry?: Maybe<Scalars['Boolean']>;
  MarkReclassLineNoRef?: Maybe<Scalars['Float']>;
  MarkReclassQuantity?: Maybe<Scalars['Float']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  ReclassFromLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderedBy?: Maybe<Scalars['String']>;
  MarkReclassLine?: Maybe<Scalars['Boolean']>;
  FilterReclassLine?: Maybe<Scalars['Boolean']>;
  ReclassFromContractNo?: Maybe<Scalars['String']>;
  ReclassToContractNo?: Maybe<Scalars['String']>;
  ReclassToLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderNo?: Maybe<Scalars['String']>;
  ReclassDocNo?: Maybe<Scalars['String']>;
  WorkshopObjectNo?: Maybe<Scalars['String']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  ExploreLine?: Maybe<Scalars['Float']>;
  EnvironmentalFee?: Maybe<Scalars['Boolean']>;
  ExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  ExtraHoursReturnNo?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  ReclassDocLineNo?: Maybe<Scalars['Float']>;
  RentalSplitCostCenter?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  RecursiveLevel?: Maybe<Scalars['Float']>;
  Repurchase?: Maybe<Scalars['Boolean']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatus?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatusOPTION?: Maybe<Scalars['String']>;
  CPAdvancePaymentDocNo?: Maybe<Scalars['String']>;
  InvoiceFee?: Maybe<Scalars['Boolean']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StairPriceDiscount?: Maybe<Scalars['Float']>;
  ReceiveRespCenter?: Maybe<Scalars['String']>;
  ReceiveCostCenter?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  ServiceMotherLineNo?: Maybe<Scalars['Float']>;
  AdditionalCharge?: Maybe<Scalars['Boolean']>;
  AdjforAdditionalCharge?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  EQMServiceNoTemp?: Maybe<Scalars['String']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  DispatchTask?: Maybe<Scalars['Float']>;
  DispatchTaskOPTION?: Maybe<Scalars['String']>;
  DispatchStatus?: Maybe<Scalars['Float']>;
  DispatchStatusOPTION?: Maybe<Scalars['String']>;
  ScheduledDeliveryDate?: Maybe<Scalars['String']>;
  ScheduledDeliveryTime?: Maybe<Scalars['String']>;
  ScheduledPickupDate?: Maybe<Scalars['String']>;
  ScheduledPickupTime?: Maybe<Scalars['String']>;
  DeliveryDriver?: Maybe<Scalars['String']>;
  PickupDriver?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  SchedDate?: Maybe<Scalars['String']>;
  ActualDate?: Maybe<Scalars['String']>;
  SchedTime?: Maybe<Scalars['String']>;
  ActualTime?: Maybe<Scalars['String']>;
  ActualDeliveryDate?: Maybe<Scalars['String']>;
  ActualDeliveryTime?: Maybe<Scalars['String']>;
  ActualPickupDate?: Maybe<Scalars['String']>;
  ActualPickupTime?: Maybe<Scalars['String']>;
  ActualReturnDate?: Maybe<Scalars['String']>;
  ActualReturnTime?: Maybe<Scalars['String']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  DispatchDocType?: Maybe<Scalars['Float']>;
  DispatchDocTypeOPTION?: Maybe<Scalars['String']>;
  DispatchDocNo?: Maybe<Scalars['String']>;
  DispatchLineNo?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  UserFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DefaultKITPriceCompFlow?: Maybe<Scalars['Float']>;
  AssignedtoKIT?: Maybe<Scalars['String']>;
  OffRentDateFlow?: Maybe<Scalars['String']>;
  ReturnDateFlow?: Maybe<Scalars['String']>;
  QuantityShippedtoFlow?: Maybe<Scalars['Float']>;
  QuantityReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityPstShippedFlow?: Maybe<Scalars['Float']>;
  QtyonPickOrder?: Maybe<Scalars['Float']>;
  QtyonShipmentOrder?: Maybe<Scalars['Float']>;
  QtyonCollectionOrder?: Maybe<Scalars['Float']>;
  UserLocationFilter?: Maybe<Scalars['String']>;
  NoofWorkOrderLines?: Maybe<Scalars['Float']>;
  NoofPickOrderLines?: Maybe<Scalars['Float']>;
  NoofShipmentOrderLines?: Maybe<Scalars['Float']>;
  NoofCollectionOrderLines?: Maybe<Scalars['Float']>;
  NoofPostedPickOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdShptOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdCollOrderLines?: Maybe<Scalars['Float']>;
  NoofRntlReservSummaries?: Maybe<Scalars['Float']>;
  NoofRntlReservEntries?: Maybe<Scalars['Float']>;
  QtyonRntlReservEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResShptEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResRetEntry?: Maybe<Scalars['Float']>;
  QtyonBufferEntry?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodPrice?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodEntry?: Maybe<Scalars['Float']>;
  LastErrorText?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  SaleReservedQuantity?: Maybe<Scalars['Float']>;
  SaleReservedQtyBase?: Maybe<Scalars['Float']>;
  KITNetWeight?: Maybe<Scalars['Float']>;
  ReportedRentalHours?: Maybe<Scalars['Float']>;
  EQMServiceDocNo?: Maybe<Scalars['String']>;
  DispatchDocument?: Maybe<Scalars['Boolean']>;
  DispatchDocumentNo?: Maybe<Scalars['String']>;
  GroupOnNumber?: Maybe<Scalars['String']>;
  PeriodLineAmountEdge?: Maybe<Scalars['Float']>;
  RentalDays?: Maybe<Scalars['Float']>;
  RemainingQty?: Maybe<Scalars['Float']>;
  FreeDays?: Maybe<Scalars['Float']>;
  DayNetPriceEdge?: Maybe<Scalars['Float']>;
  Vaccation?: Maybe<Scalars['Boolean']>;
  ReturnReservationActive?: Maybe<Scalars['Boolean']>;
  UserExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  ImageUrl?: Maybe<Scalars['String']>;
  PSILink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalLineInput>>;
  NOT?: Maybe<FilterRentalLineInput>;
  StartDateFilter?: Maybe<Scalars['DateTime']>;
  EndDateFilter?: Maybe<Scalars['DateTime']>;
  HasTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterRentalLineSnapshotInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ContractStatus?: Maybe<Scalars['Float']>;
  ContractStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['DateTime']>;
  EndRentalPeriod?: Maybe<Scalars['DateTime']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  NextInvoicePeriodStart?: Maybe<Scalars['String']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['String']>;
  RentalPeriodInvoiced?: Maybe<Scalars['Float']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  Credited?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  EntryStatus?: Maybe<Scalars['Float']>;
  EntryStatusOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  RentalPeriodNow?: Maybe<Scalars['Float']>;
  RentalChargeDays?: Maybe<Scalars['Float']>;
  RentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DeliveredTime?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  ReturnCompleted?: Maybe<Scalars['Boolean']>;
  TransferdToOrder?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['String']>;
  UnitPriceSale?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  ReservedSeasonCalendar?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  BinCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  WorkInprogress?: Maybe<Scalars['Boolean']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PctofSalesPrice?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtUseExpirationDate?: Maybe<Scalars['Boolean']>;
  PrepmtSalesLineNo?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatus?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtCrMemoStatus?: Maybe<Scalars['Float']>;
  PrepmtCrMemoStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtInsuranceAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrderInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeductVAT?: Maybe<Scalars['Float']>;
  PrepmtAmtDeductedVAT?: Maybe<Scalars['Float']>;
  PrepmtRentalLineNo?: Maybe<Scalars['Float']>;
  PrepmtInsuranceToDeduct?: Maybe<Scalars['Float']>;
  PrepmtInsuranceDeducted?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsAmtOrderIncVAT?: Maybe<Scalars['Float']>;
  PrepmtDimensionSetID?: Maybe<Scalars['Float']>;
  PrepmtVATBusPostingGroup?: Maybe<Scalars['String']>;
  PrepmtVATProdPostingGrp?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  OldAttachedtoLineNo?: Maybe<Scalars['Float']>;
  AttachedtoSwitchLineNo?: Maybe<Scalars['Float']>;
  AttachedStandByLineNo?: Maybe<Scalars['Float']>;
  StandbyStatus?: Maybe<Scalars['Float']>;
  StandbyStatusOPTION?: Maybe<Scalars['String']>;
  QtyonAttachedtoLineNo?: Maybe<Scalars['Float']>;
  KITMother?: Maybe<Scalars['String']>;
  KITFactor?: Maybe<Scalars['Float']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  KITFactorPrice?: Maybe<Scalars['Float']>;
  KITOriginalPrice?: Maybe<Scalars['Float']>;
  KITQuantity?: Maybe<Scalars['Float']>;
  KITLineAmountNet?: Maybe<Scalars['Float']>;
  KITLineAmountGross?: Maybe<Scalars['Float']>;
  KITOriginalSqMeter?: Maybe<Scalars['Float']>;
  SquareMeterperKIT?: Maybe<Scalars['Float']>;
  KITPriceperSqMeter?: Maybe<Scalars['Float']>;
  KITChange?: Maybe<Scalars['Float']>;
  TotalKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPriceMarked?: Maybe<Scalars['Boolean']>;
  DefaultKITFactorPrice?: Maybe<Scalars['Float']>;
  KITComponentUnitPrice?: Maybe<Scalars['Float']>;
  KITComponentRentalPrice?: Maybe<Scalars['Float']>;
  KITLineDiscount?: Maybe<Scalars['Float']>;
  KITLineDiscountAmount?: Maybe<Scalars['Float']>;
  OldTmplMotherLineNo?: Maybe<Scalars['Float']>;
  OnlyonFirstOrderInvoice?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  ReturnDateBuffer?: Maybe<Scalars['String']>;
  OffRentDateBuffer?: Maybe<Scalars['String']>;
  ReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  OffRentQuantityBuffer?: Maybe<Scalars['Float']>;
  DebitonReturnDateBuffer?: Maybe<Scalars['Boolean']>;
  CounteronReturnBuffer?: Maybe<Scalars['Float']>;
  ReturnReminderBuffer?: Maybe<Scalars['Float']>;
  UpdateLineBuffer?: Maybe<Scalars['Boolean']>;
  ReturnNoBuffer?: Maybe<Scalars['String']>;
  ReturnPoleSplitBuffer?: Maybe<Scalars['Boolean']>;
  ReturnCompleteBuffer?: Maybe<Scalars['Boolean']>;
  InvoicedToDateBuffer?: Maybe<Scalars['String']>;
  ReceiveLocationCodeBuffer?: Maybe<Scalars['String']>;
  ReturnTimeBuffer?: Maybe<Scalars['String']>;
  DepositAmountCardBuffer?: Maybe<Scalars['Float']>;
  DepositAmountCashBuffer?: Maybe<Scalars['Float']>;
  ReclassQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassDateBuffer?: Maybe<Scalars['String']>;
  CorrFromDateBuffer?: Maybe<Scalars['String']>;
  CorrInvToDateBuffer?: Maybe<Scalars['String']>;
  CorrReturnDateBuffer?: Maybe<Scalars['String']>;
  CorrCloseRentalBuffer?: Maybe<Scalars['Float']>;
  CorrCloseRentalBufferOPTION?: Maybe<Scalars['String']>;
  CorrUpdEntryBasisBuffer?: Maybe<Scalars['Boolean']>;
  CorrQuantityBuffer?: Maybe<Scalars['Float']>;
  CorrReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassFromContractBuffer?: Maybe<Scalars['Boolean']>;
  ReclassToContractBuffer?: Maybe<Scalars['Boolean']>;
  MaintenanceBuffer?: Maybe<Scalars['Boolean']>;
  DepositAmountTnsfBuffer?: Maybe<Scalars['Float']>;
  OffRentTimeBuffer?: Maybe<Scalars['String']>;
  OffRentNoBuffer?: Maybe<Scalars['String']>;
  CorrQtyShippedtoBuffer?: Maybe<Scalars['Float']>;
  TempEntryBuffer?: Maybe<Scalars['Boolean']>;
  ReturntoSellBuffer?: Maybe<Scalars['Float']>;
  ReturntoDamageBuffer?: Maybe<Scalars['Float']>;
  Counter2onReturnBuffer?: Maybe<Scalars['Float']>;
  CorrOnRentDateBuffer?: Maybe<Scalars['String']>;
  CorrNextInvDateBuffer?: Maybe<Scalars['String']>;
  CollectionDocNoBuffer?: Maybe<Scalars['String']>;
  CollectionLineNoBuffer?: Maybe<Scalars['Float']>;
  SourceCodeBuffer?: Maybe<Scalars['String']>;
  DeliverQuantityNowBuffer?: Maybe<Scalars['Float']>;
  ShipmentDateBuffer?: Maybe<Scalars['String']>;
  OnRentDateBuffer?: Maybe<Scalars['String']>;
  LocationCodeBuffer?: Maybe<Scalars['String']>;
  ConsumptionatReturnBuffer?: Maybe<Scalars['Float']>;
  CreditonEarlyBuffer?: Maybe<Scalars['Float']>;
  StartRentalPeriodBuffer?: Maybe<Scalars['String']>;
  LineNoBuffer?: Maybe<Scalars['Float']>;
  ConsumpatDeliveryBuffer?: Maybe<Scalars['Float']>;
  DepositAmountBackBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBufferOPTION?: Maybe<Scalars['String']>;
  PstDispatchDocNoBuffer?: Maybe<Scalars['String']>;
  PstDispatchLineNoBuffer?: Maybe<Scalars['Float']>;
  PostedInDocumentBuffer?: Maybe<Scalars['Boolean']>;
  EQMServiceDocBuffer?: Maybe<Scalars['String']>;
  UnitPriceBuffer?: Maybe<Scalars['Float']>;
  LineDiscountBuffer?: Maybe<Scalars['Float']>;
  LineMarkedBuffer?: Maybe<Scalars['Boolean']>;
  PostingDateBuffer?: Maybe<Scalars['String']>;
  UseInOpenPeriodBuffer?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNoBuffer?: Maybe<Scalars['Float']>;
  CounteronShipmentBuffer?: Maybe<Scalars['Float']>;
  Counter2onShipmentBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeDaysBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeHourBuffer?: Maybe<Scalars['Float']>;
  ReasonforRentFreeBuffer?: Maybe<Scalars['String']>;
  ReceiveRespCenterBuffer?: Maybe<Scalars['String']>;
  FullyConsumedAccBuffer?: Maybe<Scalars['Boolean']>;
  ReceivingBinCodeBuffer?: Maybe<Scalars['String']>;
  SuspendLineNoBuffer?: Maybe<Scalars['Boolean']>;
  UnitPriceNew?: Maybe<Scalars['Float']>;
  PriceTermCodeNew?: Maybe<Scalars['String']>;
  UnitPriceprDayNew?: Maybe<Scalars['Float']>;
  UnitPriceprMonthNew?: Maybe<Scalars['Float']>;
  LineAmountNew?: Maybe<Scalars['Float']>;
  RentalPeriodNew?: Maybe<Scalars['Float']>;
  LineDiscountNew?: Maybe<Scalars['Float']>;
  LineDiscountAmountNew?: Maybe<Scalars['Float']>;
  StairPriceCodeNew?: Maybe<Scalars['String']>;
  StairDiscountCodeNew?: Maybe<Scalars['String']>;
  BaseCalendarCodeNew?: Maybe<Scalars['String']>;
  StairPrice1New?: Maybe<Scalars['Float']>;
  StairPrice2New?: Maybe<Scalars['Float']>;
  StairPrice3New?: Maybe<Scalars['Float']>;
  StairPrice4New?: Maybe<Scalars['Float']>;
  StairPrice5New?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  Preliminary?: Maybe<Scalars['Boolean']>;
  ReferenceTableID?: Maybe<Scalars['Float']>;
  ReferenceUniqueID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  ReplacementUnitPrice?: Maybe<Scalars['Float']>;
  ReRentObject?: Maybe<Scalars['Boolean']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  UnitPriceprDay?: Maybe<Scalars['Float']>;
  UnitPriceprMonth?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  DepositOrigUnitPrice?: Maybe<Scalars['Float']>;
  MarkDepositEntry?: Maybe<Scalars['Boolean']>;
  DepositUnitPrice?: Maybe<Scalars['Float']>;
  DepositPostingNo?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  DepositReturnPostingNo?: Maybe<Scalars['String']>;
  AgreedToDate?: Maybe<Scalars['String']>;
  DepositPostingType?: Maybe<Scalars['Float']>;
  DepositPostingTypeOPTION?: Maybe<Scalars['String']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  DeliveredBy?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  SaleDocumentNo?: Maybe<Scalars['String']>;
  SaleDocumentType?: Maybe<Scalars['Float']>;
  SaleDocumentTypeOPTION?: Maybe<Scalars['String']>;
  SaleLineNo?: Maybe<Scalars['Float']>;
  SaleReserve?: Maybe<Scalars['Float']>;
  SaleReserveOPTION?: Maybe<Scalars['String']>;
  ReturnQuantityHours?: Maybe<Scalars['Float']>;
  ShipmentDateTime?: Maybe<Scalars['String']>;
  EndRentalDateTime?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['String']>;
  DoShipmentNow?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  OnRentTime?: Maybe<Scalars['String']>;
  OffRentTime?: Maybe<Scalars['String']>;
  OnRentDateTime?: Maybe<Scalars['String']>;
  OffRentDateTime?: Maybe<Scalars['String']>;
  ReturnQuantityChargeHours?: Maybe<Scalars['Float']>;
  TerminPeriod?: Maybe<Scalars['Float']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  IgnorCalendarCheck?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondMotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeMotherRef?: Maybe<Scalars['Float']>;
  ReturnAdditionMotherRef?: Maybe<Scalars['Float']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  ExtraHoursReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  TmpAccumEntry?: Maybe<Scalars['Boolean']>;
  MarkReclassLineNoRef?: Maybe<Scalars['Float']>;
  MarkReclassQuantity?: Maybe<Scalars['Float']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  ReclassFromLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderedBy?: Maybe<Scalars['String']>;
  MarkReclassLine?: Maybe<Scalars['Boolean']>;
  FilterReclassLine?: Maybe<Scalars['Boolean']>;
  ReclassFromContractNo?: Maybe<Scalars['String']>;
  ReclassToContractNo?: Maybe<Scalars['String']>;
  ReclassToLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderNo?: Maybe<Scalars['String']>;
  ReclassDocNo?: Maybe<Scalars['String']>;
  WorkshopObjectNo?: Maybe<Scalars['String']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  ExploreLine?: Maybe<Scalars['Float']>;
  EnvironmentalFee?: Maybe<Scalars['Boolean']>;
  ExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  ExtraHoursReturnNo?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  ReclassDocLineNo?: Maybe<Scalars['Float']>;
  RentalSplitCostCenter?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  RecursiveLevel?: Maybe<Scalars['Float']>;
  Repurchase?: Maybe<Scalars['Boolean']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatus?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatusOPTION?: Maybe<Scalars['String']>;
  CPAdvancePaymentDocNo?: Maybe<Scalars['String']>;
  InvoiceFee?: Maybe<Scalars['Boolean']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StairPriceDiscount?: Maybe<Scalars['Float']>;
  ReceiveRespCenter?: Maybe<Scalars['String']>;
  ReceiveCostCenter?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  ServiceMotherLineNo?: Maybe<Scalars['Float']>;
  AdditionalCharge?: Maybe<Scalars['Boolean']>;
  AdjforAdditionalCharge?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  EQMServiceNoTemp?: Maybe<Scalars['String']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  DispatchTask?: Maybe<Scalars['Float']>;
  DispatchTaskOPTION?: Maybe<Scalars['String']>;
  DispatchStatus?: Maybe<Scalars['Float']>;
  DispatchStatusOPTION?: Maybe<Scalars['String']>;
  ScheduledDeliveryDate?: Maybe<Scalars['String']>;
  ScheduledDeliveryTime?: Maybe<Scalars['String']>;
  ScheduledPickupDate?: Maybe<Scalars['String']>;
  ScheduledPickupTime?: Maybe<Scalars['String']>;
  DeliveryDriver?: Maybe<Scalars['String']>;
  PickupDriver?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  SchedDate?: Maybe<Scalars['String']>;
  ActualDate?: Maybe<Scalars['String']>;
  SchedTime?: Maybe<Scalars['String']>;
  ActualTime?: Maybe<Scalars['String']>;
  ActualDeliveryDate?: Maybe<Scalars['String']>;
  ActualDeliveryTime?: Maybe<Scalars['String']>;
  ActualPickupDate?: Maybe<Scalars['String']>;
  ActualPickupTime?: Maybe<Scalars['String']>;
  ActualReturnDate?: Maybe<Scalars['String']>;
  ActualReturnTime?: Maybe<Scalars['String']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  DispatchDocType?: Maybe<Scalars['Float']>;
  DispatchDocTypeOPTION?: Maybe<Scalars['String']>;
  DispatchDocNo?: Maybe<Scalars['String']>;
  DispatchLineNo?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  UserFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DefaultKITPriceCompFlow?: Maybe<Scalars['Float']>;
  AssignedtoKIT?: Maybe<Scalars['String']>;
  OffRentDateFlow?: Maybe<Scalars['String']>;
  ReturnDateFlow?: Maybe<Scalars['String']>;
  QuantityShippedtoFlow?: Maybe<Scalars['Float']>;
  QuantityReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityPstShippedFlow?: Maybe<Scalars['Float']>;
  QtyonPickOrder?: Maybe<Scalars['Float']>;
  QtyonShipmentOrder?: Maybe<Scalars['Float']>;
  QtyonCollectionOrder?: Maybe<Scalars['Float']>;
  UserLocationFilter?: Maybe<Scalars['String']>;
  NoofWorkOrderLines?: Maybe<Scalars['Float']>;
  NoofPickOrderLines?: Maybe<Scalars['Float']>;
  NoofShipmentOrderLines?: Maybe<Scalars['Float']>;
  NoofCollectionOrderLines?: Maybe<Scalars['Float']>;
  NoofPostedPickOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdShptOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdCollOrderLines?: Maybe<Scalars['Float']>;
  NoofRntlReservSummaries?: Maybe<Scalars['Float']>;
  NoofRntlReservEntries?: Maybe<Scalars['Float']>;
  QtyonRntlReservEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResShptEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResRetEntry?: Maybe<Scalars['Float']>;
  QtyonBufferEntry?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodPrice?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodEntry?: Maybe<Scalars['Float']>;
  LastErrorText?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  SaleReservedQuantity?: Maybe<Scalars['Float']>;
  SaleReservedQtyBase?: Maybe<Scalars['Float']>;
  KITNetWeight?: Maybe<Scalars['Float']>;
  ReportedRentalHours?: Maybe<Scalars['Float']>;
  EQMServiceDocNo?: Maybe<Scalars['String']>;
  DispatchDocument?: Maybe<Scalars['Boolean']>;
  DispatchDocumentNo?: Maybe<Scalars['String']>;
  GroupOnNumber?: Maybe<Scalars['String']>;
  PeriodLineAmountEdge?: Maybe<Scalars['Float']>;
  RentalDays?: Maybe<Scalars['Float']>;
  RemainingQty?: Maybe<Scalars['Float']>;
  FreeDays?: Maybe<Scalars['Float']>;
  DayNetPriceEdge?: Maybe<Scalars['Float']>;
  Vaccation?: Maybe<Scalars['Boolean']>;
  ReturnReservationActive?: Maybe<Scalars['Boolean']>;
  UserExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  ImageUrl?: Maybe<Scalars['String']>;
  PSILink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  SnapshotOn?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalLineSnapshotInput>>;
  NOT?: Maybe<FilterRentalLineSnapshotInput>;
};

export type FilterRentalPostedCollHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingHour?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  CollectionStatus?: Maybe<Scalars['Float']>;
  CollectionStatusOPTION?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderDocumentType?: Maybe<Scalars['Float']>;
  OrderDocumentTypeOPTION?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  Requestedby?: Maybe<Scalars['String']>;
  Receivedby?: Maybe<Scalars['String']>;
  ResponsibilityCenterCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  Broker?: Maybe<Scalars['String']>;
  Carrier?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  BOLSignature?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalPostedCollHeaderInput>>;
  NOT?: Maybe<FilterRentalPostedCollHeaderInput>;
};

export type FilterRentalPostedCollLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ExtRefNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  LoadSequenceNo?: Maybe<Scalars['String']>;
  PriceStairNo?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  SerialNo?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  OffRentUpdated?: Maybe<Scalars['Boolean']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  QuantitySold?: Maybe<Scalars['Float']>;
  QuantityDamaged?: Maybe<Scalars['Float']>;
  QuantityOffRented?: Maybe<Scalars['Float']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  EQMServiceID?: Maybe<Scalars['Float']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  MotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  FullyConsumedAccessory?: Maybe<Scalars['Boolean']>;
  ScannedBy?: Maybe<Scalars['String']>;
  ScannedDate?: Maybe<Scalars['DateTime']>;
  ScannedTime?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  PstDeliveryEntryNo?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  ReceivingBinCode?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  AppliedfromDeliverEntryNo?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  Level?: Maybe<Scalars['Float']>;
  LineType?: Maybe<Scalars['Float']>;
  LineTypeOPTION?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['DateTime']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalPostedCollLineInput>>;
  NOT?: Maybe<FilterRentalPostedCollLineInput>;
};

export type FilterRentalPostedShptHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingHour?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderDocumentType?: Maybe<Scalars['Float']>;
  OrderDocumentTypeOPTION?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  ResponsibilityCenterCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  Broker?: Maybe<Scalars['String']>;
  Carrier?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  BOLSignature?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalPostedShptHeaderInput>>;
  NOT?: Maybe<FilterRentalPostedShptHeaderInput>;
};

export type FilterRentalPostedShptLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ExtRefNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  LoadSequenceNo?: Maybe<Scalars['String']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  SerialNo?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  MotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  ScannedBy?: Maybe<Scalars['String']>;
  ScannedDate?: Maybe<Scalars['DateTime']>;
  ScannedTime?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  OrderDocNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  QuantityCollected?: Maybe<Scalars['Float']>;
  CollectionDocNo?: Maybe<Scalars['String']>;
  CollectionLineNo?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['DateTime']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalPostedShptLineInput>>;
  NOT?: Maybe<FilterRentalPostedShptLineInput>;
};

export type FilterRentalPriceTermsInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ChargeFullPeriod?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ReportedHours?: Maybe<Scalars['Boolean']>;
  FixedDays?: Maybe<Scalars['Float']>;
  SquareMeter?: Maybe<Scalars['Boolean']>;
  FullTerm?: Maybe<Scalars['Boolean']>;
  HalfTerm?: Maybe<Scalars['Boolean']>;
  FixedBillingPeriod?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalPriceTermsInput>>;
  NOT?: Maybe<FilterRentalPriceTermsInput>;
};

export type FilterRentalReturnEntryInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  ReturnNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  ExtRentalLineNo?: Maybe<Scalars['Float']>;
  EntryType?: Maybe<Scalars['String']>;
  EntryTypeOPTION?: Maybe<Scalars['String']>;
  ReturnType?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DocumentType?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  PostedinDocument?: Maybe<Scalars['Boolean']>;
  PostingTime?: Maybe<Scalars['String']>;
  TransferredtoOrder?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  OffRentTime?: Maybe<Scalars['String']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  Quantity?: Maybe<Scalars['Float']>;
  RemainingQuantity?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TransactionNo?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  UndoEntry?: Maybe<Scalars['Boolean']>;
  DispatchType?: Maybe<Scalars['String']>;
  PstDispatchDocNo?: Maybe<Scalars['String']>;
  PstDispatchLineNo?: Maybe<Scalars['Float']>;
  OrderDocNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  DoPostReturn?: Maybe<Scalars['Boolean']>;
  DoPostOffRent?: Maybe<Scalars['Boolean']>;
  OrigOffRentDate?: Maybe<Scalars['DateTime']>;
  NoSeries?: Maybe<Scalars['String']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  QuantitySold?: Maybe<Scalars['Float']>;
  QuantityDamaged?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  ReturnQuantityTime?: Maybe<Scalars['Float']>;
  TmpLocationCode?: Maybe<Scalars['String']>;
  AppliestoEntry?: Maybe<Scalars['Float']>;
  CompleteReturn?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ChargeCustomer?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['String']>;
  FullyConsumedAccessory?: Maybe<Scalars['Boolean']>;
  FullReturnSale?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  QtyReserved?: Maybe<Scalars['Float']>;
  QtytoCollect?: Maybe<Scalars['Float']>;
  QuantityReturned?: Maybe<Scalars['Float']>;
  HourlyRent?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  OnRentTime?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  ReceivingBinCode?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  KITComponent?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  QtyPstCollectedFlow?: Maybe<Scalars['Float']>;
  PstdCollOrderDocNo?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  ProjectedReturnDate?: Maybe<Scalars['DateTime']>;
  InvoiceToDate?: Maybe<Scalars['DateTime']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  DiamondQuantity?: Maybe<Scalars['Float']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  RentalDays?: Maybe<Scalars['Float']>;
  MonthPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRentalReturnEntryInput>>;
  NOT?: Maybe<FilterRentalReturnEntryInput>;
};

export type FilterRequestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['Float']>;
  RequestTypeOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  User?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Time?: Maybe<Scalars['String']>;
  DispatchHeader?: Maybe<Scalars['String']>;
  DispatchPosted?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRequestHeaderInput>>;
  NOT?: Maybe<FilterRequestHeaderInput>;
};

export type FilterRequestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['Float']>;
  RequestTypeOPTION?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LineType?: Maybe<Scalars['Float']>;
  LineTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRequestLineInput>>;
  NOT?: Maybe<FilterRequestLineInput>;
};

export type FilterReservationRequestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  DeliveryDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  DomainName?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  Pickup?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterReservationRequestHeaderInput>>;
  NOT?: Maybe<FilterReservationRequestHeaderInput>;
};

export type FilterReservationRequestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  LineGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterReservationRequestLineInput>>;
  NOT?: Maybe<FilterReservationRequestLineInput>;
};

export type FilterResourceInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  SocialSecurityNo?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Education?: Maybe<Scalars['String']>;
  ContractClass?: Maybe<Scalars['String']>;
  EmploymentDate?: Maybe<Scalars['DateTime']>;
  ResourceGroupNo?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['Float']>;
  PriceProfitCalculationOPTION?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  VendorNo?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  AutomaticExtTexts?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ICPartnerPurchGLAccNo?: Maybe<Scalars['String']>;
  UseTimeSheet?: Maybe<Scalars['Boolean']>;
  TimeSheetOwnerUserID?: Maybe<Scalars['String']>;
  TimeSheetApproverUserID?: Maybe<Scalars['String']>;
  ServiceZoneFilter?: Maybe<Scalars['String']>;
  DefaultQuantity?: Maybe<Scalars['Float']>;
  Mounting?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['String']>;
  UnitofMeasureFilter?: Maybe<Scalars['String']>;
  ChargeableFilter?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Capacity?: Maybe<Scalars['Float']>;
  QtyonOrderJob?: Maybe<Scalars['Float']>;
  QtyQuotedJob?: Maybe<Scalars['Float']>;
  UsageQty?: Maybe<Scalars['Float']>;
  UsageCost?: Maybe<Scalars['Float']>;
  UsagePrice?: Maybe<Scalars['Float']>;
  SalesQty?: Maybe<Scalars['Float']>;
  SalesCost?: Maybe<Scalars['Float']>;
  SalesPrice?: Maybe<Scalars['Float']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  InCustomerZone?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ShowInFieldOrder?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterResourceInput>>;
  NOT?: Maybe<FilterResourceInput>;
};

export type FilterResponsibilityCenterInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  EMailSignatureText?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ContractGainLossAmount?: Maybe<Scalars['Float']>;
  Longitud?: Maybe<Scalars['String']>;
  Latitud?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterResponsibilityCenterInput>>;
  NOT?: Maybe<FilterResponsibilityCenterInput>;
};

export type FilterReturnRequestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  DomainName?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  PickUpDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  DropOff?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterReturnRequestHeaderInput>>;
  NOT?: Maybe<FilterReturnRequestHeaderInput>;
};

export type FilterReturnRequestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  LineGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterReturnRequestLineInput>>;
  NOT?: Maybe<FilterReturnRequestLineInput>;
};

export type FilterRiskAnalysisHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Template?: Maybe<Scalars['String']>;
  WorkPlace?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedByPhone?: Maybe<Scalars['String']>;
  Customer?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Database?: Maybe<Scalars['String']>;
  RecordID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  FinishedByUser?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRiskAnalysisHeaderInput>>;
  NOT?: Maybe<FilterRiskAnalysisHeaderInput>;
};

export type FilterRiskAnalysisLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  RiskAnalysisNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  RiskCode?: Maybe<Scalars['Float']>;
  RiskCodeOPTION?: Maybe<Scalars['String']>;
  Risk?: Maybe<Scalars['Boolean']>;
  Action?: Maybe<Scalars['String']>;
  Probability?: Maybe<Scalars['Float']>;
  ProbabilityOPTION?: Maybe<Scalars['String']>;
  Consequence?: Maybe<Scalars['Float']>;
  ConsequenceOPTION?: Maybe<Scalars['String']>;
  Mandatory?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRiskAnalysisLineInput>>;
  NOT?: Maybe<FilterRiskAnalysisLineInput>;
};

export type FilterRiskAnalysisSignatureInput = {
  discriminator?: Maybe<Scalars['String']>;
  RiskAnalysisNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Signature?: Maybe<Scalars['String']>;
  TypeOfSignature?: Maybe<Scalars['Float']>;
  TypeOfSignatureOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterRiskAnalysisSignatureInput>>;
  NOT?: Maybe<FilterRiskAnalysisSignatureInput>;
};

export type FilterSalesHeaderArchiveInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesHeaderArchiveDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  SelltoCustomerGlobalId?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  QuoteValidUntilDate?: Maybe<Scalars['DateTime']>;
  QuoteSenttoCustomer?: Maybe<Scalars['String']>;
  QuoteAccepted?: Maybe<Scalars['Boolean']>;
  QuoteAcceptedDate?: Maybe<Scalars['DateTime']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['Float']>;
  EQMShipmentTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['Float']>;
  EQMTransferMethodOPTION?: Maybe<Scalars['String']>;
  EQMContraryDoc?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMCombineCustomerProject?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMInvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  EQMCashSaleDocument?: Maybe<Scalars['Boolean']>;
  EQMUseDeposit?: Maybe<Scalars['Boolean']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['Float']>;
  LastEmailSentStatusOPTION?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  AmtShipNotInvLCY?: Maybe<Scalars['Float']>;
  AmtShipNotInvLCYBase?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  Shipped?: Maybe<Scalars['Boolean']>;
  LateOrderShipping?: Maybe<Scalars['Boolean']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  MagentoId?: Maybe<Scalars['Float']>;
  BaseSubTotal?: Maybe<Scalars['Float']>;
  BaseSubTotalInclTax?: Maybe<Scalars['Float']>;
  ShippingDescription?: Maybe<Scalars['String']>;
  CustomerNote?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  BilltoEmail?: Maybe<Scalars['String']>;
  BilltoTelephone?: Maybe<Scalars['String']>;
  ShipToId?: Maybe<Scalars['Float']>;
  ShiptoEmail?: Maybe<Scalars['String']>;
  ShiptoTelephone?: Maybe<Scalars['String']>;
  MagentoOrderStatus?: Maybe<Scalars['String']>;
  MagentoOrderState?: Maybe<Scalars['String']>;
  LineCount?: Maybe<Scalars['Float']>;
  FreightIncluded?: Maybe<Scalars['Boolean']>;
  DiscountIncluded?: Maybe<Scalars['Boolean']>;
  DeliveryMethod?: Maybe<Scalars['Float']>;
  ProcessedByEdge?: Maybe<Scalars['Boolean']>;
  EdgeStartedPollingOn?: Maybe<Scalars['DateTime']>;
  HandleRelationsInJSONStructure?: Maybe<Scalars['Boolean']>;
  WebbComment?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  VersionNo?: Maybe<Scalars['Float']>;
  DeletedReason?: Maybe<SalesHeaderArchiveDeletedReasonEnum>;
  IsConstructed?: Maybe<Scalars['Boolean']>;
  PaymentMethodWeb?: Maybe<SalesHeaderArchivePaymentMethodWebEnum>;
  SharkActualPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesHeaderArchiveInput>>;
  NOT?: Maybe<FilterSalesHeaderArchiveInput>;
};

export type FilterSalesHeaderHistoryInput = {
  discriminator?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesHeaderHistoryTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesHeaderHistoryInput>>;
  NOT?: Maybe<FilterSalesHeaderHistoryInput>;
};

export type FilterSalesHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesHeaderDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  SelltoCustomerGlobalId?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  QuoteValidUntilDate?: Maybe<Scalars['DateTime']>;
  QuoteSenttoCustomer?: Maybe<Scalars['String']>;
  QuoteAccepted?: Maybe<Scalars['Boolean']>;
  QuoteAcceptedDate?: Maybe<Scalars['DateTime']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<SalesHeaderShippingAdviceEnum>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['Float']>;
  EQMShipmentTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['Float']>;
  EQMTransferMethodOPTION?: Maybe<Scalars['String']>;
  EQMContraryDoc?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMCombineCustomerProject?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMInvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  EQMCashSaleDocument?: Maybe<Scalars['Boolean']>;
  EQMUseDeposit?: Maybe<Scalars['Boolean']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['Float']>;
  LastEmailSentStatusOPTION?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  AmtShipNotInvLCY?: Maybe<Scalars['Float']>;
  AmtShipNotInvLCYBase?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  Shipped?: Maybe<Scalars['Boolean']>;
  LateOrderShipping?: Maybe<Scalars['Boolean']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  MagentoId?: Maybe<Scalars['Float']>;
  BaseSubTotal?: Maybe<Scalars['Float']>;
  BaseSubTotalInclTax?: Maybe<Scalars['Float']>;
  ShippingDescription?: Maybe<Scalars['String']>;
  CustomerNote?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  BilltoEmail?: Maybe<Scalars['String']>;
  BilltoTelephone?: Maybe<Scalars['String']>;
  ShipToId?: Maybe<Scalars['Float']>;
  ShiptoEmail?: Maybe<Scalars['String']>;
  ShiptoTelephone?: Maybe<Scalars['String']>;
  MagentoOrderStatus?: Maybe<Scalars['String']>;
  MagentoOrderState?: Maybe<Scalars['String']>;
  LineCount?: Maybe<Scalars['Float']>;
  FreightIncluded?: Maybe<Scalars['Boolean']>;
  DiscountIncluded?: Maybe<Scalars['Boolean']>;
  DeliveryMethod?: Maybe<Scalars['Float']>;
  ProcessedByEdge?: Maybe<Scalars['Boolean']>;
  EdgeStartedPollingOn?: Maybe<Scalars['DateTime']>;
  HandleRelationsInJSONStructure?: Maybe<Scalars['Boolean']>;
  WebbComment?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  PaymentMethodWeb?: Maybe<SalesHeaderPaymentMethodWebEnum>;
  EMail?: Maybe<Scalars['String']>;
  WebOrderNo?: Maybe<Scalars['String']>;
  AdditionalTelephone?: Maybe<Scalars['String']>;
  SellToAddressGlobalId?: Maybe<Scalars['String']>;
  SharkActualPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesHeaderInput>>;
  NOT?: Maybe<FilterSalesHeaderInput>;
};

export type FilterSalesHeaderRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AuthorizationRequired?: Maybe<Scalars['Boolean']>;
  CreditCardNo?: Maybe<Scalars['String']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  Ordertype?: Maybe<Scalars['Float']>;
  OrdertypeOPTION?: Maybe<Scalars['String']>;
  OurReference?: Maybe<Scalars['String']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  ConfirmedbyVendor?: Maybe<Scalars['Boolean']>;
  DocumentMethod?: Maybe<Scalars['Float']>;
  DocumentMethodOPTION?: Maybe<Scalars['String']>;
  InvoiceEmailAddress?: Maybe<Scalars['String']>;
  NAVStatus?: Maybe<Scalars['Float']>;
  NAVStatusOPTION?: Maybe<Scalars['String']>;
  NAVImportedDate?: Maybe<Scalars['DateTime']>;
  NAVOrderNo?: Maybe<Scalars['String']>;
  NAVErrorMessage?: Maybe<Scalars['String']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesHeaderRequestInput>>;
  NOT?: Maybe<FilterSalesHeaderRequestInput>;
};

export type FilterSalesLineArchiveInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesLineArchiveDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesLineArchiveTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ItemGlobalId?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvLCYNoVAT?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  LineDiscountCalculation?: Maybe<Scalars['Float']>;
  LineDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  EQMCustomerProject?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMReturnDocumentNo?: Maybe<Scalars['String']>;
  EQMReturnLineNo?: Maybe<Scalars['Float']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMAllocateObjectNo?: Maybe<Scalars['String']>;
  EQMRentalLineType?: Maybe<Scalars['Float']>;
  EQMRentalLineTypeOPTION?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['Float']>;
  EQMWorkshopTypeOPTION?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMOldTmpRKITLineNo?: Maybe<Scalars['Float']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMQtyonAtttoLineNo?: Maybe<Scalars['Float']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMRentalPrepaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtLCY?: Maybe<Scalars['Float']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['Float']>;
  EQMPriceReportedHoursOPTION?: Maybe<Scalars['String']>;
  EQMFACorrection?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  ProductType?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  VersionNo?: Maybe<Scalars['Float']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  SalesOrderLineEdgeGuid?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesLineArchiveInput>>;
  NOT?: Maybe<FilterSalesLineArchiveInput>;
};

export type FilterSalesLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesLineDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ItemGlobalId?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvLCYNoVAT?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  LineDiscountCalculation?: Maybe<Scalars['Float']>;
  LineDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  EQMCustomerProject?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMReturnDocumentNo?: Maybe<Scalars['String']>;
  EQMReturnLineNo?: Maybe<Scalars['Float']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMAllocateObjectNo?: Maybe<Scalars['String']>;
  EQMRentalLineType?: Maybe<Scalars['Float']>;
  EQMRentalLineTypeOPTION?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['Float']>;
  EQMWorkshopTypeOPTION?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMOldTmpRKITLineNo?: Maybe<Scalars['Float']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMQtyonAtttoLineNo?: Maybe<Scalars['Float']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMRentalPrepaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtLCY?: Maybe<Scalars['Float']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['Float']>;
  EQMPriceReportedHoursOPTION?: Maybe<Scalars['String']>;
  EQMFACorrection?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  ProductType?: Maybe<Scalars['String']>;
  SalesOrderLineEdgeGuid?: Maybe<Scalars['String']>;
  LinkedToGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesLineInput>>;
  NOT?: Maybe<FilterSalesLineInput>;
};

export type FilterSalesLineRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PeriodicTemplateCode?: Maybe<Scalars['String']>;
  PeriodicStartingDate?: Maybe<Scalars['String']>;
  NAVStatus?: Maybe<Scalars['Float']>;
  NAVStatusOPTION?: Maybe<Scalars['String']>;
  NAVImportedDate?: Maybe<Scalars['DateTime']>;
  NAVOrderNo?: Maybe<Scalars['String']>;
  Rubrikrad?: Maybe<Scalars['Boolean']>;
  EndofGroup?: Maybe<Scalars['Boolean']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  FreeTextLine?: Maybe<Scalars['Boolean']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  NAVErrorMessage?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesLineRequestInput>>;
  NOT?: Maybe<FilterSalesLineRequestInput>;
};

export type FilterSalesPriceInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  SalesType?: Maybe<SalesPriceSalesTypeEnum>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  UnitPriceIncVAT?: Maybe<Scalars['Float']>;
  SalesOrderMultiple?: Maybe<Scalars['Float']>;
  CarepaPurchPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesPriceInput>>;
  NOT?: Maybe<FilterSalesPriceInput>;
};

export type FilterSalesShipmentHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  MagentoOrder?: Maybe<Scalars['Boolean']>;
  MagentoOrderType?: Maybe<Scalars['Float']>;
  MagentoOrderTypeOPTION?: Maybe<Scalars['String']>;
  MagentoOrderId?: Maybe<Scalars['Float']>;
  MagentoOrderEmail?: Maybe<Scalars['String']>;
  MagentoOrderMobileNo?: Maybe<Scalars['String']>;
  MagentoOrderLinesCount?: Maybe<Scalars['Float']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  EdgeRetryCount?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['String']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['String']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DocumentExchangeIdentifier?: Maybe<Scalars['String']>;
  DocumentExchangeStatus?: Maybe<Scalars['String']>;
  DocumentExchangeStatusOPTION?: Maybe<Scalars['String']>;
  DocExchOriginalIdentifier?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  CustLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Preseason?: Maybe<Scalars['Boolean']>;
  AdditionalTelephone?: Maybe<Scalars['String']>;
  AdditionalCustomerNote?: Maybe<Scalars['String']>;
  CustomerCommentExists?: Maybe<Scalars['Boolean']>;
  WebOrderNo?: Maybe<Scalars['String']>;
  WebOrderEntityNo?: Maybe<Scalars['Float']>;
  EMail?: Maybe<Scalars['String']>;
  EMailOrderConfirmation?: Maybe<Scalars['String']>;
  EMailSalesShipment?: Maybe<Scalars['String']>;
  EMailInvoice?: Maybe<Scalars['String']>;
  EMailShipmentNotification?: Maybe<Scalars['String']>;
  OrderCreatedBy?: Maybe<Scalars['String']>;
  OrderCreated?: Maybe<Scalars['String']>;
  OrderType?: Maybe<Scalars['String']>;
  OrderTypeOPTION?: Maybe<Scalars['String']>;
  ShipmentDocType?: Maybe<Scalars['String']>;
  ShipmentDocTypeOPTION?: Maybe<Scalars['String']>;
  GearsOrder?: Maybe<Scalars['Boolean']>;
  NumberofCustomerPriceGroup?: Maybe<Scalars['Float']>;
  EDISetupCode?: Maybe<Scalars['String']>;
  InvoiceDocumentType?: Maybe<Scalars['String']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  InvoiceSentToCustomer?: Maybe<Scalars['Boolean']>;
  InvoiceSentToCustomerDate?: Maybe<Scalars['String']>;
  InvoiceSentToCustomerTime?: Maybe<Scalars['String']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  FreightOnlyShipment?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesShipmentHeaderInput>>;
  NOT?: Maybe<FilterSalesShipmentHeaderInput>;
};

export type FilterSalesShipmentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  MagentoOrderLineType?: Maybe<Scalars['Float']>;
  MagentoOrderLineTypeOPTION?: Maybe<Scalars['String']>;
  MagentoOrderLineNo?: Maybe<Scalars['Float']>;
  MagentoOrderLineQty?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ItemShptEntryNo?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['String']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AuthorizedforCreditCard?: Maybe<Scalars['Boolean']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Boolean']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['String']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  RequestedDeliveryDate?: Maybe<Scalars['String']>;
  PromisedDeliveryDate?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  CreatedOnOrderBy?: Maybe<Scalars['String']>;
  ItemReorderingPolicy?: Maybe<Scalars['String']>;
  ItemReorderingPolicyOPTION?: Maybe<Scalars['String']>;
  PeabAccountNo?: Maybe<Scalars['String']>;
  FeeType?: Maybe<Scalars['String']>;
  FeeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  Correction?: Maybe<Scalars['Boolean']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  CreatedOnOrder?: Maybe<Scalars['DateTime']>;
  Originaldeliverydate?: Maybe<Scalars['DateTime']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  ItemChargeBaseAmount?: Maybe<Scalars['Float']>;
  CalculatedPrice?: Maybe<Scalars['Float']>;
  DisposableStockBalance?: Maybe<Scalars['Float']>;
  Currentpricecalculation?: Maybe<Scalars['Float']>;
  FeeAttachedtoLineNo?: Maybe<Scalars['Float']>;
  CustomerOrderLineNo?: Maybe<Scalars['Float']>;
  CustomerOrderedQuantity?: Maybe<Scalars['Float']>;
  TotalQuantityShipped?: Maybe<Scalars['Float']>;
  CurrentOutstandingQuantity?: Maybe<Scalars['Float']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalesShipmentLineInput>>;
  NOT?: Maybe<FilterSalesShipmentLineInput>;
};

export type FilterSalespersonInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Commission?: Maybe<Scalars['Float']>;
  Image?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  SearchEMail?: Maybe<Scalars['String']>;
  EMail2?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ActionTakenFilter?: Maybe<Scalars['Float']>;
  ActionTakenFilterOPTION?: Maybe<Scalars['String']>;
  SalesCycleFilter?: Maybe<Scalars['String']>;
  SalesCycleStageFilter?: Maybe<Scalars['Float']>;
  ProbabilityFilter?: Maybe<Scalars['Float']>;
  CompletedFilter?: Maybe<Scalars['Float']>;
  ContactFilter?: Maybe<Scalars['String']>;
  ContactCompanyFilter?: Maybe<Scalars['String']>;
  CampaignFilter?: Maybe<Scalars['String']>;
  EstimatedValueFilter?: Maybe<Scalars['Float']>;
  CalcdCurrentValueFilter?: Maybe<Scalars['Float']>;
  ChancesofSuccessFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilterOPTION?: Maybe<Scalars['String']>;
  ClosedTaskFilter?: Maybe<Scalars['Boolean']>;
  PriorityFilter?: Maybe<Scalars['Float']>;
  PriorityFilterOPTION?: Maybe<Scalars['String']>;
  TeamFilter?: Maybe<Scalars['String']>;
  CloseOpportunityFilter?: Maybe<Scalars['String']>;
  NextTaskDate?: Maybe<Scalars['String']>;
  NoofOpportunities?: Maybe<Scalars['Float']>;
  EstimatedValueLCY?: Maybe<Scalars['Float']>;
  CalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  NoofInteractions?: Maybe<Scalars['Float']>;
  CostLCY?: Maybe<Scalars['Float']>;
  DurationMin?: Maybe<Scalars['Float']>;
  AvgEstimatedValueLCY?: Maybe<Scalars['Float']>;
  AvgCalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  OpportunityEntryExists?: Maybe<Scalars['Boolean']>;
  TaskEntryExists?: Maybe<Scalars['Boolean']>;
  EdgeRespCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSalespersonInput>>;
  NOT?: Maybe<FilterSalespersonInput>;
};

export type FilterServiceItemGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreateServiceItem?: Maybe<Scalars['Boolean']>;
  DefaultContractDiscountPercent?: Maybe<Scalars['Float']>;
  DefaultServPriceGroupCode?: Maybe<Scalars['String']>;
  DefaultResponseTimeHours?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterServiceItemGroupInput>>;
  NOT?: Maybe<FilterServiceItemGroupInput>;
};

export type FilterShipmentMethodInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterShipmentMethodInput>>;
  NOT?: Maybe<FilterShipmentMethodInput>;
};

export type FilterShippingAgentInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  InternetAddress?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  ShpfyTrackingCompany?: Maybe<Scalars['Float']>;
  ShpfyTrackingCompanyOPTION?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterShippingAgentInput>>;
  NOT?: Maybe<FilterShippingAgentInput>;
};

export type FilterSignRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  DocumentUri?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentEtag?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
  SigningUserEmail?: Maybe<Scalars['String']>;
  SigningUserName?: Maybe<Scalars['String']>;
  PrintedName?: Maybe<Scalars['String']>;
  SignMethod?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  GlobalId?: Maybe<Scalars['ID']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Base64WriteOnly?: Maybe<Scalars['String']>;
  MobileBankIdSSN?: Maybe<Scalars['String']>;
  MobileBankIdUserVisibleData?: Maybe<Scalars['String']>;
  orderRef?: Maybe<Scalars['String']>;
  autoStartToken?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  hintCode?: Maybe<Scalars['String']>;
  endUserIp?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSignRequestInput>>;
  NOT?: Maybe<FilterSignRequestInput>;
};

export type FilterSignatureInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSignatureInput>>;
  NOT?: Maybe<FilterSignatureInput>;
};

export type FilterSpecialEquipmentInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterSpecialEquipmentInput>>;
  NOT?: Maybe<FilterSpecialEquipmentInput>;
};

export type FilterStockkeepingUnitInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  AssemblyBOM?: Maybe<Scalars['Boolean']>;
  ShelfNo?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  VendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  ReorderPoint?: Maybe<Scalars['Float']>;
  MaximumInventory?: Maybe<Scalars['Float']>;
  ReorderQuantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  QtyonSalesOrder?: Maybe<Scalars['Float']>;
  DropShipmentFilter?: Maybe<Scalars['Boolean']>;
  AssemblyPolicy?: Maybe<Scalars['Float']>;
  AssemblyPolicyOPTION?: Maybe<Scalars['String']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonAsmComponent?: Maybe<Scalars['Float']>;
  QtyonJobOrder?: Maybe<Scalars['Float']>;
  TransferLevelCode?: Maybe<Scalars['Float']>;
  LotSize?: Maybe<Scalars['Float']>;
  DiscreteOrderQuantity?: Maybe<Scalars['Float']>;
  MinimumOrderQuantity?: Maybe<Scalars['Float']>;
  MaximumOrderQuantity?: Maybe<Scalars['Float']>;
  SafetyStockQuantity?: Maybe<Scalars['Float']>;
  OrderMultiple?: Maybe<Scalars['Float']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  ComponentsatLocation?: Maybe<Scalars['String']>;
  FlushingMethod?: Maybe<Scalars['Float']>;
  FlushingMethodOPTION?: Maybe<Scalars['String']>;
  ReplenishmentSystem?: Maybe<Scalars['Float']>;
  ReplenishmentSystemOPTION?: Maybe<Scalars['String']>;
  ScheduledReceiptQty?: Maybe<Scalars['Float']>;
  ScheduledNeedQty?: Maybe<Scalars['Float']>;
  BinFilter?: Maybe<Scalars['String']>;
  TimeBucket?: Maybe<Scalars['String']>;
  ReorderingPolicy?: Maybe<Scalars['Float']>;
  ReorderingPolicyOPTION?: Maybe<Scalars['String']>;
  IncludeInventory?: Maybe<Scalars['Boolean']>;
  ManufacturingPolicy?: Maybe<Scalars['Float']>;
  ManufacturingPolicyOPTION?: Maybe<Scalars['String']>;
  ReschedulingPeriod?: Maybe<Scalars['String']>;
  LotAccumulationPeriod?: Maybe<Scalars['String']>;
  DampenerPeriod?: Maybe<Scalars['String']>;
  DampenerQuantity?: Maybe<Scalars['Float']>;
  OverflowLevel?: Maybe<Scalars['Float']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  TransOrdReceiptQty?: Maybe<Scalars['Float']>;
  TransOrdShipmentQty?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  SpecialEquipmentCode?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  PutawayUnitofMeasureCode?: Maybe<Scalars['String']>;
  PhysInvtCountingPeriodCode?: Maybe<Scalars['String']>;
  LastCountingPeriodUpdate?: Maybe<Scalars['DateTime']>;
  LastPhysInvtDate?: Maybe<Scalars['DateTime']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  NextCountingStartDate?: Maybe<Scalars['DateTime']>;
  NextCountingEndDate?: Maybe<Scalars['DateTime']>;
  RoutingNo?: Maybe<Scalars['String']>;
  ProductionBOMNo?: Maybe<Scalars['String']>;
  PlannedOrderReceiptQty?: Maybe<Scalars['Float']>;
  FPOrderReceiptQty?: Maybe<Scalars['Float']>;
  RelOrderReceiptQty?: Maybe<Scalars['Float']>;
  PlannedOrderReleaseQty?: Maybe<Scalars['Float']>;
  PurchReqReceiptQty?: Maybe<Scalars['Float']>;
  PurchReqReleaseQty?: Maybe<Scalars['Float']>;
  QtyonProdOrder?: Maybe<Scalars['Float']>;
  QtyonComponentLines?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterStockkeepingUnitInput>>;
  NOT?: Maybe<FilterStockkeepingUnitInput>;
};

export type FilterTariffNumberInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SupplementaryUnits?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTariffNumberInput>>;
  NOT?: Maybe<FilterTariffNumberInput>;
};

export type FilterTaxGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTaxGroupInput>>;
  NOT?: Maybe<FilterTaxGroupInput>;
};

export type FilterTimeRegistrationInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  TimeSheetLineLineNo?: Maybe<Scalars['Float']>;
  TimeSheetDetailLineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Hours?: Maybe<Scalars['Float']>;
  Email?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  StartDateFilter?: Maybe<Scalars['DateTime']>;
  EndDateFilter?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTimeRegistrationInput>>;
  NOT?: Maybe<FilterTimeRegistrationInput>;
};

export type FilterTimeSheetDetailInput = {
  discriminator?: Maybe<Scalars['String']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  TimeSheetLineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  ServiceOrderNo?: Maybe<Scalars['String']>;
  ServiceOrderLineNo?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PostedQuantity?: Maybe<Scalars['Float']>;
  AssemblyOrderNo?: Maybe<Scalars['String']>;
  AssemblyOrderLineNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Posted?: Maybe<Scalars['Boolean']>;
  ExcludeFromTotals?: Maybe<Scalars['Boolean']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTimeSheetDetailInput>>;
  NOT?: Maybe<FilterTimeSheetDetailInput>;
};

export type FilterTimeSheetLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  TimeSheetStartingDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ServiceOrderNo?: Maybe<Scalars['String']>;
  ServiceOrderLineNo?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  AssemblyOrderNo?: Maybe<Scalars['String']>;
  AssemblyOrderLineNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Posted?: Maybe<Scalars['Boolean']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CustomerName?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTimeSheetLineInput>>;
  NOT?: Maybe<FilterTimeSheetLineInput>;
};

export type FilterTransactionSpecificationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTransactionSpecificationInput>>;
  NOT?: Maybe<FilterTransactionSpecificationInput>;
};

export type FilterTransactionTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTransactionTypeInput>>;
  NOT?: Maybe<FilterTransactionTypeInput>;
};

export type FilterTransportMethodInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterTransportMethodInput>>;
  NOT?: Maybe<FilterTransportMethodInput>;
};

export type FilterUnitOfMeasureInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  InternationalStandardCode?: Maybe<Scalars['String']>;
  Symbol?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  ExternalCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterUnitOfMeasureInput>>;
  NOT?: Maybe<FilterUnitOfMeasureInput>;
};

export type FilterUserFavoritesInput = {
  discriminator?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterUserFavoritesInput>>;
  NOT?: Maybe<FilterUserFavoritesInput>;
};

export type FilterUserInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  Dyn365Id?: Maybe<Scalars['String']>;
  DynNAVId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  VerifiedIdentity?: Maybe<Scalars['Boolean']>;
  IsDemo?: Maybe<Scalars['Boolean']>;
  EmailOnExpiredObjects?: Maybe<Scalars['Boolean']>;
  PushNotificationOnShipments?: Maybe<Scalars['Boolean']>;
  IdentityVerifiedByUser?: Maybe<Scalars['String']>;
  PendingAccessRequest?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  DefaultSignature?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  NavUserName?: Maybe<Scalars['String']>;
  NavApiUserNameId?: Maybe<Scalars['String']>;
  NavApiPasswordId?: Maybe<Scalars['String']>;
  UserInvitationId?: Maybe<Scalars['String']>;
  UserAcceptedInvitationOn?: Maybe<Scalars['DateTime']>;
  ImpersonateUserId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterUserInput>>;
  NOT?: Maybe<FilterUserInput>;
};

export type FilterVatBusinessPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterVatBusinessPostingGroupInput>>;
  NOT?: Maybe<FilterVatBusinessPostingGroupInput>;
};

export type FilterVatProductPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterVatProductPostingGroupInput>>;
  NOT?: Maybe<FilterVatProductPostingGroupInput>;
};

export type FilterVendorBankAccountInput = {
  discriminator?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  TransitNo?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  BankClearingCode?: Maybe<Scalars['String']>;
  BankClearingStandard?: Maybe<Scalars['String']>;
  PEBPlusGiroNo?: Maybe<Scalars['String']>;
  PEBPaymentMethod?: Maybe<Scalars['Float']>;
  PEBPaymentMethodOPTION?: Maybe<Scalars['String']>;
  PEBGiroTypeCode?: Maybe<Scalars['String']>;
  PEBBankGiroNo?: Maybe<Scalars['String']>;
  PEBCodeforBankofSweden?: Maybe<Scalars['String']>;
  PEBAccountPaymentwithAdvis?: Maybe<Scalars['Boolean']>;
  PEBChargePaidby?: Maybe<Scalars['Float']>;
  PEBChargePaidbyOPTION?: Maybe<Scalars['String']>;
  PEBCategoryPurpose?: Maybe<Scalars['Float']>;
  PEBCategoryPurposeOPTION?: Maybe<Scalars['String']>;
  PEBStatus?: Maybe<Scalars['Float']>;
  PEBStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterVendorBankAccountInput>>;
  NOT?: Maybe<FilterVendorBankAccountInput>;
};

export type FilterVendorInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  OurAccountNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  FinChargeTermsCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  Priority?: Maybe<Scalars['Float']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  ApplicationMethod?: Maybe<Scalars['Float']>;
  ApplicationMethodOPTION?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  GLN?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  BlockPaymentTolerance?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PartnerType?: Maybe<Scalars['Float']>;
  PartnerTypeOPTION?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PreferredBankAccountCode?: Maybe<Scalars['String']>;
  CashFlowPaymentTermsCode?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  DocumentSendingProfile?: Maybe<Scalars['String']>;
  ValidateEUVatRegNo?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  CurrencyId?: Maybe<Scalars['String']>;
  PaymentTermsId?: Maybe<Scalars['String']>;
  PaymentMethodId?: Maybe<Scalars['String']>;
  NocontrolofExtDocNo?: Maybe<Scalars['Boolean']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  CurrencyFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  NetChangeLCY?: Maybe<Scalars['Float']>;
  PurchasesLCY?: Maybe<Scalars['Float']>;
  InvDiscountsLCY?: Maybe<Scalars['Float']>;
  PmtDiscountsLCY?: Maybe<Scalars['Float']>;
  BalanceDue?: Maybe<Scalars['Float']>;
  BalanceDueLCY?: Maybe<Scalars['Float']>;
  Payments?: Maybe<Scalars['Float']>;
  InvoiceAmounts?: Maybe<Scalars['Float']>;
  CrMemoAmounts?: Maybe<Scalars['Float']>;
  FinanceChargeMemoAmounts?: Maybe<Scalars['Float']>;
  PaymentsLCY?: Maybe<Scalars['Float']>;
  InvAmountsLCY?: Maybe<Scalars['Float']>;
  CrMemoAmountsLCY?: Maybe<Scalars['Float']>;
  FinChargeMemoAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['Float']>;
  CreditAmount?: Maybe<Scalars['Float']>;
  DebitAmountLCY?: Maybe<Scalars['Float']>;
  CreditAmountLCY?: Maybe<Scalars['Float']>;
  ReminderAmounts?: Maybe<Scalars['Float']>;
  ReminderAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrdersLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  PmtDiscToleranceLCY?: Maybe<Scalars['Float']>;
  PmtToleranceLCY?: Maybe<Scalars['Float']>;
  Refunds?: Maybe<Scalars['Float']>;
  RefundsLCY?: Maybe<Scalars['Float']>;
  OtherAmounts?: Maybe<Scalars['Float']>;
  OtherAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingInvoices?: Maybe<Scalars['Float']>;
  OutstandingInvoicesLCY?: Maybe<Scalars['Float']>;
  PaytoNoOfArchivedDoc?: Maybe<Scalars['Float']>;
  BuyfromNoOfArchivedDoc?: Maybe<Scalars['Float']>;
  NoofPstdReceipts?: Maybe<Scalars['Float']>;
  NoofPstdInvoices?: Maybe<Scalars['Float']>;
  NoofPstdReturnShipments?: Maybe<Scalars['Float']>;
  NoofPstdCreditMemos?: Maybe<Scalars['Float']>;
  PaytoNoofOrders?: Maybe<Scalars['Float']>;
  PaytoNoofInvoices?: Maybe<Scalars['Float']>;
  PaytoNoofReturnOrders?: Maybe<Scalars['Float']>;
  PaytoNoofCreditMemos?: Maybe<Scalars['Float']>;
  PaytoNoofPstdReceipts?: Maybe<Scalars['Float']>;
  PaytoNoofPstdInvoices?: Maybe<Scalars['Float']>;
  PaytoNoofPstdReturnS?: Maybe<Scalars['Float']>;
  PaytoNoofPstdCrMemos?: Maybe<Scalars['Float']>;
  NoofQuotes?: Maybe<Scalars['Float']>;
  NoofBlanketOrders?: Maybe<Scalars['Float']>;
  NoofOrders?: Maybe<Scalars['Float']>;
  NoofInvoices?: Maybe<Scalars['Float']>;
  NoofReturnOrders?: Maybe<Scalars['Float']>;
  NoofCreditMemos?: Maybe<Scalars['Float']>;
  NoofOrderAddresses?: Maybe<Scalars['Float']>;
  PaytoNoofQuotes?: Maybe<Scalars['Float']>;
  PaytoNoofBlanketOrders?: Maybe<Scalars['Float']>;
  NoofIncomingDocuments?: Maybe<Scalars['Float']>;
  InwardRegBalanceLCY?: Maybe<Scalars['Float']>;
  InwardRegNetChangeLCY?: Maybe<Scalars['Float']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterVendorInput>>;
  NOT?: Maybe<FilterVendorInput>;
};

export type FilterVendorPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  PayablesAccount?: Maybe<Scalars['String']>;
  ServiceChargeAcc?: Maybe<Scalars['String']>;
  PaymentDiscDebitAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingAccount?: Maybe<Scalars['String']>;
  DebitCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  CreditCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  DebitRoundingAccount?: Maybe<Scalars['String']>;
  CreditRoundingAccount?: Maybe<Scalars['String']>;
  PaymentDiscCreditAcc?: Maybe<Scalars['String']>;
  PaymentToleranceDebitAcc?: Maybe<Scalars['String']>;
  PaymentToleranceCreditAcc?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ViewAllAccountsonLookup?: Maybe<Scalars['Boolean']>;
  InwardRegBridgingDebt?: Maybe<Scalars['String']>;
  PEBInwardRegBridgingDebt?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterVendorPostingGroupInput>>;
  NOT?: Maybe<FilterVendorPostingGroupInput>;
};

export type FilterWarehouseClassInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterWarehouseClassInput>>;
  NOT?: Maybe<FilterWarehouseClassInput>;
};

export type FilterWebBookingInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DeliveryDate?: Maybe<Scalars['DateTime']>;
  DeliveryAddress?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  ContactPhone?: Maybe<Scalars['String']>;
  ContactEMail?: Maybe<Scalars['String']>;
  Handled?: Maybe<Scalars['Boolean']>;
  ContractNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterWebBookingInput>>;
  NOT?: Maybe<FilterWebBookingInput>;
};

export type FilterWebhookSubscriptionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  SubscriptionId?: Maybe<Scalars['String']>;
  EventType?: Maybe<WebhookSubscriptionEventTypeEnum>;
  Enabled?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  LastUsed?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterWebhookSubscriptionInput>>;
  NOT?: Maybe<FilterWebhookSubscriptionInput>;
};

export type FilterWorkLogInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hours?: Maybe<Scalars['Float']>;
  SimplonaEmail?: Maybe<Scalars['String']>;
  NAVUser?: Maybe<Scalars['String']>;
  WorkDateTime?: Maybe<Scalars['DateTime']>;
  Debited?: Maybe<Scalars['Boolean']>;
  DoNotDebit?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterWorkLogInput>>;
  NOT?: Maybe<FilterWorkLogInput>;
};

export type FilterWorkTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IN?: Maybe<Array<FilterWorkTypeInput>>;
  NOT?: Maybe<FilterWorkTypeInput>;
};

export type FunctionTestHeader = {
  __typename?: 'FunctionTestHeader';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FinishDate?: Maybe<Scalars['String']>;
  Status?: Maybe<FunctionTestHeaderStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<FunctionTestHeaderApprovalStatusEnum>;
  ApprovalStatusOPTION?: Maybe<Scalars['String']>;
  EQMServiceHeader?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  FromContractNo?: Maybe<Scalars['String']>;
  FromCustomerNo?: Maybe<Scalars['String']>;
  FromReturnDocumentNo?: Maybe<Scalars['String']>;
  FromCollectionDocNo?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  DimCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  FinishTime?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  FinishedBy?: Maybe<Scalars['String']>;
  TypeOfWash?: Maybe<Scalars['String']>;
  ExternalPart?: Maybe<Scalars['Boolean']>;
  FncFailed?: Maybe<Scalars['Boolean']>;
  HourMeterIndicator?: Maybe<Scalars['Float']>;
  Note?: Maybe<Scalars['String']>;
  FromCustomerName?: Maybe<Scalars['String']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  WarrantyDate?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  SignatureFromEdge?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export enum FunctionTestHeaderApprovalStatusEnum {
  Empty = 'Empty',
  Open = 'Open',
  Approved = 'Approved',
  Failed = 'Failed'
}

export type FunctionTestHeaderConnection = {
  __typename?: 'FunctionTestHeaderConnection';
  count: Scalars['Int'];
  edges: Array<FunctionTestHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FunctionTestHeaderConnectionEdge = {
  __typename?: 'FunctionTestHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: FunctionTestHeader;
};

export type FunctionTestHeaderDeleteResponse = {
  __typename?: 'FunctionTestHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<FunctionTestHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FunctionTestHeaderManyResult = {
  __typename?: 'FunctionTestHeaderManyResult';
  records?: Maybe<Array<Maybe<FunctionTestHeader>>>;
  pageData?: Maybe<PageData>;
};

export enum FunctionTestHeaderStatusEnum {
  Created = 'Created',
  Started = 'Started',
  Finished = 'Finished'
}

export type FunctionTestLine = {
  __typename?: 'FunctionTestLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  ObjectNo?: Maybe<Scalars['String']>;
  TestIssue?: Maybe<Scalars['String']>;
  CurrentStatus?: Maybe<Scalars['String']>;
  AdditionalInstructions?: Maybe<Scalars['String']>;
  SpecificMeasure?: Maybe<Scalars['Boolean']>;
  DirectMeasure?: Maybe<Scalars['Boolean']>;
  InspectionTimeInterval?: Maybe<Scalars['Boolean']>;
  ToCorrect?: Maybe<Scalars['String']>;
  WorkShopOrderNo?: Maybe<Scalars['String']>;
  WorkShopOrderFinished?: Maybe<Scalars['Boolean']>;
  Headline?: Maybe<Scalars['Boolean']>;
  NoteFunctionTest?: Maybe<Scalars['String']>;
  NoteDate?: Maybe<Scalars['String']>;
  NoteOwner?: Maybe<Scalars['String']>;
  MoveNote?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  Mandatory?: Maybe<Scalars['Boolean']>;
  TimeEstimation?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  TestedApproved?: Maybe<Scalars['Boolean']>;
  TestedExecuted?: Maybe<Scalars['Boolean']>;
  ExecutedBy?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type FunctionTestLineConnection = {
  __typename?: 'FunctionTestLineConnection';
  count: Scalars['Int'];
  edges: Array<FunctionTestLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type FunctionTestLineConnectionEdge = {
  __typename?: 'FunctionTestLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: FunctionTestLine;
};

export type FunctionTestLineDeleteResponse = {
  __typename?: 'FunctionTestLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<FunctionTestLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FunctionTestLineManyResult = {
  __typename?: 'FunctionTestLineManyResult';
  records?: Maybe<Array<Maybe<FunctionTestLine>>>;
  pageData?: Maybe<PageData>;
};

export type GenBusinessPostingGroup = {
  __typename?: 'GenBusinessPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefVATBusPostingGroup?: Maybe<Scalars['String']>;
  AutoInsertDefault?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type GenBusinessPostingGroupConnection = {
  __typename?: 'GenBusinessPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<GenBusinessPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type GenBusinessPostingGroupConnectionEdge = {
  __typename?: 'GenBusinessPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: GenBusinessPostingGroup;
};

export type GenBusinessPostingGroupDeleteResponse = {
  __typename?: 'GenBusinessPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<GenBusinessPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GenBusinessPostingGroupManyResult = {
  __typename?: 'GenBusinessPostingGroupManyResult';
  records?: Maybe<Array<Maybe<GenBusinessPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type GenJournalLine = {
  __typename?: 'GenJournalLine';
  discriminator?: Maybe<Scalars['String']>;
  JournalTemplateName?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  AccountType?: Maybe<Scalars['Float']>;
  AccountTypeOPTION?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  VAT?: Maybe<Scalars['Float']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['Float']>;
  CreditAmount?: Maybe<Scalars['Float']>;
  AmountLCY?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  SalesPurchLCY?: Maybe<Scalars['Float']>;
  ProfitLCY?: Maybe<Scalars['Float']>;
  InvDiscountLCY?: Maybe<Scalars['Float']>;
  BilltoPaytoNo?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  SalespersPurchCode?: Maybe<Scalars['String']>;
  PendingApproval?: Maybe<Scalars['Boolean']>;
  SourceCode?: Maybe<Scalars['String']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  VATAmount?: Maybe<Scalars['Float']>;
  VATPosting?: Maybe<Scalars['Float']>;
  VATPostingOPTION?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  BusinessUnitCode?: Maybe<Scalars['String']>;
  JournalBatchName?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  RecurringMethod?: Maybe<Scalars['Float']>;
  RecurringMethodOPTION?: Maybe<Scalars['String']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  RecurringFrequency?: Maybe<Scalars['String']>;
  AllocatedAmtLCY?: Maybe<Scalars['Float']>;
  GenPostingType?: Maybe<Scalars['Float']>;
  GenPostingTypeOPTION?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  AllowApplication?: Maybe<Scalars['Boolean']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  BalGenPostingType?: Maybe<Scalars['Float']>;
  BalGenPostingTypeOPTION?: Maybe<Scalars['String']>;
  BalGenBusPostingGroup?: Maybe<Scalars['String']>;
  BalGenProdPostingGroup?: Maybe<Scalars['String']>;
  BalVATCalculationType?: Maybe<Scalars['Float']>;
  BalVATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  BalVAT?: Maybe<Scalars['Float']>;
  BalVATAmount?: Maybe<Scalars['Float']>;
  BankPaymentType?: Maybe<Scalars['Float']>;
  BankPaymentTypeOPTION?: Maybe<Scalars['String']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  BalVATBaseAmount?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  CheckPrinted?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['Float']>;
  SourceTypeOPTION?: Maybe<Scalars['String']>;
  SourceNo?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  BalTaxAreaCode?: Maybe<Scalars['String']>;
  BalTaxLiable?: Maybe<Scalars['Boolean']>;
  BalTaxGroupCode?: Maybe<Scalars['String']>;
  BalUseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BalVATBusPostingGroup?: Maybe<Scalars['String']>;
  BalVATProdPostingGroup?: Maybe<Scalars['String']>;
  AdditionalCurrencyPosting?: Maybe<Scalars['Float']>;
  AdditionalCurrencyPostingOPTION?: Maybe<Scalars['String']>;
  FAAddCurrencyFactor?: Maybe<Scalars['Float']>;
  SourceCurrencyCode?: Maybe<Scalars['String']>;
  SourceCurrencyAmount?: Maybe<Scalars['Float']>;
  SourceCurrVATBaseAmount?: Maybe<Scalars['Float']>;
  SourceCurrVATAmount?: Maybe<Scalars['Float']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  VATAmountLCY?: Maybe<Scalars['Float']>;
  VATBaseAmountLCY?: Maybe<Scalars['Float']>;
  BalVATAmountLCY?: Maybe<Scalars['Float']>;
  BalVATBaseAmountLCY?: Maybe<Scalars['Float']>;
  ReversingEntry?: Maybe<Scalars['Boolean']>;
  AllowZeroAmountPosting?: Maybe<Scalars['Boolean']>;
  ShiptoOrderAddressCode?: Maybe<Scalars['String']>;
  VATDifference?: Maybe<Scalars['Float']>;
  BalVATDifference?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  ICPartnerGLAccNo?: Maybe<Scalars['String']>;
  ICPartnerTransactionNo?: Maybe<Scalars['Float']>;
  SelltoBuyfromNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Boolean']>;
  FinancialVoid?: Maybe<Scalars['Boolean']>;
  CopyVATSetuptoJnlLines?: Maybe<Scalars['Boolean']>;
  VATBaseBeforePmtDisc?: Maybe<Scalars['Float']>;
  OrigPmtDiscPossible?: Maybe<Scalars['Float']>;
  OrigPmtDiscPossibleLCY?: Maybe<Scalars['Float']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  AppliestoExtDocNo?: Maybe<Scalars['String']>;
  KeepDescription?: Maybe<Scalars['Boolean']>;
  RecipientBankAccount?: Maybe<Scalars['String']>;
  MessagetoRecipient?: Maybe<Scalars['String']>;
  ExportedtoPaymentFile?: Maybe<Scalars['Boolean']>;
  HasPaymentExportError?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobQuantity?: Maybe<Scalars['Float']>;
  JobUnitCostLCY?: Maybe<Scalars['Float']>;
  JobLineDiscount?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobUnitOfMeasureCode?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobUnitCost?: Maybe<Scalars['Float']>;
  JobTotalCost?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobTotalCostLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  JobPlanningLineNo?: Maybe<Scalars['Float']>;
  JobRemainingQty?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DataExchEntryNo?: Maybe<Scalars['Float']>;
  PayerInformation?: Maybe<Scalars['String']>;
  TransactionInformation?: Maybe<Scalars['String']>;
  DataExchLineNo?: Maybe<Scalars['Float']>;
  AppliedAutomatically?: Maybe<Scalars['Boolean']>;
  LinkedTableID?: Maybe<Scalars['Float']>;
  LinkedSystemID?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  DeferralLineNo?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  NoofDepreciationDays?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  FAReclassificationEntry?: Maybe<Scalars['Boolean']>;
  FAErrorEntryNo?: Maybe<Scalars['Float']>;
  IndexEntry?: Maybe<Scalars['Boolean']>;
  SourceLineNo?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  CheckExported?: Maybe<Scalars['Boolean']>;
  CheckTransmitted?: Maybe<Scalars['Boolean']>;
  ReverseDateCalculation?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  AppliestoInvoiceId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  JournalBatchId?: Maybe<Scalars['String']>;
  PaymentMethodId?: Maybe<Scalars['String']>;
  BalanceAccountId?: Maybe<Scalars['String']>;
  VendorId?: Maybe<Scalars['String']>;
  SourcePostingDate?: Maybe<Scalars['DateTime']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBDirectDebitPackNo?: Maybe<Scalars['Float']>;
  PEBInformationType?: Maybe<Scalars['Float']>;
  PEBInformationTypeOPTION?: Maybe<Scalars['String']>;
  PEBLastGLEntryNo?: Maybe<Scalars['Float']>;
  PEBLastGLRegEntryNo?: Maybe<Scalars['Float']>;
  PEBInformation?: Maybe<Scalars['String']>;
  PEBSIEImportCode?: Maybe<Scalars['String']>;
  PEBOCRNo?: Maybe<Scalars['String']>;
  PEBExtDocNonotMandat?: Maybe<Scalars['Boolean']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMSkipExtraPost?: Maybe<Scalars['Boolean']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMCustomerDepositNo?: Maybe<Scalars['String']>;
  EQMDisposalType?: Maybe<Scalars['Float']>;
  EQMDisposalTypeOPTION?: Maybe<Scalars['String']>;
  EQMDepositEntry?: Maybe<Scalars['Boolean']>;
  EQMAdvancePaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMAdvPaymentAmountLCY?: Maybe<Scalars['Float']>;
  EQMRentalEntry?: Maybe<Scalars['Boolean']>;
  EQMWorkshopEntry?: Maybe<Scalars['Boolean']>;
  EQMObjectErrorEntryNo?: Maybe<Scalars['Float']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMDepositPayment?: Maybe<Scalars['Boolean']>;
  EQMTransactionID?: Maybe<Scalars['Float']>;
  EQMFANo?: Maybe<Scalars['String']>;
  EQMFADepreciationBookCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type GenJournalLineConnection = {
  __typename?: 'GenJournalLineConnection';
  count: Scalars['Int'];
  edges: Array<GenJournalLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type GenJournalLineConnectionEdge = {
  __typename?: 'GenJournalLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: GenJournalLine;
};

export type GenJournalLineDeleteResponse = {
  __typename?: 'GenJournalLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<GenJournalLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GenJournalLineManyResult = {
  __typename?: 'GenJournalLineManyResult';
  records?: Maybe<Array<Maybe<GenJournalLine>>>;
  pageData?: Maybe<PageData>;
};

export type GenProductPostingGroup = {
  __typename?: 'GenProductPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefVATProdPostingGroup?: Maybe<Scalars['String']>;
  AutoInsertDefault?: Maybe<Scalars['Boolean']>;
  EQMPercentofMonthlyPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type GenProductPostingGroupConnection = {
  __typename?: 'GenProductPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<GenProductPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type GenProductPostingGroupConnectionEdge = {
  __typename?: 'GenProductPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: GenProductPostingGroup;
};

export type GenProductPostingGroupDeleteResponse = {
  __typename?: 'GenProductPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<GenProductPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GenProductPostingGroupManyResult = {
  __typename?: 'GenProductPostingGroupManyResult';
  records?: Maybe<Array<Maybe<GenProductPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type IosAppCode = {
  __typename?: 'IOSAppCode';
  code?: Maybe<Scalars['String']>;
  used?: Maybe<Scalars['Boolean']>;
};

export type Inspection = {
  __typename?: 'Inspection';
  discriminator?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  InspectionDate?: Maybe<Scalars['DateTime']>;
  ObjectStatus?: Maybe<Scalars['Float']>;
  ObjectStatusOPTION?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export type InspectionConnection = {
  __typename?: 'InspectionConnection';
  count: Scalars['Int'];
  edges: Array<InspectionConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type InspectionConnectionEdge = {
  __typename?: 'InspectionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Inspection;
};

export type InspectionDeleteResponse = {
  __typename?: 'InspectionDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Inspection>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InspectionManyResult = {
  __typename?: 'InspectionManyResult';
  records?: Maybe<Array<Maybe<Inspection>>>;
  pageData?: Maybe<PageData>;
};

export type InspectionUser = {
  __typename?: 'InspectionUser';
  discriminator?: Maybe<Scalars['String']>;
  SalesPerson?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type InspectionUserConnection = {
  __typename?: 'InspectionUserConnection';
  count: Scalars['Int'];
  edges: Array<InspectionUserConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type InspectionUserConnectionEdge = {
  __typename?: 'InspectionUserConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: InspectionUser;
};

export type InspectionUserDeleteResponse = {
  __typename?: 'InspectionUserDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<InspectionUser>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InspectionUserManyResult = {
  __typename?: 'InspectionUserManyResult';
  records?: Maybe<Array<Maybe<InspectionUser>>>;
  pageData?: Maybe<PageData>;
};

export type IntegrationCollection = {
  __typename?: 'IntegrationCollection';
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  LatestHeartBeat?: Maybe<Scalars['DateTime']>;
  FirstHeartBeat?: Maybe<Scalars['DateTime']>;
  HeartBeartIntervalMinutes?: Maybe<Scalars['Float']>;
  HeartbeatMonitoringEnabled?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationCollectionConnection = {
  __typename?: 'IntegrationCollectionConnection';
  count: Scalars['Int'];
  edges: Array<IntegrationCollectionConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type IntegrationCollectionConnectionEdge = {
  __typename?: 'IntegrationCollectionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: IntegrationCollection;
};

export type IntegrationCollectionDeleteResponse = {
  __typename?: 'IntegrationCollectionDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<IntegrationCollection>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IntegrationCollectionManyResult = {
  __typename?: 'IntegrationCollectionManyResult';
  records?: Maybe<Array<Maybe<IntegrationCollection>>>;
  pageData?: Maybe<PageData>;
};

export type InventoryPostingGroup = {
  __typename?: 'InventoryPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryPostingGroupConnection = {
  __typename?: 'InventoryPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<InventoryPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type InventoryPostingGroupConnectionEdge = {
  __typename?: 'InventoryPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: InventoryPostingGroup;
};

export type InventoryPostingGroupDeleteResponse = {
  __typename?: 'InventoryPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<InventoryPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InventoryPostingGroupManyResult = {
  __typename?: 'InventoryPostingGroupManyResult';
  records?: Maybe<Array<Maybe<InventoryPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type Invitation = {
  __typename?: 'Invitation';
  discriminator?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  Status?: Maybe<InvitationStatusEnum>;
  Type?: Maybe<InvitationTypeEnum>;
  Role?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  ExpiresOn?: Maybe<Scalars['DateTime']>;
  AcceptedOn?: Maybe<Scalars['DateTime']>;
  AcceptedByUserEmail?: Maybe<Scalars['String']>;
  CreatedByUserEmail?: Maybe<Scalars['String']>;
  CreateByUserName?: Maybe<Scalars['String']>;
  InvitationCode?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  Deactivated?: Maybe<Scalars['Boolean']>;
  IOSAppLink?: Maybe<Scalars['String']>;
  AndroidAppLink?: Maybe<Scalars['String']>;
  IOSAppCode?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  IOSAppCodes?: Maybe<Array<Maybe<IosAppCode>>>;
  IOSAppCodeCount?: Maybe<Scalars['Int']>;
};

export type InvitationConnection = {
  __typename?: 'InvitationConnection';
  count: Scalars['Int'];
  edges: Array<InvitationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type InvitationConnectionEdge = {
  __typename?: 'InvitationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Invitation;
};

export type InvitationDeleteResponse = {
  __typename?: 'InvitationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Invitation>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum InvitationInputRoleEnum {
  Customeradmin = 'customeradmin',
  Customeruser = 'customeruser'
}

export type InvitationLimited = {
  __typename?: 'InvitationLimited';
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
};

export type InvitationManyResult = {
  __typename?: 'InvitationManyResult';
  records?: Maybe<Array<Maybe<Invitation>>>;
  pageData?: Maybe<PageData>;
};

export enum InvitationStatusEnum {
  Active = 'active',
  Accepted = 'accepted',
  Paused = 'paused'
}

export enum InvitationTypeEnum {
  Personal = 'personal',
  Company = 'company',
  Domain = 'domain'
}

export type InvitationUpdateInput = {
  GlobalId: Scalars['String'];
  IOSAppLink?: Maybe<Scalars['String']>;
  AndroidAppLink?: Maybe<Scalars['String']>;
  IOSAppCode?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
};

export type InvoiceHeader = {
  __typename?: 'InvoiceHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DocumentExchangeIdentifier?: Maybe<Scalars['String']>;
  DocumentExchangeStatus?: Maybe<Scalars['String']>;
  DocExchOriginalIdentifier?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  CustLedgerEntryNo?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['String']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMCombineCustomerProj?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMInvoiceDocType?: Maybe<Scalars['String']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  EQMLinkedCreditMemos?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  invoiceLines?: Maybe<Array<Maybe<InvoiceLine>>>;
};

export type InvoiceHeaderConnection = {
  __typename?: 'InvoiceHeaderConnection';
  count: Scalars['Int'];
  edges: Array<InvoiceHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type InvoiceHeaderConnectionEdge = {
  __typename?: 'InvoiceHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: InvoiceHeader;
};

export type InvoiceHeaderDeleteResponse = {
  __typename?: 'InvoiceHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<InvoiceHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoiceHeaderManyResult = {
  __typename?: 'InvoiceHeaderManyResult';
  records?: Maybe<Array<Maybe<InvoiceHeader>>>;
  pageData?: Maybe<PageData>;
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  LineDiscountCalculation?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMUnitPrice?: Maybe<Scalars['Float']>;
  EQMLineDiscount?: Maybe<Scalars['Float']>;
  EQMLineDiscountAmount?: Maybe<Scalars['Float']>;
  EQMLineAmount?: Maybe<Scalars['Float']>;
  EQMReturnFromDate?: Maybe<Scalars['DateTime']>;
  EQMReturnToDate?: Maybe<Scalars['DateTime']>;
  EQMReturnWholePeriod?: Maybe<Scalars['Boolean']>;
  EQMReturnRentalDays?: Maybe<Scalars['Float']>;
  EQMReturnFreeDays?: Maybe<Scalars['Float']>;
  EQMTransferThisLine?: Maybe<Scalars['Boolean']>;
  EQMReturnQuantity?: Maybe<Scalars['Float']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMRentalLineType?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMAmountCost?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMRefOrigDoc?: Maybe<Scalars['String']>;
  EQMRefOrigLineNo?: Maybe<Scalars['Float']>;
  EQMManuallyChanged?: Maybe<Scalars['Boolean']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMAdvancePaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineConnection = {
  __typename?: 'InvoiceLineConnection';
  count: Scalars['Int'];
  edges: Array<InvoiceLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type InvoiceLineConnectionEdge = {
  __typename?: 'InvoiceLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: InvoiceLine;
};

export type InvoiceLineDeleteResponse = {
  __typename?: 'InvoiceLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<InvoiceLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoiceLineManyResult = {
  __typename?: 'InvoiceLineManyResult';
  records?: Maybe<Array<Maybe<InvoiceLine>>>;
  pageData?: Maybe<PageData>;
};

export type Item = {
  __typename?: 'Item';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  No2?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  PriceUnitConversion?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  ShelfNo?: Maybe<Scalars['String']>;
  ItemDiscGroup?: Maybe<Scalars['String']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  CommissionGroup?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  CostingMethod?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  CostisAdjusted?: Maybe<Scalars['Boolean']>;
  AllowOnlineAdjustment?: Maybe<Scalars['Boolean']>;
  VendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  ReorderPoint?: Maybe<Scalars['Float']>;
  MaximumInventory?: Maybe<Scalars['Float']>;
  ReorderQuantity?: Maybe<Scalars['Float']>;
  AlternativeItemNo?: Maybe<Scalars['String']>;
  UnitListPrice?: Maybe<Scalars['Float']>;
  DutyDue?: Maybe<Scalars['Float']>;
  DutyCode?: Maybe<Scalars['String']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  Durability?: Maybe<Scalars['String']>;
  FreightType?: Maybe<Scalars['String']>;
  TariffNo?: Maybe<Scalars['String']>;
  DutyUnitConversion?: Maybe<Scalars['Float']>;
  CountryRegionPurchasedCode?: Maybe<Scalars['String']>;
  BudgetQuantity?: Maybe<Scalars['Float']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  BudgetProfit?: Maybe<Scalars['Float']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  BlockReason?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  CountryRegionofOriginCode?: Maybe<Scalars['String']>;
  AutomaticExtTexts?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  VATProductPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  StockoutWarning?: Maybe<Scalars['String']>;
  PreventNegativeInventory?: Maybe<Scalars['String']>;
  ApplicationWkshUserID?: Maybe<Scalars['String']>;
  AssemblyPolicy?: Maybe<Scalars['String']>;
  GTIN?: Maybe<Scalars['String']>;
  DefaultDeferralTemplateCode?: Maybe<Scalars['String']>;
  LowLevelCode?: Maybe<Scalars['Float']>;
  LotSize?: Maybe<Scalars['Float']>;
  SerialNos?: Maybe<Scalars['String']>;
  LastUnitCostCalcDate?: Maybe<Scalars['DateTime']>;
  RolledupMaterialCost?: Maybe<Scalars['Float']>;
  RolledupCapacityCost?: Maybe<Scalars['Float']>;
  Scrap?: Maybe<Scalars['Float']>;
  InventoryValueZero?: Maybe<Scalars['Boolean']>;
  DiscreteOrderQuantity?: Maybe<Scalars['Float']>;
  MinimumOrderQuantity?: Maybe<Scalars['Float']>;
  MaximumOrderQuantity?: Maybe<Scalars['Float']>;
  SafetyStockQuantity?: Maybe<Scalars['Float']>;
  OrderMultiple?: Maybe<Scalars['Float']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  FlushingMethod?: Maybe<Scalars['String']>;
  ReplenishmentSystem?: Maybe<Scalars['String']>;
  RoundingPrecision?: Maybe<Scalars['Float']>;
  SalesUnitofMeasure?: Maybe<Scalars['String']>;
  PurchUnitofMeasure?: Maybe<Scalars['String']>;
  TimeBucket?: Maybe<Scalars['String']>;
  ReorderingPolicy?: Maybe<Scalars['String']>;
  IncludeInventory?: Maybe<Scalars['Boolean']>;
  ManufacturingPolicy?: Maybe<Scalars['String']>;
  ReschedulingPeriod?: Maybe<Scalars['String']>;
  LotAccumulationPeriod?: Maybe<Scalars['String']>;
  DampenerPeriod?: Maybe<Scalars['String']>;
  DampenerQuantity?: Maybe<Scalars['Float']>;
  OverflowLevel?: Maybe<Scalars['Float']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  CategoryCode?: Maybe<Scalars['String']>;
  CreatedFromNonstockItem?: Maybe<Scalars['Boolean']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ItemTrackingCode?: Maybe<Scalars['String']>;
  LotNos?: Maybe<Scalars['String']>;
  ExpirationCalculation?: Maybe<Scalars['String']>;
  WarehouseClassCode?: Maybe<Scalars['String']>;
  SpecialEquipmentCode?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  PutawayUnitofMeasureCode?: Maybe<Scalars['String']>;
  PhysInvtCountingPeriodCode?: Maybe<Scalars['String']>;
  LastCountingPeriodUpdate?: Maybe<Scalars['String']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  NextCountingStartDate?: Maybe<Scalars['DateTime']>;
  NextCountingEndDate?: Maybe<Scalars['DateTime']>;
  Id?: Maybe<Scalars['String']>;
  UnitofMeasureId?: Maybe<Scalars['String']>;
  TaxGroupId?: Maybe<Scalars['String']>;
  EQMItemNoConcern?: Maybe<Scalars['String']>;
  EQMDiamondBladeItem?: Maybe<Scalars['String']>;
  EQMDiamondPostingGroup?: Maybe<Scalars['String']>;
  EQMDimensionRentalType?: Maybe<Scalars['String']>;
  EQMMeasurement?: Maybe<Scalars['Float']>;
  EQMReRentRProdPostGrp?: Maybe<Scalars['String']>;
  EQMReRentSProdPostGrp?: Maybe<Scalars['String']>;
  EQMObjectBOMPostingGroup?: Maybe<Scalars['String']>;
  EQMVATRntlProdPostGrp?: Maybe<Scalars['String']>;
  EQMDepositAmount?: Maybe<Scalars['Float']>;
  EQMReplacementPrice?: Maybe<Scalars['Float']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMDescription3?: Maybe<Scalars['String']>;
  EQMDescription4?: Maybe<Scalars['String']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMBlockedRental?: Maybe<Scalars['Boolean']>;
  EQMExpAvailAfterEndDate?: Maybe<Scalars['Boolean']>;
  EQMCreditonEarlyReturn?: Maybe<Scalars['Float']>;
  EQMStdRentalChargeDays?: Maybe<Scalars['String']>;
  EQMDebitonReturnDate?: Maybe<Scalars['Boolean']>;
  EQMBaseCalendarCode?: Maybe<Scalars['String']>;
  EQMGenRentalPostingGroup?: Maybe<Scalars['String']>;
  EQMRentalUnitPrice?: Maybe<Scalars['Float']>;
  EQMDefaultRentalItem?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRentalItemDiscGroup?: Maybe<Scalars['String']>;
  EQMDimensionRentalGroup?: Maybe<Scalars['String']>;
  EQMGenRepurchasePostGrp?: Maybe<Scalars['String']>;
  EQMSquareMeter?: Maybe<Scalars['Float']>;
  EQMFilterLocationCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  ProductionBOMNo?: Maybe<Scalars['String']>;
  SingleLevelMaterialCost?: Maybe<Scalars['Float']>;
  SingleLevelCapacityCost?: Maybe<Scalars['Float']>;
  SingleLevelSubcontrdCost?: Maybe<Scalars['Float']>;
  SingleLevelCapOvhdCost?: Maybe<Scalars['Float']>;
  SingleLevelMfgOvhdCost?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RolledupSubcontractedCost?: Maybe<Scalars['Float']>;
  RolledupMfgOvhdCost?: Maybe<Scalars['Float']>;
  RolledupCapOverheadCost?: Maybe<Scalars['Float']>;
  OrderTrackingPolicy?: Maybe<Scalars['String']>;
  Critical?: Maybe<Scalars['Boolean']>;
  CommonItemNo?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DropShipmentFilter?: Maybe<Scalars['Boolean']>;
  BinFilter?: Maybe<Scalars['String']>;
  VariantFilter?: Maybe<Scalars['String']>;
  LotNoFilter?: Maybe<Scalars['String']>;
  SerialNoFilter?: Maybe<Scalars['String']>;
  EQMRentalSaleFilter?: Maybe<Scalars['Boolean']>;
  ProductionForecastName?: Maybe<Scalars['String']>;
  ComponentForecast?: Maybe<Scalars['Boolean']>;
  AssemblyBOM?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CostisPostedtoGL?: Maybe<Scalars['Boolean']>;
  Inventory?: Maybe<Scalars['Float']>;
  NetInvoicedQty?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  PurchasesQty?: Maybe<Scalars['Float']>;
  SalesQty?: Maybe<Scalars['Float']>;
  PositiveAdjmtQty?: Maybe<Scalars['Float']>;
  NegativeAdjmtQty?: Maybe<Scalars['Float']>;
  PurchasesLCY?: Maybe<Scalars['Float']>;
  SalesLCY?: Maybe<Scalars['Float']>;
  PositiveAdjmtLCY?: Maybe<Scalars['Float']>;
  NegativeAdjmtLCY?: Maybe<Scalars['Float']>;
  COGSLCY?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  QtyonSalesOrder?: Maybe<Scalars['Float']>;
  TransferredQty?: Maybe<Scalars['Float']>;
  TransferredLCY?: Maybe<Scalars['Float']>;
  ReservedQtyonInventory?: Maybe<Scalars['Float']>;
  ReservedQtyonPurchOrders?: Maybe<Scalars['Float']>;
  ReservedQtyonSalesOrders?: Maybe<Scalars['Float']>;
  ResQtyonOutboundTransfer?: Maybe<Scalars['Float']>;
  ResQtyonInboundTransfer?: Maybe<Scalars['Float']>;
  ResQtyonSalesReturns?: Maybe<Scalars['Float']>;
  ResQtyonPurchReturns?: Maybe<Scalars['Float']>;
  CostofOpenProductionOrders?: Maybe<Scalars['Float']>;
  ResQtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  ResQtyonAsmComp?: Maybe<Scalars['Float']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonAsmComponent?: Maybe<Scalars['Float']>;
  QtyonJobOrder?: Maybe<Scalars['Float']>;
  ResQtyonJobOrder?: Maybe<Scalars['Float']>;
  ScheduledReceiptQty?: Maybe<Scalars['Float']>;
  ScheduledNeedQty?: Maybe<Scalars['Float']>;
  ReservedQtyonProdOrder?: Maybe<Scalars['Float']>;
  ResQtyonProdOrderComp?: Maybe<Scalars['Float']>;
  ResQtyonReqLine?: Maybe<Scalars['Float']>;
  PlanningTransferShipQty?: Maybe<Scalars['Float']>;
  PlanningWorksheetQty?: Maybe<Scalars['Float']>;
  StockkeepingUnitExists?: Maybe<Scalars['Boolean']>;
  SubstitutesExist?: Maybe<Scalars['Boolean']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  TransOrdReceiptQty?: Maybe<Scalars['Float']>;
  TransOrdShipmentQty?: Maybe<Scalars['Float']>;
  QtyAssignedtoship?: Maybe<Scalars['Float']>;
  QtyPicked?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  ResQtyonServiceOrders?: Maybe<Scalars['Float']>;
  QtyonPurchReturn?: Maybe<Scalars['Float']>;
  QtyonSalesReturn?: Maybe<Scalars['Float']>;
  NoofSubstitutes?: Maybe<Scalars['Float']>;
  LastPhysInvtDate?: Maybe<Scalars['DateTime']>;
  IdentifierCode?: Maybe<Scalars['String']>;
  EQMAvailabletoTake?: Maybe<Scalars['Float']>;
  EQMUseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  EQMRentalReservationsQty?: Maybe<Scalars['Float']>;
  EQMRentalDeliveriesQty?: Maybe<Scalars['Float']>;
  EQMExpRentalReturnsQty?: Maybe<Scalars['Float']>;
  EQMRentalReturnsQty?: Maybe<Scalars['Float']>;
  EQMQtyonRentalReRent?: Maybe<Scalars['Float']>;
  EQMQtyonRent?: Maybe<Scalars['Float']>;
  PlanningIssuesQty?: Maybe<Scalars['Float']>;
  PlanningReceiptQty?: Maybe<Scalars['Float']>;
  PlannedOrderReceiptQty?: Maybe<Scalars['Float']>;
  FPOrderReceiptQty?: Maybe<Scalars['Float']>;
  RelOrderReceiptQty?: Maybe<Scalars['Float']>;
  PlanningReleaseQty?: Maybe<Scalars['Float']>;
  PlannedOrderReleaseQty?: Maybe<Scalars['Float']>;
  PurchReqReceiptQty?: Maybe<Scalars['Float']>;
  PurchReqReleaseQty?: Maybe<Scalars['Float']>;
  ProdForecastQuantityBase?: Maybe<Scalars['Float']>;
  QtyonProdOrder?: Maybe<Scalars['Float']>;
  QtyonComponentLines?: Maybe<Scalars['Float']>;
  MagentoId?: Maybe<Scalars['Float']>;
  SynkedToMagento?: Maybe<Scalars['Boolean']>;
  MagentoSyncProblems?: Maybe<Scalars['String']>;
  AttributeSetId?: Maybe<Scalars['Float']>;
  Visibility?: Maybe<Scalars['Float']>;
  TypeId?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['Float']>;
  CalculatedInventory?: Maybe<Scalars['Float']>;
  CalculatedInventoryUOM?: Maybe<Scalars['String']>;
  CalculatedInventoryUpdatedAd?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
  ActiveWeb?: Maybe<Scalars['Boolean']>;
  TempOutOfStock?: Maybe<Scalars['Boolean']>;
  OutletSE?: Maybe<Scalars['Boolean']>;
  OutletDateFromSE?: Maybe<Scalars['DateTime']>;
  OutletDateToSE?: Maybe<Scalars['DateTime']>;
  CampaignSE?: Maybe<Scalars['Boolean']>;
  CampaignDateFromSE?: Maybe<Scalars['DateTime']>;
  CampaignDateToSE?: Maybe<Scalars['DateTime']>;
  DeliveryInformation?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  IsBulky?: Maybe<Scalars['Boolean']>;
  OrderItem?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  PackageHeight?: Maybe<Scalars['Float']>;
  PackageWidth?: Maybe<Scalars['Float']>;
  PackageLength?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
  DescriptionNo?: Maybe<Scalars['String']>;
  BrandDescription?: Maybe<Scalars['String']>;
  DeliveryTime?: Maybe<Scalars['String']>;
  HomeDelivery?: Maybe<Scalars['Boolean']>;
  ServicePointDelivery?: Maybe<Scalars['Boolean']>;
  BlockedNO?: Maybe<Scalars['Boolean']>;
  ExpectedInStockDate?: Maybe<Scalars['DateTime']>;
  BulkGroup?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsCampaign?: Maybe<Scalars['Boolean']>;
  GiftcardType?: Maybe<Scalars['String']>;
  TaxClassId?: Maybe<Scalars['String']>;
  UrlKey?: Maybe<Scalars['String']>;
  ShowInFieldOrder?: Maybe<Scalars['Boolean']>;
  ItemType?: Maybe<ItemItemTypeEnum>;
  ItemTypeOPTION?: Maybe<Scalars['String']>;
  SalesOrderMultiple?: Maybe<Scalars['Float']>;
  EcoLabel?: Maybe<Scalars['String']>;
  MainGroupCode?: Maybe<Scalars['String']>;
  ItemGroupCode?: Maybe<Scalars['String']>;
  OldItemNo?: Maybe<Scalars['String']>;
  OldItemNo2?: Maybe<Scalars['String']>;
  RKVItem?: Maybe<Scalars['Boolean']>;
  ToExpire?: Maybe<Scalars['Boolean']>;
  AcquisitionItem?: Maybe<Scalars['Boolean']>;
  MagentoItem?: Maybe<Scalars['Boolean']>;
  AlwaysAvailableOnWeb?: Maybe<Scalars['Boolean']>;
  QBSId?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  EdgeSmallImageUrl?: Maybe<Scalars['String']>;
  EdgeLargelImageUrl?: Maybe<Scalars['String']>;
  CalculationPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ParentCategory?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Indentation?: Maybe<Scalars['Float']>;
  PresentationOrder?: Maybe<Scalars['Float']>;
  HasChildren?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  DefGenProdPostingGroup?: Maybe<Scalars['String']>;
  DefInventoryPostingGroup?: Maybe<Scalars['String']>;
  DefTaxGroupCode?: Maybe<Scalars['String']>;
  DefCostingMethod?: Maybe<Scalars['Float']>;
  DefCostingMethodOPTION?: Maybe<Scalars['String']>;
  DefVATProdPostingGroup?: Maybe<Scalars['String']>;
  DefReRentRProdPostGrp?: Maybe<Scalars['String']>;
  DefReRentSProdPostGrp?: Maybe<Scalars['String']>;
  EQMDefGenRntlPostGrp?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemCategoryConnection = {
  __typename?: 'ItemCategoryConnection';
  count: Scalars['Int'];
  edges: Array<ItemCategoryConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemCategoryConnectionEdge = {
  __typename?: 'ItemCategoryConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ItemCategory;
};

export type ItemCategoryDeleteResponse = {
  __typename?: 'ItemCategoryDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ItemCategory>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemCategoryManyResult = {
  __typename?: 'ItemCategoryManyResult';
  records?: Maybe<Array<Maybe<ItemCategory>>>;
  pageData?: Maybe<PageData>;
};

export type ItemConnection = {
  __typename?: 'ItemConnection';
  count: Scalars['Int'];
  edges: Array<ItemConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemConnectionEdge = {
  __typename?: 'ItemConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Item;
};

export type ItemDeleteResponse = {
  __typename?: 'ItemDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Item>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemDiscGroup = {
  __typename?: 'ItemDiscGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemDiscGroupConnection = {
  __typename?: 'ItemDiscGroupConnection';
  count: Scalars['Int'];
  edges: Array<ItemDiscGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemDiscGroupConnectionEdge = {
  __typename?: 'ItemDiscGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ItemDiscGroup;
};

export type ItemDiscGroupDeleteResponse = {
  __typename?: 'ItemDiscGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ItemDiscGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemDiscGroupManyResult = {
  __typename?: 'ItemDiscGroupManyResult';
  records?: Maybe<Array<Maybe<ItemDiscGroup>>>;
  pageData?: Maybe<PageData>;
};

export enum ItemItemTypeEnum {
  Empty = 'Empty',
  StockItem = 'StockItem',
  OrderItem = 'OrderItem'
}

export type ItemManyResult = {
  __typename?: 'ItemManyResult';
  records?: Maybe<Array<Maybe<Item>>>;
  pageData?: Maybe<PageData>;
};

export type ItemReference = {
  __typename?: 'ItemReference';
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  ReferenceType?: Maybe<Scalars['Float']>;
  ReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ReferenceTypeNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscontinueBarCode?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  ExternalNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemReferenceConnection = {
  __typename?: 'ItemReferenceConnection';
  count: Scalars['Int'];
  edges: Array<ItemReferenceConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemReferenceConnectionEdge = {
  __typename?: 'ItemReferenceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ItemReference;
};

export type ItemReferenceDeleteResponse = {
  __typename?: 'ItemReferenceDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ItemReference>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemReferenceManyResult = {
  __typename?: 'ItemReferenceManyResult';
  records?: Maybe<Array<Maybe<ItemReference>>>;
  pageData?: Maybe<PageData>;
};

export type ItemRestriction = {
  __typename?: 'ItemRestriction';
  discriminator?: Maybe<Scalars['String']>;
  Type?: Maybe<ItemRestrictionTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemRestrictionConnection = {
  __typename?: 'ItemRestrictionConnection';
  count: Scalars['Int'];
  edges: Array<ItemRestrictionConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemRestrictionConnectionEdge = {
  __typename?: 'ItemRestrictionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ItemRestriction;
};

export type ItemRestrictionDeleteResponse = {
  __typename?: 'ItemRestrictionDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ItemRestriction>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemRestrictionManyResult = {
  __typename?: 'ItemRestrictionManyResult';
  records?: Maybe<Array<Maybe<ItemRestriction>>>;
  pageData?: Maybe<PageData>;
};

export enum ItemRestrictionTypeEnum {
  Customer = 'Customer',
  CustomerPriceGroup = 'CustomerPriceGroup',
  Country = 'Country'
}

export type ItemUnitOfMeasure = {
  __typename?: 'ItemUnitOfMeasure';
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QtyRoundingPrecision?: Maybe<Scalars['Float']>;
  Length?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  Height?: Maybe<Scalars['Float']>;
  Cubage?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemUnitOfMeasureConnection = {
  __typename?: 'ItemUnitOfMeasureConnection';
  count: Scalars['Int'];
  edges: Array<ItemUnitOfMeasureConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemUnitOfMeasureConnectionEdge = {
  __typename?: 'ItemUnitOfMeasureConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ItemUnitOfMeasure;
};

export type ItemUnitOfMeasureDeleteResponse = {
  __typename?: 'ItemUnitOfMeasureDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ItemUnitOfMeasure>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemUnitOfMeasureManyResult = {
  __typename?: 'ItemUnitOfMeasureManyResult';
  records?: Maybe<Array<Maybe<ItemUnitOfMeasure>>>;
  pageData?: Maybe<PageData>;
};

export type ItemVendor = {
  __typename?: 'ItemVendor';
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ItemVendorConnection = {
  __typename?: 'ItemVendorConnection';
  count: Scalars['Int'];
  edges: Array<ItemVendorConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ItemVendorConnectionEdge = {
  __typename?: 'ItemVendorConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ItemVendor;
};

export type ItemVendorDeleteResponse = {
  __typename?: 'ItemVendorDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ItemVendor>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ItemVendorManyResult = {
  __typename?: 'ItemVendorManyResult';
  records?: Maybe<Array<Maybe<ItemVendor>>>;
  pageData?: Maybe<PageData>;
};

export type JobTask = {
  __typename?: 'JobTask';
  discriminator?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  JobTaskType?: Maybe<Scalars['Float']>;
  JobTaskTypeOPTION?: Maybe<Scalars['String']>;
  WIPTotal?: Maybe<Scalars['Float']>;
  WIPTotalOPTION?: Maybe<Scalars['String']>;
  JobPostingGroup?: Maybe<Scalars['String']>;
  WIPMethod?: Maybe<Scalars['String']>;
  Totaling?: Maybe<Scalars['String']>;
  NewPage?: Maybe<Scalars['Boolean']>;
  NoofBlankLines?: Maybe<Scalars['Float']>;
  Indentation?: Maybe<Scalars['Float']>;
  RecognizedSalesAmount?: Maybe<Scalars['Float']>;
  RecognizedCostsAmount?: Maybe<Scalars['Float']>;
  RecognizedSalesGLAmount?: Maybe<Scalars['Float']>;
  RecognizedCostsGLAmount?: Maybe<Scalars['Float']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PostingDateFilter?: Maybe<Scalars['String']>;
  PlanningDateFilter?: Maybe<Scalars['String']>;
  ScheduleTotalCost?: Maybe<Scalars['Float']>;
  ScheduleTotalPrice?: Maybe<Scalars['Float']>;
  UsageTotalCost?: Maybe<Scalars['Float']>;
  UsageTotalPrice?: Maybe<Scalars['Float']>;
  ContractTotalCost?: Maybe<Scalars['Float']>;
  ContractTotalPrice?: Maybe<Scalars['Float']>;
  ContractInvoicedPrice?: Maybe<Scalars['Float']>;
  ContractInvoicedCost?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  RemainingTotalCost?: Maybe<Scalars['Float']>;
  RemainingTotalPrice?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  ClosedOn?: Maybe<Scalars['DateTime']>;
  FirstSavedOn?: Maybe<Scalars['DateTime']>;
  DevopsId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type JobTaskConnection = {
  __typename?: 'JobTaskConnection';
  count: Scalars['Int'];
  edges: Array<JobTaskConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type JobTaskConnectionEdge = {
  __typename?: 'JobTaskConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: JobTask;
};

export type JobTaskDeleteResponse = {
  __typename?: 'JobTaskDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<JobTask>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type JobTaskManyResult = {
  __typename?: 'JobTaskManyResult';
  records?: Maybe<Array<Maybe<JobTask>>>;
  pageData?: Maybe<PageData>;
};

export type KliHeader = {
  __typename?: 'KliHeader';
  discriminator?: Maybe<Scalars['String']>;
  ErrandNo?: Maybe<Scalars['Float']>;
  User?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  NameDescription?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['Float']>;
  Timemin?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  QAdistdat?: Maybe<Scalars['DateTime']>;
  FinnishedDate?: Maybe<Scalars['DateTime']>;
  No?: Maybe<Scalars['String']>;
  CorectivAction?: Maybe<Scalars['String']>;
  NewInvoice?: Maybe<Scalars['Boolean']>;
  OrderNo?: Maybe<Scalars['String']>;
  CurrentOwnerEmail?: Maybe<Scalars['String']>;
  Closed?: Maybe<Scalars['Boolean']>;
  CostPerMin?: Maybe<Scalars['Float']>;
  ReasonText?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ImpactOnStock?: Maybe<Scalars['Boolean']>;
  CustomerNo?: Maybe<Scalars['String']>;
  InternCost?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDate?: Maybe<Scalars['String']>;
  Guarantee?: Maybe<Scalars['Boolean']>;
  ReasonCodeDesc?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Workplace?: Maybe<Scalars['String']>;
  Caller?: Maybe<Scalars['String']>;
  CallerPhone?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ModelYear?: Maybe<Scalars['String']>;
  Pirority?: Maybe<Scalars['Boolean']>;
  RecType?: Maybe<Scalars['Float']>;
  RecTypeOPTION?: Maybe<Scalars['String']>;
  RevisitNeeded?: Maybe<Scalars['Boolean']>;
  MachineReplaced?: Maybe<Scalars['Boolean']>;
  AnalysisCode?: Maybe<Scalars['String']>;
  AnalysisText?: Maybe<Scalars['String']>;
  MainErrand?: Maybe<Scalars['Boolean']>;
  LinkErrand?: Maybe<Scalars['Float']>;
  City?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  SendToRepairer?: Maybe<Scalars['String']>;
  FollowUp?: Maybe<Scalars['String']>;
  ErrandDescription?: Maybe<Scalars['String']>;
  FollowUpPreformed?: Maybe<Scalars['Boolean']>;
  CorectivActionPreformed?: Maybe<Scalars['Boolean']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  LastStatusBeforeClose?: Maybe<Scalars['Float']>;
  LastStatusBeforeCloseOPTION?: Maybe<Scalars['String']>;
  WorkplaceContact?: Maybe<Scalars['String']>;
  WorkplaceContactPhone?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  TableNo?: Maybe<Scalars['Float']>;
  ReasonTextBlob?: Maybe<Scalars['String']>;
  ActionTextBlob?: Maybe<Scalars['String']>;
  FixedByExternalRepairer?: Maybe<Scalars['Boolean']>;
  TurnaroundTime?: Maybe<Scalars['String']>;
  ReportedToWorkingEnvDpt?: Maybe<Scalars['Boolean']>;
  SafteyOfficerInformed?: Maybe<Scalars['Boolean']>;
  NewRiskAnalysis?: Maybe<Scalars['Boolean']>;
  AnalysisTextBLOB?: Maybe<Scalars['String']>;
  FollowUpBLOB?: Maybe<Scalars['String']>;
  CorectivActionBLOB?: Maybe<Scalars['String']>;
  AccidentComment?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  Note?: Maybe<Scalars['String']>;
  CreditNo?: Maybe<Scalars['String']>;
  CreditPosted?: Maybe<Scalars['Boolean']>;
  CreditApproved?: Maybe<Scalars['Boolean']>;
  CreditApprovedBy?: Maybe<Scalars['String']>;
  NoOfLinkedErrands?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
  TurnOverToEmail?: Maybe<Scalars['String']>;
  CreatedExternal?: Maybe<Scalars['Boolean']>;
  Template?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type KliHeaderConnection = {
  __typename?: 'KliHeaderConnection';
  count: Scalars['Int'];
  edges: Array<KliHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type KliHeaderConnectionEdge = {
  __typename?: 'KliHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: KliHeader;
};

export type KliHeaderDeleteResponse = {
  __typename?: 'KliHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<KliHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KliHeaderManyResult = {
  __typename?: 'KliHeaderManyResult';
  records?: Maybe<Array<Maybe<KliHeader>>>;
  pageData?: Maybe<PageData>;
};

export type KliReasonCode = {
  __typename?: 'KliReasonCode';
  discriminator?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RecType?: Maybe<Scalars['Float']>;
  RecTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type KliReasonCodeConnection = {
  __typename?: 'KliReasonCodeConnection';
  count: Scalars['Int'];
  edges: Array<KliReasonCodeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type KliReasonCodeConnectionEdge = {
  __typename?: 'KliReasonCodeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: KliReasonCode;
};

export type KliReasonCodeDeleteResponse = {
  __typename?: 'KliReasonCodeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<KliReasonCode>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type KliReasonCodeManyResult = {
  __typename?: 'KliReasonCodeManyResult';
  records?: Maybe<Array<Maybe<KliReasonCode>>>;
  pageData?: Maybe<PageData>;
};

export type Lead = {
  __typename?: 'Lead';
  discriminator?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  PictureURL?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CompanyNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Surname?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Initials?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  OwnerName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type LeadConnection = {
  __typename?: 'LeadConnection';
  count: Scalars['Int'];
  edges: Array<LeadConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type LeadConnectionEdge = {
  __typename?: 'LeadConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Lead;
};

export type LeadDeleteResponse = {
  __typename?: 'LeadDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Lead>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LeadManyResult = {
  __typename?: 'LeadManyResult';
  records?: Maybe<Array<Maybe<Lead>>>;
  pageData?: Maybe<PageData>;
};

export type LimitedCustomerProject = {
  __typename?: 'LimitedCustomerProject';
  ProjectNo: Scalars['String'];
  Deactivated?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  IsUsingDomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LimitedUpdateCustomerProjectInput = {
  ProjectNo: Scalars['String'];
  Deactivated?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  IsUsingDomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LimitedUpdateCustomerProjectResponse = {
  __typename?: 'LimitedUpdateCustomerProjectResponse';
  record: LimitedCustomerProject;
  ok: Scalars['Boolean'];
  recordsModified: Scalars['Int'];
};

export type LineAmount = {
  __typename?: 'LineAmount';
  StartDate?: Maybe<Scalars['DateTime']>;
  PeriodName?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type Location = {
  __typename?: 'Location';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultBinCode?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  UseAsInTransit?: Maybe<Scalars['Boolean']>;
  RequirePutaway?: Maybe<Scalars['Boolean']>;
  RequirePick?: Maybe<Scalars['Boolean']>;
  CrossDockDueDateCalc?: Maybe<Scalars['String']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  RequireReceive?: Maybe<Scalars['Boolean']>;
  RequireShipment?: Maybe<Scalars['Boolean']>;
  BinMandatory?: Maybe<Scalars['Boolean']>;
  DirectedPutawayandPick?: Maybe<Scalars['Boolean']>;
  DefaultBinSelection?: Maybe<Scalars['Float']>;
  DefaultBinSelectionOPTION?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  UsePutawayWorksheet?: Maybe<Scalars['Boolean']>;
  PickAccordingtoFEFO?: Maybe<Scalars['Boolean']>;
  AllowBreakbulk?: Maybe<Scalars['Boolean']>;
  BinCapacityPolicy?: Maybe<Scalars['Float']>;
  BinCapacityPolicyOPTION?: Maybe<Scalars['String']>;
  OpenShopFloorBinCode?: Maybe<Scalars['String']>;
  ToProductionBinCode?: Maybe<Scalars['String']>;
  FromProductionBinCode?: Maybe<Scalars['String']>;
  AdjustmentBinCode?: Maybe<Scalars['String']>;
  AlwaysCreatePutawayLine?: Maybe<Scalars['Boolean']>;
  AlwaysCreatePickLine?: Maybe<Scalars['Boolean']>;
  SpecialEquipment?: Maybe<Scalars['Float']>;
  SpecialEquipmentOPTION?: Maybe<Scalars['String']>;
  ReceiptBinCode?: Maybe<Scalars['String']>;
  ShipmentBinCode?: Maybe<Scalars['String']>;
  CrossDockBinCode?: Maybe<Scalars['String']>;
  ToAssemblyBinCode?: Maybe<Scalars['String']>;
  FromAssemblyBinCode?: Maybe<Scalars['String']>;
  AsmtoOrderShptBinCode?: Maybe<Scalars['String']>;
  ToJobBinCode?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  UseADCS?: Maybe<Scalars['Boolean']>;
  EQMInternalCustomerNo?: Maybe<Scalars['String']>;
  EQMReclassJnlTemplName?: Maybe<Scalars['String']>;
  EQMReclassJnlBatchName?: Maybe<Scalars['String']>;
  EQMMaintenanceLocation?: Maybe<Scalars['Boolean']>;
  EQMHUB?: Maybe<Scalars['Boolean']>;
  ExternalLocationId?: Maybe<Scalars['Float']>;
  ExternalLocationCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  count: Scalars['Int'];
  edges: Array<LocationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type LocationConnectionEdge = {
  __typename?: 'LocationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Location;
};

export type LocationDeleteResponse = {
  __typename?: 'LocationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Location>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LocationManyResult = {
  __typename?: 'LocationManyResult';
  records?: Maybe<Array<Maybe<Location>>>;
  pageData?: Maybe<PageData>;
};

export type MagentoOrderStatus = {
  __typename?: 'MagentoOrderStatus';
  discriminator?: Maybe<Scalars['String']>;
  EntityId?: Maybe<Scalars['Float']>;
  IncrementId?: Maybe<Scalars['String']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['DateTime']>;
  UpdatedAt?: Maybe<Scalars['DateTime']>;
  CheckCount?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type MagentoOrderStatusConnection = {
  __typename?: 'MagentoOrderStatusConnection';
  count: Scalars['Int'];
  edges: Array<MagentoOrderStatusConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MagentoOrderStatusConnectionEdge = {
  __typename?: 'MagentoOrderStatusConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: MagentoOrderStatus;
};

export type MagentoOrderStatusDeleteResponse = {
  __typename?: 'MagentoOrderStatusDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<MagentoOrderStatus>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MagentoOrderStatusManyResult = {
  __typename?: 'MagentoOrderStatusManyResult';
  records?: Maybe<Array<Maybe<MagentoOrderStatus>>>;
  pageData?: Maybe<PageData>;
};

export type MagentoSettings = {
  __typename?: 'MagentoSettings';
  discriminator?: Maybe<Scalars['String']>;
  QuestCustomerNo?: Maybe<Scalars['String']>;
  QuestCustomerName?: Maybe<Scalars['String']>;
  QuestCustomerGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type MagentoSettingsConnection = {
  __typename?: 'MagentoSettingsConnection';
  count: Scalars['Int'];
  edges: Array<MagentoSettingsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MagentoSettingsConnectionEdge = {
  __typename?: 'MagentoSettingsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: MagentoSettings;
};

export type MagentoSettingsDeleteResponse = {
  __typename?: 'MagentoSettingsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<MagentoSettings>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MagentoSettingsManyResult = {
  __typename?: 'MagentoSettingsManyResult';
  records?: Maybe<Array<Maybe<MagentoSettings>>>;
  pageData?: Maybe<PageData>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ManufacturerConnection = {
  __typename?: 'ManufacturerConnection';
  count: Scalars['Int'];
  edges: Array<ManufacturerConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ManufacturerConnectionEdge = {
  __typename?: 'ManufacturerConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Manufacturer;
};

export type ManufacturerDeleteResponse = {
  __typename?: 'ManufacturerDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Manufacturer>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ManufacturerManyResult = {
  __typename?: 'ManufacturerManyResult';
  records?: Maybe<Array<Maybe<Manufacturer>>>;
  pageData?: Maybe<PageData>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Will return true */
  ping?: Maybe<Scalars['Boolean']>;
  upsertAccessRequest?: Maybe<AccessRequest>;
  removeAccessRequest?: Maybe<AccessRequestDeleteResponse>;
  removeAccessRequestMany?: Maybe<AccessRequestDeleteResponse>;
  upsertAdditionalItem?: Maybe<AdditionalItem>;
  removeAdditionalItem?: Maybe<AdditionalItemDeleteResponse>;
  removeAdditionalItemMany?: Maybe<AdditionalItemDeleteResponse>;
  upsertAddress?: Maybe<Address>;
  removeAddress?: Maybe<AddressDeleteResponse>;
  removeAddressMany?: Maybe<AddressDeleteResponse>;
  upsertAgreement?: Maybe<Agreement>;
  removeAgreement?: Maybe<AgreementDeleteResponse>;
  removeAgreementMany?: Maybe<AgreementDeleteResponse>;
  upsertAnnotation?: Maybe<Annotation>;
  removeAnnotation?: Maybe<AnnotationDeleteResponse>;
  removeAnnotationMany?: Maybe<AnnotationDeleteResponse>;
  upsertApplicationModule?: Maybe<ApplicationModule>;
  removeApplicationModule?: Maybe<ApplicationModuleDeleteResponse>;
  removeApplicationModuleMany?: Maybe<ApplicationModuleDeleteResponse>;
  upsertBcMappingTable?: Maybe<BcMappingTable>;
  removeBcMappingTable?: Maybe<BcMappingTableDeleteResponse>;
  removeBcMappingTableMany?: Maybe<BcMappingTableDeleteResponse>;
  upsertBcMappingField?: Maybe<BcMappingField>;
  removeBcMappingField?: Maybe<BcMappingFieldDeleteResponse>;
  removeBcMappingFieldMany?: Maybe<BcMappingFieldDeleteResponse>;
  upsertBcSettings?: Maybe<BcSettings>;
  removeBcSettings?: Maybe<BcSettingsDeleteResponse>;
  removeBcSettingsMany?: Maybe<BcSettingsDeleteResponse>;
  upsertBulkItemGroup?: Maybe<BulkItemGroup>;
  removeBulkItemGroup?: Maybe<BulkItemGroupDeleteResponse>;
  removeBulkItemGroupMany?: Maybe<BulkItemGroupDeleteResponse>;
  upsertCauseOfAbsence?: Maybe<CauseOfAbsence>;
  removeCauseOfAbsence?: Maybe<CauseOfAbsenceDeleteResponse>;
  removeCauseOfAbsenceMany?: Maybe<CauseOfAbsenceDeleteResponse>;
  upsertCompany?: Maybe<Company>;
  removeCompany?: Maybe<CompanyDeleteResponse>;
  removeCompanyMany?: Maybe<CompanyDeleteResponse>;
  upsertConsignmentHeader?: Maybe<ConsignmentHeader>;
  removeConsignmentHeader?: Maybe<ConsignmentHeaderDeleteResponse>;
  removeConsignmentHeaderMany?: Maybe<ConsignmentHeaderDeleteResponse>;
  upsertConsignmentLine?: Maybe<ConsignmentLine>;
  removeConsignmentLine?: Maybe<ConsignmentLineDeleteResponse>;
  removeConsignmentLineMany?: Maybe<ConsignmentLineDeleteResponse>;
  upsertContact?: Maybe<Contact>;
  removeContact?: Maybe<ContactDeleteResponse>;
  removeContactMany?: Maybe<ContactDeleteResponse>;
  upsertCountryRegion?: Maybe<CountryRegion>;
  removeCountryRegion?: Maybe<CountryRegionDeleteResponse>;
  removeCountryRegionMany?: Maybe<CountryRegionDeleteResponse>;
  upsertCurrency?: Maybe<Currency>;
  removeCurrency?: Maybe<CurrencyDeleteResponse>;
  removeCurrencyMany?: Maybe<CurrencyDeleteResponse>;
  upsertCurrencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  removeCurrencyExchangeRate?: Maybe<CurrencyExchangeRateDeleteResponse>;
  removeCurrencyExchangeRateMany?: Maybe<CurrencyExchangeRateDeleteResponse>;
  upsertCustomerDomain?: Maybe<CustomerDomain>;
  removeCustomerDomain?: Maybe<CustomerDomainDeleteResponse>;
  removeCustomerDomainMany?: Maybe<CustomerDomainDeleteResponse>;
  upsertCustomerPostingGroup?: Maybe<CustomerPostingGroup>;
  removeCustomerPostingGroup?: Maybe<CustomerPostingGroupDeleteResponse>;
  removeCustomerPostingGroupMany?: Maybe<CustomerPostingGroupDeleteResponse>;
  upsertCustomerProject?: Maybe<CustomerProject>;
  removeCustomerProject?: Maybe<CustomerProjectDeleteResponse>;
  removeCustomerProjectMany?: Maybe<CustomerProjectDeleteResponse>;
  upsertCustomerSubProject?: Maybe<CustomerSubProject>;
  removeCustomerSubProject?: Maybe<CustomerSubProjectDeleteResponse>;
  removeCustomerSubProjectMany?: Maybe<CustomerSubProjectDeleteResponse>;
  upsertDeliverytime?: Maybe<Deliverytime>;
  removeDeliverytime?: Maybe<DeliverytimeDeleteResponse>;
  removeDeliverytimeMany?: Maybe<DeliverytimeDeleteResponse>;
  upsertDevopsProject?: Maybe<DevopsProject>;
  removeDevopsProject?: Maybe<DevopsProjectDeleteResponse>;
  removeDevopsProjectMany?: Maybe<DevopsProjectDeleteResponse>;
  upsertDevopsTeam?: Maybe<DevopsTeam>;
  removeDevopsTeam?: Maybe<DevopsTeamDeleteResponse>;
  removeDevopsTeamMany?: Maybe<DevopsTeamDeleteResponse>;
  upsertDevopsWorkItem?: Maybe<DevopsWorkItem>;
  removeDevopsWorkItem?: Maybe<DevopsWorkItemDeleteResponse>;
  removeDevopsWorkItemMany?: Maybe<DevopsWorkItemDeleteResponse>;
  upsertDimension?: Maybe<Dimension>;
  removeDimension?: Maybe<DimensionDeleteResponse>;
  removeDimensionMany?: Maybe<DimensionDeleteResponse>;
  upsertDimensionValue?: Maybe<DimensionValue>;
  removeDimensionValue?: Maybe<DimensionValueDeleteResponse>;
  removeDimensionValueMany?: Maybe<DimensionValueDeleteResponse>;
  upsertDiscussionPost?: Maybe<DiscussionPost>;
  removeDiscussionPost?: Maybe<DiscussionPostDeleteResponse>;
  removeDiscussionPostMany?: Maybe<DiscussionPostDeleteResponse>;
  upsertDocumentClassification?: Maybe<DocumentClassification>;
  removeDocumentClassification?: Maybe<DocumentClassificationDeleteResponse>;
  removeDocumentClassificationMany?: Maybe<DocumentClassificationDeleteResponse>;
  upsertDocumentRequest?: Maybe<DocumentRequest>;
  removeDocumentRequest?: Maybe<DocumentRequestDeleteResponse>;
  removeDocumentRequestMany?: Maybe<DocumentRequestDeleteResponse>;
  upsertDomainSettings?: Maybe<DomainSettings>;
  removeDomainSettings?: Maybe<DomainSettingsDeleteResponse>;
  removeDomainSettingsMany?: Maybe<DomainSettingsDeleteResponse>;
  upsertEqmBaseCalendar?: Maybe<EqmBaseCalendar>;
  removeEqmBaseCalendar?: Maybe<EqmBaseCalendarDeleteResponse>;
  removeEqmBaseCalendarMany?: Maybe<EqmBaseCalendarDeleteResponse>;
  upsertEqmBaseCalendarChange?: Maybe<EqmBaseCalendarChange>;
  removeEqmBaseCalendarChange?: Maybe<EqmBaseCalendarChangeDeleteResponse>;
  removeEqmBaseCalendarChangeMany?: Maybe<EqmBaseCalendarChangeDeleteResponse>;
  upsertEqmCollectionReturnCharges?: Maybe<EqmCollectionReturnCharges>;
  removeEqmCollectionReturnCharges?: Maybe<EqmCollectionReturnChargesDeleteResponse>;
  removeEqmCollectionReturnChargesMany?: Maybe<EqmCollectionReturnChargesDeleteResponse>;
  upsertEqmCommentLine?: Maybe<EqmCommentLine>;
  removeEqmCommentLine?: Maybe<EqmCommentLineDeleteResponse>;
  removeEqmCommentLineMany?: Maybe<EqmCommentLineDeleteResponse>;
  upsertEqmCustomizedCalendarChange?: Maybe<EqmCustomizedCalendarChange>;
  removeEqmCustomizedCalendarChange?: Maybe<EqmCustomizedCalendarChangeDeleteResponse>;
  removeEqmCustomizedCalendarChangeMany?: Maybe<EqmCustomizedCalendarChangeDeleteResponse>;
  upsertEqmFncTestReturnCharges?: Maybe<EqmFncTestReturnCharges>;
  removeEqmFncTestReturnCharges?: Maybe<EqmFncTestReturnChargesDeleteResponse>;
  removeEqmFncTestReturnChargesMany?: Maybe<EqmFncTestReturnChargesDeleteResponse>;
  upsertEqmLocCostCenterComb?: Maybe<EqmLocCostCenterComb>;
  removeEqmLocCostCenterComb?: Maybe<EqmLocCostCenterCombDeleteResponse>;
  removeEqmLocCostCenterCombMany?: Maybe<EqmLocCostCenterCombDeleteResponse>;
  upsertEqmManufacturer?: Maybe<EqmManufacturer>;
  removeEqmManufacturer?: Maybe<EqmManufacturerDeleteResponse>;
  removeEqmManufacturerMany?: Maybe<EqmManufacturerDeleteResponse>;
  upsertEqmManufacturerModel?: Maybe<EqmManufacturerModel>;
  removeEqmManufacturerModel?: Maybe<EqmManufacturerModelDeleteResponse>;
  removeEqmManufacturerModelMany?: Maybe<EqmManufacturerModelDeleteResponse>;
  upsertEqmObjectCardFlow?: Maybe<EqmObjectCardFlow>;
  removeEqmObjectCardFlow?: Maybe<EqmObjectCardFlowDeleteResponse>;
  removeEqmObjectCardFlowMany?: Maybe<EqmObjectCardFlowDeleteResponse>;
  upsertEqmObjectComponents?: Maybe<EqmObjectComponents>;
  removeEqmObjectComponents?: Maybe<EqmObjectComponentsDeleteResponse>;
  removeEqmObjectComponentsMany?: Maybe<EqmObjectComponentsDeleteResponse>;
  upsertEqmObjectCountHeader?: Maybe<EqmObjectCountHeader>;
  removeEqmObjectCountHeader?: Maybe<EqmObjectCountHeaderDeleteResponse>;
  removeEqmObjectCountHeaderMany?: Maybe<EqmObjectCountHeaderDeleteResponse>;
  upsertEqmObjectCountLine?: Maybe<EqmObjectCountLine>;
  removeEqmObjectCountLine?: Maybe<EqmObjectCountLineDeleteResponse>;
  removeEqmObjectCountLineMany?: Maybe<EqmObjectCountLineDeleteResponse>;
  upsertEqmObjectGroupCard?: Maybe<EqmObjectGroupCard>;
  removeEqmObjectGroupCard?: Maybe<EqmObjectGroupCardDeleteResponse>;
  removeEqmObjectGroupCardMany?: Maybe<EqmObjectGroupCardDeleteResponse>;
  upsertEqmObjectGroupFlow?: Maybe<EqmObjectGroupFlow>;
  removeEqmObjectGroupFlow?: Maybe<EqmObjectGroupFlowDeleteResponse>;
  removeEqmObjectGroupFlowMany?: Maybe<EqmObjectGroupFlowDeleteResponse>;
  upsertEqmObjectServiceInterval?: Maybe<EqmObjectServiceInterval>;
  removeEqmObjectServiceInterval?: Maybe<EqmObjectServiceIntervalDeleteResponse>;
  removeEqmObjectServiceIntervalMany?: Maybe<EqmObjectServiceIntervalDeleteResponse>;
  upsertEqmObjectSrvIntervalAgg?: Maybe<EqmObjectSrvIntervalAgg>;
  removeEqmObjectSrvIntervalAgg?: Maybe<EqmObjectSrvIntervalAggDeleteResponse>;
  removeEqmObjectSrvIntervalAggMany?: Maybe<EqmObjectSrvIntervalAggDeleteResponse>;
  upsertEqmObjectStatus?: Maybe<EqmObjectStatus>;
  removeEqmObjectStatus?: Maybe<EqmObjectStatusDeleteResponse>;
  removeEqmObjectStatusMany?: Maybe<EqmObjectStatusDeleteResponse>;
  upsertEqmObjectTypeCard?: Maybe<EqmObjectTypeCard>;
  removeEqmObjectTypeCard?: Maybe<EqmObjectTypeCardDeleteResponse>;
  removeEqmObjectTypeCardMany?: Maybe<EqmObjectTypeCardDeleteResponse>;
  upsertEqmObjectTypeComponents?: Maybe<EqmObjectTypeComponents>;
  removeEqmObjectTypeComponents?: Maybe<EqmObjectTypeComponentsDeleteResponse>;
  removeEqmObjectTypeComponentsMany?: Maybe<EqmObjectTypeComponentsDeleteResponse>;
  upsertEqmObjectTypeFlow?: Maybe<EqmObjectTypeFlow>;
  removeEqmObjectTypeFlow?: Maybe<EqmObjectTypeFlowDeleteResponse>;
  removeEqmObjectTypeFlowMany?: Maybe<EqmObjectTypeFlowDeleteResponse>;
  upsertEqmObjectTypePriceTerm?: Maybe<EqmObjectTypePriceTerm>;
  removeEqmObjectTypePriceTerm?: Maybe<EqmObjectTypePriceTermDeleteResponse>;
  removeEqmObjectTypePriceTermMany?: Maybe<EqmObjectTypePriceTermDeleteResponse>;
  upsertEqmObjectTypeServiceInterval?: Maybe<EqmObjectTypeServiceInterval>;
  removeEqmObjectTypeServiceInterval?: Maybe<EqmObjectTypeServiceIntervalDeleteResponse>;
  removeEqmObjectTypeServiceIntervalMany?: Maybe<EqmObjectTypeServiceIntervalDeleteResponse>;
  upsertEqmRentalGroup?: Maybe<EqmRentalGroup>;
  removeEqmRentalGroup?: Maybe<EqmRentalGroupDeleteResponse>;
  removeEqmRentalGroupMany?: Maybe<EqmRentalGroupDeleteResponse>;
  upsertEqmRentalKIT?: Maybe<EqmRentalKit>;
  removeEqmRentalKIT?: Maybe<EqmRentalKitDeleteResponse>;
  removeEqmRentalKITMany?: Maybe<EqmRentalKitDeleteResponse>;
  upsertEqmRentalKITComponents?: Maybe<EqmRentalKitComponents>;
  removeEqmRentalKITComponents?: Maybe<EqmRentalKitComponentsDeleteResponse>;
  removeEqmRentalKITComponentsMany?: Maybe<EqmRentalKitComponentsDeleteResponse>;
  upsertEqmRentalKITLines?: Maybe<EqmRentalKitLines>;
  removeEqmRentalKITLines?: Maybe<EqmRentalKitLinesDeleteResponse>;
  removeEqmRentalKITLinesMany?: Maybe<EqmRentalKitLinesDeleteResponse>;
  upsertEqmRentalLineDiscount?: Maybe<EqmRentalLineDiscount>;
  removeEqmRentalLineDiscount?: Maybe<EqmRentalLineDiscountDeleteResponse>;
  removeEqmRentalLineDiscountMany?: Maybe<EqmRentalLineDiscountDeleteResponse>;
  upsertEqmRentalPrice?: Maybe<EqmRentalPrice>;
  removeEqmRentalPrice?: Maybe<EqmRentalPriceDeleteResponse>;
  removeEqmRentalPriceMany?: Maybe<EqmRentalPriceDeleteResponse>;
  upsertEqmReRentCard?: Maybe<EqmReRentCard>;
  removeEqmReRentCard?: Maybe<EqmReRentCardDeleteResponse>;
  removeEqmReRentCardMany?: Maybe<EqmReRentCardDeleteResponse>;
  upsertEqmReturnCharges?: Maybe<EqmReturnCharges>;
  removeEqmReturnCharges?: Maybe<EqmReturnChargesDeleteResponse>;
  removeEqmReturnChargesMany?: Maybe<EqmReturnChargesDeleteResponse>;
  upsertEqmServiceCard?: Maybe<EqmServiceCard>;
  removeEqmServiceCard?: Maybe<EqmServiceCardDeleteResponse>;
  removeEqmServiceCardMany?: Maybe<EqmServiceCardDeleteResponse>;
  upsertEqmServiceType?: Maybe<EqmServiceType>;
  removeEqmServiceType?: Maybe<EqmServiceTypeDeleteResponse>;
  removeEqmServiceTypeMany?: Maybe<EqmServiceTypeDeleteResponse>;
  upsertEqmTypeFunctionalTest?: Maybe<EqmTypeFunctionalTest>;
  removeEqmTypeFunctionalTest?: Maybe<EqmTypeFunctionalTestDeleteResponse>;
  removeEqmTypeFunctionalTestMany?: Maybe<EqmTypeFunctionalTestDeleteResponse>;
  upsertEqmWorkHeader?: Maybe<EqmWorkHeader>;
  removeEqmWorkHeader?: Maybe<EqmWorkHeaderDeleteResponse>;
  removeEqmWorkHeaderMany?: Maybe<EqmWorkHeaderDeleteResponse>;
  upsertEqmWorkLine?: Maybe<EqmWorkLine>;
  removeEqmWorkLine?: Maybe<EqmWorkLineDeleteResponse>;
  removeEqmWorkLineMany?: Maybe<EqmWorkLineDeleteResponse>;
  upsertEqmTransferHeader?: Maybe<EqmTransferHeader>;
  removeEqmTransferHeader?: Maybe<EqmTransferHeaderDeleteResponse>;
  removeEqmTransferHeaderMany?: Maybe<EqmTransferHeaderDeleteResponse>;
  upsertEqmTransferLine?: Maybe<EqmTransferLine>;
  removeEqmTransferLine?: Maybe<EqmTransferLineDeleteResponse>;
  removeEqmTransferLineMany?: Maybe<EqmTransferLineDeleteResponse>;
  upsertErrand?: Maybe<Errand>;
  removeErrand?: Maybe<ErrandDeleteResponse>;
  removeErrandMany?: Maybe<ErrandDeleteResponse>;
  upsertErrandLine?: Maybe<ErrandLine>;
  removeErrandLine?: Maybe<ErrandLineDeleteResponse>;
  removeErrandLineMany?: Maybe<ErrandLineDeleteResponse>;
  upsertErrorReport?: Maybe<ErrorReport>;
  removeErrorReport?: Maybe<ErrorReportDeleteResponse>;
  removeErrorReportMany?: Maybe<ErrorReportDeleteResponse>;
  upsertFeedback?: Maybe<Feedback>;
  removeFeedback?: Maybe<FeedbackDeleteResponse>;
  removeFeedbackMany?: Maybe<FeedbackDeleteResponse>;
  upsertFeemapping?: Maybe<Feemapping>;
  removeFeemapping?: Maybe<FeemappingDeleteResponse>;
  removeFeemappingMany?: Maybe<FeemappingDeleteResponse>;
  upsertFieldOrder?: Maybe<FieldOrder>;
  removeFieldOrder?: Maybe<FieldOrderDeleteResponse>;
  removeFieldOrderMany?: Maybe<FieldOrderDeleteResponse>;
  upsertFieldOrderLine?: Maybe<FieldOrderLine>;
  removeFieldOrderLine?: Maybe<FieldOrderLineDeleteResponse>;
  removeFieldOrderLineMany?: Maybe<FieldOrderLineDeleteResponse>;
  upsertFile?: Maybe<File>;
  removeFile?: Maybe<FileDeleteResponse>;
  removeFileMany?: Maybe<FileDeleteResponse>;
  upsertFunctionTestHeader?: Maybe<FunctionTestHeader>;
  removeFunctionTestHeader?: Maybe<FunctionTestHeaderDeleteResponse>;
  removeFunctionTestHeaderMany?: Maybe<FunctionTestHeaderDeleteResponse>;
  upsertFunctionTestLine?: Maybe<FunctionTestLine>;
  removeFunctionTestLine?: Maybe<FunctionTestLineDeleteResponse>;
  removeFunctionTestLineMany?: Maybe<FunctionTestLineDeleteResponse>;
  upsertGenBusinessPostingGroup?: Maybe<GenBusinessPostingGroup>;
  removeGenBusinessPostingGroup?: Maybe<GenBusinessPostingGroupDeleteResponse>;
  removeGenBusinessPostingGroupMany?: Maybe<GenBusinessPostingGroupDeleteResponse>;
  upsertGenJournalLine?: Maybe<GenJournalLine>;
  removeGenJournalLine?: Maybe<GenJournalLineDeleteResponse>;
  removeGenJournalLineMany?: Maybe<GenJournalLineDeleteResponse>;
  upsertGenProductPostingGroup?: Maybe<GenProductPostingGroup>;
  removeGenProductPostingGroup?: Maybe<GenProductPostingGroupDeleteResponse>;
  removeGenProductPostingGroupMany?: Maybe<GenProductPostingGroupDeleteResponse>;
  upsertInspection?: Maybe<Inspection>;
  removeInspection?: Maybe<InspectionDeleteResponse>;
  removeInspectionMany?: Maybe<InspectionDeleteResponse>;
  upsertInspectionUser?: Maybe<InspectionUser>;
  removeInspectionUser?: Maybe<InspectionUserDeleteResponse>;
  removeInspectionUserMany?: Maybe<InspectionUserDeleteResponse>;
  upsertIntegrationCollection?: Maybe<IntegrationCollection>;
  removeIntegrationCollection?: Maybe<IntegrationCollectionDeleteResponse>;
  removeIntegrationCollectionMany?: Maybe<IntegrationCollectionDeleteResponse>;
  upsertInventoryPostingGroup?: Maybe<InventoryPostingGroup>;
  removeInventoryPostingGroup?: Maybe<InventoryPostingGroupDeleteResponse>;
  removeInventoryPostingGroupMany?: Maybe<InventoryPostingGroupDeleteResponse>;
  upsertInvoiceHeader?: Maybe<InvoiceHeader>;
  removeInvoiceHeader?: Maybe<InvoiceHeaderDeleteResponse>;
  removeInvoiceHeaderMany?: Maybe<InvoiceHeaderDeleteResponse>;
  upsertInvoiceLine?: Maybe<InvoiceLine>;
  removeInvoiceLine?: Maybe<InvoiceLineDeleteResponse>;
  removeInvoiceLineMany?: Maybe<InvoiceLineDeleteResponse>;
  upsertItem?: Maybe<Item>;
  removeItem?: Maybe<ItemDeleteResponse>;
  removeItemMany?: Maybe<ItemDeleteResponse>;
  upsertItemCategory?: Maybe<ItemCategory>;
  removeItemCategory?: Maybe<ItemCategoryDeleteResponse>;
  removeItemCategoryMany?: Maybe<ItemCategoryDeleteResponse>;
  upsertItemDiscGroup?: Maybe<ItemDiscGroup>;
  removeItemDiscGroup?: Maybe<ItemDiscGroupDeleteResponse>;
  removeItemDiscGroupMany?: Maybe<ItemDiscGroupDeleteResponse>;
  upsertItemReference?: Maybe<ItemReference>;
  removeItemReference?: Maybe<ItemReferenceDeleteResponse>;
  removeItemReferenceMany?: Maybe<ItemReferenceDeleteResponse>;
  upsertItemRestriction?: Maybe<ItemRestriction>;
  removeItemRestriction?: Maybe<ItemRestrictionDeleteResponse>;
  removeItemRestrictionMany?: Maybe<ItemRestrictionDeleteResponse>;
  upsertItemUnitOfMeasure?: Maybe<ItemUnitOfMeasure>;
  removeItemUnitOfMeasure?: Maybe<ItemUnitOfMeasureDeleteResponse>;
  removeItemUnitOfMeasureMany?: Maybe<ItemUnitOfMeasureDeleteResponse>;
  upsertItemVendor?: Maybe<ItemVendor>;
  removeItemVendor?: Maybe<ItemVendorDeleteResponse>;
  removeItemVendorMany?: Maybe<ItemVendorDeleteResponse>;
  upsertJobTask?: Maybe<JobTask>;
  removeJobTask?: Maybe<JobTaskDeleteResponse>;
  removeJobTaskMany?: Maybe<JobTaskDeleteResponse>;
  upsertKliHeader?: Maybe<KliHeader>;
  removeKliHeader?: Maybe<KliHeaderDeleteResponse>;
  removeKliHeaderMany?: Maybe<KliHeaderDeleteResponse>;
  upsertKliReasonCode?: Maybe<KliReasonCode>;
  removeKliReasonCode?: Maybe<KliReasonCodeDeleteResponse>;
  removeKliReasonCodeMany?: Maybe<KliReasonCodeDeleteResponse>;
  upsertLocation?: Maybe<Location>;
  removeLocation?: Maybe<LocationDeleteResponse>;
  removeLocationMany?: Maybe<LocationDeleteResponse>;
  upsertLead?: Maybe<Lead>;
  removeLead?: Maybe<LeadDeleteResponse>;
  removeLeadMany?: Maybe<LeadDeleteResponse>;
  upsertMagentoOrderStatus?: Maybe<MagentoOrderStatus>;
  removeMagentoOrderStatus?: Maybe<MagentoOrderStatusDeleteResponse>;
  removeMagentoOrderStatusMany?: Maybe<MagentoOrderStatusDeleteResponse>;
  upsertMagentoSettings?: Maybe<MagentoSettings>;
  removeMagentoSettings?: Maybe<MagentoSettingsDeleteResponse>;
  removeMagentoSettingsMany?: Maybe<MagentoSettingsDeleteResponse>;
  upsertManufacturer?: Maybe<Manufacturer>;
  removeManufacturer?: Maybe<ManufacturerDeleteResponse>;
  removeManufacturerMany?: Maybe<ManufacturerDeleteResponse>;
  upsertNavUser?: Maybe<NavUser>;
  removeNavUser?: Maybe<NavUserDeleteResponse>;
  removeNavUserMany?: Maybe<NavUserDeleteResponse>;
  upsertObject?: Maybe<Object>;
  removeObject?: Maybe<ObjectDeleteResponse>;
  removeObjectMany?: Maybe<ObjectDeleteResponse>;
  upsertObjectCommentLine?: Maybe<ObjectCommentLine>;
  removeObjectCommentLine?: Maybe<ObjectCommentLineDeleteResponse>;
  removeObjectCommentLineMany?: Maybe<ObjectCommentLineDeleteResponse>;
  upsertObjectFamily?: Maybe<ObjectFamily>;
  removeObjectFamily?: Maybe<ObjectFamilyDeleteResponse>;
  removeObjectFamilyMany?: Maybe<ObjectFamilyDeleteResponse>;
  upsertObjectGroup?: Maybe<ObjectGroup>;
  removeObjectGroup?: Maybe<ObjectGroupDeleteResponse>;
  removeObjectGroupMany?: Maybe<ObjectGroupDeleteResponse>;
  upsertObjectReturnHeader?: Maybe<ObjectReturnHeader>;
  removeObjectReturnHeader?: Maybe<ObjectReturnHeaderDeleteResponse>;
  removeObjectReturnHeaderMany?: Maybe<ObjectReturnHeaderDeleteResponse>;
  upsertObjectReturnLine?: Maybe<ObjectReturnLine>;
  removeObjectReturnLine?: Maybe<ObjectReturnLineDeleteResponse>;
  removeObjectReturnLineMany?: Maybe<ObjectReturnLineDeleteResponse>;
  upsertObjectType?: Maybe<ObjectType>;
  removeObjectType?: Maybe<ObjectTypeDeleteResponse>;
  removeObjectTypeMany?: Maybe<ObjectTypeDeleteResponse>;
  upsertOcrAnalysis?: Maybe<OcrAnalysis>;
  removeOcrAnalysis?: Maybe<OcrAnalysisDeleteResponse>;
  removeOcrAnalysisMany?: Maybe<OcrAnalysisDeleteResponse>;
  upsertPaymentMethod?: Maybe<PaymentMethod>;
  removePaymentMethod?: Maybe<PaymentMethodDeleteResponse>;
  removePaymentMethodMany?: Maybe<PaymentMethodDeleteResponse>;
  upsertPaymentTerms?: Maybe<PaymentTerms>;
  removePaymentTerms?: Maybe<PaymentTermsDeleteResponse>;
  removePaymentTermsMany?: Maybe<PaymentTermsDeleteResponse>;
  upsertPlatformFeature?: Maybe<PlatformFeature>;
  removePlatformFeature?: Maybe<PlatformFeatureDeleteResponse>;
  removePlatformFeatureMany?: Maybe<PlatformFeatureDeleteResponse>;
  upsertPostCode?: Maybe<PostCode>;
  removePostCode?: Maybe<PostCodeDeleteResponse>;
  removePostCodeMany?: Maybe<PostCodeDeleteResponse>;
  upsertProject?: Maybe<Project>;
  removeProject?: Maybe<ProjectDeleteResponse>;
  removeProjectMany?: Maybe<ProjectDeleteResponse>;
  upsertPurchaseHeader?: Maybe<PurchaseHeader>;
  removePurchaseHeader?: Maybe<PurchaseHeaderDeleteResponse>;
  removePurchaseHeaderMany?: Maybe<PurchaseHeaderDeleteResponse>;
  upsertPurchaseLine?: Maybe<PurchaseLine>;
  removePurchaseLine?: Maybe<PurchaseLineDeleteResponse>;
  removePurchaseLineMany?: Maybe<PurchaseLineDeleteResponse>;
  upsertPurchasing?: Maybe<Purchasing>;
  removePurchasing?: Maybe<PurchasingDeleteResponse>;
  removePurchasingMany?: Maybe<PurchasingDeleteResponse>;
  upsertPurchCrMemoHeader?: Maybe<PurchCrMemoHeader>;
  removePurchCrMemoHeader?: Maybe<PurchCrMemoHeaderDeleteResponse>;
  removePurchCrMemoHeaderMany?: Maybe<PurchCrMemoHeaderDeleteResponse>;
  upsertPurchCrMemoLine?: Maybe<PurchCrMemoLine>;
  removePurchCrMemoLine?: Maybe<PurchCrMemoLineDeleteResponse>;
  removePurchCrMemoLineMany?: Maybe<PurchCrMemoLineDeleteResponse>;
  upsertPurchInvHeader?: Maybe<PurchInvHeader>;
  removePurchInvHeader?: Maybe<PurchInvHeaderDeleteResponse>;
  removePurchInvHeaderMany?: Maybe<PurchInvHeaderDeleteResponse>;
  upsertPurchInvLine?: Maybe<PurchInvLine>;
  removePurchInvLine?: Maybe<PurchInvLineDeleteResponse>;
  removePurchInvLineMany?: Maybe<PurchInvLineDeleteResponse>;
  upsertPurchRcptHeader?: Maybe<PurchRcptHeader>;
  removePurchRcptHeader?: Maybe<PurchRcptHeaderDeleteResponse>;
  removePurchRcptHeaderMany?: Maybe<PurchRcptHeaderDeleteResponse>;
  upsertPurchRcptLine?: Maybe<PurchRcptLine>;
  removePurchRcptLine?: Maybe<PurchRcptLineDeleteResponse>;
  removePurchRcptLineMany?: Maybe<PurchRcptLineDeleteResponse>;
  upsertQuoteHeader?: Maybe<QuoteHeader>;
  removeQuoteHeader?: Maybe<QuoteHeaderDeleteResponse>;
  removeQuoteHeaderMany?: Maybe<QuoteHeaderDeleteResponse>;
  upsertQuoteLine?: Maybe<QuoteLine>;
  removeQuoteLine?: Maybe<QuoteLineDeleteResponse>;
  removeQuoteLineMany?: Maybe<QuoteLineDeleteResponse>;
  upsertQuoteTaskGroup?: Maybe<QuoteTaskGroup>;
  removeQuoteTaskGroup?: Maybe<QuoteTaskGroupDeleteResponse>;
  removeQuoteTaskGroupMany?: Maybe<QuoteTaskGroupDeleteResponse>;
  upsertRentalHeader?: Maybe<RentalHeader>;
  removeRentalHeader?: Maybe<RentalHeaderDeleteResponse>;
  removeRentalHeaderMany?: Maybe<RentalHeaderDeleteResponse>;
  upsertRentalLine?: Maybe<RentalLine>;
  removeRentalLine?: Maybe<RentalLineDeleteResponse>;
  removeRentalLineMany?: Maybe<RentalLineDeleteResponse>;
  upsertRentalLineSnapshot?: Maybe<RentalLineSnapshot>;
  removeRentalLineSnapshot?: Maybe<RentalLineSnapshotDeleteResponse>;
  removeRentalLineSnapshotMany?: Maybe<RentalLineSnapshotDeleteResponse>;
  upsertRentalPostedCollHeader?: Maybe<RentalPostedCollHeader>;
  removeRentalPostedCollHeader?: Maybe<RentalPostedCollHeaderDeleteResponse>;
  removeRentalPostedCollHeaderMany?: Maybe<RentalPostedCollHeaderDeleteResponse>;
  upsertRentalPostedCollLine?: Maybe<RentalPostedCollLine>;
  removeRentalPostedCollLine?: Maybe<RentalPostedCollLineDeleteResponse>;
  removeRentalPostedCollLineMany?: Maybe<RentalPostedCollLineDeleteResponse>;
  upsertRentalPostedShptHeader?: Maybe<RentalPostedShptHeader>;
  removeRentalPostedShptHeader?: Maybe<RentalPostedShptHeaderDeleteResponse>;
  removeRentalPostedShptHeaderMany?: Maybe<RentalPostedShptHeaderDeleteResponse>;
  upsertRentalPostedShptLine?: Maybe<RentalPostedShptLine>;
  removeRentalPostedShptLine?: Maybe<RentalPostedShptLineDeleteResponse>;
  removeRentalPostedShptLineMany?: Maybe<RentalPostedShptLineDeleteResponse>;
  upsertRentalPriceTerms?: Maybe<RentalPriceTerms>;
  removeRentalPriceTerms?: Maybe<RentalPriceTermsDeleteResponse>;
  removeRentalPriceTermsMany?: Maybe<RentalPriceTermsDeleteResponse>;
  upsertRentalReturnEntry?: Maybe<RentalReturnEntry>;
  removeRentalReturnEntry?: Maybe<RentalReturnEntryDeleteResponse>;
  removeRentalReturnEntryMany?: Maybe<RentalReturnEntryDeleteResponse>;
  upsertRequestHeader?: Maybe<RequestHeader>;
  removeRequestHeader?: Maybe<RequestHeaderDeleteResponse>;
  removeRequestHeaderMany?: Maybe<RequestHeaderDeleteResponse>;
  upsertRequestLine?: Maybe<RequestLine>;
  removeRequestLine?: Maybe<RequestLineDeleteResponse>;
  removeRequestLineMany?: Maybe<RequestLineDeleteResponse>;
  upsertReservationRequestHeader?: Maybe<ReservationRequestHeader>;
  removeReservationRequestHeader?: Maybe<ReservationRequestHeaderDeleteResponse>;
  removeReservationRequestHeaderMany?: Maybe<ReservationRequestHeaderDeleteResponse>;
  upsertReservationRequestLine?: Maybe<ReservationRequestLine>;
  removeReservationRequestLine?: Maybe<ReservationRequestLineDeleteResponse>;
  removeReservationRequestLineMany?: Maybe<ReservationRequestLineDeleteResponse>;
  upsertResource?: Maybe<Resource>;
  removeResource?: Maybe<ResourceDeleteResponse>;
  removeResourceMany?: Maybe<ResourceDeleteResponse>;
  upsertResponsibilityCenter?: Maybe<ResponsibilityCenter>;
  removeResponsibilityCenter?: Maybe<ResponsibilityCenterDeleteResponse>;
  removeResponsibilityCenterMany?: Maybe<ResponsibilityCenterDeleteResponse>;
  upsertReturnRequestHeader?: Maybe<ReturnRequestHeader>;
  removeReturnRequestHeader?: Maybe<ReturnRequestHeaderDeleteResponse>;
  removeReturnRequestHeaderMany?: Maybe<ReturnRequestHeaderDeleteResponse>;
  upsertReturnRequestLine?: Maybe<ReturnRequestLine>;
  removeReturnRequestLine?: Maybe<ReturnRequestLineDeleteResponse>;
  removeReturnRequestLineMany?: Maybe<ReturnRequestLineDeleteResponse>;
  upsertRiskAnalysisHeader?: Maybe<RiskAnalysisHeader>;
  removeRiskAnalysisHeader?: Maybe<RiskAnalysisHeaderDeleteResponse>;
  removeRiskAnalysisHeaderMany?: Maybe<RiskAnalysisHeaderDeleteResponse>;
  upsertRiskAnalysisLine?: Maybe<RiskAnalysisLine>;
  removeRiskAnalysisLine?: Maybe<RiskAnalysisLineDeleteResponse>;
  removeRiskAnalysisLineMany?: Maybe<RiskAnalysisLineDeleteResponse>;
  upsertRiskAnalysisSignature?: Maybe<RiskAnalysisSignature>;
  removeRiskAnalysisSignature?: Maybe<RiskAnalysisSignatureDeleteResponse>;
  removeRiskAnalysisSignatureMany?: Maybe<RiskAnalysisSignatureDeleteResponse>;
  upsertSalesHeader?: Maybe<SalesHeader>;
  removeSalesHeader?: Maybe<SalesHeaderDeleteResponse>;
  removeSalesHeaderMany?: Maybe<SalesHeaderDeleteResponse>;
  upsertSalesHeaderArchive?: Maybe<SalesHeaderArchive>;
  removeSalesHeaderArchive?: Maybe<SalesHeaderArchiveDeleteResponse>;
  removeSalesHeaderArchiveMany?: Maybe<SalesHeaderArchiveDeleteResponse>;
  upsertSalesHeaderHistory?: Maybe<SalesHeaderHistory>;
  removeSalesHeaderHistory?: Maybe<SalesHeaderHistoryDeleteResponse>;
  removeSalesHeaderHistoryMany?: Maybe<SalesHeaderHistoryDeleteResponse>;
  upsertSalesHeaderRequest?: Maybe<SalesHeaderRequest>;
  removeSalesHeaderRequest?: Maybe<SalesHeaderRequestDeleteResponse>;
  removeSalesHeaderRequestMany?: Maybe<SalesHeaderRequestDeleteResponse>;
  upsertSalesLine?: Maybe<SalesLine>;
  removeSalesLine?: Maybe<SalesLineDeleteResponse>;
  removeSalesLineMany?: Maybe<SalesLineDeleteResponse>;
  upsertSalesLineArchive?: Maybe<SalesLineArchive>;
  removeSalesLineArchive?: Maybe<SalesLineArchiveDeleteResponse>;
  removeSalesLineArchiveMany?: Maybe<SalesLineArchiveDeleteResponse>;
  upsertSalesLineRequest?: Maybe<SalesLineRequest>;
  removeSalesLineRequest?: Maybe<SalesLineRequestDeleteResponse>;
  removeSalesLineRequestMany?: Maybe<SalesLineRequestDeleteResponse>;
  upsertSalesperson?: Maybe<Salesperson>;
  removeSalesperson?: Maybe<SalespersonDeleteResponse>;
  removeSalespersonMany?: Maybe<SalespersonDeleteResponse>;
  upsertSalesPrice?: Maybe<SalesPrice>;
  removeSalesPrice?: Maybe<SalesPriceDeleteResponse>;
  removeSalesPriceMany?: Maybe<SalesPriceDeleteResponse>;
  upsertSalesShipmentHeader?: Maybe<SalesShipmentHeader>;
  removeSalesShipmentHeader?: Maybe<SalesShipmentHeaderDeleteResponse>;
  removeSalesShipmentHeaderMany?: Maybe<SalesShipmentHeaderDeleteResponse>;
  upsertSalesShipmentLine?: Maybe<SalesShipmentLine>;
  removeSalesShipmentLine?: Maybe<SalesShipmentLineDeleteResponse>;
  removeSalesShipmentLineMany?: Maybe<SalesShipmentLineDeleteResponse>;
  upsertServiceItemGroup?: Maybe<ServiceItemGroup>;
  removeServiceItemGroup?: Maybe<ServiceItemGroupDeleteResponse>;
  removeServiceItemGroupMany?: Maybe<ServiceItemGroupDeleteResponse>;
  upsertShipmentMethod?: Maybe<ShipmentMethod>;
  removeShipmentMethod?: Maybe<ShipmentMethodDeleteResponse>;
  removeShipmentMethodMany?: Maybe<ShipmentMethodDeleteResponse>;
  upsertShippingAgent?: Maybe<ShippingAgent>;
  removeShippingAgent?: Maybe<ShippingAgentDeleteResponse>;
  removeShippingAgentMany?: Maybe<ShippingAgentDeleteResponse>;
  upsertSignature?: Maybe<Signature>;
  removeSignature?: Maybe<SignatureDeleteResponse>;
  removeSignatureMany?: Maybe<SignatureDeleteResponse>;
  upsertSignRequest?: Maybe<SignRequest>;
  removeSignRequest?: Maybe<SignRequestDeleteResponse>;
  removeSignRequestMany?: Maybe<SignRequestDeleteResponse>;
  upsertSpecialEquipment?: Maybe<SpecialEquipment>;
  removeSpecialEquipment?: Maybe<SpecialEquipmentDeleteResponse>;
  removeSpecialEquipmentMany?: Maybe<SpecialEquipmentDeleteResponse>;
  upsertStockkeepingUnit?: Maybe<StockkeepingUnit>;
  removeStockkeepingUnit?: Maybe<StockkeepingUnitDeleteResponse>;
  removeStockkeepingUnitMany?: Maybe<StockkeepingUnitDeleteResponse>;
  upsertTariffNumber?: Maybe<TariffNumber>;
  removeTariffNumber?: Maybe<TariffNumberDeleteResponse>;
  removeTariffNumberMany?: Maybe<TariffNumberDeleteResponse>;
  upsertTaxGroup?: Maybe<TaxGroup>;
  removeTaxGroup?: Maybe<TaxGroupDeleteResponse>;
  removeTaxGroupMany?: Maybe<TaxGroupDeleteResponse>;
  upsertTimeRegistration?: Maybe<TimeRegistration>;
  removeTimeRegistration?: Maybe<TimeRegistrationDeleteResponse>;
  removeTimeRegistrationMany?: Maybe<TimeRegistrationDeleteResponse>;
  upsertTimeSheetDetail?: Maybe<TimeSheetDetail>;
  removeTimeSheetDetail?: Maybe<TimeSheetDetailDeleteResponse>;
  removeTimeSheetDetailMany?: Maybe<TimeSheetDetailDeleteResponse>;
  upsertTimeSheetLine?: Maybe<TimeSheetLine>;
  removeTimeSheetLine?: Maybe<TimeSheetLineDeleteResponse>;
  removeTimeSheetLineMany?: Maybe<TimeSheetLineDeleteResponse>;
  upsertTransactionSpecification?: Maybe<TransactionSpecification>;
  removeTransactionSpecification?: Maybe<TransactionSpecificationDeleteResponse>;
  removeTransactionSpecificationMany?: Maybe<TransactionSpecificationDeleteResponse>;
  upsertTransactionType?: Maybe<TransactionType>;
  removeTransactionType?: Maybe<TransactionTypeDeleteResponse>;
  removeTransactionTypeMany?: Maybe<TransactionTypeDeleteResponse>;
  upsertTransportMethod?: Maybe<TransportMethod>;
  removeTransportMethod?: Maybe<TransportMethodDeleteResponse>;
  removeTransportMethodMany?: Maybe<TransportMethodDeleteResponse>;
  upsertUnitOfMeasure?: Maybe<UnitOfMeasure>;
  removeUnitOfMeasure?: Maybe<UnitOfMeasureDeleteResponse>;
  removeUnitOfMeasureMany?: Maybe<UnitOfMeasureDeleteResponse>;
  upsertUser?: Maybe<User>;
  removeUser?: Maybe<UserDeleteResponse>;
  removeUserMany?: Maybe<UserDeleteResponse>;
  upsertUserFavorites?: Maybe<UserFavorites>;
  removeUserFavorites?: Maybe<UserFavoritesDeleteResponse>;
  removeUserFavoritesMany?: Maybe<UserFavoritesDeleteResponse>;
  upsertVatBusinessPostingGroup?: Maybe<VatBusinessPostingGroup>;
  removeVatBusinessPostingGroup?: Maybe<VatBusinessPostingGroupDeleteResponse>;
  removeVatBusinessPostingGroupMany?: Maybe<VatBusinessPostingGroupDeleteResponse>;
  upsertVatProductPostingGroup?: Maybe<VatProductPostingGroup>;
  removeVatProductPostingGroup?: Maybe<VatProductPostingGroupDeleteResponse>;
  removeVatProductPostingGroupMany?: Maybe<VatProductPostingGroupDeleteResponse>;
  upsertVendor?: Maybe<Vendor>;
  removeVendor?: Maybe<VendorDeleteResponse>;
  removeVendorMany?: Maybe<VendorDeleteResponse>;
  upsertVendorBankAccount?: Maybe<VendorBankAccount>;
  removeVendorBankAccount?: Maybe<VendorBankAccountDeleteResponse>;
  removeVendorBankAccountMany?: Maybe<VendorBankAccountDeleteResponse>;
  upsertVendorPostingGroup?: Maybe<VendorPostingGroup>;
  removeVendorPostingGroup?: Maybe<VendorPostingGroupDeleteResponse>;
  removeVendorPostingGroupMany?: Maybe<VendorPostingGroupDeleteResponse>;
  upsertWarehouseClass?: Maybe<WarehouseClass>;
  removeWarehouseClass?: Maybe<WarehouseClassDeleteResponse>;
  removeWarehouseClassMany?: Maybe<WarehouseClassDeleteResponse>;
  upsertWebBooking?: Maybe<WebBooking>;
  removeWebBooking?: Maybe<WebBookingDeleteResponse>;
  removeWebBookingMany?: Maybe<WebBookingDeleteResponse>;
  upsertWebhookSubscription?: Maybe<WebhookSubscription>;
  removeWebhookSubscription?: Maybe<WebhookSubscriptionDeleteResponse>;
  removeWebhookSubscriptionMany?: Maybe<WebhookSubscriptionDeleteResponse>;
  upsertWorkLog?: Maybe<WorkLog>;
  removeWorkLog?: Maybe<WorkLogDeleteResponse>;
  removeWorkLogMany?: Maybe<WorkLogDeleteResponse>;
  upsertWorkType?: Maybe<WorkType>;
  removeWorkType?: Maybe<WorkTypeDeleteResponse>;
  removeWorkTypeMany?: Maybe<WorkTypeDeleteResponse>;
  createAccessRequest: AccessRequest;
  approveRejectAccessRequest: AccessRequest;
  toggleSupplier: ToggleSuplierResponse;
  syncUserDomainIds: SyncUsersResponse;
  addPushToken?: Maybe<UserOperationResponse>;
  clearPushTokens?: Maybe<UserOperationResponse>;
  logout?: Maybe<UserOperationResponse>;
  upsertMe?: Maybe<User>;
  toggleFavoriteProject?: Maybe<User>;
  createServiceAccount?: Maybe<User>;
  requestToken?: Maybe<AuthResponse>;
  impersonateUser?: Maybe<User>;
  clearMyImpersonation?: Maybe<User>;
  sendPushNotification?: Maybe<Array<Maybe<PushNotificationResponse>>>;
  toggleProjectNotifications?: Maybe<User>;
  proxyHeartbeat?: Maybe<ProxyHeartbeatResponse>;
  setCustomerDomainImage: CustomerDomain;
  upsertCustomerDomainLimited?: Maybe<CustomerDomain>;
  setDomainImage?: Maybe<Domain>;
  setFlagImage?: Maybe<Domain>;
  switchToSiblingDomain?: Maybe<User>;
  initializeDomain?: Maybe<Domain>;
  upsertDomain?: Maybe<Domain>;
  removeDomain?: Maybe<DomainDeleteResponse>;
  removeDomainMany?: Maybe<DomainDeleteResponse>;
  upsertDomainIntegration?: Maybe<DomainIntegration>;
  testDomainIntegration?: Maybe<DomainConnectionTestResult>;
  removeDomainIntegration?: Maybe<DomainIntegrationDeleteResponse>;
  limitedUpsertCustomerProject?: Maybe<LimitedUpdateCustomerProjectResponse>;
  upsertDocument?: Maybe<Document>;
  createWorkshopOrder?: Maybe<CreateWorkshopOrderResponse>;
  changeObjectTypeImage?: Maybe<ObjectType>;
  changeObjectFamilyImage?: Maybe<ObjectFamily>;
  changeObjectGroupImage?: Maybe<ObjectGroup>;
  notifyDailyRentTransactions?: Maybe<DailyTransactionResponse>;
  sendOnRentListByEmail?: Maybe<SendOnRentEmailResponse>;
  retrySync?: Maybe<RetrySyncResponse>;
  upsertSalesOrder?: Maybe<SalesOrderResponse>;
  upsertCompanyTree?: Maybe<Company>;
  removeInvitation?: Maybe<InvitationDeleteResponse>;
  removeInvitationsMany?: Maybe<InvitationDeleteResponse>;
  acceptPersonalInvitation?: Maybe<AcceptInivitationResponse>;
  acceptCompanyInvitation?: Maybe<AcceptInivitationResponse>;
  acceptDomainInvitation?: Maybe<AcceptInivitationResponse>;
  createPersonalInvitation?: Maybe<Invitation>;
  createCompanyInvitation?: Maybe<Invitation>;
  createDomainInvitation?: Maybe<Invitation>;
  deactivateInvitation?: Maybe<Invitation>;
  updateDomainInvitationAppLinks?: Maybe<Invitation>;
  uploadIOSAppCodeFile?: Maybe<UploadAppCodesResponse>;
  clearInvitationCodes?: Maybe<RemoveCodesResponse>;
  startStopFuncTest?: Maybe<FunctionTestHeader>;
  clearFinishedFunctionalTests?: Maybe<ClearFunctionalTestReusult>;
};


export type MutationUpsertAccessRequestArgs = {
  record?: Maybe<UpdateAccessRequestInput>;
};


export type MutationRemoveAccessRequestArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
};


export type MutationRemoveAccessRequestManyArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
};


export type MutationUpsertAdditionalItemArgs = {
  record?: Maybe<UpdateAdditionalItemInput>;
};


export type MutationRemoveAdditionalItemArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
};


export type MutationRemoveAdditionalItemManyArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
};


export type MutationUpsertAddressArgs = {
  record?: Maybe<UpdateAddressInput>;
};


export type MutationRemoveAddressArgs = {
  filter?: Maybe<FilterAddressInput>;
};


export type MutationRemoveAddressManyArgs = {
  filter?: Maybe<FilterAddressInput>;
};


export type MutationUpsertAgreementArgs = {
  record?: Maybe<UpdateAgreementInput>;
};


export type MutationRemoveAgreementArgs = {
  filter?: Maybe<FilterAgreementInput>;
};


export type MutationRemoveAgreementManyArgs = {
  filter?: Maybe<FilterAgreementInput>;
};


export type MutationUpsertAnnotationArgs = {
  record?: Maybe<UpdateAnnotationInput>;
};


export type MutationRemoveAnnotationArgs = {
  filter?: Maybe<FilterAnnotationInput>;
};


export type MutationRemoveAnnotationManyArgs = {
  filter?: Maybe<FilterAnnotationInput>;
};


export type MutationUpsertApplicationModuleArgs = {
  record?: Maybe<UpdateApplicationModuleInput>;
};


export type MutationRemoveApplicationModuleArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
};


export type MutationRemoveApplicationModuleManyArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
};


export type MutationUpsertBcMappingTableArgs = {
  record?: Maybe<UpdateBcMappingTableInput>;
};


export type MutationRemoveBcMappingTableArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
};


export type MutationRemoveBcMappingTableManyArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
};


export type MutationUpsertBcMappingFieldArgs = {
  record?: Maybe<UpdateBcMappingFieldInput>;
};


export type MutationRemoveBcMappingFieldArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
};


export type MutationRemoveBcMappingFieldManyArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
};


export type MutationUpsertBcSettingsArgs = {
  record?: Maybe<UpdateBcSettingsInput>;
};


export type MutationRemoveBcSettingsArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
};


export type MutationRemoveBcSettingsManyArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
};


export type MutationUpsertBulkItemGroupArgs = {
  record?: Maybe<UpdateBulkItemGroupInput>;
};


export type MutationRemoveBulkItemGroupArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
};


export type MutationRemoveBulkItemGroupManyArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
};


export type MutationUpsertCauseOfAbsenceArgs = {
  record?: Maybe<UpdateCauseOfAbsenceInput>;
};


export type MutationRemoveCauseOfAbsenceArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
};


export type MutationRemoveCauseOfAbsenceManyArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
};


export type MutationUpsertCompanyArgs = {
  record?: Maybe<UpdateCompanyInput>;
};


export type MutationRemoveCompanyArgs = {
  filter?: Maybe<FilterCompanyInput>;
};


export type MutationRemoveCompanyManyArgs = {
  filter?: Maybe<FilterCompanyInput>;
};


export type MutationUpsertConsignmentHeaderArgs = {
  record?: Maybe<UpdateConsignmentHeaderInput>;
};


export type MutationRemoveConsignmentHeaderArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
};


export type MutationRemoveConsignmentHeaderManyArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
};


export type MutationUpsertConsignmentLineArgs = {
  record?: Maybe<UpdateConsignmentLineInput>;
};


export type MutationRemoveConsignmentLineArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
};


export type MutationRemoveConsignmentLineManyArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
};


export type MutationUpsertContactArgs = {
  record?: Maybe<UpdateContactInput>;
};


export type MutationRemoveContactArgs = {
  filter?: Maybe<FilterContactInput>;
};


export type MutationRemoveContactManyArgs = {
  filter?: Maybe<FilterContactInput>;
};


export type MutationUpsertCountryRegionArgs = {
  record?: Maybe<UpdateCountryRegionInput>;
};


export type MutationRemoveCountryRegionArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
};


export type MutationRemoveCountryRegionManyArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
};


export type MutationUpsertCurrencyArgs = {
  record?: Maybe<UpdateCurrencyInput>;
};


export type MutationRemoveCurrencyArgs = {
  filter?: Maybe<FilterCurrencyInput>;
};


export type MutationRemoveCurrencyManyArgs = {
  filter?: Maybe<FilterCurrencyInput>;
};


export type MutationUpsertCurrencyExchangeRateArgs = {
  record?: Maybe<UpdateCurrencyExchangeRateInput>;
};


export type MutationRemoveCurrencyExchangeRateArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
};


export type MutationRemoveCurrencyExchangeRateManyArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
};


export type MutationUpsertCustomerDomainArgs = {
  record?: Maybe<UpdateCustomerDomainInput>;
};


export type MutationRemoveCustomerDomainArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
};


export type MutationRemoveCustomerDomainManyArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
};


export type MutationUpsertCustomerPostingGroupArgs = {
  record?: Maybe<UpdateCustomerPostingGroupInput>;
};


export type MutationRemoveCustomerPostingGroupArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
};


export type MutationRemoveCustomerPostingGroupManyArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
};


export type MutationUpsertCustomerProjectArgs = {
  record?: Maybe<UpdateCustomerProjectInput>;
};


export type MutationRemoveCustomerProjectArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
};


export type MutationRemoveCustomerProjectManyArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
};


export type MutationUpsertCustomerSubProjectArgs = {
  record?: Maybe<UpdateCustomerSubProjectInput>;
};


export type MutationRemoveCustomerSubProjectArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
};


export type MutationRemoveCustomerSubProjectManyArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
};


export type MutationUpsertDeliverytimeArgs = {
  record?: Maybe<UpdateDeliverytimeInput>;
};


export type MutationRemoveDeliverytimeArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
};


export type MutationRemoveDeliverytimeManyArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
};


export type MutationUpsertDevopsProjectArgs = {
  record?: Maybe<UpdateDevopsProjectInput>;
};


export type MutationRemoveDevopsProjectArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
};


export type MutationRemoveDevopsProjectManyArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
};


export type MutationUpsertDevopsTeamArgs = {
  record?: Maybe<UpdateDevopsTeamInput>;
};


export type MutationRemoveDevopsTeamArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
};


export type MutationRemoveDevopsTeamManyArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
};


export type MutationUpsertDevopsWorkItemArgs = {
  record?: Maybe<UpdateDevopsWorkItemInput>;
};


export type MutationRemoveDevopsWorkItemArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
};


export type MutationRemoveDevopsWorkItemManyArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
};


export type MutationUpsertDimensionArgs = {
  record?: Maybe<UpdateDimensionInput>;
};


export type MutationRemoveDimensionArgs = {
  filter?: Maybe<FilterDimensionInput>;
};


export type MutationRemoveDimensionManyArgs = {
  filter?: Maybe<FilterDimensionInput>;
};


export type MutationUpsertDimensionValueArgs = {
  record?: Maybe<UpdateDimensionValueInput>;
};


export type MutationRemoveDimensionValueArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
};


export type MutationRemoveDimensionValueManyArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
};


export type MutationUpsertDiscussionPostArgs = {
  record?: Maybe<UpdateDiscussionPostInput>;
};


export type MutationRemoveDiscussionPostArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
};


export type MutationRemoveDiscussionPostManyArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
};


export type MutationUpsertDocumentClassificationArgs = {
  record?: Maybe<UpdateDocumentClassificationInput>;
};


export type MutationRemoveDocumentClassificationArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
};


export type MutationRemoveDocumentClassificationManyArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
};


export type MutationUpsertDocumentRequestArgs = {
  record?: Maybe<UpdateDocumentRequestInput>;
};


export type MutationRemoveDocumentRequestArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
};


export type MutationRemoveDocumentRequestManyArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
};


export type MutationUpsertDomainSettingsArgs = {
  record?: Maybe<UpdateDomainSettingsInput>;
};


export type MutationRemoveDomainSettingsArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
};


export type MutationRemoveDomainSettingsManyArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
};


export type MutationUpsertEqmBaseCalendarArgs = {
  record?: Maybe<UpdateEqmBaseCalendarInput>;
};


export type MutationRemoveEqmBaseCalendarArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
};


export type MutationRemoveEqmBaseCalendarManyArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
};


export type MutationUpsertEqmBaseCalendarChangeArgs = {
  record?: Maybe<UpdateEqmBaseCalendarChangeInput>;
};


export type MutationRemoveEqmBaseCalendarChangeArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
};


export type MutationRemoveEqmBaseCalendarChangeManyArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
};


export type MutationUpsertEqmCollectionReturnChargesArgs = {
  record?: Maybe<UpdateEqmCollectionReturnChargesInput>;
};


export type MutationRemoveEqmCollectionReturnChargesArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
};


export type MutationRemoveEqmCollectionReturnChargesManyArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
};


export type MutationUpsertEqmCommentLineArgs = {
  record?: Maybe<UpdateEqmCommentLineInput>;
};


export type MutationRemoveEqmCommentLineArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
};


export type MutationRemoveEqmCommentLineManyArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
};


export type MutationUpsertEqmCustomizedCalendarChangeArgs = {
  record?: Maybe<UpdateEqmCustomizedCalendarChangeInput>;
};


export type MutationRemoveEqmCustomizedCalendarChangeArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};


export type MutationRemoveEqmCustomizedCalendarChangeManyArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};


export type MutationUpsertEqmFncTestReturnChargesArgs = {
  record?: Maybe<UpdateEqmFncTestReturnChargesInput>;
};


export type MutationRemoveEqmFncTestReturnChargesArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
};


export type MutationRemoveEqmFncTestReturnChargesManyArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
};


export type MutationUpsertEqmLocCostCenterCombArgs = {
  record?: Maybe<UpdateEqmLocCostCenterCombInput>;
};


export type MutationRemoveEqmLocCostCenterCombArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
};


export type MutationRemoveEqmLocCostCenterCombManyArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
};


export type MutationUpsertEqmManufacturerArgs = {
  record?: Maybe<UpdateEqmManufacturerInput>;
};


export type MutationRemoveEqmManufacturerArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
};


export type MutationRemoveEqmManufacturerManyArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
};


export type MutationUpsertEqmManufacturerModelArgs = {
  record?: Maybe<UpdateEqmManufacturerModelInput>;
};


export type MutationRemoveEqmManufacturerModelArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
};


export type MutationRemoveEqmManufacturerModelManyArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
};


export type MutationUpsertEqmObjectCardFlowArgs = {
  record?: Maybe<UpdateEqmObjectCardFlowInput>;
};


export type MutationRemoveEqmObjectCardFlowArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
};


export type MutationRemoveEqmObjectCardFlowManyArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
};


export type MutationUpsertEqmObjectComponentsArgs = {
  record?: Maybe<UpdateEqmObjectComponentsInput>;
};


export type MutationRemoveEqmObjectComponentsArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
};


export type MutationRemoveEqmObjectComponentsManyArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
};


export type MutationUpsertEqmObjectCountHeaderArgs = {
  record?: Maybe<UpdateEqmObjectCountHeaderInput>;
};


export type MutationRemoveEqmObjectCountHeaderArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
};


export type MutationRemoveEqmObjectCountHeaderManyArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
};


export type MutationUpsertEqmObjectCountLineArgs = {
  record?: Maybe<UpdateEqmObjectCountLineInput>;
};


export type MutationRemoveEqmObjectCountLineArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
};


export type MutationRemoveEqmObjectCountLineManyArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
};


export type MutationUpsertEqmObjectGroupCardArgs = {
  record?: Maybe<UpdateEqmObjectGroupCardInput>;
};


export type MutationRemoveEqmObjectGroupCardArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
};


export type MutationRemoveEqmObjectGroupCardManyArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
};


export type MutationUpsertEqmObjectGroupFlowArgs = {
  record?: Maybe<UpdateEqmObjectGroupFlowInput>;
};


export type MutationRemoveEqmObjectGroupFlowArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
};


export type MutationRemoveEqmObjectGroupFlowManyArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
};


export type MutationUpsertEqmObjectServiceIntervalArgs = {
  record?: Maybe<UpdateEqmObjectServiceIntervalInput>;
};


export type MutationRemoveEqmObjectServiceIntervalArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
};


export type MutationRemoveEqmObjectServiceIntervalManyArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
};


export type MutationUpsertEqmObjectSrvIntervalAggArgs = {
  record?: Maybe<UpdateEqmObjectSrvIntervalAggInput>;
};


export type MutationRemoveEqmObjectSrvIntervalAggArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};


export type MutationRemoveEqmObjectSrvIntervalAggManyArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};


export type MutationUpsertEqmObjectStatusArgs = {
  record?: Maybe<UpdateEqmObjectStatusInput>;
};


export type MutationRemoveEqmObjectStatusArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
};


export type MutationRemoveEqmObjectStatusManyArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
};


export type MutationUpsertEqmObjectTypeCardArgs = {
  record?: Maybe<UpdateEqmObjectTypeCardInput>;
};


export type MutationRemoveEqmObjectTypeCardArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
};


export type MutationRemoveEqmObjectTypeCardManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
};


export type MutationUpsertEqmObjectTypeComponentsArgs = {
  record?: Maybe<UpdateEqmObjectTypeComponentsInput>;
};


export type MutationRemoveEqmObjectTypeComponentsArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
};


export type MutationRemoveEqmObjectTypeComponentsManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
};


export type MutationUpsertEqmObjectTypeFlowArgs = {
  record?: Maybe<UpdateEqmObjectTypeFlowInput>;
};


export type MutationRemoveEqmObjectTypeFlowArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
};


export type MutationRemoveEqmObjectTypeFlowManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
};


export type MutationUpsertEqmObjectTypePriceTermArgs = {
  record?: Maybe<UpdateEqmObjectTypePriceTermInput>;
};


export type MutationRemoveEqmObjectTypePriceTermArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
};


export type MutationRemoveEqmObjectTypePriceTermManyArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
};


export type MutationUpsertEqmObjectTypeServiceIntervalArgs = {
  record?: Maybe<UpdateEqmObjectTypeServiceIntervalInput>;
};


export type MutationRemoveEqmObjectTypeServiceIntervalArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};


export type MutationRemoveEqmObjectTypeServiceIntervalManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};


export type MutationUpsertEqmRentalGroupArgs = {
  record?: Maybe<UpdateEqmRentalGroupInput>;
};


export type MutationRemoveEqmRentalGroupArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
};


export type MutationRemoveEqmRentalGroupManyArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
};


export type MutationUpsertEqmRentalKitArgs = {
  record?: Maybe<UpdateEqmRentalKitInput>;
};


export type MutationRemoveEqmRentalKitArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
};


export type MutationRemoveEqmRentalKitManyArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
};


export type MutationUpsertEqmRentalKitComponentsArgs = {
  record?: Maybe<UpdateEqmRentalKitComponentsInput>;
};


export type MutationRemoveEqmRentalKitComponentsArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
};


export type MutationRemoveEqmRentalKitComponentsManyArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
};


export type MutationUpsertEqmRentalKitLinesArgs = {
  record?: Maybe<UpdateEqmRentalKitLinesInput>;
};


export type MutationRemoveEqmRentalKitLinesArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
};


export type MutationRemoveEqmRentalKitLinesManyArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
};


export type MutationUpsertEqmRentalLineDiscountArgs = {
  record?: Maybe<UpdateEqmRentalLineDiscountInput>;
};


export type MutationRemoveEqmRentalLineDiscountArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
};


export type MutationRemoveEqmRentalLineDiscountManyArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
};


export type MutationUpsertEqmRentalPriceArgs = {
  record?: Maybe<UpdateEqmRentalPriceInput>;
};


export type MutationRemoveEqmRentalPriceArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
};


export type MutationRemoveEqmRentalPriceManyArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
};


export type MutationUpsertEqmReRentCardArgs = {
  record?: Maybe<UpdateEqmReRentCardInput>;
};


export type MutationRemoveEqmReRentCardArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
};


export type MutationRemoveEqmReRentCardManyArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
};


export type MutationUpsertEqmReturnChargesArgs = {
  record?: Maybe<UpdateEqmReturnChargesInput>;
};


export type MutationRemoveEqmReturnChargesArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
};


export type MutationRemoveEqmReturnChargesManyArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
};


export type MutationUpsertEqmServiceCardArgs = {
  record?: Maybe<UpdateEqmServiceCardInput>;
};


export type MutationRemoveEqmServiceCardArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
};


export type MutationRemoveEqmServiceCardManyArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
};


export type MutationUpsertEqmServiceTypeArgs = {
  record?: Maybe<UpdateEqmServiceTypeInput>;
};


export type MutationRemoveEqmServiceTypeArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
};


export type MutationRemoveEqmServiceTypeManyArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
};


export type MutationUpsertEqmTypeFunctionalTestArgs = {
  record?: Maybe<UpdateEqmTypeFunctionalTestInput>;
};


export type MutationRemoveEqmTypeFunctionalTestArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
};


export type MutationRemoveEqmTypeFunctionalTestManyArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
};


export type MutationUpsertEqmWorkHeaderArgs = {
  record?: Maybe<UpdateEqmWorkHeaderInput>;
};


export type MutationRemoveEqmWorkHeaderArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
};


export type MutationRemoveEqmWorkHeaderManyArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
};


export type MutationUpsertEqmWorkLineArgs = {
  record?: Maybe<UpdateEqmWorkLineInput>;
};


export type MutationRemoveEqmWorkLineArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
};


export type MutationRemoveEqmWorkLineManyArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
};


export type MutationUpsertEqmTransferHeaderArgs = {
  record?: Maybe<UpdateEqmTransferHeaderInput>;
};


export type MutationRemoveEqmTransferHeaderArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
};


export type MutationRemoveEqmTransferHeaderManyArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
};


export type MutationUpsertEqmTransferLineArgs = {
  record?: Maybe<UpdateEqmTransferLineInput>;
};


export type MutationRemoveEqmTransferLineArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
};


export type MutationRemoveEqmTransferLineManyArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
};


export type MutationUpsertErrandArgs = {
  record?: Maybe<UpdateErrandInput>;
};


export type MutationRemoveErrandArgs = {
  filter?: Maybe<FilterErrandInput>;
};


export type MutationRemoveErrandManyArgs = {
  filter?: Maybe<FilterErrandInput>;
};


export type MutationUpsertErrandLineArgs = {
  record?: Maybe<UpdateErrandLineInput>;
};


export type MutationRemoveErrandLineArgs = {
  filter?: Maybe<FilterErrandLineInput>;
};


export type MutationRemoveErrandLineManyArgs = {
  filter?: Maybe<FilterErrandLineInput>;
};


export type MutationUpsertErrorReportArgs = {
  record?: Maybe<UpdateErrorReportInput>;
};


export type MutationRemoveErrorReportArgs = {
  filter?: Maybe<FilterErrorReportInput>;
};


export type MutationRemoveErrorReportManyArgs = {
  filter?: Maybe<FilterErrorReportInput>;
};


export type MutationUpsertFeedbackArgs = {
  record?: Maybe<UpdateFeedbackInput>;
};


export type MutationRemoveFeedbackArgs = {
  filter?: Maybe<FilterFeedbackInput>;
};


export type MutationRemoveFeedbackManyArgs = {
  filter?: Maybe<FilterFeedbackInput>;
};


export type MutationUpsertFeemappingArgs = {
  record?: Maybe<UpdateFeemappingInput>;
};


export type MutationRemoveFeemappingArgs = {
  filter?: Maybe<FilterFeemappingInput>;
};


export type MutationRemoveFeemappingManyArgs = {
  filter?: Maybe<FilterFeemappingInput>;
};


export type MutationUpsertFieldOrderArgs = {
  record?: Maybe<UpdateFieldOrderInput>;
};


export type MutationRemoveFieldOrderArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
};


export type MutationRemoveFieldOrderManyArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
};


export type MutationUpsertFieldOrderLineArgs = {
  record?: Maybe<UpdateFieldOrderLineInput>;
};


export type MutationRemoveFieldOrderLineArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
};


export type MutationRemoveFieldOrderLineManyArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
};


export type MutationUpsertFileArgs = {
  record?: Maybe<UpdateFileInput>;
};


export type MutationRemoveFileArgs = {
  filter?: Maybe<FilterFileInput>;
};


export type MutationRemoveFileManyArgs = {
  filter?: Maybe<FilterFileInput>;
};


export type MutationUpsertFunctionTestHeaderArgs = {
  record?: Maybe<UpdateFunctionTestHeaderInput>;
};


export type MutationRemoveFunctionTestHeaderArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
};


export type MutationRemoveFunctionTestHeaderManyArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
};


export type MutationUpsertFunctionTestLineArgs = {
  record?: Maybe<UpdateFunctionTestLineInput>;
};


export type MutationRemoveFunctionTestLineArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
};


export type MutationRemoveFunctionTestLineManyArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
};


export type MutationUpsertGenBusinessPostingGroupArgs = {
  record?: Maybe<UpdateGenBusinessPostingGroupInput>;
};


export type MutationRemoveGenBusinessPostingGroupArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
};


export type MutationRemoveGenBusinessPostingGroupManyArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
};


export type MutationUpsertGenJournalLineArgs = {
  record?: Maybe<UpdateGenJournalLineInput>;
};


export type MutationRemoveGenJournalLineArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
};


export type MutationRemoveGenJournalLineManyArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
};


export type MutationUpsertGenProductPostingGroupArgs = {
  record?: Maybe<UpdateGenProductPostingGroupInput>;
};


export type MutationRemoveGenProductPostingGroupArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
};


export type MutationRemoveGenProductPostingGroupManyArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
};


export type MutationUpsertInspectionArgs = {
  record?: Maybe<UpdateInspectionInput>;
};


export type MutationRemoveInspectionArgs = {
  filter?: Maybe<FilterInspectionInput>;
};


export type MutationRemoveInspectionManyArgs = {
  filter?: Maybe<FilterInspectionInput>;
};


export type MutationUpsertInspectionUserArgs = {
  record?: Maybe<UpdateInspectionUserInput>;
};


export type MutationRemoveInspectionUserArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
};


export type MutationRemoveInspectionUserManyArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
};


export type MutationUpsertIntegrationCollectionArgs = {
  record?: Maybe<UpdateIntegrationCollectionInput>;
};


export type MutationRemoveIntegrationCollectionArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
};


export type MutationRemoveIntegrationCollectionManyArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
};


export type MutationUpsertInventoryPostingGroupArgs = {
  record?: Maybe<UpdateInventoryPostingGroupInput>;
};


export type MutationRemoveInventoryPostingGroupArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
};


export type MutationRemoveInventoryPostingGroupManyArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
};


export type MutationUpsertInvoiceHeaderArgs = {
  record?: Maybe<UpdateInvoiceHeaderInput>;
};


export type MutationRemoveInvoiceHeaderArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
};


export type MutationRemoveInvoiceHeaderManyArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
};


export type MutationUpsertInvoiceLineArgs = {
  record?: Maybe<UpdateInvoiceLineInput>;
};


export type MutationRemoveInvoiceLineArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
};


export type MutationRemoveInvoiceLineManyArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
};


export type MutationUpsertItemArgs = {
  record?: Maybe<UpdateItemInput>;
};


export type MutationRemoveItemArgs = {
  filter?: Maybe<FilterItemInput>;
};


export type MutationRemoveItemManyArgs = {
  filter?: Maybe<FilterItemInput>;
};


export type MutationUpsertItemCategoryArgs = {
  record?: Maybe<UpdateItemCategoryInput>;
};


export type MutationRemoveItemCategoryArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
};


export type MutationRemoveItemCategoryManyArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
};


export type MutationUpsertItemDiscGroupArgs = {
  record?: Maybe<UpdateItemDiscGroupInput>;
};


export type MutationRemoveItemDiscGroupArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
};


export type MutationRemoveItemDiscGroupManyArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
};


export type MutationUpsertItemReferenceArgs = {
  record?: Maybe<UpdateItemReferenceInput>;
};


export type MutationRemoveItemReferenceArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
};


export type MutationRemoveItemReferenceManyArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
};


export type MutationUpsertItemRestrictionArgs = {
  record?: Maybe<UpdateItemRestrictionInput>;
};


export type MutationRemoveItemRestrictionArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
};


export type MutationRemoveItemRestrictionManyArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
};


export type MutationUpsertItemUnitOfMeasureArgs = {
  record?: Maybe<UpdateItemUnitOfMeasureInput>;
};


export type MutationRemoveItemUnitOfMeasureArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
};


export type MutationRemoveItemUnitOfMeasureManyArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
};


export type MutationUpsertItemVendorArgs = {
  record?: Maybe<UpdateItemVendorInput>;
};


export type MutationRemoveItemVendorArgs = {
  filter?: Maybe<FilterItemVendorInput>;
};


export type MutationRemoveItemVendorManyArgs = {
  filter?: Maybe<FilterItemVendorInput>;
};


export type MutationUpsertJobTaskArgs = {
  record?: Maybe<UpdateJobTaskInput>;
};


export type MutationRemoveJobTaskArgs = {
  filter?: Maybe<FilterJobTaskInput>;
};


export type MutationRemoveJobTaskManyArgs = {
  filter?: Maybe<FilterJobTaskInput>;
};


export type MutationUpsertKliHeaderArgs = {
  record?: Maybe<UpdateKliHeaderInput>;
};


export type MutationRemoveKliHeaderArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
};


export type MutationRemoveKliHeaderManyArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
};


export type MutationUpsertKliReasonCodeArgs = {
  record?: Maybe<UpdateKliReasonCodeInput>;
};


export type MutationRemoveKliReasonCodeArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
};


export type MutationRemoveKliReasonCodeManyArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
};


export type MutationUpsertLocationArgs = {
  record?: Maybe<UpdateLocationInput>;
};


export type MutationRemoveLocationArgs = {
  filter?: Maybe<FilterLocationInput>;
};


export type MutationRemoveLocationManyArgs = {
  filter?: Maybe<FilterLocationInput>;
};


export type MutationUpsertLeadArgs = {
  record?: Maybe<UpdateLeadInput>;
};


export type MutationRemoveLeadArgs = {
  filter?: Maybe<FilterLeadInput>;
};


export type MutationRemoveLeadManyArgs = {
  filter?: Maybe<FilterLeadInput>;
};


export type MutationUpsertMagentoOrderStatusArgs = {
  record?: Maybe<UpdateMagentoOrderStatusInput>;
};


export type MutationRemoveMagentoOrderStatusArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
};


export type MutationRemoveMagentoOrderStatusManyArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
};


export type MutationUpsertMagentoSettingsArgs = {
  record?: Maybe<UpdateMagentoSettingsInput>;
};


export type MutationRemoveMagentoSettingsArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
};


export type MutationRemoveMagentoSettingsManyArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
};


export type MutationUpsertManufacturerArgs = {
  record?: Maybe<UpdateManufacturerInput>;
};


export type MutationRemoveManufacturerArgs = {
  filter?: Maybe<FilterManufacturerInput>;
};


export type MutationRemoveManufacturerManyArgs = {
  filter?: Maybe<FilterManufacturerInput>;
};


export type MutationUpsertNavUserArgs = {
  record?: Maybe<UpdateNavUserInput>;
};


export type MutationRemoveNavUserArgs = {
  filter?: Maybe<FilterNavUserInput>;
};


export type MutationRemoveNavUserManyArgs = {
  filter?: Maybe<FilterNavUserInput>;
};


export type MutationUpsertObjectArgs = {
  record?: Maybe<UpdateObjectInput>;
};


export type MutationRemoveObjectArgs = {
  filter?: Maybe<FilterObjectInput>;
};


export type MutationRemoveObjectManyArgs = {
  filter?: Maybe<FilterObjectInput>;
};


export type MutationUpsertObjectCommentLineArgs = {
  record?: Maybe<UpdateObjectCommentLineInput>;
};


export type MutationRemoveObjectCommentLineArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
};


export type MutationRemoveObjectCommentLineManyArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
};


export type MutationUpsertObjectFamilyArgs = {
  record?: Maybe<UpdateObjectFamilyInput>;
};


export type MutationRemoveObjectFamilyArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
};


export type MutationRemoveObjectFamilyManyArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
};


export type MutationUpsertObjectGroupArgs = {
  record?: Maybe<UpdateObjectGroupInput>;
};


export type MutationRemoveObjectGroupArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
};


export type MutationRemoveObjectGroupManyArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
};


export type MutationUpsertObjectReturnHeaderArgs = {
  record?: Maybe<UpdateObjectReturnHeaderInput>;
};


export type MutationRemoveObjectReturnHeaderArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
};


export type MutationRemoveObjectReturnHeaderManyArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
};


export type MutationUpsertObjectReturnLineArgs = {
  record?: Maybe<UpdateObjectReturnLineInput>;
};


export type MutationRemoveObjectReturnLineArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
};


export type MutationRemoveObjectReturnLineManyArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
};


export type MutationUpsertObjectTypeArgs = {
  record?: Maybe<UpdateObjectTypeInput>;
};


export type MutationRemoveObjectTypeArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
};


export type MutationRemoveObjectTypeManyArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
};


export type MutationUpsertOcrAnalysisArgs = {
  record?: Maybe<UpdateOcrAnalysisInput>;
};


export type MutationRemoveOcrAnalysisArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
};


export type MutationRemoveOcrAnalysisManyArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
};


export type MutationUpsertPaymentMethodArgs = {
  record?: Maybe<UpdatePaymentMethodInput>;
};


export type MutationRemovePaymentMethodArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
};


export type MutationRemovePaymentMethodManyArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
};


export type MutationUpsertPaymentTermsArgs = {
  record?: Maybe<UpdatePaymentTermsInput>;
};


export type MutationRemovePaymentTermsArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
};


export type MutationRemovePaymentTermsManyArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
};


export type MutationUpsertPlatformFeatureArgs = {
  record?: Maybe<UpdatePlatformFeatureInput>;
};


export type MutationRemovePlatformFeatureArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
};


export type MutationRemovePlatformFeatureManyArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
};


export type MutationUpsertPostCodeArgs = {
  record?: Maybe<UpdatePostCodeInput>;
};


export type MutationRemovePostCodeArgs = {
  filter?: Maybe<FilterPostCodeInput>;
};


export type MutationRemovePostCodeManyArgs = {
  filter?: Maybe<FilterPostCodeInput>;
};


export type MutationUpsertProjectArgs = {
  record?: Maybe<UpdateProjectInput>;
};


export type MutationRemoveProjectArgs = {
  filter?: Maybe<FilterProjectInput>;
};


export type MutationRemoveProjectManyArgs = {
  filter?: Maybe<FilterProjectInput>;
};


export type MutationUpsertPurchaseHeaderArgs = {
  record?: Maybe<UpdatePurchaseHeaderInput>;
};


export type MutationRemovePurchaseHeaderArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
};


export type MutationRemovePurchaseHeaderManyArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
};


export type MutationUpsertPurchaseLineArgs = {
  record?: Maybe<UpdatePurchaseLineInput>;
};


export type MutationRemovePurchaseLineArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
};


export type MutationRemovePurchaseLineManyArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
};


export type MutationUpsertPurchasingArgs = {
  record?: Maybe<UpdatePurchasingInput>;
};


export type MutationRemovePurchasingArgs = {
  filter?: Maybe<FilterPurchasingInput>;
};


export type MutationRemovePurchasingManyArgs = {
  filter?: Maybe<FilterPurchasingInput>;
};


export type MutationUpsertPurchCrMemoHeaderArgs = {
  record?: Maybe<UpdatePurchCrMemoHeaderInput>;
};


export type MutationRemovePurchCrMemoHeaderArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
};


export type MutationRemovePurchCrMemoHeaderManyArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
};


export type MutationUpsertPurchCrMemoLineArgs = {
  record?: Maybe<UpdatePurchCrMemoLineInput>;
};


export type MutationRemovePurchCrMemoLineArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
};


export type MutationRemovePurchCrMemoLineManyArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
};


export type MutationUpsertPurchInvHeaderArgs = {
  record?: Maybe<UpdatePurchInvHeaderInput>;
};


export type MutationRemovePurchInvHeaderArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
};


export type MutationRemovePurchInvHeaderManyArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
};


export type MutationUpsertPurchInvLineArgs = {
  record?: Maybe<UpdatePurchInvLineInput>;
};


export type MutationRemovePurchInvLineArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
};


export type MutationRemovePurchInvLineManyArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
};


export type MutationUpsertPurchRcptHeaderArgs = {
  record?: Maybe<UpdatePurchRcptHeaderInput>;
};


export type MutationRemovePurchRcptHeaderArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
};


export type MutationRemovePurchRcptHeaderManyArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
};


export type MutationUpsertPurchRcptLineArgs = {
  record?: Maybe<UpdatePurchRcptLineInput>;
};


export type MutationRemovePurchRcptLineArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
};


export type MutationRemovePurchRcptLineManyArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
};


export type MutationUpsertQuoteHeaderArgs = {
  record?: Maybe<UpdateQuoteHeaderInput>;
};


export type MutationRemoveQuoteHeaderArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
};


export type MutationRemoveQuoteHeaderManyArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
};


export type MutationUpsertQuoteLineArgs = {
  record?: Maybe<UpdateQuoteLineInput>;
};


export type MutationRemoveQuoteLineArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
};


export type MutationRemoveQuoteLineManyArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
};


export type MutationUpsertQuoteTaskGroupArgs = {
  record?: Maybe<UpdateQuoteTaskGroupInput>;
};


export type MutationRemoveQuoteTaskGroupArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
};


export type MutationRemoveQuoteTaskGroupManyArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
};


export type MutationUpsertRentalHeaderArgs = {
  record?: Maybe<UpdateRentalHeaderInput>;
};


export type MutationRemoveRentalHeaderArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
};


export type MutationRemoveRentalHeaderManyArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
};


export type MutationUpsertRentalLineArgs = {
  record?: Maybe<UpdateRentalLineInput>;
};


export type MutationRemoveRentalLineArgs = {
  filter?: Maybe<FilterRentalLineInput>;
};


export type MutationRemoveRentalLineManyArgs = {
  filter?: Maybe<FilterRentalLineInput>;
};


export type MutationUpsertRentalLineSnapshotArgs = {
  record?: Maybe<UpdateRentalLineSnapshotInput>;
};


export type MutationRemoveRentalLineSnapshotArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
};


export type MutationRemoveRentalLineSnapshotManyArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
};


export type MutationUpsertRentalPostedCollHeaderArgs = {
  record?: Maybe<UpdateRentalPostedCollHeaderInput>;
};


export type MutationRemoveRentalPostedCollHeaderArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
};


export type MutationRemoveRentalPostedCollHeaderManyArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
};


export type MutationUpsertRentalPostedCollLineArgs = {
  record?: Maybe<UpdateRentalPostedCollLineInput>;
};


export type MutationRemoveRentalPostedCollLineArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
};


export type MutationRemoveRentalPostedCollLineManyArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
};


export type MutationUpsertRentalPostedShptHeaderArgs = {
  record?: Maybe<UpdateRentalPostedShptHeaderInput>;
};


export type MutationRemoveRentalPostedShptHeaderArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
};


export type MutationRemoveRentalPostedShptHeaderManyArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
};


export type MutationUpsertRentalPostedShptLineArgs = {
  record?: Maybe<UpdateRentalPostedShptLineInput>;
};


export type MutationRemoveRentalPostedShptLineArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
};


export type MutationRemoveRentalPostedShptLineManyArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
};


export type MutationUpsertRentalPriceTermsArgs = {
  record?: Maybe<UpdateRentalPriceTermsInput>;
};


export type MutationRemoveRentalPriceTermsArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
};


export type MutationRemoveRentalPriceTermsManyArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
};


export type MutationUpsertRentalReturnEntryArgs = {
  record?: Maybe<UpdateRentalReturnEntryInput>;
};


export type MutationRemoveRentalReturnEntryArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
};


export type MutationRemoveRentalReturnEntryManyArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
};


export type MutationUpsertRequestHeaderArgs = {
  record?: Maybe<UpdateRequestHeaderInput>;
};


export type MutationRemoveRequestHeaderArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
};


export type MutationRemoveRequestHeaderManyArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
};


export type MutationUpsertRequestLineArgs = {
  record?: Maybe<UpdateRequestLineInput>;
};


export type MutationRemoveRequestLineArgs = {
  filter?: Maybe<FilterRequestLineInput>;
};


export type MutationRemoveRequestLineManyArgs = {
  filter?: Maybe<FilterRequestLineInput>;
};


export type MutationUpsertReservationRequestHeaderArgs = {
  record?: Maybe<UpdateReservationRequestHeaderInput>;
};


export type MutationRemoveReservationRequestHeaderArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
};


export type MutationRemoveReservationRequestHeaderManyArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
};


export type MutationUpsertReservationRequestLineArgs = {
  record?: Maybe<UpdateReservationRequestLineInput>;
};


export type MutationRemoveReservationRequestLineArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
};


export type MutationRemoveReservationRequestLineManyArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
};


export type MutationUpsertResourceArgs = {
  record?: Maybe<UpdateResourceInput>;
};


export type MutationRemoveResourceArgs = {
  filter?: Maybe<FilterResourceInput>;
};


export type MutationRemoveResourceManyArgs = {
  filter?: Maybe<FilterResourceInput>;
};


export type MutationUpsertResponsibilityCenterArgs = {
  record?: Maybe<UpdateResponsibilityCenterInput>;
};


export type MutationRemoveResponsibilityCenterArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
};


export type MutationRemoveResponsibilityCenterManyArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
};


export type MutationUpsertReturnRequestHeaderArgs = {
  record?: Maybe<UpdateReturnRequestHeaderInput>;
};


export type MutationRemoveReturnRequestHeaderArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
};


export type MutationRemoveReturnRequestHeaderManyArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
};


export type MutationUpsertReturnRequestLineArgs = {
  record?: Maybe<UpdateReturnRequestLineInput>;
};


export type MutationRemoveReturnRequestLineArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
};


export type MutationRemoveReturnRequestLineManyArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
};


export type MutationUpsertRiskAnalysisHeaderArgs = {
  record?: Maybe<UpdateRiskAnalysisHeaderInput>;
};


export type MutationRemoveRiskAnalysisHeaderArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
};


export type MutationRemoveRiskAnalysisHeaderManyArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
};


export type MutationUpsertRiskAnalysisLineArgs = {
  record?: Maybe<UpdateRiskAnalysisLineInput>;
};


export type MutationRemoveRiskAnalysisLineArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
};


export type MutationRemoveRiskAnalysisLineManyArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
};


export type MutationUpsertRiskAnalysisSignatureArgs = {
  record?: Maybe<UpdateRiskAnalysisSignatureInput>;
};


export type MutationRemoveRiskAnalysisSignatureArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
};


export type MutationRemoveRiskAnalysisSignatureManyArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
};


export type MutationUpsertSalesHeaderArgs = {
  record?: Maybe<UpdateSalesHeaderInput>;
};


export type MutationRemoveSalesHeaderArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
};


export type MutationRemoveSalesHeaderManyArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
};


export type MutationUpsertSalesHeaderArchiveArgs = {
  record?: Maybe<UpdateSalesHeaderArchiveInput>;
};


export type MutationRemoveSalesHeaderArchiveArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
};


export type MutationRemoveSalesHeaderArchiveManyArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
};


export type MutationUpsertSalesHeaderHistoryArgs = {
  record?: Maybe<UpdateSalesHeaderHistoryInput>;
};


export type MutationRemoveSalesHeaderHistoryArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
};


export type MutationRemoveSalesHeaderHistoryManyArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
};


export type MutationUpsertSalesHeaderRequestArgs = {
  record?: Maybe<UpdateSalesHeaderRequestInput>;
};


export type MutationRemoveSalesHeaderRequestArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
};


export type MutationRemoveSalesHeaderRequestManyArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
};


export type MutationUpsertSalesLineArgs = {
  record?: Maybe<UpdateSalesLineInput>;
};


export type MutationRemoveSalesLineArgs = {
  filter?: Maybe<FilterSalesLineInput>;
};


export type MutationRemoveSalesLineManyArgs = {
  filter?: Maybe<FilterSalesLineInput>;
};


export type MutationUpsertSalesLineArchiveArgs = {
  record?: Maybe<UpdateSalesLineArchiveInput>;
};


export type MutationRemoveSalesLineArchiveArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
};


export type MutationRemoveSalesLineArchiveManyArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
};


export type MutationUpsertSalesLineRequestArgs = {
  record?: Maybe<UpdateSalesLineRequestInput>;
};


export type MutationRemoveSalesLineRequestArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
};


export type MutationRemoveSalesLineRequestManyArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
};


export type MutationUpsertSalespersonArgs = {
  record?: Maybe<UpdateSalespersonInput>;
};


export type MutationRemoveSalespersonArgs = {
  filter?: Maybe<FilterSalespersonInput>;
};


export type MutationRemoveSalespersonManyArgs = {
  filter?: Maybe<FilterSalespersonInput>;
};


export type MutationUpsertSalesPriceArgs = {
  record?: Maybe<UpdateSalesPriceInput>;
};


export type MutationRemoveSalesPriceArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
};


export type MutationRemoveSalesPriceManyArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
};


export type MutationUpsertSalesShipmentHeaderArgs = {
  record?: Maybe<UpdateSalesShipmentHeaderInput>;
};


export type MutationRemoveSalesShipmentHeaderArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
};


export type MutationRemoveSalesShipmentHeaderManyArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
};


export type MutationUpsertSalesShipmentLineArgs = {
  record?: Maybe<UpdateSalesShipmentLineInput>;
};


export type MutationRemoveSalesShipmentLineArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
};


export type MutationRemoveSalesShipmentLineManyArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
};


export type MutationUpsertServiceItemGroupArgs = {
  record?: Maybe<UpdateServiceItemGroupInput>;
};


export type MutationRemoveServiceItemGroupArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
};


export type MutationRemoveServiceItemGroupManyArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
};


export type MutationUpsertShipmentMethodArgs = {
  record?: Maybe<UpdateShipmentMethodInput>;
};


export type MutationRemoveShipmentMethodArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
};


export type MutationRemoveShipmentMethodManyArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
};


export type MutationUpsertShippingAgentArgs = {
  record?: Maybe<UpdateShippingAgentInput>;
};


export type MutationRemoveShippingAgentArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
};


export type MutationRemoveShippingAgentManyArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
};


export type MutationUpsertSignatureArgs = {
  record?: Maybe<UpdateSignatureInput>;
};


export type MutationRemoveSignatureArgs = {
  filter?: Maybe<FilterSignatureInput>;
};


export type MutationRemoveSignatureManyArgs = {
  filter?: Maybe<FilterSignatureInput>;
};


export type MutationUpsertSignRequestArgs = {
  record?: Maybe<UpdateSignRequestInput>;
};


export type MutationRemoveSignRequestArgs = {
  filter?: Maybe<FilterSignRequestInput>;
};


export type MutationRemoveSignRequestManyArgs = {
  filter?: Maybe<FilterSignRequestInput>;
};


export type MutationUpsertSpecialEquipmentArgs = {
  record?: Maybe<UpdateSpecialEquipmentInput>;
};


export type MutationRemoveSpecialEquipmentArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
};


export type MutationRemoveSpecialEquipmentManyArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
};


export type MutationUpsertStockkeepingUnitArgs = {
  record?: Maybe<UpdateStockkeepingUnitInput>;
};


export type MutationRemoveStockkeepingUnitArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
};


export type MutationRemoveStockkeepingUnitManyArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
};


export type MutationUpsertTariffNumberArgs = {
  record?: Maybe<UpdateTariffNumberInput>;
};


export type MutationRemoveTariffNumberArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
};


export type MutationRemoveTariffNumberManyArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
};


export type MutationUpsertTaxGroupArgs = {
  record?: Maybe<UpdateTaxGroupInput>;
};


export type MutationRemoveTaxGroupArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
};


export type MutationRemoveTaxGroupManyArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
};


export type MutationUpsertTimeRegistrationArgs = {
  record?: Maybe<UpdateTimeRegistrationInput>;
};


export type MutationRemoveTimeRegistrationArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
};


export type MutationRemoveTimeRegistrationManyArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
};


export type MutationUpsertTimeSheetDetailArgs = {
  record?: Maybe<UpdateTimeSheetDetailInput>;
};


export type MutationRemoveTimeSheetDetailArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
};


export type MutationRemoveTimeSheetDetailManyArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
};


export type MutationUpsertTimeSheetLineArgs = {
  record?: Maybe<UpdateTimeSheetLineInput>;
};


export type MutationRemoveTimeSheetLineArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
};


export type MutationRemoveTimeSheetLineManyArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
};


export type MutationUpsertTransactionSpecificationArgs = {
  record?: Maybe<UpdateTransactionSpecificationInput>;
};


export type MutationRemoveTransactionSpecificationArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
};


export type MutationRemoveTransactionSpecificationManyArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
};


export type MutationUpsertTransactionTypeArgs = {
  record?: Maybe<UpdateTransactionTypeInput>;
};


export type MutationRemoveTransactionTypeArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
};


export type MutationRemoveTransactionTypeManyArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
};


export type MutationUpsertTransportMethodArgs = {
  record?: Maybe<UpdateTransportMethodInput>;
};


export type MutationRemoveTransportMethodArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
};


export type MutationRemoveTransportMethodManyArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
};


export type MutationUpsertUnitOfMeasureArgs = {
  record?: Maybe<UpdateUnitOfMeasureInput>;
};


export type MutationRemoveUnitOfMeasureArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
};


export type MutationRemoveUnitOfMeasureManyArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
};


export type MutationUpsertUserArgs = {
  record?: Maybe<UpdateUserInput>;
};


export type MutationRemoveUserArgs = {
  filter?: Maybe<FilterUserInput>;
};


export type MutationRemoveUserManyArgs = {
  filter?: Maybe<FilterUserInput>;
};


export type MutationUpsertUserFavoritesArgs = {
  record?: Maybe<UpdateUserFavoritesInput>;
};


export type MutationRemoveUserFavoritesArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
};


export type MutationRemoveUserFavoritesManyArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
};


export type MutationUpsertVatBusinessPostingGroupArgs = {
  record?: Maybe<UpdateVatBusinessPostingGroupInput>;
};


export type MutationRemoveVatBusinessPostingGroupArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
};


export type MutationRemoveVatBusinessPostingGroupManyArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
};


export type MutationUpsertVatProductPostingGroupArgs = {
  record?: Maybe<UpdateVatProductPostingGroupInput>;
};


export type MutationRemoveVatProductPostingGroupArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
};


export type MutationRemoveVatProductPostingGroupManyArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
};


export type MutationUpsertVendorArgs = {
  record?: Maybe<UpdateVendorInput>;
};


export type MutationRemoveVendorArgs = {
  filter?: Maybe<FilterVendorInput>;
};


export type MutationRemoveVendorManyArgs = {
  filter?: Maybe<FilterVendorInput>;
};


export type MutationUpsertVendorBankAccountArgs = {
  record?: Maybe<UpdateVendorBankAccountInput>;
};


export type MutationRemoveVendorBankAccountArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
};


export type MutationRemoveVendorBankAccountManyArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
};


export type MutationUpsertVendorPostingGroupArgs = {
  record?: Maybe<UpdateVendorPostingGroupInput>;
};


export type MutationRemoveVendorPostingGroupArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
};


export type MutationRemoveVendorPostingGroupManyArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
};


export type MutationUpsertWarehouseClassArgs = {
  record?: Maybe<UpdateWarehouseClassInput>;
};


export type MutationRemoveWarehouseClassArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
};


export type MutationRemoveWarehouseClassManyArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
};


export type MutationUpsertWebBookingArgs = {
  record?: Maybe<UpdateWebBookingInput>;
};


export type MutationRemoveWebBookingArgs = {
  filter?: Maybe<FilterWebBookingInput>;
};


export type MutationRemoveWebBookingManyArgs = {
  filter?: Maybe<FilterWebBookingInput>;
};


export type MutationUpsertWebhookSubscriptionArgs = {
  record?: Maybe<UpdateWebhookSubscriptionInput>;
};


export type MutationRemoveWebhookSubscriptionArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
};


export type MutationRemoveWebhookSubscriptionManyArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
};


export type MutationUpsertWorkLogArgs = {
  record?: Maybe<UpdateWorkLogInput>;
};


export type MutationRemoveWorkLogArgs = {
  filter?: Maybe<FilterWorkLogInput>;
};


export type MutationRemoveWorkLogManyArgs = {
  filter?: Maybe<FilterWorkLogInput>;
};


export type MutationUpsertWorkTypeArgs = {
  record?: Maybe<UpdateWorkTypeInput>;
};


export type MutationRemoveWorkTypeArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
};


export type MutationRemoveWorkTypeManyArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
};


export type MutationCreateAccessRequestArgs = {
  record?: Maybe<CreateAccessRequestInput>;
};


export type MutationApproveRejectAccessRequestArgs = {
  record?: Maybe<RejectApproveAccessRequestInput>;
};


export type MutationToggleSupplierArgs = {
  record?: Maybe<ToggleSupplierInput>;
};


export type MutationSyncUserDomainIdsArgs = {
  filter?: Maybe<SyncUsersInput>;
};


export type MutationAddPushTokenArgs = {
  token: Scalars['String'];
  app: AppPushTokenInput;
};


export type MutationClearPushTokensArgs = {
  app: AppPushTokenInput;
};


export type MutationUpsertMeArgs = {
  record?: Maybe<UpdateUserInput>;
};


export type MutationToggleFavoriteProjectArgs = {
  record?: Maybe<ToggleFavoriteProjectInput>;
};


export type MutationCreateServiceAccountArgs = {
  record?: Maybe<CreateSerciceAccountInput>;
};


export type MutationRequestTokenArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationImpersonateUserArgs = {
  Email: Scalars['String'];
};


export type MutationSendPushNotificationArgs = {
  record?: Maybe<PushNotificationInput>;
};


export type MutationToggleProjectNotificationsArgs = {
  record?: Maybe<ToggleProjectNotificationsInput>;
};


export type MutationProxyHeartbeatArgs = {
  GlobalId: Scalars['String'];
};


export type MutationSetCustomerDomainImageArgs = {
  record?: Maybe<SetCustomerDomainImageInput>;
};


export type MutationUpsertCustomerDomainLimitedArgs = {
  record?: Maybe<CustomerDomainsLimitedUpsertInput>;
};


export type MutationSetDomainImageArgs = {
  record: UploadDomainImageInput;
  DomainId: Scalars['String'];
};


export type MutationSetFlagImageArgs = {
  record: UploadDomainImageInput;
  DomainId: Scalars['String'];
};


export type MutationSwitchToSiblingDomainArgs = {
  SwitchToDomainId: Scalars['String'];
};


export type MutationInitializeDomainArgs = {
  record?: Maybe<UpdateDomainInput>;
};


export type MutationUpsertDomainArgs = {
  record?: Maybe<UpdateDomainInput>;
};


export type MutationRemoveDomainArgs = {
  filter?: Maybe<FilterDomainInput>;
};


export type MutationRemoveDomainManyArgs = {
  filter?: Maybe<FilterDomainInput>;
};


export type MutationUpsertDomainIntegrationArgs = {
  record?: Maybe<UpdateDomainIntegrationInput>;
};


export type MutationTestDomainIntegrationArgs = {
  GlobalId: Scalars['String'];
};


export type MutationRemoveDomainIntegrationArgs = {
  filter?: Maybe<FilterDomainIntegrationInput>;
};


export type MutationLimitedUpsertCustomerProjectArgs = {
  record?: Maybe<LimitedUpdateCustomerProjectInput>;
};


export type MutationUpsertDocumentArgs = {
  record?: Maybe<UpdateDocumentInput>;
};


export type MutationCreateWorkshopOrderArgs = {
  record?: Maybe<CreateWorkshopOrderInput>;
};


export type MutationChangeObjectTypeImageArgs = {
  record?: Maybe<ChangeObjectTypeImagePayload>;
};


export type MutationChangeObjectFamilyImageArgs = {
  record?: Maybe<ChangeObjectTypeImagePayload>;
};


export type MutationChangeObjectGroupImageArgs = {
  record?: Maybe<ChangeObjectTypeImagePayload>;
};


export type MutationNotifyDailyRentTransactionsArgs = {
  app: AppPushTokenInput;
};


export type MutationSendOnRentListByEmailArgs = {
  record: SendOnRentEmailInput;
};


export type MutationRetrySyncArgs = {
  record: RetrySyncInput;
};


export type MutationUpsertSalesOrderArgs = {
  record?: Maybe<SalesOrderInput>;
  DomainId?: Maybe<Scalars['String']>;
};


export type MutationUpsertCompanyTreeArgs = {
  record?: Maybe<UpdateCompanyTreeInput>;
};


export type MutationRemoveInvitationArgs = {
  filter?: Maybe<FilterInvitationInput>;
};


export type MutationRemoveInvitationsManyArgs = {
  filter?: Maybe<FilterInvitationInput>;
};


export type MutationAcceptPersonalInvitationArgs = {
  Code: Scalars['String'];
};


export type MutationAcceptCompanyInvitationArgs = {
  Code: Scalars['String'];
};


export type MutationAcceptDomainInvitationArgs = {
  Code: Scalars['String'];
};


export type MutationCreatePersonalInvitationArgs = {
  record?: Maybe<PersonalInvitationInput>;
};


export type MutationCreateCompanyInvitationArgs = {
  record?: Maybe<CompanyInvitationInput>;
};


export type MutationCreateDomainInvitationArgs = {
  record?: Maybe<DomainInvitationInput>;
};


export type MutationDeactivateInvitationArgs = {
  code: Scalars['String'];
};


export type MutationUpdateDomainInvitationAppLinksArgs = {
  record: InvitationUpdateInput;
};


export type MutationUploadIosAppCodeFileArgs = {
  file: Scalars['String'];
  InvitationCode: Scalars['String'];
};


export type MutationClearInvitationCodesArgs = {
  code: Scalars['String'];
};


export type MutationStartStopFuncTestArgs = {
  GlobalId: Scalars['String'];
};


export type MutationClearFinishedFunctionalTestsArgs = {
  filter: ClearFunctionTestInput;
};

export type NavUser = {
  __typename?: 'NavUser';
  discriminator?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['Float']>;
  StateOPTION?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  WindowsSecurityID?: Maybe<Scalars['String']>;
  ChangePassword?: Maybe<Scalars['Boolean']>;
  LicenseType?: Maybe<Scalars['Float']>;
  LicenseTypeOPTION?: Maybe<Scalars['String']>;
  AuthenticationEmail?: Maybe<Scalars['String']>;
  EdgeCostCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type NavUserConnection = {
  __typename?: 'NavUserConnection';
  count: Scalars['Int'];
  edges: Array<NavUserConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type NavUserConnectionEdge = {
  __typename?: 'NavUserConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: NavUser;
};

export type NavUserDeleteResponse = {
  __typename?: 'NavUserDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<NavUser>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NavUserManyResult = {
  __typename?: 'NavUserManyResult';
  records?: Maybe<Array<Maybe<NavUser>>>;
  pageData?: Maybe<PageData>;
};

export type Object = {
  __typename?: 'Object';
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  RentalCustomerNo?: Maybe<Scalars['String']>;
  SalesCustomerNo?: Maybe<Scalars['String']>;
  FixedAssetNo?: Maybe<Scalars['String']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StatusInventory?: Maybe<Scalars['String']>;
  ServiceCustomerNo?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  PostedReceiptNo?: Maybe<Scalars['String']>;
  PostedInvoiceNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['String']>;
  BlockedforRental?: Maybe<Scalars['Boolean']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ServiceItemNo?: Maybe<Scalars['String']>;
  DefaultContractValue?: Maybe<Scalars['Float']>;
  DefaultContractDiscount?: Maybe<Scalars['Float']>;
  OwningLocationCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SoldFor?: Maybe<Scalars['Float']>;
  SoldDate?: Maybe<Scalars['DateTime']>;
  UseFixedAsset?: Maybe<Scalars['Boolean']>;
  CounterType?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  FirstRented?: Maybe<Scalars['String']>;
  LastRented?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  InPurchaseQuote?: Maybe<Scalars['Boolean']>;
  InPurchaseOrder?: Maybe<Scalars['Boolean']>;
  PurchaseInvoiced?: Maybe<Scalars['Boolean']>;
  PurchaseReceived?: Maybe<Scalars['Boolean']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  CreatedbyUser?: Maybe<Scalars['String']>;
  BlockedforSale?: Maybe<Scalars['Boolean']>;
  BlockedforPurchase?: Maybe<Scalars['Boolean']>;
  BlockedforReservation?: Maybe<Scalars['Boolean']>;
  OwnershipStatus?: Maybe<Scalars['String']>;
  InclinInvValueReport?: Maybe<Scalars['Boolean']>;
  StdCostAmount?: Maybe<Scalars['Float']>;
  PurchasedFor?: Maybe<Scalars['Float']>;
  ObtainedDiscount?: Maybe<Scalars['Float']>;
  NetCostAmountAdditional?: Maybe<Scalars['Float']>;
  DiscountGiven?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  EngineNo?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  RadioCode?: Maybe<Scalars['String']>;
  ChassisNo?: Maybe<Scalars['String']>;
  ConstructionYear?: Maybe<Scalars['DateTime']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  DateofPermit?: Maybe<Scalars['DateTime']>;
  InSalesOrder?: Maybe<Scalars['Boolean']>;
  InSalesQuote?: Maybe<Scalars['Boolean']>;
  InSalesInvoice?: Maybe<Scalars['Boolean']>;
  SoldonInvoiceNo?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  InDelivery?: Maybe<Scalars['Boolean']>;
  AdditionalObjectCost?: Maybe<Scalars['Float']>;
  AdditionalObjectCostDisc?: Maybe<Scalars['Float']>;
  AdditionalObjectSalesPrice?: Maybe<Scalars['Float']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  InSalesCreditMemo?: Maybe<Scalars['Boolean']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDay?: Maybe<Scalars['Boolean']>;
  PriceTerm?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  PolicyNo?: Maybe<Scalars['String']>;
  LeasingContractNo?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  NewOwnershipstatus?: Maybe<Scalars['String']>;
  NewStartDate?: Maybe<Scalars['DateTime']>;
  CreateFixedAsset?: Maybe<Scalars['Boolean']>;
  MaintenanceDescription?: Maybe<Scalars['String']>;
  Installment?: Maybe<Scalars['Boolean']>;
  InstallmentDescription?: Maybe<Scalars['String']>;
  ProductCode?: Maybe<Scalars['String']>;
  BonusTypeCode?: Maybe<Scalars['String']>;
  FireInspection?: Maybe<Scalars['Float']>;
  RecurringInspection?: Maybe<Scalars['Float']>;
  RecurringInspection2?: Maybe<Scalars['Float']>;
  InternalInspection?: Maybe<Scalars['Float']>;
  FireInspectionNext?: Maybe<Scalars['String']>;
  ExcludeVacation?: Maybe<Scalars['Boolean']>;
  RecurringInspectionNext?: Maybe<Scalars['String']>;
  RecurringInspection2Next?: Maybe<Scalars['String']>;
  InternalInspectionNext?: Maybe<Scalars['String']>;
  InspectionPeriodold?: Maybe<Scalars['String']>;
  InspectionPeriod?: Maybe<Scalars['Float']>;
  InspectionDate?: Maybe<Scalars['DateTime']>;
  NextInspectionDate?: Maybe<Scalars['DateTime']>;
  InspectionSerieNo?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['String']>;
  OwnerStatus?: Maybe<Scalars['String']>;
  CEDocument?: Maybe<Scalars['Boolean']>;
  EngineCertification?: Maybe<Scalars['Boolean']>;
  LastInventoriedDate?: Maybe<Scalars['DateTime']>;
  InventoryInfo?: Maybe<Scalars['String']>;
  FinanceDate?: Maybe<Scalars['DateTime']>;
  AmortizationRate?: Maybe<Scalars['String']>;
  ContractNumber?: Maybe<Scalars['String']>;
  PurschaseValue?: Maybe<Scalars['Float']>;
  ContractInfo?: Maybe<Scalars['String']>;
  ServiceAgreement?: Maybe<Scalars['String']>;
  Finance?: Maybe<Scalars['String']>;
  RentalAmount?: Maybe<Scalars['Float']>;
  StatusInventoryBeforeLost?: Maybe<Scalars['String']>;
  Bitmap1?: Maybe<Scalars['String']>;
  Bitmap2?: Maybe<Scalars['String']>;
  Bitmap3?: Maybe<Scalars['String']>;
  Bitmap4?: Maybe<Scalars['String']>;
  Bitmap5?: Maybe<Scalars['String']>;
  Bitmap6?: Maybe<Scalars['String']>;
  Bitmap7?: Maybe<Scalars['String']>;
  Bitmap8?: Maybe<Scalars['String']>;
  Bitmap9?: Maybe<Scalars['String']>;
  Bitmap10?: Maybe<Scalars['String']>;
  Bitmap11?: Maybe<Scalars['String']>;
  Bitmap12?: Maybe<Scalars['String']>;
  Bitmap13?: Maybe<Scalars['String']>;
  Bitmap14?: Maybe<Scalars['String']>;
  Bitmap15?: Maybe<Scalars['String']>;
  Bitmap16?: Maybe<Scalars['String']>;
  Bitmap17?: Maybe<Scalars['String']>;
  Bitmap18?: Maybe<Scalars['String']>;
  Bitmap19?: Maybe<Scalars['String']>;
  Bitmap20?: Maybe<Scalars['String']>;
  Bitmap21?: Maybe<Scalars['String']>;
  Bitmap22?: Maybe<Scalars['String']>;
  Bitmap23?: Maybe<Scalars['String']>;
  Bitmap24?: Maybe<Scalars['String']>;
  Bitmap25?: Maybe<Scalars['String']>;
  Bitmap26?: Maybe<Scalars['String']>;
  Bitmap27?: Maybe<Scalars['String']>;
  Bitmap28?: Maybe<Scalars['String']>;
  Bitmap29?: Maybe<Scalars['String']>;
  Bitmap30?: Maybe<Scalars['String']>;
  Bitmap31?: Maybe<Scalars['String']>;
  Bitmap32?: Maybe<Scalars['String']>;
  ObjectNoConcern?: Maybe<Scalars['String']>;
  PreviousInventoryStatus?: Maybe<Scalars['String']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  WorkOrderCustomerNo?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  OldObjectNo?: Maybe<Scalars['String']>;
  ChangedbyUserID?: Maybe<Scalars['String']>;
  ChangedDate?: Maybe<Scalars['DateTime']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  RegStatusRental?: Maybe<Scalars['String']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  WarrantyDate?: Maybe<Scalars['DateTime']>;
  TrackingCode?: Maybe<Scalars['String']>;
  VATProdPostingGrpRental?: Maybe<Scalars['String']>;
  ServiceStatus?: Maybe<Scalars['String']>;
  LastServiceDate?: Maybe<Scalars['DateTime']>;
  LastInspectionDate?: Maybe<Scalars['DateTime']>;
  LastInspDate?: Maybe<Scalars['DateTime']>;
  TerminateFixedAsset?: Maybe<Scalars['Boolean']>;
  CounterReporting?: Maybe<Scalars['Boolean']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  CounterType2?: Maybe<Scalars['String']>;
  Counter2?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  DateFilter2?: Maybe<Scalars['DateTime']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  TimeFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  CurrentActiveContractNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  CommentObjectType?: Maybe<Scalars['Boolean']>;
  CommentsinContract?: Maybe<Scalars['Boolean']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowComponentCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowTotalPurchaseCost?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  Invoiceddays?: Maybe<Scalars['Float']>;
  FlowLinkedSumCost?: Maybe<Scalars['Float']>;
  FlowLinkedSalesDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedOtherCost?: Maybe<Scalars['Float']>;
  FlowLinkedLeasingCost?: Maybe<Scalars['Float']>;
  FlowLinkedRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedRentalDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowLinkedServiceCost?: Maybe<Scalars['Float']>;
  FlowLinkedGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedServiceAgrCost?: Maybe<Scalars['Float']>;
  FlowLinkedDepreciation?: Maybe<Scalars['Float']>;
  FlowLinkedUppreciation?: Maybe<Scalars['Float']>;
  FlowLinkedDownpreciation?: Maybe<Scalars['Float']>;
  FlowLinkedPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedSalesCharge?: Maybe<Scalars['Float']>;
  FlowLinkedServiceIncome?: Maybe<Scalars['Float']>;
  FlowLinkedSrvAgrIncome?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedValueChange?: Maybe<Scalars['Float']>;
  FlowLinkedRentalDays?: Maybe<Scalars['Float']>;
  FlowLinkedRentalHours?: Maybe<Scalars['Float']>;
  FlowLinkedInvoicedDays?: Maybe<Scalars['Float']>;
  FlowLinkedGainbySale?: Maybe<Scalars['Float']>;
  FlowLinkedLossbySale?: Maybe<Scalars['Float']>;
  FlowLinkedOtherIncome?: Maybe<Scalars['Float']>;
  PostedWorkOrderFlow?: Maybe<Scalars['Float']>;
  PostedWorkPurchaseFlow?: Maybe<Scalars['Float']>;
  PostedWorkOrderLineFlow?: Maybe<Scalars['Float']>;
  PostedWorkPurchLineFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopEntriesFlow?: Maybe<Scalars['Float']>;
  LastPriceAgreement?: Maybe<Scalars['Float']>;
  PSILink?: Maybe<Scalars['String']>;
  CaravanList?: Maybe<Scalars['String']>;
  TechnicalInfo?: Maybe<Scalars['String']>;
  FunctionalTests?: Maybe<Scalars['Float']>;
  LastRentalStatusChage?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  NoofRentalQuoteLines?: Maybe<Scalars['Float']>;
  NoofOpenRentalLines?: Maybe<Scalars['Float']>;
  NoofClosedRentalLines?: Maybe<Scalars['Float']>;
  NoofDefaultRentalPrices?: Maybe<Scalars['Float']>;
  WarrantyDateFlow?: Maybe<Scalars['DateTime']>;
  GPSCoordinates?: Maybe<Scalars['String']>;
  PSI?: Maybe<Scalars['String']>;
  AdditionalObjectStatus?: Maybe<Scalars['String']>;
  AdditionalObjectStatusOPTION?: Maybe<Scalars['String']>;
  ManufactureDate?: Maybe<Scalars['DateTime']>;
  LastInspectionBy?: Maybe<Scalars['String']>;
  LastInspectionByEmail?: Maybe<Scalars['String']>;
  PurchaseDate?: Maybe<Scalars['DateTime']>;
  FirstUseDate?: Maybe<Scalars['DateTime']>;
  IsElectric?: Maybe<Scalars['Boolean']>;
  OffRentDateEdge?: Maybe<Scalars['DateTime']>;
  Broken?: Maybe<Scalars['Boolean']>;
  RealBridgeSoldToCustomerNo?: Maybe<Scalars['String']>;
  RealBridgeCustomerNo?: Maybe<Scalars['String']>;
  RealBridgeProjectNo?: Maybe<Scalars['String']>;
  DekraLno?: Maybe<Scalars['String']>;
  SentToDekra?: Maybe<Scalars['Boolean']>;
  CustomerRef?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  CustomerPhone?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CurrentPlacement?: Maybe<Scalars['String']>;
  ActualPlacement?: Maybe<Scalars['String']>;
  ObjStatus?: Maybe<Scalars['Boolean']>;
  InspectionMonth?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectCommentLine = {
  __typename?: 'ObjectCommentLine';
  discriminator?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['Float']>;
  TableNameOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Code?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectCommentLineConnection = {
  __typename?: 'ObjectCommentLineConnection';
  count: Scalars['Int'];
  edges: Array<ObjectCommentLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectCommentLineConnectionEdge = {
  __typename?: 'ObjectCommentLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ObjectCommentLine;
};

export type ObjectCommentLineDeleteResponse = {
  __typename?: 'ObjectCommentLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ObjectCommentLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectCommentLineManyResult = {
  __typename?: 'ObjectCommentLineManyResult';
  records?: Maybe<Array<Maybe<ObjectCommentLine>>>;
  pageData?: Maybe<PageData>;
};

export type ObjectConnection = {
  __typename?: 'ObjectConnection';
  count: Scalars['Int'];
  edges: Array<ObjectConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectConnectionEdge = {
  __typename?: 'ObjectConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Object;
};

export type ObjectDeleteResponse = {
  __typename?: 'ObjectDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Object>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectFamily = {
  __typename?: 'ObjectFamily';
  discriminator?: Maybe<Scalars['String']>;
  Family?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectFamilyConnection = {
  __typename?: 'ObjectFamilyConnection';
  count: Scalars['Int'];
  edges: Array<ObjectFamilyConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectFamilyConnectionEdge = {
  __typename?: 'ObjectFamilyConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ObjectFamily;
};

export type ObjectFamilyDeleteResponse = {
  __typename?: 'ObjectFamilyDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ObjectFamily>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectFamilyManyResult = {
  __typename?: 'ObjectFamilyManyResult';
  records?: Maybe<Array<Maybe<ObjectFamily>>>;
  pageData?: Maybe<PageData>;
};

export type ObjectGroup = {
  __typename?: 'ObjectGroup';
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['Float']>;
  DepreciationMethodOPTION?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['Float']>;
  StdRentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupNoConcern?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  TaxDepreciationMethod?: Maybe<Scalars['Float']>;
  TaxDepreciationMethodOPTION?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectGroupConnection = {
  __typename?: 'ObjectGroupConnection';
  count: Scalars['Int'];
  edges: Array<ObjectGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectGroupConnectionEdge = {
  __typename?: 'ObjectGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ObjectGroup;
};

export type ObjectGroupDeleteResponse = {
  __typename?: 'ObjectGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ObjectGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectGroupManyResult = {
  __typename?: 'ObjectGroupManyResult';
  records?: Maybe<Array<Maybe<ObjectGroup>>>;
  pageData?: Maybe<PageData>;
};

export type ObjectManyResult = {
  __typename?: 'ObjectManyResult';
  records?: Maybe<Array<Maybe<Object>>>;
  pageData?: Maybe<PageData>;
};

export type ObjectReturnHeader = {
  __typename?: 'ObjectReturnHeader';
  discriminator?: Maybe<Scalars['String']>;
  ReturnType?: Maybe<Scalars['Float']>;
  ReturnTypeOPTION?: Maybe<Scalars['String']>;
  OffRentNo?: Maybe<Scalars['String']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  OffRentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDay?: Maybe<Scalars['Boolean']>;
  ReturnQuantity?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  QuantitytoSell?: Maybe<Scalars['Float']>;
  QuantitytoDamage?: Maybe<Scalars['Float']>;
  ReceivingLocation?: Maybe<Scalars['String']>;
  CounteronDelivery?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  Counter2onDelivery?: Maybe<Scalars['Float']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  ReturnQuantityTime?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ChargeCustomer?: Maybe<Scalars['Boolean']>;
  CurrentPlacement?: Maybe<Scalars['String']>;
  EdgePost?: Maybe<Scalars['Boolean']>;
  OffRentUpdated?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  ReturnNo?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ExtDocNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  DeletedManually?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqRefill?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedbyUserID?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  MotherLineRegUpdateLine?: Maybe<Scalars['Boolean']>;
  MotherLineType?: Maybe<Scalars['Float']>;
  MotherLineTypeOPTION?: Maybe<Scalars['String']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  SubmittedByUserName?: Maybe<Scalars['String']>;
  SubmittedByUserEmail?: Maybe<Scalars['String']>;
  PushNotificationSent?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectReturnHeaderConnection = {
  __typename?: 'ObjectReturnHeaderConnection';
  count: Scalars['Int'];
  edges: Array<ObjectReturnHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectReturnHeaderConnectionEdge = {
  __typename?: 'ObjectReturnHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ObjectReturnHeader;
};

export type ObjectReturnHeaderDeleteResponse = {
  __typename?: 'ObjectReturnHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ObjectReturnHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectReturnHeaderManyResult = {
  __typename?: 'ObjectReturnHeaderManyResult';
  records?: Maybe<Array<Maybe<ObjectReturnHeader>>>;
  pageData?: Maybe<PageData>;
};

export type ObjectReturnLine = {
  __typename?: 'ObjectReturnLine';
  discriminator?: Maybe<Scalars['String']>;
  ReturnNo?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ExtDocNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  DeletedManually?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqRefill?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedbyUserID?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  MotherLineRegUpdateLine?: Maybe<Scalars['Boolean']>;
  MotherLineType?: Maybe<Scalars['Float']>;
  MotherLineTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectReturnLineConnection = {
  __typename?: 'ObjectReturnLineConnection';
  count: Scalars['Int'];
  edges: Array<ObjectReturnLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectReturnLineConnectionEdge = {
  __typename?: 'ObjectReturnLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ObjectReturnLine;
};

export type ObjectReturnLineDeleteResponse = {
  __typename?: 'ObjectReturnLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ObjectReturnLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectReturnLineManyResult = {
  __typename?: 'ObjectReturnLineManyResult';
  records?: Maybe<Array<Maybe<ObjectReturnLine>>>;
  pageData?: Maybe<PageData>;
};

export type ObjectType = {
  __typename?: 'ObjectType';
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  CounterType?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  SaleDiscGroup?: Maybe<Scalars['String']>;
  RentalDiscGroup?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SalesPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['String']>;
  WebPage?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  FixedCostPrice?: Maybe<Scalars['Float']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RetentionLevel?: Maybe<Scalars['Float']>;
  TaxDepreciationMethod?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  CounterType2?: Maybe<Scalars['String']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  RentalReservationsQty?: Maybe<Scalars['Float']>;
  RentalDeliveriesQty?: Maybe<Scalars['Float']>;
  ExpectedRentalReturnsQty?: Maybe<Scalars['Float']>;
  RentalReturnsQty?: Maybe<Scalars['Float']>;
  PictureURL?: Maybe<Scalars['String']>;
  PSIDocumentURL?: Maybe<Scalars['String']>;
  NotAvailableForReservation?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectTypeConnection = {
  __typename?: 'ObjectTypeConnection';
  count: Scalars['Int'];
  edges: Array<ObjectTypeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ObjectTypeConnectionEdge = {
  __typename?: 'ObjectTypeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ObjectType;
};

export type ObjectTypeDeleteResponse = {
  __typename?: 'ObjectTypeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ObjectType>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObjectTypeLimited = {
  __typename?: 'ObjectTypeLimited';
  Descriptin?: Maybe<Scalars['String']>;
  PictureUrl?: Maybe<Scalars['String']>;
};

export type ObjectTypeLimitedInput = {
  DomainId: Scalars['String'];
  ObjectType: Scalars['String'];
};

export type ObjectTypeManyResult = {
  __typename?: 'ObjectTypeManyResult';
  records?: Maybe<Array<Maybe<ObjectType>>>;
  pageData?: Maybe<PageData>;
};

export type OcrAnalysis = {
  __typename?: 'OcrAnalysis';
  discriminator?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  textAngle?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type OcrAnalysisConnection = {
  __typename?: 'OcrAnalysisConnection';
  count: Scalars['Int'];
  edges: Array<OcrAnalysisConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type OcrAnalysisConnectionEdge = {
  __typename?: 'OcrAnalysisConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: OcrAnalysis;
};

export type OcrAnalysisDeleteResponse = {
  __typename?: 'OcrAnalysisDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<OcrAnalysis>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OcrAnalysisManyResult = {
  __typename?: 'OcrAnalysisManyResult';
  records?: Maybe<Array<Maybe<OcrAnalysis>>>;
  pageData?: Maybe<PageData>;
};

export type OldFunctionTestCountResult = {
  __typename?: 'OldFunctionTestCountResult';
  DateUsed?: Maybe<Scalars['DateTime']>;
  header?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
};

export enum OrderStateEnum {
  Open = 'Open',
  Released = 'Released',
  Shipped = 'Shipped',
  PartlyShipped = 'PartlyShipped',
  Posted = 'Posted'
}

export type PageData = {
  __typename?: 'PageData';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
  endCursor: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  DirectDebit?: Maybe<Scalars['Boolean']>;
  DirectDebitPmtTermsCode?: Maybe<Scalars['String']>;
  PmtExportLineDefinition?: Maybe<Scalars['String']>;
  BankDataConversionPmtType?: Maybe<Scalars['String']>;
  UseforInvoicing?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  AMCBankPmtType?: Maybe<Scalars['String']>;
  EQMCashPayment?: Maybe<Scalars['Boolean']>;
  EQMPaymentType?: Maybe<Scalars['Float']>;
  EQMPaymentTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  count: Scalars['Int'];
  edges: Array<PaymentMethodConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaymentMethodConnectionEdge = {
  __typename?: 'PaymentMethodConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PaymentMethod;
};

export type PaymentMethodDeleteResponse = {
  __typename?: 'PaymentMethodDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PaymentMethod>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentMethodManyResult = {
  __typename?: 'PaymentMethodManyResult';
  records?: Maybe<Array<Maybe<PaymentMethod>>>;
  pageData?: Maybe<PageData>;
};

export type PaymentTerms = {
  __typename?: 'PaymentTerms';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  DueDateCalculation?: Maybe<Scalars['String']>;
  DiscountDateCalculation?: Maybe<Scalars['String']>;
  DiscountPercent?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CalcPmtDisconCrMemos?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentTermsConnection = {
  __typename?: 'PaymentTermsConnection';
  count: Scalars['Int'];
  edges: Array<PaymentTermsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaymentTermsConnectionEdge = {
  __typename?: 'PaymentTermsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PaymentTerms;
};

export type PaymentTermsDeleteResponse = {
  __typename?: 'PaymentTermsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PaymentTerms>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentTermsManyResult = {
  __typename?: 'PaymentTermsManyResult';
  records?: Maybe<Array<Maybe<PaymentTerms>>>;
  pageData?: Maybe<PageData>;
};

export type PersonalInvitationInput = {
  UserEmail: Scalars['String'];
  UserName: Scalars['String'];
  Role?: Maybe<InvitationInputRoleEnum>;
  RegistrationNo: Scalars['String'];
  CompanyName?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
};

export type PlatformFeature = {
  __typename?: 'PlatformFeature';
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  basic?: Maybe<Scalars['Boolean']>;
  premium?: Maybe<Scalars['Boolean']>;
  sorting?: Maybe<Scalars['Float']>;
  enterprise?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PlatformFeatureConnection = {
  __typename?: 'PlatformFeatureConnection';
  count: Scalars['Int'];
  edges: Array<PlatformFeatureConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PlatformFeatureConnectionEdge = {
  __typename?: 'PlatformFeatureConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PlatformFeature;
};

export type PlatformFeatureDeleteResponse = {
  __typename?: 'PlatformFeatureDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PlatformFeature>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PlatformFeatureManyResult = {
  __typename?: 'PlatformFeatureManyResult';
  records?: Maybe<Array<Maybe<PlatformFeature>>>;
  pageData?: Maybe<PageData>;
};

export type PostCode = {
  __typename?: 'PostCode';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  SearchCity?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PostCodeConnection = {
  __typename?: 'PostCodeConnection';
  count: Scalars['Int'];
  edges: Array<PostCodeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PostCodeConnectionEdge = {
  __typename?: 'PostCodeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PostCode;
};

export type PostCodeDeleteResponse = {
  __typename?: 'PostCodeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PostCode>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PostCodeManyResult = {
  __typename?: 'PostCodeManyResult';
  records?: Maybe<Array<Maybe<PostCode>>>;
  pageData?: Maybe<PageData>;
};

export type Project = {
  __typename?: 'Project';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  CreationDate?: Maybe<Scalars['DateTime']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  PersonResponsible?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  JobPostingGroup?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  WIPMethod?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  WIPPostingDate?: Maybe<Scalars['DateTime']>;
  InvoiceCurrencyCode?: Maybe<Scalars['String']>;
  ExchCalculationCost?: Maybe<Scalars['Float']>;
  ExchCalculationCostOPTION?: Maybe<Scalars['String']>;
  ExchCalculationPrice?: Maybe<Scalars['Float']>;
  ExchCalculationPriceOPTION?: Maybe<Scalars['String']>;
  AllowScheduleContractLines?: Maybe<Scalars['Boolean']>;
  Complete?: Maybe<Scalars['Boolean']>;
  ApplyUsageLink?: Maybe<Scalars['Boolean']>;
  WIPPostingMethod?: Maybe<Scalars['Float']>;
  WIPPostingMethodOPTION?: Maybe<Scalars['String']>;
  OverBudget?: Maybe<Scalars['Boolean']>;
  ProjectManager?: Maybe<Scalars['String']>;
  BusinessArea?: Maybe<Scalars['String']>;
  Inactive?: Maybe<Scalars['Boolean']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  ResourceFilter?: Maybe<Scalars['String']>;
  PostingDateFilter?: Maybe<Scalars['String']>;
  ResourceGrFilter?: Maybe<Scalars['String']>;
  PlanningDateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ScheduledResQty?: Maybe<Scalars['Float']>;
  ScheduledResGrQty?: Maybe<Scalars['Float']>;
  TotalWIPCostAmount?: Maybe<Scalars['Float']>;
  TotalWIPCostGLAmount?: Maybe<Scalars['Float']>;
  WIPEntriesExist?: Maybe<Scalars['Boolean']>;
  WIPGLPostingDate?: Maybe<Scalars['DateTime']>;
  RecogSalesAmount?: Maybe<Scalars['Float']>;
  RecogSalesGLAmount?: Maybe<Scalars['Float']>;
  RecogCostsAmount?: Maybe<Scalars['Float']>;
  RecogCostsGLAmount?: Maybe<Scalars['Float']>;
  TotalWIPSalesAmount?: Maybe<Scalars['Float']>;
  TotalWIPSalesGLAmount?: Maybe<Scalars['Float']>;
  WIPCompletionCalculated?: Maybe<Scalars['Boolean']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  WIPWarnings?: Maybe<Scalars['Boolean']>;
  AppliedCostsGLAmount?: Maybe<Scalars['Float']>;
  AppliedSalesGLAmount?: Maybe<Scalars['Float']>;
  CalcRecogSalesAmount?: Maybe<Scalars['Float']>;
  CalcRecogCostsAmount?: Maybe<Scalars['Float']>;
  CalcRecogSalesGLAmount?: Maybe<Scalars['Float']>;
  CalcRecogCostsGLAmount?: Maybe<Scalars['Float']>;
  WIPCompletionPosted?: Maybe<Scalars['Boolean']>;
  MonthlyValue?: Maybe<Scalars['Float']>;
  EstimatedValue?: Maybe<Scalars['Float']>;
  LinkedTo?: Maybe<Scalars['String']>;
  JobTaskType?: Maybe<Scalars['String']>;
  SyncJobTastTypeWithDevops?: Maybe<Scalars['Boolean']>;
  DevopsTeamId?: Maybe<Scalars['String']>;
  DevopsProjectId?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  count: Scalars['Int'];
  edges: Array<ProjectConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ProjectConnectionEdge = {
  __typename?: 'ProjectConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Project;
};

export type ProjectDeleteResponse = {
  __typename?: 'ProjectDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Project>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectManyResult = {
  __typename?: 'ProjectManyResult';
  records?: Maybe<Array<Maybe<Project>>>;
  pageData?: Maybe<PageData>;
};

export type ProjectPushNotifications = {
  __typename?: 'ProjectPushNotifications';
  ProjectId?: Maybe<Scalars['String']>;
  Shipments?: Maybe<Scalars['Boolean']>;
  Returns?: Maybe<Scalars['Boolean']>;
};

export type ProxyHeartbeatResponse = {
  __typename?: 'ProxyHeartbeatResponse';
  status?: Maybe<Scalars['String']>;
};

export type PurchCrMemoHeader = {
  __typename?: 'PurchCrMemoHeader';
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VendorCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepaymentCreditMemo?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  Paid?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  VendorLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ReturnOrderNo?: Maybe<Scalars['String']>;
  ReturnOrderNoSeries?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchCrMemoHeaderConnection = {
  __typename?: 'PurchCrMemoHeaderConnection';
  count: Scalars['Int'];
  edges: Array<PurchCrMemoHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchCrMemoHeaderConnectionEdge = {
  __typename?: 'PurchCrMemoHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchCrMemoHeader;
};

export type PurchCrMemoHeaderDeleteResponse = {
  __typename?: 'PurchCrMemoHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchCrMemoHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchCrMemoHeaderManyResult = {
  __typename?: 'PurchCrMemoHeaderManyResult';
  records?: Maybe<Array<Maybe<PurchCrMemoHeader>>>;
  pageData?: Maybe<PageData>;
};

export type PurchCrMemoLine = {
  __typename?: 'PurchCrMemoLine';
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowCrMemooiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  CrMemoDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchCrMemoLineConnection = {
  __typename?: 'PurchCrMemoLineConnection';
  count: Scalars['Int'];
  edges: Array<PurchCrMemoLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchCrMemoLineConnectionEdge = {
  __typename?: 'PurchCrMemoLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchCrMemoLine;
};

export type PurchCrMemoLineDeleteResponse = {
  __typename?: 'PurchCrMemoLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchCrMemoLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchCrMemoLineManyResult = {
  __typename?: 'PurchCrMemoLineManyResult';
  records?: Maybe<Array<Maybe<PurchCrMemoLine>>>;
  pageData?: Maybe<PageData>;
};

export type PurchInvHeader = {
  __typename?: 'PurchInvHeader';
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorInvoiceNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  VendorLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  DraftInvoiceSystemId?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMerviceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMObjWorkPurchFlow?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchInvHeaderConnection = {
  __typename?: 'PurchInvHeaderConnection';
  count: Scalars['Int'];
  edges: Array<PurchInvHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchInvHeaderConnectionEdge = {
  __typename?: 'PurchInvHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchInvHeader;
};

export type PurchInvHeaderDeleteResponse = {
  __typename?: 'PurchInvHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchInvHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchInvHeaderManyResult = {
  __typename?: 'PurchInvHeaderManyResult';
  records?: Maybe<Array<Maybe<PurchInvHeader>>>;
  pageData?: Maybe<PageData>;
};

export type PurchInvLine = {
  __typename?: 'PurchInvLine';
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceiptLineNo?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICCrossReferenceNo?: Maybe<Scalars['String']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchInvLineConnection = {
  __typename?: 'PurchInvLineConnection';
  count: Scalars['Int'];
  edges: Array<PurchInvLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchInvLineConnectionEdge = {
  __typename?: 'PurchInvLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchInvLine;
};

export type PurchInvLineDeleteResponse = {
  __typename?: 'PurchInvLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchInvLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchInvLineManyResult = {
  __typename?: 'PurchInvLineManyResult';
  records?: Maybe<Array<Maybe<PurchInvLine>>>;
  pageData?: Maybe<PageData>;
};

export type PurchRcptHeader = {
  __typename?: 'PurchRcptHeader';
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorShipmentNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactno?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchRcptHeaderConnection = {
  __typename?: 'PurchRcptHeaderConnection';
  count: Scalars['Int'];
  edges: Array<PurchRcptHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchRcptHeaderConnectionEdge = {
  __typename?: 'PurchRcptHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchRcptHeader;
};

export type PurchRcptHeaderDeleteResponse = {
  __typename?: 'PurchRcptHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchRcptHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchRcptHeaderManyResult = {
  __typename?: 'PurchRcptHeaderManyResult';
  records?: Maybe<Array<Maybe<PurchRcptHeader>>>;
  pageData?: Maybe<PageData>;
};

export type PurchRcptLine = {
  __typename?: 'PurchRcptLine';
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ItemRcptEntryNo?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  SalesOrderNo?: Maybe<Scalars['String']>;
  SalesOrderLineNo?: Maybe<Scalars['Float']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrderSalesNo?: Maybe<Scalars['String']>;
  SpecialOrderSalesLineNo?: Maybe<Scalars['Float']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedReceiptDate?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  ItemChargeBaseAmount?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
  OverReceiptQuantity?: Maybe<Scalars['Float']>;
  OverReceiptCode?: Maybe<Scalars['String']>;
  OverReceiptCode2?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchRcptLineConnection = {
  __typename?: 'PurchRcptLineConnection';
  count: Scalars['Int'];
  edges: Array<PurchRcptLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchRcptLineConnectionEdge = {
  __typename?: 'PurchRcptLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchRcptLine;
};

export type PurchRcptLineDeleteResponse = {
  __typename?: 'PurchRcptLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchRcptLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchRcptLineManyResult = {
  __typename?: 'PurchRcptLineManyResult';
  records?: Maybe<Array<Maybe<PurchRcptLine>>>;
  pageData?: Maybe<PageData>;
};

export type PurchaseHeader = {
  __typename?: 'PurchaseHeader';
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<PurchaseHeaderDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Receive?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  ReceivingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastReceivingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorShipmentNo?: Maybe<Scalars['String']>;
  VendorInvoiceNo?: Maybe<Scalars['String']>;
  VendorCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ReceivingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  BuyfromICPartnerCode?: Maybe<Scalars['String']>;
  PaytoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  JournalTemplName?: Maybe<Scalars['String']>;
  ARcdNotInvExVATLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PartiallyInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  VendorAuthorizationNo?: Maybe<Scalars['String']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentNoSeries?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  LastReturnShipmentNo?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  PendingApprovals?: Maybe<Scalars['Float']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBGrossInvoiceAmount?: Maybe<Scalars['Float']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  PEBInwardRegistrated?: Maybe<Scalars['Boolean']>;
  PEBOCRNo?: Maybe<Scalars['String']>;
  PEBRecipientBankAccount?: Maybe<Scalars['String']>;
  PEBOfwhichVATAmount?: Maybe<Scalars['Float']>;
  PEBInwardVAT?: Maybe<Scalars['Float']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMObjWorkPurchFlow?: Maybe<Scalars['Float']>;
  EQMRentalShipmentNo?: Maybe<Scalars['String']>;
  EQMRentalCollectionNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  EQMPostedRentalShipmentNo?: Maybe<Scalars['String']>;
  EQMPostedRentalCollNo?: Maybe<Scalars['String']>;
  EQMControlTypeCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchaseHeaderConnection = {
  __typename?: 'PurchaseHeaderConnection';
  count: Scalars['Int'];
  edges: Array<PurchaseHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchaseHeaderConnectionEdge = {
  __typename?: 'PurchaseHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchaseHeader;
};

export type PurchaseHeaderDeleteResponse = {
  __typename?: 'PurchaseHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchaseHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum PurchaseHeaderDocumentTypeEnum {
  Quote = 'Quote',
  Order = 'Order',
  Invoice = 'Invoice',
  CreditMemo = 'CreditMemo',
  BlanketOrder = 'BlanketOrder',
  ReturnOrder = 'ReturnOrder'
}

export type PurchaseHeaderManyResult = {
  __typename?: 'PurchaseHeaderManyResult';
  records?: Maybe<Array<Maybe<PurchaseHeader>>>;
  pageData?: Maybe<PageData>;
};

export type PurchaseLine = {
  __typename?: 'PurchaseLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<PurchaseLineDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoReceive?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoiced?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityReceived?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceiptLineNo?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  SalesOrderNo?: Maybe<Scalars['String']>;
  SalesOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  OutstandingAmtExVATLCY?: Maybe<Scalars['Float']>;
  ARcdNotInvExVATLCY?: Maybe<Scalars['Float']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  PrepmtPmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscount?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  JobPlanningLineNo?: Maybe<Scalars['Float']>;
  JobRemainingQty?: Maybe<Scalars['Float']>;
  JobRemainingQtyBase?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QtyRoundingPrecision?: Maybe<Scalars['Float']>;
  QtyRoundingPrecisionBase?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoReceiveBase?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoicedBase?: Maybe<Scalars['Float']>;
  QtyReceivedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderSalesNo?: Maybe<Scalars['String']>;
  SpecialOrderSalesLineNo?: Maybe<Scalars['Float']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedReceiptDate?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  ReturnQtytoShip?: Maybe<Scalars['Float']>;
  ReturnQtytoShipBase?: Maybe<Scalars['Float']>;
  ReturnQtyShippedNotInvd?: Maybe<Scalars['Float']>;
  RetQtyShpdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnShpdNotInvd?: Maybe<Scalars['Float']>;
  ReturnShpdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyShipped?: Maybe<Scalars['Float']>;
  ReturnQtyShippedBase?: Maybe<Scalars['Float']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  CopiedFromPostedDoc?: Maybe<Scalars['Boolean']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AttachedDocCount?: Maybe<Scalars['Float']>;
  OverReceiptQuantity?: Maybe<Scalars['Float']>;
  OverReceiptCode?: Maybe<Scalars['String']>;
  OverReceiptApprovalStatus?: Maybe<Scalars['Float']>;
  OverReceiptApprovalStatusOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  NBAPreAssignedObjectNo?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  PEBIsDescription2?: Maybe<Scalars['Boolean']>;
  PEBNotincludedincalc?: Maybe<Scalars['Boolean']>;
  PEBNotincludedinVATcalc?: Maybe<Scalars['Boolean']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMType?: Maybe<Scalars['Float']>;
  EQMTypeOPTION?: Maybe<Scalars['String']>;
  EQMNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMORIGPurchLineNo?: Maybe<Scalars['Float']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  EQMAllocateManufacturerCode?: Maybe<Scalars['String']>;
  EQMAllocateManuModelCode?: Maybe<Scalars['String']>;
  EQMItemtoObjectFlow?: Maybe<Scalars['Float']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  Finished?: Maybe<Scalars['Boolean']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  MPSOrder?: Maybe<Scalars['Boolean']>;
  PlanningFlexibility?: Maybe<Scalars['Float']>;
  PlanningFlexibilityOPTION?: Maybe<Scalars['String']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchaseLineConnection = {
  __typename?: 'PurchaseLineConnection';
  count: Scalars['Int'];
  edges: Array<PurchaseLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchaseLineConnectionEdge = {
  __typename?: 'PurchaseLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchaseLine;
};

export type PurchaseLineDeleteResponse = {
  __typename?: 'PurchaseLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<PurchaseLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum PurchaseLineDocumentTypeEnum {
  Quote = 'Quote',
  Order = 'Order',
  Invoice = 'Invoice',
  CreditMemo = 'CreditMemo',
  BlanketOrder = 'BlanketOrder',
  ReturnOrder = 'ReturnOrder'
}

export type PurchaseLineManyResult = {
  __typename?: 'PurchaseLineManyResult';
  records?: Maybe<Array<Maybe<PurchaseLine>>>;
  pageData?: Maybe<PageData>;
};

export type Purchasing = {
  __typename?: 'Purchasing';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchasingConnection = {
  __typename?: 'PurchasingConnection';
  count: Scalars['Int'];
  edges: Array<PurchasingConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchasingConnectionEdge = {
  __typename?: 'PurchasingConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Purchasing;
};

export type PurchasingDeleteResponse = {
  __typename?: 'PurchasingDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Purchasing>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchasingManyResult = {
  __typename?: 'PurchasingManyResult';
  records?: Maybe<Array<Maybe<Purchasing>>>;
  pageData?: Maybe<PageData>;
};

export type PushNotificationInput = {
  ToUserEmail: Scalars['String'];
  App: AppPushTokenInput;
  Title?: Maybe<Scalars['String']>;
  MessageBody?: Maybe<Scalars['String']>;
  IOSMessageSubtitle?: Maybe<Scalars['String']>;
  IOSBadgeCount?: Maybe<Scalars['Int']>;
  IOSPlaySound?: Maybe<Scalars['Boolean']>;
  AndroidChannelId?: Maybe<Scalars['String']>;
  CategoryId?: Maybe<Scalars['String']>;
};

export type PushNotificationResponse = {
  __typename?: 'PushNotificationResponse';
  status?: Maybe<PushNotificationStatus>;
  id?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
};

export enum PushNotificationStatus {
  Error = 'error',
  Ok = 'ok',
  NoRecipients = 'noRecipients'
}

export type Query = {
  __typename?: 'Query';
  info?: Maybe<Scalars['String']>;
  accessRequest?: Maybe<AccessRequest>;
  accessRequestsCount?: Maybe<Scalars['Int']>;
  accessRequestsMany?: Maybe<Array<Maybe<AccessRequest>>>;
  accessRequestsPage?: Maybe<AccessRequestManyResult>;
  accessRequestsConnection?: Maybe<AccessRequestConnection>;
  additionalItem?: Maybe<AdditionalItem>;
  additionalItemsCount?: Maybe<Scalars['Int']>;
  additionalItemsMany?: Maybe<Array<Maybe<AdditionalItem>>>;
  additionalItemsPage?: Maybe<AdditionalItemManyResult>;
  additionalItemsConnection?: Maybe<AdditionalItemConnection>;
  address?: Maybe<Address>;
  addressesCount?: Maybe<Scalars['Int']>;
  addressesMany?: Maybe<Array<Maybe<Address>>>;
  addressesPage?: Maybe<AddressManyResult>;
  addressesConnection?: Maybe<AddressConnection>;
  agreement?: Maybe<Agreement>;
  agreementsCount?: Maybe<Scalars['Int']>;
  agreementsMany?: Maybe<Array<Maybe<Agreement>>>;
  agreementsPage?: Maybe<AgreementManyResult>;
  agreementsConnection?: Maybe<AgreementConnection>;
  annotation?: Maybe<Annotation>;
  annotationsCount?: Maybe<Scalars['Int']>;
  annotationsMany?: Maybe<Array<Maybe<Annotation>>>;
  annotationsPage?: Maybe<AnnotationManyResult>;
  annotationsConnection?: Maybe<AnnotationConnection>;
  applicationModule?: Maybe<ApplicationModule>;
  applicationModulesCount?: Maybe<Scalars['Int']>;
  applicationModulesMany?: Maybe<Array<Maybe<ApplicationModule>>>;
  applicationModulesPage?: Maybe<ApplicationModuleManyResult>;
  applicationModulesConnection?: Maybe<ApplicationModuleConnection>;
  bcMappingTable?: Maybe<BcMappingTable>;
  bcMappingTablesCount?: Maybe<Scalars['Int']>;
  bcMappingTablesMany?: Maybe<Array<Maybe<BcMappingTable>>>;
  bcMappingTablesPage?: Maybe<BcMappingTableManyResult>;
  bcMappingTablesConnection?: Maybe<BcMappingTableConnection>;
  bcMappingField?: Maybe<BcMappingField>;
  bcMappingFieldsCount?: Maybe<Scalars['Int']>;
  bcMappingFieldsMany?: Maybe<Array<Maybe<BcMappingField>>>;
  bcMappingFieldsPage?: Maybe<BcMappingFieldManyResult>;
  bcMappingFieldsConnection?: Maybe<BcMappingFieldConnection>;
  bcSettings?: Maybe<BcSettings>;
  bcSettingsCount?: Maybe<Scalars['Int']>;
  bcSettingsMany?: Maybe<Array<Maybe<BcSettings>>>;
  bcSettingsPage?: Maybe<BcSettingsManyResult>;
  bcSettingsConnection?: Maybe<BcSettingsConnection>;
  bulkItemGroup?: Maybe<BulkItemGroup>;
  bulkItemGroupsCount?: Maybe<Scalars['Int']>;
  bulkItemGroupsMany?: Maybe<Array<Maybe<BulkItemGroup>>>;
  bulkItemGroupsPage?: Maybe<BulkItemGroupManyResult>;
  bulkItemGroupsConnection?: Maybe<BulkItemGroupConnection>;
  causeOfAbsence?: Maybe<CauseOfAbsence>;
  causeOfAbsencesCount?: Maybe<Scalars['Int']>;
  causeOfAbsencesMany?: Maybe<Array<Maybe<CauseOfAbsence>>>;
  causeOfAbsencesPage?: Maybe<CauseOfAbsenceManyResult>;
  causeOfAbsencesConnection?: Maybe<CauseOfAbsenceConnection>;
  company?: Maybe<Company>;
  companiesCount?: Maybe<Scalars['Int']>;
  companiesMany?: Maybe<Array<Maybe<Company>>>;
  companiesPage?: Maybe<CompanyManyResult>;
  companiesConnection?: Maybe<CompanyConnection>;
  consignmentHeader?: Maybe<ConsignmentHeader>;
  consignmentHeadersCount?: Maybe<Scalars['Int']>;
  consignmentHeadersMany?: Maybe<Array<Maybe<ConsignmentHeader>>>;
  consignmentHeadersPage?: Maybe<ConsignmentHeaderManyResult>;
  consignmentHeadersConnection?: Maybe<ConsignmentHeaderConnection>;
  consignmentLine?: Maybe<ConsignmentLine>;
  consignmentLinesCount?: Maybe<Scalars['Int']>;
  consignmentLinesMany?: Maybe<Array<Maybe<ConsignmentLine>>>;
  consignmentLinesPage?: Maybe<ConsignmentLineManyResult>;
  consignmentLinesConnection?: Maybe<ConsignmentLineConnection>;
  contact?: Maybe<Contact>;
  contactsCount?: Maybe<Scalars['Int']>;
  contactsMany?: Maybe<Array<Maybe<Contact>>>;
  contactsPage?: Maybe<ContactManyResult>;
  contactsConnection?: Maybe<ContactConnection>;
  countryRegion?: Maybe<CountryRegion>;
  countryRegionsCount?: Maybe<Scalars['Int']>;
  countryRegionsMany?: Maybe<Array<Maybe<CountryRegion>>>;
  countryRegionsPage?: Maybe<CountryRegionManyResult>;
  countryRegionsConnection?: Maybe<CountryRegionConnection>;
  currency?: Maybe<Currency>;
  currenciesCount?: Maybe<Scalars['Int']>;
  currenciesMany?: Maybe<Array<Maybe<Currency>>>;
  currenciesPage?: Maybe<CurrencyManyResult>;
  currenciesConnection?: Maybe<CurrencyConnection>;
  currencyExchangeRate?: Maybe<CurrencyExchangeRate>;
  currencyExchangeRatesCount?: Maybe<Scalars['Int']>;
  currencyExchangeRatesMany?: Maybe<Array<Maybe<CurrencyExchangeRate>>>;
  currencyExchangeRatesPage?: Maybe<CurrencyExchangeRateManyResult>;
  currencyExchangeRatesConnection?: Maybe<CurrencyExchangeRateConnection>;
  customerDomain?: Maybe<CustomerDomain>;
  customerDomainsCount?: Maybe<Scalars['Int']>;
  customerDomainsMany?: Maybe<Array<Maybe<CustomerDomain>>>;
  customerDomainsPage?: Maybe<CustomerDomainManyResult>;
  customerDomainsConnection?: Maybe<CustomerDomainConnection>;
  customerPostingGroup?: Maybe<CustomerPostingGroup>;
  customerPostingGroupsCount?: Maybe<Scalars['Int']>;
  customerPostingGroupsMany?: Maybe<Array<Maybe<CustomerPostingGroup>>>;
  customerPostingGroupsPage?: Maybe<CustomerPostingGroupManyResult>;
  customerPostingGroupsConnection?: Maybe<CustomerPostingGroupConnection>;
  customerProject?: Maybe<CustomerProject>;
  customerProjectsCount?: Maybe<Scalars['Int']>;
  customerProjectsMany?: Maybe<Array<Maybe<CustomerProject>>>;
  customerProjectsPage?: Maybe<CustomerProjectManyResult>;
  customerProjectsConnection?: Maybe<CustomerProjectConnection>;
  customerSubProject?: Maybe<CustomerSubProject>;
  customerSubProjectsCount?: Maybe<Scalars['Int']>;
  customerSubProjectsMany?: Maybe<Array<Maybe<CustomerSubProject>>>;
  customerSubProjectsPage?: Maybe<CustomerSubProjectManyResult>;
  customerSubProjectsConnection?: Maybe<CustomerSubProjectConnection>;
  deliverytime?: Maybe<Deliverytime>;
  deliverytimesCount?: Maybe<Scalars['Int']>;
  deliverytimesMany?: Maybe<Array<Maybe<Deliverytime>>>;
  deliverytimesPage?: Maybe<DeliverytimeManyResult>;
  deliverytimesConnection?: Maybe<DeliverytimeConnection>;
  devopsProject?: Maybe<DevopsProject>;
  devopsProjectsCount?: Maybe<Scalars['Int']>;
  devopsProjectsMany?: Maybe<Array<Maybe<DevopsProject>>>;
  devopsProjectsPage?: Maybe<DevopsProjectManyResult>;
  devopsProjectsConnection?: Maybe<DevopsProjectConnection>;
  devopsTeam?: Maybe<DevopsTeam>;
  devopsTeamsCount?: Maybe<Scalars['Int']>;
  devopsTeamsMany?: Maybe<Array<Maybe<DevopsTeam>>>;
  devopsTeamsPage?: Maybe<DevopsTeamManyResult>;
  devopsTeamsConnection?: Maybe<DevopsTeamConnection>;
  devopsWorkItem?: Maybe<DevopsWorkItem>;
  devopsWorkItemsCount?: Maybe<Scalars['Int']>;
  devopsWorkItemsMany?: Maybe<Array<Maybe<DevopsWorkItem>>>;
  devopsWorkItemsPage?: Maybe<DevopsWorkItemManyResult>;
  devopsWorkItemsConnection?: Maybe<DevopsWorkItemConnection>;
  dimension?: Maybe<Dimension>;
  dimensionsCount?: Maybe<Scalars['Int']>;
  dimensionsMany?: Maybe<Array<Maybe<Dimension>>>;
  dimensionsPage?: Maybe<DimensionManyResult>;
  dimensionsConnection?: Maybe<DimensionConnection>;
  dimensionValue?: Maybe<DimensionValue>;
  dimensionValuesCount?: Maybe<Scalars['Int']>;
  dimensionValuesMany?: Maybe<Array<Maybe<DimensionValue>>>;
  dimensionValuesPage?: Maybe<DimensionValueManyResult>;
  dimensionValuesConnection?: Maybe<DimensionValueConnection>;
  discussionPost?: Maybe<DiscussionPost>;
  discussionPostsCount?: Maybe<Scalars['Int']>;
  discussionPostsMany?: Maybe<Array<Maybe<DiscussionPost>>>;
  discussionPostsPage?: Maybe<DiscussionPostManyResult>;
  discussionPostsConnection?: Maybe<DiscussionPostConnection>;
  documentClassification?: Maybe<DocumentClassification>;
  documentClassificationsCount?: Maybe<Scalars['Int']>;
  documentClassificationsMany?: Maybe<Array<Maybe<DocumentClassification>>>;
  documentClassificationsPage?: Maybe<DocumentClassificationManyResult>;
  documentClassificationsConnection?: Maybe<DocumentClassificationConnection>;
  documentRequest?: Maybe<DocumentRequest>;
  documentRequestsCount?: Maybe<Scalars['Int']>;
  documentRequestsMany?: Maybe<Array<Maybe<DocumentRequest>>>;
  documentRequestsPage?: Maybe<DocumentRequestManyResult>;
  documentRequestsConnection?: Maybe<DocumentRequestConnection>;
  domainSettings?: Maybe<DomainSettings>;
  domainSettingsCount?: Maybe<Scalars['Int']>;
  domainSettingsMany?: Maybe<Array<Maybe<DomainSettings>>>;
  domainSettingsPage?: Maybe<DomainSettingsManyResult>;
  domainSettingsConnection?: Maybe<DomainSettingsConnection>;
  eqmBaseCalendar?: Maybe<EqmBaseCalendar>;
  eqmBaseCalendarsCount?: Maybe<Scalars['Int']>;
  eqmBaseCalendarsMany?: Maybe<Array<Maybe<EqmBaseCalendar>>>;
  eqmBaseCalendarsPage?: Maybe<EqmBaseCalendarManyResult>;
  eqmBaseCalendarsConnection?: Maybe<EqmBaseCalendarConnection>;
  eqmBaseCalendarChange?: Maybe<EqmBaseCalendarChange>;
  eqmBaseCalendarChangesCount?: Maybe<Scalars['Int']>;
  eqmBaseCalendarChangesMany?: Maybe<Array<Maybe<EqmBaseCalendarChange>>>;
  eqmBaseCalendarChangesPage?: Maybe<EqmBaseCalendarChangeManyResult>;
  eqmBaseCalendarChangesConnection?: Maybe<EqmBaseCalendarChangeConnection>;
  eqmCollectionReturnCharges?: Maybe<EqmCollectionReturnCharges>;
  eqmCollectionReturnChargesCount?: Maybe<Scalars['Int']>;
  eqmCollectionReturnChargesMany?: Maybe<Array<Maybe<EqmCollectionReturnCharges>>>;
  eqmCollectionReturnChargesPage?: Maybe<EqmCollectionReturnChargesManyResult>;
  eqmCollectionReturnChargesConnection?: Maybe<EqmCollectionReturnChargesConnection>;
  eqmCommentLine?: Maybe<EqmCommentLine>;
  eqmCommentLinesCount?: Maybe<Scalars['Int']>;
  eqmCommentLinesMany?: Maybe<Array<Maybe<EqmCommentLine>>>;
  eqmCommentLinesPage?: Maybe<EqmCommentLineManyResult>;
  eqmCommentLinesConnection?: Maybe<EqmCommentLineConnection>;
  eqmCustomizedCalendarChange?: Maybe<EqmCustomizedCalendarChange>;
  eqmCustomizedCalendarChangesCount?: Maybe<Scalars['Int']>;
  eqmCustomizedCalendarChangesMany?: Maybe<Array<Maybe<EqmCustomizedCalendarChange>>>;
  eqmCustomizedCalendarChangesPage?: Maybe<EqmCustomizedCalendarChangeManyResult>;
  eqmCustomizedCalendarChangesConnection?: Maybe<EqmCustomizedCalendarChangeConnection>;
  eqmFncTestReturnCharges?: Maybe<EqmFncTestReturnCharges>;
  eqmFncTestReturnChargesCount?: Maybe<Scalars['Int']>;
  eqmFncTestReturnChargesMany?: Maybe<Array<Maybe<EqmFncTestReturnCharges>>>;
  eqmFncTestReturnChargesPage?: Maybe<EqmFncTestReturnChargesManyResult>;
  eqmFncTestReturnChargesConnection?: Maybe<EqmFncTestReturnChargesConnection>;
  eqmLocCostCenterComb?: Maybe<EqmLocCostCenterComb>;
  eqmLocCostCenterCombsCount?: Maybe<Scalars['Int']>;
  eqmLocCostCenterCombsMany?: Maybe<Array<Maybe<EqmLocCostCenterComb>>>;
  eqmLocCostCenterCombsPage?: Maybe<EqmLocCostCenterCombManyResult>;
  eqmLocCostCenterCombsConnection?: Maybe<EqmLocCostCenterCombConnection>;
  eqmManufacturer?: Maybe<EqmManufacturer>;
  eqmManufacturersCount?: Maybe<Scalars['Int']>;
  eqmManufacturersMany?: Maybe<Array<Maybe<EqmManufacturer>>>;
  eqmManufacturersPage?: Maybe<EqmManufacturerManyResult>;
  eqmManufacturersConnection?: Maybe<EqmManufacturerConnection>;
  eqmManufacturerModel?: Maybe<EqmManufacturerModel>;
  eqmManufacturerModelsCount?: Maybe<Scalars['Int']>;
  eqmManufacturerModelsMany?: Maybe<Array<Maybe<EqmManufacturerModel>>>;
  eqmManufacturerModelsPage?: Maybe<EqmManufacturerModelManyResult>;
  eqmManufacturerModelsConnection?: Maybe<EqmManufacturerModelConnection>;
  eqmObjectCardFlow?: Maybe<EqmObjectCardFlow>;
  eqmObjectCardFlowsCount?: Maybe<Scalars['Int']>;
  eqmObjectCardFlowsMany?: Maybe<Array<Maybe<EqmObjectCardFlow>>>;
  eqmObjectCardFlowsPage?: Maybe<EqmObjectCardFlowManyResult>;
  eqmObjectCardFlowsConnection?: Maybe<EqmObjectCardFlowConnection>;
  eqmObjectComponents?: Maybe<EqmObjectComponents>;
  eqmObjectComponentsCount?: Maybe<Scalars['Int']>;
  eqmObjectComponentsMany?: Maybe<Array<Maybe<EqmObjectComponents>>>;
  eqmObjectComponentsPage?: Maybe<EqmObjectComponentsManyResult>;
  eqmObjectComponentsConnection?: Maybe<EqmObjectComponentsConnection>;
  eqmObjectCountHeader?: Maybe<EqmObjectCountHeader>;
  eqmObjectCountHeadersCount?: Maybe<Scalars['Int']>;
  eqmObjectCountHeadersMany?: Maybe<Array<Maybe<EqmObjectCountHeader>>>;
  eqmObjectCountHeadersPage?: Maybe<EqmObjectCountHeaderManyResult>;
  eqmObjectCountHeadersConnection?: Maybe<EqmObjectCountHeaderConnection>;
  eqmObjectCountLine?: Maybe<EqmObjectCountLine>;
  eqmObjectCountLinesCount?: Maybe<Scalars['Int']>;
  eqmObjectCountLinesMany?: Maybe<Array<Maybe<EqmObjectCountLine>>>;
  eqmObjectCountLinesPage?: Maybe<EqmObjectCountLineManyResult>;
  eqmObjectCountLinesConnection?: Maybe<EqmObjectCountLineConnection>;
  eqmObjectGroupCard?: Maybe<EqmObjectGroupCard>;
  eqmObjectGroupCardsCount?: Maybe<Scalars['Int']>;
  eqmObjectGroupCardsMany?: Maybe<Array<Maybe<EqmObjectGroupCard>>>;
  eqmObjectGroupCardsPage?: Maybe<EqmObjectGroupCardManyResult>;
  eqmObjectGroupCardsConnection?: Maybe<EqmObjectGroupCardConnection>;
  eqmObjectGroupFlow?: Maybe<EqmObjectGroupFlow>;
  eqmObjectGroupFlowsCount?: Maybe<Scalars['Int']>;
  eqmObjectGroupFlowsMany?: Maybe<Array<Maybe<EqmObjectGroupFlow>>>;
  eqmObjectGroupFlowsPage?: Maybe<EqmObjectGroupFlowManyResult>;
  eqmObjectGroupFlowsConnection?: Maybe<EqmObjectGroupFlowConnection>;
  eqmObjectServiceInterval?: Maybe<EqmObjectServiceInterval>;
  eqmObjectServiceIntervalsCount?: Maybe<Scalars['Int']>;
  eqmObjectServiceIntervalsMany?: Maybe<Array<Maybe<EqmObjectServiceInterval>>>;
  eqmObjectServiceIntervalsPage?: Maybe<EqmObjectServiceIntervalManyResult>;
  eqmObjectServiceIntervalsConnection?: Maybe<EqmObjectServiceIntervalConnection>;
  eqmObjectSrvIntervalAgg?: Maybe<EqmObjectSrvIntervalAgg>;
  eqmObjectSrvIntervalAggsCount?: Maybe<Scalars['Int']>;
  eqmObjectSrvIntervalAggsMany?: Maybe<Array<Maybe<EqmObjectSrvIntervalAgg>>>;
  eqmObjectSrvIntervalAggsPage?: Maybe<EqmObjectSrvIntervalAggManyResult>;
  eqmObjectSrvIntervalAggsConnection?: Maybe<EqmObjectSrvIntervalAggConnection>;
  eqmObjectStatus?: Maybe<EqmObjectStatus>;
  eqmObjectStatusesCount?: Maybe<Scalars['Int']>;
  eqmObjectStatusesMany?: Maybe<Array<Maybe<EqmObjectStatus>>>;
  eqmObjectStatusesPage?: Maybe<EqmObjectStatusManyResult>;
  eqmObjectStatusesConnection?: Maybe<EqmObjectStatusConnection>;
  eqmObjectTypeCard?: Maybe<EqmObjectTypeCard>;
  eqmObjectTypeCardsCount?: Maybe<Scalars['Int']>;
  eqmObjectTypeCardsMany?: Maybe<Array<Maybe<EqmObjectTypeCard>>>;
  eqmObjectTypeCardsPage?: Maybe<EqmObjectTypeCardManyResult>;
  eqmObjectTypeCardsConnection?: Maybe<EqmObjectTypeCardConnection>;
  eqmObjectTypeComponents?: Maybe<EqmObjectTypeComponents>;
  eqmObjectTypeComponentsCount?: Maybe<Scalars['Int']>;
  eqmObjectTypeComponentsMany?: Maybe<Array<Maybe<EqmObjectTypeComponents>>>;
  eqmObjectTypeComponentsPage?: Maybe<EqmObjectTypeComponentsManyResult>;
  eqmObjectTypeComponentsConnection?: Maybe<EqmObjectTypeComponentsConnection>;
  eqmObjectTypeFlow?: Maybe<EqmObjectTypeFlow>;
  eqmObjectTypeFlowsCount?: Maybe<Scalars['Int']>;
  eqmObjectTypeFlowsMany?: Maybe<Array<Maybe<EqmObjectTypeFlow>>>;
  eqmObjectTypeFlowsPage?: Maybe<EqmObjectTypeFlowManyResult>;
  eqmObjectTypeFlowsConnection?: Maybe<EqmObjectTypeFlowConnection>;
  eqmObjectTypePriceTerm?: Maybe<EqmObjectTypePriceTerm>;
  eqmObjectTypePriceTermsCount?: Maybe<Scalars['Int']>;
  eqmObjectTypePriceTermsMany?: Maybe<Array<Maybe<EqmObjectTypePriceTerm>>>;
  eqmObjectTypePriceTermsPage?: Maybe<EqmObjectTypePriceTermManyResult>;
  eqmObjectTypePriceTermsConnection?: Maybe<EqmObjectTypePriceTermConnection>;
  eqmObjectTypeServiceInterval?: Maybe<EqmObjectTypeServiceInterval>;
  eqmObjectTypeServiceIntervalsCount?: Maybe<Scalars['Int']>;
  eqmObjectTypeServiceIntervalsMany?: Maybe<Array<Maybe<EqmObjectTypeServiceInterval>>>;
  eqmObjectTypeServiceIntervalsPage?: Maybe<EqmObjectTypeServiceIntervalManyResult>;
  eqmObjectTypeServiceIntervalsConnection?: Maybe<EqmObjectTypeServiceIntervalConnection>;
  eqmRentalGroup?: Maybe<EqmRentalGroup>;
  eqmRentalGroupsCount?: Maybe<Scalars['Int']>;
  eqmRentalGroupsMany?: Maybe<Array<Maybe<EqmRentalGroup>>>;
  eqmRentalGroupsPage?: Maybe<EqmRentalGroupManyResult>;
  eqmRentalGroupsConnection?: Maybe<EqmRentalGroupConnection>;
  eqmRentalKIT?: Maybe<EqmRentalKit>;
  eqmRentalKITSCount?: Maybe<Scalars['Int']>;
  eqmRentalKITSMany?: Maybe<Array<Maybe<EqmRentalKit>>>;
  eqmRentalKITSPage?: Maybe<EqmRentalKitManyResult>;
  eqmRentalKITSConnection?: Maybe<EqmRentalKitConnection>;
  eqmRentalKITComponents?: Maybe<EqmRentalKitComponents>;
  eqmRentalKITComponentsCount?: Maybe<Scalars['Int']>;
  eqmRentalKITComponentsMany?: Maybe<Array<Maybe<EqmRentalKitComponents>>>;
  eqmRentalKITComponentsPage?: Maybe<EqmRentalKitComponentsManyResult>;
  eqmRentalKITComponentsConnection?: Maybe<EqmRentalKitComponentsConnection>;
  eqmRentalKITLines?: Maybe<EqmRentalKitLines>;
  eqmRentalKITLinesCount?: Maybe<Scalars['Int']>;
  eqmRentalKITLinesMany?: Maybe<Array<Maybe<EqmRentalKitLines>>>;
  eqmRentalKITLinesPage?: Maybe<EqmRentalKitLinesManyResult>;
  eqmRentalKITLinesConnection?: Maybe<EqmRentalKitLinesConnection>;
  eqmRentalLineDiscount?: Maybe<EqmRentalLineDiscount>;
  eqmRentalLineDiscountsCount?: Maybe<Scalars['Int']>;
  eqmRentalLineDiscountsMany?: Maybe<Array<Maybe<EqmRentalLineDiscount>>>;
  eqmRentalLineDiscountsPage?: Maybe<EqmRentalLineDiscountManyResult>;
  eqmRentalLineDiscountsConnection?: Maybe<EqmRentalLineDiscountConnection>;
  eqmRentalPrice?: Maybe<EqmRentalPrice>;
  eqmRentalPricesCount?: Maybe<Scalars['Int']>;
  eqmRentalPricesMany?: Maybe<Array<Maybe<EqmRentalPrice>>>;
  eqmRentalPricesPage?: Maybe<EqmRentalPriceManyResult>;
  eqmRentalPricesConnection?: Maybe<EqmRentalPriceConnection>;
  eqmReRentCard?: Maybe<EqmReRentCard>;
  eqmReRentCardsCount?: Maybe<Scalars['Int']>;
  eqmReRentCardsMany?: Maybe<Array<Maybe<EqmReRentCard>>>;
  eqmReRentCardsPage?: Maybe<EqmReRentCardManyResult>;
  eqmReRentCardsConnection?: Maybe<EqmReRentCardConnection>;
  eqmReturnCharges?: Maybe<EqmReturnCharges>;
  eqmReturnChargesCount?: Maybe<Scalars['Int']>;
  eqmReturnChargesMany?: Maybe<Array<Maybe<EqmReturnCharges>>>;
  eqmReturnChargesPage?: Maybe<EqmReturnChargesManyResult>;
  eqmReturnChargesConnection?: Maybe<EqmReturnChargesConnection>;
  eqmServiceCard?: Maybe<EqmServiceCard>;
  eqmServiceCardsCount?: Maybe<Scalars['Int']>;
  eqmServiceCardsMany?: Maybe<Array<Maybe<EqmServiceCard>>>;
  eqmServiceCardsPage?: Maybe<EqmServiceCardManyResult>;
  eqmServiceCardsConnection?: Maybe<EqmServiceCardConnection>;
  eqmServiceType?: Maybe<EqmServiceType>;
  eqmServiceTypesCount?: Maybe<Scalars['Int']>;
  eqmServiceTypesMany?: Maybe<Array<Maybe<EqmServiceType>>>;
  eqmServiceTypesPage?: Maybe<EqmServiceTypeManyResult>;
  eqmServiceTypesConnection?: Maybe<EqmServiceTypeConnection>;
  eqmTypeFunctionalTest?: Maybe<EqmTypeFunctionalTest>;
  eqmTypeFunctionalTestsCount?: Maybe<Scalars['Int']>;
  eqmTypeFunctionalTestsMany?: Maybe<Array<Maybe<EqmTypeFunctionalTest>>>;
  eqmTypeFunctionalTestsPage?: Maybe<EqmTypeFunctionalTestManyResult>;
  eqmTypeFunctionalTestsConnection?: Maybe<EqmTypeFunctionalTestConnection>;
  eqmWorkHeader?: Maybe<EqmWorkHeader>;
  eqmWorkHeadersCount?: Maybe<Scalars['Int']>;
  eqmWorkHeadersMany?: Maybe<Array<Maybe<EqmWorkHeader>>>;
  eqmWorkHeadersPage?: Maybe<EqmWorkHeaderManyResult>;
  eqmWorkHeadersConnection?: Maybe<EqmWorkHeaderConnection>;
  eqmWorkLine?: Maybe<EqmWorkLine>;
  eqmWorkLinesCount?: Maybe<Scalars['Int']>;
  eqmWorkLinesMany?: Maybe<Array<Maybe<EqmWorkLine>>>;
  eqmWorkLinesPage?: Maybe<EqmWorkLineManyResult>;
  eqmWorkLinesConnection?: Maybe<EqmWorkLineConnection>;
  eqmTransferHeader?: Maybe<EqmTransferHeader>;
  eqmTransferHeadersCount?: Maybe<Scalars['Int']>;
  eqmTransferHeadersMany?: Maybe<Array<Maybe<EqmTransferHeader>>>;
  eqmTransferHeadersPage?: Maybe<EqmTransferHeaderManyResult>;
  eqmTransferHeadersConnection?: Maybe<EqmTransferHeaderConnection>;
  eqmTransferLine?: Maybe<EqmTransferLine>;
  eqmTransferLinesCount?: Maybe<Scalars['Int']>;
  eqmTransferLinesMany?: Maybe<Array<Maybe<EqmTransferLine>>>;
  eqmTransferLinesPage?: Maybe<EqmTransferLineManyResult>;
  eqmTransferLinesConnection?: Maybe<EqmTransferLineConnection>;
  errand?: Maybe<Errand>;
  errandsCount?: Maybe<Scalars['Int']>;
  errandsMany?: Maybe<Array<Maybe<Errand>>>;
  errandsPage?: Maybe<ErrandManyResult>;
  errandsConnection?: Maybe<ErrandConnection>;
  errandLine?: Maybe<ErrandLine>;
  errandLinesCount?: Maybe<Scalars['Int']>;
  errandLinesMany?: Maybe<Array<Maybe<ErrandLine>>>;
  errandLinesPage?: Maybe<ErrandLineManyResult>;
  errandLinesConnection?: Maybe<ErrandLineConnection>;
  errorReport?: Maybe<ErrorReport>;
  errorReportsCount?: Maybe<Scalars['Int']>;
  errorReportsMany?: Maybe<Array<Maybe<ErrorReport>>>;
  errorReportsPage?: Maybe<ErrorReportManyResult>;
  errorReportsConnection?: Maybe<ErrorReportConnection>;
  feedback?: Maybe<Feedback>;
  feedbacksCount?: Maybe<Scalars['Int']>;
  feedbacksMany?: Maybe<Array<Maybe<Feedback>>>;
  feedbacksPage?: Maybe<FeedbackManyResult>;
  feedbacksConnection?: Maybe<FeedbackConnection>;
  feemapping?: Maybe<Feemapping>;
  feemappingsCount?: Maybe<Scalars['Int']>;
  feemappingsMany?: Maybe<Array<Maybe<Feemapping>>>;
  feemappingsPage?: Maybe<FeemappingManyResult>;
  feemappingsConnection?: Maybe<FeemappingConnection>;
  fieldOrder?: Maybe<FieldOrder>;
  fieldOrdersCount?: Maybe<Scalars['Int']>;
  fieldOrdersMany?: Maybe<Array<Maybe<FieldOrder>>>;
  fieldOrdersPage?: Maybe<FieldOrderManyResult>;
  fieldOrdersConnection?: Maybe<FieldOrderConnection>;
  fieldOrderLine?: Maybe<FieldOrderLine>;
  fieldOrderLinesCount?: Maybe<Scalars['Int']>;
  fieldOrderLinesMany?: Maybe<Array<Maybe<FieldOrderLine>>>;
  fieldOrderLinesPage?: Maybe<FieldOrderLineManyResult>;
  fieldOrderLinesConnection?: Maybe<FieldOrderLineConnection>;
  file?: Maybe<File>;
  filesCount?: Maybe<Scalars['Int']>;
  filesMany?: Maybe<Array<Maybe<File>>>;
  filesPage?: Maybe<FileManyResult>;
  filesConnection?: Maybe<FileConnection>;
  functionTestHeader?: Maybe<FunctionTestHeader>;
  functionTestHeadersCount?: Maybe<Scalars['Int']>;
  functionTestHeadersMany?: Maybe<Array<Maybe<FunctionTestHeader>>>;
  functionTestHeadersPage?: Maybe<FunctionTestHeaderManyResult>;
  functionTestHeadersConnection?: Maybe<FunctionTestHeaderConnection>;
  functionTestLine?: Maybe<FunctionTestLine>;
  functionTestLinesCount?: Maybe<Scalars['Int']>;
  functionTestLinesMany?: Maybe<Array<Maybe<FunctionTestLine>>>;
  functionTestLinesPage?: Maybe<FunctionTestLineManyResult>;
  functionTestLinesConnection?: Maybe<FunctionTestLineConnection>;
  genBusinessPostingGroup?: Maybe<GenBusinessPostingGroup>;
  genBusinessPostingGroupsCount?: Maybe<Scalars['Int']>;
  genBusinessPostingGroupsMany?: Maybe<Array<Maybe<GenBusinessPostingGroup>>>;
  genBusinessPostingGroupsPage?: Maybe<GenBusinessPostingGroupManyResult>;
  genBusinessPostingGroupsConnection?: Maybe<GenBusinessPostingGroupConnection>;
  genJournalLine?: Maybe<GenJournalLine>;
  genJournalLinesCount?: Maybe<Scalars['Int']>;
  genJournalLinesMany?: Maybe<Array<Maybe<GenJournalLine>>>;
  genJournalLinesPage?: Maybe<GenJournalLineManyResult>;
  genJournalLinesConnection?: Maybe<GenJournalLineConnection>;
  genProductPostingGroup?: Maybe<GenProductPostingGroup>;
  genProductPostingGroupsCount?: Maybe<Scalars['Int']>;
  genProductPostingGroupsMany?: Maybe<Array<Maybe<GenProductPostingGroup>>>;
  genProductPostingGroupsPage?: Maybe<GenProductPostingGroupManyResult>;
  genProductPostingGroupsConnection?: Maybe<GenProductPostingGroupConnection>;
  inspection?: Maybe<Inspection>;
  inspectionsCount?: Maybe<Scalars['Int']>;
  inspectionsMany?: Maybe<Array<Maybe<Inspection>>>;
  inspectionsPage?: Maybe<InspectionManyResult>;
  inspectionsConnection?: Maybe<InspectionConnection>;
  inspectionUser?: Maybe<InspectionUser>;
  inspectionUsersCount?: Maybe<Scalars['Int']>;
  inspectionUsersMany?: Maybe<Array<Maybe<InspectionUser>>>;
  inspectionUsersPage?: Maybe<InspectionUserManyResult>;
  inspectionUsersConnection?: Maybe<InspectionUserConnection>;
  integrationCollection?: Maybe<IntegrationCollection>;
  integrationCollectionsCount?: Maybe<Scalars['Int']>;
  integrationCollectionsMany?: Maybe<Array<Maybe<IntegrationCollection>>>;
  integrationCollectionsPage?: Maybe<IntegrationCollectionManyResult>;
  integrationCollectionsConnection?: Maybe<IntegrationCollectionConnection>;
  inventoryPostingGroup?: Maybe<InventoryPostingGroup>;
  inventoryPostingGroupsCount?: Maybe<Scalars['Int']>;
  inventoryPostingGroupsMany?: Maybe<Array<Maybe<InventoryPostingGroup>>>;
  inventoryPostingGroupsPage?: Maybe<InventoryPostingGroupManyResult>;
  inventoryPostingGroupsConnection?: Maybe<InventoryPostingGroupConnection>;
  invoiceHeader?: Maybe<InvoiceHeader>;
  invoiceHeadersCount?: Maybe<Scalars['Int']>;
  invoiceHeadersMany?: Maybe<Array<Maybe<InvoiceHeader>>>;
  invoiceHeadersPage?: Maybe<InvoiceHeaderManyResult>;
  invoiceHeadersConnection?: Maybe<InvoiceHeaderConnection>;
  invoiceLine?: Maybe<InvoiceLine>;
  invoiceLinesCount?: Maybe<Scalars['Int']>;
  invoiceLinesMany?: Maybe<Array<Maybe<InvoiceLine>>>;
  invoiceLinesPage?: Maybe<InvoiceLineManyResult>;
  invoiceLinesConnection?: Maybe<InvoiceLineConnection>;
  item?: Maybe<Item>;
  itemsCount?: Maybe<Scalars['Int']>;
  itemsMany?: Maybe<Array<Maybe<Item>>>;
  itemsPage?: Maybe<ItemManyResult>;
  itemsConnection?: Maybe<ItemConnection>;
  itemCategory?: Maybe<ItemCategory>;
  itemCategoriesCount?: Maybe<Scalars['Int']>;
  itemCategoriesMany?: Maybe<Array<Maybe<ItemCategory>>>;
  itemCategoriesPage?: Maybe<ItemCategoryManyResult>;
  itemCategoriesConnection?: Maybe<ItemCategoryConnection>;
  itemDiscGroup?: Maybe<ItemDiscGroup>;
  itemDiscGroupsCount?: Maybe<Scalars['Int']>;
  itemDiscGroupsMany?: Maybe<Array<Maybe<ItemDiscGroup>>>;
  itemDiscGroupsPage?: Maybe<ItemDiscGroupManyResult>;
  itemDiscGroupsConnection?: Maybe<ItemDiscGroupConnection>;
  itemReference?: Maybe<ItemReference>;
  itemReferencesCount?: Maybe<Scalars['Int']>;
  itemReferencesMany?: Maybe<Array<Maybe<ItemReference>>>;
  itemReferencesPage?: Maybe<ItemReferenceManyResult>;
  itemReferencesConnection?: Maybe<ItemReferenceConnection>;
  itemRestriction?: Maybe<ItemRestriction>;
  itemRestrictionsCount?: Maybe<Scalars['Int']>;
  itemRestrictionsMany?: Maybe<Array<Maybe<ItemRestriction>>>;
  itemRestrictionsPage?: Maybe<ItemRestrictionManyResult>;
  itemRestrictionsConnection?: Maybe<ItemRestrictionConnection>;
  itemUnitOfMeasure?: Maybe<ItemUnitOfMeasure>;
  itemUnitOfMeasuresCount?: Maybe<Scalars['Int']>;
  itemUnitOfMeasuresMany?: Maybe<Array<Maybe<ItemUnitOfMeasure>>>;
  itemUnitOfMeasuresPage?: Maybe<ItemUnitOfMeasureManyResult>;
  itemUnitOfMeasuresConnection?: Maybe<ItemUnitOfMeasureConnection>;
  itemVendor?: Maybe<ItemVendor>;
  itemVendorsCount?: Maybe<Scalars['Int']>;
  itemVendorsMany?: Maybe<Array<Maybe<ItemVendor>>>;
  itemVendorsPage?: Maybe<ItemVendorManyResult>;
  itemVendorsConnection?: Maybe<ItemVendorConnection>;
  jobTask?: Maybe<JobTask>;
  jobTasksCount?: Maybe<Scalars['Int']>;
  jobTasksMany?: Maybe<Array<Maybe<JobTask>>>;
  jobTasksPage?: Maybe<JobTaskManyResult>;
  jobTasksConnection?: Maybe<JobTaskConnection>;
  kliHeader?: Maybe<KliHeader>;
  kliHeadersCount?: Maybe<Scalars['Int']>;
  kliHeadersMany?: Maybe<Array<Maybe<KliHeader>>>;
  kliHeadersPage?: Maybe<KliHeaderManyResult>;
  kliHeadersConnection?: Maybe<KliHeaderConnection>;
  kliReasonCode?: Maybe<KliReasonCode>;
  kliReasonCodesCount?: Maybe<Scalars['Int']>;
  kliReasonCodesMany?: Maybe<Array<Maybe<KliReasonCode>>>;
  kliReasonCodesPage?: Maybe<KliReasonCodeManyResult>;
  kliReasonCodesConnection?: Maybe<KliReasonCodeConnection>;
  location?: Maybe<Location>;
  locationsCount?: Maybe<Scalars['Int']>;
  locationsMany?: Maybe<Array<Maybe<Location>>>;
  locationsPage?: Maybe<LocationManyResult>;
  locationsConnection?: Maybe<LocationConnection>;
  lead?: Maybe<Lead>;
  leadsCount?: Maybe<Scalars['Int']>;
  leadsMany?: Maybe<Array<Maybe<Lead>>>;
  leadsPage?: Maybe<LeadManyResult>;
  leadsConnection?: Maybe<LeadConnection>;
  magentoOrderStatus?: Maybe<MagentoOrderStatus>;
  magentoOrderStatusesCount?: Maybe<Scalars['Int']>;
  magentoOrderStatusesMany?: Maybe<Array<Maybe<MagentoOrderStatus>>>;
  magentoOrderStatusesPage?: Maybe<MagentoOrderStatusManyResult>;
  magentoOrderStatusesConnection?: Maybe<MagentoOrderStatusConnection>;
  magentoSettings?: Maybe<MagentoSettings>;
  magentoSettingsCount?: Maybe<Scalars['Int']>;
  magentoSettingsMany?: Maybe<Array<Maybe<MagentoSettings>>>;
  magentoSettingsPage?: Maybe<MagentoSettingsManyResult>;
  magentoSettingsConnection?: Maybe<MagentoSettingsConnection>;
  manufacturer?: Maybe<Manufacturer>;
  manufacturersCount?: Maybe<Scalars['Int']>;
  manufacturersMany?: Maybe<Array<Maybe<Manufacturer>>>;
  manufacturersPage?: Maybe<ManufacturerManyResult>;
  manufacturersConnection?: Maybe<ManufacturerConnection>;
  navUser?: Maybe<NavUser>;
  navUsersCount?: Maybe<Scalars['Int']>;
  navUsersMany?: Maybe<Array<Maybe<NavUser>>>;
  navUsersPage?: Maybe<NavUserManyResult>;
  navUsersConnection?: Maybe<NavUserConnection>;
  object?: Maybe<Object>;
  objectsCount?: Maybe<Scalars['Int']>;
  objectsMany?: Maybe<Array<Maybe<Object>>>;
  objectsPage?: Maybe<ObjectManyResult>;
  objectsConnection?: Maybe<ObjectConnection>;
  objectCommentLine?: Maybe<ObjectCommentLine>;
  objectCommentLinesCount?: Maybe<Scalars['Int']>;
  objectCommentLinesMany?: Maybe<Array<Maybe<ObjectCommentLine>>>;
  objectCommentLinesPage?: Maybe<ObjectCommentLineManyResult>;
  objectCommentLinesConnection?: Maybe<ObjectCommentLineConnection>;
  objectFamily?: Maybe<ObjectFamily>;
  objectFamiliesCount?: Maybe<Scalars['Int']>;
  objectFamiliesMany?: Maybe<Array<Maybe<ObjectFamily>>>;
  objectFamiliesPage?: Maybe<ObjectFamilyManyResult>;
  objectFamiliesConnection?: Maybe<ObjectFamilyConnection>;
  objectGroup?: Maybe<ObjectGroup>;
  objectGroupsCount?: Maybe<Scalars['Int']>;
  objectGroupsMany?: Maybe<Array<Maybe<ObjectGroup>>>;
  objectGroupsPage?: Maybe<ObjectGroupManyResult>;
  objectGroupsConnection?: Maybe<ObjectGroupConnection>;
  objectReturnHeader?: Maybe<ObjectReturnHeader>;
  objectReturnHeadersCount?: Maybe<Scalars['Int']>;
  objectReturnHeadersMany?: Maybe<Array<Maybe<ObjectReturnHeader>>>;
  objectReturnHeadersPage?: Maybe<ObjectReturnHeaderManyResult>;
  objectReturnHeadersConnection?: Maybe<ObjectReturnHeaderConnection>;
  objectReturnLine?: Maybe<ObjectReturnLine>;
  objectReturnLinesCount?: Maybe<Scalars['Int']>;
  objectReturnLinesMany?: Maybe<Array<Maybe<ObjectReturnLine>>>;
  objectReturnLinesPage?: Maybe<ObjectReturnLineManyResult>;
  objectReturnLinesConnection?: Maybe<ObjectReturnLineConnection>;
  objectType?: Maybe<ObjectType>;
  objectTypesCount?: Maybe<Scalars['Int']>;
  objectTypesMany?: Maybe<Array<Maybe<ObjectType>>>;
  objectTypesPage?: Maybe<ObjectTypeManyResult>;
  objectTypesConnection?: Maybe<ObjectTypeConnection>;
  ocrAnalysis?: Maybe<OcrAnalysis>;
  ocrAnalysesCount?: Maybe<Scalars['Int']>;
  ocrAnalysesMany?: Maybe<Array<Maybe<OcrAnalysis>>>;
  ocrAnalysesPage?: Maybe<OcrAnalysisManyResult>;
  ocrAnalysesConnection?: Maybe<OcrAnalysisConnection>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodsCount?: Maybe<Scalars['Int']>;
  paymentMethodsMany?: Maybe<Array<Maybe<PaymentMethod>>>;
  paymentMethodsPage?: Maybe<PaymentMethodManyResult>;
  paymentMethodsConnection?: Maybe<PaymentMethodConnection>;
  paymentTerms?: Maybe<PaymentTerms>;
  paymentTermsCount?: Maybe<Scalars['Int']>;
  paymentTermsMany?: Maybe<Array<Maybe<PaymentTerms>>>;
  paymentTermsPage?: Maybe<PaymentTermsManyResult>;
  paymentTermsConnection?: Maybe<PaymentTermsConnection>;
  platformFeature?: Maybe<PlatformFeature>;
  platformFeaturesCount?: Maybe<Scalars['Int']>;
  platformFeaturesMany?: Maybe<Array<Maybe<PlatformFeature>>>;
  platformFeaturesPage?: Maybe<PlatformFeatureManyResult>;
  platformFeaturesConnection?: Maybe<PlatformFeatureConnection>;
  postCode?: Maybe<PostCode>;
  postCodesCount?: Maybe<Scalars['Int']>;
  postCodesMany?: Maybe<Array<Maybe<PostCode>>>;
  postCodesPage?: Maybe<PostCodeManyResult>;
  postCodesConnection?: Maybe<PostCodeConnection>;
  project?: Maybe<Project>;
  projectsCount?: Maybe<Scalars['Int']>;
  projectsMany?: Maybe<Array<Maybe<Project>>>;
  projectsPage?: Maybe<ProjectManyResult>;
  projectsConnection?: Maybe<ProjectConnection>;
  purchaseHeader?: Maybe<PurchaseHeader>;
  purchaseHeadersCount?: Maybe<Scalars['Int']>;
  purchaseHeadersMany?: Maybe<Array<Maybe<PurchaseHeader>>>;
  purchaseHeadersPage?: Maybe<PurchaseHeaderManyResult>;
  purchaseHeadersConnection?: Maybe<PurchaseHeaderConnection>;
  purchaseLine?: Maybe<PurchaseLine>;
  purchaseLinesCount?: Maybe<Scalars['Int']>;
  purchaseLinesMany?: Maybe<Array<Maybe<PurchaseLine>>>;
  purchaseLinesPage?: Maybe<PurchaseLineManyResult>;
  purchaseLinesConnection?: Maybe<PurchaseLineConnection>;
  purchasing?: Maybe<Purchasing>;
  purchasingsCount?: Maybe<Scalars['Int']>;
  purchasingsMany?: Maybe<Array<Maybe<Purchasing>>>;
  purchasingsPage?: Maybe<PurchasingManyResult>;
  purchasingsConnection?: Maybe<PurchasingConnection>;
  purchCrMemoHeader?: Maybe<PurchCrMemoHeader>;
  purchCrMemoHeadersCount?: Maybe<Scalars['Int']>;
  purchCrMemoHeadersMany?: Maybe<Array<Maybe<PurchCrMemoHeader>>>;
  purchCrMemoHeadersPage?: Maybe<PurchCrMemoHeaderManyResult>;
  purchCrMemoHeadersConnection?: Maybe<PurchCrMemoHeaderConnection>;
  purchCrMemoLine?: Maybe<PurchCrMemoLine>;
  purchCrMemoLinesCount?: Maybe<Scalars['Int']>;
  purchCrMemoLinesMany?: Maybe<Array<Maybe<PurchCrMemoLine>>>;
  purchCrMemoLinesPage?: Maybe<PurchCrMemoLineManyResult>;
  purchCrMemoLinesConnection?: Maybe<PurchCrMemoLineConnection>;
  purchInvHeader?: Maybe<PurchInvHeader>;
  purchInvHeadersCount?: Maybe<Scalars['Int']>;
  purchInvHeadersMany?: Maybe<Array<Maybe<PurchInvHeader>>>;
  purchInvHeadersPage?: Maybe<PurchInvHeaderManyResult>;
  purchInvHeadersConnection?: Maybe<PurchInvHeaderConnection>;
  purchInvLine?: Maybe<PurchInvLine>;
  purchInvLinesCount?: Maybe<Scalars['Int']>;
  purchInvLinesMany?: Maybe<Array<Maybe<PurchInvLine>>>;
  purchInvLinesPage?: Maybe<PurchInvLineManyResult>;
  purchInvLinesConnection?: Maybe<PurchInvLineConnection>;
  purchRcptHeader?: Maybe<PurchRcptHeader>;
  purchRcptHeadersCount?: Maybe<Scalars['Int']>;
  purchRcptHeadersMany?: Maybe<Array<Maybe<PurchRcptHeader>>>;
  purchRcptHeadersPage?: Maybe<PurchRcptHeaderManyResult>;
  purchRcptHeadersConnection?: Maybe<PurchRcptHeaderConnection>;
  purchRcptLine?: Maybe<PurchRcptLine>;
  purchRcptLinesCount?: Maybe<Scalars['Int']>;
  purchRcptLinesMany?: Maybe<Array<Maybe<PurchRcptLine>>>;
  purchRcptLinesPage?: Maybe<PurchRcptLineManyResult>;
  purchRcptLinesConnection?: Maybe<PurchRcptLineConnection>;
  quoteHeader?: Maybe<QuoteHeader>;
  quoteHeadersCount?: Maybe<Scalars['Int']>;
  quoteHeadersMany?: Maybe<Array<Maybe<QuoteHeader>>>;
  quoteHeadersPage?: Maybe<QuoteHeaderManyResult>;
  quoteHeadersConnection?: Maybe<QuoteHeaderConnection>;
  quoteLine?: Maybe<QuoteLine>;
  quoteLinesCount?: Maybe<Scalars['Int']>;
  quoteLinesMany?: Maybe<Array<Maybe<QuoteLine>>>;
  quoteLinesPage?: Maybe<QuoteLineManyResult>;
  quoteLinesConnection?: Maybe<QuoteLineConnection>;
  quoteTaskGroup?: Maybe<QuoteTaskGroup>;
  quoteTaskGroupsCount?: Maybe<Scalars['Int']>;
  quoteTaskGroupsMany?: Maybe<Array<Maybe<QuoteTaskGroup>>>;
  quoteTaskGroupsPage?: Maybe<QuoteTaskGroupManyResult>;
  quoteTaskGroupsConnection?: Maybe<QuoteTaskGroupConnection>;
  rentalHeader?: Maybe<RentalHeader>;
  rentalHeadersCount?: Maybe<Scalars['Int']>;
  rentalHeadersMany?: Maybe<Array<Maybe<RentalHeader>>>;
  rentalHeadersPage?: Maybe<RentalHeaderManyResult>;
  rentalHeadersConnection?: Maybe<RentalHeaderConnection>;
  rentalLine?: Maybe<RentalLine>;
  rentalLinesCount?: Maybe<Scalars['Int']>;
  rentalLinesMany?: Maybe<Array<Maybe<RentalLine>>>;
  rentalLinesPage?: Maybe<RentalLineManyResult>;
  rentalLinesConnection?: Maybe<RentalLineConnection>;
  rentalLineSnapshot?: Maybe<RentalLineSnapshot>;
  rentalLineSnapshotsCount?: Maybe<Scalars['Int']>;
  rentalLineSnapshotsMany?: Maybe<Array<Maybe<RentalLineSnapshot>>>;
  rentalLineSnapshotsPage?: Maybe<RentalLineSnapshotManyResult>;
  rentalLineSnapshotsConnection?: Maybe<RentalLineSnapshotConnection>;
  rentalPostedCollHeader?: Maybe<RentalPostedCollHeader>;
  rentalPostedCollHeadersCount?: Maybe<Scalars['Int']>;
  rentalPostedCollHeadersMany?: Maybe<Array<Maybe<RentalPostedCollHeader>>>;
  rentalPostedCollHeadersPage?: Maybe<RentalPostedCollHeaderManyResult>;
  rentalPostedCollHeadersConnection?: Maybe<RentalPostedCollHeaderConnection>;
  rentalPostedCollLine?: Maybe<RentalPostedCollLine>;
  rentalPostedCollLinesCount?: Maybe<Scalars['Int']>;
  rentalPostedCollLinesMany?: Maybe<Array<Maybe<RentalPostedCollLine>>>;
  rentalPostedCollLinesPage?: Maybe<RentalPostedCollLineManyResult>;
  rentalPostedCollLinesConnection?: Maybe<RentalPostedCollLineConnection>;
  rentalPostedShptHeader?: Maybe<RentalPostedShptHeader>;
  rentalPostedShptHeadersCount?: Maybe<Scalars['Int']>;
  rentalPostedShptHeadersMany?: Maybe<Array<Maybe<RentalPostedShptHeader>>>;
  rentalPostedShptHeadersPage?: Maybe<RentalPostedShptHeaderManyResult>;
  rentalPostedShptHeadersConnection?: Maybe<RentalPostedShptHeaderConnection>;
  rentalPostedShptLine?: Maybe<RentalPostedShptLine>;
  rentalPostedShptLinesCount?: Maybe<Scalars['Int']>;
  rentalPostedShptLinesMany?: Maybe<Array<Maybe<RentalPostedShptLine>>>;
  rentalPostedShptLinesPage?: Maybe<RentalPostedShptLineManyResult>;
  rentalPostedShptLinesConnection?: Maybe<RentalPostedShptLineConnection>;
  rentalPriceTerms?: Maybe<RentalPriceTerms>;
  rentalPriceTermsCount?: Maybe<Scalars['Int']>;
  rentalPriceTermsMany?: Maybe<Array<Maybe<RentalPriceTerms>>>;
  rentalPriceTermsPage?: Maybe<RentalPriceTermsManyResult>;
  rentalPriceTermsConnection?: Maybe<RentalPriceTermsConnection>;
  rentalReturnEntry?: Maybe<RentalReturnEntry>;
  rentalReturnEntriesCount?: Maybe<Scalars['Int']>;
  rentalReturnEntriesMany?: Maybe<Array<Maybe<RentalReturnEntry>>>;
  rentalReturnEntriesPage?: Maybe<RentalReturnEntryManyResult>;
  rentalReturnEntriesConnection?: Maybe<RentalReturnEntryConnection>;
  requestHeader?: Maybe<RequestHeader>;
  requestHeadersCount?: Maybe<Scalars['Int']>;
  requestHeadersMany?: Maybe<Array<Maybe<RequestHeader>>>;
  requestHeadersPage?: Maybe<RequestHeaderManyResult>;
  requestHeadersConnection?: Maybe<RequestHeaderConnection>;
  requestLine?: Maybe<RequestLine>;
  requestLinesCount?: Maybe<Scalars['Int']>;
  requestLinesMany?: Maybe<Array<Maybe<RequestLine>>>;
  requestLinesPage?: Maybe<RequestLineManyResult>;
  requestLinesConnection?: Maybe<RequestLineConnection>;
  reservationRequestHeader?: Maybe<ReservationRequestHeader>;
  reservationRequestHeadersCount?: Maybe<Scalars['Int']>;
  reservationRequestHeadersMany?: Maybe<Array<Maybe<ReservationRequestHeader>>>;
  reservationRequestHeadersPage?: Maybe<ReservationRequestHeaderManyResult>;
  reservationRequestHeadersConnection?: Maybe<ReservationRequestHeaderConnection>;
  reservationRequestLine?: Maybe<ReservationRequestLine>;
  reservationRequestLinesCount?: Maybe<Scalars['Int']>;
  reservationRequestLinesMany?: Maybe<Array<Maybe<ReservationRequestLine>>>;
  reservationRequestLinesPage?: Maybe<ReservationRequestLineManyResult>;
  reservationRequestLinesConnection?: Maybe<ReservationRequestLineConnection>;
  resource?: Maybe<Resource>;
  resourcesCount?: Maybe<Scalars['Int']>;
  resourcesMany?: Maybe<Array<Maybe<Resource>>>;
  resourcesPage?: Maybe<ResourceManyResult>;
  resourcesConnection?: Maybe<ResourceConnection>;
  responsibilityCenter?: Maybe<ResponsibilityCenter>;
  responsibilityCentersCount?: Maybe<Scalars['Int']>;
  responsibilityCentersMany?: Maybe<Array<Maybe<ResponsibilityCenter>>>;
  responsibilityCentersPage?: Maybe<ResponsibilityCenterManyResult>;
  responsibilityCentersConnection?: Maybe<ResponsibilityCenterConnection>;
  returnRequestHeader?: Maybe<ReturnRequestHeader>;
  returnRequestHeadersCount?: Maybe<Scalars['Int']>;
  returnRequestHeadersMany?: Maybe<Array<Maybe<ReturnRequestHeader>>>;
  returnRequestHeadersPage?: Maybe<ReturnRequestHeaderManyResult>;
  returnRequestHeadersConnection?: Maybe<ReturnRequestHeaderConnection>;
  returnRequestLine?: Maybe<ReturnRequestLine>;
  returnRequestLinesCount?: Maybe<Scalars['Int']>;
  returnRequestLinesMany?: Maybe<Array<Maybe<ReturnRequestLine>>>;
  returnRequestLinesPage?: Maybe<ReturnRequestLineManyResult>;
  returnRequestLinesConnection?: Maybe<ReturnRequestLineConnection>;
  riskAnalysisHeader?: Maybe<RiskAnalysisHeader>;
  riskAnalysisHeadersCount?: Maybe<Scalars['Int']>;
  riskAnalysisHeadersMany?: Maybe<Array<Maybe<RiskAnalysisHeader>>>;
  riskAnalysisHeadersPage?: Maybe<RiskAnalysisHeaderManyResult>;
  riskAnalysisHeadersConnection?: Maybe<RiskAnalysisHeaderConnection>;
  riskAnalysisLine?: Maybe<RiskAnalysisLine>;
  riskAnalysisLinesCount?: Maybe<Scalars['Int']>;
  riskAnalysisLinesMany?: Maybe<Array<Maybe<RiskAnalysisLine>>>;
  riskAnalysisLinesPage?: Maybe<RiskAnalysisLineManyResult>;
  riskAnalysisLinesConnection?: Maybe<RiskAnalysisLineConnection>;
  riskAnalysisSignature?: Maybe<RiskAnalysisSignature>;
  riskAnalysisSignaturesCount?: Maybe<Scalars['Int']>;
  riskAnalysisSignaturesMany?: Maybe<Array<Maybe<RiskAnalysisSignature>>>;
  riskAnalysisSignaturesPage?: Maybe<RiskAnalysisSignatureManyResult>;
  riskAnalysisSignaturesConnection?: Maybe<RiskAnalysisSignatureConnection>;
  salesHeader?: Maybe<SalesHeader>;
  salesHeadersCount?: Maybe<Scalars['Int']>;
  salesHeadersMany?: Maybe<Array<Maybe<SalesHeader>>>;
  salesHeadersPage?: Maybe<SalesHeaderManyResult>;
  salesHeadersConnection?: Maybe<SalesHeaderConnection>;
  salesHeaderArchive?: Maybe<SalesHeaderArchive>;
  salesHeaderArchivesCount?: Maybe<Scalars['Int']>;
  salesHeaderArchivesMany?: Maybe<Array<Maybe<SalesHeaderArchive>>>;
  salesHeaderArchivesPage?: Maybe<SalesHeaderArchiveManyResult>;
  salesHeaderArchivesConnection?: Maybe<SalesHeaderArchiveConnection>;
  salesHeaderHistory?: Maybe<SalesHeaderHistory>;
  salesHeaderHistoriesCount?: Maybe<Scalars['Int']>;
  salesHeaderHistoriesMany?: Maybe<Array<Maybe<SalesHeaderHistory>>>;
  salesHeaderHistoriesPage?: Maybe<SalesHeaderHistoryManyResult>;
  salesHeaderHistoriesConnection?: Maybe<SalesHeaderHistoryConnection>;
  salesHeaderRequest?: Maybe<SalesHeaderRequest>;
  salesHeaderRequestsCount?: Maybe<Scalars['Int']>;
  salesHeaderRequestsMany?: Maybe<Array<Maybe<SalesHeaderRequest>>>;
  salesHeaderRequestsPage?: Maybe<SalesHeaderRequestManyResult>;
  salesHeaderRequestsConnection?: Maybe<SalesHeaderRequestConnection>;
  salesLine?: Maybe<SalesLine>;
  salesLinesCount?: Maybe<Scalars['Int']>;
  salesLinesMany?: Maybe<Array<Maybe<SalesLine>>>;
  salesLinesPage?: Maybe<SalesLineManyResult>;
  salesLinesConnection?: Maybe<SalesLineConnection>;
  salesLineArchive?: Maybe<SalesLineArchive>;
  salesLineArchivesCount?: Maybe<Scalars['Int']>;
  salesLineArchivesMany?: Maybe<Array<Maybe<SalesLineArchive>>>;
  salesLineArchivesPage?: Maybe<SalesLineArchiveManyResult>;
  salesLineArchivesConnection?: Maybe<SalesLineArchiveConnection>;
  salesLineRequest?: Maybe<SalesLineRequest>;
  salesLineRequestsCount?: Maybe<Scalars['Int']>;
  salesLineRequestsMany?: Maybe<Array<Maybe<SalesLineRequest>>>;
  salesLineRequestsPage?: Maybe<SalesLineRequestManyResult>;
  salesLineRequestsConnection?: Maybe<SalesLineRequestConnection>;
  salesperson?: Maybe<Salesperson>;
  salespeopleCount?: Maybe<Scalars['Int']>;
  salespeopleMany?: Maybe<Array<Maybe<Salesperson>>>;
  salespeoplePage?: Maybe<SalespersonManyResult>;
  salespeopleConnection?: Maybe<SalespersonConnection>;
  salesPrice?: Maybe<SalesPrice>;
  salesPricesCount?: Maybe<Scalars['Int']>;
  salesPricesMany?: Maybe<Array<Maybe<SalesPrice>>>;
  salesPricesPage?: Maybe<SalesPriceManyResult>;
  salesPricesConnection?: Maybe<SalesPriceConnection>;
  salesShipmentHeader?: Maybe<SalesShipmentHeader>;
  salesShipmentHeadersCount?: Maybe<Scalars['Int']>;
  salesShipmentHeadersMany?: Maybe<Array<Maybe<SalesShipmentHeader>>>;
  salesShipmentHeadersPage?: Maybe<SalesShipmentHeaderManyResult>;
  salesShipmentHeadersConnection?: Maybe<SalesShipmentHeaderConnection>;
  salesShipmentLine?: Maybe<SalesShipmentLine>;
  salesShipmentLinesCount?: Maybe<Scalars['Int']>;
  salesShipmentLinesMany?: Maybe<Array<Maybe<SalesShipmentLine>>>;
  salesShipmentLinesPage?: Maybe<SalesShipmentLineManyResult>;
  salesShipmentLinesConnection?: Maybe<SalesShipmentLineConnection>;
  serviceItemGroup?: Maybe<ServiceItemGroup>;
  serviceItemGroupsCount?: Maybe<Scalars['Int']>;
  serviceItemGroupsMany?: Maybe<Array<Maybe<ServiceItemGroup>>>;
  serviceItemGroupsPage?: Maybe<ServiceItemGroupManyResult>;
  serviceItemGroupsConnection?: Maybe<ServiceItemGroupConnection>;
  shipmentMethod?: Maybe<ShipmentMethod>;
  shipmentMethodsCount?: Maybe<Scalars['Int']>;
  shipmentMethodsMany?: Maybe<Array<Maybe<ShipmentMethod>>>;
  shipmentMethodsPage?: Maybe<ShipmentMethodManyResult>;
  shipmentMethodsConnection?: Maybe<ShipmentMethodConnection>;
  shippingAgent?: Maybe<ShippingAgent>;
  shippingAgentsCount?: Maybe<Scalars['Int']>;
  shippingAgentsMany?: Maybe<Array<Maybe<ShippingAgent>>>;
  shippingAgentsPage?: Maybe<ShippingAgentManyResult>;
  shippingAgentsConnection?: Maybe<ShippingAgentConnection>;
  signature?: Maybe<Signature>;
  signaturesCount?: Maybe<Scalars['Int']>;
  signaturesMany?: Maybe<Array<Maybe<Signature>>>;
  signaturesPage?: Maybe<SignatureManyResult>;
  signaturesConnection?: Maybe<SignatureConnection>;
  signRequest?: Maybe<SignRequest>;
  signRequestsCount?: Maybe<Scalars['Int']>;
  signRequestsMany?: Maybe<Array<Maybe<SignRequest>>>;
  signRequestsPage?: Maybe<SignRequestManyResult>;
  signRequestsConnection?: Maybe<SignRequestConnection>;
  specialEquipment?: Maybe<SpecialEquipment>;
  specialEquipmentsCount?: Maybe<Scalars['Int']>;
  specialEquipmentsMany?: Maybe<Array<Maybe<SpecialEquipment>>>;
  specialEquipmentsPage?: Maybe<SpecialEquipmentManyResult>;
  specialEquipmentsConnection?: Maybe<SpecialEquipmentConnection>;
  stockkeepingUnit?: Maybe<StockkeepingUnit>;
  stockkeepingUnitsCount?: Maybe<Scalars['Int']>;
  stockkeepingUnitsMany?: Maybe<Array<Maybe<StockkeepingUnit>>>;
  stockkeepingUnitsPage?: Maybe<StockkeepingUnitManyResult>;
  stockkeepingUnitsConnection?: Maybe<StockkeepingUnitConnection>;
  tariffNumber?: Maybe<TariffNumber>;
  tariffNumbersCount?: Maybe<Scalars['Int']>;
  tariffNumbersMany?: Maybe<Array<Maybe<TariffNumber>>>;
  tariffNumbersPage?: Maybe<TariffNumberManyResult>;
  tariffNumbersConnection?: Maybe<TariffNumberConnection>;
  taxGroup?: Maybe<TaxGroup>;
  taxGroupsCount?: Maybe<Scalars['Int']>;
  taxGroupsMany?: Maybe<Array<Maybe<TaxGroup>>>;
  taxGroupsPage?: Maybe<TaxGroupManyResult>;
  taxGroupsConnection?: Maybe<TaxGroupConnection>;
  timeRegistration?: Maybe<TimeRegistration>;
  timeRegistrationsCount?: Maybe<Scalars['Int']>;
  timeRegistrationsMany?: Maybe<Array<Maybe<TimeRegistration>>>;
  timeRegistrationsPage?: Maybe<TimeRegistrationManyResult>;
  timeRegistrationsConnection?: Maybe<TimeRegistrationConnection>;
  timeSheetDetail?: Maybe<TimeSheetDetail>;
  timeSheetDetailsCount?: Maybe<Scalars['Int']>;
  timeSheetDetailsMany?: Maybe<Array<Maybe<TimeSheetDetail>>>;
  timeSheetDetailsPage?: Maybe<TimeSheetDetailManyResult>;
  timeSheetDetailsConnection?: Maybe<TimeSheetDetailConnection>;
  timeSheetLine?: Maybe<TimeSheetLine>;
  timeSheetLinesCount?: Maybe<Scalars['Int']>;
  timeSheetLinesMany?: Maybe<Array<Maybe<TimeSheetLine>>>;
  timeSheetLinesPage?: Maybe<TimeSheetLineManyResult>;
  timeSheetLinesConnection?: Maybe<TimeSheetLineConnection>;
  transactionSpecification?: Maybe<TransactionSpecification>;
  transactionSpecificationsCount?: Maybe<Scalars['Int']>;
  transactionSpecificationsMany?: Maybe<Array<Maybe<TransactionSpecification>>>;
  transactionSpecificationsPage?: Maybe<TransactionSpecificationManyResult>;
  transactionSpecificationsConnection?: Maybe<TransactionSpecificationConnection>;
  transactionType?: Maybe<TransactionType>;
  transactionTypesCount?: Maybe<Scalars['Int']>;
  transactionTypesMany?: Maybe<Array<Maybe<TransactionType>>>;
  transactionTypesPage?: Maybe<TransactionTypeManyResult>;
  transactionTypesConnection?: Maybe<TransactionTypeConnection>;
  transportMethod?: Maybe<TransportMethod>;
  transportMethodsCount?: Maybe<Scalars['Int']>;
  transportMethodsMany?: Maybe<Array<Maybe<TransportMethod>>>;
  transportMethodsPage?: Maybe<TransportMethodManyResult>;
  transportMethodsConnection?: Maybe<TransportMethodConnection>;
  unitOfMeasure?: Maybe<UnitOfMeasure>;
  unitOfMeasuresCount?: Maybe<Scalars['Int']>;
  unitOfMeasuresMany?: Maybe<Array<Maybe<UnitOfMeasure>>>;
  unitOfMeasuresPage?: Maybe<UnitOfMeasureManyResult>;
  unitOfMeasuresConnection?: Maybe<UnitOfMeasureConnection>;
  user?: Maybe<User>;
  usersCount?: Maybe<Scalars['Int']>;
  usersMany?: Maybe<Array<Maybe<User>>>;
  usersPage?: Maybe<UserManyResult>;
  usersConnection?: Maybe<UserConnection>;
  userFavorites?: Maybe<UserFavorites>;
  userFavoritesCount?: Maybe<Scalars['Int']>;
  userFavoritesMany?: Maybe<Array<Maybe<UserFavorites>>>;
  userFavoritesPage?: Maybe<UserFavoritesManyResult>;
  userFavoritesConnection?: Maybe<UserFavoritesConnection>;
  vatBusinessPostingGroup?: Maybe<VatBusinessPostingGroup>;
  vatBusinessPostingGroupsCount?: Maybe<Scalars['Int']>;
  vatBusinessPostingGroupsMany?: Maybe<Array<Maybe<VatBusinessPostingGroup>>>;
  vatBusinessPostingGroupsPage?: Maybe<VatBusinessPostingGroupManyResult>;
  vatBusinessPostingGroupsConnection?: Maybe<VatBusinessPostingGroupConnection>;
  vatProductPostingGroup?: Maybe<VatProductPostingGroup>;
  vatProductPostingGroupsCount?: Maybe<Scalars['Int']>;
  vatProductPostingGroupsMany?: Maybe<Array<Maybe<VatProductPostingGroup>>>;
  vatProductPostingGroupsPage?: Maybe<VatProductPostingGroupManyResult>;
  vatProductPostingGroupsConnection?: Maybe<VatProductPostingGroupConnection>;
  vendor?: Maybe<Vendor>;
  vendorsCount?: Maybe<Scalars['Int']>;
  vendorsMany?: Maybe<Array<Maybe<Vendor>>>;
  vendorsPage?: Maybe<VendorManyResult>;
  vendorsConnection?: Maybe<VendorConnection>;
  vendorBankAccount?: Maybe<VendorBankAccount>;
  vendorBankAccountsCount?: Maybe<Scalars['Int']>;
  vendorBankAccountsMany?: Maybe<Array<Maybe<VendorBankAccount>>>;
  vendorBankAccountsPage?: Maybe<VendorBankAccountManyResult>;
  vendorBankAccountsConnection?: Maybe<VendorBankAccountConnection>;
  vendorPostingGroup?: Maybe<VendorPostingGroup>;
  vendorPostingGroupsCount?: Maybe<Scalars['Int']>;
  vendorPostingGroupsMany?: Maybe<Array<Maybe<VendorPostingGroup>>>;
  vendorPostingGroupsPage?: Maybe<VendorPostingGroupManyResult>;
  vendorPostingGroupsConnection?: Maybe<VendorPostingGroupConnection>;
  warehouseClass?: Maybe<WarehouseClass>;
  warehouseClassesCount?: Maybe<Scalars['Int']>;
  warehouseClassesMany?: Maybe<Array<Maybe<WarehouseClass>>>;
  warehouseClassesPage?: Maybe<WarehouseClassManyResult>;
  warehouseClassesConnection?: Maybe<WarehouseClassConnection>;
  webBooking?: Maybe<WebBooking>;
  webBookingsCount?: Maybe<Scalars['Int']>;
  webBookingsMany?: Maybe<Array<Maybe<WebBooking>>>;
  webBookingsPage?: Maybe<WebBookingManyResult>;
  webBookingsConnection?: Maybe<WebBookingConnection>;
  webhookSubscription?: Maybe<WebhookSubscription>;
  webhookSubscriptionsCount?: Maybe<Scalars['Int']>;
  webhookSubscriptionsMany?: Maybe<Array<Maybe<WebhookSubscription>>>;
  webhookSubscriptionsPage?: Maybe<WebhookSubscriptionManyResult>;
  webhookSubscriptionsConnection?: Maybe<WebhookSubscriptionConnection>;
  workLog?: Maybe<WorkLog>;
  workLogsCount?: Maybe<Scalars['Int']>;
  workLogsMany?: Maybe<Array<Maybe<WorkLog>>>;
  workLogsPage?: Maybe<WorkLogManyResult>;
  workLogsConnection?: Maybe<WorkLogConnection>;
  workType?: Maybe<WorkType>;
  workTypesCount?: Maybe<Scalars['Int']>;
  workTypesMany?: Maybe<Array<Maybe<WorkType>>>;
  workTypesPage?: Maybe<WorkTypeManyResult>;
  workTypesConnection?: Maybe<WorkTypeConnection>;
  myAccessRequestsMany?: Maybe<Array<Maybe<AccessRequest>>>;
  me?: Maybe<User>;
  customerDomainsLimited?: Maybe<Array<Maybe<CustomerDomainLimited>>>;
  domainsLimited?: Maybe<Array<Maybe<DomainLimited>>>;
  siblingDomains?: Maybe<Array<Maybe<DomainLimited>>>;
  safetyRespectDomains?: Maybe<Array<Maybe<DomainLimited>>>;
  domain?: Maybe<Domain>;
  domainsMany?: Maybe<Array<Maybe<Domain>>>;
  domainsPage?: Maybe<DomainManyResult>;
  domainIntegration?: Maybe<DomainIntegration>;
  domainIntegrationsMany?: Maybe<Array<Maybe<DomainIntegration>>>;
  myCustomerProjects?: Maybe<Array<Maybe<CustomerProject>>>;
  documentsMany?: Maybe<Array<Maybe<Document>>>;
  document?: Maybe<Document>;
  documentsPage?: Maybe<DocumentManyResult>;
  objectTypeLimited?: Maybe<ObjectTypeLimited>;
  rentalEventsMany?: Maybe<RentalEvent>;
  rentalLinesCalculated?: Maybe<Array<Maybe<RentalLine>>>;
  rentCost?: Maybe<RentCost>;
  accumulatedRentCost?: Maybe<AccumulatedRentCost>;
  dailyRentalEvents?: Maybe<DailyRentalEvents>;
  SalesOrderChangeTrackingPage?: Maybe<SalesOrderChangeTrackingPageResponse>;
  autocomplete?: Maybe<Array<Maybe<AutoComplete>>>;
  invitationsPage?: Maybe<InvitationManyResult>;
  invitation?: Maybe<Invitation>;
  myInvitation?: Maybe<InvitationLimited>;
  countFinishedFunctiontalTests?: Maybe<OldFunctionTestCountResult>;
};


export type QueryAccessRequestArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
};


export type QueryAccessRequestsCountArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
};


export type QueryAccessRequestsManyArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAccessRequestInput>;
  aggregate?: Maybe<AggregateAccessRequestInput>;
};


export type QueryAccessRequestsPageArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAccessRequestInput>;
  aggregate?: Maybe<AggregateAccessRequestInput>;
};


export type QueryAccessRequestsConnectionArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAccessRequestInput>;
  aggregate?: Maybe<AggregateAccessRequestInput>;
};


export type QueryAdditionalItemArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
};


export type QueryAdditionalItemsCountArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
};


export type QueryAdditionalItemsManyArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAdditionalItemInput>;
  aggregate?: Maybe<AggregateAdditionalItemInput>;
};


export type QueryAdditionalItemsPageArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAdditionalItemInput>;
  aggregate?: Maybe<AggregateAdditionalItemInput>;
};


export type QueryAdditionalItemsConnectionArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAdditionalItemInput>;
  aggregate?: Maybe<AggregateAdditionalItemInput>;
};


export type QueryAddressArgs = {
  filter?: Maybe<FilterAddressInput>;
};


export type QueryAddressesCountArgs = {
  filter?: Maybe<FilterAddressInput>;
};


export type QueryAddressesManyArgs = {
  filter?: Maybe<FilterAddressInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAddressInput>;
  aggregate?: Maybe<AggregateAddressInput>;
};


export type QueryAddressesPageArgs = {
  filter?: Maybe<FilterAddressInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAddressInput>;
  aggregate?: Maybe<AggregateAddressInput>;
};


export type QueryAddressesConnectionArgs = {
  filter?: Maybe<FilterAddressInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAddressInput>;
  aggregate?: Maybe<AggregateAddressInput>;
};


export type QueryAgreementArgs = {
  filter?: Maybe<FilterAgreementInput>;
};


export type QueryAgreementsCountArgs = {
  filter?: Maybe<FilterAgreementInput>;
};


export type QueryAgreementsManyArgs = {
  filter?: Maybe<FilterAgreementInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAgreementInput>;
  aggregate?: Maybe<AggregateAgreementInput>;
};


export type QueryAgreementsPageArgs = {
  filter?: Maybe<FilterAgreementInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAgreementInput>;
  aggregate?: Maybe<AggregateAgreementInput>;
};


export type QueryAgreementsConnectionArgs = {
  filter?: Maybe<FilterAgreementInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAgreementInput>;
  aggregate?: Maybe<AggregateAgreementInput>;
};


export type QueryAnnotationArgs = {
  filter?: Maybe<FilterAnnotationInput>;
};


export type QueryAnnotationsCountArgs = {
  filter?: Maybe<FilterAnnotationInput>;
};


export type QueryAnnotationsManyArgs = {
  filter?: Maybe<FilterAnnotationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAnnotationInput>;
  aggregate?: Maybe<AggregateAnnotationInput>;
};


export type QueryAnnotationsPageArgs = {
  filter?: Maybe<FilterAnnotationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAnnotationInput>;
  aggregate?: Maybe<AggregateAnnotationInput>;
};


export type QueryAnnotationsConnectionArgs = {
  filter?: Maybe<FilterAnnotationInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortAnnotationInput>;
  aggregate?: Maybe<AggregateAnnotationInput>;
};


export type QueryApplicationModuleArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
};


export type QueryApplicationModulesCountArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
};


export type QueryApplicationModulesManyArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortApplicationModuleInput>;
  aggregate?: Maybe<AggregateApplicationModuleInput>;
};


export type QueryApplicationModulesPageArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortApplicationModuleInput>;
  aggregate?: Maybe<AggregateApplicationModuleInput>;
};


export type QueryApplicationModulesConnectionArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortApplicationModuleInput>;
  aggregate?: Maybe<AggregateApplicationModuleInput>;
};


export type QueryBcMappingTableArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
};


export type QueryBcMappingTablesCountArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
};


export type QueryBcMappingTablesManyArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcMappingTableInput>;
  aggregate?: Maybe<AggregateBcMappingTableInput>;
};


export type QueryBcMappingTablesPageArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcMappingTableInput>;
  aggregate?: Maybe<AggregateBcMappingTableInput>;
};


export type QueryBcMappingTablesConnectionArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcMappingTableInput>;
  aggregate?: Maybe<AggregateBcMappingTableInput>;
};


export type QueryBcMappingFieldArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
};


export type QueryBcMappingFieldsCountArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
};


export type QueryBcMappingFieldsManyArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcMappingFieldInput>;
  aggregate?: Maybe<AggregateBcMappingFieldInput>;
};


export type QueryBcMappingFieldsPageArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcMappingFieldInput>;
  aggregate?: Maybe<AggregateBcMappingFieldInput>;
};


export type QueryBcMappingFieldsConnectionArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcMappingFieldInput>;
  aggregate?: Maybe<AggregateBcMappingFieldInput>;
};


export type QueryBcSettingsArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
};


export type QueryBcSettingsCountArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
};


export type QueryBcSettingsManyArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcSettingsInput>;
  aggregate?: Maybe<AggregateBcSettingsInput>;
};


export type QueryBcSettingsPageArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcSettingsInput>;
  aggregate?: Maybe<AggregateBcSettingsInput>;
};


export type QueryBcSettingsConnectionArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBcSettingsInput>;
  aggregate?: Maybe<AggregateBcSettingsInput>;
};


export type QueryBulkItemGroupArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
};


export type QueryBulkItemGroupsCountArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
};


export type QueryBulkItemGroupsManyArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBulkItemGroupInput>;
  aggregate?: Maybe<AggregateBulkItemGroupInput>;
};


export type QueryBulkItemGroupsPageArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBulkItemGroupInput>;
  aggregate?: Maybe<AggregateBulkItemGroupInput>;
};


export type QueryBulkItemGroupsConnectionArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortBulkItemGroupInput>;
  aggregate?: Maybe<AggregateBulkItemGroupInput>;
};


export type QueryCauseOfAbsenceArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
};


export type QueryCauseOfAbsencesCountArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
};


export type QueryCauseOfAbsencesManyArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCauseOfAbsenceInput>;
  aggregate?: Maybe<AggregateCauseOfAbsenceInput>;
};


export type QueryCauseOfAbsencesPageArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCauseOfAbsenceInput>;
  aggregate?: Maybe<AggregateCauseOfAbsenceInput>;
};


export type QueryCauseOfAbsencesConnectionArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCauseOfAbsenceInput>;
  aggregate?: Maybe<AggregateCauseOfAbsenceInput>;
};


export type QueryCompanyArgs = {
  filter?: Maybe<FilterCompanyInput>;
};


export type QueryCompaniesCountArgs = {
  filter?: Maybe<FilterCompanyInput>;
};


export type QueryCompaniesManyArgs = {
  filter?: Maybe<FilterCompanyInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCompanyInput>;
  aggregate?: Maybe<AggregateCompanyInput>;
};


export type QueryCompaniesPageArgs = {
  filter?: Maybe<FilterCompanyInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCompanyInput>;
  aggregate?: Maybe<AggregateCompanyInput>;
};


export type QueryCompaniesConnectionArgs = {
  filter?: Maybe<FilterCompanyInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCompanyInput>;
  aggregate?: Maybe<AggregateCompanyInput>;
};


export type QueryConsignmentHeaderArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
};


export type QueryConsignmentHeadersCountArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
};


export type QueryConsignmentHeadersManyArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortConsignmentHeaderInput>;
  aggregate?: Maybe<AggregateConsignmentHeaderInput>;
};


export type QueryConsignmentHeadersPageArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortConsignmentHeaderInput>;
  aggregate?: Maybe<AggregateConsignmentHeaderInput>;
};


export type QueryConsignmentHeadersConnectionArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortConsignmentHeaderInput>;
  aggregate?: Maybe<AggregateConsignmentHeaderInput>;
};


export type QueryConsignmentLineArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
};


export type QueryConsignmentLinesCountArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
};


export type QueryConsignmentLinesManyArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortConsignmentLineInput>;
  aggregate?: Maybe<AggregateConsignmentLineInput>;
};


export type QueryConsignmentLinesPageArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortConsignmentLineInput>;
  aggregate?: Maybe<AggregateConsignmentLineInput>;
};


export type QueryConsignmentLinesConnectionArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortConsignmentLineInput>;
  aggregate?: Maybe<AggregateConsignmentLineInput>;
};


export type QueryContactArgs = {
  filter?: Maybe<FilterContactInput>;
};


export type QueryContactsCountArgs = {
  filter?: Maybe<FilterContactInput>;
};


export type QueryContactsManyArgs = {
  filter?: Maybe<FilterContactInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortContactInput>;
  aggregate?: Maybe<AggregateContactInput>;
};


export type QueryContactsPageArgs = {
  filter?: Maybe<FilterContactInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortContactInput>;
  aggregate?: Maybe<AggregateContactInput>;
};


export type QueryContactsConnectionArgs = {
  filter?: Maybe<FilterContactInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortContactInput>;
  aggregate?: Maybe<AggregateContactInput>;
};


export type QueryCountryRegionArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
};


export type QueryCountryRegionsCountArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
};


export type QueryCountryRegionsManyArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCountryRegionInput>;
  aggregate?: Maybe<AggregateCountryRegionInput>;
};


export type QueryCountryRegionsPageArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCountryRegionInput>;
  aggregate?: Maybe<AggregateCountryRegionInput>;
};


export type QueryCountryRegionsConnectionArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCountryRegionInput>;
  aggregate?: Maybe<AggregateCountryRegionInput>;
};


export type QueryCurrencyArgs = {
  filter?: Maybe<FilterCurrencyInput>;
};


export type QueryCurrenciesCountArgs = {
  filter?: Maybe<FilterCurrencyInput>;
};


export type QueryCurrenciesManyArgs = {
  filter?: Maybe<FilterCurrencyInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCurrencyInput>;
  aggregate?: Maybe<AggregateCurrencyInput>;
};


export type QueryCurrenciesPageArgs = {
  filter?: Maybe<FilterCurrencyInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCurrencyInput>;
  aggregate?: Maybe<AggregateCurrencyInput>;
};


export type QueryCurrenciesConnectionArgs = {
  filter?: Maybe<FilterCurrencyInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCurrencyInput>;
  aggregate?: Maybe<AggregateCurrencyInput>;
};


export type QueryCurrencyExchangeRateArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
};


export type QueryCurrencyExchangeRatesCountArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
};


export type QueryCurrencyExchangeRatesManyArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCurrencyExchangeRateInput>;
  aggregate?: Maybe<AggregateCurrencyExchangeRateInput>;
};


export type QueryCurrencyExchangeRatesPageArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCurrencyExchangeRateInput>;
  aggregate?: Maybe<AggregateCurrencyExchangeRateInput>;
};


export type QueryCurrencyExchangeRatesConnectionArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCurrencyExchangeRateInput>;
  aggregate?: Maybe<AggregateCurrencyExchangeRateInput>;
};


export type QueryCustomerDomainArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
};


export type QueryCustomerDomainsCountArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
};


export type QueryCustomerDomainsManyArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerDomainInput>;
  aggregate?: Maybe<AggregateCustomerDomainInput>;
};


export type QueryCustomerDomainsPageArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerDomainInput>;
  aggregate?: Maybe<AggregateCustomerDomainInput>;
};


export type QueryCustomerDomainsConnectionArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerDomainInput>;
  aggregate?: Maybe<AggregateCustomerDomainInput>;
};


export type QueryCustomerPostingGroupArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
};


export type QueryCustomerPostingGroupsCountArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
};


export type QueryCustomerPostingGroupsManyArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerPostingGroupInput>;
  aggregate?: Maybe<AggregateCustomerPostingGroupInput>;
};


export type QueryCustomerPostingGroupsPageArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerPostingGroupInput>;
  aggregate?: Maybe<AggregateCustomerPostingGroupInput>;
};


export type QueryCustomerPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerPostingGroupInput>;
  aggregate?: Maybe<AggregateCustomerPostingGroupInput>;
};


export type QueryCustomerProjectArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
};


export type QueryCustomerProjectsCountArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
};


export type QueryCustomerProjectsManyArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerProjectInput>;
  aggregate?: Maybe<AggregateCustomerProjectInput>;
};


export type QueryCustomerProjectsPageArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerProjectInput>;
  aggregate?: Maybe<AggregateCustomerProjectInput>;
};


export type QueryCustomerProjectsConnectionArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerProjectInput>;
  aggregate?: Maybe<AggregateCustomerProjectInput>;
};


export type QueryCustomerSubProjectArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
};


export type QueryCustomerSubProjectsCountArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
};


export type QueryCustomerSubProjectsManyArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerSubProjectInput>;
  aggregate?: Maybe<AggregateCustomerSubProjectInput>;
};


export type QueryCustomerSubProjectsPageArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerSubProjectInput>;
  aggregate?: Maybe<AggregateCustomerSubProjectInput>;
};


export type QueryCustomerSubProjectsConnectionArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerSubProjectInput>;
  aggregate?: Maybe<AggregateCustomerSubProjectInput>;
};


export type QueryDeliverytimeArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
};


export type QueryDeliverytimesCountArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
};


export type QueryDeliverytimesManyArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDeliverytimeInput>;
  aggregate?: Maybe<AggregateDeliverytimeInput>;
};


export type QueryDeliverytimesPageArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDeliverytimeInput>;
  aggregate?: Maybe<AggregateDeliverytimeInput>;
};


export type QueryDeliverytimesConnectionArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDeliverytimeInput>;
  aggregate?: Maybe<AggregateDeliverytimeInput>;
};


export type QueryDevopsProjectArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
};


export type QueryDevopsProjectsCountArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
};


export type QueryDevopsProjectsManyArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsProjectInput>;
  aggregate?: Maybe<AggregateDevopsProjectInput>;
};


export type QueryDevopsProjectsPageArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsProjectInput>;
  aggregate?: Maybe<AggregateDevopsProjectInput>;
};


export type QueryDevopsProjectsConnectionArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsProjectInput>;
  aggregate?: Maybe<AggregateDevopsProjectInput>;
};


export type QueryDevopsTeamArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
};


export type QueryDevopsTeamsCountArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
};


export type QueryDevopsTeamsManyArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsTeamInput>;
  aggregate?: Maybe<AggregateDevopsTeamInput>;
};


export type QueryDevopsTeamsPageArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsTeamInput>;
  aggregate?: Maybe<AggregateDevopsTeamInput>;
};


export type QueryDevopsTeamsConnectionArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsTeamInput>;
  aggregate?: Maybe<AggregateDevopsTeamInput>;
};


export type QueryDevopsWorkItemArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
};


export type QueryDevopsWorkItemsCountArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
};


export type QueryDevopsWorkItemsManyArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsWorkItemInput>;
  aggregate?: Maybe<AggregateDevopsWorkItemInput>;
};


export type QueryDevopsWorkItemsPageArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsWorkItemInput>;
  aggregate?: Maybe<AggregateDevopsWorkItemInput>;
};


export type QueryDevopsWorkItemsConnectionArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDevopsWorkItemInput>;
  aggregate?: Maybe<AggregateDevopsWorkItemInput>;
};


export type QueryDimensionArgs = {
  filter?: Maybe<FilterDimensionInput>;
};


export type QueryDimensionsCountArgs = {
  filter?: Maybe<FilterDimensionInput>;
};


export type QueryDimensionsManyArgs = {
  filter?: Maybe<FilterDimensionInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDimensionInput>;
  aggregate?: Maybe<AggregateDimensionInput>;
};


export type QueryDimensionsPageArgs = {
  filter?: Maybe<FilterDimensionInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDimensionInput>;
  aggregate?: Maybe<AggregateDimensionInput>;
};


export type QueryDimensionsConnectionArgs = {
  filter?: Maybe<FilterDimensionInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDimensionInput>;
  aggregate?: Maybe<AggregateDimensionInput>;
};


export type QueryDimensionValueArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
};


export type QueryDimensionValuesCountArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
};


export type QueryDimensionValuesManyArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDimensionValueInput>;
  aggregate?: Maybe<AggregateDimensionValueInput>;
};


export type QueryDimensionValuesPageArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDimensionValueInput>;
  aggregate?: Maybe<AggregateDimensionValueInput>;
};


export type QueryDimensionValuesConnectionArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDimensionValueInput>;
  aggregate?: Maybe<AggregateDimensionValueInput>;
};


export type QueryDiscussionPostArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
};


export type QueryDiscussionPostsCountArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
};


export type QueryDiscussionPostsManyArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDiscussionPostInput>;
  aggregate?: Maybe<AggregateDiscussionPostInput>;
};


export type QueryDiscussionPostsPageArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDiscussionPostInput>;
  aggregate?: Maybe<AggregateDiscussionPostInput>;
};


export type QueryDiscussionPostsConnectionArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDiscussionPostInput>;
  aggregate?: Maybe<AggregateDiscussionPostInput>;
};


export type QueryDocumentClassificationArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
};


export type QueryDocumentClassificationsCountArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
};


export type QueryDocumentClassificationsManyArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentClassificationInput>;
  aggregate?: Maybe<AggregateDocumentClassificationInput>;
};


export type QueryDocumentClassificationsPageArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentClassificationInput>;
  aggregate?: Maybe<AggregateDocumentClassificationInput>;
};


export type QueryDocumentClassificationsConnectionArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentClassificationInput>;
  aggregate?: Maybe<AggregateDocumentClassificationInput>;
};


export type QueryDocumentRequestArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
};


export type QueryDocumentRequestsCountArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
};


export type QueryDocumentRequestsManyArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentRequestInput>;
  aggregate?: Maybe<AggregateDocumentRequestInput>;
};


export type QueryDocumentRequestsPageArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentRequestInput>;
  aggregate?: Maybe<AggregateDocumentRequestInput>;
};


export type QueryDocumentRequestsConnectionArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentRequestInput>;
  aggregate?: Maybe<AggregateDocumentRequestInput>;
};


export type QueryDomainSettingsArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
};


export type QueryDomainSettingsCountArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
};


export type QueryDomainSettingsManyArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainSettingsInput>;
  aggregate?: Maybe<AggregateDomainSettingsInput>;
};


export type QueryDomainSettingsPageArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainSettingsInput>;
  aggregate?: Maybe<AggregateDomainSettingsInput>;
};


export type QueryDomainSettingsConnectionArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainSettingsInput>;
  aggregate?: Maybe<AggregateDomainSettingsInput>;
};


export type QueryEqmBaseCalendarArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
};


export type QueryEqmBaseCalendarsCountArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
};


export type QueryEqmBaseCalendarsManyArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmBaseCalendarInput>;
  aggregate?: Maybe<AggregateEqmBaseCalendarInput>;
};


export type QueryEqmBaseCalendarsPageArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmBaseCalendarInput>;
  aggregate?: Maybe<AggregateEqmBaseCalendarInput>;
};


export type QueryEqmBaseCalendarsConnectionArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmBaseCalendarInput>;
  aggregate?: Maybe<AggregateEqmBaseCalendarInput>;
};


export type QueryEqmBaseCalendarChangeArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
};


export type QueryEqmBaseCalendarChangesCountArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
};


export type QueryEqmBaseCalendarChangesManyArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmBaseCalendarChangeInput>;
  aggregate?: Maybe<AggregateEqmBaseCalendarChangeInput>;
};


export type QueryEqmBaseCalendarChangesPageArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmBaseCalendarChangeInput>;
  aggregate?: Maybe<AggregateEqmBaseCalendarChangeInput>;
};


export type QueryEqmBaseCalendarChangesConnectionArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmBaseCalendarChangeInput>;
  aggregate?: Maybe<AggregateEqmBaseCalendarChangeInput>;
};


export type QueryEqmCollectionReturnChargesArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
};


export type QueryEqmCollectionReturnChargesCountArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
};


export type QueryEqmCollectionReturnChargesManyArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCollectionReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmCollectionReturnChargesInput>;
};


export type QueryEqmCollectionReturnChargesPageArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCollectionReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmCollectionReturnChargesInput>;
};


export type QueryEqmCollectionReturnChargesConnectionArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCollectionReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmCollectionReturnChargesInput>;
};


export type QueryEqmCommentLineArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
};


export type QueryEqmCommentLinesCountArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
};


export type QueryEqmCommentLinesManyArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCommentLineInput>;
  aggregate?: Maybe<AggregateEqmCommentLineInput>;
};


export type QueryEqmCommentLinesPageArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCommentLineInput>;
  aggregate?: Maybe<AggregateEqmCommentLineInput>;
};


export type QueryEqmCommentLinesConnectionArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCommentLineInput>;
  aggregate?: Maybe<AggregateEqmCommentLineInput>;
};


export type QueryEqmCustomizedCalendarChangeArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};


export type QueryEqmCustomizedCalendarChangesCountArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};


export type QueryEqmCustomizedCalendarChangesManyArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCustomizedCalendarChangeInput>;
  aggregate?: Maybe<AggregateEqmCustomizedCalendarChangeInput>;
};


export type QueryEqmCustomizedCalendarChangesPageArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCustomizedCalendarChangeInput>;
  aggregate?: Maybe<AggregateEqmCustomizedCalendarChangeInput>;
};


export type QueryEqmCustomizedCalendarChangesConnectionArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmCustomizedCalendarChangeInput>;
  aggregate?: Maybe<AggregateEqmCustomizedCalendarChangeInput>;
};


export type QueryEqmFncTestReturnChargesArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
};


export type QueryEqmFncTestReturnChargesCountArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
};


export type QueryEqmFncTestReturnChargesManyArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmFncTestReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmFncTestReturnChargesInput>;
};


export type QueryEqmFncTestReturnChargesPageArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmFncTestReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmFncTestReturnChargesInput>;
};


export type QueryEqmFncTestReturnChargesConnectionArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmFncTestReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmFncTestReturnChargesInput>;
};


export type QueryEqmLocCostCenterCombArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
};


export type QueryEqmLocCostCenterCombsCountArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
};


export type QueryEqmLocCostCenterCombsManyArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmLocCostCenterCombInput>;
  aggregate?: Maybe<AggregateEqmLocCostCenterCombInput>;
};


export type QueryEqmLocCostCenterCombsPageArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmLocCostCenterCombInput>;
  aggregate?: Maybe<AggregateEqmLocCostCenterCombInput>;
};


export type QueryEqmLocCostCenterCombsConnectionArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmLocCostCenterCombInput>;
  aggregate?: Maybe<AggregateEqmLocCostCenterCombInput>;
};


export type QueryEqmManufacturerArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
};


export type QueryEqmManufacturersCountArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
};


export type QueryEqmManufacturersManyArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmManufacturerInput>;
  aggregate?: Maybe<AggregateEqmManufacturerInput>;
};


export type QueryEqmManufacturersPageArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmManufacturerInput>;
  aggregate?: Maybe<AggregateEqmManufacturerInput>;
};


export type QueryEqmManufacturersConnectionArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmManufacturerInput>;
  aggregate?: Maybe<AggregateEqmManufacturerInput>;
};


export type QueryEqmManufacturerModelArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
};


export type QueryEqmManufacturerModelsCountArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
};


export type QueryEqmManufacturerModelsManyArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmManufacturerModelInput>;
  aggregate?: Maybe<AggregateEqmManufacturerModelInput>;
};


export type QueryEqmManufacturerModelsPageArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmManufacturerModelInput>;
  aggregate?: Maybe<AggregateEqmManufacturerModelInput>;
};


export type QueryEqmManufacturerModelsConnectionArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmManufacturerModelInput>;
  aggregate?: Maybe<AggregateEqmManufacturerModelInput>;
};


export type QueryEqmObjectCardFlowArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
};


export type QueryEqmObjectCardFlowsCountArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
};


export type QueryEqmObjectCardFlowsManyArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCardFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectCardFlowInput>;
};


export type QueryEqmObjectCardFlowsPageArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCardFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectCardFlowInput>;
};


export type QueryEqmObjectCardFlowsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCardFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectCardFlowInput>;
};


export type QueryEqmObjectComponentsArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
};


export type QueryEqmObjectComponentsCountArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
};


export type QueryEqmObjectComponentsManyArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectComponentsInput>;
  aggregate?: Maybe<AggregateEqmObjectComponentsInput>;
};


export type QueryEqmObjectComponentsPageArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectComponentsInput>;
  aggregate?: Maybe<AggregateEqmObjectComponentsInput>;
};


export type QueryEqmObjectComponentsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectComponentsInput>;
  aggregate?: Maybe<AggregateEqmObjectComponentsInput>;
};


export type QueryEqmObjectCountHeaderArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
};


export type QueryEqmObjectCountHeadersCountArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
};


export type QueryEqmObjectCountHeadersManyArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCountHeaderInput>;
  aggregate?: Maybe<AggregateEqmObjectCountHeaderInput>;
};


export type QueryEqmObjectCountHeadersPageArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCountHeaderInput>;
  aggregate?: Maybe<AggregateEqmObjectCountHeaderInput>;
};


export type QueryEqmObjectCountHeadersConnectionArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCountHeaderInput>;
  aggregate?: Maybe<AggregateEqmObjectCountHeaderInput>;
};


export type QueryEqmObjectCountLineArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
};


export type QueryEqmObjectCountLinesCountArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
};


export type QueryEqmObjectCountLinesManyArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCountLineInput>;
  aggregate?: Maybe<AggregateEqmObjectCountLineInput>;
};


export type QueryEqmObjectCountLinesPageArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCountLineInput>;
  aggregate?: Maybe<AggregateEqmObjectCountLineInput>;
};


export type QueryEqmObjectCountLinesConnectionArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectCountLineInput>;
  aggregate?: Maybe<AggregateEqmObjectCountLineInput>;
};


export type QueryEqmObjectGroupCardArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
};


export type QueryEqmObjectGroupCardsCountArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
};


export type QueryEqmObjectGroupCardsManyArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectGroupCardInput>;
  aggregate?: Maybe<AggregateEqmObjectGroupCardInput>;
};


export type QueryEqmObjectGroupCardsPageArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectGroupCardInput>;
  aggregate?: Maybe<AggregateEqmObjectGroupCardInput>;
};


export type QueryEqmObjectGroupCardsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectGroupCardInput>;
  aggregate?: Maybe<AggregateEqmObjectGroupCardInput>;
};


export type QueryEqmObjectGroupFlowArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
};


export type QueryEqmObjectGroupFlowsCountArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
};


export type QueryEqmObjectGroupFlowsManyArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectGroupFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectGroupFlowInput>;
};


export type QueryEqmObjectGroupFlowsPageArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectGroupFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectGroupFlowInput>;
};


export type QueryEqmObjectGroupFlowsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectGroupFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectGroupFlowInput>;
};


export type QueryEqmObjectServiceIntervalArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
};


export type QueryEqmObjectServiceIntervalsCountArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
};


export type QueryEqmObjectServiceIntervalsManyArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectServiceIntervalInput>;
  aggregate?: Maybe<AggregateEqmObjectServiceIntervalInput>;
};


export type QueryEqmObjectServiceIntervalsPageArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectServiceIntervalInput>;
  aggregate?: Maybe<AggregateEqmObjectServiceIntervalInput>;
};


export type QueryEqmObjectServiceIntervalsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectServiceIntervalInput>;
  aggregate?: Maybe<AggregateEqmObjectServiceIntervalInput>;
};


export type QueryEqmObjectSrvIntervalAggArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};


export type QueryEqmObjectSrvIntervalAggsCountArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};


export type QueryEqmObjectSrvIntervalAggsManyArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectSrvIntervalAggInput>;
  aggregate?: Maybe<AggregateEqmObjectSrvIntervalAggInput>;
};


export type QueryEqmObjectSrvIntervalAggsPageArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectSrvIntervalAggInput>;
  aggregate?: Maybe<AggregateEqmObjectSrvIntervalAggInput>;
};


export type QueryEqmObjectSrvIntervalAggsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectSrvIntervalAggInput>;
  aggregate?: Maybe<AggregateEqmObjectSrvIntervalAggInput>;
};


export type QueryEqmObjectStatusArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
};


export type QueryEqmObjectStatusesCountArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
};


export type QueryEqmObjectStatusesManyArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectStatusInput>;
  aggregate?: Maybe<AggregateEqmObjectStatusInput>;
};


export type QueryEqmObjectStatusesPageArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectStatusInput>;
  aggregate?: Maybe<AggregateEqmObjectStatusInput>;
};


export type QueryEqmObjectStatusesConnectionArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectStatusInput>;
  aggregate?: Maybe<AggregateEqmObjectStatusInput>;
};


export type QueryEqmObjectTypeCardArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
};


export type QueryEqmObjectTypeCardsCountArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
};


export type QueryEqmObjectTypeCardsManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeCardInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeCardInput>;
};


export type QueryEqmObjectTypeCardsPageArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeCardInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeCardInput>;
};


export type QueryEqmObjectTypeCardsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeCardInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeCardInput>;
};


export type QueryEqmObjectTypeComponentsArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
};


export type QueryEqmObjectTypeComponentsCountArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
};


export type QueryEqmObjectTypeComponentsManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeComponentsInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeComponentsInput>;
};


export type QueryEqmObjectTypeComponentsPageArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeComponentsInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeComponentsInput>;
};


export type QueryEqmObjectTypeComponentsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeComponentsInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeComponentsInput>;
};


export type QueryEqmObjectTypeFlowArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
};


export type QueryEqmObjectTypeFlowsCountArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
};


export type QueryEqmObjectTypeFlowsManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeFlowInput>;
};


export type QueryEqmObjectTypeFlowsPageArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeFlowInput>;
};


export type QueryEqmObjectTypeFlowsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeFlowInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeFlowInput>;
};


export type QueryEqmObjectTypePriceTermArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
};


export type QueryEqmObjectTypePriceTermsCountArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
};


export type QueryEqmObjectTypePriceTermsManyArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypePriceTermInput>;
  aggregate?: Maybe<AggregateEqmObjectTypePriceTermInput>;
};


export type QueryEqmObjectTypePriceTermsPageArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypePriceTermInput>;
  aggregate?: Maybe<AggregateEqmObjectTypePriceTermInput>;
};


export type QueryEqmObjectTypePriceTermsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypePriceTermInput>;
  aggregate?: Maybe<AggregateEqmObjectTypePriceTermInput>;
};


export type QueryEqmObjectTypeServiceIntervalArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};


export type QueryEqmObjectTypeServiceIntervalsCountArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};


export type QueryEqmObjectTypeServiceIntervalsManyArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeServiceIntervalInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeServiceIntervalInput>;
};


export type QueryEqmObjectTypeServiceIntervalsPageArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeServiceIntervalInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeServiceIntervalInput>;
};


export type QueryEqmObjectTypeServiceIntervalsConnectionArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmObjectTypeServiceIntervalInput>;
  aggregate?: Maybe<AggregateEqmObjectTypeServiceIntervalInput>;
};


export type QueryEqmRentalGroupArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
};


export type QueryEqmRentalGroupsCountArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
};


export type QueryEqmRentalGroupsManyArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalGroupInput>;
  aggregate?: Maybe<AggregateEqmRentalGroupInput>;
};


export type QueryEqmRentalGroupsPageArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalGroupInput>;
  aggregate?: Maybe<AggregateEqmRentalGroupInput>;
};


export type QueryEqmRentalGroupsConnectionArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalGroupInput>;
  aggregate?: Maybe<AggregateEqmRentalGroupInput>;
};


export type QueryEqmRentalKitArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
};


export type QueryEqmRentalKitsCountArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
};


export type QueryEqmRentalKitsManyArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitInput>;
  aggregate?: Maybe<AggregateEqmRentalKitInput>;
};


export type QueryEqmRentalKitsPageArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitInput>;
  aggregate?: Maybe<AggregateEqmRentalKitInput>;
};


export type QueryEqmRentalKitsConnectionArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitInput>;
  aggregate?: Maybe<AggregateEqmRentalKitInput>;
};


export type QueryEqmRentalKitComponentsArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
};


export type QueryEqmRentalKitComponentsCountArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
};


export type QueryEqmRentalKitComponentsManyArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitComponentsInput>;
  aggregate?: Maybe<AggregateEqmRentalKitComponentsInput>;
};


export type QueryEqmRentalKitComponentsPageArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitComponentsInput>;
  aggregate?: Maybe<AggregateEqmRentalKitComponentsInput>;
};


export type QueryEqmRentalKitComponentsConnectionArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitComponentsInput>;
  aggregate?: Maybe<AggregateEqmRentalKitComponentsInput>;
};


export type QueryEqmRentalKitLinesArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
};


export type QueryEqmRentalKitLinesCountArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
};


export type QueryEqmRentalKitLinesManyArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitLinesInput>;
  aggregate?: Maybe<AggregateEqmRentalKitLinesInput>;
};


export type QueryEqmRentalKitLinesPageArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitLinesInput>;
  aggregate?: Maybe<AggregateEqmRentalKitLinesInput>;
};


export type QueryEqmRentalKitLinesConnectionArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalKitLinesInput>;
  aggregate?: Maybe<AggregateEqmRentalKitLinesInput>;
};


export type QueryEqmRentalLineDiscountArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
};


export type QueryEqmRentalLineDiscountsCountArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
};


export type QueryEqmRentalLineDiscountsManyArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalLineDiscountInput>;
  aggregate?: Maybe<AggregateEqmRentalLineDiscountInput>;
};


export type QueryEqmRentalLineDiscountsPageArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalLineDiscountInput>;
  aggregate?: Maybe<AggregateEqmRentalLineDiscountInput>;
};


export type QueryEqmRentalLineDiscountsConnectionArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalLineDiscountInput>;
  aggregate?: Maybe<AggregateEqmRentalLineDiscountInput>;
};


export type QueryEqmRentalPriceArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
};


export type QueryEqmRentalPricesCountArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
};


export type QueryEqmRentalPricesManyArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalPriceInput>;
  aggregate?: Maybe<AggregateEqmRentalPriceInput>;
};


export type QueryEqmRentalPricesPageArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalPriceInput>;
  aggregate?: Maybe<AggregateEqmRentalPriceInput>;
};


export type QueryEqmRentalPricesConnectionArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmRentalPriceInput>;
  aggregate?: Maybe<AggregateEqmRentalPriceInput>;
};


export type QueryEqmReRentCardArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
};


export type QueryEqmReRentCardsCountArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
};


export type QueryEqmReRentCardsManyArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmReRentCardInput>;
  aggregate?: Maybe<AggregateEqmReRentCardInput>;
};


export type QueryEqmReRentCardsPageArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmReRentCardInput>;
  aggregate?: Maybe<AggregateEqmReRentCardInput>;
};


export type QueryEqmReRentCardsConnectionArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmReRentCardInput>;
  aggregate?: Maybe<AggregateEqmReRentCardInput>;
};


export type QueryEqmReturnChargesArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
};


export type QueryEqmReturnChargesCountArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
};


export type QueryEqmReturnChargesManyArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmReturnChargesInput>;
};


export type QueryEqmReturnChargesPageArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmReturnChargesInput>;
};


export type QueryEqmReturnChargesConnectionArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmReturnChargesInput>;
  aggregate?: Maybe<AggregateEqmReturnChargesInput>;
};


export type QueryEqmServiceCardArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
};


export type QueryEqmServiceCardsCountArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
};


export type QueryEqmServiceCardsManyArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmServiceCardInput>;
  aggregate?: Maybe<AggregateEqmServiceCardInput>;
};


export type QueryEqmServiceCardsPageArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmServiceCardInput>;
  aggregate?: Maybe<AggregateEqmServiceCardInput>;
};


export type QueryEqmServiceCardsConnectionArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmServiceCardInput>;
  aggregate?: Maybe<AggregateEqmServiceCardInput>;
};


export type QueryEqmServiceTypeArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
};


export type QueryEqmServiceTypesCountArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
};


export type QueryEqmServiceTypesManyArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmServiceTypeInput>;
  aggregate?: Maybe<AggregateEqmServiceTypeInput>;
};


export type QueryEqmServiceTypesPageArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmServiceTypeInput>;
  aggregate?: Maybe<AggregateEqmServiceTypeInput>;
};


export type QueryEqmServiceTypesConnectionArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmServiceTypeInput>;
  aggregate?: Maybe<AggregateEqmServiceTypeInput>;
};


export type QueryEqmTypeFunctionalTestArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
};


export type QueryEqmTypeFunctionalTestsCountArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
};


export type QueryEqmTypeFunctionalTestsManyArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTypeFunctionalTestInput>;
  aggregate?: Maybe<AggregateEqmTypeFunctionalTestInput>;
};


export type QueryEqmTypeFunctionalTestsPageArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTypeFunctionalTestInput>;
  aggregate?: Maybe<AggregateEqmTypeFunctionalTestInput>;
};


export type QueryEqmTypeFunctionalTestsConnectionArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTypeFunctionalTestInput>;
  aggregate?: Maybe<AggregateEqmTypeFunctionalTestInput>;
};


export type QueryEqmWorkHeaderArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
};


export type QueryEqmWorkHeadersCountArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
};


export type QueryEqmWorkHeadersManyArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmWorkHeaderInput>;
  aggregate?: Maybe<AggregateEqmWorkHeaderInput>;
};


export type QueryEqmWorkHeadersPageArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmWorkHeaderInput>;
  aggregate?: Maybe<AggregateEqmWorkHeaderInput>;
};


export type QueryEqmWorkHeadersConnectionArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmWorkHeaderInput>;
  aggregate?: Maybe<AggregateEqmWorkHeaderInput>;
};


export type QueryEqmWorkLineArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
};


export type QueryEqmWorkLinesCountArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
};


export type QueryEqmWorkLinesManyArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmWorkLineInput>;
  aggregate?: Maybe<AggregateEqmWorkLineInput>;
};


export type QueryEqmWorkLinesPageArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmWorkLineInput>;
  aggregate?: Maybe<AggregateEqmWorkLineInput>;
};


export type QueryEqmWorkLinesConnectionArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmWorkLineInput>;
  aggregate?: Maybe<AggregateEqmWorkLineInput>;
};


export type QueryEqmTransferHeaderArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
};


export type QueryEqmTransferHeadersCountArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
};


export type QueryEqmTransferHeadersManyArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTransferHeaderInput>;
  aggregate?: Maybe<AggregateEqmTransferHeaderInput>;
};


export type QueryEqmTransferHeadersPageArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTransferHeaderInput>;
  aggregate?: Maybe<AggregateEqmTransferHeaderInput>;
};


export type QueryEqmTransferHeadersConnectionArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTransferHeaderInput>;
  aggregate?: Maybe<AggregateEqmTransferHeaderInput>;
};


export type QueryEqmTransferLineArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
};


export type QueryEqmTransferLinesCountArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
};


export type QueryEqmTransferLinesManyArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTransferLineInput>;
  aggregate?: Maybe<AggregateEqmTransferLineInput>;
};


export type QueryEqmTransferLinesPageArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTransferLineInput>;
  aggregate?: Maybe<AggregateEqmTransferLineInput>;
};


export type QueryEqmTransferLinesConnectionArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortEqmTransferLineInput>;
  aggregate?: Maybe<AggregateEqmTransferLineInput>;
};


export type QueryErrandArgs = {
  filter?: Maybe<FilterErrandInput>;
};


export type QueryErrandsCountArgs = {
  filter?: Maybe<FilterErrandInput>;
};


export type QueryErrandsManyArgs = {
  filter?: Maybe<FilterErrandInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrandInput>;
  aggregate?: Maybe<AggregateErrandInput>;
};


export type QueryErrandsPageArgs = {
  filter?: Maybe<FilterErrandInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrandInput>;
  aggregate?: Maybe<AggregateErrandInput>;
};


export type QueryErrandsConnectionArgs = {
  filter?: Maybe<FilterErrandInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrandInput>;
  aggregate?: Maybe<AggregateErrandInput>;
};


export type QueryErrandLineArgs = {
  filter?: Maybe<FilterErrandLineInput>;
};


export type QueryErrandLinesCountArgs = {
  filter?: Maybe<FilterErrandLineInput>;
};


export type QueryErrandLinesManyArgs = {
  filter?: Maybe<FilterErrandLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrandLineInput>;
  aggregate?: Maybe<AggregateErrandLineInput>;
};


export type QueryErrandLinesPageArgs = {
  filter?: Maybe<FilterErrandLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrandLineInput>;
  aggregate?: Maybe<AggregateErrandLineInput>;
};


export type QueryErrandLinesConnectionArgs = {
  filter?: Maybe<FilterErrandLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrandLineInput>;
  aggregate?: Maybe<AggregateErrandLineInput>;
};


export type QueryErrorReportArgs = {
  filter?: Maybe<FilterErrorReportInput>;
};


export type QueryErrorReportsCountArgs = {
  filter?: Maybe<FilterErrorReportInput>;
};


export type QueryErrorReportsManyArgs = {
  filter?: Maybe<FilterErrorReportInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrorReportInput>;
  aggregate?: Maybe<AggregateErrorReportInput>;
};


export type QueryErrorReportsPageArgs = {
  filter?: Maybe<FilterErrorReportInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrorReportInput>;
  aggregate?: Maybe<AggregateErrorReportInput>;
};


export type QueryErrorReportsConnectionArgs = {
  filter?: Maybe<FilterErrorReportInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortErrorReportInput>;
  aggregate?: Maybe<AggregateErrorReportInput>;
};


export type QueryFeedbackArgs = {
  filter?: Maybe<FilterFeedbackInput>;
};


export type QueryFeedbacksCountArgs = {
  filter?: Maybe<FilterFeedbackInput>;
};


export type QueryFeedbacksManyArgs = {
  filter?: Maybe<FilterFeedbackInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFeedbackInput>;
  aggregate?: Maybe<AggregateFeedbackInput>;
};


export type QueryFeedbacksPageArgs = {
  filter?: Maybe<FilterFeedbackInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFeedbackInput>;
  aggregate?: Maybe<AggregateFeedbackInput>;
};


export type QueryFeedbacksConnectionArgs = {
  filter?: Maybe<FilterFeedbackInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFeedbackInput>;
  aggregate?: Maybe<AggregateFeedbackInput>;
};


export type QueryFeemappingArgs = {
  filter?: Maybe<FilterFeemappingInput>;
};


export type QueryFeemappingsCountArgs = {
  filter?: Maybe<FilterFeemappingInput>;
};


export type QueryFeemappingsManyArgs = {
  filter?: Maybe<FilterFeemappingInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFeemappingInput>;
  aggregate?: Maybe<AggregateFeemappingInput>;
};


export type QueryFeemappingsPageArgs = {
  filter?: Maybe<FilterFeemappingInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFeemappingInput>;
  aggregate?: Maybe<AggregateFeemappingInput>;
};


export type QueryFeemappingsConnectionArgs = {
  filter?: Maybe<FilterFeemappingInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFeemappingInput>;
  aggregate?: Maybe<AggregateFeemappingInput>;
};


export type QueryFieldOrderArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
};


export type QueryFieldOrdersCountArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
};


export type QueryFieldOrdersManyArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFieldOrderInput>;
  aggregate?: Maybe<AggregateFieldOrderInput>;
};


export type QueryFieldOrdersPageArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFieldOrderInput>;
  aggregate?: Maybe<AggregateFieldOrderInput>;
};


export type QueryFieldOrdersConnectionArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFieldOrderInput>;
  aggregate?: Maybe<AggregateFieldOrderInput>;
};


export type QueryFieldOrderLineArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
};


export type QueryFieldOrderLinesCountArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
};


export type QueryFieldOrderLinesManyArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFieldOrderLineInput>;
  aggregate?: Maybe<AggregateFieldOrderLineInput>;
};


export type QueryFieldOrderLinesPageArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFieldOrderLineInput>;
  aggregate?: Maybe<AggregateFieldOrderLineInput>;
};


export type QueryFieldOrderLinesConnectionArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFieldOrderLineInput>;
  aggregate?: Maybe<AggregateFieldOrderLineInput>;
};


export type QueryFileArgs = {
  filter?: Maybe<FilterFileInput>;
};


export type QueryFilesCountArgs = {
  filter?: Maybe<FilterFileInput>;
};


export type QueryFilesManyArgs = {
  filter?: Maybe<FilterFileInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFileInput>;
  aggregate?: Maybe<AggregateFileInput>;
};


export type QueryFilesPageArgs = {
  filter?: Maybe<FilterFileInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFileInput>;
  aggregate?: Maybe<AggregateFileInput>;
};


export type QueryFilesConnectionArgs = {
  filter?: Maybe<FilterFileInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFileInput>;
  aggregate?: Maybe<AggregateFileInput>;
};


export type QueryFunctionTestHeaderArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
};


export type QueryFunctionTestHeadersCountArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
};


export type QueryFunctionTestHeadersManyArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFunctionTestHeaderInput>;
  aggregate?: Maybe<AggregateFunctionTestHeaderInput>;
};


export type QueryFunctionTestHeadersPageArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFunctionTestHeaderInput>;
  aggregate?: Maybe<AggregateFunctionTestHeaderInput>;
};


export type QueryFunctionTestHeadersConnectionArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFunctionTestHeaderInput>;
  aggregate?: Maybe<AggregateFunctionTestHeaderInput>;
};


export type QueryFunctionTestLineArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
};


export type QueryFunctionTestLinesCountArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
};


export type QueryFunctionTestLinesManyArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFunctionTestLineInput>;
  aggregate?: Maybe<AggregateFunctionTestLineInput>;
};


export type QueryFunctionTestLinesPageArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFunctionTestLineInput>;
  aggregate?: Maybe<AggregateFunctionTestLineInput>;
};


export type QueryFunctionTestLinesConnectionArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFunctionTestLineInput>;
  aggregate?: Maybe<AggregateFunctionTestLineInput>;
};


export type QueryGenBusinessPostingGroupArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
};


export type QueryGenBusinessPostingGroupsCountArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
};


export type QueryGenBusinessPostingGroupsManyArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenBusinessPostingGroupInput>;
  aggregate?: Maybe<AggregateGenBusinessPostingGroupInput>;
};


export type QueryGenBusinessPostingGroupsPageArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenBusinessPostingGroupInput>;
  aggregate?: Maybe<AggregateGenBusinessPostingGroupInput>;
};


export type QueryGenBusinessPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenBusinessPostingGroupInput>;
  aggregate?: Maybe<AggregateGenBusinessPostingGroupInput>;
};


export type QueryGenJournalLineArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
};


export type QueryGenJournalLinesCountArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
};


export type QueryGenJournalLinesManyArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenJournalLineInput>;
  aggregate?: Maybe<AggregateGenJournalLineInput>;
};


export type QueryGenJournalLinesPageArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenJournalLineInput>;
  aggregate?: Maybe<AggregateGenJournalLineInput>;
};


export type QueryGenJournalLinesConnectionArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenJournalLineInput>;
  aggregate?: Maybe<AggregateGenJournalLineInput>;
};


export type QueryGenProductPostingGroupArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
};


export type QueryGenProductPostingGroupsCountArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
};


export type QueryGenProductPostingGroupsManyArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenProductPostingGroupInput>;
  aggregate?: Maybe<AggregateGenProductPostingGroupInput>;
};


export type QueryGenProductPostingGroupsPageArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenProductPostingGroupInput>;
  aggregate?: Maybe<AggregateGenProductPostingGroupInput>;
};


export type QueryGenProductPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortGenProductPostingGroupInput>;
  aggregate?: Maybe<AggregateGenProductPostingGroupInput>;
};


export type QueryInspectionArgs = {
  filter?: Maybe<FilterInspectionInput>;
};


export type QueryInspectionsCountArgs = {
  filter?: Maybe<FilterInspectionInput>;
};


export type QueryInspectionsManyArgs = {
  filter?: Maybe<FilterInspectionInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInspectionInput>;
  aggregate?: Maybe<AggregateInspectionInput>;
};


export type QueryInspectionsPageArgs = {
  filter?: Maybe<FilterInspectionInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInspectionInput>;
  aggregate?: Maybe<AggregateInspectionInput>;
};


export type QueryInspectionsConnectionArgs = {
  filter?: Maybe<FilterInspectionInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInspectionInput>;
  aggregate?: Maybe<AggregateInspectionInput>;
};


export type QueryInspectionUserArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
};


export type QueryInspectionUsersCountArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
};


export type QueryInspectionUsersManyArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInspectionUserInput>;
  aggregate?: Maybe<AggregateInspectionUserInput>;
};


export type QueryInspectionUsersPageArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInspectionUserInput>;
  aggregate?: Maybe<AggregateInspectionUserInput>;
};


export type QueryInspectionUsersConnectionArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInspectionUserInput>;
  aggregate?: Maybe<AggregateInspectionUserInput>;
};


export type QueryIntegrationCollectionArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
};


export type QueryIntegrationCollectionsCountArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
};


export type QueryIntegrationCollectionsManyArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortIntegrationCollectionInput>;
  aggregate?: Maybe<AggregateIntegrationCollectionInput>;
};


export type QueryIntegrationCollectionsPageArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortIntegrationCollectionInput>;
  aggregate?: Maybe<AggregateIntegrationCollectionInput>;
};


export type QueryIntegrationCollectionsConnectionArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortIntegrationCollectionInput>;
  aggregate?: Maybe<AggregateIntegrationCollectionInput>;
};


export type QueryInventoryPostingGroupArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
};


export type QueryInventoryPostingGroupsCountArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
};


export type QueryInventoryPostingGroupsManyArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInventoryPostingGroupInput>;
  aggregate?: Maybe<AggregateInventoryPostingGroupInput>;
};


export type QueryInventoryPostingGroupsPageArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInventoryPostingGroupInput>;
  aggregate?: Maybe<AggregateInventoryPostingGroupInput>;
};


export type QueryInventoryPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInventoryPostingGroupInput>;
  aggregate?: Maybe<AggregateInventoryPostingGroupInput>;
};


export type QueryInvoiceHeaderArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
};


export type QueryInvoiceHeadersCountArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
};


export type QueryInvoiceHeadersManyArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceHeaderInput>;
  aggregate?: Maybe<AggregateInvoiceHeaderInput>;
};


export type QueryInvoiceHeadersPageArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceHeaderInput>;
  aggregate?: Maybe<AggregateInvoiceHeaderInput>;
};


export type QueryInvoiceHeadersConnectionArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceHeaderInput>;
  aggregate?: Maybe<AggregateInvoiceHeaderInput>;
};


export type QueryInvoiceLineArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
};


export type QueryInvoiceLinesCountArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
};


export type QueryInvoiceLinesManyArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceLineInput>;
  aggregate?: Maybe<AggregateInvoiceLineInput>;
};


export type QueryInvoiceLinesPageArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceLineInput>;
  aggregate?: Maybe<AggregateInvoiceLineInput>;
};


export type QueryInvoiceLinesConnectionArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceLineInput>;
  aggregate?: Maybe<AggregateInvoiceLineInput>;
};


export type QueryItemArgs = {
  filter?: Maybe<FilterItemInput>;
};


export type QueryItemsCountArgs = {
  filter?: Maybe<FilterItemInput>;
};


export type QueryItemsManyArgs = {
  filter?: Maybe<FilterItemInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemInput>;
  aggregate?: Maybe<AggregateItemInput>;
};


export type QueryItemsPageArgs = {
  filter?: Maybe<FilterItemInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemInput>;
  aggregate?: Maybe<AggregateItemInput>;
};


export type QueryItemsConnectionArgs = {
  filter?: Maybe<FilterItemInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemInput>;
  aggregate?: Maybe<AggregateItemInput>;
};


export type QueryItemCategoryArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
};


export type QueryItemCategoriesCountArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
};


export type QueryItemCategoriesManyArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemCategoryInput>;
  aggregate?: Maybe<AggregateItemCategoryInput>;
};


export type QueryItemCategoriesPageArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemCategoryInput>;
  aggregate?: Maybe<AggregateItemCategoryInput>;
};


export type QueryItemCategoriesConnectionArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemCategoryInput>;
  aggregate?: Maybe<AggregateItemCategoryInput>;
};


export type QueryItemDiscGroupArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
};


export type QueryItemDiscGroupsCountArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
};


export type QueryItemDiscGroupsManyArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemDiscGroupInput>;
  aggregate?: Maybe<AggregateItemDiscGroupInput>;
};


export type QueryItemDiscGroupsPageArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemDiscGroupInput>;
  aggregate?: Maybe<AggregateItemDiscGroupInput>;
};


export type QueryItemDiscGroupsConnectionArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemDiscGroupInput>;
  aggregate?: Maybe<AggregateItemDiscGroupInput>;
};


export type QueryItemReferenceArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
};


export type QueryItemReferencesCountArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
};


export type QueryItemReferencesManyArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemReferenceInput>;
  aggregate?: Maybe<AggregateItemReferenceInput>;
};


export type QueryItemReferencesPageArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemReferenceInput>;
  aggregate?: Maybe<AggregateItemReferenceInput>;
};


export type QueryItemReferencesConnectionArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemReferenceInput>;
  aggregate?: Maybe<AggregateItemReferenceInput>;
};


export type QueryItemRestrictionArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
};


export type QueryItemRestrictionsCountArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
};


export type QueryItemRestrictionsManyArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemRestrictionInput>;
  aggregate?: Maybe<AggregateItemRestrictionInput>;
};


export type QueryItemRestrictionsPageArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemRestrictionInput>;
  aggregate?: Maybe<AggregateItemRestrictionInput>;
};


export type QueryItemRestrictionsConnectionArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemRestrictionInput>;
  aggregate?: Maybe<AggregateItemRestrictionInput>;
};


export type QueryItemUnitOfMeasureArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
};


export type QueryItemUnitOfMeasuresCountArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
};


export type QueryItemUnitOfMeasuresManyArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemUnitOfMeasureInput>;
  aggregate?: Maybe<AggregateItemUnitOfMeasureInput>;
};


export type QueryItemUnitOfMeasuresPageArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemUnitOfMeasureInput>;
  aggregate?: Maybe<AggregateItemUnitOfMeasureInput>;
};


export type QueryItemUnitOfMeasuresConnectionArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemUnitOfMeasureInput>;
  aggregate?: Maybe<AggregateItemUnitOfMeasureInput>;
};


export type QueryItemVendorArgs = {
  filter?: Maybe<FilterItemVendorInput>;
};


export type QueryItemVendorsCountArgs = {
  filter?: Maybe<FilterItemVendorInput>;
};


export type QueryItemVendorsManyArgs = {
  filter?: Maybe<FilterItemVendorInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemVendorInput>;
  aggregate?: Maybe<AggregateItemVendorInput>;
};


export type QueryItemVendorsPageArgs = {
  filter?: Maybe<FilterItemVendorInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemVendorInput>;
  aggregate?: Maybe<AggregateItemVendorInput>;
};


export type QueryItemVendorsConnectionArgs = {
  filter?: Maybe<FilterItemVendorInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortItemVendorInput>;
  aggregate?: Maybe<AggregateItemVendorInput>;
};


export type QueryJobTaskArgs = {
  filter?: Maybe<FilterJobTaskInput>;
};


export type QueryJobTasksCountArgs = {
  filter?: Maybe<FilterJobTaskInput>;
};


export type QueryJobTasksManyArgs = {
  filter?: Maybe<FilterJobTaskInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortJobTaskInput>;
  aggregate?: Maybe<AggregateJobTaskInput>;
};


export type QueryJobTasksPageArgs = {
  filter?: Maybe<FilterJobTaskInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortJobTaskInput>;
  aggregate?: Maybe<AggregateJobTaskInput>;
};


export type QueryJobTasksConnectionArgs = {
  filter?: Maybe<FilterJobTaskInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortJobTaskInput>;
  aggregate?: Maybe<AggregateJobTaskInput>;
};


export type QueryKliHeaderArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
};


export type QueryKliHeadersCountArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
};


export type QueryKliHeadersManyArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortKliHeaderInput>;
  aggregate?: Maybe<AggregateKliHeaderInput>;
};


export type QueryKliHeadersPageArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortKliHeaderInput>;
  aggregate?: Maybe<AggregateKliHeaderInput>;
};


export type QueryKliHeadersConnectionArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortKliHeaderInput>;
  aggregate?: Maybe<AggregateKliHeaderInput>;
};


export type QueryKliReasonCodeArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
};


export type QueryKliReasonCodesCountArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
};


export type QueryKliReasonCodesManyArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortKliReasonCodeInput>;
  aggregate?: Maybe<AggregateKliReasonCodeInput>;
};


export type QueryKliReasonCodesPageArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortKliReasonCodeInput>;
  aggregate?: Maybe<AggregateKliReasonCodeInput>;
};


export type QueryKliReasonCodesConnectionArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortKliReasonCodeInput>;
  aggregate?: Maybe<AggregateKliReasonCodeInput>;
};


export type QueryLocationArgs = {
  filter?: Maybe<FilterLocationInput>;
};


export type QueryLocationsCountArgs = {
  filter?: Maybe<FilterLocationInput>;
};


export type QueryLocationsManyArgs = {
  filter?: Maybe<FilterLocationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortLocationInput>;
  aggregate?: Maybe<AggregateLocationInput>;
};


export type QueryLocationsPageArgs = {
  filter?: Maybe<FilterLocationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortLocationInput>;
  aggregate?: Maybe<AggregateLocationInput>;
};


export type QueryLocationsConnectionArgs = {
  filter?: Maybe<FilterLocationInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortLocationInput>;
  aggregate?: Maybe<AggregateLocationInput>;
};


export type QueryLeadArgs = {
  filter?: Maybe<FilterLeadInput>;
};


export type QueryLeadsCountArgs = {
  filter?: Maybe<FilterLeadInput>;
};


export type QueryLeadsManyArgs = {
  filter?: Maybe<FilterLeadInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortLeadInput>;
  aggregate?: Maybe<AggregateLeadInput>;
};


export type QueryLeadsPageArgs = {
  filter?: Maybe<FilterLeadInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortLeadInput>;
  aggregate?: Maybe<AggregateLeadInput>;
};


export type QueryLeadsConnectionArgs = {
  filter?: Maybe<FilterLeadInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortLeadInput>;
  aggregate?: Maybe<AggregateLeadInput>;
};


export type QueryMagentoOrderStatusArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
};


export type QueryMagentoOrderStatusesCountArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
};


export type QueryMagentoOrderStatusesManyArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortMagentoOrderStatusInput>;
  aggregate?: Maybe<AggregateMagentoOrderStatusInput>;
};


export type QueryMagentoOrderStatusesPageArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortMagentoOrderStatusInput>;
  aggregate?: Maybe<AggregateMagentoOrderStatusInput>;
};


export type QueryMagentoOrderStatusesConnectionArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortMagentoOrderStatusInput>;
  aggregate?: Maybe<AggregateMagentoOrderStatusInput>;
};


export type QueryMagentoSettingsArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
};


export type QueryMagentoSettingsCountArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
};


export type QueryMagentoSettingsManyArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortMagentoSettingsInput>;
  aggregate?: Maybe<AggregateMagentoSettingsInput>;
};


export type QueryMagentoSettingsPageArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortMagentoSettingsInput>;
  aggregate?: Maybe<AggregateMagentoSettingsInput>;
};


export type QueryMagentoSettingsConnectionArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortMagentoSettingsInput>;
  aggregate?: Maybe<AggregateMagentoSettingsInput>;
};


export type QueryManufacturerArgs = {
  filter?: Maybe<FilterManufacturerInput>;
};


export type QueryManufacturersCountArgs = {
  filter?: Maybe<FilterManufacturerInput>;
};


export type QueryManufacturersManyArgs = {
  filter?: Maybe<FilterManufacturerInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortManufacturerInput>;
  aggregate?: Maybe<AggregateManufacturerInput>;
};


export type QueryManufacturersPageArgs = {
  filter?: Maybe<FilterManufacturerInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortManufacturerInput>;
  aggregate?: Maybe<AggregateManufacturerInput>;
};


export type QueryManufacturersConnectionArgs = {
  filter?: Maybe<FilterManufacturerInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortManufacturerInput>;
  aggregate?: Maybe<AggregateManufacturerInput>;
};


export type QueryNavUserArgs = {
  filter?: Maybe<FilterNavUserInput>;
};


export type QueryNavUsersCountArgs = {
  filter?: Maybe<FilterNavUserInput>;
};


export type QueryNavUsersManyArgs = {
  filter?: Maybe<FilterNavUserInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortNavUserInput>;
  aggregate?: Maybe<AggregateNavUserInput>;
};


export type QueryNavUsersPageArgs = {
  filter?: Maybe<FilterNavUserInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortNavUserInput>;
  aggregate?: Maybe<AggregateNavUserInput>;
};


export type QueryNavUsersConnectionArgs = {
  filter?: Maybe<FilterNavUserInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortNavUserInput>;
  aggregate?: Maybe<AggregateNavUserInput>;
};


export type QueryObjectArgs = {
  filter?: Maybe<FilterObjectInput>;
};


export type QueryObjectsCountArgs = {
  filter?: Maybe<FilterObjectInput>;
};


export type QueryObjectsManyArgs = {
  filter?: Maybe<FilterObjectInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectInput>;
  aggregate?: Maybe<AggregateObjectInput>;
};


export type QueryObjectsPageArgs = {
  filter?: Maybe<FilterObjectInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectInput>;
  aggregate?: Maybe<AggregateObjectInput>;
};


export type QueryObjectsConnectionArgs = {
  filter?: Maybe<FilterObjectInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectInput>;
  aggregate?: Maybe<AggregateObjectInput>;
};


export type QueryObjectCommentLineArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
};


export type QueryObjectCommentLinesCountArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
};


export type QueryObjectCommentLinesManyArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectCommentLineInput>;
  aggregate?: Maybe<AggregateObjectCommentLineInput>;
};


export type QueryObjectCommentLinesPageArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectCommentLineInput>;
  aggregate?: Maybe<AggregateObjectCommentLineInput>;
};


export type QueryObjectCommentLinesConnectionArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectCommentLineInput>;
  aggregate?: Maybe<AggregateObjectCommentLineInput>;
};


export type QueryObjectFamilyArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
};


export type QueryObjectFamiliesCountArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
};


export type QueryObjectFamiliesManyArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectFamilyInput>;
  aggregate?: Maybe<AggregateObjectFamilyInput>;
};


export type QueryObjectFamiliesPageArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectFamilyInput>;
  aggregate?: Maybe<AggregateObjectFamilyInput>;
};


export type QueryObjectFamiliesConnectionArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectFamilyInput>;
  aggregate?: Maybe<AggregateObjectFamilyInput>;
};


export type QueryObjectGroupArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
};


export type QueryObjectGroupsCountArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
};


export type QueryObjectGroupsManyArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectGroupInput>;
  aggregate?: Maybe<AggregateObjectGroupInput>;
};


export type QueryObjectGroupsPageArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectGroupInput>;
  aggregate?: Maybe<AggregateObjectGroupInput>;
};


export type QueryObjectGroupsConnectionArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectGroupInput>;
  aggregate?: Maybe<AggregateObjectGroupInput>;
};


export type QueryObjectReturnHeaderArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
};


export type QueryObjectReturnHeadersCountArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
};


export type QueryObjectReturnHeadersManyArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectReturnHeaderInput>;
  aggregate?: Maybe<AggregateObjectReturnHeaderInput>;
};


export type QueryObjectReturnHeadersPageArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectReturnHeaderInput>;
  aggregate?: Maybe<AggregateObjectReturnHeaderInput>;
};


export type QueryObjectReturnHeadersConnectionArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectReturnHeaderInput>;
  aggregate?: Maybe<AggregateObjectReturnHeaderInput>;
};


export type QueryObjectReturnLineArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
};


export type QueryObjectReturnLinesCountArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
};


export type QueryObjectReturnLinesManyArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectReturnLineInput>;
  aggregate?: Maybe<AggregateObjectReturnLineInput>;
};


export type QueryObjectReturnLinesPageArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectReturnLineInput>;
  aggregate?: Maybe<AggregateObjectReturnLineInput>;
};


export type QueryObjectReturnLinesConnectionArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectReturnLineInput>;
  aggregate?: Maybe<AggregateObjectReturnLineInput>;
};


export type QueryObjectTypeArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
};


export type QueryObjectTypesCountArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
};


export type QueryObjectTypesManyArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectTypeInput>;
  aggregate?: Maybe<AggregateObjectTypeInput>;
};


export type QueryObjectTypesPageArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectTypeInput>;
  aggregate?: Maybe<AggregateObjectTypeInput>;
};


export type QueryObjectTypesConnectionArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectTypeInput>;
  aggregate?: Maybe<AggregateObjectTypeInput>;
};


export type QueryOcrAnalysisArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
};


export type QueryOcrAnalysesCountArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
};


export type QueryOcrAnalysesManyArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortOcrAnalysisInput>;
  aggregate?: Maybe<AggregateOcrAnalysisInput>;
};


export type QueryOcrAnalysesPageArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortOcrAnalysisInput>;
  aggregate?: Maybe<AggregateOcrAnalysisInput>;
};


export type QueryOcrAnalysesConnectionArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortOcrAnalysisInput>;
  aggregate?: Maybe<AggregateOcrAnalysisInput>;
};


export type QueryPaymentMethodArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
};


export type QueryPaymentMethodsCountArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
};


export type QueryPaymentMethodsManyArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPaymentMethodInput>;
  aggregate?: Maybe<AggregatePaymentMethodInput>;
};


export type QueryPaymentMethodsPageArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPaymentMethodInput>;
  aggregate?: Maybe<AggregatePaymentMethodInput>;
};


export type QueryPaymentMethodsConnectionArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPaymentMethodInput>;
  aggregate?: Maybe<AggregatePaymentMethodInput>;
};


export type QueryPaymentTermsArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
};


export type QueryPaymentTermsCountArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
};


export type QueryPaymentTermsManyArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPaymentTermsInput>;
  aggregate?: Maybe<AggregatePaymentTermsInput>;
};


export type QueryPaymentTermsPageArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPaymentTermsInput>;
  aggregate?: Maybe<AggregatePaymentTermsInput>;
};


export type QueryPaymentTermsConnectionArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPaymentTermsInput>;
  aggregate?: Maybe<AggregatePaymentTermsInput>;
};


export type QueryPlatformFeatureArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
};


export type QueryPlatformFeaturesCountArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
};


export type QueryPlatformFeaturesManyArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPlatformFeatureInput>;
  aggregate?: Maybe<AggregatePlatformFeatureInput>;
};


export type QueryPlatformFeaturesPageArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPlatformFeatureInput>;
  aggregate?: Maybe<AggregatePlatformFeatureInput>;
};


export type QueryPlatformFeaturesConnectionArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPlatformFeatureInput>;
  aggregate?: Maybe<AggregatePlatformFeatureInput>;
};


export type QueryPostCodeArgs = {
  filter?: Maybe<FilterPostCodeInput>;
};


export type QueryPostCodesCountArgs = {
  filter?: Maybe<FilterPostCodeInput>;
};


export type QueryPostCodesManyArgs = {
  filter?: Maybe<FilterPostCodeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPostCodeInput>;
  aggregate?: Maybe<AggregatePostCodeInput>;
};


export type QueryPostCodesPageArgs = {
  filter?: Maybe<FilterPostCodeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPostCodeInput>;
  aggregate?: Maybe<AggregatePostCodeInput>;
};


export type QueryPostCodesConnectionArgs = {
  filter?: Maybe<FilterPostCodeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPostCodeInput>;
  aggregate?: Maybe<AggregatePostCodeInput>;
};


export type QueryProjectArgs = {
  filter?: Maybe<FilterProjectInput>;
};


export type QueryProjectsCountArgs = {
  filter?: Maybe<FilterProjectInput>;
};


export type QueryProjectsManyArgs = {
  filter?: Maybe<FilterProjectInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortProjectInput>;
  aggregate?: Maybe<AggregateProjectInput>;
};


export type QueryProjectsPageArgs = {
  filter?: Maybe<FilterProjectInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortProjectInput>;
  aggregate?: Maybe<AggregateProjectInput>;
};


export type QueryProjectsConnectionArgs = {
  filter?: Maybe<FilterProjectInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortProjectInput>;
  aggregate?: Maybe<AggregateProjectInput>;
};


export type QueryPurchaseHeaderArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
};


export type QueryPurchaseHeadersCountArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
};


export type QueryPurchaseHeadersManyArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchaseHeaderInput>;
  aggregate?: Maybe<AggregatePurchaseHeaderInput>;
};


export type QueryPurchaseHeadersPageArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchaseHeaderInput>;
  aggregate?: Maybe<AggregatePurchaseHeaderInput>;
};


export type QueryPurchaseHeadersConnectionArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchaseHeaderInput>;
  aggregate?: Maybe<AggregatePurchaseHeaderInput>;
};


export type QueryPurchaseLineArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
};


export type QueryPurchaseLinesCountArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
};


export type QueryPurchaseLinesManyArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchaseLineInput>;
  aggregate?: Maybe<AggregatePurchaseLineInput>;
};


export type QueryPurchaseLinesPageArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchaseLineInput>;
  aggregate?: Maybe<AggregatePurchaseLineInput>;
};


export type QueryPurchaseLinesConnectionArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchaseLineInput>;
  aggregate?: Maybe<AggregatePurchaseLineInput>;
};


export type QueryPurchasingArgs = {
  filter?: Maybe<FilterPurchasingInput>;
};


export type QueryPurchasingsCountArgs = {
  filter?: Maybe<FilterPurchasingInput>;
};


export type QueryPurchasingsManyArgs = {
  filter?: Maybe<FilterPurchasingInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchasingInput>;
  aggregate?: Maybe<AggregatePurchasingInput>;
};


export type QueryPurchasingsPageArgs = {
  filter?: Maybe<FilterPurchasingInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchasingInput>;
  aggregate?: Maybe<AggregatePurchasingInput>;
};


export type QueryPurchasingsConnectionArgs = {
  filter?: Maybe<FilterPurchasingInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchasingInput>;
  aggregate?: Maybe<AggregatePurchasingInput>;
};


export type QueryPurchCrMemoHeaderArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
};


export type QueryPurchCrMemoHeadersCountArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
};


export type QueryPurchCrMemoHeadersManyArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchCrMemoHeaderInput>;
  aggregate?: Maybe<AggregatePurchCrMemoHeaderInput>;
};


export type QueryPurchCrMemoHeadersPageArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchCrMemoHeaderInput>;
  aggregate?: Maybe<AggregatePurchCrMemoHeaderInput>;
};


export type QueryPurchCrMemoHeadersConnectionArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchCrMemoHeaderInput>;
  aggregate?: Maybe<AggregatePurchCrMemoHeaderInput>;
};


export type QueryPurchCrMemoLineArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
};


export type QueryPurchCrMemoLinesCountArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
};


export type QueryPurchCrMemoLinesManyArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchCrMemoLineInput>;
  aggregate?: Maybe<AggregatePurchCrMemoLineInput>;
};


export type QueryPurchCrMemoLinesPageArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchCrMemoLineInput>;
  aggregate?: Maybe<AggregatePurchCrMemoLineInput>;
};


export type QueryPurchCrMemoLinesConnectionArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchCrMemoLineInput>;
  aggregate?: Maybe<AggregatePurchCrMemoLineInput>;
};


export type QueryPurchInvHeaderArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
};


export type QueryPurchInvHeadersCountArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
};


export type QueryPurchInvHeadersManyArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchInvHeaderInput>;
  aggregate?: Maybe<AggregatePurchInvHeaderInput>;
};


export type QueryPurchInvHeadersPageArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchInvHeaderInput>;
  aggregate?: Maybe<AggregatePurchInvHeaderInput>;
};


export type QueryPurchInvHeadersConnectionArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchInvHeaderInput>;
  aggregate?: Maybe<AggregatePurchInvHeaderInput>;
};


export type QueryPurchInvLineArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
};


export type QueryPurchInvLinesCountArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
};


export type QueryPurchInvLinesManyArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchInvLineInput>;
  aggregate?: Maybe<AggregatePurchInvLineInput>;
};


export type QueryPurchInvLinesPageArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchInvLineInput>;
  aggregate?: Maybe<AggregatePurchInvLineInput>;
};


export type QueryPurchInvLinesConnectionArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchInvLineInput>;
  aggregate?: Maybe<AggregatePurchInvLineInput>;
};


export type QueryPurchRcptHeaderArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
};


export type QueryPurchRcptHeadersCountArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
};


export type QueryPurchRcptHeadersManyArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchRcptHeaderInput>;
  aggregate?: Maybe<AggregatePurchRcptHeaderInput>;
};


export type QueryPurchRcptHeadersPageArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchRcptHeaderInput>;
  aggregate?: Maybe<AggregatePurchRcptHeaderInput>;
};


export type QueryPurchRcptHeadersConnectionArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchRcptHeaderInput>;
  aggregate?: Maybe<AggregatePurchRcptHeaderInput>;
};


export type QueryPurchRcptLineArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
};


export type QueryPurchRcptLinesCountArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
};


export type QueryPurchRcptLinesManyArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchRcptLineInput>;
  aggregate?: Maybe<AggregatePurchRcptLineInput>;
};


export type QueryPurchRcptLinesPageArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchRcptLineInput>;
  aggregate?: Maybe<AggregatePurchRcptLineInput>;
};


export type QueryPurchRcptLinesConnectionArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortPurchRcptLineInput>;
  aggregate?: Maybe<AggregatePurchRcptLineInput>;
};


export type QueryQuoteHeaderArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
};


export type QueryQuoteHeadersCountArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
};


export type QueryQuoteHeadersManyArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteHeaderInput>;
  aggregate?: Maybe<AggregateQuoteHeaderInput>;
};


export type QueryQuoteHeadersPageArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteHeaderInput>;
  aggregate?: Maybe<AggregateQuoteHeaderInput>;
};


export type QueryQuoteHeadersConnectionArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteHeaderInput>;
  aggregate?: Maybe<AggregateQuoteHeaderInput>;
};


export type QueryQuoteLineArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
};


export type QueryQuoteLinesCountArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
};


export type QueryQuoteLinesManyArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteLineInput>;
  aggregate?: Maybe<AggregateQuoteLineInput>;
};


export type QueryQuoteLinesPageArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteLineInput>;
  aggregate?: Maybe<AggregateQuoteLineInput>;
};


export type QueryQuoteLinesConnectionArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteLineInput>;
  aggregate?: Maybe<AggregateQuoteLineInput>;
};


export type QueryQuoteTaskGroupArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
};


export type QueryQuoteTaskGroupsCountArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
};


export type QueryQuoteTaskGroupsManyArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteTaskGroupInput>;
  aggregate?: Maybe<AggregateQuoteTaskGroupInput>;
};


export type QueryQuoteTaskGroupsPageArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteTaskGroupInput>;
  aggregate?: Maybe<AggregateQuoteTaskGroupInput>;
};


export type QueryQuoteTaskGroupsConnectionArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortQuoteTaskGroupInput>;
  aggregate?: Maybe<AggregateQuoteTaskGroupInput>;
};


export type QueryRentalHeaderArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
};


export type QueryRentalHeadersCountArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
};


export type QueryRentalHeadersManyArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalHeaderInput>;
  aggregate?: Maybe<AggregateRentalHeaderInput>;
};


export type QueryRentalHeadersPageArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalHeaderInput>;
  aggregate?: Maybe<AggregateRentalHeaderInput>;
};


export type QueryRentalHeadersConnectionArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalHeaderInput>;
  aggregate?: Maybe<AggregateRentalHeaderInput>;
};


export type QueryRentalLineArgs = {
  filter?: Maybe<FilterRentalLineInput>;
};


export type QueryRentalLinesCountArgs = {
  filter?: Maybe<FilterRentalLineInput>;
};


export type QueryRentalLinesManyArgs = {
  filter?: Maybe<FilterRentalLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineInput>;
  aggregate?: Maybe<AggregateRentalLineInput>;
};


export type QueryRentalLinesPageArgs = {
  filter?: Maybe<FilterRentalLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineInput>;
  aggregate?: Maybe<AggregateRentalLineInput>;
};


export type QueryRentalLinesConnectionArgs = {
  filter?: Maybe<FilterRentalLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineInput>;
  aggregate?: Maybe<AggregateRentalLineInput>;
};


export type QueryRentalLineSnapshotArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
};


export type QueryRentalLineSnapshotsCountArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
};


export type QueryRentalLineSnapshotsManyArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineSnapshotInput>;
  aggregate?: Maybe<AggregateRentalLineSnapshotInput>;
};


export type QueryRentalLineSnapshotsPageArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineSnapshotInput>;
  aggregate?: Maybe<AggregateRentalLineSnapshotInput>;
};


export type QueryRentalLineSnapshotsConnectionArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineSnapshotInput>;
  aggregate?: Maybe<AggregateRentalLineSnapshotInput>;
};


export type QueryRentalPostedCollHeaderArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
};


export type QueryRentalPostedCollHeadersCountArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
};


export type QueryRentalPostedCollHeadersManyArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedCollHeaderInput>;
  aggregate?: Maybe<AggregateRentalPostedCollHeaderInput>;
};


export type QueryRentalPostedCollHeadersPageArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedCollHeaderInput>;
  aggregate?: Maybe<AggregateRentalPostedCollHeaderInput>;
};


export type QueryRentalPostedCollHeadersConnectionArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedCollHeaderInput>;
  aggregate?: Maybe<AggregateRentalPostedCollHeaderInput>;
};


export type QueryRentalPostedCollLineArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
};


export type QueryRentalPostedCollLinesCountArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
};


export type QueryRentalPostedCollLinesManyArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedCollLineInput>;
  aggregate?: Maybe<AggregateRentalPostedCollLineInput>;
};


export type QueryRentalPostedCollLinesPageArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedCollLineInput>;
  aggregate?: Maybe<AggregateRentalPostedCollLineInput>;
};


export type QueryRentalPostedCollLinesConnectionArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedCollLineInput>;
  aggregate?: Maybe<AggregateRentalPostedCollLineInput>;
};


export type QueryRentalPostedShptHeaderArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
};


export type QueryRentalPostedShptHeadersCountArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
};


export type QueryRentalPostedShptHeadersManyArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedShptHeaderInput>;
  aggregate?: Maybe<AggregateRentalPostedShptHeaderInput>;
};


export type QueryRentalPostedShptHeadersPageArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedShptHeaderInput>;
  aggregate?: Maybe<AggregateRentalPostedShptHeaderInput>;
};


export type QueryRentalPostedShptHeadersConnectionArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedShptHeaderInput>;
  aggregate?: Maybe<AggregateRentalPostedShptHeaderInput>;
};


export type QueryRentalPostedShptLineArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
};


export type QueryRentalPostedShptLinesCountArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
};


export type QueryRentalPostedShptLinesManyArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedShptLineInput>;
  aggregate?: Maybe<AggregateRentalPostedShptLineInput>;
};


export type QueryRentalPostedShptLinesPageArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedShptLineInput>;
  aggregate?: Maybe<AggregateRentalPostedShptLineInput>;
};


export type QueryRentalPostedShptLinesConnectionArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPostedShptLineInput>;
  aggregate?: Maybe<AggregateRentalPostedShptLineInput>;
};


export type QueryRentalPriceTermsArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
};


export type QueryRentalPriceTermsCountArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
};


export type QueryRentalPriceTermsManyArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPriceTermsInput>;
  aggregate?: Maybe<AggregateRentalPriceTermsInput>;
};


export type QueryRentalPriceTermsPageArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPriceTermsInput>;
  aggregate?: Maybe<AggregateRentalPriceTermsInput>;
};


export type QueryRentalPriceTermsConnectionArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalPriceTermsInput>;
  aggregate?: Maybe<AggregateRentalPriceTermsInput>;
};


export type QueryRentalReturnEntryArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
};


export type QueryRentalReturnEntriesCountArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
};


export type QueryRentalReturnEntriesManyArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalReturnEntryInput>;
  aggregate?: Maybe<AggregateRentalReturnEntryInput>;
};


export type QueryRentalReturnEntriesPageArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalReturnEntryInput>;
  aggregate?: Maybe<AggregateRentalReturnEntryInput>;
};


export type QueryRentalReturnEntriesConnectionArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalReturnEntryInput>;
  aggregate?: Maybe<AggregateRentalReturnEntryInput>;
};


export type QueryRequestHeaderArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
};


export type QueryRequestHeadersCountArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
};


export type QueryRequestHeadersManyArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRequestHeaderInput>;
  aggregate?: Maybe<AggregateRequestHeaderInput>;
};


export type QueryRequestHeadersPageArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRequestHeaderInput>;
  aggregate?: Maybe<AggregateRequestHeaderInput>;
};


export type QueryRequestHeadersConnectionArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRequestHeaderInput>;
  aggregate?: Maybe<AggregateRequestHeaderInput>;
};


export type QueryRequestLineArgs = {
  filter?: Maybe<FilterRequestLineInput>;
};


export type QueryRequestLinesCountArgs = {
  filter?: Maybe<FilterRequestLineInput>;
};


export type QueryRequestLinesManyArgs = {
  filter?: Maybe<FilterRequestLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRequestLineInput>;
  aggregate?: Maybe<AggregateRequestLineInput>;
};


export type QueryRequestLinesPageArgs = {
  filter?: Maybe<FilterRequestLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRequestLineInput>;
  aggregate?: Maybe<AggregateRequestLineInput>;
};


export type QueryRequestLinesConnectionArgs = {
  filter?: Maybe<FilterRequestLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRequestLineInput>;
  aggregate?: Maybe<AggregateRequestLineInput>;
};


export type QueryReservationRequestHeaderArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
};


export type QueryReservationRequestHeadersCountArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
};


export type QueryReservationRequestHeadersManyArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReservationRequestHeaderInput>;
  aggregate?: Maybe<AggregateReservationRequestHeaderInput>;
};


export type QueryReservationRequestHeadersPageArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReservationRequestHeaderInput>;
  aggregate?: Maybe<AggregateReservationRequestHeaderInput>;
};


export type QueryReservationRequestHeadersConnectionArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReservationRequestHeaderInput>;
  aggregate?: Maybe<AggregateReservationRequestHeaderInput>;
};


export type QueryReservationRequestLineArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
};


export type QueryReservationRequestLinesCountArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
};


export type QueryReservationRequestLinesManyArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReservationRequestLineInput>;
  aggregate?: Maybe<AggregateReservationRequestLineInput>;
};


export type QueryReservationRequestLinesPageArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReservationRequestLineInput>;
  aggregate?: Maybe<AggregateReservationRequestLineInput>;
};


export type QueryReservationRequestLinesConnectionArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReservationRequestLineInput>;
  aggregate?: Maybe<AggregateReservationRequestLineInput>;
};


export type QueryResourceArgs = {
  filter?: Maybe<FilterResourceInput>;
};


export type QueryResourcesCountArgs = {
  filter?: Maybe<FilterResourceInput>;
};


export type QueryResourcesManyArgs = {
  filter?: Maybe<FilterResourceInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortResourceInput>;
  aggregate?: Maybe<AggregateResourceInput>;
};


export type QueryResourcesPageArgs = {
  filter?: Maybe<FilterResourceInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortResourceInput>;
  aggregate?: Maybe<AggregateResourceInput>;
};


export type QueryResourcesConnectionArgs = {
  filter?: Maybe<FilterResourceInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortResourceInput>;
  aggregate?: Maybe<AggregateResourceInput>;
};


export type QueryResponsibilityCenterArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
};


export type QueryResponsibilityCentersCountArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
};


export type QueryResponsibilityCentersManyArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortResponsibilityCenterInput>;
  aggregate?: Maybe<AggregateResponsibilityCenterInput>;
};


export type QueryResponsibilityCentersPageArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortResponsibilityCenterInput>;
  aggregate?: Maybe<AggregateResponsibilityCenterInput>;
};


export type QueryResponsibilityCentersConnectionArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortResponsibilityCenterInput>;
  aggregate?: Maybe<AggregateResponsibilityCenterInput>;
};


export type QueryReturnRequestHeaderArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
};


export type QueryReturnRequestHeadersCountArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
};


export type QueryReturnRequestHeadersManyArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReturnRequestHeaderInput>;
  aggregate?: Maybe<AggregateReturnRequestHeaderInput>;
};


export type QueryReturnRequestHeadersPageArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReturnRequestHeaderInput>;
  aggregate?: Maybe<AggregateReturnRequestHeaderInput>;
};


export type QueryReturnRequestHeadersConnectionArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReturnRequestHeaderInput>;
  aggregate?: Maybe<AggregateReturnRequestHeaderInput>;
};


export type QueryReturnRequestLineArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
};


export type QueryReturnRequestLinesCountArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
};


export type QueryReturnRequestLinesManyArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReturnRequestLineInput>;
  aggregate?: Maybe<AggregateReturnRequestLineInput>;
};


export type QueryReturnRequestLinesPageArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReturnRequestLineInput>;
  aggregate?: Maybe<AggregateReturnRequestLineInput>;
};


export type QueryReturnRequestLinesConnectionArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortReturnRequestLineInput>;
  aggregate?: Maybe<AggregateReturnRequestLineInput>;
};


export type QueryRiskAnalysisHeaderArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
};


export type QueryRiskAnalysisHeadersCountArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
};


export type QueryRiskAnalysisHeadersManyArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisHeaderInput>;
  aggregate?: Maybe<AggregateRiskAnalysisHeaderInput>;
};


export type QueryRiskAnalysisHeadersPageArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisHeaderInput>;
  aggregate?: Maybe<AggregateRiskAnalysisHeaderInput>;
};


export type QueryRiskAnalysisHeadersConnectionArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisHeaderInput>;
  aggregate?: Maybe<AggregateRiskAnalysisHeaderInput>;
};


export type QueryRiskAnalysisLineArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
};


export type QueryRiskAnalysisLinesCountArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
};


export type QueryRiskAnalysisLinesManyArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisLineInput>;
  aggregate?: Maybe<AggregateRiskAnalysisLineInput>;
};


export type QueryRiskAnalysisLinesPageArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisLineInput>;
  aggregate?: Maybe<AggregateRiskAnalysisLineInput>;
};


export type QueryRiskAnalysisLinesConnectionArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisLineInput>;
  aggregate?: Maybe<AggregateRiskAnalysisLineInput>;
};


export type QueryRiskAnalysisSignatureArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
};


export type QueryRiskAnalysisSignaturesCountArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
};


export type QueryRiskAnalysisSignaturesManyArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisSignatureInput>;
  aggregate?: Maybe<AggregateRiskAnalysisSignatureInput>;
};


export type QueryRiskAnalysisSignaturesPageArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisSignatureInput>;
  aggregate?: Maybe<AggregateRiskAnalysisSignatureInput>;
};


export type QueryRiskAnalysisSignaturesConnectionArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRiskAnalysisSignatureInput>;
  aggregate?: Maybe<AggregateRiskAnalysisSignatureInput>;
};


export type QuerySalesHeaderArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
};


export type QuerySalesHeadersCountArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
};


export type QuerySalesHeadersManyArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderInput>;
  aggregate?: Maybe<AggregateSalesHeaderInput>;
};


export type QuerySalesHeadersPageArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderInput>;
  aggregate?: Maybe<AggregateSalesHeaderInput>;
};


export type QuerySalesHeadersConnectionArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderInput>;
  aggregate?: Maybe<AggregateSalesHeaderInput>;
};


export type QuerySalesHeaderArchiveArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
};


export type QuerySalesHeaderArchivesCountArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
};


export type QuerySalesHeaderArchivesManyArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderArchiveInput>;
  aggregate?: Maybe<AggregateSalesHeaderArchiveInput>;
};


export type QuerySalesHeaderArchivesPageArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderArchiveInput>;
  aggregate?: Maybe<AggregateSalesHeaderArchiveInput>;
};


export type QuerySalesHeaderArchivesConnectionArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderArchiveInput>;
  aggregate?: Maybe<AggregateSalesHeaderArchiveInput>;
};


export type QuerySalesHeaderHistoryArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
};


export type QuerySalesHeaderHistoriesCountArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
};


export type QuerySalesHeaderHistoriesManyArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderHistoryInput>;
  aggregate?: Maybe<AggregateSalesHeaderHistoryInput>;
};


export type QuerySalesHeaderHistoriesPageArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderHistoryInput>;
  aggregate?: Maybe<AggregateSalesHeaderHistoryInput>;
};


export type QuerySalesHeaderHistoriesConnectionArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderHistoryInput>;
  aggregate?: Maybe<AggregateSalesHeaderHistoryInput>;
};


export type QuerySalesHeaderRequestArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
};


export type QuerySalesHeaderRequestsCountArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
};


export type QuerySalesHeaderRequestsManyArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderRequestInput>;
  aggregate?: Maybe<AggregateSalesHeaderRequestInput>;
};


export type QuerySalesHeaderRequestsPageArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderRequestInput>;
  aggregate?: Maybe<AggregateSalesHeaderRequestInput>;
};


export type QuerySalesHeaderRequestsConnectionArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesHeaderRequestInput>;
  aggregate?: Maybe<AggregateSalesHeaderRequestInput>;
};


export type QuerySalesLineArgs = {
  filter?: Maybe<FilterSalesLineInput>;
};


export type QuerySalesLinesCountArgs = {
  filter?: Maybe<FilterSalesLineInput>;
};


export type QuerySalesLinesManyArgs = {
  filter?: Maybe<FilterSalesLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineInput>;
  aggregate?: Maybe<AggregateSalesLineInput>;
};


export type QuerySalesLinesPageArgs = {
  filter?: Maybe<FilterSalesLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineInput>;
  aggregate?: Maybe<AggregateSalesLineInput>;
};


export type QuerySalesLinesConnectionArgs = {
  filter?: Maybe<FilterSalesLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineInput>;
  aggregate?: Maybe<AggregateSalesLineInput>;
};


export type QuerySalesLineArchiveArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
};


export type QuerySalesLineArchivesCountArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
};


export type QuerySalesLineArchivesManyArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineArchiveInput>;
  aggregate?: Maybe<AggregateSalesLineArchiveInput>;
};


export type QuerySalesLineArchivesPageArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineArchiveInput>;
  aggregate?: Maybe<AggregateSalesLineArchiveInput>;
};


export type QuerySalesLineArchivesConnectionArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineArchiveInput>;
  aggregate?: Maybe<AggregateSalesLineArchiveInput>;
};


export type QuerySalesLineRequestArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
};


export type QuerySalesLineRequestsCountArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
};


export type QuerySalesLineRequestsManyArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineRequestInput>;
  aggregate?: Maybe<AggregateSalesLineRequestInput>;
};


export type QuerySalesLineRequestsPageArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineRequestInput>;
  aggregate?: Maybe<AggregateSalesLineRequestInput>;
};


export type QuerySalesLineRequestsConnectionArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesLineRequestInput>;
  aggregate?: Maybe<AggregateSalesLineRequestInput>;
};


export type QuerySalespersonArgs = {
  filter?: Maybe<FilterSalespersonInput>;
};


export type QuerySalespeopleCountArgs = {
  filter?: Maybe<FilterSalespersonInput>;
};


export type QuerySalespeopleManyArgs = {
  filter?: Maybe<FilterSalespersonInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalespersonInput>;
  aggregate?: Maybe<AggregateSalespersonInput>;
};


export type QuerySalespeoplePageArgs = {
  filter?: Maybe<FilterSalespersonInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalespersonInput>;
  aggregate?: Maybe<AggregateSalespersonInput>;
};


export type QuerySalespeopleConnectionArgs = {
  filter?: Maybe<FilterSalespersonInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalespersonInput>;
  aggregate?: Maybe<AggregateSalespersonInput>;
};


export type QuerySalesPriceArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
};


export type QuerySalesPricesCountArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
};


export type QuerySalesPricesManyArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesPriceInput>;
  aggregate?: Maybe<AggregateSalesPriceInput>;
};


export type QuerySalesPricesPageArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesPriceInput>;
  aggregate?: Maybe<AggregateSalesPriceInput>;
};


export type QuerySalesPricesConnectionArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesPriceInput>;
  aggregate?: Maybe<AggregateSalesPriceInput>;
};


export type QuerySalesShipmentHeaderArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
};


export type QuerySalesShipmentHeadersCountArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
};


export type QuerySalesShipmentHeadersManyArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesShipmentHeaderInput>;
  aggregate?: Maybe<AggregateSalesShipmentHeaderInput>;
};


export type QuerySalesShipmentHeadersPageArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesShipmentHeaderInput>;
  aggregate?: Maybe<AggregateSalesShipmentHeaderInput>;
};


export type QuerySalesShipmentHeadersConnectionArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesShipmentHeaderInput>;
  aggregate?: Maybe<AggregateSalesShipmentHeaderInput>;
};


export type QuerySalesShipmentLineArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
};


export type QuerySalesShipmentLinesCountArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
};


export type QuerySalesShipmentLinesManyArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesShipmentLineInput>;
  aggregate?: Maybe<AggregateSalesShipmentLineInput>;
};


export type QuerySalesShipmentLinesPageArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesShipmentLineInput>;
  aggregate?: Maybe<AggregateSalesShipmentLineInput>;
};


export type QuerySalesShipmentLinesConnectionArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSalesShipmentLineInput>;
  aggregate?: Maybe<AggregateSalesShipmentLineInput>;
};


export type QueryServiceItemGroupArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
};


export type QueryServiceItemGroupsCountArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
};


export type QueryServiceItemGroupsManyArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortServiceItemGroupInput>;
  aggregate?: Maybe<AggregateServiceItemGroupInput>;
};


export type QueryServiceItemGroupsPageArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortServiceItemGroupInput>;
  aggregate?: Maybe<AggregateServiceItemGroupInput>;
};


export type QueryServiceItemGroupsConnectionArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortServiceItemGroupInput>;
  aggregate?: Maybe<AggregateServiceItemGroupInput>;
};


export type QueryShipmentMethodArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
};


export type QueryShipmentMethodsCountArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
};


export type QueryShipmentMethodsManyArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortShipmentMethodInput>;
  aggregate?: Maybe<AggregateShipmentMethodInput>;
};


export type QueryShipmentMethodsPageArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortShipmentMethodInput>;
  aggregate?: Maybe<AggregateShipmentMethodInput>;
};


export type QueryShipmentMethodsConnectionArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortShipmentMethodInput>;
  aggregate?: Maybe<AggregateShipmentMethodInput>;
};


export type QueryShippingAgentArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
};


export type QueryShippingAgentsCountArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
};


export type QueryShippingAgentsManyArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortShippingAgentInput>;
  aggregate?: Maybe<AggregateShippingAgentInput>;
};


export type QueryShippingAgentsPageArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortShippingAgentInput>;
  aggregate?: Maybe<AggregateShippingAgentInput>;
};


export type QueryShippingAgentsConnectionArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortShippingAgentInput>;
  aggregate?: Maybe<AggregateShippingAgentInput>;
};


export type QuerySignatureArgs = {
  filter?: Maybe<FilterSignatureInput>;
};


export type QuerySignaturesCountArgs = {
  filter?: Maybe<FilterSignatureInput>;
};


export type QuerySignaturesManyArgs = {
  filter?: Maybe<FilterSignatureInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSignatureInput>;
  aggregate?: Maybe<AggregateSignatureInput>;
};


export type QuerySignaturesPageArgs = {
  filter?: Maybe<FilterSignatureInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSignatureInput>;
  aggregate?: Maybe<AggregateSignatureInput>;
};


export type QuerySignaturesConnectionArgs = {
  filter?: Maybe<FilterSignatureInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSignatureInput>;
  aggregate?: Maybe<AggregateSignatureInput>;
};


export type QuerySignRequestArgs = {
  filter?: Maybe<FilterSignRequestInput>;
};


export type QuerySignRequestsCountArgs = {
  filter?: Maybe<FilterSignRequestInput>;
};


export type QuerySignRequestsManyArgs = {
  filter?: Maybe<FilterSignRequestInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSignRequestInput>;
  aggregate?: Maybe<AggregateSignRequestInput>;
};


export type QuerySignRequestsPageArgs = {
  filter?: Maybe<FilterSignRequestInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSignRequestInput>;
  aggregate?: Maybe<AggregateSignRequestInput>;
};


export type QuerySignRequestsConnectionArgs = {
  filter?: Maybe<FilterSignRequestInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSignRequestInput>;
  aggregate?: Maybe<AggregateSignRequestInput>;
};


export type QuerySpecialEquipmentArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
};


export type QuerySpecialEquipmentsCountArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
};


export type QuerySpecialEquipmentsManyArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSpecialEquipmentInput>;
  aggregate?: Maybe<AggregateSpecialEquipmentInput>;
};


export type QuerySpecialEquipmentsPageArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSpecialEquipmentInput>;
  aggregate?: Maybe<AggregateSpecialEquipmentInput>;
};


export type QuerySpecialEquipmentsConnectionArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortSpecialEquipmentInput>;
  aggregate?: Maybe<AggregateSpecialEquipmentInput>;
};


export type QueryStockkeepingUnitArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
};


export type QueryStockkeepingUnitsCountArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
};


export type QueryStockkeepingUnitsManyArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortStockkeepingUnitInput>;
  aggregate?: Maybe<AggregateStockkeepingUnitInput>;
};


export type QueryStockkeepingUnitsPageArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortStockkeepingUnitInput>;
  aggregate?: Maybe<AggregateStockkeepingUnitInput>;
};


export type QueryStockkeepingUnitsConnectionArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortStockkeepingUnitInput>;
  aggregate?: Maybe<AggregateStockkeepingUnitInput>;
};


export type QueryTariffNumberArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
};


export type QueryTariffNumbersCountArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
};


export type QueryTariffNumbersManyArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTariffNumberInput>;
  aggregate?: Maybe<AggregateTariffNumberInput>;
};


export type QueryTariffNumbersPageArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTariffNumberInput>;
  aggregate?: Maybe<AggregateTariffNumberInput>;
};


export type QueryTariffNumbersConnectionArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTariffNumberInput>;
  aggregate?: Maybe<AggregateTariffNumberInput>;
};


export type QueryTaxGroupArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
};


export type QueryTaxGroupsCountArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
};


export type QueryTaxGroupsManyArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTaxGroupInput>;
  aggregate?: Maybe<AggregateTaxGroupInput>;
};


export type QueryTaxGroupsPageArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTaxGroupInput>;
  aggregate?: Maybe<AggregateTaxGroupInput>;
};


export type QueryTaxGroupsConnectionArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTaxGroupInput>;
  aggregate?: Maybe<AggregateTaxGroupInput>;
};


export type QueryTimeRegistrationArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
};


export type QueryTimeRegistrationsCountArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
};


export type QueryTimeRegistrationsManyArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeRegistrationInput>;
  aggregate?: Maybe<AggregateTimeRegistrationInput>;
};


export type QueryTimeRegistrationsPageArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeRegistrationInput>;
  aggregate?: Maybe<AggregateTimeRegistrationInput>;
};


export type QueryTimeRegistrationsConnectionArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeRegistrationInput>;
  aggregate?: Maybe<AggregateTimeRegistrationInput>;
};


export type QueryTimeSheetDetailArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
};


export type QueryTimeSheetDetailsCountArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
};


export type QueryTimeSheetDetailsManyArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeSheetDetailInput>;
  aggregate?: Maybe<AggregateTimeSheetDetailInput>;
};


export type QueryTimeSheetDetailsPageArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeSheetDetailInput>;
  aggregate?: Maybe<AggregateTimeSheetDetailInput>;
};


export type QueryTimeSheetDetailsConnectionArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeSheetDetailInput>;
  aggregate?: Maybe<AggregateTimeSheetDetailInput>;
};


export type QueryTimeSheetLineArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
};


export type QueryTimeSheetLinesCountArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
};


export type QueryTimeSheetLinesManyArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeSheetLineInput>;
  aggregate?: Maybe<AggregateTimeSheetLineInput>;
};


export type QueryTimeSheetLinesPageArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeSheetLineInput>;
  aggregate?: Maybe<AggregateTimeSheetLineInput>;
};


export type QueryTimeSheetLinesConnectionArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTimeSheetLineInput>;
  aggregate?: Maybe<AggregateTimeSheetLineInput>;
};


export type QueryTransactionSpecificationArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
};


export type QueryTransactionSpecificationsCountArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
};


export type QueryTransactionSpecificationsManyArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransactionSpecificationInput>;
  aggregate?: Maybe<AggregateTransactionSpecificationInput>;
};


export type QueryTransactionSpecificationsPageArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransactionSpecificationInput>;
  aggregate?: Maybe<AggregateTransactionSpecificationInput>;
};


export type QueryTransactionSpecificationsConnectionArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransactionSpecificationInput>;
  aggregate?: Maybe<AggregateTransactionSpecificationInput>;
};


export type QueryTransactionTypeArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
};


export type QueryTransactionTypesCountArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
};


export type QueryTransactionTypesManyArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransactionTypeInput>;
  aggregate?: Maybe<AggregateTransactionTypeInput>;
};


export type QueryTransactionTypesPageArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransactionTypeInput>;
  aggregate?: Maybe<AggregateTransactionTypeInput>;
};


export type QueryTransactionTypesConnectionArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransactionTypeInput>;
  aggregate?: Maybe<AggregateTransactionTypeInput>;
};


export type QueryTransportMethodArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
};


export type QueryTransportMethodsCountArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
};


export type QueryTransportMethodsManyArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransportMethodInput>;
  aggregate?: Maybe<AggregateTransportMethodInput>;
};


export type QueryTransportMethodsPageArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransportMethodInput>;
  aggregate?: Maybe<AggregateTransportMethodInput>;
};


export type QueryTransportMethodsConnectionArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortTransportMethodInput>;
  aggregate?: Maybe<AggregateTransportMethodInput>;
};


export type QueryUnitOfMeasureArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
};


export type QueryUnitOfMeasuresCountArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
};


export type QueryUnitOfMeasuresManyArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUnitOfMeasureInput>;
  aggregate?: Maybe<AggregateUnitOfMeasureInput>;
};


export type QueryUnitOfMeasuresPageArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUnitOfMeasureInput>;
  aggregate?: Maybe<AggregateUnitOfMeasureInput>;
};


export type QueryUnitOfMeasuresConnectionArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUnitOfMeasureInput>;
  aggregate?: Maybe<AggregateUnitOfMeasureInput>;
};


export type QueryUserArgs = {
  filter?: Maybe<FilterUserInput>;
};


export type QueryUsersCountArgs = {
  filter?: Maybe<FilterUserInput>;
};


export type QueryUsersManyArgs = {
  filter?: Maybe<FilterUserInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserInput>;
  aggregate?: Maybe<AggregateUserInput>;
};


export type QueryUsersPageArgs = {
  filter?: Maybe<FilterUserInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserInput>;
  aggregate?: Maybe<AggregateUserInput>;
};


export type QueryUsersConnectionArgs = {
  filter?: Maybe<FilterUserInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserInput>;
  aggregate?: Maybe<AggregateUserInput>;
};


export type QueryUserFavoritesArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
};


export type QueryUserFavoritesCountArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
};


export type QueryUserFavoritesManyArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserFavoritesInput>;
  aggregate?: Maybe<AggregateUserFavoritesInput>;
};


export type QueryUserFavoritesPageArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserFavoritesInput>;
  aggregate?: Maybe<AggregateUserFavoritesInput>;
};


export type QueryUserFavoritesConnectionArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserFavoritesInput>;
  aggregate?: Maybe<AggregateUserFavoritesInput>;
};


export type QueryVatBusinessPostingGroupArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
};


export type QueryVatBusinessPostingGroupsCountArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
};


export type QueryVatBusinessPostingGroupsManyArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVatBusinessPostingGroupInput>;
  aggregate?: Maybe<AggregateVatBusinessPostingGroupInput>;
};


export type QueryVatBusinessPostingGroupsPageArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVatBusinessPostingGroupInput>;
  aggregate?: Maybe<AggregateVatBusinessPostingGroupInput>;
};


export type QueryVatBusinessPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVatBusinessPostingGroupInput>;
  aggregate?: Maybe<AggregateVatBusinessPostingGroupInput>;
};


export type QueryVatProductPostingGroupArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
};


export type QueryVatProductPostingGroupsCountArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
};


export type QueryVatProductPostingGroupsManyArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVatProductPostingGroupInput>;
  aggregate?: Maybe<AggregateVatProductPostingGroupInput>;
};


export type QueryVatProductPostingGroupsPageArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVatProductPostingGroupInput>;
  aggregate?: Maybe<AggregateVatProductPostingGroupInput>;
};


export type QueryVatProductPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVatProductPostingGroupInput>;
  aggregate?: Maybe<AggregateVatProductPostingGroupInput>;
};


export type QueryVendorArgs = {
  filter?: Maybe<FilterVendorInput>;
};


export type QueryVendorsCountArgs = {
  filter?: Maybe<FilterVendorInput>;
};


export type QueryVendorsManyArgs = {
  filter?: Maybe<FilterVendorInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorInput>;
  aggregate?: Maybe<AggregateVendorInput>;
};


export type QueryVendorsPageArgs = {
  filter?: Maybe<FilterVendorInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorInput>;
  aggregate?: Maybe<AggregateVendorInput>;
};


export type QueryVendorsConnectionArgs = {
  filter?: Maybe<FilterVendorInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorInput>;
  aggregate?: Maybe<AggregateVendorInput>;
};


export type QueryVendorBankAccountArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
};


export type QueryVendorBankAccountsCountArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
};


export type QueryVendorBankAccountsManyArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorBankAccountInput>;
  aggregate?: Maybe<AggregateVendorBankAccountInput>;
};


export type QueryVendorBankAccountsPageArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorBankAccountInput>;
  aggregate?: Maybe<AggregateVendorBankAccountInput>;
};


export type QueryVendorBankAccountsConnectionArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorBankAccountInput>;
  aggregate?: Maybe<AggregateVendorBankAccountInput>;
};


export type QueryVendorPostingGroupArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
};


export type QueryVendorPostingGroupsCountArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
};


export type QueryVendorPostingGroupsManyArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorPostingGroupInput>;
  aggregate?: Maybe<AggregateVendorPostingGroupInput>;
};


export type QueryVendorPostingGroupsPageArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorPostingGroupInput>;
  aggregate?: Maybe<AggregateVendorPostingGroupInput>;
};


export type QueryVendorPostingGroupsConnectionArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortVendorPostingGroupInput>;
  aggregate?: Maybe<AggregateVendorPostingGroupInput>;
};


export type QueryWarehouseClassArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
};


export type QueryWarehouseClassesCountArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
};


export type QueryWarehouseClassesManyArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWarehouseClassInput>;
  aggregate?: Maybe<AggregateWarehouseClassInput>;
};


export type QueryWarehouseClassesPageArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWarehouseClassInput>;
  aggregate?: Maybe<AggregateWarehouseClassInput>;
};


export type QueryWarehouseClassesConnectionArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWarehouseClassInput>;
  aggregate?: Maybe<AggregateWarehouseClassInput>;
};


export type QueryWebBookingArgs = {
  filter?: Maybe<FilterWebBookingInput>;
};


export type QueryWebBookingsCountArgs = {
  filter?: Maybe<FilterWebBookingInput>;
};


export type QueryWebBookingsManyArgs = {
  filter?: Maybe<FilterWebBookingInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWebBookingInput>;
  aggregate?: Maybe<AggregateWebBookingInput>;
};


export type QueryWebBookingsPageArgs = {
  filter?: Maybe<FilterWebBookingInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWebBookingInput>;
  aggregate?: Maybe<AggregateWebBookingInput>;
};


export type QueryWebBookingsConnectionArgs = {
  filter?: Maybe<FilterWebBookingInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWebBookingInput>;
  aggregate?: Maybe<AggregateWebBookingInput>;
};


export type QueryWebhookSubscriptionArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
};


export type QueryWebhookSubscriptionsCountArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
};


export type QueryWebhookSubscriptionsManyArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWebhookSubscriptionInput>;
  aggregate?: Maybe<AggregateWebhookSubscriptionInput>;
};


export type QueryWebhookSubscriptionsPageArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWebhookSubscriptionInput>;
  aggregate?: Maybe<AggregateWebhookSubscriptionInput>;
};


export type QueryWebhookSubscriptionsConnectionArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWebhookSubscriptionInput>;
  aggregate?: Maybe<AggregateWebhookSubscriptionInput>;
};


export type QueryWorkLogArgs = {
  filter?: Maybe<FilterWorkLogInput>;
};


export type QueryWorkLogsCountArgs = {
  filter?: Maybe<FilterWorkLogInput>;
};


export type QueryWorkLogsManyArgs = {
  filter?: Maybe<FilterWorkLogInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWorkLogInput>;
  aggregate?: Maybe<AggregateWorkLogInput>;
};


export type QueryWorkLogsPageArgs = {
  filter?: Maybe<FilterWorkLogInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWorkLogInput>;
  aggregate?: Maybe<AggregateWorkLogInput>;
};


export type QueryWorkLogsConnectionArgs = {
  filter?: Maybe<FilterWorkLogInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWorkLogInput>;
  aggregate?: Maybe<AggregateWorkLogInput>;
};


export type QueryWorkTypeArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
};


export type QueryWorkTypesCountArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
};


export type QueryWorkTypesManyArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWorkTypeInput>;
  aggregate?: Maybe<AggregateWorkTypeInput>;
};


export type QueryWorkTypesPageArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWorkTypeInput>;
  aggregate?: Maybe<AggregateWorkTypeInput>;
};


export type QueryWorkTypesConnectionArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortWorkTypeInput>;
  aggregate?: Maybe<AggregateWorkTypeInput>;
};


export type QueryCustomerDomainsLimitedArgs = {
  filter?: Maybe<CustomerDomainsLimitedInput>;
};


export type QueryDomainsLimitedArgs = {
  filter?: Maybe<DomainsLimitedInput>;
};


export type QueryDomainArgs = {
  filter?: Maybe<FilterDomainInput>;
};


export type QueryDomainsManyArgs = {
  filter?: Maybe<FilterDomainInput>;
  sort?: Maybe<SortDomainInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDomainsPageArgs = {
  filter?: Maybe<FilterDomainInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainInput>;
  aggregate?: Maybe<AggregateDomainInput>;
};


export type QueryDomainIntegrationArgs = {
  filter?: Maybe<FilterDomainIntegrationInput>;
};


export type QueryDomainIntegrationsManyArgs = {
  filter?: Maybe<FilterDomainIntegrationInput>;
  sort?: Maybe<SortDomainIntegrationInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMyCustomerProjectsArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerProjectInput>;
};


export type QueryDocumentsManyArgs = {
  filter?: Maybe<FilterDocumentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentInput>;
};


export type QueryDocumentArgs = {
  filter?: Maybe<FilterDocumentInput>;
};


export type QueryDocumentsPageArgs = {
  filter?: Maybe<FilterDocumentInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentInput>;
};


export type QueryObjectTypeLimitedArgs = {
  filter?: Maybe<ObjectTypeLimitedInput>;
};


export type QueryRentalEventsManyArgs = {
  filter?: Maybe<RentalEventFilterInput>;
};


export type QueryRentalLinesCalculatedArgs = {
  filter?: Maybe<FilterRentalLineInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  aggregate?: Maybe<AggregateRentalLineInput>;
  sort?: Maybe<SortRentalLineInput>;
  calculationType: CalculateRentallineInputValues;
};


export type QueryRentCostArgs = {
  filter?: Maybe<RentCostFilter>;
};


export type QueryAccumulatedRentCostArgs = {
  filter?: Maybe<AccumulatedRentCostFilter>;
};


export type QuerySalesOrderChangeTrackingPageArgs = {
  updatedAfter: Scalars['DateTime'];
  updatedBefore?: Maybe<Scalars['DateTime']>;
  filter?: Maybe<SalesOrderChangeTrackingFilterInput>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAutocompleteArgs = {
  filter?: Maybe<AutocompleteInput>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryInvitationsPageArgs = {
  filter?: Maybe<FilterInvitationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvitationInput>;
  aggregate?: Maybe<AggregateInvitationInput>;
};


export type QueryInvitationArgs = {
  filter?: Maybe<FilterInvitationInput>;
};


export type QueryMyInvitationArgs = {
  code?: Maybe<Scalars['String']>;
};


export type QueryCountFinishedFunctiontalTestsArgs = {
  filter: ClearFunctionTestInput;
};

export type QuoteHeader = {
  __typename?: 'QuoteHeader';
  discriminator?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  SalesPerson?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  ExternalText?: Maybe<Scalars['String']>;
  InternalText?: Maybe<Scalars['String']>;
  ProjectSum?: Maybe<Scalars['Float']>;
  LicenseSum?: Maybe<Scalars['Float']>;
  LicenseFee?: Maybe<Scalars['Float']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type QuoteHeaderConnection = {
  __typename?: 'QuoteHeaderConnection';
  count: Scalars['Int'];
  edges: Array<QuoteHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type QuoteHeaderConnectionEdge = {
  __typename?: 'QuoteHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: QuoteHeader;
};

export type QuoteHeaderDeleteResponse = {
  __typename?: 'QuoteHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<QuoteHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuoteHeaderManyResult = {
  __typename?: 'QuoteHeaderManyResult';
  records?: Maybe<Array<Maybe<QuoteHeader>>>;
  pageData?: Maybe<PageData>;
};

export type QuoteLine = {
  __typename?: 'QuoteLine';
  discriminator?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Task?: Maybe<Scalars['String']>;
  TaskGrouping?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  Sum?: Maybe<Scalars['Float']>;
  InvoicedSum?: Maybe<Scalars['Float']>;
  OnOrderSum?: Maybe<Scalars['Float']>;
  LinkedTo?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type QuoteLineConnection = {
  __typename?: 'QuoteLineConnection';
  count: Scalars['Int'];
  edges: Array<QuoteLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type QuoteLineConnectionEdge = {
  __typename?: 'QuoteLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: QuoteLine;
};

export type QuoteLineDeleteResponse = {
  __typename?: 'QuoteLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<QuoteLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuoteLineManyResult = {
  __typename?: 'QuoteLineManyResult';
  records?: Maybe<Array<Maybe<QuoteLine>>>;
  pageData?: Maybe<PageData>;
};

export type QuoteTaskGroup = {
  __typename?: 'QuoteTaskGroup';
  discriminator?: Maybe<Scalars['String']>;
  Group?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type QuoteTaskGroupConnection = {
  __typename?: 'QuoteTaskGroupConnection';
  count: Scalars['Int'];
  edges: Array<QuoteTaskGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type QuoteTaskGroupConnectionEdge = {
  __typename?: 'QuoteTaskGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: QuoteTaskGroup;
};

export type QuoteTaskGroupDeleteResponse = {
  __typename?: 'QuoteTaskGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<QuoteTaskGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuoteTaskGroupManyResult = {
  __typename?: 'QuoteTaskGroupManyResult';
  records?: Maybe<Array<Maybe<QuoteTaskGroup>>>;
  pageData?: Maybe<PageData>;
};

export type RejectApproveAccessRequestInput = {
  GlobalId: Scalars['String'];
  Approved: Scalars['Boolean'];
  Status?: Maybe<AccessRequestStatusEnum>;
};

export type RemoveCodesResponse = {
  __typename?: 'RemoveCodesResponse';
  codesRemoved?: Maybe<Scalars['Int']>;
};

export type RentCost = {
  __typename?: 'RentCost';
  TotalAmount?: Maybe<Scalars['Float']>;
  Lines?: Maybe<Array<Maybe<LineAmount>>>;
};

export type RentCostFilter = {
  ProjectId: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type RentalEvent = {
  __typename?: 'RentalEvent';
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ProjectId?: Maybe<Scalars['String']>;
  reservations?: Maybe<Array<Maybe<RentalLine>>>;
  returnLines?: Maybe<Array<Maybe<RentalPostedCollLine>>>;
  shipmentLines?: Maybe<Array<Maybe<RentalPostedShptLine>>>;
  reservationRequestLines?: Maybe<Array<Maybe<ReservationRequestLine>>>;
  returnRequestLines?: Maybe<Array<Maybe<ReturnRequestLine>>>;
};

export type RentalEventFilterInput = {
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ProjectId: Scalars['String'];
};

export type RentalHeader = {
  __typename?: 'RentalHeader';
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ChangeStatus?: Maybe<Scalars['Float']>;
  ChangeStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  BreakInvoiceatExpDate?: Maybe<Scalars['Boolean']>;
  Printed?: Maybe<Scalars['String']>;
  PrintedTime?: Maybe<Scalars['String']>;
  InvoiceFrequencyNOTINUSE?: Maybe<Scalars['Float']>;
  InvoiceFrequencyNOTINUSEOPTION?: Maybe<Scalars['String']>;
  LastInvoiceDate?: Maybe<Scalars['String']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ContractDate?: Maybe<Scalars['DateTime']>;
  StartRentalPeriodDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  TermofContract?: Maybe<Scalars['String']>;
  AnnualAmount?: Maybe<Scalars['Float']>;
  AmountperPeriod?: Maybe<Scalars['Float']>;
  CombineInvoices?: Maybe<Scalars['Boolean']>;
  Prepaid?: Maybe<Scalars['Boolean']>;
  NextInvoicePeriod?: Maybe<Scalars['String']>;
  RentalZoneCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CancelReasonCode?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  QuoteType?: Maybe<Scalars['Float']>;
  QuoteTypeOPTION?: Maybe<Scalars['String']>;
  ContractGroupCode?: Maybe<Scalars['String']>;
  RentalOrderType?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  TemplateNo?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Probability?: Maybe<Scalars['Float']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  NextInvoicePeriodStart?: Maybe<Scalars['DateTime']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['DateTime']>;
  FromQuoteNo?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  TempInvoiceDate?: Maybe<Scalars['DateTime']>;
  TermCode?: Maybe<Scalars['String']>;
  Contractterminatedcomplete?: Maybe<Scalars['Boolean']>;
  NextRentalInvoiceFrom?: Maybe<Scalars['String']>;
  NextRentalInvoiceTo?: Maybe<Scalars['String']>;
  TempReservation?: Maybe<Scalars['Boolean']>;
  TempDelivery?: Maybe<Scalars['Boolean']>;
  TempInvoice?: Maybe<Scalars['Boolean']>;
  TempDocumentType?: Maybe<Scalars['Float']>;
  TempDocumentTypeOPTION?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  InvoicingStatus?: Maybe<Scalars['Float']>;
  InvoicingStatusOPTION?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  SalesHeaderNoSale?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['String']>;
  DoNotShowDialogPrompt?: Maybe<Scalars['Boolean']>;
  RentalStartingFee?: Maybe<Scalars['String']>;
  TempStartRentalPeriod?: Maybe<Scalars['String']>;
  FrequenceFixDaysNOTINUSE?: Maybe<Scalars['Float']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  InvoiceSequence?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  LocationManagerContactNo?: Maybe<Scalars['String']>;
  ProjectManagerContactNo?: Maybe<Scalars['String']>;
  DeliveryContactNo?: Maybe<Scalars['String']>;
  FixedPriceContract?: Maybe<Scalars['Boolean']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CustomerDepositCode?: Maybe<Scalars['String']>;
  CustomerDepositAmttoPay?: Maybe<Scalars['Float']>;
  GrantedDeposit?: Maybe<Scalars['Float']>;
  RentalInvoiceDayofMonth?: Maybe<Scalars['Float']>;
  CustomerProject?: Maybe<Scalars['String']>;
  MemoText?: Maybe<Scalars['String']>;
  JournalEntryNo?: Maybe<Scalars['Float']>;
  UseRentalJnlNos?: Maybe<Scalars['Boolean']>;
  CustomerType?: Maybe<Scalars['Float']>;
  CustomerTypeOPTION?: Maybe<Scalars['String']>;
  FullyReturned?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  InvoiceDocType?: Maybe<Scalars['Float']>;
  InvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  RentalAmountExclVat?: Maybe<Scalars['Float']>;
  RentalLineDiscAmount?: Maybe<Scalars['Float']>;
  RentalTotalAmountExclVat?: Maybe<Scalars['Float']>;
  RentalAmountInclVat?: Maybe<Scalars['Float']>;
  RentalVatAmount?: Maybe<Scalars['Float']>;
  SalesAmountExclVat?: Maybe<Scalars['Float']>;
  SalesLineDiscAmount?: Maybe<Scalars['Float']>;
  SalesTotalAmountExclVat?: Maybe<Scalars['Float']>;
  SalesAmountInclVat?: Maybe<Scalars['Float']>;
  SalesVatAmount?: Maybe<Scalars['Float']>;
  SalesProfit?: Maybe<Scalars['Float']>;
  SalesQtyToInvoice?: Maybe<Scalars['Float']>;
  RentalInsurancAmtExclVat?: Maybe<Scalars['Float']>;
  RentalInsurancAmtInclVat?: Maybe<Scalars['Float']>;
  RentalContractCost?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtDocType?: Maybe<Scalars['Float']>;
  PrepmtDocTypeOPTION?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  UsePrepmtExpirationDate?: Maybe<Scalars['Boolean']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenter?: Maybe<Scalars['String']>;
  QuoteExpiryDate?: Maybe<Scalars['DateTime']>;
  QuoteStatus?: Maybe<Scalars['Float']>;
  QuoteStatusOPTION?: Maybe<Scalars['String']>;
  CustomerPONo?: Maybe<Scalars['String']>;
  NextInvoiceDueDate?: Maybe<Scalars['DateTime']>;
  TypeFilter?: Maybe<Scalars['Float']>;
  TypeFilterOPTION?: Maybe<Scalars['String']>;
  ReasonCodeFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ContractLineAmount?: Maybe<Scalars['Float']>;
  NoofPostedInvoices?: Maybe<Scalars['Float']>;
  NoofUnpostedInvoices?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ContractInvoiceAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  NoofPickOrder?: Maybe<Scalars['Float']>;
  NoofShipmentOrder?: Maybe<Scalars['Float']>;
  NoofCollectionOrder?: Maybe<Scalars['Float']>;
  NoofWorkOrder?: Maybe<Scalars['Float']>;
  LocationManagerName?: Maybe<Scalars['String']>;
  ProjectManagerName?: Maybe<Scalars['String']>;
  DeliveryContactName?: Maybe<Scalars['String']>;
  CustomerDepositAmount?: Maybe<Scalars['Float']>;
  CustomerDepositOutstAmt?: Maybe<Scalars['Float']>;
  RentalValue?: Maybe<Scalars['Float']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CustOrganizationNo?: Maybe<Scalars['String']>;
  TransactionNo?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  PrepmtPaidAmount?: Maybe<Scalars['Float']>;
  PrepmtUnpaidAmount?: Maybe<Scalars['Float']>;
  PrepmtPaidAmountInclVAT?: Maybe<Scalars['Float']>;
  PrepmtUnpaidAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmt?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalHeaderConnection = {
  __typename?: 'RentalHeaderConnection';
  count: Scalars['Int'];
  edges: Array<RentalHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalHeaderConnectionEdge = {
  __typename?: 'RentalHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalHeader;
};

export type RentalHeaderDeleteResponse = {
  __typename?: 'RentalHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalHeaderManyResult = {
  __typename?: 'RentalHeaderManyResult';
  records?: Maybe<Array<Maybe<RentalHeader>>>;
  pageData?: Maybe<PageData>;
};

export type RentalLine = {
  __typename?: 'RentalLine';
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ContractStatus?: Maybe<Scalars['Float']>;
  ContractStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['DateTime']>;
  EndRentalPeriod?: Maybe<Scalars['DateTime']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ExpirationDatesInGroup?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  NextInvoicePeriodStart?: Maybe<Scalars['String']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['String']>;
  RentalPeriodInvoiced?: Maybe<Scalars['Float']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  Credited?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  EntryStatus?: Maybe<Scalars['Float']>;
  EntryStatusOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  RentalPeriodNow?: Maybe<Scalars['Float']>;
  RentalChargeDays?: Maybe<Scalars['Float']>;
  RentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DeliveredTime?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  ReturnCompleted?: Maybe<Scalars['Boolean']>;
  TransferdToOrder?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['String']>;
  UnitPriceSale?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  ReservedSeasonCalendar?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  BinCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  WorkInprogress?: Maybe<Scalars['Boolean']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PctofSalesPrice?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtUseExpirationDate?: Maybe<Scalars['Boolean']>;
  PrepmtSalesLineNo?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatus?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtCrMemoStatus?: Maybe<Scalars['Float']>;
  PrepmtCrMemoStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtInsuranceAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrderInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeductVAT?: Maybe<Scalars['Float']>;
  PrepmtAmtDeductedVAT?: Maybe<Scalars['Float']>;
  PrepmtRentalLineNo?: Maybe<Scalars['Float']>;
  PrepmtInsuranceToDeduct?: Maybe<Scalars['Float']>;
  PrepmtInsuranceDeducted?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsAmtOrderIncVAT?: Maybe<Scalars['Float']>;
  PrepmtDimensionSetID?: Maybe<Scalars['Float']>;
  PrepmtVATBusPostingGroup?: Maybe<Scalars['String']>;
  PrepmtVATProdPostingGrp?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  OldAttachedtoLineNo?: Maybe<Scalars['Float']>;
  AttachedtoSwitchLineNo?: Maybe<Scalars['Float']>;
  AttachedStandByLineNo?: Maybe<Scalars['Float']>;
  StandbyStatus?: Maybe<Scalars['Float']>;
  StandbyStatusOPTION?: Maybe<Scalars['String']>;
  QtyonAttachedtoLineNo?: Maybe<Scalars['Float']>;
  KITMother?: Maybe<Scalars['String']>;
  KITFactor?: Maybe<Scalars['Float']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  KITFactorPrice?: Maybe<Scalars['Float']>;
  KITOriginalPrice?: Maybe<Scalars['Float']>;
  KITQuantity?: Maybe<Scalars['Float']>;
  KITLineAmountNet?: Maybe<Scalars['Float']>;
  KITLineAmountGross?: Maybe<Scalars['Float']>;
  KITOriginalSqMeter?: Maybe<Scalars['Float']>;
  SquareMeterperKIT?: Maybe<Scalars['Float']>;
  KITPriceperSqMeter?: Maybe<Scalars['Float']>;
  KITChange?: Maybe<Scalars['Float']>;
  TotalKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPriceMarked?: Maybe<Scalars['Boolean']>;
  DefaultKITFactorPrice?: Maybe<Scalars['Float']>;
  KITComponentUnitPrice?: Maybe<Scalars['Float']>;
  KITComponentRentalPrice?: Maybe<Scalars['Float']>;
  KITLineDiscount?: Maybe<Scalars['Float']>;
  KITLineDiscountAmount?: Maybe<Scalars['Float']>;
  OldTmplMotherLineNo?: Maybe<Scalars['Float']>;
  OnlyonFirstOrderInvoice?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  ReturnDateBuffer?: Maybe<Scalars['String']>;
  OffRentDateBuffer?: Maybe<Scalars['String']>;
  ReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  OffRentQuantityBuffer?: Maybe<Scalars['Float']>;
  DebitonReturnDateBuffer?: Maybe<Scalars['Boolean']>;
  CounteronReturnBuffer?: Maybe<Scalars['Float']>;
  ReturnReminderBuffer?: Maybe<Scalars['Float']>;
  UpdateLineBuffer?: Maybe<Scalars['Boolean']>;
  ReturnNoBuffer?: Maybe<Scalars['String']>;
  ReturnPoleSplitBuffer?: Maybe<Scalars['Boolean']>;
  ReturnCompleteBuffer?: Maybe<Scalars['Boolean']>;
  InvoicedToDateBuffer?: Maybe<Scalars['String']>;
  ReceiveLocationCodeBuffer?: Maybe<Scalars['String']>;
  ReturnTimeBuffer?: Maybe<Scalars['String']>;
  DepositAmountCardBuffer?: Maybe<Scalars['Float']>;
  DepositAmountCashBuffer?: Maybe<Scalars['Float']>;
  ReclassQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassDateBuffer?: Maybe<Scalars['String']>;
  CorrFromDateBuffer?: Maybe<Scalars['String']>;
  CorrInvToDateBuffer?: Maybe<Scalars['String']>;
  CorrReturnDateBuffer?: Maybe<Scalars['String']>;
  CorrCloseRentalBuffer?: Maybe<Scalars['Float']>;
  CorrCloseRentalBufferOPTION?: Maybe<Scalars['String']>;
  CorrUpdEntryBasisBuffer?: Maybe<Scalars['Boolean']>;
  CorrQuantityBuffer?: Maybe<Scalars['Float']>;
  CorrReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassFromContractBuffer?: Maybe<Scalars['Boolean']>;
  ReclassToContractBuffer?: Maybe<Scalars['Boolean']>;
  MaintenanceBuffer?: Maybe<Scalars['Boolean']>;
  DepositAmountTnsfBuffer?: Maybe<Scalars['Float']>;
  OffRentTimeBuffer?: Maybe<Scalars['String']>;
  OffRentNoBuffer?: Maybe<Scalars['String']>;
  CorrQtyShippedtoBuffer?: Maybe<Scalars['Float']>;
  TempEntryBuffer?: Maybe<Scalars['Boolean']>;
  ReturntoSellBuffer?: Maybe<Scalars['Float']>;
  ReturntoDamageBuffer?: Maybe<Scalars['Float']>;
  Counter2onReturnBuffer?: Maybe<Scalars['Float']>;
  CorrOnRentDateBuffer?: Maybe<Scalars['String']>;
  CorrNextInvDateBuffer?: Maybe<Scalars['String']>;
  CollectionDocNoBuffer?: Maybe<Scalars['String']>;
  CollectionLineNoBuffer?: Maybe<Scalars['Float']>;
  SourceCodeBuffer?: Maybe<Scalars['String']>;
  DeliverQuantityNowBuffer?: Maybe<Scalars['Float']>;
  ShipmentDateBuffer?: Maybe<Scalars['String']>;
  OnRentDateBuffer?: Maybe<Scalars['String']>;
  LocationCodeBuffer?: Maybe<Scalars['String']>;
  ConsumptionatReturnBuffer?: Maybe<Scalars['Float']>;
  CreditonEarlyBuffer?: Maybe<Scalars['Float']>;
  StartRentalPeriodBuffer?: Maybe<Scalars['String']>;
  LineNoBuffer?: Maybe<Scalars['Float']>;
  ConsumpatDeliveryBuffer?: Maybe<Scalars['Float']>;
  DepositAmountBackBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBufferOPTION?: Maybe<Scalars['String']>;
  PstDispatchDocNoBuffer?: Maybe<Scalars['String']>;
  PstDispatchLineNoBuffer?: Maybe<Scalars['Float']>;
  PostedInDocumentBuffer?: Maybe<Scalars['Boolean']>;
  EQMServiceDocBuffer?: Maybe<Scalars['String']>;
  UnitPriceBuffer?: Maybe<Scalars['Float']>;
  LineDiscountBuffer?: Maybe<Scalars['Float']>;
  LineMarkedBuffer?: Maybe<Scalars['Boolean']>;
  PostingDateBuffer?: Maybe<Scalars['String']>;
  UseInOpenPeriodBuffer?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNoBuffer?: Maybe<Scalars['Float']>;
  CounteronShipmentBuffer?: Maybe<Scalars['Float']>;
  Counter2onShipmentBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeDaysBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeHourBuffer?: Maybe<Scalars['Float']>;
  ReasonforRentFreeBuffer?: Maybe<Scalars['String']>;
  ReceiveRespCenterBuffer?: Maybe<Scalars['String']>;
  FullyConsumedAccBuffer?: Maybe<Scalars['Boolean']>;
  ReceivingBinCodeBuffer?: Maybe<Scalars['String']>;
  SuspendLineNoBuffer?: Maybe<Scalars['Boolean']>;
  UnitPriceNew?: Maybe<Scalars['Float']>;
  PriceTermCodeNew?: Maybe<Scalars['String']>;
  UnitPriceprDayNew?: Maybe<Scalars['Float']>;
  UnitPriceprMonthNew?: Maybe<Scalars['Float']>;
  LineAmountNew?: Maybe<Scalars['Float']>;
  RentalPeriodNew?: Maybe<Scalars['Float']>;
  LineDiscountNew?: Maybe<Scalars['Float']>;
  LineDiscountAmountNew?: Maybe<Scalars['Float']>;
  StairPriceCodeNew?: Maybe<Scalars['String']>;
  StairDiscountCodeNew?: Maybe<Scalars['String']>;
  BaseCalendarCodeNew?: Maybe<Scalars['String']>;
  StairPrice1New?: Maybe<Scalars['Float']>;
  StairPrice2New?: Maybe<Scalars['Float']>;
  StairPrice3New?: Maybe<Scalars['Float']>;
  StairPrice4New?: Maybe<Scalars['Float']>;
  StairPrice5New?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  Preliminary?: Maybe<Scalars['Boolean']>;
  ReferenceTableID?: Maybe<Scalars['Float']>;
  ReferenceUniqueID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  ReplacementUnitPrice?: Maybe<Scalars['Float']>;
  ReRentObject?: Maybe<Scalars['Boolean']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  UnitPriceprDay?: Maybe<Scalars['Float']>;
  UnitPriceprMonth?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  DepositOrigUnitPrice?: Maybe<Scalars['Float']>;
  MarkDepositEntry?: Maybe<Scalars['Boolean']>;
  DepositUnitPrice?: Maybe<Scalars['Float']>;
  DepositPostingNo?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  DepositReturnPostingNo?: Maybe<Scalars['String']>;
  AgreedToDate?: Maybe<Scalars['String']>;
  DepositPostingType?: Maybe<Scalars['Float']>;
  DepositPostingTypeOPTION?: Maybe<Scalars['String']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  DeliveredBy?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  SaleDocumentNo?: Maybe<Scalars['String']>;
  SaleDocumentType?: Maybe<Scalars['Float']>;
  SaleDocumentTypeOPTION?: Maybe<Scalars['String']>;
  SaleLineNo?: Maybe<Scalars['Float']>;
  SaleReserve?: Maybe<Scalars['Float']>;
  SaleReserveOPTION?: Maybe<Scalars['String']>;
  ReturnQuantityHours?: Maybe<Scalars['Float']>;
  ShipmentDateTime?: Maybe<Scalars['String']>;
  EndRentalDateTime?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['String']>;
  DoShipmentNow?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  OnRentTime?: Maybe<Scalars['String']>;
  OffRentTime?: Maybe<Scalars['String']>;
  OnRentDateTime?: Maybe<Scalars['String']>;
  OffRentDateTime?: Maybe<Scalars['String']>;
  ReturnQuantityChargeHours?: Maybe<Scalars['Float']>;
  TerminPeriod?: Maybe<Scalars['Float']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  IgnorCalendarCheck?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondMotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeMotherRef?: Maybe<Scalars['Float']>;
  ReturnAdditionMotherRef?: Maybe<Scalars['Float']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  ExtraHoursReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  TmpAccumEntry?: Maybe<Scalars['Boolean']>;
  MarkReclassLineNoRef?: Maybe<Scalars['Float']>;
  MarkReclassQuantity?: Maybe<Scalars['Float']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  ReclassFromLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderedBy?: Maybe<Scalars['String']>;
  MarkReclassLine?: Maybe<Scalars['Boolean']>;
  FilterReclassLine?: Maybe<Scalars['Boolean']>;
  ReclassFromContractNo?: Maybe<Scalars['String']>;
  ReclassToContractNo?: Maybe<Scalars['String']>;
  ReclassToLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderNo?: Maybe<Scalars['String']>;
  ReclassDocNo?: Maybe<Scalars['String']>;
  WorkshopObjectNo?: Maybe<Scalars['String']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  ExploreLine?: Maybe<Scalars['Float']>;
  EnvironmentalFee?: Maybe<Scalars['Boolean']>;
  ExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  ExtraHoursReturnNo?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  ReclassDocLineNo?: Maybe<Scalars['Float']>;
  RentalSplitCostCenter?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  RecursiveLevel?: Maybe<Scalars['Float']>;
  Repurchase?: Maybe<Scalars['Boolean']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatus?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatusOPTION?: Maybe<Scalars['String']>;
  CPAdvancePaymentDocNo?: Maybe<Scalars['String']>;
  InvoiceFee?: Maybe<Scalars['Boolean']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StairPriceDiscount?: Maybe<Scalars['Float']>;
  ReceiveRespCenter?: Maybe<Scalars['String']>;
  ReceiveCostCenter?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  ServiceMotherLineNo?: Maybe<Scalars['Float']>;
  AdditionalCharge?: Maybe<Scalars['Boolean']>;
  AdjforAdditionalCharge?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  EQMServiceNoTemp?: Maybe<Scalars['String']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  DispatchTask?: Maybe<Scalars['Float']>;
  DispatchTaskOPTION?: Maybe<Scalars['String']>;
  DispatchStatus?: Maybe<Scalars['Float']>;
  DispatchStatusOPTION?: Maybe<Scalars['String']>;
  ScheduledDeliveryDate?: Maybe<Scalars['String']>;
  ScheduledDeliveryTime?: Maybe<Scalars['String']>;
  ScheduledPickupDate?: Maybe<Scalars['String']>;
  ScheduledPickupTime?: Maybe<Scalars['String']>;
  DeliveryDriver?: Maybe<Scalars['String']>;
  PickupDriver?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  SchedDate?: Maybe<Scalars['String']>;
  ActualDate?: Maybe<Scalars['String']>;
  SchedTime?: Maybe<Scalars['String']>;
  ActualTime?: Maybe<Scalars['String']>;
  ActualDeliveryDate?: Maybe<Scalars['String']>;
  ActualDeliveryTime?: Maybe<Scalars['String']>;
  ActualPickupDate?: Maybe<Scalars['String']>;
  ActualPickupTime?: Maybe<Scalars['String']>;
  ActualReturnDate?: Maybe<Scalars['String']>;
  ActualReturnTime?: Maybe<Scalars['String']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  DispatchDocType?: Maybe<Scalars['Float']>;
  DispatchDocTypeOPTION?: Maybe<Scalars['String']>;
  DispatchDocNo?: Maybe<Scalars['String']>;
  DispatchLineNo?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  UserFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DefaultKITPriceCompFlow?: Maybe<Scalars['Float']>;
  AssignedtoKIT?: Maybe<Scalars['String']>;
  OffRentDateFlow?: Maybe<Scalars['String']>;
  ReturnDateFlow?: Maybe<Scalars['String']>;
  QuantityShippedtoFlow?: Maybe<Scalars['Float']>;
  QuantityReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityPstShippedFlow?: Maybe<Scalars['Float']>;
  QtyonPickOrder?: Maybe<Scalars['Float']>;
  QtyonShipmentOrder?: Maybe<Scalars['Float']>;
  QtyonCollectionOrder?: Maybe<Scalars['Float']>;
  UserLocationFilter?: Maybe<Scalars['String']>;
  NoofWorkOrderLines?: Maybe<Scalars['Float']>;
  NoofPickOrderLines?: Maybe<Scalars['Float']>;
  NoofShipmentOrderLines?: Maybe<Scalars['Float']>;
  NoofCollectionOrderLines?: Maybe<Scalars['Float']>;
  NoofPostedPickOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdShptOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdCollOrderLines?: Maybe<Scalars['Float']>;
  NoofRntlReservSummaries?: Maybe<Scalars['Float']>;
  NoofRntlReservEntries?: Maybe<Scalars['Float']>;
  QtyonRntlReservEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResShptEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResRetEntry?: Maybe<Scalars['Float']>;
  QtyonBufferEntry?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodPrice?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodEntry?: Maybe<Scalars['Float']>;
  LastErrorText?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  SaleReservedQuantity?: Maybe<Scalars['Float']>;
  SaleReservedQtyBase?: Maybe<Scalars['Float']>;
  KITNetWeight?: Maybe<Scalars['Float']>;
  ReportedRentalHours?: Maybe<Scalars['Float']>;
  EQMServiceDocNo?: Maybe<Scalars['String']>;
  DispatchDocument?: Maybe<Scalars['Boolean']>;
  DispatchDocumentNo?: Maybe<Scalars['String']>;
  GroupOnNumber?: Maybe<Scalars['String']>;
  PeriodLineAmountEdge?: Maybe<Scalars['Float']>;
  RentalDays?: Maybe<Scalars['Float']>;
  RemainingQty?: Maybe<Scalars['Float']>;
  FreeDays?: Maybe<Scalars['Float']>;
  DayNetPriceEdge?: Maybe<Scalars['Float']>;
  Vaccation?: Maybe<Scalars['Boolean']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  ReturnReservationActive?: Maybe<Scalars['Boolean']>;
  UserExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  ImageUrl?: Maybe<Scalars['String']>;
  PSILink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  customerProject?: Maybe<CustomerProject>;
  ActualGlobalId?: Maybe<Scalars['String']>;
};

export type RentalLineConnection = {
  __typename?: 'RentalLineConnection';
  count: Scalars['Int'];
  edges: Array<RentalLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalLineConnectionEdge = {
  __typename?: 'RentalLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalLine;
};

export type RentalLineDeleteResponse = {
  __typename?: 'RentalLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalLineManyResult = {
  __typename?: 'RentalLineManyResult';
  records?: Maybe<Array<Maybe<RentalLine>>>;
  pageData?: Maybe<PageData>;
};

export type RentalLineSnapshot = {
  __typename?: 'RentalLineSnapshot';
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ContractStatus?: Maybe<Scalars['Float']>;
  ContractStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['DateTime']>;
  EndRentalPeriod?: Maybe<Scalars['DateTime']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ExpirationDatesInGroup?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  NextInvoicePeriodStart?: Maybe<Scalars['String']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['String']>;
  RentalPeriodInvoiced?: Maybe<Scalars['Float']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  Credited?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  EntryStatus?: Maybe<Scalars['Float']>;
  EntryStatusOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  RentalPeriodNow?: Maybe<Scalars['Float']>;
  RentalChargeDays?: Maybe<Scalars['Float']>;
  RentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DeliveredTime?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  ReturnCompleted?: Maybe<Scalars['Boolean']>;
  TransferdToOrder?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['String']>;
  UnitPriceSale?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  ReservedSeasonCalendar?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  BinCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  WorkInprogress?: Maybe<Scalars['Boolean']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PctofSalesPrice?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtUseExpirationDate?: Maybe<Scalars['Boolean']>;
  PrepmtSalesLineNo?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatus?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtCrMemoStatus?: Maybe<Scalars['Float']>;
  PrepmtCrMemoStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtInsuranceAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrderInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeductVAT?: Maybe<Scalars['Float']>;
  PrepmtAmtDeductedVAT?: Maybe<Scalars['Float']>;
  PrepmtRentalLineNo?: Maybe<Scalars['Float']>;
  PrepmtInsuranceToDeduct?: Maybe<Scalars['Float']>;
  PrepmtInsuranceDeducted?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsAmtOrderIncVAT?: Maybe<Scalars['Float']>;
  PrepmtDimensionSetID?: Maybe<Scalars['Float']>;
  PrepmtVATBusPostingGroup?: Maybe<Scalars['String']>;
  PrepmtVATProdPostingGrp?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  OldAttachedtoLineNo?: Maybe<Scalars['Float']>;
  AttachedtoSwitchLineNo?: Maybe<Scalars['Float']>;
  AttachedStandByLineNo?: Maybe<Scalars['Float']>;
  StandbyStatus?: Maybe<Scalars['Float']>;
  StandbyStatusOPTION?: Maybe<Scalars['String']>;
  QtyonAttachedtoLineNo?: Maybe<Scalars['Float']>;
  KITMother?: Maybe<Scalars['String']>;
  KITFactor?: Maybe<Scalars['Float']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  KITFactorPrice?: Maybe<Scalars['Float']>;
  KITOriginalPrice?: Maybe<Scalars['Float']>;
  KITQuantity?: Maybe<Scalars['Float']>;
  KITLineAmountNet?: Maybe<Scalars['Float']>;
  KITLineAmountGross?: Maybe<Scalars['Float']>;
  KITOriginalSqMeter?: Maybe<Scalars['Float']>;
  SquareMeterperKIT?: Maybe<Scalars['Float']>;
  KITPriceperSqMeter?: Maybe<Scalars['Float']>;
  KITChange?: Maybe<Scalars['Float']>;
  TotalKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPriceMarked?: Maybe<Scalars['Boolean']>;
  DefaultKITFactorPrice?: Maybe<Scalars['Float']>;
  KITComponentUnitPrice?: Maybe<Scalars['Float']>;
  KITComponentRentalPrice?: Maybe<Scalars['Float']>;
  KITLineDiscount?: Maybe<Scalars['Float']>;
  KITLineDiscountAmount?: Maybe<Scalars['Float']>;
  OldTmplMotherLineNo?: Maybe<Scalars['Float']>;
  OnlyonFirstOrderInvoice?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  ReturnDateBuffer?: Maybe<Scalars['String']>;
  OffRentDateBuffer?: Maybe<Scalars['String']>;
  ReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  OffRentQuantityBuffer?: Maybe<Scalars['Float']>;
  DebitonReturnDateBuffer?: Maybe<Scalars['Boolean']>;
  CounteronReturnBuffer?: Maybe<Scalars['Float']>;
  ReturnReminderBuffer?: Maybe<Scalars['Float']>;
  UpdateLineBuffer?: Maybe<Scalars['Boolean']>;
  ReturnNoBuffer?: Maybe<Scalars['String']>;
  ReturnPoleSplitBuffer?: Maybe<Scalars['Boolean']>;
  ReturnCompleteBuffer?: Maybe<Scalars['Boolean']>;
  InvoicedToDateBuffer?: Maybe<Scalars['String']>;
  ReceiveLocationCodeBuffer?: Maybe<Scalars['String']>;
  ReturnTimeBuffer?: Maybe<Scalars['String']>;
  DepositAmountCardBuffer?: Maybe<Scalars['Float']>;
  DepositAmountCashBuffer?: Maybe<Scalars['Float']>;
  ReclassQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassDateBuffer?: Maybe<Scalars['String']>;
  CorrFromDateBuffer?: Maybe<Scalars['String']>;
  CorrInvToDateBuffer?: Maybe<Scalars['String']>;
  CorrReturnDateBuffer?: Maybe<Scalars['String']>;
  CorrCloseRentalBuffer?: Maybe<Scalars['Float']>;
  CorrCloseRentalBufferOPTION?: Maybe<Scalars['String']>;
  CorrUpdEntryBasisBuffer?: Maybe<Scalars['Boolean']>;
  CorrQuantityBuffer?: Maybe<Scalars['Float']>;
  CorrReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassFromContractBuffer?: Maybe<Scalars['Boolean']>;
  ReclassToContractBuffer?: Maybe<Scalars['Boolean']>;
  MaintenanceBuffer?: Maybe<Scalars['Boolean']>;
  DepositAmountTnsfBuffer?: Maybe<Scalars['Float']>;
  OffRentTimeBuffer?: Maybe<Scalars['String']>;
  OffRentNoBuffer?: Maybe<Scalars['String']>;
  CorrQtyShippedtoBuffer?: Maybe<Scalars['Float']>;
  TempEntryBuffer?: Maybe<Scalars['Boolean']>;
  ReturntoSellBuffer?: Maybe<Scalars['Float']>;
  ReturntoDamageBuffer?: Maybe<Scalars['Float']>;
  Counter2onReturnBuffer?: Maybe<Scalars['Float']>;
  CorrOnRentDateBuffer?: Maybe<Scalars['String']>;
  CorrNextInvDateBuffer?: Maybe<Scalars['String']>;
  CollectionDocNoBuffer?: Maybe<Scalars['String']>;
  CollectionLineNoBuffer?: Maybe<Scalars['Float']>;
  SourceCodeBuffer?: Maybe<Scalars['String']>;
  DeliverQuantityNowBuffer?: Maybe<Scalars['Float']>;
  ShipmentDateBuffer?: Maybe<Scalars['String']>;
  OnRentDateBuffer?: Maybe<Scalars['String']>;
  LocationCodeBuffer?: Maybe<Scalars['String']>;
  ConsumptionatReturnBuffer?: Maybe<Scalars['Float']>;
  CreditonEarlyBuffer?: Maybe<Scalars['Float']>;
  StartRentalPeriodBuffer?: Maybe<Scalars['String']>;
  LineNoBuffer?: Maybe<Scalars['Float']>;
  ConsumpatDeliveryBuffer?: Maybe<Scalars['Float']>;
  DepositAmountBackBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBufferOPTION?: Maybe<Scalars['String']>;
  PstDispatchDocNoBuffer?: Maybe<Scalars['String']>;
  PstDispatchLineNoBuffer?: Maybe<Scalars['Float']>;
  PostedInDocumentBuffer?: Maybe<Scalars['Boolean']>;
  EQMServiceDocBuffer?: Maybe<Scalars['String']>;
  UnitPriceBuffer?: Maybe<Scalars['Float']>;
  LineDiscountBuffer?: Maybe<Scalars['Float']>;
  LineMarkedBuffer?: Maybe<Scalars['Boolean']>;
  PostingDateBuffer?: Maybe<Scalars['String']>;
  UseInOpenPeriodBuffer?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNoBuffer?: Maybe<Scalars['Float']>;
  CounteronShipmentBuffer?: Maybe<Scalars['Float']>;
  Counter2onShipmentBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeDaysBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeHourBuffer?: Maybe<Scalars['Float']>;
  ReasonforRentFreeBuffer?: Maybe<Scalars['String']>;
  ReceiveRespCenterBuffer?: Maybe<Scalars['String']>;
  FullyConsumedAccBuffer?: Maybe<Scalars['Boolean']>;
  ReceivingBinCodeBuffer?: Maybe<Scalars['String']>;
  SuspendLineNoBuffer?: Maybe<Scalars['Boolean']>;
  UnitPriceNew?: Maybe<Scalars['Float']>;
  PriceTermCodeNew?: Maybe<Scalars['String']>;
  UnitPriceprDayNew?: Maybe<Scalars['Float']>;
  UnitPriceprMonthNew?: Maybe<Scalars['Float']>;
  LineAmountNew?: Maybe<Scalars['Float']>;
  RentalPeriodNew?: Maybe<Scalars['Float']>;
  LineDiscountNew?: Maybe<Scalars['Float']>;
  LineDiscountAmountNew?: Maybe<Scalars['Float']>;
  StairPriceCodeNew?: Maybe<Scalars['String']>;
  StairDiscountCodeNew?: Maybe<Scalars['String']>;
  BaseCalendarCodeNew?: Maybe<Scalars['String']>;
  StairPrice1New?: Maybe<Scalars['Float']>;
  StairPrice2New?: Maybe<Scalars['Float']>;
  StairPrice3New?: Maybe<Scalars['Float']>;
  StairPrice4New?: Maybe<Scalars['Float']>;
  StairPrice5New?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  Preliminary?: Maybe<Scalars['Boolean']>;
  ReferenceTableID?: Maybe<Scalars['Float']>;
  ReferenceUniqueID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  ReplacementUnitPrice?: Maybe<Scalars['Float']>;
  ReRentObject?: Maybe<Scalars['Boolean']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  UnitPriceprDay?: Maybe<Scalars['Float']>;
  UnitPriceprMonth?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  DepositOrigUnitPrice?: Maybe<Scalars['Float']>;
  MarkDepositEntry?: Maybe<Scalars['Boolean']>;
  DepositUnitPrice?: Maybe<Scalars['Float']>;
  DepositPostingNo?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  DepositReturnPostingNo?: Maybe<Scalars['String']>;
  AgreedToDate?: Maybe<Scalars['String']>;
  DepositPostingType?: Maybe<Scalars['Float']>;
  DepositPostingTypeOPTION?: Maybe<Scalars['String']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  DeliveredBy?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  SaleDocumentNo?: Maybe<Scalars['String']>;
  SaleDocumentType?: Maybe<Scalars['Float']>;
  SaleDocumentTypeOPTION?: Maybe<Scalars['String']>;
  SaleLineNo?: Maybe<Scalars['Float']>;
  SaleReserve?: Maybe<Scalars['Float']>;
  SaleReserveOPTION?: Maybe<Scalars['String']>;
  ReturnQuantityHours?: Maybe<Scalars['Float']>;
  ShipmentDateTime?: Maybe<Scalars['String']>;
  EndRentalDateTime?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['String']>;
  DoShipmentNow?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  OnRentTime?: Maybe<Scalars['String']>;
  OffRentTime?: Maybe<Scalars['String']>;
  OnRentDateTime?: Maybe<Scalars['String']>;
  OffRentDateTime?: Maybe<Scalars['String']>;
  ReturnQuantityChargeHours?: Maybe<Scalars['Float']>;
  TerminPeriod?: Maybe<Scalars['Float']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  IgnorCalendarCheck?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondMotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeMotherRef?: Maybe<Scalars['Float']>;
  ReturnAdditionMotherRef?: Maybe<Scalars['Float']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  ExtraHoursReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  TmpAccumEntry?: Maybe<Scalars['Boolean']>;
  MarkReclassLineNoRef?: Maybe<Scalars['Float']>;
  MarkReclassQuantity?: Maybe<Scalars['Float']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  ReclassFromLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderedBy?: Maybe<Scalars['String']>;
  MarkReclassLine?: Maybe<Scalars['Boolean']>;
  FilterReclassLine?: Maybe<Scalars['Boolean']>;
  ReclassFromContractNo?: Maybe<Scalars['String']>;
  ReclassToContractNo?: Maybe<Scalars['String']>;
  ReclassToLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderNo?: Maybe<Scalars['String']>;
  ReclassDocNo?: Maybe<Scalars['String']>;
  WorkshopObjectNo?: Maybe<Scalars['String']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  ExploreLine?: Maybe<Scalars['Float']>;
  EnvironmentalFee?: Maybe<Scalars['Boolean']>;
  ExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  ExtraHoursReturnNo?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  ReclassDocLineNo?: Maybe<Scalars['Float']>;
  RentalSplitCostCenter?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  RecursiveLevel?: Maybe<Scalars['Float']>;
  Repurchase?: Maybe<Scalars['Boolean']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatus?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatusOPTION?: Maybe<Scalars['String']>;
  CPAdvancePaymentDocNo?: Maybe<Scalars['String']>;
  InvoiceFee?: Maybe<Scalars['Boolean']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StairPriceDiscount?: Maybe<Scalars['Float']>;
  ReceiveRespCenter?: Maybe<Scalars['String']>;
  ReceiveCostCenter?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  ServiceMotherLineNo?: Maybe<Scalars['Float']>;
  AdditionalCharge?: Maybe<Scalars['Boolean']>;
  AdjforAdditionalCharge?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  EQMServiceNoTemp?: Maybe<Scalars['String']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  DispatchTask?: Maybe<Scalars['Float']>;
  DispatchTaskOPTION?: Maybe<Scalars['String']>;
  DispatchStatus?: Maybe<Scalars['Float']>;
  DispatchStatusOPTION?: Maybe<Scalars['String']>;
  ScheduledDeliveryDate?: Maybe<Scalars['String']>;
  ScheduledDeliveryTime?: Maybe<Scalars['String']>;
  ScheduledPickupDate?: Maybe<Scalars['String']>;
  ScheduledPickupTime?: Maybe<Scalars['String']>;
  DeliveryDriver?: Maybe<Scalars['String']>;
  PickupDriver?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  SchedDate?: Maybe<Scalars['String']>;
  ActualDate?: Maybe<Scalars['String']>;
  SchedTime?: Maybe<Scalars['String']>;
  ActualTime?: Maybe<Scalars['String']>;
  ActualDeliveryDate?: Maybe<Scalars['String']>;
  ActualDeliveryTime?: Maybe<Scalars['String']>;
  ActualPickupDate?: Maybe<Scalars['String']>;
  ActualPickupTime?: Maybe<Scalars['String']>;
  ActualReturnDate?: Maybe<Scalars['String']>;
  ActualReturnTime?: Maybe<Scalars['String']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  DispatchDocType?: Maybe<Scalars['Float']>;
  DispatchDocTypeOPTION?: Maybe<Scalars['String']>;
  DispatchDocNo?: Maybe<Scalars['String']>;
  DispatchLineNo?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  UserFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DefaultKITPriceCompFlow?: Maybe<Scalars['Float']>;
  AssignedtoKIT?: Maybe<Scalars['String']>;
  OffRentDateFlow?: Maybe<Scalars['String']>;
  ReturnDateFlow?: Maybe<Scalars['String']>;
  QuantityShippedtoFlow?: Maybe<Scalars['Float']>;
  QuantityReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityPstShippedFlow?: Maybe<Scalars['Float']>;
  QtyonPickOrder?: Maybe<Scalars['Float']>;
  QtyonShipmentOrder?: Maybe<Scalars['Float']>;
  QtyonCollectionOrder?: Maybe<Scalars['Float']>;
  UserLocationFilter?: Maybe<Scalars['String']>;
  NoofWorkOrderLines?: Maybe<Scalars['Float']>;
  NoofPickOrderLines?: Maybe<Scalars['Float']>;
  NoofShipmentOrderLines?: Maybe<Scalars['Float']>;
  NoofCollectionOrderLines?: Maybe<Scalars['Float']>;
  NoofPostedPickOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdShptOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdCollOrderLines?: Maybe<Scalars['Float']>;
  NoofRntlReservSummaries?: Maybe<Scalars['Float']>;
  NoofRntlReservEntries?: Maybe<Scalars['Float']>;
  QtyonRntlReservEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResShptEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResRetEntry?: Maybe<Scalars['Float']>;
  QtyonBufferEntry?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodPrice?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodEntry?: Maybe<Scalars['Float']>;
  LastErrorText?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  SaleReservedQuantity?: Maybe<Scalars['Float']>;
  SaleReservedQtyBase?: Maybe<Scalars['Float']>;
  KITNetWeight?: Maybe<Scalars['Float']>;
  ReportedRentalHours?: Maybe<Scalars['Float']>;
  EQMServiceDocNo?: Maybe<Scalars['String']>;
  DispatchDocument?: Maybe<Scalars['Boolean']>;
  DispatchDocumentNo?: Maybe<Scalars['String']>;
  GroupOnNumber?: Maybe<Scalars['String']>;
  PeriodLineAmountEdge?: Maybe<Scalars['Float']>;
  RentalDays?: Maybe<Scalars['Float']>;
  RemainingQty?: Maybe<Scalars['Float']>;
  FreeDays?: Maybe<Scalars['Float']>;
  DayNetPriceEdge?: Maybe<Scalars['Float']>;
  Vaccation?: Maybe<Scalars['Boolean']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  ReturnReservationActive?: Maybe<Scalars['Boolean']>;
  UserExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  ImageUrl?: Maybe<Scalars['String']>;
  PSILink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  SnapshotOn?: Maybe<Scalars['DateTime']>;
  rentalLines?: Maybe<Array<Maybe<RentalLine>>>;
};

export type RentalLineSnapshotConnection = {
  __typename?: 'RentalLineSnapshotConnection';
  count: Scalars['Int'];
  edges: Array<RentalLineSnapshotConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalLineSnapshotConnectionEdge = {
  __typename?: 'RentalLineSnapshotConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalLineSnapshot;
};

export type RentalLineSnapshotDeleteResponse = {
  __typename?: 'RentalLineSnapshotDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalLineSnapshot>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalLineSnapshotManyResult = {
  __typename?: 'RentalLineSnapshotManyResult';
  records?: Maybe<Array<Maybe<RentalLineSnapshot>>>;
  pageData?: Maybe<PageData>;
};

export type RentalPostedCollHeader = {
  __typename?: 'RentalPostedCollHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingHour?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  CollectionStatus?: Maybe<Scalars['Float']>;
  CollectionStatusOPTION?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderDocumentType?: Maybe<Scalars['Float']>;
  OrderDocumentTypeOPTION?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  Requestedby?: Maybe<Scalars['String']>;
  Receivedby?: Maybe<Scalars['String']>;
  ResponsibilityCenterCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  Broker?: Maybe<Scalars['String']>;
  Carrier?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  BOLSignature?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalPostedCollHeaderConnection = {
  __typename?: 'RentalPostedCollHeaderConnection';
  count: Scalars['Int'];
  edges: Array<RentalPostedCollHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalPostedCollHeaderConnectionEdge = {
  __typename?: 'RentalPostedCollHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalPostedCollHeader;
};

export type RentalPostedCollHeaderDeleteResponse = {
  __typename?: 'RentalPostedCollHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalPostedCollHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalPostedCollHeaderManyResult = {
  __typename?: 'RentalPostedCollHeaderManyResult';
  records?: Maybe<Array<Maybe<RentalPostedCollHeader>>>;
  pageData?: Maybe<PageData>;
};

export type RentalPostedCollLine = {
  __typename?: 'RentalPostedCollLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ExtRefNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  LoadSequenceNo?: Maybe<Scalars['String']>;
  PriceStairNo?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  SerialNo?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  OffRentUpdated?: Maybe<Scalars['Boolean']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  QuantitySold?: Maybe<Scalars['Float']>;
  QuantityDamaged?: Maybe<Scalars['Float']>;
  QuantityOffRented?: Maybe<Scalars['Float']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  EQMServiceID?: Maybe<Scalars['Float']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  MotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  FullyConsumedAccessory?: Maybe<Scalars['Boolean']>;
  ScannedBy?: Maybe<Scalars['String']>;
  ScannedDate?: Maybe<Scalars['DateTime']>;
  ScannedTime?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  PstDeliveryEntryNo?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  ReceivingBinCode?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  AppliedfromDeliverEntryNo?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  Level?: Maybe<Scalars['Float']>;
  LineType?: Maybe<Scalars['Float']>;
  LineTypeOPTION?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['DateTime']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalPostedCollLineConnection = {
  __typename?: 'RentalPostedCollLineConnection';
  count: Scalars['Int'];
  edges: Array<RentalPostedCollLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalPostedCollLineConnectionEdge = {
  __typename?: 'RentalPostedCollLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalPostedCollLine;
};

export type RentalPostedCollLineDeleteResponse = {
  __typename?: 'RentalPostedCollLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalPostedCollLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalPostedCollLineManyResult = {
  __typename?: 'RentalPostedCollLineManyResult';
  records?: Maybe<Array<Maybe<RentalPostedCollLine>>>;
  pageData?: Maybe<PageData>;
};

export type RentalPostedShptHeader = {
  __typename?: 'RentalPostedShptHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingHour?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderDocumentType?: Maybe<Scalars['Float']>;
  OrderDocumentTypeOPTION?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  ResponsibilityCenterCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  Broker?: Maybe<Scalars['String']>;
  Carrier?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  BOLSignature?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalPostedShptHeaderConnection = {
  __typename?: 'RentalPostedShptHeaderConnection';
  count: Scalars['Int'];
  edges: Array<RentalPostedShptHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalPostedShptHeaderConnectionEdge = {
  __typename?: 'RentalPostedShptHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalPostedShptHeader;
};

export type RentalPostedShptHeaderDeleteResponse = {
  __typename?: 'RentalPostedShptHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalPostedShptHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalPostedShptHeaderManyResult = {
  __typename?: 'RentalPostedShptHeaderManyResult';
  records?: Maybe<Array<Maybe<RentalPostedShptHeader>>>;
  pageData?: Maybe<PageData>;
};

export type RentalPostedShptLine = {
  __typename?: 'RentalPostedShptLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ExtRefNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  LoadSequenceNo?: Maybe<Scalars['String']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  SerialNo?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  MotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  ScannedBy?: Maybe<Scalars['String']>;
  ScannedDate?: Maybe<Scalars['DateTime']>;
  ScannedTime?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  OrderDocNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  QuantityCollected?: Maybe<Scalars['Float']>;
  CollectionDocNo?: Maybe<Scalars['String']>;
  CollectionLineNo?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['DateTime']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalPostedShptLineConnection = {
  __typename?: 'RentalPostedShptLineConnection';
  count: Scalars['Int'];
  edges: Array<RentalPostedShptLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalPostedShptLineConnectionEdge = {
  __typename?: 'RentalPostedShptLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalPostedShptLine;
};

export type RentalPostedShptLineDeleteResponse = {
  __typename?: 'RentalPostedShptLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalPostedShptLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalPostedShptLineManyResult = {
  __typename?: 'RentalPostedShptLineManyResult';
  records?: Maybe<Array<Maybe<RentalPostedShptLine>>>;
  pageData?: Maybe<PageData>;
};

export type RentalPriceTerms = {
  __typename?: 'RentalPriceTerms';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ChargeFullPeriod?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ReportedHours?: Maybe<Scalars['Boolean']>;
  FixedDays?: Maybe<Scalars['Float']>;
  SquareMeter?: Maybe<Scalars['Boolean']>;
  FullTerm?: Maybe<Scalars['Boolean']>;
  HalfTerm?: Maybe<Scalars['Boolean']>;
  FixedBillingPeriod?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalPriceTermsConnection = {
  __typename?: 'RentalPriceTermsConnection';
  count: Scalars['Int'];
  edges: Array<RentalPriceTermsConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalPriceTermsConnectionEdge = {
  __typename?: 'RentalPriceTermsConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalPriceTerms;
};

export type RentalPriceTermsDeleteResponse = {
  __typename?: 'RentalPriceTermsDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalPriceTerms>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalPriceTermsManyResult = {
  __typename?: 'RentalPriceTermsManyResult';
  records?: Maybe<Array<Maybe<RentalPriceTerms>>>;
  pageData?: Maybe<PageData>;
};

export type RentalReturnEntry = {
  __typename?: 'RentalReturnEntry';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  ReturnNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  ExtRentalLineNo?: Maybe<Scalars['Float']>;
  EntryType?: Maybe<Scalars['String']>;
  EntryTypeOPTION?: Maybe<Scalars['String']>;
  ReturnType?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DocumentType?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  PostedinDocument?: Maybe<Scalars['Boolean']>;
  PostingTime?: Maybe<Scalars['String']>;
  TransferredtoOrder?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  OffRentTime?: Maybe<Scalars['String']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  Quantity?: Maybe<Scalars['Float']>;
  RemainingQuantity?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TransactionNo?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  UndoEntry?: Maybe<Scalars['Boolean']>;
  DispatchType?: Maybe<Scalars['String']>;
  PstDispatchDocNo?: Maybe<Scalars['String']>;
  PstDispatchLineNo?: Maybe<Scalars['Float']>;
  OrderDocNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  DoPostReturn?: Maybe<Scalars['Boolean']>;
  DoPostOffRent?: Maybe<Scalars['Boolean']>;
  OrigOffRentDate?: Maybe<Scalars['DateTime']>;
  NoSeries?: Maybe<Scalars['String']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  QuantitySold?: Maybe<Scalars['Float']>;
  QuantityDamaged?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  ReturnQuantityTime?: Maybe<Scalars['Float']>;
  TmpLocationCode?: Maybe<Scalars['String']>;
  AppliestoEntry?: Maybe<Scalars['Float']>;
  CompleteReturn?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ChargeCustomer?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['String']>;
  FullyConsumedAccessory?: Maybe<Scalars['Boolean']>;
  FullReturnSale?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  QtyReserved?: Maybe<Scalars['Float']>;
  QtytoCollect?: Maybe<Scalars['Float']>;
  QuantityReturned?: Maybe<Scalars['Float']>;
  HourlyRent?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  OnRentTime?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  ReceivingBinCode?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  KITComponent?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  QtyPstCollectedFlow?: Maybe<Scalars['Float']>;
  PstdCollOrderDocNo?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  ProjectedReturnDate?: Maybe<Scalars['DateTime']>;
  InvoiceToDate?: Maybe<Scalars['DateTime']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  DiamondQuantity?: Maybe<Scalars['Float']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  RentalDays?: Maybe<Scalars['Float']>;
  MonthPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalReturnEntryConnection = {
  __typename?: 'RentalReturnEntryConnection';
  count: Scalars['Int'];
  edges: Array<RentalReturnEntryConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentalReturnEntryConnectionEdge = {
  __typename?: 'RentalReturnEntryConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RentalReturnEntry;
};

export type RentalReturnEntryDeleteResponse = {
  __typename?: 'RentalReturnEntryDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RentalReturnEntry>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RentalReturnEntryManyResult = {
  __typename?: 'RentalReturnEntryManyResult';
  records?: Maybe<Array<Maybe<RentalReturnEntry>>>;
  pageData?: Maybe<PageData>;
};

export type RequestHeader = {
  __typename?: 'RequestHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['Float']>;
  RequestTypeOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  User?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Time?: Maybe<Scalars['String']>;
  DispatchHeader?: Maybe<Scalars['String']>;
  DispatchPosted?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RequestHeaderConnection = {
  __typename?: 'RequestHeaderConnection';
  count: Scalars['Int'];
  edges: Array<RequestHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RequestHeaderConnectionEdge = {
  __typename?: 'RequestHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RequestHeader;
};

export type RequestHeaderDeleteResponse = {
  __typename?: 'RequestHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RequestHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RequestHeaderManyResult = {
  __typename?: 'RequestHeaderManyResult';
  records?: Maybe<Array<Maybe<RequestHeader>>>;
  pageData?: Maybe<PageData>;
};

export type RequestLine = {
  __typename?: 'RequestLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['Float']>;
  RequestTypeOPTION?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LineType?: Maybe<Scalars['Float']>;
  LineTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RequestLineConnection = {
  __typename?: 'RequestLineConnection';
  count: Scalars['Int'];
  edges: Array<RequestLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RequestLineConnectionEdge = {
  __typename?: 'RequestLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RequestLine;
};

export type RequestLineDeleteResponse = {
  __typename?: 'RequestLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RequestLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RequestLineManyResult = {
  __typename?: 'RequestLineManyResult';
  records?: Maybe<Array<Maybe<RequestLine>>>;
  pageData?: Maybe<PageData>;
};

export type ReservationRequestHeader = {
  __typename?: 'ReservationRequestHeader';
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  DeliveryDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  DomainName?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  Pickup?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ReservationRequestHeaderConnection = {
  __typename?: 'ReservationRequestHeaderConnection';
  count: Scalars['Int'];
  edges: Array<ReservationRequestHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReservationRequestHeaderConnectionEdge = {
  __typename?: 'ReservationRequestHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ReservationRequestHeader;
};

export type ReservationRequestHeaderDeleteResponse = {
  __typename?: 'ReservationRequestHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ReservationRequestHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReservationRequestHeaderManyResult = {
  __typename?: 'ReservationRequestHeaderManyResult';
  records?: Maybe<Array<Maybe<ReservationRequestHeader>>>;
  pageData?: Maybe<PageData>;
};

export type ReservationRequestLine = {
  __typename?: 'ReservationRequestLine';
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  LineGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ReservationRequestLineConnection = {
  __typename?: 'ReservationRequestLineConnection';
  count: Scalars['Int'];
  edges: Array<ReservationRequestLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReservationRequestLineConnectionEdge = {
  __typename?: 'ReservationRequestLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ReservationRequestLine;
};

export type ReservationRequestLineDeleteResponse = {
  __typename?: 'ReservationRequestLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ReservationRequestLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReservationRequestLineManyResult = {
  __typename?: 'ReservationRequestLineManyResult';
  records?: Maybe<Array<Maybe<ReservationRequestLine>>>;
  pageData?: Maybe<PageData>;
};

export type Resource = {
  __typename?: 'Resource';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  SocialSecurityNo?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Education?: Maybe<Scalars['String']>;
  ContractClass?: Maybe<Scalars['String']>;
  EmploymentDate?: Maybe<Scalars['DateTime']>;
  ResourceGroupNo?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['Float']>;
  PriceProfitCalculationOPTION?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  VendorNo?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  AutomaticExtTexts?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ICPartnerPurchGLAccNo?: Maybe<Scalars['String']>;
  UseTimeSheet?: Maybe<Scalars['Boolean']>;
  TimeSheetOwnerUserID?: Maybe<Scalars['String']>;
  TimeSheetApproverUserID?: Maybe<Scalars['String']>;
  ServiceZoneFilter?: Maybe<Scalars['String']>;
  DefaultQuantity?: Maybe<Scalars['Float']>;
  Mounting?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['String']>;
  UnitofMeasureFilter?: Maybe<Scalars['String']>;
  ChargeableFilter?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Capacity?: Maybe<Scalars['Float']>;
  QtyonOrderJob?: Maybe<Scalars['Float']>;
  QtyQuotedJob?: Maybe<Scalars['Float']>;
  UsageQty?: Maybe<Scalars['Float']>;
  UsageCost?: Maybe<Scalars['Float']>;
  UsagePrice?: Maybe<Scalars['Float']>;
  SalesQty?: Maybe<Scalars['Float']>;
  SalesCost?: Maybe<Scalars['Float']>;
  SalesPrice?: Maybe<Scalars['Float']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  InCustomerZone?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ShowInFieldOrder?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ResourceConnection = {
  __typename?: 'ResourceConnection';
  count: Scalars['Int'];
  edges: Array<ResourceConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ResourceConnectionEdge = {
  __typename?: 'ResourceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Resource;
};

export type ResourceDeleteResponse = {
  __typename?: 'ResourceDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Resource>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResourceManyResult = {
  __typename?: 'ResourceManyResult';
  records?: Maybe<Array<Maybe<Resource>>>;
  pageData?: Maybe<PageData>;
};

export type ResponsibilityCenter = {
  __typename?: 'ResponsibilityCenter';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  EMailSignatureText?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ContractGainLossAmount?: Maybe<Scalars['Float']>;
  Longitud?: Maybe<Scalars['String']>;
  Latitud?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ResponsibilityCenterConnection = {
  __typename?: 'ResponsibilityCenterConnection';
  count: Scalars['Int'];
  edges: Array<ResponsibilityCenterConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ResponsibilityCenterConnectionEdge = {
  __typename?: 'ResponsibilityCenterConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ResponsibilityCenter;
};

export type ResponsibilityCenterDeleteResponse = {
  __typename?: 'ResponsibilityCenterDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ResponsibilityCenter>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResponsibilityCenterManyResult = {
  __typename?: 'ResponsibilityCenterManyResult';
  records?: Maybe<Array<Maybe<ResponsibilityCenter>>>;
  pageData?: Maybe<PageData>;
};

export type ResponsibilityCenterMatrix = {
  __typename?: 'ResponsibilityCenterMatrix';
  DomainId?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
};

export type RetrySyncInput = {
  discriminator: Scalars['String'];
  GlobalId: Scalars['String'];
};

export type RetrySyncResponse = {
  __typename?: 'RetrySyncResponse';
  Success?: Maybe<Scalars['Boolean']>;
  Error?: Maybe<Scalars['String']>;
};

export type ReturnRequestHeader = {
  __typename?: 'ReturnRequestHeader';
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  DomainName?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  PickUpDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  DropOff?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ReturnRequestHeaderConnection = {
  __typename?: 'ReturnRequestHeaderConnection';
  count: Scalars['Int'];
  edges: Array<ReturnRequestHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReturnRequestHeaderConnectionEdge = {
  __typename?: 'ReturnRequestHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ReturnRequestHeader;
};

export type ReturnRequestHeaderDeleteResponse = {
  __typename?: 'ReturnRequestHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ReturnRequestHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReturnRequestHeaderManyResult = {
  __typename?: 'ReturnRequestHeaderManyResult';
  records?: Maybe<Array<Maybe<ReturnRequestHeader>>>;
  pageData?: Maybe<PageData>;
};

export type ReturnRequestLine = {
  __typename?: 'ReturnRequestLine';
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  LineGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  header?: Maybe<ReturnRequestHeader>;
};

export type ReturnRequestLineConnection = {
  __typename?: 'ReturnRequestLineConnection';
  count: Scalars['Int'];
  edges: Array<ReturnRequestLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReturnRequestLineConnectionEdge = {
  __typename?: 'ReturnRequestLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ReturnRequestLine;
};

export type ReturnRequestLineDeleteResponse = {
  __typename?: 'ReturnRequestLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ReturnRequestLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReturnRequestLineManyResult = {
  __typename?: 'ReturnRequestLineManyResult';
  records?: Maybe<Array<Maybe<ReturnRequestLine>>>;
  pageData?: Maybe<PageData>;
};

export type RiskAnalysisHeader = {
  __typename?: 'RiskAnalysisHeader';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Template?: Maybe<Scalars['String']>;
  WorkPlace?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedByPhone?: Maybe<Scalars['String']>;
  Customer?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Database?: Maybe<Scalars['String']>;
  RecordID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  FinishedByUser?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RiskAnalysisHeaderConnection = {
  __typename?: 'RiskAnalysisHeaderConnection';
  count: Scalars['Int'];
  edges: Array<RiskAnalysisHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RiskAnalysisHeaderConnectionEdge = {
  __typename?: 'RiskAnalysisHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RiskAnalysisHeader;
};

export type RiskAnalysisHeaderDeleteResponse = {
  __typename?: 'RiskAnalysisHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RiskAnalysisHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RiskAnalysisHeaderManyResult = {
  __typename?: 'RiskAnalysisHeaderManyResult';
  records?: Maybe<Array<Maybe<RiskAnalysisHeader>>>;
  pageData?: Maybe<PageData>;
};

export type RiskAnalysisLine = {
  __typename?: 'RiskAnalysisLine';
  discriminator?: Maybe<Scalars['String']>;
  RiskAnalysisNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  RiskCode?: Maybe<Scalars['Float']>;
  RiskCodeOPTION?: Maybe<Scalars['String']>;
  Risk?: Maybe<Scalars['Boolean']>;
  Action?: Maybe<Scalars['String']>;
  Probability?: Maybe<Scalars['Float']>;
  ProbabilityOPTION?: Maybe<Scalars['String']>;
  Consequence?: Maybe<Scalars['Float']>;
  ConsequenceOPTION?: Maybe<Scalars['String']>;
  Mandatory?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RiskAnalysisLineConnection = {
  __typename?: 'RiskAnalysisLineConnection';
  count: Scalars['Int'];
  edges: Array<RiskAnalysisLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RiskAnalysisLineConnectionEdge = {
  __typename?: 'RiskAnalysisLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RiskAnalysisLine;
};

export type RiskAnalysisLineDeleteResponse = {
  __typename?: 'RiskAnalysisLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RiskAnalysisLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RiskAnalysisLineManyResult = {
  __typename?: 'RiskAnalysisLineManyResult';
  records?: Maybe<Array<Maybe<RiskAnalysisLine>>>;
  pageData?: Maybe<PageData>;
};

export type RiskAnalysisSignature = {
  __typename?: 'RiskAnalysisSignature';
  discriminator?: Maybe<Scalars['String']>;
  RiskAnalysisNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Signature?: Maybe<Scalars['String']>;
  TypeOfSignature?: Maybe<Scalars['Float']>;
  TypeOfSignatureOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type RiskAnalysisSignatureConnection = {
  __typename?: 'RiskAnalysisSignatureConnection';
  count: Scalars['Int'];
  edges: Array<RiskAnalysisSignatureConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RiskAnalysisSignatureConnectionEdge = {
  __typename?: 'RiskAnalysisSignatureConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: RiskAnalysisSignature;
};

export type RiskAnalysisSignatureDeleteResponse = {
  __typename?: 'RiskAnalysisSignatureDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<RiskAnalysisSignature>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RiskAnalysisSignatureManyResult = {
  __typename?: 'RiskAnalysisSignatureManyResult';
  records?: Maybe<Array<Maybe<RiskAnalysisSignature>>>;
  pageData?: Maybe<PageData>;
};

export type SalesHeader = {
  __typename?: 'SalesHeader';
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesHeaderDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  SelltoCustomerGlobalId?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  QuoteValidUntilDate?: Maybe<Scalars['DateTime']>;
  QuoteSenttoCustomer?: Maybe<Scalars['String']>;
  QuoteAccepted?: Maybe<Scalars['Boolean']>;
  QuoteAcceptedDate?: Maybe<Scalars['DateTime']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<SalesHeaderShippingAdviceEnum>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['Float']>;
  EQMShipmentTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['Float']>;
  EQMTransferMethodOPTION?: Maybe<Scalars['String']>;
  EQMContraryDoc?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMCombineCustomerProject?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMInvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  EQMCashSaleDocument?: Maybe<Scalars['Boolean']>;
  EQMUseDeposit?: Maybe<Scalars['Boolean']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['Float']>;
  LastEmailSentStatusOPTION?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  AmtShipNotInvLCY?: Maybe<Scalars['Float']>;
  AmtShipNotInvLCYBase?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  Shipped?: Maybe<Scalars['Boolean']>;
  LateOrderShipping?: Maybe<Scalars['Boolean']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  MagentoId?: Maybe<Scalars['Float']>;
  BaseSubTotal?: Maybe<Scalars['Float']>;
  BaseSubTotalInclTax?: Maybe<Scalars['Float']>;
  ShippingDescription?: Maybe<Scalars['String']>;
  CustomerNote?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  BilltoEmail?: Maybe<Scalars['String']>;
  BilltoTelephone?: Maybe<Scalars['String']>;
  ShipToId?: Maybe<Scalars['Float']>;
  ShiptoEmail?: Maybe<Scalars['String']>;
  ShiptoTelephone?: Maybe<Scalars['String']>;
  MagentoOrderStatus?: Maybe<Scalars['String']>;
  MagentoOrderState?: Maybe<Scalars['String']>;
  LineCount?: Maybe<Scalars['Float']>;
  FreightIncluded?: Maybe<Scalars['Boolean']>;
  DiscountIncluded?: Maybe<Scalars['Boolean']>;
  DeliveryMethod?: Maybe<Scalars['Float']>;
  ProcessedByEdge?: Maybe<Scalars['Boolean']>;
  EdgeStartedPollingOn?: Maybe<Scalars['DateTime']>;
  HandleRelationsInJSONStructure?: Maybe<Scalars['Boolean']>;
  WebbComment?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  PaymentMethodWeb?: Maybe<SalesHeaderPaymentMethodWebEnum>;
  EMail?: Maybe<Scalars['String']>;
  WebOrderNo?: Maybe<Scalars['String']>;
  AdditionalTelephone?: Maybe<Scalars['String']>;
  SellToAddressGlobalId?: Maybe<Scalars['String']>;
  SharkActualPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  salesLines?: Maybe<Array<Maybe<SalesLine>>>;
  shipments?: Maybe<Array<Maybe<SalesShipmentHeader>>>;
  aggregatedShipment?: Maybe<SalesShipmentHeader>;
  customer?: Maybe<Company>;
  OrderState?: Maybe<OrderStateEnum>;
  ActualGlobalId?: Maybe<Scalars['String']>;
};

export type SalesHeaderArchive = {
  __typename?: 'SalesHeaderArchive';
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesHeaderArchiveDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  SelltoCustomerGlobalId?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  QuoteValidUntilDate?: Maybe<Scalars['DateTime']>;
  QuoteSenttoCustomer?: Maybe<Scalars['String']>;
  QuoteAccepted?: Maybe<Scalars['Boolean']>;
  QuoteAcceptedDate?: Maybe<Scalars['DateTime']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['Float']>;
  EQMShipmentTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['Float']>;
  EQMTransferMethodOPTION?: Maybe<Scalars['String']>;
  EQMContraryDoc?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMCombineCustomerProject?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMInvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  EQMCashSaleDocument?: Maybe<Scalars['Boolean']>;
  EQMUseDeposit?: Maybe<Scalars['Boolean']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['Float']>;
  LastEmailSentStatusOPTION?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  AmtShipNotInvLCY?: Maybe<Scalars['Float']>;
  AmtShipNotInvLCYBase?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  Shipped?: Maybe<Scalars['Boolean']>;
  LateOrderShipping?: Maybe<Scalars['Boolean']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  MagentoId?: Maybe<Scalars['Float']>;
  BaseSubTotal?: Maybe<Scalars['Float']>;
  BaseSubTotalInclTax?: Maybe<Scalars['Float']>;
  ShippingDescription?: Maybe<Scalars['String']>;
  CustomerNote?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  BilltoEmail?: Maybe<Scalars['String']>;
  BilltoTelephone?: Maybe<Scalars['String']>;
  ShipToId?: Maybe<Scalars['Float']>;
  ShiptoEmail?: Maybe<Scalars['String']>;
  ShiptoTelephone?: Maybe<Scalars['String']>;
  MagentoOrderStatus?: Maybe<Scalars['String']>;
  MagentoOrderState?: Maybe<Scalars['String']>;
  LineCount?: Maybe<Scalars['Float']>;
  FreightIncluded?: Maybe<Scalars['Boolean']>;
  DiscountIncluded?: Maybe<Scalars['Boolean']>;
  DeliveryMethod?: Maybe<Scalars['Float']>;
  ProcessedByEdge?: Maybe<Scalars['Boolean']>;
  EdgeStartedPollingOn?: Maybe<Scalars['DateTime']>;
  HandleRelationsInJSONStructure?: Maybe<Scalars['Boolean']>;
  WebbComment?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  VersionNo?: Maybe<Scalars['Float']>;
  DeletedReason?: Maybe<SalesHeaderArchiveDeletedReasonEnum>;
  IsConstructed?: Maybe<Scalars['Boolean']>;
  PaymentMethodWeb?: Maybe<SalesHeaderArchivePaymentMethodWebEnum>;
  SharkActualPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  salesLines?: Maybe<Array<Maybe<SalesLineArchive>>>;
};

export type SalesHeaderArchiveConnection = {
  __typename?: 'SalesHeaderArchiveConnection';
  count: Scalars['Int'];
  edges: Array<SalesHeaderArchiveConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesHeaderArchiveConnectionEdge = {
  __typename?: 'SalesHeaderArchiveConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesHeaderArchive;
};

export type SalesHeaderArchiveDeleteResponse = {
  __typename?: 'SalesHeaderArchiveDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesHeaderArchive>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SalesHeaderArchiveDeletedReasonEnum {
  Empty = 'Empty',
  Deleted = 'Deleted',
  Posted = 'Posted'
}

export enum SalesHeaderArchiveDocumentTypeEnum {
  Quote = 'Quote',
  Order = 'Order',
  Invoice = 'Invoice',
  CreditMemo = 'CreditMemo',
  BlanketOrder = 'BlanketOrder',
  ReturnOrder = 'ReturnOrder'
}

export type SalesHeaderArchiveManyResult = {
  __typename?: 'SalesHeaderArchiveManyResult';
  records?: Maybe<Array<Maybe<SalesHeaderArchive>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesHeaderArchivePaymentMethodWebEnum {
  Cash = 'Cash',
  Invoice = 'Invoice',
  CashOrInvoice = 'CashOrInvoice'
}

export type SalesHeaderConnection = {
  __typename?: 'SalesHeaderConnection';
  count: Scalars['Int'];
  edges: Array<SalesHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesHeaderConnectionEdge = {
  __typename?: 'SalesHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesHeader;
};

export type SalesHeaderDeleteResponse = {
  __typename?: 'SalesHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SalesHeaderDocumentTypeEnum {
  Quote = 'Quote',
  Order = 'Order',
  Invoice = 'Invoice',
  CreditMemo = 'CreditMemo',
  BlanketOrder = 'BlanketOrder',
  ReturnOrder = 'ReturnOrder'
}

export type SalesHeaderHistory = {
  __typename?: 'SalesHeaderHistory';
  discriminator?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesHeaderHistoryTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesHeaderHistoryConnection = {
  __typename?: 'SalesHeaderHistoryConnection';
  count: Scalars['Int'];
  edges: Array<SalesHeaderHistoryConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesHeaderHistoryConnectionEdge = {
  __typename?: 'SalesHeaderHistoryConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesHeaderHistory;
};

export type SalesHeaderHistoryDeleteResponse = {
  __typename?: 'SalesHeaderHistoryDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesHeaderHistory>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesHeaderHistoryManyResult = {
  __typename?: 'SalesHeaderHistoryManyResult';
  records?: Maybe<Array<Maybe<SalesHeaderHistory>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesHeaderHistoryTypeEnum {
  Empty = 'Empty',
  Deleted = 'Deleted',
  Posted = 'Posted'
}

export type SalesHeaderManyResult = {
  __typename?: 'SalesHeaderManyResult';
  records?: Maybe<Array<Maybe<SalesHeader>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesHeaderPaymentMethodWebEnum {
  Empty = 'Empty',
  Cash = 'Cash',
  Invoice = 'Invoice',
  CashOrInvoice = 'CashOrInvoice'
}

export type SalesHeaderRequest = {
  __typename?: 'SalesHeaderRequest';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AuthorizationRequired?: Maybe<Scalars['Boolean']>;
  CreditCardNo?: Maybe<Scalars['String']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  Ordertype?: Maybe<Scalars['Float']>;
  OrdertypeOPTION?: Maybe<Scalars['String']>;
  OurReference?: Maybe<Scalars['String']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  ConfirmedbyVendor?: Maybe<Scalars['Boolean']>;
  DocumentMethod?: Maybe<Scalars['Float']>;
  DocumentMethodOPTION?: Maybe<Scalars['String']>;
  InvoiceEmailAddress?: Maybe<Scalars['String']>;
  NAVStatus?: Maybe<Scalars['Float']>;
  NAVStatusOPTION?: Maybe<Scalars['String']>;
  NAVImportedDate?: Maybe<Scalars['DateTime']>;
  NAVOrderNo?: Maybe<Scalars['String']>;
  NAVErrorMessage?: Maybe<Scalars['String']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  salesLineRequests?: Maybe<Array<Maybe<SalesLineRequest>>>;
  customer?: Maybe<Company>;
};

export type SalesHeaderRequestConnection = {
  __typename?: 'SalesHeaderRequestConnection';
  count: Scalars['Int'];
  edges: Array<SalesHeaderRequestConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesHeaderRequestConnectionEdge = {
  __typename?: 'SalesHeaderRequestConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesHeaderRequest;
};

export type SalesHeaderRequestDeleteResponse = {
  __typename?: 'SalesHeaderRequestDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesHeaderRequest>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesHeaderRequestManyResult = {
  __typename?: 'SalesHeaderRequestManyResult';
  records?: Maybe<Array<Maybe<SalesHeaderRequest>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesHeaderShippingAdviceEnum {
  Partial = 'Partial',
  Complete = 'Complete'
}

export type SalesLine = {
  __typename?: 'SalesLine';
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesLineDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ItemGlobalId?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvLCYNoVAT?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  LineDiscountCalculation?: Maybe<Scalars['Float']>;
  LineDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  EQMCustomerProject?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMReturnDocumentNo?: Maybe<Scalars['String']>;
  EQMReturnLineNo?: Maybe<Scalars['Float']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMAllocateObjectNo?: Maybe<Scalars['String']>;
  EQMRentalLineType?: Maybe<Scalars['Float']>;
  EQMRentalLineTypeOPTION?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['Float']>;
  EQMWorkshopTypeOPTION?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMOldTmpRKITLineNo?: Maybe<Scalars['Float']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMQtyonAtttoLineNo?: Maybe<Scalars['Float']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMRentalPrepaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtLCY?: Maybe<Scalars['Float']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['Float']>;
  EQMPriceReportedHoursOPTION?: Maybe<Scalars['String']>;
  EQMFACorrection?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  ProductType?: Maybe<Scalars['String']>;
  SalesOrderLineEdgeGuid?: Maybe<Scalars['String']>;
  LinkedToGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesLineArchive = {
  __typename?: 'SalesLineArchive';
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesLineArchiveDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesLineArchiveTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ItemGlobalId?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvLCYNoVAT?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  LineDiscountCalculation?: Maybe<Scalars['Float']>;
  LineDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  EQMCustomerProject?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMReturnDocumentNo?: Maybe<Scalars['String']>;
  EQMReturnLineNo?: Maybe<Scalars['Float']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMAllocateObjectNo?: Maybe<Scalars['String']>;
  EQMRentalLineType?: Maybe<Scalars['Float']>;
  EQMRentalLineTypeOPTION?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['Float']>;
  EQMWorkshopTypeOPTION?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMOldTmpRKITLineNo?: Maybe<Scalars['Float']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMQtyonAtttoLineNo?: Maybe<Scalars['Float']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMRentalPrepaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtLCY?: Maybe<Scalars['Float']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['Float']>;
  EQMPriceReportedHoursOPTION?: Maybe<Scalars['String']>;
  EQMFACorrection?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  ProductType?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  VersionNo?: Maybe<Scalars['Float']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  SalesOrderLineEdgeGuid?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesLineArchiveConnection = {
  __typename?: 'SalesLineArchiveConnection';
  count: Scalars['Int'];
  edges: Array<SalesLineArchiveConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesLineArchiveConnectionEdge = {
  __typename?: 'SalesLineArchiveConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesLineArchive;
};

export type SalesLineArchiveDeleteResponse = {
  __typename?: 'SalesLineArchiveDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesLineArchive>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SalesLineArchiveDocumentTypeEnum {
  Quote = 'Quote',
  Order = 'Order',
  Invoice = 'Invoice',
  CreditMemo = 'CreditMemo',
  BlanketOrder = 'BlanketOrder',
  ReturnOrder = 'ReturnOrder'
}

export type SalesLineArchiveManyResult = {
  __typename?: 'SalesLineArchiveManyResult';
  records?: Maybe<Array<Maybe<SalesLineArchive>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesLineArchiveTypeEnum {
  Empty = 'Empty',
  GlAccount = 'GLAccount',
  Item = 'Item',
  Resource = 'Resource',
  FixedAsset = 'FixedAsset',
  ChargeItem = 'ChargeItem'
}

export type SalesLineConnection = {
  __typename?: 'SalesLineConnection';
  count: Scalars['Int'];
  edges: Array<SalesLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesLineConnectionEdge = {
  __typename?: 'SalesLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesLine;
};

export type SalesLineDeleteResponse = {
  __typename?: 'SalesLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SalesLineDocumentTypeEnum {
  Quote = 'Quote',
  Order = 'Order',
  Invoice = 'Invoice',
  CreditMemo = 'CreditMemo',
  BlanketOrder = 'BlanketOrder',
  ReturnOrder = 'ReturnOrder'
}

export type SalesLineManyResult = {
  __typename?: 'SalesLineManyResult';
  records?: Maybe<Array<Maybe<SalesLine>>>;
  pageData?: Maybe<PageData>;
};

export type SalesLineRequest = {
  __typename?: 'SalesLineRequest';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PeriodicTemplateCode?: Maybe<Scalars['String']>;
  PeriodicStartingDate?: Maybe<Scalars['String']>;
  NAVStatus?: Maybe<Scalars['Float']>;
  NAVStatusOPTION?: Maybe<Scalars['String']>;
  NAVImportedDate?: Maybe<Scalars['DateTime']>;
  NAVOrderNo?: Maybe<Scalars['String']>;
  Rubrikrad?: Maybe<Scalars['Boolean']>;
  EndofGroup?: Maybe<Scalars['Boolean']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  FreeTextLine?: Maybe<Scalars['Boolean']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  NAVErrorMessage?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesLineRequestConnection = {
  __typename?: 'SalesLineRequestConnection';
  count: Scalars['Int'];
  edges: Array<SalesLineRequestConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesLineRequestConnectionEdge = {
  __typename?: 'SalesLineRequestConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesLineRequest;
};

export type SalesLineRequestDeleteResponse = {
  __typename?: 'SalesLineRequestDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesLineRequest>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesLineRequestManyResult = {
  __typename?: 'SalesLineRequestManyResult';
  records?: Maybe<Array<Maybe<SalesLineRequest>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesLineTypeEnum {
  Empty = 'Empty',
  GlAccount = 'GLAccount',
  Item = 'Item',
  Resource = 'Resource',
  FixedAsset = 'FixedAsset',
  ChargeItem = 'ChargeItem'
}

export type SalesOrderChangeTrackingFilterInput = {
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['String']>;
};

export type SalesOrderChangeTrackingPageData = {
  __typename?: 'SalesOrderChangeTrackingPageData';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  latestUpdateOn?: Maybe<Scalars['DateTime']>;
  updatedSalesOrdersTotalCount?: Maybe<Scalars['Int']>;
  deletedSalesOrdersTotalCount?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
};

export type SalesOrderChangeTrackingPageResponse = {
  __typename?: 'SalesOrderChangeTrackingPageResponse';
  pageData?: Maybe<SalesOrderChangeTrackingPageData>;
  updatedSalesOrders?: Maybe<Array<Maybe<SalesHeader>>>;
  deletedSalesOrders?: Maybe<Array<Maybe<DeletedRecordRef>>>;
};

export type SalesOrderInput = {
  SalesHeader?: Maybe<UpdateSalesHeaderInput>;
  Customer?: Maybe<UpdateCompanyInput>;
  SalesLines?: Maybe<Array<Maybe<UpdateSalesLineInput>>>;
};

export type SalesOrderResponse = {
  __typename?: 'SalesOrderResponse';
  SalesHeader?: Maybe<SalesHeader>;
  Customer?: Maybe<Company>;
  SalesLines?: Maybe<Array<Maybe<SalesLine>>>;
};

export type SalesPrice = {
  __typename?: 'SalesPrice';
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  SalesType?: Maybe<SalesPriceSalesTypeEnum>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  UnitPriceIncVAT?: Maybe<Scalars['Float']>;
  SalesOrderMultiple?: Maybe<Scalars['Float']>;
  CarepaPurchPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesPriceConnection = {
  __typename?: 'SalesPriceConnection';
  count: Scalars['Int'];
  edges: Array<SalesPriceConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesPriceConnectionEdge = {
  __typename?: 'SalesPriceConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesPrice;
};

export type SalesPriceDeleteResponse = {
  __typename?: 'SalesPriceDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesPrice>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesPriceManyResult = {
  __typename?: 'SalesPriceManyResult';
  records?: Maybe<Array<Maybe<SalesPrice>>>;
  pageData?: Maybe<PageData>;
};

export enum SalesPriceSalesTypeEnum {
  Customer = 'Customer',
  CustomerPriceGroup = 'CustomerPriceGroup',
  AllCustomers = 'AllCustomers',
  Campaign = 'Campaign'
}

export type SalesShipmentHeader = {
  __typename?: 'SalesShipmentHeader';
  discriminator?: Maybe<Scalars['String']>;
  MagentoOrder?: Maybe<Scalars['Boolean']>;
  MagentoOrderType?: Maybe<Scalars['Float']>;
  MagentoOrderTypeOPTION?: Maybe<Scalars['String']>;
  MagentoOrderId?: Maybe<Scalars['Float']>;
  MagentoOrderEmail?: Maybe<Scalars['String']>;
  MagentoOrderMobileNo?: Maybe<Scalars['String']>;
  MagentoOrderLinesCount?: Maybe<Scalars['Float']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  EdgeRetryCount?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['String']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['String']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DocumentExchangeIdentifier?: Maybe<Scalars['String']>;
  DocumentExchangeStatus?: Maybe<Scalars['String']>;
  DocumentExchangeStatusOPTION?: Maybe<Scalars['String']>;
  DocExchOriginalIdentifier?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  CustLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Preseason?: Maybe<Scalars['Boolean']>;
  AdditionalTelephone?: Maybe<Scalars['String']>;
  AdditionalCustomerNote?: Maybe<Scalars['String']>;
  CustomerCommentExists?: Maybe<Scalars['Boolean']>;
  WebOrderNo?: Maybe<Scalars['String']>;
  WebOrderEntityNo?: Maybe<Scalars['Float']>;
  EMail?: Maybe<Scalars['String']>;
  EMailOrderConfirmation?: Maybe<Scalars['String']>;
  EMailSalesShipment?: Maybe<Scalars['String']>;
  EMailInvoice?: Maybe<Scalars['String']>;
  EMailShipmentNotification?: Maybe<Scalars['String']>;
  OrderCreatedBy?: Maybe<Scalars['String']>;
  OrderCreated?: Maybe<Scalars['String']>;
  OrderType?: Maybe<Scalars['String']>;
  OrderTypeOPTION?: Maybe<Scalars['String']>;
  ShipmentDocType?: Maybe<Scalars['String']>;
  ShipmentDocTypeOPTION?: Maybe<Scalars['String']>;
  GearsOrder?: Maybe<Scalars['Boolean']>;
  NumberofCustomerPriceGroup?: Maybe<Scalars['Float']>;
  EDISetupCode?: Maybe<Scalars['String']>;
  InvoiceDocumentType?: Maybe<Scalars['String']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  InvoiceSentToCustomer?: Maybe<Scalars['Boolean']>;
  InvoiceSentToCustomerDate?: Maybe<Scalars['String']>;
  InvoiceSentToCustomerTime?: Maybe<Scalars['String']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  FreightOnlyShipment?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  shipmentLines?: Maybe<Array<Maybe<SalesShipmentLine>>>;
  IsAggregated?: Maybe<Scalars['Boolean']>;
};

export type SalesShipmentHeaderConnection = {
  __typename?: 'SalesShipmentHeaderConnection';
  count: Scalars['Int'];
  edges: Array<SalesShipmentHeaderConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesShipmentHeaderConnectionEdge = {
  __typename?: 'SalesShipmentHeaderConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesShipmentHeader;
};

export type SalesShipmentHeaderDeleteResponse = {
  __typename?: 'SalesShipmentHeaderDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesShipmentHeader>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesShipmentHeaderManyResult = {
  __typename?: 'SalesShipmentHeaderManyResult';
  records?: Maybe<Array<Maybe<SalesShipmentHeader>>>;
  pageData?: Maybe<PageData>;
};

export type SalesShipmentLine = {
  __typename?: 'SalesShipmentLine';
  discriminator?: Maybe<Scalars['String']>;
  MagentoOrderLineType?: Maybe<Scalars['Float']>;
  MagentoOrderLineTypeOPTION?: Maybe<Scalars['String']>;
  MagentoOrderLineNo?: Maybe<Scalars['Float']>;
  MagentoOrderLineQty?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ItemShptEntryNo?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['String']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AuthorizedforCreditCard?: Maybe<Scalars['Boolean']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Boolean']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['String']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  RequestedDeliveryDate?: Maybe<Scalars['String']>;
  PromisedDeliveryDate?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  CreatedOnOrderBy?: Maybe<Scalars['String']>;
  ItemReorderingPolicy?: Maybe<Scalars['String']>;
  ItemReorderingPolicyOPTION?: Maybe<Scalars['String']>;
  PeabAccountNo?: Maybe<Scalars['String']>;
  FeeType?: Maybe<Scalars['String']>;
  FeeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  Correction?: Maybe<Scalars['Boolean']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  CreatedOnOrder?: Maybe<Scalars['DateTime']>;
  Originaldeliverydate?: Maybe<Scalars['DateTime']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  ItemChargeBaseAmount?: Maybe<Scalars['Float']>;
  CalculatedPrice?: Maybe<Scalars['Float']>;
  DisposableStockBalance?: Maybe<Scalars['Float']>;
  Currentpricecalculation?: Maybe<Scalars['Float']>;
  FeeAttachedtoLineNo?: Maybe<Scalars['Float']>;
  CustomerOrderLineNo?: Maybe<Scalars['Float']>;
  CustomerOrderedQuantity?: Maybe<Scalars['Float']>;
  TotalQuantityShipped?: Maybe<Scalars['Float']>;
  CurrentOutstandingQuantity?: Maybe<Scalars['Float']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesShipmentLineConnection = {
  __typename?: 'SalesShipmentLineConnection';
  count: Scalars['Int'];
  edges: Array<SalesShipmentLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalesShipmentLineConnectionEdge = {
  __typename?: 'SalesShipmentLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SalesShipmentLine;
};

export type SalesShipmentLineDeleteResponse = {
  __typename?: 'SalesShipmentLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SalesShipmentLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesShipmentLineManyResult = {
  __typename?: 'SalesShipmentLineManyResult';
  records?: Maybe<Array<Maybe<SalesShipmentLine>>>;
  pageData?: Maybe<PageData>;
};

export type Salesperson = {
  __typename?: 'Salesperson';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Commission?: Maybe<Scalars['Float']>;
  Image?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  SearchEMail?: Maybe<Scalars['String']>;
  EMail2?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ActionTakenFilter?: Maybe<Scalars['Float']>;
  ActionTakenFilterOPTION?: Maybe<Scalars['String']>;
  SalesCycleFilter?: Maybe<Scalars['String']>;
  SalesCycleStageFilter?: Maybe<Scalars['Float']>;
  ProbabilityFilter?: Maybe<Scalars['Float']>;
  CompletedFilter?: Maybe<Scalars['Float']>;
  ContactFilter?: Maybe<Scalars['String']>;
  ContactCompanyFilter?: Maybe<Scalars['String']>;
  CampaignFilter?: Maybe<Scalars['String']>;
  EstimatedValueFilter?: Maybe<Scalars['Float']>;
  CalcdCurrentValueFilter?: Maybe<Scalars['Float']>;
  ChancesofSuccessFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilterOPTION?: Maybe<Scalars['String']>;
  ClosedTaskFilter?: Maybe<Scalars['Boolean']>;
  PriorityFilter?: Maybe<Scalars['Float']>;
  PriorityFilterOPTION?: Maybe<Scalars['String']>;
  TeamFilter?: Maybe<Scalars['String']>;
  CloseOpportunityFilter?: Maybe<Scalars['String']>;
  NextTaskDate?: Maybe<Scalars['String']>;
  NoofOpportunities?: Maybe<Scalars['Float']>;
  EstimatedValueLCY?: Maybe<Scalars['Float']>;
  CalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  NoofInteractions?: Maybe<Scalars['Float']>;
  CostLCY?: Maybe<Scalars['Float']>;
  DurationMin?: Maybe<Scalars['Float']>;
  AvgEstimatedValueLCY?: Maybe<Scalars['Float']>;
  AvgCalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  OpportunityEntryExists?: Maybe<Scalars['Boolean']>;
  TaskEntryExists?: Maybe<Scalars['Boolean']>;
  EdgeRespCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  responsibilityCenter?: Maybe<ResponsibilityCenter>;
};

export type SalespersonConnection = {
  __typename?: 'SalespersonConnection';
  count: Scalars['Int'];
  edges: Array<SalespersonConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SalespersonConnectionEdge = {
  __typename?: 'SalespersonConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Salesperson;
};

export type SalespersonDeleteResponse = {
  __typename?: 'SalespersonDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Salesperson>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalespersonManyResult = {
  __typename?: 'SalespersonManyResult';
  records?: Maybe<Array<Maybe<Salesperson>>>;
  pageData?: Maybe<PageData>;
};

export type SendOnRentEmailInput = {
  Emails?: Maybe<Array<Maybe<EmailRecipientInput>>>;
  ProjectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SendOnRentEmailResponse = {
  __typename?: 'SendOnRentEmailResponse';
  ok?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type ServiceItemGroup = {
  __typename?: 'ServiceItemGroup';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreateServiceItem?: Maybe<Scalars['Boolean']>;
  DefaultContractDiscountPercent?: Maybe<Scalars['Float']>;
  DefaultServPriceGroupCode?: Maybe<Scalars['String']>;
  DefaultResponseTimeHours?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceItemGroupConnection = {
  __typename?: 'ServiceItemGroupConnection';
  count: Scalars['Int'];
  edges: Array<ServiceItemGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ServiceItemGroupConnectionEdge = {
  __typename?: 'ServiceItemGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ServiceItemGroup;
};

export type ServiceItemGroupDeleteResponse = {
  __typename?: 'ServiceItemGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ServiceItemGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ServiceItemGroupManyResult = {
  __typename?: 'ServiceItemGroupManyResult';
  records?: Maybe<Array<Maybe<ServiceItemGroup>>>;
  pageData?: Maybe<PageData>;
};

export type ShipmentMethod = {
  __typename?: 'ShipmentMethod';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ShipmentMethodConnection = {
  __typename?: 'ShipmentMethodConnection';
  count: Scalars['Int'];
  edges: Array<ShipmentMethodConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ShipmentMethodConnectionEdge = {
  __typename?: 'ShipmentMethodConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ShipmentMethod;
};

export type ShipmentMethodDeleteResponse = {
  __typename?: 'ShipmentMethodDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ShipmentMethod>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShipmentMethodManyResult = {
  __typename?: 'ShipmentMethodManyResult';
  records?: Maybe<Array<Maybe<ShipmentMethod>>>;
  pageData?: Maybe<PageData>;
};

export type ShippingAgent = {
  __typename?: 'ShippingAgent';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  InternetAddress?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  ShpfyTrackingCompany?: Maybe<Scalars['Float']>;
  ShpfyTrackingCompanyOPTION?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type ShippingAgentConnection = {
  __typename?: 'ShippingAgentConnection';
  count: Scalars['Int'];
  edges: Array<ShippingAgentConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ShippingAgentConnectionEdge = {
  __typename?: 'ShippingAgentConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ShippingAgent;
};

export type ShippingAgentDeleteResponse = {
  __typename?: 'ShippingAgentDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<ShippingAgent>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShippingAgentManyResult = {
  __typename?: 'ShippingAgentManyResult';
  records?: Maybe<Array<Maybe<ShippingAgent>>>;
  pageData?: Maybe<PageData>;
};

export type SignRequest = {
  __typename?: 'SignRequest';
  discriminator?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  DocumentUri?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentEtag?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
  SigningUserEmail?: Maybe<Scalars['String']>;
  SigningUserName?: Maybe<Scalars['String']>;
  PrintedName?: Maybe<Scalars['String']>;
  SignMethod?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  GlobalId?: Maybe<Scalars['ID']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Base64WriteOnly?: Maybe<Scalars['String']>;
  MobileBankIdSSN?: Maybe<Scalars['String']>;
  MobileBankIdUserVisibleData?: Maybe<Scalars['String']>;
  orderRef?: Maybe<Scalars['String']>;
  autoStartToken?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  hintCode?: Maybe<Scalars['String']>;
  endUserIp?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SignRequestConnection = {
  __typename?: 'SignRequestConnection';
  count: Scalars['Int'];
  edges: Array<SignRequestConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SignRequestConnectionEdge = {
  __typename?: 'SignRequestConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SignRequest;
};

export type SignRequestDeleteResponse = {
  __typename?: 'SignRequestDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SignRequest>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SignRequestManyResult = {
  __typename?: 'SignRequestManyResult';
  records?: Maybe<Array<Maybe<SignRequest>>>;
  pageData?: Maybe<PageData>;
};

export type Signature = {
  __typename?: 'Signature';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SignatureConnection = {
  __typename?: 'SignatureConnection';
  count: Scalars['Int'];
  edges: Array<SignatureConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SignatureConnectionEdge = {
  __typename?: 'SignatureConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Signature;
};

export type SignatureDeleteResponse = {
  __typename?: 'SignatureDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Signature>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SignatureManyResult = {
  __typename?: 'SignatureManyResult';
  records?: Maybe<Array<Maybe<Signature>>>;
  pageData?: Maybe<PageData>;
};

export enum SortAccessRequestInput {
  /** Sort on UserEmail ascending */
  UseremailAsc = '_USEREMAIL_ASC_',
  /** sort on UserEmail descending */
  UseremailDesc = '_USEREMAIL_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on TargetName ascending */
  TargetnameAsc = '_TARGETNAME_ASC_',
  /** sort on TargetName descending */
  TargetnameDesc = '_TARGETNAME_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on Approved ascending */
  ApprovedAsc = '_APPROVED_ASC_',
  /** sort on Approved descending */
  ApprovedDesc = '_APPROVED_DESC_',
  /** Sort on ApprovedOn ascending */
  ApprovedonAsc = '_APPROVEDON_ASC_',
  /** sort on ApprovedOn descending */
  ApprovedonDesc = '_APPROVEDON_DESC_',
  /** Sort on RequestedOn ascending */
  RequestedonAsc = '_REQUESTEDON_ASC_',
  /** sort on RequestedOn descending */
  RequestedonDesc = '_REQUESTEDON_DESC_',
  /** Sort on ApprovedByUserGlobalId ascending */
  ApprovedbyuserglobalidAsc = '_APPROVEDBYUSERGLOBALID_ASC_',
  /** sort on ApprovedByUserGlobalId descending */
  ApprovedbyuserglobalidDesc = '_APPROVEDBYUSERGLOBALID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortAdditionalItemInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on AdditionalItemNo ascending */
  AdditionalitemnoAsc = '_ADDITIONALITEMNO_ASC_',
  /** sort on AdditionalItemNo descending */
  AdditionalitemnoDesc = '_ADDITIONALITEMNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on QuantityOption ascending */
  QuantityoptionAsc = '_QUANTITYOPTION_ASC_',
  /** sort on QuantityOption descending */
  QuantityoptionDesc = '_QUANTITYOPTION_DESC_',
  /** Sort on QuantityOptionOPTION ascending */
  QuantityoptionoptionAsc = '_QUANTITYOPTIONOPTION_ASC_',
  /** sort on QuantityOptionOPTION descending */
  QuantityoptionoptionDesc = '_QUANTITYOPTIONOPTION_DESC_',
  /** Sort on QuantityRatio ascending */
  QuantityratioAsc = '_QUANTITYRATIO_ASC_',
  /** sort on QuantityRatio descending */
  QuantityratioDesc = '_QUANTITYRATIO_DESC_',
  /** Sort on MinRequiredQty ascending */
  MinrequiredqtyAsc = '_MINREQUIREDQTY_ASC_',
  /** sort on MinRequiredQty descending */
  MinrequiredqtyDesc = '_MINREQUIREDQTY_DESC_',
  /** Sort on UseZeroPrice ascending */
  UsezeropriceAsc = '_USEZEROPRICE_ASC_',
  /** sort on UseZeroPrice descending */
  UsezeropriceDesc = '_USEZEROPRICE_DESC_',
  /** Sort on Active ascending */
  ActiveAsc = '_ACTIVE_ASC_',
  /** sort on Active descending */
  ActiveDesc = '_ACTIVE_DESC_',
  /** Sort on Price ascending */
  PriceAsc = '_PRICE_ASC_',
  /** sort on Price descending */
  PriceDesc = '_PRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortAddressInput {
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PlaceofExport ascending */
  PlaceofexportAsc = '_PLACEOFEXPORT_ASC_',
  /** sort on PlaceofExport descending */
  PlaceofexportDesc = '_PLACEOFEXPORT_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on TelexAnswerBack ascending */
  TelexanswerbackAsc = '_TELEXANSWERBACK_ASC_',
  /** sort on TelexAnswerBack descending */
  TelexanswerbackDesc = '_TELEXANSWERBACK_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ServiceZoneCode ascending */
  ServicezonecodeAsc = '_SERVICEZONECODE_ASC_',
  /** sort on ServiceZoneCode descending */
  ServicezonecodeDesc = '_SERVICEZONECODE_DESC_',
  /** Sort on ShipmentDays ascending */
  ShipmentdaysAsc = '_SHIPMENTDAYS_ASC_',
  /** sort on ShipmentDays descending */
  ShipmentdaysDesc = '_SHIPMENTDAYS_DESC_',
  /** Sort on SharkFreightInstrToWsh ascending */
  SharkfreightinstrtowshAsc = '_SHARKFREIGHTINSTRTOWSH_ASC_',
  /** sort on SharkFreightInstrToWsh descending */
  SharkfreightinstrtowshDesc = '_SHARKFREIGHTINSTRTOWSH_DESC_',
  /** Sort on Tour ascending */
  TourAsc = '_TOUR_ASC_',
  /** sort on Tour descending */
  TourDesc = '_TOUR_DESC_',
  /** Sort on TourNo ascending */
  TournoAsc = '_TOURNO_ASC_',
  /** sort on TourNo descending */
  TournoDesc = '_TOURNO_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on MagentoAddressId ascending */
  MagentoaddressidAsc = '_MAGENTOADDRESSID_ASC_',
  /** sort on MagentoAddressId descending */
  MagentoaddressidDesc = '_MAGENTOADDRESSID_DESC_',
  /** Sort on CustomerGlobalId ascending */
  CustomerglobalidAsc = '_CUSTOMERGLOBALID_ASC_',
  /** sort on CustomerGlobalId descending */
  CustomerglobalidDesc = '_CUSTOMERGLOBALID_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on Fax ascending */
  FaxAsc = '_FAX_ASC_',
  /** sort on Fax descending */
  FaxDesc = '_FAX_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortAgreementInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on RecName ascending */
  RecnameAsc = '_RECNAME_ASC_',
  /** sort on RecName descending */
  RecnameDesc = '_RECNAME_DESC_',
  /** Sort on RecId ascending */
  RecidAsc = '_RECID_ASC_',
  /** sort on RecId descending */
  RecidDesc = '_RECID_DESC_',
  /** Sort on AgreementType ascending */
  AgreementtypeAsc = '_AGREEMENTTYPE_ASC_',
  /** sort on AgreementType descending */
  AgreementtypeDesc = '_AGREEMENTTYPE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on FromDate ascending */
  FromdateAsc = '_FROMDATE_ASC_',
  /** sort on FromDate descending */
  FromdateDesc = '_FROMDATE_DESC_',
  /** Sort on ToDate ascending */
  TodateAsc = '_TODATE_ASC_',
  /** sort on ToDate descending */
  TodateDesc = '_TODATE_DESC_',
  /** Sort on SignatureType ascending */
  SignaturetypeAsc = '_SIGNATURETYPE_ASC_',
  /** sort on SignatureType descending */
  SignaturetypeDesc = '_SIGNATURETYPE_DESC_',
  /** Sort on Signature ascending */
  SignatureAsc = '_SIGNATURE_ASC_',
  /** sort on Signature descending */
  SignatureDesc = '_SIGNATURE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortAnnotationInput {
  /** Sort on ObjectTypeCode ascending */
  ObjecttypecodeAsc = '_OBJECTTYPECODE_ASC_',
  /** sort on ObjectTypeCode descending */
  ObjecttypecodeDesc = '_OBJECTTYPECODE_DESC_',
  /** Sort on NoteText ascending */
  NotetextAsc = '_NOTETEXT_ASC_',
  /** sort on NoteText descending */
  NotetextDesc = '_NOTETEXT_DESC_',
  /** Sort on OwnerId ascending */
  OwneridAsc = '_OWNERID_ASC_',
  /** sort on OwnerId descending */
  OwneridDesc = '_OWNERID_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on OwnerEmail ascending */
  OwneremailAsc = '_OWNEREMAIL_ASC_',
  /** sort on OwnerEmail descending */
  OwneremailDesc = '_OWNEREMAIL_DESC_',
  /** Sort on OwnerName ascending */
  OwnernameAsc = '_OWNERNAME_ASC_',
  /** sort on OwnerName descending */
  OwnernameDesc = '_OWNERNAME_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortApplicationModuleInput {
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on ShortName ascending */
  ShortnameAsc = '_SHORTNAME_ASC_',
  /** sort on ShortName descending */
  ShortnameDesc = '_SHORTNAME_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ShortDescription ascending */
  ShortdescriptionAsc = '_SHORTDESCRIPTION_ASC_',
  /** sort on ShortDescription descending */
  ShortdescriptionDesc = '_SHORTDESCRIPTION_DESC_',
  /** Sort on DefaultWebLink ascending */
  DefaultweblinkAsc = '_DEFAULTWEBLINK_ASC_',
  /** sort on DefaultWebLink descending */
  DefaultweblinkDesc = '_DEFAULTWEBLINK_DESC_',
  /** Sort on DefaultAppNavStackName ascending */
  DefaultappnavstacknameAsc = '_DEFAULTAPPNAVSTACKNAME_ASC_',
  /** sort on DefaultAppNavStackName descending */
  DefaultappnavstacknameDesc = '_DEFAULTAPPNAVSTACKNAME_DESC_',
  /** Sort on SearchOrder ascending */
  SearchorderAsc = '_SEARCHORDER_ASC_',
  /** sort on SearchOrder descending */
  SearchorderDesc = '_SEARCHORDER_DESC_',
  /** Sort on NavigationOrder ascending */
  NavigationorderAsc = '_NAVIGATIONORDER_ASC_',
  /** sort on NavigationOrder descending */
  NavigationorderDesc = '_NAVIGATIONORDER_DESC_',
  /** Sort on Searchable ascending */
  SearchableAsc = '_SEARCHABLE_ASC_',
  /** sort on Searchable descending */
  SearchableDesc = '_SEARCHABLE_DESC_',
  /** Sort on ShowOnWebHeader ascending */
  ShowonwebheaderAsc = '_SHOWONWEBHEADER_ASC_',
  /** sort on ShowOnWebHeader descending */
  ShowonwebheaderDesc = '_SHOWONWEBHEADER_DESC_',
  /** Sort on ShowOnAppMainTab ascending */
  ShowonappmaintabAsc = '_SHOWONAPPMAINTAB_ASC_',
  /** sort on ShowOnAppMainTab descending */
  ShowonappmaintabDesc = '_SHOWONAPPMAINTAB_DESC_',
  /** Sort on VisibleForCustomer ascending */
  VisibleforcustomerAsc = '_VISIBLEFORCUSTOMER_ASC_',
  /** sort on VisibleForCustomer descending */
  VisibleforcustomerDesc = '_VISIBLEFORCUSTOMER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortBcMappingFieldInput {
  /** Sort on NBAEDirection ascending */
  NbaedirectionAsc = '_NBAEDIRECTION_ASC_',
  /** sort on NBAEDirection descending */
  NbaedirectionDesc = '_NBAEDIRECTION_DESC_',
  /** Sort on NBAEDirectionOPTION ascending */
  NbaedirectionoptionAsc = '_NBAEDIRECTIONOPTION_ASC_',
  /** sort on NBAEDirectionOPTION descending */
  NbaedirectionoptionDesc = '_NBAEDIRECTIONOPTION_DESC_',
  /** Sort on NBAETableNo ascending */
  NbaetablenoAsc = '_NBAETABLENO_ASC_',
  /** sort on NBAETableNo descending */
  NbaetablenoDesc = '_NBAETABLENO_DESC_',
  /** Sort on NBAEEventType ascending */
  NbaeeventtypeAsc = '_NBAEEVENTTYPE_ASC_',
  /** sort on NBAEEventType descending */
  NbaeeventtypeDesc = '_NBAEEVENTTYPE_DESC_',
  /** Sort on NBAEEventTypeOPTION ascending */
  NbaeeventtypeoptionAsc = '_NBAEEVENTTYPEOPTION_ASC_',
  /** sort on NBAEEventTypeOPTION descending */
  NbaeeventtypeoptionDesc = '_NBAEEVENTTYPEOPTION_DESC_',
  /** Sort on NBAESequenceNo ascending */
  NbaesequencenoAsc = '_NBAESEQUENCENO_ASC_',
  /** sort on NBAESequenceNo descending */
  NbaesequencenoDesc = '_NBAESEQUENCENO_DESC_',
  /** Sort on NBAELineNo ascending */
  NbaelinenoAsc = '_NBAELINENO_ASC_',
  /** sort on NBAELineNo descending */
  NbaelinenoDesc = '_NBAELINENO_DESC_',
  /** Sort on NBAEPKField ascending */
  NbaepkfieldAsc = '_NBAEPKFIELD_ASC_',
  /** sort on NBAEPKField descending */
  NbaepkfieldDesc = '_NBAEPKFIELD_DESC_',
  /** Sort on NBAEIgnore ascending */
  NbaeignoreAsc = '_NBAEIGNORE_ASC_',
  /** sort on NBAEIgnore descending */
  NbaeignoreDesc = '_NBAEIGNORE_DESC_',
  /** Sort on NBAESortingOrder ascending */
  NbaesortingorderAsc = '_NBAESORTINGORDER_ASC_',
  /** sort on NBAESortingOrder descending */
  NbaesortingorderDesc = '_NBAESORTINGORDER_DESC_',
  /** Sort on NBAEOutFromDataType ascending */
  NbaeoutfromdatatypeAsc = '_NBAEOUTFROMDATATYPE_ASC_',
  /** sort on NBAEOutFromDataType descending */
  NbaeoutfromdatatypeDesc = '_NBAEOUTFROMDATATYPE_DESC_',
  /** Sort on NBAEOutFromDataTypeOPTION ascending */
  NbaeoutfromdatatypeoptionAsc = '_NBAEOUTFROMDATATYPEOPTION_ASC_',
  /** sort on NBAEOutFromDataTypeOPTION descending */
  NbaeoutfromdatatypeoptionDesc = '_NBAEOUTFROMDATATYPEOPTION_DESC_',
  /** Sort on NBAEOutFromDataSubType ascending */
  NbaeoutfromdatasubtypeAsc = '_NBAEOUTFROMDATASUBTYPE_ASC_',
  /** sort on NBAEOutFromDataSubType descending */
  NbaeoutfromdatasubtypeDesc = '_NBAEOUTFROMDATASUBTYPE_DESC_',
  /** Sort on NBAEOutToJSONElement ascending */
  NbaeouttojsonelementAsc = '_NBAEOUTTOJSONELEMENT_ASC_',
  /** sort on NBAEOutToJSONElement descending */
  NbaeouttojsonelementDesc = '_NBAEOUTTOJSONELEMENT_DESC_',
  /** Sort on NBAEInFromDataType ascending */
  NbaeinfromdatatypeAsc = '_NBAEINFROMDATATYPE_ASC_',
  /** sort on NBAEInFromDataType descending */
  NbaeinfromdatatypeDesc = '_NBAEINFROMDATATYPE_DESC_',
  /** Sort on NBAEInFromDataTypeOPTION ascending */
  NbaeinfromdatatypeoptionAsc = '_NBAEINFROMDATATYPEOPTION_ASC_',
  /** sort on NBAEInFromDataTypeOPTION descending */
  NbaeinfromdatatypeoptionDesc = '_NBAEINFROMDATATYPEOPTION_DESC_',
  /** Sort on NBAEInFromDataSubType ascending */
  NbaeinfromdatasubtypeAsc = '_NBAEINFROMDATASUBTYPE_ASC_',
  /** sort on NBAEInFromDataSubType descending */
  NbaeinfromdatasubtypeDesc = '_NBAEINFROMDATASUBTYPE_DESC_',
  /** Sort on NBAEInOperator ascending */
  NbaeinoperatorAsc = '_NBAEINOPERATOR_ASC_',
  /** sort on NBAEInOperator descending */
  NbaeinoperatorDesc = '_NBAEINOPERATOR_DESC_',
  /** Sort on NBAEInOperatorOPTION ascending */
  NbaeinoperatoroptionAsc = '_NBAEINOPERATOROPTION_ASC_',
  /** sort on NBAEInOperatorOPTION descending */
  NbaeinoperatoroptionDesc = '_NBAEINOPERATOROPTION_DESC_',
  /** Sort on NBAEInToFieldNo ascending */
  NbaeintofieldnoAsc = '_NBAEINTOFIELDNO_ASC_',
  /** sort on NBAEInToFieldNo descending */
  NbaeintofieldnoDesc = '_NBAEINTOFIELDNO_DESC_',
  /** Sort on NBAEInToFieldName ascending */
  NbaeintofieldnameAsc = '_NBAEINTOFIELDNAME_ASC_',
  /** sort on NBAEInToFieldName descending */
  NbaeintofieldnameDesc = '_NBAEINTOFIELDNAME_DESC_',
  /** Sort on NBAEInValidate ascending */
  NbaeinvalidateAsc = '_NBAEINVALIDATE_ASC_',
  /** sort on NBAEInValidate descending */
  NbaeinvalidateDesc = '_NBAEINVALIDATE_DESC_',
  /** Sort on NBAEInTranslatesFromField ascending */
  NbaeintranslatesfromfieldAsc = '_NBAEINTRANSLATESFROMFIELD_ASC_',
  /** sort on NBAEInTranslatesFromField descending */
  NbaeintranslatesfromfieldDesc = '_NBAEINTRANSLATESFROMFIELD_DESC_',
  /** Sort on NBAELineFieldNo ascending */
  NbaelinefieldnoAsc = '_NBAELINEFIELDNO_ASC_',
  /** sort on NBAELineFieldNo descending */
  NbaelinefieldnoDesc = '_NBAELINEFIELDNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortBcMappingTableInput {
  /** Sort on NBAEDirection ascending */
  NbaedirectionAsc = '_NBAEDIRECTION_ASC_',
  /** sort on NBAEDirection descending */
  NbaedirectionDesc = '_NBAEDIRECTION_DESC_',
  /** Sort on NBAEDirectionOPTION ascending */
  NbaedirectionoptionAsc = '_NBAEDIRECTIONOPTION_ASC_',
  /** sort on NBAEDirectionOPTION descending */
  NbaedirectionoptionDesc = '_NBAEDIRECTIONOPTION_DESC_',
  /** Sort on NBAETableNo ascending */
  NbaetablenoAsc = '_NBAETABLENO_ASC_',
  /** sort on NBAETableNo descending */
  NbaetablenoDesc = '_NBAETABLENO_DESC_',
  /** Sort on NBAEEventType ascending */
  NbaeeventtypeAsc = '_NBAEEVENTTYPE_ASC_',
  /** sort on NBAEEventType descending */
  NbaeeventtypeDesc = '_NBAEEVENTTYPE_DESC_',
  /** Sort on NBAEEventTypeOPTION ascending */
  NbaeeventtypeoptionAsc = '_NBAEEVENTTYPEOPTION_ASC_',
  /** sort on NBAEEventTypeOPTION descending */
  NbaeeventtypeoptionDesc = '_NBAEEVENTTYPEOPTION_DESC_',
  /** Sort on NBAESequenceNo ascending */
  NbaesequencenoAsc = '_NBAESEQUENCENO_ASC_',
  /** sort on NBAESequenceNo descending */
  NbaesequencenoDesc = '_NBAESEQUENCENO_DESC_',
  /** Sort on NBAETableName ascending */
  NbaetablenameAsc = '_NBAETABLENAME_ASC_',
  /** sort on NBAETableName descending */
  NbaetablenameDesc = '_NBAETABLENAME_DESC_',
  /** Sort on NBAEIgnore ascending */
  NbaeignoreAsc = '_NBAEIGNORE_ASC_',
  /** sort on NBAEIgnore descending */
  NbaeignoreDesc = '_NBAEIGNORE_DESC_',
  /** Sort on NBAEEntityName ascending */
  NbaeentitynameAsc = '_NBAEENTITYNAME_ASC_',
  /** sort on NBAEEntityName descending */
  NbaeentitynameDesc = '_NBAEENTITYNAME_DESC_',
  /** Sort on NBAEDescription ascending */
  NbaedescriptionAsc = '_NBAEDESCRIPTION_ASC_',
  /** sort on NBAEDescription descending */
  NbaedescriptionDesc = '_NBAEDESCRIPTION_DESC_',
  /** Sort on NBAEProcessingOrder ascending */
  NbaeprocessingorderAsc = '_NBAEPROCESSINGORDER_ASC_',
  /** sort on NBAEProcessingOrder descending */
  NbaeprocessingorderDesc = '_NBAEPROCESSINGORDER_DESC_',
  /** Sort on NBAEProcessOnReceipt ascending */
  NbaeprocessonreceiptAsc = '_NBAEPROCESSONRECEIPT_ASC_',
  /** sort on NBAEProcessOnReceipt descending */
  NbaeprocessonreceiptDesc = '_NBAEPROCESSONRECEIPT_DESC_',
  /** Sort on NBAEProcessAsTransactions ascending */
  NbaeprocessastransactionsAsc = '_NBAEPROCESSASTRANSACTIONS_ASC_',
  /** sort on NBAEProcessAsTransactions descending */
  NbaeprocessastransactionsDesc = '_NBAEPROCESSASTRANSACTIONS_DESC_',
  /** Sort on NBAEFields ascending */
  NbaefieldsAsc = '_NBAEFIELDS_ASC_',
  /** sort on NBAEFields descending */
  NbaefieldsDesc = '_NBAEFIELDS_DESC_',
  /** Sort on NBAEFilters ascending */
  NbaefiltersAsc = '_NBAEFILTERS_ASC_',
  /** sort on NBAEFilters descending */
  NbaefiltersDesc = '_NBAEFILTERS_DESC_',
  /** Sort on NBAERelations ascending */
  NbaerelationsAsc = '_NBAERELATIONS_ASC_',
  /** sort on NBAERelations descending */
  NbaerelationsDesc = '_NBAERELATIONS_DESC_',
  /** Sort on NBAEDelay ascending */
  NbaedelayAsc = '_NBAEDELAY_ASC_',
  /** sort on NBAEDelay descending */
  NbaedelayDesc = '_NBAEDELAY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortBcSettingsInput {
  /** Sort on NBAECode ascending */
  NbaecodeAsc = '_NBAECODE_ASC_',
  /** sort on NBAECode descending */
  NbaecodeDesc = '_NBAECODE_DESC_',
  /** Sort on NBAELineNo ascending */
  NbaelinenoAsc = '_NBAELINENO_ASC_',
  /** sort on NBAELineNo descending */
  NbaelinenoDesc = '_NBAELINENO_DESC_',
  /** Sort on NBAESettingName ascending */
  NbaesettingnameAsc = '_NBAESETTINGNAME_ASC_',
  /** sort on NBAESettingName descending */
  NbaesettingnameDesc = '_NBAESETTINGNAME_DESC_',
  /** Sort on NBAESettingType ascending */
  NbaesettingtypeAsc = '_NBAESETTINGTYPE_ASC_',
  /** sort on NBAESettingType descending */
  NbaesettingtypeDesc = '_NBAESETTINGTYPE_DESC_',
  /** Sort on NBAESettingTypeOPTION ascending */
  NbaesettingtypeoptionAsc = '_NBAESETTINGTYPEOPTION_ASC_',
  /** sort on NBAESettingTypeOPTION descending */
  NbaesettingtypeoptionDesc = '_NBAESETTINGTYPEOPTION_DESC_',
  /** Sort on NBAESettingValue ascending */
  NbaesettingvalueAsc = '_NBAESETTINGVALUE_ASC_',
  /** sort on NBAESettingValue descending */
  NbaesettingvalueDesc = '_NBAESETTINGVALUE_DESC_',
  /** Sort on NBAEIgnore ascending */
  NbaeignoreAsc = '_NBAEIGNORE_ASC_',
  /** sort on NBAEIgnore descending */
  NbaeignoreDesc = '_NBAEIGNORE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortBulkItemGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on MagentoAttribOptionId ascending */
  MagentoattriboptionidAsc = '_MAGENTOATTRIBOPTIONID_ASC_',
  /** sort on MagentoAttribOptionId descending */
  MagentoattriboptionidDesc = '_MAGENTOATTRIBOPTIONID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCauseOfAbsenceInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on EmployeeNoFilter ascending */
  EmployeenofilterAsc = '_EMPLOYEENOFILTER_ASC_',
  /** sort on EmployeeNoFilter descending */
  EmployeenofilterDesc = '_EMPLOYEENOFILTER_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on TotalAbsenceBase ascending */
  TotalabsencebaseAsc = '_TOTALABSENCEBASE_ASC_',
  /** sort on TotalAbsenceBase descending */
  TotalabsencebaseDesc = '_TOTALABSENCEBASE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCompanyInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on PrimaryKey ascending */
  PrimarykeyAsc = '_PRIMARYKEY_ASC_',
  /** sort on PrimaryKey descending */
  PrimarykeyDesc = '_PRIMARYKEY_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on PhoneNo2 ascending */
  Phoneno2Asc = '_PHONENO2_ASC_',
  /** sort on PhoneNo2 descending */
  Phoneno2Desc = '_PHONENO2_DESC_',
  /** Sort on TelexNo ascending */
  TelexnoAsc = '_TELEXNO_ASC_',
  /** sort on TelexNo descending */
  TelexnoDesc = '_TELEXNO_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on DocumentSendingProfile ascending */
  DocumentsendingprofileAsc = '_DOCUMENTSENDINGPROFILE_ASC_',
  /** sort on DocumentSendingProfile descending */
  DocumentsendingprofileDesc = '_DOCUMENTSENDINGPROFILE_DESC_',
  /** Sort on OurAccountNo ascending */
  OuraccountnoAsc = '_OURACCOUNTNO_ASC_',
  /** sort on OurAccountNo descending */
  OuraccountnoDesc = '_OURACCOUNTNO_DESC_',
  /** Sort on TerritoryCode ascending */
  TerritorycodeAsc = '_TERRITORYCODE_ASC_',
  /** sort on TerritoryCode descending */
  TerritorycodeDesc = '_TERRITORYCODE_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on ChainName ascending */
  ChainnameAsc = '_CHAINNAME_ASC_',
  /** sort on ChainName descending */
  ChainnameDesc = '_CHAINNAME_DESC_',
  /** Sort on BudgetedAmount ascending */
  BudgetedamountAsc = '_BUDGETEDAMOUNT_ASC_',
  /** sort on BudgetedAmount descending */
  BudgetedamountDesc = '_BUDGETEDAMOUNT_DESC_',
  /** Sort on CreditLimitLCY ascending */
  CreditlimitlcyAsc = '_CREDITLIMITLCY_ASC_',
  /** sort on CreditLimitLCY descending */
  CreditlimitlcyDesc = '_CREDITLIMITLCY_DESC_',
  /** Sort on CustomerPostingGroup ascending */
  CustomerpostinggroupAsc = '_CUSTOMERPOSTINGGROUP_ASC_',
  /** sort on CustomerPostingGroup descending */
  CustomerpostinggroupDesc = '_CUSTOMERPOSTINGGROUP_DESC_',
  /** Sort on GiroNo ascending */
  GironoAsc = '_GIRONO_ASC_',
  /** sort on GiroNo descending */
  GironoDesc = '_GIRONO_DESC_',
  /** Sort on BankName ascending */
  BanknameAsc = '_BANKNAME_ASC_',
  /** sort on BankName descending */
  BanknameDesc = '_BANKNAME_DESC_',
  /** Sort on BankBranchNo ascending */
  BankbranchnoAsc = '_BANKBRANCHNO_ASC_',
  /** sort on BankBranchNo descending */
  BankbranchnoDesc = '_BANKBRANCHNO_DESC_',
  /** Sort on BankAccountNo ascending */
  BankaccountnoAsc = '_BANKACCOUNTNO_ASC_',
  /** sort on BankAccountNo descending */
  BankaccountnoDesc = '_BANKACCOUNTNO_DESC_',
  /** Sort on PaymentRoutingNo ascending */
  PaymentroutingnoAsc = '_PAYMENTROUTINGNO_ASC_',
  /** sort on PaymentRoutingNo descending */
  PaymentroutingnoDesc = '_PAYMENTROUTINGNO_DESC_',
  /** Sort on CustomsPermitNo ascending */
  CustomspermitnoAsc = '_CUSTOMSPERMITNO_ASC_',
  /** sort on CustomsPermitNo descending */
  CustomspermitnoDesc = '_CUSTOMSPERMITNO_DESC_',
  /** Sort on CustomsPermitDate ascending */
  CustomspermitdateAsc = '_CUSTOMSPERMITDATE_ASC_',
  /** sort on CustomsPermitDate descending */
  CustomspermitdateDesc = '_CUSTOMSPERMITDATE_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on RegistrationNo ascending */
  RegistrationnoAsc = '_REGISTRATIONNO_ASC_',
  /** sort on RegistrationNo descending */
  RegistrationnoDesc = '_REGISTRATIONNO_DESC_',
  /** Sort on TelexAnswerBack ascending */
  TelexanswerbackAsc = '_TELEXANSWERBACK_ASC_',
  /** sort on TelexAnswerBack descending */
  TelexanswerbackDesc = '_TELEXANSWERBACK_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on PrimaryContactNo ascending */
  PrimarycontactnoAsc = '_PRIMARYCONTACTNO_ASC_',
  /** sort on PrimaryContactNo descending */
  PrimarycontactnoDesc = '_PRIMARYCONTACTNO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on BlockedOPTION ascending */
  BlockedoptionAsc = '_BLOCKEDOPTION_ASC_',
  /** sort on BlockedOPTION descending */
  BlockedoptionDesc = '_BLOCKEDOPTION_DESC_',
  /** Sort on IBAN ascending */
  IbanAsc = '_IBAN_ASC_',
  /** sort on IBAN descending */
  IbanDesc = '_IBAN_DESC_',
  /** Sort on SWIFTCode ascending */
  SwiftcodeAsc = '_SWIFTCODE_ASC_',
  /** sort on SWIFTCode descending */
  SwiftcodeDesc = '_SWIFTCODE_DESC_',
  /** Sort on IndustrialClassification ascending */
  IndustrialclassificationAsc = '_INDUSTRIALCLASSIFICATION_ASC_',
  /** sort on IndustrialClassification descending */
  IndustrialclassificationDesc = '_INDUSTRIALCLASSIFICATION_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on ICInboxType ascending */
  IcinboxtypeAsc = '_ICINBOXTYPE_ASC_',
  /** sort on ICInboxType descending */
  IcinboxtypeDesc = '_ICINBOXTYPE_DESC_',
  /** Sort on ICInboxDetails ascending */
  IcinboxdetailsAsc = '_ICINBOXDETAILS_ASC_',
  /** sort on ICInboxDetails descending */
  IcinboxdetailsDesc = '_ICINBOXDETAILS_DESC_',
  /** Sort on SystemIndicator ascending */
  SystemindicatorAsc = '_SYSTEMINDICATOR_ASC_',
  /** sort on SystemIndicator descending */
  SystemindicatorDesc = '_SYSTEMINDICATOR_DESC_',
  /** Sort on CustomSystemIndicatorText ascending */
  CustomsystemindicatortextAsc = '_CUSTOMSYSTEMINDICATORTEXT_ASC_',
  /** sort on CustomSystemIndicatorText descending */
  CustomsystemindicatortextDesc = '_CUSTOMSYSTEMINDICATORTEXT_DESC_',
  /** Sort on SystemIndicatorStyle ascending */
  SystemindicatorstyleAsc = '_SYSTEMINDICATORSTYLE_ASC_',
  /** sort on SystemIndicatorStyle descending */
  SystemindicatorstyleDesc = '_SYSTEMINDICATORSTYLE_DESC_',
  /** Sort on AllowBlankPaymentInfo ascending */
  AllowblankpaymentinfoAsc = '_ALLOWBLANKPAYMENTINFO_ASC_',
  /** sort on AllowBlankPaymentInfo descending */
  AllowblankpaymentinfoDesc = '_ALLOWBLANKPAYMENTINFO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on CheckAvailPeriodCalc ascending */
  CheckavailperiodcalcAsc = '_CHECKAVAILPERIODCALC_ASC_',
  /** sort on CheckAvailPeriodCalc descending */
  CheckavailperiodcalcDesc = '_CHECKAVAILPERIODCALC_DESC_',
  /** Sort on CheckAvailTimeBucket ascending */
  CheckavailtimebucketAsc = '_CHECKAVAILTIMEBUCKET_ASC_',
  /** sort on CheckAvailTimeBucket descending */
  CheckavailtimebucketDesc = '_CHECKAVAILTIMEBUCKET_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on CalConvergenceTimeFrame ascending */
  CalconvergencetimeframeAsc = '_CALCONVERGENCETIMEFRAME_ASC_',
  /** sort on CalConvergenceTimeFrame descending */
  CalconvergencetimeframeDesc = '_CALCONVERGENCETIMEFRAME_DESC_',
  /** Sort on PlusGiroNo ascending */
  PlusgironoAsc = '_PLUSGIRONO_ASC_',
  /** sort on PlusGiroNo descending */
  PlusgironoDesc = '_PLUSGIRONO_DESC_',
  /** Sort on RegisteredOffice ascending */
  RegisteredofficeAsc = '_REGISTEREDOFFICE_ASC_',
  /** sort on RegisteredOffice descending */
  RegisteredofficeDesc = '_REGISTEREDOFFICE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on UseExtraNavetPermissions ascending */
  UseextranavetpermissionsAsc = '_USEEXTRANAVETPERMISSIONS_ASC_',
  /** sort on UseExtraNavetPermissions descending */
  UseextranavetpermissionsDesc = '_USEEXTRANAVETPERMISSIONS_DESC_',
  /** Sort on BlockObjectTypeModify ascending */
  BlockobjecttypemodifyAsc = '_BLOCKOBJECTTYPEMODIFY_ASC_',
  /** sort on BlockObjectTypeModify descending */
  BlockobjecttypemodifyDesc = '_BLOCKOBJECTTYPEMODIFY_DESC_',
  /** Sort on UseStandardPriceCalculation ascending */
  UsestandardpricecalculationAsc = '_USESTANDARDPRICECALCULATION_ASC_',
  /** sort on UseStandardPriceCalculation descending */
  UsestandardpricecalculationDesc = '_USESTANDARDPRICECALCULATION_DESC_',
  /** Sort on UseStavdalResourcePrice ascending */
  UsestavdalresourcepriceAsc = '_USESTAVDALRESOURCEPRICE_ASC_',
  /** sort on UseStavdalResourcePrice descending */
  UsestavdalresourcepriceDesc = '_USESTAVDALRESOURCEPRICE_DESC_',
  /** Sort on CombineShipments ascending */
  CombineshipmentsAsc = '_COMBINESHIPMENTS_ASC_',
  /** sort on CombineShipments descending */
  CombineshipmentsDesc = '_COMBINESHIPMENTS_DESC_',
  /** Sort on GLN ascending */
  GlnAsc = '_GLN_ASC_',
  /** sort on GLN descending */
  GlnDesc = '_GLN_DESC_',
  /** Sort on ProductionDatabase ascending */
  ProductiondatabaseAsc = '_PRODUCTIONDATABASE_ASC_',
  /** sort on ProductionDatabase descending */
  ProductiondatabaseDesc = '_PRODUCTIONDATABASE_DESC_',
  /** Sort on MarkedAsTest ascending */
  MarkedastestAsc = '_MARKEDASTEST_ASC_',
  /** sort on MarkedAsTest descending */
  MarkedastestDesc = '_MARKEDASTEST_DESC_',
  /** Sort on DontAskAgain ascending */
  DontaskagainAsc = '_DONTASKAGAIN_ASC_',
  /** sort on DontAskAgain descending */
  DontaskagainDesc = '_DONTASKAGAIN_DESC_',
  /** Sort on TestEmail ascending */
  TestemailAsc = '_TESTEMAIL_ASC_',
  /** sort on TestEmail descending */
  TestemailDesc = '_TESTEMAIL_DESC_',
  /** Sort on BaseisInitiated ascending */
  BaseisinitiatedAsc = '_BASEISINITIATED_ASC_',
  /** sort on BaseisInitiated descending */
  BaseisinitiatedDesc = '_BASEISINITIATED_DESC_',
  /** Sort on PlusGiroRefAccountNo ascending */
  PlusgirorefaccountnoAsc = '_PLUSGIROREFACCOUNTNO_ASC_',
  /** sort on PlusGiroRefAccountNo descending */
  PlusgirorefaccountnoDesc = '_PLUSGIROREFACCOUNTNO_DESC_',
  /** Sort on CompanyImageEtag ascending */
  CompanyimageetagAsc = '_COMPANYIMAGEETAG_ASC_',
  /** sort on CompanyImageEtag descending */
  CompanyimageetagDesc = '_COMPANYIMAGEETAG_DESC_',
  /** Sort on CompanyImageDocGlobalId ascending */
  CompanyimagedocglobalidAsc = '_COMPANYIMAGEDOCGLOBALID_ASC_',
  /** sort on CompanyImageDocGlobalId descending */
  CompanyimagedocglobalidDesc = '_COMPANYIMAGEDOCGLOBALID_DESC_',
  /** Sort on CompanyImageURL ascending */
  CompanyimageurlAsc = '_COMPANYIMAGEURL_ASC_',
  /** sort on CompanyImageURL descending */
  CompanyimageurlDesc = '_COMPANYIMAGEURL_DESC_',
  /** Sort on Address_Visit ascending */
  AddressVisitAsc = '_ADDRESS_VISIT_ASC_',
  /** sort on Address_Visit descending */
  AddressVisitDesc = '_ADDRESS_VISIT_DESC_',
  /** Sort on Address2_Visit ascending */
  Address2VisitAsc = '_ADDRESS2_VISIT_ASC_',
  /** sort on Address2_Visit descending */
  Address2VisitDesc = '_ADDRESS2_VISIT_DESC_',
  /** Sort on City_Visit ascending */
  CityVisitAsc = '_CITY_VISIT_ASC_',
  /** sort on City_Visit descending */
  CityVisitDesc = '_CITY_VISIT_DESC_',
  /** Sort on PostCode_Visit ascending */
  PostcodeVisitAsc = '_POSTCODE_VISIT_ASC_',
  /** sort on PostCode_Visit descending */
  PostcodeVisitDesc = '_POSTCODE_VISIT_DESC_',
  /** Sort on EmailDomain ascending */
  EmaildomainAsc = '_EMAILDOMAIN_ASC_',
  /** sort on EmailDomain descending */
  EmaildomainDesc = '_EMAILDOMAIN_DESC_',
  /** Sort on ScriveField ascending */
  ScrivefieldAsc = '_SCRIVEFIELD_ASC_',
  /** sort on ScriveField descending */
  ScrivefieldDesc = '_SCRIVEFIELD_DESC_',
  /** Sort on InvoiceEmail ascending */
  InvoiceemailAsc = '_INVOICEEMAIL_ASC_',
  /** sort on InvoiceEmail descending */
  InvoiceemailDesc = '_INVOICEEMAIL_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on ActiveInEdge ascending */
  ActiveinedgeAsc = '_ACTIVEINEDGE_ASC_',
  /** sort on ActiveInEdge descending */
  ActiveinedgeDesc = '_ACTIVEINEDGE_DESC_',
  /** Sort on IsProspect ascending */
  IsprospectAsc = '_ISPROSPECT_ASC_',
  /** sort on IsProspect descending */
  IsprospectDesc = '_ISPROSPECT_DESC_',
  /** Sort on Analytics ascending */
  AnalyticsAsc = '_ANALYTICS_ASC_',
  /** sort on Analytics descending */
  AnalyticsDesc = '_ANALYTICS_DESC_',
  /** Sort on MagentoGroupId ascending */
  MagentogroupidAsc = '_MAGENTOGROUPID_ASC_',
  /** sort on MagentoGroupId descending */
  MagentogroupidDesc = '_MAGENTOGROUPID_DESC_',
  /** Sort on StoreId ascending */
  StoreidAsc = '_STOREID_ASC_',
  /** sort on StoreId descending */
  StoreidDesc = '_STOREID_DESC_',
  /** Sort on WebsiteId ascending */
  WebsiteidAsc = '_WEBSITEID_ASC_',
  /** sort on WebsiteId descending */
  WebsiteidDesc = '_WEBSITEID_DESC_',
  /** Sort on MagentoAddressId ascending */
  MagentoaddressidAsc = '_MAGENTOADDRESSID_ASC_',
  /** sort on MagentoAddressId descending */
  MagentoaddressidDesc = '_MAGENTOADDRESSID_DESC_',
  /** Sort on Fax ascending */
  FaxAsc = '_FAX_ASC_',
  /** sort on Fax descending */
  FaxDesc = '_FAX_DESC_',
  /** Sort on IsWebCRM ascending */
  IswebcrmAsc = '_ISWEBCRM_ASC_',
  /** sort on IsWebCRM descending */
  IswebcrmDesc = '_ISWEBCRM_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on LatestInvoiceDate ascending */
  LatestinvoicedateAsc = '_LATESTINVOICEDATE_ASC_',
  /** sort on LatestInvoiceDate descending */
  LatestinvoicedateDesc = '_LATESTINVOICEDATE_DESC_',
  /** Sort on RevenueThisYear ascending */
  RevenuethisyearAsc = '_REVENUETHISYEAR_ASC_',
  /** sort on RevenueThisYear descending */
  RevenuethisyearDesc = '_REVENUETHISYEAR_DESC_',
  /** Sort on RevenueLastYear ascending */
  RevenuelastyearAsc = '_REVENUELASTYEAR_ASC_',
  /** sort on RevenueLastYear descending */
  RevenuelastyearDesc = '_REVENUELASTYEAR_DESC_',
  /** Sort on RevenueYearBefLastYear ascending */
  RevenueyearbeflastyearAsc = '_REVENUEYEARBEFLASTYEAR_ASC_',
  /** sort on RevenueYearBefLastYear descending */
  RevenueyearbeflastyearDesc = '_REVENUEYEARBEFLASTYEAR_DESC_',
  /** Sort on IsPublicRealbridge ascending */
  IspublicrealbridgeAsc = '_ISPUBLICREALBRIDGE_ASC_',
  /** sort on IsPublicRealbridge descending */
  IspublicrealbridgeDesc = '_ISPUBLICREALBRIDGE_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on StatisticsGroup ascending */
  StatisticsgroupAsc = '_STATISTICSGROUP_ASC_',
  /** sort on StatisticsGroup descending */
  StatisticsgroupDesc = '_STATISTICSGROUP_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on FinChargeTermsCode ascending */
  FinchargetermscodeAsc = '_FINCHARGETERMSCODE_ASC_',
  /** sort on FinChargeTermsCode descending */
  FinchargetermscodeDesc = '_FINCHARGETERMSCODE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PlaceofExport ascending */
  PlaceofexportAsc = '_PLACEOFEXPORT_ASC_',
  /** sort on PlaceofExport descending */
  PlaceofexportDesc = '_PLACEOFEXPORT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on CollectionMethod ascending */
  CollectionmethodAsc = '_COLLECTIONMETHOD_ASC_',
  /** sort on CollectionMethod descending */
  CollectionmethodDesc = '_COLLECTIONMETHOD_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on InvoiceCopies ascending */
  InvoicecopiesAsc = '_INVOICECOPIES_ASC_',
  /** sort on InvoiceCopies descending */
  InvoicecopiesDesc = '_INVOICECOPIES_DESC_',
  /** Sort on LastStatementNo ascending */
  LaststatementnoAsc = '_LASTSTATEMENTNO_ASC_',
  /** sort on LastStatementNo descending */
  LaststatementnoDesc = '_LASTSTATEMENTNO_DESC_',
  /** Sort on PrintStatements ascending */
  PrintstatementsAsc = '_PRINTSTATEMENTS_ASC_',
  /** sort on PrintStatements descending */
  PrintstatementsDesc = '_PRINTSTATEMENTS_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on Priority ascending */
  PriorityAsc = '_PRIORITY_ASC_',
  /** sort on Priority descending */
  PriorityDesc = '_PRIORITY_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on Balance ascending */
  BalanceAsc = '_BALANCE_ASC_',
  /** sort on Balance descending */
  BalanceDesc = '_BALANCE_DESC_',
  /** Sort on BalanceLCY ascending */
  BalancelcyAsc = '_BALANCELCY_ASC_',
  /** sort on BalanceLCY descending */
  BalancelcyDesc = '_BALANCELCY_DESC_',
  /** Sort on NetChange ascending */
  NetchangeAsc = '_NETCHANGE_ASC_',
  /** sort on NetChange descending */
  NetchangeDesc = '_NETCHANGE_DESC_',
  /** Sort on NetChangeLCY ascending */
  NetchangelcyAsc = '_NETCHANGELCY_ASC_',
  /** sort on NetChangeLCY descending */
  NetchangelcyDesc = '_NETCHANGELCY_DESC_',
  /** Sort on SalesLCY ascending */
  SaleslcyAsc = '_SALESLCY_ASC_',
  /** sort on SalesLCY descending */
  SaleslcyDesc = '_SALESLCY_DESC_',
  /** Sort on ProfitLCY ascending */
  ProfitlcyAsc = '_PROFITLCY_ASC_',
  /** sort on ProfitLCY descending */
  ProfitlcyDesc = '_PROFITLCY_DESC_',
  /** Sort on InvDiscountsLCY ascending */
  InvdiscountslcyAsc = '_INVDISCOUNTSLCY_ASC_',
  /** sort on InvDiscountsLCY descending */
  InvdiscountslcyDesc = '_INVDISCOUNTSLCY_DESC_',
  /** Sort on PmtDiscountsLCY ascending */
  PmtdiscountslcyAsc = '_PMTDISCOUNTSLCY_ASC_',
  /** sort on PmtDiscountsLCY descending */
  PmtdiscountslcyDesc = '_PMTDISCOUNTSLCY_DESC_',
  /** Sort on BalanceDue ascending */
  BalancedueAsc = '_BALANCEDUE_ASC_',
  /** sort on BalanceDue descending */
  BalancedueDesc = '_BALANCEDUE_DESC_',
  /** Sort on BalanceDueLCY ascending */
  BalanceduelcyAsc = '_BALANCEDUELCY_ASC_',
  /** sort on BalanceDueLCY descending */
  BalanceduelcyDesc = '_BALANCEDUELCY_DESC_',
  /** Sort on Payments ascending */
  PaymentsAsc = '_PAYMENTS_ASC_',
  /** sort on Payments descending */
  PaymentsDesc = '_PAYMENTS_DESC_',
  /** Sort on InvoiceAmounts ascending */
  InvoiceamountsAsc = '_INVOICEAMOUNTS_ASC_',
  /** sort on InvoiceAmounts descending */
  InvoiceamountsDesc = '_INVOICEAMOUNTS_DESC_',
  /** Sort on CrMemoAmounts ascending */
  CrmemoamountsAsc = '_CRMEMOAMOUNTS_ASC_',
  /** sort on CrMemoAmounts descending */
  CrmemoamountsDesc = '_CRMEMOAMOUNTS_DESC_',
  /** Sort on FinanceChargeMemoAmounts ascending */
  FinancechargememoamountsAsc = '_FINANCECHARGEMEMOAMOUNTS_ASC_',
  /** sort on FinanceChargeMemoAmounts descending */
  FinancechargememoamountsDesc = '_FINANCECHARGEMEMOAMOUNTS_DESC_',
  /** Sort on PaymentsLCY ascending */
  PaymentslcyAsc = '_PAYMENTSLCY_ASC_',
  /** sort on PaymentsLCY descending */
  PaymentslcyDesc = '_PAYMENTSLCY_DESC_',
  /** Sort on InvAmountsLCY ascending */
  InvamountslcyAsc = '_INVAMOUNTSLCY_ASC_',
  /** sort on InvAmountsLCY descending */
  InvamountslcyDesc = '_INVAMOUNTSLCY_DESC_',
  /** Sort on CrMemoAmountsLCY ascending */
  CrmemoamountslcyAsc = '_CRMEMOAMOUNTSLCY_ASC_',
  /** sort on CrMemoAmountsLCY descending */
  CrmemoamountslcyDesc = '_CRMEMOAMOUNTSLCY_DESC_',
  /** Sort on FinChargeMemoAmountsLCY ascending */
  FinchargememoamountslcyAsc = '_FINCHARGEMEMOAMOUNTSLCY_ASC_',
  /** sort on FinChargeMemoAmountsLCY descending */
  FinchargememoamountslcyDesc = '_FINCHARGEMEMOAMOUNTSLCY_DESC_',
  /** Sort on OutstandingOrders ascending */
  OutstandingordersAsc = '_OUTSTANDINGORDERS_ASC_',
  /** sort on OutstandingOrders descending */
  OutstandingordersDesc = '_OUTSTANDINGORDERS_DESC_',
  /** Sort on ShippedNotInvoiced ascending */
  ShippednotinvoicedAsc = '_SHIPPEDNOTINVOICED_ASC_',
  /** sort on ShippedNotInvoiced descending */
  ShippednotinvoicedDesc = '_SHIPPEDNOTINVOICED_DESC_',
  /** Sort on ApplicationMethod ascending */
  ApplicationmethodAsc = '_APPLICATIONMETHOD_ASC_',
  /** sort on ApplicationMethod descending */
  ApplicationmethodDesc = '_APPLICATIONMETHOD_DESC_',
  /** Sort on ApplicationMethodOPTION ascending */
  ApplicationmethodoptionAsc = '_APPLICATIONMETHODOPTION_ASC_',
  /** sort on ApplicationMethodOPTION descending */
  ApplicationmethodoptionDesc = '_APPLICATIONMETHODOPTION_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on DontDebitPlasticTax ascending */
  DontdebitplastictaxAsc = '_DONTDEBITPLASTICTAX_ASC_',
  /** sort on DontDebitPlasticTax descending */
  DontdebitplastictaxDesc = '_DONTDEBITPLASTICTAX_DESC_',
  /** Sort on DontDebitEnvironmentFees ascending */
  DontdebitenvironmentfeesAsc = '_DONTDEBITENVIRONMENTFEES_ASC_',
  /** sort on DontDebitEnvironmentFees descending */
  DontdebitenvironmentfeesDesc = '_DONTDEBITENVIRONMENTFEES_DESC_',
  /** Sort on ShipmentDays ascending */
  ShipmentdaysAsc = '_SHIPMENTDAYS_ASC_',
  /** sort on ShipmentDays descending */
  ShipmentdaysDesc = '_SHIPMENTDAYS_DESC_',
  /** Sort on EnvironmentShipmentDays ascending */
  EnvironmentshipmentdaysAsc = '_ENVIRONMENTSHIPMENTDAYS_ASC_',
  /** sort on EnvironmentShipmentDays descending */
  EnvironmentshipmentdaysDesc = '_ENVIRONMENTSHIPMENTDAYS_DESC_',
  /** Sort on CustomerCategory ascending */
  CustomercategoryAsc = '_CUSTOMERCATEGORY_ASC_',
  /** sort on CustomerCategory descending */
  CustomercategoryDesc = '_CUSTOMERCATEGORY_DESC_',
  /** Sort on CustomerClassification ascending */
  CustomerclassificationAsc = '_CUSTOMERCLASSIFICATION_ASC_',
  /** sort on CustomerClassification descending */
  CustomerclassificationDesc = '_CUSTOMERCLASSIFICATION_DESC_',
  /** Sort on MagentoCustomer ascending */
  MagentocustomerAsc = '_MAGENTOCUSTOMER_ASC_',
  /** sort on MagentoCustomer descending */
  MagentocustomerDesc = '_MAGENTOCUSTOMER_DESC_',
  /** Sort on Allwebitemsisvisible ascending */
  AllwebitemsisvisibleAsc = '_ALLWEBITEMSISVISIBLE_ASC_',
  /** sort on Allwebitemsisvisible descending */
  AllwebitemsisvisibleDesc = '_ALLWEBITEMSISVISIBLE_DESC_',
  /** Sort on SharkFreightInstrToWsh ascending */
  SharkfreightinstrtowshAsc = '_SHARKFREIGHTINSTRTOWSH_ASC_',
  /** sort on SharkFreightInstrToWsh descending */
  SharkfreightinstrtowshDesc = '_SHARKFREIGHTINSTRTOWSH_DESC_',
  /** Sort on SharkPrioritizeCode ascending */
  SharkprioritizecodeAsc = '_SHARKPRIORITIZECODE_ASC_',
  /** sort on SharkPrioritizeCode descending */
  SharkprioritizecodeDesc = '_SHARKPRIORITIZECODE_DESC_',
  /** Sort on SharkPrioritizeCodeOPTION ascending */
  SharkprioritizecodeoptionAsc = '_SHARKPRIORITIZECODEOPTION_ASC_',
  /** sort on SharkPrioritizeCodeOPTION descending */
  SharkprioritizecodeoptionDesc = '_SHARKPRIORITIZECODEOPTION_DESC_',
  /** Sort on SharkInvoiceValue ascending */
  SharkinvoicevalueAsc = '_SHARKINVOICEVALUE_ASC_',
  /** sort on SharkInvoiceValue descending */
  SharkinvoicevalueDesc = '_SHARKINVOICEVALUE_DESC_',
  /** Sort on SharkFreightPrice ascending */
  SharkfreightpriceAsc = '_SHARKFREIGHTPRICE_ASC_',
  /** sort on SharkFreightPrice descending */
  SharkfreightpriceDesc = '_SHARKFREIGHTPRICE_DESC_',
  /** Sort on SharkTelephoneInstruction ascending */
  SharktelephoneinstructionAsc = '_SHARKTELEPHONEINSTRUCTION_ASC_',
  /** sort on SharkTelephoneInstruction descending */
  SharktelephoneinstructionDesc = '_SHARKTELEPHONEINSTRUCTION_DESC_',
  /** Sort on NoBackOrder ascending */
  NobackorderAsc = '_NOBACKORDER_ASC_',
  /** sort on NoBackOrder descending */
  NobackorderDesc = '_NOBACKORDER_DESC_',
  /** Sort on Tour ascending */
  TourAsc = '_TOUR_ASC_',
  /** sort on Tour descending */
  TourDesc = '_TOUR_DESC_',
  /** Sort on TourNo ascending */
  TournoAsc = '_TOURNO_ASC_',
  /** sort on TourNo descending */
  TournoDesc = '_TOURNO_DESC_',
  /** Sort on OnlyActualFreightPrice ascending */
  OnlyactualfreightpriceAsc = '_ONLYACTUALFREIGHTPRICE_ASC_',
  /** sort on OnlyActualFreightPrice descending */
  OnlyactualfreightpriceDesc = '_ONLYACTUALFREIGHTPRICE_DESC_',
  /** Sort on InvoiceDocumentType ascending */
  InvoicedocumenttypeAsc = '_INVOICEDOCUMENTTYPE_ASC_',
  /** sort on InvoiceDocumentType descending */
  InvoicedocumenttypeDesc = '_INVOICEDOCUMENTTYPE_DESC_',
  /** Sort on InvoiceDocumentTypeOPTION ascending */
  InvoicedocumenttypeoptionAsc = '_INVOICEDOCUMENTTYPEOPTION_ASC_',
  /** sort on InvoiceDocumentTypeOPTION descending */
  InvoicedocumenttypeoptionDesc = '_INVOICEDOCUMENTTYPEOPTION_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on DocumentCode ascending */
  DocumentcodeAsc = '_DOCUMENTCODE_ASC_',
  /** sort on DocumentCode descending */
  DocumentcodeDesc = '_DOCUMENTCODE_DESC_',
  /** Sort on PaymentMethodWeb ascending */
  PaymentmethodwebAsc = '_PAYMENTMETHODWEB_ASC_',
  /** sort on PaymentMethodWeb descending */
  PaymentmethodwebDesc = '_PAYMENTMETHODWEB_DESC_',
  /** Sort on QBSId ascending */
  QbsidAsc = '_QBSID_ASC_',
  /** sort on QBSId descending */
  QbsidDesc = '_QBSID_DESC_',
  /** Sort on CRMClassification ascending */
  CrmclassificationAsc = '_CRMCLASSIFICATION_ASC_',
  /** sort on CRMClassification descending */
  CrmclassificationDesc = '_CRMCLASSIFICATION_DESC_',
  /** Sort on PEBShiptoCode ascending */
  PebshiptocodeAsc = '_PEBSHIPTOCODE_ASC_',
  /** sort on PEBShiptoCode descending */
  PebshiptocodeDesc = '_PEBSHIPTOCODE_DESC_',
  /** Sort on PEBDirectDebitPackType ascending */
  PebdirectdebitpacktypeAsc = '_PEBDIRECTDEBITPACKTYPE_ASC_',
  /** sort on PEBDirectDebitPackType descending */
  PebdirectdebitpacktypeDesc = '_PEBDIRECTDEBITPACKTYPE_DESC_',
  /** Sort on PEBDirectDebitPackTypeOPTION ascending */
  PebdirectdebitpacktypeoptionAsc = '_PEBDIRECTDEBITPACKTYPEOPTION_ASC_',
  /** sort on PEBDirectDebitPackTypeOPTION descending */
  PebdirectdebitpacktypeoptionDesc = '_PEBDIRECTDEBITPACKTYPEOPTION_DESC_',
  /** Sort on PEBDirectDebitPaymentNo ascending */
  PebdirectdebitpaymentnoAsc = '_PEBDIRECTDEBITPAYMENTNO_ASC_',
  /** sort on PEBDirectDebitPaymentNo descending */
  PebdirectdebitpaymentnoDesc = '_PEBDIRECTDEBITPAYMENTNO_DESC_',
  /** Sort on PEBDirectDebitApprovalStat ascending */
  PebdirectdebitapprovalstatAsc = '_PEBDIRECTDEBITAPPROVALSTAT_ASC_',
  /** sort on PEBDirectDebitApprovalStat descending */
  PebdirectdebitapprovalstatDesc = '_PEBDIRECTDEBITAPPROVALSTAT_DESC_',
  /** Sort on PEBDirectDebitApprovalStatOPTION ascending */
  PebdirectdebitapprovalstatoptionAsc = '_PEBDIRECTDEBITAPPROVALSTATOPTION_ASC_',
  /** sort on PEBDirectDebitApprovalStatOPTION descending */
  PebdirectdebitapprovalstatoptionDesc = '_PEBDIRECTDEBITAPPROVALSTATOPTION_DESC_',
  /** Sort on PEBDDApprovalStatus ascending */
  PebddapprovalstatusAsc = '_PEBDDAPPROVALSTATUS_ASC_',
  /** sort on PEBDDApprovalStatus descending */
  PebddapprovalstatusDesc = '_PEBDDAPPROVALSTATUS_DESC_',
  /** Sort on PEBDDApprovalStatusOPTION ascending */
  PebddapprovalstatusoptionAsc = '_PEBDDAPPROVALSTATUSOPTION_ASC_',
  /** sort on PEBDDApprovalStatusOPTION descending */
  PebddapprovalstatusoptionDesc = '_PEBDDAPPROVALSTATUSOPTION_DESC_',
  /** Sort on PEBRegistrationNo ascending */
  PebregistrationnoAsc = '_PEBREGISTRATIONNO_ASC_',
  /** sort on PEBRegistrationNo descending */
  PebregistrationnoDesc = '_PEBREGISTRATIONNO_DESC_',
  /** Sort on PEBDocumentCode ascending */
  PebdocumentcodeAsc = '_PEBDOCUMENTCODE_ASC_',
  /** sort on PEBDocumentCode descending */
  PebdocumentcodeDesc = '_PEBDOCUMENTCODE_DESC_',
  /** Sort on EQMInvoiceDocType ascending */
  EqminvoicedoctypeAsc = '_EQMINVOICEDOCTYPE_ASC_',
  /** sort on EQMInvoiceDocType descending */
  EqminvoicedoctypeDesc = '_EQMINVOICEDOCTYPE_DESC_',
  /** Sort on EQMCustomerTemplate ascending */
  EqmcustomertemplateAsc = '_EQMCUSTOMERTEMPLATE_ASC_',
  /** sort on EQMCustomerTemplate descending */
  EqmcustomertemplateDesc = '_EQMCUSTOMERTEMPLATE_DESC_',
  /** Sort on EQMCustRentalDiscGroup ascending */
  EqmcustrentaldiscgroupAsc = '_EQMCUSTRENTALDISCGROUP_ASC_',
  /** sort on EQMCustRentalDiscGroup descending */
  EqmcustrentaldiscgroupDesc = '_EQMCUSTRENTALDISCGROUP_DESC_',
  /** Sort on EQMCustomerType ascending */
  EqmcustomertypeAsc = '_EQMCUSTOMERTYPE_ASC_',
  /** sort on EQMCustomerType descending */
  EqmcustomertypeDesc = '_EQMCUSTOMERTYPE_DESC_',
  /** Sort on EQMCustomerTypeOPTION ascending */
  EqmcustomertypeoptionAsc = '_EQMCUSTOMERTYPEOPTION_ASC_',
  /** sort on EQMCustomerTypeOPTION descending */
  EqmcustomertypeoptionDesc = '_EQMCUSTOMERTYPEOPTION_DESC_',
  /** Sort on EQMEditableRentalInsurance ascending */
  EqmeditablerentalinsuranceAsc = '_EQMEDITABLERENTALINSURANCE_ASC_',
  /** sort on EQMEditableRentalInsurance descending */
  EqmeditablerentalinsuranceDesc = '_EQMEDITABLERENTALINSURANCE_DESC_',
  /** Sort on EQMRentalInsurance ascending */
  EqmrentalinsuranceAsc = '_EQMRENTALINSURANCE_ASC_',
  /** sort on EQMRentalInsurance descending */
  EqmrentalinsuranceDesc = '_EQMRENTALINSURANCE_DESC_',
  /** Sort on EQMCustRentalPriceGroup ascending */
  EqmcustrentalpricegroupAsc = '_EQMCUSTRENTALPRICEGROUP_ASC_',
  /** sort on EQMCustRentalPriceGroup descending */
  EqmcustrentalpricegroupDesc = '_EQMCUSTRENTALPRICEGROUP_DESC_',
  /** Sort on EQMHowInvoiceRentalContr ascending */
  EqmhowinvoicerentalcontrAsc = '_EQMHOWINVOICERENTALCONTR_ASC_',
  /** sort on EQMHowInvoiceRentalContr descending */
  EqmhowinvoicerentalcontrDesc = '_EQMHOWINVOICERENTALCONTR_DESC_',
  /** Sort on EQMHowInvoiceRentalContrOPTION ascending */
  EqmhowinvoicerentalcontroptionAsc = '_EQMHOWINVOICERENTALCONTROPTION_ASC_',
  /** sort on EQMHowInvoiceRentalContrOPTION descending */
  EqmhowinvoicerentalcontroptionDesc = '_EQMHOWINVOICERENTALCONTROPTION_DESC_',
  /** Sort on EQMCustomerDeposit ascending */
  EqmcustomerdepositAsc = '_EQMCUSTOMERDEPOSIT_ASC_',
  /** sort on EQMCustomerDeposit descending */
  EqmcustomerdepositDesc = '_EQMCUSTOMERDEPOSIT_DESC_',
  /** Sort on EQMCheckListCode ascending */
  EqmchecklistcodeAsc = '_EQMCHECKLISTCODE_ASC_',
  /** sort on EQMCheckListCode descending */
  EqmchecklistcodeDesc = '_EQMCHECKLISTCODE_DESC_',
  /** Sort on EQMCustomerCategory ascending */
  EqmcustomercategoryAsc = '_EQMCUSTOMERCATEGORY_ASC_',
  /** sort on EQMCustomerCategory descending */
  EqmcustomercategoryDesc = '_EQMCUSTOMERCATEGORY_DESC_',
  /** Sort on EQMCustomerCategoryOPTION ascending */
  EqmcustomercategoryoptionAsc = '_EQMCUSTOMERCATEGORYOPTION_ASC_',
  /** sort on EQMCustomerCategoryOPTION descending */
  EqmcustomercategoryoptionDesc = '_EQMCUSTOMERCATEGORYOPTION_DESC_',
  /** Sort on EQMPopupNote ascending */
  EqmpopupnoteAsc = '_EQMPOPUPNOTE_ASC_',
  /** sort on EQMPopupNote descending */
  EqmpopupnoteDesc = '_EQMPOPUPNOTE_DESC_',
  /** Sort on EQMCombineRentalInvoice ascending */
  EqmcombinerentalinvoiceAsc = '_EQMCOMBINERENTALINVOICE_ASC_',
  /** sort on EQMCombineRentalInvoice descending */
  EqmcombinerentalinvoiceDesc = '_EQMCOMBINERENTALINVOICE_DESC_',
  /** Sort on EQMCombineMethod ascending */
  EqmcombinemethodAsc = '_EQMCOMBINEMETHOD_ASC_',
  /** sort on EQMCombineMethod descending */
  EqmcombinemethodDesc = '_EQMCOMBINEMETHOD_DESC_',
  /** Sort on EQMCombineMethodOPTION ascending */
  EqmcombinemethodoptionAsc = '_EQMCOMBINEMETHODOPTION_ASC_',
  /** sort on EQMCombineMethodOPTION descending */
  EqmcombinemethodoptionDesc = '_EQMCOMBINEMETHODOPTION_DESC_',
  /** Sort on EQMRentalInvDayofMonth ascending */
  EqmrentalinvdayofmonthAsc = '_EQMRENTALINVDAYOFMONTH_ASC_',
  /** sort on EQMRentalInvDayofMonth descending */
  EqmrentalinvdayofmonthDesc = '_EQMRENTALINVDAYOFMONTH_DESC_',
  /** Sort on EQMCustomerClassification ascending */
  EqmcustomerclassificationAsc = '_EQMCUSTOMERCLASSIFICATION_ASC_',
  /** sort on EQMCustomerClassification descending */
  EqmcustomerclassificationDesc = '_EQMCUSTOMERCLASSIFICATION_DESC_',
  /** Sort on EQMManuallyBlocked ascending */
  EqmmanuallyblockedAsc = '_EQMMANUALLYBLOCKED_ASC_',
  /** sort on EQMManuallyBlocked descending */
  EqmmanuallyblockedDesc = '_EQMMANUALLYBLOCKED_DESC_',
  /** Sort on EQMOrganizationNo ascending */
  EqmorganizationnoAsc = '_EQMORGANIZATIONNO_ASC_',
  /** sort on EQMOrganizationNo descending */
  EqmorganizationnoDesc = '_EQMORGANIZATIONNO_DESC_',
  /** Sort on EQMRentalInvoiceSequence ascending */
  EqmrentalinvoicesequenceAsc = '_EQMRENTALINVOICESEQUENCE_ASC_',
  /** sort on EQMRentalInvoiceSequence descending */
  EqmrentalinvoicesequenceDesc = '_EQMRENTALINVOICESEQUENCE_DESC_',
  /** Sort on EQMEnvironmentalFeeCode ascending */
  EqmenvironmentalfeecodeAsc = '_EQMENVIRONMENTALFEECODE_ASC_',
  /** sort on EQMEnvironmentalFeeCode descending */
  EqmenvironmentalfeecodeDesc = '_EQMENVIRONMENTALFEECODE_DESC_',
  /** Sort on EQMNotEditable ascending */
  EqmnoteditableAsc = '_EQMNOTEDITABLE_ASC_',
  /** sort on EQMNotEditable descending */
  EqmnoteditableDesc = '_EQMNOTEDITABLE_DESC_',
  /** Sort on EQMPaidDeposits ascending */
  EqmpaiddepositsAsc = '_EQMPAIDDEPOSITS_ASC_',
  /** sort on EQMPaidDeposits descending */
  EqmpaiddepositsDesc = '_EQMPAIDDEPOSITS_DESC_',
  /** Sort on EQMPaidDepositsLCY ascending */
  EqmpaiddepositslcyAsc = '_EQMPAIDDEPOSITSLCY_ASC_',
  /** sort on EQMPaidDepositsLCY descending */
  EqmpaiddepositslcyDesc = '_EQMPAIDDEPOSITSLCY_DESC_',
  /** Sort on EQMJobNo ascending */
  EqmjobnoAsc = '_EQMJOBNO_ASC_',
  /** sort on EQMJobNo descending */
  EqmjobnoDesc = '_EQMJOBNO_DESC_',
  /** Sort on EQMJobTaskNo ascending */
  EqmjobtasknoAsc = '_EQMJOBTASKNO_ASC_',
  /** sort on EQMJobTaskNo descending */
  EqmjobtasknoDesc = '_EQMJOBTASKNO_DESC_',
  /** Sort on EQMInvoiceFee ascending */
  EqminvoicefeeAsc = '_EQMINVOICEFEE_ASC_',
  /** sort on EQMInvoiceFee descending */
  EqminvoicefeeDesc = '_EQMINVOICEFEE_DESC_',
  /** Sort on EQMInvoiceFeeOPTION ascending */
  EqminvoicefeeoptionAsc = '_EQMINVOICEFEEOPTION_ASC_',
  /** sort on EQMInvoiceFeeOPTION descending */
  EqminvoicefeeoptionDesc = '_EQMINVOICEFEEOPTION_DESC_',
  /** Sort on EQMPrepaymentLCY ascending */
  EqmprepaymentlcyAsc = '_EQMPREPAYMENTLCY_ASC_',
  /** sort on EQMPrepaymentLCY descending */
  EqmprepaymentlcyDesc = '_EQMPREPAYMENTLCY_DESC_',
  /** Sort on EQMPrepaymentPeriod ascending */
  EqmprepaymentperiodAsc = '_EQMPREPAYMENTPERIOD_ASC_',
  /** sort on EQMPrepaymentPeriod descending */
  EqmprepaymentperiodDesc = '_EQMPREPAYMENTPERIOD_DESC_',
  /** Sort on EQMMaxRentalValue ascending */
  EqmmaxrentalvalueAsc = '_EQMMAXRENTALVALUE_ASC_',
  /** sort on EQMMaxRentalValue descending */
  EqmmaxrentalvalueDesc = '_EQMMAXRENTALVALUE_DESC_',
  /** Sort on EQMMaxGrantedDeposit ascending */
  EqmmaxgranteddepositAsc = '_EQMMAXGRANTEDDEPOSIT_ASC_',
  /** sort on EQMMaxGrantedDeposit descending */
  EqmmaxgranteddepositDesc = '_EQMMAXGRANTEDDEPOSIT_DESC_',
  /** Sort on EQMCatValidtoDate ascending */
  EqmcatvalidtodateAsc = '_EQMCATVALIDTODATE_ASC_',
  /** sort on EQMCatValidtoDate descending */
  EqmcatvalidtodateDesc = '_EQMCATVALIDTODATE_DESC_',
  /** Sort on EQMNoofRentalQuotes ascending */
  EqmnoofrentalquotesAsc = '_EQMNOOFRENTALQUOTES_ASC_',
  /** sort on EQMNoofRentalQuotes descending */
  EqmnoofrentalquotesDesc = '_EQMNOOFRENTALQUOTES_DESC_',
  /** Sort on EQMNoofOpenRntlContr ascending */
  EqmnoofopenrntlcontrAsc = '_EQMNOOFOPENRNTLCONTR_ASC_',
  /** sort on EQMNoofOpenRntlContr descending */
  EqmnoofopenrntlcontrDesc = '_EQMNOOFOPENRNTLCONTR_DESC_',
  /** Sort on EQMNoofOpenRentalLines ascending */
  EqmnoofopenrentallinesAsc = '_EQMNOOFOPENRENTALLINES_ASC_',
  /** sort on EQMNoofOpenRentalLines descending */
  EqmnoofopenrentallinesDesc = '_EQMNOOFOPENRENTALLINES_DESC_',
  /** Sort on EQMNoofRentalOrders ascending */
  EqmnoofrentalordersAsc = '_EQMNOOFRENTALORDERS_ASC_',
  /** sort on EQMNoofRentalOrders descending */
  EqmnoofrentalordersDesc = '_EQMNOOFRENTALORDERS_DESC_',
  /** Sort on EQMNoofRentalCreditMemos ascending */
  EqmnoofrentalcreditmemosAsc = '_EQMNOOFRENTALCREDITMEMOS_ASC_',
  /** sort on EQMNoofRentalCreditMemos descending */
  EqmnoofrentalcreditmemosDesc = '_EQMNOOFRENTALCREDITMEMOS_DESC_',
  /** Sort on EQMNoofPstdRntlShpt ascending */
  EqmnoofpstdrntlshptAsc = '_EQMNOOFPSTDRNTLSHPT_ASC_',
  /** sort on EQMNoofPstdRntlShpt descending */
  EqmnoofpstdrntlshptDesc = '_EQMNOOFPSTDRNTLSHPT_DESC_',
  /** Sort on EQMNoofPstdRntlInv ascending */
  EqmnoofpstdrntlinvAsc = '_EQMNOOFPSTDRNTLINV_ASC_',
  /** sort on EQMNoofPstdRntlInv descending */
  EqmnoofpstdrntlinvDesc = '_EQMNOOFPSTDRNTLINV_DESC_',
  /** Sort on EQMNoofPstdRntlCrM ascending */
  EqmnoofpstdrntlcrmAsc = '_EQMNOOFPSTDRNTLCRM_ASC_',
  /** sort on EQMNoofPstdRntlCrM descending */
  EqmnoofpstdrntlcrmDesc = '_EQMNOOFPSTDRNTLCRM_DESC_',
  /** Sort on EQMNoofWorkshopQuotes ascending */
  EqmnoofworkshopquotesAsc = '_EQMNOOFWORKSHOPQUOTES_ASC_',
  /** sort on EQMNoofWorkshopQuotes descending */
  EqmnoofworkshopquotesDesc = '_EQMNOOFWORKSHOPQUOTES_DESC_',
  /** Sort on EQMNoofWorkshopOrders ascending */
  EqmnoofworkshopordersAsc = '_EQMNOOFWORKSHOPORDERS_ASC_',
  /** sort on EQMNoofWorkshopOrders descending */
  EqmnoofworkshopordersDesc = '_EQMNOOFWORKSHOPORDERS_DESC_',
  /** Sort on EQMNoofWrkshpCrMemos ascending */
  EqmnoofwrkshpcrmemosAsc = '_EQMNOOFWRKSHPCRMEMOS_ASC_',
  /** sort on EQMNoofWrkshpCrMemos descending */
  EqmnoofwrkshpcrmemosDesc = '_EQMNOOFWRKSHPCRMEMOS_DESC_',
  /** Sort on EQMNoofPstdWrkshpShpt ascending */
  EqmnoofpstdwrkshpshptAsc = '_EQMNOOFPSTDWRKSHPSHPT_ASC_',
  /** sort on EQMNoofPstdWrkshpShpt descending */
  EqmnoofpstdwrkshpshptDesc = '_EQMNOOFPSTDWRKSHPSHPT_DESC_',
  /** Sort on EQMNoofPstdWrkshpInv ascending */
  EqmnoofpstdwrkshpinvAsc = '_EQMNOOFPSTDWRKSHPINV_ASC_',
  /** sort on EQMNoofPstdWrkshpInv descending */
  EqmnoofpstdwrkshpinvDesc = '_EQMNOOFPSTDWRKSHPINV_DESC_',
  /** Sort on EQMNoofPstdWrkshpCrM ascending */
  EqmnoofpstdwrkshpcrmAsc = '_EQMNOOFPSTDWRKSHPCRM_ASC_',
  /** sort on EQMNoofPstdWrkshpCrM descending */
  EqmnoofpstdwrkshpcrmDesc = '_EQMNOOFPSTDWRKSHPCRM_DESC_',
  /** Sort on EQMBillToNoofRntlQte ascending */
  EqmbilltonoofrntlqteAsc = '_EQMBILLTONOOFRNTLQTE_ASC_',
  /** sort on EQMBillToNoofRntlQte descending */
  EqmbilltonoofrntlqteDesc = '_EQMBILLTONOOFRNTLQTE_DESC_',
  /** Sort on EQMBillToNoofORContr ascending */
  EqmbilltonooforcontrAsc = '_EQMBILLTONOOFORCONTR_ASC_',
  /** sort on EQMBillToNoofORContr descending */
  EqmbilltonooforcontrDesc = '_EQMBILLTONOOFORCONTR_DESC_',
  /** Sort on EQMBillToNoofRntlOrds ascending */
  EqmbilltonoofrntlordsAsc = '_EQMBILLTONOOFRNTLORDS_ASC_',
  /** sort on EQMBillToNoofRntlOrds descending */
  EqmbilltonoofrntlordsDesc = '_EQMBILLTONOOFRNTLORDS_DESC_',
  /** Sort on EQMBillToNoofRCrM ascending */
  EqmbilltonoofrcrmAsc = '_EQMBILLTONOOFRCRM_ASC_',
  /** sort on EQMBillToNoofRCrM descending */
  EqmbilltonoofrcrmDesc = '_EQMBILLTONOOFRCRM_DESC_',
  /** Sort on EQMBillToNoofPstdRS ascending */
  EqmbilltonoofpstdrsAsc = '_EQMBILLTONOOFPSTDRS_ASC_',
  /** sort on EQMBillToNoofPstdRS descending */
  EqmbilltonoofpstdrsDesc = '_EQMBILLTONOOFPSTDRS_DESC_',
  /** Sort on EQMBillToNoofPstdRI ascending */
  EqmbilltonoofpstdriAsc = '_EQMBILLTONOOFPSTDRI_ASC_',
  /** sort on EQMBillToNoofPstdRI descending */
  EqmbilltonoofpstdriDesc = '_EQMBILLTONOOFPSTDRI_DESC_',
  /** Sort on EQMBillToNoofPRCrM ascending */
  EqmbilltonoofprcrmAsc = '_EQMBILLTONOOFPRCRM_ASC_',
  /** sort on EQMBillToNoofPRCrM descending */
  EqmbilltonoofprcrmDesc = '_EQMBILLTONOOFPRCRM_DESC_',
  /** Sort on EQMBillToNoofWQte ascending */
  EqmbilltonoofwqteAsc = '_EQMBILLTONOOFWQTE_ASC_',
  /** sort on EQMBillToNoofWQte descending */
  EqmbilltonoofwqteDesc = '_EQMBILLTONOOFWQTE_DESC_',
  /** Sort on EQMBillToNoofWrkshpO ascending */
  EqmbilltonoofwrkshpoAsc = '_EQMBILLTONOOFWRKSHPO_ASC_',
  /** sort on EQMBillToNoofWrkshpO descending */
  EqmbilltonoofwrkshpoDesc = '_EQMBILLTONOOFWRKSHPO_DESC_',
  /** Sort on EQMBillToNoofWCrM ascending */
  EqmbilltonoofwcrmAsc = '_EQMBILLTONOOFWCRM_ASC_',
  /** sort on EQMBillToNoofWCrM descending */
  EqmbilltonoofwcrmDesc = '_EQMBILLTONOOFWCRM_DESC_',
  /** Sort on EQMBillToNoofPstdWS ascending */
  EqmbilltonoofpstdwsAsc = '_EQMBILLTONOOFPSTDWS_ASC_',
  /** sort on EQMBillToNoofPstdWS descending */
  EqmbilltonoofpstdwsDesc = '_EQMBILLTONOOFPSTDWS_DESC_',
  /** Sort on EQMBillToNoofPWInv ascending */
  EqmbilltonoofpwinvAsc = '_EQMBILLTONOOFPWINV_ASC_',
  /** sort on EQMBillToNoofPWInv descending */
  EqmbilltonoofpwinvDesc = '_EQMBILLTONOOFPWINV_DESC_',
  /** Sort on EQMBillToNoofPWCrM ascending */
  EqmbilltonoofpwcrmAsc = '_EQMBILLTONOOFPWCRM_ASC_',
  /** sort on EQMBillToNoofPWCrM descending */
  EqmbilltonoofpwcrmDesc = '_EQMBILLTONOOFPWCRM_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMRentalInsuranceOptions ascending */
  EqmrentalinsuranceoptionsAsc = '_EQMRENTALINSURANCEOPTIONS_ASC_',
  /** sort on EQMRentalInsuranceOptions descending */
  EqmrentalinsuranceoptionsDesc = '_EQMRENTALINSURANCEOPTIONS_DESC_',
  /** Sort on EQMRentalInsuranceOptionsOPTION ascending */
  EqmrentalinsuranceoptionsoptionAsc = '_EQMRENTALINSURANCEOPTIONSOPTION_ASC_',
  /** sort on EQMRentalInsuranceOptionsOPTION descending */
  EqmrentalinsuranceoptionsoptionDesc = '_EQMRENTALINSURANCEOPTIONSOPTION_DESC_',
  /** Sort on EQMPONoMandatory ascending */
  EqmponomandatoryAsc = '_EQMPONOMANDATORY_ASC_',
  /** sort on EQMPONoMandatory descending */
  EqmponomandatoryDesc = '_EQMPONOMANDATORY_DESC_',
  /** Sort on EQMNoofRentPickOrders ascending */
  EqmnoofrentpickordersAsc = '_EQMNOOFRENTPICKORDERS_ASC_',
  /** sort on EQMNoofRentPickOrders descending */
  EqmnoofrentpickordersDesc = '_EQMNOOFRENTPICKORDERS_DESC_',
  /** Sort on EQMNoofRentDeliverOrders ascending */
  EqmnoofrentdeliverordersAsc = '_EQMNOOFRENTDELIVERORDERS_ASC_',
  /** sort on EQMNoofRentDeliverOrders descending */
  EqmnoofrentdeliverordersDesc = '_EQMNOOFRENTDELIVERORDERS_DESC_',
  /** Sort on EQMNoofRentCollOrders ascending */
  EqmnoofrentcollordersAsc = '_EQMNOOFRENTCOLLORDERS_ASC_',
  /** sort on EQMNoofRentCollOrders descending */
  EqmnoofrentcollordersDesc = '_EQMNOOFRENTCOLLORDERS_DESC_',
  /** Sort on EQMNoofPstdPickOrders ascending */
  EqmnoofpstdpickordersAsc = '_EQMNOOFPSTDPICKORDERS_ASC_',
  /** sort on EQMNoofPstdPickOrders descending */
  EqmnoofpstdpickordersDesc = '_EQMNOOFPSTDPICKORDERS_DESC_',
  /** Sort on EQMNoofPstdDelOrders ascending */
  EqmnoofpstddelordersAsc = '_EQMNOOFPSTDDELORDERS_ASC_',
  /** sort on EQMNoofPstdDelOrders descending */
  EqmnoofpstddelordersDesc = '_EQMNOOFPSTDDELORDERS_DESC_',
  /** Sort on EQMNoofPstdCollOrders ascending */
  EqmnoofpstdcollordersAsc = '_EQMNOOFPSTDCOLLORDERS_ASC_',
  /** sort on EQMNoofPstdCollOrders descending */
  EqmnoofpstdcollordersDesc = '_EQMNOOFPSTDCOLLORDERS_DESC_',
  /** Sort on EQMStairPriceFilter ascending */
  EqmstairpricefilterAsc = '_EQMSTAIRPRICEFILTER_ASC_',
  /** sort on EQMStairPriceFilter descending */
  EqmstairpricefilterDesc = '_EQMSTAIRPRICEFILTER_DESC_',
  /** Sort on EQMStairDiscountFilter ascending */
  EqmstairdiscountfilterAsc = '_EQMSTAIRDISCOUNTFILTER_ASC_',
  /** sort on EQMStairDiscountFilter descending */
  EqmstairdiscountfilterDesc = '_EQMSTAIRDISCOUNTFILTER_DESC_',
  /** Sort on HowtoInvoiceRentalContract ascending */
  HowtoinvoicerentalcontractAsc = '_HOWTOINVOICERENTALCONTRACT_ASC_',
  /** sort on HowtoInvoiceRentalContract descending */
  HowtoinvoicerentalcontractDesc = '_HOWTOINVOICERENTALCONTRACT_DESC_',
  /** Sort on HowtoInvoiceRentalContractOPTION ascending */
  HowtoinvoicerentalcontractoptionAsc = '_HOWTOINVOICERENTALCONTRACTOPTION_ASC_',
  /** sort on HowtoInvoiceRentalContractOPTION descending */
  HowtoinvoicerentalcontractoptionDesc = '_HOWTOINVOICERENTALCONTRACTOPTION_DESC_',
  /** Sort on EQMShortTermRental ascending */
  EqmshorttermrentalAsc = '_EQMSHORTTERMRENTAL_ASC_',
  /** sort on EQMShortTermRental descending */
  EqmshorttermrentalDesc = '_EQMSHORTTERMRENTAL_DESC_',
  /** Sort on EQMCombineInvoiceSubProj ascending */
  EqmcombineinvoicesubprojAsc = '_EQMCOMBINEINVOICESUBPROJ_ASC_',
  /** sort on EQMCombineInvoiceSubProj descending */
  EqmcombineinvoicesubprojDesc = '_EQMCOMBINEINVOICESUBPROJ_DESC_',
  /** Sort on EQMTermofContract ascending */
  EqmtermofcontractAsc = '_EQMTERMOFCONTRACT_ASC_',
  /** sort on EQMTermofContract descending */
  EqmtermofcontractDesc = '_EQMTERMOFCONTRACT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortConsignmentHeaderInput {
  /** Sort on TableID ascending */
  TableidAsc = '_TABLEID_ASC_',
  /** sort on TableID descending */
  TableidDesc = '_TABLEID_DESC_',
  /** Sort on UniqueID ascending */
  UniqueidAsc = '_UNIQUEID_ASC_',
  /** sort on UniqueID descending */
  UniqueidDesc = '_UNIQUEID_DESC_',
  /** Sort on ConsignmentNo ascending */
  ConsignmentnoAsc = '_CONSIGNMENTNO_ASC_',
  /** sort on ConsignmentNo descending */
  ConsignmentnoDesc = '_CONSIGNMENTNO_DESC_',
  /** Sort on ConsignmentType ascending */
  ConsignmenttypeAsc = '_CONSIGNMENTTYPE_ASC_',
  /** sort on ConsignmentType descending */
  ConsignmenttypeDesc = '_CONSIGNMENTTYPE_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on DocumentTable ascending */
  DocumenttableAsc = '_DOCUMENTTABLE_ASC_',
  /** sort on DocumentTable descending */
  DocumenttableDesc = '_DOCUMENTTABLE_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on PickupName ascending */
  PickupnameAsc = '_PICKUPNAME_ASC_',
  /** sort on PickupName descending */
  PickupnameDesc = '_PICKUPNAME_DESC_',
  /** Sort on PickupAddress ascending */
  PickupaddressAsc = '_PICKUPADDRESS_ASC_',
  /** sort on PickupAddress descending */
  PickupaddressDesc = '_PICKUPADDRESS_DESC_',
  /** Sort on PickupAddress2 ascending */
  Pickupaddress2Asc = '_PICKUPADDRESS2_ASC_',
  /** sort on PickupAddress2 descending */
  Pickupaddress2Desc = '_PICKUPADDRESS2_DESC_',
  /** Sort on PickupPostCode ascending */
  PickuppostcodeAsc = '_PICKUPPOSTCODE_ASC_',
  /** sort on PickupPostCode descending */
  PickuppostcodeDesc = '_PICKUPPOSTCODE_DESC_',
  /** Sort on PickupCity ascending */
  PickupcityAsc = '_PICKUPCITY_ASC_',
  /** sort on PickupCity descending */
  PickupcityDesc = '_PICKUPCITY_DESC_',
  /** Sort on PickupCounty ascending */
  PickupcountyAsc = '_PICKUPCOUNTY_ASC_',
  /** sort on PickupCounty descending */
  PickupcountyDesc = '_PICKUPCOUNTY_DESC_',
  /** Sort on PickupCountryRegionCode ascending */
  PickupcountryregioncodeAsc = '_PICKUPCOUNTRYREGIONCODE_ASC_',
  /** sort on PickupCountryRegionCode descending */
  PickupcountryregioncodeDesc = '_PICKUPCOUNTRYREGIONCODE_DESC_',
  /** Sort on PickupContactNo ascending */
  PickupcontactnoAsc = '_PICKUPCONTACTNO_ASC_',
  /** sort on PickupContactNo descending */
  PickupcontactnoDesc = '_PICKUPCONTACTNO_DESC_',
  /** Sort on PickupContactName ascending */
  PickupcontactnameAsc = '_PICKUPCONTACTNAME_ASC_',
  /** sort on PickupContactName descending */
  PickupcontactnameDesc = '_PICKUPCONTACTNAME_DESC_',
  /** Sort on PickupContactPhoneNo ascending */
  PickupcontactphonenoAsc = '_PICKUPCONTACTPHONENO_ASC_',
  /** sort on PickupContactPhoneNo descending */
  PickupcontactphonenoDesc = '_PICKUPCONTACTPHONENO_DESC_',
  /** Sort on PickupContactMobPhoneNo ascending */
  PickupcontactmobphonenoAsc = '_PICKUPCONTACTMOBPHONENO_ASC_',
  /** sort on PickupContactMobPhoneNo descending */
  PickupcontactmobphonenoDesc = '_PICKUPCONTACTMOBPHONENO_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoContactNo ascending */
  ShiptocontactnoAsc = '_SHIPTOCONTACTNO_ASC_',
  /** sort on ShiptoContactNo descending */
  ShiptocontactnoDesc = '_SHIPTOCONTACTNO_DESC_',
  /** Sort on ShiptoContactName ascending */
  ShiptocontactnameAsc = '_SHIPTOCONTACTNAME_ASC_',
  /** sort on ShiptoContactName descending */
  ShiptocontactnameDesc = '_SHIPTOCONTACTNAME_DESC_',
  /** Sort on ShiptoContactPhoneNo ascending */
  ShiptocontactphonenoAsc = '_SHIPTOCONTACTPHONENO_ASC_',
  /** sort on ShiptoContactPhoneNo descending */
  ShiptocontactphonenoDesc = '_SHIPTOCONTACTPHONENO_DESC_',
  /** Sort on ShiptoContactMobPhoneNo ascending */
  ShiptocontactmobphonenoAsc = '_SHIPTOCONTACTMOBPHONENO_ASC_',
  /** sort on ShiptoContactMobPhoneNo descending */
  ShiptocontactmobphonenoDesc = '_SHIPTOCONTACTMOBPHONENO_DESC_',
  /** Sort on EQMRentalContractNo ascending */
  EqmrentalcontractnoAsc = '_EQMRENTALCONTRACTNO_ASC_',
  /** sort on EQMRentalContractNo descending */
  EqmrentalcontractnoDesc = '_EQMRENTALCONTRACTNO_DESC_',
  /** Sort on EQMProjectNo ascending */
  EqmprojectnoAsc = '_EQMPROJECTNO_ASC_',
  /** sort on EQMProjectNo descending */
  EqmprojectnoDesc = '_EQMPROJECTNO_DESC_',
  /** Sort on EQMPickOrderNo ascending */
  EqmpickordernoAsc = '_EQMPICKORDERNO_ASC_',
  /** sort on EQMPickOrderNo descending */
  EqmpickordernoDesc = '_EQMPICKORDERNO_DESC_',
  /** Sort on EQMShipOrderNo ascending */
  EqmshipordernoAsc = '_EQMSHIPORDERNO_ASC_',
  /** sort on EQMShipOrderNo descending */
  EqmshipordernoDesc = '_EQMSHIPORDERNO_DESC_',
  /** Sort on ReturnOrder ascending */
  ReturnorderAsc = '_RETURNORDER_ASC_',
  /** sort on ReturnOrder descending */
  ReturnorderDesc = '_RETURNORDER_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on TransportRequestSent ascending */
  TransportrequestsentAsc = '_TRANSPORTREQUESTSENT_ASC_',
  /** sort on TransportRequestSent descending */
  TransportrequestsentDesc = '_TRANSPORTREQUESTSENT_DESC_',
  /** Sort on TransportRequestSentBy ascending */
  TransportrequestsentbyAsc = '_TRANSPORTREQUESTSENTBY_ASC_',
  /** sort on TransportRequestSentBy descending */
  TransportrequestsentbyDesc = '_TRANSPORTREQUESTSENTBY_DESC_',
  /** Sort on TransportComment ascending */
  TransportcommentAsc = '_TRANSPORTCOMMENT_ASC_',
  /** sort on TransportComment descending */
  TransportcommentDesc = '_TRANSPORTCOMMENT_DESC_',
  /** Sort on ReadyToFetchDate ascending */
  ReadytofetchdateAsc = '_READYTOFETCHDATE_ASC_',
  /** sort on ReadyToFetchDate descending */
  ReadytofetchdateDesc = '_READYTOFETCHDATE_DESC_',
  /** Sort on ReadyToFetchTime ascending */
  ReadytofetchtimeAsc = '_READYTOFETCHTIME_ASC_',
  /** sort on ReadyToFetchTime descending */
  ReadytofetchtimeDesc = '_READYTOFETCHTIME_DESC_',
  /** Sort on LatestAtRecieverDate ascending */
  LatestatrecieverdateAsc = '_LATESTATRECIEVERDATE_ASC_',
  /** sort on LatestAtRecieverDate descending */
  LatestatrecieverdateDesc = '_LATESTATRECIEVERDATE_DESC_',
  /** Sort on LatestAtRecieverTime ascending */
  LatestatrecievertimeAsc = '_LATESTATRECIEVERTIME_ASC_',
  /** sort on LatestAtRecieverTime descending */
  LatestatrecievertimeDesc = '_LATESTATRECIEVERTIME_DESC_',
  /** Sort on AtReceiverOnTime ascending */
  AtreceiverontimeAsc = '_ATRECEIVERONTIME_ASC_',
  /** sort on AtReceiverOnTime descending */
  AtreceiverontimeDesc = '_ATRECEIVERONTIME_DESC_',
  /** Sort on TotalEstimatedVolume ascending */
  TotalestimatedvolumeAsc = '_TOTALESTIMATEDVOLUME_ASC_',
  /** sort on TotalEstimatedVolume descending */
  TotalestimatedvolumeDesc = '_TOTALESTIMATEDVOLUME_DESC_',
  /** Sort on TransportConfirmed ascending */
  TransportconfirmedAsc = '_TRANSPORTCONFIRMED_ASC_',
  /** sort on TransportConfirmed descending */
  TransportconfirmedDesc = '_TRANSPORTCONFIRMED_DESC_',
  /** Sort on TransportConfirmedBy ascending */
  TransportconfirmedbyAsc = '_TRANSPORTCONFIRMEDBY_ASC_',
  /** sort on TransportConfirmedBy descending */
  TransportconfirmedbyDesc = '_TRANSPORTCONFIRMEDBY_DESC_',
  /** Sort on TypeOfVehicle ascending */
  TypeofvehicleAsc = '_TYPEOFVEHICLE_ASC_',
  /** sort on TypeOfVehicle descending */
  TypeofvehicleDesc = '_TYPEOFVEHICLE_DESC_',
  /** Sort on Freightisdebited ascending */
  FreightisdebitedAsc = '_FREIGHTISDEBITED_ASC_',
  /** sort on Freightisdebited descending */
  FreightisdebitedDesc = '_FREIGHTISDEBITED_DESC_',
  /** Sort on EQMCollectionOrderNo ascending */
  EqmcollectionordernoAsc = '_EQMCOLLECTIONORDERNO_ASC_',
  /** sort on EQMCollectionOrderNo descending */
  EqmcollectionordernoDesc = '_EQMCOLLECTIONORDERNO_DESC_',
  /** Sort on TotalGrossWeight ascending */
  TotalgrossweightAsc = '_TOTALGROSSWEIGHT_ASC_',
  /** sort on TotalGrossWeight descending */
  TotalgrossweightDesc = '_TOTALGROSSWEIGHT_DESC_',
  /** Sort on TotalNetWeight ascending */
  TotalnetweightAsc = '_TOTALNETWEIGHT_ASC_',
  /** sort on TotalNetWeight descending */
  TotalnetweightDesc = '_TOTALNETWEIGHT_DESC_',
  /** Sort on TotalVolume ascending */
  TotalvolumeAsc = '_TOTALVOLUME_ASC_',
  /** sort on TotalVolume descending */
  TotalvolumeDesc = '_TOTALVOLUME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortConsignmentLineInput {
  /** Sort on ConsignmentNo ascending */
  ConsignmentnoAsc = '_CONSIGNMENTNO_ASC_',
  /** sort on ConsignmentNo descending */
  ConsignmentnoDesc = '_CONSIGNMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on OriginalTableID ascending */
  OriginaltableidAsc = '_ORIGINALTABLEID_ASC_',
  /** sort on OriginalTableID descending */
  OriginaltableidDesc = '_ORIGINALTABLEID_DESC_',
  /** Sort on OriginalUniqueID ascending */
  OriginaluniqueidAsc = '_ORIGINALUNIQUEID_ASC_',
  /** sort on OriginalUniqueID descending */
  OriginaluniqueidDesc = '_ORIGINALUNIQUEID_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on TotalGrossWeight ascending */
  TotalgrossweightAsc = '_TOTALGROSSWEIGHT_ASC_',
  /** sort on TotalGrossWeight descending */
  TotalgrossweightDesc = '_TOTALGROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on TotalNetWeight ascending */
  TotalnetweightAsc = '_TOTALNETWEIGHT_ASC_',
  /** sort on TotalNetWeight descending */
  TotalnetweightDesc = '_TOTALNETWEIGHT_DESC_',
  /** Sort on Volume ascending */
  VolumeAsc = '_VOLUME_ASC_',
  /** sort on Volume descending */
  VolumeDesc = '_VOLUME_DESC_',
  /** Sort on TotalVolume ascending */
  TotalvolumeAsc = '_TOTALVOLUME_ASC_',
  /** sort on TotalVolume descending */
  TotalvolumeDesc = '_TOTALVOLUME_DESC_',
  /** Sort on TextBlob ascending */
  TextblobAsc = '_TEXTBLOB_ASC_',
  /** sort on TextBlob descending */
  TextblobDesc = '_TEXTBLOB_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortContactInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on SearchName ascending */
  SearchnameAsc = '_SEARCHNAME_ASC_',
  /** sort on SearchName descending */
  SearchnameDesc = '_SEARCHNAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on TelexNo ascending */
  TelexnoAsc = '_TELEXNO_ASC_',
  /** sort on TelexNo descending */
  TelexnoDesc = '_TELEXNO_DESC_',
  /** Sort on TerritoryCode ascending */
  TerritorycodeAsc = '_TERRITORYCODE_ASC_',
  /** sort on TerritoryCode descending */
  TerritorycodeDesc = '_TERRITORYCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on TelexAnswerBack ascending */
  TelexanswerbackAsc = '_TELEXANSWERBACK_ASC_',
  /** sort on TelexAnswerBack descending */
  TelexanswerbackDesc = '_TELEXANSWERBACK_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on Image ascending */
  ImageAsc = '_IMAGE_ASC_',
  /** sort on Image descending */
  ImageDesc = '_IMAGE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on CompanyNo ascending */
  CompanynoAsc = '_COMPANYNO_ASC_',
  /** sort on CompanyNo descending */
  CompanynoDesc = '_COMPANYNO_DESC_',
  /** Sort on CompanyName ascending */
  CompanynameAsc = '_COMPANYNAME_ASC_',
  /** sort on CompanyName descending */
  CompanynameDesc = '_COMPANYNAME_DESC_',
  /** Sort on LookupContactNo ascending */
  LookupcontactnoAsc = '_LOOKUPCONTACTNO_ASC_',
  /** sort on LookupContactNo descending */
  LookupcontactnoDesc = '_LOOKUPCONTACTNO_DESC_',
  /** Sort on FirstName ascending */
  FirstnameAsc = '_FIRSTNAME_ASC_',
  /** sort on FirstName descending */
  FirstnameDesc = '_FIRSTNAME_DESC_',
  /** Sort on MiddleName ascending */
  MiddlenameAsc = '_MIDDLENAME_ASC_',
  /** sort on MiddleName descending */
  MiddlenameDesc = '_MIDDLENAME_DESC_',
  /** Sort on Surname ascending */
  SurnameAsc = '_SURNAME_ASC_',
  /** sort on Surname descending */
  SurnameDesc = '_SURNAME_DESC_',
  /** Sort on JobTitle ascending */
  JobtitleAsc = '_JOBTITLE_ASC_',
  /** sort on JobTitle descending */
  JobtitleDesc = '_JOBTITLE_DESC_',
  /** Sort on Initials ascending */
  InitialsAsc = '_INITIALS_ASC_',
  /** sort on Initials descending */
  InitialsDesc = '_INITIALS_DESC_',
  /** Sort on ExtensionNo ascending */
  ExtensionnoAsc = '_EXTENSIONNO_ASC_',
  /** sort on ExtensionNo descending */
  ExtensionnoDesc = '_EXTENSIONNO_DESC_',
  /** Sort on MobilePhoneNo ascending */
  MobilephonenoAsc = '_MOBILEPHONENO_ASC_',
  /** sort on MobilePhoneNo descending */
  MobilephonenoDesc = '_MOBILEPHONENO_DESC_',
  /** Sort on Pager ascending */
  PagerAsc = '_PAGER_ASC_',
  /** sort on Pager descending */
  PagerDesc = '_PAGER_DESC_',
  /** Sort on OrganizationalLevelCode ascending */
  OrganizationallevelcodeAsc = '_ORGANIZATIONALLEVELCODE_ASC_',
  /** sort on OrganizationalLevelCode descending */
  OrganizationallevelcodeDesc = '_ORGANIZATIONALLEVELCODE_DESC_',
  /** Sort on ExcludefromSegment ascending */
  ExcludefromsegmentAsc = '_EXCLUDEFROMSEGMENT_ASC_',
  /** sort on ExcludefromSegment descending */
  ExcludefromsegmentDesc = '_EXCLUDEFROMSEGMENT_DESC_',
  /** Sort on ExternalID ascending */
  ExternalidAsc = '_EXTERNALID_ASC_',
  /** sort on ExternalID descending */
  ExternalidDesc = '_EXTERNALID_DESC_',
  /** Sort on CorrespondenceType ascending */
  CorrespondencetypeAsc = '_CORRESPONDENCETYPE_ASC_',
  /** sort on CorrespondenceType descending */
  CorrespondencetypeDesc = '_CORRESPONDENCETYPE_DESC_',
  /** Sort on SalutationCode ascending */
  SalutationcodeAsc = '_SALUTATIONCODE_ASC_',
  /** sort on SalutationCode descending */
  SalutationcodeDesc = '_SALUTATIONCODE_DESC_',
  /** Sort on SearchEMail ascending */
  SearchemailAsc = '_SEARCHEMAIL_ASC_',
  /** sort on SearchEMail descending */
  SearchemailDesc = '_SEARCHEMAIL_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on EMail2 ascending */
  Email2Asc = '_EMAIL2_ASC_',
  /** sort on EMail2 descending */
  Email2Desc = '_EMAIL2_DESC_',
  /** Sort on XrmId ascending */
  XrmidAsc = '_XRMID_ASC_',
  /** sort on XrmId descending */
  XrmidDesc = '_XRMID_DESC_',
  /** Sort on EQMEvaluationStatus ascending */
  EqmevaluationstatusAsc = '_EQMEVALUATIONSTATUS_ASC_',
  /** sort on EQMEvaluationStatus descending */
  EqmevaluationstatusDesc = '_EQMEVALUATIONSTATUS_DESC_',
  /** Sort on EQMCheckListCode ascending */
  EqmchecklistcodeAsc = '_EQMCHECKLISTCODE_ASC_',
  /** sort on EQMCheckListCode descending */
  EqmchecklistcodeDesc = '_EQMCHECKLISTCODE_DESC_',
  /** Sort on EQMCustomerCategory2 ascending */
  Eqmcustomercategory2Asc = '_EQMCUSTOMERCATEGORY2_ASC_',
  /** sort on EQMCustomerCategory2 descending */
  Eqmcustomercategory2Desc = '_EQMCUSTOMERCATEGORY2_DESC_',
  /** Sort on EQMIdentificationNo ascending */
  EqmidentificationnoAsc = '_EQMIDENTIFICATIONNO_ASC_',
  /** sort on EQMIdentificationNo descending */
  EqmidentificationnoDesc = '_EQMIDENTIFICATIONNO_DESC_',
  /** Sort on EQMContactType ascending */
  EqmcontacttypeAsc = '_EQMCONTACTTYPE_ASC_',
  /** sort on EQMContactType descending */
  EqmcontacttypeDesc = '_EQMCONTACTTYPE_DESC_',
  /** Sort on EQMNotEditable ascending */
  EqmnoteditableAsc = '_EQMNOTEDITABLE_ASC_',
  /** sort on EQMNotEditable descending */
  EqmnoteditableDesc = '_EQMNOTEDITABLE_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on SalespersonFilter ascending */
  SalespersonfilterAsc = '_SALESPERSONFILTER_ASC_',
  /** sort on SalespersonFilter descending */
  SalespersonfilterDesc = '_SALESPERSONFILTER_DESC_',
  /** Sort on CampaignFilter ascending */
  CampaignfilterAsc = '_CAMPAIGNFILTER_ASC_',
  /** sort on CampaignFilter descending */
  CampaignfilterDesc = '_CAMPAIGNFILTER_DESC_',
  /** Sort on ActionTakenFilter ascending */
  ActiontakenfilterAsc = '_ACTIONTAKENFILTER_ASC_',
  /** sort on ActionTakenFilter descending */
  ActiontakenfilterDesc = '_ACTIONTAKENFILTER_DESC_',
  /** Sort on SalesCycleFilter ascending */
  SalescyclefilterAsc = '_SALESCYCLEFILTER_ASC_',
  /** sort on SalesCycleFilter descending */
  SalescyclefilterDesc = '_SALESCYCLEFILTER_DESC_',
  /** Sort on SalesCycleStageFilter ascending */
  SalescyclestagefilterAsc = '_SALESCYCLESTAGEFILTER_ASC_',
  /** sort on SalesCycleStageFilter descending */
  SalescyclestagefilterDesc = '_SALESCYCLESTAGEFILTER_DESC_',
  /** Sort on ProbabilityFilter ascending */
  ProbabilityfilterAsc = '_PROBABILITYFILTER_ASC_',
  /** sort on ProbabilityFilter descending */
  ProbabilityfilterDesc = '_PROBABILITYFILTER_DESC_',
  /** Sort on CompletedFilter ascending */
  CompletedfilterAsc = '_COMPLETEDFILTER_ASC_',
  /** sort on CompletedFilter descending */
  CompletedfilterDesc = '_COMPLETEDFILTER_DESC_',
  /** Sort on EstimatedValueFilter ascending */
  EstimatedvaluefilterAsc = '_ESTIMATEDVALUEFILTER_ASC_',
  /** sort on EstimatedValueFilter descending */
  EstimatedvaluefilterDesc = '_ESTIMATEDVALUEFILTER_DESC_',
  /** Sort on CalcdCurrentValueFilter ascending */
  CalcdcurrentvaluefilterAsc = '_CALCDCURRENTVALUEFILTER_ASC_',
  /** sort on CalcdCurrentValueFilter descending */
  CalcdcurrentvaluefilterDesc = '_CALCDCURRENTVALUEFILTER_DESC_',
  /** Sort on ChancesofSuccessFilter ascending */
  ChancesofsuccessfilterAsc = '_CHANCESOFSUCCESSFILTER_ASC_',
  /** sort on ChancesofSuccessFilter descending */
  ChancesofsuccessfilterDesc = '_CHANCESOFSUCCESSFILTER_DESC_',
  /** Sort on TaskStatusFilter ascending */
  TaskstatusfilterAsc = '_TASKSTATUSFILTER_ASC_',
  /** sort on TaskStatusFilter descending */
  TaskstatusfilterDesc = '_TASKSTATUSFILTER_DESC_',
  /** Sort on TaskClosedFilter ascending */
  TaskclosedfilterAsc = '_TASKCLOSEDFILTER_ASC_',
  /** sort on TaskClosedFilter descending */
  TaskclosedfilterDesc = '_TASKCLOSEDFILTER_DESC_',
  /** Sort on PriorityFilter ascending */
  PriorityfilterAsc = '_PRIORITYFILTER_ASC_',
  /** sort on PriorityFilter descending */
  PriorityfilterDesc = '_PRIORITYFILTER_DESC_',
  /** Sort on TeamFilter ascending */
  TeamfilterAsc = '_TEAMFILTER_ASC_',
  /** sort on TeamFilter descending */
  TeamfilterDesc = '_TEAMFILTER_DESC_',
  /** Sort on CloseOpportunityFilter ascending */
  CloseopportunityfilterAsc = '_CLOSEOPPORTUNITYFILTER_ASC_',
  /** sort on CloseOpportunityFilter descending */
  CloseopportunityfilterDesc = '_CLOSEOPPORTUNITYFILTER_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on NextTaskDate ascending */
  NexttaskdateAsc = '_NEXTTASKDATE_ASC_',
  /** sort on NextTaskDate descending */
  NexttaskdateDesc = '_NEXTTASKDATE_DESC_',
  /** Sort on LastDateAttempted ascending */
  LastdateattemptedAsc = '_LASTDATEATTEMPTED_ASC_',
  /** sort on LastDateAttempted descending */
  LastdateattemptedDesc = '_LASTDATEATTEMPTED_DESC_',
  /** Sort on DateofLastInteraction ascending */
  DateoflastinteractionAsc = '_DATEOFLASTINTERACTION_ASC_',
  /** sort on DateofLastInteraction descending */
  DateoflastinteractionDesc = '_DATEOFLASTINTERACTION_DESC_',
  /** Sort on NoofJobResponsibilities ascending */
  NoofjobresponsibilitiesAsc = '_NOOFJOBRESPONSIBILITIES_ASC_',
  /** sort on NoofJobResponsibilities descending */
  NoofjobresponsibilitiesDesc = '_NOOFJOBRESPONSIBILITIES_DESC_',
  /** Sort on NoofIndustryGroups ascending */
  NoofindustrygroupsAsc = '_NOOFINDUSTRYGROUPS_ASC_',
  /** sort on NoofIndustryGroups descending */
  NoofindustrygroupsDesc = '_NOOFINDUSTRYGROUPS_DESC_',
  /** Sort on NoofBusinessRelations ascending */
  NoofbusinessrelationsAsc = '_NOOFBUSINESSRELATIONS_ASC_',
  /** sort on NoofBusinessRelations descending */
  NoofbusinessrelationsDesc = '_NOOFBUSINESSRELATIONS_DESC_',
  /** Sort on NoofMailingGroups ascending */
  NoofmailinggroupsAsc = '_NOOFMAILINGGROUPS_ASC_',
  /** sort on NoofMailingGroups descending */
  NoofmailinggroupsDesc = '_NOOFMAILINGGROUPS_DESC_',
  /** Sort on NoofInteractions ascending */
  NoofinteractionsAsc = '_NOOFINTERACTIONS_ASC_',
  /** sort on NoofInteractions descending */
  NoofinteractionsDesc = '_NOOFINTERACTIONS_DESC_',
  /** Sort on CostLCY ascending */
  CostlcyAsc = '_COSTLCY_ASC_',
  /** sort on CostLCY descending */
  CostlcyDesc = '_COSTLCY_DESC_',
  /** Sort on DurationMin ascending */
  DurationminAsc = '_DURATIONMIN_ASC_',
  /** sort on DurationMin descending */
  DurationminDesc = '_DURATIONMIN_DESC_',
  /** Sort on NoofOpportunities ascending */
  NoofopportunitiesAsc = '_NOOFOPPORTUNITIES_ASC_',
  /** sort on NoofOpportunities descending */
  NoofopportunitiesDesc = '_NOOFOPPORTUNITIES_DESC_',
  /** Sort on EstimatedValueLCY ascending */
  EstimatedvaluelcyAsc = '_ESTIMATEDVALUELCY_ASC_',
  /** sort on EstimatedValueLCY descending */
  EstimatedvaluelcyDesc = '_ESTIMATEDVALUELCY_DESC_',
  /** Sort on CalcdCurrentValueLCY ascending */
  CalcdcurrentvaluelcyAsc = '_CALCDCURRENTVALUELCY_ASC_',
  /** sort on CalcdCurrentValueLCY descending */
  CalcdcurrentvaluelcyDesc = '_CALCDCURRENTVALUELCY_DESC_',
  /** Sort on OpportunityEntryExists ascending */
  OpportunityentryexistsAsc = '_OPPORTUNITYENTRYEXISTS_ASC_',
  /** sort on OpportunityEntryExists descending */
  OpportunityentryexistsDesc = '_OPPORTUNITYENTRYEXISTS_DESC_',
  /** Sort on TaskEntryExists ascending */
  TaskentryexistsAsc = '_TASKENTRYEXISTS_ASC_',
  /** sort on TaskEntryExists descending */
  TaskentryexistsDesc = '_TASKENTRYEXISTS_DESC_',
  /** Sort on LinkedToCustomerNo ascending */
  LinkedtocustomernoAsc = '_LINKEDTOCUSTOMERNO_ASC_',
  /** sort on LinkedToCustomerNo descending */
  LinkedtocustomernoDesc = '_LINKEDTOCUSTOMERNO_DESC_',
  /** Sort on Lead ascending */
  LeadAsc = '_LEAD_ASC_',
  /** sort on Lead descending */
  LeadDesc = '_LEAD_DESC_',
  /** Sort on LinkedTo ascending */
  LinkedtoAsc = '_LINKEDTO_ASC_',
  /** sort on LinkedTo descending */
  LinkedtoDesc = '_LINKEDTO_DESC_',
  /** Sort on LastCustomerLedgerDate ascending */
  LastcustomerledgerdateAsc = '_LASTCUSTOMERLEDGERDATE_ASC_',
  /** sort on LastCustomerLedgerDate descending */
  LastcustomerledgerdateDesc = '_LASTCUSTOMERLEDGERDATE_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCountryRegionInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on ISOCode ascending */
  IsocodeAsc = '_ISOCODE_ASC_',
  /** sort on ISOCode descending */
  IsocodeDesc = '_ISOCODE_DESC_',
  /** Sort on ISONumericCode ascending */
  IsonumericcodeAsc = '_ISONUMERICCODE_ASC_',
  /** sort on ISONumericCode descending */
  IsonumericcodeDesc = '_ISONUMERICCODE_DESC_',
  /** Sort on EUCountryRegionCode ascending */
  EucountryregioncodeAsc = '_EUCOUNTRYREGIONCODE_ASC_',
  /** sort on EUCountryRegionCode descending */
  EucountryregioncodeDesc = '_EUCOUNTRYREGIONCODE_DESC_',
  /** Sort on IntrastatCode ascending */
  IntrastatcodeAsc = '_INTRASTATCODE_ASC_',
  /** sort on IntrastatCode descending */
  IntrastatcodeDesc = '_INTRASTATCODE_DESC_',
  /** Sort on AddressFormat ascending */
  AddressformatAsc = '_ADDRESSFORMAT_ASC_',
  /** sort on AddressFormat descending */
  AddressformatDesc = '_ADDRESSFORMAT_DESC_',
  /** Sort on AddressFormatOPTION ascending */
  AddressformatoptionAsc = '_ADDRESSFORMATOPTION_ASC_',
  /** sort on AddressFormatOPTION descending */
  AddressformatoptionDesc = '_ADDRESSFORMATOPTION_DESC_',
  /** Sort on ContactAddressFormat ascending */
  ContactaddressformatAsc = '_CONTACTADDRESSFORMAT_ASC_',
  /** sort on ContactAddressFormat descending */
  ContactaddressformatDesc = '_CONTACTADDRESSFORMAT_DESC_',
  /** Sort on ContactAddressFormatOPTION ascending */
  ContactaddressformatoptionAsc = '_CONTACTADDRESSFORMATOPTION_ASC_',
  /** sort on ContactAddressFormatOPTION descending */
  ContactaddressformatoptionDesc = '_CONTACTADDRESSFORMATOPTION_DESC_',
  /** Sort on VATScheme ascending */
  VatschemeAsc = '_VATSCHEME_ASC_',
  /** sort on VATScheme descending */
  VatschemeDesc = '_VATSCHEME_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on CountyName ascending */
  CountynameAsc = '_COUNTYNAME_ASC_',
  /** sort on CountyName descending */
  CountynameDesc = '_COUNTYNAME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCurrencyExchangeRateInput {
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on StartingDate ascending */
  StartingdateAsc = '_STARTINGDATE_ASC_',
  /** sort on StartingDate descending */
  StartingdateDesc = '_STARTINGDATE_DESC_',
  /** Sort on ExchangeRateAmount ascending */
  ExchangerateamountAsc = '_EXCHANGERATEAMOUNT_ASC_',
  /** sort on ExchangeRateAmount descending */
  ExchangerateamountDesc = '_EXCHANGERATEAMOUNT_DESC_',
  /** Sort on AdjustmentExchRateAmount ascending */
  AdjustmentexchrateamountAsc = '_ADJUSTMENTEXCHRATEAMOUNT_ASC_',
  /** sort on AdjustmentExchRateAmount descending */
  AdjustmentexchrateamountDesc = '_ADJUSTMENTEXCHRATEAMOUNT_DESC_',
  /** Sort on RelationalCurrencyCode ascending */
  RelationalcurrencycodeAsc = '_RELATIONALCURRENCYCODE_ASC_',
  /** sort on RelationalCurrencyCode descending */
  RelationalcurrencycodeDesc = '_RELATIONALCURRENCYCODE_DESC_',
  /** Sort on RelationalExchRateAmount ascending */
  RelationalexchrateamountAsc = '_RELATIONALEXCHRATEAMOUNT_ASC_',
  /** sort on RelationalExchRateAmount descending */
  RelationalexchrateamountDesc = '_RELATIONALEXCHRATEAMOUNT_DESC_',
  /** Sort on FixExchRateAmount ascending */
  FixexchrateamountAsc = '_FIXEXCHRATEAMOUNT_ASC_',
  /** sort on FixExchRateAmount descending */
  FixexchrateamountDesc = '_FIXEXCHRATEAMOUNT_DESC_',
  /** Sort on FixExchRateAmountOPTION ascending */
  FixexchrateamountoptionAsc = '_FIXEXCHRATEAMOUNTOPTION_ASC_',
  /** sort on FixExchRateAmountOPTION descending */
  FixexchrateamountoptionDesc = '_FIXEXCHRATEAMOUNTOPTION_DESC_',
  /** Sort on RelationalAdjmtExchRateAmt ascending */
  RelationaladjmtexchrateamtAsc = '_RELATIONALADJMTEXCHRATEAMT_ASC_',
  /** sort on RelationalAdjmtExchRateAmt descending */
  RelationaladjmtexchrateamtDesc = '_RELATIONALADJMTEXCHRATEAMT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCurrencyInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastDateAdjusted ascending */
  LastdateadjustedAsc = '_LASTDATEADJUSTED_ASC_',
  /** sort on LastDateAdjusted descending */
  LastdateadjustedDesc = '_LASTDATEADJUSTED_DESC_',
  /** Sort on ISOCode ascending */
  IsocodeAsc = '_ISOCODE_ASC_',
  /** sort on ISOCode descending */
  IsocodeDesc = '_ISOCODE_DESC_',
  /** Sort on ISONumericCode ascending */
  IsonumericcodeAsc = '_ISONUMERICCODE_ASC_',
  /** sort on ISONumericCode descending */
  IsonumericcodeDesc = '_ISONUMERICCODE_DESC_',
  /** Sort on UnrealizedGainsAcc ascending */
  UnrealizedgainsaccAsc = '_UNREALIZEDGAINSACC_ASC_',
  /** sort on UnrealizedGainsAcc descending */
  UnrealizedgainsaccDesc = '_UNREALIZEDGAINSACC_DESC_',
  /** Sort on RealizedGainsAcc ascending */
  RealizedgainsaccAsc = '_REALIZEDGAINSACC_ASC_',
  /** sort on RealizedGainsAcc descending */
  RealizedgainsaccDesc = '_REALIZEDGAINSACC_DESC_',
  /** Sort on UnrealizedLossesAcc ascending */
  UnrealizedlossesaccAsc = '_UNREALIZEDLOSSESACC_ASC_',
  /** sort on UnrealizedLossesAcc descending */
  UnrealizedlossesaccDesc = '_UNREALIZEDLOSSESACC_DESC_',
  /** Sort on RealizedLossesAcc ascending */
  RealizedlossesaccAsc = '_REALIZEDLOSSESACC_ASC_',
  /** sort on RealizedLossesAcc descending */
  RealizedlossesaccDesc = '_REALIZEDLOSSESACC_DESC_',
  /** Sort on InvoiceRoundingPrecision ascending */
  InvoiceroundingprecisionAsc = '_INVOICEROUNDINGPRECISION_ASC_',
  /** sort on InvoiceRoundingPrecision descending */
  InvoiceroundingprecisionDesc = '_INVOICEROUNDINGPRECISION_DESC_',
  /** Sort on InvoiceRoundingType ascending */
  InvoiceroundingtypeAsc = '_INVOICEROUNDINGTYPE_ASC_',
  /** sort on InvoiceRoundingType descending */
  InvoiceroundingtypeDesc = '_INVOICEROUNDINGTYPE_DESC_',
  /** Sort on InvoiceRoundingTypeOPTION ascending */
  InvoiceroundingtypeoptionAsc = '_INVOICEROUNDINGTYPEOPTION_ASC_',
  /** sort on InvoiceRoundingTypeOPTION descending */
  InvoiceroundingtypeoptionDesc = '_INVOICEROUNDINGTYPEOPTION_DESC_',
  /** Sort on AmountRoundingPrecision ascending */
  AmountroundingprecisionAsc = '_AMOUNTROUNDINGPRECISION_ASC_',
  /** sort on AmountRoundingPrecision descending */
  AmountroundingprecisionDesc = '_AMOUNTROUNDINGPRECISION_DESC_',
  /** Sort on UnitAmountRoundingPrecision ascending */
  UnitamountroundingprecisionAsc = '_UNITAMOUNTROUNDINGPRECISION_ASC_',
  /** sort on UnitAmountRoundingPrecision descending */
  UnitamountroundingprecisionDesc = '_UNITAMOUNTROUNDINGPRECISION_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on AmountDecimalPlaces ascending */
  AmountdecimalplacesAsc = '_AMOUNTDECIMALPLACES_ASC_',
  /** sort on AmountDecimalPlaces descending */
  AmountdecimalplacesDesc = '_AMOUNTDECIMALPLACES_DESC_',
  /** Sort on UnitAmountDecimalPlaces ascending */
  UnitamountdecimalplacesAsc = '_UNITAMOUNTDECIMALPLACES_ASC_',
  /** sort on UnitAmountDecimalPlaces descending */
  UnitamountdecimalplacesDesc = '_UNITAMOUNTDECIMALPLACES_DESC_',
  /** Sort on CustomerFilter ascending */
  CustomerfilterAsc = '_CUSTOMERFILTER_ASC_',
  /** sort on CustomerFilter descending */
  CustomerfilterDesc = '_CUSTOMERFILTER_DESC_',
  /** Sort on VendorFilter ascending */
  VendorfilterAsc = '_VENDORFILTER_ASC_',
  /** sort on VendorFilter descending */
  VendorfilterDesc = '_VENDORFILTER_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on CustLedgEntriesinFilter ascending */
  CustledgentriesinfilterAsc = '_CUSTLEDGENTRIESINFILTER_ASC_',
  /** sort on CustLedgEntriesinFilter descending */
  CustledgentriesinfilterDesc = '_CUSTLEDGENTRIESINFILTER_DESC_',
  /** Sort on CustomerBalance ascending */
  CustomerbalanceAsc = '_CUSTOMERBALANCE_ASC_',
  /** sort on CustomerBalance descending */
  CustomerbalanceDesc = '_CUSTOMERBALANCE_DESC_',
  /** Sort on CustomerOutstandingOrders ascending */
  CustomeroutstandingordersAsc = '_CUSTOMEROUTSTANDINGORDERS_ASC_',
  /** sort on CustomerOutstandingOrders descending */
  CustomeroutstandingordersDesc = '_CUSTOMEROUTSTANDINGORDERS_DESC_',
  /** Sort on CustomerShippedNotInvoiced ascending */
  CustomershippednotinvoicedAsc = '_CUSTOMERSHIPPEDNOTINVOICED_ASC_',
  /** sort on CustomerShippedNotInvoiced descending */
  CustomershippednotinvoicedDesc = '_CUSTOMERSHIPPEDNOTINVOICED_DESC_',
  /** Sort on CustomerBalanceDue ascending */
  CustomerbalancedueAsc = '_CUSTOMERBALANCEDUE_ASC_',
  /** sort on CustomerBalanceDue descending */
  CustomerbalancedueDesc = '_CUSTOMERBALANCEDUE_DESC_',
  /** Sort on VendorLedgEntriesinFilter ascending */
  VendorledgentriesinfilterAsc = '_VENDORLEDGENTRIESINFILTER_ASC_',
  /** sort on VendorLedgEntriesinFilter descending */
  VendorledgentriesinfilterDesc = '_VENDORLEDGENTRIESINFILTER_DESC_',
  /** Sort on VendorBalance ascending */
  VendorbalanceAsc = '_VENDORBALANCE_ASC_',
  /** sort on VendorBalance descending */
  VendorbalanceDesc = '_VENDORBALANCE_DESC_',
  /** Sort on VendorOutstandingOrders ascending */
  VendoroutstandingordersAsc = '_VENDOROUTSTANDINGORDERS_ASC_',
  /** sort on VendorOutstandingOrders descending */
  VendoroutstandingordersDesc = '_VENDOROUTSTANDINGORDERS_DESC_',
  /** Sort on VendorAmtRcdNotInvoiced ascending */
  VendoramtrcdnotinvoicedAsc = '_VENDORAMTRCDNOTINVOICED_ASC_',
  /** sort on VendorAmtRcdNotInvoiced descending */
  VendoramtrcdnotinvoicedDesc = '_VENDORAMTRCDNOTINVOICED_DESC_',
  /** Sort on VendorBalanceDue ascending */
  VendorbalancedueAsc = '_VENDORBALANCEDUE_ASC_',
  /** sort on VendorBalanceDue descending */
  VendorbalancedueDesc = '_VENDORBALANCEDUE_DESC_',
  /** Sort on CustomerBalanceLCY ascending */
  CustomerbalancelcyAsc = '_CUSTOMERBALANCELCY_ASC_',
  /** sort on CustomerBalanceLCY descending */
  CustomerbalancelcyDesc = '_CUSTOMERBALANCELCY_DESC_',
  /** Sort on VendorBalanceLCY ascending */
  VendorbalancelcyAsc = '_VENDORBALANCELCY_ASC_',
  /** sort on VendorBalanceLCY descending */
  VendorbalancelcyDesc = '_VENDORBALANCELCY_DESC_',
  /** Sort on RealizedGLGainsAccount ascending */
  RealizedglgainsaccountAsc = '_REALIZEDGLGAINSACCOUNT_ASC_',
  /** sort on RealizedGLGainsAccount descending */
  RealizedglgainsaccountDesc = '_REALIZEDGLGAINSACCOUNT_DESC_',
  /** Sort on RealizedGLLossesAccount ascending */
  RealizedgllossesaccountAsc = '_REALIZEDGLLOSSESACCOUNT_ASC_',
  /** sort on RealizedGLLossesAccount descending */
  RealizedgllossesaccountDesc = '_REALIZEDGLLOSSESACCOUNT_DESC_',
  /** Sort on ApplnRoundingPrecision ascending */
  ApplnroundingprecisionAsc = '_APPLNROUNDINGPRECISION_ASC_',
  /** sort on ApplnRoundingPrecision descending */
  ApplnroundingprecisionDesc = '_APPLNROUNDINGPRECISION_DESC_',
  /** Sort on EMUCurrency ascending */
  EmucurrencyAsc = '_EMUCURRENCY_ASC_',
  /** sort on EMUCurrency descending */
  EmucurrencyDesc = '_EMUCURRENCY_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on ResidualGainsAccount ascending */
  ResidualgainsaccountAsc = '_RESIDUALGAINSACCOUNT_ASC_',
  /** sort on ResidualGainsAccount descending */
  ResidualgainsaccountDesc = '_RESIDUALGAINSACCOUNT_DESC_',
  /** Sort on ResidualLossesAccount ascending */
  ResiduallossesaccountAsc = '_RESIDUALLOSSESACCOUNT_ASC_',
  /** sort on ResidualLossesAccount descending */
  ResiduallossesaccountDesc = '_RESIDUALLOSSESACCOUNT_DESC_',
  /** Sort on ConvLCYRndgDebitAcc ascending */
  ConvlcyrndgdebitaccAsc = '_CONVLCYRNDGDEBITACC_ASC_',
  /** sort on ConvLCYRndgDebitAcc descending */
  ConvlcyrndgdebitaccDesc = '_CONVLCYRNDGDEBITACC_DESC_',
  /** Sort on ConvLCYRndgCreditAcc ascending */
  ConvlcyrndgcreditaccAsc = '_CONVLCYRNDGCREDITACC_ASC_',
  /** sort on ConvLCYRndgCreditAcc descending */
  ConvlcyrndgcreditaccDesc = '_CONVLCYRNDGCREDITACC_DESC_',
  /** Sort on MaxVATDifferenceAllowed ascending */
  MaxvatdifferenceallowedAsc = '_MAXVATDIFFERENCEALLOWED_ASC_',
  /** sort on MaxVATDifferenceAllowed descending */
  MaxvatdifferenceallowedDesc = '_MAXVATDIFFERENCEALLOWED_DESC_',
  /** Sort on VATRoundingType ascending */
  VatroundingtypeAsc = '_VATROUNDINGTYPE_ASC_',
  /** sort on VATRoundingType descending */
  VatroundingtypeDesc = '_VATROUNDINGTYPE_DESC_',
  /** Sort on VATRoundingTypeOPTION ascending */
  VatroundingtypeoptionAsc = '_VATROUNDINGTYPEOPTION_ASC_',
  /** sort on VATRoundingTypeOPTION descending */
  VatroundingtypeoptionDesc = '_VATROUNDINGTYPEOPTION_DESC_',
  /** Sort on PaymentTolerancePercent ascending */
  PaymenttolerancepercentAsc = '_PAYMENTTOLERANCEPERCENT_ASC_',
  /** sort on PaymentTolerancePercent descending */
  PaymenttolerancepercentDesc = '_PAYMENTTOLERANCEPERCENT_DESC_',
  /** Sort on MaxPaymentToleranceAmount ascending */
  MaxpaymenttoleranceamountAsc = '_MAXPAYMENTTOLERANCEAMOUNT_ASC_',
  /** sort on MaxPaymentToleranceAmount descending */
  MaxpaymenttoleranceamountDesc = '_MAXPAYMENTTOLERANCEAMOUNT_DESC_',
  /** Sort on Symbol ascending */
  SymbolAsc = '_SYMBOL_ASC_',
  /** sort on Symbol descending */
  SymbolDesc = '_SYMBOL_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on PEBNoofForwardAgreement ascending */
  PebnoofforwardagreementAsc = '_PEBNOOFFORWARDAGREEMENT_ASC_',
  /** sort on PEBNoofForwardAgreement descending */
  PebnoofforwardagreementDesc = '_PEBNOOFFORWARDAGREEMENT_DESC_',
  /** Sort on PEBCountryCodeISOAccNo ascending */
  PebcountrycodeisoaccnoAsc = '_PEBCOUNTRYCODEISOACCNO_ASC_',
  /** sort on PEBCountryCodeISOAccNo descending */
  PebcountrycodeisoaccnoDesc = '_PEBCOUNTRYCODEISOACCNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCustomerDomainInput {
  /** Sort on RegistrationNo ascending */
  RegistrationnoAsc = '_REGISTRATIONNO_ASC_',
  /** sort on RegistrationNo descending */
  RegistrationnoDesc = '_REGISTRATIONNO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on AdminUserGlobalId ascending */
  AdminuserglobalidAsc = '_ADMINUSERGLOBALID_ASC_',
  /** sort on AdminUserGlobalId descending */
  AdminuserglobalidDesc = '_ADMINUSERGLOBALID_DESC_',
  /** Sort on EmailDomain ascending */
  EmaildomainAsc = '_EMAILDOMAIN_ASC_',
  /** sort on EmailDomain descending */
  EmaildomainDesc = '_EMAILDOMAIN_DESC_',
  /** Sort on ImageURL ascending */
  ImageurlAsc = '_IMAGEURL_ASC_',
  /** sort on ImageURL descending */
  ImageurlDesc = '_IMAGEURL_DESC_',
  /** Sort on DomainImageBase64 ascending */
  Domainimagebase64Asc = '_DOMAINIMAGEBASE64_ASC_',
  /** sort on DomainImageBase64 descending */
  Domainimagebase64Desc = '_DOMAINIMAGEBASE64_DESC_',
  /** Sort on DomainImageBase64Name ascending */
  Domainimagebase64NameAsc = '_DOMAINIMAGEBASE64NAME_ASC_',
  /** sort on DomainImageBase64Name descending */
  Domainimagebase64NameDesc = '_DOMAINIMAGEBASE64NAME_DESC_',
  /** Sort on DomainMainOrientation ascending */
  DomainmainorientationAsc = '_DOMAINMAINORIENTATION_ASC_',
  /** sort on DomainMainOrientation descending */
  DomainmainorientationDesc = '_DOMAINMAINORIENTATION_DESC_',
  /** Sort on CreatedOn ascending */
  CreatedonAsc = '_CREATEDON_ASC_',
  /** sort on CreatedOn descending */
  CreatedonDesc = '_CREATEDON_DESC_',
  /** Sort on VerifiedOn ascending */
  VerifiedonAsc = '_VERIFIEDON_ASC_',
  /** sort on VerifiedOn descending */
  VerifiedonDesc = '_VERIFIEDON_DESC_',
  /** Sort on VerifiedByUserEmail ascending */
  VerifiedbyuseremailAsc = '_VERIFIEDBYUSEREMAIL_ASC_',
  /** sort on VerifiedByUserEmail descending */
  VerifiedbyuseremailDesc = '_VERIFIEDBYUSEREMAIL_DESC_',
  /** Sort on VerifiedByUserName ascending */
  VerifiedbyusernameAsc = '_VERIFIEDBYUSERNAME_ASC_',
  /** sort on VerifiedByUserName descending */
  VerifiedbyusernameDesc = '_VERIFIEDBYUSERNAME_DESC_',
  /** Sort on TrialStartedOn ascending */
  TrialstartedonAsc = '_TRIALSTARTEDON_ASC_',
  /** sort on TrialStartedOn descending */
  TrialstartedonDesc = '_TRIALSTARTEDON_DESC_',
  /** Sort on TrialEndsOn ascending */
  TrialendsonAsc = '_TRIALENDSON_ASC_',
  /** sort on TrialEndsOn descending */
  TrialendsonDesc = '_TRIALENDSON_DESC_',
  /** Sort on TrialLengthDays ascending */
  TriallengthdaysAsc = '_TRIALLENGTHDAYS_ASC_',
  /** sort on TrialLengthDays descending */
  TriallengthdaysDesc = '_TRIALLENGTHDAYS_DESC_',
  /** Sort on TrailActive ascending */
  TrailactiveAsc = '_TRAILACTIVE_ASC_',
  /** sort on TrailActive descending */
  TrailactiveDesc = '_TRAILACTIVE_DESC_',
  /** Sort on InvoiceAddress ascending */
  InvoiceaddressAsc = '_INVOICEADDRESS_ASC_',
  /** sort on InvoiceAddress descending */
  InvoiceaddressDesc = '_INVOICEADDRESS_DESC_',
  /** Sort on InvoicePostalCode ascending */
  InvoicepostalcodeAsc = '_INVOICEPOSTALCODE_ASC_',
  /** sort on InvoicePostalCode descending */
  InvoicepostalcodeDesc = '_INVOICEPOSTALCODE_DESC_',
  /** Sort on InvoiceCity ascending */
  InvoicecityAsc = '_INVOICECITY_ASC_',
  /** sort on InvoiceCity descending */
  InvoicecityDesc = '_INVOICECITY_DESC_',
  /** Sort on InvoiceNote ascending */
  InvoicenoteAsc = '_INVOICENOTE_ASC_',
  /** sort on InvoiceNote descending */
  InvoicenoteDesc = '_INVOICENOTE_DESC_',
  /** Sort on InvoiceEmail ascending */
  InvoiceemailAsc = '_INVOICEEMAIL_ASC_',
  /** sort on InvoiceEmail descending */
  InvoiceemailDesc = '_INVOICEEMAIL_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCustomerPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on ReceivablesAccount ascending */
  ReceivablesaccountAsc = '_RECEIVABLESACCOUNT_ASC_',
  /** sort on ReceivablesAccount descending */
  ReceivablesaccountDesc = '_RECEIVABLESACCOUNT_DESC_',
  /** Sort on ServiceChargeAcc ascending */
  ServicechargeaccAsc = '_SERVICECHARGEACC_ASC_',
  /** sort on ServiceChargeAcc descending */
  ServicechargeaccDesc = '_SERVICECHARGEACC_DESC_',
  /** Sort on PaymentDiscDebitAcc ascending */
  PaymentdiscdebitaccAsc = '_PAYMENTDISCDEBITACC_ASC_',
  /** sort on PaymentDiscDebitAcc descending */
  PaymentdiscdebitaccDesc = '_PAYMENTDISCDEBITACC_DESC_',
  /** Sort on InvoiceRoundingAccount ascending */
  InvoiceroundingaccountAsc = '_INVOICEROUNDINGACCOUNT_ASC_',
  /** sort on InvoiceRoundingAccount descending */
  InvoiceroundingaccountDesc = '_INVOICEROUNDINGACCOUNT_DESC_',
  /** Sort on AdditionalFeeAccount ascending */
  AdditionalfeeaccountAsc = '_ADDITIONALFEEACCOUNT_ASC_',
  /** sort on AdditionalFeeAccount descending */
  AdditionalfeeaccountDesc = '_ADDITIONALFEEACCOUNT_DESC_',
  /** Sort on InterestAccount ascending */
  InterestaccountAsc = '_INTERESTACCOUNT_ASC_',
  /** sort on InterestAccount descending */
  InterestaccountDesc = '_INTERESTACCOUNT_DESC_',
  /** Sort on DebitCurrApplnRndgAcc ascending */
  DebitcurrapplnrndgaccAsc = '_DEBITCURRAPPLNRNDGACC_ASC_',
  /** sort on DebitCurrApplnRndgAcc descending */
  DebitcurrapplnrndgaccDesc = '_DEBITCURRAPPLNRNDGACC_DESC_',
  /** Sort on CreditCurrApplnRndgAcc ascending */
  CreditcurrapplnrndgaccAsc = '_CREDITCURRAPPLNRNDGACC_ASC_',
  /** sort on CreditCurrApplnRndgAcc descending */
  CreditcurrapplnrndgaccDesc = '_CREDITCURRAPPLNRNDGACC_DESC_',
  /** Sort on DebitRoundingAccount ascending */
  DebitroundingaccountAsc = '_DEBITROUNDINGACCOUNT_ASC_',
  /** sort on DebitRoundingAccount descending */
  DebitroundingaccountDesc = '_DEBITROUNDINGACCOUNT_DESC_',
  /** Sort on CreditRoundingAccount ascending */
  CreditroundingaccountAsc = '_CREDITROUNDINGACCOUNT_ASC_',
  /** sort on CreditRoundingAccount descending */
  CreditroundingaccountDesc = '_CREDITROUNDINGACCOUNT_DESC_',
  /** Sort on PaymentDiscCreditAcc ascending */
  PaymentdisccreditaccAsc = '_PAYMENTDISCCREDITACC_ASC_',
  /** sort on PaymentDiscCreditAcc descending */
  PaymentdisccreditaccDesc = '_PAYMENTDISCCREDITACC_DESC_',
  /** Sort on PaymentToleranceDebitAcc ascending */
  PaymenttolerancedebitaccAsc = '_PAYMENTTOLERANCEDEBITACC_ASC_',
  /** sort on PaymentToleranceDebitAcc descending */
  PaymenttolerancedebitaccDesc = '_PAYMENTTOLERANCEDEBITACC_DESC_',
  /** Sort on PaymentToleranceCreditAcc ascending */
  PaymenttolerancecreditaccAsc = '_PAYMENTTOLERANCECREDITACC_ASC_',
  /** sort on PaymentToleranceCreditAcc descending */
  PaymenttolerancecreditaccDesc = '_PAYMENTTOLERANCECREDITACC_DESC_',
  /** Sort on AddFeeperLineAccount ascending */
  AddfeeperlineaccountAsc = '_ADDFEEPERLINEACCOUNT_ASC_',
  /** sort on AddFeeperLineAccount descending */
  AddfeeperlineaccountDesc = '_ADDFEEPERLINEACCOUNT_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ViewAllAccountsonLookup ascending */
  ViewallaccountsonlookupAsc = '_VIEWALLACCOUNTSONLOOKUP_ASC_',
  /** sort on ViewAllAccountsonLookup descending */
  ViewallaccountsonlookupDesc = '_VIEWALLACCOUNTSONLOOKUP_DESC_',
  /** Sort on EQMDepositReceiveablesAcc ascending */
  EqmdepositreceiveablesaccAsc = '_EQMDEPOSITRECEIVEABLESACC_ASC_',
  /** sort on EQMDepositReceiveablesAcc descending */
  EqmdepositreceiveablesaccDesc = '_EQMDEPOSITRECEIVEABLESACC_DESC_',
  /** Sort on EQMDepositAccount ascending */
  EqmdepositaccountAsc = '_EQMDEPOSITACCOUNT_ASC_',
  /** sort on EQMDepositAccount descending */
  EqmdepositaccountDesc = '_EQMDEPOSITACCOUNT_DESC_',
  /** Sort on EQMPrepaymentReceivableAcc ascending */
  EqmprepaymentreceivableaccAsc = '_EQMPREPAYMENTRECEIVABLEACC_ASC_',
  /** sort on EQMPrepaymentReceivableAcc descending */
  EqmprepaymentreceivableaccDesc = '_EQMPREPAYMENTRECEIVABLEACC_DESC_',
  /** Sort on EQMPrepaymentAccount ascending */
  EqmprepaymentaccountAsc = '_EQMPREPAYMENTACCOUNT_ASC_',
  /** sort on EQMPrepaymentAccount descending */
  EqmprepaymentaccountDesc = '_EQMPREPAYMENTACCOUNT_DESC_',
  /** Sort on EQMBankTransferReceiveables ascending */
  EqmbanktransferreceiveablesAsc = '_EQMBANKTRANSFERRECEIVEABLES_ASC_',
  /** sort on EQMBankTransferReceiveables descending */
  EqmbanktransferreceiveablesDesc = '_EQMBANKTRANSFERRECEIVEABLES_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCustomerProjectInput {
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on ContactNo ascending */
  ContactnoAsc = '_CONTACTNO_ASC_',
  /** sort on ContactNo descending */
  ContactnoDesc = '_CONTACTNO_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on StatusChangedBy ascending */
  StatuschangedbyAsc = '_STATUSCHANGEDBY_ASC_',
  /** sort on StatusChangedBy descending */
  StatuschangedbyDesc = '_STATUSCHANGEDBY_DESC_',
  /** Sort on CustomerProjectDimension ascending */
  CustomerprojectdimensionAsc = '_CUSTOMERPROJECTDIMENSION_ASC_',
  /** sort on CustomerProjectDimension descending */
  CustomerprojectdimensionDesc = '_CUSTOMERPROJECTDIMENSION_DESC_',
  /** Sort on DimensionConstructProject ascending */
  DimensionconstructprojectAsc = '_DIMENSIONCONSTRUCTPROJECT_ASC_',
  /** sort on DimensionConstructProject descending */
  DimensionconstructprojectDesc = '_DIMENSIONCONSTRUCTPROJECT_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on CombineRentalInvoice ascending */
  CombinerentalinvoiceAsc = '_COMBINERENTALINVOICE_ASC_',
  /** sort on CombineRentalInvoice descending */
  CombinerentalinvoiceDesc = '_COMBINERENTALINVOICE_DESC_',
  /** Sort on CombineMethod ascending */
  CombinemethodAsc = '_COMBINEMETHOD_ASC_',
  /** sort on CombineMethod descending */
  CombinemethodDesc = '_COMBINEMETHOD_DESC_',
  /** Sort on CombineInvoiceSubProject ascending */
  CombineinvoicesubprojectAsc = '_COMBINEINVOICESUBPROJECT_ASC_',
  /** sort on CombineInvoiceSubProject descending */
  CombineinvoicesubprojectDesc = '_COMBINEINVOICESUBPROJECT_DESC_',
  /** Sort on SubProjectMandatory ascending */
  SubprojectmandatoryAsc = '_SUBPROJECTMANDATORY_ASC_',
  /** sort on SubProjectMandatory descending */
  SubprojectmandatoryDesc = '_SUBPROJECTMANDATORY_DESC_',
  /** Sort on CustomerRentalPriceGroup ascending */
  CustomerrentalpricegroupAsc = '_CUSTOMERRENTALPRICEGROUP_ASC_',
  /** sort on CustomerRentalPriceGroup descending */
  CustomerrentalpricegroupDesc = '_CUSTOMERRENTALPRICEGROUP_DESC_',
  /** Sort on CustomerRentPriceGroup ascending */
  CustomerrentpricegroupAsc = '_CUSTOMERRENTPRICEGROUP_ASC_',
  /** sort on CustomerRentPriceGroup descending */
  CustomerrentpricegroupDesc = '_CUSTOMERRENTPRICEGROUP_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on CustomerSalesPriceGroup ascending */
  CustomersalespricegroupAsc = '_CUSTOMERSALESPRICEGROUP_ASC_',
  /** sort on CustomerSalesPriceGroup descending */
  CustomersalespricegroupDesc = '_CUSTOMERSALESPRICEGROUP_DESC_',
  /** Sort on CustomerSalesDiscGroup ascending */
  CustomersalesdiscgroupAsc = '_CUSTOMERSALESDISCGROUP_ASC_',
  /** sort on CustomerSalesDiscGroup descending */
  CustomersalesdiscgroupDesc = '_CUSTOMERSALESDISCGROUP_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on InsuranceCode ascending */
  InsurancecodeAsc = '_INSURANCECODE_ASC_',
  /** sort on InsuranceCode descending */
  InsurancecodeDesc = '_INSURANCECODE_DESC_',
  /** Sort on CustomerRentalDiscGroup ascending */
  CustomerrentaldiscgroupAsc = '_CUSTOMERRENTALDISCGROUP_ASC_',
  /** sort on CustomerRentalDiscGroup descending */
  CustomerrentaldiscgroupDesc = '_CUSTOMERRENTALDISCGROUP_DESC_',
  /** Sort on CombineInvperRespCenter ascending */
  CombineinvperrespcenterAsc = '_COMBINEINVPERRESPCENTER_ASC_',
  /** sort on CombineInvperRespCenter descending */
  CombineinvperrespcenterDesc = '_COMBINEINVPERRESPCENTER_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ServiceZoneCode ascending */
  ServicezonecodeAsc = '_SERVICEZONECODE_ASC_',
  /** sort on ServiceZoneCode descending */
  ServicezonecodeDesc = '_SERVICEZONECODE_DESC_',
  /** Sort on Incentive ascending */
  IncentiveAsc = '_INCENTIVE_ASC_',
  /** sort on Incentive descending */
  IncentiveDesc = '_INCENTIVE_DESC_',
  /** Sort on ReturnConfirmationperEMail ascending */
  ReturnconfirmationperemailAsc = '_RETURNCONFIRMATIONPEREMAIL_ASC_',
  /** sort on ReturnConfirmationperEMail descending */
  ReturnconfirmationperemailDesc = '_RETURNCONFIRMATIONPEREMAIL_DESC_',
  /** Sort on Inactive ascending */
  InactiveAsc = '_INACTIVE_ASC_',
  /** sort on Inactive descending */
  InactiveDesc = '_INACTIVE_DESC_',
  /** Sort on ExpectedTurnover ascending */
  ExpectedturnoverAsc = '_EXPECTEDTURNOVER_ASC_',
  /** sort on ExpectedTurnover descending */
  ExpectedturnoverDesc = '_EXPECTEDTURNOVER_DESC_',
  /** Sort on ShiptoInstruction ascending */
  ShiptoinstructionAsc = '_SHIPTOINSTRUCTION_ASC_',
  /** sort on ShiptoInstruction descending */
  ShiptoinstructionDesc = '_SHIPTOINSTRUCTION_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on FromDate ascending */
  FromdateAsc = '_FROMDATE_ASC_',
  /** sort on FromDate descending */
  FromdateDesc = '_FROMDATE_DESC_',
  /** Sort on ToDate ascending */
  TodateAsc = '_TODATE_ASC_',
  /** sort on ToDate descending */
  TodateDesc = '_TODATE_DESC_',
  /** Sort on LockPrices ascending */
  LockpricesAsc = '_LOCKPRICES_ASC_',
  /** sort on LockPrices descending */
  LockpricesDesc = '_LOCKPRICES_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on EquipmentListperEMail ascending */
  EquipmentlistperemailAsc = '_EQUIPMENTLISTPEREMAIL_ASC_',
  /** sort on EquipmentListperEMail descending */
  EquipmentlistperemailDesc = '_EQUIPMENTLISTPEREMAIL_DESC_',
  /** Sort on EmailEquipmentList ascending */
  EmailequipmentlistAsc = '_EMAILEQUIPMENTLIST_ASC_',
  /** sort on EmailEquipmentList descending */
  EmailequipmentlistDesc = '_EMAILEQUIPMENTLIST_DESC_',
  /** Sort on EmailFrequency ascending */
  EmailfrequencyAsc = '_EMAILFREQUENCY_ASC_',
  /** sort on EmailFrequency descending */
  EmailfrequencyDesc = '_EMAILFREQUENCY_DESC_',
  /** Sort on InternalComment ascending */
  InternalcommentAsc = '_INTERNALCOMMENT_ASC_',
  /** sort on InternalComment descending */
  InternalcommentDesc = '_INTERNALCOMMENT_DESC_',
  /** Sort on PopUp ascending */
  PopupAsc = '_POPUP_ASC_',
  /** sort on PopUp descending */
  PopupDesc = '_POPUP_DESC_',
  /** Sort on InactivateNoSaleMail ascending */
  InactivatenosalemailAsc = '_INACTIVATENOSALEMAIL_ASC_',
  /** sort on InactivateNoSaleMail descending */
  InactivatenosalemailDesc = '_INACTIVATENOSALEMAIL_DESC_',
  /** Sort on ContactName ascending */
  ContactnameAsc = '_CONTACTNAME_ASC_',
  /** sort on ContactName descending */
  ContactnameDesc = '_CONTACTNAME_DESC_',
  /** Sort on ContactPhoneNo ascending */
  ContactphonenoAsc = '_CONTACTPHONENO_ASC_',
  /** sort on ContactPhoneNo descending */
  ContactphonenoDesc = '_CONTACTPHONENO_DESC_',
  /** Sort on CustomerProjectRegistrationNo ascending */
  CustomerprojectregistrationnoAsc = '_CUSTOMERPROJECTREGISTRATIONNO_ASC_',
  /** sort on CustomerProjectRegistrationNo descending */
  CustomerprojectregistrationnoDesc = '_CUSTOMERPROJECTREGISTRATIONNO_DESC_',
  /** Sort on Deactivated ascending */
  DeactivatedAsc = '_DEACTIVATED_ASC_',
  /** sort on Deactivated descending */
  DeactivatedDesc = '_DEACTIVATED_DESC_',
  /** Sort on Finished ascending */
  FinishedAsc = '_FINISHED_ASC_',
  /** sort on Finished descending */
  FinishedDesc = '_FINISHED_DESC_',
  /** Sort on LocationManagerContact ascending */
  LocationmanagercontactAsc = '_LOCATIONMANAGERCONTACT_ASC_',
  /** sort on LocationManagerContact descending */
  LocationmanagercontactDesc = '_LOCATIONMANAGERCONTACT_DESC_',
  /** Sort on ProjectManager ascending */
  ProjectmanagerAsc = '_PROJECTMANAGER_ASC_',
  /** sort on ProjectManager descending */
  ProjectmanagerDesc = '_PROJECTMANAGER_DESC_',
  /** Sort on Deliverycontact ascending */
  DeliverycontactAsc = '_DELIVERYCONTACT_ASC_',
  /** sort on Deliverycontact descending */
  DeliverycontactDesc = '_DELIVERYCONTACT_DESC_',
  /** Sort on ProjectManagerEmail ascending */
  ProjectmanageremailAsc = '_PROJECTMANAGEREMAIL_ASC_',
  /** sort on ProjectManagerEmail descending */
  ProjectmanageremailDesc = '_PROJECTMANAGEREMAIL_DESC_',
  /** Sort on DeliveryContactEmail ascending */
  DeliverycontactemailAsc = '_DELIVERYCONTACTEMAIL_ASC_',
  /** sort on DeliveryContactEmail descending */
  DeliverycontactemailDesc = '_DELIVERYCONTACTEMAIL_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on LocationId ascending */
  LocationidAsc = '_LOCATIONID_ASC_',
  /** sort on LocationId descending */
  LocationidDesc = '_LOCATIONID_DESC_',
  /** Sort on ContactGraphId ascending */
  ContactgraphidAsc = '_CONTACTGRAPHID_ASC_',
  /** sort on ContactGraphId descending */
  ContactgraphidDesc = '_CONTACTGRAPHID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on ExternallyCreated ascending */
  ExternallycreatedAsc = '_EXTERNALLYCREATED_ASC_',
  /** sort on ExternallyCreated descending */
  ExternallycreatedDesc = '_EXTERNALLYCREATED_DESC_',
  /** Sort on RentIsActive ascending */
  RentisactiveAsc = '_RENTISACTIVE_ASC_',
  /** sort on RentIsActive descending */
  RentisactiveDesc = '_RENTISACTIVE_DESC_',
  /** Sort on IsPublicRealbridge ascending */
  IspublicrealbridgeAsc = '_ISPUBLICREALBRIDGE_ASC_',
  /** sort on IsPublicRealbridge descending */
  IspublicrealbridgeDesc = '_ISPUBLICREALBRIDGE_DESC_',
  /** Sort on SendToEquipmentLoop ascending */
  SendtoequipmentloopAsc = '_SENDTOEQUIPMENTLOOP_ASC_',
  /** sort on SendToEquipmentLoop descending */
  SendtoequipmentloopDesc = '_SENDTOEQUIPMENTLOOP_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortCustomerSubProjectInput {
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on ContactNo ascending */
  ContactnoAsc = '_CONTACTNO_ASC_',
  /** sort on ContactNo descending */
  ContactnoDesc = '_CONTACTNO_DESC_',
  /** Sort on ContactName ascending */
  ContactnameAsc = '_CONTACTNAME_ASC_',
  /** sort on ContactName descending */
  ContactnameDesc = '_CONTACTNAME_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on SubProjectNo ascending */
  SubprojectnoAsc = '_SUBPROJECTNO_ASC_',
  /** sort on SubProjectNo descending */
  SubprojectnoDesc = '_SUBPROJECTNO_DESC_',
  /** Sort on CustomerProjectDimension ascending */
  CustomerprojectdimensionAsc = '_CUSTOMERPROJECTDIMENSION_ASC_',
  /** sort on CustomerProjectDimension descending */
  CustomerprojectdimensionDesc = '_CUSTOMERPROJECTDIMENSION_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on CombineRentalInvoice ascending */
  CombinerentalinvoiceAsc = '_COMBINERENTALINVOICE_ASC_',
  /** sort on CombineRentalInvoice descending */
  CombinerentalinvoiceDesc = '_COMBINERENTALINVOICE_DESC_',
  /** Sort on CombineMethod ascending */
  CombinemethodAsc = '_COMBINEMETHOD_ASC_',
  /** sort on CombineMethod descending */
  CombinemethodDesc = '_COMBINEMETHOD_DESC_',
  /** Sort on CombineMethodOPTION ascending */
  CombinemethodoptionAsc = '_COMBINEMETHODOPTION_ASC_',
  /** sort on CombineMethodOPTION descending */
  CombinemethodoptionDesc = '_COMBINEMETHODOPTION_DESC_',
  /** Sort on CustomerRentalPriceGroup ascending */
  CustomerrentalpricegroupAsc = '_CUSTOMERRENTALPRICEGROUP_ASC_',
  /** sort on CustomerRentalPriceGroup descending */
  CustomerrentalpricegroupDesc = '_CUSTOMERRENTALPRICEGROUP_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on CustomerSalesPriceGroup ascending */
  CustomersalespricegroupAsc = '_CUSTOMERSALESPRICEGROUP_ASC_',
  /** sort on CustomerSalesPriceGroup descending */
  CustomersalespricegroupDesc = '_CUSTOMERSALESPRICEGROUP_DESC_',
  /** Sort on CustomerSalesDiscGroup ascending */
  CustomersalesdiscgroupAsc = '_CUSTOMERSALESDISCGROUP_ASC_',
  /** sort on CustomerSalesDiscGroup descending */
  CustomersalesdiscgroupDesc = '_CUSTOMERSALESDISCGROUP_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on InsuranceCode ascending */
  InsurancecodeAsc = '_INSURANCECODE_ASC_',
  /** sort on InsuranceCode descending */
  InsurancecodeDesc = '_INSURANCECODE_DESC_',
  /** Sort on CustomerRentalDiscGroup ascending */
  CustomerrentaldiscgroupAsc = '_CUSTOMERRENTALDISCGROUP_ASC_',
  /** sort on CustomerRentalDiscGroup descending */
  CustomerrentaldiscgroupDesc = '_CUSTOMERRENTALDISCGROUP_DESC_',
  /** Sort on CombineInvperRespCenter ascending */
  CombineinvperrespcenterAsc = '_COMBINEINVPERRESPCENTER_ASC_',
  /** sort on CombineInvperRespCenter descending */
  CombineinvperrespcenterDesc = '_COMBINEINVPERRESPCENTER_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ServiceZoneCode ascending */
  ServicezonecodeAsc = '_SERVICEZONECODE_ASC_',
  /** sort on ServiceZoneCode descending */
  ServicezonecodeDesc = '_SERVICEZONECODE_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on LocationId ascending */
  LocationidAsc = '_LOCATIONID_ASC_',
  /** sort on LocationId descending */
  LocationidDesc = '_LOCATIONID_DESC_',
  /** Sort on ContactGraphId ascending */
  ContactgraphidAsc = '_CONTACTGRAPHID_ASC_',
  /** sort on ContactGraphId descending */
  ContactgraphidDesc = '_CONTACTGRAPHID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDeliverytimeInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on MagentoAttribOptionId ascending */
  MagentoattriboptionidAsc = '_MAGENTOATTRIBOPTIONID_ASC_',
  /** sort on MagentoAttribOptionId descending */
  MagentoattriboptionidDesc = '_MAGENTOATTRIBOPTIONID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDevopsProjectInput {
  /** Sort on name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on url ascending */
  UrlAsc = '_URL_ASC_',
  /** sort on url descending */
  UrlDesc = '_URL_DESC_',
  /** Sort on state ascending */
  StateAsc = '_STATE_ASC_',
  /** sort on state descending */
  StateDesc = '_STATE_DESC_',
  /** Sort on revision ascending */
  RevisionAsc = '_REVISION_ASC_',
  /** sort on revision descending */
  RevisionDesc = '_REVISION_DESC_',
  /** Sort on visibility ascending */
  VisibilityAsc = '_VISIBILITY_ASC_',
  /** sort on visibility descending */
  VisibilityDesc = '_VISIBILITY_DESC_',
  /** Sort on lastUpdateTime ascending */
  LastupdatetimeAsc = '_LASTUPDATETIME_ASC_',
  /** sort on lastUpdateTime descending */
  LastupdatetimeDesc = '_LASTUPDATETIME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDevopsTeamInput {
  /** Sort on name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on path ascending */
  PathAsc = '_PATH_ASC_',
  /** sort on path descending */
  PathDesc = '_PATH_DESC_',
  /** Sort on isFolder ascending */
  IsfolderAsc = '_ISFOLDER_ASC_',
  /** sort on isFolder descending */
  IsfolderDesc = '_ISFOLDER_DESC_',
  /** Sort on hasChildren ascending */
  HaschildrenAsc = '_HASCHILDREN_ASC_',
  /** sort on hasChildren descending */
  HaschildrenDesc = '_HASCHILDREN_DESC_',
  /** Sort on children ascending */
  ChildrenAsc = '_CHILDREN_ASC_',
  /** sort on children descending */
  ChildrenDesc = '_CHILDREN_DESC_',
  /** Sort on isPublic ascending */
  IspublicAsc = '_ISPUBLIC_ASC_',
  /** sort on isPublic descending */
  IspublicDesc = '_ISPUBLIC_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDevopsWorkItemInput {
  /** Sort on rev ascending */
  RevAsc = '_REV_ASC_',
  /** sort on rev descending */
  RevDesc = '_REV_DESC_',
  /** Sort on fields ascending */
  FieldsAsc = '_FIELDS_ASC_',
  /** sort on fields descending */
  FieldsDesc = '_FIELDS_DESC_',
  /** Sort on _links ascending */
  LinksAsc = '_LINKS_ASC_',
  /** sort on _links descending */
  LinksDesc = '_LINKS_DESC_',
  /** Sort on url ascending */
  UrlAsc = '_URL_ASC_',
  /** sort on url descending */
  UrlDesc = '_URL_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDimensionInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on CodeCaption ascending */
  CodecaptionAsc = '_CODECAPTION_ASC_',
  /** sort on CodeCaption descending */
  CodecaptionDesc = '_CODECAPTION_DESC_',
  /** Sort on FilterCaption ascending */
  FiltercaptionAsc = '_FILTERCAPTION_ASC_',
  /** sort on FilterCaption descending */
  FiltercaptionDesc = '_FILTERCAPTION_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on ConsolidationCode ascending */
  ConsolidationcodeAsc = '_CONSOLIDATIONCODE_ASC_',
  /** sort on ConsolidationCode descending */
  ConsolidationcodeDesc = '_CONSOLIDATIONCODE_DESC_',
  /** Sort on MaptoICDimensionCode ascending */
  MaptoicdimensioncodeAsc = '_MAPTOICDIMENSIONCODE_ASC_',
  /** sort on MaptoICDimensionCode descending */
  MaptoicdimensioncodeDesc = '_MAPTOICDIMENSIONCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDimensionValueInput {
  /** Sort on DimensionCode ascending */
  DimensioncodeAsc = '_DIMENSIONCODE_ASC_',
  /** sort on DimensionCode descending */
  DimensioncodeDesc = '_DIMENSIONCODE_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on DimensionValueType ascending */
  DimensionvaluetypeAsc = '_DIMENSIONVALUETYPE_ASC_',
  /** sort on DimensionValueType descending */
  DimensionvaluetypeDesc = '_DIMENSIONVALUETYPE_DESC_',
  /** Sort on DimensionValueTypeOPTION ascending */
  DimensionvaluetypeoptionAsc = '_DIMENSIONVALUETYPEOPTION_ASC_',
  /** sort on DimensionValueTypeOPTION descending */
  DimensionvaluetypeoptionDesc = '_DIMENSIONVALUETYPEOPTION_DESC_',
  /** Sort on Totaling ascending */
  TotalingAsc = '_TOTALING_ASC_',
  /** sort on Totaling descending */
  TotalingDesc = '_TOTALING_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on ConsolidationCode ascending */
  ConsolidationcodeAsc = '_CONSOLIDATIONCODE_ASC_',
  /** sort on ConsolidationCode descending */
  ConsolidationcodeDesc = '_CONSOLIDATIONCODE_DESC_',
  /** Sort on Indentation ascending */
  IndentationAsc = '_INDENTATION_ASC_',
  /** sort on Indentation descending */
  IndentationDesc = '_INDENTATION_DESC_',
  /** Sort on GlobalDimensionNo ascending */
  GlobaldimensionnoAsc = '_GLOBALDIMENSIONNO_ASC_',
  /** sort on GlobalDimensionNo descending */
  GlobaldimensionnoDesc = '_GLOBALDIMENSIONNO_DESC_',
  /** Sort on MaptoICDimensionCode ascending */
  MaptoicdimensioncodeAsc = '_MAPTOICDIMENSIONCODE_ASC_',
  /** sort on MaptoICDimensionCode descending */
  MaptoicdimensioncodeDesc = '_MAPTOICDIMENSIONCODE_DESC_',
  /** Sort on MaptoICDimensionValueCode ascending */
  MaptoicdimensionvaluecodeAsc = '_MAPTOICDIMENSIONVALUECODE_ASC_',
  /** sort on MaptoICDimensionValueCode descending */
  MaptoicdimensionvaluecodeDesc = '_MAPTOICDIMENSIONVALUECODE_DESC_',
  /** Sort on DimensionValueID ascending */
  DimensionvalueidAsc = '_DIMENSIONVALUEID_ASC_',
  /** sort on DimensionValueID descending */
  DimensionvalueidDesc = '_DIMENSIONVALUEID_DESC_',
  /** Sort on EQMCostCenterDimension ascending */
  EqmcostcenterdimensionAsc = '_EQMCOSTCENTERDIMENSION_ASC_',
  /** sort on EQMCostCenterDimension descending */
  EqmcostcenterdimensionDesc = '_EQMCOSTCENTERDIMENSION_DESC_',
  /** Sort on EQMObjectDimension ascending */
  EqmobjectdimensionAsc = '_EQMOBJECTDIMENSION_ASC_',
  /** sort on EQMObjectDimension descending */
  EqmobjectdimensionDesc = '_EQMOBJECTDIMENSION_DESC_',
  /** Sort on EQMObjectTypeDimension ascending */
  EqmobjecttypedimensionAsc = '_EQMOBJECTTYPEDIMENSION_ASC_',
  /** sort on EQMObjectTypeDimension descending */
  EqmobjecttypedimensionDesc = '_EQMOBJECTTYPEDIMENSION_DESC_',
  /** Sort on EQMObjectGroupDimension ascending */
  EqmobjectgroupdimensionAsc = '_EQMOBJECTGROUPDIMENSION_ASC_',
  /** sort on EQMObjectGroupDimension descending */
  EqmobjectgroupdimensionDesc = '_EQMOBJECTGROUPDIMENSION_DESC_',
  /** Sort on EQMRelatedSalesCostDim ascending */
  EqmrelatedsalescostdimAsc = '_EQMRELATEDSALESCOSTDIM_ASC_',
  /** sort on EQMRelatedSalesCostDim descending */
  EqmrelatedsalescostdimDesc = '_EQMRELATEDSALESCOSTDIM_DESC_',
  /** Sort on EQMRentalContractDimension ascending */
  EqmrentalcontractdimensionAsc = '_EQMRENTALCONTRACTDIMENSION_ASC_',
  /** sort on EQMRentalContractDimension descending */
  EqmrentalcontractdimensionDesc = '_EQMRENTALCONTRACTDIMENSION_DESC_',
  /** Sort on EQMCustomerDimension ascending */
  EqmcustomerdimensionAsc = '_EQMCUSTOMERDIMENSION_ASC_',
  /** sort on EQMCustomerDimension descending */
  EqmcustomerdimensionDesc = '_EQMCUSTOMERDIMENSION_DESC_',
  /** Sort on EQMCustomerProjectDimension ascending */
  EqmcustomerprojectdimensionAsc = '_EQMCUSTOMERPROJECTDIMENSION_ASC_',
  /** sort on EQMCustomerProjectDimension descending */
  EqmcustomerprojectdimensionDesc = '_EQMCUSTOMERPROJECTDIMENSION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDiscussionPostInput {
  /** Sort on FromEmail ascending */
  FromemailAsc = '_FROMEMAIL_ASC_',
  /** sort on FromEmail descending */
  FromemailDesc = '_FROMEMAIL_DESC_',
  /** Sort on FromName ascending */
  FromnameAsc = '_FROMNAME_ASC_',
  /** sort on FromName descending */
  FromnameDesc = '_FROMNAME_DESC_',
  /** Sort on CreatedOn ascending */
  CreatedonAsc = '_CREATEDON_ASC_',
  /** sort on CreatedOn descending */
  CreatedonDesc = '_CREATEDON_DESC_',
  /** Sort on Text ascending */
  TextAsc = '_TEXT_ASC_',
  /** sort on Text descending */
  TextDesc = '_TEXT_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on Tags ascending */
  TagsAsc = '_TAGS_ASC_',
  /** sort on Tags descending */
  TagsDesc = '_TAGS_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on IsFromCustomer ascending */
  IsfromcustomerAsc = '_ISFROMCUSTOMER_ASC_',
  /** sort on IsFromCustomer descending */
  IsfromcustomerDesc = '_ISFROMCUSTOMER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDocumentClassificationInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDocumentInput {
  /** Sort on _SignInitiatingUserId ascending */
  SigninitiatinguseridAsc = '_SIGNINITIATINGUSERID_ASC_',
  /** sort on _SignInitiatingUserId descending */
  SigninitiatinguseridDesc = '_SIGNINITIATINGUSERID_DESC_',
  /** Sort on SignInitiatingUserName ascending */
  SigninitiatingusernameAsc = '_SIGNINITIATINGUSERNAME_ASC_',
  /** sort on SignInitiatingUserName descending */
  SigninitiatingusernameDesc = '_SIGNINITIATINGUSERNAME_DESC_',
  /** Sort on SignInitiatingUserGlobalId ascending */
  SigninitiatinguserglobalidAsc = '_SIGNINITIATINGUSERGLOBALID_ASC_',
  /** sort on SignInitiatingUserGlobalId descending */
  SigninitiatinguserglobalidDesc = '_SIGNINITIATINGUSERGLOBALID_DESC_',
  /** Sort on SignRequestSentToUserName ascending */
  SignrequestsenttousernameAsc = '_SIGNREQUESTSENTTOUSERNAME_ASC_',
  /** sort on SignRequestSentToUserName descending */
  SignrequestsenttousernameDesc = '_SIGNREQUESTSENTTOUSERNAME_DESC_',
  /** Sort on SignRequestSentToUserEmail ascending */
  SignrequestsenttouseremailAsc = '_SIGNREQUESTSENTTOUSEREMAIL_ASC_',
  /** sort on SignRequestSentToUserEmail descending */
  SignrequestsenttouseremailDesc = '_SIGNREQUESTSENTTOUSEREMAIL_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on GUID ascending */
  GuidAsc = '_GUID_ASC_',
  /** sort on GUID descending */
  GuidDesc = '_GUID_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on RecName ascending */
  RecnameAsc = '_RECNAME_ASC_',
  /** sort on RecName descending */
  RecnameDesc = '_RECNAME_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on DocumentSubType ascending */
  DocumentsubtypeAsc = '_DOCUMENTSUBTYPE_ASC_',
  /** sort on DocumentSubType descending */
  DocumentsubtypeDesc = '_DOCUMENTSUBTYPE_DESC_',
  /** Sort on DocumentSubTypeOPTION ascending */
  DocumentsubtypeoptionAsc = '_DOCUMENTSUBTYPEOPTION_ASC_',
  /** sort on DocumentSubTypeOPTION descending */
  DocumentsubtypeoptionDesc = '_DOCUMENTSUBTYPEOPTION_DESC_',
  /** Sort on Signed ascending */
  SignedAsc = '_SIGNED_ASC_',
  /** sort on Signed descending */
  SignedDesc = '_SIGNED_DESC_',
  /** Sort on SignedByUserGlobalId ascending */
  SignedbyuserglobalidAsc = '_SIGNEDBYUSERGLOBALID_ASC_',
  /** sort on SignedByUserGlobalId descending */
  SignedbyuserglobalidDesc = '_SIGNEDBYUSERGLOBALID_DESC_',
  /** Sort on SignedMethod ascending */
  SignedmethodAsc = '_SIGNEDMETHOD_ASC_',
  /** sort on SignedMethod descending */
  SignedmethodDesc = '_SIGNEDMETHOD_DESC_',
  /** Sort on SignedOn ascending */
  SignedonAsc = '_SIGNEDON_ASC_',
  /** sort on SignedOn descending */
  SignedonDesc = '_SIGNEDON_DESC_',
  /** Sort on OriginalFileName ascending */
  OriginalfilenameAsc = '_ORIGINALFILENAME_ASC_',
  /** sort on OriginalFileName descending */
  OriginalfilenameDesc = '_ORIGINALFILENAME_DESC_',
  /** Sort on SignedFileName ascending */
  SignedfilenameAsc = '_SIGNEDFILENAME_ASC_',
  /** sort on SignedFileName descending */
  SignedfilenameDesc = '_SIGNEDFILENAME_DESC_',
  /** Sort on SignedPrintedName ascending */
  SignedprintednameAsc = '_SIGNEDPRINTEDNAME_ASC_',
  /** sort on SignedPrintedName descending */
  SignedprintednameDesc = '_SIGNEDPRINTEDNAME_DESC_',
  /** Sort on DocumentETag ascending */
  DocumentetagAsc = '_DOCUMENTETAG_ASC_',
  /** sort on DocumentETag descending */
  DocumentetagDesc = '_DOCUMENTETAG_DESC_',
  /** Sort on Base64WriteOnly ascending */
  Base64WriteonlyAsc = '_BASE64WRITEONLY_ASC_',
  /** sort on Base64WriteOnly descending */
  Base64WriteonlyDesc = '_BASE64WRITEONLY_DESC_',
  /** Sort on Classification ascending */
  ClassificationAsc = '_CLASSIFICATION_ASC_',
  /** sort on Classification descending */
  ClassificationDesc = '_CLASSIFICATION_DESC_',
  /** Sort on Created ascending */
  CreatedAsc = '_CREATED_ASC_',
  /** sort on Created descending */
  CreatedDesc = '_CREATED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDocumentRequestInput {
  /** Sort on GUID ascending */
  GuidAsc = '_GUID_ASC_',
  /** sort on GUID descending */
  GuidDesc = '_GUID_DESC_',
  /** Sort on RecName ascending */
  RecnameAsc = '_RECNAME_ASC_',
  /** sort on RecName descending */
  RecnameDesc = '_RECNAME_DESC_',
  /** Sort on TargetID ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetID descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on DocumentSubType ascending */
  DocumentsubtypeAsc = '_DOCUMENTSUBTYPE_ASC_',
  /** sort on DocumentSubType descending */
  DocumentsubtypeDesc = '_DOCUMENTSUBTYPE_DESC_',
  /** Sort on DocumentSubTypeOPTION ascending */
  DocumentsubtypeoptionAsc = '_DOCUMENTSUBTYPEOPTION_ASC_',
  /** sort on DocumentSubTypeOPTION descending */
  DocumentsubtypeoptionDesc = '_DOCUMENTSUBTYPEOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDomainInput {
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ImgURL ascending */
  ImgurlAsc = '_IMGURL_ASC_',
  /** sort on ImgURL descending */
  ImgurlDesc = '_IMGURL_DESC_',
  /** Sort on AppBackgroundImgURL ascending */
  AppbackgroundimgurlAsc = '_APPBACKGROUNDIMGURL_ASC_',
  /** sort on AppBackgroundImgURL descending */
  AppbackgroundimgurlDesc = '_APPBACKGROUNDIMGURL_DESC_',
  /** Sort on AdminUserEmail ascending */
  AdminuseremailAsc = '_ADMINUSEREMAIL_ASC_',
  /** sort on AdminUserEmail descending */
  AdminuseremailDesc = '_ADMINUSEREMAIL_DESC_',
  /** Sort on EmailDomain ascending */
  EmaildomainAsc = '_EMAILDOMAIN_ASC_',
  /** sort on EmailDomain descending */
  EmaildomainDesc = '_EMAILDOMAIN_DESC_',
  /** Sort on IsDemo ascending */
  IsdemoAsc = '_ISDEMO_ASC_',
  /** sort on IsDemo descending */
  IsdemoDesc = '_ISDEMO_DESC_',
  /** Sort on EnableObjectsLimited ascending */
  EnableobjectslimitedAsc = '_ENABLEOBJECTSLIMITED_ASC_',
  /** sort on EnableObjectsLimited descending */
  EnableobjectslimitedDesc = '_ENABLEOBJECTSLIMITED_DESC_',
  /** Sort on EnableParkingSpotsLimited ascending */
  EnableparkingspotslimitedAsc = '_ENABLEPARKINGSPOTSLIMITED_ASC_',
  /** sort on EnableParkingSpotsLimited descending */
  EnableparkingspotslimitedDesc = '_ENABLEPARKINGSPOTSLIMITED_DESC_',
  /** Sort on ImageEtag ascending */
  ImageetagAsc = '_IMAGEETAG_ASC_',
  /** sort on ImageEtag descending */
  ImageetagDesc = '_IMAGEETAG_DESC_',
  /** Sort on ImageDocGlobalId ascending */
  ImagedocglobalidAsc = '_IMAGEDOCGLOBALID_ASC_',
  /** sort on ImageDocGlobalId descending */
  ImagedocglobalidDesc = '_IMAGEDOCGLOBALID_DESC_',
  /** Sort on ImageURL ascending */
  ImageurlAsc = '_IMAGEURL_ASC_',
  /** sort on ImageURL descending */
  ImageurlDesc = '_IMAGEURL_DESC_',
  /** Sort on DomainImageBase64 ascending */
  Domainimagebase64Asc = '_DOMAINIMAGEBASE64_ASC_',
  /** sort on DomainImageBase64 descending */
  Domainimagebase64Desc = '_DOMAINIMAGEBASE64_DESC_',
  /** Sort on DomainImageBase64Name ascending */
  Domainimagebase64NameAsc = '_DOMAINIMAGEBASE64NAME_ASC_',
  /** sort on DomainImageBase64Name descending */
  Domainimagebase64NameDesc = '_DOMAINIMAGEBASE64NAME_DESC_',
  /** Sort on DomainMainOrientation ascending */
  DomainmainorientationAsc = '_DOMAINMAINORIENTATION_ASC_',
  /** sort on DomainMainOrientation descending */
  DomainmainorientationDesc = '_DOMAINMAINORIENTATION_DESC_',
  /** Sort on WebhookURL ascending */
  WebhookurlAsc = '_WEBHOOKURL_ASC_',
  /** sort on WebhookURL descending */
  WebhookurlDesc = '_WEBHOOKURL_DESC_',
  /** Sort on ParentDomainId ascending */
  ParentdomainidAsc = '_PARENTDOMAINID_ASC_',
  /** sort on ParentDomainId descending */
  ParentdomainidDesc = '_PARENTDOMAINID_DESC_',
  /** Sort on AlternativeName ascending */
  AlternativenameAsc = '_ALTERNATIVENAME_ASC_',
  /** sort on AlternativeName descending */
  AlternativenameDesc = '_ALTERNATIVENAME_DESC_',
  /** Sort on FlagImageUrl ascending */
  FlagimageurlAsc = '_FLAGIMAGEURL_ASC_',
  /** sort on FlagImageUrl descending */
  FlagimageurlDesc = '_FLAGIMAGEURL_DESC_',
  /** Sort on VisibleInSafetyRespect ascending */
  VisibleinsafetyrespectAsc = '_VISIBLEINSAFETYRESPECT_ASC_',
  /** sort on VisibleInSafetyRespect descending */
  VisibleinsafetyrespectDesc = '_VISIBLEINSAFETYRESPECT_DESC_',
  /** Sort on ReservationsEnabled ascending */
  ReservationsenabledAsc = '_RESERVATIONSENABLED_ASC_',
  /** sort on ReservationsEnabled descending */
  ReservationsenabledDesc = '_RESERVATIONSENABLED_DESC_',
  /** Sort on ReturnReservationsEnabled ascending */
  ReturnreservationsenabledAsc = '_RETURNRESERVATIONSENABLED_ASC_',
  /** sort on ReturnReservationsEnabled descending */
  ReturnreservationsenabledDesc = '_RETURNRESERVATIONSENABLED_DESC_',
  /** Sort on SendToEquipmentLoop ascending */
  SendtoequipmentloopAsc = '_SENDTOEQUIPMENTLOOP_ASC_',
  /** sort on SendToEquipmentLoop descending */
  SendtoequipmentloopDesc = '_SENDTOEQUIPMENTLOOP_DESC_',
  /** Sort on primaryColors ascending */
  PrimarycolorsAsc = '_PRIMARYCOLORS_ASC_',
  /** sort on primaryColors descending */
  PrimarycolorsDesc = '_PRIMARYCOLORS_DESC_',
  /** Sort on secondaryColors ascending */
  SecondarycolorsAsc = '_SECONDARYCOLORS_ASC_',
  /** sort on secondaryColors descending */
  SecondarycolorsDesc = '_SECONDARYCOLORS_DESC_',
  /** Sort on other ascending */
  OtherAsc = '_OTHER_ASC_',
  /** sort on other descending */
  OtherDesc = '_OTHER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDomainIntegrationInput {
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Url ascending */
  UrlAsc = '_URL_ASC_',
  /** sort on Url descending */
  UrlDesc = '_URL_DESC_',
  /** Sort on Username ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on Username descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on Password ascending */
  PasswordAsc = '_PASSWORD_ASC_',
  /** sort on Password descending */
  PasswordDesc = '_PASSWORD_DESC_',
  /** Sort on AuthType ascending */
  AuthtypeAsc = '_AUTHTYPE_ASC_',
  /** sort on AuthType descending */
  AuthtypeDesc = '_AUTHTYPE_DESC_',
  /** Sort on client_id ascending */
  ClientIdAsc = '_CLIENT_ID_ASC_',
  /** sort on client_id descending */
  ClientIdDesc = '_CLIENT_ID_DESC_',
  /** Sort on client_secret ascending */
  ClientSecretAsc = '_CLIENT_SECRET_ASC_',
  /** sort on client_secret descending */
  ClientSecretDesc = '_CLIENT_SECRET_DESC_',
  /** Sort on grant_type ascending */
  GrantTypeAsc = '_GRANT_TYPE_ASC_',
  /** sort on grant_type descending */
  GrantTypeDesc = '_GRANT_TYPE_DESC_',
  /** Sort on commonAuthority ascending */
  CommonauthorityAsc = '_COMMONAUTHORITY_ASC_',
  /** sort on commonAuthority descending */
  CommonauthorityDesc = '_COMMONAUTHORITY_DESC_',
  /** Sort on tenant ascending */
  TenantAsc = '_TENANT_ASC_',
  /** sort on tenant descending */
  TenantDesc = '_TENANT_DESC_',
  /** Sort on ConnectionType ascending */
  ConnectiontypeAsc = '_CONNECTIONTYPE_ASC_',
  /** sort on ConnectionType descending */
  ConnectiontypeDesc = '_CONNECTIONTYPE_DESC_',
  /** Sort on resource ascending */
  ResourceAsc = '_RESOURCE_ASC_',
  /** sort on resource descending */
  ResourceDesc = '_RESOURCE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on SubType ascending */
  SubtypeAsc = '_SUBTYPE_ASC_',
  /** sort on SubType descending */
  SubtypeDesc = '_SUBTYPE_DESC_',
  /** Sort on OrganizationId ascending */
  OrganizationidAsc = '_ORGANIZATIONID_ASC_',
  /** sort on OrganizationId descending */
  OrganizationidDesc = '_ORGANIZATIONID_DESC_',
  /** Sort on Active ascending */
  ActiveAsc = '_ACTIVE_ASC_',
  /** sort on Active descending */
  ActiveDesc = '_ACTIVE_DESC_',
  /** Sort on MetaDataJson ascending */
  MetadatajsonAsc = '_METADATAJSON_ASC_',
  /** sort on MetaDataJson descending */
  MetadatajsonDesc = '_METADATAJSON_DESC_',
  /** Sort on DisableOrderPolling ascending */
  DisableorderpollingAsc = '_DISABLEORDERPOLLING_ASC_',
  /** sort on DisableOrderPolling descending */
  DisableorderpollingDesc = '_DISABLEORDERPOLLING_DESC_',
  /** Sort on PreviousMagentoOrderId ascending */
  PreviousmagentoorderidAsc = '_PREVIOUSMAGENTOORDERID_ASC_',
  /** sort on PreviousMagentoOrderId descending */
  PreviousmagentoorderidDesc = '_PREVIOUSMAGENTOORDERID_DESC_',
  /** Sort on PreviousDekraPollingDateTime ascending */
  PreviousdekrapollingdatetimeAsc = '_PREVIOUSDEKRAPOLLINGDATETIME_ASC_',
  /** sort on PreviousDekraPollingDateTime descending */
  PreviousdekrapollingdatetimeDesc = '_PREVIOUSDEKRAPOLLINGDATETIME_DESC_',
  /** Sort on SchemaVersion ascending */
  SchemaversionAsc = '_SCHEMAVERSION_ASC_',
  /** sort on SchemaVersion descending */
  SchemaversionDesc = '_SCHEMAVERSION_DESC_',
  /** Sort on Online ascending */
  OnlineAsc = '_ONLINE_ASC_',
  /** sort on Online descending */
  OnlineDesc = '_ONLINE_DESC_',
  /** Sort on AutoSyncUnsentRecords ascending */
  AutosyncunsentrecordsAsc = '_AUTOSYNCUNSENTRECORDS_ASC_',
  /** sort on AutoSyncUnsentRecords descending */
  AutosyncunsentrecordsDesc = '_AUTOSYNCUNSENTRECORDS_DESC_',
  /** Sort on LatestHeartBeat ascending */
  LatestheartbeatAsc = '_LATESTHEARTBEAT_ASC_',
  /** sort on LatestHeartBeat descending */
  LatestheartbeatDesc = '_LATESTHEARTBEAT_DESC_',
  /** Sort on FirstHeartBeat ascending */
  FirstheartbeatAsc = '_FIRSTHEARTBEAT_ASC_',
  /** sort on FirstHeartBeat descending */
  FirstheartbeatDesc = '_FIRSTHEARTBEAT_DESC_',
  /** Sort on HeartBeartIntervalMinutes ascending */
  HeartbeartintervalminutesAsc = '_HEARTBEARTINTERVALMINUTES_ASC_',
  /** sort on HeartBeartIntervalMinutes descending */
  HeartbeartintervalminutesDesc = '_HEARTBEARTINTERVALMINUTES_DESC_',
  /** Sort on NoOfReqsInOutboundQueue ascending */
  NoofreqsinoutboundqueueAsc = '_NOOFREQSINOUTBOUNDQUEUE_ASC_',
  /** sort on NoOfReqsInOutboundQueue descending */
  NoofreqsinoutboundqueueDesc = '_NOOFREQSINOUTBOUNDQUEUE_DESC_',
  /** Sort on NoOfReqsInInboundQueue ascending */
  NoofreqsininboundqueueAsc = '_NOOFREQSININBOUNDQUEUE_ASC_',
  /** sort on NoOfReqsInInboundQueue descending */
  NoofreqsininboundqueueDesc = '_NOOFREQSININBOUNDQUEUE_DESC_',
  /** Sort on NoOfUnprocessableItems ascending */
  NoofunprocessableitemsAsc = '_NOOFUNPROCESSABLEITEMS_ASC_',
  /** sort on NoOfUnprocessableItems descending */
  NoofunprocessableitemsDesc = '_NOOFUNPROCESSABLEITEMS_DESC_',
  /** Sort on HeartbeatMonitoringEnabled ascending */
  HeartbeatmonitoringenabledAsc = '_HEARTBEATMONITORINGENABLED_ASC_',
  /** sort on HeartbeatMonitoringEnabled descending */
  HeartbeatmonitoringenabledDesc = '_HEARTBEATMONITORINGENABLED_DESC_',
  /** Sort on OAuth2_URL ascending */
  Oauth2UrlAsc = '_OAUTH2_URL_ASC_',
  /** sort on OAuth2_URL descending */
  Oauth2UrlDesc = '_OAUTH2_URL_DESC_',
  /** Sort on OAuth2_Grant_Type ascending */
  Oauth2GrantTypeAsc = '_OAUTH2_GRANT_TYPE_ASC_',
  /** sort on OAuth2_Grant_Type descending */
  Oauth2GrantTypeDesc = '_OAUTH2_GRANT_TYPE_DESC_',
  /** Sort on OAuth2_Scope ascending */
  Oauth2ScopeAsc = '_OAUTH2_SCOPE_ASC_',
  /** sort on OAuth2_Scope descending */
  Oauth2ScopeDesc = '_OAUTH2_SCOPE_DESC_',
  /** Sort on OAuth2_Client_Id ascending */
  Oauth2ClientIdAsc = '_OAUTH2_CLIENT_ID_ASC_',
  /** sort on OAuth2_Client_Id descending */
  Oauth2ClientIdDesc = '_OAUTH2_CLIENT_ID_DESC_',
  /** Sort on OAuth2_Client_Secret ascending */
  Oauth2ClientSecretAsc = '_OAUTH2_CLIENT_SECRET_ASC_',
  /** sort on OAuth2_Client_Secret descending */
  Oauth2ClientSecretDesc = '_OAUTH2_CLIENT_SECRET_DESC_',
  /** Sort on OAuth2_Redirect_URL ascending */
  Oauth2RedirectUrlAsc = '_OAUTH2_REDIRECT_URL_ASC_',
  /** sort on OAuth2_Redirect_URL descending */
  Oauth2RedirectUrlDesc = '_OAUTH2_REDIRECT_URL_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortDomainSettingsInput {
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Value ascending */
  ValueAsc = '_VALUE_ASC_',
  /** sort on Value descending */
  ValueDesc = '_VALUE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmBaseCalendarChangeInput {
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on RecurringSystem ascending */
  RecurringsystemAsc = '_RECURRINGSYSTEM_ASC_',
  /** sort on RecurringSystem descending */
  RecurringsystemDesc = '_RECURRINGSYSTEM_DESC_',
  /** Sort on RecurringSystemOPTION ascending */
  RecurringsystemoptionAsc = '_RECURRINGSYSTEMOPTION_ASC_',
  /** sort on RecurringSystemOPTION descending */
  RecurringsystemoptionDesc = '_RECURRINGSYSTEMOPTION_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on Day ascending */
  DayAsc = '_DAY_ASC_',
  /** sort on Day descending */
  DayDesc = '_DAY_DESC_',
  /** Sort on DayOPTION ascending */
  DayoptionAsc = '_DAYOPTION_ASC_',
  /** sort on DayOPTION descending */
  DayoptionDesc = '_DAYOPTION_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Nonworking ascending */
  NonworkingAsc = '_NONWORKING_ASC_',
  /** sort on Nonworking descending */
  NonworkingDesc = '_NONWORKING_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmBaseCalendarInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on CustomizedChangesExist ascending */
  CustomizedchangesexistAsc = '_CUSTOMIZEDCHANGESEXIST_ASC_',
  /** sort on CustomizedChangesExist descending */
  CustomizedchangesexistDesc = '_CUSTOMIZEDCHANGESEXIST_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmCollectionReturnChargesInput {
  /** Sort on ExtDocumentNo ascending */
  ExtdocumentnoAsc = '_EXTDOCUMENTNO_ASC_',
  /** sort on ExtDocumentNo descending */
  ExtdocumentnoDesc = '_EXTDOCUMENTNO_DESC_',
  /** Sort on ExtLineNo ascending */
  ExtlinenoAsc = '_EXTLINENO_ASC_',
  /** sort on ExtLineNo descending */
  ExtlinenoDesc = '_EXTLINENO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Unitprice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on Unitprice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on ReqAction ascending */
  ReqactionAsc = '_REQACTION_ASC_',
  /** sort on ReqAction descending */
  ReqactionDesc = '_REQACTION_DESC_',
  /** Sort on ReqApproved ascending */
  ReqapprovedAsc = '_REQAPPROVED_ASC_',
  /** sort on ReqApproved descending */
  ReqapprovedDesc = '_REQAPPROVED_DESC_',
  /** Sort on ApprovedBy ascending */
  ApprovedbyAsc = '_APPROVEDBY_ASC_',
  /** sort on ApprovedBy descending */
  ApprovedbyDesc = '_APPROVEDBY_DESC_',
  /** Sort on UseatFunctionalTest ascending */
  UseatfunctionaltestAsc = '_USEATFUNCTIONALTEST_ASC_',
  /** sort on UseatFunctionalTest descending */
  UseatfunctionaltestDesc = '_USEATFUNCTIONALTEST_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on FunctionalTestHeaderNo ascending */
  FunctionaltestheadernoAsc = '_FUNCTIONALTESTHEADERNO_ASC_',
  /** sort on FunctionalTestHeaderNo descending */
  FunctionaltestheadernoDesc = '_FUNCTIONALTESTHEADERNO_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on NBAESMotherMarkedForReturn ascending */
  NbaesmothermarkedforreturnAsc = '_NBAESMOTHERMARKEDFORRETURN_ASC_',
  /** sort on NBAESMotherMarkedForReturn descending */
  NbaesmothermarkedforreturnDesc = '_NBAESMOTHERMARKEDFORRETURN_DESC_',
  /** Sort on NBAESObjectNo ascending */
  NbaesobjectnoAsc = '_NBAESOBJECTNO_ASC_',
  /** sort on NBAESObjectNo descending */
  NbaesobjectnoDesc = '_NBAESOBJECTNO_DESC_',
  /** Sort on NBAESObjectDescription ascending */
  NbaesobjectdescriptionAsc = '_NBAESOBJECTDESCRIPTION_ASC_',
  /** sort on NBAESObjectDescription descending */
  NbaesobjectdescriptionDesc = '_NBAESOBJECTDESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmCommentLineInput {
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on TableLineNo ascending */
  TablelinenoAsc = '_TABLELINENO_ASC_',
  /** sort on TableLineNo descending */
  TablelinenoDesc = '_TABLELINENO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on TableSubtype ascending */
  TablesubtypeAsc = '_TABLESUBTYPE_ASC_',
  /** sort on TableSubtype descending */
  TablesubtypeDesc = '_TABLESUBTYPE_DESC_',
  /** Sort on TableSubtypeOPTION ascending */
  TablesubtypeoptionAsc = '_TABLESUBTYPEOPTION_ASC_',
  /** sort on TableSubtypeOPTION descending */
  TablesubtypeoptionDesc = '_TABLESUBTYPEOPTION_DESC_',
  /** Sort on TableName ascending */
  TablenameAsc = '_TABLENAME_ASC_',
  /** sort on TableName descending */
  TablenameDesc = '_TABLENAME_DESC_',
  /** Sort on TableNameOPTION ascending */
  TablenameoptionAsc = '_TABLENAMEOPTION_ASC_',
  /** sort on TableNameOPTION descending */
  TablenameoptionDesc = '_TABLENAMEOPTION_DESC_',
  /** Sort on LinkedtoObjectNo ascending */
  LinkedtoobjectnoAsc = '_LINKEDTOOBJECTNO_ASC_',
  /** sort on LinkedtoObjectNo descending */
  LinkedtoobjectnoDesc = '_LINKEDTOOBJECTNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmCustomizedCalendarChangeInput {
  /** Sort on SourceType ascending */
  SourcetypeAsc = '_SOURCETYPE_ASC_',
  /** sort on SourceType descending */
  SourcetypeDesc = '_SOURCETYPE_DESC_',
  /** Sort on SourceTypeOPTION ascending */
  SourcetypeoptionAsc = '_SOURCETYPEOPTION_ASC_',
  /** sort on SourceTypeOPTION descending */
  SourcetypeoptionDesc = '_SOURCETYPEOPTION_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on AdditionalSourceCode ascending */
  AdditionalsourcecodeAsc = '_ADDITIONALSOURCECODE_ASC_',
  /** sort on AdditionalSourceCode descending */
  AdditionalsourcecodeDesc = '_ADDITIONALSOURCECODE_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on RecurringSystem ascending */
  RecurringsystemAsc = '_RECURRINGSYSTEM_ASC_',
  /** sort on RecurringSystem descending */
  RecurringsystemDesc = '_RECURRINGSYSTEM_DESC_',
  /** Sort on RecurringSystemOPTION ascending */
  RecurringsystemoptionAsc = '_RECURRINGSYSTEMOPTION_ASC_',
  /** sort on RecurringSystemOPTION descending */
  RecurringsystemoptionDesc = '_RECURRINGSYSTEMOPTION_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on Day ascending */
  DayAsc = '_DAY_ASC_',
  /** sort on Day descending */
  DayDesc = '_DAY_DESC_',
  /** Sort on DayOPTION ascending */
  DayoptionAsc = '_DAYOPTION_ASC_',
  /** sort on DayOPTION descending */
  DayoptionDesc = '_DAYOPTION_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Nonworking ascending */
  NonworkingAsc = '_NONWORKING_ASC_',
  /** sort on Nonworking descending */
  NonworkingDesc = '_NONWORKING_DESC_',
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmFncTestReturnChargesInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Unitprice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on Unitprice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on ReqAction ascending */
  ReqactionAsc = '_REQACTION_ASC_',
  /** sort on ReqAction descending */
  ReqactionDesc = '_REQACTION_DESC_',
  /** Sort on ReqApproved ascending */
  ReqapprovedAsc = '_REQAPPROVED_ASC_',
  /** sort on ReqApproved descending */
  ReqapprovedDesc = '_REQAPPROVED_DESC_',
  /** Sort on ApprovedBy ascending */
  ApprovedbyAsc = '_APPROVEDBY_ASC_',
  /** sort on ApprovedBy descending */
  ApprovedbyDesc = '_APPROVEDBY_DESC_',
  /** Sort on UseatFunctionalTest ascending */
  UseatfunctionaltestAsc = '_USEATFUNCTIONALTEST_ASC_',
  /** sort on UseatFunctionalTest descending */
  UseatfunctionaltestDesc = '_USEATFUNCTIONALTEST_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on TransferredToContract ascending */
  TransferredtocontractAsc = '_TRANSFERREDTOCONTRACT_ASC_',
  /** sort on TransferredToContract descending */
  TransferredtocontractDesc = '_TRANSFERREDTOCONTRACT_DESC_',
  /** Sort on TransferredBy ascending */
  TransferredbyAsc = '_TRANSFERREDBY_ASC_',
  /** sort on TransferredBy descending */
  TransferredbyDesc = '_TRANSFERREDBY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmLocCostCenterCombInput {
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on CostCenterCode ascending */
  CostcentercodeAsc = '_COSTCENTERCODE_ASC_',
  /** sort on CostCenterCode descending */
  CostcentercodeDesc = '_COSTCENTERCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmManufacturerInput {
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmManufacturerModelInput {
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on ModelCode ascending */
  ModelcodeAsc = '_MODELCODE_ASC_',
  /** sort on ModelCode descending */
  ModelcodeDesc = '_MODELCODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on CounterReporting ascending */
  CounterreportingAsc = '_COUNTERREPORTING_ASC_',
  /** sort on CounterReporting descending */
  CounterreportingDesc = '_COUNTERREPORTING_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on ItemDescription ascending */
  ItemdescriptionAsc = '_ITEMDESCRIPTION_ASC_',
  /** sort on ItemDescription descending */
  ItemdescriptionDesc = '_ITEMDESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Capacity ascending */
  CapacityAsc = '_CAPACITY_ASC_',
  /** sort on Capacity descending */
  CapacityDesc = '_CAPACITY_DESC_',
  /** Sort on CapacityMeasure ascending */
  CapacitymeasureAsc = '_CAPACITYMEASURE_ASC_',
  /** sort on CapacityMeasure descending */
  CapacitymeasureDesc = '_CAPACITYMEASURE_DESC_',
  /** Sort on Effect ascending */
  EffectAsc = '_EFFECT_ASC_',
  /** sort on Effect descending */
  EffectDesc = '_EFFECT_DESC_',
  /** Sort on EffectMeasure ascending */
  EffectmeasureAsc = '_EFFECTMEASURE_ASC_',
  /** sort on EffectMeasure descending */
  EffectmeasureDesc = '_EFFECTMEASURE_DESC_',
  /** Sort on Length ascending */
  LengthAsc = '_LENGTH_ASC_',
  /** sort on Length descending */
  LengthDesc = '_LENGTH_DESC_',
  /** Sort on LengthMeasure ascending */
  LengthmeasureAsc = '_LENGTHMEASURE_ASC_',
  /** sort on LengthMeasure descending */
  LengthmeasureDesc = '_LENGTHMEASURE_DESC_',
  /** Sort on Width ascending */
  WidthAsc = '_WIDTH_ASC_',
  /** sort on Width descending */
  WidthDesc = '_WIDTH_DESC_',
  /** Sort on WidthMeasure ascending */
  WidthmeasureAsc = '_WIDTHMEASURE_ASC_',
  /** sort on WidthMeasure descending */
  WidthmeasureDesc = '_WIDTHMEASURE_DESC_',
  /** Sort on Height ascending */
  HeightAsc = '_HEIGHT_ASC_',
  /** sort on Height descending */
  HeightDesc = '_HEIGHT_DESC_',
  /** Sort on HeightMeasure ascending */
  HeightmeasureAsc = '_HEIGHTMEASURE_ASC_',
  /** sort on HeightMeasure descending */
  HeightmeasureDesc = '_HEIGHTMEASURE_DESC_',
  /** Sort on Weight ascending */
  WeightAsc = '_WEIGHT_ASC_',
  /** sort on Weight descending */
  WeightDesc = '_WEIGHT_DESC_',
  /** Sort on WeightMeasure ascending */
  WeightmeasureAsc = '_WEIGHTMEASURE_ASC_',
  /** sort on WeightMeasure descending */
  WeightmeasureDesc = '_WEIGHTMEASURE_DESC_',
  /** Sort on CylinderVolume ascending */
  CylindervolumeAsc = '_CYLINDERVOLUME_ASC_',
  /** sort on CylinderVolume descending */
  CylindervolumeDesc = '_CYLINDERVOLUME_DESC_',
  /** Sort on CylinderMeasure ascending */
  CylindermeasureAsc = '_CYLINDERMEASURE_ASC_',
  /** sort on CylinderMeasure descending */
  CylindermeasureDesc = '_CYLINDERMEASURE_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectTypeDescription ascending */
  ObjecttypedescriptionAsc = '_OBJECTTYPEDESCRIPTION_ASC_',
  /** sort on ObjectTypeDescription descending */
  ObjecttypedescriptionDesc = '_OBJECTTYPEDESCRIPTION_DESC_',
  /** Sort on AttachmentNo ascending */
  AttachmentnoAsc = '_ATTACHMENTNO_ASC_',
  /** sort on AttachmentNo descending */
  AttachmentnoDesc = '_ATTACHMENTNO_DESC_',
  /** Sort on AttachmentNoSeries ascending */
  AttachmentnoseriesAsc = '_ATTACHMENTNOSERIES_ASC_',
  /** sort on AttachmentNoSeries descending */
  AttachmentnoseriesDesc = '_ATTACHMENTNOSERIES_DESC_',
  /** Sort on NoofObjects ascending */
  NoofobjectsAsc = '_NOOFOBJECTS_ASC_',
  /** sort on NoofObjects descending */
  NoofobjectsDesc = '_NOOFOBJECTS_DESC_',
  /** Sort on ManufacturerId ascending */
  ManufactureridAsc = '_MANUFACTURERID_ASC_',
  /** sort on ManufacturerId descending */
  ManufactureridDesc = '_MANUFACTURERID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on SRALink ascending */
  SralinkAsc = '_SRALINK_ASC_',
  /** sort on SRALink descending */
  SralinkDesc = '_SRALINK_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectCardFlowInput {
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on UserSecurityID ascending */
  UsersecurityidAsc = '_USERSECURITYID_ASC_',
  /** sort on UserSecurityID descending */
  UsersecurityidDesc = '_USERSECURITYID_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on DateFilter2 ascending */
  Datefilter2Asc = '_DATEFILTER2_ASC_',
  /** sort on DateFilter2 descending */
  Datefilter2Desc = '_DATEFILTER2_DESC_',
  /** Sort on ServiceItemNo ascending */
  ServiceitemnoAsc = '_SERVICEITEMNO_ASC_',
  /** sort on ServiceItemNo descending */
  ServiceitemnoDesc = '_SERVICEITEMNO_DESC_',
  /** Sort on LinkedtoObjectNo ascending */
  LinkedtoobjectnoAsc = '_LINKEDTOOBJECTNO_ASC_',
  /** sort on LinkedtoObjectNo descending */
  LinkedtoobjectnoDesc = '_LINKEDTOOBJECTNO_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowCostofSale ascending */
  FlowcostofsaleAsc = '_FLOWCOSTOFSALE_ASC_',
  /** sort on FlowCostofSale descending */
  FlowcostofsaleDesc = '_FLOWCOSTOFSALE_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowComponentCost ascending */
  FlowcomponentcostAsc = '_FLOWCOMPONENTCOST_ASC_',
  /** sort on FlowComponentCost descending */
  FlowcomponentcostDesc = '_FLOWCOMPONENTCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowPurchaseAddCost ascending */
  FlowpurchaseaddcostAsc = '_FLOWPURCHASEADDCOST_ASC_',
  /** sort on FlowPurchaseAddCost descending */
  FlowpurchaseaddcostDesc = '_FLOWPURCHASEADDCOST_DESC_',
  /** Sort on FlowBookValue ascending */
  FlowbookvalueAsc = '_FLOWBOOKVALUE_ASC_',
  /** sort on FlowBookValue descending */
  FlowbookvalueDesc = '_FLOWBOOKVALUE_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowAppreciation ascending */
  FlowappreciationAsc = '_FLOWAPPRECIATION_ASC_',
  /** sort on FlowAppreciation descending */
  FlowappreciationDesc = '_FLOWAPPRECIATION_DESC_',
  /** Sort on FlowWriteDown ascending */
  FlowwritedownAsc = '_FLOWWRITEDOWN_ASC_',
  /** sort on FlowWriteDown descending */
  FlowwritedownDesc = '_FLOWWRITEDOWN_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowAcquisitionCost ascending */
  FlowacquisitioncostAsc = '_FLOWACQUISITIONCOST_ASC_',
  /** sort on FlowAcquisitionCost descending */
  FlowacquisitioncostDesc = '_FLOWACQUISITIONCOST_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on Invoiceddays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on Invoiceddays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on FlowLinkedOtherCost ascending */
  FlowlinkedothercostAsc = '_FLOWLINKEDOTHERCOST_ASC_',
  /** sort on FlowLinkedOtherCost descending */
  FlowlinkedothercostDesc = '_FLOWLINKEDOTHERCOST_DESC_',
  /** Sort on FlowLinkedLeasingCost ascending */
  FlowlinkedleasingcostAsc = '_FLOWLINKEDLEASINGCOST_ASC_',
  /** sort on FlowLinkedLeasingCost descending */
  FlowlinkedleasingcostDesc = '_FLOWLINKEDLEASINGCOST_DESC_',
  /** Sort on FlowLinkedRentalIncome ascending */
  FlowlinkedrentalincomeAsc = '_FLOWLINKEDRENTALINCOME_ASC_',
  /** sort on FlowLinkedRentalIncome descending */
  FlowlinkedrentalincomeDesc = '_FLOWLINKEDRENTALINCOME_DESC_',
  /** Sort on FlowLinkedServiceCost ascending */
  FlowlinkedservicecostAsc = '_FLOWLINKEDSERVICECOST_ASC_',
  /** sort on FlowLinkedServiceCost descending */
  FlowlinkedservicecostDesc = '_FLOWLINKEDSERVICECOST_DESC_',
  /** Sort on FlowLinkedGuaranteeCost ascending */
  FlowlinkedguaranteecostAsc = '_FLOWLINKEDGUARANTEECOST_ASC_',
  /** sort on FlowLinkedGuaranteeCost descending */
  FlowlinkedguaranteecostDesc = '_FLOWLINKEDGUARANTEECOST_DESC_',
  /** Sort on FlowLinkedIntRentalCost ascending */
  FlowlinkedintrentalcostAsc = '_FLOWLINKEDINTRENTALCOST_ASC_',
  /** sort on FlowLinkedIntRentalCost descending */
  FlowlinkedintrentalcostDesc = '_FLOWLINKEDINTRENTALCOST_DESC_',
  /** Sort on FlowLinkedHireCost ascending */
  FlowlinkedhirecostAsc = '_FLOWLINKEDHIRECOST_ASC_',
  /** sort on FlowLinkedHireCost descending */
  FlowlinkedhirecostDesc = '_FLOWLINKEDHIRECOST_DESC_',
  /** Sort on FlowLinkedHireDiscount ascending */
  FlowlinkedhirediscountAsc = '_FLOWLINKEDHIREDISCOUNT_ASC_',
  /** sort on FlowLinkedHireDiscount descending */
  FlowlinkedhirediscountDesc = '_FLOWLINKEDHIREDISCOUNT_DESC_',
  /** Sort on FlowLinkedServiceAgrCost ascending */
  FlowlinkedserviceagrcostAsc = '_FLOWLINKEDSERVICEAGRCOST_ASC_',
  /** sort on FlowLinkedServiceAgrCost descending */
  FlowlinkedserviceagrcostDesc = '_FLOWLINKEDSERVICEAGRCOST_DESC_',
  /** Sort on FlowLinkedDepreciation ascending */
  FlowlinkeddepreciationAsc = '_FLOWLINKEDDEPRECIATION_ASC_',
  /** sort on FlowLinkedDepreciation descending */
  FlowlinkeddepreciationDesc = '_FLOWLINKEDDEPRECIATION_DESC_',
  /** Sort on FlowLinkedUppreciation ascending */
  FlowlinkeduppreciationAsc = '_FLOWLINKEDUPPRECIATION_ASC_',
  /** sort on FlowLinkedUppreciation descending */
  FlowlinkeduppreciationDesc = '_FLOWLINKEDUPPRECIATION_DESC_',
  /** Sort on FlowLinkedDownpreciation ascending */
  FlowlinkeddownpreciationAsc = '_FLOWLINKEDDOWNPRECIATION_ASC_',
  /** sort on FlowLinkedDownpreciation descending */
  FlowlinkeddownpreciationDesc = '_FLOWLINKEDDOWNPRECIATION_DESC_',
  /** Sort on FlowLinkedServiceIncome ascending */
  FlowlinkedserviceincomeAsc = '_FLOWLINKEDSERVICEINCOME_ASC_',
  /** sort on FlowLinkedServiceIncome descending */
  FlowlinkedserviceincomeDesc = '_FLOWLINKEDSERVICEINCOME_DESC_',
  /** Sort on FlowLinkedSrvAgrIncome ascending */
  FlowlinkedsrvagrincomeAsc = '_FLOWLINKEDSRVAGRINCOME_ASC_',
  /** sort on FlowLinkedSrvAgrIncome descending */
  FlowlinkedsrvagrincomeDesc = '_FLOWLINKEDSRVAGRINCOME_DESC_',
  /** Sort on FlowLinkedIntRentalIncome ascending */
  FlowlinkedintrentalincomeAsc = '_FLOWLINKEDINTRENTALINCOME_ASC_',
  /** sort on FlowLinkedIntRentalIncome descending */
  FlowlinkedintrentalincomeDesc = '_FLOWLINKEDINTRENTALINCOME_DESC_',
  /** Sort on FlowLinkedOtherIncome ascending */
  FlowlinkedotherincomeAsc = '_FLOWLINKEDOTHERINCOME_ASC_',
  /** sort on FlowLinkedOtherIncome descending */
  FlowlinkedotherincomeDesc = '_FLOWLINKEDOTHERINCOME_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectComponentsInput {
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on LevelofAspiration ascending */
  LevelofaspirationAsc = '_LEVELOFASPIRATION_ASC_',
  /** sort on LevelofAspiration descending */
  LevelofaspirationDesc = '_LEVELOFASPIRATION_DESC_',
  /** Sort on LevelofAspirationOPTION ascending */
  LevelofaspirationoptionAsc = '_LEVELOFASPIRATIONOPTION_ASC_',
  /** sort on LevelofAspirationOPTION descending */
  LevelofaspirationoptionDesc = '_LEVELOFASPIRATIONOPTION_DESC_',
  /** Sort on PurchaseNo ascending */
  PurchasenoAsc = '_PURCHASENO_ASC_',
  /** sort on PurchaseNo descending */
  PurchasenoDesc = '_PURCHASENO_DESC_',
  /** Sort on FromService ascending */
  FromserviceAsc = '_FROMSERVICE_ASC_',
  /** sort on FromService descending */
  FromserviceDesc = '_FROMSERVICE_DESC_',
  /** Sort on Ordered ascending */
  OrderedAsc = '_ORDERED_ASC_',
  /** sort on Ordered descending */
  OrderedDesc = '_ORDERED_DESC_',
  /** Sort on FatherObjectSold ascending */
  FatherobjectsoldAsc = '_FATHEROBJECTSOLD_ASC_',
  /** sort on FatherObjectSold descending */
  FatherobjectsoldDesc = '_FATHEROBJECTSOLD_DESC_',
  /** Sort on Typeold ascending */
  TypeoldAsc = '_TYPEOLD_ASC_',
  /** sort on Typeold descending */
  TypeoldDesc = '_TYPEOLD_DESC_',
  /** Sort on TypeoldOPTION ascending */
  TypeoldoptionAsc = '_TYPEOLDOPTION_ASC_',
  /** sort on TypeoldOPTION descending */
  TypeoldoptionDesc = '_TYPEOLDOPTION_DESC_',
  /** Sort on LineCost ascending */
  LinecostAsc = '_LINECOST_ASC_',
  /** sort on LineCost descending */
  LinecostDesc = '_LINECOST_DESC_',
  /** Sort on Assembled ascending */
  AssembledAsc = '_ASSEMBLED_ASC_',
  /** sort on Assembled descending */
  AssembledDesc = '_ASSEMBLED_DESC_',
  /** Sort on NonBillable ascending */
  NonbillableAsc = '_NONBILLABLE_ASC_',
  /** sort on NonBillable descending */
  NonbillableDesc = '_NONBILLABLE_DESC_',
  /** Sort on PostReclass ascending */
  PostreclassAsc = '_POSTRECLASS_ASC_',
  /** sort on PostReclass descending */
  PostreclassDesc = '_POSTRECLASS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectCountHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on DocumentStatus ascending */
  DocumentstatusAsc = '_DOCUMENTSTATUS_ASC_',
  /** sort on DocumentStatus descending */
  DocumentstatusDesc = '_DOCUMENTSTATUS_DESC_',
  /** Sort on DocumentStatusOPTION ascending */
  DocumentstatusoptionAsc = '_DOCUMENTSTATUSOPTION_ASC_',
  /** sort on DocumentStatusOPTION descending */
  DocumentstatusoptionDesc = '_DOCUMENTSTATUSOPTION_DESC_',
  /** Sort on DateCounted ascending */
  DatecountedAsc = '_DATECOUNTED_ASC_',
  /** sort on DateCounted descending */
  DatecountedDesc = '_DATECOUNTED_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectCountLineInput {
  /** Sort on CountNo ascending */
  CountnoAsc = '_COUNTNO_ASC_',
  /** sort on CountNo descending */
  CountnoDesc = '_COUNTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on ObjectTypeNo ascending */
  ObjecttypenoAsc = '_OBJECTTYPENO_ASC_',
  /** sort on ObjectTypeNo descending */
  ObjecttypenoDesc = '_OBJECTTYPENO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on DateCounted ascending */
  DatecountedAsc = '_DATECOUNTED_ASC_',
  /** sort on DateCounted descending */
  DatecountedDesc = '_DATECOUNTED_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on DocumentStatus ascending */
  DocumentstatusAsc = '_DOCUMENTSTATUS_ASC_',
  /** sort on DocumentStatus descending */
  DocumentstatusDesc = '_DOCUMENTSTATUS_DESC_',
  /** Sort on DocumentStatusOPTION ascending */
  DocumentstatusoptionAsc = '_DOCUMENTSTATUSOPTION_ASC_',
  /** sort on DocumentStatusOPTION descending */
  DocumentstatusoptionDesc = '_DOCUMENTSTATUSOPTION_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on Selected ascending */
  SelectedAsc = '_SELECTED_ASC_',
  /** sort on Selected descending */
  SelectedDesc = '_SELECTED_DESC_',
  /** Sort on Corrected ascending */
  CorrectedAsc = '_CORRECTED_ASC_',
  /** sort on Corrected descending */
  CorrectedDesc = '_CORRECTED_DESC_',
  /** Sort on CurrentLocationCode ascending */
  CurrentlocationcodeAsc = '_CURRENTLOCATIONCODE_ASC_',
  /** sort on CurrentLocationCode descending */
  CurrentlocationcodeDesc = '_CURRENTLOCATIONCODE_DESC_',
  /** Sort on RentalStatus ascending */
  RentalstatusAsc = '_RENTALSTATUS_ASC_',
  /** sort on RentalStatus descending */
  RentalstatusDesc = '_RENTALSTATUS_DESC_',
  /** Sort on RentalStatusOPTION ascending */
  RentalstatusoptionAsc = '_RENTALSTATUSOPTION_ASC_',
  /** sort on RentalStatusOPTION descending */
  RentalstatusoptionDesc = '_RENTALSTATUSOPTION_DESC_',
  /** Sort on RentalContractNo ascending */
  RentalcontractnoAsc = '_RENTALCONTRACTNO_ASC_',
  /** sort on RentalContractNo descending */
  RentalcontractnoDesc = '_RENTALCONTRACTNO_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on LineMessage ascending */
  LinemessageAsc = '_LINEMESSAGE_ASC_',
  /** sort on LineMessage descending */
  LinemessageDesc = '_LINEMESSAGE_DESC_',
  /** Sort on CountedObjectNo ascending */
  CountedobjectnoAsc = '_COUNTEDOBJECTNO_ASC_',
  /** sort on CountedObjectNo descending */
  CountedobjectnoDesc = '_COUNTEDOBJECTNO_DESC_',
  /** Sort on MemoText ascending */
  MemotextAsc = '_MEMOTEXT_ASC_',
  /** sort on MemoText descending */
  MemotextDesc = '_MEMOTEXT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectGroupCardInput {
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpIntRent ascending */
  GenprodpostinggrpintrentAsc = '_GENPRODPOSTINGGRPINTRENT_ASC_',
  /** sort on GenProdPostingGrpIntRent descending */
  GenprodpostinggrpintrentDesc = '_GENPRODPOSTINGGRPINTRENT_DESC_',
  /** Sort on GenProdPostingGrpExtRent ascending */
  GenprodpostinggrpextrentAsc = '_GENPRODPOSTINGGRPEXTRENT_ASC_',
  /** sort on GenProdPostingGrpExtRent descending */
  GenprodpostinggrpextrentDesc = '_GENPRODPOSTINGGRPEXTRENT_DESC_',
  /** Sort on ReRentPostingGroup ascending */
  RerentpostinggroupAsc = '_RERENTPOSTINGGROUP_ASC_',
  /** sort on ReRentPostingGroup descending */
  RerentpostinggroupDesc = '_RERENTPOSTINGGROUP_DESC_',
  /** Sort on NoofObjects ascending */
  NoofobjectsAsc = '_NOOFOBJECTS_ASC_',
  /** sort on NoofObjects descending */
  NoofobjectsDesc = '_NOOFOBJECTS_DESC_',
  /** Sort on NoofObjectType ascending */
  NoofobjecttypeAsc = '_NOOFOBJECTTYPE_ASC_',
  /** sort on NoofObjectType descending */
  NoofobjecttypeDesc = '_NOOFOBJECTTYPE_DESC_',
  /** Sort on ObjectNos ascending */
  ObjectnosAsc = '_OBJECTNOS_ASC_',
  /** sort on ObjectNos descending */
  ObjectnosDesc = '_OBJECTNOS_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on FAClassCode ascending */
  FaclasscodeAsc = '_FACLASSCODE_ASC_',
  /** sort on FAClassCode descending */
  FaclasscodeDesc = '_FACLASSCODE_DESC_',
  /** Sort on FASubclassCode ascending */
  FasubclasscodeAsc = '_FASUBCLASSCODE_ASC_',
  /** sort on FASubclassCode descending */
  FasubclasscodeDesc = '_FASUBCLASSCODE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepreciationMethod ascending */
  DepreciationmethodAsc = '_DEPRECIATIONMETHOD_ASC_',
  /** sort on DepreciationMethod descending */
  DepreciationmethodDesc = '_DEPRECIATIONMETHOD_DESC_',
  /** Sort on DepreciationMethodOPTION ascending */
  DepreciationmethodoptionAsc = '_DEPRECIATIONMETHODOPTION_ASC_',
  /** sort on DepreciationMethodOPTION descending */
  DepreciationmethodoptionDesc = '_DEPRECIATIONMETHODOPTION_DESC_',
  /** Sort on FAPostingGroup ascending */
  FapostinggroupAsc = '_FAPOSTINGGROUP_ASC_',
  /** sort on FAPostingGroup descending */
  FapostinggroupDesc = '_FAPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpHire ascending */
  GenprodpostinggrphireAsc = '_GENPRODPOSTINGGRPHIRE_ASC_',
  /** sort on GenProdPostingGrpHire descending */
  GenprodpostinggrphireDesc = '_GENPRODPOSTINGGRPHIRE_DESC_',
  /** Sort on GenProdPostingGrpLeasing ascending */
  GenprodpostinggrpleasingAsc = '_GENPRODPOSTINGGRPLEASING_ASC_',
  /** sort on GenProdPostingGrpLeasing descending */
  GenprodpostinggrpleasingDesc = '_GENPRODPOSTINGGRPLEASING_DESC_',
  /** Sort on RentalSplitPostingGroup ascending */
  RentalsplitpostinggroupAsc = '_RENTALSPLITPOSTINGGROUP_ASC_',
  /** sort on RentalSplitPostingGroup descending */
  RentalsplitpostinggroupDesc = '_RENTALSPLITPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpWriteUp ascending */
  GenprodpostinggrpwriteupAsc = '_GENPRODPOSTINGGRPWRITEUP_ASC_',
  /** sort on GenProdPostingGrpWriteUp descending */
  GenprodpostinggrpwriteupDesc = '_GENPRODPOSTINGGRPWRITEUP_DESC_',
  /** Sort on NoofDepreciationYears ascending */
  NoofdepreciationyearsAsc = '_NOOFDEPRECIATIONYEARS_ASC_',
  /** sort on NoofDepreciationYears descending */
  NoofdepreciationyearsDesc = '_NOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on NoofDepreciationMonths ascending */
  NoofdepreciationmonthsAsc = '_NOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on NoofDepreciationMonths descending */
  NoofdepreciationmonthsDesc = '_NOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on ServiceItemGroup ascending */
  ServiceitemgroupAsc = '_SERVICEITEMGROUP_ASC_',
  /** sort on ServiceItemGroup descending */
  ServiceitemgroupDesc = '_SERVICEITEMGROUP_DESC_',
  /** Sort on CreditonEarlyOffRent ascending */
  CreditonearlyoffrentAsc = '_CREDITONEARLYOFFRENT_ASC_',
  /** sort on CreditonEarlyOffRent descending */
  CreditonearlyoffrentDesc = '_CREDITONEARLYOFFRENT_DESC_',
  /** Sort on StdRentalChargeDays ascending */
  StdrentalchargedaysAsc = '_STDRENTALCHARGEDAYS_ASC_',
  /** sort on StdRentalChargeDays descending */
  StdrentalchargedaysDesc = '_STDRENTALCHARGEDAYS_DESC_',
  /** Sort on StdRentalChargeDaysOPTION ascending */
  StdrentalchargedaysoptionAsc = '_STDRENTALCHARGEDAYSOPTION_ASC_',
  /** sort on StdRentalChargeDaysOPTION descending */
  StdrentalchargedaysoptionDesc = '_STDRENTALCHARGEDAYSOPTION_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on FixedAssetSetupGroup ascending */
  FixedassetsetupgroupAsc = '_FIXEDASSETSETUPGROUP_ASC_',
  /** sort on FixedAssetSetupGroup descending */
  FixedassetsetupgroupDesc = '_FIXEDASSETSETUPGROUP_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on ObjectGroupNoConcern ascending */
  ObjectgroupnoconcernAsc = '_OBJECTGROUPNOCONCERN_ASC_',
  /** sort on ObjectGroupNoConcern descending */
  ObjectgroupnoconcernDesc = '_OBJECTGROUPNOCONCERN_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Description3 ascending */
  Description3Asc = '_DESCRIPTION3_ASC_',
  /** sort on Description3 descending */
  Description3Desc = '_DESCRIPTION3_DESC_',
  /** Sort on Description4 ascending */
  Description4Asc = '_DESCRIPTION4_ASC_',
  /** sort on Description4 descending */
  Description4Desc = '_DESCRIPTION4_DESC_',
  /** Sort on StraightLine ascending */
  StraightlineAsc = '_STRAIGHTLINE_ASC_',
  /** sort on StraightLine descending */
  StraightlineDesc = '_STRAIGHTLINE_DESC_',
  /** Sort on WarrantyDuration ascending */
  WarrantydurationAsc = '_WARRANTYDURATION_ASC_',
  /** sort on WarrantyDuration descending */
  WarrantydurationDesc = '_WARRANTYDURATION_DESC_',
  /** Sort on DecliningBalance ascending */
  DecliningbalanceAsc = '_DECLININGBALANCE_ASC_',
  /** sort on DecliningBalance descending */
  DecliningbalanceDesc = '_DECLININGBALANCE_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on TaxDepreciationMethod ascending */
  TaxdepreciationmethodAsc = '_TAXDEPRECIATIONMETHOD_ASC_',
  /** sort on TaxDepreciationMethod descending */
  TaxdepreciationmethodDesc = '_TAXDEPRECIATIONMETHOD_DESC_',
  /** Sort on TaxDepreciationMethodOPTION ascending */
  TaxdepreciationmethodoptionAsc = '_TAXDEPRECIATIONMETHODOPTION_ASC_',
  /** sort on TaxDepreciationMethodOPTION descending */
  TaxdepreciationmethodoptionDesc = '_TAXDEPRECIATIONMETHODOPTION_DESC_',
  /** Sort on TaxFAPostingGroup ascending */
  TaxfapostinggroupAsc = '_TAXFAPOSTINGGROUP_ASC_',
  /** sort on TaxFAPostingGroup descending */
  TaxfapostinggroupDesc = '_TAXFAPOSTINGGROUP_DESC_',
  /** Sort on TaxNoofDepreciationYears ascending */
  TaxnoofdepreciationyearsAsc = '_TAXNOOFDEPRECIATIONYEARS_ASC_',
  /** sort on TaxNoofDepreciationYears descending */
  TaxnoofdepreciationyearsDesc = '_TAXNOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on TaxNoofDepreciationMonths ascending */
  TaxnoofdepreciationmonthsAsc = '_TAXNOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on TaxNoofDepreciationMonths descending */
  TaxnoofdepreciationmonthsDesc = '_TAXNOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on TaxStraightLine ascending */
  TaxstraightlineAsc = '_TAXSTRAIGHTLINE_ASC_',
  /** sort on TaxStraightLine descending */
  TaxstraightlineDesc = '_TAXSTRAIGHTLINE_DESC_',
  /** Sort on TaxDecliningBalance ascending */
  TaxdecliningbalanceAsc = '_TAXDECLININGBALANCE_ASC_',
  /** sort on TaxDecliningBalance descending */
  TaxdecliningbalanceDesc = '_TAXDECLININGBALANCE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on DateFilter2 ascending */
  Datefilter2Asc = '_DATEFILTER2_ASC_',
  /** sort on DateFilter2 descending */
  Datefilter2Desc = '_DATEFILTER2_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on InvoicedDays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on InvoicedDays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ImgUrlSmall ascending */
  ImgurlsmallAsc = '_IMGURLSMALL_ASC_',
  /** sort on ImgUrlSmall descending */
  ImgurlsmallDesc = '_IMGURLSMALL_DESC_',
  /** Sort on ImgUrlLarge ascending */
  ImgurllargeAsc = '_IMGURLLARGE_ASC_',
  /** sort on ImgUrlLarge descending */
  ImgurllargeDesc = '_IMGURLLARGE_DESC_',
  /** Sort on ObjectGroupFamily ascending */
  ObjectgroupfamilyAsc = '_OBJECTGROUPFAMILY_ASC_',
  /** sort on ObjectGroupFamily descending */
  ObjectgroupfamilyDesc = '_OBJECTGROUPFAMILY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectGroupFlowInput {
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on UserSecurityID ascending */
  UsersecurityidAsc = '_USERSECURITYID_ASC_',
  /** sort on UserSecurityID descending */
  UsersecurityidDesc = '_USERSECURITYID_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowPurchaseAddCost ascending */
  FlowpurchaseaddcostAsc = '_FLOWPURCHASEADDCOST_ASC_',
  /** sort on FlowPurchaseAddCost descending */
  FlowpurchaseaddcostDesc = '_FLOWPURCHASEADDCOST_DESC_',
  /** Sort on FlowBookValue ascending */
  FlowbookvalueAsc = '_FLOWBOOKVALUE_ASC_',
  /** sort on FlowBookValue descending */
  FlowbookvalueDesc = '_FLOWBOOKVALUE_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowAcquisitionCost ascending */
  FlowacquisitioncostAsc = '_FLOWACQUISITIONCOST_ASC_',
  /** sort on FlowAcquisitionCost descending */
  FlowacquisitioncostDesc = '_FLOWACQUISITIONCOST_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on InvoicedDays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on InvoicedDays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectServiceIntervalInput {
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ServiceTypeCode ascending */
  ServicetypecodeAsc = '_SERVICETYPECODE_ASC_',
  /** sort on ServiceTypeCode descending */
  ServicetypecodeDesc = '_SERVICETYPECODE_DESC_',
  /** Sort on ServiceInitiator ascending */
  ServiceinitiatorAsc = '_SERVICEINITIATOR_ASC_',
  /** sort on ServiceInitiator descending */
  ServiceinitiatorDesc = '_SERVICEINITIATOR_DESC_',
  /** Sort on ServiceInitiatorOPTION ascending */
  ServiceinitiatoroptionAsc = '_SERVICEINITIATOROPTION_ASC_',
  /** sort on ServiceInitiatorOPTION descending */
  ServiceinitiatoroptionDesc = '_SERVICEINITIATOROPTION_DESC_',
  /** Sort on ServiceCounter ascending */
  ServicecounterAsc = '_SERVICECOUNTER_ASC_',
  /** sort on ServiceCounter descending */
  ServicecounterDesc = '_SERVICECOUNTER_DESC_',
  /** Sort on ServiceDate ascending */
  ServicedateAsc = '_SERVICEDATE_ASC_',
  /** sort on ServiceDate descending */
  ServicedateDesc = '_SERVICEDATE_DESC_',
  /** Sort on ServiceDurationDays ascending */
  ServicedurationdaysAsc = '_SERVICEDURATIONDAYS_ASC_',
  /** sort on ServiceDurationDays descending */
  ServicedurationdaysDesc = '_SERVICEDURATIONDAYS_DESC_',
  /** Sort on AssociatedServicePack ascending */
  AssociatedservicepackAsc = '_ASSOCIATEDSERVICEPACK_ASC_',
  /** sort on AssociatedServicePack descending */
  AssociatedservicepackDesc = '_ASSOCIATEDSERVICEPACK_DESC_',
  /** Sort on CustomerDebit ascending */
  CustomerdebitAsc = '_CUSTOMERDEBIT_ASC_',
  /** sort on CustomerDebit descending */
  CustomerdebitDesc = '_CUSTOMERDEBIT_DESC_',
  /** Sort on DateCompleted ascending */
  DatecompletedAsc = '_DATECOMPLETED_ASC_',
  /** sort on DateCompleted descending */
  DatecompletedDesc = '_DATECOMPLETED_DESC_',
  /** Sort on TransferedtoOrder ascending */
  TransferedtoorderAsc = '_TRANSFEREDTOORDER_ASC_',
  /** sort on TransferedtoOrder descending */
  TransferedtoorderDesc = '_TRANSFEREDTOORDER_DESC_',
  /** Sort on TransferedtoWSOrder ascending */
  TransferedtowsorderAsc = '_TRANSFEREDTOWSORDER_ASC_',
  /** sort on TransferedtoWSOrder descending */
  TransferedtowsorderDesc = '_TRANSFEREDTOWSORDER_DESC_',
  /** Sort on TransferedtoWSPurchase ascending */
  TransferedtowspurchaseAsc = '_TRANSFEREDTOWSPURCHASE_ASC_',
  /** sort on TransferedtoWSPurchase descending */
  TransferedtowspurchaseDesc = '_TRANSFEREDTOWSPURCHASE_DESC_',
  /** Sort on TransferedtoEQMService ascending */
  TransferedtoeqmserviceAsc = '_TRANSFEREDTOEQMSERVICE_ASC_',
  /** sort on TransferedtoEQMService descending */
  TransferedtoeqmserviceDesc = '_TRANSFEREDTOEQMSERVICE_DESC_',
  /** Sort on TransferredtoWorkOrder ascending */
  TransferredtoworkorderAsc = '_TRANSFERREDTOWORKORDER_ASC_',
  /** sort on TransferredtoWorkOrder descending */
  TransferredtoworkorderDesc = '_TRANSFERREDTOWORKORDER_DESC_',
  /** Sort on ServiceMargin ascending */
  ServicemarginAsc = '_SERVICEMARGIN_ASC_',
  /** sort on ServiceMargin descending */
  ServicemarginDesc = '_SERVICEMARGIN_DESC_',
  /** Sort on ServiceInitiator1 ascending */
  Serviceinitiator1Asc = '_SERVICEINITIATOR1_ASC_',
  /** sort on ServiceInitiator1 descending */
  Serviceinitiator1Desc = '_SERVICEINITIATOR1_DESC_',
  /** Sort on ServiceInitiator1OPTION ascending */
  Serviceinitiator1OptionAsc = '_SERVICEINITIATOR1OPTION_ASC_',
  /** sort on ServiceInitiator1OPTION descending */
  Serviceinitiator1OptionDesc = '_SERVICEINITIATOR1OPTION_DESC_',
  /** Sort on Repetitive ascending */
  RepetitiveAsc = '_REPETITIVE_ASC_',
  /** sort on Repetitive descending */
  RepetitiveDesc = '_REPETITIVE_DESC_',
  /** Sort on Interval ascending */
  IntervalAsc = '_INTERVAL_ASC_',
  /** sort on Interval descending */
  IntervalDesc = '_INTERVAL_DESC_',
  /** Sort on DateInterval ascending */
  DateintervalAsc = '_DATEINTERVAL_ASC_',
  /** sort on DateInterval descending */
  DateintervalDesc = '_DATEINTERVAL_DESC_',
  /** Sort on ServiceCounterMargin ascending */
  ServicecountermarginAsc = '_SERVICECOUNTERMARGIN_ASC_',
  /** sort on ServiceCounterMargin descending */
  ServicecountermarginDesc = '_SERVICECOUNTERMARGIN_DESC_',
  /** Sort on ServiceDateMargin ascending */
  ServicedatemarginAsc = '_SERVICEDATEMARGIN_ASC_',
  /** sort on ServiceDateMargin descending */
  ServicedatemarginDesc = '_SERVICEDATEMARGIN_DESC_',
  /** Sort on ServiceInitiator2 ascending */
  Serviceinitiator2Asc = '_SERVICEINITIATOR2_ASC_',
  /** sort on ServiceInitiator2 descending */
  Serviceinitiator2Desc = '_SERVICEINITIATOR2_DESC_',
  /** Sort on ServiceInitiator2OPTION ascending */
  Serviceinitiator2OptionAsc = '_SERVICEINITIATOR2OPTION_ASC_',
  /** sort on ServiceInitiator2OPTION descending */
  Serviceinitiator2OptionDesc = '_SERVICEINITIATOR2OPTION_DESC_',
  /** Sort on ServiceCounter2 ascending */
  Servicecounter2Asc = '_SERVICECOUNTER2_ASC_',
  /** sort on ServiceCounter2 descending */
  Servicecounter2Desc = '_SERVICECOUNTER2_DESC_',
  /** Sort on ServiceCounterMargin2 ascending */
  Servicecountermargin2Asc = '_SERVICECOUNTERMARGIN2_ASC_',
  /** sort on ServiceCounterMargin2 descending */
  Servicecountermargin2Desc = '_SERVICECOUNTERMARGIN2_DESC_',
  /** Sort on Interval2 ascending */
  Interval2Asc = '_INTERVAL2_ASC_',
  /** sort on Interval2 descending */
  Interval2Desc = '_INTERVAL2_DESC_',
  /** Sort on RepairCode ascending */
  RepaircodeAsc = '_REPAIRCODE_ASC_',
  /** sort on RepairCode descending */
  RepaircodeDesc = '_REPAIRCODE_DESC_',
  /** Sort on PostedDocumentType ascending */
  PosteddocumenttypeAsc = '_POSTEDDOCUMENTTYPE_ASC_',
  /** sort on PostedDocumentType descending */
  PosteddocumenttypeDesc = '_POSTEDDOCUMENTTYPE_DESC_',
  /** Sort on PostedDocumentTypeOPTION ascending */
  PosteddocumenttypeoptionAsc = '_POSTEDDOCUMENTTYPEOPTION_ASC_',
  /** sort on PostedDocumentTypeOPTION descending */
  PosteddocumenttypeoptionDesc = '_POSTEDDOCUMENTTYPEOPTION_DESC_',
  /** Sort on PostedDocumentNo ascending */
  PosteddocumentnoAsc = '_POSTEDDOCUMENTNO_ASC_',
  /** sort on PostedDocumentNo descending */
  PosteddocumentnoDesc = '_POSTEDDOCUMENTNO_DESC_',
  /** Sort on ServiceDateMarginBlocked ascending */
  ServicedatemarginblockedAsc = '_SERVICEDATEMARGINBLOCKED_ASC_',
  /** sort on ServiceDateMarginBlocked descending */
  ServicedatemarginblockedDesc = '_SERVICEDATEMARGINBLOCKED_DESC_',
  /** Sort on ControlTypeCode ascending */
  ControltypecodeAsc = '_CONTROLTYPECODE_ASC_',
  /** sort on ControlTypeCode descending */
  ControltypecodeDesc = '_CONTROLTYPECODE_DESC_',
  /** Sort on Certification ascending */
  CertificationAsc = '_CERTIFICATION_ASC_',
  /** sort on Certification descending */
  CertificationDesc = '_CERTIFICATION_DESC_',
  /** Sort on ObjectId ascending */
  ObjectidAsc = '_OBJECTID_ASC_',
  /** sort on ObjectId descending */
  ObjectidDesc = '_OBJECTID_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on ObjectTypeId ascending */
  ObjecttypeidAsc = '_OBJECTTYPEID_ASC_',
  /** sort on ObjectTypeId descending */
  ObjecttypeidDesc = '_OBJECTTYPEID_DESC_',
  /** Sort on ManufacturerId ascending */
  ManufactureridAsc = '_MANUFACTURERID_ASC_',
  /** sort on ManufacturerId descending */
  ManufactureridDesc = '_MANUFACTURERID_DESC_',
  /** Sort on ManufacturerModelId ascending */
  ManufacturermodelidAsc = '_MANUFACTURERMODELID_ASC_',
  /** sort on ManufacturerModelId descending */
  ManufacturermodelidDesc = '_MANUFACTURERMODELID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on ObjectGroupId ascending */
  ObjectgroupidAsc = '_OBJECTGROUPID_ASC_',
  /** sort on ObjectGroupId descending */
  ObjectgroupidDesc = '_OBJECTGROUPID_DESC_',
  /** Sort on ServiceCode ascending */
  ServicecodeAsc = '_SERVICECODE_ASC_',
  /** sort on ServiceCode descending */
  ServicecodeDesc = '_SERVICECODE_DESC_',
  /** Sort on MarginDate ascending */
  MargindateAsc = '_MARGINDATE_ASC_',
  /** sort on MarginDate descending */
  MargindateDesc = '_MARGINDATE_DESC_',
  /** Sort on ObjectDescription ascending */
  ObjectdescriptionAsc = '_OBJECTDESCRIPTION_ASC_',
  /** sort on ObjectDescription descending */
  ObjectdescriptionDesc = '_OBJECTDESCRIPTION_DESC_',
  /** Sort on ObjectRentalStatus ascending */
  ObjectrentalstatusAsc = '_OBJECTRENTALSTATUS_ASC_',
  /** sort on ObjectRentalStatus descending */
  ObjectrentalstatusDesc = '_OBJECTRENTALSTATUS_DESC_',
  /** Sort on ObjectRentalStatusOPTION ascending */
  ObjectrentalstatusoptionAsc = '_OBJECTRENTALSTATUSOPTION_ASC_',
  /** sort on ObjectRentalStatusOPTION descending */
  ObjectrentalstatusoptionDesc = '_OBJECTRENTALSTATUSOPTION_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on ActionType ascending */
  ActiontypeAsc = '_ACTIONTYPE_ASC_',
  /** sort on ActionType descending */
  ActiontypeDesc = '_ACTIONTYPE_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on DekraInspectionResult ascending */
  DekrainspectionresultAsc = '_DEKRAINSPECTIONRESULT_ASC_',
  /** sort on DekraInspectionResult descending */
  DekrainspectionresultDesc = '_DEKRAINSPECTIONRESULT_DESC_',
  /** Sort on DekraInspectionResultOPTION ascending */
  DekrainspectionresultoptionAsc = '_DEKRAINSPECTIONRESULTOPTION_ASC_',
  /** sort on DekraInspectionResultOPTION descending */
  DekrainspectionresultoptionDesc = '_DEKRAINSPECTIONRESULTOPTION_DESC_',
  /** Sort on DekraInspection ascending */
  DekrainspectionAsc = '_DEKRAINSPECTION_ASC_',
  /** sort on DekraInspection descending */
  DekrainspectionDesc = '_DEKRAINSPECTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectSrvIntervalAggInput {
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ServiceTypeCode ascending */
  ServicetypecodeAsc = '_SERVICETYPECODE_ASC_',
  /** sort on ServiceTypeCode descending */
  ServicetypecodeDesc = '_SERVICETYPECODE_DESC_',
  /** Sort on ServiceInitiator ascending */
  ServiceinitiatorAsc = '_SERVICEINITIATOR_ASC_',
  /** sort on ServiceInitiator descending */
  ServiceinitiatorDesc = '_SERVICEINITIATOR_DESC_',
  /** Sort on ServiceInitiatorOPTION ascending */
  ServiceinitiatoroptionAsc = '_SERVICEINITIATOROPTION_ASC_',
  /** sort on ServiceInitiatorOPTION descending */
  ServiceinitiatoroptionDesc = '_SERVICEINITIATOROPTION_DESC_',
  /** Sort on ServiceCounter ascending */
  ServicecounterAsc = '_SERVICECOUNTER_ASC_',
  /** sort on ServiceCounter descending */
  ServicecounterDesc = '_SERVICECOUNTER_DESC_',
  /** Sort on ServiceDate ascending */
  ServicedateAsc = '_SERVICEDATE_ASC_',
  /** sort on ServiceDate descending */
  ServicedateDesc = '_SERVICEDATE_DESC_',
  /** Sort on ServiceDurationDays ascending */
  ServicedurationdaysAsc = '_SERVICEDURATIONDAYS_ASC_',
  /** sort on ServiceDurationDays descending */
  ServicedurationdaysDesc = '_SERVICEDURATIONDAYS_DESC_',
  /** Sort on AssociatedServicePack ascending */
  AssociatedservicepackAsc = '_ASSOCIATEDSERVICEPACK_ASC_',
  /** sort on AssociatedServicePack descending */
  AssociatedservicepackDesc = '_ASSOCIATEDSERVICEPACK_DESC_',
  /** Sort on CustomerDebit ascending */
  CustomerdebitAsc = '_CUSTOMERDEBIT_ASC_',
  /** sort on CustomerDebit descending */
  CustomerdebitDesc = '_CUSTOMERDEBIT_DESC_',
  /** Sort on DateCompleted ascending */
  DatecompletedAsc = '_DATECOMPLETED_ASC_',
  /** sort on DateCompleted descending */
  DatecompletedDesc = '_DATECOMPLETED_DESC_',
  /** Sort on TransferedtoOrder ascending */
  TransferedtoorderAsc = '_TRANSFEREDTOORDER_ASC_',
  /** sort on TransferedtoOrder descending */
  TransferedtoorderDesc = '_TRANSFEREDTOORDER_DESC_',
  /** Sort on TransferedtoWSOrder ascending */
  TransferedtowsorderAsc = '_TRANSFEREDTOWSORDER_ASC_',
  /** sort on TransferedtoWSOrder descending */
  TransferedtowsorderDesc = '_TRANSFEREDTOWSORDER_DESC_',
  /** Sort on TransferedtoWSPurchase ascending */
  TransferedtowspurchaseAsc = '_TRANSFEREDTOWSPURCHASE_ASC_',
  /** sort on TransferedtoWSPurchase descending */
  TransferedtowspurchaseDesc = '_TRANSFEREDTOWSPURCHASE_DESC_',
  /** Sort on TransferedtoEQMService ascending */
  TransferedtoeqmserviceAsc = '_TRANSFEREDTOEQMSERVICE_ASC_',
  /** sort on TransferedtoEQMService descending */
  TransferedtoeqmserviceDesc = '_TRANSFEREDTOEQMSERVICE_DESC_',
  /** Sort on TransferredtoWorkOrder ascending */
  TransferredtoworkorderAsc = '_TRANSFERREDTOWORKORDER_ASC_',
  /** sort on TransferredtoWorkOrder descending */
  TransferredtoworkorderDesc = '_TRANSFERREDTOWORKORDER_DESC_',
  /** Sort on ServiceMargin ascending */
  ServicemarginAsc = '_SERVICEMARGIN_ASC_',
  /** sort on ServiceMargin descending */
  ServicemarginDesc = '_SERVICEMARGIN_DESC_',
  /** Sort on Repetitive ascending */
  RepetitiveAsc = '_REPETITIVE_ASC_',
  /** sort on Repetitive descending */
  RepetitiveDesc = '_REPETITIVE_DESC_',
  /** Sort on Interval ascending */
  IntervalAsc = '_INTERVAL_ASC_',
  /** sort on Interval descending */
  IntervalDesc = '_INTERVAL_DESC_',
  /** Sort on DateInterval ascending */
  DateintervalAsc = '_DATEINTERVAL_ASC_',
  /** sort on DateInterval descending */
  DateintervalDesc = '_DATEINTERVAL_DESC_',
  /** Sort on ServiceCounterMargin ascending */
  ServicecountermarginAsc = '_SERVICECOUNTERMARGIN_ASC_',
  /** sort on ServiceCounterMargin descending */
  ServicecountermarginDesc = '_SERVICECOUNTERMARGIN_DESC_',
  /** Sort on ServiceDateMargin ascending */
  ServicedatemarginAsc = '_SERVICEDATEMARGIN_ASC_',
  /** sort on ServiceDateMargin descending */
  ServicedatemarginDesc = '_SERVICEDATEMARGIN_DESC_',
  /** Sort on ServiceInitiator2 ascending */
  Serviceinitiator2Asc = '_SERVICEINITIATOR2_ASC_',
  /** sort on ServiceInitiator2 descending */
  Serviceinitiator2Desc = '_SERVICEINITIATOR2_DESC_',
  /** Sort on ServiceInitiator2OPTION ascending */
  Serviceinitiator2OptionAsc = '_SERVICEINITIATOR2OPTION_ASC_',
  /** sort on ServiceInitiator2OPTION descending */
  Serviceinitiator2OptionDesc = '_SERVICEINITIATOR2OPTION_DESC_',
  /** Sort on ServiceCounter2 ascending */
  Servicecounter2Asc = '_SERVICECOUNTER2_ASC_',
  /** sort on ServiceCounter2 descending */
  Servicecounter2Desc = '_SERVICECOUNTER2_DESC_',
  /** Sort on ServiceCounterMargin2 ascending */
  Servicecountermargin2Asc = '_SERVICECOUNTERMARGIN2_ASC_',
  /** sort on ServiceCounterMargin2 descending */
  Servicecountermargin2Desc = '_SERVICECOUNTERMARGIN2_DESC_',
  /** Sort on Interval2 ascending */
  Interval2Asc = '_INTERVAL2_ASC_',
  /** sort on Interval2 descending */
  Interval2Desc = '_INTERVAL2_DESC_',
  /** Sort on RepairCode ascending */
  RepaircodeAsc = '_REPAIRCODE_ASC_',
  /** sort on RepairCode descending */
  RepaircodeDesc = '_REPAIRCODE_DESC_',
  /** Sort on PostedDocumentType ascending */
  PosteddocumenttypeAsc = '_POSTEDDOCUMENTTYPE_ASC_',
  /** sort on PostedDocumentType descending */
  PosteddocumenttypeDesc = '_POSTEDDOCUMENTTYPE_DESC_',
  /** Sort on PostedDocumentTypeOPTION ascending */
  PosteddocumenttypeoptionAsc = '_POSTEDDOCUMENTTYPEOPTION_ASC_',
  /** sort on PostedDocumentTypeOPTION descending */
  PosteddocumenttypeoptionDesc = '_POSTEDDOCUMENTTYPEOPTION_DESC_',
  /** Sort on PostedDocumentNo ascending */
  PosteddocumentnoAsc = '_POSTEDDOCUMENTNO_ASC_',
  /** sort on PostedDocumentNo descending */
  PosteddocumentnoDesc = '_POSTEDDOCUMENTNO_DESC_',
  /** Sort on ServiceDateMarginBlocked ascending */
  ServicedatemarginblockedAsc = '_SERVICEDATEMARGINBLOCKED_ASC_',
  /** sort on ServiceDateMarginBlocked descending */
  ServicedatemarginblockedDesc = '_SERVICEDATEMARGINBLOCKED_DESC_',
  /** Sort on ControlTypeCode ascending */
  ControltypecodeAsc = '_CONTROLTYPECODE_ASC_',
  /** sort on ControlTypeCode descending */
  ControltypecodeDesc = '_CONTROLTYPECODE_DESC_',
  /** Sort on ObjectId ascending */
  ObjectidAsc = '_OBJECTID_ASC_',
  /** sort on ObjectId descending */
  ObjectidDesc = '_OBJECTID_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on ObjectTypeId ascending */
  ObjecttypeidAsc = '_OBJECTTYPEID_ASC_',
  /** sort on ObjectTypeId descending */
  ObjecttypeidDesc = '_OBJECTTYPEID_DESC_',
  /** Sort on ManufacturerId ascending */
  ManufactureridAsc = '_MANUFACTURERID_ASC_',
  /** sort on ManufacturerId descending */
  ManufactureridDesc = '_MANUFACTURERID_DESC_',
  /** Sort on ManufacturerModelId ascending */
  ManufacturermodelidAsc = '_MANUFACTURERMODELID_ASC_',
  /** sort on ManufacturerModelId descending */
  ManufacturermodelidDesc = '_MANUFACTURERMODELID_DESC_',
  /** Sort on ObjectGroupId ascending */
  ObjectgroupidAsc = '_OBJECTGROUPID_ASC_',
  /** sort on ObjectGroupId descending */
  ObjectgroupidDesc = '_OBJECTGROUPID_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on AssociatedServicePackId ascending */
  AssociatedservicepackidAsc = '_ASSOCIATEDSERVICEPACKID_ASC_',
  /** sort on AssociatedServicePackId descending */
  AssociatedservicepackidDesc = '_ASSOCIATEDSERVICEPACKID_DESC_',
  /** Sort on TransferredtoOrderId ascending */
  TransferredtoorderidAsc = '_TRANSFERREDTOORDERID_ASC_',
  /** sort on TransferredtoOrderId descending */
  TransferredtoorderidDesc = '_TRANSFERREDTOORDERID_DESC_',
  /** Sort on TransferredtoWSOrderId ascending */
  TransferredtowsorderidAsc = '_TRANSFERREDTOWSORDERID_ASC_',
  /** sort on TransferredtoWSOrderId descending */
  TransferredtowsorderidDesc = '_TRANSFERREDTOWSORDERID_DESC_',
  /** Sort on TransferredtoWSPurchId ascending */
  TransferredtowspurchidAsc = '_TRANSFERREDTOWSPURCHID_ASC_',
  /** sort on TransferredtoWSPurchId descending */
  TransferredtowspurchidDesc = '_TRANSFERREDTOWSPURCHID_DESC_',
  /** Sort on TransferredtoEQMServiceId ascending */
  TransferredtoeqmserviceidAsc = '_TRANSFERREDTOEQMSERVICEID_ASC_',
  /** sort on TransferredtoEQMServiceId descending */
  TransferredtoeqmserviceidDesc = '_TRANSFERREDTOEQMSERVICEID_DESC_',
  /** Sort on TransferredtoWorkOrderId ascending */
  TransferredtoworkorderidAsc = '_TRANSFERREDTOWORKORDERID_ASC_',
  /** sort on TransferredtoWorkOrderId descending */
  TransferredtoworkorderidDesc = '_TRANSFERREDTOWORKORDERID_DESC_',
  /** Sort on RepairId ascending */
  RepairidAsc = '_REPAIRID_ASC_',
  /** sort on RepairId descending */
  RepairidDesc = '_REPAIRID_DESC_',
  /** Sort on PostedDocumentId ascending */
  PosteddocumentidAsc = '_POSTEDDOCUMENTID_ASC_',
  /** sort on PostedDocumentId descending */
  PosteddocumentidDesc = '_POSTEDDOCUMENTID_DESC_',
  /** Sort on ControlTypeId ascending */
  ControltypeidAsc = '_CONTROLTYPEID_ASC_',
  /** sort on ControlTypeId descending */
  ControltypeidDesc = '_CONTROLTYPEID_DESC_',
  /** Sort on ServiceTypeId ascending */
  ServicetypeidAsc = '_SERVICETYPEID_ASC_',
  /** sort on ServiceTypeId descending */
  ServicetypeidDesc = '_SERVICETYPEID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectStatusInput {
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Descripton ascending */
  DescriptonAsc = '_DESCRIPTON_ASC_',
  /** sort on Descripton descending */
  DescriptonDesc = '_DESCRIPTON_DESC_',
  /** Sort on Answer ascending */
  AnswerAsc = '_ANSWER_ASC_',
  /** sort on Answer descending */
  AnswerDesc = '_ANSWER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectTypeCardInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on FAClassCode ascending */
  FaclasscodeAsc = '_FACLASSCODE_ASC_',
  /** sort on FAClassCode descending */
  FaclasscodeDesc = '_FACLASSCODE_DESC_',
  /** Sort on FASubclassCode ascending */
  FasubclasscodeAsc = '_FASUBCLASSCODE_ASC_',
  /** sort on FASubclassCode descending */
  FasubclasscodeDesc = '_FASUBCLASSCODE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepreciationMethod ascending */
  DepreciationmethodAsc = '_DEPRECIATIONMETHOD_ASC_',
  /** sort on DepreciationMethod descending */
  DepreciationmethodDesc = '_DEPRECIATIONMETHOD_DESC_',
  /** Sort on FAPostingGroup ascending */
  FapostinggroupAsc = '_FAPOSTINGGROUP_ASC_',
  /** sort on FAPostingGroup descending */
  FapostinggroupDesc = '_FAPOSTINGGROUP_DESC_',
  /** Sort on ObjectNos ascending */
  ObjectnosAsc = '_OBJECTNOS_ASC_',
  /** sort on ObjectNos descending */
  ObjectnosDesc = '_OBJECTNOS_DESC_',
  /** Sort on CostingMethod ascending */
  CostingmethodAsc = '_COSTINGMETHOD_ASC_',
  /** sort on CostingMethod descending */
  CostingmethodDesc = '_COSTINGMETHOD_DESC_',
  /** Sort on StandardCost ascending */
  StandardcostAsc = '_STANDARDCOST_ASC_',
  /** sort on StandardCost descending */
  StandardcostDesc = '_STANDARDCOST_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on IndirectCost ascending */
  IndirectcostAsc = '_INDIRECTCOST_ASC_',
  /** sort on IndirectCost descending */
  IndirectcostDesc = '_INDIRECTCOST_DESC_',
  /** Sort on LastDirectCost ascending */
  LastdirectcostAsc = '_LASTDIRECTCOST_ASC_',
  /** sort on LastDirectCost descending */
  LastdirectcostDesc = '_LASTDIRECTCOST_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceProfitCalculation ascending */
  PriceprofitcalculationAsc = '_PRICEPROFITCALCULATION_ASC_',
  /** sort on PriceProfitCalculation descending */
  PriceprofitcalculationDesc = '_PRICEPROFITCALCULATION_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on PriceIncludesVAT ascending */
  PriceincludesvatAsc = '_PRICEINCLUDESVAT_ASC_',
  /** sort on PriceIncludesVAT descending */
  PriceincludesvatDesc = '_PRICEINCLUDESVAT_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on NoofDepreciationYears ascending */
  NoofdepreciationyearsAsc = '_NOOFDEPRECIATIONYEARS_ASC_',
  /** sort on NoofDepreciationYears descending */
  NoofdepreciationyearsDesc = '_NOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on NoofDepreciationMonths ascending */
  NoofdepreciationmonthsAsc = '_NOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on NoofDepreciationMonths descending */
  NoofdepreciationmonthsDesc = '_NOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on CounterType ascending */
  CountertypeAsc = '_COUNTERTYPE_ASC_',
  /** sort on CounterType descending */
  CountertypeDesc = '_COUNTERTYPE_DESC_',
  /** Sort on WarrantyDiscParts ascending */
  WarrantydiscpartsAsc = '_WARRANTYDISCPARTS_ASC_',
  /** sort on WarrantyDiscParts descending */
  WarrantydiscpartsDesc = '_WARRANTYDISCPARTS_DESC_',
  /** Sort on WarrantyDiscLabor ascending */
  WarrantydisclaborAsc = '_WARRANTYDISCLABOR_ASC_',
  /** sort on WarrantyDiscLabor descending */
  WarrantydisclaborDesc = '_WARRANTYDISCLABOR_DESC_',
  /** Sort on DefaultWarrantyDuration ascending */
  DefaultwarrantydurationAsc = '_DEFAULTWARRANTYDURATION_ASC_',
  /** sort on DefaultWarrantyDuration descending */
  DefaultwarrantydurationDesc = '_DEFAULTWARRANTYDURATION_DESC_',
  /** Sort on ServiceItemGroup ascending */
  ServiceitemgroupAsc = '_SERVICEITEMGROUP_ASC_',
  /** sort on ServiceItemGroup descending */
  ServiceitemgroupDesc = '_SERVICEITEMGROUP_DESC_',
  /** Sort on ResponseTimeHours ascending */
  ResponsetimehoursAsc = '_RESPONSETIMEHOURS_ASC_',
  /** sort on ResponseTimeHours descending */
  ResponsetimehoursDesc = '_RESPONSETIMEHOURS_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpWriteUp ascending */
  GenprodpostinggrpwriteupAsc = '_GENPRODPOSTINGGRPWRITEUP_ASC_',
  /** sort on GenProdPostingGrpWriteUp descending */
  GenprodpostinggrpwriteupDesc = '_GENPRODPOSTINGGRPWRITEUP_DESC_',
  /** Sort on SaleDiscGroup ascending */
  SalediscgroupAsc = '_SALEDISCGROUP_ASC_',
  /** sort on SaleDiscGroup descending */
  SalediscgroupDesc = '_SALEDISCGROUP_DESC_',
  /** Sort on RentalDiscGroup ascending */
  RentaldiscgroupAsc = '_RENTALDISCGROUP_ASC_',
  /** sort on RentalDiscGroup descending */
  RentaldiscgroupDesc = '_RENTALDISCGROUP_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on VATBusPostingGrPrice ascending */
  VatbuspostinggrpriceAsc = '_VATBUSPOSTINGGRPRICE_ASC_',
  /** sort on VATBusPostingGrPrice descending */
  VatbuspostinggrpriceDesc = '_VATBUSPOSTINGGRPRICE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on GenProdPostingGrpHire ascending */
  GenprodpostinggrphireAsc = '_GENPRODPOSTINGGRPHIRE_ASC_',
  /** sort on GenProdPostingGrpHire descending */
  GenprodpostinggrphireDesc = '_GENPRODPOSTINGGRPHIRE_DESC_',
  /** Sort on GenProdPostingGrpLeasing ascending */
  GenprodpostinggrpleasingAsc = '_GENPRODPOSTINGGRPLEASING_ASC_',
  /** sort on GenProdPostingGrpLeasing descending */
  GenprodpostinggrpleasingDesc = '_GENPRODPOSTINGGRPLEASING_DESC_',
  /** Sort on RentalSplitPostingGroup ascending */
  RentalsplitpostinggroupAsc = '_RENTALSPLITPOSTINGGROUP_ASC_',
  /** sort on RentalSplitPostingGroup descending */
  RentalsplitpostinggroupDesc = '_RENTALSPLITPOSTINGGROUP_DESC_',
  /** Sort on SalesPostingGroup ascending */
  SalespostinggroupAsc = '_SALESPOSTINGGROUP_ASC_',
  /** sort on SalesPostingGroup descending */
  SalespostinggroupDesc = '_SALESPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpExtRent ascending */
  GenprodpostinggrpextrentAsc = '_GENPRODPOSTINGGRPEXTRENT_ASC_',
  /** sort on GenProdPostingGrpExtRent descending */
  GenprodpostinggrpextrentDesc = '_GENPRODPOSTINGGRPEXTRENT_DESC_',
  /** Sort on GenProdPostingGrpIntRent ascending */
  GenprodpostinggrpintrentAsc = '_GENPRODPOSTINGGRPINTRENT_ASC_',
  /** sort on GenProdPostingGrpIntRent descending */
  GenprodpostinggrpintrentDesc = '_GENPRODPOSTINGGRPINTRENT_DESC_',
  /** Sort on VATProdPostingGrpRental ascending */
  VatprodpostinggrprentalAsc = '_VATPRODPOSTINGGRPRENTAL_ASC_',
  /** sort on VATProdPostingGrpRental descending */
  VatprodpostinggrprentalDesc = '_VATPRODPOSTINGGRPRENTAL_DESC_',
  /** Sort on ReRentPostingGroup ascending */
  RerentpostinggroupAsc = '_RERENTPOSTINGGROUP_ASC_',
  /** sort on ReRentPostingGroup descending */
  RerentpostinggroupDesc = '_RERENTPOSTINGGROUP_DESC_',
  /** Sort on ReplacementPrice ascending */
  ReplacementpriceAsc = '_REPLACEMENTPRICE_ASC_',
  /** sort on ReplacementPrice descending */
  ReplacementpriceDesc = '_REPLACEMENTPRICE_DESC_',
  /** Sort on Capacity ascending */
  CapacityAsc = '_CAPACITY_ASC_',
  /** sort on Capacity descending */
  CapacityDesc = '_CAPACITY_DESC_',
  /** Sort on Effect ascending */
  EffectAsc = '_EFFECT_ASC_',
  /** sort on Effect descending */
  EffectDesc = '_EFFECT_DESC_',
  /** Sort on CylinderVolume ascending */
  CylindervolumeAsc = '_CYLINDERVOLUME_ASC_',
  /** sort on CylinderVolume descending */
  CylindervolumeDesc = '_CYLINDERVOLUME_DESC_',
  /** Sort on AutomaticExtendedTexts ascending */
  AutomaticextendedtextsAsc = '_AUTOMATICEXTENDEDTEXTS_ASC_',
  /** sort on AutomaticExtendedTexts descending */
  AutomaticextendedtextsDesc = '_AUTOMATICEXTENDEDTEXTS_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on CapacityMeasure ascending */
  CapacitymeasureAsc = '_CAPACITYMEASURE_ASC_',
  /** sort on CapacityMeasure descending */
  CapacitymeasureDesc = '_CAPACITYMEASURE_DESC_',
  /** Sort on EffectMeasure ascending */
  EffectmeasureAsc = '_EFFECTMEASURE_ASC_',
  /** sort on EffectMeasure descending */
  EffectmeasureDesc = '_EFFECTMEASURE_DESC_',
  /** Sort on CylinderMeasure ascending */
  CylindermeasureAsc = '_CYLINDERMEASURE_ASC_',
  /** sort on CylinderMeasure descending */
  CylindermeasureDesc = '_CYLINDERMEASURE_DESC_',
  /** Sort on Document ascending */
  DocumentAsc = '_DOCUMENT_ASC_',
  /** sort on Document descending */
  DocumentDesc = '_DOCUMENT_DESC_',
  /** Sort on WebPage ascending */
  WebpageAsc = '_WEBPAGE_ASC_',
  /** sort on WebPage descending */
  WebpageDesc = '_WEBPAGE_DESC_',
  /** Sort on ExpAvailableAfterEndDate ascending */
  ExpavailableafterenddateAsc = '_EXPAVAILABLEAFTERENDDATE_ASC_',
  /** sort on ExpAvailableAfterEndDate descending */
  ExpavailableafterenddateDesc = '_EXPAVAILABLEAFTERENDDATE_DESC_',
  /** Sort on CreditonEarlyReturn ascending */
  CreditonearlyreturnAsc = '_CREDITONEARLYRETURN_ASC_',
  /** sort on CreditonEarlyReturn descending */
  CreditonearlyreturnDesc = '_CREDITONEARLYRETURN_DESC_',
  /** Sort on StdRentalChargeDays ascending */
  StdrentalchargedaysAsc = '_STDRENTALCHARGEDAYS_ASC_',
  /** sort on StdRentalChargeDays descending */
  StdrentalchargedaysDesc = '_STDRENTALCHARGEDAYS_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on DefaultFunctionalTest ascending */
  DefaultfunctionaltestAsc = '_DEFAULTFUNCTIONALTEST_ASC_',
  /** sort on DefaultFunctionalTest descending */
  DefaultfunctionaltestDesc = '_DEFAULTFUNCTIONALTEST_DESC_',
  /** Sort on DefaultMaintenance ascending */
  DefaultmaintenanceAsc = '_DEFAULTMAINTENANCE_ASC_',
  /** sort on DefaultMaintenance descending */
  DefaultmaintenanceDesc = '_DEFAULTMAINTENANCE_DESC_',
  /** Sort on Length ascending */
  LengthAsc = '_LENGTH_ASC_',
  /** sort on Length descending */
  LengthDesc = '_LENGTH_DESC_',
  /** Sort on LengthMeasure ascending */
  LengthmeasureAsc = '_LENGTHMEASURE_ASC_',
  /** sort on LengthMeasure descending */
  LengthmeasureDesc = '_LENGTHMEASURE_DESC_',
  /** Sort on Width ascending */
  WidthAsc = '_WIDTH_ASC_',
  /** sort on Width descending */
  WidthDesc = '_WIDTH_DESC_',
  /** Sort on WidthMeasure ascending */
  WidthmeasureAsc = '_WIDTHMEASURE_ASC_',
  /** sort on WidthMeasure descending */
  WidthmeasureDesc = '_WIDTHMEASURE_DESC_',
  /** Sort on Height ascending */
  HeightAsc = '_HEIGHT_ASC_',
  /** sort on Height descending */
  HeightDesc = '_HEIGHT_DESC_',
  /** Sort on HeightMeasure ascending */
  HeightmeasureAsc = '_HEIGHTMEASURE_ASC_',
  /** sort on HeightMeasure descending */
  HeightmeasureDesc = '_HEIGHTMEASURE_DESC_',
  /** Sort on Weight ascending */
  WeightAsc = '_WEIGHT_ASC_',
  /** sort on Weight descending */
  WeightDesc = '_WEIGHT_DESC_',
  /** Sort on WeightMeasure ascending */
  WeightmeasureAsc = '_WEIGHTMEASURE_ASC_',
  /** sort on WeightMeasure descending */
  WeightmeasureDesc = '_WEIGHTMEASURE_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on QuantityHoursFullRentalDay ascending */
  QuantityhoursfullrentaldayAsc = '_QUANTITYHOURSFULLRENTALDAY_ASC_',
  /** sort on QuantityHoursFullRentalDay descending */
  QuantityhoursfullrentaldayDesc = '_QUANTITYHOURSFULLRENTALDAY_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on ObjectGroupId ascending */
  ObjectgroupidAsc = '_OBJECTGROUPID_ASC_',
  /** sort on ObjectGroupId descending */
  ObjectgroupidDesc = '_OBJECTGROUPID_DESC_',
  /** Sort on ManufacturerId ascending */
  ManufactureridAsc = '_MANUFACTURERID_ASC_',
  /** sort on ManufacturerId descending */
  ManufactureridDesc = '_MANUFACTURERID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on NBAConnectedToHierarchy ascending */
  NbaconnectedtohierarchyAsc = '_NBACONNECTEDTOHIERARCHY_ASC_',
  /** sort on NBAConnectedToHierarchy descending */
  NbaconnectedtohierarchyDesc = '_NBACONNECTEDTOHIERARCHY_DESC_',
  /** Sort on NBAMappingNo ascending */
  NbamappingnoAsc = '_NBAMAPPINGNO_ASC_',
  /** sort on NBAMappingNo descending */
  NbamappingnoDesc = '_NBAMAPPINGNO_DESC_',
  /** Sort on NBAMappingDescription ascending */
  NbamappingdescriptionAsc = '_NBAMAPPINGDESCRIPTION_ASC_',
  /** sort on NBAMappingDescription descending */
  NbamappingdescriptionDesc = '_NBAMAPPINGDESCRIPTION_DESC_',
  /** Sort on NBARamirentGroupCode ascending */
  NbaramirentgroupcodeAsc = '_NBARAMIRENTGROUPCODE_ASC_',
  /** sort on NBARamirentGroupCode descending */
  NbaramirentgroupcodeDesc = '_NBARAMIRENTGROUPCODE_DESC_',
  /** Sort on NBAESInspectionInterval ascending */
  NbaesinspectionintervalAsc = '_NBAESINSPECTIONINTERVAL_ASC_',
  /** sort on NBAESInspectionInterval descending */
  NbaesinspectionintervalDesc = '_NBAESINSPECTIONINTERVAL_DESC_',
  /** Sort on NBAESManufacturerModel ascending */
  NbaesmanufacturermodelAsc = '_NBAESMANUFACTURERMODEL_ASC_',
  /** sort on NBAESManufacturerModel descending */
  NbaesmanufacturermodelDesc = '_NBAESMANUFACTURERMODEL_DESC_',
  /** Sort on NBAESBonusGroup ascending */
  NbaesbonusgroupAsc = '_NBAESBONUSGROUP_ASC_',
  /** sort on NBAESBonusGroup descending */
  NbaesbonusgroupDesc = '_NBAESBONUSGROUP_DESC_',
  /** Sort on NBAESExcludeFromVaction ascending */
  NbaesexcludefromvactionAsc = '_NBAESEXCLUDEFROMVACTION_ASC_',
  /** sort on NBAESExcludeFromVaction descending */
  NbaesexcludefromvactionDesc = '_NBAESEXCLUDEFROMVACTION_DESC_',
  /** Sort on ObjectTypeNoConcern ascending */
  ObjecttypenoconcernAsc = '_OBJECTTYPENOCONCERN_ASC_',
  /** sort on ObjectTypeNoConcern descending */
  ObjecttypenoconcernDesc = '_OBJECTTYPENOCONCERN_DESC_',
  /** Sort on DepositAmount ascending */
  DepositamountAsc = '_DEPOSITAMOUNT_ASC_',
  /** sort on DepositAmount descending */
  DepositamountDesc = '_DEPOSITAMOUNT_DESC_',
  /** Sort on HireSplit ascending */
  HiresplitAsc = '_HIRESPLIT_ASC_',
  /** sort on HireSplit descending */
  HiresplitDesc = '_HIRESPLIT_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Description3 ascending */
  Description3Asc = '_DESCRIPTION3_ASC_',
  /** sort on Description3 descending */
  Description3Desc = '_DESCRIPTION3_DESC_',
  /** Sort on Description4 ascending */
  Description4Asc = '_DESCRIPTION4_ASC_',
  /** sort on Description4 descending */
  Description4Desc = '_DESCRIPTION4_DESC_',
  /** Sort on FixedCostPrice ascending */
  FixedcostpriceAsc = '_FIXEDCOSTPRICE_ASC_',
  /** sort on FixedCostPrice descending */
  FixedcostpriceDesc = '_FIXEDCOSTPRICE_DESC_',
  /** Sort on RentalSplit ascending */
  RentalsplitAsc = '_RENTALSPLIT_ASC_',
  /** sort on RentalSplit descending */
  RentalsplitDesc = '_RENTALSPLIT_DESC_',
  /** Sort on StraightLine ascending */
  StraightlineAsc = '_STRAIGHTLINE_ASC_',
  /** sort on StraightLine descending */
  StraightlineDesc = '_STRAIGHTLINE_DESC_',
  /** Sort on WarrantyDuration ascending */
  WarrantydurationAsc = '_WARRANTYDURATION_ASC_',
  /** sort on WarrantyDuration descending */
  WarrantydurationDesc = '_WARRANTYDURATION_DESC_',
  /** Sort on DecliningBalance ascending */
  DecliningbalanceAsc = '_DECLININGBALANCE_ASC_',
  /** sort on DecliningBalance descending */
  DecliningbalanceDesc = '_DECLININGBALANCE_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on RetentionLevel ascending */
  RetentionlevelAsc = '_RETENTIONLEVEL_ASC_',
  /** sort on RetentionLevel descending */
  RetentionlevelDesc = '_RETENTIONLEVEL_DESC_',
  /** Sort on TaxDepreciationMethod ascending */
  TaxdepreciationmethodAsc = '_TAXDEPRECIATIONMETHOD_ASC_',
  /** sort on TaxDepreciationMethod descending */
  TaxdepreciationmethodDesc = '_TAXDEPRECIATIONMETHOD_DESC_',
  /** Sort on TaxFAPostingGroup ascending */
  TaxfapostinggroupAsc = '_TAXFAPOSTINGGROUP_ASC_',
  /** sort on TaxFAPostingGroup descending */
  TaxfapostinggroupDesc = '_TAXFAPOSTINGGROUP_DESC_',
  /** Sort on TaxNoofDepreciationYears ascending */
  TaxnoofdepreciationyearsAsc = '_TAXNOOFDEPRECIATIONYEARS_ASC_',
  /** sort on TaxNoofDepreciationYears descending */
  TaxnoofdepreciationyearsDesc = '_TAXNOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on TaxNoofDepreciationMonths ascending */
  TaxnoofdepreciationmonthsAsc = '_TAXNOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on TaxNoofDepreciationMonths descending */
  TaxnoofdepreciationmonthsDesc = '_TAXNOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on TaxStraightLine ascending */
  TaxstraightlineAsc = '_TAXSTRAIGHTLINE_ASC_',
  /** sort on TaxStraightLine descending */
  TaxstraightlineDesc = '_TAXSTRAIGHTLINE_DESC_',
  /** Sort on TaxDecliningBalance ascending */
  TaxdecliningbalanceAsc = '_TAXDECLININGBALANCE_ASC_',
  /** sort on TaxDecliningBalance descending */
  TaxdecliningbalanceDesc = '_TAXDECLININGBALANCE_DESC_',
  /** Sort on PriceScheduleCode ascending */
  PriceschedulecodeAsc = '_PRICESCHEDULECODE_ASC_',
  /** sort on PriceScheduleCode descending */
  PriceschedulecodeDesc = '_PRICESCHEDULECODE_DESC_',
  /** Sort on EstimatedDailyHours ascending */
  EstimateddailyhoursAsc = '_ESTIMATEDDAILYHOURS_ASC_',
  /** sort on EstimatedDailyHours descending */
  EstimateddailyhoursDesc = '_ESTIMATEDDAILYHOURS_DESC_',
  /** Sort on MandatoryCounteronReturn ascending */
  MandatorycounteronreturnAsc = '_MANDATORYCOUNTERONRETURN_ASC_',
  /** sort on MandatoryCounteronReturn descending */
  MandatorycounteronreturnDesc = '_MANDATORYCOUNTERONRETURN_DESC_',
  /** Sort on CounterType2 ascending */
  Countertype2Asc = '_COUNTERTYPE2_ASC_',
  /** sort on CounterType2 descending */
  Countertype2Desc = '_COUNTERTYPE2_DESC_',
  /** Sort on MandatoryCounter2onReturn ascending */
  Mandatorycounter2OnreturnAsc = '_MANDATORYCOUNTER2ONRETURN_ASC_',
  /** sort on MandatoryCounter2onReturn descending */
  Mandatorycounter2OnreturnDesc = '_MANDATORYCOUNTER2ONRETURN_DESC_',
  /** Sort on EstimatedCounterperDay ascending */
  EstimatedcounterperdayAsc = '_ESTIMATEDCOUNTERPERDAY_ASC_',
  /** sort on EstimatedCounterperDay descending */
  EstimatedcounterperdayDesc = '_ESTIMATEDCOUNTERPERDAY_DESC_',
  /** Sort on EstimatedCounter2perDay ascending */
  Estimatedcounter2PerdayAsc = '_ESTIMATEDCOUNTER2PERDAY_ASC_',
  /** sort on EstimatedCounter2perDay descending */
  Estimatedcounter2PerdayDesc = '_ESTIMATEDCOUNTER2PERDAY_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DateFilter2 ascending */
  Datefilter2Asc = '_DATEFILTER2_ASC_',
  /** sort on DateFilter2 descending */
  Datefilter2Desc = '_DATEFILTER2_DESC_',
  /** Sort on Inventory ascending */
  InventoryAsc = '_INVENTORY_ASC_',
  /** sort on Inventory descending */
  InventoryDesc = '_INVENTORY_DESC_',
  /** Sort on InventoryObject ascending */
  InventoryobjectAsc = '_INVENTORYOBJECT_ASC_',
  /** sort on InventoryObject descending */
  InventoryobjectDesc = '_INVENTORYOBJECT_DESC_',
  /** Sort on InventoryRental ascending */
  InventoryrentalAsc = '_INVENTORYRENTAL_ASC_',
  /** sort on InventoryRental descending */
  InventoryrentalDesc = '_INVENTORYRENTAL_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on CommentObjectGroup ascending */
  CommentobjectgroupAsc = '_COMMENTOBJECTGROUP_ASC_',
  /** sort on CommentObjectGroup descending */
  CommentobjectgroupDesc = '_COMMENTOBJECTGROUP_DESC_',
  /** Sort on FixedAssetSetupGroup ascending */
  FixedassetsetupgroupAsc = '_FIXEDASSETSETUPGROUP_ASC_',
  /** sort on FixedAssetSetupGroup descending */
  FixedassetsetupgroupDesc = '_FIXEDASSETSETUPGROUP_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on InvoicedDays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on InvoicedDays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on RentalReservationsQty ascending */
  RentalreservationsqtyAsc = '_RENTALRESERVATIONSQTY_ASC_',
  /** sort on RentalReservationsQty descending */
  RentalreservationsqtyDesc = '_RENTALRESERVATIONSQTY_DESC_',
  /** Sort on RentalDeliveriesQty ascending */
  RentaldeliveriesqtyAsc = '_RENTALDELIVERIESQTY_ASC_',
  /** sort on RentalDeliveriesQty descending */
  RentaldeliveriesqtyDesc = '_RENTALDELIVERIESQTY_DESC_',
  /** Sort on ExpectedRentalReturnsQty ascending */
  ExpectedrentalreturnsqtyAsc = '_EXPECTEDRENTALRETURNSQTY_ASC_',
  /** sort on ExpectedRentalReturnsQty descending */
  ExpectedrentalreturnsqtyDesc = '_EXPECTEDRENTALRETURNSQTY_DESC_',
  /** Sort on RentalReturnsQty ascending */
  RentalreturnsqtyAsc = '_RENTALRETURNSQTY_ASC_',
  /** sort on RentalReturnsQty descending */
  RentalreturnsqtyDesc = '_RENTALRETURNSQTY_DESC_',
  /** Sort on PictureURL ascending */
  PictureurlAsc = '_PICTUREURL_ASC_',
  /** sort on PictureURL descending */
  PictureurlDesc = '_PICTUREURL_DESC_',
  /** Sort on PSIDocumentURL ascending */
  PsidocumenturlAsc = '_PSIDOCUMENTURL_ASC_',
  /** sort on PSIDocumentURL descending */
  PsidocumenturlDesc = '_PSIDOCUMENTURL_DESC_',
  /** Sort on NotAvailableForReservation ascending */
  NotavailableforreservationAsc = '_NOTAVAILABLEFORRESERVATION_ASC_',
  /** sort on NotAvailableForReservation descending */
  NotavailableforreservationDesc = '_NOTAVAILABLEFORRESERVATION_DESC_',
  /** Sort on ExpirationDateMandatory ascending */
  ExpirationdatemandatoryAsc = '_EXPIRATIONDATEMANDATORY_ASC_',
  /** sort on ExpirationDateMandatory descending */
  ExpirationdatemandatoryDesc = '_EXPIRATIONDATEMANDATORY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectTypeComponentsInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineDiscountPercent ascending */
  LinediscountpercentAsc = '_LINEDISCOUNTPERCENT_ASC_',
  /** sort on LineDiscountPercent descending */
  LinediscountpercentDesc = '_LINEDISCOUNTPERCENT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on LevelofAspiration ascending */
  LevelofaspirationAsc = '_LEVELOFASPIRATION_ASC_',
  /** sort on LevelofAspiration descending */
  LevelofaspirationDesc = '_LEVELOFASPIRATION_DESC_',
  /** Sort on LevelofAspirationOPTION ascending */
  LevelofaspirationoptionAsc = '_LEVELOFASPIRATIONOPTION_ASC_',
  /** sort on LevelofAspirationOPTION descending */
  LevelofaspirationoptionDesc = '_LEVELOFASPIRATIONOPTION_DESC_',
  /** Sort on EntryType ascending */
  EntrytypeAsc = '_ENTRYTYPE_ASC_',
  /** sort on EntryType descending */
  EntrytypeDesc = '_ENTRYTYPE_DESC_',
  /** Sort on EntryTypeOPTION ascending */
  EntrytypeoptionAsc = '_ENTRYTYPEOPTION_ASC_',
  /** sort on EntryTypeOPTION descending */
  EntrytypeoptionDesc = '_ENTRYTYPEOPTION_DESC_',
  /** Sort on NonBillable ascending */
  NonbillableAsc = '_NONBILLABLE_ASC_',
  /** sort on NonBillable descending */
  NonbillableDesc = '_NONBILLABLE_DESC_',
  /** Sort on InsertAutomatically ascending */
  InsertautomaticallyAsc = '_INSERTAUTOMATICALLY_ASC_',
  /** sort on InsertAutomatically descending */
  InsertautomaticallyDesc = '_INSERTAUTOMATICALLY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectTypeFlowInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on UserSecurityID ascending */
  UsersecurityidAsc = '_USERSECURITYID_ASC_',
  /** sort on UserSecurityID descending */
  UsersecurityidDesc = '_USERSECURITYID_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowPurchaseAddCost ascending */
  FlowpurchaseaddcostAsc = '_FLOWPURCHASEADDCOST_ASC_',
  /** sort on FlowPurchaseAddCost descending */
  FlowpurchaseaddcostDesc = '_FLOWPURCHASEADDCOST_DESC_',
  /** Sort on FlowBookValue ascending */
  FlowbookvalueAsc = '_FLOWBOOKVALUE_ASC_',
  /** sort on FlowBookValue descending */
  FlowbookvalueDesc = '_FLOWBOOKVALUE_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowAcquisitionCost ascending */
  FlowacquisitioncostAsc = '_FLOWACQUISITIONCOST_ASC_',
  /** sort on FlowAcquisitionCost descending */
  FlowacquisitioncostDesc = '_FLOWACQUISITIONCOST_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on InvoicedDays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on InvoicedDays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectTypePriceTermInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on PercentofMonthlyPrice ascending */
  PercentofmonthlypriceAsc = '_PERCENTOFMONTHLYPRICE_ASC_',
  /** sort on PercentofMonthlyPrice descending */
  PercentofmonthlypriceDesc = '_PERCENTOFMONTHLYPRICE_DESC_',
  /** Sort on FixedTermPrice ascending */
  FixedtermpriceAsc = '_FIXEDTERMPRICE_ASC_',
  /** sort on FixedTermPrice descending */
  FixedtermpriceDesc = '_FIXEDTERMPRICE_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on PriceonExtraHours ascending */
  PriceonextrahoursAsc = '_PRICEONEXTRAHOURS_ASC_',
  /** sort on PriceonExtraHours descending */
  PriceonextrahoursDesc = '_PRICEONEXTRAHOURS_DESC_',
  /** Sort on AllowLineDisconExHours ascending */
  AllowlinedisconexhoursAsc = '_ALLOWLINEDISCONEXHOURS_ASC_',
  /** sort on AllowLineDisconExHours descending */
  AllowlinedisconexhoursDesc = '_ALLOWLINEDISCONEXHOURS_DESC_',
  /** Sort on PriceReportedHours ascending */
  PricereportedhoursAsc = '_PRICEREPORTEDHOURS_ASC_',
  /** sort on PriceReportedHours descending */
  PricereportedhoursDesc = '_PRICEREPORTEDHOURS_DESC_',
  /** Sort on FreeRentalHours ascending */
  FreerentalhoursAsc = '_FREERENTALHOURS_ASC_',
  /** sort on FreeRentalHours descending */
  FreerentalhoursDesc = '_FREERENTALHOURS_DESC_',
  /** Sort on MinimumReportedHours ascending */
  MinimumreportedhoursAsc = '_MINIMUMREPORTEDHOURS_ASC_',
  /** sort on MinimumReportedHours descending */
  MinimumreportedhoursDesc = '_MINIMUMREPORTEDHOURS_DESC_',
  /** Sort on MinRepHoursPeriod ascending */
  MinrephoursperiodAsc = '_MINREPHOURSPERIOD_ASC_',
  /** sort on MinRepHoursPeriod descending */
  MinrephoursperiodDesc = '_MINREPHOURSPERIOD_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmObjectTypeServiceIntervalInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ServiceTypeCode ascending */
  ServicetypecodeAsc = '_SERVICETYPECODE_ASC_',
  /** sort on ServiceTypeCode descending */
  ServicetypecodeDesc = '_SERVICETYPECODE_DESC_',
  /** Sort on ServiceInitiator ascending */
  ServiceinitiatorAsc = '_SERVICEINITIATOR_ASC_',
  /** sort on ServiceInitiator descending */
  ServiceinitiatorDesc = '_SERVICEINITIATOR_DESC_',
  /** Sort on ServiceInitiatorOPTION ascending */
  ServiceinitiatoroptionAsc = '_SERVICEINITIATOROPTION_ASC_',
  /** sort on ServiceInitiatorOPTION descending */
  ServiceinitiatoroptionDesc = '_SERVICEINITIATOROPTION_DESC_',
  /** Sort on ServiceCounter ascending */
  ServicecounterAsc = '_SERVICECOUNTER_ASC_',
  /** sort on ServiceCounter descending */
  ServicecounterDesc = '_SERVICECOUNTER_DESC_',
  /** Sort on ServiceDate ascending */
  ServicedateAsc = '_SERVICEDATE_ASC_',
  /** sort on ServiceDate descending */
  ServicedateDesc = '_SERVICEDATE_DESC_',
  /** Sort on ServiceDurationDays ascending */
  ServicedurationdaysAsc = '_SERVICEDURATIONDAYS_ASC_',
  /** sort on ServiceDurationDays descending */
  ServicedurationdaysDesc = '_SERVICEDURATIONDAYS_DESC_',
  /** Sort on AssociatedServicePack ascending */
  AssociatedservicepackAsc = '_ASSOCIATEDSERVICEPACK_ASC_',
  /** sort on AssociatedServicePack descending */
  AssociatedservicepackDesc = '_ASSOCIATEDSERVICEPACK_DESC_',
  /** Sort on CustomerDebit ascending */
  CustomerdebitAsc = '_CUSTOMERDEBIT_ASC_',
  /** sort on CustomerDebit descending */
  CustomerdebitDesc = '_CUSTOMERDEBIT_DESC_',
  /** Sort on ServiceMargin ascending */
  ServicemarginAsc = '_SERVICEMARGIN_ASC_',
  /** sort on ServiceMargin descending */
  ServicemarginDesc = '_SERVICEMARGIN_DESC_',
  /** Sort on Repetitive ascending */
  RepetitiveAsc = '_REPETITIVE_ASC_',
  /** sort on Repetitive descending */
  RepetitiveDesc = '_REPETITIVE_DESC_',
  /** Sort on Interval ascending */
  IntervalAsc = '_INTERVAL_ASC_',
  /** sort on Interval descending */
  IntervalDesc = '_INTERVAL_DESC_',
  /** Sort on DateInterval ascending */
  DateintervalAsc = '_DATEINTERVAL_ASC_',
  /** sort on DateInterval descending */
  DateintervalDesc = '_DATEINTERVAL_DESC_',
  /** Sort on ServiceCounterMargin ascending */
  ServicecountermarginAsc = '_SERVICECOUNTERMARGIN_ASC_',
  /** sort on ServiceCounterMargin descending */
  ServicecountermarginDesc = '_SERVICECOUNTERMARGIN_DESC_',
  /** Sort on ServiceDateMargin ascending */
  ServicedatemarginAsc = '_SERVICEDATEMARGIN_ASC_',
  /** sort on ServiceDateMargin descending */
  ServicedatemarginDesc = '_SERVICEDATEMARGIN_DESC_',
  /** Sort on ServiceInitiator2 ascending */
  Serviceinitiator2Asc = '_SERVICEINITIATOR2_ASC_',
  /** sort on ServiceInitiator2 descending */
  Serviceinitiator2Desc = '_SERVICEINITIATOR2_DESC_',
  /** Sort on ServiceInitiator2OPTION ascending */
  Serviceinitiator2OptionAsc = '_SERVICEINITIATOR2OPTION_ASC_',
  /** sort on ServiceInitiator2OPTION descending */
  Serviceinitiator2OptionDesc = '_SERVICEINITIATOR2OPTION_DESC_',
  /** Sort on ServiceCounter2 ascending */
  Servicecounter2Asc = '_SERVICECOUNTER2_ASC_',
  /** sort on ServiceCounter2 descending */
  Servicecounter2Desc = '_SERVICECOUNTER2_DESC_',
  /** Sort on ServiceCounterMargin2 ascending */
  Servicecountermargin2Asc = '_SERVICECOUNTERMARGIN2_ASC_',
  /** sort on ServiceCounterMargin2 descending */
  Servicecountermargin2Desc = '_SERVICECOUNTERMARGIN2_DESC_',
  /** Sort on Interval2 ascending */
  Interval2Asc = '_INTERVAL2_ASC_',
  /** sort on Interval2 descending */
  Interval2Desc = '_INTERVAL2_DESC_',
  /** Sort on RepairCode ascending */
  RepaircodeAsc = '_REPAIRCODE_ASC_',
  /** sort on RepairCode descending */
  RepaircodeDesc = '_REPAIRCODE_DESC_',
  /** Sort on ServiceDateMarginBlocked ascending */
  ServicedatemarginblockedAsc = '_SERVICEDATEMARGINBLOCKED_ASC_',
  /** sort on ServiceDateMarginBlocked descending */
  ServicedatemarginblockedDesc = '_SERVICEDATEMARGINBLOCKED_DESC_',
  /** Sort on ControlTypeCode ascending */
  ControltypecodeAsc = '_CONTROLTYPECODE_ASC_',
  /** sort on ControlTypeCode descending */
  ControltypecodeDesc = '_CONTROLTYPECODE_DESC_',
  /** Sort on Certification ascending */
  CertificationAsc = '_CERTIFICATION_ASC_',
  /** sort on Certification descending */
  CertificationDesc = '_CERTIFICATION_DESC_',
  /** Sort on ServiceCode ascending */
  ServicecodeAsc = '_SERVICECODE_ASC_',
  /** sort on ServiceCode descending */
  ServicecodeDesc = '_SERVICECODE_DESC_',
  /** Sort on ActionType ascending */
  ActiontypeAsc = '_ACTIONTYPE_ASC_',
  /** sort on ActionType descending */
  ActiontypeDesc = '_ACTIONTYPE_DESC_',
  /** Sort on DekraInspection ascending */
  DekrainspectionAsc = '_DEKRAINSPECTION_ASC_',
  /** sort on DekraInspection descending */
  DekrainspectionDesc = '_DEKRAINSPECTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmReRentCardInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SearchDescription ascending */
  SearchdescriptionAsc = '_SEARCHDESCRIPTION_ASC_',
  /** sort on SearchDescription descending */
  SearchdescriptionDesc = '_SEARCHDESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on RentalStatus ascending */
  RentalstatusAsc = '_RENTALSTATUS_ASC_',
  /** sort on RentalStatus descending */
  RentalstatusDesc = '_RENTALSTATUS_DESC_',
  /** Sort on RentalStatusOPTION ascending */
  RentalstatusoptionAsc = '_RENTALSTATUSOPTION_ASC_',
  /** sort on RentalStatusOPTION descending */
  RentalstatusoptionDesc = '_RENTALSTATUSOPTION_DESC_',
  /** Sort on Counter ascending */
  CounterAsc = '_COUNTER_ASC_',
  /** sort on Counter descending */
  CounterDesc = '_COUNTER_DESC_',
  /** Sort on Counter2 ascending */
  Counter2Asc = '_COUNTER2_ASC_',
  /** sort on Counter2 descending */
  Counter2Desc = '_COUNTER2_DESC_',
  /** Sort on PurchaseDocument ascending */
  PurchasedocumentAsc = '_PURCHASEDOCUMENT_ASC_',
  /** sort on PurchaseDocument descending */
  PurchasedocumentDesc = '_PURCHASEDOCUMENT_DESC_',
  /** Sort on PostedPurchaseInvoice ascending */
  PostedpurchaseinvoiceAsc = '_POSTEDPURCHASEINVOICE_ASC_',
  /** sort on PostedPurchaseInvoice descending */
  PostedpurchaseinvoiceDesc = '_POSTEDPURCHASEINVOICE_DESC_',
  /** Sort on PostedPurchaseCrMemo ascending */
  PostedpurchasecrmemoAsc = '_POSTEDPURCHASECRMEMO_ASC_',
  /** sort on PostedPurchaseCrMemo descending */
  PostedpurchasecrmemoDesc = '_POSTEDPURCHASECRMEMO_DESC_',
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on ManufacturerModelCode ascending */
  ManufacturermodelcodeAsc = '_MANUFACTURERMODELCODE_ASC_',
  /** sort on ManufacturerModelCode descending */
  ManufacturermodelcodeDesc = '_MANUFACTURERMODELCODE_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on RentalContractOpen ascending */
  RentalcontractopenAsc = '_RENTALCONTRACTOPEN_ASC_',
  /** sort on RentalContractOpen descending */
  RentalcontractopenDesc = '_RENTALCONTRACTOPEN_DESC_',
  /** Sort on RentalContractAll ascending */
  RentalcontractallAsc = '_RENTALCONTRACTALL_ASC_',
  /** sort on RentalContractAll descending */
  RentalcontractallDesc = '_RENTALCONTRACTALL_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on VendorName ascending */
  VendornameAsc = '_VENDORNAME_ASC_',
  /** sort on VendorName descending */
  VendornameDesc = '_VENDORNAME_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on CreateDate ascending */
  CreatedateAsc = '_CREATEDATE_ASC_',
  /** sort on CreateDate descending */
  CreatedateDesc = '_CREATEDATE_DESC_',
  /** Sort on ReRentCost ascending */
  RerentcostAsc = '_RERENTCOST_ASC_',
  /** sort on ReRentCost descending */
  RerentcostDesc = '_RERENTCOST_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on ReceivedDate ascending */
  ReceiveddateAsc = '_RECEIVEDDATE_ASC_',
  /** sort on ReceivedDate descending */
  ReceiveddateDesc = '_RECEIVEDDATE_DESC_',
  /** Sort on ReturnedDate ascending */
  ReturneddateAsc = '_RETURNEDDATE_ASC_',
  /** sort on ReturnedDate descending */
  ReturneddateDesc = '_RETURNEDDATE_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on ObjectTypeId ascending */
  ObjecttypeidAsc = '_OBJECTTYPEID_ASC_',
  /** sort on ObjectTypeId descending */
  ObjecttypeidDesc = '_OBJECTTYPEID_DESC_',
  /** Sort on ObjectGroupId ascending */
  ObjectgroupidAsc = '_OBJECTGROUPID_ASC_',
  /** sort on ObjectGroupId descending */
  ObjectgroupidDesc = '_OBJECTGROUPID_DESC_',
  /** Sort on ManufacturerId ascending */
  ManufactureridAsc = '_MANUFACTURERID_ASC_',
  /** sort on ManufacturerId descending */
  ManufactureridDesc = '_MANUFACTURERID_DESC_',
  /** Sort on ManufacturerModelId ascending */
  ManufacturermodelidAsc = '_MANUFACTURERMODELID_ASC_',
  /** sort on ManufacturerModelId descending */
  ManufacturermodelidDesc = '_MANUFACTURERMODELID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmRentalGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmRentalKitComponentsInput {
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on ContractType ascending */
  ContracttypeAsc = '_CONTRACTTYPE_ASC_',
  /** sort on ContractType descending */
  ContracttypeDesc = '_CONTRACTTYPE_DESC_',
  /** Sort on ContractTypeOPTION ascending */
  ContracttypeoptionAsc = '_CONTRACTTYPEOPTION_ASC_',
  /** sort on ContractTypeOPTION descending */
  ContracttypeoptionDesc = '_CONTRACTTYPEOPTION_DESC_',
  /** Sort on ExtLineNo ascending */
  ExtlinenoAsc = '_EXTLINENO_ASC_',
  /** sort on ExtLineNo descending */
  ExtlinenoDesc = '_EXTLINENO_DESC_',
  /** Sort on KITNo ascending */
  KitnoAsc = '_KITNO_ASC_',
  /** sort on KITNo descending */
  KitnoDesc = '_KITNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ChildItem ascending */
  ChilditemAsc = '_CHILDITEM_ASC_',
  /** sort on ChildItem descending */
  ChilditemDesc = '_CHILDITEM_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Price ascending */
  PriceAsc = '_PRICE_ASC_',
  /** sort on Price descending */
  PriceDesc = '_PRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmRentalKitInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on KitType ascending */
  KittypeAsc = '_KITTYPE_ASC_',
  /** sort on KitType descending */
  KittypeDesc = '_KITTYPE_DESC_',
  /** Sort on KitTypeOPTION ascending */
  KittypeoptionAsc = '_KITTYPEOPTION_ASC_',
  /** sort on KitTypeOPTION descending */
  KittypeoptionDesc = '_KITTYPEOPTION_DESC_',
  /** Sort on KITPrice ascending */
  KitpriceAsc = '_KITPRICE_ASC_',
  /** sort on KITPrice descending */
  KitpriceDesc = '_KITPRICE_DESC_',
  /** Sort on PriceTerm ascending */
  PricetermAsc = '_PRICETERM_ASC_',
  /** sort on PriceTerm descending */
  PricetermDesc = '_PRICETERM_DESC_',
  /** Sort on ShowinContract ascending */
  ShowincontractAsc = '_SHOWINCONTRACT_ASC_',
  /** sort on ShowinContract descending */
  ShowincontractDesc = '_SHOWINCONTRACT_DESC_',
  /** Sort on PricePriority ascending */
  PricepriorityAsc = '_PRICEPRIORITY_ASC_',
  /** sort on PricePriority descending */
  PricepriorityDesc = '_PRICEPRIORITY_DESC_',
  /** Sort on PricePriorityOPTION ascending */
  PricepriorityoptionAsc = '_PRICEPRIORITYOPTION_ASC_',
  /** sort on PricePriorityOPTION descending */
  PricepriorityoptionDesc = '_PRICEPRIORITYOPTION_DESC_',
  /** Sort on PostingPriority ascending */
  PostingpriorityAsc = '_POSTINGPRIORITY_ASC_',
  /** sort on PostingPriority descending */
  PostingpriorityDesc = '_POSTINGPRIORITY_DESC_',
  /** Sort on PostingPriorityOPTION ascending */
  PostingpriorityoptionAsc = '_POSTINGPRIORITYOPTION_ASC_',
  /** sort on PostingPriorityOPTION descending */
  PostingpriorityoptionDesc = '_POSTINGPRIORITYOPTION_DESC_',
  /** Sort on SpecifyKitComponents ascending */
  SpecifykitcomponentsAsc = '_SPECIFYKITCOMPONENTS_ASC_',
  /** sort on SpecifyKitComponents descending */
  SpecifykitcomponentsDesc = '_SPECIFYKITCOMPONENTS_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on BaseCalendar ascending */
  BasecalendarAsc = '_BASECALENDAR_ASC_',
  /** sort on BaseCalendar descending */
  BasecalendarDesc = '_BASECALENDAR_DESC_',
  /** Sort on BaseUnitofMeasure ascending */
  BaseunitofmeasureAsc = '_BASEUNITOFMEASURE_ASC_',
  /** sort on BaseUnitofMeasure descending */
  BaseunitofmeasureDesc = '_BASEUNITOFMEASURE_DESC_',
  /** Sort on DepositAmount ascending */
  DepositamountAsc = '_DEPOSITAMOUNT_ASC_',
  /** sort on DepositAmount descending */
  DepositamountDesc = '_DEPOSITAMOUNT_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on IgnoreVaryingCompQty ascending */
  IgnorevaryingcompqtyAsc = '_IGNOREVARYINGCOMPQTY_ASC_',
  /** sort on IgnoreVaryingCompQty descending */
  IgnorevaryingcompqtyDesc = '_IGNOREVARYINGCOMPQTY_DESC_',
  /** Sort on CreditonEarlyOffRent ascending */
  CreditonearlyoffrentAsc = '_CREDITONEARLYOFFRENT_ASC_',
  /** sort on CreditonEarlyOffRent descending */
  CreditonearlyoffrentDesc = '_CREDITONEARLYOFFRENT_DESC_',
  /** Sort on DebitforReturnDate ascending */
  DebitforreturndateAsc = '_DEBITFORRETURNDATE_ASC_',
  /** sort on DebitforReturnDate descending */
  DebitforreturndateDesc = '_DEBITFORRETURNDATE_DESC_',
  /** Sort on IgnoreKITLineinDispatch ascending */
  IgnorekitlineindispatchAsc = '_IGNOREKITLINEINDISPATCH_ASC_',
  /** sort on IgnoreKITLineinDispatch descending */
  IgnorekitlineindispatchDesc = '_IGNOREKITLINEINDISPATCH_DESC_',
  /** Sort on SuspendUpdateComponents ascending */
  SuspendupdatecomponentsAsc = '_SUSPENDUPDATECOMPONENTS_ASC_',
  /** sort on SuspendUpdateComponents descending */
  SuspendupdatecomponentsDesc = '_SUSPENDUPDATECOMPONENTS_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on OnAccountBilling ascending */
  OnaccountbillingAsc = '_ONACCOUNTBILLING_ASC_',
  /** sort on OnAccountBilling descending */
  OnaccountbillingDesc = '_ONACCOUNTBILLING_DESC_',
  /** Sort on UnitofMeasureId ascending */
  UnitofmeasureidAsc = '_UNITOFMEASUREID_ASC_',
  /** sort on UnitofMeasureId descending */
  UnitofmeasureidDesc = '_UNITOFMEASUREID_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on NBAESBonusGroup ascending */
  NbaesbonusgroupAsc = '_NBAESBONUSGROUP_ASC_',
  /** sort on NBAESBonusGroup descending */
  NbaesbonusgroupDesc = '_NBAESBONUSGROUP_DESC_',
  /** Sort on UseforReporting ascending */
  UseforreportingAsc = '_USEFORREPORTING_ASC_',
  /** sort on UseforReporting descending */
  UseforreportingDesc = '_USEFORREPORTING_DESC_',
  /** Sort on DefaultKIT ascending */
  DefaultkitAsc = '_DEFAULTKIT_ASC_',
  /** sort on DefaultKIT descending */
  DefaultkitDesc = '_DEFAULTKIT_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on RentalItemDiscGroup ascending */
  RentalitemdiscgroupAsc = '_RENTALITEMDISCGROUP_ASC_',
  /** sort on RentalItemDiscGroup descending */
  RentalitemdiscgroupDesc = '_RENTALITEMDISCGROUP_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmRentalKitLinesInput {
  /** Sort on KITNo ascending */
  KitnoAsc = '_KITNO_ASC_',
  /** sort on KITNo descending */
  KitnoDesc = '_KITNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ChildNo ascending */
  ChildnoAsc = '_CHILDNO_ASC_',
  /** sort on ChildNo descending */
  ChildnoDesc = '_CHILDNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Price ascending */
  PriceAsc = '_PRICE_ASC_',
  /** sort on Price descending */
  PriceDesc = '_PRICE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmRentalLineDiscountInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on SalesCode ascending */
  SalescodeAsc = '_SALESCODE_ASC_',
  /** sort on SalesCode descending */
  SalescodeDesc = '_SALESCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on StartingDate ascending */
  StartingdateAsc = '_STARTINGDATE_ASC_',
  /** sort on StartingDate descending */
  StartingdateDesc = '_STARTINGDATE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on SalesType ascending */
  SalestypeAsc = '_SALESTYPE_ASC_',
  /** sort on SalesType descending */
  SalestypeDesc = '_SALESTYPE_DESC_',
  /** Sort on SalesTypeOPTION ascending */
  SalestypeoptionAsc = '_SALESTYPEOPTION_ASC_',
  /** sort on SalesTypeOPTION descending */
  SalestypeoptionDesc = '_SALESTYPEOPTION_DESC_',
  /** Sort on MinimumQuantity ascending */
  MinimumquantityAsc = '_MINIMUMQUANTITY_ASC_',
  /** sort on MinimumQuantity descending */
  MinimumquantityDesc = '_MINIMUMQUANTITY_DESC_',
  /** Sort on EndingDate ascending */
  EndingdateAsc = '_ENDINGDATE_ASC_',
  /** sort on EndingDate descending */
  EndingdateDesc = '_ENDINGDATE_DESC_',
  /** Sort on CodeType ascending */
  CodetypeAsc = '_CODETYPE_ASC_',
  /** sort on CodeType descending */
  CodetypeDesc = '_CODETYPE_DESC_',
  /** Sort on CodeTypeOPTION ascending */
  CodetypeoptionAsc = '_CODETYPEOPTION_ASC_',
  /** sort on CodeTypeOPTION descending */
  CodetypeoptionDesc = '_CODETYPEOPTION_DESC_',
  /** Sort on UsageType ascending */
  UsagetypeAsc = '_USAGETYPE_ASC_',
  /** sort on UsageType descending */
  UsagetypeDesc = '_USAGETYPE_DESC_',
  /** Sort on UsageTypeOPTION ascending */
  UsagetypeoptionAsc = '_USAGETYPEOPTION_ASC_',
  /** sort on UsageTypeOPTION descending */
  UsagetypeoptionDesc = '_USAGETYPEOPTION_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on NBAESDescription ascending */
  NbaesdescriptionAsc = '_NBAESDESCRIPTION_ASC_',
  /** sort on NBAESDescription descending */
  NbaesdescriptionDesc = '_NBAESDESCRIPTION_DESC_',
  /** Sort on DiscountType ascending */
  DiscounttypeAsc = '_DISCOUNTTYPE_ASC_',
  /** sort on DiscountType descending */
  DiscounttypeDesc = '_DISCOUNTTYPE_DESC_',
  /** Sort on DiscountTypeOPTION ascending */
  DiscounttypeoptionAsc = '_DISCOUNTTYPEOPTION_ASC_',
  /** sort on DiscountTypeOPTION descending */
  DiscounttypeoptionDesc = '_DISCOUNTTYPEOPTION_DESC_',
  /** Sort on StairDiscountCode ascending */
  StairdiscountcodeAsc = '_STAIRDISCOUNTCODE_ASC_',
  /** sort on StairDiscountCode descending */
  StairdiscountcodeDesc = '_STAIRDISCOUNTCODE_DESC_',
  /** Sort on StepwiseDiscount ascending */
  StepwisediscountAsc = '_STEPWISEDISCOUNT_ASC_',
  /** sort on StepwiseDiscount descending */
  StepwisediscountDesc = '_STEPWISEDISCOUNT_DESC_',
  /** Sort on UseStepwiseDisc ascending */
  UsestepwisediscAsc = '_USESTEPWISEDISC_ASC_',
  /** sort on UseStepwiseDisc descending */
  UsestepwisediscDesc = '_USESTEPWISEDISC_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on FrameAgreementNo ascending */
  FrameagreementnoAsc = '_FRAMEAGREEMENTNO_ASC_',
  /** sort on FrameAgreementNo descending */
  FrameagreementnoDesc = '_FRAMEAGREEMENTNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmRentalPriceInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on SalesCode ascending */
  SalescodeAsc = '_SALESCODE_ASC_',
  /** sort on SalesCode descending */
  SalescodeDesc = '_SALESCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on StartingDate ascending */
  StartingdateAsc = '_STARTINGDATE_ASC_',
  /** sort on StartingDate descending */
  StartingdateDesc = '_STARTINGDATE_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceIncludesVAT ascending */
  PriceincludesvatAsc = '_PRICEINCLUDESVAT_ASC_',
  /** sort on PriceIncludesVAT descending */
  PriceincludesvatDesc = '_PRICEINCLUDESVAT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on VATBusPostingGrPrice ascending */
  VatbuspostinggrpriceAsc = '_VATBUSPOSTINGGRPRICE_ASC_',
  /** sort on VATBusPostingGrPrice descending */
  VatbuspostinggrpriceDesc = '_VATBUSPOSTINGGRPRICE_DESC_',
  /** Sort on CodeType ascending */
  CodetypeAsc = '_CODETYPE_ASC_',
  /** sort on CodeType descending */
  CodetypeDesc = '_CODETYPE_DESC_',
  /** Sort on CodeTypeOPTION ascending */
  CodetypeoptionAsc = '_CODETYPEOPTION_ASC_',
  /** sort on CodeTypeOPTION descending */
  CodetypeoptionDesc = '_CODETYPEOPTION_DESC_',
  /** Sort on SalesType ascending */
  SalestypeAsc = '_SALESTYPE_ASC_',
  /** sort on SalesType descending */
  SalestypeDesc = '_SALESTYPE_DESC_',
  /** Sort on SalesTypeOPTION ascending */
  SalestypeoptionAsc = '_SALESTYPEOPTION_ASC_',
  /** sort on SalesTypeOPTION descending */
  SalestypeoptionDesc = '_SALESTYPEOPTION_DESC_',
  /** Sort on MinimumQuantity ascending */
  MinimumquantityAsc = '_MINIMUMQUANTITY_ASC_',
  /** sort on MinimumQuantity descending */
  MinimumquantityDesc = '_MINIMUMQUANTITY_DESC_',
  /** Sort on EndingDate ascending */
  EndingdateAsc = '_ENDINGDATE_ASC_',
  /** sort on EndingDate descending */
  EndingdateDesc = '_ENDINGDATE_DESC_',
  /** Sort on RentalType ascending */
  RentaltypeAsc = '_RENTALTYPE_ASC_',
  /** sort on RentalType descending */
  RentaltypeDesc = '_RENTALTYPE_DESC_',
  /** Sort on RentalTypeOPTION ascending */
  RentaltypeoptionAsc = '_RENTALTYPEOPTION_ASC_',
  /** sort on RentalTypeOPTION descending */
  RentaltypeoptionDesc = '_RENTALTYPEOPTION_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on StairCode ascending */
  StaircodeAsc = '_STAIRCODE_ASC_',
  /** sort on StairCode descending */
  StaircodeDesc = '_STAIRCODE_DESC_',
  /** Sort on ReturnPriceDisc ascending */
  ReturnpricediscAsc = '_RETURNPRICEDISC_ASC_',
  /** sort on ReturnPriceDisc descending */
  ReturnpricediscDesc = '_RETURNPRICEDISC_DESC_',
  /** Sort on ReturnStairCode ascending */
  ReturnstaircodeAsc = '_RETURNSTAIRCODE_ASC_',
  /** sort on ReturnStairCode descending */
  ReturnstaircodeDesc = '_RETURNSTAIRCODE_DESC_',
  /** Sort on UsageType ascending */
  UsagetypeAsc = '_USAGETYPE_ASC_',
  /** sort on UsageType descending */
  UsagetypeDesc = '_USAGETYPE_DESC_',
  /** Sort on UsageTypeOPTION ascending */
  UsagetypeoptionAsc = '_USAGETYPEOPTION_ASC_',
  /** sort on UsageTypeOPTION descending */
  UsagetypeoptionDesc = '_USAGETYPEOPTION_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on NBAESDescription ascending */
  NbaesdescriptionAsc = '_NBAESDESCRIPTION_ASC_',
  /** sort on NBAESDescription descending */
  NbaesdescriptionDesc = '_NBAESDESCRIPTION_DESC_',
  /** Sort on PriceonExtraHours ascending */
  PriceonextrahoursAsc = '_PRICEONEXTRAHOURS_ASC_',
  /** sort on PriceonExtraHours descending */
  PriceonextrahoursDesc = '_PRICEONEXTRAHOURS_DESC_',
  /** Sort on PriceScheduleCode ascending */
  PriceschedulecodeAsc = '_PRICESCHEDULECODE_ASC_',
  /** sort on PriceScheduleCode descending */
  PriceschedulecodeDesc = '_PRICESCHEDULECODE_DESC_',
  /** Sort on AllowLineDisconExHours ascending */
  AllowlinedisconexhoursAsc = '_ALLOWLINEDISCONEXHOURS_ASC_',
  /** sort on AllowLineDisconExHours descending */
  AllowlinedisconexhoursDesc = '_ALLOWLINEDISCONEXHOURS_DESC_',
  /** Sort on PriceReportedHours ascending */
  PricereportedhoursAsc = '_PRICEREPORTEDHOURS_ASC_',
  /** sort on PriceReportedHours descending */
  PricereportedhoursDesc = '_PRICEREPORTEDHOURS_DESC_',
  /** Sort on FreeRentalHours ascending */
  FreerentalhoursAsc = '_FREERENTALHOURS_ASC_',
  /** sort on FreeRentalHours descending */
  FreerentalhoursDesc = '_FREERENTALHOURS_DESC_',
  /** Sort on MinimumReportedHours ascending */
  MinimumreportedhoursAsc = '_MINIMUMREPORTEDHOURS_ASC_',
  /** sort on MinimumReportedHours descending */
  MinimumreportedhoursDesc = '_MINIMUMREPORTEDHOURS_DESC_',
  /** Sort on MinRepHoursPeriod ascending */
  MinrephoursperiodAsc = '_MINREPHOURSPERIOD_ASC_',
  /** sort on MinRepHoursPeriod descending */
  MinrephoursperiodDesc = '_MINREPHOURSPERIOD_DESC_',
  /** Sort on ofSalesPrice ascending */
  OfsalespriceAsc = '_OFSALESPRICE_ASC_',
  /** sort on ofSalesPrice descending */
  OfsalespriceDesc = '_OFSALESPRICE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on MinRentalPeriod ascending */
  MinrentalperiodAsc = '_MINRENTALPERIOD_ASC_',
  /** sort on MinRentalPeriod descending */
  MinrentalperiodDesc = '_MINRENTALPERIOD_DESC_',
  /** Sort on StairPrice1 ascending */
  Stairprice1Asc = '_STAIRPRICE1_ASC_',
  /** sort on StairPrice1 descending */
  Stairprice1Desc = '_STAIRPRICE1_DESC_',
  /** Sort on StairPrice2 ascending */
  Stairprice2Asc = '_STAIRPRICE2_ASC_',
  /** sort on StairPrice2 descending */
  Stairprice2Desc = '_STAIRPRICE2_DESC_',
  /** Sort on StairPrice3 ascending */
  Stairprice3Asc = '_STAIRPRICE3_ASC_',
  /** sort on StairPrice3 descending */
  Stairprice3Desc = '_STAIRPRICE3_DESC_',
  /** Sort on StairPrice4 ascending */
  Stairprice4Asc = '_STAIRPRICE4_ASC_',
  /** sort on StairPrice4 descending */
  Stairprice4Desc = '_STAIRPRICE4_DESC_',
  /** Sort on StairPrice5 ascending */
  Stairprice5Asc = '_STAIRPRICE5_ASC_',
  /** sort on StairPrice5 descending */
  Stairprice5Desc = '_STAIRPRICE5_DESC_',
  /** Sort on MultipleRentalPrices ascending */
  MultiplerentalpricesAsc = '_MULTIPLERENTALPRICES_ASC_',
  /** sort on MultipleRentalPrices descending */
  MultiplerentalpricesDesc = '_MULTIPLERENTALPRICES_DESC_',
  /** Sort on FrameAgreementNo ascending */
  FrameagreementnoAsc = '_FRAMEAGREEMENTNO_ASC_',
  /** sort on FrameAgreementNo descending */
  FrameagreementnoDesc = '_FRAMEAGREEMENTNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmReturnChargesInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ReqAction ascending */
  ReqactionAsc = '_REQACTION_ASC_',
  /** sort on ReqAction descending */
  ReqactionDesc = '_REQACTION_DESC_',
  /** Sort on UseatFunctionalTest ascending */
  UseatfunctionaltestAsc = '_USEATFUNCTIONALTEST_ASC_',
  /** sort on UseatFunctionalTest descending */
  UseatfunctionaltestDesc = '_USEATFUNCTIONALTEST_DESC_',
  /** Sort on WorkType ascending */
  WorktypeAsc = '_WORKTYPE_ASC_',
  /** sort on WorkType descending */
  WorktypeDesc = '_WORKTYPE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmServiceCardInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on ObjectSerialNo ascending */
  ObjectserialnoAsc = '_OBJECTSERIALNO_ASC_',
  /** sort on ObjectSerialNo descending */
  ObjectserialnoDesc = '_OBJECTSERIALNO_DESC_',
  /** Sort on FunctionalTestsFlow ascending */
  FunctionaltestsflowAsc = '_FUNCTIONALTESTSFLOW_ASC_',
  /** sort on FunctionalTestsFlow descending */
  FunctionaltestsflowDesc = '_FUNCTIONALTESTSFLOW_DESC_',
  /** Sort on WorkshopOrdersFlow ascending */
  WorkshopordersflowAsc = '_WORKSHOPORDERSFLOW_ASC_',
  /** sort on WorkshopOrdersFlow descending */
  WorkshopordersflowDesc = '_WORKSHOPORDERSFLOW_DESC_',
  /** Sort on WorkshopPurchasesFlow ascending */
  WorkshoppurchasesflowAsc = '_WORKSHOPPURCHASESFLOW_ASC_',
  /** sort on WorkshopPurchasesFlow descending */
  WorkshoppurchasesflowDesc = '_WORKSHOPPURCHASESFLOW_DESC_',
  /** Sort on WorkshopPurchLinesFlow ascending */
  WorkshoppurchlinesflowAsc = '_WORKSHOPPURCHLINESFLOW_ASC_',
  /** sort on WorkshopPurchLinesFlow descending */
  WorkshoppurchlinesflowDesc = '_WORKSHOPPURCHLINESFLOW_DESC_',
  /** Sort on PlannedStartDate ascending */
  PlannedstartdateAsc = '_PLANNEDSTARTDATE_ASC_',
  /** sort on PlannedStartDate descending */
  PlannedstartdateDesc = '_PLANNEDSTARTDATE_DESC_',
  /** Sort on PlannedStartTime ascending */
  PlannedstarttimeAsc = '_PLANNEDSTARTTIME_ASC_',
  /** sort on PlannedStartTime descending */
  PlannedstarttimeDesc = '_PLANNEDSTARTTIME_DESC_',
  /** Sort on PlannedEndDate ascending */
  PlannedenddateAsc = '_PLANNEDENDDATE_ASC_',
  /** sort on PlannedEndDate descending */
  PlannedenddateDesc = '_PLANNEDENDDATE_DESC_',
  /** Sort on PlannedEndTime ascending */
  PlannedendtimeAsc = '_PLANNEDENDTIME_ASC_',
  /** sort on PlannedEndTime descending */
  PlannedendtimeDesc = '_PLANNEDENDTIME_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on StartTime ascending */
  StarttimeAsc = '_STARTTIME_ASC_',
  /** sort on StartTime descending */
  StarttimeDesc = '_STARTTIME_DESC_',
  /** Sort on EndDate ascending */
  EnddateAsc = '_ENDDATE_ASC_',
  /** sort on EndDate descending */
  EnddateDesc = '_ENDDATE_DESC_',
  /** Sort on EndTime ascending */
  EndtimeAsc = '_ENDTIME_ASC_',
  /** sort on EndTime descending */
  EndtimeDesc = '_ENDTIME_DESC_',
  /** Sort on PostedFunctionalTestsFlow ascending */
  PostedfunctionaltestsflowAsc = '_POSTEDFUNCTIONALTESTSFLOW_ASC_',
  /** sort on PostedFunctionalTestsFlow descending */
  PostedfunctionaltestsflowDesc = '_POSTEDFUNCTIONALTESTSFLOW_DESC_',
  /** Sort on PostedWorkshopOrdersFlow ascending */
  PostedworkshopordersflowAsc = '_POSTEDWORKSHOPORDERSFLOW_ASC_',
  /** sort on PostedWorkshopOrdersFlow descending */
  PostedworkshopordersflowDesc = '_POSTEDWORKSHOPORDERSFLOW_DESC_',
  /** Sort on PostedWorkshopPurchFlow ascending */
  PostedworkshoppurchflowAsc = '_POSTEDWORKSHOPPURCHFLOW_ASC_',
  /** sort on PostedWorkshopPurchFlow descending */
  PostedworkshoppurchflowDesc = '_POSTEDWORKSHOPPURCHFLOW_DESC_',
  /** Sort on AvoidCalendarEntry ascending */
  AvoidcalendarentryAsc = '_AVOIDCALENDARENTRY_ASC_',
  /** sort on AvoidCalendarEntry descending */
  AvoidcalendarentryDesc = '_AVOIDCALENDARENTRY_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ReturnDocumentNo ascending */
  ReturndocumentnoAsc = '_RETURNDOCUMENTNO_ASC_',
  /** sort on ReturnDocumentNo descending */
  ReturndocumentnoDesc = '_RETURNDOCUMENTNO_DESC_',
  /** Sort on ContractLineNo ascending */
  ContractlinenoAsc = '_CONTRACTLINENO_ASC_',
  /** sort on ContractLineNo descending */
  ContractlinenoDesc = '_CONTRACTLINENO_DESC_',
  /** Sort on CollectionDocumentNo ascending */
  CollectiondocumentnoAsc = '_COLLECTIONDOCUMENTNO_ASC_',
  /** sort on CollectionDocumentNo descending */
  CollectiondocumentnoDesc = '_COLLECTIONDOCUMENTNO_DESC_',
  /** Sort on RepairCode ascending */
  RepaircodeAsc = '_REPAIRCODE_ASC_',
  /** sort on RepairCode descending */
  RepaircodeDesc = '_REPAIRCODE_DESC_',
  /** Sort on MainResource ascending */
  MainresourceAsc = '_MAINRESOURCE_ASC_',
  /** sort on MainResource descending */
  MainresourceDesc = '_MAINRESOURCE_DESC_',
  /** Sort on MainCustomer ascending */
  MaincustomerAsc = '_MAINCUSTOMER_ASC_',
  /** sort on MainCustomer descending */
  MaincustomerDesc = '_MAINCUSTOMER_DESC_',
  /** Sort on MainVendor ascending */
  MainvendorAsc = '_MAINVENDOR_ASC_',
  /** sort on MainVendor descending */
  MainvendorDesc = '_MAINVENDOR_DESC_',
  /** Sort on Manufacturer ascending */
  ManufacturerAsc = '_MANUFACTURER_ASC_',
  /** sort on Manufacturer descending */
  ManufacturerDesc = '_MANUFACTURER_DESC_',
  /** Sort on ManufacturerModel ascending */
  ManufacturermodelAsc = '_MANUFACTURERMODEL_ASC_',
  /** sort on ManufacturerModel descending */
  ManufacturermodelDesc = '_MANUFACTURERMODEL_DESC_',
  /** Sort on EmployeeNo ascending */
  EmployeenoAsc = '_EMPLOYEENO_ASC_',
  /** sort on EmployeeNo descending */
  EmployeenoDesc = '_EMPLOYEENO_DESC_',
  /** Sort on InternalCustomerNo ascending */
  InternalcustomernoAsc = '_INTERNALCUSTOMERNO_ASC_',
  /** sort on InternalCustomerNo descending */
  InternalcustomernoDesc = '_INTERNALCUSTOMERNO_DESC_',
  /** Sort on CurrentLocationCode ascending */
  CurrentlocationcodeAsc = '_CURRENTLOCATIONCODE_ASC_',
  /** sort on CurrentLocationCode descending */
  CurrentlocationcodeDesc = '_CURRENTLOCATIONCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on PurchaseDocumentType ascending */
  PurchasedocumenttypeAsc = '_PURCHASEDOCUMENTTYPE_ASC_',
  /** sort on PurchaseDocumentType descending */
  PurchasedocumenttypeDesc = '_PURCHASEDOCUMENTTYPE_DESC_',
  /** Sort on PurchaseDocumentTypeOPTION ascending */
  PurchasedocumenttypeoptionAsc = '_PURCHASEDOCUMENTTYPEOPTION_ASC_',
  /** sort on PurchaseDocumentTypeOPTION descending */
  PurchasedocumenttypeoptionDesc = '_PURCHASEDOCUMENTTYPEOPTION_DESC_',
  /** Sort on PurchaseDocumentNo ascending */
  PurchasedocumentnoAsc = '_PURCHASEDOCUMENTNO_ASC_',
  /** sort on PurchaseDocumentNo descending */
  PurchasedocumentnoDesc = '_PURCHASEDOCUMENTNO_DESC_',
  /** Sort on PurchaseLineNo ascending */
  PurchaselinenoAsc = '_PURCHASELINENO_ASC_',
  /** sort on PurchaseLineNo descending */
  PurchaselinenoDesc = '_PURCHASELINENO_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on ReceivingRespCenter ascending */
  ReceivingrespcenterAsc = '_RECEIVINGRESPCENTER_ASC_',
  /** sort on ReceivingRespCenter descending */
  ReceivingrespcenterDesc = '_RECEIVINGRESPCENTER_DESC_',
  /** Sort on ReceivingCostCenterCode ascending */
  ReceivingcostcentercodeAsc = '_RECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on ReceivingCostCenterCode descending */
  ReceivingcostcentercodeDesc = '_RECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on ReceivingLocationCode ascending */
  ReceivinglocationcodeAsc = '_RECEIVINGLOCATIONCODE_ASC_',
  /** sort on ReceivingLocationCode descending */
  ReceivinglocationcodeDesc = '_RECEIVINGLOCATIONCODE_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on FinishedBy ascending */
  FinishedbyAsc = '_FINISHEDBY_ASC_',
  /** sort on FinishedBy descending */
  FinishedbyDesc = '_FINISHEDBY_DESC_',
  /** Sort on CreatedDate ascending */
  CreateddateAsc = '_CREATEDDATE_ASC_',
  /** sort on CreatedDate descending */
  CreateddateDesc = '_CREATEDDATE_DESC_',
  /** Sort on CreatedTime ascending */
  CreatedtimeAsc = '_CREATEDTIME_ASC_',
  /** sort on CreatedTime descending */
  CreatedtimeDesc = '_CREATEDTIME_DESC_',
  /** Sort on StartedBy ascending */
  StartedbyAsc = '_STARTEDBY_ASC_',
  /** sort on StartedBy descending */
  StartedbyDesc = '_STARTEDBY_DESC_',
  /** Sort on NoofServiceOrders ascending */
  NoofserviceordersAsc = '_NOOFSERVICEORDERS_ASC_',
  /** sort on NoofServiceOrders descending */
  NoofserviceordersDesc = '_NOOFSERVICEORDERS_DESC_',
  /** Sort on NoofPostedServiceOrders ascending */
  NoofpostedserviceordersAsc = '_NOOFPOSTEDSERVICEORDERS_ASC_',
  /** sort on NoofPostedServiceOrders descending */
  NoofpostedserviceordersDesc = '_NOOFPOSTEDSERVICEORDERS_DESC_',
  /** Sort on ControlTestsFlow ascending */
  ControltestsflowAsc = '_CONTROLTESTSFLOW_ASC_',
  /** sort on ControlTestsFlow descending */
  ControltestsflowDesc = '_CONTROLTESTSFLOW_DESC_',
  /** Sort on PostedControlTestFlow ascending */
  PostedcontroltestflowAsc = '_POSTEDCONTROLTESTFLOW_ASC_',
  /** sort on PostedControlTestFlow descending */
  PostedcontroltestflowDesc = '_POSTEDCONTROLTESTFLOW_DESC_',
  /** Sort on FunctionalTestPictures ascending */
  FunctionaltestpicturesAsc = '_FUNCTIONALTESTPICTURES_ASC_',
  /** sort on FunctionalTestPictures descending */
  FunctionaltestpicturesDesc = '_FUNCTIONALTESTPICTURES_DESC_',
  /** Sort on ControlTypeCode ascending */
  ControltypecodeAsc = '_CONTROLTYPECODE_ASC_',
  /** sort on ControlTypeCode descending */
  ControltypecodeDesc = '_CONTROLTYPECODE_DESC_',
  /** Sort on WorkOrderFlow ascending */
  WorkorderflowAsc = '_WORKORDERFLOW_ASC_',
  /** sort on WorkOrderFlow descending */
  WorkorderflowDesc = '_WORKORDERFLOW_DESC_',
  /** Sort on NoofReturnCharges ascending */
  NoofreturnchargesAsc = '_NOOFRETURNCHARGES_ASC_',
  /** sort on NoofReturnCharges descending */
  NoofreturnchargesDesc = '_NOOFRETURNCHARGES_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmServiceTypeInput {
  /** Sort on ServiceType ascending */
  ServicetypeAsc = '_SERVICETYPE_ASC_',
  /** sort on ServiceType descending */
  ServicetypeDesc = '_SERVICETYPE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ParallelwithRental ascending */
  ParallelwithrentalAsc = '_PARALLELWITHRENTAL_ASC_',
  /** sort on ParallelwithRental descending */
  ParallelwithrentalDesc = '_PARALLELWITHRENTAL_DESC_',
  /** Sort on NeedsWorkshopTime ascending */
  NeedsworkshoptimeAsc = '_NEEDSWORKSHOPTIME_ASC_',
  /** sort on NeedsWorkshopTime descending */
  NeedsworkshoptimeDesc = '_NEEDSWORKSHOPTIME_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmTransferHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on TransferfromCode ascending */
  TransferfromcodeAsc = '_TRANSFERFROMCODE_ASC_',
  /** sort on TransferfromCode descending */
  TransferfromcodeDesc = '_TRANSFERFROMCODE_DESC_',
  /** Sort on TransferfromName ascending */
  TransferfromnameAsc = '_TRANSFERFROMNAME_ASC_',
  /** sort on TransferfromName descending */
  TransferfromnameDesc = '_TRANSFERFROMNAME_DESC_',
  /** Sort on TransferfromName2 ascending */
  Transferfromname2Asc = '_TRANSFERFROMNAME2_ASC_',
  /** sort on TransferfromName2 descending */
  Transferfromname2Desc = '_TRANSFERFROMNAME2_DESC_',
  /** Sort on TransferfromAddress ascending */
  TransferfromaddressAsc = '_TRANSFERFROMADDRESS_ASC_',
  /** sort on TransferfromAddress descending */
  TransferfromaddressDesc = '_TRANSFERFROMADDRESS_DESC_',
  /** Sort on TransferfromAddress2 ascending */
  Transferfromaddress2Asc = '_TRANSFERFROMADDRESS2_ASC_',
  /** sort on TransferfromAddress2 descending */
  Transferfromaddress2Desc = '_TRANSFERFROMADDRESS2_DESC_',
  /** Sort on TransferfromPostCode ascending */
  TransferfrompostcodeAsc = '_TRANSFERFROMPOSTCODE_ASC_',
  /** sort on TransferfromPostCode descending */
  TransferfrompostcodeDesc = '_TRANSFERFROMPOSTCODE_DESC_',
  /** Sort on TransferfromCity ascending */
  TransferfromcityAsc = '_TRANSFERFROMCITY_ASC_',
  /** sort on TransferfromCity descending */
  TransferfromcityDesc = '_TRANSFERFROMCITY_DESC_',
  /** Sort on TransferfromCounty ascending */
  TransferfromcountyAsc = '_TRANSFERFROMCOUNTY_ASC_',
  /** sort on TransferfromCounty descending */
  TransferfromcountyDesc = '_TRANSFERFROMCOUNTY_DESC_',
  /** Sort on TrsffromCountryRegionCode ascending */
  TrsffromcountryregioncodeAsc = '_TRSFFROMCOUNTRYREGIONCODE_ASC_',
  /** sort on TrsffromCountryRegionCode descending */
  TrsffromcountryregioncodeDesc = '_TRSFFROMCOUNTRYREGIONCODE_DESC_',
  /** Sort on TransfertoCode ascending */
  TransfertocodeAsc = '_TRANSFERTOCODE_ASC_',
  /** sort on TransfertoCode descending */
  TransfertocodeDesc = '_TRANSFERTOCODE_DESC_',
  /** Sort on TransfertoName ascending */
  TransfertonameAsc = '_TRANSFERTONAME_ASC_',
  /** sort on TransfertoName descending */
  TransfertonameDesc = '_TRANSFERTONAME_DESC_',
  /** Sort on TransfertoName2 ascending */
  Transfertoname2Asc = '_TRANSFERTONAME2_ASC_',
  /** sort on TransfertoName2 descending */
  Transfertoname2Desc = '_TRANSFERTONAME2_DESC_',
  /** Sort on TransfertoAddress ascending */
  TransfertoaddressAsc = '_TRANSFERTOADDRESS_ASC_',
  /** sort on TransfertoAddress descending */
  TransfertoaddressDesc = '_TRANSFERTOADDRESS_DESC_',
  /** Sort on TransfertoAddress2 ascending */
  Transfertoaddress2Asc = '_TRANSFERTOADDRESS2_ASC_',
  /** sort on TransfertoAddress2 descending */
  Transfertoaddress2Desc = '_TRANSFERTOADDRESS2_DESC_',
  /** Sort on TransfertoPostCode ascending */
  TransfertopostcodeAsc = '_TRANSFERTOPOSTCODE_ASC_',
  /** sort on TransfertoPostCode descending */
  TransfertopostcodeDesc = '_TRANSFERTOPOSTCODE_DESC_',
  /** Sort on TransfertoCity ascending */
  TransfertocityAsc = '_TRANSFERTOCITY_ASC_',
  /** sort on TransfertoCity descending */
  TransfertocityDesc = '_TRANSFERTOCITY_DESC_',
  /** Sort on TransfertoCounty ascending */
  TransfertocountyAsc = '_TRANSFERTOCOUNTY_ASC_',
  /** sort on TransfertoCounty descending */
  TransfertocountyDesc = '_TRANSFERTOCOUNTY_DESC_',
  /** Sort on TrsftoCountryRegionCode ascending */
  TrsftocountryregioncodeAsc = '_TRSFTOCOUNTRYREGIONCODE_ASC_',
  /** sort on TrsftoCountryRegionCode descending */
  TrsftocountryregioncodeDesc = '_TRSFTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on ReceiptDate ascending */
  ReceiptdateAsc = '_RECEIPTDATE_ASC_',
  /** sort on ReceiptDate descending */
  ReceiptdateDesc = '_RECEIPTDATE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on InTransitCode ascending */
  IntransitcodeAsc = '_INTRANSITCODE_ASC_',
  /** sort on InTransitCode descending */
  IntransitcodeDesc = '_INTRANSITCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on LastShipmentNo ascending */
  LastshipmentnoAsc = '_LASTSHIPMENTNO_ASC_',
  /** sort on LastShipmentNo descending */
  LastshipmentnoDesc = '_LASTSHIPMENTNO_DESC_',
  /** Sort on LastReceiptNo ascending */
  LastreceiptnoAsc = '_LASTRECEIPTNO_ASC_',
  /** sort on LastReceiptNo descending */
  LastreceiptnoDesc = '_LASTRECEIPTNO_DESC_',
  /** Sort on TransferfromContact ascending */
  TransferfromcontactAsc = '_TRANSFERFROMCONTACT_ASC_',
  /** sort on TransferfromContact descending */
  TransferfromcontactDesc = '_TRANSFERFROMCONTACT_DESC_',
  /** Sort on TransfertoContact ascending */
  TransfertocontactAsc = '_TRANSFERTOCONTACT_ASC_',
  /** sort on TransfertoContact descending */
  TransfertocontactDesc = '_TRANSFERTOCONTACT_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on TransferOrderReference ascending */
  TransferorderreferenceAsc = '_TRANSFERORDERREFERENCE_ASC_',
  /** sort on TransferOrderReference descending */
  TransferorderreferenceDesc = '_TRANSFERORDERREFERENCE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AssignedEmployeeNo ascending */
  AssignedemployeenoAsc = '_ASSIGNEDEMPLOYEENO_ASC_',
  /** sort on AssignedEmployeeNo descending */
  AssignedemployeenoDesc = '_ASSIGNEDEMPLOYEENO_DESC_',
  /** Sort on EntryExitPoint ascending */
  EntryexitpointAsc = '_ENTRYEXITPOINT_ASC_',
  /** sort on EntryExitPoint descending */
  EntryexitpointDesc = '_ENTRYEXITPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on DirectTransfer ascending */
  DirecttransferAsc = '_DIRECTTRANSFER_ASC_',
  /** sort on DirectTransfer descending */
  DirecttransferDesc = '_DIRECTTRANSFER_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on RentalDispatchType ascending */
  RentaldispatchtypeAsc = '_RENTALDISPATCHTYPE_ASC_',
  /** sort on RentalDispatchType descending */
  RentaldispatchtypeDesc = '_RENTALDISPATCHTYPE_DESC_',
  /** Sort on RentalDispatchTypeOPTION ascending */
  RentaldispatchtypeoptionAsc = '_RENTALDISPATCHTYPEOPTION_ASC_',
  /** sort on RentalDispatchTypeOPTION descending */
  RentaldispatchtypeoptionDesc = '_RENTALDISPATCHTYPEOPTION_DESC_',
  /** Sort on RentalDispatchOrderNo ascending */
  RentaldispatchordernoAsc = '_RENTALDISPATCHORDERNO_ASC_',
  /** sort on RentalDispatchOrderNo descending */
  RentaldispatchordernoDesc = '_RENTALDISPATCHORDERNO_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on PostingfromWhseRef ascending */
  PostingfromwhserefAsc = '_POSTINGFROMWHSEREF_ASC_',
  /** sort on PostingfromWhseRef descending */
  PostingfromwhserefDesc = '_POSTINGFROMWHSEREF_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on CompletelyReceived ascending */
  CompletelyreceivedAsc = '_COMPLETELYRECEIVED_ASC_',
  /** sort on CompletelyReceived descending */
  CompletelyreceivedDesc = '_COMPLETELYRECEIVED_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on HasShippedLines ascending */
  HasshippedlinesAsc = '_HASSHIPPEDLINES_ASC_',
  /** sort on HasShippedLines descending */
  HasshippedlinesDesc = '_HASSHIPPEDLINES_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on ReadyForPostShipment ascending */
  ReadyforpostshipmentAsc = '_READYFORPOSTSHIPMENT_ASC_',
  /** sort on ReadyForPostShipment descending */
  ReadyforpostshipmentDesc = '_READYFORPOSTSHIPMENT_DESC_',
  /** Sort on ReadyForPostReceipt ascending */
  ReadyforpostreceiptAsc = '_READYFORPOSTRECEIPT_ASC_',
  /** sort on ReadyForPostReceipt descending */
  ReadyforpostreceiptDesc = '_READYFORPOSTRECEIPT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmTransferLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on QtytoReceive ascending */
  QtytoreceiveAsc = '_QTYTORECEIVE_ASC_',
  /** sort on QtytoReceive descending */
  QtytoreceiveDesc = '_QTYTORECEIVE_DESC_',
  /** Sort on QuantityShipped ascending */
  QuantityshippedAsc = '_QUANTITYSHIPPED_ASC_',
  /** sort on QuantityShipped descending */
  QuantityshippedDesc = '_QUANTITYSHIPPED_DESC_',
  /** Sort on QuantityReceived ascending */
  QuantityreceivedAsc = '_QUANTITYRECEIVED_ASC_',
  /** sort on QuantityReceived descending */
  QuantityreceivedDesc = '_QUANTITYRECEIVED_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on OutstandingQtyBase ascending */
  OutstandingqtybaseAsc = '_OUTSTANDINGQTYBASE_ASC_',
  /** sort on OutstandingQtyBase descending */
  OutstandingqtybaseDesc = '_OUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoShipBase ascending */
  QtytoshipbaseAsc = '_QTYTOSHIPBASE_ASC_',
  /** sort on QtytoShipBase descending */
  QtytoshipbaseDesc = '_QTYTOSHIPBASE_DESC_',
  /** Sort on QtyShippedBase ascending */
  QtyshippedbaseAsc = '_QTYSHIPPEDBASE_ASC_',
  /** sort on QtyShippedBase descending */
  QtyshippedbaseDesc = '_QTYSHIPPEDBASE_DESC_',
  /** Sort on QtytoReceiveBase ascending */
  QtytoreceivebaseAsc = '_QTYTORECEIVEBASE_ASC_',
  /** sort on QtytoReceiveBase descending */
  QtytoreceivebaseDesc = '_QTYTORECEIVEBASE_DESC_',
  /** Sort on QtyReceivedBase ascending */
  QtyreceivedbaseAsc = '_QTYRECEIVEDBASE_ASC_',
  /** sort on QtyReceivedBase descending */
  QtyreceivedbaseDesc = '_QTYRECEIVEDBASE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on OutstandingQuantity ascending */
  OutstandingquantityAsc = '_OUTSTANDINGQUANTITY_ASC_',
  /** sort on OutstandingQuantity descending */
  OutstandingquantityDesc = '_OUTSTANDINGQUANTITY_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on InTransitCode ascending */
  IntransitcodeAsc = '_INTRANSITCODE_ASC_',
  /** sort on InTransitCode descending */
  IntransitcodeDesc = '_INTRANSITCODE_DESC_',
  /** Sort on QtyinTransitBase ascending */
  QtyintransitbaseAsc = '_QTYINTRANSITBASE_ASC_',
  /** sort on QtyinTransitBase descending */
  QtyintransitbaseDesc = '_QTYINTRANSITBASE_DESC_',
  /** Sort on TransferfromCode ascending */
  TransferfromcodeAsc = '_TRANSFERFROMCODE_ASC_',
  /** sort on TransferfromCode descending */
  TransferfromcodeDesc = '_TRANSFERFROMCODE_DESC_',
  /** Sort on TransfertoCode ascending */
  TransfertocodeAsc = '_TRANSFERTOCODE_ASC_',
  /** sort on TransfertoCode descending */
  TransfertocodeDesc = '_TRANSFERTOCODE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on ReceiptDate ascending */
  ReceiptdateAsc = '_RECEIPTDATE_ASC_',
  /** sort on ReceiptDate descending */
  ReceiptdateDesc = '_RECEIPTDATE_DESC_',
  /** Sort on DerivedFromLineNo ascending */
  DerivedfromlinenoAsc = '_DERIVEDFROMLINENO_ASC_',
  /** sort on DerivedFromLineNo descending */
  DerivedfromlinenoDesc = '_DERIVEDFROMLINENO_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on QtyinTransit ascending */
  QtyintransitAsc = '_QTYINTRANSIT_ASC_',
  /** sort on QtyinTransit descending */
  QtyintransitDesc = '_QTYINTRANSIT_DESC_',
  /** Sort on ReservedQuantityInbnd ascending */
  ReservedquantityinbndAsc = '_RESERVEDQUANTITYINBND_ASC_',
  /** sort on ReservedQuantityInbnd descending */
  ReservedquantityinbndDesc = '_RESERVEDQUANTITYINBND_DESC_',
  /** Sort on ReservedQuantityOutbnd ascending */
  ReservedquantityoutbndAsc = '_RESERVEDQUANTITYOUTBND_ASC_',
  /** sort on ReservedQuantityOutbnd descending */
  ReservedquantityoutbndDesc = '_RESERVEDQUANTITYOUTBND_DESC_',
  /** Sort on ReservedQtyInbndBase ascending */
  ReservedqtyinbndbaseAsc = '_RESERVEDQTYINBNDBASE_ASC_',
  /** sort on ReservedQtyInbndBase descending */
  ReservedqtyinbndbaseDesc = '_RESERVEDQTYINBNDBASE_DESC_',
  /** Sort on ReservedQtyOutbndBase ascending */
  ReservedqtyoutbndbaseAsc = '_RESERVEDQTYOUTBNDBASE_ASC_',
  /** sort on ReservedQtyOutbndBase descending */
  ReservedqtyoutbndbaseDesc = '_RESERVEDQTYOUTBNDBASE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on ReservedQuantityShipped ascending */
  ReservedquantityshippedAsc = '_RESERVEDQUANTITYSHIPPED_ASC_',
  /** sort on ReservedQuantityShipped descending */
  ReservedquantityshippedDesc = '_RESERVEDQUANTITYSHIPPED_DESC_',
  /** Sort on ReservedQtyShippedBase ascending */
  ReservedqtyshippedbaseAsc = '_RESERVEDQTYSHIPPEDBASE_ASC_',
  /** sort on ReservedQtyShippedBase descending */
  ReservedqtyshippedbaseDesc = '_RESERVEDQTYSHIPPEDBASE_DESC_',
  /** Sort on DirectTransfer ascending */
  DirecttransferAsc = '_DIRECTTRANSFER_ASC_',
  /** sort on DirectTransfer descending */
  DirecttransferDesc = '_DIRECTTRANSFER_DESC_',
  /** Sort on ChgCurrLocationOnly ascending */
  ChgcurrlocationonlyAsc = '_CHGCURRLOCATIONONLY_ASC_',
  /** sort on ChgCurrLocationOnly descending */
  ChgcurrlocationonlyDesc = '_CHGCURRLOCATIONONLY_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on AllocateObjectNo ascending */
  AllocateobjectnoAsc = '_ALLOCATEOBJECTNO_ASC_',
  /** sort on AllocateObjectNo descending */
  AllocateobjectnoDesc = '_ALLOCATEOBJECTNO_DESC_',
  /** Sort on AllocateOwnershipStatus ascending */
  AllocateownershipstatusAsc = '_ALLOCATEOWNERSHIPSTATUS_ASC_',
  /** sort on AllocateOwnershipStatus descending */
  AllocateownershipstatusDesc = '_ALLOCATEOWNERSHIPSTATUS_DESC_',
  /** Sort on AllocateOwnershipStatusOPTION ascending */
  AllocateownershipstatusoptionAsc = '_ALLOCATEOWNERSHIPSTATUSOPTION_ASC_',
  /** sort on AllocateOwnershipStatusOPTION descending */
  AllocateownershipstatusoptionDesc = '_ALLOCATEOWNERSHIPSTATUSOPTION_DESC_',
  /** Sort on AllocateCreateFA ascending */
  AllocatecreatefaAsc = '_ALLOCATECREATEFA_ASC_',
  /** sort on AllocateCreateFA descending */
  AllocatecreatefaDesc = '_ALLOCATECREATEFA_DESC_',
  /** Sort on AllocateSerialNo ascending */
  AllocateserialnoAsc = '_ALLOCATESERIALNO_ASC_',
  /** sort on AllocateSerialNo descending */
  AllocateserialnoDesc = '_ALLOCATESERIALNO_DESC_',
  /** Sort on QuantitytoAllocate ascending */
  QuantitytoallocateAsc = '_QUANTITYTOALLOCATE_ASC_',
  /** sort on QuantitytoAllocate descending */
  QuantitytoallocateDesc = '_QUANTITYTOALLOCATE_DESC_',
  /** Sort on RentalDispatchType ascending */
  RentaldispatchtypeAsc = '_RENTALDISPATCHTYPE_ASC_',
  /** sort on RentalDispatchType descending */
  RentaldispatchtypeDesc = '_RENTALDISPATCHTYPE_DESC_',
  /** Sort on RentalDispatchTypeOPTION ascending */
  RentaldispatchtypeoptionAsc = '_RENTALDISPATCHTYPEOPTION_ASC_',
  /** sort on RentalDispatchTypeOPTION descending */
  RentaldispatchtypeoptionDesc = '_RENTALDISPATCHTYPEOPTION_DESC_',
  /** Sort on RentalDispatchOrderNo ascending */
  RentaldispatchordernoAsc = '_RENTALDISPATCHORDERNO_ASC_',
  /** sort on RentalDispatchOrderNo descending */
  RentaldispatchordernoDesc = '_RENTALDISPATCHORDERNO_DESC_',
  /** Sort on RentalDispatchOrderLineNo ascending */
  RentaldispatchorderlinenoAsc = '_RENTALDISPATCHORDERLINENO_ASC_',
  /** sort on RentalDispatchOrderLineNo descending */
  RentaldispatchorderlinenoDesc = '_RENTALDISPATCHORDERLINENO_DESC_',
  /** Sort on CustomerDamage ascending */
  CustomerdamageAsc = '_CUSTOMERDAMAGE_ASC_',
  /** sort on CustomerDamage descending */
  CustomerdamageDesc = '_CUSTOMERDAMAGE_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on WhseInbndOtsdgQtyBase ascending */
  WhseinbndotsdgqtybaseAsc = '_WHSEINBNDOTSDGQTYBASE_ASC_',
  /** sort on WhseInbndOtsdgQtyBase descending */
  WhseinbndotsdgqtybaseDesc = '_WHSEINBNDOTSDGQTYBASE_DESC_',
  /** Sort on WhseOutbndOtsdgQtyBase ascending */
  WhseoutbndotsdgqtybaseAsc = '_WHSEOUTBNDOTSDGQTYBASE_ASC_',
  /** sort on WhseOutbndOtsdgQtyBase descending */
  WhseoutbndotsdgqtybaseDesc = '_WHSEOUTBNDOTSDGQTYBASE_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on CompletelyReceived ascending */
  CompletelyreceivedAsc = '_COMPLETELYRECEIVED_ASC_',
  /** sort on CompletelyReceived descending */
  CompletelyreceivedDesc = '_COMPLETELYRECEIVED_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on TransferfromBinCode ascending */
  TransferfrombincodeAsc = '_TRANSFERFROMBINCODE_ASC_',
  /** sort on TransferfromBinCode descending */
  TransferfrombincodeDesc = '_TRANSFERFROMBINCODE_DESC_',
  /** Sort on TransferToBinCode ascending */
  TransfertobincodeAsc = '_TRANSFERTOBINCODE_ASC_',
  /** sort on TransferToBinCode descending */
  TransfertobincodeDesc = '_TRANSFERTOBINCODE_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmTypeFunctionalTestInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on TestIssue ascending */
  TestissueAsc = '_TESTISSUE_ASC_',
  /** sort on TestIssue descending */
  TestissueDesc = '_TESTISSUE_DESC_',
  /** Sort on AdditionalInstructions ascending */
  AdditionalinstructionsAsc = '_ADDITIONALINSTRUCTIONS_ASC_',
  /** sort on AdditionalInstructions descending */
  AdditionalinstructionsDesc = '_ADDITIONALINSTRUCTIONS_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on NAAllowed ascending */
  NaallowedAsc = '_NAALLOWED_ASC_',
  /** sort on NAAllowed descending */
  NaallowedDesc = '_NAALLOWED_DESC_',
  /** Sort on ObjectTypeId ascending */
  ObjecttypeidAsc = '_OBJECTTYPEID_ASC_',
  /** sort on ObjectTypeId descending */
  ObjecttypeidDesc = '_OBJECTTYPEID_DESC_',
  /** Sort on NBAESHeadline ascending */
  NbaesheadlineAsc = '_NBAESHEADLINE_ASC_',
  /** sort on NBAESHeadline descending */
  NbaesheadlineDesc = '_NBAESHEADLINE_DESC_',
  /** Sort on NBAESSkillReq ascending */
  NbaesskillreqAsc = '_NBAESSKILLREQ_ASC_',
  /** sort on NBAESSkillReq descending */
  NbaesskillreqDesc = '_NBAESSKILLREQ_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmWorkHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on FunctionalTestNo ascending */
  FunctionaltestnoAsc = '_FUNCTIONALTESTNO_ASC_',
  /** sort on FunctionalTestNo descending */
  FunctionaltestnoDesc = '_FUNCTIONALTESTNO_DESC_',
  /** Sort on NoFailedTestIssues ascending */
  NofailedtestissuesAsc = '_NOFAILEDTESTISSUES_ASC_',
  /** sort on NoFailedTestIssues descending */
  NofailedtestissuesDesc = '_NOFAILEDTESTISSUES_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on InternalCustomerNo ascending */
  InternalcustomernoAsc = '_INTERNALCUSTOMERNO_ASC_',
  /** sort on InternalCustomerNo descending */
  InternalcustomernoDesc = '_INTERNALCUSTOMERNO_DESC_',
  /** Sort on ContactNo ascending */
  ContactnoAsc = '_CONTACTNO_ASC_',
  /** sort on ContactNo descending */
  ContactnoDesc = '_CONTACTNO_DESC_',
  /** Sort on ResourceNo ascending */
  ResourcenoAsc = '_RESOURCENO_ASC_',
  /** sort on ResourceNo descending */
  ResourcenoDesc = '_RESOURCENO_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on EQMServiceCardNo ascending */
  EqmservicecardnoAsc = '_EQMSERVICECARDNO_ASC_',
  /** sort on EQMServiceCardNo descending */
  EqmservicecardnoDesc = '_EQMSERVICECARDNO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on ObjectSerialNo ascending */
  ObjectserialnoAsc = '_OBJECTSERIALNO_ASC_',
  /** sort on ObjectSerialNo descending */
  ObjectserialnoDesc = '_OBJECTSERIALNO_DESC_',
  /** Sort on ObjectCounter ascending */
  ObjectcounterAsc = '_OBJECTCOUNTER_ASC_',
  /** sort on ObjectCounter descending */
  ObjectcounterDesc = '_OBJECTCOUNTER_DESC_',
  /** Sort on ExternalWorkDescription ascending */
  ExternalworkdescriptionAsc = '_EXTERNALWORKDESCRIPTION_ASC_',
  /** sort on ExternalWorkDescription descending */
  ExternalworkdescriptionDesc = '_EXTERNALWORKDESCRIPTION_DESC_',
  /** Sort on InternalWorkDescription ascending */
  InternalworkdescriptionAsc = '_INTERNALWORKDESCRIPTION_ASC_',
  /** sort on InternalWorkDescription descending */
  InternalworkdescriptionDesc = '_INTERNALWORKDESCRIPTION_DESC_',
  /** Sort on FromContractNo ascending */
  FromcontractnoAsc = '_FROMCONTRACTNO_ASC_',
  /** sort on FromContractNo descending */
  FromcontractnoDesc = '_FROMCONTRACTNO_DESC_',
  /** Sort on FromContractLineNo ascending */
  FromcontractlinenoAsc = '_FROMCONTRACTLINENO_ASC_',
  /** sort on FromContractLineNo descending */
  FromcontractlinenoDesc = '_FROMCONTRACTLINENO_DESC_',
  /** Sort on FromCustomerNo ascending */
  FromcustomernoAsc = '_FROMCUSTOMERNO_ASC_',
  /** sort on FromCustomerNo descending */
  FromcustomernoDesc = '_FROMCUSTOMERNO_DESC_',
  /** Sort on FromCustomerProjectNo ascending */
  FromcustomerprojectnoAsc = '_FROMCUSTOMERPROJECTNO_ASC_',
  /** sort on FromCustomerProjectNo descending */
  FromcustomerprojectnoDesc = '_FROMCUSTOMERPROJECTNO_DESC_',
  /** Sort on FromCollectionDocNo ascending */
  FromcollectiondocnoAsc = '_FROMCOLLECTIONDOCNO_ASC_',
  /** sort on FromCollectionDocNo descending */
  FromcollectiondocnoDesc = '_FROMCOLLECTIONDOCNO_DESC_',
  /** Sort on FromCollectionLineNo ascending */
  FromcollectionlinenoAsc = '_FROMCOLLECTIONLINENO_ASC_',
  /** sort on FromCollectionLineNo descending */
  FromcollectionlinenoDesc = '_FROMCOLLECTIONLINENO_DESC_',
  /** Sort on FromPostedCollDocNo ascending */
  FrompostedcolldocnoAsc = '_FROMPOSTEDCOLLDOCNO_ASC_',
  /** sort on FromPostedCollDocNo descending */
  FrompostedcolldocnoDesc = '_FROMPOSTEDCOLLDOCNO_DESC_',
  /** Sort on FromPostedCollLineNo ascending */
  FrompostedcolllinenoAsc = '_FROMPOSTEDCOLLLINENO_ASC_',
  /** sort on FromPostedCollLineNo descending */
  FrompostedcolllinenoDesc = '_FROMPOSTEDCOLLLINENO_DESC_',
  /** Sort on FromReturnDocumentNo ascending */
  FromreturndocumentnoAsc = '_FROMRETURNDOCUMENTNO_ASC_',
  /** sort on FromReturnDocumentNo descending */
  FromreturndocumentnoDesc = '_FROMRETURNDOCUMENTNO_DESC_',
  /** Sort on FromCostCenter ascending */
  FromcostcenterAsc = '_FROMCOSTCENTER_ASC_',
  /** sort on FromCostCenter descending */
  FromcostcenterDesc = '_FROMCOSTCENTER_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on AssignedEmployeeNo ascending */
  AssignedemployeenoAsc = '_ASSIGNEDEMPLOYEENO_ASC_',
  /** sort on AssignedEmployeeNo descending */
  AssignedemployeenoDesc = '_ASSIGNEDEMPLOYEENO_DESC_',
  /** Sort on RepairCode ascending */
  RepaircodeAsc = '_REPAIRCODE_ASC_',
  /** sort on RepairCode descending */
  RepaircodeDesc = '_REPAIRCODE_DESC_',
  /** Sort on NextCertification ascending */
  NextcertificationAsc = '_NEXTCERTIFICATION_ASC_',
  /** sort on NextCertification descending */
  NextcertificationDesc = '_NEXTCERTIFICATION_DESC_',
  /** Sort on NextService ascending */
  NextserviceAsc = '_NEXTSERVICE_ASC_',
  /** sort on NextService descending */
  NextserviceDesc = '_NEXTSERVICE_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on ChargeType ascending */
  ChargetypeAsc = '_CHARGETYPE_ASC_',
  /** sort on ChargeType descending */
  ChargetypeDesc = '_CHARGETYPE_DESC_',
  /** Sort on ChargeTypeOPTION ascending */
  ChargetypeoptionAsc = '_CHARGETYPEOPTION_ASC_',
  /** sort on ChargeTypeOPTION descending */
  ChargetypeoptionDesc = '_CHARGETYPEOPTION_DESC_',
  /** Sort on ChargeDocType ascending */
  ChargedoctypeAsc = '_CHARGEDOCTYPE_ASC_',
  /** sort on ChargeDocType descending */
  ChargedoctypeDesc = '_CHARGEDOCTYPE_DESC_',
  /** Sort on ChargeDocTypeOPTION ascending */
  ChargedoctypeoptionAsc = '_CHARGEDOCTYPEOPTION_ASC_',
  /** sort on ChargeDocTypeOPTION descending */
  ChargedoctypeoptionDesc = '_CHARGEDOCTYPEOPTION_DESC_',
  /** Sort on ChargeDocNo ascending */
  ChargedocnoAsc = '_CHARGEDOCNO_ASC_',
  /** sort on ChargeDocNo descending */
  ChargedocnoDesc = '_CHARGEDOCNO_DESC_',
  /** Sort on ReceivingRespCenter ascending */
  ReceivingrespcenterAsc = '_RECEIVINGRESPCENTER_ASC_',
  /** sort on ReceivingRespCenter descending */
  ReceivingrespcenterDesc = '_RECEIVINGRESPCENTER_DESC_',
  /** Sort on ReceivingCostCenterCode ascending */
  ReceivingcostcentercodeAsc = '_RECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on ReceivingCostCenterCode descending */
  ReceivingcostcentercodeDesc = '_RECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on ReceivingLocationCode ascending */
  ReceivinglocationcodeAsc = '_RECEIVINGLOCATIONCODE_ASC_',
  /** sort on ReceivingLocationCode descending */
  ReceivinglocationcodeDesc = '_RECEIVINGLOCATIONCODE_DESC_',
  /** Sort on CombineInvoices ascending */
  CombineinvoicesAsc = '_COMBINEINVOICES_ASC_',
  /** sort on CombineInvoices descending */
  CombineinvoicesDesc = '_COMBINEINVOICES_DESC_',
  /** Sort on CombineMethod ascending */
  CombinemethodAsc = '_COMBINEMETHOD_ASC_',
  /** sort on CombineMethod descending */
  CombinemethodDesc = '_COMBINEMETHOD_DESC_',
  /** Sort on CombineMethodOPTION ascending */
  CombinemethodoptionAsc = '_COMBINEMETHODOPTION_ASC_',
  /** sort on CombineMethodOPTION descending */
  CombinemethodoptionDesc = '_COMBINEMETHODOPTION_DESC_',
  /** Sort on CombineInvperRespCenter ascending */
  CombineinvperrespcenterAsc = '_COMBINEINVPERRESPCENTER_ASC_',
  /** sort on CombineInvperRespCenter descending */
  CombineinvperrespcenterDesc = '_COMBINEINVPERRESPCENTER_DESC_',
  /** Sort on PlannedStartDate ascending */
  PlannedstartdateAsc = '_PLANNEDSTARTDATE_ASC_',
  /** sort on PlannedStartDate descending */
  PlannedstartdateDesc = '_PLANNEDSTARTDATE_DESC_',
  /** Sort on PlannedStartTime ascending */
  PlannedstarttimeAsc = '_PLANNEDSTARTTIME_ASC_',
  /** sort on PlannedStartTime descending */
  PlannedstarttimeDesc = '_PLANNEDSTARTTIME_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on StartTime ascending */
  StarttimeAsc = '_STARTTIME_ASC_',
  /** sort on StartTime descending */
  StarttimeDesc = '_STARTTIME_DESC_',
  /** Sort on CompletedDate ascending */
  CompleteddateAsc = '_COMPLETEDDATE_ASC_',
  /** sort on CompletedDate descending */
  CompleteddateDesc = '_COMPLETEDDATE_DESC_',
  /** Sort on CompletedTime ascending */
  CompletedtimeAsc = '_COMPLETEDTIME_ASC_',
  /** sort on CompletedTime descending */
  CompletedtimeDesc = '_COMPLETEDTIME_DESC_',
  /** Sort on ApprovedbyUser ascending */
  ApprovedbyuserAsc = '_APPROVEDBYUSER_ASC_',
  /** sort on ApprovedbyUser descending */
  ApprovedbyuserDesc = '_APPROVEDBYUSER_DESC_',
  /** Sort on AllowPartialInvoicing ascending */
  AllowpartialinvoicingAsc = '_ALLOWPARTIALINVOICING_ASC_',
  /** sort on AllowPartialInvoicing descending */
  AllowpartialinvoicingDesc = '_ALLOWPARTIALINVOICING_DESC_',
  /** Sort on ExternalCustomerPriceGroup ascending */
  ExternalcustomerpricegroupAsc = '_EXTERNALCUSTOMERPRICEGROUP_ASC_',
  /** sort on ExternalCustomerPriceGroup descending */
  ExternalcustomerpricegroupDesc = '_EXTERNALCUSTOMERPRICEGROUP_DESC_',
  /** Sort on ExternalCustomerDiscGroup ascending */
  ExternalcustomerdiscgroupAsc = '_EXTERNALCUSTOMERDISCGROUP_ASC_',
  /** sort on ExternalCustomerDiscGroup descending */
  ExternalcustomerdiscgroupDesc = '_EXTERNALCUSTOMERDISCGROUP_DESC_',
  /** Sort on InternalCustomerPriceGroup ascending */
  InternalcustomerpricegroupAsc = '_INTERNALCUSTOMERPRICEGROUP_ASC_',
  /** sort on InternalCustomerPriceGroup descending */
  InternalcustomerpricegroupDesc = '_INTERNALCUSTOMERPRICEGROUP_DESC_',
  /** Sort on InternalCustomerDiscGroup ascending */
  InternalcustomerdiscgroupAsc = '_INTERNALCUSTOMERDISCGROUP_ASC_',
  /** sort on InternalCustomerDiscGroup descending */
  InternalcustomerdiscgroupDesc = '_INTERNALCUSTOMERDISCGROUP_DESC_',
  /** Sort on CustomerDamage ascending */
  CustomerdamageAsc = '_CUSTOMERDAMAGE_ASC_',
  /** sort on CustomerDamage descending */
  CustomerdamageDesc = '_CUSTOMERDAMAGE_DESC_',
  /** Sort on Pictures ascending */
  PicturesAsc = '_PICTURES_ASC_',
  /** sort on Pictures descending */
  PicturesDesc = '_PICTURES_DESC_',
  /** Sort on UnpostedWorkOrderLines ascending */
  UnpostedworkorderlinesAsc = '_UNPOSTEDWORKORDERLINES_ASC_',
  /** sort on UnpostedWorkOrderLines descending */
  UnpostedworkorderlinesDesc = '_UNPOSTEDWORKORDERLINES_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortEqmWorkLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on LineStatus ascending */
  LinestatusAsc = '_LINESTATUS_ASC_',
  /** sort on LineStatus descending */
  LinestatusDesc = '_LINESTATUS_DESC_',
  /** Sort on LineStatusOPTION ascending */
  LinestatusoptionAsc = '_LINESTATUSOPTION_ASC_',
  /** sort on LineStatusOPTION descending */
  LinestatusoptionDesc = '_LINESTATUSOPTION_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on OverruledUnitPrice ascending */
  OverruledunitpriceAsc = '_OVERRULEDUNITPRICE_ASC_',
  /** sort on OverruledUnitPrice descending */
  OverruledunitpriceDesc = '_OVERRULEDUNITPRICE_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on ExternalCustomerPriceGroup ascending */
  ExternalcustomerpricegroupAsc = '_EXTERNALCUSTOMERPRICEGROUP_ASC_',
  /** sort on ExternalCustomerPriceGroup descending */
  ExternalcustomerpricegroupDesc = '_EXTERNALCUSTOMERPRICEGROUP_DESC_',
  /** Sort on ExternalCustomerDiscGroup ascending */
  ExternalcustomerdiscgroupAsc = '_EXTERNALCUSTOMERDISCGROUP_ASC_',
  /** sort on ExternalCustomerDiscGroup descending */
  ExternalcustomerdiscgroupDesc = '_EXTERNALCUSTOMERDISCGROUP_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on ChargeType ascending */
  ChargetypeAsc = '_CHARGETYPE_ASC_',
  /** sort on ChargeType descending */
  ChargetypeDesc = '_CHARGETYPE_DESC_',
  /** Sort on ChargeTypeOPTION ascending */
  ChargetypeoptionAsc = '_CHARGETYPEOPTION_ASC_',
  /** sort on ChargeTypeOPTION descending */
  ChargetypeoptionDesc = '_CHARGETYPEOPTION_DESC_',
  /** Sort on ChargeDocType ascending */
  ChargedoctypeAsc = '_CHARGEDOCTYPE_ASC_',
  /** sort on ChargeDocType descending */
  ChargedoctypeDesc = '_CHARGEDOCTYPE_DESC_',
  /** Sort on ChargeDocTypeOPTION ascending */
  ChargedoctypeoptionAsc = '_CHARGEDOCTYPEOPTION_ASC_',
  /** sort on ChargeDocTypeOPTION descending */
  ChargedoctypeoptionDesc = '_CHARGEDOCTYPEOPTION_DESC_',
  /** Sort on ChargeDocNo ascending */
  ChargedocnoAsc = '_CHARGEDOCNO_ASC_',
  /** sort on ChargeDocNo descending */
  ChargedocnoDesc = '_CHARGEDOCNO_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on Transferred ascending */
  TransferredAsc = '_TRANSFERRED_ASC_',
  /** sort on Transferred descending */
  TransferredDesc = '_TRANSFERRED_DESC_',
  /** Sort on TransferredtoDocNo ascending */
  TransferredtodocnoAsc = '_TRANSFERREDTODOCNO_ASC_',
  /** sort on TransferredtoDocNo descending */
  TransferredtodocnoDesc = '_TRANSFERREDTODOCNO_DESC_',
  /** Sort on TransferredtoDocLineNo ascending */
  TransferredtodoclinenoAsc = '_TRANSFERREDTODOCLINENO_ASC_',
  /** sort on TransferredtoDocLineNo descending */
  TransferredtodoclinenoDesc = '_TRANSFERREDTODOCLINENO_DESC_',
  /** Sort on PostedSalesLine ascending */
  PostedsaleslineAsc = '_POSTEDSALESLINE_ASC_',
  /** sort on PostedSalesLine descending */
  PostedsaleslineDesc = '_POSTEDSALESLINE_DESC_',
  /** Sort on InternalCustomerPriceGroup ascending */
  InternalcustomerpricegroupAsc = '_INTERNALCUSTOMERPRICEGROUP_ASC_',
  /** sort on InternalCustomerPriceGroup descending */
  InternalcustomerpricegroupDesc = '_INTERNALCUSTOMERPRICEGROUP_DESC_',
  /** Sort on InternalCustomerDiscGroup ascending */
  InternalcustomerdiscgroupAsc = '_INTERNALCUSTOMERDISCGROUP_ASC_',
  /** sort on InternalCustomerDiscGroup descending */
  InternalcustomerdiscgroupDesc = '_INTERNALCUSTOMERDISCGROUP_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on CreatedfromAPI ascending */
  CreatedfromapiAsc = '_CREATEDFROMAPI_ASC_',
  /** sort on CreatedfromAPI descending */
  CreatedfromapiDesc = '_CREATEDFROMAPI_DESC_',
  /** Sort on PatchedfromAPI ascending */
  PatchedfromapiAsc = '_PATCHEDFROMAPI_ASC_',
  /** sort on PatchedfromAPI descending */
  PatchedfromapiDesc = '_PATCHEDFROMAPI_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortErrandInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Header ascending */
  HeaderAsc = '_HEADER_ASC_',
  /** sort on Header descending */
  HeaderDesc = '_HEADER_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on ErrandType ascending */
  ErrandtypeAsc = '_ERRANDTYPE_ASC_',
  /** sort on ErrandType descending */
  ErrandtypeDesc = '_ERRANDTYPE_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on Email ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on Email descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on Dim1 ascending */
  Dim1Asc = '_DIM1_ASC_',
  /** sort on Dim1 descending */
  Dim1Desc = '_DIM1_DESC_',
  /** Sort on Dim2 ascending */
  Dim2Asc = '_DIM2_ASC_',
  /** sort on Dim2 descending */
  Dim2Desc = '_DIM2_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on ActionBlob ascending */
  ActionblobAsc = '_ACTIONBLOB_ASC_',
  /** sort on ActionBlob descending */
  ActionblobDesc = '_ACTIONBLOB_DESC_',
  /** Sort on MainSource ascending */
  MainsourceAsc = '_MAINSOURCE_ASC_',
  /** sort on MainSource descending */
  MainsourceDesc = '_MAINSOURCE_DESC_',
  /** Sort on CreatedFromRecord ascending */
  CreatedfromrecordAsc = '_CREATEDFROMRECORD_ASC_',
  /** sort on CreatedFromRecord descending */
  CreatedfromrecordDesc = '_CREATEDFROMRECORD_DESC_',
  /** Sort on TimeCost ascending */
  TimecostAsc = '_TIMECOST_ASC_',
  /** sort on TimeCost descending */
  TimecostDesc = '_TIMECOST_DESC_',
  /** Sort on Cost ascending */
  CostAsc = '_COST_ASC_',
  /** sort on Cost descending */
  CostDesc = '_COST_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortErrandLineInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ConnectedToRecord ascending */
  ConnectedtorecordAsc = '_CONNECTEDTORECORD_ASC_',
  /** sort on ConnectedToRecord descending */
  ConnectedtorecordDesc = '_CONNECTEDTORECORD_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortErrorReportInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on ReasonText ascending */
  ReasontextAsc = '_REASONTEXT_ASC_',
  /** sort on ReasonText descending */
  ReasontextDesc = '_REASONTEXT_DESC_',
  /** Sort on DateTime ascending */
  DatetimeAsc = '_DATETIME_ASC_',
  /** sort on DateTime descending */
  DatetimeDesc = '_DATETIME_DESC_',
  /** Sort on Caller ascending */
  CallerAsc = '_CALLER_ASC_',
  /** sort on Caller descending */
  CallerDesc = '_CALLER_DESC_',
  /** Sort on CallerPhone ascending */
  CallerphoneAsc = '_CALLERPHONE_ASC_',
  /** sort on CallerPhone descending */
  CallerphoneDesc = '_CALLERPHONE_DESC_',
  /** Sort on CallerEmail ascending */
  CalleremailAsc = '_CALLEREMAIL_ASC_',
  /** sort on CallerEmail descending */
  CalleremailDesc = '_CALLEREMAIL_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ActionCode ascending */
  ActioncodeAsc = '_ACTIONCODE_ASC_',
  /** sort on ActionCode descending */
  ActioncodeDesc = '_ACTIONCODE_DESC_',
  /** Sort on ActionText ascending */
  ActiontextAsc = '_ACTIONTEXT_ASC_',
  /** sort on ActionText descending */
  ActiontextDesc = '_ACTIONTEXT_DESC_',
  /** Sort on CurrentHandler ascending */
  CurrenthandlerAsc = '_CURRENTHANDLER_ASC_',
  /** sort on CurrentHandler descending */
  CurrenthandlerDesc = '_CURRENTHANDLER_DESC_',
  /** Sort on CurrentHandlerPhone ascending */
  CurrenthandlerphoneAsc = '_CURRENTHANDLERPHONE_ASC_',
  /** sort on CurrentHandlerPhone descending */
  CurrenthandlerphoneDesc = '_CURRENTHANDLERPHONE_DESC_',
  /** Sort on CurrentHandlerEmail ascending */
  CurrenthandleremailAsc = '_CURRENTHANDLEREMAIL_ASC_',
  /** sort on CurrentHandlerEmail descending */
  CurrenthandleremailDesc = '_CURRENTHANDLEREMAIL_DESC_',
  /** Sort on TurnedOverTo ascending */
  TurnedovertoAsc = '_TURNEDOVERTO_ASC_',
  /** sort on TurnedOverTo descending */
  TurnedovertoDesc = '_TURNEDOVERTO_DESC_',
  /** Sort on Recived ascending */
  RecivedAsc = '_RECIVED_ASC_',
  /** sort on Recived descending */
  RecivedDesc = '_RECIVED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFeedbackInput {
  /** Sort on Text ascending */
  TextAsc = '_TEXT_ASC_',
  /** sort on Text descending */
  TextDesc = '_TEXT_DESC_',
  /** Sort on ByUserEmail ascending */
  ByuseremailAsc = '_BYUSEREMAIL_ASC_',
  /** sort on ByUserEmail descending */
  ByuseremailDesc = '_BYUSEREMAIL_DESC_',
  /** Sort on ByUserName ascending */
  ByusernameAsc = '_BYUSERNAME_ASC_',
  /** sort on ByUserName descending */
  ByusernameDesc = '_BYUSERNAME_DESC_',
  /** Sort on CreatedOn ascending */
  CreatedonAsc = '_CREATEDON_ASC_',
  /** sort on CreatedOn descending */
  CreatedonDesc = '_CREATEDON_DESC_',
  /** Sort on Processed ascending */
  ProcessedAsc = '_PROCESSED_ASC_',
  /** sort on Processed descending */
  ProcessedDesc = '_PROCESSED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFeemappingInput {
  /** Sort on LCYPrice ascending */
  LcypriceAsc = '_LCYPRICE_ASC_',
  /** sort on LCYPrice descending */
  LcypriceDesc = '_LCYPRICE_DESC_',
  /** Sort on LCYPriceIncVat ascending */
  LcypriceincvatAsc = '_LCYPRICEINCVAT_ASC_',
  /** sort on LCYPriceIncVat descending */
  LcypriceincvatDesc = '_LCYPRICEINCVAT_DESC_',
  /** Sort on EURPrice ascending */
  EurpriceAsc = '_EURPRICE_ASC_',
  /** sort on EURPrice descending */
  EurpriceDesc = '_EURPRICE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Unit ascending */
  UnitAsc = '_UNIT_ASC_',
  /** sort on Unit descending */
  UnitDesc = '_UNIT_DESC_',
  /** Sort on Fee ascending */
  FeeAsc = '_FEE_ASC_',
  /** sort on Fee descending */
  FeeDesc = '_FEE_DESC_',
  /** Sort on PriceType ascending */
  PricetypeAsc = '_PRICETYPE_ASC_',
  /** sort on PriceType descending */
  PricetypeDesc = '_PRICETYPE_DESC_',
  /** Sort on TypeOfFee ascending */
  TypeoffeeAsc = '_TYPEOFFEE_ASC_',
  /** sort on TypeOfFee descending */
  TypeoffeeDesc = '_TYPEOFFEE_DESC_',
  /** Sort on FeeItemNumber ascending */
  FeeitemnumberAsc = '_FEEITEMNUMBER_ASC_',
  /** sort on FeeItemNumber descending */
  FeeitemnumberDesc = '_FEEITEMNUMBER_DESC_',
  /** Sort on BaseQuantity ascending */
  BasequantityAsc = '_BASEQUANTITY_ASC_',
  /** sort on BaseQuantity descending */
  BasequantityDesc = '_BASEQUANTITY_DESC_',
  /** Sort on WebDescription ascending */
  WebdescriptionAsc = '_WEBDESCRIPTION_ASC_',
  /** sort on WebDescription descending */
  WebdescriptionDesc = '_WEBDESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFieldOrderInput {
  /** Sort on FieldOrderCode ascending */
  FieldordercodeAsc = '_FIELDORDERCODE_ASC_',
  /** sort on FieldOrderCode descending */
  FieldordercodeDesc = '_FIELDORDERCODE_DESC_',
  /** Sort on ExecutionTime ascending */
  ExecutiontimeAsc = '_EXECUTIONTIME_ASC_',
  /** sort on ExecutionTime descending */
  ExecutiontimeDesc = '_EXECUTIONTIME_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on WorkDescription ascending */
  WorkdescriptionAsc = '_WORKDESCRIPTION_ASC_',
  /** sort on WorkDescription descending */
  WorkdescriptionDesc = '_WORKDESCRIPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on AssignmentType ascending */
  AssignmenttypeAsc = '_ASSIGNMENTTYPE_ASC_',
  /** sort on AssignmentType descending */
  AssignmenttypeDesc = '_ASSIGNMENTTYPE_DESC_',
  /** Sort on AssignmentTypeOPTION ascending */
  AssignmenttypeoptionAsc = '_ASSIGNMENTTYPEOPTION_ASC_',
  /** sort on AssignmentTypeOPTION descending */
  AssignmenttypeoptionDesc = '_ASSIGNMENTTYPEOPTION_DESC_',
  /** Sort on AssignedTo ascending */
  AssignedtoAsc = '_ASSIGNEDTO_ASC_',
  /** sort on AssignedTo descending */
  AssignedtoDesc = '_ASSIGNEDTO_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PostedInvoiceNo ascending */
  PostedinvoicenoAsc = '_POSTEDINVOICENO_ASC_',
  /** sort on PostedInvoiceNo descending */
  PostedinvoicenoDesc = '_POSTEDINVOICENO_DESC_',
  /** Sort on ReversedVAT ascending */
  ReversedvatAsc = '_REVERSEDVAT_ASC_',
  /** sort on ReversedVAT descending */
  ReversedvatDesc = '_REVERSEDVAT_DESC_',
  /** Sort on ContactNo ascending */
  ContactnoAsc = '_CONTACTNO_ASC_',
  /** sort on ContactNo descending */
  ContactnoDesc = '_CONTACTNO_DESC_',
  /** Sort on ContactPhone ascending */
  ContactphoneAsc = '_CONTACTPHONE_ASC_',
  /** sort on ContactPhone descending */
  ContactphoneDesc = '_CONTACTPHONE_DESC_',
  /** Sort on Instructions ascending */
  InstructionsAsc = '_INSTRUCTIONS_ASC_',
  /** sort on Instructions descending */
  InstructionsDesc = '_INSTRUCTIONS_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on NoteFromPreformer ascending */
  NotefrompreformerAsc = '_NOTEFROMPREFORMER_ASC_',
  /** sort on NoteFromPreformer descending */
  NotefrompreformerDesc = '_NOTEFROMPREFORMER_DESC_',
  /** Sort on ObjectDescription ascending */
  ObjectdescriptionAsc = '_OBJECTDESCRIPTION_ASC_',
  /** sort on ObjectDescription descending */
  ObjectdescriptionDesc = '_OBJECTDESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFieldOrderLineInput {
  /** Sort on FieldOrderCode ascending */
  FieldordercodeAsc = '_FIELDORDERCODE_ASC_',
  /** sort on FieldOrderCode descending */
  FieldordercodeDesc = '_FIELDORDERCODE_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFileInput {
  /** Sort on link ascending */
  LinkAsc = '_LINK_ASC_',
  /** sort on link descending */
  LinkDesc = '_LINK_DESC_',
  /** Sort on name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on etag ascending */
  EtagAsc = '_ETAG_ASC_',
  /** sort on etag descending */
  EtagDesc = '_ETAG_DESC_',
  /** Sort on base64 ascending */
  Base64Asc = '_BASE64_ASC_',
  /** sort on base64 descending */
  Base64Desc = '_BASE64_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFunctionTestHeaderInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Resource ascending */
  ResourceAsc = '_RESOURCE_ASC_',
  /** sort on Resource descending */
  ResourceDesc = '_RESOURCE_DESC_',
  /** Sort on CreateDate ascending */
  CreatedateAsc = '_CREATEDATE_ASC_',
  /** sort on CreateDate descending */
  CreatedateDesc = '_CREATEDATE_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on FinishDate ascending */
  FinishdateAsc = '_FINISHDATE_ASC_',
  /** sort on FinishDate descending */
  FinishdateDesc = '_FINISHDATE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ApprovalStatus ascending */
  ApprovalstatusAsc = '_APPROVALSTATUS_ASC_',
  /** sort on ApprovalStatus descending */
  ApprovalstatusDesc = '_APPROVALSTATUS_DESC_',
  /** Sort on ApprovalStatusOPTION ascending */
  ApprovalstatusoptionAsc = '_APPROVALSTATUSOPTION_ASC_',
  /** sort on ApprovalStatusOPTION descending */
  ApprovalstatusoptionDesc = '_APPROVALSTATUSOPTION_DESC_',
  /** Sort on EQMServiceHeader ascending */
  EqmserviceheaderAsc = '_EQMSERVICEHEADER_ASC_',
  /** sort on EQMServiceHeader descending */
  EqmserviceheaderDesc = '_EQMSERVICEHEADER_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on FromContractNo ascending */
  FromcontractnoAsc = '_FROMCONTRACTNO_ASC_',
  /** sort on FromContractNo descending */
  FromcontractnoDesc = '_FROMCONTRACTNO_DESC_',
  /** Sort on FromCustomerNo ascending */
  FromcustomernoAsc = '_FROMCUSTOMERNO_ASC_',
  /** sort on FromCustomerNo descending */
  FromcustomernoDesc = '_FROMCUSTOMERNO_DESC_',
  /** Sort on FromReturnDocumentNo ascending */
  FromreturndocumentnoAsc = '_FROMRETURNDOCUMENTNO_ASC_',
  /** sort on FromReturnDocumentNo descending */
  FromreturndocumentnoDesc = '_FROMRETURNDOCUMENTNO_DESC_',
  /** Sort on FromCollectionDocNo ascending */
  FromcollectiondocnoAsc = '_FROMCOLLECTIONDOCNO_ASC_',
  /** sort on FromCollectionDocNo descending */
  FromcollectiondocnoDesc = '_FROMCOLLECTIONDOCNO_DESC_',
  /** Sort on CostCenter ascending */
  CostcenterAsc = '_COSTCENTER_ASC_',
  /** sort on CostCenter descending */
  CostcenterDesc = '_COSTCENTER_DESC_',
  /** Sort on DimCode ascending */
  DimcodeAsc = '_DIMCODE_ASC_',
  /** sort on DimCode descending */
  DimcodeDesc = '_DIMCODE_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on StartTime ascending */
  StarttimeAsc = '_STARTTIME_ASC_',
  /** sort on StartTime descending */
  StarttimeDesc = '_STARTTIME_DESC_',
  /** Sort on FinishTime ascending */
  FinishtimeAsc = '_FINISHTIME_ASC_',
  /** sort on FinishTime descending */
  FinishtimeDesc = '_FINISHTIME_DESC_',
  /** Sort on Signature ascending */
  SignatureAsc = '_SIGNATURE_ASC_',
  /** sort on Signature descending */
  SignatureDesc = '_SIGNATURE_DESC_',
  /** Sort on FinishedBy ascending */
  FinishedbyAsc = '_FINISHEDBY_ASC_',
  /** sort on FinishedBy descending */
  FinishedbyDesc = '_FINISHEDBY_DESC_',
  /** Sort on TypeOfWash ascending */
  TypeofwashAsc = '_TYPEOFWASH_ASC_',
  /** sort on TypeOfWash descending */
  TypeofwashDesc = '_TYPEOFWASH_DESC_',
  /** Sort on ExternalPart ascending */
  ExternalpartAsc = '_EXTERNALPART_ASC_',
  /** sort on ExternalPart descending */
  ExternalpartDesc = '_EXTERNALPART_DESC_',
  /** Sort on FncFailed ascending */
  FncfailedAsc = '_FNCFAILED_ASC_',
  /** sort on FncFailed descending */
  FncfailedDesc = '_FNCFAILED_DESC_',
  /** Sort on HourMeterIndicator ascending */
  HourmeterindicatorAsc = '_HOURMETERINDICATOR_ASC_',
  /** sort on HourMeterIndicator descending */
  HourmeterindicatorDesc = '_HOURMETERINDICATOR_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on FromCustomerName ascending */
  FromcustomernameAsc = '_FROMCUSTOMERNAME_ASC_',
  /** sort on FromCustomerName descending */
  FromcustomernameDesc = '_FROMCUSTOMERNAME_DESC_',
  /** Sort on ObjectDescription ascending */
  ObjectdescriptionAsc = '_OBJECTDESCRIPTION_ASC_',
  /** sort on ObjectDescription descending */
  ObjectdescriptionDesc = '_OBJECTDESCRIPTION_DESC_',
  /** Sort on WarrantyDate ascending */
  WarrantydateAsc = '_WARRANTYDATE_ASC_',
  /** sort on WarrantyDate descending */
  WarrantydateDesc = '_WARRANTYDATE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on SignatureFromEdge ascending */
  SignaturefromedgeAsc = '_SIGNATUREFROMEDGE_ASC_',
  /** sort on SignatureFromEdge descending */
  SignaturefromedgeDesc = '_SIGNATUREFROMEDGE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortFunctionTestLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on TestIssue ascending */
  TestissueAsc = '_TESTISSUE_ASC_',
  /** sort on TestIssue descending */
  TestissueDesc = '_TESTISSUE_DESC_',
  /** Sort on CurrentStatus ascending */
  CurrentstatusAsc = '_CURRENTSTATUS_ASC_',
  /** sort on CurrentStatus descending */
  CurrentstatusDesc = '_CURRENTSTATUS_DESC_',
  /** Sort on AdditionalInstructions ascending */
  AdditionalinstructionsAsc = '_ADDITIONALINSTRUCTIONS_ASC_',
  /** sort on AdditionalInstructions descending */
  AdditionalinstructionsDesc = '_ADDITIONALINSTRUCTIONS_DESC_',
  /** Sort on SpecificMeasure ascending */
  SpecificmeasureAsc = '_SPECIFICMEASURE_ASC_',
  /** sort on SpecificMeasure descending */
  SpecificmeasureDesc = '_SPECIFICMEASURE_DESC_',
  /** Sort on DirectMeasure ascending */
  DirectmeasureAsc = '_DIRECTMEASURE_ASC_',
  /** sort on DirectMeasure descending */
  DirectmeasureDesc = '_DIRECTMEASURE_DESC_',
  /** Sort on InspectionTimeInterval ascending */
  InspectiontimeintervalAsc = '_INSPECTIONTIMEINTERVAL_ASC_',
  /** sort on InspectionTimeInterval descending */
  InspectiontimeintervalDesc = '_INSPECTIONTIMEINTERVAL_DESC_',
  /** Sort on ToCorrect ascending */
  TocorrectAsc = '_TOCORRECT_ASC_',
  /** sort on ToCorrect descending */
  TocorrectDesc = '_TOCORRECT_DESC_',
  /** Sort on WorkShopOrderNo ascending */
  WorkshopordernoAsc = '_WORKSHOPORDERNO_ASC_',
  /** sort on WorkShopOrderNo descending */
  WorkshopordernoDesc = '_WORKSHOPORDERNO_DESC_',
  /** Sort on WorkShopOrderFinished ascending */
  WorkshoporderfinishedAsc = '_WORKSHOPORDERFINISHED_ASC_',
  /** sort on WorkShopOrderFinished descending */
  WorkshoporderfinishedDesc = '_WORKSHOPORDERFINISHED_DESC_',
  /** Sort on Headline ascending */
  HeadlineAsc = '_HEADLINE_ASC_',
  /** sort on Headline descending */
  HeadlineDesc = '_HEADLINE_DESC_',
  /** Sort on NoteFunctionTest ascending */
  NotefunctiontestAsc = '_NOTEFUNCTIONTEST_ASC_',
  /** sort on NoteFunctionTest descending */
  NotefunctiontestDesc = '_NOTEFUNCTIONTEST_DESC_',
  /** Sort on NoteDate ascending */
  NotedateAsc = '_NOTEDATE_ASC_',
  /** sort on NoteDate descending */
  NotedateDesc = '_NOTEDATE_DESC_',
  /** Sort on NoteOwner ascending */
  NoteownerAsc = '_NOTEOWNER_ASC_',
  /** sort on NoteOwner descending */
  NoteownerDesc = '_NOTEOWNER_DESC_',
  /** Sort on MoveNote ascending */
  MovenoteAsc = '_MOVENOTE_ASC_',
  /** sort on MoveNote descending */
  MovenoteDesc = '_MOVENOTE_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on StartTime ascending */
  StarttimeAsc = '_STARTTIME_ASC_',
  /** sort on StartTime descending */
  StarttimeDesc = '_STARTTIME_DESC_',
  /** Sort on Mandatory ascending */
  MandatoryAsc = '_MANDATORY_ASC_',
  /** sort on Mandatory descending */
  MandatoryDesc = '_MANDATORY_DESC_',
  /** Sort on TimeEstimation ascending */
  TimeestimationAsc = '_TIMEESTIMATION_ASC_',
  /** sort on TimeEstimation descending */
  TimeestimationDesc = '_TIMEESTIMATION_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on TestedApproved ascending */
  TestedapprovedAsc = '_TESTEDAPPROVED_ASC_',
  /** sort on TestedApproved descending */
  TestedapprovedDesc = '_TESTEDAPPROVED_DESC_',
  /** Sort on TestedExecuted ascending */
  TestedexecutedAsc = '_TESTEDEXECUTED_ASC_',
  /** sort on TestedExecuted descending */
  TestedexecutedDesc = '_TESTEDEXECUTED_DESC_',
  /** Sort on ExecutedBy ascending */
  ExecutedbyAsc = '_EXECUTEDBY_ASC_',
  /** sort on ExecutedBy descending */
  ExecutedbyDesc = '_EXECUTEDBY_DESC_',
  /** Sort on ApprovedBy ascending */
  ApprovedbyAsc = '_APPROVEDBY_ASC_',
  /** sort on ApprovedBy descending */
  ApprovedbyDesc = '_APPROVEDBY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortGenBusinessPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on DefVATBusPostingGroup ascending */
  DefvatbuspostinggroupAsc = '_DEFVATBUSPOSTINGGROUP_ASC_',
  /** sort on DefVATBusPostingGroup descending */
  DefvatbuspostinggroupDesc = '_DEFVATBUSPOSTINGGROUP_DESC_',
  /** Sort on AutoInsertDefault ascending */
  AutoinsertdefaultAsc = '_AUTOINSERTDEFAULT_ASC_',
  /** sort on AutoInsertDefault descending */
  AutoinsertdefaultDesc = '_AUTOINSERTDEFAULT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortGenJournalLineInput {
  /** Sort on JournalTemplateName ascending */
  JournaltemplatenameAsc = '_JOURNALTEMPLATENAME_ASC_',
  /** sort on JournalTemplateName descending */
  JournaltemplatenameDesc = '_JOURNALTEMPLATENAME_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on AccountType ascending */
  AccounttypeAsc = '_ACCOUNTTYPE_ASC_',
  /** sort on AccountType descending */
  AccounttypeDesc = '_ACCOUNTTYPE_DESC_',
  /** Sort on AccountTypeOPTION ascending */
  AccounttypeoptionAsc = '_ACCOUNTTYPEOPTION_ASC_',
  /** sort on AccountTypeOPTION descending */
  AccounttypeoptionDesc = '_ACCOUNTTYPEOPTION_DESC_',
  /** Sort on AccountNo ascending */
  AccountnoAsc = '_ACCOUNTNO_ASC_',
  /** sort on AccountNo descending */
  AccountnoDesc = '_ACCOUNTNO_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on DebitAmount ascending */
  DebitamountAsc = '_DEBITAMOUNT_ASC_',
  /** sort on DebitAmount descending */
  DebitamountDesc = '_DEBITAMOUNT_DESC_',
  /** Sort on CreditAmount ascending */
  CreditamountAsc = '_CREDITAMOUNT_ASC_',
  /** sort on CreditAmount descending */
  CreditamountDesc = '_CREDITAMOUNT_DESC_',
  /** Sort on AmountLCY ascending */
  AmountlcyAsc = '_AMOUNTLCY_ASC_',
  /** sort on AmountLCY descending */
  AmountlcyDesc = '_AMOUNTLCY_DESC_',
  /** Sort on BalanceLCY ascending */
  BalancelcyAsc = '_BALANCELCY_ASC_',
  /** sort on BalanceLCY descending */
  BalancelcyDesc = '_BALANCELCY_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on SalesPurchLCY ascending */
  SalespurchlcyAsc = '_SALESPURCHLCY_ASC_',
  /** sort on SalesPurchLCY descending */
  SalespurchlcyDesc = '_SALESPURCHLCY_DESC_',
  /** Sort on ProfitLCY ascending */
  ProfitlcyAsc = '_PROFITLCY_ASC_',
  /** sort on ProfitLCY descending */
  ProfitlcyDesc = '_PROFITLCY_DESC_',
  /** Sort on InvDiscountLCY ascending */
  InvdiscountlcyAsc = '_INVDISCOUNTLCY_ASC_',
  /** sort on InvDiscountLCY descending */
  InvdiscountlcyDesc = '_INVDISCOUNTLCY_DESC_',
  /** Sort on BilltoPaytoNo ascending */
  BilltopaytonoAsc = '_BILLTOPAYTONO_ASC_',
  /** sort on BilltoPaytoNo descending */
  BilltopaytonoDesc = '_BILLTOPAYTONO_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on SalespersPurchCode ascending */
  SalesperspurchcodeAsc = '_SALESPERSPURCHCODE_ASC_',
  /** sort on SalespersPurchCode descending */
  SalesperspurchcodeDesc = '_SALESPERSPURCHCODE_DESC_',
  /** Sort on PendingApproval ascending */
  PendingapprovalAsc = '_PENDINGAPPROVAL_ASC_',
  /** sort on PendingApproval descending */
  PendingapprovalDesc = '_PENDINGAPPROVAL_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on VATAmount ascending */
  VatamountAsc = '_VATAMOUNT_ASC_',
  /** sort on VATAmount descending */
  VatamountDesc = '_VATAMOUNT_DESC_',
  /** Sort on VATPosting ascending */
  VatpostingAsc = '_VATPOSTING_ASC_',
  /** sort on VATPosting descending */
  VatpostingDesc = '_VATPOSTING_DESC_',
  /** Sort on VATPostingOPTION ascending */
  VatpostingoptionAsc = '_VATPOSTINGOPTION_ASC_',
  /** sort on VATPostingOPTION descending */
  VatpostingoptionDesc = '_VATPOSTINGOPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on AppliestoID ascending */
  AppliestoidAsc = '_APPLIESTOID_ASC_',
  /** sort on AppliestoID descending */
  AppliestoidDesc = '_APPLIESTOID_DESC_',
  /** Sort on BusinessUnitCode ascending */
  BusinessunitcodeAsc = '_BUSINESSUNITCODE_ASC_',
  /** sort on BusinessUnitCode descending */
  BusinessunitcodeDesc = '_BUSINESSUNITCODE_DESC_',
  /** Sort on JournalBatchName ascending */
  JournalbatchnameAsc = '_JOURNALBATCHNAME_ASC_',
  /** sort on JournalBatchName descending */
  JournalbatchnameDesc = '_JOURNALBATCHNAME_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on RecurringMethod ascending */
  RecurringmethodAsc = '_RECURRINGMETHOD_ASC_',
  /** sort on RecurringMethod descending */
  RecurringmethodDesc = '_RECURRINGMETHOD_DESC_',
  /** Sort on RecurringMethodOPTION ascending */
  RecurringmethodoptionAsc = '_RECURRINGMETHODOPTION_ASC_',
  /** sort on RecurringMethodOPTION descending */
  RecurringmethodoptionDesc = '_RECURRINGMETHODOPTION_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on RecurringFrequency ascending */
  RecurringfrequencyAsc = '_RECURRINGFREQUENCY_ASC_',
  /** sort on RecurringFrequency descending */
  RecurringfrequencyDesc = '_RECURRINGFREQUENCY_DESC_',
  /** Sort on AllocatedAmtLCY ascending */
  AllocatedamtlcyAsc = '_ALLOCATEDAMTLCY_ASC_',
  /** sort on AllocatedAmtLCY descending */
  AllocatedamtlcyDesc = '_ALLOCATEDAMTLCY_DESC_',
  /** Sort on GenPostingType ascending */
  GenpostingtypeAsc = '_GENPOSTINGTYPE_ASC_',
  /** sort on GenPostingType descending */
  GenpostingtypeDesc = '_GENPOSTINGTYPE_DESC_',
  /** Sort on GenPostingTypeOPTION ascending */
  GenpostingtypeoptionAsc = '_GENPOSTINGTYPEOPTION_ASC_',
  /** sort on GenPostingTypeOPTION descending */
  GenpostingtypeoptionDesc = '_GENPOSTINGTYPEOPTION_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on AllowApplication ascending */
  AllowapplicationAsc = '_ALLOWAPPLICATION_ASC_',
  /** sort on AllowApplication descending */
  AllowapplicationDesc = '_ALLOWAPPLICATION_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on BalGenPostingType ascending */
  BalgenpostingtypeAsc = '_BALGENPOSTINGTYPE_ASC_',
  /** sort on BalGenPostingType descending */
  BalgenpostingtypeDesc = '_BALGENPOSTINGTYPE_DESC_',
  /** Sort on BalGenPostingTypeOPTION ascending */
  BalgenpostingtypeoptionAsc = '_BALGENPOSTINGTYPEOPTION_ASC_',
  /** sort on BalGenPostingTypeOPTION descending */
  BalgenpostingtypeoptionDesc = '_BALGENPOSTINGTYPEOPTION_DESC_',
  /** Sort on BalGenBusPostingGroup ascending */
  BalgenbuspostinggroupAsc = '_BALGENBUSPOSTINGGROUP_ASC_',
  /** sort on BalGenBusPostingGroup descending */
  BalgenbuspostinggroupDesc = '_BALGENBUSPOSTINGGROUP_DESC_',
  /** Sort on BalGenProdPostingGroup ascending */
  BalgenprodpostinggroupAsc = '_BALGENPRODPOSTINGGROUP_ASC_',
  /** sort on BalGenProdPostingGroup descending */
  BalgenprodpostinggroupDesc = '_BALGENPRODPOSTINGGROUP_DESC_',
  /** Sort on BalVATCalculationType ascending */
  BalvatcalculationtypeAsc = '_BALVATCALCULATIONTYPE_ASC_',
  /** sort on BalVATCalculationType descending */
  BalvatcalculationtypeDesc = '_BALVATCALCULATIONTYPE_DESC_',
  /** Sort on BalVATCalculationTypeOPTION ascending */
  BalvatcalculationtypeoptionAsc = '_BALVATCALCULATIONTYPEOPTION_ASC_',
  /** sort on BalVATCalculationTypeOPTION descending */
  BalvatcalculationtypeoptionDesc = '_BALVATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on BalVAT ascending */
  BalvatAsc = '_BALVAT_ASC_',
  /** sort on BalVAT descending */
  BalvatDesc = '_BALVAT_DESC_',
  /** Sort on BalVATAmount ascending */
  BalvatamountAsc = '_BALVATAMOUNT_ASC_',
  /** sort on BalVATAmount descending */
  BalvatamountDesc = '_BALVATAMOUNT_DESC_',
  /** Sort on BankPaymentType ascending */
  BankpaymenttypeAsc = '_BANKPAYMENTTYPE_ASC_',
  /** sort on BankPaymentType descending */
  BankpaymenttypeDesc = '_BANKPAYMENTTYPE_DESC_',
  /** Sort on BankPaymentTypeOPTION ascending */
  BankpaymenttypeoptionAsc = '_BANKPAYMENTTYPEOPTION_ASC_',
  /** sort on BankPaymentTypeOPTION descending */
  BankpaymenttypeoptionDesc = '_BANKPAYMENTTYPEOPTION_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on BalVATBaseAmount ascending */
  BalvatbaseamountAsc = '_BALVATBASEAMOUNT_ASC_',
  /** sort on BalVATBaseAmount descending */
  BalvatbaseamountDesc = '_BALVATBASEAMOUNT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on PrintPostedDocuments ascending */
  PrintposteddocumentsAsc = '_PRINTPOSTEDDOCUMENTS_ASC_',
  /** sort on PrintPostedDocuments descending */
  PrintposteddocumentsDesc = '_PRINTPOSTEDDOCUMENTS_DESC_',
  /** Sort on CheckPrinted ascending */
  CheckprintedAsc = '_CHECKPRINTED_ASC_',
  /** sort on CheckPrinted descending */
  CheckprintedDesc = '_CHECKPRINTED_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on SourceType ascending */
  SourcetypeAsc = '_SOURCETYPE_ASC_',
  /** sort on SourceType descending */
  SourcetypeDesc = '_SOURCETYPE_DESC_',
  /** Sort on SourceTypeOPTION ascending */
  SourcetypeoptionAsc = '_SOURCETYPEOPTION_ASC_',
  /** sort on SourceTypeOPTION descending */
  SourcetypeoptionDesc = '_SOURCETYPEOPTION_DESC_',
  /** Sort on SourceNo ascending */
  SourcenoAsc = '_SOURCENO_ASC_',
  /** sort on SourceNo descending */
  SourcenoDesc = '_SOURCENO_DESC_',
  /** Sort on PostingNoSeries ascending */
  PostingnoseriesAsc = '_POSTINGNOSERIES_ASC_',
  /** sort on PostingNoSeries descending */
  PostingnoseriesDesc = '_POSTINGNOSERIES_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on UseTax ascending */
  UsetaxAsc = '_USETAX_ASC_',
  /** sort on UseTax descending */
  UsetaxDesc = '_USETAX_DESC_',
  /** Sort on BalTaxAreaCode ascending */
  BaltaxareacodeAsc = '_BALTAXAREACODE_ASC_',
  /** sort on BalTaxAreaCode descending */
  BaltaxareacodeDesc = '_BALTAXAREACODE_DESC_',
  /** Sort on BalTaxLiable ascending */
  BaltaxliableAsc = '_BALTAXLIABLE_ASC_',
  /** sort on BalTaxLiable descending */
  BaltaxliableDesc = '_BALTAXLIABLE_DESC_',
  /** Sort on BalTaxGroupCode ascending */
  BaltaxgroupcodeAsc = '_BALTAXGROUPCODE_ASC_',
  /** sort on BalTaxGroupCode descending */
  BaltaxgroupcodeDesc = '_BALTAXGROUPCODE_DESC_',
  /** Sort on BalUseTax ascending */
  BalusetaxAsc = '_BALUSETAX_ASC_',
  /** sort on BalUseTax descending */
  BalusetaxDesc = '_BALUSETAX_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on BalVATBusPostingGroup ascending */
  BalvatbuspostinggroupAsc = '_BALVATBUSPOSTINGGROUP_ASC_',
  /** sort on BalVATBusPostingGroup descending */
  BalvatbuspostinggroupDesc = '_BALVATBUSPOSTINGGROUP_DESC_',
  /** Sort on BalVATProdPostingGroup ascending */
  BalvatprodpostinggroupAsc = '_BALVATPRODPOSTINGGROUP_ASC_',
  /** sort on BalVATProdPostingGroup descending */
  BalvatprodpostinggroupDesc = '_BALVATPRODPOSTINGGROUP_DESC_',
  /** Sort on AdditionalCurrencyPosting ascending */
  AdditionalcurrencypostingAsc = '_ADDITIONALCURRENCYPOSTING_ASC_',
  /** sort on AdditionalCurrencyPosting descending */
  AdditionalcurrencypostingDesc = '_ADDITIONALCURRENCYPOSTING_DESC_',
  /** Sort on AdditionalCurrencyPostingOPTION ascending */
  AdditionalcurrencypostingoptionAsc = '_ADDITIONALCURRENCYPOSTINGOPTION_ASC_',
  /** sort on AdditionalCurrencyPostingOPTION descending */
  AdditionalcurrencypostingoptionDesc = '_ADDITIONALCURRENCYPOSTINGOPTION_DESC_',
  /** Sort on FAAddCurrencyFactor ascending */
  FaaddcurrencyfactorAsc = '_FAADDCURRENCYFACTOR_ASC_',
  /** sort on FAAddCurrencyFactor descending */
  FaaddcurrencyfactorDesc = '_FAADDCURRENCYFACTOR_DESC_',
  /** Sort on SourceCurrencyCode ascending */
  SourcecurrencycodeAsc = '_SOURCECURRENCYCODE_ASC_',
  /** sort on SourceCurrencyCode descending */
  SourcecurrencycodeDesc = '_SOURCECURRENCYCODE_DESC_',
  /** Sort on SourceCurrencyAmount ascending */
  SourcecurrencyamountAsc = '_SOURCECURRENCYAMOUNT_ASC_',
  /** sort on SourceCurrencyAmount descending */
  SourcecurrencyamountDesc = '_SOURCECURRENCYAMOUNT_DESC_',
  /** Sort on SourceCurrVATBaseAmount ascending */
  SourcecurrvatbaseamountAsc = '_SOURCECURRVATBASEAMOUNT_ASC_',
  /** sort on SourceCurrVATBaseAmount descending */
  SourcecurrvatbaseamountDesc = '_SOURCECURRVATBASEAMOUNT_DESC_',
  /** Sort on SourceCurrVATAmount ascending */
  SourcecurrvatamountAsc = '_SOURCECURRVATAMOUNT_ASC_',
  /** sort on SourceCurrVATAmount descending */
  SourcecurrvatamountDesc = '_SOURCECURRVATAMOUNT_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on VATAmountLCY ascending */
  VatamountlcyAsc = '_VATAMOUNTLCY_ASC_',
  /** sort on VATAmountLCY descending */
  VatamountlcyDesc = '_VATAMOUNTLCY_DESC_',
  /** Sort on VATBaseAmountLCY ascending */
  VatbaseamountlcyAsc = '_VATBASEAMOUNTLCY_ASC_',
  /** sort on VATBaseAmountLCY descending */
  VatbaseamountlcyDesc = '_VATBASEAMOUNTLCY_DESC_',
  /** Sort on BalVATAmountLCY ascending */
  BalvatamountlcyAsc = '_BALVATAMOUNTLCY_ASC_',
  /** sort on BalVATAmountLCY descending */
  BalvatamountlcyDesc = '_BALVATAMOUNTLCY_DESC_',
  /** Sort on BalVATBaseAmountLCY ascending */
  BalvatbaseamountlcyAsc = '_BALVATBASEAMOUNTLCY_ASC_',
  /** sort on BalVATBaseAmountLCY descending */
  BalvatbaseamountlcyDesc = '_BALVATBASEAMOUNTLCY_DESC_',
  /** Sort on ReversingEntry ascending */
  ReversingentryAsc = '_REVERSINGENTRY_ASC_',
  /** sort on ReversingEntry descending */
  ReversingentryDesc = '_REVERSINGENTRY_DESC_',
  /** Sort on AllowZeroAmountPosting ascending */
  AllowzeroamountpostingAsc = '_ALLOWZEROAMOUNTPOSTING_ASC_',
  /** sort on AllowZeroAmountPosting descending */
  AllowzeroamountpostingDesc = '_ALLOWZEROAMOUNTPOSTING_DESC_',
  /** Sort on ShiptoOrderAddressCode ascending */
  ShiptoorderaddresscodeAsc = '_SHIPTOORDERADDRESSCODE_ASC_',
  /** sort on ShiptoOrderAddressCode descending */
  ShiptoorderaddresscodeDesc = '_SHIPTOORDERADDRESSCODE_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on BalVATDifference ascending */
  BalvatdifferenceAsc = '_BALVATDIFFERENCE_ASC_',
  /** sort on BalVATDifference descending */
  BalvatdifferenceDesc = '_BALVATDIFFERENCE_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on ICDirection ascending */
  IcdirectionAsc = '_ICDIRECTION_ASC_',
  /** sort on ICDirection descending */
  IcdirectionDesc = '_ICDIRECTION_DESC_',
  /** Sort on ICDirectionOPTION ascending */
  IcdirectionoptionAsc = '_ICDIRECTIONOPTION_ASC_',
  /** sort on ICDirectionOPTION descending */
  IcdirectionoptionDesc = '_ICDIRECTIONOPTION_DESC_',
  /** Sort on ICPartnerGLAccNo ascending */
  IcpartnerglaccnoAsc = '_ICPARTNERGLACCNO_ASC_',
  /** sort on ICPartnerGLAccNo descending */
  IcpartnerglaccnoDesc = '_ICPARTNERGLACCNO_DESC_',
  /** Sort on ICPartnerTransactionNo ascending */
  IcpartnertransactionnoAsc = '_ICPARTNERTRANSACTIONNO_ASC_',
  /** sort on ICPartnerTransactionNo descending */
  IcpartnertransactionnoDesc = '_ICPARTNERTRANSACTIONNO_DESC_',
  /** Sort on SelltoBuyfromNo ascending */
  SelltobuyfromnoAsc = '_SELLTOBUYFROMNO_ASC_',
  /** sort on SelltoBuyfromNo descending */
  SelltobuyfromnoDesc = '_SELLTOBUYFROMNO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on FinancialVoid ascending */
  FinancialvoidAsc = '_FINANCIALVOID_ASC_',
  /** sort on FinancialVoid descending */
  FinancialvoidDesc = '_FINANCIALVOID_DESC_',
  /** Sort on CopyVATSetuptoJnlLines ascending */
  CopyvatsetuptojnllinesAsc = '_COPYVATSETUPTOJNLLINES_ASC_',
  /** sort on CopyVATSetuptoJnlLines descending */
  CopyvatsetuptojnllinesDesc = '_COPYVATSETUPTOJNLLINES_DESC_',
  /** Sort on VATBaseBeforePmtDisc ascending */
  VatbasebeforepmtdiscAsc = '_VATBASEBEFOREPMTDISC_ASC_',
  /** sort on VATBaseBeforePmtDisc descending */
  VatbasebeforepmtdiscDesc = '_VATBASEBEFOREPMTDISC_DESC_',
  /** Sort on OrigPmtDiscPossible ascending */
  OrigpmtdiscpossibleAsc = '_ORIGPMTDISCPOSSIBLE_ASC_',
  /** sort on OrigPmtDiscPossible descending */
  OrigpmtdiscpossibleDesc = '_ORIGPMTDISCPOSSIBLE_DESC_',
  /** Sort on OrigPmtDiscPossibleLCY ascending */
  OrigpmtdiscpossiblelcyAsc = '_ORIGPMTDISCPOSSIBLELCY_ASC_',
  /** sort on OrigPmtDiscPossibleLCY descending */
  OrigpmtdiscpossiblelcyDesc = '_ORIGPMTDISCPOSSIBLELCY_DESC_',
  /** Sort on JobQueueStatus ascending */
  JobqueuestatusAsc = '_JOBQUEUESTATUS_ASC_',
  /** sort on JobQueueStatus descending */
  JobqueuestatusDesc = '_JOBQUEUESTATUS_DESC_',
  /** Sort on JobQueueStatusOPTION ascending */
  JobqueuestatusoptionAsc = '_JOBQUEUESTATUSOPTION_ASC_',
  /** sort on JobQueueStatusOPTION descending */
  JobqueuestatusoptionDesc = '_JOBQUEUESTATUSOPTION_DESC_',
  /** Sort on JobQueueEntryID ascending */
  JobqueueentryidAsc = '_JOBQUEUEENTRYID_ASC_',
  /** sort on JobQueueEntryID descending */
  JobqueueentryidDesc = '_JOBQUEUEENTRYID_DESC_',
  /** Sort on IncomingDocumentEntryNo ascending */
  IncomingdocumententrynoAsc = '_INCOMINGDOCUMENTENTRYNO_ASC_',
  /** sort on IncomingDocumentEntryNo descending */
  IncomingdocumententrynoDesc = '_INCOMINGDOCUMENTENTRYNO_DESC_',
  /** Sort on CreditorNo ascending */
  CreditornoAsc = '_CREDITORNO_ASC_',
  /** sort on CreditorNo descending */
  CreditornoDesc = '_CREDITORNO_DESC_',
  /** Sort on PaymentReference ascending */
  PaymentreferenceAsc = '_PAYMENTREFERENCE_ASC_',
  /** sort on PaymentReference descending */
  PaymentreferenceDesc = '_PAYMENTREFERENCE_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on AppliestoExtDocNo ascending */
  AppliestoextdocnoAsc = '_APPLIESTOEXTDOCNO_ASC_',
  /** sort on AppliestoExtDocNo descending */
  AppliestoextdocnoDesc = '_APPLIESTOEXTDOCNO_DESC_',
  /** Sort on KeepDescription ascending */
  KeepdescriptionAsc = '_KEEPDESCRIPTION_ASC_',
  /** sort on KeepDescription descending */
  KeepdescriptionDesc = '_KEEPDESCRIPTION_DESC_',
  /** Sort on RecipientBankAccount ascending */
  RecipientbankaccountAsc = '_RECIPIENTBANKACCOUNT_ASC_',
  /** sort on RecipientBankAccount descending */
  RecipientbankaccountDesc = '_RECIPIENTBANKACCOUNT_DESC_',
  /** Sort on MessagetoRecipient ascending */
  MessagetorecipientAsc = '_MESSAGETORECIPIENT_ASC_',
  /** sort on MessagetoRecipient descending */
  MessagetorecipientDesc = '_MESSAGETORECIPIENT_DESC_',
  /** Sort on ExportedtoPaymentFile ascending */
  ExportedtopaymentfileAsc = '_EXPORTEDTOPAYMENTFILE_ASC_',
  /** sort on ExportedtoPaymentFile descending */
  ExportedtopaymentfileDesc = '_EXPORTEDTOPAYMENTFILE_DESC_',
  /** Sort on HasPaymentExportError ascending */
  HaspaymentexporterrorAsc = '_HASPAYMENTEXPORTERROR_ASC_',
  /** sort on HasPaymentExportError descending */
  HaspaymentexporterrorDesc = '_HASPAYMENTEXPORTERROR_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobUnitPriceLCY ascending */
  JobunitpricelcyAsc = '_JOBUNITPRICELCY_ASC_',
  /** sort on JobUnitPriceLCY descending */
  JobunitpricelcyDesc = '_JOBUNITPRICELCY_DESC_',
  /** Sort on JobTotalPriceLCY ascending */
  JobtotalpricelcyAsc = '_JOBTOTALPRICELCY_ASC_',
  /** sort on JobTotalPriceLCY descending */
  JobtotalpricelcyDesc = '_JOBTOTALPRICELCY_DESC_',
  /** Sort on JobQuantity ascending */
  JobquantityAsc = '_JOBQUANTITY_ASC_',
  /** sort on JobQuantity descending */
  JobquantityDesc = '_JOBQUANTITY_DESC_',
  /** Sort on JobUnitCostLCY ascending */
  JobunitcostlcyAsc = '_JOBUNITCOSTLCY_ASC_',
  /** sort on JobUnitCostLCY descending */
  JobunitcostlcyDesc = '_JOBUNITCOSTLCY_DESC_',
  /** Sort on JobLineDiscount ascending */
  JoblinediscountAsc = '_JOBLINEDISCOUNT_ASC_',
  /** sort on JobLineDiscount descending */
  JoblinediscountDesc = '_JOBLINEDISCOUNT_DESC_',
  /** Sort on JobLineDiscAmountLCY ascending */
  JoblinediscamountlcyAsc = '_JOBLINEDISCAMOUNTLCY_ASC_',
  /** sort on JobLineDiscAmountLCY descending */
  JoblinediscamountlcyDesc = '_JOBLINEDISCAMOUNTLCY_DESC_',
  /** Sort on JobUnitOfMeasureCode ascending */
  JobunitofmeasurecodeAsc = '_JOBUNITOFMEASURECODE_ASC_',
  /** sort on JobUnitOfMeasureCode descending */
  JobunitofmeasurecodeDesc = '_JOBUNITOFMEASURECODE_DESC_',
  /** Sort on JobLineType ascending */
  JoblinetypeAsc = '_JOBLINETYPE_ASC_',
  /** sort on JobLineType descending */
  JoblinetypeDesc = '_JOBLINETYPE_DESC_',
  /** Sort on JobLineTypeOPTION ascending */
  JoblinetypeoptionAsc = '_JOBLINETYPEOPTION_ASC_',
  /** sort on JobLineTypeOPTION descending */
  JoblinetypeoptionDesc = '_JOBLINETYPEOPTION_DESC_',
  /** Sort on JobUnitPrice ascending */
  JobunitpriceAsc = '_JOBUNITPRICE_ASC_',
  /** sort on JobUnitPrice descending */
  JobunitpriceDesc = '_JOBUNITPRICE_DESC_',
  /** Sort on JobTotalPrice ascending */
  JobtotalpriceAsc = '_JOBTOTALPRICE_ASC_',
  /** sort on JobTotalPrice descending */
  JobtotalpriceDesc = '_JOBTOTALPRICE_DESC_',
  /** Sort on JobUnitCost ascending */
  JobunitcostAsc = '_JOBUNITCOST_ASC_',
  /** sort on JobUnitCost descending */
  JobunitcostDesc = '_JOBUNITCOST_DESC_',
  /** Sort on JobTotalCost ascending */
  JobtotalcostAsc = '_JOBTOTALCOST_ASC_',
  /** sort on JobTotalCost descending */
  JobtotalcostDesc = '_JOBTOTALCOST_DESC_',
  /** Sort on JobLineDiscountAmount ascending */
  JoblinediscountamountAsc = '_JOBLINEDISCOUNTAMOUNT_ASC_',
  /** sort on JobLineDiscountAmount descending */
  JoblinediscountamountDesc = '_JOBLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on JobLineAmount ascending */
  JoblineamountAsc = '_JOBLINEAMOUNT_ASC_',
  /** sort on JobLineAmount descending */
  JoblineamountDesc = '_JOBLINEAMOUNT_DESC_',
  /** Sort on JobTotalCostLCY ascending */
  JobtotalcostlcyAsc = '_JOBTOTALCOSTLCY_ASC_',
  /** sort on JobTotalCostLCY descending */
  JobtotalcostlcyDesc = '_JOBTOTALCOSTLCY_DESC_',
  /** Sort on JobLineAmountLCY ascending */
  JoblineamountlcyAsc = '_JOBLINEAMOUNTLCY_ASC_',
  /** sort on JobLineAmountLCY descending */
  JoblineamountlcyDesc = '_JOBLINEAMOUNTLCY_DESC_',
  /** Sort on JobCurrencyFactor ascending */
  JobcurrencyfactorAsc = '_JOBCURRENCYFACTOR_ASC_',
  /** sort on JobCurrencyFactor descending */
  JobcurrencyfactorDesc = '_JOBCURRENCYFACTOR_DESC_',
  /** Sort on JobCurrencyCode ascending */
  JobcurrencycodeAsc = '_JOBCURRENCYCODE_ASC_',
  /** sort on JobCurrencyCode descending */
  JobcurrencycodeDesc = '_JOBCURRENCYCODE_DESC_',
  /** Sort on JobPlanningLineNo ascending */
  JobplanninglinenoAsc = '_JOBPLANNINGLINENO_ASC_',
  /** sort on JobPlanningLineNo descending */
  JobplanninglinenoDesc = '_JOBPLANNINGLINENO_DESC_',
  /** Sort on JobRemainingQty ascending */
  JobremainingqtyAsc = '_JOBREMAININGQTY_ASC_',
  /** sort on JobRemainingQty descending */
  JobremainingqtyDesc = '_JOBREMAININGQTY_DESC_',
  /** Sort on DirectDebitMandateID ascending */
  DirectdebitmandateidAsc = '_DIRECTDEBITMANDATEID_ASC_',
  /** sort on DirectDebitMandateID descending */
  DirectdebitmandateidDesc = '_DIRECTDEBITMANDATEID_DESC_',
  /** Sort on DataExchEntryNo ascending */
  DataexchentrynoAsc = '_DATAEXCHENTRYNO_ASC_',
  /** sort on DataExchEntryNo descending */
  DataexchentrynoDesc = '_DATAEXCHENTRYNO_DESC_',
  /** Sort on PayerInformation ascending */
  PayerinformationAsc = '_PAYERINFORMATION_ASC_',
  /** sort on PayerInformation descending */
  PayerinformationDesc = '_PAYERINFORMATION_DESC_',
  /** Sort on TransactionInformation ascending */
  TransactioninformationAsc = '_TRANSACTIONINFORMATION_ASC_',
  /** sort on TransactionInformation descending */
  TransactioninformationDesc = '_TRANSACTIONINFORMATION_DESC_',
  /** Sort on DataExchLineNo ascending */
  DataexchlinenoAsc = '_DATAEXCHLINENO_ASC_',
  /** sort on DataExchLineNo descending */
  DataexchlinenoDesc = '_DATAEXCHLINENO_DESC_',
  /** Sort on AppliedAutomatically ascending */
  AppliedautomaticallyAsc = '_APPLIEDAUTOMATICALLY_ASC_',
  /** sort on AppliedAutomatically descending */
  AppliedautomaticallyDesc = '_APPLIEDAUTOMATICALLY_DESC_',
  /** Sort on LinkedTableID ascending */
  LinkedtableidAsc = '_LINKEDTABLEID_ASC_',
  /** sort on LinkedTableID descending */
  LinkedtableidDesc = '_LINKEDTABLEID_DESC_',
  /** Sort on LinkedSystemID ascending */
  LinkedsystemidAsc = '_LINKEDSYSTEMID_ASC_',
  /** sort on LinkedSystemID descending */
  LinkedsystemidDesc = '_LINKEDSYSTEMID_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on DeferralLineNo ascending */
  DeferrallinenoAsc = '_DEFERRALLINENO_ASC_',
  /** sort on DeferralLineNo descending */
  DeferrallinenoDesc = '_DEFERRALLINENO_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on ProdOrderNo ascending */
  ProdordernoAsc = '_PRODORDERNO_ASC_',
  /** sort on ProdOrderNo descending */
  ProdordernoDesc = '_PRODORDERNO_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on FAPostingType ascending */
  FapostingtypeAsc = '_FAPOSTINGTYPE_ASC_',
  /** sort on FAPostingType descending */
  FapostingtypeDesc = '_FAPOSTINGTYPE_DESC_',
  /** Sort on FAPostingTypeOPTION ascending */
  FapostingtypeoptionAsc = '_FAPOSTINGTYPEOPTION_ASC_',
  /** sort on FAPostingTypeOPTION descending */
  FapostingtypeoptionDesc = '_FAPOSTINGTYPEOPTION_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on SalvageValue ascending */
  SalvagevalueAsc = '_SALVAGEVALUE_ASC_',
  /** sort on SalvageValue descending */
  SalvagevalueDesc = '_SALVAGEVALUE_DESC_',
  /** Sort on NoofDepreciationDays ascending */
  NoofdepreciationdaysAsc = '_NOOFDEPRECIATIONDAYS_ASC_',
  /** sort on NoofDepreciationDays descending */
  NoofdepreciationdaysDesc = '_NOOFDEPRECIATIONDAYS_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DeprAcquisitionCost ascending */
  DepracquisitioncostAsc = '_DEPRACQUISITIONCOST_ASC_',
  /** sort on DeprAcquisitionCost descending */
  DepracquisitioncostDesc = '_DEPRACQUISITIONCOST_DESC_',
  /** Sort on MaintenanceCode ascending */
  MaintenancecodeAsc = '_MAINTENANCECODE_ASC_',
  /** sort on MaintenanceCode descending */
  MaintenancecodeDesc = '_MAINTENANCECODE_DESC_',
  /** Sort on InsuranceNo ascending */
  InsurancenoAsc = '_INSURANCENO_ASC_',
  /** sort on InsuranceNo descending */
  InsurancenoDesc = '_INSURANCENO_DESC_',
  /** Sort on BudgetedFANo ascending */
  BudgetedfanoAsc = '_BUDGETEDFANO_ASC_',
  /** sort on BudgetedFANo descending */
  BudgetedfanoDesc = '_BUDGETEDFANO_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on FAReclassificationEntry ascending */
  FareclassificationentryAsc = '_FARECLASSIFICATIONENTRY_ASC_',
  /** sort on FAReclassificationEntry descending */
  FareclassificationentryDesc = '_FARECLASSIFICATIONENTRY_DESC_',
  /** Sort on FAErrorEntryNo ascending */
  FaerrorentrynoAsc = '_FAERRORENTRYNO_ASC_',
  /** sort on FAErrorEntryNo descending */
  FaerrorentrynoDesc = '_FAERRORENTRYNO_DESC_',
  /** Sort on IndexEntry ascending */
  IndexentryAsc = '_INDEXENTRY_ASC_',
  /** sort on IndexEntry descending */
  IndexentryDesc = '_INDEXENTRY_DESC_',
  /** Sort on SourceLineNo ascending */
  SourcelinenoAsc = '_SOURCELINENO_ASC_',
  /** sort on SourceLineNo descending */
  SourcelinenoDesc = '_SOURCELINENO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on CheckExported ascending */
  CheckexportedAsc = '_CHECKEXPORTED_ASC_',
  /** sort on CheckExported descending */
  CheckexportedDesc = '_CHECKEXPORTED_DESC_',
  /** Sort on CheckTransmitted ascending */
  ChecktransmittedAsc = '_CHECKTRANSMITTED_ASC_',
  /** sort on CheckTransmitted descending */
  ChecktransmittedDesc = '_CHECKTRANSMITTED_DESC_',
  /** Sort on ReverseDateCalculation ascending */
  ReversedatecalculationAsc = '_REVERSEDATECALCULATION_ASC_',
  /** sort on ReverseDateCalculation descending */
  ReversedatecalculationDesc = '_REVERSEDATECALCULATION_DESC_',
  /** Sort on AccountId ascending */
  AccountidAsc = '_ACCOUNTID_ASC_',
  /** sort on AccountId descending */
  AccountidDesc = '_ACCOUNTID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on AppliestoInvoiceId ascending */
  AppliestoinvoiceidAsc = '_APPLIESTOINVOICEID_ASC_',
  /** sort on AppliestoInvoiceId descending */
  AppliestoinvoiceidDesc = '_APPLIESTOINVOICEID_DESC_',
  /** Sort on ContactGraphId ascending */
  ContactgraphidAsc = '_CONTACTGRAPHID_ASC_',
  /** sort on ContactGraphId descending */
  ContactgraphidDesc = '_CONTACTGRAPHID_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on JournalBatchId ascending */
  JournalbatchidAsc = '_JOURNALBATCHID_ASC_',
  /** sort on JournalBatchId descending */
  JournalbatchidDesc = '_JOURNALBATCHID_DESC_',
  /** Sort on PaymentMethodId ascending */
  PaymentmethodidAsc = '_PAYMENTMETHODID_ASC_',
  /** sort on PaymentMethodId descending */
  PaymentmethodidDesc = '_PAYMENTMETHODID_DESC_',
  /** Sort on BalanceAccountId ascending */
  BalanceaccountidAsc = '_BALANCEACCOUNTID_ASC_',
  /** sort on BalanceAccountId descending */
  BalanceaccountidDesc = '_BALANCEACCOUNTID_DESC_',
  /** Sort on VendorId ascending */
  VendoridAsc = '_VENDORID_ASC_',
  /** sort on VendorId descending */
  VendoridDesc = '_VENDORID_DESC_',
  /** Sort on SourcePostingDate ascending */
  SourcepostingdateAsc = '_SOURCEPOSTINGDATE_ASC_',
  /** sort on SourcePostingDate descending */
  SourcepostingdateDesc = '_SOURCEPOSTINGDATE_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on PEBDirectDebitPackNo ascending */
  PebdirectdebitpacknoAsc = '_PEBDIRECTDEBITPACKNO_ASC_',
  /** sort on PEBDirectDebitPackNo descending */
  PebdirectdebitpacknoDesc = '_PEBDIRECTDEBITPACKNO_DESC_',
  /** Sort on PEBInformationType ascending */
  PebinformationtypeAsc = '_PEBINFORMATIONTYPE_ASC_',
  /** sort on PEBInformationType descending */
  PebinformationtypeDesc = '_PEBINFORMATIONTYPE_DESC_',
  /** Sort on PEBInformationTypeOPTION ascending */
  PebinformationtypeoptionAsc = '_PEBINFORMATIONTYPEOPTION_ASC_',
  /** sort on PEBInformationTypeOPTION descending */
  PebinformationtypeoptionDesc = '_PEBINFORMATIONTYPEOPTION_DESC_',
  /** Sort on PEBLastGLEntryNo ascending */
  PeblastglentrynoAsc = '_PEBLASTGLENTRYNO_ASC_',
  /** sort on PEBLastGLEntryNo descending */
  PeblastglentrynoDesc = '_PEBLASTGLENTRYNO_DESC_',
  /** Sort on PEBLastGLRegEntryNo ascending */
  PeblastglregentrynoAsc = '_PEBLASTGLREGENTRYNO_ASC_',
  /** sort on PEBLastGLRegEntryNo descending */
  PeblastglregentrynoDesc = '_PEBLASTGLREGENTRYNO_DESC_',
  /** Sort on PEBInformation ascending */
  PebinformationAsc = '_PEBINFORMATION_ASC_',
  /** sort on PEBInformation descending */
  PebinformationDesc = '_PEBINFORMATION_DESC_',
  /** Sort on PEBSIEImportCode ascending */
  PebsieimportcodeAsc = '_PEBSIEIMPORTCODE_ASC_',
  /** sort on PEBSIEImportCode descending */
  PebsieimportcodeDesc = '_PEBSIEIMPORTCODE_DESC_',
  /** Sort on PEBOCRNo ascending */
  PebocrnoAsc = '_PEBOCRNO_ASC_',
  /** sort on PEBOCRNo descending */
  PebocrnoDesc = '_PEBOCRNO_DESC_',
  /** Sort on PEBExtDocNonotMandat ascending */
  PebextdocnonotmandatAsc = '_PEBEXTDOCNONOTMANDAT_ASC_',
  /** sort on PEBExtDocNonotMandat descending */
  PebextdocnonotmandatDesc = '_PEBEXTDOCNONOTMANDAT_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMSkipExtraPost ascending */
  EqmskipextrapostAsc = '_EQMSKIPEXTRAPOST_ASC_',
  /** sort on EQMSkipExtraPost descending */
  EqmskipextrapostDesc = '_EQMSKIPEXTRAPOST_DESC_',
  /** Sort on EQMDepositPosting ascending */
  EqmdepositpostingAsc = '_EQMDEPOSITPOSTING_ASC_',
  /** sort on EQMDepositPosting descending */
  EqmdepositpostingDesc = '_EQMDEPOSITPOSTING_DESC_',
  /** Sort on EQMCustomerDepositNo ascending */
  EqmcustomerdepositnoAsc = '_EQMCUSTOMERDEPOSITNO_ASC_',
  /** sort on EQMCustomerDepositNo descending */
  EqmcustomerdepositnoDesc = '_EQMCUSTOMERDEPOSITNO_DESC_',
  /** Sort on EQMDisposalType ascending */
  EqmdisposaltypeAsc = '_EQMDISPOSALTYPE_ASC_',
  /** sort on EQMDisposalType descending */
  EqmdisposaltypeDesc = '_EQMDISPOSALTYPE_DESC_',
  /** Sort on EQMDisposalTypeOPTION ascending */
  EqmdisposaltypeoptionAsc = '_EQMDISPOSALTYPEOPTION_ASC_',
  /** sort on EQMDisposalTypeOPTION descending */
  EqmdisposaltypeoptionDesc = '_EQMDISPOSALTYPEOPTION_DESC_',
  /** Sort on EQMDepositEntry ascending */
  EqmdepositentryAsc = '_EQMDEPOSITENTRY_ASC_',
  /** sort on EQMDepositEntry descending */
  EqmdepositentryDesc = '_EQMDEPOSITENTRY_DESC_',
  /** Sort on EQMAdvancePaymentEntry ascending */
  EqmadvancepaymententryAsc = '_EQMADVANCEPAYMENTENTRY_ASC_',
  /** sort on EQMAdvancePaymentEntry descending */
  EqmadvancepaymententryDesc = '_EQMADVANCEPAYMENTENTRY_DESC_',
  /** Sort on EQMAdvPaymentAmountLCY ascending */
  EqmadvpaymentamountlcyAsc = '_EQMADVPAYMENTAMOUNTLCY_ASC_',
  /** sort on EQMAdvPaymentAmountLCY descending */
  EqmadvpaymentamountlcyDesc = '_EQMADVPAYMENTAMOUNTLCY_DESC_',
  /** Sort on EQMRentalEntry ascending */
  EqmrentalentryAsc = '_EQMRENTALENTRY_ASC_',
  /** sort on EQMRentalEntry descending */
  EqmrentalentryDesc = '_EQMRENTALENTRY_DESC_',
  /** Sort on EQMWorkshopEntry ascending */
  EqmworkshopentryAsc = '_EQMWORKSHOPENTRY_ASC_',
  /** sort on EQMWorkshopEntry descending */
  EqmworkshopentryDesc = '_EQMWORKSHOPENTRY_DESC_',
  /** Sort on EQMObjectErrorEntryNo ascending */
  EqmobjecterrorentrynoAsc = '_EQMOBJECTERRORENTRYNO_ASC_',
  /** sort on EQMObjectErrorEntryNo descending */
  EqmobjecterrorentrynoDesc = '_EQMOBJECTERRORENTRYNO_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMDepositPayment ascending */
  EqmdepositpaymentAsc = '_EQMDEPOSITPAYMENT_ASC_',
  /** sort on EQMDepositPayment descending */
  EqmdepositpaymentDesc = '_EQMDEPOSITPAYMENT_DESC_',
  /** Sort on EQMTransactionID ascending */
  EqmtransactionidAsc = '_EQMTRANSACTIONID_ASC_',
  /** sort on EQMTransactionID descending */
  EqmtransactionidDesc = '_EQMTRANSACTIONID_DESC_',
  /** Sort on EQMFANo ascending */
  EqmfanoAsc = '_EQMFANO_ASC_',
  /** sort on EQMFANo descending */
  EqmfanoDesc = '_EQMFANO_DESC_',
  /** Sort on EQMFADepreciationBookCode ascending */
  EqmfadepreciationbookcodeAsc = '_EQMFADEPRECIATIONBOOKCODE_ASC_',
  /** sort on EQMFADepreciationBookCode descending */
  EqmfadepreciationbookcodeDesc = '_EQMFADEPRECIATIONBOOKCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortGenProductPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on DefVATProdPostingGroup ascending */
  DefvatprodpostinggroupAsc = '_DEFVATPRODPOSTINGGROUP_ASC_',
  /** sort on DefVATProdPostingGroup descending */
  DefvatprodpostinggroupDesc = '_DEFVATPRODPOSTINGGROUP_DESC_',
  /** Sort on AutoInsertDefault ascending */
  AutoinsertdefaultAsc = '_AUTOINSERTDEFAULT_ASC_',
  /** sort on AutoInsertDefault descending */
  AutoinsertdefaultDesc = '_AUTOINSERTDEFAULT_DESC_',
  /** Sort on EQMPercentofMonthlyPrice ascending */
  EqmpercentofmonthlypriceAsc = '_EQMPERCENTOFMONTHLYPRICE_ASC_',
  /** sort on EQMPercentofMonthlyPrice descending */
  EqmpercentofmonthlypriceDesc = '_EQMPERCENTOFMONTHLYPRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortInspectionInput {
  /** Sort on ID ascending */
  IdAsc = '_ID_ASC_',
  /** sort on ID descending */
  IdDesc = '_ID_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on InspectionDate ascending */
  InspectiondateAsc = '_INSPECTIONDATE_ASC_',
  /** sort on InspectionDate descending */
  InspectiondateDesc = '_INSPECTIONDATE_DESC_',
  /** Sort on ObjectStatus ascending */
  ObjectstatusAsc = '_OBJECTSTATUS_ASC_',
  /** sort on ObjectStatus descending */
  ObjectstatusDesc = '_OBJECTSTATUS_DESC_',
  /** Sort on ObjectStatusOPTION ascending */
  ObjectstatusoptionAsc = '_OBJECTSTATUSOPTION_ASC_',
  /** sort on ObjectStatusOPTION descending */
  ObjectstatusoptionDesc = '_OBJECTSTATUSOPTION_DESC_',
  /** Sort on Document ascending */
  DocumentAsc = '_DOCUMENT_ASC_',
  /** sort on Document descending */
  DocumentDesc = '_DOCUMENT_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on UserEmail ascending */
  UseremailAsc = '_USEREMAIL_ASC_',
  /** sort on UserEmail descending */
  UseremailDesc = '_USEREMAIL_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortInspectionUserInput {
  /** Sort on SalesPerson ascending */
  SalespersonAsc = '_SALESPERSON_ASC_',
  /** sort on SalesPerson descending */
  SalespersonDesc = '_SALESPERSON_DESC_',
  /** Sort on UserEmail ascending */
  UseremailAsc = '_USEREMAIL_ASC_',
  /** sort on UserEmail descending */
  UseremailDesc = '_USEREMAIL_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortIntegrationCollectionInput {
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on Active ascending */
  ActiveAsc = '_ACTIVE_ASC_',
  /** sort on Active descending */
  ActiveDesc = '_ACTIVE_DESC_',
  /** Sort on LatestHeartBeat ascending */
  LatestheartbeatAsc = '_LATESTHEARTBEAT_ASC_',
  /** sort on LatestHeartBeat descending */
  LatestheartbeatDesc = '_LATESTHEARTBEAT_DESC_',
  /** Sort on FirstHeartBeat ascending */
  FirstheartbeatAsc = '_FIRSTHEARTBEAT_ASC_',
  /** sort on FirstHeartBeat descending */
  FirstheartbeatDesc = '_FIRSTHEARTBEAT_DESC_',
  /** Sort on HeartBeartIntervalMinutes ascending */
  HeartbeartintervalminutesAsc = '_HEARTBEARTINTERVALMINUTES_ASC_',
  /** sort on HeartBeartIntervalMinutes descending */
  HeartbeartintervalminutesDesc = '_HEARTBEARTINTERVALMINUTES_DESC_',
  /** Sort on HeartbeatMonitoringEnabled ascending */
  HeartbeatmonitoringenabledAsc = '_HEARTBEATMONITORINGENABLED_ASC_',
  /** sort on HeartbeatMonitoringEnabled descending */
  HeartbeatmonitoringenabledDesc = '_HEARTBEATMONITORINGENABLED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortInventoryPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortInvitationInput {
  /** Sort on UserEmail ascending */
  UseremailAsc = '_USEREMAIL_ASC_',
  /** sort on UserEmail descending */
  UseremailDesc = '_USEREMAIL_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on Role ascending */
  RoleAsc = '_ROLE_ASC_',
  /** sort on Role descending */
  RoleDesc = '_ROLE_DESC_',
  /** Sort on UserName ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on UserName descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on CreatedOn ascending */
  CreatedonAsc = '_CREATEDON_ASC_',
  /** sort on CreatedOn descending */
  CreatedonDesc = '_CREATEDON_DESC_',
  /** Sort on ExpiresOn ascending */
  ExpiresonAsc = '_EXPIRESON_ASC_',
  /** sort on ExpiresOn descending */
  ExpiresonDesc = '_EXPIRESON_DESC_',
  /** Sort on AcceptedOn ascending */
  AcceptedonAsc = '_ACCEPTEDON_ASC_',
  /** sort on AcceptedOn descending */
  AcceptedonDesc = '_ACCEPTEDON_DESC_',
  /** Sort on AcceptedByUserEmail ascending */
  AcceptedbyuseremailAsc = '_ACCEPTEDBYUSEREMAIL_ASC_',
  /** sort on AcceptedByUserEmail descending */
  AcceptedbyuseremailDesc = '_ACCEPTEDBYUSEREMAIL_DESC_',
  /** Sort on CreatedByUserEmail ascending */
  CreatedbyuseremailAsc = '_CREATEDBYUSEREMAIL_ASC_',
  /** sort on CreatedByUserEmail descending */
  CreatedbyuseremailDesc = '_CREATEDBYUSEREMAIL_DESC_',
  /** Sort on CreateByUserName ascending */
  CreatebyusernameAsc = '_CREATEBYUSERNAME_ASC_',
  /** sort on CreateByUserName descending */
  CreatebyusernameDesc = '_CREATEBYUSERNAME_DESC_',
  /** Sort on InvitationCode ascending */
  InvitationcodeAsc = '_INVITATIONCODE_ASC_',
  /** sort on InvitationCode descending */
  InvitationcodeDesc = '_INVITATIONCODE_DESC_',
  /** Sort on RegistrationNo ascending */
  RegistrationnoAsc = '_REGISTRATIONNO_ASC_',
  /** sort on RegistrationNo descending */
  RegistrationnoDesc = '_REGISTRATIONNO_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on Deactivated ascending */
  DeactivatedAsc = '_DEACTIVATED_ASC_',
  /** sort on Deactivated descending */
  DeactivatedDesc = '_DEACTIVATED_DESC_',
  /** Sort on IOSAppLink ascending */
  IosapplinkAsc = '_IOSAPPLINK_ASC_',
  /** sort on IOSAppLink descending */
  IosapplinkDesc = '_IOSAPPLINK_DESC_',
  /** Sort on AndroidAppLink ascending */
  AndroidapplinkAsc = '_ANDROIDAPPLINK_ASC_',
  /** sort on AndroidAppLink descending */
  AndroidapplinkDesc = '_ANDROIDAPPLINK_DESC_',
  /** Sort on IOSAppCode ascending */
  IosappcodeAsc = '_IOSAPPCODE_ASC_',
  /** sort on IOSAppCode descending */
  IosappcodeDesc = '_IOSAPPCODE_DESC_',
  /** Sort on IsOnAppStore ascending */
  IsonappstoreAsc = '_ISONAPPSTORE_ASC_',
  /** sort on IsOnAppStore descending */
  IsonappstoreDesc = '_ISONAPPSTORE_DESC_',
  /** Sort on IsOnPlayStore ascending */
  IsonplaystoreAsc = '_ISONPLAYSTORE_ASC_',
  /** sort on IsOnPlayStore descending */
  IsonplaystoreDesc = '_ISONPLAYSTORE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortInvoiceHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPostingGroup ascending */
  CustomerpostinggroupAsc = '_CUSTOMERPOSTINGGROUP_ASC_',
  /** sort on CustomerPostingGroup descending */
  CustomerpostinggroupDesc = '_CUSTOMERPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoCustomerName ascending */
  SelltocustomernameAsc = '_SELLTOCUSTOMERNAME_ASC_',
  /** sort on SelltoCustomerName descending */
  SelltocustomernameDesc = '_SELLTOCUSTOMERNAME_DESC_',
  /** Sort on SelltoCustomerName2 ascending */
  Selltocustomername2Asc = '_SELLTOCUSTOMERNAME2_ASC_',
  /** sort on SelltoCustomerName2 descending */
  Selltocustomername2Desc = '_SELLTOCUSTOMERNAME2_DESC_',
  /** Sort on SelltoAddress ascending */
  SelltoaddressAsc = '_SELLTOADDRESS_ASC_',
  /** sort on SelltoAddress descending */
  SelltoaddressDesc = '_SELLTOADDRESS_DESC_',
  /** Sort on SelltoAddress2 ascending */
  Selltoaddress2Asc = '_SELLTOADDRESS2_ASC_',
  /** sort on SelltoAddress2 descending */
  Selltoaddress2Desc = '_SELLTOADDRESS2_DESC_',
  /** Sort on SelltoCity ascending */
  SelltocityAsc = '_SELLTOCITY_ASC_',
  /** sort on SelltoCity descending */
  SelltocityDesc = '_SELLTOCITY_DESC_',
  /** Sort on SelltoContact ascending */
  SelltocontactAsc = '_SELLTOCONTACT_ASC_',
  /** sort on SelltoContact descending */
  SelltocontactDesc = '_SELLTOCONTACT_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoPostCode ascending */
  SelltopostcodeAsc = '_SELLTOPOSTCODE_ASC_',
  /** sort on SelltoPostCode descending */
  SelltopostcodeDesc = '_SELLTOPOSTCODE_DESC_',
  /** Sort on SelltoCounty ascending */
  SelltocountyAsc = '_SELLTOCOUNTY_ASC_',
  /** sort on SelltoCounty descending */
  SelltocountyDesc = '_SELLTOCOUNTY_DESC_',
  /** Sort on SelltoCountryRegionCode ascending */
  SelltocountryregioncodeAsc = '_SELLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on SelltoCountryRegionCode descending */
  SelltocountryregioncodeDesc = '_SELLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on PreAssignedNoSeries ascending */
  PreassignednoseriesAsc = '_PREASSIGNEDNOSERIES_ASC_',
  /** sort on PreAssignedNoSeries descending */
  PreassignednoseriesDesc = '_PREASSIGNEDNOSERIES_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on OrderNoSeries ascending */
  OrdernoseriesAsc = '_ORDERNOSERIES_ASC_',
  /** sort on OrderNoSeries descending */
  OrdernoseriesDesc = '_ORDERNOSERIES_DESC_',
  /** Sort on PreAssignedNo ascending */
  PreassignednoAsc = '_PREASSIGNEDNO_ASC_',
  /** sort on PreAssignedNo descending */
  PreassignednoDesc = '_PREASSIGNEDNO_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on InvoiceDiscountCalculation ascending */
  InvoicediscountcalculationAsc = '_INVOICEDISCOUNTCALCULATION_ASC_',
  /** sort on InvoiceDiscountCalculation descending */
  InvoicediscountcalculationDesc = '_INVOICEDISCOUNTCALCULATION_DESC_',
  /** Sort on InvoiceDiscountValue ascending */
  InvoicediscountvalueAsc = '_INVOICEDISCOUNTVALUE_ASC_',
  /** sort on InvoiceDiscountValue descending */
  InvoicediscountvalueDesc = '_INVOICEDISCOUNTVALUE_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on PrepaymentInvoice ascending */
  PrepaymentinvoiceAsc = '_PREPAYMENTINVOICE_ASC_',
  /** sort on PrepaymentInvoice descending */
  PrepaymentinvoiceDesc = '_PREPAYMENTINVOICE_DESC_',
  /** Sort on PrepaymentOrderNo ascending */
  PrepaymentordernoAsc = '_PREPAYMENTORDERNO_ASC_',
  /** sort on PrepaymentOrderNo descending */
  PrepaymentordernoDesc = '_PREPAYMENTORDERNO_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on WorkDescription ascending */
  WorkdescriptionAsc = '_WORKDESCRIPTION_ASC_',
  /** sort on WorkDescription descending */
  WorkdescriptionDesc = '_WORKDESCRIPTION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on PaymentServiceSetID ascending */
  PaymentservicesetidAsc = '_PAYMENTSERVICESETID_ASC_',
  /** sort on PaymentServiceSetID descending */
  PaymentservicesetidDesc = '_PAYMENTSERVICESETID_DESC_',
  /** Sort on DocumentExchangeIdentifier ascending */
  DocumentexchangeidentifierAsc = '_DOCUMENTEXCHANGEIDENTIFIER_ASC_',
  /** sort on DocumentExchangeIdentifier descending */
  DocumentexchangeidentifierDesc = '_DOCUMENTEXCHANGEIDENTIFIER_DESC_',
  /** Sort on DocumentExchangeStatus ascending */
  DocumentexchangestatusAsc = '_DOCUMENTEXCHANGESTATUS_ASC_',
  /** sort on DocumentExchangeStatus descending */
  DocumentexchangestatusDesc = '_DOCUMENTEXCHANGESTATUS_DESC_',
  /** Sort on DocExchOriginalIdentifier ascending */
  DocexchoriginalidentifierAsc = '_DOCEXCHORIGINALIDENTIFIER_ASC_',
  /** sort on DocExchOriginalIdentifier descending */
  DocexchoriginalidentifierDesc = '_DOCEXCHORIGINALIDENTIFIER_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on DirectDebitMandateID ascending */
  DirectdebitmandateidAsc = '_DIRECTDEBITMANDATEID_ASC_',
  /** sort on DirectDebitMandateID descending */
  DirectdebitmandateidDesc = '_DIRECTDEBITMANDATEID_DESC_',
  /** Sort on CustLedgerEntryNo ascending */
  CustledgerentrynoAsc = '_CUSTLEDGERENTRYNO_ASC_',
  /** sort on CustLedgerEntryNo descending */
  CustledgerentrynoDesc = '_CUSTLEDGERENTRYNO_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on SelltoContactNo ascending */
  SelltocontactnoAsc = '_SELLTOCONTACTNO_ASC_',
  /** sort on SelltoContactNo descending */
  SelltocontactnoDesc = '_SELLTOCONTACTNO_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on OpportunityNo ascending */
  OpportunitynoAsc = '_OPPORTUNITYNO_ASC_',
  /** sort on OpportunityNo descending */
  OpportunitynoDesc = '_OPPORTUNITYNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on GetShipmentUsed ascending */
  GetshipmentusedAsc = '_GETSHIPMENTUSED_ASC_',
  /** sort on GetShipmentUsed descending */
  GetshipmentusedDesc = '_GETSHIPMENTUSED_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on EQMShipmentType ascending */
  EqmshipmenttypeAsc = '_EQMSHIPMENTTYPE_ASC_',
  /** sort on EQMShipmentType descending */
  EqmshipmenttypeDesc = '_EQMSHIPMENTTYPE_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMCustomerType ascending */
  EqmcustomertypeAsc = '_EQMCUSTOMERTYPE_ASC_',
  /** sort on EQMCustomerType descending */
  EqmcustomertypeDesc = '_EQMCUSTOMERTYPE_DESC_',
  /** Sort on EQMRentalDocument ascending */
  EqmrentaldocumentAsc = '_EQMRENTALDOCUMENT_ASC_',
  /** sort on EQMRentalDocument descending */
  EqmrentaldocumentDesc = '_EQMRENTALDOCUMENT_DESC_',
  /** Sort on EQMTransferMethod ascending */
  EqmtransfermethodAsc = '_EQMTRANSFERMETHOD_ASC_',
  /** sort on EQMTransferMethod descending */
  EqmtransfermethodDesc = '_EQMTRANSFERMETHOD_DESC_',
  /** Sort on EQMWorkshopDocument ascending */
  EqmworkshopdocumentAsc = '_EQMWORKSHOPDOCUMENT_ASC_',
  /** sort on EQMWorkshopDocument descending */
  EqmworkshopdocumentDesc = '_EQMWORKSHOPDOCUMENT_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMCombineCustomerProj ascending */
  EqmcombinecustomerprojAsc = '_EQMCOMBINECUSTOMERPROJ_ASC_',
  /** sort on EQMCombineCustomerProj descending */
  EqmcombinecustomerprojDesc = '_EQMCOMBINECUSTOMERPROJ_DESC_',
  /** Sort on EQMServiceTermNo ascending */
  EqmservicetermnoAsc = '_EQMSERVICETERMNO_ASC_',
  /** sort on EQMServiceTermNo descending */
  EqmservicetermnoDesc = '_EQMSERVICETERMNO_DESC_',
  /** Sort on EQMInvoiceDocType ascending */
  EqminvoicedoctypeAsc = '_EQMINVOICEDOCTYPE_ASC_',
  /** sort on EQMInvoiceDocType descending */
  EqminvoicedoctypeDesc = '_EQMINVOICEDOCTYPE_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMJobNo ascending */
  EqmjobnoAsc = '_EQMJOBNO_ASC_',
  /** sort on EQMJobNo descending */
  EqmjobnoDesc = '_EQMJOBNO_DESC_',
  /** Sort on EQMJobTaskNo ascending */
  EqmjobtasknoAsc = '_EQMJOBTASKNO_ASC_',
  /** sort on EQMJobTaskNo descending */
  EqmjobtasknoDesc = '_EQMJOBTASKNO_DESC_',
  /** Sort on EQMWSObjectCounter ascending */
  EqmwsobjectcounterAsc = '_EQMWSOBJECTCOUNTER_ASC_',
  /** sort on EQMWSObjectCounter descending */
  EqmwsobjectcounterDesc = '_EQMWSOBJECTCOUNTER_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMServiceStartDate ascending */
  EqmservicestartdateAsc = '_EQMSERVICESTARTDATE_ASC_',
  /** sort on EQMServiceStartDate descending */
  EqmservicestartdateDesc = '_EQMSERVICESTARTDATE_DESC_',
  /** Sort on EQMServiceEndDate ascending */
  EqmserviceenddateAsc = '_EQMSERVICEENDDATE_ASC_',
  /** sort on EQMServiceEndDate descending */
  EqmserviceenddateDesc = '_EQMSERVICEENDDATE_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMObjectWarrantyDate ascending */
  EqmobjectwarrantydateAsc = '_EQMOBJECTWARRANTYDATE_ASC_',
  /** sort on EQMObjectWarrantyDate descending */
  EqmobjectwarrantydateDesc = '_EQMOBJECTWARRANTYDATE_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMWSEstimatedStartDate ascending */
  EqmwsestimatedstartdateAsc = '_EQMWSESTIMATEDSTARTDATE_ASC_',
  /** sort on EQMWSEstimatedStartDate descending */
  EqmwsestimatedstartdateDesc = '_EQMWSESTIMATEDSTARTDATE_DESC_',
  /** Sort on EQMWSEstimatedStartTime ascending */
  EqmwsestimatedstarttimeAsc = '_EQMWSESTIMATEDSTARTTIME_ASC_',
  /** sort on EQMWSEstimatedStartTime descending */
  EqmwsestimatedstarttimeDesc = '_EQMWSESTIMATEDSTARTTIME_DESC_',
  /** Sort on EQMWSEstimatedEndDate ascending */
  EqmwsestimatedenddateAsc = '_EQMWSESTIMATEDENDDATE_ASC_',
  /** sort on EQMWSEstimatedEndDate descending */
  EqmwsestimatedenddateDesc = '_EQMWSESTIMATEDENDDATE_DESC_',
  /** Sort on EQMWSEstimatedEndTime ascending */
  EqmwsestimatedendtimeAsc = '_EQMWSESTIMATEDENDTIME_ASC_',
  /** sort on EQMWSEstimatedEndTime descending */
  EqmwsestimatedendtimeDesc = '_EQMWSESTIMATEDENDTIME_DESC_',
  /** Sort on EQMServiceStartTime ascending */
  EqmservicestarttimeAsc = '_EQMSERVICESTARTTIME_ASC_',
  /** sort on EQMServiceStartTime descending */
  EqmservicestarttimeDesc = '_EQMSERVICESTARTTIME_DESC_',
  /** Sort on EQMServiceEndTime ascending */
  EqmserviceendtimeAsc = '_EQMSERVICEENDTIME_ASC_',
  /** sort on EQMServiceEndTime descending */
  EqmserviceendtimeDesc = '_EQMSERVICEENDTIME_DESC_',
  /** Sort on EQMWSObjectCounter2 ascending */
  Eqmwsobjectcounter2Asc = '_EQMWSOBJECTCOUNTER2_ASC_',
  /** sort on EQMWSObjectCounter2 descending */
  Eqmwsobjectcounter2Desc = '_EQMWSOBJECTCOUNTER2_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on LastEmailSentTime ascending */
  LastemailsenttimeAsc = '_LASTEMAILSENTTIME_ASC_',
  /** sort on LastEmailSentTime descending */
  LastemailsenttimeDesc = '_LASTEMAILSENTTIME_DESC_',
  /** Sort on LastEmailSentStatus ascending */
  LastemailsentstatusAsc = '_LASTEMAILSENTSTATUS_ASC_',
  /** sort on LastEmailSentStatus descending */
  LastemailsentstatusDesc = '_LASTEMAILSENTSTATUS_DESC_',
  /** Sort on SentasEmail ascending */
  SentasemailAsc = '_SENTASEMAIL_ASC_',
  /** sort on SentasEmail descending */
  SentasemailDesc = '_SENTASEMAIL_DESC_',
  /** Sort on LastEmailNotifCleared ascending */
  LastemailnotifclearedAsc = '_LASTEMAILNOTIFCLEARED_ASC_',
  /** sort on LastEmailNotifCleared descending */
  LastemailnotifclearedDesc = '_LASTEMAILNOTIFCLEARED_DESC_',
  /** Sort on Closed ascending */
  ClosedAsc = '_CLOSED_ASC_',
  /** sort on Closed descending */
  ClosedDesc = '_CLOSED_DESC_',
  /** Sort on RemainingAmount ascending */
  RemainingamountAsc = '_REMAININGAMOUNT_ASC_',
  /** sort on RemainingAmount descending */
  RemainingamountDesc = '_REMAININGAMOUNT_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on Cancelled ascending */
  CancelledAsc = '_CANCELLED_ASC_',
  /** sort on Cancelled descending */
  CancelledDesc = '_CANCELLED_DESC_',
  /** Sort on Corrective ascending */
  CorrectiveAsc = '_CORRECTIVE_ASC_',
  /** sort on Corrective descending */
  CorrectiveDesc = '_CORRECTIVE_DESC_',
  /** Sort on EQMLinkedCreditMemos ascending */
  EqmlinkedcreditmemosAsc = '_EQMLINKEDCREDITMEMOS_ASC_',
  /** sort on EQMLinkedCreditMemos descending */
  EqmlinkedcreditmemosDesc = '_EQMLINKEDCREDITMEMOS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortInvoiceLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on ShipmentNo ascending */
  ShipmentnoAsc = '_SHIPMENTNO_ASC_',
  /** sort on ShipmentNo descending */
  ShipmentnoDesc = '_SHIPMENTNO_DESC_',
  /** Sort on ShipmentLineNo ascending */
  ShipmentlinenoAsc = '_SHIPMENTLINENO_ASC_',
  /** sort on ShipmentLineNo descending */
  ShipmentlinenoDesc = '_SHIPMENTLINENO_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on InvDiscountAmount ascending */
  InvdiscountamountAsc = '_INVDISCOUNTAMOUNT_ASC_',
  /** sort on InvDiscountAmount descending */
  InvdiscountamountDesc = '_INVDISCOUNTAMOUNT_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxCategory ascending */
  TaxcategoryAsc = '_TAXCATEGORY_ASC_',
  /** sort on TaxCategory descending */
  TaxcategoryDesc = '_TAXCATEGORY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATClauseCode ascending */
  VatclausecodeAsc = '_VATCLAUSECODE_ASC_',
  /** sort on VATClauseCode descending */
  VatclausecodeDesc = '_VATCLAUSECODE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on LineDiscountCalculation ascending */
  LinediscountcalculationAsc = '_LINEDISCOUNTCALCULATION_ASC_',
  /** sort on LineDiscountCalculation descending */
  LinediscountcalculationDesc = '_LINEDISCOUNTCALCULATION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobContractEntryNo ascending */
  JobcontractentrynoAsc = '_JOBCONTRACTENTRYNO_ASC_',
  /** sort on JobContractEntryNo descending */
  JobcontractentrynoDesc = '_JOBCONTRACTENTRYNO_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ApplfromItemEntry ascending */
  ApplfromitementryAsc = '_APPLFROMITEMENTRY_ASC_',
  /** sort on ApplfromItemEntry descending */
  ApplfromitementryDesc = '_APPLFROMITEMENTRY_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on Pricedescription ascending */
  PricedescriptionAsc = '_PRICEDESCRIPTION_ASC_',
  /** sort on Pricedescription descending */
  PricedescriptionDesc = '_PRICEDESCRIPTION_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMRental ascending */
  EqmrentalAsc = '_EQMRENTAL_ASC_',
  /** sort on EQMRental descending */
  EqmrentalDesc = '_EQMRENTAL_DESC_',
  /** Sort on EQMRentalFromDate ascending */
  EqmrentalfromdateAsc = '_EQMRENTALFROMDATE_ASC_',
  /** sort on EQMRentalFromDate descending */
  EqmrentalfromdateDesc = '_EQMRENTALFROMDATE_DESC_',
  /** Sort on EQMRentalToDate ascending */
  EqmrentaltodateAsc = '_EQMRENTALTODATE_ASC_',
  /** sort on EQMRentalToDate descending */
  EqmrentaltodateDesc = '_EQMRENTALTODATE_DESC_',
  /** Sort on EQMRentalLineNo ascending */
  EqmrentallinenoAsc = '_EQMRENTALLINENO_ASC_',
  /** sort on EQMRentalLineNo descending */
  EqmrentallinenoDesc = '_EQMRENTALLINENO_DESC_',
  /** Sort on EQMRentalDays ascending */
  EqmrentaldaysAsc = '_EQMRENTALDAYS_ASC_',
  /** sort on EQMRentalDays descending */
  EqmrentaldaysDesc = '_EQMRENTALDAYS_DESC_',
  /** Sort on EQMAttachedtoNo ascending */
  EqmattachedtonoAsc = '_EQMATTACHEDTONO_ASC_',
  /** sort on EQMAttachedtoNo descending */
  EqmattachedtonoDesc = '_EQMATTACHEDTONO_DESC_',
  /** Sort on EQMAttachedtoLineNo ascending */
  EqmattachedtolinenoAsc = '_EQMATTACHEDTOLINENO_ASC_',
  /** sort on EQMAttachedtoLineNo descending */
  EqmattachedtolinenoDesc = '_EQMATTACHEDTOLINENO_DESC_',
  /** Sort on EQMRentalFreeDays ascending */
  EqmrentalfreedaysAsc = '_EQMRENTALFREEDAYS_ASC_',
  /** sort on EQMRentalFreeDays descending */
  EqmrentalfreedaysDesc = '_EQMRENTALFREEDAYS_DESC_',
  /** Sort on EQMLineUpdated ascending */
  EqmlineupdatedAsc = '_EQMLINEUPDATED_ASC_',
  /** sort on EQMLineUpdated descending */
  EqmlineupdatedDesc = '_EQMLINEUPDATED_DESC_',
  /** Sort on EQMRentalSale ascending */
  EqmrentalsaleAsc = '_EQMRENTALSALE_ASC_',
  /** sort on EQMRentalSale descending */
  EqmrentalsaleDesc = '_EQMRENTALSALE_DESC_',
  /** Sort on EQMRentalQuantity ascending */
  EqmrentalquantityAsc = '_EQMRENTALQUANTITY_ASC_',
  /** sort on EQMRentalQuantity descending */
  EqmrentalquantityDesc = '_EQMRENTALQUANTITY_DESC_',
  /** Sort on EQMRentalNo ascending */
  EqmrentalnoAsc = '_EQMRENTALNO_ASC_',
  /** sort on EQMRentalNo descending */
  EqmrentalnoDesc = '_EQMRENTALNO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMRentalFromDateTime ascending */
  EqmrentalfromdatetimeAsc = '_EQMRENTALFROMDATETIME_ASC_',
  /** sort on EQMRentalFromDateTime descending */
  EqmrentalfromdatetimeDesc = '_EQMRENTALFROMDATETIME_DESC_',
  /** Sort on EQMRentalToDateTime ascending */
  EqmrentaltodatetimeAsc = '_EQMRENTALTODATETIME_ASC_',
  /** sort on EQMRentalToDateTime descending */
  EqmrentaltodatetimeDesc = '_EQMRENTALTODATETIME_DESC_',
  /** Sort on EQMUnitPrice ascending */
  EqmunitpriceAsc = '_EQMUNITPRICE_ASC_',
  /** sort on EQMUnitPrice descending */
  EqmunitpriceDesc = '_EQMUNITPRICE_DESC_',
  /** Sort on EQMLineDiscount ascending */
  EqmlinediscountAsc = '_EQMLINEDISCOUNT_ASC_',
  /** sort on EQMLineDiscount descending */
  EqmlinediscountDesc = '_EQMLINEDISCOUNT_DESC_',
  /** Sort on EQMLineDiscountAmount ascending */
  EqmlinediscountamountAsc = '_EQMLINEDISCOUNTAMOUNT_ASC_',
  /** sort on EQMLineDiscountAmount descending */
  EqmlinediscountamountDesc = '_EQMLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on EQMLineAmount ascending */
  EqmlineamountAsc = '_EQMLINEAMOUNT_ASC_',
  /** sort on EQMLineAmount descending */
  EqmlineamountDesc = '_EQMLINEAMOUNT_DESC_',
  /** Sort on EQMReturnFromDate ascending */
  EqmreturnfromdateAsc = '_EQMRETURNFROMDATE_ASC_',
  /** sort on EQMReturnFromDate descending */
  EqmreturnfromdateDesc = '_EQMRETURNFROMDATE_DESC_',
  /** Sort on EQMReturnToDate ascending */
  EqmreturntodateAsc = '_EQMRETURNTODATE_ASC_',
  /** sort on EQMReturnToDate descending */
  EqmreturntodateDesc = '_EQMRETURNTODATE_DESC_',
  /** Sort on EQMReturnWholePeriod ascending */
  EqmreturnwholeperiodAsc = '_EQMRETURNWHOLEPERIOD_ASC_',
  /** sort on EQMReturnWholePeriod descending */
  EqmreturnwholeperiodDesc = '_EQMRETURNWHOLEPERIOD_DESC_',
  /** Sort on EQMReturnRentalDays ascending */
  EqmreturnrentaldaysAsc = '_EQMRETURNRENTALDAYS_ASC_',
  /** sort on EQMReturnRentalDays descending */
  EqmreturnrentaldaysDesc = '_EQMRETURNRENTALDAYS_DESC_',
  /** Sort on EQMReturnFreeDays ascending */
  EqmreturnfreedaysAsc = '_EQMRETURNFREEDAYS_ASC_',
  /** sort on EQMReturnFreeDays descending */
  EqmreturnfreedaysDesc = '_EQMRETURNFREEDAYS_DESC_',
  /** Sort on EQMTransferThisLine ascending */
  EqmtransferthislineAsc = '_EQMTRANSFERTHISLINE_ASC_',
  /** sort on EQMTransferThisLine descending */
  EqmtransferthislineDesc = '_EQMTRANSFERTHISLINE_DESC_',
  /** Sort on EQMReturnQuantity ascending */
  EqmreturnquantityAsc = '_EQMRETURNQUANTITY_ASC_',
  /** sort on EQMReturnQuantity descending */
  EqmreturnquantityDesc = '_EQMRETURNQUANTITY_DESC_',
  /** Sort on EQMRentalDuration ascending */
  EqmrentaldurationAsc = '_EQMRENTALDURATION_ASC_',
  /** sort on EQMRentalDuration descending */
  EqmrentaldurationDesc = '_EQMRENTALDURATION_DESC_',
  /** Sort on EQMServiceUpwrite ascending */
  EqmserviceupwriteAsc = '_EQMSERVICEUPWRITE_ASC_',
  /** sort on EQMServiceUpwrite descending */
  EqmserviceupwriteDesc = '_EQMSERVICEUPWRITE_DESC_',
  /** Sort on EQMRentalLineType ascending */
  EqmrentallinetypeAsc = '_EQMRENTALLINETYPE_ASC_',
  /** sort on EQMRentalLineType descending */
  EqmrentallinetypeDesc = '_EQMRENTALLINETYPE_DESC_',
  /** Sort on EQMUseOfFixPrice ascending */
  EqmuseoffixpriceAsc = '_EQMUSEOFFIXPRICE_ASC_',
  /** sort on EQMUseOfFixPrice descending */
  EqmuseoffixpriceDesc = '_EQMUSEOFFIXPRICE_DESC_',
  /** Sort on EQMUpdateRentalLine ascending */
  EqmupdaterentallineAsc = '_EQMUPDATERENTALLINE_ASC_',
  /** sort on EQMUpdateRentalLine descending */
  EqmupdaterentallineDesc = '_EQMUPDATERENTALLINE_DESC_',
  /** Sort on EQMWorkshop ascending */
  EqmworkshopAsc = '_EQMWORKSHOP_ASC_',
  /** sort on EQMWorkshop descending */
  EqmworkshopDesc = '_EQMWORKSHOP_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMWorkshopGuarantee ascending */
  EqmworkshopguaranteeAsc = '_EQMWORKSHOPGUARANTEE_ASC_',
  /** sort on EQMWorkshopGuarantee descending */
  EqmworkshopguaranteeDesc = '_EQMWORKSHOPGUARANTEE_DESC_',
  /** Sort on EQMWorkshopType ascending */
  EqmworkshoptypeAsc = '_EQMWORKSHOPTYPE_ASC_',
  /** sort on EQMWorkshopType descending */
  EqmworkshoptypeDesc = '_EQMWORKSHOPTYPE_DESC_',
  /** Sort on EQMSkipObjectIntoInv ascending */
  EqmskipobjectintoinvAsc = '_EQMSKIPOBJECTINTOINV_ASC_',
  /** sort on EQMSkipObjectIntoInv descending */
  EqmskipobjectintoinvDesc = '_EQMSKIPOBJECTINTOINV_DESC_',
  /** Sort on EQMRefBasisEntryNo ascending */
  EqmrefbasisentrynoAsc = '_EQMREFBASISENTRYNO_ASC_',
  /** sort on EQMRefBasisEntryNo descending */
  EqmrefbasisentrynoDesc = '_EQMREFBASISENTRYNO_DESC_',
  /** Sort on EQMAmountCost ascending */
  EqmamountcostAsc = '_EQMAMOUNTCOST_ASC_',
  /** sort on EQMAmountCost descending */
  EqmamountcostDesc = '_EQMAMOUNTCOST_DESC_',
  /** Sort on EQMStairPriceCode ascending */
  EqmstairpricecodeAsc = '_EQMSTAIRPRICECODE_ASC_',
  /** sort on EQMStairPriceCode descending */
  EqmstairpricecodeDesc = '_EQMSTAIRPRICECODE_DESC_',
  /** Sort on EQMStairPriceStep ascending */
  EqmstairpricestepAsc = '_EQMSTAIRPRICESTEP_ASC_',
  /** sort on EQMStairPriceStep descending */
  EqmstairpricestepDesc = '_EQMSTAIRPRICESTEP_DESC_',
  /** Sort on EQMRentalKITMother ascending */
  EqmrentalkitmotherAsc = '_EQMRENTALKITMOTHER_ASC_',
  /** sort on EQMRentalKITMother descending */
  EqmrentalkitmotherDesc = '_EQMRENTALKITMOTHER_DESC_',
  /** Sort on EQMRentalKITMotherLineNo ascending */
  EqmrentalkitmotherlinenoAsc = '_EQMRENTALKITMOTHERLINENO_ASC_',
  /** sort on EQMRentalKITMotherLineNo descending */
  EqmrentalkitmotherlinenoDesc = '_EQMRENTALKITMOTHERLINENO_DESC_',
  /** Sort on EQMDepositPosting ascending */
  EqmdepositpostingAsc = '_EQMDEPOSITPOSTING_ASC_',
  /** sort on EQMDepositPosting descending */
  EqmdepositpostingDesc = '_EQMDEPOSITPOSTING_DESC_',
  /** Sort on EQMReRentObject ascending */
  EqmrerentobjectAsc = '_EQMRERENTOBJECT_ASC_',
  /** sort on EQMReRentObject descending */
  EqmrerentobjectDesc = '_EQMRERENTOBJECT_DESC_',
  /** Sort on EQMUsedFractionCalculation ascending */
  EqmusedfractioncalculationAsc = '_EQMUSEDFRACTIONCALCULATION_ASC_',
  /** sort on EQMUsedFractionCalculation descending */
  EqmusedfractioncalculationDesc = '_EQMUSEDFRACTIONCALCULATION_DESC_',
  /** Sort on EQMRentalHeaderLine ascending */
  EqmrentalheaderlineAsc = '_EQMRENTALHEADERLINE_ASC_',
  /** sort on EQMRentalHeaderLine descending */
  EqmrentalheaderlineDesc = '_EQMRENTALHEADERLINE_DESC_',
  /** Sort on EQMSerialNo ascending */
  EqmserialnoAsc = '_EQMSERIALNO_ASC_',
  /** sort on EQMSerialNo descending */
  EqmserialnoDesc = '_EQMSERIALNO_DESC_',
  /** Sort on EQMRefOrigDoc ascending */
  EqmreforigdocAsc = '_EQMREFORIGDOC_ASC_',
  /** sort on EQMRefOrigDoc descending */
  EqmreforigdocDesc = '_EQMREFORIGDOC_DESC_',
  /** Sort on EQMRefOrigLineNo ascending */
  EqmreforiglinenoAsc = '_EQMREFORIGLINENO_ASC_',
  /** sort on EQMRefOrigLineNo descending */
  EqmreforiglinenoDesc = '_EQMREFORIGLINENO_DESC_',
  /** Sort on EQMManuallyChanged ascending */
  EqmmanuallychangedAsc = '_EQMMANUALLYCHANGED_ASC_',
  /** sort on EQMManuallyChanged descending */
  EqmmanuallychangedDesc = '_EQMMANUALLYCHANGED_DESC_',
  /** Sort on EQMReclassOrderedBy ascending */
  EqmreclassorderedbyAsc = '_EQMRECLASSORDEREDBY_ASC_',
  /** sort on EQMReclassOrderedBy descending */
  EqmreclassorderedbyDesc = '_EQMRECLASSORDEREDBY_DESC_',
  /** Sort on EQMReclassOrderNo ascending */
  EqmreclassordernoAsc = '_EQMRECLASSORDERNO_ASC_',
  /** sort on EQMReclassOrderNo descending */
  EqmreclassordernoDesc = '_EQMRECLASSORDERNO_DESC_',
  /** Sort on EQMExtraHoursCharged ascending */
  EqmextrahourschargedAsc = '_EQMEXTRAHOURSCHARGED_ASC_',
  /** sort on EQMExtraHoursCharged descending */
  EqmextrahourschargedDesc = '_EQMEXTRAHOURSCHARGED_DESC_',
  /** Sort on EQMExtraHoursReturnNo ascending */
  EqmextrahoursreturnnoAsc = '_EQMEXTRAHOURSRETURNNO_ASC_',
  /** sort on EQMExtraHoursReturnNo descending */
  EqmextrahoursreturnnoDesc = '_EQMEXTRAHOURSRETURNNO_DESC_',
  /** Sort on EQMRentalSplitCostCenter ascending */
  EqmrentalsplitcostcenterAsc = '_EQMRENTALSPLITCOSTCENTER_ASC_',
  /** sort on EQMRentalSplitCostCenter descending */
  EqmrentalsplitcostcenterDesc = '_EQMRENTALSPLITCOSTCENTER_DESC_',
  /** Sort on EQMRentalSplit ascending */
  EqmrentalsplitAsc = '_EQMRENTALSPLIT_ASC_',
  /** sort on EQMRentalSplit descending */
  EqmrentalsplitDesc = '_EQMRENTALSPLIT_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMRePurchase ascending */
  EqmrepurchaseAsc = '_EQMREPURCHASE_ASC_',
  /** sort on EQMRePurchase descending */
  EqmrepurchaseDesc = '_EQMREPURCHASE_DESC_',
  /** Sort on EQMInvoiceFee ascending */
  EqminvoicefeeAsc = '_EQMINVOICEFEE_ASC_',
  /** sort on EQMInvoiceFee descending */
  EqminvoicefeeDesc = '_EQMINVOICEFEE_DESC_',
  /** Sort on EQMPriceScheduleCode ascending */
  EqmpriceschedulecodeAsc = '_EQMPRICESCHEDULECODE_ASC_',
  /** sort on EQMPriceScheduleCode descending */
  EqmpriceschedulecodeDesc = '_EQMPRICESCHEDULECODE_DESC_',
  /** Sort on EQMAdvancePaymentEntry ascending */
  EqmadvancepaymententryAsc = '_EQMADVANCEPAYMENTENTRY_ASC_',
  /** sort on EQMAdvancePaymentEntry descending */
  EqmadvancepaymententryDesc = '_EQMADVANCEPAYMENTENTRY_DESC_',
  /** Sort on EQMRentalInsurance ascending */
  EqmrentalinsuranceAsc = '_EQMRENTALINSURANCE_ASC_',
  /** sort on EQMRentalInsurance descending */
  EqmrentalinsuranceDesc = '_EQMRENTALINSURANCE_DESC_',
  /** Sort on EQMObjectInsurance ascending */
  EqmobjectinsuranceAsc = '_EQMOBJECTINSURANCE_ASC_',
  /** sort on EQMObjectInsurance descending */
  EqmobjectinsuranceDesc = '_EQMOBJECTINSURANCE_DESC_',
  /** Sort on EQMServiceMotherLineNo ascending */
  EqmservicemotherlinenoAsc = '_EQMSERVICEMOTHERLINENO_ASC_',
  /** sort on EQMServiceMotherLineNo descending */
  EqmservicemotherlinenoDesc = '_EQMSERVICEMOTHERLINENO_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMPriceReportedHours ascending */
  EqmpricereportedhoursAsc = '_EQMPRICEREPORTEDHOURS_ASC_',
  /** sort on EQMPriceReportedHours descending */
  EqmpricereportedhoursDesc = '_EQMPRICEREPORTEDHOURS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemCategoryInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on ParentCategory ascending */
  ParentcategoryAsc = '_PARENTCATEGORY_ASC_',
  /** sort on ParentCategory descending */
  ParentcategoryDesc = '_PARENTCATEGORY_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Indentation ascending */
  IndentationAsc = '_INDENTATION_ASC_',
  /** sort on Indentation descending */
  IndentationDesc = '_INDENTATION_DESC_',
  /** Sort on PresentationOrder ascending */
  PresentationorderAsc = '_PRESENTATIONORDER_ASC_',
  /** sort on PresentationOrder descending */
  PresentationorderDesc = '_PRESENTATIONORDER_DESC_',
  /** Sort on HasChildren ascending */
  HaschildrenAsc = '_HASCHILDREN_ASC_',
  /** sort on HasChildren descending */
  HaschildrenDesc = '_HASCHILDREN_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on DefGenProdPostingGroup ascending */
  DefgenprodpostinggroupAsc = '_DEFGENPRODPOSTINGGROUP_ASC_',
  /** sort on DefGenProdPostingGroup descending */
  DefgenprodpostinggroupDesc = '_DEFGENPRODPOSTINGGROUP_DESC_',
  /** Sort on DefInventoryPostingGroup ascending */
  DefinventorypostinggroupAsc = '_DEFINVENTORYPOSTINGGROUP_ASC_',
  /** sort on DefInventoryPostingGroup descending */
  DefinventorypostinggroupDesc = '_DEFINVENTORYPOSTINGGROUP_DESC_',
  /** Sort on DefTaxGroupCode ascending */
  DeftaxgroupcodeAsc = '_DEFTAXGROUPCODE_ASC_',
  /** sort on DefTaxGroupCode descending */
  DeftaxgroupcodeDesc = '_DEFTAXGROUPCODE_DESC_',
  /** Sort on DefCostingMethod ascending */
  DefcostingmethodAsc = '_DEFCOSTINGMETHOD_ASC_',
  /** sort on DefCostingMethod descending */
  DefcostingmethodDesc = '_DEFCOSTINGMETHOD_DESC_',
  /** Sort on DefCostingMethodOPTION ascending */
  DefcostingmethodoptionAsc = '_DEFCOSTINGMETHODOPTION_ASC_',
  /** sort on DefCostingMethodOPTION descending */
  DefcostingmethodoptionDesc = '_DEFCOSTINGMETHODOPTION_DESC_',
  /** Sort on DefVATProdPostingGroup ascending */
  DefvatprodpostinggroupAsc = '_DEFVATPRODPOSTINGGROUP_ASC_',
  /** sort on DefVATProdPostingGroup descending */
  DefvatprodpostinggroupDesc = '_DEFVATPRODPOSTINGGROUP_DESC_',
  /** Sort on DefReRentRProdPostGrp ascending */
  DefrerentrprodpostgrpAsc = '_DEFRERENTRPRODPOSTGRP_ASC_',
  /** sort on DefReRentRProdPostGrp descending */
  DefrerentrprodpostgrpDesc = '_DEFRERENTRPRODPOSTGRP_DESC_',
  /** Sort on DefReRentSProdPostGrp ascending */
  DefrerentsprodpostgrpAsc = '_DEFRERENTSPRODPOSTGRP_ASC_',
  /** sort on DefReRentSProdPostGrp descending */
  DefrerentsprodpostgrpDesc = '_DEFRERENTSPRODPOSTGRP_DESC_',
  /** Sort on EQMDefGenRntlPostGrp ascending */
  EqmdefgenrntlpostgrpAsc = '_EQMDEFGENRNTLPOSTGRP_ASC_',
  /** sort on EQMDefGenRntlPostGrp descending */
  EqmdefgenrntlpostgrpDesc = '_EQMDEFGENRNTLPOSTGRP_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemDiscGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on No2 ascending */
  No2Asc = '_NO2_ASC_',
  /** sort on No2 descending */
  No2Desc = '_NO2_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SearchDescription ascending */
  SearchdescriptionAsc = '_SEARCHDESCRIPTION_ASC_',
  /** sort on SearchDescription descending */
  SearchdescriptionDesc = '_SEARCHDESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on BaseUnitofMeasure ascending */
  BaseunitofmeasureAsc = '_BASEUNITOFMEASURE_ASC_',
  /** sort on BaseUnitofMeasure descending */
  BaseunitofmeasureDesc = '_BASEUNITOFMEASURE_DESC_',
  /** Sort on PriceUnitConversion ascending */
  PriceunitconversionAsc = '_PRICEUNITCONVERSION_ASC_',
  /** sort on PriceUnitConversion descending */
  PriceunitconversionDesc = '_PRICEUNITCONVERSION_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on ShelfNo ascending */
  ShelfnoAsc = '_SHELFNO_ASC_',
  /** sort on ShelfNo descending */
  ShelfnoDesc = '_SHELFNO_DESC_',
  /** Sort on ItemDiscGroup ascending */
  ItemdiscgroupAsc = '_ITEMDISCGROUP_ASC_',
  /** sort on ItemDiscGroup descending */
  ItemdiscgroupDesc = '_ITEMDISCGROUP_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on StatisticsGroup ascending */
  StatisticsgroupAsc = '_STATISTICSGROUP_ASC_',
  /** sort on StatisticsGroup descending */
  StatisticsgroupDesc = '_STATISTICSGROUP_DESC_',
  /** Sort on CommissionGroup ascending */
  CommissiongroupAsc = '_COMMISSIONGROUP_ASC_',
  /** sort on CommissionGroup descending */
  CommissiongroupDesc = '_COMMISSIONGROUP_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceProfitCalculation ascending */
  PriceprofitcalculationAsc = '_PRICEPROFITCALCULATION_ASC_',
  /** sort on PriceProfitCalculation descending */
  PriceprofitcalculationDesc = '_PRICEPROFITCALCULATION_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on CostingMethod ascending */
  CostingmethodAsc = '_COSTINGMETHOD_ASC_',
  /** sort on CostingMethod descending */
  CostingmethodDesc = '_COSTINGMETHOD_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on StandardCost ascending */
  StandardcostAsc = '_STANDARDCOST_ASC_',
  /** sort on StandardCost descending */
  StandardcostDesc = '_STANDARDCOST_DESC_',
  /** Sort on LastDirectCost ascending */
  LastdirectcostAsc = '_LASTDIRECTCOST_ASC_',
  /** sort on LastDirectCost descending */
  LastdirectcostDesc = '_LASTDIRECTCOST_DESC_',
  /** Sort on IndirectCost ascending */
  IndirectcostAsc = '_INDIRECTCOST_ASC_',
  /** sort on IndirectCost descending */
  IndirectcostDesc = '_INDIRECTCOST_DESC_',
  /** Sort on CostisAdjusted ascending */
  CostisadjustedAsc = '_COSTISADJUSTED_ASC_',
  /** sort on CostisAdjusted descending */
  CostisadjustedDesc = '_COSTISADJUSTED_DESC_',
  /** Sort on AllowOnlineAdjustment ascending */
  AllowonlineadjustmentAsc = '_ALLOWONLINEADJUSTMENT_ASC_',
  /** sort on AllowOnlineAdjustment descending */
  AllowonlineadjustmentDesc = '_ALLOWONLINEADJUSTMENT_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on ReorderPoint ascending */
  ReorderpointAsc = '_REORDERPOINT_ASC_',
  /** sort on ReorderPoint descending */
  ReorderpointDesc = '_REORDERPOINT_DESC_',
  /** Sort on MaximumInventory ascending */
  MaximuminventoryAsc = '_MAXIMUMINVENTORY_ASC_',
  /** sort on MaximumInventory descending */
  MaximuminventoryDesc = '_MAXIMUMINVENTORY_DESC_',
  /** Sort on ReorderQuantity ascending */
  ReorderquantityAsc = '_REORDERQUANTITY_ASC_',
  /** sort on ReorderQuantity descending */
  ReorderquantityDesc = '_REORDERQUANTITY_DESC_',
  /** Sort on AlternativeItemNo ascending */
  AlternativeitemnoAsc = '_ALTERNATIVEITEMNO_ASC_',
  /** sort on AlternativeItemNo descending */
  AlternativeitemnoDesc = '_ALTERNATIVEITEMNO_DESC_',
  /** Sort on UnitListPrice ascending */
  UnitlistpriceAsc = '_UNITLISTPRICE_ASC_',
  /** sort on UnitListPrice descending */
  UnitlistpriceDesc = '_UNITLISTPRICE_DESC_',
  /** Sort on DutyDue ascending */
  DutydueAsc = '_DUTYDUE_ASC_',
  /** sort on DutyDue descending */
  DutydueDesc = '_DUTYDUE_DESC_',
  /** Sort on DutyCode ascending */
  DutycodeAsc = '_DUTYCODE_ASC_',
  /** sort on DutyCode descending */
  DutycodeDesc = '_DUTYCODE_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on Durability ascending */
  DurabilityAsc = '_DURABILITY_ASC_',
  /** sort on Durability descending */
  DurabilityDesc = '_DURABILITY_DESC_',
  /** Sort on FreightType ascending */
  FreighttypeAsc = '_FREIGHTTYPE_ASC_',
  /** sort on FreightType descending */
  FreighttypeDesc = '_FREIGHTTYPE_DESC_',
  /** Sort on TariffNo ascending */
  TariffnoAsc = '_TARIFFNO_ASC_',
  /** sort on TariffNo descending */
  TariffnoDesc = '_TARIFFNO_DESC_',
  /** Sort on DutyUnitConversion ascending */
  DutyunitconversionAsc = '_DUTYUNITCONVERSION_ASC_',
  /** sort on DutyUnitConversion descending */
  DutyunitconversionDesc = '_DUTYUNITCONVERSION_DESC_',
  /** Sort on CountryRegionPurchasedCode ascending */
  CountryregionpurchasedcodeAsc = '_COUNTRYREGIONPURCHASEDCODE_ASC_',
  /** sort on CountryRegionPurchasedCode descending */
  CountryregionpurchasedcodeDesc = '_COUNTRYREGIONPURCHASEDCODE_DESC_',
  /** Sort on BudgetQuantity ascending */
  BudgetquantityAsc = '_BUDGETQUANTITY_ASC_',
  /** sort on BudgetQuantity descending */
  BudgetquantityDesc = '_BUDGETQUANTITY_DESC_',
  /** Sort on BudgetedAmount ascending */
  BudgetedamountAsc = '_BUDGETEDAMOUNT_ASC_',
  /** sort on BudgetedAmount descending */
  BudgetedamountDesc = '_BUDGETEDAMOUNT_DESC_',
  /** Sort on BudgetProfit ascending */
  BudgetprofitAsc = '_BUDGETPROFIT_ASC_',
  /** sort on BudgetProfit descending */
  BudgetprofitDesc = '_BUDGETPROFIT_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on BlockReason ascending */
  BlockreasonAsc = '_BLOCKREASON_ASC_',
  /** sort on BlockReason descending */
  BlockreasonDesc = '_BLOCKREASON_DESC_',
  /** Sort on LastDateTimeModified ascending */
  LastdatetimemodifiedAsc = '_LASTDATETIMEMODIFIED_ASC_',
  /** sort on LastDateTimeModified descending */
  LastdatetimemodifiedDesc = '_LASTDATETIMEMODIFIED_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on PriceIncludesVAT ascending */
  PriceincludesvatAsc = '_PRICEINCLUDESVAT_ASC_',
  /** sort on PriceIncludesVAT descending */
  PriceincludesvatDesc = '_PRICEINCLUDESVAT_DESC_',
  /** Sort on VATBusPostingGrPrice ascending */
  VatbuspostinggrpriceAsc = '_VATBUSPOSTINGGRPRICE_ASC_',
  /** sort on VATBusPostingGrPrice descending */
  VatbuspostinggrpriceDesc = '_VATBUSPOSTINGGRPRICE_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on CountryRegionofOriginCode ascending */
  CountryregionoforigincodeAsc = '_COUNTRYREGIONOFORIGINCODE_ASC_',
  /** sort on CountryRegionofOriginCode descending */
  CountryregionoforigincodeDesc = '_COUNTRYREGIONOFORIGINCODE_DESC_',
  /** Sort on AutomaticExtTexts ascending */
  AutomaticexttextsAsc = '_AUTOMATICEXTTEXTS_ASC_',
  /** sort on AutomaticExtTexts descending */
  AutomaticexttextsDesc = '_AUTOMATICEXTTEXTS_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on VATProductPostingGroup ascending */
  VatproductpostinggroupAsc = '_VATPRODUCTPOSTINGGROUP_ASC_',
  /** sort on VATProductPostingGroup descending */
  VatproductpostinggroupDesc = '_VATPRODUCTPOSTINGGROUP_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on StockoutWarning ascending */
  StockoutwarningAsc = '_STOCKOUTWARNING_ASC_',
  /** sort on StockoutWarning descending */
  StockoutwarningDesc = '_STOCKOUTWARNING_DESC_',
  /** Sort on PreventNegativeInventory ascending */
  PreventnegativeinventoryAsc = '_PREVENTNEGATIVEINVENTORY_ASC_',
  /** sort on PreventNegativeInventory descending */
  PreventnegativeinventoryDesc = '_PREVENTNEGATIVEINVENTORY_DESC_',
  /** Sort on ApplicationWkshUserID ascending */
  ApplicationwkshuseridAsc = '_APPLICATIONWKSHUSERID_ASC_',
  /** sort on ApplicationWkshUserID descending */
  ApplicationwkshuseridDesc = '_APPLICATIONWKSHUSERID_DESC_',
  /** Sort on AssemblyPolicy ascending */
  AssemblypolicyAsc = '_ASSEMBLYPOLICY_ASC_',
  /** sort on AssemblyPolicy descending */
  AssemblypolicyDesc = '_ASSEMBLYPOLICY_DESC_',
  /** Sort on GTIN ascending */
  GtinAsc = '_GTIN_ASC_',
  /** sort on GTIN descending */
  GtinDesc = '_GTIN_DESC_',
  /** Sort on DefaultDeferralTemplateCode ascending */
  DefaultdeferraltemplatecodeAsc = '_DEFAULTDEFERRALTEMPLATECODE_ASC_',
  /** sort on DefaultDeferralTemplateCode descending */
  DefaultdeferraltemplatecodeDesc = '_DEFAULTDEFERRALTEMPLATECODE_DESC_',
  /** Sort on LowLevelCode ascending */
  LowlevelcodeAsc = '_LOWLEVELCODE_ASC_',
  /** sort on LowLevelCode descending */
  LowlevelcodeDesc = '_LOWLEVELCODE_DESC_',
  /** Sort on LotSize ascending */
  LotsizeAsc = '_LOTSIZE_ASC_',
  /** sort on LotSize descending */
  LotsizeDesc = '_LOTSIZE_DESC_',
  /** Sort on SerialNos ascending */
  SerialnosAsc = '_SERIALNOS_ASC_',
  /** sort on SerialNos descending */
  SerialnosDesc = '_SERIALNOS_DESC_',
  /** Sort on LastUnitCostCalcDate ascending */
  LastunitcostcalcdateAsc = '_LASTUNITCOSTCALCDATE_ASC_',
  /** sort on LastUnitCostCalcDate descending */
  LastunitcostcalcdateDesc = '_LASTUNITCOSTCALCDATE_DESC_',
  /** Sort on RolledupMaterialCost ascending */
  RolledupmaterialcostAsc = '_ROLLEDUPMATERIALCOST_ASC_',
  /** sort on RolledupMaterialCost descending */
  RolledupmaterialcostDesc = '_ROLLEDUPMATERIALCOST_DESC_',
  /** Sort on RolledupCapacityCost ascending */
  RolledupcapacitycostAsc = '_ROLLEDUPCAPACITYCOST_ASC_',
  /** sort on RolledupCapacityCost descending */
  RolledupcapacitycostDesc = '_ROLLEDUPCAPACITYCOST_DESC_',
  /** Sort on Scrap ascending */
  ScrapAsc = '_SCRAP_ASC_',
  /** sort on Scrap descending */
  ScrapDesc = '_SCRAP_DESC_',
  /** Sort on InventoryValueZero ascending */
  InventoryvaluezeroAsc = '_INVENTORYVALUEZERO_ASC_',
  /** sort on InventoryValueZero descending */
  InventoryvaluezeroDesc = '_INVENTORYVALUEZERO_DESC_',
  /** Sort on DiscreteOrderQuantity ascending */
  DiscreteorderquantityAsc = '_DISCRETEORDERQUANTITY_ASC_',
  /** sort on DiscreteOrderQuantity descending */
  DiscreteorderquantityDesc = '_DISCRETEORDERQUANTITY_DESC_',
  /** Sort on MinimumOrderQuantity ascending */
  MinimumorderquantityAsc = '_MINIMUMORDERQUANTITY_ASC_',
  /** sort on MinimumOrderQuantity descending */
  MinimumorderquantityDesc = '_MINIMUMORDERQUANTITY_DESC_',
  /** Sort on MaximumOrderQuantity ascending */
  MaximumorderquantityAsc = '_MAXIMUMORDERQUANTITY_ASC_',
  /** sort on MaximumOrderQuantity descending */
  MaximumorderquantityDesc = '_MAXIMUMORDERQUANTITY_DESC_',
  /** Sort on SafetyStockQuantity ascending */
  SafetystockquantityAsc = '_SAFETYSTOCKQUANTITY_ASC_',
  /** sort on SafetyStockQuantity descending */
  SafetystockquantityDesc = '_SAFETYSTOCKQUANTITY_DESC_',
  /** Sort on OrderMultiple ascending */
  OrdermultipleAsc = '_ORDERMULTIPLE_ASC_',
  /** sort on OrderMultiple descending */
  OrdermultipleDesc = '_ORDERMULTIPLE_DESC_',
  /** Sort on SafetyLeadTime ascending */
  SafetyleadtimeAsc = '_SAFETYLEADTIME_ASC_',
  /** sort on SafetyLeadTime descending */
  SafetyleadtimeDesc = '_SAFETYLEADTIME_DESC_',
  /** Sort on FlushingMethod ascending */
  FlushingmethodAsc = '_FLUSHINGMETHOD_ASC_',
  /** sort on FlushingMethod descending */
  FlushingmethodDesc = '_FLUSHINGMETHOD_DESC_',
  /** Sort on ReplenishmentSystem ascending */
  ReplenishmentsystemAsc = '_REPLENISHMENTSYSTEM_ASC_',
  /** sort on ReplenishmentSystem descending */
  ReplenishmentsystemDesc = '_REPLENISHMENTSYSTEM_DESC_',
  /** Sort on RoundingPrecision ascending */
  RoundingprecisionAsc = '_ROUNDINGPRECISION_ASC_',
  /** sort on RoundingPrecision descending */
  RoundingprecisionDesc = '_ROUNDINGPRECISION_DESC_',
  /** Sort on SalesUnitofMeasure ascending */
  SalesunitofmeasureAsc = '_SALESUNITOFMEASURE_ASC_',
  /** sort on SalesUnitofMeasure descending */
  SalesunitofmeasureDesc = '_SALESUNITOFMEASURE_DESC_',
  /** Sort on PurchUnitofMeasure ascending */
  PurchunitofmeasureAsc = '_PURCHUNITOFMEASURE_ASC_',
  /** sort on PurchUnitofMeasure descending */
  PurchunitofmeasureDesc = '_PURCHUNITOFMEASURE_DESC_',
  /** Sort on TimeBucket ascending */
  TimebucketAsc = '_TIMEBUCKET_ASC_',
  /** sort on TimeBucket descending */
  TimebucketDesc = '_TIMEBUCKET_DESC_',
  /** Sort on ReorderingPolicy ascending */
  ReorderingpolicyAsc = '_REORDERINGPOLICY_ASC_',
  /** sort on ReorderingPolicy descending */
  ReorderingpolicyDesc = '_REORDERINGPOLICY_DESC_',
  /** Sort on IncludeInventory ascending */
  IncludeinventoryAsc = '_INCLUDEINVENTORY_ASC_',
  /** sort on IncludeInventory descending */
  IncludeinventoryDesc = '_INCLUDEINVENTORY_DESC_',
  /** Sort on ManufacturingPolicy ascending */
  ManufacturingpolicyAsc = '_MANUFACTURINGPOLICY_ASC_',
  /** sort on ManufacturingPolicy descending */
  ManufacturingpolicyDesc = '_MANUFACTURINGPOLICY_DESC_',
  /** Sort on ReschedulingPeriod ascending */
  ReschedulingperiodAsc = '_RESCHEDULINGPERIOD_ASC_',
  /** sort on ReschedulingPeriod descending */
  ReschedulingperiodDesc = '_RESCHEDULINGPERIOD_DESC_',
  /** Sort on LotAccumulationPeriod ascending */
  LotaccumulationperiodAsc = '_LOTACCUMULATIONPERIOD_ASC_',
  /** sort on LotAccumulationPeriod descending */
  LotaccumulationperiodDesc = '_LOTACCUMULATIONPERIOD_DESC_',
  /** Sort on DampenerPeriod ascending */
  DampenerperiodAsc = '_DAMPENERPERIOD_ASC_',
  /** sort on DampenerPeriod descending */
  DampenerperiodDesc = '_DAMPENERPERIOD_DESC_',
  /** Sort on DampenerQuantity ascending */
  DampenerquantityAsc = '_DAMPENERQUANTITY_ASC_',
  /** sort on DampenerQuantity descending */
  DampenerquantityDesc = '_DAMPENERQUANTITY_DESC_',
  /** Sort on OverflowLevel ascending */
  OverflowlevelAsc = '_OVERFLOWLEVEL_ASC_',
  /** sort on OverflowLevel descending */
  OverflowlevelDesc = '_OVERFLOWLEVEL_DESC_',
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on CategoryCode ascending */
  CategorycodeAsc = '_CATEGORYCODE_ASC_',
  /** sort on CategoryCode descending */
  CategorycodeDesc = '_CATEGORYCODE_DESC_',
  /** Sort on CreatedFromNonstockItem ascending */
  CreatedfromnonstockitemAsc = '_CREATEDFROMNONSTOCKITEM_ASC_',
  /** sort on CreatedFromNonstockItem descending */
  CreatedfromnonstockitemDesc = '_CREATEDFROMNONSTOCKITEM_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ServiceItemGroup ascending */
  ServiceitemgroupAsc = '_SERVICEITEMGROUP_ASC_',
  /** sort on ServiceItemGroup descending */
  ServiceitemgroupDesc = '_SERVICEITEMGROUP_DESC_',
  /** Sort on ItemTrackingCode ascending */
  ItemtrackingcodeAsc = '_ITEMTRACKINGCODE_ASC_',
  /** sort on ItemTrackingCode descending */
  ItemtrackingcodeDesc = '_ITEMTRACKINGCODE_DESC_',
  /** Sort on LotNos ascending */
  LotnosAsc = '_LOTNOS_ASC_',
  /** sort on LotNos descending */
  LotnosDesc = '_LOTNOS_DESC_',
  /** Sort on ExpirationCalculation ascending */
  ExpirationcalculationAsc = '_EXPIRATIONCALCULATION_ASC_',
  /** sort on ExpirationCalculation descending */
  ExpirationcalculationDesc = '_EXPIRATIONCALCULATION_DESC_',
  /** Sort on WarehouseClassCode ascending */
  WarehouseclasscodeAsc = '_WAREHOUSECLASSCODE_ASC_',
  /** sort on WarehouseClassCode descending */
  WarehouseclasscodeDesc = '_WAREHOUSECLASSCODE_DESC_',
  /** Sort on SpecialEquipmentCode ascending */
  SpecialequipmentcodeAsc = '_SPECIALEQUIPMENTCODE_ASC_',
  /** sort on SpecialEquipmentCode descending */
  SpecialequipmentcodeDesc = '_SPECIALEQUIPMENTCODE_DESC_',
  /** Sort on PutawayTemplateCode ascending */
  PutawaytemplatecodeAsc = '_PUTAWAYTEMPLATECODE_ASC_',
  /** sort on PutawayTemplateCode descending */
  PutawaytemplatecodeDesc = '_PUTAWAYTEMPLATECODE_DESC_',
  /** Sort on PutawayUnitofMeasureCode ascending */
  PutawayunitofmeasurecodeAsc = '_PUTAWAYUNITOFMEASURECODE_ASC_',
  /** sort on PutawayUnitofMeasureCode descending */
  PutawayunitofmeasurecodeDesc = '_PUTAWAYUNITOFMEASURECODE_DESC_',
  /** Sort on PhysInvtCountingPeriodCode ascending */
  PhysinvtcountingperiodcodeAsc = '_PHYSINVTCOUNTINGPERIODCODE_ASC_',
  /** sort on PhysInvtCountingPeriodCode descending */
  PhysinvtcountingperiodcodeDesc = '_PHYSINVTCOUNTINGPERIODCODE_DESC_',
  /** Sort on LastCountingPeriodUpdate ascending */
  LastcountingperiodupdateAsc = '_LASTCOUNTINGPERIODUPDATE_ASC_',
  /** sort on LastCountingPeriodUpdate descending */
  LastcountingperiodupdateDesc = '_LASTCOUNTINGPERIODUPDATE_DESC_',
  /** Sort on UseCrossDocking ascending */
  UsecrossdockingAsc = '_USECROSSDOCKING_ASC_',
  /** sort on UseCrossDocking descending */
  UsecrossdockingDesc = '_USECROSSDOCKING_DESC_',
  /** Sort on NextCountingStartDate ascending */
  NextcountingstartdateAsc = '_NEXTCOUNTINGSTARTDATE_ASC_',
  /** sort on NextCountingStartDate descending */
  NextcountingstartdateDesc = '_NEXTCOUNTINGSTARTDATE_DESC_',
  /** Sort on NextCountingEndDate ascending */
  NextcountingenddateAsc = '_NEXTCOUNTINGENDDATE_ASC_',
  /** sort on NextCountingEndDate descending */
  NextcountingenddateDesc = '_NEXTCOUNTINGENDDATE_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on UnitofMeasureId ascending */
  UnitofmeasureidAsc = '_UNITOFMEASUREID_ASC_',
  /** sort on UnitofMeasureId descending */
  UnitofmeasureidDesc = '_UNITOFMEASUREID_DESC_',
  /** Sort on TaxGroupId ascending */
  TaxgroupidAsc = '_TAXGROUPID_ASC_',
  /** sort on TaxGroupId descending */
  TaxgroupidDesc = '_TAXGROUPID_DESC_',
  /** Sort on EQMItemNoConcern ascending */
  EqmitemnoconcernAsc = '_EQMITEMNOCONCERN_ASC_',
  /** sort on EQMItemNoConcern descending */
  EqmitemnoconcernDesc = '_EQMITEMNOCONCERN_DESC_',
  /** Sort on EQMDiamondBladeItem ascending */
  EqmdiamondbladeitemAsc = '_EQMDIAMONDBLADEITEM_ASC_',
  /** sort on EQMDiamondBladeItem descending */
  EqmdiamondbladeitemDesc = '_EQMDIAMONDBLADEITEM_DESC_',
  /** Sort on EQMDiamondPostingGroup ascending */
  EqmdiamondpostinggroupAsc = '_EQMDIAMONDPOSTINGGROUP_ASC_',
  /** sort on EQMDiamondPostingGroup descending */
  EqmdiamondpostinggroupDesc = '_EQMDIAMONDPOSTINGGROUP_DESC_',
  /** Sort on EQMDimensionRentalType ascending */
  EqmdimensionrentaltypeAsc = '_EQMDIMENSIONRENTALTYPE_ASC_',
  /** sort on EQMDimensionRentalType descending */
  EqmdimensionrentaltypeDesc = '_EQMDIMENSIONRENTALTYPE_DESC_',
  /** Sort on EQMMeasurement ascending */
  EqmmeasurementAsc = '_EQMMEASUREMENT_ASC_',
  /** sort on EQMMeasurement descending */
  EqmmeasurementDesc = '_EQMMEASUREMENT_DESC_',
  /** Sort on EQMReRentRProdPostGrp ascending */
  EqmrerentrprodpostgrpAsc = '_EQMRERENTRPRODPOSTGRP_ASC_',
  /** sort on EQMReRentRProdPostGrp descending */
  EqmrerentrprodpostgrpDesc = '_EQMRERENTRPRODPOSTGRP_DESC_',
  /** Sort on EQMReRentSProdPostGrp ascending */
  EqmrerentsprodpostgrpAsc = '_EQMRERENTSPRODPOSTGRP_ASC_',
  /** sort on EQMReRentSProdPostGrp descending */
  EqmrerentsprodpostgrpDesc = '_EQMRERENTSPRODPOSTGRP_DESC_',
  /** Sort on EQMObjectBOMPostingGroup ascending */
  EqmobjectbompostinggroupAsc = '_EQMOBJECTBOMPOSTINGGROUP_ASC_',
  /** sort on EQMObjectBOMPostingGroup descending */
  EqmobjectbompostinggroupDesc = '_EQMOBJECTBOMPOSTINGGROUP_DESC_',
  /** Sort on EQMVATRntlProdPostGrp ascending */
  EqmvatrntlprodpostgrpAsc = '_EQMVATRNTLPRODPOSTGRP_ASC_',
  /** sort on EQMVATRntlProdPostGrp descending */
  EqmvatrntlprodpostgrpDesc = '_EQMVATRNTLPRODPOSTGRP_DESC_',
  /** Sort on EQMDepositAmount ascending */
  EqmdepositamountAsc = '_EQMDEPOSITAMOUNT_ASC_',
  /** sort on EQMDepositAmount descending */
  EqmdepositamountDesc = '_EQMDEPOSITAMOUNT_DESC_',
  /** Sort on EQMReplacementPrice ascending */
  EqmreplacementpriceAsc = '_EQMREPLACEMENTPRICE_ASC_',
  /** sort on EQMReplacementPrice descending */
  EqmreplacementpriceDesc = '_EQMREPLACEMENTPRICE_DESC_',
  /** Sort on EQMFixedAssetSetupGroup ascending */
  EqmfixedassetsetupgroupAsc = '_EQMFIXEDASSETSETUPGROUP_ASC_',
  /** sort on EQMFixedAssetSetupGroup descending */
  EqmfixedassetsetupgroupDesc = '_EQMFIXEDASSETSETUPGROUP_DESC_',
  /** Sort on EQMDescription3 ascending */
  Eqmdescription3Asc = '_EQMDESCRIPTION3_ASC_',
  /** sort on EQMDescription3 descending */
  Eqmdescription3Desc = '_EQMDESCRIPTION3_DESC_',
  /** Sort on EQMDescription4 ascending */
  Eqmdescription4Asc = '_EQMDESCRIPTION4_ASC_',
  /** sort on EQMDescription4 descending */
  Eqmdescription4Desc = '_EQMDESCRIPTION4_DESC_',
  /** Sort on EQMRentalInsurance ascending */
  EqmrentalinsuranceAsc = '_EQMRENTALINSURANCE_ASC_',
  /** sort on EQMRentalInsurance descending */
  EqmrentalinsuranceDesc = '_EQMRENTALINSURANCE_DESC_',
  /** Sort on EQMBlockedRental ascending */
  EqmblockedrentalAsc = '_EQMBLOCKEDRENTAL_ASC_',
  /** sort on EQMBlockedRental descending */
  EqmblockedrentalDesc = '_EQMBLOCKEDRENTAL_DESC_',
  /** Sort on EQMExpAvailAfterEndDate ascending */
  EqmexpavailafterenddateAsc = '_EQMEXPAVAILAFTERENDDATE_ASC_',
  /** sort on EQMExpAvailAfterEndDate descending */
  EqmexpavailafterenddateDesc = '_EQMEXPAVAILAFTERENDDATE_DESC_',
  /** Sort on EQMCreditonEarlyReturn ascending */
  EqmcreditonearlyreturnAsc = '_EQMCREDITONEARLYRETURN_ASC_',
  /** sort on EQMCreditonEarlyReturn descending */
  EqmcreditonearlyreturnDesc = '_EQMCREDITONEARLYRETURN_DESC_',
  /** Sort on EQMStdRentalChargeDays ascending */
  EqmstdrentalchargedaysAsc = '_EQMSTDRENTALCHARGEDAYS_ASC_',
  /** sort on EQMStdRentalChargeDays descending */
  EqmstdrentalchargedaysDesc = '_EQMSTDRENTALCHARGEDAYS_DESC_',
  /** Sort on EQMDebitonReturnDate ascending */
  EqmdebitonreturndateAsc = '_EQMDEBITONRETURNDATE_ASC_',
  /** sort on EQMDebitonReturnDate descending */
  EqmdebitonreturndateDesc = '_EQMDEBITONRETURNDATE_DESC_',
  /** Sort on EQMBaseCalendarCode ascending */
  EqmbasecalendarcodeAsc = '_EQMBASECALENDARCODE_ASC_',
  /** sort on EQMBaseCalendarCode descending */
  EqmbasecalendarcodeDesc = '_EQMBASECALENDARCODE_DESC_',
  /** Sort on EQMGenRentalPostingGroup ascending */
  EqmgenrentalpostinggroupAsc = '_EQMGENRENTALPOSTINGGROUP_ASC_',
  /** sort on EQMGenRentalPostingGroup descending */
  EqmgenrentalpostinggroupDesc = '_EQMGENRENTALPOSTINGGROUP_DESC_',
  /** Sort on EQMRentalUnitPrice ascending */
  EqmrentalunitpriceAsc = '_EQMRENTALUNITPRICE_ASC_',
  /** sort on EQMRentalUnitPrice descending */
  EqmrentalunitpriceDesc = '_EQMRENTALUNITPRICE_DESC_',
  /** Sort on EQMDefaultRentalItem ascending */
  EqmdefaultrentalitemAsc = '_EQMDEFAULTRENTALITEM_ASC_',
  /** sort on EQMDefaultRentalItem descending */
  EqmdefaultrentalitemDesc = '_EQMDEFAULTRENTALITEM_DESC_',
  /** Sort on EQMPriceTermCode ascending */
  EqmpricetermcodeAsc = '_EQMPRICETERMCODE_ASC_',
  /** sort on EQMPriceTermCode descending */
  EqmpricetermcodeDesc = '_EQMPRICETERMCODE_DESC_',
  /** Sort on EQMRentalItemDiscGroup ascending */
  EqmrentalitemdiscgroupAsc = '_EQMRENTALITEMDISCGROUP_ASC_',
  /** sort on EQMRentalItemDiscGroup descending */
  EqmrentalitemdiscgroupDesc = '_EQMRENTALITEMDISCGROUP_DESC_',
  /** Sort on EQMDimensionRentalGroup ascending */
  EqmdimensionrentalgroupAsc = '_EQMDIMENSIONRENTALGROUP_ASC_',
  /** sort on EQMDimensionRentalGroup descending */
  EqmdimensionrentalgroupDesc = '_EQMDIMENSIONRENTALGROUP_DESC_',
  /** Sort on EQMGenRepurchasePostGrp ascending */
  EqmgenrepurchasepostgrpAsc = '_EQMGENREPURCHASEPOSTGRP_ASC_',
  /** sort on EQMGenRepurchasePostGrp descending */
  EqmgenrepurchasepostgrpDesc = '_EQMGENREPURCHASEPOSTGRP_DESC_',
  /** Sort on EQMSquareMeter ascending */
  EqmsquaremeterAsc = '_EQMSQUAREMETER_ASC_',
  /** sort on EQMSquareMeter descending */
  EqmsquaremeterDesc = '_EQMSQUAREMETER_DESC_',
  /** Sort on EQMFilterLocationCode ascending */
  EqmfilterlocationcodeAsc = '_EQMFILTERLOCATIONCODE_ASC_',
  /** sort on EQMFilterLocationCode descending */
  EqmfilterlocationcodeDesc = '_EQMFILTERLOCATIONCODE_DESC_',
  /** Sort on RoutingNo ascending */
  RoutingnoAsc = '_ROUTINGNO_ASC_',
  /** sort on RoutingNo descending */
  RoutingnoDesc = '_ROUTINGNO_DESC_',
  /** Sort on ProductionBOMNo ascending */
  ProductionbomnoAsc = '_PRODUCTIONBOMNO_ASC_',
  /** sort on ProductionBOMNo descending */
  ProductionbomnoDesc = '_PRODUCTIONBOMNO_DESC_',
  /** Sort on SingleLevelMaterialCost ascending */
  SinglelevelmaterialcostAsc = '_SINGLELEVELMATERIALCOST_ASC_',
  /** sort on SingleLevelMaterialCost descending */
  SinglelevelmaterialcostDesc = '_SINGLELEVELMATERIALCOST_DESC_',
  /** Sort on SingleLevelCapacityCost ascending */
  SinglelevelcapacitycostAsc = '_SINGLELEVELCAPACITYCOST_ASC_',
  /** sort on SingleLevelCapacityCost descending */
  SinglelevelcapacitycostDesc = '_SINGLELEVELCAPACITYCOST_DESC_',
  /** Sort on SingleLevelSubcontrdCost ascending */
  SinglelevelsubcontrdcostAsc = '_SINGLELEVELSUBCONTRDCOST_ASC_',
  /** sort on SingleLevelSubcontrdCost descending */
  SinglelevelsubcontrdcostDesc = '_SINGLELEVELSUBCONTRDCOST_DESC_',
  /** Sort on SingleLevelCapOvhdCost ascending */
  SinglelevelcapovhdcostAsc = '_SINGLELEVELCAPOVHDCOST_ASC_',
  /** sort on SingleLevelCapOvhdCost descending */
  SinglelevelcapovhdcostDesc = '_SINGLELEVELCAPOVHDCOST_DESC_',
  /** Sort on SingleLevelMfgOvhdCost ascending */
  SinglelevelmfgovhdcostAsc = '_SINGLELEVELMFGOVHDCOST_ASC_',
  /** sort on SingleLevelMfgOvhdCost descending */
  SinglelevelmfgovhdcostDesc = '_SINGLELEVELMFGOVHDCOST_DESC_',
  /** Sort on OverheadRate ascending */
  OverheadrateAsc = '_OVERHEADRATE_ASC_',
  /** sort on OverheadRate descending */
  OverheadrateDesc = '_OVERHEADRATE_DESC_',
  /** Sort on RolledupSubcontractedCost ascending */
  RolledupsubcontractedcostAsc = '_ROLLEDUPSUBCONTRACTEDCOST_ASC_',
  /** sort on RolledupSubcontractedCost descending */
  RolledupsubcontractedcostDesc = '_ROLLEDUPSUBCONTRACTEDCOST_DESC_',
  /** Sort on RolledupMfgOvhdCost ascending */
  RolledupmfgovhdcostAsc = '_ROLLEDUPMFGOVHDCOST_ASC_',
  /** sort on RolledupMfgOvhdCost descending */
  RolledupmfgovhdcostDesc = '_ROLLEDUPMFGOVHDCOST_DESC_',
  /** Sort on RolledupCapOverheadCost ascending */
  RolledupcapoverheadcostAsc = '_ROLLEDUPCAPOVERHEADCOST_ASC_',
  /** sort on RolledupCapOverheadCost descending */
  RolledupcapoverheadcostDesc = '_ROLLEDUPCAPOVERHEADCOST_DESC_',
  /** Sort on OrderTrackingPolicy ascending */
  OrdertrackingpolicyAsc = '_ORDERTRACKINGPOLICY_ASC_',
  /** sort on OrderTrackingPolicy descending */
  OrdertrackingpolicyDesc = '_ORDERTRACKINGPOLICY_DESC_',
  /** Sort on Critical ascending */
  CriticalAsc = '_CRITICAL_ASC_',
  /** sort on Critical descending */
  CriticalDesc = '_CRITICAL_DESC_',
  /** Sort on CommonItemNo ascending */
  CommonitemnoAsc = '_COMMONITEMNO_ASC_',
  /** sort on CommonItemNo descending */
  CommonitemnoDesc = '_COMMONITEMNO_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DropShipmentFilter ascending */
  DropshipmentfilterAsc = '_DROPSHIPMENTFILTER_ASC_',
  /** sort on DropShipmentFilter descending */
  DropshipmentfilterDesc = '_DROPSHIPMENTFILTER_DESC_',
  /** Sort on BinFilter ascending */
  BinfilterAsc = '_BINFILTER_ASC_',
  /** sort on BinFilter descending */
  BinfilterDesc = '_BINFILTER_DESC_',
  /** Sort on VariantFilter ascending */
  VariantfilterAsc = '_VARIANTFILTER_ASC_',
  /** sort on VariantFilter descending */
  VariantfilterDesc = '_VARIANTFILTER_DESC_',
  /** Sort on LotNoFilter ascending */
  LotnofilterAsc = '_LOTNOFILTER_ASC_',
  /** sort on LotNoFilter descending */
  LotnofilterDesc = '_LOTNOFILTER_DESC_',
  /** Sort on SerialNoFilter ascending */
  SerialnofilterAsc = '_SERIALNOFILTER_ASC_',
  /** sort on SerialNoFilter descending */
  SerialnofilterDesc = '_SERIALNOFILTER_DESC_',
  /** Sort on EQMRentalSaleFilter ascending */
  EqmrentalsalefilterAsc = '_EQMRENTALSALEFILTER_ASC_',
  /** sort on EQMRentalSaleFilter descending */
  EqmrentalsalefilterDesc = '_EQMRENTALSALEFILTER_DESC_',
  /** Sort on ProductionForecastName ascending */
  ProductionforecastnameAsc = '_PRODUCTIONFORECASTNAME_ASC_',
  /** sort on ProductionForecastName descending */
  ProductionforecastnameDesc = '_PRODUCTIONFORECASTNAME_DESC_',
  /** Sort on ComponentForecast ascending */
  ComponentforecastAsc = '_COMPONENTFORECAST_ASC_',
  /** sort on ComponentForecast descending */
  ComponentforecastDesc = '_COMPONENTFORECAST_DESC_',
  /** Sort on AssemblyBOM ascending */
  AssemblybomAsc = '_ASSEMBLYBOM_ASC_',
  /** sort on AssemblyBOM descending */
  AssemblybomDesc = '_ASSEMBLYBOM_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on CostisPostedtoGL ascending */
  CostispostedtoglAsc = '_COSTISPOSTEDTOGL_ASC_',
  /** sort on CostisPostedtoGL descending */
  CostispostedtoglDesc = '_COSTISPOSTEDTOGL_DESC_',
  /** Sort on Inventory ascending */
  InventoryAsc = '_INVENTORY_ASC_',
  /** sort on Inventory descending */
  InventoryDesc = '_INVENTORY_DESC_',
  /** Sort on NetInvoicedQty ascending */
  NetinvoicedqtyAsc = '_NETINVOICEDQTY_ASC_',
  /** sort on NetInvoicedQty descending */
  NetinvoicedqtyDesc = '_NETINVOICEDQTY_DESC_',
  /** Sort on NetChange ascending */
  NetchangeAsc = '_NETCHANGE_ASC_',
  /** sort on NetChange descending */
  NetchangeDesc = '_NETCHANGE_DESC_',
  /** Sort on PurchasesQty ascending */
  PurchasesqtyAsc = '_PURCHASESQTY_ASC_',
  /** sort on PurchasesQty descending */
  PurchasesqtyDesc = '_PURCHASESQTY_DESC_',
  /** Sort on SalesQty ascending */
  SalesqtyAsc = '_SALESQTY_ASC_',
  /** sort on SalesQty descending */
  SalesqtyDesc = '_SALESQTY_DESC_',
  /** Sort on PositiveAdjmtQty ascending */
  PositiveadjmtqtyAsc = '_POSITIVEADJMTQTY_ASC_',
  /** sort on PositiveAdjmtQty descending */
  PositiveadjmtqtyDesc = '_POSITIVEADJMTQTY_DESC_',
  /** Sort on NegativeAdjmtQty ascending */
  NegativeadjmtqtyAsc = '_NEGATIVEADJMTQTY_ASC_',
  /** sort on NegativeAdjmtQty descending */
  NegativeadjmtqtyDesc = '_NEGATIVEADJMTQTY_DESC_',
  /** Sort on PurchasesLCY ascending */
  PurchaseslcyAsc = '_PURCHASESLCY_ASC_',
  /** sort on PurchasesLCY descending */
  PurchaseslcyDesc = '_PURCHASESLCY_DESC_',
  /** Sort on SalesLCY ascending */
  SaleslcyAsc = '_SALESLCY_ASC_',
  /** sort on SalesLCY descending */
  SaleslcyDesc = '_SALESLCY_DESC_',
  /** Sort on PositiveAdjmtLCY ascending */
  PositiveadjmtlcyAsc = '_POSITIVEADJMTLCY_ASC_',
  /** sort on PositiveAdjmtLCY descending */
  PositiveadjmtlcyDesc = '_POSITIVEADJMTLCY_DESC_',
  /** Sort on NegativeAdjmtLCY ascending */
  NegativeadjmtlcyAsc = '_NEGATIVEADJMTLCY_ASC_',
  /** sort on NegativeAdjmtLCY descending */
  NegativeadjmtlcyDesc = '_NEGATIVEADJMTLCY_DESC_',
  /** Sort on COGSLCY ascending */
  CogslcyAsc = '_COGSLCY_ASC_',
  /** sort on COGSLCY descending */
  CogslcyDesc = '_COGSLCY_DESC_',
  /** Sort on QtyonPurchOrder ascending */
  QtyonpurchorderAsc = '_QTYONPURCHORDER_ASC_',
  /** sort on QtyonPurchOrder descending */
  QtyonpurchorderDesc = '_QTYONPURCHORDER_DESC_',
  /** Sort on QtyonSalesOrder ascending */
  QtyonsalesorderAsc = '_QTYONSALESORDER_ASC_',
  /** sort on QtyonSalesOrder descending */
  QtyonsalesorderDesc = '_QTYONSALESORDER_DESC_',
  /** Sort on TransferredQty ascending */
  TransferredqtyAsc = '_TRANSFERREDQTY_ASC_',
  /** sort on TransferredQty descending */
  TransferredqtyDesc = '_TRANSFERREDQTY_DESC_',
  /** Sort on TransferredLCY ascending */
  TransferredlcyAsc = '_TRANSFERREDLCY_ASC_',
  /** sort on TransferredLCY descending */
  TransferredlcyDesc = '_TRANSFERREDLCY_DESC_',
  /** Sort on ReservedQtyonInventory ascending */
  ReservedqtyoninventoryAsc = '_RESERVEDQTYONINVENTORY_ASC_',
  /** sort on ReservedQtyonInventory descending */
  ReservedqtyoninventoryDesc = '_RESERVEDQTYONINVENTORY_DESC_',
  /** Sort on ReservedQtyonPurchOrders ascending */
  ReservedqtyonpurchordersAsc = '_RESERVEDQTYONPURCHORDERS_ASC_',
  /** sort on ReservedQtyonPurchOrders descending */
  ReservedqtyonpurchordersDesc = '_RESERVEDQTYONPURCHORDERS_DESC_',
  /** Sort on ReservedQtyonSalesOrders ascending */
  ReservedqtyonsalesordersAsc = '_RESERVEDQTYONSALESORDERS_ASC_',
  /** sort on ReservedQtyonSalesOrders descending */
  ReservedqtyonsalesordersDesc = '_RESERVEDQTYONSALESORDERS_DESC_',
  /** Sort on ResQtyonOutboundTransfer ascending */
  ResqtyonoutboundtransferAsc = '_RESQTYONOUTBOUNDTRANSFER_ASC_',
  /** sort on ResQtyonOutboundTransfer descending */
  ResqtyonoutboundtransferDesc = '_RESQTYONOUTBOUNDTRANSFER_DESC_',
  /** Sort on ResQtyonInboundTransfer ascending */
  ResqtyoninboundtransferAsc = '_RESQTYONINBOUNDTRANSFER_ASC_',
  /** sort on ResQtyonInboundTransfer descending */
  ResqtyoninboundtransferDesc = '_RESQTYONINBOUNDTRANSFER_DESC_',
  /** Sort on ResQtyonSalesReturns ascending */
  ResqtyonsalesreturnsAsc = '_RESQTYONSALESRETURNS_ASC_',
  /** sort on ResQtyonSalesReturns descending */
  ResqtyonsalesreturnsDesc = '_RESQTYONSALESRETURNS_DESC_',
  /** Sort on ResQtyonPurchReturns ascending */
  ResqtyonpurchreturnsAsc = '_RESQTYONPURCHRETURNS_ASC_',
  /** sort on ResQtyonPurchReturns descending */
  ResqtyonpurchreturnsDesc = '_RESQTYONPURCHRETURNS_DESC_',
  /** Sort on CostofOpenProductionOrders ascending */
  CostofopenproductionordersAsc = '_COSTOFOPENPRODUCTIONORDERS_ASC_',
  /** sort on CostofOpenProductionOrders descending */
  CostofopenproductionordersDesc = '_COSTOFOPENPRODUCTIONORDERS_DESC_',
  /** Sort on ResQtyonAssemblyOrder ascending */
  ResqtyonassemblyorderAsc = '_RESQTYONASSEMBLYORDER_ASC_',
  /** sort on ResQtyonAssemblyOrder descending */
  ResqtyonassemblyorderDesc = '_RESQTYONASSEMBLYORDER_DESC_',
  /** Sort on ResQtyonAsmComp ascending */
  ResqtyonasmcompAsc = '_RESQTYONASMCOMP_ASC_',
  /** sort on ResQtyonAsmComp descending */
  ResqtyonasmcompDesc = '_RESQTYONASMCOMP_DESC_',
  /** Sort on QtyonAssemblyOrder ascending */
  QtyonassemblyorderAsc = '_QTYONASSEMBLYORDER_ASC_',
  /** sort on QtyonAssemblyOrder descending */
  QtyonassemblyorderDesc = '_QTYONASSEMBLYORDER_DESC_',
  /** Sort on QtyonAsmComponent ascending */
  QtyonasmcomponentAsc = '_QTYONASMCOMPONENT_ASC_',
  /** sort on QtyonAsmComponent descending */
  QtyonasmcomponentDesc = '_QTYONASMCOMPONENT_DESC_',
  /** Sort on QtyonJobOrder ascending */
  QtyonjoborderAsc = '_QTYONJOBORDER_ASC_',
  /** sort on QtyonJobOrder descending */
  QtyonjoborderDesc = '_QTYONJOBORDER_DESC_',
  /** Sort on ResQtyonJobOrder ascending */
  ResqtyonjoborderAsc = '_RESQTYONJOBORDER_ASC_',
  /** sort on ResQtyonJobOrder descending */
  ResqtyonjoborderDesc = '_RESQTYONJOBORDER_DESC_',
  /** Sort on ScheduledReceiptQty ascending */
  ScheduledreceiptqtyAsc = '_SCHEDULEDRECEIPTQTY_ASC_',
  /** sort on ScheduledReceiptQty descending */
  ScheduledreceiptqtyDesc = '_SCHEDULEDRECEIPTQTY_DESC_',
  /** Sort on ScheduledNeedQty ascending */
  ScheduledneedqtyAsc = '_SCHEDULEDNEEDQTY_ASC_',
  /** sort on ScheduledNeedQty descending */
  ScheduledneedqtyDesc = '_SCHEDULEDNEEDQTY_DESC_',
  /** Sort on ReservedQtyonProdOrder ascending */
  ReservedqtyonprodorderAsc = '_RESERVEDQTYONPRODORDER_ASC_',
  /** sort on ReservedQtyonProdOrder descending */
  ReservedqtyonprodorderDesc = '_RESERVEDQTYONPRODORDER_DESC_',
  /** Sort on ResQtyonProdOrderComp ascending */
  ResqtyonprodordercompAsc = '_RESQTYONPRODORDERCOMP_ASC_',
  /** sort on ResQtyonProdOrderComp descending */
  ResqtyonprodordercompDesc = '_RESQTYONPRODORDERCOMP_DESC_',
  /** Sort on ResQtyonReqLine ascending */
  ResqtyonreqlineAsc = '_RESQTYONREQLINE_ASC_',
  /** sort on ResQtyonReqLine descending */
  ResqtyonreqlineDesc = '_RESQTYONREQLINE_DESC_',
  /** Sort on PlanningTransferShipQty ascending */
  PlanningtransfershipqtyAsc = '_PLANNINGTRANSFERSHIPQTY_ASC_',
  /** sort on PlanningTransferShipQty descending */
  PlanningtransfershipqtyDesc = '_PLANNINGTRANSFERSHIPQTY_DESC_',
  /** Sort on PlanningWorksheetQty ascending */
  PlanningworksheetqtyAsc = '_PLANNINGWORKSHEETQTY_ASC_',
  /** sort on PlanningWorksheetQty descending */
  PlanningworksheetqtyDesc = '_PLANNINGWORKSHEETQTY_DESC_',
  /** Sort on StockkeepingUnitExists ascending */
  StockkeepingunitexistsAsc = '_STOCKKEEPINGUNITEXISTS_ASC_',
  /** sort on StockkeepingUnitExists descending */
  StockkeepingunitexistsDesc = '_STOCKKEEPINGUNITEXISTS_DESC_',
  /** Sort on SubstitutesExist ascending */
  SubstitutesexistAsc = '_SUBSTITUTESEXIST_ASC_',
  /** sort on SubstitutesExist descending */
  SubstitutesexistDesc = '_SUBSTITUTESEXIST_DESC_',
  /** Sort on QtyinTransit ascending */
  QtyintransitAsc = '_QTYINTRANSIT_ASC_',
  /** sort on QtyinTransit descending */
  QtyintransitDesc = '_QTYINTRANSIT_DESC_',
  /** Sort on TransOrdReceiptQty ascending */
  TransordreceiptqtyAsc = '_TRANSORDRECEIPTQTY_ASC_',
  /** sort on TransOrdReceiptQty descending */
  TransordreceiptqtyDesc = '_TRANSORDRECEIPTQTY_DESC_',
  /** Sort on TransOrdShipmentQty ascending */
  TransordshipmentqtyAsc = '_TRANSORDSHIPMENTQTY_ASC_',
  /** sort on TransOrdShipmentQty descending */
  TransordshipmentqtyDesc = '_TRANSORDSHIPMENTQTY_DESC_',
  /** Sort on QtyAssignedtoship ascending */
  QtyassignedtoshipAsc = '_QTYASSIGNEDTOSHIP_ASC_',
  /** sort on QtyAssignedtoship descending */
  QtyassignedtoshipDesc = '_QTYASSIGNEDTOSHIP_DESC_',
  /** Sort on QtyPicked ascending */
  QtypickedAsc = '_QTYPICKED_ASC_',
  /** sort on QtyPicked descending */
  QtypickedDesc = '_QTYPICKED_DESC_',
  /** Sort on QtyonServiceOrder ascending */
  QtyonserviceorderAsc = '_QTYONSERVICEORDER_ASC_',
  /** sort on QtyonServiceOrder descending */
  QtyonserviceorderDesc = '_QTYONSERVICEORDER_DESC_',
  /** Sort on ResQtyonServiceOrders ascending */
  ResqtyonserviceordersAsc = '_RESQTYONSERVICEORDERS_ASC_',
  /** sort on ResQtyonServiceOrders descending */
  ResqtyonserviceordersDesc = '_RESQTYONSERVICEORDERS_DESC_',
  /** Sort on QtyonPurchReturn ascending */
  QtyonpurchreturnAsc = '_QTYONPURCHRETURN_ASC_',
  /** sort on QtyonPurchReturn descending */
  QtyonpurchreturnDesc = '_QTYONPURCHRETURN_DESC_',
  /** Sort on QtyonSalesReturn ascending */
  QtyonsalesreturnAsc = '_QTYONSALESRETURN_ASC_',
  /** sort on QtyonSalesReturn descending */
  QtyonsalesreturnDesc = '_QTYONSALESRETURN_DESC_',
  /** Sort on NoofSubstitutes ascending */
  NoofsubstitutesAsc = '_NOOFSUBSTITUTES_ASC_',
  /** sort on NoofSubstitutes descending */
  NoofsubstitutesDesc = '_NOOFSUBSTITUTES_DESC_',
  /** Sort on LastPhysInvtDate ascending */
  LastphysinvtdateAsc = '_LASTPHYSINVTDATE_ASC_',
  /** sort on LastPhysInvtDate descending */
  LastphysinvtdateDesc = '_LASTPHYSINVTDATE_DESC_',
  /** Sort on IdentifierCode ascending */
  IdentifiercodeAsc = '_IDENTIFIERCODE_ASC_',
  /** sort on IdentifierCode descending */
  IdentifiercodeDesc = '_IDENTIFIERCODE_DESC_',
  /** Sort on EQMAvailabletoTake ascending */
  EqmavailabletotakeAsc = '_EQMAVAILABLETOTAKE_ASC_',
  /** sort on EQMAvailabletoTake descending */
  EqmavailabletotakeDesc = '_EQMAVAILABLETOTAKE_DESC_',
  /** Sort on EQMUseAdvancedMgtCalendar ascending */
  EqmuseadvancedmgtcalendarAsc = '_EQMUSEADVANCEDMGTCALENDAR_ASC_',
  /** sort on EQMUseAdvancedMgtCalendar descending */
  EqmuseadvancedmgtcalendarDesc = '_EQMUSEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on EQMRentalReservationsQty ascending */
  EqmrentalreservationsqtyAsc = '_EQMRENTALRESERVATIONSQTY_ASC_',
  /** sort on EQMRentalReservationsQty descending */
  EqmrentalreservationsqtyDesc = '_EQMRENTALRESERVATIONSQTY_DESC_',
  /** Sort on EQMRentalDeliveriesQty ascending */
  EqmrentaldeliveriesqtyAsc = '_EQMRENTALDELIVERIESQTY_ASC_',
  /** sort on EQMRentalDeliveriesQty descending */
  EqmrentaldeliveriesqtyDesc = '_EQMRENTALDELIVERIESQTY_DESC_',
  /** Sort on EQMExpRentalReturnsQty ascending */
  EqmexprentalreturnsqtyAsc = '_EQMEXPRENTALRETURNSQTY_ASC_',
  /** sort on EQMExpRentalReturnsQty descending */
  EqmexprentalreturnsqtyDesc = '_EQMEXPRENTALRETURNSQTY_DESC_',
  /** Sort on EQMRentalReturnsQty ascending */
  EqmrentalreturnsqtyAsc = '_EQMRENTALRETURNSQTY_ASC_',
  /** sort on EQMRentalReturnsQty descending */
  EqmrentalreturnsqtyDesc = '_EQMRENTALRETURNSQTY_DESC_',
  /** Sort on EQMQtyonRentalReRent ascending */
  EqmqtyonrentalrerentAsc = '_EQMQTYONRENTALRERENT_ASC_',
  /** sort on EQMQtyonRentalReRent descending */
  EqmqtyonrentalrerentDesc = '_EQMQTYONRENTALRERENT_DESC_',
  /** Sort on EQMQtyonRent ascending */
  EqmqtyonrentAsc = '_EQMQTYONRENT_ASC_',
  /** sort on EQMQtyonRent descending */
  EqmqtyonrentDesc = '_EQMQTYONRENT_DESC_',
  /** Sort on PlanningIssuesQty ascending */
  PlanningissuesqtyAsc = '_PLANNINGISSUESQTY_ASC_',
  /** sort on PlanningIssuesQty descending */
  PlanningissuesqtyDesc = '_PLANNINGISSUESQTY_DESC_',
  /** Sort on PlanningReceiptQty ascending */
  PlanningreceiptqtyAsc = '_PLANNINGRECEIPTQTY_ASC_',
  /** sort on PlanningReceiptQty descending */
  PlanningreceiptqtyDesc = '_PLANNINGRECEIPTQTY_DESC_',
  /** Sort on PlannedOrderReceiptQty ascending */
  PlannedorderreceiptqtyAsc = '_PLANNEDORDERRECEIPTQTY_ASC_',
  /** sort on PlannedOrderReceiptQty descending */
  PlannedorderreceiptqtyDesc = '_PLANNEDORDERRECEIPTQTY_DESC_',
  /** Sort on FPOrderReceiptQty ascending */
  FporderreceiptqtyAsc = '_FPORDERRECEIPTQTY_ASC_',
  /** sort on FPOrderReceiptQty descending */
  FporderreceiptqtyDesc = '_FPORDERRECEIPTQTY_DESC_',
  /** Sort on RelOrderReceiptQty ascending */
  RelorderreceiptqtyAsc = '_RELORDERRECEIPTQTY_ASC_',
  /** sort on RelOrderReceiptQty descending */
  RelorderreceiptqtyDesc = '_RELORDERRECEIPTQTY_DESC_',
  /** Sort on PlanningReleaseQty ascending */
  PlanningreleaseqtyAsc = '_PLANNINGRELEASEQTY_ASC_',
  /** sort on PlanningReleaseQty descending */
  PlanningreleaseqtyDesc = '_PLANNINGRELEASEQTY_DESC_',
  /** Sort on PlannedOrderReleaseQty ascending */
  PlannedorderreleaseqtyAsc = '_PLANNEDORDERRELEASEQTY_ASC_',
  /** sort on PlannedOrderReleaseQty descending */
  PlannedorderreleaseqtyDesc = '_PLANNEDORDERRELEASEQTY_DESC_',
  /** Sort on PurchReqReceiptQty ascending */
  PurchreqreceiptqtyAsc = '_PURCHREQRECEIPTQTY_ASC_',
  /** sort on PurchReqReceiptQty descending */
  PurchreqreceiptqtyDesc = '_PURCHREQRECEIPTQTY_DESC_',
  /** Sort on PurchReqReleaseQty ascending */
  PurchreqreleaseqtyAsc = '_PURCHREQRELEASEQTY_ASC_',
  /** sort on PurchReqReleaseQty descending */
  PurchreqreleaseqtyDesc = '_PURCHREQRELEASEQTY_DESC_',
  /** Sort on ProdForecastQuantityBase ascending */
  ProdforecastquantitybaseAsc = '_PRODFORECASTQUANTITYBASE_ASC_',
  /** sort on ProdForecastQuantityBase descending */
  ProdforecastquantitybaseDesc = '_PRODFORECASTQUANTITYBASE_DESC_',
  /** Sort on QtyonProdOrder ascending */
  QtyonprodorderAsc = '_QTYONPRODORDER_ASC_',
  /** sort on QtyonProdOrder descending */
  QtyonprodorderDesc = '_QTYONPRODORDER_DESC_',
  /** Sort on QtyonComponentLines ascending */
  QtyoncomponentlinesAsc = '_QTYONCOMPONENTLINES_ASC_',
  /** sort on QtyonComponentLines descending */
  QtyoncomponentlinesDesc = '_QTYONCOMPONENTLINES_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on SynkedToMagento ascending */
  SynkedtomagentoAsc = '_SYNKEDTOMAGENTO_ASC_',
  /** sort on SynkedToMagento descending */
  SynkedtomagentoDesc = '_SYNKEDTOMAGENTO_DESC_',
  /** Sort on MagentoSyncProblems ascending */
  MagentosyncproblemsAsc = '_MAGENTOSYNCPROBLEMS_ASC_',
  /** sort on MagentoSyncProblems descending */
  MagentosyncproblemsDesc = '_MAGENTOSYNCPROBLEMS_DESC_',
  /** Sort on AttributeSetId ascending */
  AttributesetidAsc = '_ATTRIBUTESETID_ASC_',
  /** sort on AttributeSetId descending */
  AttributesetidDesc = '_ATTRIBUTESETID_DESC_',
  /** Sort on Visibility ascending */
  VisibilityAsc = '_VISIBILITY_ASC_',
  /** sort on Visibility descending */
  VisibilityDesc = '_VISIBILITY_DESC_',
  /** Sort on TypeId ascending */
  TypeidAsc = '_TYPEID_ASC_',
  /** sort on TypeId descending */
  TypeidDesc = '_TYPEID_DESC_',
  /** Sort on Website ascending */
  WebsiteAsc = '_WEBSITE_ASC_',
  /** sort on Website descending */
  WebsiteDesc = '_WEBSITE_DESC_',
  /** Sort on CalculatedInventory ascending */
  CalculatedinventoryAsc = '_CALCULATEDINVENTORY_ASC_',
  /** sort on CalculatedInventory descending */
  CalculatedinventoryDesc = '_CALCULATEDINVENTORY_DESC_',
  /** Sort on CalculatedInventoryUOM ascending */
  CalculatedinventoryuomAsc = '_CALCULATEDINVENTORYUOM_ASC_',
  /** sort on CalculatedInventoryUOM descending */
  CalculatedinventoryuomDesc = '_CALCULATEDINVENTORYUOM_DESC_',
  /** Sort on CalculatedInventoryUpdatedAd ascending */
  CalculatedinventoryupdatedadAsc = '_CALCULATEDINVENTORYUPDATEDAD_ASC_',
  /** sort on CalculatedInventoryUpdatedAd descending */
  CalculatedinventoryupdatedadDesc = '_CALCULATEDINVENTORYUPDATEDAD_DESC_',
  /** Sort on ActiveWeb ascending */
  ActivewebAsc = '_ACTIVEWEB_ASC_',
  /** sort on ActiveWeb descending */
  ActivewebDesc = '_ACTIVEWEB_DESC_',
  /** Sort on TempOutOfStock ascending */
  TempoutofstockAsc = '_TEMPOUTOFSTOCK_ASC_',
  /** sort on TempOutOfStock descending */
  TempoutofstockDesc = '_TEMPOUTOFSTOCK_DESC_',
  /** Sort on OutletSE ascending */
  OutletseAsc = '_OUTLETSE_ASC_',
  /** sort on OutletSE descending */
  OutletseDesc = '_OUTLETSE_DESC_',
  /** Sort on OutletDateFromSE ascending */
  OutletdatefromseAsc = '_OUTLETDATEFROMSE_ASC_',
  /** sort on OutletDateFromSE descending */
  OutletdatefromseDesc = '_OUTLETDATEFROMSE_DESC_',
  /** Sort on OutletDateToSE ascending */
  OutletdatetoseAsc = '_OUTLETDATETOSE_ASC_',
  /** sort on OutletDateToSE descending */
  OutletdatetoseDesc = '_OUTLETDATETOSE_DESC_',
  /** Sort on CampaignSE ascending */
  CampaignseAsc = '_CAMPAIGNSE_ASC_',
  /** sort on CampaignSE descending */
  CampaignseDesc = '_CAMPAIGNSE_DESC_',
  /** Sort on CampaignDateFromSE ascending */
  CampaigndatefromseAsc = '_CAMPAIGNDATEFROMSE_ASC_',
  /** sort on CampaignDateFromSE descending */
  CampaigndatefromseDesc = '_CAMPAIGNDATEFROMSE_DESC_',
  /** Sort on CampaignDateToSE ascending */
  CampaigndatetoseAsc = '_CAMPAIGNDATETOSE_ASC_',
  /** sort on CampaignDateToSE descending */
  CampaigndatetoseDesc = '_CAMPAIGNDATETOSE_DESC_',
  /** Sort on DeliveryInformation ascending */
  DeliveryinformationAsc = '_DELIVERYINFORMATION_ASC_',
  /** sort on DeliveryInformation descending */
  DeliveryinformationDesc = '_DELIVERYINFORMATION_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on IsBulky ascending */
  IsbulkyAsc = '_ISBULKY_ASC_',
  /** sort on IsBulky descending */
  IsbulkyDesc = '_ISBULKY_DESC_',
  /** Sort on OrderItem ascending */
  OrderitemAsc = '_ORDERITEM_ASC_',
  /** sort on OrderItem descending */
  OrderitemDesc = '_ORDERITEM_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on PackageHeight ascending */
  PackageheightAsc = '_PACKAGEHEIGHT_ASC_',
  /** sort on PackageHeight descending */
  PackageheightDesc = '_PACKAGEHEIGHT_DESC_',
  /** Sort on PackageWidth ascending */
  PackagewidthAsc = '_PACKAGEWIDTH_ASC_',
  /** sort on PackageWidth descending */
  PackagewidthDesc = '_PACKAGEWIDTH_DESC_',
  /** Sort on PackageLength ascending */
  PackagelengthAsc = '_PACKAGELENGTH_ASC_',
  /** sort on PackageLength descending */
  PackagelengthDesc = '_PACKAGELENGTH_DESC_',
  /** Sort on VendorName ascending */
  VendornameAsc = '_VENDORNAME_ASC_',
  /** sort on VendorName descending */
  VendornameDesc = '_VENDORNAME_DESC_',
  /** Sort on DescriptionNo ascending */
  DescriptionnoAsc = '_DESCRIPTIONNO_ASC_',
  /** sort on DescriptionNo descending */
  DescriptionnoDesc = '_DESCRIPTIONNO_DESC_',
  /** Sort on BrandDescription ascending */
  BranddescriptionAsc = '_BRANDDESCRIPTION_ASC_',
  /** sort on BrandDescription descending */
  BranddescriptionDesc = '_BRANDDESCRIPTION_DESC_',
  /** Sort on DeliveryTime ascending */
  DeliverytimeAsc = '_DELIVERYTIME_ASC_',
  /** sort on DeliveryTime descending */
  DeliverytimeDesc = '_DELIVERYTIME_DESC_',
  /** Sort on HomeDelivery ascending */
  HomedeliveryAsc = '_HOMEDELIVERY_ASC_',
  /** sort on HomeDelivery descending */
  HomedeliveryDesc = '_HOMEDELIVERY_DESC_',
  /** Sort on ServicePointDelivery ascending */
  ServicepointdeliveryAsc = '_SERVICEPOINTDELIVERY_ASC_',
  /** sort on ServicePointDelivery descending */
  ServicepointdeliveryDesc = '_SERVICEPOINTDELIVERY_DESC_',
  /** Sort on BlockedNO ascending */
  BlockednoAsc = '_BLOCKEDNO_ASC_',
  /** sort on BlockedNO descending */
  BlockednoDesc = '_BLOCKEDNO_DESC_',
  /** Sort on ExpectedInStockDate ascending */
  ExpectedinstockdateAsc = '_EXPECTEDINSTOCKDATE_ASC_',
  /** sort on ExpectedInStockDate descending */
  ExpectedinstockdateDesc = '_EXPECTEDINSTOCKDATE_DESC_',
  /** Sort on BulkGroup ascending */
  BulkgroupAsc = '_BULKGROUP_ASC_',
  /** sort on BulkGroup descending */
  BulkgroupDesc = '_BULKGROUP_DESC_',
  /** Sort on IsOutlet ascending */
  IsoutletAsc = '_ISOUTLET_ASC_',
  /** sort on IsOutlet descending */
  IsoutletDesc = '_ISOUTLET_DESC_',
  /** Sort on IsCampaign ascending */
  IscampaignAsc = '_ISCAMPAIGN_ASC_',
  /** sort on IsCampaign descending */
  IscampaignDesc = '_ISCAMPAIGN_DESC_',
  /** Sort on GiftcardType ascending */
  GiftcardtypeAsc = '_GIFTCARDTYPE_ASC_',
  /** sort on GiftcardType descending */
  GiftcardtypeDesc = '_GIFTCARDTYPE_DESC_',
  /** Sort on TaxClassId ascending */
  TaxclassidAsc = '_TAXCLASSID_ASC_',
  /** sort on TaxClassId descending */
  TaxclassidDesc = '_TAXCLASSID_DESC_',
  /** Sort on UrlKey ascending */
  UrlkeyAsc = '_URLKEY_ASC_',
  /** sort on UrlKey descending */
  UrlkeyDesc = '_URLKEY_DESC_',
  /** Sort on ShowInFieldOrder ascending */
  ShowinfieldorderAsc = '_SHOWINFIELDORDER_ASC_',
  /** sort on ShowInFieldOrder descending */
  ShowinfieldorderDesc = '_SHOWINFIELDORDER_DESC_',
  /** Sort on ItemType ascending */
  ItemtypeAsc = '_ITEMTYPE_ASC_',
  /** sort on ItemType descending */
  ItemtypeDesc = '_ITEMTYPE_DESC_',
  /** Sort on ItemTypeOPTION ascending */
  ItemtypeoptionAsc = '_ITEMTYPEOPTION_ASC_',
  /** sort on ItemTypeOPTION descending */
  ItemtypeoptionDesc = '_ITEMTYPEOPTION_DESC_',
  /** Sort on SalesOrderMultiple ascending */
  SalesordermultipleAsc = '_SALESORDERMULTIPLE_ASC_',
  /** sort on SalesOrderMultiple descending */
  SalesordermultipleDesc = '_SALESORDERMULTIPLE_DESC_',
  /** Sort on EcoLabel ascending */
  EcolabelAsc = '_ECOLABEL_ASC_',
  /** sort on EcoLabel descending */
  EcolabelDesc = '_ECOLABEL_DESC_',
  /** Sort on MainGroupCode ascending */
  MaingroupcodeAsc = '_MAINGROUPCODE_ASC_',
  /** sort on MainGroupCode descending */
  MaingroupcodeDesc = '_MAINGROUPCODE_DESC_',
  /** Sort on ItemGroupCode ascending */
  ItemgroupcodeAsc = '_ITEMGROUPCODE_ASC_',
  /** sort on ItemGroupCode descending */
  ItemgroupcodeDesc = '_ITEMGROUPCODE_DESC_',
  /** Sort on OldItemNo ascending */
  OlditemnoAsc = '_OLDITEMNO_ASC_',
  /** sort on OldItemNo descending */
  OlditemnoDesc = '_OLDITEMNO_DESC_',
  /** Sort on OldItemNo2 ascending */
  Olditemno2Asc = '_OLDITEMNO2_ASC_',
  /** sort on OldItemNo2 descending */
  Olditemno2Desc = '_OLDITEMNO2_DESC_',
  /** Sort on RKVItem ascending */
  RkvitemAsc = '_RKVITEM_ASC_',
  /** sort on RKVItem descending */
  RkvitemDesc = '_RKVITEM_DESC_',
  /** Sort on ToExpire ascending */
  ToexpireAsc = '_TOEXPIRE_ASC_',
  /** sort on ToExpire descending */
  ToexpireDesc = '_TOEXPIRE_DESC_',
  /** Sort on AcquisitionItem ascending */
  AcquisitionitemAsc = '_ACQUISITIONITEM_ASC_',
  /** sort on AcquisitionItem descending */
  AcquisitionitemDesc = '_ACQUISITIONITEM_DESC_',
  /** Sort on MagentoItem ascending */
  MagentoitemAsc = '_MAGENTOITEM_ASC_',
  /** sort on MagentoItem descending */
  MagentoitemDesc = '_MAGENTOITEM_DESC_',
  /** Sort on AlwaysAvailableOnWeb ascending */
  AlwaysavailableonwebAsc = '_ALWAYSAVAILABLEONWEB_ASC_',
  /** sort on AlwaysAvailableOnWeb descending */
  AlwaysavailableonwebDesc = '_ALWAYSAVAILABLEONWEB_DESC_',
  /** Sort on QBSId ascending */
  QbsidAsc = '_QBSID_ASC_',
  /** sort on QBSId descending */
  QbsidDesc = '_QBSID_DESC_',
  /** Sort on ObjectGroupFamily ascending */
  ObjectgroupfamilyAsc = '_OBJECTGROUPFAMILY_ASC_',
  /** sort on ObjectGroupFamily descending */
  ObjectgroupfamilyDesc = '_OBJECTGROUPFAMILY_DESC_',
  /** Sort on EdgeSmallImageUrl ascending */
  EdgesmallimageurlAsc = '_EDGESMALLIMAGEURL_ASC_',
  /** sort on EdgeSmallImageUrl descending */
  EdgesmallimageurlDesc = '_EDGESMALLIMAGEURL_DESC_',
  /** Sort on EdgeLargelImageUrl ascending */
  EdgelargelimageurlAsc = '_EDGELARGELIMAGEURL_ASC_',
  /** sort on EdgeLargelImageUrl descending */
  EdgelargelimageurlDesc = '_EDGELARGELIMAGEURL_DESC_',
  /** Sort on CalculationPrice ascending */
  CalculationpriceAsc = '_CALCULATIONPRICE_ASC_',
  /** sort on CalculationPrice descending */
  CalculationpriceDesc = '_CALCULATIONPRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemReferenceInput {
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on ReferenceType ascending */
  ReferencetypeAsc = '_REFERENCETYPE_ASC_',
  /** sort on ReferenceType descending */
  ReferencetypeDesc = '_REFERENCETYPE_DESC_',
  /** Sort on ReferenceTypeOPTION ascending */
  ReferencetypeoptionAsc = '_REFERENCETYPEOPTION_ASC_',
  /** sort on ReferenceTypeOPTION descending */
  ReferencetypeoptionDesc = '_REFERENCETYPEOPTION_DESC_',
  /** Sort on ReferenceTypeNo ascending */
  ReferencetypenoAsc = '_REFERENCETYPENO_ASC_',
  /** sort on ReferenceTypeNo descending */
  ReferencetypenoDesc = '_REFERENCETYPENO_DESC_',
  /** Sort on ReferenceNo ascending */
  ReferencenoAsc = '_REFERENCENO_ASC_',
  /** sort on ReferenceNo descending */
  ReferencenoDesc = '_REFERENCENO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on DiscontinueBarCode ascending */
  DiscontinuebarcodeAsc = '_DISCONTINUEBARCODE_ASC_',
  /** sort on DiscontinueBarCode descending */
  DiscontinuebarcodeDesc = '_DISCONTINUEBARCODE_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on ExternalNo ascending */
  ExternalnoAsc = '_EXTERNALNO_ASC_',
  /** sort on ExternalNo descending */
  ExternalnoDesc = '_EXTERNALNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemRestrictionInput {
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemUnitOfMeasureInput {
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on QtyRoundingPrecision ascending */
  QtyroundingprecisionAsc = '_QTYROUNDINGPRECISION_ASC_',
  /** sort on QtyRoundingPrecision descending */
  QtyroundingprecisionDesc = '_QTYROUNDINGPRECISION_DESC_',
  /** Sort on Length ascending */
  LengthAsc = '_LENGTH_ASC_',
  /** sort on Length descending */
  LengthDesc = '_LENGTH_DESC_',
  /** Sort on Width ascending */
  WidthAsc = '_WIDTH_ASC_',
  /** sort on Width descending */
  WidthDesc = '_WIDTH_DESC_',
  /** Sort on Height ascending */
  HeightAsc = '_HEIGHT_ASC_',
  /** sort on Height descending */
  HeightDesc = '_HEIGHT_DESC_',
  /** Sort on Cubage ascending */
  CubageAsc = '_CUBAGE_ASC_',
  /** sort on Cubage descending */
  CubageDesc = '_CUBAGE_DESC_',
  /** Sort on Weight ascending */
  WeightAsc = '_WEIGHT_ASC_',
  /** sort on Weight descending */
  WeightDesc = '_WEIGHT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortItemVendorInput {
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortJobTaskInput {
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on JobTaskType ascending */
  JobtasktypeAsc = '_JOBTASKTYPE_ASC_',
  /** sort on JobTaskType descending */
  JobtasktypeDesc = '_JOBTASKTYPE_DESC_',
  /** Sort on JobTaskTypeOPTION ascending */
  JobtasktypeoptionAsc = '_JOBTASKTYPEOPTION_ASC_',
  /** sort on JobTaskTypeOPTION descending */
  JobtasktypeoptionDesc = '_JOBTASKTYPEOPTION_DESC_',
  /** Sort on WIPTotal ascending */
  WiptotalAsc = '_WIPTOTAL_ASC_',
  /** sort on WIPTotal descending */
  WiptotalDesc = '_WIPTOTAL_DESC_',
  /** Sort on WIPTotalOPTION ascending */
  WiptotaloptionAsc = '_WIPTOTALOPTION_ASC_',
  /** sort on WIPTotalOPTION descending */
  WiptotaloptionDesc = '_WIPTOTALOPTION_DESC_',
  /** Sort on JobPostingGroup ascending */
  JobpostinggroupAsc = '_JOBPOSTINGGROUP_ASC_',
  /** sort on JobPostingGroup descending */
  JobpostinggroupDesc = '_JOBPOSTINGGROUP_DESC_',
  /** Sort on WIPMethod ascending */
  WipmethodAsc = '_WIPMETHOD_ASC_',
  /** sort on WIPMethod descending */
  WipmethodDesc = '_WIPMETHOD_DESC_',
  /** Sort on Totaling ascending */
  TotalingAsc = '_TOTALING_ASC_',
  /** sort on Totaling descending */
  TotalingDesc = '_TOTALING_DESC_',
  /** Sort on NewPage ascending */
  NewpageAsc = '_NEWPAGE_ASC_',
  /** sort on NewPage descending */
  NewpageDesc = '_NEWPAGE_DESC_',
  /** Sort on NoofBlankLines ascending */
  NoofblanklinesAsc = '_NOOFBLANKLINES_ASC_',
  /** sort on NoofBlankLines descending */
  NoofblanklinesDesc = '_NOOFBLANKLINES_DESC_',
  /** Sort on Indentation ascending */
  IndentationAsc = '_INDENTATION_ASC_',
  /** sort on Indentation descending */
  IndentationDesc = '_INDENTATION_DESC_',
  /** Sort on RecognizedSalesAmount ascending */
  RecognizedsalesamountAsc = '_RECOGNIZEDSALESAMOUNT_ASC_',
  /** sort on RecognizedSalesAmount descending */
  RecognizedsalesamountDesc = '_RECOGNIZEDSALESAMOUNT_DESC_',
  /** Sort on RecognizedCostsAmount ascending */
  RecognizedcostsamountAsc = '_RECOGNIZEDCOSTSAMOUNT_ASC_',
  /** sort on RecognizedCostsAmount descending */
  RecognizedcostsamountDesc = '_RECOGNIZEDCOSTSAMOUNT_DESC_',
  /** Sort on RecognizedSalesGLAmount ascending */
  RecognizedsalesglamountAsc = '_RECOGNIZEDSALESGLAMOUNT_ASC_',
  /** sort on RecognizedSalesGLAmount descending */
  RecognizedsalesglamountDesc = '_RECOGNIZEDSALESGLAMOUNT_DESC_',
  /** Sort on RecognizedCostsGLAmount ascending */
  RecognizedcostsglamountAsc = '_RECOGNIZEDCOSTSGLAMOUNT_ASC_',
  /** sort on RecognizedCostsGLAmount descending */
  RecognizedcostsglamountDesc = '_RECOGNIZEDCOSTSGLAMOUNT_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PostingDateFilter ascending */
  PostingdatefilterAsc = '_POSTINGDATEFILTER_ASC_',
  /** sort on PostingDateFilter descending */
  PostingdatefilterDesc = '_POSTINGDATEFILTER_DESC_',
  /** Sort on PlanningDateFilter ascending */
  PlanningdatefilterAsc = '_PLANNINGDATEFILTER_ASC_',
  /** sort on PlanningDateFilter descending */
  PlanningdatefilterDesc = '_PLANNINGDATEFILTER_DESC_',
  /** Sort on ScheduleTotalCost ascending */
  ScheduletotalcostAsc = '_SCHEDULETOTALCOST_ASC_',
  /** sort on ScheduleTotalCost descending */
  ScheduletotalcostDesc = '_SCHEDULETOTALCOST_DESC_',
  /** Sort on ScheduleTotalPrice ascending */
  ScheduletotalpriceAsc = '_SCHEDULETOTALPRICE_ASC_',
  /** sort on ScheduleTotalPrice descending */
  ScheduletotalpriceDesc = '_SCHEDULETOTALPRICE_DESC_',
  /** Sort on UsageTotalCost ascending */
  UsagetotalcostAsc = '_USAGETOTALCOST_ASC_',
  /** sort on UsageTotalCost descending */
  UsagetotalcostDesc = '_USAGETOTALCOST_DESC_',
  /** Sort on UsageTotalPrice ascending */
  UsagetotalpriceAsc = '_USAGETOTALPRICE_ASC_',
  /** sort on UsageTotalPrice descending */
  UsagetotalpriceDesc = '_USAGETOTALPRICE_DESC_',
  /** Sort on ContractTotalCost ascending */
  ContracttotalcostAsc = '_CONTRACTTOTALCOST_ASC_',
  /** sort on ContractTotalCost descending */
  ContracttotalcostDesc = '_CONTRACTTOTALCOST_DESC_',
  /** Sort on ContractTotalPrice ascending */
  ContracttotalpriceAsc = '_CONTRACTTOTALPRICE_ASC_',
  /** sort on ContractTotalPrice descending */
  ContracttotalpriceDesc = '_CONTRACTTOTALPRICE_DESC_',
  /** Sort on ContractInvoicedPrice ascending */
  ContractinvoicedpriceAsc = '_CONTRACTINVOICEDPRICE_ASC_',
  /** sort on ContractInvoicedPrice descending */
  ContractinvoicedpriceDesc = '_CONTRACTINVOICEDPRICE_DESC_',
  /** Sort on ContractInvoicedCost ascending */
  ContractinvoicedcostAsc = '_CONTRACTINVOICEDCOST_ASC_',
  /** sort on ContractInvoicedCost descending */
  ContractinvoicedcostDesc = '_CONTRACTINVOICEDCOST_DESC_',
  /** Sort on OutstandingOrders ascending */
  OutstandingordersAsc = '_OUTSTANDINGORDERS_ASC_',
  /** sort on OutstandingOrders descending */
  OutstandingordersDesc = '_OUTSTANDINGORDERS_DESC_',
  /** Sort on AmtRcdNotInvoiced ascending */
  AmtrcdnotinvoicedAsc = '_AMTRCDNOTINVOICED_ASC_',
  /** sort on AmtRcdNotInvoiced descending */
  AmtrcdnotinvoicedDesc = '_AMTRCDNOTINVOICED_DESC_',
  /** Sort on RemainingTotalCost ascending */
  RemainingtotalcostAsc = '_REMAININGTOTALCOST_ASC_',
  /** sort on RemainingTotalCost descending */
  RemainingtotalcostDesc = '_REMAININGTOTALCOST_DESC_',
  /** Sort on RemainingTotalPrice ascending */
  RemainingtotalpriceAsc = '_REMAININGTOTALPRICE_ASC_',
  /** sort on RemainingTotalPrice descending */
  RemainingtotalpriceDesc = '_REMAININGTOTALPRICE_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on EndDate ascending */
  EnddateAsc = '_ENDDATE_ASC_',
  /** sort on EndDate descending */
  EnddateDesc = '_ENDDATE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on ClosedOn ascending */
  ClosedonAsc = '_CLOSEDON_ASC_',
  /** sort on ClosedOn descending */
  ClosedonDesc = '_CLOSEDON_DESC_',
  /** Sort on FirstSavedOn ascending */
  FirstsavedonAsc = '_FIRSTSAVEDON_ASC_',
  /** sort on FirstSavedOn descending */
  FirstsavedonDesc = '_FIRSTSAVEDON_DESC_',
  /** Sort on DevopsId ascending */
  DevopsidAsc = '_DEVOPSID_ASC_',
  /** sort on DevopsId descending */
  DevopsidDesc = '_DEVOPSID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortKliHeaderInput {
  /** Sort on ErrandNo ascending */
  ErrandnoAsc = '_ERRANDNO_ASC_',
  /** sort on ErrandNo descending */
  ErrandnoDesc = '_ERRANDNO_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on NameDescription ascending */
  NamedescriptionAsc = '_NAMEDESCRIPTION_ASC_',
  /** sort on NameDescription descending */
  NamedescriptionDesc = '_NAMEDESCRIPTION_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on Reason ascending */
  ReasonAsc = '_REASON_ASC_',
  /** sort on Reason descending */
  ReasonDesc = '_REASON_DESC_',
  /** Sort on Credit ascending */
  CreditAsc = '_CREDIT_ASC_',
  /** sort on Credit descending */
  CreditDesc = '_CREDIT_DESC_',
  /** Sort on Timemin ascending */
  TimeminAsc = '_TIMEMIN_ASC_',
  /** sort on Timemin descending */
  TimeminDesc = '_TIMEMIN_DESC_',
  /** Sort on Cost ascending */
  CostAsc = '_COST_ASC_',
  /** sort on Cost descending */
  CostDesc = '_COST_DESC_',
  /** Sort on QAdistdat ascending */
  QadistdatAsc = '_QADISTDAT_ASC_',
  /** sort on QAdistdat descending */
  QadistdatDesc = '_QADISTDAT_DESC_',
  /** Sort on FinnishedDate ascending */
  FinnisheddateAsc = '_FINNISHEDDATE_ASC_',
  /** sort on FinnishedDate descending */
  FinnisheddateDesc = '_FINNISHEDDATE_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on CorectivAction ascending */
  CorectivactionAsc = '_CORECTIVACTION_ASC_',
  /** sort on CorectivAction descending */
  CorectivactionDesc = '_CORECTIVACTION_DESC_',
  /** Sort on NewInvoice ascending */
  NewinvoiceAsc = '_NEWINVOICE_ASC_',
  /** sort on NewInvoice descending */
  NewinvoiceDesc = '_NEWINVOICE_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on CurrentOwnerEmail ascending */
  CurrentowneremailAsc = '_CURRENTOWNEREMAIL_ASC_',
  /** sort on CurrentOwnerEmail descending */
  CurrentowneremailDesc = '_CURRENTOWNEREMAIL_DESC_',
  /** Sort on Closed ascending */
  ClosedAsc = '_CLOSED_ASC_',
  /** sort on Closed descending */
  ClosedDesc = '_CLOSED_DESC_',
  /** Sort on CostPerMin ascending */
  CostperminAsc = '_COSTPERMIN_ASC_',
  /** sort on CostPerMin descending */
  CostperminDesc = '_COSTPERMIN_DESC_',
  /** Sort on ReasonText ascending */
  ReasontextAsc = '_REASONTEXT_ASC_',
  /** sort on ReasonText descending */
  ReasontextDesc = '_REASONTEXT_DESC_',
  /** Sort on Action ascending */
  ActionAsc = '_ACTION_ASC_',
  /** sort on Action descending */
  ActionDesc = '_ACTION_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ImpactOnStock ascending */
  ImpactonstockAsc = '_IMPACTONSTOCK_ASC_',
  /** sort on ImpactOnStock descending */
  ImpactonstockDesc = '_IMPACTONSTOCK_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on InternCost ascending */
  InterncostAsc = '_INTERNCOST_ASC_',
  /** sort on InternCost descending */
  InterncostDesc = '_INTERNCOST_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on CreatedDate ascending */
  CreateddateAsc = '_CREATEDDATE_ASC_',
  /** sort on CreatedDate descending */
  CreateddateDesc = '_CREATEDDATE_DESC_',
  /** Sort on ModifiedBy ascending */
  ModifiedbyAsc = '_MODIFIEDBY_ASC_',
  /** sort on ModifiedBy descending */
  ModifiedbyDesc = '_MODIFIEDBY_DESC_',
  /** Sort on ModifiedDate ascending */
  ModifieddateAsc = '_MODIFIEDDATE_ASC_',
  /** sort on ModifiedDate descending */
  ModifieddateDesc = '_MODIFIEDDATE_DESC_',
  /** Sort on Guarantee ascending */
  GuaranteeAsc = '_GUARANTEE_ASC_',
  /** sort on Guarantee descending */
  GuaranteeDesc = '_GUARANTEE_DESC_',
  /** Sort on ReasonCodeDesc ascending */
  ReasoncodedescAsc = '_REASONCODEDESC_ASC_',
  /** sort on ReasonCodeDesc descending */
  ReasoncodedescDesc = '_REASONCODEDESC_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on Workplace ascending */
  WorkplaceAsc = '_WORKPLACE_ASC_',
  /** sort on Workplace descending */
  WorkplaceDesc = '_WORKPLACE_DESC_',
  /** Sort on Caller ascending */
  CallerAsc = '_CALLER_ASC_',
  /** sort on Caller descending */
  CallerDesc = '_CALLER_DESC_',
  /** Sort on CallerPhone ascending */
  CallerphoneAsc = '_CALLERPHONE_ASC_',
  /** sort on CallerPhone descending */
  CallerphoneDesc = '_CALLERPHONE_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on ModelYear ascending */
  ModelyearAsc = '_MODELYEAR_ASC_',
  /** sort on ModelYear descending */
  ModelyearDesc = '_MODELYEAR_DESC_',
  /** Sort on Pirority ascending */
  PirorityAsc = '_PIRORITY_ASC_',
  /** sort on Pirority descending */
  PirorityDesc = '_PIRORITY_DESC_',
  /** Sort on RecType ascending */
  RectypeAsc = '_RECTYPE_ASC_',
  /** sort on RecType descending */
  RectypeDesc = '_RECTYPE_DESC_',
  /** Sort on RecTypeOPTION ascending */
  RectypeoptionAsc = '_RECTYPEOPTION_ASC_',
  /** sort on RecTypeOPTION descending */
  RectypeoptionDesc = '_RECTYPEOPTION_DESC_',
  /** Sort on RevisitNeeded ascending */
  RevisitneededAsc = '_REVISITNEEDED_ASC_',
  /** sort on RevisitNeeded descending */
  RevisitneededDesc = '_REVISITNEEDED_DESC_',
  /** Sort on MachineReplaced ascending */
  MachinereplacedAsc = '_MACHINEREPLACED_ASC_',
  /** sort on MachineReplaced descending */
  MachinereplacedDesc = '_MACHINEREPLACED_DESC_',
  /** Sort on AnalysisCode ascending */
  AnalysiscodeAsc = '_ANALYSISCODE_ASC_',
  /** sort on AnalysisCode descending */
  AnalysiscodeDesc = '_ANALYSISCODE_DESC_',
  /** Sort on AnalysisText ascending */
  AnalysistextAsc = '_ANALYSISTEXT_ASC_',
  /** sort on AnalysisText descending */
  AnalysistextDesc = '_ANALYSISTEXT_DESC_',
  /** Sort on MainErrand ascending */
  MainerrandAsc = '_MAINERRAND_ASC_',
  /** sort on MainErrand descending */
  MainerrandDesc = '_MAINERRAND_DESC_',
  /** Sort on LinkErrand ascending */
  LinkerrandAsc = '_LINKERRAND_ASC_',
  /** sort on LinkErrand descending */
  LinkerrandDesc = '_LINKERRAND_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on SendToRepairer ascending */
  SendtorepairerAsc = '_SENDTOREPAIRER_ASC_',
  /** sort on SendToRepairer descending */
  SendtorepairerDesc = '_SENDTOREPAIRER_DESC_',
  /** Sort on FollowUp ascending */
  FollowupAsc = '_FOLLOWUP_ASC_',
  /** sort on FollowUp descending */
  FollowupDesc = '_FOLLOWUP_DESC_',
  /** Sort on ErrandDescription ascending */
  ErranddescriptionAsc = '_ERRANDDESCRIPTION_ASC_',
  /** sort on ErrandDescription descending */
  ErranddescriptionDesc = '_ERRANDDESCRIPTION_DESC_',
  /** Sort on FollowUpPreformed ascending */
  FollowuppreformedAsc = '_FOLLOWUPPREFORMED_ASC_',
  /** sort on FollowUpPreformed descending */
  FollowuppreformedDesc = '_FOLLOWUPPREFORMED_DESC_',
  /** Sort on CorectivActionPreformed ascending */
  CorectivactionpreformedAsc = '_CORECTIVACTIONPREFORMED_ASC_',
  /** sort on CorectivActionPreformed descending */
  CorectivactionpreformedDesc = '_CORECTIVACTIONPREFORMED_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on LastStatusBeforeClose ascending */
  LaststatusbeforecloseAsc = '_LASTSTATUSBEFORECLOSE_ASC_',
  /** sort on LastStatusBeforeClose descending */
  LaststatusbeforecloseDesc = '_LASTSTATUSBEFORECLOSE_DESC_',
  /** Sort on LastStatusBeforeCloseOPTION ascending */
  LaststatusbeforecloseoptionAsc = '_LASTSTATUSBEFORECLOSEOPTION_ASC_',
  /** sort on LastStatusBeforeCloseOPTION descending */
  LaststatusbeforecloseoptionDesc = '_LASTSTATUSBEFORECLOSEOPTION_DESC_',
  /** Sort on WorkplaceContact ascending */
  WorkplacecontactAsc = '_WORKPLACECONTACT_ASC_',
  /** sort on WorkplaceContact descending */
  WorkplacecontactDesc = '_WORKPLACECONTACT_DESC_',
  /** Sort on WorkplaceContactPhone ascending */
  WorkplacecontactphoneAsc = '_WORKPLACECONTACTPHONE_ASC_',
  /** sort on WorkplaceContactPhone descending */
  WorkplacecontactphoneDesc = '_WORKPLACECONTACTPHONE_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on TableNo ascending */
  TablenoAsc = '_TABLENO_ASC_',
  /** sort on TableNo descending */
  TablenoDesc = '_TABLENO_DESC_',
  /** Sort on ReasonTextBlob ascending */
  ReasontextblobAsc = '_REASONTEXTBLOB_ASC_',
  /** sort on ReasonTextBlob descending */
  ReasontextblobDesc = '_REASONTEXTBLOB_DESC_',
  /** Sort on ActionTextBlob ascending */
  ActiontextblobAsc = '_ACTIONTEXTBLOB_ASC_',
  /** sort on ActionTextBlob descending */
  ActiontextblobDesc = '_ACTIONTEXTBLOB_DESC_',
  /** Sort on FixedByExternalRepairer ascending */
  FixedbyexternalrepairerAsc = '_FIXEDBYEXTERNALREPAIRER_ASC_',
  /** sort on FixedByExternalRepairer descending */
  FixedbyexternalrepairerDesc = '_FIXEDBYEXTERNALREPAIRER_DESC_',
  /** Sort on TurnaroundTime ascending */
  TurnaroundtimeAsc = '_TURNAROUNDTIME_ASC_',
  /** sort on TurnaroundTime descending */
  TurnaroundtimeDesc = '_TURNAROUNDTIME_DESC_',
  /** Sort on ReportedToWorkingEnvDpt ascending */
  ReportedtoworkingenvdptAsc = '_REPORTEDTOWORKINGENVDPT_ASC_',
  /** sort on ReportedToWorkingEnvDpt descending */
  ReportedtoworkingenvdptDesc = '_REPORTEDTOWORKINGENVDPT_DESC_',
  /** Sort on SafteyOfficerInformed ascending */
  SafteyofficerinformedAsc = '_SAFTEYOFFICERINFORMED_ASC_',
  /** sort on SafteyOfficerInformed descending */
  SafteyofficerinformedDesc = '_SAFTEYOFFICERINFORMED_DESC_',
  /** Sort on NewRiskAnalysis ascending */
  NewriskanalysisAsc = '_NEWRISKANALYSIS_ASC_',
  /** sort on NewRiskAnalysis descending */
  NewriskanalysisDesc = '_NEWRISKANALYSIS_DESC_',
  /** Sort on AnalysisTextBLOB ascending */
  AnalysistextblobAsc = '_ANALYSISTEXTBLOB_ASC_',
  /** sort on AnalysisTextBLOB descending */
  AnalysistextblobDesc = '_ANALYSISTEXTBLOB_DESC_',
  /** Sort on FollowUpBLOB ascending */
  FollowupblobAsc = '_FOLLOWUPBLOB_ASC_',
  /** sort on FollowUpBLOB descending */
  FollowupblobDesc = '_FOLLOWUPBLOB_DESC_',
  /** Sort on CorectivActionBLOB ascending */
  CorectivactionblobAsc = '_CORECTIVACTIONBLOB_ASC_',
  /** sort on CorectivActionBLOB descending */
  CorectivactionblobDesc = '_CORECTIVACTIONBLOB_DESC_',
  /** Sort on AccidentComment ascending */
  AccidentcommentAsc = '_ACCIDENTCOMMENT_ASC_',
  /** sort on AccidentComment descending */
  AccidentcommentDesc = '_ACCIDENTCOMMENT_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on CreditNo ascending */
  CreditnoAsc = '_CREDITNO_ASC_',
  /** sort on CreditNo descending */
  CreditnoDesc = '_CREDITNO_DESC_',
  /** Sort on CreditPosted ascending */
  CreditpostedAsc = '_CREDITPOSTED_ASC_',
  /** sort on CreditPosted descending */
  CreditpostedDesc = '_CREDITPOSTED_DESC_',
  /** Sort on CreditApproved ascending */
  CreditapprovedAsc = '_CREDITAPPROVED_ASC_',
  /** sort on CreditApproved descending */
  CreditapprovedDesc = '_CREDITAPPROVED_DESC_',
  /** Sort on CreditApprovedBy ascending */
  CreditapprovedbyAsc = '_CREDITAPPROVEDBY_ASC_',
  /** sort on CreditApprovedBy descending */
  CreditapprovedbyDesc = '_CREDITAPPROVEDBY_DESC_',
  /** Sort on NoOfLinkedErrands ascending */
  NooflinkederrandsAsc = '_NOOFLINKEDERRANDS_ASC_',
  /** sort on NoOfLinkedErrands descending */
  NooflinkederrandsDesc = '_NOOFLINKEDERRANDS_DESC_',
  /** Sort on VendorName ascending */
  VendornameAsc = '_VENDORNAME_ASC_',
  /** sort on VendorName descending */
  VendornameDesc = '_VENDORNAME_DESC_',
  /** Sort on TurnOverToEmail ascending */
  TurnovertoemailAsc = '_TURNOVERTOEMAIL_ASC_',
  /** sort on TurnOverToEmail descending */
  TurnovertoemailDesc = '_TURNOVERTOEMAIL_DESC_',
  /** Sort on CreatedExternal ascending */
  CreatedexternalAsc = '_CREATEDEXTERNAL_ASC_',
  /** sort on CreatedExternal descending */
  CreatedexternalDesc = '_CREATEDEXTERNAL_DESC_',
  /** Sort on Template ascending */
  TemplateAsc = '_TEMPLATE_ASC_',
  /** sort on Template descending */
  TemplateDesc = '_TEMPLATE_DESC_',
  /** Sort on CurrentHandler ascending */
  CurrenthandlerAsc = '_CURRENTHANDLER_ASC_',
  /** sort on CurrentHandler descending */
  CurrenthandlerDesc = '_CURRENTHANDLER_DESC_',
  /** Sort on CurrentHandlerEmail ascending */
  CurrenthandleremailAsc = '_CURRENTHANDLEREMAIL_ASC_',
  /** sort on CurrentHandlerEmail descending */
  CurrenthandleremailDesc = '_CURRENTHANDLEREMAIL_DESC_',
  /** Sort on CurrentHandlerPhone ascending */
  CurrenthandlerphoneAsc = '_CURRENTHANDLERPHONE_ASC_',
  /** sort on CurrentHandlerPhone descending */
  CurrenthandlerphoneDesc = '_CURRENTHANDLERPHONE_DESC_',
  /** Sort on CustomerDamage ascending */
  CustomerdamageAsc = '_CUSTOMERDAMAGE_ASC_',
  /** sort on CustomerDamage descending */
  CustomerdamageDesc = '_CUSTOMERDAMAGE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortKliReasonCodeInput {
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on RecType ascending */
  RectypeAsc = '_RECTYPE_ASC_',
  /** sort on RecType descending */
  RectypeDesc = '_RECTYPE_DESC_',
  /** Sort on RecTypeOPTION ascending */
  RectypeoptionAsc = '_RECTYPEOPTION_ASC_',
  /** sort on RecTypeOPTION descending */
  RectypeoptionDesc = '_RECTYPEOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortLeadInput {
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on TelexNo ascending */
  TelexnoAsc = '_TELEXNO_ASC_',
  /** sort on TelexNo descending */
  TelexnoDesc = '_TELEXNO_DESC_',
  /** Sort on TerritoryCode ascending */
  TerritorycodeAsc = '_TERRITORYCODE_ASC_',
  /** sort on TerritoryCode descending */
  TerritorycodeDesc = '_TERRITORYCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on PictureURL ascending */
  PictureurlAsc = '_PICTUREURL_ASC_',
  /** sort on PictureURL descending */
  PictureurlDesc = '_PICTUREURL_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on CompanyNo ascending */
  CompanynoAsc = '_COMPANYNO_ASC_',
  /** sort on CompanyNo descending */
  CompanynoDesc = '_COMPANYNO_DESC_',
  /** Sort on CompanyName ascending */
  CompanynameAsc = '_COMPANYNAME_ASC_',
  /** sort on CompanyName descending */
  CompanynameDesc = '_COMPANYNAME_DESC_',
  /** Sort on FirstName ascending */
  FirstnameAsc = '_FIRSTNAME_ASC_',
  /** sort on FirstName descending */
  FirstnameDesc = '_FIRSTNAME_DESC_',
  /** Sort on Surname ascending */
  SurnameAsc = '_SURNAME_ASC_',
  /** sort on Surname descending */
  SurnameDesc = '_SURNAME_DESC_',
  /** Sort on JobTitle ascending */
  JobtitleAsc = '_JOBTITLE_ASC_',
  /** sort on JobTitle descending */
  JobtitleDesc = '_JOBTITLE_DESC_',
  /** Sort on Initials ascending */
  InitialsAsc = '_INITIALS_ASC_',
  /** sort on Initials descending */
  InitialsDesc = '_INITIALS_DESC_',
  /** Sort on MobilePhoneNo ascending */
  MobilephonenoAsc = '_MOBILEPHONENO_ASC_',
  /** sort on MobilePhoneNo descending */
  MobilephonenoDesc = '_MOBILEPHONENO_DESC_',
  /** Sort on Subject ascending */
  SubjectAsc = '_SUBJECT_ASC_',
  /** sort on Subject descending */
  SubjectDesc = '_SUBJECT_DESC_',
  /** Sort on OwnerEmail ascending */
  OwneremailAsc = '_OWNEREMAIL_ASC_',
  /** sort on OwnerEmail descending */
  OwneremailDesc = '_OWNEREMAIL_DESC_',
  /** Sort on OwnerName ascending */
  OwnernameAsc = '_OWNERNAME_ASC_',
  /** sort on OwnerName descending */
  OwnernameDesc = '_OWNERNAME_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortLocationInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on DefaultBinCode ascending */
  DefaultbincodeAsc = '_DEFAULTBINCODE_ASC_',
  /** sort on DefaultBinCode descending */
  DefaultbincodeDesc = '_DEFAULTBINCODE_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on PhoneNo2 ascending */
  Phoneno2Asc = '_PHONENO2_ASC_',
  /** sort on PhoneNo2 descending */
  Phoneno2Desc = '_PHONENO2_DESC_',
  /** Sort on TelexNo ascending */
  TelexnoAsc = '_TELEXNO_ASC_',
  /** sort on TelexNo descending */
  TelexnoDesc = '_TELEXNO_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on UseAsInTransit ascending */
  UseasintransitAsc = '_USEASINTRANSIT_ASC_',
  /** sort on UseAsInTransit descending */
  UseasintransitDesc = '_USEASINTRANSIT_DESC_',
  /** Sort on RequirePutaway ascending */
  RequireputawayAsc = '_REQUIREPUTAWAY_ASC_',
  /** sort on RequirePutaway descending */
  RequireputawayDesc = '_REQUIREPUTAWAY_DESC_',
  /** Sort on RequirePick ascending */
  RequirepickAsc = '_REQUIREPICK_ASC_',
  /** sort on RequirePick descending */
  RequirepickDesc = '_REQUIREPICK_DESC_',
  /** Sort on CrossDockDueDateCalc ascending */
  CrossdockduedatecalcAsc = '_CROSSDOCKDUEDATECALC_ASC_',
  /** sort on CrossDockDueDateCalc descending */
  CrossdockduedatecalcDesc = '_CROSSDOCKDUEDATECALC_DESC_',
  /** Sort on UseCrossDocking ascending */
  UsecrossdockingAsc = '_USECROSSDOCKING_ASC_',
  /** sort on UseCrossDocking descending */
  UsecrossdockingDesc = '_USECROSSDOCKING_DESC_',
  /** Sort on RequireReceive ascending */
  RequirereceiveAsc = '_REQUIRERECEIVE_ASC_',
  /** sort on RequireReceive descending */
  RequirereceiveDesc = '_REQUIRERECEIVE_DESC_',
  /** Sort on RequireShipment ascending */
  RequireshipmentAsc = '_REQUIRESHIPMENT_ASC_',
  /** sort on RequireShipment descending */
  RequireshipmentDesc = '_REQUIRESHIPMENT_DESC_',
  /** Sort on BinMandatory ascending */
  BinmandatoryAsc = '_BINMANDATORY_ASC_',
  /** sort on BinMandatory descending */
  BinmandatoryDesc = '_BINMANDATORY_DESC_',
  /** Sort on DirectedPutawayandPick ascending */
  DirectedputawayandpickAsc = '_DIRECTEDPUTAWAYANDPICK_ASC_',
  /** sort on DirectedPutawayandPick descending */
  DirectedputawayandpickDesc = '_DIRECTEDPUTAWAYANDPICK_DESC_',
  /** Sort on DefaultBinSelection ascending */
  DefaultbinselectionAsc = '_DEFAULTBINSELECTION_ASC_',
  /** sort on DefaultBinSelection descending */
  DefaultbinselectionDesc = '_DEFAULTBINSELECTION_DESC_',
  /** Sort on DefaultBinSelectionOPTION ascending */
  DefaultbinselectionoptionAsc = '_DEFAULTBINSELECTIONOPTION_ASC_',
  /** sort on DefaultBinSelectionOPTION descending */
  DefaultbinselectionoptionDesc = '_DEFAULTBINSELECTIONOPTION_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PutawayTemplateCode ascending */
  PutawaytemplatecodeAsc = '_PUTAWAYTEMPLATECODE_ASC_',
  /** sort on PutawayTemplateCode descending */
  PutawaytemplatecodeDesc = '_PUTAWAYTEMPLATECODE_DESC_',
  /** Sort on UsePutawayWorksheet ascending */
  UseputawayworksheetAsc = '_USEPUTAWAYWORKSHEET_ASC_',
  /** sort on UsePutawayWorksheet descending */
  UseputawayworksheetDesc = '_USEPUTAWAYWORKSHEET_DESC_',
  /** Sort on PickAccordingtoFEFO ascending */
  PickaccordingtofefoAsc = '_PICKACCORDINGTOFEFO_ASC_',
  /** sort on PickAccordingtoFEFO descending */
  PickaccordingtofefoDesc = '_PICKACCORDINGTOFEFO_DESC_',
  /** Sort on AllowBreakbulk ascending */
  AllowbreakbulkAsc = '_ALLOWBREAKBULK_ASC_',
  /** sort on AllowBreakbulk descending */
  AllowbreakbulkDesc = '_ALLOWBREAKBULK_DESC_',
  /** Sort on BinCapacityPolicy ascending */
  BincapacitypolicyAsc = '_BINCAPACITYPOLICY_ASC_',
  /** sort on BinCapacityPolicy descending */
  BincapacitypolicyDesc = '_BINCAPACITYPOLICY_DESC_',
  /** Sort on BinCapacityPolicyOPTION ascending */
  BincapacitypolicyoptionAsc = '_BINCAPACITYPOLICYOPTION_ASC_',
  /** sort on BinCapacityPolicyOPTION descending */
  BincapacitypolicyoptionDesc = '_BINCAPACITYPOLICYOPTION_DESC_',
  /** Sort on OpenShopFloorBinCode ascending */
  OpenshopfloorbincodeAsc = '_OPENSHOPFLOORBINCODE_ASC_',
  /** sort on OpenShopFloorBinCode descending */
  OpenshopfloorbincodeDesc = '_OPENSHOPFLOORBINCODE_DESC_',
  /** Sort on ToProductionBinCode ascending */
  ToproductionbincodeAsc = '_TOPRODUCTIONBINCODE_ASC_',
  /** sort on ToProductionBinCode descending */
  ToproductionbincodeDesc = '_TOPRODUCTIONBINCODE_DESC_',
  /** Sort on FromProductionBinCode ascending */
  FromproductionbincodeAsc = '_FROMPRODUCTIONBINCODE_ASC_',
  /** sort on FromProductionBinCode descending */
  FromproductionbincodeDesc = '_FROMPRODUCTIONBINCODE_DESC_',
  /** Sort on AdjustmentBinCode ascending */
  AdjustmentbincodeAsc = '_ADJUSTMENTBINCODE_ASC_',
  /** sort on AdjustmentBinCode descending */
  AdjustmentbincodeDesc = '_ADJUSTMENTBINCODE_DESC_',
  /** Sort on AlwaysCreatePutawayLine ascending */
  AlwayscreateputawaylineAsc = '_ALWAYSCREATEPUTAWAYLINE_ASC_',
  /** sort on AlwaysCreatePutawayLine descending */
  AlwayscreateputawaylineDesc = '_ALWAYSCREATEPUTAWAYLINE_DESC_',
  /** Sort on AlwaysCreatePickLine ascending */
  AlwayscreatepicklineAsc = '_ALWAYSCREATEPICKLINE_ASC_',
  /** sort on AlwaysCreatePickLine descending */
  AlwayscreatepicklineDesc = '_ALWAYSCREATEPICKLINE_DESC_',
  /** Sort on SpecialEquipment ascending */
  SpecialequipmentAsc = '_SPECIALEQUIPMENT_ASC_',
  /** sort on SpecialEquipment descending */
  SpecialequipmentDesc = '_SPECIALEQUIPMENT_DESC_',
  /** Sort on SpecialEquipmentOPTION ascending */
  SpecialequipmentoptionAsc = '_SPECIALEQUIPMENTOPTION_ASC_',
  /** sort on SpecialEquipmentOPTION descending */
  SpecialequipmentoptionDesc = '_SPECIALEQUIPMENTOPTION_DESC_',
  /** Sort on ReceiptBinCode ascending */
  ReceiptbincodeAsc = '_RECEIPTBINCODE_ASC_',
  /** sort on ReceiptBinCode descending */
  ReceiptbincodeDesc = '_RECEIPTBINCODE_DESC_',
  /** Sort on ShipmentBinCode ascending */
  ShipmentbincodeAsc = '_SHIPMENTBINCODE_ASC_',
  /** sort on ShipmentBinCode descending */
  ShipmentbincodeDesc = '_SHIPMENTBINCODE_DESC_',
  /** Sort on CrossDockBinCode ascending */
  CrossdockbincodeAsc = '_CROSSDOCKBINCODE_ASC_',
  /** sort on CrossDockBinCode descending */
  CrossdockbincodeDesc = '_CROSSDOCKBINCODE_DESC_',
  /** Sort on ToAssemblyBinCode ascending */
  ToassemblybincodeAsc = '_TOASSEMBLYBINCODE_ASC_',
  /** sort on ToAssemblyBinCode descending */
  ToassemblybincodeDesc = '_TOASSEMBLYBINCODE_DESC_',
  /** Sort on FromAssemblyBinCode ascending */
  FromassemblybincodeAsc = '_FROMASSEMBLYBINCODE_ASC_',
  /** sort on FromAssemblyBinCode descending */
  FromassemblybincodeDesc = '_FROMASSEMBLYBINCODE_DESC_',
  /** Sort on AsmtoOrderShptBinCode ascending */
  AsmtoordershptbincodeAsc = '_ASMTOORDERSHPTBINCODE_ASC_',
  /** sort on AsmtoOrderShptBinCode descending */
  AsmtoordershptbincodeDesc = '_ASMTOORDERSHPTBINCODE_DESC_',
  /** Sort on ToJobBinCode ascending */
  TojobbincodeAsc = '_TOJOBBINCODE_ASC_',
  /** sort on ToJobBinCode descending */
  TojobbincodeDesc = '_TOJOBBINCODE_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on UseADCS ascending */
  UseadcsAsc = '_USEADCS_ASC_',
  /** sort on UseADCS descending */
  UseadcsDesc = '_USEADCS_DESC_',
  /** Sort on EQMInternalCustomerNo ascending */
  EqminternalcustomernoAsc = '_EQMINTERNALCUSTOMERNO_ASC_',
  /** sort on EQMInternalCustomerNo descending */
  EqminternalcustomernoDesc = '_EQMINTERNALCUSTOMERNO_DESC_',
  /** Sort on EQMReclassJnlTemplName ascending */
  EqmreclassjnltemplnameAsc = '_EQMRECLASSJNLTEMPLNAME_ASC_',
  /** sort on EQMReclassJnlTemplName descending */
  EqmreclassjnltemplnameDesc = '_EQMRECLASSJNLTEMPLNAME_DESC_',
  /** Sort on EQMReclassJnlBatchName ascending */
  EqmreclassjnlbatchnameAsc = '_EQMRECLASSJNLBATCHNAME_ASC_',
  /** sort on EQMReclassJnlBatchName descending */
  EqmreclassjnlbatchnameDesc = '_EQMRECLASSJNLBATCHNAME_DESC_',
  /** Sort on EQMMaintenanceLocation ascending */
  EqmmaintenancelocationAsc = '_EQMMAINTENANCELOCATION_ASC_',
  /** sort on EQMMaintenanceLocation descending */
  EqmmaintenancelocationDesc = '_EQMMAINTENANCELOCATION_DESC_',
  /** Sort on EQMHUB ascending */
  EqmhubAsc = '_EQMHUB_ASC_',
  /** sort on EQMHUB descending */
  EqmhubDesc = '_EQMHUB_DESC_',
  /** Sort on ExternalLocationId ascending */
  ExternallocationidAsc = '_EXTERNALLOCATIONID_ASC_',
  /** sort on ExternalLocationId descending */
  ExternallocationidDesc = '_EXTERNALLOCATIONID_DESC_',
  /** Sort on ExternalLocationCode ascending */
  ExternallocationcodeAsc = '_EXTERNALLOCATIONCODE_ASC_',
  /** sort on ExternalLocationCode descending */
  ExternallocationcodeDesc = '_EXTERNALLOCATIONCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortMagentoOrderStatusInput {
  /** Sort on EntityId ascending */
  EntityidAsc = '_ENTITYID_ASC_',
  /** sort on EntityId descending */
  EntityidDesc = '_ENTITYID_DESC_',
  /** Sort on IncrementId ascending */
  IncrementidAsc = '_INCREMENTID_ASC_',
  /** sort on IncrementId descending */
  IncrementidDesc = '_INCREMENTID_DESC_',
  /** Sort on StoreId ascending */
  StoreidAsc = '_STOREID_ASC_',
  /** sort on StoreId descending */
  StoreidDesc = '_STOREID_DESC_',
  /** Sort on WebsiteId ascending */
  WebsiteidAsc = '_WEBSITEID_ASC_',
  /** sort on WebsiteId descending */
  WebsiteidDesc = '_WEBSITEID_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on CreatedAt ascending */
  CreatedatAsc = '_CREATEDAT_ASC_',
  /** sort on CreatedAt descending */
  CreatedatDesc = '_CREATEDAT_DESC_',
  /** Sort on UpdatedAt ascending */
  UpdatedatAsc = '_UPDATEDAT_ASC_',
  /** sort on UpdatedAt descending */
  UpdatedatDesc = '_UPDATEDAT_DESC_',
  /** Sort on CheckCount ascending */
  CheckcountAsc = '_CHECKCOUNT_ASC_',
  /** sort on CheckCount descending */
  CheckcountDesc = '_CHECKCOUNT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortMagentoSettingsInput {
  /** Sort on QuestCustomerNo ascending */
  QuestcustomernoAsc = '_QUESTCUSTOMERNO_ASC_',
  /** sort on QuestCustomerNo descending */
  QuestcustomernoDesc = '_QUESTCUSTOMERNO_DESC_',
  /** Sort on QuestCustomerName ascending */
  QuestcustomernameAsc = '_QUESTCUSTOMERNAME_ASC_',
  /** sort on QuestCustomerName descending */
  QuestcustomernameDesc = '_QUESTCUSTOMERNAME_DESC_',
  /** Sort on QuestCustomerGlobalId ascending */
  QuestcustomerglobalidAsc = '_QUESTCUSTOMERGLOBALID_ASC_',
  /** sort on QuestCustomerGlobalId descending */
  QuestcustomerglobalidDesc = '_QUESTCUSTOMERGLOBALID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortManufacturerInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on MagentoAttribOptionId ascending */
  MagentoattriboptionidAsc = '_MAGENTOATTRIBOPTIONID_ASC_',
  /** sort on MagentoAttribOptionId descending */
  MagentoattriboptionidDesc = '_MAGENTOATTRIBOPTIONID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortNavUserInput {
  /** Sort on UserSecurityID ascending */
  UsersecurityidAsc = '_USERSECURITYID_ASC_',
  /** sort on UserSecurityID descending */
  UsersecurityidDesc = '_USERSECURITYID_DESC_',
  /** Sort on UserName ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on UserName descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on FullName ascending */
  FullnameAsc = '_FULLNAME_ASC_',
  /** sort on FullName descending */
  FullnameDesc = '_FULLNAME_DESC_',
  /** Sort on State ascending */
  StateAsc = '_STATE_ASC_',
  /** sort on State descending */
  StateDesc = '_STATE_DESC_',
  /** Sort on StateOPTION ascending */
  StateoptionAsc = '_STATEOPTION_ASC_',
  /** sort on StateOPTION descending */
  StateoptionDesc = '_STATEOPTION_DESC_',
  /** Sort on ExpiryDate ascending */
  ExpirydateAsc = '_EXPIRYDATE_ASC_',
  /** sort on ExpiryDate descending */
  ExpirydateDesc = '_EXPIRYDATE_DESC_',
  /** Sort on WindowsSecurityID ascending */
  WindowssecurityidAsc = '_WINDOWSSECURITYID_ASC_',
  /** sort on WindowsSecurityID descending */
  WindowssecurityidDesc = '_WINDOWSSECURITYID_DESC_',
  /** Sort on ChangePassword ascending */
  ChangepasswordAsc = '_CHANGEPASSWORD_ASC_',
  /** sort on ChangePassword descending */
  ChangepasswordDesc = '_CHANGEPASSWORD_DESC_',
  /** Sort on LicenseType ascending */
  LicensetypeAsc = '_LICENSETYPE_ASC_',
  /** sort on LicenseType descending */
  LicensetypeDesc = '_LICENSETYPE_DESC_',
  /** Sort on LicenseTypeOPTION ascending */
  LicensetypeoptionAsc = '_LICENSETYPEOPTION_ASC_',
  /** sort on LicenseTypeOPTION descending */
  LicensetypeoptionDesc = '_LICENSETYPEOPTION_DESC_',
  /** Sort on AuthenticationEmail ascending */
  AuthenticationemailAsc = '_AUTHENTICATIONEMAIL_ASC_',
  /** sort on AuthenticationEmail descending */
  AuthenticationemailDesc = '_AUTHENTICATIONEMAIL_DESC_',
  /** Sort on EdgeCostCenter ascending */
  EdgecostcenterAsc = '_EDGECOSTCENTER_ASC_',
  /** sort on EdgeCostCenter descending */
  EdgecostcenterDesc = '_EDGECOSTCENTER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectCommentLineInput {
  /** Sort on TableName ascending */
  TablenameAsc = '_TABLENAME_ASC_',
  /** sort on TableName descending */
  TablenameDesc = '_TABLENAME_DESC_',
  /** Sort on TableNameOPTION ascending */
  TablenameoptionAsc = '_TABLENAMEOPTION_ASC_',
  /** sort on TableNameOPTION descending */
  TablenameoptionDesc = '_TABLENAMEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectFamilyInput {
  /** Sort on Family ascending */
  FamilyAsc = '_FAMILY_ASC_',
  /** sort on Family descending */
  FamilyDesc = '_FAMILY_DESC_',
  /** Sort on ImgUrlSmall ascending */
  ImgurlsmallAsc = '_IMGURLSMALL_ASC_',
  /** sort on ImgUrlSmall descending */
  ImgurlsmallDesc = '_IMGURLSMALL_DESC_',
  /** Sort on ImgUrlLarge ascending */
  ImgurllargeAsc = '_IMGURLLARGE_ASC_',
  /** sort on ImgUrlLarge descending */
  ImgurllargeDesc = '_IMGURLLARGE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectGroupInput {
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpIntRent ascending */
  GenprodpostinggrpintrentAsc = '_GENPRODPOSTINGGRPINTRENT_ASC_',
  /** sort on GenProdPostingGrpIntRent descending */
  GenprodpostinggrpintrentDesc = '_GENPRODPOSTINGGRPINTRENT_DESC_',
  /** Sort on GenProdPostingGrpExtRent ascending */
  GenprodpostinggrpextrentAsc = '_GENPRODPOSTINGGRPEXTRENT_ASC_',
  /** sort on GenProdPostingGrpExtRent descending */
  GenprodpostinggrpextrentDesc = '_GENPRODPOSTINGGRPEXTRENT_DESC_',
  /** Sort on ObjectNos ascending */
  ObjectnosAsc = '_OBJECTNOS_ASC_',
  /** sort on ObjectNos descending */
  ObjectnosDesc = '_OBJECTNOS_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on FAClassCode ascending */
  FaclasscodeAsc = '_FACLASSCODE_ASC_',
  /** sort on FAClassCode descending */
  FaclasscodeDesc = '_FACLASSCODE_DESC_',
  /** Sort on FASubclassCode ascending */
  FasubclasscodeAsc = '_FASUBCLASSCODE_ASC_',
  /** sort on FASubclassCode descending */
  FasubclasscodeDesc = '_FASUBCLASSCODE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepreciationMethod ascending */
  DepreciationmethodAsc = '_DEPRECIATIONMETHOD_ASC_',
  /** sort on DepreciationMethod descending */
  DepreciationmethodDesc = '_DEPRECIATIONMETHOD_DESC_',
  /** Sort on DepreciationMethodOPTION ascending */
  DepreciationmethodoptionAsc = '_DEPRECIATIONMETHODOPTION_ASC_',
  /** sort on DepreciationMethodOPTION descending */
  DepreciationmethodoptionDesc = '_DEPRECIATIONMETHODOPTION_DESC_',
  /** Sort on FAPostingGroup ascending */
  FapostinggroupAsc = '_FAPOSTINGGROUP_ASC_',
  /** sort on FAPostingGroup descending */
  FapostinggroupDesc = '_FAPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpHire ascending */
  GenprodpostinggrphireAsc = '_GENPRODPOSTINGGRPHIRE_ASC_',
  /** sort on GenProdPostingGrpHire descending */
  GenprodpostinggrphireDesc = '_GENPRODPOSTINGGRPHIRE_DESC_',
  /** Sort on GenProdPostingGrpLeasing ascending */
  GenprodpostinggrpleasingAsc = '_GENPRODPOSTINGGRPLEASING_ASC_',
  /** sort on GenProdPostingGrpLeasing descending */
  GenprodpostinggrpleasingDesc = '_GENPRODPOSTINGGRPLEASING_DESC_',
  /** Sort on RentalSplitPostingGroup ascending */
  RentalsplitpostinggroupAsc = '_RENTALSPLITPOSTINGGROUP_ASC_',
  /** sort on RentalSplitPostingGroup descending */
  RentalsplitpostinggroupDesc = '_RENTALSPLITPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpWriteUp ascending */
  GenprodpostinggrpwriteupAsc = '_GENPRODPOSTINGGRPWRITEUP_ASC_',
  /** sort on GenProdPostingGrpWriteUp descending */
  GenprodpostinggrpwriteupDesc = '_GENPRODPOSTINGGRPWRITEUP_DESC_',
  /** Sort on NoofDepreciationYears ascending */
  NoofdepreciationyearsAsc = '_NOOFDEPRECIATIONYEARS_ASC_',
  /** sort on NoofDepreciationYears descending */
  NoofdepreciationyearsDesc = '_NOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on NoofDepreciationMonths ascending */
  NoofdepreciationmonthsAsc = '_NOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on NoofDepreciationMonths descending */
  NoofdepreciationmonthsDesc = '_NOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on ServiceItemGroup ascending */
  ServiceitemgroupAsc = '_SERVICEITEMGROUP_ASC_',
  /** sort on ServiceItemGroup descending */
  ServiceitemgroupDesc = '_SERVICEITEMGROUP_DESC_',
  /** Sort on CreditonEarlyOffRent ascending */
  CreditonearlyoffrentAsc = '_CREDITONEARLYOFFRENT_ASC_',
  /** sort on CreditonEarlyOffRent descending */
  CreditonearlyoffrentDesc = '_CREDITONEARLYOFFRENT_DESC_',
  /** Sort on StdRentalChargeDays ascending */
  StdrentalchargedaysAsc = '_STDRENTALCHARGEDAYS_ASC_',
  /** sort on StdRentalChargeDays descending */
  StdrentalchargedaysDesc = '_STDRENTALCHARGEDAYS_DESC_',
  /** Sort on StdRentalChargeDaysOPTION ascending */
  StdrentalchargedaysoptionAsc = '_STDRENTALCHARGEDAYSOPTION_ASC_',
  /** sort on StdRentalChargeDaysOPTION descending */
  StdrentalchargedaysoptionDesc = '_STDRENTALCHARGEDAYSOPTION_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on ObjectGroupNoConcern ascending */
  ObjectgroupnoconcernAsc = '_OBJECTGROUPNOCONCERN_ASC_',
  /** sort on ObjectGroupNoConcern descending */
  ObjectgroupnoconcernDesc = '_OBJECTGROUPNOCONCERN_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Description3 ascending */
  Description3Asc = '_DESCRIPTION3_ASC_',
  /** sort on Description3 descending */
  Description3Desc = '_DESCRIPTION3_DESC_',
  /** Sort on Description4 ascending */
  Description4Asc = '_DESCRIPTION4_ASC_',
  /** sort on Description4 descending */
  Description4Desc = '_DESCRIPTION4_DESC_',
  /** Sort on StraightLine ascending */
  StraightlineAsc = '_STRAIGHTLINE_ASC_',
  /** sort on StraightLine descending */
  StraightlineDesc = '_STRAIGHTLINE_DESC_',
  /** Sort on WarrantyDuration ascending */
  WarrantydurationAsc = '_WARRANTYDURATION_ASC_',
  /** sort on WarrantyDuration descending */
  WarrantydurationDesc = '_WARRANTYDURATION_DESC_',
  /** Sort on DecliningBalance ascending */
  DecliningbalanceAsc = '_DECLININGBALANCE_ASC_',
  /** sort on DecliningBalance descending */
  DecliningbalanceDesc = '_DECLININGBALANCE_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on TaxDepreciationMethod ascending */
  TaxdepreciationmethodAsc = '_TAXDEPRECIATIONMETHOD_ASC_',
  /** sort on TaxDepreciationMethod descending */
  TaxdepreciationmethodDesc = '_TAXDEPRECIATIONMETHOD_DESC_',
  /** Sort on TaxDepreciationMethodOPTION ascending */
  TaxdepreciationmethodoptionAsc = '_TAXDEPRECIATIONMETHODOPTION_ASC_',
  /** sort on TaxDepreciationMethodOPTION descending */
  TaxdepreciationmethodoptionDesc = '_TAXDEPRECIATIONMETHODOPTION_DESC_',
  /** Sort on TaxFAPostingGroup ascending */
  TaxfapostinggroupAsc = '_TAXFAPOSTINGGROUP_ASC_',
  /** sort on TaxFAPostingGroup descending */
  TaxfapostinggroupDesc = '_TAXFAPOSTINGGROUP_DESC_',
  /** Sort on TaxNoofDepreciationYears ascending */
  TaxnoofdepreciationyearsAsc = '_TAXNOOFDEPRECIATIONYEARS_ASC_',
  /** sort on TaxNoofDepreciationYears descending */
  TaxnoofdepreciationyearsDesc = '_TAXNOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on TaxNoofDepreciationMonths ascending */
  TaxnoofdepreciationmonthsAsc = '_TAXNOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on TaxNoofDepreciationMonths descending */
  TaxnoofdepreciationmonthsDesc = '_TAXNOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on TaxStraightLine ascending */
  TaxstraightlineAsc = '_TAXSTRAIGHTLINE_ASC_',
  /** sort on TaxStraightLine descending */
  TaxstraightlineDesc = '_TAXSTRAIGHTLINE_DESC_',
  /** Sort on TaxDecliningBalance ascending */
  TaxdecliningbalanceAsc = '_TAXDECLININGBALANCE_ASC_',
  /** sort on TaxDecliningBalance descending */
  TaxdecliningbalanceDesc = '_TAXDECLININGBALANCE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on DateFilter2 ascending */
  Datefilter2Asc = '_DATEFILTER2_ASC_',
  /** sort on DateFilter2 descending */
  Datefilter2Desc = '_DATEFILTER2_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on InvoicedDays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on InvoicedDays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ImgUrlSmall ascending */
  ImgurlsmallAsc = '_IMGURLSMALL_ASC_',
  /** sort on ImgUrlSmall descending */
  ImgurlsmallDesc = '_IMGURLSMALL_DESC_',
  /** Sort on ImgUrlLarge ascending */
  ImgurllargeAsc = '_IMGURLLARGE_ASC_',
  /** sort on ImgUrlLarge descending */
  ImgurllargeDesc = '_IMGURLLARGE_DESC_',
  /** Sort on ObjectGroupFamily ascending */
  ObjectgroupfamilyAsc = '_OBJECTGROUPFAMILY_ASC_',
  /** sort on ObjectGroupFamily descending */
  ObjectgroupfamilyDesc = '_OBJECTGROUPFAMILY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectInput {
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on RentalCustomerNo ascending */
  RentalcustomernoAsc = '_RENTALCUSTOMERNO_ASC_',
  /** sort on RentalCustomerNo descending */
  RentalcustomernoDesc = '_RENTALCUSTOMERNO_DESC_',
  /** Sort on SalesCustomerNo ascending */
  SalescustomernoAsc = '_SALESCUSTOMERNO_ASC_',
  /** sort on SalesCustomerNo descending */
  SalescustomernoDesc = '_SALESCUSTOMERNO_DESC_',
  /** Sort on FixedAssetNo ascending */
  FixedassetnoAsc = '_FIXEDASSETNO_ASC_',
  /** sort on FixedAssetNo descending */
  FixedassetnoDesc = '_FIXEDASSETNO_DESC_',
  /** Sort on CurrentLocationCode ascending */
  CurrentlocationcodeAsc = '_CURRENTLOCATIONCODE_ASC_',
  /** sort on CurrentLocationCode descending */
  CurrentlocationcodeDesc = '_CURRENTLOCATIONCODE_DESC_',
  /** Sort on CreateDate ascending */
  CreatedateAsc = '_CREATEDATE_ASC_',
  /** sort on CreateDate descending */
  CreatedateDesc = '_CREATEDATE_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on EndDate ascending */
  EnddateAsc = '_ENDDATE_ASC_',
  /** sort on EndDate descending */
  EnddateDesc = '_ENDDATE_DESC_',
  /** Sort on StatusInventory ascending */
  StatusinventoryAsc = '_STATUSINVENTORY_ASC_',
  /** sort on StatusInventory descending */
  StatusinventoryDesc = '_STATUSINVENTORY_DESC_',
  /** Sort on ServiceCustomerNo ascending */
  ServicecustomernoAsc = '_SERVICECUSTOMERNO_ASC_',
  /** sort on ServiceCustomerNo descending */
  ServicecustomernoDesc = '_SERVICECUSTOMERNO_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpWriteUp ascending */
  GenprodpostinggrpwriteupAsc = '_GENPRODPOSTINGGRPWRITEUP_ASC_',
  /** sort on GenProdPostingGrpWriteUp descending */
  GenprodpostinggrpwriteupDesc = '_GENPRODPOSTINGGRPWRITEUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpIntRent ascending */
  GenprodpostinggrpintrentAsc = '_GENPRODPOSTINGGRPINTRENT_ASC_',
  /** sort on GenProdPostingGrpIntRent descending */
  GenprodpostinggrpintrentDesc = '_GENPRODPOSTINGGRPINTRENT_DESC_',
  /** Sort on GenProdPostingGrpExtRent ascending */
  GenprodpostinggrpextrentAsc = '_GENPRODPOSTINGGRPEXTRENT_ASC_',
  /** sort on GenProdPostingGrpExtRent descending */
  GenprodpostinggrpextrentDesc = '_GENPRODPOSTINGGRPEXTRENT_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on PostedReceiptNo ascending */
  PostedreceiptnoAsc = '_POSTEDRECEIPTNO_ASC_',
  /** sort on PostedReceiptNo descending */
  PostedreceiptnoDesc = '_POSTEDRECEIPTNO_DESC_',
  /** Sort on PostedInvoiceNo ascending */
  PostedinvoicenoAsc = '_POSTEDINVOICENO_ASC_',
  /** sort on PostedInvoiceNo descending */
  PostedinvoicenoDesc = '_POSTEDINVOICENO_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on CostingMethod ascending */
  CostingmethodAsc = '_COSTINGMETHOD_ASC_',
  /** sort on CostingMethod descending */
  CostingmethodDesc = '_COSTINGMETHOD_DESC_',
  /** Sort on StandardCost ascending */
  StandardcostAsc = '_STANDARDCOST_ASC_',
  /** sort on StandardCost descending */
  StandardcostDesc = '_STANDARDCOST_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on IndirectCost ascending */
  IndirectcostAsc = '_INDIRECTCOST_ASC_',
  /** sort on IndirectCost descending */
  IndirectcostDesc = '_INDIRECTCOST_DESC_',
  /** Sort on LastDirectCost ascending */
  LastdirectcostAsc = '_LASTDIRECTCOST_ASC_',
  /** sort on LastDirectCost descending */
  LastdirectcostDesc = '_LASTDIRECTCOST_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceProfitCalculation ascending */
  PriceprofitcalculationAsc = '_PRICEPROFITCALCULATION_ASC_',
  /** sort on PriceProfitCalculation descending */
  PriceprofitcalculationDesc = '_PRICEPROFITCALCULATION_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on PriceIncludesVAT ascending */
  PriceincludesvatAsc = '_PRICEINCLUDESVAT_ASC_',
  /** sort on PriceIncludesVAT descending */
  PriceincludesvatDesc = '_PRICEINCLUDESVAT_DESC_',
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on RentalStatus ascending */
  RentalstatusAsc = '_RENTALSTATUS_ASC_',
  /** sort on RentalStatus descending */
  RentalstatusDesc = '_RENTALSTATUS_DESC_',
  /** Sort on BlockedforRental ascending */
  BlockedforrentalAsc = '_BLOCKEDFORRENTAL_ASC_',
  /** sort on BlockedforRental descending */
  BlockedforrentalDesc = '_BLOCKEDFORRENTAL_DESC_',
  /** Sort on ServiceItemGroup ascending */
  ServiceitemgroupAsc = '_SERVICEITEMGROUP_ASC_',
  /** sort on ServiceItemGroup descending */
  ServiceitemgroupDesc = '_SERVICEITEMGROUP_DESC_',
  /** Sort on ServiceItemNo ascending */
  ServiceitemnoAsc = '_SERVICEITEMNO_ASC_',
  /** sort on ServiceItemNo descending */
  ServiceitemnoDesc = '_SERVICEITEMNO_DESC_',
  /** Sort on DefaultContractValue ascending */
  DefaultcontractvalueAsc = '_DEFAULTCONTRACTVALUE_ASC_',
  /** sort on DefaultContractValue descending */
  DefaultcontractvalueDesc = '_DEFAULTCONTRACTVALUE_DESC_',
  /** Sort on DefaultContractDiscount ascending */
  DefaultcontractdiscountAsc = '_DEFAULTCONTRACTDISCOUNT_ASC_',
  /** sort on DefaultContractDiscount descending */
  DefaultcontractdiscountDesc = '_DEFAULTCONTRACTDISCOUNT_DESC_',
  /** Sort on OwningLocationCode ascending */
  OwninglocationcodeAsc = '_OWNINGLOCATIONCODE_ASC_',
  /** sort on OwningLocationCode descending */
  OwninglocationcodeDesc = '_OWNINGLOCATIONCODE_DESC_',
  /** Sort on GenProdPostingGrpHire ascending */
  GenprodpostinggrphireAsc = '_GENPRODPOSTINGGRPHIRE_ASC_',
  /** sort on GenProdPostingGrpHire descending */
  GenprodpostinggrphireDesc = '_GENPRODPOSTINGGRPHIRE_DESC_',
  /** Sort on GenProdPostingGrpLeasing ascending */
  GenprodpostinggrpleasingAsc = '_GENPRODPOSTINGGRPLEASING_ASC_',
  /** sort on GenProdPostingGrpLeasing descending */
  GenprodpostinggrpleasingDesc = '_GENPRODPOSTINGGRPLEASING_DESC_',
  /** Sort on RentalSplitPostingGroup ascending */
  RentalsplitpostinggroupAsc = '_RENTALSPLITPOSTINGGROUP_ASC_',
  /** sort on RentalSplitPostingGroup descending */
  RentalsplitpostinggroupDesc = '_RENTALSPLITPOSTINGGROUP_DESC_',
  /** Sort on SoldFor ascending */
  SoldforAsc = '_SOLDFOR_ASC_',
  /** sort on SoldFor descending */
  SoldforDesc = '_SOLDFOR_DESC_',
  /** Sort on SoldDate ascending */
  SolddateAsc = '_SOLDDATE_ASC_',
  /** sort on SoldDate descending */
  SolddateDesc = '_SOLDDATE_DESC_',
  /** Sort on UseFixedAsset ascending */
  UsefixedassetAsc = '_USEFIXEDASSET_ASC_',
  /** sort on UseFixedAsset descending */
  UsefixedassetDesc = '_USEFIXEDASSET_DESC_',
  /** Sort on CounterType ascending */
  CountertypeAsc = '_COUNTERTYPE_ASC_',
  /** sort on CounterType descending */
  CountertypeDesc = '_COUNTERTYPE_DESC_',
  /** Sort on Counter ascending */
  CounterAsc = '_COUNTER_ASC_',
  /** sort on Counter descending */
  CounterDesc = '_COUNTER_DESC_',
  /** Sort on FirstRented ascending */
  FirstrentedAsc = '_FIRSTRENTED_ASC_',
  /** sort on FirstRented descending */
  FirstrentedDesc = '_FIRSTRENTED_DESC_',
  /** Sort on LastRented ascending */
  LastrentedAsc = '_LASTRENTED_ASC_',
  /** sort on LastRented descending */
  LastrentedDesc = '_LASTRENTED_DESC_',
  /** Sort on WarrantyDiscParts ascending */
  WarrantydiscpartsAsc = '_WARRANTYDISCPARTS_ASC_',
  /** sort on WarrantyDiscParts descending */
  WarrantydiscpartsDesc = '_WARRANTYDISCPARTS_DESC_',
  /** Sort on WarrantyDiscLabor ascending */
  WarrantydisclaborAsc = '_WARRANTYDISCLABOR_ASC_',
  /** sort on WarrantyDiscLabor descending */
  WarrantydisclaborDesc = '_WARRANTYDISCLABOR_DESC_',
  /** Sort on DefaultWarrantyDuration ascending */
  DefaultwarrantydurationAsc = '_DEFAULTWARRANTYDURATION_ASC_',
  /** sort on DefaultWarrantyDuration descending */
  DefaultwarrantydurationDesc = '_DEFAULTWARRANTYDURATION_DESC_',
  /** Sort on ResponseTimeHours ascending */
  ResponsetimehoursAsc = '_RESPONSETIMEHOURS_ASC_',
  /** sort on ResponseTimeHours descending */
  ResponsetimehoursDesc = '_RESPONSETIMEHOURS_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on InPurchaseQuote ascending */
  InpurchasequoteAsc = '_INPURCHASEQUOTE_ASC_',
  /** sort on InPurchaseQuote descending */
  InpurchasequoteDesc = '_INPURCHASEQUOTE_DESC_',
  /** Sort on InPurchaseOrder ascending */
  InpurchaseorderAsc = '_INPURCHASEORDER_ASC_',
  /** sort on InPurchaseOrder descending */
  InpurchaseorderDesc = '_INPURCHASEORDER_DESC_',
  /** Sort on PurchaseInvoiced ascending */
  PurchaseinvoicedAsc = '_PURCHASEINVOICED_ASC_',
  /** sort on PurchaseInvoiced descending */
  PurchaseinvoicedDesc = '_PURCHASEINVOICED_DESC_',
  /** Sort on PurchaseReceived ascending */
  PurchasereceivedAsc = '_PURCHASERECEIVED_ASC_',
  /** sort on PurchaseReceived descending */
  PurchasereceivedDesc = '_PURCHASERECEIVED_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on VATBusPostingGrPrice ascending */
  VatbuspostinggrpriceAsc = '_VATBUSPOSTINGGRPRICE_ASC_',
  /** sort on VATBusPostingGrPrice descending */
  VatbuspostinggrpriceDesc = '_VATBUSPOSTINGGRPRICE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on CreatedbyUser ascending */
  CreatedbyuserAsc = '_CREATEDBYUSER_ASC_',
  /** sort on CreatedbyUser descending */
  CreatedbyuserDesc = '_CREATEDBYUSER_DESC_',
  /** Sort on BlockedforSale ascending */
  BlockedforsaleAsc = '_BLOCKEDFORSALE_ASC_',
  /** sort on BlockedforSale descending */
  BlockedforsaleDesc = '_BLOCKEDFORSALE_DESC_',
  /** Sort on BlockedforPurchase ascending */
  BlockedforpurchaseAsc = '_BLOCKEDFORPURCHASE_ASC_',
  /** sort on BlockedforPurchase descending */
  BlockedforpurchaseDesc = '_BLOCKEDFORPURCHASE_DESC_',
  /** Sort on BlockedforReservation ascending */
  BlockedforreservationAsc = '_BLOCKEDFORRESERVATION_ASC_',
  /** sort on BlockedforReservation descending */
  BlockedforreservationDesc = '_BLOCKEDFORRESERVATION_DESC_',
  /** Sort on OwnershipStatus ascending */
  OwnershipstatusAsc = '_OWNERSHIPSTATUS_ASC_',
  /** sort on OwnershipStatus descending */
  OwnershipstatusDesc = '_OWNERSHIPSTATUS_DESC_',
  /** Sort on InclinInvValueReport ascending */
  InclininvvaluereportAsc = '_INCLININVVALUEREPORT_ASC_',
  /** sort on InclinInvValueReport descending */
  InclininvvaluereportDesc = '_INCLININVVALUEREPORT_DESC_',
  /** Sort on StdCostAmount ascending */
  StdcostamountAsc = '_STDCOSTAMOUNT_ASC_',
  /** sort on StdCostAmount descending */
  StdcostamountDesc = '_STDCOSTAMOUNT_DESC_',
  /** Sort on PurchasedFor ascending */
  PurchasedforAsc = '_PURCHASEDFOR_ASC_',
  /** sort on PurchasedFor descending */
  PurchasedforDesc = '_PURCHASEDFOR_DESC_',
  /** Sort on ObtainedDiscount ascending */
  ObtaineddiscountAsc = '_OBTAINEDDISCOUNT_ASC_',
  /** sort on ObtainedDiscount descending */
  ObtaineddiscountDesc = '_OBTAINEDDISCOUNT_DESC_',
  /** Sort on NetCostAmountAdditional ascending */
  NetcostamountadditionalAsc = '_NETCOSTAMOUNTADDITIONAL_ASC_',
  /** sort on NetCostAmountAdditional descending */
  NetcostamountadditionalDesc = '_NETCOSTAMOUNTADDITIONAL_DESC_',
  /** Sort on DiscountGiven ascending */
  DiscountgivenAsc = '_DISCOUNTGIVEN_ASC_',
  /** sort on DiscountGiven descending */
  DiscountgivenDesc = '_DISCOUNTGIVEN_DESC_',
  /** Sort on ReplacementPrice ascending */
  ReplacementpriceAsc = '_REPLACEMENTPRICE_ASC_',
  /** sort on ReplacementPrice descending */
  ReplacementpriceDesc = '_REPLACEMENTPRICE_DESC_',
  /** Sort on LinkedtoObjectNo ascending */
  LinkedtoobjectnoAsc = '_LINKEDTOOBJECTNO_ASC_',
  /** sort on LinkedtoObjectNo descending */
  LinkedtoobjectnoDesc = '_LINKEDTOOBJECTNO_DESC_',
  /** Sort on Capacity ascending */
  CapacityAsc = '_CAPACITY_ASC_',
  /** sort on Capacity descending */
  CapacityDesc = '_CAPACITY_DESC_',
  /** Sort on Effect ascending */
  EffectAsc = '_EFFECT_ASC_',
  /** sort on Effect descending */
  EffectDesc = '_EFFECT_DESC_',
  /** Sort on CylinderVolume ascending */
  CylindervolumeAsc = '_CYLINDERVOLUME_ASC_',
  /** sort on CylinderVolume descending */
  CylindervolumeDesc = '_CYLINDERVOLUME_DESC_',
  /** Sort on AutomaticExtendedTexts ascending */
  AutomaticextendedtextsAsc = '_AUTOMATICEXTENDEDTEXTS_ASC_',
  /** sort on AutomaticExtendedTexts descending */
  AutomaticextendedtextsDesc = '_AUTOMATICEXTENDEDTEXTS_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on CapacityMeasure ascending */
  CapacitymeasureAsc = '_CAPACITYMEASURE_ASC_',
  /** sort on CapacityMeasure descending */
  CapacitymeasureDesc = '_CAPACITYMEASURE_DESC_',
  /** Sort on EffectMeasure ascending */
  EffectmeasureAsc = '_EFFECTMEASURE_ASC_',
  /** sort on EffectMeasure descending */
  EffectmeasureDesc = '_EFFECTMEASURE_DESC_',
  /** Sort on CylinderMeasure ascending */
  CylindermeasureAsc = '_CYLINDERMEASURE_ASC_',
  /** sort on CylinderMeasure descending */
  CylindermeasureDesc = '_CYLINDERMEASURE_DESC_',
  /** Sort on EngineNo ascending */
  EnginenoAsc = '_ENGINENO_ASC_',
  /** sort on EngineNo descending */
  EnginenoDesc = '_ENGINENO_DESC_',
  /** Sort on MobilePhoneNo ascending */
  MobilephonenoAsc = '_MOBILEPHONENO_ASC_',
  /** sort on MobilePhoneNo descending */
  MobilephonenoDesc = '_MOBILEPHONENO_DESC_',
  /** Sort on RadioCode ascending */
  RadiocodeAsc = '_RADIOCODE_ASC_',
  /** sort on RadioCode descending */
  RadiocodeDesc = '_RADIOCODE_DESC_',
  /** Sort on ChassisNo ascending */
  ChassisnoAsc = '_CHASSISNO_ASC_',
  /** sort on ChassisNo descending */
  ChassisnoDesc = '_CHASSISNO_DESC_',
  /** Sort on ConstructionYear ascending */
  ConstructionyearAsc = '_CONSTRUCTIONYEAR_ASC_',
  /** sort on ConstructionYear descending */
  ConstructionyearDesc = '_CONSTRUCTIONYEAR_DESC_',
  /** Sort on RegistrationNo ascending */
  RegistrationnoAsc = '_REGISTRATIONNO_ASC_',
  /** sort on RegistrationNo descending */
  RegistrationnoDesc = '_REGISTRATIONNO_DESC_',
  /** Sort on DateofPermit ascending */
  DateofpermitAsc = '_DATEOFPERMIT_ASC_',
  /** sort on DateofPermit descending */
  DateofpermitDesc = '_DATEOFPERMIT_DESC_',
  /** Sort on InSalesOrder ascending */
  InsalesorderAsc = '_INSALESORDER_ASC_',
  /** sort on InSalesOrder descending */
  InsalesorderDesc = '_INSALESORDER_DESC_',
  /** Sort on InSalesQuote ascending */
  InsalesquoteAsc = '_INSALESQUOTE_ASC_',
  /** sort on InSalesQuote descending */
  InsalesquoteDesc = '_INSALESQUOTE_DESC_',
  /** Sort on InSalesInvoice ascending */
  InsalesinvoiceAsc = '_INSALESINVOICE_ASC_',
  /** sort on InSalesInvoice descending */
  InsalesinvoiceDesc = '_INSALESINVOICE_DESC_',
  /** Sort on SoldonInvoiceNo ascending */
  SoldoninvoicenoAsc = '_SOLDONINVOICENO_ASC_',
  /** sort on SoldonInvoiceNo descending */
  SoldoninvoicenoDesc = '_SOLDONINVOICENO_DESC_',
  /** Sort on ExpAvailableAfterEndDate ascending */
  ExpavailableafterenddateAsc = '_EXPAVAILABLEAFTERENDDATE_ASC_',
  /** sort on ExpAvailableAfterEndDate descending */
  ExpavailableafterenddateDesc = '_EXPAVAILABLEAFTERENDDATE_DESC_',
  /** Sort on CreditonEarlyReturn ascending */
  CreditonearlyreturnAsc = '_CREDITONEARLYRETURN_ASC_',
  /** sort on CreditonEarlyReturn descending */
  CreditonearlyreturnDesc = '_CREDITONEARLYRETURN_DESC_',
  /** Sort on DefaultMaintenance ascending */
  DefaultmaintenanceAsc = '_DEFAULTMAINTENANCE_ASC_',
  /** sort on DefaultMaintenance descending */
  DefaultmaintenanceDesc = '_DEFAULTMAINTENANCE_DESC_',
  /** Sort on InDelivery ascending */
  IndeliveryAsc = '_INDELIVERY_ASC_',
  /** sort on InDelivery descending */
  IndeliveryDesc = '_INDELIVERY_DESC_',
  /** Sort on AdditionalObjectCost ascending */
  AdditionalobjectcostAsc = '_ADDITIONALOBJECTCOST_ASC_',
  /** sort on AdditionalObjectCost descending */
  AdditionalobjectcostDesc = '_ADDITIONALOBJECTCOST_DESC_',
  /** Sort on AdditionalObjectCostDisc ascending */
  AdditionalobjectcostdiscAsc = '_ADDITIONALOBJECTCOSTDISC_ASC_',
  /** sort on AdditionalObjectCostDisc descending */
  AdditionalobjectcostdiscDesc = '_ADDITIONALOBJECTCOSTDISC_DESC_',
  /** Sort on AdditionalObjectSalesPrice ascending */
  AdditionalobjectsalespriceAsc = '_ADDITIONALOBJECTSALESPRICE_ASC_',
  /** sort on AdditionalObjectSalesPrice descending */
  AdditionalobjectsalespriceDesc = '_ADDITIONALOBJECTSALESPRICE_DESC_',
  /** Sort on Length ascending */
  LengthAsc = '_LENGTH_ASC_',
  /** sort on Length descending */
  LengthDesc = '_LENGTH_DESC_',
  /** Sort on LengthMeasure ascending */
  LengthmeasureAsc = '_LENGTHMEASURE_ASC_',
  /** sort on LengthMeasure descending */
  LengthmeasureDesc = '_LENGTHMEASURE_DESC_',
  /** Sort on Width ascending */
  WidthAsc = '_WIDTH_ASC_',
  /** sort on Width descending */
  WidthDesc = '_WIDTH_DESC_',
  /** Sort on WidthMeasure ascending */
  WidthmeasureAsc = '_WIDTHMEASURE_ASC_',
  /** sort on WidthMeasure descending */
  WidthmeasureDesc = '_WIDTHMEASURE_DESC_',
  /** Sort on Height ascending */
  HeightAsc = '_HEIGHT_ASC_',
  /** sort on Height descending */
  HeightDesc = '_HEIGHT_DESC_',
  /** Sort on HeightMeasure ascending */
  HeightmeasureAsc = '_HEIGHTMEASURE_ASC_',
  /** sort on HeightMeasure descending */
  HeightmeasureDesc = '_HEIGHTMEASURE_DESC_',
  /** Sort on Weight ascending */
  WeightAsc = '_WEIGHT_ASC_',
  /** sort on Weight descending */
  WeightDesc = '_WEIGHT_DESC_',
  /** Sort on WeightMeasure ascending */
  WeightmeasureAsc = '_WEIGHTMEASURE_ASC_',
  /** sort on WeightMeasure descending */
  WeightmeasureDesc = '_WEIGHTMEASURE_DESC_',
  /** Sort on InSalesCreditMemo ascending */
  InsalescreditmemoAsc = '_INSALESCREDITMEMO_ASC_',
  /** sort on InSalesCreditMemo descending */
  InsalescreditmemoDesc = '_INSALESCREDITMEMO_DESC_',
  /** Sort on StdRentalChargeDays ascending */
  StdrentalchargedaysAsc = '_STDRENTALCHARGEDAYS_ASC_',
  /** sort on StdRentalChargeDays descending */
  StdrentalchargedaysDesc = '_STDRENTALCHARGEDAYS_DESC_',
  /** Sort on DebitonReturnDay ascending */
  DebitonreturndayAsc = '_DEBITONRETURNDAY_ASC_',
  /** sort on DebitonReturnDay descending */
  DebitonreturndayDesc = '_DEBITONRETURNDAY_DESC_',
  /** Sort on PriceTerm ascending */
  PricetermAsc = '_PRICETERM_ASC_',
  /** sort on PriceTerm descending */
  PricetermDesc = '_PRICETERM_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on PolicyNo ascending */
  PolicynoAsc = '_POLICYNO_ASC_',
  /** sort on PolicyNo descending */
  PolicynoDesc = '_POLICYNO_DESC_',
  /** Sort on LeasingContractNo ascending */
  LeasingcontractnoAsc = '_LEASINGCONTRACTNO_ASC_',
  /** sort on LeasingContractNo descending */
  LeasingcontractnoDesc = '_LEASINGCONTRACTNO_DESC_',
  /** Sort on BaseCalendar ascending */
  BasecalendarAsc = '_BASECALENDAR_ASC_',
  /** sort on BaseCalendar descending */
  BasecalendarDesc = '_BASECALENDAR_DESC_',
  /** Sort on NewOwnershipstatus ascending */
  NewownershipstatusAsc = '_NEWOWNERSHIPSTATUS_ASC_',
  /** sort on NewOwnershipstatus descending */
  NewownershipstatusDesc = '_NEWOWNERSHIPSTATUS_DESC_',
  /** Sort on NewStartDate ascending */
  NewstartdateAsc = '_NEWSTARTDATE_ASC_',
  /** sort on NewStartDate descending */
  NewstartdateDesc = '_NEWSTARTDATE_DESC_',
  /** Sort on CreateFixedAsset ascending */
  CreatefixedassetAsc = '_CREATEFIXEDASSET_ASC_',
  /** sort on CreateFixedAsset descending */
  CreatefixedassetDesc = '_CREATEFIXEDASSET_DESC_',
  /** Sort on MaintenanceDescription ascending */
  MaintenancedescriptionAsc = '_MAINTENANCEDESCRIPTION_ASC_',
  /** sort on MaintenanceDescription descending */
  MaintenancedescriptionDesc = '_MAINTENANCEDESCRIPTION_DESC_',
  /** Sort on Installment ascending */
  InstallmentAsc = '_INSTALLMENT_ASC_',
  /** sort on Installment descending */
  InstallmentDesc = '_INSTALLMENT_DESC_',
  /** Sort on InstallmentDescription ascending */
  InstallmentdescriptionAsc = '_INSTALLMENTDESCRIPTION_ASC_',
  /** sort on InstallmentDescription descending */
  InstallmentdescriptionDesc = '_INSTALLMENTDESCRIPTION_DESC_',
  /** Sort on ProductCode ascending */
  ProductcodeAsc = '_PRODUCTCODE_ASC_',
  /** sort on ProductCode descending */
  ProductcodeDesc = '_PRODUCTCODE_DESC_',
  /** Sort on BonusTypeCode ascending */
  BonustypecodeAsc = '_BONUSTYPECODE_ASC_',
  /** sort on BonusTypeCode descending */
  BonustypecodeDesc = '_BONUSTYPECODE_DESC_',
  /** Sort on FireInspection ascending */
  FireinspectionAsc = '_FIREINSPECTION_ASC_',
  /** sort on FireInspection descending */
  FireinspectionDesc = '_FIREINSPECTION_DESC_',
  /** Sort on RecurringInspection ascending */
  RecurringinspectionAsc = '_RECURRINGINSPECTION_ASC_',
  /** sort on RecurringInspection descending */
  RecurringinspectionDesc = '_RECURRINGINSPECTION_DESC_',
  /** Sort on RecurringInspection2 ascending */
  Recurringinspection2Asc = '_RECURRINGINSPECTION2_ASC_',
  /** sort on RecurringInspection2 descending */
  Recurringinspection2Desc = '_RECURRINGINSPECTION2_DESC_',
  /** Sort on InternalInspection ascending */
  InternalinspectionAsc = '_INTERNALINSPECTION_ASC_',
  /** sort on InternalInspection descending */
  InternalinspectionDesc = '_INTERNALINSPECTION_DESC_',
  /** Sort on FireInspectionNext ascending */
  FireinspectionnextAsc = '_FIREINSPECTIONNEXT_ASC_',
  /** sort on FireInspectionNext descending */
  FireinspectionnextDesc = '_FIREINSPECTIONNEXT_DESC_',
  /** Sort on ExcludeVacation ascending */
  ExcludevacationAsc = '_EXCLUDEVACATION_ASC_',
  /** sort on ExcludeVacation descending */
  ExcludevacationDesc = '_EXCLUDEVACATION_DESC_',
  /** Sort on RecurringInspectionNext ascending */
  RecurringinspectionnextAsc = '_RECURRINGINSPECTIONNEXT_ASC_',
  /** sort on RecurringInspectionNext descending */
  RecurringinspectionnextDesc = '_RECURRINGINSPECTIONNEXT_DESC_',
  /** Sort on RecurringInspection2Next ascending */
  Recurringinspection2NextAsc = '_RECURRINGINSPECTION2NEXT_ASC_',
  /** sort on RecurringInspection2Next descending */
  Recurringinspection2NextDesc = '_RECURRINGINSPECTION2NEXT_DESC_',
  /** Sort on InternalInspectionNext ascending */
  InternalinspectionnextAsc = '_INTERNALINSPECTIONNEXT_ASC_',
  /** sort on InternalInspectionNext descending */
  InternalinspectionnextDesc = '_INTERNALINSPECTIONNEXT_DESC_',
  /** Sort on InspectionPeriodold ascending */
  InspectionperiodoldAsc = '_INSPECTIONPERIODOLD_ASC_',
  /** sort on InspectionPeriodold descending */
  InspectionperiodoldDesc = '_INSPECTIONPERIODOLD_DESC_',
  /** Sort on InspectionPeriod ascending */
  InspectionperiodAsc = '_INSPECTIONPERIOD_ASC_',
  /** sort on InspectionPeriod descending */
  InspectionperiodDesc = '_INSPECTIONPERIOD_DESC_',
  /** Sort on InspectionDate ascending */
  InspectiondateAsc = '_INSPECTIONDATE_ASC_',
  /** sort on InspectionDate descending */
  InspectiondateDesc = '_INSPECTIONDATE_DESC_',
  /** Sort on NextInspectionDate ascending */
  NextinspectiondateAsc = '_NEXTINSPECTIONDATE_ASC_',
  /** sort on NextInspectionDate descending */
  NextinspectiondateDesc = '_NEXTINSPECTIONDATE_DESC_',
  /** Sort on InspectionSerieNo ascending */
  InspectionserienoAsc = '_INSPECTIONSERIENO_ASC_',
  /** sort on InspectionSerieNo descending */
  InspectionserienoDesc = '_INSPECTIONSERIENO_DESC_',
  /** Sort on RentalSplit ascending */
  RentalsplitAsc = '_RENTALSPLIT_ASC_',
  /** sort on RentalSplit descending */
  RentalsplitDesc = '_RENTALSPLIT_DESC_',
  /** Sort on OwnerStatus ascending */
  OwnerstatusAsc = '_OWNERSTATUS_ASC_',
  /** sort on OwnerStatus descending */
  OwnerstatusDesc = '_OWNERSTATUS_DESC_',
  /** Sort on CEDocument ascending */
  CedocumentAsc = '_CEDOCUMENT_ASC_',
  /** sort on CEDocument descending */
  CedocumentDesc = '_CEDOCUMENT_DESC_',
  /** Sort on EngineCertification ascending */
  EnginecertificationAsc = '_ENGINECERTIFICATION_ASC_',
  /** sort on EngineCertification descending */
  EnginecertificationDesc = '_ENGINECERTIFICATION_DESC_',
  /** Sort on LastInventoriedDate ascending */
  LastinventorieddateAsc = '_LASTINVENTORIEDDATE_ASC_',
  /** sort on LastInventoriedDate descending */
  LastinventorieddateDesc = '_LASTINVENTORIEDDATE_DESC_',
  /** Sort on InventoryInfo ascending */
  InventoryinfoAsc = '_INVENTORYINFO_ASC_',
  /** sort on InventoryInfo descending */
  InventoryinfoDesc = '_INVENTORYINFO_DESC_',
  /** Sort on FinanceDate ascending */
  FinancedateAsc = '_FINANCEDATE_ASC_',
  /** sort on FinanceDate descending */
  FinancedateDesc = '_FINANCEDATE_DESC_',
  /** Sort on AmortizationRate ascending */
  AmortizationrateAsc = '_AMORTIZATIONRATE_ASC_',
  /** sort on AmortizationRate descending */
  AmortizationrateDesc = '_AMORTIZATIONRATE_DESC_',
  /** Sort on ContractNumber ascending */
  ContractnumberAsc = '_CONTRACTNUMBER_ASC_',
  /** sort on ContractNumber descending */
  ContractnumberDesc = '_CONTRACTNUMBER_DESC_',
  /** Sort on PurschaseValue ascending */
  PurschasevalueAsc = '_PURSCHASEVALUE_ASC_',
  /** sort on PurschaseValue descending */
  PurschasevalueDesc = '_PURSCHASEVALUE_DESC_',
  /** Sort on ContractInfo ascending */
  ContractinfoAsc = '_CONTRACTINFO_ASC_',
  /** sort on ContractInfo descending */
  ContractinfoDesc = '_CONTRACTINFO_DESC_',
  /** Sort on ServiceAgreement ascending */
  ServiceagreementAsc = '_SERVICEAGREEMENT_ASC_',
  /** sort on ServiceAgreement descending */
  ServiceagreementDesc = '_SERVICEAGREEMENT_DESC_',
  /** Sort on Finance ascending */
  FinanceAsc = '_FINANCE_ASC_',
  /** sort on Finance descending */
  FinanceDesc = '_FINANCE_DESC_',
  /** Sort on RentalAmount ascending */
  RentalamountAsc = '_RENTALAMOUNT_ASC_',
  /** sort on RentalAmount descending */
  RentalamountDesc = '_RENTALAMOUNT_DESC_',
  /** Sort on StatusInventoryBeforeLost ascending */
  StatusinventorybeforelostAsc = '_STATUSINVENTORYBEFORELOST_ASC_',
  /** sort on StatusInventoryBeforeLost descending */
  StatusinventorybeforelostDesc = '_STATUSINVENTORYBEFORELOST_DESC_',
  /** Sort on Bitmap1 ascending */
  Bitmap1Asc = '_BITMAP1_ASC_',
  /** sort on Bitmap1 descending */
  Bitmap1Desc = '_BITMAP1_DESC_',
  /** Sort on Bitmap2 ascending */
  Bitmap2Asc = '_BITMAP2_ASC_',
  /** sort on Bitmap2 descending */
  Bitmap2Desc = '_BITMAP2_DESC_',
  /** Sort on Bitmap3 ascending */
  Bitmap3Asc = '_BITMAP3_ASC_',
  /** sort on Bitmap3 descending */
  Bitmap3Desc = '_BITMAP3_DESC_',
  /** Sort on Bitmap4 ascending */
  Bitmap4Asc = '_BITMAP4_ASC_',
  /** sort on Bitmap4 descending */
  Bitmap4Desc = '_BITMAP4_DESC_',
  /** Sort on Bitmap5 ascending */
  Bitmap5Asc = '_BITMAP5_ASC_',
  /** sort on Bitmap5 descending */
  Bitmap5Desc = '_BITMAP5_DESC_',
  /** Sort on Bitmap6 ascending */
  Bitmap6Asc = '_BITMAP6_ASC_',
  /** sort on Bitmap6 descending */
  Bitmap6Desc = '_BITMAP6_DESC_',
  /** Sort on Bitmap7 ascending */
  Bitmap7Asc = '_BITMAP7_ASC_',
  /** sort on Bitmap7 descending */
  Bitmap7Desc = '_BITMAP7_DESC_',
  /** Sort on Bitmap8 ascending */
  Bitmap8Asc = '_BITMAP8_ASC_',
  /** sort on Bitmap8 descending */
  Bitmap8Desc = '_BITMAP8_DESC_',
  /** Sort on Bitmap9 ascending */
  Bitmap9Asc = '_BITMAP9_ASC_',
  /** sort on Bitmap9 descending */
  Bitmap9Desc = '_BITMAP9_DESC_',
  /** Sort on Bitmap10 ascending */
  Bitmap10Asc = '_BITMAP10_ASC_',
  /** sort on Bitmap10 descending */
  Bitmap10Desc = '_BITMAP10_DESC_',
  /** Sort on Bitmap11 ascending */
  Bitmap11Asc = '_BITMAP11_ASC_',
  /** sort on Bitmap11 descending */
  Bitmap11Desc = '_BITMAP11_DESC_',
  /** Sort on Bitmap12 ascending */
  Bitmap12Asc = '_BITMAP12_ASC_',
  /** sort on Bitmap12 descending */
  Bitmap12Desc = '_BITMAP12_DESC_',
  /** Sort on Bitmap13 ascending */
  Bitmap13Asc = '_BITMAP13_ASC_',
  /** sort on Bitmap13 descending */
  Bitmap13Desc = '_BITMAP13_DESC_',
  /** Sort on Bitmap14 ascending */
  Bitmap14Asc = '_BITMAP14_ASC_',
  /** sort on Bitmap14 descending */
  Bitmap14Desc = '_BITMAP14_DESC_',
  /** Sort on Bitmap15 ascending */
  Bitmap15Asc = '_BITMAP15_ASC_',
  /** sort on Bitmap15 descending */
  Bitmap15Desc = '_BITMAP15_DESC_',
  /** Sort on Bitmap16 ascending */
  Bitmap16Asc = '_BITMAP16_ASC_',
  /** sort on Bitmap16 descending */
  Bitmap16Desc = '_BITMAP16_DESC_',
  /** Sort on Bitmap17 ascending */
  Bitmap17Asc = '_BITMAP17_ASC_',
  /** sort on Bitmap17 descending */
  Bitmap17Desc = '_BITMAP17_DESC_',
  /** Sort on Bitmap18 ascending */
  Bitmap18Asc = '_BITMAP18_ASC_',
  /** sort on Bitmap18 descending */
  Bitmap18Desc = '_BITMAP18_DESC_',
  /** Sort on Bitmap19 ascending */
  Bitmap19Asc = '_BITMAP19_ASC_',
  /** sort on Bitmap19 descending */
  Bitmap19Desc = '_BITMAP19_DESC_',
  /** Sort on Bitmap20 ascending */
  Bitmap20Asc = '_BITMAP20_ASC_',
  /** sort on Bitmap20 descending */
  Bitmap20Desc = '_BITMAP20_DESC_',
  /** Sort on Bitmap21 ascending */
  Bitmap21Asc = '_BITMAP21_ASC_',
  /** sort on Bitmap21 descending */
  Bitmap21Desc = '_BITMAP21_DESC_',
  /** Sort on Bitmap22 ascending */
  Bitmap22Asc = '_BITMAP22_ASC_',
  /** sort on Bitmap22 descending */
  Bitmap22Desc = '_BITMAP22_DESC_',
  /** Sort on Bitmap23 ascending */
  Bitmap23Asc = '_BITMAP23_ASC_',
  /** sort on Bitmap23 descending */
  Bitmap23Desc = '_BITMAP23_DESC_',
  /** Sort on Bitmap24 ascending */
  Bitmap24Asc = '_BITMAP24_ASC_',
  /** sort on Bitmap24 descending */
  Bitmap24Desc = '_BITMAP24_DESC_',
  /** Sort on Bitmap25 ascending */
  Bitmap25Asc = '_BITMAP25_ASC_',
  /** sort on Bitmap25 descending */
  Bitmap25Desc = '_BITMAP25_DESC_',
  /** Sort on Bitmap26 ascending */
  Bitmap26Asc = '_BITMAP26_ASC_',
  /** sort on Bitmap26 descending */
  Bitmap26Desc = '_BITMAP26_DESC_',
  /** Sort on Bitmap27 ascending */
  Bitmap27Asc = '_BITMAP27_ASC_',
  /** sort on Bitmap27 descending */
  Bitmap27Desc = '_BITMAP27_DESC_',
  /** Sort on Bitmap28 ascending */
  Bitmap28Asc = '_BITMAP28_ASC_',
  /** sort on Bitmap28 descending */
  Bitmap28Desc = '_BITMAP28_DESC_',
  /** Sort on Bitmap29 ascending */
  Bitmap29Asc = '_BITMAP29_ASC_',
  /** sort on Bitmap29 descending */
  Bitmap29Desc = '_BITMAP29_DESC_',
  /** Sort on Bitmap30 ascending */
  Bitmap30Asc = '_BITMAP30_ASC_',
  /** sort on Bitmap30 descending */
  Bitmap30Desc = '_BITMAP30_DESC_',
  /** Sort on Bitmap31 ascending */
  Bitmap31Asc = '_BITMAP31_ASC_',
  /** sort on Bitmap31 descending */
  Bitmap31Desc = '_BITMAP31_DESC_',
  /** Sort on Bitmap32 ascending */
  Bitmap32Asc = '_BITMAP32_ASC_',
  /** sort on Bitmap32 descending */
  Bitmap32Desc = '_BITMAP32_DESC_',
  /** Sort on ObjectNoConcern ascending */
  ObjectnoconcernAsc = '_OBJECTNOCONCERN_ASC_',
  /** sort on ObjectNoConcern descending */
  ObjectnoconcernDesc = '_OBJECTNOCONCERN_DESC_',
  /** Sort on PreviousInventoryStatus ascending */
  PreviousinventorystatusAsc = '_PREVIOUSINVENTORYSTATUS_ASC_',
  /** sort on PreviousInventoryStatus descending */
  PreviousinventorystatusDesc = '_PREVIOUSINVENTORYSTATUS_DESC_',
  /** Sort on QuantityHoursFullRentalDay ascending */
  QuantityhoursfullrentaldayAsc = '_QUANTITYHOURSFULLRENTALDAY_ASC_',
  /** sort on QuantityHoursFullRentalDay descending */
  QuantityhoursfullrentaldayDesc = '_QUANTITYHOURSFULLRENTALDAY_DESC_',
  /** Sort on DepositAmount ascending */
  DepositamountAsc = '_DEPOSITAMOUNT_ASC_',
  /** sort on DepositAmount descending */
  DepositamountDesc = '_DEPOSITAMOUNT_DESC_',
  /** Sort on HireSplit ascending */
  HiresplitAsc = '_HIRESPLIT_ASC_',
  /** sort on HireSplit descending */
  HiresplitDesc = '_HIRESPLIT_DESC_',
  /** Sort on WorkOrderCustomerNo ascending */
  WorkordercustomernoAsc = '_WORKORDERCUSTOMERNO_ASC_',
  /** sort on WorkOrderCustomerNo descending */
  WorkordercustomernoDesc = '_WORKORDERCUSTOMERNO_DESC_',
  /** Sort on Description3 ascending */
  Description3Asc = '_DESCRIPTION3_ASC_',
  /** sort on Description3 descending */
  Description3Desc = '_DESCRIPTION3_DESC_',
  /** Sort on Description4 ascending */
  Description4Asc = '_DESCRIPTION4_ASC_',
  /** sort on Description4 descending */
  Description4Desc = '_DESCRIPTION4_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on OldObjectNo ascending */
  OldobjectnoAsc = '_OLDOBJECTNO_ASC_',
  /** sort on OldObjectNo descending */
  OldobjectnoDesc = '_OLDOBJECTNO_DESC_',
  /** Sort on ChangedbyUserID ascending */
  ChangedbyuseridAsc = '_CHANGEDBYUSERID_ASC_',
  /** sort on ChangedbyUserID descending */
  ChangedbyuseridDesc = '_CHANGEDBYUSERID_DESC_',
  /** Sort on ChangedDate ascending */
  ChangeddateAsc = '_CHANGEDDATE_ASC_',
  /** sort on ChangedDate descending */
  ChangeddateDesc = '_CHANGEDDATE_DESC_',
  /** Sort on PriceScheduleCode ascending */
  PriceschedulecodeAsc = '_PRICESCHEDULECODE_ASC_',
  /** sort on PriceScheduleCode descending */
  PriceschedulecodeDesc = '_PRICESCHEDULECODE_DESC_',
  /** Sort on MandatoryCounteronReturn ascending */
  MandatorycounteronreturnAsc = '_MANDATORYCOUNTERONRETURN_ASC_',
  /** sort on MandatoryCounteronReturn descending */
  MandatorycounteronreturnDesc = '_MANDATORYCOUNTERONRETURN_DESC_',
  /** Sort on RegStatusRental ascending */
  RegstatusrentalAsc = '_REGSTATUSRENTAL_ASC_',
  /** sort on RegStatusRental descending */
  RegstatusrentalDesc = '_REGSTATUSRENTAL_DESC_',
  /** Sort on WarrantyDuration ascending */
  WarrantydurationAsc = '_WARRANTYDURATION_ASC_',
  /** sort on WarrantyDuration descending */
  WarrantydurationDesc = '_WARRANTYDURATION_DESC_',
  /** Sort on WarrantyDate ascending */
  WarrantydateAsc = '_WARRANTYDATE_ASC_',
  /** sort on WarrantyDate descending */
  WarrantydateDesc = '_WARRANTYDATE_DESC_',
  /** Sort on TrackingCode ascending */
  TrackingcodeAsc = '_TRACKINGCODE_ASC_',
  /** sort on TrackingCode descending */
  TrackingcodeDesc = '_TRACKINGCODE_DESC_',
  /** Sort on VATProdPostingGrpRental ascending */
  VatprodpostinggrprentalAsc = '_VATPRODPOSTINGGRPRENTAL_ASC_',
  /** sort on VATProdPostingGrpRental descending */
  VatprodpostinggrprentalDesc = '_VATPRODPOSTINGGRPRENTAL_DESC_',
  /** Sort on ServiceStatus ascending */
  ServicestatusAsc = '_SERVICESTATUS_ASC_',
  /** sort on ServiceStatus descending */
  ServicestatusDesc = '_SERVICESTATUS_DESC_',
  /** Sort on LastServiceDate ascending */
  LastservicedateAsc = '_LASTSERVICEDATE_ASC_',
  /** sort on LastServiceDate descending */
  LastservicedateDesc = '_LASTSERVICEDATE_DESC_',
  /** Sort on LastInspectionDate ascending */
  LastinspectiondateAsc = '_LASTINSPECTIONDATE_ASC_',
  /** sort on LastInspectionDate descending */
  LastinspectiondateDesc = '_LASTINSPECTIONDATE_DESC_',
  /** Sort on LastInspDate ascending */
  LastinspdateAsc = '_LASTINSPDATE_ASC_',
  /** sort on LastInspDate descending */
  LastinspdateDesc = '_LASTINSPDATE_DESC_',
  /** Sort on TerminateFixedAsset ascending */
  TerminatefixedassetAsc = '_TERMINATEFIXEDASSET_ASC_',
  /** sort on TerminateFixedAsset descending */
  TerminatefixedassetDesc = '_TERMINATEFIXEDASSET_DESC_',
  /** Sort on CounterReporting ascending */
  CounterreportingAsc = '_COUNTERREPORTING_ASC_',
  /** sort on CounterReporting descending */
  CounterreportingDesc = '_COUNTERREPORTING_DESC_',
  /** Sort on EstimatedDailyHours ascending */
  EstimateddailyhoursAsc = '_ESTIMATEDDAILYHOURS_ASC_',
  /** sort on EstimatedDailyHours descending */
  EstimateddailyhoursDesc = '_ESTIMATEDDAILYHOURS_DESC_',
  /** Sort on ManufacturerModelCode ascending */
  ManufacturermodelcodeAsc = '_MANUFACTURERMODELCODE_ASC_',
  /** sort on ManufacturerModelCode descending */
  ManufacturermodelcodeDesc = '_MANUFACTURERMODELCODE_DESC_',
  /** Sort on EstimatedCounterperDay ascending */
  EstimatedcounterperdayAsc = '_ESTIMATEDCOUNTERPERDAY_ASC_',
  /** sort on EstimatedCounterperDay descending */
  EstimatedcounterperdayDesc = '_ESTIMATEDCOUNTERPERDAY_DESC_',
  /** Sort on CounterType2 ascending */
  Countertype2Asc = '_COUNTERTYPE2_ASC_',
  /** sort on CounterType2 descending */
  Countertype2Desc = '_COUNTERTYPE2_DESC_',
  /** Sort on Counter2 ascending */
  Counter2Asc = '_COUNTER2_ASC_',
  /** sort on Counter2 descending */
  Counter2Desc = '_COUNTER2_DESC_',
  /** Sort on EstimatedCounter2perDay ascending */
  Estimatedcounter2PerdayAsc = '_ESTIMATEDCOUNTER2PERDAY_ASC_',
  /** sort on EstimatedCounter2perDay descending */
  Estimatedcounter2PerdayDesc = '_ESTIMATEDCOUNTER2PERDAY_DESC_',
  /** Sort on MandatoryCounter2onReturn ascending */
  Mandatorycounter2OnreturnAsc = '_MANDATORYCOUNTER2ONRETURN_ASC_',
  /** sort on MandatoryCounter2onReturn descending */
  Mandatorycounter2OnreturnDesc = '_MANDATORYCOUNTER2ONRETURN_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on DateFilter2 ascending */
  Datefilter2Asc = '_DATEFILTER2_ASC_',
  /** sort on DateFilter2 descending */
  Datefilter2Desc = '_DATEFILTER2_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on TimeFilter ascending */
  TimefilterAsc = '_TIMEFILTER_ASC_',
  /** sort on TimeFilter descending */
  TimefilterDesc = '_TIMEFILTER_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on Inventory ascending */
  InventoryAsc = '_INVENTORY_ASC_',
  /** sort on Inventory descending */
  InventoryDesc = '_INVENTORY_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CurrentActiveContractNo ascending */
  CurrentactivecontractnoAsc = '_CURRENTACTIVECONTRACTNO_ASC_',
  /** sort on CurrentActiveContractNo descending */
  CurrentactivecontractnoDesc = '_CURRENTACTIVECONTRACTNO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on CommentObjectGroup ascending */
  CommentobjectgroupAsc = '_COMMENTOBJECTGROUP_ASC_',
  /** sort on CommentObjectGroup descending */
  CommentobjectgroupDesc = '_COMMENTOBJECTGROUP_DESC_',
  /** Sort on CommentObjectType ascending */
  CommentobjecttypeAsc = '_COMMENTOBJECTTYPE_ASC_',
  /** sort on CommentObjectType descending */
  CommentobjecttypeDesc = '_COMMENTOBJECTTYPE_DESC_',
  /** Sort on CommentsinContract ascending */
  CommentsincontractAsc = '_COMMENTSINCONTRACT_ASC_',
  /** sort on CommentsinContract descending */
  CommentsincontractDesc = '_COMMENTSINCONTRACT_DESC_',
  /** Sort on InventoryRental ascending */
  InventoryrentalAsc = '_INVENTORYRENTAL_ASC_',
  /** sort on InventoryRental descending */
  InventoryrentalDesc = '_INVENTORYRENTAL_DESC_',
  /** Sort on QtyonPurchOrder ascending */
  QtyonpurchorderAsc = '_QTYONPURCHORDER_ASC_',
  /** sort on QtyonPurchOrder descending */
  QtyonpurchorderDesc = '_QTYONPURCHORDER_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowComponentCost ascending */
  FlowcomponentcostAsc = '_FLOWCOMPONENTCOST_ASC_',
  /** sort on FlowComponentCost descending */
  FlowcomponentcostDesc = '_FLOWCOMPONENTCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowPurchaseAddCost ascending */
  FlowpurchaseaddcostAsc = '_FLOWPURCHASEADDCOST_ASC_',
  /** sort on FlowPurchaseAddCost descending */
  FlowpurchaseaddcostDesc = '_FLOWPURCHASEADDCOST_DESC_',
  /** Sort on FlowTotalPurchaseCost ascending */
  FlowtotalpurchasecostAsc = '_FLOWTOTALPURCHASECOST_ASC_',
  /** sort on FlowTotalPurchaseCost descending */
  FlowtotalpurchasecostDesc = '_FLOWTOTALPURCHASECOST_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on Invoiceddays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on Invoiceddays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on FlowLinkedSumCost ascending */
  FlowlinkedsumcostAsc = '_FLOWLINKEDSUMCOST_ASC_',
  /** sort on FlowLinkedSumCost descending */
  FlowlinkedsumcostDesc = '_FLOWLINKEDSUMCOST_DESC_',
  /** Sort on FlowLinkedSalesDiscount ascending */
  FlowlinkedsalesdiscountAsc = '_FLOWLINKEDSALESDISCOUNT_ASC_',
  /** sort on FlowLinkedSalesDiscount descending */
  FlowlinkedsalesdiscountDesc = '_FLOWLINKEDSALESDISCOUNT_DESC_',
  /** Sort on FlowLinkedOtherCost ascending */
  FlowlinkedothercostAsc = '_FLOWLINKEDOTHERCOST_ASC_',
  /** sort on FlowLinkedOtherCost descending */
  FlowlinkedothercostDesc = '_FLOWLINKEDOTHERCOST_DESC_',
  /** Sort on FlowLinkedLeasingCost ascending */
  FlowlinkedleasingcostAsc = '_FLOWLINKEDLEASINGCOST_ASC_',
  /** sort on FlowLinkedLeasingCost descending */
  FlowlinkedleasingcostDesc = '_FLOWLINKEDLEASINGCOST_DESC_',
  /** Sort on FlowLinkedRentalIncome ascending */
  FlowlinkedrentalincomeAsc = '_FLOWLINKEDRENTALINCOME_ASC_',
  /** sort on FlowLinkedRentalIncome descending */
  FlowlinkedrentalincomeDesc = '_FLOWLINKEDRENTALINCOME_DESC_',
  /** Sort on FlowLinkedRentalDiscount ascending */
  FlowlinkedrentaldiscountAsc = '_FLOWLINKEDRENTALDISCOUNT_ASC_',
  /** sort on FlowLinkedRentalDiscount descending */
  FlowlinkedrentaldiscountDesc = '_FLOWLINKEDRENTALDISCOUNT_DESC_',
  /** Sort on FlowLinkedPurchaseCharge ascending */
  FlowlinkedpurchasechargeAsc = '_FLOWLINKEDPURCHASECHARGE_ASC_',
  /** sort on FlowLinkedPurchaseCharge descending */
  FlowlinkedpurchasechargeDesc = '_FLOWLINKEDPURCHASECHARGE_DESC_',
  /** Sort on FlowLinkedServiceCost ascending */
  FlowlinkedservicecostAsc = '_FLOWLINKEDSERVICECOST_ASC_',
  /** sort on FlowLinkedServiceCost descending */
  FlowlinkedservicecostDesc = '_FLOWLINKEDSERVICECOST_DESC_',
  /** Sort on FlowLinkedGuaranteeCost ascending */
  FlowlinkedguaranteecostAsc = '_FLOWLINKEDGUARANTEECOST_ASC_',
  /** sort on FlowLinkedGuaranteeCost descending */
  FlowlinkedguaranteecostDesc = '_FLOWLINKEDGUARANTEECOST_DESC_',
  /** Sort on FlowLinkedIntRentalCost ascending */
  FlowlinkedintrentalcostAsc = '_FLOWLINKEDINTRENTALCOST_ASC_',
  /** sort on FlowLinkedIntRentalCost descending */
  FlowlinkedintrentalcostDesc = '_FLOWLINKEDINTRENTALCOST_DESC_',
  /** Sort on FlowLinkedHireCost ascending */
  FlowlinkedhirecostAsc = '_FLOWLINKEDHIRECOST_ASC_',
  /** sort on FlowLinkedHireCost descending */
  FlowlinkedhirecostDesc = '_FLOWLINKEDHIRECOST_DESC_',
  /** Sort on FlowLinkedHireDiscount ascending */
  FlowlinkedhirediscountAsc = '_FLOWLINKEDHIREDISCOUNT_ASC_',
  /** sort on FlowLinkedHireDiscount descending */
  FlowlinkedhirediscountDesc = '_FLOWLINKEDHIREDISCOUNT_DESC_',
  /** Sort on FlowLinkedServiceAgrCost ascending */
  FlowlinkedserviceagrcostAsc = '_FLOWLINKEDSERVICEAGRCOST_ASC_',
  /** sort on FlowLinkedServiceAgrCost descending */
  FlowlinkedserviceagrcostDesc = '_FLOWLINKEDSERVICEAGRCOST_DESC_',
  /** Sort on FlowLinkedDepreciation ascending */
  FlowlinkeddepreciationAsc = '_FLOWLINKEDDEPRECIATION_ASC_',
  /** sort on FlowLinkedDepreciation descending */
  FlowlinkeddepreciationDesc = '_FLOWLINKEDDEPRECIATION_DESC_',
  /** Sort on FlowLinkedUppreciation ascending */
  FlowlinkeduppreciationAsc = '_FLOWLINKEDUPPRECIATION_ASC_',
  /** sort on FlowLinkedUppreciation descending */
  FlowlinkeduppreciationDesc = '_FLOWLINKEDUPPRECIATION_DESC_',
  /** Sort on FlowLinkedDownpreciation ascending */
  FlowlinkeddownpreciationAsc = '_FLOWLINKEDDOWNPRECIATION_ASC_',
  /** sort on FlowLinkedDownpreciation descending */
  FlowlinkeddownpreciationDesc = '_FLOWLINKEDDOWNPRECIATION_DESC_',
  /** Sort on FlowLinkedPurchaseDiscount ascending */
  FlowlinkedpurchasediscountAsc = '_FLOWLINKEDPURCHASEDISCOUNT_ASC_',
  /** sort on FlowLinkedPurchaseDiscount descending */
  FlowlinkedpurchasediscountDesc = '_FLOWLINKEDPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowLinkedSalesCharge ascending */
  FlowlinkedsaleschargeAsc = '_FLOWLINKEDSALESCHARGE_ASC_',
  /** sort on FlowLinkedSalesCharge descending */
  FlowlinkedsaleschargeDesc = '_FLOWLINKEDSALESCHARGE_DESC_',
  /** Sort on FlowLinkedServiceIncome ascending */
  FlowlinkedserviceincomeAsc = '_FLOWLINKEDSERVICEINCOME_ASC_',
  /** sort on FlowLinkedServiceIncome descending */
  FlowlinkedserviceincomeDesc = '_FLOWLINKEDSERVICEINCOME_DESC_',
  /** Sort on FlowLinkedSrvAgrIncome ascending */
  FlowlinkedsrvagrincomeAsc = '_FLOWLINKEDSRVAGRINCOME_ASC_',
  /** sort on FlowLinkedSrvAgrIncome descending */
  FlowlinkedsrvagrincomeDesc = '_FLOWLINKEDSRVAGRINCOME_DESC_',
  /** Sort on FlowLinkedIntRentalIncome ascending */
  FlowlinkedintrentalincomeAsc = '_FLOWLINKEDINTRENTALINCOME_ASC_',
  /** sort on FlowLinkedIntRentalIncome descending */
  FlowlinkedintrentalincomeDesc = '_FLOWLINKEDINTRENTALINCOME_DESC_',
  /** Sort on FlowLinkedValueChange ascending */
  FlowlinkedvaluechangeAsc = '_FLOWLINKEDVALUECHANGE_ASC_',
  /** sort on FlowLinkedValueChange descending */
  FlowlinkedvaluechangeDesc = '_FLOWLINKEDVALUECHANGE_DESC_',
  /** Sort on FlowLinkedRentalDays ascending */
  FlowlinkedrentaldaysAsc = '_FLOWLINKEDRENTALDAYS_ASC_',
  /** sort on FlowLinkedRentalDays descending */
  FlowlinkedrentaldaysDesc = '_FLOWLINKEDRENTALDAYS_DESC_',
  /** Sort on FlowLinkedRentalHours ascending */
  FlowlinkedrentalhoursAsc = '_FLOWLINKEDRENTALHOURS_ASC_',
  /** sort on FlowLinkedRentalHours descending */
  FlowlinkedrentalhoursDesc = '_FLOWLINKEDRENTALHOURS_DESC_',
  /** Sort on FlowLinkedInvoicedDays ascending */
  FlowlinkedinvoiceddaysAsc = '_FLOWLINKEDINVOICEDDAYS_ASC_',
  /** sort on FlowLinkedInvoicedDays descending */
  FlowlinkedinvoiceddaysDesc = '_FLOWLINKEDINVOICEDDAYS_DESC_',
  /** Sort on FlowLinkedGainbySale ascending */
  FlowlinkedgainbysaleAsc = '_FLOWLINKEDGAINBYSALE_ASC_',
  /** sort on FlowLinkedGainbySale descending */
  FlowlinkedgainbysaleDesc = '_FLOWLINKEDGAINBYSALE_DESC_',
  /** Sort on FlowLinkedLossbySale ascending */
  FlowlinkedlossbysaleAsc = '_FLOWLINKEDLOSSBYSALE_ASC_',
  /** sort on FlowLinkedLossbySale descending */
  FlowlinkedlossbysaleDesc = '_FLOWLINKEDLOSSBYSALE_DESC_',
  /** Sort on FlowLinkedOtherIncome ascending */
  FlowlinkedotherincomeAsc = '_FLOWLINKEDOTHERINCOME_ASC_',
  /** sort on FlowLinkedOtherIncome descending */
  FlowlinkedotherincomeDesc = '_FLOWLINKEDOTHERINCOME_DESC_',
  /** Sort on PostedWorkOrderFlow ascending */
  PostedworkorderflowAsc = '_POSTEDWORKORDERFLOW_ASC_',
  /** sort on PostedWorkOrderFlow descending */
  PostedworkorderflowDesc = '_POSTEDWORKORDERFLOW_DESC_',
  /** Sort on PostedWorkPurchaseFlow ascending */
  PostedworkpurchaseflowAsc = '_POSTEDWORKPURCHASEFLOW_ASC_',
  /** sort on PostedWorkPurchaseFlow descending */
  PostedworkpurchaseflowDesc = '_POSTEDWORKPURCHASEFLOW_DESC_',
  /** Sort on PostedWorkOrderLineFlow ascending */
  PostedworkorderlineflowAsc = '_POSTEDWORKORDERLINEFLOW_ASC_',
  /** sort on PostedWorkOrderLineFlow descending */
  PostedworkorderlineflowDesc = '_POSTEDWORKORDERLINEFLOW_DESC_',
  /** Sort on PostedWorkPurchLineFlow ascending */
  PostedworkpurchlineflowAsc = '_POSTEDWORKPURCHLINEFLOW_ASC_',
  /** sort on PostedWorkPurchLineFlow descending */
  PostedworkpurchlineflowDesc = '_POSTEDWORKPURCHLINEFLOW_DESC_',
  /** Sort on PostedWorkshopEntriesFlow ascending */
  PostedworkshopentriesflowAsc = '_POSTEDWORKSHOPENTRIESFLOW_ASC_',
  /** sort on PostedWorkshopEntriesFlow descending */
  PostedworkshopentriesflowDesc = '_POSTEDWORKSHOPENTRIESFLOW_DESC_',
  /** Sort on LastPriceAgreement ascending */
  LastpriceagreementAsc = '_LASTPRICEAGREEMENT_ASC_',
  /** sort on LastPriceAgreement descending */
  LastpriceagreementDesc = '_LASTPRICEAGREEMENT_DESC_',
  /** Sort on PSILink ascending */
  PsilinkAsc = '_PSILINK_ASC_',
  /** sort on PSILink descending */
  PsilinkDesc = '_PSILINK_DESC_',
  /** Sort on CaravanList ascending */
  CaravanlistAsc = '_CARAVANLIST_ASC_',
  /** sort on CaravanList descending */
  CaravanlistDesc = '_CARAVANLIST_DESC_',
  /** Sort on TechnicalInfo ascending */
  TechnicalinfoAsc = '_TECHNICALINFO_ASC_',
  /** sort on TechnicalInfo descending */
  TechnicalinfoDesc = '_TECHNICALINFO_DESC_',
  /** Sort on FunctionalTests ascending */
  FunctionaltestsAsc = '_FUNCTIONALTESTS_ASC_',
  /** sort on FunctionalTests descending */
  FunctionaltestsDesc = '_FUNCTIONALTESTS_DESC_',
  /** Sort on LastRentalStatusChage ascending */
  LastrentalstatuschageAsc = '_LASTRENTALSTATUSCHAGE_ASC_',
  /** sort on LastRentalStatusChage descending */
  LastrentalstatuschageDesc = '_LASTRENTALSTATUSCHAGE_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on ObjectTypeNoConcern ascending */
  ObjecttypenoconcernAsc = '_OBJECTTYPENOCONCERN_ASC_',
  /** sort on ObjectTypeNoConcern descending */
  ObjecttypenoconcernDesc = '_OBJECTTYPENOCONCERN_DESC_',
  /** Sort on NoofRentalQuoteLines ascending */
  NoofrentalquotelinesAsc = '_NOOFRENTALQUOTELINES_ASC_',
  /** sort on NoofRentalQuoteLines descending */
  NoofrentalquotelinesDesc = '_NOOFRENTALQUOTELINES_DESC_',
  /** Sort on NoofOpenRentalLines ascending */
  NoofopenrentallinesAsc = '_NOOFOPENRENTALLINES_ASC_',
  /** sort on NoofOpenRentalLines descending */
  NoofopenrentallinesDesc = '_NOOFOPENRENTALLINES_DESC_',
  /** Sort on NoofClosedRentalLines ascending */
  NoofclosedrentallinesAsc = '_NOOFCLOSEDRENTALLINES_ASC_',
  /** sort on NoofClosedRentalLines descending */
  NoofclosedrentallinesDesc = '_NOOFCLOSEDRENTALLINES_DESC_',
  /** Sort on NoofDefaultRentalPrices ascending */
  NoofdefaultrentalpricesAsc = '_NOOFDEFAULTRENTALPRICES_ASC_',
  /** sort on NoofDefaultRentalPrices descending */
  NoofdefaultrentalpricesDesc = '_NOOFDEFAULTRENTALPRICES_DESC_',
  /** Sort on WarrantyDateFlow ascending */
  WarrantydateflowAsc = '_WARRANTYDATEFLOW_ASC_',
  /** sort on WarrantyDateFlow descending */
  WarrantydateflowDesc = '_WARRANTYDATEFLOW_DESC_',
  /** Sort on GPSCoordinates ascending */
  GpscoordinatesAsc = '_GPSCOORDINATES_ASC_',
  /** sort on GPSCoordinates descending */
  GpscoordinatesDesc = '_GPSCOORDINATES_DESC_',
  /** Sort on PSI ascending */
  PsiAsc = '_PSI_ASC_',
  /** sort on PSI descending */
  PsiDesc = '_PSI_DESC_',
  /** Sort on AdditionalObjectStatus ascending */
  AdditionalobjectstatusAsc = '_ADDITIONALOBJECTSTATUS_ASC_',
  /** sort on AdditionalObjectStatus descending */
  AdditionalobjectstatusDesc = '_ADDITIONALOBJECTSTATUS_DESC_',
  /** Sort on AdditionalObjectStatusOPTION ascending */
  AdditionalobjectstatusoptionAsc = '_ADDITIONALOBJECTSTATUSOPTION_ASC_',
  /** sort on AdditionalObjectStatusOPTION descending */
  AdditionalobjectstatusoptionDesc = '_ADDITIONALOBJECTSTATUSOPTION_DESC_',
  /** Sort on ManufactureDate ascending */
  ManufacturedateAsc = '_MANUFACTUREDATE_ASC_',
  /** sort on ManufactureDate descending */
  ManufacturedateDesc = '_MANUFACTUREDATE_DESC_',
  /** Sort on LastInspectionBy ascending */
  LastinspectionbyAsc = '_LASTINSPECTIONBY_ASC_',
  /** sort on LastInspectionBy descending */
  LastinspectionbyDesc = '_LASTINSPECTIONBY_DESC_',
  /** Sort on LastInspectionByEmail ascending */
  LastinspectionbyemailAsc = '_LASTINSPECTIONBYEMAIL_ASC_',
  /** sort on LastInspectionByEmail descending */
  LastinspectionbyemailDesc = '_LASTINSPECTIONBYEMAIL_DESC_',
  /** Sort on PurchaseDate ascending */
  PurchasedateAsc = '_PURCHASEDATE_ASC_',
  /** sort on PurchaseDate descending */
  PurchasedateDesc = '_PURCHASEDATE_DESC_',
  /** Sort on FirstUseDate ascending */
  FirstusedateAsc = '_FIRSTUSEDATE_ASC_',
  /** sort on FirstUseDate descending */
  FirstusedateDesc = '_FIRSTUSEDATE_DESC_',
  /** Sort on IsElectric ascending */
  IselectricAsc = '_ISELECTRIC_ASC_',
  /** sort on IsElectric descending */
  IselectricDesc = '_ISELECTRIC_DESC_',
  /** Sort on OffRentDateEdge ascending */
  OffrentdateedgeAsc = '_OFFRENTDATEEDGE_ASC_',
  /** sort on OffRentDateEdge descending */
  OffrentdateedgeDesc = '_OFFRENTDATEEDGE_DESC_',
  /** Sort on Broken ascending */
  BrokenAsc = '_BROKEN_ASC_',
  /** sort on Broken descending */
  BrokenDesc = '_BROKEN_DESC_',
  /** Sort on RealBridgeSoldToCustomerNo ascending */
  RealbridgesoldtocustomernoAsc = '_REALBRIDGESOLDTOCUSTOMERNO_ASC_',
  /** sort on RealBridgeSoldToCustomerNo descending */
  RealbridgesoldtocustomernoDesc = '_REALBRIDGESOLDTOCUSTOMERNO_DESC_',
  /** Sort on RealBridgeCustomerNo ascending */
  RealbridgecustomernoAsc = '_REALBRIDGECUSTOMERNO_ASC_',
  /** sort on RealBridgeCustomerNo descending */
  RealbridgecustomernoDesc = '_REALBRIDGECUSTOMERNO_DESC_',
  /** Sort on RealBridgeProjectNo ascending */
  RealbridgeprojectnoAsc = '_REALBRIDGEPROJECTNO_ASC_',
  /** sort on RealBridgeProjectNo descending */
  RealbridgeprojectnoDesc = '_REALBRIDGEPROJECTNO_DESC_',
  /** Sort on DekraLno ascending */
  DekralnoAsc = '_DEKRALNO_ASC_',
  /** sort on DekraLno descending */
  DekralnoDesc = '_DEKRALNO_DESC_',
  /** Sort on SentToDekra ascending */
  SenttodekraAsc = '_SENTTODEKRA_ASC_',
  /** sort on SentToDekra descending */
  SenttodekraDesc = '_SENTTODEKRA_DESC_',
  /** Sort on CustomerRef ascending */
  CustomerrefAsc = '_CUSTOMERREF_ASC_',
  /** sort on CustomerRef descending */
  CustomerrefDesc = '_CUSTOMERREF_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on CustomerEmail ascending */
  CustomeremailAsc = '_CUSTOMEREMAIL_ASC_',
  /** sort on CustomerEmail descending */
  CustomeremailDesc = '_CUSTOMEREMAIL_DESC_',
  /** Sort on CustomerPhone ascending */
  CustomerphoneAsc = '_CUSTOMERPHONE_ASC_',
  /** sort on CustomerPhone descending */
  CustomerphoneDesc = '_CUSTOMERPHONE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on CurrentPlacement ascending */
  CurrentplacementAsc = '_CURRENTPLACEMENT_ASC_',
  /** sort on CurrentPlacement descending */
  CurrentplacementDesc = '_CURRENTPLACEMENT_DESC_',
  /** Sort on ActualPlacement ascending */
  ActualplacementAsc = '_ACTUALPLACEMENT_ASC_',
  /** sort on ActualPlacement descending */
  ActualplacementDesc = '_ACTUALPLACEMENT_DESC_',
  /** Sort on ObjStatus ascending */
  ObjstatusAsc = '_OBJSTATUS_ASC_',
  /** sort on ObjStatus descending */
  ObjstatusDesc = '_OBJSTATUS_DESC_',
  /** Sort on InspectionMonth ascending */
  InspectionmonthAsc = '_INSPECTIONMONTH_ASC_',
  /** sort on InspectionMonth descending */
  InspectionmonthDesc = '_INSPECTIONMONTH_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectReturnHeaderInput {
  /** Sort on ReturnType ascending */
  ReturntypeAsc = '_RETURNTYPE_ASC_',
  /** sort on ReturnType descending */
  ReturntypeDesc = '_RETURNTYPE_DESC_',
  /** Sort on ReturnTypeOPTION ascending */
  ReturntypeoptionAsc = '_RETURNTYPEOPTION_ASC_',
  /** sort on ReturnTypeOPTION descending */
  ReturntypeoptionDesc = '_RETURNTYPEOPTION_DESC_',
  /** Sort on OffRentNo ascending */
  OffrentnoAsc = '_OFFRENTNO_ASC_',
  /** sort on OffRentNo descending */
  OffrentnoDesc = '_OFFRENTNO_DESC_',
  /** Sort on OffRentDate ascending */
  OffrentdateAsc = '_OFFRENTDATE_ASC_',
  /** sort on OffRentDate descending */
  OffrentdateDesc = '_OFFRENTDATE_DESC_',
  /** Sort on OffRentTime ascending */
  OffrenttimeAsc = '_OFFRENTTIME_ASC_',
  /** sort on OffRentTime descending */
  OffrenttimeDesc = '_OFFRENTTIME_DESC_',
  /** Sort on ReturnTime ascending */
  ReturntimeAsc = '_RETURNTIME_ASC_',
  /** sort on ReturnTime descending */
  ReturntimeDesc = '_RETURNTIME_DESC_',
  /** Sort on DebitonReturnDay ascending */
  DebitonreturndayAsc = '_DEBITONRETURNDAY_ASC_',
  /** sort on DebitonReturnDay descending */
  DebitonreturndayDesc = '_DEBITONRETURNDAY_DESC_',
  /** Sort on ReturnQuantity ascending */
  ReturnquantityAsc = '_RETURNQUANTITY_ASC_',
  /** sort on ReturnQuantity descending */
  ReturnquantityDesc = '_RETURNQUANTITY_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on NumberofRentFreeDays ascending */
  NumberofrentfreedaysAsc = '_NUMBEROFRENTFREEDAYS_ASC_',
  /** sort on NumberofRentFreeDays descending */
  NumberofrentfreedaysDesc = '_NUMBEROFRENTFREEDAYS_DESC_',
  /** Sort on NumberofRentFreeHours ascending */
  NumberofrentfreehoursAsc = '_NUMBEROFRENTFREEHOURS_ASC_',
  /** sort on NumberofRentFreeHours descending */
  NumberofrentfreehoursDesc = '_NUMBEROFRENTFREEHOURS_DESC_',
  /** Sort on ReasonforRentFree ascending */
  ReasonforrentfreeAsc = '_REASONFORRENTFREE_ASC_',
  /** sort on ReasonforRentFree descending */
  ReasonforrentfreeDesc = '_REASONFORRENTFREE_DESC_',
  /** Sort on CreditonEarlyReturn ascending */
  CreditonearlyreturnAsc = '_CREDITONEARLYRETURN_ASC_',
  /** sort on CreditonEarlyReturn descending */
  CreditonearlyreturnDesc = '_CREDITONEARLYRETURN_DESC_',
  /** Sort on QuantitytoSell ascending */
  QuantitytosellAsc = '_QUANTITYTOSELL_ASC_',
  /** sort on QuantitytoSell descending */
  QuantitytosellDesc = '_QUANTITYTOSELL_DESC_',
  /** Sort on QuantitytoDamage ascending */
  QuantitytodamageAsc = '_QUANTITYTODAMAGE_ASC_',
  /** sort on QuantitytoDamage descending */
  QuantitytodamageDesc = '_QUANTITYTODAMAGE_DESC_',
  /** Sort on ReceivingLocation ascending */
  ReceivinglocationAsc = '_RECEIVINGLOCATION_ASC_',
  /** sort on ReceivingLocation descending */
  ReceivinglocationDesc = '_RECEIVINGLOCATION_DESC_',
  /** Sort on CounteronDelivery ascending */
  CounterondeliveryAsc = '_COUNTERONDELIVERY_ASC_',
  /** sort on CounteronDelivery descending */
  CounterondeliveryDesc = '_COUNTERONDELIVERY_DESC_',
  /** Sort on CounteronReturn ascending */
  CounteronreturnAsc = '_COUNTERONRETURN_ASC_',
  /** sort on CounteronReturn descending */
  CounteronreturnDesc = '_COUNTERONRETURN_DESC_',
  /** Sort on Counter2onReturn ascending */
  Counter2OnreturnAsc = '_COUNTER2ONRETURN_ASC_',
  /** sort on Counter2onReturn descending */
  Counter2OnreturnDesc = '_COUNTER2ONRETURN_DESC_',
  /** Sort on Counter2onDelivery ascending */
  Counter2OndeliveryAsc = '_COUNTER2ONDELIVERY_ASC_',
  /** sort on Counter2onDelivery descending */
  Counter2OndeliveryDesc = '_COUNTER2ONDELIVERY_DESC_',
  /** Sort on QuantityHoursFullRentalDay ascending */
  QuantityhoursfullrentaldayAsc = '_QUANTITYHOURSFULLRENTALDAY_ASC_',
  /** sort on QuantityHoursFullRentalDay descending */
  QuantityhoursfullrentaldayDesc = '_QUANTITYHOURSFULLRENTALDAY_DESC_',
  /** Sort on ReturnQuantityTime ascending */
  ReturnquantitytimeAsc = '_RETURNQUANTITYTIME_ASC_',
  /** sort on ReturnQuantityTime descending */
  ReturnquantitytimeDesc = '_RETURNQUANTITYTIME_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ChargeCustomer ascending */
  ChargecustomerAsc = '_CHARGECUSTOMER_ASC_',
  /** sort on ChargeCustomer descending */
  ChargecustomerDesc = '_CHARGECUSTOMER_DESC_',
  /** Sort on CurrentPlacement ascending */
  CurrentplacementAsc = '_CURRENTPLACEMENT_ASC_',
  /** sort on CurrentPlacement descending */
  CurrentplacementDesc = '_CURRENTPLACEMENT_DESC_',
  /** Sort on EdgePost ascending */
  EdgepostAsc = '_EDGEPOST_ASC_',
  /** sort on EdgePost descending */
  EdgepostDesc = '_EDGEPOST_DESC_',
  /** Sort on OffRentUpdated ascending */
  OffrentupdatedAsc = '_OFFRENTUPDATED_ASC_',
  /** sort on OffRentUpdated descending */
  OffrentupdatedDesc = '_OFFRENTUPDATED_DESC_',
  /** Sort on EQMServiceDoc ascending */
  EqmservicedocAsc = '_EQMSERVICEDOC_ASC_',
  /** sort on EQMServiceDoc descending */
  EqmservicedocDesc = '_EQMSERVICEDOC_DESC_',
  /** Sort on ReturnNo ascending */
  ReturnnoAsc = '_RETURNNO_ASC_',
  /** sort on ReturnNo descending */
  ReturnnoDesc = '_RETURNNO_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Unitprice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on Unitprice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on ExtDocNo ascending */
  ExtdocnoAsc = '_EXTDOCNO_ASC_',
  /** sort on ExtDocNo descending */
  ExtdocnoDesc = '_EXTDOCNO_DESC_',
  /** Sort on ExtLineNo ascending */
  ExtlinenoAsc = '_EXTLINENO_ASC_',
  /** sort on ExtLineNo descending */
  ExtlinenoDesc = '_EXTLINENO_DESC_',
  /** Sort on DeletedManually ascending */
  DeletedmanuallyAsc = '_DELETEDMANUALLY_ASC_',
  /** sort on DeletedManually descending */
  DeletedmanuallyDesc = '_DELETEDMANUALLY_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on ReqRefill ascending */
  ReqrefillAsc = '_REQREFILL_ASC_',
  /** sort on ReqRefill descending */
  ReqrefillDesc = '_REQREFILL_DESC_',
  /** Sort on ReqApproved ascending */
  ReqapprovedAsc = '_REQAPPROVED_ASC_',
  /** sort on ReqApproved descending */
  ReqapprovedDesc = '_REQAPPROVED_DESC_',
  /** Sort on ApprovedbyUserID ascending */
  ApprovedbyuseridAsc = '_APPROVEDBYUSERID_ASC_',
  /** sort on ApprovedbyUserID descending */
  ApprovedbyuseridDesc = '_APPROVEDBYUSERID_DESC_',
  /** Sort on CodeType ascending */
  CodetypeAsc = '_CODETYPE_ASC_',
  /** sort on CodeType descending */
  CodetypeDesc = '_CODETYPE_DESC_',
  /** Sort on CodeTypeOPTION ascending */
  CodetypeoptionAsc = '_CODETYPEOPTION_ASC_',
  /** sort on CodeTypeOPTION descending */
  CodetypeoptionDesc = '_CODETYPEOPTION_DESC_',
  /** Sort on MotherLineRegUpdateLine ascending */
  MotherlineregupdatelineAsc = '_MOTHERLINEREGUPDATELINE_ASC_',
  /** sort on MotherLineRegUpdateLine descending */
  MotherlineregupdatelineDesc = '_MOTHERLINEREGUPDATELINE_DESC_',
  /** Sort on MotherLineType ascending */
  MotherlinetypeAsc = '_MOTHERLINETYPE_ASC_',
  /** sort on MotherLineType descending */
  MotherlinetypeDesc = '_MOTHERLINETYPE_DESC_',
  /** Sort on MotherLineTypeOPTION ascending */
  MotherlinetypeoptionAsc = '_MOTHERLINETYPEOPTION_ASC_',
  /** sort on MotherLineTypeOPTION descending */
  MotherlinetypeoptionDesc = '_MOTHERLINETYPEOPTION_DESC_',
  /** Sort on ReturnDate ascending */
  ReturndateAsc = '_RETURNDATE_ASC_',
  /** sort on ReturnDate descending */
  ReturndateDesc = '_RETURNDATE_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on Maintenance ascending */
  MaintenanceAsc = '_MAINTENANCE_ASC_',
  /** sort on Maintenance descending */
  MaintenanceDesc = '_MAINTENANCE_DESC_',
  /** Sort on SubmittedByUserName ascending */
  SubmittedbyusernameAsc = '_SUBMITTEDBYUSERNAME_ASC_',
  /** sort on SubmittedByUserName descending */
  SubmittedbyusernameDesc = '_SUBMITTEDBYUSERNAME_DESC_',
  /** Sort on SubmittedByUserEmail ascending */
  SubmittedbyuseremailAsc = '_SUBMITTEDBYUSEREMAIL_ASC_',
  /** sort on SubmittedByUserEmail descending */
  SubmittedbyuseremailDesc = '_SUBMITTEDBYUSEREMAIL_DESC_',
  /** Sort on PushNotificationSent ascending */
  PushnotificationsentAsc = '_PUSHNOTIFICATIONSENT_ASC_',
  /** sort on PushNotificationSent descending */
  PushnotificationsentDesc = '_PUSHNOTIFICATIONSENT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectReturnLineInput {
  /** Sort on ReturnNo ascending */
  ReturnnoAsc = '_RETURNNO_ASC_',
  /** sort on ReturnNo descending */
  ReturnnoDesc = '_RETURNNO_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ObjectNo ascending */
  ObjectnoAsc = '_OBJECTNO_ASC_',
  /** sort on ObjectNo descending */
  ObjectnoDesc = '_OBJECTNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Unitprice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on Unitprice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on ExtDocNo ascending */
  ExtdocnoAsc = '_EXTDOCNO_ASC_',
  /** sort on ExtDocNo descending */
  ExtdocnoDesc = '_EXTDOCNO_DESC_',
  /** Sort on ExtLineNo ascending */
  ExtlinenoAsc = '_EXTLINENO_ASC_',
  /** sort on ExtLineNo descending */
  ExtlinenoDesc = '_EXTLINENO_DESC_',
  /** Sort on DeletedManually ascending */
  DeletedmanuallyAsc = '_DELETEDMANUALLY_ASC_',
  /** sort on DeletedManually descending */
  DeletedmanuallyDesc = '_DELETEDMANUALLY_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on ReqRefill ascending */
  ReqrefillAsc = '_REQREFILL_ASC_',
  /** sort on ReqRefill descending */
  ReqrefillDesc = '_REQREFILL_DESC_',
  /** Sort on ReqApproved ascending */
  ReqapprovedAsc = '_REQAPPROVED_ASC_',
  /** sort on ReqApproved descending */
  ReqapprovedDesc = '_REQAPPROVED_DESC_',
  /** Sort on ApprovedbyUserID ascending */
  ApprovedbyuseridAsc = '_APPROVEDBYUSERID_ASC_',
  /** sort on ApprovedbyUserID descending */
  ApprovedbyuseridDesc = '_APPROVEDBYUSERID_DESC_',
  /** Sort on CodeType ascending */
  CodetypeAsc = '_CODETYPE_ASC_',
  /** sort on CodeType descending */
  CodetypeDesc = '_CODETYPE_DESC_',
  /** Sort on CodeTypeOPTION ascending */
  CodetypeoptionAsc = '_CODETYPEOPTION_ASC_',
  /** sort on CodeTypeOPTION descending */
  CodetypeoptionDesc = '_CODETYPEOPTION_DESC_',
  /** Sort on MotherLineRegUpdateLine ascending */
  MotherlineregupdatelineAsc = '_MOTHERLINEREGUPDATELINE_ASC_',
  /** sort on MotherLineRegUpdateLine descending */
  MotherlineregupdatelineDesc = '_MOTHERLINEREGUPDATELINE_DESC_',
  /** Sort on MotherLineType ascending */
  MotherlinetypeAsc = '_MOTHERLINETYPE_ASC_',
  /** sort on MotherLineType descending */
  MotherlinetypeDesc = '_MOTHERLINETYPE_DESC_',
  /** Sort on MotherLineTypeOPTION ascending */
  MotherlinetypeoptionAsc = '_MOTHERLINETYPEOPTION_ASC_',
  /** sort on MotherLineTypeOPTION descending */
  MotherlinetypeoptionDesc = '_MOTHERLINETYPEOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortObjectTypeInput {
  /** Sort on ObjectType ascending */
  ObjecttypeAsc = '_OBJECTTYPE_ASC_',
  /** sort on ObjectType descending */
  ObjecttypeDesc = '_OBJECTTYPE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ObjectGroup ascending */
  ObjectgroupAsc = '_OBJECTGROUP_ASC_',
  /** sort on ObjectGroup descending */
  ObjectgroupDesc = '_OBJECTGROUP_DESC_',
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on FAClassCode ascending */
  FaclasscodeAsc = '_FACLASSCODE_ASC_',
  /** sort on FAClassCode descending */
  FaclasscodeDesc = '_FACLASSCODE_DESC_',
  /** Sort on FASubclassCode ascending */
  FasubclasscodeAsc = '_FASUBCLASSCODE_ASC_',
  /** sort on FASubclassCode descending */
  FasubclasscodeDesc = '_FASUBCLASSCODE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepreciationMethod ascending */
  DepreciationmethodAsc = '_DEPRECIATIONMETHOD_ASC_',
  /** sort on DepreciationMethod descending */
  DepreciationmethodDesc = '_DEPRECIATIONMETHOD_DESC_',
  /** Sort on FAPostingGroup ascending */
  FapostinggroupAsc = '_FAPOSTINGGROUP_ASC_',
  /** sort on FAPostingGroup descending */
  FapostinggroupDesc = '_FAPOSTINGGROUP_DESC_',
  /** Sort on ObjectNos ascending */
  ObjectnosAsc = '_OBJECTNOS_ASC_',
  /** sort on ObjectNos descending */
  ObjectnosDesc = '_OBJECTNOS_DESC_',
  /** Sort on CostingMethod ascending */
  CostingmethodAsc = '_COSTINGMETHOD_ASC_',
  /** sort on CostingMethod descending */
  CostingmethodDesc = '_COSTINGMETHOD_DESC_',
  /** Sort on StandardCost ascending */
  StandardcostAsc = '_STANDARDCOST_ASC_',
  /** sort on StandardCost descending */
  StandardcostDesc = '_STANDARDCOST_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on IndirectCost ascending */
  IndirectcostAsc = '_INDIRECTCOST_ASC_',
  /** sort on IndirectCost descending */
  IndirectcostDesc = '_INDIRECTCOST_DESC_',
  /** Sort on LastDirectCost ascending */
  LastdirectcostAsc = '_LASTDIRECTCOST_ASC_',
  /** sort on LastDirectCost descending */
  LastdirectcostDesc = '_LASTDIRECTCOST_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceProfitCalculation ascending */
  PriceprofitcalculationAsc = '_PRICEPROFITCALCULATION_ASC_',
  /** sort on PriceProfitCalculation descending */
  PriceprofitcalculationDesc = '_PRICEPROFITCALCULATION_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on PriceIncludesVAT ascending */
  PriceincludesvatAsc = '_PRICEINCLUDESVAT_ASC_',
  /** sort on PriceIncludesVAT descending */
  PriceincludesvatDesc = '_PRICEINCLUDESVAT_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on NoofDepreciationYears ascending */
  NoofdepreciationyearsAsc = '_NOOFDEPRECIATIONYEARS_ASC_',
  /** sort on NoofDepreciationYears descending */
  NoofdepreciationyearsDesc = '_NOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on NoofDepreciationMonths ascending */
  NoofdepreciationmonthsAsc = '_NOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on NoofDepreciationMonths descending */
  NoofdepreciationmonthsDesc = '_NOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on CounterType ascending */
  CountertypeAsc = '_COUNTERTYPE_ASC_',
  /** sort on CounterType descending */
  CountertypeDesc = '_COUNTERTYPE_DESC_',
  /** Sort on WarrantyDiscParts ascending */
  WarrantydiscpartsAsc = '_WARRANTYDISCPARTS_ASC_',
  /** sort on WarrantyDiscParts descending */
  WarrantydiscpartsDesc = '_WARRANTYDISCPARTS_DESC_',
  /** Sort on WarrantyDiscLabor ascending */
  WarrantydisclaborAsc = '_WARRANTYDISCLABOR_ASC_',
  /** sort on WarrantyDiscLabor descending */
  WarrantydisclaborDesc = '_WARRANTYDISCLABOR_DESC_',
  /** Sort on DefaultWarrantyDuration ascending */
  DefaultwarrantydurationAsc = '_DEFAULTWARRANTYDURATION_ASC_',
  /** sort on DefaultWarrantyDuration descending */
  DefaultwarrantydurationDesc = '_DEFAULTWARRANTYDURATION_DESC_',
  /** Sort on ServiceItemGroup ascending */
  ServiceitemgroupAsc = '_SERVICEITEMGROUP_ASC_',
  /** sort on ServiceItemGroup descending */
  ServiceitemgroupDesc = '_SERVICEITEMGROUP_DESC_',
  /** Sort on ResponseTimeHours ascending */
  ResponsetimehoursAsc = '_RESPONSETIMEHOURS_ASC_',
  /** sort on ResponseTimeHours descending */
  ResponsetimehoursDesc = '_RESPONSETIMEHOURS_DESC_',
  /** Sort on InventoryPostingGroup ascending */
  InventorypostinggroupAsc = '_INVENTORYPOSTINGGROUP_ASC_',
  /** sort on InventoryPostingGroup descending */
  InventorypostinggroupDesc = '_INVENTORYPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpWriteUp ascending */
  GenprodpostinggrpwriteupAsc = '_GENPRODPOSTINGGRPWRITEUP_ASC_',
  /** sort on GenProdPostingGrpWriteUp descending */
  GenprodpostinggrpwriteupDesc = '_GENPRODPOSTINGGRPWRITEUP_DESC_',
  /** Sort on SaleDiscGroup ascending */
  SalediscgroupAsc = '_SALEDISCGROUP_ASC_',
  /** sort on SaleDiscGroup descending */
  SalediscgroupDesc = '_SALEDISCGROUP_DESC_',
  /** Sort on RentalDiscGroup ascending */
  RentaldiscgroupAsc = '_RENTALDISCGROUP_ASC_',
  /** sort on RentalDiscGroup descending */
  RentaldiscgroupDesc = '_RENTALDISCGROUP_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on LastTimeModified ascending */
  LasttimemodifiedAsc = '_LASTTIMEMODIFIED_ASC_',
  /** sort on LastTimeModified descending */
  LasttimemodifiedDesc = '_LASTTIMEMODIFIED_DESC_',
  /** Sort on VATBusPostingGrPrice ascending */
  VatbuspostinggrpriceAsc = '_VATBUSPOSTINGGRPRICE_ASC_',
  /** sort on VATBusPostingGrPrice descending */
  VatbuspostinggrpriceDesc = '_VATBUSPOSTINGGRPRICE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on GenProdPostingGrpHire ascending */
  GenprodpostinggrphireAsc = '_GENPRODPOSTINGGRPHIRE_ASC_',
  /** sort on GenProdPostingGrpHire descending */
  GenprodpostinggrphireDesc = '_GENPRODPOSTINGGRPHIRE_DESC_',
  /** Sort on GenProdPostingGrpLeasing ascending */
  GenprodpostinggrpleasingAsc = '_GENPRODPOSTINGGRPLEASING_ASC_',
  /** sort on GenProdPostingGrpLeasing descending */
  GenprodpostinggrpleasingDesc = '_GENPRODPOSTINGGRPLEASING_DESC_',
  /** Sort on RentalSplitPostingGroup ascending */
  RentalsplitpostinggroupAsc = '_RENTALSPLITPOSTINGGROUP_ASC_',
  /** sort on RentalSplitPostingGroup descending */
  RentalsplitpostinggroupDesc = '_RENTALSPLITPOSTINGGROUP_DESC_',
  /** Sort on SalesPostingGroup ascending */
  SalespostinggroupAsc = '_SALESPOSTINGGROUP_ASC_',
  /** sort on SalesPostingGroup descending */
  SalespostinggroupDesc = '_SALESPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGrpExtRent ascending */
  GenprodpostinggrpextrentAsc = '_GENPRODPOSTINGGRPEXTRENT_ASC_',
  /** sort on GenProdPostingGrpExtRent descending */
  GenprodpostinggrpextrentDesc = '_GENPRODPOSTINGGRPEXTRENT_DESC_',
  /** Sort on GenProdPostingGrpIntRent ascending */
  GenprodpostinggrpintrentAsc = '_GENPRODPOSTINGGRPINTRENT_ASC_',
  /** sort on GenProdPostingGrpIntRent descending */
  GenprodpostinggrpintrentDesc = '_GENPRODPOSTINGGRPINTRENT_DESC_',
  /** Sort on ReplacementPrice ascending */
  ReplacementpriceAsc = '_REPLACEMENTPRICE_ASC_',
  /** sort on ReplacementPrice descending */
  ReplacementpriceDesc = '_REPLACEMENTPRICE_DESC_',
  /** Sort on Capacity ascending */
  CapacityAsc = '_CAPACITY_ASC_',
  /** sort on Capacity descending */
  CapacityDesc = '_CAPACITY_DESC_',
  /** Sort on Effect ascending */
  EffectAsc = '_EFFECT_ASC_',
  /** sort on Effect descending */
  EffectDesc = '_EFFECT_DESC_',
  /** Sort on CylinderVolume ascending */
  CylindervolumeAsc = '_CYLINDERVOLUME_ASC_',
  /** sort on CylinderVolume descending */
  CylindervolumeDesc = '_CYLINDERVOLUME_DESC_',
  /** Sort on AutomaticExtendedTexts ascending */
  AutomaticextendedtextsAsc = '_AUTOMATICEXTENDEDTEXTS_ASC_',
  /** sort on AutomaticExtendedTexts descending */
  AutomaticextendedtextsDesc = '_AUTOMATICEXTENDEDTEXTS_DESC_',
  /** Sort on Note ascending */
  NoteAsc = '_NOTE_ASC_',
  /** sort on Note descending */
  NoteDesc = '_NOTE_DESC_',
  /** Sort on CapacityMeasure ascending */
  CapacitymeasureAsc = '_CAPACITYMEASURE_ASC_',
  /** sort on CapacityMeasure descending */
  CapacitymeasureDesc = '_CAPACITYMEASURE_DESC_',
  /** Sort on EffectMeasure ascending */
  EffectmeasureAsc = '_EFFECTMEASURE_ASC_',
  /** sort on EffectMeasure descending */
  EffectmeasureDesc = '_EFFECTMEASURE_DESC_',
  /** Sort on CylinderMeasure ascending */
  CylindermeasureAsc = '_CYLINDERMEASURE_ASC_',
  /** sort on CylinderMeasure descending */
  CylindermeasureDesc = '_CYLINDERMEASURE_DESC_',
  /** Sort on Document ascending */
  DocumentAsc = '_DOCUMENT_ASC_',
  /** sort on Document descending */
  DocumentDesc = '_DOCUMENT_DESC_',
  /** Sort on WebPage ascending */
  WebpageAsc = '_WEBPAGE_ASC_',
  /** sort on WebPage descending */
  WebpageDesc = '_WEBPAGE_DESC_',
  /** Sort on ExpAvailableAfterEndDate ascending */
  ExpavailableafterenddateAsc = '_EXPAVAILABLEAFTERENDDATE_ASC_',
  /** sort on ExpAvailableAfterEndDate descending */
  ExpavailableafterenddateDesc = '_EXPAVAILABLEAFTERENDDATE_DESC_',
  /** Sort on CreditonEarlyReturn ascending */
  CreditonearlyreturnAsc = '_CREDITONEARLYRETURN_ASC_',
  /** sort on CreditonEarlyReturn descending */
  CreditonearlyreturnDesc = '_CREDITONEARLYRETURN_DESC_',
  /** Sort on StdRentalChargeDays ascending */
  StdrentalchargedaysAsc = '_STDRENTALCHARGEDAYS_ASC_',
  /** sort on StdRentalChargeDays descending */
  StdrentalchargedaysDesc = '_STDRENTALCHARGEDAYS_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on DefaultMaintenance ascending */
  DefaultmaintenanceAsc = '_DEFAULTMAINTENANCE_ASC_',
  /** sort on DefaultMaintenance descending */
  DefaultmaintenanceDesc = '_DEFAULTMAINTENANCE_DESC_',
  /** Sort on Length ascending */
  LengthAsc = '_LENGTH_ASC_',
  /** sort on Length descending */
  LengthDesc = '_LENGTH_DESC_',
  /** Sort on LengthMeasure ascending */
  LengthmeasureAsc = '_LENGTHMEASURE_ASC_',
  /** sort on LengthMeasure descending */
  LengthmeasureDesc = '_LENGTHMEASURE_DESC_',
  /** Sort on Width ascending */
  WidthAsc = '_WIDTH_ASC_',
  /** sort on Width descending */
  WidthDesc = '_WIDTH_DESC_',
  /** Sort on WidthMeasure ascending */
  WidthmeasureAsc = '_WIDTHMEASURE_ASC_',
  /** sort on WidthMeasure descending */
  WidthmeasureDesc = '_WIDTHMEASURE_DESC_',
  /** Sort on Height ascending */
  HeightAsc = '_HEIGHT_ASC_',
  /** sort on Height descending */
  HeightDesc = '_HEIGHT_DESC_',
  /** Sort on HeightMeasure ascending */
  HeightmeasureAsc = '_HEIGHTMEASURE_ASC_',
  /** sort on HeightMeasure descending */
  HeightmeasureDesc = '_HEIGHTMEASURE_DESC_',
  /** Sort on Weight ascending */
  WeightAsc = '_WEIGHT_ASC_',
  /** sort on Weight descending */
  WeightDesc = '_WEIGHT_DESC_',
  /** Sort on WeightMeasure ascending */
  WeightmeasureAsc = '_WEIGHTMEASURE_ASC_',
  /** sort on WeightMeasure descending */
  WeightmeasureDesc = '_WEIGHTMEASURE_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on QuantityHoursFullRentalDay ascending */
  QuantityhoursfullrentaldayAsc = '_QUANTITYHOURSFULLRENTALDAY_ASC_',
  /** sort on QuantityHoursFullRentalDay descending */
  QuantityhoursfullrentaldayDesc = '_QUANTITYHOURSFULLRENTALDAY_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on ObjectTypeNoConcern ascending */
  ObjecttypenoconcernAsc = '_OBJECTTYPENOCONCERN_ASC_',
  /** sort on ObjectTypeNoConcern descending */
  ObjecttypenoconcernDesc = '_OBJECTTYPENOCONCERN_DESC_',
  /** Sort on DepositAmount ascending */
  DepositamountAsc = '_DEPOSITAMOUNT_ASC_',
  /** sort on DepositAmount descending */
  DepositamountDesc = '_DEPOSITAMOUNT_DESC_',
  /** Sort on HireSplit ascending */
  HiresplitAsc = '_HIRESPLIT_ASC_',
  /** sort on HireSplit descending */
  HiresplitDesc = '_HIRESPLIT_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Description3 ascending */
  Description3Asc = '_DESCRIPTION3_ASC_',
  /** sort on Description3 descending */
  Description3Desc = '_DESCRIPTION3_DESC_',
  /** Sort on Description4 ascending */
  Description4Asc = '_DESCRIPTION4_ASC_',
  /** sort on Description4 descending */
  Description4Desc = '_DESCRIPTION4_DESC_',
  /** Sort on FixedCostPrice ascending */
  FixedcostpriceAsc = '_FIXEDCOSTPRICE_ASC_',
  /** sort on FixedCostPrice descending */
  FixedcostpriceDesc = '_FIXEDCOSTPRICE_DESC_',
  /** Sort on RentalSplit ascending */
  RentalsplitAsc = '_RENTALSPLIT_ASC_',
  /** sort on RentalSplit descending */
  RentalsplitDesc = '_RENTALSPLIT_DESC_',
  /** Sort on StraightLine ascending */
  StraightlineAsc = '_STRAIGHTLINE_ASC_',
  /** sort on StraightLine descending */
  StraightlineDesc = '_STRAIGHTLINE_DESC_',
  /** Sort on WarrantyDuration ascending */
  WarrantydurationAsc = '_WARRANTYDURATION_ASC_',
  /** sort on WarrantyDuration descending */
  WarrantydurationDesc = '_WARRANTYDURATION_DESC_',
  /** Sort on DecliningBalance ascending */
  DecliningbalanceAsc = '_DECLININGBALANCE_ASC_',
  /** sort on DecliningBalance descending */
  DecliningbalanceDesc = '_DECLININGBALANCE_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on RetentionLevel ascending */
  RetentionlevelAsc = '_RETENTIONLEVEL_ASC_',
  /** sort on RetentionLevel descending */
  RetentionlevelDesc = '_RETENTIONLEVEL_DESC_',
  /** Sort on TaxDepreciationMethod ascending */
  TaxdepreciationmethodAsc = '_TAXDEPRECIATIONMETHOD_ASC_',
  /** sort on TaxDepreciationMethod descending */
  TaxdepreciationmethodDesc = '_TAXDEPRECIATIONMETHOD_DESC_',
  /** Sort on TaxFAPostingGroup ascending */
  TaxfapostinggroupAsc = '_TAXFAPOSTINGGROUP_ASC_',
  /** sort on TaxFAPostingGroup descending */
  TaxfapostinggroupDesc = '_TAXFAPOSTINGGROUP_DESC_',
  /** Sort on TaxNoofDepreciationYears ascending */
  TaxnoofdepreciationyearsAsc = '_TAXNOOFDEPRECIATIONYEARS_ASC_',
  /** sort on TaxNoofDepreciationYears descending */
  TaxnoofdepreciationyearsDesc = '_TAXNOOFDEPRECIATIONYEARS_DESC_',
  /** Sort on TaxNoofDepreciationMonths ascending */
  TaxnoofdepreciationmonthsAsc = '_TAXNOOFDEPRECIATIONMONTHS_ASC_',
  /** sort on TaxNoofDepreciationMonths descending */
  TaxnoofdepreciationmonthsDesc = '_TAXNOOFDEPRECIATIONMONTHS_DESC_',
  /** Sort on TaxStraightLine ascending */
  TaxstraightlineAsc = '_TAXSTRAIGHTLINE_ASC_',
  /** sort on TaxStraightLine descending */
  TaxstraightlineDesc = '_TAXSTRAIGHTLINE_DESC_',
  /** Sort on TaxDecliningBalance ascending */
  TaxdecliningbalanceAsc = '_TAXDECLININGBALANCE_ASC_',
  /** sort on TaxDecliningBalance descending */
  TaxdecliningbalanceDesc = '_TAXDECLININGBALANCE_DESC_',
  /** Sort on PriceScheduleCode ascending */
  PriceschedulecodeAsc = '_PRICESCHEDULECODE_ASC_',
  /** sort on PriceScheduleCode descending */
  PriceschedulecodeDesc = '_PRICESCHEDULECODE_DESC_',
  /** Sort on EstimatedDailyHours ascending */
  EstimateddailyhoursAsc = '_ESTIMATEDDAILYHOURS_ASC_',
  /** sort on EstimatedDailyHours descending */
  EstimateddailyhoursDesc = '_ESTIMATEDDAILYHOURS_DESC_',
  /** Sort on MandatoryCounteronReturn ascending */
  MandatorycounteronreturnAsc = '_MANDATORYCOUNTERONRETURN_ASC_',
  /** sort on MandatoryCounteronReturn descending */
  MandatorycounteronreturnDesc = '_MANDATORYCOUNTERONRETURN_DESC_',
  /** Sort on CounterType2 ascending */
  Countertype2Asc = '_COUNTERTYPE2_ASC_',
  /** sort on CounterType2 descending */
  Countertype2Desc = '_COUNTERTYPE2_DESC_',
  /** Sort on MandatoryCounter2onReturn ascending */
  Mandatorycounter2OnreturnAsc = '_MANDATORYCOUNTER2ONRETURN_ASC_',
  /** sort on MandatoryCounter2onReturn descending */
  Mandatorycounter2OnreturnDesc = '_MANDATORYCOUNTER2ONRETURN_DESC_',
  /** Sort on EstimatedCounterperDay ascending */
  EstimatedcounterperdayAsc = '_ESTIMATEDCOUNTERPERDAY_ASC_',
  /** sort on EstimatedCounterperDay descending */
  EstimatedcounterperdayDesc = '_ESTIMATEDCOUNTERPERDAY_DESC_',
  /** Sort on EstimatedCounter2perDay ascending */
  Estimatedcounter2PerdayAsc = '_ESTIMATEDCOUNTER2PERDAY_ASC_',
  /** sort on EstimatedCounter2perDay descending */
  Estimatedcounter2PerdayDesc = '_ESTIMATEDCOUNTER2PERDAY_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DateFilter2 ascending */
  Datefilter2Asc = '_DATEFILTER2_ASC_',
  /** sort on DateFilter2 descending */
  Datefilter2Desc = '_DATEFILTER2_DESC_',
  /** Sort on Inventory ascending */
  InventoryAsc = '_INVENTORY_ASC_',
  /** sort on Inventory descending */
  InventoryDesc = '_INVENTORY_DESC_',
  /** Sort on InventoryRental ascending */
  InventoryrentalAsc = '_INVENTORYRENTAL_ASC_',
  /** sort on InventoryRental descending */
  InventoryrentalDesc = '_INVENTORYRENTAL_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on CommentObjectGroup ascending */
  CommentobjectgroupAsc = '_COMMENTOBJECTGROUP_ASC_',
  /** sort on CommentObjectGroup descending */
  CommentobjectgroupDesc = '_COMMENTOBJECTGROUP_DESC_',
  /** Sort on FlowSumCost ascending */
  FlowsumcostAsc = '_FLOWSUMCOST_ASC_',
  /** sort on FlowSumCost descending */
  FlowsumcostDesc = '_FLOWSUMCOST_DESC_',
  /** Sort on FlowSalesDiscount ascending */
  FlowsalesdiscountAsc = '_FLOWSALESDISCOUNT_ASC_',
  /** sort on FlowSalesDiscount descending */
  FlowsalesdiscountDesc = '_FLOWSALESDISCOUNT_DESC_',
  /** Sort on FlowSalesAmount ascending */
  FlowsalesamountAsc = '_FLOWSALESAMOUNT_ASC_',
  /** sort on FlowSalesAmount descending */
  FlowsalesamountDesc = '_FLOWSALESAMOUNT_DESC_',
  /** Sort on FlowOtherCost ascending */
  FlowothercostAsc = '_FLOWOTHERCOST_ASC_',
  /** sort on FlowOtherCost descending */
  FlowothercostDesc = '_FLOWOTHERCOST_DESC_',
  /** Sort on FlowLeasingCost ascending */
  FlowleasingcostAsc = '_FLOWLEASINGCOST_ASC_',
  /** sort on FlowLeasingCost descending */
  FlowleasingcostDesc = '_FLOWLEASINGCOST_DESC_',
  /** Sort on FlowOtherIncome ascending */
  FlowotherincomeAsc = '_FLOWOTHERINCOME_ASC_',
  /** sort on FlowOtherIncome descending */
  FlowotherincomeDesc = '_FLOWOTHERINCOME_DESC_',
  /** Sort on FlowRentalIncome ascending */
  FlowrentalincomeAsc = '_FLOWRENTALINCOME_ASC_',
  /** sort on FlowRentalIncome descending */
  FlowrentalincomeDesc = '_FLOWRENTALINCOME_DESC_',
  /** Sort on FlowRentalDiscount ascending */
  FlowrentaldiscountAsc = '_FLOWRENTALDISCOUNT_ASC_',
  /** sort on FlowRentalDiscount descending */
  FlowrentaldiscountDesc = '_FLOWRENTALDISCOUNT_DESC_',
  /** Sort on FlowPurchaseCharge ascending */
  FlowpurchasechargeAsc = '_FLOWPURCHASECHARGE_ASC_',
  /** sort on FlowPurchaseCharge descending */
  FlowpurchasechargeDesc = '_FLOWPURCHASECHARGE_DESC_',
  /** Sort on FlowServiceCost ascending */
  FlowservicecostAsc = '_FLOWSERVICECOST_ASC_',
  /** sort on FlowServiceCost descending */
  FlowservicecostDesc = '_FLOWSERVICECOST_DESC_',
  /** Sort on FlowGuaranteeCost ascending */
  FlowguaranteecostAsc = '_FLOWGUARANTEECOST_ASC_',
  /** sort on FlowGuaranteeCost descending */
  FlowguaranteecostDesc = '_FLOWGUARANTEECOST_DESC_',
  /** Sort on FlowInternalRentalCost ascending */
  FlowinternalrentalcostAsc = '_FLOWINTERNALRENTALCOST_ASC_',
  /** sort on FlowInternalRentalCost descending */
  FlowinternalrentalcostDesc = '_FLOWINTERNALRENTALCOST_DESC_',
  /** Sort on FlowHireCost ascending */
  FlowhirecostAsc = '_FLOWHIRECOST_ASC_',
  /** sort on FlowHireCost descending */
  FlowhirecostDesc = '_FLOWHIRECOST_DESC_',
  /** Sort on FlowHireDiscount ascending */
  FlowhirediscountAsc = '_FLOWHIREDISCOUNT_ASC_',
  /** sort on FlowHireDiscount descending */
  FlowhirediscountDesc = '_FLOWHIREDISCOUNT_DESC_',
  /** Sort on FlowServiceAgreementCost ascending */
  FlowserviceagreementcostAsc = '_FLOWSERVICEAGREEMENTCOST_ASC_',
  /** sort on FlowServiceAgreementCost descending */
  FlowserviceagreementcostDesc = '_FLOWSERVICEAGREEMENTCOST_DESC_',
  /** Sort on FlowDepreciation ascending */
  FlowdepreciationAsc = '_FLOWDEPRECIATION_ASC_',
  /** sort on FlowDepreciation descending */
  FlowdepreciationDesc = '_FLOWDEPRECIATION_DESC_',
  /** Sort on FlowUppreciation ascending */
  FlowuppreciationAsc = '_FLOWUPPRECIATION_ASC_',
  /** sort on FlowUppreciation descending */
  FlowuppreciationDesc = '_FLOWUPPRECIATION_DESC_',
  /** Sort on FlowDownpreciation ascending */
  FlowdownpreciationAsc = '_FLOWDOWNPRECIATION_ASC_',
  /** sort on FlowDownpreciation descending */
  FlowdownpreciationDesc = '_FLOWDOWNPRECIATION_DESC_',
  /** Sort on FlowGainbySale ascending */
  FlowgainbysaleAsc = '_FLOWGAINBYSALE_ASC_',
  /** sort on FlowGainbySale descending */
  FlowgainbysaleDesc = '_FLOWGAINBYSALE_DESC_',
  /** Sort on FlowLossbySale ascending */
  FlowlossbysaleAsc = '_FLOWLOSSBYSALE_ASC_',
  /** sort on FlowLossbySale descending */
  FlowlossbysaleDesc = '_FLOWLOSSBYSALE_DESC_',
  /** Sort on FlowPurchaseDiscount ascending */
  FlowpurchasediscountAsc = '_FLOWPURCHASEDISCOUNT_ASC_',
  /** sort on FlowPurchaseDiscount descending */
  FlowpurchasediscountDesc = '_FLOWPURCHASEDISCOUNT_DESC_',
  /** Sort on FlowSalesCharge ascending */
  FlowsaleschargeAsc = '_FLOWSALESCHARGE_ASC_',
  /** sort on FlowSalesCharge descending */
  FlowsaleschargeDesc = '_FLOWSALESCHARGE_DESC_',
  /** Sort on FlowServiceIncome ascending */
  FlowserviceincomeAsc = '_FLOWSERVICEINCOME_ASC_',
  /** sort on FlowServiceIncome descending */
  FlowserviceincomeDesc = '_FLOWSERVICEINCOME_DESC_',
  /** Sort on FlowServiceAgreementIncome ascending */
  FlowserviceagreementincomeAsc = '_FLOWSERVICEAGREEMENTINCOME_ASC_',
  /** sort on FlowServiceAgreementIncome descending */
  FlowserviceagreementincomeDesc = '_FLOWSERVICEAGREEMENTINCOME_DESC_',
  /** Sort on FlowInternalRentalIncome ascending */
  FlowinternalrentalincomeAsc = '_FLOWINTERNALRENTALINCOME_ASC_',
  /** sort on FlowInternalRentalIncome descending */
  FlowinternalrentalincomeDesc = '_FLOWINTERNALRENTALINCOME_DESC_',
  /** Sort on FlowValueChange ascending */
  FlowvaluechangeAsc = '_FLOWVALUECHANGE_ASC_',
  /** sort on FlowValueChange descending */
  FlowvaluechangeDesc = '_FLOWVALUECHANGE_DESC_',
  /** Sort on FlowRentalDays ascending */
  FlowrentaldaysAsc = '_FLOWRENTALDAYS_ASC_',
  /** sort on FlowRentalDays descending */
  FlowrentaldaysDesc = '_FLOWRENTALDAYS_DESC_',
  /** Sort on FlowRentalHours ascending */
  FlowrentalhoursAsc = '_FLOWRENTALHOURS_ASC_',
  /** sort on FlowRentalHours descending */
  FlowrentalhoursDesc = '_FLOWRENTALHOURS_DESC_',
  /** Sort on InvoicedDays ascending */
  InvoiceddaysAsc = '_INVOICEDDAYS_ASC_',
  /** sort on InvoicedDays descending */
  InvoiceddaysDesc = '_INVOICEDDAYS_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on RentalReservationsQty ascending */
  RentalreservationsqtyAsc = '_RENTALRESERVATIONSQTY_ASC_',
  /** sort on RentalReservationsQty descending */
  RentalreservationsqtyDesc = '_RENTALRESERVATIONSQTY_DESC_',
  /** Sort on RentalDeliveriesQty ascending */
  RentaldeliveriesqtyAsc = '_RENTALDELIVERIESQTY_ASC_',
  /** sort on RentalDeliveriesQty descending */
  RentaldeliveriesqtyDesc = '_RENTALDELIVERIESQTY_DESC_',
  /** Sort on ExpectedRentalReturnsQty ascending */
  ExpectedrentalreturnsqtyAsc = '_EXPECTEDRENTALRETURNSQTY_ASC_',
  /** sort on ExpectedRentalReturnsQty descending */
  ExpectedrentalreturnsqtyDesc = '_EXPECTEDRENTALRETURNSQTY_DESC_',
  /** Sort on RentalReturnsQty ascending */
  RentalreturnsqtyAsc = '_RENTALRETURNSQTY_ASC_',
  /** sort on RentalReturnsQty descending */
  RentalreturnsqtyDesc = '_RENTALRETURNSQTY_DESC_',
  /** Sort on PictureURL ascending */
  PictureurlAsc = '_PICTUREURL_ASC_',
  /** sort on PictureURL descending */
  PictureurlDesc = '_PICTUREURL_DESC_',
  /** Sort on PSIDocumentURL ascending */
  PsidocumenturlAsc = '_PSIDOCUMENTURL_ASC_',
  /** sort on PSIDocumentURL descending */
  PsidocumenturlDesc = '_PSIDOCUMENTURL_DESC_',
  /** Sort on NotAvailableForReservation ascending */
  NotavailableforreservationAsc = '_NOTAVAILABLEFORRESERVATION_ASC_',
  /** sort on NotAvailableForReservation descending */
  NotavailableforreservationDesc = '_NOTAVAILABLEFORRESERVATION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortOcrAnalysisInput {
  /** Sort on language ascending */
  LanguageAsc = '_LANGUAGE_ASC_',
  /** sort on language descending */
  LanguageDesc = '_LANGUAGE_DESC_',
  /** Sort on textAngle ascending */
  TextangleAsc = '_TEXTANGLE_ASC_',
  /** sort on textAngle descending */
  TextangleDesc = '_TEXTANGLE_DESC_',
  /** Sort on orientation ascending */
  OrientationAsc = '_ORIENTATION_ASC_',
  /** sort on orientation descending */
  OrientationDesc = '_ORIENTATION_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPaymentMethodInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on DirectDebit ascending */
  DirectdebitAsc = '_DIRECTDEBIT_ASC_',
  /** sort on DirectDebit descending */
  DirectdebitDesc = '_DIRECTDEBIT_DESC_',
  /** Sort on DirectDebitPmtTermsCode ascending */
  DirectdebitpmttermscodeAsc = '_DIRECTDEBITPMTTERMSCODE_ASC_',
  /** sort on DirectDebitPmtTermsCode descending */
  DirectdebitpmttermscodeDesc = '_DIRECTDEBITPMTTERMSCODE_DESC_',
  /** Sort on PmtExportLineDefinition ascending */
  PmtexportlinedefinitionAsc = '_PMTEXPORTLINEDEFINITION_ASC_',
  /** sort on PmtExportLineDefinition descending */
  PmtexportlinedefinitionDesc = '_PMTEXPORTLINEDEFINITION_DESC_',
  /** Sort on BankDataConversionPmtType ascending */
  BankdataconversionpmttypeAsc = '_BANKDATACONVERSIONPMTTYPE_ASC_',
  /** sort on BankDataConversionPmtType descending */
  BankdataconversionpmttypeDesc = '_BANKDATACONVERSIONPMTTYPE_DESC_',
  /** Sort on UseforInvoicing ascending */
  UseforinvoicingAsc = '_USEFORINVOICING_ASC_',
  /** sort on UseforInvoicing descending */
  UseforinvoicingDesc = '_USEFORINVOICING_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on AMCBankPmtType ascending */
  AmcbankpmttypeAsc = '_AMCBANKPMTTYPE_ASC_',
  /** sort on AMCBankPmtType descending */
  AmcbankpmttypeDesc = '_AMCBANKPMTTYPE_DESC_',
  /** Sort on EQMCashPayment ascending */
  EqmcashpaymentAsc = '_EQMCASHPAYMENT_ASC_',
  /** sort on EQMCashPayment descending */
  EqmcashpaymentDesc = '_EQMCASHPAYMENT_DESC_',
  /** Sort on EQMPaymentType ascending */
  EqmpaymenttypeAsc = '_EQMPAYMENTTYPE_ASC_',
  /** sort on EQMPaymentType descending */
  EqmpaymenttypeDesc = '_EQMPAYMENTTYPE_DESC_',
  /** Sort on EQMPaymentTypeOPTION ascending */
  EqmpaymenttypeoptionAsc = '_EQMPAYMENTTYPEOPTION_ASC_',
  /** sort on EQMPaymentTypeOPTION descending */
  EqmpaymenttypeoptionDesc = '_EQMPAYMENTTYPEOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPaymentTermsInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on DueDateCalculation ascending */
  DuedatecalculationAsc = '_DUEDATECALCULATION_ASC_',
  /** sort on DueDateCalculation descending */
  DuedatecalculationDesc = '_DUEDATECALCULATION_DESC_',
  /** Sort on DiscountDateCalculation ascending */
  DiscountdatecalculationAsc = '_DISCOUNTDATECALCULATION_ASC_',
  /** sort on DiscountDateCalculation descending */
  DiscountdatecalculationDesc = '_DISCOUNTDATECALCULATION_DESC_',
  /** Sort on DiscountPercent ascending */
  DiscountpercentAsc = '_DISCOUNTPERCENT_ASC_',
  /** sort on DiscountPercent descending */
  DiscountpercentDesc = '_DISCOUNTPERCENT_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on CalcPmtDisconCrMemos ascending */
  CalcpmtdisconcrmemosAsc = '_CALCPMTDISCONCRMEMOS_ASC_',
  /** sort on CalcPmtDisconCrMemos descending */
  CalcpmtdisconcrmemosDesc = '_CALCPMTDISCONCRMEMOS_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPlatformFeatureInput {
  /** Sort on name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on group ascending */
  GroupAsc = '_GROUP_ASC_',
  /** sort on group descending */
  GroupDesc = '_GROUP_DESC_',
  /** Sort on basic ascending */
  BasicAsc = '_BASIC_ASC_',
  /** sort on basic descending */
  BasicDesc = '_BASIC_DESC_',
  /** Sort on premium ascending */
  PremiumAsc = '_PREMIUM_ASC_',
  /** sort on premium descending */
  PremiumDesc = '_PREMIUM_DESC_',
  /** Sort on sorting ascending */
  SortingAsc = '_SORTING_ASC_',
  /** sort on sorting descending */
  SortingDesc = '_SORTING_DESC_',
  /** Sort on enterprise ascending */
  EnterpriseAsc = '_ENTERPRISE_ASC_',
  /** sort on enterprise descending */
  EnterpriseDesc = '_ENTERPRISE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPostCodeInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on SearchCity ascending */
  SearchcityAsc = '_SEARCHCITY_ASC_',
  /** sort on SearchCity descending */
  SearchcityDesc = '_SEARCHCITY_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on TimeZone ascending */
  TimezoneAsc = '_TIMEZONE_ASC_',
  /** sort on TimeZone descending */
  TimezoneDesc = '_TIMEZONE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortProjectInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on SearchDescription ascending */
  SearchdescriptionAsc = '_SEARCHDESCRIPTION_ASC_',
  /** sort on SearchDescription descending */
  SearchdescriptionDesc = '_SEARCHDESCRIPTION_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on CreationDate ascending */
  CreationdateAsc = '_CREATIONDATE_ASC_',
  /** sort on CreationDate descending */
  CreationdateDesc = '_CREATIONDATE_DESC_',
  /** Sort on StartingDate ascending */
  StartingdateAsc = '_STARTINGDATE_ASC_',
  /** sort on StartingDate descending */
  StartingdateDesc = '_STARTINGDATE_DESC_',
  /** Sort on EndingDate ascending */
  EndingdateAsc = '_ENDINGDATE_ASC_',
  /** sort on EndingDate descending */
  EndingdateDesc = '_ENDINGDATE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on PersonResponsible ascending */
  PersonresponsibleAsc = '_PERSONRESPONSIBLE_ASC_',
  /** sort on PersonResponsible descending */
  PersonresponsibleDesc = '_PERSONRESPONSIBLE_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on JobPostingGroup ascending */
  JobpostinggroupAsc = '_JOBPOSTINGGROUP_ASC_',
  /** sort on JobPostingGroup descending */
  JobpostinggroupDesc = '_JOBPOSTINGGROUP_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on BlockedOPTION ascending */
  BlockedoptionAsc = '_BLOCKEDOPTION_ASC_',
  /** sort on BlockedOPTION descending */
  BlockedoptionDesc = '_BLOCKEDOPTION_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on Image ascending */
  ImageAsc = '_IMAGE_ASC_',
  /** sort on Image descending */
  ImageDesc = '_IMAGE_DESC_',
  /** Sort on WIPMethod ascending */
  WipmethodAsc = '_WIPMETHOD_ASC_',
  /** sort on WIPMethod descending */
  WipmethodDesc = '_WIPMETHOD_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on WIPPostingDate ascending */
  WippostingdateAsc = '_WIPPOSTINGDATE_ASC_',
  /** sort on WIPPostingDate descending */
  WippostingdateDesc = '_WIPPOSTINGDATE_DESC_',
  /** Sort on InvoiceCurrencyCode ascending */
  InvoicecurrencycodeAsc = '_INVOICECURRENCYCODE_ASC_',
  /** sort on InvoiceCurrencyCode descending */
  InvoicecurrencycodeDesc = '_INVOICECURRENCYCODE_DESC_',
  /** Sort on ExchCalculationCost ascending */
  ExchcalculationcostAsc = '_EXCHCALCULATIONCOST_ASC_',
  /** sort on ExchCalculationCost descending */
  ExchcalculationcostDesc = '_EXCHCALCULATIONCOST_DESC_',
  /** Sort on ExchCalculationCostOPTION ascending */
  ExchcalculationcostoptionAsc = '_EXCHCALCULATIONCOSTOPTION_ASC_',
  /** sort on ExchCalculationCostOPTION descending */
  ExchcalculationcostoptionDesc = '_EXCHCALCULATIONCOSTOPTION_DESC_',
  /** Sort on ExchCalculationPrice ascending */
  ExchcalculationpriceAsc = '_EXCHCALCULATIONPRICE_ASC_',
  /** sort on ExchCalculationPrice descending */
  ExchcalculationpriceDesc = '_EXCHCALCULATIONPRICE_DESC_',
  /** Sort on ExchCalculationPriceOPTION ascending */
  ExchcalculationpriceoptionAsc = '_EXCHCALCULATIONPRICEOPTION_ASC_',
  /** sort on ExchCalculationPriceOPTION descending */
  ExchcalculationpriceoptionDesc = '_EXCHCALCULATIONPRICEOPTION_DESC_',
  /** Sort on AllowScheduleContractLines ascending */
  AllowschedulecontractlinesAsc = '_ALLOWSCHEDULECONTRACTLINES_ASC_',
  /** sort on AllowScheduleContractLines descending */
  AllowschedulecontractlinesDesc = '_ALLOWSCHEDULECONTRACTLINES_DESC_',
  /** Sort on Complete ascending */
  CompleteAsc = '_COMPLETE_ASC_',
  /** sort on Complete descending */
  CompleteDesc = '_COMPLETE_DESC_',
  /** Sort on ApplyUsageLink ascending */
  ApplyusagelinkAsc = '_APPLYUSAGELINK_ASC_',
  /** sort on ApplyUsageLink descending */
  ApplyusagelinkDesc = '_APPLYUSAGELINK_DESC_',
  /** Sort on WIPPostingMethod ascending */
  WippostingmethodAsc = '_WIPPOSTINGMETHOD_ASC_',
  /** sort on WIPPostingMethod descending */
  WippostingmethodDesc = '_WIPPOSTINGMETHOD_DESC_',
  /** Sort on WIPPostingMethodOPTION ascending */
  WippostingmethodoptionAsc = '_WIPPOSTINGMETHODOPTION_ASC_',
  /** sort on WIPPostingMethodOPTION descending */
  WippostingmethodoptionDesc = '_WIPPOSTINGMETHODOPTION_DESC_',
  /** Sort on OverBudget ascending */
  OverbudgetAsc = '_OVERBUDGET_ASC_',
  /** sort on OverBudget descending */
  OverbudgetDesc = '_OVERBUDGET_DESC_',
  /** Sort on ProjectManager ascending */
  ProjectmanagerAsc = '_PROJECTMANAGER_ASC_',
  /** sort on ProjectManager descending */
  ProjectmanagerDesc = '_PROJECTMANAGER_DESC_',
  /** Sort on BusinessArea ascending */
  BusinessareaAsc = '_BUSINESSAREA_ASC_',
  /** sort on BusinessArea descending */
  BusinessareaDesc = '_BUSINESSAREA_DESC_',
  /** Sort on Inactive ascending */
  InactiveAsc = '_INACTIVE_ASC_',
  /** sort on Inactive descending */
  InactiveDesc = '_INACTIVE_DESC_',
  /** Sort on NotChargeable ascending */
  NotchargeableAsc = '_NOTCHARGEABLE_ASC_',
  /** sort on NotChargeable descending */
  NotchargeableDesc = '_NOTCHARGEABLE_DESC_',
  /** Sort on ResourceFilter ascending */
  ResourcefilterAsc = '_RESOURCEFILTER_ASC_',
  /** sort on ResourceFilter descending */
  ResourcefilterDesc = '_RESOURCEFILTER_DESC_',
  /** Sort on PostingDateFilter ascending */
  PostingdatefilterAsc = '_POSTINGDATEFILTER_ASC_',
  /** sort on PostingDateFilter descending */
  PostingdatefilterDesc = '_POSTINGDATEFILTER_DESC_',
  /** Sort on ResourceGrFilter ascending */
  ResourcegrfilterAsc = '_RESOURCEGRFILTER_ASC_',
  /** sort on ResourceGrFilter descending */
  ResourcegrfilterDesc = '_RESOURCEGRFILTER_DESC_',
  /** Sort on PlanningDateFilter ascending */
  PlanningdatefilterAsc = '_PLANNINGDATEFILTER_ASC_',
  /** sort on PlanningDateFilter descending */
  PlanningdatefilterDesc = '_PLANNINGDATEFILTER_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on ScheduledResQty ascending */
  ScheduledresqtyAsc = '_SCHEDULEDRESQTY_ASC_',
  /** sort on ScheduledResQty descending */
  ScheduledresqtyDesc = '_SCHEDULEDRESQTY_DESC_',
  /** Sort on ScheduledResGrQty ascending */
  ScheduledresgrqtyAsc = '_SCHEDULEDRESGRQTY_ASC_',
  /** sort on ScheduledResGrQty descending */
  ScheduledresgrqtyDesc = '_SCHEDULEDRESGRQTY_DESC_',
  /** Sort on TotalWIPCostAmount ascending */
  TotalwipcostamountAsc = '_TOTALWIPCOSTAMOUNT_ASC_',
  /** sort on TotalWIPCostAmount descending */
  TotalwipcostamountDesc = '_TOTALWIPCOSTAMOUNT_DESC_',
  /** Sort on TotalWIPCostGLAmount ascending */
  TotalwipcostglamountAsc = '_TOTALWIPCOSTGLAMOUNT_ASC_',
  /** sort on TotalWIPCostGLAmount descending */
  TotalwipcostglamountDesc = '_TOTALWIPCOSTGLAMOUNT_DESC_',
  /** Sort on WIPEntriesExist ascending */
  WipentriesexistAsc = '_WIPENTRIESEXIST_ASC_',
  /** sort on WIPEntriesExist descending */
  WipentriesexistDesc = '_WIPENTRIESEXIST_DESC_',
  /** Sort on WIPGLPostingDate ascending */
  WipglpostingdateAsc = '_WIPGLPOSTINGDATE_ASC_',
  /** sort on WIPGLPostingDate descending */
  WipglpostingdateDesc = '_WIPGLPOSTINGDATE_DESC_',
  /** Sort on RecogSalesAmount ascending */
  RecogsalesamountAsc = '_RECOGSALESAMOUNT_ASC_',
  /** sort on RecogSalesAmount descending */
  RecogsalesamountDesc = '_RECOGSALESAMOUNT_DESC_',
  /** Sort on RecogSalesGLAmount ascending */
  RecogsalesglamountAsc = '_RECOGSALESGLAMOUNT_ASC_',
  /** sort on RecogSalesGLAmount descending */
  RecogsalesglamountDesc = '_RECOGSALESGLAMOUNT_DESC_',
  /** Sort on RecogCostsAmount ascending */
  RecogcostsamountAsc = '_RECOGCOSTSAMOUNT_ASC_',
  /** sort on RecogCostsAmount descending */
  RecogcostsamountDesc = '_RECOGCOSTSAMOUNT_DESC_',
  /** Sort on RecogCostsGLAmount ascending */
  RecogcostsglamountAsc = '_RECOGCOSTSGLAMOUNT_ASC_',
  /** sort on RecogCostsGLAmount descending */
  RecogcostsglamountDesc = '_RECOGCOSTSGLAMOUNT_DESC_',
  /** Sort on TotalWIPSalesAmount ascending */
  TotalwipsalesamountAsc = '_TOTALWIPSALESAMOUNT_ASC_',
  /** sort on TotalWIPSalesAmount descending */
  TotalwipsalesamountDesc = '_TOTALWIPSALESAMOUNT_DESC_',
  /** Sort on TotalWIPSalesGLAmount ascending */
  TotalwipsalesglamountAsc = '_TOTALWIPSALESGLAMOUNT_ASC_',
  /** sort on TotalWIPSalesGLAmount descending */
  TotalwipsalesglamountDesc = '_TOTALWIPSALESGLAMOUNT_DESC_',
  /** Sort on WIPCompletionCalculated ascending */
  WipcompletioncalculatedAsc = '_WIPCOMPLETIONCALCULATED_ASC_',
  /** sort on WIPCompletionCalculated descending */
  WipcompletioncalculatedDesc = '_WIPCOMPLETIONCALCULATED_DESC_',
  /** Sort on NextInvoiceDate ascending */
  NextinvoicedateAsc = '_NEXTINVOICEDATE_ASC_',
  /** sort on NextInvoiceDate descending */
  NextinvoicedateDesc = '_NEXTINVOICEDATE_DESC_',
  /** Sort on WIPWarnings ascending */
  WipwarningsAsc = '_WIPWARNINGS_ASC_',
  /** sort on WIPWarnings descending */
  WipwarningsDesc = '_WIPWARNINGS_DESC_',
  /** Sort on AppliedCostsGLAmount ascending */
  AppliedcostsglamountAsc = '_APPLIEDCOSTSGLAMOUNT_ASC_',
  /** sort on AppliedCostsGLAmount descending */
  AppliedcostsglamountDesc = '_APPLIEDCOSTSGLAMOUNT_DESC_',
  /** Sort on AppliedSalesGLAmount ascending */
  AppliedsalesglamountAsc = '_APPLIEDSALESGLAMOUNT_ASC_',
  /** sort on AppliedSalesGLAmount descending */
  AppliedsalesglamountDesc = '_APPLIEDSALESGLAMOUNT_DESC_',
  /** Sort on CalcRecogSalesAmount ascending */
  CalcrecogsalesamountAsc = '_CALCRECOGSALESAMOUNT_ASC_',
  /** sort on CalcRecogSalesAmount descending */
  CalcrecogsalesamountDesc = '_CALCRECOGSALESAMOUNT_DESC_',
  /** Sort on CalcRecogCostsAmount ascending */
  CalcrecogcostsamountAsc = '_CALCRECOGCOSTSAMOUNT_ASC_',
  /** sort on CalcRecogCostsAmount descending */
  CalcrecogcostsamountDesc = '_CALCRECOGCOSTSAMOUNT_DESC_',
  /** Sort on CalcRecogSalesGLAmount ascending */
  CalcrecogsalesglamountAsc = '_CALCRECOGSALESGLAMOUNT_ASC_',
  /** sort on CalcRecogSalesGLAmount descending */
  CalcrecogsalesglamountDesc = '_CALCRECOGSALESGLAMOUNT_DESC_',
  /** Sort on CalcRecogCostsGLAmount ascending */
  CalcrecogcostsglamountAsc = '_CALCRECOGCOSTSGLAMOUNT_ASC_',
  /** sort on CalcRecogCostsGLAmount descending */
  CalcrecogcostsglamountDesc = '_CALCRECOGCOSTSGLAMOUNT_DESC_',
  /** Sort on WIPCompletionPosted ascending */
  WipcompletionpostedAsc = '_WIPCOMPLETIONPOSTED_ASC_',
  /** sort on WIPCompletionPosted descending */
  WipcompletionpostedDesc = '_WIPCOMPLETIONPOSTED_DESC_',
  /** Sort on MonthlyValue ascending */
  MonthlyvalueAsc = '_MONTHLYVALUE_ASC_',
  /** sort on MonthlyValue descending */
  MonthlyvalueDesc = '_MONTHLYVALUE_DESC_',
  /** Sort on EstimatedValue ascending */
  EstimatedvalueAsc = '_ESTIMATEDVALUE_ASC_',
  /** sort on EstimatedValue descending */
  EstimatedvalueDesc = '_ESTIMATEDVALUE_DESC_',
  /** Sort on LinkedTo ascending */
  LinkedtoAsc = '_LINKEDTO_ASC_',
  /** sort on LinkedTo descending */
  LinkedtoDesc = '_LINKEDTO_DESC_',
  /** Sort on JobTaskType ascending */
  JobtasktypeAsc = '_JOBTASKTYPE_ASC_',
  /** sort on JobTaskType descending */
  JobtasktypeDesc = '_JOBTASKTYPE_DESC_',
  /** Sort on SyncJobTastTypeWithDevops ascending */
  SyncjobtasttypewithdevopsAsc = '_SYNCJOBTASTTYPEWITHDEVOPS_ASC_',
  /** sort on SyncJobTastTypeWithDevops descending */
  SyncjobtasttypewithdevopsDesc = '_SYNCJOBTASTTYPEWITHDEVOPS_DESC_',
  /** Sort on DevopsTeamId ascending */
  DevopsteamidAsc = '_DEVOPSTEAMID_ASC_',
  /** sort on DevopsTeamId descending */
  DevopsteamidDesc = '_DEVOPSTEAMID_DESC_',
  /** Sort on DevopsProjectId ascending */
  DevopsprojectidAsc = '_DEVOPSPROJECTID_ASC_',
  /** sort on DevopsProjectId descending */
  DevopsprojectidDesc = '_DEVOPSPROJECTID_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchCrMemoHeaderInput {
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on PaytoName ascending */
  PaytonameAsc = '_PAYTONAME_ASC_',
  /** sort on PaytoName descending */
  PaytonameDesc = '_PAYTONAME_DESC_',
  /** Sort on PaytoName2 ascending */
  Paytoname2Asc = '_PAYTONAME2_ASC_',
  /** sort on PaytoName2 descending */
  Paytoname2Desc = '_PAYTONAME2_DESC_',
  /** Sort on PaytoAddress ascending */
  PaytoaddressAsc = '_PAYTOADDRESS_ASC_',
  /** sort on PaytoAddress descending */
  PaytoaddressDesc = '_PAYTOADDRESS_DESC_',
  /** Sort on PaytoAddress2 ascending */
  Paytoaddress2Asc = '_PAYTOADDRESS2_ASC_',
  /** sort on PaytoAddress2 descending */
  Paytoaddress2Desc = '_PAYTOADDRESS2_DESC_',
  /** Sort on PaytoCity ascending */
  PaytocityAsc = '_PAYTOCITY_ASC_',
  /** sort on PaytoCity descending */
  PaytocityDesc = '_PAYTOCITY_DESC_',
  /** Sort on PaytoContact ascending */
  PaytocontactAsc = '_PAYTOCONTACT_ASC_',
  /** sort on PaytoContact descending */
  PaytocontactDesc = '_PAYTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscountPercent ascending */
  PaymentdiscountpercentAsc = '_PAYMENTDISCOUNTPERCENT_ASC_',
  /** sort on PaymentDiscountPercent descending */
  PaymentdiscountpercentDesc = '_PAYMENTDISCOUNTPERCENT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on VendorPostingGroup ascending */
  VendorpostinggroupAsc = '_VENDORPOSTINGGROUP_ASC_',
  /** sort on VendorPostingGroup descending */
  VendorpostinggroupDesc = '_VENDORPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on PurchaserCode ascending */
  PurchasercodeAsc = '_PURCHASERCODE_ASC_',
  /** sort on PurchaserCode descending */
  PurchasercodeDesc = '_PURCHASERCODE_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on VendorCrMemoNo ascending */
  VendorcrmemonoAsc = '_VENDORCRMEMONO_ASC_',
  /** sort on VendorCrMemoNo descending */
  VendorcrmemonoDesc = '_VENDORCRMEMONO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromVendorName ascending */
  BuyfromvendornameAsc = '_BUYFROMVENDORNAME_ASC_',
  /** sort on BuyfromVendorName descending */
  BuyfromvendornameDesc = '_BUYFROMVENDORNAME_DESC_',
  /** Sort on BuyfromVendorName2 ascending */
  Buyfromvendorname2Asc = '_BUYFROMVENDORNAME2_ASC_',
  /** sort on BuyfromVendorName2 descending */
  Buyfromvendorname2Desc = '_BUYFROMVENDORNAME2_DESC_',
  /** Sort on BuyfromAddress ascending */
  BuyfromaddressAsc = '_BUYFROMADDRESS_ASC_',
  /** sort on BuyfromAddress descending */
  BuyfromaddressDesc = '_BUYFROMADDRESS_DESC_',
  /** Sort on BuyfromAddress2 ascending */
  Buyfromaddress2Asc = '_BUYFROMADDRESS2_ASC_',
  /** sort on BuyfromAddress2 descending */
  Buyfromaddress2Desc = '_BUYFROMADDRESS2_DESC_',
  /** Sort on BuyfromCity ascending */
  BuyfromcityAsc = '_BUYFROMCITY_ASC_',
  /** sort on BuyfromCity descending */
  BuyfromcityDesc = '_BUYFROMCITY_DESC_',
  /** Sort on BuyfromContact ascending */
  BuyfromcontactAsc = '_BUYFROMCONTACT_ASC_',
  /** sort on BuyfromContact descending */
  BuyfromcontactDesc = '_BUYFROMCONTACT_DESC_',
  /** Sort on PaytoPostCode ascending */
  PaytopostcodeAsc = '_PAYTOPOSTCODE_ASC_',
  /** sort on PaytoPostCode descending */
  PaytopostcodeDesc = '_PAYTOPOSTCODE_DESC_',
  /** Sort on PaytoCounty ascending */
  PaytocountyAsc = '_PAYTOCOUNTY_ASC_',
  /** sort on PaytoCounty descending */
  PaytocountyDesc = '_PAYTOCOUNTY_DESC_',
  /** Sort on PaytoCountryRegionCode ascending */
  PaytocountryregioncodeAsc = '_PAYTOCOUNTRYREGIONCODE_ASC_',
  /** sort on PaytoCountryRegionCode descending */
  PaytocountryregioncodeDesc = '_PAYTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromPostCode ascending */
  BuyfrompostcodeAsc = '_BUYFROMPOSTCODE_ASC_',
  /** sort on BuyfromPostCode descending */
  BuyfrompostcodeDesc = '_BUYFROMPOSTCODE_DESC_',
  /** Sort on BuyfromCounty ascending */
  BuyfromcountyAsc = '_BUYFROMCOUNTY_ASC_',
  /** sort on BuyfromCounty descending */
  BuyfromcountyDesc = '_BUYFROMCOUNTY_DESC_',
  /** Sort on BuyfromCountryRegionCode ascending */
  BuyfromcountryregioncodeAsc = '_BUYFROMCOUNTRYREGIONCODE_ASC_',
  /** sort on BuyfromCountryRegionCode descending */
  BuyfromcountryregioncodeDesc = '_BUYFROMCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on OrderAddressCode ascending */
  OrderaddresscodeAsc = '_ORDERADDRESSCODE_ASC_',
  /** sort on OrderAddressCode descending */
  OrderaddresscodeDesc = '_ORDERADDRESSCODE_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on PreAssignedNoSeries ascending */
  PreassignednoseriesAsc = '_PREASSIGNEDNOSERIES_ASC_',
  /** sort on PreAssignedNoSeries descending */
  PreassignednoseriesDesc = '_PREASSIGNEDNOSERIES_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on PreAssignedNo ascending */
  PreassignednoAsc = '_PREASSIGNEDNO_ASC_',
  /** sort on PreAssignedNo descending */
  PreassignednoDesc = '_PREASSIGNEDNO_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATBaseDiscountPercent ascending */
  VatbasediscountpercentAsc = '_VATBASEDISCOUNTPERCENT_ASC_',
  /** sort on VATBaseDiscountPercent descending */
  VatbasediscountpercentDesc = '_VATBASEDISCOUNTPERCENT_DESC_',
  /** Sort on PrepmtCrMemoNoSeries ascending */
  PrepmtcrmemonoseriesAsc = '_PREPMTCRMEMONOSERIES_ASC_',
  /** sort on PrepmtCrMemoNoSeries descending */
  PrepmtcrmemonoseriesDesc = '_PREPMTCRMEMONOSERIES_DESC_',
  /** Sort on PrepaymentCreditMemo ascending */
  PrepaymentcreditmemoAsc = '_PREPAYMENTCREDITMEMO_ASC_',
  /** sort on PrepaymentCreditMemo descending */
  PrepaymentcreditmemoDesc = '_PREPAYMENTCREDITMEMO_DESC_',
  /** Sort on PrepaymentOrderNo ascending */
  PrepaymentordernoAsc = '_PREPAYMENTORDERNO_ASC_',
  /** sort on PrepaymentOrderNo descending */
  PrepaymentordernoDesc = '_PREPAYMENTORDERNO_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on Paid ascending */
  PaidAsc = '_PAID_ASC_',
  /** sort on Paid descending */
  PaidDesc = '_PAID_DESC_',
  /** Sort on RemainingAmount ascending */
  RemainingamountAsc = '_REMAININGAMOUNT_ASC_',
  /** sort on RemainingAmount descending */
  RemainingamountDesc = '_REMAININGAMOUNT_DESC_',
  /** Sort on VendorLedgerEntryNo ascending */
  VendorledgerentrynoAsc = '_VENDORLEDGERENTRYNO_ASC_',
  /** sort on VendorLedgerEntryNo descending */
  VendorledgerentrynoDesc = '_VENDORLEDGERENTRYNO_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on Cancelled ascending */
  CancelledAsc = '_CANCELLED_ASC_',
  /** sort on Cancelled descending */
  CancelledDesc = '_CANCELLED_DESC_',
  /** Sort on Corrective ascending */
  CorrectiveAsc = '_CORRECTIVE_ASC_',
  /** sort on Corrective descending */
  CorrectiveDesc = '_CORRECTIVE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on BuyfromContactNo ascending */
  BuyfromcontactnoAsc = '_BUYFROMCONTACTNO_ASC_',
  /** sort on BuyfromContactNo descending */
  BuyfromcontactnoDesc = '_BUYFROMCONTACTNO_DESC_',
  /** Sort on PaytoContactNo ascending */
  PaytocontactnoAsc = '_PAYTOCONTACTNO_ASC_',
  /** sort on PaytoContactNo descending */
  PaytocontactnoDesc = '_PAYTOCONTACTNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on ReturnOrderNo ascending */
  ReturnordernoAsc = '_RETURNORDERNO_ASC_',
  /** sort on ReturnOrderNo descending */
  ReturnordernoDesc = '_RETURNORDERNO_DESC_',
  /** Sort on ReturnOrderNoSeries ascending */
  ReturnordernoseriesAsc = '_RETURNORDERNOSERIES_ASC_',
  /** sort on ReturnOrderNoSeries descending */
  ReturnordernoseriesDesc = '_RETURNORDERNOSERIES_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on PEBNoteofGoods ascending */
  PebnoteofgoodsAsc = '_PEBNOTEOFGOODS_ASC_',
  /** sort on PEBNoteofGoods descending */
  PebnoteofgoodsDesc = '_PEBNOTEOFGOODS_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMObjectPurchase ascending */
  EqmobjectpurchaseAsc = '_EQMOBJECTPURCHASE_ASC_',
  /** sort on EQMObjectPurchase descending */
  EqmobjectpurchaseDesc = '_EQMOBJECTPURCHASE_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceTermNo ascending */
  EqmservicetermnoAsc = '_EQMSERVICETERMNO_ASC_',
  /** sort on EQMServiceTermNo descending */
  EqmservicetermnoDesc = '_EQMSERVICETERMNO_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMServiceStatusOPTION ascending */
  EqmservicestatusoptionAsc = '_EQMSERVICESTATUSOPTION_ASC_',
  /** sort on EQMServiceStatusOPTION descending */
  EqmservicestatusoptionDesc = '_EQMSERVICESTATUSOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchCrMemoLineInput {
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on DirectUnitCost ascending */
  DirectunitcostAsc = '_DIRECTUNITCOST_ASC_',
  /** sort on DirectUnitCost descending */
  DirectunitcostDesc = '_DIRECTUNITCOST_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VATPercent ascending */
  VatpercentAsc = '_VATPERCENT_ASC_',
  /** sort on VATPercent descending */
  VatpercentDesc = '_VATPERCENT_DESC_',
  /** Sort on LineDiscountPercent ascending */
  LinediscountpercentAsc = '_LINEDISCOUNTPERCENT_ASC_',
  /** sort on LineDiscountPercent descending */
  LinediscountpercentDesc = '_LINEDISCOUNTPERCENT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on UnitPriceLCY ascending */
  UnitpricelcyAsc = '_UNITPRICELCY_ASC_',
  /** sort on UnitPriceLCY descending */
  UnitpricelcyDesc = '_UNITPRICELCY_DESC_',
  /** Sort on AllowCrMemooiceDisc ascending */
  AllowcrmemooicediscAsc = '_ALLOWCRMEMOOICEDISC_ASC_',
  /** sort on AllowCrMemooiceDisc descending */
  AllowcrmemooicediscDesc = '_ALLOWCRMEMOOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on IndirectCostPercent ascending */
  IndirectcostpercentAsc = '_INDIRECTCOSTPERCENT_ASC_',
  /** sort on IndirectCostPercent descending */
  IndirectcostpercentDesc = '_INDIRECTCOSTPERCENT_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on CrMemoDiscountAmount ascending */
  CrmemodiscountamountAsc = '_CRMEMODISCOUNTAMOUNT_ASC_',
  /** sort on CrMemoDiscountAmount descending */
  CrmemodiscountamountDesc = '_CRMEMODISCOUNTAMOUNT_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on UseTax ascending */
  UsetaxAsc = '_USETAX_ASC_',
  /** sort on UseTax descending */
  UsetaxDesc = '_USETAX_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ICItemReferenceNo ascending */
  IcitemreferencenoAsc = '_ICITEMREFERENCENO_ASC_',
  /** sort on ICItemReferenceNo descending */
  IcitemreferencenoDesc = '_ICITEMREFERENCENO_DESC_',
  /** Sort on PmtDiscountAmount ascending */
  PmtdiscountamountAsc = '_PMTDISCOUNTAMOUNT_ASC_',
  /** sort on PmtDiscountAmount descending */
  PmtdiscountamountDesc = '_PMTDISCOUNTAMOUNT_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobLineType ascending */
  JoblinetypeAsc = '_JOBLINETYPE_ASC_',
  /** sort on JobLineType descending */
  JoblinetypeDesc = '_JOBLINETYPE_DESC_',
  /** Sort on JobLineTypeOPTION ascending */
  JoblinetypeoptionAsc = '_JOBLINETYPEOPTION_ASC_',
  /** sort on JobLineTypeOPTION descending */
  JoblinetypeoptionDesc = '_JOBLINETYPEOPTION_DESC_',
  /** Sort on JobUnitPrice ascending */
  JobunitpriceAsc = '_JOBUNITPRICE_ASC_',
  /** sort on JobUnitPrice descending */
  JobunitpriceDesc = '_JOBUNITPRICE_DESC_',
  /** Sort on JobTotalPrice ascending */
  JobtotalpriceAsc = '_JOBTOTALPRICE_ASC_',
  /** sort on JobTotalPrice descending */
  JobtotalpriceDesc = '_JOBTOTALPRICE_DESC_',
  /** Sort on JobLineAmount ascending */
  JoblineamountAsc = '_JOBLINEAMOUNT_ASC_',
  /** sort on JobLineAmount descending */
  JoblineamountDesc = '_JOBLINEAMOUNT_DESC_',
  /** Sort on JobLineDiscountAmount ascending */
  JoblinediscountamountAsc = '_JOBLINEDISCOUNTAMOUNT_ASC_',
  /** sort on JobLineDiscountAmount descending */
  JoblinediscountamountDesc = '_JOBLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on JobLineDiscountPercent ascending */
  JoblinediscountpercentAsc = '_JOBLINEDISCOUNTPERCENT_ASC_',
  /** sort on JobLineDiscountPercent descending */
  JoblinediscountpercentDesc = '_JOBLINEDISCOUNTPERCENT_DESC_',
  /** Sort on JobUnitPriceLCY ascending */
  JobunitpricelcyAsc = '_JOBUNITPRICELCY_ASC_',
  /** sort on JobUnitPriceLCY descending */
  JobunitpricelcyDesc = '_JOBUNITPRICELCY_DESC_',
  /** Sort on JobTotalPriceLCY ascending */
  JobtotalpricelcyAsc = '_JOBTOTALPRICELCY_ASC_',
  /** sort on JobTotalPriceLCY descending */
  JobtotalpricelcyDesc = '_JOBTOTALPRICELCY_DESC_',
  /** Sort on JobLineAmountLCY ascending */
  JoblineamountlcyAsc = '_JOBLINEAMOUNTLCY_ASC_',
  /** sort on JobLineAmountLCY descending */
  JoblineamountlcyDesc = '_JOBLINEAMOUNTLCY_DESC_',
  /** Sort on JobLineDiscAmountLCY ascending */
  JoblinediscamountlcyAsc = '_JOBLINEDISCAMOUNTLCY_ASC_',
  /** sort on JobLineDiscAmountLCY descending */
  JoblinediscamountlcyDesc = '_JOBLINEDISCAMOUNTLCY_DESC_',
  /** Sort on JobCurrencyFactor ascending */
  JobcurrencyfactorAsc = '_JOBCURRENCYFACTOR_ASC_',
  /** sort on JobCurrencyFactor descending */
  JobcurrencyfactorDesc = '_JOBCURRENCYFACTOR_DESC_',
  /** Sort on JobCurrencyCode ascending */
  JobcurrencycodeAsc = '_JOBCURRENCYCODE_ASC_',
  /** sort on JobCurrencyCode descending */
  JobcurrencycodeDesc = '_JOBCURRENCYCODE_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on ProdOrderNo ascending */
  ProdordernoAsc = '_PRODORDERNO_ASC_',
  /** sort on ProdOrderNo descending */
  ProdordernoDesc = '_PRODORDERNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on FAPostingType ascending */
  FapostingtypeAsc = '_FAPOSTINGTYPE_ASC_',
  /** sort on FAPostingType descending */
  FapostingtypeDesc = '_FAPOSTINGTYPE_DESC_',
  /** Sort on FAPostingTypeOPTION ascending */
  FapostingtypeoptionAsc = '_FAPOSTINGTYPEOPTION_ASC_',
  /** sort on FAPostingTypeOPTION descending */
  FapostingtypeoptionDesc = '_FAPOSTINGTYPEOPTION_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on SalvageValue ascending */
  SalvagevalueAsc = '_SALVAGEVALUE_ASC_',
  /** sort on SalvageValue descending */
  SalvagevalueDesc = '_SALVAGEVALUE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DeprAcquisitionCost ascending */
  DepracquisitioncostAsc = '_DEPRACQUISITIONCOST_ASC_',
  /** sort on DeprAcquisitionCost descending */
  DepracquisitioncostDesc = '_DEPRACQUISITIONCOST_DESC_',
  /** Sort on MaintenanceCode ascending */
  MaintenancecodeAsc = '_MAINTENANCECODE_ASC_',
  /** sort on MaintenanceCode descending */
  MaintenancecodeDesc = '_MAINTENANCECODE_DESC_',
  /** Sort on InsuranceNo ascending */
  InsurancenoAsc = '_INSURANCENO_ASC_',
  /** sort on InsuranceNo descending */
  InsurancenoDesc = '_INSURANCENO_DESC_',
  /** Sort on BudgetedFANo ascending */
  BudgetedfanoAsc = '_BUDGETEDFANO_ASC_',
  /** sort on BudgetedFANo descending */
  BudgetedfanoDesc = '_BUDGETEDFANO_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ItemReferenceNo ascending */
  ItemreferencenoAsc = '_ITEMREFERENCENO_ASC_',
  /** sort on ItemReferenceNo descending */
  ItemreferencenoDesc = '_ITEMREFERENCENO_DESC_',
  /** Sort on ItemReferenceUnitofMeasure ascending */
  ItemreferenceunitofmeasureAsc = '_ITEMREFERENCEUNITOFMEASURE_ASC_',
  /** sort on ItemReferenceUnitofMeasure descending */
  ItemreferenceunitofmeasureDesc = '_ITEMREFERENCEUNITOFMEASURE_DESC_',
  /** Sort on ItemReferenceType ascending */
  ItemreferencetypeAsc = '_ITEMREFERENCETYPE_ASC_',
  /** sort on ItemReferenceType descending */
  ItemreferencetypeDesc = '_ITEMREFERENCETYPE_DESC_',
  /** Sort on ItemReferenceTypeOPTION ascending */
  ItemreferencetypeoptionAsc = '_ITEMREFERENCETYPEOPTION_ASC_',
  /** sort on ItemReferenceTypeOPTION descending */
  ItemreferencetypeoptionDesc = '_ITEMREFERENCETYPEOPTION_DESC_',
  /** Sort on ItemReferenceTypeNo ascending */
  ItemreferencetypenoAsc = '_ITEMREFERENCETYPENO_ASC_',
  /** sort on ItemReferenceTypeNo descending */
  ItemreferencetypenoDesc = '_ITEMREFERENCETYPENO_DESC_',
  /** Sort on ReturnShipmentNo ascending */
  ReturnshipmentnoAsc = '_RETURNSHIPMENTNO_ASC_',
  /** sort on ReturnShipmentNo descending */
  ReturnshipmentnoDesc = '_RETURNSHIPMENTNO_DESC_',
  /** Sort on ReturnShipmentLineNo ascending */
  ReturnshipmentlinenoAsc = '_RETURNSHIPMENTLINENO_ASC_',
  /** sort on ReturnShipmentLineNo descending */
  ReturnshipmentlinenoDesc = '_RETURNSHIPMENTLINENO_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on PEBChargeType ascending */
  PebchargetypeAsc = '_PEBCHARGETYPE_ASC_',
  /** sort on PEBChargeType descending */
  PebchargetypeDesc = '_PEBCHARGETYPE_DESC_',
  /** Sort on PEBChargeTypeOPTION ascending */
  PebchargetypeoptionAsc = '_PEBCHARGETYPEOPTION_ASC_',
  /** sort on PEBChargeTypeOPTION descending */
  PebchargetypeoptionDesc = '_PEBCHARGETYPEOPTION_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMObjectCostAssignment ascending */
  EqmobjectcostassignmentAsc = '_EQMOBJECTCOSTASSIGNMENT_ASC_',
  /** sort on EQMObjectCostAssignment descending */
  EqmobjectcostassignmentDesc = '_EQMOBJECTCOSTASSIGNMENT_DESC_',
  /** Sort on EQMFixedAssetSetupGroup ascending */
  EqmfixedassetsetupgroupAsc = '_EQMFIXEDASSETSETUPGROUP_ASC_',
  /** sort on EQMFixedAssetSetupGroup descending */
  EqmfixedassetsetupgroupDesc = '_EQMFIXEDASSETSETUPGROUP_DESC_',
  /** Sort on EQMFixedAssetNo ascending */
  EqmfixedassetnoAsc = '_EQMFIXEDASSETNO_ASC_',
  /** sort on EQMFixedAssetNo descending */
  EqmfixedassetnoDesc = '_EQMFIXEDASSETNO_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMReRentNo ascending */
  EqmrerentnoAsc = '_EQMRERENTNO_ASC_',
  /** sort on EQMReRentNo descending */
  EqmrerentnoDesc = '_EQMRERENTNO_DESC_',
  /** Sort on EQMSerialNo ascending */
  EqmserialnoAsc = '_EQMSERIALNO_ASC_',
  /** sort on EQMSerialNo descending */
  EqmserialnoDesc = '_EQMSERIALNO_DESC_',
  /** Sort on EQMObjectBinCode ascending */
  EqmobjectbincodeAsc = '_EQMOBJECTBINCODE_ASC_',
  /** sort on EQMObjectBinCode descending */
  EqmobjectbincodeDesc = '_EQMOBJECTBINCODE_DESC_',
  /** Sort on EQMManufacturerCode ascending */
  EqmmanufacturercodeAsc = '_EQMMANUFACTURERCODE_ASC_',
  /** sort on EQMManufacturerCode descending */
  EqmmanufacturercodeDesc = '_EQMMANUFACTURERCODE_DESC_',
  /** Sort on EQMManufacturerModelCode ascending */
  EqmmanufacturermodelcodeAsc = '_EQMMANUFACTURERMODELCODE_ASC_',
  /** sort on EQMManufacturerModelCode descending */
  EqmmanufacturermodelcodeDesc = '_EQMMANUFACTURERMODELCODE_DESC_',
  /** Sort on ExternalLineNo ascending */
  ExternallinenoAsc = '_EXTERNALLINENO_ASC_',
  /** sort on ExternalLineNo descending */
  ExternallinenoDesc = '_EXTERNALLINENO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchInvHeaderInput {
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on PaytoName ascending */
  PaytonameAsc = '_PAYTONAME_ASC_',
  /** sort on PaytoName descending */
  PaytonameDesc = '_PAYTONAME_DESC_',
  /** Sort on PaytoName2 ascending */
  Paytoname2Asc = '_PAYTONAME2_ASC_',
  /** sort on PaytoName2 descending */
  Paytoname2Desc = '_PAYTONAME2_DESC_',
  /** Sort on PaytoAddress ascending */
  PaytoaddressAsc = '_PAYTOADDRESS_ASC_',
  /** sort on PaytoAddress descending */
  PaytoaddressDesc = '_PAYTOADDRESS_DESC_',
  /** Sort on PaytoAddress2 ascending */
  Paytoaddress2Asc = '_PAYTOADDRESS2_ASC_',
  /** sort on PaytoAddress2 descending */
  Paytoaddress2Desc = '_PAYTOADDRESS2_DESC_',
  /** Sort on PaytoCity ascending */
  PaytocityAsc = '_PAYTOCITY_ASC_',
  /** sort on PaytoCity descending */
  PaytocityDesc = '_PAYTOCITY_DESC_',
  /** Sort on PaytoContact ascending */
  PaytocontactAsc = '_PAYTOCONTACT_ASC_',
  /** sort on PaytoContact descending */
  PaytocontactDesc = '_PAYTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscountPercent ascending */
  PaymentdiscountpercentAsc = '_PAYMENTDISCOUNTPERCENT_ASC_',
  /** sort on PaymentDiscountPercent descending */
  PaymentdiscountpercentDesc = '_PAYMENTDISCOUNTPERCENT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on VendorPostingGroup ascending */
  VendorpostinggroupAsc = '_VENDORPOSTINGGROUP_ASC_',
  /** sort on VendorPostingGroup descending */
  VendorpostinggroupDesc = '_VENDORPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on PurchaserCode ascending */
  PurchasercodeAsc = '_PURCHASERCODE_ASC_',
  /** sort on PurchaserCode descending */
  PurchasercodeDesc = '_PURCHASERCODE_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on VendorOrderNo ascending */
  VendorordernoAsc = '_VENDORORDERNO_ASC_',
  /** sort on VendorOrderNo descending */
  VendorordernoDesc = '_VENDORORDERNO_DESC_',
  /** Sort on VendorInvoiceNo ascending */
  VendorinvoicenoAsc = '_VENDORINVOICENO_ASC_',
  /** sort on VendorInvoiceNo descending */
  VendorinvoicenoDesc = '_VENDORINVOICENO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromVendorName ascending */
  BuyfromvendornameAsc = '_BUYFROMVENDORNAME_ASC_',
  /** sort on BuyfromVendorName descending */
  BuyfromvendornameDesc = '_BUYFROMVENDORNAME_DESC_',
  /** Sort on BuyfromVendorName2 ascending */
  Buyfromvendorname2Asc = '_BUYFROMVENDORNAME2_ASC_',
  /** sort on BuyfromVendorName2 descending */
  Buyfromvendorname2Desc = '_BUYFROMVENDORNAME2_DESC_',
  /** Sort on BuyfromAddress ascending */
  BuyfromaddressAsc = '_BUYFROMADDRESS_ASC_',
  /** sort on BuyfromAddress descending */
  BuyfromaddressDesc = '_BUYFROMADDRESS_DESC_',
  /** Sort on BuyfromAddress2 ascending */
  Buyfromaddress2Asc = '_BUYFROMADDRESS2_ASC_',
  /** sort on BuyfromAddress2 descending */
  Buyfromaddress2Desc = '_BUYFROMADDRESS2_DESC_',
  /** Sort on BuyfromCity ascending */
  BuyfromcityAsc = '_BUYFROMCITY_ASC_',
  /** sort on BuyfromCity descending */
  BuyfromcityDesc = '_BUYFROMCITY_DESC_',
  /** Sort on BuyfromContact ascending */
  BuyfromcontactAsc = '_BUYFROMCONTACT_ASC_',
  /** sort on BuyfromContact descending */
  BuyfromcontactDesc = '_BUYFROMCONTACT_DESC_',
  /** Sort on PaytoPostCode ascending */
  PaytopostcodeAsc = '_PAYTOPOSTCODE_ASC_',
  /** sort on PaytoPostCode descending */
  PaytopostcodeDesc = '_PAYTOPOSTCODE_DESC_',
  /** Sort on PaytoCounty ascending */
  PaytocountyAsc = '_PAYTOCOUNTY_ASC_',
  /** sort on PaytoCounty descending */
  PaytocountyDesc = '_PAYTOCOUNTY_DESC_',
  /** Sort on PaytoCountryRegionCode ascending */
  PaytocountryregioncodeAsc = '_PAYTOCOUNTRYREGIONCODE_ASC_',
  /** sort on PaytoCountryRegionCode descending */
  PaytocountryregioncodeDesc = '_PAYTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromPostCode ascending */
  BuyfrompostcodeAsc = '_BUYFROMPOSTCODE_ASC_',
  /** sort on BuyfromPostCode descending */
  BuyfrompostcodeDesc = '_BUYFROMPOSTCODE_DESC_',
  /** Sort on BuyfromCounty ascending */
  BuyfromcountyAsc = '_BUYFROMCOUNTY_ASC_',
  /** sort on BuyfromCounty descending */
  BuyfromcountyDesc = '_BUYFROMCOUNTY_DESC_',
  /** Sort on BuyfromCountryRegionCode ascending */
  BuyfromcountryregioncodeAsc = '_BUYFROMCOUNTRYREGIONCODE_ASC_',
  /** sort on BuyfromCountryRegionCode descending */
  BuyfromcountryregioncodeDesc = '_BUYFROMCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on OrderAddressCode ascending */
  OrderaddresscodeAsc = '_ORDERADDRESSCODE_ASC_',
  /** sort on OrderAddressCode descending */
  OrderaddresscodeDesc = '_ORDERADDRESSCODE_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on PreAssignedNoSeries ascending */
  PreassignednoseriesAsc = '_PREASSIGNEDNOSERIES_ASC_',
  /** sort on PreAssignedNoSeries descending */
  PreassignednoseriesDesc = '_PREASSIGNEDNOSERIES_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on OrderNoSeries ascending */
  OrdernoseriesAsc = '_ORDERNOSERIES_ASC_',
  /** sort on OrderNoSeries descending */
  OrdernoseriesDesc = '_ORDERNOSERIES_DESC_',
  /** Sort on PreAssignedNo ascending */
  PreassignednoAsc = '_PREASSIGNEDNO_ASC_',
  /** sort on PreAssignedNo descending */
  PreassignednoDesc = '_PREASSIGNEDNO_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATBaseDiscountPercent ascending */
  VatbasediscountpercentAsc = '_VATBASEDISCOUNTPERCENT_ASC_',
  /** sort on VATBaseDiscountPercent descending */
  VatbasediscountpercentDesc = '_VATBASEDISCOUNTPERCENT_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on PrepaymentInvoice ascending */
  PrepaymentinvoiceAsc = '_PREPAYMENTINVOICE_ASC_',
  /** sort on PrepaymentInvoice descending */
  PrepaymentinvoiceDesc = '_PREPAYMENTINVOICE_DESC_',
  /** Sort on PrepaymentOrderNo ascending */
  PrepaymentordernoAsc = '_PREPAYMENTORDERNO_ASC_',
  /** sort on PrepaymentOrderNo descending */
  PrepaymentordernoDesc = '_PREPAYMENTORDERNO_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on CreditorNo ascending */
  CreditornoAsc = '_CREDITORNO_ASC_',
  /** sort on CreditorNo descending */
  CreditornoDesc = '_CREDITORNO_DESC_',
  /** Sort on PaymentReference ascending */
  PaymentreferenceAsc = '_PAYMENTREFERENCE_ASC_',
  /** sort on PaymentReference descending */
  PaymentreferenceDesc = '_PAYMENTREFERENCE_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on Closed ascending */
  ClosedAsc = '_CLOSED_ASC_',
  /** sort on Closed descending */
  ClosedDesc = '_CLOSED_DESC_',
  /** Sort on RemainingAmount ascending */
  RemainingamountAsc = '_REMAININGAMOUNT_ASC_',
  /** sort on RemainingAmount descending */
  RemainingamountDesc = '_REMAININGAMOUNT_DESC_',
  /** Sort on VendorLedgerEntryNo ascending */
  VendorledgerentrynoAsc = '_VENDORLEDGERENTRYNO_ASC_',
  /** sort on VendorLedgerEntryNo descending */
  VendorledgerentrynoDesc = '_VENDORLEDGERENTRYNO_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on Cancelled ascending */
  CancelledAsc = '_CANCELLED_ASC_',
  /** sort on Cancelled descending */
  CancelledDesc = '_CANCELLED_DESC_',
  /** Sort on Corrective ascending */
  CorrectiveAsc = '_CORRECTIVE_ASC_',
  /** sort on Corrective descending */
  CorrectiveDesc = '_CORRECTIVE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on BuyfromContactNo ascending */
  BuyfromcontactnoAsc = '_BUYFROMCONTACTNO_ASC_',
  /** sort on BuyfromContactNo descending */
  BuyfromcontactnoDesc = '_BUYFROMCONTACTNO_DESC_',
  /** Sort on PaytoContactNo ascending */
  PaytocontactnoAsc = '_PAYTOCONTACTNO_ASC_',
  /** sort on PaytoContactNo descending */
  PaytocontactnoDesc = '_PAYTOCONTACTNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on DraftInvoiceSystemId ascending */
  DraftinvoicesystemidAsc = '_DRAFTINVOICESYSTEMID_ASC_',
  /** sort on DraftInvoiceSystemId descending */
  DraftinvoicesystemidDesc = '_DRAFTINVOICESYSTEMID_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on PEBNoteofGoods ascending */
  PebnoteofgoodsAsc = '_PEBNOTEOFGOODS_ASC_',
  /** sort on PEBNoteofGoods descending */
  PebnoteofgoodsDesc = '_PEBNOTEOFGOODS_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMObjectPurchase ascending */
  EqmobjectpurchaseAsc = '_EQMOBJECTPURCHASE_ASC_',
  /** sort on EQMObjectPurchase descending */
  EqmobjectpurchaseDesc = '_EQMOBJECTPURCHASE_DESC_',
  /** Sort on EQMWorkshopDocument ascending */
  EqmworkshopdocumentAsc = '_EQMWORKSHOPDOCUMENT_ASC_',
  /** sort on EQMWorkshopDocument descending */
  EqmworkshopdocumentDesc = '_EQMWORKSHOPDOCUMENT_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceTermNo ascending */
  EqmservicetermnoAsc = '_EQMSERVICETERMNO_ASC_',
  /** sort on EQMServiceTermNo descending */
  EqmservicetermnoDesc = '_EQMSERVICETERMNO_DESC_',
  /** Sort on EQMWSObjectCounter ascending */
  EqmwsobjectcounterAsc = '_EQMWSOBJECTCOUNTER_ASC_',
  /** sort on EQMWSObjectCounter descending */
  EqmwsobjectcounterDesc = '_EQMWSOBJECTCOUNTER_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMServiceStartDate ascending */
  EqmservicestartdateAsc = '_EQMSERVICESTARTDATE_ASC_',
  /** sort on EQMServiceStartDate descending */
  EqmservicestartdateDesc = '_EQMSERVICESTARTDATE_DESC_',
  /** Sort on EQMServiceEndDate ascending */
  EqmserviceenddateAsc = '_EQMSERVICEENDDATE_ASC_',
  /** sort on EQMServiceEndDate descending */
  EqmserviceenddateDesc = '_EQMSERVICEENDDATE_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMServiceStatusOPTION ascending */
  EqmservicestatusoptionAsc = '_EQMSERVICESTATUSOPTION_ASC_',
  /** sort on EQMServiceStatusOPTION descending */
  EqmservicestatusoptionDesc = '_EQMSERVICESTATUSOPTION_DESC_',
  /** Sort on EQMObjectWarrantyDate ascending */
  EqmobjectwarrantydateAsc = '_EQMOBJECTWARRANTYDATE_ASC_',
  /** sort on EQMObjectWarrantyDate descending */
  EqmobjectwarrantydateDesc = '_EQMOBJECTWARRANTYDATE_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMWSEstimatedStartDate ascending */
  EqmwsestimatedstartdateAsc = '_EQMWSESTIMATEDSTARTDATE_ASC_',
  /** sort on EQMWSEstimatedStartDate descending */
  EqmwsestimatedstartdateDesc = '_EQMWSESTIMATEDSTARTDATE_DESC_',
  /** Sort on EQMWSEstimatedStartTime ascending */
  EqmwsestimatedstarttimeAsc = '_EQMWSESTIMATEDSTARTTIME_ASC_',
  /** sort on EQMWSEstimatedStartTime descending */
  EqmwsestimatedstarttimeDesc = '_EQMWSESTIMATEDSTARTTIME_DESC_',
  /** Sort on EQMWSEstimatedEndDate ascending */
  EqmwsestimatedenddateAsc = '_EQMWSESTIMATEDENDDATE_ASC_',
  /** sort on EQMWSEstimatedEndDate descending */
  EqmwsestimatedenddateDesc = '_EQMWSESTIMATEDENDDATE_DESC_',
  /** Sort on EQMWSEstimatedEndTime ascending */
  EqmwsestimatedendtimeAsc = '_EQMWSESTIMATEDENDTIME_ASC_',
  /** sort on EQMWSEstimatedEndTime descending */
  EqmwsestimatedendtimeDesc = '_EQMWSESTIMATEDENDTIME_DESC_',
  /** Sort on EQMerviceStartTime ascending */
  EqmervicestarttimeAsc = '_EQMERVICESTARTTIME_ASC_',
  /** sort on EQMerviceStartTime descending */
  EqmervicestarttimeDesc = '_EQMERVICESTARTTIME_DESC_',
  /** Sort on EQMServiceEndTime ascending */
  EqmserviceendtimeAsc = '_EQMSERVICEENDTIME_ASC_',
  /** sort on EQMServiceEndTime descending */
  EqmserviceendtimeDesc = '_EQMSERVICEENDTIME_DESC_',
  /** Sort on EQMObjWorkPurchFlow ascending */
  EqmobjworkpurchflowAsc = '_EQMOBJWORKPURCHFLOW_ASC_',
  /** sort on EQMObjWorkPurchFlow descending */
  EqmobjworkpurchflowDesc = '_EQMOBJWORKPURCHFLOW_DESC_',
  /** Sort on EQMWSObjectCounter2 ascending */
  Eqmwsobjectcounter2Asc = '_EQMWSOBJECTCOUNTER2_ASC_',
  /** sort on EQMWSObjectCounter2 descending */
  Eqmwsobjectcounter2Desc = '_EQMWSOBJECTCOUNTER2_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchInvLineInput {
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on DirectUnitCost ascending */
  DirectunitcostAsc = '_DIRECTUNITCOST_ASC_',
  /** sort on DirectUnitCost descending */
  DirectunitcostDesc = '_DIRECTUNITCOST_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VATPercent ascending */
  VatpercentAsc = '_VATPERCENT_ASC_',
  /** sort on VATPercent descending */
  VatpercentDesc = '_VATPERCENT_DESC_',
  /** Sort on LineDiscountPercent ascending */
  LinediscountpercentAsc = '_LINEDISCOUNTPERCENT_ASC_',
  /** sort on LineDiscountPercent descending */
  LinediscountpercentDesc = '_LINEDISCOUNTPERCENT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on UnitPriceLCY ascending */
  UnitpricelcyAsc = '_UNITPRICELCY_ASC_',
  /** sort on UnitPriceLCY descending */
  UnitpricelcyDesc = '_UNITPRICELCY_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on IndirectCostPercent ascending */
  IndirectcostpercentAsc = '_INDIRECTCOSTPERCENT_ASC_',
  /** sort on IndirectCostPercent descending */
  IndirectcostpercentDesc = '_INDIRECTCOSTPERCENT_DESC_',
  /** Sort on ReceiptNo ascending */
  ReceiptnoAsc = '_RECEIPTNO_ASC_',
  /** sort on ReceiptNo descending */
  ReceiptnoDesc = '_RECEIPTNO_DESC_',
  /** Sort on ReceiptLineNo ascending */
  ReceiptlinenoAsc = '_RECEIPTLINENO_ASC_',
  /** sort on ReceiptLineNo descending */
  ReceiptlinenoDesc = '_RECEIPTLINENO_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on InvDiscountAmount ascending */
  InvdiscountamountAsc = '_INVDISCOUNTAMOUNT_ASC_',
  /** sort on InvDiscountAmount descending */
  InvdiscountamountDesc = '_INVDISCOUNTAMOUNT_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on UseTax ascending */
  UsetaxAsc = '_USETAX_ASC_',
  /** sort on UseTax descending */
  UsetaxDesc = '_USETAX_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ICCrossReferenceNo ascending */
  IccrossreferencenoAsc = '_ICCROSSREFERENCENO_ASC_',
  /** sort on ICCrossReferenceNo descending */
  IccrossreferencenoDesc = '_ICCROSSREFERENCENO_DESC_',
  /** Sort on PmtDiscountAmount ascending */
  PmtdiscountamountAsc = '_PMTDISCOUNTAMOUNT_ASC_',
  /** sort on PmtDiscountAmount descending */
  PmtdiscountamountDesc = '_PMTDISCOUNTAMOUNT_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobLineType ascending */
  JoblinetypeAsc = '_JOBLINETYPE_ASC_',
  /** sort on JobLineType descending */
  JoblinetypeDesc = '_JOBLINETYPE_DESC_',
  /** Sort on JobLineTypeOPTION ascending */
  JoblinetypeoptionAsc = '_JOBLINETYPEOPTION_ASC_',
  /** sort on JobLineTypeOPTION descending */
  JoblinetypeoptionDesc = '_JOBLINETYPEOPTION_DESC_',
  /** Sort on JobUnitPrice ascending */
  JobunitpriceAsc = '_JOBUNITPRICE_ASC_',
  /** sort on JobUnitPrice descending */
  JobunitpriceDesc = '_JOBUNITPRICE_DESC_',
  /** Sort on JobTotalPrice ascending */
  JobtotalpriceAsc = '_JOBTOTALPRICE_ASC_',
  /** sort on JobTotalPrice descending */
  JobtotalpriceDesc = '_JOBTOTALPRICE_DESC_',
  /** Sort on JobLineAmount ascending */
  JoblineamountAsc = '_JOBLINEAMOUNT_ASC_',
  /** sort on JobLineAmount descending */
  JoblineamountDesc = '_JOBLINEAMOUNT_DESC_',
  /** Sort on JobLineDiscountAmount ascending */
  JoblinediscountamountAsc = '_JOBLINEDISCOUNTAMOUNT_ASC_',
  /** sort on JobLineDiscountAmount descending */
  JoblinediscountamountDesc = '_JOBLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on JobLineDiscountPercent ascending */
  JoblinediscountpercentAsc = '_JOBLINEDISCOUNTPERCENT_ASC_',
  /** sort on JobLineDiscountPercent descending */
  JoblinediscountpercentDesc = '_JOBLINEDISCOUNTPERCENT_DESC_',
  /** Sort on JobUnitPriceLCY ascending */
  JobunitpricelcyAsc = '_JOBUNITPRICELCY_ASC_',
  /** sort on JobUnitPriceLCY descending */
  JobunitpricelcyDesc = '_JOBUNITPRICELCY_DESC_',
  /** Sort on JobTotalPriceLCY ascending */
  JobtotalpricelcyAsc = '_JOBTOTALPRICELCY_ASC_',
  /** sort on JobTotalPriceLCY descending */
  JobtotalpricelcyDesc = '_JOBTOTALPRICELCY_DESC_',
  /** Sort on JobLineAmountLCY ascending */
  JoblineamountlcyAsc = '_JOBLINEAMOUNTLCY_ASC_',
  /** sort on JobLineAmountLCY descending */
  JoblineamountlcyDesc = '_JOBLINEAMOUNTLCY_DESC_',
  /** Sort on JobLineDiscAmountLCY ascending */
  JoblinediscamountlcyAsc = '_JOBLINEDISCAMOUNTLCY_ASC_',
  /** sort on JobLineDiscAmountLCY descending */
  JoblinediscamountlcyDesc = '_JOBLINEDISCAMOUNTLCY_DESC_',
  /** Sort on JobCurrencyFactor ascending */
  JobcurrencyfactorAsc = '_JOBCURRENCYFACTOR_ASC_',
  /** sort on JobCurrencyFactor descending */
  JobcurrencyfactorDesc = '_JOBCURRENCYFACTOR_DESC_',
  /** Sort on JobCurrencyCode ascending */
  JobcurrencycodeAsc = '_JOBCURRENCYCODE_ASC_',
  /** sort on JobCurrencyCode descending */
  JobcurrencycodeDesc = '_JOBCURRENCYCODE_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on ProdOrderNo ascending */
  ProdordernoAsc = '_PRODORDERNO_ASC_',
  /** sort on ProdOrderNo descending */
  ProdordernoDesc = '_PRODORDERNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on FAPostingType ascending */
  FapostingtypeAsc = '_FAPOSTINGTYPE_ASC_',
  /** sort on FAPostingType descending */
  FapostingtypeDesc = '_FAPOSTINGTYPE_DESC_',
  /** Sort on FAPostingTypeOPTION ascending */
  FapostingtypeoptionAsc = '_FAPOSTINGTYPEOPTION_ASC_',
  /** sort on FAPostingTypeOPTION descending */
  FapostingtypeoptionDesc = '_FAPOSTINGTYPEOPTION_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on SalvageValue ascending */
  SalvagevalueAsc = '_SALVAGEVALUE_ASC_',
  /** sort on SalvageValue descending */
  SalvagevalueDesc = '_SALVAGEVALUE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DeprAcquisitionCost ascending */
  DepracquisitioncostAsc = '_DEPRACQUISITIONCOST_ASC_',
  /** sort on DeprAcquisitionCost descending */
  DepracquisitioncostDesc = '_DEPRACQUISITIONCOST_DESC_',
  /** Sort on MaintenanceCode ascending */
  MaintenancecodeAsc = '_MAINTENANCECODE_ASC_',
  /** sort on MaintenanceCode descending */
  MaintenancecodeDesc = '_MAINTENANCECODE_DESC_',
  /** Sort on InsuranceNo ascending */
  InsurancenoAsc = '_INSURANCENO_ASC_',
  /** sort on InsuranceNo descending */
  InsurancenoDesc = '_INSURANCENO_DESC_',
  /** Sort on BudgetedFANo ascending */
  BudgetedfanoAsc = '_BUDGETEDFANO_ASC_',
  /** sort on BudgetedFANo descending */
  BudgetedfanoDesc = '_BUDGETEDFANO_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on ItemReferenceNo ascending */
  ItemreferencenoAsc = '_ITEMREFERENCENO_ASC_',
  /** sort on ItemReferenceNo descending */
  ItemreferencenoDesc = '_ITEMREFERENCENO_DESC_',
  /** Sort on ItemReferenceUnitofMeasure ascending */
  ItemreferenceunitofmeasureAsc = '_ITEMREFERENCEUNITOFMEASURE_ASC_',
  /** sort on ItemReferenceUnitofMeasure descending */
  ItemreferenceunitofmeasureDesc = '_ITEMREFERENCEUNITOFMEASURE_DESC_',
  /** Sort on ItemReferenceType ascending */
  ItemreferencetypeAsc = '_ITEMREFERENCETYPE_ASC_',
  /** sort on ItemReferenceType descending */
  ItemreferencetypeDesc = '_ITEMREFERENCETYPE_DESC_',
  /** Sort on ItemReferenceTypeOPTION ascending */
  ItemreferencetypeoptionAsc = '_ITEMREFERENCETYPEOPTION_ASC_',
  /** sort on ItemReferenceTypeOPTION descending */
  ItemreferencetypeoptionDesc = '_ITEMREFERENCETYPEOPTION_DESC_',
  /** Sort on ItemReferenceTypeNo ascending */
  ItemreferencetypenoAsc = '_ITEMREFERENCETYPENO_ASC_',
  /** sort on ItemReferenceTypeNo descending */
  ItemreferencetypenoDesc = '_ITEMREFERENCETYPENO_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on PEBChargeType ascending */
  PebchargetypeAsc = '_PEBCHARGETYPE_ASC_',
  /** sort on PEBChargeType descending */
  PebchargetypeDesc = '_PEBCHARGETYPE_DESC_',
  /** Sort on PEBChargeTypeOPTION ascending */
  PebchargetypeoptionAsc = '_PEBCHARGETYPEOPTION_ASC_',
  /** sort on PEBChargeTypeOPTION descending */
  PebchargetypeoptionDesc = '_PEBCHARGETYPEOPTION_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMObjectCostAssignment ascending */
  EqmobjectcostassignmentAsc = '_EQMOBJECTCOSTASSIGNMENT_ASC_',
  /** sort on EQMObjectCostAssignment descending */
  EqmobjectcostassignmentDesc = '_EQMOBJECTCOSTASSIGNMENT_DESC_',
  /** Sort on EQMFixedAssetSetupGroup ascending */
  EqmfixedassetsetupgroupAsc = '_EQMFIXEDASSETSETUPGROUP_ASC_',
  /** sort on EQMFixedAssetSetupGroup descending */
  EqmfixedassetsetupgroupDesc = '_EQMFIXEDASSETSETUPGROUP_DESC_',
  /** Sort on EQMFixedAssetNo ascending */
  EqmfixedassetnoAsc = '_EQMFIXEDASSETNO_ASC_',
  /** sort on EQMFixedAssetNo descending */
  EqmfixedassetnoDesc = '_EQMFIXEDASSETNO_DESC_',
  /** Sort on EQMWorkshop ascending */
  EqmworkshopAsc = '_EQMWORKSHOP_ASC_',
  /** sort on EQMWorkshop descending */
  EqmworkshopDesc = '_EQMWORKSHOP_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMReRentNo ascending */
  EqmrerentnoAsc = '_EQMRERENTNO_ASC_',
  /** sort on EQMReRentNo descending */
  EqmrerentnoDesc = '_EQMRERENTNO_DESC_',
  /** Sort on EQMSerialNo ascending */
  EqmserialnoAsc = '_EQMSERIALNO_ASC_',
  /** sort on EQMSerialNo descending */
  EqmserialnoDesc = '_EQMSERIALNO_DESC_',
  /** Sort on EQMObjectBinCode ascending */
  EqmobjectbincodeAsc = '_EQMOBJECTBINCODE_ASC_',
  /** sort on EQMObjectBinCode descending */
  EqmobjectbincodeDesc = '_EQMOBJECTBINCODE_DESC_',
  /** Sort on EQMManufacturerCode ascending */
  EqmmanufacturercodeAsc = '_EQMMANUFACTURERCODE_ASC_',
  /** sort on EQMManufacturerCode descending */
  EqmmanufacturercodeDesc = '_EQMMANUFACTURERCODE_DESC_',
  /** Sort on EQMManufacturerModelCode ascending */
  EqmmanufacturermodelcodeAsc = '_EQMMANUFACTURERMODELCODE_ASC_',
  /** sort on EQMManufacturerModelCode descending */
  EqmmanufacturermodelcodeDesc = '_EQMMANUFACTURERMODELCODE_DESC_',
  /** Sort on RoutingNo ascending */
  RoutingnoAsc = '_ROUTINGNO_ASC_',
  /** sort on RoutingNo descending */
  RoutingnoDesc = '_ROUTINGNO_DESC_',
  /** Sort on OperationNo ascending */
  OperationnoAsc = '_OPERATIONNO_ASC_',
  /** sort on OperationNo descending */
  OperationnoDesc = '_OPERATIONNO_DESC_',
  /** Sort on WorkCenterNo ascending */
  WorkcenternoAsc = '_WORKCENTERNO_ASC_',
  /** sort on WorkCenterNo descending */
  WorkcenternoDesc = '_WORKCENTERNO_DESC_',
  /** Sort on ProdOrderLineNo ascending */
  ProdorderlinenoAsc = '_PRODORDERLINENO_ASC_',
  /** sort on ProdOrderLineNo descending */
  ProdorderlinenoDesc = '_PRODORDERLINENO_DESC_',
  /** Sort on OverheadRate ascending */
  OverheadrateAsc = '_OVERHEADRATE_ASC_',
  /** sort on OverheadRate descending */
  OverheadrateDesc = '_OVERHEADRATE_DESC_',
  /** Sort on RoutingReferenceNo ascending */
  RoutingreferencenoAsc = '_ROUTINGREFERENCENO_ASC_',
  /** sort on RoutingReferenceNo descending */
  RoutingreferencenoDesc = '_ROUTINGREFERENCENO_DESC_',
  /** Sort on ExternalLineNo ascending */
  ExternallinenoAsc = '_EXTERNALLINENO_ASC_',
  /** sort on ExternalLineNo descending */
  ExternallinenoDesc = '_EXTERNALLINENO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchRcptHeaderInput {
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on PaytoName ascending */
  PaytonameAsc = '_PAYTONAME_ASC_',
  /** sort on PaytoName descending */
  PaytonameDesc = '_PAYTONAME_DESC_',
  /** Sort on PaytoName2 ascending */
  Paytoname2Asc = '_PAYTONAME2_ASC_',
  /** sort on PaytoName2 descending */
  Paytoname2Desc = '_PAYTONAME2_DESC_',
  /** Sort on PaytoAddress ascending */
  PaytoaddressAsc = '_PAYTOADDRESS_ASC_',
  /** sort on PaytoAddress descending */
  PaytoaddressDesc = '_PAYTOADDRESS_DESC_',
  /** Sort on PaytoAddress2 ascending */
  Paytoaddress2Asc = '_PAYTOADDRESS2_ASC_',
  /** sort on PaytoAddress2 descending */
  Paytoaddress2Desc = '_PAYTOADDRESS2_DESC_',
  /** Sort on PaytoCity ascending */
  PaytocityAsc = '_PAYTOCITY_ASC_',
  /** sort on PaytoCity descending */
  PaytocityDesc = '_PAYTOCITY_DESC_',
  /** Sort on PaytoContact ascending */
  PaytocontactAsc = '_PAYTOCONTACT_ASC_',
  /** sort on PaytoContact descending */
  PaytocontactDesc = '_PAYTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscountPercent ascending */
  PaymentdiscountpercentAsc = '_PAYMENTDISCOUNTPERCENT_ASC_',
  /** sort on PaymentDiscountPercent descending */
  PaymentdiscountpercentDesc = '_PAYMENTDISCOUNTPERCENT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on VendorPostingGroup ascending */
  VendorpostinggroupAsc = '_VENDORPOSTINGGROUP_ASC_',
  /** sort on VendorPostingGroup descending */
  VendorpostinggroupDesc = '_VENDORPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on PurchaserCode ascending */
  PurchasercodeAsc = '_PURCHASERCODE_ASC_',
  /** sort on PurchaserCode descending */
  PurchasercodeDesc = '_PURCHASERCODE_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on VendorOrderNo ascending */
  VendorordernoAsc = '_VENDORORDERNO_ASC_',
  /** sort on VendorOrderNo descending */
  VendorordernoDesc = '_VENDORORDERNO_DESC_',
  /** Sort on VendorShipmentNo ascending */
  VendorshipmentnoAsc = '_VENDORSHIPMENTNO_ASC_',
  /** sort on VendorShipmentNo descending */
  VendorshipmentnoDesc = '_VENDORSHIPMENTNO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromVendorName ascending */
  BuyfromvendornameAsc = '_BUYFROMVENDORNAME_ASC_',
  /** sort on BuyfromVendorName descending */
  BuyfromvendornameDesc = '_BUYFROMVENDORNAME_DESC_',
  /** Sort on BuyfromVendorName2 ascending */
  Buyfromvendorname2Asc = '_BUYFROMVENDORNAME2_ASC_',
  /** sort on BuyfromVendorName2 descending */
  Buyfromvendorname2Desc = '_BUYFROMVENDORNAME2_DESC_',
  /** Sort on BuyfromAddress ascending */
  BuyfromaddressAsc = '_BUYFROMADDRESS_ASC_',
  /** sort on BuyfromAddress descending */
  BuyfromaddressDesc = '_BUYFROMADDRESS_DESC_',
  /** Sort on BuyfromAddress2 ascending */
  Buyfromaddress2Asc = '_BUYFROMADDRESS2_ASC_',
  /** sort on BuyfromAddress2 descending */
  Buyfromaddress2Desc = '_BUYFROMADDRESS2_DESC_',
  /** Sort on BuyfromCity ascending */
  BuyfromcityAsc = '_BUYFROMCITY_ASC_',
  /** sort on BuyfromCity descending */
  BuyfromcityDesc = '_BUYFROMCITY_DESC_',
  /** Sort on BuyfromContact ascending */
  BuyfromcontactAsc = '_BUYFROMCONTACT_ASC_',
  /** sort on BuyfromContact descending */
  BuyfromcontactDesc = '_BUYFROMCONTACT_DESC_',
  /** Sort on PaytoPostCode ascending */
  PaytopostcodeAsc = '_PAYTOPOSTCODE_ASC_',
  /** sort on PaytoPostCode descending */
  PaytopostcodeDesc = '_PAYTOPOSTCODE_DESC_',
  /** Sort on PaytoCounty ascending */
  PaytocountyAsc = '_PAYTOCOUNTY_ASC_',
  /** sort on PaytoCounty descending */
  PaytocountyDesc = '_PAYTOCOUNTY_DESC_',
  /** Sort on PaytoCountryRegionCode ascending */
  PaytocountryregioncodeAsc = '_PAYTOCOUNTRYREGIONCODE_ASC_',
  /** sort on PaytoCountryRegionCode descending */
  PaytocountryregioncodeDesc = '_PAYTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromPostCode ascending */
  BuyfrompostcodeAsc = '_BUYFROMPOSTCODE_ASC_',
  /** sort on BuyfromPostCode descending */
  BuyfrompostcodeDesc = '_BUYFROMPOSTCODE_DESC_',
  /** Sort on BuyfromCounty ascending */
  BuyfromcountyAsc = '_BUYFROMCOUNTY_ASC_',
  /** sort on BuyfromCounty descending */
  BuyfromcountyDesc = '_BUYFROMCOUNTY_DESC_',
  /** Sort on BuyfromCountryRegionCode ascending */
  BuyfromcountryregioncodeAsc = '_BUYFROMCOUNTRYREGIONCODE_ASC_',
  /** sort on BuyfromCountryRegionCode descending */
  BuyfromcountryregioncodeDesc = '_BUYFROMCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on OrderAddressCode ascending */
  OrderaddresscodeAsc = '_ORDERADDRESSCODE_ASC_',
  /** sort on OrderAddressCode descending */
  OrderaddresscodeDesc = '_ORDERADDRESSCODE_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on OrderNoSeries ascending */
  OrdernoseriesAsc = '_ORDERNOSERIES_ASC_',
  /** sort on OrderNoSeries descending */
  OrdernoseriesDesc = '_ORDERNOSERIES_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATBaseDiscountPercent ascending */
  VatbasediscountpercentAsc = '_VATBASEDISCOUNTPERCENT_ASC_',
  /** sort on VATBaseDiscountPercent descending */
  VatbasediscountpercentDesc = '_VATBASEDISCOUNTPERCENT_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on BuyfromContactNo ascending */
  BuyfromcontactnoAsc = '_BUYFROMCONTACTNO_ASC_',
  /** sort on BuyfromContactNo descending */
  BuyfromcontactnoDesc = '_BUYFROMCONTACTNO_DESC_',
  /** Sort on PaytoContactno ascending */
  PaytocontactnoAsc = '_PAYTOCONTACTNO_ASC_',
  /** sort on PaytoContactno descending */
  PaytocontactnoDesc = '_PAYTOCONTACTNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on RequestedReceiptDate ascending */
  RequestedreceiptdateAsc = '_REQUESTEDRECEIPTDATE_ASC_',
  /** sort on RequestedReceiptDate descending */
  RequestedreceiptdateDesc = '_REQUESTEDRECEIPTDATE_DESC_',
  /** Sort on PromisedReceiptDate ascending */
  PromisedreceiptdateAsc = '_PROMISEDRECEIPTDATE_ASC_',
  /** sort on PromisedReceiptDate descending */
  PromisedreceiptdateDesc = '_PROMISEDRECEIPTDATE_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on PEBNoteofGoods ascending */
  PebnoteofgoodsAsc = '_PEBNOTEOFGOODS_ASC_',
  /** sort on PEBNoteofGoods descending */
  PebnoteofgoodsDesc = '_PEBNOTEOFGOODS_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMObjectPurchase ascending */
  EqmobjectpurchaseAsc = '_EQMOBJECTPURCHASE_ASC_',
  /** sort on EQMObjectPurchase descending */
  EqmobjectpurchaseDesc = '_EQMOBJECTPURCHASE_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMServiceStatusOPTION ascending */
  EqmservicestatusoptionAsc = '_EQMSERVICESTATUSOPTION_ASC_',
  /** sort on EQMServiceStatusOPTION descending */
  EqmservicestatusoptionDesc = '_EQMSERVICESTATUSOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchRcptLineInput {
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on DirectUnitCost ascending */
  DirectunitcostAsc = '_DIRECTUNITCOST_ASC_',
  /** sort on DirectUnitCost descending */
  DirectunitcostDesc = '_DIRECTUNITCOST_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VATPercent ascending */
  VatpercentAsc = '_VATPERCENT_ASC_',
  /** sort on VATPercent descending */
  VatpercentDesc = '_VATPERCENT_DESC_',
  /** Sort on LineDiscountPercent ascending */
  LinediscountpercentAsc = '_LINEDISCOUNTPERCENT_ASC_',
  /** sort on LineDiscountPercent descending */
  LinediscountpercentDesc = '_LINEDISCOUNTPERCENT_DESC_',
  /** Sort on UnitPriceLCY ascending */
  UnitpricelcyAsc = '_UNITPRICELCY_ASC_',
  /** sort on UnitPriceLCY descending */
  UnitpricelcyDesc = '_UNITPRICELCY_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ItemRcptEntryNo ascending */
  ItemrcptentrynoAsc = '_ITEMRCPTENTRYNO_ASC_',
  /** sort on ItemRcptEntryNo descending */
  ItemrcptentrynoDesc = '_ITEMRCPTENTRYNO_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on IndirectCostPercent ascending */
  IndirectcostpercentAsc = '_INDIRECTCOSTPERCENT_ASC_',
  /** sort on IndirectCostPercent descending */
  IndirectcostpercentDesc = '_INDIRECTCOSTPERCENT_DESC_',
  /** Sort on QtyRcdNotInvoiced ascending */
  QtyrcdnotinvoicedAsc = '_QTYRCDNOTINVOICED_ASC_',
  /** sort on QtyRcdNotInvoiced descending */
  QtyrcdnotinvoicedDesc = '_QTYRCDNOTINVOICED_DESC_',
  /** Sort on QuantityInvoiced ascending */
  QuantityinvoicedAsc = '_QUANTITYINVOICED_ASC_',
  /** sort on QuantityInvoiced descending */
  QuantityinvoicedDesc = '_QUANTITYINVOICED_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on SalesOrderNo ascending */
  SalesordernoAsc = '_SALESORDERNO_ASC_',
  /** sort on SalesOrderNo descending */
  SalesordernoDesc = '_SALESORDERNO_DESC_',
  /** Sort on SalesOrderLineNo ascending */
  SalesorderlinenoAsc = '_SALESORDERLINENO_ASC_',
  /** sort on SalesOrderLineNo descending */
  SalesorderlinenoDesc = '_SALESORDERLINENO_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on UseTax ascending */
  UsetaxAsc = '_USETAX_ASC_',
  /** sort on UseTax descending */
  UsetaxDesc = '_USETAX_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ICItemReferenceNo ascending */
  IcitemreferencenoAsc = '_ICITEMREFERENCENO_ASC_',
  /** sort on ICItemReferenceNo descending */
  IcitemreferencenoDesc = '_ICITEMREFERENCENO_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobLineType ascending */
  JoblinetypeAsc = '_JOBLINETYPE_ASC_',
  /** sort on JobLineType descending */
  JoblinetypeDesc = '_JOBLINETYPE_DESC_',
  /** Sort on JobLineTypeOPTION ascending */
  JoblinetypeoptionAsc = '_JOBLINETYPEOPTION_ASC_',
  /** sort on JobLineTypeOPTION descending */
  JoblinetypeoptionDesc = '_JOBLINETYPEOPTION_DESC_',
  /** Sort on JobUnitPrice ascending */
  JobunitpriceAsc = '_JOBUNITPRICE_ASC_',
  /** sort on JobUnitPrice descending */
  JobunitpriceDesc = '_JOBUNITPRICE_DESC_',
  /** Sort on JobTotalPrice ascending */
  JobtotalpriceAsc = '_JOBTOTALPRICE_ASC_',
  /** sort on JobTotalPrice descending */
  JobtotalpriceDesc = '_JOBTOTALPRICE_DESC_',
  /** Sort on JobLineAmount ascending */
  JoblineamountAsc = '_JOBLINEAMOUNT_ASC_',
  /** sort on JobLineAmount descending */
  JoblineamountDesc = '_JOBLINEAMOUNT_DESC_',
  /** Sort on JobLineDiscountAmount ascending */
  JoblinediscountamountAsc = '_JOBLINEDISCOUNTAMOUNT_ASC_',
  /** sort on JobLineDiscountAmount descending */
  JoblinediscountamountDesc = '_JOBLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on JobLineDiscountPercent ascending */
  JoblinediscountpercentAsc = '_JOBLINEDISCOUNTPERCENT_ASC_',
  /** sort on JobLineDiscountPercent descending */
  JoblinediscountpercentDesc = '_JOBLINEDISCOUNTPERCENT_DESC_',
  /** Sort on JobUnitPriceLCY ascending */
  JobunitpricelcyAsc = '_JOBUNITPRICELCY_ASC_',
  /** sort on JobUnitPriceLCY descending */
  JobunitpricelcyDesc = '_JOBUNITPRICELCY_DESC_',
  /** Sort on JobTotalPriceLCY ascending */
  JobtotalpricelcyAsc = '_JOBTOTALPRICELCY_ASC_',
  /** sort on JobTotalPriceLCY descending */
  JobtotalpricelcyDesc = '_JOBTOTALPRICELCY_DESC_',
  /** Sort on JobLineAmountLCY ascending */
  JoblineamountlcyAsc = '_JOBLINEAMOUNTLCY_ASC_',
  /** sort on JobLineAmountLCY descending */
  JoblineamountlcyDesc = '_JOBLINEAMOUNTLCY_DESC_',
  /** Sort on JobLineDiscAmountLCY ascending */
  JoblinediscamountlcyAsc = '_JOBLINEDISCAMOUNTLCY_ASC_',
  /** sort on JobLineDiscAmountLCY descending */
  JoblinediscamountlcyDesc = '_JOBLINEDISCAMOUNTLCY_DESC_',
  /** Sort on JobCurrencyFactor ascending */
  JobcurrencyfactorAsc = '_JOBCURRENCYFACTOR_ASC_',
  /** sort on JobCurrencyFactor descending */
  JobcurrencyfactorDesc = '_JOBCURRENCYFACTOR_DESC_',
  /** Sort on JobCurrencyCode ascending */
  JobcurrencycodeAsc = '_JOBCURRENCYCODE_ASC_',
  /** sort on JobCurrencyCode descending */
  JobcurrencycodeDesc = '_JOBCURRENCYCODE_DESC_',
  /** Sort on ProdOrderNo ascending */
  ProdordernoAsc = '_PRODORDERNO_ASC_',
  /** sort on ProdOrderNo descending */
  ProdordernoDesc = '_PRODORDERNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on QtyInvoicedBase ascending */
  QtyinvoicedbaseAsc = '_QTYINVOICEDBASE_ASC_',
  /** sort on QtyInvoicedBase descending */
  QtyinvoicedbaseDesc = '_QTYINVOICEDBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on FAPostingType ascending */
  FapostingtypeAsc = '_FAPOSTINGTYPE_ASC_',
  /** sort on FAPostingType descending */
  FapostingtypeDesc = '_FAPOSTINGTYPE_DESC_',
  /** Sort on FAPostingTypeOPTION ascending */
  FapostingtypeoptionAsc = '_FAPOSTINGTYPEOPTION_ASC_',
  /** sort on FAPostingTypeOPTION descending */
  FapostingtypeoptionDesc = '_FAPOSTINGTYPEOPTION_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on SalvageValue ascending */
  SalvagevalueAsc = '_SALVAGEVALUE_ASC_',
  /** sort on SalvageValue descending */
  SalvagevalueDesc = '_SALVAGEVALUE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DeprAcquisitionCost ascending */
  DepracquisitioncostAsc = '_DEPRACQUISITIONCOST_ASC_',
  /** sort on DeprAcquisitionCost descending */
  DepracquisitioncostDesc = '_DEPRACQUISITIONCOST_DESC_',
  /** Sort on MaintenanceCode ascending */
  MaintenancecodeAsc = '_MAINTENANCECODE_ASC_',
  /** sort on MaintenanceCode descending */
  MaintenancecodeDesc = '_MAINTENANCECODE_DESC_',
  /** Sort on InsuranceNo ascending */
  InsurancenoAsc = '_INSURANCENO_ASC_',
  /** sort on InsuranceNo descending */
  InsurancenoDesc = '_INSURANCENO_DESC_',
  /** Sort on BudgetedFANo ascending */
  BudgetedfanoAsc = '_BUDGETEDFANO_ASC_',
  /** sort on BudgetedFANo descending */
  BudgetedfanoDesc = '_BUDGETEDFANO_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on SpecialOrderSalesNo ascending */
  SpecialordersalesnoAsc = '_SPECIALORDERSALESNO_ASC_',
  /** sort on SpecialOrderSalesNo descending */
  SpecialordersalesnoDesc = '_SPECIALORDERSALESNO_DESC_',
  /** Sort on SpecialOrderSalesLineNo ascending */
  SpecialordersaleslinenoAsc = '_SPECIALORDERSALESLINENO_ASC_',
  /** sort on SpecialOrderSalesLineNo descending */
  SpecialordersaleslinenoDesc = '_SPECIALORDERSALESLINENO_DESC_',
  /** Sort on ItemReferenceNo ascending */
  ItemreferencenoAsc = '_ITEMREFERENCENO_ASC_',
  /** sort on ItemReferenceNo descending */
  ItemreferencenoDesc = '_ITEMREFERENCENO_DESC_',
  /** Sort on ItemReferenceUnitofMeasure ascending */
  ItemreferenceunitofmeasureAsc = '_ITEMREFERENCEUNITOFMEASURE_ASC_',
  /** sort on ItemReferenceUnitofMeasure descending */
  ItemreferenceunitofmeasureDesc = '_ITEMREFERENCEUNITOFMEASURE_DESC_',
  /** Sort on ItemReferenceType ascending */
  ItemreferencetypeAsc = '_ITEMREFERENCETYPE_ASC_',
  /** sort on ItemReferenceType descending */
  ItemreferencetypeDesc = '_ITEMREFERENCETYPE_DESC_',
  /** Sort on ItemReferenceTypeOPTION ascending */
  ItemreferencetypeoptionAsc = '_ITEMREFERENCETYPEOPTION_ASC_',
  /** sort on ItemReferenceTypeOPTION descending */
  ItemreferencetypeoptionDesc = '_ITEMREFERENCETYPEOPTION_DESC_',
  /** Sort on ItemReferenceTypeNo ascending */
  ItemreferencetypenoAsc = '_ITEMREFERENCETYPENO_ASC_',
  /** sort on ItemReferenceTypeNo descending */
  ItemreferencetypenoDesc = '_ITEMREFERENCETYPENO_DESC_',
  /** Sort on RequestedReceiptDate ascending */
  RequestedreceiptdateAsc = '_REQUESTEDRECEIPTDATE_ASC_',
  /** sort on RequestedReceiptDate descending */
  RequestedreceiptdateDesc = '_REQUESTEDRECEIPTDATE_DESC_',
  /** Sort on PromisedReceiptDate ascending */
  PromisedreceiptdateAsc = '_PROMISEDRECEIPTDATE_ASC_',
  /** sort on PromisedReceiptDate descending */
  PromisedreceiptdateDesc = '_PROMISEDRECEIPTDATE_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PlannedReceiptDate ascending */
  PlannedreceiptdateAsc = '_PLANNEDRECEIPTDATE_ASC_',
  /** sort on PlannedReceiptDate descending */
  PlannedreceiptdateDesc = '_PLANNEDRECEIPTDATE_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on ItemChargeBaseAmount ascending */
  ItemchargebaseamountAsc = '_ITEMCHARGEBASEAMOUNT_ASC_',
  /** sort on ItemChargeBaseAmount descending */
  ItemchargebaseamountDesc = '_ITEMCHARGEBASEAMOUNT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on DocumentId ascending */
  DocumentidAsc = '_DOCUMENTID_ASC_',
  /** sort on DocumentId descending */
  DocumentidDesc = '_DOCUMENTID_DESC_',
  /** Sort on OverReceiptQuantity ascending */
  OverreceiptquantityAsc = '_OVERRECEIPTQUANTITY_ASC_',
  /** sort on OverReceiptQuantity descending */
  OverreceiptquantityDesc = '_OVERRECEIPTQUANTITY_DESC_',
  /** Sort on OverReceiptCode ascending */
  OverreceiptcodeAsc = '_OVERRECEIPTCODE_ASC_',
  /** sort on OverReceiptCode descending */
  OverreceiptcodeDesc = '_OVERRECEIPTCODE_DESC_',
  /** Sort on OverReceiptCode2 ascending */
  Overreceiptcode2Asc = '_OVERRECEIPTCODE2_ASC_',
  /** sort on OverReceiptCode2 descending */
  Overreceiptcode2Desc = '_OVERRECEIPTCODE2_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on PEBChargeType ascending */
  PebchargetypeAsc = '_PEBCHARGETYPE_ASC_',
  /** sort on PEBChargeType descending */
  PebchargetypeDesc = '_PEBCHARGETYPE_DESC_',
  /** Sort on PEBChargeTypeOPTION ascending */
  PebchargetypeoptionAsc = '_PEBCHARGETYPEOPTION_ASC_',
  /** sort on PEBChargeTypeOPTION descending */
  PebchargetypeoptionDesc = '_PEBCHARGETYPEOPTION_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMNo ascending */
  EqmnoAsc = '_EQMNO_ASC_',
  /** sort on EQMNo descending */
  EqmnoDesc = '_EQMNO_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMObjectBinCode ascending */
  EqmobjectbincodeAsc = '_EQMOBJECTBINCODE_ASC_',
  /** sort on EQMObjectBinCode descending */
  EqmobjectbincodeDesc = '_EQMOBJECTBINCODE_DESC_',
  /** Sort on EQMManufacturerCode ascending */
  EqmmanufacturercodeAsc = '_EQMMANUFACTURERCODE_ASC_',
  /** sort on EQMManufacturerCode descending */
  EqmmanufacturercodeDesc = '_EQMMANUFACTURERCODE_DESC_',
  /** Sort on EQMManufacturerModelCode ascending */
  EqmmanufacturermodelcodeAsc = '_EQMMANUFACTURERMODELCODE_ASC_',
  /** sort on EQMManufacturerModelCode descending */
  EqmmanufacturermodelcodeDesc = '_EQMMANUFACTURERMODELCODE_DESC_',
  /** Sort on RoutingNo ascending */
  RoutingnoAsc = '_ROUTINGNO_ASC_',
  /** sort on RoutingNo descending */
  RoutingnoDesc = '_ROUTINGNO_DESC_',
  /** Sort on OperationNo ascending */
  OperationnoAsc = '_OPERATIONNO_ASC_',
  /** sort on OperationNo descending */
  OperationnoDesc = '_OPERATIONNO_DESC_',
  /** Sort on WorkCenterNo ascending */
  WorkcenternoAsc = '_WORKCENTERNO_ASC_',
  /** sort on WorkCenterNo descending */
  WorkcenternoDesc = '_WORKCENTERNO_DESC_',
  /** Sort on ProdOrderLineNo ascending */
  ProdorderlinenoAsc = '_PRODORDERLINENO_ASC_',
  /** sort on ProdOrderLineNo descending */
  ProdorderlinenoDesc = '_PRODORDERLINENO_DESC_',
  /** Sort on OverheadRate ascending */
  OverheadrateAsc = '_OVERHEADRATE_ASC_',
  /** sort on OverheadRate descending */
  OverheadrateDesc = '_OVERHEADRATE_DESC_',
  /** Sort on RoutingReferenceNo ascending */
  RoutingreferencenoAsc = '_ROUTINGREFERENCENO_ASC_',
  /** sort on RoutingReferenceNo descending */
  RoutingreferencenoDesc = '_ROUTINGREFERENCENO_DESC_',
  /** Sort on ExternalLineNo ascending */
  ExternallinenoAsc = '_EXTERNALLINENO_ASC_',
  /** sort on ExternalLineNo descending */
  ExternallinenoDesc = '_EXTERNALLINENO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchaseHeaderInput {
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on PaytoName ascending */
  PaytonameAsc = '_PAYTONAME_ASC_',
  /** sort on PaytoName descending */
  PaytonameDesc = '_PAYTONAME_DESC_',
  /** Sort on PaytoName2 ascending */
  Paytoname2Asc = '_PAYTONAME2_ASC_',
  /** sort on PaytoName2 descending */
  Paytoname2Desc = '_PAYTONAME2_DESC_',
  /** Sort on PaytoAddress ascending */
  PaytoaddressAsc = '_PAYTOADDRESS_ASC_',
  /** sort on PaytoAddress descending */
  PaytoaddressDesc = '_PAYTOADDRESS_DESC_',
  /** Sort on PaytoAddress2 ascending */
  Paytoaddress2Asc = '_PAYTOADDRESS2_ASC_',
  /** sort on PaytoAddress2 descending */
  Paytoaddress2Desc = '_PAYTOADDRESS2_DESC_',
  /** Sort on PaytoCity ascending */
  PaytocityAsc = '_PAYTOCITY_ASC_',
  /** sort on PaytoCity descending */
  PaytocityDesc = '_PAYTOCITY_DESC_',
  /** Sort on PaytoContact ascending */
  PaytocontactAsc = '_PAYTOCONTACT_ASC_',
  /** sort on PaytoContact descending */
  PaytocontactDesc = '_PAYTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on VendorPostingGroup ascending */
  VendorpostinggroupAsc = '_VENDORPOSTINGGROUP_ASC_',
  /** sort on VendorPostingGroup descending */
  VendorpostinggroupDesc = '_VENDORPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on PurchaserCode ascending */
  PurchasercodeAsc = '_PURCHASERCODE_ASC_',
  /** sort on PurchaserCode descending */
  PurchasercodeDesc = '_PURCHASERCODE_DESC_',
  /** Sort on OrderClass ascending */
  OrderclassAsc = '_ORDERCLASS_ASC_',
  /** sort on OrderClass descending */
  OrderclassDesc = '_ORDERCLASS_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on Receive ascending */
  ReceiveAsc = '_RECEIVE_ASC_',
  /** sort on Receive descending */
  ReceiveDesc = '_RECEIVE_DESC_',
  /** Sort on Invoice ascending */
  InvoiceAsc = '_INVOICE_ASC_',
  /** sort on Invoice descending */
  InvoiceDesc = '_INVOICE_DESC_',
  /** Sort on PrintPostedDocuments ascending */
  PrintposteddocumentsAsc = '_PRINTPOSTEDDOCUMENTS_ASC_',
  /** sort on PrintPostedDocuments descending */
  PrintposteddocumentsDesc = '_PRINTPOSTEDDOCUMENTS_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on ReceivingNo ascending */
  ReceivingnoAsc = '_RECEIVINGNO_ASC_',
  /** sort on ReceivingNo descending */
  ReceivingnoDesc = '_RECEIVINGNO_DESC_',
  /** Sort on PostingNo ascending */
  PostingnoAsc = '_POSTINGNO_ASC_',
  /** sort on PostingNo descending */
  PostingnoDesc = '_POSTINGNO_DESC_',
  /** Sort on LastReceivingNo ascending */
  LastreceivingnoAsc = '_LASTRECEIVINGNO_ASC_',
  /** sort on LastReceivingNo descending */
  LastreceivingnoDesc = '_LASTRECEIVINGNO_DESC_',
  /** Sort on LastPostingNo ascending */
  LastpostingnoAsc = '_LASTPOSTINGNO_ASC_',
  /** sort on LastPostingNo descending */
  LastpostingnoDesc = '_LASTPOSTINGNO_DESC_',
  /** Sort on VendorOrderNo ascending */
  VendorordernoAsc = '_VENDORORDERNO_ASC_',
  /** sort on VendorOrderNo descending */
  VendorordernoDesc = '_VENDORORDERNO_DESC_',
  /** Sort on VendorShipmentNo ascending */
  VendorshipmentnoAsc = '_VENDORSHIPMENTNO_ASC_',
  /** sort on VendorShipmentNo descending */
  VendorshipmentnoDesc = '_VENDORSHIPMENTNO_DESC_',
  /** Sort on VendorInvoiceNo ascending */
  VendorinvoicenoAsc = '_VENDORINVOICENO_ASC_',
  /** sort on VendorInvoiceNo descending */
  VendorinvoicenoDesc = '_VENDORINVOICENO_DESC_',
  /** Sort on VendorCrMemoNo ascending */
  VendorcrmemonoAsc = '_VENDORCRMEMONO_ASC_',
  /** sort on VendorCrMemoNo descending */
  VendorcrmemonoDesc = '_VENDORCRMEMONO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromVendorName ascending */
  BuyfromvendornameAsc = '_BUYFROMVENDORNAME_ASC_',
  /** sort on BuyfromVendorName descending */
  BuyfromvendornameDesc = '_BUYFROMVENDORNAME_DESC_',
  /** Sort on BuyfromVendorName2 ascending */
  Buyfromvendorname2Asc = '_BUYFROMVENDORNAME2_ASC_',
  /** sort on BuyfromVendorName2 descending */
  Buyfromvendorname2Desc = '_BUYFROMVENDORNAME2_DESC_',
  /** Sort on BuyfromAddress ascending */
  BuyfromaddressAsc = '_BUYFROMADDRESS_ASC_',
  /** sort on BuyfromAddress descending */
  BuyfromaddressDesc = '_BUYFROMADDRESS_DESC_',
  /** Sort on BuyfromAddress2 ascending */
  Buyfromaddress2Asc = '_BUYFROMADDRESS2_ASC_',
  /** sort on BuyfromAddress2 descending */
  Buyfromaddress2Desc = '_BUYFROMADDRESS2_DESC_',
  /** Sort on BuyfromCity ascending */
  BuyfromcityAsc = '_BUYFROMCITY_ASC_',
  /** sort on BuyfromCity descending */
  BuyfromcityDesc = '_BUYFROMCITY_DESC_',
  /** Sort on BuyfromContact ascending */
  BuyfromcontactAsc = '_BUYFROMCONTACT_ASC_',
  /** sort on BuyfromContact descending */
  BuyfromcontactDesc = '_BUYFROMCONTACT_DESC_',
  /** Sort on PaytoPostCode ascending */
  PaytopostcodeAsc = '_PAYTOPOSTCODE_ASC_',
  /** sort on PaytoPostCode descending */
  PaytopostcodeDesc = '_PAYTOPOSTCODE_DESC_',
  /** Sort on PaytoCounty ascending */
  PaytocountyAsc = '_PAYTOCOUNTY_ASC_',
  /** sort on PaytoCounty descending */
  PaytocountyDesc = '_PAYTOCOUNTY_DESC_',
  /** Sort on PaytoCountryRegionCode ascending */
  PaytocountryregioncodeAsc = '_PAYTOCOUNTRYREGIONCODE_ASC_',
  /** sort on PaytoCountryRegionCode descending */
  PaytocountryregioncodeDesc = '_PAYTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BuyfromPostCode ascending */
  BuyfrompostcodeAsc = '_BUYFROMPOSTCODE_ASC_',
  /** sort on BuyfromPostCode descending */
  BuyfrompostcodeDesc = '_BUYFROMPOSTCODE_DESC_',
  /** Sort on BuyfromCounty ascending */
  BuyfromcountyAsc = '_BUYFROMCOUNTY_ASC_',
  /** sort on BuyfromCounty descending */
  BuyfromcountyDesc = '_BUYFROMCOUNTY_DESC_',
  /** Sort on BuyfromCountryRegionCode ascending */
  BuyfromcountryregioncodeAsc = '_BUYFROMCOUNTRYREGIONCODE_ASC_',
  /** sort on BuyfromCountryRegionCode descending */
  BuyfromcountryregioncodeDesc = '_BUYFROMCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on OrderAddressCode ascending */
  OrderaddresscodeAsc = '_ORDERADDRESSCODE_ASC_',
  /** sort on OrderAddressCode descending */
  OrderaddresscodeDesc = '_ORDERADDRESSCODE_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on PostingNoSeries ascending */
  PostingnoseriesAsc = '_POSTINGNOSERIES_ASC_',
  /** sort on PostingNoSeries descending */
  PostingnoseriesDesc = '_POSTINGNOSERIES_DESC_',
  /** Sort on ReceivingNoSeries ascending */
  ReceivingnoseriesAsc = '_RECEIVINGNOSERIES_ASC_',
  /** sort on ReceivingNoSeries descending */
  ReceivingnoseriesDesc = '_RECEIVINGNOSERIES_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on AppliestoID ascending */
  AppliestoidAsc = '_APPLIESTOID_ASC_',
  /** sort on AppliestoID descending */
  AppliestoidDesc = '_APPLIESTOID_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on InvoiceDiscountCalculation ascending */
  InvoicediscountcalculationAsc = '_INVOICEDISCOUNTCALCULATION_ASC_',
  /** sort on InvoiceDiscountCalculation descending */
  InvoicediscountcalculationDesc = '_INVOICEDISCOUNTCALCULATION_DESC_',
  /** Sort on InvoiceDiscountCalculationOPTION ascending */
  InvoicediscountcalculationoptionAsc = '_INVOICEDISCOUNTCALCULATIONOPTION_ASC_',
  /** sort on InvoiceDiscountCalculationOPTION descending */
  InvoicediscountcalculationoptionDesc = '_INVOICEDISCOUNTCALCULATIONOPTION_DESC_',
  /** Sort on InvoiceDiscountValue ascending */
  InvoicediscountvalueAsc = '_INVOICEDISCOUNTVALUE_ASC_',
  /** sort on InvoiceDiscountValue descending */
  InvoicediscountvalueDesc = '_INVOICEDISCOUNTVALUE_DESC_',
  /** Sort on SendICDocument ascending */
  SendicdocumentAsc = '_SENDICDOCUMENT_ASC_',
  /** sort on SendICDocument descending */
  SendicdocumentDesc = '_SENDICDOCUMENT_DESC_',
  /** Sort on ICStatus ascending */
  IcstatusAsc = '_ICSTATUS_ASC_',
  /** sort on ICStatus descending */
  IcstatusDesc = '_ICSTATUS_DESC_',
  /** Sort on ICStatusOPTION ascending */
  IcstatusoptionAsc = '_ICSTATUSOPTION_ASC_',
  /** sort on ICStatusOPTION descending */
  IcstatusoptionDesc = '_ICSTATUSOPTION_DESC_',
  /** Sort on BuyfromICPartnerCode ascending */
  BuyfromicpartnercodeAsc = '_BUYFROMICPARTNERCODE_ASC_',
  /** sort on BuyfromICPartnerCode descending */
  BuyfromicpartnercodeDesc = '_BUYFROMICPARTNERCODE_DESC_',
  /** Sort on PaytoICPartnerCode ascending */
  PaytoicpartnercodeAsc = '_PAYTOICPARTNERCODE_ASC_',
  /** sort on PaytoICPartnerCode descending */
  PaytoicpartnercodeDesc = '_PAYTOICPARTNERCODE_DESC_',
  /** Sort on ICDirection ascending */
  IcdirectionAsc = '_ICDIRECTION_ASC_',
  /** sort on ICDirection descending */
  IcdirectionDesc = '_ICDIRECTION_DESC_',
  /** Sort on ICDirectionOPTION ascending */
  IcdirectionoptionAsc = '_ICDIRECTIONOPTION_ASC_',
  /** sort on ICDirectionOPTION descending */
  IcdirectionoptionDesc = '_ICDIRECTIONOPTION_DESC_',
  /** Sort on PrepaymentNo ascending */
  PrepaymentnoAsc = '_PREPAYMENTNO_ASC_',
  /** sort on PrepaymentNo descending */
  PrepaymentnoDesc = '_PREPAYMENTNO_DESC_',
  /** Sort on LastPrepaymentNo ascending */
  LastprepaymentnoAsc = '_LASTPREPAYMENTNO_ASC_',
  /** sort on LastPrepaymentNo descending */
  LastprepaymentnoDesc = '_LASTPREPAYMENTNO_DESC_',
  /** Sort on PrepmtCrMemoNo ascending */
  PrepmtcrmemonoAsc = '_PREPMTCRMEMONO_ASC_',
  /** sort on PrepmtCrMemoNo descending */
  PrepmtcrmemonoDesc = '_PREPMTCRMEMONO_DESC_',
  /** Sort on LastPrepmtCrMemoNo ascending */
  LastprepmtcrmemonoAsc = '_LASTPREPMTCRMEMONO_ASC_',
  /** sort on LastPrepmtCrMemoNo descending */
  LastprepmtcrmemonoDesc = '_LASTPREPMTCRMEMONO_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on CompressPrepayment ascending */
  CompressprepaymentAsc = '_COMPRESSPREPAYMENT_ASC_',
  /** sort on CompressPrepayment descending */
  CompressprepaymentDesc = '_COMPRESSPREPAYMENT_DESC_',
  /** Sort on PrepaymentDueDate ascending */
  PrepaymentduedateAsc = '_PREPAYMENTDUEDATE_ASC_',
  /** sort on PrepaymentDueDate descending */
  PrepaymentduedateDesc = '_PREPAYMENTDUEDATE_DESC_',
  /** Sort on PrepmtCrMemoNoSeries ascending */
  PrepmtcrmemonoseriesAsc = '_PREPMTCRMEMONOSERIES_ASC_',
  /** sort on PrepmtCrMemoNoSeries descending */
  PrepmtcrmemonoseriesDesc = '_PREPMTCRMEMONOSERIES_DESC_',
  /** Sort on PrepmtPostingDescription ascending */
  PrepmtpostingdescriptionAsc = '_PREPMTPOSTINGDESCRIPTION_ASC_',
  /** sort on PrepmtPostingDescription descending */
  PrepmtpostingdescriptionDesc = '_PREPMTPOSTINGDESCRIPTION_DESC_',
  /** Sort on PrepmtPmtDiscountDate ascending */
  PrepmtpmtdiscountdateAsc = '_PREPMTPMTDISCOUNTDATE_ASC_',
  /** sort on PrepmtPmtDiscountDate descending */
  PrepmtpmtdiscountdateDesc = '_PREPMTPMTDISCOUNTDATE_DESC_',
  /** Sort on PrepmtPaymentTermsCode ascending */
  PrepmtpaymenttermscodeAsc = '_PREPMTPAYMENTTERMSCODE_ASC_',
  /** sort on PrepmtPaymentTermsCode descending */
  PrepmtpaymenttermscodeDesc = '_PREPMTPAYMENTTERMSCODE_DESC_',
  /** Sort on PrepmtPaymentDiscount ascending */
  PrepmtpaymentdiscountAsc = '_PREPMTPAYMENTDISCOUNT_ASC_',
  /** sort on PrepmtPaymentDiscount descending */
  PrepmtpaymentdiscountDesc = '_PREPMTPAYMENTDISCOUNT_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on JobQueueStatus ascending */
  JobqueuestatusAsc = '_JOBQUEUESTATUS_ASC_',
  /** sort on JobQueueStatus descending */
  JobqueuestatusDesc = '_JOBQUEUESTATUS_DESC_',
  /** Sort on JobQueueStatusOPTION ascending */
  JobqueuestatusoptionAsc = '_JOBQUEUESTATUSOPTION_ASC_',
  /** sort on JobQueueStatusOPTION descending */
  JobqueuestatusoptionDesc = '_JOBQUEUESTATUSOPTION_DESC_',
  /** Sort on JobQueueEntryID ascending */
  JobqueueentryidAsc = '_JOBQUEUEENTRYID_ASC_',
  /** sort on JobQueueEntryID descending */
  JobqueueentryidDesc = '_JOBQUEUEENTRYID_DESC_',
  /** Sort on IncomingDocumentEntryNo ascending */
  IncomingdocumententrynoAsc = '_INCOMINGDOCUMENTENTRYNO_ASC_',
  /** sort on IncomingDocumentEntryNo descending */
  IncomingdocumententrynoDesc = '_INCOMINGDOCUMENTENTRYNO_DESC_',
  /** Sort on CreditorNo ascending */
  CreditornoAsc = '_CREDITORNO_ASC_',
  /** sort on CreditorNo descending */
  CreditornoDesc = '_CREDITORNO_DESC_',
  /** Sort on PaymentReference ascending */
  PaymentreferenceAsc = '_PAYMENTREFERENCE_ASC_',
  /** sort on PaymentReference descending */
  PaymentreferenceDesc = '_PAYMENTREFERENCE_DESC_',
  /** Sort on JournalTemplName ascending */
  JournaltemplnameAsc = '_JOURNALTEMPLNAME_ASC_',
  /** sort on JournalTemplName descending */
  JournaltemplnameDesc = '_JOURNALTEMPLNAME_DESC_',
  /** Sort on ARcdNotInvExVATLCY ascending */
  ArcdnotinvexvatlcyAsc = '_ARCDNOTINVEXVATLCY_ASC_',
  /** sort on ARcdNotInvExVATLCY descending */
  ArcdnotinvexvatlcyDesc = '_ARCDNOTINVEXVATLCY_DESC_',
  /** Sort on AmtRcdNotInvoicedLCY ascending */
  AmtrcdnotinvoicedlcyAsc = '_AMTRCDNOTINVOICEDLCY_ASC_',
  /** sort on AmtRcdNotInvoicedLCY descending */
  AmtrcdnotinvoicedlcyDesc = '_AMTRCDNOTINVOICEDLCY_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on NoofArchivedVersions ascending */
  NoofarchivedversionsAsc = '_NOOFARCHIVEDVERSIONS_ASC_',
  /** sort on NoofArchivedVersions descending */
  NoofarchivedversionsDesc = '_NOOFARCHIVEDVERSIONS_DESC_',
  /** Sort on DocNoOccurrence ascending */
  DocnooccurrenceAsc = '_DOCNOOCCURRENCE_ASC_',
  /** sort on DocNoOccurrence descending */
  DocnooccurrenceDesc = '_DOCNOOCCURRENCE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on BuyfromContactNo ascending */
  BuyfromcontactnoAsc = '_BUYFROMCONTACTNO_ASC_',
  /** sort on BuyfromContactNo descending */
  BuyfromcontactnoDesc = '_BUYFROMCONTACTNO_DESC_',
  /** Sort on PaytoContactNo ascending */
  PaytocontactnoAsc = '_PAYTOCONTACTNO_ASC_',
  /** sort on PaytoContactNo descending */
  PaytocontactnoDesc = '_PAYTOCONTACTNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on PartiallyInvoiced ascending */
  PartiallyinvoicedAsc = '_PARTIALLYINVOICED_ASC_',
  /** sort on PartiallyInvoiced descending */
  PartiallyinvoicedDesc = '_PARTIALLYINVOICED_DESC_',
  /** Sort on CompletelyReceived ascending */
  CompletelyreceivedAsc = '_COMPLETELYRECEIVED_ASC_',
  /** sort on CompletelyReceived descending */
  CompletelyreceivedDesc = '_COMPLETELYRECEIVED_DESC_',
  /** Sort on PostingfromWhseRef ascending */
  PostingfromwhserefAsc = '_POSTINGFROMWHSEREF_ASC_',
  /** sort on PostingfromWhseRef descending */
  PostingfromwhserefDesc = '_POSTINGFROMWHSEREF_DESC_',
  /** Sort on RequestedReceiptDate ascending */
  RequestedreceiptdateAsc = '_REQUESTEDRECEIPTDATE_ASC_',
  /** sort on RequestedReceiptDate descending */
  RequestedreceiptdateDesc = '_REQUESTEDRECEIPTDATE_DESC_',
  /** Sort on PromisedReceiptDate ascending */
  PromisedreceiptdateAsc = '_PROMISEDRECEIPTDATE_ASC_',
  /** sort on PromisedReceiptDate descending */
  PromisedreceiptdateDesc = '_PROMISEDRECEIPTDATE_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on VendorAuthorizationNo ascending */
  VendorauthorizationnoAsc = '_VENDORAUTHORIZATIONNO_ASC_',
  /** sort on VendorAuthorizationNo descending */
  VendorauthorizationnoDesc = '_VENDORAUTHORIZATIONNO_DESC_',
  /** Sort on ReturnShipmentNo ascending */
  ReturnshipmentnoAsc = '_RETURNSHIPMENTNO_ASC_',
  /** sort on ReturnShipmentNo descending */
  ReturnshipmentnoDesc = '_RETURNSHIPMENTNO_DESC_',
  /** Sort on ReturnShipmentNoSeries ascending */
  ReturnshipmentnoseriesAsc = '_RETURNSHIPMENTNOSERIES_ASC_',
  /** sort on ReturnShipmentNoSeries descending */
  ReturnshipmentnoseriesDesc = '_RETURNSHIPMENTNOSERIES_DESC_',
  /** Sort on Ship ascending */
  ShipAsc = '_SHIP_ASC_',
  /** sort on Ship descending */
  ShipDesc = '_SHIP_DESC_',
  /** Sort on LastReturnShipmentNo ascending */
  LastreturnshipmentnoAsc = '_LASTRETURNSHIPMENTNO_ASC_',
  /** sort on LastReturnShipmentNo descending */
  LastreturnshipmentnoDesc = '_LASTRETURNSHIPMENTNO_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on PendingApprovals ascending */
  PendingapprovalsAsc = '_PENDINGAPPROVALS_ASC_',
  /** sort on PendingApprovals descending */
  PendingapprovalsDesc = '_PENDINGAPPROVALS_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on PEBGrossInvoiceAmount ascending */
  PebgrossinvoiceamountAsc = '_PEBGROSSINVOICEAMOUNT_ASC_',
  /** sort on PEBGrossInvoiceAmount descending */
  PebgrossinvoiceamountDesc = '_PEBGROSSINVOICEAMOUNT_DESC_',
  /** Sort on PEBNoteofGoods ascending */
  PebnoteofgoodsAsc = '_PEBNOTEOFGOODS_ASC_',
  /** sort on PEBNoteofGoods descending */
  PebnoteofgoodsDesc = '_PEBNOTEOFGOODS_DESC_',
  /** Sort on PEBInwardRegistrated ascending */
  PebinwardregistratedAsc = '_PEBINWARDREGISTRATED_ASC_',
  /** sort on PEBInwardRegistrated descending */
  PebinwardregistratedDesc = '_PEBINWARDREGISTRATED_DESC_',
  /** Sort on PEBOCRNo ascending */
  PebocrnoAsc = '_PEBOCRNO_ASC_',
  /** sort on PEBOCRNo descending */
  PebocrnoDesc = '_PEBOCRNO_DESC_',
  /** Sort on PEBRecipientBankAccount ascending */
  PebrecipientbankaccountAsc = '_PEBRECIPIENTBANKACCOUNT_ASC_',
  /** sort on PEBRecipientBankAccount descending */
  PebrecipientbankaccountDesc = '_PEBRECIPIENTBANKACCOUNT_DESC_',
  /** Sort on PEBOfwhichVATAmount ascending */
  PebofwhichvatamountAsc = '_PEBOFWHICHVATAMOUNT_ASC_',
  /** sort on PEBOfwhichVATAmount descending */
  PebofwhichvatamountDesc = '_PEBOFWHICHVATAMOUNT_DESC_',
  /** Sort on PEBInwardVAT ascending */
  PebinwardvatAsc = '_PEBINWARDVAT_ASC_',
  /** sort on PEBInwardVAT descending */
  PebinwardvatDesc = '_PEBINWARDVAT_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMContractType ascending */
  EqmcontracttypeAsc = '_EQMCONTRACTTYPE_ASC_',
  /** sort on EQMContractType descending */
  EqmcontracttypeDesc = '_EQMCONTRACTTYPE_DESC_',
  /** Sort on EQMContractTypeOPTION ascending */
  EqmcontracttypeoptionAsc = '_EQMCONTRACTTYPEOPTION_ASC_',
  /** sort on EQMContractTypeOPTION descending */
  EqmcontracttypeoptionDesc = '_EQMCONTRACTTYPEOPTION_DESC_',
  /** Sort on EQMObjectPurchase ascending */
  EqmobjectpurchaseAsc = '_EQMOBJECTPURCHASE_ASC_',
  /** sort on EQMObjectPurchase descending */
  EqmobjectpurchaseDesc = '_EQMOBJECTPURCHASE_DESC_',
  /** Sort on EQMWorkshopDocument ascending */
  EqmworkshopdocumentAsc = '_EQMWORKSHOPDOCUMENT_ASC_',
  /** sort on EQMWorkshopDocument descending */
  EqmworkshopdocumentDesc = '_EQMWORKSHOPDOCUMENT_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceTermNo ascending */
  EqmservicetermnoAsc = '_EQMSERVICETERMNO_ASC_',
  /** sort on EQMServiceTermNo descending */
  EqmservicetermnoDesc = '_EQMSERVICETERMNO_DESC_',
  /** Sort on EQMWSObjectCounter ascending */
  EqmwsobjectcounterAsc = '_EQMWSOBJECTCOUNTER_ASC_',
  /** sort on EQMWSObjectCounter descending */
  EqmwsobjectcounterDesc = '_EQMWSOBJECTCOUNTER_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMServiceStartDate ascending */
  EqmservicestartdateAsc = '_EQMSERVICESTARTDATE_ASC_',
  /** sort on EQMServiceStartDate descending */
  EqmservicestartdateDesc = '_EQMSERVICESTARTDATE_DESC_',
  /** Sort on EQMServiceEndDate ascending */
  EqmserviceenddateAsc = '_EQMSERVICEENDDATE_ASC_',
  /** sort on EQMServiceEndDate descending */
  EqmserviceenddateDesc = '_EQMSERVICEENDDATE_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMServiceStatusOPTION ascending */
  EqmservicestatusoptionAsc = '_EQMSERVICESTATUSOPTION_ASC_',
  /** sort on EQMServiceStatusOPTION descending */
  EqmservicestatusoptionDesc = '_EQMSERVICESTATUSOPTION_DESC_',
  /** Sort on EQMObjectWarrantyDate ascending */
  EqmobjectwarrantydateAsc = '_EQMOBJECTWARRANTYDATE_ASC_',
  /** sort on EQMObjectWarrantyDate descending */
  EqmobjectwarrantydateDesc = '_EQMOBJECTWARRANTYDATE_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMWSEstimatedStartDate ascending */
  EqmwsestimatedstartdateAsc = '_EQMWSESTIMATEDSTARTDATE_ASC_',
  /** sort on EQMWSEstimatedStartDate descending */
  EqmwsestimatedstartdateDesc = '_EQMWSESTIMATEDSTARTDATE_DESC_',
  /** Sort on EQMWSEstimatedStartTime ascending */
  EqmwsestimatedstarttimeAsc = '_EQMWSESTIMATEDSTARTTIME_ASC_',
  /** sort on EQMWSEstimatedStartTime descending */
  EqmwsestimatedstarttimeDesc = '_EQMWSESTIMATEDSTARTTIME_DESC_',
  /** Sort on EQMWSEstimatedEndDate ascending */
  EqmwsestimatedenddateAsc = '_EQMWSESTIMATEDENDDATE_ASC_',
  /** sort on EQMWSEstimatedEndDate descending */
  EqmwsestimatedenddateDesc = '_EQMWSESTIMATEDENDDATE_DESC_',
  /** Sort on EQMWSEstimatedEndTime ascending */
  EqmwsestimatedendtimeAsc = '_EQMWSESTIMATEDENDTIME_ASC_',
  /** sort on EQMWSEstimatedEndTime descending */
  EqmwsestimatedendtimeDesc = '_EQMWSESTIMATEDENDTIME_DESC_',
  /** Sort on EQMServiceStartTime ascending */
  EqmservicestarttimeAsc = '_EQMSERVICESTARTTIME_ASC_',
  /** sort on EQMServiceStartTime descending */
  EqmservicestarttimeDesc = '_EQMSERVICESTARTTIME_DESC_',
  /** Sort on EQMServiceEndTime ascending */
  EqmserviceendtimeAsc = '_EQMSERVICEENDTIME_ASC_',
  /** sort on EQMServiceEndTime descending */
  EqmserviceendtimeDesc = '_EQMSERVICEENDTIME_DESC_',
  /** Sort on EQMObjWorkPurchFlow ascending */
  EqmobjworkpurchflowAsc = '_EQMOBJWORKPURCHFLOW_ASC_',
  /** sort on EQMObjWorkPurchFlow descending */
  EqmobjworkpurchflowDesc = '_EQMOBJWORKPURCHFLOW_DESC_',
  /** Sort on EQMRentalShipmentNo ascending */
  EqmrentalshipmentnoAsc = '_EQMRENTALSHIPMENTNO_ASC_',
  /** sort on EQMRentalShipmentNo descending */
  EqmrentalshipmentnoDesc = '_EQMRENTALSHIPMENTNO_DESC_',
  /** Sort on EQMRentalCollectionNo ascending */
  EqmrentalcollectionnoAsc = '_EQMRENTALCOLLECTIONNO_ASC_',
  /** sort on EQMRentalCollectionNo descending */
  EqmrentalcollectionnoDesc = '_EQMRENTALCOLLECTIONNO_DESC_',
  /** Sort on EQMWSObjectCounter2 ascending */
  Eqmwsobjectcounter2Asc = '_EQMWSOBJECTCOUNTER2_ASC_',
  /** sort on EQMWSObjectCounter2 descending */
  Eqmwsobjectcounter2Desc = '_EQMWSOBJECTCOUNTER2_DESC_',
  /** Sort on EQMPostedRentalShipmentNo ascending */
  EqmpostedrentalshipmentnoAsc = '_EQMPOSTEDRENTALSHIPMENTNO_ASC_',
  /** sort on EQMPostedRentalShipmentNo descending */
  EqmpostedrentalshipmentnoDesc = '_EQMPOSTEDRENTALSHIPMENTNO_DESC_',
  /** Sort on EQMPostedRentalCollNo ascending */
  EqmpostedrentalcollnoAsc = '_EQMPOSTEDRENTALCOLLNO_ASC_',
  /** sort on EQMPostedRentalCollNo descending */
  EqmpostedrentalcollnoDesc = '_EQMPOSTEDRENTALCOLLNO_DESC_',
  /** Sort on EQMControlTypeCode ascending */
  EqmcontroltypecodeAsc = '_EQMCONTROLTYPECODE_ASC_',
  /** sort on EQMControlTypeCode descending */
  EqmcontroltypecodeDesc = '_EQMCONTROLTYPECODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchaseLineInput {
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on BuyfromVendorNo ascending */
  BuyfromvendornoAsc = '_BUYFROMVENDORNO_ASC_',
  /** sort on BuyfromVendorNo descending */
  BuyfromvendornoDesc = '_BUYFROMVENDORNO_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ExpectedReceiptDate ascending */
  ExpectedreceiptdateAsc = '_EXPECTEDRECEIPTDATE_ASC_',
  /** sort on ExpectedReceiptDate descending */
  ExpectedreceiptdateDesc = '_EXPECTEDRECEIPTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on OutstandingQuantity ascending */
  OutstandingquantityAsc = '_OUTSTANDINGQUANTITY_ASC_',
  /** sort on OutstandingQuantity descending */
  OutstandingquantityDesc = '_OUTSTANDINGQUANTITY_DESC_',
  /** Sort on QtytoInvoice ascending */
  QtytoinvoiceAsc = '_QTYTOINVOICE_ASC_',
  /** sort on QtytoInvoice descending */
  QtytoinvoiceDesc = '_QTYTOINVOICE_DESC_',
  /** Sort on QtytoReceive ascending */
  QtytoreceiveAsc = '_QTYTORECEIVE_ASC_',
  /** sort on QtytoReceive descending */
  QtytoreceiveDesc = '_QTYTORECEIVE_DESC_',
  /** Sort on DirectUnitCost ascending */
  DirectunitcostAsc = '_DIRECTUNITCOST_ASC_',
  /** sort on DirectUnitCost descending */
  DirectunitcostDesc = '_DIRECTUNITCOST_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on UnitPriceLCY ascending */
  UnitpricelcyAsc = '_UNITPRICELCY_ASC_',
  /** sort on UnitPriceLCY descending */
  UnitpricelcyDesc = '_UNITPRICELCY_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on IndirectCost ascending */
  IndirectcostAsc = '_INDIRECTCOST_ASC_',
  /** sort on IndirectCost descending */
  IndirectcostDesc = '_INDIRECTCOST_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on OutstandingAmount ascending */
  OutstandingamountAsc = '_OUTSTANDINGAMOUNT_ASC_',
  /** sort on OutstandingAmount descending */
  OutstandingamountDesc = '_OUTSTANDINGAMOUNT_DESC_',
  /** Sort on QtyRcdNotInvoiced ascending */
  QtyrcdnotinvoicedAsc = '_QTYRCDNOTINVOICED_ASC_',
  /** sort on QtyRcdNotInvoiced descending */
  QtyrcdnotinvoicedDesc = '_QTYRCDNOTINVOICED_DESC_',
  /** Sort on AmtRcdNotInvoiced ascending */
  AmtrcdnotinvoicedAsc = '_AMTRCDNOTINVOICED_ASC_',
  /** sort on AmtRcdNotInvoiced descending */
  AmtrcdnotinvoicedDesc = '_AMTRCDNOTINVOICED_DESC_',
  /** Sort on QuantityReceived ascending */
  QuantityreceivedAsc = '_QUANTITYRECEIVED_ASC_',
  /** sort on QuantityReceived descending */
  QuantityreceivedDesc = '_QUANTITYRECEIVED_DESC_',
  /** Sort on QuantityInvoiced ascending */
  QuantityinvoicedAsc = '_QUANTITYINVOICED_ASC_',
  /** sort on QuantityInvoiced descending */
  QuantityinvoicedDesc = '_QUANTITYINVOICED_DESC_',
  /** Sort on ReceiptNo ascending */
  ReceiptnoAsc = '_RECEIPTNO_ASC_',
  /** sort on ReceiptNo descending */
  ReceiptnoDesc = '_RECEIPTNO_DESC_',
  /** Sort on ReceiptLineNo ascending */
  ReceiptlinenoAsc = '_RECEIPTLINENO_ASC_',
  /** sort on ReceiptLineNo descending */
  ReceiptlinenoDesc = '_RECEIPTLINENO_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on InvDiscountAmount ascending */
  InvdiscountamountAsc = '_INVDISCOUNTAMOUNT_ASC_',
  /** sort on InvDiscountAmount descending */
  InvdiscountamountDesc = '_INVDISCOUNTAMOUNT_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on SalesOrderNo ascending */
  SalesordernoAsc = '_SALESORDERNO_ASC_',
  /** sort on SalesOrderNo descending */
  SalesordernoDesc = '_SALESORDERNO_DESC_',
  /** Sort on SalesOrderLineNo ascending */
  SalesorderlinenoAsc = '_SALESORDERLINENO_ASC_',
  /** sort on SalesOrderLineNo descending */
  SalesorderlinenoDesc = '_SALESORDERLINENO_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on EntryPoint ascending */
  EntrypointAsc = '_ENTRYPOINT_ASC_',
  /** sort on EntryPoint descending */
  EntrypointDesc = '_ENTRYPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on UseTax ascending */
  UsetaxAsc = '_USETAX_ASC_',
  /** sort on UseTax descending */
  UsetaxDesc = '_USETAX_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on OutstandingAmountLCY ascending */
  OutstandingamountlcyAsc = '_OUTSTANDINGAMOUNTLCY_ASC_',
  /** sort on OutstandingAmountLCY descending */
  OutstandingamountlcyDesc = '_OUTSTANDINGAMOUNTLCY_DESC_',
  /** Sort on AmtRcdNotInvoicedLCY ascending */
  AmtrcdnotinvoicedlcyAsc = '_AMTRCDNOTINVOICEDLCY_ASC_',
  /** sort on AmtRcdNotInvoicedLCY descending */
  AmtrcdnotinvoicedlcyDesc = '_AMTRCDNOTINVOICEDLCY_DESC_',
  /** Sort on ReservedQuantity ascending */
  ReservedquantityAsc = '_RESERVEDQUANTITY_ASC_',
  /** sort on ReservedQuantity descending */
  ReservedquantityDesc = '_RESERVEDQUANTITY_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on InvDiscAmounttoInvoice ascending */
  InvdiscamounttoinvoiceAsc = '_INVDISCAMOUNTTOINVOICE_ASC_',
  /** sort on InvDiscAmounttoInvoice descending */
  InvdiscamounttoinvoiceDesc = '_INVDISCAMOUNTTOINVOICE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepmtLineAmount ascending */
  PrepmtlineamountAsc = '_PREPMTLINEAMOUNT_ASC_',
  /** sort on PrepmtLineAmount descending */
  PrepmtlineamountDesc = '_PREPMTLINEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInv ascending */
  PrepmtamtinvAsc = '_PREPMTAMTINV_ASC_',
  /** sort on PrepmtAmtInv descending */
  PrepmtamtinvDesc = '_PREPMTAMTINV_DESC_',
  /** Sort on PrepmtAmtInclVAT ascending */
  PrepmtamtinclvatAsc = '_PREPMTAMTINCLVAT_ASC_',
  /** sort on PrepmtAmtInclVAT descending */
  PrepmtamtinclvatDesc = '_PREPMTAMTINCLVAT_DESC_',
  /** Sort on PrepaymentAmount ascending */
  PrepaymentamountAsc = '_PREPAYMENTAMOUNT_ASC_',
  /** sort on PrepaymentAmount descending */
  PrepaymentamountDesc = '_PREPAYMENTAMOUNT_DESC_',
  /** Sort on PrepmtVATBaseAmt ascending */
  PrepmtvatbaseamtAsc = '_PREPMTVATBASEAMT_ASC_',
  /** sort on PrepmtVATBaseAmt descending */
  PrepmtvatbaseamtDesc = '_PREPMTVATBASEAMT_DESC_',
  /** Sort on PrepaymentVAT ascending */
  PrepaymentvatAsc = '_PREPAYMENTVAT_ASC_',
  /** sort on PrepaymentVAT descending */
  PrepaymentvatDesc = '_PREPAYMENTVAT_DESC_',
  /** Sort on PrepmtVATCalcType ascending */
  PrepmtvatcalctypeAsc = '_PREPMTVATCALCTYPE_ASC_',
  /** sort on PrepmtVATCalcType descending */
  PrepmtvatcalctypeDesc = '_PREPMTVATCALCTYPE_DESC_',
  /** Sort on PrepmtVATCalcTypeOPTION ascending */
  PrepmtvatcalctypeoptionAsc = '_PREPMTVATCALCTYPEOPTION_ASC_',
  /** sort on PrepmtVATCalcTypeOPTION descending */
  PrepmtvatcalctypeoptionDesc = '_PREPMTVATCALCTYPEOPTION_DESC_',
  /** Sort on PrepaymentVATIdentifier ascending */
  PrepaymentvatidentifierAsc = '_PREPAYMENTVATIDENTIFIER_ASC_',
  /** sort on PrepaymentVATIdentifier descending */
  PrepaymentvatidentifierDesc = '_PREPAYMENTVATIDENTIFIER_DESC_',
  /** Sort on PrepaymentTaxAreaCode ascending */
  PrepaymenttaxareacodeAsc = '_PREPAYMENTTAXAREACODE_ASC_',
  /** sort on PrepaymentTaxAreaCode descending */
  PrepaymenttaxareacodeDesc = '_PREPAYMENTTAXAREACODE_DESC_',
  /** Sort on PrepaymentTaxLiable ascending */
  PrepaymenttaxliableAsc = '_PREPAYMENTTAXLIABLE_ASC_',
  /** sort on PrepaymentTaxLiable descending */
  PrepaymenttaxliableDesc = '_PREPAYMENTTAXLIABLE_DESC_',
  /** Sort on PrepaymentTaxGroupCode ascending */
  PrepaymenttaxgroupcodeAsc = '_PREPAYMENTTAXGROUPCODE_ASC_',
  /** sort on PrepaymentTaxGroupCode descending */
  PrepaymenttaxgroupcodeDesc = '_PREPAYMENTTAXGROUPCODE_DESC_',
  /** Sort on PrepmtAmttoDeduct ascending */
  PrepmtamttodeductAsc = '_PREPMTAMTTODEDUCT_ASC_',
  /** sort on PrepmtAmttoDeduct descending */
  PrepmtamttodeductDesc = '_PREPMTAMTTODEDUCT_DESC_',
  /** Sort on PrepmtAmtDeducted ascending */
  PrepmtamtdeductedAsc = '_PREPMTAMTDEDUCTED_ASC_',
  /** sort on PrepmtAmtDeducted descending */
  PrepmtamtdeductedDesc = '_PREPMTAMTDEDUCTED_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on PrepmtAmountInvInclVAT ascending */
  PrepmtamountinvinclvatAsc = '_PREPMTAMOUNTINVINCLVAT_ASC_',
  /** sort on PrepmtAmountInvInclVAT descending */
  PrepmtamountinvinclvatDesc = '_PREPMTAMOUNTINVINCLVAT_DESC_',
  /** Sort on PrepmtAmountInvLCY ascending */
  PrepmtamountinvlcyAsc = '_PREPMTAMOUNTINVLCY_ASC_',
  /** sort on PrepmtAmountInvLCY descending */
  PrepmtamountinvlcyDesc = '_PREPMTAMOUNTINVLCY_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PrepmtVATAmountInvLCY ascending */
  PrepmtvatamountinvlcyAsc = '_PREPMTVATAMOUNTINVLCY_ASC_',
  /** sort on PrepmtVATAmountInvLCY descending */
  PrepmtvatamountinvlcyDesc = '_PREPMTVATAMOUNTINVLCY_DESC_',
  /** Sort on PrepaymentVATDifference ascending */
  PrepaymentvatdifferenceAsc = '_PREPAYMENTVATDIFFERENCE_ASC_',
  /** sort on PrepaymentVATDifference descending */
  PrepaymentvatdifferenceDesc = '_PREPAYMENTVATDIFFERENCE_DESC_',
  /** Sort on PrepmtVATDifftoDeduct ascending */
  PrepmtvatdifftodeductAsc = '_PREPMTVATDIFFTODEDUCT_ASC_',
  /** sort on PrepmtVATDifftoDeduct descending */
  PrepmtvatdifftodeductDesc = '_PREPMTVATDIFFTODEDUCT_DESC_',
  /** Sort on PrepmtVATDiffDeducted ascending */
  PrepmtvatdiffdeductedAsc = '_PREPMTVATDIFFDEDUCTED_ASC_',
  /** sort on PrepmtVATDiffDeducted descending */
  PrepmtvatdiffdeductedDesc = '_PREPMTVATDIFFDEDUCTED_DESC_',
  /** Sort on ICItemReferenceNo ascending */
  IcitemreferencenoAsc = '_ICITEMREFERENCENO_ASC_',
  /** sort on ICItemReferenceNo descending */
  IcitemreferencenoDesc = '_ICITEMREFERENCENO_DESC_',
  /** Sort on OutstandingAmtExVATLCY ascending */
  OutstandingamtexvatlcyAsc = '_OUTSTANDINGAMTEXVATLCY_ASC_',
  /** sort on OutstandingAmtExVATLCY descending */
  OutstandingamtexvatlcyDesc = '_OUTSTANDINGAMTEXVATLCY_DESC_',
  /** Sort on ARcdNotInvExVATLCY ascending */
  ArcdnotinvexvatlcyAsc = '_ARCDNOTINVEXVATLCY_ASC_',
  /** sort on ARcdNotInvExVATLCY descending */
  ArcdnotinvexvatlcyDesc = '_ARCDNOTINVEXVATLCY_DESC_',
  /** Sort on PmtDiscountAmount ascending */
  PmtdiscountamountAsc = '_PMTDISCOUNTAMOUNT_ASC_',
  /** sort on PmtDiscountAmount descending */
  PmtdiscountamountDesc = '_PMTDISCOUNTAMOUNT_DESC_',
  /** Sort on PrepmtPmtDiscountAmount ascending */
  PrepmtpmtdiscountamountAsc = '_PREPMTPMTDISCOUNTAMOUNT_ASC_',
  /** sort on PrepmtPmtDiscountAmount descending */
  PrepmtpmtdiscountamountDesc = '_PREPMTPMTDISCOUNTAMOUNT_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobLineType ascending */
  JoblinetypeAsc = '_JOBLINETYPE_ASC_',
  /** sort on JobLineType descending */
  JoblinetypeDesc = '_JOBLINETYPE_DESC_',
  /** Sort on JobLineTypeOPTION ascending */
  JoblinetypeoptionAsc = '_JOBLINETYPEOPTION_ASC_',
  /** sort on JobLineTypeOPTION descending */
  JoblinetypeoptionDesc = '_JOBLINETYPEOPTION_DESC_',
  /** Sort on JobUnitPrice ascending */
  JobunitpriceAsc = '_JOBUNITPRICE_ASC_',
  /** sort on JobUnitPrice descending */
  JobunitpriceDesc = '_JOBUNITPRICE_DESC_',
  /** Sort on JobTotalPrice ascending */
  JobtotalpriceAsc = '_JOBTOTALPRICE_ASC_',
  /** sort on JobTotalPrice descending */
  JobtotalpriceDesc = '_JOBTOTALPRICE_DESC_',
  /** Sort on JobLineAmount ascending */
  JoblineamountAsc = '_JOBLINEAMOUNT_ASC_',
  /** sort on JobLineAmount descending */
  JoblineamountDesc = '_JOBLINEAMOUNT_DESC_',
  /** Sort on JobLineDiscountAmount ascending */
  JoblinediscountamountAsc = '_JOBLINEDISCOUNTAMOUNT_ASC_',
  /** sort on JobLineDiscountAmount descending */
  JoblinediscountamountDesc = '_JOBLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on JobLineDiscount ascending */
  JoblinediscountAsc = '_JOBLINEDISCOUNT_ASC_',
  /** sort on JobLineDiscount descending */
  JoblinediscountDesc = '_JOBLINEDISCOUNT_DESC_',
  /** Sort on JobUnitPriceLCY ascending */
  JobunitpricelcyAsc = '_JOBUNITPRICELCY_ASC_',
  /** sort on JobUnitPriceLCY descending */
  JobunitpricelcyDesc = '_JOBUNITPRICELCY_DESC_',
  /** Sort on JobTotalPriceLCY ascending */
  JobtotalpricelcyAsc = '_JOBTOTALPRICELCY_ASC_',
  /** sort on JobTotalPriceLCY descending */
  JobtotalpricelcyDesc = '_JOBTOTALPRICELCY_DESC_',
  /** Sort on JobLineAmountLCY ascending */
  JoblineamountlcyAsc = '_JOBLINEAMOUNTLCY_ASC_',
  /** sort on JobLineAmountLCY descending */
  JoblineamountlcyDesc = '_JOBLINEAMOUNTLCY_DESC_',
  /** Sort on JobLineDiscAmountLCY ascending */
  JoblinediscamountlcyAsc = '_JOBLINEDISCAMOUNTLCY_ASC_',
  /** sort on JobLineDiscAmountLCY descending */
  JoblinediscamountlcyDesc = '_JOBLINEDISCAMOUNTLCY_DESC_',
  /** Sort on JobCurrencyFactor ascending */
  JobcurrencyfactorAsc = '_JOBCURRENCYFACTOR_ASC_',
  /** sort on JobCurrencyFactor descending */
  JobcurrencyfactorDesc = '_JOBCURRENCYFACTOR_DESC_',
  /** Sort on JobCurrencyCode ascending */
  JobcurrencycodeAsc = '_JOBCURRENCYCODE_ASC_',
  /** sort on JobCurrencyCode descending */
  JobcurrencycodeDesc = '_JOBCURRENCYCODE_DESC_',
  /** Sort on JobPlanningLineNo ascending */
  JobplanninglinenoAsc = '_JOBPLANNINGLINENO_ASC_',
  /** sort on JobPlanningLineNo descending */
  JobplanninglinenoDesc = '_JOBPLANNINGLINENO_DESC_',
  /** Sort on JobRemainingQty ascending */
  JobremainingqtyAsc = '_JOBREMAININGQTY_ASC_',
  /** sort on JobRemainingQty descending */
  JobremainingqtyDesc = '_JOBREMAININGQTY_DESC_',
  /** Sort on JobRemainingQtyBase ascending */
  JobremainingqtybaseAsc = '_JOBREMAININGQTYBASE_ASC_',
  /** sort on JobRemainingQtyBase descending */
  JobremainingqtybaseDesc = '_JOBREMAININGQTYBASE_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on ReturnsDeferralStartDate ascending */
  ReturnsdeferralstartdateAsc = '_RETURNSDEFERRALSTARTDATE_ASC_',
  /** sort on ReturnsDeferralStartDate descending */
  ReturnsdeferralstartdateDesc = '_RETURNSDEFERRALSTARTDATE_DESC_',
  /** Sort on ProdOrderNo ascending */
  ProdordernoAsc = '_PRODORDERNO_ASC_',
  /** sort on ProdOrderNo descending */
  ProdordernoDesc = '_PRODORDERNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on QtyRoundingPrecision ascending */
  QtyroundingprecisionAsc = '_QTYROUNDINGPRECISION_ASC_',
  /** sort on QtyRoundingPrecision descending */
  QtyroundingprecisionDesc = '_QTYROUNDINGPRECISION_DESC_',
  /** Sort on QtyRoundingPrecisionBase ascending */
  QtyroundingprecisionbaseAsc = '_QTYROUNDINGPRECISIONBASE_ASC_',
  /** sort on QtyRoundingPrecisionBase descending */
  QtyroundingprecisionbaseDesc = '_QTYROUNDINGPRECISIONBASE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on OutstandingQtyBase ascending */
  OutstandingqtybaseAsc = '_OUTSTANDINGQTYBASE_ASC_',
  /** sort on OutstandingQtyBase descending */
  OutstandingqtybaseDesc = '_OUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoInvoiceBase ascending */
  QtytoinvoicebaseAsc = '_QTYTOINVOICEBASE_ASC_',
  /** sort on QtytoInvoiceBase descending */
  QtytoinvoicebaseDesc = '_QTYTOINVOICEBASE_DESC_',
  /** Sort on QtytoReceiveBase ascending */
  QtytoreceivebaseAsc = '_QTYTORECEIVEBASE_ASC_',
  /** sort on QtytoReceiveBase descending */
  QtytoreceivebaseDesc = '_QTYTORECEIVEBASE_DESC_',
  /** Sort on QtyRcdNotInvoicedBase ascending */
  QtyrcdnotinvoicedbaseAsc = '_QTYRCDNOTINVOICEDBASE_ASC_',
  /** sort on QtyRcdNotInvoicedBase descending */
  QtyrcdnotinvoicedbaseDesc = '_QTYRCDNOTINVOICEDBASE_DESC_',
  /** Sort on QtyReceivedBase ascending */
  QtyreceivedbaseAsc = '_QTYRECEIVEDBASE_ASC_',
  /** sort on QtyReceivedBase descending */
  QtyreceivedbaseDesc = '_QTYRECEIVEDBASE_DESC_',
  /** Sort on QtyInvoicedBase ascending */
  QtyinvoicedbaseAsc = '_QTYINVOICEDBASE_ASC_',
  /** sort on QtyInvoicedBase descending */
  QtyinvoicedbaseDesc = '_QTYINVOICEDBASE_DESC_',
  /** Sort on ReservedQtyBase ascending */
  ReservedqtybaseAsc = '_RESERVEDQTYBASE_ASC_',
  /** sort on ReservedQtyBase descending */
  ReservedqtybaseDesc = '_RESERVEDQTYBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on FAPostingType ascending */
  FapostingtypeAsc = '_FAPOSTINGTYPE_ASC_',
  /** sort on FAPostingType descending */
  FapostingtypeDesc = '_FAPOSTINGTYPE_DESC_',
  /** Sort on FAPostingTypeOPTION ascending */
  FapostingtypeoptionAsc = '_FAPOSTINGTYPEOPTION_ASC_',
  /** sort on FAPostingTypeOPTION descending */
  FapostingtypeoptionDesc = '_FAPOSTINGTYPEOPTION_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on SalvageValue ascending */
  SalvagevalueAsc = '_SALVAGEVALUE_ASC_',
  /** sort on SalvageValue descending */
  SalvagevalueDesc = '_SALVAGEVALUE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DeprAcquisitionCost ascending */
  DepracquisitioncostAsc = '_DEPRACQUISITIONCOST_ASC_',
  /** sort on DeprAcquisitionCost descending */
  DepracquisitioncostDesc = '_DEPRACQUISITIONCOST_DESC_',
  /** Sort on MaintenanceCode ascending */
  MaintenancecodeAsc = '_MAINTENANCECODE_ASC_',
  /** sort on MaintenanceCode descending */
  MaintenancecodeDesc = '_MAINTENANCECODE_DESC_',
  /** Sort on InsuranceNo ascending */
  InsurancenoAsc = '_INSURANCENO_ASC_',
  /** sort on InsuranceNo descending */
  InsurancenoDesc = '_INSURANCENO_DESC_',
  /** Sort on BudgetedFANo ascending */
  BudgetedfanoAsc = '_BUDGETEDFANO_ASC_',
  /** sort on BudgetedFANo descending */
  BudgetedfanoDesc = '_BUDGETEDFANO_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on SpecialOrder ascending */
  SpecialorderAsc = '_SPECIALORDER_ASC_',
  /** sort on SpecialOrder descending */
  SpecialorderDesc = '_SPECIALORDER_DESC_',
  /** Sort on SpecialOrderSalesNo ascending */
  SpecialordersalesnoAsc = '_SPECIALORDERSALESNO_ASC_',
  /** sort on SpecialOrderSalesNo descending */
  SpecialordersalesnoDesc = '_SPECIALORDERSALESNO_DESC_',
  /** Sort on SpecialOrderSalesLineNo ascending */
  SpecialordersaleslinenoAsc = '_SPECIALORDERSALESLINENO_ASC_',
  /** sort on SpecialOrderSalesLineNo descending */
  SpecialordersaleslinenoDesc = '_SPECIALORDERSALESLINENO_DESC_',
  /** Sort on ItemReferenceNo ascending */
  ItemreferencenoAsc = '_ITEMREFERENCENO_ASC_',
  /** sort on ItemReferenceNo descending */
  ItemreferencenoDesc = '_ITEMREFERENCENO_DESC_',
  /** Sort on ItemReferenceUnitofMeasure ascending */
  ItemreferenceunitofmeasureAsc = '_ITEMREFERENCEUNITOFMEASURE_ASC_',
  /** sort on ItemReferenceUnitofMeasure descending */
  ItemreferenceunitofmeasureDesc = '_ITEMREFERENCEUNITOFMEASURE_DESC_',
  /** Sort on ItemReferenceType ascending */
  ItemreferencetypeAsc = '_ITEMREFERENCETYPE_ASC_',
  /** sort on ItemReferenceType descending */
  ItemreferencetypeDesc = '_ITEMREFERENCETYPE_DESC_',
  /** Sort on ItemReferenceTypeOPTION ascending */
  ItemreferencetypeoptionAsc = '_ITEMREFERENCETYPEOPTION_ASC_',
  /** sort on ItemReferenceTypeOPTION descending */
  ItemreferencetypeoptionDesc = '_ITEMREFERENCETYPEOPTION_DESC_',
  /** Sort on ItemReferenceTypeNo ascending */
  ItemreferencetypenoAsc = '_ITEMREFERENCETYPENO_ASC_',
  /** sort on ItemReferenceTypeNo descending */
  ItemreferencetypenoDesc = '_ITEMREFERENCETYPENO_DESC_',
  /** Sort on WhseOutstandingQtyBase ascending */
  WhseoutstandingqtybaseAsc = '_WHSEOUTSTANDINGQTYBASE_ASC_',
  /** sort on WhseOutstandingQtyBase descending */
  WhseoutstandingqtybaseDesc = '_WHSEOUTSTANDINGQTYBASE_DESC_',
  /** Sort on CompletelyReceived ascending */
  CompletelyreceivedAsc = '_COMPLETELYRECEIVED_ASC_',
  /** sort on CompletelyReceived descending */
  CompletelyreceivedDesc = '_COMPLETELYRECEIVED_DESC_',
  /** Sort on RequestedReceiptDate ascending */
  RequestedreceiptdateAsc = '_REQUESTEDRECEIPTDATE_ASC_',
  /** sort on RequestedReceiptDate descending */
  RequestedreceiptdateDesc = '_REQUESTEDRECEIPTDATE_DESC_',
  /** Sort on PromisedReceiptDate ascending */
  PromisedreceiptdateAsc = '_PROMISEDRECEIPTDATE_ASC_',
  /** sort on PromisedReceiptDate descending */
  PromisedreceiptdateDesc = '_PROMISEDRECEIPTDATE_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on InboundWhseHandlingTime ascending */
  InboundwhsehandlingtimeAsc = '_INBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on InboundWhseHandlingTime descending */
  InboundwhsehandlingtimeDesc = '_INBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PlannedReceiptDate ascending */
  PlannedreceiptdateAsc = '_PLANNEDRECEIPTDATE_ASC_',
  /** sort on PlannedReceiptDate descending */
  PlannedreceiptdateDesc = '_PLANNEDRECEIPTDATE_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on AllowItemChargeAssignment ascending */
  AllowitemchargeassignmentAsc = '_ALLOWITEMCHARGEASSIGNMENT_ASC_',
  /** sort on AllowItemChargeAssignment descending */
  AllowitemchargeassignmentDesc = '_ALLOWITEMCHARGEASSIGNMENT_DESC_',
  /** Sort on QtytoAssign ascending */
  QtytoassignAsc = '_QTYTOASSIGN_ASC_',
  /** sort on QtytoAssign descending */
  QtytoassignDesc = '_QTYTOASSIGN_DESC_',
  /** Sort on QtyAssigned ascending */
  QtyassignedAsc = '_QTYASSIGNED_ASC_',
  /** sort on QtyAssigned descending */
  QtyassignedDesc = '_QTYASSIGNED_DESC_',
  /** Sort on ReturnQtytoShip ascending */
  ReturnqtytoshipAsc = '_RETURNQTYTOSHIP_ASC_',
  /** sort on ReturnQtytoShip descending */
  ReturnqtytoshipDesc = '_RETURNQTYTOSHIP_DESC_',
  /** Sort on ReturnQtytoShipBase ascending */
  ReturnqtytoshipbaseAsc = '_RETURNQTYTOSHIPBASE_ASC_',
  /** sort on ReturnQtytoShipBase descending */
  ReturnqtytoshipbaseDesc = '_RETURNQTYTOSHIPBASE_DESC_',
  /** Sort on ReturnQtyShippedNotInvd ascending */
  ReturnqtyshippednotinvdAsc = '_RETURNQTYSHIPPEDNOTINVD_ASC_',
  /** sort on ReturnQtyShippedNotInvd descending */
  ReturnqtyshippednotinvdDesc = '_RETURNQTYSHIPPEDNOTINVD_DESC_',
  /** Sort on RetQtyShpdNotInvdBase ascending */
  RetqtyshpdnotinvdbaseAsc = '_RETQTYSHPDNOTINVDBASE_ASC_',
  /** sort on RetQtyShpdNotInvdBase descending */
  RetqtyshpdnotinvdbaseDesc = '_RETQTYSHPDNOTINVDBASE_DESC_',
  /** Sort on ReturnShpdNotInvd ascending */
  ReturnshpdnotinvdAsc = '_RETURNSHPDNOTINVD_ASC_',
  /** sort on ReturnShpdNotInvd descending */
  ReturnshpdnotinvdDesc = '_RETURNSHPDNOTINVD_DESC_',
  /** Sort on ReturnShpdNotInvdLCY ascending */
  ReturnshpdnotinvdlcyAsc = '_RETURNSHPDNOTINVDLCY_ASC_',
  /** sort on ReturnShpdNotInvdLCY descending */
  ReturnshpdnotinvdlcyDesc = '_RETURNSHPDNOTINVDLCY_DESC_',
  /** Sort on ReturnQtyShipped ascending */
  ReturnqtyshippedAsc = '_RETURNQTYSHIPPED_ASC_',
  /** sort on ReturnQtyShipped descending */
  ReturnqtyshippedDesc = '_RETURNQTYSHIPPED_DESC_',
  /** Sort on ReturnQtyShippedBase ascending */
  ReturnqtyshippedbaseAsc = '_RETURNQTYSHIPPEDBASE_ASC_',
  /** sort on ReturnQtyShippedBase descending */
  ReturnqtyshippedbaseDesc = '_RETURNQTYSHIPPEDBASE_DESC_',
  /** Sort on ReturnShipmentNo ascending */
  ReturnshipmentnoAsc = '_RETURNSHIPMENTNO_ASC_',
  /** sort on ReturnShipmentNo descending */
  ReturnshipmentnoDesc = '_RETURNSHIPMENTNO_DESC_',
  /** Sort on ReturnShipmentLineNo ascending */
  ReturnshipmentlinenoAsc = '_RETURNSHIPMENTLINENO_ASC_',
  /** sort on ReturnShipmentLineNo descending */
  ReturnshipmentlinenoDesc = '_RETURNSHIPMENTLINENO_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on Subtype ascending */
  SubtypeAsc = '_SUBTYPE_ASC_',
  /** sort on Subtype descending */
  SubtypeDesc = '_SUBTYPE_DESC_',
  /** Sort on SubtypeOPTION ascending */
  SubtypeoptionAsc = '_SUBTYPEOPTION_ASC_',
  /** sort on SubtypeOPTION descending */
  SubtypeoptionDesc = '_SUBTYPEOPTION_DESC_',
  /** Sort on CopiedFromPostedDoc ascending */
  CopiedfromposteddocAsc = '_COPIEDFROMPOSTEDDOC_ASC_',
  /** sort on CopiedFromPostedDoc descending */
  CopiedfromposteddocDesc = '_COPIEDFROMPOSTEDDOC_DESC_',
  /** Sort on PriceCalculationMethod ascending */
  PricecalculationmethodAsc = '_PRICECALCULATIONMETHOD_ASC_',
  /** sort on PriceCalculationMethod descending */
  PricecalculationmethodDesc = '_PRICECALCULATIONMETHOD_DESC_',
  /** Sort on PriceCalculationMethodOPTION ascending */
  PricecalculationmethodoptionAsc = '_PRICECALCULATIONMETHODOPTION_ASC_',
  /** sort on PriceCalculationMethodOPTION descending */
  PricecalculationmethodoptionDesc = '_PRICECALCULATIONMETHODOPTION_DESC_',
  /** Sort on AttachedDocCount ascending */
  AttacheddoccountAsc = '_ATTACHEDDOCCOUNT_ASC_',
  /** sort on AttachedDocCount descending */
  AttacheddoccountDesc = '_ATTACHEDDOCCOUNT_DESC_',
  /** Sort on OverReceiptQuantity ascending */
  OverreceiptquantityAsc = '_OVERRECEIPTQUANTITY_ASC_',
  /** sort on OverReceiptQuantity descending */
  OverreceiptquantityDesc = '_OVERRECEIPTQUANTITY_DESC_',
  /** Sort on OverReceiptCode ascending */
  OverreceiptcodeAsc = '_OVERRECEIPTCODE_ASC_',
  /** sort on OverReceiptCode descending */
  OverreceiptcodeDesc = '_OVERRECEIPTCODE_DESC_',
  /** Sort on OverReceiptApprovalStatus ascending */
  OverreceiptapprovalstatusAsc = '_OVERRECEIPTAPPROVALSTATUS_ASC_',
  /** sort on OverReceiptApprovalStatus descending */
  OverreceiptapprovalstatusDesc = '_OVERRECEIPTAPPROVALSTATUS_DESC_',
  /** Sort on OverReceiptApprovalStatusOPTION ascending */
  OverreceiptapprovalstatusoptionAsc = '_OVERRECEIPTAPPROVALSTATUSOPTION_ASC_',
  /** sort on OverReceiptApprovalStatusOPTION descending */
  OverreceiptapprovalstatusoptionDesc = '_OVERRECEIPTAPPROVALSTATUSOPTION_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on NBAPreAssignedObjectNo ascending */
  NbapreassignedobjectnoAsc = '_NBAPREASSIGNEDOBJECTNO_ASC_',
  /** sort on NBAPreAssignedObjectNo descending */
  NbapreassignedobjectnoDesc = '_NBAPREASSIGNEDOBJECTNO_DESC_',
  /** Sort on PEBChargeType ascending */
  PebchargetypeAsc = '_PEBCHARGETYPE_ASC_',
  /** sort on PEBChargeType descending */
  PebchargetypeDesc = '_PEBCHARGETYPE_DESC_',
  /** Sort on PEBChargeTypeOPTION ascending */
  PebchargetypeoptionAsc = '_PEBCHARGETYPEOPTION_ASC_',
  /** sort on PEBChargeTypeOPTION descending */
  PebchargetypeoptionDesc = '_PEBCHARGETYPEOPTION_DESC_',
  /** Sort on PEBIsDescription2 ascending */
  Pebisdescription2Asc = '_PEBISDESCRIPTION2_ASC_',
  /** sort on PEBIsDescription2 descending */
  Pebisdescription2Desc = '_PEBISDESCRIPTION2_DESC_',
  /** Sort on PEBNotincludedincalc ascending */
  PebnotincludedincalcAsc = '_PEBNOTINCLUDEDINCALC_ASC_',
  /** sort on PEBNotincludedincalc descending */
  PebnotincludedincalcDesc = '_PEBNOTINCLUDEDINCALC_DESC_',
  /** Sort on PEBNotincludedinVATcalc ascending */
  PebnotincludedinvatcalcAsc = '_PEBNOTINCLUDEDINVATCALC_ASC_',
  /** sort on PEBNotincludedinVATcalc descending */
  PebnotincludedinvatcalcDesc = '_PEBNOTINCLUDEDINVATCALC_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMContractType ascending */
  EqmcontracttypeAsc = '_EQMCONTRACTTYPE_ASC_',
  /** sort on EQMContractType descending */
  EqmcontracttypeDesc = '_EQMCONTRACTTYPE_DESC_',
  /** Sort on EQMContractTypeOPTION ascending */
  EqmcontracttypeoptionAsc = '_EQMCONTRACTTYPEOPTION_ASC_',
  /** sort on EQMContractTypeOPTION descending */
  EqmcontracttypeoptionDesc = '_EQMCONTRACTTYPEOPTION_DESC_',
  /** Sort on EQMType ascending */
  EqmtypeAsc = '_EQMTYPE_ASC_',
  /** sort on EQMType descending */
  EqmtypeDesc = '_EQMTYPE_DESC_',
  /** Sort on EQMTypeOPTION ascending */
  EqmtypeoptionAsc = '_EQMTYPEOPTION_ASC_',
  /** sort on EQMTypeOPTION descending */
  EqmtypeoptionDesc = '_EQMTYPEOPTION_DESC_',
  /** Sort on EQMNo ascending */
  EqmnoAsc = '_EQMNO_ASC_',
  /** sort on EQMNo descending */
  EqmnoDesc = '_EQMNO_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMObjectCostAssignment ascending */
  EqmobjectcostassignmentAsc = '_EQMOBJECTCOSTASSIGNMENT_ASC_',
  /** sort on EQMObjectCostAssignment descending */
  EqmobjectcostassignmentDesc = '_EQMOBJECTCOSTASSIGNMENT_DESC_',
  /** Sort on EQMFixedAssetSetupGroup ascending */
  EqmfixedassetsetupgroupAsc = '_EQMFIXEDASSETSETUPGROUP_ASC_',
  /** sort on EQMFixedAssetSetupGroup descending */
  EqmfixedassetsetupgroupDesc = '_EQMFIXEDASSETSETUPGROUP_DESC_',
  /** Sort on EQMFixedAssetNo ascending */
  EqmfixedassetnoAsc = '_EQMFIXEDASSETNO_ASC_',
  /** sort on EQMFixedAssetNo descending */
  EqmfixedassetnoDesc = '_EQMFIXEDASSETNO_DESC_',
  /** Sort on EQMORIGPurchLineNo ascending */
  EqmorigpurchlinenoAsc = '_EQMORIGPURCHLINENO_ASC_',
  /** sort on EQMORIGPurchLineNo descending */
  EqmorigpurchlinenoDesc = '_EQMORIGPURCHLINENO_DESC_',
  /** Sort on EQMWorkshop ascending */
  EqmworkshopAsc = '_EQMWORKSHOP_ASC_',
  /** sort on EQMWorkshop descending */
  EqmworkshopDesc = '_EQMWORKSHOP_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMReRentNo ascending */
  EqmrerentnoAsc = '_EQMRERENTNO_ASC_',
  /** sort on EQMReRentNo descending */
  EqmrerentnoDesc = '_EQMRERENTNO_DESC_',
  /** Sort on EQMSerialNo ascending */
  EqmserialnoAsc = '_EQMSERIALNO_ASC_',
  /** sort on EQMSerialNo descending */
  EqmserialnoDesc = '_EQMSERIALNO_DESC_',
  /** Sort on EQMObjectBinCode ascending */
  EqmobjectbincodeAsc = '_EQMOBJECTBINCODE_ASC_',
  /** sort on EQMObjectBinCode descending */
  EqmobjectbincodeDesc = '_EQMOBJECTBINCODE_DESC_',
  /** Sort on EQMManufacturerCode ascending */
  EqmmanufacturercodeAsc = '_EQMMANUFACTURERCODE_ASC_',
  /** sort on EQMManufacturerCode descending */
  EqmmanufacturercodeDesc = '_EQMMANUFACTURERCODE_DESC_',
  /** Sort on EQMManufacturerModelCode ascending */
  EqmmanufacturermodelcodeAsc = '_EQMMANUFACTURERMODELCODE_ASC_',
  /** sort on EQMManufacturerModelCode descending */
  EqmmanufacturermodelcodeDesc = '_EQMMANUFACTURERMODELCODE_DESC_',
  /** Sort on EQMAllocateManufacturerCode ascending */
  EqmallocatemanufacturercodeAsc = '_EQMALLOCATEMANUFACTURERCODE_ASC_',
  /** sort on EQMAllocateManufacturerCode descending */
  EqmallocatemanufacturercodeDesc = '_EQMALLOCATEMANUFACTURERCODE_DESC_',
  /** Sort on EQMAllocateManuModelCode ascending */
  EqmallocatemanumodelcodeAsc = '_EQMALLOCATEMANUMODELCODE_ASC_',
  /** sort on EQMAllocateManuModelCode descending */
  EqmallocatemanumodelcodeDesc = '_EQMALLOCATEMANUMODELCODE_DESC_',
  /** Sort on EQMItemtoObjectFlow ascending */
  EqmitemtoobjectflowAsc = '_EQMITEMTOOBJECTFLOW_ASC_',
  /** sort on EQMItemtoObjectFlow descending */
  EqmitemtoobjectflowDesc = '_EQMITEMTOOBJECTFLOW_DESC_',
  /** Sort on RoutingNo ascending */
  RoutingnoAsc = '_ROUTINGNO_ASC_',
  /** sort on RoutingNo descending */
  RoutingnoDesc = '_ROUTINGNO_DESC_',
  /** Sort on OperationNo ascending */
  OperationnoAsc = '_OPERATIONNO_ASC_',
  /** sort on OperationNo descending */
  OperationnoDesc = '_OPERATIONNO_DESC_',
  /** Sort on WorkCenterNo ascending */
  WorkcenternoAsc = '_WORKCENTERNO_ASC_',
  /** sort on WorkCenterNo descending */
  WorkcenternoDesc = '_WORKCENTERNO_DESC_',
  /** Sort on Finished ascending */
  FinishedAsc = '_FINISHED_ASC_',
  /** sort on Finished descending */
  FinishedDesc = '_FINISHED_DESC_',
  /** Sort on ProdOrderLineNo ascending */
  ProdorderlinenoAsc = '_PRODORDERLINENO_ASC_',
  /** sort on ProdOrderLineNo descending */
  ProdorderlinenoDesc = '_PRODORDERLINENO_DESC_',
  /** Sort on OverheadRate ascending */
  OverheadrateAsc = '_OVERHEADRATE_ASC_',
  /** sort on OverheadRate descending */
  OverheadrateDesc = '_OVERHEADRATE_DESC_',
  /** Sort on MPSOrder ascending */
  MpsorderAsc = '_MPSORDER_ASC_',
  /** sort on MPSOrder descending */
  MpsorderDesc = '_MPSORDER_DESC_',
  /** Sort on PlanningFlexibility ascending */
  PlanningflexibilityAsc = '_PLANNINGFLEXIBILITY_ASC_',
  /** sort on PlanningFlexibility descending */
  PlanningflexibilityDesc = '_PLANNINGFLEXIBILITY_DESC_',
  /** Sort on PlanningFlexibilityOPTION ascending */
  PlanningflexibilityoptionAsc = '_PLANNINGFLEXIBILITYOPTION_ASC_',
  /** sort on PlanningFlexibilityOPTION descending */
  PlanningflexibilityoptionDesc = '_PLANNINGFLEXIBILITYOPTION_DESC_',
  /** Sort on SafetyLeadTime ascending */
  SafetyleadtimeAsc = '_SAFETYLEADTIME_ASC_',
  /** sort on SafetyLeadTime descending */
  SafetyleadtimeDesc = '_SAFETYLEADTIME_DESC_',
  /** Sort on RoutingReferenceNo ascending */
  RoutingreferencenoAsc = '_ROUTINGREFERENCENO_ASC_',
  /** sort on RoutingReferenceNo descending */
  RoutingreferencenoDesc = '_ROUTINGREFERENCENO_DESC_',
  /** Sort on ExternalLineNo ascending */
  ExternallinenoAsc = '_EXTERNALLINENO_ASC_',
  /** sort on ExternalLineNo descending */
  ExternallinenoDesc = '_EXTERNALLINENO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortPurchasingInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on SpecialOrder ascending */
  SpecialorderAsc = '_SPECIALORDER_ASC_',
  /** sort on SpecialOrder descending */
  SpecialorderDesc = '_SPECIALORDER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortQuoteHeaderInput {
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on Version ascending */
  VersionAsc = '_VERSION_ASC_',
  /** sort on Version descending */
  VersionDesc = '_VERSION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on StartDate ascending */
  StartdateAsc = '_STARTDATE_ASC_',
  /** sort on StartDate descending */
  StartdateDesc = '_STARTDATE_DESC_',
  /** Sort on EndDate ascending */
  EnddateAsc = '_ENDDATE_ASC_',
  /** sort on EndDate descending */
  EnddateDesc = '_ENDDATE_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on SalesPerson ascending */
  SalespersonAsc = '_SALESPERSON_ASC_',
  /** sort on SalesPerson descending */
  SalespersonDesc = '_SALESPERSON_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on ExternalText ascending */
  ExternaltextAsc = '_EXTERNALTEXT_ASC_',
  /** sort on ExternalText descending */
  ExternaltextDesc = '_EXTERNALTEXT_DESC_',
  /** Sort on InternalText ascending */
  InternaltextAsc = '_INTERNALTEXT_ASC_',
  /** sort on InternalText descending */
  InternaltextDesc = '_INTERNALTEXT_DESC_',
  /** Sort on ProjectSum ascending */
  ProjectsumAsc = '_PROJECTSUM_ASC_',
  /** sort on ProjectSum descending */
  ProjectsumDesc = '_PROJECTSUM_DESC_',
  /** Sort on LicenseSum ascending */
  LicensesumAsc = '_LICENSESUM_ASC_',
  /** sort on LicenseSum descending */
  LicensesumDesc = '_LICENSESUM_DESC_',
  /** Sort on LicenseFee ascending */
  LicensefeeAsc = '_LICENSEFEE_ASC_',
  /** sort on LicenseFee descending */
  LicensefeeDesc = '_LICENSEFEE_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortQuoteLineInput {
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on Version ascending */
  VersionAsc = '_VERSION_ASC_',
  /** sort on Version descending */
  VersionDesc = '_VERSION_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on Task ascending */
  TaskAsc = '_TASK_ASC_',
  /** sort on Task descending */
  TaskDesc = '_TASK_DESC_',
  /** Sort on TaskGrouping ascending */
  TaskgroupingAsc = '_TASKGROUPING_ASC_',
  /** sort on TaskGrouping descending */
  TaskgroupingDesc = '_TASKGROUPING_DESC_',
  /** Sort on Days ascending */
  DaysAsc = '_DAYS_ASC_',
  /** sort on Days descending */
  DaysDesc = '_DAYS_DESC_',
  /** Sort on Sum ascending */
  SumAsc = '_SUM_ASC_',
  /** sort on Sum descending */
  SumDesc = '_SUM_DESC_',
  /** Sort on InvoicedSum ascending */
  InvoicedsumAsc = '_INVOICEDSUM_ASC_',
  /** sort on InvoicedSum descending */
  InvoicedsumDesc = '_INVOICEDSUM_DESC_',
  /** Sort on OnOrderSum ascending */
  OnordersumAsc = '_ONORDERSUM_ASC_',
  /** sort on OnOrderSum descending */
  OnordersumDesc = '_ONORDERSUM_DESC_',
  /** Sort on LinkedTo ascending */
  LinkedtoAsc = '_LINKEDTO_ASC_',
  /** sort on LinkedTo descending */
  LinkedtoDesc = '_LINKEDTO_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortQuoteTaskGroupInput {
  /** Sort on Group ascending */
  GroupAsc = '_GROUP_ASC_',
  /** sort on Group descending */
  GroupDesc = '_GROUP_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalHeaderInput {
  /** Sort on ContractType ascending */
  ContracttypeAsc = '_CONTRACTTYPE_ASC_',
  /** sort on ContractType descending */
  ContracttypeDesc = '_CONTRACTTYPE_DESC_',
  /** Sort on ContractTypeOPTION ascending */
  ContracttypeoptionAsc = '_CONTRACTTYPEOPTION_ASC_',
  /** sort on ContractTypeOPTION descending */
  ContracttypeoptionDesc = '_CONTRACTTYPEOPTION_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ChangeStatus ascending */
  ChangestatusAsc = '_CHANGESTATUS_ASC_',
  /** sort on ChangeStatus descending */
  ChangestatusDesc = '_CHANGESTATUS_DESC_',
  /** Sort on ChangeStatusOPTION ascending */
  ChangestatusoptionAsc = '_CHANGESTATUSOPTION_ASC_',
  /** sort on ChangeStatusOPTION descending */
  ChangestatusoptionDesc = '_CHANGESTATUSOPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on ContactName ascending */
  ContactnameAsc = '_CONTACTNAME_ASC_',
  /** sort on ContactName descending */
  ContactnameDesc = '_CONTACTNAME_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on BreakInvoiceatExpDate ascending */
  BreakinvoiceatexpdateAsc = '_BREAKINVOICEATEXPDATE_ASC_',
  /** sort on BreakInvoiceatExpDate descending */
  BreakinvoiceatexpdateDesc = '_BREAKINVOICEATEXPDATE_DESC_',
  /** Sort on Printed ascending */
  PrintedAsc = '_PRINTED_ASC_',
  /** sort on Printed descending */
  PrintedDesc = '_PRINTED_DESC_',
  /** Sort on PrintedTime ascending */
  PrintedtimeAsc = '_PRINTEDTIME_ASC_',
  /** sort on PrintedTime descending */
  PrintedtimeDesc = '_PRINTEDTIME_DESC_',
  /** Sort on InvoiceFrequencyNOTINUSE ascending */
  InvoicefrequencynotinuseAsc = '_INVOICEFREQUENCYNOTINUSE_ASC_',
  /** sort on InvoiceFrequencyNOTINUSE descending */
  InvoicefrequencynotinuseDesc = '_INVOICEFREQUENCYNOTINUSE_DESC_',
  /** Sort on InvoiceFrequencyNOTINUSEOPTION ascending */
  InvoicefrequencynotinuseoptionAsc = '_INVOICEFREQUENCYNOTINUSEOPTION_ASC_',
  /** sort on InvoiceFrequencyNOTINUSEOPTION descending */
  InvoicefrequencynotinuseoptionDesc = '_INVOICEFREQUENCYNOTINUSEOPTION_DESC_',
  /** Sort on LastInvoiceDate ascending */
  LastinvoicedateAsc = '_LASTINVOICEDATE_ASC_',
  /** sort on LastInvoiceDate descending */
  LastinvoicedateDesc = '_LASTINVOICEDATE_DESC_',
  /** Sort on NextInvoiceDate ascending */
  NextinvoicedateAsc = '_NEXTINVOICEDATE_ASC_',
  /** sort on NextInvoiceDate descending */
  NextinvoicedateDesc = '_NEXTINVOICEDATE_DESC_',
  /** Sort on ContractDate ascending */
  ContractdateAsc = '_CONTRACTDATE_ASC_',
  /** sort on ContractDate descending */
  ContractdateDesc = '_CONTRACTDATE_DESC_',
  /** Sort on StartRentalPeriodDate ascending */
  StartrentalperioddateAsc = '_STARTRENTALPERIODDATE_ASC_',
  /** sort on StartRentalPeriodDate descending */
  StartrentalperioddateDesc = '_STARTRENTALPERIODDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on TermofContract ascending */
  TermofcontractAsc = '_TERMOFCONTRACT_ASC_',
  /** sort on TermofContract descending */
  TermofcontractDesc = '_TERMOFCONTRACT_DESC_',
  /** Sort on AnnualAmount ascending */
  AnnualamountAsc = '_ANNUALAMOUNT_ASC_',
  /** sort on AnnualAmount descending */
  AnnualamountDesc = '_ANNUALAMOUNT_DESC_',
  /** Sort on AmountperPeriod ascending */
  AmountperperiodAsc = '_AMOUNTPERPERIOD_ASC_',
  /** sort on AmountperPeriod descending */
  AmountperperiodDesc = '_AMOUNTPERPERIOD_DESC_',
  /** Sort on CombineInvoices ascending */
  CombineinvoicesAsc = '_COMBINEINVOICES_ASC_',
  /** sort on CombineInvoices descending */
  CombineinvoicesDesc = '_COMBINEINVOICES_DESC_',
  /** Sort on Prepaid ascending */
  PrepaidAsc = '_PREPAID_ASC_',
  /** sort on Prepaid descending */
  PrepaidDesc = '_PREPAID_DESC_',
  /** Sort on NextInvoicePeriod ascending */
  NextinvoiceperiodAsc = '_NEXTINVOICEPERIOD_ASC_',
  /** sort on NextInvoicePeriod descending */
  NextinvoiceperiodDesc = '_NEXTINVOICEPERIOD_DESC_',
  /** Sort on RentalZoneCode ascending */
  RentalzonecodeAsc = '_RENTALZONECODE_ASC_',
  /** sort on RentalZoneCode descending */
  RentalzonecodeDesc = '_RENTALZONECODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on CombineMethod ascending */
  CombinemethodAsc = '_COMBINEMETHOD_ASC_',
  /** sort on CombineMethod descending */
  CombinemethodDesc = '_COMBINEMETHOD_DESC_',
  /** Sort on CombineMethodOPTION ascending */
  CombinemethodoptionAsc = '_COMBINEMETHODOPTION_ASC_',
  /** sort on CombineMethodOPTION descending */
  CombinemethodoptionDesc = '_COMBINEMETHODOPTION_DESC_',
  /** Sort on CancelReasonCode ascending */
  CancelreasoncodeAsc = '_CANCELREASONCODE_ASC_',
  /** sort on CancelReasonCode descending */
  CancelreasoncodeDesc = '_CANCELREASONCODE_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on QuoteType ascending */
  QuotetypeAsc = '_QUOTETYPE_ASC_',
  /** sort on QuoteType descending */
  QuotetypeDesc = '_QUOTETYPE_DESC_',
  /** Sort on QuoteTypeOPTION ascending */
  QuotetypeoptionAsc = '_QUOTETYPEOPTION_ASC_',
  /** sort on QuoteTypeOPTION descending */
  QuotetypeoptionDesc = '_QUOTETYPEOPTION_DESC_',
  /** Sort on ContractGroupCode ascending */
  ContractgroupcodeAsc = '_CONTRACTGROUPCODE_ASC_',
  /** sort on ContractGroupCode descending */
  ContractgroupcodeDesc = '_CONTRACTGROUPCODE_DESC_',
  /** Sort on RentalOrderType ascending */
  RentalordertypeAsc = '_RENTALORDERTYPE_ASC_',
  /** sort on RentalOrderType descending */
  RentalordertypeDesc = '_RENTALORDERTYPE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on TemplateNo ascending */
  TemplatenoAsc = '_TEMPLATENO_ASC_',
  /** sort on TemplateNo descending */
  TemplatenoDesc = '_TEMPLATENO_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on CustomerRentalPriceGroup ascending */
  CustomerrentalpricegroupAsc = '_CUSTOMERRENTALPRICEGROUP_ASC_',
  /** sort on CustomerRentalPriceGroup descending */
  CustomerrentalpricegroupDesc = '_CUSTOMERRENTALPRICEGROUP_DESC_',
  /** Sort on CustomerSalesPriceGroup ascending */
  CustomersalespricegroupAsc = '_CUSTOMERSALESPRICEGROUP_ASC_',
  /** sort on CustomerSalesPriceGroup descending */
  CustomersalespricegroupDesc = '_CUSTOMERSALESPRICEGROUP_DESC_',
  /** Sort on CustomerSalesDiscGroup ascending */
  CustomersalesdiscgroupAsc = '_CUSTOMERSALESDISCGROUP_ASC_',
  /** sort on CustomerSalesDiscGroup descending */
  CustomersalesdiscgroupDesc = '_CUSTOMERSALESDISCGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on Probability ascending */
  ProbabilityAsc = '_PROBABILITY_ASC_',
  /** sort on Probability descending */
  ProbabilityDesc = '_PROBABILITY_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on NextInvoicePeriodStart ascending */
  NextinvoiceperiodstartAsc = '_NEXTINVOICEPERIODSTART_ASC_',
  /** sort on NextInvoicePeriodStart descending */
  NextinvoiceperiodstartDesc = '_NEXTINVOICEPERIODSTART_DESC_',
  /** Sort on NextInvoicePeriodEnd ascending */
  NextinvoiceperiodendAsc = '_NEXTINVOICEPERIODEND_ASC_',
  /** sort on NextInvoicePeriodEnd descending */
  NextinvoiceperiodendDesc = '_NEXTINVOICEPERIODEND_DESC_',
  /** Sort on FromQuoteNo ascending */
  FromquotenoAsc = '_FROMQUOTENO_ASC_',
  /** sort on FromQuoteNo descending */
  FromquotenoDesc = '_FROMQUOTENO_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ContactNo ascending */
  ContactnoAsc = '_CONTACTNO_ASC_',
  /** sort on ContactNo descending */
  ContactnoDesc = '_CONTACTNO_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on TempInvoiceDate ascending */
  TempinvoicedateAsc = '_TEMPINVOICEDATE_ASC_',
  /** sort on TempInvoiceDate descending */
  TempinvoicedateDesc = '_TEMPINVOICEDATE_DESC_',
  /** Sort on TermCode ascending */
  TermcodeAsc = '_TERMCODE_ASC_',
  /** sort on TermCode descending */
  TermcodeDesc = '_TERMCODE_DESC_',
  /** Sort on Contractterminatedcomplete ascending */
  ContractterminatedcompleteAsc = '_CONTRACTTERMINATEDCOMPLETE_ASC_',
  /** sort on Contractterminatedcomplete descending */
  ContractterminatedcompleteDesc = '_CONTRACTTERMINATEDCOMPLETE_DESC_',
  /** Sort on NextRentalInvoiceFrom ascending */
  NextrentalinvoicefromAsc = '_NEXTRENTALINVOICEFROM_ASC_',
  /** sort on NextRentalInvoiceFrom descending */
  NextrentalinvoicefromDesc = '_NEXTRENTALINVOICEFROM_DESC_',
  /** Sort on NextRentalInvoiceTo ascending */
  NextrentalinvoicetoAsc = '_NEXTRENTALINVOICETO_ASC_',
  /** sort on NextRentalInvoiceTo descending */
  NextrentalinvoicetoDesc = '_NEXTRENTALINVOICETO_DESC_',
  /** Sort on TempReservation ascending */
  TempreservationAsc = '_TEMPRESERVATION_ASC_',
  /** sort on TempReservation descending */
  TempreservationDesc = '_TEMPRESERVATION_DESC_',
  /** Sort on TempDelivery ascending */
  TempdeliveryAsc = '_TEMPDELIVERY_ASC_',
  /** sort on TempDelivery descending */
  TempdeliveryDesc = '_TEMPDELIVERY_DESC_',
  /** Sort on TempInvoice ascending */
  TempinvoiceAsc = '_TEMPINVOICE_ASC_',
  /** sort on TempInvoice descending */
  TempinvoiceDesc = '_TEMPINVOICE_DESC_',
  /** Sort on TempDocumentType ascending */
  TempdocumenttypeAsc = '_TEMPDOCUMENTTYPE_ASC_',
  /** sort on TempDocumentType descending */
  TempdocumenttypeDesc = '_TEMPDOCUMENTTYPE_DESC_',
  /** Sort on TempDocumentTypeOPTION ascending */
  TempdocumenttypeoptionAsc = '_TEMPDOCUMENTTYPEOPTION_ASC_',
  /** sort on TempDocumentTypeOPTION descending */
  TempdocumenttypeoptionDesc = '_TEMPDOCUMENTTYPEOPTION_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on InvoicingStatus ascending */
  InvoicingstatusAsc = '_INVOICINGSTATUS_ASC_',
  /** sort on InvoicingStatus descending */
  InvoicingstatusDesc = '_INVOICINGSTATUS_DESC_',
  /** Sort on InvoicingStatusOPTION ascending */
  InvoicingstatusoptionAsc = '_INVOICINGSTATUSOPTION_ASC_',
  /** sort on InvoicingStatusOPTION descending */
  InvoicingstatusoptionDesc = '_INVOICINGSTATUSOPTION_DESC_',
  /** Sort on CustomerProjectDimension ascending */
  CustomerprojectdimensionAsc = '_CUSTOMERPROJECTDIMENSION_ASC_',
  /** sort on CustomerProjectDimension descending */
  CustomerprojectdimensionDesc = '_CUSTOMERPROJECTDIMENSION_DESC_',
  /** Sort on SalesHeaderNoSale ascending */
  SalesheadernosaleAsc = '_SALESHEADERNOSALE_ASC_',
  /** sort on SalesHeaderNoSale descending */
  SalesheadernosaleDesc = '_SALESHEADERNOSALE_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on DoNotShowDialogPrompt ascending */
  DonotshowdialogpromptAsc = '_DONOTSHOWDIALOGPROMPT_ASC_',
  /** sort on DoNotShowDialogPrompt descending */
  DonotshowdialogpromptDesc = '_DONOTSHOWDIALOGPROMPT_DESC_',
  /** Sort on RentalStartingFee ascending */
  RentalstartingfeeAsc = '_RENTALSTARTINGFEE_ASC_',
  /** sort on RentalStartingFee descending */
  RentalstartingfeeDesc = '_RENTALSTARTINGFEE_DESC_',
  /** Sort on TempStartRentalPeriod ascending */
  TempstartrentalperiodAsc = '_TEMPSTARTRENTALPERIOD_ASC_',
  /** sort on TempStartRentalPeriod descending */
  TempstartrentalperiodDesc = '_TEMPSTARTRENTALPERIOD_DESC_',
  /** Sort on FrequenceFixDaysNOTINUSE ascending */
  FrequencefixdaysnotinuseAsc = '_FREQUENCEFIXDAYSNOTINUSE_ASC_',
  /** sort on FrequenceFixDaysNOTINUSE descending */
  FrequencefixdaysnotinuseDesc = '_FREQUENCEFIXDAYSNOTINUSE_DESC_',
  /** Sort on CustomerRentalDiscGroup ascending */
  CustomerrentaldiscgroupAsc = '_CUSTOMERRENTALDISCGROUP_ASC_',
  /** sort on CustomerRentalDiscGroup descending */
  CustomerrentaldiscgroupDesc = '_CUSTOMERRENTALDISCGROUP_DESC_',
  /** Sort on InvoiceSequence ascending */
  InvoicesequenceAsc = '_INVOICESEQUENCE_ASC_',
  /** sort on InvoiceSequence descending */
  InvoicesequenceDesc = '_INVOICESEQUENCE_DESC_',
  /** Sort on SelltoCustomerTemplateCode ascending */
  SelltocustomertemplatecodeAsc = '_SELLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on SelltoCustomerTemplateCode descending */
  SelltocustomertemplatecodeDesc = '_SELLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on SelltoContactNo ascending */
  SelltocontactnoAsc = '_SELLTOCONTACTNO_ASC_',
  /** sort on SelltoContactNo descending */
  SelltocontactnoDesc = '_SELLTOCONTACTNO_DESC_',
  /** Sort on BilltoCustomerTemplateCode ascending */
  BilltocustomertemplatecodeAsc = '_BILLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on BilltoCustomerTemplateCode descending */
  BilltocustomertemplatecodeDesc = '_BILLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on ShiptoPhoneNo ascending */
  ShiptophonenoAsc = '_SHIPTOPHONENO_ASC_',
  /** sort on ShiptoPhoneNo descending */
  ShiptophonenoDesc = '_SHIPTOPHONENO_DESC_',
  /** Sort on ShiptoMobilePhoneNo ascending */
  ShiptomobilephonenoAsc = '_SHIPTOMOBILEPHONENO_ASC_',
  /** sort on ShiptoMobilePhoneNo descending */
  ShiptomobilephonenoDesc = '_SHIPTOMOBILEPHONENO_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on LocationManagerContactNo ascending */
  LocationmanagercontactnoAsc = '_LOCATIONMANAGERCONTACTNO_ASC_',
  /** sort on LocationManagerContactNo descending */
  LocationmanagercontactnoDesc = '_LOCATIONMANAGERCONTACTNO_DESC_',
  /** Sort on ProjectManagerContactNo ascending */
  ProjectmanagercontactnoAsc = '_PROJECTMANAGERCONTACTNO_ASC_',
  /** sort on ProjectManagerContactNo descending */
  ProjectmanagercontactnoDesc = '_PROJECTMANAGERCONTACTNO_DESC_',
  /** Sort on DeliveryContactNo ascending */
  DeliverycontactnoAsc = '_DELIVERYCONTACTNO_ASC_',
  /** sort on DeliveryContactNo descending */
  DeliverycontactnoDesc = '_DELIVERYCONTACTNO_DESC_',
  /** Sort on FixedPriceContract ascending */
  FixedpricecontractAsc = '_FIXEDPRICECONTRACT_ASC_',
  /** sort on FixedPriceContract descending */
  FixedpricecontractDesc = '_FIXEDPRICECONTRACT_DESC_',
  /** Sort on UniqueID ascending */
  UniqueidAsc = '_UNIQUEID_ASC_',
  /** sort on UniqueID descending */
  UniqueidDesc = '_UNIQUEID_DESC_',
  /** Sort on CreatedTime ascending */
  CreatedtimeAsc = '_CREATEDTIME_ASC_',
  /** sort on CreatedTime descending */
  CreatedtimeDesc = '_CREATEDTIME_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on CustomerDepositCode ascending */
  CustomerdepositcodeAsc = '_CUSTOMERDEPOSITCODE_ASC_',
  /** sort on CustomerDepositCode descending */
  CustomerdepositcodeDesc = '_CUSTOMERDEPOSITCODE_DESC_',
  /** Sort on CustomerDepositAmttoPay ascending */
  CustomerdepositamttopayAsc = '_CUSTOMERDEPOSITAMTTOPAY_ASC_',
  /** sort on CustomerDepositAmttoPay descending */
  CustomerdepositamttopayDesc = '_CUSTOMERDEPOSITAMTTOPAY_DESC_',
  /** Sort on GrantedDeposit ascending */
  GranteddepositAsc = '_GRANTEDDEPOSIT_ASC_',
  /** sort on GrantedDeposit descending */
  GranteddepositDesc = '_GRANTEDDEPOSIT_DESC_',
  /** Sort on RentalInvoiceDayofMonth ascending */
  RentalinvoicedayofmonthAsc = '_RENTALINVOICEDAYOFMONTH_ASC_',
  /** sort on RentalInvoiceDayofMonth descending */
  RentalinvoicedayofmonthDesc = '_RENTALINVOICEDAYOFMONTH_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on MemoText ascending */
  MemotextAsc = '_MEMOTEXT_ASC_',
  /** sort on MemoText descending */
  MemotextDesc = '_MEMOTEXT_DESC_',
  /** Sort on JournalEntryNo ascending */
  JournalentrynoAsc = '_JOURNALENTRYNO_ASC_',
  /** sort on JournalEntryNo descending */
  JournalentrynoDesc = '_JOURNALENTRYNO_DESC_',
  /** Sort on UseRentalJnlNos ascending */
  UserentaljnlnosAsc = '_USERENTALJNLNOS_ASC_',
  /** sort on UseRentalJnlNos descending */
  UserentaljnlnosDesc = '_USERENTALJNLNOS_DESC_',
  /** Sort on CustomerType ascending */
  CustomertypeAsc = '_CUSTOMERTYPE_ASC_',
  /** sort on CustomerType descending */
  CustomertypeDesc = '_CUSTOMERTYPE_DESC_',
  /** Sort on CustomerTypeOPTION ascending */
  CustomertypeoptionAsc = '_CUSTOMERTYPEOPTION_ASC_',
  /** sort on CustomerTypeOPTION descending */
  CustomertypeoptionDesc = '_CUSTOMERTYPEOPTION_DESC_',
  /** Sort on FullyReturned ascending */
  FullyreturnedAsc = '_FULLYRETURNED_ASC_',
  /** sort on FullyReturned descending */
  FullyreturnedDesc = '_FULLYRETURNED_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on InvoiceDocType ascending */
  InvoicedoctypeAsc = '_INVOICEDOCTYPE_ASC_',
  /** sort on InvoiceDocType descending */
  InvoicedoctypeDesc = '_INVOICEDOCTYPE_DESC_',
  /** Sort on InvoiceDocTypeOPTION ascending */
  InvoicedoctypeoptionAsc = '_INVOICEDOCTYPEOPTION_ASC_',
  /** sort on InvoiceDocTypeOPTION descending */
  InvoicedoctypeoptionDesc = '_INVOICEDOCTYPEOPTION_DESC_',
  /** Sort on RentalAmountExclVat ascending */
  RentalamountexclvatAsc = '_RENTALAMOUNTEXCLVAT_ASC_',
  /** sort on RentalAmountExclVat descending */
  RentalamountexclvatDesc = '_RENTALAMOUNTEXCLVAT_DESC_',
  /** Sort on RentalLineDiscAmount ascending */
  RentallinediscamountAsc = '_RENTALLINEDISCAMOUNT_ASC_',
  /** sort on RentalLineDiscAmount descending */
  RentallinediscamountDesc = '_RENTALLINEDISCAMOUNT_DESC_',
  /** Sort on RentalTotalAmountExclVat ascending */
  RentaltotalamountexclvatAsc = '_RENTALTOTALAMOUNTEXCLVAT_ASC_',
  /** sort on RentalTotalAmountExclVat descending */
  RentaltotalamountexclvatDesc = '_RENTALTOTALAMOUNTEXCLVAT_DESC_',
  /** Sort on RentalAmountInclVat ascending */
  RentalamountinclvatAsc = '_RENTALAMOUNTINCLVAT_ASC_',
  /** sort on RentalAmountInclVat descending */
  RentalamountinclvatDesc = '_RENTALAMOUNTINCLVAT_DESC_',
  /** Sort on RentalVatAmount ascending */
  RentalvatamountAsc = '_RENTALVATAMOUNT_ASC_',
  /** sort on RentalVatAmount descending */
  RentalvatamountDesc = '_RENTALVATAMOUNT_DESC_',
  /** Sort on SalesAmountExclVat ascending */
  SalesamountexclvatAsc = '_SALESAMOUNTEXCLVAT_ASC_',
  /** sort on SalesAmountExclVat descending */
  SalesamountexclvatDesc = '_SALESAMOUNTEXCLVAT_DESC_',
  /** Sort on SalesLineDiscAmount ascending */
  SaleslinediscamountAsc = '_SALESLINEDISCAMOUNT_ASC_',
  /** sort on SalesLineDiscAmount descending */
  SaleslinediscamountDesc = '_SALESLINEDISCAMOUNT_DESC_',
  /** Sort on SalesTotalAmountExclVat ascending */
  SalestotalamountexclvatAsc = '_SALESTOTALAMOUNTEXCLVAT_ASC_',
  /** sort on SalesTotalAmountExclVat descending */
  SalestotalamountexclvatDesc = '_SALESTOTALAMOUNTEXCLVAT_DESC_',
  /** Sort on SalesAmountInclVat ascending */
  SalesamountinclvatAsc = '_SALESAMOUNTINCLVAT_ASC_',
  /** sort on SalesAmountInclVat descending */
  SalesamountinclvatDesc = '_SALESAMOUNTINCLVAT_DESC_',
  /** Sort on SalesVatAmount ascending */
  SalesvatamountAsc = '_SALESVATAMOUNT_ASC_',
  /** sort on SalesVatAmount descending */
  SalesvatamountDesc = '_SALESVATAMOUNT_DESC_',
  /** Sort on SalesProfit ascending */
  SalesprofitAsc = '_SALESPROFIT_ASC_',
  /** sort on SalesProfit descending */
  SalesprofitDesc = '_SALESPROFIT_DESC_',
  /** Sort on SalesQtyToInvoice ascending */
  SalesqtytoinvoiceAsc = '_SALESQTYTOINVOICE_ASC_',
  /** sort on SalesQtyToInvoice descending */
  SalesqtytoinvoiceDesc = '_SALESQTYTOINVOICE_DESC_',
  /** Sort on RentalInsurancAmtExclVat ascending */
  RentalinsurancamtexclvatAsc = '_RENTALINSURANCAMTEXCLVAT_ASC_',
  /** sort on RentalInsurancAmtExclVat descending */
  RentalinsurancamtexclvatDesc = '_RENTALINSURANCAMTEXCLVAT_DESC_',
  /** Sort on RentalInsurancAmtInclVat ascending */
  RentalinsurancamtinclvatAsc = '_RENTALINSURANCAMTINCLVAT_ASC_',
  /** sort on RentalInsurancAmtInclVat descending */
  RentalinsurancamtinclvatDesc = '_RENTALINSURANCAMTINCLVAT_DESC_',
  /** Sort on RentalContractCost ascending */
  RentalcontractcostAsc = '_RENTALCONTRACTCOST_ASC_',
  /** sort on RentalContractCost descending */
  RentalcontractcostDesc = '_RENTALCONTRACTCOST_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepaymentPeriod ascending */
  PrepaymentperiodAsc = '_PREPAYMENTPERIOD_ASC_',
  /** sort on PrepaymentPeriod descending */
  PrepaymentperiodDesc = '_PREPAYMENTPERIOD_DESC_',
  /** Sort on PrepmtDocNo ascending */
  PrepmtdocnoAsc = '_PREPMTDOCNO_ASC_',
  /** sort on PrepmtDocNo descending */
  PrepmtdocnoDesc = '_PREPMTDOCNO_DESC_',
  /** Sort on PrepmtDocType ascending */
  PrepmtdoctypeAsc = '_PREPMTDOCTYPE_ASC_',
  /** sort on PrepmtDocType descending */
  PrepmtdoctypeDesc = '_PREPMTDOCTYPE_DESC_',
  /** Sort on PrepmtDocTypeOPTION ascending */
  PrepmtdoctypeoptionAsc = '_PREPMTDOCTYPEOPTION_ASC_',
  /** sort on PrepmtDocTypeOPTION descending */
  PrepmtdoctypeoptionDesc = '_PREPMTDOCTYPEOPTION_DESC_',
  /** Sort on CompressPrepayment ascending */
  CompressprepaymentAsc = '_COMPRESSPREPAYMENT_ASC_',
  /** sort on CompressPrepayment descending */
  CompressprepaymentDesc = '_COMPRESSPREPAYMENT_DESC_',
  /** Sort on UsePrepmtExpirationDate ascending */
  UseprepmtexpirationdateAsc = '_USEPREPMTEXPIRATIONDATE_ASC_',
  /** sort on UsePrepmtExpirationDate descending */
  UseprepmtexpirationdateDesc = '_USEPREPMTEXPIRATIONDATE_DESC_',
  /** Sort on ReceivingRespCenter ascending */
  ReceivingrespcenterAsc = '_RECEIVINGRESPCENTER_ASC_',
  /** sort on ReceivingRespCenter descending */
  ReceivingrespcenterDesc = '_RECEIVINGRESPCENTER_DESC_',
  /** Sort on ReceivingCostCenter ascending */
  ReceivingcostcenterAsc = '_RECEIVINGCOSTCENTER_ASC_',
  /** sort on ReceivingCostCenter descending */
  ReceivingcostcenterDesc = '_RECEIVINGCOSTCENTER_DESC_',
  /** Sort on QuoteExpiryDate ascending */
  QuoteexpirydateAsc = '_QUOTEEXPIRYDATE_ASC_',
  /** sort on QuoteExpiryDate descending */
  QuoteexpirydateDesc = '_QUOTEEXPIRYDATE_DESC_',
  /** Sort on QuoteStatus ascending */
  QuotestatusAsc = '_QUOTESTATUS_ASC_',
  /** sort on QuoteStatus descending */
  QuotestatusDesc = '_QUOTESTATUS_DESC_',
  /** Sort on QuoteStatusOPTION ascending */
  QuotestatusoptionAsc = '_QUOTESTATUSOPTION_ASC_',
  /** sort on QuoteStatusOPTION descending */
  QuotestatusoptionDesc = '_QUOTESTATUSOPTION_DESC_',
  /** Sort on CustomerPONo ascending */
  CustomerponoAsc = '_CUSTOMERPONO_ASC_',
  /** sort on CustomerPONo descending */
  CustomerponoDesc = '_CUSTOMERPONO_DESC_',
  /** Sort on NextInvoiceDueDate ascending */
  NextinvoiceduedateAsc = '_NEXTINVOICEDUEDATE_ASC_',
  /** sort on NextInvoiceDueDate descending */
  NextinvoiceduedateDesc = '_NEXTINVOICEDUEDATE_DESC_',
  /** Sort on TypeFilter ascending */
  TypefilterAsc = '_TYPEFILTER_ASC_',
  /** sort on TypeFilter descending */
  TypefilterDesc = '_TYPEFILTER_DESC_',
  /** Sort on TypeFilterOPTION ascending */
  TypefilteroptionAsc = '_TYPEFILTEROPTION_ASC_',
  /** sort on TypeFilterOPTION descending */
  TypefilteroptionDesc = '_TYPEFILTEROPTION_DESC_',
  /** Sort on ReasonCodeFilter ascending */
  ReasoncodefilterAsc = '_REASONCODEFILTER_ASC_',
  /** sort on ReasonCodeFilter descending */
  ReasoncodefilterDesc = '_REASONCODEFILTER_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on ContractLineAmount ascending */
  ContractlineamountAsc = '_CONTRACTLINEAMOUNT_ASC_',
  /** sort on ContractLineAmount descending */
  ContractlineamountDesc = '_CONTRACTLINEAMOUNT_DESC_',
  /** Sort on NoofPostedInvoices ascending */
  NoofpostedinvoicesAsc = '_NOOFPOSTEDINVOICES_ASC_',
  /** sort on NoofPostedInvoices descending */
  NoofpostedinvoicesDesc = '_NOOFPOSTEDINVOICES_DESC_',
  /** Sort on NoofUnpostedInvoices ascending */
  NoofunpostedinvoicesAsc = '_NOOFUNPOSTEDINVOICES_ASC_',
  /** sort on NoofUnpostedInvoices descending */
  NoofunpostedinvoicesDesc = '_NOOFUNPOSTEDINVOICES_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on ContractInvoiceAmount ascending */
  ContractinvoiceamountAsc = '_CONTRACTINVOICEAMOUNT_ASC_',
  /** sort on ContractInvoiceAmount descending */
  ContractinvoiceamountDesc = '_CONTRACTINVOICEAMOUNT_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on NoofPickOrder ascending */
  NoofpickorderAsc = '_NOOFPICKORDER_ASC_',
  /** sort on NoofPickOrder descending */
  NoofpickorderDesc = '_NOOFPICKORDER_DESC_',
  /** Sort on NoofShipmentOrder ascending */
  NoofshipmentorderAsc = '_NOOFSHIPMENTORDER_ASC_',
  /** sort on NoofShipmentOrder descending */
  NoofshipmentorderDesc = '_NOOFSHIPMENTORDER_DESC_',
  /** Sort on NoofCollectionOrder ascending */
  NoofcollectionorderAsc = '_NOOFCOLLECTIONORDER_ASC_',
  /** sort on NoofCollectionOrder descending */
  NoofcollectionorderDesc = '_NOOFCOLLECTIONORDER_DESC_',
  /** Sort on NoofWorkOrder ascending */
  NoofworkorderAsc = '_NOOFWORKORDER_ASC_',
  /** sort on NoofWorkOrder descending */
  NoofworkorderDesc = '_NOOFWORKORDER_DESC_',
  /** Sort on LocationManagerName ascending */
  LocationmanagernameAsc = '_LOCATIONMANAGERNAME_ASC_',
  /** sort on LocationManagerName descending */
  LocationmanagernameDesc = '_LOCATIONMANAGERNAME_DESC_',
  /** Sort on ProjectManagerName ascending */
  ProjectmanagernameAsc = '_PROJECTMANAGERNAME_ASC_',
  /** sort on ProjectManagerName descending */
  ProjectmanagernameDesc = '_PROJECTMANAGERNAME_DESC_',
  /** Sort on DeliveryContactName ascending */
  DeliverycontactnameAsc = '_DELIVERYCONTACTNAME_ASC_',
  /** sort on DeliveryContactName descending */
  DeliverycontactnameDesc = '_DELIVERYCONTACTNAME_DESC_',
  /** Sort on CustomerDepositAmount ascending */
  CustomerdepositamountAsc = '_CUSTOMERDEPOSITAMOUNT_ASC_',
  /** sort on CustomerDepositAmount descending */
  CustomerdepositamountDesc = '_CUSTOMERDEPOSITAMOUNT_DESC_',
  /** Sort on CustomerDepositOutstAmt ascending */
  CustomerdepositoutstamtAsc = '_CUSTOMERDEPOSITOUTSTAMT_ASC_',
  /** sort on CustomerDepositOutstAmt descending */
  CustomerdepositoutstamtDesc = '_CUSTOMERDEPOSITOUTSTAMT_DESC_',
  /** Sort on RentalValue ascending */
  RentalvalueAsc = '_RENTALVALUE_ASC_',
  /** sort on RentalValue descending */
  RentalvalueDesc = '_RENTALVALUE_DESC_',
  /** Sort on CPNextInvoiceAmount ascending */
  CpnextinvoiceamountAsc = '_CPNEXTINVOICEAMOUNT_ASC_',
  /** sort on CPNextInvoiceAmount descending */
  CpnextinvoiceamountDesc = '_CPNEXTINVOICEAMOUNT_DESC_',
  /** Sort on CustOrganizationNo ascending */
  CustorganizationnoAsc = '_CUSTORGANIZATIONNO_ASC_',
  /** sort on CustOrganizationNo descending */
  CustorganizationnoDesc = '_CUSTORGANIZATIONNO_DESC_',
  /** Sort on TransactionNo ascending */
  TransactionnoAsc = '_TRANSACTIONNO_ASC_',
  /** sort on TransactionNo descending */
  TransactionnoDesc = '_TRANSACTIONNO_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on PrepmtPaidAmount ascending */
  PrepmtpaidamountAsc = '_PREPMTPAIDAMOUNT_ASC_',
  /** sort on PrepmtPaidAmount descending */
  PrepmtpaidamountDesc = '_PREPMTPAIDAMOUNT_DESC_',
  /** Sort on PrepmtUnpaidAmount ascending */
  PrepmtunpaidamountAsc = '_PREPMTUNPAIDAMOUNT_ASC_',
  /** sort on PrepmtUnpaidAmount descending */
  PrepmtunpaidamountDesc = '_PREPMTUNPAIDAMOUNT_DESC_',
  /** Sort on PrepmtPaidAmountInclVAT ascending */
  PrepmtpaidamountinclvatAsc = '_PREPMTPAIDAMOUNTINCLVAT_ASC_',
  /** sort on PrepmtPaidAmountInclVAT descending */
  PrepmtpaidamountinclvatDesc = '_PREPMTPAIDAMOUNTINCLVAT_DESC_',
  /** Sort on PrepmtUnpaidAmtInclVAT ascending */
  PrepmtunpaidamtinclvatAsc = '_PREPMTUNPAIDAMTINCLVAT_ASC_',
  /** sort on PrepmtUnpaidAmtInclVAT descending */
  PrepmtunpaidamtinclvatDesc = '_PREPMTUNPAIDAMTINCLVAT_DESC_',
  /** Sort on PrepmtInsuranceAmt ascending */
  PrepmtinsuranceamtAsc = '_PREPMTINSURANCEAMT_ASC_',
  /** sort on PrepmtInsuranceAmt descending */
  PrepmtinsuranceamtDesc = '_PREPMTINSURANCEAMT_DESC_',
  /** Sort on PrepmtInsuranceAmtInclVAT ascending */
  PrepmtinsuranceamtinclvatAsc = '_PREPMTINSURANCEAMTINCLVAT_ASC_',
  /** sort on PrepmtInsuranceAmtInclVAT descending */
  PrepmtinsuranceamtinclvatDesc = '_PREPMTINSURANCEAMTINCLVAT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalLineInput {
  /** Sort on ContractType ascending */
  ContracttypeAsc = '_CONTRACTTYPE_ASC_',
  /** sort on ContractType descending */
  ContracttypeDesc = '_CONTRACTTYPE_DESC_',
  /** Sort on ContractTypeOPTION ascending */
  ContracttypeoptionAsc = '_CONTRACTTYPEOPTION_ASC_',
  /** sort on ContractTypeOPTION descending */
  ContracttypeoptionDesc = '_CONTRACTTYPEOPTION_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on ContractStatus ascending */
  ContractstatusAsc = '_CONTRACTSTATUS_ASC_',
  /** sort on ContractStatus descending */
  ContractstatusDesc = '_CONTRACTSTATUS_DESC_',
  /** Sort on ContractStatusOPTION ascending */
  ContractstatusoptionAsc = '_CONTRACTSTATUSOPTION_ASC_',
  /** sort on ContractStatusOPTION descending */
  ContractstatusoptionDesc = '_CONTRACTSTATUSOPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on StartRentalPeriod ascending */
  StartrentalperiodAsc = '_STARTRENTALPERIOD_ASC_',
  /** sort on StartRentalPeriod descending */
  StartrentalperiodDesc = '_STARTRENTALPERIOD_DESC_',
  /** Sort on EndRentalPeriod ascending */
  EndrentalperiodAsc = '_ENDRENTALPERIOD_ASC_',
  /** sort on EndRentalPeriod descending */
  EndrentalperiodDesc = '_ENDRENTALPERIOD_DESC_',
  /** Sort on ShipmentTime ascending */
  ShipmenttimeAsc = '_SHIPMENTTIME_ASC_',
  /** sort on ShipmentTime descending */
  ShipmenttimeDesc = '_SHIPMENTTIME_DESC_',
  /** Sort on ReturnTime ascending */
  ReturntimeAsc = '_RETURNTIME_ASC_',
  /** sort on ReturnTime descending */
  ReturntimeDesc = '_RETURNTIME_DESC_',
  /** Sort on InvoicedToDate ascending */
  InvoicedtodateAsc = '_INVOICEDTODATE_ASC_',
  /** sort on InvoicedToDate descending */
  InvoicedtodateDesc = '_INVOICEDTODATE_DESC_',
  /** Sort on NextInvoiceDate ascending */
  NextinvoicedateAsc = '_NEXTINVOICEDATE_ASC_',
  /** sort on NextInvoiceDate descending */
  NextinvoicedateDesc = '_NEXTINVOICEDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on RentalPeriod ascending */
  RentalperiodAsc = '_RENTALPERIOD_ASC_',
  /** sort on RentalPeriod descending */
  RentalperiodDesc = '_RENTALPERIOD_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on NextInvoicePeriodStart ascending */
  NextinvoiceperiodstartAsc = '_NEXTINVOICEPERIODSTART_ASC_',
  /** sort on NextInvoicePeriodStart descending */
  NextinvoiceperiodstartDesc = '_NEXTINVOICEPERIODSTART_DESC_',
  /** Sort on NextInvoicePeriodEnd ascending */
  NextinvoiceperiodendAsc = '_NEXTINVOICEPERIODEND_ASC_',
  /** sort on NextInvoicePeriodEnd descending */
  NextinvoiceperiodendDesc = '_NEXTINVOICEPERIODEND_DESC_',
  /** Sort on RentalPeriodInvoiced ascending */
  RentalperiodinvoicedAsc = '_RENTALPERIODINVOICED_ASC_',
  /** sort on RentalPeriodInvoiced descending */
  RentalperiodinvoicedDesc = '_RENTALPERIODINVOICED_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on Credited ascending */
  CreditedAsc = '_CREDITED_ASC_',
  /** sort on Credited descending */
  CreditedDesc = '_CREDITED_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on EntryStatus ascending */
  EntrystatusAsc = '_ENTRYSTATUS_ASC_',
  /** sort on EntryStatus descending */
  EntrystatusDesc = '_ENTRYSTATUS_DESC_',
  /** Sort on EntryStatusOPTION ascending */
  EntrystatusoptionAsc = '_ENTRYSTATUSOPTION_ASC_',
  /** sort on EntryStatusOPTION descending */
  EntrystatusoptionDesc = '_ENTRYSTATUSOPTION_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on RentalClosed ascending */
  RentalclosedAsc = '_RENTALCLOSED_ASC_',
  /** sort on RentalClosed descending */
  RentalclosedDesc = '_RENTALCLOSED_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on RentalPeriodNow ascending */
  RentalperiodnowAsc = '_RENTALPERIODNOW_ASC_',
  /** sort on RentalPeriodNow descending */
  RentalperiodnowDesc = '_RENTALPERIODNOW_DESC_',
  /** Sort on RentalChargeDays ascending */
  RentalchargedaysAsc = '_RENTALCHARGEDAYS_ASC_',
  /** sort on RentalChargeDays descending */
  RentalchargedaysDesc = '_RENTALCHARGEDAYS_DESC_',
  /** Sort on RentalChargeDaysOPTION ascending */
  RentalchargedaysoptionAsc = '_RENTALCHARGEDAYSOPTION_ASC_',
  /** sort on RentalChargeDaysOPTION descending */
  RentalchargedaysoptionDesc = '_RENTALCHARGEDAYSOPTION_DESC_',
  /** Sort on DeliveredTime ascending */
  DeliveredtimeAsc = '_DELIVEREDTIME_ASC_',
  /** sort on DeliveredTime descending */
  DeliveredtimeDesc = '_DELIVEREDTIME_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on ReturnCompleted ascending */
  ReturncompletedAsc = '_RETURNCOMPLETED_ASC_',
  /** sort on ReturnCompleted descending */
  ReturncompletedDesc = '_RETURNCOMPLETED_DESC_',
  /** Sort on TransferdToOrder ascending */
  TransferdtoorderAsc = '_TRANSFERDTOORDER_ASC_',
  /** sort on TransferdToOrder descending */
  TransferdtoorderDesc = '_TRANSFERDTOORDER_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Counter ascending */
  CounterAsc = '_COUNTER_ASC_',
  /** sort on Counter descending */
  CounterDesc = '_COUNTER_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on PriceTerm ascending */
  PricetermAsc = '_PRICETERM_ASC_',
  /** sort on PriceTerm descending */
  PricetermDesc = '_PRICETERM_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on UnitPriceSale ascending */
  UnitpricesaleAsc = '_UNITPRICESALE_ASC_',
  /** sort on UnitPriceSale descending */
  UnitpricesaleDesc = '_UNITPRICESALE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on ReservedSeasonCalendar ascending */
  ReservedseasoncalendarAsc = '_RESERVEDSEASONCALENDAR_ASC_',
  /** sort on ReservedSeasonCalendar descending */
  ReservedseasoncalendarDesc = '_RESERVEDSEASONCALENDAR_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on ShipmentNo ascending */
  ShipmentnoAsc = '_SHIPMENTNO_ASC_',
  /** sort on ShipmentNo descending */
  ShipmentnoDesc = '_SHIPMENTNO_DESC_',
  /** Sort on ShipmentLineNo ascending */
  ShipmentlinenoAsc = '_SHIPMENTLINENO_ASC_',
  /** sort on ShipmentLineNo descending */
  ShipmentlinenoDesc = '_SHIPMENTLINENO_DESC_',
  /** Sort on Counter2 ascending */
  Counter2Asc = '_COUNTER2_ASC_',
  /** sort on Counter2 descending */
  Counter2Desc = '_COUNTER2_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on EstimatedCounterperDay ascending */
  EstimatedcounterperdayAsc = '_ESTIMATEDCOUNTERPERDAY_ASC_',
  /** sort on EstimatedCounterperDay descending */
  EstimatedcounterperdayDesc = '_ESTIMATEDCOUNTERPERDAY_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on CustomerSubProject ascending */
  CustomersubprojectAsc = '_CUSTOMERSUBPROJECT_ASC_',
  /** sort on CustomerSubProject descending */
  CustomersubprojectDesc = '_CUSTOMERSUBPROJECT_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on EstimatedCounter2perDay ascending */
  Estimatedcounter2PerdayAsc = '_ESTIMATEDCOUNTER2PERDAY_ASC_',
  /** sort on EstimatedCounter2perDay descending */
  Estimatedcounter2PerdayDesc = '_ESTIMATEDCOUNTER2PERDAY_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on WorkInprogress ascending */
  WorkinprogressAsc = '_WORKINPROGRESS_ASC_',
  /** sort on WorkInprogress descending */
  WorkinprogressDesc = '_WORKINPROGRESS_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on PctofSalesPrice ascending */
  PctofsalespriceAsc = '_PCTOFSALESPRICE_ASC_',
  /** sort on PctofSalesPrice descending */
  PctofsalespriceDesc = '_PCTOFSALESPRICE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepmtLineAmount ascending */
  PrepmtlineamountAsc = '_PREPMTLINEAMOUNT_ASC_',
  /** sort on PrepmtLineAmount descending */
  PrepmtlineamountDesc = '_PREPMTLINEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInv ascending */
  PrepmtamtinvAsc = '_PREPMTAMTINV_ASC_',
  /** sort on PrepmtAmtInv descending */
  PrepmtamtinvDesc = '_PREPMTAMTINV_DESC_',
  /** Sort on PrepmtAmtInclVAT ascending */
  PrepmtamtinclvatAsc = '_PREPMTAMTINCLVAT_ASC_',
  /** sort on PrepmtAmtInclVAT descending */
  PrepmtamtinclvatDesc = '_PREPMTAMTINCLVAT_DESC_',
  /** Sort on PrepaymentPeriod ascending */
  PrepaymentperiodAsc = '_PREPAYMENTPERIOD_ASC_',
  /** sort on PrepaymentPeriod descending */
  PrepaymentperiodDesc = '_PREPAYMENTPERIOD_DESC_',
  /** Sort on PrepmtUseExpirationDate ascending */
  PrepmtuseexpirationdateAsc = '_PREPMTUSEEXPIRATIONDATE_ASC_',
  /** sort on PrepmtUseExpirationDate descending */
  PrepmtuseexpirationdateDesc = '_PREPMTUSEEXPIRATIONDATE_DESC_',
  /** Sort on PrepmtSalesLineNo ascending */
  PrepmtsaleslinenoAsc = '_PREPMTSALESLINENO_ASC_',
  /** sort on PrepmtSalesLineNo descending */
  PrepmtsaleslinenoDesc = '_PREPMTSALESLINENO_DESC_',
  /** Sort on PrepmtInvoiceStatus ascending */
  PrepmtinvoicestatusAsc = '_PREPMTINVOICESTATUS_ASC_',
  /** sort on PrepmtInvoiceStatus descending */
  PrepmtinvoicestatusDesc = '_PREPMTINVOICESTATUS_DESC_',
  /** Sort on PrepmtInvoiceStatusOPTION ascending */
  PrepmtinvoicestatusoptionAsc = '_PREPMTINVOICESTATUSOPTION_ASC_',
  /** sort on PrepmtInvoiceStatusOPTION descending */
  PrepmtinvoicestatusoptionDesc = '_PREPMTINVOICESTATUSOPTION_DESC_',
  /** Sort on PrepmtCrMemoStatus ascending */
  PrepmtcrmemostatusAsc = '_PREPMTCRMEMOSTATUS_ASC_',
  /** sort on PrepmtCrMemoStatus descending */
  PrepmtcrmemostatusDesc = '_PREPMTCRMEMOSTATUS_DESC_',
  /** Sort on PrepmtCrMemoStatusOPTION ascending */
  PrepmtcrmemostatusoptionAsc = '_PREPMTCRMEMOSTATUSOPTION_ASC_',
  /** sort on PrepmtCrMemoStatusOPTION descending */
  PrepmtcrmemostatusoptionDesc = '_PREPMTCRMEMOSTATUSOPTION_DESC_',
  /** Sort on PrepmtDocNo ascending */
  PrepmtdocnoAsc = '_PREPMTDOCNO_ASC_',
  /** sort on PrepmtDocNo descending */
  PrepmtdocnoDesc = '_PREPMTDOCNO_DESC_',
  /** Sort on PrepmtInsuranceAmount ascending */
  PrepmtinsuranceamountAsc = '_PREPMTINSURANCEAMOUNT_ASC_',
  /** sort on PrepmtInsuranceAmount descending */
  PrepmtinsuranceamountDesc = '_PREPMTINSURANCEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInOrder ascending */
  PrepmtamtinorderAsc = '_PREPMTAMTINORDER_ASC_',
  /** sort on PrepmtAmtInOrder descending */
  PrepmtamtinorderDesc = '_PREPMTAMTINORDER_DESC_',
  /** Sort on PrepmtAmtInOrderInclVAT ascending */
  PrepmtamtinorderinclvatAsc = '_PREPMTAMTINORDERINCLVAT_ASC_',
  /** sort on PrepmtAmtInOrderInclVAT descending */
  PrepmtamtinorderinclvatDesc = '_PREPMTAMTINORDERINCLVAT_DESC_',
  /** Sort on PrepmtInsuranceAmtInOrder ascending */
  PrepmtinsuranceamtinorderAsc = '_PREPMTINSURANCEAMTINORDER_ASC_',
  /** sort on PrepmtInsuranceAmtInOrder descending */
  PrepmtinsuranceamtinorderDesc = '_PREPMTINSURANCEAMTINORDER_DESC_',
  /** Sort on PrepmtAmttoDeduct ascending */
  PrepmtamttodeductAsc = '_PREPMTAMTTODEDUCT_ASC_',
  /** sort on PrepmtAmttoDeduct descending */
  PrepmtamttodeductDesc = '_PREPMTAMTTODEDUCT_DESC_',
  /** Sort on PrepmtAmtDeducted ascending */
  PrepmtamtdeductedAsc = '_PREPMTAMTDEDUCTED_ASC_',
  /** sort on PrepmtAmtDeducted descending */
  PrepmtamtdeductedDesc = '_PREPMTAMTDEDUCTED_DESC_',
  /** Sort on PrepmtAmttoDeductVAT ascending */
  PrepmtamttodeductvatAsc = '_PREPMTAMTTODEDUCTVAT_ASC_',
  /** sort on PrepmtAmttoDeductVAT descending */
  PrepmtamttodeductvatDesc = '_PREPMTAMTTODEDUCTVAT_DESC_',
  /** Sort on PrepmtAmtDeductedVAT ascending */
  PrepmtamtdeductedvatAsc = '_PREPMTAMTDEDUCTEDVAT_ASC_',
  /** sort on PrepmtAmtDeductedVAT descending */
  PrepmtamtdeductedvatDesc = '_PREPMTAMTDEDUCTEDVAT_DESC_',
  /** Sort on PrepmtRentalLineNo ascending */
  PrepmtrentallinenoAsc = '_PREPMTRENTALLINENO_ASC_',
  /** sort on PrepmtRentalLineNo descending */
  PrepmtrentallinenoDesc = '_PREPMTRENTALLINENO_DESC_',
  /** Sort on PrepmtInsuranceToDeduct ascending */
  PrepmtinsurancetodeductAsc = '_PREPMTINSURANCETODEDUCT_ASC_',
  /** sort on PrepmtInsuranceToDeduct descending */
  PrepmtinsurancetodeductDesc = '_PREPMTINSURANCETODEDUCT_DESC_',
  /** Sort on PrepmtInsuranceDeducted ascending */
  PrepmtinsurancedeductedAsc = '_PREPMTINSURANCEDEDUCTED_ASC_',
  /** sort on PrepmtInsuranceDeducted descending */
  PrepmtinsurancedeductedDesc = '_PREPMTINSURANCEDEDUCTED_DESC_',
  /** Sort on PrepmtInsuranceAmtInclVAT ascending */
  PrepmtinsuranceamtinclvatAsc = '_PREPMTINSURANCEAMTINCLVAT_ASC_',
  /** sort on PrepmtInsuranceAmtInclVAT descending */
  PrepmtinsuranceamtinclvatDesc = '_PREPMTINSURANCEAMTINCLVAT_DESC_',
  /** Sort on PrepmtInsAmtOrderIncVAT ascending */
  PrepmtinsamtorderincvatAsc = '_PREPMTINSAMTORDERINCVAT_ASC_',
  /** sort on PrepmtInsAmtOrderIncVAT descending */
  PrepmtinsamtorderincvatDesc = '_PREPMTINSAMTORDERINCVAT_DESC_',
  /** Sort on PrepmtDimensionSetID ascending */
  PrepmtdimensionsetidAsc = '_PREPMTDIMENSIONSETID_ASC_',
  /** sort on PrepmtDimensionSetID descending */
  PrepmtdimensionsetidDesc = '_PREPMTDIMENSIONSETID_DESC_',
  /** Sort on PrepmtVATBusPostingGroup ascending */
  PrepmtvatbuspostinggroupAsc = '_PREPMTVATBUSPOSTINGGROUP_ASC_',
  /** sort on PrepmtVATBusPostingGroup descending */
  PrepmtvatbuspostinggroupDesc = '_PREPMTVATBUSPOSTINGGROUP_DESC_',
  /** Sort on PrepmtVATProdPostingGrp ascending */
  PrepmtvatprodpostinggrpAsc = '_PREPMTVATPRODPOSTINGGRP_ASC_',
  /** sort on PrepmtVATProdPostingGrp descending */
  PrepmtvatprodpostinggrpDesc = '_PREPMTVATPRODPOSTINGGRP_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on OldAttachedtoLineNo ascending */
  OldattachedtolinenoAsc = '_OLDATTACHEDTOLINENO_ASC_',
  /** sort on OldAttachedtoLineNo descending */
  OldattachedtolinenoDesc = '_OLDATTACHEDTOLINENO_DESC_',
  /** Sort on AttachedtoSwitchLineNo ascending */
  AttachedtoswitchlinenoAsc = '_ATTACHEDTOSWITCHLINENO_ASC_',
  /** sort on AttachedtoSwitchLineNo descending */
  AttachedtoswitchlinenoDesc = '_ATTACHEDTOSWITCHLINENO_DESC_',
  /** Sort on AttachedStandByLineNo ascending */
  AttachedstandbylinenoAsc = '_ATTACHEDSTANDBYLINENO_ASC_',
  /** sort on AttachedStandByLineNo descending */
  AttachedstandbylinenoDesc = '_ATTACHEDSTANDBYLINENO_DESC_',
  /** Sort on StandbyStatus ascending */
  StandbystatusAsc = '_STANDBYSTATUS_ASC_',
  /** sort on StandbyStatus descending */
  StandbystatusDesc = '_STANDBYSTATUS_DESC_',
  /** Sort on StandbyStatusOPTION ascending */
  StandbystatusoptionAsc = '_STANDBYSTATUSOPTION_ASC_',
  /** sort on StandbyStatusOPTION descending */
  StandbystatusoptionDesc = '_STANDBYSTATUSOPTION_DESC_',
  /** Sort on QtyonAttachedtoLineNo ascending */
  QtyonattachedtolinenoAsc = '_QTYONATTACHEDTOLINENO_ASC_',
  /** sort on QtyonAttachedtoLineNo descending */
  QtyonattachedtolinenoDesc = '_QTYONATTACHEDTOLINENO_DESC_',
  /** Sort on KITMother ascending */
  KitmotherAsc = '_KITMOTHER_ASC_',
  /** sort on KITMother descending */
  KitmotherDesc = '_KITMOTHER_DESC_',
  /** Sort on KITFactor ascending */
  KitfactorAsc = '_KITFACTOR_ASC_',
  /** sort on KITFactor descending */
  KitfactorDesc = '_KITFACTOR_DESC_',
  /** Sort on KITDontShow ascending */
  KitdontshowAsc = '_KITDONTSHOW_ASC_',
  /** sort on KITDontShow descending */
  KitdontshowDesc = '_KITDONTSHOW_DESC_',
  /** Sort on KITMotherLineNo ascending */
  KitmotherlinenoAsc = '_KITMOTHERLINENO_ASC_',
  /** sort on KITMotherLineNo descending */
  KitmotherlinenoDesc = '_KITMOTHERLINENO_DESC_',
  /** Sort on KITFactorPrice ascending */
  KitfactorpriceAsc = '_KITFACTORPRICE_ASC_',
  /** sort on KITFactorPrice descending */
  KitfactorpriceDesc = '_KITFACTORPRICE_DESC_',
  /** Sort on KITOriginalPrice ascending */
  KitoriginalpriceAsc = '_KITORIGINALPRICE_ASC_',
  /** sort on KITOriginalPrice descending */
  KitoriginalpriceDesc = '_KITORIGINALPRICE_DESC_',
  /** Sort on KITQuantity ascending */
  KitquantityAsc = '_KITQUANTITY_ASC_',
  /** sort on KITQuantity descending */
  KitquantityDesc = '_KITQUANTITY_DESC_',
  /** Sort on KITLineAmountNet ascending */
  KitlineamountnetAsc = '_KITLINEAMOUNTNET_ASC_',
  /** sort on KITLineAmountNet descending */
  KitlineamountnetDesc = '_KITLINEAMOUNTNET_DESC_',
  /** Sort on KITLineAmountGross ascending */
  KitlineamountgrossAsc = '_KITLINEAMOUNTGROSS_ASC_',
  /** sort on KITLineAmountGross descending */
  KitlineamountgrossDesc = '_KITLINEAMOUNTGROSS_DESC_',
  /** Sort on KITOriginalSqMeter ascending */
  KitoriginalsqmeterAsc = '_KITORIGINALSQMETER_ASC_',
  /** sort on KITOriginalSqMeter descending */
  KitoriginalsqmeterDesc = '_KITORIGINALSQMETER_DESC_',
  /** Sort on SquareMeterperKIT ascending */
  SquaremeterperkitAsc = '_SQUAREMETERPERKIT_ASC_',
  /** sort on SquareMeterperKIT descending */
  SquaremeterperkitDesc = '_SQUAREMETERPERKIT_DESC_',
  /** Sort on KITPriceperSqMeter ascending */
  KitpricepersqmeterAsc = '_KITPRICEPERSQMETER_ASC_',
  /** sort on KITPriceperSqMeter descending */
  KitpricepersqmeterDesc = '_KITPRICEPERSQMETER_DESC_',
  /** Sort on KITChange ascending */
  KitchangeAsc = '_KITCHANGE_ASC_',
  /** sort on KITChange descending */
  KitchangeDesc = '_KITCHANGE_DESC_',
  /** Sort on TotalKITPrice ascending */
  TotalkitpriceAsc = '_TOTALKITPRICE_ASC_',
  /** sort on TotalKITPrice descending */
  TotalkitpriceDesc = '_TOTALKITPRICE_DESC_',
  /** Sort on DefaultKITPrice ascending */
  DefaultkitpriceAsc = '_DEFAULTKITPRICE_ASC_',
  /** sort on DefaultKITPrice descending */
  DefaultkitpriceDesc = '_DEFAULTKITPRICE_DESC_',
  /** Sort on DefaultKITPriceMarked ascending */
  DefaultkitpricemarkedAsc = '_DEFAULTKITPRICEMARKED_ASC_',
  /** sort on DefaultKITPriceMarked descending */
  DefaultkitpricemarkedDesc = '_DEFAULTKITPRICEMARKED_DESC_',
  /** Sort on DefaultKITFactorPrice ascending */
  DefaultkitfactorpriceAsc = '_DEFAULTKITFACTORPRICE_ASC_',
  /** sort on DefaultKITFactorPrice descending */
  DefaultkitfactorpriceDesc = '_DEFAULTKITFACTORPRICE_DESC_',
  /** Sort on KITComponentUnitPrice ascending */
  KitcomponentunitpriceAsc = '_KITCOMPONENTUNITPRICE_ASC_',
  /** sort on KITComponentUnitPrice descending */
  KitcomponentunitpriceDesc = '_KITCOMPONENTUNITPRICE_DESC_',
  /** Sort on KITComponentRentalPrice ascending */
  KitcomponentrentalpriceAsc = '_KITCOMPONENTRENTALPRICE_ASC_',
  /** sort on KITComponentRentalPrice descending */
  KitcomponentrentalpriceDesc = '_KITCOMPONENTRENTALPRICE_DESC_',
  /** Sort on KITLineDiscount ascending */
  KitlinediscountAsc = '_KITLINEDISCOUNT_ASC_',
  /** sort on KITLineDiscount descending */
  KitlinediscountDesc = '_KITLINEDISCOUNT_DESC_',
  /** Sort on KITLineDiscountAmount ascending */
  KitlinediscountamountAsc = '_KITLINEDISCOUNTAMOUNT_ASC_',
  /** sort on KITLineDiscountAmount descending */
  KitlinediscountamountDesc = '_KITLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on OldTmplMotherLineNo ascending */
  OldtmplmotherlinenoAsc = '_OLDTMPLMOTHERLINENO_ASC_',
  /** sort on OldTmplMotherLineNo descending */
  OldtmplmotherlinenoDesc = '_OLDTMPLMOTHERLINENO_DESC_',
  /** Sort on OnlyonFirstOrderInvoice ascending */
  OnlyonfirstorderinvoiceAsc = '_ONLYONFIRSTORDERINVOICE_ASC_',
  /** sort on OnlyonFirstOrderInvoice descending */
  OnlyonfirstorderinvoiceDesc = '_ONLYONFIRSTORDERINVOICE_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on ReturnDateBuffer ascending */
  ReturndatebufferAsc = '_RETURNDATEBUFFER_ASC_',
  /** sort on ReturnDateBuffer descending */
  ReturndatebufferDesc = '_RETURNDATEBUFFER_DESC_',
  /** Sort on OffRentDateBuffer ascending */
  OffrentdatebufferAsc = '_OFFRENTDATEBUFFER_ASC_',
  /** sort on OffRentDateBuffer descending */
  OffrentdatebufferDesc = '_OFFRENTDATEBUFFER_DESC_',
  /** Sort on ReturnQuantityBuffer ascending */
  ReturnquantitybufferAsc = '_RETURNQUANTITYBUFFER_ASC_',
  /** sort on ReturnQuantityBuffer descending */
  ReturnquantitybufferDesc = '_RETURNQUANTITYBUFFER_DESC_',
  /** Sort on OffRentQuantityBuffer ascending */
  OffrentquantitybufferAsc = '_OFFRENTQUANTITYBUFFER_ASC_',
  /** sort on OffRentQuantityBuffer descending */
  OffrentquantitybufferDesc = '_OFFRENTQUANTITYBUFFER_DESC_',
  /** Sort on DebitonReturnDateBuffer ascending */
  DebitonreturndatebufferAsc = '_DEBITONRETURNDATEBUFFER_ASC_',
  /** sort on DebitonReturnDateBuffer descending */
  DebitonreturndatebufferDesc = '_DEBITONRETURNDATEBUFFER_DESC_',
  /** Sort on CounteronReturnBuffer ascending */
  CounteronreturnbufferAsc = '_COUNTERONRETURNBUFFER_ASC_',
  /** sort on CounteronReturnBuffer descending */
  CounteronreturnbufferDesc = '_COUNTERONRETURNBUFFER_DESC_',
  /** Sort on ReturnReminderBuffer ascending */
  ReturnreminderbufferAsc = '_RETURNREMINDERBUFFER_ASC_',
  /** sort on ReturnReminderBuffer descending */
  ReturnreminderbufferDesc = '_RETURNREMINDERBUFFER_DESC_',
  /** Sort on UpdateLineBuffer ascending */
  UpdatelinebufferAsc = '_UPDATELINEBUFFER_ASC_',
  /** sort on UpdateLineBuffer descending */
  UpdatelinebufferDesc = '_UPDATELINEBUFFER_DESC_',
  /** Sort on ReturnNoBuffer ascending */
  ReturnnobufferAsc = '_RETURNNOBUFFER_ASC_',
  /** sort on ReturnNoBuffer descending */
  ReturnnobufferDesc = '_RETURNNOBUFFER_DESC_',
  /** Sort on ReturnPoleSplitBuffer ascending */
  ReturnpolesplitbufferAsc = '_RETURNPOLESPLITBUFFER_ASC_',
  /** sort on ReturnPoleSplitBuffer descending */
  ReturnpolesplitbufferDesc = '_RETURNPOLESPLITBUFFER_DESC_',
  /** Sort on ReturnCompleteBuffer ascending */
  ReturncompletebufferAsc = '_RETURNCOMPLETEBUFFER_ASC_',
  /** sort on ReturnCompleteBuffer descending */
  ReturncompletebufferDesc = '_RETURNCOMPLETEBUFFER_DESC_',
  /** Sort on InvoicedToDateBuffer ascending */
  InvoicedtodatebufferAsc = '_INVOICEDTODATEBUFFER_ASC_',
  /** sort on InvoicedToDateBuffer descending */
  InvoicedtodatebufferDesc = '_INVOICEDTODATEBUFFER_DESC_',
  /** Sort on ReceiveLocationCodeBuffer ascending */
  ReceivelocationcodebufferAsc = '_RECEIVELOCATIONCODEBUFFER_ASC_',
  /** sort on ReceiveLocationCodeBuffer descending */
  ReceivelocationcodebufferDesc = '_RECEIVELOCATIONCODEBUFFER_DESC_',
  /** Sort on ReturnTimeBuffer ascending */
  ReturntimebufferAsc = '_RETURNTIMEBUFFER_ASC_',
  /** sort on ReturnTimeBuffer descending */
  ReturntimebufferDesc = '_RETURNTIMEBUFFER_DESC_',
  /** Sort on DepositAmountCardBuffer ascending */
  DepositamountcardbufferAsc = '_DEPOSITAMOUNTCARDBUFFER_ASC_',
  /** sort on DepositAmountCardBuffer descending */
  DepositamountcardbufferDesc = '_DEPOSITAMOUNTCARDBUFFER_DESC_',
  /** Sort on DepositAmountCashBuffer ascending */
  DepositamountcashbufferAsc = '_DEPOSITAMOUNTCASHBUFFER_ASC_',
  /** sort on DepositAmountCashBuffer descending */
  DepositamountcashbufferDesc = '_DEPOSITAMOUNTCASHBUFFER_DESC_',
  /** Sort on ReclassQuantityBuffer ascending */
  ReclassquantitybufferAsc = '_RECLASSQUANTITYBUFFER_ASC_',
  /** sort on ReclassQuantityBuffer descending */
  ReclassquantitybufferDesc = '_RECLASSQUANTITYBUFFER_DESC_',
  /** Sort on ReclassDateBuffer ascending */
  ReclassdatebufferAsc = '_RECLASSDATEBUFFER_ASC_',
  /** sort on ReclassDateBuffer descending */
  ReclassdatebufferDesc = '_RECLASSDATEBUFFER_DESC_',
  /** Sort on CorrFromDateBuffer ascending */
  CorrfromdatebufferAsc = '_CORRFROMDATEBUFFER_ASC_',
  /** sort on CorrFromDateBuffer descending */
  CorrfromdatebufferDesc = '_CORRFROMDATEBUFFER_DESC_',
  /** Sort on CorrInvToDateBuffer ascending */
  CorrinvtodatebufferAsc = '_CORRINVTODATEBUFFER_ASC_',
  /** sort on CorrInvToDateBuffer descending */
  CorrinvtodatebufferDesc = '_CORRINVTODATEBUFFER_DESC_',
  /** Sort on CorrReturnDateBuffer ascending */
  CorrreturndatebufferAsc = '_CORRRETURNDATEBUFFER_ASC_',
  /** sort on CorrReturnDateBuffer descending */
  CorrreturndatebufferDesc = '_CORRRETURNDATEBUFFER_DESC_',
  /** Sort on CorrCloseRentalBuffer ascending */
  CorrcloserentalbufferAsc = '_CORRCLOSERENTALBUFFER_ASC_',
  /** sort on CorrCloseRentalBuffer descending */
  CorrcloserentalbufferDesc = '_CORRCLOSERENTALBUFFER_DESC_',
  /** Sort on CorrCloseRentalBufferOPTION ascending */
  CorrcloserentalbufferoptionAsc = '_CORRCLOSERENTALBUFFEROPTION_ASC_',
  /** sort on CorrCloseRentalBufferOPTION descending */
  CorrcloserentalbufferoptionDesc = '_CORRCLOSERENTALBUFFEROPTION_DESC_',
  /** Sort on CorrUpdEntryBasisBuffer ascending */
  CorrupdentrybasisbufferAsc = '_CORRUPDENTRYBASISBUFFER_ASC_',
  /** sort on CorrUpdEntryBasisBuffer descending */
  CorrupdentrybasisbufferDesc = '_CORRUPDENTRYBASISBUFFER_DESC_',
  /** Sort on CorrQuantityBuffer ascending */
  CorrquantitybufferAsc = '_CORRQUANTITYBUFFER_ASC_',
  /** sort on CorrQuantityBuffer descending */
  CorrquantitybufferDesc = '_CORRQUANTITYBUFFER_DESC_',
  /** Sort on CorrReturnQuantityBuffer ascending */
  CorrreturnquantitybufferAsc = '_CORRRETURNQUANTITYBUFFER_ASC_',
  /** sort on CorrReturnQuantityBuffer descending */
  CorrreturnquantitybufferDesc = '_CORRRETURNQUANTITYBUFFER_DESC_',
  /** Sort on ReclassFromContractBuffer ascending */
  ReclassfromcontractbufferAsc = '_RECLASSFROMCONTRACTBUFFER_ASC_',
  /** sort on ReclassFromContractBuffer descending */
  ReclassfromcontractbufferDesc = '_RECLASSFROMCONTRACTBUFFER_DESC_',
  /** Sort on ReclassToContractBuffer ascending */
  ReclasstocontractbufferAsc = '_RECLASSTOCONTRACTBUFFER_ASC_',
  /** sort on ReclassToContractBuffer descending */
  ReclasstocontractbufferDesc = '_RECLASSTOCONTRACTBUFFER_DESC_',
  /** Sort on MaintenanceBuffer ascending */
  MaintenancebufferAsc = '_MAINTENANCEBUFFER_ASC_',
  /** sort on MaintenanceBuffer descending */
  MaintenancebufferDesc = '_MAINTENANCEBUFFER_DESC_',
  /** Sort on DepositAmountTnsfBuffer ascending */
  DepositamounttnsfbufferAsc = '_DEPOSITAMOUNTTNSFBUFFER_ASC_',
  /** sort on DepositAmountTnsfBuffer descending */
  DepositamounttnsfbufferDesc = '_DEPOSITAMOUNTTNSFBUFFER_DESC_',
  /** Sort on OffRentTimeBuffer ascending */
  OffrenttimebufferAsc = '_OFFRENTTIMEBUFFER_ASC_',
  /** sort on OffRentTimeBuffer descending */
  OffrenttimebufferDesc = '_OFFRENTTIMEBUFFER_DESC_',
  /** Sort on OffRentNoBuffer ascending */
  OffrentnobufferAsc = '_OFFRENTNOBUFFER_ASC_',
  /** sort on OffRentNoBuffer descending */
  OffrentnobufferDesc = '_OFFRENTNOBUFFER_DESC_',
  /** Sort on CorrQtyShippedtoBuffer ascending */
  CorrqtyshippedtobufferAsc = '_CORRQTYSHIPPEDTOBUFFER_ASC_',
  /** sort on CorrQtyShippedtoBuffer descending */
  CorrqtyshippedtobufferDesc = '_CORRQTYSHIPPEDTOBUFFER_DESC_',
  /** Sort on TempEntryBuffer ascending */
  TempentrybufferAsc = '_TEMPENTRYBUFFER_ASC_',
  /** sort on TempEntryBuffer descending */
  TempentrybufferDesc = '_TEMPENTRYBUFFER_DESC_',
  /** Sort on ReturntoSellBuffer ascending */
  ReturntosellbufferAsc = '_RETURNTOSELLBUFFER_ASC_',
  /** sort on ReturntoSellBuffer descending */
  ReturntosellbufferDesc = '_RETURNTOSELLBUFFER_DESC_',
  /** Sort on ReturntoDamageBuffer ascending */
  ReturntodamagebufferAsc = '_RETURNTODAMAGEBUFFER_ASC_',
  /** sort on ReturntoDamageBuffer descending */
  ReturntodamagebufferDesc = '_RETURNTODAMAGEBUFFER_DESC_',
  /** Sort on Counter2onReturnBuffer ascending */
  Counter2OnreturnbufferAsc = '_COUNTER2ONRETURNBUFFER_ASC_',
  /** sort on Counter2onReturnBuffer descending */
  Counter2OnreturnbufferDesc = '_COUNTER2ONRETURNBUFFER_DESC_',
  /** Sort on CorrOnRentDateBuffer ascending */
  CorronrentdatebufferAsc = '_CORRONRENTDATEBUFFER_ASC_',
  /** sort on CorrOnRentDateBuffer descending */
  CorronrentdatebufferDesc = '_CORRONRENTDATEBUFFER_DESC_',
  /** Sort on CorrNextInvDateBuffer ascending */
  CorrnextinvdatebufferAsc = '_CORRNEXTINVDATEBUFFER_ASC_',
  /** sort on CorrNextInvDateBuffer descending */
  CorrnextinvdatebufferDesc = '_CORRNEXTINVDATEBUFFER_DESC_',
  /** Sort on CollectionDocNoBuffer ascending */
  CollectiondocnobufferAsc = '_COLLECTIONDOCNOBUFFER_ASC_',
  /** sort on CollectionDocNoBuffer descending */
  CollectiondocnobufferDesc = '_COLLECTIONDOCNOBUFFER_DESC_',
  /** Sort on CollectionLineNoBuffer ascending */
  CollectionlinenobufferAsc = '_COLLECTIONLINENOBUFFER_ASC_',
  /** sort on CollectionLineNoBuffer descending */
  CollectionlinenobufferDesc = '_COLLECTIONLINENOBUFFER_DESC_',
  /** Sort on SourceCodeBuffer ascending */
  SourcecodebufferAsc = '_SOURCECODEBUFFER_ASC_',
  /** sort on SourceCodeBuffer descending */
  SourcecodebufferDesc = '_SOURCECODEBUFFER_DESC_',
  /** Sort on DeliverQuantityNowBuffer ascending */
  DeliverquantitynowbufferAsc = '_DELIVERQUANTITYNOWBUFFER_ASC_',
  /** sort on DeliverQuantityNowBuffer descending */
  DeliverquantitynowbufferDesc = '_DELIVERQUANTITYNOWBUFFER_DESC_',
  /** Sort on ShipmentDateBuffer ascending */
  ShipmentdatebufferAsc = '_SHIPMENTDATEBUFFER_ASC_',
  /** sort on ShipmentDateBuffer descending */
  ShipmentdatebufferDesc = '_SHIPMENTDATEBUFFER_DESC_',
  /** Sort on OnRentDateBuffer ascending */
  OnrentdatebufferAsc = '_ONRENTDATEBUFFER_ASC_',
  /** sort on OnRentDateBuffer descending */
  OnrentdatebufferDesc = '_ONRENTDATEBUFFER_DESC_',
  /** Sort on LocationCodeBuffer ascending */
  LocationcodebufferAsc = '_LOCATIONCODEBUFFER_ASC_',
  /** sort on LocationCodeBuffer descending */
  LocationcodebufferDesc = '_LOCATIONCODEBUFFER_DESC_',
  /** Sort on ConsumptionatReturnBuffer ascending */
  ConsumptionatreturnbufferAsc = '_CONSUMPTIONATRETURNBUFFER_ASC_',
  /** sort on ConsumptionatReturnBuffer descending */
  ConsumptionatreturnbufferDesc = '_CONSUMPTIONATRETURNBUFFER_DESC_',
  /** Sort on CreditonEarlyBuffer ascending */
  CreditonearlybufferAsc = '_CREDITONEARLYBUFFER_ASC_',
  /** sort on CreditonEarlyBuffer descending */
  CreditonearlybufferDesc = '_CREDITONEARLYBUFFER_DESC_',
  /** Sort on StartRentalPeriodBuffer ascending */
  StartrentalperiodbufferAsc = '_STARTRENTALPERIODBUFFER_ASC_',
  /** sort on StartRentalPeriodBuffer descending */
  StartrentalperiodbufferDesc = '_STARTRENTALPERIODBUFFER_DESC_',
  /** Sort on LineNoBuffer ascending */
  LinenobufferAsc = '_LINENOBUFFER_ASC_',
  /** sort on LineNoBuffer descending */
  LinenobufferDesc = '_LINENOBUFFER_DESC_',
  /** Sort on ConsumpatDeliveryBuffer ascending */
  ConsumpatdeliverybufferAsc = '_CONSUMPATDELIVERYBUFFER_ASC_',
  /** sort on ConsumpatDeliveryBuffer descending */
  ConsumpatdeliverybufferDesc = '_CONSUMPATDELIVERYBUFFER_DESC_',
  /** Sort on DepositAmountBackBuffer ascending */
  DepositamountbackbufferAsc = '_DEPOSITAMOUNTBACKBUFFER_ASC_',
  /** sort on DepositAmountBackBuffer descending */
  DepositamountbackbufferDesc = '_DEPOSITAMOUNTBACKBUFFER_DESC_',
  /** Sort on DispatchTypeBuffer ascending */
  DispatchtypebufferAsc = '_DISPATCHTYPEBUFFER_ASC_',
  /** sort on DispatchTypeBuffer descending */
  DispatchtypebufferDesc = '_DISPATCHTYPEBUFFER_DESC_',
  /** Sort on DispatchTypeBufferOPTION ascending */
  DispatchtypebufferoptionAsc = '_DISPATCHTYPEBUFFEROPTION_ASC_',
  /** sort on DispatchTypeBufferOPTION descending */
  DispatchtypebufferoptionDesc = '_DISPATCHTYPEBUFFEROPTION_DESC_',
  /** Sort on PstDispatchDocNoBuffer ascending */
  PstdispatchdocnobufferAsc = '_PSTDISPATCHDOCNOBUFFER_ASC_',
  /** sort on PstDispatchDocNoBuffer descending */
  PstdispatchdocnobufferDesc = '_PSTDISPATCHDOCNOBUFFER_DESC_',
  /** Sort on PstDispatchLineNoBuffer ascending */
  PstdispatchlinenobufferAsc = '_PSTDISPATCHLINENOBUFFER_ASC_',
  /** sort on PstDispatchLineNoBuffer descending */
  PstdispatchlinenobufferDesc = '_PSTDISPATCHLINENOBUFFER_DESC_',
  /** Sort on PostedInDocumentBuffer ascending */
  PostedindocumentbufferAsc = '_POSTEDINDOCUMENTBUFFER_ASC_',
  /** sort on PostedInDocumentBuffer descending */
  PostedindocumentbufferDesc = '_POSTEDINDOCUMENTBUFFER_DESC_',
  /** Sort on EQMServiceDocBuffer ascending */
  EqmservicedocbufferAsc = '_EQMSERVICEDOCBUFFER_ASC_',
  /** sort on EQMServiceDocBuffer descending */
  EqmservicedocbufferDesc = '_EQMSERVICEDOCBUFFER_DESC_',
  /** Sort on UnitPriceBuffer ascending */
  UnitpricebufferAsc = '_UNITPRICEBUFFER_ASC_',
  /** sort on UnitPriceBuffer descending */
  UnitpricebufferDesc = '_UNITPRICEBUFFER_DESC_',
  /** Sort on LineDiscountBuffer ascending */
  LinediscountbufferAsc = '_LINEDISCOUNTBUFFER_ASC_',
  /** sort on LineDiscountBuffer descending */
  LinediscountbufferDesc = '_LINEDISCOUNTBUFFER_DESC_',
  /** Sort on LineMarkedBuffer ascending */
  LinemarkedbufferAsc = '_LINEMARKEDBUFFER_ASC_',
  /** sort on LineMarkedBuffer descending */
  LinemarkedbufferDesc = '_LINEMARKEDBUFFER_DESC_',
  /** Sort on PostingDateBuffer ascending */
  PostingdatebufferAsc = '_POSTINGDATEBUFFER_ASC_',
  /** sort on PostingDateBuffer descending */
  PostingdatebufferDesc = '_POSTINGDATEBUFFER_DESC_',
  /** Sort on UseInOpenPeriodBuffer ascending */
  UseinopenperiodbufferAsc = '_USEINOPENPERIODBUFFER_ASC_',
  /** sort on UseInOpenPeriodBuffer descending */
  UseinopenperiodbufferDesc = '_USEINOPENPERIODBUFFER_DESC_',
  /** Sort on AttachedtoLineNoBuffer ascending */
  AttachedtolinenobufferAsc = '_ATTACHEDTOLINENOBUFFER_ASC_',
  /** sort on AttachedtoLineNoBuffer descending */
  AttachedtolinenobufferDesc = '_ATTACHEDTOLINENOBUFFER_DESC_',
  /** Sort on CounteronShipmentBuffer ascending */
  CounteronshipmentbufferAsc = '_COUNTERONSHIPMENTBUFFER_ASC_',
  /** sort on CounteronShipmentBuffer descending */
  CounteronshipmentbufferDesc = '_COUNTERONSHIPMENTBUFFER_DESC_',
  /** Sort on Counter2onShipmentBuffer ascending */
  Counter2OnshipmentbufferAsc = '_COUNTER2ONSHIPMENTBUFFER_ASC_',
  /** sort on Counter2onShipmentBuffer descending */
  Counter2OnshipmentbufferDesc = '_COUNTER2ONSHIPMENTBUFFER_DESC_',
  /** Sort on NoofRentFreeDaysBuffer ascending */
  NoofrentfreedaysbufferAsc = '_NOOFRENTFREEDAYSBUFFER_ASC_',
  /** sort on NoofRentFreeDaysBuffer descending */
  NoofrentfreedaysbufferDesc = '_NOOFRENTFREEDAYSBUFFER_DESC_',
  /** Sort on NoofRentFreeHourBuffer ascending */
  NoofrentfreehourbufferAsc = '_NOOFRENTFREEHOURBUFFER_ASC_',
  /** sort on NoofRentFreeHourBuffer descending */
  NoofrentfreehourbufferDesc = '_NOOFRENTFREEHOURBUFFER_DESC_',
  /** Sort on ReasonforRentFreeBuffer ascending */
  ReasonforrentfreebufferAsc = '_REASONFORRENTFREEBUFFER_ASC_',
  /** sort on ReasonforRentFreeBuffer descending */
  ReasonforrentfreebufferDesc = '_REASONFORRENTFREEBUFFER_DESC_',
  /** Sort on ReceiveRespCenterBuffer ascending */
  ReceiverespcenterbufferAsc = '_RECEIVERESPCENTERBUFFER_ASC_',
  /** sort on ReceiveRespCenterBuffer descending */
  ReceiverespcenterbufferDesc = '_RECEIVERESPCENTERBUFFER_DESC_',
  /** Sort on FullyConsumedAccBuffer ascending */
  FullyconsumedaccbufferAsc = '_FULLYCONSUMEDACCBUFFER_ASC_',
  /** sort on FullyConsumedAccBuffer descending */
  FullyconsumedaccbufferDesc = '_FULLYCONSUMEDACCBUFFER_DESC_',
  /** Sort on ReceivingBinCodeBuffer ascending */
  ReceivingbincodebufferAsc = '_RECEIVINGBINCODEBUFFER_ASC_',
  /** sort on ReceivingBinCodeBuffer descending */
  ReceivingbincodebufferDesc = '_RECEIVINGBINCODEBUFFER_DESC_',
  /** Sort on SuspendLineNoBuffer ascending */
  SuspendlinenobufferAsc = '_SUSPENDLINENOBUFFER_ASC_',
  /** sort on SuspendLineNoBuffer descending */
  SuspendlinenobufferDesc = '_SUSPENDLINENOBUFFER_DESC_',
  /** Sort on UnitPriceNew ascending */
  UnitpricenewAsc = '_UNITPRICENEW_ASC_',
  /** sort on UnitPriceNew descending */
  UnitpricenewDesc = '_UNITPRICENEW_DESC_',
  /** Sort on PriceTermCodeNew ascending */
  PricetermcodenewAsc = '_PRICETERMCODENEW_ASC_',
  /** sort on PriceTermCodeNew descending */
  PricetermcodenewDesc = '_PRICETERMCODENEW_DESC_',
  /** Sort on UnitPriceprDayNew ascending */
  UnitpriceprdaynewAsc = '_UNITPRICEPRDAYNEW_ASC_',
  /** sort on UnitPriceprDayNew descending */
  UnitpriceprdaynewDesc = '_UNITPRICEPRDAYNEW_DESC_',
  /** Sort on UnitPriceprMonthNew ascending */
  UnitpriceprmonthnewAsc = '_UNITPRICEPRMONTHNEW_ASC_',
  /** sort on UnitPriceprMonthNew descending */
  UnitpriceprmonthnewDesc = '_UNITPRICEPRMONTHNEW_DESC_',
  /** Sort on LineAmountNew ascending */
  LineamountnewAsc = '_LINEAMOUNTNEW_ASC_',
  /** sort on LineAmountNew descending */
  LineamountnewDesc = '_LINEAMOUNTNEW_DESC_',
  /** Sort on RentalPeriodNew ascending */
  RentalperiodnewAsc = '_RENTALPERIODNEW_ASC_',
  /** sort on RentalPeriodNew descending */
  RentalperiodnewDesc = '_RENTALPERIODNEW_DESC_',
  /** Sort on LineDiscountNew ascending */
  LinediscountnewAsc = '_LINEDISCOUNTNEW_ASC_',
  /** sort on LineDiscountNew descending */
  LinediscountnewDesc = '_LINEDISCOUNTNEW_DESC_',
  /** Sort on LineDiscountAmountNew ascending */
  LinediscountamountnewAsc = '_LINEDISCOUNTAMOUNTNEW_ASC_',
  /** sort on LineDiscountAmountNew descending */
  LinediscountamountnewDesc = '_LINEDISCOUNTAMOUNTNEW_DESC_',
  /** Sort on StairPriceCodeNew ascending */
  StairpricecodenewAsc = '_STAIRPRICECODENEW_ASC_',
  /** sort on StairPriceCodeNew descending */
  StairpricecodenewDesc = '_STAIRPRICECODENEW_DESC_',
  /** Sort on StairDiscountCodeNew ascending */
  StairdiscountcodenewAsc = '_STAIRDISCOUNTCODENEW_ASC_',
  /** sort on StairDiscountCodeNew descending */
  StairdiscountcodenewDesc = '_STAIRDISCOUNTCODENEW_DESC_',
  /** Sort on BaseCalendarCodeNew ascending */
  BasecalendarcodenewAsc = '_BASECALENDARCODENEW_ASC_',
  /** sort on BaseCalendarCodeNew descending */
  BasecalendarcodenewDesc = '_BASECALENDARCODENEW_DESC_',
  /** Sort on StairPrice1New ascending */
  Stairprice1NewAsc = '_STAIRPRICE1NEW_ASC_',
  /** sort on StairPrice1New descending */
  Stairprice1NewDesc = '_STAIRPRICE1NEW_DESC_',
  /** Sort on StairPrice2New ascending */
  Stairprice2NewAsc = '_STAIRPRICE2NEW_ASC_',
  /** sort on StairPrice2New descending */
  Stairprice2NewDesc = '_STAIRPRICE2NEW_DESC_',
  /** Sort on StairPrice3New ascending */
  Stairprice3NewAsc = '_STAIRPRICE3NEW_ASC_',
  /** sort on StairPrice3New descending */
  Stairprice3NewDesc = '_STAIRPRICE3NEW_DESC_',
  /** Sort on StairPrice4New ascending */
  Stairprice4NewAsc = '_STAIRPRICE4NEW_ASC_',
  /** sort on StairPrice4New descending */
  Stairprice4NewDesc = '_STAIRPRICE4NEW_DESC_',
  /** Sort on StairPrice5New ascending */
  Stairprice5NewAsc = '_STAIRPRICE5NEW_ASC_',
  /** sort on StairPrice5New descending */
  Stairprice5NewDesc = '_STAIRPRICE5NEW_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on Preliminary ascending */
  PreliminaryAsc = '_PRELIMINARY_ASC_',
  /** sort on Preliminary descending */
  PreliminaryDesc = '_PRELIMINARY_DESC_',
  /** Sort on ReferenceTableID ascending */
  ReferencetableidAsc = '_REFERENCETABLEID_ASC_',
  /** sort on ReferenceTableID descending */
  ReferencetableidDesc = '_REFERENCETABLEID_DESC_',
  /** Sort on ReferenceUniqueID ascending */
  ReferenceuniqueidAsc = '_REFERENCEUNIQUEID_ASC_',
  /** sort on ReferenceUniqueID descending */
  ReferenceuniqueidDesc = '_REFERENCEUNIQUEID_DESC_',
  /** Sort on UniqueID ascending */
  UniqueidAsc = '_UNIQUEID_ASC_',
  /** sort on UniqueID descending */
  UniqueidDesc = '_UNIQUEID_DESC_',
  /** Sort on CreatedTime ascending */
  CreatedtimeAsc = '_CREATEDTIME_ASC_',
  /** sort on CreatedTime descending */
  CreatedtimeDesc = '_CREATEDTIME_DESC_',
  /** Sort on ReplacementUnitPrice ascending */
  ReplacementunitpriceAsc = '_REPLACEMENTUNITPRICE_ASC_',
  /** sort on ReplacementUnitPrice descending */
  ReplacementunitpriceDesc = '_REPLACEMENTUNITPRICE_DESC_',
  /** Sort on ReRentObject ascending */
  RerentobjectAsc = '_RERENTOBJECT_ASC_',
  /** sort on ReRentObject descending */
  RerentobjectDesc = '_RERENTOBJECT_DESC_',
  /** Sort on ReRentCost ascending */
  RerentcostAsc = '_RERENTCOST_ASC_',
  /** sort on ReRentCost descending */
  RerentcostDesc = '_RERENTCOST_DESC_',
  /** Sort on UnitPriceprDay ascending */
  UnitpriceprdayAsc = '_UNITPRICEPRDAY_ASC_',
  /** sort on UnitPriceprDay descending */
  UnitpriceprdayDesc = '_UNITPRICEPRDAY_DESC_',
  /** Sort on UnitPriceprMonth ascending */
  UnitpriceprmonthAsc = '_UNITPRICEPRMONTH_ASC_',
  /** sort on UnitPriceprMonth descending */
  UnitpriceprmonthDesc = '_UNITPRICEPRMONTH_DESC_',
  /** Sort on AllocateObjectNo ascending */
  AllocateobjectnoAsc = '_ALLOCATEOBJECTNO_ASC_',
  /** sort on AllocateObjectNo descending */
  AllocateobjectnoDesc = '_ALLOCATEOBJECTNO_DESC_',
  /** Sort on DepositOrigUnitPrice ascending */
  DepositorigunitpriceAsc = '_DEPOSITORIGUNITPRICE_ASC_',
  /** sort on DepositOrigUnitPrice descending */
  DepositorigunitpriceDesc = '_DEPOSITORIGUNITPRICE_DESC_',
  /** Sort on MarkDepositEntry ascending */
  MarkdepositentryAsc = '_MARKDEPOSITENTRY_ASC_',
  /** sort on MarkDepositEntry descending */
  MarkdepositentryDesc = '_MARKDEPOSITENTRY_DESC_',
  /** Sort on DepositUnitPrice ascending */
  DepositunitpriceAsc = '_DEPOSITUNITPRICE_ASC_',
  /** sort on DepositUnitPrice descending */
  DepositunitpriceDesc = '_DEPOSITUNITPRICE_DESC_',
  /** Sort on DepositPostingNo ascending */
  DepositpostingnoAsc = '_DEPOSITPOSTINGNO_ASC_',
  /** sort on DepositPostingNo descending */
  DepositpostingnoDesc = '_DEPOSITPOSTINGNO_DESC_',
  /** Sort on DepositAmount ascending */
  DepositamountAsc = '_DEPOSITAMOUNT_ASC_',
  /** sort on DepositAmount descending */
  DepositamountDesc = '_DEPOSITAMOUNT_DESC_',
  /** Sort on ReplacementPrice ascending */
  ReplacementpriceAsc = '_REPLACEMENTPRICE_ASC_',
  /** sort on ReplacementPrice descending */
  ReplacementpriceDesc = '_REPLACEMENTPRICE_DESC_',
  /** Sort on DepositReturnPostingNo ascending */
  DepositreturnpostingnoAsc = '_DEPOSITRETURNPOSTINGNO_ASC_',
  /** sort on DepositReturnPostingNo descending */
  DepositreturnpostingnoDesc = '_DEPOSITRETURNPOSTINGNO_DESC_',
  /** Sort on AgreedToDate ascending */
  AgreedtodateAsc = '_AGREEDTODATE_ASC_',
  /** sort on AgreedToDate descending */
  AgreedtodateDesc = '_AGREEDTODATE_DESC_',
  /** Sort on DepositPostingType ascending */
  DepositpostingtypeAsc = '_DEPOSITPOSTINGTYPE_ASC_',
  /** sort on DepositPostingType descending */
  DepositpostingtypeDesc = '_DEPOSITPOSTINGTYPE_DESC_',
  /** Sort on DepositPostingTypeOPTION ascending */
  DepositpostingtypeoptionAsc = '_DEPOSITPOSTINGTYPEOPTION_ASC_',
  /** sort on DepositPostingTypeOPTION descending */
  DepositpostingtypeoptionDesc = '_DEPOSITPOSTINGTYPEOPTION_DESC_',
  /** Sort on DontTransfertoOrder ascending */
  DonttransfertoorderAsc = '_DONTTRANSFERTOORDER_ASC_',
  /** sort on DontTransfertoOrder descending */
  DonttransfertoorderDesc = '_DONTTRANSFERTOORDER_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on DeliveredBy ascending */
  DeliveredbyAsc = '_DELIVEREDBY_ASC_',
  /** sort on DeliveredBy descending */
  DeliveredbyDesc = '_DELIVEREDBY_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on SaleDocumentNo ascending */
  SaledocumentnoAsc = '_SALEDOCUMENTNO_ASC_',
  /** sort on SaleDocumentNo descending */
  SaledocumentnoDesc = '_SALEDOCUMENTNO_DESC_',
  /** Sort on SaleDocumentType ascending */
  SaledocumenttypeAsc = '_SALEDOCUMENTTYPE_ASC_',
  /** sort on SaleDocumentType descending */
  SaledocumenttypeDesc = '_SALEDOCUMENTTYPE_DESC_',
  /** Sort on SaleDocumentTypeOPTION ascending */
  SaledocumenttypeoptionAsc = '_SALEDOCUMENTTYPEOPTION_ASC_',
  /** sort on SaleDocumentTypeOPTION descending */
  SaledocumenttypeoptionDesc = '_SALEDOCUMENTTYPEOPTION_DESC_',
  /** Sort on SaleLineNo ascending */
  SalelinenoAsc = '_SALELINENO_ASC_',
  /** sort on SaleLineNo descending */
  SalelinenoDesc = '_SALELINENO_DESC_',
  /** Sort on SaleReserve ascending */
  SalereserveAsc = '_SALERESERVE_ASC_',
  /** sort on SaleReserve descending */
  SalereserveDesc = '_SALERESERVE_DESC_',
  /** Sort on SaleReserveOPTION ascending */
  SalereserveoptionAsc = '_SALERESERVEOPTION_ASC_',
  /** sort on SaleReserveOPTION descending */
  SalereserveoptionDesc = '_SALERESERVEOPTION_DESC_',
  /** Sort on ReturnQuantityHours ascending */
  ReturnquantityhoursAsc = '_RETURNQUANTITYHOURS_ASC_',
  /** sort on ReturnQuantityHours descending */
  ReturnquantityhoursDesc = '_RETURNQUANTITYHOURS_DESC_',
  /** Sort on ShipmentDateTime ascending */
  ShipmentdatetimeAsc = '_SHIPMENTDATETIME_ASC_',
  /** sort on ShipmentDateTime descending */
  ShipmentdatetimeDesc = '_SHIPMENTDATETIME_DESC_',
  /** Sort on EndRentalDateTime ascending */
  EndrentaldatetimeAsc = '_ENDRENTALDATETIME_ASC_',
  /** sort on EndRentalDateTime descending */
  EndrentaldatetimeDesc = '_ENDRENTALDATETIME_DESC_',
  /** Sort on ExpectedReturnDate ascending */
  ExpectedreturndateAsc = '_EXPECTEDRETURNDATE_ASC_',
  /** sort on ExpectedReturnDate descending */
  ExpectedreturndateDesc = '_EXPECTEDRETURNDATE_DESC_',
  /** Sort on DoShipmentNow ascending */
  DoshipmentnowAsc = '_DOSHIPMENTNOW_ASC_',
  /** sort on DoShipmentNow descending */
  DoshipmentnowDesc = '_DOSHIPMENTNOW_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on QtyHours ascending */
  QtyhoursAsc = '_QTYHOURS_ASC_',
  /** sort on QtyHours descending */
  QtyhoursDesc = '_QTYHOURS_DESC_',
  /** Sort on OnRentTime ascending */
  OnrenttimeAsc = '_ONRENTTIME_ASC_',
  /** sort on OnRentTime descending */
  OnrenttimeDesc = '_ONRENTTIME_DESC_',
  /** Sort on OffRentTime ascending */
  OffrenttimeAsc = '_OFFRENTTIME_ASC_',
  /** sort on OffRentTime descending */
  OffrenttimeDesc = '_OFFRENTTIME_DESC_',
  /** Sort on OnRentDateTime ascending */
  OnrentdatetimeAsc = '_ONRENTDATETIME_ASC_',
  /** sort on OnRentDateTime descending */
  OnrentdatetimeDesc = '_ONRENTDATETIME_DESC_',
  /** Sort on OffRentDateTime ascending */
  OffrentdatetimeAsc = '_OFFRENTDATETIME_ASC_',
  /** sort on OffRentDateTime descending */
  OffrentdatetimeDesc = '_OFFRENTDATETIME_DESC_',
  /** Sort on ReturnQuantityChargeHours ascending */
  ReturnquantitychargehoursAsc = '_RETURNQUANTITYCHARGEHOURS_ASC_',
  /** sort on ReturnQuantityChargeHours descending */
  ReturnquantitychargehoursDesc = '_RETURNQUANTITYCHARGEHOURS_DESC_',
  /** Sort on TerminPeriod ascending */
  TerminperiodAsc = '_TERMINPERIOD_ASC_',
  /** sort on TerminPeriod descending */
  TerminperiodDesc = '_TERMINPERIOD_DESC_',
  /** Sort on LineAmountPeriod ascending */
  LineamountperiodAsc = '_LINEAMOUNTPERIOD_ASC_',
  /** sort on LineAmountPeriod descending */
  LineamountperiodDesc = '_LINEAMOUNTPERIOD_DESC_',
  /** Sort on IgnorCalendarCheck ascending */
  IgnorcalendarcheckAsc = '_IGNORCALENDARCHECK_ASC_',
  /** sort on IgnorCalendarCheck descending */
  IgnorcalendarcheckDesc = '_IGNORCALENDARCHECK_DESC_',
  /** Sort on MeasurementatDelivery ascending */
  MeasurementatdeliveryAsc = '_MEASUREMENTATDELIVERY_ASC_',
  /** sort on MeasurementatDelivery descending */
  MeasurementatdeliveryDesc = '_MEASUREMENTATDELIVERY_DESC_',
  /** Sort on MeasurementatReturn ascending */
  MeasurementatreturnAsc = '_MEASUREMENTATRETURN_ASC_',
  /** sort on MeasurementatReturn descending */
  MeasurementatreturnDesc = '_MEASUREMENTATRETURN_DESC_',
  /** Sort on DiamondMotherLineNo ascending */
  DiamondmotherlinenoAsc = '_DIAMONDMOTHERLINENO_ASC_',
  /** sort on DiamondMotherLineNo descending */
  DiamondmotherlinenoDesc = '_DIAMONDMOTHERLINENO_DESC_',
  /** Sort on DiamondBladeMotherRef ascending */
  DiamondblademotherrefAsc = '_DIAMONDBLADEMOTHERREF_ASC_',
  /** sort on DiamondBladeMotherRef descending */
  DiamondblademotherrefDesc = '_DIAMONDBLADEMOTHERREF_DESC_',
  /** Sort on ReturnAdditionMotherRef ascending */
  ReturnadditionmotherrefAsc = '_RETURNADDITIONMOTHERREF_ASC_',
  /** sort on ReturnAdditionMotherRef descending */
  ReturnadditionmotherrefDesc = '_RETURNADDITIONMOTHERREF_DESC_',
  /** Sort on StairPriceCode ascending */
  StairpricecodeAsc = '_STAIRPRICECODE_ASC_',
  /** sort on StairPriceCode descending */
  StairpricecodeDesc = '_STAIRPRICECODE_DESC_',
  /** Sort on ExtraHoursReturnTime ascending */
  ExtrahoursreturntimeAsc = '_EXTRAHOURSRETURNTIME_ASC_',
  /** sort on ExtraHoursReturnTime descending */
  ExtrahoursreturntimeDesc = '_EXTRAHOURSRETURNTIME_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on TmpAccumEntry ascending */
  TmpaccumentryAsc = '_TMPACCUMENTRY_ASC_',
  /** sort on TmpAccumEntry descending */
  TmpaccumentryDesc = '_TMPACCUMENTRY_DESC_',
  /** Sort on MarkReclassLineNoRef ascending */
  MarkreclasslinenorefAsc = '_MARKRECLASSLINENOREF_ASC_',
  /** sort on MarkReclassLineNoRef descending */
  MarkreclasslinenorefDesc = '_MARKRECLASSLINENOREF_DESC_',
  /** Sort on MarkReclassQuantity ascending */
  MarkreclassquantityAsc = '_MARKRECLASSQUANTITY_ASC_',
  /** sort on MarkReclassQuantity descending */
  MarkreclassquantityDesc = '_MARKRECLASSQUANTITY_DESC_',
  /** Sort on PostingPriority ascending */
  PostingpriorityAsc = '_POSTINGPRIORITY_ASC_',
  /** sort on PostingPriority descending */
  PostingpriorityDesc = '_POSTINGPRIORITY_DESC_',
  /** Sort on PostingPriorityOPTION ascending */
  PostingpriorityoptionAsc = '_POSTINGPRIORITYOPTION_ASC_',
  /** sort on PostingPriorityOPTION descending */
  PostingpriorityoptionDesc = '_POSTINGPRIORITYOPTION_DESC_',
  /** Sort on ReclassFromLocationCode ascending */
  ReclassfromlocationcodeAsc = '_RECLASSFROMLOCATIONCODE_ASC_',
  /** sort on ReclassFromLocationCode descending */
  ReclassfromlocationcodeDesc = '_RECLASSFROMLOCATIONCODE_DESC_',
  /** Sort on ReclassOrderedBy ascending */
  ReclassorderedbyAsc = '_RECLASSORDEREDBY_ASC_',
  /** sort on ReclassOrderedBy descending */
  ReclassorderedbyDesc = '_RECLASSORDEREDBY_DESC_',
  /** Sort on MarkReclassLine ascending */
  MarkreclasslineAsc = '_MARKRECLASSLINE_ASC_',
  /** sort on MarkReclassLine descending */
  MarkreclasslineDesc = '_MARKRECLASSLINE_DESC_',
  /** Sort on FilterReclassLine ascending */
  FilterreclasslineAsc = '_FILTERRECLASSLINE_ASC_',
  /** sort on FilterReclassLine descending */
  FilterreclasslineDesc = '_FILTERRECLASSLINE_DESC_',
  /** Sort on ReclassFromContractNo ascending */
  ReclassfromcontractnoAsc = '_RECLASSFROMCONTRACTNO_ASC_',
  /** sort on ReclassFromContractNo descending */
  ReclassfromcontractnoDesc = '_RECLASSFROMCONTRACTNO_DESC_',
  /** Sort on ReclassToContractNo ascending */
  ReclasstocontractnoAsc = '_RECLASSTOCONTRACTNO_ASC_',
  /** sort on ReclassToContractNo descending */
  ReclasstocontractnoDesc = '_RECLASSTOCONTRACTNO_DESC_',
  /** Sort on ReclassToLocationCode ascending */
  ReclasstolocationcodeAsc = '_RECLASSTOLOCATIONCODE_ASC_',
  /** sort on ReclassToLocationCode descending */
  ReclasstolocationcodeDesc = '_RECLASSTOLOCATIONCODE_DESC_',
  /** Sort on ReclassOrderNo ascending */
  ReclassordernoAsc = '_RECLASSORDERNO_ASC_',
  /** sort on ReclassOrderNo descending */
  ReclassordernoDesc = '_RECLASSORDERNO_DESC_',
  /** Sort on ReclassDocNo ascending */
  ReclassdocnoAsc = '_RECLASSDOCNO_ASC_',
  /** sort on ReclassDocNo descending */
  ReclassdocnoDesc = '_RECLASSDOCNO_DESC_',
  /** Sort on WorkshopObjectNo ascending */
  WorkshopobjectnoAsc = '_WORKSHOPOBJECTNO_ASC_',
  /** sort on WorkshopObjectNo descending */
  WorkshopobjectnoDesc = '_WORKSHOPOBJECTNO_DESC_',
  /** Sort on DiamondBladeItem ascending */
  DiamondbladeitemAsc = '_DIAMONDBLADEITEM_ASC_',
  /** sort on DiamondBladeItem descending */
  DiamondbladeitemDesc = '_DIAMONDBLADEITEM_DESC_',
  /** Sort on DiamondBladeItemOPTION ascending */
  DiamondbladeitemoptionAsc = '_DIAMONDBLADEITEMOPTION_ASC_',
  /** sort on DiamondBladeItemOPTION descending */
  DiamondbladeitemoptionDesc = '_DIAMONDBLADEITEMOPTION_DESC_',
  /** Sort on IgnoreVaryingCompQty ascending */
  IgnorevaryingcompqtyAsc = '_IGNOREVARYINGCOMPQTY_ASC_',
  /** sort on IgnoreVaryingCompQty descending */
  IgnorevaryingcompqtyDesc = '_IGNOREVARYINGCOMPQTY_DESC_',
  /** Sort on ExploreLine ascending */
  ExplorelineAsc = '_EXPLORELINE_ASC_',
  /** sort on ExploreLine descending */
  ExplorelineDesc = '_EXPLORELINE_DESC_',
  /** Sort on EnvironmentalFee ascending */
  EnvironmentalfeeAsc = '_ENVIRONMENTALFEE_ASC_',
  /** sort on EnvironmentalFee descending */
  EnvironmentalfeeDesc = '_ENVIRONMENTALFEE_DESC_',
  /** Sort on ExtraHoursCharged ascending */
  ExtrahourschargedAsc = '_EXTRAHOURSCHARGED_ASC_',
  /** sort on ExtraHoursCharged descending */
  ExtrahourschargedDesc = '_EXTRAHOURSCHARGED_DESC_',
  /** Sort on ExtraHoursReturnNo ascending */
  ExtrahoursreturnnoAsc = '_EXTRAHOURSRETURNNO_ASC_',
  /** sort on ExtraHoursReturnNo descending */
  ExtrahoursreturnnoDesc = '_EXTRAHOURSRETURNNO_DESC_',
  /** Sort on PriceonExtraHours ascending */
  PriceonextrahoursAsc = '_PRICEONEXTRAHOURS_ASC_',
  /** sort on PriceonExtraHours descending */
  PriceonextrahoursDesc = '_PRICEONEXTRAHOURS_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on ReclassDocLineNo ascending */
  ReclassdoclinenoAsc = '_RECLASSDOCLINENO_ASC_',
  /** sort on ReclassDocLineNo descending */
  ReclassdoclinenoDesc = '_RECLASSDOCLINENO_DESC_',
  /** Sort on RentalSplitCostCenter ascending */
  RentalsplitcostcenterAsc = '_RENTALSPLITCOSTCENTER_ASC_',
  /** sort on RentalSplitCostCenter descending */
  RentalsplitcostcenterDesc = '_RENTALSPLITCOSTCENTER_DESC_',
  /** Sort on RentalSplit ascending */
  RentalsplitAsc = '_RENTALSPLIT_ASC_',
  /** sort on RentalSplit descending */
  RentalsplitDesc = '_RENTALSPLIT_DESC_',
  /** Sort on PricePriority ascending */
  PricepriorityAsc = '_PRICEPRIORITY_ASC_',
  /** sort on PricePriority descending */
  PricepriorityDesc = '_PRICEPRIORITY_DESC_',
  /** Sort on PricePriorityOPTION ascending */
  PricepriorityoptionAsc = '_PRICEPRIORITYOPTION_ASC_',
  /** sort on PricePriorityOPTION descending */
  PricepriorityoptionDesc = '_PRICEPRIORITYOPTION_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on TotalNetWeight ascending */
  TotalnetweightAsc = '_TOTALNETWEIGHT_ASC_',
  /** sort on TotalNetWeight descending */
  TotalnetweightDesc = '_TOTALNETWEIGHT_DESC_',
  /** Sort on RecursiveLevel ascending */
  RecursivelevelAsc = '_RECURSIVELEVEL_ASC_',
  /** sort on RecursiveLevel descending */
  RecursivelevelDesc = '_RECURSIVELEVEL_DESC_',
  /** Sort on Repurchase ascending */
  RepurchaseAsc = '_REPURCHASE_ASC_',
  /** sort on Repurchase descending */
  RepurchaseDesc = '_REPURCHASE_DESC_',
  /** Sort on PriceScheduleCode ascending */
  PriceschedulecodeAsc = '_PRICESCHEDULECODE_ASC_',
  /** sort on PriceScheduleCode descending */
  PriceschedulecodeDesc = '_PRICESCHEDULECODE_DESC_',
  /** Sort on CPNextInvoiceAmount ascending */
  CpnextinvoiceamountAsc = '_CPNEXTINVOICEAMOUNT_ASC_',
  /** sort on CPNextInvoiceAmount descending */
  CpnextinvoiceamountDesc = '_CPNEXTINVOICEAMOUNT_DESC_',
  /** Sort on CPAdvancePaymentStatus ascending */
  CpadvancepaymentstatusAsc = '_CPADVANCEPAYMENTSTATUS_ASC_',
  /** sort on CPAdvancePaymentStatus descending */
  CpadvancepaymentstatusDesc = '_CPADVANCEPAYMENTSTATUS_DESC_',
  /** Sort on CPAdvancePaymentStatusOPTION ascending */
  CpadvancepaymentstatusoptionAsc = '_CPADVANCEPAYMENTSTATUSOPTION_ASC_',
  /** sort on CPAdvancePaymentStatusOPTION descending */
  CpadvancepaymentstatusoptionDesc = '_CPADVANCEPAYMENTSTATUSOPTION_DESC_',
  /** Sort on CPAdvancePaymentDocNo ascending */
  CpadvancepaymentdocnoAsc = '_CPADVANCEPAYMENTDOCNO_ASC_',
  /** sort on CPAdvancePaymentDocNo descending */
  CpadvancepaymentdocnoDesc = '_CPADVANCEPAYMENTDOCNO_DESC_',
  /** Sort on InvoiceFee ascending */
  InvoicefeeAsc = '_INVOICEFEE_ASC_',
  /** sort on InvoiceFee descending */
  InvoicefeeDesc = '_INVOICEFEE_DESC_',
  /** Sort on StairDiscountCode ascending */
  StairdiscountcodeAsc = '_STAIRDISCOUNTCODE_ASC_',
  /** sort on StairDiscountCode descending */
  StairdiscountcodeDesc = '_STAIRDISCOUNTCODE_DESC_',
  /** Sort on StairPriceDiscount ascending */
  StairpricediscountAsc = '_STAIRPRICEDISCOUNT_ASC_',
  /** sort on StairPriceDiscount descending */
  StairpricediscountDesc = '_STAIRPRICEDISCOUNT_DESC_',
  /** Sort on ReceiveRespCenter ascending */
  ReceiverespcenterAsc = '_RECEIVERESPCENTER_ASC_',
  /** sort on ReceiveRespCenter descending */
  ReceiverespcenterDesc = '_RECEIVERESPCENTER_DESC_',
  /** Sort on ReceiveCostCenter ascending */
  ReceivecostcenterAsc = '_RECEIVECOSTCENTER_ASC_',
  /** sort on ReceiveCostCenter descending */
  ReceivecostcenterDesc = '_RECEIVECOSTCENTER_DESC_',
  /** Sort on AllowLineDisconExHours ascending */
  AllowlinedisconexhoursAsc = '_ALLOWLINEDISCONEXHOURS_ASC_',
  /** sort on AllowLineDisconExHours descending */
  AllowlinedisconexhoursDesc = '_ALLOWLINEDISCONEXHOURS_DESC_',
  /** Sort on QuantitytoAllocate ascending */
  QuantitytoallocateAsc = '_QUANTITYTOALLOCATE_ASC_',
  /** sort on QuantitytoAllocate descending */
  QuantitytoallocateDesc = '_QUANTITYTOALLOCATE_DESC_',
  /** Sort on ServiceMotherLineNo ascending */
  ServicemotherlinenoAsc = '_SERVICEMOTHERLINENO_ASC_',
  /** sort on ServiceMotherLineNo descending */
  ServicemotherlinenoDesc = '_SERVICEMOTHERLINENO_DESC_',
  /** Sort on AdditionalCharge ascending */
  AdditionalchargeAsc = '_ADDITIONALCHARGE_ASC_',
  /** sort on AdditionalCharge descending */
  AdditionalchargeDesc = '_ADDITIONALCHARGE_DESC_',
  /** Sort on AdjforAdditionalCharge ascending */
  AdjforadditionalchargeAsc = '_ADJFORADDITIONALCHARGE_ASC_',
  /** sort on AdjforAdditionalCharge descending */
  AdjforadditionalchargeDesc = '_ADJFORADDITIONALCHARGE_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on EQMServiceNoTemp ascending */
  EqmservicenotempAsc = '_EQMSERVICENOTEMP_ASC_',
  /** sort on EQMServiceNoTemp descending */
  EqmservicenotempDesc = '_EQMSERVICENOTEMP_DESC_',
  /** Sort on PriceReportedHours ascending */
  PricereportedhoursAsc = '_PRICEREPORTEDHOURS_ASC_',
  /** sort on PriceReportedHours descending */
  PricereportedhoursDesc = '_PRICEREPORTEDHOURS_DESC_',
  /** Sort on FreeRentalHours ascending */
  FreerentalhoursAsc = '_FREERENTALHOURS_ASC_',
  /** sort on FreeRentalHours descending */
  FreerentalhoursDesc = '_FREERENTALHOURS_DESC_',
  /** Sort on DispatchTask ascending */
  DispatchtaskAsc = '_DISPATCHTASK_ASC_',
  /** sort on DispatchTask descending */
  DispatchtaskDesc = '_DISPATCHTASK_DESC_',
  /** Sort on DispatchTaskOPTION ascending */
  DispatchtaskoptionAsc = '_DISPATCHTASKOPTION_ASC_',
  /** sort on DispatchTaskOPTION descending */
  DispatchtaskoptionDesc = '_DISPATCHTASKOPTION_DESC_',
  /** Sort on DispatchStatus ascending */
  DispatchstatusAsc = '_DISPATCHSTATUS_ASC_',
  /** sort on DispatchStatus descending */
  DispatchstatusDesc = '_DISPATCHSTATUS_DESC_',
  /** Sort on DispatchStatusOPTION ascending */
  DispatchstatusoptionAsc = '_DISPATCHSTATUSOPTION_ASC_',
  /** sort on DispatchStatusOPTION descending */
  DispatchstatusoptionDesc = '_DISPATCHSTATUSOPTION_DESC_',
  /** Sort on ScheduledDeliveryDate ascending */
  ScheduleddeliverydateAsc = '_SCHEDULEDDELIVERYDATE_ASC_',
  /** sort on ScheduledDeliveryDate descending */
  ScheduleddeliverydateDesc = '_SCHEDULEDDELIVERYDATE_DESC_',
  /** Sort on ScheduledDeliveryTime ascending */
  ScheduleddeliverytimeAsc = '_SCHEDULEDDELIVERYTIME_ASC_',
  /** sort on ScheduledDeliveryTime descending */
  ScheduleddeliverytimeDesc = '_SCHEDULEDDELIVERYTIME_DESC_',
  /** Sort on ScheduledPickupDate ascending */
  ScheduledpickupdateAsc = '_SCHEDULEDPICKUPDATE_ASC_',
  /** sort on ScheduledPickupDate descending */
  ScheduledpickupdateDesc = '_SCHEDULEDPICKUPDATE_DESC_',
  /** Sort on ScheduledPickupTime ascending */
  ScheduledpickuptimeAsc = '_SCHEDULEDPICKUPTIME_ASC_',
  /** sort on ScheduledPickupTime descending */
  ScheduledpickuptimeDesc = '_SCHEDULEDPICKUPTIME_DESC_',
  /** Sort on DeliveryDriver ascending */
  DeliverydriverAsc = '_DELIVERYDRIVER_ASC_',
  /** sort on DeliveryDriver descending */
  DeliverydriverDesc = '_DELIVERYDRIVER_DESC_',
  /** Sort on PickupDriver ascending */
  PickupdriverAsc = '_PICKUPDRIVER_ASC_',
  /** sort on PickupDriver descending */
  PickupdriverDesc = '_PICKUPDRIVER_DESC_',
  /** Sort on Driver ascending */
  DriverAsc = '_DRIVER_ASC_',
  /** sort on Driver descending */
  DriverDesc = '_DRIVER_DESC_',
  /** Sort on SchedDate ascending */
  ScheddateAsc = '_SCHEDDATE_ASC_',
  /** sort on SchedDate descending */
  ScheddateDesc = '_SCHEDDATE_DESC_',
  /** Sort on ActualDate ascending */
  ActualdateAsc = '_ACTUALDATE_ASC_',
  /** sort on ActualDate descending */
  ActualdateDesc = '_ACTUALDATE_DESC_',
  /** Sort on SchedTime ascending */
  SchedtimeAsc = '_SCHEDTIME_ASC_',
  /** sort on SchedTime descending */
  SchedtimeDesc = '_SCHEDTIME_DESC_',
  /** Sort on ActualTime ascending */
  ActualtimeAsc = '_ACTUALTIME_ASC_',
  /** sort on ActualTime descending */
  ActualtimeDesc = '_ACTUALTIME_DESC_',
  /** Sort on ActualDeliveryDate ascending */
  ActualdeliverydateAsc = '_ACTUALDELIVERYDATE_ASC_',
  /** sort on ActualDeliveryDate descending */
  ActualdeliverydateDesc = '_ACTUALDELIVERYDATE_DESC_',
  /** Sort on ActualDeliveryTime ascending */
  ActualdeliverytimeAsc = '_ACTUALDELIVERYTIME_ASC_',
  /** sort on ActualDeliveryTime descending */
  ActualdeliverytimeDesc = '_ACTUALDELIVERYTIME_DESC_',
  /** Sort on ActualPickupDate ascending */
  ActualpickupdateAsc = '_ACTUALPICKUPDATE_ASC_',
  /** sort on ActualPickupDate descending */
  ActualpickupdateDesc = '_ACTUALPICKUPDATE_DESC_',
  /** Sort on ActualPickupTime ascending */
  ActualpickuptimeAsc = '_ACTUALPICKUPTIME_ASC_',
  /** sort on ActualPickupTime descending */
  ActualpickuptimeDesc = '_ACTUALPICKUPTIME_DESC_',
  /** Sort on ActualReturnDate ascending */
  ActualreturndateAsc = '_ACTUALRETURNDATE_ASC_',
  /** sort on ActualReturnDate descending */
  ActualreturndateDesc = '_ACTUALRETURNDATE_DESC_',
  /** Sort on ActualReturnTime ascending */
  ActualreturntimeAsc = '_ACTUALRETURNTIME_ASC_',
  /** sort on ActualReturnTime descending */
  ActualreturntimeDesc = '_ACTUALRETURNTIME_DESC_',
  /** Sort on MinimumReportedHours ascending */
  MinimumreportedhoursAsc = '_MINIMUMREPORTEDHOURS_ASC_',
  /** sort on MinimumReportedHours descending */
  MinimumreportedhoursDesc = '_MINIMUMREPORTEDHOURS_DESC_',
  /** Sort on MinRepHoursPeriod ascending */
  MinrephoursperiodAsc = '_MINREPHOURSPERIOD_ASC_',
  /** sort on MinRepHoursPeriod descending */
  MinrephoursperiodDesc = '_MINREPHOURSPERIOD_DESC_',
  /** Sort on StairPrice1 ascending */
  Stairprice1Asc = '_STAIRPRICE1_ASC_',
  /** sort on StairPrice1 descending */
  Stairprice1Desc = '_STAIRPRICE1_DESC_',
  /** Sort on StairPrice2 ascending */
  Stairprice2Asc = '_STAIRPRICE2_ASC_',
  /** sort on StairPrice2 descending */
  Stairprice2Desc = '_STAIRPRICE2_DESC_',
  /** Sort on StairPrice3 ascending */
  Stairprice3Asc = '_STAIRPRICE3_ASC_',
  /** sort on StairPrice3 descending */
  Stairprice3Desc = '_STAIRPRICE3_DESC_',
  /** Sort on DispatchDocType ascending */
  DispatchdoctypeAsc = '_DISPATCHDOCTYPE_ASC_',
  /** sort on DispatchDocType descending */
  DispatchdoctypeDesc = '_DISPATCHDOCTYPE_DESC_',
  /** Sort on DispatchDocTypeOPTION ascending */
  DispatchdoctypeoptionAsc = '_DISPATCHDOCTYPEOPTION_ASC_',
  /** sort on DispatchDocTypeOPTION descending */
  DispatchdoctypeoptionDesc = '_DISPATCHDOCTYPEOPTION_DESC_',
  /** Sort on DispatchDocNo ascending */
  DispatchdocnoAsc = '_DISPATCHDOCNO_ASC_',
  /** sort on DispatchDocNo descending */
  DispatchdocnoDesc = '_DISPATCHDOCNO_DESC_',
  /** Sort on DispatchLineNo ascending */
  DispatchlinenoAsc = '_DISPATCHLINENO_ASC_',
  /** sort on DispatchLineNo descending */
  DispatchlinenoDesc = '_DISPATCHLINENO_DESC_',
  /** Sort on StairPrice4 ascending */
  Stairprice4Asc = '_STAIRPRICE4_ASC_',
  /** sort on StairPrice4 descending */
  Stairprice4Desc = '_STAIRPRICE4_DESC_',
  /** Sort on StairPrice5 ascending */
  Stairprice5Asc = '_STAIRPRICE5_ASC_',
  /** sort on StairPrice5 descending */
  Stairprice5Desc = '_STAIRPRICE5_DESC_',
  /** Sort on MinimumRentalPeriod ascending */
  MinimumrentalperiodAsc = '_MINIMUMRENTALPERIOD_ASC_',
  /** sort on MinimumRentalPeriod descending */
  MinimumrentalperiodDesc = '_MINIMUMRENTALPERIOD_DESC_',
  /** Sort on MinimumRentaltoDate ascending */
  MinimumrentaltodateAsc = '_MINIMUMRENTALTODATE_ASC_',
  /** sort on MinimumRentaltoDate descending */
  MinimumrentaltodateDesc = '_MINIMUMRENTALTODATE_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on UserFilter ascending */
  UserfilterAsc = '_USERFILTER_ASC_',
  /** sort on UserFilter descending */
  UserfilterDesc = '_USERFILTER_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DefaultKITPriceCompFlow ascending */
  DefaultkitpricecompflowAsc = '_DEFAULTKITPRICECOMPFLOW_ASC_',
  /** sort on DefaultKITPriceCompFlow descending */
  DefaultkitpricecompflowDesc = '_DEFAULTKITPRICECOMPFLOW_DESC_',
  /** Sort on AssignedtoKIT ascending */
  AssignedtokitAsc = '_ASSIGNEDTOKIT_ASC_',
  /** sort on AssignedtoKIT descending */
  AssignedtokitDesc = '_ASSIGNEDTOKIT_DESC_',
  /** Sort on OffRentDateFlow ascending */
  OffrentdateflowAsc = '_OFFRENTDATEFLOW_ASC_',
  /** sort on OffRentDateFlow descending */
  OffrentdateflowDesc = '_OFFRENTDATEFLOW_DESC_',
  /** Sort on ReturnDateFlow ascending */
  ReturndateflowAsc = '_RETURNDATEFLOW_ASC_',
  /** sort on ReturnDateFlow descending */
  ReturndateflowDesc = '_RETURNDATEFLOW_DESC_',
  /** Sort on QuantityShippedtoFlow ascending */
  QuantityshippedtoflowAsc = '_QUANTITYSHIPPEDTOFLOW_ASC_',
  /** sort on QuantityShippedtoFlow descending */
  QuantityshippedtoflowDesc = '_QUANTITYSHIPPEDTOFLOW_DESC_',
  /** Sort on QuantityReturnedFlow ascending */
  QuantityreturnedflowAsc = '_QUANTITYRETURNEDFLOW_ASC_',
  /** sort on QuantityReturnedFlow descending */
  QuantityreturnedflowDesc = '_QUANTITYRETURNEDFLOW_DESC_',
  /** Sort on QuantityOffRentedFlow ascending */
  QuantityoffrentedflowAsc = '_QUANTITYOFFRENTEDFLOW_ASC_',
  /** sort on QuantityOffRentedFlow descending */
  QuantityoffrentedflowDesc = '_QUANTITYOFFRENTEDFLOW_DESC_',
  /** Sort on QuantityPstOffRentedFlow ascending */
  QuantitypstoffrentedflowAsc = '_QUANTITYPSTOFFRENTEDFLOW_ASC_',
  /** sort on QuantityPstOffRentedFlow descending */
  QuantitypstoffrentedflowDesc = '_QUANTITYPSTOFFRENTEDFLOW_DESC_',
  /** Sort on QuantityPstReturnedFlow ascending */
  QuantitypstreturnedflowAsc = '_QUANTITYPSTRETURNEDFLOW_ASC_',
  /** sort on QuantityPstReturnedFlow descending */
  QuantitypstreturnedflowDesc = '_QUANTITYPSTRETURNEDFLOW_DESC_',
  /** Sort on QuantityPstShippedFlow ascending */
  QuantitypstshippedflowAsc = '_QUANTITYPSTSHIPPEDFLOW_ASC_',
  /** sort on QuantityPstShippedFlow descending */
  QuantitypstshippedflowDesc = '_QUANTITYPSTSHIPPEDFLOW_DESC_',
  /** Sort on QtyonPickOrder ascending */
  QtyonpickorderAsc = '_QTYONPICKORDER_ASC_',
  /** sort on QtyonPickOrder descending */
  QtyonpickorderDesc = '_QTYONPICKORDER_DESC_',
  /** Sort on QtyonShipmentOrder ascending */
  QtyonshipmentorderAsc = '_QTYONSHIPMENTORDER_ASC_',
  /** sort on QtyonShipmentOrder descending */
  QtyonshipmentorderDesc = '_QTYONSHIPMENTORDER_DESC_',
  /** Sort on QtyonCollectionOrder ascending */
  QtyoncollectionorderAsc = '_QTYONCOLLECTIONORDER_ASC_',
  /** sort on QtyonCollectionOrder descending */
  QtyoncollectionorderDesc = '_QTYONCOLLECTIONORDER_DESC_',
  /** Sort on UserLocationFilter ascending */
  UserlocationfilterAsc = '_USERLOCATIONFILTER_ASC_',
  /** sort on UserLocationFilter descending */
  UserlocationfilterDesc = '_USERLOCATIONFILTER_DESC_',
  /** Sort on NoofWorkOrderLines ascending */
  NoofworkorderlinesAsc = '_NOOFWORKORDERLINES_ASC_',
  /** sort on NoofWorkOrderLines descending */
  NoofworkorderlinesDesc = '_NOOFWORKORDERLINES_DESC_',
  /** Sort on NoofPickOrderLines ascending */
  NoofpickorderlinesAsc = '_NOOFPICKORDERLINES_ASC_',
  /** sort on NoofPickOrderLines descending */
  NoofpickorderlinesDesc = '_NOOFPICKORDERLINES_DESC_',
  /** Sort on NoofShipmentOrderLines ascending */
  NoofshipmentorderlinesAsc = '_NOOFSHIPMENTORDERLINES_ASC_',
  /** sort on NoofShipmentOrderLines descending */
  NoofshipmentorderlinesDesc = '_NOOFSHIPMENTORDERLINES_DESC_',
  /** Sort on NoofCollectionOrderLines ascending */
  NoofcollectionorderlinesAsc = '_NOOFCOLLECTIONORDERLINES_ASC_',
  /** sort on NoofCollectionOrderLines descending */
  NoofcollectionorderlinesDesc = '_NOOFCOLLECTIONORDERLINES_DESC_',
  /** Sort on NoofPostedPickOrderLines ascending */
  NoofpostedpickorderlinesAsc = '_NOOFPOSTEDPICKORDERLINES_ASC_',
  /** sort on NoofPostedPickOrderLines descending */
  NoofpostedpickorderlinesDesc = '_NOOFPOSTEDPICKORDERLINES_DESC_',
  /** Sort on NoofPstdShptOrderLines ascending */
  NoofpstdshptorderlinesAsc = '_NOOFPSTDSHPTORDERLINES_ASC_',
  /** sort on NoofPstdShptOrderLines descending */
  NoofpstdshptorderlinesDesc = '_NOOFPSTDSHPTORDERLINES_DESC_',
  /** Sort on NoofPstdCollOrderLines ascending */
  NoofpstdcollorderlinesAsc = '_NOOFPSTDCOLLORDERLINES_ASC_',
  /** sort on NoofPstdCollOrderLines descending */
  NoofpstdcollorderlinesDesc = '_NOOFPSTDCOLLORDERLINES_DESC_',
  /** Sort on NoofRntlReservSummaries ascending */
  NoofrntlreservsummariesAsc = '_NOOFRNTLRESERVSUMMARIES_ASC_',
  /** sort on NoofRntlReservSummaries descending */
  NoofrntlreservsummariesDesc = '_NOOFRNTLRESERVSUMMARIES_DESC_',
  /** Sort on NoofRntlReservEntries ascending */
  NoofrntlreserventriesAsc = '_NOOFRNTLRESERVENTRIES_ASC_',
  /** sort on NoofRntlReservEntries descending */
  NoofrntlreserventriesDesc = '_NOOFRNTLRESERVENTRIES_DESC_',
  /** Sort on QtyonRntlReservEntry ascending */
  QtyonrntlreserventryAsc = '_QTYONRNTLRESERVENTRY_ASC_',
  /** sort on QtyonRntlReservEntry descending */
  QtyonrntlreserventryDesc = '_QTYONRNTLRESERVENTRY_DESC_',
  /** Sort on QtyonRntlResShptEntry ascending */
  QtyonrntlresshptentryAsc = '_QTYONRNTLRESSHPTENTRY_ASC_',
  /** sort on QtyonRntlResShptEntry descending */
  QtyonrntlresshptentryDesc = '_QTYONRNTLRESSHPTENTRY_DESC_',
  /** Sort on QtyonRntlResRetEntry ascending */
  QtyonrntlresretentryAsc = '_QTYONRNTLRESRETENTRY_ASC_',
  /** sort on QtyonRntlResRetEntry descending */
  QtyonrntlresretentryDesc = '_QTYONRNTLRESRETENTRY_DESC_',
  /** Sort on QtyonBufferEntry ascending */
  QtyonbufferentryAsc = '_QTYONBUFFERENTRY_ASC_',
  /** sort on QtyonBufferEntry descending */
  QtyonbufferentryDesc = '_QTYONBUFFERENTRY_DESC_',
  /** Sort on MultipleRentalPeriodPrice ascending */
  MultiplerentalperiodpriceAsc = '_MULTIPLERENTALPERIODPRICE_ASC_',
  /** sort on MultipleRentalPeriodPrice descending */
  MultiplerentalperiodpriceDesc = '_MULTIPLERENTALPERIODPRICE_DESC_',
  /** Sort on MultipleRentalPeriodEntry ascending */
  MultiplerentalperiodentryAsc = '_MULTIPLERENTALPERIODENTRY_ASC_',
  /** sort on MultipleRentalPeriodEntry descending */
  MultiplerentalperiodentryDesc = '_MULTIPLERENTALPERIODENTRY_DESC_',
  /** Sort on LastErrorText ascending */
  LasterrortextAsc = '_LASTERRORTEXT_ASC_',
  /** sort on LastErrorText descending */
  LasterrortextDesc = '_LASTERRORTEXT_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on SaleReservedQuantity ascending */
  SalereservedquantityAsc = '_SALERESERVEDQUANTITY_ASC_',
  /** sort on SaleReservedQuantity descending */
  SalereservedquantityDesc = '_SALERESERVEDQUANTITY_DESC_',
  /** Sort on SaleReservedQtyBase ascending */
  SalereservedqtybaseAsc = '_SALERESERVEDQTYBASE_ASC_',
  /** sort on SaleReservedQtyBase descending */
  SalereservedqtybaseDesc = '_SALERESERVEDQTYBASE_DESC_',
  /** Sort on KITNetWeight ascending */
  KitnetweightAsc = '_KITNETWEIGHT_ASC_',
  /** sort on KITNetWeight descending */
  KitnetweightDesc = '_KITNETWEIGHT_DESC_',
  /** Sort on ReportedRentalHours ascending */
  ReportedrentalhoursAsc = '_REPORTEDRENTALHOURS_ASC_',
  /** sort on ReportedRentalHours descending */
  ReportedrentalhoursDesc = '_REPORTEDRENTALHOURS_DESC_',
  /** Sort on EQMServiceDocNo ascending */
  EqmservicedocnoAsc = '_EQMSERVICEDOCNO_ASC_',
  /** sort on EQMServiceDocNo descending */
  EqmservicedocnoDesc = '_EQMSERVICEDOCNO_DESC_',
  /** Sort on DispatchDocument ascending */
  DispatchdocumentAsc = '_DISPATCHDOCUMENT_ASC_',
  /** sort on DispatchDocument descending */
  DispatchdocumentDesc = '_DISPATCHDOCUMENT_DESC_',
  /** Sort on DispatchDocumentNo ascending */
  DispatchdocumentnoAsc = '_DISPATCHDOCUMENTNO_ASC_',
  /** sort on DispatchDocumentNo descending */
  DispatchdocumentnoDesc = '_DISPATCHDOCUMENTNO_DESC_',
  /** Sort on GroupOnNumber ascending */
  GrouponnumberAsc = '_GROUPONNUMBER_ASC_',
  /** sort on GroupOnNumber descending */
  GrouponnumberDesc = '_GROUPONNUMBER_DESC_',
  /** Sort on PeriodLineAmountEdge ascending */
  PeriodlineamountedgeAsc = '_PERIODLINEAMOUNTEDGE_ASC_',
  /** sort on PeriodLineAmountEdge descending */
  PeriodlineamountedgeDesc = '_PERIODLINEAMOUNTEDGE_DESC_',
  /** Sort on RentalDays ascending */
  RentaldaysAsc = '_RENTALDAYS_ASC_',
  /** sort on RentalDays descending */
  RentaldaysDesc = '_RENTALDAYS_DESC_',
  /** Sort on RemainingQty ascending */
  RemainingqtyAsc = '_REMAININGQTY_ASC_',
  /** sort on RemainingQty descending */
  RemainingqtyDesc = '_REMAININGQTY_DESC_',
  /** Sort on FreeDays ascending */
  FreedaysAsc = '_FREEDAYS_ASC_',
  /** sort on FreeDays descending */
  FreedaysDesc = '_FREEDAYS_DESC_',
  /** Sort on DayNetPriceEdge ascending */
  DaynetpriceedgeAsc = '_DAYNETPRICEEDGE_ASC_',
  /** sort on DayNetPriceEdge descending */
  DaynetpriceedgeDesc = '_DAYNETPRICEEDGE_DESC_',
  /** Sort on Vaccation ascending */
  VaccationAsc = '_VACCATION_ASC_',
  /** sort on Vaccation descending */
  VaccationDesc = '_VACCATION_DESC_',
  /** Sort on ReturnReservationActive ascending */
  ReturnreservationactiveAsc = '_RETURNRESERVATIONACTIVE_ASC_',
  /** sort on ReturnReservationActive descending */
  ReturnreservationactiveDesc = '_RETURNRESERVATIONACTIVE_DESC_',
  /** Sort on UserExpectedReturnDate ascending */
  UserexpectedreturndateAsc = '_USEREXPECTEDRETURNDATE_ASC_',
  /** sort on UserExpectedReturnDate descending */
  UserexpectedreturndateDesc = '_USEREXPECTEDRETURNDATE_DESC_',
  /** Sort on ImageUrl ascending */
  ImageurlAsc = '_IMAGEURL_ASC_',
  /** sort on ImageUrl descending */
  ImageurlDesc = '_IMAGEURL_DESC_',
  /** Sort on PSILink ascending */
  PsilinkAsc = '_PSILINK_ASC_',
  /** sort on PSILink descending */
  PsilinkDesc = '_PSILINK_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalLineSnapshotInput {
  /** Sort on ContractType ascending */
  ContracttypeAsc = '_CONTRACTTYPE_ASC_',
  /** sort on ContractType descending */
  ContracttypeDesc = '_CONTRACTTYPE_DESC_',
  /** Sort on ContractTypeOPTION ascending */
  ContracttypeoptionAsc = '_CONTRACTTYPEOPTION_ASC_',
  /** sort on ContractTypeOPTION descending */
  ContracttypeoptionDesc = '_CONTRACTTYPEOPTION_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on ContractStatus ascending */
  ContractstatusAsc = '_CONTRACTSTATUS_ASC_',
  /** sort on ContractStatus descending */
  ContractstatusDesc = '_CONTRACTSTATUS_DESC_',
  /** Sort on ContractStatusOPTION ascending */
  ContractstatusoptionAsc = '_CONTRACTSTATUSOPTION_ASC_',
  /** sort on ContractStatusOPTION descending */
  ContractstatusoptionDesc = '_CONTRACTSTATUSOPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on StartRentalPeriod ascending */
  StartrentalperiodAsc = '_STARTRENTALPERIOD_ASC_',
  /** sort on StartRentalPeriod descending */
  StartrentalperiodDesc = '_STARTRENTALPERIOD_DESC_',
  /** Sort on EndRentalPeriod ascending */
  EndrentalperiodAsc = '_ENDRENTALPERIOD_ASC_',
  /** sort on EndRentalPeriod descending */
  EndrentalperiodDesc = '_ENDRENTALPERIOD_DESC_',
  /** Sort on ShipmentTime ascending */
  ShipmenttimeAsc = '_SHIPMENTTIME_ASC_',
  /** sort on ShipmentTime descending */
  ShipmenttimeDesc = '_SHIPMENTTIME_DESC_',
  /** Sort on ReturnTime ascending */
  ReturntimeAsc = '_RETURNTIME_ASC_',
  /** sort on ReturnTime descending */
  ReturntimeDesc = '_RETURNTIME_DESC_',
  /** Sort on InvoicedToDate ascending */
  InvoicedtodateAsc = '_INVOICEDTODATE_ASC_',
  /** sort on InvoicedToDate descending */
  InvoicedtodateDesc = '_INVOICEDTODATE_DESC_',
  /** Sort on NextInvoiceDate ascending */
  NextinvoicedateAsc = '_NEXTINVOICEDATE_ASC_',
  /** sort on NextInvoiceDate descending */
  NextinvoicedateDesc = '_NEXTINVOICEDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on RentalPeriod ascending */
  RentalperiodAsc = '_RENTALPERIOD_ASC_',
  /** sort on RentalPeriod descending */
  RentalperiodDesc = '_RENTALPERIOD_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on NextInvoicePeriodStart ascending */
  NextinvoiceperiodstartAsc = '_NEXTINVOICEPERIODSTART_ASC_',
  /** sort on NextInvoicePeriodStart descending */
  NextinvoiceperiodstartDesc = '_NEXTINVOICEPERIODSTART_DESC_',
  /** Sort on NextInvoicePeriodEnd ascending */
  NextinvoiceperiodendAsc = '_NEXTINVOICEPERIODEND_ASC_',
  /** sort on NextInvoicePeriodEnd descending */
  NextinvoiceperiodendDesc = '_NEXTINVOICEPERIODEND_DESC_',
  /** Sort on RentalPeriodInvoiced ascending */
  RentalperiodinvoicedAsc = '_RENTALPERIODINVOICED_ASC_',
  /** sort on RentalPeriodInvoiced descending */
  RentalperiodinvoicedDesc = '_RENTALPERIODINVOICED_DESC_',
  /** Sort on UseHourlyRent ascending */
  UsehourlyrentAsc = '_USEHOURLYRENT_ASC_',
  /** sort on UseHourlyRent descending */
  UsehourlyrentDesc = '_USEHOURLYRENT_DESC_',
  /** Sort on Credited ascending */
  CreditedAsc = '_CREDITED_ASC_',
  /** sort on Credited descending */
  CreditedDesc = '_CREDITED_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on EntryStatus ascending */
  EntrystatusAsc = '_ENTRYSTATUS_ASC_',
  /** sort on EntryStatus descending */
  EntrystatusDesc = '_ENTRYSTATUS_DESC_',
  /** Sort on EntryStatusOPTION ascending */
  EntrystatusoptionAsc = '_ENTRYSTATUSOPTION_ASC_',
  /** sort on EntryStatusOPTION descending */
  EntrystatusoptionDesc = '_ENTRYSTATUSOPTION_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on RentalClosed ascending */
  RentalclosedAsc = '_RENTALCLOSED_ASC_',
  /** sort on RentalClosed descending */
  RentalclosedDesc = '_RENTALCLOSED_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on RentalPeriodNow ascending */
  RentalperiodnowAsc = '_RENTALPERIODNOW_ASC_',
  /** sort on RentalPeriodNow descending */
  RentalperiodnowDesc = '_RENTALPERIODNOW_DESC_',
  /** Sort on RentalChargeDays ascending */
  RentalchargedaysAsc = '_RENTALCHARGEDAYS_ASC_',
  /** sort on RentalChargeDays descending */
  RentalchargedaysDesc = '_RENTALCHARGEDAYS_DESC_',
  /** Sort on RentalChargeDaysOPTION ascending */
  RentalchargedaysoptionAsc = '_RENTALCHARGEDAYSOPTION_ASC_',
  /** sort on RentalChargeDaysOPTION descending */
  RentalchargedaysoptionDesc = '_RENTALCHARGEDAYSOPTION_DESC_',
  /** Sort on DeliveredTime ascending */
  DeliveredtimeAsc = '_DELIVEREDTIME_ASC_',
  /** sort on DeliveredTime descending */
  DeliveredtimeDesc = '_DELIVEREDTIME_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on ReturnCompleted ascending */
  ReturncompletedAsc = '_RETURNCOMPLETED_ASC_',
  /** sort on ReturnCompleted descending */
  ReturncompletedDesc = '_RETURNCOMPLETED_DESC_',
  /** Sort on TransferdToOrder ascending */
  TransferdtoorderAsc = '_TRANSFERDTOORDER_ASC_',
  /** sort on TransferdToOrder descending */
  TransferdtoorderDesc = '_TRANSFERDTOORDER_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on Counter ascending */
  CounterAsc = '_COUNTER_ASC_',
  /** sort on Counter descending */
  CounterDesc = '_COUNTER_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on PriceTerm ascending */
  PricetermAsc = '_PRICETERM_ASC_',
  /** sort on PriceTerm descending */
  PricetermDesc = '_PRICETERM_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on UnitPriceSale ascending */
  UnitpricesaleAsc = '_UNITPRICESALE_ASC_',
  /** sort on UnitPriceSale descending */
  UnitpricesaleDesc = '_UNITPRICESALE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on ReservedSeasonCalendar ascending */
  ReservedseasoncalendarAsc = '_RESERVEDSEASONCALENDAR_ASC_',
  /** sort on ReservedSeasonCalendar descending */
  ReservedseasoncalendarDesc = '_RESERVEDSEASONCALENDAR_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on ShipmentNo ascending */
  ShipmentnoAsc = '_SHIPMENTNO_ASC_',
  /** sort on ShipmentNo descending */
  ShipmentnoDesc = '_SHIPMENTNO_DESC_',
  /** Sort on ShipmentLineNo ascending */
  ShipmentlinenoAsc = '_SHIPMENTLINENO_ASC_',
  /** sort on ShipmentLineNo descending */
  ShipmentlinenoDesc = '_SHIPMENTLINENO_DESC_',
  /** Sort on Counter2 ascending */
  Counter2Asc = '_COUNTER2_ASC_',
  /** sort on Counter2 descending */
  Counter2Desc = '_COUNTER2_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on EstimatedCounterperDay ascending */
  EstimatedcounterperdayAsc = '_ESTIMATEDCOUNTERPERDAY_ASC_',
  /** sort on EstimatedCounterperDay descending */
  EstimatedcounterperdayDesc = '_ESTIMATEDCOUNTERPERDAY_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on CustomerSubProject ascending */
  CustomersubprojectAsc = '_CUSTOMERSUBPROJECT_ASC_',
  /** sort on CustomerSubProject descending */
  CustomersubprojectDesc = '_CUSTOMERSUBPROJECT_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on EstimatedCounter2perDay ascending */
  Estimatedcounter2PerdayAsc = '_ESTIMATEDCOUNTER2PERDAY_ASC_',
  /** sort on EstimatedCounter2perDay descending */
  Estimatedcounter2PerdayDesc = '_ESTIMATEDCOUNTER2PERDAY_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on WorkInprogress ascending */
  WorkinprogressAsc = '_WORKINPROGRESS_ASC_',
  /** sort on WorkInprogress descending */
  WorkinprogressDesc = '_WORKINPROGRESS_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on PctofSalesPrice ascending */
  PctofsalespriceAsc = '_PCTOFSALESPRICE_ASC_',
  /** sort on PctofSalesPrice descending */
  PctofsalespriceDesc = '_PCTOFSALESPRICE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepmtLineAmount ascending */
  PrepmtlineamountAsc = '_PREPMTLINEAMOUNT_ASC_',
  /** sort on PrepmtLineAmount descending */
  PrepmtlineamountDesc = '_PREPMTLINEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInv ascending */
  PrepmtamtinvAsc = '_PREPMTAMTINV_ASC_',
  /** sort on PrepmtAmtInv descending */
  PrepmtamtinvDesc = '_PREPMTAMTINV_DESC_',
  /** Sort on PrepmtAmtInclVAT ascending */
  PrepmtamtinclvatAsc = '_PREPMTAMTINCLVAT_ASC_',
  /** sort on PrepmtAmtInclVAT descending */
  PrepmtamtinclvatDesc = '_PREPMTAMTINCLVAT_DESC_',
  /** Sort on PrepaymentPeriod ascending */
  PrepaymentperiodAsc = '_PREPAYMENTPERIOD_ASC_',
  /** sort on PrepaymentPeriod descending */
  PrepaymentperiodDesc = '_PREPAYMENTPERIOD_DESC_',
  /** Sort on PrepmtUseExpirationDate ascending */
  PrepmtuseexpirationdateAsc = '_PREPMTUSEEXPIRATIONDATE_ASC_',
  /** sort on PrepmtUseExpirationDate descending */
  PrepmtuseexpirationdateDesc = '_PREPMTUSEEXPIRATIONDATE_DESC_',
  /** Sort on PrepmtSalesLineNo ascending */
  PrepmtsaleslinenoAsc = '_PREPMTSALESLINENO_ASC_',
  /** sort on PrepmtSalesLineNo descending */
  PrepmtsaleslinenoDesc = '_PREPMTSALESLINENO_DESC_',
  /** Sort on PrepmtInvoiceStatus ascending */
  PrepmtinvoicestatusAsc = '_PREPMTINVOICESTATUS_ASC_',
  /** sort on PrepmtInvoiceStatus descending */
  PrepmtinvoicestatusDesc = '_PREPMTINVOICESTATUS_DESC_',
  /** Sort on PrepmtInvoiceStatusOPTION ascending */
  PrepmtinvoicestatusoptionAsc = '_PREPMTINVOICESTATUSOPTION_ASC_',
  /** sort on PrepmtInvoiceStatusOPTION descending */
  PrepmtinvoicestatusoptionDesc = '_PREPMTINVOICESTATUSOPTION_DESC_',
  /** Sort on PrepmtCrMemoStatus ascending */
  PrepmtcrmemostatusAsc = '_PREPMTCRMEMOSTATUS_ASC_',
  /** sort on PrepmtCrMemoStatus descending */
  PrepmtcrmemostatusDesc = '_PREPMTCRMEMOSTATUS_DESC_',
  /** Sort on PrepmtCrMemoStatusOPTION ascending */
  PrepmtcrmemostatusoptionAsc = '_PREPMTCRMEMOSTATUSOPTION_ASC_',
  /** sort on PrepmtCrMemoStatusOPTION descending */
  PrepmtcrmemostatusoptionDesc = '_PREPMTCRMEMOSTATUSOPTION_DESC_',
  /** Sort on PrepmtDocNo ascending */
  PrepmtdocnoAsc = '_PREPMTDOCNO_ASC_',
  /** sort on PrepmtDocNo descending */
  PrepmtdocnoDesc = '_PREPMTDOCNO_DESC_',
  /** Sort on PrepmtInsuranceAmount ascending */
  PrepmtinsuranceamountAsc = '_PREPMTINSURANCEAMOUNT_ASC_',
  /** sort on PrepmtInsuranceAmount descending */
  PrepmtinsuranceamountDesc = '_PREPMTINSURANCEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInOrder ascending */
  PrepmtamtinorderAsc = '_PREPMTAMTINORDER_ASC_',
  /** sort on PrepmtAmtInOrder descending */
  PrepmtamtinorderDesc = '_PREPMTAMTINORDER_DESC_',
  /** Sort on PrepmtAmtInOrderInclVAT ascending */
  PrepmtamtinorderinclvatAsc = '_PREPMTAMTINORDERINCLVAT_ASC_',
  /** sort on PrepmtAmtInOrderInclVAT descending */
  PrepmtamtinorderinclvatDesc = '_PREPMTAMTINORDERINCLVAT_DESC_',
  /** Sort on PrepmtInsuranceAmtInOrder ascending */
  PrepmtinsuranceamtinorderAsc = '_PREPMTINSURANCEAMTINORDER_ASC_',
  /** sort on PrepmtInsuranceAmtInOrder descending */
  PrepmtinsuranceamtinorderDesc = '_PREPMTINSURANCEAMTINORDER_DESC_',
  /** Sort on PrepmtAmttoDeduct ascending */
  PrepmtamttodeductAsc = '_PREPMTAMTTODEDUCT_ASC_',
  /** sort on PrepmtAmttoDeduct descending */
  PrepmtamttodeductDesc = '_PREPMTAMTTODEDUCT_DESC_',
  /** Sort on PrepmtAmtDeducted ascending */
  PrepmtamtdeductedAsc = '_PREPMTAMTDEDUCTED_ASC_',
  /** sort on PrepmtAmtDeducted descending */
  PrepmtamtdeductedDesc = '_PREPMTAMTDEDUCTED_DESC_',
  /** Sort on PrepmtAmttoDeductVAT ascending */
  PrepmtamttodeductvatAsc = '_PREPMTAMTTODEDUCTVAT_ASC_',
  /** sort on PrepmtAmttoDeductVAT descending */
  PrepmtamttodeductvatDesc = '_PREPMTAMTTODEDUCTVAT_DESC_',
  /** Sort on PrepmtAmtDeductedVAT ascending */
  PrepmtamtdeductedvatAsc = '_PREPMTAMTDEDUCTEDVAT_ASC_',
  /** sort on PrepmtAmtDeductedVAT descending */
  PrepmtamtdeductedvatDesc = '_PREPMTAMTDEDUCTEDVAT_DESC_',
  /** Sort on PrepmtRentalLineNo ascending */
  PrepmtrentallinenoAsc = '_PREPMTRENTALLINENO_ASC_',
  /** sort on PrepmtRentalLineNo descending */
  PrepmtrentallinenoDesc = '_PREPMTRENTALLINENO_DESC_',
  /** Sort on PrepmtInsuranceToDeduct ascending */
  PrepmtinsurancetodeductAsc = '_PREPMTINSURANCETODEDUCT_ASC_',
  /** sort on PrepmtInsuranceToDeduct descending */
  PrepmtinsurancetodeductDesc = '_PREPMTINSURANCETODEDUCT_DESC_',
  /** Sort on PrepmtInsuranceDeducted ascending */
  PrepmtinsurancedeductedAsc = '_PREPMTINSURANCEDEDUCTED_ASC_',
  /** sort on PrepmtInsuranceDeducted descending */
  PrepmtinsurancedeductedDesc = '_PREPMTINSURANCEDEDUCTED_DESC_',
  /** Sort on PrepmtInsuranceAmtInclVAT ascending */
  PrepmtinsuranceamtinclvatAsc = '_PREPMTINSURANCEAMTINCLVAT_ASC_',
  /** sort on PrepmtInsuranceAmtInclVAT descending */
  PrepmtinsuranceamtinclvatDesc = '_PREPMTINSURANCEAMTINCLVAT_DESC_',
  /** Sort on PrepmtInsAmtOrderIncVAT ascending */
  PrepmtinsamtorderincvatAsc = '_PREPMTINSAMTORDERINCVAT_ASC_',
  /** sort on PrepmtInsAmtOrderIncVAT descending */
  PrepmtinsamtorderincvatDesc = '_PREPMTINSAMTORDERINCVAT_DESC_',
  /** Sort on PrepmtDimensionSetID ascending */
  PrepmtdimensionsetidAsc = '_PREPMTDIMENSIONSETID_ASC_',
  /** sort on PrepmtDimensionSetID descending */
  PrepmtdimensionsetidDesc = '_PREPMTDIMENSIONSETID_DESC_',
  /** Sort on PrepmtVATBusPostingGroup ascending */
  PrepmtvatbuspostinggroupAsc = '_PREPMTVATBUSPOSTINGGROUP_ASC_',
  /** sort on PrepmtVATBusPostingGroup descending */
  PrepmtvatbuspostinggroupDesc = '_PREPMTVATBUSPOSTINGGROUP_DESC_',
  /** Sort on PrepmtVATProdPostingGrp ascending */
  PrepmtvatprodpostinggrpAsc = '_PREPMTVATPRODPOSTINGGRP_ASC_',
  /** sort on PrepmtVATProdPostingGrp descending */
  PrepmtvatprodpostinggrpDesc = '_PREPMTVATPRODPOSTINGGRP_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on OldAttachedtoLineNo ascending */
  OldattachedtolinenoAsc = '_OLDATTACHEDTOLINENO_ASC_',
  /** sort on OldAttachedtoLineNo descending */
  OldattachedtolinenoDesc = '_OLDATTACHEDTOLINENO_DESC_',
  /** Sort on AttachedtoSwitchLineNo ascending */
  AttachedtoswitchlinenoAsc = '_ATTACHEDTOSWITCHLINENO_ASC_',
  /** sort on AttachedtoSwitchLineNo descending */
  AttachedtoswitchlinenoDesc = '_ATTACHEDTOSWITCHLINENO_DESC_',
  /** Sort on AttachedStandByLineNo ascending */
  AttachedstandbylinenoAsc = '_ATTACHEDSTANDBYLINENO_ASC_',
  /** sort on AttachedStandByLineNo descending */
  AttachedstandbylinenoDesc = '_ATTACHEDSTANDBYLINENO_DESC_',
  /** Sort on StandbyStatus ascending */
  StandbystatusAsc = '_STANDBYSTATUS_ASC_',
  /** sort on StandbyStatus descending */
  StandbystatusDesc = '_STANDBYSTATUS_DESC_',
  /** Sort on StandbyStatusOPTION ascending */
  StandbystatusoptionAsc = '_STANDBYSTATUSOPTION_ASC_',
  /** sort on StandbyStatusOPTION descending */
  StandbystatusoptionDesc = '_STANDBYSTATUSOPTION_DESC_',
  /** Sort on QtyonAttachedtoLineNo ascending */
  QtyonattachedtolinenoAsc = '_QTYONATTACHEDTOLINENO_ASC_',
  /** sort on QtyonAttachedtoLineNo descending */
  QtyonattachedtolinenoDesc = '_QTYONATTACHEDTOLINENO_DESC_',
  /** Sort on KITMother ascending */
  KitmotherAsc = '_KITMOTHER_ASC_',
  /** sort on KITMother descending */
  KitmotherDesc = '_KITMOTHER_DESC_',
  /** Sort on KITFactor ascending */
  KitfactorAsc = '_KITFACTOR_ASC_',
  /** sort on KITFactor descending */
  KitfactorDesc = '_KITFACTOR_DESC_',
  /** Sort on KITDontShow ascending */
  KitdontshowAsc = '_KITDONTSHOW_ASC_',
  /** sort on KITDontShow descending */
  KitdontshowDesc = '_KITDONTSHOW_DESC_',
  /** Sort on KITMotherLineNo ascending */
  KitmotherlinenoAsc = '_KITMOTHERLINENO_ASC_',
  /** sort on KITMotherLineNo descending */
  KitmotherlinenoDesc = '_KITMOTHERLINENO_DESC_',
  /** Sort on KITFactorPrice ascending */
  KitfactorpriceAsc = '_KITFACTORPRICE_ASC_',
  /** sort on KITFactorPrice descending */
  KitfactorpriceDesc = '_KITFACTORPRICE_DESC_',
  /** Sort on KITOriginalPrice ascending */
  KitoriginalpriceAsc = '_KITORIGINALPRICE_ASC_',
  /** sort on KITOriginalPrice descending */
  KitoriginalpriceDesc = '_KITORIGINALPRICE_DESC_',
  /** Sort on KITQuantity ascending */
  KitquantityAsc = '_KITQUANTITY_ASC_',
  /** sort on KITQuantity descending */
  KitquantityDesc = '_KITQUANTITY_DESC_',
  /** Sort on KITLineAmountNet ascending */
  KitlineamountnetAsc = '_KITLINEAMOUNTNET_ASC_',
  /** sort on KITLineAmountNet descending */
  KitlineamountnetDesc = '_KITLINEAMOUNTNET_DESC_',
  /** Sort on KITLineAmountGross ascending */
  KitlineamountgrossAsc = '_KITLINEAMOUNTGROSS_ASC_',
  /** sort on KITLineAmountGross descending */
  KitlineamountgrossDesc = '_KITLINEAMOUNTGROSS_DESC_',
  /** Sort on KITOriginalSqMeter ascending */
  KitoriginalsqmeterAsc = '_KITORIGINALSQMETER_ASC_',
  /** sort on KITOriginalSqMeter descending */
  KitoriginalsqmeterDesc = '_KITORIGINALSQMETER_DESC_',
  /** Sort on SquareMeterperKIT ascending */
  SquaremeterperkitAsc = '_SQUAREMETERPERKIT_ASC_',
  /** sort on SquareMeterperKIT descending */
  SquaremeterperkitDesc = '_SQUAREMETERPERKIT_DESC_',
  /** Sort on KITPriceperSqMeter ascending */
  KitpricepersqmeterAsc = '_KITPRICEPERSQMETER_ASC_',
  /** sort on KITPriceperSqMeter descending */
  KitpricepersqmeterDesc = '_KITPRICEPERSQMETER_DESC_',
  /** Sort on KITChange ascending */
  KitchangeAsc = '_KITCHANGE_ASC_',
  /** sort on KITChange descending */
  KitchangeDesc = '_KITCHANGE_DESC_',
  /** Sort on TotalKITPrice ascending */
  TotalkitpriceAsc = '_TOTALKITPRICE_ASC_',
  /** sort on TotalKITPrice descending */
  TotalkitpriceDesc = '_TOTALKITPRICE_DESC_',
  /** Sort on DefaultKITPrice ascending */
  DefaultkitpriceAsc = '_DEFAULTKITPRICE_ASC_',
  /** sort on DefaultKITPrice descending */
  DefaultkitpriceDesc = '_DEFAULTKITPRICE_DESC_',
  /** Sort on DefaultKITPriceMarked ascending */
  DefaultkitpricemarkedAsc = '_DEFAULTKITPRICEMARKED_ASC_',
  /** sort on DefaultKITPriceMarked descending */
  DefaultkitpricemarkedDesc = '_DEFAULTKITPRICEMARKED_DESC_',
  /** Sort on DefaultKITFactorPrice ascending */
  DefaultkitfactorpriceAsc = '_DEFAULTKITFACTORPRICE_ASC_',
  /** sort on DefaultKITFactorPrice descending */
  DefaultkitfactorpriceDesc = '_DEFAULTKITFACTORPRICE_DESC_',
  /** Sort on KITComponentUnitPrice ascending */
  KitcomponentunitpriceAsc = '_KITCOMPONENTUNITPRICE_ASC_',
  /** sort on KITComponentUnitPrice descending */
  KitcomponentunitpriceDesc = '_KITCOMPONENTUNITPRICE_DESC_',
  /** Sort on KITComponentRentalPrice ascending */
  KitcomponentrentalpriceAsc = '_KITCOMPONENTRENTALPRICE_ASC_',
  /** sort on KITComponentRentalPrice descending */
  KitcomponentrentalpriceDesc = '_KITCOMPONENTRENTALPRICE_DESC_',
  /** Sort on KITLineDiscount ascending */
  KitlinediscountAsc = '_KITLINEDISCOUNT_ASC_',
  /** sort on KITLineDiscount descending */
  KitlinediscountDesc = '_KITLINEDISCOUNT_DESC_',
  /** Sort on KITLineDiscountAmount ascending */
  KitlinediscountamountAsc = '_KITLINEDISCOUNTAMOUNT_ASC_',
  /** sort on KITLineDiscountAmount descending */
  KitlinediscountamountDesc = '_KITLINEDISCOUNTAMOUNT_DESC_',
  /** Sort on OldTmplMotherLineNo ascending */
  OldtmplmotherlinenoAsc = '_OLDTMPLMOTHERLINENO_ASC_',
  /** sort on OldTmplMotherLineNo descending */
  OldtmplmotherlinenoDesc = '_OLDTMPLMOTHERLINENO_DESC_',
  /** Sort on OnlyonFirstOrderInvoice ascending */
  OnlyonfirstorderinvoiceAsc = '_ONLYONFIRSTORDERINVOICE_ASC_',
  /** sort on OnlyonFirstOrderInvoice descending */
  OnlyonfirstorderinvoiceDesc = '_ONLYONFIRSTORDERINVOICE_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on ReturnDateBuffer ascending */
  ReturndatebufferAsc = '_RETURNDATEBUFFER_ASC_',
  /** sort on ReturnDateBuffer descending */
  ReturndatebufferDesc = '_RETURNDATEBUFFER_DESC_',
  /** Sort on OffRentDateBuffer ascending */
  OffrentdatebufferAsc = '_OFFRENTDATEBUFFER_ASC_',
  /** sort on OffRentDateBuffer descending */
  OffrentdatebufferDesc = '_OFFRENTDATEBUFFER_DESC_',
  /** Sort on ReturnQuantityBuffer ascending */
  ReturnquantitybufferAsc = '_RETURNQUANTITYBUFFER_ASC_',
  /** sort on ReturnQuantityBuffer descending */
  ReturnquantitybufferDesc = '_RETURNQUANTITYBUFFER_DESC_',
  /** Sort on OffRentQuantityBuffer ascending */
  OffrentquantitybufferAsc = '_OFFRENTQUANTITYBUFFER_ASC_',
  /** sort on OffRentQuantityBuffer descending */
  OffrentquantitybufferDesc = '_OFFRENTQUANTITYBUFFER_DESC_',
  /** Sort on DebitonReturnDateBuffer ascending */
  DebitonreturndatebufferAsc = '_DEBITONRETURNDATEBUFFER_ASC_',
  /** sort on DebitonReturnDateBuffer descending */
  DebitonreturndatebufferDesc = '_DEBITONRETURNDATEBUFFER_DESC_',
  /** Sort on CounteronReturnBuffer ascending */
  CounteronreturnbufferAsc = '_COUNTERONRETURNBUFFER_ASC_',
  /** sort on CounteronReturnBuffer descending */
  CounteronreturnbufferDesc = '_COUNTERONRETURNBUFFER_DESC_',
  /** Sort on ReturnReminderBuffer ascending */
  ReturnreminderbufferAsc = '_RETURNREMINDERBUFFER_ASC_',
  /** sort on ReturnReminderBuffer descending */
  ReturnreminderbufferDesc = '_RETURNREMINDERBUFFER_DESC_',
  /** Sort on UpdateLineBuffer ascending */
  UpdatelinebufferAsc = '_UPDATELINEBUFFER_ASC_',
  /** sort on UpdateLineBuffer descending */
  UpdatelinebufferDesc = '_UPDATELINEBUFFER_DESC_',
  /** Sort on ReturnNoBuffer ascending */
  ReturnnobufferAsc = '_RETURNNOBUFFER_ASC_',
  /** sort on ReturnNoBuffer descending */
  ReturnnobufferDesc = '_RETURNNOBUFFER_DESC_',
  /** Sort on ReturnPoleSplitBuffer ascending */
  ReturnpolesplitbufferAsc = '_RETURNPOLESPLITBUFFER_ASC_',
  /** sort on ReturnPoleSplitBuffer descending */
  ReturnpolesplitbufferDesc = '_RETURNPOLESPLITBUFFER_DESC_',
  /** Sort on ReturnCompleteBuffer ascending */
  ReturncompletebufferAsc = '_RETURNCOMPLETEBUFFER_ASC_',
  /** sort on ReturnCompleteBuffer descending */
  ReturncompletebufferDesc = '_RETURNCOMPLETEBUFFER_DESC_',
  /** Sort on InvoicedToDateBuffer ascending */
  InvoicedtodatebufferAsc = '_INVOICEDTODATEBUFFER_ASC_',
  /** sort on InvoicedToDateBuffer descending */
  InvoicedtodatebufferDesc = '_INVOICEDTODATEBUFFER_DESC_',
  /** Sort on ReceiveLocationCodeBuffer ascending */
  ReceivelocationcodebufferAsc = '_RECEIVELOCATIONCODEBUFFER_ASC_',
  /** sort on ReceiveLocationCodeBuffer descending */
  ReceivelocationcodebufferDesc = '_RECEIVELOCATIONCODEBUFFER_DESC_',
  /** Sort on ReturnTimeBuffer ascending */
  ReturntimebufferAsc = '_RETURNTIMEBUFFER_ASC_',
  /** sort on ReturnTimeBuffer descending */
  ReturntimebufferDesc = '_RETURNTIMEBUFFER_DESC_',
  /** Sort on DepositAmountCardBuffer ascending */
  DepositamountcardbufferAsc = '_DEPOSITAMOUNTCARDBUFFER_ASC_',
  /** sort on DepositAmountCardBuffer descending */
  DepositamountcardbufferDesc = '_DEPOSITAMOUNTCARDBUFFER_DESC_',
  /** Sort on DepositAmountCashBuffer ascending */
  DepositamountcashbufferAsc = '_DEPOSITAMOUNTCASHBUFFER_ASC_',
  /** sort on DepositAmountCashBuffer descending */
  DepositamountcashbufferDesc = '_DEPOSITAMOUNTCASHBUFFER_DESC_',
  /** Sort on ReclassQuantityBuffer ascending */
  ReclassquantitybufferAsc = '_RECLASSQUANTITYBUFFER_ASC_',
  /** sort on ReclassQuantityBuffer descending */
  ReclassquantitybufferDesc = '_RECLASSQUANTITYBUFFER_DESC_',
  /** Sort on ReclassDateBuffer ascending */
  ReclassdatebufferAsc = '_RECLASSDATEBUFFER_ASC_',
  /** sort on ReclassDateBuffer descending */
  ReclassdatebufferDesc = '_RECLASSDATEBUFFER_DESC_',
  /** Sort on CorrFromDateBuffer ascending */
  CorrfromdatebufferAsc = '_CORRFROMDATEBUFFER_ASC_',
  /** sort on CorrFromDateBuffer descending */
  CorrfromdatebufferDesc = '_CORRFROMDATEBUFFER_DESC_',
  /** Sort on CorrInvToDateBuffer ascending */
  CorrinvtodatebufferAsc = '_CORRINVTODATEBUFFER_ASC_',
  /** sort on CorrInvToDateBuffer descending */
  CorrinvtodatebufferDesc = '_CORRINVTODATEBUFFER_DESC_',
  /** Sort on CorrReturnDateBuffer ascending */
  CorrreturndatebufferAsc = '_CORRRETURNDATEBUFFER_ASC_',
  /** sort on CorrReturnDateBuffer descending */
  CorrreturndatebufferDesc = '_CORRRETURNDATEBUFFER_DESC_',
  /** Sort on CorrCloseRentalBuffer ascending */
  CorrcloserentalbufferAsc = '_CORRCLOSERENTALBUFFER_ASC_',
  /** sort on CorrCloseRentalBuffer descending */
  CorrcloserentalbufferDesc = '_CORRCLOSERENTALBUFFER_DESC_',
  /** Sort on CorrCloseRentalBufferOPTION ascending */
  CorrcloserentalbufferoptionAsc = '_CORRCLOSERENTALBUFFEROPTION_ASC_',
  /** sort on CorrCloseRentalBufferOPTION descending */
  CorrcloserentalbufferoptionDesc = '_CORRCLOSERENTALBUFFEROPTION_DESC_',
  /** Sort on CorrUpdEntryBasisBuffer ascending */
  CorrupdentrybasisbufferAsc = '_CORRUPDENTRYBASISBUFFER_ASC_',
  /** sort on CorrUpdEntryBasisBuffer descending */
  CorrupdentrybasisbufferDesc = '_CORRUPDENTRYBASISBUFFER_DESC_',
  /** Sort on CorrQuantityBuffer ascending */
  CorrquantitybufferAsc = '_CORRQUANTITYBUFFER_ASC_',
  /** sort on CorrQuantityBuffer descending */
  CorrquantitybufferDesc = '_CORRQUANTITYBUFFER_DESC_',
  /** Sort on CorrReturnQuantityBuffer ascending */
  CorrreturnquantitybufferAsc = '_CORRRETURNQUANTITYBUFFER_ASC_',
  /** sort on CorrReturnQuantityBuffer descending */
  CorrreturnquantitybufferDesc = '_CORRRETURNQUANTITYBUFFER_DESC_',
  /** Sort on ReclassFromContractBuffer ascending */
  ReclassfromcontractbufferAsc = '_RECLASSFROMCONTRACTBUFFER_ASC_',
  /** sort on ReclassFromContractBuffer descending */
  ReclassfromcontractbufferDesc = '_RECLASSFROMCONTRACTBUFFER_DESC_',
  /** Sort on ReclassToContractBuffer ascending */
  ReclasstocontractbufferAsc = '_RECLASSTOCONTRACTBUFFER_ASC_',
  /** sort on ReclassToContractBuffer descending */
  ReclasstocontractbufferDesc = '_RECLASSTOCONTRACTBUFFER_DESC_',
  /** Sort on MaintenanceBuffer ascending */
  MaintenancebufferAsc = '_MAINTENANCEBUFFER_ASC_',
  /** sort on MaintenanceBuffer descending */
  MaintenancebufferDesc = '_MAINTENANCEBUFFER_DESC_',
  /** Sort on DepositAmountTnsfBuffer ascending */
  DepositamounttnsfbufferAsc = '_DEPOSITAMOUNTTNSFBUFFER_ASC_',
  /** sort on DepositAmountTnsfBuffer descending */
  DepositamounttnsfbufferDesc = '_DEPOSITAMOUNTTNSFBUFFER_DESC_',
  /** Sort on OffRentTimeBuffer ascending */
  OffrenttimebufferAsc = '_OFFRENTTIMEBUFFER_ASC_',
  /** sort on OffRentTimeBuffer descending */
  OffrenttimebufferDesc = '_OFFRENTTIMEBUFFER_DESC_',
  /** Sort on OffRentNoBuffer ascending */
  OffrentnobufferAsc = '_OFFRENTNOBUFFER_ASC_',
  /** sort on OffRentNoBuffer descending */
  OffrentnobufferDesc = '_OFFRENTNOBUFFER_DESC_',
  /** Sort on CorrQtyShippedtoBuffer ascending */
  CorrqtyshippedtobufferAsc = '_CORRQTYSHIPPEDTOBUFFER_ASC_',
  /** sort on CorrQtyShippedtoBuffer descending */
  CorrqtyshippedtobufferDesc = '_CORRQTYSHIPPEDTOBUFFER_DESC_',
  /** Sort on TempEntryBuffer ascending */
  TempentrybufferAsc = '_TEMPENTRYBUFFER_ASC_',
  /** sort on TempEntryBuffer descending */
  TempentrybufferDesc = '_TEMPENTRYBUFFER_DESC_',
  /** Sort on ReturntoSellBuffer ascending */
  ReturntosellbufferAsc = '_RETURNTOSELLBUFFER_ASC_',
  /** sort on ReturntoSellBuffer descending */
  ReturntosellbufferDesc = '_RETURNTOSELLBUFFER_DESC_',
  /** Sort on ReturntoDamageBuffer ascending */
  ReturntodamagebufferAsc = '_RETURNTODAMAGEBUFFER_ASC_',
  /** sort on ReturntoDamageBuffer descending */
  ReturntodamagebufferDesc = '_RETURNTODAMAGEBUFFER_DESC_',
  /** Sort on Counter2onReturnBuffer ascending */
  Counter2OnreturnbufferAsc = '_COUNTER2ONRETURNBUFFER_ASC_',
  /** sort on Counter2onReturnBuffer descending */
  Counter2OnreturnbufferDesc = '_COUNTER2ONRETURNBUFFER_DESC_',
  /** Sort on CorrOnRentDateBuffer ascending */
  CorronrentdatebufferAsc = '_CORRONRENTDATEBUFFER_ASC_',
  /** sort on CorrOnRentDateBuffer descending */
  CorronrentdatebufferDesc = '_CORRONRENTDATEBUFFER_DESC_',
  /** Sort on CorrNextInvDateBuffer ascending */
  CorrnextinvdatebufferAsc = '_CORRNEXTINVDATEBUFFER_ASC_',
  /** sort on CorrNextInvDateBuffer descending */
  CorrnextinvdatebufferDesc = '_CORRNEXTINVDATEBUFFER_DESC_',
  /** Sort on CollectionDocNoBuffer ascending */
  CollectiondocnobufferAsc = '_COLLECTIONDOCNOBUFFER_ASC_',
  /** sort on CollectionDocNoBuffer descending */
  CollectiondocnobufferDesc = '_COLLECTIONDOCNOBUFFER_DESC_',
  /** Sort on CollectionLineNoBuffer ascending */
  CollectionlinenobufferAsc = '_COLLECTIONLINENOBUFFER_ASC_',
  /** sort on CollectionLineNoBuffer descending */
  CollectionlinenobufferDesc = '_COLLECTIONLINENOBUFFER_DESC_',
  /** Sort on SourceCodeBuffer ascending */
  SourcecodebufferAsc = '_SOURCECODEBUFFER_ASC_',
  /** sort on SourceCodeBuffer descending */
  SourcecodebufferDesc = '_SOURCECODEBUFFER_DESC_',
  /** Sort on DeliverQuantityNowBuffer ascending */
  DeliverquantitynowbufferAsc = '_DELIVERQUANTITYNOWBUFFER_ASC_',
  /** sort on DeliverQuantityNowBuffer descending */
  DeliverquantitynowbufferDesc = '_DELIVERQUANTITYNOWBUFFER_DESC_',
  /** Sort on ShipmentDateBuffer ascending */
  ShipmentdatebufferAsc = '_SHIPMENTDATEBUFFER_ASC_',
  /** sort on ShipmentDateBuffer descending */
  ShipmentdatebufferDesc = '_SHIPMENTDATEBUFFER_DESC_',
  /** Sort on OnRentDateBuffer ascending */
  OnrentdatebufferAsc = '_ONRENTDATEBUFFER_ASC_',
  /** sort on OnRentDateBuffer descending */
  OnrentdatebufferDesc = '_ONRENTDATEBUFFER_DESC_',
  /** Sort on LocationCodeBuffer ascending */
  LocationcodebufferAsc = '_LOCATIONCODEBUFFER_ASC_',
  /** sort on LocationCodeBuffer descending */
  LocationcodebufferDesc = '_LOCATIONCODEBUFFER_DESC_',
  /** Sort on ConsumptionatReturnBuffer ascending */
  ConsumptionatreturnbufferAsc = '_CONSUMPTIONATRETURNBUFFER_ASC_',
  /** sort on ConsumptionatReturnBuffer descending */
  ConsumptionatreturnbufferDesc = '_CONSUMPTIONATRETURNBUFFER_DESC_',
  /** Sort on CreditonEarlyBuffer ascending */
  CreditonearlybufferAsc = '_CREDITONEARLYBUFFER_ASC_',
  /** sort on CreditonEarlyBuffer descending */
  CreditonearlybufferDesc = '_CREDITONEARLYBUFFER_DESC_',
  /** Sort on StartRentalPeriodBuffer ascending */
  StartrentalperiodbufferAsc = '_STARTRENTALPERIODBUFFER_ASC_',
  /** sort on StartRentalPeriodBuffer descending */
  StartrentalperiodbufferDesc = '_STARTRENTALPERIODBUFFER_DESC_',
  /** Sort on LineNoBuffer ascending */
  LinenobufferAsc = '_LINENOBUFFER_ASC_',
  /** sort on LineNoBuffer descending */
  LinenobufferDesc = '_LINENOBUFFER_DESC_',
  /** Sort on ConsumpatDeliveryBuffer ascending */
  ConsumpatdeliverybufferAsc = '_CONSUMPATDELIVERYBUFFER_ASC_',
  /** sort on ConsumpatDeliveryBuffer descending */
  ConsumpatdeliverybufferDesc = '_CONSUMPATDELIVERYBUFFER_DESC_',
  /** Sort on DepositAmountBackBuffer ascending */
  DepositamountbackbufferAsc = '_DEPOSITAMOUNTBACKBUFFER_ASC_',
  /** sort on DepositAmountBackBuffer descending */
  DepositamountbackbufferDesc = '_DEPOSITAMOUNTBACKBUFFER_DESC_',
  /** Sort on DispatchTypeBuffer ascending */
  DispatchtypebufferAsc = '_DISPATCHTYPEBUFFER_ASC_',
  /** sort on DispatchTypeBuffer descending */
  DispatchtypebufferDesc = '_DISPATCHTYPEBUFFER_DESC_',
  /** Sort on DispatchTypeBufferOPTION ascending */
  DispatchtypebufferoptionAsc = '_DISPATCHTYPEBUFFEROPTION_ASC_',
  /** sort on DispatchTypeBufferOPTION descending */
  DispatchtypebufferoptionDesc = '_DISPATCHTYPEBUFFEROPTION_DESC_',
  /** Sort on PstDispatchDocNoBuffer ascending */
  PstdispatchdocnobufferAsc = '_PSTDISPATCHDOCNOBUFFER_ASC_',
  /** sort on PstDispatchDocNoBuffer descending */
  PstdispatchdocnobufferDesc = '_PSTDISPATCHDOCNOBUFFER_DESC_',
  /** Sort on PstDispatchLineNoBuffer ascending */
  PstdispatchlinenobufferAsc = '_PSTDISPATCHLINENOBUFFER_ASC_',
  /** sort on PstDispatchLineNoBuffer descending */
  PstdispatchlinenobufferDesc = '_PSTDISPATCHLINENOBUFFER_DESC_',
  /** Sort on PostedInDocumentBuffer ascending */
  PostedindocumentbufferAsc = '_POSTEDINDOCUMENTBUFFER_ASC_',
  /** sort on PostedInDocumentBuffer descending */
  PostedindocumentbufferDesc = '_POSTEDINDOCUMENTBUFFER_DESC_',
  /** Sort on EQMServiceDocBuffer ascending */
  EqmservicedocbufferAsc = '_EQMSERVICEDOCBUFFER_ASC_',
  /** sort on EQMServiceDocBuffer descending */
  EqmservicedocbufferDesc = '_EQMSERVICEDOCBUFFER_DESC_',
  /** Sort on UnitPriceBuffer ascending */
  UnitpricebufferAsc = '_UNITPRICEBUFFER_ASC_',
  /** sort on UnitPriceBuffer descending */
  UnitpricebufferDesc = '_UNITPRICEBUFFER_DESC_',
  /** Sort on LineDiscountBuffer ascending */
  LinediscountbufferAsc = '_LINEDISCOUNTBUFFER_ASC_',
  /** sort on LineDiscountBuffer descending */
  LinediscountbufferDesc = '_LINEDISCOUNTBUFFER_DESC_',
  /** Sort on LineMarkedBuffer ascending */
  LinemarkedbufferAsc = '_LINEMARKEDBUFFER_ASC_',
  /** sort on LineMarkedBuffer descending */
  LinemarkedbufferDesc = '_LINEMARKEDBUFFER_DESC_',
  /** Sort on PostingDateBuffer ascending */
  PostingdatebufferAsc = '_POSTINGDATEBUFFER_ASC_',
  /** sort on PostingDateBuffer descending */
  PostingdatebufferDesc = '_POSTINGDATEBUFFER_DESC_',
  /** Sort on UseInOpenPeriodBuffer ascending */
  UseinopenperiodbufferAsc = '_USEINOPENPERIODBUFFER_ASC_',
  /** sort on UseInOpenPeriodBuffer descending */
  UseinopenperiodbufferDesc = '_USEINOPENPERIODBUFFER_DESC_',
  /** Sort on AttachedtoLineNoBuffer ascending */
  AttachedtolinenobufferAsc = '_ATTACHEDTOLINENOBUFFER_ASC_',
  /** sort on AttachedtoLineNoBuffer descending */
  AttachedtolinenobufferDesc = '_ATTACHEDTOLINENOBUFFER_DESC_',
  /** Sort on CounteronShipmentBuffer ascending */
  CounteronshipmentbufferAsc = '_COUNTERONSHIPMENTBUFFER_ASC_',
  /** sort on CounteronShipmentBuffer descending */
  CounteronshipmentbufferDesc = '_COUNTERONSHIPMENTBUFFER_DESC_',
  /** Sort on Counter2onShipmentBuffer ascending */
  Counter2OnshipmentbufferAsc = '_COUNTER2ONSHIPMENTBUFFER_ASC_',
  /** sort on Counter2onShipmentBuffer descending */
  Counter2OnshipmentbufferDesc = '_COUNTER2ONSHIPMENTBUFFER_DESC_',
  /** Sort on NoofRentFreeDaysBuffer ascending */
  NoofrentfreedaysbufferAsc = '_NOOFRENTFREEDAYSBUFFER_ASC_',
  /** sort on NoofRentFreeDaysBuffer descending */
  NoofrentfreedaysbufferDesc = '_NOOFRENTFREEDAYSBUFFER_DESC_',
  /** Sort on NoofRentFreeHourBuffer ascending */
  NoofrentfreehourbufferAsc = '_NOOFRENTFREEHOURBUFFER_ASC_',
  /** sort on NoofRentFreeHourBuffer descending */
  NoofrentfreehourbufferDesc = '_NOOFRENTFREEHOURBUFFER_DESC_',
  /** Sort on ReasonforRentFreeBuffer ascending */
  ReasonforrentfreebufferAsc = '_REASONFORRENTFREEBUFFER_ASC_',
  /** sort on ReasonforRentFreeBuffer descending */
  ReasonforrentfreebufferDesc = '_REASONFORRENTFREEBUFFER_DESC_',
  /** Sort on ReceiveRespCenterBuffer ascending */
  ReceiverespcenterbufferAsc = '_RECEIVERESPCENTERBUFFER_ASC_',
  /** sort on ReceiveRespCenterBuffer descending */
  ReceiverespcenterbufferDesc = '_RECEIVERESPCENTERBUFFER_DESC_',
  /** Sort on FullyConsumedAccBuffer ascending */
  FullyconsumedaccbufferAsc = '_FULLYCONSUMEDACCBUFFER_ASC_',
  /** sort on FullyConsumedAccBuffer descending */
  FullyconsumedaccbufferDesc = '_FULLYCONSUMEDACCBUFFER_DESC_',
  /** Sort on ReceivingBinCodeBuffer ascending */
  ReceivingbincodebufferAsc = '_RECEIVINGBINCODEBUFFER_ASC_',
  /** sort on ReceivingBinCodeBuffer descending */
  ReceivingbincodebufferDesc = '_RECEIVINGBINCODEBUFFER_DESC_',
  /** Sort on SuspendLineNoBuffer ascending */
  SuspendlinenobufferAsc = '_SUSPENDLINENOBUFFER_ASC_',
  /** sort on SuspendLineNoBuffer descending */
  SuspendlinenobufferDesc = '_SUSPENDLINENOBUFFER_DESC_',
  /** Sort on UnitPriceNew ascending */
  UnitpricenewAsc = '_UNITPRICENEW_ASC_',
  /** sort on UnitPriceNew descending */
  UnitpricenewDesc = '_UNITPRICENEW_DESC_',
  /** Sort on PriceTermCodeNew ascending */
  PricetermcodenewAsc = '_PRICETERMCODENEW_ASC_',
  /** sort on PriceTermCodeNew descending */
  PricetermcodenewDesc = '_PRICETERMCODENEW_DESC_',
  /** Sort on UnitPriceprDayNew ascending */
  UnitpriceprdaynewAsc = '_UNITPRICEPRDAYNEW_ASC_',
  /** sort on UnitPriceprDayNew descending */
  UnitpriceprdaynewDesc = '_UNITPRICEPRDAYNEW_DESC_',
  /** Sort on UnitPriceprMonthNew ascending */
  UnitpriceprmonthnewAsc = '_UNITPRICEPRMONTHNEW_ASC_',
  /** sort on UnitPriceprMonthNew descending */
  UnitpriceprmonthnewDesc = '_UNITPRICEPRMONTHNEW_DESC_',
  /** Sort on LineAmountNew ascending */
  LineamountnewAsc = '_LINEAMOUNTNEW_ASC_',
  /** sort on LineAmountNew descending */
  LineamountnewDesc = '_LINEAMOUNTNEW_DESC_',
  /** Sort on RentalPeriodNew ascending */
  RentalperiodnewAsc = '_RENTALPERIODNEW_ASC_',
  /** sort on RentalPeriodNew descending */
  RentalperiodnewDesc = '_RENTALPERIODNEW_DESC_',
  /** Sort on LineDiscountNew ascending */
  LinediscountnewAsc = '_LINEDISCOUNTNEW_ASC_',
  /** sort on LineDiscountNew descending */
  LinediscountnewDesc = '_LINEDISCOUNTNEW_DESC_',
  /** Sort on LineDiscountAmountNew ascending */
  LinediscountamountnewAsc = '_LINEDISCOUNTAMOUNTNEW_ASC_',
  /** sort on LineDiscountAmountNew descending */
  LinediscountamountnewDesc = '_LINEDISCOUNTAMOUNTNEW_DESC_',
  /** Sort on StairPriceCodeNew ascending */
  StairpricecodenewAsc = '_STAIRPRICECODENEW_ASC_',
  /** sort on StairPriceCodeNew descending */
  StairpricecodenewDesc = '_STAIRPRICECODENEW_DESC_',
  /** Sort on StairDiscountCodeNew ascending */
  StairdiscountcodenewAsc = '_STAIRDISCOUNTCODENEW_ASC_',
  /** sort on StairDiscountCodeNew descending */
  StairdiscountcodenewDesc = '_STAIRDISCOUNTCODENEW_DESC_',
  /** Sort on BaseCalendarCodeNew ascending */
  BasecalendarcodenewAsc = '_BASECALENDARCODENEW_ASC_',
  /** sort on BaseCalendarCodeNew descending */
  BasecalendarcodenewDesc = '_BASECALENDARCODENEW_DESC_',
  /** Sort on StairPrice1New ascending */
  Stairprice1NewAsc = '_STAIRPRICE1NEW_ASC_',
  /** sort on StairPrice1New descending */
  Stairprice1NewDesc = '_STAIRPRICE1NEW_DESC_',
  /** Sort on StairPrice2New ascending */
  Stairprice2NewAsc = '_STAIRPRICE2NEW_ASC_',
  /** sort on StairPrice2New descending */
  Stairprice2NewDesc = '_STAIRPRICE2NEW_DESC_',
  /** Sort on StairPrice3New ascending */
  Stairprice3NewAsc = '_STAIRPRICE3NEW_ASC_',
  /** sort on StairPrice3New descending */
  Stairprice3NewDesc = '_STAIRPRICE3NEW_DESC_',
  /** Sort on StairPrice4New ascending */
  Stairprice4NewAsc = '_STAIRPRICE4NEW_ASC_',
  /** sort on StairPrice4New descending */
  Stairprice4NewDesc = '_STAIRPRICE4NEW_DESC_',
  /** Sort on StairPrice5New ascending */
  Stairprice5NewAsc = '_STAIRPRICE5NEW_ASC_',
  /** sort on StairPrice5New descending */
  Stairprice5NewDesc = '_STAIRPRICE5NEW_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on Preliminary ascending */
  PreliminaryAsc = '_PRELIMINARY_ASC_',
  /** sort on Preliminary descending */
  PreliminaryDesc = '_PRELIMINARY_DESC_',
  /** Sort on ReferenceTableID ascending */
  ReferencetableidAsc = '_REFERENCETABLEID_ASC_',
  /** sort on ReferenceTableID descending */
  ReferencetableidDesc = '_REFERENCETABLEID_DESC_',
  /** Sort on ReferenceUniqueID ascending */
  ReferenceuniqueidAsc = '_REFERENCEUNIQUEID_ASC_',
  /** sort on ReferenceUniqueID descending */
  ReferenceuniqueidDesc = '_REFERENCEUNIQUEID_DESC_',
  /** Sort on UniqueID ascending */
  UniqueidAsc = '_UNIQUEID_ASC_',
  /** sort on UniqueID descending */
  UniqueidDesc = '_UNIQUEID_DESC_',
  /** Sort on CreatedTime ascending */
  CreatedtimeAsc = '_CREATEDTIME_ASC_',
  /** sort on CreatedTime descending */
  CreatedtimeDesc = '_CREATEDTIME_DESC_',
  /** Sort on ReplacementUnitPrice ascending */
  ReplacementunitpriceAsc = '_REPLACEMENTUNITPRICE_ASC_',
  /** sort on ReplacementUnitPrice descending */
  ReplacementunitpriceDesc = '_REPLACEMENTUNITPRICE_DESC_',
  /** Sort on ReRentObject ascending */
  RerentobjectAsc = '_RERENTOBJECT_ASC_',
  /** sort on ReRentObject descending */
  RerentobjectDesc = '_RERENTOBJECT_DESC_',
  /** Sort on ReRentCost ascending */
  RerentcostAsc = '_RERENTCOST_ASC_',
  /** sort on ReRentCost descending */
  RerentcostDesc = '_RERENTCOST_DESC_',
  /** Sort on UnitPriceprDay ascending */
  UnitpriceprdayAsc = '_UNITPRICEPRDAY_ASC_',
  /** sort on UnitPriceprDay descending */
  UnitpriceprdayDesc = '_UNITPRICEPRDAY_DESC_',
  /** Sort on UnitPriceprMonth ascending */
  UnitpriceprmonthAsc = '_UNITPRICEPRMONTH_ASC_',
  /** sort on UnitPriceprMonth descending */
  UnitpriceprmonthDesc = '_UNITPRICEPRMONTH_DESC_',
  /** Sort on AllocateObjectNo ascending */
  AllocateobjectnoAsc = '_ALLOCATEOBJECTNO_ASC_',
  /** sort on AllocateObjectNo descending */
  AllocateobjectnoDesc = '_ALLOCATEOBJECTNO_DESC_',
  /** Sort on DepositOrigUnitPrice ascending */
  DepositorigunitpriceAsc = '_DEPOSITORIGUNITPRICE_ASC_',
  /** sort on DepositOrigUnitPrice descending */
  DepositorigunitpriceDesc = '_DEPOSITORIGUNITPRICE_DESC_',
  /** Sort on MarkDepositEntry ascending */
  MarkdepositentryAsc = '_MARKDEPOSITENTRY_ASC_',
  /** sort on MarkDepositEntry descending */
  MarkdepositentryDesc = '_MARKDEPOSITENTRY_DESC_',
  /** Sort on DepositUnitPrice ascending */
  DepositunitpriceAsc = '_DEPOSITUNITPRICE_ASC_',
  /** sort on DepositUnitPrice descending */
  DepositunitpriceDesc = '_DEPOSITUNITPRICE_DESC_',
  /** Sort on DepositPostingNo ascending */
  DepositpostingnoAsc = '_DEPOSITPOSTINGNO_ASC_',
  /** sort on DepositPostingNo descending */
  DepositpostingnoDesc = '_DEPOSITPOSTINGNO_DESC_',
  /** Sort on DepositAmount ascending */
  DepositamountAsc = '_DEPOSITAMOUNT_ASC_',
  /** sort on DepositAmount descending */
  DepositamountDesc = '_DEPOSITAMOUNT_DESC_',
  /** Sort on ReplacementPrice ascending */
  ReplacementpriceAsc = '_REPLACEMENTPRICE_ASC_',
  /** sort on ReplacementPrice descending */
  ReplacementpriceDesc = '_REPLACEMENTPRICE_DESC_',
  /** Sort on DepositReturnPostingNo ascending */
  DepositreturnpostingnoAsc = '_DEPOSITRETURNPOSTINGNO_ASC_',
  /** sort on DepositReturnPostingNo descending */
  DepositreturnpostingnoDesc = '_DEPOSITRETURNPOSTINGNO_DESC_',
  /** Sort on AgreedToDate ascending */
  AgreedtodateAsc = '_AGREEDTODATE_ASC_',
  /** sort on AgreedToDate descending */
  AgreedtodateDesc = '_AGREEDTODATE_DESC_',
  /** Sort on DepositPostingType ascending */
  DepositpostingtypeAsc = '_DEPOSITPOSTINGTYPE_ASC_',
  /** sort on DepositPostingType descending */
  DepositpostingtypeDesc = '_DEPOSITPOSTINGTYPE_DESC_',
  /** Sort on DepositPostingTypeOPTION ascending */
  DepositpostingtypeoptionAsc = '_DEPOSITPOSTINGTYPEOPTION_ASC_',
  /** sort on DepositPostingTypeOPTION descending */
  DepositpostingtypeoptionDesc = '_DEPOSITPOSTINGTYPEOPTION_DESC_',
  /** Sort on DontTransfertoOrder ascending */
  DonttransfertoorderAsc = '_DONTTRANSFERTOORDER_ASC_',
  /** sort on DontTransfertoOrder descending */
  DonttransfertoorderDesc = '_DONTTRANSFERTOORDER_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on DeliveredBy ascending */
  DeliveredbyAsc = '_DELIVEREDBY_ASC_',
  /** sort on DeliveredBy descending */
  DeliveredbyDesc = '_DELIVEREDBY_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on SaleDocumentNo ascending */
  SaledocumentnoAsc = '_SALEDOCUMENTNO_ASC_',
  /** sort on SaleDocumentNo descending */
  SaledocumentnoDesc = '_SALEDOCUMENTNO_DESC_',
  /** Sort on SaleDocumentType ascending */
  SaledocumenttypeAsc = '_SALEDOCUMENTTYPE_ASC_',
  /** sort on SaleDocumentType descending */
  SaledocumenttypeDesc = '_SALEDOCUMENTTYPE_DESC_',
  /** Sort on SaleDocumentTypeOPTION ascending */
  SaledocumenttypeoptionAsc = '_SALEDOCUMENTTYPEOPTION_ASC_',
  /** sort on SaleDocumentTypeOPTION descending */
  SaledocumenttypeoptionDesc = '_SALEDOCUMENTTYPEOPTION_DESC_',
  /** Sort on SaleLineNo ascending */
  SalelinenoAsc = '_SALELINENO_ASC_',
  /** sort on SaleLineNo descending */
  SalelinenoDesc = '_SALELINENO_DESC_',
  /** Sort on SaleReserve ascending */
  SalereserveAsc = '_SALERESERVE_ASC_',
  /** sort on SaleReserve descending */
  SalereserveDesc = '_SALERESERVE_DESC_',
  /** Sort on SaleReserveOPTION ascending */
  SalereserveoptionAsc = '_SALERESERVEOPTION_ASC_',
  /** sort on SaleReserveOPTION descending */
  SalereserveoptionDesc = '_SALERESERVEOPTION_DESC_',
  /** Sort on ReturnQuantityHours ascending */
  ReturnquantityhoursAsc = '_RETURNQUANTITYHOURS_ASC_',
  /** sort on ReturnQuantityHours descending */
  ReturnquantityhoursDesc = '_RETURNQUANTITYHOURS_DESC_',
  /** Sort on ShipmentDateTime ascending */
  ShipmentdatetimeAsc = '_SHIPMENTDATETIME_ASC_',
  /** sort on ShipmentDateTime descending */
  ShipmentdatetimeDesc = '_SHIPMENTDATETIME_DESC_',
  /** Sort on EndRentalDateTime ascending */
  EndrentaldatetimeAsc = '_ENDRENTALDATETIME_ASC_',
  /** sort on EndRentalDateTime descending */
  EndrentaldatetimeDesc = '_ENDRENTALDATETIME_DESC_',
  /** Sort on ExpectedReturnDate ascending */
  ExpectedreturndateAsc = '_EXPECTEDRETURNDATE_ASC_',
  /** sort on ExpectedReturnDate descending */
  ExpectedreturndateDesc = '_EXPECTEDRETURNDATE_DESC_',
  /** Sort on DoShipmentNow ascending */
  DoshipmentnowAsc = '_DOSHIPMENTNOW_ASC_',
  /** sort on DoShipmentNow descending */
  DoshipmentnowDesc = '_DOSHIPMENTNOW_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on QtyHours ascending */
  QtyhoursAsc = '_QTYHOURS_ASC_',
  /** sort on QtyHours descending */
  QtyhoursDesc = '_QTYHOURS_DESC_',
  /** Sort on OnRentTime ascending */
  OnrenttimeAsc = '_ONRENTTIME_ASC_',
  /** sort on OnRentTime descending */
  OnrenttimeDesc = '_ONRENTTIME_DESC_',
  /** Sort on OffRentTime ascending */
  OffrenttimeAsc = '_OFFRENTTIME_ASC_',
  /** sort on OffRentTime descending */
  OffrenttimeDesc = '_OFFRENTTIME_DESC_',
  /** Sort on OnRentDateTime ascending */
  OnrentdatetimeAsc = '_ONRENTDATETIME_ASC_',
  /** sort on OnRentDateTime descending */
  OnrentdatetimeDesc = '_ONRENTDATETIME_DESC_',
  /** Sort on OffRentDateTime ascending */
  OffrentdatetimeAsc = '_OFFRENTDATETIME_ASC_',
  /** sort on OffRentDateTime descending */
  OffrentdatetimeDesc = '_OFFRENTDATETIME_DESC_',
  /** Sort on ReturnQuantityChargeHours ascending */
  ReturnquantitychargehoursAsc = '_RETURNQUANTITYCHARGEHOURS_ASC_',
  /** sort on ReturnQuantityChargeHours descending */
  ReturnquantitychargehoursDesc = '_RETURNQUANTITYCHARGEHOURS_DESC_',
  /** Sort on TerminPeriod ascending */
  TerminperiodAsc = '_TERMINPERIOD_ASC_',
  /** sort on TerminPeriod descending */
  TerminperiodDesc = '_TERMINPERIOD_DESC_',
  /** Sort on LineAmountPeriod ascending */
  LineamountperiodAsc = '_LINEAMOUNTPERIOD_ASC_',
  /** sort on LineAmountPeriod descending */
  LineamountperiodDesc = '_LINEAMOUNTPERIOD_DESC_',
  /** Sort on IgnorCalendarCheck ascending */
  IgnorcalendarcheckAsc = '_IGNORCALENDARCHECK_ASC_',
  /** sort on IgnorCalendarCheck descending */
  IgnorcalendarcheckDesc = '_IGNORCALENDARCHECK_DESC_',
  /** Sort on MeasurementatDelivery ascending */
  MeasurementatdeliveryAsc = '_MEASUREMENTATDELIVERY_ASC_',
  /** sort on MeasurementatDelivery descending */
  MeasurementatdeliveryDesc = '_MEASUREMENTATDELIVERY_DESC_',
  /** Sort on MeasurementatReturn ascending */
  MeasurementatreturnAsc = '_MEASUREMENTATRETURN_ASC_',
  /** sort on MeasurementatReturn descending */
  MeasurementatreturnDesc = '_MEASUREMENTATRETURN_DESC_',
  /** Sort on DiamondMotherLineNo ascending */
  DiamondmotherlinenoAsc = '_DIAMONDMOTHERLINENO_ASC_',
  /** sort on DiamondMotherLineNo descending */
  DiamondmotherlinenoDesc = '_DIAMONDMOTHERLINENO_DESC_',
  /** Sort on DiamondBladeMotherRef ascending */
  DiamondblademotherrefAsc = '_DIAMONDBLADEMOTHERREF_ASC_',
  /** sort on DiamondBladeMotherRef descending */
  DiamondblademotherrefDesc = '_DIAMONDBLADEMOTHERREF_DESC_',
  /** Sort on ReturnAdditionMotherRef ascending */
  ReturnadditionmotherrefAsc = '_RETURNADDITIONMOTHERREF_ASC_',
  /** sort on ReturnAdditionMotherRef descending */
  ReturnadditionmotherrefDesc = '_RETURNADDITIONMOTHERREF_DESC_',
  /** Sort on StairPriceCode ascending */
  StairpricecodeAsc = '_STAIRPRICECODE_ASC_',
  /** sort on StairPriceCode descending */
  StairpricecodeDesc = '_STAIRPRICECODE_DESC_',
  /** Sort on ExtraHoursReturnTime ascending */
  ExtrahoursreturntimeAsc = '_EXTRAHOURSRETURNTIME_ASC_',
  /** sort on ExtraHoursReturnTime descending */
  ExtrahoursreturntimeDesc = '_EXTRAHOURSRETURNTIME_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on TmpAccumEntry ascending */
  TmpaccumentryAsc = '_TMPACCUMENTRY_ASC_',
  /** sort on TmpAccumEntry descending */
  TmpaccumentryDesc = '_TMPACCUMENTRY_DESC_',
  /** Sort on MarkReclassLineNoRef ascending */
  MarkreclasslinenorefAsc = '_MARKRECLASSLINENOREF_ASC_',
  /** sort on MarkReclassLineNoRef descending */
  MarkreclasslinenorefDesc = '_MARKRECLASSLINENOREF_DESC_',
  /** Sort on MarkReclassQuantity ascending */
  MarkreclassquantityAsc = '_MARKRECLASSQUANTITY_ASC_',
  /** sort on MarkReclassQuantity descending */
  MarkreclassquantityDesc = '_MARKRECLASSQUANTITY_DESC_',
  /** Sort on PostingPriority ascending */
  PostingpriorityAsc = '_POSTINGPRIORITY_ASC_',
  /** sort on PostingPriority descending */
  PostingpriorityDesc = '_POSTINGPRIORITY_DESC_',
  /** Sort on PostingPriorityOPTION ascending */
  PostingpriorityoptionAsc = '_POSTINGPRIORITYOPTION_ASC_',
  /** sort on PostingPriorityOPTION descending */
  PostingpriorityoptionDesc = '_POSTINGPRIORITYOPTION_DESC_',
  /** Sort on ReclassFromLocationCode ascending */
  ReclassfromlocationcodeAsc = '_RECLASSFROMLOCATIONCODE_ASC_',
  /** sort on ReclassFromLocationCode descending */
  ReclassfromlocationcodeDesc = '_RECLASSFROMLOCATIONCODE_DESC_',
  /** Sort on ReclassOrderedBy ascending */
  ReclassorderedbyAsc = '_RECLASSORDEREDBY_ASC_',
  /** sort on ReclassOrderedBy descending */
  ReclassorderedbyDesc = '_RECLASSORDEREDBY_DESC_',
  /** Sort on MarkReclassLine ascending */
  MarkreclasslineAsc = '_MARKRECLASSLINE_ASC_',
  /** sort on MarkReclassLine descending */
  MarkreclasslineDesc = '_MARKRECLASSLINE_DESC_',
  /** Sort on FilterReclassLine ascending */
  FilterreclasslineAsc = '_FILTERRECLASSLINE_ASC_',
  /** sort on FilterReclassLine descending */
  FilterreclasslineDesc = '_FILTERRECLASSLINE_DESC_',
  /** Sort on ReclassFromContractNo ascending */
  ReclassfromcontractnoAsc = '_RECLASSFROMCONTRACTNO_ASC_',
  /** sort on ReclassFromContractNo descending */
  ReclassfromcontractnoDesc = '_RECLASSFROMCONTRACTNO_DESC_',
  /** Sort on ReclassToContractNo ascending */
  ReclasstocontractnoAsc = '_RECLASSTOCONTRACTNO_ASC_',
  /** sort on ReclassToContractNo descending */
  ReclasstocontractnoDesc = '_RECLASSTOCONTRACTNO_DESC_',
  /** Sort on ReclassToLocationCode ascending */
  ReclasstolocationcodeAsc = '_RECLASSTOLOCATIONCODE_ASC_',
  /** sort on ReclassToLocationCode descending */
  ReclasstolocationcodeDesc = '_RECLASSTOLOCATIONCODE_DESC_',
  /** Sort on ReclassOrderNo ascending */
  ReclassordernoAsc = '_RECLASSORDERNO_ASC_',
  /** sort on ReclassOrderNo descending */
  ReclassordernoDesc = '_RECLASSORDERNO_DESC_',
  /** Sort on ReclassDocNo ascending */
  ReclassdocnoAsc = '_RECLASSDOCNO_ASC_',
  /** sort on ReclassDocNo descending */
  ReclassdocnoDesc = '_RECLASSDOCNO_DESC_',
  /** Sort on WorkshopObjectNo ascending */
  WorkshopobjectnoAsc = '_WORKSHOPOBJECTNO_ASC_',
  /** sort on WorkshopObjectNo descending */
  WorkshopobjectnoDesc = '_WORKSHOPOBJECTNO_DESC_',
  /** Sort on DiamondBladeItem ascending */
  DiamondbladeitemAsc = '_DIAMONDBLADEITEM_ASC_',
  /** sort on DiamondBladeItem descending */
  DiamondbladeitemDesc = '_DIAMONDBLADEITEM_DESC_',
  /** Sort on DiamondBladeItemOPTION ascending */
  DiamondbladeitemoptionAsc = '_DIAMONDBLADEITEMOPTION_ASC_',
  /** sort on DiamondBladeItemOPTION descending */
  DiamondbladeitemoptionDesc = '_DIAMONDBLADEITEMOPTION_DESC_',
  /** Sort on IgnoreVaryingCompQty ascending */
  IgnorevaryingcompqtyAsc = '_IGNOREVARYINGCOMPQTY_ASC_',
  /** sort on IgnoreVaryingCompQty descending */
  IgnorevaryingcompqtyDesc = '_IGNOREVARYINGCOMPQTY_DESC_',
  /** Sort on ExploreLine ascending */
  ExplorelineAsc = '_EXPLORELINE_ASC_',
  /** sort on ExploreLine descending */
  ExplorelineDesc = '_EXPLORELINE_DESC_',
  /** Sort on EnvironmentalFee ascending */
  EnvironmentalfeeAsc = '_ENVIRONMENTALFEE_ASC_',
  /** sort on EnvironmentalFee descending */
  EnvironmentalfeeDesc = '_ENVIRONMENTALFEE_DESC_',
  /** Sort on ExtraHoursCharged ascending */
  ExtrahourschargedAsc = '_EXTRAHOURSCHARGED_ASC_',
  /** sort on ExtraHoursCharged descending */
  ExtrahourschargedDesc = '_EXTRAHOURSCHARGED_DESC_',
  /** Sort on ExtraHoursReturnNo ascending */
  ExtrahoursreturnnoAsc = '_EXTRAHOURSRETURNNO_ASC_',
  /** sort on ExtraHoursReturnNo descending */
  ExtrahoursreturnnoDesc = '_EXTRAHOURSRETURNNO_DESC_',
  /** Sort on PriceonExtraHours ascending */
  PriceonextrahoursAsc = '_PRICEONEXTRAHOURS_ASC_',
  /** sort on PriceonExtraHours descending */
  PriceonextrahoursDesc = '_PRICEONEXTRAHOURS_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on ReclassDocLineNo ascending */
  ReclassdoclinenoAsc = '_RECLASSDOCLINENO_ASC_',
  /** sort on ReclassDocLineNo descending */
  ReclassdoclinenoDesc = '_RECLASSDOCLINENO_DESC_',
  /** Sort on RentalSplitCostCenter ascending */
  RentalsplitcostcenterAsc = '_RENTALSPLITCOSTCENTER_ASC_',
  /** sort on RentalSplitCostCenter descending */
  RentalsplitcostcenterDesc = '_RENTALSPLITCOSTCENTER_DESC_',
  /** Sort on RentalSplit ascending */
  RentalsplitAsc = '_RENTALSPLIT_ASC_',
  /** sort on RentalSplit descending */
  RentalsplitDesc = '_RENTALSPLIT_DESC_',
  /** Sort on PricePriority ascending */
  PricepriorityAsc = '_PRICEPRIORITY_ASC_',
  /** sort on PricePriority descending */
  PricepriorityDesc = '_PRICEPRIORITY_DESC_',
  /** Sort on PricePriorityOPTION ascending */
  PricepriorityoptionAsc = '_PRICEPRIORITYOPTION_ASC_',
  /** sort on PricePriorityOPTION descending */
  PricepriorityoptionDesc = '_PRICEPRIORITYOPTION_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on TotalNetWeight ascending */
  TotalnetweightAsc = '_TOTALNETWEIGHT_ASC_',
  /** sort on TotalNetWeight descending */
  TotalnetweightDesc = '_TOTALNETWEIGHT_DESC_',
  /** Sort on RecursiveLevel ascending */
  RecursivelevelAsc = '_RECURSIVELEVEL_ASC_',
  /** sort on RecursiveLevel descending */
  RecursivelevelDesc = '_RECURSIVELEVEL_DESC_',
  /** Sort on Repurchase ascending */
  RepurchaseAsc = '_REPURCHASE_ASC_',
  /** sort on Repurchase descending */
  RepurchaseDesc = '_REPURCHASE_DESC_',
  /** Sort on PriceScheduleCode ascending */
  PriceschedulecodeAsc = '_PRICESCHEDULECODE_ASC_',
  /** sort on PriceScheduleCode descending */
  PriceschedulecodeDesc = '_PRICESCHEDULECODE_DESC_',
  /** Sort on CPNextInvoiceAmount ascending */
  CpnextinvoiceamountAsc = '_CPNEXTINVOICEAMOUNT_ASC_',
  /** sort on CPNextInvoiceAmount descending */
  CpnextinvoiceamountDesc = '_CPNEXTINVOICEAMOUNT_DESC_',
  /** Sort on CPAdvancePaymentStatus ascending */
  CpadvancepaymentstatusAsc = '_CPADVANCEPAYMENTSTATUS_ASC_',
  /** sort on CPAdvancePaymentStatus descending */
  CpadvancepaymentstatusDesc = '_CPADVANCEPAYMENTSTATUS_DESC_',
  /** Sort on CPAdvancePaymentStatusOPTION ascending */
  CpadvancepaymentstatusoptionAsc = '_CPADVANCEPAYMENTSTATUSOPTION_ASC_',
  /** sort on CPAdvancePaymentStatusOPTION descending */
  CpadvancepaymentstatusoptionDesc = '_CPADVANCEPAYMENTSTATUSOPTION_DESC_',
  /** Sort on CPAdvancePaymentDocNo ascending */
  CpadvancepaymentdocnoAsc = '_CPADVANCEPAYMENTDOCNO_ASC_',
  /** sort on CPAdvancePaymentDocNo descending */
  CpadvancepaymentdocnoDesc = '_CPADVANCEPAYMENTDOCNO_DESC_',
  /** Sort on InvoiceFee ascending */
  InvoicefeeAsc = '_INVOICEFEE_ASC_',
  /** sort on InvoiceFee descending */
  InvoicefeeDesc = '_INVOICEFEE_DESC_',
  /** Sort on StairDiscountCode ascending */
  StairdiscountcodeAsc = '_STAIRDISCOUNTCODE_ASC_',
  /** sort on StairDiscountCode descending */
  StairdiscountcodeDesc = '_STAIRDISCOUNTCODE_DESC_',
  /** Sort on StairPriceDiscount ascending */
  StairpricediscountAsc = '_STAIRPRICEDISCOUNT_ASC_',
  /** sort on StairPriceDiscount descending */
  StairpricediscountDesc = '_STAIRPRICEDISCOUNT_DESC_',
  /** Sort on ReceiveRespCenter ascending */
  ReceiverespcenterAsc = '_RECEIVERESPCENTER_ASC_',
  /** sort on ReceiveRespCenter descending */
  ReceiverespcenterDesc = '_RECEIVERESPCENTER_DESC_',
  /** Sort on ReceiveCostCenter ascending */
  ReceivecostcenterAsc = '_RECEIVECOSTCENTER_ASC_',
  /** sort on ReceiveCostCenter descending */
  ReceivecostcenterDesc = '_RECEIVECOSTCENTER_DESC_',
  /** Sort on AllowLineDisconExHours ascending */
  AllowlinedisconexhoursAsc = '_ALLOWLINEDISCONEXHOURS_ASC_',
  /** sort on AllowLineDisconExHours descending */
  AllowlinedisconexhoursDesc = '_ALLOWLINEDISCONEXHOURS_DESC_',
  /** Sort on QuantitytoAllocate ascending */
  QuantitytoallocateAsc = '_QUANTITYTOALLOCATE_ASC_',
  /** sort on QuantitytoAllocate descending */
  QuantitytoallocateDesc = '_QUANTITYTOALLOCATE_DESC_',
  /** Sort on ServiceMotherLineNo ascending */
  ServicemotherlinenoAsc = '_SERVICEMOTHERLINENO_ASC_',
  /** sort on ServiceMotherLineNo descending */
  ServicemotherlinenoDesc = '_SERVICEMOTHERLINENO_DESC_',
  /** Sort on AdditionalCharge ascending */
  AdditionalchargeAsc = '_ADDITIONALCHARGE_ASC_',
  /** sort on AdditionalCharge descending */
  AdditionalchargeDesc = '_ADDITIONALCHARGE_DESC_',
  /** Sort on AdjforAdditionalCharge ascending */
  AdjforadditionalchargeAsc = '_ADJFORADDITIONALCHARGE_ASC_',
  /** sort on AdjforAdditionalCharge descending */
  AdjforadditionalchargeDesc = '_ADJFORADDITIONALCHARGE_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on EQMServiceNoTemp ascending */
  EqmservicenotempAsc = '_EQMSERVICENOTEMP_ASC_',
  /** sort on EQMServiceNoTemp descending */
  EqmservicenotempDesc = '_EQMSERVICENOTEMP_DESC_',
  /** Sort on PriceReportedHours ascending */
  PricereportedhoursAsc = '_PRICEREPORTEDHOURS_ASC_',
  /** sort on PriceReportedHours descending */
  PricereportedhoursDesc = '_PRICEREPORTEDHOURS_DESC_',
  /** Sort on FreeRentalHours ascending */
  FreerentalhoursAsc = '_FREERENTALHOURS_ASC_',
  /** sort on FreeRentalHours descending */
  FreerentalhoursDesc = '_FREERENTALHOURS_DESC_',
  /** Sort on DispatchTask ascending */
  DispatchtaskAsc = '_DISPATCHTASK_ASC_',
  /** sort on DispatchTask descending */
  DispatchtaskDesc = '_DISPATCHTASK_DESC_',
  /** Sort on DispatchTaskOPTION ascending */
  DispatchtaskoptionAsc = '_DISPATCHTASKOPTION_ASC_',
  /** sort on DispatchTaskOPTION descending */
  DispatchtaskoptionDesc = '_DISPATCHTASKOPTION_DESC_',
  /** Sort on DispatchStatus ascending */
  DispatchstatusAsc = '_DISPATCHSTATUS_ASC_',
  /** sort on DispatchStatus descending */
  DispatchstatusDesc = '_DISPATCHSTATUS_DESC_',
  /** Sort on DispatchStatusOPTION ascending */
  DispatchstatusoptionAsc = '_DISPATCHSTATUSOPTION_ASC_',
  /** sort on DispatchStatusOPTION descending */
  DispatchstatusoptionDesc = '_DISPATCHSTATUSOPTION_DESC_',
  /** Sort on ScheduledDeliveryDate ascending */
  ScheduleddeliverydateAsc = '_SCHEDULEDDELIVERYDATE_ASC_',
  /** sort on ScheduledDeliveryDate descending */
  ScheduleddeliverydateDesc = '_SCHEDULEDDELIVERYDATE_DESC_',
  /** Sort on ScheduledDeliveryTime ascending */
  ScheduleddeliverytimeAsc = '_SCHEDULEDDELIVERYTIME_ASC_',
  /** sort on ScheduledDeliveryTime descending */
  ScheduleddeliverytimeDesc = '_SCHEDULEDDELIVERYTIME_DESC_',
  /** Sort on ScheduledPickupDate ascending */
  ScheduledpickupdateAsc = '_SCHEDULEDPICKUPDATE_ASC_',
  /** sort on ScheduledPickupDate descending */
  ScheduledpickupdateDesc = '_SCHEDULEDPICKUPDATE_DESC_',
  /** Sort on ScheduledPickupTime ascending */
  ScheduledpickuptimeAsc = '_SCHEDULEDPICKUPTIME_ASC_',
  /** sort on ScheduledPickupTime descending */
  ScheduledpickuptimeDesc = '_SCHEDULEDPICKUPTIME_DESC_',
  /** Sort on DeliveryDriver ascending */
  DeliverydriverAsc = '_DELIVERYDRIVER_ASC_',
  /** sort on DeliveryDriver descending */
  DeliverydriverDesc = '_DELIVERYDRIVER_DESC_',
  /** Sort on PickupDriver ascending */
  PickupdriverAsc = '_PICKUPDRIVER_ASC_',
  /** sort on PickupDriver descending */
  PickupdriverDesc = '_PICKUPDRIVER_DESC_',
  /** Sort on Driver ascending */
  DriverAsc = '_DRIVER_ASC_',
  /** sort on Driver descending */
  DriverDesc = '_DRIVER_DESC_',
  /** Sort on SchedDate ascending */
  ScheddateAsc = '_SCHEDDATE_ASC_',
  /** sort on SchedDate descending */
  ScheddateDesc = '_SCHEDDATE_DESC_',
  /** Sort on ActualDate ascending */
  ActualdateAsc = '_ACTUALDATE_ASC_',
  /** sort on ActualDate descending */
  ActualdateDesc = '_ACTUALDATE_DESC_',
  /** Sort on SchedTime ascending */
  SchedtimeAsc = '_SCHEDTIME_ASC_',
  /** sort on SchedTime descending */
  SchedtimeDesc = '_SCHEDTIME_DESC_',
  /** Sort on ActualTime ascending */
  ActualtimeAsc = '_ACTUALTIME_ASC_',
  /** sort on ActualTime descending */
  ActualtimeDesc = '_ACTUALTIME_DESC_',
  /** Sort on ActualDeliveryDate ascending */
  ActualdeliverydateAsc = '_ACTUALDELIVERYDATE_ASC_',
  /** sort on ActualDeliveryDate descending */
  ActualdeliverydateDesc = '_ACTUALDELIVERYDATE_DESC_',
  /** Sort on ActualDeliveryTime ascending */
  ActualdeliverytimeAsc = '_ACTUALDELIVERYTIME_ASC_',
  /** sort on ActualDeliveryTime descending */
  ActualdeliverytimeDesc = '_ACTUALDELIVERYTIME_DESC_',
  /** Sort on ActualPickupDate ascending */
  ActualpickupdateAsc = '_ACTUALPICKUPDATE_ASC_',
  /** sort on ActualPickupDate descending */
  ActualpickupdateDesc = '_ACTUALPICKUPDATE_DESC_',
  /** Sort on ActualPickupTime ascending */
  ActualpickuptimeAsc = '_ACTUALPICKUPTIME_ASC_',
  /** sort on ActualPickupTime descending */
  ActualpickuptimeDesc = '_ACTUALPICKUPTIME_DESC_',
  /** Sort on ActualReturnDate ascending */
  ActualreturndateAsc = '_ACTUALRETURNDATE_ASC_',
  /** sort on ActualReturnDate descending */
  ActualreturndateDesc = '_ACTUALRETURNDATE_DESC_',
  /** Sort on ActualReturnTime ascending */
  ActualreturntimeAsc = '_ACTUALRETURNTIME_ASC_',
  /** sort on ActualReturnTime descending */
  ActualreturntimeDesc = '_ACTUALRETURNTIME_DESC_',
  /** Sort on MinimumReportedHours ascending */
  MinimumreportedhoursAsc = '_MINIMUMREPORTEDHOURS_ASC_',
  /** sort on MinimumReportedHours descending */
  MinimumreportedhoursDesc = '_MINIMUMREPORTEDHOURS_DESC_',
  /** Sort on MinRepHoursPeriod ascending */
  MinrephoursperiodAsc = '_MINREPHOURSPERIOD_ASC_',
  /** sort on MinRepHoursPeriod descending */
  MinrephoursperiodDesc = '_MINREPHOURSPERIOD_DESC_',
  /** Sort on StairPrice1 ascending */
  Stairprice1Asc = '_STAIRPRICE1_ASC_',
  /** sort on StairPrice1 descending */
  Stairprice1Desc = '_STAIRPRICE1_DESC_',
  /** Sort on StairPrice2 ascending */
  Stairprice2Asc = '_STAIRPRICE2_ASC_',
  /** sort on StairPrice2 descending */
  Stairprice2Desc = '_STAIRPRICE2_DESC_',
  /** Sort on StairPrice3 ascending */
  Stairprice3Asc = '_STAIRPRICE3_ASC_',
  /** sort on StairPrice3 descending */
  Stairprice3Desc = '_STAIRPRICE3_DESC_',
  /** Sort on DispatchDocType ascending */
  DispatchdoctypeAsc = '_DISPATCHDOCTYPE_ASC_',
  /** sort on DispatchDocType descending */
  DispatchdoctypeDesc = '_DISPATCHDOCTYPE_DESC_',
  /** Sort on DispatchDocTypeOPTION ascending */
  DispatchdoctypeoptionAsc = '_DISPATCHDOCTYPEOPTION_ASC_',
  /** sort on DispatchDocTypeOPTION descending */
  DispatchdoctypeoptionDesc = '_DISPATCHDOCTYPEOPTION_DESC_',
  /** Sort on DispatchDocNo ascending */
  DispatchdocnoAsc = '_DISPATCHDOCNO_ASC_',
  /** sort on DispatchDocNo descending */
  DispatchdocnoDesc = '_DISPATCHDOCNO_DESC_',
  /** Sort on DispatchLineNo ascending */
  DispatchlinenoAsc = '_DISPATCHLINENO_ASC_',
  /** sort on DispatchLineNo descending */
  DispatchlinenoDesc = '_DISPATCHLINENO_DESC_',
  /** Sort on StairPrice4 ascending */
  Stairprice4Asc = '_STAIRPRICE4_ASC_',
  /** sort on StairPrice4 descending */
  Stairprice4Desc = '_STAIRPRICE4_DESC_',
  /** Sort on StairPrice5 ascending */
  Stairprice5Asc = '_STAIRPRICE5_ASC_',
  /** sort on StairPrice5 descending */
  Stairprice5Desc = '_STAIRPRICE5_DESC_',
  /** Sort on MinimumRentalPeriod ascending */
  MinimumrentalperiodAsc = '_MINIMUMRENTALPERIOD_ASC_',
  /** sort on MinimumRentalPeriod descending */
  MinimumrentalperiodDesc = '_MINIMUMRENTALPERIOD_DESC_',
  /** Sort on MinimumRentaltoDate ascending */
  MinimumrentaltodateAsc = '_MINIMUMRENTALTODATE_ASC_',
  /** sort on MinimumRentaltoDate descending */
  MinimumrentaltodateDesc = '_MINIMUMRENTALTODATE_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on UserFilter ascending */
  UserfilterAsc = '_USERFILTER_ASC_',
  /** sort on UserFilter descending */
  UserfilterDesc = '_USERFILTER_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DefaultKITPriceCompFlow ascending */
  DefaultkitpricecompflowAsc = '_DEFAULTKITPRICECOMPFLOW_ASC_',
  /** sort on DefaultKITPriceCompFlow descending */
  DefaultkitpricecompflowDesc = '_DEFAULTKITPRICECOMPFLOW_DESC_',
  /** Sort on AssignedtoKIT ascending */
  AssignedtokitAsc = '_ASSIGNEDTOKIT_ASC_',
  /** sort on AssignedtoKIT descending */
  AssignedtokitDesc = '_ASSIGNEDTOKIT_DESC_',
  /** Sort on OffRentDateFlow ascending */
  OffrentdateflowAsc = '_OFFRENTDATEFLOW_ASC_',
  /** sort on OffRentDateFlow descending */
  OffrentdateflowDesc = '_OFFRENTDATEFLOW_DESC_',
  /** Sort on ReturnDateFlow ascending */
  ReturndateflowAsc = '_RETURNDATEFLOW_ASC_',
  /** sort on ReturnDateFlow descending */
  ReturndateflowDesc = '_RETURNDATEFLOW_DESC_',
  /** Sort on QuantityShippedtoFlow ascending */
  QuantityshippedtoflowAsc = '_QUANTITYSHIPPEDTOFLOW_ASC_',
  /** sort on QuantityShippedtoFlow descending */
  QuantityshippedtoflowDesc = '_QUANTITYSHIPPEDTOFLOW_DESC_',
  /** Sort on QuantityReturnedFlow ascending */
  QuantityreturnedflowAsc = '_QUANTITYRETURNEDFLOW_ASC_',
  /** sort on QuantityReturnedFlow descending */
  QuantityreturnedflowDesc = '_QUANTITYRETURNEDFLOW_DESC_',
  /** Sort on QuantityOffRentedFlow ascending */
  QuantityoffrentedflowAsc = '_QUANTITYOFFRENTEDFLOW_ASC_',
  /** sort on QuantityOffRentedFlow descending */
  QuantityoffrentedflowDesc = '_QUANTITYOFFRENTEDFLOW_DESC_',
  /** Sort on QuantityPstOffRentedFlow ascending */
  QuantitypstoffrentedflowAsc = '_QUANTITYPSTOFFRENTEDFLOW_ASC_',
  /** sort on QuantityPstOffRentedFlow descending */
  QuantitypstoffrentedflowDesc = '_QUANTITYPSTOFFRENTEDFLOW_DESC_',
  /** Sort on QuantityPstReturnedFlow ascending */
  QuantitypstreturnedflowAsc = '_QUANTITYPSTRETURNEDFLOW_ASC_',
  /** sort on QuantityPstReturnedFlow descending */
  QuantitypstreturnedflowDesc = '_QUANTITYPSTRETURNEDFLOW_DESC_',
  /** Sort on QuantityPstShippedFlow ascending */
  QuantitypstshippedflowAsc = '_QUANTITYPSTSHIPPEDFLOW_ASC_',
  /** sort on QuantityPstShippedFlow descending */
  QuantitypstshippedflowDesc = '_QUANTITYPSTSHIPPEDFLOW_DESC_',
  /** Sort on QtyonPickOrder ascending */
  QtyonpickorderAsc = '_QTYONPICKORDER_ASC_',
  /** sort on QtyonPickOrder descending */
  QtyonpickorderDesc = '_QTYONPICKORDER_DESC_',
  /** Sort on QtyonShipmentOrder ascending */
  QtyonshipmentorderAsc = '_QTYONSHIPMENTORDER_ASC_',
  /** sort on QtyonShipmentOrder descending */
  QtyonshipmentorderDesc = '_QTYONSHIPMENTORDER_DESC_',
  /** Sort on QtyonCollectionOrder ascending */
  QtyoncollectionorderAsc = '_QTYONCOLLECTIONORDER_ASC_',
  /** sort on QtyonCollectionOrder descending */
  QtyoncollectionorderDesc = '_QTYONCOLLECTIONORDER_DESC_',
  /** Sort on UserLocationFilter ascending */
  UserlocationfilterAsc = '_USERLOCATIONFILTER_ASC_',
  /** sort on UserLocationFilter descending */
  UserlocationfilterDesc = '_USERLOCATIONFILTER_DESC_',
  /** Sort on NoofWorkOrderLines ascending */
  NoofworkorderlinesAsc = '_NOOFWORKORDERLINES_ASC_',
  /** sort on NoofWorkOrderLines descending */
  NoofworkorderlinesDesc = '_NOOFWORKORDERLINES_DESC_',
  /** Sort on NoofPickOrderLines ascending */
  NoofpickorderlinesAsc = '_NOOFPICKORDERLINES_ASC_',
  /** sort on NoofPickOrderLines descending */
  NoofpickorderlinesDesc = '_NOOFPICKORDERLINES_DESC_',
  /** Sort on NoofShipmentOrderLines ascending */
  NoofshipmentorderlinesAsc = '_NOOFSHIPMENTORDERLINES_ASC_',
  /** sort on NoofShipmentOrderLines descending */
  NoofshipmentorderlinesDesc = '_NOOFSHIPMENTORDERLINES_DESC_',
  /** Sort on NoofCollectionOrderLines ascending */
  NoofcollectionorderlinesAsc = '_NOOFCOLLECTIONORDERLINES_ASC_',
  /** sort on NoofCollectionOrderLines descending */
  NoofcollectionorderlinesDesc = '_NOOFCOLLECTIONORDERLINES_DESC_',
  /** Sort on NoofPostedPickOrderLines ascending */
  NoofpostedpickorderlinesAsc = '_NOOFPOSTEDPICKORDERLINES_ASC_',
  /** sort on NoofPostedPickOrderLines descending */
  NoofpostedpickorderlinesDesc = '_NOOFPOSTEDPICKORDERLINES_DESC_',
  /** Sort on NoofPstdShptOrderLines ascending */
  NoofpstdshptorderlinesAsc = '_NOOFPSTDSHPTORDERLINES_ASC_',
  /** sort on NoofPstdShptOrderLines descending */
  NoofpstdshptorderlinesDesc = '_NOOFPSTDSHPTORDERLINES_DESC_',
  /** Sort on NoofPstdCollOrderLines ascending */
  NoofpstdcollorderlinesAsc = '_NOOFPSTDCOLLORDERLINES_ASC_',
  /** sort on NoofPstdCollOrderLines descending */
  NoofpstdcollorderlinesDesc = '_NOOFPSTDCOLLORDERLINES_DESC_',
  /** Sort on NoofRntlReservSummaries ascending */
  NoofrntlreservsummariesAsc = '_NOOFRNTLRESERVSUMMARIES_ASC_',
  /** sort on NoofRntlReservSummaries descending */
  NoofrntlreservsummariesDesc = '_NOOFRNTLRESERVSUMMARIES_DESC_',
  /** Sort on NoofRntlReservEntries ascending */
  NoofrntlreserventriesAsc = '_NOOFRNTLRESERVENTRIES_ASC_',
  /** sort on NoofRntlReservEntries descending */
  NoofrntlreserventriesDesc = '_NOOFRNTLRESERVENTRIES_DESC_',
  /** Sort on QtyonRntlReservEntry ascending */
  QtyonrntlreserventryAsc = '_QTYONRNTLRESERVENTRY_ASC_',
  /** sort on QtyonRntlReservEntry descending */
  QtyonrntlreserventryDesc = '_QTYONRNTLRESERVENTRY_DESC_',
  /** Sort on QtyonRntlResShptEntry ascending */
  QtyonrntlresshptentryAsc = '_QTYONRNTLRESSHPTENTRY_ASC_',
  /** sort on QtyonRntlResShptEntry descending */
  QtyonrntlresshptentryDesc = '_QTYONRNTLRESSHPTENTRY_DESC_',
  /** Sort on QtyonRntlResRetEntry ascending */
  QtyonrntlresretentryAsc = '_QTYONRNTLRESRETENTRY_ASC_',
  /** sort on QtyonRntlResRetEntry descending */
  QtyonrntlresretentryDesc = '_QTYONRNTLRESRETENTRY_DESC_',
  /** Sort on QtyonBufferEntry ascending */
  QtyonbufferentryAsc = '_QTYONBUFFERENTRY_ASC_',
  /** sort on QtyonBufferEntry descending */
  QtyonbufferentryDesc = '_QTYONBUFFERENTRY_DESC_',
  /** Sort on MultipleRentalPeriodPrice ascending */
  MultiplerentalperiodpriceAsc = '_MULTIPLERENTALPERIODPRICE_ASC_',
  /** sort on MultipleRentalPeriodPrice descending */
  MultiplerentalperiodpriceDesc = '_MULTIPLERENTALPERIODPRICE_DESC_',
  /** Sort on MultipleRentalPeriodEntry ascending */
  MultiplerentalperiodentryAsc = '_MULTIPLERENTALPERIODENTRY_ASC_',
  /** sort on MultipleRentalPeriodEntry descending */
  MultiplerentalperiodentryDesc = '_MULTIPLERENTALPERIODENTRY_DESC_',
  /** Sort on LastErrorText ascending */
  LasterrortextAsc = '_LASTERRORTEXT_ASC_',
  /** sort on LastErrorText descending */
  LasterrortextDesc = '_LASTERRORTEXT_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on SaleReservedQuantity ascending */
  SalereservedquantityAsc = '_SALERESERVEDQUANTITY_ASC_',
  /** sort on SaleReservedQuantity descending */
  SalereservedquantityDesc = '_SALERESERVEDQUANTITY_DESC_',
  /** Sort on SaleReservedQtyBase ascending */
  SalereservedqtybaseAsc = '_SALERESERVEDQTYBASE_ASC_',
  /** sort on SaleReservedQtyBase descending */
  SalereservedqtybaseDesc = '_SALERESERVEDQTYBASE_DESC_',
  /** Sort on KITNetWeight ascending */
  KitnetweightAsc = '_KITNETWEIGHT_ASC_',
  /** sort on KITNetWeight descending */
  KitnetweightDesc = '_KITNETWEIGHT_DESC_',
  /** Sort on ReportedRentalHours ascending */
  ReportedrentalhoursAsc = '_REPORTEDRENTALHOURS_ASC_',
  /** sort on ReportedRentalHours descending */
  ReportedrentalhoursDesc = '_REPORTEDRENTALHOURS_DESC_',
  /** Sort on EQMServiceDocNo ascending */
  EqmservicedocnoAsc = '_EQMSERVICEDOCNO_ASC_',
  /** sort on EQMServiceDocNo descending */
  EqmservicedocnoDesc = '_EQMSERVICEDOCNO_DESC_',
  /** Sort on DispatchDocument ascending */
  DispatchdocumentAsc = '_DISPATCHDOCUMENT_ASC_',
  /** sort on DispatchDocument descending */
  DispatchdocumentDesc = '_DISPATCHDOCUMENT_DESC_',
  /** Sort on DispatchDocumentNo ascending */
  DispatchdocumentnoAsc = '_DISPATCHDOCUMENTNO_ASC_',
  /** sort on DispatchDocumentNo descending */
  DispatchdocumentnoDesc = '_DISPATCHDOCUMENTNO_DESC_',
  /** Sort on GroupOnNumber ascending */
  GrouponnumberAsc = '_GROUPONNUMBER_ASC_',
  /** sort on GroupOnNumber descending */
  GrouponnumberDesc = '_GROUPONNUMBER_DESC_',
  /** Sort on PeriodLineAmountEdge ascending */
  PeriodlineamountedgeAsc = '_PERIODLINEAMOUNTEDGE_ASC_',
  /** sort on PeriodLineAmountEdge descending */
  PeriodlineamountedgeDesc = '_PERIODLINEAMOUNTEDGE_DESC_',
  /** Sort on RentalDays ascending */
  RentaldaysAsc = '_RENTALDAYS_ASC_',
  /** sort on RentalDays descending */
  RentaldaysDesc = '_RENTALDAYS_DESC_',
  /** Sort on RemainingQty ascending */
  RemainingqtyAsc = '_REMAININGQTY_ASC_',
  /** sort on RemainingQty descending */
  RemainingqtyDesc = '_REMAININGQTY_DESC_',
  /** Sort on FreeDays ascending */
  FreedaysAsc = '_FREEDAYS_ASC_',
  /** sort on FreeDays descending */
  FreedaysDesc = '_FREEDAYS_DESC_',
  /** Sort on DayNetPriceEdge ascending */
  DaynetpriceedgeAsc = '_DAYNETPRICEEDGE_ASC_',
  /** sort on DayNetPriceEdge descending */
  DaynetpriceedgeDesc = '_DAYNETPRICEEDGE_DESC_',
  /** Sort on Vaccation ascending */
  VaccationAsc = '_VACCATION_ASC_',
  /** sort on Vaccation descending */
  VaccationDesc = '_VACCATION_DESC_',
  /** Sort on ReturnReservationActive ascending */
  ReturnreservationactiveAsc = '_RETURNRESERVATIONACTIVE_ASC_',
  /** sort on ReturnReservationActive descending */
  ReturnreservationactiveDesc = '_RETURNRESERVATIONACTIVE_DESC_',
  /** Sort on UserExpectedReturnDate ascending */
  UserexpectedreturndateAsc = '_USEREXPECTEDRETURNDATE_ASC_',
  /** sort on UserExpectedReturnDate descending */
  UserexpectedreturndateDesc = '_USEREXPECTEDRETURNDATE_DESC_',
  /** Sort on ImageUrl ascending */
  ImageurlAsc = '_IMAGEURL_ASC_',
  /** sort on ImageUrl descending */
  ImageurlDesc = '_IMAGEURL_DESC_',
  /** Sort on PSILink ascending */
  PsilinkAsc = '_PSILINK_ASC_',
  /** sort on PSILink descending */
  PsilinkDesc = '_PSILINK_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_',
  /** Sort on SnapshotOn ascending */
  SnapshotonAsc = '_SNAPSHOTON_ASC_',
  /** sort on SnapshotOn descending */
  SnapshotonDesc = '_SNAPSHOTON_DESC_'
}

export enum SortRentalPostedCollHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ResourceNo ascending */
  ResourcenoAsc = '_RESOURCENO_ASC_',
  /** sort on ResourceNo descending */
  ResourcenoDesc = '_RESOURCENO_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ShippingHour ascending */
  ShippinghourAsc = '_SHIPPINGHOUR_ASC_',
  /** sort on ShippingHour descending */
  ShippinghourDesc = '_SHIPPINGHOUR_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on PickDate ascending */
  PickdateAsc = '_PICKDATE_ASC_',
  /** sort on PickDate descending */
  PickdateDesc = '_PICKDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on OffRentDate ascending */
  OffrentdateAsc = '_OFFRENTDATE_ASC_',
  /** sort on OffRentDate descending */
  OffrentdateDesc = '_OFFRENTDATE_DESC_',
  /** Sort on ReturnDate ascending */
  ReturndateAsc = '_RETURNDATE_ASC_',
  /** sort on ReturnDate descending */
  ReturndateDesc = '_RETURNDATE_DESC_',
  /** Sort on CollectionStatus ascending */
  CollectionstatusAsc = '_COLLECTIONSTATUS_ASC_',
  /** sort on CollectionStatus descending */
  CollectionstatusDesc = '_COLLECTIONSTATUS_DESC_',
  /** Sort on CollectionStatusOPTION ascending */
  CollectionstatusoptionAsc = '_COLLECTIONSTATUSOPTION_ASC_',
  /** sort on CollectionStatusOPTION descending */
  CollectionstatusoptionDesc = '_COLLECTIONSTATUSOPTION_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on ReceivingLocationCode ascending */
  ReceivinglocationcodeAsc = '_RECEIVINGLOCATIONCODE_ASC_',
  /** sort on ReceivingLocationCode descending */
  ReceivinglocationcodeDesc = '_RECEIVINGLOCATIONCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderDocumentType ascending */
  OrderdocumenttypeAsc = '_ORDERDOCUMENTTYPE_ASC_',
  /** sort on OrderDocumentType descending */
  OrderdocumenttypeDesc = '_ORDERDOCUMENTTYPE_DESC_',
  /** Sort on OrderDocumentTypeOPTION ascending */
  OrderdocumenttypeoptionAsc = '_ORDERDOCUMENTTYPEOPTION_ASC_',
  /** sort on OrderDocumentTypeOPTION descending */
  OrderdocumenttypeoptionDesc = '_ORDERDOCUMENTTYPEOPTION_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on PurchaseOrderNo ascending */
  PurchaseordernoAsc = '_PURCHASEORDERNO_ASC_',
  /** sort on PurchaseOrderNo descending */
  PurchaseordernoDesc = '_PURCHASEORDERNO_DESC_',
  /** Sort on Requestedby ascending */
  RequestedbyAsc = '_REQUESTEDBY_ASC_',
  /** sort on Requestedby descending */
  RequestedbyDesc = '_REQUESTEDBY_DESC_',
  /** Sort on Receivedby ascending */
  ReceivedbyAsc = '_RECEIVEDBY_ASC_',
  /** sort on Receivedby descending */
  ReceivedbyDesc = '_RECEIVEDBY_DESC_',
  /** Sort on ResponsibilityCenterCode ascending */
  ResponsibilitycentercodeAsc = '_RESPONSIBILITYCENTERCODE_ASC_',
  /** sort on ResponsibilityCenterCode descending */
  ResponsibilitycentercodeDesc = '_RESPONSIBILITYCENTERCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on ShiptoPhoneNo ascending */
  ShiptophonenoAsc = '_SHIPTOPHONENO_ASC_',
  /** sort on ShiptoPhoneNo descending */
  ShiptophonenoDesc = '_SHIPTOPHONENO_DESC_',
  /** Sort on ShiptoMobilePhoneNo ascending */
  ShiptomobilephonenoAsc = '_SHIPTOMOBILEPHONENO_ASC_',
  /** sort on ShiptoMobilePhoneNo descending */
  ShiptomobilephonenoDesc = '_SHIPTOMOBILEPHONENO_DESC_',
  /** Sort on Broker ascending */
  BrokerAsc = '_BROKER_ASC_',
  /** sort on Broker descending */
  BrokerDesc = '_BROKER_DESC_',
  /** Sort on Carrier ascending */
  CarrierAsc = '_CARRIER_ASC_',
  /** sort on Carrier descending */
  CarrierDesc = '_CARRIER_DESC_',
  /** Sort on Driver ascending */
  DriverAsc = '_DRIVER_ASC_',
  /** sort on Driver descending */
  DriverDesc = '_DRIVER_DESC_',
  /** Sort on BOLSignature ascending */
  BolsignatureAsc = '_BOLSIGNATURE_ASC_',
  /** sort on BOLSignature descending */
  BolsignatureDesc = '_BOLSIGNATURE_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalPostedCollLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ExtRefNo ascending */
  ExtrefnoAsc = '_EXTREFNO_ASC_',
  /** sort on ExtRefNo descending */
  ExtrefnoDesc = '_EXTREFNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ResourceNo ascending */
  ResourcenoAsc = '_RESOURCENO_ASC_',
  /** sort on ResourceNo descending */
  ResourcenoDesc = '_RESOURCENO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on CustomerSubProject ascending */
  CustomersubprojectAsc = '_CUSTOMERSUBPROJECT_ASC_',
  /** sort on CustomerSubProject descending */
  CustomersubprojectDesc = '_CUSTOMERSUBPROJECT_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on PriceTerm ascending */
  PricetermAsc = '_PRICETERM_ASC_',
  /** sort on PriceTerm descending */
  PricetermDesc = '_PRICETERM_DESC_',
  /** Sort on BaseCalendar ascending */
  BasecalendarAsc = '_BASECALENDAR_ASC_',
  /** sort on BaseCalendar descending */
  BasecalendarDesc = '_BASECALENDAR_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on RentalPeriod ascending */
  RentalperiodAsc = '_RENTALPERIOD_ASC_',
  /** sort on RentalPeriod descending */
  RentalperiodDesc = '_RENTALPERIOD_DESC_',
  /** Sort on LoadSequenceNo ascending */
  LoadsequencenoAsc = '_LOADSEQUENCENO_ASC_',
  /** sort on LoadSequenceNo descending */
  LoadsequencenoDesc = '_LOADSEQUENCENO_DESC_',
  /** Sort on PriceStairNo ascending */
  PricestairnoAsc = '_PRICESTAIRNO_ASC_',
  /** sort on PriceStairNo descending */
  PricestairnoDesc = '_PRICESTAIRNO_DESC_',
  /** Sort on StairDiscountCode ascending */
  StairdiscountcodeAsc = '_STAIRDISCOUNTCODE_ASC_',
  /** sort on StairDiscountCode descending */
  StairdiscountcodeDesc = '_STAIRDISCOUNTCODE_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on PickDate ascending */
  PickdateAsc = '_PICKDATE_ASC_',
  /** sort on PickDate descending */
  PickdateDesc = '_PICKDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on OffRentDate ascending */
  OffrentdateAsc = '_OFFRENTDATE_ASC_',
  /** sort on OffRentDate descending */
  OffrentdateDesc = '_OFFRENTDATE_DESC_',
  /** Sort on ReturnDate ascending */
  ReturndateAsc = '_RETURNDATE_ASC_',
  /** sort on ReturnDate descending */
  ReturndateDesc = '_RETURNDATE_DESC_',
  /** Sort on OffRentUpdated ascending */
  OffrentupdatedAsc = '_OFFRENTUPDATED_ASC_',
  /** sort on OffRentUpdated descending */
  OffrentupdatedDesc = '_OFFRENTUPDATED_DESC_',
  /** Sort on ReturnTime ascending */
  ReturntimeAsc = '_RETURNTIME_ASC_',
  /** sort on ReturnTime descending */
  ReturntimeDesc = '_RETURNTIME_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on QuantitySold ascending */
  QuantitysoldAsc = '_QUANTITYSOLD_ASC_',
  /** sort on QuantitySold descending */
  QuantitysoldDesc = '_QUANTITYSOLD_DESC_',
  /** Sort on QuantityDamaged ascending */
  QuantitydamagedAsc = '_QUANTITYDAMAGED_ASC_',
  /** sort on QuantityDamaged descending */
  QuantitydamagedDesc = '_QUANTITYDAMAGED_DESC_',
  /** Sort on QuantityOffRented ascending */
  QuantityoffrentedAsc = '_QUANTITYOFFRENTED_ASC_',
  /** sort on QuantityOffRented descending */
  QuantityoffrentedDesc = '_QUANTITYOFFRENTED_DESC_',
  /** Sort on ReceivingLocationCode ascending */
  ReceivinglocationcodeAsc = '_RECEIVINGLOCATIONCODE_ASC_',
  /** sort on ReceivingLocationCode descending */
  ReceivinglocationcodeDesc = '_RECEIVINGLOCATIONCODE_DESC_',
  /** Sort on CounteronShipment ascending */
  CounteronshipmentAsc = '_COUNTERONSHIPMENT_ASC_',
  /** sort on CounteronShipment descending */
  CounteronshipmentDesc = '_COUNTERONSHIPMENT_DESC_',
  /** Sort on CounteronReturn ascending */
  CounteronreturnAsc = '_COUNTERONRETURN_ASC_',
  /** sort on CounteronReturn descending */
  CounteronreturnDesc = '_COUNTERONRETURN_DESC_',
  /** Sort on Maintenance ascending */
  MaintenanceAsc = '_MAINTENANCE_ASC_',
  /** sort on Maintenance descending */
  MaintenanceDesc = '_MAINTENANCE_DESC_',
  /** Sort on EQMServiceDoc ascending */
  EqmservicedocAsc = '_EQMSERVICEDOC_ASC_',
  /** sort on EQMServiceDoc descending */
  EqmservicedocDesc = '_EQMSERVICEDOC_DESC_',
  /** Sort on EQMServiceID ascending */
  EqmserviceidAsc = '_EQMSERVICEID_ASC_',
  /** sort on EQMServiceID descending */
  EqmserviceidDesc = '_EQMSERVICEID_DESC_',
  /** Sort on Counter2onShipment ascending */
  Counter2OnshipmentAsc = '_COUNTER2ONSHIPMENT_ASC_',
  /** sort on Counter2onShipment descending */
  Counter2OnshipmentDesc = '_COUNTER2ONSHIPMENT_DESC_',
  /** Sort on Counter2onReturn ascending */
  Counter2OnreturnAsc = '_COUNTER2ONRETURN_ASC_',
  /** sort on Counter2onReturn descending */
  Counter2OnreturnDesc = '_COUNTER2ONRETURN_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on MotherLineNo ascending */
  MotherlinenoAsc = '_MOTHERLINENO_ASC_',
  /** sort on MotherLineNo descending */
  MotherlinenoDesc = '_MOTHERLINENO_DESC_',
  /** Sort on DiamondBladeItem ascending */
  DiamondbladeitemAsc = '_DIAMONDBLADEITEM_ASC_',
  /** sort on DiamondBladeItem descending */
  DiamondbladeitemDesc = '_DIAMONDBLADEITEM_DESC_',
  /** Sort on DiamondBladeItemOPTION ascending */
  DiamondbladeitemoptionAsc = '_DIAMONDBLADEITEMOPTION_ASC_',
  /** sort on DiamondBladeItemOPTION descending */
  DiamondbladeitemoptionDesc = '_DIAMONDBLADEITEMOPTION_DESC_',
  /** Sort on MeasurementatDelivery ascending */
  MeasurementatdeliveryAsc = '_MEASUREMENTATDELIVERY_ASC_',
  /** sort on MeasurementatDelivery descending */
  MeasurementatdeliveryDesc = '_MEASUREMENTATDELIVERY_DESC_',
  /** Sort on MeasurementatReturn ascending */
  MeasurementatreturnAsc = '_MEASUREMENTATRETURN_ASC_',
  /** sort on MeasurementatReturn descending */
  MeasurementatreturnDesc = '_MEASUREMENTATRETURN_DESC_',
  /** Sort on FullyConsumedAccessory ascending */
  FullyconsumedaccessoryAsc = '_FULLYCONSUMEDACCESSORY_ASC_',
  /** sort on FullyConsumedAccessory descending */
  FullyconsumedaccessoryDesc = '_FULLYCONSUMEDACCESSORY_DESC_',
  /** Sort on ScannedBy ascending */
  ScannedbyAsc = '_SCANNEDBY_ASC_',
  /** sort on ScannedBy descending */
  ScannedbyDesc = '_SCANNEDBY_DESC_',
  /** Sort on ScannedDate ascending */
  ScanneddateAsc = '_SCANNEDDATE_ASC_',
  /** sort on ScannedDate descending */
  ScanneddateDesc = '_SCANNEDDATE_DESC_',
  /** Sort on ScannedTime ascending */
  ScannedtimeAsc = '_SCANNEDTIME_ASC_',
  /** sort on ScannedTime descending */
  ScannedtimeDesc = '_SCANNEDTIME_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on NumberofRentFreeDays ascending */
  NumberofrentfreedaysAsc = '_NUMBEROFRENTFREEDAYS_ASC_',
  /** sort on NumberofRentFreeDays descending */
  NumberofrentfreedaysDesc = '_NUMBEROFRENTFREEDAYS_DESC_',
  /** Sort on NumberofRentFreeHours ascending */
  NumberofrentfreehoursAsc = '_NUMBEROFRENTFREEHOURS_ASC_',
  /** sort on NumberofRentFreeHours descending */
  NumberofrentfreehoursDesc = '_NUMBEROFRENTFREEHOURS_DESC_',
  /** Sort on ReasonforRentFree ascending */
  ReasonforrentfreeAsc = '_REASONFORRENTFREE_ASC_',
  /** sort on ReasonforRentFree descending */
  ReasonforrentfreeDesc = '_REASONFORRENTFREE_DESC_',
  /** Sort on CreditonEarlyOffRent ascending */
  CreditonearlyoffrentAsc = '_CREDITONEARLYOFFRENT_ASC_',
  /** sort on CreditonEarlyOffRent descending */
  CreditonearlyoffrentDesc = '_CREDITONEARLYOFFRENT_DESC_',
  /** Sort on PriceonExtraHours ascending */
  PriceonextrahoursAsc = '_PRICEONEXTRAHOURS_ASC_',
  /** sort on PriceonExtraHours descending */
  PriceonextrahoursDesc = '_PRICEONEXTRAHOURS_DESC_',
  /** Sort on PstDeliveryEntryNo ascending */
  PstdeliveryentrynoAsc = '_PSTDELIVERYENTRYNO_ASC_',
  /** sort on PstDeliveryEntryNo descending */
  PstdeliveryentrynoDesc = '_PSTDELIVERYENTRYNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on ReceivingBinCode ascending */
  ReceivingbincodeAsc = '_RECEIVINGBINCODE_ASC_',
  /** sort on ReceivingBinCode descending */
  ReceivingbincodeDesc = '_RECEIVINGBINCODE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on AppliedfromDeliverEntryNo ascending */
  AppliedfromdeliverentrynoAsc = '_APPLIEDFROMDELIVERENTRYNO_ASC_',
  /** sort on AppliedfromDeliverEntryNo descending */
  AppliedfromdeliverentrynoDesc = '_APPLIEDFROMDELIVERENTRYNO_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on Level ascending */
  LevelAsc = '_LEVEL_ASC_',
  /** sort on Level descending */
  LevelDesc = '_LEVEL_DESC_',
  /** Sort on LineType ascending */
  LinetypeAsc = '_LINETYPE_ASC_',
  /** sort on LineType descending */
  LinetypeDesc = '_LINETYPE_DESC_',
  /** Sort on LineTypeOPTION ascending */
  LinetypeoptionAsc = '_LINETYPEOPTION_ASC_',
  /** sort on LineTypeOPTION descending */
  LinetypeoptionDesc = '_LINETYPEOPTION_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on PriceReportedHours ascending */
  PricereportedhoursAsc = '_PRICEREPORTEDHOURS_ASC_',
  /** sort on PriceReportedHours descending */
  PricereportedhoursDesc = '_PRICEREPORTEDHOURS_DESC_',
  /** Sort on FreeRentalHours ascending */
  FreerentalhoursAsc = '_FREERENTALHOURS_ASC_',
  /** sort on FreeRentalHours descending */
  FreerentalhoursDesc = '_FREERENTALHOURS_DESC_',
  /** Sort on MinimumReportedHours ascending */
  MinimumreportedhoursAsc = '_MINIMUMREPORTEDHOURS_ASC_',
  /** sort on MinimumReportedHours descending */
  MinimumreportedhoursDesc = '_MINIMUMREPORTEDHOURS_DESC_',
  /** Sort on MinRepHoursPeriod ascending */
  MinrephoursperiodAsc = '_MINREPHOURSPERIOD_ASC_',
  /** sort on MinRepHoursPeriod descending */
  MinrephoursperiodDesc = '_MINREPHOURSPERIOD_DESC_',
  /** Sort on StairPrice1 ascending */
  Stairprice1Asc = '_STAIRPRICE1_ASC_',
  /** sort on StairPrice1 descending */
  Stairprice1Desc = '_STAIRPRICE1_DESC_',
  /** Sort on StairPrice2 ascending */
  Stairprice2Asc = '_STAIRPRICE2_ASC_',
  /** sort on StairPrice2 descending */
  Stairprice2Desc = '_STAIRPRICE2_DESC_',
  /** Sort on StairPrice3 ascending */
  Stairprice3Asc = '_STAIRPRICE3_ASC_',
  /** sort on StairPrice3 descending */
  Stairprice3Desc = '_STAIRPRICE3_DESC_',
  /** Sort on StairPrice4 ascending */
  Stairprice4Asc = '_STAIRPRICE4_ASC_',
  /** sort on StairPrice4 descending */
  Stairprice4Desc = '_STAIRPRICE4_DESC_',
  /** Sort on StairPrice5 ascending */
  Stairprice5Asc = '_STAIRPRICE5_ASC_',
  /** sort on StairPrice5 descending */
  Stairprice5Desc = '_STAIRPRICE5_DESC_',
  /** Sort on MinimumRentalPeriod ascending */
  MinimumrentalperiodAsc = '_MINIMUMRENTALPERIOD_ASC_',
  /** sort on MinimumRentalPeriod descending */
  MinimumrentalperiodDesc = '_MINIMUMRENTALPERIOD_DESC_',
  /** Sort on MinimumRentaltoDate ascending */
  MinimumrentaltodateAsc = '_MINIMUMRENTALTODATE_ASC_',
  /** sort on MinimumRentaltoDate descending */
  MinimumrentaltodateDesc = '_MINIMUMRENTALTODATE_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalPostedShptHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ResourceNo ascending */
  ResourcenoAsc = '_RESOURCENO_ASC_',
  /** sort on ResourceNo descending */
  ResourcenoDesc = '_RESOURCENO_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on ShippingHour ascending */
  ShippinghourAsc = '_SHIPPINGHOUR_ASC_',
  /** sort on ShippingHour descending */
  ShippinghourDesc = '_SHIPPINGHOUR_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on PickDate ascending */
  PickdateAsc = '_PICKDATE_ASC_',
  /** sort on PickDate descending */
  PickdateDesc = '_PICKDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderDocumentType ascending */
  OrderdocumenttypeAsc = '_ORDERDOCUMENTTYPE_ASC_',
  /** sort on OrderDocumentType descending */
  OrderdocumenttypeDesc = '_ORDERDOCUMENTTYPE_DESC_',
  /** Sort on OrderDocumentTypeOPTION ascending */
  OrderdocumenttypeoptionAsc = '_ORDERDOCUMENTTYPEOPTION_ASC_',
  /** sort on OrderDocumentTypeOPTION descending */
  OrderdocumenttypeoptionDesc = '_ORDERDOCUMENTTYPEOPTION_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on PurchaseOrderNo ascending */
  PurchaseordernoAsc = '_PURCHASEORDERNO_ASC_',
  /** sort on PurchaseOrderNo descending */
  PurchaseordernoDesc = '_PURCHASEORDERNO_DESC_',
  /** Sort on ResponsibilityCenterCode ascending */
  ResponsibilitycentercodeAsc = '_RESPONSIBILITYCENTERCODE_ASC_',
  /** sort on ResponsibilityCenterCode descending */
  ResponsibilitycentercodeDesc = '_RESPONSIBILITYCENTERCODE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on ShiptoPhoneNo ascending */
  ShiptophonenoAsc = '_SHIPTOPHONENO_ASC_',
  /** sort on ShiptoPhoneNo descending */
  ShiptophonenoDesc = '_SHIPTOPHONENO_DESC_',
  /** Sort on ShiptoMobilePhoneNo ascending */
  ShiptomobilephonenoAsc = '_SHIPTOMOBILEPHONENO_ASC_',
  /** sort on ShiptoMobilePhoneNo descending */
  ShiptomobilephonenoDesc = '_SHIPTOMOBILEPHONENO_DESC_',
  /** Sort on Broker ascending */
  BrokerAsc = '_BROKER_ASC_',
  /** sort on Broker descending */
  BrokerDesc = '_BROKER_DESC_',
  /** Sort on Carrier ascending */
  CarrierAsc = '_CARRIER_ASC_',
  /** sort on Carrier descending */
  CarrierDesc = '_CARRIER_DESC_',
  /** Sort on Driver ascending */
  DriverAsc = '_DRIVER_ASC_',
  /** sort on Driver descending */
  DriverDesc = '_DRIVER_DESC_',
  /** Sort on BOLSignature ascending */
  BolsignatureAsc = '_BOLSIGNATURE_ASC_',
  /** sort on BOLSignature descending */
  BolsignatureDesc = '_BOLSIGNATURE_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalPostedShptLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on ExtRefNo ascending */
  ExtrefnoAsc = '_EXTREFNO_ASC_',
  /** sort on ExtRefNo descending */
  ExtrefnoDesc = '_EXTREFNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ResourceNo ascending */
  ResourcenoAsc = '_RESOURCENO_ASC_',
  /** sort on ResourceNo descending */
  ResourcenoDesc = '_RESOURCENO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on CustomerSubProject ascending */
  CustomersubprojectAsc = '_CUSTOMERSUBPROJECT_ASC_',
  /** sort on CustomerSubProject descending */
  CustomersubprojectDesc = '_CUSTOMERSUBPROJECT_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on PriceTerm ascending */
  PricetermAsc = '_PRICETERM_ASC_',
  /** sort on PriceTerm descending */
  PricetermDesc = '_PRICETERM_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on RentalPeriod ascending */
  RentalperiodAsc = '_RENTALPERIOD_ASC_',
  /** sort on RentalPeriod descending */
  RentalperiodDesc = '_RENTALPERIOD_DESC_',
  /** Sort on LoadSequenceNo ascending */
  LoadsequencenoAsc = '_LOADSEQUENCENO_ASC_',
  /** sort on LoadSequenceNo descending */
  LoadsequencenoDesc = '_LOADSEQUENCENO_DESC_',
  /** Sort on StairPriceCode ascending */
  StairpricecodeAsc = '_STAIRPRICECODE_ASC_',
  /** sort on StairPriceCode descending */
  StairpricecodeDesc = '_STAIRPRICECODE_DESC_',
  /** Sort on StairDiscountCode ascending */
  StairdiscountcodeAsc = '_STAIRDISCOUNTCODE_ASC_',
  /** sort on StairDiscountCode descending */
  StairdiscountcodeDesc = '_STAIRDISCOUNTCODE_DESC_',
  /** Sort on TransId ascending */
  TransidAsc = '_TRANSID_ASC_',
  /** sort on TransId descending */
  TransidDesc = '_TRANSID_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on PickDate ascending */
  PickdateAsc = '_PICKDATE_ASC_',
  /** sort on PickDate descending */
  PickdateDesc = '_PICKDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on ExpirationDate ascending */
  ExpirationdateAsc = '_EXPIRATIONDATE_ASC_',
  /** sort on ExpirationDate descending */
  ExpirationdateDesc = '_EXPIRATIONDATE_DESC_',
  /** Sort on CounteronShipment ascending */
  CounteronshipmentAsc = '_COUNTERONSHIPMENT_ASC_',
  /** sort on CounteronShipment descending */
  CounteronshipmentDesc = '_COUNTERONSHIPMENT_DESC_',
  /** Sort on CounteronReturn ascending */
  CounteronreturnAsc = '_COUNTERONRETURN_ASC_',
  /** sort on CounteronReturn descending */
  CounteronreturnDesc = '_COUNTERONRETURN_DESC_',
  /** Sort on EQMServiceDoc ascending */
  EqmservicedocAsc = '_EQMSERVICEDOC_ASC_',
  /** sort on EQMServiceDoc descending */
  EqmservicedocDesc = '_EQMSERVICEDOC_DESC_',
  /** Sort on Counter2onShipment ascending */
  Counter2OnshipmentAsc = '_COUNTER2ONSHIPMENT_ASC_',
  /** sort on Counter2onShipment descending */
  Counter2OnshipmentDesc = '_COUNTER2ONSHIPMENT_DESC_',
  /** Sort on Counter2onReturn ascending */
  Counter2OnreturnAsc = '_COUNTER2ONRETURN_ASC_',
  /** sort on Counter2onReturn descending */
  Counter2OnreturnDesc = '_COUNTER2ONRETURN_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on MotherLineNo ascending */
  MotherlinenoAsc = '_MOTHERLINENO_ASC_',
  /** sort on MotherLineNo descending */
  MotherlinenoDesc = '_MOTHERLINENO_DESC_',
  /** Sort on DiamondBladeItem ascending */
  DiamondbladeitemAsc = '_DIAMONDBLADEITEM_ASC_',
  /** sort on DiamondBladeItem descending */
  DiamondbladeitemDesc = '_DIAMONDBLADEITEM_DESC_',
  /** Sort on DiamondBladeItemOPTION ascending */
  DiamondbladeitemoptionAsc = '_DIAMONDBLADEITEMOPTION_ASC_',
  /** sort on DiamondBladeItemOPTION descending */
  DiamondbladeitemoptionDesc = '_DIAMONDBLADEITEMOPTION_DESC_',
  /** Sort on MeasurementatDelivery ascending */
  MeasurementatdeliveryAsc = '_MEASUREMENTATDELIVERY_ASC_',
  /** sort on MeasurementatDelivery descending */
  MeasurementatdeliveryDesc = '_MEASUREMENTATDELIVERY_DESC_',
  /** Sort on ScannedBy ascending */
  ScannedbyAsc = '_SCANNEDBY_ASC_',
  /** sort on ScannedBy descending */
  ScannedbyDesc = '_SCANNEDBY_DESC_',
  /** Sort on ScannedDate ascending */
  ScanneddateAsc = '_SCANNEDDATE_ASC_',
  /** sort on ScannedDate descending */
  ScanneddateDesc = '_SCANNEDDATE_DESC_',
  /** Sort on ScannedTime ascending */
  ScannedtimeAsc = '_SCANNEDTIME_ASC_',
  /** sort on ScannedTime descending */
  ScannedtimeDesc = '_SCANNEDTIME_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on OrderDocNo ascending */
  OrderdocnoAsc = '_ORDERDOCNO_ASC_',
  /** sort on OrderDocNo descending */
  OrderdocnoDesc = '_ORDERDOCNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on QuantityCollected ascending */
  QuantitycollectedAsc = '_QUANTITYCOLLECTED_ASC_',
  /** sort on QuantityCollected descending */
  QuantitycollectedDesc = '_QUANTITYCOLLECTED_DESC_',
  /** Sort on CollectionDocNo ascending */
  CollectiondocnoAsc = '_COLLECTIONDOCNO_ASC_',
  /** sort on CollectionDocNo descending */
  CollectiondocnoDesc = '_COLLECTIONDOCNO_DESC_',
  /** Sort on CollectionLineNo ascending */
  CollectionlinenoAsc = '_COLLECTIONLINENO_ASC_',
  /** sort on CollectionLineNo descending */
  CollectionlinenoDesc = '_COLLECTIONLINENO_DESC_',
  /** Sort on RentalInsurance ascending */
  RentalinsuranceAsc = '_RENTALINSURANCE_ASC_',
  /** sort on RentalInsurance descending */
  RentalinsuranceDesc = '_RENTALINSURANCE_DESC_',
  /** Sort on PriceReportedHours ascending */
  PricereportedhoursAsc = '_PRICEREPORTEDHOURS_ASC_',
  /** sort on PriceReportedHours descending */
  PricereportedhoursDesc = '_PRICEREPORTEDHOURS_DESC_',
  /** Sort on FreeRentalHours ascending */
  FreerentalhoursAsc = '_FREERENTALHOURS_ASC_',
  /** sort on FreeRentalHours descending */
  FreerentalhoursDesc = '_FREERENTALHOURS_DESC_',
  /** Sort on MinimumReportedHours ascending */
  MinimumreportedhoursAsc = '_MINIMUMREPORTEDHOURS_ASC_',
  /** sort on MinimumReportedHours descending */
  MinimumreportedhoursDesc = '_MINIMUMREPORTEDHOURS_DESC_',
  /** Sort on MinRepHoursPeriod ascending */
  MinrephoursperiodAsc = '_MINREPHOURSPERIOD_ASC_',
  /** sort on MinRepHoursPeriod descending */
  MinrephoursperiodDesc = '_MINREPHOURSPERIOD_DESC_',
  /** Sort on StairPrice1 ascending */
  Stairprice1Asc = '_STAIRPRICE1_ASC_',
  /** sort on StairPrice1 descending */
  Stairprice1Desc = '_STAIRPRICE1_DESC_',
  /** Sort on StairPrice2 ascending */
  Stairprice2Asc = '_STAIRPRICE2_ASC_',
  /** sort on StairPrice2 descending */
  Stairprice2Desc = '_STAIRPRICE2_DESC_',
  /** Sort on StairPrice3 ascending */
  Stairprice3Asc = '_STAIRPRICE3_ASC_',
  /** sort on StairPrice3 descending */
  Stairprice3Desc = '_STAIRPRICE3_DESC_',
  /** Sort on StairPrice4 ascending */
  Stairprice4Asc = '_STAIRPRICE4_ASC_',
  /** sort on StairPrice4 descending */
  Stairprice4Desc = '_STAIRPRICE4_DESC_',
  /** Sort on StairPrice5 ascending */
  Stairprice5Asc = '_STAIRPRICE5_ASC_',
  /** sort on StairPrice5 descending */
  Stairprice5Desc = '_STAIRPRICE5_DESC_',
  /** Sort on MinimumRentalPeriod ascending */
  MinimumrentalperiodAsc = '_MINIMUMRENTALPERIOD_ASC_',
  /** sort on MinimumRentalPeriod descending */
  MinimumrentalperiodDesc = '_MINIMUMRENTALPERIOD_DESC_',
  /** Sort on MinimumRentaltoDate ascending */
  MinimumrentaltodateAsc = '_MINIMUMRENTALTODATE_ASC_',
  /** sort on MinimumRentaltoDate descending */
  MinimumrentaltodateDesc = '_MINIMUMRENTALTODATE_DESC_',
  /** Sort on UseAdvancedMgtCalendar ascending */
  UseadvancedmgtcalendarAsc = '_USEADVANCEDMGTCALENDAR_ASC_',
  /** sort on UseAdvancedMgtCalendar descending */
  UseadvancedmgtcalendarDesc = '_USEADVANCEDMGTCALENDAR_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalPriceTermsInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on ChargeFullPeriod ascending */
  ChargefullperiodAsc = '_CHARGEFULLPERIOD_ASC_',
  /** sort on ChargeFullPeriod descending */
  ChargefullperiodDesc = '_CHARGEFULLPERIOD_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on ReportedHours ascending */
  ReportedhoursAsc = '_REPORTEDHOURS_ASC_',
  /** sort on ReportedHours descending */
  ReportedhoursDesc = '_REPORTEDHOURS_DESC_',
  /** Sort on FixedDays ascending */
  FixeddaysAsc = '_FIXEDDAYS_ASC_',
  /** sort on FixedDays descending */
  FixeddaysDesc = '_FIXEDDAYS_DESC_',
  /** Sort on SquareMeter ascending */
  SquaremeterAsc = '_SQUAREMETER_ASC_',
  /** sort on SquareMeter descending */
  SquaremeterDesc = '_SQUAREMETER_DESC_',
  /** Sort on FullTerm ascending */
  FulltermAsc = '_FULLTERM_ASC_',
  /** sort on FullTerm descending */
  FulltermDesc = '_FULLTERM_DESC_',
  /** Sort on HalfTerm ascending */
  HalftermAsc = '_HALFTERM_ASC_',
  /** sort on HalfTerm descending */
  HalftermDesc = '_HALFTERM_DESC_',
  /** Sort on FixedBillingPeriod ascending */
  FixedbillingperiodAsc = '_FIXEDBILLINGPERIOD_ASC_',
  /** sort on FixedBillingPeriod descending */
  FixedbillingperiodDesc = '_FIXEDBILLINGPERIOD_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRentalReturnEntryInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on ReturnNo ascending */
  ReturnnoAsc = '_RETURNNO_ASC_',
  /** sort on ReturnNo descending */
  ReturnnoDesc = '_RETURNNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on ExtRentalLineNo ascending */
  ExtrentallinenoAsc = '_EXTRENTALLINENO_ASC_',
  /** sort on ExtRentalLineNo descending */
  ExtrentallinenoDesc = '_EXTRENTALLINENO_DESC_',
  /** Sort on EntryType ascending */
  EntrytypeAsc = '_ENTRYTYPE_ASC_',
  /** sort on EntryType descending */
  EntrytypeDesc = '_ENTRYTYPE_DESC_',
  /** Sort on EntryTypeOPTION ascending */
  EntrytypeoptionAsc = '_ENTRYTYPEOPTION_ASC_',
  /** sort on EntryTypeOPTION descending */
  EntrytypeoptionDesc = '_ENTRYTYPEOPTION_DESC_',
  /** Sort on ReturnType ascending */
  ReturntypeAsc = '_RETURNTYPE_ASC_',
  /** sort on ReturnType descending */
  ReturntypeDesc = '_RETURNTYPE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on PostedinDocument ascending */
  PostedindocumentAsc = '_POSTEDINDOCUMENT_ASC_',
  /** sort on PostedinDocument descending */
  PostedindocumentDesc = '_POSTEDINDOCUMENT_DESC_',
  /** Sort on PostingTime ascending */
  PostingtimeAsc = '_POSTINGTIME_ASC_',
  /** sort on PostingTime descending */
  PostingtimeDesc = '_POSTINGTIME_DESC_',
  /** Sort on TransferredtoOrder ascending */
  TransferredtoorderAsc = '_TRANSFERREDTOORDER_ASC_',
  /** sort on TransferredtoOrder descending */
  TransferredtoorderDesc = '_TRANSFERREDTOORDER_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SerialNo ascending */
  SerialnoAsc = '_SERIALNO_ASC_',
  /** sort on SerialNo descending */
  SerialnoDesc = '_SERIALNO_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on StartRentalPeriod ascending */
  StartrentalperiodAsc = '_STARTRENTALPERIOD_ASC_',
  /** sort on StartRentalPeriod descending */
  StartrentalperiodDesc = '_STARTRENTALPERIOD_DESC_',
  /** Sort on OnRentDate ascending */
  OnrentdateAsc = '_ONRENTDATE_ASC_',
  /** sort on OnRentDate descending */
  OnrentdateDesc = '_ONRENTDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on InvoicedToDate ascending */
  InvoicedtodateAsc = '_INVOICEDTODATE_ASC_',
  /** sort on InvoicedToDate descending */
  InvoicedtodateDesc = '_INVOICEDTODATE_DESC_',
  /** Sort on OffRentDate ascending */
  OffrentdateAsc = '_OFFRENTDATE_ASC_',
  /** sort on OffRentDate descending */
  OffrentdateDesc = '_OFFRENTDATE_DESC_',
  /** Sort on OffRentTime ascending */
  OffrenttimeAsc = '_OFFRENTTIME_ASC_',
  /** sort on OffRentTime descending */
  OffrenttimeDesc = '_OFFRENTTIME_DESC_',
  /** Sort on ReturnDate ascending */
  ReturndateAsc = '_RETURNDATE_ASC_',
  /** sort on ReturnDate descending */
  ReturndateDesc = '_RETURNDATE_DESC_',
  /** Sort on ReturnTime ascending */
  ReturntimeAsc = '_RETURNTIME_ASC_',
  /** sort on ReturnTime descending */
  ReturntimeDesc = '_RETURNTIME_DESC_',
  /** Sort on DebitonReturnDate ascending */
  DebitonreturndateAsc = '_DEBITONRETURNDATE_ASC_',
  /** sort on DebitonReturnDate descending */
  DebitonreturndateDesc = '_DEBITONRETURNDATE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on RemainingQuantity ascending */
  RemainingquantityAsc = '_REMAININGQUANTITY_ASC_',
  /** sort on RemainingQuantity descending */
  RemainingquantityDesc = '_REMAININGQUANTITY_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on TransactionNo ascending */
  TransactionnoAsc = '_TRANSACTIONNO_ASC_',
  /** sort on TransactionNo descending */
  TransactionnoDesc = '_TRANSACTIONNO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ReceivingLocationCode ascending */
  ReceivinglocationcodeAsc = '_RECEIVINGLOCATIONCODE_ASC_',
  /** sort on ReceivingLocationCode descending */
  ReceivinglocationcodeDesc = '_RECEIVINGLOCATIONCODE_DESC_',
  /** Sort on UndoEntry ascending */
  UndoentryAsc = '_UNDOENTRY_ASC_',
  /** sort on UndoEntry descending */
  UndoentryDesc = '_UNDOENTRY_DESC_',
  /** Sort on DispatchType ascending */
  DispatchtypeAsc = '_DISPATCHTYPE_ASC_',
  /** sort on DispatchType descending */
  DispatchtypeDesc = '_DISPATCHTYPE_DESC_',
  /** Sort on PstDispatchDocNo ascending */
  PstdispatchdocnoAsc = '_PSTDISPATCHDOCNO_ASC_',
  /** sort on PstDispatchDocNo descending */
  PstdispatchdocnoDesc = '_PSTDISPATCHDOCNO_DESC_',
  /** Sort on PstDispatchLineNo ascending */
  PstdispatchlinenoAsc = '_PSTDISPATCHLINENO_ASC_',
  /** sort on PstDispatchLineNo descending */
  PstdispatchlinenoDesc = '_PSTDISPATCHLINENO_DESC_',
  /** Sort on OrderDocNo ascending */
  OrderdocnoAsc = '_ORDERDOCNO_ASC_',
  /** sort on OrderDocNo descending */
  OrderdocnoDesc = '_ORDERDOCNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on DoPostReturn ascending */
  DopostreturnAsc = '_DOPOSTRETURN_ASC_',
  /** sort on DoPostReturn descending */
  DopostreturnDesc = '_DOPOSTRETURN_DESC_',
  /** Sort on DoPostOffRent ascending */
  DopostoffrentAsc = '_DOPOSTOFFRENT_ASC_',
  /** sort on DoPostOffRent descending */
  DopostoffrentDesc = '_DOPOSTOFFRENT_DESC_',
  /** Sort on OrigOffRentDate ascending */
  OrigoffrentdateAsc = '_ORIGOFFRENTDATE_ASC_',
  /** sort on OrigOffRentDate descending */
  OrigoffrentdateDesc = '_ORIGOFFRENTDATE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on KITDontShow ascending */
  KitdontshowAsc = '_KITDONTSHOW_ASC_',
  /** sort on KITDontShow descending */
  KitdontshowDesc = '_KITDONTSHOW_DESC_',
  /** Sort on KITMotherLineNo ascending */
  KitmotherlinenoAsc = '_KITMOTHERLINENO_ASC_',
  /** sort on KITMotherLineNo descending */
  KitmotherlinenoDesc = '_KITMOTHERLINENO_DESC_',
  /** Sort on NumberofRentFreeDays ascending */
  NumberofrentfreedaysAsc = '_NUMBEROFRENTFREEDAYS_ASC_',
  /** sort on NumberofRentFreeDays descending */
  NumberofrentfreedaysDesc = '_NUMBEROFRENTFREEDAYS_DESC_',
  /** Sort on NumberofRentFreeHours ascending */
  NumberofrentfreehoursAsc = '_NUMBEROFRENTFREEHOURS_ASC_',
  /** sort on NumberofRentFreeHours descending */
  NumberofrentfreehoursDesc = '_NUMBEROFRENTFREEHOURS_DESC_',
  /** Sort on ReasonforRentFree ascending */
  ReasonforrentfreeAsc = '_REASONFORRENTFREE_ASC_',
  /** sort on ReasonforRentFree descending */
  ReasonforrentfreeDesc = '_REASONFORRENTFREE_DESC_',
  /** Sort on CreditonEarlyReturn ascending */
  CreditonearlyreturnAsc = '_CREDITONEARLYRETURN_ASC_',
  /** sort on CreditonEarlyReturn descending */
  CreditonearlyreturnDesc = '_CREDITONEARLYRETURN_DESC_',
  /** Sort on QuantitySold ascending */
  QuantitysoldAsc = '_QUANTITYSOLD_ASC_',
  /** sort on QuantitySold descending */
  QuantitysoldDesc = '_QUANTITYSOLD_DESC_',
  /** Sort on QuantityDamaged ascending */
  QuantitydamagedAsc = '_QUANTITYDAMAGED_ASC_',
  /** sort on QuantityDamaged descending */
  QuantitydamagedDesc = '_QUANTITYDAMAGED_DESC_',
  /** Sort on CounteronReturn ascending */
  CounteronreturnAsc = '_COUNTERONRETURN_ASC_',
  /** sort on CounteronReturn descending */
  CounteronreturnDesc = '_COUNTERONRETURN_DESC_',
  /** Sort on Maintenance ascending */
  MaintenanceAsc = '_MAINTENANCE_ASC_',
  /** sort on Maintenance descending */
  MaintenanceDesc = '_MAINTENANCE_DESC_',
  /** Sort on CounteronShipment ascending */
  CounteronshipmentAsc = '_COUNTERONSHIPMENT_ASC_',
  /** sort on CounteronShipment descending */
  CounteronshipmentDesc = '_COUNTERONSHIPMENT_DESC_',
  /** Sort on Counter2onReturn ascending */
  Counter2OnreturnAsc = '_COUNTER2ONRETURN_ASC_',
  /** sort on Counter2onReturn descending */
  Counter2OnreturnDesc = '_COUNTER2ONRETURN_DESC_',
  /** Sort on Counter2onShipment ascending */
  Counter2OnshipmentAsc = '_COUNTER2ONSHIPMENT_ASC_',
  /** sort on Counter2onShipment descending */
  Counter2OnshipmentDesc = '_COUNTER2ONSHIPMENT_DESC_',
  /** Sort on ReturnQuantityTime ascending */
  ReturnquantitytimeAsc = '_RETURNQUANTITYTIME_ASC_',
  /** sort on ReturnQuantityTime descending */
  ReturnquantitytimeDesc = '_RETURNQUANTITYTIME_DESC_',
  /** Sort on TmpLocationCode ascending */
  TmplocationcodeAsc = '_TMPLOCATIONCODE_ASC_',
  /** sort on TmpLocationCode descending */
  TmplocationcodeDesc = '_TMPLOCATIONCODE_DESC_',
  /** Sort on AppliestoEntry ascending */
  AppliestoentryAsc = '_APPLIESTOENTRY_ASC_',
  /** sort on AppliestoEntry descending */
  AppliestoentryDesc = '_APPLIESTOENTRY_DESC_',
  /** Sort on CompleteReturn ascending */
  CompletereturnAsc = '_COMPLETERETURN_ASC_',
  /** sort on CompleteReturn descending */
  CompletereturnDesc = '_COMPLETERETURN_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ChargeCustomer ascending */
  ChargecustomerAsc = '_CHARGECUSTOMER_ASC_',
  /** sort on ChargeCustomer descending */
  ChargecustomerDesc = '_CHARGECUSTOMER_DESC_',
  /** Sort on MeasurementatDelivery ascending */
  MeasurementatdeliveryAsc = '_MEASUREMENTATDELIVERY_ASC_',
  /** sort on MeasurementatDelivery descending */
  MeasurementatdeliveryDesc = '_MEASUREMENTATDELIVERY_DESC_',
  /** Sort on MeasurementatReturn ascending */
  MeasurementatreturnAsc = '_MEASUREMENTATRETURN_ASC_',
  /** sort on MeasurementatReturn descending */
  MeasurementatreturnDesc = '_MEASUREMENTATRETURN_DESC_',
  /** Sort on DiamondBladeItem ascending */
  DiamondbladeitemAsc = '_DIAMONDBLADEITEM_ASC_',
  /** sort on DiamondBladeItem descending */
  DiamondbladeitemDesc = '_DIAMONDBLADEITEM_DESC_',
  /** Sort on FullyConsumedAccessory ascending */
  FullyconsumedaccessoryAsc = '_FULLYCONSUMEDACCESSORY_ASC_',
  /** sort on FullyConsumedAccessory descending */
  FullyconsumedaccessoryDesc = '_FULLYCONSUMEDACCESSORY_DESC_',
  /** Sort on FullReturnSale ascending */
  FullreturnsaleAsc = '_FULLRETURNSALE_ASC_',
  /** sort on FullReturnSale descending */
  FullreturnsaleDesc = '_FULLRETURNSALE_DESC_',
  /** Sort on RentalSale ascending */
  RentalsaleAsc = '_RENTALSALE_ASC_',
  /** sort on RentalSale descending */
  RentalsaleDesc = '_RENTALSALE_DESC_',
  /** Sort on QtyReserved ascending */
  QtyreservedAsc = '_QTYRESERVED_ASC_',
  /** sort on QtyReserved descending */
  QtyreservedDesc = '_QTYRESERVED_DESC_',
  /** Sort on QtytoCollect ascending */
  QtytocollectAsc = '_QTYTOCOLLECT_ASC_',
  /** sort on QtytoCollect descending */
  QtytocollectDesc = '_QTYTOCOLLECT_DESC_',
  /** Sort on QuantityReturned ascending */
  QuantityreturnedAsc = '_QUANTITYRETURNED_ASC_',
  /** sort on QuantityReturned descending */
  QuantityreturnedDesc = '_QUANTITYRETURNED_DESC_',
  /** Sort on HourlyRent ascending */
  HourlyrentAsc = '_HOURLYRENT_ASC_',
  /** sort on HourlyRent descending */
  HourlyrentDesc = '_HOURLYRENT_DESC_',
  /** Sort on QtyHours ascending */
  QtyhoursAsc = '_QTYHOURS_ASC_',
  /** sort on QtyHours descending */
  QtyhoursDesc = '_QTYHOURS_DESC_',
  /** Sort on ShipmentTime ascending */
  ShipmenttimeAsc = '_SHIPMENTTIME_ASC_',
  /** sort on ShipmentTime descending */
  ShipmenttimeDesc = '_SHIPMENTTIME_DESC_',
  /** Sort on OnRentTime ascending */
  OnrenttimeAsc = '_ONRENTTIME_ASC_',
  /** sort on OnRentTime descending */
  OnrenttimeDesc = '_ONRENTTIME_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on ReceivingBinCode ascending */
  ReceivingbincodeAsc = '_RECEIVINGBINCODE_ASC_',
  /** sort on ReceivingBinCode descending */
  ReceivingbincodeDesc = '_RECEIVINGBINCODE_DESC_',
  /** Sort on PriceonExtraHours ascending */
  PriceonextrahoursAsc = '_PRICEONEXTRAHOURS_ASC_',
  /** sort on PriceonExtraHours descending */
  PriceonextrahoursDesc = '_PRICEONEXTRAHOURS_DESC_',
  /** Sort on KITComponent ascending */
  KitcomponentAsc = '_KITCOMPONENT_ASC_',
  /** sort on KITComponent descending */
  KitcomponentDesc = '_KITCOMPONENT_DESC_',
  /** Sort on EQMServiceDoc ascending */
  EqmservicedocAsc = '_EQMSERVICEDOC_ASC_',
  /** sort on EQMServiceDoc descending */
  EqmservicedocDesc = '_EQMSERVICEDOC_DESC_',
  /** Sort on IgnoreVaryingCompQty ascending */
  IgnorevaryingcompqtyAsc = '_IGNOREVARYINGCOMPQTY_ASC_',
  /** sort on IgnoreVaryingCompQty descending */
  IgnorevaryingcompqtyDesc = '_IGNOREVARYINGCOMPQTY_DESC_',
  /** Sort on QtyPstCollectedFlow ascending */
  QtypstcollectedflowAsc = '_QTYPSTCOLLECTEDFLOW_ASC_',
  /** sort on QtyPstCollectedFlow descending */
  QtypstcollectedflowDesc = '_QTYPSTCOLLECTEDFLOW_DESC_',
  /** Sort on PstdCollOrderDocNo ascending */
  PstdcollorderdocnoAsc = '_PSTDCOLLORDERDOCNO_ASC_',
  /** sort on PstdCollOrderDocNo descending */
  PstdcollorderdocnoDesc = '_PSTDCOLLORDERDOCNO_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceTermCode ascending */
  PricetermcodeAsc = '_PRICETERMCODE_ASC_',
  /** sort on PriceTermCode descending */
  PricetermcodeDesc = '_PRICETERMCODE_DESC_',
  /** Sort on RentalClosed ascending */
  RentalclosedAsc = '_RENTALCLOSED_ASC_',
  /** sort on RentalClosed descending */
  RentalclosedDesc = '_RENTALCLOSED_DESC_',
  /** Sort on BaseCalendar ascending */
  BasecalendarAsc = '_BASECALENDAR_ASC_',
  /** sort on BaseCalendar descending */
  BasecalendarDesc = '_BASECALENDAR_DESC_',
  /** Sort on LineAmountPeriod ascending */
  LineamountperiodAsc = '_LINEAMOUNTPERIOD_ASC_',
  /** sort on LineAmountPeriod descending */
  LineamountperiodDesc = '_LINEAMOUNTPERIOD_DESC_',
  /** Sort on ProjectedReturnDate ascending */
  ProjectedreturndateAsc = '_PROJECTEDRETURNDATE_ASC_',
  /** sort on ProjectedReturnDate descending */
  ProjectedreturndateDesc = '_PROJECTEDRETURNDATE_DESC_',
  /** Sort on InvoiceToDate ascending */
  InvoicetodateAsc = '_INVOICETODATE_ASC_',
  /** sort on InvoiceToDate descending */
  InvoicetodateDesc = '_INVOICETODATE_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on DiamondQuantity ascending */
  DiamondquantityAsc = '_DIAMONDQUANTITY_ASC_',
  /** sort on DiamondQuantity descending */
  DiamondquantityDesc = '_DIAMONDQUANTITY_DESC_',
  /** Sort on DontTransfertoOrder ascending */
  DonttransfertoorderAsc = '_DONTTRANSFERTOORDER_ASC_',
  /** sort on DontTransfertoOrder descending */
  DonttransfertoorderDesc = '_DONTTRANSFERTOORDER_DESC_',
  /** Sort on RentalDays ascending */
  RentaldaysAsc = '_RENTALDAYS_ASC_',
  /** sort on RentalDays descending */
  RentaldaysDesc = '_RENTALDAYS_DESC_',
  /** Sort on MonthPrice ascending */
  MonthpriceAsc = '_MONTHPRICE_ASC_',
  /** sort on MonthPrice descending */
  MonthpriceDesc = '_MONTHPRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRequestHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on RequestType ascending */
  RequesttypeAsc = '_REQUESTTYPE_ASC_',
  /** sort on RequestType descending */
  RequesttypeDesc = '_REQUESTTYPE_DESC_',
  /** Sort on RequestTypeOPTION ascending */
  RequesttypeoptionAsc = '_REQUESTTYPEOPTION_ASC_',
  /** sort on RequestTypeOPTION descending */
  RequesttypeoptionDesc = '_REQUESTTYPEOPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on SubProjectNo ascending */
  SubprojectnoAsc = '_SUBPROJECTNO_ASC_',
  /** sort on SubProjectNo descending */
  SubprojectnoDesc = '_SUBPROJECTNO_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on ProjectName ascending */
  ProjectnameAsc = '_PROJECTNAME_ASC_',
  /** sort on ProjectName descending */
  ProjectnameDesc = '_PROJECTNAME_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on Time ascending */
  TimeAsc = '_TIME_ASC_',
  /** sort on Time descending */
  TimeDesc = '_TIME_DESC_',
  /** Sort on DispatchHeader ascending */
  DispatchheaderAsc = '_DISPATCHHEADER_ASC_',
  /** sort on DispatchHeader descending */
  DispatchheaderDesc = '_DISPATCHHEADER_DESC_',
  /** Sort on DispatchPosted ascending */
  DispatchpostedAsc = '_DISPATCHPOSTED_ASC_',
  /** sort on DispatchPosted descending */
  DispatchpostedDesc = '_DISPATCHPOSTED_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on Email ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on Email descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRequestLineInput {
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on RequestType ascending */
  RequesttypeAsc = '_REQUESTTYPE_ASC_',
  /** sort on RequestType descending */
  RequesttypeDesc = '_REQUESTTYPE_DESC_',
  /** Sort on RequestTypeOPTION ascending */
  RequesttypeoptionAsc = '_REQUESTTYPEOPTION_ASC_',
  /** sort on RequestTypeOPTION descending */
  RequesttypeoptionDesc = '_REQUESTTYPEOPTION_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on LineType ascending */
  LinetypeAsc = '_LINETYPE_ASC_',
  /** sort on LineType descending */
  LinetypeDesc = '_LINETYPE_DESC_',
  /** Sort on LineTypeOPTION ascending */
  LinetypeoptionAsc = '_LINETYPEOPTION_ASC_',
  /** sort on LineTypeOPTION descending */
  LinetypeoptionDesc = '_LINETYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on SubProjectNo ascending */
  SubprojectnoAsc = '_SUBPROJECTNO_ASC_',
  /** sort on SubProjectNo descending */
  SubprojectnoDesc = '_SUBPROJECTNO_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on ProjectName ascending */
  ProjectnameAsc = '_PROJECTNAME_ASC_',
  /** sort on ProjectName descending */
  ProjectnameDesc = '_PROJECTNAME_DESC_',
  /** Sort on ManufacturerCode ascending */
  ManufacturercodeAsc = '_MANUFACTURERCODE_ASC_',
  /** sort on ManufacturerCode descending */
  ManufacturercodeDesc = '_MANUFACTURERCODE_DESC_',
  /** Sort on ManufacturerModelCode ascending */
  ManufacturermodelcodeAsc = '_MANUFACTURERMODELCODE_ASC_',
  /** sort on ManufacturerModelCode descending */
  ManufacturermodelcodeDesc = '_MANUFACTURERMODELCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortReservationRequestHeaderInput {
  /** Sort on GroupGUID ascending */
  GroupguidAsc = '_GROUPGUID_ASC_',
  /** sort on GroupGUID descending */
  GroupguidDesc = '_GROUPGUID_DESC_',
  /** Sort on Header ascending */
  HeaderAsc = '_HEADER_ASC_',
  /** sort on Header descending */
  HeaderDesc = '_HEADER_DESC_',
  /** Sort on TypeOfRequest ascending */
  TypeofrequestAsc = '_TYPEOFREQUEST_ASC_',
  /** sort on TypeOfRequest descending */
  TypeofrequestDesc = '_TYPEOFREQUEST_DESC_',
  /** Sort on Confirmed ascending */
  ConfirmedAsc = '_CONFIRMED_ASC_',
  /** sort on Confirmed descending */
  ConfirmedDesc = '_CONFIRMED_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on UserName ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on UserName descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on DeliveryDate ascending */
  DeliverydateAsc = '_DELIVERYDATE_ASC_',
  /** sort on DeliveryDate descending */
  DeliverydateDesc = '_DELIVERYDATE_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on ExpectedReturnDate ascending */
  ExpectedreturndateAsc = '_EXPECTEDRETURNDATE_ASC_',
  /** sort on ExpectedReturnDate descending */
  ExpectedreturndateDesc = '_EXPECTEDRETURNDATE_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on RequestDate ascending */
  RequestdateAsc = '_REQUESTDATE_ASC_',
  /** sort on RequestDate descending */
  RequestdateDesc = '_REQUESTDATE_DESC_',
  /** Sort on DomainName ascending */
  DomainnameAsc = '_DOMAINNAME_ASC_',
  /** sort on DomainName descending */
  DomainnameDesc = '_DOMAINNAME_DESC_',
  /** Sort on Executed ascending */
  ExecutedAsc = '_EXECUTED_ASC_',
  /** sort on Executed descending */
  ExecutedDesc = '_EXECUTED_DESC_',
  /** Sort on Pickup ascending */
  PickupAsc = '_PICKUP_ASC_',
  /** sort on Pickup descending */
  PickupDesc = '_PICKUP_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortReservationRequestLineInput {
  /** Sort on GroupGUID ascending */
  GroupguidAsc = '_GROUPGUID_ASC_',
  /** sort on GroupGUID descending */
  GroupguidDesc = '_GROUPGUID_DESC_',
  /** Sort on LineGUID ascending */
  LineguidAsc = '_LINEGUID_ASC_',
  /** sort on LineGUID descending */
  LineguidDesc = '_LINEGUID_DESC_',
  /** Sort on Header ascending */
  HeaderAsc = '_HEADER_ASC_',
  /** sort on Header descending */
  HeaderDesc = '_HEADER_DESC_',
  /** Sort on TypeOfRequest ascending */
  TypeofrequestAsc = '_TYPEOFREQUEST_ASC_',
  /** sort on TypeOfRequest descending */
  TypeofrequestDesc = '_TYPEOFREQUEST_DESC_',
  /** Sort on Confirmed ascending */
  ConfirmedAsc = '_CONFIRMED_ASC_',
  /** sort on Confirmed descending */
  ConfirmedDesc = '_CONFIRMED_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on UserName ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on UserName descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on RequestDate ascending */
  RequestdateAsc = '_REQUESTDATE_ASC_',
  /** sort on RequestDate descending */
  RequestdateDesc = '_REQUESTDATE_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on Executed ascending */
  ExecutedAsc = '_EXECUTED_ASC_',
  /** sort on Executed descending */
  ExecutedDesc = '_EXECUTED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortResourceInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on SearchName ascending */
  SearchnameAsc = '_SEARCHNAME_ASC_',
  /** sort on SearchName descending */
  SearchnameDesc = '_SEARCHNAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on SocialSecurityNo ascending */
  SocialsecuritynoAsc = '_SOCIALSECURITYNO_ASC_',
  /** sort on SocialSecurityNo descending */
  SocialsecuritynoDesc = '_SOCIALSECURITYNO_DESC_',
  /** Sort on JobTitle ascending */
  JobtitleAsc = '_JOBTITLE_ASC_',
  /** sort on JobTitle descending */
  JobtitleDesc = '_JOBTITLE_DESC_',
  /** Sort on Education ascending */
  EducationAsc = '_EDUCATION_ASC_',
  /** sort on Education descending */
  EducationDesc = '_EDUCATION_DESC_',
  /** Sort on ContractClass ascending */
  ContractclassAsc = '_CONTRACTCLASS_ASC_',
  /** sort on ContractClass descending */
  ContractclassDesc = '_CONTRACTCLASS_DESC_',
  /** Sort on EmploymentDate ascending */
  EmploymentdateAsc = '_EMPLOYMENTDATE_ASC_',
  /** sort on EmploymentDate descending */
  EmploymentdateDesc = '_EMPLOYMENTDATE_DESC_',
  /** Sort on ResourceGroupNo ascending */
  ResourcegroupnoAsc = '_RESOURCEGROUPNO_ASC_',
  /** sort on ResourceGroupNo descending */
  ResourcegroupnoDesc = '_RESOURCEGROUPNO_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on BaseUnitofMeasure ascending */
  BaseunitofmeasureAsc = '_BASEUNITOFMEASURE_ASC_',
  /** sort on BaseUnitofMeasure descending */
  BaseunitofmeasureDesc = '_BASEUNITOFMEASURE_DESC_',
  /** Sort on DirectUnitCost ascending */
  DirectunitcostAsc = '_DIRECTUNITCOST_ASC_',
  /** sort on DirectUnitCost descending */
  DirectunitcostDesc = '_DIRECTUNITCOST_DESC_',
  /** Sort on IndirectCost ascending */
  IndirectcostAsc = '_INDIRECTCOST_ASC_',
  /** sort on IndirectCost descending */
  IndirectcostDesc = '_INDIRECTCOST_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on PriceProfitCalculation ascending */
  PriceprofitcalculationAsc = '_PRICEPROFITCALCULATION_ASC_',
  /** sort on PriceProfitCalculation descending */
  PriceprofitcalculationDesc = '_PRICEPROFITCALCULATION_DESC_',
  /** Sort on PriceProfitCalculationOPTION ascending */
  PriceprofitcalculationoptionAsc = '_PRICEPROFITCALCULATIONOPTION_ASC_',
  /** sort on PriceProfitCalculationOPTION descending */
  PriceprofitcalculationoptionDesc = '_PRICEPROFITCALCULATIONOPTION_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on AutomaticExtTexts ascending */
  AutomaticexttextsAsc = '_AUTOMATICEXTTEXTS_ASC_',
  /** sort on AutomaticExtTexts descending */
  AutomaticexttextsDesc = '_AUTOMATICEXTTEXTS_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on ICPartnerPurchGLAccNo ascending */
  IcpartnerpurchglaccnoAsc = '_ICPARTNERPURCHGLACCNO_ASC_',
  /** sort on ICPartnerPurchGLAccNo descending */
  IcpartnerpurchglaccnoDesc = '_ICPARTNERPURCHGLACCNO_DESC_',
  /** Sort on UseTimeSheet ascending */
  UsetimesheetAsc = '_USETIMESHEET_ASC_',
  /** sort on UseTimeSheet descending */
  UsetimesheetDesc = '_USETIMESHEET_DESC_',
  /** Sort on TimeSheetOwnerUserID ascending */
  TimesheetowneruseridAsc = '_TIMESHEETOWNERUSERID_ASC_',
  /** sort on TimeSheetOwnerUserID descending */
  TimesheetowneruseridDesc = '_TIMESHEETOWNERUSERID_DESC_',
  /** Sort on TimeSheetApproverUserID ascending */
  TimesheetapproveruseridAsc = '_TIMESHEETAPPROVERUSERID_ASC_',
  /** sort on TimeSheetApproverUserID descending */
  TimesheetapproveruseridDesc = '_TIMESHEETAPPROVERUSERID_DESC_',
  /** Sort on ServiceZoneFilter ascending */
  ServicezonefilterAsc = '_SERVICEZONEFILTER_ASC_',
  /** sort on ServiceZoneFilter descending */
  ServicezonefilterDesc = '_SERVICEZONEFILTER_DESC_',
  /** Sort on DefaultQuantity ascending */
  DefaultquantityAsc = '_DEFAULTQUANTITY_ASC_',
  /** sort on DefaultQuantity descending */
  DefaultquantityDesc = '_DEFAULTQUANTITY_DESC_',
  /** Sort on Mounting ascending */
  MountingAsc = '_MOUNTING_ASC_',
  /** sort on Mounting descending */
  MountingDesc = '_MOUNTING_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on UnitofMeasureFilter ascending */
  UnitofmeasurefilterAsc = '_UNITOFMEASUREFILTER_ASC_',
  /** sort on UnitofMeasureFilter descending */
  UnitofmeasurefilterDesc = '_UNITOFMEASUREFILTER_DESC_',
  /** Sort on ChargeableFilter ascending */
  ChargeablefilterAsc = '_CHARGEABLEFILTER_ASC_',
  /** sort on ChargeableFilter descending */
  ChargeablefilterDesc = '_CHARGEABLEFILTER_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Capacity ascending */
  CapacityAsc = '_CAPACITY_ASC_',
  /** sort on Capacity descending */
  CapacityDesc = '_CAPACITY_DESC_',
  /** Sort on QtyonOrderJob ascending */
  QtyonorderjobAsc = '_QTYONORDERJOB_ASC_',
  /** sort on QtyonOrderJob descending */
  QtyonorderjobDesc = '_QTYONORDERJOB_DESC_',
  /** Sort on QtyQuotedJob ascending */
  QtyquotedjobAsc = '_QTYQUOTEDJOB_ASC_',
  /** sort on QtyQuotedJob descending */
  QtyquotedjobDesc = '_QTYQUOTEDJOB_DESC_',
  /** Sort on UsageQty ascending */
  UsageqtyAsc = '_USAGEQTY_ASC_',
  /** sort on UsageQty descending */
  UsageqtyDesc = '_USAGEQTY_DESC_',
  /** Sort on UsageCost ascending */
  UsagecostAsc = '_USAGECOST_ASC_',
  /** sort on UsageCost descending */
  UsagecostDesc = '_USAGECOST_DESC_',
  /** Sort on UsagePrice ascending */
  UsagepriceAsc = '_USAGEPRICE_ASC_',
  /** sort on UsagePrice descending */
  UsagepriceDesc = '_USAGEPRICE_DESC_',
  /** Sort on SalesQty ascending */
  SalesqtyAsc = '_SALESQTY_ASC_',
  /** sort on SalesQty descending */
  SalesqtyDesc = '_SALESQTY_DESC_',
  /** Sort on SalesCost ascending */
  SalescostAsc = '_SALESCOST_ASC_',
  /** sort on SalesCost descending */
  SalescostDesc = '_SALESCOST_DESC_',
  /** Sort on SalesPrice ascending */
  SalespriceAsc = '_SALESPRICE_ASC_',
  /** sort on SalesPrice descending */
  SalespriceDesc = '_SALESPRICE_DESC_',
  /** Sort on QtyonAssemblyOrder ascending */
  QtyonassemblyorderAsc = '_QTYONASSEMBLYORDER_ASC_',
  /** sort on QtyonAssemblyOrder descending */
  QtyonassemblyorderDesc = '_QTYONASSEMBLYORDER_DESC_',
  /** Sort on QtyonServiceOrder ascending */
  QtyonserviceorderAsc = '_QTYONSERVICEORDER_ASC_',
  /** sort on QtyonServiceOrder descending */
  QtyonserviceorderDesc = '_QTYONSERVICEORDER_DESC_',
  /** Sort on InCustomerZone ascending */
  IncustomerzoneAsc = '_INCUSTOMERZONE_ASC_',
  /** sort on InCustomerZone descending */
  IncustomerzoneDesc = '_INCUSTOMERZONE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ShowInFieldOrder ascending */
  ShowinfieldorderAsc = '_SHOWINFIELDORDER_ASC_',
  /** sort on ShowInFieldOrder descending */
  ShowinfieldorderDesc = '_SHOWINFIELDORDER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortResponsibilityCenterInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on EMailSignatureText ascending */
  EmailsignaturetextAsc = '_EMAILSIGNATURETEXT_ASC_',
  /** sort on EMailSignatureText descending */
  EmailsignaturetextDesc = '_EMAILSIGNATURETEXT_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on ContractGainLossAmount ascending */
  ContractgainlossamountAsc = '_CONTRACTGAINLOSSAMOUNT_ASC_',
  /** sort on ContractGainLossAmount descending */
  ContractgainlossamountDesc = '_CONTRACTGAINLOSSAMOUNT_DESC_',
  /** Sort on Longitud ascending */
  LongitudAsc = '_LONGITUD_ASC_',
  /** sort on Longitud descending */
  LongitudDesc = '_LONGITUD_DESC_',
  /** Sort on Latitud ascending */
  LatitudAsc = '_LATITUD_ASC_',
  /** sort on Latitud descending */
  LatitudDesc = '_LATITUD_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortReturnRequestHeaderInput {
  /** Sort on GroupGUID ascending */
  GroupguidAsc = '_GROUPGUID_ASC_',
  /** sort on GroupGUID descending */
  GroupguidDesc = '_GROUPGUID_DESC_',
  /** Sort on Header ascending */
  HeaderAsc = '_HEADER_ASC_',
  /** sort on Header descending */
  HeaderDesc = '_HEADER_DESC_',
  /** Sort on TypeOfRequest ascending */
  TypeofrequestAsc = '_TYPEOFREQUEST_ASC_',
  /** sort on TypeOfRequest descending */
  TypeofrequestDesc = '_TYPEOFREQUEST_DESC_',
  /** Sort on Confirmed ascending */
  ConfirmedAsc = '_CONFIRMED_ASC_',
  /** sort on Confirmed descending */
  ConfirmedDesc = '_CONFIRMED_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on UserName ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on UserName descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on DomainName ascending */
  DomainnameAsc = '_DOMAINNAME_ASC_',
  /** sort on DomainName descending */
  DomainnameDesc = '_DOMAINNAME_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on PickUpDate ascending */
  PickupdateAsc = '_PICKUPDATE_ASC_',
  /** sort on PickUpDate descending */
  PickupdateDesc = '_PICKUPDATE_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Executed ascending */
  ExecutedAsc = '_EXECUTED_ASC_',
  /** sort on Executed descending */
  ExecutedDesc = '_EXECUTED_DESC_',
  /** Sort on DropOff ascending */
  DropoffAsc = '_DROPOFF_ASC_',
  /** sort on DropOff descending */
  DropoffDesc = '_DROPOFF_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortReturnRequestLineInput {
  /** Sort on GroupGUID ascending */
  GroupguidAsc = '_GROUPGUID_ASC_',
  /** sort on GroupGUID descending */
  GroupguidDesc = '_GROUPGUID_DESC_',
  /** Sort on LineGUID ascending */
  LineguidAsc = '_LINEGUID_ASC_',
  /** sort on LineGUID descending */
  LineguidDesc = '_LINEGUID_DESC_',
  /** Sort on Header ascending */
  HeaderAsc = '_HEADER_ASC_',
  /** sort on Header descending */
  HeaderDesc = '_HEADER_DESC_',
  /** Sort on TypeOfRequest ascending */
  TypeofrequestAsc = '_TYPEOFREQUEST_ASC_',
  /** sort on TypeOfRequest descending */
  TypeofrequestDesc = '_TYPEOFREQUEST_DESC_',
  /** Sort on Confirmed ascending */
  ConfirmedAsc = '_CONFIRMED_ASC_',
  /** sort on Confirmed descending */
  ConfirmedDesc = '_CONFIRMED_DESC_',
  /** Sort on User ascending */
  UserAsc = '_USER_ASC_',
  /** sort on User descending */
  UserDesc = '_USER_DESC_',
  /** Sort on UserName ascending */
  UsernameAsc = '_USERNAME_ASC_',
  /** sort on UserName descending */
  UsernameDesc = '_USERNAME_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on RequestDate ascending */
  RequestdateAsc = '_REQUESTDATE_ASC_',
  /** sort on RequestDate descending */
  RequestdateDesc = '_REQUESTDATE_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on ProjectNo ascending */
  ProjectnoAsc = '_PROJECTNO_ASC_',
  /** sort on ProjectNo descending */
  ProjectnoDesc = '_PROJECTNO_DESC_',
  /** Sort on Executed ascending */
  ExecutedAsc = '_EXECUTED_ASC_',
  /** sort on Executed descending */
  ExecutedDesc = '_EXECUTED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRiskAnalysisHeaderInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Template ascending */
  TemplateAsc = '_TEMPLATE_ASC_',
  /** sort on Template descending */
  TemplateDesc = '_TEMPLATE_DESC_',
  /** Sort on WorkPlace ascending */
  WorkplaceAsc = '_WORKPLACE_ASC_',
  /** sort on WorkPlace descending */
  WorkplaceDesc = '_WORKPLACE_DESC_',
  /** Sort on CreatedDate ascending */
  CreateddateAsc = '_CREATEDDATE_ASC_',
  /** sort on CreatedDate descending */
  CreateddateDesc = '_CREATEDDATE_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on CreatedByPhone ascending */
  CreatedbyphoneAsc = '_CREATEDBYPHONE_ASC_',
  /** sort on CreatedByPhone descending */
  CreatedbyphoneDesc = '_CREATEDBYPHONE_DESC_',
  /** Sort on Customer ascending */
  CustomerAsc = '_CUSTOMER_ASC_',
  /** sort on Customer descending */
  CustomerDesc = '_CUSTOMER_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on Database ascending */
  DatabaseAsc = '_DATABASE_ASC_',
  /** sort on Database descending */
  DatabaseDesc = '_DATABASE_DESC_',
  /** Sort on RecordID ascending */
  RecordidAsc = '_RECORDID_ASC_',
  /** sort on RecordID descending */
  RecordidDesc = '_RECORDID_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on CurrentHandler ascending */
  CurrenthandlerAsc = '_CURRENTHANDLER_ASC_',
  /** sort on CurrentHandler descending */
  CurrenthandlerDesc = '_CURRENTHANDLER_DESC_',
  /** Sort on CurrentHandlerEmail ascending */
  CurrenthandleremailAsc = '_CURRENTHANDLEREMAIL_ASC_',
  /** sort on CurrentHandlerEmail descending */
  CurrenthandleremailDesc = '_CURRENTHANDLEREMAIL_DESC_',
  /** Sort on CurrentHandlerPhone ascending */
  CurrenthandlerphoneAsc = '_CURRENTHANDLERPHONE_ASC_',
  /** sort on CurrentHandlerPhone descending */
  CurrenthandlerphoneDesc = '_CURRENTHANDLERPHONE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on FinishedByUser ascending */
  FinishedbyuserAsc = '_FINISHEDBYUSER_ASC_',
  /** sort on FinishedByUser descending */
  FinishedbyuserDesc = '_FINISHEDBYUSER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRiskAnalysisLineInput {
  /** Sort on RiskAnalysisNo ascending */
  RiskanalysisnoAsc = '_RISKANALYSISNO_ASC_',
  /** sort on RiskAnalysisNo descending */
  RiskanalysisnoDesc = '_RISKANALYSISNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on RiskCode ascending */
  RiskcodeAsc = '_RISKCODE_ASC_',
  /** sort on RiskCode descending */
  RiskcodeDesc = '_RISKCODE_DESC_',
  /** Sort on RiskCodeOPTION ascending */
  RiskcodeoptionAsc = '_RISKCODEOPTION_ASC_',
  /** sort on RiskCodeOPTION descending */
  RiskcodeoptionDesc = '_RISKCODEOPTION_DESC_',
  /** Sort on Risk ascending */
  RiskAsc = '_RISK_ASC_',
  /** sort on Risk descending */
  RiskDesc = '_RISK_DESC_',
  /** Sort on Action ascending */
  ActionAsc = '_ACTION_ASC_',
  /** sort on Action descending */
  ActionDesc = '_ACTION_DESC_',
  /** Sort on Probability ascending */
  ProbabilityAsc = '_PROBABILITY_ASC_',
  /** sort on Probability descending */
  ProbabilityDesc = '_PROBABILITY_DESC_',
  /** Sort on ProbabilityOPTION ascending */
  ProbabilityoptionAsc = '_PROBABILITYOPTION_ASC_',
  /** sort on ProbabilityOPTION descending */
  ProbabilityoptionDesc = '_PROBABILITYOPTION_DESC_',
  /** Sort on Consequence ascending */
  ConsequenceAsc = '_CONSEQUENCE_ASC_',
  /** sort on Consequence descending */
  ConsequenceDesc = '_CONSEQUENCE_DESC_',
  /** Sort on ConsequenceOPTION ascending */
  ConsequenceoptionAsc = '_CONSEQUENCEOPTION_ASC_',
  /** sort on ConsequenceOPTION descending */
  ConsequenceoptionDesc = '_CONSEQUENCEOPTION_DESC_',
  /** Sort on Mandatory ascending */
  MandatoryAsc = '_MANDATORY_ASC_',
  /** sort on Mandatory descending */
  MandatoryDesc = '_MANDATORY_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortRiskAnalysisSignatureInput {
  /** Sort on RiskAnalysisNo ascending */
  RiskanalysisnoAsc = '_RISKANALYSISNO_ASC_',
  /** sort on RiskAnalysisNo descending */
  RiskanalysisnoDesc = '_RISKANALYSISNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Signature ascending */
  SignatureAsc = '_SIGNATURE_ASC_',
  /** sort on Signature descending */
  SignatureDesc = '_SIGNATURE_DESC_',
  /** Sort on TypeOfSignature ascending */
  TypeofsignatureAsc = '_TYPEOFSIGNATURE_ASC_',
  /** sort on TypeOfSignature descending */
  TypeofsignatureDesc = '_TYPEOFSIGNATURE_DESC_',
  /** Sort on TypeOfSignatureOPTION ascending */
  TypeofsignatureoptionAsc = '_TYPEOFSIGNATUREOPTION_ASC_',
  /** sort on TypeOfSignatureOPTION descending */
  TypeofsignatureoptionDesc = '_TYPEOFSIGNATUREOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesHeaderArchiveInput {
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on SelltoCustomerGlobalId ascending */
  SelltocustomerglobalidAsc = '_SELLTOCUSTOMERGLOBALID_ASC_',
  /** sort on SelltoCustomerGlobalId descending */
  SelltocustomerglobalidDesc = '_SELLTOCUSTOMERGLOBALID_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPostingGroup ascending */
  CustomerpostinggroupAsc = '_CUSTOMERPOSTINGGROUP_ASC_',
  /** sort on CustomerPostingGroup descending */
  CustomerpostinggroupDesc = '_CUSTOMERPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderClass ascending */
  OrderclassAsc = '_ORDERCLASS_ASC_',
  /** sort on OrderClass descending */
  OrderclassDesc = '_ORDERCLASS_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on Ship ascending */
  ShipAsc = '_SHIP_ASC_',
  /** sort on Ship descending */
  ShipDesc = '_SHIP_DESC_',
  /** Sort on Invoice ascending */
  InvoiceAsc = '_INVOICE_ASC_',
  /** sort on Invoice descending */
  InvoiceDesc = '_INVOICE_DESC_',
  /** Sort on PrintPostedDocuments ascending */
  PrintposteddocumentsAsc = '_PRINTPOSTEDDOCUMENTS_ASC_',
  /** sort on PrintPostedDocuments descending */
  PrintposteddocumentsDesc = '_PRINTPOSTEDDOCUMENTS_DESC_',
  /** Sort on ShippingNo ascending */
  ShippingnoAsc = '_SHIPPINGNO_ASC_',
  /** sort on ShippingNo descending */
  ShippingnoDesc = '_SHIPPINGNO_DESC_',
  /** Sort on PostingNo ascending */
  PostingnoAsc = '_POSTINGNO_ASC_',
  /** sort on PostingNo descending */
  PostingnoDesc = '_POSTINGNO_DESC_',
  /** Sort on LastShippingNo ascending */
  LastshippingnoAsc = '_LASTSHIPPINGNO_ASC_',
  /** sort on LastShippingNo descending */
  LastshippingnoDesc = '_LASTSHIPPINGNO_DESC_',
  /** Sort on LastPostingNo ascending */
  LastpostingnoAsc = '_LASTPOSTINGNO_ASC_',
  /** sort on LastPostingNo descending */
  LastpostingnoDesc = '_LASTPOSTINGNO_DESC_',
  /** Sort on PrepaymentNo ascending */
  PrepaymentnoAsc = '_PREPAYMENTNO_ASC_',
  /** sort on PrepaymentNo descending */
  PrepaymentnoDesc = '_PREPAYMENTNO_DESC_',
  /** Sort on LastPrepaymentNo ascending */
  LastprepaymentnoAsc = '_LASTPREPAYMENTNO_ASC_',
  /** sort on LastPrepaymentNo descending */
  LastprepaymentnoDesc = '_LASTPREPAYMENTNO_DESC_',
  /** Sort on PrepmtCrMemoNo ascending */
  PrepmtcrmemonoAsc = '_PREPMTCRMEMONO_ASC_',
  /** sort on PrepmtCrMemoNo descending */
  PrepmtcrmemonoDesc = '_PREPMTCRMEMONO_DESC_',
  /** Sort on LastPrepmtCrMemoNo ascending */
  LastprepmtcrmemonoAsc = '_LASTPREPMTCRMEMONO_ASC_',
  /** sort on LastPrepmtCrMemoNo descending */
  LastprepmtcrmemonoDesc = '_LASTPREPMTCRMEMONO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on CombineShipments ascending */
  CombineshipmentsAsc = '_COMBINESHIPMENTS_ASC_',
  /** sort on CombineShipments descending */
  CombineshipmentsDesc = '_COMBINESHIPMENTS_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoCustomerName ascending */
  SelltocustomernameAsc = '_SELLTOCUSTOMERNAME_ASC_',
  /** sort on SelltoCustomerName descending */
  SelltocustomernameDesc = '_SELLTOCUSTOMERNAME_DESC_',
  /** Sort on SelltoCustomerName2 ascending */
  Selltocustomername2Asc = '_SELLTOCUSTOMERNAME2_ASC_',
  /** sort on SelltoCustomerName2 descending */
  Selltocustomername2Desc = '_SELLTOCUSTOMERNAME2_DESC_',
  /** Sort on SelltoAddress ascending */
  SelltoaddressAsc = '_SELLTOADDRESS_ASC_',
  /** sort on SelltoAddress descending */
  SelltoaddressDesc = '_SELLTOADDRESS_DESC_',
  /** Sort on SelltoAddress2 ascending */
  Selltoaddress2Asc = '_SELLTOADDRESS2_ASC_',
  /** sort on SelltoAddress2 descending */
  Selltoaddress2Desc = '_SELLTOADDRESS2_DESC_',
  /** Sort on SelltoCity ascending */
  SelltocityAsc = '_SELLTOCITY_ASC_',
  /** sort on SelltoCity descending */
  SelltocityDesc = '_SELLTOCITY_DESC_',
  /** Sort on SelltoContact ascending */
  SelltocontactAsc = '_SELLTOCONTACT_ASC_',
  /** sort on SelltoContact descending */
  SelltocontactDesc = '_SELLTOCONTACT_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoPostCode ascending */
  SelltopostcodeAsc = '_SELLTOPOSTCODE_ASC_',
  /** sort on SelltoPostCode descending */
  SelltopostcodeDesc = '_SELLTOPOSTCODE_DESC_',
  /** Sort on SelltoCounty ascending */
  SelltocountyAsc = '_SELLTOCOUNTY_ASC_',
  /** sort on SelltoCounty descending */
  SelltocountyDesc = '_SELLTOCOUNTY_DESC_',
  /** Sort on SelltoCountryRegionCode ascending */
  SelltocountryregioncodeAsc = '_SELLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on SelltoCountryRegionCode descending */
  SelltocountryregioncodeDesc = '_SELLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on PostingNoSeries ascending */
  PostingnoseriesAsc = '_POSTINGNOSERIES_ASC_',
  /** sort on PostingNoSeries descending */
  PostingnoseriesDesc = '_POSTINGNOSERIES_DESC_',
  /** Sort on ShippingNoSeries ascending */
  ShippingnoseriesAsc = '_SHIPPINGNOSERIES_ASC_',
  /** sort on ShippingNoSeries descending */
  ShippingnoseriesDesc = '_SHIPPINGNOSERIES_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on AppliestoID ascending */
  AppliestoidAsc = '_APPLIESTOID_ASC_',
  /** sort on AppliestoID descending */
  AppliestoidDesc = '_APPLIESTOID_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on InvoiceDiscountCalculation ascending */
  InvoicediscountcalculationAsc = '_INVOICEDISCOUNTCALCULATION_ASC_',
  /** sort on InvoiceDiscountCalculation descending */
  InvoicediscountcalculationDesc = '_INVOICEDISCOUNTCALCULATION_DESC_',
  /** Sort on InvoiceDiscountCalculationOPTION ascending */
  InvoicediscountcalculationoptionAsc = '_INVOICEDISCOUNTCALCULATIONOPTION_ASC_',
  /** sort on InvoiceDiscountCalculationOPTION descending */
  InvoicediscountcalculationoptionDesc = '_INVOICEDISCOUNTCALCULATIONOPTION_DESC_',
  /** Sort on InvoiceDiscountValue ascending */
  InvoicediscountvalueAsc = '_INVOICEDISCOUNTVALUE_ASC_',
  /** sort on InvoiceDiscountValue descending */
  InvoicediscountvalueDesc = '_INVOICEDISCOUNTVALUE_DESC_',
  /** Sort on SendICDocument ascending */
  SendicdocumentAsc = '_SENDICDOCUMENT_ASC_',
  /** sort on SendICDocument descending */
  SendicdocumentDesc = '_SENDICDOCUMENT_DESC_',
  /** Sort on ICStatus ascending */
  IcstatusAsc = '_ICSTATUS_ASC_',
  /** sort on ICStatus descending */
  IcstatusDesc = '_ICSTATUS_DESC_',
  /** Sort on ICStatusOPTION ascending */
  IcstatusoptionAsc = '_ICSTATUSOPTION_ASC_',
  /** sort on ICStatusOPTION descending */
  IcstatusoptionDesc = '_ICSTATUSOPTION_DESC_',
  /** Sort on SelltoICPartnerCode ascending */
  SelltoicpartnercodeAsc = '_SELLTOICPARTNERCODE_ASC_',
  /** sort on SelltoICPartnerCode descending */
  SelltoicpartnercodeDesc = '_SELLTOICPARTNERCODE_DESC_',
  /** Sort on BilltoICPartnerCode ascending */
  BilltoicpartnercodeAsc = '_BILLTOICPARTNERCODE_ASC_',
  /** sort on BilltoICPartnerCode descending */
  BilltoicpartnercodeDesc = '_BILLTOICPARTNERCODE_DESC_',
  /** Sort on ICDirection ascending */
  IcdirectionAsc = '_ICDIRECTION_ASC_',
  /** sort on ICDirection descending */
  IcdirectionDesc = '_ICDIRECTION_DESC_',
  /** Sort on ICDirectionOPTION ascending */
  IcdirectionoptionAsc = '_ICDIRECTIONOPTION_ASC_',
  /** sort on ICDirectionOPTION descending */
  IcdirectionoptionDesc = '_ICDIRECTIONOPTION_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on CompressPrepayment ascending */
  CompressprepaymentAsc = '_COMPRESSPREPAYMENT_ASC_',
  /** sort on CompressPrepayment descending */
  CompressprepaymentDesc = '_COMPRESSPREPAYMENT_DESC_',
  /** Sort on PrepaymentDueDate ascending */
  PrepaymentduedateAsc = '_PREPAYMENTDUEDATE_ASC_',
  /** sort on PrepaymentDueDate descending */
  PrepaymentduedateDesc = '_PREPAYMENTDUEDATE_DESC_',
  /** Sort on PrepmtCrMemoNoSeries ascending */
  PrepmtcrmemonoseriesAsc = '_PREPMTCRMEMONOSERIES_ASC_',
  /** sort on PrepmtCrMemoNoSeries descending */
  PrepmtcrmemonoseriesDesc = '_PREPMTCRMEMONOSERIES_DESC_',
  /** Sort on PrepmtPostingDescription ascending */
  PrepmtpostingdescriptionAsc = '_PREPMTPOSTINGDESCRIPTION_ASC_',
  /** sort on PrepmtPostingDescription descending */
  PrepmtpostingdescriptionDesc = '_PREPMTPOSTINGDESCRIPTION_DESC_',
  /** Sort on PrepmtPmtDiscountDate ascending */
  PrepmtpmtdiscountdateAsc = '_PREPMTPMTDISCOUNTDATE_ASC_',
  /** sort on PrepmtPmtDiscountDate descending */
  PrepmtpmtdiscountdateDesc = '_PREPMTPMTDISCOUNTDATE_DESC_',
  /** Sort on PrepmtPaymentTermsCode ascending */
  PrepmtpaymenttermscodeAsc = '_PREPMTPAYMENTTERMSCODE_ASC_',
  /** sort on PrepmtPaymentTermsCode descending */
  PrepmtpaymenttermscodeDesc = '_PREPMTPAYMENTTERMSCODE_DESC_',
  /** Sort on PrepmtPaymentDiscount ascending */
  PrepmtpaymentdiscountAsc = '_PREPMTPAYMENTDISCOUNT_ASC_',
  /** sort on PrepmtPaymentDiscount descending */
  PrepmtpaymentdiscountDesc = '_PREPMTPAYMENTDISCOUNT_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on QuoteValidUntilDate ascending */
  QuotevaliduntildateAsc = '_QUOTEVALIDUNTILDATE_ASC_',
  /** sort on QuoteValidUntilDate descending */
  QuotevaliduntildateDesc = '_QUOTEVALIDUNTILDATE_DESC_',
  /** Sort on QuoteSenttoCustomer ascending */
  QuotesenttocustomerAsc = '_QUOTESENTTOCUSTOMER_ASC_',
  /** sort on QuoteSenttoCustomer descending */
  QuotesenttocustomerDesc = '_QUOTESENTTOCUSTOMER_DESC_',
  /** Sort on QuoteAccepted ascending */
  QuoteacceptedAsc = '_QUOTEACCEPTED_ASC_',
  /** sort on QuoteAccepted descending */
  QuoteacceptedDesc = '_QUOTEACCEPTED_DESC_',
  /** Sort on QuoteAcceptedDate ascending */
  QuoteaccepteddateAsc = '_QUOTEACCEPTEDDATE_ASC_',
  /** sort on QuoteAcceptedDate descending */
  QuoteaccepteddateDesc = '_QUOTEACCEPTEDDATE_DESC_',
  /** Sort on JobQueueStatus ascending */
  JobqueuestatusAsc = '_JOBQUEUESTATUS_ASC_',
  /** sort on JobQueueStatus descending */
  JobqueuestatusDesc = '_JOBQUEUESTATUS_DESC_',
  /** Sort on JobQueueStatusOPTION ascending */
  JobqueuestatusoptionAsc = '_JOBQUEUESTATUSOPTION_ASC_',
  /** sort on JobQueueStatusOPTION descending */
  JobqueuestatusoptionDesc = '_JOBQUEUESTATUSOPTION_DESC_',
  /** Sort on JobQueueEntryID ascending */
  JobqueueentryidAsc = '_JOBQUEUEENTRYID_ASC_',
  /** sort on JobQueueEntryID descending */
  JobqueueentryidDesc = '_JOBQUEUEENTRYID_DESC_',
  /** Sort on IncomingDocumentEntryNo ascending */
  IncomingdocumententrynoAsc = '_INCOMINGDOCUMENTENTRYNO_ASC_',
  /** sort on IncomingDocumentEntryNo descending */
  IncomingdocumententrynoDesc = '_INCOMINGDOCUMENTENTRYNO_DESC_',
  /** Sort on WorkDescription ascending */
  WorkdescriptionAsc = '_WORKDESCRIPTION_ASC_',
  /** sort on WorkDescription descending */
  WorkdescriptionDesc = '_WORKDESCRIPTION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on PaymentServiceSetID ascending */
  PaymentservicesetidAsc = '_PAYMENTSERVICESETID_ASC_',
  /** sort on PaymentServiceSetID descending */
  PaymentservicesetidDesc = '_PAYMENTSERVICESETID_DESC_',
  /** Sort on DirectDebitMandateID ascending */
  DirectdebitmandateidAsc = '_DIRECTDEBITMANDATEID_ASC_',
  /** sort on DirectDebitMandateID descending */
  DirectdebitmandateidDesc = '_DIRECTDEBITMANDATEID_DESC_',
  /** Sort on DocNoOccurrence ascending */
  DocnooccurrenceAsc = '_DOCNOOCCURRENCE_ASC_',
  /** sort on DocNoOccurrence descending */
  DocnooccurrenceDesc = '_DOCNOOCCURRENCE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on SelltoCustomerTemplateCode ascending */
  SelltocustomertemplatecodeAsc = '_SELLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on SelltoCustomerTemplateCode descending */
  SelltocustomertemplatecodeDesc = '_SELLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on SelltoContactNo ascending */
  SelltocontactnoAsc = '_SELLTOCONTACTNO_ASC_',
  /** sort on SelltoContactNo descending */
  SelltocontactnoDesc = '_SELLTOCONTACTNO_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on BilltoCustomerTemplateCode ascending */
  BilltocustomertemplatecodeAsc = '_BILLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on BilltoCustomerTemplateCode descending */
  BilltocustomertemplatecodeDesc = '_BILLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on OpportunityNo ascending */
  OpportunitynoAsc = '_OPPORTUNITYNO_ASC_',
  /** sort on OpportunityNo descending */
  OpportunitynoDesc = '_OPPORTUNITYNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on PostingfromWhseRef ascending */
  PostingfromwhserefAsc = '_POSTINGFROMWHSEREF_ASC_',
  /** sort on PostingfromWhseRef descending */
  PostingfromwhserefDesc = '_POSTINGFROMWHSEREF_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on Receive ascending */
  ReceiveAsc = '_RECEIVE_ASC_',
  /** sort on Receive descending */
  ReceiveDesc = '_RECEIVE_DESC_',
  /** Sort on ReturnReceiptNo ascending */
  ReturnreceiptnoAsc = '_RETURNRECEIPTNO_ASC_',
  /** sort on ReturnReceiptNo descending */
  ReturnreceiptnoDesc = '_RETURNRECEIPTNO_DESC_',
  /** Sort on ReturnReceiptNoSeries ascending */
  ReturnreceiptnoseriesAsc = '_RETURNRECEIPTNOSERIES_ASC_',
  /** sort on ReturnReceiptNoSeries descending */
  ReturnreceiptnoseriesDesc = '_RETURNRECEIPTNOSERIES_DESC_',
  /** Sort on LastReturnReceiptNo ascending */
  LastreturnreceiptnoAsc = '_LASTRETURNRECEIPTNO_ASC_',
  /** sort on LastReturnReceiptNo descending */
  LastreturnreceiptnoDesc = '_LASTRETURNRECEIPTNO_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on GetShipmentUsed ascending */
  GetshipmentusedAsc = '_GETSHIPMENTUSED_ASC_',
  /** sort on GetShipmentUsed descending */
  GetshipmentusedDesc = '_GETSHIPMENTUSED_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on SourceInvNo ascending */
  SourceinvnoAsc = '_SOURCEINVNO_ASC_',
  /** sort on SourceInvNo descending */
  SourceinvnoDesc = '_SOURCEINVNO_DESC_',
  /** Sort on SourceInvVAT ascending */
  SourceinvvatAsc = '_SOURCEINVVAT_ASC_',
  /** sort on SourceInvVAT descending */
  SourceinvvatDesc = '_SOURCEINVVAT_DESC_',
  /** Sort on SourceInvTotal ascending */
  SourceinvtotalAsc = '_SOURCEINVTOTAL_ASC_',
  /** sort on SourceInvTotal descending */
  SourceinvtotalDesc = '_SOURCEINVTOTAL_DESC_',
  /** Sort on NoteofGoods ascending */
  NoteofgoodsAsc = '_NOTEOFGOODS_ASC_',
  /** sort on NoteofGoods descending */
  NoteofgoodsDesc = '_NOTEOFGOODS_DESC_',
  /** Sort on EQMShipmentType ascending */
  EqmshipmenttypeAsc = '_EQMSHIPMENTTYPE_ASC_',
  /** sort on EQMShipmentType descending */
  EqmshipmenttypeDesc = '_EQMSHIPMENTTYPE_DESC_',
  /** Sort on EQMShipmentTypeOPTION ascending */
  EqmshipmenttypeoptionAsc = '_EQMSHIPMENTTYPEOPTION_ASC_',
  /** sort on EQMShipmentTypeOPTION descending */
  EqmshipmenttypeoptionDesc = '_EQMSHIPMENTTYPEOPTION_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMCustomerType ascending */
  EqmcustomertypeAsc = '_EQMCUSTOMERTYPE_ASC_',
  /** sort on EQMCustomerType descending */
  EqmcustomertypeDesc = '_EQMCUSTOMERTYPE_DESC_',
  /** Sort on EQMCustomerTypeOPTION ascending */
  EqmcustomertypeoptionAsc = '_EQMCUSTOMERTYPEOPTION_ASC_',
  /** sort on EQMCustomerTypeOPTION descending */
  EqmcustomertypeoptionDesc = '_EQMCUSTOMERTYPEOPTION_DESC_',
  /** Sort on EQMRentalDocument ascending */
  EqmrentaldocumentAsc = '_EQMRENTALDOCUMENT_ASC_',
  /** sort on EQMRentalDocument descending */
  EqmrentaldocumentDesc = '_EQMRENTALDOCUMENT_DESC_',
  /** Sort on EQMTransferMethod ascending */
  EqmtransfermethodAsc = '_EQMTRANSFERMETHOD_ASC_',
  /** sort on EQMTransferMethod descending */
  EqmtransfermethodDesc = '_EQMTRANSFERMETHOD_DESC_',
  /** Sort on EQMTransferMethodOPTION ascending */
  EqmtransfermethodoptionAsc = '_EQMTRANSFERMETHODOPTION_ASC_',
  /** sort on EQMTransferMethodOPTION descending */
  EqmtransfermethodoptionDesc = '_EQMTRANSFERMETHODOPTION_DESC_',
  /** Sort on EQMContraryDoc ascending */
  EqmcontrarydocAsc = '_EQMCONTRARYDOC_ASC_',
  /** sort on EQMContraryDoc descending */
  EqmcontrarydocDesc = '_EQMCONTRARYDOC_DESC_',
  /** Sort on EQMWorkshopDocument ascending */
  EqmworkshopdocumentAsc = '_EQMWORKSHOPDOCUMENT_ASC_',
  /** sort on EQMWorkshopDocument descending */
  EqmworkshopdocumentDesc = '_EQMWORKSHOPDOCUMENT_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceTermNo ascending */
  EqmservicetermnoAsc = '_EQMSERVICETERMNO_ASC_',
  /** sort on EQMServiceTermNo descending */
  EqmservicetermnoDesc = '_EQMSERVICETERMNO_DESC_',
  /** Sort on EQMCombineCustomerProject ascending */
  EqmcombinecustomerprojectAsc = '_EQMCOMBINECUSTOMERPROJECT_ASC_',
  /** sort on EQMCombineCustomerProject descending */
  EqmcombinecustomerprojectDesc = '_EQMCOMBINECUSTOMERPROJECT_DESC_',
  /** Sort on EQMInvoiceDocType ascending */
  EqminvoicedoctypeAsc = '_EQMINVOICEDOCTYPE_ASC_',
  /** sort on EQMInvoiceDocType descending */
  EqminvoicedoctypeDesc = '_EQMINVOICEDOCTYPE_DESC_',
  /** Sort on EQMInvoiceDocTypeOPTION ascending */
  EqminvoicedoctypeoptionAsc = '_EQMINVOICEDOCTYPEOPTION_ASC_',
  /** sort on EQMInvoiceDocTypeOPTION descending */
  EqminvoicedoctypeoptionDesc = '_EQMINVOICEDOCTYPEOPTION_DESC_',
  /** Sort on EQMCashSaleDocument ascending */
  EqmcashsaledocumentAsc = '_EQMCASHSALEDOCUMENT_ASC_',
  /** sort on EQMCashSaleDocument descending */
  EqmcashsaledocumentDesc = '_EQMCASHSALEDOCUMENT_DESC_',
  /** Sort on EQMUseDeposit ascending */
  EqmusedepositAsc = '_EQMUSEDEPOSIT_ASC_',
  /** sort on EQMUseDeposit descending */
  EqmusedepositDesc = '_EQMUSEDEPOSIT_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMJobNo ascending */
  EqmjobnoAsc = '_EQMJOBNO_ASC_',
  /** sort on EQMJobNo descending */
  EqmjobnoDesc = '_EQMJOBNO_DESC_',
  /** Sort on EQMJobTaskNo ascending */
  EqmjobtasknoAsc = '_EQMJOBTASKNO_ASC_',
  /** sort on EQMJobTaskNo descending */
  EqmjobtasknoDesc = '_EQMJOBTASKNO_DESC_',
  /** Sort on EQMWSObjectCounter ascending */
  EqmwsobjectcounterAsc = '_EQMWSOBJECTCOUNTER_ASC_',
  /** sort on EQMWSObjectCounter descending */
  EqmwsobjectcounterDesc = '_EQMWSOBJECTCOUNTER_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMServiceStartDate ascending */
  EqmservicestartdateAsc = '_EQMSERVICESTARTDATE_ASC_',
  /** sort on EQMServiceStartDate descending */
  EqmservicestartdateDesc = '_EQMSERVICESTARTDATE_DESC_',
  /** Sort on EQMServiceEndDate ascending */
  EqmserviceenddateAsc = '_EQMSERVICEENDDATE_ASC_',
  /** sort on EQMServiceEndDate descending */
  EqmserviceenddateDesc = '_EQMSERVICEENDDATE_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMServiceStatusOPTION ascending */
  EqmservicestatusoptionAsc = '_EQMSERVICESTATUSOPTION_ASC_',
  /** sort on EQMServiceStatusOPTION descending */
  EqmservicestatusoptionDesc = '_EQMSERVICESTATUSOPTION_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMWSEstimatedStartDate ascending */
  EqmwsestimatedstartdateAsc = '_EQMWSESTIMATEDSTARTDATE_ASC_',
  /** sort on EQMWSEstimatedStartDate descending */
  EqmwsestimatedstartdateDesc = '_EQMWSESTIMATEDSTARTDATE_DESC_',
  /** Sort on EQMWSEstimatedStartTime ascending */
  EqmwsestimatedstarttimeAsc = '_EQMWSESTIMATEDSTARTTIME_ASC_',
  /** sort on EQMWSEstimatedStartTime descending */
  EqmwsestimatedstarttimeDesc = '_EQMWSESTIMATEDSTARTTIME_DESC_',
  /** Sort on EQMWSEstimatedEndDate ascending */
  EqmwsestimatedenddateAsc = '_EQMWSESTIMATEDENDDATE_ASC_',
  /** sort on EQMWSEstimatedEndDate descending */
  EqmwsestimatedenddateDesc = '_EQMWSESTIMATEDENDDATE_DESC_',
  /** Sort on EQMWSEstimatedEndTime ascending */
  EqmwsestimatedendtimeAsc = '_EQMWSESTIMATEDENDTIME_ASC_',
  /** sort on EQMWSEstimatedEndTime descending */
  EqmwsestimatedendtimeDesc = '_EQMWSESTIMATEDENDTIME_DESC_',
  /** Sort on EQMServiceStartTime ascending */
  EqmservicestarttimeAsc = '_EQMSERVICESTARTTIME_ASC_',
  /** sort on EQMServiceStartTime descending */
  EqmservicestarttimeDesc = '_EQMSERVICESTARTTIME_DESC_',
  /** Sort on EQMServiceEndTime ascending */
  EqmserviceendtimeAsc = '_EQMSERVICEENDTIME_ASC_',
  /** sort on EQMServiceEndTime descending */
  EqmserviceendtimeDesc = '_EQMSERVICEENDTIME_DESC_',
  /** Sort on EQMWSObjectCounter2 ascending */
  Eqmwsobjectcounter2Asc = '_EQMWSOBJECTCOUNTER2_ASC_',
  /** sort on EQMWSObjectCounter2 descending */
  Eqmwsobjectcounter2Desc = '_EQMWSOBJECTCOUNTER2_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on LastEmailSentTime ascending */
  LastemailsenttimeAsc = '_LASTEMAILSENTTIME_ASC_',
  /** sort on LastEmailSentTime descending */
  LastemailsenttimeDesc = '_LASTEMAILSENTTIME_DESC_',
  /** Sort on LastEmailSentStatus ascending */
  LastemailsentstatusAsc = '_LASTEMAILSENTSTATUS_ASC_',
  /** sort on LastEmailSentStatus descending */
  LastemailsentstatusDesc = '_LASTEMAILSENTSTATUS_DESC_',
  /** Sort on LastEmailSentStatusOPTION ascending */
  LastemailsentstatusoptionAsc = '_LASTEMAILSENTSTATUSOPTION_ASC_',
  /** sort on LastEmailSentStatusOPTION descending */
  LastemailsentstatusoptionDesc = '_LASTEMAILSENTSTATUSOPTION_DESC_',
  /** Sort on SentasEmail ascending */
  SentasemailAsc = '_SENTASEMAIL_ASC_',
  /** sort on SentasEmail descending */
  SentasemailDesc = '_SENTASEMAIL_DESC_',
  /** Sort on LastEmailNotifCleared ascending */
  LastemailnotifclearedAsc = '_LASTEMAILNOTIFCLEARED_ASC_',
  /** sort on LastEmailNotifCleared descending */
  LastemailnotifclearedDesc = '_LASTEMAILNOTIFCLEARED_DESC_',
  /** Sort on AmtShipNotInvLCY ascending */
  AmtshipnotinvlcyAsc = '_AMTSHIPNOTINVLCY_ASC_',
  /** sort on AmtShipNotInvLCY descending */
  AmtshipnotinvlcyDesc = '_AMTSHIPNOTINVLCY_DESC_',
  /** Sort on AmtShipNotInvLCYBase ascending */
  AmtshipnotinvlcybaseAsc = '_AMTSHIPNOTINVLCYBASE_ASC_',
  /** sort on AmtShipNotInvLCYBase descending */
  AmtshipnotinvlcybaseDesc = '_AMTSHIPNOTINVLCYBASE_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on NoofArchivedVersions ascending */
  NoofarchivedversionsAsc = '_NOOFARCHIVEDVERSIONS_ASC_',
  /** sort on NoofArchivedVersions descending */
  NoofarchivedversionsDesc = '_NOOFARCHIVEDVERSIONS_DESC_',
  /** Sort on ShippedNotInvoiced ascending */
  ShippednotinvoicedAsc = '_SHIPPEDNOTINVOICED_ASC_',
  /** sort on ShippedNotInvoiced descending */
  ShippednotinvoicedDesc = '_SHIPPEDNOTINVOICED_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on Shipped ascending */
  ShippedAsc = '_SHIPPED_ASC_',
  /** sort on Shipped descending */
  ShippedDesc = '_SHIPPED_DESC_',
  /** Sort on LateOrderShipping ascending */
  LateordershippingAsc = '_LATEORDERSHIPPING_ASC_',
  /** sort on LateOrderShipping descending */
  LateordershippingDesc = '_LATEORDERSHIPPING_DESC_',
  /** Sort on EQMObjectWarrantyDate ascending */
  EqmobjectwarrantydateAsc = '_EQMOBJECTWARRANTYDATE_ASC_',
  /** sort on EQMObjectWarrantyDate descending */
  EqmobjectwarrantydateDesc = '_EQMOBJECTWARRANTYDATE_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on BaseSubTotal ascending */
  BasesubtotalAsc = '_BASESUBTOTAL_ASC_',
  /** sort on BaseSubTotal descending */
  BasesubtotalDesc = '_BASESUBTOTAL_DESC_',
  /** Sort on BaseSubTotalInclTax ascending */
  BasesubtotalincltaxAsc = '_BASESUBTOTALINCLTAX_ASC_',
  /** sort on BaseSubTotalInclTax descending */
  BasesubtotalincltaxDesc = '_BASESUBTOTALINCLTAX_DESC_',
  /** Sort on ShippingDescription ascending */
  ShippingdescriptionAsc = '_SHIPPINGDESCRIPTION_ASC_',
  /** sort on ShippingDescription descending */
  ShippingdescriptionDesc = '_SHIPPINGDESCRIPTION_DESC_',
  /** Sort on CustomerNote ascending */
  CustomernoteAsc = '_CUSTOMERNOTE_ASC_',
  /** sort on CustomerNote descending */
  CustomernoteDesc = '_CUSTOMERNOTE_DESC_',
  /** Sort on CustomerEmail ascending */
  CustomeremailAsc = '_CUSTOMEREMAIL_ASC_',
  /** sort on CustomerEmail descending */
  CustomeremailDesc = '_CUSTOMEREMAIL_DESC_',
  /** Sort on BilltoEmail ascending */
  BilltoemailAsc = '_BILLTOEMAIL_ASC_',
  /** sort on BilltoEmail descending */
  BilltoemailDesc = '_BILLTOEMAIL_DESC_',
  /** Sort on BilltoTelephone ascending */
  BilltotelephoneAsc = '_BILLTOTELEPHONE_ASC_',
  /** sort on BilltoTelephone descending */
  BilltotelephoneDesc = '_BILLTOTELEPHONE_DESC_',
  /** Sort on ShipToId ascending */
  ShiptoidAsc = '_SHIPTOID_ASC_',
  /** sort on ShipToId descending */
  ShiptoidDesc = '_SHIPTOID_DESC_',
  /** Sort on ShiptoEmail ascending */
  ShiptoemailAsc = '_SHIPTOEMAIL_ASC_',
  /** sort on ShiptoEmail descending */
  ShiptoemailDesc = '_SHIPTOEMAIL_DESC_',
  /** Sort on ShiptoTelephone ascending */
  ShiptotelephoneAsc = '_SHIPTOTELEPHONE_ASC_',
  /** sort on ShiptoTelephone descending */
  ShiptotelephoneDesc = '_SHIPTOTELEPHONE_DESC_',
  /** Sort on MagentoOrderStatus ascending */
  MagentoorderstatusAsc = '_MAGENTOORDERSTATUS_ASC_',
  /** sort on MagentoOrderStatus descending */
  MagentoorderstatusDesc = '_MAGENTOORDERSTATUS_DESC_',
  /** Sort on MagentoOrderState ascending */
  MagentoorderstateAsc = '_MAGENTOORDERSTATE_ASC_',
  /** sort on MagentoOrderState descending */
  MagentoorderstateDesc = '_MAGENTOORDERSTATE_DESC_',
  /** Sort on LineCount ascending */
  LinecountAsc = '_LINECOUNT_ASC_',
  /** sort on LineCount descending */
  LinecountDesc = '_LINECOUNT_DESC_',
  /** Sort on FreightIncluded ascending */
  FreightincludedAsc = '_FREIGHTINCLUDED_ASC_',
  /** sort on FreightIncluded descending */
  FreightincludedDesc = '_FREIGHTINCLUDED_DESC_',
  /** Sort on DiscountIncluded ascending */
  DiscountincludedAsc = '_DISCOUNTINCLUDED_ASC_',
  /** sort on DiscountIncluded descending */
  DiscountincludedDesc = '_DISCOUNTINCLUDED_DESC_',
  /** Sort on DeliveryMethod ascending */
  DeliverymethodAsc = '_DELIVERYMETHOD_ASC_',
  /** sort on DeliveryMethod descending */
  DeliverymethodDesc = '_DELIVERYMETHOD_DESC_',
  /** Sort on ProcessedByEdge ascending */
  ProcessedbyedgeAsc = '_PROCESSEDBYEDGE_ASC_',
  /** sort on ProcessedByEdge descending */
  ProcessedbyedgeDesc = '_PROCESSEDBYEDGE_DESC_',
  /** Sort on EdgeStartedPollingOn ascending */
  EdgestartedpollingonAsc = '_EDGESTARTEDPOLLINGON_ASC_',
  /** sort on EdgeStartedPollingOn descending */
  EdgestartedpollingonDesc = '_EDGESTARTEDPOLLINGON_DESC_',
  /** Sort on HandleRelationsInJSONStructure ascending */
  HandlerelationsinjsonstructureAsc = '_HANDLERELATIONSINJSONSTRUCTURE_ASC_',
  /** sort on HandleRelationsInJSONStructure descending */
  HandlerelationsinjsonstructureDesc = '_HANDLERELATIONSINJSONSTRUCTURE_DESC_',
  /** Sort on WebbComment ascending */
  WebbcommentAsc = '_WEBBCOMMENT_ASC_',
  /** sort on WebbComment descending */
  WebbcommentDesc = '_WEBBCOMMENT_DESC_',
  /** Sort on SalesOrderEdgeGuid ascending */
  SalesorderedgeguidAsc = '_SALESORDEREDGEGUID_ASC_',
  /** sort on SalesOrderEdgeGuid descending */
  SalesorderedgeguidDesc = '_SALESORDEREDGEGUID_DESC_',
  /** Sort on VersionNo ascending */
  VersionnoAsc = '_VERSIONNO_ASC_',
  /** sort on VersionNo descending */
  VersionnoDesc = '_VERSIONNO_DESC_',
  /** Sort on DeletedReason ascending */
  DeletedreasonAsc = '_DELETEDREASON_ASC_',
  /** sort on DeletedReason descending */
  DeletedreasonDesc = '_DELETEDREASON_DESC_',
  /** Sort on IsConstructed ascending */
  IsconstructedAsc = '_ISCONSTRUCTED_ASC_',
  /** sort on IsConstructed descending */
  IsconstructedDesc = '_ISCONSTRUCTED_DESC_',
  /** Sort on PaymentMethodWeb ascending */
  PaymentmethodwebAsc = '_PAYMENTMETHODWEB_ASC_',
  /** sort on PaymentMethodWeb descending */
  PaymentmethodwebDesc = '_PAYMENTMETHODWEB_DESC_',
  /** Sort on SharkActualPrice ascending */
  SharkactualpriceAsc = '_SHARKACTUALPRICE_ASC_',
  /** sort on SharkActualPrice descending */
  SharkactualpriceDesc = '_SHARKACTUALPRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesHeaderHistoryInput {
  /** Sort on GUID ascending */
  GuidAsc = '_GUID_ASC_',
  /** sort on GUID descending */
  GuidDesc = '_GUID_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesHeaderInput {
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on SelltoCustomerGlobalId ascending */
  SelltocustomerglobalidAsc = '_SELLTOCUSTOMERGLOBALID_ASC_',
  /** sort on SelltoCustomerGlobalId descending */
  SelltocustomerglobalidDesc = '_SELLTOCUSTOMERGLOBALID_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPostingGroup ascending */
  CustomerpostinggroupAsc = '_CUSTOMERPOSTINGGROUP_ASC_',
  /** sort on CustomerPostingGroup descending */
  CustomerpostinggroupDesc = '_CUSTOMERPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderClass ascending */
  OrderclassAsc = '_ORDERCLASS_ASC_',
  /** sort on OrderClass descending */
  OrderclassDesc = '_ORDERCLASS_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on Ship ascending */
  ShipAsc = '_SHIP_ASC_',
  /** sort on Ship descending */
  ShipDesc = '_SHIP_DESC_',
  /** Sort on Invoice ascending */
  InvoiceAsc = '_INVOICE_ASC_',
  /** sort on Invoice descending */
  InvoiceDesc = '_INVOICE_DESC_',
  /** Sort on PrintPostedDocuments ascending */
  PrintposteddocumentsAsc = '_PRINTPOSTEDDOCUMENTS_ASC_',
  /** sort on PrintPostedDocuments descending */
  PrintposteddocumentsDesc = '_PRINTPOSTEDDOCUMENTS_DESC_',
  /** Sort on ShippingNo ascending */
  ShippingnoAsc = '_SHIPPINGNO_ASC_',
  /** sort on ShippingNo descending */
  ShippingnoDesc = '_SHIPPINGNO_DESC_',
  /** Sort on PostingNo ascending */
  PostingnoAsc = '_POSTINGNO_ASC_',
  /** sort on PostingNo descending */
  PostingnoDesc = '_POSTINGNO_DESC_',
  /** Sort on LastShippingNo ascending */
  LastshippingnoAsc = '_LASTSHIPPINGNO_ASC_',
  /** sort on LastShippingNo descending */
  LastshippingnoDesc = '_LASTSHIPPINGNO_DESC_',
  /** Sort on LastPostingNo ascending */
  LastpostingnoAsc = '_LASTPOSTINGNO_ASC_',
  /** sort on LastPostingNo descending */
  LastpostingnoDesc = '_LASTPOSTINGNO_DESC_',
  /** Sort on PrepaymentNo ascending */
  PrepaymentnoAsc = '_PREPAYMENTNO_ASC_',
  /** sort on PrepaymentNo descending */
  PrepaymentnoDesc = '_PREPAYMENTNO_DESC_',
  /** Sort on LastPrepaymentNo ascending */
  LastprepaymentnoAsc = '_LASTPREPAYMENTNO_ASC_',
  /** sort on LastPrepaymentNo descending */
  LastprepaymentnoDesc = '_LASTPREPAYMENTNO_DESC_',
  /** Sort on PrepmtCrMemoNo ascending */
  PrepmtcrmemonoAsc = '_PREPMTCRMEMONO_ASC_',
  /** sort on PrepmtCrMemoNo descending */
  PrepmtcrmemonoDesc = '_PREPMTCRMEMONO_DESC_',
  /** Sort on LastPrepmtCrMemoNo ascending */
  LastprepmtcrmemonoAsc = '_LASTPREPMTCRMEMONO_ASC_',
  /** sort on LastPrepmtCrMemoNo descending */
  LastprepmtcrmemonoDesc = '_LASTPREPMTCRMEMONO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on CombineShipments ascending */
  CombineshipmentsAsc = '_COMBINESHIPMENTS_ASC_',
  /** sort on CombineShipments descending */
  CombineshipmentsDesc = '_COMBINESHIPMENTS_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoCustomerName ascending */
  SelltocustomernameAsc = '_SELLTOCUSTOMERNAME_ASC_',
  /** sort on SelltoCustomerName descending */
  SelltocustomernameDesc = '_SELLTOCUSTOMERNAME_DESC_',
  /** Sort on SelltoCustomerName2 ascending */
  Selltocustomername2Asc = '_SELLTOCUSTOMERNAME2_ASC_',
  /** sort on SelltoCustomerName2 descending */
  Selltocustomername2Desc = '_SELLTOCUSTOMERNAME2_DESC_',
  /** Sort on SelltoAddress ascending */
  SelltoaddressAsc = '_SELLTOADDRESS_ASC_',
  /** sort on SelltoAddress descending */
  SelltoaddressDesc = '_SELLTOADDRESS_DESC_',
  /** Sort on SelltoAddress2 ascending */
  Selltoaddress2Asc = '_SELLTOADDRESS2_ASC_',
  /** sort on SelltoAddress2 descending */
  Selltoaddress2Desc = '_SELLTOADDRESS2_DESC_',
  /** Sort on SelltoCity ascending */
  SelltocityAsc = '_SELLTOCITY_ASC_',
  /** sort on SelltoCity descending */
  SelltocityDesc = '_SELLTOCITY_DESC_',
  /** Sort on SelltoContact ascending */
  SelltocontactAsc = '_SELLTOCONTACT_ASC_',
  /** sort on SelltoContact descending */
  SelltocontactDesc = '_SELLTOCONTACT_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoPostCode ascending */
  SelltopostcodeAsc = '_SELLTOPOSTCODE_ASC_',
  /** sort on SelltoPostCode descending */
  SelltopostcodeDesc = '_SELLTOPOSTCODE_DESC_',
  /** Sort on SelltoCounty ascending */
  SelltocountyAsc = '_SELLTOCOUNTY_ASC_',
  /** sort on SelltoCounty descending */
  SelltocountyDesc = '_SELLTOCOUNTY_DESC_',
  /** Sort on SelltoCountryRegionCode ascending */
  SelltocountryregioncodeAsc = '_SELLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on SelltoCountryRegionCode descending */
  SelltocountryregioncodeDesc = '_SELLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on PostingNoSeries ascending */
  PostingnoseriesAsc = '_POSTINGNOSERIES_ASC_',
  /** sort on PostingNoSeries descending */
  PostingnoseriesDesc = '_POSTINGNOSERIES_DESC_',
  /** Sort on ShippingNoSeries ascending */
  ShippingnoseriesAsc = '_SHIPPINGNOSERIES_ASC_',
  /** sort on ShippingNoSeries descending */
  ShippingnoseriesDesc = '_SHIPPINGNOSERIES_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on AppliestoID ascending */
  AppliestoidAsc = '_APPLIESTOID_ASC_',
  /** sort on AppliestoID descending */
  AppliestoidDesc = '_APPLIESTOID_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on InvoiceDiscountCalculation ascending */
  InvoicediscountcalculationAsc = '_INVOICEDISCOUNTCALCULATION_ASC_',
  /** sort on InvoiceDiscountCalculation descending */
  InvoicediscountcalculationDesc = '_INVOICEDISCOUNTCALCULATION_DESC_',
  /** Sort on InvoiceDiscountCalculationOPTION ascending */
  InvoicediscountcalculationoptionAsc = '_INVOICEDISCOUNTCALCULATIONOPTION_ASC_',
  /** sort on InvoiceDiscountCalculationOPTION descending */
  InvoicediscountcalculationoptionDesc = '_INVOICEDISCOUNTCALCULATIONOPTION_DESC_',
  /** Sort on InvoiceDiscountValue ascending */
  InvoicediscountvalueAsc = '_INVOICEDISCOUNTVALUE_ASC_',
  /** sort on InvoiceDiscountValue descending */
  InvoicediscountvalueDesc = '_INVOICEDISCOUNTVALUE_DESC_',
  /** Sort on SendICDocument ascending */
  SendicdocumentAsc = '_SENDICDOCUMENT_ASC_',
  /** sort on SendICDocument descending */
  SendicdocumentDesc = '_SENDICDOCUMENT_DESC_',
  /** Sort on ICStatus ascending */
  IcstatusAsc = '_ICSTATUS_ASC_',
  /** sort on ICStatus descending */
  IcstatusDesc = '_ICSTATUS_DESC_',
  /** Sort on ICStatusOPTION ascending */
  IcstatusoptionAsc = '_ICSTATUSOPTION_ASC_',
  /** sort on ICStatusOPTION descending */
  IcstatusoptionDesc = '_ICSTATUSOPTION_DESC_',
  /** Sort on SelltoICPartnerCode ascending */
  SelltoicpartnercodeAsc = '_SELLTOICPARTNERCODE_ASC_',
  /** sort on SelltoICPartnerCode descending */
  SelltoicpartnercodeDesc = '_SELLTOICPARTNERCODE_DESC_',
  /** Sort on BilltoICPartnerCode ascending */
  BilltoicpartnercodeAsc = '_BILLTOICPARTNERCODE_ASC_',
  /** sort on BilltoICPartnerCode descending */
  BilltoicpartnercodeDesc = '_BILLTOICPARTNERCODE_DESC_',
  /** Sort on ICDirection ascending */
  IcdirectionAsc = '_ICDIRECTION_ASC_',
  /** sort on ICDirection descending */
  IcdirectionDesc = '_ICDIRECTION_DESC_',
  /** Sort on ICDirectionOPTION ascending */
  IcdirectionoptionAsc = '_ICDIRECTIONOPTION_ASC_',
  /** sort on ICDirectionOPTION descending */
  IcdirectionoptionDesc = '_ICDIRECTIONOPTION_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on CompressPrepayment ascending */
  CompressprepaymentAsc = '_COMPRESSPREPAYMENT_ASC_',
  /** sort on CompressPrepayment descending */
  CompressprepaymentDesc = '_COMPRESSPREPAYMENT_DESC_',
  /** Sort on PrepaymentDueDate ascending */
  PrepaymentduedateAsc = '_PREPAYMENTDUEDATE_ASC_',
  /** sort on PrepaymentDueDate descending */
  PrepaymentduedateDesc = '_PREPAYMENTDUEDATE_DESC_',
  /** Sort on PrepmtCrMemoNoSeries ascending */
  PrepmtcrmemonoseriesAsc = '_PREPMTCRMEMONOSERIES_ASC_',
  /** sort on PrepmtCrMemoNoSeries descending */
  PrepmtcrmemonoseriesDesc = '_PREPMTCRMEMONOSERIES_DESC_',
  /** Sort on PrepmtPostingDescription ascending */
  PrepmtpostingdescriptionAsc = '_PREPMTPOSTINGDESCRIPTION_ASC_',
  /** sort on PrepmtPostingDescription descending */
  PrepmtpostingdescriptionDesc = '_PREPMTPOSTINGDESCRIPTION_DESC_',
  /** Sort on PrepmtPmtDiscountDate ascending */
  PrepmtpmtdiscountdateAsc = '_PREPMTPMTDISCOUNTDATE_ASC_',
  /** sort on PrepmtPmtDiscountDate descending */
  PrepmtpmtdiscountdateDesc = '_PREPMTPMTDISCOUNTDATE_DESC_',
  /** Sort on PrepmtPaymentTermsCode ascending */
  PrepmtpaymenttermscodeAsc = '_PREPMTPAYMENTTERMSCODE_ASC_',
  /** sort on PrepmtPaymentTermsCode descending */
  PrepmtpaymenttermscodeDesc = '_PREPMTPAYMENTTERMSCODE_DESC_',
  /** Sort on PrepmtPaymentDiscount ascending */
  PrepmtpaymentdiscountAsc = '_PREPMTPAYMENTDISCOUNT_ASC_',
  /** sort on PrepmtPaymentDiscount descending */
  PrepmtpaymentdiscountDesc = '_PREPMTPAYMENTDISCOUNT_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on QuoteValidUntilDate ascending */
  QuotevaliduntildateAsc = '_QUOTEVALIDUNTILDATE_ASC_',
  /** sort on QuoteValidUntilDate descending */
  QuotevaliduntildateDesc = '_QUOTEVALIDUNTILDATE_DESC_',
  /** Sort on QuoteSenttoCustomer ascending */
  QuotesenttocustomerAsc = '_QUOTESENTTOCUSTOMER_ASC_',
  /** sort on QuoteSenttoCustomer descending */
  QuotesenttocustomerDesc = '_QUOTESENTTOCUSTOMER_DESC_',
  /** Sort on QuoteAccepted ascending */
  QuoteacceptedAsc = '_QUOTEACCEPTED_ASC_',
  /** sort on QuoteAccepted descending */
  QuoteacceptedDesc = '_QUOTEACCEPTED_DESC_',
  /** Sort on QuoteAcceptedDate ascending */
  QuoteaccepteddateAsc = '_QUOTEACCEPTEDDATE_ASC_',
  /** sort on QuoteAcceptedDate descending */
  QuoteaccepteddateDesc = '_QUOTEACCEPTEDDATE_DESC_',
  /** Sort on JobQueueStatus ascending */
  JobqueuestatusAsc = '_JOBQUEUESTATUS_ASC_',
  /** sort on JobQueueStatus descending */
  JobqueuestatusDesc = '_JOBQUEUESTATUS_DESC_',
  /** Sort on JobQueueStatusOPTION ascending */
  JobqueuestatusoptionAsc = '_JOBQUEUESTATUSOPTION_ASC_',
  /** sort on JobQueueStatusOPTION descending */
  JobqueuestatusoptionDesc = '_JOBQUEUESTATUSOPTION_DESC_',
  /** Sort on JobQueueEntryID ascending */
  JobqueueentryidAsc = '_JOBQUEUEENTRYID_ASC_',
  /** sort on JobQueueEntryID descending */
  JobqueueentryidDesc = '_JOBQUEUEENTRYID_DESC_',
  /** Sort on IncomingDocumentEntryNo ascending */
  IncomingdocumententrynoAsc = '_INCOMINGDOCUMENTENTRYNO_ASC_',
  /** sort on IncomingDocumentEntryNo descending */
  IncomingdocumententrynoDesc = '_INCOMINGDOCUMENTENTRYNO_DESC_',
  /** Sort on WorkDescription ascending */
  WorkdescriptionAsc = '_WORKDESCRIPTION_ASC_',
  /** sort on WorkDescription descending */
  WorkdescriptionDesc = '_WORKDESCRIPTION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on PaymentServiceSetID ascending */
  PaymentservicesetidAsc = '_PAYMENTSERVICESETID_ASC_',
  /** sort on PaymentServiceSetID descending */
  PaymentservicesetidDesc = '_PAYMENTSERVICESETID_DESC_',
  /** Sort on DirectDebitMandateID ascending */
  DirectdebitmandateidAsc = '_DIRECTDEBITMANDATEID_ASC_',
  /** sort on DirectDebitMandateID descending */
  DirectdebitmandateidDesc = '_DIRECTDEBITMANDATEID_DESC_',
  /** Sort on DocNoOccurrence ascending */
  DocnooccurrenceAsc = '_DOCNOOCCURRENCE_ASC_',
  /** sort on DocNoOccurrence descending */
  DocnooccurrenceDesc = '_DOCNOOCCURRENCE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on SelltoCustomerTemplateCode ascending */
  SelltocustomertemplatecodeAsc = '_SELLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on SelltoCustomerTemplateCode descending */
  SelltocustomertemplatecodeDesc = '_SELLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on SelltoContactNo ascending */
  SelltocontactnoAsc = '_SELLTOCONTACTNO_ASC_',
  /** sort on SelltoContactNo descending */
  SelltocontactnoDesc = '_SELLTOCONTACTNO_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on BilltoCustomerTemplateCode ascending */
  BilltocustomertemplatecodeAsc = '_BILLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on BilltoCustomerTemplateCode descending */
  BilltocustomertemplatecodeDesc = '_BILLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on OpportunityNo ascending */
  OpportunitynoAsc = '_OPPORTUNITYNO_ASC_',
  /** sort on OpportunityNo descending */
  OpportunitynoDesc = '_OPPORTUNITYNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on PostingfromWhseRef ascending */
  PostingfromwhserefAsc = '_POSTINGFROMWHSEREF_ASC_',
  /** sort on PostingfromWhseRef descending */
  PostingfromwhserefDesc = '_POSTINGFROMWHSEREF_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on Receive ascending */
  ReceiveAsc = '_RECEIVE_ASC_',
  /** sort on Receive descending */
  ReceiveDesc = '_RECEIVE_DESC_',
  /** Sort on ReturnReceiptNo ascending */
  ReturnreceiptnoAsc = '_RETURNRECEIPTNO_ASC_',
  /** sort on ReturnReceiptNo descending */
  ReturnreceiptnoDesc = '_RETURNRECEIPTNO_DESC_',
  /** Sort on ReturnReceiptNoSeries ascending */
  ReturnreceiptnoseriesAsc = '_RETURNRECEIPTNOSERIES_ASC_',
  /** sort on ReturnReceiptNoSeries descending */
  ReturnreceiptnoseriesDesc = '_RETURNRECEIPTNOSERIES_DESC_',
  /** Sort on LastReturnReceiptNo ascending */
  LastreturnreceiptnoAsc = '_LASTRETURNRECEIPTNO_ASC_',
  /** sort on LastReturnReceiptNo descending */
  LastreturnreceiptnoDesc = '_LASTRETURNRECEIPTNO_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on GetShipmentUsed ascending */
  GetshipmentusedAsc = '_GETSHIPMENTUSED_ASC_',
  /** sort on GetShipmentUsed descending */
  GetshipmentusedDesc = '_GETSHIPMENTUSED_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on SourceInvNo ascending */
  SourceinvnoAsc = '_SOURCEINVNO_ASC_',
  /** sort on SourceInvNo descending */
  SourceinvnoDesc = '_SOURCEINVNO_DESC_',
  /** Sort on SourceInvVAT ascending */
  SourceinvvatAsc = '_SOURCEINVVAT_ASC_',
  /** sort on SourceInvVAT descending */
  SourceinvvatDesc = '_SOURCEINVVAT_DESC_',
  /** Sort on SourceInvTotal ascending */
  SourceinvtotalAsc = '_SOURCEINVTOTAL_ASC_',
  /** sort on SourceInvTotal descending */
  SourceinvtotalDesc = '_SOURCEINVTOTAL_DESC_',
  /** Sort on NoteofGoods ascending */
  NoteofgoodsAsc = '_NOTEOFGOODS_ASC_',
  /** sort on NoteofGoods descending */
  NoteofgoodsDesc = '_NOTEOFGOODS_DESC_',
  /** Sort on EQMShipmentType ascending */
  EqmshipmenttypeAsc = '_EQMSHIPMENTTYPE_ASC_',
  /** sort on EQMShipmentType descending */
  EqmshipmenttypeDesc = '_EQMSHIPMENTTYPE_DESC_',
  /** Sort on EQMShipmentTypeOPTION ascending */
  EqmshipmenttypeoptionAsc = '_EQMSHIPMENTTYPEOPTION_ASC_',
  /** sort on EQMShipmentTypeOPTION descending */
  EqmshipmenttypeoptionDesc = '_EQMSHIPMENTTYPEOPTION_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMCustomerType ascending */
  EqmcustomertypeAsc = '_EQMCUSTOMERTYPE_ASC_',
  /** sort on EQMCustomerType descending */
  EqmcustomertypeDesc = '_EQMCUSTOMERTYPE_DESC_',
  /** Sort on EQMCustomerTypeOPTION ascending */
  EqmcustomertypeoptionAsc = '_EQMCUSTOMERTYPEOPTION_ASC_',
  /** sort on EQMCustomerTypeOPTION descending */
  EqmcustomertypeoptionDesc = '_EQMCUSTOMERTYPEOPTION_DESC_',
  /** Sort on EQMRentalDocument ascending */
  EqmrentaldocumentAsc = '_EQMRENTALDOCUMENT_ASC_',
  /** sort on EQMRentalDocument descending */
  EqmrentaldocumentDesc = '_EQMRENTALDOCUMENT_DESC_',
  /** Sort on EQMTransferMethod ascending */
  EqmtransfermethodAsc = '_EQMTRANSFERMETHOD_ASC_',
  /** sort on EQMTransferMethod descending */
  EqmtransfermethodDesc = '_EQMTRANSFERMETHOD_DESC_',
  /** Sort on EQMTransferMethodOPTION ascending */
  EqmtransfermethodoptionAsc = '_EQMTRANSFERMETHODOPTION_ASC_',
  /** sort on EQMTransferMethodOPTION descending */
  EqmtransfermethodoptionDesc = '_EQMTRANSFERMETHODOPTION_DESC_',
  /** Sort on EQMContraryDoc ascending */
  EqmcontrarydocAsc = '_EQMCONTRARYDOC_ASC_',
  /** sort on EQMContraryDoc descending */
  EqmcontrarydocDesc = '_EQMCONTRARYDOC_DESC_',
  /** Sort on EQMWorkshopDocument ascending */
  EqmworkshopdocumentAsc = '_EQMWORKSHOPDOCUMENT_ASC_',
  /** sort on EQMWorkshopDocument descending */
  EqmworkshopdocumentDesc = '_EQMWORKSHOPDOCUMENT_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMServiceTermNo ascending */
  EqmservicetermnoAsc = '_EQMSERVICETERMNO_ASC_',
  /** sort on EQMServiceTermNo descending */
  EqmservicetermnoDesc = '_EQMSERVICETERMNO_DESC_',
  /** Sort on EQMCombineCustomerProject ascending */
  EqmcombinecustomerprojectAsc = '_EQMCOMBINECUSTOMERPROJECT_ASC_',
  /** sort on EQMCombineCustomerProject descending */
  EqmcombinecustomerprojectDesc = '_EQMCOMBINECUSTOMERPROJECT_DESC_',
  /** Sort on EQMInvoiceDocType ascending */
  EqminvoicedoctypeAsc = '_EQMINVOICEDOCTYPE_ASC_',
  /** sort on EQMInvoiceDocType descending */
  EqminvoicedoctypeDesc = '_EQMINVOICEDOCTYPE_DESC_',
  /** Sort on EQMInvoiceDocTypeOPTION ascending */
  EqminvoicedoctypeoptionAsc = '_EQMINVOICEDOCTYPEOPTION_ASC_',
  /** sort on EQMInvoiceDocTypeOPTION descending */
  EqminvoicedoctypeoptionDesc = '_EQMINVOICEDOCTYPEOPTION_DESC_',
  /** Sort on EQMCashSaleDocument ascending */
  EqmcashsaledocumentAsc = '_EQMCASHSALEDOCUMENT_ASC_',
  /** sort on EQMCashSaleDocument descending */
  EqmcashsaledocumentDesc = '_EQMCASHSALEDOCUMENT_DESC_',
  /** Sort on EQMUseDeposit ascending */
  EqmusedepositAsc = '_EQMUSEDEPOSIT_ASC_',
  /** sort on EQMUseDeposit descending */
  EqmusedepositDesc = '_EQMUSEDEPOSIT_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMJobNo ascending */
  EqmjobnoAsc = '_EQMJOBNO_ASC_',
  /** sort on EQMJobNo descending */
  EqmjobnoDesc = '_EQMJOBNO_DESC_',
  /** Sort on EQMJobTaskNo ascending */
  EqmjobtasknoAsc = '_EQMJOBTASKNO_ASC_',
  /** sort on EQMJobTaskNo descending */
  EqmjobtasknoDesc = '_EQMJOBTASKNO_DESC_',
  /** Sort on EQMWSObjectCounter ascending */
  EqmwsobjectcounterAsc = '_EQMWSOBJECTCOUNTER_ASC_',
  /** sort on EQMWSObjectCounter descending */
  EqmwsobjectcounterDesc = '_EQMWSOBJECTCOUNTER_DESC_',
  /** Sort on EQMServiceNo ascending */
  EqmservicenoAsc = '_EQMSERVICENO_ASC_',
  /** sort on EQMServiceNo descending */
  EqmservicenoDesc = '_EQMSERVICENO_DESC_',
  /** Sort on EQMServiceStartDate ascending */
  EqmservicestartdateAsc = '_EQMSERVICESTARTDATE_ASC_',
  /** sort on EQMServiceStartDate descending */
  EqmservicestartdateDesc = '_EQMSERVICESTARTDATE_DESC_',
  /** Sort on EQMServiceEndDate ascending */
  EqmserviceenddateAsc = '_EQMSERVICEENDDATE_ASC_',
  /** sort on EQMServiceEndDate descending */
  EqmserviceenddateDesc = '_EQMSERVICEENDDATE_DESC_',
  /** Sort on EQMServiceStatus ascending */
  EqmservicestatusAsc = '_EQMSERVICESTATUS_ASC_',
  /** sort on EQMServiceStatus descending */
  EqmservicestatusDesc = '_EQMSERVICESTATUS_DESC_',
  /** Sort on EQMServiceStatusOPTION ascending */
  EqmservicestatusoptionAsc = '_EQMSERVICESTATUSOPTION_ASC_',
  /** sort on EQMServiceStatusOPTION descending */
  EqmservicestatusoptionDesc = '_EQMSERVICESTATUSOPTION_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMWSEstimatedStartDate ascending */
  EqmwsestimatedstartdateAsc = '_EQMWSESTIMATEDSTARTDATE_ASC_',
  /** sort on EQMWSEstimatedStartDate descending */
  EqmwsestimatedstartdateDesc = '_EQMWSESTIMATEDSTARTDATE_DESC_',
  /** Sort on EQMWSEstimatedStartTime ascending */
  EqmwsestimatedstarttimeAsc = '_EQMWSESTIMATEDSTARTTIME_ASC_',
  /** sort on EQMWSEstimatedStartTime descending */
  EqmwsestimatedstarttimeDesc = '_EQMWSESTIMATEDSTARTTIME_DESC_',
  /** Sort on EQMWSEstimatedEndDate ascending */
  EqmwsestimatedenddateAsc = '_EQMWSESTIMATEDENDDATE_ASC_',
  /** sort on EQMWSEstimatedEndDate descending */
  EqmwsestimatedenddateDesc = '_EQMWSESTIMATEDENDDATE_DESC_',
  /** Sort on EQMWSEstimatedEndTime ascending */
  EqmwsestimatedendtimeAsc = '_EQMWSESTIMATEDENDTIME_ASC_',
  /** sort on EQMWSEstimatedEndTime descending */
  EqmwsestimatedendtimeDesc = '_EQMWSESTIMATEDENDTIME_DESC_',
  /** Sort on EQMServiceStartTime ascending */
  EqmservicestarttimeAsc = '_EQMSERVICESTARTTIME_ASC_',
  /** sort on EQMServiceStartTime descending */
  EqmservicestarttimeDesc = '_EQMSERVICESTARTTIME_DESC_',
  /** Sort on EQMServiceEndTime ascending */
  EqmserviceendtimeAsc = '_EQMSERVICEENDTIME_ASC_',
  /** sort on EQMServiceEndTime descending */
  EqmserviceendtimeDesc = '_EQMSERVICEENDTIME_DESC_',
  /** Sort on EQMWSObjectCounter2 ascending */
  Eqmwsobjectcounter2Asc = '_EQMWSOBJECTCOUNTER2_ASC_',
  /** sort on EQMWSObjectCounter2 descending */
  Eqmwsobjectcounter2Desc = '_EQMWSOBJECTCOUNTER2_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on LastEmailSentTime ascending */
  LastemailsenttimeAsc = '_LASTEMAILSENTTIME_ASC_',
  /** sort on LastEmailSentTime descending */
  LastemailsenttimeDesc = '_LASTEMAILSENTTIME_DESC_',
  /** Sort on LastEmailSentStatus ascending */
  LastemailsentstatusAsc = '_LASTEMAILSENTSTATUS_ASC_',
  /** sort on LastEmailSentStatus descending */
  LastemailsentstatusDesc = '_LASTEMAILSENTSTATUS_DESC_',
  /** Sort on LastEmailSentStatusOPTION ascending */
  LastemailsentstatusoptionAsc = '_LASTEMAILSENTSTATUSOPTION_ASC_',
  /** sort on LastEmailSentStatusOPTION descending */
  LastemailsentstatusoptionDesc = '_LASTEMAILSENTSTATUSOPTION_DESC_',
  /** Sort on SentasEmail ascending */
  SentasemailAsc = '_SENTASEMAIL_ASC_',
  /** sort on SentasEmail descending */
  SentasemailDesc = '_SENTASEMAIL_DESC_',
  /** Sort on LastEmailNotifCleared ascending */
  LastemailnotifclearedAsc = '_LASTEMAILNOTIFCLEARED_ASC_',
  /** sort on LastEmailNotifCleared descending */
  LastemailnotifclearedDesc = '_LASTEMAILNOTIFCLEARED_DESC_',
  /** Sort on AmtShipNotInvLCY ascending */
  AmtshipnotinvlcyAsc = '_AMTSHIPNOTINVLCY_ASC_',
  /** sort on AmtShipNotInvLCY descending */
  AmtshipnotinvlcyDesc = '_AMTSHIPNOTINVLCY_DESC_',
  /** Sort on AmtShipNotInvLCYBase ascending */
  AmtshipnotinvlcybaseAsc = '_AMTSHIPNOTINVLCYBASE_ASC_',
  /** sort on AmtShipNotInvLCYBase descending */
  AmtshipnotinvlcybaseDesc = '_AMTSHIPNOTINVLCYBASE_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on NoofArchivedVersions ascending */
  NoofarchivedversionsAsc = '_NOOFARCHIVEDVERSIONS_ASC_',
  /** sort on NoofArchivedVersions descending */
  NoofarchivedversionsDesc = '_NOOFARCHIVEDVERSIONS_DESC_',
  /** Sort on ShippedNotInvoiced ascending */
  ShippednotinvoicedAsc = '_SHIPPEDNOTINVOICED_ASC_',
  /** sort on ShippedNotInvoiced descending */
  ShippednotinvoicedDesc = '_SHIPPEDNOTINVOICED_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on Shipped ascending */
  ShippedAsc = '_SHIPPED_ASC_',
  /** sort on Shipped descending */
  ShippedDesc = '_SHIPPED_DESC_',
  /** Sort on LateOrderShipping ascending */
  LateordershippingAsc = '_LATEORDERSHIPPING_ASC_',
  /** sort on LateOrderShipping descending */
  LateordershippingDesc = '_LATEORDERSHIPPING_DESC_',
  /** Sort on EQMObjectWarrantyDate ascending */
  EqmobjectwarrantydateAsc = '_EQMOBJECTWARRANTYDATE_ASC_',
  /** sort on EQMObjectWarrantyDate descending */
  EqmobjectwarrantydateDesc = '_EQMOBJECTWARRANTYDATE_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on BaseSubTotal ascending */
  BasesubtotalAsc = '_BASESUBTOTAL_ASC_',
  /** sort on BaseSubTotal descending */
  BasesubtotalDesc = '_BASESUBTOTAL_DESC_',
  /** Sort on BaseSubTotalInclTax ascending */
  BasesubtotalincltaxAsc = '_BASESUBTOTALINCLTAX_ASC_',
  /** sort on BaseSubTotalInclTax descending */
  BasesubtotalincltaxDesc = '_BASESUBTOTALINCLTAX_DESC_',
  /** Sort on ShippingDescription ascending */
  ShippingdescriptionAsc = '_SHIPPINGDESCRIPTION_ASC_',
  /** sort on ShippingDescription descending */
  ShippingdescriptionDesc = '_SHIPPINGDESCRIPTION_DESC_',
  /** Sort on CustomerNote ascending */
  CustomernoteAsc = '_CUSTOMERNOTE_ASC_',
  /** sort on CustomerNote descending */
  CustomernoteDesc = '_CUSTOMERNOTE_DESC_',
  /** Sort on CustomerEmail ascending */
  CustomeremailAsc = '_CUSTOMEREMAIL_ASC_',
  /** sort on CustomerEmail descending */
  CustomeremailDesc = '_CUSTOMEREMAIL_DESC_',
  /** Sort on BilltoEmail ascending */
  BilltoemailAsc = '_BILLTOEMAIL_ASC_',
  /** sort on BilltoEmail descending */
  BilltoemailDesc = '_BILLTOEMAIL_DESC_',
  /** Sort on BilltoTelephone ascending */
  BilltotelephoneAsc = '_BILLTOTELEPHONE_ASC_',
  /** sort on BilltoTelephone descending */
  BilltotelephoneDesc = '_BILLTOTELEPHONE_DESC_',
  /** Sort on ShipToId ascending */
  ShiptoidAsc = '_SHIPTOID_ASC_',
  /** sort on ShipToId descending */
  ShiptoidDesc = '_SHIPTOID_DESC_',
  /** Sort on ShiptoEmail ascending */
  ShiptoemailAsc = '_SHIPTOEMAIL_ASC_',
  /** sort on ShiptoEmail descending */
  ShiptoemailDesc = '_SHIPTOEMAIL_DESC_',
  /** Sort on ShiptoTelephone ascending */
  ShiptotelephoneAsc = '_SHIPTOTELEPHONE_ASC_',
  /** sort on ShiptoTelephone descending */
  ShiptotelephoneDesc = '_SHIPTOTELEPHONE_DESC_',
  /** Sort on MagentoOrderStatus ascending */
  MagentoorderstatusAsc = '_MAGENTOORDERSTATUS_ASC_',
  /** sort on MagentoOrderStatus descending */
  MagentoorderstatusDesc = '_MAGENTOORDERSTATUS_DESC_',
  /** Sort on MagentoOrderState ascending */
  MagentoorderstateAsc = '_MAGENTOORDERSTATE_ASC_',
  /** sort on MagentoOrderState descending */
  MagentoorderstateDesc = '_MAGENTOORDERSTATE_DESC_',
  /** Sort on LineCount ascending */
  LinecountAsc = '_LINECOUNT_ASC_',
  /** sort on LineCount descending */
  LinecountDesc = '_LINECOUNT_DESC_',
  /** Sort on FreightIncluded ascending */
  FreightincludedAsc = '_FREIGHTINCLUDED_ASC_',
  /** sort on FreightIncluded descending */
  FreightincludedDesc = '_FREIGHTINCLUDED_DESC_',
  /** Sort on DiscountIncluded ascending */
  DiscountincludedAsc = '_DISCOUNTINCLUDED_ASC_',
  /** sort on DiscountIncluded descending */
  DiscountincludedDesc = '_DISCOUNTINCLUDED_DESC_',
  /** Sort on DeliveryMethod ascending */
  DeliverymethodAsc = '_DELIVERYMETHOD_ASC_',
  /** sort on DeliveryMethod descending */
  DeliverymethodDesc = '_DELIVERYMETHOD_DESC_',
  /** Sort on ProcessedByEdge ascending */
  ProcessedbyedgeAsc = '_PROCESSEDBYEDGE_ASC_',
  /** sort on ProcessedByEdge descending */
  ProcessedbyedgeDesc = '_PROCESSEDBYEDGE_DESC_',
  /** Sort on EdgeStartedPollingOn ascending */
  EdgestartedpollingonAsc = '_EDGESTARTEDPOLLINGON_ASC_',
  /** sort on EdgeStartedPollingOn descending */
  EdgestartedpollingonDesc = '_EDGESTARTEDPOLLINGON_DESC_',
  /** Sort on HandleRelationsInJSONStructure ascending */
  HandlerelationsinjsonstructureAsc = '_HANDLERELATIONSINJSONSTRUCTURE_ASC_',
  /** sort on HandleRelationsInJSONStructure descending */
  HandlerelationsinjsonstructureDesc = '_HANDLERELATIONSINJSONSTRUCTURE_DESC_',
  /** Sort on WebbComment ascending */
  WebbcommentAsc = '_WEBBCOMMENT_ASC_',
  /** sort on WebbComment descending */
  WebbcommentDesc = '_WEBBCOMMENT_DESC_',
  /** Sort on SalesOrderEdgeGuid ascending */
  SalesorderedgeguidAsc = '_SALESORDEREDGEGUID_ASC_',
  /** sort on SalesOrderEdgeGuid descending */
  SalesorderedgeguidDesc = '_SALESORDEREDGEGUID_DESC_',
  /** Sort on PaymentMethodWeb ascending */
  PaymentmethodwebAsc = '_PAYMENTMETHODWEB_ASC_',
  /** sort on PaymentMethodWeb descending */
  PaymentmethodwebDesc = '_PAYMENTMETHODWEB_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on WebOrderNo ascending */
  WebordernoAsc = '_WEBORDERNO_ASC_',
  /** sort on WebOrderNo descending */
  WebordernoDesc = '_WEBORDERNO_DESC_',
  /** Sort on AdditionalTelephone ascending */
  AdditionaltelephoneAsc = '_ADDITIONALTELEPHONE_ASC_',
  /** sort on AdditionalTelephone descending */
  AdditionaltelephoneDesc = '_ADDITIONALTELEPHONE_DESC_',
  /** Sort on SellToAddressGlobalId ascending */
  SelltoaddressglobalidAsc = '_SELLTOADDRESSGLOBALID_ASC_',
  /** sort on SellToAddressGlobalId descending */
  SelltoaddressglobalidDesc = '_SELLTOADDRESSGLOBALID_DESC_',
  /** Sort on SharkActualPrice ascending */
  SharkactualpriceAsc = '_SHARKACTUALPRICE_ASC_',
  /** sort on SharkActualPrice descending */
  SharkactualpriceDesc = '_SHARKACTUALPRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesHeaderRequestInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPostingGroup ascending */
  CustomerpostinggroupAsc = '_CUSTOMERPOSTINGGROUP_ASC_',
  /** sort on CustomerPostingGroup descending */
  CustomerpostinggroupDesc = '_CUSTOMERPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderClass ascending */
  OrderclassAsc = '_ORDERCLASS_ASC_',
  /** sort on OrderClass descending */
  OrderclassDesc = '_ORDERCLASS_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on Ship ascending */
  ShipAsc = '_SHIP_ASC_',
  /** sort on Ship descending */
  ShipDesc = '_SHIP_DESC_',
  /** Sort on Invoice ascending */
  InvoiceAsc = '_INVOICE_ASC_',
  /** sort on Invoice descending */
  InvoiceDesc = '_INVOICE_DESC_',
  /** Sort on PrintPostedDocuments ascending */
  PrintposteddocumentsAsc = '_PRINTPOSTEDDOCUMENTS_ASC_',
  /** sort on PrintPostedDocuments descending */
  PrintposteddocumentsDesc = '_PRINTPOSTEDDOCUMENTS_DESC_',
  /** Sort on ShippingNo ascending */
  ShippingnoAsc = '_SHIPPINGNO_ASC_',
  /** sort on ShippingNo descending */
  ShippingnoDesc = '_SHIPPINGNO_DESC_',
  /** Sort on PostingNo ascending */
  PostingnoAsc = '_POSTINGNO_ASC_',
  /** sort on PostingNo descending */
  PostingnoDesc = '_POSTINGNO_DESC_',
  /** Sort on LastShippingNo ascending */
  LastshippingnoAsc = '_LASTSHIPPINGNO_ASC_',
  /** sort on LastShippingNo descending */
  LastshippingnoDesc = '_LASTSHIPPINGNO_DESC_',
  /** Sort on LastPostingNo ascending */
  LastpostingnoAsc = '_LASTPOSTINGNO_ASC_',
  /** sort on LastPostingNo descending */
  LastpostingnoDesc = '_LASTPOSTINGNO_DESC_',
  /** Sort on PrepaymentNo ascending */
  PrepaymentnoAsc = '_PREPAYMENTNO_ASC_',
  /** sort on PrepaymentNo descending */
  PrepaymentnoDesc = '_PREPAYMENTNO_DESC_',
  /** Sort on LastPrepaymentNo ascending */
  LastprepaymentnoAsc = '_LASTPREPAYMENTNO_ASC_',
  /** sort on LastPrepaymentNo descending */
  LastprepaymentnoDesc = '_LASTPREPAYMENTNO_DESC_',
  /** Sort on PrepmtCrMemoNo ascending */
  PrepmtcrmemonoAsc = '_PREPMTCRMEMONO_ASC_',
  /** sort on PrepmtCrMemoNo descending */
  PrepmtcrmemonoDesc = '_PREPMTCRMEMONO_DESC_',
  /** Sort on LastPrepmtCrMemoNo ascending */
  LastprepmtcrmemonoAsc = '_LASTPREPMTCRMEMONO_ASC_',
  /** sort on LastPrepmtCrMemoNo descending */
  LastprepmtcrmemonoDesc = '_LASTPREPMTCRMEMONO_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on CombineShipments ascending */
  CombineshipmentsAsc = '_COMBINESHIPMENTS_ASC_',
  /** sort on CombineShipments descending */
  CombineshipmentsDesc = '_COMBINESHIPMENTS_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoCustomerName ascending */
  SelltocustomernameAsc = '_SELLTOCUSTOMERNAME_ASC_',
  /** sort on SelltoCustomerName descending */
  SelltocustomernameDesc = '_SELLTOCUSTOMERNAME_DESC_',
  /** Sort on SelltoCustomerName2 ascending */
  Selltocustomername2Asc = '_SELLTOCUSTOMERNAME2_ASC_',
  /** sort on SelltoCustomerName2 descending */
  Selltocustomername2Desc = '_SELLTOCUSTOMERNAME2_DESC_',
  /** Sort on SelltoAddress ascending */
  SelltoaddressAsc = '_SELLTOADDRESS_ASC_',
  /** sort on SelltoAddress descending */
  SelltoaddressDesc = '_SELLTOADDRESS_DESC_',
  /** Sort on SelltoAddress2 ascending */
  Selltoaddress2Asc = '_SELLTOADDRESS2_ASC_',
  /** sort on SelltoAddress2 descending */
  Selltoaddress2Desc = '_SELLTOADDRESS2_DESC_',
  /** Sort on SelltoCity ascending */
  SelltocityAsc = '_SELLTOCITY_ASC_',
  /** sort on SelltoCity descending */
  SelltocityDesc = '_SELLTOCITY_DESC_',
  /** Sort on SelltoContact ascending */
  SelltocontactAsc = '_SELLTOCONTACT_ASC_',
  /** sort on SelltoContact descending */
  SelltocontactDesc = '_SELLTOCONTACT_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoPostCode ascending */
  SelltopostcodeAsc = '_SELLTOPOSTCODE_ASC_',
  /** sort on SelltoPostCode descending */
  SelltopostcodeDesc = '_SELLTOPOSTCODE_DESC_',
  /** Sort on SelltoCounty ascending */
  SelltocountyAsc = '_SELLTOCOUNTY_ASC_',
  /** sort on SelltoCounty descending */
  SelltocountyDesc = '_SELLTOCOUNTY_DESC_',
  /** Sort on SelltoCountryRegionCode ascending */
  SelltocountryregioncodeAsc = '_SELLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on SelltoCountryRegionCode descending */
  SelltocountryregioncodeDesc = '_SELLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on PostingNoSeries ascending */
  PostingnoseriesAsc = '_POSTINGNOSERIES_ASC_',
  /** sort on PostingNoSeries descending */
  PostingnoseriesDesc = '_POSTINGNOSERIES_DESC_',
  /** Sort on ShippingNoSeries ascending */
  ShippingnoseriesAsc = '_SHIPPINGNOSERIES_ASC_',
  /** sort on ShippingNoSeries descending */
  ShippingnoseriesDesc = '_SHIPPINGNOSERIES_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on AppliestoID ascending */
  AppliestoidAsc = '_APPLIESTOID_ASC_',
  /** sort on AppliestoID descending */
  AppliestoidDesc = '_APPLIESTOID_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on InvoiceDiscountCalculation ascending */
  InvoicediscountcalculationAsc = '_INVOICEDISCOUNTCALCULATION_ASC_',
  /** sort on InvoiceDiscountCalculation descending */
  InvoicediscountcalculationDesc = '_INVOICEDISCOUNTCALCULATION_DESC_',
  /** Sort on InvoiceDiscountCalculationOPTION ascending */
  InvoicediscountcalculationoptionAsc = '_INVOICEDISCOUNTCALCULATIONOPTION_ASC_',
  /** sort on InvoiceDiscountCalculationOPTION descending */
  InvoicediscountcalculationoptionDesc = '_INVOICEDISCOUNTCALCULATIONOPTION_DESC_',
  /** Sort on InvoiceDiscountValue ascending */
  InvoicediscountvalueAsc = '_INVOICEDISCOUNTVALUE_ASC_',
  /** sort on InvoiceDiscountValue descending */
  InvoicediscountvalueDesc = '_INVOICEDISCOUNTVALUE_DESC_',
  /** Sort on SendICDocument ascending */
  SendicdocumentAsc = '_SENDICDOCUMENT_ASC_',
  /** sort on SendICDocument descending */
  SendicdocumentDesc = '_SENDICDOCUMENT_DESC_',
  /** Sort on ICStatus ascending */
  IcstatusAsc = '_ICSTATUS_ASC_',
  /** sort on ICStatus descending */
  IcstatusDesc = '_ICSTATUS_DESC_',
  /** Sort on ICStatusOPTION ascending */
  IcstatusoptionAsc = '_ICSTATUSOPTION_ASC_',
  /** sort on ICStatusOPTION descending */
  IcstatusoptionDesc = '_ICSTATUSOPTION_DESC_',
  /** Sort on SelltoICPartnerCode ascending */
  SelltoicpartnercodeAsc = '_SELLTOICPARTNERCODE_ASC_',
  /** sort on SelltoICPartnerCode descending */
  SelltoicpartnercodeDesc = '_SELLTOICPARTNERCODE_DESC_',
  /** Sort on BilltoICPartnerCode ascending */
  BilltoicpartnercodeAsc = '_BILLTOICPARTNERCODE_ASC_',
  /** sort on BilltoICPartnerCode descending */
  BilltoicpartnercodeDesc = '_BILLTOICPARTNERCODE_DESC_',
  /** Sort on ICDirection ascending */
  IcdirectionAsc = '_ICDIRECTION_ASC_',
  /** sort on ICDirection descending */
  IcdirectionDesc = '_ICDIRECTION_DESC_',
  /** Sort on ICDirectionOPTION ascending */
  IcdirectionoptionAsc = '_ICDIRECTIONOPTION_ASC_',
  /** sort on ICDirectionOPTION descending */
  IcdirectionoptionDesc = '_ICDIRECTIONOPTION_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on CompressPrepayment ascending */
  CompressprepaymentAsc = '_COMPRESSPREPAYMENT_ASC_',
  /** sort on CompressPrepayment descending */
  CompressprepaymentDesc = '_COMPRESSPREPAYMENT_DESC_',
  /** Sort on PrepaymentDueDate ascending */
  PrepaymentduedateAsc = '_PREPAYMENTDUEDATE_ASC_',
  /** sort on PrepaymentDueDate descending */
  PrepaymentduedateDesc = '_PREPAYMENTDUEDATE_DESC_',
  /** Sort on PrepmtCrMemoNoSeries ascending */
  PrepmtcrmemonoseriesAsc = '_PREPMTCRMEMONOSERIES_ASC_',
  /** sort on PrepmtCrMemoNoSeries descending */
  PrepmtcrmemonoseriesDesc = '_PREPMTCRMEMONOSERIES_DESC_',
  /** Sort on PrepmtPostingDescription ascending */
  PrepmtpostingdescriptionAsc = '_PREPMTPOSTINGDESCRIPTION_ASC_',
  /** sort on PrepmtPostingDescription descending */
  PrepmtpostingdescriptionDesc = '_PREPMTPOSTINGDESCRIPTION_DESC_',
  /** Sort on PrepmtPmtDiscountDate ascending */
  PrepmtpmtdiscountdateAsc = '_PREPMTPMTDISCOUNTDATE_ASC_',
  /** sort on PrepmtPmtDiscountDate descending */
  PrepmtpmtdiscountdateDesc = '_PREPMTPMTDISCOUNTDATE_DESC_',
  /** Sort on PrepmtPaymentTermsCode ascending */
  PrepmtpaymenttermscodeAsc = '_PREPMTPAYMENTTERMSCODE_ASC_',
  /** sort on PrepmtPaymentTermsCode descending */
  PrepmtpaymenttermscodeDesc = '_PREPMTPAYMENTTERMSCODE_DESC_',
  /** Sort on PrepmtPaymentDiscount ascending */
  PrepmtpaymentdiscountAsc = '_PREPMTPAYMENTDISCOUNT_ASC_',
  /** sort on PrepmtPaymentDiscount descending */
  PrepmtpaymentdiscountDesc = '_PREPMTPAYMENTDISCOUNT_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on JobQueueStatus ascending */
  JobqueuestatusAsc = '_JOBQUEUESTATUS_ASC_',
  /** sort on JobQueueStatus descending */
  JobqueuestatusDesc = '_JOBQUEUESTATUS_DESC_',
  /** Sort on JobQueueStatusOPTION ascending */
  JobqueuestatusoptionAsc = '_JOBQUEUESTATUSOPTION_ASC_',
  /** sort on JobQueueStatusOPTION descending */
  JobqueuestatusoptionDesc = '_JOBQUEUESTATUSOPTION_DESC_',
  /** Sort on JobQueueEntryID ascending */
  JobqueueentryidAsc = '_JOBQUEUEENTRYID_ASC_',
  /** sort on JobQueueEntryID descending */
  JobqueueentryidDesc = '_JOBQUEUEENTRYID_DESC_',
  /** Sort on IncomingDocumentEntryNo ascending */
  IncomingdocumententrynoAsc = '_INCOMINGDOCUMENTENTRYNO_ASC_',
  /** sort on IncomingDocumentEntryNo descending */
  IncomingdocumententrynoDesc = '_INCOMINGDOCUMENTENTRYNO_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on AuthorizationRequired ascending */
  AuthorizationrequiredAsc = '_AUTHORIZATIONREQUIRED_ASC_',
  /** sort on AuthorizationRequired descending */
  AuthorizationrequiredDesc = '_AUTHORIZATIONREQUIRED_DESC_',
  /** Sort on CreditCardNo ascending */
  CreditcardnoAsc = '_CREDITCARDNO_ASC_',
  /** sort on CreditCardNo descending */
  CreditcardnoDesc = '_CREDITCARDNO_DESC_',
  /** Sort on DirectDebitMandateID ascending */
  DirectdebitmandateidAsc = '_DIRECTDEBITMANDATEID_ASC_',
  /** sort on DirectDebitMandateID descending */
  DirectdebitmandateidDesc = '_DIRECTDEBITMANDATEID_DESC_',
  /** Sort on DocNoOccurrence ascending */
  DocnooccurrenceAsc = '_DOCNOOCCURRENCE_ASC_',
  /** sort on DocNoOccurrence descending */
  DocnooccurrenceDesc = '_DOCNOOCCURRENCE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on SelltoCustomerTemplateCode ascending */
  SelltocustomertemplatecodeAsc = '_SELLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on SelltoCustomerTemplateCode descending */
  SelltocustomertemplatecodeDesc = '_SELLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on SelltoContactNo ascending */
  SelltocontactnoAsc = '_SELLTOCONTACTNO_ASC_',
  /** sort on SelltoContactNo descending */
  SelltocontactnoDesc = '_SELLTOCONTACTNO_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on BilltoCustomerTemplateCode ascending */
  BilltocustomertemplatecodeAsc = '_BILLTOCUSTOMERTEMPLATECODE_ASC_',
  /** sort on BilltoCustomerTemplateCode descending */
  BilltocustomertemplatecodeDesc = '_BILLTOCUSTOMERTEMPLATECODE_DESC_',
  /** Sort on OpportunityNo ascending */
  OpportunitynoAsc = '_OPPORTUNITYNO_ASC_',
  /** sort on OpportunityNo descending */
  OpportunitynoDesc = '_OPPORTUNITYNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on ShippingAdvice ascending */
  ShippingadviceAsc = '_SHIPPINGADVICE_ASC_',
  /** sort on ShippingAdvice descending */
  ShippingadviceDesc = '_SHIPPINGADVICE_DESC_',
  /** Sort on ShippingAdviceOPTION ascending */
  ShippingadviceoptionAsc = '_SHIPPINGADVICEOPTION_ASC_',
  /** sort on ShippingAdviceOPTION descending */
  ShippingadviceoptionDesc = '_SHIPPINGADVICEOPTION_DESC_',
  /** Sort on PostingfromWhseRef ascending */
  PostingfromwhserefAsc = '_POSTINGFROMWHSEREF_ASC_',
  /** sort on PostingfromWhseRef descending */
  PostingfromwhserefDesc = '_POSTINGFROMWHSEREF_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on Receive ascending */
  ReceiveAsc = '_RECEIVE_ASC_',
  /** sort on Receive descending */
  ReceiveDesc = '_RECEIVE_DESC_',
  /** Sort on ReturnReceiptNo ascending */
  ReturnreceiptnoAsc = '_RETURNRECEIPTNO_ASC_',
  /** sort on ReturnReceiptNo descending */
  ReturnreceiptnoDesc = '_RETURNRECEIPTNO_DESC_',
  /** Sort on ReturnReceiptNoSeries ascending */
  ReturnreceiptnoseriesAsc = '_RETURNRECEIPTNOSERIES_ASC_',
  /** sort on ReturnReceiptNoSeries descending */
  ReturnreceiptnoseriesDesc = '_RETURNRECEIPTNOSERIES_DESC_',
  /** Sort on LastReturnReceiptNo ascending */
  LastreturnreceiptnoAsc = '_LASTRETURNRECEIPTNO_ASC_',
  /** sort on LastReturnReceiptNo descending */
  LastreturnreceiptnoDesc = '_LASTRETURNRECEIPTNO_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on GetShipmentUsed ascending */
  GetshipmentusedAsc = '_GETSHIPMENTUSED_ASC_',
  /** sort on GetShipmentUsed descending */
  GetshipmentusedDesc = '_GETSHIPMENTUSED_DESC_',
  /** Sort on AssignedUserID ascending */
  AssigneduseridAsc = '_ASSIGNEDUSERID_ASC_',
  /** sort on AssignedUserID descending */
  AssigneduseridDesc = '_ASSIGNEDUSERID_DESC_',
  /** Sort on SourceInvNo ascending */
  SourceinvnoAsc = '_SOURCEINVNO_ASC_',
  /** sort on SourceInvNo descending */
  SourceinvnoDesc = '_SOURCEINVNO_DESC_',
  /** Sort on SourceInvVAT ascending */
  SourceinvvatAsc = '_SOURCEINVVAT_ASC_',
  /** sort on SourceInvVAT descending */
  SourceinvvatDesc = '_SOURCEINVVAT_DESC_',
  /** Sort on SourceInvTotal ascending */
  SourceinvtotalAsc = '_SOURCEINVTOTAL_ASC_',
  /** sort on SourceInvTotal descending */
  SourceinvtotalDesc = '_SOURCEINVTOTAL_DESC_',
  /** Sort on Ordertype ascending */
  OrdertypeAsc = '_ORDERTYPE_ASC_',
  /** sort on Ordertype descending */
  OrdertypeDesc = '_ORDERTYPE_DESC_',
  /** Sort on OrdertypeOPTION ascending */
  OrdertypeoptionAsc = '_ORDERTYPEOPTION_ASC_',
  /** sort on OrdertypeOPTION descending */
  OrdertypeoptionDesc = '_ORDERTYPEOPTION_DESC_',
  /** Sort on OurReference ascending */
  OurreferenceAsc = '_OURREFERENCE_ASC_',
  /** sort on OurReference descending */
  OurreferenceDesc = '_OURREFERENCE_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on ConfirmedbyVendor ascending */
  ConfirmedbyvendorAsc = '_CONFIRMEDBYVENDOR_ASC_',
  /** sort on ConfirmedbyVendor descending */
  ConfirmedbyvendorDesc = '_CONFIRMEDBYVENDOR_DESC_',
  /** Sort on DocumentMethod ascending */
  DocumentmethodAsc = '_DOCUMENTMETHOD_ASC_',
  /** sort on DocumentMethod descending */
  DocumentmethodDesc = '_DOCUMENTMETHOD_DESC_',
  /** Sort on DocumentMethodOPTION ascending */
  DocumentmethodoptionAsc = '_DOCUMENTMETHODOPTION_ASC_',
  /** sort on DocumentMethodOPTION descending */
  DocumentmethodoptionDesc = '_DOCUMENTMETHODOPTION_DESC_',
  /** Sort on InvoiceEmailAddress ascending */
  InvoiceemailaddressAsc = '_INVOICEEMAILADDRESS_ASC_',
  /** sort on InvoiceEmailAddress descending */
  InvoiceemailaddressDesc = '_INVOICEEMAILADDRESS_DESC_',
  /** Sort on NAVStatus ascending */
  NavstatusAsc = '_NAVSTATUS_ASC_',
  /** sort on NAVStatus descending */
  NavstatusDesc = '_NAVSTATUS_DESC_',
  /** Sort on NAVStatusOPTION ascending */
  NavstatusoptionAsc = '_NAVSTATUSOPTION_ASC_',
  /** sort on NAVStatusOPTION descending */
  NavstatusoptionDesc = '_NAVSTATUSOPTION_DESC_',
  /** Sort on NAVImportedDate ascending */
  NavimporteddateAsc = '_NAVIMPORTEDDATE_ASC_',
  /** sort on NAVImportedDate descending */
  NavimporteddateDesc = '_NAVIMPORTEDDATE_DESC_',
  /** Sort on NAVOrderNo ascending */
  NavordernoAsc = '_NAVORDERNO_ASC_',
  /** sort on NAVOrderNo descending */
  NavordernoDesc = '_NAVORDERNO_DESC_',
  /** Sort on NAVErrorMessage ascending */
  NaverrormessageAsc = '_NAVERRORMESSAGE_ASC_',
  /** sort on NAVErrorMessage descending */
  NaverrormessageDesc = '_NAVERRORMESSAGE_DESC_',
  /** Sort on NoteofGoods ascending */
  NoteofgoodsAsc = '_NOTEOFGOODS_ASC_',
  /** sort on NoteofGoods descending */
  NoteofgoodsDesc = '_NOTEOFGOODS_DESC_',
  /** Sort on LocationFilter ascending */
  LocationfilterAsc = '_LOCATIONFILTER_ASC_',
  /** sort on LocationFilter descending */
  LocationfilterDesc = '_LOCATIONFILTER_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesLineArchiveInput {
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on DocumentGlobalId ascending */
  DocumentglobalidAsc = '_DOCUMENTGLOBALID_ASC_',
  /** sort on DocumentGlobalId descending */
  DocumentglobalidDesc = '_DOCUMENTGLOBALID_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on ItemGlobalId ascending */
  ItemglobalidAsc = '_ITEMGLOBALID_ASC_',
  /** sort on ItemGlobalId descending */
  ItemglobalidDesc = '_ITEMGLOBALID_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on OutstandingQuantity ascending */
  OutstandingquantityAsc = '_OUTSTANDINGQUANTITY_ASC_',
  /** sort on OutstandingQuantity descending */
  OutstandingquantityDesc = '_OUTSTANDINGQUANTITY_DESC_',
  /** Sort on QtytoInvoice ascending */
  QtytoinvoiceAsc = '_QTYTOINVOICE_ASC_',
  /** sort on QtytoInvoice descending */
  QtytoinvoiceDesc = '_QTYTOINVOICE_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountPercent ascending */
  LinediscountpercentAsc = '_LINEDISCOUNTPERCENT_ASC_',
  /** sort on LineDiscountPercent descending */
  LinediscountpercentDesc = '_LINEDISCOUNTPERCENT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on OutstandingAmount ascending */
  OutstandingamountAsc = '_OUTSTANDINGAMOUNT_ASC_',
  /** sort on OutstandingAmount descending */
  OutstandingamountDesc = '_OUTSTANDINGAMOUNT_DESC_',
  /** Sort on QtyShippedNotInvoiced ascending */
  QtyshippednotinvoicedAsc = '_QTYSHIPPEDNOTINVOICED_ASC_',
  /** sort on QtyShippedNotInvoiced descending */
  QtyshippednotinvoicedDesc = '_QTYSHIPPEDNOTINVOICED_DESC_',
  /** Sort on ShippedNotInvoiced ascending */
  ShippednotinvoicedAsc = '_SHIPPEDNOTINVOICED_ASC_',
  /** sort on ShippedNotInvoiced descending */
  ShippednotinvoicedDesc = '_SHIPPEDNOTINVOICED_DESC_',
  /** Sort on QuantityShipped ascending */
  QuantityshippedAsc = '_QUANTITYSHIPPED_ASC_',
  /** sort on QuantityShipped descending */
  QuantityshippedDesc = '_QUANTITYSHIPPED_DESC_',
  /** Sort on QuantityInvoiced ascending */
  QuantityinvoicedAsc = '_QUANTITYINVOICED_ASC_',
  /** sort on QuantityInvoiced descending */
  QuantityinvoicedDesc = '_QUANTITYINVOICED_DESC_',
  /** Sort on ShipmentNo ascending */
  ShipmentnoAsc = '_SHIPMENTNO_ASC_',
  /** sort on ShipmentNo descending */
  ShipmentnoDesc = '_SHIPMENTNO_DESC_',
  /** Sort on ShipmentLineNo ascending */
  ShipmentlinenoAsc = '_SHIPMENTLINENO_ASC_',
  /** sort on ShipmentLineNo descending */
  ShipmentlinenoDesc = '_SHIPMENTLINENO_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on InvDiscountAmount ascending */
  InvdiscountamountAsc = '_INVDISCOUNTAMOUNT_ASC_',
  /** sort on InvDiscountAmount descending */
  InvdiscountamountDesc = '_INVDISCOUNTAMOUNT_DESC_',
  /** Sort on PurchaseOrderNo ascending */
  PurchaseordernoAsc = '_PURCHASEORDERNO_ASC_',
  /** sort on PurchaseOrderNo descending */
  PurchaseordernoDesc = '_PURCHASEORDERNO_DESC_',
  /** Sort on PurchOrderLineNo ascending */
  PurchorderlinenoAsc = '_PURCHORDERLINENO_ASC_',
  /** sort on PurchOrderLineNo descending */
  PurchorderlinenoDesc = '_PURCHORDERLINENO_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxCategory ascending */
  TaxcategoryAsc = '_TAXCATEGORY_ASC_',
  /** sort on TaxCategory descending */
  TaxcategoryDesc = '_TAXCATEGORY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATClauseCode ascending */
  VatclausecodeAsc = '_VATCLAUSECODE_ASC_',
  /** sort on VATClauseCode descending */
  VatclausecodeDesc = '_VATCLAUSECODE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on OutstandingAmountLCY ascending */
  OutstandingamountlcyAsc = '_OUTSTANDINGAMOUNTLCY_ASC_',
  /** sort on OutstandingAmountLCY descending */
  OutstandingamountlcyDesc = '_OUTSTANDINGAMOUNTLCY_DESC_',
  /** Sort on ShippedNotInvoicedLCY ascending */
  ShippednotinvoicedlcyAsc = '_SHIPPEDNOTINVOICEDLCY_ASC_',
  /** sort on ShippedNotInvoicedLCY descending */
  ShippednotinvoicedlcyDesc = '_SHIPPEDNOTINVOICEDLCY_DESC_',
  /** Sort on ShippedNotInvLCYNoVAT ascending */
  ShippednotinvlcynovatAsc = '_SHIPPEDNOTINVLCYNOVAT_ASC_',
  /** sort on ShippedNotInvLCYNoVAT descending */
  ShippednotinvlcynovatDesc = '_SHIPPEDNOTINVLCYNOVAT_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on InvDiscAmounttoInvoice ascending */
  InvdiscamounttoinvoiceAsc = '_INVDISCAMOUNTTOINVOICE_ASC_',
  /** sort on InvDiscAmounttoInvoice descending */
  InvdiscamounttoinvoiceDesc = '_INVDISCAMOUNTTOINVOICE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepmtLineAmount ascending */
  PrepmtlineamountAsc = '_PREPMTLINEAMOUNT_ASC_',
  /** sort on PrepmtLineAmount descending */
  PrepmtlineamountDesc = '_PREPMTLINEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInv ascending */
  PrepmtamtinvAsc = '_PREPMTAMTINV_ASC_',
  /** sort on PrepmtAmtInv descending */
  PrepmtamtinvDesc = '_PREPMTAMTINV_DESC_',
  /** Sort on PrepmtAmtInclVAT ascending */
  PrepmtamtinclvatAsc = '_PREPMTAMTINCLVAT_ASC_',
  /** sort on PrepmtAmtInclVAT descending */
  PrepmtamtinclvatDesc = '_PREPMTAMTINCLVAT_DESC_',
  /** Sort on PrepaymentAmount ascending */
  PrepaymentamountAsc = '_PREPAYMENTAMOUNT_ASC_',
  /** sort on PrepaymentAmount descending */
  PrepaymentamountDesc = '_PREPAYMENTAMOUNT_DESC_',
  /** Sort on PrepmtVATBaseAmt ascending */
  PrepmtvatbaseamtAsc = '_PREPMTVATBASEAMT_ASC_',
  /** sort on PrepmtVATBaseAmt descending */
  PrepmtvatbaseamtDesc = '_PREPMTVATBASEAMT_DESC_',
  /** Sort on PrepaymentVAT ascending */
  PrepaymentvatAsc = '_PREPAYMENTVAT_ASC_',
  /** sort on PrepaymentVAT descending */
  PrepaymentvatDesc = '_PREPAYMENTVAT_DESC_',
  /** Sort on PrepmtVATCalcType ascending */
  PrepmtvatcalctypeAsc = '_PREPMTVATCALCTYPE_ASC_',
  /** sort on PrepmtVATCalcType descending */
  PrepmtvatcalctypeDesc = '_PREPMTVATCALCTYPE_DESC_',
  /** Sort on PrepmtVATCalcTypeOPTION ascending */
  PrepmtvatcalctypeoptionAsc = '_PREPMTVATCALCTYPEOPTION_ASC_',
  /** sort on PrepmtVATCalcTypeOPTION descending */
  PrepmtvatcalctypeoptionDesc = '_PREPMTVATCALCTYPEOPTION_DESC_',
  /** Sort on PrepaymentVATIdentifier ascending */
  PrepaymentvatidentifierAsc = '_PREPAYMENTVATIDENTIFIER_ASC_',
  /** sort on PrepaymentVATIdentifier descending */
  PrepaymentvatidentifierDesc = '_PREPAYMENTVATIDENTIFIER_DESC_',
  /** Sort on PrepaymentTaxAreaCode ascending */
  PrepaymenttaxareacodeAsc = '_PREPAYMENTTAXAREACODE_ASC_',
  /** sort on PrepaymentTaxAreaCode descending */
  PrepaymenttaxareacodeDesc = '_PREPAYMENTTAXAREACODE_DESC_',
  /** Sort on PrepaymentTaxLiable ascending */
  PrepaymenttaxliableAsc = '_PREPAYMENTTAXLIABLE_ASC_',
  /** sort on PrepaymentTaxLiable descending */
  PrepaymenttaxliableDesc = '_PREPAYMENTTAXLIABLE_DESC_',
  /** Sort on PrepaymentTaxGroupCode ascending */
  PrepaymenttaxgroupcodeAsc = '_PREPAYMENTTAXGROUPCODE_ASC_',
  /** sort on PrepaymentTaxGroupCode descending */
  PrepaymenttaxgroupcodeDesc = '_PREPAYMENTTAXGROUPCODE_DESC_',
  /** Sort on PrepmtAmttoDeduct ascending */
  PrepmtamttodeductAsc = '_PREPMTAMTTODEDUCT_ASC_',
  /** sort on PrepmtAmttoDeduct descending */
  PrepmtamttodeductDesc = '_PREPMTAMTTODEDUCT_DESC_',
  /** Sort on PrepmtAmtDeducted ascending */
  PrepmtamtdeductedAsc = '_PREPMTAMTDEDUCTED_ASC_',
  /** sort on PrepmtAmtDeducted descending */
  PrepmtamtdeductedDesc = '_PREPMTAMTDEDUCTED_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on PrepmtAmountInvInclVAT ascending */
  PrepmtamountinvinclvatAsc = '_PREPMTAMOUNTINVINCLVAT_ASC_',
  /** sort on PrepmtAmountInvInclVAT descending */
  PrepmtamountinvinclvatDesc = '_PREPMTAMOUNTINVINCLVAT_DESC_',
  /** Sort on PrepmtAmountInvLCY ascending */
  PrepmtamountinvlcyAsc = '_PREPMTAMOUNTINVLCY_ASC_',
  /** sort on PrepmtAmountInvLCY descending */
  PrepmtamountinvlcyDesc = '_PREPMTAMOUNTINVLCY_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PrepmtVATAmountInvLCY ascending */
  PrepmtvatamountinvlcyAsc = '_PREPMTVATAMOUNTINVLCY_ASC_',
  /** sort on PrepmtVATAmountInvLCY descending */
  PrepmtvatamountinvlcyDesc = '_PREPMTVATAMOUNTINVLCY_DESC_',
  /** Sort on PrepaymentVATDifference ascending */
  PrepaymentvatdifferenceAsc = '_PREPAYMENTVATDIFFERENCE_ASC_',
  /** sort on PrepaymentVATDifference descending */
  PrepaymentvatdifferenceDesc = '_PREPAYMENTVATDIFFERENCE_DESC_',
  /** Sort on PrepmtVATDifftoDeduct ascending */
  PrepmtvatdifftodeductAsc = '_PREPMTVATDIFFTODEDUCT_ASC_',
  /** sort on PrepmtVATDifftoDeduct descending */
  PrepmtvatdifftodeductDesc = '_PREPMTVATDIFFTODEDUCT_DESC_',
  /** Sort on PrepmtVATDiffDeducted ascending */
  PrepmtvatdiffdeductedAsc = '_PREPMTVATDIFFDEDUCTED_ASC_',
  /** sort on PrepmtVATDiffDeducted descending */
  PrepmtvatdiffdeductedDesc = '_PREPMTVATDIFFDEDUCTED_DESC_',
  /** Sort on LineDiscountCalculation ascending */
  LinediscountcalculationAsc = '_LINEDISCOUNTCALCULATION_ASC_',
  /** sort on LineDiscountCalculation descending */
  LinediscountcalculationDesc = '_LINEDISCOUNTCALCULATION_DESC_',
  /** Sort on LineDiscountCalculationOPTION ascending */
  LinediscountcalculationoptionAsc = '_LINEDISCOUNTCALCULATIONOPTION_ASC_',
  /** sort on LineDiscountCalculationOPTION descending */
  LinediscountcalculationoptionDesc = '_LINEDISCOUNTCALCULATIONOPTION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on QtytoAssembletoOrder ascending */
  QtytoassembletoorderAsc = '_QTYTOASSEMBLETOORDER_ASC_',
  /** sort on QtytoAssembletoOrder descending */
  QtytoassembletoorderDesc = '_QTYTOASSEMBLETOORDER_DESC_',
  /** Sort on QtytoAsmtoOrderBase ascending */
  QtytoasmtoorderbaseAsc = '_QTYTOASMTOORDERBASE_ASC_',
  /** sort on QtytoAsmtoOrderBase descending */
  QtytoasmtoorderbaseDesc = '_QTYTOASMTOORDERBASE_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobContractEntryNo ascending */
  JobcontractentrynoAsc = '_JOBCONTRACTENTRYNO_ASC_',
  /** sort on JobContractEntryNo descending */
  JobcontractentrynoDesc = '_JOBCONTRACTENTRYNO_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on ReturnsDeferralStartDate ascending */
  ReturnsdeferralstartdateAsc = '_RETURNSDEFERRALSTARTDATE_ASC_',
  /** sort on ReturnsDeferralStartDate descending */
  ReturnsdeferralstartdateDesc = '_RETURNSDEFERRALSTARTDATE_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on Planned ascending */
  PlannedAsc = '_PLANNED_ASC_',
  /** sort on Planned descending */
  PlannedDesc = '_PLANNED_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on OutstandingQtyBase ascending */
  OutstandingqtybaseAsc = '_OUTSTANDINGQTYBASE_ASC_',
  /** sort on OutstandingQtyBase descending */
  OutstandingqtybaseDesc = '_OUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoInvoiceBase ascending */
  QtytoinvoicebaseAsc = '_QTYTOINVOICEBASE_ASC_',
  /** sort on QtytoInvoiceBase descending */
  QtytoinvoicebaseDesc = '_QTYTOINVOICEBASE_DESC_',
  /** Sort on QtytoShipBase ascending */
  QtytoshipbaseAsc = '_QTYTOSHIPBASE_ASC_',
  /** sort on QtytoShipBase descending */
  QtytoshipbaseDesc = '_QTYTOSHIPBASE_DESC_',
  /** Sort on QtyShippedNotInvdBase ascending */
  QtyshippednotinvdbaseAsc = '_QTYSHIPPEDNOTINVDBASE_ASC_',
  /** sort on QtyShippedNotInvdBase descending */
  QtyshippednotinvdbaseDesc = '_QTYSHIPPEDNOTINVDBASE_DESC_',
  /** Sort on QtyShippedBase ascending */
  QtyshippedbaseAsc = '_QTYSHIPPEDBASE_ASC_',
  /** sort on QtyShippedBase descending */
  QtyshippedbaseDesc = '_QTYSHIPPEDBASE_DESC_',
  /** Sort on QtyInvoicedBase ascending */
  QtyinvoicedbaseAsc = '_QTYINVOICEDBASE_ASC_',
  /** sort on QtyInvoicedBase descending */
  QtyinvoicedbaseDesc = '_QTYINVOICEDBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on OutofStockSubstitution ascending */
  OutofstocksubstitutionAsc = '_OUTOFSTOCKSUBSTITUTION_ASC_',
  /** sort on OutofStockSubstitution descending */
  OutofstocksubstitutionDesc = '_OUTOFSTOCKSUBSTITUTION_DESC_',
  /** Sort on OriginallyOrderedNo ascending */
  OriginallyorderednoAsc = '_ORIGINALLYORDEREDNO_ASC_',
  /** sort on OriginallyOrderedNo descending */
  OriginallyorderednoDesc = '_ORIGINALLYORDEREDNO_DESC_',
  /** Sort on OriginallyOrderedVarCode ascending */
  OriginallyorderedvarcodeAsc = '_ORIGINALLYORDEREDVARCODE_ASC_',
  /** sort on OriginallyOrderedVarCode descending */
  OriginallyorderedvarcodeDesc = '_ORIGINALLYORDEREDVARCODE_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on SpecialOrder ascending */
  SpecialorderAsc = '_SPECIALORDER_ASC_',
  /** sort on SpecialOrder descending */
  SpecialorderDesc = '_SPECIALORDER_DESC_',
  /** Sort on SpecialOrderPurchaseNo ascending */
  SpecialorderpurchasenoAsc = '_SPECIALORDERPURCHASENO_ASC_',
  /** sort on SpecialOrderPurchaseNo descending */
  SpecialorderpurchasenoDesc = '_SPECIALORDERPURCHASENO_DESC_',
  /** Sort on SpecialOrderPurchLineNo ascending */
  SpecialorderpurchlinenoAsc = '_SPECIALORDERPURCHLINENO_ASC_',
  /** sort on SpecialOrderPurchLineNo descending */
  SpecialorderpurchlinenoDesc = '_SPECIALORDERPURCHLINENO_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PlannedDeliveryDate ascending */
  PlanneddeliverydateAsc = '_PLANNEDDELIVERYDATE_ASC_',
  /** sort on PlannedDeliveryDate descending */
  PlanneddeliverydateDesc = '_PLANNEDDELIVERYDATE_DESC_',
  /** Sort on PlannedShipmentDate ascending */
  PlannedshipmentdateAsc = '_PLANNEDSHIPMENTDATE_ASC_',
  /** sort on PlannedShipmentDate descending */
  PlannedshipmentdateDesc = '_PLANNEDSHIPMENTDATE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on AllowItemChargeAssignment ascending */
  AllowitemchargeassignmentAsc = '_ALLOWITEMCHARGEASSIGNMENT_ASC_',
  /** sort on AllowItemChargeAssignment descending */
  AllowitemchargeassignmentDesc = '_ALLOWITEMCHARGEASSIGNMENT_DESC_',
  /** Sort on ReturnQtytoReceive ascending */
  ReturnqtytoreceiveAsc = '_RETURNQTYTORECEIVE_ASC_',
  /** sort on ReturnQtytoReceive descending */
  ReturnqtytoreceiveDesc = '_RETURNQTYTORECEIVE_DESC_',
  /** Sort on ReturnQtytoReceiveBase ascending */
  ReturnqtytoreceivebaseAsc = '_RETURNQTYTORECEIVEBASE_ASC_',
  /** sort on ReturnQtytoReceiveBase descending */
  ReturnqtytoreceivebaseDesc = '_RETURNQTYTORECEIVEBASE_DESC_',
  /** Sort on ReturnQtyRcdNotInvd ascending */
  ReturnqtyrcdnotinvdAsc = '_RETURNQTYRCDNOTINVD_ASC_',
  /** sort on ReturnQtyRcdNotInvd descending */
  ReturnqtyrcdnotinvdDesc = '_RETURNQTYRCDNOTINVD_DESC_',
  /** Sort on RetQtyRcdNotInvdBase ascending */
  RetqtyrcdnotinvdbaseAsc = '_RETQTYRCDNOTINVDBASE_ASC_',
  /** sort on RetQtyRcdNotInvdBase descending */
  RetqtyrcdnotinvdbaseDesc = '_RETQTYRCDNOTINVDBASE_DESC_',
  /** Sort on ReturnRcdNotInvd ascending */
  ReturnrcdnotinvdAsc = '_RETURNRCDNOTINVD_ASC_',
  /** sort on ReturnRcdNotInvd descending */
  ReturnrcdnotinvdDesc = '_RETURNRCDNOTINVD_DESC_',
  /** Sort on ReturnRcdNotInvdLCY ascending */
  ReturnrcdnotinvdlcyAsc = '_RETURNRCDNOTINVDLCY_ASC_',
  /** sort on ReturnRcdNotInvdLCY descending */
  ReturnrcdnotinvdlcyDesc = '_RETURNRCDNOTINVDLCY_DESC_',
  /** Sort on ReturnQtyReceived ascending */
  ReturnqtyreceivedAsc = '_RETURNQTYRECEIVED_ASC_',
  /** sort on ReturnQtyReceived descending */
  ReturnqtyreceivedDesc = '_RETURNQTYRECEIVED_DESC_',
  /** Sort on ReturnQtyReceivedBase ascending */
  ReturnqtyreceivedbaseAsc = '_RETURNQTYRECEIVEDBASE_ASC_',
  /** sort on ReturnQtyReceivedBase descending */
  ReturnqtyreceivedbaseDesc = '_RETURNQTYRECEIVEDBASE_DESC_',
  /** Sort on ApplfromItemEntry ascending */
  ApplfromitementryAsc = '_APPLFROMITEMENTRY_ASC_',
  /** sort on ApplfromItemEntry descending */
  ApplfromitementryDesc = '_APPLFROMITEMENTRY_DESC_',
  /** Sort on BOMItemNo ascending */
  BomitemnoAsc = '_BOMITEMNO_ASC_',
  /** sort on BOMItemNo descending */
  BomitemnoDesc = '_BOMITEMNO_DESC_',
  /** Sort on ReturnReceiptNo ascending */
  ReturnreceiptnoAsc = '_RETURNRECEIPTNO_ASC_',
  /** sort on ReturnReceiptNo descending */
  ReturnreceiptnoDesc = '_RETURNRECEIPTNO_DESC_',
  /** Sort on ReturnReceiptLineNo ascending */
  ReturnreceiptlinenoAsc = '_RETURNRECEIPTLINENO_ASC_',
  /** sort on ReturnReceiptLineNo descending */
  ReturnreceiptlinenoDesc = '_RETURNRECEIPTLINENO_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on Subtype ascending */
  SubtypeAsc = '_SUBTYPE_ASC_',
  /** sort on Subtype descending */
  SubtypeDesc = '_SUBTYPE_DESC_',
  /** Sort on SubtypeOPTION ascending */
  SubtypeoptionAsc = '_SUBTYPEOPTION_ASC_',
  /** sort on SubtypeOPTION descending */
  SubtypeoptionDesc = '_SUBTYPEOPTION_DESC_',
  /** Sort on Pricedescription ascending */
  PricedescriptionAsc = '_PRICEDESCRIPTION_ASC_',
  /** sort on Pricedescription descending */
  PricedescriptionDesc = '_PRICEDESCRIPTION_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on EQMCustomerProject ascending */
  EqmcustomerprojectAsc = '_EQMCUSTOMERPROJECT_ASC_',
  /** sort on EQMCustomerProject descending */
  EqmcustomerprojectDesc = '_EQMCUSTOMERPROJECT_DESC_',
  /** Sort on ChargeType ascending */
  ChargetypeAsc = '_CHARGETYPE_ASC_',
  /** sort on ChargeType descending */
  ChargetypeDesc = '_CHARGETYPE_DESC_',
  /** Sort on ChargeTypeOPTION ascending */
  ChargetypeoptionAsc = '_CHARGETYPEOPTION_ASC_',
  /** sort on ChargeTypeOPTION descending */
  ChargetypeoptionDesc = '_CHARGETYPEOPTION_DESC_',
  /** Sort on TextconnectedtoLineNo ascending */
  TextconnectedtolinenoAsc = '_TEXTCONNECTEDTOLINENO_ASC_',
  /** sort on TextconnectedtoLineNo descending */
  TextconnectedtolinenoDesc = '_TEXTCONNECTEDTOLINENO_DESC_',
  /** Sort on ConnectedtoItemLine ascending */
  ConnectedtoitemlineAsc = '_CONNECTEDTOITEMLINE_ASC_',
  /** sort on ConnectedtoItemLine descending */
  ConnectedtoitemlineDesc = '_CONNECTEDTOITEMLINE_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMRental ascending */
  EqmrentalAsc = '_EQMRENTAL_ASC_',
  /** sort on EQMRental descending */
  EqmrentalDesc = '_EQMRENTAL_DESC_',
  /** Sort on EQMRentalFromDate ascending */
  EqmrentalfromdateAsc = '_EQMRENTALFROMDATE_ASC_',
  /** sort on EQMRentalFromDate descending */
  EqmrentalfromdateDesc = '_EQMRENTALFROMDATE_DESC_',
  /** Sort on EQMRentalToDate ascending */
  EqmrentaltodateAsc = '_EQMRENTALTODATE_ASC_',
  /** sort on EQMRentalToDate descending */
  EqmrentaltodateDesc = '_EQMRENTALTODATE_DESC_',
  /** Sort on EQMRentalLineNo ascending */
  EqmrentallinenoAsc = '_EQMRENTALLINENO_ASC_',
  /** sort on EQMRentalLineNo descending */
  EqmrentallinenoDesc = '_EQMRENTALLINENO_DESC_',
  /** Sort on EQMRentalDays ascending */
  EqmrentaldaysAsc = '_EQMRENTALDAYS_ASC_',
  /** sort on EQMRentalDays descending */
  EqmrentaldaysDesc = '_EQMRENTALDAYS_DESC_',
  /** Sort on EQMAttachedtoNo ascending */
  EqmattachedtonoAsc = '_EQMATTACHEDTONO_ASC_',
  /** sort on EQMAttachedtoNo descending */
  EqmattachedtonoDesc = '_EQMATTACHEDTONO_DESC_',
  /** Sort on EQMAttachedtoLineNo ascending */
  EqmattachedtolinenoAsc = '_EQMATTACHEDTOLINENO_ASC_',
  /** sort on EQMAttachedtoLineNo descending */
  EqmattachedtolinenoDesc = '_EQMATTACHEDTOLINENO_DESC_',
  /** Sort on EQMRentalFreeDays ascending */
  EqmrentalfreedaysAsc = '_EQMRENTALFREEDAYS_ASC_',
  /** sort on EQMRentalFreeDays descending */
  EqmrentalfreedaysDesc = '_EQMRENTALFREEDAYS_DESC_',
  /** Sort on EQMLineUpdated ascending */
  EqmlineupdatedAsc = '_EQMLINEUPDATED_ASC_',
  /** sort on EQMLineUpdated descending */
  EqmlineupdatedDesc = '_EQMLINEUPDATED_DESC_',
  /** Sort on EQMRentalSale ascending */
  EqmrentalsaleAsc = '_EQMRENTALSALE_ASC_',
  /** sort on EQMRentalSale descending */
  EqmrentalsaleDesc = '_EQMRENTALSALE_DESC_',
  /** Sort on EQMRentalQuantity ascending */
  EqmrentalquantityAsc = '_EQMRENTALQUANTITY_ASC_',
  /** sort on EQMRentalQuantity descending */
  EqmrentalquantityDesc = '_EQMRENTALQUANTITY_DESC_',
  /** Sort on EQMRentalNo ascending */
  EqmrentalnoAsc = '_EQMRENTALNO_ASC_',
  /** sort on EQMRentalNo descending */
  EqmrentalnoDesc = '_EQMRENTALNO_DESC_',
  /** Sort on EQMReturnDocumentNo ascending */
  EqmreturndocumentnoAsc = '_EQMRETURNDOCUMENTNO_ASC_',
  /** sort on EQMReturnDocumentNo descending */
  EqmreturndocumentnoDesc = '_EQMRETURNDOCUMENTNO_DESC_',
  /** Sort on EQMReturnLineNo ascending */
  EqmreturnlinenoAsc = '_EQMRETURNLINENO_ASC_',
  /** sort on EQMReturnLineNo descending */
  EqmreturnlinenoDesc = '_EQMRETURNLINENO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMRentalFromDateTime ascending */
  EqmrentalfromdatetimeAsc = '_EQMRENTALFROMDATETIME_ASC_',
  /** sort on EQMRentalFromDateTime descending */
  EqmrentalfromdatetimeDesc = '_EQMRENTALFROMDATETIME_DESC_',
  /** Sort on EQMRentalToDateTime ascending */
  EqmrentaltodatetimeAsc = '_EQMRENTALTODATETIME_ASC_',
  /** sort on EQMRentalToDateTime descending */
  EqmrentaltodatetimeDesc = '_EQMRENTALTODATETIME_DESC_',
  /** Sort on EQMRentalDuration ascending */
  EqmrentaldurationAsc = '_EQMRENTALDURATION_ASC_',
  /** sort on EQMRentalDuration descending */
  EqmrentaldurationDesc = '_EQMRENTALDURATION_DESC_',
  /** Sort on EQMServiceUpwrite ascending */
  EqmserviceupwriteAsc = '_EQMSERVICEUPWRITE_ASC_',
  /** sort on EQMServiceUpwrite descending */
  EqmserviceupwriteDesc = '_EQMSERVICEUPWRITE_DESC_',
  /** Sort on EQMAllocateObjectNo ascending */
  EqmallocateobjectnoAsc = '_EQMALLOCATEOBJECTNO_ASC_',
  /** sort on EQMAllocateObjectNo descending */
  EqmallocateobjectnoDesc = '_EQMALLOCATEOBJECTNO_DESC_',
  /** Sort on EQMRentalLineType ascending */
  EqmrentallinetypeAsc = '_EQMRENTALLINETYPE_ASC_',
  /** sort on EQMRentalLineType descending */
  EqmrentallinetypeDesc = '_EQMRENTALLINETYPE_DESC_',
  /** Sort on EQMRentalLineTypeOPTION ascending */
  EqmrentallinetypeoptionAsc = '_EQMRENTALLINETYPEOPTION_ASC_',
  /** sort on EQMRentalLineTypeOPTION descending */
  EqmrentallinetypeoptionDesc = '_EQMRENTALLINETYPEOPTION_DESC_',
  /** Sort on EQMUseOfFixPrice ascending */
  EqmuseoffixpriceAsc = '_EQMUSEOFFIXPRICE_ASC_',
  /** sort on EQMUseOfFixPrice descending */
  EqmuseoffixpriceDesc = '_EQMUSEOFFIXPRICE_DESC_',
  /** Sort on EQMUpdateRentalLine ascending */
  EqmupdaterentallineAsc = '_EQMUPDATERENTALLINE_ASC_',
  /** sort on EQMUpdateRentalLine descending */
  EqmupdaterentallineDesc = '_EQMUPDATERENTALLINE_DESC_',
  /** Sort on EQMWorkshop ascending */
  EqmworkshopAsc = '_EQMWORKSHOP_ASC_',
  /** sort on EQMWorkshop descending */
  EqmworkshopDesc = '_EQMWORKSHOP_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMWorkshopGuarantee ascending */
  EqmworkshopguaranteeAsc = '_EQMWORKSHOPGUARANTEE_ASC_',
  /** sort on EQMWorkshopGuarantee descending */
  EqmworkshopguaranteeDesc = '_EQMWORKSHOPGUARANTEE_DESC_',
  /** Sort on EQMWorkshopType ascending */
  EqmworkshoptypeAsc = '_EQMWORKSHOPTYPE_ASC_',
  /** sort on EQMWorkshopType descending */
  EqmworkshoptypeDesc = '_EQMWORKSHOPTYPE_DESC_',
  /** Sort on EQMWorkshopTypeOPTION ascending */
  EqmworkshoptypeoptionAsc = '_EQMWORKSHOPTYPEOPTION_ASC_',
  /** sort on EQMWorkshopTypeOPTION descending */
  EqmworkshoptypeoptionDesc = '_EQMWORKSHOPTYPEOPTION_DESC_',
  /** Sort on EQMSkipObjectIntoInv ascending */
  EqmskipobjectintoinvAsc = '_EQMSKIPOBJECTINTOINV_ASC_',
  /** sort on EQMSkipObjectIntoInv descending */
  EqmskipobjectintoinvDesc = '_EQMSKIPOBJECTINTOINV_DESC_',
  /** Sort on EQMPriceTermCode ascending */
  EqmpricetermcodeAsc = '_EQMPRICETERMCODE_ASC_',
  /** sort on EQMPriceTermCode descending */
  EqmpricetermcodeDesc = '_EQMPRICETERMCODE_DESC_',
  /** Sort on EQMRefBasisEntryNo ascending */
  EqmrefbasisentrynoAsc = '_EQMREFBASISENTRYNO_ASC_',
  /** sort on EQMRefBasisEntryNo descending */
  EqmrefbasisentrynoDesc = '_EQMREFBASISENTRYNO_DESC_',
  /** Sort on EQMStairPriceCode ascending */
  EqmstairpricecodeAsc = '_EQMSTAIRPRICECODE_ASC_',
  /** sort on EQMStairPriceCode descending */
  EqmstairpricecodeDesc = '_EQMSTAIRPRICECODE_DESC_',
  /** Sort on EQMStairPriceStep ascending */
  EqmstairpricestepAsc = '_EQMSTAIRPRICESTEP_ASC_',
  /** sort on EQMStairPriceStep descending */
  EqmstairpricestepDesc = '_EQMSTAIRPRICESTEP_DESC_',
  /** Sort on EQMRentalKITMother ascending */
  EqmrentalkitmotherAsc = '_EQMRENTALKITMOTHER_ASC_',
  /** sort on EQMRentalKITMother descending */
  EqmrentalkitmotherDesc = '_EQMRENTALKITMOTHER_DESC_',
  /** Sort on EQMRentalKITMotherLineNo ascending */
  EqmrentalkitmotherlinenoAsc = '_EQMRENTALKITMOTHERLINENO_ASC_',
  /** sort on EQMRentalKITMotherLineNo descending */
  EqmrentalkitmotherlinenoDesc = '_EQMRENTALKITMOTHERLINENO_DESC_',
  /** Sort on EQMDepositPosting ascending */
  EqmdepositpostingAsc = '_EQMDEPOSITPOSTING_ASC_',
  /** sort on EQMDepositPosting descending */
  EqmdepositpostingDesc = '_EQMDEPOSITPOSTING_DESC_',
  /** Sort on EQMOldTmpRKITLineNo ascending */
  EqmoldtmprkitlinenoAsc = '_EQMOLDTMPRKITLINENO_ASC_',
  /** sort on EQMOldTmpRKITLineNo descending */
  EqmoldtmprkitlinenoDesc = '_EQMOLDTMPRKITLINENO_DESC_',
  /** Sort on EQMContractType ascending */
  EqmcontracttypeAsc = '_EQMCONTRACTTYPE_ASC_',
  /** sort on EQMContractType descending */
  EqmcontracttypeDesc = '_EQMCONTRACTTYPE_DESC_',
  /** Sort on EQMContractTypeOPTION ascending */
  EqmcontracttypeoptionAsc = '_EQMCONTRACTTYPEOPTION_ASC_',
  /** sort on EQMContractTypeOPTION descending */
  EqmcontracttypeoptionDesc = '_EQMCONTRACTTYPEOPTION_DESC_',
  /** Sort on EQMQtyonAtttoLineNo ascending */
  EqmqtyonatttolinenoAsc = '_EQMQTYONATTTOLINENO_ASC_',
  /** sort on EQMQtyonAtttoLineNo descending */
  EqmqtyonatttolinenoDesc = '_EQMQTYONATTTOLINENO_DESC_',
  /** Sort on EQMReRentObject ascending */
  EqmrerentobjectAsc = '_EQMRERENTOBJECT_ASC_',
  /** sort on EQMReRentObject descending */
  EqmrerentobjectDesc = '_EQMRERENTOBJECT_DESC_',
  /** Sort on EQMUsedFractionCalculation ascending */
  EqmusedfractioncalculationAsc = '_EQMUSEDFRACTIONCALCULATION_ASC_',
  /** sort on EQMUsedFractionCalculation descending */
  EqmusedfractioncalculationDesc = '_EQMUSEDFRACTIONCALCULATION_DESC_',
  /** Sort on EQMRentalHeaderLine ascending */
  EqmrentalheaderlineAsc = '_EQMRENTALHEADERLINE_ASC_',
  /** sort on EQMRentalHeaderLine descending */
  EqmrentalheaderlineDesc = '_EQMRENTALHEADERLINE_DESC_',
  /** Sort on EQMSerialNo ascending */
  EqmserialnoAsc = '_EQMSERIALNO_ASC_',
  /** sort on EQMSerialNo descending */
  EqmserialnoDesc = '_EQMSERIALNO_DESC_',
  /** Sort on EQMReclassOrderedBy ascending */
  EqmreclassorderedbyAsc = '_EQMRECLASSORDEREDBY_ASC_',
  /** sort on EQMReclassOrderedBy descending */
  EqmreclassorderedbyDesc = '_EQMRECLASSORDEREDBY_DESC_',
  /** Sort on EQMReclassOrderNo ascending */
  EqmreclassordernoAsc = '_EQMRECLASSORDERNO_ASC_',
  /** sort on EQMReclassOrderNo descending */
  EqmreclassordernoDesc = '_EQMRECLASSORDERNO_DESC_',
  /** Sort on EQMExtraHoursCharged ascending */
  EqmextrahourschargedAsc = '_EQMEXTRAHOURSCHARGED_ASC_',
  /** sort on EQMExtraHoursCharged descending */
  EqmextrahourschargedDesc = '_EQMEXTRAHOURSCHARGED_DESC_',
  /** Sort on EQMExtraHoursReturnNo ascending */
  EqmextrahoursreturnnoAsc = '_EQMEXTRAHOURSRETURNNO_ASC_',
  /** sort on EQMExtraHoursReturnNo descending */
  EqmextrahoursreturnnoDesc = '_EQMEXTRAHOURSRETURNNO_DESC_',
  /** Sort on EQMRentalSplitCostCenter ascending */
  EqmrentalsplitcostcenterAsc = '_EQMRENTALSPLITCOSTCENTER_ASC_',
  /** sort on EQMRentalSplitCostCenter descending */
  EqmrentalsplitcostcenterDesc = '_EQMRENTALSPLITCOSTCENTER_DESC_',
  /** Sort on EQMRentalSplit ascending */
  EqmrentalsplitAsc = '_EQMRENTALSPLIT_ASC_',
  /** sort on EQMRentalSplit descending */
  EqmrentalsplitDesc = '_EQMRENTALSPLIT_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMRePurchase ascending */
  EqmrepurchaseAsc = '_EQMREPURCHASE_ASC_',
  /** sort on EQMRePurchase descending */
  EqmrepurchaseDesc = '_EQMREPURCHASE_DESC_',
  /** Sort on EQMInvoiceFee ascending */
  EqminvoicefeeAsc = '_EQMINVOICEFEE_ASC_',
  /** sort on EQMInvoiceFee descending */
  EqminvoicefeeDesc = '_EQMINVOICEFEE_DESC_',
  /** Sort on EQMPriceScheduleCode ascending */
  EqmpriceschedulecodeAsc = '_EQMPRICESCHEDULECODE_ASC_',
  /** sort on EQMPriceScheduleCode descending */
  EqmpriceschedulecodeDesc = '_EQMPRICESCHEDULECODE_DESC_',
  /** Sort on EQMRentalPrepaymentEntry ascending */
  EqmrentalprepaymententryAsc = '_EQMRENTALPREPAYMENTENTRY_ASC_',
  /** sort on EQMRentalPrepaymentEntry descending */
  EqmrentalprepaymententryDesc = '_EQMRENTALPREPAYMENTENTRY_DESC_',
  /** Sort on EQMPrepmtAmtLCY ascending */
  EqmprepmtamtlcyAsc = '_EQMPREPMTAMTLCY_ASC_',
  /** sort on EQMPrepmtAmtLCY descending */
  EqmprepmtamtlcyDesc = '_EQMPREPMTAMTLCY_DESC_',
  /** Sort on EQMRentalInsurance ascending */
  EqmrentalinsuranceAsc = '_EQMRENTALINSURANCE_ASC_',
  /** sort on EQMRentalInsurance descending */
  EqmrentalinsuranceDesc = '_EQMRENTALINSURANCE_DESC_',
  /** Sort on EQMObjectInsurance ascending */
  EqmobjectinsuranceAsc = '_EQMOBJECTINSURANCE_ASC_',
  /** sort on EQMObjectInsurance descending */
  EqmobjectinsuranceDesc = '_EQMOBJECTINSURANCE_DESC_',
  /** Sort on EQMServiceMotherLineNo ascending */
  EqmservicemotherlinenoAsc = '_EQMSERVICEMOTHERLINENO_ASC_',
  /** sort on EQMServiceMotherLineNo descending */
  EqmservicemotherlinenoDesc = '_EQMSERVICEMOTHERLINENO_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMPriceReportedHours ascending */
  EqmpricereportedhoursAsc = '_EQMPRICEREPORTEDHOURS_ASC_',
  /** sort on EQMPriceReportedHours descending */
  EqmpricereportedhoursDesc = '_EQMPRICEREPORTEDHOURS_DESC_',
  /** Sort on EQMPriceReportedHoursOPTION ascending */
  EqmpricereportedhoursoptionAsc = '_EQMPRICEREPORTEDHOURSOPTION_ASC_',
  /** sort on EQMPriceReportedHoursOPTION descending */
  EqmpricereportedhoursoptionDesc = '_EQMPRICEREPORTEDHOURSOPTION_DESC_',
  /** Sort on EQMFACorrection ascending */
  EqmfacorrectionAsc = '_EQMFACORRECTION_ASC_',
  /** sort on EQMFACorrection descending */
  EqmfacorrectionDesc = '_EQMFACORRECTION_DESC_',
  /** Sort on EQMPrepmtAmtInclVAT ascending */
  EqmprepmtamtinclvatAsc = '_EQMPREPMTAMTINCLVAT_ASC_',
  /** sort on EQMPrepmtAmtInclVAT descending */
  EqmprepmtamtinclvatDesc = '_EQMPREPMTAMTINCLVAT_DESC_',
  /** Sort on ReservedQuantity ascending */
  ReservedquantityAsc = '_RESERVEDQUANTITY_ASC_',
  /** sort on ReservedQuantity descending */
  ReservedquantityDesc = '_RESERVEDQUANTITY_DESC_',
  /** Sort on ATOWhseOutstandingQty ascending */
  AtowhseoutstandingqtyAsc = '_ATOWHSEOUTSTANDINGQTY_ASC_',
  /** sort on ATOWhseOutstandingQty descending */
  AtowhseoutstandingqtyDesc = '_ATOWHSEOUTSTANDINGQTY_DESC_',
  /** Sort on ATOWhseOutstdQtyBase ascending */
  AtowhseoutstdqtybaseAsc = '_ATOWHSEOUTSTDQTYBASE_ASC_',
  /** sort on ATOWhseOutstdQtyBase descending */
  AtowhseoutstdqtybaseDesc = '_ATOWHSEOUTSTDQTYBASE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ReservedQtyBase ascending */
  ReservedqtybaseAsc = '_RESERVEDQTYBASE_ASC_',
  /** sort on ReservedQtyBase descending */
  ReservedqtybaseDesc = '_RESERVEDQTYBASE_DESC_',
  /** Sort on SubstitutionAvailable ascending */
  SubstitutionavailableAsc = '_SUBSTITUTIONAVAILABLE_ASC_',
  /** sort on SubstitutionAvailable descending */
  SubstitutionavailableDesc = '_SUBSTITUTIONAVAILABLE_DESC_',
  /** Sort on WhseOutstandingQty ascending */
  WhseoutstandingqtyAsc = '_WHSEOUTSTANDINGQTY_ASC_',
  /** sort on WhseOutstandingQty descending */
  WhseoutstandingqtyDesc = '_WHSEOUTSTANDINGQTY_DESC_',
  /** Sort on WhseOutstandingQtyBase ascending */
  WhseoutstandingqtybaseAsc = '_WHSEOUTSTANDINGQTYBASE_ASC_',
  /** sort on WhseOutstandingQtyBase descending */
  WhseoutstandingqtybaseDesc = '_WHSEOUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoAssign ascending */
  QtytoassignAsc = '_QTYTOASSIGN_ASC_',
  /** sort on QtytoAssign descending */
  QtytoassignDesc = '_QTYTOASSIGN_DESC_',
  /** Sort on QtyAssigned ascending */
  QtyassignedAsc = '_QTYASSIGNED_ASC_',
  /** sort on QtyAssigned descending */
  QtyassignedDesc = '_QTYASSIGNED_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on ProductType ascending */
  ProducttypeAsc = '_PRODUCTTYPE_ASC_',
  /** sort on ProductType descending */
  ProducttypeDesc = '_PRODUCTTYPE_DESC_',
  /** Sort on SalesOrderEdgeGuid ascending */
  SalesorderedgeguidAsc = '_SALESORDEREDGEGUID_ASC_',
  /** sort on SalesOrderEdgeGuid descending */
  SalesorderedgeguidDesc = '_SALESORDEREDGEGUID_DESC_',
  /** Sort on VersionNo ascending */
  VersionnoAsc = '_VERSIONNO_ASC_',
  /** sort on VersionNo descending */
  VersionnoDesc = '_VERSIONNO_DESC_',
  /** Sort on DocNoOccurrence ascending */
  DocnooccurrenceAsc = '_DOCNOOCCURRENCE_ASC_',
  /** sort on DocNoOccurrence descending */
  DocnooccurrenceDesc = '_DOCNOOCCURRENCE_DESC_',
  /** Sort on SalesOrderLineEdgeGuid ascending */
  SalesorderlineedgeguidAsc = '_SALESORDERLINEEDGEGUID_ASC_',
  /** sort on SalesOrderLineEdgeGuid descending */
  SalesorderlineedgeguidDesc = '_SALESORDERLINEEDGEGUID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesLineInput {
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on DocumentGlobalId ascending */
  DocumentglobalidAsc = '_DOCUMENTGLOBALID_ASC_',
  /** sort on DocumentGlobalId descending */
  DocumentglobalidDesc = '_DOCUMENTGLOBALID_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on ItemGlobalId ascending */
  ItemglobalidAsc = '_ITEMGLOBALID_ASC_',
  /** sort on ItemGlobalId descending */
  ItemglobalidDesc = '_ITEMGLOBALID_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on OutstandingQuantity ascending */
  OutstandingquantityAsc = '_OUTSTANDINGQUANTITY_ASC_',
  /** sort on OutstandingQuantity descending */
  OutstandingquantityDesc = '_OUTSTANDINGQUANTITY_DESC_',
  /** Sort on QtytoInvoice ascending */
  QtytoinvoiceAsc = '_QTYTOINVOICE_ASC_',
  /** sort on QtytoInvoice descending */
  QtytoinvoiceDesc = '_QTYTOINVOICE_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountPercent ascending */
  LinediscountpercentAsc = '_LINEDISCOUNTPERCENT_ASC_',
  /** sort on LineDiscountPercent descending */
  LinediscountpercentDesc = '_LINEDISCOUNTPERCENT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on OutstandingAmount ascending */
  OutstandingamountAsc = '_OUTSTANDINGAMOUNT_ASC_',
  /** sort on OutstandingAmount descending */
  OutstandingamountDesc = '_OUTSTANDINGAMOUNT_DESC_',
  /** Sort on QtyShippedNotInvoiced ascending */
  QtyshippednotinvoicedAsc = '_QTYSHIPPEDNOTINVOICED_ASC_',
  /** sort on QtyShippedNotInvoiced descending */
  QtyshippednotinvoicedDesc = '_QTYSHIPPEDNOTINVOICED_DESC_',
  /** Sort on ShippedNotInvoiced ascending */
  ShippednotinvoicedAsc = '_SHIPPEDNOTINVOICED_ASC_',
  /** sort on ShippedNotInvoiced descending */
  ShippednotinvoicedDesc = '_SHIPPEDNOTINVOICED_DESC_',
  /** Sort on QuantityShipped ascending */
  QuantityshippedAsc = '_QUANTITYSHIPPED_ASC_',
  /** sort on QuantityShipped descending */
  QuantityshippedDesc = '_QUANTITYSHIPPED_DESC_',
  /** Sort on QuantityInvoiced ascending */
  QuantityinvoicedAsc = '_QUANTITYINVOICED_ASC_',
  /** sort on QuantityInvoiced descending */
  QuantityinvoicedDesc = '_QUANTITYINVOICED_DESC_',
  /** Sort on ShipmentNo ascending */
  ShipmentnoAsc = '_SHIPMENTNO_ASC_',
  /** sort on ShipmentNo descending */
  ShipmentnoDesc = '_SHIPMENTNO_DESC_',
  /** Sort on ShipmentLineNo ascending */
  ShipmentlinenoAsc = '_SHIPMENTLINENO_ASC_',
  /** sort on ShipmentLineNo descending */
  ShipmentlinenoDesc = '_SHIPMENTLINENO_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on InvDiscountAmount ascending */
  InvdiscountamountAsc = '_INVDISCOUNTAMOUNT_ASC_',
  /** sort on InvDiscountAmount descending */
  InvdiscountamountDesc = '_INVDISCOUNTAMOUNT_DESC_',
  /** Sort on PurchaseOrderNo ascending */
  PurchaseordernoAsc = '_PURCHASEORDERNO_ASC_',
  /** sort on PurchaseOrderNo descending */
  PurchaseordernoDesc = '_PURCHASEORDERNO_DESC_',
  /** Sort on PurchOrderLineNo ascending */
  PurchorderlinenoAsc = '_PURCHORDERLINENO_ASC_',
  /** sort on PurchOrderLineNo descending */
  PurchorderlinenoDesc = '_PURCHORDERLINENO_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxCategory ascending */
  TaxcategoryAsc = '_TAXCATEGORY_ASC_',
  /** sort on TaxCategory descending */
  TaxcategoryDesc = '_TAXCATEGORY_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATClauseCode ascending */
  VatclausecodeAsc = '_VATCLAUSECODE_ASC_',
  /** sort on VATClauseCode descending */
  VatclausecodeDesc = '_VATCLAUSECODE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on OutstandingAmountLCY ascending */
  OutstandingamountlcyAsc = '_OUTSTANDINGAMOUNTLCY_ASC_',
  /** sort on OutstandingAmountLCY descending */
  OutstandingamountlcyDesc = '_OUTSTANDINGAMOUNTLCY_DESC_',
  /** Sort on ShippedNotInvoicedLCY ascending */
  ShippednotinvoicedlcyAsc = '_SHIPPEDNOTINVOICEDLCY_ASC_',
  /** sort on ShippedNotInvoicedLCY descending */
  ShippednotinvoicedlcyDesc = '_SHIPPEDNOTINVOICEDLCY_DESC_',
  /** Sort on ShippedNotInvLCYNoVAT ascending */
  ShippednotinvlcynovatAsc = '_SHIPPEDNOTINVLCYNOVAT_ASC_',
  /** sort on ShippedNotInvLCYNoVAT descending */
  ShippednotinvlcynovatDesc = '_SHIPPEDNOTINVLCYNOVAT_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on InvDiscAmounttoInvoice ascending */
  InvdiscamounttoinvoiceAsc = '_INVDISCAMOUNTTOINVOICE_ASC_',
  /** sort on InvDiscAmounttoInvoice descending */
  InvdiscamounttoinvoiceDesc = '_INVDISCAMOUNTTOINVOICE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepmtLineAmount ascending */
  PrepmtlineamountAsc = '_PREPMTLINEAMOUNT_ASC_',
  /** sort on PrepmtLineAmount descending */
  PrepmtlineamountDesc = '_PREPMTLINEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInv ascending */
  PrepmtamtinvAsc = '_PREPMTAMTINV_ASC_',
  /** sort on PrepmtAmtInv descending */
  PrepmtamtinvDesc = '_PREPMTAMTINV_DESC_',
  /** Sort on PrepmtAmtInclVAT ascending */
  PrepmtamtinclvatAsc = '_PREPMTAMTINCLVAT_ASC_',
  /** sort on PrepmtAmtInclVAT descending */
  PrepmtamtinclvatDesc = '_PREPMTAMTINCLVAT_DESC_',
  /** Sort on PrepaymentAmount ascending */
  PrepaymentamountAsc = '_PREPAYMENTAMOUNT_ASC_',
  /** sort on PrepaymentAmount descending */
  PrepaymentamountDesc = '_PREPAYMENTAMOUNT_DESC_',
  /** Sort on PrepmtVATBaseAmt ascending */
  PrepmtvatbaseamtAsc = '_PREPMTVATBASEAMT_ASC_',
  /** sort on PrepmtVATBaseAmt descending */
  PrepmtvatbaseamtDesc = '_PREPMTVATBASEAMT_DESC_',
  /** Sort on PrepaymentVAT ascending */
  PrepaymentvatAsc = '_PREPAYMENTVAT_ASC_',
  /** sort on PrepaymentVAT descending */
  PrepaymentvatDesc = '_PREPAYMENTVAT_DESC_',
  /** Sort on PrepmtVATCalcType ascending */
  PrepmtvatcalctypeAsc = '_PREPMTVATCALCTYPE_ASC_',
  /** sort on PrepmtVATCalcType descending */
  PrepmtvatcalctypeDesc = '_PREPMTVATCALCTYPE_DESC_',
  /** Sort on PrepmtVATCalcTypeOPTION ascending */
  PrepmtvatcalctypeoptionAsc = '_PREPMTVATCALCTYPEOPTION_ASC_',
  /** sort on PrepmtVATCalcTypeOPTION descending */
  PrepmtvatcalctypeoptionDesc = '_PREPMTVATCALCTYPEOPTION_DESC_',
  /** Sort on PrepaymentVATIdentifier ascending */
  PrepaymentvatidentifierAsc = '_PREPAYMENTVATIDENTIFIER_ASC_',
  /** sort on PrepaymentVATIdentifier descending */
  PrepaymentvatidentifierDesc = '_PREPAYMENTVATIDENTIFIER_DESC_',
  /** Sort on PrepaymentTaxAreaCode ascending */
  PrepaymenttaxareacodeAsc = '_PREPAYMENTTAXAREACODE_ASC_',
  /** sort on PrepaymentTaxAreaCode descending */
  PrepaymenttaxareacodeDesc = '_PREPAYMENTTAXAREACODE_DESC_',
  /** Sort on PrepaymentTaxLiable ascending */
  PrepaymenttaxliableAsc = '_PREPAYMENTTAXLIABLE_ASC_',
  /** sort on PrepaymentTaxLiable descending */
  PrepaymenttaxliableDesc = '_PREPAYMENTTAXLIABLE_DESC_',
  /** Sort on PrepaymentTaxGroupCode ascending */
  PrepaymenttaxgroupcodeAsc = '_PREPAYMENTTAXGROUPCODE_ASC_',
  /** sort on PrepaymentTaxGroupCode descending */
  PrepaymenttaxgroupcodeDesc = '_PREPAYMENTTAXGROUPCODE_DESC_',
  /** Sort on PrepmtAmttoDeduct ascending */
  PrepmtamttodeductAsc = '_PREPMTAMTTODEDUCT_ASC_',
  /** sort on PrepmtAmttoDeduct descending */
  PrepmtamttodeductDesc = '_PREPMTAMTTODEDUCT_DESC_',
  /** Sort on PrepmtAmtDeducted ascending */
  PrepmtamtdeductedAsc = '_PREPMTAMTDEDUCTED_ASC_',
  /** sort on PrepmtAmtDeducted descending */
  PrepmtamtdeductedDesc = '_PREPMTAMTDEDUCTED_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on PrepmtAmountInvInclVAT ascending */
  PrepmtamountinvinclvatAsc = '_PREPMTAMOUNTINVINCLVAT_ASC_',
  /** sort on PrepmtAmountInvInclVAT descending */
  PrepmtamountinvinclvatDesc = '_PREPMTAMOUNTINVINCLVAT_DESC_',
  /** Sort on PrepmtAmountInvLCY ascending */
  PrepmtamountinvlcyAsc = '_PREPMTAMOUNTINVLCY_ASC_',
  /** sort on PrepmtAmountInvLCY descending */
  PrepmtamountinvlcyDesc = '_PREPMTAMOUNTINVLCY_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PrepmtVATAmountInvLCY ascending */
  PrepmtvatamountinvlcyAsc = '_PREPMTVATAMOUNTINVLCY_ASC_',
  /** sort on PrepmtVATAmountInvLCY descending */
  PrepmtvatamountinvlcyDesc = '_PREPMTVATAMOUNTINVLCY_DESC_',
  /** Sort on PrepaymentVATDifference ascending */
  PrepaymentvatdifferenceAsc = '_PREPAYMENTVATDIFFERENCE_ASC_',
  /** sort on PrepaymentVATDifference descending */
  PrepaymentvatdifferenceDesc = '_PREPAYMENTVATDIFFERENCE_DESC_',
  /** Sort on PrepmtVATDifftoDeduct ascending */
  PrepmtvatdifftodeductAsc = '_PREPMTVATDIFFTODEDUCT_ASC_',
  /** sort on PrepmtVATDifftoDeduct descending */
  PrepmtvatdifftodeductDesc = '_PREPMTVATDIFFTODEDUCT_DESC_',
  /** Sort on PrepmtVATDiffDeducted ascending */
  PrepmtvatdiffdeductedAsc = '_PREPMTVATDIFFDEDUCTED_ASC_',
  /** sort on PrepmtVATDiffDeducted descending */
  PrepmtvatdiffdeductedDesc = '_PREPMTVATDIFFDEDUCTED_DESC_',
  /** Sort on LineDiscountCalculation ascending */
  LinediscountcalculationAsc = '_LINEDISCOUNTCALCULATION_ASC_',
  /** sort on LineDiscountCalculation descending */
  LinediscountcalculationDesc = '_LINEDISCOUNTCALCULATION_DESC_',
  /** Sort on LineDiscountCalculationOPTION ascending */
  LinediscountcalculationoptionAsc = '_LINEDISCOUNTCALCULATIONOPTION_ASC_',
  /** sort on LineDiscountCalculationOPTION descending */
  LinediscountcalculationoptionDesc = '_LINEDISCOUNTCALCULATIONOPTION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on QtytoAssembletoOrder ascending */
  QtytoassembletoorderAsc = '_QTYTOASSEMBLETOORDER_ASC_',
  /** sort on QtytoAssembletoOrder descending */
  QtytoassembletoorderDesc = '_QTYTOASSEMBLETOORDER_DESC_',
  /** Sort on QtytoAsmtoOrderBase ascending */
  QtytoasmtoorderbaseAsc = '_QTYTOASMTOORDERBASE_ASC_',
  /** sort on QtytoAsmtoOrderBase descending */
  QtytoasmtoorderbaseDesc = '_QTYTOASMTOORDERBASE_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobContractEntryNo ascending */
  JobcontractentrynoAsc = '_JOBCONTRACTENTRYNO_ASC_',
  /** sort on JobContractEntryNo descending */
  JobcontractentrynoDesc = '_JOBCONTRACTENTRYNO_DESC_',
  /** Sort on DeferralCode ascending */
  DeferralcodeAsc = '_DEFERRALCODE_ASC_',
  /** sort on DeferralCode descending */
  DeferralcodeDesc = '_DEFERRALCODE_DESC_',
  /** Sort on ReturnsDeferralStartDate ascending */
  ReturnsdeferralstartdateAsc = '_RETURNSDEFERRALSTARTDATE_ASC_',
  /** sort on ReturnsDeferralStartDate descending */
  ReturnsdeferralstartdateDesc = '_RETURNSDEFERRALSTARTDATE_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on Planned ascending */
  PlannedAsc = '_PLANNED_ASC_',
  /** sort on Planned descending */
  PlannedDesc = '_PLANNED_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on OutstandingQtyBase ascending */
  OutstandingqtybaseAsc = '_OUTSTANDINGQTYBASE_ASC_',
  /** sort on OutstandingQtyBase descending */
  OutstandingqtybaseDesc = '_OUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoInvoiceBase ascending */
  QtytoinvoicebaseAsc = '_QTYTOINVOICEBASE_ASC_',
  /** sort on QtytoInvoiceBase descending */
  QtytoinvoicebaseDesc = '_QTYTOINVOICEBASE_DESC_',
  /** Sort on QtytoShipBase ascending */
  QtytoshipbaseAsc = '_QTYTOSHIPBASE_ASC_',
  /** sort on QtytoShipBase descending */
  QtytoshipbaseDesc = '_QTYTOSHIPBASE_DESC_',
  /** Sort on QtyShippedNotInvdBase ascending */
  QtyshippednotinvdbaseAsc = '_QTYSHIPPEDNOTINVDBASE_ASC_',
  /** sort on QtyShippedNotInvdBase descending */
  QtyshippednotinvdbaseDesc = '_QTYSHIPPEDNOTINVDBASE_DESC_',
  /** Sort on QtyShippedBase ascending */
  QtyshippedbaseAsc = '_QTYSHIPPEDBASE_ASC_',
  /** sort on QtyShippedBase descending */
  QtyshippedbaseDesc = '_QTYSHIPPEDBASE_DESC_',
  /** Sort on QtyInvoicedBase ascending */
  QtyinvoicedbaseAsc = '_QTYINVOICEDBASE_ASC_',
  /** sort on QtyInvoicedBase descending */
  QtyinvoicedbaseDesc = '_QTYINVOICEDBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on OutofStockSubstitution ascending */
  OutofstocksubstitutionAsc = '_OUTOFSTOCKSUBSTITUTION_ASC_',
  /** sort on OutofStockSubstitution descending */
  OutofstocksubstitutionDesc = '_OUTOFSTOCKSUBSTITUTION_DESC_',
  /** Sort on OriginallyOrderedNo ascending */
  OriginallyorderednoAsc = '_ORIGINALLYORDEREDNO_ASC_',
  /** sort on OriginallyOrderedNo descending */
  OriginallyorderednoDesc = '_ORIGINALLYORDEREDNO_DESC_',
  /** Sort on OriginallyOrderedVarCode ascending */
  OriginallyorderedvarcodeAsc = '_ORIGINALLYORDEREDVARCODE_ASC_',
  /** sort on OriginallyOrderedVarCode descending */
  OriginallyorderedvarcodeDesc = '_ORIGINALLYORDEREDVARCODE_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on SpecialOrder ascending */
  SpecialorderAsc = '_SPECIALORDER_ASC_',
  /** sort on SpecialOrder descending */
  SpecialorderDesc = '_SPECIALORDER_DESC_',
  /** Sort on SpecialOrderPurchaseNo ascending */
  SpecialorderpurchasenoAsc = '_SPECIALORDERPURCHASENO_ASC_',
  /** sort on SpecialOrderPurchaseNo descending */
  SpecialorderpurchasenoDesc = '_SPECIALORDERPURCHASENO_DESC_',
  /** Sort on SpecialOrderPurchLineNo ascending */
  SpecialorderpurchlinenoAsc = '_SPECIALORDERPURCHLINENO_ASC_',
  /** sort on SpecialOrderPurchLineNo descending */
  SpecialorderpurchlinenoDesc = '_SPECIALORDERPURCHLINENO_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PlannedDeliveryDate ascending */
  PlanneddeliverydateAsc = '_PLANNEDDELIVERYDATE_ASC_',
  /** sort on PlannedDeliveryDate descending */
  PlanneddeliverydateDesc = '_PLANNEDDELIVERYDATE_DESC_',
  /** Sort on PlannedShipmentDate ascending */
  PlannedshipmentdateAsc = '_PLANNEDSHIPMENTDATE_ASC_',
  /** sort on PlannedShipmentDate descending */
  PlannedshipmentdateDesc = '_PLANNEDSHIPMENTDATE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on AllowItemChargeAssignment ascending */
  AllowitemchargeassignmentAsc = '_ALLOWITEMCHARGEASSIGNMENT_ASC_',
  /** sort on AllowItemChargeAssignment descending */
  AllowitemchargeassignmentDesc = '_ALLOWITEMCHARGEASSIGNMENT_DESC_',
  /** Sort on ReturnQtytoReceive ascending */
  ReturnqtytoreceiveAsc = '_RETURNQTYTORECEIVE_ASC_',
  /** sort on ReturnQtytoReceive descending */
  ReturnqtytoreceiveDesc = '_RETURNQTYTORECEIVE_DESC_',
  /** Sort on ReturnQtytoReceiveBase ascending */
  ReturnqtytoreceivebaseAsc = '_RETURNQTYTORECEIVEBASE_ASC_',
  /** sort on ReturnQtytoReceiveBase descending */
  ReturnqtytoreceivebaseDesc = '_RETURNQTYTORECEIVEBASE_DESC_',
  /** Sort on ReturnQtyRcdNotInvd ascending */
  ReturnqtyrcdnotinvdAsc = '_RETURNQTYRCDNOTINVD_ASC_',
  /** sort on ReturnQtyRcdNotInvd descending */
  ReturnqtyrcdnotinvdDesc = '_RETURNQTYRCDNOTINVD_DESC_',
  /** Sort on RetQtyRcdNotInvdBase ascending */
  RetqtyrcdnotinvdbaseAsc = '_RETQTYRCDNOTINVDBASE_ASC_',
  /** sort on RetQtyRcdNotInvdBase descending */
  RetqtyrcdnotinvdbaseDesc = '_RETQTYRCDNOTINVDBASE_DESC_',
  /** Sort on ReturnRcdNotInvd ascending */
  ReturnrcdnotinvdAsc = '_RETURNRCDNOTINVD_ASC_',
  /** sort on ReturnRcdNotInvd descending */
  ReturnrcdnotinvdDesc = '_RETURNRCDNOTINVD_DESC_',
  /** Sort on ReturnRcdNotInvdLCY ascending */
  ReturnrcdnotinvdlcyAsc = '_RETURNRCDNOTINVDLCY_ASC_',
  /** sort on ReturnRcdNotInvdLCY descending */
  ReturnrcdnotinvdlcyDesc = '_RETURNRCDNOTINVDLCY_DESC_',
  /** Sort on ReturnQtyReceived ascending */
  ReturnqtyreceivedAsc = '_RETURNQTYRECEIVED_ASC_',
  /** sort on ReturnQtyReceived descending */
  ReturnqtyreceivedDesc = '_RETURNQTYRECEIVED_DESC_',
  /** Sort on ReturnQtyReceivedBase ascending */
  ReturnqtyreceivedbaseAsc = '_RETURNQTYRECEIVEDBASE_ASC_',
  /** sort on ReturnQtyReceivedBase descending */
  ReturnqtyreceivedbaseDesc = '_RETURNQTYRECEIVEDBASE_DESC_',
  /** Sort on ApplfromItemEntry ascending */
  ApplfromitementryAsc = '_APPLFROMITEMENTRY_ASC_',
  /** sort on ApplfromItemEntry descending */
  ApplfromitementryDesc = '_APPLFROMITEMENTRY_DESC_',
  /** Sort on BOMItemNo ascending */
  BomitemnoAsc = '_BOMITEMNO_ASC_',
  /** sort on BOMItemNo descending */
  BomitemnoDesc = '_BOMITEMNO_DESC_',
  /** Sort on ReturnReceiptNo ascending */
  ReturnreceiptnoAsc = '_RETURNRECEIPTNO_ASC_',
  /** sort on ReturnReceiptNo descending */
  ReturnreceiptnoDesc = '_RETURNRECEIPTNO_DESC_',
  /** Sort on ReturnReceiptLineNo ascending */
  ReturnreceiptlinenoAsc = '_RETURNRECEIPTLINENO_ASC_',
  /** sort on ReturnReceiptLineNo descending */
  ReturnreceiptlinenoDesc = '_RETURNRECEIPTLINENO_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on Subtype ascending */
  SubtypeAsc = '_SUBTYPE_ASC_',
  /** sort on Subtype descending */
  SubtypeDesc = '_SUBTYPE_DESC_',
  /** Sort on SubtypeOPTION ascending */
  SubtypeoptionAsc = '_SUBTYPEOPTION_ASC_',
  /** sort on SubtypeOPTION descending */
  SubtypeoptionDesc = '_SUBTYPEOPTION_DESC_',
  /** Sort on Pricedescription ascending */
  PricedescriptionAsc = '_PRICEDESCRIPTION_ASC_',
  /** sort on Pricedescription descending */
  PricedescriptionDesc = '_PRICEDESCRIPTION_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on EQMCustomerProject ascending */
  EqmcustomerprojectAsc = '_EQMCUSTOMERPROJECT_ASC_',
  /** sort on EQMCustomerProject descending */
  EqmcustomerprojectDesc = '_EQMCUSTOMERPROJECT_DESC_',
  /** Sort on ChargeType ascending */
  ChargetypeAsc = '_CHARGETYPE_ASC_',
  /** sort on ChargeType descending */
  ChargetypeDesc = '_CHARGETYPE_DESC_',
  /** Sort on ChargeTypeOPTION ascending */
  ChargetypeoptionAsc = '_CHARGETYPEOPTION_ASC_',
  /** sort on ChargeTypeOPTION descending */
  ChargetypeoptionDesc = '_CHARGETYPEOPTION_DESC_',
  /** Sort on TextconnectedtoLineNo ascending */
  TextconnectedtolinenoAsc = '_TEXTCONNECTEDTOLINENO_ASC_',
  /** sort on TextconnectedtoLineNo descending */
  TextconnectedtolinenoDesc = '_TEXTCONNECTEDTOLINENO_DESC_',
  /** Sort on ConnectedtoItemLine ascending */
  ConnectedtoitemlineAsc = '_CONNECTEDTOITEMLINE_ASC_',
  /** sort on ConnectedtoItemLine descending */
  ConnectedtoitemlineDesc = '_CONNECTEDTOITEMLINE_DESC_',
  /** Sort on EQMObjectNo ascending */
  EqmobjectnoAsc = '_EQMOBJECTNO_ASC_',
  /** sort on EQMObjectNo descending */
  EqmobjectnoDesc = '_EQMOBJECTNO_DESC_',
  /** Sort on EQMContractNo ascending */
  EqmcontractnoAsc = '_EQMCONTRACTNO_ASC_',
  /** sort on EQMContractNo descending */
  EqmcontractnoDesc = '_EQMCONTRACTNO_DESC_',
  /** Sort on EQMRental ascending */
  EqmrentalAsc = '_EQMRENTAL_ASC_',
  /** sort on EQMRental descending */
  EqmrentalDesc = '_EQMRENTAL_DESC_',
  /** Sort on EQMRentalFromDate ascending */
  EqmrentalfromdateAsc = '_EQMRENTALFROMDATE_ASC_',
  /** sort on EQMRentalFromDate descending */
  EqmrentalfromdateDesc = '_EQMRENTALFROMDATE_DESC_',
  /** Sort on EQMRentalToDate ascending */
  EqmrentaltodateAsc = '_EQMRENTALTODATE_ASC_',
  /** sort on EQMRentalToDate descending */
  EqmrentaltodateDesc = '_EQMRENTALTODATE_DESC_',
  /** Sort on EQMRentalLineNo ascending */
  EqmrentallinenoAsc = '_EQMRENTALLINENO_ASC_',
  /** sort on EQMRentalLineNo descending */
  EqmrentallinenoDesc = '_EQMRENTALLINENO_DESC_',
  /** Sort on EQMRentalDays ascending */
  EqmrentaldaysAsc = '_EQMRENTALDAYS_ASC_',
  /** sort on EQMRentalDays descending */
  EqmrentaldaysDesc = '_EQMRENTALDAYS_DESC_',
  /** Sort on EQMAttachedtoNo ascending */
  EqmattachedtonoAsc = '_EQMATTACHEDTONO_ASC_',
  /** sort on EQMAttachedtoNo descending */
  EqmattachedtonoDesc = '_EQMATTACHEDTONO_DESC_',
  /** Sort on EQMAttachedtoLineNo ascending */
  EqmattachedtolinenoAsc = '_EQMATTACHEDTOLINENO_ASC_',
  /** sort on EQMAttachedtoLineNo descending */
  EqmattachedtolinenoDesc = '_EQMATTACHEDTOLINENO_DESC_',
  /** Sort on EQMRentalFreeDays ascending */
  EqmrentalfreedaysAsc = '_EQMRENTALFREEDAYS_ASC_',
  /** sort on EQMRentalFreeDays descending */
  EqmrentalfreedaysDesc = '_EQMRENTALFREEDAYS_DESC_',
  /** Sort on EQMLineUpdated ascending */
  EqmlineupdatedAsc = '_EQMLINEUPDATED_ASC_',
  /** sort on EQMLineUpdated descending */
  EqmlineupdatedDesc = '_EQMLINEUPDATED_DESC_',
  /** Sort on EQMRentalSale ascending */
  EqmrentalsaleAsc = '_EQMRENTALSALE_ASC_',
  /** sort on EQMRentalSale descending */
  EqmrentalsaleDesc = '_EQMRENTALSALE_DESC_',
  /** Sort on EQMRentalQuantity ascending */
  EqmrentalquantityAsc = '_EQMRENTALQUANTITY_ASC_',
  /** sort on EQMRentalQuantity descending */
  EqmrentalquantityDesc = '_EQMRENTALQUANTITY_DESC_',
  /** Sort on EQMRentalNo ascending */
  EqmrentalnoAsc = '_EQMRENTALNO_ASC_',
  /** sort on EQMRentalNo descending */
  EqmrentalnoDesc = '_EQMRENTALNO_DESC_',
  /** Sort on EQMReturnDocumentNo ascending */
  EqmreturndocumentnoAsc = '_EQMRETURNDOCUMENTNO_ASC_',
  /** sort on EQMReturnDocumentNo descending */
  EqmreturndocumentnoDesc = '_EQMRETURNDOCUMENTNO_DESC_',
  /** Sort on EQMReturnLineNo ascending */
  EqmreturnlinenoAsc = '_EQMRETURNLINENO_ASC_',
  /** sort on EQMReturnLineNo descending */
  EqmreturnlinenoDesc = '_EQMRETURNLINENO_DESC_',
  /** Sort on EQMObjectType ascending */
  EqmobjecttypeAsc = '_EQMOBJECTTYPE_ASC_',
  /** sort on EQMObjectType descending */
  EqmobjecttypeDesc = '_EQMOBJECTTYPE_DESC_',
  /** Sort on EQMObjectGroup ascending */
  EqmobjectgroupAsc = '_EQMOBJECTGROUP_ASC_',
  /** sort on EQMObjectGroup descending */
  EqmobjectgroupDesc = '_EQMOBJECTGROUP_DESC_',
  /** Sort on EQMRentalFromDateTime ascending */
  EqmrentalfromdatetimeAsc = '_EQMRENTALFROMDATETIME_ASC_',
  /** sort on EQMRentalFromDateTime descending */
  EqmrentalfromdatetimeDesc = '_EQMRENTALFROMDATETIME_DESC_',
  /** Sort on EQMRentalToDateTime ascending */
  EqmrentaltodatetimeAsc = '_EQMRENTALTODATETIME_ASC_',
  /** sort on EQMRentalToDateTime descending */
  EqmrentaltodatetimeDesc = '_EQMRENTALTODATETIME_DESC_',
  /** Sort on EQMRentalDuration ascending */
  EqmrentaldurationAsc = '_EQMRENTALDURATION_ASC_',
  /** sort on EQMRentalDuration descending */
  EqmrentaldurationDesc = '_EQMRENTALDURATION_DESC_',
  /** Sort on EQMServiceUpwrite ascending */
  EqmserviceupwriteAsc = '_EQMSERVICEUPWRITE_ASC_',
  /** sort on EQMServiceUpwrite descending */
  EqmserviceupwriteDesc = '_EQMSERVICEUPWRITE_DESC_',
  /** Sort on EQMAllocateObjectNo ascending */
  EqmallocateobjectnoAsc = '_EQMALLOCATEOBJECTNO_ASC_',
  /** sort on EQMAllocateObjectNo descending */
  EqmallocateobjectnoDesc = '_EQMALLOCATEOBJECTNO_DESC_',
  /** Sort on EQMRentalLineType ascending */
  EqmrentallinetypeAsc = '_EQMRENTALLINETYPE_ASC_',
  /** sort on EQMRentalLineType descending */
  EqmrentallinetypeDesc = '_EQMRENTALLINETYPE_DESC_',
  /** Sort on EQMRentalLineTypeOPTION ascending */
  EqmrentallinetypeoptionAsc = '_EQMRENTALLINETYPEOPTION_ASC_',
  /** sort on EQMRentalLineTypeOPTION descending */
  EqmrentallinetypeoptionDesc = '_EQMRENTALLINETYPEOPTION_DESC_',
  /** Sort on EQMUseOfFixPrice ascending */
  EqmuseoffixpriceAsc = '_EQMUSEOFFIXPRICE_ASC_',
  /** sort on EQMUseOfFixPrice descending */
  EqmuseoffixpriceDesc = '_EQMUSEOFFIXPRICE_DESC_',
  /** Sort on EQMUpdateRentalLine ascending */
  EqmupdaterentallineAsc = '_EQMUPDATERENTALLINE_ASC_',
  /** sort on EQMUpdateRentalLine descending */
  EqmupdaterentallineDesc = '_EQMUPDATERENTALLINE_DESC_',
  /** Sort on EQMWorkshop ascending */
  EqmworkshopAsc = '_EQMWORKSHOP_ASC_',
  /** sort on EQMWorkshop descending */
  EqmworkshopDesc = '_EQMWORKSHOP_DESC_',
  /** Sort on EQMWorkshopObjectNo ascending */
  EqmworkshopobjectnoAsc = '_EQMWORKSHOPOBJECTNO_ASC_',
  /** sort on EQMWorkshopObjectNo descending */
  EqmworkshopobjectnoDesc = '_EQMWORKSHOPOBJECTNO_DESC_',
  /** Sort on EQMWorkshopGuarantee ascending */
  EqmworkshopguaranteeAsc = '_EQMWORKSHOPGUARANTEE_ASC_',
  /** sort on EQMWorkshopGuarantee descending */
  EqmworkshopguaranteeDesc = '_EQMWORKSHOPGUARANTEE_DESC_',
  /** Sort on EQMWorkshopType ascending */
  EqmworkshoptypeAsc = '_EQMWORKSHOPTYPE_ASC_',
  /** sort on EQMWorkshopType descending */
  EqmworkshoptypeDesc = '_EQMWORKSHOPTYPE_DESC_',
  /** Sort on EQMWorkshopTypeOPTION ascending */
  EqmworkshoptypeoptionAsc = '_EQMWORKSHOPTYPEOPTION_ASC_',
  /** sort on EQMWorkshopTypeOPTION descending */
  EqmworkshoptypeoptionDesc = '_EQMWORKSHOPTYPEOPTION_DESC_',
  /** Sort on EQMSkipObjectIntoInv ascending */
  EqmskipobjectintoinvAsc = '_EQMSKIPOBJECTINTOINV_ASC_',
  /** sort on EQMSkipObjectIntoInv descending */
  EqmskipobjectintoinvDesc = '_EQMSKIPOBJECTINTOINV_DESC_',
  /** Sort on EQMPriceTermCode ascending */
  EqmpricetermcodeAsc = '_EQMPRICETERMCODE_ASC_',
  /** sort on EQMPriceTermCode descending */
  EqmpricetermcodeDesc = '_EQMPRICETERMCODE_DESC_',
  /** Sort on EQMRefBasisEntryNo ascending */
  EqmrefbasisentrynoAsc = '_EQMREFBASISENTRYNO_ASC_',
  /** sort on EQMRefBasisEntryNo descending */
  EqmrefbasisentrynoDesc = '_EQMREFBASISENTRYNO_DESC_',
  /** Sort on EQMStairPriceCode ascending */
  EqmstairpricecodeAsc = '_EQMSTAIRPRICECODE_ASC_',
  /** sort on EQMStairPriceCode descending */
  EqmstairpricecodeDesc = '_EQMSTAIRPRICECODE_DESC_',
  /** Sort on EQMStairPriceStep ascending */
  EqmstairpricestepAsc = '_EQMSTAIRPRICESTEP_ASC_',
  /** sort on EQMStairPriceStep descending */
  EqmstairpricestepDesc = '_EQMSTAIRPRICESTEP_DESC_',
  /** Sort on EQMRentalKITMother ascending */
  EqmrentalkitmotherAsc = '_EQMRENTALKITMOTHER_ASC_',
  /** sort on EQMRentalKITMother descending */
  EqmrentalkitmotherDesc = '_EQMRENTALKITMOTHER_DESC_',
  /** Sort on EQMRentalKITMotherLineNo ascending */
  EqmrentalkitmotherlinenoAsc = '_EQMRENTALKITMOTHERLINENO_ASC_',
  /** sort on EQMRentalKITMotherLineNo descending */
  EqmrentalkitmotherlinenoDesc = '_EQMRENTALKITMOTHERLINENO_DESC_',
  /** Sort on EQMDepositPosting ascending */
  EqmdepositpostingAsc = '_EQMDEPOSITPOSTING_ASC_',
  /** sort on EQMDepositPosting descending */
  EqmdepositpostingDesc = '_EQMDEPOSITPOSTING_DESC_',
  /** Sort on EQMOldTmpRKITLineNo ascending */
  EqmoldtmprkitlinenoAsc = '_EQMOLDTMPRKITLINENO_ASC_',
  /** sort on EQMOldTmpRKITLineNo descending */
  EqmoldtmprkitlinenoDesc = '_EQMOLDTMPRKITLINENO_DESC_',
  /** Sort on EQMContractType ascending */
  EqmcontracttypeAsc = '_EQMCONTRACTTYPE_ASC_',
  /** sort on EQMContractType descending */
  EqmcontracttypeDesc = '_EQMCONTRACTTYPE_DESC_',
  /** Sort on EQMContractTypeOPTION ascending */
  EqmcontracttypeoptionAsc = '_EQMCONTRACTTYPEOPTION_ASC_',
  /** sort on EQMContractTypeOPTION descending */
  EqmcontracttypeoptionDesc = '_EQMCONTRACTTYPEOPTION_DESC_',
  /** Sort on EQMQtyonAtttoLineNo ascending */
  EqmqtyonatttolinenoAsc = '_EQMQTYONATTTOLINENO_ASC_',
  /** sort on EQMQtyonAtttoLineNo descending */
  EqmqtyonatttolinenoDesc = '_EQMQTYONATTTOLINENO_DESC_',
  /** Sort on EQMReRentObject ascending */
  EqmrerentobjectAsc = '_EQMRERENTOBJECT_ASC_',
  /** sort on EQMReRentObject descending */
  EqmrerentobjectDesc = '_EQMRERENTOBJECT_DESC_',
  /** Sort on EQMUsedFractionCalculation ascending */
  EqmusedfractioncalculationAsc = '_EQMUSEDFRACTIONCALCULATION_ASC_',
  /** sort on EQMUsedFractionCalculation descending */
  EqmusedfractioncalculationDesc = '_EQMUSEDFRACTIONCALCULATION_DESC_',
  /** Sort on EQMRentalHeaderLine ascending */
  EqmrentalheaderlineAsc = '_EQMRENTALHEADERLINE_ASC_',
  /** sort on EQMRentalHeaderLine descending */
  EqmrentalheaderlineDesc = '_EQMRENTALHEADERLINE_DESC_',
  /** Sort on EQMSerialNo ascending */
  EqmserialnoAsc = '_EQMSERIALNO_ASC_',
  /** sort on EQMSerialNo descending */
  EqmserialnoDesc = '_EQMSERIALNO_DESC_',
  /** Sort on EQMReclassOrderedBy ascending */
  EqmreclassorderedbyAsc = '_EQMRECLASSORDEREDBY_ASC_',
  /** sort on EQMReclassOrderedBy descending */
  EqmreclassorderedbyDesc = '_EQMRECLASSORDEREDBY_DESC_',
  /** Sort on EQMReclassOrderNo ascending */
  EqmreclassordernoAsc = '_EQMRECLASSORDERNO_ASC_',
  /** sort on EQMReclassOrderNo descending */
  EqmreclassordernoDesc = '_EQMRECLASSORDERNO_DESC_',
  /** Sort on EQMExtraHoursCharged ascending */
  EqmextrahourschargedAsc = '_EQMEXTRAHOURSCHARGED_ASC_',
  /** sort on EQMExtraHoursCharged descending */
  EqmextrahourschargedDesc = '_EQMEXTRAHOURSCHARGED_DESC_',
  /** Sort on EQMExtraHoursReturnNo ascending */
  EqmextrahoursreturnnoAsc = '_EQMEXTRAHOURSRETURNNO_ASC_',
  /** sort on EQMExtraHoursReturnNo descending */
  EqmextrahoursreturnnoDesc = '_EQMEXTRAHOURSRETURNNO_DESC_',
  /** Sort on EQMRentalSplitCostCenter ascending */
  EqmrentalsplitcostcenterAsc = '_EQMRENTALSPLITCOSTCENTER_ASC_',
  /** sort on EQMRentalSplitCostCenter descending */
  EqmrentalsplitcostcenterDesc = '_EQMRENTALSPLITCOSTCENTER_DESC_',
  /** Sort on EQMRentalSplit ascending */
  EqmrentalsplitAsc = '_EQMRENTALSPLIT_ASC_',
  /** sort on EQMRentalSplit descending */
  EqmrentalsplitDesc = '_EQMRENTALSPLIT_DESC_',
  /** Sort on EQMReceivingRespCenter ascending */
  EqmreceivingrespcenterAsc = '_EQMRECEIVINGRESPCENTER_ASC_',
  /** sort on EQMReceivingRespCenter descending */
  EqmreceivingrespcenterDesc = '_EQMRECEIVINGRESPCENTER_DESC_',
  /** Sort on EQMReceivingCostCenterCode ascending */
  EqmreceivingcostcentercodeAsc = '_EQMRECEIVINGCOSTCENTERCODE_ASC_',
  /** sort on EQMReceivingCostCenterCode descending */
  EqmreceivingcostcentercodeDesc = '_EQMRECEIVINGCOSTCENTERCODE_DESC_',
  /** Sort on EQMRePurchase ascending */
  EqmrepurchaseAsc = '_EQMREPURCHASE_ASC_',
  /** sort on EQMRePurchase descending */
  EqmrepurchaseDesc = '_EQMREPURCHASE_DESC_',
  /** Sort on EQMInvoiceFee ascending */
  EqminvoicefeeAsc = '_EQMINVOICEFEE_ASC_',
  /** sort on EQMInvoiceFee descending */
  EqminvoicefeeDesc = '_EQMINVOICEFEE_DESC_',
  /** Sort on EQMPriceScheduleCode ascending */
  EqmpriceschedulecodeAsc = '_EQMPRICESCHEDULECODE_ASC_',
  /** sort on EQMPriceScheduleCode descending */
  EqmpriceschedulecodeDesc = '_EQMPRICESCHEDULECODE_DESC_',
  /** Sort on EQMRentalPrepaymentEntry ascending */
  EqmrentalprepaymententryAsc = '_EQMRENTALPREPAYMENTENTRY_ASC_',
  /** sort on EQMRentalPrepaymentEntry descending */
  EqmrentalprepaymententryDesc = '_EQMRENTALPREPAYMENTENTRY_DESC_',
  /** Sort on EQMPrepmtAmtLCY ascending */
  EqmprepmtamtlcyAsc = '_EQMPREPMTAMTLCY_ASC_',
  /** sort on EQMPrepmtAmtLCY descending */
  EqmprepmtamtlcyDesc = '_EQMPREPMTAMTLCY_DESC_',
  /** Sort on EQMRentalInsurance ascending */
  EqmrentalinsuranceAsc = '_EQMRENTALINSURANCE_ASC_',
  /** sort on EQMRentalInsurance descending */
  EqmrentalinsuranceDesc = '_EQMRENTALINSURANCE_DESC_',
  /** Sort on EQMObjectInsurance ascending */
  EqmobjectinsuranceAsc = '_EQMOBJECTINSURANCE_ASC_',
  /** sort on EQMObjectInsurance descending */
  EqmobjectinsuranceDesc = '_EQMOBJECTINSURANCE_DESC_',
  /** Sort on EQMServiceMotherLineNo ascending */
  EqmservicemotherlinenoAsc = '_EQMSERVICEMOTHERLINENO_ASC_',
  /** sort on EQMServiceMotherLineNo descending */
  EqmservicemotherlinenoDesc = '_EQMSERVICEMOTHERLINENO_DESC_',
  /** Sort on EQMRepairCode ascending */
  EqmrepaircodeAsc = '_EQMREPAIRCODE_ASC_',
  /** sort on EQMRepairCode descending */
  EqmrepaircodeDesc = '_EQMREPAIRCODE_DESC_',
  /** Sort on EQMPriceReportedHours ascending */
  EqmpricereportedhoursAsc = '_EQMPRICEREPORTEDHOURS_ASC_',
  /** sort on EQMPriceReportedHours descending */
  EqmpricereportedhoursDesc = '_EQMPRICEREPORTEDHOURS_DESC_',
  /** Sort on EQMPriceReportedHoursOPTION ascending */
  EqmpricereportedhoursoptionAsc = '_EQMPRICEREPORTEDHOURSOPTION_ASC_',
  /** sort on EQMPriceReportedHoursOPTION descending */
  EqmpricereportedhoursoptionDesc = '_EQMPRICEREPORTEDHOURSOPTION_DESC_',
  /** Sort on EQMFACorrection ascending */
  EqmfacorrectionAsc = '_EQMFACORRECTION_ASC_',
  /** sort on EQMFACorrection descending */
  EqmfacorrectionDesc = '_EQMFACORRECTION_DESC_',
  /** Sort on EQMPrepmtAmtInclVAT ascending */
  EqmprepmtamtinclvatAsc = '_EQMPREPMTAMTINCLVAT_ASC_',
  /** sort on EQMPrepmtAmtInclVAT descending */
  EqmprepmtamtinclvatDesc = '_EQMPREPMTAMTINCLVAT_DESC_',
  /** Sort on ReservedQuantity ascending */
  ReservedquantityAsc = '_RESERVEDQUANTITY_ASC_',
  /** sort on ReservedQuantity descending */
  ReservedquantityDesc = '_RESERVEDQUANTITY_DESC_',
  /** Sort on ATOWhseOutstandingQty ascending */
  AtowhseoutstandingqtyAsc = '_ATOWHSEOUTSTANDINGQTY_ASC_',
  /** sort on ATOWhseOutstandingQty descending */
  AtowhseoutstandingqtyDesc = '_ATOWHSEOUTSTANDINGQTY_DESC_',
  /** Sort on ATOWhseOutstdQtyBase ascending */
  AtowhseoutstdqtybaseAsc = '_ATOWHSEOUTSTDQTYBASE_ASC_',
  /** sort on ATOWhseOutstdQtyBase descending */
  AtowhseoutstdqtybaseDesc = '_ATOWHSEOUTSTDQTYBASE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ReservedQtyBase ascending */
  ReservedqtybaseAsc = '_RESERVEDQTYBASE_ASC_',
  /** sort on ReservedQtyBase descending */
  ReservedqtybaseDesc = '_RESERVEDQTYBASE_DESC_',
  /** Sort on SubstitutionAvailable ascending */
  SubstitutionavailableAsc = '_SUBSTITUTIONAVAILABLE_ASC_',
  /** sort on SubstitutionAvailable descending */
  SubstitutionavailableDesc = '_SUBSTITUTIONAVAILABLE_DESC_',
  /** Sort on WhseOutstandingQty ascending */
  WhseoutstandingqtyAsc = '_WHSEOUTSTANDINGQTY_ASC_',
  /** sort on WhseOutstandingQty descending */
  WhseoutstandingqtyDesc = '_WHSEOUTSTANDINGQTY_DESC_',
  /** Sort on WhseOutstandingQtyBase ascending */
  WhseoutstandingqtybaseAsc = '_WHSEOUTSTANDINGQTYBASE_ASC_',
  /** sort on WhseOutstandingQtyBase descending */
  WhseoutstandingqtybaseDesc = '_WHSEOUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoAssign ascending */
  QtytoassignAsc = '_QTYTOASSIGN_ASC_',
  /** sort on QtytoAssign descending */
  QtytoassignDesc = '_QTYTOASSIGN_DESC_',
  /** Sort on QtyAssigned ascending */
  QtyassignedAsc = '_QTYASSIGNED_ASC_',
  /** sort on QtyAssigned descending */
  QtyassignedDesc = '_QTYASSIGNED_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on MagentoId ascending */
  MagentoidAsc = '_MAGENTOID_ASC_',
  /** sort on MagentoId descending */
  MagentoidDesc = '_MAGENTOID_DESC_',
  /** Sort on ProductType ascending */
  ProducttypeAsc = '_PRODUCTTYPE_ASC_',
  /** sort on ProductType descending */
  ProducttypeDesc = '_PRODUCTTYPE_DESC_',
  /** Sort on SalesOrderLineEdgeGuid ascending */
  SalesorderlineedgeguidAsc = '_SALESORDERLINEEDGEGUID_ASC_',
  /** sort on SalesOrderLineEdgeGuid descending */
  SalesorderlineedgeguidDesc = '_SALESORDERLINEEDGEGUID_DESC_',
  /** Sort on LinkedToGlobalId ascending */
  LinkedtoglobalidAsc = '_LINKEDTOGLOBALID_ASC_',
  /** sort on LinkedToGlobalId descending */
  LinkedtoglobalidDesc = '_LINKEDTOGLOBALID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesLineRequestInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on OutstandingQuantity ascending */
  OutstandingquantityAsc = '_OUTSTANDINGQUANTITY_ASC_',
  /** sort on OutstandingQuantity descending */
  OutstandingquantityDesc = '_OUTSTANDINGQUANTITY_DESC_',
  /** Sort on QtytoInvoice ascending */
  QtytoinvoiceAsc = '_QTYTOINVOICE_ASC_',
  /** sort on QtytoInvoice descending */
  QtytoinvoiceDesc = '_QTYTOINVOICE_DESC_',
  /** Sort on QtytoShip ascending */
  QtytoshipAsc = '_QTYTOSHIP_ASC_',
  /** sort on QtytoShip descending */
  QtytoshipDesc = '_QTYTOSHIP_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on LineDiscountAmount ascending */
  LinediscountamountAsc = '_LINEDISCOUNTAMOUNT_ASC_',
  /** sort on LineDiscountAmount descending */
  LinediscountamountDesc = '_LINEDISCOUNTAMOUNT_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on RecalculateInvoiceDisc ascending */
  RecalculateinvoicediscAsc = '_RECALCULATEINVOICEDISC_ASC_',
  /** sort on RecalculateInvoiceDisc descending */
  RecalculateinvoicediscDesc = '_RECALCULATEINVOICEDISC_DESC_',
  /** Sort on OutstandingAmount ascending */
  OutstandingamountAsc = '_OUTSTANDINGAMOUNT_ASC_',
  /** sort on OutstandingAmount descending */
  OutstandingamountDesc = '_OUTSTANDINGAMOUNT_DESC_',
  /** Sort on QtyShippedNotInvoiced ascending */
  QtyshippednotinvoicedAsc = '_QTYSHIPPEDNOTINVOICED_ASC_',
  /** sort on QtyShippedNotInvoiced descending */
  QtyshippednotinvoicedDesc = '_QTYSHIPPEDNOTINVOICED_DESC_',
  /** Sort on ShippedNotInvoiced ascending */
  ShippednotinvoicedAsc = '_SHIPPEDNOTINVOICED_ASC_',
  /** sort on ShippedNotInvoiced descending */
  ShippednotinvoicedDesc = '_SHIPPEDNOTINVOICED_DESC_',
  /** Sort on QuantityShipped ascending */
  QuantityshippedAsc = '_QUANTITYSHIPPED_ASC_',
  /** sort on QuantityShipped descending */
  QuantityshippedDesc = '_QUANTITYSHIPPED_DESC_',
  /** Sort on QuantityInvoiced ascending */
  QuantityinvoicedAsc = '_QUANTITYINVOICED_ASC_',
  /** sort on QuantityInvoiced descending */
  QuantityinvoicedDesc = '_QUANTITYINVOICED_DESC_',
  /** Sort on ShipmentNo ascending */
  ShipmentnoAsc = '_SHIPMENTNO_ASC_',
  /** sort on ShipmentNo descending */
  ShipmentnoDesc = '_SHIPMENTNO_DESC_',
  /** Sort on ShipmentLineNo ascending */
  ShipmentlinenoAsc = '_SHIPMENTLINENO_ASC_',
  /** sort on ShipmentLineNo descending */
  ShipmentlinenoDesc = '_SHIPMENTLINENO_DESC_',
  /** Sort on Profit ascending */
  ProfitAsc = '_PROFIT_ASC_',
  /** sort on Profit descending */
  ProfitDesc = '_PROFIT_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on InvDiscountAmount ascending */
  InvdiscountamountAsc = '_INVDISCOUNTAMOUNT_ASC_',
  /** sort on InvDiscountAmount descending */
  InvdiscountamountDesc = '_INVDISCOUNTAMOUNT_DESC_',
  /** Sort on PurchaseOrderNo ascending */
  PurchaseordernoAsc = '_PURCHASEORDERNO_ASC_',
  /** sort on PurchaseOrderNo descending */
  PurchaseordernoDesc = '_PURCHASEORDERNO_DESC_',
  /** Sort on PurchOrderLineNo ascending */
  PurchorderlinenoAsc = '_PURCHORDERLINENO_ASC_',
  /** sort on PurchOrderLineNo descending */
  PurchorderlinenoDesc = '_PURCHORDERLINENO_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATClauseCode ascending */
  VatclausecodeAsc = '_VATCLAUSECODE_ASC_',
  /** sort on VATClauseCode descending */
  VatclausecodeDesc = '_VATCLAUSECODE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on OutstandingAmountLCY ascending */
  OutstandingamountlcyAsc = '_OUTSTANDINGAMOUNTLCY_ASC_',
  /** sort on OutstandingAmountLCY descending */
  OutstandingamountlcyDesc = '_OUTSTANDINGAMOUNTLCY_DESC_',
  /** Sort on ShippedNotInvoicedLCY ascending */
  ShippednotinvoicedlcyAsc = '_SHIPPEDNOTINVOICEDLCY_ASC_',
  /** sort on ShippedNotInvoicedLCY descending */
  ShippednotinvoicedlcyDesc = '_SHIPPEDNOTINVOICEDLCY_DESC_',
  /** Sort on Reserve ascending */
  ReserveAsc = '_RESERVE_ASC_',
  /** sort on Reserve descending */
  ReserveDesc = '_RESERVE_DESC_',
  /** Sort on ReserveOPTION ascending */
  ReserveoptionAsc = '_RESERVEOPTION_ASC_',
  /** sort on ReserveOPTION descending */
  ReserveoptionDesc = '_RESERVEOPTION_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on SystemCreatedEntry ascending */
  SystemcreatedentryAsc = '_SYSTEMCREATEDENTRY_ASC_',
  /** sort on SystemCreatedEntry descending */
  SystemcreatedentryDesc = '_SYSTEMCREATEDENTRY_DESC_',
  /** Sort on LineAmount ascending */
  LineamountAsc = '_LINEAMOUNT_ASC_',
  /** sort on LineAmount descending */
  LineamountDesc = '_LINEAMOUNT_DESC_',
  /** Sort on VATDifference ascending */
  VatdifferenceAsc = '_VATDIFFERENCE_ASC_',
  /** sort on VATDifference descending */
  VatdifferenceDesc = '_VATDIFFERENCE_DESC_',
  /** Sort on InvDiscAmounttoInvoice ascending */
  InvdiscamounttoinvoiceAsc = '_INVDISCAMOUNTTOINVOICE_ASC_',
  /** sort on InvDiscAmounttoInvoice descending */
  InvdiscamounttoinvoiceDesc = '_INVDISCAMOUNTTOINVOICE_DESC_',
  /** Sort on VATIdentifier ascending */
  VatidentifierAsc = '_VATIDENTIFIER_ASC_',
  /** sort on VATIdentifier descending */
  VatidentifierDesc = '_VATIDENTIFIER_DESC_',
  /** Sort on ICPartnerRefType ascending */
  IcpartnerreftypeAsc = '_ICPARTNERREFTYPE_ASC_',
  /** sort on ICPartnerRefType descending */
  IcpartnerreftypeDesc = '_ICPARTNERREFTYPE_DESC_',
  /** Sort on ICPartnerRefTypeOPTION ascending */
  IcpartnerreftypeoptionAsc = '_ICPARTNERREFTYPEOPTION_ASC_',
  /** sort on ICPartnerRefTypeOPTION descending */
  IcpartnerreftypeoptionDesc = '_ICPARTNERREFTYPEOPTION_DESC_',
  /** Sort on ICPartnerReference ascending */
  IcpartnerreferenceAsc = '_ICPARTNERREFERENCE_ASC_',
  /** sort on ICPartnerReference descending */
  IcpartnerreferenceDesc = '_ICPARTNERREFERENCE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PrepmtLineAmount ascending */
  PrepmtlineamountAsc = '_PREPMTLINEAMOUNT_ASC_',
  /** sort on PrepmtLineAmount descending */
  PrepmtlineamountDesc = '_PREPMTLINEAMOUNT_DESC_',
  /** Sort on PrepmtAmtInv ascending */
  PrepmtamtinvAsc = '_PREPMTAMTINV_ASC_',
  /** sort on PrepmtAmtInv descending */
  PrepmtamtinvDesc = '_PREPMTAMTINV_DESC_',
  /** Sort on PrepmtAmtInclVAT ascending */
  PrepmtamtinclvatAsc = '_PREPMTAMTINCLVAT_ASC_',
  /** sort on PrepmtAmtInclVAT descending */
  PrepmtamtinclvatDesc = '_PREPMTAMTINCLVAT_DESC_',
  /** Sort on PrepaymentAmount ascending */
  PrepaymentamountAsc = '_PREPAYMENTAMOUNT_ASC_',
  /** sort on PrepaymentAmount descending */
  PrepaymentamountDesc = '_PREPAYMENTAMOUNT_DESC_',
  /** Sort on PrepmtVATBaseAmt ascending */
  PrepmtvatbaseamtAsc = '_PREPMTVATBASEAMT_ASC_',
  /** sort on PrepmtVATBaseAmt descending */
  PrepmtvatbaseamtDesc = '_PREPMTVATBASEAMT_DESC_',
  /** Sort on PrepaymentVAT ascending */
  PrepaymentvatAsc = '_PREPAYMENTVAT_ASC_',
  /** sort on PrepaymentVAT descending */
  PrepaymentvatDesc = '_PREPAYMENTVAT_DESC_',
  /** Sort on PrepmtVATCalcType ascending */
  PrepmtvatcalctypeAsc = '_PREPMTVATCALCTYPE_ASC_',
  /** sort on PrepmtVATCalcType descending */
  PrepmtvatcalctypeDesc = '_PREPMTVATCALCTYPE_DESC_',
  /** Sort on PrepmtVATCalcTypeOPTION ascending */
  PrepmtvatcalctypeoptionAsc = '_PREPMTVATCALCTYPEOPTION_ASC_',
  /** sort on PrepmtVATCalcTypeOPTION descending */
  PrepmtvatcalctypeoptionDesc = '_PREPMTVATCALCTYPEOPTION_DESC_',
  /** Sort on PrepaymentVATIdentifier ascending */
  PrepaymentvatidentifierAsc = '_PREPAYMENTVATIDENTIFIER_ASC_',
  /** sort on PrepaymentVATIdentifier descending */
  PrepaymentvatidentifierDesc = '_PREPAYMENTVATIDENTIFIER_DESC_',
  /** Sort on PrepaymentTaxAreaCode ascending */
  PrepaymenttaxareacodeAsc = '_PREPAYMENTTAXAREACODE_ASC_',
  /** sort on PrepaymentTaxAreaCode descending */
  PrepaymenttaxareacodeDesc = '_PREPAYMENTTAXAREACODE_DESC_',
  /** Sort on PrepaymentTaxLiable ascending */
  PrepaymenttaxliableAsc = '_PREPAYMENTTAXLIABLE_ASC_',
  /** sort on PrepaymentTaxLiable descending */
  PrepaymenttaxliableDesc = '_PREPAYMENTTAXLIABLE_DESC_',
  /** Sort on PrepaymentTaxGroupCode ascending */
  PrepaymenttaxgroupcodeAsc = '_PREPAYMENTTAXGROUPCODE_ASC_',
  /** sort on PrepaymentTaxGroupCode descending */
  PrepaymenttaxgroupcodeDesc = '_PREPAYMENTTAXGROUPCODE_DESC_',
  /** Sort on PrepmtAmttoDeduct ascending */
  PrepmtamttodeductAsc = '_PREPMTAMTTODEDUCT_ASC_',
  /** sort on PrepmtAmttoDeduct descending */
  PrepmtamttodeductDesc = '_PREPMTAMTTODEDUCT_DESC_',
  /** Sort on PrepmtAmtDeducted ascending */
  PrepmtamtdeductedAsc = '_PREPMTAMTDEDUCTED_ASC_',
  /** sort on PrepmtAmtDeducted descending */
  PrepmtamtdeductedDesc = '_PREPMTAMTDEDUCTED_DESC_',
  /** Sort on PrepaymentLine ascending */
  PrepaymentlineAsc = '_PREPAYMENTLINE_ASC_',
  /** sort on PrepaymentLine descending */
  PrepaymentlineDesc = '_PREPAYMENTLINE_DESC_',
  /** Sort on PrepmtAmountInvInclVAT ascending */
  PrepmtamountinvinclvatAsc = '_PREPMTAMOUNTINVINCLVAT_ASC_',
  /** sort on PrepmtAmountInvInclVAT descending */
  PrepmtamountinvinclvatDesc = '_PREPMTAMOUNTINVINCLVAT_DESC_',
  /** Sort on PrepmtAmountInvLCY ascending */
  PrepmtamountinvlcyAsc = '_PREPMTAMOUNTINVLCY_ASC_',
  /** sort on PrepmtAmountInvLCY descending */
  PrepmtamountinvlcyDesc = '_PREPMTAMOUNTINVLCY_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on PrepmtVATAmountInvLCY ascending */
  PrepmtvatamountinvlcyAsc = '_PREPMTVATAMOUNTINVLCY_ASC_',
  /** sort on PrepmtVATAmountInvLCY descending */
  PrepmtvatamountinvlcyDesc = '_PREPMTVATAMOUNTINVLCY_DESC_',
  /** Sort on PrepaymentVATDifference ascending */
  PrepaymentvatdifferenceAsc = '_PREPAYMENTVATDIFFERENCE_ASC_',
  /** sort on PrepaymentVATDifference descending */
  PrepaymentvatdifferenceDesc = '_PREPAYMENTVATDIFFERENCE_DESC_',
  /** Sort on PrepmtVATDifftoDeduct ascending */
  PrepmtvatdifftodeductAsc = '_PREPMTVATDIFFTODEDUCT_ASC_',
  /** sort on PrepmtVATDifftoDeduct descending */
  PrepmtvatdifftodeductDesc = '_PREPMTVATDIFFTODEDUCT_DESC_',
  /** Sort on PrepmtVATDiffDeducted ascending */
  PrepmtvatdiffdeductedAsc = '_PREPMTVATDIFFDEDUCTED_ASC_',
  /** sort on PrepmtVATDiffDeducted descending */
  PrepmtvatdiffdeductedDesc = '_PREPMTVATDIFFDEDUCTED_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on QtytoAssembletoOrder ascending */
  QtytoassembletoorderAsc = '_QTYTOASSEMBLETOORDER_ASC_',
  /** sort on QtytoAssembletoOrder descending */
  QtytoassembletoorderDesc = '_QTYTOASSEMBLETOORDER_DESC_',
  /** Sort on QtytoAsmtoOrderBase ascending */
  QtytoasmtoorderbaseAsc = '_QTYTOASMTOORDERBASE_ASC_',
  /** sort on QtytoAsmtoOrderBase descending */
  QtytoasmtoorderbaseDesc = '_QTYTOASMTOORDERBASE_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobContractEntryNo ascending */
  JobcontractentrynoAsc = '_JOBCONTRACTENTRYNO_ASC_',
  /** sort on JobContractEntryNo descending */
  JobcontractentrynoDesc = '_JOBCONTRACTENTRYNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on Planned ascending */
  PlannedAsc = '_PLANNED_ASC_',
  /** sort on Planned descending */
  PlannedDesc = '_PLANNED_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on OutstandingQtyBase ascending */
  OutstandingqtybaseAsc = '_OUTSTANDINGQTYBASE_ASC_',
  /** sort on OutstandingQtyBase descending */
  OutstandingqtybaseDesc = '_OUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoInvoiceBase ascending */
  QtytoinvoicebaseAsc = '_QTYTOINVOICEBASE_ASC_',
  /** sort on QtytoInvoiceBase descending */
  QtytoinvoicebaseDesc = '_QTYTOINVOICEBASE_DESC_',
  /** Sort on QtytoShipBase ascending */
  QtytoshipbaseAsc = '_QTYTOSHIPBASE_ASC_',
  /** sort on QtytoShipBase descending */
  QtytoshipbaseDesc = '_QTYTOSHIPBASE_DESC_',
  /** Sort on QtyShippedNotInvdBase ascending */
  QtyshippednotinvdbaseAsc = '_QTYSHIPPEDNOTINVDBASE_ASC_',
  /** sort on QtyShippedNotInvdBase descending */
  QtyshippednotinvdbaseDesc = '_QTYSHIPPEDNOTINVDBASE_DESC_',
  /** Sort on QtyShippedBase ascending */
  QtyshippedbaseAsc = '_QTYSHIPPEDBASE_ASC_',
  /** sort on QtyShippedBase descending */
  QtyshippedbaseDesc = '_QTYSHIPPEDBASE_DESC_',
  /** Sort on QtyInvoicedBase ascending */
  QtyinvoicedbaseAsc = '_QTYINVOICEDBASE_ASC_',
  /** sort on QtyInvoicedBase descending */
  QtyinvoicedbaseDesc = '_QTYINVOICEDBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on OutofStockSubstitution ascending */
  OutofstocksubstitutionAsc = '_OUTOFSTOCKSUBSTITUTION_ASC_',
  /** sort on OutofStockSubstitution descending */
  OutofstocksubstitutionDesc = '_OUTOFSTOCKSUBSTITUTION_DESC_',
  /** Sort on OriginallyOrderedNo ascending */
  OriginallyorderednoAsc = '_ORIGINALLYORDEREDNO_ASC_',
  /** sort on OriginallyOrderedNo descending */
  OriginallyorderednoDesc = '_ORIGINALLYORDEREDNO_DESC_',
  /** Sort on OriginallyOrderedVarCode ascending */
  OriginallyorderedvarcodeAsc = '_ORIGINALLYORDEREDVARCODE_ASC_',
  /** sort on OriginallyOrderedVarCode descending */
  OriginallyorderedvarcodeDesc = '_ORIGINALLYORDEREDVARCODE_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on SpecialOrder ascending */
  SpecialorderAsc = '_SPECIALORDER_ASC_',
  /** sort on SpecialOrder descending */
  SpecialorderDesc = '_SPECIALORDER_DESC_',
  /** Sort on SpecialOrderPurchaseNo ascending */
  SpecialorderpurchasenoAsc = '_SPECIALORDERPURCHASENO_ASC_',
  /** sort on SpecialOrderPurchaseNo descending */
  SpecialorderpurchasenoDesc = '_SPECIALORDERPURCHASENO_DESC_',
  /** Sort on SpecialOrderPurchLineNo ascending */
  SpecialorderpurchlinenoAsc = '_SPECIALORDERPURCHLINENO_ASC_',
  /** sort on SpecialOrderPurchLineNo descending */
  SpecialorderpurchlinenoDesc = '_SPECIALORDERPURCHLINENO_DESC_',
  /** Sort on CompletelyShipped ascending */
  CompletelyshippedAsc = '_COMPLETELYSHIPPED_ASC_',
  /** sort on CompletelyShipped descending */
  CompletelyshippedDesc = '_COMPLETELYSHIPPED_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on PlannedDeliveryDate ascending */
  PlanneddeliverydateAsc = '_PLANNEDDELIVERYDATE_ASC_',
  /** sort on PlannedDeliveryDate descending */
  PlanneddeliverydateDesc = '_PLANNEDDELIVERYDATE_DESC_',
  /** Sort on PlannedShipmentDate ascending */
  PlannedshipmentdateAsc = '_PLANNEDSHIPMENTDATE_ASC_',
  /** sort on PlannedShipmentDate descending */
  PlannedshipmentdateDesc = '_PLANNEDSHIPMENTDATE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on AllowItemChargeAssignment ascending */
  AllowitemchargeassignmentAsc = '_ALLOWITEMCHARGEASSIGNMENT_ASC_',
  /** sort on AllowItemChargeAssignment descending */
  AllowitemchargeassignmentDesc = '_ALLOWITEMCHARGEASSIGNMENT_DESC_',
  /** Sort on ReturnQtytoReceive ascending */
  ReturnqtytoreceiveAsc = '_RETURNQTYTORECEIVE_ASC_',
  /** sort on ReturnQtytoReceive descending */
  ReturnqtytoreceiveDesc = '_RETURNQTYTORECEIVE_DESC_',
  /** Sort on ReturnQtytoReceiveBase ascending */
  ReturnqtytoreceivebaseAsc = '_RETURNQTYTORECEIVEBASE_ASC_',
  /** sort on ReturnQtytoReceiveBase descending */
  ReturnqtytoreceivebaseDesc = '_RETURNQTYTORECEIVEBASE_DESC_',
  /** Sort on ReturnQtyRcdNotInvd ascending */
  ReturnqtyrcdnotinvdAsc = '_RETURNQTYRCDNOTINVD_ASC_',
  /** sort on ReturnQtyRcdNotInvd descending */
  ReturnqtyrcdnotinvdDesc = '_RETURNQTYRCDNOTINVD_DESC_',
  /** Sort on RetQtyRcdNotInvdBase ascending */
  RetqtyrcdnotinvdbaseAsc = '_RETQTYRCDNOTINVDBASE_ASC_',
  /** sort on RetQtyRcdNotInvdBase descending */
  RetqtyrcdnotinvdbaseDesc = '_RETQTYRCDNOTINVDBASE_DESC_',
  /** Sort on ReturnRcdNotInvd ascending */
  ReturnrcdnotinvdAsc = '_RETURNRCDNOTINVD_ASC_',
  /** sort on ReturnRcdNotInvd descending */
  ReturnrcdnotinvdDesc = '_RETURNRCDNOTINVD_DESC_',
  /** Sort on ReturnRcdNotInvdLCY ascending */
  ReturnrcdnotinvdlcyAsc = '_RETURNRCDNOTINVDLCY_ASC_',
  /** sort on ReturnRcdNotInvdLCY descending */
  ReturnrcdnotinvdlcyDesc = '_RETURNRCDNOTINVDLCY_DESC_',
  /** Sort on ReturnQtyReceived ascending */
  ReturnqtyreceivedAsc = '_RETURNQTYRECEIVED_ASC_',
  /** sort on ReturnQtyReceived descending */
  ReturnqtyreceivedDesc = '_RETURNQTYRECEIVED_DESC_',
  /** Sort on ReturnQtyReceivedBase ascending */
  ReturnqtyreceivedbaseAsc = '_RETURNQTYRECEIVEDBASE_ASC_',
  /** sort on ReturnQtyReceivedBase descending */
  ReturnqtyreceivedbaseDesc = '_RETURNQTYRECEIVEDBASE_DESC_',
  /** Sort on ApplfromItemEntry ascending */
  ApplfromitementryAsc = '_APPLFROMITEMENTRY_ASC_',
  /** sort on ApplfromItemEntry descending */
  ApplfromitementryDesc = '_APPLFROMITEMENTRY_DESC_',
  /** Sort on BOMItemNo ascending */
  BomitemnoAsc = '_BOMITEMNO_ASC_',
  /** sort on BOMItemNo descending */
  BomitemnoDesc = '_BOMITEMNO_DESC_',
  /** Sort on ReturnReceiptNo ascending */
  ReturnreceiptnoAsc = '_RETURNRECEIPTNO_ASC_',
  /** sort on ReturnReceiptNo descending */
  ReturnreceiptnoDesc = '_RETURNRECEIPTNO_DESC_',
  /** Sort on ReturnReceiptLineNo ascending */
  ReturnreceiptlinenoAsc = '_RETURNRECEIPTLINENO_ASC_',
  /** sort on ReturnReceiptLineNo descending */
  ReturnreceiptlinenoDesc = '_RETURNRECEIPTLINENO_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on PeriodicTemplateCode ascending */
  PeriodictemplatecodeAsc = '_PERIODICTEMPLATECODE_ASC_',
  /** sort on PeriodicTemplateCode descending */
  PeriodictemplatecodeDesc = '_PERIODICTEMPLATECODE_DESC_',
  /** Sort on PeriodicStartingDate ascending */
  PeriodicstartingdateAsc = '_PERIODICSTARTINGDATE_ASC_',
  /** sort on PeriodicStartingDate descending */
  PeriodicstartingdateDesc = '_PERIODICSTARTINGDATE_DESC_',
  /** Sort on NAVStatus ascending */
  NavstatusAsc = '_NAVSTATUS_ASC_',
  /** sort on NAVStatus descending */
  NavstatusDesc = '_NAVSTATUS_DESC_',
  /** Sort on NAVStatusOPTION ascending */
  NavstatusoptionAsc = '_NAVSTATUSOPTION_ASC_',
  /** sort on NAVStatusOPTION descending */
  NavstatusoptionDesc = '_NAVSTATUSOPTION_DESC_',
  /** Sort on NAVImportedDate ascending */
  NavimporteddateAsc = '_NAVIMPORTEDDATE_ASC_',
  /** sort on NAVImportedDate descending */
  NavimporteddateDesc = '_NAVIMPORTEDDATE_DESC_',
  /** Sort on NAVOrderNo ascending */
  NavordernoAsc = '_NAVORDERNO_ASC_',
  /** sort on NAVOrderNo descending */
  NavordernoDesc = '_NAVORDERNO_DESC_',
  /** Sort on Rubrikrad ascending */
  RubrikradAsc = '_RUBRIKRAD_ASC_',
  /** sort on Rubrikrad descending */
  RubrikradDesc = '_RUBRIKRAD_DESC_',
  /** Sort on EndofGroup ascending */
  EndofgroupAsc = '_ENDOFGROUP_ASC_',
  /** sort on EndofGroup descending */
  EndofgroupDesc = '_ENDOFGROUP_DESC_',
  /** Sort on ChargeType ascending */
  ChargetypeAsc = '_CHARGETYPE_ASC_',
  /** sort on ChargeType descending */
  ChargetypeDesc = '_CHARGETYPE_DESC_',
  /** Sort on ChargeTypeOPTION ascending */
  ChargetypeoptionAsc = '_CHARGETYPEOPTION_ASC_',
  /** sort on ChargeTypeOPTION descending */
  ChargetypeoptionDesc = '_CHARGETYPEOPTION_DESC_',
  /** Sort on TextconnectedtoLineNo ascending */
  TextconnectedtolinenoAsc = '_TEXTCONNECTEDTOLINENO_ASC_',
  /** sort on TextconnectedtoLineNo descending */
  TextconnectedtolinenoDesc = '_TEXTCONNECTEDTOLINENO_DESC_',
  /** Sort on ConnectedtoItemLine ascending */
  ConnectedtoitemlineAsc = '_CONNECTEDTOITEMLINE_ASC_',
  /** sort on ConnectedtoItemLine descending */
  ConnectedtoitemlineDesc = '_CONNECTEDTOITEMLINE_DESC_',
  /** Sort on FreeTextLine ascending */
  FreetextlineAsc = '_FREETEXTLINE_ASC_',
  /** sort on FreeTextLine descending */
  FreetextlineDesc = '_FREETEXTLINE_DESC_',
  /** Sort on ReservedQuantity ascending */
  ReservedquantityAsc = '_RESERVEDQUANTITY_ASC_',
  /** sort on ReservedQuantity descending */
  ReservedquantityDesc = '_RESERVEDQUANTITY_DESC_',
  /** Sort on ATOWhseOutstandingQty ascending */
  AtowhseoutstandingqtyAsc = '_ATOWHSEOUTSTANDINGQTY_ASC_',
  /** sort on ATOWhseOutstandingQty descending */
  AtowhseoutstandingqtyDesc = '_ATOWHSEOUTSTANDINGQTY_DESC_',
  /** Sort on ATOWhseOutstdQtyBase ascending */
  AtowhseoutstdqtybaseAsc = '_ATOWHSEOUTSTDQTYBASE_ASC_',
  /** sort on ATOWhseOutstdQtyBase descending */
  AtowhseoutstdqtybaseDesc = '_ATOWHSEOUTSTDQTYBASE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ReservedQtyBase ascending */
  ReservedqtybaseAsc = '_RESERVEDQTYBASE_ASC_',
  /** sort on ReservedQtyBase descending */
  ReservedqtybaseDesc = '_RESERVEDQTYBASE_DESC_',
  /** Sort on SubstitutionAvailable ascending */
  SubstitutionavailableAsc = '_SUBSTITUTIONAVAILABLE_ASC_',
  /** sort on SubstitutionAvailable descending */
  SubstitutionavailableDesc = '_SUBSTITUTIONAVAILABLE_DESC_',
  /** Sort on WhseOutstandingQty ascending */
  WhseoutstandingqtyAsc = '_WHSEOUTSTANDINGQTY_ASC_',
  /** sort on WhseOutstandingQty descending */
  WhseoutstandingqtyDesc = '_WHSEOUTSTANDINGQTY_DESC_',
  /** Sort on WhseOutstandingQtyBase ascending */
  WhseoutstandingqtybaseAsc = '_WHSEOUTSTANDINGQTYBASE_ASC_',
  /** sort on WhseOutstandingQtyBase descending */
  WhseoutstandingqtybaseDesc = '_WHSEOUTSTANDINGQTYBASE_DESC_',
  /** Sort on QtytoAssign ascending */
  QtytoassignAsc = '_QTYTOASSIGN_ASC_',
  /** sort on QtytoAssign descending */
  QtytoassignDesc = '_QTYTOASSIGN_DESC_',
  /** Sort on QtyAssigned ascending */
  QtyassignedAsc = '_QTYASSIGNED_ASC_',
  /** sort on QtyAssigned descending */
  QtyassignedDesc = '_QTYASSIGNED_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on NAVErrorMessage ascending */
  NaverrormessageAsc = '_NAVERRORMESSAGE_ASC_',
  /** sort on NAVErrorMessage descending */
  NaverrormessageDesc = '_NAVERRORMESSAGE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesPriceInput {
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on SalesType ascending */
  SalestypeAsc = '_SALESTYPE_ASC_',
  /** sort on SalesType descending */
  SalestypeDesc = '_SALESTYPE_DESC_',
  /** Sort on SalesTypeOPTION ascending */
  SalestypeoptionAsc = '_SALESTYPEOPTION_ASC_',
  /** sort on SalesTypeOPTION descending */
  SalestypeoptionDesc = '_SALESTYPEOPTION_DESC_',
  /** Sort on SalesCode ascending */
  SalescodeAsc = '_SALESCODE_ASC_',
  /** sort on SalesCode descending */
  SalescodeDesc = '_SALESCODE_DESC_',
  /** Sort on StartingDate ascending */
  StartingdateAsc = '_STARTINGDATE_ASC_',
  /** sort on StartingDate descending */
  StartingdateDesc = '_STARTINGDATE_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on MinimumQuantity ascending */
  MinimumquantityAsc = '_MINIMUMQUANTITY_ASC_',
  /** sort on MinimumQuantity descending */
  MinimumquantityDesc = '_MINIMUMQUANTITY_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on PriceIncludesVAT ascending */
  PriceincludesvatAsc = '_PRICEINCLUDESVAT_ASC_',
  /** sort on PriceIncludesVAT descending */
  PriceincludesvatDesc = '_PRICEINCLUDESVAT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on VATBusPostingGrPrice ascending */
  VatbuspostinggrpriceAsc = '_VATBUSPOSTINGGRPRICE_ASC_',
  /** sort on VATBusPostingGrPrice descending */
  VatbuspostinggrpriceDesc = '_VATBUSPOSTINGGRPRICE_DESC_',
  /** Sort on EndingDate ascending */
  EndingdateAsc = '_ENDINGDATE_ASC_',
  /** sort on EndingDate descending */
  EndingdateDesc = '_ENDINGDATE_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on UnitPriceIncVAT ascending */
  UnitpriceincvatAsc = '_UNITPRICEINCVAT_ASC_',
  /** sort on UnitPriceIncVAT descending */
  UnitpriceincvatDesc = '_UNITPRICEINCVAT_DESC_',
  /** Sort on SalesOrderMultiple ascending */
  SalesordermultipleAsc = '_SALESORDERMULTIPLE_ASC_',
  /** sort on SalesOrderMultiple descending */
  SalesordermultipleDesc = '_SALESORDERMULTIPLE_DESC_',
  /** Sort on CarepaPurchPrice ascending */
  CarepapurchpriceAsc = '_CAREPAPURCHPRICE_ASC_',
  /** sort on CarepaPurchPrice descending */
  CarepapurchpriceDesc = '_CAREPAPURCHPRICE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesShipmentHeaderInput {
  /** Sort on MagentoOrder ascending */
  MagentoorderAsc = '_MAGENTOORDER_ASC_',
  /** sort on MagentoOrder descending */
  MagentoorderDesc = '_MAGENTOORDER_DESC_',
  /** Sort on MagentoOrderType ascending */
  MagentoordertypeAsc = '_MAGENTOORDERTYPE_ASC_',
  /** sort on MagentoOrderType descending */
  MagentoordertypeDesc = '_MAGENTOORDERTYPE_DESC_',
  /** Sort on MagentoOrderTypeOPTION ascending */
  MagentoordertypeoptionAsc = '_MAGENTOORDERTYPEOPTION_ASC_',
  /** sort on MagentoOrderTypeOPTION descending */
  MagentoordertypeoptionDesc = '_MAGENTOORDERTYPEOPTION_DESC_',
  /** Sort on MagentoOrderId ascending */
  MagentoorderidAsc = '_MAGENTOORDERID_ASC_',
  /** sort on MagentoOrderId descending */
  MagentoorderidDesc = '_MAGENTOORDERID_DESC_',
  /** Sort on MagentoOrderEmail ascending */
  MagentoorderemailAsc = '_MAGENTOORDEREMAIL_ASC_',
  /** sort on MagentoOrderEmail descending */
  MagentoorderemailDesc = '_MAGENTOORDEREMAIL_DESC_',
  /** Sort on MagentoOrderMobileNo ascending */
  MagentoordermobilenoAsc = '_MAGENTOORDERMOBILENO_ASC_',
  /** sort on MagentoOrderMobileNo descending */
  MagentoordermobilenoDesc = '_MAGENTOORDERMOBILENO_DESC_',
  /** Sort on MagentoOrderLinesCount ascending */
  MagentoorderlinescountAsc = '_MAGENTOORDERLINESCOUNT_ASC_',
  /** sort on MagentoOrderLinesCount descending */
  MagentoorderlinescountDesc = '_MAGENTOORDERLINESCOUNT_DESC_',
  /** Sort on PEBNoteofGoods ascending */
  PebnoteofgoodsAsc = '_PEBNOTEOFGOODS_ASC_',
  /** sort on PEBNoteofGoods descending */
  PebnoteofgoodsDesc = '_PEBNOTEOFGOODS_DESC_',
  /** Sort on ShippingAgentServiceCode ascending */
  ShippingagentservicecodeAsc = '_SHIPPINGAGENTSERVICECODE_ASC_',
  /** sort on ShippingAgentServiceCode descending */
  ShippingagentservicecodeDesc = '_SHIPPINGAGENTSERVICECODE_DESC_',
  /** Sort on EdgeRetryCount ascending */
  EdgeretrycountAsc = '_EDGERETRYCOUNT_ASC_',
  /** sort on EdgeRetryCount descending */
  EdgeretrycountDesc = '_EDGERETRYCOUNT_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on BilltoName ascending */
  BilltonameAsc = '_BILLTONAME_ASC_',
  /** sort on BilltoName descending */
  BilltonameDesc = '_BILLTONAME_DESC_',
  /** Sort on BilltoName2 ascending */
  Billtoname2Asc = '_BILLTONAME2_ASC_',
  /** sort on BilltoName2 descending */
  Billtoname2Desc = '_BILLTONAME2_DESC_',
  /** Sort on BilltoAddress ascending */
  BilltoaddressAsc = '_BILLTOADDRESS_ASC_',
  /** sort on BilltoAddress descending */
  BilltoaddressDesc = '_BILLTOADDRESS_DESC_',
  /** Sort on BilltoAddress2 ascending */
  Billtoaddress2Asc = '_BILLTOADDRESS2_ASC_',
  /** sort on BilltoAddress2 descending */
  Billtoaddress2Desc = '_BILLTOADDRESS2_DESC_',
  /** Sort on BilltoCity ascending */
  BilltocityAsc = '_BILLTOCITY_ASC_',
  /** sort on BilltoCity descending */
  BilltocityDesc = '_BILLTOCITY_DESC_',
  /** Sort on BilltoContact ascending */
  BilltocontactAsc = '_BILLTOCONTACT_ASC_',
  /** sort on BilltoContact descending */
  BilltocontactDesc = '_BILLTOCONTACT_DESC_',
  /** Sort on YourReference ascending */
  YourreferenceAsc = '_YOURREFERENCE_ASC_',
  /** sort on YourReference descending */
  YourreferenceDesc = '_YOURREFERENCE_DESC_',
  /** Sort on ShiptoCode ascending */
  ShiptocodeAsc = '_SHIPTOCODE_ASC_',
  /** sort on ShiptoCode descending */
  ShiptocodeDesc = '_SHIPTOCODE_DESC_',
  /** Sort on ShiptoName ascending */
  ShiptonameAsc = '_SHIPTONAME_ASC_',
  /** sort on ShiptoName descending */
  ShiptonameDesc = '_SHIPTONAME_DESC_',
  /** Sort on ShiptoName2 ascending */
  Shiptoname2Asc = '_SHIPTONAME2_ASC_',
  /** sort on ShiptoName2 descending */
  Shiptoname2Desc = '_SHIPTONAME2_DESC_',
  /** Sort on ShiptoAddress ascending */
  ShiptoaddressAsc = '_SHIPTOADDRESS_ASC_',
  /** sort on ShiptoAddress descending */
  ShiptoaddressDesc = '_SHIPTOADDRESS_DESC_',
  /** Sort on ShiptoAddress2 ascending */
  Shiptoaddress2Asc = '_SHIPTOADDRESS2_ASC_',
  /** sort on ShiptoAddress2 descending */
  Shiptoaddress2Desc = '_SHIPTOADDRESS2_DESC_',
  /** Sort on ShiptoCity ascending */
  ShiptocityAsc = '_SHIPTOCITY_ASC_',
  /** sort on ShiptoCity descending */
  ShiptocityDesc = '_SHIPTOCITY_DESC_',
  /** Sort on ShiptoContact ascending */
  ShiptocontactAsc = '_SHIPTOCONTACT_ASC_',
  /** sort on ShiptoContact descending */
  ShiptocontactDesc = '_SHIPTOCONTACT_DESC_',
  /** Sort on OrderDate ascending */
  OrderdateAsc = '_ORDERDATE_ASC_',
  /** sort on OrderDate descending */
  OrderdateDesc = '_ORDERDATE_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on PostingDescription ascending */
  PostingdescriptionAsc = '_POSTINGDESCRIPTION_ASC_',
  /** sort on PostingDescription descending */
  PostingdescriptionDesc = '_POSTINGDESCRIPTION_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on DueDate ascending */
  DuedateAsc = '_DUEDATE_ASC_',
  /** sort on DueDate descending */
  DuedateDesc = '_DUEDATE_DESC_',
  /** Sort on PaymentDiscount ascending */
  PaymentdiscountAsc = '_PAYMENTDISCOUNT_ASC_',
  /** sort on PaymentDiscount descending */
  PaymentdiscountDesc = '_PAYMENTDISCOUNT_DESC_',
  /** Sort on PmtDiscountDate ascending */
  PmtdiscountdateAsc = '_PMTDISCOUNTDATE_ASC_',
  /** sort on PmtDiscountDate descending */
  PmtdiscountdateDesc = '_PMTDISCOUNTDATE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPostingGroup ascending */
  CustomerpostinggroupAsc = '_CUSTOMERPOSTINGGROUP_ASC_',
  /** sort on CustomerPostingGroup descending */
  CustomerpostinggroupDesc = '_CUSTOMERPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CurrencyFactor ascending */
  CurrencyfactorAsc = '_CURRENCYFACTOR_ASC_',
  /** sort on CurrencyFactor descending */
  CurrencyfactorDesc = '_CURRENCYFACTOR_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on SalespersonCode ascending */
  SalespersoncodeAsc = '_SALESPERSONCODE_ASC_',
  /** sort on SalespersonCode descending */
  SalespersoncodeDesc = '_SALESPERSONCODE_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on NoPrinted ascending */
  NoprintedAsc = '_NOPRINTED_ASC_',
  /** sort on NoPrinted descending */
  NoprintedDesc = '_NOPRINTED_DESC_',
  /** Sort on OnHold ascending */
  OnholdAsc = '_ONHOLD_ASC_',
  /** sort on OnHold descending */
  OnholdDesc = '_ONHOLD_DESC_',
  /** Sort on AppliestoDocType ascending */
  AppliestodoctypeAsc = '_APPLIESTODOCTYPE_ASC_',
  /** sort on AppliestoDocType descending */
  AppliestodoctypeDesc = '_APPLIESTODOCTYPE_DESC_',
  /** Sort on AppliestoDocTypeOPTION ascending */
  AppliestodoctypeoptionAsc = '_APPLIESTODOCTYPEOPTION_ASC_',
  /** sort on AppliestoDocTypeOPTION descending */
  AppliestodoctypeoptionDesc = '_APPLIESTODOCTYPEOPTION_DESC_',
  /** Sort on AppliestoDocNo ascending */
  AppliestodocnoAsc = '_APPLIESTODOCNO_ASC_',
  /** sort on AppliestoDocNo descending */
  AppliestodocnoDesc = '_APPLIESTODOCNO_DESC_',
  /** Sort on BalAccountNo ascending */
  BalaccountnoAsc = '_BALACCOUNTNO_ASC_',
  /** sort on BalAccountNo descending */
  BalaccountnoDesc = '_BALACCOUNTNO_DESC_',
  /** Sort on Amount ascending */
  AmountAsc = '_AMOUNT_ASC_',
  /** sort on Amount descending */
  AmountDesc = '_AMOUNT_DESC_',
  /** Sort on AmountIncludingVAT ascending */
  AmountincludingvatAsc = '_AMOUNTINCLUDINGVAT_ASC_',
  /** sort on AmountIncludingVAT descending */
  AmountincludingvatDesc = '_AMOUNTINCLUDINGVAT_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on ReasonCode ascending */
  ReasoncodeAsc = '_REASONCODE_ASC_',
  /** sort on ReasonCode descending */
  ReasoncodeDesc = '_REASONCODE_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on EU3PartyTrade ascending */
  Eu3PartytradeAsc = '_EU3PARTYTRADE_ASC_',
  /** sort on EU3PartyTrade descending */
  Eu3PartytradeDesc = '_EU3PARTYTRADE_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on VATCountryRegionCode ascending */
  VatcountryregioncodeAsc = '_VATCOUNTRYREGIONCODE_ASC_',
  /** sort on VATCountryRegionCode descending */
  VatcountryregioncodeDesc = '_VATCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoCustomerName ascending */
  SelltocustomernameAsc = '_SELLTOCUSTOMERNAME_ASC_',
  /** sort on SelltoCustomerName descending */
  SelltocustomernameDesc = '_SELLTOCUSTOMERNAME_DESC_',
  /** Sort on SelltoCustomerName2 ascending */
  Selltocustomername2Asc = '_SELLTOCUSTOMERNAME2_ASC_',
  /** sort on SelltoCustomerName2 descending */
  Selltocustomername2Desc = '_SELLTOCUSTOMERNAME2_DESC_',
  /** Sort on SelltoAddress ascending */
  SelltoaddressAsc = '_SELLTOADDRESS_ASC_',
  /** sort on SelltoAddress descending */
  SelltoaddressDesc = '_SELLTOADDRESS_DESC_',
  /** Sort on SelltoAddress2 ascending */
  Selltoaddress2Asc = '_SELLTOADDRESS2_ASC_',
  /** sort on SelltoAddress2 descending */
  Selltoaddress2Desc = '_SELLTOADDRESS2_DESC_',
  /** Sort on SelltoCity ascending */
  SelltocityAsc = '_SELLTOCITY_ASC_',
  /** sort on SelltoCity descending */
  SelltocityDesc = '_SELLTOCITY_DESC_',
  /** Sort on SelltoContact ascending */
  SelltocontactAsc = '_SELLTOCONTACT_ASC_',
  /** sort on SelltoContact descending */
  SelltocontactDesc = '_SELLTOCONTACT_DESC_',
  /** Sort on BilltoPostCode ascending */
  BilltopostcodeAsc = '_BILLTOPOSTCODE_ASC_',
  /** sort on BilltoPostCode descending */
  BilltopostcodeDesc = '_BILLTOPOSTCODE_DESC_',
  /** Sort on BilltoCounty ascending */
  BilltocountyAsc = '_BILLTOCOUNTY_ASC_',
  /** sort on BilltoCounty descending */
  BilltocountyDesc = '_BILLTOCOUNTY_DESC_',
  /** Sort on BilltoCountryRegionCode ascending */
  BilltocountryregioncodeAsc = '_BILLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on BilltoCountryRegionCode descending */
  BilltocountryregioncodeDesc = '_BILLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on SelltoPostCode ascending */
  SelltopostcodeAsc = '_SELLTOPOSTCODE_ASC_',
  /** sort on SelltoPostCode descending */
  SelltopostcodeDesc = '_SELLTOPOSTCODE_DESC_',
  /** Sort on SelltoCounty ascending */
  SelltocountyAsc = '_SELLTOCOUNTY_ASC_',
  /** sort on SelltoCounty descending */
  SelltocountyDesc = '_SELLTOCOUNTY_DESC_',
  /** Sort on SelltoCountryRegionCode ascending */
  SelltocountryregioncodeAsc = '_SELLTOCOUNTRYREGIONCODE_ASC_',
  /** sort on SelltoCountryRegionCode descending */
  SelltocountryregioncodeDesc = '_SELLTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on ShiptoPostCode ascending */
  ShiptopostcodeAsc = '_SHIPTOPOSTCODE_ASC_',
  /** sort on ShiptoPostCode descending */
  ShiptopostcodeDesc = '_SHIPTOPOSTCODE_DESC_',
  /** Sort on ShiptoCounty ascending */
  ShiptocountyAsc = '_SHIPTOCOUNTY_ASC_',
  /** sort on ShiptoCounty descending */
  ShiptocountyDesc = '_SHIPTOCOUNTY_DESC_',
  /** Sort on ShiptoCountryRegionCode ascending */
  ShiptocountryregioncodeAsc = '_SHIPTOCOUNTRYREGIONCODE_ASC_',
  /** sort on ShiptoCountryRegionCode descending */
  ShiptocountryregioncodeDesc = '_SHIPTOCOUNTRYREGIONCODE_DESC_',
  /** Sort on BalAccountType ascending */
  BalaccounttypeAsc = '_BALACCOUNTTYPE_ASC_',
  /** sort on BalAccountType descending */
  BalaccounttypeDesc = '_BALACCOUNTTYPE_DESC_',
  /** Sort on BalAccountTypeOPTION ascending */
  BalaccounttypeoptionAsc = '_BALACCOUNTTYPEOPTION_ASC_',
  /** sort on BalAccountTypeOPTION descending */
  BalaccounttypeoptionDesc = '_BALACCOUNTTYPEOPTION_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on DocumentDate ascending */
  DocumentdateAsc = '_DOCUMENTDATE_ASC_',
  /** sort on DocumentDate descending */
  DocumentdateDesc = '_DOCUMENTDATE_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on PackageTrackingNo ascending */
  PackagetrackingnoAsc = '_PACKAGETRACKINGNO_ASC_',
  /** sort on PackageTrackingNo descending */
  PackagetrackingnoDesc = '_PACKAGETRACKINGNO_DESC_',
  /** Sort on PreAssignedNoSeries ascending */
  PreassignednoseriesAsc = '_PREASSIGNEDNOSERIES_ASC_',
  /** sort on PreAssignedNoSeries descending */
  PreassignednoseriesDesc = '_PREASSIGNEDNOSERIES_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on OrderNoSeries ascending */
  OrdernoseriesAsc = '_ORDERNOSERIES_ASC_',
  /** sort on OrderNoSeries descending */
  OrdernoseriesDesc = '_ORDERNOSERIES_DESC_',
  /** Sort on PreAssignedNo ascending */
  PreassignednoAsc = '_PREASSIGNEDNO_ASC_',
  /** sort on PreAssignedNo descending */
  PreassignednoDesc = '_PREASSIGNEDNO_DESC_',
  /** Sort on UserID ascending */
  UseridAsc = '_USERID_ASC_',
  /** sort on UserID descending */
  UseridDesc = '_USERID_DESC_',
  /** Sort on SourceCode ascending */
  SourcecodeAsc = '_SOURCECODE_ASC_',
  /** sort on SourceCode descending */
  SourcecodeDesc = '_SOURCECODE_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATBaseDiscount ascending */
  VatbasediscountAsc = '_VATBASEDISCOUNT_ASC_',
  /** sort on VATBaseDiscount descending */
  VatbasediscountDesc = '_VATBASEDISCOUNT_DESC_',
  /** Sort on PrepaymentNoSeries ascending */
  PrepaymentnoseriesAsc = '_PREPAYMENTNOSERIES_ASC_',
  /** sort on PrepaymentNoSeries descending */
  PrepaymentnoseriesDesc = '_PREPAYMENTNOSERIES_DESC_',
  /** Sort on PrepaymentInvoice ascending */
  PrepaymentinvoiceAsc = '_PREPAYMENTINVOICE_ASC_',
  /** sort on PrepaymentInvoice descending */
  PrepaymentinvoiceDesc = '_PREPAYMENTINVOICE_DESC_',
  /** Sort on PrepaymentOrderNo ascending */
  PrepaymentordernoAsc = '_PREPAYMENTORDERNO_ASC_',
  /** sort on PrepaymentOrderNo descending */
  PrepaymentordernoDesc = '_PREPAYMENTORDERNO_DESC_',
  /** Sort on QuoteNo ascending */
  QuotenoAsc = '_QUOTENO_ASC_',
  /** sort on QuoteNo descending */
  QuotenoDesc = '_QUOTENO_DESC_',
  /** Sort on WorkDescription ascending */
  WorkdescriptionAsc = '_WORKDESCRIPTION_ASC_',
  /** sort on WorkDescription descending */
  WorkdescriptionDesc = '_WORKDESCRIPTION_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on PaymentServiceSetID ascending */
  PaymentservicesetidAsc = '_PAYMENTSERVICESETID_ASC_',
  /** sort on PaymentServiceSetID descending */
  PaymentservicesetidDesc = '_PAYMENTSERVICESETID_DESC_',
  /** Sort on DocumentExchangeIdentifier ascending */
  DocumentexchangeidentifierAsc = '_DOCUMENTEXCHANGEIDENTIFIER_ASC_',
  /** sort on DocumentExchangeIdentifier descending */
  DocumentexchangeidentifierDesc = '_DOCUMENTEXCHANGEIDENTIFIER_DESC_',
  /** Sort on DocumentExchangeStatus ascending */
  DocumentexchangestatusAsc = '_DOCUMENTEXCHANGESTATUS_ASC_',
  /** sort on DocumentExchangeStatus descending */
  DocumentexchangestatusDesc = '_DOCUMENTEXCHANGESTATUS_DESC_',
  /** Sort on DocumentExchangeStatusOPTION ascending */
  DocumentexchangestatusoptionAsc = '_DOCUMENTEXCHANGESTATUSOPTION_ASC_',
  /** sort on DocumentExchangeStatusOPTION descending */
  DocumentexchangestatusoptionDesc = '_DOCUMENTEXCHANGESTATUSOPTION_DESC_',
  /** Sort on DocExchOriginalIdentifier ascending */
  DocexchoriginalidentifierAsc = '_DOCEXCHORIGINALIDENTIFIER_ASC_',
  /** sort on DocExchOriginalIdentifier descending */
  DocexchoriginalidentifierDesc = '_DOCEXCHORIGINALIDENTIFIER_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on DirectDebitMandateID ascending */
  DirectdebitmandateidAsc = '_DIRECTDEBITMANDATEID_ASC_',
  /** sort on DirectDebitMandateID descending */
  DirectdebitmandateidDesc = '_DIRECTDEBITMANDATEID_DESC_',
  /** Sort on Closed ascending */
  ClosedAsc = '_CLOSED_ASC_',
  /** sort on Closed descending */
  ClosedDesc = '_CLOSED_DESC_',
  /** Sort on RemainingAmount ascending */
  RemainingamountAsc = '_REMAININGAMOUNT_ASC_',
  /** sort on RemainingAmount descending */
  RemainingamountDesc = '_REMAININGAMOUNT_DESC_',
  /** Sort on CustLedgerEntryNo ascending */
  CustledgerentrynoAsc = '_CUSTLEDGERENTRYNO_ASC_',
  /** sort on CustLedgerEntryNo descending */
  CustledgerentrynoDesc = '_CUSTLEDGERENTRYNO_DESC_',
  /** Sort on InvoiceDiscountAmount ascending */
  InvoicediscountamountAsc = '_INVOICEDISCOUNTAMOUNT_ASC_',
  /** sort on InvoiceDiscountAmount descending */
  InvoicediscountamountDesc = '_INVOICEDISCOUNTAMOUNT_DESC_',
  /** Sort on Cancelled ascending */
  CancelledAsc = '_CANCELLED_ASC_',
  /** sort on Cancelled descending */
  CancelledDesc = '_CANCELLED_DESC_',
  /** Sort on Corrective ascending */
  CorrectiveAsc = '_CORRECTIVE_ASC_',
  /** sort on Corrective descending */
  CorrectiveDesc = '_CORRECTIVE_DESC_',
  /** Sort on CampaignNo ascending */
  CampaignnoAsc = '_CAMPAIGNNO_ASC_',
  /** sort on CampaignNo descending */
  CampaignnoDesc = '_CAMPAIGNNO_DESC_',
  /** Sort on SelltoContactNo ascending */
  SelltocontactnoAsc = '_SELLTOCONTACTNO_ASC_',
  /** sort on SelltoContactNo descending */
  SelltocontactnoDesc = '_SELLTOCONTACTNO_DESC_',
  /** Sort on BilltoContactNo ascending */
  BilltocontactnoAsc = '_BILLTOCONTACTNO_ASC_',
  /** sort on BilltoContactNo descending */
  BilltocontactnoDesc = '_BILLTOCONTACTNO_DESC_',
  /** Sort on OpportunityNo ascending */
  OpportunitynoAsc = '_OPPORTUNITYNO_ASC_',
  /** sort on OpportunityNo descending */
  OpportunitynoDesc = '_OPPORTUNITYNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on GetShipmentUsed ascending */
  GetshipmentusedAsc = '_GETSHIPMENTUSED_ASC_',
  /** sort on GetShipmentUsed descending */
  GetshipmentusedDesc = '_GETSHIPMENTUSED_DESC_',
  /** Sort on Preseason ascending */
  PreseasonAsc = '_PRESEASON_ASC_',
  /** sort on Preseason descending */
  PreseasonDesc = '_PRESEASON_DESC_',
  /** Sort on AdditionalTelephone ascending */
  AdditionaltelephoneAsc = '_ADDITIONALTELEPHONE_ASC_',
  /** sort on AdditionalTelephone descending */
  AdditionaltelephoneDesc = '_ADDITIONALTELEPHONE_DESC_',
  /** Sort on AdditionalCustomerNote ascending */
  AdditionalcustomernoteAsc = '_ADDITIONALCUSTOMERNOTE_ASC_',
  /** sort on AdditionalCustomerNote descending */
  AdditionalcustomernoteDesc = '_ADDITIONALCUSTOMERNOTE_DESC_',
  /** Sort on CustomerCommentExists ascending */
  CustomercommentexistsAsc = '_CUSTOMERCOMMENTEXISTS_ASC_',
  /** sort on CustomerCommentExists descending */
  CustomercommentexistsDesc = '_CUSTOMERCOMMENTEXISTS_DESC_',
  /** Sort on WebOrderNo ascending */
  WebordernoAsc = '_WEBORDERNO_ASC_',
  /** sort on WebOrderNo descending */
  WebordernoDesc = '_WEBORDERNO_DESC_',
  /** Sort on WebOrderEntityNo ascending */
  WeborderentitynoAsc = '_WEBORDERENTITYNO_ASC_',
  /** sort on WebOrderEntityNo descending */
  WeborderentitynoDesc = '_WEBORDERENTITYNO_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on EMailOrderConfirmation ascending */
  EmailorderconfirmationAsc = '_EMAILORDERCONFIRMATION_ASC_',
  /** sort on EMailOrderConfirmation descending */
  EmailorderconfirmationDesc = '_EMAILORDERCONFIRMATION_DESC_',
  /** Sort on EMailSalesShipment ascending */
  EmailsalesshipmentAsc = '_EMAILSALESSHIPMENT_ASC_',
  /** sort on EMailSalesShipment descending */
  EmailsalesshipmentDesc = '_EMAILSALESSHIPMENT_DESC_',
  /** Sort on EMailInvoice ascending */
  EmailinvoiceAsc = '_EMAILINVOICE_ASC_',
  /** sort on EMailInvoice descending */
  EmailinvoiceDesc = '_EMAILINVOICE_DESC_',
  /** Sort on EMailShipmentNotification ascending */
  EmailshipmentnotificationAsc = '_EMAILSHIPMENTNOTIFICATION_ASC_',
  /** sort on EMailShipmentNotification descending */
  EmailshipmentnotificationDesc = '_EMAILSHIPMENTNOTIFICATION_DESC_',
  /** Sort on OrderCreatedBy ascending */
  OrdercreatedbyAsc = '_ORDERCREATEDBY_ASC_',
  /** sort on OrderCreatedBy descending */
  OrdercreatedbyDesc = '_ORDERCREATEDBY_DESC_',
  /** Sort on OrderCreated ascending */
  OrdercreatedAsc = '_ORDERCREATED_ASC_',
  /** sort on OrderCreated descending */
  OrdercreatedDesc = '_ORDERCREATED_DESC_',
  /** Sort on OrderType ascending */
  OrdertypeAsc = '_ORDERTYPE_ASC_',
  /** sort on OrderType descending */
  OrdertypeDesc = '_ORDERTYPE_DESC_',
  /** Sort on OrderTypeOPTION ascending */
  OrdertypeoptionAsc = '_ORDERTYPEOPTION_ASC_',
  /** sort on OrderTypeOPTION descending */
  OrdertypeoptionDesc = '_ORDERTYPEOPTION_DESC_',
  /** Sort on ShipmentDocType ascending */
  ShipmentdoctypeAsc = '_SHIPMENTDOCTYPE_ASC_',
  /** sort on ShipmentDocType descending */
  ShipmentdoctypeDesc = '_SHIPMENTDOCTYPE_DESC_',
  /** Sort on ShipmentDocTypeOPTION ascending */
  ShipmentdoctypeoptionAsc = '_SHIPMENTDOCTYPEOPTION_ASC_',
  /** sort on ShipmentDocTypeOPTION descending */
  ShipmentdoctypeoptionDesc = '_SHIPMENTDOCTYPEOPTION_DESC_',
  /** Sort on GearsOrder ascending */
  GearsorderAsc = '_GEARSORDER_ASC_',
  /** sort on GearsOrder descending */
  GearsorderDesc = '_GEARSORDER_DESC_',
  /** Sort on NumberofCustomerPriceGroup ascending */
  NumberofcustomerpricegroupAsc = '_NUMBEROFCUSTOMERPRICEGROUP_ASC_',
  /** sort on NumberofCustomerPriceGroup descending */
  NumberofcustomerpricegroupDesc = '_NUMBEROFCUSTOMERPRICEGROUP_DESC_',
  /** Sort on EDISetupCode ascending */
  EdisetupcodeAsc = '_EDISETUPCODE_ASC_',
  /** sort on EDISetupCode descending */
  EdisetupcodeDesc = '_EDISETUPCODE_DESC_',
  /** Sort on InvoiceDocumentType ascending */
  InvoicedocumenttypeAsc = '_INVOICEDOCUMENTTYPE_ASC_',
  /** sort on InvoiceDocumentType descending */
  InvoicedocumenttypeDesc = '_INVOICEDOCUMENTTYPE_DESC_',
  /** Sort on InvoiceDocumentTypeOPTION ascending */
  InvoicedocumenttypeoptionAsc = '_INVOICEDOCUMENTTYPEOPTION_ASC_',
  /** sort on InvoiceDocumentTypeOPTION descending */
  InvoicedocumenttypeoptionDesc = '_INVOICEDOCUMENTTYPEOPTION_DESC_',
  /** Sort on InvoiceSentToCustomer ascending */
  InvoicesenttocustomerAsc = '_INVOICESENTTOCUSTOMER_ASC_',
  /** sort on InvoiceSentToCustomer descending */
  InvoicesenttocustomerDesc = '_INVOICESENTTOCUSTOMER_DESC_',
  /** Sort on InvoiceSentToCustomerDate ascending */
  InvoicesenttocustomerdateAsc = '_INVOICESENTTOCUSTOMERDATE_ASC_',
  /** sort on InvoiceSentToCustomerDate descending */
  InvoicesenttocustomerdateDesc = '_INVOICESENTTOCUSTOMERDATE_DESC_',
  /** Sort on InvoiceSentToCustomerTime ascending */
  InvoicesenttocustomertimeAsc = '_INVOICESENTTOCUSTOMERTIME_ASC_',
  /** sort on InvoiceSentToCustomerTime descending */
  InvoicesenttocustomertimeDesc = '_INVOICESENTTOCUSTOMERTIME_DESC_',
  /** Sort on NoteofGoods ascending */
  NoteofgoodsAsc = '_NOTEOFGOODS_ASC_',
  /** sort on NoteofGoods descending */
  NoteofgoodsDesc = '_NOTEOFGOODS_DESC_',
  /** Sort on DocumentGlobalId ascending */
  DocumentglobalidAsc = '_DOCUMENTGLOBALID_ASC_',
  /** sort on DocumentGlobalId descending */
  DocumentglobalidDesc = '_DOCUMENTGLOBALID_DESC_',
  /** Sort on FreightOnlyShipment ascending */
  FreightonlyshipmentAsc = '_FREIGHTONLYSHIPMENT_ASC_',
  /** sort on FreightOnlyShipment descending */
  FreightonlyshipmentDesc = '_FREIGHTONLYSHIPMENT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalesShipmentLineInput {
  /** Sort on MagentoOrderLineType ascending */
  MagentoorderlinetypeAsc = '_MAGENTOORDERLINETYPE_ASC_',
  /** sort on MagentoOrderLineType descending */
  MagentoorderlinetypeDesc = '_MAGENTOORDERLINETYPE_DESC_',
  /** Sort on MagentoOrderLineTypeOPTION ascending */
  MagentoorderlinetypeoptionAsc = '_MAGENTOORDERLINETYPEOPTION_ASC_',
  /** sort on MagentoOrderLineTypeOPTION descending */
  MagentoorderlinetypeoptionDesc = '_MAGENTOORDERLINETYPEOPTION_DESC_',
  /** Sort on MagentoOrderLineNo ascending */
  MagentoorderlinenoAsc = '_MAGENTOORDERLINENO_ASC_',
  /** sort on MagentoOrderLineNo descending */
  MagentoorderlinenoDesc = '_MAGENTOORDERLINENO_DESC_',
  /** Sort on MagentoOrderLineQty ascending */
  MagentoorderlineqtyAsc = '_MAGENTOORDERLINEQTY_ASC_',
  /** sort on MagentoOrderLineQty descending */
  MagentoorderlineqtyDesc = '_MAGENTOORDERLINEQTY_DESC_',
  /** Sort on SelltoCustomerNo ascending */
  SelltocustomernoAsc = '_SELLTOCUSTOMERNO_ASC_',
  /** sort on SelltoCustomerNo descending */
  SelltocustomernoDesc = '_SELLTOCUSTOMERNO_DESC_',
  /** Sort on DocumentNo ascending */
  DocumentnoAsc = '_DOCUMENTNO_ASC_',
  /** sort on DocumentNo descending */
  DocumentnoDesc = '_DOCUMENTNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on PostingGroup ascending */
  PostinggroupAsc = '_POSTINGGROUP_ASC_',
  /** sort on PostingGroup descending */
  PostinggroupDesc = '_POSTINGGROUP_DESC_',
  /** Sort on ShipmentDate ascending */
  ShipmentdateAsc = '_SHIPMENTDATE_ASC_',
  /** sort on ShipmentDate descending */
  ShipmentdateDesc = '_SHIPMENTDATE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on UnitofMeasure ascending */
  UnitofmeasureAsc = '_UNITOFMEASURE_ASC_',
  /** sort on UnitofMeasure descending */
  UnitofmeasureDesc = '_UNITOFMEASURE_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on UnitCostLCY ascending */
  UnitcostlcyAsc = '_UNITCOSTLCY_ASC_',
  /** sort on UnitCostLCY descending */
  UnitcostlcyDesc = '_UNITCOSTLCY_DESC_',
  /** Sort on VAT ascending */
  VatAsc = '_VAT_ASC_',
  /** sort on VAT descending */
  VatDesc = '_VAT_DESC_',
  /** Sort on LineDiscount ascending */
  LinediscountAsc = '_LINEDISCOUNT_ASC_',
  /** sort on LineDiscount descending */
  LinediscountDesc = '_LINEDISCOUNT_DESC_',
  /** Sort on AllowInvoiceDisc ascending */
  AllowinvoicediscAsc = '_ALLOWINVOICEDISC_ASC_',
  /** sort on AllowInvoiceDisc descending */
  AllowinvoicediscDesc = '_ALLOWINVOICEDISC_DESC_',
  /** Sort on GrossWeight ascending */
  GrossweightAsc = '_GROSSWEIGHT_ASC_',
  /** sort on GrossWeight descending */
  GrossweightDesc = '_GROSSWEIGHT_DESC_',
  /** Sort on NetWeight ascending */
  NetweightAsc = '_NETWEIGHT_ASC_',
  /** sort on NetWeight descending */
  NetweightDesc = '_NETWEIGHT_DESC_',
  /** Sort on UnitsperParcel ascending */
  UnitsperparcelAsc = '_UNITSPERPARCEL_ASC_',
  /** sort on UnitsperParcel descending */
  UnitsperparcelDesc = '_UNITSPERPARCEL_DESC_',
  /** Sort on UnitVolume ascending */
  UnitvolumeAsc = '_UNITVOLUME_ASC_',
  /** sort on UnitVolume descending */
  UnitvolumeDesc = '_UNITVOLUME_DESC_',
  /** Sort on AppltoItemEntry ascending */
  AppltoitementryAsc = '_APPLTOITEMENTRY_ASC_',
  /** sort on AppltoItemEntry descending */
  AppltoitementryDesc = '_APPLTOITEMENTRY_DESC_',
  /** Sort on ItemShptEntryNo ascending */
  ItemshptentrynoAsc = '_ITEMSHPTENTRYNO_ASC_',
  /** sort on ItemShptEntryNo descending */
  ItemshptentrynoDesc = '_ITEMSHPTENTRYNO_DESC_',
  /** Sort on ShortcutDimension1Code ascending */
  Shortcutdimension1CodeAsc = '_SHORTCUTDIMENSION1CODE_ASC_',
  /** sort on ShortcutDimension1Code descending */
  Shortcutdimension1CodeDesc = '_SHORTCUTDIMENSION1CODE_DESC_',
  /** Sort on ShortcutDimension2Code ascending */
  Shortcutdimension2CodeAsc = '_SHORTCUTDIMENSION2CODE_ASC_',
  /** sort on ShortcutDimension2Code descending */
  Shortcutdimension2CodeDesc = '_SHORTCUTDIMENSION2CODE_DESC_',
  /** Sort on CustomerPriceGroup ascending */
  CustomerpricegroupAsc = '_CUSTOMERPRICEGROUP_ASC_',
  /** sort on CustomerPriceGroup descending */
  CustomerpricegroupDesc = '_CUSTOMERPRICEGROUP_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on QtyShippedNotInvoiced ascending */
  QtyshippednotinvoicedAsc = '_QTYSHIPPEDNOTINVOICED_ASC_',
  /** sort on QtyShippedNotInvoiced descending */
  QtyshippednotinvoicedDesc = '_QTYSHIPPEDNOTINVOICED_DESC_',
  /** Sort on QuantityInvoiced ascending */
  QuantityinvoicedAsc = '_QUANTITYINVOICED_ASC_',
  /** sort on QuantityInvoiced descending */
  QuantityinvoicedDesc = '_QUANTITYINVOICED_DESC_',
  /** Sort on OrderNo ascending */
  OrdernoAsc = '_ORDERNO_ASC_',
  /** sort on OrderNo descending */
  OrdernoDesc = '_ORDERNO_DESC_',
  /** Sort on OrderLineNo ascending */
  OrderlinenoAsc = '_ORDERLINENO_ASC_',
  /** sort on OrderLineNo descending */
  OrderlinenoDesc = '_ORDERLINENO_DESC_',
  /** Sort on BilltoCustomerNo ascending */
  BilltocustomernoAsc = '_BILLTOCUSTOMERNO_ASC_',
  /** sort on BilltoCustomerNo descending */
  BilltocustomernoDesc = '_BILLTOCUSTOMERNO_DESC_',
  /** Sort on PurchaseOrderNo ascending */
  PurchaseordernoAsc = '_PURCHASEORDERNO_ASC_',
  /** sort on PurchaseOrderNo descending */
  PurchaseordernoDesc = '_PURCHASEORDERNO_DESC_',
  /** Sort on PurchOrderLineNo ascending */
  PurchorderlinenoAsc = '_PURCHORDERLINENO_ASC_',
  /** sort on PurchOrderLineNo descending */
  PurchorderlinenoDesc = '_PURCHORDERLINENO_DESC_',
  /** Sort on DropShipment ascending */
  DropshipmentAsc = '_DROPSHIPMENT_ASC_',
  /** sort on DropShipment descending */
  DropshipmentDesc = '_DROPSHIPMENT_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATCalculationType ascending */
  VatcalculationtypeAsc = '_VATCALCULATIONTYPE_ASC_',
  /** sort on VATCalculationType descending */
  VatcalculationtypeDesc = '_VATCALCULATIONTYPE_DESC_',
  /** Sort on VATCalculationTypeOPTION ascending */
  VatcalculationtypeoptionAsc = '_VATCALCULATIONTYPEOPTION_ASC_',
  /** sort on VATCalculationTypeOPTION descending */
  VatcalculationtypeoptionDesc = '_VATCALCULATIONTYPEOPTION_DESC_',
  /** Sort on TransactionType ascending */
  TransactiontypeAsc = '_TRANSACTIONTYPE_ASC_',
  /** sort on TransactionType descending */
  TransactiontypeDesc = '_TRANSACTIONTYPE_DESC_',
  /** Sort on TransportMethod ascending */
  TransportmethodAsc = '_TRANSPORTMETHOD_ASC_',
  /** sort on TransportMethod descending */
  TransportmethodDesc = '_TRANSPORTMETHOD_DESC_',
  /** Sort on AttachedtoLineNo ascending */
  AttachedtolinenoAsc = '_ATTACHEDTOLINENO_ASC_',
  /** sort on AttachedtoLineNo descending */
  AttachedtolinenoDesc = '_ATTACHEDTOLINENO_DESC_',
  /** Sort on ExitPoint ascending */
  ExitpointAsc = '_EXITPOINT_ASC_',
  /** sort on ExitPoint descending */
  ExitpointDesc = '_EXITPOINT_DESC_',
  /** Sort on Area ascending */
  AreaAsc = '_AREA_ASC_',
  /** sort on Area descending */
  AreaDesc = '_AREA_DESC_',
  /** Sort on TransactionSpecification ascending */
  TransactionspecificationAsc = '_TRANSACTIONSPECIFICATION_ASC_',
  /** sort on TransactionSpecification descending */
  TransactionspecificationDesc = '_TRANSACTIONSPECIFICATION_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on TaxGroupCode ascending */
  TaxgroupcodeAsc = '_TAXGROUPCODE_ASC_',
  /** sort on TaxGroupCode descending */
  TaxgroupcodeDesc = '_TAXGROUPCODE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on BlanketOrderNo ascending */
  BlanketordernoAsc = '_BLANKETORDERNO_ASC_',
  /** sort on BlanketOrderNo descending */
  BlanketordernoDesc = '_BLANKETORDERNO_DESC_',
  /** Sort on BlanketOrderLineNo ascending */
  BlanketorderlinenoAsc = '_BLANKETORDERLINENO_ASC_',
  /** sort on BlanketOrderLineNo descending */
  BlanketorderlinenoDesc = '_BLANKETORDERLINENO_DESC_',
  /** Sort on VATBaseAmount ascending */
  VatbaseamountAsc = '_VATBASEAMOUNT_ASC_',
  /** sort on VATBaseAmount descending */
  VatbaseamountDesc = '_VATBASEAMOUNT_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on PostingDate ascending */
  PostingdateAsc = '_POSTINGDATE_ASC_',
  /** sort on PostingDate descending */
  PostingdateDesc = '_POSTINGDATE_DESC_',
  /** Sort on DimensionSetID ascending */
  DimensionsetidAsc = '_DIMENSIONSETID_ASC_',
  /** sort on DimensionSetID descending */
  DimensionsetidDesc = '_DIMENSIONSETID_DESC_',
  /** Sort on AuthorizedforCreditCard ascending */
  AuthorizedforcreditcardAsc = '_AUTHORIZEDFORCREDITCARD_ASC_',
  /** sort on AuthorizedforCreditCard descending */
  AuthorizedforcreditcardDesc = '_AUTHORIZEDFORCREDITCARD_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on JobContractEntryNo ascending */
  JobcontractentrynoAsc = '_JOBCONTRACTENTRYNO_ASC_',
  /** sort on JobContractEntryNo descending */
  JobcontractentrynoDesc = '_JOBCONTRACTENTRYNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on BinCode ascending */
  BincodeAsc = '_BINCODE_ASC_',
  /** sort on BinCode descending */
  BincodeDesc = '_BINCODE_DESC_',
  /** Sort on QtyperUnitofMeasure ascending */
  QtyperunitofmeasureAsc = '_QTYPERUNITOFMEASURE_ASC_',
  /** sort on QtyperUnitofMeasure descending */
  QtyperunitofmeasureDesc = '_QTYPERUNITOFMEASURE_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on QuantityBase ascending */
  QuantitybaseAsc = '_QUANTITYBASE_ASC_',
  /** sort on QuantityBase descending */
  QuantitybaseDesc = '_QUANTITYBASE_DESC_',
  /** Sort on QtyInvoicedBase ascending */
  QtyinvoicedbaseAsc = '_QTYINVOICEDBASE_ASC_',
  /** sort on QtyInvoicedBase descending */
  QtyinvoicedbaseDesc = '_QTYINVOICEDBASE_DESC_',
  /** Sort on FAPostingDate ascending */
  FapostingdateAsc = '_FAPOSTINGDATE_ASC_',
  /** sort on FAPostingDate descending */
  FapostingdateDesc = '_FAPOSTINGDATE_DESC_',
  /** Sort on DepreciationBookCode ascending */
  DepreciationbookcodeAsc = '_DEPRECIATIONBOOKCODE_ASC_',
  /** sort on DepreciationBookCode descending */
  DepreciationbookcodeDesc = '_DEPRECIATIONBOOKCODE_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on CrossReferenceNo ascending */
  CrossreferencenoAsc = '_CROSSREFERENCENO_ASC_',
  /** sort on CrossReferenceNo descending */
  CrossreferencenoDesc = '_CROSSREFERENCENO_DESC_',
  /** Sort on UnitofMeasureCrossRef ascending */
  UnitofmeasurecrossrefAsc = '_UNITOFMEASURECROSSREF_ASC_',
  /** sort on UnitofMeasureCrossRef descending */
  UnitofmeasurecrossrefDesc = '_UNITOFMEASURECROSSREF_DESC_',
  /** Sort on CrossReferenceType ascending */
  CrossreferencetypeAsc = '_CROSSREFERENCETYPE_ASC_',
  /** sort on CrossReferenceType descending */
  CrossreferencetypeDesc = '_CROSSREFERENCETYPE_DESC_',
  /** Sort on CrossReferenceTypeOPTION ascending */
  CrossreferencetypeoptionAsc = '_CROSSREFERENCETYPEOPTION_ASC_',
  /** sort on CrossReferenceTypeOPTION descending */
  CrossreferencetypeoptionDesc = '_CROSSREFERENCETYPEOPTION_DESC_',
  /** Sort on CrossReferenceTypeNo ascending */
  CrossreferencetypenoAsc = '_CROSSREFERENCETYPENO_ASC_',
  /** sort on CrossReferenceTypeNo descending */
  CrossreferencetypenoDesc = '_CROSSREFERENCETYPENO_DESC_',
  /** Sort on ItemCategoryCode ascending */
  ItemcategorycodeAsc = '_ITEMCATEGORYCODE_ASC_',
  /** sort on ItemCategoryCode descending */
  ItemcategorycodeDesc = '_ITEMCATEGORYCODE_DESC_',
  /** Sort on DuplicateinDepreciationBook ascending */
  DuplicateindepreciationbookAsc = '_DUPLICATEINDEPRECIATIONBOOK_ASC_',
  /** sort on DuplicateinDepreciationBook descending */
  DuplicateindepreciationbookDesc = '_DUPLICATEINDEPRECIATIONBOOK_DESC_',
  /** Sort on PurchasingCode ascending */
  PurchasingcodeAsc = '_PURCHASINGCODE_ASC_',
  /** sort on PurchasingCode descending */
  PurchasingcodeDesc = '_PURCHASINGCODE_DESC_',
  /** Sort on ProductGroupCode ascending */
  ProductgroupcodeAsc = '_PRODUCTGROUPCODE_ASC_',
  /** sort on ProductGroupCode descending */
  ProductgroupcodeDesc = '_PRODUCTGROUPCODE_DESC_',
  /** Sort on RequestedDeliveryDate ascending */
  RequesteddeliverydateAsc = '_REQUESTEDDELIVERYDATE_ASC_',
  /** sort on RequestedDeliveryDate descending */
  RequesteddeliverydateDesc = '_REQUESTEDDELIVERYDATE_DESC_',
  /** Sort on PromisedDeliveryDate ascending */
  PromiseddeliverydateAsc = '_PROMISEDDELIVERYDATE_ASC_',
  /** sort on PromisedDeliveryDate descending */
  PromiseddeliverydateDesc = '_PROMISEDDELIVERYDATE_DESC_',
  /** Sort on ShippingTime ascending */
  ShippingtimeAsc = '_SHIPPINGTIME_ASC_',
  /** sort on ShippingTime descending */
  ShippingtimeDesc = '_SHIPPINGTIME_DESC_',
  /** Sort on OutboundWhseHandlingTime ascending */
  OutboundwhsehandlingtimeAsc = '_OUTBOUNDWHSEHANDLINGTIME_ASC_',
  /** sort on OutboundWhseHandlingTime descending */
  OutboundwhsehandlingtimeDesc = '_OUTBOUNDWHSEHANDLINGTIME_DESC_',
  /** Sort on ReturnReasonCode ascending */
  ReturnreasoncodeAsc = '_RETURNREASONCODE_ASC_',
  /** sort on ReturnReasonCode descending */
  ReturnreasoncodeDesc = '_RETURNREASONCODE_DESC_',
  /** Sort on CustomerDiscGroup ascending */
  CustomerdiscgroupAsc = '_CUSTOMERDISCGROUP_ASC_',
  /** sort on CustomerDiscGroup descending */
  CustomerdiscgroupDesc = '_CUSTOMERDISCGROUP_DESC_',
  /** Sort on AutoAccGroup ascending */
  AutoaccgroupAsc = '_AUTOACCGROUP_ASC_',
  /** sort on AutoAccGroup descending */
  AutoaccgroupDesc = '_AUTOACCGROUP_DESC_',
  /** Sort on CreatedOnOrderBy ascending */
  CreatedonorderbyAsc = '_CREATEDONORDERBY_ASC_',
  /** sort on CreatedOnOrderBy descending */
  CreatedonorderbyDesc = '_CREATEDONORDERBY_DESC_',
  /** Sort on ItemReorderingPolicy ascending */
  ItemreorderingpolicyAsc = '_ITEMREORDERINGPOLICY_ASC_',
  /** sort on ItemReorderingPolicy descending */
  ItemreorderingpolicyDesc = '_ITEMREORDERINGPOLICY_DESC_',
  /** Sort on ItemReorderingPolicyOPTION ascending */
  ItemreorderingpolicyoptionAsc = '_ITEMREORDERINGPOLICYOPTION_ASC_',
  /** sort on ItemReorderingPolicyOPTION descending */
  ItemreorderingpolicyoptionDesc = '_ITEMREORDERINGPOLICYOPTION_DESC_',
  /** Sort on PeabAccountNo ascending */
  PeabaccountnoAsc = '_PEABACCOUNTNO_ASC_',
  /** sort on PeabAccountNo descending */
  PeabaccountnoDesc = '_PEABACCOUNTNO_DESC_',
  /** Sort on FeeType ascending */
  FeetypeAsc = '_FEETYPE_ASC_',
  /** sort on FeeType descending */
  FeetypeDesc = '_FEETYPE_DESC_',
  /** Sort on FeeTypeOPTION ascending */
  FeetypeoptionAsc = '_FEETYPEOPTION_ASC_',
  /** sort on FeeTypeOPTION descending */
  FeetypeoptionDesc = '_FEETYPEOPTION_DESC_',
  /** Sort on ChargeType ascending */
  ChargetypeAsc = '_CHARGETYPE_ASC_',
  /** sort on ChargeType descending */
  ChargetypeDesc = '_CHARGETYPE_DESC_',
  /** Sort on ChargeTypeOPTION ascending */
  ChargetypeoptionAsc = '_CHARGETYPEOPTION_ASC_',
  /** sort on ChargeTypeOPTION descending */
  ChargetypeoptionDesc = '_CHARGETYPEOPTION_DESC_',
  /** Sort on DepruntilFAPostingDate ascending */
  DepruntilfapostingdateAsc = '_DEPRUNTILFAPOSTINGDATE_ASC_',
  /** sort on DepruntilFAPostingDate descending */
  DepruntilfapostingdateDesc = '_DEPRUNTILFAPOSTINGDATE_DESC_',
  /** Sort on UseDuplicationList ascending */
  UseduplicationlistAsc = '_USEDUPLICATIONLIST_ASC_',
  /** sort on UseDuplicationList descending */
  UseduplicationlistDesc = '_USEDUPLICATIONLIST_DESC_',
  /** Sort on Nonstock ascending */
  NonstockAsc = '_NONSTOCK_ASC_',
  /** sort on Nonstock descending */
  NonstockDesc = '_NONSTOCK_DESC_',
  /** Sort on Correction ascending */
  CorrectionAsc = '_CORRECTION_ASC_',
  /** sort on Correction descending */
  CorrectionDesc = '_CORRECTION_DESC_',
  /** Sort on AllowLineDisc ascending */
  AllowlinediscAsc = '_ALLOWLINEDISC_ASC_',
  /** sort on AllowLineDisc descending */
  AllowlinediscDesc = '_ALLOWLINEDISC_DESC_',
  /** Sort on PlannedDeliveryDate ascending */
  PlanneddeliverydateAsc = '_PLANNEDDELIVERYDATE_ASC_',
  /** sort on PlannedDeliveryDate descending */
  PlanneddeliverydateDesc = '_PLANNEDDELIVERYDATE_DESC_',
  /** Sort on PlannedShipmentDate ascending */
  PlannedshipmentdateAsc = '_PLANNEDSHIPMENTDATE_ASC_',
  /** sort on PlannedShipmentDate descending */
  PlannedshipmentdateDesc = '_PLANNEDSHIPMENTDATE_DESC_',
  /** Sort on CreatedOnOrder ascending */
  CreatedonorderAsc = '_CREATEDONORDER_ASC_',
  /** sort on CreatedOnOrder descending */
  CreatedonorderDesc = '_CREATEDONORDER_DESC_',
  /** Sort on Originaldeliverydate ascending */
  OriginaldeliverydateAsc = '_ORIGINALDELIVERYDATE_ASC_',
  /** sort on Originaldeliverydate descending */
  OriginaldeliverydateDesc = '_ORIGINALDELIVERYDATE_DESC_',
  /** Sort on ApplfromItemEntry ascending */
  ApplfromitementryAsc = '_APPLFROMITEMENTRY_ASC_',
  /** sort on ApplfromItemEntry descending */
  ApplfromitementryDesc = '_APPLFROMITEMENTRY_DESC_',
  /** Sort on ItemChargeBaseAmount ascending */
  ItemchargebaseamountAsc = '_ITEMCHARGEBASEAMOUNT_ASC_',
  /** sort on ItemChargeBaseAmount descending */
  ItemchargebaseamountDesc = '_ITEMCHARGEBASEAMOUNT_DESC_',
  /** Sort on CalculatedPrice ascending */
  CalculatedpriceAsc = '_CALCULATEDPRICE_ASC_',
  /** sort on CalculatedPrice descending */
  CalculatedpriceDesc = '_CALCULATEDPRICE_DESC_',
  /** Sort on DisposableStockBalance ascending */
  DisposablestockbalanceAsc = '_DISPOSABLESTOCKBALANCE_ASC_',
  /** sort on DisposableStockBalance descending */
  DisposablestockbalanceDesc = '_DISPOSABLESTOCKBALANCE_DESC_',
  /** Sort on Currentpricecalculation ascending */
  CurrentpricecalculationAsc = '_CURRENTPRICECALCULATION_ASC_',
  /** sort on Currentpricecalculation descending */
  CurrentpricecalculationDesc = '_CURRENTPRICECALCULATION_DESC_',
  /** Sort on FeeAttachedtoLineNo ascending */
  FeeattachedtolinenoAsc = '_FEEATTACHEDTOLINENO_ASC_',
  /** sort on FeeAttachedtoLineNo descending */
  FeeattachedtolinenoDesc = '_FEEATTACHEDTOLINENO_DESC_',
  /** Sort on CustomerOrderLineNo ascending */
  CustomerorderlinenoAsc = '_CUSTOMERORDERLINENO_ASC_',
  /** sort on CustomerOrderLineNo descending */
  CustomerorderlinenoDesc = '_CUSTOMERORDERLINENO_DESC_',
  /** Sort on CustomerOrderedQuantity ascending */
  CustomerorderedquantityAsc = '_CUSTOMERORDEREDQUANTITY_ASC_',
  /** sort on CustomerOrderedQuantity descending */
  CustomerorderedquantityDesc = '_CUSTOMERORDEREDQUANTITY_DESC_',
  /** Sort on TotalQuantityShipped ascending */
  TotalquantityshippedAsc = '_TOTALQUANTITYSHIPPED_ASC_',
  /** sort on TotalQuantityShipped descending */
  TotalquantityshippedDesc = '_TOTALQUANTITYSHIPPED_DESC_',
  /** Sort on CurrentOutstandingQuantity ascending */
  CurrentoutstandingquantityAsc = '_CURRENTOUTSTANDINGQUANTITY_ASC_',
  /** sort on CurrentOutstandingQuantity descending */
  CurrentoutstandingquantityDesc = '_CURRENTOUTSTANDINGQUANTITY_DESC_',
  /** Sort on TextconnectedtoLineNo ascending */
  TextconnectedtolinenoAsc = '_TEXTCONNECTEDTOLINENO_ASC_',
  /** sort on TextconnectedtoLineNo descending */
  TextconnectedtolinenoDesc = '_TEXTCONNECTEDTOLINENO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSalespersonInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Commission ascending */
  CommissionAsc = '_COMMISSION_ASC_',
  /** sort on Commission descending */
  CommissionDesc = '_COMMISSION_DESC_',
  /** Sort on Image ascending */
  ImageAsc = '_IMAGE_ASC_',
  /** sort on Image descending */
  ImageDesc = '_IMAGE_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on JobTitle ascending */
  JobtitleAsc = '_JOBTITLE_ASC_',
  /** sort on JobTitle descending */
  JobtitleDesc = '_JOBTITLE_DESC_',
  /** Sort on SearchEMail ascending */
  SearchemailAsc = '_SEARCHEMAIL_ASC_',
  /** sort on SearchEMail descending */
  SearchemailDesc = '_SEARCHEMAIL_DESC_',
  /** Sort on EMail2 ascending */
  Email2Asc = '_EMAIL2_ASC_',
  /** sort on EMail2 descending */
  Email2Desc = '_EMAIL2_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on ActionTakenFilter ascending */
  ActiontakenfilterAsc = '_ACTIONTAKENFILTER_ASC_',
  /** sort on ActionTakenFilter descending */
  ActiontakenfilterDesc = '_ACTIONTAKENFILTER_DESC_',
  /** Sort on ActionTakenFilterOPTION ascending */
  ActiontakenfilteroptionAsc = '_ACTIONTAKENFILTEROPTION_ASC_',
  /** sort on ActionTakenFilterOPTION descending */
  ActiontakenfilteroptionDesc = '_ACTIONTAKENFILTEROPTION_DESC_',
  /** Sort on SalesCycleFilter ascending */
  SalescyclefilterAsc = '_SALESCYCLEFILTER_ASC_',
  /** sort on SalesCycleFilter descending */
  SalescyclefilterDesc = '_SALESCYCLEFILTER_DESC_',
  /** Sort on SalesCycleStageFilter ascending */
  SalescyclestagefilterAsc = '_SALESCYCLESTAGEFILTER_ASC_',
  /** sort on SalesCycleStageFilter descending */
  SalescyclestagefilterDesc = '_SALESCYCLESTAGEFILTER_DESC_',
  /** Sort on ProbabilityFilter ascending */
  ProbabilityfilterAsc = '_PROBABILITYFILTER_ASC_',
  /** sort on ProbabilityFilter descending */
  ProbabilityfilterDesc = '_PROBABILITYFILTER_DESC_',
  /** Sort on CompletedFilter ascending */
  CompletedfilterAsc = '_COMPLETEDFILTER_ASC_',
  /** sort on CompletedFilter descending */
  CompletedfilterDesc = '_COMPLETEDFILTER_DESC_',
  /** Sort on ContactFilter ascending */
  ContactfilterAsc = '_CONTACTFILTER_ASC_',
  /** sort on ContactFilter descending */
  ContactfilterDesc = '_CONTACTFILTER_DESC_',
  /** Sort on ContactCompanyFilter ascending */
  ContactcompanyfilterAsc = '_CONTACTCOMPANYFILTER_ASC_',
  /** sort on ContactCompanyFilter descending */
  ContactcompanyfilterDesc = '_CONTACTCOMPANYFILTER_DESC_',
  /** Sort on CampaignFilter ascending */
  CampaignfilterAsc = '_CAMPAIGNFILTER_ASC_',
  /** sort on CampaignFilter descending */
  CampaignfilterDesc = '_CAMPAIGNFILTER_DESC_',
  /** Sort on EstimatedValueFilter ascending */
  EstimatedvaluefilterAsc = '_ESTIMATEDVALUEFILTER_ASC_',
  /** sort on EstimatedValueFilter descending */
  EstimatedvaluefilterDesc = '_ESTIMATEDVALUEFILTER_DESC_',
  /** Sort on CalcdCurrentValueFilter ascending */
  CalcdcurrentvaluefilterAsc = '_CALCDCURRENTVALUEFILTER_ASC_',
  /** sort on CalcdCurrentValueFilter descending */
  CalcdcurrentvaluefilterDesc = '_CALCDCURRENTVALUEFILTER_DESC_',
  /** Sort on ChancesofSuccessFilter ascending */
  ChancesofsuccessfilterAsc = '_CHANCESOFSUCCESSFILTER_ASC_',
  /** sort on ChancesofSuccessFilter descending */
  ChancesofsuccessfilterDesc = '_CHANCESOFSUCCESSFILTER_DESC_',
  /** Sort on TaskStatusFilter ascending */
  TaskstatusfilterAsc = '_TASKSTATUSFILTER_ASC_',
  /** sort on TaskStatusFilter descending */
  TaskstatusfilterDesc = '_TASKSTATUSFILTER_DESC_',
  /** Sort on TaskStatusFilterOPTION ascending */
  TaskstatusfilteroptionAsc = '_TASKSTATUSFILTEROPTION_ASC_',
  /** sort on TaskStatusFilterOPTION descending */
  TaskstatusfilteroptionDesc = '_TASKSTATUSFILTEROPTION_DESC_',
  /** Sort on ClosedTaskFilter ascending */
  ClosedtaskfilterAsc = '_CLOSEDTASKFILTER_ASC_',
  /** sort on ClosedTaskFilter descending */
  ClosedtaskfilterDesc = '_CLOSEDTASKFILTER_DESC_',
  /** Sort on PriorityFilter ascending */
  PriorityfilterAsc = '_PRIORITYFILTER_ASC_',
  /** sort on PriorityFilter descending */
  PriorityfilterDesc = '_PRIORITYFILTER_DESC_',
  /** Sort on PriorityFilterOPTION ascending */
  PriorityfilteroptionAsc = '_PRIORITYFILTEROPTION_ASC_',
  /** sort on PriorityFilterOPTION descending */
  PriorityfilteroptionDesc = '_PRIORITYFILTEROPTION_DESC_',
  /** Sort on TeamFilter ascending */
  TeamfilterAsc = '_TEAMFILTER_ASC_',
  /** sort on TeamFilter descending */
  TeamfilterDesc = '_TEAMFILTER_DESC_',
  /** Sort on CloseOpportunityFilter ascending */
  CloseopportunityfilterAsc = '_CLOSEOPPORTUNITYFILTER_ASC_',
  /** sort on CloseOpportunityFilter descending */
  CloseopportunityfilterDesc = '_CLOSEOPPORTUNITYFILTER_DESC_',
  /** Sort on NextTaskDate ascending */
  NexttaskdateAsc = '_NEXTTASKDATE_ASC_',
  /** sort on NextTaskDate descending */
  NexttaskdateDesc = '_NEXTTASKDATE_DESC_',
  /** Sort on NoofOpportunities ascending */
  NoofopportunitiesAsc = '_NOOFOPPORTUNITIES_ASC_',
  /** sort on NoofOpportunities descending */
  NoofopportunitiesDesc = '_NOOFOPPORTUNITIES_DESC_',
  /** Sort on EstimatedValueLCY ascending */
  EstimatedvaluelcyAsc = '_ESTIMATEDVALUELCY_ASC_',
  /** sort on EstimatedValueLCY descending */
  EstimatedvaluelcyDesc = '_ESTIMATEDVALUELCY_DESC_',
  /** Sort on CalcdCurrentValueLCY ascending */
  CalcdcurrentvaluelcyAsc = '_CALCDCURRENTVALUELCY_ASC_',
  /** sort on CalcdCurrentValueLCY descending */
  CalcdcurrentvaluelcyDesc = '_CALCDCURRENTVALUELCY_DESC_',
  /** Sort on NoofInteractions ascending */
  NoofinteractionsAsc = '_NOOFINTERACTIONS_ASC_',
  /** sort on NoofInteractions descending */
  NoofinteractionsDesc = '_NOOFINTERACTIONS_DESC_',
  /** Sort on CostLCY ascending */
  CostlcyAsc = '_COSTLCY_ASC_',
  /** sort on CostLCY descending */
  CostlcyDesc = '_COSTLCY_DESC_',
  /** Sort on DurationMin ascending */
  DurationminAsc = '_DURATIONMIN_ASC_',
  /** sort on DurationMin descending */
  DurationminDesc = '_DURATIONMIN_DESC_',
  /** Sort on AvgEstimatedValueLCY ascending */
  AvgestimatedvaluelcyAsc = '_AVGESTIMATEDVALUELCY_ASC_',
  /** sort on AvgEstimatedValueLCY descending */
  AvgestimatedvaluelcyDesc = '_AVGESTIMATEDVALUELCY_DESC_',
  /** Sort on AvgCalcdCurrentValueLCY ascending */
  AvgcalcdcurrentvaluelcyAsc = '_AVGCALCDCURRENTVALUELCY_ASC_',
  /** sort on AvgCalcdCurrentValueLCY descending */
  AvgcalcdcurrentvaluelcyDesc = '_AVGCALCDCURRENTVALUELCY_DESC_',
  /** Sort on OpportunityEntryExists ascending */
  OpportunityentryexistsAsc = '_OPPORTUNITYENTRYEXISTS_ASC_',
  /** sort on OpportunityEntryExists descending */
  OpportunityentryexistsDesc = '_OPPORTUNITYENTRYEXISTS_DESC_',
  /** Sort on TaskEntryExists ascending */
  TaskentryexistsAsc = '_TASKENTRYEXISTS_ASC_',
  /** sort on TaskEntryExists descending */
  TaskentryexistsDesc = '_TASKENTRYEXISTS_DESC_',
  /** Sort on EdgeRespCenter ascending */
  EdgerespcenterAsc = '_EDGERESPCENTER_ASC_',
  /** sort on EdgeRespCenter descending */
  EdgerespcenterDesc = '_EDGERESPCENTER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortServiceItemGroupInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on CreateServiceItem ascending */
  CreateserviceitemAsc = '_CREATESERVICEITEM_ASC_',
  /** sort on CreateServiceItem descending */
  CreateserviceitemDesc = '_CREATESERVICEITEM_DESC_',
  /** Sort on DefaultContractDiscountPercent ascending */
  DefaultcontractdiscountpercentAsc = '_DEFAULTCONTRACTDISCOUNTPERCENT_ASC_',
  /** sort on DefaultContractDiscountPercent descending */
  DefaultcontractdiscountpercentDesc = '_DEFAULTCONTRACTDISCOUNTPERCENT_DESC_',
  /** Sort on DefaultServPriceGroupCode ascending */
  DefaultservpricegroupcodeAsc = '_DEFAULTSERVPRICEGROUPCODE_ASC_',
  /** sort on DefaultServPriceGroupCode descending */
  DefaultservpricegroupcodeDesc = '_DEFAULTSERVPRICEGROUPCODE_DESC_',
  /** Sort on DefaultResponseTimeHours ascending */
  DefaultresponsetimehoursAsc = '_DEFAULTRESPONSETIMEHOURS_ASC_',
  /** sort on DefaultResponseTimeHours descending */
  DefaultresponsetimehoursDesc = '_DEFAULTRESPONSETIMEHOURS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortShipmentMethodInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortShippingAgentInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on InternetAddress ascending */
  InternetaddressAsc = '_INTERNETADDRESS_ASC_',
  /** sort on InternetAddress descending */
  InternetaddressDesc = '_INTERNETADDRESS_DESC_',
  /** Sort on AccountNo ascending */
  AccountnoAsc = '_ACCOUNTNO_ASC_',
  /** sort on AccountNo descending */
  AccountnoDesc = '_ACCOUNTNO_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on ShpfyTrackingCompany ascending */
  ShpfytrackingcompanyAsc = '_SHPFYTRACKINGCOMPANY_ASC_',
  /** sort on ShpfyTrackingCompany descending */
  ShpfytrackingcompanyDesc = '_SHPFYTRACKINGCOMPANY_DESC_',
  /** Sort on ShpfyTrackingCompanyOPTION ascending */
  ShpfytrackingcompanyoptionAsc = '_SHPFYTRACKINGCOMPANYOPTION_ASC_',
  /** sort on ShpfyTrackingCompanyOPTION descending */
  ShpfytrackingcompanyoptionDesc = '_SHPFYTRACKINGCOMPANYOPTION_DESC_',
  /** Sort on DocumentType ascending */
  DocumenttypeAsc = '_DOCUMENTTYPE_ASC_',
  /** sort on DocumentType descending */
  DocumenttypeDesc = '_DOCUMENTTYPE_DESC_',
  /** Sort on DocumentTypeOPTION ascending */
  DocumenttypeoptionAsc = '_DOCUMENTTYPEOPTION_ASC_',
  /** sort on DocumentTypeOPTION descending */
  DocumenttypeoptionDesc = '_DOCUMENTTYPEOPTION_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSignRequestInput {
  /** Sort on _initiatingUserId ascending */
  InitiatinguseridAsc = '_INITIATINGUSERID_ASC_',
  /** sort on _initiatingUserId descending */
  InitiatinguseridDesc = '_INITIATINGUSERID_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on DocumentUri ascending */
  DocumenturiAsc = '_DOCUMENTURI_ASC_',
  /** sort on DocumentUri descending */
  DocumenturiDesc = '_DOCUMENTURI_DESC_',
  /** Sort on DocumentName ascending */
  DocumentnameAsc = '_DOCUMENTNAME_ASC_',
  /** sort on DocumentName descending */
  DocumentnameDesc = '_DOCUMENTNAME_DESC_',
  /** Sort on DocumentEtag ascending */
  DocumentetagAsc = '_DOCUMENTETAG_ASC_',
  /** sort on DocumentEtag descending */
  DocumentetagDesc = '_DOCUMENTETAG_DESC_',
  /** Sort on DocumentId ascending */
  DocumentidAsc = '_DOCUMENTID_ASC_',
  /** sort on DocumentId descending */
  DocumentidDesc = '_DOCUMENTID_DESC_',
  /** Sort on SigningUserEmail ascending */
  SigninguseremailAsc = '_SIGNINGUSEREMAIL_ASC_',
  /** sort on SigningUserEmail descending */
  SigninguseremailDesc = '_SIGNINGUSEREMAIL_DESC_',
  /** Sort on SigningUserName ascending */
  SigningusernameAsc = '_SIGNINGUSERNAME_ASC_',
  /** sort on SigningUserName descending */
  SigningusernameDesc = '_SIGNINGUSERNAME_DESC_',
  /** Sort on PrintedName ascending */
  PrintednameAsc = '_PRINTEDNAME_ASC_',
  /** sort on PrintedName descending */
  PrintednameDesc = '_PRINTEDNAME_DESC_',
  /** Sort on SignMethod ascending */
  SignmethodAsc = '_SIGNMETHOD_ASC_',
  /** sort on SignMethod descending */
  SignmethodDesc = '_SIGNMETHOD_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on CreatedOn ascending */
  CreatedonAsc = '_CREATEDON_ASC_',
  /** sort on CreatedOn descending */
  CreatedonDesc = '_CREATEDON_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on TargetId ascending */
  TargetidAsc = '_TARGETID_ASC_',
  /** sort on TargetId descending */
  TargetidDesc = '_TARGETID_DESC_',
  /** Sort on TargetDiscriminator ascending */
  TargetdiscriminatorAsc = '_TARGETDISCRIMINATOR_ASC_',
  /** sort on TargetDiscriminator descending */
  TargetdiscriminatorDesc = '_TARGETDISCRIMINATOR_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on Base64WriteOnly ascending */
  Base64WriteonlyAsc = '_BASE64WRITEONLY_ASC_',
  /** sort on Base64WriteOnly descending */
  Base64WriteonlyDesc = '_BASE64WRITEONLY_DESC_',
  /** Sort on MobileBankIdSSN ascending */
  MobilebankidssnAsc = '_MOBILEBANKIDSSN_ASC_',
  /** sort on MobileBankIdSSN descending */
  MobilebankidssnDesc = '_MOBILEBANKIDSSN_DESC_',
  /** Sort on MobileBankIdUserVisibleData ascending */
  MobilebankiduservisibledataAsc = '_MOBILEBANKIDUSERVISIBLEDATA_ASC_',
  /** sort on MobileBankIdUserVisibleData descending */
  MobilebankiduservisibledataDesc = '_MOBILEBANKIDUSERVISIBLEDATA_DESC_',
  /** Sort on orderRef ascending */
  OrderrefAsc = '_ORDERREF_ASC_',
  /** sort on orderRef descending */
  OrderrefDesc = '_ORDERREF_DESC_',
  /** Sort on autoStartToken ascending */
  AutostarttokenAsc = '_AUTOSTARTTOKEN_ASC_',
  /** sort on autoStartToken descending */
  AutostarttokenDesc = '_AUTOSTARTTOKEN_DESC_',
  /** Sort on hintCode ascending */
  HintcodeAsc = '_HINTCODE_ASC_',
  /** sort on hintCode descending */
  HintcodeDesc = '_HINTCODE_DESC_',
  /** Sort on endUserIp ascending */
  EnduseripAsc = '_ENDUSERIP_ASC_',
  /** sort on endUserIp descending */
  EnduseripDesc = '_ENDUSERIP_DESC_',
  /** Sort on completionData ascending */
  CompletiondataAsc = '_COMPLETIONDATA_ASC_',
  /** sort on completionData descending */
  CompletiondataDesc = '_COMPLETIONDATA_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSignatureInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortSpecialEquipmentInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortStockkeepingUnitInput {
  /** Sort on ItemNo ascending */
  ItemnoAsc = '_ITEMNO_ASC_',
  /** sort on ItemNo descending */
  ItemnoDesc = '_ITEMNO_DESC_',
  /** Sort on VariantCode ascending */
  VariantcodeAsc = '_VARIANTCODE_ASC_',
  /** sort on VariantCode descending */
  VariantcodeDesc = '_VARIANTCODE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Description2 ascending */
  Description2Asc = '_DESCRIPTION2_ASC_',
  /** sort on Description2 descending */
  Description2Desc = '_DESCRIPTION2_DESC_',
  /** Sort on AssemblyBOM ascending */
  AssemblybomAsc = '_ASSEMBLYBOM_ASC_',
  /** sort on AssemblyBOM descending */
  AssemblybomDesc = '_ASSEMBLYBOM_DESC_',
  /** Sort on ShelfNo ascending */
  ShelfnoAsc = '_SHELFNO_ASC_',
  /** sort on ShelfNo descending */
  ShelfnoDesc = '_SHELFNO_DESC_',
  /** Sort on UnitCost ascending */
  UnitcostAsc = '_UNITCOST_ASC_',
  /** sort on UnitCost descending */
  UnitcostDesc = '_UNITCOST_DESC_',
  /** Sort on StandardCost ascending */
  StandardcostAsc = '_STANDARDCOST_ASC_',
  /** sort on StandardCost descending */
  StandardcostDesc = '_STANDARDCOST_DESC_',
  /** Sort on LastDirectCost ascending */
  LastdirectcostAsc = '_LASTDIRECTCOST_ASC_',
  /** sort on LastDirectCost descending */
  LastdirectcostDesc = '_LASTDIRECTCOST_DESC_',
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on VendorItemNo ascending */
  VendoritemnoAsc = '_VENDORITEMNO_ASC_',
  /** sort on VendorItemNo descending */
  VendoritemnoDesc = '_VENDORITEMNO_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on ReorderPoint ascending */
  ReorderpointAsc = '_REORDERPOINT_ASC_',
  /** sort on ReorderPoint descending */
  ReorderpointDesc = '_REORDERPOINT_DESC_',
  /** Sort on MaximumInventory ascending */
  MaximuminventoryAsc = '_MAXIMUMINVENTORY_ASC_',
  /** sort on MaximumInventory descending */
  MaximuminventoryDesc = '_MAXIMUMINVENTORY_DESC_',
  /** Sort on ReorderQuantity ascending */
  ReorderquantityAsc = '_REORDERQUANTITY_ASC_',
  /** sort on ReorderQuantity descending */
  ReorderquantityDesc = '_REORDERQUANTITY_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on Inventory ascending */
  InventoryAsc = '_INVENTORY_ASC_',
  /** sort on Inventory descending */
  InventoryDesc = '_INVENTORY_DESC_',
  /** Sort on QtyonPurchOrder ascending */
  QtyonpurchorderAsc = '_QTYONPURCHORDER_ASC_',
  /** sort on QtyonPurchOrder descending */
  QtyonpurchorderDesc = '_QTYONPURCHORDER_DESC_',
  /** Sort on QtyonSalesOrder ascending */
  QtyonsalesorderAsc = '_QTYONSALESORDER_ASC_',
  /** sort on QtyonSalesOrder descending */
  QtyonsalesorderDesc = '_QTYONSALESORDER_DESC_',
  /** Sort on DropShipmentFilter ascending */
  DropshipmentfilterAsc = '_DROPSHIPMENTFILTER_ASC_',
  /** sort on DropShipmentFilter descending */
  DropshipmentfilterDesc = '_DROPSHIPMENTFILTER_DESC_',
  /** Sort on AssemblyPolicy ascending */
  AssemblypolicyAsc = '_ASSEMBLYPOLICY_ASC_',
  /** sort on AssemblyPolicy descending */
  AssemblypolicyDesc = '_ASSEMBLYPOLICY_DESC_',
  /** Sort on AssemblyPolicyOPTION ascending */
  AssemblypolicyoptionAsc = '_ASSEMBLYPOLICYOPTION_ASC_',
  /** sort on AssemblyPolicyOPTION descending */
  AssemblypolicyoptionDesc = '_ASSEMBLYPOLICYOPTION_DESC_',
  /** Sort on QtyonAssemblyOrder ascending */
  QtyonassemblyorderAsc = '_QTYONASSEMBLYORDER_ASC_',
  /** sort on QtyonAssemblyOrder descending */
  QtyonassemblyorderDesc = '_QTYONASSEMBLYORDER_DESC_',
  /** Sort on QtyonAsmComponent ascending */
  QtyonasmcomponentAsc = '_QTYONASMCOMPONENT_ASC_',
  /** sort on QtyonAsmComponent descending */
  QtyonasmcomponentDesc = '_QTYONASMCOMPONENT_DESC_',
  /** Sort on QtyonJobOrder ascending */
  QtyonjoborderAsc = '_QTYONJOBORDER_ASC_',
  /** sort on QtyonJobOrder descending */
  QtyonjoborderDesc = '_QTYONJOBORDER_DESC_',
  /** Sort on TransferLevelCode ascending */
  TransferlevelcodeAsc = '_TRANSFERLEVELCODE_ASC_',
  /** sort on TransferLevelCode descending */
  TransferlevelcodeDesc = '_TRANSFERLEVELCODE_DESC_',
  /** Sort on LotSize ascending */
  LotsizeAsc = '_LOTSIZE_ASC_',
  /** sort on LotSize descending */
  LotsizeDesc = '_LOTSIZE_DESC_',
  /** Sort on DiscreteOrderQuantity ascending */
  DiscreteorderquantityAsc = '_DISCRETEORDERQUANTITY_ASC_',
  /** sort on DiscreteOrderQuantity descending */
  DiscreteorderquantityDesc = '_DISCRETEORDERQUANTITY_DESC_',
  /** Sort on MinimumOrderQuantity ascending */
  MinimumorderquantityAsc = '_MINIMUMORDERQUANTITY_ASC_',
  /** sort on MinimumOrderQuantity descending */
  MinimumorderquantityDesc = '_MINIMUMORDERQUANTITY_DESC_',
  /** Sort on MaximumOrderQuantity ascending */
  MaximumorderquantityAsc = '_MAXIMUMORDERQUANTITY_ASC_',
  /** sort on MaximumOrderQuantity descending */
  MaximumorderquantityDesc = '_MAXIMUMORDERQUANTITY_DESC_',
  /** Sort on SafetyStockQuantity ascending */
  SafetystockquantityAsc = '_SAFETYSTOCKQUANTITY_ASC_',
  /** sort on SafetyStockQuantity descending */
  SafetystockquantityDesc = '_SAFETYSTOCKQUANTITY_DESC_',
  /** Sort on OrderMultiple ascending */
  OrdermultipleAsc = '_ORDERMULTIPLE_ASC_',
  /** sort on OrderMultiple descending */
  OrdermultipleDesc = '_ORDERMULTIPLE_DESC_',
  /** Sort on SafetyLeadTime ascending */
  SafetyleadtimeAsc = '_SAFETYLEADTIME_ASC_',
  /** sort on SafetyLeadTime descending */
  SafetyleadtimeDesc = '_SAFETYLEADTIME_DESC_',
  /** Sort on ComponentsatLocation ascending */
  ComponentsatlocationAsc = '_COMPONENTSATLOCATION_ASC_',
  /** sort on ComponentsatLocation descending */
  ComponentsatlocationDesc = '_COMPONENTSATLOCATION_DESC_',
  /** Sort on FlushingMethod ascending */
  FlushingmethodAsc = '_FLUSHINGMETHOD_ASC_',
  /** sort on FlushingMethod descending */
  FlushingmethodDesc = '_FLUSHINGMETHOD_DESC_',
  /** Sort on FlushingMethodOPTION ascending */
  FlushingmethodoptionAsc = '_FLUSHINGMETHODOPTION_ASC_',
  /** sort on FlushingMethodOPTION descending */
  FlushingmethodoptionDesc = '_FLUSHINGMETHODOPTION_DESC_',
  /** Sort on ReplenishmentSystem ascending */
  ReplenishmentsystemAsc = '_REPLENISHMENTSYSTEM_ASC_',
  /** sort on ReplenishmentSystem descending */
  ReplenishmentsystemDesc = '_REPLENISHMENTSYSTEM_DESC_',
  /** Sort on ReplenishmentSystemOPTION ascending */
  ReplenishmentsystemoptionAsc = '_REPLENISHMENTSYSTEMOPTION_ASC_',
  /** sort on ReplenishmentSystemOPTION descending */
  ReplenishmentsystemoptionDesc = '_REPLENISHMENTSYSTEMOPTION_DESC_',
  /** Sort on ScheduledReceiptQty ascending */
  ScheduledreceiptqtyAsc = '_SCHEDULEDRECEIPTQTY_ASC_',
  /** sort on ScheduledReceiptQty descending */
  ScheduledreceiptqtyDesc = '_SCHEDULEDRECEIPTQTY_DESC_',
  /** Sort on ScheduledNeedQty ascending */
  ScheduledneedqtyAsc = '_SCHEDULEDNEEDQTY_ASC_',
  /** sort on ScheduledNeedQty descending */
  ScheduledneedqtyDesc = '_SCHEDULEDNEEDQTY_DESC_',
  /** Sort on BinFilter ascending */
  BinfilterAsc = '_BINFILTER_ASC_',
  /** sort on BinFilter descending */
  BinfilterDesc = '_BINFILTER_DESC_',
  /** Sort on TimeBucket ascending */
  TimebucketAsc = '_TIMEBUCKET_ASC_',
  /** sort on TimeBucket descending */
  TimebucketDesc = '_TIMEBUCKET_DESC_',
  /** Sort on ReorderingPolicy ascending */
  ReorderingpolicyAsc = '_REORDERINGPOLICY_ASC_',
  /** sort on ReorderingPolicy descending */
  ReorderingpolicyDesc = '_REORDERINGPOLICY_DESC_',
  /** Sort on ReorderingPolicyOPTION ascending */
  ReorderingpolicyoptionAsc = '_REORDERINGPOLICYOPTION_ASC_',
  /** sort on ReorderingPolicyOPTION descending */
  ReorderingpolicyoptionDesc = '_REORDERINGPOLICYOPTION_DESC_',
  /** Sort on IncludeInventory ascending */
  IncludeinventoryAsc = '_INCLUDEINVENTORY_ASC_',
  /** sort on IncludeInventory descending */
  IncludeinventoryDesc = '_INCLUDEINVENTORY_DESC_',
  /** Sort on ManufacturingPolicy ascending */
  ManufacturingpolicyAsc = '_MANUFACTURINGPOLICY_ASC_',
  /** sort on ManufacturingPolicy descending */
  ManufacturingpolicyDesc = '_MANUFACTURINGPOLICY_DESC_',
  /** Sort on ManufacturingPolicyOPTION ascending */
  ManufacturingpolicyoptionAsc = '_MANUFACTURINGPOLICYOPTION_ASC_',
  /** sort on ManufacturingPolicyOPTION descending */
  ManufacturingpolicyoptionDesc = '_MANUFACTURINGPOLICYOPTION_DESC_',
  /** Sort on ReschedulingPeriod ascending */
  ReschedulingperiodAsc = '_RESCHEDULINGPERIOD_ASC_',
  /** sort on ReschedulingPeriod descending */
  ReschedulingperiodDesc = '_RESCHEDULINGPERIOD_DESC_',
  /** Sort on LotAccumulationPeriod ascending */
  LotaccumulationperiodAsc = '_LOTACCUMULATIONPERIOD_ASC_',
  /** sort on LotAccumulationPeriod descending */
  LotaccumulationperiodDesc = '_LOTACCUMULATIONPERIOD_DESC_',
  /** Sort on DampenerPeriod ascending */
  DampenerperiodAsc = '_DAMPENERPERIOD_ASC_',
  /** sort on DampenerPeriod descending */
  DampenerperiodDesc = '_DAMPENERPERIOD_DESC_',
  /** Sort on DampenerQuantity ascending */
  DampenerquantityAsc = '_DAMPENERQUANTITY_ASC_',
  /** sort on DampenerQuantity descending */
  DampenerquantityDesc = '_DAMPENERQUANTITY_DESC_',
  /** Sort on OverflowLevel ascending */
  OverflowlevelAsc = '_OVERFLOWLEVEL_ASC_',
  /** sort on OverflowLevel descending */
  OverflowlevelDesc = '_OVERFLOWLEVEL_DESC_',
  /** Sort on TransferfromCode ascending */
  TransferfromcodeAsc = '_TRANSFERFROMCODE_ASC_',
  /** sort on TransferfromCode descending */
  TransferfromcodeDesc = '_TRANSFERFROMCODE_DESC_',
  /** Sort on QtyinTransit ascending */
  QtyintransitAsc = '_QTYINTRANSIT_ASC_',
  /** sort on QtyinTransit descending */
  QtyintransitDesc = '_QTYINTRANSIT_DESC_',
  /** Sort on TransOrdReceiptQty ascending */
  TransordreceiptqtyAsc = '_TRANSORDRECEIPTQTY_ASC_',
  /** sort on TransOrdReceiptQty descending */
  TransordreceiptqtyDesc = '_TRANSORDRECEIPTQTY_DESC_',
  /** Sort on TransOrdShipmentQty ascending */
  TransordshipmentqtyAsc = '_TRANSORDSHIPMENTQTY_ASC_',
  /** sort on TransOrdShipmentQty descending */
  TransordshipmentqtyDesc = '_TRANSORDSHIPMENTQTY_DESC_',
  /** Sort on QtyonServiceOrder ascending */
  QtyonserviceorderAsc = '_QTYONSERVICEORDER_ASC_',
  /** sort on QtyonServiceOrder descending */
  QtyonserviceorderDesc = '_QTYONSERVICEORDER_DESC_',
  /** Sort on SpecialEquipmentCode ascending */
  SpecialequipmentcodeAsc = '_SPECIALEQUIPMENTCODE_ASC_',
  /** sort on SpecialEquipmentCode descending */
  SpecialequipmentcodeDesc = '_SPECIALEQUIPMENTCODE_DESC_',
  /** Sort on PutawayTemplateCode ascending */
  PutawaytemplatecodeAsc = '_PUTAWAYTEMPLATECODE_ASC_',
  /** sort on PutawayTemplateCode descending */
  PutawaytemplatecodeDesc = '_PUTAWAYTEMPLATECODE_DESC_',
  /** Sort on PutawayUnitofMeasureCode ascending */
  PutawayunitofmeasurecodeAsc = '_PUTAWAYUNITOFMEASURECODE_ASC_',
  /** sort on PutawayUnitofMeasureCode descending */
  PutawayunitofmeasurecodeDesc = '_PUTAWAYUNITOFMEASURECODE_DESC_',
  /** Sort on PhysInvtCountingPeriodCode ascending */
  PhysinvtcountingperiodcodeAsc = '_PHYSINVTCOUNTINGPERIODCODE_ASC_',
  /** sort on PhysInvtCountingPeriodCode descending */
  PhysinvtcountingperiodcodeDesc = '_PHYSINVTCOUNTINGPERIODCODE_DESC_',
  /** Sort on LastCountingPeriodUpdate ascending */
  LastcountingperiodupdateAsc = '_LASTCOUNTINGPERIODUPDATE_ASC_',
  /** sort on LastCountingPeriodUpdate descending */
  LastcountingperiodupdateDesc = '_LASTCOUNTINGPERIODUPDATE_DESC_',
  /** Sort on LastPhysInvtDate ascending */
  LastphysinvtdateAsc = '_LASTPHYSINVTDATE_ASC_',
  /** sort on LastPhysInvtDate descending */
  LastphysinvtdateDesc = '_LASTPHYSINVTDATE_DESC_',
  /** Sort on UseCrossDocking ascending */
  UsecrossdockingAsc = '_USECROSSDOCKING_ASC_',
  /** sort on UseCrossDocking descending */
  UsecrossdockingDesc = '_USECROSSDOCKING_DESC_',
  /** Sort on NextCountingStartDate ascending */
  NextcountingstartdateAsc = '_NEXTCOUNTINGSTARTDATE_ASC_',
  /** sort on NextCountingStartDate descending */
  NextcountingstartdateDesc = '_NEXTCOUNTINGSTARTDATE_DESC_',
  /** Sort on NextCountingEndDate ascending */
  NextcountingenddateAsc = '_NEXTCOUNTINGENDDATE_ASC_',
  /** sort on NextCountingEndDate descending */
  NextcountingenddateDesc = '_NEXTCOUNTINGENDDATE_DESC_',
  /** Sort on RoutingNo ascending */
  RoutingnoAsc = '_ROUTINGNO_ASC_',
  /** sort on RoutingNo descending */
  RoutingnoDesc = '_ROUTINGNO_DESC_',
  /** Sort on ProductionBOMNo ascending */
  ProductionbomnoAsc = '_PRODUCTIONBOMNO_ASC_',
  /** sort on ProductionBOMNo descending */
  ProductionbomnoDesc = '_PRODUCTIONBOMNO_DESC_',
  /** Sort on PlannedOrderReceiptQty ascending */
  PlannedorderreceiptqtyAsc = '_PLANNEDORDERRECEIPTQTY_ASC_',
  /** sort on PlannedOrderReceiptQty descending */
  PlannedorderreceiptqtyDesc = '_PLANNEDORDERRECEIPTQTY_DESC_',
  /** Sort on FPOrderReceiptQty ascending */
  FporderreceiptqtyAsc = '_FPORDERRECEIPTQTY_ASC_',
  /** sort on FPOrderReceiptQty descending */
  FporderreceiptqtyDesc = '_FPORDERRECEIPTQTY_DESC_',
  /** Sort on RelOrderReceiptQty ascending */
  RelorderreceiptqtyAsc = '_RELORDERRECEIPTQTY_ASC_',
  /** sort on RelOrderReceiptQty descending */
  RelorderreceiptqtyDesc = '_RELORDERRECEIPTQTY_DESC_',
  /** Sort on PlannedOrderReleaseQty ascending */
  PlannedorderreleaseqtyAsc = '_PLANNEDORDERRELEASEQTY_ASC_',
  /** sort on PlannedOrderReleaseQty descending */
  PlannedorderreleaseqtyDesc = '_PLANNEDORDERRELEASEQTY_DESC_',
  /** Sort on PurchReqReceiptQty ascending */
  PurchreqreceiptqtyAsc = '_PURCHREQRECEIPTQTY_ASC_',
  /** sort on PurchReqReceiptQty descending */
  PurchreqreceiptqtyDesc = '_PURCHREQRECEIPTQTY_DESC_',
  /** Sort on PurchReqReleaseQty ascending */
  PurchreqreleaseqtyAsc = '_PURCHREQRELEASEQTY_ASC_',
  /** sort on PurchReqReleaseQty descending */
  PurchreqreleaseqtyDesc = '_PURCHREQRELEASEQTY_DESC_',
  /** Sort on QtyonProdOrder ascending */
  QtyonprodorderAsc = '_QTYONPRODORDER_ASC_',
  /** sort on QtyonProdOrder descending */
  QtyonprodorderDesc = '_QTYONPRODORDER_DESC_',
  /** Sort on QtyonComponentLines ascending */
  QtyoncomponentlinesAsc = '_QTYONCOMPONENTLINES_ASC_',
  /** sort on QtyonComponentLines descending */
  QtyoncomponentlinesDesc = '_QTYONCOMPONENTLINES_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTariffNumberInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on SupplementaryUnits ascending */
  SupplementaryunitsAsc = '_SUPPLEMENTARYUNITS_ASC_',
  /** sort on SupplementaryUnits descending */
  SupplementaryunitsDesc = '_SUPPLEMENTARYUNITS_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTaxGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTimeRegistrationInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on TimeSheetNo ascending */
  TimesheetnoAsc = '_TIMESHEETNO_ASC_',
  /** sort on TimeSheetNo descending */
  TimesheetnoDesc = '_TIMESHEETNO_DESC_',
  /** Sort on TimeSheetLineLineNo ascending */
  TimesheetlinelinenoAsc = '_TIMESHEETLINELINENO_ASC_',
  /** sort on TimeSheetLineLineNo descending */
  TimesheetlinelinenoDesc = '_TIMESHEETLINELINENO_DESC_',
  /** Sort on TimeSheetDetailLineNo ascending */
  TimesheetdetaillinenoAsc = '_TIMESHEETDETAILLINENO_ASC_',
  /** sort on TimeSheetDetailLineNo descending */
  TimesheetdetaillinenoDesc = '_TIMESHEETDETAILLINENO_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on CauseofAbsenceCode ascending */
  CauseofabsencecodeAsc = '_CAUSEOFABSENCECODE_ASC_',
  /** sort on CauseofAbsenceCode descending */
  CauseofabsencecodeDesc = '_CAUSEOFABSENCECODE_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on Hours ascending */
  HoursAsc = '_HOURS_ASC_',
  /** sort on Hours descending */
  HoursDesc = '_HOURS_DESC_',
  /** Sort on Email ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on Email descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on Resource ascending */
  ResourceAsc = '_RESOURCE_ASC_',
  /** sort on Resource descending */
  ResourceDesc = '_RESOURCE_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on StartDateFilter ascending */
  StartdatefilterAsc = '_STARTDATEFILTER_ASC_',
  /** sort on StartDateFilter descending */
  StartdatefilterDesc = '_STARTDATEFILTER_DESC_',
  /** Sort on EndDateFilter ascending */
  EnddatefilterAsc = '_ENDDATEFILTER_ASC_',
  /** sort on EndDateFilter descending */
  EnddatefilterDesc = '_ENDDATEFILTER_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTimeSheetDetailInput {
  /** Sort on TimeSheetNo ascending */
  TimesheetnoAsc = '_TIMESHEETNO_ASC_',
  /** sort on TimeSheetNo descending */
  TimesheetnoDesc = '_TIMESHEETNO_DESC_',
  /** Sort on TimeSheetLineNo ascending */
  TimesheetlinenoAsc = '_TIMESHEETLINENO_ASC_',
  /** sort on TimeSheetLineNo descending */
  TimesheetlinenoDesc = '_TIMESHEETLINENO_DESC_',
  /** Sort on Date ascending */
  DateAsc = '_DATE_ASC_',
  /** sort on Date descending */
  DateDesc = '_DATE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on ResourceNo ascending */
  ResourcenoAsc = '_RESOURCENO_ASC_',
  /** sort on ResourceNo descending */
  ResourcenoDesc = '_RESOURCENO_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on CauseofAbsenceCode ascending */
  CauseofabsencecodeAsc = '_CAUSEOFABSENCECODE_ASC_',
  /** sort on CauseofAbsenceCode descending */
  CauseofabsencecodeDesc = '_CAUSEOFABSENCECODE_DESC_',
  /** Sort on ServiceOrderNo ascending */
  ServiceordernoAsc = '_SERVICEORDERNO_ASC_',
  /** sort on ServiceOrderNo descending */
  ServiceordernoDesc = '_SERVICEORDERNO_DESC_',
  /** Sort on ServiceOrderLineNo ascending */
  ServiceorderlinenoAsc = '_SERVICEORDERLINENO_ASC_',
  /** sort on ServiceOrderLineNo descending */
  ServiceorderlinenoDesc = '_SERVICEORDERLINENO_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on PostedQuantity ascending */
  PostedquantityAsc = '_POSTEDQUANTITY_ASC_',
  /** sort on PostedQuantity descending */
  PostedquantityDesc = '_POSTEDQUANTITY_DESC_',
  /** Sort on AssemblyOrderNo ascending */
  AssemblyordernoAsc = '_ASSEMBLYORDERNO_ASC_',
  /** sort on AssemblyOrderNo descending */
  AssemblyordernoDesc = '_ASSEMBLYORDERNO_DESC_',
  /** Sort on AssemblyOrderLineNo ascending */
  AssemblyorderlinenoAsc = '_ASSEMBLYORDERLINENO_ASC_',
  /** sort on AssemblyOrderLineNo descending */
  AssemblyorderlinenoDesc = '_ASSEMBLYORDERLINENO_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on Posted ascending */
  PostedAsc = '_POSTED_ASC_',
  /** sort on Posted descending */
  PostedDesc = '_POSTED_DESC_',
  /** Sort on ExcludeFromTotals ascending */
  ExcludefromtotalsAsc = '_EXCLUDEFROMTOTALS_ASC_',
  /** sort on ExcludeFromTotals descending */
  ExcludefromtotalsDesc = '_EXCLUDEFROMTOTALS_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on NotChargeable ascending */
  NotchargeableAsc = '_NOTCHARGEABLE_ASC_',
  /** sort on NotChargeable descending */
  NotchargeableDesc = '_NOTCHARGEABLE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTimeSheetLineInput {
  /** Sort on TimeSheetNo ascending */
  TimesheetnoAsc = '_TIMESHEETNO_ASC_',
  /** sort on TimeSheetNo descending */
  TimesheetnoDesc = '_TIMESHEETNO_DESC_',
  /** Sort on LineNo ascending */
  LinenoAsc = '_LINENO_ASC_',
  /** sort on LineNo descending */
  LinenoDesc = '_LINENO_DESC_',
  /** Sort on TimeSheetStartingDate ascending */
  TimesheetstartingdateAsc = '_TIMESHEETSTARTINGDATE_ASC_',
  /** sort on TimeSheetStartingDate descending */
  TimesheetstartingdateDesc = '_TIMESHEETSTARTINGDATE_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on JobNo ascending */
  JobnoAsc = '_JOBNO_ASC_',
  /** sort on JobNo descending */
  JobnoDesc = '_JOBNO_DESC_',
  /** Sort on JobTaskNo ascending */
  JobtasknoAsc = '_JOBTASKNO_ASC_',
  /** sort on JobTaskNo descending */
  JobtasknoDesc = '_JOBTASKNO_DESC_',
  /** Sort on CauseofAbsenceCode ascending */
  CauseofabsencecodeAsc = '_CAUSEOFABSENCECODE_ASC_',
  /** sort on CauseofAbsenceCode descending */
  CauseofabsencecodeDesc = '_CAUSEOFABSENCECODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on WorkTypeCode ascending */
  WorktypecodeAsc = '_WORKTYPECODE_ASC_',
  /** sort on WorkTypeCode descending */
  WorktypecodeDesc = '_WORKTYPECODE_DESC_',
  /** Sort on ApproverID ascending */
  ApproveridAsc = '_APPROVERID_ASC_',
  /** sort on ApproverID descending */
  ApproveridDesc = '_APPROVERID_DESC_',
  /** Sort on ServiceOrderNo ascending */
  ServiceordernoAsc = '_SERVICEORDERNO_ASC_',
  /** sort on ServiceOrderNo descending */
  ServiceordernoDesc = '_SERVICEORDERNO_DESC_',
  /** Sort on ServiceOrderLineNo ascending */
  ServiceorderlinenoAsc = '_SERVICEORDERLINENO_ASC_',
  /** sort on ServiceOrderLineNo descending */
  ServiceorderlinenoDesc = '_SERVICEORDERLINENO_DESC_',
  /** Sort on Chargeable ascending */
  ChargeableAsc = '_CHARGEABLE_ASC_',
  /** sort on Chargeable descending */
  ChargeableDesc = '_CHARGEABLE_DESC_',
  /** Sort on AssemblyOrderNo ascending */
  AssemblyordernoAsc = '_ASSEMBLYORDERNO_ASC_',
  /** sort on AssemblyOrderNo descending */
  AssemblyordernoDesc = '_ASSEMBLYORDERNO_DESC_',
  /** Sort on AssemblyOrderLineNo ascending */
  AssemblyorderlinenoAsc = '_ASSEMBLYORDERLINENO_ASC_',
  /** sort on AssemblyOrderLineNo descending */
  AssemblyorderlinenoDesc = '_ASSEMBLYORDERLINENO_DESC_',
  /** Sort on Status ascending */
  StatusAsc = '_STATUS_ASC_',
  /** sort on Status descending */
  StatusDesc = '_STATUS_DESC_',
  /** Sort on StatusOPTION ascending */
  StatusoptionAsc = '_STATUSOPTION_ASC_',
  /** sort on StatusOPTION descending */
  StatusoptionDesc = '_STATUSOPTION_DESC_',
  /** Sort on ApprovedBy ascending */
  ApprovedbyAsc = '_APPROVEDBY_ASC_',
  /** sort on ApprovedBy descending */
  ApprovedbyDesc = '_APPROVEDBY_DESC_',
  /** Sort on ApprovalDate ascending */
  ApprovaldateAsc = '_APPROVALDATE_ASC_',
  /** sort on ApprovalDate descending */
  ApprovaldateDesc = '_APPROVALDATE_DESC_',
  /** Sort on Posted ascending */
  PostedAsc = '_POSTED_ASC_',
  /** sort on Posted descending */
  PostedDesc = '_POSTED_DESC_',
  /** Sort on UnitPrice ascending */
  UnitpriceAsc = '_UNITPRICE_ASC_',
  /** sort on UnitPrice descending */
  UnitpriceDesc = '_UNITPRICE_DESC_',
  /** Sort on NotChargeable ascending */
  NotchargeableAsc = '_NOTCHARGEABLE_ASC_',
  /** sort on NotChargeable descending */
  NotchargeableDesc = '_NOTCHARGEABLE_DESC_',
  /** Sort on TotalQuantity ascending */
  TotalquantityAsc = '_TOTALQUANTITY_ASC_',
  /** sort on TotalQuantity descending */
  TotalquantityDesc = '_TOTALQUANTITY_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTransactionSpecificationInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Text ascending */
  TextAsc = '_TEXT_ASC_',
  /** sort on Text descending */
  TextDesc = '_TEXT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTransactionTypeInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortTransportMethodInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortUnitOfMeasureInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on InternationalStandardCode ascending */
  InternationalstandardcodeAsc = '_INTERNATIONALSTANDARDCODE_ASC_',
  /** sort on InternationalStandardCode descending */
  InternationalstandardcodeDesc = '_INTERNATIONALSTANDARDCODE_DESC_',
  /** Sort on Symbol ascending */
  SymbolAsc = '_SYMBOL_ASC_',
  /** sort on Symbol descending */
  SymbolDesc = '_SYMBOL_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on CoupledtoCRM ascending */
  CoupledtocrmAsc = '_COUPLEDTOCRM_ASC_',
  /** sort on CoupledtoCRM descending */
  CoupledtocrmDesc = '_COUPLEDTOCRM_DESC_',
  /** Sort on ExternalCode ascending */
  ExternalcodeAsc = '_EXTERNALCODE_ASC_',
  /** sort on ExternalCode descending */
  ExternalcodeDesc = '_EXTERNALCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortUserFavoritesInput {
  /** Sort on userEmail ascending */
  UseremailAsc = '_USEREMAIL_ASC_',
  /** sort on userEmail descending */
  UseremailDesc = '_USEREMAIL_DESC_',
  /** Sort on userGlobalId ascending */
  UserglobalidAsc = '_USERGLOBALID_ASC_',
  /** sort on userGlobalId descending */
  UserglobalidDesc = '_USERGLOBALID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortUserInput {
  /** Sort on name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on email ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on email descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on active ascending */
  ActiveAsc = '_ACTIVE_ASC_',
  /** sort on active descending */
  ActiveDesc = '_ACTIVE_DESC_',
  /** Sort on Dyn365Id ascending */
  Dyn365IdAsc = '_DYN365ID_ASC_',
  /** sort on Dyn365Id descending */
  Dyn365IdDesc = '_DYN365ID_DESC_',
  /** Sort on DynNAVId ascending */
  DynnavidAsc = '_DYNNAVID_ASC_',
  /** sort on DynNAVId descending */
  DynnavidDesc = '_DYNNAVID_DESC_',
  /** Sort on role ascending */
  RoleAsc = '_ROLE_ASC_',
  /** sort on role descending */
  RoleDesc = '_ROLE_DESC_',
  /** Sort on VerifiedIdentity ascending */
  VerifiedidentityAsc = '_VERIFIEDIDENTITY_ASC_',
  /** sort on VerifiedIdentity descending */
  VerifiedidentityDesc = '_VERIFIEDIDENTITY_DESC_',
  /** Sort on IsDemo ascending */
  IsdemoAsc = '_ISDEMO_ASC_',
  /** sort on IsDemo descending */
  IsdemoDesc = '_ISDEMO_DESC_',
  /** Sort on EmailOnExpiredObjects ascending */
  EmailonexpiredobjectsAsc = '_EMAILONEXPIREDOBJECTS_ASC_',
  /** sort on EmailOnExpiredObjects descending */
  EmailonexpiredobjectsDesc = '_EMAILONEXPIREDOBJECTS_DESC_',
  /** Sort on PushNotificationOnShipments ascending */
  PushnotificationonshipmentsAsc = '_PUSHNOTIFICATIONONSHIPMENTS_ASC_',
  /** sort on PushNotificationOnShipments descending */
  PushnotificationonshipmentsDesc = '_PUSHNOTIFICATIONONSHIPMENTS_DESC_',
  /** Sort on IdentityVerifiedByUser ascending */
  IdentityverifiedbyuserAsc = '_IDENTITYVERIFIEDBYUSER_ASC_',
  /** sort on IdentityVerifiedByUser descending */
  IdentityverifiedbyuserDesc = '_IDENTITYVERIFIEDBYUSER_DESC_',
  /** Sort on PendingAccessRequest ascending */
  PendingaccessrequestAsc = '_PENDINGACCESSREQUEST_ASC_',
  /** sort on PendingAccessRequest descending */
  PendingaccessrequestDesc = '_PENDINGACCESSREQUEST_DESC_',
  /** Sort on CustomerName ascending */
  CustomernameAsc = '_CUSTOMERNAME_ASC_',
  /** sort on CustomerName descending */
  CustomernameDesc = '_CUSTOMERNAME_DESC_',
  /** Sort on DefaultSignature ascending */
  DefaultsignatureAsc = '_DEFAULTSIGNATURE_ASC_',
  /** sort on DefaultSignature descending */
  DefaultsignatureDesc = '_DEFAULTSIGNATURE_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on NavUserName ascending */
  NavusernameAsc = '_NAVUSERNAME_ASC_',
  /** sort on NavUserName descending */
  NavusernameDesc = '_NAVUSERNAME_DESC_',
  /** Sort on NavApiUserNameId ascending */
  NavapiusernameidAsc = '_NAVAPIUSERNAMEID_ASC_',
  /** sort on NavApiUserNameId descending */
  NavapiusernameidDesc = '_NAVAPIUSERNAMEID_DESC_',
  /** Sort on NavApiPasswordId ascending */
  NavapipasswordidAsc = '_NAVAPIPASSWORDID_ASC_',
  /** sort on NavApiPasswordId descending */
  NavapipasswordidDesc = '_NAVAPIPASSWORDID_DESC_',
  /** Sort on UserInvitationId ascending */
  UserinvitationidAsc = '_USERINVITATIONID_ASC_',
  /** sort on UserInvitationId descending */
  UserinvitationidDesc = '_USERINVITATIONID_DESC_',
  /** Sort on UserAcceptedInvitationOn ascending */
  UseracceptedinvitationonAsc = '_USERACCEPTEDINVITATIONON_ASC_',
  /** sort on UserAcceptedInvitationOn descending */
  UseracceptedinvitationonDesc = '_USERACCEPTEDINVITATIONON_DESC_',
  /** Sort on ImpersonateUserId ascending */
  ImpersonateuseridAsc = '_IMPERSONATEUSERID_ASC_',
  /** sort on ImpersonateUserId descending */
  ImpersonateuseridDesc = '_IMPERSONATEUSERID_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortVatBusinessPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortVatProductPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortVendorBankAccountInput {
  /** Sort on VendorNo ascending */
  VendornoAsc = '_VENDORNO_ASC_',
  /** sort on VendorNo descending */
  VendornoDesc = '_VENDORNO_DESC_',
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on TelexNo ascending */
  TelexnoAsc = '_TELEXNO_ASC_',
  /** sort on TelexNo descending */
  TelexnoDesc = '_TELEXNO_DESC_',
  /** Sort on BankBranchNo ascending */
  BankbranchnoAsc = '_BANKBRANCHNO_ASC_',
  /** sort on BankBranchNo descending */
  BankbranchnoDesc = '_BANKBRANCHNO_DESC_',
  /** Sort on BankAccountNo ascending */
  BankaccountnoAsc = '_BANKACCOUNTNO_ASC_',
  /** sort on BankAccountNo descending */
  BankaccountnoDesc = '_BANKACCOUNTNO_DESC_',
  /** Sort on TransitNo ascending */
  TransitnoAsc = '_TRANSITNO_ASC_',
  /** sort on TransitNo descending */
  TransitnoDesc = '_TRANSITNO_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on TelexAnswerBack ascending */
  TelexanswerbackAsc = '_TELEXANSWERBACK_ASC_',
  /** sort on TelexAnswerBack descending */
  TelexanswerbackDesc = '_TELEXANSWERBACK_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on IBAN ascending */
  IbanAsc = '_IBAN_ASC_',
  /** sort on IBAN descending */
  IbanDesc = '_IBAN_DESC_',
  /** Sort on SWIFTCode ascending */
  SwiftcodeAsc = '_SWIFTCODE_ASC_',
  /** sort on SWIFTCode descending */
  SwiftcodeDesc = '_SWIFTCODE_DESC_',
  /** Sort on BankClearingCode ascending */
  BankclearingcodeAsc = '_BANKCLEARINGCODE_ASC_',
  /** sort on BankClearingCode descending */
  BankclearingcodeDesc = '_BANKCLEARINGCODE_DESC_',
  /** Sort on BankClearingStandard ascending */
  BankclearingstandardAsc = '_BANKCLEARINGSTANDARD_ASC_',
  /** sort on BankClearingStandard descending */
  BankclearingstandardDesc = '_BANKCLEARINGSTANDARD_DESC_',
  /** Sort on PEBPlusGiroNo ascending */
  PebplusgironoAsc = '_PEBPLUSGIRONO_ASC_',
  /** sort on PEBPlusGiroNo descending */
  PebplusgironoDesc = '_PEBPLUSGIRONO_DESC_',
  /** Sort on PEBPaymentMethod ascending */
  PebpaymentmethodAsc = '_PEBPAYMENTMETHOD_ASC_',
  /** sort on PEBPaymentMethod descending */
  PebpaymentmethodDesc = '_PEBPAYMENTMETHOD_DESC_',
  /** Sort on PEBPaymentMethodOPTION ascending */
  PebpaymentmethodoptionAsc = '_PEBPAYMENTMETHODOPTION_ASC_',
  /** sort on PEBPaymentMethodOPTION descending */
  PebpaymentmethodoptionDesc = '_PEBPAYMENTMETHODOPTION_DESC_',
  /** Sort on PEBGiroTypeCode ascending */
  PebgirotypecodeAsc = '_PEBGIROTYPECODE_ASC_',
  /** sort on PEBGiroTypeCode descending */
  PebgirotypecodeDesc = '_PEBGIROTYPECODE_DESC_',
  /** Sort on PEBBankGiroNo ascending */
  PebbankgironoAsc = '_PEBBANKGIRONO_ASC_',
  /** sort on PEBBankGiroNo descending */
  PebbankgironoDesc = '_PEBBANKGIRONO_DESC_',
  /** Sort on PEBCodeforBankofSweden ascending */
  PebcodeforbankofswedenAsc = '_PEBCODEFORBANKOFSWEDEN_ASC_',
  /** sort on PEBCodeforBankofSweden descending */
  PebcodeforbankofswedenDesc = '_PEBCODEFORBANKOFSWEDEN_DESC_',
  /** Sort on PEBAccountPaymentwithAdvis ascending */
  PebaccountpaymentwithadvisAsc = '_PEBACCOUNTPAYMENTWITHADVIS_ASC_',
  /** sort on PEBAccountPaymentwithAdvis descending */
  PebaccountpaymentwithadvisDesc = '_PEBACCOUNTPAYMENTWITHADVIS_DESC_',
  /** Sort on PEBChargePaidby ascending */
  PebchargepaidbyAsc = '_PEBCHARGEPAIDBY_ASC_',
  /** sort on PEBChargePaidby descending */
  PebchargepaidbyDesc = '_PEBCHARGEPAIDBY_DESC_',
  /** Sort on PEBChargePaidbyOPTION ascending */
  PebchargepaidbyoptionAsc = '_PEBCHARGEPAIDBYOPTION_ASC_',
  /** sort on PEBChargePaidbyOPTION descending */
  PebchargepaidbyoptionDesc = '_PEBCHARGEPAIDBYOPTION_DESC_',
  /** Sort on PEBCategoryPurpose ascending */
  PebcategorypurposeAsc = '_PEBCATEGORYPURPOSE_ASC_',
  /** sort on PEBCategoryPurpose descending */
  PebcategorypurposeDesc = '_PEBCATEGORYPURPOSE_DESC_',
  /** Sort on PEBCategoryPurposeOPTION ascending */
  PebcategorypurposeoptionAsc = '_PEBCATEGORYPURPOSEOPTION_ASC_',
  /** sort on PEBCategoryPurposeOPTION descending */
  PebcategorypurposeoptionDesc = '_PEBCATEGORYPURPOSEOPTION_DESC_',
  /** Sort on PEBStatus ascending */
  PebstatusAsc = '_PEBSTATUS_ASC_',
  /** sort on PEBStatus descending */
  PebstatusDesc = '_PEBSTATUS_DESC_',
  /** Sort on PEBStatusOPTION ascending */
  PebstatusoptionAsc = '_PEBSTATUSOPTION_ASC_',
  /** sort on PEBStatusOPTION descending */
  PebstatusoptionDesc = '_PEBSTATUSOPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortVendorInput {
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Name ascending */
  NameAsc = '_NAME_ASC_',
  /** sort on Name descending */
  NameDesc = '_NAME_DESC_',
  /** Sort on SearchName ascending */
  SearchnameAsc = '_SEARCHNAME_ASC_',
  /** sort on SearchName descending */
  SearchnameDesc = '_SEARCHNAME_DESC_',
  /** Sort on Name2 ascending */
  Name2Asc = '_NAME2_ASC_',
  /** sort on Name2 descending */
  Name2Desc = '_NAME2_DESC_',
  /** Sort on Address ascending */
  AddressAsc = '_ADDRESS_ASC_',
  /** sort on Address descending */
  AddressDesc = '_ADDRESS_DESC_',
  /** Sort on Address2 ascending */
  Address2Asc = '_ADDRESS2_ASC_',
  /** sort on Address2 descending */
  Address2Desc = '_ADDRESS2_DESC_',
  /** Sort on City ascending */
  CityAsc = '_CITY_ASC_',
  /** sort on City descending */
  CityDesc = '_CITY_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on PhoneNo ascending */
  PhonenoAsc = '_PHONENO_ASC_',
  /** sort on PhoneNo descending */
  PhonenoDesc = '_PHONENO_DESC_',
  /** Sort on TelexNo ascending */
  TelexnoAsc = '_TELEXNO_ASC_',
  /** sort on TelexNo descending */
  TelexnoDesc = '_TELEXNO_DESC_',
  /** Sort on OurAccountNo ascending */
  OuraccountnoAsc = '_OURACCOUNTNO_ASC_',
  /** sort on OurAccountNo descending */
  OuraccountnoDesc = '_OURACCOUNTNO_DESC_',
  /** Sort on TerritoryCode ascending */
  TerritorycodeAsc = '_TERRITORYCODE_ASC_',
  /** sort on TerritoryCode descending */
  TerritorycodeDesc = '_TERRITORYCODE_DESC_',
  /** Sort on GlobalDimension1Code ascending */
  Globaldimension1CodeAsc = '_GLOBALDIMENSION1CODE_ASC_',
  /** sort on GlobalDimension1Code descending */
  Globaldimension1CodeDesc = '_GLOBALDIMENSION1CODE_DESC_',
  /** Sort on GlobalDimension2Code ascending */
  Globaldimension2CodeAsc = '_GLOBALDIMENSION2CODE_ASC_',
  /** sort on GlobalDimension2Code descending */
  Globaldimension2CodeDesc = '_GLOBALDIMENSION2CODE_DESC_',
  /** Sort on BudgetedAmount ascending */
  BudgetedamountAsc = '_BUDGETEDAMOUNT_ASC_',
  /** sort on BudgetedAmount descending */
  BudgetedamountDesc = '_BUDGETEDAMOUNT_DESC_',
  /** Sort on VendorPostingGroup ascending */
  VendorpostinggroupAsc = '_VENDORPOSTINGGROUP_ASC_',
  /** sort on VendorPostingGroup descending */
  VendorpostinggroupDesc = '_VENDORPOSTINGGROUP_DESC_',
  /** Sort on CurrencyCode ascending */
  CurrencycodeAsc = '_CURRENCYCODE_ASC_',
  /** sort on CurrencyCode descending */
  CurrencycodeDesc = '_CURRENCYCODE_DESC_',
  /** Sort on LanguageCode ascending */
  LanguagecodeAsc = '_LANGUAGECODE_ASC_',
  /** sort on LanguageCode descending */
  LanguagecodeDesc = '_LANGUAGECODE_DESC_',
  /** Sort on StatisticsGroup ascending */
  StatisticsgroupAsc = '_STATISTICSGROUP_ASC_',
  /** sort on StatisticsGroup descending */
  StatisticsgroupDesc = '_STATISTICSGROUP_DESC_',
  /** Sort on PaymentTermsCode ascending */
  PaymenttermscodeAsc = '_PAYMENTTERMSCODE_ASC_',
  /** sort on PaymentTermsCode descending */
  PaymenttermscodeDesc = '_PAYMENTTERMSCODE_DESC_',
  /** Sort on FinChargeTermsCode ascending */
  FinchargetermscodeAsc = '_FINCHARGETERMSCODE_ASC_',
  /** sort on FinChargeTermsCode descending */
  FinchargetermscodeDesc = '_FINCHARGETERMSCODE_DESC_',
  /** Sort on PurchaserCode ascending */
  PurchasercodeAsc = '_PURCHASERCODE_ASC_',
  /** sort on PurchaserCode descending */
  PurchasercodeDesc = '_PURCHASERCODE_DESC_',
  /** Sort on ShipmentMethodCode ascending */
  ShipmentmethodcodeAsc = '_SHIPMENTMETHODCODE_ASC_',
  /** sort on ShipmentMethodCode descending */
  ShipmentmethodcodeDesc = '_SHIPMENTMETHODCODE_DESC_',
  /** Sort on ShippingAgentCode ascending */
  ShippingagentcodeAsc = '_SHIPPINGAGENTCODE_ASC_',
  /** sort on ShippingAgentCode descending */
  ShippingagentcodeDesc = '_SHIPPINGAGENTCODE_DESC_',
  /** Sort on InvoiceDiscCode ascending */
  InvoicedisccodeAsc = '_INVOICEDISCCODE_ASC_',
  /** sort on InvoiceDiscCode descending */
  InvoicedisccodeDesc = '_INVOICEDISCCODE_DESC_',
  /** Sort on CountryRegionCode ascending */
  CountryregioncodeAsc = '_COUNTRYREGIONCODE_ASC_',
  /** sort on CountryRegionCode descending */
  CountryregioncodeDesc = '_COUNTRYREGIONCODE_DESC_',
  /** Sort on Blocked ascending */
  BlockedAsc = '_BLOCKED_ASC_',
  /** sort on Blocked descending */
  BlockedDesc = '_BLOCKED_DESC_',
  /** Sort on BlockedOPTION ascending */
  BlockedoptionAsc = '_BLOCKEDOPTION_ASC_',
  /** sort on BlockedOPTION descending */
  BlockedoptionDesc = '_BLOCKEDOPTION_DESC_',
  /** Sort on PaytoVendorNo ascending */
  PaytovendornoAsc = '_PAYTOVENDORNO_ASC_',
  /** sort on PaytoVendorNo descending */
  PaytovendornoDesc = '_PAYTOVENDORNO_DESC_',
  /** Sort on Priority ascending */
  PriorityAsc = '_PRIORITY_ASC_',
  /** sort on Priority descending */
  PriorityDesc = '_PRIORITY_DESC_',
  /** Sort on PaymentMethodCode ascending */
  PaymentmethodcodeAsc = '_PAYMENTMETHODCODE_ASC_',
  /** sort on PaymentMethodCode descending */
  PaymentmethodcodeDesc = '_PAYMENTMETHODCODE_DESC_',
  /** Sort on LastModifiedDateTime ascending */
  LastmodifieddatetimeAsc = '_LASTMODIFIEDDATETIME_ASC_',
  /** sort on LastModifiedDateTime descending */
  LastmodifieddatetimeDesc = '_LASTMODIFIEDDATETIME_DESC_',
  /** Sort on LastDateModified ascending */
  LastdatemodifiedAsc = '_LASTDATEMODIFIED_ASC_',
  /** sort on LastDateModified descending */
  LastdatemodifiedDesc = '_LASTDATEMODIFIED_DESC_',
  /** Sort on ApplicationMethod ascending */
  ApplicationmethodAsc = '_APPLICATIONMETHOD_ASC_',
  /** sort on ApplicationMethod descending */
  ApplicationmethodDesc = '_APPLICATIONMETHOD_DESC_',
  /** Sort on ApplicationMethodOPTION ascending */
  ApplicationmethodoptionAsc = '_APPLICATIONMETHODOPTION_ASC_',
  /** sort on ApplicationMethodOPTION descending */
  ApplicationmethodoptionDesc = '_APPLICATIONMETHODOPTION_DESC_',
  /** Sort on PricesIncludingVAT ascending */
  PricesincludingvatAsc = '_PRICESINCLUDINGVAT_ASC_',
  /** sort on PricesIncludingVAT descending */
  PricesincludingvatDesc = '_PRICESINCLUDINGVAT_DESC_',
  /** Sort on FaxNo ascending */
  FaxnoAsc = '_FAXNO_ASC_',
  /** sort on FaxNo descending */
  FaxnoDesc = '_FAXNO_DESC_',
  /** Sort on TelexAnswerBack ascending */
  TelexanswerbackAsc = '_TELEXANSWERBACK_ASC_',
  /** sort on TelexAnswerBack descending */
  TelexanswerbackDesc = '_TELEXANSWERBACK_DESC_',
  /** Sort on VATRegistrationNo ascending */
  VatregistrationnoAsc = '_VATREGISTRATIONNO_ASC_',
  /** sort on VATRegistrationNo descending */
  VatregistrationnoDesc = '_VATREGISTRATIONNO_DESC_',
  /** Sort on GenBusPostingGroup ascending */
  GenbuspostinggroupAsc = '_GENBUSPOSTINGGROUP_ASC_',
  /** sort on GenBusPostingGroup descending */
  GenbuspostinggroupDesc = '_GENBUSPOSTINGGROUP_DESC_',
  /** Sort on Picture ascending */
  PictureAsc = '_PICTURE_ASC_',
  /** sort on Picture descending */
  PictureDesc = '_PICTURE_DESC_',
  /** Sort on GLN ascending */
  GlnAsc = '_GLN_ASC_',
  /** sort on GLN descending */
  GlnDesc = '_GLN_DESC_',
  /** Sort on PostCode ascending */
  PostcodeAsc = '_POSTCODE_ASC_',
  /** sort on PostCode descending */
  PostcodeDesc = '_POSTCODE_DESC_',
  /** Sort on County ascending */
  CountyAsc = '_COUNTY_ASC_',
  /** sort on County descending */
  CountyDesc = '_COUNTY_DESC_',
  /** Sort on EMail ascending */
  EmailAsc = '_EMAIL_ASC_',
  /** sort on EMail descending */
  EmailDesc = '_EMAIL_DESC_',
  /** Sort on HomePage ascending */
  HomepageAsc = '_HOMEPAGE_ASC_',
  /** sort on HomePage descending */
  HomepageDesc = '_HOMEPAGE_DESC_',
  /** Sort on NoSeries ascending */
  NoseriesAsc = '_NOSERIES_ASC_',
  /** sort on NoSeries descending */
  NoseriesDesc = '_NOSERIES_DESC_',
  /** Sort on TaxAreaCode ascending */
  TaxareacodeAsc = '_TAXAREACODE_ASC_',
  /** sort on TaxAreaCode descending */
  TaxareacodeDesc = '_TAXAREACODE_DESC_',
  /** Sort on TaxLiable ascending */
  TaxliableAsc = '_TAXLIABLE_ASC_',
  /** sort on TaxLiable descending */
  TaxliableDesc = '_TAXLIABLE_DESC_',
  /** Sort on VATBusPostingGroup ascending */
  VatbuspostinggroupAsc = '_VATBUSPOSTINGGROUP_ASC_',
  /** sort on VATBusPostingGroup descending */
  VatbuspostinggroupDesc = '_VATBUSPOSTINGGROUP_DESC_',
  /** Sort on BlockPaymentTolerance ascending */
  BlockpaymenttoleranceAsc = '_BLOCKPAYMENTTOLERANCE_ASC_',
  /** sort on BlockPaymentTolerance descending */
  BlockpaymenttoleranceDesc = '_BLOCKPAYMENTTOLERANCE_DESC_',
  /** Sort on ICPartnerCode ascending */
  IcpartnercodeAsc = '_ICPARTNERCODE_ASC_',
  /** sort on ICPartnerCode descending */
  IcpartnercodeDesc = '_ICPARTNERCODE_DESC_',
  /** Sort on Prepayment ascending */
  PrepaymentAsc = '_PREPAYMENT_ASC_',
  /** sort on Prepayment descending */
  PrepaymentDesc = '_PREPAYMENT_DESC_',
  /** Sort on PartnerType ascending */
  PartnertypeAsc = '_PARTNERTYPE_ASC_',
  /** sort on PartnerType descending */
  PartnertypeDesc = '_PARTNERTYPE_DESC_',
  /** Sort on PartnerTypeOPTION ascending */
  PartnertypeoptionAsc = '_PARTNERTYPEOPTION_ASC_',
  /** sort on PartnerTypeOPTION descending */
  PartnertypeoptionDesc = '_PARTNERTYPEOPTION_DESC_',
  /** Sort on Image ascending */
  ImageAsc = '_IMAGE_ASC_',
  /** sort on Image descending */
  ImageDesc = '_IMAGE_DESC_',
  /** Sort on CreditorNo ascending */
  CreditornoAsc = '_CREDITORNO_ASC_',
  /** sort on CreditorNo descending */
  CreditornoDesc = '_CREDITORNO_DESC_',
  /** Sort on PreferredBankAccountCode ascending */
  PreferredbankaccountcodeAsc = '_PREFERREDBANKACCOUNTCODE_ASC_',
  /** sort on PreferredBankAccountCode descending */
  PreferredbankaccountcodeDesc = '_PREFERREDBANKACCOUNTCODE_DESC_',
  /** Sort on CashFlowPaymentTermsCode ascending */
  CashflowpaymenttermscodeAsc = '_CASHFLOWPAYMENTTERMSCODE_ASC_',
  /** sort on CashFlowPaymentTermsCode descending */
  CashflowpaymenttermscodeDesc = '_CASHFLOWPAYMENTTERMSCODE_DESC_',
  /** Sort on PrimaryContactNo ascending */
  PrimarycontactnoAsc = '_PRIMARYCONTACTNO_ASC_',
  /** sort on PrimaryContactNo descending */
  PrimarycontactnoDesc = '_PRIMARYCONTACTNO_DESC_',
  /** Sort on ResponsibilityCenter ascending */
  ResponsibilitycenterAsc = '_RESPONSIBILITYCENTER_ASC_',
  /** sort on ResponsibilityCenter descending */
  ResponsibilitycenterDesc = '_RESPONSIBILITYCENTER_DESC_',
  /** Sort on LocationCode ascending */
  LocationcodeAsc = '_LOCATIONCODE_ASC_',
  /** sort on LocationCode descending */
  LocationcodeDesc = '_LOCATIONCODE_DESC_',
  /** Sort on LeadTimeCalculation ascending */
  LeadtimecalculationAsc = '_LEADTIMECALCULATION_ASC_',
  /** sort on LeadTimeCalculation descending */
  LeadtimecalculationDesc = '_LEADTIMECALCULATION_DESC_',
  /** Sort on BaseCalendarCode ascending */
  BasecalendarcodeAsc = '_BASECALENDARCODE_ASC_',
  /** sort on BaseCalendarCode descending */
  BasecalendarcodeDesc = '_BASECALENDARCODE_DESC_',
  /** Sort on DocumentSendingProfile ascending */
  DocumentsendingprofileAsc = '_DOCUMENTSENDINGPROFILE_ASC_',
  /** sort on DocumentSendingProfile descending */
  DocumentsendingprofileDesc = '_DOCUMENTSENDINGPROFILE_DESC_',
  /** Sort on ValidateEUVatRegNo ascending */
  ValidateeuvatregnoAsc = '_VALIDATEEUVATREGNO_ASC_',
  /** sort on ValidateEUVatRegNo descending */
  ValidateeuvatregnoDesc = '_VALIDATEEUVATREGNO_DESC_',
  /** Sort on Id ascending */
  IdAsc = '_ID_ASC_',
  /** sort on Id descending */
  IdDesc = '_ID_DESC_',
  /** Sort on CurrencyId ascending */
  CurrencyidAsc = '_CURRENCYID_ASC_',
  /** sort on CurrencyId descending */
  CurrencyidDesc = '_CURRENCYID_DESC_',
  /** Sort on PaymentTermsId ascending */
  PaymenttermsidAsc = '_PAYMENTTERMSID_ASC_',
  /** sort on PaymentTermsId descending */
  PaymenttermsidDesc = '_PAYMENTTERMSID_DESC_',
  /** Sort on PaymentMethodId ascending */
  PaymentmethodidAsc = '_PAYMENTMETHODID_ASC_',
  /** sort on PaymentMethodId descending */
  PaymentmethodidDesc = '_PAYMENTMETHODID_DESC_',
  /** Sort on NocontrolofExtDocNo ascending */
  NocontrolofextdocnoAsc = '_NOCONTROLOFEXTDOCNO_ASC_',
  /** sort on NocontrolofExtDocNo descending */
  NocontrolofextdocnoDesc = '_NOCONTROLOFEXTDOCNO_DESC_',
  /** Sort on ExternalDocumentNo ascending */
  ExternaldocumentnoAsc = '_EXTERNALDOCUMENTNO_ASC_',
  /** sort on ExternalDocumentNo descending */
  ExternaldocumentnoDesc = '_EXTERNALDOCUMENTNO_DESC_',
  /** Sort on RegistrationNo ascending */
  RegistrationnoAsc = '_REGISTRATIONNO_ASC_',
  /** sort on RegistrationNo descending */
  RegistrationnoDesc = '_REGISTRATIONNO_DESC_',
  /** Sort on DocumentCode ascending */
  DocumentcodeAsc = '_DOCUMENTCODE_ASC_',
  /** sort on DocumentCode descending */
  DocumentcodeDesc = '_DOCUMENTCODE_DESC_',
  /** Sort on DateFilter ascending */
  DatefilterAsc = '_DATEFILTER_ASC_',
  /** sort on DateFilter descending */
  DatefilterDesc = '_DATEFILTER_DESC_',
  /** Sort on GlobalDimension1Filter ascending */
  Globaldimension1FilterAsc = '_GLOBALDIMENSION1FILTER_ASC_',
  /** sort on GlobalDimension1Filter descending */
  Globaldimension1FilterDesc = '_GLOBALDIMENSION1FILTER_DESC_',
  /** Sort on GlobalDimension2Filter ascending */
  Globaldimension2FilterAsc = '_GLOBALDIMENSION2FILTER_ASC_',
  /** sort on GlobalDimension2Filter descending */
  Globaldimension2FilterDesc = '_GLOBALDIMENSION2FILTER_DESC_',
  /** Sort on CurrencyFilter ascending */
  CurrencyfilterAsc = '_CURRENCYFILTER_ASC_',
  /** sort on CurrencyFilter descending */
  CurrencyfilterDesc = '_CURRENCYFILTER_DESC_',
  /** Sort on Comment ascending */
  CommentAsc = '_COMMENT_ASC_',
  /** sort on Comment descending */
  CommentDesc = '_COMMENT_DESC_',
  /** Sort on Balance ascending */
  BalanceAsc = '_BALANCE_ASC_',
  /** sort on Balance descending */
  BalanceDesc = '_BALANCE_DESC_',
  /** Sort on BalanceLCY ascending */
  BalancelcyAsc = '_BALANCELCY_ASC_',
  /** sort on BalanceLCY descending */
  BalancelcyDesc = '_BALANCELCY_DESC_',
  /** Sort on NetChange ascending */
  NetchangeAsc = '_NETCHANGE_ASC_',
  /** sort on NetChange descending */
  NetchangeDesc = '_NETCHANGE_DESC_',
  /** Sort on NetChangeLCY ascending */
  NetchangelcyAsc = '_NETCHANGELCY_ASC_',
  /** sort on NetChangeLCY descending */
  NetchangelcyDesc = '_NETCHANGELCY_DESC_',
  /** Sort on PurchasesLCY ascending */
  PurchaseslcyAsc = '_PURCHASESLCY_ASC_',
  /** sort on PurchasesLCY descending */
  PurchaseslcyDesc = '_PURCHASESLCY_DESC_',
  /** Sort on InvDiscountsLCY ascending */
  InvdiscountslcyAsc = '_INVDISCOUNTSLCY_ASC_',
  /** sort on InvDiscountsLCY descending */
  InvdiscountslcyDesc = '_INVDISCOUNTSLCY_DESC_',
  /** Sort on PmtDiscountsLCY ascending */
  PmtdiscountslcyAsc = '_PMTDISCOUNTSLCY_ASC_',
  /** sort on PmtDiscountsLCY descending */
  PmtdiscountslcyDesc = '_PMTDISCOUNTSLCY_DESC_',
  /** Sort on BalanceDue ascending */
  BalancedueAsc = '_BALANCEDUE_ASC_',
  /** sort on BalanceDue descending */
  BalancedueDesc = '_BALANCEDUE_DESC_',
  /** Sort on BalanceDueLCY ascending */
  BalanceduelcyAsc = '_BALANCEDUELCY_ASC_',
  /** sort on BalanceDueLCY descending */
  BalanceduelcyDesc = '_BALANCEDUELCY_DESC_',
  /** Sort on Payments ascending */
  PaymentsAsc = '_PAYMENTS_ASC_',
  /** sort on Payments descending */
  PaymentsDesc = '_PAYMENTS_DESC_',
  /** Sort on InvoiceAmounts ascending */
  InvoiceamountsAsc = '_INVOICEAMOUNTS_ASC_',
  /** sort on InvoiceAmounts descending */
  InvoiceamountsDesc = '_INVOICEAMOUNTS_DESC_',
  /** Sort on CrMemoAmounts ascending */
  CrmemoamountsAsc = '_CRMEMOAMOUNTS_ASC_',
  /** sort on CrMemoAmounts descending */
  CrmemoamountsDesc = '_CRMEMOAMOUNTS_DESC_',
  /** Sort on FinanceChargeMemoAmounts ascending */
  FinancechargememoamountsAsc = '_FINANCECHARGEMEMOAMOUNTS_ASC_',
  /** sort on FinanceChargeMemoAmounts descending */
  FinancechargememoamountsDesc = '_FINANCECHARGEMEMOAMOUNTS_DESC_',
  /** Sort on PaymentsLCY ascending */
  PaymentslcyAsc = '_PAYMENTSLCY_ASC_',
  /** sort on PaymentsLCY descending */
  PaymentslcyDesc = '_PAYMENTSLCY_DESC_',
  /** Sort on InvAmountsLCY ascending */
  InvamountslcyAsc = '_INVAMOUNTSLCY_ASC_',
  /** sort on InvAmountsLCY descending */
  InvamountslcyDesc = '_INVAMOUNTSLCY_DESC_',
  /** Sort on CrMemoAmountsLCY ascending */
  CrmemoamountslcyAsc = '_CRMEMOAMOUNTSLCY_ASC_',
  /** sort on CrMemoAmountsLCY descending */
  CrmemoamountslcyDesc = '_CRMEMOAMOUNTSLCY_DESC_',
  /** Sort on FinChargeMemoAmountsLCY ascending */
  FinchargememoamountslcyAsc = '_FINCHARGEMEMOAMOUNTSLCY_ASC_',
  /** sort on FinChargeMemoAmountsLCY descending */
  FinchargememoamountslcyDesc = '_FINCHARGEMEMOAMOUNTSLCY_DESC_',
  /** Sort on OutstandingOrders ascending */
  OutstandingordersAsc = '_OUTSTANDINGORDERS_ASC_',
  /** sort on OutstandingOrders descending */
  OutstandingordersDesc = '_OUTSTANDINGORDERS_DESC_',
  /** Sort on AmtRcdNotInvoiced ascending */
  AmtrcdnotinvoicedAsc = '_AMTRCDNOTINVOICED_ASC_',
  /** sort on AmtRcdNotInvoiced descending */
  AmtrcdnotinvoicedDesc = '_AMTRCDNOTINVOICED_DESC_',
  /** Sort on DebitAmount ascending */
  DebitamountAsc = '_DEBITAMOUNT_ASC_',
  /** sort on DebitAmount descending */
  DebitamountDesc = '_DEBITAMOUNT_DESC_',
  /** Sort on CreditAmount ascending */
  CreditamountAsc = '_CREDITAMOUNT_ASC_',
  /** sort on CreditAmount descending */
  CreditamountDesc = '_CREDITAMOUNT_DESC_',
  /** Sort on DebitAmountLCY ascending */
  DebitamountlcyAsc = '_DEBITAMOUNTLCY_ASC_',
  /** sort on DebitAmountLCY descending */
  DebitamountlcyDesc = '_DEBITAMOUNTLCY_DESC_',
  /** Sort on CreditAmountLCY ascending */
  CreditamountlcyAsc = '_CREDITAMOUNTLCY_ASC_',
  /** sort on CreditAmountLCY descending */
  CreditamountlcyDesc = '_CREDITAMOUNTLCY_DESC_',
  /** Sort on ReminderAmounts ascending */
  ReminderamountsAsc = '_REMINDERAMOUNTS_ASC_',
  /** sort on ReminderAmounts descending */
  ReminderamountsDesc = '_REMINDERAMOUNTS_DESC_',
  /** Sort on ReminderAmountsLCY ascending */
  ReminderamountslcyAsc = '_REMINDERAMOUNTSLCY_ASC_',
  /** sort on ReminderAmountsLCY descending */
  ReminderamountslcyDesc = '_REMINDERAMOUNTSLCY_DESC_',
  /** Sort on OutstandingOrdersLCY ascending */
  OutstandingorderslcyAsc = '_OUTSTANDINGORDERSLCY_ASC_',
  /** sort on OutstandingOrdersLCY descending */
  OutstandingorderslcyDesc = '_OUTSTANDINGORDERSLCY_DESC_',
  /** Sort on AmtRcdNotInvoicedLCY ascending */
  AmtrcdnotinvoicedlcyAsc = '_AMTRCDNOTINVOICEDLCY_ASC_',
  /** sort on AmtRcdNotInvoicedLCY descending */
  AmtrcdnotinvoicedlcyDesc = '_AMTRCDNOTINVOICEDLCY_DESC_',
  /** Sort on PmtDiscToleranceLCY ascending */
  PmtdisctolerancelcyAsc = '_PMTDISCTOLERANCELCY_ASC_',
  /** sort on PmtDiscToleranceLCY descending */
  PmtdisctolerancelcyDesc = '_PMTDISCTOLERANCELCY_DESC_',
  /** Sort on PmtToleranceLCY ascending */
  PmttolerancelcyAsc = '_PMTTOLERANCELCY_ASC_',
  /** sort on PmtToleranceLCY descending */
  PmttolerancelcyDesc = '_PMTTOLERANCELCY_DESC_',
  /** Sort on Refunds ascending */
  RefundsAsc = '_REFUNDS_ASC_',
  /** sort on Refunds descending */
  RefundsDesc = '_REFUNDS_DESC_',
  /** Sort on RefundsLCY ascending */
  RefundslcyAsc = '_REFUNDSLCY_ASC_',
  /** sort on RefundsLCY descending */
  RefundslcyDesc = '_REFUNDSLCY_DESC_',
  /** Sort on OtherAmounts ascending */
  OtheramountsAsc = '_OTHERAMOUNTS_ASC_',
  /** sort on OtherAmounts descending */
  OtheramountsDesc = '_OTHERAMOUNTS_DESC_',
  /** Sort on OtherAmountsLCY ascending */
  OtheramountslcyAsc = '_OTHERAMOUNTSLCY_ASC_',
  /** sort on OtherAmountsLCY descending */
  OtheramountslcyDesc = '_OTHERAMOUNTSLCY_DESC_',
  /** Sort on OutstandingInvoices ascending */
  OutstandinginvoicesAsc = '_OUTSTANDINGINVOICES_ASC_',
  /** sort on OutstandingInvoices descending */
  OutstandinginvoicesDesc = '_OUTSTANDINGINVOICES_DESC_',
  /** Sort on OutstandingInvoicesLCY ascending */
  OutstandinginvoiceslcyAsc = '_OUTSTANDINGINVOICESLCY_ASC_',
  /** sort on OutstandingInvoicesLCY descending */
  OutstandinginvoiceslcyDesc = '_OUTSTANDINGINVOICESLCY_DESC_',
  /** Sort on PaytoNoOfArchivedDoc ascending */
  PaytonoofarchiveddocAsc = '_PAYTONOOFARCHIVEDDOC_ASC_',
  /** sort on PaytoNoOfArchivedDoc descending */
  PaytonoofarchiveddocDesc = '_PAYTONOOFARCHIVEDDOC_DESC_',
  /** Sort on BuyfromNoOfArchivedDoc ascending */
  BuyfromnoofarchiveddocAsc = '_BUYFROMNOOFARCHIVEDDOC_ASC_',
  /** sort on BuyfromNoOfArchivedDoc descending */
  BuyfromnoofarchiveddocDesc = '_BUYFROMNOOFARCHIVEDDOC_DESC_',
  /** Sort on NoofPstdReceipts ascending */
  NoofpstdreceiptsAsc = '_NOOFPSTDRECEIPTS_ASC_',
  /** sort on NoofPstdReceipts descending */
  NoofpstdreceiptsDesc = '_NOOFPSTDRECEIPTS_DESC_',
  /** Sort on NoofPstdInvoices ascending */
  NoofpstdinvoicesAsc = '_NOOFPSTDINVOICES_ASC_',
  /** sort on NoofPstdInvoices descending */
  NoofpstdinvoicesDesc = '_NOOFPSTDINVOICES_DESC_',
  /** Sort on NoofPstdReturnShipments ascending */
  NoofpstdreturnshipmentsAsc = '_NOOFPSTDRETURNSHIPMENTS_ASC_',
  /** sort on NoofPstdReturnShipments descending */
  NoofpstdreturnshipmentsDesc = '_NOOFPSTDRETURNSHIPMENTS_DESC_',
  /** Sort on NoofPstdCreditMemos ascending */
  NoofpstdcreditmemosAsc = '_NOOFPSTDCREDITMEMOS_ASC_',
  /** sort on NoofPstdCreditMemos descending */
  NoofpstdcreditmemosDesc = '_NOOFPSTDCREDITMEMOS_DESC_',
  /** Sort on PaytoNoofOrders ascending */
  PaytonoofordersAsc = '_PAYTONOOFORDERS_ASC_',
  /** sort on PaytoNoofOrders descending */
  PaytonoofordersDesc = '_PAYTONOOFORDERS_DESC_',
  /** Sort on PaytoNoofInvoices ascending */
  PaytonoofinvoicesAsc = '_PAYTONOOFINVOICES_ASC_',
  /** sort on PaytoNoofInvoices descending */
  PaytonoofinvoicesDesc = '_PAYTONOOFINVOICES_DESC_',
  /** Sort on PaytoNoofReturnOrders ascending */
  PaytonoofreturnordersAsc = '_PAYTONOOFRETURNORDERS_ASC_',
  /** sort on PaytoNoofReturnOrders descending */
  PaytonoofreturnordersDesc = '_PAYTONOOFRETURNORDERS_DESC_',
  /** Sort on PaytoNoofCreditMemos ascending */
  PaytonoofcreditmemosAsc = '_PAYTONOOFCREDITMEMOS_ASC_',
  /** sort on PaytoNoofCreditMemos descending */
  PaytonoofcreditmemosDesc = '_PAYTONOOFCREDITMEMOS_DESC_',
  /** Sort on PaytoNoofPstdReceipts ascending */
  PaytonoofpstdreceiptsAsc = '_PAYTONOOFPSTDRECEIPTS_ASC_',
  /** sort on PaytoNoofPstdReceipts descending */
  PaytonoofpstdreceiptsDesc = '_PAYTONOOFPSTDRECEIPTS_DESC_',
  /** Sort on PaytoNoofPstdInvoices ascending */
  PaytonoofpstdinvoicesAsc = '_PAYTONOOFPSTDINVOICES_ASC_',
  /** sort on PaytoNoofPstdInvoices descending */
  PaytonoofpstdinvoicesDesc = '_PAYTONOOFPSTDINVOICES_DESC_',
  /** Sort on PaytoNoofPstdReturnS ascending */
  PaytonoofpstdreturnsAsc = '_PAYTONOOFPSTDRETURNS_ASC_',
  /** sort on PaytoNoofPstdReturnS descending */
  PaytonoofpstdreturnsDesc = '_PAYTONOOFPSTDRETURNS_DESC_',
  /** Sort on PaytoNoofPstdCrMemos ascending */
  PaytonoofpstdcrmemosAsc = '_PAYTONOOFPSTDCRMEMOS_ASC_',
  /** sort on PaytoNoofPstdCrMemos descending */
  PaytonoofpstdcrmemosDesc = '_PAYTONOOFPSTDCRMEMOS_DESC_',
  /** Sort on NoofQuotes ascending */
  NoofquotesAsc = '_NOOFQUOTES_ASC_',
  /** sort on NoofQuotes descending */
  NoofquotesDesc = '_NOOFQUOTES_DESC_',
  /** Sort on NoofBlanketOrders ascending */
  NoofblanketordersAsc = '_NOOFBLANKETORDERS_ASC_',
  /** sort on NoofBlanketOrders descending */
  NoofblanketordersDesc = '_NOOFBLANKETORDERS_DESC_',
  /** Sort on NoofOrders ascending */
  NoofordersAsc = '_NOOFORDERS_ASC_',
  /** sort on NoofOrders descending */
  NoofordersDesc = '_NOOFORDERS_DESC_',
  /** Sort on NoofInvoices ascending */
  NoofinvoicesAsc = '_NOOFINVOICES_ASC_',
  /** sort on NoofInvoices descending */
  NoofinvoicesDesc = '_NOOFINVOICES_DESC_',
  /** Sort on NoofReturnOrders ascending */
  NoofreturnordersAsc = '_NOOFRETURNORDERS_ASC_',
  /** sort on NoofReturnOrders descending */
  NoofreturnordersDesc = '_NOOFRETURNORDERS_DESC_',
  /** Sort on NoofCreditMemos ascending */
  NoofcreditmemosAsc = '_NOOFCREDITMEMOS_ASC_',
  /** sort on NoofCreditMemos descending */
  NoofcreditmemosDesc = '_NOOFCREDITMEMOS_DESC_',
  /** Sort on NoofOrderAddresses ascending */
  NooforderaddressesAsc = '_NOOFORDERADDRESSES_ASC_',
  /** sort on NoofOrderAddresses descending */
  NooforderaddressesDesc = '_NOOFORDERADDRESSES_DESC_',
  /** Sort on PaytoNoofQuotes ascending */
  PaytonoofquotesAsc = '_PAYTONOOFQUOTES_ASC_',
  /** sort on PaytoNoofQuotes descending */
  PaytonoofquotesDesc = '_PAYTONOOFQUOTES_DESC_',
  /** Sort on PaytoNoofBlanketOrders ascending */
  PaytonoofblanketordersAsc = '_PAYTONOOFBLANKETORDERS_ASC_',
  /** sort on PaytoNoofBlanketOrders descending */
  PaytonoofblanketordersDesc = '_PAYTONOOFBLANKETORDERS_DESC_',
  /** Sort on NoofIncomingDocuments ascending */
  NoofincomingdocumentsAsc = '_NOOFINCOMINGDOCUMENTS_ASC_',
  /** sort on NoofIncomingDocuments descending */
  NoofincomingdocumentsDesc = '_NOOFINCOMINGDOCUMENTS_DESC_',
  /** Sort on InwardRegBalanceLCY ascending */
  InwardregbalancelcyAsc = '_INWARDREGBALANCELCY_ASC_',
  /** sort on InwardRegBalanceLCY descending */
  InwardregbalancelcyDesc = '_INWARDREGBALANCELCY_DESC_',
  /** Sort on InwardRegNetChangeLCY ascending */
  InwardregnetchangelcyAsc = '_INWARDREGNETCHANGELCY_ASC_',
  /** sort on InwardRegNetChangeLCY descending */
  InwardregnetchangelcyDesc = '_INWARDREGNETCHANGELCY_DESC_',
  /** Sort on StateCode ascending */
  StatecodeAsc = '_STATECODE_ASC_',
  /** sort on StateCode descending */
  StatecodeDesc = '_STATECODE_DESC_',
  /** Sort on StatusCode ascending */
  StatuscodeAsc = '_STATUSCODE_ASC_',
  /** sort on StatusCode descending */
  StatuscodeDesc = '_STATUSCODE_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortVendorPostingGroupInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on PayablesAccount ascending */
  PayablesaccountAsc = '_PAYABLESACCOUNT_ASC_',
  /** sort on PayablesAccount descending */
  PayablesaccountDesc = '_PAYABLESACCOUNT_DESC_',
  /** Sort on ServiceChargeAcc ascending */
  ServicechargeaccAsc = '_SERVICECHARGEACC_ASC_',
  /** sort on ServiceChargeAcc descending */
  ServicechargeaccDesc = '_SERVICECHARGEACC_DESC_',
  /** Sort on PaymentDiscDebitAcc ascending */
  PaymentdiscdebitaccAsc = '_PAYMENTDISCDEBITACC_ASC_',
  /** sort on PaymentDiscDebitAcc descending */
  PaymentdiscdebitaccDesc = '_PAYMENTDISCDEBITACC_DESC_',
  /** Sort on InvoiceRoundingAccount ascending */
  InvoiceroundingaccountAsc = '_INVOICEROUNDINGACCOUNT_ASC_',
  /** sort on InvoiceRoundingAccount descending */
  InvoiceroundingaccountDesc = '_INVOICEROUNDINGACCOUNT_DESC_',
  /** Sort on DebitCurrApplnRndgAcc ascending */
  DebitcurrapplnrndgaccAsc = '_DEBITCURRAPPLNRNDGACC_ASC_',
  /** sort on DebitCurrApplnRndgAcc descending */
  DebitcurrapplnrndgaccDesc = '_DEBITCURRAPPLNRNDGACC_DESC_',
  /** Sort on CreditCurrApplnRndgAcc ascending */
  CreditcurrapplnrndgaccAsc = '_CREDITCURRAPPLNRNDGACC_ASC_',
  /** sort on CreditCurrApplnRndgAcc descending */
  CreditcurrapplnrndgaccDesc = '_CREDITCURRAPPLNRNDGACC_DESC_',
  /** Sort on DebitRoundingAccount ascending */
  DebitroundingaccountAsc = '_DEBITROUNDINGACCOUNT_ASC_',
  /** sort on DebitRoundingAccount descending */
  DebitroundingaccountDesc = '_DEBITROUNDINGACCOUNT_DESC_',
  /** Sort on CreditRoundingAccount ascending */
  CreditroundingaccountAsc = '_CREDITROUNDINGACCOUNT_ASC_',
  /** sort on CreditRoundingAccount descending */
  CreditroundingaccountDesc = '_CREDITROUNDINGACCOUNT_DESC_',
  /** Sort on PaymentDiscCreditAcc ascending */
  PaymentdisccreditaccAsc = '_PAYMENTDISCCREDITACC_ASC_',
  /** sort on PaymentDiscCreditAcc descending */
  PaymentdisccreditaccDesc = '_PAYMENTDISCCREDITACC_DESC_',
  /** Sort on PaymentToleranceDebitAcc ascending */
  PaymenttolerancedebitaccAsc = '_PAYMENTTOLERANCEDEBITACC_ASC_',
  /** sort on PaymentToleranceDebitAcc descending */
  PaymenttolerancedebitaccDesc = '_PAYMENTTOLERANCEDEBITACC_DESC_',
  /** Sort on PaymentToleranceCreditAcc ascending */
  PaymenttolerancecreditaccAsc = '_PAYMENTTOLERANCECREDITACC_ASC_',
  /** sort on PaymentToleranceCreditAcc descending */
  PaymenttolerancecreditaccDesc = '_PAYMENTTOLERANCECREDITACC_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on ViewAllAccountsonLookup ascending */
  ViewallaccountsonlookupAsc = '_VIEWALLACCOUNTSONLOOKUP_ASC_',
  /** sort on ViewAllAccountsonLookup descending */
  ViewallaccountsonlookupDesc = '_VIEWALLACCOUNTSONLOOKUP_DESC_',
  /** Sort on InwardRegBridgingDebt ascending */
  InwardregbridgingdebtAsc = '_INWARDREGBRIDGINGDEBT_ASC_',
  /** sort on InwardRegBridgingDebt descending */
  InwardregbridgingdebtDesc = '_INWARDREGBRIDGINGDEBT_DESC_',
  /** Sort on PEBInwardRegBridgingDebt ascending */
  PebinwardregbridgingdebtAsc = '_PEBINWARDREGBRIDGINGDEBT_ASC_',
  /** sort on PEBInwardRegBridgingDebt descending */
  PebinwardregbridgingdebtDesc = '_PEBINWARDREGBRIDGINGDEBT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortWarehouseClassInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortWebBookingInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on BookingNo ascending */
  BookingnoAsc = '_BOOKINGNO_ASC_',
  /** sort on BookingNo descending */
  BookingnoDesc = '_BOOKINGNO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on Type ascending */
  TypeAsc = '_TYPE_ASC_',
  /** sort on Type descending */
  TypeDesc = '_TYPE_DESC_',
  /** Sort on TypeOPTION ascending */
  TypeoptionAsc = '_TYPEOPTION_ASC_',
  /** sort on TypeOPTION descending */
  TypeoptionDesc = '_TYPEOPTION_DESC_',
  /** Sort on No ascending */
  NoAsc = '_NO_ASC_',
  /** sort on No descending */
  NoDesc = '_NO_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Quantity ascending */
  QuantityAsc = '_QUANTITY_ASC_',
  /** sort on Quantity descending */
  QuantityDesc = '_QUANTITY_DESC_',
  /** Sort on DeliveryDate ascending */
  DeliverydateAsc = '_DELIVERYDATE_ASC_',
  /** sort on DeliveryDate descending */
  DeliverydateDesc = '_DELIVERYDATE_DESC_',
  /** Sort on DeliveryAddress ascending */
  DeliveryaddressAsc = '_DELIVERYADDRESS_ASC_',
  /** sort on DeliveryAddress descending */
  DeliveryaddressDesc = '_DELIVERYADDRESS_DESC_',
  /** Sort on Contact ascending */
  ContactAsc = '_CONTACT_ASC_',
  /** sort on Contact descending */
  ContactDesc = '_CONTACT_DESC_',
  /** Sort on ContactPhone ascending */
  ContactphoneAsc = '_CONTACTPHONE_ASC_',
  /** sort on ContactPhone descending */
  ContactphoneDesc = '_CONTACTPHONE_DESC_',
  /** Sort on ContactEMail ascending */
  ContactemailAsc = '_CONTACTEMAIL_ASC_',
  /** sort on ContactEMail descending */
  ContactemailDesc = '_CONTACTEMAIL_DESC_',
  /** Sort on Handled ascending */
  HandledAsc = '_HANDLED_ASC_',
  /** sort on Handled descending */
  HandledDesc = '_HANDLED_DESC_',
  /** Sort on ContractNo ascending */
  ContractnoAsc = '_CONTRACTNO_ASC_',
  /** sort on ContractNo descending */
  ContractnoDesc = '_CONTRACTNO_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortWebhookSubscriptionInput {
  /** Sort on Resource ascending */
  ResourceAsc = '_RESOURCE_ASC_',
  /** sort on Resource descending */
  ResourceDesc = '_RESOURCE_DESC_',
  /** Sort on SubscriptionId ascending */
  SubscriptionidAsc = '_SUBSCRIPTIONID_ASC_',
  /** sort on SubscriptionId descending */
  SubscriptionidDesc = '_SUBSCRIPTIONID_DESC_',
  /** Sort on EventType ascending */
  EventtypeAsc = '_EVENTTYPE_ASC_',
  /** sort on EventType descending */
  EventtypeDesc = '_EVENTTYPE_DESC_',
  /** Sort on Enabled ascending */
  EnabledAsc = '_ENABLED_ASC_',
  /** sort on Enabled descending */
  EnabledDesc = '_ENABLED_DESC_',
  /** Sort on CreatedBy ascending */
  CreatedbyAsc = '_CREATEDBY_ASC_',
  /** sort on CreatedBy descending */
  CreatedbyDesc = '_CREATEDBY_DESC_',
  /** Sort on LastUsed ascending */
  LastusedAsc = '_LASTUSED_ASC_',
  /** sort on LastUsed descending */
  LastusedDesc = '_LASTUSED_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortWorkLogInput {
  /** Sort on EntryNo ascending */
  EntrynoAsc = '_ENTRYNO_ASC_',
  /** sort on EntryNo descending */
  EntrynoDesc = '_ENTRYNO_DESC_',
  /** Sort on CustomerNo ascending */
  CustomernoAsc = '_CUSTOMERNO_ASC_',
  /** sort on CustomerNo descending */
  CustomernoDesc = '_CUSTOMERNO_DESC_',
  /** Sort on CustomerProject ascending */
  CustomerprojectAsc = '_CUSTOMERPROJECT_ASC_',
  /** sort on CustomerProject descending */
  CustomerprojectDesc = '_CUSTOMERPROJECT_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on Hours ascending */
  HoursAsc = '_HOURS_ASC_',
  /** sort on Hours descending */
  HoursDesc = '_HOURS_DESC_',
  /** Sort on SimplonaEmail ascending */
  SimplonaemailAsc = '_SIMPLONAEMAIL_ASC_',
  /** sort on SimplonaEmail descending */
  SimplonaemailDesc = '_SIMPLONAEMAIL_DESC_',
  /** Sort on NAVUser ascending */
  NavuserAsc = '_NAVUSER_ASC_',
  /** sort on NAVUser descending */
  NavuserDesc = '_NAVUSER_DESC_',
  /** Sort on WorkDateTime ascending */
  WorkdatetimeAsc = '_WORKDATETIME_ASC_',
  /** sort on WorkDateTime descending */
  WorkdatetimeDesc = '_WORKDATETIME_DESC_',
  /** Sort on Debited ascending */
  DebitedAsc = '_DEBITED_ASC_',
  /** sort on Debited descending */
  DebitedDesc = '_DEBITED_DESC_',
  /** Sort on DoNotDebit ascending */
  DonotdebitAsc = '_DONOTDEBIT_ASC_',
  /** sort on DoNotDebit descending */
  DonotdebitDesc = '_DONOTDEBIT_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export enum SortWorkTypeInput {
  /** Sort on Code ascending */
  CodeAsc = '_CODE_ASC_',
  /** sort on Code descending */
  CodeDesc = '_CODE_DESC_',
  /** Sort on Description ascending */
  DescriptionAsc = '_DESCRIPTION_ASC_',
  /** sort on Description descending */
  DescriptionDesc = '_DESCRIPTION_DESC_',
  /** Sort on UnitofMeasureCode ascending */
  UnitofmeasurecodeAsc = '_UNITOFMEASURECODE_ASC_',
  /** sort on UnitofMeasureCode descending */
  UnitofmeasurecodeDesc = '_UNITOFMEASURECODE_DESC_',
  /** Sort on GenProdPostingGroup ascending */
  GenprodpostinggroupAsc = '_GENPRODPOSTINGGROUP_ASC_',
  /** sort on GenProdPostingGroup descending */
  GenprodpostinggroupDesc = '_GENPRODPOSTINGGROUP_DESC_',
  /** Sort on VATProdPostingGroup ascending */
  VatprodpostinggroupAsc = '_VATPRODPOSTINGGROUP_ASC_',
  /** sort on VATProdPostingGroup descending */
  VatprodpostinggroupDesc = '_VATPRODPOSTINGGROUP_DESC_',
  /** Sort on GroupCountReadOnly ascending */
  GroupcountreadonlyAsc = '_GROUPCOUNTREADONLY_ASC_',
  /** sort on GroupCountReadOnly descending */
  GroupcountreadonlyDesc = '_GROUPCOUNTREADONLY_DESC_',
  /** Sort on TotalCountReadOnly ascending */
  TotalcountreadonlyAsc = '_TOTALCOUNTREADONLY_ASC_',
  /** sort on TotalCountReadOnly descending */
  TotalcountreadonlyDesc = '_TOTALCOUNTREADONLY_DESC_',
  /** Sort on KeyValue ascending */
  KeyvalueAsc = '_KEYVALUE_ASC_',
  /** sort on KeyValue descending */
  KeyvalueDesc = '_KEYVALUE_DESC_',
  /** Sort on Company ascending */
  CompanyAsc = '_COMPANY_ASC_',
  /** sort on Company descending */
  CompanyDesc = '_COMPANY_DESC_',
  /** Sort on CompanyRegistrationNo ascending */
  CompanyregistrationnoAsc = '_COMPANYREGISTRATIONNO_ASC_',
  /** sort on CompanyRegistrationNo descending */
  CompanyregistrationnoDesc = '_COMPANYREGISTRATIONNO_DESC_',
  /** Sort on CompanyRegistrationNoClean ascending */
  CompanyregistrationnocleanAsc = '_COMPANYREGISTRATIONNOCLEAN_ASC_',
  /** sort on CompanyRegistrationNoClean descending */
  CompanyregistrationnocleanDesc = '_COMPANYREGISTRATIONNOCLEAN_DESC_',
  /** Sort on DomainId ascending */
  DomainidAsc = '_DOMAINID_ASC_',
  /** sort on DomainId descending */
  DomainidDesc = '_DOMAINID_DESC_',
  /** Sort on CustomerId ascending */
  CustomeridAsc = '_CUSTOMERID_ASC_',
  /** sort on CustomerId descending */
  CustomeridDesc = '_CUSTOMERID_DESC_',
  /** Sort on ProjectId ascending */
  ProjectidAsc = '_PROJECTID_ASC_',
  /** sort on ProjectId descending */
  ProjectidDesc = '_PROJECTID_DESC_',
  /** Sort on SignStatus ascending */
  SignstatusAsc = '_SIGNSTATUS_ASC_',
  /** sort on SignStatus descending */
  SignstatusDesc = '_SIGNSTATUS_DESC_',
  /** Sort on SearchString ascending */
  SearchstringAsc = '_SEARCHSTRING_ASC_',
  /** sort on SearchString descending */
  SearchstringDesc = '_SEARCHSTRING_DESC_',
  /** Sort on SyncedToNav ascending */
  SyncedtonavAsc = '_SYNCEDTONAV_ASC_',
  /** sort on SyncedToNav descending */
  SyncedtonavDesc = '_SYNCEDTONAV_DESC_',
  /** Sort on SyncCount ascending */
  SynccountAsc = '_SYNCCOUNT_ASC_',
  /** sort on SyncCount descending */
  SynccountDesc = '_SYNCCOUNT_DESC_',
  /** Sort on SyncProblems ascending */
  SyncproblemsAsc = '_SYNCPROBLEMS_ASC_',
  /** sort on SyncProblems descending */
  SyncproblemsDesc = '_SYNCPROBLEMS_DESC_',
  /** Sort on SyncProblemOrgiginalEvent ascending */
  SyncproblemorgiginaleventAsc = '_SYNCPROBLEMORGIGINALEVENT_ASC_',
  /** sort on SyncProblemOrgiginalEvent descending */
  SyncproblemorgiginaleventDesc = '_SYNCPROBLEMORGIGINALEVENT_DESC_',
  /** Sort on NavRecordId ascending */
  NavrecordidAsc = '_NAVRECORDID_ASC_',
  /** sort on NavRecordId descending */
  NavrecordidDesc = '_NAVRECORDID_DESC_',
  /** Sort on ConnectedToRecordId ascending */
  ConnectedtorecordidAsc = '_CONNECTEDTORECORDID_ASC_',
  /** sort on ConnectedToRecordId descending */
  ConnectedtorecordidDesc = '_CONNECTEDTORECORDID_DESC_',
  /** Sort on ModifiedOn ascending */
  ModifiedonAsc = '_MODIFIEDON_ASC_',
  /** sort on ModifiedOn descending */
  ModifiedonDesc = '_MODIFIEDON_DESC_',
  /** Sort on ModifiedOnSales ascending */
  ModifiedonsalesAsc = '_MODIFIEDONSALES_ASC_',
  /** sort on ModifiedOnSales descending */
  ModifiedonsalesDesc = '_MODIFIEDONSALES_DESC_',
  /** Sort on ModifiedOnMagento ascending */
  ModifiedonmagentoAsc = '_MODIFIEDONMAGENTO_ASC_',
  /** sort on ModifiedOnMagento descending */
  ModifiedonmagentoDesc = '_MODIFIEDONMAGENTO_DESC_',
  /** Sort on SentToMagento ascending */
  SenttomagentoAsc = '_SENTTOMAGENTO_ASC_',
  /** sort on SentToMagento descending */
  SenttomagentoDesc = '_SENTTOMAGENTO_DESC_',
  /** Sort on SentToWebhook ascending */
  SenttowebhookAsc = '_SENTTOWEBHOOK_ASC_',
  /** sort on SentToWebhook descending */
  SenttowebhookDesc = '_SENTTOWEBHOOK_DESC_',
  /** Sort on SentToCoredination ascending */
  SenttocoredinationAsc = '_SENTTOCOREDINATION_ASC_',
  /** sort on SentToCoredination descending */
  SenttocoredinationDesc = '_SENTTOCOREDINATION_DESC_',
  /** Sort on SentToEquipmentLoop ascending */
  SenttoequipmentloopAsc = '_SENTTOEQUIPMENTLOOP_ASC_',
  /** sort on SentToEquipmentLoop descending */
  SenttoequipmentloopDesc = '_SENTTOEQUIPMENTLOOP_DESC_',
  /** Sort on ForceSave ascending */
  ForcesaveAsc = '_FORCESAVE_ASC_',
  /** sort on ForceSave descending */
  ForcesaveDesc = '_FORCESAVE_DESC_',
  /** Sort on DoNotRetrySaveToNAV ascending */
  DonotretrysavetonavAsc = '_DONOTRETRYSAVETONAV_ASC_',
  /** sort on DoNotRetrySaveToNAV descending */
  DonotretrysavetonavDesc = '_DONOTRETRYSAVETONAV_DESC_',
  /** Sort on GlobalId ascending */
  GlobalidAsc = '_GLOBALID_ASC_',
  /** sort on GlobalId descending */
  GlobalidDesc = '_GLOBALID_DESC_',
  /** Sort on InitiatingUserEmail ascending */
  InitiatinguseremailAsc = '_INITIATINGUSEREMAIL_ASC_',
  /** sort on InitiatingUserEmail descending */
  InitiatinguseremailDesc = '_INITIATINGUSEREMAIL_DESC_',
  /** Sort on InitiatingUserName ascending */
  InitiatingusernameAsc = '_INITIATINGUSERNAME_ASC_',
  /** sort on InitiatingUserName descending */
  InitiatingusernameDesc = '_INITIATINGUSERNAME_DESC_',
  /** Sort on LastModifiedBy ascending */
  LastmodifiedbyAsc = '_LASTMODIFIEDBY_ASC_',
  /** sort on LastModifiedBy descending */
  LastmodifiedbyDesc = '_LASTMODIFIEDBY_DESC_',
  /** Sort on SystemCreatedAt ascending */
  SystemcreatedatAsc = '_SYSTEMCREATEDAT_ASC_',
  /** sort on SystemCreatedAt descending */
  SystemcreatedatDesc = '_SYSTEMCREATEDAT_DESC_',
  /** Sort on SystemModifiedAt ascending */
  SystemmodifiedatAsc = '_SYSTEMMODIFIEDAT_ASC_',
  /** sort on SystemModifiedAt descending */
  SystemmodifiedatDesc = '_SYSTEMMODIFIEDAT_DESC_'
}

export type SpecialEquipment = {
  __typename?: 'SpecialEquipment';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SpecialEquipmentConnection = {
  __typename?: 'SpecialEquipmentConnection';
  count: Scalars['Int'];
  edges: Array<SpecialEquipmentConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SpecialEquipmentConnectionEdge = {
  __typename?: 'SpecialEquipmentConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SpecialEquipment;
};

export type SpecialEquipmentDeleteResponse = {
  __typename?: 'SpecialEquipmentDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<SpecialEquipment>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SpecialEquipmentManyResult = {
  __typename?: 'SpecialEquipmentManyResult';
  records?: Maybe<Array<Maybe<SpecialEquipment>>>;
  pageData?: Maybe<PageData>;
};

export type StockkeepingUnit = {
  __typename?: 'StockkeepingUnit';
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  AssemblyBOM?: Maybe<Scalars['Boolean']>;
  ShelfNo?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  VendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  ReorderPoint?: Maybe<Scalars['Float']>;
  MaximumInventory?: Maybe<Scalars['Float']>;
  ReorderQuantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  QtyonSalesOrder?: Maybe<Scalars['Float']>;
  DropShipmentFilter?: Maybe<Scalars['Boolean']>;
  AssemblyPolicy?: Maybe<Scalars['Float']>;
  AssemblyPolicyOPTION?: Maybe<Scalars['String']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonAsmComponent?: Maybe<Scalars['Float']>;
  QtyonJobOrder?: Maybe<Scalars['Float']>;
  TransferLevelCode?: Maybe<Scalars['Float']>;
  LotSize?: Maybe<Scalars['Float']>;
  DiscreteOrderQuantity?: Maybe<Scalars['Float']>;
  MinimumOrderQuantity?: Maybe<Scalars['Float']>;
  MaximumOrderQuantity?: Maybe<Scalars['Float']>;
  SafetyStockQuantity?: Maybe<Scalars['Float']>;
  OrderMultiple?: Maybe<Scalars['Float']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  ComponentsatLocation?: Maybe<Scalars['String']>;
  FlushingMethod?: Maybe<Scalars['Float']>;
  FlushingMethodOPTION?: Maybe<Scalars['String']>;
  ReplenishmentSystem?: Maybe<Scalars['Float']>;
  ReplenishmentSystemOPTION?: Maybe<Scalars['String']>;
  ScheduledReceiptQty?: Maybe<Scalars['Float']>;
  ScheduledNeedQty?: Maybe<Scalars['Float']>;
  BinFilter?: Maybe<Scalars['String']>;
  TimeBucket?: Maybe<Scalars['String']>;
  ReorderingPolicy?: Maybe<Scalars['Float']>;
  ReorderingPolicyOPTION?: Maybe<Scalars['String']>;
  IncludeInventory?: Maybe<Scalars['Boolean']>;
  ManufacturingPolicy?: Maybe<Scalars['Float']>;
  ManufacturingPolicyOPTION?: Maybe<Scalars['String']>;
  ReschedulingPeriod?: Maybe<Scalars['String']>;
  LotAccumulationPeriod?: Maybe<Scalars['String']>;
  DampenerPeriod?: Maybe<Scalars['String']>;
  DampenerQuantity?: Maybe<Scalars['Float']>;
  OverflowLevel?: Maybe<Scalars['Float']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  TransOrdReceiptQty?: Maybe<Scalars['Float']>;
  TransOrdShipmentQty?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  SpecialEquipmentCode?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  PutawayUnitofMeasureCode?: Maybe<Scalars['String']>;
  PhysInvtCountingPeriodCode?: Maybe<Scalars['String']>;
  LastCountingPeriodUpdate?: Maybe<Scalars['DateTime']>;
  LastPhysInvtDate?: Maybe<Scalars['DateTime']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  NextCountingStartDate?: Maybe<Scalars['DateTime']>;
  NextCountingEndDate?: Maybe<Scalars['DateTime']>;
  RoutingNo?: Maybe<Scalars['String']>;
  ProductionBOMNo?: Maybe<Scalars['String']>;
  PlannedOrderReceiptQty?: Maybe<Scalars['Float']>;
  FPOrderReceiptQty?: Maybe<Scalars['Float']>;
  RelOrderReceiptQty?: Maybe<Scalars['Float']>;
  PlannedOrderReleaseQty?: Maybe<Scalars['Float']>;
  PurchReqReceiptQty?: Maybe<Scalars['Float']>;
  PurchReqReleaseQty?: Maybe<Scalars['Float']>;
  QtyonProdOrder?: Maybe<Scalars['Float']>;
  QtyonComponentLines?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type StockkeepingUnitConnection = {
  __typename?: 'StockkeepingUnitConnection';
  count: Scalars['Int'];
  edges: Array<StockkeepingUnitConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type StockkeepingUnitConnectionEdge = {
  __typename?: 'StockkeepingUnitConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: StockkeepingUnit;
};

export type StockkeepingUnitDeleteResponse = {
  __typename?: 'StockkeepingUnitDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<StockkeepingUnit>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StockkeepingUnitManyResult = {
  __typename?: 'StockkeepingUnitManyResult';
  records?: Maybe<Array<Maybe<StockkeepingUnit>>>;
  pageData?: Maybe<PageData>;
};

export type StoredFile = {
  __typename?: 'StoredFile';
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Invoked on every ping mutation */
  ping?: Maybe<Scalars['String']>;
  AccessRequestUpserted?: Maybe<AccessRequest>;
  AccessRequestRemoved?: Maybe<AccessRequestDeleteResponse>;
  AdditionalItemUpserted?: Maybe<AdditionalItem>;
  AdditionalItemRemoved?: Maybe<AdditionalItemDeleteResponse>;
  AddressUpserted?: Maybe<Address>;
  AddressRemoved?: Maybe<AddressDeleteResponse>;
  AgreementUpserted?: Maybe<Agreement>;
  AgreementRemoved?: Maybe<AgreementDeleteResponse>;
  AnnotationUpserted?: Maybe<Annotation>;
  AnnotationRemoved?: Maybe<AnnotationDeleteResponse>;
  ApplicationModuleUpserted?: Maybe<ApplicationModule>;
  ApplicationModuleRemoved?: Maybe<ApplicationModuleDeleteResponse>;
  BcMappingTableUpserted?: Maybe<BcMappingTable>;
  BcMappingTableRemoved?: Maybe<BcMappingTableDeleteResponse>;
  BcMappingFieldUpserted?: Maybe<BcMappingField>;
  BcMappingFieldRemoved?: Maybe<BcMappingFieldDeleteResponse>;
  BcSettingsUpserted?: Maybe<BcSettings>;
  BcSettingsRemoved?: Maybe<BcSettingsDeleteResponse>;
  BulkItemGroupUpserted?: Maybe<BulkItemGroup>;
  BulkItemGroupRemoved?: Maybe<BulkItemGroupDeleteResponse>;
  CauseOfAbsenceUpserted?: Maybe<CauseOfAbsence>;
  CauseOfAbsenceRemoved?: Maybe<CauseOfAbsenceDeleteResponse>;
  CompanyUpserted?: Maybe<Company>;
  CompanyRemoved?: Maybe<CompanyDeleteResponse>;
  ConsignmentHeaderUpserted?: Maybe<ConsignmentHeader>;
  ConsignmentHeaderRemoved?: Maybe<ConsignmentHeaderDeleteResponse>;
  ConsignmentLineUpserted?: Maybe<ConsignmentLine>;
  ConsignmentLineRemoved?: Maybe<ConsignmentLineDeleteResponse>;
  ContactUpserted?: Maybe<Contact>;
  ContactRemoved?: Maybe<ContactDeleteResponse>;
  CountryRegionUpserted?: Maybe<CountryRegion>;
  CountryRegionRemoved?: Maybe<CountryRegionDeleteResponse>;
  CurrencyUpserted?: Maybe<Currency>;
  CurrencyRemoved?: Maybe<CurrencyDeleteResponse>;
  CurrencyExchangeRateUpserted?: Maybe<CurrencyExchangeRate>;
  CurrencyExchangeRateRemoved?: Maybe<CurrencyExchangeRateDeleteResponse>;
  CustomerDomainUpserted?: Maybe<CustomerDomain>;
  CustomerDomainRemoved?: Maybe<CustomerDomainDeleteResponse>;
  CustomerPostingGroupUpserted?: Maybe<CustomerPostingGroup>;
  CustomerPostingGroupRemoved?: Maybe<CustomerPostingGroupDeleteResponse>;
  CustomerProjectUpserted?: Maybe<CustomerProject>;
  CustomerProjectRemoved?: Maybe<CustomerProjectDeleteResponse>;
  CustomerSubProjectUpserted?: Maybe<CustomerSubProject>;
  CustomerSubProjectRemoved?: Maybe<CustomerSubProjectDeleteResponse>;
  DeliverytimeUpserted?: Maybe<Deliverytime>;
  DeliverytimeRemoved?: Maybe<DeliverytimeDeleteResponse>;
  DevopsProjectUpserted?: Maybe<DevopsProject>;
  DevopsProjectRemoved?: Maybe<DevopsProjectDeleteResponse>;
  DevopsTeamUpserted?: Maybe<DevopsTeam>;
  DevopsTeamRemoved?: Maybe<DevopsTeamDeleteResponse>;
  DevopsWorkItemUpserted?: Maybe<DevopsWorkItem>;
  DevopsWorkItemRemoved?: Maybe<DevopsWorkItemDeleteResponse>;
  DimensionUpserted?: Maybe<Dimension>;
  DimensionRemoved?: Maybe<DimensionDeleteResponse>;
  DimensionValueUpserted?: Maybe<DimensionValue>;
  DimensionValueRemoved?: Maybe<DimensionValueDeleteResponse>;
  DiscussionPostUpserted?: Maybe<DiscussionPost>;
  DiscussionPostRemoved?: Maybe<DiscussionPostDeleteResponse>;
  DocumentClassificationUpserted?: Maybe<DocumentClassification>;
  DocumentClassificationRemoved?: Maybe<DocumentClassificationDeleteResponse>;
  DocumentRequestUpserted?: Maybe<DocumentRequest>;
  DocumentRequestRemoved?: Maybe<DocumentRequestDeleteResponse>;
  DomainSettingsUpserted?: Maybe<DomainSettings>;
  DomainSettingsRemoved?: Maybe<DomainSettingsDeleteResponse>;
  EqmBaseCalendarUpserted?: Maybe<EqmBaseCalendar>;
  EqmBaseCalendarRemoved?: Maybe<EqmBaseCalendarDeleteResponse>;
  EqmBaseCalendarChangeUpserted?: Maybe<EqmBaseCalendarChange>;
  EqmBaseCalendarChangeRemoved?: Maybe<EqmBaseCalendarChangeDeleteResponse>;
  EqmCollectionReturnChargesUpserted?: Maybe<EqmCollectionReturnCharges>;
  EqmCollectionReturnChargesRemoved?: Maybe<EqmCollectionReturnChargesDeleteResponse>;
  EqmCommentLineUpserted?: Maybe<EqmCommentLine>;
  EqmCommentLineRemoved?: Maybe<EqmCommentLineDeleteResponse>;
  EqmCustomizedCalendarChangeUpserted?: Maybe<EqmCustomizedCalendarChange>;
  EqmCustomizedCalendarChangeRemoved?: Maybe<EqmCustomizedCalendarChangeDeleteResponse>;
  EqmFncTestReturnChargesUpserted?: Maybe<EqmFncTestReturnCharges>;
  EqmFncTestReturnChargesRemoved?: Maybe<EqmFncTestReturnChargesDeleteResponse>;
  EqmLocCostCenterCombUpserted?: Maybe<EqmLocCostCenterComb>;
  EqmLocCostCenterCombRemoved?: Maybe<EqmLocCostCenterCombDeleteResponse>;
  EqmManufacturerUpserted?: Maybe<EqmManufacturer>;
  EqmManufacturerRemoved?: Maybe<EqmManufacturerDeleteResponse>;
  EqmManufacturerModelUpserted?: Maybe<EqmManufacturerModel>;
  EqmManufacturerModelRemoved?: Maybe<EqmManufacturerModelDeleteResponse>;
  EqmObjectCardFlowUpserted?: Maybe<EqmObjectCardFlow>;
  EqmObjectCardFlowRemoved?: Maybe<EqmObjectCardFlowDeleteResponse>;
  EqmObjectComponentsUpserted?: Maybe<EqmObjectComponents>;
  EqmObjectComponentsRemoved?: Maybe<EqmObjectComponentsDeleteResponse>;
  EqmObjectCountHeaderUpserted?: Maybe<EqmObjectCountHeader>;
  EqmObjectCountHeaderRemoved?: Maybe<EqmObjectCountHeaderDeleteResponse>;
  EqmObjectCountLineUpserted?: Maybe<EqmObjectCountLine>;
  EqmObjectCountLineRemoved?: Maybe<EqmObjectCountLineDeleteResponse>;
  EqmObjectGroupCardUpserted?: Maybe<EqmObjectGroupCard>;
  EqmObjectGroupCardRemoved?: Maybe<EqmObjectGroupCardDeleteResponse>;
  EqmObjectGroupFlowUpserted?: Maybe<EqmObjectGroupFlow>;
  EqmObjectGroupFlowRemoved?: Maybe<EqmObjectGroupFlowDeleteResponse>;
  EqmObjectServiceIntervalUpserted?: Maybe<EqmObjectServiceInterval>;
  EqmObjectServiceIntervalRemoved?: Maybe<EqmObjectServiceIntervalDeleteResponse>;
  EqmObjectSrvIntervalAggUpserted?: Maybe<EqmObjectSrvIntervalAgg>;
  EqmObjectSrvIntervalAggRemoved?: Maybe<EqmObjectSrvIntervalAggDeleteResponse>;
  EqmObjectStatusUpserted?: Maybe<EqmObjectStatus>;
  EqmObjectStatusRemoved?: Maybe<EqmObjectStatusDeleteResponse>;
  EqmObjectTypeCardUpserted?: Maybe<EqmObjectTypeCard>;
  EqmObjectTypeCardRemoved?: Maybe<EqmObjectTypeCardDeleteResponse>;
  EqmObjectTypeComponentsUpserted?: Maybe<EqmObjectTypeComponents>;
  EqmObjectTypeComponentsRemoved?: Maybe<EqmObjectTypeComponentsDeleteResponse>;
  EqmObjectTypeFlowUpserted?: Maybe<EqmObjectTypeFlow>;
  EqmObjectTypeFlowRemoved?: Maybe<EqmObjectTypeFlowDeleteResponse>;
  EqmObjectTypePriceTermUpserted?: Maybe<EqmObjectTypePriceTerm>;
  EqmObjectTypePriceTermRemoved?: Maybe<EqmObjectTypePriceTermDeleteResponse>;
  EqmObjectTypeServiceIntervalUpserted?: Maybe<EqmObjectTypeServiceInterval>;
  EqmObjectTypeServiceIntervalRemoved?: Maybe<EqmObjectTypeServiceIntervalDeleteResponse>;
  EqmRentalGroupUpserted?: Maybe<EqmRentalGroup>;
  EqmRentalGroupRemoved?: Maybe<EqmRentalGroupDeleteResponse>;
  EqmRentalKITUpserted?: Maybe<EqmRentalKit>;
  EqmRentalKITRemoved?: Maybe<EqmRentalKitDeleteResponse>;
  EqmRentalKITComponentsUpserted?: Maybe<EqmRentalKitComponents>;
  EqmRentalKITComponentsRemoved?: Maybe<EqmRentalKitComponentsDeleteResponse>;
  EqmRentalKITLinesUpserted?: Maybe<EqmRentalKitLines>;
  EqmRentalKITLinesRemoved?: Maybe<EqmRentalKitLinesDeleteResponse>;
  EqmRentalLineDiscountUpserted?: Maybe<EqmRentalLineDiscount>;
  EqmRentalLineDiscountRemoved?: Maybe<EqmRentalLineDiscountDeleteResponse>;
  EqmRentalPriceUpserted?: Maybe<EqmRentalPrice>;
  EqmRentalPriceRemoved?: Maybe<EqmRentalPriceDeleteResponse>;
  EqmReRentCardUpserted?: Maybe<EqmReRentCard>;
  EqmReRentCardRemoved?: Maybe<EqmReRentCardDeleteResponse>;
  EqmReturnChargesUpserted?: Maybe<EqmReturnCharges>;
  EqmReturnChargesRemoved?: Maybe<EqmReturnChargesDeleteResponse>;
  EqmServiceCardUpserted?: Maybe<EqmServiceCard>;
  EqmServiceCardRemoved?: Maybe<EqmServiceCardDeleteResponse>;
  EqmServiceTypeUpserted?: Maybe<EqmServiceType>;
  EqmServiceTypeRemoved?: Maybe<EqmServiceTypeDeleteResponse>;
  EqmTypeFunctionalTestUpserted?: Maybe<EqmTypeFunctionalTest>;
  EqmTypeFunctionalTestRemoved?: Maybe<EqmTypeFunctionalTestDeleteResponse>;
  EqmWorkHeaderUpserted?: Maybe<EqmWorkHeader>;
  EqmWorkHeaderRemoved?: Maybe<EqmWorkHeaderDeleteResponse>;
  EqmWorkLineUpserted?: Maybe<EqmWorkLine>;
  EqmWorkLineRemoved?: Maybe<EqmWorkLineDeleteResponse>;
  EqmTransferHeaderUpserted?: Maybe<EqmTransferHeader>;
  EqmTransferHeaderRemoved?: Maybe<EqmTransferHeaderDeleteResponse>;
  EqmTransferLineUpserted?: Maybe<EqmTransferLine>;
  EqmTransferLineRemoved?: Maybe<EqmTransferLineDeleteResponse>;
  ErrandUpserted?: Maybe<Errand>;
  ErrandRemoved?: Maybe<ErrandDeleteResponse>;
  ErrandLineUpserted?: Maybe<ErrandLine>;
  ErrandLineRemoved?: Maybe<ErrandLineDeleteResponse>;
  ErrorReportUpserted?: Maybe<ErrorReport>;
  ErrorReportRemoved?: Maybe<ErrorReportDeleteResponse>;
  FeedbackUpserted?: Maybe<Feedback>;
  FeedbackRemoved?: Maybe<FeedbackDeleteResponse>;
  FeemappingUpserted?: Maybe<Feemapping>;
  FeemappingRemoved?: Maybe<FeemappingDeleteResponse>;
  FieldOrderUpserted?: Maybe<FieldOrder>;
  FieldOrderRemoved?: Maybe<FieldOrderDeleteResponse>;
  FieldOrderLineUpserted?: Maybe<FieldOrderLine>;
  FieldOrderLineRemoved?: Maybe<FieldOrderLineDeleteResponse>;
  FileUpserted?: Maybe<File>;
  FileRemoved?: Maybe<FileDeleteResponse>;
  FunctionTestHeaderUpserted?: Maybe<FunctionTestHeader>;
  FunctionTestHeaderRemoved?: Maybe<FunctionTestHeaderDeleteResponse>;
  FunctionTestLineUpserted?: Maybe<FunctionTestLine>;
  FunctionTestLineRemoved?: Maybe<FunctionTestLineDeleteResponse>;
  GenBusinessPostingGroupUpserted?: Maybe<GenBusinessPostingGroup>;
  GenBusinessPostingGroupRemoved?: Maybe<GenBusinessPostingGroupDeleteResponse>;
  GenJournalLineUpserted?: Maybe<GenJournalLine>;
  GenJournalLineRemoved?: Maybe<GenJournalLineDeleteResponse>;
  GenProductPostingGroupUpserted?: Maybe<GenProductPostingGroup>;
  GenProductPostingGroupRemoved?: Maybe<GenProductPostingGroupDeleteResponse>;
  InspectionUpserted?: Maybe<Inspection>;
  InspectionRemoved?: Maybe<InspectionDeleteResponse>;
  InspectionUserUpserted?: Maybe<InspectionUser>;
  InspectionUserRemoved?: Maybe<InspectionUserDeleteResponse>;
  IntegrationCollectionUpserted?: Maybe<IntegrationCollection>;
  IntegrationCollectionRemoved?: Maybe<IntegrationCollectionDeleteResponse>;
  InventoryPostingGroupUpserted?: Maybe<InventoryPostingGroup>;
  InventoryPostingGroupRemoved?: Maybe<InventoryPostingGroupDeleteResponse>;
  InvoiceHeaderUpserted?: Maybe<InvoiceHeader>;
  InvoiceHeaderRemoved?: Maybe<InvoiceHeaderDeleteResponse>;
  InvoiceLineUpserted?: Maybe<InvoiceLine>;
  InvoiceLineRemoved?: Maybe<InvoiceLineDeleteResponse>;
  ItemUpserted?: Maybe<Item>;
  ItemRemoved?: Maybe<ItemDeleteResponse>;
  ItemCategoryUpserted?: Maybe<ItemCategory>;
  ItemCategoryRemoved?: Maybe<ItemCategoryDeleteResponse>;
  ItemDiscGroupUpserted?: Maybe<ItemDiscGroup>;
  ItemDiscGroupRemoved?: Maybe<ItemDiscGroupDeleteResponse>;
  ItemReferenceUpserted?: Maybe<ItemReference>;
  ItemReferenceRemoved?: Maybe<ItemReferenceDeleteResponse>;
  ItemRestrictionUpserted?: Maybe<ItemRestriction>;
  ItemRestrictionRemoved?: Maybe<ItemRestrictionDeleteResponse>;
  ItemUnitOfMeasureUpserted?: Maybe<ItemUnitOfMeasure>;
  ItemUnitOfMeasureRemoved?: Maybe<ItemUnitOfMeasureDeleteResponse>;
  ItemVendorUpserted?: Maybe<ItemVendor>;
  ItemVendorRemoved?: Maybe<ItemVendorDeleteResponse>;
  JobTaskUpserted?: Maybe<JobTask>;
  JobTaskRemoved?: Maybe<JobTaskDeleteResponse>;
  KliHeaderUpserted?: Maybe<KliHeader>;
  KliHeaderRemoved?: Maybe<KliHeaderDeleteResponse>;
  KliReasonCodeUpserted?: Maybe<KliReasonCode>;
  KliReasonCodeRemoved?: Maybe<KliReasonCodeDeleteResponse>;
  LocationUpserted?: Maybe<Location>;
  LocationRemoved?: Maybe<LocationDeleteResponse>;
  LeadUpserted?: Maybe<Lead>;
  LeadRemoved?: Maybe<LeadDeleteResponse>;
  MagentoOrderStatusUpserted?: Maybe<MagentoOrderStatus>;
  MagentoOrderStatusRemoved?: Maybe<MagentoOrderStatusDeleteResponse>;
  MagentoSettingsUpserted?: Maybe<MagentoSettings>;
  MagentoSettingsRemoved?: Maybe<MagentoSettingsDeleteResponse>;
  ManufacturerUpserted?: Maybe<Manufacturer>;
  ManufacturerRemoved?: Maybe<ManufacturerDeleteResponse>;
  NavUserUpserted?: Maybe<NavUser>;
  NavUserRemoved?: Maybe<NavUserDeleteResponse>;
  ObjectUpserted?: Maybe<Object>;
  ObjectRemoved?: Maybe<ObjectDeleteResponse>;
  ObjectCommentLineUpserted?: Maybe<ObjectCommentLine>;
  ObjectCommentLineRemoved?: Maybe<ObjectCommentLineDeleteResponse>;
  ObjectFamilyUpserted?: Maybe<ObjectFamily>;
  ObjectFamilyRemoved?: Maybe<ObjectFamilyDeleteResponse>;
  ObjectGroupUpserted?: Maybe<ObjectGroup>;
  ObjectGroupRemoved?: Maybe<ObjectGroupDeleteResponse>;
  ObjectReturnHeaderUpserted?: Maybe<ObjectReturnHeader>;
  ObjectReturnHeaderRemoved?: Maybe<ObjectReturnHeaderDeleteResponse>;
  ObjectReturnLineUpserted?: Maybe<ObjectReturnLine>;
  ObjectReturnLineRemoved?: Maybe<ObjectReturnLineDeleteResponse>;
  ObjectTypeUpserted?: Maybe<ObjectType>;
  ObjectTypeRemoved?: Maybe<ObjectTypeDeleteResponse>;
  OcrAnalysisUpserted?: Maybe<OcrAnalysis>;
  OcrAnalysisRemoved?: Maybe<OcrAnalysisDeleteResponse>;
  PaymentMethodUpserted?: Maybe<PaymentMethod>;
  PaymentMethodRemoved?: Maybe<PaymentMethodDeleteResponse>;
  PaymentTermsUpserted?: Maybe<PaymentTerms>;
  PaymentTermsRemoved?: Maybe<PaymentTermsDeleteResponse>;
  PlatformFeatureUpserted?: Maybe<PlatformFeature>;
  PlatformFeatureRemoved?: Maybe<PlatformFeatureDeleteResponse>;
  PostCodeUpserted?: Maybe<PostCode>;
  PostCodeRemoved?: Maybe<PostCodeDeleteResponse>;
  ProjectUpserted?: Maybe<Project>;
  ProjectRemoved?: Maybe<ProjectDeleteResponse>;
  PurchaseHeaderUpserted?: Maybe<PurchaseHeader>;
  PurchaseHeaderRemoved?: Maybe<PurchaseHeaderDeleteResponse>;
  PurchaseLineUpserted?: Maybe<PurchaseLine>;
  PurchaseLineRemoved?: Maybe<PurchaseLineDeleteResponse>;
  PurchasingUpserted?: Maybe<Purchasing>;
  PurchasingRemoved?: Maybe<PurchasingDeleteResponse>;
  PurchCrMemoHeaderUpserted?: Maybe<PurchCrMemoHeader>;
  PurchCrMemoHeaderRemoved?: Maybe<PurchCrMemoHeaderDeleteResponse>;
  PurchCrMemoLineUpserted?: Maybe<PurchCrMemoLine>;
  PurchCrMemoLineRemoved?: Maybe<PurchCrMemoLineDeleteResponse>;
  PurchInvHeaderUpserted?: Maybe<PurchInvHeader>;
  PurchInvHeaderRemoved?: Maybe<PurchInvHeaderDeleteResponse>;
  PurchInvLineUpserted?: Maybe<PurchInvLine>;
  PurchInvLineRemoved?: Maybe<PurchInvLineDeleteResponse>;
  PurchRcptHeaderUpserted?: Maybe<PurchRcptHeader>;
  PurchRcptHeaderRemoved?: Maybe<PurchRcptHeaderDeleteResponse>;
  PurchRcptLineUpserted?: Maybe<PurchRcptLine>;
  PurchRcptLineRemoved?: Maybe<PurchRcptLineDeleteResponse>;
  QuoteHeaderUpserted?: Maybe<QuoteHeader>;
  QuoteHeaderRemoved?: Maybe<QuoteHeaderDeleteResponse>;
  QuoteLineUpserted?: Maybe<QuoteLine>;
  QuoteLineRemoved?: Maybe<QuoteLineDeleteResponse>;
  QuoteTaskGroupUpserted?: Maybe<QuoteTaskGroup>;
  QuoteTaskGroupRemoved?: Maybe<QuoteTaskGroupDeleteResponse>;
  RentalHeaderUpserted?: Maybe<RentalHeader>;
  RentalHeaderRemoved?: Maybe<RentalHeaderDeleteResponse>;
  RentalLineUpserted?: Maybe<RentalLine>;
  RentalLineRemoved?: Maybe<RentalLineDeleteResponse>;
  RentalLineSnapshotUpserted?: Maybe<RentalLineSnapshot>;
  RentalLineSnapshotRemoved?: Maybe<RentalLineSnapshotDeleteResponse>;
  RentalPostedCollHeaderUpserted?: Maybe<RentalPostedCollHeader>;
  RentalPostedCollHeaderRemoved?: Maybe<RentalPostedCollHeaderDeleteResponse>;
  RentalPostedCollLineUpserted?: Maybe<RentalPostedCollLine>;
  RentalPostedCollLineRemoved?: Maybe<RentalPostedCollLineDeleteResponse>;
  RentalPostedShptHeaderUpserted?: Maybe<RentalPostedShptHeader>;
  RentalPostedShptHeaderRemoved?: Maybe<RentalPostedShptHeaderDeleteResponse>;
  RentalPostedShptLineUpserted?: Maybe<RentalPostedShptLine>;
  RentalPostedShptLineRemoved?: Maybe<RentalPostedShptLineDeleteResponse>;
  RentalPriceTermsUpserted?: Maybe<RentalPriceTerms>;
  RentalPriceTermsRemoved?: Maybe<RentalPriceTermsDeleteResponse>;
  RentalReturnEntryUpserted?: Maybe<RentalReturnEntry>;
  RentalReturnEntryRemoved?: Maybe<RentalReturnEntryDeleteResponse>;
  RequestHeaderUpserted?: Maybe<RequestHeader>;
  RequestHeaderRemoved?: Maybe<RequestHeaderDeleteResponse>;
  RequestLineUpserted?: Maybe<RequestLine>;
  RequestLineRemoved?: Maybe<RequestLineDeleteResponse>;
  ReservationRequestHeaderUpserted?: Maybe<ReservationRequestHeader>;
  ReservationRequestHeaderRemoved?: Maybe<ReservationRequestHeaderDeleteResponse>;
  ReservationRequestLineUpserted?: Maybe<ReservationRequestLine>;
  ReservationRequestLineRemoved?: Maybe<ReservationRequestLineDeleteResponse>;
  ResourceUpserted?: Maybe<Resource>;
  ResourceRemoved?: Maybe<ResourceDeleteResponse>;
  ResponsibilityCenterUpserted?: Maybe<ResponsibilityCenter>;
  ResponsibilityCenterRemoved?: Maybe<ResponsibilityCenterDeleteResponse>;
  ReturnRequestHeaderUpserted?: Maybe<ReturnRequestHeader>;
  ReturnRequestHeaderRemoved?: Maybe<ReturnRequestHeaderDeleteResponse>;
  ReturnRequestLineUpserted?: Maybe<ReturnRequestLine>;
  ReturnRequestLineRemoved?: Maybe<ReturnRequestLineDeleteResponse>;
  RiskAnalysisHeaderUpserted?: Maybe<RiskAnalysisHeader>;
  RiskAnalysisHeaderRemoved?: Maybe<RiskAnalysisHeaderDeleteResponse>;
  RiskAnalysisLineUpserted?: Maybe<RiskAnalysisLine>;
  RiskAnalysisLineRemoved?: Maybe<RiskAnalysisLineDeleteResponse>;
  RiskAnalysisSignatureUpserted?: Maybe<RiskAnalysisSignature>;
  RiskAnalysisSignatureRemoved?: Maybe<RiskAnalysisSignatureDeleteResponse>;
  SalesHeaderUpserted?: Maybe<SalesHeader>;
  SalesHeaderRemoved?: Maybe<SalesHeaderDeleteResponse>;
  SalesHeaderArchiveUpserted?: Maybe<SalesHeaderArchive>;
  SalesHeaderArchiveRemoved?: Maybe<SalesHeaderArchiveDeleteResponse>;
  SalesHeaderHistoryUpserted?: Maybe<SalesHeaderHistory>;
  SalesHeaderHistoryRemoved?: Maybe<SalesHeaderHistoryDeleteResponse>;
  SalesHeaderRequestUpserted?: Maybe<SalesHeaderRequest>;
  SalesHeaderRequestRemoved?: Maybe<SalesHeaderRequestDeleteResponse>;
  SalesLineUpserted?: Maybe<SalesLine>;
  SalesLineRemoved?: Maybe<SalesLineDeleteResponse>;
  SalesLineArchiveUpserted?: Maybe<SalesLineArchive>;
  SalesLineArchiveRemoved?: Maybe<SalesLineArchiveDeleteResponse>;
  SalesLineRequestUpserted?: Maybe<SalesLineRequest>;
  SalesLineRequestRemoved?: Maybe<SalesLineRequestDeleteResponse>;
  SalespersonUpserted?: Maybe<Salesperson>;
  SalespersonRemoved?: Maybe<SalespersonDeleteResponse>;
  SalesPriceUpserted?: Maybe<SalesPrice>;
  SalesPriceRemoved?: Maybe<SalesPriceDeleteResponse>;
  SalesShipmentHeaderUpserted?: Maybe<SalesShipmentHeader>;
  SalesShipmentHeaderRemoved?: Maybe<SalesShipmentHeaderDeleteResponse>;
  SalesShipmentLineUpserted?: Maybe<SalesShipmentLine>;
  SalesShipmentLineRemoved?: Maybe<SalesShipmentLineDeleteResponse>;
  ServiceItemGroupUpserted?: Maybe<ServiceItemGroup>;
  ServiceItemGroupRemoved?: Maybe<ServiceItemGroupDeleteResponse>;
  ShipmentMethodUpserted?: Maybe<ShipmentMethod>;
  ShipmentMethodRemoved?: Maybe<ShipmentMethodDeleteResponse>;
  ShippingAgentUpserted?: Maybe<ShippingAgent>;
  ShippingAgentRemoved?: Maybe<ShippingAgentDeleteResponse>;
  SignatureUpserted?: Maybe<Signature>;
  SignatureRemoved?: Maybe<SignatureDeleteResponse>;
  SignRequestUpserted?: Maybe<SignRequest>;
  SignRequestRemoved?: Maybe<SignRequestDeleteResponse>;
  SpecialEquipmentUpserted?: Maybe<SpecialEquipment>;
  SpecialEquipmentRemoved?: Maybe<SpecialEquipmentDeleteResponse>;
  StockkeepingUnitUpserted?: Maybe<StockkeepingUnit>;
  StockkeepingUnitRemoved?: Maybe<StockkeepingUnitDeleteResponse>;
  TariffNumberUpserted?: Maybe<TariffNumber>;
  TariffNumberRemoved?: Maybe<TariffNumberDeleteResponse>;
  TaxGroupUpserted?: Maybe<TaxGroup>;
  TaxGroupRemoved?: Maybe<TaxGroupDeleteResponse>;
  TimeRegistrationUpserted?: Maybe<TimeRegistration>;
  TimeRegistrationRemoved?: Maybe<TimeRegistrationDeleteResponse>;
  TimeSheetDetailUpserted?: Maybe<TimeSheetDetail>;
  TimeSheetDetailRemoved?: Maybe<TimeSheetDetailDeleteResponse>;
  TimeSheetLineUpserted?: Maybe<TimeSheetLine>;
  TimeSheetLineRemoved?: Maybe<TimeSheetLineDeleteResponse>;
  TransactionSpecificationUpserted?: Maybe<TransactionSpecification>;
  TransactionSpecificationRemoved?: Maybe<TransactionSpecificationDeleteResponse>;
  TransactionTypeUpserted?: Maybe<TransactionType>;
  TransactionTypeRemoved?: Maybe<TransactionTypeDeleteResponse>;
  TransportMethodUpserted?: Maybe<TransportMethod>;
  TransportMethodRemoved?: Maybe<TransportMethodDeleteResponse>;
  UnitOfMeasureUpserted?: Maybe<UnitOfMeasure>;
  UnitOfMeasureRemoved?: Maybe<UnitOfMeasureDeleteResponse>;
  UserUpserted?: Maybe<User>;
  UserRemoved?: Maybe<UserDeleteResponse>;
  UserFavoritesUpserted?: Maybe<UserFavorites>;
  UserFavoritesRemoved?: Maybe<UserFavoritesDeleteResponse>;
  VatBusinessPostingGroupUpserted?: Maybe<VatBusinessPostingGroup>;
  VatBusinessPostingGroupRemoved?: Maybe<VatBusinessPostingGroupDeleteResponse>;
  VatProductPostingGroupUpserted?: Maybe<VatProductPostingGroup>;
  VatProductPostingGroupRemoved?: Maybe<VatProductPostingGroupDeleteResponse>;
  VendorUpserted?: Maybe<Vendor>;
  VendorRemoved?: Maybe<VendorDeleteResponse>;
  VendorBankAccountUpserted?: Maybe<VendorBankAccount>;
  VendorBankAccountRemoved?: Maybe<VendorBankAccountDeleteResponse>;
  VendorPostingGroupUpserted?: Maybe<VendorPostingGroup>;
  VendorPostingGroupRemoved?: Maybe<VendorPostingGroupDeleteResponse>;
  WarehouseClassUpserted?: Maybe<WarehouseClass>;
  WarehouseClassRemoved?: Maybe<WarehouseClassDeleteResponse>;
  WebBookingUpserted?: Maybe<WebBooking>;
  WebBookingRemoved?: Maybe<WebBookingDeleteResponse>;
  WebhookSubscriptionUpserted?: Maybe<WebhookSubscription>;
  WebhookSubscriptionRemoved?: Maybe<WebhookSubscriptionDeleteResponse>;
  WorkLogUpserted?: Maybe<WorkLog>;
  WorkLogRemoved?: Maybe<WorkLogDeleteResponse>;
  WorkTypeUpserted?: Maybe<WorkType>;
  WorkTypeRemoved?: Maybe<WorkTypeDeleteResponse>;
  DomainIntegrationUpserted?: Maybe<DomainIntegration>;
  DomainIntegrationRemoved?: Maybe<DomainIntegrationDeleteResponse>;
};


export type SubscriptionAccessRequestUpsertedArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
};


export type SubscriptionAccessRequestRemovedArgs = {
  filter?: Maybe<FilterAccessRequestInput>;
};


export type SubscriptionAdditionalItemUpsertedArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
};


export type SubscriptionAdditionalItemRemovedArgs = {
  filter?: Maybe<FilterAdditionalItemInput>;
};


export type SubscriptionAddressUpsertedArgs = {
  filter?: Maybe<FilterAddressInput>;
};


export type SubscriptionAddressRemovedArgs = {
  filter?: Maybe<FilterAddressInput>;
};


export type SubscriptionAgreementUpsertedArgs = {
  filter?: Maybe<FilterAgreementInput>;
};


export type SubscriptionAgreementRemovedArgs = {
  filter?: Maybe<FilterAgreementInput>;
};


export type SubscriptionAnnotationUpsertedArgs = {
  filter?: Maybe<FilterAnnotationInput>;
};


export type SubscriptionAnnotationRemovedArgs = {
  filter?: Maybe<FilterAnnotationInput>;
};


export type SubscriptionApplicationModuleUpsertedArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
};


export type SubscriptionApplicationModuleRemovedArgs = {
  filter?: Maybe<FilterApplicationModuleInput>;
};


export type SubscriptionBcMappingTableUpsertedArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
};


export type SubscriptionBcMappingTableRemovedArgs = {
  filter?: Maybe<FilterBcMappingTableInput>;
};


export type SubscriptionBcMappingFieldUpsertedArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
};


export type SubscriptionBcMappingFieldRemovedArgs = {
  filter?: Maybe<FilterBcMappingFieldInput>;
};


export type SubscriptionBcSettingsUpsertedArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
};


export type SubscriptionBcSettingsRemovedArgs = {
  filter?: Maybe<FilterBcSettingsInput>;
};


export type SubscriptionBulkItemGroupUpsertedArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
};


export type SubscriptionBulkItemGroupRemovedArgs = {
  filter?: Maybe<FilterBulkItemGroupInput>;
};


export type SubscriptionCauseOfAbsenceUpsertedArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
};


export type SubscriptionCauseOfAbsenceRemovedArgs = {
  filter?: Maybe<FilterCauseOfAbsenceInput>;
};


export type SubscriptionCompanyUpsertedArgs = {
  filter?: Maybe<FilterCompanyInput>;
};


export type SubscriptionCompanyRemovedArgs = {
  filter?: Maybe<FilterCompanyInput>;
};


export type SubscriptionConsignmentHeaderUpsertedArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
};


export type SubscriptionConsignmentHeaderRemovedArgs = {
  filter?: Maybe<FilterConsignmentHeaderInput>;
};


export type SubscriptionConsignmentLineUpsertedArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
};


export type SubscriptionConsignmentLineRemovedArgs = {
  filter?: Maybe<FilterConsignmentLineInput>;
};


export type SubscriptionContactUpsertedArgs = {
  filter?: Maybe<FilterContactInput>;
};


export type SubscriptionContactRemovedArgs = {
  filter?: Maybe<FilterContactInput>;
};


export type SubscriptionCountryRegionUpsertedArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
};


export type SubscriptionCountryRegionRemovedArgs = {
  filter?: Maybe<FilterCountryRegionInput>;
};


export type SubscriptionCurrencyUpsertedArgs = {
  filter?: Maybe<FilterCurrencyInput>;
};


export type SubscriptionCurrencyRemovedArgs = {
  filter?: Maybe<FilterCurrencyInput>;
};


export type SubscriptionCurrencyExchangeRateUpsertedArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
};


export type SubscriptionCurrencyExchangeRateRemovedArgs = {
  filter?: Maybe<FilterCurrencyExchangeRateInput>;
};


export type SubscriptionCustomerDomainUpsertedArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
};


export type SubscriptionCustomerDomainRemovedArgs = {
  filter?: Maybe<FilterCustomerDomainInput>;
};


export type SubscriptionCustomerPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
};


export type SubscriptionCustomerPostingGroupRemovedArgs = {
  filter?: Maybe<FilterCustomerPostingGroupInput>;
};


export type SubscriptionCustomerProjectUpsertedArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
};


export type SubscriptionCustomerProjectRemovedArgs = {
  filter?: Maybe<FilterCustomerProjectInput>;
};


export type SubscriptionCustomerSubProjectUpsertedArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
};


export type SubscriptionCustomerSubProjectRemovedArgs = {
  filter?: Maybe<FilterCustomerSubProjectInput>;
};


export type SubscriptionDeliverytimeUpsertedArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
};


export type SubscriptionDeliverytimeRemovedArgs = {
  filter?: Maybe<FilterDeliverytimeInput>;
};


export type SubscriptionDevopsProjectUpsertedArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
};


export type SubscriptionDevopsProjectRemovedArgs = {
  filter?: Maybe<FilterDevopsProjectInput>;
};


export type SubscriptionDevopsTeamUpsertedArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
};


export type SubscriptionDevopsTeamRemovedArgs = {
  filter?: Maybe<FilterDevopsTeamInput>;
};


export type SubscriptionDevopsWorkItemUpsertedArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
};


export type SubscriptionDevopsWorkItemRemovedArgs = {
  filter?: Maybe<FilterDevopsWorkItemInput>;
};


export type SubscriptionDimensionUpsertedArgs = {
  filter?: Maybe<FilterDimensionInput>;
};


export type SubscriptionDimensionRemovedArgs = {
  filter?: Maybe<FilterDimensionInput>;
};


export type SubscriptionDimensionValueUpsertedArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
};


export type SubscriptionDimensionValueRemovedArgs = {
  filter?: Maybe<FilterDimensionValueInput>;
};


export type SubscriptionDiscussionPostUpsertedArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
};


export type SubscriptionDiscussionPostRemovedArgs = {
  filter?: Maybe<FilterDiscussionPostInput>;
};


export type SubscriptionDocumentClassificationUpsertedArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
};


export type SubscriptionDocumentClassificationRemovedArgs = {
  filter?: Maybe<FilterDocumentClassificationInput>;
};


export type SubscriptionDocumentRequestUpsertedArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
};


export type SubscriptionDocumentRequestRemovedArgs = {
  filter?: Maybe<FilterDocumentRequestInput>;
};


export type SubscriptionDomainSettingsUpsertedArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
};


export type SubscriptionDomainSettingsRemovedArgs = {
  filter?: Maybe<FilterDomainSettingsInput>;
};


export type SubscriptionEqmBaseCalendarUpsertedArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
};


export type SubscriptionEqmBaseCalendarRemovedArgs = {
  filter?: Maybe<FilterEqmBaseCalendarInput>;
};


export type SubscriptionEqmBaseCalendarChangeUpsertedArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
};


export type SubscriptionEqmBaseCalendarChangeRemovedArgs = {
  filter?: Maybe<FilterEqmBaseCalendarChangeInput>;
};


export type SubscriptionEqmCollectionReturnChargesUpsertedArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
};


export type SubscriptionEqmCollectionReturnChargesRemovedArgs = {
  filter?: Maybe<FilterEqmCollectionReturnChargesInput>;
};


export type SubscriptionEqmCommentLineUpsertedArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
};


export type SubscriptionEqmCommentLineRemovedArgs = {
  filter?: Maybe<FilterEqmCommentLineInput>;
};


export type SubscriptionEqmCustomizedCalendarChangeUpsertedArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};


export type SubscriptionEqmCustomizedCalendarChangeRemovedArgs = {
  filter?: Maybe<FilterEqmCustomizedCalendarChangeInput>;
};


export type SubscriptionEqmFncTestReturnChargesUpsertedArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
};


export type SubscriptionEqmFncTestReturnChargesRemovedArgs = {
  filter?: Maybe<FilterEqmFncTestReturnChargesInput>;
};


export type SubscriptionEqmLocCostCenterCombUpsertedArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
};


export type SubscriptionEqmLocCostCenterCombRemovedArgs = {
  filter?: Maybe<FilterEqmLocCostCenterCombInput>;
};


export type SubscriptionEqmManufacturerUpsertedArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
};


export type SubscriptionEqmManufacturerRemovedArgs = {
  filter?: Maybe<FilterEqmManufacturerInput>;
};


export type SubscriptionEqmManufacturerModelUpsertedArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
};


export type SubscriptionEqmManufacturerModelRemovedArgs = {
  filter?: Maybe<FilterEqmManufacturerModelInput>;
};


export type SubscriptionEqmObjectCardFlowUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
};


export type SubscriptionEqmObjectCardFlowRemovedArgs = {
  filter?: Maybe<FilterEqmObjectCardFlowInput>;
};


export type SubscriptionEqmObjectComponentsUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
};


export type SubscriptionEqmObjectComponentsRemovedArgs = {
  filter?: Maybe<FilterEqmObjectComponentsInput>;
};


export type SubscriptionEqmObjectCountHeaderUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
};


export type SubscriptionEqmObjectCountHeaderRemovedArgs = {
  filter?: Maybe<FilterEqmObjectCountHeaderInput>;
};


export type SubscriptionEqmObjectCountLineUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
};


export type SubscriptionEqmObjectCountLineRemovedArgs = {
  filter?: Maybe<FilterEqmObjectCountLineInput>;
};


export type SubscriptionEqmObjectGroupCardUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
};


export type SubscriptionEqmObjectGroupCardRemovedArgs = {
  filter?: Maybe<FilterEqmObjectGroupCardInput>;
};


export type SubscriptionEqmObjectGroupFlowUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
};


export type SubscriptionEqmObjectGroupFlowRemovedArgs = {
  filter?: Maybe<FilterEqmObjectGroupFlowInput>;
};


export type SubscriptionEqmObjectServiceIntervalUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
};


export type SubscriptionEqmObjectServiceIntervalRemovedArgs = {
  filter?: Maybe<FilterEqmObjectServiceIntervalInput>;
};


export type SubscriptionEqmObjectSrvIntervalAggUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};


export type SubscriptionEqmObjectSrvIntervalAggRemovedArgs = {
  filter?: Maybe<FilterEqmObjectSrvIntervalAggInput>;
};


export type SubscriptionEqmObjectStatusUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
};


export type SubscriptionEqmObjectStatusRemovedArgs = {
  filter?: Maybe<FilterEqmObjectStatusInput>;
};


export type SubscriptionEqmObjectTypeCardUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
};


export type SubscriptionEqmObjectTypeCardRemovedArgs = {
  filter?: Maybe<FilterEqmObjectTypeCardInput>;
};


export type SubscriptionEqmObjectTypeComponentsUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
};


export type SubscriptionEqmObjectTypeComponentsRemovedArgs = {
  filter?: Maybe<FilterEqmObjectTypeComponentsInput>;
};


export type SubscriptionEqmObjectTypeFlowUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
};


export type SubscriptionEqmObjectTypeFlowRemovedArgs = {
  filter?: Maybe<FilterEqmObjectTypeFlowInput>;
};


export type SubscriptionEqmObjectTypePriceTermUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
};


export type SubscriptionEqmObjectTypePriceTermRemovedArgs = {
  filter?: Maybe<FilterEqmObjectTypePriceTermInput>;
};


export type SubscriptionEqmObjectTypeServiceIntervalUpsertedArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};


export type SubscriptionEqmObjectTypeServiceIntervalRemovedArgs = {
  filter?: Maybe<FilterEqmObjectTypeServiceIntervalInput>;
};


export type SubscriptionEqmRentalGroupUpsertedArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
};


export type SubscriptionEqmRentalGroupRemovedArgs = {
  filter?: Maybe<FilterEqmRentalGroupInput>;
};


export type SubscriptionEqmRentalKitUpsertedArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
};


export type SubscriptionEqmRentalKitRemovedArgs = {
  filter?: Maybe<FilterEqmRentalKitInput>;
};


export type SubscriptionEqmRentalKitComponentsUpsertedArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
};


export type SubscriptionEqmRentalKitComponentsRemovedArgs = {
  filter?: Maybe<FilterEqmRentalKitComponentsInput>;
};


export type SubscriptionEqmRentalKitLinesUpsertedArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
};


export type SubscriptionEqmRentalKitLinesRemovedArgs = {
  filter?: Maybe<FilterEqmRentalKitLinesInput>;
};


export type SubscriptionEqmRentalLineDiscountUpsertedArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
};


export type SubscriptionEqmRentalLineDiscountRemovedArgs = {
  filter?: Maybe<FilterEqmRentalLineDiscountInput>;
};


export type SubscriptionEqmRentalPriceUpsertedArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
};


export type SubscriptionEqmRentalPriceRemovedArgs = {
  filter?: Maybe<FilterEqmRentalPriceInput>;
};


export type SubscriptionEqmReRentCardUpsertedArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
};


export type SubscriptionEqmReRentCardRemovedArgs = {
  filter?: Maybe<FilterEqmReRentCardInput>;
};


export type SubscriptionEqmReturnChargesUpsertedArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
};


export type SubscriptionEqmReturnChargesRemovedArgs = {
  filter?: Maybe<FilterEqmReturnChargesInput>;
};


export type SubscriptionEqmServiceCardUpsertedArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
};


export type SubscriptionEqmServiceCardRemovedArgs = {
  filter?: Maybe<FilterEqmServiceCardInput>;
};


export type SubscriptionEqmServiceTypeUpsertedArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
};


export type SubscriptionEqmServiceTypeRemovedArgs = {
  filter?: Maybe<FilterEqmServiceTypeInput>;
};


export type SubscriptionEqmTypeFunctionalTestUpsertedArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
};


export type SubscriptionEqmTypeFunctionalTestRemovedArgs = {
  filter?: Maybe<FilterEqmTypeFunctionalTestInput>;
};


export type SubscriptionEqmWorkHeaderUpsertedArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
};


export type SubscriptionEqmWorkHeaderRemovedArgs = {
  filter?: Maybe<FilterEqmWorkHeaderInput>;
};


export type SubscriptionEqmWorkLineUpsertedArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
};


export type SubscriptionEqmWorkLineRemovedArgs = {
  filter?: Maybe<FilterEqmWorkLineInput>;
};


export type SubscriptionEqmTransferHeaderUpsertedArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
};


export type SubscriptionEqmTransferHeaderRemovedArgs = {
  filter?: Maybe<FilterEqmTransferHeaderInput>;
};


export type SubscriptionEqmTransferLineUpsertedArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
};


export type SubscriptionEqmTransferLineRemovedArgs = {
  filter?: Maybe<FilterEqmTransferLineInput>;
};


export type SubscriptionErrandUpsertedArgs = {
  filter?: Maybe<FilterErrandInput>;
};


export type SubscriptionErrandRemovedArgs = {
  filter?: Maybe<FilterErrandInput>;
};


export type SubscriptionErrandLineUpsertedArgs = {
  filter?: Maybe<FilterErrandLineInput>;
};


export type SubscriptionErrandLineRemovedArgs = {
  filter?: Maybe<FilterErrandLineInput>;
};


export type SubscriptionErrorReportUpsertedArgs = {
  filter?: Maybe<FilterErrorReportInput>;
};


export type SubscriptionErrorReportRemovedArgs = {
  filter?: Maybe<FilterErrorReportInput>;
};


export type SubscriptionFeedbackUpsertedArgs = {
  filter?: Maybe<FilterFeedbackInput>;
};


export type SubscriptionFeedbackRemovedArgs = {
  filter?: Maybe<FilterFeedbackInput>;
};


export type SubscriptionFeemappingUpsertedArgs = {
  filter?: Maybe<FilterFeemappingInput>;
};


export type SubscriptionFeemappingRemovedArgs = {
  filter?: Maybe<FilterFeemappingInput>;
};


export type SubscriptionFieldOrderUpsertedArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
};


export type SubscriptionFieldOrderRemovedArgs = {
  filter?: Maybe<FilterFieldOrderInput>;
};


export type SubscriptionFieldOrderLineUpsertedArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
};


export type SubscriptionFieldOrderLineRemovedArgs = {
  filter?: Maybe<FilterFieldOrderLineInput>;
};


export type SubscriptionFileUpsertedArgs = {
  filter?: Maybe<FilterFileInput>;
};


export type SubscriptionFileRemovedArgs = {
  filter?: Maybe<FilterFileInput>;
};


export type SubscriptionFunctionTestHeaderUpsertedArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
};


export type SubscriptionFunctionTestHeaderRemovedArgs = {
  filter?: Maybe<FilterFunctionTestHeaderInput>;
};


export type SubscriptionFunctionTestLineUpsertedArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
};


export type SubscriptionFunctionTestLineRemovedArgs = {
  filter?: Maybe<FilterFunctionTestLineInput>;
};


export type SubscriptionGenBusinessPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
};


export type SubscriptionGenBusinessPostingGroupRemovedArgs = {
  filter?: Maybe<FilterGenBusinessPostingGroupInput>;
};


export type SubscriptionGenJournalLineUpsertedArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
};


export type SubscriptionGenJournalLineRemovedArgs = {
  filter?: Maybe<FilterGenJournalLineInput>;
};


export type SubscriptionGenProductPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
};


export type SubscriptionGenProductPostingGroupRemovedArgs = {
  filter?: Maybe<FilterGenProductPostingGroupInput>;
};


export type SubscriptionInspectionUpsertedArgs = {
  filter?: Maybe<FilterInspectionInput>;
};


export type SubscriptionInspectionRemovedArgs = {
  filter?: Maybe<FilterInspectionInput>;
};


export type SubscriptionInspectionUserUpsertedArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
};


export type SubscriptionInspectionUserRemovedArgs = {
  filter?: Maybe<FilterInspectionUserInput>;
};


export type SubscriptionIntegrationCollectionUpsertedArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
};


export type SubscriptionIntegrationCollectionRemovedArgs = {
  filter?: Maybe<FilterIntegrationCollectionInput>;
};


export type SubscriptionInventoryPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
};


export type SubscriptionInventoryPostingGroupRemovedArgs = {
  filter?: Maybe<FilterInventoryPostingGroupInput>;
};


export type SubscriptionInvoiceHeaderUpsertedArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
};


export type SubscriptionInvoiceHeaderRemovedArgs = {
  filter?: Maybe<FilterInvoiceHeaderInput>;
};


export type SubscriptionInvoiceLineUpsertedArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
};


export type SubscriptionInvoiceLineRemovedArgs = {
  filter?: Maybe<FilterInvoiceLineInput>;
};


export type SubscriptionItemUpsertedArgs = {
  filter?: Maybe<FilterItemInput>;
};


export type SubscriptionItemRemovedArgs = {
  filter?: Maybe<FilterItemInput>;
};


export type SubscriptionItemCategoryUpsertedArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
};


export type SubscriptionItemCategoryRemovedArgs = {
  filter?: Maybe<FilterItemCategoryInput>;
};


export type SubscriptionItemDiscGroupUpsertedArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
};


export type SubscriptionItemDiscGroupRemovedArgs = {
  filter?: Maybe<FilterItemDiscGroupInput>;
};


export type SubscriptionItemReferenceUpsertedArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
};


export type SubscriptionItemReferenceRemovedArgs = {
  filter?: Maybe<FilterItemReferenceInput>;
};


export type SubscriptionItemRestrictionUpsertedArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
};


export type SubscriptionItemRestrictionRemovedArgs = {
  filter?: Maybe<FilterItemRestrictionInput>;
};


export type SubscriptionItemUnitOfMeasureUpsertedArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
};


export type SubscriptionItemUnitOfMeasureRemovedArgs = {
  filter?: Maybe<FilterItemUnitOfMeasureInput>;
};


export type SubscriptionItemVendorUpsertedArgs = {
  filter?: Maybe<FilterItemVendorInput>;
};


export type SubscriptionItemVendorRemovedArgs = {
  filter?: Maybe<FilterItemVendorInput>;
};


export type SubscriptionJobTaskUpsertedArgs = {
  filter?: Maybe<FilterJobTaskInput>;
};


export type SubscriptionJobTaskRemovedArgs = {
  filter?: Maybe<FilterJobTaskInput>;
};


export type SubscriptionKliHeaderUpsertedArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
};


export type SubscriptionKliHeaderRemovedArgs = {
  filter?: Maybe<FilterKliHeaderInput>;
};


export type SubscriptionKliReasonCodeUpsertedArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
};


export type SubscriptionKliReasonCodeRemovedArgs = {
  filter?: Maybe<FilterKliReasonCodeInput>;
};


export type SubscriptionLocationUpsertedArgs = {
  filter?: Maybe<FilterLocationInput>;
};


export type SubscriptionLocationRemovedArgs = {
  filter?: Maybe<FilterLocationInput>;
};


export type SubscriptionLeadUpsertedArgs = {
  filter?: Maybe<FilterLeadInput>;
};


export type SubscriptionLeadRemovedArgs = {
  filter?: Maybe<FilterLeadInput>;
};


export type SubscriptionMagentoOrderStatusUpsertedArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
};


export type SubscriptionMagentoOrderStatusRemovedArgs = {
  filter?: Maybe<FilterMagentoOrderStatusInput>;
};


export type SubscriptionMagentoSettingsUpsertedArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
};


export type SubscriptionMagentoSettingsRemovedArgs = {
  filter?: Maybe<FilterMagentoSettingsInput>;
};


export type SubscriptionManufacturerUpsertedArgs = {
  filter?: Maybe<FilterManufacturerInput>;
};


export type SubscriptionManufacturerRemovedArgs = {
  filter?: Maybe<FilterManufacturerInput>;
};


export type SubscriptionNavUserUpsertedArgs = {
  filter?: Maybe<FilterNavUserInput>;
};


export type SubscriptionNavUserRemovedArgs = {
  filter?: Maybe<FilterNavUserInput>;
};


export type SubscriptionObjectUpsertedArgs = {
  filter?: Maybe<FilterObjectInput>;
};


export type SubscriptionObjectRemovedArgs = {
  filter?: Maybe<FilterObjectInput>;
};


export type SubscriptionObjectCommentLineUpsertedArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
};


export type SubscriptionObjectCommentLineRemovedArgs = {
  filter?: Maybe<FilterObjectCommentLineInput>;
};


export type SubscriptionObjectFamilyUpsertedArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
};


export type SubscriptionObjectFamilyRemovedArgs = {
  filter?: Maybe<FilterObjectFamilyInput>;
};


export type SubscriptionObjectGroupUpsertedArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
};


export type SubscriptionObjectGroupRemovedArgs = {
  filter?: Maybe<FilterObjectGroupInput>;
};


export type SubscriptionObjectReturnHeaderUpsertedArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
};


export type SubscriptionObjectReturnHeaderRemovedArgs = {
  filter?: Maybe<FilterObjectReturnHeaderInput>;
};


export type SubscriptionObjectReturnLineUpsertedArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
};


export type SubscriptionObjectReturnLineRemovedArgs = {
  filter?: Maybe<FilterObjectReturnLineInput>;
};


export type SubscriptionObjectTypeUpsertedArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
};


export type SubscriptionObjectTypeRemovedArgs = {
  filter?: Maybe<FilterObjectTypeInput>;
};


export type SubscriptionOcrAnalysisUpsertedArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
};


export type SubscriptionOcrAnalysisRemovedArgs = {
  filter?: Maybe<FilterOcrAnalysisInput>;
};


export type SubscriptionPaymentMethodUpsertedArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
};


export type SubscriptionPaymentMethodRemovedArgs = {
  filter?: Maybe<FilterPaymentMethodInput>;
};


export type SubscriptionPaymentTermsUpsertedArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
};


export type SubscriptionPaymentTermsRemovedArgs = {
  filter?: Maybe<FilterPaymentTermsInput>;
};


export type SubscriptionPlatformFeatureUpsertedArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
};


export type SubscriptionPlatformFeatureRemovedArgs = {
  filter?: Maybe<FilterPlatformFeatureInput>;
};


export type SubscriptionPostCodeUpsertedArgs = {
  filter?: Maybe<FilterPostCodeInput>;
};


export type SubscriptionPostCodeRemovedArgs = {
  filter?: Maybe<FilterPostCodeInput>;
};


export type SubscriptionProjectUpsertedArgs = {
  filter?: Maybe<FilterProjectInput>;
};


export type SubscriptionProjectRemovedArgs = {
  filter?: Maybe<FilterProjectInput>;
};


export type SubscriptionPurchaseHeaderUpsertedArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
};


export type SubscriptionPurchaseHeaderRemovedArgs = {
  filter?: Maybe<FilterPurchaseHeaderInput>;
};


export type SubscriptionPurchaseLineUpsertedArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
};


export type SubscriptionPurchaseLineRemovedArgs = {
  filter?: Maybe<FilterPurchaseLineInput>;
};


export type SubscriptionPurchasingUpsertedArgs = {
  filter?: Maybe<FilterPurchasingInput>;
};


export type SubscriptionPurchasingRemovedArgs = {
  filter?: Maybe<FilterPurchasingInput>;
};


export type SubscriptionPurchCrMemoHeaderUpsertedArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
};


export type SubscriptionPurchCrMemoHeaderRemovedArgs = {
  filter?: Maybe<FilterPurchCrMemoHeaderInput>;
};


export type SubscriptionPurchCrMemoLineUpsertedArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
};


export type SubscriptionPurchCrMemoLineRemovedArgs = {
  filter?: Maybe<FilterPurchCrMemoLineInput>;
};


export type SubscriptionPurchInvHeaderUpsertedArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
};


export type SubscriptionPurchInvHeaderRemovedArgs = {
  filter?: Maybe<FilterPurchInvHeaderInput>;
};


export type SubscriptionPurchInvLineUpsertedArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
};


export type SubscriptionPurchInvLineRemovedArgs = {
  filter?: Maybe<FilterPurchInvLineInput>;
};


export type SubscriptionPurchRcptHeaderUpsertedArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
};


export type SubscriptionPurchRcptHeaderRemovedArgs = {
  filter?: Maybe<FilterPurchRcptHeaderInput>;
};


export type SubscriptionPurchRcptLineUpsertedArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
};


export type SubscriptionPurchRcptLineRemovedArgs = {
  filter?: Maybe<FilterPurchRcptLineInput>;
};


export type SubscriptionQuoteHeaderUpsertedArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
};


export type SubscriptionQuoteHeaderRemovedArgs = {
  filter?: Maybe<FilterQuoteHeaderInput>;
};


export type SubscriptionQuoteLineUpsertedArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
};


export type SubscriptionQuoteLineRemovedArgs = {
  filter?: Maybe<FilterQuoteLineInput>;
};


export type SubscriptionQuoteTaskGroupUpsertedArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
};


export type SubscriptionQuoteTaskGroupRemovedArgs = {
  filter?: Maybe<FilterQuoteTaskGroupInput>;
};


export type SubscriptionRentalHeaderUpsertedArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
};


export type SubscriptionRentalHeaderRemovedArgs = {
  filter?: Maybe<FilterRentalHeaderInput>;
};


export type SubscriptionRentalLineUpsertedArgs = {
  filter?: Maybe<FilterRentalLineInput>;
};


export type SubscriptionRentalLineRemovedArgs = {
  filter?: Maybe<FilterRentalLineInput>;
};


export type SubscriptionRentalLineSnapshotUpsertedArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
};


export type SubscriptionRentalLineSnapshotRemovedArgs = {
  filter?: Maybe<FilterRentalLineSnapshotInput>;
};


export type SubscriptionRentalPostedCollHeaderUpsertedArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
};


export type SubscriptionRentalPostedCollHeaderRemovedArgs = {
  filter?: Maybe<FilterRentalPostedCollHeaderInput>;
};


export type SubscriptionRentalPostedCollLineUpsertedArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
};


export type SubscriptionRentalPostedCollLineRemovedArgs = {
  filter?: Maybe<FilterRentalPostedCollLineInput>;
};


export type SubscriptionRentalPostedShptHeaderUpsertedArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
};


export type SubscriptionRentalPostedShptHeaderRemovedArgs = {
  filter?: Maybe<FilterRentalPostedShptHeaderInput>;
};


export type SubscriptionRentalPostedShptLineUpsertedArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
};


export type SubscriptionRentalPostedShptLineRemovedArgs = {
  filter?: Maybe<FilterRentalPostedShptLineInput>;
};


export type SubscriptionRentalPriceTermsUpsertedArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
};


export type SubscriptionRentalPriceTermsRemovedArgs = {
  filter?: Maybe<FilterRentalPriceTermsInput>;
};


export type SubscriptionRentalReturnEntryUpsertedArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
};


export type SubscriptionRentalReturnEntryRemovedArgs = {
  filter?: Maybe<FilterRentalReturnEntryInput>;
};


export type SubscriptionRequestHeaderUpsertedArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
};


export type SubscriptionRequestHeaderRemovedArgs = {
  filter?: Maybe<FilterRequestHeaderInput>;
};


export type SubscriptionRequestLineUpsertedArgs = {
  filter?: Maybe<FilterRequestLineInput>;
};


export type SubscriptionRequestLineRemovedArgs = {
  filter?: Maybe<FilterRequestLineInput>;
};


export type SubscriptionReservationRequestHeaderUpsertedArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
};


export type SubscriptionReservationRequestHeaderRemovedArgs = {
  filter?: Maybe<FilterReservationRequestHeaderInput>;
};


export type SubscriptionReservationRequestLineUpsertedArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
};


export type SubscriptionReservationRequestLineRemovedArgs = {
  filter?: Maybe<FilterReservationRequestLineInput>;
};


export type SubscriptionResourceUpsertedArgs = {
  filter?: Maybe<FilterResourceInput>;
};


export type SubscriptionResourceRemovedArgs = {
  filter?: Maybe<FilterResourceInput>;
};


export type SubscriptionResponsibilityCenterUpsertedArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
};


export type SubscriptionResponsibilityCenterRemovedArgs = {
  filter?: Maybe<FilterResponsibilityCenterInput>;
};


export type SubscriptionReturnRequestHeaderUpsertedArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
};


export type SubscriptionReturnRequestHeaderRemovedArgs = {
  filter?: Maybe<FilterReturnRequestHeaderInput>;
};


export type SubscriptionReturnRequestLineUpsertedArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
};


export type SubscriptionReturnRequestLineRemovedArgs = {
  filter?: Maybe<FilterReturnRequestLineInput>;
};


export type SubscriptionRiskAnalysisHeaderUpsertedArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
};


export type SubscriptionRiskAnalysisHeaderRemovedArgs = {
  filter?: Maybe<FilterRiskAnalysisHeaderInput>;
};


export type SubscriptionRiskAnalysisLineUpsertedArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
};


export type SubscriptionRiskAnalysisLineRemovedArgs = {
  filter?: Maybe<FilterRiskAnalysisLineInput>;
};


export type SubscriptionRiskAnalysisSignatureUpsertedArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
};


export type SubscriptionRiskAnalysisSignatureRemovedArgs = {
  filter?: Maybe<FilterRiskAnalysisSignatureInput>;
};


export type SubscriptionSalesHeaderUpsertedArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
};


export type SubscriptionSalesHeaderRemovedArgs = {
  filter?: Maybe<FilterSalesHeaderInput>;
};


export type SubscriptionSalesHeaderArchiveUpsertedArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
};


export type SubscriptionSalesHeaderArchiveRemovedArgs = {
  filter?: Maybe<FilterSalesHeaderArchiveInput>;
};


export type SubscriptionSalesHeaderHistoryUpsertedArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
};


export type SubscriptionSalesHeaderHistoryRemovedArgs = {
  filter?: Maybe<FilterSalesHeaderHistoryInput>;
};


export type SubscriptionSalesHeaderRequestUpsertedArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
};


export type SubscriptionSalesHeaderRequestRemovedArgs = {
  filter?: Maybe<FilterSalesHeaderRequestInput>;
};


export type SubscriptionSalesLineUpsertedArgs = {
  filter?: Maybe<FilterSalesLineInput>;
};


export type SubscriptionSalesLineRemovedArgs = {
  filter?: Maybe<FilterSalesLineInput>;
};


export type SubscriptionSalesLineArchiveUpsertedArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
};


export type SubscriptionSalesLineArchiveRemovedArgs = {
  filter?: Maybe<FilterSalesLineArchiveInput>;
};


export type SubscriptionSalesLineRequestUpsertedArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
};


export type SubscriptionSalesLineRequestRemovedArgs = {
  filter?: Maybe<FilterSalesLineRequestInput>;
};


export type SubscriptionSalespersonUpsertedArgs = {
  filter?: Maybe<FilterSalespersonInput>;
};


export type SubscriptionSalespersonRemovedArgs = {
  filter?: Maybe<FilterSalespersonInput>;
};


export type SubscriptionSalesPriceUpsertedArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
};


export type SubscriptionSalesPriceRemovedArgs = {
  filter?: Maybe<FilterSalesPriceInput>;
};


export type SubscriptionSalesShipmentHeaderUpsertedArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
};


export type SubscriptionSalesShipmentHeaderRemovedArgs = {
  filter?: Maybe<FilterSalesShipmentHeaderInput>;
};


export type SubscriptionSalesShipmentLineUpsertedArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
};


export type SubscriptionSalesShipmentLineRemovedArgs = {
  filter?: Maybe<FilterSalesShipmentLineInput>;
};


export type SubscriptionServiceItemGroupUpsertedArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
};


export type SubscriptionServiceItemGroupRemovedArgs = {
  filter?: Maybe<FilterServiceItemGroupInput>;
};


export type SubscriptionShipmentMethodUpsertedArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
};


export type SubscriptionShipmentMethodRemovedArgs = {
  filter?: Maybe<FilterShipmentMethodInput>;
};


export type SubscriptionShippingAgentUpsertedArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
};


export type SubscriptionShippingAgentRemovedArgs = {
  filter?: Maybe<FilterShippingAgentInput>;
};


export type SubscriptionSignatureUpsertedArgs = {
  filter?: Maybe<FilterSignatureInput>;
};


export type SubscriptionSignatureRemovedArgs = {
  filter?: Maybe<FilterSignatureInput>;
};


export type SubscriptionSignRequestUpsertedArgs = {
  filter?: Maybe<FilterSignRequestInput>;
};


export type SubscriptionSignRequestRemovedArgs = {
  filter?: Maybe<FilterSignRequestInput>;
};


export type SubscriptionSpecialEquipmentUpsertedArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
};


export type SubscriptionSpecialEquipmentRemovedArgs = {
  filter?: Maybe<FilterSpecialEquipmentInput>;
};


export type SubscriptionStockkeepingUnitUpsertedArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
};


export type SubscriptionStockkeepingUnitRemovedArgs = {
  filter?: Maybe<FilterStockkeepingUnitInput>;
};


export type SubscriptionTariffNumberUpsertedArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
};


export type SubscriptionTariffNumberRemovedArgs = {
  filter?: Maybe<FilterTariffNumberInput>;
};


export type SubscriptionTaxGroupUpsertedArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
};


export type SubscriptionTaxGroupRemovedArgs = {
  filter?: Maybe<FilterTaxGroupInput>;
};


export type SubscriptionTimeRegistrationUpsertedArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
};


export type SubscriptionTimeRegistrationRemovedArgs = {
  filter?: Maybe<FilterTimeRegistrationInput>;
};


export type SubscriptionTimeSheetDetailUpsertedArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
};


export type SubscriptionTimeSheetDetailRemovedArgs = {
  filter?: Maybe<FilterTimeSheetDetailInput>;
};


export type SubscriptionTimeSheetLineUpsertedArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
};


export type SubscriptionTimeSheetLineRemovedArgs = {
  filter?: Maybe<FilterTimeSheetLineInput>;
};


export type SubscriptionTransactionSpecificationUpsertedArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
};


export type SubscriptionTransactionSpecificationRemovedArgs = {
  filter?: Maybe<FilterTransactionSpecificationInput>;
};


export type SubscriptionTransactionTypeUpsertedArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
};


export type SubscriptionTransactionTypeRemovedArgs = {
  filter?: Maybe<FilterTransactionTypeInput>;
};


export type SubscriptionTransportMethodUpsertedArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
};


export type SubscriptionTransportMethodRemovedArgs = {
  filter?: Maybe<FilterTransportMethodInput>;
};


export type SubscriptionUnitOfMeasureUpsertedArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
};


export type SubscriptionUnitOfMeasureRemovedArgs = {
  filter?: Maybe<FilterUnitOfMeasureInput>;
};


export type SubscriptionUserUpsertedArgs = {
  filter?: Maybe<FilterUserInput>;
};


export type SubscriptionUserRemovedArgs = {
  filter?: Maybe<FilterUserInput>;
};


export type SubscriptionUserFavoritesUpsertedArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
};


export type SubscriptionUserFavoritesRemovedArgs = {
  filter?: Maybe<FilterUserFavoritesInput>;
};


export type SubscriptionVatBusinessPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
};


export type SubscriptionVatBusinessPostingGroupRemovedArgs = {
  filter?: Maybe<FilterVatBusinessPostingGroupInput>;
};


export type SubscriptionVatProductPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
};


export type SubscriptionVatProductPostingGroupRemovedArgs = {
  filter?: Maybe<FilterVatProductPostingGroupInput>;
};


export type SubscriptionVendorUpsertedArgs = {
  filter?: Maybe<FilterVendorInput>;
};


export type SubscriptionVendorRemovedArgs = {
  filter?: Maybe<FilterVendorInput>;
};


export type SubscriptionVendorBankAccountUpsertedArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
};


export type SubscriptionVendorBankAccountRemovedArgs = {
  filter?: Maybe<FilterVendorBankAccountInput>;
};


export type SubscriptionVendorPostingGroupUpsertedArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
};


export type SubscriptionVendorPostingGroupRemovedArgs = {
  filter?: Maybe<FilterVendorPostingGroupInput>;
};


export type SubscriptionWarehouseClassUpsertedArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
};


export type SubscriptionWarehouseClassRemovedArgs = {
  filter?: Maybe<FilterWarehouseClassInput>;
};


export type SubscriptionWebBookingUpsertedArgs = {
  filter?: Maybe<FilterWebBookingInput>;
};


export type SubscriptionWebBookingRemovedArgs = {
  filter?: Maybe<FilterWebBookingInput>;
};


export type SubscriptionWebhookSubscriptionUpsertedArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
};


export type SubscriptionWebhookSubscriptionRemovedArgs = {
  filter?: Maybe<FilterWebhookSubscriptionInput>;
};


export type SubscriptionWorkLogUpsertedArgs = {
  filter?: Maybe<FilterWorkLogInput>;
};


export type SubscriptionWorkLogRemovedArgs = {
  filter?: Maybe<FilterWorkLogInput>;
};


export type SubscriptionWorkTypeUpsertedArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
};


export type SubscriptionWorkTypeRemovedArgs = {
  filter?: Maybe<FilterWorkTypeInput>;
};


export type SubscriptionDomainIntegrationUpsertedArgs = {
  filter?: Maybe<FilterDomainIntegrationInput>;
};


export type SubscriptionDomainIntegrationRemovedArgs = {
  filter?: Maybe<FilterDomainIntegrationInput>;
};

export type SyncUsersInput = {
  CompanyId: Scalars['String'];
};

export type TariffNumber = {
  __typename?: 'TariffNumber';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SupplementaryUnits?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TariffNumberConnection = {
  __typename?: 'TariffNumberConnection';
  count: Scalars['Int'];
  edges: Array<TariffNumberConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TariffNumberConnectionEdge = {
  __typename?: 'TariffNumberConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TariffNumber;
};

export type TariffNumberDeleteResponse = {
  __typename?: 'TariffNumberDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TariffNumber>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TariffNumberManyResult = {
  __typename?: 'TariffNumberManyResult';
  records?: Maybe<Array<Maybe<TariffNumber>>>;
  pageData?: Maybe<PageData>;
};

export type TaxGroup = {
  __typename?: 'TaxGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TaxGroupConnection = {
  __typename?: 'TaxGroupConnection';
  count: Scalars['Int'];
  edges: Array<TaxGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TaxGroupConnectionEdge = {
  __typename?: 'TaxGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TaxGroup;
};

export type TaxGroupDeleteResponse = {
  __typename?: 'TaxGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TaxGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TaxGroupManyResult = {
  __typename?: 'TaxGroupManyResult';
  records?: Maybe<Array<Maybe<TaxGroup>>>;
  pageData?: Maybe<PageData>;
};

export type TimeRegistration = {
  __typename?: 'TimeRegistration';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  TimeSheetLineLineNo?: Maybe<Scalars['Float']>;
  TimeSheetDetailLineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Hours?: Maybe<Scalars['Float']>;
  Email?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  GlobalId?: Maybe<Scalars['ID']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  StartDateFilter?: Maybe<Scalars['DateTime']>;
  EndDateFilter?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TimeRegistrationConnection = {
  __typename?: 'TimeRegistrationConnection';
  count: Scalars['Int'];
  edges: Array<TimeRegistrationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TimeRegistrationConnectionEdge = {
  __typename?: 'TimeRegistrationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TimeRegistration;
};

export type TimeRegistrationDeleteResponse = {
  __typename?: 'TimeRegistrationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TimeRegistration>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimeRegistrationManyResult = {
  __typename?: 'TimeRegistrationManyResult';
  records?: Maybe<Array<Maybe<TimeRegistration>>>;
  pageData?: Maybe<PageData>;
};

export type TimeSheetDetail = {
  __typename?: 'TimeSheetDetail';
  discriminator?: Maybe<Scalars['String']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  TimeSheetLineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  ServiceOrderNo?: Maybe<Scalars['String']>;
  ServiceOrderLineNo?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PostedQuantity?: Maybe<Scalars['Float']>;
  AssemblyOrderNo?: Maybe<Scalars['String']>;
  AssemblyOrderLineNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Posted?: Maybe<Scalars['Boolean']>;
  ExcludeFromTotals?: Maybe<Scalars['Boolean']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TimeSheetDetailConnection = {
  __typename?: 'TimeSheetDetailConnection';
  count: Scalars['Int'];
  edges: Array<TimeSheetDetailConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TimeSheetDetailConnectionEdge = {
  __typename?: 'TimeSheetDetailConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TimeSheetDetail;
};

export type TimeSheetDetailDeleteResponse = {
  __typename?: 'TimeSheetDetailDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TimeSheetDetail>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimeSheetDetailManyResult = {
  __typename?: 'TimeSheetDetailManyResult';
  records?: Maybe<Array<Maybe<TimeSheetDetail>>>;
  pageData?: Maybe<PageData>;
};

export type TimeSheetLine = {
  __typename?: 'TimeSheetLine';
  discriminator?: Maybe<Scalars['String']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  TimeSheetStartingDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ServiceOrderNo?: Maybe<Scalars['String']>;
  ServiceOrderLineNo?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  AssemblyOrderNo?: Maybe<Scalars['String']>;
  AssemblyOrderLineNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Posted?: Maybe<Scalars['Boolean']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CustomerName?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TimeSheetLineConnection = {
  __typename?: 'TimeSheetLineConnection';
  count: Scalars['Int'];
  edges: Array<TimeSheetLineConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TimeSheetLineConnectionEdge = {
  __typename?: 'TimeSheetLineConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TimeSheetLine;
};

export type TimeSheetLineDeleteResponse = {
  __typename?: 'TimeSheetLineDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TimeSheetLine>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimeSheetLineManyResult = {
  __typename?: 'TimeSheetLineManyResult';
  records?: Maybe<Array<Maybe<TimeSheetLine>>>;
  pageData?: Maybe<PageData>;
};

export type ToggleProjectNotificationsInput = {
  ProjectId: Scalars['String'];
  Shipments: Scalars['Boolean'];
  Returns: Scalars['Boolean'];
};

export type TransactionSpecification = {
  __typename?: 'TransactionSpecification';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionSpecificationConnection = {
  __typename?: 'TransactionSpecificationConnection';
  count: Scalars['Int'];
  edges: Array<TransactionSpecificationConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TransactionSpecificationConnectionEdge = {
  __typename?: 'TransactionSpecificationConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TransactionSpecification;
};

export type TransactionSpecificationDeleteResponse = {
  __typename?: 'TransactionSpecificationDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TransactionSpecification>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransactionSpecificationManyResult = {
  __typename?: 'TransactionSpecificationManyResult';
  records?: Maybe<Array<Maybe<TransactionSpecification>>>;
  pageData?: Maybe<PageData>;
};

export type TransactionType = {
  __typename?: 'TransactionType';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionTypeConnection = {
  __typename?: 'TransactionTypeConnection';
  count: Scalars['Int'];
  edges: Array<TransactionTypeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TransactionTypeConnectionEdge = {
  __typename?: 'TransactionTypeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TransactionType;
};

export type TransactionTypeDeleteResponse = {
  __typename?: 'TransactionTypeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TransactionType>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransactionTypeManyResult = {
  __typename?: 'TransactionTypeManyResult';
  records?: Maybe<Array<Maybe<TransactionType>>>;
  pageData?: Maybe<PageData>;
};

export type TransportMethod = {
  __typename?: 'TransportMethod';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type TransportMethodConnection = {
  __typename?: 'TransportMethodConnection';
  count: Scalars['Int'];
  edges: Array<TransportMethodConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TransportMethodConnectionEdge = {
  __typename?: 'TransportMethodConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: TransportMethod;
};

export type TransportMethodDeleteResponse = {
  __typename?: 'TransportMethodDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<TransportMethod>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransportMethodManyResult = {
  __typename?: 'TransportMethodManyResult';
  records?: Maybe<Array<Maybe<TransportMethod>>>;
  pageData?: Maybe<PageData>;
};

export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  InternationalStandardCode?: Maybe<Scalars['String']>;
  Symbol?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  ExternalCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitOfMeasureConnection = {
  __typename?: 'UnitOfMeasureConnection';
  count: Scalars['Int'];
  edges: Array<UnitOfMeasureConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type UnitOfMeasureConnectionEdge = {
  __typename?: 'UnitOfMeasureConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: UnitOfMeasure;
};

export type UnitOfMeasureDeleteResponse = {
  __typename?: 'UnitOfMeasureDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<UnitOfMeasure>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UnitOfMeasureManyResult = {
  __typename?: 'UnitOfMeasureManyResult';
  records?: Maybe<Array<Maybe<UnitOfMeasure>>>;
  pageData?: Maybe<PageData>;
};


export type UpdateAccessRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  Type?: Maybe<AccessRequestTypeEnum>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  TargetName?: Maybe<Scalars['String']>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  Status?: Maybe<AccessRequestStatusEnum>;
  Approved?: Maybe<Scalars['Boolean']>;
  ApprovedOn?: Maybe<Scalars['DateTime']>;
  RequestedOn?: Maybe<Scalars['DateTime']>;
  ApprovedByUserGlobalId?: Maybe<Scalars['String']>;
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateAdditionalItemInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Type?: Maybe<AdditionalItemTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  AdditionalItemNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  QuantityOption?: Maybe<AdditionalItemQuantityOptionEnum>;
  QuantityOptionOPTION?: Maybe<Scalars['String']>;
  QuantityRatio?: Maybe<Scalars['Float']>;
  MinRequiredQty?: Maybe<Scalars['Float']>;
  UseZeroPrice?: Maybe<Scalars['Boolean']>;
  Active?: Maybe<Scalars['Boolean']>;
  Price?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateAddressInput = {
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PlaceofExport?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  CustomerGlobalId?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateAgreementInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  RecName?: Maybe<Scalars['String']>;
  RecId?: Maybe<Scalars['String']>;
  AgreementType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  SignatureType?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateAnnotationInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectTypeCode?: Maybe<Scalars['String']>;
  NoteText?: Maybe<Scalars['String']>;
  OwnerId?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  OwnerName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateApplicationModuleInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ShortName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ShortDescription?: Maybe<Scalars['String']>;
  DefaultWebLink?: Maybe<Scalars['String']>;
  DefaultAppNavStackName?: Maybe<Scalars['String']>;
  SearchOrder?: Maybe<Scalars['Float']>;
  NavigationOrder?: Maybe<Scalars['Float']>;
  Searchable?: Maybe<Scalars['Boolean']>;
  ShowOnWebHeader?: Maybe<Scalars['Boolean']>;
  ShowOnAppMainTab?: Maybe<Scalars['Boolean']>;
  VisibleForCustomer?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBcMappingFieldInput = {
  discriminator?: Maybe<Scalars['String']>;
  NBAEDirection?: Maybe<Scalars['Float']>;
  NBAEDirectionOPTION?: Maybe<Scalars['String']>;
  NBAETableNo?: Maybe<Scalars['Float']>;
  NBAEEventType?: Maybe<Scalars['Float']>;
  NBAEEventTypeOPTION?: Maybe<Scalars['String']>;
  NBAESequenceNo?: Maybe<Scalars['Float']>;
  NBAELineNo?: Maybe<Scalars['Float']>;
  NBAEPKField?: Maybe<Scalars['Boolean']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  NBAESortingOrder?: Maybe<Scalars['Float']>;
  NBAEOutFromDataType?: Maybe<Scalars['Float']>;
  NBAEOutFromDataTypeOPTION?: Maybe<Scalars['String']>;
  NBAEOutFromDataSubType?: Maybe<Scalars['String']>;
  NBAEOutToJSONElement?: Maybe<Scalars['String']>;
  NBAEInFromDataType?: Maybe<Scalars['Float']>;
  NBAEInFromDataTypeOPTION?: Maybe<Scalars['String']>;
  NBAEInFromDataSubType?: Maybe<Scalars['String']>;
  NBAEInOperator?: Maybe<Scalars['Float']>;
  NBAEInOperatorOPTION?: Maybe<Scalars['String']>;
  NBAEInToFieldNo?: Maybe<Scalars['Float']>;
  NBAEInToFieldName?: Maybe<Scalars['String']>;
  NBAEInValidate?: Maybe<Scalars['Boolean']>;
  NBAEInTranslatesFromField?: Maybe<Scalars['Float']>;
  NBAELineFieldNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBcMappingTableInput = {
  discriminator?: Maybe<Scalars['String']>;
  NBAEDirection?: Maybe<Scalars['Float']>;
  NBAEDirectionOPTION?: Maybe<Scalars['String']>;
  NBAETableNo?: Maybe<Scalars['Float']>;
  NBAEEventType?: Maybe<Scalars['Float']>;
  NBAEEventTypeOPTION?: Maybe<Scalars['String']>;
  NBAESequenceNo?: Maybe<Scalars['Float']>;
  NBAETableName?: Maybe<Scalars['String']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  NBAEEntityName?: Maybe<Scalars['String']>;
  NBAEDescription?: Maybe<Scalars['String']>;
  NBAEProcessingOrder?: Maybe<Scalars['Float']>;
  NBAEProcessOnReceipt?: Maybe<Scalars['Boolean']>;
  NBAEProcessAsTransactions?: Maybe<Scalars['Boolean']>;
  NBAEFields?: Maybe<Scalars['Float']>;
  NBAEFilters?: Maybe<Scalars['Float']>;
  NBAERelations?: Maybe<Scalars['Float']>;
  NBAEDelay?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBcSettingsInput = {
  discriminator?: Maybe<Scalars['String']>;
  NBAECode?: Maybe<Scalars['String']>;
  NBAELineNo?: Maybe<Scalars['Float']>;
  NBAESettingName?: Maybe<Scalars['String']>;
  NBAESettingType?: Maybe<Scalars['Float']>;
  NBAESettingTypeOPTION?: Maybe<Scalars['String']>;
  NBAESettingValue?: Maybe<Scalars['String']>;
  NBAEIgnore?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBulkItemGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCauseOfAbsenceInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  EmployeeNoFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  TotalAbsenceBase?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCompanyInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  PrimaryKey?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  DocumentSendingProfile?: Maybe<Scalars['String']>;
  OurAccountNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  ChainName?: Maybe<Scalars['String']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  CreditLimitLCY?: Maybe<Scalars['Float']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  GiroNo?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  PaymentRoutingNo?: Maybe<Scalars['String']>;
  CustomsPermitNo?: Maybe<Scalars['String']>;
  CustomsPermitDate?: Maybe<Scalars['DateTime']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  IndustrialClassification?: Maybe<Scalars['String']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICInboxType?: Maybe<Scalars['String']>;
  ICInboxDetails?: Maybe<Scalars['String']>;
  SystemIndicator?: Maybe<Scalars['String']>;
  CustomSystemIndicatorText?: Maybe<Scalars['String']>;
  SystemIndicatorStyle?: Maybe<Scalars['String']>;
  AllowBlankPaymentInfo?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CheckAvailPeriodCalc?: Maybe<Scalars['String']>;
  CheckAvailTimeBucket?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  CalConvergenceTimeFrame?: Maybe<Scalars['String']>;
  PlusGiroNo?: Maybe<Scalars['String']>;
  RegisteredOffice?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  UseExtraNavetPermissions?: Maybe<Scalars['Boolean']>;
  BlockObjectTypeModify?: Maybe<Scalars['Boolean']>;
  UseStandardPriceCalculation?: Maybe<Scalars['Boolean']>;
  UseStavdalResourcePrice?: Maybe<Scalars['Boolean']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  GLN?: Maybe<Scalars['String']>;
  ProductionDatabase?: Maybe<Scalars['String']>;
  MarkedAsTest?: Maybe<Scalars['Boolean']>;
  DontAskAgain?: Maybe<Scalars['Boolean']>;
  TestEmail?: Maybe<Scalars['String']>;
  BaseisInitiated?: Maybe<Scalars['Boolean']>;
  PlusGiroRefAccountNo?: Maybe<Scalars['String']>;
  CompanyImageEtag?: Maybe<Scalars['String']>;
  CompanyImageDocGlobalId?: Maybe<Scalars['String']>;
  CompanyImageURL?: Maybe<Scalars['String']>;
  Address_Visit?: Maybe<Scalars['String']>;
  Address2_Visit?: Maybe<Scalars['String']>;
  City_Visit?: Maybe<Scalars['String']>;
  PostCode_Visit?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ScriveField?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  ActiveInEdge?: Maybe<Scalars['Boolean']>;
  IsProspect?: Maybe<Scalars['Boolean']>;
  Analytics?: Maybe<Scalars['Boolean']>;
  MagentoGroupId?: Maybe<Scalars['Float']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  Fax?: Maybe<Scalars['String']>;
  IsWebCRM?: Maybe<Scalars['Boolean']>;
  MagentoId?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LatestInvoiceDate?: Maybe<Scalars['DateTime']>;
  RevenueThisYear?: Maybe<Scalars['Float']>;
  RevenueLastYear?: Maybe<Scalars['Float']>;
  RevenueYearBefLastYear?: Maybe<Scalars['Float']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  FinChargeTermsCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PlaceofExport?: Maybe<Scalars['String']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  CollectionMethod?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  InvoiceCopies?: Maybe<Scalars['Float']>;
  LastStatementNo?: Maybe<Scalars['Float']>;
  PrintStatements?: Maybe<Scalars['Boolean']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  Priority?: Maybe<Scalars['Float']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  NetChangeLCY?: Maybe<Scalars['Float']>;
  SalesLCY?: Maybe<Scalars['Float']>;
  ProfitLCY?: Maybe<Scalars['Float']>;
  InvDiscountsLCY?: Maybe<Scalars['Float']>;
  PmtDiscountsLCY?: Maybe<Scalars['Float']>;
  BalanceDue?: Maybe<Scalars['Float']>;
  BalanceDueLCY?: Maybe<Scalars['Float']>;
  Payments?: Maybe<Scalars['Float']>;
  InvoiceAmounts?: Maybe<Scalars['Float']>;
  CrMemoAmounts?: Maybe<Scalars['Float']>;
  FinanceChargeMemoAmounts?: Maybe<Scalars['Float']>;
  PaymentsLCY?: Maybe<Scalars['Float']>;
  InvAmountsLCY?: Maybe<Scalars['Float']>;
  CrMemoAmountsLCY?: Maybe<Scalars['Float']>;
  FinChargeMemoAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ApplicationMethod?: Maybe<Scalars['Float']>;
  ApplicationMethodOPTION?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  DontDebitPlasticTax?: Maybe<Scalars['Boolean']>;
  DontDebitEnvironmentFees?: Maybe<Scalars['Boolean']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  EnvironmentShipmentDays?: Maybe<Scalars['String']>;
  CustomerCategory?: Maybe<Scalars['String']>;
  CustomerClassification?: Maybe<Scalars['String']>;
  MagentoCustomer?: Maybe<Scalars['Boolean']>;
  Allwebitemsisvisible?: Maybe<Scalars['Boolean']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  SharkPrioritizeCode?: Maybe<Scalars['Float']>;
  SharkPrioritizeCodeOPTION?: Maybe<Scalars['String']>;
  SharkInvoiceValue?: Maybe<Scalars['Float']>;
  SharkFreightPrice?: Maybe<Scalars['Float']>;
  SharkTelephoneInstruction?: Maybe<Scalars['Boolean']>;
  NoBackOrder?: Maybe<Scalars['Boolean']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  OnlyActualFreightPrice?: Maybe<Scalars['Boolean']>;
  InvoiceDocumentType?: Maybe<Scalars['Float']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  CustomerPriceGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  PaymentMethodWeb?: Maybe<CompanyPaymentMethodWebEnum>;
  QBSId?: Maybe<Scalars['String']>;
  CRMClassification?: Maybe<Scalars['Float']>;
  PEBShiptoCode?: Maybe<Scalars['String']>;
  PEBDirectDebitPackType?: Maybe<Scalars['Float']>;
  PEBDirectDebitPackTypeOPTION?: Maybe<Scalars['String']>;
  PEBDirectDebitPaymentNo?: Maybe<Scalars['String']>;
  PEBDirectDebitApprovalStat?: Maybe<Scalars['Float']>;
  PEBDirectDebitApprovalStatOPTION?: Maybe<Scalars['String']>;
  PEBDDApprovalStatus?: Maybe<Scalars['Float']>;
  PEBDDApprovalStatusOPTION?: Maybe<Scalars['String']>;
  PEBRegistrationNo?: Maybe<Scalars['String']>;
  PEBDocumentCode?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMCustomerTemplate?: Maybe<Scalars['String']>;
  EQMCustRentalDiscGroup?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMEditableRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMRentalInsurance?: Maybe<Scalars['String']>;
  EQMCustRentalPriceGroup?: Maybe<Scalars['String']>;
  EQMHowInvoiceRentalContr?: Maybe<Scalars['Float']>;
  EQMHowInvoiceRentalContrOPTION?: Maybe<Scalars['String']>;
  EQMCustomerDeposit?: Maybe<Scalars['String']>;
  EQMCheckListCode?: Maybe<Scalars['String']>;
  EQMCustomerCategory?: Maybe<Scalars['Float']>;
  EQMCustomerCategoryOPTION?: Maybe<Scalars['String']>;
  EQMPopupNote?: Maybe<Scalars['String']>;
  EQMCombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  EQMCombineMethod?: Maybe<Scalars['Float']>;
  EQMCombineMethodOPTION?: Maybe<Scalars['String']>;
  EQMRentalInvDayofMonth?: Maybe<Scalars['Float']>;
  EQMCustomerClassification?: Maybe<Scalars['String']>;
  EQMManuallyBlocked?: Maybe<Scalars['Boolean']>;
  EQMOrganizationNo?: Maybe<Scalars['String']>;
  EQMRentalInvoiceSequence?: Maybe<Scalars['String']>;
  EQMEnvironmentalFeeCode?: Maybe<Scalars['String']>;
  EQMNotEditable?: Maybe<Scalars['Boolean']>;
  EQMPaidDeposits?: Maybe<Scalars['Float']>;
  EQMPaidDepositsLCY?: Maybe<Scalars['Float']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMInvoiceFee?: Maybe<Scalars['Float']>;
  EQMInvoiceFeeOPTION?: Maybe<Scalars['String']>;
  EQMPrepaymentLCY?: Maybe<Scalars['Float']>;
  EQMPrepaymentPeriod?: Maybe<Scalars['String']>;
  EQMMaxRentalValue?: Maybe<Scalars['Float']>;
  EQMMaxGrantedDeposit?: Maybe<Scalars['Float']>;
  EQMCatValidtoDate?: Maybe<Scalars['DateTime']>;
  EQMNoofRentalQuotes?: Maybe<Scalars['Float']>;
  EQMNoofOpenRntlContr?: Maybe<Scalars['Float']>;
  EQMNoofOpenRentalLines?: Maybe<Scalars['Float']>;
  EQMNoofRentalOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentalCreditMemos?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlShpt?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlInv?: Maybe<Scalars['Float']>;
  EQMNoofPstdRntlCrM?: Maybe<Scalars['Float']>;
  EQMNoofWorkshopQuotes?: Maybe<Scalars['Float']>;
  EQMNoofWorkshopOrders?: Maybe<Scalars['Float']>;
  EQMNoofWrkshpCrMemos?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpShpt?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpInv?: Maybe<Scalars['Float']>;
  EQMNoofPstdWrkshpCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofRntlQte?: Maybe<Scalars['Float']>;
  EQMBillToNoofORContr?: Maybe<Scalars['Float']>;
  EQMBillToNoofRntlOrds?: Maybe<Scalars['Float']>;
  EQMBillToNoofRCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdRS?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdRI?: Maybe<Scalars['Float']>;
  EQMBillToNoofPRCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofWQte?: Maybe<Scalars['Float']>;
  EQMBillToNoofWrkshpO?: Maybe<Scalars['Float']>;
  EQMBillToNoofWCrM?: Maybe<Scalars['Float']>;
  EQMBillToNoofPstdWS?: Maybe<Scalars['Float']>;
  EQMBillToNoofPWInv?: Maybe<Scalars['Float']>;
  EQMBillToNoofPWCrM?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRentalInsuranceOptions?: Maybe<Scalars['Float']>;
  EQMRentalInsuranceOptionsOPTION?: Maybe<Scalars['String']>;
  EQMPONoMandatory?: Maybe<Scalars['Boolean']>;
  EQMNoofRentPickOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentDeliverOrders?: Maybe<Scalars['Float']>;
  EQMNoofRentCollOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdPickOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdDelOrders?: Maybe<Scalars['Float']>;
  EQMNoofPstdCollOrders?: Maybe<Scalars['Float']>;
  EQMStairPriceFilter?: Maybe<Scalars['String']>;
  EQMStairDiscountFilter?: Maybe<Scalars['String']>;
  HowtoInvoiceRentalContract?: Maybe<Scalars['Float']>;
  HowtoInvoiceRentalContractOPTION?: Maybe<Scalars['String']>;
  EQMShortTermRental?: Maybe<Scalars['Boolean']>;
  EQMCombineInvoiceSubProj?: Maybe<Scalars['Boolean']>;
  EQMTermofContract?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCompanyTreeInput = {
  discriminator?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Maybe<UpdateAddressInput>>>;
  No?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  PrimaryKey?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  GiroNo?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  PaymentRoutingNo?: Maybe<Scalars['String']>;
  CustomsPermitNo?: Maybe<Scalars['String']>;
  CustomsPermitDate?: Maybe<Scalars['DateTime']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  IndustrialClassification?: Maybe<Scalars['String']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICInboxType?: Maybe<Scalars['String']>;
  ICInboxDetails?: Maybe<Scalars['String']>;
  SystemIndicator?: Maybe<Scalars['String']>;
  CustomSystemIndicatorText?: Maybe<Scalars['String']>;
  SystemIndicatorStyle?: Maybe<Scalars['String']>;
  AllowBlankPaymentInfo?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CheckAvailPeriodCalc?: Maybe<Scalars['String']>;
  CheckAvailTimeBucket?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  CalConvergenceTimeFrame?: Maybe<Scalars['String']>;
  PlusGiroNo?: Maybe<Scalars['String']>;
  RegisteredOffice?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  UseExtraNavetPermissions?: Maybe<Scalars['Boolean']>;
  BlockObjectTypeModify?: Maybe<Scalars['Boolean']>;
  UseStandardPriceCalculation?: Maybe<Scalars['Boolean']>;
  UseStavdalResourcePrice?: Maybe<Scalars['Boolean']>;
  GLN?: Maybe<Scalars['String']>;
  ProductionDatabase?: Maybe<Scalars['String']>;
  MarkedAsTest?: Maybe<Scalars['Boolean']>;
  DontAskAgain?: Maybe<Scalars['Boolean']>;
  TestEmail?: Maybe<Scalars['String']>;
  BaseisInitiated?: Maybe<Scalars['Boolean']>;
  PlusGiroRefAccountNo?: Maybe<Scalars['String']>;
  CompanyImageEtag?: Maybe<Scalars['String']>;
  CompanyImageDocGlobalId?: Maybe<Scalars['String']>;
  CompanyImageURL?: Maybe<Scalars['String']>;
  Address_Visit?: Maybe<Scalars['String']>;
  Address2_Visit?: Maybe<Scalars['String']>;
  City_Visit?: Maybe<Scalars['String']>;
  PostCode_Visit?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ScriveField?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMCustomerTemplate?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  ActiveInEdge?: Maybe<Scalars['Boolean']>;
  IsProspect?: Maybe<Scalars['Boolean']>;
  Analytics?: Maybe<Scalars['Boolean']>;
  MagentoGroupId?: Maybe<Scalars['Float']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  MagentoAddressId?: Maybe<Scalars['Float']>;
  Fax?: Maybe<Scalars['String']>;
  IsWebCRM?: Maybe<Scalars['Boolean']>;
  MagentoId?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LatestInvoiceDate?: Maybe<Scalars['DateTime']>;
  RevenueThisYear?: Maybe<Scalars['Float']>;
  RevenueLastYear?: Maybe<Scalars['Float']>;
  RevenueYearBefLastYear?: Maybe<Scalars['Float']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  DontDebitPlasticTax?: Maybe<Scalars['Boolean']>;
  DontDebitEnvironmentFees?: Maybe<Scalars['Boolean']>;
  ShipmentDays?: Maybe<Scalars['String']>;
  EnvironmentShipmentDays?: Maybe<Scalars['String']>;
  CustomerCategory?: Maybe<Scalars['String']>;
  CustomerClassification?: Maybe<Scalars['String']>;
  MagentoCustomer?: Maybe<Scalars['Boolean']>;
  Allwebitemsisvisible?: Maybe<Scalars['Boolean']>;
  SharkFreightInstrToWsh?: Maybe<Scalars['String']>;
  SharkPrioritizeCode?: Maybe<Scalars['Float']>;
  SharkPrioritizeCodeOPTION?: Maybe<Scalars['String']>;
  SharkInvoiceValue?: Maybe<Scalars['Float']>;
  SharkFreightPrice?: Maybe<Scalars['Float']>;
  SharkTelephoneInstruction?: Maybe<Scalars['Boolean']>;
  NoBackOrder?: Maybe<Scalars['Boolean']>;
  Tour?: Maybe<Scalars['String']>;
  TourNo?: Maybe<Scalars['String']>;
  OnlyActualFreightPrice?: Maybe<Scalars['Boolean']>;
  InvoiceDocumentType?: Maybe<Scalars['Float']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
};

export type UpdateConsignmentHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  TableID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  ConsignmentNo?: Maybe<Scalars['String']>;
  ConsignmentType?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentTable?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  PickupName?: Maybe<Scalars['String']>;
  PickupAddress?: Maybe<Scalars['String']>;
  PickupAddress2?: Maybe<Scalars['String']>;
  PickupPostCode?: Maybe<Scalars['String']>;
  PickupCity?: Maybe<Scalars['String']>;
  PickupCounty?: Maybe<Scalars['String']>;
  PickupCountryRegionCode?: Maybe<Scalars['String']>;
  PickupContactNo?: Maybe<Scalars['String']>;
  PickupContactName?: Maybe<Scalars['String']>;
  PickupContactPhoneNo?: Maybe<Scalars['String']>;
  PickupContactMobPhoneNo?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoContactNo?: Maybe<Scalars['String']>;
  ShiptoContactName?: Maybe<Scalars['String']>;
  ShiptoContactPhoneNo?: Maybe<Scalars['String']>;
  ShiptoContactMobPhoneNo?: Maybe<Scalars['String']>;
  EQMRentalContractNo?: Maybe<Scalars['String']>;
  EQMProjectNo?: Maybe<Scalars['String']>;
  EQMPickOrderNo?: Maybe<Scalars['String']>;
  EQMShipOrderNo?: Maybe<Scalars['String']>;
  ReturnOrder?: Maybe<Scalars['Boolean']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  TransportRequestSent?: Maybe<Scalars['String']>;
  TransportRequestSentBy?: Maybe<Scalars['String']>;
  TransportComment?: Maybe<Scalars['String']>;
  ReadyToFetchDate?: Maybe<Scalars['DateTime']>;
  ReadyToFetchTime?: Maybe<Scalars['String']>;
  LatestAtRecieverDate?: Maybe<Scalars['DateTime']>;
  LatestAtRecieverTime?: Maybe<Scalars['String']>;
  AtReceiverOnTime?: Maybe<Scalars['Boolean']>;
  TotalEstimatedVolume?: Maybe<Scalars['Float']>;
  TransportConfirmed?: Maybe<Scalars['Boolean']>;
  TransportConfirmedBy?: Maybe<Scalars['String']>;
  TypeOfVehicle?: Maybe<Scalars['String']>;
  Freightisdebited?: Maybe<Scalars['Boolean']>;
  EQMCollectionOrderNo?: Maybe<Scalars['String']>;
  TotalGrossWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  TotalVolume?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateConsignmentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  ConsignmentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  OriginalTableID?: Maybe<Scalars['Float']>;
  OriginalUniqueID?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  TotalGrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  Volume?: Maybe<Scalars['Float']>;
  TotalVolume?: Maybe<Scalars['Float']>;
  TextBlob?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateContactInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CompanyNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  LookupContactNo?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  Surname?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Initials?: Maybe<Scalars['String']>;
  ExtensionNo?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  Pager?: Maybe<Scalars['String']>;
  OrganizationalLevelCode?: Maybe<Scalars['String']>;
  ExcludefromSegment?: Maybe<Scalars['Boolean']>;
  ExternalID?: Maybe<Scalars['String']>;
  CorrespondenceType?: Maybe<Scalars['String']>;
  SalutationCode?: Maybe<Scalars['String']>;
  SearchEMail?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  EMail2?: Maybe<Scalars['String']>;
  XrmId?: Maybe<Scalars['String']>;
  EQMEvaluationStatus?: Maybe<Scalars['String']>;
  EQMCheckListCode?: Maybe<Scalars['String']>;
  EQMCustomerCategory2?: Maybe<Scalars['String']>;
  EQMIdentificationNo?: Maybe<Scalars['String']>;
  EQMContactType?: Maybe<Scalars['String']>;
  EQMNotEditable?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  SalespersonFilter?: Maybe<Scalars['String']>;
  CampaignFilter?: Maybe<Scalars['String']>;
  ActionTakenFilter?: Maybe<Scalars['String']>;
  SalesCycleFilter?: Maybe<Scalars['String']>;
  SalesCycleStageFilter?: Maybe<Scalars['Float']>;
  ProbabilityFilter?: Maybe<Scalars['Float']>;
  CompletedFilter?: Maybe<Scalars['Float']>;
  EstimatedValueFilter?: Maybe<Scalars['Float']>;
  CalcdCurrentValueFilter?: Maybe<Scalars['Float']>;
  ChancesofSuccessFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilter?: Maybe<Scalars['String']>;
  TaskClosedFilter?: Maybe<Scalars['Boolean']>;
  PriorityFilter?: Maybe<Scalars['String']>;
  TeamFilter?: Maybe<Scalars['String']>;
  CloseOpportunityFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NextTaskDate?: Maybe<Scalars['DateTime']>;
  LastDateAttempted?: Maybe<Scalars['DateTime']>;
  DateofLastInteraction?: Maybe<Scalars['DateTime']>;
  NoofJobResponsibilities?: Maybe<Scalars['Float']>;
  NoofIndustryGroups?: Maybe<Scalars['Float']>;
  NoofBusinessRelations?: Maybe<Scalars['Float']>;
  NoofMailingGroups?: Maybe<Scalars['Float']>;
  NoofInteractions?: Maybe<Scalars['Float']>;
  CostLCY?: Maybe<Scalars['Float']>;
  DurationMin?: Maybe<Scalars['Float']>;
  NoofOpportunities?: Maybe<Scalars['Float']>;
  EstimatedValueLCY?: Maybe<Scalars['Float']>;
  CalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  OpportunityEntryExists?: Maybe<Scalars['Boolean']>;
  TaskEntryExists?: Maybe<Scalars['Boolean']>;
  LinkedToCustomerNo?: Maybe<Scalars['String']>;
  Lead?: Maybe<Scalars['Boolean']>;
  LinkedTo?: Maybe<Scalars['String']>;
  LastCustomerLedgerDate?: Maybe<Scalars['DateTime']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCountryRegionInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ISOCode?: Maybe<Scalars['String']>;
  ISONumericCode?: Maybe<Scalars['String']>;
  EUCountryRegionCode?: Maybe<Scalars['String']>;
  IntrastatCode?: Maybe<Scalars['String']>;
  AddressFormat?: Maybe<Scalars['Float']>;
  AddressFormatOPTION?: Maybe<Scalars['String']>;
  ContactAddressFormat?: Maybe<Scalars['Float']>;
  ContactAddressFormatOPTION?: Maybe<Scalars['String']>;
  VATScheme?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CountyName?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCurrencyExchangeRateInput = {
  discriminator?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  ExchangeRateAmount?: Maybe<Scalars['Float']>;
  AdjustmentExchRateAmount?: Maybe<Scalars['Float']>;
  RelationalCurrencyCode?: Maybe<Scalars['String']>;
  RelationalExchRateAmount?: Maybe<Scalars['Float']>;
  FixExchRateAmount?: Maybe<Scalars['Float']>;
  FixExchRateAmountOPTION?: Maybe<Scalars['String']>;
  RelationalAdjmtExchRateAmt?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCurrencyInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastDateAdjusted?: Maybe<Scalars['DateTime']>;
  ISOCode?: Maybe<Scalars['String']>;
  ISONumericCode?: Maybe<Scalars['String']>;
  UnrealizedGainsAcc?: Maybe<Scalars['String']>;
  RealizedGainsAcc?: Maybe<Scalars['String']>;
  UnrealizedLossesAcc?: Maybe<Scalars['String']>;
  RealizedLossesAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingPrecision?: Maybe<Scalars['Float']>;
  InvoiceRoundingType?: Maybe<Scalars['Float']>;
  InvoiceRoundingTypeOPTION?: Maybe<Scalars['String']>;
  AmountRoundingPrecision?: Maybe<Scalars['Float']>;
  UnitAmountRoundingPrecision?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  AmountDecimalPlaces?: Maybe<Scalars['String']>;
  UnitAmountDecimalPlaces?: Maybe<Scalars['String']>;
  CustomerFilter?: Maybe<Scalars['String']>;
  VendorFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  CustLedgEntriesinFilter?: Maybe<Scalars['Boolean']>;
  CustomerBalance?: Maybe<Scalars['Float']>;
  CustomerOutstandingOrders?: Maybe<Scalars['Float']>;
  CustomerShippedNotInvoiced?: Maybe<Scalars['Float']>;
  CustomerBalanceDue?: Maybe<Scalars['Float']>;
  VendorLedgEntriesinFilter?: Maybe<Scalars['Boolean']>;
  VendorBalance?: Maybe<Scalars['Float']>;
  VendorOutstandingOrders?: Maybe<Scalars['Float']>;
  VendorAmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  VendorBalanceDue?: Maybe<Scalars['Float']>;
  CustomerBalanceLCY?: Maybe<Scalars['Float']>;
  VendorBalanceLCY?: Maybe<Scalars['Float']>;
  RealizedGLGainsAccount?: Maybe<Scalars['String']>;
  RealizedGLLossesAccount?: Maybe<Scalars['String']>;
  ApplnRoundingPrecision?: Maybe<Scalars['Float']>;
  EMUCurrency?: Maybe<Scalars['Boolean']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  ResidualGainsAccount?: Maybe<Scalars['String']>;
  ResidualLossesAccount?: Maybe<Scalars['String']>;
  ConvLCYRndgDebitAcc?: Maybe<Scalars['String']>;
  ConvLCYRndgCreditAcc?: Maybe<Scalars['String']>;
  MaxVATDifferenceAllowed?: Maybe<Scalars['Float']>;
  VATRoundingType?: Maybe<Scalars['Float']>;
  VATRoundingTypeOPTION?: Maybe<Scalars['String']>;
  PaymentTolerancePercent?: Maybe<Scalars['Float']>;
  MaxPaymentToleranceAmount?: Maybe<Scalars['Float']>;
  Symbol?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  PEBNoofForwardAgreement?: Maybe<Scalars['String']>;
  PEBCountryCodeISOAccNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCustomerDomainInput = {
  discriminator?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AdminUserGlobalId?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  DomainImageBase64?: Maybe<Scalars['String']>;
  DomainImageBase64Name?: Maybe<Scalars['String']>;
  DomainMainOrientation?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  VerifiedOn?: Maybe<Scalars['DateTime']>;
  VerifiedByUserEmail?: Maybe<Scalars['String']>;
  VerifiedByUserName?: Maybe<Scalars['String']>;
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  TrialStartedOn?: Maybe<Scalars['DateTime']>;
  TrialEndsOn?: Maybe<Scalars['DateTime']>;
  TrialLengthDays?: Maybe<Scalars['Float']>;
  TrailActive?: Maybe<Scalars['Boolean']>;
  InvoiceAddress?: Maybe<Scalars['String']>;
  InvoicePostalCode?: Maybe<Scalars['String']>;
  InvoiceCity?: Maybe<Scalars['String']>;
  InvoiceNote?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCustomerPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ReceivablesAccount?: Maybe<Scalars['String']>;
  ServiceChargeAcc?: Maybe<Scalars['String']>;
  PaymentDiscDebitAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingAccount?: Maybe<Scalars['String']>;
  AdditionalFeeAccount?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  DebitCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  CreditCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  DebitRoundingAccount?: Maybe<Scalars['String']>;
  CreditRoundingAccount?: Maybe<Scalars['String']>;
  PaymentDiscCreditAcc?: Maybe<Scalars['String']>;
  PaymentToleranceDebitAcc?: Maybe<Scalars['String']>;
  PaymentToleranceCreditAcc?: Maybe<Scalars['String']>;
  AddFeeperLineAccount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ViewAllAccountsonLookup?: Maybe<Scalars['Boolean']>;
  EQMDepositReceiveablesAcc?: Maybe<Scalars['String']>;
  EQMDepositAccount?: Maybe<Scalars['String']>;
  EQMPrepaymentReceivableAcc?: Maybe<Scalars['String']>;
  EQMPrepaymentAccount?: Maybe<Scalars['String']>;
  EQMBankTransferReceiveables?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCustomerProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  StatusChangedBy?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  DimensionConstructProject?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  CombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['String']>;
  CombineInvoiceSubProject?: Maybe<Scalars['Boolean']>;
  SubProjectMandatory?: Maybe<Scalars['Boolean']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  CustomerRentPriceGroup?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  InsuranceCode?: Maybe<Scalars['String']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  Incentive?: Maybe<Scalars['Boolean']>;
  ReturnConfirmationperEMail?: Maybe<Scalars['Boolean']>;
  Inactive?: Maybe<Scalars['Boolean']>;
  ExpectedTurnover?: Maybe<Scalars['Float']>;
  ShiptoInstruction?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  LockPrices?: Maybe<Scalars['Boolean']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  EquipmentListperEMail?: Maybe<Scalars['String']>;
  EmailEquipmentList?: Maybe<Scalars['String']>;
  EmailFrequency?: Maybe<Scalars['String']>;
  InternalComment?: Maybe<Scalars['String']>;
  PopUp?: Maybe<Scalars['String']>;
  InactivateNoSaleMail?: Maybe<Scalars['Boolean']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactPhoneNo?: Maybe<Scalars['String']>;
  CustomerProjectRegistrationNo?: Maybe<Scalars['String']>;
  Deactivated?: Maybe<Scalars['Boolean']>;
  Finished?: Maybe<Scalars['Boolean']>;
  LocationManagerContact?: Maybe<Scalars['String']>;
  ProjectManager?: Maybe<Scalars['String']>;
  Deliverycontact?: Maybe<Scalars['String']>;
  ProjectManagerEmail?: Maybe<Scalars['String']>;
  DeliveryContactEmail?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  ExternallyCreated?: Maybe<Scalars['Boolean']>;
  IsUsingDomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  RentIsActive?: Maybe<Scalars['Boolean']>;
  IsPublicRealbridge?: Maybe<Scalars['Boolean']>;
  SendToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCustomerSubProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  CombineRentalInvoice?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  InsuranceCode?: Maybe<Scalars['String']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ServiceZoneCode?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  LocationId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDeliverytimeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDevopsProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['String']>;
  lastUpdateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDevopsTeamInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDevopsWorkItemInput = {
  discriminator?: Maybe<Scalars['String']>;
  rev?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDimensionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CodeCaption?: Maybe<Scalars['String']>;
  FilterCaption?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  ConsolidationCode?: Maybe<Scalars['String']>;
  MaptoICDimensionCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDimensionValueInput = {
  discriminator?: Maybe<Scalars['String']>;
  DimensionCode?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DimensionValueType?: Maybe<Scalars['Float']>;
  DimensionValueTypeOPTION?: Maybe<Scalars['String']>;
  Totaling?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  ConsolidationCode?: Maybe<Scalars['String']>;
  Indentation?: Maybe<Scalars['Float']>;
  GlobalDimensionNo?: Maybe<Scalars['Float']>;
  MaptoICDimensionCode?: Maybe<Scalars['String']>;
  MaptoICDimensionValueCode?: Maybe<Scalars['String']>;
  DimensionValueID?: Maybe<Scalars['Float']>;
  EQMCostCenterDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectTypeDimension?: Maybe<Scalars['Boolean']>;
  EQMObjectGroupDimension?: Maybe<Scalars['Boolean']>;
  EQMRelatedSalesCostDim?: Maybe<Scalars['Boolean']>;
  EQMRentalContractDimension?: Maybe<Scalars['Boolean']>;
  EQMCustomerDimension?: Maybe<Scalars['Boolean']>;
  EQMCustomerProjectDimension?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDiscussionPostInput = {
  discriminator?: Maybe<Scalars['String']>;
  FromEmail?: Maybe<Scalars['String']>;
  FromName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  Text?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  IsFromCustomer?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDocumentClassificationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDocumentInput = {
  discriminator?: Maybe<Scalars['String']>;
  SignInitiatingUserName?: Maybe<Scalars['String']>;
  SignInitiatingUserGlobalId?: Maybe<Scalars['String']>;
  SignRequestSentToUserName?: Maybe<Scalars['String']>;
  SignRequestSentToUserEmail?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RecName?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentSubType?: Maybe<Scalars['Float']>;
  DocumentSubTypeOPTION?: Maybe<Scalars['String']>;
  Signed?: Maybe<Scalars['Boolean']>;
  SignedByUserGlobalId?: Maybe<Scalars['String']>;
  SignedMethod?: Maybe<Scalars['String']>;
  SignedOn?: Maybe<Scalars['DateTime']>;
  OriginalFileName?: Maybe<Scalars['String']>;
  SignedFileName?: Maybe<Scalars['String']>;
  SignedPrintedName?: Maybe<Scalars['String']>;
  DocumentETag?: Maybe<Scalars['String']>;
  Base64WriteOnly?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  Created?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDocumentRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  RecName?: Maybe<Scalars['String']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentSubType?: Maybe<Scalars['Float']>;
  DocumentSubTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDomainInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ImgURL?: Maybe<Scalars['String']>;
  AppBackgroundImgURL?: Maybe<Scalars['String']>;
  AdminUserEmail?: Maybe<Scalars['String']>;
  EmailDomain?: Maybe<Scalars['String']>;
  IsDemo?: Maybe<Scalars['Boolean']>;
  EnableObjectsLimited?: Maybe<Scalars['Boolean']>;
  EnableParkingSpotsLimited?: Maybe<Scalars['Boolean']>;
  ImageEtag?: Maybe<Scalars['String']>;
  ImageDocGlobalId?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  DomainImageBase64?: Maybe<Scalars['String']>;
  DomainImageBase64Name?: Maybe<Scalars['String']>;
  DomainMainOrientation?: Maybe<Scalars['String']>;
  WebhookURL?: Maybe<Scalars['String']>;
  ParentDomainId?: Maybe<Scalars['String']>;
  AlternativeName?: Maybe<Scalars['String']>;
  FlagImageUrl?: Maybe<Scalars['String']>;
  VisibleInSafetyRespect?: Maybe<Scalars['Boolean']>;
  ReservationsEnabled?: Maybe<Scalars['Boolean']>;
  ReturnReservationsEnabled?: Maybe<Scalars['Boolean']>;
  SendToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDomainIntegrationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  Username?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  AuthType?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  grant_type?: Maybe<Scalars['String']>;
  commonAuthority?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  ConnectionType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  SubType?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  MetaDataJson?: Maybe<Scalars['String']>;
  DisableOrderPolling?: Maybe<Scalars['Boolean']>;
  PreviousMagentoOrderId?: Maybe<Scalars['Float']>;
  PreviousDekraPollingDateTime?: Maybe<Scalars['String']>;
  SchemaVersion?: Maybe<DomainIntegrationSchemaVersionEnum>;
  Online?: Maybe<Scalars['Boolean']>;
  AutoSyncUnsentRecords?: Maybe<Scalars['Boolean']>;
  LatestHeartBeat?: Maybe<Scalars['DateTime']>;
  FirstHeartBeat?: Maybe<Scalars['DateTime']>;
  HeartBeartIntervalMinutes?: Maybe<Scalars['Float']>;
  NoOfReqsInOutboundQueue?: Maybe<Scalars['Float']>;
  NoOfReqsInInboundQueue?: Maybe<Scalars['Float']>;
  NoOfUnprocessableItems?: Maybe<Scalars['Float']>;
  HeartbeatMonitoringEnabled?: Maybe<Scalars['Boolean']>;
  OAuth2_URL?: Maybe<Scalars['String']>;
  OAuth2_Grant_Type?: Maybe<Scalars['String']>;
  OAuth2_Scope?: Maybe<Scalars['String']>;
  OAuth2_Client_Id?: Maybe<Scalars['String']>;
  OAuth2_Client_Secret?: Maybe<Scalars['String']>;
  OAuth2_Redirect_URL?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateDomainSettingsInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmBaseCalendarChangeInput = {
  discriminator?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  RecurringSystem?: Maybe<Scalars['Float']>;
  RecurringSystemOPTION?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Day?: Maybe<Scalars['Float']>;
  DayOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Nonworking?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmBaseCalendarInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CustomizedChangesExist?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmCollectionReturnChargesInput = {
  discriminator?: Maybe<Scalars['String']>;
  ExtDocumentNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  FunctionalTestHeaderNo?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAESMotherMarkedForReturn?: Maybe<Scalars['Boolean']>;
  NBAESObjectNo?: Maybe<Scalars['String']>;
  NBAESObjectDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmCommentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  TableLineNo?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  TableSubtype?: Maybe<Scalars['Float']>;
  TableSubtypeOPTION?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['Float']>;
  TableNameOPTION?: Maybe<Scalars['String']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmCustomizedCalendarChangeInput = {
  discriminator?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['Float']>;
  SourceTypeOPTION?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  AdditionalSourceCode?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  RecurringSystem?: Maybe<Scalars['Float']>;
  RecurringSystemOPTION?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Day?: Maybe<Scalars['Float']>;
  DayOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Nonworking?: Maybe<Scalars['Boolean']>;
  EntryNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmFncTestReturnChargesInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  TransferredToContract?: Maybe<Scalars['Boolean']>;
  TransferredBy?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmLocCostCenterCombInput = {
  discriminator?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  CostCenterCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmManufacturerInput = {
  discriminator?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmManufacturerModelInput = {
  discriminator?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ModelCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CounterReporting?: Maybe<Scalars['Boolean']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  ItemNo?: Maybe<Scalars['String']>;
  ItemDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Capacity?: Maybe<Scalars['Float']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  Effect?: Maybe<Scalars['Float']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectTypeDescription?: Maybe<Scalars['String']>;
  AttachmentNo?: Maybe<Scalars['String']>;
  AttachmentNoSeries?: Maybe<Scalars['String']>;
  NoofObjects?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  SRALink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectCardFlowInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  DateFilter2?: Maybe<Scalars['DateTime']>;
  ServiceItemNo?: Maybe<Scalars['String']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowCostofSale?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowComponentCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowAppreciation?: Maybe<Scalars['Float']>;
  FlowWriteDown?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  Invoiceddays?: Maybe<Scalars['Float']>;
  FlowLinkedOtherCost?: Maybe<Scalars['Float']>;
  FlowLinkedLeasingCost?: Maybe<Scalars['Float']>;
  FlowLinkedRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedServiceCost?: Maybe<Scalars['Float']>;
  FlowLinkedGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedServiceAgrCost?: Maybe<Scalars['Float']>;
  FlowLinkedDepreciation?: Maybe<Scalars['Float']>;
  FlowLinkedUppreciation?: Maybe<Scalars['Float']>;
  FlowLinkedDownpreciation?: Maybe<Scalars['Float']>;
  FlowLinkedServiceIncome?: Maybe<Scalars['Float']>;
  FlowLinkedSrvAgrIncome?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedOtherIncome?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectComponentsInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  LevelofAspiration?: Maybe<Scalars['Float']>;
  LevelofAspirationOPTION?: Maybe<Scalars['String']>;
  PurchaseNo?: Maybe<Scalars['String']>;
  FromService?: Maybe<Scalars['Boolean']>;
  Ordered?: Maybe<Scalars['Boolean']>;
  FatherObjectSold?: Maybe<Scalars['Boolean']>;
  Typeold?: Maybe<Scalars['Float']>;
  TypeoldOPTION?: Maybe<Scalars['String']>;
  LineCost?: Maybe<Scalars['Float']>;
  Assembled?: Maybe<Scalars['Boolean']>;
  NonBillable?: Maybe<Scalars['Boolean']>;
  PostReclass?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectCountHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  DocumentStatus?: Maybe<Scalars['Float']>;
  DocumentStatusOPTION?: Maybe<Scalars['String']>;
  DateCounted?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectCountLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  CountNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectTypeNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DateCounted?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  DocumentStatus?: Maybe<Scalars['Float']>;
  DocumentStatusOPTION?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Selected?: Maybe<Scalars['Boolean']>;
  Corrected?: Maybe<Scalars['Boolean']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['Float']>;
  RentalStatusOPTION?: Maybe<Scalars['String']>;
  RentalContractNo?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  LineMessage?: Maybe<Scalars['String']>;
  CountedObjectNo?: Maybe<Scalars['String']>;
  MemoText?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectGroupCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  ReRentPostingGroup?: Maybe<Scalars['String']>;
  NoofObjects?: Maybe<Scalars['Float']>;
  NoofObjectType?: Maybe<Scalars['Float']>;
  ObjectNos?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['Float']>;
  DepreciationMethodOPTION?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['Float']>;
  StdRentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  FixedAssetSetupGroup?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupNoConcern?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  TaxDepreciationMethod?: Maybe<Scalars['Float']>;
  TaxDepreciationMethodOPTION?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectGroupFlowInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectServiceIntervalInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  DateCompleted?: Maybe<Scalars['DateTime']>;
  TransferedtoOrder?: Maybe<Scalars['String']>;
  TransferedtoWSOrder?: Maybe<Scalars['String']>;
  TransferedtoWSPurchase?: Maybe<Scalars['String']>;
  TransferedtoEQMService?: Maybe<Scalars['String']>;
  TransferredtoWorkOrder?: Maybe<Scalars['String']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  ServiceInitiator1?: Maybe<Scalars['Float']>;
  ServiceInitiator1OPTION?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  PostedDocumentType?: Maybe<Scalars['Float']>;
  PostedDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PostedDocumentNo?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  Certification?: Maybe<Scalars['Boolean']>;
  ObjectId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['Float']>;
  ManufacturerId?: Maybe<Scalars['Float']>;
  ManufacturerModelId?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  ObjectGroupId?: Maybe<Scalars['Float']>;
  ServiceCode?: Maybe<Scalars['String']>;
  MarginDate?: Maybe<Scalars['DateTime']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  ObjectRentalStatus?: Maybe<Scalars['Float']>;
  ObjectRentalStatusOPTION?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  DekraInspectionResult?: Maybe<Scalars['Float']>;
  DekraInspectionResultOPTION?: Maybe<Scalars['String']>;
  DekraInspection?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectSrvIntervalAggInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  DateCompleted?: Maybe<Scalars['DateTime']>;
  TransferedtoOrder?: Maybe<Scalars['String']>;
  TransferedtoWSOrder?: Maybe<Scalars['String']>;
  TransferedtoWSPurchase?: Maybe<Scalars['String']>;
  TransferedtoEQMService?: Maybe<Scalars['String']>;
  TransferredtoWorkOrder?: Maybe<Scalars['String']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  PostedDocumentType?: Maybe<Scalars['Float']>;
  PostedDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PostedDocumentNo?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  ObjectId?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  ManufacturerModelId?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  AssociatedServicePackId?: Maybe<Scalars['String']>;
  TransferredtoOrderId?: Maybe<Scalars['String']>;
  TransferredtoWSOrderId?: Maybe<Scalars['String']>;
  TransferredtoWSPurchId?: Maybe<Scalars['String']>;
  TransferredtoEQMServiceId?: Maybe<Scalars['String']>;
  TransferredtoWorkOrderId?: Maybe<Scalars['String']>;
  RepairId?: Maybe<Scalars['String']>;
  PostedDocumentId?: Maybe<Scalars['String']>;
  ControlTypeId?: Maybe<Scalars['String']>;
  ServiceTypeId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectStatusInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Descripton?: Maybe<Scalars['String']>;
  Answer?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectTypeCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  CounterType?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  SaleDiscGroup?: Maybe<Scalars['String']>;
  RentalDiscGroup?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SalesPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  VATProdPostingGrpRental?: Maybe<Scalars['String']>;
  ReRentPostingGroup?: Maybe<Scalars['String']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['String']>;
  WebPage?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  DefaultFunctionalTest?: Maybe<Scalars['Boolean']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAConnectedToHierarchy?: Maybe<Scalars['String']>;
  NBAMappingNo?: Maybe<Scalars['String']>;
  NBAMappingDescription?: Maybe<Scalars['String']>;
  NBARamirentGroupCode?: Maybe<Scalars['String']>;
  NBAESInspectionInterval?: Maybe<Scalars['String']>;
  NBAESManufacturerModel?: Maybe<Scalars['String']>;
  NBAESBonusGroup?: Maybe<Scalars['String']>;
  NBAESExcludeFromVaction?: Maybe<Scalars['Boolean']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  FixedCostPrice?: Maybe<Scalars['Float']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RetentionLevel?: Maybe<Scalars['Float']>;
  TaxDepreciationMethod?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  CounterType2?: Maybe<Scalars['String']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  InventoryObject?: Maybe<Scalars['Float']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  FixedAssetSetupGroup?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  RentalReservationsQty?: Maybe<Scalars['Float']>;
  RentalDeliveriesQty?: Maybe<Scalars['Float']>;
  ExpectedRentalReturnsQty?: Maybe<Scalars['Float']>;
  RentalReturnsQty?: Maybe<Scalars['Float']>;
  PictureURL?: Maybe<Scalars['String']>;
  PSIDocumentURL?: Maybe<Scalars['String']>;
  NotAvailableForReservation?: Maybe<Scalars['Boolean']>;
  ExpirationDateMandatory?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectTypeComponentsInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  LevelofAspiration?: Maybe<Scalars['Float']>;
  LevelofAspirationOPTION?: Maybe<Scalars['String']>;
  EntryType?: Maybe<Scalars['Float']>;
  EntryTypeOPTION?: Maybe<Scalars['String']>;
  NonBillable?: Maybe<Scalars['Boolean']>;
  InsertAutomatically?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectTypeFlowInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowBookValue?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowAcquisitionCost?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectTypePriceTermInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PercentofMonthlyPrice?: Maybe<Scalars['Float']>;
  FixedTermPrice?: Maybe<Scalars['Float']>;
  Note?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmObjectTypeServiceIntervalInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ServiceTypeCode?: Maybe<Scalars['String']>;
  ServiceInitiator?: Maybe<Scalars['Float']>;
  ServiceInitiatorOPTION?: Maybe<Scalars['String']>;
  ServiceCounter?: Maybe<Scalars['Float']>;
  ServiceDate?: Maybe<Scalars['DateTime']>;
  ServiceDurationDays?: Maybe<Scalars['Float']>;
  AssociatedServicePack?: Maybe<Scalars['String']>;
  CustomerDebit?: Maybe<Scalars['Boolean']>;
  ServiceMargin?: Maybe<Scalars['String']>;
  Repetitive?: Maybe<Scalars['Boolean']>;
  Interval?: Maybe<Scalars['Float']>;
  DateInterval?: Maybe<Scalars['String']>;
  ServiceCounterMargin?: Maybe<Scalars['Float']>;
  ServiceDateMargin?: Maybe<Scalars['String']>;
  ServiceInitiator2?: Maybe<Scalars['Float']>;
  ServiceInitiator2OPTION?: Maybe<Scalars['String']>;
  ServiceCounter2?: Maybe<Scalars['Float']>;
  ServiceCounterMargin2?: Maybe<Scalars['Float']>;
  Interval2?: Maybe<Scalars['Float']>;
  RepairCode?: Maybe<Scalars['String']>;
  ServiceDateMarginBlocked?: Maybe<Scalars['String']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  Certification?: Maybe<Scalars['Boolean']>;
  ServiceCode?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  DekraInspection?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmReRentCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['Float']>;
  RentalStatusOPTION?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  PurchaseDocument?: Maybe<Scalars['Float']>;
  PostedPurchaseInvoice?: Maybe<Scalars['Float']>;
  PostedPurchaseCrMemo?: Maybe<Scalars['Float']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  RentalContractOpen?: Maybe<Scalars['Float']>;
  RentalContractAll?: Maybe<Scalars['Float']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  VendorName?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ReceivedDate?: Maybe<Scalars['DateTime']>;
  ReturnedDate?: Maybe<Scalars['DateTime']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  ObjectGroupId?: Maybe<Scalars['String']>;
  ManufacturerId?: Maybe<Scalars['String']>;
  ManufacturerModelId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmRentalGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmRentalKitComponentsInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  KITNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ChildItem?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmRentalKitInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  KitType?: Maybe<Scalars['Float']>;
  KitTypeOPTION?: Maybe<Scalars['String']>;
  KITPrice?: Maybe<Scalars['Float']>;
  PriceTerm?: Maybe<Scalars['String']>;
  ShowinContract?: Maybe<Scalars['Boolean']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  SpecifyKitComponents?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  DebitforReturnDate?: Maybe<Scalars['Boolean']>;
  IgnoreKITLineinDispatch?: Maybe<Scalars['Boolean']>;
  SuspendUpdateComponents?: Maybe<Scalars['Boolean']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  OnAccountBilling?: Maybe<Scalars['Boolean']>;
  UnitofMeasureId?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  NBAESBonusGroup?: Maybe<Scalars['String']>;
  UseforReporting?: Maybe<Scalars['Boolean']>;
  DefaultKIT?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RentalItemDiscGroup?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmRentalKitLinesInput = {
  discriminator?: Maybe<Scalars['String']>;
  KITNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ChildNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Price?: Maybe<Scalars['Float']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmRentalLineDiscountInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  SalesType?: Maybe<Scalars['Float']>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  UsageType?: Maybe<Scalars['Float']>;
  UsageTypeOPTION?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  NBAESDescription?: Maybe<Scalars['String']>;
  DiscountType?: Maybe<Scalars['Float']>;
  DiscountTypeOPTION?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StepwiseDiscount?: Maybe<Scalars['Float']>;
  UseStepwiseDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  FrameAgreementNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmRentalPriceInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  SalesType?: Maybe<Scalars['Float']>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  RentalType?: Maybe<Scalars['Float']>;
  RentalTypeOPTION?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  StairCode?: Maybe<Scalars['String']>;
  ReturnPriceDisc?: Maybe<Scalars['Float']>;
  ReturnStairCode?: Maybe<Scalars['String']>;
  UsageType?: Maybe<Scalars['Float']>;
  UsageTypeOPTION?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  NBAESDescription?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  ofSalesPrice?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  MinRentalPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MultipleRentalPrices?: Maybe<Scalars['Boolean']>;
  FrameAgreementNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmReturnChargesInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ReqAction?: Maybe<Scalars['Boolean']>;
  UseatFunctionalTest?: Maybe<Scalars['Boolean']>;
  WorkType?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmServiceCardInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ObjectSerialNo?: Maybe<Scalars['String']>;
  FunctionalTestsFlow?: Maybe<Scalars['Float']>;
  WorkshopOrdersFlow?: Maybe<Scalars['Float']>;
  WorkshopPurchasesFlow?: Maybe<Scalars['Float']>;
  WorkshopPurchLinesFlow?: Maybe<Scalars['Float']>;
  PlannedStartDate?: Maybe<Scalars['DateTime']>;
  PlannedStartTime?: Maybe<Scalars['String']>;
  PlannedEndDate?: Maybe<Scalars['DateTime']>;
  PlannedEndTime?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['String']>;
  PostedFunctionalTestsFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopOrdersFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopPurchFlow?: Maybe<Scalars['Float']>;
  AvoidCalendarEntry?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ReturnDocumentNo?: Maybe<Scalars['String']>;
  ContractLineNo?: Maybe<Scalars['Float']>;
  CollectionDocumentNo?: Maybe<Scalars['String']>;
  RepairCode?: Maybe<Scalars['String']>;
  MainResource?: Maybe<Scalars['String']>;
  MainCustomer?: Maybe<Scalars['String']>;
  MainVendor?: Maybe<Scalars['String']>;
  Manufacturer?: Maybe<Scalars['String']>;
  ManufacturerModel?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  InternalCustomerNo?: Maybe<Scalars['String']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PurchaseDocumentType?: Maybe<Scalars['Float']>;
  PurchaseDocumentTypeOPTION?: Maybe<Scalars['String']>;
  PurchaseDocumentNo?: Maybe<Scalars['String']>;
  PurchaseLineNo?: Maybe<Scalars['Float']>;
  NoSeries?: Maybe<Scalars['String']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenterCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  FinishedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  CreatedTime?: Maybe<Scalars['String']>;
  StartedBy?: Maybe<Scalars['String']>;
  NoofServiceOrders?: Maybe<Scalars['Float']>;
  NoofPostedServiceOrders?: Maybe<Scalars['Float']>;
  ControlTestsFlow?: Maybe<Scalars['Float']>;
  PostedControlTestFlow?: Maybe<Scalars['Float']>;
  FunctionalTestPictures?: Maybe<Scalars['Float']>;
  ControlTypeCode?: Maybe<Scalars['String']>;
  WorkOrderFlow?: Maybe<Scalars['Float']>;
  NoofReturnCharges?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmServiceTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  ServiceType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ParallelwithRental?: Maybe<Scalars['Boolean']>;
  NeedsWorkshopTime?: Maybe<Scalars['Boolean']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmTransferHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  TransferfromName?: Maybe<Scalars['String']>;
  TransferfromName2?: Maybe<Scalars['String']>;
  TransferfromAddress?: Maybe<Scalars['String']>;
  TransferfromAddress2?: Maybe<Scalars['String']>;
  TransferfromPostCode?: Maybe<Scalars['String']>;
  TransferfromCity?: Maybe<Scalars['String']>;
  TransferfromCounty?: Maybe<Scalars['String']>;
  TrsffromCountryRegionCode?: Maybe<Scalars['String']>;
  TransfertoCode?: Maybe<Scalars['String']>;
  TransfertoName?: Maybe<Scalars['String']>;
  TransfertoName2?: Maybe<Scalars['String']>;
  TransfertoAddress?: Maybe<Scalars['String']>;
  TransfertoAddress2?: Maybe<Scalars['String']>;
  TransfertoPostCode?: Maybe<Scalars['String']>;
  TransfertoCity?: Maybe<Scalars['String']>;
  TransfertoCounty?: Maybe<Scalars['String']>;
  TrsftoCountryRegionCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  InTransitCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  LastShipmentNo?: Maybe<Scalars['String']>;
  LastReceiptNo?: Maybe<Scalars['String']>;
  TransferfromContact?: Maybe<Scalars['String']>;
  TransfertoContact?: Maybe<Scalars['String']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  TransferOrderReference?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AssignedEmployeeNo?: Maybe<Scalars['String']>;
  EntryExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  DirectTransfer?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  RentalDispatchType?: Maybe<Scalars['Float']>;
  RentalDispatchTypeOPTION?: Maybe<Scalars['String']>;
  RentalDispatchOrderNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  LocationFilter?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  HasShippedLines?: Maybe<Scalars['Boolean']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  ReadyForPostShipment?: Maybe<Scalars['Boolean']>;
  ReadyForPostReceipt?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmTransferLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  QtytoReceive?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityReceived?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtytoReceiveBase?: Maybe<Scalars['Float']>;
  QtyReceivedBase?: Maybe<Scalars['Float']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  InTransitCode?: Maybe<Scalars['String']>;
  QtyinTransitBase?: Maybe<Scalars['Float']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  TransfertoCode?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  DerivedFromLineNo?: Maybe<Scalars['Float']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  ReservedQuantityInbnd?: Maybe<Scalars['Float']>;
  ReservedQuantityOutbnd?: Maybe<Scalars['Float']>;
  ReservedQtyInbndBase?: Maybe<Scalars['Float']>;
  ReservedQtyOutbndBase?: Maybe<Scalars['Float']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ReservedQuantityShipped?: Maybe<Scalars['Float']>;
  ReservedQtyShippedBase?: Maybe<Scalars['Float']>;
  DirectTransfer?: Maybe<Scalars['Boolean']>;
  ChgCurrLocationOnly?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  AllocateOwnershipStatus?: Maybe<Scalars['Float']>;
  AllocateOwnershipStatusOPTION?: Maybe<Scalars['String']>;
  AllocateCreateFA?: Maybe<Scalars['Boolean']>;
  AllocateSerialNo?: Maybe<Scalars['String']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  RentalDispatchType?: Maybe<Scalars['Float']>;
  RentalDispatchTypeOPTION?: Maybe<Scalars['String']>;
  RentalDispatchOrderNo?: Maybe<Scalars['String']>;
  RentalDispatchOrderLineNo?: Maybe<Scalars['Float']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  WhseInbndOtsdgQtyBase?: Maybe<Scalars['Float']>;
  WhseOutbndOtsdgQtyBase?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  TransferfromBinCode?: Maybe<Scalars['String']>;
  TransferToBinCode?: Maybe<Scalars['String']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmTypeFunctionalTestInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  TestIssue?: Maybe<Scalars['String']>;
  AdditionalInstructions?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  NAAllowed?: Maybe<Scalars['Boolean']>;
  ObjectTypeId?: Maybe<Scalars['String']>;
  NBAESHeadline?: Maybe<Scalars['Boolean']>;
  NBAESSkillReq?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmWorkHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  FunctionalTestNo?: Maybe<Scalars['String']>;
  NoFailedTestIssues?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  InternalCustomerNo?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  EQMServiceCardNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  ObjectSerialNo?: Maybe<Scalars['String']>;
  ObjectCounter?: Maybe<Scalars['Float']>;
  ExternalWorkDescription?: Maybe<Scalars['String']>;
  InternalWorkDescription?: Maybe<Scalars['String']>;
  FromContractNo?: Maybe<Scalars['String']>;
  FromContractLineNo?: Maybe<Scalars['Float']>;
  FromCustomerNo?: Maybe<Scalars['String']>;
  FromCustomerProjectNo?: Maybe<Scalars['String']>;
  FromCollectionDocNo?: Maybe<Scalars['String']>;
  FromCollectionLineNo?: Maybe<Scalars['Float']>;
  FromPostedCollDocNo?: Maybe<Scalars['String']>;
  FromPostedCollLineNo?: Maybe<Scalars['Float']>;
  FromReturnDocumentNo?: Maybe<Scalars['String']>;
  FromCostCenter?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  AssignedEmployeeNo?: Maybe<Scalars['String']>;
  RepairCode?: Maybe<Scalars['String']>;
  NextCertification?: Maybe<Scalars['DateTime']>;
  NextService?: Maybe<Scalars['DateTime']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocType?: Maybe<Scalars['Float']>;
  ChargeDocTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocNo?: Maybe<Scalars['String']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenterCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CombineInvoices?: Maybe<Scalars['Boolean']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CombineInvperRespCenter?: Maybe<Scalars['Boolean']>;
  PlannedStartDate?: Maybe<Scalars['DateTime']>;
  PlannedStartTime?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['String']>;
  CompletedDate?: Maybe<Scalars['DateTime']>;
  CompletedTime?: Maybe<Scalars['String']>;
  ApprovedbyUser?: Maybe<Scalars['String']>;
  AllowPartialInvoicing?: Maybe<Scalars['Boolean']>;
  ExternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  ExternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  InternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  InternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  Pictures?: Maybe<Scalars['Float']>;
  UnpostedWorkOrderLines?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateEqmWorkLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LineStatus?: Maybe<Scalars['Float']>;
  LineStatusOPTION?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  OverruledUnitPrice?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ExternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  ExternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocType?: Maybe<Scalars['Float']>;
  ChargeDocTypeOPTION?: Maybe<Scalars['String']>;
  ChargeDocNo?: Maybe<Scalars['String']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  Transferred?: Maybe<Scalars['Boolean']>;
  TransferredtoDocNo?: Maybe<Scalars['String']>;
  TransferredtoDocLineNo?: Maybe<Scalars['Float']>;
  PostedSalesLine?: Maybe<Scalars['String']>;
  InternalCustomerPriceGroup?: Maybe<Scalars['String']>;
  InternalCustomerDiscGroup?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  CreatedfromAPI?: Maybe<Scalars['Boolean']>;
  PatchedfromAPI?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateErrandInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<ErrandTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ErrandType?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Dim1?: Maybe<Scalars['String']>;
  Dim2?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Status?: Maybe<ErrandStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ActionBlob?: Maybe<Scalars['String']>;
  MainSource?: Maybe<Scalars['String']>;
  CreatedFromRecord?: Maybe<Scalars['String']>;
  TimeCost?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateErrandLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ConnectedToRecord?: Maybe<Scalars['String']>;
  Note?: Maybe<Scalars['String']>;
  Type?: Maybe<ErrandLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateErrorReportInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  ReasonText?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Caller?: Maybe<Scalars['String']>;
  CallerPhone?: Maybe<Scalars['String']>;
  CallerEmail?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ActionCode?: Maybe<Scalars['String']>;
  ActionText?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  TurnedOverTo?: Maybe<Scalars['String']>;
  Recived?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFeedbackInput = {
  discriminator?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  ByUserEmail?: Maybe<Scalars['String']>;
  ByUserName?: Maybe<Scalars['String']>;
  FileGlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  Processed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFeemappingInput = {
  discriminator?: Maybe<Scalars['String']>;
  LCYPrice?: Maybe<Scalars['Float']>;
  LCYPriceIncVat?: Maybe<Scalars['Float']>;
  EURPrice?: Maybe<Scalars['Float']>;
  Type?: Maybe<FeemappingTypeEnum>;
  Code?: Maybe<Scalars['String']>;
  Unit?: Maybe<Scalars['String']>;
  Fee?: Maybe<Scalars['String']>;
  PriceType?: Maybe<FeemappingPriceTypeEnum>;
  TypeOfFee?: Maybe<FeemappingTypeOfFeeEnum>;
  FeeItemNumber?: Maybe<Scalars['String']>;
  BaseQuantity?: Maybe<Scalars['Float']>;
  WebDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFieldOrderInput = {
  discriminator?: Maybe<Scalars['String']>;
  FieldOrderCode?: Maybe<Scalars['String']>;
  ExecutionTime?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  WorkDescription?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Status?: Maybe<FieldOrderStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  AssignmentType?: Maybe<FieldOrderAssignmentTypeEnum>;
  AssignmentTypeOPTION?: Maybe<Scalars['String']>;
  AssignedTo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostedInvoiceNo?: Maybe<Scalars['String']>;
  ReversedVAT?: Maybe<Scalars['Boolean']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactPhone?: Maybe<Scalars['String']>;
  Instructions?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  NoteFromPreformer?: Maybe<Scalars['String']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFieldOrderLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  FieldOrderCode?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<FieldOrderLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFileInput = {
  discriminator?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  base64?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFunctionTestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FinishDate?: Maybe<Scalars['String']>;
  Status?: Maybe<FunctionTestHeaderStatusEnum>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<FunctionTestHeaderApprovalStatusEnum>;
  ApprovalStatusOPTION?: Maybe<Scalars['String']>;
  EQMServiceHeader?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  FromContractNo?: Maybe<Scalars['String']>;
  FromCustomerNo?: Maybe<Scalars['String']>;
  FromReturnDocumentNo?: Maybe<Scalars['String']>;
  FromCollectionDocNo?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  DimCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  FinishTime?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
  FinishedBy?: Maybe<Scalars['String']>;
  TypeOfWash?: Maybe<Scalars['String']>;
  ExternalPart?: Maybe<Scalars['Boolean']>;
  FncFailed?: Maybe<Scalars['Boolean']>;
  HourMeterIndicator?: Maybe<Scalars['Float']>;
  Note?: Maybe<Scalars['String']>;
  FromCustomerName?: Maybe<Scalars['String']>;
  ObjectDescription?: Maybe<Scalars['String']>;
  WarrantyDate?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  SignatureFromEdge?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateFunctionTestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  ObjectNo?: Maybe<Scalars['String']>;
  TestIssue?: Maybe<Scalars['String']>;
  CurrentStatus?: Maybe<Scalars['String']>;
  AdditionalInstructions?: Maybe<Scalars['String']>;
  SpecificMeasure?: Maybe<Scalars['Boolean']>;
  DirectMeasure?: Maybe<Scalars['Boolean']>;
  InspectionTimeInterval?: Maybe<Scalars['Boolean']>;
  ToCorrect?: Maybe<Scalars['String']>;
  WorkShopOrderNo?: Maybe<Scalars['String']>;
  WorkShopOrderFinished?: Maybe<Scalars['Boolean']>;
  Headline?: Maybe<Scalars['Boolean']>;
  NoteFunctionTest?: Maybe<Scalars['String']>;
  NoteDate?: Maybe<Scalars['String']>;
  NoteOwner?: Maybe<Scalars['String']>;
  MoveNote?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  Mandatory?: Maybe<Scalars['Boolean']>;
  TimeEstimation?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  TestedApproved?: Maybe<Scalars['Boolean']>;
  TestedExecuted?: Maybe<Scalars['Boolean']>;
  ExecutedBy?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateGenBusinessPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefVATBusPostingGroup?: Maybe<Scalars['String']>;
  AutoInsertDefault?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateGenJournalLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  JournalTemplateName?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  AccountType?: Maybe<Scalars['Float']>;
  AccountTypeOPTION?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  VAT?: Maybe<Scalars['Float']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['Float']>;
  CreditAmount?: Maybe<Scalars['Float']>;
  AmountLCY?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  SalesPurchLCY?: Maybe<Scalars['Float']>;
  ProfitLCY?: Maybe<Scalars['Float']>;
  InvDiscountLCY?: Maybe<Scalars['Float']>;
  BilltoPaytoNo?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  SalespersPurchCode?: Maybe<Scalars['String']>;
  PendingApproval?: Maybe<Scalars['Boolean']>;
  SourceCode?: Maybe<Scalars['String']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  VATAmount?: Maybe<Scalars['Float']>;
  VATPosting?: Maybe<Scalars['Float']>;
  VATPostingOPTION?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  BusinessUnitCode?: Maybe<Scalars['String']>;
  JournalBatchName?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  RecurringMethod?: Maybe<Scalars['Float']>;
  RecurringMethodOPTION?: Maybe<Scalars['String']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  RecurringFrequency?: Maybe<Scalars['String']>;
  AllocatedAmtLCY?: Maybe<Scalars['Float']>;
  GenPostingType?: Maybe<Scalars['Float']>;
  GenPostingTypeOPTION?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  AllowApplication?: Maybe<Scalars['Boolean']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  BalGenPostingType?: Maybe<Scalars['Float']>;
  BalGenPostingTypeOPTION?: Maybe<Scalars['String']>;
  BalGenBusPostingGroup?: Maybe<Scalars['String']>;
  BalGenProdPostingGroup?: Maybe<Scalars['String']>;
  BalVATCalculationType?: Maybe<Scalars['Float']>;
  BalVATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  BalVAT?: Maybe<Scalars['Float']>;
  BalVATAmount?: Maybe<Scalars['Float']>;
  BankPaymentType?: Maybe<Scalars['Float']>;
  BankPaymentTypeOPTION?: Maybe<Scalars['String']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  BalVATBaseAmount?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  CheckPrinted?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['Float']>;
  SourceTypeOPTION?: Maybe<Scalars['String']>;
  SourceNo?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  BalTaxAreaCode?: Maybe<Scalars['String']>;
  BalTaxLiable?: Maybe<Scalars['Boolean']>;
  BalTaxGroupCode?: Maybe<Scalars['String']>;
  BalUseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BalVATBusPostingGroup?: Maybe<Scalars['String']>;
  BalVATProdPostingGroup?: Maybe<Scalars['String']>;
  AdditionalCurrencyPosting?: Maybe<Scalars['Float']>;
  AdditionalCurrencyPostingOPTION?: Maybe<Scalars['String']>;
  FAAddCurrencyFactor?: Maybe<Scalars['Float']>;
  SourceCurrencyCode?: Maybe<Scalars['String']>;
  SourceCurrencyAmount?: Maybe<Scalars['Float']>;
  SourceCurrVATBaseAmount?: Maybe<Scalars['Float']>;
  SourceCurrVATAmount?: Maybe<Scalars['Float']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  VATAmountLCY?: Maybe<Scalars['Float']>;
  VATBaseAmountLCY?: Maybe<Scalars['Float']>;
  BalVATAmountLCY?: Maybe<Scalars['Float']>;
  BalVATBaseAmountLCY?: Maybe<Scalars['Float']>;
  ReversingEntry?: Maybe<Scalars['Boolean']>;
  AllowZeroAmountPosting?: Maybe<Scalars['Boolean']>;
  ShiptoOrderAddressCode?: Maybe<Scalars['String']>;
  VATDifference?: Maybe<Scalars['Float']>;
  BalVATDifference?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  ICPartnerGLAccNo?: Maybe<Scalars['String']>;
  ICPartnerTransactionNo?: Maybe<Scalars['Float']>;
  SelltoBuyfromNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Boolean']>;
  FinancialVoid?: Maybe<Scalars['Boolean']>;
  CopyVATSetuptoJnlLines?: Maybe<Scalars['Boolean']>;
  VATBaseBeforePmtDisc?: Maybe<Scalars['Float']>;
  OrigPmtDiscPossible?: Maybe<Scalars['Float']>;
  OrigPmtDiscPossibleLCY?: Maybe<Scalars['Float']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  AppliestoExtDocNo?: Maybe<Scalars['String']>;
  KeepDescription?: Maybe<Scalars['Boolean']>;
  RecipientBankAccount?: Maybe<Scalars['String']>;
  MessagetoRecipient?: Maybe<Scalars['String']>;
  ExportedtoPaymentFile?: Maybe<Scalars['Boolean']>;
  HasPaymentExportError?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobQuantity?: Maybe<Scalars['Float']>;
  JobUnitCostLCY?: Maybe<Scalars['Float']>;
  JobLineDiscount?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobUnitOfMeasureCode?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobUnitCost?: Maybe<Scalars['Float']>;
  JobTotalCost?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobTotalCostLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  JobPlanningLineNo?: Maybe<Scalars['Float']>;
  JobRemainingQty?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DataExchEntryNo?: Maybe<Scalars['Float']>;
  PayerInformation?: Maybe<Scalars['String']>;
  TransactionInformation?: Maybe<Scalars['String']>;
  DataExchLineNo?: Maybe<Scalars['Float']>;
  AppliedAutomatically?: Maybe<Scalars['Boolean']>;
  LinkedTableID?: Maybe<Scalars['Float']>;
  LinkedSystemID?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  DeferralLineNo?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  NoofDepreciationDays?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  FAReclassificationEntry?: Maybe<Scalars['Boolean']>;
  FAErrorEntryNo?: Maybe<Scalars['Float']>;
  IndexEntry?: Maybe<Scalars['Boolean']>;
  SourceLineNo?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  CheckExported?: Maybe<Scalars['Boolean']>;
  CheckTransmitted?: Maybe<Scalars['Boolean']>;
  ReverseDateCalculation?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  AppliestoInvoiceId?: Maybe<Scalars['String']>;
  ContactGraphId?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  JournalBatchId?: Maybe<Scalars['String']>;
  PaymentMethodId?: Maybe<Scalars['String']>;
  BalanceAccountId?: Maybe<Scalars['String']>;
  VendorId?: Maybe<Scalars['String']>;
  SourcePostingDate?: Maybe<Scalars['DateTime']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBDirectDebitPackNo?: Maybe<Scalars['Float']>;
  PEBInformationType?: Maybe<Scalars['Float']>;
  PEBInformationTypeOPTION?: Maybe<Scalars['String']>;
  PEBLastGLEntryNo?: Maybe<Scalars['Float']>;
  PEBLastGLRegEntryNo?: Maybe<Scalars['Float']>;
  PEBInformation?: Maybe<Scalars['String']>;
  PEBSIEImportCode?: Maybe<Scalars['String']>;
  PEBOCRNo?: Maybe<Scalars['String']>;
  PEBExtDocNonotMandat?: Maybe<Scalars['Boolean']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMSkipExtraPost?: Maybe<Scalars['Boolean']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMCustomerDepositNo?: Maybe<Scalars['String']>;
  EQMDisposalType?: Maybe<Scalars['Float']>;
  EQMDisposalTypeOPTION?: Maybe<Scalars['String']>;
  EQMDepositEntry?: Maybe<Scalars['Boolean']>;
  EQMAdvancePaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMAdvPaymentAmountLCY?: Maybe<Scalars['Float']>;
  EQMRentalEntry?: Maybe<Scalars['Boolean']>;
  EQMWorkshopEntry?: Maybe<Scalars['Boolean']>;
  EQMObjectErrorEntryNo?: Maybe<Scalars['Float']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMDepositPayment?: Maybe<Scalars['Boolean']>;
  EQMTransactionID?: Maybe<Scalars['Float']>;
  EQMFANo?: Maybe<Scalars['String']>;
  EQMFADepreciationBookCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateGenProductPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefVATProdPostingGroup?: Maybe<Scalars['String']>;
  AutoInsertDefault?: Maybe<Scalars['Boolean']>;
  EQMPercentofMonthlyPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateInspectionInput = {
  discriminator?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  InspectionDate?: Maybe<Scalars['DateTime']>;
  ObjectStatus?: Maybe<Scalars['Float']>;
  ObjectStatusOPTION?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateInspectionUserInput = {
  discriminator?: Maybe<Scalars['String']>;
  SalesPerson?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateIntegrationCollectionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  LatestHeartBeat?: Maybe<Scalars['DateTime']>;
  FirstHeartBeat?: Maybe<Scalars['DateTime']>;
  HeartBeartIntervalMinutes?: Maybe<Scalars['Float']>;
  HeartbeatMonitoringEnabled?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateInventoryPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateInvitationInput = {
  discriminator?: Maybe<Scalars['String']>;
  UserEmail?: Maybe<Scalars['String']>;
  Status?: Maybe<InvitationStatusEnum>;
  Type?: Maybe<InvitationTypeEnum>;
  Role?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  ExpiresOn?: Maybe<Scalars['DateTime']>;
  AcceptedOn?: Maybe<Scalars['DateTime']>;
  AcceptedByUserEmail?: Maybe<Scalars['String']>;
  CreatedByUserEmail?: Maybe<Scalars['String']>;
  CreateByUserName?: Maybe<Scalars['String']>;
  InvitationCode?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  Deactivated?: Maybe<Scalars['Boolean']>;
  IOSAppLink?: Maybe<Scalars['String']>;
  AndroidAppLink?: Maybe<Scalars['String']>;
  IOSAppCode?: Maybe<Scalars['String']>;
  IsOnAppStore?: Maybe<Scalars['Boolean']>;
  IsOnPlayStore?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateInvoiceHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DocumentExchangeIdentifier?: Maybe<Scalars['String']>;
  DocumentExchangeStatus?: Maybe<Scalars['String']>;
  DocExchOriginalIdentifier?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  CustLedgerEntryNo?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['String']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMCombineCustomerProj?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMInvoiceDocType?: Maybe<Scalars['String']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  EQMLinkedCreditMemos?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateInvoiceLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  LineDiscountCalculation?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMUnitPrice?: Maybe<Scalars['Float']>;
  EQMLineDiscount?: Maybe<Scalars['Float']>;
  EQMLineDiscountAmount?: Maybe<Scalars['Float']>;
  EQMLineAmount?: Maybe<Scalars['Float']>;
  EQMReturnFromDate?: Maybe<Scalars['DateTime']>;
  EQMReturnToDate?: Maybe<Scalars['DateTime']>;
  EQMReturnWholePeriod?: Maybe<Scalars['Boolean']>;
  EQMReturnRentalDays?: Maybe<Scalars['Float']>;
  EQMReturnFreeDays?: Maybe<Scalars['Float']>;
  EQMTransferThisLine?: Maybe<Scalars['Boolean']>;
  EQMReturnQuantity?: Maybe<Scalars['Float']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMRentalLineType?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMAmountCost?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMRefOrigDoc?: Maybe<Scalars['String']>;
  EQMRefOrigLineNo?: Maybe<Scalars['Float']>;
  EQMManuallyChanged?: Maybe<Scalars['Boolean']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMAdvancePaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemCategoryInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ParentCategory?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Indentation?: Maybe<Scalars['Float']>;
  PresentationOrder?: Maybe<Scalars['Float']>;
  HasChildren?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  DefGenProdPostingGroup?: Maybe<Scalars['String']>;
  DefInventoryPostingGroup?: Maybe<Scalars['String']>;
  DefTaxGroupCode?: Maybe<Scalars['String']>;
  DefCostingMethod?: Maybe<Scalars['Float']>;
  DefCostingMethodOPTION?: Maybe<Scalars['String']>;
  DefVATProdPostingGroup?: Maybe<Scalars['String']>;
  DefReRentRProdPostGrp?: Maybe<Scalars['String']>;
  DefReRentSProdPostGrp?: Maybe<Scalars['String']>;
  EQMDefGenRntlPostGrp?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemDiscGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  No2?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  PriceUnitConversion?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  ShelfNo?: Maybe<Scalars['String']>;
  ItemDiscGroup?: Maybe<Scalars['String']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  CommissionGroup?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  CostingMethod?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  CostisAdjusted?: Maybe<Scalars['Boolean']>;
  AllowOnlineAdjustment?: Maybe<Scalars['Boolean']>;
  VendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  ReorderPoint?: Maybe<Scalars['Float']>;
  MaximumInventory?: Maybe<Scalars['Float']>;
  ReorderQuantity?: Maybe<Scalars['Float']>;
  AlternativeItemNo?: Maybe<Scalars['String']>;
  UnitListPrice?: Maybe<Scalars['Float']>;
  DutyDue?: Maybe<Scalars['Float']>;
  DutyCode?: Maybe<Scalars['String']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  Durability?: Maybe<Scalars['String']>;
  FreightType?: Maybe<Scalars['String']>;
  TariffNo?: Maybe<Scalars['String']>;
  DutyUnitConversion?: Maybe<Scalars['Float']>;
  CountryRegionPurchasedCode?: Maybe<Scalars['String']>;
  BudgetQuantity?: Maybe<Scalars['Float']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  BudgetProfit?: Maybe<Scalars['Float']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  BlockReason?: Maybe<Scalars['String']>;
  LastDateTimeModified?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  CountryRegionofOriginCode?: Maybe<Scalars['String']>;
  AutomaticExtTexts?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  VATProductPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  StockoutWarning?: Maybe<Scalars['String']>;
  PreventNegativeInventory?: Maybe<Scalars['String']>;
  ApplicationWkshUserID?: Maybe<Scalars['String']>;
  AssemblyPolicy?: Maybe<Scalars['String']>;
  GTIN?: Maybe<Scalars['String']>;
  DefaultDeferralTemplateCode?: Maybe<Scalars['String']>;
  LowLevelCode?: Maybe<Scalars['Float']>;
  LotSize?: Maybe<Scalars['Float']>;
  SerialNos?: Maybe<Scalars['String']>;
  LastUnitCostCalcDate?: Maybe<Scalars['DateTime']>;
  RolledupMaterialCost?: Maybe<Scalars['Float']>;
  RolledupCapacityCost?: Maybe<Scalars['Float']>;
  Scrap?: Maybe<Scalars['Float']>;
  InventoryValueZero?: Maybe<Scalars['Boolean']>;
  DiscreteOrderQuantity?: Maybe<Scalars['Float']>;
  MinimumOrderQuantity?: Maybe<Scalars['Float']>;
  MaximumOrderQuantity?: Maybe<Scalars['Float']>;
  SafetyStockQuantity?: Maybe<Scalars['Float']>;
  OrderMultiple?: Maybe<Scalars['Float']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  FlushingMethod?: Maybe<Scalars['String']>;
  ReplenishmentSystem?: Maybe<Scalars['String']>;
  RoundingPrecision?: Maybe<Scalars['Float']>;
  SalesUnitofMeasure?: Maybe<Scalars['String']>;
  PurchUnitofMeasure?: Maybe<Scalars['String']>;
  TimeBucket?: Maybe<Scalars['String']>;
  ReorderingPolicy?: Maybe<Scalars['String']>;
  IncludeInventory?: Maybe<Scalars['Boolean']>;
  ManufacturingPolicy?: Maybe<Scalars['String']>;
  ReschedulingPeriod?: Maybe<Scalars['String']>;
  LotAccumulationPeriod?: Maybe<Scalars['String']>;
  DampenerPeriod?: Maybe<Scalars['String']>;
  DampenerQuantity?: Maybe<Scalars['Float']>;
  OverflowLevel?: Maybe<Scalars['Float']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  CategoryCode?: Maybe<Scalars['String']>;
  CreatedFromNonstockItem?: Maybe<Scalars['Boolean']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ItemTrackingCode?: Maybe<Scalars['String']>;
  LotNos?: Maybe<Scalars['String']>;
  ExpirationCalculation?: Maybe<Scalars['String']>;
  WarehouseClassCode?: Maybe<Scalars['String']>;
  SpecialEquipmentCode?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  PutawayUnitofMeasureCode?: Maybe<Scalars['String']>;
  PhysInvtCountingPeriodCode?: Maybe<Scalars['String']>;
  LastCountingPeriodUpdate?: Maybe<Scalars['String']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  NextCountingStartDate?: Maybe<Scalars['DateTime']>;
  NextCountingEndDate?: Maybe<Scalars['DateTime']>;
  Id?: Maybe<Scalars['String']>;
  UnitofMeasureId?: Maybe<Scalars['String']>;
  TaxGroupId?: Maybe<Scalars['String']>;
  EQMItemNoConcern?: Maybe<Scalars['String']>;
  EQMDiamondBladeItem?: Maybe<Scalars['String']>;
  EQMDiamondPostingGroup?: Maybe<Scalars['String']>;
  EQMDimensionRentalType?: Maybe<Scalars['String']>;
  EQMMeasurement?: Maybe<Scalars['Float']>;
  EQMReRentRProdPostGrp?: Maybe<Scalars['String']>;
  EQMReRentSProdPostGrp?: Maybe<Scalars['String']>;
  EQMObjectBOMPostingGroup?: Maybe<Scalars['String']>;
  EQMVATRntlProdPostGrp?: Maybe<Scalars['String']>;
  EQMDepositAmount?: Maybe<Scalars['Float']>;
  EQMReplacementPrice?: Maybe<Scalars['Float']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMDescription3?: Maybe<Scalars['String']>;
  EQMDescription4?: Maybe<Scalars['String']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMBlockedRental?: Maybe<Scalars['Boolean']>;
  EQMExpAvailAfterEndDate?: Maybe<Scalars['Boolean']>;
  EQMCreditonEarlyReturn?: Maybe<Scalars['Float']>;
  EQMStdRentalChargeDays?: Maybe<Scalars['String']>;
  EQMDebitonReturnDate?: Maybe<Scalars['Boolean']>;
  EQMBaseCalendarCode?: Maybe<Scalars['String']>;
  EQMGenRentalPostingGroup?: Maybe<Scalars['String']>;
  EQMRentalUnitPrice?: Maybe<Scalars['Float']>;
  EQMDefaultRentalItem?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRentalItemDiscGroup?: Maybe<Scalars['String']>;
  EQMDimensionRentalGroup?: Maybe<Scalars['String']>;
  EQMGenRepurchasePostGrp?: Maybe<Scalars['String']>;
  EQMSquareMeter?: Maybe<Scalars['Float']>;
  EQMFilterLocationCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  ProductionBOMNo?: Maybe<Scalars['String']>;
  SingleLevelMaterialCost?: Maybe<Scalars['Float']>;
  SingleLevelCapacityCost?: Maybe<Scalars['Float']>;
  SingleLevelSubcontrdCost?: Maybe<Scalars['Float']>;
  SingleLevelCapOvhdCost?: Maybe<Scalars['Float']>;
  SingleLevelMfgOvhdCost?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RolledupSubcontractedCost?: Maybe<Scalars['Float']>;
  RolledupMfgOvhdCost?: Maybe<Scalars['Float']>;
  RolledupCapOverheadCost?: Maybe<Scalars['Float']>;
  OrderTrackingPolicy?: Maybe<Scalars['String']>;
  Critical?: Maybe<Scalars['Boolean']>;
  CommonItemNo?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DropShipmentFilter?: Maybe<Scalars['Boolean']>;
  BinFilter?: Maybe<Scalars['String']>;
  VariantFilter?: Maybe<Scalars['String']>;
  LotNoFilter?: Maybe<Scalars['String']>;
  SerialNoFilter?: Maybe<Scalars['String']>;
  EQMRentalSaleFilter?: Maybe<Scalars['Boolean']>;
  ProductionForecastName?: Maybe<Scalars['String']>;
  ComponentForecast?: Maybe<Scalars['Boolean']>;
  AssemblyBOM?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CostisPostedtoGL?: Maybe<Scalars['Boolean']>;
  Inventory?: Maybe<Scalars['Float']>;
  NetInvoicedQty?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  PurchasesQty?: Maybe<Scalars['Float']>;
  SalesQty?: Maybe<Scalars['Float']>;
  PositiveAdjmtQty?: Maybe<Scalars['Float']>;
  NegativeAdjmtQty?: Maybe<Scalars['Float']>;
  PurchasesLCY?: Maybe<Scalars['Float']>;
  SalesLCY?: Maybe<Scalars['Float']>;
  PositiveAdjmtLCY?: Maybe<Scalars['Float']>;
  NegativeAdjmtLCY?: Maybe<Scalars['Float']>;
  COGSLCY?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  QtyonSalesOrder?: Maybe<Scalars['Float']>;
  TransferredQty?: Maybe<Scalars['Float']>;
  TransferredLCY?: Maybe<Scalars['Float']>;
  ReservedQtyonInventory?: Maybe<Scalars['Float']>;
  ReservedQtyonPurchOrders?: Maybe<Scalars['Float']>;
  ReservedQtyonSalesOrders?: Maybe<Scalars['Float']>;
  ResQtyonOutboundTransfer?: Maybe<Scalars['Float']>;
  ResQtyonInboundTransfer?: Maybe<Scalars['Float']>;
  ResQtyonSalesReturns?: Maybe<Scalars['Float']>;
  ResQtyonPurchReturns?: Maybe<Scalars['Float']>;
  CostofOpenProductionOrders?: Maybe<Scalars['Float']>;
  ResQtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  ResQtyonAsmComp?: Maybe<Scalars['Float']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonAsmComponent?: Maybe<Scalars['Float']>;
  QtyonJobOrder?: Maybe<Scalars['Float']>;
  ResQtyonJobOrder?: Maybe<Scalars['Float']>;
  ScheduledReceiptQty?: Maybe<Scalars['Float']>;
  ScheduledNeedQty?: Maybe<Scalars['Float']>;
  ReservedQtyonProdOrder?: Maybe<Scalars['Float']>;
  ResQtyonProdOrderComp?: Maybe<Scalars['Float']>;
  ResQtyonReqLine?: Maybe<Scalars['Float']>;
  PlanningTransferShipQty?: Maybe<Scalars['Float']>;
  PlanningWorksheetQty?: Maybe<Scalars['Float']>;
  StockkeepingUnitExists?: Maybe<Scalars['Boolean']>;
  SubstitutesExist?: Maybe<Scalars['Boolean']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  TransOrdReceiptQty?: Maybe<Scalars['Float']>;
  TransOrdShipmentQty?: Maybe<Scalars['Float']>;
  QtyAssignedtoship?: Maybe<Scalars['Float']>;
  QtyPicked?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  ResQtyonServiceOrders?: Maybe<Scalars['Float']>;
  QtyonPurchReturn?: Maybe<Scalars['Float']>;
  QtyonSalesReturn?: Maybe<Scalars['Float']>;
  NoofSubstitutes?: Maybe<Scalars['Float']>;
  LastPhysInvtDate?: Maybe<Scalars['DateTime']>;
  IdentifierCode?: Maybe<Scalars['String']>;
  EQMAvailabletoTake?: Maybe<Scalars['Float']>;
  EQMUseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  EQMRentalReservationsQty?: Maybe<Scalars['Float']>;
  EQMRentalDeliveriesQty?: Maybe<Scalars['Float']>;
  EQMExpRentalReturnsQty?: Maybe<Scalars['Float']>;
  EQMRentalReturnsQty?: Maybe<Scalars['Float']>;
  EQMQtyonRentalReRent?: Maybe<Scalars['Float']>;
  EQMQtyonRent?: Maybe<Scalars['Float']>;
  PlanningIssuesQty?: Maybe<Scalars['Float']>;
  PlanningReceiptQty?: Maybe<Scalars['Float']>;
  PlannedOrderReceiptQty?: Maybe<Scalars['Float']>;
  FPOrderReceiptQty?: Maybe<Scalars['Float']>;
  RelOrderReceiptQty?: Maybe<Scalars['Float']>;
  PlanningReleaseQty?: Maybe<Scalars['Float']>;
  PlannedOrderReleaseQty?: Maybe<Scalars['Float']>;
  PurchReqReceiptQty?: Maybe<Scalars['Float']>;
  PurchReqReleaseQty?: Maybe<Scalars['Float']>;
  ProdForecastQuantityBase?: Maybe<Scalars['Float']>;
  QtyonProdOrder?: Maybe<Scalars['Float']>;
  QtyonComponentLines?: Maybe<Scalars['Float']>;
  MagentoId?: Maybe<Scalars['Float']>;
  SynkedToMagento?: Maybe<Scalars['Boolean']>;
  MagentoSyncProblems?: Maybe<Scalars['String']>;
  AttributeSetId?: Maybe<Scalars['Float']>;
  Visibility?: Maybe<Scalars['Float']>;
  TypeId?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['Float']>;
  CalculatedInventory?: Maybe<Scalars['Float']>;
  CalculatedInventoryUOM?: Maybe<Scalars['String']>;
  CalculatedInventoryUpdatedAd?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
  ActiveWeb?: Maybe<Scalars['Boolean']>;
  TempOutOfStock?: Maybe<Scalars['Boolean']>;
  OutletSE?: Maybe<Scalars['Boolean']>;
  OutletDateFromSE?: Maybe<Scalars['DateTime']>;
  OutletDateToSE?: Maybe<Scalars['DateTime']>;
  CampaignSE?: Maybe<Scalars['Boolean']>;
  CampaignDateFromSE?: Maybe<Scalars['DateTime']>;
  CampaignDateToSE?: Maybe<Scalars['DateTime']>;
  DeliveryInformation?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  IsBulky?: Maybe<Scalars['Boolean']>;
  OrderItem?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  PackageHeight?: Maybe<Scalars['Float']>;
  PackageWidth?: Maybe<Scalars['Float']>;
  PackageLength?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
  DescriptionNo?: Maybe<Scalars['String']>;
  BrandDescription?: Maybe<Scalars['String']>;
  DeliveryTime?: Maybe<Scalars['String']>;
  HomeDelivery?: Maybe<Scalars['Boolean']>;
  ServicePointDelivery?: Maybe<Scalars['Boolean']>;
  BlockedNO?: Maybe<Scalars['Boolean']>;
  ExpectedInStockDate?: Maybe<Scalars['DateTime']>;
  BulkGroup?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsCampaign?: Maybe<Scalars['Boolean']>;
  GiftcardType?: Maybe<Scalars['String']>;
  TaxClassId?: Maybe<Scalars['String']>;
  UrlKey?: Maybe<Scalars['String']>;
  ShowInFieldOrder?: Maybe<Scalars['Boolean']>;
  ItemType?: Maybe<ItemItemTypeEnum>;
  ItemTypeOPTION?: Maybe<Scalars['String']>;
  SalesOrderMultiple?: Maybe<Scalars['Float']>;
  EcoLabel?: Maybe<Scalars['String']>;
  MainGroupCode?: Maybe<Scalars['String']>;
  ItemGroupCode?: Maybe<Scalars['String']>;
  OldItemNo?: Maybe<Scalars['String']>;
  OldItemNo2?: Maybe<Scalars['String']>;
  RKVItem?: Maybe<Scalars['Boolean']>;
  ToExpire?: Maybe<Scalars['Boolean']>;
  AcquisitionItem?: Maybe<Scalars['Boolean']>;
  MagentoItem?: Maybe<Scalars['Boolean']>;
  AlwaysAvailableOnWeb?: Maybe<Scalars['Boolean']>;
  QBSId?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  EdgeSmallImageUrl?: Maybe<Scalars['String']>;
  EdgeLargelImageUrl?: Maybe<Scalars['String']>;
  CalculationPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemReferenceInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  ReferenceType?: Maybe<Scalars['Float']>;
  ReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ReferenceTypeNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscontinueBarCode?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  ExternalNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemRestrictionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Type?: Maybe<ItemRestrictionTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemUnitOfMeasureInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QtyRoundingPrecision?: Maybe<Scalars['Float']>;
  Length?: Maybe<Scalars['Float']>;
  Width?: Maybe<Scalars['Float']>;
  Height?: Maybe<Scalars['Float']>;
  Cubage?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateItemVendorInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateJobTaskInput = {
  discriminator?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  JobTaskType?: Maybe<Scalars['Float']>;
  JobTaskTypeOPTION?: Maybe<Scalars['String']>;
  WIPTotal?: Maybe<Scalars['Float']>;
  WIPTotalOPTION?: Maybe<Scalars['String']>;
  JobPostingGroup?: Maybe<Scalars['String']>;
  WIPMethod?: Maybe<Scalars['String']>;
  Totaling?: Maybe<Scalars['String']>;
  NewPage?: Maybe<Scalars['Boolean']>;
  NoofBlankLines?: Maybe<Scalars['Float']>;
  Indentation?: Maybe<Scalars['Float']>;
  RecognizedSalesAmount?: Maybe<Scalars['Float']>;
  RecognizedCostsAmount?: Maybe<Scalars['Float']>;
  RecognizedSalesGLAmount?: Maybe<Scalars['Float']>;
  RecognizedCostsGLAmount?: Maybe<Scalars['Float']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PostingDateFilter?: Maybe<Scalars['String']>;
  PlanningDateFilter?: Maybe<Scalars['String']>;
  ScheduleTotalCost?: Maybe<Scalars['Float']>;
  ScheduleTotalPrice?: Maybe<Scalars['Float']>;
  UsageTotalCost?: Maybe<Scalars['Float']>;
  UsageTotalPrice?: Maybe<Scalars['Float']>;
  ContractTotalCost?: Maybe<Scalars['Float']>;
  ContractTotalPrice?: Maybe<Scalars['Float']>;
  ContractInvoicedPrice?: Maybe<Scalars['Float']>;
  ContractInvoicedCost?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  RemainingTotalCost?: Maybe<Scalars['Float']>;
  RemainingTotalPrice?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  ClosedOn?: Maybe<Scalars['DateTime']>;
  FirstSavedOn?: Maybe<Scalars['DateTime']>;
  DevopsId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateKliHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  ErrandNo?: Maybe<Scalars['Float']>;
  User?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  NameDescription?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['Float']>;
  Timemin?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  QAdistdat?: Maybe<Scalars['DateTime']>;
  FinnishedDate?: Maybe<Scalars['DateTime']>;
  No?: Maybe<Scalars['String']>;
  CorectivAction?: Maybe<Scalars['String']>;
  NewInvoice?: Maybe<Scalars['Boolean']>;
  OrderNo?: Maybe<Scalars['String']>;
  CurrentOwnerEmail?: Maybe<Scalars['String']>;
  Closed?: Maybe<Scalars['Boolean']>;
  CostPerMin?: Maybe<Scalars['Float']>;
  ReasonText?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ImpactOnStock?: Maybe<Scalars['Boolean']>;
  CustomerNo?: Maybe<Scalars['String']>;
  InternCost?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDate?: Maybe<Scalars['String']>;
  Guarantee?: Maybe<Scalars['Boolean']>;
  ReasonCodeDesc?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Workplace?: Maybe<Scalars['String']>;
  Caller?: Maybe<Scalars['String']>;
  CallerPhone?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ModelYear?: Maybe<Scalars['String']>;
  Pirority?: Maybe<Scalars['Boolean']>;
  RecType?: Maybe<Scalars['Float']>;
  RecTypeOPTION?: Maybe<Scalars['String']>;
  RevisitNeeded?: Maybe<Scalars['Boolean']>;
  MachineReplaced?: Maybe<Scalars['Boolean']>;
  AnalysisCode?: Maybe<Scalars['String']>;
  AnalysisText?: Maybe<Scalars['String']>;
  MainErrand?: Maybe<Scalars['Boolean']>;
  LinkErrand?: Maybe<Scalars['Float']>;
  City?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  SendToRepairer?: Maybe<Scalars['String']>;
  FollowUp?: Maybe<Scalars['String']>;
  ErrandDescription?: Maybe<Scalars['String']>;
  FollowUpPreformed?: Maybe<Scalars['Boolean']>;
  CorectivActionPreformed?: Maybe<Scalars['Boolean']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  LastStatusBeforeClose?: Maybe<Scalars['Float']>;
  LastStatusBeforeCloseOPTION?: Maybe<Scalars['String']>;
  WorkplaceContact?: Maybe<Scalars['String']>;
  WorkplaceContactPhone?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  TableNo?: Maybe<Scalars['Float']>;
  ReasonTextBlob?: Maybe<Scalars['String']>;
  ActionTextBlob?: Maybe<Scalars['String']>;
  FixedByExternalRepairer?: Maybe<Scalars['Boolean']>;
  TurnaroundTime?: Maybe<Scalars['String']>;
  ReportedToWorkingEnvDpt?: Maybe<Scalars['Boolean']>;
  SafteyOfficerInformed?: Maybe<Scalars['Boolean']>;
  NewRiskAnalysis?: Maybe<Scalars['Boolean']>;
  AnalysisTextBLOB?: Maybe<Scalars['String']>;
  FollowUpBLOB?: Maybe<Scalars['String']>;
  CorectivActionBLOB?: Maybe<Scalars['String']>;
  AccidentComment?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  Note?: Maybe<Scalars['String']>;
  CreditNo?: Maybe<Scalars['String']>;
  CreditPosted?: Maybe<Scalars['Boolean']>;
  CreditApproved?: Maybe<Scalars['Boolean']>;
  CreditApprovedBy?: Maybe<Scalars['String']>;
  NoOfLinkedErrands?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
  TurnOverToEmail?: Maybe<Scalars['String']>;
  CreatedExternal?: Maybe<Scalars['Boolean']>;
  Template?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  CustomerDamage?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateKliReasonCodeInput = {
  discriminator?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RecType?: Maybe<Scalars['Float']>;
  RecTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateLeadInput = {
  discriminator?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  PictureURL?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CompanyNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Surname?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Initials?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  OwnerName?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateLocationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultBinCode?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  PhoneNo2?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  UseAsInTransit?: Maybe<Scalars['Boolean']>;
  RequirePutaway?: Maybe<Scalars['Boolean']>;
  RequirePick?: Maybe<Scalars['Boolean']>;
  CrossDockDueDateCalc?: Maybe<Scalars['String']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  RequireReceive?: Maybe<Scalars['Boolean']>;
  RequireShipment?: Maybe<Scalars['Boolean']>;
  BinMandatory?: Maybe<Scalars['Boolean']>;
  DirectedPutawayandPick?: Maybe<Scalars['Boolean']>;
  DefaultBinSelection?: Maybe<Scalars['Float']>;
  DefaultBinSelectionOPTION?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  UsePutawayWorksheet?: Maybe<Scalars['Boolean']>;
  PickAccordingtoFEFO?: Maybe<Scalars['Boolean']>;
  AllowBreakbulk?: Maybe<Scalars['Boolean']>;
  BinCapacityPolicy?: Maybe<Scalars['Float']>;
  BinCapacityPolicyOPTION?: Maybe<Scalars['String']>;
  OpenShopFloorBinCode?: Maybe<Scalars['String']>;
  ToProductionBinCode?: Maybe<Scalars['String']>;
  FromProductionBinCode?: Maybe<Scalars['String']>;
  AdjustmentBinCode?: Maybe<Scalars['String']>;
  AlwaysCreatePutawayLine?: Maybe<Scalars['Boolean']>;
  AlwaysCreatePickLine?: Maybe<Scalars['Boolean']>;
  SpecialEquipment?: Maybe<Scalars['Float']>;
  SpecialEquipmentOPTION?: Maybe<Scalars['String']>;
  ReceiptBinCode?: Maybe<Scalars['String']>;
  ShipmentBinCode?: Maybe<Scalars['String']>;
  CrossDockBinCode?: Maybe<Scalars['String']>;
  ToAssemblyBinCode?: Maybe<Scalars['String']>;
  FromAssemblyBinCode?: Maybe<Scalars['String']>;
  AsmtoOrderShptBinCode?: Maybe<Scalars['String']>;
  ToJobBinCode?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  UseADCS?: Maybe<Scalars['Boolean']>;
  EQMInternalCustomerNo?: Maybe<Scalars['String']>;
  EQMReclassJnlTemplName?: Maybe<Scalars['String']>;
  EQMReclassJnlBatchName?: Maybe<Scalars['String']>;
  EQMMaintenanceLocation?: Maybe<Scalars['Boolean']>;
  EQMHUB?: Maybe<Scalars['Boolean']>;
  ExternalLocationId?: Maybe<Scalars['Float']>;
  ExternalLocationCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateMagentoOrderStatusInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntityId?: Maybe<Scalars['Float']>;
  IncrementId?: Maybe<Scalars['String']>;
  StoreId?: Maybe<Scalars['Float']>;
  WebsiteId?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['DateTime']>;
  UpdatedAt?: Maybe<Scalars['DateTime']>;
  CheckCount?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateMagentoSettingsInput = {
  discriminator?: Maybe<Scalars['String']>;
  QuestCustomerNo?: Maybe<Scalars['String']>;
  QuestCustomerName?: Maybe<Scalars['String']>;
  QuestCustomerGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateManufacturerInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MagentoAttribOptionId?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateNavUserInput = {
  discriminator?: Maybe<Scalars['String']>;
  UserSecurityID?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['Float']>;
  StateOPTION?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  WindowsSecurityID?: Maybe<Scalars['String']>;
  ChangePassword?: Maybe<Scalars['Boolean']>;
  LicenseType?: Maybe<Scalars['Float']>;
  LicenseTypeOPTION?: Maybe<Scalars['String']>;
  AuthenticationEmail?: Maybe<Scalars['String']>;
  EdgeCostCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectCommentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  TableName?: Maybe<Scalars['Float']>;
  TableNameOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Code?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectFamilyInput = {
  discriminator?: Maybe<Scalars['String']>;
  Family?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['Float']>;
  DepreciationMethodOPTION?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['Float']>;
  StdRentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectGroupNoConcern?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  TaxDepreciationMethod?: Maybe<Scalars['Float']>;
  TaxDepreciationMethodOPTION?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ImgUrlSmall?: Maybe<Scalars['String']>;
  ImgUrlLarge?: Maybe<Scalars['String']>;
  ObjectGroupFamily?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  RentalCustomerNo?: Maybe<Scalars['String']>;
  SalesCustomerNo?: Maybe<Scalars['String']>;
  FixedAssetNo?: Maybe<Scalars['String']>;
  CurrentLocationCode?: Maybe<Scalars['String']>;
  CreateDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StatusInventory?: Maybe<Scalars['String']>;
  ServiceCustomerNo?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  PostedReceiptNo?: Maybe<Scalars['String']>;
  PostedInvoiceNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  RentalStatus?: Maybe<Scalars['String']>;
  BlockedforRental?: Maybe<Scalars['Boolean']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ServiceItemNo?: Maybe<Scalars['String']>;
  DefaultContractValue?: Maybe<Scalars['Float']>;
  DefaultContractDiscount?: Maybe<Scalars['Float']>;
  OwningLocationCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SoldFor?: Maybe<Scalars['Float']>;
  SoldDate?: Maybe<Scalars['DateTime']>;
  UseFixedAsset?: Maybe<Scalars['Boolean']>;
  CounterType?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  FirstRented?: Maybe<Scalars['String']>;
  LastRented?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  InPurchaseQuote?: Maybe<Scalars['Boolean']>;
  InPurchaseOrder?: Maybe<Scalars['Boolean']>;
  PurchaseInvoiced?: Maybe<Scalars['Boolean']>;
  PurchaseReceived?: Maybe<Scalars['Boolean']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  CreatedbyUser?: Maybe<Scalars['String']>;
  BlockedforSale?: Maybe<Scalars['Boolean']>;
  BlockedforPurchase?: Maybe<Scalars['Boolean']>;
  BlockedforReservation?: Maybe<Scalars['Boolean']>;
  OwnershipStatus?: Maybe<Scalars['String']>;
  InclinInvValueReport?: Maybe<Scalars['Boolean']>;
  StdCostAmount?: Maybe<Scalars['Float']>;
  PurchasedFor?: Maybe<Scalars['Float']>;
  ObtainedDiscount?: Maybe<Scalars['Float']>;
  NetCostAmountAdditional?: Maybe<Scalars['Float']>;
  DiscountGiven?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  LinkedtoObjectNo?: Maybe<Scalars['String']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  EngineNo?: Maybe<Scalars['String']>;
  MobilePhoneNo?: Maybe<Scalars['String']>;
  RadioCode?: Maybe<Scalars['String']>;
  ChassisNo?: Maybe<Scalars['String']>;
  ConstructionYear?: Maybe<Scalars['DateTime']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  DateofPermit?: Maybe<Scalars['DateTime']>;
  InSalesOrder?: Maybe<Scalars['Boolean']>;
  InSalesQuote?: Maybe<Scalars['Boolean']>;
  InSalesInvoice?: Maybe<Scalars['Boolean']>;
  SoldonInvoiceNo?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  InDelivery?: Maybe<Scalars['Boolean']>;
  AdditionalObjectCost?: Maybe<Scalars['Float']>;
  AdditionalObjectCostDisc?: Maybe<Scalars['Float']>;
  AdditionalObjectSalesPrice?: Maybe<Scalars['Float']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  InSalesCreditMemo?: Maybe<Scalars['Boolean']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDay?: Maybe<Scalars['Boolean']>;
  PriceTerm?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  PolicyNo?: Maybe<Scalars['String']>;
  LeasingContractNo?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  NewOwnershipstatus?: Maybe<Scalars['String']>;
  NewStartDate?: Maybe<Scalars['DateTime']>;
  CreateFixedAsset?: Maybe<Scalars['Boolean']>;
  MaintenanceDescription?: Maybe<Scalars['String']>;
  Installment?: Maybe<Scalars['Boolean']>;
  InstallmentDescription?: Maybe<Scalars['String']>;
  ProductCode?: Maybe<Scalars['String']>;
  BonusTypeCode?: Maybe<Scalars['String']>;
  FireInspection?: Maybe<Scalars['Float']>;
  RecurringInspection?: Maybe<Scalars['Float']>;
  RecurringInspection2?: Maybe<Scalars['Float']>;
  InternalInspection?: Maybe<Scalars['Float']>;
  FireInspectionNext?: Maybe<Scalars['String']>;
  ExcludeVacation?: Maybe<Scalars['Boolean']>;
  RecurringInspectionNext?: Maybe<Scalars['String']>;
  RecurringInspection2Next?: Maybe<Scalars['String']>;
  InternalInspectionNext?: Maybe<Scalars['String']>;
  InspectionPeriodold?: Maybe<Scalars['String']>;
  InspectionPeriod?: Maybe<Scalars['Float']>;
  InspectionDate?: Maybe<Scalars['DateTime']>;
  NextInspectionDate?: Maybe<Scalars['DateTime']>;
  InspectionSerieNo?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['String']>;
  OwnerStatus?: Maybe<Scalars['String']>;
  CEDocument?: Maybe<Scalars['Boolean']>;
  EngineCertification?: Maybe<Scalars['Boolean']>;
  LastInventoriedDate?: Maybe<Scalars['DateTime']>;
  InventoryInfo?: Maybe<Scalars['String']>;
  FinanceDate?: Maybe<Scalars['DateTime']>;
  AmortizationRate?: Maybe<Scalars['String']>;
  ContractNumber?: Maybe<Scalars['String']>;
  PurschaseValue?: Maybe<Scalars['Float']>;
  ContractInfo?: Maybe<Scalars['String']>;
  ServiceAgreement?: Maybe<Scalars['String']>;
  Finance?: Maybe<Scalars['String']>;
  RentalAmount?: Maybe<Scalars['Float']>;
  StatusInventoryBeforeLost?: Maybe<Scalars['String']>;
  Bitmap1?: Maybe<Scalars['String']>;
  Bitmap2?: Maybe<Scalars['String']>;
  Bitmap3?: Maybe<Scalars['String']>;
  Bitmap4?: Maybe<Scalars['String']>;
  Bitmap5?: Maybe<Scalars['String']>;
  Bitmap6?: Maybe<Scalars['String']>;
  Bitmap7?: Maybe<Scalars['String']>;
  Bitmap8?: Maybe<Scalars['String']>;
  Bitmap9?: Maybe<Scalars['String']>;
  Bitmap10?: Maybe<Scalars['String']>;
  Bitmap11?: Maybe<Scalars['String']>;
  Bitmap12?: Maybe<Scalars['String']>;
  Bitmap13?: Maybe<Scalars['String']>;
  Bitmap14?: Maybe<Scalars['String']>;
  Bitmap15?: Maybe<Scalars['String']>;
  Bitmap16?: Maybe<Scalars['String']>;
  Bitmap17?: Maybe<Scalars['String']>;
  Bitmap18?: Maybe<Scalars['String']>;
  Bitmap19?: Maybe<Scalars['String']>;
  Bitmap20?: Maybe<Scalars['String']>;
  Bitmap21?: Maybe<Scalars['String']>;
  Bitmap22?: Maybe<Scalars['String']>;
  Bitmap23?: Maybe<Scalars['String']>;
  Bitmap24?: Maybe<Scalars['String']>;
  Bitmap25?: Maybe<Scalars['String']>;
  Bitmap26?: Maybe<Scalars['String']>;
  Bitmap27?: Maybe<Scalars['String']>;
  Bitmap28?: Maybe<Scalars['String']>;
  Bitmap29?: Maybe<Scalars['String']>;
  Bitmap30?: Maybe<Scalars['String']>;
  Bitmap31?: Maybe<Scalars['String']>;
  Bitmap32?: Maybe<Scalars['String']>;
  ObjectNoConcern?: Maybe<Scalars['String']>;
  PreviousInventoryStatus?: Maybe<Scalars['String']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  WorkOrderCustomerNo?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  OldObjectNo?: Maybe<Scalars['String']>;
  ChangedbyUserID?: Maybe<Scalars['String']>;
  ChangedDate?: Maybe<Scalars['DateTime']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  RegStatusRental?: Maybe<Scalars['String']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  WarrantyDate?: Maybe<Scalars['DateTime']>;
  TrackingCode?: Maybe<Scalars['String']>;
  VATProdPostingGrpRental?: Maybe<Scalars['String']>;
  ServiceStatus?: Maybe<Scalars['String']>;
  LastServiceDate?: Maybe<Scalars['DateTime']>;
  LastInspectionDate?: Maybe<Scalars['DateTime']>;
  LastInspDate?: Maybe<Scalars['DateTime']>;
  TerminateFixedAsset?: Maybe<Scalars['Boolean']>;
  CounterReporting?: Maybe<Scalars['Boolean']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  CounterType2?: Maybe<Scalars['String']>;
  Counter2?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  DateFilter2?: Maybe<Scalars['DateTime']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  TimeFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  CurrentActiveContractNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  CommentObjectType?: Maybe<Scalars['Boolean']>;
  CommentsinContract?: Maybe<Scalars['Boolean']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowComponentCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowPurchaseAddCost?: Maybe<Scalars['Float']>;
  FlowTotalPurchaseCost?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  Invoiceddays?: Maybe<Scalars['Float']>;
  FlowLinkedSumCost?: Maybe<Scalars['Float']>;
  FlowLinkedSalesDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedOtherCost?: Maybe<Scalars['Float']>;
  FlowLinkedLeasingCost?: Maybe<Scalars['Float']>;
  FlowLinkedRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedRentalDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowLinkedServiceCost?: Maybe<Scalars['Float']>;
  FlowLinkedGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireCost?: Maybe<Scalars['Float']>;
  FlowLinkedHireDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedServiceAgrCost?: Maybe<Scalars['Float']>;
  FlowLinkedDepreciation?: Maybe<Scalars['Float']>;
  FlowLinkedUppreciation?: Maybe<Scalars['Float']>;
  FlowLinkedDownpreciation?: Maybe<Scalars['Float']>;
  FlowLinkedPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowLinkedSalesCharge?: Maybe<Scalars['Float']>;
  FlowLinkedServiceIncome?: Maybe<Scalars['Float']>;
  FlowLinkedSrvAgrIncome?: Maybe<Scalars['Float']>;
  FlowLinkedIntRentalIncome?: Maybe<Scalars['Float']>;
  FlowLinkedValueChange?: Maybe<Scalars['Float']>;
  FlowLinkedRentalDays?: Maybe<Scalars['Float']>;
  FlowLinkedRentalHours?: Maybe<Scalars['Float']>;
  FlowLinkedInvoicedDays?: Maybe<Scalars['Float']>;
  FlowLinkedGainbySale?: Maybe<Scalars['Float']>;
  FlowLinkedLossbySale?: Maybe<Scalars['Float']>;
  FlowLinkedOtherIncome?: Maybe<Scalars['Float']>;
  PostedWorkOrderFlow?: Maybe<Scalars['Float']>;
  PostedWorkPurchaseFlow?: Maybe<Scalars['Float']>;
  PostedWorkOrderLineFlow?: Maybe<Scalars['Float']>;
  PostedWorkPurchLineFlow?: Maybe<Scalars['Float']>;
  PostedWorkshopEntriesFlow?: Maybe<Scalars['Float']>;
  LastPriceAgreement?: Maybe<Scalars['Float']>;
  PSILink?: Maybe<Scalars['String']>;
  CaravanList?: Maybe<Scalars['String']>;
  TechnicalInfo?: Maybe<Scalars['String']>;
  FunctionalTests?: Maybe<Scalars['Float']>;
  LastRentalStatusChage?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  NoofRentalQuoteLines?: Maybe<Scalars['Float']>;
  NoofOpenRentalLines?: Maybe<Scalars['Float']>;
  NoofClosedRentalLines?: Maybe<Scalars['Float']>;
  NoofDefaultRentalPrices?: Maybe<Scalars['Float']>;
  WarrantyDateFlow?: Maybe<Scalars['DateTime']>;
  GPSCoordinates?: Maybe<Scalars['String']>;
  PSI?: Maybe<Scalars['String']>;
  AdditionalObjectStatus?: Maybe<Scalars['String']>;
  AdditionalObjectStatusOPTION?: Maybe<Scalars['String']>;
  ManufactureDate?: Maybe<Scalars['DateTime']>;
  LastInspectionBy?: Maybe<Scalars['String']>;
  LastInspectionByEmail?: Maybe<Scalars['String']>;
  PurchaseDate?: Maybe<Scalars['DateTime']>;
  FirstUseDate?: Maybe<Scalars['DateTime']>;
  IsElectric?: Maybe<Scalars['Boolean']>;
  OffRentDateEdge?: Maybe<Scalars['DateTime']>;
  Broken?: Maybe<Scalars['Boolean']>;
  RealBridgeSoldToCustomerNo?: Maybe<Scalars['String']>;
  RealBridgeCustomerNo?: Maybe<Scalars['String']>;
  RealBridgeProjectNo?: Maybe<Scalars['String']>;
  DekraLno?: Maybe<Scalars['String']>;
  SentToDekra?: Maybe<Scalars['Boolean']>;
  CustomerRef?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  CustomerPhone?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CurrentPlacement?: Maybe<Scalars['String']>;
  ActualPlacement?: Maybe<Scalars['String']>;
  ObjStatus?: Maybe<Scalars['Boolean']>;
  InspectionMonth?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectReturnHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  ReturnType?: Maybe<Scalars['Float']>;
  ReturnTypeOPTION?: Maybe<Scalars['String']>;
  OffRentNo?: Maybe<Scalars['String']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  OffRentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDay?: Maybe<Scalars['Boolean']>;
  ReturnQuantity?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  QuantitytoSell?: Maybe<Scalars['Float']>;
  QuantitytoDamage?: Maybe<Scalars['Float']>;
  ReceivingLocation?: Maybe<Scalars['String']>;
  CounteronDelivery?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  Counter2onDelivery?: Maybe<Scalars['Float']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  ReturnQuantityTime?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ChargeCustomer?: Maybe<Scalars['Boolean']>;
  CurrentPlacement?: Maybe<Scalars['String']>;
  EdgePost?: Maybe<Scalars['Boolean']>;
  OffRentUpdated?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  ReturnNo?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ExtDocNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  DeletedManually?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqRefill?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedbyUserID?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  MotherLineRegUpdateLine?: Maybe<Scalars['Boolean']>;
  MotherLineType?: Maybe<Scalars['Float']>;
  MotherLineTypeOPTION?: Maybe<Scalars['String']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  SubmittedByUserName?: Maybe<Scalars['String']>;
  SubmittedByUserEmail?: Maybe<Scalars['String']>;
  PushNotificationSent?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectReturnLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  ReturnNo?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  ObjectNo?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Unitprice?: Maybe<Scalars['Float']>;
  ContractNo?: Maybe<Scalars['String']>;
  ExtDocNo?: Maybe<Scalars['String']>;
  ExtLineNo?: Maybe<Scalars['Float']>;
  DeletedManually?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ReqRefill?: Maybe<Scalars['Boolean']>;
  ReqApproved?: Maybe<Scalars['Boolean']>;
  ApprovedbyUserID?: Maybe<Scalars['String']>;
  CodeType?: Maybe<Scalars['Float']>;
  CodeTypeOPTION?: Maybe<Scalars['String']>;
  MotherLineRegUpdateLine?: Maybe<Scalars['Boolean']>;
  MotherLineType?: Maybe<Scalars['Float']>;
  MotherLineTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateObjectTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  ObjectType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ObjectGroup?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  FAClassCode?: Maybe<Scalars['String']>;
  FASubclassCode?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepreciationMethod?: Maybe<Scalars['String']>;
  FAPostingGroup?: Maybe<Scalars['String']>;
  ObjectNos?: Maybe<Scalars['String']>;
  CostingMethod?: Maybe<Scalars['String']>;
  StandardCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['String']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  NoofDepreciationYears?: Maybe<Scalars['Float']>;
  NoofDepreciationMonths?: Maybe<Scalars['Float']>;
  CounterType?: Maybe<Scalars['String']>;
  WarrantyDiscParts?: Maybe<Scalars['Float']>;
  WarrantyDiscLabor?: Maybe<Scalars['Float']>;
  DefaultWarrantyDuration?: Maybe<Scalars['String']>;
  ServiceItemGroup?: Maybe<Scalars['String']>;
  ResponseTimeHours?: Maybe<Scalars['Float']>;
  InventoryPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpWriteUp?: Maybe<Scalars['String']>;
  SaleDiscGroup?: Maybe<Scalars['String']>;
  RentalDiscGroup?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['String']>;
  LastTimeModified?: Maybe<Scalars['String']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  GenProdPostingGrpHire?: Maybe<Scalars['String']>;
  GenProdPostingGrpLeasing?: Maybe<Scalars['String']>;
  RentalSplitPostingGroup?: Maybe<Scalars['String']>;
  SalesPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGrpExtRent?: Maybe<Scalars['String']>;
  GenProdPostingGrpIntRent?: Maybe<Scalars['String']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  Capacity?: Maybe<Scalars['Float']>;
  Effect?: Maybe<Scalars['Float']>;
  CylinderVolume?: Maybe<Scalars['Float']>;
  AutomaticExtendedTexts?: Maybe<Scalars['Boolean']>;
  Note?: Maybe<Scalars['String']>;
  CapacityMeasure?: Maybe<Scalars['String']>;
  EffectMeasure?: Maybe<Scalars['String']>;
  CylinderMeasure?: Maybe<Scalars['String']>;
  Document?: Maybe<Scalars['String']>;
  WebPage?: Maybe<Scalars['String']>;
  ExpAvailableAfterEndDate?: Maybe<Scalars['Boolean']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  StdRentalChargeDays?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  DefaultMaintenance?: Maybe<Scalars['Boolean']>;
  Length?: Maybe<Scalars['Float']>;
  LengthMeasure?: Maybe<Scalars['String']>;
  Width?: Maybe<Scalars['Float']>;
  WidthMeasure?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  HeightMeasure?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Float']>;
  WeightMeasure?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  QuantityHoursFullRentalDay?: Maybe<Scalars['Float']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  ObjectTypeNoConcern?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  HireSplit?: Maybe<Scalars['Float']>;
  Description2?: Maybe<Scalars['String']>;
  Description3?: Maybe<Scalars['String']>;
  Description4?: Maybe<Scalars['String']>;
  FixedCostPrice?: Maybe<Scalars['Float']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  StraightLine?: Maybe<Scalars['Float']>;
  WarrantyDuration?: Maybe<Scalars['String']>;
  DecliningBalance?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  RetentionLevel?: Maybe<Scalars['Float']>;
  TaxDepreciationMethod?: Maybe<Scalars['String']>;
  TaxFAPostingGroup?: Maybe<Scalars['String']>;
  TaxNoofDepreciationYears?: Maybe<Scalars['Float']>;
  TaxNoofDepreciationMonths?: Maybe<Scalars['Float']>;
  TaxStraightLine?: Maybe<Scalars['Float']>;
  TaxDecliningBalance?: Maybe<Scalars['Float']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  EstimatedDailyHours?: Maybe<Scalars['Float']>;
  MandatoryCounteronReturn?: Maybe<Scalars['Boolean']>;
  CounterType2?: Maybe<Scalars['String']>;
  MandatoryCounter2onReturn?: Maybe<Scalars['Boolean']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter2?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  InventoryRental?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CommentObjectGroup?: Maybe<Scalars['Boolean']>;
  FlowSumCost?: Maybe<Scalars['Float']>;
  FlowSalesDiscount?: Maybe<Scalars['Float']>;
  FlowSalesAmount?: Maybe<Scalars['Float']>;
  FlowOtherCost?: Maybe<Scalars['Float']>;
  FlowLeasingCost?: Maybe<Scalars['Float']>;
  FlowOtherIncome?: Maybe<Scalars['Float']>;
  FlowRentalIncome?: Maybe<Scalars['Float']>;
  FlowRentalDiscount?: Maybe<Scalars['Float']>;
  FlowPurchaseCharge?: Maybe<Scalars['Float']>;
  FlowServiceCost?: Maybe<Scalars['Float']>;
  FlowGuaranteeCost?: Maybe<Scalars['Float']>;
  FlowInternalRentalCost?: Maybe<Scalars['Float']>;
  FlowHireCost?: Maybe<Scalars['Float']>;
  FlowHireDiscount?: Maybe<Scalars['Float']>;
  FlowServiceAgreementCost?: Maybe<Scalars['Float']>;
  FlowDepreciation?: Maybe<Scalars['Float']>;
  FlowUppreciation?: Maybe<Scalars['Float']>;
  FlowDownpreciation?: Maybe<Scalars['Float']>;
  FlowGainbySale?: Maybe<Scalars['Float']>;
  FlowLossbySale?: Maybe<Scalars['Float']>;
  FlowPurchaseDiscount?: Maybe<Scalars['Float']>;
  FlowSalesCharge?: Maybe<Scalars['Float']>;
  FlowServiceIncome?: Maybe<Scalars['Float']>;
  FlowServiceAgreementIncome?: Maybe<Scalars['Float']>;
  FlowInternalRentalIncome?: Maybe<Scalars['Float']>;
  FlowValueChange?: Maybe<Scalars['Float']>;
  FlowRentalDays?: Maybe<Scalars['Float']>;
  FlowRentalHours?: Maybe<Scalars['Float']>;
  InvoicedDays?: Maybe<Scalars['Float']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  RentalReservationsQty?: Maybe<Scalars['Float']>;
  RentalDeliveriesQty?: Maybe<Scalars['Float']>;
  ExpectedRentalReturnsQty?: Maybe<Scalars['Float']>;
  RentalReturnsQty?: Maybe<Scalars['Float']>;
  PictureURL?: Maybe<Scalars['String']>;
  PSIDocumentURL?: Maybe<Scalars['String']>;
  NotAvailableForReservation?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateOcrAnalysisInput = {
  discriminator?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  textAngle?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePaymentMethodInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  DirectDebit?: Maybe<Scalars['Boolean']>;
  DirectDebitPmtTermsCode?: Maybe<Scalars['String']>;
  PmtExportLineDefinition?: Maybe<Scalars['String']>;
  BankDataConversionPmtType?: Maybe<Scalars['String']>;
  UseforInvoicing?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  AMCBankPmtType?: Maybe<Scalars['String']>;
  EQMCashPayment?: Maybe<Scalars['Boolean']>;
  EQMPaymentType?: Maybe<Scalars['Float']>;
  EQMPaymentTypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePaymentTermsInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  DueDateCalculation?: Maybe<Scalars['String']>;
  DiscountDateCalculation?: Maybe<Scalars['String']>;
  DiscountPercent?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CalcPmtDisconCrMemos?: Maybe<Scalars['Boolean']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePlatformFeatureInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  basic?: Maybe<Scalars['Boolean']>;
  premium?: Maybe<Scalars['Boolean']>;
  sorting?: Maybe<Scalars['Float']>;
  enterprise?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePostCodeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  SearchCity?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateProjectInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SearchDescription?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  CreationDate?: Maybe<Scalars['DateTime']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  PersonResponsible?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  JobPostingGroup?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  WIPMethod?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  WIPPostingDate?: Maybe<Scalars['DateTime']>;
  InvoiceCurrencyCode?: Maybe<Scalars['String']>;
  ExchCalculationCost?: Maybe<Scalars['Float']>;
  ExchCalculationCostOPTION?: Maybe<Scalars['String']>;
  ExchCalculationPrice?: Maybe<Scalars['Float']>;
  ExchCalculationPriceOPTION?: Maybe<Scalars['String']>;
  AllowScheduleContractLines?: Maybe<Scalars['Boolean']>;
  Complete?: Maybe<Scalars['Boolean']>;
  ApplyUsageLink?: Maybe<Scalars['Boolean']>;
  WIPPostingMethod?: Maybe<Scalars['Float']>;
  WIPPostingMethodOPTION?: Maybe<Scalars['String']>;
  OverBudget?: Maybe<Scalars['Boolean']>;
  ProjectManager?: Maybe<Scalars['String']>;
  BusinessArea?: Maybe<Scalars['String']>;
  Inactive?: Maybe<Scalars['Boolean']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  ResourceFilter?: Maybe<Scalars['String']>;
  PostingDateFilter?: Maybe<Scalars['String']>;
  ResourceGrFilter?: Maybe<Scalars['String']>;
  PlanningDateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ScheduledResQty?: Maybe<Scalars['Float']>;
  ScheduledResGrQty?: Maybe<Scalars['Float']>;
  TotalWIPCostAmount?: Maybe<Scalars['Float']>;
  TotalWIPCostGLAmount?: Maybe<Scalars['Float']>;
  WIPEntriesExist?: Maybe<Scalars['Boolean']>;
  WIPGLPostingDate?: Maybe<Scalars['DateTime']>;
  RecogSalesAmount?: Maybe<Scalars['Float']>;
  RecogSalesGLAmount?: Maybe<Scalars['Float']>;
  RecogCostsAmount?: Maybe<Scalars['Float']>;
  RecogCostsGLAmount?: Maybe<Scalars['Float']>;
  TotalWIPSalesAmount?: Maybe<Scalars['Float']>;
  TotalWIPSalesGLAmount?: Maybe<Scalars['Float']>;
  WIPCompletionCalculated?: Maybe<Scalars['Boolean']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  WIPWarnings?: Maybe<Scalars['Boolean']>;
  AppliedCostsGLAmount?: Maybe<Scalars['Float']>;
  AppliedSalesGLAmount?: Maybe<Scalars['Float']>;
  CalcRecogSalesAmount?: Maybe<Scalars['Float']>;
  CalcRecogCostsAmount?: Maybe<Scalars['Float']>;
  CalcRecogSalesGLAmount?: Maybe<Scalars['Float']>;
  CalcRecogCostsGLAmount?: Maybe<Scalars['Float']>;
  WIPCompletionPosted?: Maybe<Scalars['Boolean']>;
  MonthlyValue?: Maybe<Scalars['Float']>;
  EstimatedValue?: Maybe<Scalars['Float']>;
  LinkedTo?: Maybe<Scalars['String']>;
  JobTaskType?: Maybe<Scalars['String']>;
  SyncJobTastTypeWithDevops?: Maybe<Scalars['Boolean']>;
  DevopsTeamId?: Maybe<Scalars['String']>;
  DevopsProjectId?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchCrMemoHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VendorCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepaymentCreditMemo?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  Paid?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  VendorLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ReturnOrderNo?: Maybe<Scalars['String']>;
  ReturnOrderNoSeries?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchCrMemoLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowCrMemooiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  CrMemoDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchInvHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorInvoiceNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  VendorLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  DraftInvoiceSystemId?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMerviceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMObjWorkPurchFlow?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchInvLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceiptLineNo?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICCrossReferenceNo?: Maybe<Scalars['String']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchRcptHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscountPercent?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorShipmentNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscountPercent?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactno?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchRcptLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VATPercent?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ItemRcptEntryNo?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCostPercent?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  SalesOrderNo?: Maybe<Scalars['String']>;
  SalesOrderLineNo?: Maybe<Scalars['Float']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountPercent?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrderSalesNo?: Maybe<Scalars['String']>;
  SpecialOrderSalesLineNo?: Maybe<Scalars['Float']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedReceiptDate?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  ItemChargeBaseAmount?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
  OverReceiptQuantity?: Maybe<Scalars['Float']>;
  OverReceiptCode?: Maybe<Scalars['String']>;
  OverReceiptCode2?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchaseHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<PurchaseHeaderDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  PaytoName?: Maybe<Scalars['String']>;
  PaytoName2?: Maybe<Scalars['String']>;
  PaytoAddress?: Maybe<Scalars['String']>;
  PaytoAddress2?: Maybe<Scalars['String']>;
  PaytoCity?: Maybe<Scalars['String']>;
  PaytoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Receive?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  ReceivingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastReceivingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  VendorOrderNo?: Maybe<Scalars['String']>;
  VendorShipmentNo?: Maybe<Scalars['String']>;
  VendorInvoiceNo?: Maybe<Scalars['String']>;
  VendorCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromVendorName?: Maybe<Scalars['String']>;
  BuyfromVendorName2?: Maybe<Scalars['String']>;
  BuyfromAddress?: Maybe<Scalars['String']>;
  BuyfromAddress2?: Maybe<Scalars['String']>;
  BuyfromCity?: Maybe<Scalars['String']>;
  BuyfromContact?: Maybe<Scalars['String']>;
  PaytoPostCode?: Maybe<Scalars['String']>;
  PaytoCounty?: Maybe<Scalars['String']>;
  PaytoCountryRegionCode?: Maybe<Scalars['String']>;
  BuyfromPostCode?: Maybe<Scalars['String']>;
  BuyfromCounty?: Maybe<Scalars['String']>;
  BuyfromCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  OrderAddressCode?: Maybe<Scalars['String']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ReceivingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  BuyfromICPartnerCode?: Maybe<Scalars['String']>;
  PaytoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PaymentReference?: Maybe<Scalars['String']>;
  JournalTemplName?: Maybe<Scalars['String']>;
  ARcdNotInvExVATLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  BuyfromContactNo?: Maybe<Scalars['String']>;
  PaytoContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PartiallyInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  VendorAuthorizationNo?: Maybe<Scalars['String']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentNoSeries?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  LastReturnShipmentNo?: Maybe<Scalars['String']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  PendingApprovals?: Maybe<Scalars['Float']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  PEBGrossInvoiceAmount?: Maybe<Scalars['Float']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  PEBInwardRegistrated?: Maybe<Scalars['Boolean']>;
  PEBOCRNo?: Maybe<Scalars['String']>;
  PEBRecipientBankAccount?: Maybe<Scalars['String']>;
  PEBOfwhichVATAmount?: Maybe<Scalars['Float']>;
  PEBInwardVAT?: Maybe<Scalars['Float']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMObjectPurchase?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMObjWorkPurchFlow?: Maybe<Scalars['Float']>;
  EQMRentalShipmentNo?: Maybe<Scalars['String']>;
  EQMRentalCollectionNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  EQMPostedRentalShipmentNo?: Maybe<Scalars['String']>;
  EQMPostedRentalCollNo?: Maybe<Scalars['String']>;
  EQMControlTypeCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchaseLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<PurchaseLineDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  BuyfromVendorNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ExpectedReceiptDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoReceive?: Maybe<Scalars['Float']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  UnitPriceLCY?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoiced?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityReceived?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReceiptLineNo?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  SalesOrderNo?: Maybe<Scalars['String']>;
  SalesOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  EntryPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  UseTax?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  ICItemReferenceNo?: Maybe<Scalars['String']>;
  OutstandingAmtExVATLCY?: Maybe<Scalars['Float']>;
  ARcdNotInvExVATLCY?: Maybe<Scalars['Float']>;
  PmtDiscountAmount?: Maybe<Scalars['Float']>;
  PrepmtPmtDiscountAmount?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobLineType?: Maybe<Scalars['Float']>;
  JobLineTypeOPTION?: Maybe<Scalars['String']>;
  JobUnitPrice?: Maybe<Scalars['Float']>;
  JobTotalPrice?: Maybe<Scalars['Float']>;
  JobLineAmount?: Maybe<Scalars['Float']>;
  JobLineDiscountAmount?: Maybe<Scalars['Float']>;
  JobLineDiscount?: Maybe<Scalars['Float']>;
  JobUnitPriceLCY?: Maybe<Scalars['Float']>;
  JobTotalPriceLCY?: Maybe<Scalars['Float']>;
  JobLineAmountLCY?: Maybe<Scalars['Float']>;
  JobLineDiscAmountLCY?: Maybe<Scalars['Float']>;
  JobCurrencyFactor?: Maybe<Scalars['Float']>;
  JobCurrencyCode?: Maybe<Scalars['String']>;
  JobPlanningLineNo?: Maybe<Scalars['Float']>;
  JobRemainingQty?: Maybe<Scalars['Float']>;
  JobRemainingQtyBase?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  ProdOrderNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  QtyRoundingPrecision?: Maybe<Scalars['Float']>;
  QtyRoundingPrecisionBase?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoReceiveBase?: Maybe<Scalars['Float']>;
  QtyRcdNotInvoicedBase?: Maybe<Scalars['Float']>;
  QtyReceivedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  FAPostingType?: Maybe<Scalars['Float']>;
  FAPostingTypeOPTION?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  SalvageValue?: Maybe<Scalars['Float']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DeprAcquisitionCost?: Maybe<Scalars['Boolean']>;
  MaintenanceCode?: Maybe<Scalars['String']>;
  InsuranceNo?: Maybe<Scalars['String']>;
  BudgetedFANo?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderSalesNo?: Maybe<Scalars['String']>;
  SpecialOrderSalesLineNo?: Maybe<Scalars['Float']>;
  ItemReferenceNo?: Maybe<Scalars['String']>;
  ItemReferenceUnitofMeasure?: Maybe<Scalars['String']>;
  ItemReferenceType?: Maybe<Scalars['Float']>;
  ItemReferenceTypeOPTION?: Maybe<Scalars['String']>;
  ItemReferenceTypeNo?: Maybe<Scalars['String']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  CompletelyReceived?: Maybe<Scalars['Boolean']>;
  RequestedReceiptDate?: Maybe<Scalars['DateTime']>;
  PromisedReceiptDate?: Maybe<Scalars['DateTime']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  InboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedReceiptDate?: Maybe<Scalars['DateTime']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  ReturnQtytoShip?: Maybe<Scalars['Float']>;
  ReturnQtytoShipBase?: Maybe<Scalars['Float']>;
  ReturnQtyShippedNotInvd?: Maybe<Scalars['Float']>;
  RetQtyShpdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnShpdNotInvd?: Maybe<Scalars['Float']>;
  ReturnShpdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyShipped?: Maybe<Scalars['Float']>;
  ReturnQtyShippedBase?: Maybe<Scalars['Float']>;
  ReturnShipmentNo?: Maybe<Scalars['String']>;
  ReturnShipmentLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  CopiedFromPostedDoc?: Maybe<Scalars['Boolean']>;
  PriceCalculationMethod?: Maybe<Scalars['Float']>;
  PriceCalculationMethodOPTION?: Maybe<Scalars['String']>;
  AttachedDocCount?: Maybe<Scalars['Float']>;
  OverReceiptQuantity?: Maybe<Scalars['Float']>;
  OverReceiptCode?: Maybe<Scalars['String']>;
  OverReceiptApprovalStatus?: Maybe<Scalars['Float']>;
  OverReceiptApprovalStatusOPTION?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  NBAPreAssignedObjectNo?: Maybe<Scalars['String']>;
  PEBChargeType?: Maybe<Scalars['Float']>;
  PEBChargeTypeOPTION?: Maybe<Scalars['String']>;
  PEBIsDescription2?: Maybe<Scalars['Boolean']>;
  PEBNotincludedincalc?: Maybe<Scalars['Boolean']>;
  PEBNotincludedinVATcalc?: Maybe<Scalars['Boolean']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMType?: Maybe<Scalars['Float']>;
  EQMTypeOPTION?: Maybe<Scalars['String']>;
  EQMNo?: Maybe<Scalars['String']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMObjectCostAssignment?: Maybe<Scalars['String']>;
  EQMFixedAssetSetupGroup?: Maybe<Scalars['String']>;
  EQMFixedAssetNo?: Maybe<Scalars['String']>;
  EQMORIGPurchLineNo?: Maybe<Scalars['Float']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMReRentNo?: Maybe<Scalars['String']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMObjectBinCode?: Maybe<Scalars['String']>;
  EQMManufacturerCode?: Maybe<Scalars['String']>;
  EQMManufacturerModelCode?: Maybe<Scalars['String']>;
  EQMAllocateManufacturerCode?: Maybe<Scalars['String']>;
  EQMAllocateManuModelCode?: Maybe<Scalars['String']>;
  EQMItemtoObjectFlow?: Maybe<Scalars['Float']>;
  RoutingNo?: Maybe<Scalars['String']>;
  OperationNo?: Maybe<Scalars['String']>;
  WorkCenterNo?: Maybe<Scalars['String']>;
  Finished?: Maybe<Scalars['Boolean']>;
  ProdOrderLineNo?: Maybe<Scalars['Float']>;
  OverheadRate?: Maybe<Scalars['Float']>;
  MPSOrder?: Maybe<Scalars['Boolean']>;
  PlanningFlexibility?: Maybe<Scalars['Float']>;
  PlanningFlexibilityOPTION?: Maybe<Scalars['String']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  RoutingReferenceNo?: Maybe<Scalars['Float']>;
  ExternalLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchasingInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateQuoteHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  SalesPerson?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  ExternalText?: Maybe<Scalars['String']>;
  InternalText?: Maybe<Scalars['String']>;
  ProjectSum?: Maybe<Scalars['Float']>;
  LicenseSum?: Maybe<Scalars['Float']>;
  LicenseFee?: Maybe<Scalars['Float']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateQuoteLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['Float']>;
  LineNo?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Task?: Maybe<Scalars['String']>;
  TaskGrouping?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  Sum?: Maybe<Scalars['Float']>;
  InvoicedSum?: Maybe<Scalars['Float']>;
  OnOrderSum?: Maybe<Scalars['Float']>;
  LinkedTo?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateQuoteTaskGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Group?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ChangeStatus?: Maybe<Scalars['Float']>;
  ChangeStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  BreakInvoiceatExpDate?: Maybe<Scalars['Boolean']>;
  Printed?: Maybe<Scalars['String']>;
  PrintedTime?: Maybe<Scalars['String']>;
  InvoiceFrequencyNOTINUSE?: Maybe<Scalars['Float']>;
  InvoiceFrequencyNOTINUSEOPTION?: Maybe<Scalars['String']>;
  LastInvoiceDate?: Maybe<Scalars['String']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ContractDate?: Maybe<Scalars['DateTime']>;
  StartRentalPeriodDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  TermofContract?: Maybe<Scalars['String']>;
  AnnualAmount?: Maybe<Scalars['Float']>;
  AmountperPeriod?: Maybe<Scalars['Float']>;
  CombineInvoices?: Maybe<Scalars['Boolean']>;
  Prepaid?: Maybe<Scalars['Boolean']>;
  NextInvoicePeriod?: Maybe<Scalars['String']>;
  RentalZoneCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CombineMethod?: Maybe<Scalars['Float']>;
  CombineMethodOPTION?: Maybe<Scalars['String']>;
  CancelReasonCode?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  QuoteType?: Maybe<Scalars['Float']>;
  QuoteTypeOPTION?: Maybe<Scalars['String']>;
  ContractGroupCode?: Maybe<Scalars['String']>;
  RentalOrderType?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  TemplateNo?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  CustomerRentalPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesPriceGroup?: Maybe<Scalars['String']>;
  CustomerSalesDiscGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  Probability?: Maybe<Scalars['Float']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  NextInvoicePeriodStart?: Maybe<Scalars['DateTime']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['DateTime']>;
  FromQuoteNo?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  TempInvoiceDate?: Maybe<Scalars['DateTime']>;
  TermCode?: Maybe<Scalars['String']>;
  Contractterminatedcomplete?: Maybe<Scalars['Boolean']>;
  NextRentalInvoiceFrom?: Maybe<Scalars['String']>;
  NextRentalInvoiceTo?: Maybe<Scalars['String']>;
  TempReservation?: Maybe<Scalars['Boolean']>;
  TempDelivery?: Maybe<Scalars['Boolean']>;
  TempInvoice?: Maybe<Scalars['Boolean']>;
  TempDocumentType?: Maybe<Scalars['Float']>;
  TempDocumentTypeOPTION?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  InvoicingStatus?: Maybe<Scalars['Float']>;
  InvoicingStatusOPTION?: Maybe<Scalars['String']>;
  CustomerProjectDimension?: Maybe<Scalars['String']>;
  SalesHeaderNoSale?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['String']>;
  DoNotShowDialogPrompt?: Maybe<Scalars['Boolean']>;
  RentalStartingFee?: Maybe<Scalars['String']>;
  TempStartRentalPeriod?: Maybe<Scalars['String']>;
  FrequenceFixDaysNOTINUSE?: Maybe<Scalars['Float']>;
  CustomerRentalDiscGroup?: Maybe<Scalars['String']>;
  InvoiceSequence?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  LocationManagerContactNo?: Maybe<Scalars['String']>;
  ProjectManagerContactNo?: Maybe<Scalars['String']>;
  DeliveryContactNo?: Maybe<Scalars['String']>;
  FixedPriceContract?: Maybe<Scalars['Boolean']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CustomerDepositCode?: Maybe<Scalars['String']>;
  CustomerDepositAmttoPay?: Maybe<Scalars['Float']>;
  GrantedDeposit?: Maybe<Scalars['Float']>;
  RentalInvoiceDayofMonth?: Maybe<Scalars['Float']>;
  CustomerProject?: Maybe<Scalars['String']>;
  MemoText?: Maybe<Scalars['String']>;
  JournalEntryNo?: Maybe<Scalars['Float']>;
  UseRentalJnlNos?: Maybe<Scalars['Boolean']>;
  CustomerType?: Maybe<Scalars['Float']>;
  CustomerTypeOPTION?: Maybe<Scalars['String']>;
  FullyReturned?: Maybe<Scalars['Boolean']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  InvoiceDocType?: Maybe<Scalars['Float']>;
  InvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  RentalAmountExclVat?: Maybe<Scalars['Float']>;
  RentalLineDiscAmount?: Maybe<Scalars['Float']>;
  RentalTotalAmountExclVat?: Maybe<Scalars['Float']>;
  RentalAmountInclVat?: Maybe<Scalars['Float']>;
  RentalVatAmount?: Maybe<Scalars['Float']>;
  SalesAmountExclVat?: Maybe<Scalars['Float']>;
  SalesLineDiscAmount?: Maybe<Scalars['Float']>;
  SalesTotalAmountExclVat?: Maybe<Scalars['Float']>;
  SalesAmountInclVat?: Maybe<Scalars['Float']>;
  SalesVatAmount?: Maybe<Scalars['Float']>;
  SalesProfit?: Maybe<Scalars['Float']>;
  SalesQtyToInvoice?: Maybe<Scalars['Float']>;
  RentalInsurancAmtExclVat?: Maybe<Scalars['Float']>;
  RentalInsurancAmtInclVat?: Maybe<Scalars['Float']>;
  RentalContractCost?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtDocType?: Maybe<Scalars['Float']>;
  PrepmtDocTypeOPTION?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  UsePrepmtExpirationDate?: Maybe<Scalars['Boolean']>;
  ReceivingRespCenter?: Maybe<Scalars['String']>;
  ReceivingCostCenter?: Maybe<Scalars['String']>;
  QuoteExpiryDate?: Maybe<Scalars['DateTime']>;
  QuoteStatus?: Maybe<Scalars['Float']>;
  QuoteStatusOPTION?: Maybe<Scalars['String']>;
  CustomerPONo?: Maybe<Scalars['String']>;
  NextInvoiceDueDate?: Maybe<Scalars['DateTime']>;
  TypeFilter?: Maybe<Scalars['Float']>;
  TypeFilterOPTION?: Maybe<Scalars['String']>;
  ReasonCodeFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ContractLineAmount?: Maybe<Scalars['Float']>;
  NoofPostedInvoices?: Maybe<Scalars['Float']>;
  NoofUnpostedInvoices?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  ContractInvoiceAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  NoofPickOrder?: Maybe<Scalars['Float']>;
  NoofShipmentOrder?: Maybe<Scalars['Float']>;
  NoofCollectionOrder?: Maybe<Scalars['Float']>;
  NoofWorkOrder?: Maybe<Scalars['Float']>;
  LocationManagerName?: Maybe<Scalars['String']>;
  ProjectManagerName?: Maybe<Scalars['String']>;
  DeliveryContactName?: Maybe<Scalars['String']>;
  CustomerDepositAmount?: Maybe<Scalars['Float']>;
  CustomerDepositOutstAmt?: Maybe<Scalars['Float']>;
  RentalValue?: Maybe<Scalars['Float']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CustOrganizationNo?: Maybe<Scalars['String']>;
  TransactionNo?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  PrepmtPaidAmount?: Maybe<Scalars['Float']>;
  PrepmtUnpaidAmount?: Maybe<Scalars['Float']>;
  PrepmtPaidAmountInclVAT?: Maybe<Scalars['Float']>;
  PrepmtUnpaidAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmt?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ContractStatus?: Maybe<Scalars['Float']>;
  ContractStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['DateTime']>;
  EndRentalPeriod?: Maybe<Scalars['DateTime']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ExpirationDatesInGroup?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  NextInvoicePeriodStart?: Maybe<Scalars['String']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['String']>;
  RentalPeriodInvoiced?: Maybe<Scalars['Float']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  Credited?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  EntryStatus?: Maybe<Scalars['Float']>;
  EntryStatusOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  RentalPeriodNow?: Maybe<Scalars['Float']>;
  RentalChargeDays?: Maybe<Scalars['Float']>;
  RentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DeliveredTime?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  ReturnCompleted?: Maybe<Scalars['Boolean']>;
  TransferdToOrder?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['String']>;
  UnitPriceSale?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  ReservedSeasonCalendar?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  BinCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  WorkInprogress?: Maybe<Scalars['Boolean']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PctofSalesPrice?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtUseExpirationDate?: Maybe<Scalars['Boolean']>;
  PrepmtSalesLineNo?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatus?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtCrMemoStatus?: Maybe<Scalars['Float']>;
  PrepmtCrMemoStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtInsuranceAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrderInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeductVAT?: Maybe<Scalars['Float']>;
  PrepmtAmtDeductedVAT?: Maybe<Scalars['Float']>;
  PrepmtRentalLineNo?: Maybe<Scalars['Float']>;
  PrepmtInsuranceToDeduct?: Maybe<Scalars['Float']>;
  PrepmtInsuranceDeducted?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsAmtOrderIncVAT?: Maybe<Scalars['Float']>;
  PrepmtDimensionSetID?: Maybe<Scalars['Float']>;
  PrepmtVATBusPostingGroup?: Maybe<Scalars['String']>;
  PrepmtVATProdPostingGrp?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  OldAttachedtoLineNo?: Maybe<Scalars['Float']>;
  AttachedtoSwitchLineNo?: Maybe<Scalars['Float']>;
  AttachedStandByLineNo?: Maybe<Scalars['Float']>;
  StandbyStatus?: Maybe<Scalars['Float']>;
  StandbyStatusOPTION?: Maybe<Scalars['String']>;
  QtyonAttachedtoLineNo?: Maybe<Scalars['Float']>;
  KITMother?: Maybe<Scalars['String']>;
  KITFactor?: Maybe<Scalars['Float']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  KITFactorPrice?: Maybe<Scalars['Float']>;
  KITOriginalPrice?: Maybe<Scalars['Float']>;
  KITQuantity?: Maybe<Scalars['Float']>;
  KITLineAmountNet?: Maybe<Scalars['Float']>;
  KITLineAmountGross?: Maybe<Scalars['Float']>;
  KITOriginalSqMeter?: Maybe<Scalars['Float']>;
  SquareMeterperKIT?: Maybe<Scalars['Float']>;
  KITPriceperSqMeter?: Maybe<Scalars['Float']>;
  KITChange?: Maybe<Scalars['Float']>;
  TotalKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPriceMarked?: Maybe<Scalars['Boolean']>;
  DefaultKITFactorPrice?: Maybe<Scalars['Float']>;
  KITComponentUnitPrice?: Maybe<Scalars['Float']>;
  KITComponentRentalPrice?: Maybe<Scalars['Float']>;
  KITLineDiscount?: Maybe<Scalars['Float']>;
  KITLineDiscountAmount?: Maybe<Scalars['Float']>;
  OldTmplMotherLineNo?: Maybe<Scalars['Float']>;
  OnlyonFirstOrderInvoice?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  ReturnDateBuffer?: Maybe<Scalars['String']>;
  OffRentDateBuffer?: Maybe<Scalars['String']>;
  ReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  OffRentQuantityBuffer?: Maybe<Scalars['Float']>;
  DebitonReturnDateBuffer?: Maybe<Scalars['Boolean']>;
  CounteronReturnBuffer?: Maybe<Scalars['Float']>;
  ReturnReminderBuffer?: Maybe<Scalars['Float']>;
  UpdateLineBuffer?: Maybe<Scalars['Boolean']>;
  ReturnNoBuffer?: Maybe<Scalars['String']>;
  ReturnPoleSplitBuffer?: Maybe<Scalars['Boolean']>;
  ReturnCompleteBuffer?: Maybe<Scalars['Boolean']>;
  InvoicedToDateBuffer?: Maybe<Scalars['String']>;
  ReceiveLocationCodeBuffer?: Maybe<Scalars['String']>;
  ReturnTimeBuffer?: Maybe<Scalars['String']>;
  DepositAmountCardBuffer?: Maybe<Scalars['Float']>;
  DepositAmountCashBuffer?: Maybe<Scalars['Float']>;
  ReclassQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassDateBuffer?: Maybe<Scalars['String']>;
  CorrFromDateBuffer?: Maybe<Scalars['String']>;
  CorrInvToDateBuffer?: Maybe<Scalars['String']>;
  CorrReturnDateBuffer?: Maybe<Scalars['String']>;
  CorrCloseRentalBuffer?: Maybe<Scalars['Float']>;
  CorrCloseRentalBufferOPTION?: Maybe<Scalars['String']>;
  CorrUpdEntryBasisBuffer?: Maybe<Scalars['Boolean']>;
  CorrQuantityBuffer?: Maybe<Scalars['Float']>;
  CorrReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassFromContractBuffer?: Maybe<Scalars['Boolean']>;
  ReclassToContractBuffer?: Maybe<Scalars['Boolean']>;
  MaintenanceBuffer?: Maybe<Scalars['Boolean']>;
  DepositAmountTnsfBuffer?: Maybe<Scalars['Float']>;
  OffRentTimeBuffer?: Maybe<Scalars['String']>;
  OffRentNoBuffer?: Maybe<Scalars['String']>;
  CorrQtyShippedtoBuffer?: Maybe<Scalars['Float']>;
  TempEntryBuffer?: Maybe<Scalars['Boolean']>;
  ReturntoSellBuffer?: Maybe<Scalars['Float']>;
  ReturntoDamageBuffer?: Maybe<Scalars['Float']>;
  Counter2onReturnBuffer?: Maybe<Scalars['Float']>;
  CorrOnRentDateBuffer?: Maybe<Scalars['String']>;
  CorrNextInvDateBuffer?: Maybe<Scalars['String']>;
  CollectionDocNoBuffer?: Maybe<Scalars['String']>;
  CollectionLineNoBuffer?: Maybe<Scalars['Float']>;
  SourceCodeBuffer?: Maybe<Scalars['String']>;
  DeliverQuantityNowBuffer?: Maybe<Scalars['Float']>;
  ShipmentDateBuffer?: Maybe<Scalars['String']>;
  OnRentDateBuffer?: Maybe<Scalars['String']>;
  LocationCodeBuffer?: Maybe<Scalars['String']>;
  ConsumptionatReturnBuffer?: Maybe<Scalars['Float']>;
  CreditonEarlyBuffer?: Maybe<Scalars['Float']>;
  StartRentalPeriodBuffer?: Maybe<Scalars['String']>;
  LineNoBuffer?: Maybe<Scalars['Float']>;
  ConsumpatDeliveryBuffer?: Maybe<Scalars['Float']>;
  DepositAmountBackBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBufferOPTION?: Maybe<Scalars['String']>;
  PstDispatchDocNoBuffer?: Maybe<Scalars['String']>;
  PstDispatchLineNoBuffer?: Maybe<Scalars['Float']>;
  PostedInDocumentBuffer?: Maybe<Scalars['Boolean']>;
  EQMServiceDocBuffer?: Maybe<Scalars['String']>;
  UnitPriceBuffer?: Maybe<Scalars['Float']>;
  LineDiscountBuffer?: Maybe<Scalars['Float']>;
  LineMarkedBuffer?: Maybe<Scalars['Boolean']>;
  PostingDateBuffer?: Maybe<Scalars['String']>;
  UseInOpenPeriodBuffer?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNoBuffer?: Maybe<Scalars['Float']>;
  CounteronShipmentBuffer?: Maybe<Scalars['Float']>;
  Counter2onShipmentBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeDaysBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeHourBuffer?: Maybe<Scalars['Float']>;
  ReasonforRentFreeBuffer?: Maybe<Scalars['String']>;
  ReceiveRespCenterBuffer?: Maybe<Scalars['String']>;
  FullyConsumedAccBuffer?: Maybe<Scalars['Boolean']>;
  ReceivingBinCodeBuffer?: Maybe<Scalars['String']>;
  SuspendLineNoBuffer?: Maybe<Scalars['Boolean']>;
  UnitPriceNew?: Maybe<Scalars['Float']>;
  PriceTermCodeNew?: Maybe<Scalars['String']>;
  UnitPriceprDayNew?: Maybe<Scalars['Float']>;
  UnitPriceprMonthNew?: Maybe<Scalars['Float']>;
  LineAmountNew?: Maybe<Scalars['Float']>;
  RentalPeriodNew?: Maybe<Scalars['Float']>;
  LineDiscountNew?: Maybe<Scalars['Float']>;
  LineDiscountAmountNew?: Maybe<Scalars['Float']>;
  StairPriceCodeNew?: Maybe<Scalars['String']>;
  StairDiscountCodeNew?: Maybe<Scalars['String']>;
  BaseCalendarCodeNew?: Maybe<Scalars['String']>;
  StairPrice1New?: Maybe<Scalars['Float']>;
  StairPrice2New?: Maybe<Scalars['Float']>;
  StairPrice3New?: Maybe<Scalars['Float']>;
  StairPrice4New?: Maybe<Scalars['Float']>;
  StairPrice5New?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  Preliminary?: Maybe<Scalars['Boolean']>;
  ReferenceTableID?: Maybe<Scalars['Float']>;
  ReferenceUniqueID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  ReplacementUnitPrice?: Maybe<Scalars['Float']>;
  ReRentObject?: Maybe<Scalars['Boolean']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  UnitPriceprDay?: Maybe<Scalars['Float']>;
  UnitPriceprMonth?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  DepositOrigUnitPrice?: Maybe<Scalars['Float']>;
  MarkDepositEntry?: Maybe<Scalars['Boolean']>;
  DepositUnitPrice?: Maybe<Scalars['Float']>;
  DepositPostingNo?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  DepositReturnPostingNo?: Maybe<Scalars['String']>;
  AgreedToDate?: Maybe<Scalars['String']>;
  DepositPostingType?: Maybe<Scalars['Float']>;
  DepositPostingTypeOPTION?: Maybe<Scalars['String']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  DeliveredBy?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  SaleDocumentNo?: Maybe<Scalars['String']>;
  SaleDocumentType?: Maybe<Scalars['Float']>;
  SaleDocumentTypeOPTION?: Maybe<Scalars['String']>;
  SaleLineNo?: Maybe<Scalars['Float']>;
  SaleReserve?: Maybe<Scalars['Float']>;
  SaleReserveOPTION?: Maybe<Scalars['String']>;
  ReturnQuantityHours?: Maybe<Scalars['Float']>;
  ShipmentDateTime?: Maybe<Scalars['String']>;
  EndRentalDateTime?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['String']>;
  DoShipmentNow?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  OnRentTime?: Maybe<Scalars['String']>;
  OffRentTime?: Maybe<Scalars['String']>;
  OnRentDateTime?: Maybe<Scalars['String']>;
  OffRentDateTime?: Maybe<Scalars['String']>;
  ReturnQuantityChargeHours?: Maybe<Scalars['Float']>;
  TerminPeriod?: Maybe<Scalars['Float']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  IgnorCalendarCheck?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondMotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeMotherRef?: Maybe<Scalars['Float']>;
  ReturnAdditionMotherRef?: Maybe<Scalars['Float']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  ExtraHoursReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  TmpAccumEntry?: Maybe<Scalars['Boolean']>;
  MarkReclassLineNoRef?: Maybe<Scalars['Float']>;
  MarkReclassQuantity?: Maybe<Scalars['Float']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  ReclassFromLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderedBy?: Maybe<Scalars['String']>;
  MarkReclassLine?: Maybe<Scalars['Boolean']>;
  FilterReclassLine?: Maybe<Scalars['Boolean']>;
  ReclassFromContractNo?: Maybe<Scalars['String']>;
  ReclassToContractNo?: Maybe<Scalars['String']>;
  ReclassToLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderNo?: Maybe<Scalars['String']>;
  ReclassDocNo?: Maybe<Scalars['String']>;
  WorkshopObjectNo?: Maybe<Scalars['String']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  ExploreLine?: Maybe<Scalars['Float']>;
  EnvironmentalFee?: Maybe<Scalars['Boolean']>;
  ExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  ExtraHoursReturnNo?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  ReclassDocLineNo?: Maybe<Scalars['Float']>;
  RentalSplitCostCenter?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  RecursiveLevel?: Maybe<Scalars['Float']>;
  Repurchase?: Maybe<Scalars['Boolean']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatus?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatusOPTION?: Maybe<Scalars['String']>;
  CPAdvancePaymentDocNo?: Maybe<Scalars['String']>;
  InvoiceFee?: Maybe<Scalars['Boolean']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StairPriceDiscount?: Maybe<Scalars['Float']>;
  ReceiveRespCenter?: Maybe<Scalars['String']>;
  ReceiveCostCenter?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  ServiceMotherLineNo?: Maybe<Scalars['Float']>;
  AdditionalCharge?: Maybe<Scalars['Boolean']>;
  AdjforAdditionalCharge?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  EQMServiceNoTemp?: Maybe<Scalars['String']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  DispatchTask?: Maybe<Scalars['Float']>;
  DispatchTaskOPTION?: Maybe<Scalars['String']>;
  DispatchStatus?: Maybe<Scalars['Float']>;
  DispatchStatusOPTION?: Maybe<Scalars['String']>;
  ScheduledDeliveryDate?: Maybe<Scalars['String']>;
  ScheduledDeliveryTime?: Maybe<Scalars['String']>;
  ScheduledPickupDate?: Maybe<Scalars['String']>;
  ScheduledPickupTime?: Maybe<Scalars['String']>;
  DeliveryDriver?: Maybe<Scalars['String']>;
  PickupDriver?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  SchedDate?: Maybe<Scalars['String']>;
  ActualDate?: Maybe<Scalars['String']>;
  SchedTime?: Maybe<Scalars['String']>;
  ActualTime?: Maybe<Scalars['String']>;
  ActualDeliveryDate?: Maybe<Scalars['String']>;
  ActualDeliveryTime?: Maybe<Scalars['String']>;
  ActualPickupDate?: Maybe<Scalars['String']>;
  ActualPickupTime?: Maybe<Scalars['String']>;
  ActualReturnDate?: Maybe<Scalars['String']>;
  ActualReturnTime?: Maybe<Scalars['String']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  DispatchDocType?: Maybe<Scalars['Float']>;
  DispatchDocTypeOPTION?: Maybe<Scalars['String']>;
  DispatchDocNo?: Maybe<Scalars['String']>;
  DispatchLineNo?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  UserFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DefaultKITPriceCompFlow?: Maybe<Scalars['Float']>;
  AssignedtoKIT?: Maybe<Scalars['String']>;
  OffRentDateFlow?: Maybe<Scalars['String']>;
  ReturnDateFlow?: Maybe<Scalars['String']>;
  QuantityShippedtoFlow?: Maybe<Scalars['Float']>;
  QuantityReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityPstShippedFlow?: Maybe<Scalars['Float']>;
  QtyonPickOrder?: Maybe<Scalars['Float']>;
  QtyonShipmentOrder?: Maybe<Scalars['Float']>;
  QtyonCollectionOrder?: Maybe<Scalars['Float']>;
  UserLocationFilter?: Maybe<Scalars['String']>;
  NoofWorkOrderLines?: Maybe<Scalars['Float']>;
  NoofPickOrderLines?: Maybe<Scalars['Float']>;
  NoofShipmentOrderLines?: Maybe<Scalars['Float']>;
  NoofCollectionOrderLines?: Maybe<Scalars['Float']>;
  NoofPostedPickOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdShptOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdCollOrderLines?: Maybe<Scalars['Float']>;
  NoofRntlReservSummaries?: Maybe<Scalars['Float']>;
  NoofRntlReservEntries?: Maybe<Scalars['Float']>;
  QtyonRntlReservEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResShptEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResRetEntry?: Maybe<Scalars['Float']>;
  QtyonBufferEntry?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodPrice?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodEntry?: Maybe<Scalars['Float']>;
  LastErrorText?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  SaleReservedQuantity?: Maybe<Scalars['Float']>;
  SaleReservedQtyBase?: Maybe<Scalars['Float']>;
  KITNetWeight?: Maybe<Scalars['Float']>;
  ReportedRentalHours?: Maybe<Scalars['Float']>;
  EQMServiceDocNo?: Maybe<Scalars['String']>;
  DispatchDocument?: Maybe<Scalars['Boolean']>;
  DispatchDocumentNo?: Maybe<Scalars['String']>;
  GroupOnNumber?: Maybe<Scalars['String']>;
  PeriodLineAmountEdge?: Maybe<Scalars['Float']>;
  RentalDays?: Maybe<Scalars['Float']>;
  RemainingQty?: Maybe<Scalars['Float']>;
  FreeDays?: Maybe<Scalars['Float']>;
  DayNetPriceEdge?: Maybe<Scalars['Float']>;
  Vaccation?: Maybe<Scalars['Boolean']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  ReturnReservationActive?: Maybe<Scalars['Boolean']>;
  UserExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  ImageUrl?: Maybe<Scalars['String']>;
  PSILink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalLineSnapshotInput = {
  discriminator?: Maybe<Scalars['String']>;
  ContractType?: Maybe<Scalars['Float']>;
  ContractTypeOPTION?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  ContractStatus?: Maybe<Scalars['Float']>;
  ContractStatusOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['DateTime']>;
  EndRentalPeriod?: Maybe<Scalars['DateTime']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  ReturnTime?: Maybe<Scalars['String']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  NextInvoiceDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ExpirationDatesInGroup?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  NextInvoicePeriodStart?: Maybe<Scalars['String']>;
  NextInvoicePeriodEnd?: Maybe<Scalars['String']>;
  RentalPeriodInvoiced?: Maybe<Scalars['Float']>;
  UseHourlyRent?: Maybe<Scalars['Boolean']>;
  Credited?: Maybe<Scalars['Boolean']>;
  TransId?: Maybe<Scalars['Float']>;
  EntryStatus?: Maybe<Scalars['Float']>;
  EntryStatusOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  RentalPeriodNow?: Maybe<Scalars['Float']>;
  RentalChargeDays?: Maybe<Scalars['Float']>;
  RentalChargeDaysOPTION?: Maybe<Scalars['String']>;
  DeliveredTime?: Maybe<Scalars['String']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  ReturnCompleted?: Maybe<Scalars['Boolean']>;
  TransferdToOrder?: Maybe<Scalars['Boolean']>;
  Description2?: Maybe<Scalars['String']>;
  Counter?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['String']>;
  UnitPriceSale?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  ReservedSeasonCalendar?: Maybe<Scalars['Boolean']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Counter2?: Maybe<Scalars['Float']>;
  BinCode?: Maybe<Scalars['String']>;
  EstimatedCounterperDay?: Maybe<Scalars['Float']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  EstimatedCounter2perDay?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  WorkInprogress?: Maybe<Scalars['Boolean']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PctofSalesPrice?: Maybe<Scalars['Float']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentPeriod?: Maybe<Scalars['String']>;
  PrepmtUseExpirationDate?: Maybe<Scalars['Boolean']>;
  PrepmtSalesLineNo?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatus?: Maybe<Scalars['Float']>;
  PrepmtInvoiceStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtCrMemoStatus?: Maybe<Scalars['Float']>;
  PrepmtCrMemoStatusOPTION?: Maybe<Scalars['String']>;
  PrepmtDocNo?: Maybe<Scalars['String']>;
  PrepmtInsuranceAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmtInOrderInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInOrder?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepmtAmttoDeductVAT?: Maybe<Scalars['Float']>;
  PrepmtAmtDeductedVAT?: Maybe<Scalars['Float']>;
  PrepmtRentalLineNo?: Maybe<Scalars['Float']>;
  PrepmtInsuranceToDeduct?: Maybe<Scalars['Float']>;
  PrepmtInsuranceDeducted?: Maybe<Scalars['Float']>;
  PrepmtInsuranceAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepmtInsAmtOrderIncVAT?: Maybe<Scalars['Float']>;
  PrepmtDimensionSetID?: Maybe<Scalars['Float']>;
  PrepmtVATBusPostingGroup?: Maybe<Scalars['String']>;
  PrepmtVATProdPostingGrp?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  OldAttachedtoLineNo?: Maybe<Scalars['Float']>;
  AttachedtoSwitchLineNo?: Maybe<Scalars['Float']>;
  AttachedStandByLineNo?: Maybe<Scalars['Float']>;
  StandbyStatus?: Maybe<Scalars['Float']>;
  StandbyStatusOPTION?: Maybe<Scalars['String']>;
  QtyonAttachedtoLineNo?: Maybe<Scalars['Float']>;
  KITMother?: Maybe<Scalars['String']>;
  KITFactor?: Maybe<Scalars['Float']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  KITFactorPrice?: Maybe<Scalars['Float']>;
  KITOriginalPrice?: Maybe<Scalars['Float']>;
  KITQuantity?: Maybe<Scalars['Float']>;
  KITLineAmountNet?: Maybe<Scalars['Float']>;
  KITLineAmountGross?: Maybe<Scalars['Float']>;
  KITOriginalSqMeter?: Maybe<Scalars['Float']>;
  SquareMeterperKIT?: Maybe<Scalars['Float']>;
  KITPriceperSqMeter?: Maybe<Scalars['Float']>;
  KITChange?: Maybe<Scalars['Float']>;
  TotalKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPrice?: Maybe<Scalars['Float']>;
  DefaultKITPriceMarked?: Maybe<Scalars['Boolean']>;
  DefaultKITFactorPrice?: Maybe<Scalars['Float']>;
  KITComponentUnitPrice?: Maybe<Scalars['Float']>;
  KITComponentRentalPrice?: Maybe<Scalars['Float']>;
  KITLineDiscount?: Maybe<Scalars['Float']>;
  KITLineDiscountAmount?: Maybe<Scalars['Float']>;
  OldTmplMotherLineNo?: Maybe<Scalars['Float']>;
  OnlyonFirstOrderInvoice?: Maybe<Scalars['Boolean']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  ReturnDateBuffer?: Maybe<Scalars['String']>;
  OffRentDateBuffer?: Maybe<Scalars['String']>;
  ReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  OffRentQuantityBuffer?: Maybe<Scalars['Float']>;
  DebitonReturnDateBuffer?: Maybe<Scalars['Boolean']>;
  CounteronReturnBuffer?: Maybe<Scalars['Float']>;
  ReturnReminderBuffer?: Maybe<Scalars['Float']>;
  UpdateLineBuffer?: Maybe<Scalars['Boolean']>;
  ReturnNoBuffer?: Maybe<Scalars['String']>;
  ReturnPoleSplitBuffer?: Maybe<Scalars['Boolean']>;
  ReturnCompleteBuffer?: Maybe<Scalars['Boolean']>;
  InvoicedToDateBuffer?: Maybe<Scalars['String']>;
  ReceiveLocationCodeBuffer?: Maybe<Scalars['String']>;
  ReturnTimeBuffer?: Maybe<Scalars['String']>;
  DepositAmountCardBuffer?: Maybe<Scalars['Float']>;
  DepositAmountCashBuffer?: Maybe<Scalars['Float']>;
  ReclassQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassDateBuffer?: Maybe<Scalars['String']>;
  CorrFromDateBuffer?: Maybe<Scalars['String']>;
  CorrInvToDateBuffer?: Maybe<Scalars['String']>;
  CorrReturnDateBuffer?: Maybe<Scalars['String']>;
  CorrCloseRentalBuffer?: Maybe<Scalars['Float']>;
  CorrCloseRentalBufferOPTION?: Maybe<Scalars['String']>;
  CorrUpdEntryBasisBuffer?: Maybe<Scalars['Boolean']>;
  CorrQuantityBuffer?: Maybe<Scalars['Float']>;
  CorrReturnQuantityBuffer?: Maybe<Scalars['Float']>;
  ReclassFromContractBuffer?: Maybe<Scalars['Boolean']>;
  ReclassToContractBuffer?: Maybe<Scalars['Boolean']>;
  MaintenanceBuffer?: Maybe<Scalars['Boolean']>;
  DepositAmountTnsfBuffer?: Maybe<Scalars['Float']>;
  OffRentTimeBuffer?: Maybe<Scalars['String']>;
  OffRentNoBuffer?: Maybe<Scalars['String']>;
  CorrQtyShippedtoBuffer?: Maybe<Scalars['Float']>;
  TempEntryBuffer?: Maybe<Scalars['Boolean']>;
  ReturntoSellBuffer?: Maybe<Scalars['Float']>;
  ReturntoDamageBuffer?: Maybe<Scalars['Float']>;
  Counter2onReturnBuffer?: Maybe<Scalars['Float']>;
  CorrOnRentDateBuffer?: Maybe<Scalars['String']>;
  CorrNextInvDateBuffer?: Maybe<Scalars['String']>;
  CollectionDocNoBuffer?: Maybe<Scalars['String']>;
  CollectionLineNoBuffer?: Maybe<Scalars['Float']>;
  SourceCodeBuffer?: Maybe<Scalars['String']>;
  DeliverQuantityNowBuffer?: Maybe<Scalars['Float']>;
  ShipmentDateBuffer?: Maybe<Scalars['String']>;
  OnRentDateBuffer?: Maybe<Scalars['String']>;
  LocationCodeBuffer?: Maybe<Scalars['String']>;
  ConsumptionatReturnBuffer?: Maybe<Scalars['Float']>;
  CreditonEarlyBuffer?: Maybe<Scalars['Float']>;
  StartRentalPeriodBuffer?: Maybe<Scalars['String']>;
  LineNoBuffer?: Maybe<Scalars['Float']>;
  ConsumpatDeliveryBuffer?: Maybe<Scalars['Float']>;
  DepositAmountBackBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBuffer?: Maybe<Scalars['Float']>;
  DispatchTypeBufferOPTION?: Maybe<Scalars['String']>;
  PstDispatchDocNoBuffer?: Maybe<Scalars['String']>;
  PstDispatchLineNoBuffer?: Maybe<Scalars['Float']>;
  PostedInDocumentBuffer?: Maybe<Scalars['Boolean']>;
  EQMServiceDocBuffer?: Maybe<Scalars['String']>;
  UnitPriceBuffer?: Maybe<Scalars['Float']>;
  LineDiscountBuffer?: Maybe<Scalars['Float']>;
  LineMarkedBuffer?: Maybe<Scalars['Boolean']>;
  PostingDateBuffer?: Maybe<Scalars['String']>;
  UseInOpenPeriodBuffer?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNoBuffer?: Maybe<Scalars['Float']>;
  CounteronShipmentBuffer?: Maybe<Scalars['Float']>;
  Counter2onShipmentBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeDaysBuffer?: Maybe<Scalars['Float']>;
  NoofRentFreeHourBuffer?: Maybe<Scalars['Float']>;
  ReasonforRentFreeBuffer?: Maybe<Scalars['String']>;
  ReceiveRespCenterBuffer?: Maybe<Scalars['String']>;
  FullyConsumedAccBuffer?: Maybe<Scalars['Boolean']>;
  ReceivingBinCodeBuffer?: Maybe<Scalars['String']>;
  SuspendLineNoBuffer?: Maybe<Scalars['Boolean']>;
  UnitPriceNew?: Maybe<Scalars['Float']>;
  PriceTermCodeNew?: Maybe<Scalars['String']>;
  UnitPriceprDayNew?: Maybe<Scalars['Float']>;
  UnitPriceprMonthNew?: Maybe<Scalars['Float']>;
  LineAmountNew?: Maybe<Scalars['Float']>;
  RentalPeriodNew?: Maybe<Scalars['Float']>;
  LineDiscountNew?: Maybe<Scalars['Float']>;
  LineDiscountAmountNew?: Maybe<Scalars['Float']>;
  StairPriceCodeNew?: Maybe<Scalars['String']>;
  StairDiscountCodeNew?: Maybe<Scalars['String']>;
  BaseCalendarCodeNew?: Maybe<Scalars['String']>;
  StairPrice1New?: Maybe<Scalars['Float']>;
  StairPrice2New?: Maybe<Scalars['Float']>;
  StairPrice3New?: Maybe<Scalars['Float']>;
  StairPrice4New?: Maybe<Scalars['Float']>;
  StairPrice5New?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  Preliminary?: Maybe<Scalars['Boolean']>;
  ReferenceTableID?: Maybe<Scalars['Float']>;
  ReferenceUniqueID?: Maybe<Scalars['Float']>;
  UniqueID?: Maybe<Scalars['Float']>;
  CreatedTime?: Maybe<Scalars['String']>;
  ReplacementUnitPrice?: Maybe<Scalars['Float']>;
  ReRentObject?: Maybe<Scalars['Boolean']>;
  ReRentCost?: Maybe<Scalars['Float']>;
  UnitPriceprDay?: Maybe<Scalars['Float']>;
  UnitPriceprMonth?: Maybe<Scalars['Float']>;
  AllocateObjectNo?: Maybe<Scalars['String']>;
  DepositOrigUnitPrice?: Maybe<Scalars['Float']>;
  MarkDepositEntry?: Maybe<Scalars['Boolean']>;
  DepositUnitPrice?: Maybe<Scalars['Float']>;
  DepositPostingNo?: Maybe<Scalars['String']>;
  DepositAmount?: Maybe<Scalars['Float']>;
  ReplacementPrice?: Maybe<Scalars['Float']>;
  DepositReturnPostingNo?: Maybe<Scalars['String']>;
  AgreedToDate?: Maybe<Scalars['String']>;
  DepositPostingType?: Maybe<Scalars['Float']>;
  DepositPostingTypeOPTION?: Maybe<Scalars['String']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  DeliveredBy?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  SaleDocumentNo?: Maybe<Scalars['String']>;
  SaleDocumentType?: Maybe<Scalars['Float']>;
  SaleDocumentTypeOPTION?: Maybe<Scalars['String']>;
  SaleLineNo?: Maybe<Scalars['Float']>;
  SaleReserve?: Maybe<Scalars['Float']>;
  SaleReserveOPTION?: Maybe<Scalars['String']>;
  ReturnQuantityHours?: Maybe<Scalars['Float']>;
  ShipmentDateTime?: Maybe<Scalars['String']>;
  EndRentalDateTime?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['String']>;
  DoShipmentNow?: Maybe<Scalars['Boolean']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  OnRentTime?: Maybe<Scalars['String']>;
  OffRentTime?: Maybe<Scalars['String']>;
  OnRentDateTime?: Maybe<Scalars['String']>;
  OffRentDateTime?: Maybe<Scalars['String']>;
  ReturnQuantityChargeHours?: Maybe<Scalars['Float']>;
  TerminPeriod?: Maybe<Scalars['Float']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  IgnorCalendarCheck?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondMotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeMotherRef?: Maybe<Scalars['Float']>;
  ReturnAdditionMotherRef?: Maybe<Scalars['Float']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  ExtraHoursReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  TmpAccumEntry?: Maybe<Scalars['Boolean']>;
  MarkReclassLineNoRef?: Maybe<Scalars['Float']>;
  MarkReclassQuantity?: Maybe<Scalars['Float']>;
  PostingPriority?: Maybe<Scalars['Float']>;
  PostingPriorityOPTION?: Maybe<Scalars['String']>;
  ReclassFromLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderedBy?: Maybe<Scalars['String']>;
  MarkReclassLine?: Maybe<Scalars['Boolean']>;
  FilterReclassLine?: Maybe<Scalars['Boolean']>;
  ReclassFromContractNo?: Maybe<Scalars['String']>;
  ReclassToContractNo?: Maybe<Scalars['String']>;
  ReclassToLocationCode?: Maybe<Scalars['String']>;
  ReclassOrderNo?: Maybe<Scalars['String']>;
  ReclassDocNo?: Maybe<Scalars['String']>;
  WorkshopObjectNo?: Maybe<Scalars['String']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  ExploreLine?: Maybe<Scalars['Float']>;
  EnvironmentalFee?: Maybe<Scalars['Boolean']>;
  ExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  ExtraHoursReturnNo?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  ReclassDocLineNo?: Maybe<Scalars['Float']>;
  RentalSplitCostCenter?: Maybe<Scalars['String']>;
  RentalSplit?: Maybe<Scalars['Float']>;
  PricePriority?: Maybe<Scalars['Float']>;
  PricePriorityOPTION?: Maybe<Scalars['String']>;
  NetWeight?: Maybe<Scalars['Float']>;
  TotalNetWeight?: Maybe<Scalars['Float']>;
  RecursiveLevel?: Maybe<Scalars['Float']>;
  Repurchase?: Maybe<Scalars['Boolean']>;
  PriceScheduleCode?: Maybe<Scalars['String']>;
  CPNextInvoiceAmount?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatus?: Maybe<Scalars['Float']>;
  CPAdvancePaymentStatusOPTION?: Maybe<Scalars['String']>;
  CPAdvancePaymentDocNo?: Maybe<Scalars['String']>;
  InvoiceFee?: Maybe<Scalars['Boolean']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  StairPriceDiscount?: Maybe<Scalars['Float']>;
  ReceiveRespCenter?: Maybe<Scalars['String']>;
  ReceiveCostCenter?: Maybe<Scalars['String']>;
  AllowLineDisconExHours?: Maybe<Scalars['Boolean']>;
  QuantitytoAllocate?: Maybe<Scalars['Float']>;
  ServiceMotherLineNo?: Maybe<Scalars['Float']>;
  AdditionalCharge?: Maybe<Scalars['Boolean']>;
  AdjforAdditionalCharge?: Maybe<Scalars['Boolean']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  EQMServiceNoTemp?: Maybe<Scalars['String']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  DispatchTask?: Maybe<Scalars['Float']>;
  DispatchTaskOPTION?: Maybe<Scalars['String']>;
  DispatchStatus?: Maybe<Scalars['Float']>;
  DispatchStatusOPTION?: Maybe<Scalars['String']>;
  ScheduledDeliveryDate?: Maybe<Scalars['String']>;
  ScheduledDeliveryTime?: Maybe<Scalars['String']>;
  ScheduledPickupDate?: Maybe<Scalars['String']>;
  ScheduledPickupTime?: Maybe<Scalars['String']>;
  DeliveryDriver?: Maybe<Scalars['String']>;
  PickupDriver?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  SchedDate?: Maybe<Scalars['String']>;
  ActualDate?: Maybe<Scalars['String']>;
  SchedTime?: Maybe<Scalars['String']>;
  ActualTime?: Maybe<Scalars['String']>;
  ActualDeliveryDate?: Maybe<Scalars['String']>;
  ActualDeliveryTime?: Maybe<Scalars['String']>;
  ActualPickupDate?: Maybe<Scalars['String']>;
  ActualPickupTime?: Maybe<Scalars['String']>;
  ActualReturnDate?: Maybe<Scalars['String']>;
  ActualReturnTime?: Maybe<Scalars['String']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  DispatchDocType?: Maybe<Scalars['Float']>;
  DispatchDocTypeOPTION?: Maybe<Scalars['String']>;
  DispatchDocNo?: Maybe<Scalars['String']>;
  DispatchLineNo?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  UserFilter?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DefaultKITPriceCompFlow?: Maybe<Scalars['Float']>;
  AssignedtoKIT?: Maybe<Scalars['String']>;
  OffRentDateFlow?: Maybe<Scalars['String']>;
  ReturnDateFlow?: Maybe<Scalars['String']>;
  QuantityShippedtoFlow?: Maybe<Scalars['Float']>;
  QuantityReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstOffRentedFlow?: Maybe<Scalars['Float']>;
  QuantityPstReturnedFlow?: Maybe<Scalars['Float']>;
  QuantityPstShippedFlow?: Maybe<Scalars['Float']>;
  QtyonPickOrder?: Maybe<Scalars['Float']>;
  QtyonShipmentOrder?: Maybe<Scalars['Float']>;
  QtyonCollectionOrder?: Maybe<Scalars['Float']>;
  UserLocationFilter?: Maybe<Scalars['String']>;
  NoofWorkOrderLines?: Maybe<Scalars['Float']>;
  NoofPickOrderLines?: Maybe<Scalars['Float']>;
  NoofShipmentOrderLines?: Maybe<Scalars['Float']>;
  NoofCollectionOrderLines?: Maybe<Scalars['Float']>;
  NoofPostedPickOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdShptOrderLines?: Maybe<Scalars['Float']>;
  NoofPstdCollOrderLines?: Maybe<Scalars['Float']>;
  NoofRntlReservSummaries?: Maybe<Scalars['Float']>;
  NoofRntlReservEntries?: Maybe<Scalars['Float']>;
  QtyonRntlReservEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResShptEntry?: Maybe<Scalars['Float']>;
  QtyonRntlResRetEntry?: Maybe<Scalars['Float']>;
  QtyonBufferEntry?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodPrice?: Maybe<Scalars['Float']>;
  MultipleRentalPeriodEntry?: Maybe<Scalars['Float']>;
  LastErrorText?: Maybe<Scalars['String']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  SaleReservedQuantity?: Maybe<Scalars['Float']>;
  SaleReservedQtyBase?: Maybe<Scalars['Float']>;
  KITNetWeight?: Maybe<Scalars['Float']>;
  ReportedRentalHours?: Maybe<Scalars['Float']>;
  EQMServiceDocNo?: Maybe<Scalars['String']>;
  DispatchDocument?: Maybe<Scalars['Boolean']>;
  DispatchDocumentNo?: Maybe<Scalars['String']>;
  GroupOnNumber?: Maybe<Scalars['String']>;
  PeriodLineAmountEdge?: Maybe<Scalars['Float']>;
  RentalDays?: Maybe<Scalars['Float']>;
  RemainingQty?: Maybe<Scalars['Float']>;
  FreeDays?: Maybe<Scalars['Float']>;
  DayNetPriceEdge?: Maybe<Scalars['Float']>;
  Vaccation?: Maybe<Scalars['Boolean']>;
  Tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  ReturnReservationActive?: Maybe<Scalars['Boolean']>;
  UserExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  ImageUrl?: Maybe<Scalars['String']>;
  PSILink?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  SnapshotOn?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalPostedCollHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingHour?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  CollectionStatus?: Maybe<Scalars['Float']>;
  CollectionStatusOPTION?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderDocumentType?: Maybe<Scalars['Float']>;
  OrderDocumentTypeOPTION?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  Requestedby?: Maybe<Scalars['String']>;
  Receivedby?: Maybe<Scalars['String']>;
  ResponsibilityCenterCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  Broker?: Maybe<Scalars['String']>;
  Carrier?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  BOLSignature?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalPostedCollLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ExtRefNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  LoadSequenceNo?: Maybe<Scalars['String']>;
  PriceStairNo?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  SerialNo?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  OffRentUpdated?: Maybe<Scalars['Boolean']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  QuantitySold?: Maybe<Scalars['Float']>;
  QuantityDamaged?: Maybe<Scalars['Float']>;
  QuantityOffRented?: Maybe<Scalars['Float']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  EQMServiceID?: Maybe<Scalars['Float']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  MotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  FullyConsumedAccessory?: Maybe<Scalars['Boolean']>;
  ScannedBy?: Maybe<Scalars['String']>;
  ScannedDate?: Maybe<Scalars['DateTime']>;
  ScannedTime?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyOffRent?: Maybe<Scalars['Float']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  PstDeliveryEntryNo?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  ReceivingBinCode?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  AppliedfromDeliverEntryNo?: Maybe<Scalars['Float']>;
  Correction?: Maybe<Scalars['Boolean']>;
  Level?: Maybe<Scalars['Float']>;
  LineType?: Maybe<Scalars['Float']>;
  LineTypeOPTION?: Maybe<Scalars['String']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['DateTime']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalPostedShptHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  ShippingHour?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  LanguageCode?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderDocumentType?: Maybe<Scalars['Float']>;
  OrderDocumentTypeOPTION?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  ResponsibilityCenterCode?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ShiptoPhoneNo?: Maybe<Scalars['String']>;
  ShiptoMobilePhoneNo?: Maybe<Scalars['String']>;
  Broker?: Maybe<Scalars['String']>;
  Carrier?: Maybe<Scalars['String']>;
  Driver?: Maybe<Scalars['String']>;
  BOLSignature?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalPostedShptLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  ExtRefNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  CustomerSubProject?: Maybe<Scalars['String']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  PriceTerm?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  LineAmount?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  RentalPeriod?: Maybe<Scalars['Float']>;
  LoadSequenceNo?: Maybe<Scalars['String']>;
  StairPriceCode?: Maybe<Scalars['String']>;
  StairDiscountCode?: Maybe<Scalars['String']>;
  TransId?: Maybe<Scalars['Float']>;
  SerialNo?: Maybe<Scalars['String']>;
  PickDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ExpirationDate?: Maybe<Scalars['DateTime']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  MotherLineNo?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['Float']>;
  DiamondBladeItemOPTION?: Maybe<Scalars['String']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  ScannedBy?: Maybe<Scalars['String']>;
  ScannedDate?: Maybe<Scalars['DateTime']>;
  ScannedTime?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  OrderDocNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  QuantityCollected?: Maybe<Scalars['Float']>;
  CollectionDocNo?: Maybe<Scalars['String']>;
  CollectionLineNo?: Maybe<Scalars['Float']>;
  RentalInsurance?: Maybe<Scalars['Boolean']>;
  PriceReportedHours?: Maybe<Scalars['Float']>;
  FreeRentalHours?: Maybe<Scalars['Float']>;
  MinimumReportedHours?: Maybe<Scalars['Float']>;
  MinRepHoursPeriod?: Maybe<Scalars['String']>;
  StairPrice1?: Maybe<Scalars['Float']>;
  StairPrice2?: Maybe<Scalars['Float']>;
  StairPrice3?: Maybe<Scalars['Float']>;
  StairPrice4?: Maybe<Scalars['Float']>;
  StairPrice5?: Maybe<Scalars['Float']>;
  MinimumRentalPeriod?: Maybe<Scalars['String']>;
  MinimumRentaltoDate?: Maybe<Scalars['DateTime']>;
  UseAdvancedMgtCalendar?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalPriceTermsInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ChargeFullPeriod?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  ReportedHours?: Maybe<Scalars['Boolean']>;
  FixedDays?: Maybe<Scalars['Float']>;
  SquareMeter?: Maybe<Scalars['Boolean']>;
  FullTerm?: Maybe<Scalars['Boolean']>;
  HalfTerm?: Maybe<Scalars['Boolean']>;
  FixedBillingPeriod?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRentalReturnEntryInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  ReturnNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  ExtRentalLineNo?: Maybe<Scalars['Float']>;
  EntryType?: Maybe<Scalars['String']>;
  EntryTypeOPTION?: Maybe<Scalars['String']>;
  ReturnType?: Maybe<Scalars['String']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DocumentType?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  PostedinDocument?: Maybe<Scalars['Boolean']>;
  PostingTime?: Maybe<Scalars['String']>;
  TransferredtoOrder?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SerialNo?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  StartRentalPeriod?: Maybe<Scalars['String']>;
  OnRentDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  InvoicedToDate?: Maybe<Scalars['DateTime']>;
  OffRentDate?: Maybe<Scalars['DateTime']>;
  OffRentTime?: Maybe<Scalars['String']>;
  ReturnDate?: Maybe<Scalars['DateTime']>;
  ReturnTime?: Maybe<Scalars['String']>;
  DebitonReturnDate?: Maybe<Scalars['Boolean']>;
  Quantity?: Maybe<Scalars['Float']>;
  RemainingQuantity?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TransactionNo?: Maybe<Scalars['Float']>;
  LocationCode?: Maybe<Scalars['String']>;
  ReceivingLocationCode?: Maybe<Scalars['String']>;
  UndoEntry?: Maybe<Scalars['Boolean']>;
  DispatchType?: Maybe<Scalars['String']>;
  PstDispatchDocNo?: Maybe<Scalars['String']>;
  PstDispatchLineNo?: Maybe<Scalars['Float']>;
  OrderDocNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  DoPostReturn?: Maybe<Scalars['Boolean']>;
  DoPostOffRent?: Maybe<Scalars['Boolean']>;
  OrigOffRentDate?: Maybe<Scalars['DateTime']>;
  NoSeries?: Maybe<Scalars['String']>;
  KITDontShow?: Maybe<Scalars['Boolean']>;
  KITMotherLineNo?: Maybe<Scalars['Float']>;
  NumberofRentFreeDays?: Maybe<Scalars['Float']>;
  NumberofRentFreeHours?: Maybe<Scalars['Float']>;
  ReasonforRentFree?: Maybe<Scalars['String']>;
  CreditonEarlyReturn?: Maybe<Scalars['Float']>;
  QuantitySold?: Maybe<Scalars['Float']>;
  QuantityDamaged?: Maybe<Scalars['Float']>;
  CounteronReturn?: Maybe<Scalars['Float']>;
  Maintenance?: Maybe<Scalars['Boolean']>;
  CounteronShipment?: Maybe<Scalars['Float']>;
  Counter2onReturn?: Maybe<Scalars['Float']>;
  Counter2onShipment?: Maybe<Scalars['Float']>;
  ReturnQuantityTime?: Maybe<Scalars['Float']>;
  TmpLocationCode?: Maybe<Scalars['String']>;
  AppliestoEntry?: Maybe<Scalars['Float']>;
  CompleteReturn?: Maybe<Scalars['Boolean']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ChargeCustomer?: Maybe<Scalars['Boolean']>;
  MeasurementatDelivery?: Maybe<Scalars['Float']>;
  MeasurementatReturn?: Maybe<Scalars['Float']>;
  DiamondBladeItem?: Maybe<Scalars['String']>;
  FullyConsumedAccessory?: Maybe<Scalars['Boolean']>;
  FullReturnSale?: Maybe<Scalars['Boolean']>;
  RentalSale?: Maybe<Scalars['Boolean']>;
  QtyReserved?: Maybe<Scalars['Float']>;
  QtytoCollect?: Maybe<Scalars['Float']>;
  QuantityReturned?: Maybe<Scalars['Float']>;
  HourlyRent?: Maybe<Scalars['Boolean']>;
  QtyHours?: Maybe<Scalars['Float']>;
  ShipmentTime?: Maybe<Scalars['String']>;
  OnRentTime?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  ReceivingBinCode?: Maybe<Scalars['String']>;
  PriceonExtraHours?: Maybe<Scalars['Float']>;
  KITComponent?: Maybe<Scalars['Boolean']>;
  EQMServiceDoc?: Maybe<Scalars['String']>;
  IgnoreVaryingCompQty?: Maybe<Scalars['Boolean']>;
  QtyPstCollectedFlow?: Maybe<Scalars['Float']>;
  PstdCollOrderDocNo?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceTermCode?: Maybe<Scalars['String']>;
  RentalClosed?: Maybe<Scalars['Boolean']>;
  BaseCalendar?: Maybe<Scalars['String']>;
  LineAmountPeriod?: Maybe<Scalars['Float']>;
  ProjectedReturnDate?: Maybe<Scalars['DateTime']>;
  InvoiceToDate?: Maybe<Scalars['DateTime']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  DiamondQuantity?: Maybe<Scalars['Float']>;
  DontTransfertoOrder?: Maybe<Scalars['Boolean']>;
  RentalDays?: Maybe<Scalars['Float']>;
  MonthPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRequestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['Float']>;
  RequestTypeOPTION?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  User?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Time?: Maybe<Scalars['String']>;
  DispatchHeader?: Maybe<Scalars['String']>;
  DispatchPosted?: Maybe<Scalars['Boolean']>;
  LocationCode?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRequestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['Float']>;
  RequestTypeOPTION?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  LineType?: Maybe<Scalars['Float']>;
  LineTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  SubProjectNo?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ManufacturerCode?: Maybe<Scalars['String']>;
  ManufacturerModelCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateReservationRequestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  ContractNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  DeliveryDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  ExpectedReturnDate?: Maybe<Scalars['DateTime']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  DomainName?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  Pickup?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateReservationRequestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  LineGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateResourceInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  SocialSecurityNo?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  Education?: Maybe<Scalars['String']>;
  ContractClass?: Maybe<Scalars['String']>;
  EmploymentDate?: Maybe<Scalars['DateTime']>;
  ResourceGroupNo?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  BaseUnitofMeasure?: Maybe<Scalars['String']>;
  DirectUnitCost?: Maybe<Scalars['Float']>;
  IndirectCost?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  PriceProfitCalculation?: Maybe<Scalars['Float']>;
  PriceProfitCalculationOPTION?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  VendorNo?: Maybe<Scalars['String']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  Blocked?: Maybe<Scalars['Boolean']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  AutomaticExtTexts?: Maybe<Scalars['Boolean']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  ICPartnerPurchGLAccNo?: Maybe<Scalars['String']>;
  UseTimeSheet?: Maybe<Scalars['Boolean']>;
  TimeSheetOwnerUserID?: Maybe<Scalars['String']>;
  TimeSheetApproverUserID?: Maybe<Scalars['String']>;
  ServiceZoneFilter?: Maybe<Scalars['String']>;
  DefaultQuantity?: Maybe<Scalars['Float']>;
  Mounting?: Maybe<Scalars['Boolean']>;
  DateFilter?: Maybe<Scalars['String']>;
  UnitofMeasureFilter?: Maybe<Scalars['String']>;
  ChargeableFilter?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Capacity?: Maybe<Scalars['Float']>;
  QtyonOrderJob?: Maybe<Scalars['Float']>;
  QtyQuotedJob?: Maybe<Scalars['Float']>;
  UsageQty?: Maybe<Scalars['Float']>;
  UsageCost?: Maybe<Scalars['Float']>;
  UsagePrice?: Maybe<Scalars['Float']>;
  SalesQty?: Maybe<Scalars['Float']>;
  SalesCost?: Maybe<Scalars['Float']>;
  SalesPrice?: Maybe<Scalars['Float']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  InCustomerZone?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ShowInFieldOrder?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateResponsibilityCenterInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  EMailSignatureText?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ContractGainLossAmount?: Maybe<Scalars['Float']>;
  Longitud?: Maybe<Scalars['String']>;
  Latitud?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateReturnRequestHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  DomainName?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  PickUpDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  DropOff?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateReturnRequestLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  GroupGUID?: Maybe<Scalars['String']>;
  LineGUID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['Boolean']>;
  TypeOfRequest?: Maybe<Scalars['Float']>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  User?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['DateTime']>;
  CustomerNo?: Maybe<Scalars['String']>;
  ProjectNo?: Maybe<Scalars['String']>;
  Executed?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRiskAnalysisHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Template?: Maybe<Scalars['String']>;
  WorkPlace?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedByPhone?: Maybe<Scalars['String']>;
  Customer?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  Database?: Maybe<Scalars['String']>;
  RecordID?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  CurrentHandler?: Maybe<Scalars['String']>;
  CurrentHandlerEmail?: Maybe<Scalars['String']>;
  CurrentHandlerPhone?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  FinishedByUser?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRiskAnalysisLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  RiskAnalysisNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  RiskCode?: Maybe<Scalars['Float']>;
  RiskCodeOPTION?: Maybe<Scalars['String']>;
  Risk?: Maybe<Scalars['Boolean']>;
  Action?: Maybe<Scalars['String']>;
  Probability?: Maybe<Scalars['Float']>;
  ProbabilityOPTION?: Maybe<Scalars['String']>;
  Consequence?: Maybe<Scalars['Float']>;
  ConsequenceOPTION?: Maybe<Scalars['String']>;
  Mandatory?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateRiskAnalysisSignatureInput = {
  discriminator?: Maybe<Scalars['String']>;
  RiskAnalysisNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Signature?: Maybe<Scalars['String']>;
  TypeOfSignature?: Maybe<Scalars['Float']>;
  TypeOfSignatureOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesHeaderArchiveInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesHeaderArchiveDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  SelltoCustomerGlobalId?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  QuoteValidUntilDate?: Maybe<Scalars['DateTime']>;
  QuoteSenttoCustomer?: Maybe<Scalars['String']>;
  QuoteAccepted?: Maybe<Scalars['Boolean']>;
  QuoteAcceptedDate?: Maybe<Scalars['DateTime']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['Float']>;
  EQMShipmentTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['Float']>;
  EQMTransferMethodOPTION?: Maybe<Scalars['String']>;
  EQMContraryDoc?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMCombineCustomerProject?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMInvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  EQMCashSaleDocument?: Maybe<Scalars['Boolean']>;
  EQMUseDeposit?: Maybe<Scalars['Boolean']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['Float']>;
  LastEmailSentStatusOPTION?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  AmtShipNotInvLCY?: Maybe<Scalars['Float']>;
  AmtShipNotInvLCYBase?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  Shipped?: Maybe<Scalars['Boolean']>;
  LateOrderShipping?: Maybe<Scalars['Boolean']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  MagentoId?: Maybe<Scalars['Float']>;
  BaseSubTotal?: Maybe<Scalars['Float']>;
  BaseSubTotalInclTax?: Maybe<Scalars['Float']>;
  ShippingDescription?: Maybe<Scalars['String']>;
  CustomerNote?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  BilltoEmail?: Maybe<Scalars['String']>;
  BilltoTelephone?: Maybe<Scalars['String']>;
  ShipToId?: Maybe<Scalars['Float']>;
  ShiptoEmail?: Maybe<Scalars['String']>;
  ShiptoTelephone?: Maybe<Scalars['String']>;
  MagentoOrderStatus?: Maybe<Scalars['String']>;
  MagentoOrderState?: Maybe<Scalars['String']>;
  LineCount?: Maybe<Scalars['Float']>;
  FreightIncluded?: Maybe<Scalars['Boolean']>;
  DiscountIncluded?: Maybe<Scalars['Boolean']>;
  DeliveryMethod?: Maybe<Scalars['Float']>;
  ProcessedByEdge?: Maybe<Scalars['Boolean']>;
  EdgeStartedPollingOn?: Maybe<Scalars['DateTime']>;
  HandleRelationsInJSONStructure?: Maybe<Scalars['Boolean']>;
  WebbComment?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  VersionNo?: Maybe<Scalars['Float']>;
  DeletedReason?: Maybe<SalesHeaderArchiveDeletedReasonEnum>;
  IsConstructed?: Maybe<Scalars['Boolean']>;
  PaymentMethodWeb?: Maybe<SalesHeaderArchivePaymentMethodWebEnum>;
  SharkActualPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesHeaderHistoryInput = {
  discriminator?: Maybe<Scalars['String']>;
  GUID?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesHeaderHistoryTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesHeaderDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  SelltoCustomerGlobalId?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  QuoteValidUntilDate?: Maybe<Scalars['DateTime']>;
  QuoteSenttoCustomer?: Maybe<Scalars['String']>;
  QuoteAccepted?: Maybe<Scalars['Boolean']>;
  QuoteAcceptedDate?: Maybe<Scalars['DateTime']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<SalesHeaderShippingAdviceEnum>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  EQMShipmentType?: Maybe<Scalars['Float']>;
  EQMShipmentTypeOPTION?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMCustomerType?: Maybe<Scalars['Float']>;
  EQMCustomerTypeOPTION?: Maybe<Scalars['String']>;
  EQMRentalDocument?: Maybe<Scalars['Boolean']>;
  EQMTransferMethod?: Maybe<Scalars['Float']>;
  EQMTransferMethodOPTION?: Maybe<Scalars['String']>;
  EQMContraryDoc?: Maybe<Scalars['Boolean']>;
  EQMWorkshopDocument?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMServiceTermNo?: Maybe<Scalars['Float']>;
  EQMCombineCustomerProject?: Maybe<Scalars['String']>;
  EQMInvoiceDocType?: Maybe<Scalars['Float']>;
  EQMInvoiceDocTypeOPTION?: Maybe<Scalars['String']>;
  EQMCashSaleDocument?: Maybe<Scalars['Boolean']>;
  EQMUseDeposit?: Maybe<Scalars['Boolean']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMJobNo?: Maybe<Scalars['String']>;
  EQMJobTaskNo?: Maybe<Scalars['String']>;
  EQMWSObjectCounter?: Maybe<Scalars['Float']>;
  EQMServiceNo?: Maybe<Scalars['String']>;
  EQMServiceStartDate?: Maybe<Scalars['DateTime']>;
  EQMServiceEndDate?: Maybe<Scalars['DateTime']>;
  EQMServiceStatus?: Maybe<Scalars['Float']>;
  EQMServiceStatusOPTION?: Maybe<Scalars['String']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMWSEstimatedStartDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedStartTime?: Maybe<Scalars['String']>;
  EQMWSEstimatedEndDate?: Maybe<Scalars['DateTime']>;
  EQMWSEstimatedEndTime?: Maybe<Scalars['String']>;
  EQMServiceStartTime?: Maybe<Scalars['String']>;
  EQMServiceEndTime?: Maybe<Scalars['String']>;
  EQMWSObjectCounter2?: Maybe<Scalars['Float']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  LastEmailSentTime?: Maybe<Scalars['String']>;
  LastEmailSentStatus?: Maybe<Scalars['Float']>;
  LastEmailSentStatusOPTION?: Maybe<Scalars['String']>;
  SentasEmail?: Maybe<Scalars['Boolean']>;
  LastEmailNotifCleared?: Maybe<Scalars['Boolean']>;
  AmtShipNotInvLCY?: Maybe<Scalars['Float']>;
  AmtShipNotInvLCYBase?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  NoofArchivedVersions?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Boolean']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  Shipped?: Maybe<Scalars['Boolean']>;
  LateOrderShipping?: Maybe<Scalars['Boolean']>;
  EQMObjectWarrantyDate?: Maybe<Scalars['DateTime']>;
  MagentoId?: Maybe<Scalars['Float']>;
  BaseSubTotal?: Maybe<Scalars['Float']>;
  BaseSubTotalInclTax?: Maybe<Scalars['Float']>;
  ShippingDescription?: Maybe<Scalars['String']>;
  CustomerNote?: Maybe<Scalars['String']>;
  CustomerEmail?: Maybe<Scalars['String']>;
  BilltoEmail?: Maybe<Scalars['String']>;
  BilltoTelephone?: Maybe<Scalars['String']>;
  ShipToId?: Maybe<Scalars['Float']>;
  ShiptoEmail?: Maybe<Scalars['String']>;
  ShiptoTelephone?: Maybe<Scalars['String']>;
  MagentoOrderStatus?: Maybe<Scalars['String']>;
  MagentoOrderState?: Maybe<Scalars['String']>;
  LineCount?: Maybe<Scalars['Float']>;
  FreightIncluded?: Maybe<Scalars['Boolean']>;
  DiscountIncluded?: Maybe<Scalars['Boolean']>;
  DeliveryMethod?: Maybe<Scalars['Float']>;
  ProcessedByEdge?: Maybe<Scalars['Boolean']>;
  EdgeStartedPollingOn?: Maybe<Scalars['DateTime']>;
  HandleRelationsInJSONStructure?: Maybe<Scalars['Boolean']>;
  WebbComment?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  PaymentMethodWeb?: Maybe<SalesHeaderPaymentMethodWebEnum>;
  EMail?: Maybe<Scalars['String']>;
  WebOrderNo?: Maybe<Scalars['String']>;
  AdditionalTelephone?: Maybe<Scalars['String']>;
  SellToAddressGlobalId?: Maybe<Scalars['String']>;
  SharkActualPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesHeaderRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['DateTime']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderClass?: Maybe<Scalars['String']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['Float']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Ship?: Maybe<Scalars['Boolean']>;
  Invoice?: Maybe<Scalars['Boolean']>;
  PrintPostedDocuments?: Maybe<Scalars['Boolean']>;
  ShippingNo?: Maybe<Scalars['String']>;
  PostingNo?: Maybe<Scalars['String']>;
  LastShippingNo?: Maybe<Scalars['String']>;
  LastPostingNo?: Maybe<Scalars['String']>;
  PrepaymentNo?: Maybe<Scalars['String']>;
  LastPrepaymentNo?: Maybe<Scalars['String']>;
  PrepmtCrMemoNo?: Maybe<Scalars['String']>;
  LastPrepmtCrMemoNo?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  CombineShipments?: Maybe<Scalars['Boolean']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['Float']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  PostingNoSeries?: Maybe<Scalars['String']>;
  ShippingNoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  AppliestoID?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountCalculation?: Maybe<Scalars['Float']>;
  InvoiceDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  InvoiceDiscountValue?: Maybe<Scalars['Float']>;
  SendICDocument?: Maybe<Scalars['Boolean']>;
  ICStatus?: Maybe<Scalars['Float']>;
  ICStatusOPTION?: Maybe<Scalars['String']>;
  SelltoICPartnerCode?: Maybe<Scalars['String']>;
  BilltoICPartnerCode?: Maybe<Scalars['String']>;
  ICDirection?: Maybe<Scalars['Float']>;
  ICDirectionOPTION?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  CompressPrepayment?: Maybe<Scalars['Boolean']>;
  PrepaymentDueDate?: Maybe<Scalars['DateTime']>;
  PrepmtCrMemoNoSeries?: Maybe<Scalars['String']>;
  PrepmtPostingDescription?: Maybe<Scalars['String']>;
  PrepmtPmtDiscountDate?: Maybe<Scalars['DateTime']>;
  PrepmtPaymentTermsCode?: Maybe<Scalars['String']>;
  PrepmtPaymentDiscount?: Maybe<Scalars['Float']>;
  QuoteNo?: Maybe<Scalars['String']>;
  JobQueueStatus?: Maybe<Scalars['Float']>;
  JobQueueStatusOPTION?: Maybe<Scalars['String']>;
  JobQueueEntryID?: Maybe<Scalars['String']>;
  IncomingDocumentEntryNo?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AuthorizationRequired?: Maybe<Scalars['Boolean']>;
  CreditCardNo?: Maybe<Scalars['String']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  BilltoCustomerTemplateCode?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  ShippingAdvice?: Maybe<Scalars['Float']>;
  ShippingAdviceOPTION?: Maybe<Scalars['String']>;
  PostingfromWhseRef?: Maybe<Scalars['Float']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  Receive?: Maybe<Scalars['Boolean']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptNoSeries?: Maybe<Scalars['String']>;
  LastReturnReceiptNo?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  AssignedUserID?: Maybe<Scalars['String']>;
  SourceInvNo?: Maybe<Scalars['String']>;
  SourceInvVAT?: Maybe<Scalars['Float']>;
  SourceInvTotal?: Maybe<Scalars['Float']>;
  Ordertype?: Maybe<Scalars['Float']>;
  OrdertypeOPTION?: Maybe<Scalars['String']>;
  OurReference?: Maybe<Scalars['String']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  ConfirmedbyVendor?: Maybe<Scalars['Boolean']>;
  DocumentMethod?: Maybe<Scalars['Float']>;
  DocumentMethodOPTION?: Maybe<Scalars['String']>;
  InvoiceEmailAddress?: Maybe<Scalars['String']>;
  NAVStatus?: Maybe<Scalars['Float']>;
  NAVStatusOPTION?: Maybe<Scalars['String']>;
  NAVImportedDate?: Maybe<Scalars['DateTime']>;
  NAVOrderNo?: Maybe<Scalars['String']>;
  NAVErrorMessage?: Maybe<Scalars['String']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  LocationFilter?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesLineArchiveInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesLineArchiveDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesLineArchiveTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ItemGlobalId?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvLCYNoVAT?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  LineDiscountCalculation?: Maybe<Scalars['Float']>;
  LineDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  EQMCustomerProject?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMReturnDocumentNo?: Maybe<Scalars['String']>;
  EQMReturnLineNo?: Maybe<Scalars['Float']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMAllocateObjectNo?: Maybe<Scalars['String']>;
  EQMRentalLineType?: Maybe<Scalars['Float']>;
  EQMRentalLineTypeOPTION?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['Float']>;
  EQMWorkshopTypeOPTION?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMOldTmpRKITLineNo?: Maybe<Scalars['Float']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMQtyonAtttoLineNo?: Maybe<Scalars['Float']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMRentalPrepaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtLCY?: Maybe<Scalars['Float']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['Float']>;
  EQMPriceReportedHoursOPTION?: Maybe<Scalars['String']>;
  EQMFACorrection?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  ProductType?: Maybe<Scalars['String']>;
  SalesOrderEdgeGuid?: Maybe<Scalars['String']>;
  VersionNo?: Maybe<Scalars['Float']>;
  DocNoOccurrence?: Maybe<Scalars['Float']>;
  SalesOrderLineEdgeGuid?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<SalesLineDocumentTypeEnum>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  Type?: Maybe<SalesLineTypeEnum>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  ItemGlobalId?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountPercent?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxCategory?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvLCYNoVAT?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  LineDiscountCalculation?: Maybe<Scalars['Float']>;
  LineDiscountCalculationOPTION?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  DeferralCode?: Maybe<Scalars['String']>;
  ReturnsDeferralStartDate?: Maybe<Scalars['DateTime']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['DateTime']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  Subtype?: Maybe<Scalars['Float']>;
  SubtypeOPTION?: Maybe<Scalars['String']>;
  Pricedescription?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  EQMCustomerProject?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  EQMObjectNo?: Maybe<Scalars['String']>;
  EQMContractNo?: Maybe<Scalars['String']>;
  EQMRental?: Maybe<Scalars['Boolean']>;
  EQMRentalFromDate?: Maybe<Scalars['DateTime']>;
  EQMRentalToDate?: Maybe<Scalars['DateTime']>;
  EQMRentalLineNo?: Maybe<Scalars['Float']>;
  EQMRentalDays?: Maybe<Scalars['Float']>;
  EQMAttachedtoNo?: Maybe<Scalars['String']>;
  EQMAttachedtoLineNo?: Maybe<Scalars['Float']>;
  EQMRentalFreeDays?: Maybe<Scalars['Float']>;
  EQMLineUpdated?: Maybe<Scalars['Boolean']>;
  EQMRentalSale?: Maybe<Scalars['Boolean']>;
  EQMRentalQuantity?: Maybe<Scalars['Float']>;
  EQMRentalNo?: Maybe<Scalars['String']>;
  EQMReturnDocumentNo?: Maybe<Scalars['String']>;
  EQMReturnLineNo?: Maybe<Scalars['Float']>;
  EQMObjectType?: Maybe<Scalars['String']>;
  EQMObjectGroup?: Maybe<Scalars['String']>;
  EQMRentalFromDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalToDateTime?: Maybe<Scalars['DateTime']>;
  EQMRentalDuration?: Maybe<Scalars['Float']>;
  EQMServiceUpwrite?: Maybe<Scalars['Boolean']>;
  EQMAllocateObjectNo?: Maybe<Scalars['String']>;
  EQMRentalLineType?: Maybe<Scalars['Float']>;
  EQMRentalLineTypeOPTION?: Maybe<Scalars['String']>;
  EQMUseOfFixPrice?: Maybe<Scalars['Boolean']>;
  EQMUpdateRentalLine?: Maybe<Scalars['Boolean']>;
  EQMWorkshop?: Maybe<Scalars['Boolean']>;
  EQMWorkshopObjectNo?: Maybe<Scalars['String']>;
  EQMWorkshopGuarantee?: Maybe<Scalars['Boolean']>;
  EQMWorkshopType?: Maybe<Scalars['Float']>;
  EQMWorkshopTypeOPTION?: Maybe<Scalars['String']>;
  EQMSkipObjectIntoInv?: Maybe<Scalars['Boolean']>;
  EQMPriceTermCode?: Maybe<Scalars['String']>;
  EQMRefBasisEntryNo?: Maybe<Scalars['Float']>;
  EQMStairPriceCode?: Maybe<Scalars['String']>;
  EQMStairPriceStep?: Maybe<Scalars['Float']>;
  EQMRentalKITMother?: Maybe<Scalars['String']>;
  EQMRentalKITMotherLineNo?: Maybe<Scalars['Float']>;
  EQMDepositPosting?: Maybe<Scalars['Boolean']>;
  EQMOldTmpRKITLineNo?: Maybe<Scalars['Float']>;
  EQMContractType?: Maybe<Scalars['Float']>;
  EQMContractTypeOPTION?: Maybe<Scalars['String']>;
  EQMQtyonAtttoLineNo?: Maybe<Scalars['Float']>;
  EQMReRentObject?: Maybe<Scalars['String']>;
  EQMUsedFractionCalculation?: Maybe<Scalars['Boolean']>;
  EQMRentalHeaderLine?: Maybe<Scalars['Boolean']>;
  EQMSerialNo?: Maybe<Scalars['String']>;
  EQMReclassOrderedBy?: Maybe<Scalars['String']>;
  EQMReclassOrderNo?: Maybe<Scalars['String']>;
  EQMExtraHoursCharged?: Maybe<Scalars['Boolean']>;
  EQMExtraHoursReturnNo?: Maybe<Scalars['String']>;
  EQMRentalSplitCostCenter?: Maybe<Scalars['String']>;
  EQMRentalSplit?: Maybe<Scalars['Float']>;
  EQMReceivingRespCenter?: Maybe<Scalars['String']>;
  EQMReceivingCostCenterCode?: Maybe<Scalars['String']>;
  EQMRePurchase?: Maybe<Scalars['Boolean']>;
  EQMInvoiceFee?: Maybe<Scalars['Boolean']>;
  EQMPriceScheduleCode?: Maybe<Scalars['String']>;
  EQMRentalPrepaymentEntry?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtLCY?: Maybe<Scalars['Float']>;
  EQMRentalInsurance?: Maybe<Scalars['Boolean']>;
  EQMObjectInsurance?: Maybe<Scalars['String']>;
  EQMServiceMotherLineNo?: Maybe<Scalars['Float']>;
  EQMRepairCode?: Maybe<Scalars['String']>;
  EQMPriceReportedHours?: Maybe<Scalars['Float']>;
  EQMPriceReportedHoursOPTION?: Maybe<Scalars['String']>;
  EQMFACorrection?: Maybe<Scalars['Boolean']>;
  EQMPrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  MagentoId?: Maybe<Scalars['Float']>;
  ProductType?: Maybe<Scalars['String']>;
  SalesOrderLineEdgeGuid?: Maybe<Scalars['String']>;
  LinkedToGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesLineRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  OutstandingQuantity?: Maybe<Scalars['Float']>;
  QtytoInvoice?: Maybe<Scalars['Float']>;
  QtytoShip?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  LineDiscountAmount?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  RecalculateInvoiceDisc?: Maybe<Scalars['Boolean']>;
  OutstandingAmount?: Maybe<Scalars['Float']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  ShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityShipped?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  ShipmentNo?: Maybe<Scalars['String']>;
  ShipmentLineNo?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  InvDiscountAmount?: Maybe<Scalars['Float']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['Float']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATClauseCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  OutstandingAmountLCY?: Maybe<Scalars['Float']>;
  ShippedNotInvoicedLCY?: Maybe<Scalars['Float']>;
  Reserve?: Maybe<Scalars['Float']>;
  ReserveOPTION?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  SystemCreatedEntry?: Maybe<Scalars['Boolean']>;
  LineAmount?: Maybe<Scalars['Float']>;
  VATDifference?: Maybe<Scalars['Float']>;
  InvDiscAmounttoInvoice?: Maybe<Scalars['Float']>;
  VATIdentifier?: Maybe<Scalars['String']>;
  ICPartnerRefType?: Maybe<Scalars['Float']>;
  ICPartnerRefTypeOPTION?: Maybe<Scalars['String']>;
  ICPartnerReference?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PrepmtLineAmount?: Maybe<Scalars['Float']>;
  PrepmtAmtInv?: Maybe<Scalars['Float']>;
  PrepmtAmtInclVAT?: Maybe<Scalars['Float']>;
  PrepaymentAmount?: Maybe<Scalars['Float']>;
  PrepmtVATBaseAmt?: Maybe<Scalars['Float']>;
  PrepaymentVAT?: Maybe<Scalars['Float']>;
  PrepmtVATCalcType?: Maybe<Scalars['Float']>;
  PrepmtVATCalcTypeOPTION?: Maybe<Scalars['String']>;
  PrepaymentVATIdentifier?: Maybe<Scalars['String']>;
  PrepaymentTaxAreaCode?: Maybe<Scalars['String']>;
  PrepaymentTaxLiable?: Maybe<Scalars['Boolean']>;
  PrepaymentTaxGroupCode?: Maybe<Scalars['String']>;
  PrepmtAmttoDeduct?: Maybe<Scalars['Float']>;
  PrepmtAmtDeducted?: Maybe<Scalars['Float']>;
  PrepaymentLine?: Maybe<Scalars['Boolean']>;
  PrepmtAmountInvInclVAT?: Maybe<Scalars['Float']>;
  PrepmtAmountInvLCY?: Maybe<Scalars['Float']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  PrepmtVATAmountInvLCY?: Maybe<Scalars['Float']>;
  PrepaymentVATDifference?: Maybe<Scalars['Float']>;
  PrepmtVATDifftoDeduct?: Maybe<Scalars['Float']>;
  PrepmtVATDiffDeducted?: Maybe<Scalars['Float']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  QtytoAssembletoOrder?: Maybe<Scalars['Float']>;
  QtytoAsmtoOrderBase?: Maybe<Scalars['Float']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Float']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  Planned?: Maybe<Scalars['Boolean']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  OutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoInvoiceBase?: Maybe<Scalars['Float']>;
  QtytoShipBase?: Maybe<Scalars['Float']>;
  QtyShippedNotInvdBase?: Maybe<Scalars['Float']>;
  QtyShippedBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  OutofStockSubstitution?: Maybe<Scalars['Boolean']>;
  OriginallyOrderedNo?: Maybe<Scalars['String']>;
  OriginallyOrderedVarCode?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['Float']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  SpecialOrder?: Maybe<Scalars['Boolean']>;
  SpecialOrderPurchaseNo?: Maybe<Scalars['String']>;
  SpecialOrderPurchLineNo?: Maybe<Scalars['Float']>;
  CompletelyShipped?: Maybe<Scalars['Boolean']>;
  RequestedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PromisedDeliveryDate?: Maybe<Scalars['DateTime']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  AllowItemChargeAssignment?: Maybe<Scalars['Boolean']>;
  ReturnQtytoReceive?: Maybe<Scalars['Float']>;
  ReturnQtytoReceiveBase?: Maybe<Scalars['Float']>;
  ReturnQtyRcdNotInvd?: Maybe<Scalars['Float']>;
  RetQtyRcdNotInvdBase?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvd?: Maybe<Scalars['Float']>;
  ReturnRcdNotInvdLCY?: Maybe<Scalars['Float']>;
  ReturnQtyReceived?: Maybe<Scalars['Float']>;
  ReturnQtyReceivedBase?: Maybe<Scalars['Float']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  BOMItemNo?: Maybe<Scalars['String']>;
  ReturnReceiptNo?: Maybe<Scalars['String']>;
  ReturnReceiptLineNo?: Maybe<Scalars['Float']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  PeriodicTemplateCode?: Maybe<Scalars['String']>;
  PeriodicStartingDate?: Maybe<Scalars['String']>;
  NAVStatus?: Maybe<Scalars['Float']>;
  NAVStatusOPTION?: Maybe<Scalars['String']>;
  NAVImportedDate?: Maybe<Scalars['DateTime']>;
  NAVOrderNo?: Maybe<Scalars['String']>;
  Rubrikrad?: Maybe<Scalars['Boolean']>;
  EndofGroup?: Maybe<Scalars['Boolean']>;
  ChargeType?: Maybe<Scalars['Float']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  ConnectedtoItemLine?: Maybe<Scalars['Boolean']>;
  FreeTextLine?: Maybe<Scalars['Boolean']>;
  ReservedQuantity?: Maybe<Scalars['Float']>;
  ATOWhseOutstandingQty?: Maybe<Scalars['Float']>;
  ATOWhseOutstdQtyBase?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ReservedQtyBase?: Maybe<Scalars['Float']>;
  SubstitutionAvailable?: Maybe<Scalars['Boolean']>;
  WhseOutstandingQty?: Maybe<Scalars['Float']>;
  WhseOutstandingQtyBase?: Maybe<Scalars['Float']>;
  QtytoAssign?: Maybe<Scalars['Float']>;
  QtyAssigned?: Maybe<Scalars['Float']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  NAVErrorMessage?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesPriceInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  SalesType?: Maybe<SalesPriceSalesTypeEnum>;
  SalesTypeOPTION?: Maybe<Scalars['String']>;
  SalesCode?: Maybe<Scalars['String']>;
  StartingDate?: Maybe<Scalars['DateTime']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  MinimumQuantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  PriceIncludesVAT?: Maybe<Scalars['Boolean']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  VATBusPostingGrPrice?: Maybe<Scalars['String']>;
  EndingDate?: Maybe<Scalars['DateTime']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  UnitPriceIncVAT?: Maybe<Scalars['Float']>;
  SalesOrderMultiple?: Maybe<Scalars['Float']>;
  CarepaPurchPrice?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesShipmentHeaderInput = {
  discriminator?: Maybe<Scalars['String']>;
  MagentoOrder?: Maybe<Scalars['Boolean']>;
  MagentoOrderType?: Maybe<Scalars['Float']>;
  MagentoOrderTypeOPTION?: Maybe<Scalars['String']>;
  MagentoOrderId?: Maybe<Scalars['Float']>;
  MagentoOrderEmail?: Maybe<Scalars['String']>;
  MagentoOrderMobileNo?: Maybe<Scalars['String']>;
  MagentoOrderLinesCount?: Maybe<Scalars['Float']>;
  PEBNoteofGoods?: Maybe<Scalars['String']>;
  ShippingAgentServiceCode?: Maybe<Scalars['String']>;
  EdgeRetryCount?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  BilltoName?: Maybe<Scalars['String']>;
  BilltoName2?: Maybe<Scalars['String']>;
  BilltoAddress?: Maybe<Scalars['String']>;
  BilltoAddress2?: Maybe<Scalars['String']>;
  BilltoCity?: Maybe<Scalars['String']>;
  BilltoContact?: Maybe<Scalars['String']>;
  YourReference?: Maybe<Scalars['String']>;
  ShiptoCode?: Maybe<Scalars['String']>;
  ShiptoName?: Maybe<Scalars['String']>;
  ShiptoName2?: Maybe<Scalars['String']>;
  ShiptoAddress?: Maybe<Scalars['String']>;
  ShiptoAddress2?: Maybe<Scalars['String']>;
  ShiptoCity?: Maybe<Scalars['String']>;
  ShiptoContact?: Maybe<Scalars['String']>;
  OrderDate?: Maybe<Scalars['DateTime']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  ShipmentDate?: Maybe<Scalars['DateTime']>;
  PostingDescription?: Maybe<Scalars['String']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  PaymentDiscount?: Maybe<Scalars['Float']>;
  PmtDiscountDate?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyFactor?: Maybe<Scalars['Float']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  SalespersonCode?: Maybe<Scalars['String']>;
  OrderNo?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  NoPrinted?: Maybe<Scalars['Float']>;
  OnHold?: Maybe<Scalars['String']>;
  AppliestoDocType?: Maybe<Scalars['String']>;
  AppliestoDocTypeOPTION?: Maybe<Scalars['String']>;
  AppliestoDocNo?: Maybe<Scalars['String']>;
  BalAccountNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AmountIncludingVAT?: Maybe<Scalars['Float']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  EU3PartyTrade?: Maybe<Scalars['Boolean']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  VATCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoCustomerName?: Maybe<Scalars['String']>;
  SelltoCustomerName2?: Maybe<Scalars['String']>;
  SelltoAddress?: Maybe<Scalars['String']>;
  SelltoAddress2?: Maybe<Scalars['String']>;
  SelltoCity?: Maybe<Scalars['String']>;
  SelltoContact?: Maybe<Scalars['String']>;
  BilltoPostCode?: Maybe<Scalars['String']>;
  BilltoCounty?: Maybe<Scalars['String']>;
  BilltoCountryRegionCode?: Maybe<Scalars['String']>;
  SelltoPostCode?: Maybe<Scalars['String']>;
  SelltoCounty?: Maybe<Scalars['String']>;
  SelltoCountryRegionCode?: Maybe<Scalars['String']>;
  ShiptoPostCode?: Maybe<Scalars['String']>;
  ShiptoCounty?: Maybe<Scalars['String']>;
  ShiptoCountryRegionCode?: Maybe<Scalars['String']>;
  BalAccountType?: Maybe<Scalars['String']>;
  BalAccountTypeOPTION?: Maybe<Scalars['String']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Correction?: Maybe<Scalars['Boolean']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  PackageTrackingNo?: Maybe<Scalars['String']>;
  PreAssignedNoSeries?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  OrderNoSeries?: Maybe<Scalars['String']>;
  PreAssignedNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  SourceCode?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATBaseDiscount?: Maybe<Scalars['Float']>;
  PrepaymentNoSeries?: Maybe<Scalars['String']>;
  PrepaymentInvoice?: Maybe<Scalars['Boolean']>;
  PrepaymentOrderNo?: Maybe<Scalars['String']>;
  QuoteNo?: Maybe<Scalars['String']>;
  WorkDescription?: Maybe<Scalars['String']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  PaymentServiceSetID?: Maybe<Scalars['Float']>;
  DocumentExchangeIdentifier?: Maybe<Scalars['String']>;
  DocumentExchangeStatus?: Maybe<Scalars['String']>;
  DocumentExchangeStatusOPTION?: Maybe<Scalars['String']>;
  DocExchOriginalIdentifier?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  DirectDebitMandateID?: Maybe<Scalars['String']>;
  Closed?: Maybe<Scalars['Boolean']>;
  RemainingAmount?: Maybe<Scalars['Float']>;
  CustLedgerEntryNo?: Maybe<Scalars['Float']>;
  InvoiceDiscountAmount?: Maybe<Scalars['Float']>;
  Cancelled?: Maybe<Scalars['Boolean']>;
  Corrective?: Maybe<Scalars['Boolean']>;
  CampaignNo?: Maybe<Scalars['String']>;
  SelltoContactNo?: Maybe<Scalars['String']>;
  BilltoContactNo?: Maybe<Scalars['String']>;
  OpportunityNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  GetShipmentUsed?: Maybe<Scalars['Boolean']>;
  Preseason?: Maybe<Scalars['Boolean']>;
  AdditionalTelephone?: Maybe<Scalars['String']>;
  AdditionalCustomerNote?: Maybe<Scalars['String']>;
  CustomerCommentExists?: Maybe<Scalars['Boolean']>;
  WebOrderNo?: Maybe<Scalars['String']>;
  WebOrderEntityNo?: Maybe<Scalars['Float']>;
  EMail?: Maybe<Scalars['String']>;
  EMailOrderConfirmation?: Maybe<Scalars['String']>;
  EMailSalesShipment?: Maybe<Scalars['String']>;
  EMailInvoice?: Maybe<Scalars['String']>;
  EMailShipmentNotification?: Maybe<Scalars['String']>;
  OrderCreatedBy?: Maybe<Scalars['String']>;
  OrderCreated?: Maybe<Scalars['String']>;
  OrderType?: Maybe<Scalars['String']>;
  OrderTypeOPTION?: Maybe<Scalars['String']>;
  ShipmentDocType?: Maybe<Scalars['String']>;
  ShipmentDocTypeOPTION?: Maybe<Scalars['String']>;
  GearsOrder?: Maybe<Scalars['Boolean']>;
  NumberofCustomerPriceGroup?: Maybe<Scalars['Float']>;
  EDISetupCode?: Maybe<Scalars['String']>;
  InvoiceDocumentType?: Maybe<Scalars['String']>;
  InvoiceDocumentTypeOPTION?: Maybe<Scalars['String']>;
  InvoiceSentToCustomer?: Maybe<Scalars['Boolean']>;
  InvoiceSentToCustomerDate?: Maybe<Scalars['String']>;
  InvoiceSentToCustomerTime?: Maybe<Scalars['String']>;
  NoteofGoods?: Maybe<Scalars['String']>;
  DocumentGlobalId?: Maybe<Scalars['String']>;
  FreightOnlyShipment?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalesShipmentLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  MagentoOrderLineType?: Maybe<Scalars['Float']>;
  MagentoOrderLineTypeOPTION?: Maybe<Scalars['String']>;
  MagentoOrderLineNo?: Maybe<Scalars['Float']>;
  MagentoOrderLineQty?: Maybe<Scalars['Float']>;
  SelltoCustomerNo?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  PostingGroup?: Maybe<Scalars['String']>;
  ShipmentDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  UnitofMeasure?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UnitCostLCY?: Maybe<Scalars['Float']>;
  VAT?: Maybe<Scalars['Float']>;
  LineDiscount?: Maybe<Scalars['Float']>;
  AllowInvoiceDisc?: Maybe<Scalars['Boolean']>;
  GrossWeight?: Maybe<Scalars['Float']>;
  NetWeight?: Maybe<Scalars['Float']>;
  UnitsperParcel?: Maybe<Scalars['Float']>;
  UnitVolume?: Maybe<Scalars['Float']>;
  AppltoItemEntry?: Maybe<Scalars['Float']>;
  ItemShptEntryNo?: Maybe<Scalars['Float']>;
  ShortcutDimension1Code?: Maybe<Scalars['String']>;
  ShortcutDimension2Code?: Maybe<Scalars['String']>;
  CustomerPriceGroup?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  QtyShippedNotInvoiced?: Maybe<Scalars['Float']>;
  QuantityInvoiced?: Maybe<Scalars['Float']>;
  OrderNo?: Maybe<Scalars['String']>;
  OrderLineNo?: Maybe<Scalars['Float']>;
  BilltoCustomerNo?: Maybe<Scalars['String']>;
  PurchaseOrderNo?: Maybe<Scalars['String']>;
  PurchOrderLineNo?: Maybe<Scalars['Float']>;
  DropShipment?: Maybe<Scalars['Boolean']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATCalculationType?: Maybe<Scalars['String']>;
  VATCalculationTypeOPTION?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TransportMethod?: Maybe<Scalars['String']>;
  AttachedtoLineNo?: Maybe<Scalars['Float']>;
  ExitPoint?: Maybe<Scalars['String']>;
  Area?: Maybe<Scalars['String']>;
  TransactionSpecification?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  TaxGroupCode?: Maybe<Scalars['String']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  BlanketOrderNo?: Maybe<Scalars['String']>;
  BlanketOrderLineNo?: Maybe<Scalars['Float']>;
  VATBaseAmount?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  PostingDate?: Maybe<Scalars['DateTime']>;
  DimensionSetID?: Maybe<Scalars['Float']>;
  AuthorizedforCreditCard?: Maybe<Scalars['Boolean']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  JobContractEntryNo?: Maybe<Scalars['Boolean']>;
  VariantCode?: Maybe<Scalars['String']>;
  BinCode?: Maybe<Scalars['String']>;
  QtyperUnitofMeasure?: Maybe<Scalars['Float']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  QuantityBase?: Maybe<Scalars['Float']>;
  QtyInvoicedBase?: Maybe<Scalars['Float']>;
  FAPostingDate?: Maybe<Scalars['String']>;
  DepreciationBookCode?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  CrossReferenceNo?: Maybe<Scalars['String']>;
  UnitofMeasureCrossRef?: Maybe<Scalars['String']>;
  CrossReferenceType?: Maybe<Scalars['String']>;
  CrossReferenceTypeOPTION?: Maybe<Scalars['String']>;
  CrossReferenceTypeNo?: Maybe<Scalars['String']>;
  ItemCategoryCode?: Maybe<Scalars['String']>;
  DuplicateinDepreciationBook?: Maybe<Scalars['String']>;
  PurchasingCode?: Maybe<Scalars['String']>;
  ProductGroupCode?: Maybe<Scalars['String']>;
  RequestedDeliveryDate?: Maybe<Scalars['String']>;
  PromisedDeliveryDate?: Maybe<Scalars['String']>;
  ShippingTime?: Maybe<Scalars['String']>;
  OutboundWhseHandlingTime?: Maybe<Scalars['String']>;
  ReturnReasonCode?: Maybe<Scalars['String']>;
  CustomerDiscGroup?: Maybe<Scalars['String']>;
  AutoAccGroup?: Maybe<Scalars['String']>;
  CreatedOnOrderBy?: Maybe<Scalars['String']>;
  ItemReorderingPolicy?: Maybe<Scalars['String']>;
  ItemReorderingPolicyOPTION?: Maybe<Scalars['String']>;
  PeabAccountNo?: Maybe<Scalars['String']>;
  FeeType?: Maybe<Scalars['String']>;
  FeeTypeOPTION?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  ChargeTypeOPTION?: Maybe<Scalars['String']>;
  DepruntilFAPostingDate?: Maybe<Scalars['Boolean']>;
  UseDuplicationList?: Maybe<Scalars['Boolean']>;
  Nonstock?: Maybe<Scalars['Boolean']>;
  Correction?: Maybe<Scalars['Boolean']>;
  AllowLineDisc?: Maybe<Scalars['Boolean']>;
  PlannedDeliveryDate?: Maybe<Scalars['DateTime']>;
  PlannedShipmentDate?: Maybe<Scalars['DateTime']>;
  CreatedOnOrder?: Maybe<Scalars['DateTime']>;
  Originaldeliverydate?: Maybe<Scalars['DateTime']>;
  ApplfromItemEntry?: Maybe<Scalars['Float']>;
  ItemChargeBaseAmount?: Maybe<Scalars['Float']>;
  CalculatedPrice?: Maybe<Scalars['Float']>;
  DisposableStockBalance?: Maybe<Scalars['Float']>;
  Currentpricecalculation?: Maybe<Scalars['Float']>;
  FeeAttachedtoLineNo?: Maybe<Scalars['Float']>;
  CustomerOrderLineNo?: Maybe<Scalars['Float']>;
  CustomerOrderedQuantity?: Maybe<Scalars['Float']>;
  TotalQuantityShipped?: Maybe<Scalars['Float']>;
  CurrentOutstandingQuantity?: Maybe<Scalars['Float']>;
  TextconnectedtoLineNo?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSalespersonInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Commission?: Maybe<Scalars['Float']>;
  Image?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  SearchEMail?: Maybe<Scalars['String']>;
  EMail2?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  ActionTakenFilter?: Maybe<Scalars['Float']>;
  ActionTakenFilterOPTION?: Maybe<Scalars['String']>;
  SalesCycleFilter?: Maybe<Scalars['String']>;
  SalesCycleStageFilter?: Maybe<Scalars['Float']>;
  ProbabilityFilter?: Maybe<Scalars['Float']>;
  CompletedFilter?: Maybe<Scalars['Float']>;
  ContactFilter?: Maybe<Scalars['String']>;
  ContactCompanyFilter?: Maybe<Scalars['String']>;
  CampaignFilter?: Maybe<Scalars['String']>;
  EstimatedValueFilter?: Maybe<Scalars['Float']>;
  CalcdCurrentValueFilter?: Maybe<Scalars['Float']>;
  ChancesofSuccessFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilter?: Maybe<Scalars['Float']>;
  TaskStatusFilterOPTION?: Maybe<Scalars['String']>;
  ClosedTaskFilter?: Maybe<Scalars['Boolean']>;
  PriorityFilter?: Maybe<Scalars['Float']>;
  PriorityFilterOPTION?: Maybe<Scalars['String']>;
  TeamFilter?: Maybe<Scalars['String']>;
  CloseOpportunityFilter?: Maybe<Scalars['String']>;
  NextTaskDate?: Maybe<Scalars['String']>;
  NoofOpportunities?: Maybe<Scalars['Float']>;
  EstimatedValueLCY?: Maybe<Scalars['Float']>;
  CalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  NoofInteractions?: Maybe<Scalars['Float']>;
  CostLCY?: Maybe<Scalars['Float']>;
  DurationMin?: Maybe<Scalars['Float']>;
  AvgEstimatedValueLCY?: Maybe<Scalars['Float']>;
  AvgCalcdCurrentValueLCY?: Maybe<Scalars['Float']>;
  OpportunityEntryExists?: Maybe<Scalars['Boolean']>;
  TaskEntryExists?: Maybe<Scalars['Boolean']>;
  EdgeRespCenter?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateServiceItemGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreateServiceItem?: Maybe<Scalars['Boolean']>;
  DefaultContractDiscountPercent?: Maybe<Scalars['Float']>;
  DefaultServPriceGroupCode?: Maybe<Scalars['String']>;
  DefaultResponseTimeHours?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateShipmentMethodInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateShippingAgentInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  InternetAddress?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  ShpfyTrackingCompany?: Maybe<Scalars['Float']>;
  ShpfyTrackingCompanyOPTION?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['Float']>;
  DocumentTypeOPTION?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSignRequestInput = {
  discriminator?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  DocumentUri?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentEtag?: Maybe<Scalars['String']>;
  DocumentId?: Maybe<Scalars['String']>;
  SigningUserEmail?: Maybe<Scalars['String']>;
  SigningUserName?: Maybe<Scalars['String']>;
  PrintedName?: Maybe<Scalars['String']>;
  SignMethod?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['DateTime']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  TargetId?: Maybe<Scalars['String']>;
  TargetDiscriminator?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Base64WriteOnly?: Maybe<Scalars['String']>;
  MobileBankIdSSN?: Maybe<Scalars['String']>;
  MobileBankIdUserVisibleData?: Maybe<Scalars['String']>;
  orderRef?: Maybe<Scalars['String']>;
  autoStartToken?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  hintCode?: Maybe<Scalars['String']>;
  endUserIp?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSignatureInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSpecialEquipmentInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateStockkeepingUnitInput = {
  discriminator?: Maybe<Scalars['String']>;
  ItemNo?: Maybe<Scalars['String']>;
  VariantCode?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Description2?: Maybe<Scalars['String']>;
  AssemblyBOM?: Maybe<Scalars['Boolean']>;
  ShelfNo?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  LastDirectCost?: Maybe<Scalars['Float']>;
  VendorNo?: Maybe<Scalars['String']>;
  VendorItemNo?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  ReorderPoint?: Maybe<Scalars['Float']>;
  MaximumInventory?: Maybe<Scalars['Float']>;
  ReorderQuantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  DateFilter?: Maybe<Scalars['DateTime']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  Inventory?: Maybe<Scalars['Float']>;
  QtyonPurchOrder?: Maybe<Scalars['Float']>;
  QtyonSalesOrder?: Maybe<Scalars['Float']>;
  DropShipmentFilter?: Maybe<Scalars['Boolean']>;
  AssemblyPolicy?: Maybe<Scalars['Float']>;
  AssemblyPolicyOPTION?: Maybe<Scalars['String']>;
  QtyonAssemblyOrder?: Maybe<Scalars['Float']>;
  QtyonAsmComponent?: Maybe<Scalars['Float']>;
  QtyonJobOrder?: Maybe<Scalars['Float']>;
  TransferLevelCode?: Maybe<Scalars['Float']>;
  LotSize?: Maybe<Scalars['Float']>;
  DiscreteOrderQuantity?: Maybe<Scalars['Float']>;
  MinimumOrderQuantity?: Maybe<Scalars['Float']>;
  MaximumOrderQuantity?: Maybe<Scalars['Float']>;
  SafetyStockQuantity?: Maybe<Scalars['Float']>;
  OrderMultiple?: Maybe<Scalars['Float']>;
  SafetyLeadTime?: Maybe<Scalars['String']>;
  ComponentsatLocation?: Maybe<Scalars['String']>;
  FlushingMethod?: Maybe<Scalars['Float']>;
  FlushingMethodOPTION?: Maybe<Scalars['String']>;
  ReplenishmentSystem?: Maybe<Scalars['Float']>;
  ReplenishmentSystemOPTION?: Maybe<Scalars['String']>;
  ScheduledReceiptQty?: Maybe<Scalars['Float']>;
  ScheduledNeedQty?: Maybe<Scalars['Float']>;
  BinFilter?: Maybe<Scalars['String']>;
  TimeBucket?: Maybe<Scalars['String']>;
  ReorderingPolicy?: Maybe<Scalars['Float']>;
  ReorderingPolicyOPTION?: Maybe<Scalars['String']>;
  IncludeInventory?: Maybe<Scalars['Boolean']>;
  ManufacturingPolicy?: Maybe<Scalars['Float']>;
  ManufacturingPolicyOPTION?: Maybe<Scalars['String']>;
  ReschedulingPeriod?: Maybe<Scalars['String']>;
  LotAccumulationPeriod?: Maybe<Scalars['String']>;
  DampenerPeriod?: Maybe<Scalars['String']>;
  DampenerQuantity?: Maybe<Scalars['Float']>;
  OverflowLevel?: Maybe<Scalars['Float']>;
  TransferfromCode?: Maybe<Scalars['String']>;
  QtyinTransit?: Maybe<Scalars['Float']>;
  TransOrdReceiptQty?: Maybe<Scalars['Float']>;
  TransOrdShipmentQty?: Maybe<Scalars['Float']>;
  QtyonServiceOrder?: Maybe<Scalars['Float']>;
  SpecialEquipmentCode?: Maybe<Scalars['String']>;
  PutawayTemplateCode?: Maybe<Scalars['String']>;
  PutawayUnitofMeasureCode?: Maybe<Scalars['String']>;
  PhysInvtCountingPeriodCode?: Maybe<Scalars['String']>;
  LastCountingPeriodUpdate?: Maybe<Scalars['DateTime']>;
  LastPhysInvtDate?: Maybe<Scalars['DateTime']>;
  UseCrossDocking?: Maybe<Scalars['Boolean']>;
  NextCountingStartDate?: Maybe<Scalars['DateTime']>;
  NextCountingEndDate?: Maybe<Scalars['DateTime']>;
  RoutingNo?: Maybe<Scalars['String']>;
  ProductionBOMNo?: Maybe<Scalars['String']>;
  PlannedOrderReceiptQty?: Maybe<Scalars['Float']>;
  FPOrderReceiptQty?: Maybe<Scalars['Float']>;
  RelOrderReceiptQty?: Maybe<Scalars['Float']>;
  PlannedOrderReleaseQty?: Maybe<Scalars['Float']>;
  PurchReqReceiptQty?: Maybe<Scalars['Float']>;
  PurchReqReleaseQty?: Maybe<Scalars['Float']>;
  QtyonProdOrder?: Maybe<Scalars['Float']>;
  QtyonComponentLines?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTariffNumberInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SupplementaryUnits?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTaxGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTimeRegistrationInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  TimeSheetLineLineNo?: Maybe<Scalars['Float']>;
  TimeSheetDetailLineNo?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Hours?: Maybe<Scalars['Float']>;
  Email?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Scalars['String']>;
  KeyValue?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  StartDateFilter?: Maybe<Scalars['DateTime']>;
  EndDateFilter?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTimeSheetDetailInput = {
  discriminator?: Maybe<Scalars['String']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  TimeSheetLineNo?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  ResourceNo?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  ServiceOrderNo?: Maybe<Scalars['String']>;
  ServiceOrderLineNo?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PostedQuantity?: Maybe<Scalars['Float']>;
  AssemblyOrderNo?: Maybe<Scalars['String']>;
  AssemblyOrderLineNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  Posted?: Maybe<Scalars['Boolean']>;
  ExcludeFromTotals?: Maybe<Scalars['Boolean']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTimeSheetLineInput = {
  discriminator?: Maybe<Scalars['String']>;
  TimeSheetNo?: Maybe<Scalars['String']>;
  LineNo?: Maybe<Scalars['Float']>;
  TimeSheetStartingDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  JobNo?: Maybe<Scalars['String']>;
  JobTaskNo?: Maybe<Scalars['String']>;
  CauseofAbsenceCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WorkTypeCode?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ServiceOrderNo?: Maybe<Scalars['String']>;
  ServiceOrderLineNo?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  AssemblyOrderNo?: Maybe<Scalars['String']>;
  AssemblyOrderLineNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  StatusOPTION?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Posted?: Maybe<Scalars['Boolean']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  NotChargeable?: Maybe<Scalars['Boolean']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  Comment?: Maybe<Scalars['Boolean']>;
  CustomerName?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTransactionSpecificationInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTransactionTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTransportMethodInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateUnitOfMeasureInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  InternationalStandardCode?: Maybe<Scalars['String']>;
  Symbol?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  CoupledtoCRM?: Maybe<Scalars['Boolean']>;
  ExternalCode?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateUserFavoritesInput = {
  discriminator?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateUserInput = {
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  pushTokens?: Maybe<Array<Maybe<Scalars['String']>>>;
  Dyn365Id?: Maybe<Scalars['String']>;
  DynNAVId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  FilterProjectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  VerifiedIdentity?: Maybe<Scalars['Boolean']>;
  IsDemo?: Maybe<Scalars['Boolean']>;
  EmailOnExpiredObjects?: Maybe<Scalars['Boolean']>;
  PushNotificationOnShipments?: Maybe<Scalars['Boolean']>;
  IdentityVerifiedByUser?: Maybe<Scalars['String']>;
  PendingAccessRequest?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  DefaultSignature?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  NavUserName?: Maybe<Scalars['String']>;
  NavApiUserNameId?: Maybe<Scalars['String']>;
  NavApiPasswordId?: Maybe<Scalars['String']>;
  UserInvitationId?: Maybe<Scalars['String']>;
  UserAcceptedInvitationOn?: Maybe<Scalars['DateTime']>;
  ImpersonateUserId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateVatBusinessPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateVatProductPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateVendorBankAccountInput = {
  discriminator?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  TransitNo?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  BankClearingCode?: Maybe<Scalars['String']>;
  BankClearingStandard?: Maybe<Scalars['String']>;
  PEBPlusGiroNo?: Maybe<Scalars['String']>;
  PEBPaymentMethod?: Maybe<Scalars['Float']>;
  PEBPaymentMethodOPTION?: Maybe<Scalars['String']>;
  PEBGiroTypeCode?: Maybe<Scalars['String']>;
  PEBBankGiroNo?: Maybe<Scalars['String']>;
  PEBCodeforBankofSweden?: Maybe<Scalars['String']>;
  PEBAccountPaymentwithAdvis?: Maybe<Scalars['Boolean']>;
  PEBChargePaidby?: Maybe<Scalars['Float']>;
  PEBChargePaidbyOPTION?: Maybe<Scalars['String']>;
  PEBCategoryPurpose?: Maybe<Scalars['Float']>;
  PEBCategoryPurposeOPTION?: Maybe<Scalars['String']>;
  PEBStatus?: Maybe<Scalars['Float']>;
  PEBStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateVendorInput = {
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  OurAccountNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  FinChargeTermsCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  Priority?: Maybe<Scalars['Float']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  ApplicationMethod?: Maybe<Scalars['Float']>;
  ApplicationMethodOPTION?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  GLN?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  BlockPaymentTolerance?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PartnerType?: Maybe<Scalars['Float']>;
  PartnerTypeOPTION?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PreferredBankAccountCode?: Maybe<Scalars['String']>;
  CashFlowPaymentTermsCode?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  DocumentSendingProfile?: Maybe<Scalars['String']>;
  ValidateEUVatRegNo?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  CurrencyId?: Maybe<Scalars['String']>;
  PaymentTermsId?: Maybe<Scalars['String']>;
  PaymentMethodId?: Maybe<Scalars['String']>;
  NocontrolofExtDocNo?: Maybe<Scalars['Boolean']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  CurrencyFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  NetChangeLCY?: Maybe<Scalars['Float']>;
  PurchasesLCY?: Maybe<Scalars['Float']>;
  InvDiscountsLCY?: Maybe<Scalars['Float']>;
  PmtDiscountsLCY?: Maybe<Scalars['Float']>;
  BalanceDue?: Maybe<Scalars['Float']>;
  BalanceDueLCY?: Maybe<Scalars['Float']>;
  Payments?: Maybe<Scalars['Float']>;
  InvoiceAmounts?: Maybe<Scalars['Float']>;
  CrMemoAmounts?: Maybe<Scalars['Float']>;
  FinanceChargeMemoAmounts?: Maybe<Scalars['Float']>;
  PaymentsLCY?: Maybe<Scalars['Float']>;
  InvAmountsLCY?: Maybe<Scalars['Float']>;
  CrMemoAmountsLCY?: Maybe<Scalars['Float']>;
  FinChargeMemoAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['Float']>;
  CreditAmount?: Maybe<Scalars['Float']>;
  DebitAmountLCY?: Maybe<Scalars['Float']>;
  CreditAmountLCY?: Maybe<Scalars['Float']>;
  ReminderAmounts?: Maybe<Scalars['Float']>;
  ReminderAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrdersLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  PmtDiscToleranceLCY?: Maybe<Scalars['Float']>;
  PmtToleranceLCY?: Maybe<Scalars['Float']>;
  Refunds?: Maybe<Scalars['Float']>;
  RefundsLCY?: Maybe<Scalars['Float']>;
  OtherAmounts?: Maybe<Scalars['Float']>;
  OtherAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingInvoices?: Maybe<Scalars['Float']>;
  OutstandingInvoicesLCY?: Maybe<Scalars['Float']>;
  PaytoNoOfArchivedDoc?: Maybe<Scalars['Float']>;
  BuyfromNoOfArchivedDoc?: Maybe<Scalars['Float']>;
  NoofPstdReceipts?: Maybe<Scalars['Float']>;
  NoofPstdInvoices?: Maybe<Scalars['Float']>;
  NoofPstdReturnShipments?: Maybe<Scalars['Float']>;
  NoofPstdCreditMemos?: Maybe<Scalars['Float']>;
  PaytoNoofOrders?: Maybe<Scalars['Float']>;
  PaytoNoofInvoices?: Maybe<Scalars['Float']>;
  PaytoNoofReturnOrders?: Maybe<Scalars['Float']>;
  PaytoNoofCreditMemos?: Maybe<Scalars['Float']>;
  PaytoNoofPstdReceipts?: Maybe<Scalars['Float']>;
  PaytoNoofPstdInvoices?: Maybe<Scalars['Float']>;
  PaytoNoofPstdReturnS?: Maybe<Scalars['Float']>;
  PaytoNoofPstdCrMemos?: Maybe<Scalars['Float']>;
  NoofQuotes?: Maybe<Scalars['Float']>;
  NoofBlanketOrders?: Maybe<Scalars['Float']>;
  NoofOrders?: Maybe<Scalars['Float']>;
  NoofInvoices?: Maybe<Scalars['Float']>;
  NoofReturnOrders?: Maybe<Scalars['Float']>;
  NoofCreditMemos?: Maybe<Scalars['Float']>;
  NoofOrderAddresses?: Maybe<Scalars['Float']>;
  PaytoNoofQuotes?: Maybe<Scalars['Float']>;
  PaytoNoofBlanketOrders?: Maybe<Scalars['Float']>;
  NoofIncomingDocuments?: Maybe<Scalars['Float']>;
  InwardRegBalanceLCY?: Maybe<Scalars['Float']>;
  InwardRegNetChangeLCY?: Maybe<Scalars['Float']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateVendorPostingGroupInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  PayablesAccount?: Maybe<Scalars['String']>;
  ServiceChargeAcc?: Maybe<Scalars['String']>;
  PaymentDiscDebitAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingAccount?: Maybe<Scalars['String']>;
  DebitCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  CreditCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  DebitRoundingAccount?: Maybe<Scalars['String']>;
  CreditRoundingAccount?: Maybe<Scalars['String']>;
  PaymentDiscCreditAcc?: Maybe<Scalars['String']>;
  PaymentToleranceDebitAcc?: Maybe<Scalars['String']>;
  PaymentToleranceCreditAcc?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ViewAllAccountsonLookup?: Maybe<Scalars['Boolean']>;
  InwardRegBridgingDebt?: Maybe<Scalars['String']>;
  PEBInwardRegBridgingDebt?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateWarehouseClassInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateWebBookingInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DeliveryDate?: Maybe<Scalars['DateTime']>;
  DeliveryAddress?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  ContactPhone?: Maybe<Scalars['String']>;
  ContactEMail?: Maybe<Scalars['String']>;
  Handled?: Maybe<Scalars['Boolean']>;
  ContractNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateWebhookSubscriptionInput = {
  discriminator?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  SubscriptionId?: Maybe<Scalars['String']>;
  EventType?: Maybe<WebhookSubscriptionEventTypeEnum>;
  Enabled?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  LastUsed?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateWorkLogInput = {
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hours?: Maybe<Scalars['Float']>;
  SimplonaEmail?: Maybe<Scalars['String']>;
  NAVUser?: Maybe<Scalars['String']>;
  WorkDateTime?: Maybe<Scalars['DateTime']>;
  Debited?: Maybe<Scalars['Boolean']>;
  DoNotDebit?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateWorkTypeInput = {
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  /**
   * The GlobalId is mandatory.
   * Use an empty string to create a new record, or pass the GlobalId of an existing record to update
   * You can also pass a non-existing value to create a new record
   */
  GlobalId: Scalars['ID'];
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadAppCodesResponse = {
  __typename?: 'UploadAppCodesResponse';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  newCodesAdded?: Maybe<Scalars['Int']>;
  totalCodeCount?: Maybe<Scalars['Int']>;
};

export type UploadDomainImageInput = {
  Base64: Scalars['String'];
  Name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  discriminator?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  pushTokens?: Maybe<Array<Maybe<Scalars['String']>>>;
  Dyn365Id?: Maybe<Scalars['String']>;
  DynNAVId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  DomainIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  ProjectNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  FilterProjectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  VerifiedIdentity?: Maybe<Scalars['Boolean']>;
  IsDemo?: Maybe<Scalars['Boolean']>;
  EmailOnExpiredObjects?: Maybe<Scalars['Boolean']>;
  PushNotificationOnShipments?: Maybe<Scalars['Boolean']>;
  IdentityVerifiedByUser?: Maybe<Scalars['String']>;
  PendingAccessRequest?: Maybe<Scalars['String']>;
  CustomerName?: Maybe<Scalars['String']>;
  DefaultSignature?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  NavUserName?: Maybe<Scalars['String']>;
  NavApiUserNameId?: Maybe<Scalars['String']>;
  NavApiPasswordId?: Maybe<Scalars['String']>;
  UserInvitationId?: Maybe<Scalars['String']>;
  UserAcceptedInvitationOn?: Maybe<Scalars['DateTime']>;
  ImpersonateUserId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
  AppPushTokens?: Maybe<Array<Maybe<AppPushtoken>>>;
  favoriteProjects?: Maybe<Array<Maybe<CustomerProject>>>;
  domain?: Maybe<Domain>;
  company?: Maybe<Company>;
  DefaultResponsibilityCenterMatrix?: Maybe<Array<Maybe<ResponsibilityCenterMatrix>>>;
  NotificationsForProjects?: Maybe<Array<Maybe<ProjectPushNotifications>>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  count: Scalars['Int'];
  edges: Array<UserConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserConnectionEdge = {
  __typename?: 'UserConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: User;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<User>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserFavorites = {
  __typename?: 'UserFavorites';
  discriminator?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userGlobalId?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFavoritesConnection = {
  __typename?: 'UserFavoritesConnection';
  count: Scalars['Int'];
  edges: Array<UserFavoritesConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserFavoritesConnectionEdge = {
  __typename?: 'UserFavoritesConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: UserFavorites;
};

export type UserFavoritesDeleteResponse = {
  __typename?: 'UserFavoritesDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<UserFavorites>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserFavoritesManyResult = {
  __typename?: 'UserFavoritesManyResult';
  records?: Maybe<Array<Maybe<UserFavorites>>>;
  pageData?: Maybe<PageData>;
};

export type UserManyResult = {
  __typename?: 'UserManyResult';
  records?: Maybe<Array<Maybe<User>>>;
  pageData?: Maybe<PageData>;
};

export type UserOperationResponse = {
  __typename?: 'UserOperationResponse';
  ok?: Maybe<Scalars['Boolean']>;
};

export type VatBusinessPostingGroup = {
  __typename?: 'VatBusinessPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type VatBusinessPostingGroupConnection = {
  __typename?: 'VatBusinessPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<VatBusinessPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type VatBusinessPostingGroupConnectionEdge = {
  __typename?: 'VatBusinessPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: VatBusinessPostingGroup;
};

export type VatBusinessPostingGroupDeleteResponse = {
  __typename?: 'VatBusinessPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<VatBusinessPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VatBusinessPostingGroupManyResult = {
  __typename?: 'VatBusinessPostingGroupManyResult';
  records?: Maybe<Array<Maybe<VatBusinessPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type VatProductPostingGroup = {
  __typename?: 'VatProductPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type VatProductPostingGroupConnection = {
  __typename?: 'VatProductPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<VatProductPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type VatProductPostingGroupConnectionEdge = {
  __typename?: 'VatProductPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: VatProductPostingGroup;
};

export type VatProductPostingGroupDeleteResponse = {
  __typename?: 'VatProductPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<VatProductPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VatProductPostingGroupManyResult = {
  __typename?: 'VatProductPostingGroupManyResult';
  records?: Maybe<Array<Maybe<VatProductPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type Vendor = {
  __typename?: 'Vendor';
  discriminator?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SearchName?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  OurAccountNo?: Maybe<Scalars['String']>;
  TerritoryCode?: Maybe<Scalars['String']>;
  GlobalDimension1Code?: Maybe<Scalars['String']>;
  GlobalDimension2Code?: Maybe<Scalars['String']>;
  BudgetedAmount?: Maybe<Scalars['Float']>;
  VendorPostingGroup?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  StatisticsGroup?: Maybe<Scalars['Float']>;
  PaymentTermsCode?: Maybe<Scalars['String']>;
  FinChargeTermsCode?: Maybe<Scalars['String']>;
  PurchaserCode?: Maybe<Scalars['String']>;
  ShipmentMethodCode?: Maybe<Scalars['String']>;
  ShippingAgentCode?: Maybe<Scalars['String']>;
  InvoiceDiscCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  Blocked?: Maybe<Scalars['Float']>;
  BlockedOPTION?: Maybe<Scalars['String']>;
  PaytoVendorNo?: Maybe<Scalars['String']>;
  Priority?: Maybe<Scalars['Float']>;
  PaymentMethodCode?: Maybe<Scalars['String']>;
  LastModifiedDateTime?: Maybe<Scalars['DateTime']>;
  LastDateModified?: Maybe<Scalars['DateTime']>;
  ApplicationMethod?: Maybe<Scalars['Float']>;
  ApplicationMethodOPTION?: Maybe<Scalars['String']>;
  PricesIncludingVAT?: Maybe<Scalars['Boolean']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  VATRegistrationNo?: Maybe<Scalars['String']>;
  GenBusPostingGroup?: Maybe<Scalars['String']>;
  Picture?: Maybe<Scalars['String']>;
  GLN?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  NoSeries?: Maybe<Scalars['String']>;
  TaxAreaCode?: Maybe<Scalars['String']>;
  TaxLiable?: Maybe<Scalars['Boolean']>;
  VATBusPostingGroup?: Maybe<Scalars['String']>;
  BlockPaymentTolerance?: Maybe<Scalars['Boolean']>;
  ICPartnerCode?: Maybe<Scalars['String']>;
  Prepayment?: Maybe<Scalars['Float']>;
  PartnerType?: Maybe<Scalars['Float']>;
  PartnerTypeOPTION?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  CreditorNo?: Maybe<Scalars['String']>;
  PreferredBankAccountCode?: Maybe<Scalars['String']>;
  CashFlowPaymentTermsCode?: Maybe<Scalars['String']>;
  PrimaryContactNo?: Maybe<Scalars['String']>;
  ResponsibilityCenter?: Maybe<Scalars['String']>;
  LocationCode?: Maybe<Scalars['String']>;
  LeadTimeCalculation?: Maybe<Scalars['String']>;
  BaseCalendarCode?: Maybe<Scalars['String']>;
  DocumentSendingProfile?: Maybe<Scalars['String']>;
  ValidateEUVatRegNo?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['String']>;
  CurrencyId?: Maybe<Scalars['String']>;
  PaymentTermsId?: Maybe<Scalars['String']>;
  PaymentMethodId?: Maybe<Scalars['String']>;
  NocontrolofExtDocNo?: Maybe<Scalars['Boolean']>;
  ExternalDocumentNo?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  DocumentCode?: Maybe<Scalars['String']>;
  DateFilter?: Maybe<Scalars['String']>;
  GlobalDimension1Filter?: Maybe<Scalars['String']>;
  GlobalDimension2Filter?: Maybe<Scalars['String']>;
  CurrencyFilter?: Maybe<Scalars['String']>;
  Comment?: Maybe<Scalars['Boolean']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceLCY?: Maybe<Scalars['Float']>;
  NetChange?: Maybe<Scalars['Float']>;
  NetChangeLCY?: Maybe<Scalars['Float']>;
  PurchasesLCY?: Maybe<Scalars['Float']>;
  InvDiscountsLCY?: Maybe<Scalars['Float']>;
  PmtDiscountsLCY?: Maybe<Scalars['Float']>;
  BalanceDue?: Maybe<Scalars['Float']>;
  BalanceDueLCY?: Maybe<Scalars['Float']>;
  Payments?: Maybe<Scalars['Float']>;
  InvoiceAmounts?: Maybe<Scalars['Float']>;
  CrMemoAmounts?: Maybe<Scalars['Float']>;
  FinanceChargeMemoAmounts?: Maybe<Scalars['Float']>;
  PaymentsLCY?: Maybe<Scalars['Float']>;
  InvAmountsLCY?: Maybe<Scalars['Float']>;
  CrMemoAmountsLCY?: Maybe<Scalars['Float']>;
  FinChargeMemoAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrders?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoiced?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['Float']>;
  CreditAmount?: Maybe<Scalars['Float']>;
  DebitAmountLCY?: Maybe<Scalars['Float']>;
  CreditAmountLCY?: Maybe<Scalars['Float']>;
  ReminderAmounts?: Maybe<Scalars['Float']>;
  ReminderAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingOrdersLCY?: Maybe<Scalars['Float']>;
  AmtRcdNotInvoicedLCY?: Maybe<Scalars['Float']>;
  PmtDiscToleranceLCY?: Maybe<Scalars['Float']>;
  PmtToleranceLCY?: Maybe<Scalars['Float']>;
  Refunds?: Maybe<Scalars['Float']>;
  RefundsLCY?: Maybe<Scalars['Float']>;
  OtherAmounts?: Maybe<Scalars['Float']>;
  OtherAmountsLCY?: Maybe<Scalars['Float']>;
  OutstandingInvoices?: Maybe<Scalars['Float']>;
  OutstandingInvoicesLCY?: Maybe<Scalars['Float']>;
  PaytoNoOfArchivedDoc?: Maybe<Scalars['Float']>;
  BuyfromNoOfArchivedDoc?: Maybe<Scalars['Float']>;
  NoofPstdReceipts?: Maybe<Scalars['Float']>;
  NoofPstdInvoices?: Maybe<Scalars['Float']>;
  NoofPstdReturnShipments?: Maybe<Scalars['Float']>;
  NoofPstdCreditMemos?: Maybe<Scalars['Float']>;
  PaytoNoofOrders?: Maybe<Scalars['Float']>;
  PaytoNoofInvoices?: Maybe<Scalars['Float']>;
  PaytoNoofReturnOrders?: Maybe<Scalars['Float']>;
  PaytoNoofCreditMemos?: Maybe<Scalars['Float']>;
  PaytoNoofPstdReceipts?: Maybe<Scalars['Float']>;
  PaytoNoofPstdInvoices?: Maybe<Scalars['Float']>;
  PaytoNoofPstdReturnS?: Maybe<Scalars['Float']>;
  PaytoNoofPstdCrMemos?: Maybe<Scalars['Float']>;
  NoofQuotes?: Maybe<Scalars['Float']>;
  NoofBlanketOrders?: Maybe<Scalars['Float']>;
  NoofOrders?: Maybe<Scalars['Float']>;
  NoofInvoices?: Maybe<Scalars['Float']>;
  NoofReturnOrders?: Maybe<Scalars['Float']>;
  NoofCreditMemos?: Maybe<Scalars['Float']>;
  NoofOrderAddresses?: Maybe<Scalars['Float']>;
  PaytoNoofQuotes?: Maybe<Scalars['Float']>;
  PaytoNoofBlanketOrders?: Maybe<Scalars['Float']>;
  NoofIncomingDocuments?: Maybe<Scalars['Float']>;
  InwardRegBalanceLCY?: Maybe<Scalars['Float']>;
  InwardRegNetChangeLCY?: Maybe<Scalars['Float']>;
  StateCode?: Maybe<Scalars['Float']>;
  StatusCode?: Maybe<Scalars['Float']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorBankAccount = {
  __typename?: 'VendorBankAccount';
  discriminator?: Maybe<Scalars['String']>;
  VendorNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Name2?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  TelexNo?: Maybe<Scalars['String']>;
  BankBranchNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  TransitNo?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CountryRegionCode?: Maybe<Scalars['String']>;
  County?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  TelexAnswerBack?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  EMail?: Maybe<Scalars['String']>;
  HomePage?: Maybe<Scalars['String']>;
  IBAN?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  BankClearingCode?: Maybe<Scalars['String']>;
  BankClearingStandard?: Maybe<Scalars['String']>;
  PEBPlusGiroNo?: Maybe<Scalars['String']>;
  PEBPaymentMethod?: Maybe<Scalars['Float']>;
  PEBPaymentMethodOPTION?: Maybe<Scalars['String']>;
  PEBGiroTypeCode?: Maybe<Scalars['String']>;
  PEBBankGiroNo?: Maybe<Scalars['String']>;
  PEBCodeforBankofSweden?: Maybe<Scalars['String']>;
  PEBAccountPaymentwithAdvis?: Maybe<Scalars['Boolean']>;
  PEBChargePaidby?: Maybe<Scalars['Float']>;
  PEBChargePaidbyOPTION?: Maybe<Scalars['String']>;
  PEBCategoryPurpose?: Maybe<Scalars['Float']>;
  PEBCategoryPurposeOPTION?: Maybe<Scalars['String']>;
  PEBStatus?: Maybe<Scalars['Float']>;
  PEBStatusOPTION?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorBankAccountConnection = {
  __typename?: 'VendorBankAccountConnection';
  count: Scalars['Int'];
  edges: Array<VendorBankAccountConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type VendorBankAccountConnectionEdge = {
  __typename?: 'VendorBankAccountConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: VendorBankAccount;
};

export type VendorBankAccountDeleteResponse = {
  __typename?: 'VendorBankAccountDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<VendorBankAccount>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VendorBankAccountManyResult = {
  __typename?: 'VendorBankAccountManyResult';
  records?: Maybe<Array<Maybe<VendorBankAccount>>>;
  pageData?: Maybe<PageData>;
};

export type VendorConnection = {
  __typename?: 'VendorConnection';
  count: Scalars['Int'];
  edges: Array<VendorConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type VendorConnectionEdge = {
  __typename?: 'VendorConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Vendor;
};

export type VendorDeleteResponse = {
  __typename?: 'VendorDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<Vendor>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VendorManyResult = {
  __typename?: 'VendorManyResult';
  records?: Maybe<Array<Maybe<Vendor>>>;
  pageData?: Maybe<PageData>;
};

export type VendorPostingGroup = {
  __typename?: 'VendorPostingGroup';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  PayablesAccount?: Maybe<Scalars['String']>;
  ServiceChargeAcc?: Maybe<Scalars['String']>;
  PaymentDiscDebitAcc?: Maybe<Scalars['String']>;
  InvoiceRoundingAccount?: Maybe<Scalars['String']>;
  DebitCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  CreditCurrApplnRndgAcc?: Maybe<Scalars['String']>;
  DebitRoundingAccount?: Maybe<Scalars['String']>;
  CreditRoundingAccount?: Maybe<Scalars['String']>;
  PaymentDiscCreditAcc?: Maybe<Scalars['String']>;
  PaymentToleranceDebitAcc?: Maybe<Scalars['String']>;
  PaymentToleranceCreditAcc?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ViewAllAccountsonLookup?: Maybe<Scalars['Boolean']>;
  InwardRegBridgingDebt?: Maybe<Scalars['String']>;
  PEBInwardRegBridgingDebt?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorPostingGroupConnection = {
  __typename?: 'VendorPostingGroupConnection';
  count: Scalars['Int'];
  edges: Array<VendorPostingGroupConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type VendorPostingGroupConnectionEdge = {
  __typename?: 'VendorPostingGroupConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: VendorPostingGroup;
};

export type VendorPostingGroupDeleteResponse = {
  __typename?: 'VendorPostingGroupDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<VendorPostingGroup>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VendorPostingGroupManyResult = {
  __typename?: 'VendorPostingGroupManyResult';
  records?: Maybe<Array<Maybe<VendorPostingGroup>>>;
  pageData?: Maybe<PageData>;
};

export type WarehouseClass = {
  __typename?: 'WarehouseClass';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type WarehouseClassConnection = {
  __typename?: 'WarehouseClassConnection';
  count: Scalars['Int'];
  edges: Array<WarehouseClassConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type WarehouseClassConnectionEdge = {
  __typename?: 'WarehouseClassConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: WarehouseClass;
};

export type WarehouseClassDeleteResponse = {
  __typename?: 'WarehouseClassDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<WarehouseClass>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WarehouseClassManyResult = {
  __typename?: 'WarehouseClassManyResult';
  records?: Maybe<Array<Maybe<WarehouseClass>>>;
  pageData?: Maybe<PageData>;
};

export type WebBooking = {
  __typename?: 'WebBooking';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Float']>;
  TypeOPTION?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  DeliveryDate?: Maybe<Scalars['DateTime']>;
  DeliveryAddress?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  ContactPhone?: Maybe<Scalars['String']>;
  ContactEMail?: Maybe<Scalars['String']>;
  Handled?: Maybe<Scalars['Boolean']>;
  ContractNo?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type WebBookingConnection = {
  __typename?: 'WebBookingConnection';
  count: Scalars['Int'];
  edges: Array<WebBookingConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type WebBookingConnectionEdge = {
  __typename?: 'WebBookingConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: WebBooking;
};

export type WebBookingDeleteResponse = {
  __typename?: 'WebBookingDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<WebBooking>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WebBookingManyResult = {
  __typename?: 'WebBookingManyResult';
  records?: Maybe<Array<Maybe<WebBooking>>>;
  pageData?: Maybe<PageData>;
};

export type WebhookSubscription = {
  __typename?: 'WebhookSubscription';
  discriminator?: Maybe<Scalars['String']>;
  Resource?: Maybe<Scalars['String']>;
  SubscriptionId?: Maybe<Scalars['String']>;
  EventType?: Maybe<WebhookSubscriptionEventTypeEnum>;
  Enabled?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  LastUsed?: Maybe<Scalars['DateTime']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type WebhookSubscriptionConnection = {
  __typename?: 'WebhookSubscriptionConnection';
  count: Scalars['Int'];
  edges: Array<WebhookSubscriptionConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type WebhookSubscriptionConnectionEdge = {
  __typename?: 'WebhookSubscriptionConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: WebhookSubscription;
};

export type WebhookSubscriptionDeleteResponse = {
  __typename?: 'WebhookSubscriptionDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<WebhookSubscription>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum WebhookSubscriptionEventTypeEnum {
  Upsert = 'Upsert',
  Delete = 'Delete'
}

export type WebhookSubscriptionManyResult = {
  __typename?: 'WebhookSubscriptionManyResult';
  records?: Maybe<Array<Maybe<WebhookSubscription>>>;
  pageData?: Maybe<PageData>;
};

export type WorkLog = {
  __typename?: 'WorkLog';
  discriminator?: Maybe<Scalars['String']>;
  EntryNo?: Maybe<Scalars['Float']>;
  CustomerNo?: Maybe<Scalars['String']>;
  CustomerProject?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hours?: Maybe<Scalars['Float']>;
  SimplonaEmail?: Maybe<Scalars['String']>;
  NAVUser?: Maybe<Scalars['String']>;
  WorkDateTime?: Maybe<Scalars['DateTime']>;
  Debited?: Maybe<Scalars['Boolean']>;
  DoNotDebit?: Maybe<Scalars['Boolean']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkLogConnection = {
  __typename?: 'WorkLogConnection';
  count: Scalars['Int'];
  edges: Array<WorkLogConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type WorkLogConnectionEdge = {
  __typename?: 'WorkLogConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: WorkLog;
};

export type WorkLogDeleteResponse = {
  __typename?: 'WorkLogDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<WorkLog>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WorkLogManyResult = {
  __typename?: 'WorkLogManyResult';
  records?: Maybe<Array<Maybe<WorkLog>>>;
  pageData?: Maybe<PageData>;
};

export type WorkType = {
  __typename?: 'WorkType';
  discriminator?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  UnitofMeasureCode?: Maybe<Scalars['String']>;
  GenProdPostingGroup?: Maybe<Scalars['String']>;
  VATProdPostingGroup?: Maybe<Scalars['String']>;
  GroupCountReadOnly?: Maybe<Scalars['Float']>;
  TotalCountReadOnly?: Maybe<Scalars['Float']>;
  KeyValue?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  CompanyRegistrationNo?: Maybe<Scalars['String']>;
  CompanyRegistrationNoClean?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
  CustomerId?: Maybe<Scalars['String']>;
  ProjectId?: Maybe<Scalars['String']>;
  SignStatus?: Maybe<Scalars['String']>;
  SearchString?: Maybe<Scalars['String']>;
  SyncedToNav?: Maybe<Scalars['Boolean']>;
  SyncCount?: Maybe<Scalars['Float']>;
  SyncProblems?: Maybe<Scalars['String']>;
  SyncProblemOrgiginalEvent?: Maybe<Scalars['String']>;
  NavRecordId?: Maybe<Scalars['String']>;
  ConnectedToRecordId?: Maybe<Scalars['String']>;
  ModifiedOn?: Maybe<Scalars['DateTime']>;
  ModifiedOnSales?: Maybe<Scalars['String']>;
  ModifiedOnMagento?: Maybe<Scalars['DateTime']>;
  SentToMagento?: Maybe<Scalars['Boolean']>;
  SentToWebhook?: Maybe<Scalars['Boolean']>;
  SentToCoredination?: Maybe<Scalars['Boolean']>;
  SentToEquipmentLoop?: Maybe<Scalars['Boolean']>;
  ForceSave?: Maybe<Scalars['Boolean']>;
  DoNotRetrySaveToNAV?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['ID']>;
  InitiatingUserEmail?: Maybe<Scalars['String']>;
  InitiatingUserName?: Maybe<Scalars['String']>;
  LastModifiedBy?: Maybe<Scalars['String']>;
  SystemCreatedAt?: Maybe<Scalars['DateTime']>;
  SystemModifiedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkTypeConnection = {
  __typename?: 'WorkTypeConnection';
  count: Scalars['Int'];
  edges: Array<WorkTypeConnectionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type WorkTypeConnectionEdge = {
  __typename?: 'WorkTypeConnectionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: WorkType;
};

export type WorkTypeDeleteResponse = {
  __typename?: 'WorkTypeDeleteResponse';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  records?: Maybe<Array<Maybe<WorkType>>>;
  GlobalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WorkTypeManyResult = {
  __typename?: 'WorkTypeManyResult';
  records?: Maybe<Array<Maybe<WorkType>>>;
  pageData?: Maybe<PageData>;
};

export type CreateCompanyInvitationResponse = {
  __typename?: 'createCompanyInvitationResponse';
  invitation?: Maybe<Invitation>;
  link?: Maybe<Scalars['String']>;
};

export type CustomerDomainsLimitedInput = {
  SearchString?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
};

export type CustomerDomainsLimitedUpsertInput = {
  CustomerId?: Maybe<Scalars['String']>;
  InvoiceAddress?: Maybe<Scalars['String']>;
  InvoiceCity?: Maybe<Scalars['String']>;
  InvoiceNote?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  InvoicePostalCode?: Maybe<Scalars['String']>;
};

export type DomainsLimitedInput = {
  SearchString?: Maybe<Scalars['String']>;
  OnlyMine?: Maybe<Scalars['Boolean']>;
  IsDemoRequest?: Maybe<Scalars['Boolean']>;
  GlobalId?: Maybe<Scalars['String']>;
  ReservationsEnabled?: Maybe<Scalars['Boolean']>;
  ReturnReservationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type SetCustomerDomainImageInput = {
  Name: Scalars['String'];
  Base64: Scalars['String'];
  TargetGlobalId: Scalars['String'];
};

export type SyncUsersResponse = {
  __typename?: 'syncUsersResponse';
  count?: Maybe<Scalars['Int']>;
};

export type ToggleFavoriteProjectInput = {
  customerProjectNo: Scalars['String'];
};

export type ToggleSuplierResponse = {
  __typename?: 'toggleSuplierResponse';
  Error?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type ToggleSupplierInput = {
  CustomerId?: Maybe<Scalars['String']>;
  DomainId?: Maybe<Scalars['String']>;
};

export type DocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'discriminator' | 'TargetId' | 'DomainId' | 'Name' | 'GlobalId' | 'DocumentTypeOPTION' | 'DocumentSubTypeOPTION'>
);

export type DocumentWithLinkFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'discriminator' | 'TargetId' | 'DomainId' | 'Name' | 'GlobalId' | 'DocumentTypeOPTION' | 'DocumentSubTypeOPTION' | 'OriginalFileName'>
  & { image?: Maybe<(
    { __typename?: 'StoredFile' }
    & Pick<StoredFile, 'link'>
  )>, files?: Maybe<Array<Maybe<(
    { __typename?: 'StoredFile' }
    & Pick<StoredFile, 'name' | 'link'>
  )>>> }
);

export type CustomerProjectFragment = (
  { __typename?: 'CustomerProject' }
  & Pick<CustomerProject, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'CustomerNo' | 'Name' | 'GlobalId' | 'DomainId' | 'ProjectNo' | 'ProjectId' | 'YourReference' | 'CustomerId'>
);

export type InvitationFragment = (
  { __typename?: 'Invitation' }
  & Pick<Invitation, 'GlobalId' | 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'UserEmail' | 'Status' | 'Deactivated' | 'Role' | 'UserName' | 'Type' | 'CreatedOn' | 'IOSAppCode' | 'IOSAppLink' | 'AndroidAppLink' | 'CreatedByUserEmail' | 'CreateByUserName' | 'InvitationCode' | 'ProjectNos' | 'DomainId' | 'RegistrationNo' | 'CustomerName' | 'CustomerId' | 'IsOnAppStore' | 'IsOnPlayStore' | 'IOSAppCodeCount'>
  & { IOSAppCodes?: Maybe<Array<Maybe<(
    { __typename?: 'IOSAppCode' }
    & Pick<IosAppCode, 'code' | 'used'>
  )>>> }
);

export type InvitationLimitedFragment = (
  { __typename?: 'InvitationLimited' }
  & Pick<InvitationLimited, 'CompanyName' | 'RegistrationNo'>
);

export type CompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'Name' | 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'No' | 'Address' | 'CompanyImageEtag' | 'CompanyImageURL' | 'City' | 'PostCode' | 'RegistrationNo' | 'EMail' | 'KeyValue' | 'GlobalId' | 'DomainId' | 'CustomerId'>
);

export type DomainFragment = (
  { __typename?: 'Domain' }
  & Pick<Domain, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'Name' | 'DomainMainOrientation' | 'ReservationsEnabled' | 'ReturnReservationsEnabled' | 'Description' | 'EnableObjectsLimited' | 'GlobalId' | 'ParentDomainId' | 'VisibleInSafetyRespect' | 'IsDemo' | 'ImageURL' | 'AlternativeName' | 'FlagImageUrl'>
);

export type DomainLimitedFragment = (
  { __typename?: 'DomainLimited' }
  & Pick<DomainLimited, 'GlobalId' | 'Name' | 'ImageURL' | 'AlternativeName' | 'FlagImageUrl' | 'ReservationsEnabled' | 'ReturnReservationsEnabled'>
);

export type DomainIntegrationFragment = (
  { __typename?: 'DomainIntegration' }
  & Pick<DomainIntegration, 'discriminator' | 'SyncedToNav' | 'SyncProblems' | 'Name' | 'Url' | 'Type' | 'AuthType' | 'DisableOrderPolling' | 'PreviousMagentoOrderId' | 'PreviousDekraPollingDateTime' | 'Company' | 'DomainId' | 'commonAuthority' | 'resource' | 'GlobalId' | 'Active' | 'grant_type' | 'SchemaVersion' | 'Online' | 'LatestHeartBeat' | 'FirstHeartBeat' | 'HeartBeartIntervalMinutes' | 'NoOfReqsInOutboundQueue' | 'NoOfReqsInInboundQueue' | 'NoOfUnprocessableItems' | 'HeartbeatMonitoringEnabled'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'name' | 'email' | 'active' | 'PendingAccessRequest' | 'role' | 'CustomerId' | 'CustomerName' | 'DomainId' | 'ProjectNos' | 'EmailOnExpiredObjects' | 'PushNotificationOnShipments' | 'GlobalId'>
);

export type MeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'name' | 'email' | 'active' | 'PendingAccessRequest' | 'role' | 'CustomerId' | 'CustomerName' | 'UserAcceptedInvitationOn' | 'UserInvitationId' | 'DomainId' | 'ProjectNos' | 'DomainIds' | 'EmailOnExpiredObjects' | 'ImpersonateUserId' | 'PushNotificationOnShipments'>
  & { favoriteProjects?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerProject' }
    & Pick<CustomerProject, 'Deactivated' | 'ProjectNo' | 'ProjectId' | 'CustomerNo' | 'GlobalId'>
  )>>>, domain?: Maybe<(
    { __typename?: 'Domain' }
    & Pick<Domain, 'Name' | 'DomainMainOrientation'>
  )> }
);

export type RentalPostedCollHeaderFragment = (
  { __typename?: 'RentalPostedCollHeader' }
  & Pick<RentalPostedCollHeader, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'No' | 'GlobalId' | 'DomainId' | 'CustomerProject' | 'PostingDate' | 'ShiptoContact'>
);

export type RentalPostedShptHeaderFragment = (
  { __typename?: 'RentalPostedShptHeader' }
  & Pick<RentalPostedShptHeader, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'CustomerProject' | 'No' | 'PostingDate' | 'ShiptoContact' | 'GlobalId' | 'DomainId'>
);

export type FunctionTestHeaderFragment = (
  { __typename?: 'FunctionTestHeader' }
  & Pick<FunctionTestHeader, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GlobalId' | 'DomainId' | 'DocumentNo' | 'Status' | 'ResponsibilityCenter' | 'StatusOPTION' | 'TypeOfWash' | 'ObjectNo' | 'ObjectDescription'>
);

export type FunctionTestLineFragment = (
  { __typename?: 'FunctionTestLine' }
  & Pick<FunctionTestLine, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GlobalId' | 'LineNo' | 'DocumentNo' | 'Date' | 'TestIssue' | 'AdditionalInstructions' | 'CurrentStatus' | 'Mandatory' | 'ToCorrect'>
);

export type CustomerDomainFragment = (
  { __typename?: 'CustomerDomain' }
  & Pick<CustomerDomain, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GlobalId' | 'RegistrationNo' | 'Name' | 'DomainIds' | 'AdminUserGlobalId' | 'EmailDomain' | 'VerifiedOn' | 'VerifiedByUserName' | 'VerifiedByUserEmail' | 'ImageURL' | 'TrialStartedOn' | 'TrialEndsOn' | 'TrialLengthDays' | 'TrailActive' | 'InvoiceAddress' | 'InvoicePostalCode' | 'InvoiceCity' | 'InvoiceNote' | 'InvoiceEmail'>
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'name' | 'email' | 'PendingAccessRequest' | 'role' | 'CustomerId' | 'CustomerName' | 'DomainId' | 'ProjectNos' | 'EmailOnExpiredObjects' | 'PushNotificationOnShipments' | 'GlobalId'>
  )>>> }
);

export type ReservationRequestHeaderFragment = (
  { __typename?: 'ReservationRequestHeader' }
  & Pick<ReservationRequestHeader, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GroupGUID' | 'Header' | 'TypeOfRequest' | 'Confirmed' | 'User' | 'UserName' | 'ProjectId' | 'RequestDate' | 'DomainId' | 'DeliveryDate' | 'Address' | 'City' | 'ExpectedReturnDate' | 'Comment' | 'DomainName'>
);

export type ReservationRequestLineFragment = (
  { __typename?: 'ReservationRequestLine' }
  & Pick<ReservationRequestLine, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GroupGUID' | 'Header' | 'TypeOfRequest' | 'Confirmed' | 'User' | 'ProjectId' | 'RequestDate' | 'UserName' | 'Type' | 'No' | 'Description' | 'Quantity' | 'Comment'>
);

export type ReturnRequestHeaderFragment = (
  { __typename?: 'ReturnRequestHeader' }
  & Pick<ReturnRequestHeader, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GroupGUID' | 'Header' | 'TypeOfRequest' | 'Confirmed' | 'User' | 'UserName' | 'DomainName' | 'ProjectNo' | 'PickUpDate' | 'Address' | 'City' | 'Comment'>
);

export type ReturnRequestLineFragment = (
  { __typename?: 'ReturnRequestLine' }
  & Pick<ReturnRequestLine, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'GroupGUID' | 'LineGUID' | 'Header' | 'TypeOfRequest' | 'Confirmed' | 'Executed' | 'RequestDate' | 'User' | 'UserName' | 'No' | 'Description' | 'Quantity' | 'Comment'>
);

export type ObjectFamilyFragment = (
  { __typename?: 'ObjectFamily' }
  & Pick<ObjectFamily, 'discriminator' | 'SyncedToNav' | 'SyncProblems' | 'GlobalId' | 'DomainId' | 'Family' | 'ImgUrlSmall' | 'ImgUrlLarge'>
);

export type ObjectFragment = (
  { __typename?: 'Object' }
  & Pick<Object, 'discriminator' | 'GlobalId' | 'DomainId' | 'CustomerId' | 'ObjectNo' | 'ObjectType' | 'Description' | 'ManufacturerCode' | 'ManufacturerModelCode' | 'Length' | 'Width' | 'Height' | 'LengthMeasure' | 'WidthMeasure' | 'HeightMeasure' | 'GPSCoordinates' | 'PSI'>
);

export type ObjectTypeFragment = (
  { __typename?: 'ObjectType' }
  & Pick<ObjectType, 'discriminator' | 'SyncedToNav' | 'SyncProblems' | 'GlobalId' | 'DomainId' | 'CustomerId' | 'ProjectId' | 'NotAvailableForReservation' | 'ObjectType' | 'Description' | 'PictureURL' | 'Description3'>
);

export type ObjectGroupFragment = (
  { __typename?: 'ObjectGroup' }
  & Pick<ObjectGroup, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'ObjectGroup' | 'Description' | 'ImgUrlSmall'>
);

export type RentalPostedCollLineFragment = (
  { __typename?: 'RentalPostedCollLine' }
  & Pick<RentalPostedCollLine, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'DocumentNo' | 'No' | 'Type' | 'ProjectId' | 'TypeOPTION' | 'Description' | 'Quantity' | 'ShipmentDate' | 'OnRentDate' | 'OffRentDate' | 'GlobalId' | 'DomainId'>
);

export type RentalPostedShptLineFragment = (
  { __typename?: 'RentalPostedShptLine' }
  & Pick<RentalPostedShptLine, 'SyncedToNav' | 'SyncProblems' | 'discriminator' | 'DocumentNo' | 'No' | 'Type' | 'TypeOPTION' | 'Description' | 'ProjectId' | 'Quantity' | 'OnRentDate' | 'GlobalId' | 'DomainId' | 'ShipmentDate'>
);

export type RentalLineFragment = (
  { __typename?: 'RentalLine' }
  & Pick<RentalLine, 'discriminator' | 'SyncedToNav' | 'SyncProblems' | 'Quantity' | 'Tags' | 'ActualGlobalId' | 'GroupOnNumber' | 'ReturnReservationActive' | 'OnRentDate' | 'CustomerProject' | 'Description' | 'DomainId' | 'InvoicedToDate' | 'PriceTermCode' | 'BaseCalendarCode' | 'Type' | 'GlobalId' | 'TypeOPTION' | 'KITMotherLineNo' | 'ContractNo' | 'RemainingQty' | 'DayNetPriceEdge' | 'PeriodLineAmountEdge' | 'NextInvoicePeriodStart' | 'RentalDays' | 'Vaccation' | 'RentalSale' | 'UserExpectedReturnDate' | 'LineNo' | 'FreeDays' | 'OffRentDateFlow' | 'No' | 'ImageUrl' | 'CustomerNo' | 'ProjectId'>
);

export type InvoiceHeaderFragment = (
  { __typename?: 'InvoiceHeader' }
  & Pick<InvoiceHeader, 'discriminator' | 'SyncedToNav' | 'SyncProblems' | 'GlobalId' | 'PostingDescription' | 'Closed' | 'EQMCombineCustomerProj' | 'No' | 'BilltoCustomerNo' | 'BilltoName' | 'DomainId' | 'ProjectId' | 'OrderDate' | 'Amount' | 'AmountIncludingVAT' | 'DueDate'>
);

export type SendOnRentListByEmailMutationVariables = Exact<{
  record: SendOnRentEmailInput;
}>;


export type SendOnRentListByEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendOnRentListByEmail?: Maybe<(
    { __typename?: 'SendOnRentEmailResponse' }
    & Pick<SendOnRentEmailResponse, 'ok' | 'error'>
  )> }
);

export type UpsertObjectTypeMutationVariables = Exact<{
  record: UpdateObjectTypeInput;
}>;


export type UpsertObjectTypeMutation = (
  { __typename?: 'Mutation' }
  & { upsertObjectType?: Maybe<(
    { __typename?: 'ObjectType' }
    & ObjectTypeFragment
  )> }
);

export type ChangeObjectTypeImageMutationVariables = Exact<{
  record: ChangeObjectTypeImagePayload;
}>;


export type ChangeObjectTypeImageMutation = (
  { __typename?: 'Mutation' }
  & { changeObjectTypeImage?: Maybe<(
    { __typename?: 'ObjectType' }
    & ObjectTypeFragment
  )> }
);

export type ChangeObjectGroupImageMutationVariables = Exact<{
  record: ChangeObjectTypeImagePayload;
}>;


export type ChangeObjectGroupImageMutation = (
  { __typename?: 'Mutation' }
  & { changeObjectGroupImage?: Maybe<(
    { __typename?: 'ObjectGroup' }
    & ObjectGroupFragment
  )> }
);

export type ChangeObjectFamilyImageMutationVariables = Exact<{
  record: ChangeObjectTypeImagePayload;
}>;


export type ChangeObjectFamilyImageMutation = (
  { __typename?: 'Mutation' }
  & { changeObjectFamilyImage?: Maybe<(
    { __typename?: 'ObjectFamily' }
    & ObjectFamilyFragment
  )> }
);

export type ImpersonateUserMutationVariables = Exact<{
  Email: Scalars['String'];
}>;


export type ImpersonateUserMutation = (
  { __typename?: 'Mutation' }
  & { impersonateUser?: Maybe<(
    { __typename?: 'User' }
    & MeFragment
  )> }
);

export type ClearMyImpersonationMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearMyImpersonationMutation = (
  { __typename?: 'Mutation' }
  & { clearMyImpersonation?: Maybe<(
    { __typename?: 'User' }
    & MeFragment
  )> }
);

export type ClearInvitationCodesMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ClearInvitationCodesMutation = (
  { __typename?: 'Mutation' }
  & { clearInvitationCodes?: Maybe<(
    { __typename?: 'RemoveCodesResponse' }
    & Pick<RemoveCodesResponse, 'codesRemoved'>
  )> }
);

export type RemoveUserMutationVariables = Exact<{
  filter: FilterUserInput;
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & { removeUser?: Maybe<(
    { __typename?: 'UserDeleteResponse' }
    & Pick<UserDeleteResponse, 'ok'>
  )> }
);

export type UploadIosAppCodeFileMutationVariables = Exact<{
  file: Scalars['String'];
  InvitationCode: Scalars['String'];
}>;


export type UploadIosAppCodeFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadIOSAppCodeFile?: Maybe<(
    { __typename?: 'UploadAppCodesResponse' }
    & Pick<UploadAppCodesResponse, 'success' | 'newCodesAdded' | 'totalCodeCount' | 'error'>
  )> }
);

export type SetFlagImageMutationVariables = Exact<{
  record: UploadDomainImageInput;
  DomainId: Scalars['String'];
}>;


export type SetFlagImageMutation = (
  { __typename?: 'Mutation' }
  & { setFlagImage?: Maybe<(
    { __typename?: 'Domain' }
    & DomainFragment
  )> }
);

export type RemoveDomainIntegrationMutationVariables = Exact<{
  filter: FilterDomainIntegrationInput;
}>;


export type RemoveDomainIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { removeDomainIntegration?: Maybe<(
    { __typename?: 'DomainIntegrationDeleteResponse' }
    & Pick<DomainIntegrationDeleteResponse, 'ok' | 'deletedCount' | 'GlobalIds'>
  )> }
);

export type UpsertDomainIntegrationMutationVariables = Exact<{
  record?: Maybe<UpdateDomainIntegrationInput>;
}>;


export type UpsertDomainIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { upsertDomainIntegration?: Maybe<(
    { __typename?: 'DomainIntegration' }
    & DomainIntegrationFragment
  )> }
);

export type SetDomainImageMutationVariables = Exact<{
  record: UploadDomainImageInput;
  DomainId: Scalars['String'];
}>;


export type SetDomainImageMutation = (
  { __typename?: 'Mutation' }
  & { setDomainImage?: Maybe<(
    { __typename?: 'Domain' }
    & DomainFragment
  )> }
);

export type RemoveUserManyMutationVariables = Exact<{
  filter: FilterUserInput;
}>;


export type RemoveUserManyMutation = (
  { __typename?: 'Mutation' }
  & { removeUserMany?: Maybe<(
    { __typename?: 'UserDeleteResponse' }
    & Pick<UserDeleteResponse, 'ok' | 'deletedCount' | 'GlobalIds'>
  )> }
);

export type CreateServiceAccountMutationVariables = Exact<{
  record?: Maybe<CreateSerciceAccountInput>;
}>;


export type CreateServiceAccountMutation = (
  { __typename?: 'Mutation' }
  & { createServiceAccount?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type UpsertUserMutationVariables = Exact<{
  record?: Maybe<UpdateUserInput>;
}>;


export type UpsertUserMutation = (
  { __typename?: 'Mutation' }
  & { upsertUser?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type CreateCompanyInvitationMutationVariables = Exact<{
  record?: Maybe<CompanyInvitationInput>;
}>;


export type CreateCompanyInvitationMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationFragment
  )> }
);

export type CreateDomainInvitationMutationVariables = Exact<{
  record?: Maybe<DomainInvitationInput>;
}>;


export type CreateDomainInvitationMutation = (
  { __typename?: 'Mutation' }
  & { createDomainInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationFragment
  )> }
);

export type AcceptCompanyInvitationMutationVariables = Exact<{
  Code: Scalars['String'];
}>;


export type AcceptCompanyInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptCompanyInvitation?: Maybe<(
    { __typename?: 'AcceptInivitationResponse' }
    & Pick<AcceptInivitationResponse, 'status' | 'CustomerId' | 'CustomerName' | 'UserAcceptedInvitationOn' | 'UserInvitationId' | 'role'>
  )> }
);

export type AcceptDomainInvitationMutationVariables = Exact<{
  Code: Scalars['String'];
}>;


export type AcceptDomainInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptDomainInvitation?: Maybe<(
    { __typename?: 'AcceptInivitationResponse' }
    & Pick<AcceptInivitationResponse, 'status' | 'DomainId' | 'CustomerName' | 'UserAcceptedInvitationOn' | 'UserInvitationId' | 'role' | 'IOSAppLink' | 'AndroidAppLink' | 'IOSAppCode'>
  )> }
);

export type UpsertCustomerDomainMutationVariables = Exact<{
  record?: Maybe<UpdateCustomerDomainInput>;
}>;


export type UpsertCustomerDomainMutation = (
  { __typename?: 'Mutation' }
  & { upsertCustomerDomain?: Maybe<(
    { __typename?: 'CustomerDomain' }
    & CustomerDomainFragment
  )> }
);

export type InitializeDomainMutationVariables = Exact<{
  record?: Maybe<UpdateDomainInput>;
}>;


export type InitializeDomainMutation = (
  { __typename?: 'Mutation' }
  & { initializeDomain?: Maybe<(
    { __typename?: 'Domain' }
    & DomainFragment
  )> }
);

export type UpsertDomainMutationVariables = Exact<{
  record?: Maybe<UpdateDomainInput>;
}>;


export type UpsertDomainMutation = (
  { __typename?: 'Mutation' }
  & { upsertDomain?: Maybe<(
    { __typename?: 'Domain' }
    & DomainFragment
  )> }
);

export type RemoveDomainManyMutationVariables = Exact<{
  filter?: Maybe<FilterDomainInput>;
}>;


export type RemoveDomainManyMutation = (
  { __typename?: 'Mutation' }
  & { removeDomainMany?: Maybe<(
    { __typename?: 'DomainDeleteResponse' }
    & Pick<DomainDeleteResponse, 'ok' | 'deletedCount' | 'GlobalIds'>
  )> }
);

export type ToggleSupplierMutationVariables = Exact<{
  record?: Maybe<ToggleSupplierInput>;
}>;


export type ToggleSupplierMutation = (
  { __typename?: 'Mutation' }
  & { toggleSupplier: (
    { __typename?: 'toggleSuplierResponse' }
    & Pick<ToggleSuplierResponse, 'Error' | 'Status'>
  ) }
);

export type RemoveCustomerDomainManyMutationVariables = Exact<{
  filter?: Maybe<FilterCustomerDomainInput>;
}>;


export type RemoveCustomerDomainManyMutation = (
  { __typename?: 'Mutation' }
  & { removeCustomerDomainMany?: Maybe<(
    { __typename?: 'CustomerDomainDeleteResponse' }
    & Pick<CustomerDomainDeleteResponse, 'ok' | 'deletedCount' | 'GlobalIds'>
  )> }
);

export type RemoveInvitationsManyMutationVariables = Exact<{
  filter?: Maybe<FilterInvitationInput>;
}>;


export type RemoveInvitationsManyMutation = (
  { __typename?: 'Mutation' }
  & { removeInvitationsMany?: Maybe<(
    { __typename?: 'InvitationDeleteResponse' }
    & Pick<InvitationDeleteResponse, 'ok' | 'deletedCount' | 'GlobalIds'>
  )> }
);

export type RemoveInvitationMutationVariables = Exact<{
  filter?: Maybe<FilterInvitationInput>;
}>;


export type RemoveInvitationMutation = (
  { __typename?: 'Mutation' }
  & { removeInvitation?: Maybe<(
    { __typename?: 'InvitationDeleteResponse' }
    & Pick<InvitationDeleteResponse, 'ok' | 'deletedCount' | 'GlobalIds'>
  )> }
);

export type UpdateDomainInvitationAppLinksMutationVariables = Exact<{
  record: InvitationUpdateInput;
}>;


export type UpdateDomainInvitationAppLinksMutation = (
  { __typename?: 'Mutation' }
  & { updateDomainInvitationAppLinks?: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationFragment
  )> }
);

export type DeactivateInvitationMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type DeactivateInvitationMutation = (
  { __typename?: 'Mutation' }
  & { deactivateInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationFragment
  )> }
);

export type ToggleFavoriteProjectMutationVariables = Exact<{
  record?: Maybe<ToggleFavoriteProjectInput>;
}>;


export type ToggleFavoriteProjectMutation = (
  { __typename?: 'Mutation' }
  & { toggleFavoriteProject?: Maybe<(
    { __typename?: 'User' }
    & MeFragment
  )> }
);

export type UpsertRentalLineMutationVariables = Exact<{
  record: UpdateRentalLineInput;
}>;


export type UpsertRentalLineMutation = (
  { __typename?: 'Mutation' }
  & { upsertRentalLine?: Maybe<(
    { __typename?: 'RentalLine' }
    & RentalLineFragment
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & MeFragment
  )> }
);

export type DocumentsPageQueryVariables = Exact<{
  filter?: Maybe<FilterDocumentInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDocumentInput>;
}>;


export type DocumentsPageQuery = (
  { __typename?: 'Query' }
  & { documentsPage?: Maybe<(
    { __typename?: 'DocumentManyResult' }
    & { pageData?: Maybe<(
      { __typename?: 'PageData' }
      & Pick<PageData, 'hasNextPage' | 'hasPreviousPage' | 'totalCount' | 'pageCount'>
    )>, records?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & DocumentFragment
    )>>> }
  )> }
);

export type DocumentQueryVariables = Exact<{
  filter?: Maybe<FilterDocumentInput>;
}>;


export type DocumentQuery = (
  { __typename?: 'Query' }
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & DocumentWithLinkFragment
  )> }
);

export type DocumentsManyQueryVariables = Exact<{
  filter?: Maybe<FilterDocumentInput>;
  sort?: Maybe<SortDocumentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type DocumentsManyQuery = (
  { __typename?: 'Query' }
  & { documentsMany?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & DocumentWithLinkFragment
  )>>> }
);

export type RentalLineQueryVariables = Exact<{
  filter?: Maybe<FilterRentalLineInput>;
}>;


export type RentalLineQuery = (
  { __typename?: 'Query' }
  & { rentalLine?: Maybe<(
    { __typename?: 'RentalLine' }
    & { customerProject?: Maybe<(
      { __typename?: 'CustomerProject' }
      & Pick<CustomerProject, 'YourReference' | 'Name' | 'ProjectNo'>
    )> }
    & RentalLineFragment
  )> }
);

export type UsersPageQueryVariables = Exact<{
  filter?: Maybe<FilterUserInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserInput>;
  aggregate?: Maybe<AggregateUserInput>;
}>;


export type UsersPageQuery = (
  { __typename?: 'Query' }
  & { usersPage?: Maybe<(
    { __typename?: 'UserManyResult' }
    & { pageData?: Maybe<(
      { __typename?: 'PageData' }
      & Pick<PageData, 'hasNextPage' | 'hasPreviousPage' | 'totalCount' | 'pageCount'>
    )>, records?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )>>> }
  )> }
);

export type UserQueryVariables = Exact<{
  filter?: Maybe<FilterUserInput>;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type UserssManyQueryVariables = Exact<{
  filter?: Maybe<FilterUserInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortUserInput>;
}>;


export type UserssManyQuery = (
  { __typename?: 'Query' }
  & { usersMany?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )>>> }
);

export type DomainsManyQueryVariables = Exact<{
  filter?: Maybe<FilterDomainInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainInput>;
}>;


export type DomainsManyQuery = (
  { __typename?: 'Query' }
  & { domainsMany?: Maybe<Array<Maybe<(
    { __typename?: 'Domain' }
    & DomainFragment
  )>>> }
);

export type DomainQueryVariables = Exact<{
  filter?: Maybe<FilterDomainInput>;
}>;


export type DomainQuery = (
  { __typename?: 'Query' }
  & { domain?: Maybe<(
    { __typename?: 'Domain' }
    & DomainFragment
  )> }
);

export type DomainsLimitedQueryVariables = Exact<{
  filter?: Maybe<DomainsLimitedInput>;
}>;


export type DomainsLimitedQuery = (
  { __typename?: 'Query' }
  & { domainsLimited?: Maybe<Array<Maybe<(
    { __typename?: 'DomainLimited' }
    & Pick<DomainLimited, 'GlobalId' | 'Name' | 'ImageURL' | 'AlternativeName' | 'FlagImageUrl' | 'ReservationsEnabled' | 'ReturnReservationsEnabled'>
  )>>> }
);

export type DomainsPageQueryVariables = Exact<{
  filter?: Maybe<FilterDomainInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainInput>;
  aggregate?: Maybe<AggregateDomainInput>;
}>;


export type DomainsPageQuery = (
  { __typename?: 'Query' }
  & { domainsPage?: Maybe<(
    { __typename?: 'DomainManyResult' }
    & { pageData?: Maybe<(
      { __typename?: 'PageData' }
      & Pick<PageData, 'hasNextPage' | 'hasPreviousPage' | 'totalCount' | 'pageCount'>
    )>, records?: Maybe<Array<Maybe<(
      { __typename?: 'Domain' }
      & DomainFragment
    )>>> }
  )> }
);

export type DomainIntegrationsManyQueryVariables = Exact<{
  filter?: Maybe<FilterDomainIntegrationInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortDomainIntegrationInput>;
}>;


export type DomainIntegrationsManyQuery = (
  { __typename?: 'Query' }
  & { domainIntegrationsMany?: Maybe<Array<Maybe<(
    { __typename?: 'DomainIntegration' }
    & DomainIntegrationFragment
  )>>> }
);

export type DomainIntegrationQueryVariables = Exact<{
  filter?: Maybe<FilterDomainIntegrationInput>;
}>;


export type DomainIntegrationQuery = (
  { __typename?: 'Query' }
  & { domainIntegration?: Maybe<(
    { __typename?: 'DomainIntegration' }
    & DomainIntegrationFragment
  )> }
);

export type CustomerDomainsPageQueryVariables = Exact<{
  filter?: Maybe<FilterCustomerDomainInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerDomainInput>;
  aggregate?: Maybe<AggregateCustomerDomainInput>;
}>;


export type CustomerDomainsPageQuery = (
  { __typename?: 'Query' }
  & { customerDomainsPage?: Maybe<(
    { __typename?: 'CustomerDomainManyResult' }
    & { pageData?: Maybe<(
      { __typename?: 'PageData' }
      & Pick<PageData, 'hasNextPage' | 'hasPreviousPage' | 'totalCount' | 'pageCount'>
    )>, records?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerDomain' }
      & CustomerDomainFragment
    )>>> }
  )> }
);

export type CustomerDomainQueryVariables = Exact<{
  filter?: Maybe<FilterCustomerDomainInput>;
}>;


export type CustomerDomainQuery = (
  { __typename?: 'Query' }
  & { customerDomain?: Maybe<(
    { __typename?: 'CustomerDomain' }
    & CustomerDomainFragment
  )> }
);

export type CompanyQueryVariables = Exact<{
  filter?: Maybe<FilterCompanyInput>;
}>;


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFragment
  )> }
);

export type CompaniesManyQueryVariables = Exact<{
  filter?: Maybe<FilterCompanyInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCompanyInput>;
  aggregate?: Maybe<AggregateCompanyInput>;
}>;


export type CompaniesManyQuery = (
  { __typename?: 'Query' }
  & { companiesMany?: Maybe<Array<Maybe<(
    { __typename?: 'Company' }
    & CompanyFragment
  )>>> }
);

export type CustomerProjectsManyQueryVariables = Exact<{
  filter?: Maybe<FilterCustomerProjectInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerProjectInput>;
  aggregate?: Maybe<AggregateCustomerProjectInput>;
}>;


export type CustomerProjectsManyQuery = (
  { __typename?: 'Query' }
  & { customerProjectsMany?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerProject' }
    & CustomerProjectFragment
  )>>> }
);

export type CustomerProjectQueryVariables = Exact<{
  filter?: Maybe<FilterCustomerProjectInput>;
}>;


export type CustomerProjectQuery = (
  { __typename?: 'Query' }
  & { customerProject?: Maybe<(
    { __typename?: 'CustomerProject' }
    & CustomerProjectFragment
  )> }
);

export type CustomerProjectsPageQueryVariables = Exact<{
  filter?: Maybe<FilterCustomerProjectInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortCustomerProjectInput>;
  aggregate?: Maybe<AggregateCustomerProjectInput>;
}>;


export type CustomerProjectsPageQuery = (
  { __typename?: 'Query' }
  & { customerProjectsPage?: Maybe<(
    { __typename?: 'CustomerProjectManyResult' }
    & { pageData?: Maybe<(
      { __typename?: 'PageData' }
      & Pick<PageData, 'hasNextPage' | 'hasPreviousPage' | 'totalCount' | 'pageCount'>
    )>, records?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerProject' }
      & CustomerProjectFragment
    )>>> }
  )> }
);

export type AutocompleteQueryVariables = Exact<{
  filter?: Maybe<AutocompleteInput>;
}>;


export type AutocompleteQuery = (
  { __typename?: 'Query' }
  & { autocomplete?: Maybe<Array<Maybe<(
    { __typename?: 'AutoComplete' }
    & Pick<AutoComplete, 'text' | 'queryPlusText'>
  )>>> }
);

export type InvitationsPageQueryVariables = Exact<{
  filter?: Maybe<FilterInvitationInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvitationInput>;
  aggregate?: Maybe<AggregateInvitationInput>;
}>;


export type InvitationsPageQuery = (
  { __typename?: 'Query' }
  & { invitationsPage?: Maybe<(
    { __typename?: 'InvitationManyResult' }
    & { pageData?: Maybe<(
      { __typename?: 'PageData' }
      & Pick<PageData, 'hasNextPage' | 'hasPreviousPage' | 'totalCount' | 'pageCount'>
    )>, records?: Maybe<Array<Maybe<(
      { __typename?: 'Invitation' }
      & InvitationFragment
    )>>> }
  )> }
);

export type InvitationQueryVariables = Exact<{
  filter?: Maybe<FilterInvitationInput>;
}>;


export type InvitationQuery = (
  { __typename?: 'Query' }
  & { invitation?: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationFragment
  )> }
);

export type MyInvitationQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;


export type MyInvitationQuery = (
  { __typename?: 'Query' }
  & { myInvitation?: Maybe<(
    { __typename?: 'InvitationLimited' }
    & InvitationLimitedFragment
  )> }
);

export type ObjectQueryVariables = Exact<{
  filter?: Maybe<FilterObjectInput>;
}>;


export type ObjectQuery = (
  { __typename?: 'Query' }
  & { object?: Maybe<(
    { __typename?: 'Object' }
    & ObjectFragment
  )> }
);

export type ObjectTypesManyQueryVariables = Exact<{
  filter?: Maybe<FilterObjectTypeInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ObjectTypesManyQuery = (
  { __typename?: 'Query' }
  & { objectTypesMany?: Maybe<Array<Maybe<(
    { __typename?: 'ObjectType' }
    & ObjectTypeFragment
  )>>> }
);

export type ObjectTypeQueryVariables = Exact<{
  filter?: Maybe<FilterObjectTypeInput>;
}>;


export type ObjectTypeQuery = (
  { __typename?: 'Query' }
  & { objectType?: Maybe<(
    { __typename?: 'ObjectType' }
    & ObjectTypeFragment
  )> }
);

export type ObjectFamiliesManyQueryVariables = Exact<{
  filter?: Maybe<FilterObjectFamilyInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectFamilyInput>;
}>;


export type ObjectFamiliesManyQuery = (
  { __typename?: 'Query' }
  & { objectFamiliesMany?: Maybe<Array<Maybe<(
    { __typename?: 'ObjectFamily' }
    & ObjectFamilyFragment
  )>>> }
);

export type ObjectFamilyQueryVariables = Exact<{
  filter?: Maybe<FilterObjectFamilyInput>;
}>;


export type ObjectFamilyQuery = (
  { __typename?: 'Query' }
  & { objectFamily?: Maybe<(
    { __typename?: 'ObjectFamily' }
    & ObjectFamilyFragment
  )> }
);

export type ObjectGroupsManyQueryVariables = Exact<{
  filter?: Maybe<FilterObjectGroupInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortObjectGroupInput>;
}>;


export type ObjectGroupsManyQuery = (
  { __typename?: 'Query' }
  & { objectGroupsMany?: Maybe<Array<Maybe<(
    { __typename?: 'ObjectGroup' }
    & ObjectGroupFragment
  )>>> }
);

export type ObjectGroupQueryVariables = Exact<{
  filter?: Maybe<FilterObjectGroupInput>;
}>;


export type ObjectGroupQuery = (
  { __typename?: 'Query' }
  & { objectGroup?: Maybe<(
    { __typename?: 'ObjectGroup' }
    & ObjectGroupFragment
  )> }
);

export type RentalLinesCalculatedQueryVariables = Exact<{
  filter?: Maybe<FilterRentalLineInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  calculationType: CalculateRentallineInputValues;
  sort?: Maybe<SortRentalLineInput>;
  aggregate?: Maybe<AggregateRentalLineInput>;
}>;


export type RentalLinesCalculatedQuery = (
  { __typename?: 'Query' }
  & { rentalLinesCalculated?: Maybe<Array<Maybe<(
    { __typename?: 'RentalLine' }
    & RentalLineFragment
  )>>> }
);

export type RentalLinesCalculatedWithProjectQueryVariables = Exact<{
  filter?: Maybe<FilterRentalLineInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  calculationType: CalculateRentallineInputValues;
  sort?: Maybe<SortRentalLineInput>;
  aggregate?: Maybe<AggregateRentalLineInput>;
}>;


export type RentalLinesCalculatedWithProjectQuery = (
  { __typename?: 'Query' }
  & { rentalLinesCalculated?: Maybe<Array<Maybe<(
    { __typename?: 'RentalLine' }
    & Pick<RentalLine, 'GroupCountReadOnly'>
    & { customerProject?: Maybe<(
      { __typename?: 'CustomerProject' }
      & Pick<CustomerProject, 'YourReference' | 'Name' | 'ProjectNo'>
    )> }
    & RentalLineFragment
  )>>> }
);

export type RentCostQueryVariables = Exact<{
  filter?: Maybe<RentCostFilter>;
}>;


export type RentCostQuery = (
  { __typename?: 'Query' }
  & { rentCost?: Maybe<(
    { __typename?: 'RentCost' }
    & Pick<RentCost, 'TotalAmount'>
    & { Lines?: Maybe<Array<Maybe<(
      { __typename?: 'LineAmount' }
      & Pick<LineAmount, 'StartDate' | 'EndDate' | 'Amount' | 'PeriodName'>
    )>>> }
  )> }
);

export type AccumulatedRentCostQueryVariables = Exact<{
  filter?: Maybe<AccumulatedRentCostFilter>;
}>;


export type AccumulatedRentCostQuery = (
  { __typename?: 'Query' }
  & { accumulatedRentCost?: Maybe<(
    { __typename?: 'AccumulatedRentCost' }
    & Pick<AccumulatedRentCost, 'TotalAmount'>
  )> }
);

export type RentalLineSnapshotsManyQueryVariables = Exact<{
  filter?: Maybe<FilterRentalLineSnapshotInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortRentalLineSnapshotInput>;
  aggregate?: Maybe<AggregateRentalLineSnapshotInput>;
}>;


export type RentalLineSnapshotsManyQuery = (
  { __typename?: 'Query' }
  & { rentalLineSnapshotsMany?: Maybe<Array<Maybe<(
    { __typename?: 'RentalLineSnapshot' }
    & Pick<RentalLineSnapshot, 'SnapshotOn'>
    & { rentalLines?: Maybe<Array<Maybe<(
      { __typename?: 'RentalLine' }
      & RentalLineFragment
    )>>> }
  )>>> }
);

export type InvoiceHeadersManyQueryVariables = Exact<{
  filter?: Maybe<FilterInvoiceHeaderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortInvoiceHeaderInput>;
}>;


export type InvoiceHeadersManyQuery = (
  { __typename?: 'Query' }
  & { invoiceHeadersMany?: Maybe<Array<Maybe<(
    { __typename?: 'InvoiceHeader' }
    & InvoiceHeaderFragment
  )>>> }
);

export type InvoiceHeaderQueryVariables = Exact<{
  filter?: Maybe<FilterInvoiceHeaderInput>;
}>;


export type InvoiceHeaderQuery = (
  { __typename?: 'Query' }
  & { invoiceHeader?: Maybe<(
    { __typename?: 'InvoiceHeader' }
    & { invoiceLines?: Maybe<Array<Maybe<(
      { __typename?: 'InvoiceLine' }
      & Pick<InvoiceLine, 'LineNo' | 'Type' | 'No' | 'Description' | 'Quantity' | 'EQMRentalQuantity' | 'EQMRentalDays' | 'LineAmount' | 'EQMRentalFromDate' | 'EQMRentalToDate'>
    )>>> }
    & InvoiceHeaderFragment
  )> }
);

export type RentalEventsManyQueryVariables = Exact<{
  filter?: Maybe<RentalEventFilterInput>;
}>;


export type RentalEventsManyQuery = (
  { __typename?: 'Query' }
  & { rentalEventsMany?: Maybe<(
    { __typename?: 'RentalEvent' }
    & { reservationRequestLines?: Maybe<Array<Maybe<(
      { __typename?: 'ReservationRequestLine' }
      & ReservationRequestLineFragment
    )>>>, returnRequestLines?: Maybe<Array<Maybe<(
      { __typename?: 'ReturnRequestLine' }
      & ReturnRequestLineFragment
    )>>>, returnLines?: Maybe<Array<Maybe<(
      { __typename?: 'RentalPostedCollLine' }
      & RentalPostedCollLineFragment
    )>>>, reservations?: Maybe<Array<Maybe<(
      { __typename?: 'RentalLine' }
      & RentalLineFragment
    )>>>, shipmentLines?: Maybe<Array<Maybe<(
      { __typename?: 'RentalPostedShptLine' }
      & RentalPostedShptLineFragment
    )>>> }
  )> }
);

export type DomainIntegrationUpsertedSubscriptionVariables = Exact<{
  filter?: Maybe<FilterDomainIntegrationInput>;
}>;


export type DomainIntegrationUpsertedSubscription = (
  { __typename?: 'Subscription' }
  & { DomainIntegrationUpserted?: Maybe<(
    { __typename?: 'DomainIntegration' }
    & DomainIntegrationFragment
  )> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AcceptInivitationResponse: ResolverTypeWrapper<AcceptInivitationResponse>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AccessRequest: ResolverTypeWrapper<AccessRequest>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  AccessRequestConnection: ResolverTypeWrapper<AccessRequestConnection>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  AccessRequestConnectionEdge: ResolverTypeWrapper<AccessRequestConnectionEdge>;
  AccessRequestDeleteResponse: ResolverTypeWrapper<AccessRequestDeleteResponse>;
  AccessRequestManyResult: ResolverTypeWrapper<AccessRequestManyResult>;
  AccessRequestStatusEnum: AccessRequestStatusEnum;
  AccessRequestTypeEnum: AccessRequestTypeEnum;
  AccumulatedRentCost: ResolverTypeWrapper<AccumulatedRentCost>;
  AccumulatedRentCostFilter: AccumulatedRentCostFilter;
  AdditionalItem: ResolverTypeWrapper<AdditionalItem>;
  AdditionalItemConnection: ResolverTypeWrapper<AdditionalItemConnection>;
  AdditionalItemConnectionEdge: ResolverTypeWrapper<AdditionalItemConnectionEdge>;
  AdditionalItemDeleteResponse: ResolverTypeWrapper<AdditionalItemDeleteResponse>;
  AdditionalItemManyResult: ResolverTypeWrapper<AdditionalItemManyResult>;
  AdditionalItemQuantityOptionEnum: AdditionalItemQuantityOptionEnum;
  AdditionalItemTypeEnum: AdditionalItemTypeEnum;
  Address: ResolverTypeWrapper<Address>;
  AddressConnection: ResolverTypeWrapper<AddressConnection>;
  AddressConnectionEdge: ResolverTypeWrapper<AddressConnectionEdge>;
  AddressDeleteResponse: ResolverTypeWrapper<AddressDeleteResponse>;
  AddressManyResult: ResolverTypeWrapper<AddressManyResult>;
  AggregateAccessRequestInput: AggregateAccessRequestInput;
  AggregateAdditionalItemInput: AggregateAdditionalItemInput;
  AggregateAddressInput: AggregateAddressInput;
  AggregateAgreementInput: AggregateAgreementInput;
  AggregateAnnotationInput: AggregateAnnotationInput;
  AggregateApplicationModuleInput: AggregateApplicationModuleInput;
  AggregateBcMappingFieldInput: AggregateBcMappingFieldInput;
  AggregateBcMappingTableInput: AggregateBcMappingTableInput;
  AggregateBcSettingsInput: AggregateBcSettingsInput;
  AggregateBulkItemGroupInput: AggregateBulkItemGroupInput;
  AggregateCauseOfAbsenceInput: AggregateCauseOfAbsenceInput;
  AggregateCompanyInput: AggregateCompanyInput;
  AggregateConsignmentHeaderInput: AggregateConsignmentHeaderInput;
  AggregateConsignmentLineInput: AggregateConsignmentLineInput;
  AggregateContactInput: AggregateContactInput;
  AggregateCountryRegionInput: AggregateCountryRegionInput;
  AggregateCurrencyExchangeRateInput: AggregateCurrencyExchangeRateInput;
  AggregateCurrencyInput: AggregateCurrencyInput;
  AggregateCustomerDomainInput: AggregateCustomerDomainInput;
  AggregateCustomerPostingGroupInput: AggregateCustomerPostingGroupInput;
  AggregateCustomerProjectInput: AggregateCustomerProjectInput;
  AggregateCustomerSubProjectInput: AggregateCustomerSubProjectInput;
  AggregateDeliverytimeInput: AggregateDeliverytimeInput;
  AggregateDevopsProjectInput: AggregateDevopsProjectInput;
  AggregateDevopsTeamInput: AggregateDevopsTeamInput;
  AggregateDevopsWorkItemInput: AggregateDevopsWorkItemInput;
  AggregateDimensionInput: AggregateDimensionInput;
  AggregateDimensionValueInput: AggregateDimensionValueInput;
  AggregateDiscussionPostInput: AggregateDiscussionPostInput;
  AggregateDocumentClassificationInput: AggregateDocumentClassificationInput;
  AggregateDocumentInput: AggregateDocumentInput;
  AggregateDocumentRequestInput: AggregateDocumentRequestInput;
  AggregateDomainInput: AggregateDomainInput;
  AggregateDomainIntegrationInput: AggregateDomainIntegrationInput;
  AggregateDomainSettingsInput: AggregateDomainSettingsInput;
  AggregateEqmBaseCalendarChangeInput: AggregateEqmBaseCalendarChangeInput;
  AggregateEqmBaseCalendarInput: AggregateEqmBaseCalendarInput;
  AggregateEqmCollectionReturnChargesInput: AggregateEqmCollectionReturnChargesInput;
  AggregateEqmCommentLineInput: AggregateEqmCommentLineInput;
  AggregateEqmCustomizedCalendarChangeInput: AggregateEqmCustomizedCalendarChangeInput;
  AggregateEqmFncTestReturnChargesInput: AggregateEqmFncTestReturnChargesInput;
  AggregateEqmLocCostCenterCombInput: AggregateEqmLocCostCenterCombInput;
  AggregateEqmManufacturerInput: AggregateEqmManufacturerInput;
  AggregateEqmManufacturerModelInput: AggregateEqmManufacturerModelInput;
  AggregateEqmObjectCardFlowInput: AggregateEqmObjectCardFlowInput;
  AggregateEqmObjectComponentsInput: AggregateEqmObjectComponentsInput;
  AggregateEqmObjectCountHeaderInput: AggregateEqmObjectCountHeaderInput;
  AggregateEqmObjectCountLineInput: AggregateEqmObjectCountLineInput;
  AggregateEqmObjectGroupCardInput: AggregateEqmObjectGroupCardInput;
  AggregateEqmObjectGroupFlowInput: AggregateEqmObjectGroupFlowInput;
  AggregateEqmObjectServiceIntervalInput: AggregateEqmObjectServiceIntervalInput;
  AggregateEqmObjectSrvIntervalAggInput: AggregateEqmObjectSrvIntervalAggInput;
  AggregateEqmObjectStatusInput: AggregateEqmObjectStatusInput;
  AggregateEqmObjectTypeCardInput: AggregateEqmObjectTypeCardInput;
  AggregateEqmObjectTypeComponentsInput: AggregateEqmObjectTypeComponentsInput;
  AggregateEqmObjectTypeFlowInput: AggregateEqmObjectTypeFlowInput;
  AggregateEqmObjectTypePriceTermInput: AggregateEqmObjectTypePriceTermInput;
  AggregateEqmObjectTypeServiceIntervalInput: AggregateEqmObjectTypeServiceIntervalInput;
  AggregateEqmReRentCardInput: AggregateEqmReRentCardInput;
  AggregateEqmRentalGroupInput: AggregateEqmRentalGroupInput;
  AggregateEqmRentalKITComponentsInput: AggregateEqmRentalKitComponentsInput;
  AggregateEqmRentalKITInput: AggregateEqmRentalKitInput;
  AggregateEqmRentalKITLinesInput: AggregateEqmRentalKitLinesInput;
  AggregateEqmRentalLineDiscountInput: AggregateEqmRentalLineDiscountInput;
  AggregateEqmRentalPriceInput: AggregateEqmRentalPriceInput;
  AggregateEqmReturnChargesInput: AggregateEqmReturnChargesInput;
  AggregateEqmServiceCardInput: AggregateEqmServiceCardInput;
  AggregateEqmServiceTypeInput: AggregateEqmServiceTypeInput;
  AggregateEqmTransferHeaderInput: AggregateEqmTransferHeaderInput;
  AggregateEqmTransferLineInput: AggregateEqmTransferLineInput;
  AggregateEqmTypeFunctionalTestInput: AggregateEqmTypeFunctionalTestInput;
  AggregateEqmWorkHeaderInput: AggregateEqmWorkHeaderInput;
  AggregateEqmWorkLineInput: AggregateEqmWorkLineInput;
  AggregateErrandInput: AggregateErrandInput;
  AggregateErrandLineInput: AggregateErrandLineInput;
  AggregateErrorReportInput: AggregateErrorReportInput;
  AggregateFeedbackInput: AggregateFeedbackInput;
  AggregateFeemappingInput: AggregateFeemappingInput;
  AggregateFieldOrderInput: AggregateFieldOrderInput;
  AggregateFieldOrderLineInput: AggregateFieldOrderLineInput;
  AggregateFileInput: AggregateFileInput;
  AggregateFunctionTestHeaderInput: AggregateFunctionTestHeaderInput;
  AggregateFunctionTestLineInput: AggregateFunctionTestLineInput;
  AggregateGenBusinessPostingGroupInput: AggregateGenBusinessPostingGroupInput;
  AggregateGenJournalLineInput: AggregateGenJournalLineInput;
  AggregateGenProductPostingGroupInput: AggregateGenProductPostingGroupInput;
  AggregateInspectionInput: AggregateInspectionInput;
  AggregateInspectionUserInput: AggregateInspectionUserInput;
  AggregateIntegrationCollectionInput: AggregateIntegrationCollectionInput;
  AggregateInventoryPostingGroupInput: AggregateInventoryPostingGroupInput;
  AggregateInvitationInput: AggregateInvitationInput;
  AggregateInvoiceHeaderInput: AggregateInvoiceHeaderInput;
  AggregateInvoiceLineInput: AggregateInvoiceLineInput;
  AggregateItemCategoryInput: AggregateItemCategoryInput;
  AggregateItemDiscGroupInput: AggregateItemDiscGroupInput;
  AggregateItemInput: AggregateItemInput;
  AggregateItemReferenceInput: AggregateItemReferenceInput;
  AggregateItemRestrictionInput: AggregateItemRestrictionInput;
  AggregateItemUnitOfMeasureInput: AggregateItemUnitOfMeasureInput;
  AggregateItemVendorInput: AggregateItemVendorInput;
  AggregateJobTaskInput: AggregateJobTaskInput;
  AggregateKliHeaderInput: AggregateKliHeaderInput;
  AggregateKliReasonCodeInput: AggregateKliReasonCodeInput;
  AggregateLeadInput: AggregateLeadInput;
  AggregateLocationInput: AggregateLocationInput;
  AggregateMagentoOrderStatusInput: AggregateMagentoOrderStatusInput;
  AggregateMagentoSettingsInput: AggregateMagentoSettingsInput;
  AggregateManufacturerInput: AggregateManufacturerInput;
  AggregateNavUserInput: AggregateNavUserInput;
  AggregateObjectCommentLineInput: AggregateObjectCommentLineInput;
  AggregateObjectFamilyInput: AggregateObjectFamilyInput;
  AggregateObjectGroupInput: AggregateObjectGroupInput;
  AggregateObjectInput: AggregateObjectInput;
  AggregateObjectReturnHeaderInput: AggregateObjectReturnHeaderInput;
  AggregateObjectReturnLineInput: AggregateObjectReturnLineInput;
  AggregateObjectTypeInput: AggregateObjectTypeInput;
  AggregateOcrAnalysisInput: AggregateOcrAnalysisInput;
  AggregatePaymentMethodInput: AggregatePaymentMethodInput;
  AggregatePaymentTermsInput: AggregatePaymentTermsInput;
  AggregatePlatformFeatureInput: AggregatePlatformFeatureInput;
  AggregatePostCodeInput: AggregatePostCodeInput;
  AggregateProjectInput: AggregateProjectInput;
  AggregatePurchCrMemoHeaderInput: AggregatePurchCrMemoHeaderInput;
  AggregatePurchCrMemoLineInput: AggregatePurchCrMemoLineInput;
  AggregatePurchInvHeaderInput: AggregatePurchInvHeaderInput;
  AggregatePurchInvLineInput: AggregatePurchInvLineInput;
  AggregatePurchRcptHeaderInput: AggregatePurchRcptHeaderInput;
  AggregatePurchRcptLineInput: AggregatePurchRcptLineInput;
  AggregatePurchaseHeaderInput: AggregatePurchaseHeaderInput;
  AggregatePurchaseLineInput: AggregatePurchaseLineInput;
  AggregatePurchasingInput: AggregatePurchasingInput;
  AggregateQuoteHeaderInput: AggregateQuoteHeaderInput;
  AggregateQuoteLineInput: AggregateQuoteLineInput;
  AggregateQuoteTaskGroupInput: AggregateQuoteTaskGroupInput;
  AggregateRentalHeaderInput: AggregateRentalHeaderInput;
  AggregateRentalLineInput: AggregateRentalLineInput;
  AggregateRentalLineSnapshotInput: AggregateRentalLineSnapshotInput;
  AggregateRentalPostedCollHeaderInput: AggregateRentalPostedCollHeaderInput;
  AggregateRentalPostedCollLineInput: AggregateRentalPostedCollLineInput;
  AggregateRentalPostedShptHeaderInput: AggregateRentalPostedShptHeaderInput;
  AggregateRentalPostedShptLineInput: AggregateRentalPostedShptLineInput;
  AggregateRentalPriceTermsInput: AggregateRentalPriceTermsInput;
  AggregateRentalReturnEntryInput: AggregateRentalReturnEntryInput;
  AggregateRequestHeaderInput: AggregateRequestHeaderInput;
  AggregateRequestLineInput: AggregateRequestLineInput;
  AggregateReservationRequestHeaderInput: AggregateReservationRequestHeaderInput;
  AggregateReservationRequestLineInput: AggregateReservationRequestLineInput;
  AggregateResourceInput: AggregateResourceInput;
  AggregateResponsibilityCenterInput: AggregateResponsibilityCenterInput;
  AggregateReturnRequestHeaderInput: AggregateReturnRequestHeaderInput;
  AggregateReturnRequestLineInput: AggregateReturnRequestLineInput;
  AggregateRiskAnalysisHeaderInput: AggregateRiskAnalysisHeaderInput;
  AggregateRiskAnalysisLineInput: AggregateRiskAnalysisLineInput;
  AggregateRiskAnalysisSignatureInput: AggregateRiskAnalysisSignatureInput;
  AggregateSalesHeaderArchiveInput: AggregateSalesHeaderArchiveInput;
  AggregateSalesHeaderHistoryInput: AggregateSalesHeaderHistoryInput;
  AggregateSalesHeaderInput: AggregateSalesHeaderInput;
  AggregateSalesHeaderRequestInput: AggregateSalesHeaderRequestInput;
  AggregateSalesLineArchiveInput: AggregateSalesLineArchiveInput;
  AggregateSalesLineInput: AggregateSalesLineInput;
  AggregateSalesLineRequestInput: AggregateSalesLineRequestInput;
  AggregateSalesPriceInput: AggregateSalesPriceInput;
  AggregateSalesShipmentHeaderInput: AggregateSalesShipmentHeaderInput;
  AggregateSalesShipmentLineInput: AggregateSalesShipmentLineInput;
  AggregateSalespersonInput: AggregateSalespersonInput;
  AggregateServiceItemGroupInput: AggregateServiceItemGroupInput;
  AggregateShipmentMethodInput: AggregateShipmentMethodInput;
  AggregateShippingAgentInput: AggregateShippingAgentInput;
  AggregateSignRequestInput: AggregateSignRequestInput;
  AggregateSignatureInput: AggregateSignatureInput;
  AggregateSpecialEquipmentInput: AggregateSpecialEquipmentInput;
  AggregateStockkeepingUnitInput: AggregateStockkeepingUnitInput;
  AggregateTariffNumberInput: AggregateTariffNumberInput;
  AggregateTaxGroupInput: AggregateTaxGroupInput;
  AggregateTimeRegistrationInput: AggregateTimeRegistrationInput;
  AggregateTimeSheetDetailInput: AggregateTimeSheetDetailInput;
  AggregateTimeSheetLineInput: AggregateTimeSheetLineInput;
  AggregateTransactionSpecificationInput: AggregateTransactionSpecificationInput;
  AggregateTransactionTypeInput: AggregateTransactionTypeInput;
  AggregateTransportMethodInput: AggregateTransportMethodInput;
  AggregateUnitOfMeasureInput: AggregateUnitOfMeasureInput;
  AggregateUserFavoritesInput: AggregateUserFavoritesInput;
  AggregateUserInput: AggregateUserInput;
  AggregateVatBusinessPostingGroupInput: AggregateVatBusinessPostingGroupInput;
  AggregateVatProductPostingGroupInput: AggregateVatProductPostingGroupInput;
  AggregateVendorBankAccountInput: AggregateVendorBankAccountInput;
  AggregateVendorInput: AggregateVendorInput;
  AggregateVendorPostingGroupInput: AggregateVendorPostingGroupInput;
  AggregateWarehouseClassInput: AggregateWarehouseClassInput;
  AggregateWebBookingInput: AggregateWebBookingInput;
  AggregateWebhookSubscriptionInput: AggregateWebhookSubscriptionInput;
  AggregateWorkLogInput: AggregateWorkLogInput;
  AggregateWorkTypeInput: AggregateWorkTypeInput;
  Agreement: ResolverTypeWrapper<Agreement>;
  AgreementConnection: ResolverTypeWrapper<AgreementConnection>;
  AgreementConnectionEdge: ResolverTypeWrapper<AgreementConnectionEdge>;
  AgreementDeleteResponse: ResolverTypeWrapper<AgreementDeleteResponse>;
  AgreementManyResult: ResolverTypeWrapper<AgreementManyResult>;
  Annotation: ResolverTypeWrapper<Annotation>;
  AnnotationConnection: ResolverTypeWrapper<AnnotationConnection>;
  AnnotationConnectionEdge: ResolverTypeWrapper<AnnotationConnectionEdge>;
  AnnotationDeleteResponse: ResolverTypeWrapper<AnnotationDeleteResponse>;
  AnnotationManyResult: ResolverTypeWrapper<AnnotationManyResult>;
  AppPushTokenInput: AppPushTokenInput;
  AppPushtoken: ResolverTypeWrapper<AppPushtoken>;
  ApplicationModule: ResolverTypeWrapper<ApplicationModule>;
  ApplicationModuleConnection: ResolverTypeWrapper<ApplicationModuleConnection>;
  ApplicationModuleConnectionEdge: ResolverTypeWrapper<ApplicationModuleConnectionEdge>;
  ApplicationModuleDeleteResponse: ResolverTypeWrapper<ApplicationModuleDeleteResponse>;
  ApplicationModuleManyResult: ResolverTypeWrapper<ApplicationModuleManyResult>;
  AuthError: ResolverTypeWrapper<AuthError>;
  AuthResponse: ResolverTypeWrapper<AuthResponse>;
  AutoComplete: ResolverTypeWrapper<AutoComplete>;
  AutocompleteInput: AutocompleteInput;
  BcMappingField: ResolverTypeWrapper<BcMappingField>;
  BcMappingFieldConnection: ResolverTypeWrapper<BcMappingFieldConnection>;
  BcMappingFieldConnectionEdge: ResolverTypeWrapper<BcMappingFieldConnectionEdge>;
  BcMappingFieldDeleteResponse: ResolverTypeWrapper<BcMappingFieldDeleteResponse>;
  BcMappingFieldManyResult: ResolverTypeWrapper<BcMappingFieldManyResult>;
  BcMappingTable: ResolverTypeWrapper<BcMappingTable>;
  BcMappingTableConnection: ResolverTypeWrapper<BcMappingTableConnection>;
  BcMappingTableConnectionEdge: ResolverTypeWrapper<BcMappingTableConnectionEdge>;
  BcMappingTableDeleteResponse: ResolverTypeWrapper<BcMappingTableDeleteResponse>;
  BcMappingTableManyResult: ResolverTypeWrapper<BcMappingTableManyResult>;
  BcSettings: ResolverTypeWrapper<BcSettings>;
  BcSettingsConnection: ResolverTypeWrapper<BcSettingsConnection>;
  BcSettingsConnectionEdge: ResolverTypeWrapper<BcSettingsConnectionEdge>;
  BcSettingsDeleteResponse: ResolverTypeWrapper<BcSettingsDeleteResponse>;
  BcSettingsManyResult: ResolverTypeWrapper<BcSettingsManyResult>;
  BulkItemGroup: ResolverTypeWrapper<BulkItemGroup>;
  BulkItemGroupConnection: ResolverTypeWrapper<BulkItemGroupConnection>;
  BulkItemGroupConnectionEdge: ResolverTypeWrapper<BulkItemGroupConnectionEdge>;
  BulkItemGroupDeleteResponse: ResolverTypeWrapper<BulkItemGroupDeleteResponse>;
  BulkItemGroupManyResult: ResolverTypeWrapper<BulkItemGroupManyResult>;
  CalculateRentallineInputValues: CalculateRentallineInputValues;
  CauseOfAbsence: ResolverTypeWrapper<CauseOfAbsence>;
  CauseOfAbsenceConnection: ResolverTypeWrapper<CauseOfAbsenceConnection>;
  CauseOfAbsenceConnectionEdge: ResolverTypeWrapper<CauseOfAbsenceConnectionEdge>;
  CauseOfAbsenceDeleteResponse: ResolverTypeWrapper<CauseOfAbsenceDeleteResponse>;
  CauseOfAbsenceManyResult: ResolverTypeWrapper<CauseOfAbsenceManyResult>;
  ChangeObjectTypeImagePayload: ChangeObjectTypeImagePayload;
  ClearFunctionTestInput: ClearFunctionTestInput;
  ClearFunctionalTestReusult: ResolverTypeWrapper<ClearFunctionalTestReusult>;
  Company: ResolverTypeWrapper<Company>;
  CompanyConnection: ResolverTypeWrapper<CompanyConnection>;
  CompanyConnectionEdge: ResolverTypeWrapper<CompanyConnectionEdge>;
  CompanyDeleteResponse: ResolverTypeWrapper<CompanyDeleteResponse>;
  CompanyInvitationInput: CompanyInvitationInput;
  CompanyManyResult: ResolverTypeWrapper<CompanyManyResult>;
  CompanyPaymentMethodWebEnum: CompanyPaymentMethodWebEnum;
  ConsignmentHeader: ResolverTypeWrapper<ConsignmentHeader>;
  ConsignmentHeaderConnection: ResolverTypeWrapper<ConsignmentHeaderConnection>;
  ConsignmentHeaderConnectionEdge: ResolverTypeWrapper<ConsignmentHeaderConnectionEdge>;
  ConsignmentHeaderDeleteResponse: ResolverTypeWrapper<ConsignmentHeaderDeleteResponse>;
  ConsignmentHeaderManyResult: ResolverTypeWrapper<ConsignmentHeaderManyResult>;
  ConsignmentLine: ResolverTypeWrapper<ConsignmentLine>;
  ConsignmentLineConnection: ResolverTypeWrapper<ConsignmentLineConnection>;
  ConsignmentLineConnectionEdge: ResolverTypeWrapper<ConsignmentLineConnectionEdge>;
  ConsignmentLineDeleteResponse: ResolverTypeWrapper<ConsignmentLineDeleteResponse>;
  ConsignmentLineManyResult: ResolverTypeWrapper<ConsignmentLineManyResult>;
  Contact: ResolverTypeWrapper<Contact>;
  ContactConnection: ResolverTypeWrapper<ContactConnection>;
  ContactConnectionEdge: ResolverTypeWrapper<ContactConnectionEdge>;
  ContactDeleteResponse: ResolverTypeWrapper<ContactDeleteResponse>;
  ContactManyResult: ResolverTypeWrapper<ContactManyResult>;
  CountryRegion: ResolverTypeWrapper<CountryRegion>;
  CountryRegionConnection: ResolverTypeWrapper<CountryRegionConnection>;
  CountryRegionConnectionEdge: ResolverTypeWrapper<CountryRegionConnectionEdge>;
  CountryRegionDeleteResponse: ResolverTypeWrapper<CountryRegionDeleteResponse>;
  CountryRegionManyResult: ResolverTypeWrapper<CountryRegionManyResult>;
  CreateAccessRequestInput: CreateAccessRequestInput;
  CreateSerciceAccountInput: CreateSerciceAccountInput;
  CreateWorkshopOrderInput: CreateWorkshopOrderInput;
  CreateWorkshopOrderResponse: ResolverTypeWrapper<CreateWorkshopOrderResponse>;
  Currency: ResolverTypeWrapper<Currency>;
  CurrencyConnection: ResolverTypeWrapper<CurrencyConnection>;
  CurrencyConnectionEdge: ResolverTypeWrapper<CurrencyConnectionEdge>;
  CurrencyDeleteResponse: ResolverTypeWrapper<CurrencyDeleteResponse>;
  CurrencyExchangeRate: ResolverTypeWrapper<CurrencyExchangeRate>;
  CurrencyExchangeRateConnection: ResolverTypeWrapper<CurrencyExchangeRateConnection>;
  CurrencyExchangeRateConnectionEdge: ResolverTypeWrapper<CurrencyExchangeRateConnectionEdge>;
  CurrencyExchangeRateDeleteResponse: ResolverTypeWrapper<CurrencyExchangeRateDeleteResponse>;
  CurrencyExchangeRateManyResult: ResolverTypeWrapper<CurrencyExchangeRateManyResult>;
  CurrencyManyResult: ResolverTypeWrapper<CurrencyManyResult>;
  CustomerDomain: ResolverTypeWrapper<CustomerDomain>;
  CustomerDomainConnection: ResolverTypeWrapper<CustomerDomainConnection>;
  CustomerDomainConnectionEdge: ResolverTypeWrapper<CustomerDomainConnectionEdge>;
  CustomerDomainDeleteResponse: ResolverTypeWrapper<CustomerDomainDeleteResponse>;
  CustomerDomainLimited: ResolverTypeWrapper<CustomerDomainLimited>;
  CustomerDomainManyResult: ResolverTypeWrapper<CustomerDomainManyResult>;
  CustomerPostingGroup: ResolverTypeWrapper<CustomerPostingGroup>;
  CustomerPostingGroupConnection: ResolverTypeWrapper<CustomerPostingGroupConnection>;
  CustomerPostingGroupConnectionEdge: ResolverTypeWrapper<CustomerPostingGroupConnectionEdge>;
  CustomerPostingGroupDeleteResponse: ResolverTypeWrapper<CustomerPostingGroupDeleteResponse>;
  CustomerPostingGroupManyResult: ResolverTypeWrapper<CustomerPostingGroupManyResult>;
  CustomerProject: ResolverTypeWrapper<CustomerProject>;
  CustomerProjectConnection: ResolverTypeWrapper<CustomerProjectConnection>;
  CustomerProjectConnectionEdge: ResolverTypeWrapper<CustomerProjectConnectionEdge>;
  CustomerProjectDeleteResponse: ResolverTypeWrapper<CustomerProjectDeleteResponse>;
  CustomerProjectManyResult: ResolverTypeWrapper<CustomerProjectManyResult>;
  CustomerSubProject: ResolverTypeWrapper<CustomerSubProject>;
  CustomerSubProjectConnection: ResolverTypeWrapper<CustomerSubProjectConnection>;
  CustomerSubProjectConnectionEdge: ResolverTypeWrapper<CustomerSubProjectConnectionEdge>;
  CustomerSubProjectDeleteResponse: ResolverTypeWrapper<CustomerSubProjectDeleteResponse>;
  CustomerSubProjectManyResult: ResolverTypeWrapper<CustomerSubProjectManyResult>;
  DailyRentalEvents: ResolverTypeWrapper<DailyRentalEvents>;
  DailyTransactionResponse: ResolverTypeWrapper<DailyTransactionResponse>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteResponse: ResolverTypeWrapper<DeleteResponse>;
  DeletedRecord: ResolverTypeWrapper<DeletedRecord>;
  DeletedRecordRef: ResolverTypeWrapper<DeletedRecordRef>;
  Deliverytime: ResolverTypeWrapper<Deliverytime>;
  DeliverytimeConnection: ResolverTypeWrapper<DeliverytimeConnection>;
  DeliverytimeConnectionEdge: ResolverTypeWrapper<DeliverytimeConnectionEdge>;
  DeliverytimeDeleteResponse: ResolverTypeWrapper<DeliverytimeDeleteResponse>;
  DeliverytimeManyResult: ResolverTypeWrapper<DeliverytimeManyResult>;
  DevopsProject: ResolverTypeWrapper<DevopsProject>;
  DevopsProjectConnection: ResolverTypeWrapper<DevopsProjectConnection>;
  DevopsProjectConnectionEdge: ResolverTypeWrapper<DevopsProjectConnectionEdge>;
  DevopsProjectDeleteResponse: ResolverTypeWrapper<DevopsProjectDeleteResponse>;
  DevopsProjectManyResult: ResolverTypeWrapper<DevopsProjectManyResult>;
  DevopsTeam: ResolverTypeWrapper<DevopsTeam>;
  DevopsTeamConnection: ResolverTypeWrapper<DevopsTeamConnection>;
  DevopsTeamConnectionEdge: ResolverTypeWrapper<DevopsTeamConnectionEdge>;
  DevopsTeamDeleteResponse: ResolverTypeWrapper<DevopsTeamDeleteResponse>;
  DevopsTeamManyResult: ResolverTypeWrapper<DevopsTeamManyResult>;
  DevopsWorkItem: ResolverTypeWrapper<DevopsWorkItem>;
  DevopsWorkItemConnection: ResolverTypeWrapper<DevopsWorkItemConnection>;
  DevopsWorkItemConnectionEdge: ResolverTypeWrapper<DevopsWorkItemConnectionEdge>;
  DevopsWorkItemDeleteResponse: ResolverTypeWrapper<DevopsWorkItemDeleteResponse>;
  DevopsWorkItemManyResult: ResolverTypeWrapper<DevopsWorkItemManyResult>;
  Dimension: ResolverTypeWrapper<Dimension>;
  DimensionConnection: ResolverTypeWrapper<DimensionConnection>;
  DimensionConnectionEdge: ResolverTypeWrapper<DimensionConnectionEdge>;
  DimensionDeleteResponse: ResolverTypeWrapper<DimensionDeleteResponse>;
  DimensionManyResult: ResolverTypeWrapper<DimensionManyResult>;
  DimensionValue: ResolverTypeWrapper<DimensionValue>;
  DimensionValueConnection: ResolverTypeWrapper<DimensionValueConnection>;
  DimensionValueConnectionEdge: ResolverTypeWrapper<DimensionValueConnectionEdge>;
  DimensionValueDeleteResponse: ResolverTypeWrapper<DimensionValueDeleteResponse>;
  DimensionValueManyResult: ResolverTypeWrapper<DimensionValueManyResult>;
  DiscussionPost: ResolverTypeWrapper<DiscussionPost>;
  DiscussionPostConnection: ResolverTypeWrapper<DiscussionPostConnection>;
  DiscussionPostConnectionEdge: ResolverTypeWrapper<DiscussionPostConnectionEdge>;
  DiscussionPostDeleteResponse: ResolverTypeWrapper<DiscussionPostDeleteResponse>;
  DiscussionPostManyResult: ResolverTypeWrapper<DiscussionPostManyResult>;
  Document: ResolverTypeWrapper<Document>;
  DocumentClassification: ResolverTypeWrapper<DocumentClassification>;
  DocumentClassificationConnection: ResolverTypeWrapper<DocumentClassificationConnection>;
  DocumentClassificationConnectionEdge: ResolverTypeWrapper<DocumentClassificationConnectionEdge>;
  DocumentClassificationDeleteResponse: ResolverTypeWrapper<DocumentClassificationDeleteResponse>;
  DocumentClassificationManyResult: ResolverTypeWrapper<DocumentClassificationManyResult>;
  DocumentConnection: ResolverTypeWrapper<DocumentConnection>;
  DocumentConnectionEdge: ResolverTypeWrapper<DocumentConnectionEdge>;
  DocumentDeleteResponse: ResolverTypeWrapper<DocumentDeleteResponse>;
  DocumentManyResult: ResolverTypeWrapper<DocumentManyResult>;
  DocumentRequest: ResolverTypeWrapper<DocumentRequest>;
  DocumentRequestConnection: ResolverTypeWrapper<DocumentRequestConnection>;
  DocumentRequestConnectionEdge: ResolverTypeWrapper<DocumentRequestConnectionEdge>;
  DocumentRequestDeleteResponse: ResolverTypeWrapper<DocumentRequestDeleteResponse>;
  DocumentRequestManyResult: ResolverTypeWrapper<DocumentRequestManyResult>;
  Domain: ResolverTypeWrapper<Domain>;
  DomainConnection: ResolverTypeWrapper<DomainConnection>;
  DomainConnectionEdge: ResolverTypeWrapper<DomainConnectionEdge>;
  DomainConnectionTestResult: ResolverTypeWrapper<DomainConnectionTestResult>;
  DomainDeleteResponse: ResolverTypeWrapper<DomainDeleteResponse>;
  DomainIntegration: ResolverTypeWrapper<DomainIntegration>;
  DomainIntegrationConnection: ResolverTypeWrapper<DomainIntegrationConnection>;
  DomainIntegrationConnectionEdge: ResolverTypeWrapper<DomainIntegrationConnectionEdge>;
  DomainIntegrationDeleteResponse: ResolverTypeWrapper<DomainIntegrationDeleteResponse>;
  DomainIntegrationManyResult: ResolverTypeWrapper<DomainIntegrationManyResult>;
  DomainIntegrationSchemaVersionEnum: DomainIntegrationSchemaVersionEnum;
  DomainInvitationInput: DomainInvitationInput;
  DomainLimited: ResolverTypeWrapper<DomainLimited>;
  DomainManyResult: ResolverTypeWrapper<DomainManyResult>;
  DomainSettings: ResolverTypeWrapper<DomainSettings>;
  DomainSettingsConnection: ResolverTypeWrapper<DomainSettingsConnection>;
  DomainSettingsConnectionEdge: ResolverTypeWrapper<DomainSettingsConnectionEdge>;
  DomainSettingsDeleteResponse: ResolverTypeWrapper<DomainSettingsDeleteResponse>;
  DomainSettingsManyResult: ResolverTypeWrapper<DomainSettingsManyResult>;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']>;
  EmailRecipientInput: EmailRecipientInput;
  EqmBaseCalendar: ResolverTypeWrapper<EqmBaseCalendar>;
  EqmBaseCalendarChange: ResolverTypeWrapper<EqmBaseCalendarChange>;
  EqmBaseCalendarChangeConnection: ResolverTypeWrapper<EqmBaseCalendarChangeConnection>;
  EqmBaseCalendarChangeConnectionEdge: ResolverTypeWrapper<EqmBaseCalendarChangeConnectionEdge>;
  EqmBaseCalendarChangeDeleteResponse: ResolverTypeWrapper<EqmBaseCalendarChangeDeleteResponse>;
  EqmBaseCalendarChangeManyResult: ResolverTypeWrapper<EqmBaseCalendarChangeManyResult>;
  EqmBaseCalendarConnection: ResolverTypeWrapper<EqmBaseCalendarConnection>;
  EqmBaseCalendarConnectionEdge: ResolverTypeWrapper<EqmBaseCalendarConnectionEdge>;
  EqmBaseCalendarDeleteResponse: ResolverTypeWrapper<EqmBaseCalendarDeleteResponse>;
  EqmBaseCalendarManyResult: ResolverTypeWrapper<EqmBaseCalendarManyResult>;
  EqmCollectionReturnCharges: ResolverTypeWrapper<EqmCollectionReturnCharges>;
  EqmCollectionReturnChargesConnection: ResolverTypeWrapper<EqmCollectionReturnChargesConnection>;
  EqmCollectionReturnChargesConnectionEdge: ResolverTypeWrapper<EqmCollectionReturnChargesConnectionEdge>;
  EqmCollectionReturnChargesDeleteResponse: ResolverTypeWrapper<EqmCollectionReturnChargesDeleteResponse>;
  EqmCollectionReturnChargesManyResult: ResolverTypeWrapper<EqmCollectionReturnChargesManyResult>;
  EqmCommentLine: ResolverTypeWrapper<EqmCommentLine>;
  EqmCommentLineConnection: ResolverTypeWrapper<EqmCommentLineConnection>;
  EqmCommentLineConnectionEdge: ResolverTypeWrapper<EqmCommentLineConnectionEdge>;
  EqmCommentLineDeleteResponse: ResolverTypeWrapper<EqmCommentLineDeleteResponse>;
  EqmCommentLineManyResult: ResolverTypeWrapper<EqmCommentLineManyResult>;
  EqmCustomizedCalendarChange: ResolverTypeWrapper<EqmCustomizedCalendarChange>;
  EqmCustomizedCalendarChangeConnection: ResolverTypeWrapper<EqmCustomizedCalendarChangeConnection>;
  EqmCustomizedCalendarChangeConnectionEdge: ResolverTypeWrapper<EqmCustomizedCalendarChangeConnectionEdge>;
  EqmCustomizedCalendarChangeDeleteResponse: ResolverTypeWrapper<EqmCustomizedCalendarChangeDeleteResponse>;
  EqmCustomizedCalendarChangeManyResult: ResolverTypeWrapper<EqmCustomizedCalendarChangeManyResult>;
  EqmFncTestReturnCharges: ResolverTypeWrapper<EqmFncTestReturnCharges>;
  EqmFncTestReturnChargesConnection: ResolverTypeWrapper<EqmFncTestReturnChargesConnection>;
  EqmFncTestReturnChargesConnectionEdge: ResolverTypeWrapper<EqmFncTestReturnChargesConnectionEdge>;
  EqmFncTestReturnChargesDeleteResponse: ResolverTypeWrapper<EqmFncTestReturnChargesDeleteResponse>;
  EqmFncTestReturnChargesManyResult: ResolverTypeWrapper<EqmFncTestReturnChargesManyResult>;
  EqmLocCostCenterComb: ResolverTypeWrapper<EqmLocCostCenterComb>;
  EqmLocCostCenterCombConnection: ResolverTypeWrapper<EqmLocCostCenterCombConnection>;
  EqmLocCostCenterCombConnectionEdge: ResolverTypeWrapper<EqmLocCostCenterCombConnectionEdge>;
  EqmLocCostCenterCombDeleteResponse: ResolverTypeWrapper<EqmLocCostCenterCombDeleteResponse>;
  EqmLocCostCenterCombManyResult: ResolverTypeWrapper<EqmLocCostCenterCombManyResult>;
  EqmManufacturer: ResolverTypeWrapper<EqmManufacturer>;
  EqmManufacturerConnection: ResolverTypeWrapper<EqmManufacturerConnection>;
  EqmManufacturerConnectionEdge: ResolverTypeWrapper<EqmManufacturerConnectionEdge>;
  EqmManufacturerDeleteResponse: ResolverTypeWrapper<EqmManufacturerDeleteResponse>;
  EqmManufacturerManyResult: ResolverTypeWrapper<EqmManufacturerManyResult>;
  EqmManufacturerModel: ResolverTypeWrapper<EqmManufacturerModel>;
  EqmManufacturerModelConnection: ResolverTypeWrapper<EqmManufacturerModelConnection>;
  EqmManufacturerModelConnectionEdge: ResolverTypeWrapper<EqmManufacturerModelConnectionEdge>;
  EqmManufacturerModelDeleteResponse: ResolverTypeWrapper<EqmManufacturerModelDeleteResponse>;
  EqmManufacturerModelManyResult: ResolverTypeWrapper<EqmManufacturerModelManyResult>;
  EqmObjectCardFlow: ResolverTypeWrapper<EqmObjectCardFlow>;
  EqmObjectCardFlowConnection: ResolverTypeWrapper<EqmObjectCardFlowConnection>;
  EqmObjectCardFlowConnectionEdge: ResolverTypeWrapper<EqmObjectCardFlowConnectionEdge>;
  EqmObjectCardFlowDeleteResponse: ResolverTypeWrapper<EqmObjectCardFlowDeleteResponse>;
  EqmObjectCardFlowManyResult: ResolverTypeWrapper<EqmObjectCardFlowManyResult>;
  EqmObjectComponents: ResolverTypeWrapper<EqmObjectComponents>;
  EqmObjectComponentsConnection: ResolverTypeWrapper<EqmObjectComponentsConnection>;
  EqmObjectComponentsConnectionEdge: ResolverTypeWrapper<EqmObjectComponentsConnectionEdge>;
  EqmObjectComponentsDeleteResponse: ResolverTypeWrapper<EqmObjectComponentsDeleteResponse>;
  EqmObjectComponentsManyResult: ResolverTypeWrapper<EqmObjectComponentsManyResult>;
  EqmObjectCountHeader: ResolverTypeWrapper<EqmObjectCountHeader>;
  EqmObjectCountHeaderConnection: ResolverTypeWrapper<EqmObjectCountHeaderConnection>;
  EqmObjectCountHeaderConnectionEdge: ResolverTypeWrapper<EqmObjectCountHeaderConnectionEdge>;
  EqmObjectCountHeaderDeleteResponse: ResolverTypeWrapper<EqmObjectCountHeaderDeleteResponse>;
  EqmObjectCountHeaderManyResult: ResolverTypeWrapper<EqmObjectCountHeaderManyResult>;
  EqmObjectCountLine: ResolverTypeWrapper<EqmObjectCountLine>;
  EqmObjectCountLineConnection: ResolverTypeWrapper<EqmObjectCountLineConnection>;
  EqmObjectCountLineConnectionEdge: ResolverTypeWrapper<EqmObjectCountLineConnectionEdge>;
  EqmObjectCountLineDeleteResponse: ResolverTypeWrapper<EqmObjectCountLineDeleteResponse>;
  EqmObjectCountLineManyResult: ResolverTypeWrapper<EqmObjectCountLineManyResult>;
  EqmObjectGroupCard: ResolverTypeWrapper<EqmObjectGroupCard>;
  EqmObjectGroupCardConnection: ResolverTypeWrapper<EqmObjectGroupCardConnection>;
  EqmObjectGroupCardConnectionEdge: ResolverTypeWrapper<EqmObjectGroupCardConnectionEdge>;
  EqmObjectGroupCardDeleteResponse: ResolverTypeWrapper<EqmObjectGroupCardDeleteResponse>;
  EqmObjectGroupCardManyResult: ResolverTypeWrapper<EqmObjectGroupCardManyResult>;
  EqmObjectGroupFlow: ResolverTypeWrapper<EqmObjectGroupFlow>;
  EqmObjectGroupFlowConnection: ResolverTypeWrapper<EqmObjectGroupFlowConnection>;
  EqmObjectGroupFlowConnectionEdge: ResolverTypeWrapper<EqmObjectGroupFlowConnectionEdge>;
  EqmObjectGroupFlowDeleteResponse: ResolverTypeWrapper<EqmObjectGroupFlowDeleteResponse>;
  EqmObjectGroupFlowManyResult: ResolverTypeWrapper<EqmObjectGroupFlowManyResult>;
  EqmObjectServiceInterval: ResolverTypeWrapper<EqmObjectServiceInterval>;
  EqmObjectServiceIntervalConnection: ResolverTypeWrapper<EqmObjectServiceIntervalConnection>;
  EqmObjectServiceIntervalConnectionEdge: ResolverTypeWrapper<EqmObjectServiceIntervalConnectionEdge>;
  EqmObjectServiceIntervalDeleteResponse: ResolverTypeWrapper<EqmObjectServiceIntervalDeleteResponse>;
  EqmObjectServiceIntervalManyResult: ResolverTypeWrapper<EqmObjectServiceIntervalManyResult>;
  EqmObjectSrvIntervalAgg: ResolverTypeWrapper<EqmObjectSrvIntervalAgg>;
  EqmObjectSrvIntervalAggConnection: ResolverTypeWrapper<EqmObjectSrvIntervalAggConnection>;
  EqmObjectSrvIntervalAggConnectionEdge: ResolverTypeWrapper<EqmObjectSrvIntervalAggConnectionEdge>;
  EqmObjectSrvIntervalAggDeleteResponse: ResolverTypeWrapper<EqmObjectSrvIntervalAggDeleteResponse>;
  EqmObjectSrvIntervalAggManyResult: ResolverTypeWrapper<EqmObjectSrvIntervalAggManyResult>;
  EqmObjectStatus: ResolverTypeWrapper<EqmObjectStatus>;
  EqmObjectStatusConnection: ResolverTypeWrapper<EqmObjectStatusConnection>;
  EqmObjectStatusConnectionEdge: ResolverTypeWrapper<EqmObjectStatusConnectionEdge>;
  EqmObjectStatusDeleteResponse: ResolverTypeWrapper<EqmObjectStatusDeleteResponse>;
  EqmObjectStatusManyResult: ResolverTypeWrapper<EqmObjectStatusManyResult>;
  EqmObjectTypeCard: ResolverTypeWrapper<EqmObjectTypeCard>;
  EqmObjectTypeCardConnection: ResolverTypeWrapper<EqmObjectTypeCardConnection>;
  EqmObjectTypeCardConnectionEdge: ResolverTypeWrapper<EqmObjectTypeCardConnectionEdge>;
  EqmObjectTypeCardDeleteResponse: ResolverTypeWrapper<EqmObjectTypeCardDeleteResponse>;
  EqmObjectTypeCardManyResult: ResolverTypeWrapper<EqmObjectTypeCardManyResult>;
  EqmObjectTypeComponents: ResolverTypeWrapper<EqmObjectTypeComponents>;
  EqmObjectTypeComponentsConnection: ResolverTypeWrapper<EqmObjectTypeComponentsConnection>;
  EqmObjectTypeComponentsConnectionEdge: ResolverTypeWrapper<EqmObjectTypeComponentsConnectionEdge>;
  EqmObjectTypeComponentsDeleteResponse: ResolverTypeWrapper<EqmObjectTypeComponentsDeleteResponse>;
  EqmObjectTypeComponentsManyResult: ResolverTypeWrapper<EqmObjectTypeComponentsManyResult>;
  EqmObjectTypeFlow: ResolverTypeWrapper<EqmObjectTypeFlow>;
  EqmObjectTypeFlowConnection: ResolverTypeWrapper<EqmObjectTypeFlowConnection>;
  EqmObjectTypeFlowConnectionEdge: ResolverTypeWrapper<EqmObjectTypeFlowConnectionEdge>;
  EqmObjectTypeFlowDeleteResponse: ResolverTypeWrapper<EqmObjectTypeFlowDeleteResponse>;
  EqmObjectTypeFlowManyResult: ResolverTypeWrapper<EqmObjectTypeFlowManyResult>;
  EqmObjectTypePriceTerm: ResolverTypeWrapper<EqmObjectTypePriceTerm>;
  EqmObjectTypePriceTermConnection: ResolverTypeWrapper<EqmObjectTypePriceTermConnection>;
  EqmObjectTypePriceTermConnectionEdge: ResolverTypeWrapper<EqmObjectTypePriceTermConnectionEdge>;
  EqmObjectTypePriceTermDeleteResponse: ResolverTypeWrapper<EqmObjectTypePriceTermDeleteResponse>;
  EqmObjectTypePriceTermManyResult: ResolverTypeWrapper<EqmObjectTypePriceTermManyResult>;
  EqmObjectTypeServiceInterval: ResolverTypeWrapper<EqmObjectTypeServiceInterval>;
  EqmObjectTypeServiceIntervalConnection: ResolverTypeWrapper<EqmObjectTypeServiceIntervalConnection>;
  EqmObjectTypeServiceIntervalConnectionEdge: ResolverTypeWrapper<EqmObjectTypeServiceIntervalConnectionEdge>;
  EqmObjectTypeServiceIntervalDeleteResponse: ResolverTypeWrapper<EqmObjectTypeServiceIntervalDeleteResponse>;
  EqmObjectTypeServiceIntervalManyResult: ResolverTypeWrapper<EqmObjectTypeServiceIntervalManyResult>;
  EqmReRentCard: ResolverTypeWrapper<EqmReRentCard>;
  EqmReRentCardConnection: ResolverTypeWrapper<EqmReRentCardConnection>;
  EqmReRentCardConnectionEdge: ResolverTypeWrapper<EqmReRentCardConnectionEdge>;
  EqmReRentCardDeleteResponse: ResolverTypeWrapper<EqmReRentCardDeleteResponse>;
  EqmReRentCardManyResult: ResolverTypeWrapper<EqmReRentCardManyResult>;
  EqmRentalGroup: ResolverTypeWrapper<EqmRentalGroup>;
  EqmRentalGroupConnection: ResolverTypeWrapper<EqmRentalGroupConnection>;
  EqmRentalGroupConnectionEdge: ResolverTypeWrapper<EqmRentalGroupConnectionEdge>;
  EqmRentalGroupDeleteResponse: ResolverTypeWrapper<EqmRentalGroupDeleteResponse>;
  EqmRentalGroupManyResult: ResolverTypeWrapper<EqmRentalGroupManyResult>;
  EqmRentalKIT: ResolverTypeWrapper<EqmRentalKit>;
  EqmRentalKITComponents: ResolverTypeWrapper<EqmRentalKitComponents>;
  EqmRentalKITComponentsConnection: ResolverTypeWrapper<EqmRentalKitComponentsConnection>;
  EqmRentalKITComponentsConnectionEdge: ResolverTypeWrapper<EqmRentalKitComponentsConnectionEdge>;
  EqmRentalKITComponentsDeleteResponse: ResolverTypeWrapper<EqmRentalKitComponentsDeleteResponse>;
  EqmRentalKITComponentsManyResult: ResolverTypeWrapper<EqmRentalKitComponentsManyResult>;
  EqmRentalKITConnection: ResolverTypeWrapper<EqmRentalKitConnection>;
  EqmRentalKITConnectionEdge: ResolverTypeWrapper<EqmRentalKitConnectionEdge>;
  EqmRentalKITDeleteResponse: ResolverTypeWrapper<EqmRentalKitDeleteResponse>;
  EqmRentalKITLines: ResolverTypeWrapper<EqmRentalKitLines>;
  EqmRentalKITLinesConnection: ResolverTypeWrapper<EqmRentalKitLinesConnection>;
  EqmRentalKITLinesConnectionEdge: ResolverTypeWrapper<EqmRentalKitLinesConnectionEdge>;
  EqmRentalKITLinesDeleteResponse: ResolverTypeWrapper<EqmRentalKitLinesDeleteResponse>;
  EqmRentalKITLinesManyResult: ResolverTypeWrapper<EqmRentalKitLinesManyResult>;
  EqmRentalKITManyResult: ResolverTypeWrapper<EqmRentalKitManyResult>;
  EqmRentalLineDiscount: ResolverTypeWrapper<EqmRentalLineDiscount>;
  EqmRentalLineDiscountConnection: ResolverTypeWrapper<EqmRentalLineDiscountConnection>;
  EqmRentalLineDiscountConnectionEdge: ResolverTypeWrapper<EqmRentalLineDiscountConnectionEdge>;
  EqmRentalLineDiscountDeleteResponse: ResolverTypeWrapper<EqmRentalLineDiscountDeleteResponse>;
  EqmRentalLineDiscountManyResult: ResolverTypeWrapper<EqmRentalLineDiscountManyResult>;
  EqmRentalPrice: ResolverTypeWrapper<EqmRentalPrice>;
  EqmRentalPriceConnection: ResolverTypeWrapper<EqmRentalPriceConnection>;
  EqmRentalPriceConnectionEdge: ResolverTypeWrapper<EqmRentalPriceConnectionEdge>;
  EqmRentalPriceDeleteResponse: ResolverTypeWrapper<EqmRentalPriceDeleteResponse>;
  EqmRentalPriceManyResult: ResolverTypeWrapper<EqmRentalPriceManyResult>;
  EqmReturnCharges: ResolverTypeWrapper<EqmReturnCharges>;
  EqmReturnChargesConnection: ResolverTypeWrapper<EqmReturnChargesConnection>;
  EqmReturnChargesConnectionEdge: ResolverTypeWrapper<EqmReturnChargesConnectionEdge>;
  EqmReturnChargesDeleteResponse: ResolverTypeWrapper<EqmReturnChargesDeleteResponse>;
  EqmReturnChargesManyResult: ResolverTypeWrapper<EqmReturnChargesManyResult>;
  EqmServiceCard: ResolverTypeWrapper<EqmServiceCard>;
  EqmServiceCardConnection: ResolverTypeWrapper<EqmServiceCardConnection>;
  EqmServiceCardConnectionEdge: ResolverTypeWrapper<EqmServiceCardConnectionEdge>;
  EqmServiceCardDeleteResponse: ResolverTypeWrapper<EqmServiceCardDeleteResponse>;
  EqmServiceCardManyResult: ResolverTypeWrapper<EqmServiceCardManyResult>;
  EqmServiceType: ResolverTypeWrapper<EqmServiceType>;
  EqmServiceTypeConnection: ResolverTypeWrapper<EqmServiceTypeConnection>;
  EqmServiceTypeConnectionEdge: ResolverTypeWrapper<EqmServiceTypeConnectionEdge>;
  EqmServiceTypeDeleteResponse: ResolverTypeWrapper<EqmServiceTypeDeleteResponse>;
  EqmServiceTypeManyResult: ResolverTypeWrapper<EqmServiceTypeManyResult>;
  EqmTransferHeader: ResolverTypeWrapper<EqmTransferHeader>;
  EqmTransferHeaderConnection: ResolverTypeWrapper<EqmTransferHeaderConnection>;
  EqmTransferHeaderConnectionEdge: ResolverTypeWrapper<EqmTransferHeaderConnectionEdge>;
  EqmTransferHeaderDeleteResponse: ResolverTypeWrapper<EqmTransferHeaderDeleteResponse>;
  EqmTransferHeaderManyResult: ResolverTypeWrapper<EqmTransferHeaderManyResult>;
  EqmTransferLine: ResolverTypeWrapper<EqmTransferLine>;
  EqmTransferLineConnection: ResolverTypeWrapper<EqmTransferLineConnection>;
  EqmTransferLineConnectionEdge: ResolverTypeWrapper<EqmTransferLineConnectionEdge>;
  EqmTransferLineDeleteResponse: ResolverTypeWrapper<EqmTransferLineDeleteResponse>;
  EqmTransferLineManyResult: ResolverTypeWrapper<EqmTransferLineManyResult>;
  EqmTypeFunctionalTest: ResolverTypeWrapper<EqmTypeFunctionalTest>;
  EqmTypeFunctionalTestConnection: ResolverTypeWrapper<EqmTypeFunctionalTestConnection>;
  EqmTypeFunctionalTestConnectionEdge: ResolverTypeWrapper<EqmTypeFunctionalTestConnectionEdge>;
  EqmTypeFunctionalTestDeleteResponse: ResolverTypeWrapper<EqmTypeFunctionalTestDeleteResponse>;
  EqmTypeFunctionalTestManyResult: ResolverTypeWrapper<EqmTypeFunctionalTestManyResult>;
  EqmWorkHeader: ResolverTypeWrapper<EqmWorkHeader>;
  EqmWorkHeaderConnection: ResolverTypeWrapper<EqmWorkHeaderConnection>;
  EqmWorkHeaderConnectionEdge: ResolverTypeWrapper<EqmWorkHeaderConnectionEdge>;
  EqmWorkHeaderDeleteResponse: ResolverTypeWrapper<EqmWorkHeaderDeleteResponse>;
  EqmWorkHeaderManyResult: ResolverTypeWrapper<EqmWorkHeaderManyResult>;
  EqmWorkLine: ResolverTypeWrapper<EqmWorkLine>;
  EqmWorkLineConnection: ResolverTypeWrapper<EqmWorkLineConnection>;
  EqmWorkLineConnectionEdge: ResolverTypeWrapper<EqmWorkLineConnectionEdge>;
  EqmWorkLineDeleteResponse: ResolverTypeWrapper<EqmWorkLineDeleteResponse>;
  EqmWorkLineManyResult: ResolverTypeWrapper<EqmWorkLineManyResult>;
  Errand: ResolverTypeWrapper<Errand>;
  ErrandConnection: ResolverTypeWrapper<ErrandConnection>;
  ErrandConnectionEdge: ResolverTypeWrapper<ErrandConnectionEdge>;
  ErrandDeleteResponse: ResolverTypeWrapper<ErrandDeleteResponse>;
  ErrandLine: ResolverTypeWrapper<ErrandLine>;
  ErrandLineConnection: ResolverTypeWrapper<ErrandLineConnection>;
  ErrandLineConnectionEdge: ResolverTypeWrapper<ErrandLineConnectionEdge>;
  ErrandLineDeleteResponse: ResolverTypeWrapper<ErrandLineDeleteResponse>;
  ErrandLineManyResult: ResolverTypeWrapper<ErrandLineManyResult>;
  ErrandLineTypeEnum: ErrandLineTypeEnum;
  ErrandManyResult: ResolverTypeWrapper<ErrandManyResult>;
  ErrandStatusEnum: ErrandStatusEnum;
  ErrandTypeEnum: ErrandTypeEnum;
  ErrorReport: ResolverTypeWrapper<ErrorReport>;
  ErrorReportConnection: ResolverTypeWrapper<ErrorReportConnection>;
  ErrorReportConnectionEdge: ResolverTypeWrapper<ErrorReportConnectionEdge>;
  ErrorReportDeleteResponse: ResolverTypeWrapper<ErrorReportDeleteResponse>;
  ErrorReportManyResult: ResolverTypeWrapper<ErrorReportManyResult>;
  Feedback: ResolverTypeWrapper<Feedback>;
  FeedbackConnection: ResolverTypeWrapper<FeedbackConnection>;
  FeedbackConnectionEdge: ResolverTypeWrapper<FeedbackConnectionEdge>;
  FeedbackDeleteResponse: ResolverTypeWrapper<FeedbackDeleteResponse>;
  FeedbackManyResult: ResolverTypeWrapper<FeedbackManyResult>;
  Feemapping: ResolverTypeWrapper<Feemapping>;
  FeemappingConnection: ResolverTypeWrapper<FeemappingConnection>;
  FeemappingConnectionEdge: ResolverTypeWrapper<FeemappingConnectionEdge>;
  FeemappingDeleteResponse: ResolverTypeWrapper<FeemappingDeleteResponse>;
  FeemappingManyResult: ResolverTypeWrapper<FeemappingManyResult>;
  FeemappingPriceTypeEnum: FeemappingPriceTypeEnum;
  FeemappingTypeEnum: FeemappingTypeEnum;
  FeemappingTypeOfFeeEnum: FeemappingTypeOfFeeEnum;
  FieldOrder: ResolverTypeWrapper<FieldOrder>;
  FieldOrderAssignmentTypeEnum: FieldOrderAssignmentTypeEnum;
  FieldOrderConnection: ResolverTypeWrapper<FieldOrderConnection>;
  FieldOrderConnectionEdge: ResolverTypeWrapper<FieldOrderConnectionEdge>;
  FieldOrderDeleteResponse: ResolverTypeWrapper<FieldOrderDeleteResponse>;
  FieldOrderLine: ResolverTypeWrapper<FieldOrderLine>;
  FieldOrderLineConnection: ResolverTypeWrapper<FieldOrderLineConnection>;
  FieldOrderLineConnectionEdge: ResolverTypeWrapper<FieldOrderLineConnectionEdge>;
  FieldOrderLineDeleteResponse: ResolverTypeWrapper<FieldOrderLineDeleteResponse>;
  FieldOrderLineManyResult: ResolverTypeWrapper<FieldOrderLineManyResult>;
  FieldOrderLineTypeEnum: FieldOrderLineTypeEnum;
  FieldOrderManyResult: ResolverTypeWrapper<FieldOrderManyResult>;
  FieldOrderStatusEnum: FieldOrderStatusEnum;
  File: ResolverTypeWrapper<File>;
  FileConnection: ResolverTypeWrapper<FileConnection>;
  FileConnectionEdge: ResolverTypeWrapper<FileConnectionEdge>;
  FileDeleteResponse: ResolverTypeWrapper<FileDeleteResponse>;
  FileManyResult: ResolverTypeWrapper<FileManyResult>;
  FilterAccessRequestInput: FilterAccessRequestInput;
  FilterAdditionalItemInput: FilterAdditionalItemInput;
  FilterAddressInput: FilterAddressInput;
  FilterAgreementInput: FilterAgreementInput;
  FilterAnnotationInput: FilterAnnotationInput;
  FilterApplicationModuleInput: FilterApplicationModuleInput;
  FilterBcMappingFieldInput: FilterBcMappingFieldInput;
  FilterBcMappingTableInput: FilterBcMappingTableInput;
  FilterBcSettingsInput: FilterBcSettingsInput;
  FilterBulkItemGroupInput: FilterBulkItemGroupInput;
  FilterCauseOfAbsenceInput: FilterCauseOfAbsenceInput;
  FilterCompanyInput: FilterCompanyInput;
  FilterConsignmentHeaderInput: FilterConsignmentHeaderInput;
  FilterConsignmentLineInput: FilterConsignmentLineInput;
  FilterContactInput: FilterContactInput;
  FilterCountryRegionInput: FilterCountryRegionInput;
  FilterCurrencyExchangeRateInput: FilterCurrencyExchangeRateInput;
  FilterCurrencyInput: FilterCurrencyInput;
  FilterCustomerDomainInput: FilterCustomerDomainInput;
  FilterCustomerPostingGroupInput: FilterCustomerPostingGroupInput;
  FilterCustomerProjectInput: FilterCustomerProjectInput;
  FilterCustomerSubProjectInput: FilterCustomerSubProjectInput;
  FilterDeliverytimeInput: FilterDeliverytimeInput;
  FilterDevopsProjectInput: FilterDevopsProjectInput;
  FilterDevopsTeamInput: FilterDevopsTeamInput;
  FilterDevopsWorkItemInput: FilterDevopsWorkItemInput;
  FilterDimensionInput: FilterDimensionInput;
  FilterDimensionValueInput: FilterDimensionValueInput;
  FilterDiscussionPostInput: FilterDiscussionPostInput;
  FilterDocumentClassificationInput: FilterDocumentClassificationInput;
  FilterDocumentInput: FilterDocumentInput;
  FilterDocumentRequestInput: FilterDocumentRequestInput;
  FilterDomainInput: FilterDomainInput;
  FilterDomainIntegrationInput: FilterDomainIntegrationInput;
  FilterDomainSettingsInput: FilterDomainSettingsInput;
  FilterEqmBaseCalendarChangeInput: FilterEqmBaseCalendarChangeInput;
  FilterEqmBaseCalendarInput: FilterEqmBaseCalendarInput;
  FilterEqmCollectionReturnChargesInput: FilterEqmCollectionReturnChargesInput;
  FilterEqmCommentLineInput: FilterEqmCommentLineInput;
  FilterEqmCustomizedCalendarChangeInput: FilterEqmCustomizedCalendarChangeInput;
  FilterEqmFncTestReturnChargesInput: FilterEqmFncTestReturnChargesInput;
  FilterEqmLocCostCenterCombInput: FilterEqmLocCostCenterCombInput;
  FilterEqmManufacturerInput: FilterEqmManufacturerInput;
  FilterEqmManufacturerModelInput: FilterEqmManufacturerModelInput;
  FilterEqmObjectCardFlowInput: FilterEqmObjectCardFlowInput;
  FilterEqmObjectComponentsInput: FilterEqmObjectComponentsInput;
  FilterEqmObjectCountHeaderInput: FilterEqmObjectCountHeaderInput;
  FilterEqmObjectCountLineInput: FilterEqmObjectCountLineInput;
  FilterEqmObjectGroupCardInput: FilterEqmObjectGroupCardInput;
  FilterEqmObjectGroupFlowInput: FilterEqmObjectGroupFlowInput;
  FilterEqmObjectServiceIntervalInput: FilterEqmObjectServiceIntervalInput;
  FilterEqmObjectSrvIntervalAggInput: FilterEqmObjectSrvIntervalAggInput;
  FilterEqmObjectStatusInput: FilterEqmObjectStatusInput;
  FilterEqmObjectTypeCardInput: FilterEqmObjectTypeCardInput;
  FilterEqmObjectTypeComponentsInput: FilterEqmObjectTypeComponentsInput;
  FilterEqmObjectTypeFlowInput: FilterEqmObjectTypeFlowInput;
  FilterEqmObjectTypePriceTermInput: FilterEqmObjectTypePriceTermInput;
  FilterEqmObjectTypeServiceIntervalInput: FilterEqmObjectTypeServiceIntervalInput;
  FilterEqmReRentCardInput: FilterEqmReRentCardInput;
  FilterEqmRentalGroupInput: FilterEqmRentalGroupInput;
  FilterEqmRentalKITComponentsInput: FilterEqmRentalKitComponentsInput;
  FilterEqmRentalKITInput: FilterEqmRentalKitInput;
  FilterEqmRentalKITLinesInput: FilterEqmRentalKitLinesInput;
  FilterEqmRentalLineDiscountInput: FilterEqmRentalLineDiscountInput;
  FilterEqmRentalPriceInput: FilterEqmRentalPriceInput;
  FilterEqmReturnChargesInput: FilterEqmReturnChargesInput;
  FilterEqmServiceCardInput: FilterEqmServiceCardInput;
  FilterEqmServiceTypeInput: FilterEqmServiceTypeInput;
  FilterEqmTransferHeaderInput: FilterEqmTransferHeaderInput;
  FilterEqmTransferLineInput: FilterEqmTransferLineInput;
  FilterEqmTypeFunctionalTestInput: FilterEqmTypeFunctionalTestInput;
  FilterEqmWorkHeaderInput: FilterEqmWorkHeaderInput;
  FilterEqmWorkLineInput: FilterEqmWorkLineInput;
  FilterErrandInput: FilterErrandInput;
  FilterErrandLineInput: FilterErrandLineInput;
  FilterErrorReportInput: FilterErrorReportInput;
  FilterFeedbackInput: FilterFeedbackInput;
  FilterFeemappingInput: FilterFeemappingInput;
  FilterFieldOrderInput: FilterFieldOrderInput;
  FilterFieldOrderLineInput: FilterFieldOrderLineInput;
  FilterFileInput: FilterFileInput;
  FilterFunctionTestHeaderInput: FilterFunctionTestHeaderInput;
  FilterFunctionTestLineInput: FilterFunctionTestLineInput;
  FilterGenBusinessPostingGroupInput: FilterGenBusinessPostingGroupInput;
  FilterGenJournalLineInput: FilterGenJournalLineInput;
  FilterGenProductPostingGroupInput: FilterGenProductPostingGroupInput;
  FilterInspectionInput: FilterInspectionInput;
  FilterInspectionUserInput: FilterInspectionUserInput;
  FilterIntegrationCollectionInput: FilterIntegrationCollectionInput;
  FilterInventoryPostingGroupInput: FilterInventoryPostingGroupInput;
  FilterInvitationInput: FilterInvitationInput;
  FilterInvoiceHeaderInput: FilterInvoiceHeaderInput;
  FilterInvoiceLineInput: FilterInvoiceLineInput;
  FilterItemCategoryInput: FilterItemCategoryInput;
  FilterItemDiscGroupInput: FilterItemDiscGroupInput;
  FilterItemInput: FilterItemInput;
  FilterItemReferenceInput: FilterItemReferenceInput;
  FilterItemRestrictionInput: FilterItemRestrictionInput;
  FilterItemUnitOfMeasureInput: FilterItemUnitOfMeasureInput;
  FilterItemVendorInput: FilterItemVendorInput;
  FilterJobTaskInput: FilterJobTaskInput;
  FilterKliHeaderInput: FilterKliHeaderInput;
  FilterKliReasonCodeInput: FilterKliReasonCodeInput;
  FilterLeadInput: FilterLeadInput;
  FilterLocationInput: FilterLocationInput;
  FilterMagentoOrderStatusInput: FilterMagentoOrderStatusInput;
  FilterMagentoSettingsInput: FilterMagentoSettingsInput;
  FilterManufacturerInput: FilterManufacturerInput;
  FilterNavUserInput: FilterNavUserInput;
  FilterObjectCommentLineInput: FilterObjectCommentLineInput;
  FilterObjectFamilyInput: FilterObjectFamilyInput;
  FilterObjectGroupInput: FilterObjectGroupInput;
  FilterObjectInput: FilterObjectInput;
  FilterObjectReturnHeaderInput: FilterObjectReturnHeaderInput;
  FilterObjectReturnLineInput: FilterObjectReturnLineInput;
  FilterObjectTypeInput: FilterObjectTypeInput;
  FilterOcrAnalysisInput: FilterOcrAnalysisInput;
  FilterPaymentMethodInput: FilterPaymentMethodInput;
  FilterPaymentTermsInput: FilterPaymentTermsInput;
  FilterPlatformFeatureInput: FilterPlatformFeatureInput;
  FilterPostCodeInput: FilterPostCodeInput;
  FilterProjectInput: FilterProjectInput;
  FilterPurchCrMemoHeaderInput: FilterPurchCrMemoHeaderInput;
  FilterPurchCrMemoLineInput: FilterPurchCrMemoLineInput;
  FilterPurchInvHeaderInput: FilterPurchInvHeaderInput;
  FilterPurchInvLineInput: FilterPurchInvLineInput;
  FilterPurchRcptHeaderInput: FilterPurchRcptHeaderInput;
  FilterPurchRcptLineInput: FilterPurchRcptLineInput;
  FilterPurchaseHeaderInput: FilterPurchaseHeaderInput;
  FilterPurchaseLineInput: FilterPurchaseLineInput;
  FilterPurchasingInput: FilterPurchasingInput;
  FilterQuoteHeaderInput: FilterQuoteHeaderInput;
  FilterQuoteLineInput: FilterQuoteLineInput;
  FilterQuoteTaskGroupInput: FilterQuoteTaskGroupInput;
  FilterRentalHeaderInput: FilterRentalHeaderInput;
  FilterRentalLineInput: FilterRentalLineInput;
  FilterRentalLineSnapshotInput: FilterRentalLineSnapshotInput;
  FilterRentalPostedCollHeaderInput: FilterRentalPostedCollHeaderInput;
  FilterRentalPostedCollLineInput: FilterRentalPostedCollLineInput;
  FilterRentalPostedShptHeaderInput: FilterRentalPostedShptHeaderInput;
  FilterRentalPostedShptLineInput: FilterRentalPostedShptLineInput;
  FilterRentalPriceTermsInput: FilterRentalPriceTermsInput;
  FilterRentalReturnEntryInput: FilterRentalReturnEntryInput;
  FilterRequestHeaderInput: FilterRequestHeaderInput;
  FilterRequestLineInput: FilterRequestLineInput;
  FilterReservationRequestHeaderInput: FilterReservationRequestHeaderInput;
  FilterReservationRequestLineInput: FilterReservationRequestLineInput;
  FilterResourceInput: FilterResourceInput;
  FilterResponsibilityCenterInput: FilterResponsibilityCenterInput;
  FilterReturnRequestHeaderInput: FilterReturnRequestHeaderInput;
  FilterReturnRequestLineInput: FilterReturnRequestLineInput;
  FilterRiskAnalysisHeaderInput: FilterRiskAnalysisHeaderInput;
  FilterRiskAnalysisLineInput: FilterRiskAnalysisLineInput;
  FilterRiskAnalysisSignatureInput: FilterRiskAnalysisSignatureInput;
  FilterSalesHeaderArchiveInput: FilterSalesHeaderArchiveInput;
  FilterSalesHeaderHistoryInput: FilterSalesHeaderHistoryInput;
  FilterSalesHeaderInput: FilterSalesHeaderInput;
  FilterSalesHeaderRequestInput: FilterSalesHeaderRequestInput;
  FilterSalesLineArchiveInput: FilterSalesLineArchiveInput;
  FilterSalesLineInput: FilterSalesLineInput;
  FilterSalesLineRequestInput: FilterSalesLineRequestInput;
  FilterSalesPriceInput: FilterSalesPriceInput;
  FilterSalesShipmentHeaderInput: FilterSalesShipmentHeaderInput;
  FilterSalesShipmentLineInput: FilterSalesShipmentLineInput;
  FilterSalespersonInput: FilterSalespersonInput;
  FilterServiceItemGroupInput: FilterServiceItemGroupInput;
  FilterShipmentMethodInput: FilterShipmentMethodInput;
  FilterShippingAgentInput: FilterShippingAgentInput;
  FilterSignRequestInput: FilterSignRequestInput;
  FilterSignatureInput: FilterSignatureInput;
  FilterSpecialEquipmentInput: FilterSpecialEquipmentInput;
  FilterStockkeepingUnitInput: FilterStockkeepingUnitInput;
  FilterTariffNumberInput: FilterTariffNumberInput;
  FilterTaxGroupInput: FilterTaxGroupInput;
  FilterTimeRegistrationInput: FilterTimeRegistrationInput;
  FilterTimeSheetDetailInput: FilterTimeSheetDetailInput;
  FilterTimeSheetLineInput: FilterTimeSheetLineInput;
  FilterTransactionSpecificationInput: FilterTransactionSpecificationInput;
  FilterTransactionTypeInput: FilterTransactionTypeInput;
  FilterTransportMethodInput: FilterTransportMethodInput;
  FilterUnitOfMeasureInput: FilterUnitOfMeasureInput;
  FilterUserFavoritesInput: FilterUserFavoritesInput;
  FilterUserInput: FilterUserInput;
  FilterVatBusinessPostingGroupInput: FilterVatBusinessPostingGroupInput;
  FilterVatProductPostingGroupInput: FilterVatProductPostingGroupInput;
  FilterVendorBankAccountInput: FilterVendorBankAccountInput;
  FilterVendorInput: FilterVendorInput;
  FilterVendorPostingGroupInput: FilterVendorPostingGroupInput;
  FilterWarehouseClassInput: FilterWarehouseClassInput;
  FilterWebBookingInput: FilterWebBookingInput;
  FilterWebhookSubscriptionInput: FilterWebhookSubscriptionInput;
  FilterWorkLogInput: FilterWorkLogInput;
  FilterWorkTypeInput: FilterWorkTypeInput;
  FunctionTestHeader: ResolverTypeWrapper<FunctionTestHeader>;
  FunctionTestHeaderApprovalStatusEnum: FunctionTestHeaderApprovalStatusEnum;
  FunctionTestHeaderConnection: ResolverTypeWrapper<FunctionTestHeaderConnection>;
  FunctionTestHeaderConnectionEdge: ResolverTypeWrapper<FunctionTestHeaderConnectionEdge>;
  FunctionTestHeaderDeleteResponse: ResolverTypeWrapper<FunctionTestHeaderDeleteResponse>;
  FunctionTestHeaderManyResult: ResolverTypeWrapper<FunctionTestHeaderManyResult>;
  FunctionTestHeaderStatusEnum: FunctionTestHeaderStatusEnum;
  FunctionTestLine: ResolverTypeWrapper<FunctionTestLine>;
  FunctionTestLineConnection: ResolverTypeWrapper<FunctionTestLineConnection>;
  FunctionTestLineConnectionEdge: ResolverTypeWrapper<FunctionTestLineConnectionEdge>;
  FunctionTestLineDeleteResponse: ResolverTypeWrapper<FunctionTestLineDeleteResponse>;
  FunctionTestLineManyResult: ResolverTypeWrapper<FunctionTestLineManyResult>;
  GenBusinessPostingGroup: ResolverTypeWrapper<GenBusinessPostingGroup>;
  GenBusinessPostingGroupConnection: ResolverTypeWrapper<GenBusinessPostingGroupConnection>;
  GenBusinessPostingGroupConnectionEdge: ResolverTypeWrapper<GenBusinessPostingGroupConnectionEdge>;
  GenBusinessPostingGroupDeleteResponse: ResolverTypeWrapper<GenBusinessPostingGroupDeleteResponse>;
  GenBusinessPostingGroupManyResult: ResolverTypeWrapper<GenBusinessPostingGroupManyResult>;
  GenJournalLine: ResolverTypeWrapper<GenJournalLine>;
  GenJournalLineConnection: ResolverTypeWrapper<GenJournalLineConnection>;
  GenJournalLineConnectionEdge: ResolverTypeWrapper<GenJournalLineConnectionEdge>;
  GenJournalLineDeleteResponse: ResolverTypeWrapper<GenJournalLineDeleteResponse>;
  GenJournalLineManyResult: ResolverTypeWrapper<GenJournalLineManyResult>;
  GenProductPostingGroup: ResolverTypeWrapper<GenProductPostingGroup>;
  GenProductPostingGroupConnection: ResolverTypeWrapper<GenProductPostingGroupConnection>;
  GenProductPostingGroupConnectionEdge: ResolverTypeWrapper<GenProductPostingGroupConnectionEdge>;
  GenProductPostingGroupDeleteResponse: ResolverTypeWrapper<GenProductPostingGroupDeleteResponse>;
  GenProductPostingGroupManyResult: ResolverTypeWrapper<GenProductPostingGroupManyResult>;
  IOSAppCode: ResolverTypeWrapper<IosAppCode>;
  Inspection: ResolverTypeWrapper<Inspection>;
  InspectionConnection: ResolverTypeWrapper<InspectionConnection>;
  InspectionConnectionEdge: ResolverTypeWrapper<InspectionConnectionEdge>;
  InspectionDeleteResponse: ResolverTypeWrapper<InspectionDeleteResponse>;
  InspectionManyResult: ResolverTypeWrapper<InspectionManyResult>;
  InspectionUser: ResolverTypeWrapper<InspectionUser>;
  InspectionUserConnection: ResolverTypeWrapper<InspectionUserConnection>;
  InspectionUserConnectionEdge: ResolverTypeWrapper<InspectionUserConnectionEdge>;
  InspectionUserDeleteResponse: ResolverTypeWrapper<InspectionUserDeleteResponse>;
  InspectionUserManyResult: ResolverTypeWrapper<InspectionUserManyResult>;
  IntegrationCollection: ResolverTypeWrapper<IntegrationCollection>;
  IntegrationCollectionConnection: ResolverTypeWrapper<IntegrationCollectionConnection>;
  IntegrationCollectionConnectionEdge: ResolverTypeWrapper<IntegrationCollectionConnectionEdge>;
  IntegrationCollectionDeleteResponse: ResolverTypeWrapper<IntegrationCollectionDeleteResponse>;
  IntegrationCollectionManyResult: ResolverTypeWrapper<IntegrationCollectionManyResult>;
  InventoryPostingGroup: ResolverTypeWrapper<InventoryPostingGroup>;
  InventoryPostingGroupConnection: ResolverTypeWrapper<InventoryPostingGroupConnection>;
  InventoryPostingGroupConnectionEdge: ResolverTypeWrapper<InventoryPostingGroupConnectionEdge>;
  InventoryPostingGroupDeleteResponse: ResolverTypeWrapper<InventoryPostingGroupDeleteResponse>;
  InventoryPostingGroupManyResult: ResolverTypeWrapper<InventoryPostingGroupManyResult>;
  Invitation: ResolverTypeWrapper<Invitation>;
  InvitationConnection: ResolverTypeWrapper<InvitationConnection>;
  InvitationConnectionEdge: ResolverTypeWrapper<InvitationConnectionEdge>;
  InvitationDeleteResponse: ResolverTypeWrapper<InvitationDeleteResponse>;
  InvitationInputRoleEnum: InvitationInputRoleEnum;
  InvitationLimited: ResolverTypeWrapper<InvitationLimited>;
  InvitationManyResult: ResolverTypeWrapper<InvitationManyResult>;
  InvitationStatusEnum: InvitationStatusEnum;
  InvitationTypeEnum: InvitationTypeEnum;
  InvitationUpdateInput: InvitationUpdateInput;
  InvoiceHeader: ResolverTypeWrapper<InvoiceHeader>;
  InvoiceHeaderConnection: ResolverTypeWrapper<InvoiceHeaderConnection>;
  InvoiceHeaderConnectionEdge: ResolverTypeWrapper<InvoiceHeaderConnectionEdge>;
  InvoiceHeaderDeleteResponse: ResolverTypeWrapper<InvoiceHeaderDeleteResponse>;
  InvoiceHeaderManyResult: ResolverTypeWrapper<InvoiceHeaderManyResult>;
  InvoiceLine: ResolverTypeWrapper<InvoiceLine>;
  InvoiceLineConnection: ResolverTypeWrapper<InvoiceLineConnection>;
  InvoiceLineConnectionEdge: ResolverTypeWrapper<InvoiceLineConnectionEdge>;
  InvoiceLineDeleteResponse: ResolverTypeWrapper<InvoiceLineDeleteResponse>;
  InvoiceLineManyResult: ResolverTypeWrapper<InvoiceLineManyResult>;
  Item: ResolverTypeWrapper<Item>;
  ItemCategory: ResolverTypeWrapper<ItemCategory>;
  ItemCategoryConnection: ResolverTypeWrapper<ItemCategoryConnection>;
  ItemCategoryConnectionEdge: ResolverTypeWrapper<ItemCategoryConnectionEdge>;
  ItemCategoryDeleteResponse: ResolverTypeWrapper<ItemCategoryDeleteResponse>;
  ItemCategoryManyResult: ResolverTypeWrapper<ItemCategoryManyResult>;
  ItemConnection: ResolverTypeWrapper<ItemConnection>;
  ItemConnectionEdge: ResolverTypeWrapper<ItemConnectionEdge>;
  ItemDeleteResponse: ResolverTypeWrapper<ItemDeleteResponse>;
  ItemDiscGroup: ResolverTypeWrapper<ItemDiscGroup>;
  ItemDiscGroupConnection: ResolverTypeWrapper<ItemDiscGroupConnection>;
  ItemDiscGroupConnectionEdge: ResolverTypeWrapper<ItemDiscGroupConnectionEdge>;
  ItemDiscGroupDeleteResponse: ResolverTypeWrapper<ItemDiscGroupDeleteResponse>;
  ItemDiscGroupManyResult: ResolverTypeWrapper<ItemDiscGroupManyResult>;
  ItemItemTypeEnum: ItemItemTypeEnum;
  ItemManyResult: ResolverTypeWrapper<ItemManyResult>;
  ItemReference: ResolverTypeWrapper<ItemReference>;
  ItemReferenceConnection: ResolverTypeWrapper<ItemReferenceConnection>;
  ItemReferenceConnectionEdge: ResolverTypeWrapper<ItemReferenceConnectionEdge>;
  ItemReferenceDeleteResponse: ResolverTypeWrapper<ItemReferenceDeleteResponse>;
  ItemReferenceManyResult: ResolverTypeWrapper<ItemReferenceManyResult>;
  ItemRestriction: ResolverTypeWrapper<ItemRestriction>;
  ItemRestrictionConnection: ResolverTypeWrapper<ItemRestrictionConnection>;
  ItemRestrictionConnectionEdge: ResolverTypeWrapper<ItemRestrictionConnectionEdge>;
  ItemRestrictionDeleteResponse: ResolverTypeWrapper<ItemRestrictionDeleteResponse>;
  ItemRestrictionManyResult: ResolverTypeWrapper<ItemRestrictionManyResult>;
  ItemRestrictionTypeEnum: ItemRestrictionTypeEnum;
  ItemUnitOfMeasure: ResolverTypeWrapper<ItemUnitOfMeasure>;
  ItemUnitOfMeasureConnection: ResolverTypeWrapper<ItemUnitOfMeasureConnection>;
  ItemUnitOfMeasureConnectionEdge: ResolverTypeWrapper<ItemUnitOfMeasureConnectionEdge>;
  ItemUnitOfMeasureDeleteResponse: ResolverTypeWrapper<ItemUnitOfMeasureDeleteResponse>;
  ItemUnitOfMeasureManyResult: ResolverTypeWrapper<ItemUnitOfMeasureManyResult>;
  ItemVendor: ResolverTypeWrapper<ItemVendor>;
  ItemVendorConnection: ResolverTypeWrapper<ItemVendorConnection>;
  ItemVendorConnectionEdge: ResolverTypeWrapper<ItemVendorConnectionEdge>;
  ItemVendorDeleteResponse: ResolverTypeWrapper<ItemVendorDeleteResponse>;
  ItemVendorManyResult: ResolverTypeWrapper<ItemVendorManyResult>;
  JobTask: ResolverTypeWrapper<JobTask>;
  JobTaskConnection: ResolverTypeWrapper<JobTaskConnection>;
  JobTaskConnectionEdge: ResolverTypeWrapper<JobTaskConnectionEdge>;
  JobTaskDeleteResponse: ResolverTypeWrapper<JobTaskDeleteResponse>;
  JobTaskManyResult: ResolverTypeWrapper<JobTaskManyResult>;
  KliHeader: ResolverTypeWrapper<KliHeader>;
  KliHeaderConnection: ResolverTypeWrapper<KliHeaderConnection>;
  KliHeaderConnectionEdge: ResolverTypeWrapper<KliHeaderConnectionEdge>;
  KliHeaderDeleteResponse: ResolverTypeWrapper<KliHeaderDeleteResponse>;
  KliHeaderManyResult: ResolverTypeWrapper<KliHeaderManyResult>;
  KliReasonCode: ResolverTypeWrapper<KliReasonCode>;
  KliReasonCodeConnection: ResolverTypeWrapper<KliReasonCodeConnection>;
  KliReasonCodeConnectionEdge: ResolverTypeWrapper<KliReasonCodeConnectionEdge>;
  KliReasonCodeDeleteResponse: ResolverTypeWrapper<KliReasonCodeDeleteResponse>;
  KliReasonCodeManyResult: ResolverTypeWrapper<KliReasonCodeManyResult>;
  Lead: ResolverTypeWrapper<Lead>;
  LeadConnection: ResolverTypeWrapper<LeadConnection>;
  LeadConnectionEdge: ResolverTypeWrapper<LeadConnectionEdge>;
  LeadDeleteResponse: ResolverTypeWrapper<LeadDeleteResponse>;
  LeadManyResult: ResolverTypeWrapper<LeadManyResult>;
  LimitedCustomerProject: ResolverTypeWrapper<LimitedCustomerProject>;
  LimitedUpdateCustomerProjectInput: LimitedUpdateCustomerProjectInput;
  LimitedUpdateCustomerProjectResponse: ResolverTypeWrapper<LimitedUpdateCustomerProjectResponse>;
  LineAmount: ResolverTypeWrapper<LineAmount>;
  Location: ResolverTypeWrapper<Location>;
  LocationConnection: ResolverTypeWrapper<LocationConnection>;
  LocationConnectionEdge: ResolverTypeWrapper<LocationConnectionEdge>;
  LocationDeleteResponse: ResolverTypeWrapper<LocationDeleteResponse>;
  LocationManyResult: ResolverTypeWrapper<LocationManyResult>;
  MagentoOrderStatus: ResolverTypeWrapper<MagentoOrderStatus>;
  MagentoOrderStatusConnection: ResolverTypeWrapper<MagentoOrderStatusConnection>;
  MagentoOrderStatusConnectionEdge: ResolverTypeWrapper<MagentoOrderStatusConnectionEdge>;
  MagentoOrderStatusDeleteResponse: ResolverTypeWrapper<MagentoOrderStatusDeleteResponse>;
  MagentoOrderStatusManyResult: ResolverTypeWrapper<MagentoOrderStatusManyResult>;
  MagentoSettings: ResolverTypeWrapper<MagentoSettings>;
  MagentoSettingsConnection: ResolverTypeWrapper<MagentoSettingsConnection>;
  MagentoSettingsConnectionEdge: ResolverTypeWrapper<MagentoSettingsConnectionEdge>;
  MagentoSettingsDeleteResponse: ResolverTypeWrapper<MagentoSettingsDeleteResponse>;
  MagentoSettingsManyResult: ResolverTypeWrapper<MagentoSettingsManyResult>;
  Manufacturer: ResolverTypeWrapper<Manufacturer>;
  ManufacturerConnection: ResolverTypeWrapper<ManufacturerConnection>;
  ManufacturerConnectionEdge: ResolverTypeWrapper<ManufacturerConnectionEdge>;
  ManufacturerDeleteResponse: ResolverTypeWrapper<ManufacturerDeleteResponse>;
  ManufacturerManyResult: ResolverTypeWrapper<ManufacturerManyResult>;
  Mutation: ResolverTypeWrapper<{}>;
  NavUser: ResolverTypeWrapper<NavUser>;
  NavUserConnection: ResolverTypeWrapper<NavUserConnection>;
  NavUserConnectionEdge: ResolverTypeWrapper<NavUserConnectionEdge>;
  NavUserDeleteResponse: ResolverTypeWrapper<NavUserDeleteResponse>;
  NavUserManyResult: ResolverTypeWrapper<NavUserManyResult>;
  Object: ResolverTypeWrapper<Object>;
  ObjectCommentLine: ResolverTypeWrapper<ObjectCommentLine>;
  ObjectCommentLineConnection: ResolverTypeWrapper<ObjectCommentLineConnection>;
  ObjectCommentLineConnectionEdge: ResolverTypeWrapper<ObjectCommentLineConnectionEdge>;
  ObjectCommentLineDeleteResponse: ResolverTypeWrapper<ObjectCommentLineDeleteResponse>;
  ObjectCommentLineManyResult: ResolverTypeWrapper<ObjectCommentLineManyResult>;
  ObjectConnection: ResolverTypeWrapper<ObjectConnection>;
  ObjectConnectionEdge: ResolverTypeWrapper<ObjectConnectionEdge>;
  ObjectDeleteResponse: ResolverTypeWrapper<ObjectDeleteResponse>;
  ObjectFamily: ResolverTypeWrapper<ObjectFamily>;
  ObjectFamilyConnection: ResolverTypeWrapper<ObjectFamilyConnection>;
  ObjectFamilyConnectionEdge: ResolverTypeWrapper<ObjectFamilyConnectionEdge>;
  ObjectFamilyDeleteResponse: ResolverTypeWrapper<ObjectFamilyDeleteResponse>;
  ObjectFamilyManyResult: ResolverTypeWrapper<ObjectFamilyManyResult>;
  ObjectGroup: ResolverTypeWrapper<ObjectGroup>;
  ObjectGroupConnection: ResolverTypeWrapper<ObjectGroupConnection>;
  ObjectGroupConnectionEdge: ResolverTypeWrapper<ObjectGroupConnectionEdge>;
  ObjectGroupDeleteResponse: ResolverTypeWrapper<ObjectGroupDeleteResponse>;
  ObjectGroupManyResult: ResolverTypeWrapper<ObjectGroupManyResult>;
  ObjectManyResult: ResolverTypeWrapper<ObjectManyResult>;
  ObjectReturnHeader: ResolverTypeWrapper<ObjectReturnHeader>;
  ObjectReturnHeaderConnection: ResolverTypeWrapper<ObjectReturnHeaderConnection>;
  ObjectReturnHeaderConnectionEdge: ResolverTypeWrapper<ObjectReturnHeaderConnectionEdge>;
  ObjectReturnHeaderDeleteResponse: ResolverTypeWrapper<ObjectReturnHeaderDeleteResponse>;
  ObjectReturnHeaderManyResult: ResolverTypeWrapper<ObjectReturnHeaderManyResult>;
  ObjectReturnLine: ResolverTypeWrapper<ObjectReturnLine>;
  ObjectReturnLineConnection: ResolverTypeWrapper<ObjectReturnLineConnection>;
  ObjectReturnLineConnectionEdge: ResolverTypeWrapper<ObjectReturnLineConnectionEdge>;
  ObjectReturnLineDeleteResponse: ResolverTypeWrapper<ObjectReturnLineDeleteResponse>;
  ObjectReturnLineManyResult: ResolverTypeWrapper<ObjectReturnLineManyResult>;
  ObjectType: ResolverTypeWrapper<ObjectType>;
  ObjectTypeConnection: ResolverTypeWrapper<ObjectTypeConnection>;
  ObjectTypeConnectionEdge: ResolverTypeWrapper<ObjectTypeConnectionEdge>;
  ObjectTypeDeleteResponse: ResolverTypeWrapper<ObjectTypeDeleteResponse>;
  ObjectTypeLimited: ResolverTypeWrapper<ObjectTypeLimited>;
  ObjectTypeLimitedInput: ObjectTypeLimitedInput;
  ObjectTypeManyResult: ResolverTypeWrapper<ObjectTypeManyResult>;
  OcrAnalysis: ResolverTypeWrapper<OcrAnalysis>;
  OcrAnalysisConnection: ResolverTypeWrapper<OcrAnalysisConnection>;
  OcrAnalysisConnectionEdge: ResolverTypeWrapper<OcrAnalysisConnectionEdge>;
  OcrAnalysisDeleteResponse: ResolverTypeWrapper<OcrAnalysisDeleteResponse>;
  OcrAnalysisManyResult: ResolverTypeWrapper<OcrAnalysisManyResult>;
  OldFunctionTestCountResult: ResolverTypeWrapper<OldFunctionTestCountResult>;
  OrderStateEnum: OrderStateEnum;
  PageData: ResolverTypeWrapper<PageData>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  PaymentMethodConnection: ResolverTypeWrapper<PaymentMethodConnection>;
  PaymentMethodConnectionEdge: ResolverTypeWrapper<PaymentMethodConnectionEdge>;
  PaymentMethodDeleteResponse: ResolverTypeWrapper<PaymentMethodDeleteResponse>;
  PaymentMethodManyResult: ResolverTypeWrapper<PaymentMethodManyResult>;
  PaymentTerms: ResolverTypeWrapper<PaymentTerms>;
  PaymentTermsConnection: ResolverTypeWrapper<PaymentTermsConnection>;
  PaymentTermsConnectionEdge: ResolverTypeWrapper<PaymentTermsConnectionEdge>;
  PaymentTermsDeleteResponse: ResolverTypeWrapper<PaymentTermsDeleteResponse>;
  PaymentTermsManyResult: ResolverTypeWrapper<PaymentTermsManyResult>;
  PersonalInvitationInput: PersonalInvitationInput;
  PlatformFeature: ResolverTypeWrapper<PlatformFeature>;
  PlatformFeatureConnection: ResolverTypeWrapper<PlatformFeatureConnection>;
  PlatformFeatureConnectionEdge: ResolverTypeWrapper<PlatformFeatureConnectionEdge>;
  PlatformFeatureDeleteResponse: ResolverTypeWrapper<PlatformFeatureDeleteResponse>;
  PlatformFeatureManyResult: ResolverTypeWrapper<PlatformFeatureManyResult>;
  PostCode: ResolverTypeWrapper<PostCode>;
  PostCodeConnection: ResolverTypeWrapper<PostCodeConnection>;
  PostCodeConnectionEdge: ResolverTypeWrapper<PostCodeConnectionEdge>;
  PostCodeDeleteResponse: ResolverTypeWrapper<PostCodeDeleteResponse>;
  PostCodeManyResult: ResolverTypeWrapper<PostCodeManyResult>;
  Project: ResolverTypeWrapper<Project>;
  ProjectConnection: ResolverTypeWrapper<ProjectConnection>;
  ProjectConnectionEdge: ResolverTypeWrapper<ProjectConnectionEdge>;
  ProjectDeleteResponse: ResolverTypeWrapper<ProjectDeleteResponse>;
  ProjectManyResult: ResolverTypeWrapper<ProjectManyResult>;
  ProjectPushNotifications: ResolverTypeWrapper<ProjectPushNotifications>;
  ProxyHeartbeatResponse: ResolverTypeWrapper<ProxyHeartbeatResponse>;
  PurchCrMemoHeader: ResolverTypeWrapper<PurchCrMemoHeader>;
  PurchCrMemoHeaderConnection: ResolverTypeWrapper<PurchCrMemoHeaderConnection>;
  PurchCrMemoHeaderConnectionEdge: ResolverTypeWrapper<PurchCrMemoHeaderConnectionEdge>;
  PurchCrMemoHeaderDeleteResponse: ResolverTypeWrapper<PurchCrMemoHeaderDeleteResponse>;
  PurchCrMemoHeaderManyResult: ResolverTypeWrapper<PurchCrMemoHeaderManyResult>;
  PurchCrMemoLine: ResolverTypeWrapper<PurchCrMemoLine>;
  PurchCrMemoLineConnection: ResolverTypeWrapper<PurchCrMemoLineConnection>;
  PurchCrMemoLineConnectionEdge: ResolverTypeWrapper<PurchCrMemoLineConnectionEdge>;
  PurchCrMemoLineDeleteResponse: ResolverTypeWrapper<PurchCrMemoLineDeleteResponse>;
  PurchCrMemoLineManyResult: ResolverTypeWrapper<PurchCrMemoLineManyResult>;
  PurchInvHeader: ResolverTypeWrapper<PurchInvHeader>;
  PurchInvHeaderConnection: ResolverTypeWrapper<PurchInvHeaderConnection>;
  PurchInvHeaderConnectionEdge: ResolverTypeWrapper<PurchInvHeaderConnectionEdge>;
  PurchInvHeaderDeleteResponse: ResolverTypeWrapper<PurchInvHeaderDeleteResponse>;
  PurchInvHeaderManyResult: ResolverTypeWrapper<PurchInvHeaderManyResult>;
  PurchInvLine: ResolverTypeWrapper<PurchInvLine>;
  PurchInvLineConnection: ResolverTypeWrapper<PurchInvLineConnection>;
  PurchInvLineConnectionEdge: ResolverTypeWrapper<PurchInvLineConnectionEdge>;
  PurchInvLineDeleteResponse: ResolverTypeWrapper<PurchInvLineDeleteResponse>;
  PurchInvLineManyResult: ResolverTypeWrapper<PurchInvLineManyResult>;
  PurchRcptHeader: ResolverTypeWrapper<PurchRcptHeader>;
  PurchRcptHeaderConnection: ResolverTypeWrapper<PurchRcptHeaderConnection>;
  PurchRcptHeaderConnectionEdge: ResolverTypeWrapper<PurchRcptHeaderConnectionEdge>;
  PurchRcptHeaderDeleteResponse: ResolverTypeWrapper<PurchRcptHeaderDeleteResponse>;
  PurchRcptHeaderManyResult: ResolverTypeWrapper<PurchRcptHeaderManyResult>;
  PurchRcptLine: ResolverTypeWrapper<PurchRcptLine>;
  PurchRcptLineConnection: ResolverTypeWrapper<PurchRcptLineConnection>;
  PurchRcptLineConnectionEdge: ResolverTypeWrapper<PurchRcptLineConnectionEdge>;
  PurchRcptLineDeleteResponse: ResolverTypeWrapper<PurchRcptLineDeleteResponse>;
  PurchRcptLineManyResult: ResolverTypeWrapper<PurchRcptLineManyResult>;
  PurchaseHeader: ResolverTypeWrapper<PurchaseHeader>;
  PurchaseHeaderConnection: ResolverTypeWrapper<PurchaseHeaderConnection>;
  PurchaseHeaderConnectionEdge: ResolverTypeWrapper<PurchaseHeaderConnectionEdge>;
  PurchaseHeaderDeleteResponse: ResolverTypeWrapper<PurchaseHeaderDeleteResponse>;
  PurchaseHeaderDocumentTypeEnum: PurchaseHeaderDocumentTypeEnum;
  PurchaseHeaderManyResult: ResolverTypeWrapper<PurchaseHeaderManyResult>;
  PurchaseLine: ResolverTypeWrapper<PurchaseLine>;
  PurchaseLineConnection: ResolverTypeWrapper<PurchaseLineConnection>;
  PurchaseLineConnectionEdge: ResolverTypeWrapper<PurchaseLineConnectionEdge>;
  PurchaseLineDeleteResponse: ResolverTypeWrapper<PurchaseLineDeleteResponse>;
  PurchaseLineDocumentTypeEnum: PurchaseLineDocumentTypeEnum;
  PurchaseLineManyResult: ResolverTypeWrapper<PurchaseLineManyResult>;
  Purchasing: ResolverTypeWrapper<Purchasing>;
  PurchasingConnection: ResolverTypeWrapper<PurchasingConnection>;
  PurchasingConnectionEdge: ResolverTypeWrapper<PurchasingConnectionEdge>;
  PurchasingDeleteResponse: ResolverTypeWrapper<PurchasingDeleteResponse>;
  PurchasingManyResult: ResolverTypeWrapper<PurchasingManyResult>;
  PushNotificationInput: PushNotificationInput;
  PushNotificationResponse: ResolverTypeWrapper<PushNotificationResponse>;
  PushNotificationStatus: PushNotificationStatus;
  Query: ResolverTypeWrapper<{}>;
  QuoteHeader: ResolverTypeWrapper<QuoteHeader>;
  QuoteHeaderConnection: ResolverTypeWrapper<QuoteHeaderConnection>;
  QuoteHeaderConnectionEdge: ResolverTypeWrapper<QuoteHeaderConnectionEdge>;
  QuoteHeaderDeleteResponse: ResolverTypeWrapper<QuoteHeaderDeleteResponse>;
  QuoteHeaderManyResult: ResolverTypeWrapper<QuoteHeaderManyResult>;
  QuoteLine: ResolverTypeWrapper<QuoteLine>;
  QuoteLineConnection: ResolverTypeWrapper<QuoteLineConnection>;
  QuoteLineConnectionEdge: ResolverTypeWrapper<QuoteLineConnectionEdge>;
  QuoteLineDeleteResponse: ResolverTypeWrapper<QuoteLineDeleteResponse>;
  QuoteLineManyResult: ResolverTypeWrapper<QuoteLineManyResult>;
  QuoteTaskGroup: ResolverTypeWrapper<QuoteTaskGroup>;
  QuoteTaskGroupConnection: ResolverTypeWrapper<QuoteTaskGroupConnection>;
  QuoteTaskGroupConnectionEdge: ResolverTypeWrapper<QuoteTaskGroupConnectionEdge>;
  QuoteTaskGroupDeleteResponse: ResolverTypeWrapper<QuoteTaskGroupDeleteResponse>;
  QuoteTaskGroupManyResult: ResolverTypeWrapper<QuoteTaskGroupManyResult>;
  RejectApproveAccessRequestInput: RejectApproveAccessRequestInput;
  RemoveCodesResponse: ResolverTypeWrapper<RemoveCodesResponse>;
  RentCost: ResolverTypeWrapper<RentCost>;
  RentCostFilter: RentCostFilter;
  RentalEvent: ResolverTypeWrapper<RentalEvent>;
  RentalEventFilterInput: RentalEventFilterInput;
  RentalHeader: ResolverTypeWrapper<RentalHeader>;
  RentalHeaderConnection: ResolverTypeWrapper<RentalHeaderConnection>;
  RentalHeaderConnectionEdge: ResolverTypeWrapper<RentalHeaderConnectionEdge>;
  RentalHeaderDeleteResponse: ResolverTypeWrapper<RentalHeaderDeleteResponse>;
  RentalHeaderManyResult: ResolverTypeWrapper<RentalHeaderManyResult>;
  RentalLine: ResolverTypeWrapper<RentalLine>;
  RentalLineConnection: ResolverTypeWrapper<RentalLineConnection>;
  RentalLineConnectionEdge: ResolverTypeWrapper<RentalLineConnectionEdge>;
  RentalLineDeleteResponse: ResolverTypeWrapper<RentalLineDeleteResponse>;
  RentalLineManyResult: ResolverTypeWrapper<RentalLineManyResult>;
  RentalLineSnapshot: ResolverTypeWrapper<RentalLineSnapshot>;
  RentalLineSnapshotConnection: ResolverTypeWrapper<RentalLineSnapshotConnection>;
  RentalLineSnapshotConnectionEdge: ResolverTypeWrapper<RentalLineSnapshotConnectionEdge>;
  RentalLineSnapshotDeleteResponse: ResolverTypeWrapper<RentalLineSnapshotDeleteResponse>;
  RentalLineSnapshotManyResult: ResolverTypeWrapper<RentalLineSnapshotManyResult>;
  RentalPostedCollHeader: ResolverTypeWrapper<RentalPostedCollHeader>;
  RentalPostedCollHeaderConnection: ResolverTypeWrapper<RentalPostedCollHeaderConnection>;
  RentalPostedCollHeaderConnectionEdge: ResolverTypeWrapper<RentalPostedCollHeaderConnectionEdge>;
  RentalPostedCollHeaderDeleteResponse: ResolverTypeWrapper<RentalPostedCollHeaderDeleteResponse>;
  RentalPostedCollHeaderManyResult: ResolverTypeWrapper<RentalPostedCollHeaderManyResult>;
  RentalPostedCollLine: ResolverTypeWrapper<RentalPostedCollLine>;
  RentalPostedCollLineConnection: ResolverTypeWrapper<RentalPostedCollLineConnection>;
  RentalPostedCollLineConnectionEdge: ResolverTypeWrapper<RentalPostedCollLineConnectionEdge>;
  RentalPostedCollLineDeleteResponse: ResolverTypeWrapper<RentalPostedCollLineDeleteResponse>;
  RentalPostedCollLineManyResult: ResolverTypeWrapper<RentalPostedCollLineManyResult>;
  RentalPostedShptHeader: ResolverTypeWrapper<RentalPostedShptHeader>;
  RentalPostedShptHeaderConnection: ResolverTypeWrapper<RentalPostedShptHeaderConnection>;
  RentalPostedShptHeaderConnectionEdge: ResolverTypeWrapper<RentalPostedShptHeaderConnectionEdge>;
  RentalPostedShptHeaderDeleteResponse: ResolverTypeWrapper<RentalPostedShptHeaderDeleteResponse>;
  RentalPostedShptHeaderManyResult: ResolverTypeWrapper<RentalPostedShptHeaderManyResult>;
  RentalPostedShptLine: ResolverTypeWrapper<RentalPostedShptLine>;
  RentalPostedShptLineConnection: ResolverTypeWrapper<RentalPostedShptLineConnection>;
  RentalPostedShptLineConnectionEdge: ResolverTypeWrapper<RentalPostedShptLineConnectionEdge>;
  RentalPostedShptLineDeleteResponse: ResolverTypeWrapper<RentalPostedShptLineDeleteResponse>;
  RentalPostedShptLineManyResult: ResolverTypeWrapper<RentalPostedShptLineManyResult>;
  RentalPriceTerms: ResolverTypeWrapper<RentalPriceTerms>;
  RentalPriceTermsConnection: ResolverTypeWrapper<RentalPriceTermsConnection>;
  RentalPriceTermsConnectionEdge: ResolverTypeWrapper<RentalPriceTermsConnectionEdge>;
  RentalPriceTermsDeleteResponse: ResolverTypeWrapper<RentalPriceTermsDeleteResponse>;
  RentalPriceTermsManyResult: ResolverTypeWrapper<RentalPriceTermsManyResult>;
  RentalReturnEntry: ResolverTypeWrapper<RentalReturnEntry>;
  RentalReturnEntryConnection: ResolverTypeWrapper<RentalReturnEntryConnection>;
  RentalReturnEntryConnectionEdge: ResolverTypeWrapper<RentalReturnEntryConnectionEdge>;
  RentalReturnEntryDeleteResponse: ResolverTypeWrapper<RentalReturnEntryDeleteResponse>;
  RentalReturnEntryManyResult: ResolverTypeWrapper<RentalReturnEntryManyResult>;
  RequestHeader: ResolverTypeWrapper<RequestHeader>;
  RequestHeaderConnection: ResolverTypeWrapper<RequestHeaderConnection>;
  RequestHeaderConnectionEdge: ResolverTypeWrapper<RequestHeaderConnectionEdge>;
  RequestHeaderDeleteResponse: ResolverTypeWrapper<RequestHeaderDeleteResponse>;
  RequestHeaderManyResult: ResolverTypeWrapper<RequestHeaderManyResult>;
  RequestLine: ResolverTypeWrapper<RequestLine>;
  RequestLineConnection: ResolverTypeWrapper<RequestLineConnection>;
  RequestLineConnectionEdge: ResolverTypeWrapper<RequestLineConnectionEdge>;
  RequestLineDeleteResponse: ResolverTypeWrapper<RequestLineDeleteResponse>;
  RequestLineManyResult: ResolverTypeWrapper<RequestLineManyResult>;
  ReservationRequestHeader: ResolverTypeWrapper<ReservationRequestHeader>;
  ReservationRequestHeaderConnection: ResolverTypeWrapper<ReservationRequestHeaderConnection>;
  ReservationRequestHeaderConnectionEdge: ResolverTypeWrapper<ReservationRequestHeaderConnectionEdge>;
  ReservationRequestHeaderDeleteResponse: ResolverTypeWrapper<ReservationRequestHeaderDeleteResponse>;
  ReservationRequestHeaderManyResult: ResolverTypeWrapper<ReservationRequestHeaderManyResult>;
  ReservationRequestLine: ResolverTypeWrapper<ReservationRequestLine>;
  ReservationRequestLineConnection: ResolverTypeWrapper<ReservationRequestLineConnection>;
  ReservationRequestLineConnectionEdge: ResolverTypeWrapper<ReservationRequestLineConnectionEdge>;
  ReservationRequestLineDeleteResponse: ResolverTypeWrapper<ReservationRequestLineDeleteResponse>;
  ReservationRequestLineManyResult: ResolverTypeWrapper<ReservationRequestLineManyResult>;
  Resource: ResolverTypeWrapper<Resource>;
  ResourceConnection: ResolverTypeWrapper<ResourceConnection>;
  ResourceConnectionEdge: ResolverTypeWrapper<ResourceConnectionEdge>;
  ResourceDeleteResponse: ResolverTypeWrapper<ResourceDeleteResponse>;
  ResourceManyResult: ResolverTypeWrapper<ResourceManyResult>;
  ResponsibilityCenter: ResolverTypeWrapper<ResponsibilityCenter>;
  ResponsibilityCenterConnection: ResolverTypeWrapper<ResponsibilityCenterConnection>;
  ResponsibilityCenterConnectionEdge: ResolverTypeWrapper<ResponsibilityCenterConnectionEdge>;
  ResponsibilityCenterDeleteResponse: ResolverTypeWrapper<ResponsibilityCenterDeleteResponse>;
  ResponsibilityCenterManyResult: ResolverTypeWrapper<ResponsibilityCenterManyResult>;
  ResponsibilityCenterMatrix: ResolverTypeWrapper<ResponsibilityCenterMatrix>;
  RetrySyncInput: RetrySyncInput;
  RetrySyncResponse: ResolverTypeWrapper<RetrySyncResponse>;
  ReturnRequestHeader: ResolverTypeWrapper<ReturnRequestHeader>;
  ReturnRequestHeaderConnection: ResolverTypeWrapper<ReturnRequestHeaderConnection>;
  ReturnRequestHeaderConnectionEdge: ResolverTypeWrapper<ReturnRequestHeaderConnectionEdge>;
  ReturnRequestHeaderDeleteResponse: ResolverTypeWrapper<ReturnRequestHeaderDeleteResponse>;
  ReturnRequestHeaderManyResult: ResolverTypeWrapper<ReturnRequestHeaderManyResult>;
  ReturnRequestLine: ResolverTypeWrapper<ReturnRequestLine>;
  ReturnRequestLineConnection: ResolverTypeWrapper<ReturnRequestLineConnection>;
  ReturnRequestLineConnectionEdge: ResolverTypeWrapper<ReturnRequestLineConnectionEdge>;
  ReturnRequestLineDeleteResponse: ResolverTypeWrapper<ReturnRequestLineDeleteResponse>;
  ReturnRequestLineManyResult: ResolverTypeWrapper<ReturnRequestLineManyResult>;
  RiskAnalysisHeader: ResolverTypeWrapper<RiskAnalysisHeader>;
  RiskAnalysisHeaderConnection: ResolverTypeWrapper<RiskAnalysisHeaderConnection>;
  RiskAnalysisHeaderConnectionEdge: ResolverTypeWrapper<RiskAnalysisHeaderConnectionEdge>;
  RiskAnalysisHeaderDeleteResponse: ResolverTypeWrapper<RiskAnalysisHeaderDeleteResponse>;
  RiskAnalysisHeaderManyResult: ResolverTypeWrapper<RiskAnalysisHeaderManyResult>;
  RiskAnalysisLine: ResolverTypeWrapper<RiskAnalysisLine>;
  RiskAnalysisLineConnection: ResolverTypeWrapper<RiskAnalysisLineConnection>;
  RiskAnalysisLineConnectionEdge: ResolverTypeWrapper<RiskAnalysisLineConnectionEdge>;
  RiskAnalysisLineDeleteResponse: ResolverTypeWrapper<RiskAnalysisLineDeleteResponse>;
  RiskAnalysisLineManyResult: ResolverTypeWrapper<RiskAnalysisLineManyResult>;
  RiskAnalysisSignature: ResolverTypeWrapper<RiskAnalysisSignature>;
  RiskAnalysisSignatureConnection: ResolverTypeWrapper<RiskAnalysisSignatureConnection>;
  RiskAnalysisSignatureConnectionEdge: ResolverTypeWrapper<RiskAnalysisSignatureConnectionEdge>;
  RiskAnalysisSignatureDeleteResponse: ResolverTypeWrapper<RiskAnalysisSignatureDeleteResponse>;
  RiskAnalysisSignatureManyResult: ResolverTypeWrapper<RiskAnalysisSignatureManyResult>;
  SalesHeader: ResolverTypeWrapper<SalesHeader>;
  SalesHeaderArchive: ResolverTypeWrapper<SalesHeaderArchive>;
  SalesHeaderArchiveConnection: ResolverTypeWrapper<SalesHeaderArchiveConnection>;
  SalesHeaderArchiveConnectionEdge: ResolverTypeWrapper<SalesHeaderArchiveConnectionEdge>;
  SalesHeaderArchiveDeleteResponse: ResolverTypeWrapper<SalesHeaderArchiveDeleteResponse>;
  SalesHeaderArchiveDeletedReasonEnum: SalesHeaderArchiveDeletedReasonEnum;
  SalesHeaderArchiveDocumentTypeEnum: SalesHeaderArchiveDocumentTypeEnum;
  SalesHeaderArchiveManyResult: ResolverTypeWrapper<SalesHeaderArchiveManyResult>;
  SalesHeaderArchivePaymentMethodWebEnum: SalesHeaderArchivePaymentMethodWebEnum;
  SalesHeaderConnection: ResolverTypeWrapper<SalesHeaderConnection>;
  SalesHeaderConnectionEdge: ResolverTypeWrapper<SalesHeaderConnectionEdge>;
  SalesHeaderDeleteResponse: ResolverTypeWrapper<SalesHeaderDeleteResponse>;
  SalesHeaderDocumentTypeEnum: SalesHeaderDocumentTypeEnum;
  SalesHeaderHistory: ResolverTypeWrapper<SalesHeaderHistory>;
  SalesHeaderHistoryConnection: ResolverTypeWrapper<SalesHeaderHistoryConnection>;
  SalesHeaderHistoryConnectionEdge: ResolverTypeWrapper<SalesHeaderHistoryConnectionEdge>;
  SalesHeaderHistoryDeleteResponse: ResolverTypeWrapper<SalesHeaderHistoryDeleteResponse>;
  SalesHeaderHistoryManyResult: ResolverTypeWrapper<SalesHeaderHistoryManyResult>;
  SalesHeaderHistoryTypeEnum: SalesHeaderHistoryTypeEnum;
  SalesHeaderManyResult: ResolverTypeWrapper<SalesHeaderManyResult>;
  SalesHeaderPaymentMethodWebEnum: SalesHeaderPaymentMethodWebEnum;
  SalesHeaderRequest: ResolverTypeWrapper<SalesHeaderRequest>;
  SalesHeaderRequestConnection: ResolverTypeWrapper<SalesHeaderRequestConnection>;
  SalesHeaderRequestConnectionEdge: ResolverTypeWrapper<SalesHeaderRequestConnectionEdge>;
  SalesHeaderRequestDeleteResponse: ResolverTypeWrapper<SalesHeaderRequestDeleteResponse>;
  SalesHeaderRequestManyResult: ResolverTypeWrapper<SalesHeaderRequestManyResult>;
  SalesHeaderShippingAdviceEnum: SalesHeaderShippingAdviceEnum;
  SalesLine: ResolverTypeWrapper<SalesLine>;
  SalesLineArchive: ResolverTypeWrapper<SalesLineArchive>;
  SalesLineArchiveConnection: ResolverTypeWrapper<SalesLineArchiveConnection>;
  SalesLineArchiveConnectionEdge: ResolverTypeWrapper<SalesLineArchiveConnectionEdge>;
  SalesLineArchiveDeleteResponse: ResolverTypeWrapper<SalesLineArchiveDeleteResponse>;
  SalesLineArchiveDocumentTypeEnum: SalesLineArchiveDocumentTypeEnum;
  SalesLineArchiveManyResult: ResolverTypeWrapper<SalesLineArchiveManyResult>;
  SalesLineArchiveTypeEnum: SalesLineArchiveTypeEnum;
  SalesLineConnection: ResolverTypeWrapper<SalesLineConnection>;
  SalesLineConnectionEdge: ResolverTypeWrapper<SalesLineConnectionEdge>;
  SalesLineDeleteResponse: ResolverTypeWrapper<SalesLineDeleteResponse>;
  SalesLineDocumentTypeEnum: SalesLineDocumentTypeEnum;
  SalesLineManyResult: ResolverTypeWrapper<SalesLineManyResult>;
  SalesLineRequest: ResolverTypeWrapper<SalesLineRequest>;
  SalesLineRequestConnection: ResolverTypeWrapper<SalesLineRequestConnection>;
  SalesLineRequestConnectionEdge: ResolverTypeWrapper<SalesLineRequestConnectionEdge>;
  SalesLineRequestDeleteResponse: ResolverTypeWrapper<SalesLineRequestDeleteResponse>;
  SalesLineRequestManyResult: ResolverTypeWrapper<SalesLineRequestManyResult>;
  SalesLineTypeEnum: SalesLineTypeEnum;
  SalesOrderChangeTrackingFilterInput: SalesOrderChangeTrackingFilterInput;
  SalesOrderChangeTrackingPageData: ResolverTypeWrapper<SalesOrderChangeTrackingPageData>;
  SalesOrderChangeTrackingPageResponse: ResolverTypeWrapper<SalesOrderChangeTrackingPageResponse>;
  SalesOrderInput: SalesOrderInput;
  SalesOrderResponse: ResolverTypeWrapper<SalesOrderResponse>;
  SalesPrice: ResolverTypeWrapper<SalesPrice>;
  SalesPriceConnection: ResolverTypeWrapper<SalesPriceConnection>;
  SalesPriceConnectionEdge: ResolverTypeWrapper<SalesPriceConnectionEdge>;
  SalesPriceDeleteResponse: ResolverTypeWrapper<SalesPriceDeleteResponse>;
  SalesPriceManyResult: ResolverTypeWrapper<SalesPriceManyResult>;
  SalesPriceSalesTypeEnum: SalesPriceSalesTypeEnum;
  SalesShipmentHeader: ResolverTypeWrapper<SalesShipmentHeader>;
  SalesShipmentHeaderConnection: ResolverTypeWrapper<SalesShipmentHeaderConnection>;
  SalesShipmentHeaderConnectionEdge: ResolverTypeWrapper<SalesShipmentHeaderConnectionEdge>;
  SalesShipmentHeaderDeleteResponse: ResolverTypeWrapper<SalesShipmentHeaderDeleteResponse>;
  SalesShipmentHeaderManyResult: ResolverTypeWrapper<SalesShipmentHeaderManyResult>;
  SalesShipmentLine: ResolverTypeWrapper<SalesShipmentLine>;
  SalesShipmentLineConnection: ResolverTypeWrapper<SalesShipmentLineConnection>;
  SalesShipmentLineConnectionEdge: ResolverTypeWrapper<SalesShipmentLineConnectionEdge>;
  SalesShipmentLineDeleteResponse: ResolverTypeWrapper<SalesShipmentLineDeleteResponse>;
  SalesShipmentLineManyResult: ResolverTypeWrapper<SalesShipmentLineManyResult>;
  Salesperson: ResolverTypeWrapper<Salesperson>;
  SalespersonConnection: ResolverTypeWrapper<SalespersonConnection>;
  SalespersonConnectionEdge: ResolverTypeWrapper<SalespersonConnectionEdge>;
  SalespersonDeleteResponse: ResolverTypeWrapper<SalespersonDeleteResponse>;
  SalespersonManyResult: ResolverTypeWrapper<SalespersonManyResult>;
  SendOnRentEmailInput: SendOnRentEmailInput;
  SendOnRentEmailResponse: ResolverTypeWrapper<SendOnRentEmailResponse>;
  ServiceItemGroup: ResolverTypeWrapper<ServiceItemGroup>;
  ServiceItemGroupConnection: ResolverTypeWrapper<ServiceItemGroupConnection>;
  ServiceItemGroupConnectionEdge: ResolverTypeWrapper<ServiceItemGroupConnectionEdge>;
  ServiceItemGroupDeleteResponse: ResolverTypeWrapper<ServiceItemGroupDeleteResponse>;
  ServiceItemGroupManyResult: ResolverTypeWrapper<ServiceItemGroupManyResult>;
  ShipmentMethod: ResolverTypeWrapper<ShipmentMethod>;
  ShipmentMethodConnection: ResolverTypeWrapper<ShipmentMethodConnection>;
  ShipmentMethodConnectionEdge: ResolverTypeWrapper<ShipmentMethodConnectionEdge>;
  ShipmentMethodDeleteResponse: ResolverTypeWrapper<ShipmentMethodDeleteResponse>;
  ShipmentMethodManyResult: ResolverTypeWrapper<ShipmentMethodManyResult>;
  ShippingAgent: ResolverTypeWrapper<ShippingAgent>;
  ShippingAgentConnection: ResolverTypeWrapper<ShippingAgentConnection>;
  ShippingAgentConnectionEdge: ResolverTypeWrapper<ShippingAgentConnectionEdge>;
  ShippingAgentDeleteResponse: ResolverTypeWrapper<ShippingAgentDeleteResponse>;
  ShippingAgentManyResult: ResolverTypeWrapper<ShippingAgentManyResult>;
  SignRequest: ResolverTypeWrapper<SignRequest>;
  SignRequestConnection: ResolverTypeWrapper<SignRequestConnection>;
  SignRequestConnectionEdge: ResolverTypeWrapper<SignRequestConnectionEdge>;
  SignRequestDeleteResponse: ResolverTypeWrapper<SignRequestDeleteResponse>;
  SignRequestManyResult: ResolverTypeWrapper<SignRequestManyResult>;
  Signature: ResolverTypeWrapper<Signature>;
  SignatureConnection: ResolverTypeWrapper<SignatureConnection>;
  SignatureConnectionEdge: ResolverTypeWrapper<SignatureConnectionEdge>;
  SignatureDeleteResponse: ResolverTypeWrapper<SignatureDeleteResponse>;
  SignatureManyResult: ResolverTypeWrapper<SignatureManyResult>;
  SortAccessRequestInput: SortAccessRequestInput;
  SortAdditionalItemInput: SortAdditionalItemInput;
  SortAddressInput: SortAddressInput;
  SortAgreementInput: SortAgreementInput;
  SortAnnotationInput: SortAnnotationInput;
  SortApplicationModuleInput: SortApplicationModuleInput;
  SortBcMappingFieldInput: SortBcMappingFieldInput;
  SortBcMappingTableInput: SortBcMappingTableInput;
  SortBcSettingsInput: SortBcSettingsInput;
  SortBulkItemGroupInput: SortBulkItemGroupInput;
  SortCauseOfAbsenceInput: SortCauseOfAbsenceInput;
  SortCompanyInput: SortCompanyInput;
  SortConsignmentHeaderInput: SortConsignmentHeaderInput;
  SortConsignmentLineInput: SortConsignmentLineInput;
  SortContactInput: SortContactInput;
  SortCountryRegionInput: SortCountryRegionInput;
  SortCurrencyExchangeRateInput: SortCurrencyExchangeRateInput;
  SortCurrencyInput: SortCurrencyInput;
  SortCustomerDomainInput: SortCustomerDomainInput;
  SortCustomerPostingGroupInput: SortCustomerPostingGroupInput;
  SortCustomerProjectInput: SortCustomerProjectInput;
  SortCustomerSubProjectInput: SortCustomerSubProjectInput;
  SortDeliverytimeInput: SortDeliverytimeInput;
  SortDevopsProjectInput: SortDevopsProjectInput;
  SortDevopsTeamInput: SortDevopsTeamInput;
  SortDevopsWorkItemInput: SortDevopsWorkItemInput;
  SortDimensionInput: SortDimensionInput;
  SortDimensionValueInput: SortDimensionValueInput;
  SortDiscussionPostInput: SortDiscussionPostInput;
  SortDocumentClassificationInput: SortDocumentClassificationInput;
  SortDocumentInput: SortDocumentInput;
  SortDocumentRequestInput: SortDocumentRequestInput;
  SortDomainInput: SortDomainInput;
  SortDomainIntegrationInput: SortDomainIntegrationInput;
  SortDomainSettingsInput: SortDomainSettingsInput;
  SortEqmBaseCalendarChangeInput: SortEqmBaseCalendarChangeInput;
  SortEqmBaseCalendarInput: SortEqmBaseCalendarInput;
  SortEqmCollectionReturnChargesInput: SortEqmCollectionReturnChargesInput;
  SortEqmCommentLineInput: SortEqmCommentLineInput;
  SortEqmCustomizedCalendarChangeInput: SortEqmCustomizedCalendarChangeInput;
  SortEqmFncTestReturnChargesInput: SortEqmFncTestReturnChargesInput;
  SortEqmLocCostCenterCombInput: SortEqmLocCostCenterCombInput;
  SortEqmManufacturerInput: SortEqmManufacturerInput;
  SortEqmManufacturerModelInput: SortEqmManufacturerModelInput;
  SortEqmObjectCardFlowInput: SortEqmObjectCardFlowInput;
  SortEqmObjectComponentsInput: SortEqmObjectComponentsInput;
  SortEqmObjectCountHeaderInput: SortEqmObjectCountHeaderInput;
  SortEqmObjectCountLineInput: SortEqmObjectCountLineInput;
  SortEqmObjectGroupCardInput: SortEqmObjectGroupCardInput;
  SortEqmObjectGroupFlowInput: SortEqmObjectGroupFlowInput;
  SortEqmObjectServiceIntervalInput: SortEqmObjectServiceIntervalInput;
  SortEqmObjectSrvIntervalAggInput: SortEqmObjectSrvIntervalAggInput;
  SortEqmObjectStatusInput: SortEqmObjectStatusInput;
  SortEqmObjectTypeCardInput: SortEqmObjectTypeCardInput;
  SortEqmObjectTypeComponentsInput: SortEqmObjectTypeComponentsInput;
  SortEqmObjectTypeFlowInput: SortEqmObjectTypeFlowInput;
  SortEqmObjectTypePriceTermInput: SortEqmObjectTypePriceTermInput;
  SortEqmObjectTypeServiceIntervalInput: SortEqmObjectTypeServiceIntervalInput;
  SortEqmReRentCardInput: SortEqmReRentCardInput;
  SortEqmRentalGroupInput: SortEqmRentalGroupInput;
  SortEqmRentalKITComponentsInput: SortEqmRentalKitComponentsInput;
  SortEqmRentalKITInput: SortEqmRentalKitInput;
  SortEqmRentalKITLinesInput: SortEqmRentalKitLinesInput;
  SortEqmRentalLineDiscountInput: SortEqmRentalLineDiscountInput;
  SortEqmRentalPriceInput: SortEqmRentalPriceInput;
  SortEqmReturnChargesInput: SortEqmReturnChargesInput;
  SortEqmServiceCardInput: SortEqmServiceCardInput;
  SortEqmServiceTypeInput: SortEqmServiceTypeInput;
  SortEqmTransferHeaderInput: SortEqmTransferHeaderInput;
  SortEqmTransferLineInput: SortEqmTransferLineInput;
  SortEqmTypeFunctionalTestInput: SortEqmTypeFunctionalTestInput;
  SortEqmWorkHeaderInput: SortEqmWorkHeaderInput;
  SortEqmWorkLineInput: SortEqmWorkLineInput;
  SortErrandInput: SortErrandInput;
  SortErrandLineInput: SortErrandLineInput;
  SortErrorReportInput: SortErrorReportInput;
  SortFeedbackInput: SortFeedbackInput;
  SortFeemappingInput: SortFeemappingInput;
  SortFieldOrderInput: SortFieldOrderInput;
  SortFieldOrderLineInput: SortFieldOrderLineInput;
  SortFileInput: SortFileInput;
  SortFunctionTestHeaderInput: SortFunctionTestHeaderInput;
  SortFunctionTestLineInput: SortFunctionTestLineInput;
  SortGenBusinessPostingGroupInput: SortGenBusinessPostingGroupInput;
  SortGenJournalLineInput: SortGenJournalLineInput;
  SortGenProductPostingGroupInput: SortGenProductPostingGroupInput;
  SortInspectionInput: SortInspectionInput;
  SortInspectionUserInput: SortInspectionUserInput;
  SortIntegrationCollectionInput: SortIntegrationCollectionInput;
  SortInventoryPostingGroupInput: SortInventoryPostingGroupInput;
  SortInvitationInput: SortInvitationInput;
  SortInvoiceHeaderInput: SortInvoiceHeaderInput;
  SortInvoiceLineInput: SortInvoiceLineInput;
  SortItemCategoryInput: SortItemCategoryInput;
  SortItemDiscGroupInput: SortItemDiscGroupInput;
  SortItemInput: SortItemInput;
  SortItemReferenceInput: SortItemReferenceInput;
  SortItemRestrictionInput: SortItemRestrictionInput;
  SortItemUnitOfMeasureInput: SortItemUnitOfMeasureInput;
  SortItemVendorInput: SortItemVendorInput;
  SortJobTaskInput: SortJobTaskInput;
  SortKliHeaderInput: SortKliHeaderInput;
  SortKliReasonCodeInput: SortKliReasonCodeInput;
  SortLeadInput: SortLeadInput;
  SortLocationInput: SortLocationInput;
  SortMagentoOrderStatusInput: SortMagentoOrderStatusInput;
  SortMagentoSettingsInput: SortMagentoSettingsInput;
  SortManufacturerInput: SortManufacturerInput;
  SortNavUserInput: SortNavUserInput;
  SortObjectCommentLineInput: SortObjectCommentLineInput;
  SortObjectFamilyInput: SortObjectFamilyInput;
  SortObjectGroupInput: SortObjectGroupInput;
  SortObjectInput: SortObjectInput;
  SortObjectReturnHeaderInput: SortObjectReturnHeaderInput;
  SortObjectReturnLineInput: SortObjectReturnLineInput;
  SortObjectTypeInput: SortObjectTypeInput;
  SortOcrAnalysisInput: SortOcrAnalysisInput;
  SortPaymentMethodInput: SortPaymentMethodInput;
  SortPaymentTermsInput: SortPaymentTermsInput;
  SortPlatformFeatureInput: SortPlatformFeatureInput;
  SortPostCodeInput: SortPostCodeInput;
  SortProjectInput: SortProjectInput;
  SortPurchCrMemoHeaderInput: SortPurchCrMemoHeaderInput;
  SortPurchCrMemoLineInput: SortPurchCrMemoLineInput;
  SortPurchInvHeaderInput: SortPurchInvHeaderInput;
  SortPurchInvLineInput: SortPurchInvLineInput;
  SortPurchRcptHeaderInput: SortPurchRcptHeaderInput;
  SortPurchRcptLineInput: SortPurchRcptLineInput;
  SortPurchaseHeaderInput: SortPurchaseHeaderInput;
  SortPurchaseLineInput: SortPurchaseLineInput;
  SortPurchasingInput: SortPurchasingInput;
  SortQuoteHeaderInput: SortQuoteHeaderInput;
  SortQuoteLineInput: SortQuoteLineInput;
  SortQuoteTaskGroupInput: SortQuoteTaskGroupInput;
  SortRentalHeaderInput: SortRentalHeaderInput;
  SortRentalLineInput: SortRentalLineInput;
  SortRentalLineSnapshotInput: SortRentalLineSnapshotInput;
  SortRentalPostedCollHeaderInput: SortRentalPostedCollHeaderInput;
  SortRentalPostedCollLineInput: SortRentalPostedCollLineInput;
  SortRentalPostedShptHeaderInput: SortRentalPostedShptHeaderInput;
  SortRentalPostedShptLineInput: SortRentalPostedShptLineInput;
  SortRentalPriceTermsInput: SortRentalPriceTermsInput;
  SortRentalReturnEntryInput: SortRentalReturnEntryInput;
  SortRequestHeaderInput: SortRequestHeaderInput;
  SortRequestLineInput: SortRequestLineInput;
  SortReservationRequestHeaderInput: SortReservationRequestHeaderInput;
  SortReservationRequestLineInput: SortReservationRequestLineInput;
  SortResourceInput: SortResourceInput;
  SortResponsibilityCenterInput: SortResponsibilityCenterInput;
  SortReturnRequestHeaderInput: SortReturnRequestHeaderInput;
  SortReturnRequestLineInput: SortReturnRequestLineInput;
  SortRiskAnalysisHeaderInput: SortRiskAnalysisHeaderInput;
  SortRiskAnalysisLineInput: SortRiskAnalysisLineInput;
  SortRiskAnalysisSignatureInput: SortRiskAnalysisSignatureInput;
  SortSalesHeaderArchiveInput: SortSalesHeaderArchiveInput;
  SortSalesHeaderHistoryInput: SortSalesHeaderHistoryInput;
  SortSalesHeaderInput: SortSalesHeaderInput;
  SortSalesHeaderRequestInput: SortSalesHeaderRequestInput;
  SortSalesLineArchiveInput: SortSalesLineArchiveInput;
  SortSalesLineInput: SortSalesLineInput;
  SortSalesLineRequestInput: SortSalesLineRequestInput;
  SortSalesPriceInput: SortSalesPriceInput;
  SortSalesShipmentHeaderInput: SortSalesShipmentHeaderInput;
  SortSalesShipmentLineInput: SortSalesShipmentLineInput;
  SortSalespersonInput: SortSalespersonInput;
  SortServiceItemGroupInput: SortServiceItemGroupInput;
  SortShipmentMethodInput: SortShipmentMethodInput;
  SortShippingAgentInput: SortShippingAgentInput;
  SortSignRequestInput: SortSignRequestInput;
  SortSignatureInput: SortSignatureInput;
  SortSpecialEquipmentInput: SortSpecialEquipmentInput;
  SortStockkeepingUnitInput: SortStockkeepingUnitInput;
  SortTariffNumberInput: SortTariffNumberInput;
  SortTaxGroupInput: SortTaxGroupInput;
  SortTimeRegistrationInput: SortTimeRegistrationInput;
  SortTimeSheetDetailInput: SortTimeSheetDetailInput;
  SortTimeSheetLineInput: SortTimeSheetLineInput;
  SortTransactionSpecificationInput: SortTransactionSpecificationInput;
  SortTransactionTypeInput: SortTransactionTypeInput;
  SortTransportMethodInput: SortTransportMethodInput;
  SortUnitOfMeasureInput: SortUnitOfMeasureInput;
  SortUserFavoritesInput: SortUserFavoritesInput;
  SortUserInput: SortUserInput;
  SortVatBusinessPostingGroupInput: SortVatBusinessPostingGroupInput;
  SortVatProductPostingGroupInput: SortVatProductPostingGroupInput;
  SortVendorBankAccountInput: SortVendorBankAccountInput;
  SortVendorInput: SortVendorInput;
  SortVendorPostingGroupInput: SortVendorPostingGroupInput;
  SortWarehouseClassInput: SortWarehouseClassInput;
  SortWebBookingInput: SortWebBookingInput;
  SortWebhookSubscriptionInput: SortWebhookSubscriptionInput;
  SortWorkLogInput: SortWorkLogInput;
  SortWorkTypeInput: SortWorkTypeInput;
  SpecialEquipment: ResolverTypeWrapper<SpecialEquipment>;
  SpecialEquipmentConnection: ResolverTypeWrapper<SpecialEquipmentConnection>;
  SpecialEquipmentConnectionEdge: ResolverTypeWrapper<SpecialEquipmentConnectionEdge>;
  SpecialEquipmentDeleteResponse: ResolverTypeWrapper<SpecialEquipmentDeleteResponse>;
  SpecialEquipmentManyResult: ResolverTypeWrapper<SpecialEquipmentManyResult>;
  StockkeepingUnit: ResolverTypeWrapper<StockkeepingUnit>;
  StockkeepingUnitConnection: ResolverTypeWrapper<StockkeepingUnitConnection>;
  StockkeepingUnitConnectionEdge: ResolverTypeWrapper<StockkeepingUnitConnectionEdge>;
  StockkeepingUnitDeleteResponse: ResolverTypeWrapper<StockkeepingUnitDeleteResponse>;
  StockkeepingUnitManyResult: ResolverTypeWrapper<StockkeepingUnitManyResult>;
  StoredFile: ResolverTypeWrapper<StoredFile>;
  Subscription: ResolverTypeWrapper<{}>;
  SyncUsersInput: SyncUsersInput;
  TariffNumber: ResolverTypeWrapper<TariffNumber>;
  TariffNumberConnection: ResolverTypeWrapper<TariffNumberConnection>;
  TariffNumberConnectionEdge: ResolverTypeWrapper<TariffNumberConnectionEdge>;
  TariffNumberDeleteResponse: ResolverTypeWrapper<TariffNumberDeleteResponse>;
  TariffNumberManyResult: ResolverTypeWrapper<TariffNumberManyResult>;
  TaxGroup: ResolverTypeWrapper<TaxGroup>;
  TaxGroupConnection: ResolverTypeWrapper<TaxGroupConnection>;
  TaxGroupConnectionEdge: ResolverTypeWrapper<TaxGroupConnectionEdge>;
  TaxGroupDeleteResponse: ResolverTypeWrapper<TaxGroupDeleteResponse>;
  TaxGroupManyResult: ResolverTypeWrapper<TaxGroupManyResult>;
  TimeRegistration: ResolverTypeWrapper<TimeRegistration>;
  TimeRegistrationConnection: ResolverTypeWrapper<TimeRegistrationConnection>;
  TimeRegistrationConnectionEdge: ResolverTypeWrapper<TimeRegistrationConnectionEdge>;
  TimeRegistrationDeleteResponse: ResolverTypeWrapper<TimeRegistrationDeleteResponse>;
  TimeRegistrationManyResult: ResolverTypeWrapper<TimeRegistrationManyResult>;
  TimeSheetDetail: ResolverTypeWrapper<TimeSheetDetail>;
  TimeSheetDetailConnection: ResolverTypeWrapper<TimeSheetDetailConnection>;
  TimeSheetDetailConnectionEdge: ResolverTypeWrapper<TimeSheetDetailConnectionEdge>;
  TimeSheetDetailDeleteResponse: ResolverTypeWrapper<TimeSheetDetailDeleteResponse>;
  TimeSheetDetailManyResult: ResolverTypeWrapper<TimeSheetDetailManyResult>;
  TimeSheetLine: ResolverTypeWrapper<TimeSheetLine>;
  TimeSheetLineConnection: ResolverTypeWrapper<TimeSheetLineConnection>;
  TimeSheetLineConnectionEdge: ResolverTypeWrapper<TimeSheetLineConnectionEdge>;
  TimeSheetLineDeleteResponse: ResolverTypeWrapper<TimeSheetLineDeleteResponse>;
  TimeSheetLineManyResult: ResolverTypeWrapper<TimeSheetLineManyResult>;
  ToggleProjectNotificationsInput: ToggleProjectNotificationsInput;
  TransactionSpecification: ResolverTypeWrapper<TransactionSpecification>;
  TransactionSpecificationConnection: ResolverTypeWrapper<TransactionSpecificationConnection>;
  TransactionSpecificationConnectionEdge: ResolverTypeWrapper<TransactionSpecificationConnectionEdge>;
  TransactionSpecificationDeleteResponse: ResolverTypeWrapper<TransactionSpecificationDeleteResponse>;
  TransactionSpecificationManyResult: ResolverTypeWrapper<TransactionSpecificationManyResult>;
  TransactionType: ResolverTypeWrapper<TransactionType>;
  TransactionTypeConnection: ResolverTypeWrapper<TransactionTypeConnection>;
  TransactionTypeConnectionEdge: ResolverTypeWrapper<TransactionTypeConnectionEdge>;
  TransactionTypeDeleteResponse: ResolverTypeWrapper<TransactionTypeDeleteResponse>;
  TransactionTypeManyResult: ResolverTypeWrapper<TransactionTypeManyResult>;
  TransportMethod: ResolverTypeWrapper<TransportMethod>;
  TransportMethodConnection: ResolverTypeWrapper<TransportMethodConnection>;
  TransportMethodConnectionEdge: ResolverTypeWrapper<TransportMethodConnectionEdge>;
  TransportMethodDeleteResponse: ResolverTypeWrapper<TransportMethodDeleteResponse>;
  TransportMethodManyResult: ResolverTypeWrapper<TransportMethodManyResult>;
  UnitOfMeasure: ResolverTypeWrapper<UnitOfMeasure>;
  UnitOfMeasureConnection: ResolverTypeWrapper<UnitOfMeasureConnection>;
  UnitOfMeasureConnectionEdge: ResolverTypeWrapper<UnitOfMeasureConnectionEdge>;
  UnitOfMeasureDeleteResponse: ResolverTypeWrapper<UnitOfMeasureDeleteResponse>;
  UnitOfMeasureManyResult: ResolverTypeWrapper<UnitOfMeasureManyResult>;
  UnsignedInt: ResolverTypeWrapper<Scalars['UnsignedInt']>;
  UpdateAccessRequestInput: UpdateAccessRequestInput;
  UpdateAdditionalItemInput: UpdateAdditionalItemInput;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAgreementInput: UpdateAgreementInput;
  UpdateAnnotationInput: UpdateAnnotationInput;
  UpdateApplicationModuleInput: UpdateApplicationModuleInput;
  UpdateBcMappingFieldInput: UpdateBcMappingFieldInput;
  UpdateBcMappingTableInput: UpdateBcMappingTableInput;
  UpdateBcSettingsInput: UpdateBcSettingsInput;
  UpdateBulkItemGroupInput: UpdateBulkItemGroupInput;
  UpdateCauseOfAbsenceInput: UpdateCauseOfAbsenceInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateCompanyTreeInput: UpdateCompanyTreeInput;
  UpdateConsignmentHeaderInput: UpdateConsignmentHeaderInput;
  UpdateConsignmentLineInput: UpdateConsignmentLineInput;
  UpdateContactInput: UpdateContactInput;
  UpdateCountryRegionInput: UpdateCountryRegionInput;
  UpdateCurrencyExchangeRateInput: UpdateCurrencyExchangeRateInput;
  UpdateCurrencyInput: UpdateCurrencyInput;
  UpdateCustomerDomainInput: UpdateCustomerDomainInput;
  UpdateCustomerPostingGroupInput: UpdateCustomerPostingGroupInput;
  UpdateCustomerProjectInput: UpdateCustomerProjectInput;
  UpdateCustomerSubProjectInput: UpdateCustomerSubProjectInput;
  UpdateDeliverytimeInput: UpdateDeliverytimeInput;
  UpdateDevopsProjectInput: UpdateDevopsProjectInput;
  UpdateDevopsTeamInput: UpdateDevopsTeamInput;
  UpdateDevopsWorkItemInput: UpdateDevopsWorkItemInput;
  UpdateDimensionInput: UpdateDimensionInput;
  UpdateDimensionValueInput: UpdateDimensionValueInput;
  UpdateDiscussionPostInput: UpdateDiscussionPostInput;
  UpdateDocumentClassificationInput: UpdateDocumentClassificationInput;
  UpdateDocumentInput: UpdateDocumentInput;
  UpdateDocumentRequestInput: UpdateDocumentRequestInput;
  UpdateDomainInput: UpdateDomainInput;
  UpdateDomainIntegrationInput: UpdateDomainIntegrationInput;
  UpdateDomainSettingsInput: UpdateDomainSettingsInput;
  UpdateEqmBaseCalendarChangeInput: UpdateEqmBaseCalendarChangeInput;
  UpdateEqmBaseCalendarInput: UpdateEqmBaseCalendarInput;
  UpdateEqmCollectionReturnChargesInput: UpdateEqmCollectionReturnChargesInput;
  UpdateEqmCommentLineInput: UpdateEqmCommentLineInput;
  UpdateEqmCustomizedCalendarChangeInput: UpdateEqmCustomizedCalendarChangeInput;
  UpdateEqmFncTestReturnChargesInput: UpdateEqmFncTestReturnChargesInput;
  UpdateEqmLocCostCenterCombInput: UpdateEqmLocCostCenterCombInput;
  UpdateEqmManufacturerInput: UpdateEqmManufacturerInput;
  UpdateEqmManufacturerModelInput: UpdateEqmManufacturerModelInput;
  UpdateEqmObjectCardFlowInput: UpdateEqmObjectCardFlowInput;
  UpdateEqmObjectComponentsInput: UpdateEqmObjectComponentsInput;
  UpdateEqmObjectCountHeaderInput: UpdateEqmObjectCountHeaderInput;
  UpdateEqmObjectCountLineInput: UpdateEqmObjectCountLineInput;
  UpdateEqmObjectGroupCardInput: UpdateEqmObjectGroupCardInput;
  UpdateEqmObjectGroupFlowInput: UpdateEqmObjectGroupFlowInput;
  UpdateEqmObjectServiceIntervalInput: UpdateEqmObjectServiceIntervalInput;
  UpdateEqmObjectSrvIntervalAggInput: UpdateEqmObjectSrvIntervalAggInput;
  UpdateEqmObjectStatusInput: UpdateEqmObjectStatusInput;
  UpdateEqmObjectTypeCardInput: UpdateEqmObjectTypeCardInput;
  UpdateEqmObjectTypeComponentsInput: UpdateEqmObjectTypeComponentsInput;
  UpdateEqmObjectTypeFlowInput: UpdateEqmObjectTypeFlowInput;
  UpdateEqmObjectTypePriceTermInput: UpdateEqmObjectTypePriceTermInput;
  UpdateEqmObjectTypeServiceIntervalInput: UpdateEqmObjectTypeServiceIntervalInput;
  UpdateEqmReRentCardInput: UpdateEqmReRentCardInput;
  UpdateEqmRentalGroupInput: UpdateEqmRentalGroupInput;
  UpdateEqmRentalKITComponentsInput: UpdateEqmRentalKitComponentsInput;
  UpdateEqmRentalKITInput: UpdateEqmRentalKitInput;
  UpdateEqmRentalKITLinesInput: UpdateEqmRentalKitLinesInput;
  UpdateEqmRentalLineDiscountInput: UpdateEqmRentalLineDiscountInput;
  UpdateEqmRentalPriceInput: UpdateEqmRentalPriceInput;
  UpdateEqmReturnChargesInput: UpdateEqmReturnChargesInput;
  UpdateEqmServiceCardInput: UpdateEqmServiceCardInput;
  UpdateEqmServiceTypeInput: UpdateEqmServiceTypeInput;
  UpdateEqmTransferHeaderInput: UpdateEqmTransferHeaderInput;
  UpdateEqmTransferLineInput: UpdateEqmTransferLineInput;
  UpdateEqmTypeFunctionalTestInput: UpdateEqmTypeFunctionalTestInput;
  UpdateEqmWorkHeaderInput: UpdateEqmWorkHeaderInput;
  UpdateEqmWorkLineInput: UpdateEqmWorkLineInput;
  UpdateErrandInput: UpdateErrandInput;
  UpdateErrandLineInput: UpdateErrandLineInput;
  UpdateErrorReportInput: UpdateErrorReportInput;
  UpdateFeedbackInput: UpdateFeedbackInput;
  UpdateFeemappingInput: UpdateFeemappingInput;
  UpdateFieldOrderInput: UpdateFieldOrderInput;
  UpdateFieldOrderLineInput: UpdateFieldOrderLineInput;
  UpdateFileInput: UpdateFileInput;
  UpdateFunctionTestHeaderInput: UpdateFunctionTestHeaderInput;
  UpdateFunctionTestLineInput: UpdateFunctionTestLineInput;
  UpdateGenBusinessPostingGroupInput: UpdateGenBusinessPostingGroupInput;
  UpdateGenJournalLineInput: UpdateGenJournalLineInput;
  UpdateGenProductPostingGroupInput: UpdateGenProductPostingGroupInput;
  UpdateInspectionInput: UpdateInspectionInput;
  UpdateInspectionUserInput: UpdateInspectionUserInput;
  UpdateIntegrationCollectionInput: UpdateIntegrationCollectionInput;
  UpdateInventoryPostingGroupInput: UpdateInventoryPostingGroupInput;
  UpdateInvitationInput: UpdateInvitationInput;
  UpdateInvoiceHeaderInput: UpdateInvoiceHeaderInput;
  UpdateInvoiceLineInput: UpdateInvoiceLineInput;
  UpdateItemCategoryInput: UpdateItemCategoryInput;
  UpdateItemDiscGroupInput: UpdateItemDiscGroupInput;
  UpdateItemInput: UpdateItemInput;
  UpdateItemReferenceInput: UpdateItemReferenceInput;
  UpdateItemRestrictionInput: UpdateItemRestrictionInput;
  UpdateItemUnitOfMeasureInput: UpdateItemUnitOfMeasureInput;
  UpdateItemVendorInput: UpdateItemVendorInput;
  UpdateJobTaskInput: UpdateJobTaskInput;
  UpdateKliHeaderInput: UpdateKliHeaderInput;
  UpdateKliReasonCodeInput: UpdateKliReasonCodeInput;
  UpdateLeadInput: UpdateLeadInput;
  UpdateLocationInput: UpdateLocationInput;
  UpdateMagentoOrderStatusInput: UpdateMagentoOrderStatusInput;
  UpdateMagentoSettingsInput: UpdateMagentoSettingsInput;
  UpdateManufacturerInput: UpdateManufacturerInput;
  UpdateNavUserInput: UpdateNavUserInput;
  UpdateObjectCommentLineInput: UpdateObjectCommentLineInput;
  UpdateObjectFamilyInput: UpdateObjectFamilyInput;
  UpdateObjectGroupInput: UpdateObjectGroupInput;
  UpdateObjectInput: UpdateObjectInput;
  UpdateObjectReturnHeaderInput: UpdateObjectReturnHeaderInput;
  UpdateObjectReturnLineInput: UpdateObjectReturnLineInput;
  UpdateObjectTypeInput: UpdateObjectTypeInput;
  UpdateOcrAnalysisInput: UpdateOcrAnalysisInput;
  UpdatePaymentMethodInput: UpdatePaymentMethodInput;
  UpdatePaymentTermsInput: UpdatePaymentTermsInput;
  UpdatePlatformFeatureInput: UpdatePlatformFeatureInput;
  UpdatePostCodeInput: UpdatePostCodeInput;
  UpdateProjectInput: UpdateProjectInput;
  UpdatePurchCrMemoHeaderInput: UpdatePurchCrMemoHeaderInput;
  UpdatePurchCrMemoLineInput: UpdatePurchCrMemoLineInput;
  UpdatePurchInvHeaderInput: UpdatePurchInvHeaderInput;
  UpdatePurchInvLineInput: UpdatePurchInvLineInput;
  UpdatePurchRcptHeaderInput: UpdatePurchRcptHeaderInput;
  UpdatePurchRcptLineInput: UpdatePurchRcptLineInput;
  UpdatePurchaseHeaderInput: UpdatePurchaseHeaderInput;
  UpdatePurchaseLineInput: UpdatePurchaseLineInput;
  UpdatePurchasingInput: UpdatePurchasingInput;
  UpdateQuoteHeaderInput: UpdateQuoteHeaderInput;
  UpdateQuoteLineInput: UpdateQuoteLineInput;
  UpdateQuoteTaskGroupInput: UpdateQuoteTaskGroupInput;
  UpdateRentalHeaderInput: UpdateRentalHeaderInput;
  UpdateRentalLineInput: UpdateRentalLineInput;
  UpdateRentalLineSnapshotInput: UpdateRentalLineSnapshotInput;
  UpdateRentalPostedCollHeaderInput: UpdateRentalPostedCollHeaderInput;
  UpdateRentalPostedCollLineInput: UpdateRentalPostedCollLineInput;
  UpdateRentalPostedShptHeaderInput: UpdateRentalPostedShptHeaderInput;
  UpdateRentalPostedShptLineInput: UpdateRentalPostedShptLineInput;
  UpdateRentalPriceTermsInput: UpdateRentalPriceTermsInput;
  UpdateRentalReturnEntryInput: UpdateRentalReturnEntryInput;
  UpdateRequestHeaderInput: UpdateRequestHeaderInput;
  UpdateRequestLineInput: UpdateRequestLineInput;
  UpdateReservationRequestHeaderInput: UpdateReservationRequestHeaderInput;
  UpdateReservationRequestLineInput: UpdateReservationRequestLineInput;
  UpdateResourceInput: UpdateResourceInput;
  UpdateResponsibilityCenterInput: UpdateResponsibilityCenterInput;
  UpdateReturnRequestHeaderInput: UpdateReturnRequestHeaderInput;
  UpdateReturnRequestLineInput: UpdateReturnRequestLineInput;
  UpdateRiskAnalysisHeaderInput: UpdateRiskAnalysisHeaderInput;
  UpdateRiskAnalysisLineInput: UpdateRiskAnalysisLineInput;
  UpdateRiskAnalysisSignatureInput: UpdateRiskAnalysisSignatureInput;
  UpdateSalesHeaderArchiveInput: UpdateSalesHeaderArchiveInput;
  UpdateSalesHeaderHistoryInput: UpdateSalesHeaderHistoryInput;
  UpdateSalesHeaderInput: UpdateSalesHeaderInput;
  UpdateSalesHeaderRequestInput: UpdateSalesHeaderRequestInput;
  UpdateSalesLineArchiveInput: UpdateSalesLineArchiveInput;
  UpdateSalesLineInput: UpdateSalesLineInput;
  UpdateSalesLineRequestInput: UpdateSalesLineRequestInput;
  UpdateSalesPriceInput: UpdateSalesPriceInput;
  UpdateSalesShipmentHeaderInput: UpdateSalesShipmentHeaderInput;
  UpdateSalesShipmentLineInput: UpdateSalesShipmentLineInput;
  UpdateSalespersonInput: UpdateSalespersonInput;
  UpdateServiceItemGroupInput: UpdateServiceItemGroupInput;
  UpdateShipmentMethodInput: UpdateShipmentMethodInput;
  UpdateShippingAgentInput: UpdateShippingAgentInput;
  UpdateSignRequestInput: UpdateSignRequestInput;
  UpdateSignatureInput: UpdateSignatureInput;
  UpdateSpecialEquipmentInput: UpdateSpecialEquipmentInput;
  UpdateStockkeepingUnitInput: UpdateStockkeepingUnitInput;
  UpdateTariffNumberInput: UpdateTariffNumberInput;
  UpdateTaxGroupInput: UpdateTaxGroupInput;
  UpdateTimeRegistrationInput: UpdateTimeRegistrationInput;
  UpdateTimeSheetDetailInput: UpdateTimeSheetDetailInput;
  UpdateTimeSheetLineInput: UpdateTimeSheetLineInput;
  UpdateTransactionSpecificationInput: UpdateTransactionSpecificationInput;
  UpdateTransactionTypeInput: UpdateTransactionTypeInput;
  UpdateTransportMethodInput: UpdateTransportMethodInput;
  UpdateUnitOfMeasureInput: UpdateUnitOfMeasureInput;
  UpdateUserFavoritesInput: UpdateUserFavoritesInput;
  UpdateUserInput: UpdateUserInput;
  UpdateVatBusinessPostingGroupInput: UpdateVatBusinessPostingGroupInput;
  UpdateVatProductPostingGroupInput: UpdateVatProductPostingGroupInput;
  UpdateVendorBankAccountInput: UpdateVendorBankAccountInput;
  UpdateVendorInput: UpdateVendorInput;
  UpdateVendorPostingGroupInput: UpdateVendorPostingGroupInput;
  UpdateWarehouseClassInput: UpdateWarehouseClassInput;
  UpdateWebBookingInput: UpdateWebBookingInput;
  UpdateWebhookSubscriptionInput: UpdateWebhookSubscriptionInput;
  UpdateWorkLogInput: UpdateWorkLogInput;
  UpdateWorkTypeInput: UpdateWorkTypeInput;
  UploadAppCodesResponse: ResolverTypeWrapper<UploadAppCodesResponse>;
  UploadDomainImageInput: UploadDomainImageInput;
  User: ResolverTypeWrapper<User>;
  UserConnection: ResolverTypeWrapper<UserConnection>;
  UserConnectionEdge: ResolverTypeWrapper<UserConnectionEdge>;
  UserDeleteResponse: ResolverTypeWrapper<UserDeleteResponse>;
  UserFavorites: ResolverTypeWrapper<UserFavorites>;
  UserFavoritesConnection: ResolverTypeWrapper<UserFavoritesConnection>;
  UserFavoritesConnectionEdge: ResolverTypeWrapper<UserFavoritesConnectionEdge>;
  UserFavoritesDeleteResponse: ResolverTypeWrapper<UserFavoritesDeleteResponse>;
  UserFavoritesManyResult: ResolverTypeWrapper<UserFavoritesManyResult>;
  UserManyResult: ResolverTypeWrapper<UserManyResult>;
  UserOperationResponse: ResolverTypeWrapper<UserOperationResponse>;
  VatBusinessPostingGroup: ResolverTypeWrapper<VatBusinessPostingGroup>;
  VatBusinessPostingGroupConnection: ResolverTypeWrapper<VatBusinessPostingGroupConnection>;
  VatBusinessPostingGroupConnectionEdge: ResolverTypeWrapper<VatBusinessPostingGroupConnectionEdge>;
  VatBusinessPostingGroupDeleteResponse: ResolverTypeWrapper<VatBusinessPostingGroupDeleteResponse>;
  VatBusinessPostingGroupManyResult: ResolverTypeWrapper<VatBusinessPostingGroupManyResult>;
  VatProductPostingGroup: ResolverTypeWrapper<VatProductPostingGroup>;
  VatProductPostingGroupConnection: ResolverTypeWrapper<VatProductPostingGroupConnection>;
  VatProductPostingGroupConnectionEdge: ResolverTypeWrapper<VatProductPostingGroupConnectionEdge>;
  VatProductPostingGroupDeleteResponse: ResolverTypeWrapper<VatProductPostingGroupDeleteResponse>;
  VatProductPostingGroupManyResult: ResolverTypeWrapper<VatProductPostingGroupManyResult>;
  Vendor: ResolverTypeWrapper<Vendor>;
  VendorBankAccount: ResolverTypeWrapper<VendorBankAccount>;
  VendorBankAccountConnection: ResolverTypeWrapper<VendorBankAccountConnection>;
  VendorBankAccountConnectionEdge: ResolverTypeWrapper<VendorBankAccountConnectionEdge>;
  VendorBankAccountDeleteResponse: ResolverTypeWrapper<VendorBankAccountDeleteResponse>;
  VendorBankAccountManyResult: ResolverTypeWrapper<VendorBankAccountManyResult>;
  VendorConnection: ResolverTypeWrapper<VendorConnection>;
  VendorConnectionEdge: ResolverTypeWrapper<VendorConnectionEdge>;
  VendorDeleteResponse: ResolverTypeWrapper<VendorDeleteResponse>;
  VendorManyResult: ResolverTypeWrapper<VendorManyResult>;
  VendorPostingGroup: ResolverTypeWrapper<VendorPostingGroup>;
  VendorPostingGroupConnection: ResolverTypeWrapper<VendorPostingGroupConnection>;
  VendorPostingGroupConnectionEdge: ResolverTypeWrapper<VendorPostingGroupConnectionEdge>;
  VendorPostingGroupDeleteResponse: ResolverTypeWrapper<VendorPostingGroupDeleteResponse>;
  VendorPostingGroupManyResult: ResolverTypeWrapper<VendorPostingGroupManyResult>;
  WarehouseClass: ResolverTypeWrapper<WarehouseClass>;
  WarehouseClassConnection: ResolverTypeWrapper<WarehouseClassConnection>;
  WarehouseClassConnectionEdge: ResolverTypeWrapper<WarehouseClassConnectionEdge>;
  WarehouseClassDeleteResponse: ResolverTypeWrapper<WarehouseClassDeleteResponse>;
  WarehouseClassManyResult: ResolverTypeWrapper<WarehouseClassManyResult>;
  WebBooking: ResolverTypeWrapper<WebBooking>;
  WebBookingConnection: ResolverTypeWrapper<WebBookingConnection>;
  WebBookingConnectionEdge: ResolverTypeWrapper<WebBookingConnectionEdge>;
  WebBookingDeleteResponse: ResolverTypeWrapper<WebBookingDeleteResponse>;
  WebBookingManyResult: ResolverTypeWrapper<WebBookingManyResult>;
  WebhookSubscription: ResolverTypeWrapper<WebhookSubscription>;
  WebhookSubscriptionConnection: ResolverTypeWrapper<WebhookSubscriptionConnection>;
  WebhookSubscriptionConnectionEdge: ResolverTypeWrapper<WebhookSubscriptionConnectionEdge>;
  WebhookSubscriptionDeleteResponse: ResolverTypeWrapper<WebhookSubscriptionDeleteResponse>;
  WebhookSubscriptionEventTypeEnum: WebhookSubscriptionEventTypeEnum;
  WebhookSubscriptionManyResult: ResolverTypeWrapper<WebhookSubscriptionManyResult>;
  WorkLog: ResolverTypeWrapper<WorkLog>;
  WorkLogConnection: ResolverTypeWrapper<WorkLogConnection>;
  WorkLogConnectionEdge: ResolverTypeWrapper<WorkLogConnectionEdge>;
  WorkLogDeleteResponse: ResolverTypeWrapper<WorkLogDeleteResponse>;
  WorkLogManyResult: ResolverTypeWrapper<WorkLogManyResult>;
  WorkType: ResolverTypeWrapper<WorkType>;
  WorkTypeConnection: ResolverTypeWrapper<WorkTypeConnection>;
  WorkTypeConnectionEdge: ResolverTypeWrapper<WorkTypeConnectionEdge>;
  WorkTypeDeleteResponse: ResolverTypeWrapper<WorkTypeDeleteResponse>;
  WorkTypeManyResult: ResolverTypeWrapper<WorkTypeManyResult>;
  createCompanyInvitationResponse: ResolverTypeWrapper<CreateCompanyInvitationResponse>;
  customerDomainsLimitedInput: CustomerDomainsLimitedInput;
  customerDomainsLimitedUpsertInput: CustomerDomainsLimitedUpsertInput;
  domainsLimitedInput: DomainsLimitedInput;
  setCustomerDomainImageInput: SetCustomerDomainImageInput;
  syncUsersResponse: ResolverTypeWrapper<SyncUsersResponse>;
  toggleFavoriteProjectInput: ToggleFavoriteProjectInput;
  toggleSuplierResponse: ResolverTypeWrapper<ToggleSuplierResponse>;
  toggleSupplierInput: ToggleSupplierInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AcceptInivitationResponse: AcceptInivitationResponse;
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  AccessRequest: AccessRequest;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  AccessRequestConnection: AccessRequestConnection;
  Int: Scalars['Int'];
  AccessRequestConnectionEdge: AccessRequestConnectionEdge;
  AccessRequestDeleteResponse: AccessRequestDeleteResponse;
  AccessRequestManyResult: AccessRequestManyResult;
  AccumulatedRentCost: AccumulatedRentCost;
  AccumulatedRentCostFilter: AccumulatedRentCostFilter;
  AdditionalItem: AdditionalItem;
  AdditionalItemConnection: AdditionalItemConnection;
  AdditionalItemConnectionEdge: AdditionalItemConnectionEdge;
  AdditionalItemDeleteResponse: AdditionalItemDeleteResponse;
  AdditionalItemManyResult: AdditionalItemManyResult;
  Address: Address;
  AddressConnection: AddressConnection;
  AddressConnectionEdge: AddressConnectionEdge;
  AddressDeleteResponse: AddressDeleteResponse;
  AddressManyResult: AddressManyResult;
  Agreement: Agreement;
  AgreementConnection: AgreementConnection;
  AgreementConnectionEdge: AgreementConnectionEdge;
  AgreementDeleteResponse: AgreementDeleteResponse;
  AgreementManyResult: AgreementManyResult;
  Annotation: Annotation;
  AnnotationConnection: AnnotationConnection;
  AnnotationConnectionEdge: AnnotationConnectionEdge;
  AnnotationDeleteResponse: AnnotationDeleteResponse;
  AnnotationManyResult: AnnotationManyResult;
  AppPushtoken: AppPushtoken;
  ApplicationModule: ApplicationModule;
  ApplicationModuleConnection: ApplicationModuleConnection;
  ApplicationModuleConnectionEdge: ApplicationModuleConnectionEdge;
  ApplicationModuleDeleteResponse: ApplicationModuleDeleteResponse;
  ApplicationModuleManyResult: ApplicationModuleManyResult;
  AuthError: AuthError;
  AuthResponse: AuthResponse;
  AutoComplete: AutoComplete;
  AutocompleteInput: AutocompleteInput;
  BcMappingField: BcMappingField;
  BcMappingFieldConnection: BcMappingFieldConnection;
  BcMappingFieldConnectionEdge: BcMappingFieldConnectionEdge;
  BcMappingFieldDeleteResponse: BcMappingFieldDeleteResponse;
  BcMappingFieldManyResult: BcMappingFieldManyResult;
  BcMappingTable: BcMappingTable;
  BcMappingTableConnection: BcMappingTableConnection;
  BcMappingTableConnectionEdge: BcMappingTableConnectionEdge;
  BcMappingTableDeleteResponse: BcMappingTableDeleteResponse;
  BcMappingTableManyResult: BcMappingTableManyResult;
  BcSettings: BcSettings;
  BcSettingsConnection: BcSettingsConnection;
  BcSettingsConnectionEdge: BcSettingsConnectionEdge;
  BcSettingsDeleteResponse: BcSettingsDeleteResponse;
  BcSettingsManyResult: BcSettingsManyResult;
  BulkItemGroup: BulkItemGroup;
  BulkItemGroupConnection: BulkItemGroupConnection;
  BulkItemGroupConnectionEdge: BulkItemGroupConnectionEdge;
  BulkItemGroupDeleteResponse: BulkItemGroupDeleteResponse;
  BulkItemGroupManyResult: BulkItemGroupManyResult;
  CauseOfAbsence: CauseOfAbsence;
  CauseOfAbsenceConnection: CauseOfAbsenceConnection;
  CauseOfAbsenceConnectionEdge: CauseOfAbsenceConnectionEdge;
  CauseOfAbsenceDeleteResponse: CauseOfAbsenceDeleteResponse;
  CauseOfAbsenceManyResult: CauseOfAbsenceManyResult;
  ChangeObjectTypeImagePayload: ChangeObjectTypeImagePayload;
  ClearFunctionTestInput: ClearFunctionTestInput;
  ClearFunctionalTestReusult: ClearFunctionalTestReusult;
  Company: Company;
  CompanyConnection: CompanyConnection;
  CompanyConnectionEdge: CompanyConnectionEdge;
  CompanyDeleteResponse: CompanyDeleteResponse;
  CompanyInvitationInput: CompanyInvitationInput;
  CompanyManyResult: CompanyManyResult;
  ConsignmentHeader: ConsignmentHeader;
  ConsignmentHeaderConnection: ConsignmentHeaderConnection;
  ConsignmentHeaderConnectionEdge: ConsignmentHeaderConnectionEdge;
  ConsignmentHeaderDeleteResponse: ConsignmentHeaderDeleteResponse;
  ConsignmentHeaderManyResult: ConsignmentHeaderManyResult;
  ConsignmentLine: ConsignmentLine;
  ConsignmentLineConnection: ConsignmentLineConnection;
  ConsignmentLineConnectionEdge: ConsignmentLineConnectionEdge;
  ConsignmentLineDeleteResponse: ConsignmentLineDeleteResponse;
  ConsignmentLineManyResult: ConsignmentLineManyResult;
  Contact: Contact;
  ContactConnection: ContactConnection;
  ContactConnectionEdge: ContactConnectionEdge;
  ContactDeleteResponse: ContactDeleteResponse;
  ContactManyResult: ContactManyResult;
  CountryRegion: CountryRegion;
  CountryRegionConnection: CountryRegionConnection;
  CountryRegionConnectionEdge: CountryRegionConnectionEdge;
  CountryRegionDeleteResponse: CountryRegionDeleteResponse;
  CountryRegionManyResult: CountryRegionManyResult;
  CreateAccessRequestInput: CreateAccessRequestInput;
  CreateSerciceAccountInput: CreateSerciceAccountInput;
  CreateWorkshopOrderInput: CreateWorkshopOrderInput;
  CreateWorkshopOrderResponse: CreateWorkshopOrderResponse;
  Currency: Currency;
  CurrencyConnection: CurrencyConnection;
  CurrencyConnectionEdge: CurrencyConnectionEdge;
  CurrencyDeleteResponse: CurrencyDeleteResponse;
  CurrencyExchangeRate: CurrencyExchangeRate;
  CurrencyExchangeRateConnection: CurrencyExchangeRateConnection;
  CurrencyExchangeRateConnectionEdge: CurrencyExchangeRateConnectionEdge;
  CurrencyExchangeRateDeleteResponse: CurrencyExchangeRateDeleteResponse;
  CurrencyExchangeRateManyResult: CurrencyExchangeRateManyResult;
  CurrencyManyResult: CurrencyManyResult;
  CustomerDomain: CustomerDomain;
  CustomerDomainConnection: CustomerDomainConnection;
  CustomerDomainConnectionEdge: CustomerDomainConnectionEdge;
  CustomerDomainDeleteResponse: CustomerDomainDeleteResponse;
  CustomerDomainLimited: CustomerDomainLimited;
  CustomerDomainManyResult: CustomerDomainManyResult;
  CustomerPostingGroup: CustomerPostingGroup;
  CustomerPostingGroupConnection: CustomerPostingGroupConnection;
  CustomerPostingGroupConnectionEdge: CustomerPostingGroupConnectionEdge;
  CustomerPostingGroupDeleteResponse: CustomerPostingGroupDeleteResponse;
  CustomerPostingGroupManyResult: CustomerPostingGroupManyResult;
  CustomerProject: CustomerProject;
  CustomerProjectConnection: CustomerProjectConnection;
  CustomerProjectConnectionEdge: CustomerProjectConnectionEdge;
  CustomerProjectDeleteResponse: CustomerProjectDeleteResponse;
  CustomerProjectManyResult: CustomerProjectManyResult;
  CustomerSubProject: CustomerSubProject;
  CustomerSubProjectConnection: CustomerSubProjectConnection;
  CustomerSubProjectConnectionEdge: CustomerSubProjectConnectionEdge;
  CustomerSubProjectDeleteResponse: CustomerSubProjectDeleteResponse;
  CustomerSubProjectManyResult: CustomerSubProjectManyResult;
  DailyRentalEvents: DailyRentalEvents;
  DailyTransactionResponse: DailyTransactionResponse;
  DateTime: Scalars['DateTime'];
  DeleteResponse: DeleteResponse;
  DeletedRecord: DeletedRecord;
  DeletedRecordRef: DeletedRecordRef;
  Deliverytime: Deliverytime;
  DeliverytimeConnection: DeliverytimeConnection;
  DeliverytimeConnectionEdge: DeliverytimeConnectionEdge;
  DeliverytimeDeleteResponse: DeliverytimeDeleteResponse;
  DeliverytimeManyResult: DeliverytimeManyResult;
  DevopsProject: DevopsProject;
  DevopsProjectConnection: DevopsProjectConnection;
  DevopsProjectConnectionEdge: DevopsProjectConnectionEdge;
  DevopsProjectDeleteResponse: DevopsProjectDeleteResponse;
  DevopsProjectManyResult: DevopsProjectManyResult;
  DevopsTeam: DevopsTeam;
  DevopsTeamConnection: DevopsTeamConnection;
  DevopsTeamConnectionEdge: DevopsTeamConnectionEdge;
  DevopsTeamDeleteResponse: DevopsTeamDeleteResponse;
  DevopsTeamManyResult: DevopsTeamManyResult;
  DevopsWorkItem: DevopsWorkItem;
  DevopsWorkItemConnection: DevopsWorkItemConnection;
  DevopsWorkItemConnectionEdge: DevopsWorkItemConnectionEdge;
  DevopsWorkItemDeleteResponse: DevopsWorkItemDeleteResponse;
  DevopsWorkItemManyResult: DevopsWorkItemManyResult;
  Dimension: Dimension;
  DimensionConnection: DimensionConnection;
  DimensionConnectionEdge: DimensionConnectionEdge;
  DimensionDeleteResponse: DimensionDeleteResponse;
  DimensionManyResult: DimensionManyResult;
  DimensionValue: DimensionValue;
  DimensionValueConnection: DimensionValueConnection;
  DimensionValueConnectionEdge: DimensionValueConnectionEdge;
  DimensionValueDeleteResponse: DimensionValueDeleteResponse;
  DimensionValueManyResult: DimensionValueManyResult;
  DiscussionPost: DiscussionPost;
  DiscussionPostConnection: DiscussionPostConnection;
  DiscussionPostConnectionEdge: DiscussionPostConnectionEdge;
  DiscussionPostDeleteResponse: DiscussionPostDeleteResponse;
  DiscussionPostManyResult: DiscussionPostManyResult;
  Document: Document;
  DocumentClassification: DocumentClassification;
  DocumentClassificationConnection: DocumentClassificationConnection;
  DocumentClassificationConnectionEdge: DocumentClassificationConnectionEdge;
  DocumentClassificationDeleteResponse: DocumentClassificationDeleteResponse;
  DocumentClassificationManyResult: DocumentClassificationManyResult;
  DocumentConnection: DocumentConnection;
  DocumentConnectionEdge: DocumentConnectionEdge;
  DocumentDeleteResponse: DocumentDeleteResponse;
  DocumentManyResult: DocumentManyResult;
  DocumentRequest: DocumentRequest;
  DocumentRequestConnection: DocumentRequestConnection;
  DocumentRequestConnectionEdge: DocumentRequestConnectionEdge;
  DocumentRequestDeleteResponse: DocumentRequestDeleteResponse;
  DocumentRequestManyResult: DocumentRequestManyResult;
  Domain: Domain;
  DomainConnection: DomainConnection;
  DomainConnectionEdge: DomainConnectionEdge;
  DomainConnectionTestResult: DomainConnectionTestResult;
  DomainDeleteResponse: DomainDeleteResponse;
  DomainIntegration: DomainIntegration;
  DomainIntegrationConnection: DomainIntegrationConnection;
  DomainIntegrationConnectionEdge: DomainIntegrationConnectionEdge;
  DomainIntegrationDeleteResponse: DomainIntegrationDeleteResponse;
  DomainIntegrationManyResult: DomainIntegrationManyResult;
  DomainInvitationInput: DomainInvitationInput;
  DomainLimited: DomainLimited;
  DomainManyResult: DomainManyResult;
  DomainSettings: DomainSettings;
  DomainSettingsConnection: DomainSettingsConnection;
  DomainSettingsConnectionEdge: DomainSettingsConnectionEdge;
  DomainSettingsDeleteResponse: DomainSettingsDeleteResponse;
  DomainSettingsManyResult: DomainSettingsManyResult;
  EmailAddress: Scalars['EmailAddress'];
  EmailRecipientInput: EmailRecipientInput;
  EqmBaseCalendar: EqmBaseCalendar;
  EqmBaseCalendarChange: EqmBaseCalendarChange;
  EqmBaseCalendarChangeConnection: EqmBaseCalendarChangeConnection;
  EqmBaseCalendarChangeConnectionEdge: EqmBaseCalendarChangeConnectionEdge;
  EqmBaseCalendarChangeDeleteResponse: EqmBaseCalendarChangeDeleteResponse;
  EqmBaseCalendarChangeManyResult: EqmBaseCalendarChangeManyResult;
  EqmBaseCalendarConnection: EqmBaseCalendarConnection;
  EqmBaseCalendarConnectionEdge: EqmBaseCalendarConnectionEdge;
  EqmBaseCalendarDeleteResponse: EqmBaseCalendarDeleteResponse;
  EqmBaseCalendarManyResult: EqmBaseCalendarManyResult;
  EqmCollectionReturnCharges: EqmCollectionReturnCharges;
  EqmCollectionReturnChargesConnection: EqmCollectionReturnChargesConnection;
  EqmCollectionReturnChargesConnectionEdge: EqmCollectionReturnChargesConnectionEdge;
  EqmCollectionReturnChargesDeleteResponse: EqmCollectionReturnChargesDeleteResponse;
  EqmCollectionReturnChargesManyResult: EqmCollectionReturnChargesManyResult;
  EqmCommentLine: EqmCommentLine;
  EqmCommentLineConnection: EqmCommentLineConnection;
  EqmCommentLineConnectionEdge: EqmCommentLineConnectionEdge;
  EqmCommentLineDeleteResponse: EqmCommentLineDeleteResponse;
  EqmCommentLineManyResult: EqmCommentLineManyResult;
  EqmCustomizedCalendarChange: EqmCustomizedCalendarChange;
  EqmCustomizedCalendarChangeConnection: EqmCustomizedCalendarChangeConnection;
  EqmCustomizedCalendarChangeConnectionEdge: EqmCustomizedCalendarChangeConnectionEdge;
  EqmCustomizedCalendarChangeDeleteResponse: EqmCustomizedCalendarChangeDeleteResponse;
  EqmCustomizedCalendarChangeManyResult: EqmCustomizedCalendarChangeManyResult;
  EqmFncTestReturnCharges: EqmFncTestReturnCharges;
  EqmFncTestReturnChargesConnection: EqmFncTestReturnChargesConnection;
  EqmFncTestReturnChargesConnectionEdge: EqmFncTestReturnChargesConnectionEdge;
  EqmFncTestReturnChargesDeleteResponse: EqmFncTestReturnChargesDeleteResponse;
  EqmFncTestReturnChargesManyResult: EqmFncTestReturnChargesManyResult;
  EqmLocCostCenterComb: EqmLocCostCenterComb;
  EqmLocCostCenterCombConnection: EqmLocCostCenterCombConnection;
  EqmLocCostCenterCombConnectionEdge: EqmLocCostCenterCombConnectionEdge;
  EqmLocCostCenterCombDeleteResponse: EqmLocCostCenterCombDeleteResponse;
  EqmLocCostCenterCombManyResult: EqmLocCostCenterCombManyResult;
  EqmManufacturer: EqmManufacturer;
  EqmManufacturerConnection: EqmManufacturerConnection;
  EqmManufacturerConnectionEdge: EqmManufacturerConnectionEdge;
  EqmManufacturerDeleteResponse: EqmManufacturerDeleteResponse;
  EqmManufacturerManyResult: EqmManufacturerManyResult;
  EqmManufacturerModel: EqmManufacturerModel;
  EqmManufacturerModelConnection: EqmManufacturerModelConnection;
  EqmManufacturerModelConnectionEdge: EqmManufacturerModelConnectionEdge;
  EqmManufacturerModelDeleteResponse: EqmManufacturerModelDeleteResponse;
  EqmManufacturerModelManyResult: EqmManufacturerModelManyResult;
  EqmObjectCardFlow: EqmObjectCardFlow;
  EqmObjectCardFlowConnection: EqmObjectCardFlowConnection;
  EqmObjectCardFlowConnectionEdge: EqmObjectCardFlowConnectionEdge;
  EqmObjectCardFlowDeleteResponse: EqmObjectCardFlowDeleteResponse;
  EqmObjectCardFlowManyResult: EqmObjectCardFlowManyResult;
  EqmObjectComponents: EqmObjectComponents;
  EqmObjectComponentsConnection: EqmObjectComponentsConnection;
  EqmObjectComponentsConnectionEdge: EqmObjectComponentsConnectionEdge;
  EqmObjectComponentsDeleteResponse: EqmObjectComponentsDeleteResponse;
  EqmObjectComponentsManyResult: EqmObjectComponentsManyResult;
  EqmObjectCountHeader: EqmObjectCountHeader;
  EqmObjectCountHeaderConnection: EqmObjectCountHeaderConnection;
  EqmObjectCountHeaderConnectionEdge: EqmObjectCountHeaderConnectionEdge;
  EqmObjectCountHeaderDeleteResponse: EqmObjectCountHeaderDeleteResponse;
  EqmObjectCountHeaderManyResult: EqmObjectCountHeaderManyResult;
  EqmObjectCountLine: EqmObjectCountLine;
  EqmObjectCountLineConnection: EqmObjectCountLineConnection;
  EqmObjectCountLineConnectionEdge: EqmObjectCountLineConnectionEdge;
  EqmObjectCountLineDeleteResponse: EqmObjectCountLineDeleteResponse;
  EqmObjectCountLineManyResult: EqmObjectCountLineManyResult;
  EqmObjectGroupCard: EqmObjectGroupCard;
  EqmObjectGroupCardConnection: EqmObjectGroupCardConnection;
  EqmObjectGroupCardConnectionEdge: EqmObjectGroupCardConnectionEdge;
  EqmObjectGroupCardDeleteResponse: EqmObjectGroupCardDeleteResponse;
  EqmObjectGroupCardManyResult: EqmObjectGroupCardManyResult;
  EqmObjectGroupFlow: EqmObjectGroupFlow;
  EqmObjectGroupFlowConnection: EqmObjectGroupFlowConnection;
  EqmObjectGroupFlowConnectionEdge: EqmObjectGroupFlowConnectionEdge;
  EqmObjectGroupFlowDeleteResponse: EqmObjectGroupFlowDeleteResponse;
  EqmObjectGroupFlowManyResult: EqmObjectGroupFlowManyResult;
  EqmObjectServiceInterval: EqmObjectServiceInterval;
  EqmObjectServiceIntervalConnection: EqmObjectServiceIntervalConnection;
  EqmObjectServiceIntervalConnectionEdge: EqmObjectServiceIntervalConnectionEdge;
  EqmObjectServiceIntervalDeleteResponse: EqmObjectServiceIntervalDeleteResponse;
  EqmObjectServiceIntervalManyResult: EqmObjectServiceIntervalManyResult;
  EqmObjectSrvIntervalAgg: EqmObjectSrvIntervalAgg;
  EqmObjectSrvIntervalAggConnection: EqmObjectSrvIntervalAggConnection;
  EqmObjectSrvIntervalAggConnectionEdge: EqmObjectSrvIntervalAggConnectionEdge;
  EqmObjectSrvIntervalAggDeleteResponse: EqmObjectSrvIntervalAggDeleteResponse;
  EqmObjectSrvIntervalAggManyResult: EqmObjectSrvIntervalAggManyResult;
  EqmObjectStatus: EqmObjectStatus;
  EqmObjectStatusConnection: EqmObjectStatusConnection;
  EqmObjectStatusConnectionEdge: EqmObjectStatusConnectionEdge;
  EqmObjectStatusDeleteResponse: EqmObjectStatusDeleteResponse;
  EqmObjectStatusManyResult: EqmObjectStatusManyResult;
  EqmObjectTypeCard: EqmObjectTypeCard;
  EqmObjectTypeCardConnection: EqmObjectTypeCardConnection;
  EqmObjectTypeCardConnectionEdge: EqmObjectTypeCardConnectionEdge;
  EqmObjectTypeCardDeleteResponse: EqmObjectTypeCardDeleteResponse;
  EqmObjectTypeCardManyResult: EqmObjectTypeCardManyResult;
  EqmObjectTypeComponents: EqmObjectTypeComponents;
  EqmObjectTypeComponentsConnection: EqmObjectTypeComponentsConnection;
  EqmObjectTypeComponentsConnectionEdge: EqmObjectTypeComponentsConnectionEdge;
  EqmObjectTypeComponentsDeleteResponse: EqmObjectTypeComponentsDeleteResponse;
  EqmObjectTypeComponentsManyResult: EqmObjectTypeComponentsManyResult;
  EqmObjectTypeFlow: EqmObjectTypeFlow;
  EqmObjectTypeFlowConnection: EqmObjectTypeFlowConnection;
  EqmObjectTypeFlowConnectionEdge: EqmObjectTypeFlowConnectionEdge;
  EqmObjectTypeFlowDeleteResponse: EqmObjectTypeFlowDeleteResponse;
  EqmObjectTypeFlowManyResult: EqmObjectTypeFlowManyResult;
  EqmObjectTypePriceTerm: EqmObjectTypePriceTerm;
  EqmObjectTypePriceTermConnection: EqmObjectTypePriceTermConnection;
  EqmObjectTypePriceTermConnectionEdge: EqmObjectTypePriceTermConnectionEdge;
  EqmObjectTypePriceTermDeleteResponse: EqmObjectTypePriceTermDeleteResponse;
  EqmObjectTypePriceTermManyResult: EqmObjectTypePriceTermManyResult;
  EqmObjectTypeServiceInterval: EqmObjectTypeServiceInterval;
  EqmObjectTypeServiceIntervalConnection: EqmObjectTypeServiceIntervalConnection;
  EqmObjectTypeServiceIntervalConnectionEdge: EqmObjectTypeServiceIntervalConnectionEdge;
  EqmObjectTypeServiceIntervalDeleteResponse: EqmObjectTypeServiceIntervalDeleteResponse;
  EqmObjectTypeServiceIntervalManyResult: EqmObjectTypeServiceIntervalManyResult;
  EqmReRentCard: EqmReRentCard;
  EqmReRentCardConnection: EqmReRentCardConnection;
  EqmReRentCardConnectionEdge: EqmReRentCardConnectionEdge;
  EqmReRentCardDeleteResponse: EqmReRentCardDeleteResponse;
  EqmReRentCardManyResult: EqmReRentCardManyResult;
  EqmRentalGroup: EqmRentalGroup;
  EqmRentalGroupConnection: EqmRentalGroupConnection;
  EqmRentalGroupConnectionEdge: EqmRentalGroupConnectionEdge;
  EqmRentalGroupDeleteResponse: EqmRentalGroupDeleteResponse;
  EqmRentalGroupManyResult: EqmRentalGroupManyResult;
  EqmRentalKIT: EqmRentalKit;
  EqmRentalKITComponents: EqmRentalKitComponents;
  EqmRentalKITComponentsConnection: EqmRentalKitComponentsConnection;
  EqmRentalKITComponentsConnectionEdge: EqmRentalKitComponentsConnectionEdge;
  EqmRentalKITComponentsDeleteResponse: EqmRentalKitComponentsDeleteResponse;
  EqmRentalKITComponentsManyResult: EqmRentalKitComponentsManyResult;
  EqmRentalKITConnection: EqmRentalKitConnection;
  EqmRentalKITConnectionEdge: EqmRentalKitConnectionEdge;
  EqmRentalKITDeleteResponse: EqmRentalKitDeleteResponse;
  EqmRentalKITLines: EqmRentalKitLines;
  EqmRentalKITLinesConnection: EqmRentalKitLinesConnection;
  EqmRentalKITLinesConnectionEdge: EqmRentalKitLinesConnectionEdge;
  EqmRentalKITLinesDeleteResponse: EqmRentalKitLinesDeleteResponse;
  EqmRentalKITLinesManyResult: EqmRentalKitLinesManyResult;
  EqmRentalKITManyResult: EqmRentalKitManyResult;
  EqmRentalLineDiscount: EqmRentalLineDiscount;
  EqmRentalLineDiscountConnection: EqmRentalLineDiscountConnection;
  EqmRentalLineDiscountConnectionEdge: EqmRentalLineDiscountConnectionEdge;
  EqmRentalLineDiscountDeleteResponse: EqmRentalLineDiscountDeleteResponse;
  EqmRentalLineDiscountManyResult: EqmRentalLineDiscountManyResult;
  EqmRentalPrice: EqmRentalPrice;
  EqmRentalPriceConnection: EqmRentalPriceConnection;
  EqmRentalPriceConnectionEdge: EqmRentalPriceConnectionEdge;
  EqmRentalPriceDeleteResponse: EqmRentalPriceDeleteResponse;
  EqmRentalPriceManyResult: EqmRentalPriceManyResult;
  EqmReturnCharges: EqmReturnCharges;
  EqmReturnChargesConnection: EqmReturnChargesConnection;
  EqmReturnChargesConnectionEdge: EqmReturnChargesConnectionEdge;
  EqmReturnChargesDeleteResponse: EqmReturnChargesDeleteResponse;
  EqmReturnChargesManyResult: EqmReturnChargesManyResult;
  EqmServiceCard: EqmServiceCard;
  EqmServiceCardConnection: EqmServiceCardConnection;
  EqmServiceCardConnectionEdge: EqmServiceCardConnectionEdge;
  EqmServiceCardDeleteResponse: EqmServiceCardDeleteResponse;
  EqmServiceCardManyResult: EqmServiceCardManyResult;
  EqmServiceType: EqmServiceType;
  EqmServiceTypeConnection: EqmServiceTypeConnection;
  EqmServiceTypeConnectionEdge: EqmServiceTypeConnectionEdge;
  EqmServiceTypeDeleteResponse: EqmServiceTypeDeleteResponse;
  EqmServiceTypeManyResult: EqmServiceTypeManyResult;
  EqmTransferHeader: EqmTransferHeader;
  EqmTransferHeaderConnection: EqmTransferHeaderConnection;
  EqmTransferHeaderConnectionEdge: EqmTransferHeaderConnectionEdge;
  EqmTransferHeaderDeleteResponse: EqmTransferHeaderDeleteResponse;
  EqmTransferHeaderManyResult: EqmTransferHeaderManyResult;
  EqmTransferLine: EqmTransferLine;
  EqmTransferLineConnection: EqmTransferLineConnection;
  EqmTransferLineConnectionEdge: EqmTransferLineConnectionEdge;
  EqmTransferLineDeleteResponse: EqmTransferLineDeleteResponse;
  EqmTransferLineManyResult: EqmTransferLineManyResult;
  EqmTypeFunctionalTest: EqmTypeFunctionalTest;
  EqmTypeFunctionalTestConnection: EqmTypeFunctionalTestConnection;
  EqmTypeFunctionalTestConnectionEdge: EqmTypeFunctionalTestConnectionEdge;
  EqmTypeFunctionalTestDeleteResponse: EqmTypeFunctionalTestDeleteResponse;
  EqmTypeFunctionalTestManyResult: EqmTypeFunctionalTestManyResult;
  EqmWorkHeader: EqmWorkHeader;
  EqmWorkHeaderConnection: EqmWorkHeaderConnection;
  EqmWorkHeaderConnectionEdge: EqmWorkHeaderConnectionEdge;
  EqmWorkHeaderDeleteResponse: EqmWorkHeaderDeleteResponse;
  EqmWorkHeaderManyResult: EqmWorkHeaderManyResult;
  EqmWorkLine: EqmWorkLine;
  EqmWorkLineConnection: EqmWorkLineConnection;
  EqmWorkLineConnectionEdge: EqmWorkLineConnectionEdge;
  EqmWorkLineDeleteResponse: EqmWorkLineDeleteResponse;
  EqmWorkLineManyResult: EqmWorkLineManyResult;
  Errand: Errand;
  ErrandConnection: ErrandConnection;
  ErrandConnectionEdge: ErrandConnectionEdge;
  ErrandDeleteResponse: ErrandDeleteResponse;
  ErrandLine: ErrandLine;
  ErrandLineConnection: ErrandLineConnection;
  ErrandLineConnectionEdge: ErrandLineConnectionEdge;
  ErrandLineDeleteResponse: ErrandLineDeleteResponse;
  ErrandLineManyResult: ErrandLineManyResult;
  ErrandManyResult: ErrandManyResult;
  ErrorReport: ErrorReport;
  ErrorReportConnection: ErrorReportConnection;
  ErrorReportConnectionEdge: ErrorReportConnectionEdge;
  ErrorReportDeleteResponse: ErrorReportDeleteResponse;
  ErrorReportManyResult: ErrorReportManyResult;
  Feedback: Feedback;
  FeedbackConnection: FeedbackConnection;
  FeedbackConnectionEdge: FeedbackConnectionEdge;
  FeedbackDeleteResponse: FeedbackDeleteResponse;
  FeedbackManyResult: FeedbackManyResult;
  Feemapping: Feemapping;
  FeemappingConnection: FeemappingConnection;
  FeemappingConnectionEdge: FeemappingConnectionEdge;
  FeemappingDeleteResponse: FeemappingDeleteResponse;
  FeemappingManyResult: FeemappingManyResult;
  FieldOrder: FieldOrder;
  FieldOrderConnection: FieldOrderConnection;
  FieldOrderConnectionEdge: FieldOrderConnectionEdge;
  FieldOrderDeleteResponse: FieldOrderDeleteResponse;
  FieldOrderLine: FieldOrderLine;
  FieldOrderLineConnection: FieldOrderLineConnection;
  FieldOrderLineConnectionEdge: FieldOrderLineConnectionEdge;
  FieldOrderLineDeleteResponse: FieldOrderLineDeleteResponse;
  FieldOrderLineManyResult: FieldOrderLineManyResult;
  FieldOrderManyResult: FieldOrderManyResult;
  File: File;
  FileConnection: FileConnection;
  FileConnectionEdge: FileConnectionEdge;
  FileDeleteResponse: FileDeleteResponse;
  FileManyResult: FileManyResult;
  FilterAccessRequestInput: FilterAccessRequestInput;
  FilterAdditionalItemInput: FilterAdditionalItemInput;
  FilterAddressInput: FilterAddressInput;
  FilterAgreementInput: FilterAgreementInput;
  FilterAnnotationInput: FilterAnnotationInput;
  FilterApplicationModuleInput: FilterApplicationModuleInput;
  FilterBcMappingFieldInput: FilterBcMappingFieldInput;
  FilterBcMappingTableInput: FilterBcMappingTableInput;
  FilterBcSettingsInput: FilterBcSettingsInput;
  FilterBulkItemGroupInput: FilterBulkItemGroupInput;
  FilterCauseOfAbsenceInput: FilterCauseOfAbsenceInput;
  FilterCompanyInput: FilterCompanyInput;
  FilterConsignmentHeaderInput: FilterConsignmentHeaderInput;
  FilterConsignmentLineInput: FilterConsignmentLineInput;
  FilterContactInput: FilterContactInput;
  FilterCountryRegionInput: FilterCountryRegionInput;
  FilterCurrencyExchangeRateInput: FilterCurrencyExchangeRateInput;
  FilterCurrencyInput: FilterCurrencyInput;
  FilterCustomerDomainInput: FilterCustomerDomainInput;
  FilterCustomerPostingGroupInput: FilterCustomerPostingGroupInput;
  FilterCustomerProjectInput: FilterCustomerProjectInput;
  FilterCustomerSubProjectInput: FilterCustomerSubProjectInput;
  FilterDeliverytimeInput: FilterDeliverytimeInput;
  FilterDevopsProjectInput: FilterDevopsProjectInput;
  FilterDevopsTeamInput: FilterDevopsTeamInput;
  FilterDevopsWorkItemInput: FilterDevopsWorkItemInput;
  FilterDimensionInput: FilterDimensionInput;
  FilterDimensionValueInput: FilterDimensionValueInput;
  FilterDiscussionPostInput: FilterDiscussionPostInput;
  FilterDocumentClassificationInput: FilterDocumentClassificationInput;
  FilterDocumentInput: FilterDocumentInput;
  FilterDocumentRequestInput: FilterDocumentRequestInput;
  FilterDomainInput: FilterDomainInput;
  FilterDomainIntegrationInput: FilterDomainIntegrationInput;
  FilterDomainSettingsInput: FilterDomainSettingsInput;
  FilterEqmBaseCalendarChangeInput: FilterEqmBaseCalendarChangeInput;
  FilterEqmBaseCalendarInput: FilterEqmBaseCalendarInput;
  FilterEqmCollectionReturnChargesInput: FilterEqmCollectionReturnChargesInput;
  FilterEqmCommentLineInput: FilterEqmCommentLineInput;
  FilterEqmCustomizedCalendarChangeInput: FilterEqmCustomizedCalendarChangeInput;
  FilterEqmFncTestReturnChargesInput: FilterEqmFncTestReturnChargesInput;
  FilterEqmLocCostCenterCombInput: FilterEqmLocCostCenterCombInput;
  FilterEqmManufacturerInput: FilterEqmManufacturerInput;
  FilterEqmManufacturerModelInput: FilterEqmManufacturerModelInput;
  FilterEqmObjectCardFlowInput: FilterEqmObjectCardFlowInput;
  FilterEqmObjectComponentsInput: FilterEqmObjectComponentsInput;
  FilterEqmObjectCountHeaderInput: FilterEqmObjectCountHeaderInput;
  FilterEqmObjectCountLineInput: FilterEqmObjectCountLineInput;
  FilterEqmObjectGroupCardInput: FilterEqmObjectGroupCardInput;
  FilterEqmObjectGroupFlowInput: FilterEqmObjectGroupFlowInput;
  FilterEqmObjectServiceIntervalInput: FilterEqmObjectServiceIntervalInput;
  FilterEqmObjectSrvIntervalAggInput: FilterEqmObjectSrvIntervalAggInput;
  FilterEqmObjectStatusInput: FilterEqmObjectStatusInput;
  FilterEqmObjectTypeCardInput: FilterEqmObjectTypeCardInput;
  FilterEqmObjectTypeComponentsInput: FilterEqmObjectTypeComponentsInput;
  FilterEqmObjectTypeFlowInput: FilterEqmObjectTypeFlowInput;
  FilterEqmObjectTypePriceTermInput: FilterEqmObjectTypePriceTermInput;
  FilterEqmObjectTypeServiceIntervalInput: FilterEqmObjectTypeServiceIntervalInput;
  FilterEqmReRentCardInput: FilterEqmReRentCardInput;
  FilterEqmRentalGroupInput: FilterEqmRentalGroupInput;
  FilterEqmRentalKITComponentsInput: FilterEqmRentalKitComponentsInput;
  FilterEqmRentalKITInput: FilterEqmRentalKitInput;
  FilterEqmRentalKITLinesInput: FilterEqmRentalKitLinesInput;
  FilterEqmRentalLineDiscountInput: FilterEqmRentalLineDiscountInput;
  FilterEqmRentalPriceInput: FilterEqmRentalPriceInput;
  FilterEqmReturnChargesInput: FilterEqmReturnChargesInput;
  FilterEqmServiceCardInput: FilterEqmServiceCardInput;
  FilterEqmServiceTypeInput: FilterEqmServiceTypeInput;
  FilterEqmTransferHeaderInput: FilterEqmTransferHeaderInput;
  FilterEqmTransferLineInput: FilterEqmTransferLineInput;
  FilterEqmTypeFunctionalTestInput: FilterEqmTypeFunctionalTestInput;
  FilterEqmWorkHeaderInput: FilterEqmWorkHeaderInput;
  FilterEqmWorkLineInput: FilterEqmWorkLineInput;
  FilterErrandInput: FilterErrandInput;
  FilterErrandLineInput: FilterErrandLineInput;
  FilterErrorReportInput: FilterErrorReportInput;
  FilterFeedbackInput: FilterFeedbackInput;
  FilterFeemappingInput: FilterFeemappingInput;
  FilterFieldOrderInput: FilterFieldOrderInput;
  FilterFieldOrderLineInput: FilterFieldOrderLineInput;
  FilterFileInput: FilterFileInput;
  FilterFunctionTestHeaderInput: FilterFunctionTestHeaderInput;
  FilterFunctionTestLineInput: FilterFunctionTestLineInput;
  FilterGenBusinessPostingGroupInput: FilterGenBusinessPostingGroupInput;
  FilterGenJournalLineInput: FilterGenJournalLineInput;
  FilterGenProductPostingGroupInput: FilterGenProductPostingGroupInput;
  FilterInspectionInput: FilterInspectionInput;
  FilterInspectionUserInput: FilterInspectionUserInput;
  FilterIntegrationCollectionInput: FilterIntegrationCollectionInput;
  FilterInventoryPostingGroupInput: FilterInventoryPostingGroupInput;
  FilterInvitationInput: FilterInvitationInput;
  FilterInvoiceHeaderInput: FilterInvoiceHeaderInput;
  FilterInvoiceLineInput: FilterInvoiceLineInput;
  FilterItemCategoryInput: FilterItemCategoryInput;
  FilterItemDiscGroupInput: FilterItemDiscGroupInput;
  FilterItemInput: FilterItemInput;
  FilterItemReferenceInput: FilterItemReferenceInput;
  FilterItemRestrictionInput: FilterItemRestrictionInput;
  FilterItemUnitOfMeasureInput: FilterItemUnitOfMeasureInput;
  FilterItemVendorInput: FilterItemVendorInput;
  FilterJobTaskInput: FilterJobTaskInput;
  FilterKliHeaderInput: FilterKliHeaderInput;
  FilterKliReasonCodeInput: FilterKliReasonCodeInput;
  FilterLeadInput: FilterLeadInput;
  FilterLocationInput: FilterLocationInput;
  FilterMagentoOrderStatusInput: FilterMagentoOrderStatusInput;
  FilterMagentoSettingsInput: FilterMagentoSettingsInput;
  FilterManufacturerInput: FilterManufacturerInput;
  FilterNavUserInput: FilterNavUserInput;
  FilterObjectCommentLineInput: FilterObjectCommentLineInput;
  FilterObjectFamilyInput: FilterObjectFamilyInput;
  FilterObjectGroupInput: FilterObjectGroupInput;
  FilterObjectInput: FilterObjectInput;
  FilterObjectReturnHeaderInput: FilterObjectReturnHeaderInput;
  FilterObjectReturnLineInput: FilterObjectReturnLineInput;
  FilterObjectTypeInput: FilterObjectTypeInput;
  FilterOcrAnalysisInput: FilterOcrAnalysisInput;
  FilterPaymentMethodInput: FilterPaymentMethodInput;
  FilterPaymentTermsInput: FilterPaymentTermsInput;
  FilterPlatformFeatureInput: FilterPlatformFeatureInput;
  FilterPostCodeInput: FilterPostCodeInput;
  FilterProjectInput: FilterProjectInput;
  FilterPurchCrMemoHeaderInput: FilterPurchCrMemoHeaderInput;
  FilterPurchCrMemoLineInput: FilterPurchCrMemoLineInput;
  FilterPurchInvHeaderInput: FilterPurchInvHeaderInput;
  FilterPurchInvLineInput: FilterPurchInvLineInput;
  FilterPurchRcptHeaderInput: FilterPurchRcptHeaderInput;
  FilterPurchRcptLineInput: FilterPurchRcptLineInput;
  FilterPurchaseHeaderInput: FilterPurchaseHeaderInput;
  FilterPurchaseLineInput: FilterPurchaseLineInput;
  FilterPurchasingInput: FilterPurchasingInput;
  FilterQuoteHeaderInput: FilterQuoteHeaderInput;
  FilterQuoteLineInput: FilterQuoteLineInput;
  FilterQuoteTaskGroupInput: FilterQuoteTaskGroupInput;
  FilterRentalHeaderInput: FilterRentalHeaderInput;
  FilterRentalLineInput: FilterRentalLineInput;
  FilterRentalLineSnapshotInput: FilterRentalLineSnapshotInput;
  FilterRentalPostedCollHeaderInput: FilterRentalPostedCollHeaderInput;
  FilterRentalPostedCollLineInput: FilterRentalPostedCollLineInput;
  FilterRentalPostedShptHeaderInput: FilterRentalPostedShptHeaderInput;
  FilterRentalPostedShptLineInput: FilterRentalPostedShptLineInput;
  FilterRentalPriceTermsInput: FilterRentalPriceTermsInput;
  FilterRentalReturnEntryInput: FilterRentalReturnEntryInput;
  FilterRequestHeaderInput: FilterRequestHeaderInput;
  FilterRequestLineInput: FilterRequestLineInput;
  FilterReservationRequestHeaderInput: FilterReservationRequestHeaderInput;
  FilterReservationRequestLineInput: FilterReservationRequestLineInput;
  FilterResourceInput: FilterResourceInput;
  FilterResponsibilityCenterInput: FilterResponsibilityCenterInput;
  FilterReturnRequestHeaderInput: FilterReturnRequestHeaderInput;
  FilterReturnRequestLineInput: FilterReturnRequestLineInput;
  FilterRiskAnalysisHeaderInput: FilterRiskAnalysisHeaderInput;
  FilterRiskAnalysisLineInput: FilterRiskAnalysisLineInput;
  FilterRiskAnalysisSignatureInput: FilterRiskAnalysisSignatureInput;
  FilterSalesHeaderArchiveInput: FilterSalesHeaderArchiveInput;
  FilterSalesHeaderHistoryInput: FilterSalesHeaderHistoryInput;
  FilterSalesHeaderInput: FilterSalesHeaderInput;
  FilterSalesHeaderRequestInput: FilterSalesHeaderRequestInput;
  FilterSalesLineArchiveInput: FilterSalesLineArchiveInput;
  FilterSalesLineInput: FilterSalesLineInput;
  FilterSalesLineRequestInput: FilterSalesLineRequestInput;
  FilterSalesPriceInput: FilterSalesPriceInput;
  FilterSalesShipmentHeaderInput: FilterSalesShipmentHeaderInput;
  FilterSalesShipmentLineInput: FilterSalesShipmentLineInput;
  FilterSalespersonInput: FilterSalespersonInput;
  FilterServiceItemGroupInput: FilterServiceItemGroupInput;
  FilterShipmentMethodInput: FilterShipmentMethodInput;
  FilterShippingAgentInput: FilterShippingAgentInput;
  FilterSignRequestInput: FilterSignRequestInput;
  FilterSignatureInput: FilterSignatureInput;
  FilterSpecialEquipmentInput: FilterSpecialEquipmentInput;
  FilterStockkeepingUnitInput: FilterStockkeepingUnitInput;
  FilterTariffNumberInput: FilterTariffNumberInput;
  FilterTaxGroupInput: FilterTaxGroupInput;
  FilterTimeRegistrationInput: FilterTimeRegistrationInput;
  FilterTimeSheetDetailInput: FilterTimeSheetDetailInput;
  FilterTimeSheetLineInput: FilterTimeSheetLineInput;
  FilterTransactionSpecificationInput: FilterTransactionSpecificationInput;
  FilterTransactionTypeInput: FilterTransactionTypeInput;
  FilterTransportMethodInput: FilterTransportMethodInput;
  FilterUnitOfMeasureInput: FilterUnitOfMeasureInput;
  FilterUserFavoritesInput: FilterUserFavoritesInput;
  FilterUserInput: FilterUserInput;
  FilterVatBusinessPostingGroupInput: FilterVatBusinessPostingGroupInput;
  FilterVatProductPostingGroupInput: FilterVatProductPostingGroupInput;
  FilterVendorBankAccountInput: FilterVendorBankAccountInput;
  FilterVendorInput: FilterVendorInput;
  FilterVendorPostingGroupInput: FilterVendorPostingGroupInput;
  FilterWarehouseClassInput: FilterWarehouseClassInput;
  FilterWebBookingInput: FilterWebBookingInput;
  FilterWebhookSubscriptionInput: FilterWebhookSubscriptionInput;
  FilterWorkLogInput: FilterWorkLogInput;
  FilterWorkTypeInput: FilterWorkTypeInput;
  FunctionTestHeader: FunctionTestHeader;
  FunctionTestHeaderConnection: FunctionTestHeaderConnection;
  FunctionTestHeaderConnectionEdge: FunctionTestHeaderConnectionEdge;
  FunctionTestHeaderDeleteResponse: FunctionTestHeaderDeleteResponse;
  FunctionTestHeaderManyResult: FunctionTestHeaderManyResult;
  FunctionTestLine: FunctionTestLine;
  FunctionTestLineConnection: FunctionTestLineConnection;
  FunctionTestLineConnectionEdge: FunctionTestLineConnectionEdge;
  FunctionTestLineDeleteResponse: FunctionTestLineDeleteResponse;
  FunctionTestLineManyResult: FunctionTestLineManyResult;
  GenBusinessPostingGroup: GenBusinessPostingGroup;
  GenBusinessPostingGroupConnection: GenBusinessPostingGroupConnection;
  GenBusinessPostingGroupConnectionEdge: GenBusinessPostingGroupConnectionEdge;
  GenBusinessPostingGroupDeleteResponse: GenBusinessPostingGroupDeleteResponse;
  GenBusinessPostingGroupManyResult: GenBusinessPostingGroupManyResult;
  GenJournalLine: GenJournalLine;
  GenJournalLineConnection: GenJournalLineConnection;
  GenJournalLineConnectionEdge: GenJournalLineConnectionEdge;
  GenJournalLineDeleteResponse: GenJournalLineDeleteResponse;
  GenJournalLineManyResult: GenJournalLineManyResult;
  GenProductPostingGroup: GenProductPostingGroup;
  GenProductPostingGroupConnection: GenProductPostingGroupConnection;
  GenProductPostingGroupConnectionEdge: GenProductPostingGroupConnectionEdge;
  GenProductPostingGroupDeleteResponse: GenProductPostingGroupDeleteResponse;
  GenProductPostingGroupManyResult: GenProductPostingGroupManyResult;
  IOSAppCode: IosAppCode;
  Inspection: Inspection;
  InspectionConnection: InspectionConnection;
  InspectionConnectionEdge: InspectionConnectionEdge;
  InspectionDeleteResponse: InspectionDeleteResponse;
  InspectionManyResult: InspectionManyResult;
  InspectionUser: InspectionUser;
  InspectionUserConnection: InspectionUserConnection;
  InspectionUserConnectionEdge: InspectionUserConnectionEdge;
  InspectionUserDeleteResponse: InspectionUserDeleteResponse;
  InspectionUserManyResult: InspectionUserManyResult;
  IntegrationCollection: IntegrationCollection;
  IntegrationCollectionConnection: IntegrationCollectionConnection;
  IntegrationCollectionConnectionEdge: IntegrationCollectionConnectionEdge;
  IntegrationCollectionDeleteResponse: IntegrationCollectionDeleteResponse;
  IntegrationCollectionManyResult: IntegrationCollectionManyResult;
  InventoryPostingGroup: InventoryPostingGroup;
  InventoryPostingGroupConnection: InventoryPostingGroupConnection;
  InventoryPostingGroupConnectionEdge: InventoryPostingGroupConnectionEdge;
  InventoryPostingGroupDeleteResponse: InventoryPostingGroupDeleteResponse;
  InventoryPostingGroupManyResult: InventoryPostingGroupManyResult;
  Invitation: Invitation;
  InvitationConnection: InvitationConnection;
  InvitationConnectionEdge: InvitationConnectionEdge;
  InvitationDeleteResponse: InvitationDeleteResponse;
  InvitationLimited: InvitationLimited;
  InvitationManyResult: InvitationManyResult;
  InvitationUpdateInput: InvitationUpdateInput;
  InvoiceHeader: InvoiceHeader;
  InvoiceHeaderConnection: InvoiceHeaderConnection;
  InvoiceHeaderConnectionEdge: InvoiceHeaderConnectionEdge;
  InvoiceHeaderDeleteResponse: InvoiceHeaderDeleteResponse;
  InvoiceHeaderManyResult: InvoiceHeaderManyResult;
  InvoiceLine: InvoiceLine;
  InvoiceLineConnection: InvoiceLineConnection;
  InvoiceLineConnectionEdge: InvoiceLineConnectionEdge;
  InvoiceLineDeleteResponse: InvoiceLineDeleteResponse;
  InvoiceLineManyResult: InvoiceLineManyResult;
  Item: Item;
  ItemCategory: ItemCategory;
  ItemCategoryConnection: ItemCategoryConnection;
  ItemCategoryConnectionEdge: ItemCategoryConnectionEdge;
  ItemCategoryDeleteResponse: ItemCategoryDeleteResponse;
  ItemCategoryManyResult: ItemCategoryManyResult;
  ItemConnection: ItemConnection;
  ItemConnectionEdge: ItemConnectionEdge;
  ItemDeleteResponse: ItemDeleteResponse;
  ItemDiscGroup: ItemDiscGroup;
  ItemDiscGroupConnection: ItemDiscGroupConnection;
  ItemDiscGroupConnectionEdge: ItemDiscGroupConnectionEdge;
  ItemDiscGroupDeleteResponse: ItemDiscGroupDeleteResponse;
  ItemDiscGroupManyResult: ItemDiscGroupManyResult;
  ItemManyResult: ItemManyResult;
  ItemReference: ItemReference;
  ItemReferenceConnection: ItemReferenceConnection;
  ItemReferenceConnectionEdge: ItemReferenceConnectionEdge;
  ItemReferenceDeleteResponse: ItemReferenceDeleteResponse;
  ItemReferenceManyResult: ItemReferenceManyResult;
  ItemRestriction: ItemRestriction;
  ItemRestrictionConnection: ItemRestrictionConnection;
  ItemRestrictionConnectionEdge: ItemRestrictionConnectionEdge;
  ItemRestrictionDeleteResponse: ItemRestrictionDeleteResponse;
  ItemRestrictionManyResult: ItemRestrictionManyResult;
  ItemUnitOfMeasure: ItemUnitOfMeasure;
  ItemUnitOfMeasureConnection: ItemUnitOfMeasureConnection;
  ItemUnitOfMeasureConnectionEdge: ItemUnitOfMeasureConnectionEdge;
  ItemUnitOfMeasureDeleteResponse: ItemUnitOfMeasureDeleteResponse;
  ItemUnitOfMeasureManyResult: ItemUnitOfMeasureManyResult;
  ItemVendor: ItemVendor;
  ItemVendorConnection: ItemVendorConnection;
  ItemVendorConnectionEdge: ItemVendorConnectionEdge;
  ItemVendorDeleteResponse: ItemVendorDeleteResponse;
  ItemVendorManyResult: ItemVendorManyResult;
  JobTask: JobTask;
  JobTaskConnection: JobTaskConnection;
  JobTaskConnectionEdge: JobTaskConnectionEdge;
  JobTaskDeleteResponse: JobTaskDeleteResponse;
  JobTaskManyResult: JobTaskManyResult;
  KliHeader: KliHeader;
  KliHeaderConnection: KliHeaderConnection;
  KliHeaderConnectionEdge: KliHeaderConnectionEdge;
  KliHeaderDeleteResponse: KliHeaderDeleteResponse;
  KliHeaderManyResult: KliHeaderManyResult;
  KliReasonCode: KliReasonCode;
  KliReasonCodeConnection: KliReasonCodeConnection;
  KliReasonCodeConnectionEdge: KliReasonCodeConnectionEdge;
  KliReasonCodeDeleteResponse: KliReasonCodeDeleteResponse;
  KliReasonCodeManyResult: KliReasonCodeManyResult;
  Lead: Lead;
  LeadConnection: LeadConnection;
  LeadConnectionEdge: LeadConnectionEdge;
  LeadDeleteResponse: LeadDeleteResponse;
  LeadManyResult: LeadManyResult;
  LimitedCustomerProject: LimitedCustomerProject;
  LimitedUpdateCustomerProjectInput: LimitedUpdateCustomerProjectInput;
  LimitedUpdateCustomerProjectResponse: LimitedUpdateCustomerProjectResponse;
  LineAmount: LineAmount;
  Location: Location;
  LocationConnection: LocationConnection;
  LocationConnectionEdge: LocationConnectionEdge;
  LocationDeleteResponse: LocationDeleteResponse;
  LocationManyResult: LocationManyResult;
  MagentoOrderStatus: MagentoOrderStatus;
  MagentoOrderStatusConnection: MagentoOrderStatusConnection;
  MagentoOrderStatusConnectionEdge: MagentoOrderStatusConnectionEdge;
  MagentoOrderStatusDeleteResponse: MagentoOrderStatusDeleteResponse;
  MagentoOrderStatusManyResult: MagentoOrderStatusManyResult;
  MagentoSettings: MagentoSettings;
  MagentoSettingsConnection: MagentoSettingsConnection;
  MagentoSettingsConnectionEdge: MagentoSettingsConnectionEdge;
  MagentoSettingsDeleteResponse: MagentoSettingsDeleteResponse;
  MagentoSettingsManyResult: MagentoSettingsManyResult;
  Manufacturer: Manufacturer;
  ManufacturerConnection: ManufacturerConnection;
  ManufacturerConnectionEdge: ManufacturerConnectionEdge;
  ManufacturerDeleteResponse: ManufacturerDeleteResponse;
  ManufacturerManyResult: ManufacturerManyResult;
  Mutation: {};
  NavUser: NavUser;
  NavUserConnection: NavUserConnection;
  NavUserConnectionEdge: NavUserConnectionEdge;
  NavUserDeleteResponse: NavUserDeleteResponse;
  NavUserManyResult: NavUserManyResult;
  Object: Object;
  ObjectCommentLine: ObjectCommentLine;
  ObjectCommentLineConnection: ObjectCommentLineConnection;
  ObjectCommentLineConnectionEdge: ObjectCommentLineConnectionEdge;
  ObjectCommentLineDeleteResponse: ObjectCommentLineDeleteResponse;
  ObjectCommentLineManyResult: ObjectCommentLineManyResult;
  ObjectConnection: ObjectConnection;
  ObjectConnectionEdge: ObjectConnectionEdge;
  ObjectDeleteResponse: ObjectDeleteResponse;
  ObjectFamily: ObjectFamily;
  ObjectFamilyConnection: ObjectFamilyConnection;
  ObjectFamilyConnectionEdge: ObjectFamilyConnectionEdge;
  ObjectFamilyDeleteResponse: ObjectFamilyDeleteResponse;
  ObjectFamilyManyResult: ObjectFamilyManyResult;
  ObjectGroup: ObjectGroup;
  ObjectGroupConnection: ObjectGroupConnection;
  ObjectGroupConnectionEdge: ObjectGroupConnectionEdge;
  ObjectGroupDeleteResponse: ObjectGroupDeleteResponse;
  ObjectGroupManyResult: ObjectGroupManyResult;
  ObjectManyResult: ObjectManyResult;
  ObjectReturnHeader: ObjectReturnHeader;
  ObjectReturnHeaderConnection: ObjectReturnHeaderConnection;
  ObjectReturnHeaderConnectionEdge: ObjectReturnHeaderConnectionEdge;
  ObjectReturnHeaderDeleteResponse: ObjectReturnHeaderDeleteResponse;
  ObjectReturnHeaderManyResult: ObjectReturnHeaderManyResult;
  ObjectReturnLine: ObjectReturnLine;
  ObjectReturnLineConnection: ObjectReturnLineConnection;
  ObjectReturnLineConnectionEdge: ObjectReturnLineConnectionEdge;
  ObjectReturnLineDeleteResponse: ObjectReturnLineDeleteResponse;
  ObjectReturnLineManyResult: ObjectReturnLineManyResult;
  ObjectType: ObjectType;
  ObjectTypeConnection: ObjectTypeConnection;
  ObjectTypeConnectionEdge: ObjectTypeConnectionEdge;
  ObjectTypeDeleteResponse: ObjectTypeDeleteResponse;
  ObjectTypeLimited: ObjectTypeLimited;
  ObjectTypeLimitedInput: ObjectTypeLimitedInput;
  ObjectTypeManyResult: ObjectTypeManyResult;
  OcrAnalysis: OcrAnalysis;
  OcrAnalysisConnection: OcrAnalysisConnection;
  OcrAnalysisConnectionEdge: OcrAnalysisConnectionEdge;
  OcrAnalysisDeleteResponse: OcrAnalysisDeleteResponse;
  OcrAnalysisManyResult: OcrAnalysisManyResult;
  OldFunctionTestCountResult: OldFunctionTestCountResult;
  PageData: PageData;
  PageInfo: PageInfo;
  PaymentMethod: PaymentMethod;
  PaymentMethodConnection: PaymentMethodConnection;
  PaymentMethodConnectionEdge: PaymentMethodConnectionEdge;
  PaymentMethodDeleteResponse: PaymentMethodDeleteResponse;
  PaymentMethodManyResult: PaymentMethodManyResult;
  PaymentTerms: PaymentTerms;
  PaymentTermsConnection: PaymentTermsConnection;
  PaymentTermsConnectionEdge: PaymentTermsConnectionEdge;
  PaymentTermsDeleteResponse: PaymentTermsDeleteResponse;
  PaymentTermsManyResult: PaymentTermsManyResult;
  PersonalInvitationInput: PersonalInvitationInput;
  PlatformFeature: PlatformFeature;
  PlatformFeatureConnection: PlatformFeatureConnection;
  PlatformFeatureConnectionEdge: PlatformFeatureConnectionEdge;
  PlatformFeatureDeleteResponse: PlatformFeatureDeleteResponse;
  PlatformFeatureManyResult: PlatformFeatureManyResult;
  PostCode: PostCode;
  PostCodeConnection: PostCodeConnection;
  PostCodeConnectionEdge: PostCodeConnectionEdge;
  PostCodeDeleteResponse: PostCodeDeleteResponse;
  PostCodeManyResult: PostCodeManyResult;
  Project: Project;
  ProjectConnection: ProjectConnection;
  ProjectConnectionEdge: ProjectConnectionEdge;
  ProjectDeleteResponse: ProjectDeleteResponse;
  ProjectManyResult: ProjectManyResult;
  ProjectPushNotifications: ProjectPushNotifications;
  ProxyHeartbeatResponse: ProxyHeartbeatResponse;
  PurchCrMemoHeader: PurchCrMemoHeader;
  PurchCrMemoHeaderConnection: PurchCrMemoHeaderConnection;
  PurchCrMemoHeaderConnectionEdge: PurchCrMemoHeaderConnectionEdge;
  PurchCrMemoHeaderDeleteResponse: PurchCrMemoHeaderDeleteResponse;
  PurchCrMemoHeaderManyResult: PurchCrMemoHeaderManyResult;
  PurchCrMemoLine: PurchCrMemoLine;
  PurchCrMemoLineConnection: PurchCrMemoLineConnection;
  PurchCrMemoLineConnectionEdge: PurchCrMemoLineConnectionEdge;
  PurchCrMemoLineDeleteResponse: PurchCrMemoLineDeleteResponse;
  PurchCrMemoLineManyResult: PurchCrMemoLineManyResult;
  PurchInvHeader: PurchInvHeader;
  PurchInvHeaderConnection: PurchInvHeaderConnection;
  PurchInvHeaderConnectionEdge: PurchInvHeaderConnectionEdge;
  PurchInvHeaderDeleteResponse: PurchInvHeaderDeleteResponse;
  PurchInvHeaderManyResult: PurchInvHeaderManyResult;
  PurchInvLine: PurchInvLine;
  PurchInvLineConnection: PurchInvLineConnection;
  PurchInvLineConnectionEdge: PurchInvLineConnectionEdge;
  PurchInvLineDeleteResponse: PurchInvLineDeleteResponse;
  PurchInvLineManyResult: PurchInvLineManyResult;
  PurchRcptHeader: PurchRcptHeader;
  PurchRcptHeaderConnection: PurchRcptHeaderConnection;
  PurchRcptHeaderConnectionEdge: PurchRcptHeaderConnectionEdge;
  PurchRcptHeaderDeleteResponse: PurchRcptHeaderDeleteResponse;
  PurchRcptHeaderManyResult: PurchRcptHeaderManyResult;
  PurchRcptLine: PurchRcptLine;
  PurchRcptLineConnection: PurchRcptLineConnection;
  PurchRcptLineConnectionEdge: PurchRcptLineConnectionEdge;
  PurchRcptLineDeleteResponse: PurchRcptLineDeleteResponse;
  PurchRcptLineManyResult: PurchRcptLineManyResult;
  PurchaseHeader: PurchaseHeader;
  PurchaseHeaderConnection: PurchaseHeaderConnection;
  PurchaseHeaderConnectionEdge: PurchaseHeaderConnectionEdge;
  PurchaseHeaderDeleteResponse: PurchaseHeaderDeleteResponse;
  PurchaseHeaderManyResult: PurchaseHeaderManyResult;
  PurchaseLine: PurchaseLine;
  PurchaseLineConnection: PurchaseLineConnection;
  PurchaseLineConnectionEdge: PurchaseLineConnectionEdge;
  PurchaseLineDeleteResponse: PurchaseLineDeleteResponse;
  PurchaseLineManyResult: PurchaseLineManyResult;
  Purchasing: Purchasing;
  PurchasingConnection: PurchasingConnection;
  PurchasingConnectionEdge: PurchasingConnectionEdge;
  PurchasingDeleteResponse: PurchasingDeleteResponse;
  PurchasingManyResult: PurchasingManyResult;
  PushNotificationInput: PushNotificationInput;
  PushNotificationResponse: PushNotificationResponse;
  Query: {};
  QuoteHeader: QuoteHeader;
  QuoteHeaderConnection: QuoteHeaderConnection;
  QuoteHeaderConnectionEdge: QuoteHeaderConnectionEdge;
  QuoteHeaderDeleteResponse: QuoteHeaderDeleteResponse;
  QuoteHeaderManyResult: QuoteHeaderManyResult;
  QuoteLine: QuoteLine;
  QuoteLineConnection: QuoteLineConnection;
  QuoteLineConnectionEdge: QuoteLineConnectionEdge;
  QuoteLineDeleteResponse: QuoteLineDeleteResponse;
  QuoteLineManyResult: QuoteLineManyResult;
  QuoteTaskGroup: QuoteTaskGroup;
  QuoteTaskGroupConnection: QuoteTaskGroupConnection;
  QuoteTaskGroupConnectionEdge: QuoteTaskGroupConnectionEdge;
  QuoteTaskGroupDeleteResponse: QuoteTaskGroupDeleteResponse;
  QuoteTaskGroupManyResult: QuoteTaskGroupManyResult;
  RejectApproveAccessRequestInput: RejectApproveAccessRequestInput;
  RemoveCodesResponse: RemoveCodesResponse;
  RentCost: RentCost;
  RentCostFilter: RentCostFilter;
  RentalEvent: RentalEvent;
  RentalEventFilterInput: RentalEventFilterInput;
  RentalHeader: RentalHeader;
  RentalHeaderConnection: RentalHeaderConnection;
  RentalHeaderConnectionEdge: RentalHeaderConnectionEdge;
  RentalHeaderDeleteResponse: RentalHeaderDeleteResponse;
  RentalHeaderManyResult: RentalHeaderManyResult;
  RentalLine: RentalLine;
  RentalLineConnection: RentalLineConnection;
  RentalLineConnectionEdge: RentalLineConnectionEdge;
  RentalLineDeleteResponse: RentalLineDeleteResponse;
  RentalLineManyResult: RentalLineManyResult;
  RentalLineSnapshot: RentalLineSnapshot;
  RentalLineSnapshotConnection: RentalLineSnapshotConnection;
  RentalLineSnapshotConnectionEdge: RentalLineSnapshotConnectionEdge;
  RentalLineSnapshotDeleteResponse: RentalLineSnapshotDeleteResponse;
  RentalLineSnapshotManyResult: RentalLineSnapshotManyResult;
  RentalPostedCollHeader: RentalPostedCollHeader;
  RentalPostedCollHeaderConnection: RentalPostedCollHeaderConnection;
  RentalPostedCollHeaderConnectionEdge: RentalPostedCollHeaderConnectionEdge;
  RentalPostedCollHeaderDeleteResponse: RentalPostedCollHeaderDeleteResponse;
  RentalPostedCollHeaderManyResult: RentalPostedCollHeaderManyResult;
  RentalPostedCollLine: RentalPostedCollLine;
  RentalPostedCollLineConnection: RentalPostedCollLineConnection;
  RentalPostedCollLineConnectionEdge: RentalPostedCollLineConnectionEdge;
  RentalPostedCollLineDeleteResponse: RentalPostedCollLineDeleteResponse;
  RentalPostedCollLineManyResult: RentalPostedCollLineManyResult;
  RentalPostedShptHeader: RentalPostedShptHeader;
  RentalPostedShptHeaderConnection: RentalPostedShptHeaderConnection;
  RentalPostedShptHeaderConnectionEdge: RentalPostedShptHeaderConnectionEdge;
  RentalPostedShptHeaderDeleteResponse: RentalPostedShptHeaderDeleteResponse;
  RentalPostedShptHeaderManyResult: RentalPostedShptHeaderManyResult;
  RentalPostedShptLine: RentalPostedShptLine;
  RentalPostedShptLineConnection: RentalPostedShptLineConnection;
  RentalPostedShptLineConnectionEdge: RentalPostedShptLineConnectionEdge;
  RentalPostedShptLineDeleteResponse: RentalPostedShptLineDeleteResponse;
  RentalPostedShptLineManyResult: RentalPostedShptLineManyResult;
  RentalPriceTerms: RentalPriceTerms;
  RentalPriceTermsConnection: RentalPriceTermsConnection;
  RentalPriceTermsConnectionEdge: RentalPriceTermsConnectionEdge;
  RentalPriceTermsDeleteResponse: RentalPriceTermsDeleteResponse;
  RentalPriceTermsManyResult: RentalPriceTermsManyResult;
  RentalReturnEntry: RentalReturnEntry;
  RentalReturnEntryConnection: RentalReturnEntryConnection;
  RentalReturnEntryConnectionEdge: RentalReturnEntryConnectionEdge;
  RentalReturnEntryDeleteResponse: RentalReturnEntryDeleteResponse;
  RentalReturnEntryManyResult: RentalReturnEntryManyResult;
  RequestHeader: RequestHeader;
  RequestHeaderConnection: RequestHeaderConnection;
  RequestHeaderConnectionEdge: RequestHeaderConnectionEdge;
  RequestHeaderDeleteResponse: RequestHeaderDeleteResponse;
  RequestHeaderManyResult: RequestHeaderManyResult;
  RequestLine: RequestLine;
  RequestLineConnection: RequestLineConnection;
  RequestLineConnectionEdge: RequestLineConnectionEdge;
  RequestLineDeleteResponse: RequestLineDeleteResponse;
  RequestLineManyResult: RequestLineManyResult;
  ReservationRequestHeader: ReservationRequestHeader;
  ReservationRequestHeaderConnection: ReservationRequestHeaderConnection;
  ReservationRequestHeaderConnectionEdge: ReservationRequestHeaderConnectionEdge;
  ReservationRequestHeaderDeleteResponse: ReservationRequestHeaderDeleteResponse;
  ReservationRequestHeaderManyResult: ReservationRequestHeaderManyResult;
  ReservationRequestLine: ReservationRequestLine;
  ReservationRequestLineConnection: ReservationRequestLineConnection;
  ReservationRequestLineConnectionEdge: ReservationRequestLineConnectionEdge;
  ReservationRequestLineDeleteResponse: ReservationRequestLineDeleteResponse;
  ReservationRequestLineManyResult: ReservationRequestLineManyResult;
  Resource: Resource;
  ResourceConnection: ResourceConnection;
  ResourceConnectionEdge: ResourceConnectionEdge;
  ResourceDeleteResponse: ResourceDeleteResponse;
  ResourceManyResult: ResourceManyResult;
  ResponsibilityCenter: ResponsibilityCenter;
  ResponsibilityCenterConnection: ResponsibilityCenterConnection;
  ResponsibilityCenterConnectionEdge: ResponsibilityCenterConnectionEdge;
  ResponsibilityCenterDeleteResponse: ResponsibilityCenterDeleteResponse;
  ResponsibilityCenterManyResult: ResponsibilityCenterManyResult;
  ResponsibilityCenterMatrix: ResponsibilityCenterMatrix;
  RetrySyncInput: RetrySyncInput;
  RetrySyncResponse: RetrySyncResponse;
  ReturnRequestHeader: ReturnRequestHeader;
  ReturnRequestHeaderConnection: ReturnRequestHeaderConnection;
  ReturnRequestHeaderConnectionEdge: ReturnRequestHeaderConnectionEdge;
  ReturnRequestHeaderDeleteResponse: ReturnRequestHeaderDeleteResponse;
  ReturnRequestHeaderManyResult: ReturnRequestHeaderManyResult;
  ReturnRequestLine: ReturnRequestLine;
  ReturnRequestLineConnection: ReturnRequestLineConnection;
  ReturnRequestLineConnectionEdge: ReturnRequestLineConnectionEdge;
  ReturnRequestLineDeleteResponse: ReturnRequestLineDeleteResponse;
  ReturnRequestLineManyResult: ReturnRequestLineManyResult;
  RiskAnalysisHeader: RiskAnalysisHeader;
  RiskAnalysisHeaderConnection: RiskAnalysisHeaderConnection;
  RiskAnalysisHeaderConnectionEdge: RiskAnalysisHeaderConnectionEdge;
  RiskAnalysisHeaderDeleteResponse: RiskAnalysisHeaderDeleteResponse;
  RiskAnalysisHeaderManyResult: RiskAnalysisHeaderManyResult;
  RiskAnalysisLine: RiskAnalysisLine;
  RiskAnalysisLineConnection: RiskAnalysisLineConnection;
  RiskAnalysisLineConnectionEdge: RiskAnalysisLineConnectionEdge;
  RiskAnalysisLineDeleteResponse: RiskAnalysisLineDeleteResponse;
  RiskAnalysisLineManyResult: RiskAnalysisLineManyResult;
  RiskAnalysisSignature: RiskAnalysisSignature;
  RiskAnalysisSignatureConnection: RiskAnalysisSignatureConnection;
  RiskAnalysisSignatureConnectionEdge: RiskAnalysisSignatureConnectionEdge;
  RiskAnalysisSignatureDeleteResponse: RiskAnalysisSignatureDeleteResponse;
  RiskAnalysisSignatureManyResult: RiskAnalysisSignatureManyResult;
  SalesHeader: SalesHeader;
  SalesHeaderArchive: SalesHeaderArchive;
  SalesHeaderArchiveConnection: SalesHeaderArchiveConnection;
  SalesHeaderArchiveConnectionEdge: SalesHeaderArchiveConnectionEdge;
  SalesHeaderArchiveDeleteResponse: SalesHeaderArchiveDeleteResponse;
  SalesHeaderArchiveManyResult: SalesHeaderArchiveManyResult;
  SalesHeaderConnection: SalesHeaderConnection;
  SalesHeaderConnectionEdge: SalesHeaderConnectionEdge;
  SalesHeaderDeleteResponse: SalesHeaderDeleteResponse;
  SalesHeaderHistory: SalesHeaderHistory;
  SalesHeaderHistoryConnection: SalesHeaderHistoryConnection;
  SalesHeaderHistoryConnectionEdge: SalesHeaderHistoryConnectionEdge;
  SalesHeaderHistoryDeleteResponse: SalesHeaderHistoryDeleteResponse;
  SalesHeaderHistoryManyResult: SalesHeaderHistoryManyResult;
  SalesHeaderManyResult: SalesHeaderManyResult;
  SalesHeaderRequest: SalesHeaderRequest;
  SalesHeaderRequestConnection: SalesHeaderRequestConnection;
  SalesHeaderRequestConnectionEdge: SalesHeaderRequestConnectionEdge;
  SalesHeaderRequestDeleteResponse: SalesHeaderRequestDeleteResponse;
  SalesHeaderRequestManyResult: SalesHeaderRequestManyResult;
  SalesLine: SalesLine;
  SalesLineArchive: SalesLineArchive;
  SalesLineArchiveConnection: SalesLineArchiveConnection;
  SalesLineArchiveConnectionEdge: SalesLineArchiveConnectionEdge;
  SalesLineArchiveDeleteResponse: SalesLineArchiveDeleteResponse;
  SalesLineArchiveManyResult: SalesLineArchiveManyResult;
  SalesLineConnection: SalesLineConnection;
  SalesLineConnectionEdge: SalesLineConnectionEdge;
  SalesLineDeleteResponse: SalesLineDeleteResponse;
  SalesLineManyResult: SalesLineManyResult;
  SalesLineRequest: SalesLineRequest;
  SalesLineRequestConnection: SalesLineRequestConnection;
  SalesLineRequestConnectionEdge: SalesLineRequestConnectionEdge;
  SalesLineRequestDeleteResponse: SalesLineRequestDeleteResponse;
  SalesLineRequestManyResult: SalesLineRequestManyResult;
  SalesOrderChangeTrackingFilterInput: SalesOrderChangeTrackingFilterInput;
  SalesOrderChangeTrackingPageData: SalesOrderChangeTrackingPageData;
  SalesOrderChangeTrackingPageResponse: SalesOrderChangeTrackingPageResponse;
  SalesOrderInput: SalesOrderInput;
  SalesOrderResponse: SalesOrderResponse;
  SalesPrice: SalesPrice;
  SalesPriceConnection: SalesPriceConnection;
  SalesPriceConnectionEdge: SalesPriceConnectionEdge;
  SalesPriceDeleteResponse: SalesPriceDeleteResponse;
  SalesPriceManyResult: SalesPriceManyResult;
  SalesShipmentHeader: SalesShipmentHeader;
  SalesShipmentHeaderConnection: SalesShipmentHeaderConnection;
  SalesShipmentHeaderConnectionEdge: SalesShipmentHeaderConnectionEdge;
  SalesShipmentHeaderDeleteResponse: SalesShipmentHeaderDeleteResponse;
  SalesShipmentHeaderManyResult: SalesShipmentHeaderManyResult;
  SalesShipmentLine: SalesShipmentLine;
  SalesShipmentLineConnection: SalesShipmentLineConnection;
  SalesShipmentLineConnectionEdge: SalesShipmentLineConnectionEdge;
  SalesShipmentLineDeleteResponse: SalesShipmentLineDeleteResponse;
  SalesShipmentLineManyResult: SalesShipmentLineManyResult;
  Salesperson: Salesperson;
  SalespersonConnection: SalespersonConnection;
  SalespersonConnectionEdge: SalespersonConnectionEdge;
  SalespersonDeleteResponse: SalespersonDeleteResponse;
  SalespersonManyResult: SalespersonManyResult;
  SendOnRentEmailInput: SendOnRentEmailInput;
  SendOnRentEmailResponse: SendOnRentEmailResponse;
  ServiceItemGroup: ServiceItemGroup;
  ServiceItemGroupConnection: ServiceItemGroupConnection;
  ServiceItemGroupConnectionEdge: ServiceItemGroupConnectionEdge;
  ServiceItemGroupDeleteResponse: ServiceItemGroupDeleteResponse;
  ServiceItemGroupManyResult: ServiceItemGroupManyResult;
  ShipmentMethod: ShipmentMethod;
  ShipmentMethodConnection: ShipmentMethodConnection;
  ShipmentMethodConnectionEdge: ShipmentMethodConnectionEdge;
  ShipmentMethodDeleteResponse: ShipmentMethodDeleteResponse;
  ShipmentMethodManyResult: ShipmentMethodManyResult;
  ShippingAgent: ShippingAgent;
  ShippingAgentConnection: ShippingAgentConnection;
  ShippingAgentConnectionEdge: ShippingAgentConnectionEdge;
  ShippingAgentDeleteResponse: ShippingAgentDeleteResponse;
  ShippingAgentManyResult: ShippingAgentManyResult;
  SignRequest: SignRequest;
  SignRequestConnection: SignRequestConnection;
  SignRequestConnectionEdge: SignRequestConnectionEdge;
  SignRequestDeleteResponse: SignRequestDeleteResponse;
  SignRequestManyResult: SignRequestManyResult;
  Signature: Signature;
  SignatureConnection: SignatureConnection;
  SignatureConnectionEdge: SignatureConnectionEdge;
  SignatureDeleteResponse: SignatureDeleteResponse;
  SignatureManyResult: SignatureManyResult;
  SpecialEquipment: SpecialEquipment;
  SpecialEquipmentConnection: SpecialEquipmentConnection;
  SpecialEquipmentConnectionEdge: SpecialEquipmentConnectionEdge;
  SpecialEquipmentDeleteResponse: SpecialEquipmentDeleteResponse;
  SpecialEquipmentManyResult: SpecialEquipmentManyResult;
  StockkeepingUnit: StockkeepingUnit;
  StockkeepingUnitConnection: StockkeepingUnitConnection;
  StockkeepingUnitConnectionEdge: StockkeepingUnitConnectionEdge;
  StockkeepingUnitDeleteResponse: StockkeepingUnitDeleteResponse;
  StockkeepingUnitManyResult: StockkeepingUnitManyResult;
  StoredFile: StoredFile;
  Subscription: {};
  SyncUsersInput: SyncUsersInput;
  TariffNumber: TariffNumber;
  TariffNumberConnection: TariffNumberConnection;
  TariffNumberConnectionEdge: TariffNumberConnectionEdge;
  TariffNumberDeleteResponse: TariffNumberDeleteResponse;
  TariffNumberManyResult: TariffNumberManyResult;
  TaxGroup: TaxGroup;
  TaxGroupConnection: TaxGroupConnection;
  TaxGroupConnectionEdge: TaxGroupConnectionEdge;
  TaxGroupDeleteResponse: TaxGroupDeleteResponse;
  TaxGroupManyResult: TaxGroupManyResult;
  TimeRegistration: TimeRegistration;
  TimeRegistrationConnection: TimeRegistrationConnection;
  TimeRegistrationConnectionEdge: TimeRegistrationConnectionEdge;
  TimeRegistrationDeleteResponse: TimeRegistrationDeleteResponse;
  TimeRegistrationManyResult: TimeRegistrationManyResult;
  TimeSheetDetail: TimeSheetDetail;
  TimeSheetDetailConnection: TimeSheetDetailConnection;
  TimeSheetDetailConnectionEdge: TimeSheetDetailConnectionEdge;
  TimeSheetDetailDeleteResponse: TimeSheetDetailDeleteResponse;
  TimeSheetDetailManyResult: TimeSheetDetailManyResult;
  TimeSheetLine: TimeSheetLine;
  TimeSheetLineConnection: TimeSheetLineConnection;
  TimeSheetLineConnectionEdge: TimeSheetLineConnectionEdge;
  TimeSheetLineDeleteResponse: TimeSheetLineDeleteResponse;
  TimeSheetLineManyResult: TimeSheetLineManyResult;
  ToggleProjectNotificationsInput: ToggleProjectNotificationsInput;
  TransactionSpecification: TransactionSpecification;
  TransactionSpecificationConnection: TransactionSpecificationConnection;
  TransactionSpecificationConnectionEdge: TransactionSpecificationConnectionEdge;
  TransactionSpecificationDeleteResponse: TransactionSpecificationDeleteResponse;
  TransactionSpecificationManyResult: TransactionSpecificationManyResult;
  TransactionType: TransactionType;
  TransactionTypeConnection: TransactionTypeConnection;
  TransactionTypeConnectionEdge: TransactionTypeConnectionEdge;
  TransactionTypeDeleteResponse: TransactionTypeDeleteResponse;
  TransactionTypeManyResult: TransactionTypeManyResult;
  TransportMethod: TransportMethod;
  TransportMethodConnection: TransportMethodConnection;
  TransportMethodConnectionEdge: TransportMethodConnectionEdge;
  TransportMethodDeleteResponse: TransportMethodDeleteResponse;
  TransportMethodManyResult: TransportMethodManyResult;
  UnitOfMeasure: UnitOfMeasure;
  UnitOfMeasureConnection: UnitOfMeasureConnection;
  UnitOfMeasureConnectionEdge: UnitOfMeasureConnectionEdge;
  UnitOfMeasureDeleteResponse: UnitOfMeasureDeleteResponse;
  UnitOfMeasureManyResult: UnitOfMeasureManyResult;
  UnsignedInt: Scalars['UnsignedInt'];
  UpdateAccessRequestInput: UpdateAccessRequestInput;
  UpdateAdditionalItemInput: UpdateAdditionalItemInput;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAgreementInput: UpdateAgreementInput;
  UpdateAnnotationInput: UpdateAnnotationInput;
  UpdateApplicationModuleInput: UpdateApplicationModuleInput;
  UpdateBcMappingFieldInput: UpdateBcMappingFieldInput;
  UpdateBcMappingTableInput: UpdateBcMappingTableInput;
  UpdateBcSettingsInput: UpdateBcSettingsInput;
  UpdateBulkItemGroupInput: UpdateBulkItemGroupInput;
  UpdateCauseOfAbsenceInput: UpdateCauseOfAbsenceInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateCompanyTreeInput: UpdateCompanyTreeInput;
  UpdateConsignmentHeaderInput: UpdateConsignmentHeaderInput;
  UpdateConsignmentLineInput: UpdateConsignmentLineInput;
  UpdateContactInput: UpdateContactInput;
  UpdateCountryRegionInput: UpdateCountryRegionInput;
  UpdateCurrencyExchangeRateInput: UpdateCurrencyExchangeRateInput;
  UpdateCurrencyInput: UpdateCurrencyInput;
  UpdateCustomerDomainInput: UpdateCustomerDomainInput;
  UpdateCustomerPostingGroupInput: UpdateCustomerPostingGroupInput;
  UpdateCustomerProjectInput: UpdateCustomerProjectInput;
  UpdateCustomerSubProjectInput: UpdateCustomerSubProjectInput;
  UpdateDeliverytimeInput: UpdateDeliverytimeInput;
  UpdateDevopsProjectInput: UpdateDevopsProjectInput;
  UpdateDevopsTeamInput: UpdateDevopsTeamInput;
  UpdateDevopsWorkItemInput: UpdateDevopsWorkItemInput;
  UpdateDimensionInput: UpdateDimensionInput;
  UpdateDimensionValueInput: UpdateDimensionValueInput;
  UpdateDiscussionPostInput: UpdateDiscussionPostInput;
  UpdateDocumentClassificationInput: UpdateDocumentClassificationInput;
  UpdateDocumentInput: UpdateDocumentInput;
  UpdateDocumentRequestInput: UpdateDocumentRequestInput;
  UpdateDomainInput: UpdateDomainInput;
  UpdateDomainIntegrationInput: UpdateDomainIntegrationInput;
  UpdateDomainSettingsInput: UpdateDomainSettingsInput;
  UpdateEqmBaseCalendarChangeInput: UpdateEqmBaseCalendarChangeInput;
  UpdateEqmBaseCalendarInput: UpdateEqmBaseCalendarInput;
  UpdateEqmCollectionReturnChargesInput: UpdateEqmCollectionReturnChargesInput;
  UpdateEqmCommentLineInput: UpdateEqmCommentLineInput;
  UpdateEqmCustomizedCalendarChangeInput: UpdateEqmCustomizedCalendarChangeInput;
  UpdateEqmFncTestReturnChargesInput: UpdateEqmFncTestReturnChargesInput;
  UpdateEqmLocCostCenterCombInput: UpdateEqmLocCostCenterCombInput;
  UpdateEqmManufacturerInput: UpdateEqmManufacturerInput;
  UpdateEqmManufacturerModelInput: UpdateEqmManufacturerModelInput;
  UpdateEqmObjectCardFlowInput: UpdateEqmObjectCardFlowInput;
  UpdateEqmObjectComponentsInput: UpdateEqmObjectComponentsInput;
  UpdateEqmObjectCountHeaderInput: UpdateEqmObjectCountHeaderInput;
  UpdateEqmObjectCountLineInput: UpdateEqmObjectCountLineInput;
  UpdateEqmObjectGroupCardInput: UpdateEqmObjectGroupCardInput;
  UpdateEqmObjectGroupFlowInput: UpdateEqmObjectGroupFlowInput;
  UpdateEqmObjectServiceIntervalInput: UpdateEqmObjectServiceIntervalInput;
  UpdateEqmObjectSrvIntervalAggInput: UpdateEqmObjectSrvIntervalAggInput;
  UpdateEqmObjectStatusInput: UpdateEqmObjectStatusInput;
  UpdateEqmObjectTypeCardInput: UpdateEqmObjectTypeCardInput;
  UpdateEqmObjectTypeComponentsInput: UpdateEqmObjectTypeComponentsInput;
  UpdateEqmObjectTypeFlowInput: UpdateEqmObjectTypeFlowInput;
  UpdateEqmObjectTypePriceTermInput: UpdateEqmObjectTypePriceTermInput;
  UpdateEqmObjectTypeServiceIntervalInput: UpdateEqmObjectTypeServiceIntervalInput;
  UpdateEqmReRentCardInput: UpdateEqmReRentCardInput;
  UpdateEqmRentalGroupInput: UpdateEqmRentalGroupInput;
  UpdateEqmRentalKITComponentsInput: UpdateEqmRentalKitComponentsInput;
  UpdateEqmRentalKITInput: UpdateEqmRentalKitInput;
  UpdateEqmRentalKITLinesInput: UpdateEqmRentalKitLinesInput;
  UpdateEqmRentalLineDiscountInput: UpdateEqmRentalLineDiscountInput;
  UpdateEqmRentalPriceInput: UpdateEqmRentalPriceInput;
  UpdateEqmReturnChargesInput: UpdateEqmReturnChargesInput;
  UpdateEqmServiceCardInput: UpdateEqmServiceCardInput;
  UpdateEqmServiceTypeInput: UpdateEqmServiceTypeInput;
  UpdateEqmTransferHeaderInput: UpdateEqmTransferHeaderInput;
  UpdateEqmTransferLineInput: UpdateEqmTransferLineInput;
  UpdateEqmTypeFunctionalTestInput: UpdateEqmTypeFunctionalTestInput;
  UpdateEqmWorkHeaderInput: UpdateEqmWorkHeaderInput;
  UpdateEqmWorkLineInput: UpdateEqmWorkLineInput;
  UpdateErrandInput: UpdateErrandInput;
  UpdateErrandLineInput: UpdateErrandLineInput;
  UpdateErrorReportInput: UpdateErrorReportInput;
  UpdateFeedbackInput: UpdateFeedbackInput;
  UpdateFeemappingInput: UpdateFeemappingInput;
  UpdateFieldOrderInput: UpdateFieldOrderInput;
  UpdateFieldOrderLineInput: UpdateFieldOrderLineInput;
  UpdateFileInput: UpdateFileInput;
  UpdateFunctionTestHeaderInput: UpdateFunctionTestHeaderInput;
  UpdateFunctionTestLineInput: UpdateFunctionTestLineInput;
  UpdateGenBusinessPostingGroupInput: UpdateGenBusinessPostingGroupInput;
  UpdateGenJournalLineInput: UpdateGenJournalLineInput;
  UpdateGenProductPostingGroupInput: UpdateGenProductPostingGroupInput;
  UpdateInspectionInput: UpdateInspectionInput;
  UpdateInspectionUserInput: UpdateInspectionUserInput;
  UpdateIntegrationCollectionInput: UpdateIntegrationCollectionInput;
  UpdateInventoryPostingGroupInput: UpdateInventoryPostingGroupInput;
  UpdateInvitationInput: UpdateInvitationInput;
  UpdateInvoiceHeaderInput: UpdateInvoiceHeaderInput;
  UpdateInvoiceLineInput: UpdateInvoiceLineInput;
  UpdateItemCategoryInput: UpdateItemCategoryInput;
  UpdateItemDiscGroupInput: UpdateItemDiscGroupInput;
  UpdateItemInput: UpdateItemInput;
  UpdateItemReferenceInput: UpdateItemReferenceInput;
  UpdateItemRestrictionInput: UpdateItemRestrictionInput;
  UpdateItemUnitOfMeasureInput: UpdateItemUnitOfMeasureInput;
  UpdateItemVendorInput: UpdateItemVendorInput;
  UpdateJobTaskInput: UpdateJobTaskInput;
  UpdateKliHeaderInput: UpdateKliHeaderInput;
  UpdateKliReasonCodeInput: UpdateKliReasonCodeInput;
  UpdateLeadInput: UpdateLeadInput;
  UpdateLocationInput: UpdateLocationInput;
  UpdateMagentoOrderStatusInput: UpdateMagentoOrderStatusInput;
  UpdateMagentoSettingsInput: UpdateMagentoSettingsInput;
  UpdateManufacturerInput: UpdateManufacturerInput;
  UpdateNavUserInput: UpdateNavUserInput;
  UpdateObjectCommentLineInput: UpdateObjectCommentLineInput;
  UpdateObjectFamilyInput: UpdateObjectFamilyInput;
  UpdateObjectGroupInput: UpdateObjectGroupInput;
  UpdateObjectInput: UpdateObjectInput;
  UpdateObjectReturnHeaderInput: UpdateObjectReturnHeaderInput;
  UpdateObjectReturnLineInput: UpdateObjectReturnLineInput;
  UpdateObjectTypeInput: UpdateObjectTypeInput;
  UpdateOcrAnalysisInput: UpdateOcrAnalysisInput;
  UpdatePaymentMethodInput: UpdatePaymentMethodInput;
  UpdatePaymentTermsInput: UpdatePaymentTermsInput;
  UpdatePlatformFeatureInput: UpdatePlatformFeatureInput;
  UpdatePostCodeInput: UpdatePostCodeInput;
  UpdateProjectInput: UpdateProjectInput;
  UpdatePurchCrMemoHeaderInput: UpdatePurchCrMemoHeaderInput;
  UpdatePurchCrMemoLineInput: UpdatePurchCrMemoLineInput;
  UpdatePurchInvHeaderInput: UpdatePurchInvHeaderInput;
  UpdatePurchInvLineInput: UpdatePurchInvLineInput;
  UpdatePurchRcptHeaderInput: UpdatePurchRcptHeaderInput;
  UpdatePurchRcptLineInput: UpdatePurchRcptLineInput;
  UpdatePurchaseHeaderInput: UpdatePurchaseHeaderInput;
  UpdatePurchaseLineInput: UpdatePurchaseLineInput;
  UpdatePurchasingInput: UpdatePurchasingInput;
  UpdateQuoteHeaderInput: UpdateQuoteHeaderInput;
  UpdateQuoteLineInput: UpdateQuoteLineInput;
  UpdateQuoteTaskGroupInput: UpdateQuoteTaskGroupInput;
  UpdateRentalHeaderInput: UpdateRentalHeaderInput;
  UpdateRentalLineInput: UpdateRentalLineInput;
  UpdateRentalLineSnapshotInput: UpdateRentalLineSnapshotInput;
  UpdateRentalPostedCollHeaderInput: UpdateRentalPostedCollHeaderInput;
  UpdateRentalPostedCollLineInput: UpdateRentalPostedCollLineInput;
  UpdateRentalPostedShptHeaderInput: UpdateRentalPostedShptHeaderInput;
  UpdateRentalPostedShptLineInput: UpdateRentalPostedShptLineInput;
  UpdateRentalPriceTermsInput: UpdateRentalPriceTermsInput;
  UpdateRentalReturnEntryInput: UpdateRentalReturnEntryInput;
  UpdateRequestHeaderInput: UpdateRequestHeaderInput;
  UpdateRequestLineInput: UpdateRequestLineInput;
  UpdateReservationRequestHeaderInput: UpdateReservationRequestHeaderInput;
  UpdateReservationRequestLineInput: UpdateReservationRequestLineInput;
  UpdateResourceInput: UpdateResourceInput;
  UpdateResponsibilityCenterInput: UpdateResponsibilityCenterInput;
  UpdateReturnRequestHeaderInput: UpdateReturnRequestHeaderInput;
  UpdateReturnRequestLineInput: UpdateReturnRequestLineInput;
  UpdateRiskAnalysisHeaderInput: UpdateRiskAnalysisHeaderInput;
  UpdateRiskAnalysisLineInput: UpdateRiskAnalysisLineInput;
  UpdateRiskAnalysisSignatureInput: UpdateRiskAnalysisSignatureInput;
  UpdateSalesHeaderArchiveInput: UpdateSalesHeaderArchiveInput;
  UpdateSalesHeaderHistoryInput: UpdateSalesHeaderHistoryInput;
  UpdateSalesHeaderInput: UpdateSalesHeaderInput;
  UpdateSalesHeaderRequestInput: UpdateSalesHeaderRequestInput;
  UpdateSalesLineArchiveInput: UpdateSalesLineArchiveInput;
  UpdateSalesLineInput: UpdateSalesLineInput;
  UpdateSalesLineRequestInput: UpdateSalesLineRequestInput;
  UpdateSalesPriceInput: UpdateSalesPriceInput;
  UpdateSalesShipmentHeaderInput: UpdateSalesShipmentHeaderInput;
  UpdateSalesShipmentLineInput: UpdateSalesShipmentLineInput;
  UpdateSalespersonInput: UpdateSalespersonInput;
  UpdateServiceItemGroupInput: UpdateServiceItemGroupInput;
  UpdateShipmentMethodInput: UpdateShipmentMethodInput;
  UpdateShippingAgentInput: UpdateShippingAgentInput;
  UpdateSignRequestInput: UpdateSignRequestInput;
  UpdateSignatureInput: UpdateSignatureInput;
  UpdateSpecialEquipmentInput: UpdateSpecialEquipmentInput;
  UpdateStockkeepingUnitInput: UpdateStockkeepingUnitInput;
  UpdateTariffNumberInput: UpdateTariffNumberInput;
  UpdateTaxGroupInput: UpdateTaxGroupInput;
  UpdateTimeRegistrationInput: UpdateTimeRegistrationInput;
  UpdateTimeSheetDetailInput: UpdateTimeSheetDetailInput;
  UpdateTimeSheetLineInput: UpdateTimeSheetLineInput;
  UpdateTransactionSpecificationInput: UpdateTransactionSpecificationInput;
  UpdateTransactionTypeInput: UpdateTransactionTypeInput;
  UpdateTransportMethodInput: UpdateTransportMethodInput;
  UpdateUnitOfMeasureInput: UpdateUnitOfMeasureInput;
  UpdateUserFavoritesInput: UpdateUserFavoritesInput;
  UpdateUserInput: UpdateUserInput;
  UpdateVatBusinessPostingGroupInput: UpdateVatBusinessPostingGroupInput;
  UpdateVatProductPostingGroupInput: UpdateVatProductPostingGroupInput;
  UpdateVendorBankAccountInput: UpdateVendorBankAccountInput;
  UpdateVendorInput: UpdateVendorInput;
  UpdateVendorPostingGroupInput: UpdateVendorPostingGroupInput;
  UpdateWarehouseClassInput: UpdateWarehouseClassInput;
  UpdateWebBookingInput: UpdateWebBookingInput;
  UpdateWebhookSubscriptionInput: UpdateWebhookSubscriptionInput;
  UpdateWorkLogInput: UpdateWorkLogInput;
  UpdateWorkTypeInput: UpdateWorkTypeInput;
  UploadAppCodesResponse: UploadAppCodesResponse;
  UploadDomainImageInput: UploadDomainImageInput;
  User: User;
  UserConnection: UserConnection;
  UserConnectionEdge: UserConnectionEdge;
  UserDeleteResponse: UserDeleteResponse;
  UserFavorites: UserFavorites;
  UserFavoritesConnection: UserFavoritesConnection;
  UserFavoritesConnectionEdge: UserFavoritesConnectionEdge;
  UserFavoritesDeleteResponse: UserFavoritesDeleteResponse;
  UserFavoritesManyResult: UserFavoritesManyResult;
  UserManyResult: UserManyResult;
  UserOperationResponse: UserOperationResponse;
  VatBusinessPostingGroup: VatBusinessPostingGroup;
  VatBusinessPostingGroupConnection: VatBusinessPostingGroupConnection;
  VatBusinessPostingGroupConnectionEdge: VatBusinessPostingGroupConnectionEdge;
  VatBusinessPostingGroupDeleteResponse: VatBusinessPostingGroupDeleteResponse;
  VatBusinessPostingGroupManyResult: VatBusinessPostingGroupManyResult;
  VatProductPostingGroup: VatProductPostingGroup;
  VatProductPostingGroupConnection: VatProductPostingGroupConnection;
  VatProductPostingGroupConnectionEdge: VatProductPostingGroupConnectionEdge;
  VatProductPostingGroupDeleteResponse: VatProductPostingGroupDeleteResponse;
  VatProductPostingGroupManyResult: VatProductPostingGroupManyResult;
  Vendor: Vendor;
  VendorBankAccount: VendorBankAccount;
  VendorBankAccountConnection: VendorBankAccountConnection;
  VendorBankAccountConnectionEdge: VendorBankAccountConnectionEdge;
  VendorBankAccountDeleteResponse: VendorBankAccountDeleteResponse;
  VendorBankAccountManyResult: VendorBankAccountManyResult;
  VendorConnection: VendorConnection;
  VendorConnectionEdge: VendorConnectionEdge;
  VendorDeleteResponse: VendorDeleteResponse;
  VendorManyResult: VendorManyResult;
  VendorPostingGroup: VendorPostingGroup;
  VendorPostingGroupConnection: VendorPostingGroupConnection;
  VendorPostingGroupConnectionEdge: VendorPostingGroupConnectionEdge;
  VendorPostingGroupDeleteResponse: VendorPostingGroupDeleteResponse;
  VendorPostingGroupManyResult: VendorPostingGroupManyResult;
  WarehouseClass: WarehouseClass;
  WarehouseClassConnection: WarehouseClassConnection;
  WarehouseClassConnectionEdge: WarehouseClassConnectionEdge;
  WarehouseClassDeleteResponse: WarehouseClassDeleteResponse;
  WarehouseClassManyResult: WarehouseClassManyResult;
  WebBooking: WebBooking;
  WebBookingConnection: WebBookingConnection;
  WebBookingConnectionEdge: WebBookingConnectionEdge;
  WebBookingDeleteResponse: WebBookingDeleteResponse;
  WebBookingManyResult: WebBookingManyResult;
  WebhookSubscription: WebhookSubscription;
  WebhookSubscriptionConnection: WebhookSubscriptionConnection;
  WebhookSubscriptionConnectionEdge: WebhookSubscriptionConnectionEdge;
  WebhookSubscriptionDeleteResponse: WebhookSubscriptionDeleteResponse;
  WebhookSubscriptionManyResult: WebhookSubscriptionManyResult;
  WorkLog: WorkLog;
  WorkLogConnection: WorkLogConnection;
  WorkLogConnectionEdge: WorkLogConnectionEdge;
  WorkLogDeleteResponse: WorkLogDeleteResponse;
  WorkLogManyResult: WorkLogManyResult;
  WorkType: WorkType;
  WorkTypeConnection: WorkTypeConnection;
  WorkTypeConnectionEdge: WorkTypeConnectionEdge;
  WorkTypeDeleteResponse: WorkTypeDeleteResponse;
  WorkTypeManyResult: WorkTypeManyResult;
  createCompanyInvitationResponse: CreateCompanyInvitationResponse;
  customerDomainsLimitedInput: CustomerDomainsLimitedInput;
  customerDomainsLimitedUpsertInput: CustomerDomainsLimitedUpsertInput;
  domainsLimitedInput: DomainsLimitedInput;
  setCustomerDomainImageInput: SetCustomerDomainImageInput;
  syncUsersResponse: SyncUsersResponse;
  toggleFavoriteProjectInput: ToggleFavoriteProjectInput;
  toggleSuplierResponse: ToggleSuplierResponse;
  toggleSupplierInput: ToggleSupplierInput;
};

export type AcceptInivitationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AcceptInivitationResponse'] = ResolversParentTypes['AcceptInivitationResponse']> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserAcceptedInvitationOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UserInvitationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IOSAppLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AndroidAppLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IOSAppCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsOnAppStore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsOnPlayStore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessRequest'] = ResolversParentTypes['AccessRequest']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['AccessRequestTypeEnum']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['AccessRequestStatusEnum']>, ParentType, ContextType>;
  Approved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApprovedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RequestedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ApprovedByUserGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  domain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessRequestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessRequestConnection'] = ResolversParentTypes['AccessRequestConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AccessRequestConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessRequestConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessRequestConnectionEdge'] = ResolversParentTypes['AccessRequestConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AccessRequest'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessRequestDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessRequestDeleteResponse'] = ResolversParentTypes['AccessRequestDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessRequest']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessRequestManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessRequestManyResult'] = ResolversParentTypes['AccessRequestManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessRequest']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccumulatedRentCostResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccumulatedRentCost'] = ResolversParentTypes['AccumulatedRentCost']> = {
  TotalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdditionalItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalItem'] = ResolversParentTypes['AdditionalItem']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['AdditionalItemTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityOption?: Resolver<Maybe<ResolversTypes['AdditionalItemQuantityOptionEnum']>, ParentType, ContextType>;
  QuantityOptionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRequiredQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseZeroPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdditionalItemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalItemConnection'] = ResolversParentTypes['AdditionalItemConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AdditionalItemConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdditionalItemConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalItemConnectionEdge'] = ResolversParentTypes['AdditionalItemConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AdditionalItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdditionalItemDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalItemDeleteResponse'] = ResolversParentTypes['AdditionalItemDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['AdditionalItem']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdditionalItemManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalItemManyResult'] = ResolversParentTypes['AdditionalItemManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['AdditionalItem']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlaceofExport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexAnswerBack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceZoneCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SharkFreightInstrToWsh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Tour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TourNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MagentoAddressId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressConnection'] = ResolversParentTypes['AddressConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AddressConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressConnectionEdge'] = ResolversParentTypes['AddressConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressDeleteResponse'] = ResolversParentTypes['AddressDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressManyResult'] = ResolversParentTypes['AddressManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgreementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Agreement'] = ResolversParentTypes['Agreement']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AgreementType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SignatureType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgreementConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AgreementConnection'] = ResolversParentTypes['AgreementConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AgreementConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgreementConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AgreementConnectionEdge'] = ResolversParentTypes['AgreementConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Agreement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgreementDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AgreementDeleteResponse'] = ResolversParentTypes['AgreementDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Agreement']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgreementManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AgreementManyResult'] = ResolversParentTypes['AgreementManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Agreement']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Annotation'] = ResolversParentTypes['Annotation']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoteText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OwnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OwnerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OwnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnnotationConnection'] = ResolversParentTypes['AnnotationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AnnotationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnnotationConnectionEdge'] = ResolversParentTypes['AnnotationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Annotation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnnotationDeleteResponse'] = ResolversParentTypes['AnnotationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Annotation']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnnotationManyResult'] = ResolversParentTypes['AnnotationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Annotation']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppPushtokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppPushtoken'] = ResolversParentTypes['AppPushtoken']> = {
  App?: Resolver<Maybe<ResolversTypes['AppPushTokenInput']>, ParentType, ContextType>;
  Token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationModule'] = ResolversParentTypes['ApplicationModule']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultWebLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultAppNavStackName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NavigationOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Searchable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShowOnWebHeader?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShowOnAppMainTab?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VisibleForCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationModuleConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationModuleConnection'] = ResolversParentTypes['ApplicationModuleConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ApplicationModuleConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationModuleConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationModuleConnectionEdge'] = ResolversParentTypes['ApplicationModuleConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ApplicationModule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationModuleDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationModuleDeleteResponse'] = ResolversParentTypes['ApplicationModuleDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicationModule']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationModuleManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationModuleManyResult'] = ResolversParentTypes['ApplicationModuleManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicationModule']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthError'] = ResolversParentTypes['AuthError']> = {
  error_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trace_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error_uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthResponse'] = ResolversParentTypes['AuthResponse']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['AuthError']>, ParentType, ContextType>;
  jwt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutoCompleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['AutoComplete'] = ResolversParentTypes['AutoComplete']> = {
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  queryPlusText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingField'] = ResolversParentTypes['BcMappingField']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAETableNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEEventType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEEventTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESequenceNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAELineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEPKField?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAEIgnore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAESortingOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEOutFromDataType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEOutFromDataTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEOutFromDataSubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEOutToJSONElement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEInFromDataType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEInFromDataTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEInFromDataSubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEInOperator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEInOperatorOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEInToFieldNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEInToFieldName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEInValidate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAEInTranslatesFromField?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAELineFieldNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingFieldConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingFieldConnection'] = ResolversParentTypes['BcMappingFieldConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BcMappingFieldConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingFieldConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingFieldConnectionEdge'] = ResolversParentTypes['BcMappingFieldConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BcMappingField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingFieldDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingFieldDeleteResponse'] = ResolversParentTypes['BcMappingFieldDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcMappingField']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingFieldManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingFieldManyResult'] = ResolversParentTypes['BcMappingFieldManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcMappingField']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingTableResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingTable'] = ResolversParentTypes['BcMappingTable']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAETableNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEEventType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEEventTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESequenceNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAETableName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEIgnore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAEEntityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEProcessingOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEProcessOnReceipt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAEProcessAsTransactions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAEFields?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEFilters?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAERelations?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAEDelay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingTableConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingTableConnection'] = ResolversParentTypes['BcMappingTableConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BcMappingTableConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingTableConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingTableConnectionEdge'] = ResolversParentTypes['BcMappingTableConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BcMappingTable'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingTableDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingTableDeleteResponse'] = ResolversParentTypes['BcMappingTableDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcMappingTable']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcMappingTableManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcMappingTableManyResult'] = ResolversParentTypes['BcMappingTableManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcMappingTable']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcSettings'] = ResolversParentTypes['BcSettings']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAECode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAELineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAESettingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESettingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NBAESettingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESettingValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAEIgnore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcSettingsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcSettingsConnection'] = ResolversParentTypes['BcSettingsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BcSettingsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcSettingsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcSettingsConnectionEdge'] = ResolversParentTypes['BcSettingsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BcSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcSettingsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcSettingsDeleteResponse'] = ResolversParentTypes['BcSettingsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcSettings']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcSettingsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BcSettingsManyResult'] = ResolversParentTypes['BcSettingsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcSettings']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkItemGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkItemGroup'] = ResolversParentTypes['BulkItemGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoAttribOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkItemGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkItemGroupConnection'] = ResolversParentTypes['BulkItemGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BulkItemGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkItemGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkItemGroupConnectionEdge'] = ResolversParentTypes['BulkItemGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BulkItemGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkItemGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkItemGroupDeleteResponse'] = ResolversParentTypes['BulkItemGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkItemGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkItemGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkItemGroupManyResult'] = ResolversParentTypes['BulkItemGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkItemGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CauseOfAbsenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CauseOfAbsence'] = ResolversParentTypes['CauseOfAbsence']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmployeeNoFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TotalAbsenceBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CauseOfAbsenceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CauseOfAbsenceConnection'] = ResolversParentTypes['CauseOfAbsenceConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CauseOfAbsenceConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CauseOfAbsenceConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CauseOfAbsenceConnectionEdge'] = ResolversParentTypes['CauseOfAbsenceConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CauseOfAbsence'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CauseOfAbsenceDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CauseOfAbsenceDeleteResponse'] = ResolversParentTypes['CauseOfAbsenceDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CauseOfAbsence']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CauseOfAbsenceManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CauseOfAbsenceManyResult'] = ResolversParentTypes['CauseOfAbsenceManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CauseOfAbsence']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClearFunctionalTestReusultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClearFunctionalTestReusult'] = ResolversParentTypes['ClearFunctionalTestReusult']> = {
  DateUsed?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  headersCleared?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rowsCleared?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrimaryKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentSendingProfile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OurAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TerritoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChainName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditLimitLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GiroNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankBranchNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentRoutingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomsPermitNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomsPermitDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexAnswerBack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrimaryContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BlockedOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IBAN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SWIFTCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndustrialClassification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICInboxType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICInboxDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemIndicator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomSystemIndicatorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemIndicatorStyle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowBlankPaymentInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CheckAvailPeriodCalc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CheckAvailTimeBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CalConvergenceTimeFrame?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlusGiroNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegisteredOffice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseExtraNavetPermissions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BlockObjectTypeModify?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UseStandardPriceCalculation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UseStavdalResourcePrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CombineShipments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GLN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductionDatabase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MarkedAsTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DontAskAgain?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TestEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseisInitiated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PlusGiroRefAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyImageEtag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyImageDocGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyImageURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address_Visit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2_Visit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City_Visit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode_Visit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailDomain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScriveField?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ActiveInEdge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsProspect?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Analytics?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MagentoGroupId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StoreId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WebsiteId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MagentoAddressId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsWebCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LatestInvoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RevenueThisYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RevenueLastYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RevenueYearBefLastYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IsPublicRealbridge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StatisticsGroup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinChargeTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlaceofExport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CollectionMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceCopies?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastStatementNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrintStatements?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetChangeLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProfitLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceDue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceDueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Payments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrMemoAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FinanceChargeMemoAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrMemoAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FinChargeMemoAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ApplicationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ApplicationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DontDebitPlasticTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DontDebitEnvironmentFees?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShipmentDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EnvironmentShipmentDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerClassification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Allwebitemsisvisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SharkFreightInstrToWsh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SharkPrioritizeCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SharkPrioritizeCodeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SharkInvoiceValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SharkFreightPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SharkTelephoneInstruction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoBackOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Tour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TourNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnlyActualFreightPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  PaymentMethodWeb?: Resolver<Maybe<ResolversTypes['CompanyPaymentMethodWebEnum']>, ParentType, ContextType>;
  QBSId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CRMClassification?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBDirectDebitPackType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBDirectDebitPackTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBDirectDebitPaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBDirectDebitApprovalStat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBDirectDebitApprovalStatOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBDDApprovalStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBDDApprovalStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBDocumentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMInvoiceDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCustomerTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustRentalDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCustomerTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMEditableRentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalInsurance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustRentalPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMHowInvoiceRentalContr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMHowInvoiceRentalContrOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerDeposit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCheckListCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerCategory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCustomerCategoryOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPopupNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCombineRentalInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCombineMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCombineMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalInvDayofMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCustomerClassification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManuallyBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMOrganizationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalInvoiceSequence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMEnvironmentalFeeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMNotEditable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPaidDeposits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMPaidDepositsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMJobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMInvoiceFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMInvoiceFeeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPrepaymentLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMPrepaymentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMMaxRentalValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMMaxGrantedDeposit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCatValidtoDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMNoofRentalQuotes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofOpenRntlContr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofOpenRentalLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofRentalOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofRentalCreditMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdRntlShpt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdRntlInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdRntlCrM?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofWorkshopQuotes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofWorkshopOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofWrkshpCrMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdWrkshpShpt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdWrkshpInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdWrkshpCrM?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofRntlQte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofORContr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofRntlOrds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofRCrM?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofPstdRS?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofPstdRI?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofPRCrM?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofWQte?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofWrkshpO?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofWCrM?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofPstdWS?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofPWInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMBillToNoofPWCrM?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalInsuranceOptions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalInsuranceOptionsOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPONoMandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMNoofRentPickOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofRentDeliverOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofRentCollOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdPickOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdDelOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMNoofPstdCollOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMStairPriceFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMStairDiscountFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HowtoInvoiceRentalContract?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HowtoInvoiceRentalContractOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMShortTermRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCombineInvoiceSubProj?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMTermofContract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyConnection'] = ResolversParentTypes['CompanyConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CompanyConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyConnectionEdge'] = ResolversParentTypes['CompanyConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Company'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyDeleteResponse'] = ResolversParentTypes['CompanyDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyManyResult'] = ResolversParentTypes['CompanyManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentHeader'] = ResolversParentTypes['ConsignmentHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TableID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConsignmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConsignmentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentTable?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupContactPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupContactMobPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContactPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContactMobPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPickOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMShipOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportRequestSent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportRequestSentBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReadyToFetchDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReadyToFetchTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LatestAtRecieverDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LatestAtRecieverTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AtReceiverOnTime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TotalEstimatedVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransportConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransportConfirmedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TypeOfVehicle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Freightisdebited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCollectionOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TotalGrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalNetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentHeaderConnection'] = ResolversParentTypes['ConsignmentHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ConsignmentHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentHeaderConnectionEdge'] = ResolversParentTypes['ConsignmentHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ConsignmentHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentHeaderDeleteResponse'] = ResolversParentTypes['ConsignmentHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsignmentHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentHeaderManyResult'] = ResolversParentTypes['ConsignmentHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsignmentHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentLine'] = ResolversParentTypes['ConsignmentLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConsignmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OriginalTableID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OriginalUniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalGrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalNetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Volume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TextBlob?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentLineConnection'] = ResolversParentTypes['ConsignmentLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ConsignmentLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentLineConnectionEdge'] = ResolversParentTypes['ConsignmentLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ConsignmentLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentLineDeleteResponse'] = ResolversParentTypes['ConsignmentLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsignmentLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsignmentLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsignmentLineManyResult'] = ResolversParentTypes['ConsignmentLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsignmentLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TerritoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexAnswerBack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LookupContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtensionNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MobilePhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Pager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationalLevelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExcludefromSegment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExternalID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrespondenceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalutationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchEMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  XrmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMEvaluationStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCheckListCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerCategory2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMIdentificationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContactType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMNotEditable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SalespersonFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CampaignFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionTakenFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCycleFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCycleStageFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProbabilityFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletedFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedValueFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcdCurrentValueFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChancesofSuccessFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaskStatusFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaskClosedFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriorityFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TeamFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CloseOpportunityFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NextTaskDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateAttempted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DateofLastInteraction?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NoofJobResponsibilities?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofIndustryGroups?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofBusinessRelations?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofMailingGroups?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofInteractions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DurationMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofOpportunities?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedValueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcdCurrentValueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OpportunityEntryExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaskEntryExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LinkedToCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Lead?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LinkedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastCustomerLedgerDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactConnection'] = ResolversParentTypes['ContactConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ContactConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactConnectionEdge'] = ResolversParentTypes['ContactConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactDeleteResponse'] = ResolversParentTypes['ContactDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactManyResult'] = ResolversParentTypes['ContactManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryRegionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryRegion'] = ResolversParentTypes['CountryRegion']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ISOCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ISONumericCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EUCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IntrastatCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddressFormat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AddressFormatOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactAddressFormat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContactAddressFormatOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CountyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryRegionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryRegionConnection'] = ResolversParentTypes['CountryRegionConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CountryRegionConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryRegionConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryRegionConnectionEdge'] = ResolversParentTypes['CountryRegionConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CountryRegion'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryRegionDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryRegionDeleteResponse'] = ResolversParentTypes['CountryRegionDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryRegion']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryRegionManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryRegionManyResult'] = ResolversParentTypes['CountryRegionManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryRegion']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateWorkshopOrderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateWorkshopOrderResponse'] = ResolversParentTypes['CreateWorkshopOrderResponse']> = {
  Success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Currency'] = ResolversParentTypes['Currency']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateAdjusted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ISOCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ISONumericCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnrealizedGainsAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RealizedGainsAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnrealizedLossesAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RealizedLossesAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceRoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceRoundingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceRoundingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AmountRoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitAmountRoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AmountDecimalPlaces?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitAmountDecimalPlaces?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CustLedgEntriesinFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerOutstandingOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerBalanceDue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorLedgEntriesinFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VendorBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorOutstandingOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorAmtRcdNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorBalanceDue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerBalanceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorBalanceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RealizedGLGainsAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RealizedGLLossesAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApplnRoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EMUCurrency?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualGainsAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResidualLossesAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConvLCYRndgDebitAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConvLCYRndgCreditAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MaxVATDifferenceAllowed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATRoundingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATRoundingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTolerancePercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MaxPaymentToleranceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBNoofForwardAgreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBCountryCodeISOAccNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyConnection'] = ResolversParentTypes['CurrencyConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CurrencyConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyConnectionEdge'] = ResolversParentTypes['CurrencyConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyDeleteResponse'] = ResolversParentTypes['CurrencyDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Currency']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyExchangeRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyExchangeRate'] = ResolversParentTypes['CurrencyExchangeRate']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExchangeRateAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AdjustmentExchRateAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RelationalCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RelationalExchRateAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FixExchRateAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FixExchRateAmountOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RelationalAdjmtExchRateAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyExchangeRateConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyExchangeRateConnection'] = ResolversParentTypes['CurrencyExchangeRateConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CurrencyExchangeRateConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyExchangeRateConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyExchangeRateConnectionEdge'] = ResolversParentTypes['CurrencyExchangeRateConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CurrencyExchangeRate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyExchangeRateDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyExchangeRateDeleteResponse'] = ResolversParentTypes['CurrencyExchangeRateDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CurrencyExchangeRate']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyExchangeRateManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyExchangeRateManyResult'] = ResolversParentTypes['CurrencyExchangeRateManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CurrencyExchangeRate']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyManyResult'] = ResolversParentTypes['CurrencyManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Currency']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDomainResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDomain'] = ResolversParentTypes['CustomerDomain']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdminUserGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailDomain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImageURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainImageBase64?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainImageBase64Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainMainOrientation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VerifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VerifiedByUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VerifiedByUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  TrialStartedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TrialEndsOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TrialLengthDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TrailActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoicePostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDomainConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDomainConnection'] = ResolversParentTypes['CustomerDomainConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CustomerDomainConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDomainConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDomainConnectionEdge'] = ResolversParentTypes['CustomerDomainConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CustomerDomain'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDomainDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDomainDeleteResponse'] = ResolversParentTypes['CustomerDomainDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerDomain']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDomainLimitedResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDomainLimited'] = ResolversParentTypes['CustomerDomainLimited']> = {
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDomainManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDomainManyResult'] = ResolversParentTypes['CustomerDomainManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerDomain']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPostingGroup'] = ResolversParentTypes['CustomerPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivablesAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceChargeAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentDiscDebitAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceRoundingAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalFeeAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InterestAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitCurrApplnRndgAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditCurrApplnRndgAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitRoundingAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditRoundingAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentDiscCreditAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentToleranceDebitAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentToleranceCreditAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AddFeeperLineAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ViewAllAccountsonLookup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMDepositReceiveablesAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDepositAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPrepaymentReceivableAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPrepaymentAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMBankTransferReceiveables?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPostingGroupConnection'] = ResolversParentTypes['CustomerPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CustomerPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPostingGroupConnectionEdge'] = ResolversParentTypes['CustomerPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CustomerPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPostingGroupDeleteResponse'] = ResolversParentTypes['CustomerPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPostingGroupManyResult'] = ResolversParentTypes['CustomerPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerProject'] = ResolversParentTypes['CustomerProject']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StatusChangedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProjectDimension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionConstructProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineRentalInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CombineMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineInvoiceSubProject?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SubProjectMandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerRentalPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerRentPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CustomerSalesPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSalesDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InsuranceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerRentalDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineInvperRespCenter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceZoneCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Incentive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnConfirmationperEMail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Inactive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExpectedTurnover?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShiptoInstruction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LockPrices?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EquipmentListperEMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailEquipmentList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailFrequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PopUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InactivateNoSaleMail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProjectRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Deactivated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Finished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationManagerContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectManager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Deliverycontact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectManagerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryContactEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactGraphId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExternallyCreated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsUsingDomainIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  RentIsActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsPublicRealbridge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SendToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerProjectConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerProjectConnection'] = ResolversParentTypes['CustomerProjectConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CustomerProjectConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerProjectConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerProjectConnectionEdge'] = ResolversParentTypes['CustomerProjectConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CustomerProject'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerProjectDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerProjectDeleteResponse'] = ResolversParentTypes['CustomerProjectDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerProject']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerProjectManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerProjectManyResult'] = ResolversParentTypes['CustomerProjectManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerProject']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSubProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerSubProject'] = ResolversParentTypes['CustomerSubProject']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProjectDimension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineRentalInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CombineMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CombineMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerRentalPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CustomerSalesPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSalesDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InsuranceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerRentalDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineInvperRespCenter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceZoneCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactGraphId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSubProjectConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerSubProjectConnection'] = ResolversParentTypes['CustomerSubProjectConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CustomerSubProjectConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSubProjectConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerSubProjectConnectionEdge'] = ResolversParentTypes['CustomerSubProjectConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CustomerSubProject'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSubProjectDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerSubProjectDeleteResponse'] = ResolversParentTypes['CustomerSubProjectDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerSubProject']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerSubProjectManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerSubProjectManyResult'] = ResolversParentTypes['CustomerSubProjectManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerSubProject']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyRentalEventsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyRentalEvents'] = ResolversParentTypes['DailyRentalEvents']> = {
  returnLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollLine']>>>, ParentType, ContextType>;
  shipmentLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptLine']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyTransactionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyTransactionResponse'] = ResolversParentTypes['DailyTransactionResponse']> = {
  usersNotified?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteResponse'] = ResolversParentTypes['DeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DeletedRecord']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedRecord'] = ResolversParentTypes['DeletedRecord']> = {
  GlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedRecordRefResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedRecordRef'] = ResolversParentTypes['DeletedRecordRef']> = {
  GlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliverytimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Deliverytime'] = ResolversParentTypes['Deliverytime']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoAttribOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliverytimeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliverytimeConnection'] = ResolversParentTypes['DeliverytimeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DeliverytimeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliverytimeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliverytimeConnectionEdge'] = ResolversParentTypes['DeliverytimeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Deliverytime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliverytimeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliverytimeDeleteResponse'] = ResolversParentTypes['DeliverytimeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Deliverytime']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliverytimeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliverytimeManyResult'] = ResolversParentTypes['DeliverytimeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Deliverytime']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsProject'] = ResolversParentTypes['DevopsProject']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  visibility?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsProjectConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsProjectConnection'] = ResolversParentTypes['DevopsProjectConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DevopsProjectConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsProjectConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsProjectConnectionEdge'] = ResolversParentTypes['DevopsProjectConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DevopsProject'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsProjectDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsProjectDeleteResponse'] = ResolversParentTypes['DevopsProjectDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsProject']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsProjectManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsProjectManyResult'] = ResolversParentTypes['DevopsProjectManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsProject']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsTeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsTeam'] = ResolversParentTypes['DevopsTeam']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isFolder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasChildren?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsTeamConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsTeamConnection'] = ResolversParentTypes['DevopsTeamConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DevopsTeamConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsTeamConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsTeamConnectionEdge'] = ResolversParentTypes['DevopsTeamConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DevopsTeam'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsTeamDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsTeamDeleteResponse'] = ResolversParentTypes['DevopsTeamDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsTeam']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsTeamManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsTeamManyResult'] = ResolversParentTypes['DevopsTeamManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsTeam']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsWorkItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsWorkItem'] = ResolversParentTypes['DevopsWorkItem']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rev?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsWorkItemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsWorkItemConnection'] = ResolversParentTypes['DevopsWorkItemConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DevopsWorkItemConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsWorkItemConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsWorkItemConnectionEdge'] = ResolversParentTypes['DevopsWorkItemConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DevopsWorkItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsWorkItemDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsWorkItemDeleteResponse'] = ResolversParentTypes['DevopsWorkItemDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsWorkItem']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DevopsWorkItemManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DevopsWorkItemManyResult'] = ResolversParentTypes['DevopsWorkItemManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsWorkItem']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dimension'] = ResolversParentTypes['Dimension']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CodeCaption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FilterCaption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ConsolidationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MaptoICDimensionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionConnection'] = ResolversParentTypes['DimensionConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DimensionConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionConnectionEdge'] = ResolversParentTypes['DimensionConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Dimension'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionDeleteResponse'] = ResolversParentTypes['DimensionDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Dimension']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionManyResult'] = ResolversParentTypes['DimensionManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Dimension']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionValue'] = ResolversParentTypes['DimensionValue']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionValueType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionValueTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Totaling?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ConsolidationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Indentation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GlobalDimensionNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MaptoICDimensionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MaptoICDimensionValueCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionValueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCostCenterDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectTypeDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectGroupDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRelatedSalesCostDim?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalContractDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCustomerDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCustomerProjectDimension?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionValueConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionValueConnection'] = ResolversParentTypes['DimensionValueConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DimensionValueConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionValueConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionValueConnectionEdge'] = ResolversParentTypes['DimensionValueConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DimensionValue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionValueDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionValueDeleteResponse'] = ResolversParentTypes['DimensionValueDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DimensionValue']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DimensionValueManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DimensionValueManyResult'] = ResolversParentTypes['DimensionValueManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DimensionValue']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscussionPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscussionPost'] = ResolversParentTypes['DiscussionPost']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsFromCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscussionPostConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscussionPostConnection'] = ResolversParentTypes['DiscussionPostConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DiscussionPostConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscussionPostConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscussionPostConnectionEdge'] = ResolversParentTypes['DiscussionPostConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiscussionPost'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscussionPostDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscussionPostDeleteResponse'] = ResolversParentTypes['DiscussionPostDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DiscussionPost']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscussionPostManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscussionPostManyResult'] = ResolversParentTypes['DiscussionPostManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DiscussionPost']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignInitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignInitiatingUserGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignRequestSentToUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignRequestSentToUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentSubType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentSubTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Signed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SignedByUserGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignedMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OriginalFileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignedFileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignedPrintedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentETag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Base64WriteOnly?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Classification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  files?: Resolver<Maybe<Array<Maybe<ResolversTypes['StoredFile']>>>, ParentType, ContextType>;
  originalFile?: Resolver<Maybe<ResolversTypes['StoredFile']>, ParentType, ContextType>;
  SignedByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['StoredFile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassification'] = ResolversParentTypes['DocumentClassification']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassificationConnection'] = ResolversParentTypes['DocumentClassificationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DocumentClassificationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassificationConnectionEdge'] = ResolversParentTypes['DocumentClassificationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DocumentClassification'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassificationDeleteResponse'] = ResolversParentTypes['DocumentClassificationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassification']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentClassificationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentClassificationManyResult'] = ResolversParentTypes['DocumentClassificationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassification']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentConnection'] = ResolversParentTypes['DocumentConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DocumentConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentConnectionEdge'] = ResolversParentTypes['DocumentConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Document'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentDeleteResponse'] = ResolversParentTypes['DocumentDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentManyResult'] = ResolversParentTypes['DocumentManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentRequest'] = ResolversParentTypes['DocumentRequest']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentSubType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentSubTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentRequestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentRequestConnection'] = ResolversParentTypes['DocumentRequestConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DocumentRequestConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentRequestConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentRequestConnectionEdge'] = ResolversParentTypes['DocumentRequestConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DocumentRequest'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentRequestDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentRequestDeleteResponse'] = ResolversParentTypes['DocumentRequestDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentRequest']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentRequestManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentRequestManyResult'] = ResolversParentTypes['DocumentRequestManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentRequest']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainResolvers<ContextType = any, ParentType extends ResolversParentTypes['Domain'] = ResolversParentTypes['Domain']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppBackgroundImgURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdminUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmailDomain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsDemo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EnableObjectsLimited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EnableParkingSpotsLimited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ImageEtag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImageDocGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImageURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainImageBase64?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainImageBase64Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainMainOrientation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebhookURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentDomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AlternativeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlagImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VisibleInSafetyRespect?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReservationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnReservationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SendToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainConnection'] = ResolversParentTypes['DomainConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DomainConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainConnectionEdge'] = ResolversParentTypes['DomainConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Domain'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainConnectionTestResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainConnectionTestResult'] = ResolversParentTypes['DomainConnectionTestResult']> = {
  ok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainDeleteResponse'] = ResolversParentTypes['DomainDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Domain']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainIntegration'] = ResolversParentTypes['DomainIntegration']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AuthType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commonAuthority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MetaDataJson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DisableOrderPolling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PreviousMagentoOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PreviousDekraPollingDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SchemaVersion?: Resolver<Maybe<ResolversTypes['DomainIntegrationSchemaVersionEnum']>, ParentType, ContextType>;
  Online?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AutoSyncUnsentRecords?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LatestHeartBeat?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FirstHeartBeat?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  HeartBeartIntervalMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoOfReqsInOutboundQueue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoOfReqsInInboundQueue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoOfUnprocessableItems?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HeartbeatMonitoringEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OAuth2_URL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OAuth2_Grant_Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OAuth2_Scope?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OAuth2_Client_Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OAuth2_Client_Secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OAuth2_Redirect_URL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainIntegrationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainIntegrationConnection'] = ResolversParentTypes['DomainIntegrationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DomainIntegrationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainIntegrationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainIntegrationConnectionEdge'] = ResolversParentTypes['DomainIntegrationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DomainIntegration'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainIntegrationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainIntegrationDeleteResponse'] = ResolversParentTypes['DomainIntegrationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainIntegration']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainIntegrationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainIntegrationManyResult'] = ResolversParentTypes['DomainIntegrationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainIntegration']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainLimitedResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainLimited'] = ResolversParentTypes['DomainLimited']> = {
  GlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImageURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AlternativeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlagImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReservationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnReservationsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainManyResult'] = ResolversParentTypes['DomainManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Domain']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainSettings'] = ResolversParentTypes['DomainSettings']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainSettingsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainSettingsConnection'] = ResolversParentTypes['DomainSettingsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DomainSettingsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainSettingsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainSettingsConnectionEdge'] = ResolversParentTypes['DomainSettingsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DomainSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainSettingsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainSettingsDeleteResponse'] = ResolversParentTypes['DomainSettingsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainSettings']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomainSettingsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomainSettingsManyResult'] = ResolversParentTypes['DomainSettingsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainSettings']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export type EqmBaseCalendarResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendar'] = ResolversParentTypes['EqmBaseCalendar']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomizedChangesExist?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarChange'] = ResolversParentTypes['EqmBaseCalendarChange']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecurringSystem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecurringSystemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DayOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonworking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarChangeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarChangeConnection'] = ResolversParentTypes['EqmBaseCalendarChangeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmBaseCalendarChangeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarChangeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarChangeConnectionEdge'] = ResolversParentTypes['EqmBaseCalendarChangeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmBaseCalendarChange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarChangeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarChangeDeleteResponse'] = ResolversParentTypes['EqmBaseCalendarChangeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmBaseCalendarChange']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarChangeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarChangeManyResult'] = ResolversParentTypes['EqmBaseCalendarChangeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmBaseCalendarChange']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarConnection'] = ResolversParentTypes['EqmBaseCalendarConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmBaseCalendarConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarConnectionEdge'] = ResolversParentTypes['EqmBaseCalendarConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmBaseCalendar'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarDeleteResponse'] = ResolversParentTypes['EqmBaseCalendarDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmBaseCalendar']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmBaseCalendarManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmBaseCalendarManyResult'] = ResolversParentTypes['EqmBaseCalendarManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmBaseCalendar']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCollectionReturnChargesResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCollectionReturnCharges'] = ResolversParentTypes['EqmCollectionReturnCharges']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Unitprice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReqAction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReqApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApprovedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseatFunctionalTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FunctionalTestHeaderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAESMotherMarkedForReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAESObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESObjectDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCollectionReturnChargesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCollectionReturnChargesConnection'] = ResolversParentTypes['EqmCollectionReturnChargesConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmCollectionReturnChargesConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCollectionReturnChargesConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCollectionReturnChargesConnectionEdge'] = ResolversParentTypes['EqmCollectionReturnChargesConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmCollectionReturnCharges'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCollectionReturnChargesDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCollectionReturnChargesDeleteResponse'] = ResolversParentTypes['EqmCollectionReturnChargesDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCollectionReturnCharges']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCollectionReturnChargesManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCollectionReturnChargesManyResult'] = ResolversParentTypes['EqmCollectionReturnChargesManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCollectionReturnCharges']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCommentLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCommentLine'] = ResolversParentTypes['EqmCommentLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TableLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TableSubtype?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TableSubtypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TableName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TableNameOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LinkedtoObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCommentLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCommentLineConnection'] = ResolversParentTypes['EqmCommentLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmCommentLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCommentLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCommentLineConnectionEdge'] = ResolversParentTypes['EqmCommentLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmCommentLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCommentLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCommentLineDeleteResponse'] = ResolversParentTypes['EqmCommentLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCommentLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCommentLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCommentLineManyResult'] = ResolversParentTypes['EqmCommentLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCommentLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCustomizedCalendarChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCustomizedCalendarChange'] = ResolversParentTypes['EqmCustomizedCalendarChange']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalSourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecurringSystem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecurringSystemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DayOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonworking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCustomizedCalendarChangeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCustomizedCalendarChangeConnection'] = ResolversParentTypes['EqmCustomizedCalendarChangeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmCustomizedCalendarChangeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCustomizedCalendarChangeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCustomizedCalendarChangeConnectionEdge'] = ResolversParentTypes['EqmCustomizedCalendarChangeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmCustomizedCalendarChange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCustomizedCalendarChangeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCustomizedCalendarChangeDeleteResponse'] = ResolversParentTypes['EqmCustomizedCalendarChangeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCustomizedCalendarChange']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmCustomizedCalendarChangeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmCustomizedCalendarChangeManyResult'] = ResolversParentTypes['EqmCustomizedCalendarChangeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCustomizedCalendarChange']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmFncTestReturnChargesResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmFncTestReturnCharges'] = ResolversParentTypes['EqmFncTestReturnCharges']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Unitprice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReqAction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReqApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApprovedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseatFunctionalTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredToContract?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransferredBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmFncTestReturnChargesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmFncTestReturnChargesConnection'] = ResolversParentTypes['EqmFncTestReturnChargesConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmFncTestReturnChargesConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmFncTestReturnChargesConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmFncTestReturnChargesConnectionEdge'] = ResolversParentTypes['EqmFncTestReturnChargesConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmFncTestReturnCharges'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmFncTestReturnChargesDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmFncTestReturnChargesDeleteResponse'] = ResolversParentTypes['EqmFncTestReturnChargesDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmFncTestReturnCharges']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmFncTestReturnChargesManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmFncTestReturnChargesManyResult'] = ResolversParentTypes['EqmFncTestReturnChargesManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmFncTestReturnCharges']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmLocCostCenterCombResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmLocCostCenterComb'] = ResolversParentTypes['EqmLocCostCenterComb']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmLocCostCenterCombConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmLocCostCenterCombConnection'] = ResolversParentTypes['EqmLocCostCenterCombConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmLocCostCenterCombConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmLocCostCenterCombConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmLocCostCenterCombConnectionEdge'] = ResolversParentTypes['EqmLocCostCenterCombConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmLocCostCenterComb'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmLocCostCenterCombDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmLocCostCenterCombDeleteResponse'] = ResolversParentTypes['EqmLocCostCenterCombDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmLocCostCenterComb']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmLocCostCenterCombManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmLocCostCenterCombManyResult'] = ResolversParentTypes['EqmLocCostCenterCombManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmLocCostCenterComb']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturer'] = ResolversParentTypes['EqmManufacturer']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerConnection'] = ResolversParentTypes['EqmManufacturerConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmManufacturerConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerConnectionEdge'] = ResolversParentTypes['EqmManufacturerConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmManufacturer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerDeleteResponse'] = ResolversParentTypes['EqmManufacturerDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmManufacturer']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerManyResult'] = ResolversParentTypes['EqmManufacturerManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmManufacturer']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerModel'] = ResolversParentTypes['EqmManufacturerModel']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CounterReporting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Capacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CapacityMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Effect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EffectMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LengthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WidthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CylinderVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CylinderMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachmentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofObjects?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SRALink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerModelConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerModelConnection'] = ResolversParentTypes['EqmManufacturerModelConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmManufacturerModelConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerModelConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerModelConnectionEdge'] = ResolversParentTypes['EqmManufacturerModelConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmManufacturerModel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerModelDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerModelDeleteResponse'] = ResolversParentTypes['EqmManufacturerModelDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmManufacturerModel']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmManufacturerModelManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmManufacturerModelManyResult'] = ResolversParentTypes['EqmManufacturerModelManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmManufacturerModel']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCardFlowResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCardFlow'] = ResolversParentTypes['EqmObjectCardFlow']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserSecurityID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DateFilter2?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ServiceItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LinkedtoObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowCostofSale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowComponentCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseAddCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowBookValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowAppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowWriteDown?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowAcquisitionCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Invoiceddays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedIntRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedServiceAgrCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedSrvAgrIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedIntRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCardFlowConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCardFlowConnection'] = ResolversParentTypes['EqmObjectCardFlowConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectCardFlowConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCardFlowConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCardFlowConnectionEdge'] = ResolversParentTypes['EqmObjectCardFlowConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectCardFlow'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCardFlowDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCardFlowDeleteResponse'] = ResolversParentTypes['EqmObjectCardFlowDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCardFlow']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCardFlowManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCardFlowManyResult'] = ResolversParentTypes['EqmObjectCardFlowManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCardFlow']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectComponentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectComponents'] = ResolversParentTypes['EqmObjectComponents']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LevelofAspiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LevelofAspirationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromService?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Ordered?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FatherObjectSold?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Typeold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeoldOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Assembled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NonBillable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PostReclass?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectComponentsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectComponentsConnection'] = ResolversParentTypes['EqmObjectComponentsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectComponentsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectComponentsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectComponentsConnectionEdge'] = ResolversParentTypes['EqmObjectComponentsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectComponents'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectComponentsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectComponentsDeleteResponse'] = ResolversParentTypes['EqmObjectComponentsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectComponents']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectComponentsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectComponentsManyResult'] = ResolversParentTypes['EqmObjectComponentsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectComponents']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountHeader'] = ResolversParentTypes['EqmObjectCountHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCounted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountHeaderConnection'] = ResolversParentTypes['EqmObjectCountHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectCountHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountHeaderConnectionEdge'] = ResolversParentTypes['EqmObjectCountHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectCountHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountHeaderDeleteResponse'] = ResolversParentTypes['EqmObjectCountHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCountHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountHeaderManyResult'] = ResolversParentTypes['EqmObjectCountHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCountHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountLine'] = ResolversParentTypes['EqmObjectCountLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateCounted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Corrected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CurrentLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountedObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemoText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountLineConnection'] = ResolversParentTypes['EqmObjectCountLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectCountLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountLineConnectionEdge'] = ResolversParentTypes['EqmObjectCountLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectCountLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountLineDeleteResponse'] = ResolversParentTypes['EqmObjectCountLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCountLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectCountLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectCountLineManyResult'] = ResolversParentTypes['EqmObjectCountLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCountLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupCard'] = ResolversParentTypes['EqmObjectGroupCard']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpIntRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpExtRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReRentPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofObjects?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofObjectType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObjectNos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAClassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FASubclassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepreciationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpHire?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpLeasing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplitPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpWriteUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceItemGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditonEarlyOffRent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StdRentalChargeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StdRentalChargeDaysOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FixedAssetSetupGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxDepreciationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDepreciationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxFAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxNoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxNoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxStraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgUrlSmall?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgUrlLarge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupCardConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupCardConnection'] = ResolversParentTypes['EqmObjectGroupCardConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectGroupCardConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupCardConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupCardConnectionEdge'] = ResolversParentTypes['EqmObjectGroupCardConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectGroupCard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupCardDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupCardDeleteResponse'] = ResolversParentTypes['EqmObjectGroupCardDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectGroupCard']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupCardManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupCardManyResult'] = ResolversParentTypes['EqmObjectGroupCardManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectGroupCard']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupFlowResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupFlow'] = ResolversParentTypes['EqmObjectGroupFlow']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserSecurityID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseAddCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowBookValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowAcquisitionCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupFlowConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupFlowConnection'] = ResolversParentTypes['EqmObjectGroupFlowConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectGroupFlowConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupFlowConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupFlowConnectionEdge'] = ResolversParentTypes['EqmObjectGroupFlowConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectGroupFlow'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupFlowDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupFlowDeleteResponse'] = ResolversParentTypes['EqmObjectGroupFlowDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectGroupFlow']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectGroupFlowManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectGroupFlowManyResult'] = ResolversParentTypes['EqmObjectGroupFlowManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectGroupFlow']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectServiceIntervalResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectServiceInterval'] = ResolversParentTypes['EqmObjectServiceInterval']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiatorOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ServiceDurationDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssociatedServicePack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDebit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DateCompleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TransferedtoOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferedtoWSOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferedtoWSPurchase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferedtoEQMService?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoWorkOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiator1OPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Repetitive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DateInterval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounterMargin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceDateMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiator2OPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceCounterMargin2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Interval2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceDateMarginBlocked?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ControlTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Certification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ObjectId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturerModelId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ObjectGroupId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MarginDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ObjectDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectRentalStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObjectRentalStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DekraInspectionResult?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DekraInspectionResultOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DekraInspection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectServiceIntervalConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectServiceIntervalConnection'] = ResolversParentTypes['EqmObjectServiceIntervalConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectServiceIntervalConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectServiceIntervalConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectServiceIntervalConnectionEdge'] = ResolversParentTypes['EqmObjectServiceIntervalConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectServiceInterval'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectServiceIntervalDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectServiceIntervalDeleteResponse'] = ResolversParentTypes['EqmObjectServiceIntervalDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectServiceInterval']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectServiceIntervalManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectServiceIntervalManyResult'] = ResolversParentTypes['EqmObjectServiceIntervalManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectServiceInterval']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectSrvIntervalAggResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectSrvIntervalAgg'] = ResolversParentTypes['EqmObjectSrvIntervalAgg']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiatorOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ServiceDurationDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssociatedServicePack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDebit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DateCompleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TransferedtoOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferedtoWSOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferedtoWSPurchase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferedtoEQMService?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoWorkOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Repetitive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DateInterval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounterMargin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceDateMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiator2OPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceCounterMargin2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Interval2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceDateMarginBlocked?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ControlTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerModelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AssociatedServicePackId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoOrderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoWSOrderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoWSPurchId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoEQMServiceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoWorkOrderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RepairId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedDocumentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ControlTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectSrvIntervalAggConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectSrvIntervalAggConnection'] = ResolversParentTypes['EqmObjectSrvIntervalAggConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectSrvIntervalAggConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectSrvIntervalAggConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectSrvIntervalAggConnectionEdge'] = ResolversParentTypes['EqmObjectSrvIntervalAggConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectSrvIntervalAgg'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectSrvIntervalAggDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectSrvIntervalAggDeleteResponse'] = ResolversParentTypes['EqmObjectSrvIntervalAggDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectSrvIntervalAgg']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectSrvIntervalAggManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectSrvIntervalAggManyResult'] = ResolversParentTypes['EqmObjectSrvIntervalAggManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectSrvIntervalAgg']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectStatus'] = ResolversParentTypes['EqmObjectStatus']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Descripton?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectStatusConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectStatusConnection'] = ResolversParentTypes['EqmObjectStatusConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectStatusConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectStatusConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectStatusConnectionEdge'] = ResolversParentTypes['EqmObjectStatusConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectStatusDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectStatusDeleteResponse'] = ResolversParentTypes['EqmObjectStatusDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectStatus']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectStatusManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectStatusManyResult'] = ResolversParentTypes['EqmObjectStatusManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectStatus']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeCard'] = ResolversParentTypes['EqmObjectTypeCard']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAClassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FASubclassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostingMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StandardCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IndirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastDirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceProfitCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceIncludesVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounterType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarrantyDiscParts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDiscLabor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultWarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceItemGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponseTimeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpWriteUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGrPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpHire?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpLeasing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplitPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpExtRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpIntRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGrpRental?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReRentPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReplacementPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Capacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Effect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CylinderVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AutomaticExtendedTexts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CapacityMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EffectMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CylinderMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Document?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebPage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpAvailableAfterEndDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditonEarlyReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StdRentalChargeDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultFunctionalTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DefaultMaintenance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LengthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WidthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuantityHoursFullRentalDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAConnectedToHierarchy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAMappingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAMappingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBARamirentGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESInspectionInterval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESManufacturerModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESBonusGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESExcludeFromVaction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ObjectTypeNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HireSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FixedCostPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RetentionLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDepreciationMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxFAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxNoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxNoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxStraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedDailyHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MandatoryCounteronReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounterType2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MandatoryCounter2onReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EstimatedCounterperDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedCounter2perDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Inventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryObject?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryRental?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CommentObjectGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FixedAssetSetupGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalReservationsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDeliveriesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExpectedRentalReturnsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalReturnsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PictureURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PSIDocumentURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NotAvailableForReservation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExpirationDateMandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeCardConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeCardConnection'] = ResolversParentTypes['EqmObjectTypeCardConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectTypeCardConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeCardConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeCardConnectionEdge'] = ResolversParentTypes['EqmObjectTypeCardConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectTypeCard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeCardDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeCardDeleteResponse'] = ResolversParentTypes['EqmObjectTypeCardDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeCard']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeCardManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeCardManyResult'] = ResolversParentTypes['EqmObjectTypeCardManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeCard']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeComponentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeComponents'] = ResolversParentTypes['EqmObjectTypeComponents']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LevelofAspiration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LevelofAspirationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NonBillable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InsertAutomatically?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeComponentsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeComponentsConnection'] = ResolversParentTypes['EqmObjectTypeComponentsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectTypeComponentsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeComponentsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeComponentsConnectionEdge'] = ResolversParentTypes['EqmObjectTypeComponentsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectTypeComponents'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeComponentsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeComponentsDeleteResponse'] = ResolversParentTypes['EqmObjectTypeComponentsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeComponents']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeComponentsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeComponentsManyResult'] = ResolversParentTypes['EqmObjectTypeComponentsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeComponents']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeFlowResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeFlow'] = ResolversParentTypes['EqmObjectTypeFlow']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserSecurityID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseAddCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowBookValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowAcquisitionCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeFlowConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeFlowConnection'] = ResolversParentTypes['EqmObjectTypeFlowConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectTypeFlowConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeFlowConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeFlowConnectionEdge'] = ResolversParentTypes['EqmObjectTypeFlowConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectTypeFlow'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeFlowDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeFlowDeleteResponse'] = ResolversParentTypes['EqmObjectTypeFlowDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeFlow']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeFlowManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeFlowManyResult'] = ResolversParentTypes['EqmObjectTypeFlowManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeFlow']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypePriceTermResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypePriceTerm'] = ResolversParentTypes['EqmObjectTypePriceTerm']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PercentofMonthlyPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FixedTermPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceonExtraHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowLineDisconExHours?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRepHoursPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypePriceTermConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypePriceTermConnection'] = ResolversParentTypes['EqmObjectTypePriceTermConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectTypePriceTermConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypePriceTermConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypePriceTermConnectionEdge'] = ResolversParentTypes['EqmObjectTypePriceTermConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectTypePriceTerm'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypePriceTermDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypePriceTermDeleteResponse'] = ResolversParentTypes['EqmObjectTypePriceTermDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypePriceTerm']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypePriceTermManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypePriceTermManyResult'] = ResolversParentTypes['EqmObjectTypePriceTermManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypePriceTerm']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeServiceIntervalResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeServiceInterval'] = ResolversParentTypes['EqmObjectTypeServiceInterval']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiatorOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ServiceDurationDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssociatedServicePack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDebit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ServiceMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Repetitive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DateInterval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounterMargin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceDateMargin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceInitiator2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceInitiator2OPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceCounterMargin2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Interval2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceDateMarginBlocked?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ControlTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Certification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DekraInspection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeServiceIntervalConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeServiceIntervalConnection'] = ResolversParentTypes['EqmObjectTypeServiceIntervalConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmObjectTypeServiceIntervalConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeServiceIntervalConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeServiceIntervalConnectionEdge'] = ResolversParentTypes['EqmObjectTypeServiceIntervalConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmObjectTypeServiceInterval'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeServiceIntervalDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeServiceIntervalDeleteResponse'] = ResolversParentTypes['EqmObjectTypeServiceIntervalDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeServiceInterval']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmObjectTypeServiceIntervalManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmObjectTypeServiceIntervalManyResult'] = ResolversParentTypes['EqmObjectTypeServiceIntervalManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeServiceInterval']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReRentCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReRentCard'] = ResolversParentTypes['EqmReRentCard']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchaseDocument?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedPurchaseInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedPurchaseCrMemo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalContractOpen?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalContractAll?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReRentCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReturnedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerModelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReRentCardConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReRentCardConnection'] = ResolversParentTypes['EqmReRentCardConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmReRentCardConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReRentCardConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReRentCardConnectionEdge'] = ResolversParentTypes['EqmReRentCardConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmReRentCard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReRentCardDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReRentCardDeleteResponse'] = ResolversParentTypes['EqmReRentCardDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmReRentCard']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReRentCardManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReRentCardManyResult'] = ResolversParentTypes['EqmReRentCardManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmReRentCard']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalGroup'] = ResolversParentTypes['EqmRentalGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalGroupConnection'] = ResolversParentTypes['EqmRentalGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmRentalGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalGroupConnectionEdge'] = ResolversParentTypes['EqmRentalGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmRentalGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalGroupDeleteResponse'] = ResolversParentTypes['EqmRentalGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalGroupManyResult'] = ResolversParentTypes['EqmRentalGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKIT'] = ResolversParentTypes['EqmRentalKIT']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KitType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KitTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KITPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShowinContract?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PricePriority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricePriorityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingPriority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingPriorityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecifyKitComponents?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IgnoreVaryingCompQty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditonEarlyOffRent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DebitforReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IgnoreKITLineinDispatch?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SuspendUpdateComponents?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnAccountBilling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitofMeasureId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAESBonusGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseforReporting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DefaultKIT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalItemDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitComponentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITComponents'] = ResolversParentTypes['EqmRentalKITComponents']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChildItem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitComponentsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITComponentsConnection'] = ResolversParentTypes['EqmRentalKITComponentsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmRentalKITComponentsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitComponentsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITComponentsConnectionEdge'] = ResolversParentTypes['EqmRentalKITComponentsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmRentalKITComponents'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitComponentsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITComponentsDeleteResponse'] = ResolversParentTypes['EqmRentalKITComponentsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKITComponents']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitComponentsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITComponentsManyResult'] = ResolversParentTypes['EqmRentalKITComponentsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKITComponents']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITConnection'] = ResolversParentTypes['EqmRentalKITConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmRentalKITConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITConnectionEdge'] = ResolversParentTypes['EqmRentalKITConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmRentalKIT'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITDeleteResponse'] = ResolversParentTypes['EqmRentalKITDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKIT']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitLinesResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITLines'] = ResolversParentTypes['EqmRentalKITLines']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KITNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChildNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitLinesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITLinesConnection'] = ResolversParentTypes['EqmRentalKITLinesConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmRentalKITLinesConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitLinesConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITLinesConnectionEdge'] = ResolversParentTypes['EqmRentalKITLinesConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmRentalKITLines'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitLinesDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITLinesDeleteResponse'] = ResolversParentTypes['EqmRentalKITLinesDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKITLines']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitLinesManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITLinesManyResult'] = ResolversParentTypes['EqmRentalKITLinesManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKITLines']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalKitManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalKITManyResult'] = ResolversParentTypes['EqmRentalKITManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKIT']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalLineDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalLineDiscount'] = ResolversParentTypes['EqmRentalLineDiscount']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EndingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CodeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CodeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UsageType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsageTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DiscountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiscountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairDiscountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StepwiseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseStepwiseDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FrameAgreementNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalLineDiscountConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalLineDiscountConnection'] = ResolversParentTypes['EqmRentalLineDiscountConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmRentalLineDiscountConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalLineDiscountConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalLineDiscountConnectionEdge'] = ResolversParentTypes['EqmRentalLineDiscountConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmRentalLineDiscount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalLineDiscountDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalLineDiscountDeleteResponse'] = ResolversParentTypes['EqmRentalLineDiscountDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalLineDiscount']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalLineDiscountManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalLineDiscountManyResult'] = ResolversParentTypes['EqmRentalLineDiscountManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalLineDiscount']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalPrice'] = ResolversParentTypes['EqmRentalPrice']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceIncludesVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGrPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CodeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CodeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EndingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RentalType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnPriceDisc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnStairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UsageType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsageTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAESDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceonExtraHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisconExHours?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRepHoursPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ofSalesPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MultipleRentalPrices?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FrameAgreementNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalPriceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalPriceConnection'] = ResolversParentTypes['EqmRentalPriceConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmRentalPriceConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalPriceConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalPriceConnectionEdge'] = ResolversParentTypes['EqmRentalPriceConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmRentalPrice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalPriceDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalPriceDeleteResponse'] = ResolversParentTypes['EqmRentalPriceDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalPrice']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmRentalPriceManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmRentalPriceManyResult'] = ResolversParentTypes['EqmRentalPriceManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalPrice']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReturnChargesResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReturnCharges'] = ResolversParentTypes['EqmReturnCharges']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReqAction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UseatFunctionalTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WorkType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReturnChargesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReturnChargesConnection'] = ResolversParentTypes['EqmReturnChargesConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmReturnChargesConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReturnChargesConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReturnChargesConnectionEdge'] = ResolversParentTypes['EqmReturnChargesConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmReturnCharges'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReturnChargesDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReturnChargesDeleteResponse'] = ResolversParentTypes['EqmReturnChargesDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmReturnCharges']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmReturnChargesManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmReturnChargesManyResult'] = ResolversParentTypes['EqmReturnChargesManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmReturnCharges']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceCard'] = ResolversParentTypes['EqmServiceCard']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FunctionalTestsFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WorkshopOrdersFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WorkshopPurchasesFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WorkshopPurchLinesFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlannedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedFunctionalTestsFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkshopOrdersFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkshopPurchFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AvoidCalendarEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CollectionDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MainResource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MainCustomer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MainVendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Manufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmployeeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchaseDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinishedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CreatedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofServiceOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPostedServiceOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ControlTestsFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedControlTestFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FunctionalTestPictures?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ControlTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkOrderFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofReturnCharges?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceCardConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceCardConnection'] = ResolversParentTypes['EqmServiceCardConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmServiceCardConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceCardConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceCardConnectionEdge'] = ResolversParentTypes['EqmServiceCardConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmServiceCard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceCardDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceCardDeleteResponse'] = ResolversParentTypes['EqmServiceCardDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmServiceCard']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceCardManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceCardManyResult'] = ResolversParentTypes['EqmServiceCardManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmServiceCard']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceType'] = ResolversParentTypes['EqmServiceType']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParallelwithRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NeedsWorkshopTime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceTypeConnection'] = ResolversParentTypes['EqmServiceTypeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmServiceTypeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceTypeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceTypeConnectionEdge'] = ResolversParentTypes['EqmServiceTypeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmServiceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceTypeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceTypeDeleteResponse'] = ResolversParentTypes['EqmServiceTypeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmServiceType']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmServiceTypeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmServiceTypeManyResult'] = ResolversParentTypes['EqmServiceTypeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmServiceType']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferHeader'] = ResolversParentTypes['EqmTransferHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TrsffromCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TrsftoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InTransitCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferOrderReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignedEmployeeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DirectTransfer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDispatchType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDispatchTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalDispatchOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingfromWhseRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CompletelyReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  HasShippedLines?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReadyForPostShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReadyForPostReceipt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferHeaderConnection'] = ResolversParentTypes['EqmTransferHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmTransferHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferHeaderConnectionEdge'] = ResolversParentTypes['EqmTransferHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmTransferHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferHeaderDeleteResponse'] = ResolversParentTypes['EqmTransferHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTransferHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferHeaderManyResult'] = ResolversParentTypes['EqmTransferHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTransferHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferLine'] = ResolversParentTypes['EqmTransferLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoReceive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityReceived?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShipBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoReceiveBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyReceivedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutstandingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InTransitCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyinTransitBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransferfromCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransfertoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DerivedFromLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyinTransit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQuantityInbnd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQuantityOutbnd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyInbndBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyOutbndBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReservedQuantityShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyShippedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectTransfer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ChgCurrLocationOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllocateObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllocateOwnershipStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllocateOwnershipStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllocateCreateFA?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllocateSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantitytoAllocate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDispatchType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDispatchTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalDispatchOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalDispatchOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerDamage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WhseInbndOtsdgQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WhseOutbndOtsdgQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CompletelyReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferfromBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferToBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferLineConnection'] = ResolversParentTypes['EqmTransferLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmTransferLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferLineConnectionEdge'] = ResolversParentTypes['EqmTransferLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmTransferLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferLineDeleteResponse'] = ResolversParentTypes['EqmTransferLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTransferLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTransferLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTransferLineManyResult'] = ResolversParentTypes['EqmTransferLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTransferLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTypeFunctionalTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTypeFunctionalTest'] = ResolversParentTypes['EqmTypeFunctionalTest']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TestIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalInstructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NAAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ObjectTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAESHeadline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NBAESSkillReq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTypeFunctionalTestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTypeFunctionalTestConnection'] = ResolversParentTypes['EqmTypeFunctionalTestConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmTypeFunctionalTestConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTypeFunctionalTestConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTypeFunctionalTestConnectionEdge'] = ResolversParentTypes['EqmTypeFunctionalTestConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmTypeFunctionalTest'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTypeFunctionalTestDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTypeFunctionalTestDeleteResponse'] = ResolversParentTypes['EqmTypeFunctionalTestDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTypeFunctionalTest']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmTypeFunctionalTestManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmTypeFunctionalTestManyResult'] = ResolversParentTypes['EqmTypeFunctionalTestManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTypeFunctionalTest']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkHeader'] = ResolversParentTypes['EqmWorkHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FunctionalTestNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoFailedTestIssues?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceCardNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExternalWorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalWorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromContractLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FromCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCustomerProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCollectionDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCollectionLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FromPostedCollDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromPostedCollLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FromReturnDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssignedEmployeeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextCertification?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextService?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineInvoices?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CombineMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CombineMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineInvperRespCenter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PlannedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompletedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CompletedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedbyUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowPartialInvoicing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExternalCustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalCustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalCustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalCustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDamage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Pictures?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnpostedWorkOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkHeaderConnection'] = ResolversParentTypes['EqmWorkHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmWorkHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkHeaderConnectionEdge'] = ResolversParentTypes['EqmWorkHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmWorkHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkHeaderDeleteResponse'] = ResolversParentTypes['EqmWorkHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmWorkHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkHeaderManyResult'] = ResolversParentTypes['EqmWorkHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmWorkHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkLine'] = ResolversParentTypes['EqmWorkLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverruledUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalCustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalCustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Transferred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransferredtoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoDocLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedSalesLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalCustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalCustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PatchedfromAPI?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkLineConnection'] = ResolversParentTypes['EqmWorkLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['EqmWorkLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkLineConnectionEdge'] = ResolversParentTypes['EqmWorkLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EqmWorkLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkLineDeleteResponse'] = ResolversParentTypes['EqmWorkLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmWorkLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EqmWorkLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EqmWorkLineManyResult'] = ResolversParentTypes['EqmWorkLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmWorkLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandResolvers<ContextType = any, ParentType extends ResolversParentTypes['Errand'] = ResolversParentTypes['Errand']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['ErrandTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ErrandType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Dim1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Dim2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['ErrandStatusEnum']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionBlob?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MainSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedFromRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandConnection'] = ResolversParentTypes['ErrandConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ErrandConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandConnectionEdge'] = ResolversParentTypes['ErrandConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Errand'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandDeleteResponse'] = ResolversParentTypes['ErrandDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Errand']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandLine'] = ResolversParentTypes['ErrandLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConnectedToRecord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['ErrandLineTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandLineConnection'] = ResolversParentTypes['ErrandLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ErrandLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandLineConnectionEdge'] = ResolversParentTypes['ErrandLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ErrandLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandLineDeleteResponse'] = ResolversParentTypes['ErrandLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ErrandLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandLineManyResult'] = ResolversParentTypes['ErrandLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ErrandLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrandManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrandManyResult'] = ResolversParentTypes['ErrandManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Errand']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrorReport'] = ResolversParentTypes['ErrorReport']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Caller?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CallerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CallerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandler?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandlerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandlerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TurnedOverTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Recived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorReportConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrorReportConnection'] = ResolversParentTypes['ErrorReportConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ErrorReportConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorReportConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrorReportConnectionEdge'] = ResolversParentTypes['ErrorReportConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ErrorReport'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorReportDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrorReportDeleteResponse'] = ResolversParentTypes['ErrorReportDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ErrorReport']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorReportManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ErrorReportManyResult'] = ResolversParentTypes['ErrorReportManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ErrorReport']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['Feedback'] = ResolversParentTypes['Feedback']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ByUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ByUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileGlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Processed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackConnection'] = ResolversParentTypes['FeedbackConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FeedbackConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackConnectionEdge'] = ResolversParentTypes['FeedbackConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Feedback'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackDeleteResponse'] = ResolversParentTypes['FeedbackDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feedback']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackManyResult'] = ResolversParentTypes['FeedbackManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feedback']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeemappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Feemapping'] = ResolversParentTypes['Feemapping']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LCYPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LCYPriceIncVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EURPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['FeemappingTypeEnum']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Fee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceType?: Resolver<Maybe<ResolversTypes['FeemappingPriceTypeEnum']>, ParentType, ContextType>;
  TypeOfFee?: Resolver<Maybe<ResolversTypes['FeemappingTypeOfFeeEnum']>, ParentType, ContextType>;
  FeeItemNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WebDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeemappingConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeemappingConnection'] = ResolversParentTypes['FeemappingConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FeemappingConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeemappingConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeemappingConnectionEdge'] = ResolversParentTypes['FeemappingConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Feemapping'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeemappingDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeemappingDeleteResponse'] = ResolversParentTypes['FeemappingDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feemapping']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeemappingManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeemappingManyResult'] = ResolversParentTypes['FeemappingManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feemapping']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrder'] = ResolversParentTypes['FieldOrder']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FieldOrderCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExecutionTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  WorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['FieldOrderStatusEnum']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignmentType?: Resolver<Maybe<ResolversTypes['FieldOrderAssignmentTypeEnum']>, ParentType, ContextType>;
  AssignmentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedInvoiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReversedVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Instructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoteFromPreformer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderConnection'] = ResolversParentTypes['FieldOrderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FieldOrderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderConnectionEdge'] = ResolversParentTypes['FieldOrderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['FieldOrder'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderDeleteResponse'] = ResolversParentTypes['FieldOrderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldOrder']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderLine'] = ResolversParentTypes['FieldOrderLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FieldOrderCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['FieldOrderLineTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderLineConnection'] = ResolversParentTypes['FieldOrderLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FieldOrderLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderLineConnectionEdge'] = ResolversParentTypes['FieldOrderLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['FieldOrderLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderLineDeleteResponse'] = ResolversParentTypes['FieldOrderLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldOrderLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderLineManyResult'] = ResolversParentTypes['FieldOrderLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldOrderLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldOrderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldOrderManyResult'] = ResolversParentTypes['FieldOrderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldOrder']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  base64?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileConnection'] = ResolversParentTypes['FileConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FileConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileConnectionEdge'] = ResolversParentTypes['FileConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['File'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileDeleteResponse'] = ResolversParentTypes['FileDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['File']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileManyResult'] = ResolversParentTypes['FileManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['File']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestHeader'] = ResolversParentTypes['FunctionTestHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Resource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FinishDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['FunctionTestHeaderStatusEnum']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovalStatus?: Resolver<Maybe<ResolversTypes['FunctionTestHeaderApprovalStatusEnum']>, ParentType, ContextType>;
  ApprovalStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceHeader?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromReturnDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCollectionDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinishTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinishedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TypeOfWash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalPart?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FncFailed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  HourMeterIndicator?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarrantyDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignatureFromEdge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestHeaderConnection'] = ResolversParentTypes['FunctionTestHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FunctionTestHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestHeaderConnectionEdge'] = ResolversParentTypes['FunctionTestHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['FunctionTestHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestHeaderDeleteResponse'] = ResolversParentTypes['FunctionTestHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FunctionTestHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestHeaderManyResult'] = ResolversParentTypes['FunctionTestHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FunctionTestHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestLine'] = ResolversParentTypes['FunctionTestLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalInstructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecificMeasure?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DirectMeasure?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InspectionTimeInterval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ToCorrect?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkShopOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkShopOrderFinished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Headline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoteFunctionTest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoteDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoteOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MoveNote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Mandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TimeEstimation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestedApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TestedExecuted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExecutedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestLineConnection'] = ResolversParentTypes['FunctionTestLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['FunctionTestLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestLineConnectionEdge'] = ResolversParentTypes['FunctionTestLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['FunctionTestLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestLineDeleteResponse'] = ResolversParentTypes['FunctionTestLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FunctionTestLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FunctionTestLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FunctionTestLineManyResult'] = ResolversParentTypes['FunctionTestLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['FunctionTestLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenBusinessPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenBusinessPostingGroup'] = ResolversParentTypes['GenBusinessPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefVATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoInsertDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenBusinessPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenBusinessPostingGroupConnection'] = ResolversParentTypes['GenBusinessPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['GenBusinessPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenBusinessPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenBusinessPostingGroupConnectionEdge'] = ResolversParentTypes['GenBusinessPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['GenBusinessPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenBusinessPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenBusinessPostingGroupDeleteResponse'] = ResolversParentTypes['GenBusinessPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenBusinessPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenBusinessPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenBusinessPostingGroupManyResult'] = ResolversParentTypes['GenBusinessPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenBusinessPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenJournalLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenJournalLine'] = ResolversParentTypes['GenJournalLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JournalTemplateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DebitAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesPurchLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProfitLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BilltoPaytoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersPurchCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PendingApproval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATPosting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATPostingOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnitCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JournalBatchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecurringMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecurringMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RecurringFrequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllocatedAmtLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GenPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GenPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowApplication?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalGenPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalGenPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalGenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalGenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalVATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalVATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalVATAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BankPaymentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BankPaymentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalVATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrintPostedDocuments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CheckPrinted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BalTaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalTaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BalTaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalUseTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalVATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalVATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalCurrencyPosting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AdditionalCurrencyPostingOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAAddCurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCurrencyAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceCurrVATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceCurrVATAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalVATAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalVATBaseAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReversingEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowZeroAmountPosting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShiptoOrderAddressCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalVATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerGLAccNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerTransactionNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SelltoBuyfromNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FinancialVoid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CopyVATSetuptoJnlLines?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBaseBeforePmtDisc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrigPmtDiscPossible?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrigPmtDiscPossibleLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQueueStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQueueStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueEntryID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncomingDocumentEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoExtDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeepDescription?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RecipientBankAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MessagetoRecipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExportedtoPaymentFile?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  HasPaymentExportError?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobUnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitOfMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobPlanningLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobRemainingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectDebitMandateID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DataExchEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PayerInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DataExchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliedAutomatically?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LinkedTableID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LinkedSystemID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeferralLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProdOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FAPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalvageValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofDepreciationDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeprAcquisitionCost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InsuranceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedFANo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FAReclassificationEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FAErrorEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IndexEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SourceLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CheckExported?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CheckTransmitted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReverseDateCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoInvoiceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactGraphId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  JournalBatchId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalanceAccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourcePostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBDirectDebitPackNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBInformationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBInformationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBLastGLEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBLastGLRegEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBSIEImportCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBOCRNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBExtDocNonotMandat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSkipExtraPost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMDepositPosting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCustomerDepositNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDisposalType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMDisposalTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDepositEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMAdvancePaymentEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMAdvPaymentAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectErrorEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDepositPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMTransactionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMFANo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFADepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenJournalLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenJournalLineConnection'] = ResolversParentTypes['GenJournalLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['GenJournalLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenJournalLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenJournalLineConnectionEdge'] = ResolversParentTypes['GenJournalLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['GenJournalLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenJournalLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenJournalLineDeleteResponse'] = ResolversParentTypes['GenJournalLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenJournalLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenJournalLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenJournalLineManyResult'] = ResolversParentTypes['GenJournalLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenJournalLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenProductPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenProductPostingGroup'] = ResolversParentTypes['GenProductPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefVATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoInsertDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPercentofMonthlyPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenProductPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenProductPostingGroupConnection'] = ResolversParentTypes['GenProductPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['GenProductPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenProductPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenProductPostingGroupConnectionEdge'] = ResolversParentTypes['GenProductPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['GenProductPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenProductPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenProductPostingGroupDeleteResponse'] = ResolversParentTypes['GenProductPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenProductPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenProductPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenProductPostingGroupManyResult'] = ResolversParentTypes['GenProductPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenProductPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IosAppCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IOSAppCode'] = ResolversParentTypes['IOSAppCode']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Inspection'] = ResolversParentTypes['Inspection']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InspectionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ObjectStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObjectStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Document?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionConnection'] = ResolversParentTypes['InspectionConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['InspectionConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionConnectionEdge'] = ResolversParentTypes['InspectionConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Inspection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionDeleteResponse'] = ResolversParentTypes['InspectionDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Inspection']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionManyResult'] = ResolversParentTypes['InspectionManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Inspection']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionUser'] = ResolversParentTypes['InspectionUser']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesPerson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionUserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionUserConnection'] = ResolversParentTypes['InspectionUserConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['InspectionUserConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionUserConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionUserConnectionEdge'] = ResolversParentTypes['InspectionUserConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['InspectionUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionUserDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionUserDeleteResponse'] = ResolversParentTypes['InspectionUserDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InspectionUser']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InspectionUserManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InspectionUserManyResult'] = ResolversParentTypes['InspectionUserManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InspectionUser']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationCollection'] = ResolversParentTypes['IntegrationCollection']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LatestHeartBeat?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FirstHeartBeat?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  HeartBeartIntervalMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HeartbeatMonitoringEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationCollectionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationCollectionConnection'] = ResolversParentTypes['IntegrationCollectionConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['IntegrationCollectionConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationCollectionConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationCollectionConnectionEdge'] = ResolversParentTypes['IntegrationCollectionConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['IntegrationCollection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationCollectionDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationCollectionDeleteResponse'] = ResolversParentTypes['IntegrationCollectionDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationCollection']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationCollectionManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationCollectionManyResult'] = ResolversParentTypes['IntegrationCollectionManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationCollection']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryPostingGroup'] = ResolversParentTypes['InventoryPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryPostingGroupConnection'] = ResolversParentTypes['InventoryPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['InventoryPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryPostingGroupConnectionEdge'] = ResolversParentTypes['InventoryPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['InventoryPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryPostingGroupDeleteResponse'] = ResolversParentTypes['InventoryPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InventoryPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InventoryPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InventoryPostingGroupManyResult'] = ResolversParentTypes['InventoryPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InventoryPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invitation'] = ResolversParentTypes['Invitation']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['InvitationStatusEnum']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['InvitationTypeEnum']>, ParentType, ContextType>;
  Role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AcceptedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AcceptedByUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreateByUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvitationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  Deactivated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IOSAppLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AndroidAppLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IOSAppCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsOnAppStore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsOnPlayStore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  IOSAppCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['IOSAppCode']>>>, ParentType, ContextType>;
  IOSAppCodeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationConnection'] = ResolversParentTypes['InvitationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['InvitationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationConnectionEdge'] = ResolversParentTypes['InvitationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Invitation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationDeleteResponse'] = ResolversParentTypes['InvitationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invitation']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationLimitedResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationLimited'] = ResolversParentTypes['InvitationLimited']> = {
  CompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationManyResult'] = ResolversParentTypes['InvitationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invitation']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceHeader'] = ResolversParentTypes['InvoiceHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentServiceSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentExchangeIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentExchangeStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocExchOriginalIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DirectDebitMandateID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustLedgerEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OpportunityNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GetShipmentUsed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMShipmentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMTransferMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWorkshopDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCombineCustomerProj?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceTermNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMInvoiceDocType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectWarrantyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastEmailSentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastEmailSentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SentasEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastEmailNotifCleared?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Closed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RemainingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cancelled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Corrective?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMLinkedCreditMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceLine']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceHeaderConnection'] = ResolversParentTypes['InvoiceHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['InvoiceHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceHeaderConnectionEdge'] = ResolversParentTypes['InvoiceHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['InvoiceHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceHeaderDeleteResponse'] = ResolversParentTypes['InvoiceHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceHeaderManyResult'] = ResolversParentTypes['InvoiceHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceLine'] = ResolversParentTypes['InvoiceLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATClauseCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LineDiscountCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobContractEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApplfromItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Pricedescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalFromDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMAttachedtoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMLineUpdated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalFromDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalToDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMLineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReturnFromDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMReturnToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMReturnWholePeriod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMReturnRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReturnFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMTransferThisLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMReturnQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceUpwrite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalLineType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMUseOfFixPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMUpdateRentalLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWorkshopGuarantee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSkipObjectIntoInv?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRefBasisEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMAmountCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMStairPriceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMStairPriceStep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalKITMother?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalKITMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMDepositPosting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMReRentObject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMUsedFractionCalculation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalHeaderLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRefOrigDoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRefOrigLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMManuallyChanged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMReclassOrderedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMExtraHoursCharged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMExtraHoursReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSplitCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRePurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMInvoiceFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAdvancePaymentEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectInsurance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPriceReportedHours?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceLineConnection'] = ResolversParentTypes['InvoiceLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['InvoiceLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceLineConnectionEdge'] = ResolversParentTypes['InvoiceLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['InvoiceLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceLineDeleteResponse'] = ResolversParentTypes['InvoiceLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceLineManyResult'] = ResolversParentTypes['InvoiceLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['Item'] = ResolversParentTypes['Item']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceUnitConversion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShelfNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  StatisticsGroup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CommissionGroup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceProfitCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CostingMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StandardCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastDirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IndirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CostisAdjusted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowOnlineAdjustment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReorderPoint?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MaximumInventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReorderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AlternativeItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitListPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DutyDue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DutyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Durability?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FreightType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TariffNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DutyUnitConversion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CountryRegionPurchasedCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BudgetedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BudgetProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BlockReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateTimeModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceIncludesVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGrPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionofOriginCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutomaticExtTexts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProductPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StockoutWarning?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreventNegativeInventory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApplicationWkshUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssemblyPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GTIN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultDeferralTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LowLevelCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LotSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SerialNos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUnitCostCalcDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RolledupMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RolledupCapacityCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Scrap?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryValueZero?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DiscreteOrderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumOrderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MaximumOrderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SafetyStockQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderMultiple?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SafetyLeadTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlushingMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReplenishmentSystem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReorderingPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncludeInventory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ManufacturingPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReschedulingPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LotAccumulationPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DampenerPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DampenerQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverflowLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedFromNonstockItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceItemGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemTrackingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LotNos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpirationCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarehouseClassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialEquipmentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PutawayTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PutawayUnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhysInvtCountingPeriodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastCountingPeriodUpdate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseCrossDocking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NextCountingStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextCountingEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMItemNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDiamondBladeItem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDiamondPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDimensionRentalType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMMeasurement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReRentRProdPostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReRentSProdPostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectBOMPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMVATRntlProdPostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReplacementPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMFixedAssetSetupGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDescription3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDescription4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMBlockedRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMExpAvailAfterEndDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMCreditonEarlyReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMStdRentalChargeDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMBaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMGenRentalPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMDefaultRentalItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalItemDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDimensionRentalGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMGenRepurchasePostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSquareMeter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMFilterLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoutingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductionBOMNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SingleLevelMaterialCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SingleLevelCapacityCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SingleLevelSubcontrdCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SingleLevelCapOvhdCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SingleLevelMfgOvhdCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverheadRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RolledupSubcontractedCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RolledupMfgOvhdCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RolledupCapOverheadCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderTrackingPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Critical?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CommonItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DropShipmentFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BinFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LotNoFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNoFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSaleFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ProductionForecastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ComponentForecast?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AssemblyBOM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CostisPostedtoGL?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Inventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetInvoicedQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchasesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PositiveAdjmtQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NegativeAdjmtQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchasesLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PositiveAdjmtLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NegativeAdjmtLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  COGSLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonPurchOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonSalesOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransferredQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransferredLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyonInventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyonPurchOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyonSalesOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonOutboundTransfer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonInboundTransfer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonSalesReturns?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonPurchReturns?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CostofOpenProductionOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonAssemblyOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonAsmComp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonAssemblyOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonAsmComponent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonJobOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonJobOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ScheduledReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ScheduledNeedQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyonProdOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonProdOrderComp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonReqLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlanningTransferShipQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlanningWorksheetQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StockkeepingUnitExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SubstitutesExist?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtyinTransit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransOrdReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransOrdShipmentQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyAssignedtoship?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyPicked?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonServiceOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResQtyonServiceOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonPurchReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonSalesReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofSubstitutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastPhysInvtDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  IdentifierCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAvailabletoTake?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMUseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalReservationsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalDeliveriesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMExpRentalReturnsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalReturnsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMQtyonRentalReRent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMQtyonRent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlanningIssuesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlanningReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlannedOrderReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FPOrderReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RelOrderReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlanningReleaseQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlannedOrderReleaseQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchReqReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchReqReleaseQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProdForecastQuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonProdOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonComponentLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SynkedToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MagentoSyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttributeSetId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Visibility?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Website?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalculatedInventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalculatedInventoryUOM?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CalculatedInventoryUpdatedAd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActiveWeb?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TempOutOfStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutletSE?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutletDateFromSE?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OutletDateToSE?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CampaignSE?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CampaignDateFromSE?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CampaignDateToSE?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DeliveryInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IsBulky?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OrderItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageHeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PackageWidth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PackageLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DescriptionNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BrandDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomeDelivery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ServicePointDelivery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BlockedNO?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExpectedInStockDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  BulkGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsOutlet?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsCampaign?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GiftcardType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UrlKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShowInFieldOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ItemType?: Resolver<Maybe<ResolversTypes['ItemItemTypeEnum']>, ParentType, ContextType>;
  ItemTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderMultiple?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EcoLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MainGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OldItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OldItemNo2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RKVItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ToExpire?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AcquisitionItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MagentoItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AlwaysAvailableOnWeb?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QBSId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EdgeSmallImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EdgeLargelImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CalculationPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemCategory'] = ResolversParentTypes['ItemCategory']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Indentation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PresentationOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HasChildren?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DefGenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefInventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefTaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefCostingMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefCostingMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefVATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefReRentRProdPostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefReRentSProdPostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMDefGenRntlPostGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCategoryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemCategoryConnection'] = ResolversParentTypes['ItemCategoryConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemCategoryConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCategoryConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemCategoryConnectionEdge'] = ResolversParentTypes['ItemCategoryConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ItemCategory'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCategoryDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemCategoryDeleteResponse'] = ResolversParentTypes['ItemCategoryDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemCategory']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCategoryManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemCategoryManyResult'] = ResolversParentTypes['ItemCategoryManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemCategory']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemConnection'] = ResolversParentTypes['ItemConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemConnectionEdge'] = ResolversParentTypes['ItemConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemDeleteResponse'] = ResolversParentTypes['ItemDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Item']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDiscGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemDiscGroup'] = ResolversParentTypes['ItemDiscGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDiscGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemDiscGroupConnection'] = ResolversParentTypes['ItemDiscGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemDiscGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDiscGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemDiscGroupConnectionEdge'] = ResolversParentTypes['ItemDiscGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ItemDiscGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDiscGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemDiscGroupDeleteResponse'] = ResolversParentTypes['ItemDiscGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemDiscGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDiscGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemDiscGroupManyResult'] = ResolversParentTypes['ItemDiscGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemDiscGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemManyResult'] = ResolversParentTypes['ItemManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Item']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemReference'] = ResolversParentTypes['ItemReference']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DiscontinueBarCode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemReferenceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemReferenceConnection'] = ResolversParentTypes['ItemReferenceConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemReferenceConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemReferenceConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemReferenceConnectionEdge'] = ResolversParentTypes['ItemReferenceConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ItemReference'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemReferenceDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemReferenceDeleteResponse'] = ResolversParentTypes['ItemReferenceDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemReference']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemReferenceManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemReferenceManyResult'] = ResolversParentTypes['ItemReferenceManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemReference']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRestrictionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRestriction'] = ResolversParentTypes['ItemRestriction']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['ItemRestrictionTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRestrictionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRestrictionConnection'] = ResolversParentTypes['ItemRestrictionConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemRestrictionConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRestrictionConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRestrictionConnectionEdge'] = ResolversParentTypes['ItemRestrictionConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ItemRestriction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRestrictionDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRestrictionDeleteResponse'] = ResolversParentTypes['ItemRestrictionDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemRestriction']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRestrictionManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemRestrictionManyResult'] = ResolversParentTypes['ItemRestrictionManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemRestriction']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUnitOfMeasureResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUnitOfMeasure'] = ResolversParentTypes['ItemUnitOfMeasure']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyRoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cubage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUnitOfMeasureConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUnitOfMeasureConnection'] = ResolversParentTypes['ItemUnitOfMeasureConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemUnitOfMeasureConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUnitOfMeasureConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUnitOfMeasureConnectionEdge'] = ResolversParentTypes['ItemUnitOfMeasureConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ItemUnitOfMeasure'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUnitOfMeasureDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUnitOfMeasureDeleteResponse'] = ResolversParentTypes['ItemUnitOfMeasureDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemUnitOfMeasure']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUnitOfMeasureManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUnitOfMeasureManyResult'] = ResolversParentTypes['ItemUnitOfMeasureManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemUnitOfMeasure']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemVendorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemVendor'] = ResolversParentTypes['ItemVendor']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemVendorConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemVendorConnection'] = ResolversParentTypes['ItemVendorConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ItemVendorConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemVendorConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemVendorConnectionEdge'] = ResolversParentTypes['ItemVendorConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ItemVendor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemVendorDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemVendorDeleteResponse'] = ResolversParentTypes['ItemVendorDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemVendor']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemVendorManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemVendorManyResult'] = ResolversParentTypes['ItemVendorManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemVendor']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTask'] = ResolversParentTypes['JobTask']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WIPTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WIPTotalOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WIPMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Totaling?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NewPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoofBlankLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Indentation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecognizedSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecognizedCostsAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecognizedSalesGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecognizedCostsGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingDateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlanningDateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduleTotalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ScheduleTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsageTotalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsageTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractTotalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractInvoicedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractInvoicedCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtRcdNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RemainingTotalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RemainingTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClosedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FirstSavedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DevopsId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTaskConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTaskConnection'] = ResolversParentTypes['JobTaskConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['JobTaskConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTaskConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTaskConnectionEdge'] = ResolversParentTypes['JobTaskConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['JobTask'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTaskDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTaskDeleteResponse'] = ResolversParentTypes['JobTaskDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobTask']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTaskManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTaskManyResult'] = ResolversParentTypes['JobTaskManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobTask']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliHeader'] = ResolversParentTypes['KliHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ErrandNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NameDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Credit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Timemin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QAdistdat?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FinnishedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorectivAction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NewInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentOwnerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Closed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CostPerMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImpactOnStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Guarantee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReasonCodeDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Workplace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Caller?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CallerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModelYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Pirority?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RecType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RevisitNeeded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MachineReplaced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AnalysisCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AnalysisText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MainErrand?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LinkErrand?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SendToRepairer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FollowUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ErrandDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FollowUpPreformed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CorectivActionPreformed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastStatusBeforeClose?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastStatusBeforeCloseOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkplaceContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkplaceContactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TableNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonTextBlob?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionTextBlob?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FixedByExternalRepairer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TurnaroundTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReportedToWorkingEnvDpt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SafteyOfficerInformed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NewRiskAnalysis?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AnalysisTextBLOB?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FollowUpBLOB?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorectivActionBLOB?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AccidentComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditPosted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditApprovedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoOfLinkedErrands?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TurnOverToEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedExternal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandler?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandlerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandlerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDamage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliHeaderConnection'] = ResolversParentTypes['KliHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['KliHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliHeaderConnectionEdge'] = ResolversParentTypes['KliHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['KliHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliHeaderDeleteResponse'] = ResolversParentTypes['KliHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['KliHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliHeaderManyResult'] = ResolversParentTypes['KliHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['KliHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliReasonCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliReasonCode'] = ResolversParentTypes['KliReasonCode']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliReasonCodeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliReasonCodeConnection'] = ResolversParentTypes['KliReasonCodeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['KliReasonCodeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliReasonCodeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliReasonCodeConnectionEdge'] = ResolversParentTypes['KliReasonCodeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['KliReasonCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliReasonCodeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliReasonCodeDeleteResponse'] = ResolversParentTypes['KliReasonCodeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['KliReasonCode']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KliReasonCodeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['KliReasonCodeManyResult'] = ResolversParentTypes['KliReasonCodeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['KliReasonCode']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadResolvers<ContextType = any, ParentType extends ResolversParentTypes['Lead'] = ResolversParentTypes['Lead']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TerritoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PictureURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MobilePhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OwnerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OwnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeadConnection'] = ResolversParentTypes['LeadConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['LeadConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeadConnectionEdge'] = ResolversParentTypes['LeadConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Lead'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeadDeleteResponse'] = ResolversParentTypes['LeadDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Lead']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeadManyResult'] = ResolversParentTypes['LeadManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Lead']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LimitedCustomerProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['LimitedCustomerProject'] = ResolversParentTypes['LimitedCustomerProject']> = {
  ProjectNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Deactivated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsUsingDomainIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LimitedUpdateCustomerProjectResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LimitedUpdateCustomerProjectResponse'] = ResolversParentTypes['LimitedUpdateCustomerProjectResponse']> = {
  record?: Resolver<ResolversTypes['LimitedCustomerProject'], ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  recordsModified?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineAmount'] = ResolversParentTypes['LineAmount']> = {
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PeriodName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseAsInTransit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequirePutaway?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequirePick?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CrossDockDueDateCalc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseCrossDocking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequireReceive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequireShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BinMandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DirectedPutawayandPick?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DefaultBinSelection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultBinSelectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PutawayTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UsePutawayWorksheet?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PickAccordingtoFEFO?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowBreakbulk?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BinCapacityPolicy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BinCapacityPolicyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OpenShopFloorBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ToProductionBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromProductionBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdjustmentBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AlwaysCreatePutawayLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AlwaysCreatePickLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SpecialEquipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SpecialEquipmentOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiptBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossDockBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ToAssemblyBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FromAssemblyBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AsmtoOrderShptBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ToJobBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseADCS?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMInternalCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassJnlTemplName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassJnlBatchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMMaintenanceLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMHUB?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExternalLocationId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExternalLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationConnection'] = ResolversParentTypes['LocationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['LocationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationConnectionEdge'] = ResolversParentTypes['LocationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationDeleteResponse'] = ResolversParentTypes['LocationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationManyResult'] = ResolversParentTypes['LocationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoOrderStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoOrderStatus'] = ResolversParentTypes['MagentoOrderStatus']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntityId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IncrementId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StoreId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WebsiteId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CheckCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoOrderStatusConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoOrderStatusConnection'] = ResolversParentTypes['MagentoOrderStatusConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['MagentoOrderStatusConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoOrderStatusConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoOrderStatusConnectionEdge'] = ResolversParentTypes['MagentoOrderStatusConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['MagentoOrderStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoOrderStatusDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoOrderStatusDeleteResponse'] = ResolversParentTypes['MagentoOrderStatusDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['MagentoOrderStatus']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoOrderStatusManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoOrderStatusManyResult'] = ResolversParentTypes['MagentoOrderStatusManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['MagentoOrderStatus']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoSettings'] = ResolversParentTypes['MagentoSettings']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuestCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuestCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuestCustomerGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoSettingsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoSettingsConnection'] = ResolversParentTypes['MagentoSettingsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['MagentoSettingsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoSettingsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoSettingsConnectionEdge'] = ResolversParentTypes['MagentoSettingsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['MagentoSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoSettingsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoSettingsDeleteResponse'] = ResolversParentTypes['MagentoSettingsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['MagentoSettings']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MagentoSettingsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MagentoSettingsManyResult'] = ResolversParentTypes['MagentoSettingsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['MagentoSettings']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManufacturerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Manufacturer'] = ResolversParentTypes['Manufacturer']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoAttribOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManufacturerConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManufacturerConnection'] = ResolversParentTypes['ManufacturerConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ManufacturerConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManufacturerConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManufacturerConnectionEdge'] = ResolversParentTypes['ManufacturerConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Manufacturer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManufacturerDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManufacturerDeleteResponse'] = ResolversParentTypes['ManufacturerDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Manufacturer']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManufacturerManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManufacturerManyResult'] = ResolversParentTypes['ManufacturerManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Manufacturer']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  ping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  upsertAccessRequest?: Resolver<Maybe<ResolversTypes['AccessRequest']>, ParentType, ContextType, RequireFields<MutationUpsertAccessRequestArgs, never>>;
  removeAccessRequest?: Resolver<Maybe<ResolversTypes['AccessRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAccessRequestArgs, never>>;
  removeAccessRequestMany?: Resolver<Maybe<ResolversTypes['AccessRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAccessRequestManyArgs, never>>;
  upsertAdditionalItem?: Resolver<Maybe<ResolversTypes['AdditionalItem']>, ParentType, ContextType, RequireFields<MutationUpsertAdditionalItemArgs, never>>;
  removeAdditionalItem?: Resolver<Maybe<ResolversTypes['AdditionalItemDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAdditionalItemArgs, never>>;
  removeAdditionalItemMany?: Resolver<Maybe<ResolversTypes['AdditionalItemDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAdditionalItemManyArgs, never>>;
  upsertAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, RequireFields<MutationUpsertAddressArgs, never>>;
  removeAddress?: Resolver<Maybe<ResolversTypes['AddressDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAddressArgs, never>>;
  removeAddressMany?: Resolver<Maybe<ResolversTypes['AddressDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAddressManyArgs, never>>;
  upsertAgreement?: Resolver<Maybe<ResolversTypes['Agreement']>, ParentType, ContextType, RequireFields<MutationUpsertAgreementArgs, never>>;
  removeAgreement?: Resolver<Maybe<ResolversTypes['AgreementDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAgreementArgs, never>>;
  removeAgreementMany?: Resolver<Maybe<ResolversTypes['AgreementDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAgreementManyArgs, never>>;
  upsertAnnotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType, RequireFields<MutationUpsertAnnotationArgs, never>>;
  removeAnnotation?: Resolver<Maybe<ResolversTypes['AnnotationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAnnotationArgs, never>>;
  removeAnnotationMany?: Resolver<Maybe<ResolversTypes['AnnotationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveAnnotationManyArgs, never>>;
  upsertApplicationModule?: Resolver<Maybe<ResolversTypes['ApplicationModule']>, ParentType, ContextType, RequireFields<MutationUpsertApplicationModuleArgs, never>>;
  removeApplicationModule?: Resolver<Maybe<ResolversTypes['ApplicationModuleDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveApplicationModuleArgs, never>>;
  removeApplicationModuleMany?: Resolver<Maybe<ResolversTypes['ApplicationModuleDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveApplicationModuleManyArgs, never>>;
  upsertBcMappingTable?: Resolver<Maybe<ResolversTypes['BcMappingTable']>, ParentType, ContextType, RequireFields<MutationUpsertBcMappingTableArgs, never>>;
  removeBcMappingTable?: Resolver<Maybe<ResolversTypes['BcMappingTableDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBcMappingTableArgs, never>>;
  removeBcMappingTableMany?: Resolver<Maybe<ResolversTypes['BcMappingTableDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBcMappingTableManyArgs, never>>;
  upsertBcMappingField?: Resolver<Maybe<ResolversTypes['BcMappingField']>, ParentType, ContextType, RequireFields<MutationUpsertBcMappingFieldArgs, never>>;
  removeBcMappingField?: Resolver<Maybe<ResolversTypes['BcMappingFieldDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBcMappingFieldArgs, never>>;
  removeBcMappingFieldMany?: Resolver<Maybe<ResolversTypes['BcMappingFieldDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBcMappingFieldManyArgs, never>>;
  upsertBcSettings?: Resolver<Maybe<ResolversTypes['BcSettings']>, ParentType, ContextType, RequireFields<MutationUpsertBcSettingsArgs, never>>;
  removeBcSettings?: Resolver<Maybe<ResolversTypes['BcSettingsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBcSettingsArgs, never>>;
  removeBcSettingsMany?: Resolver<Maybe<ResolversTypes['BcSettingsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBcSettingsManyArgs, never>>;
  upsertBulkItemGroup?: Resolver<Maybe<ResolversTypes['BulkItemGroup']>, ParentType, ContextType, RequireFields<MutationUpsertBulkItemGroupArgs, never>>;
  removeBulkItemGroup?: Resolver<Maybe<ResolversTypes['BulkItemGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBulkItemGroupArgs, never>>;
  removeBulkItemGroupMany?: Resolver<Maybe<ResolversTypes['BulkItemGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveBulkItemGroupManyArgs, never>>;
  upsertCauseOfAbsence?: Resolver<Maybe<ResolversTypes['CauseOfAbsence']>, ParentType, ContextType, RequireFields<MutationUpsertCauseOfAbsenceArgs, never>>;
  removeCauseOfAbsence?: Resolver<Maybe<ResolversTypes['CauseOfAbsenceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCauseOfAbsenceArgs, never>>;
  removeCauseOfAbsenceMany?: Resolver<Maybe<ResolversTypes['CauseOfAbsenceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCauseOfAbsenceManyArgs, never>>;
  upsertCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationUpsertCompanyArgs, never>>;
  removeCompany?: Resolver<Maybe<ResolversTypes['CompanyDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCompanyArgs, never>>;
  removeCompanyMany?: Resolver<Maybe<ResolversTypes['CompanyDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCompanyManyArgs, never>>;
  upsertConsignmentHeader?: Resolver<Maybe<ResolversTypes['ConsignmentHeader']>, ParentType, ContextType, RequireFields<MutationUpsertConsignmentHeaderArgs, never>>;
  removeConsignmentHeader?: Resolver<Maybe<ResolversTypes['ConsignmentHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveConsignmentHeaderArgs, never>>;
  removeConsignmentHeaderMany?: Resolver<Maybe<ResolversTypes['ConsignmentHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveConsignmentHeaderManyArgs, never>>;
  upsertConsignmentLine?: Resolver<Maybe<ResolversTypes['ConsignmentLine']>, ParentType, ContextType, RequireFields<MutationUpsertConsignmentLineArgs, never>>;
  removeConsignmentLine?: Resolver<Maybe<ResolversTypes['ConsignmentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveConsignmentLineArgs, never>>;
  removeConsignmentLineMany?: Resolver<Maybe<ResolversTypes['ConsignmentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveConsignmentLineManyArgs, never>>;
  upsertContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType, RequireFields<MutationUpsertContactArgs, never>>;
  removeContact?: Resolver<Maybe<ResolversTypes['ContactDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveContactArgs, never>>;
  removeContactMany?: Resolver<Maybe<ResolversTypes['ContactDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveContactManyArgs, never>>;
  upsertCountryRegion?: Resolver<Maybe<ResolversTypes['CountryRegion']>, ParentType, ContextType, RequireFields<MutationUpsertCountryRegionArgs, never>>;
  removeCountryRegion?: Resolver<Maybe<ResolversTypes['CountryRegionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCountryRegionArgs, never>>;
  removeCountryRegionMany?: Resolver<Maybe<ResolversTypes['CountryRegionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCountryRegionManyArgs, never>>;
  upsertCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType, RequireFields<MutationUpsertCurrencyArgs, never>>;
  removeCurrency?: Resolver<Maybe<ResolversTypes['CurrencyDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCurrencyArgs, never>>;
  removeCurrencyMany?: Resolver<Maybe<ResolversTypes['CurrencyDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCurrencyManyArgs, never>>;
  upsertCurrencyExchangeRate?: Resolver<Maybe<ResolversTypes['CurrencyExchangeRate']>, ParentType, ContextType, RequireFields<MutationUpsertCurrencyExchangeRateArgs, never>>;
  removeCurrencyExchangeRate?: Resolver<Maybe<ResolversTypes['CurrencyExchangeRateDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCurrencyExchangeRateArgs, never>>;
  removeCurrencyExchangeRateMany?: Resolver<Maybe<ResolversTypes['CurrencyExchangeRateDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCurrencyExchangeRateManyArgs, never>>;
  upsertCustomerDomain?: Resolver<Maybe<ResolversTypes['CustomerDomain']>, ParentType, ContextType, RequireFields<MutationUpsertCustomerDomainArgs, never>>;
  removeCustomerDomain?: Resolver<Maybe<ResolversTypes['CustomerDomainDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerDomainArgs, never>>;
  removeCustomerDomainMany?: Resolver<Maybe<ResolversTypes['CustomerDomainDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerDomainManyArgs, never>>;
  upsertCustomerPostingGroup?: Resolver<Maybe<ResolversTypes['CustomerPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertCustomerPostingGroupArgs, never>>;
  removeCustomerPostingGroup?: Resolver<Maybe<ResolversTypes['CustomerPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerPostingGroupArgs, never>>;
  removeCustomerPostingGroupMany?: Resolver<Maybe<ResolversTypes['CustomerPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerPostingGroupManyArgs, never>>;
  upsertCustomerProject?: Resolver<Maybe<ResolversTypes['CustomerProject']>, ParentType, ContextType, RequireFields<MutationUpsertCustomerProjectArgs, never>>;
  removeCustomerProject?: Resolver<Maybe<ResolversTypes['CustomerProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerProjectArgs, never>>;
  removeCustomerProjectMany?: Resolver<Maybe<ResolversTypes['CustomerProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerProjectManyArgs, never>>;
  upsertCustomerSubProject?: Resolver<Maybe<ResolversTypes['CustomerSubProject']>, ParentType, ContextType, RequireFields<MutationUpsertCustomerSubProjectArgs, never>>;
  removeCustomerSubProject?: Resolver<Maybe<ResolversTypes['CustomerSubProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerSubProjectArgs, never>>;
  removeCustomerSubProjectMany?: Resolver<Maybe<ResolversTypes['CustomerSubProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerSubProjectManyArgs, never>>;
  upsertDeliverytime?: Resolver<Maybe<ResolversTypes['Deliverytime']>, ParentType, ContextType, RequireFields<MutationUpsertDeliverytimeArgs, never>>;
  removeDeliverytime?: Resolver<Maybe<ResolversTypes['DeliverytimeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDeliverytimeArgs, never>>;
  removeDeliverytimeMany?: Resolver<Maybe<ResolversTypes['DeliverytimeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDeliverytimeManyArgs, never>>;
  upsertDevopsProject?: Resolver<Maybe<ResolversTypes['DevopsProject']>, ParentType, ContextType, RequireFields<MutationUpsertDevopsProjectArgs, never>>;
  removeDevopsProject?: Resolver<Maybe<ResolversTypes['DevopsProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDevopsProjectArgs, never>>;
  removeDevopsProjectMany?: Resolver<Maybe<ResolversTypes['DevopsProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDevopsProjectManyArgs, never>>;
  upsertDevopsTeam?: Resolver<Maybe<ResolversTypes['DevopsTeam']>, ParentType, ContextType, RequireFields<MutationUpsertDevopsTeamArgs, never>>;
  removeDevopsTeam?: Resolver<Maybe<ResolversTypes['DevopsTeamDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDevopsTeamArgs, never>>;
  removeDevopsTeamMany?: Resolver<Maybe<ResolversTypes['DevopsTeamDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDevopsTeamManyArgs, never>>;
  upsertDevopsWorkItem?: Resolver<Maybe<ResolversTypes['DevopsWorkItem']>, ParentType, ContextType, RequireFields<MutationUpsertDevopsWorkItemArgs, never>>;
  removeDevopsWorkItem?: Resolver<Maybe<ResolversTypes['DevopsWorkItemDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDevopsWorkItemArgs, never>>;
  removeDevopsWorkItemMany?: Resolver<Maybe<ResolversTypes['DevopsWorkItemDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDevopsWorkItemManyArgs, never>>;
  upsertDimension?: Resolver<Maybe<ResolversTypes['Dimension']>, ParentType, ContextType, RequireFields<MutationUpsertDimensionArgs, never>>;
  removeDimension?: Resolver<Maybe<ResolversTypes['DimensionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDimensionArgs, never>>;
  removeDimensionMany?: Resolver<Maybe<ResolversTypes['DimensionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDimensionManyArgs, never>>;
  upsertDimensionValue?: Resolver<Maybe<ResolversTypes['DimensionValue']>, ParentType, ContextType, RequireFields<MutationUpsertDimensionValueArgs, never>>;
  removeDimensionValue?: Resolver<Maybe<ResolversTypes['DimensionValueDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDimensionValueArgs, never>>;
  removeDimensionValueMany?: Resolver<Maybe<ResolversTypes['DimensionValueDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDimensionValueManyArgs, never>>;
  upsertDiscussionPost?: Resolver<Maybe<ResolversTypes['DiscussionPost']>, ParentType, ContextType, RequireFields<MutationUpsertDiscussionPostArgs, never>>;
  removeDiscussionPost?: Resolver<Maybe<ResolversTypes['DiscussionPostDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDiscussionPostArgs, never>>;
  removeDiscussionPostMany?: Resolver<Maybe<ResolversTypes['DiscussionPostDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDiscussionPostManyArgs, never>>;
  upsertDocumentClassification?: Resolver<Maybe<ResolversTypes['DocumentClassification']>, ParentType, ContextType, RequireFields<MutationUpsertDocumentClassificationArgs, never>>;
  removeDocumentClassification?: Resolver<Maybe<ResolversTypes['DocumentClassificationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDocumentClassificationArgs, never>>;
  removeDocumentClassificationMany?: Resolver<Maybe<ResolversTypes['DocumentClassificationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDocumentClassificationManyArgs, never>>;
  upsertDocumentRequest?: Resolver<Maybe<ResolversTypes['DocumentRequest']>, ParentType, ContextType, RequireFields<MutationUpsertDocumentRequestArgs, never>>;
  removeDocumentRequest?: Resolver<Maybe<ResolversTypes['DocumentRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDocumentRequestArgs, never>>;
  removeDocumentRequestMany?: Resolver<Maybe<ResolversTypes['DocumentRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDocumentRequestManyArgs, never>>;
  upsertDomainSettings?: Resolver<Maybe<ResolversTypes['DomainSettings']>, ParentType, ContextType, RequireFields<MutationUpsertDomainSettingsArgs, never>>;
  removeDomainSettings?: Resolver<Maybe<ResolversTypes['DomainSettingsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDomainSettingsArgs, never>>;
  removeDomainSettingsMany?: Resolver<Maybe<ResolversTypes['DomainSettingsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDomainSettingsManyArgs, never>>;
  upsertEqmBaseCalendar?: Resolver<Maybe<ResolversTypes['EqmBaseCalendar']>, ParentType, ContextType, RequireFields<MutationUpsertEqmBaseCalendarArgs, never>>;
  removeEqmBaseCalendar?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmBaseCalendarArgs, never>>;
  removeEqmBaseCalendarMany?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmBaseCalendarManyArgs, never>>;
  upsertEqmBaseCalendarChange?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarChange']>, ParentType, ContextType, RequireFields<MutationUpsertEqmBaseCalendarChangeArgs, never>>;
  removeEqmBaseCalendarChange?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarChangeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmBaseCalendarChangeArgs, never>>;
  removeEqmBaseCalendarChangeMany?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarChangeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmBaseCalendarChangeManyArgs, never>>;
  upsertEqmCollectionReturnCharges?: Resolver<Maybe<ResolversTypes['EqmCollectionReturnCharges']>, ParentType, ContextType, RequireFields<MutationUpsertEqmCollectionReturnChargesArgs, never>>;
  removeEqmCollectionReturnCharges?: Resolver<Maybe<ResolversTypes['EqmCollectionReturnChargesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmCollectionReturnChargesArgs, never>>;
  removeEqmCollectionReturnChargesMany?: Resolver<Maybe<ResolversTypes['EqmCollectionReturnChargesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmCollectionReturnChargesManyArgs, never>>;
  upsertEqmCommentLine?: Resolver<Maybe<ResolversTypes['EqmCommentLine']>, ParentType, ContextType, RequireFields<MutationUpsertEqmCommentLineArgs, never>>;
  removeEqmCommentLine?: Resolver<Maybe<ResolversTypes['EqmCommentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmCommentLineArgs, never>>;
  removeEqmCommentLineMany?: Resolver<Maybe<ResolversTypes['EqmCommentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmCommentLineManyArgs, never>>;
  upsertEqmCustomizedCalendarChange?: Resolver<Maybe<ResolversTypes['EqmCustomizedCalendarChange']>, ParentType, ContextType, RequireFields<MutationUpsertEqmCustomizedCalendarChangeArgs, never>>;
  removeEqmCustomizedCalendarChange?: Resolver<Maybe<ResolversTypes['EqmCustomizedCalendarChangeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmCustomizedCalendarChangeArgs, never>>;
  removeEqmCustomizedCalendarChangeMany?: Resolver<Maybe<ResolversTypes['EqmCustomizedCalendarChangeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmCustomizedCalendarChangeManyArgs, never>>;
  upsertEqmFncTestReturnCharges?: Resolver<Maybe<ResolversTypes['EqmFncTestReturnCharges']>, ParentType, ContextType, RequireFields<MutationUpsertEqmFncTestReturnChargesArgs, never>>;
  removeEqmFncTestReturnCharges?: Resolver<Maybe<ResolversTypes['EqmFncTestReturnChargesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmFncTestReturnChargesArgs, never>>;
  removeEqmFncTestReturnChargesMany?: Resolver<Maybe<ResolversTypes['EqmFncTestReturnChargesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmFncTestReturnChargesManyArgs, never>>;
  upsertEqmLocCostCenterComb?: Resolver<Maybe<ResolversTypes['EqmLocCostCenterComb']>, ParentType, ContextType, RequireFields<MutationUpsertEqmLocCostCenterCombArgs, never>>;
  removeEqmLocCostCenterComb?: Resolver<Maybe<ResolversTypes['EqmLocCostCenterCombDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmLocCostCenterCombArgs, never>>;
  removeEqmLocCostCenterCombMany?: Resolver<Maybe<ResolversTypes['EqmLocCostCenterCombDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmLocCostCenterCombManyArgs, never>>;
  upsertEqmManufacturer?: Resolver<Maybe<ResolversTypes['EqmManufacturer']>, ParentType, ContextType, RequireFields<MutationUpsertEqmManufacturerArgs, never>>;
  removeEqmManufacturer?: Resolver<Maybe<ResolversTypes['EqmManufacturerDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmManufacturerArgs, never>>;
  removeEqmManufacturerMany?: Resolver<Maybe<ResolversTypes['EqmManufacturerDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmManufacturerManyArgs, never>>;
  upsertEqmManufacturerModel?: Resolver<Maybe<ResolversTypes['EqmManufacturerModel']>, ParentType, ContextType, RequireFields<MutationUpsertEqmManufacturerModelArgs, never>>;
  removeEqmManufacturerModel?: Resolver<Maybe<ResolversTypes['EqmManufacturerModelDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmManufacturerModelArgs, never>>;
  removeEqmManufacturerModelMany?: Resolver<Maybe<ResolversTypes['EqmManufacturerModelDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmManufacturerModelManyArgs, never>>;
  upsertEqmObjectCardFlow?: Resolver<Maybe<ResolversTypes['EqmObjectCardFlow']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectCardFlowArgs, never>>;
  removeEqmObjectCardFlow?: Resolver<Maybe<ResolversTypes['EqmObjectCardFlowDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectCardFlowArgs, never>>;
  removeEqmObjectCardFlowMany?: Resolver<Maybe<ResolversTypes['EqmObjectCardFlowDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectCardFlowManyArgs, never>>;
  upsertEqmObjectComponents?: Resolver<Maybe<ResolversTypes['EqmObjectComponents']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectComponentsArgs, never>>;
  removeEqmObjectComponents?: Resolver<Maybe<ResolversTypes['EqmObjectComponentsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectComponentsArgs, never>>;
  removeEqmObjectComponentsMany?: Resolver<Maybe<ResolversTypes['EqmObjectComponentsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectComponentsManyArgs, never>>;
  upsertEqmObjectCountHeader?: Resolver<Maybe<ResolversTypes['EqmObjectCountHeader']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectCountHeaderArgs, never>>;
  removeEqmObjectCountHeader?: Resolver<Maybe<ResolversTypes['EqmObjectCountHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectCountHeaderArgs, never>>;
  removeEqmObjectCountHeaderMany?: Resolver<Maybe<ResolversTypes['EqmObjectCountHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectCountHeaderManyArgs, never>>;
  upsertEqmObjectCountLine?: Resolver<Maybe<ResolversTypes['EqmObjectCountLine']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectCountLineArgs, never>>;
  removeEqmObjectCountLine?: Resolver<Maybe<ResolversTypes['EqmObjectCountLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectCountLineArgs, never>>;
  removeEqmObjectCountLineMany?: Resolver<Maybe<ResolversTypes['EqmObjectCountLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectCountLineManyArgs, never>>;
  upsertEqmObjectGroupCard?: Resolver<Maybe<ResolversTypes['EqmObjectGroupCard']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectGroupCardArgs, never>>;
  removeEqmObjectGroupCard?: Resolver<Maybe<ResolversTypes['EqmObjectGroupCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectGroupCardArgs, never>>;
  removeEqmObjectGroupCardMany?: Resolver<Maybe<ResolversTypes['EqmObjectGroupCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectGroupCardManyArgs, never>>;
  upsertEqmObjectGroupFlow?: Resolver<Maybe<ResolversTypes['EqmObjectGroupFlow']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectGroupFlowArgs, never>>;
  removeEqmObjectGroupFlow?: Resolver<Maybe<ResolversTypes['EqmObjectGroupFlowDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectGroupFlowArgs, never>>;
  removeEqmObjectGroupFlowMany?: Resolver<Maybe<ResolversTypes['EqmObjectGroupFlowDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectGroupFlowManyArgs, never>>;
  upsertEqmObjectServiceInterval?: Resolver<Maybe<ResolversTypes['EqmObjectServiceInterval']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectServiceIntervalArgs, never>>;
  removeEqmObjectServiceInterval?: Resolver<Maybe<ResolversTypes['EqmObjectServiceIntervalDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectServiceIntervalArgs, never>>;
  removeEqmObjectServiceIntervalMany?: Resolver<Maybe<ResolversTypes['EqmObjectServiceIntervalDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectServiceIntervalManyArgs, never>>;
  upsertEqmObjectSrvIntervalAgg?: Resolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAgg']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectSrvIntervalAggArgs, never>>;
  removeEqmObjectSrvIntervalAgg?: Resolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAggDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectSrvIntervalAggArgs, never>>;
  removeEqmObjectSrvIntervalAggMany?: Resolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAggDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectSrvIntervalAggManyArgs, never>>;
  upsertEqmObjectStatus?: Resolver<Maybe<ResolversTypes['EqmObjectStatus']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectStatusArgs, never>>;
  removeEqmObjectStatus?: Resolver<Maybe<ResolversTypes['EqmObjectStatusDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectStatusArgs, never>>;
  removeEqmObjectStatusMany?: Resolver<Maybe<ResolversTypes['EqmObjectStatusDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectStatusManyArgs, never>>;
  upsertEqmObjectTypeCard?: Resolver<Maybe<ResolversTypes['EqmObjectTypeCard']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectTypeCardArgs, never>>;
  removeEqmObjectTypeCard?: Resolver<Maybe<ResolversTypes['EqmObjectTypeCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeCardArgs, never>>;
  removeEqmObjectTypeCardMany?: Resolver<Maybe<ResolversTypes['EqmObjectTypeCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeCardManyArgs, never>>;
  upsertEqmObjectTypeComponents?: Resolver<Maybe<ResolversTypes['EqmObjectTypeComponents']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectTypeComponentsArgs, never>>;
  removeEqmObjectTypeComponents?: Resolver<Maybe<ResolversTypes['EqmObjectTypeComponentsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeComponentsArgs, never>>;
  removeEqmObjectTypeComponentsMany?: Resolver<Maybe<ResolversTypes['EqmObjectTypeComponentsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeComponentsManyArgs, never>>;
  upsertEqmObjectTypeFlow?: Resolver<Maybe<ResolversTypes['EqmObjectTypeFlow']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectTypeFlowArgs, never>>;
  removeEqmObjectTypeFlow?: Resolver<Maybe<ResolversTypes['EqmObjectTypeFlowDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeFlowArgs, never>>;
  removeEqmObjectTypeFlowMany?: Resolver<Maybe<ResolversTypes['EqmObjectTypeFlowDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeFlowManyArgs, never>>;
  upsertEqmObjectTypePriceTerm?: Resolver<Maybe<ResolversTypes['EqmObjectTypePriceTerm']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectTypePriceTermArgs, never>>;
  removeEqmObjectTypePriceTerm?: Resolver<Maybe<ResolversTypes['EqmObjectTypePriceTermDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypePriceTermArgs, never>>;
  removeEqmObjectTypePriceTermMany?: Resolver<Maybe<ResolversTypes['EqmObjectTypePriceTermDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypePriceTermManyArgs, never>>;
  upsertEqmObjectTypeServiceInterval?: Resolver<Maybe<ResolversTypes['EqmObjectTypeServiceInterval']>, ParentType, ContextType, RequireFields<MutationUpsertEqmObjectTypeServiceIntervalArgs, never>>;
  removeEqmObjectTypeServiceInterval?: Resolver<Maybe<ResolversTypes['EqmObjectTypeServiceIntervalDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeServiceIntervalArgs, never>>;
  removeEqmObjectTypeServiceIntervalMany?: Resolver<Maybe<ResolversTypes['EqmObjectTypeServiceIntervalDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmObjectTypeServiceIntervalManyArgs, never>>;
  upsertEqmRentalGroup?: Resolver<Maybe<ResolversTypes['EqmRentalGroup']>, ParentType, ContextType, RequireFields<MutationUpsertEqmRentalGroupArgs, never>>;
  removeEqmRentalGroup?: Resolver<Maybe<ResolversTypes['EqmRentalGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalGroupArgs, never>>;
  removeEqmRentalGroupMany?: Resolver<Maybe<ResolversTypes['EqmRentalGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalGroupManyArgs, never>>;
  upsertEqmRentalKIT?: Resolver<Maybe<ResolversTypes['EqmRentalKIT']>, ParentType, ContextType, RequireFields<MutationUpsertEqmRentalKitArgs, never>>;
  removeEqmRentalKIT?: Resolver<Maybe<ResolversTypes['EqmRentalKITDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalKitArgs, never>>;
  removeEqmRentalKITMany?: Resolver<Maybe<ResolversTypes['EqmRentalKITDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalKitManyArgs, never>>;
  upsertEqmRentalKITComponents?: Resolver<Maybe<ResolversTypes['EqmRentalKITComponents']>, ParentType, ContextType, RequireFields<MutationUpsertEqmRentalKitComponentsArgs, never>>;
  removeEqmRentalKITComponents?: Resolver<Maybe<ResolversTypes['EqmRentalKITComponentsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalKitComponentsArgs, never>>;
  removeEqmRentalKITComponentsMany?: Resolver<Maybe<ResolversTypes['EqmRentalKITComponentsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalKitComponentsManyArgs, never>>;
  upsertEqmRentalKITLines?: Resolver<Maybe<ResolversTypes['EqmRentalKITLines']>, ParentType, ContextType, RequireFields<MutationUpsertEqmRentalKitLinesArgs, never>>;
  removeEqmRentalKITLines?: Resolver<Maybe<ResolversTypes['EqmRentalKITLinesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalKitLinesArgs, never>>;
  removeEqmRentalKITLinesMany?: Resolver<Maybe<ResolversTypes['EqmRentalKITLinesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalKitLinesManyArgs, never>>;
  upsertEqmRentalLineDiscount?: Resolver<Maybe<ResolversTypes['EqmRentalLineDiscount']>, ParentType, ContextType, RequireFields<MutationUpsertEqmRentalLineDiscountArgs, never>>;
  removeEqmRentalLineDiscount?: Resolver<Maybe<ResolversTypes['EqmRentalLineDiscountDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalLineDiscountArgs, never>>;
  removeEqmRentalLineDiscountMany?: Resolver<Maybe<ResolversTypes['EqmRentalLineDiscountDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalLineDiscountManyArgs, never>>;
  upsertEqmRentalPrice?: Resolver<Maybe<ResolversTypes['EqmRentalPrice']>, ParentType, ContextType, RequireFields<MutationUpsertEqmRentalPriceArgs, never>>;
  removeEqmRentalPrice?: Resolver<Maybe<ResolversTypes['EqmRentalPriceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalPriceArgs, never>>;
  removeEqmRentalPriceMany?: Resolver<Maybe<ResolversTypes['EqmRentalPriceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmRentalPriceManyArgs, never>>;
  upsertEqmReRentCard?: Resolver<Maybe<ResolversTypes['EqmReRentCard']>, ParentType, ContextType, RequireFields<MutationUpsertEqmReRentCardArgs, never>>;
  removeEqmReRentCard?: Resolver<Maybe<ResolversTypes['EqmReRentCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmReRentCardArgs, never>>;
  removeEqmReRentCardMany?: Resolver<Maybe<ResolversTypes['EqmReRentCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmReRentCardManyArgs, never>>;
  upsertEqmReturnCharges?: Resolver<Maybe<ResolversTypes['EqmReturnCharges']>, ParentType, ContextType, RequireFields<MutationUpsertEqmReturnChargesArgs, never>>;
  removeEqmReturnCharges?: Resolver<Maybe<ResolversTypes['EqmReturnChargesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmReturnChargesArgs, never>>;
  removeEqmReturnChargesMany?: Resolver<Maybe<ResolversTypes['EqmReturnChargesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmReturnChargesManyArgs, never>>;
  upsertEqmServiceCard?: Resolver<Maybe<ResolversTypes['EqmServiceCard']>, ParentType, ContextType, RequireFields<MutationUpsertEqmServiceCardArgs, never>>;
  removeEqmServiceCard?: Resolver<Maybe<ResolversTypes['EqmServiceCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmServiceCardArgs, never>>;
  removeEqmServiceCardMany?: Resolver<Maybe<ResolversTypes['EqmServiceCardDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmServiceCardManyArgs, never>>;
  upsertEqmServiceType?: Resolver<Maybe<ResolversTypes['EqmServiceType']>, ParentType, ContextType, RequireFields<MutationUpsertEqmServiceTypeArgs, never>>;
  removeEqmServiceType?: Resolver<Maybe<ResolversTypes['EqmServiceTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmServiceTypeArgs, never>>;
  removeEqmServiceTypeMany?: Resolver<Maybe<ResolversTypes['EqmServiceTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmServiceTypeManyArgs, never>>;
  upsertEqmTypeFunctionalTest?: Resolver<Maybe<ResolversTypes['EqmTypeFunctionalTest']>, ParentType, ContextType, RequireFields<MutationUpsertEqmTypeFunctionalTestArgs, never>>;
  removeEqmTypeFunctionalTest?: Resolver<Maybe<ResolversTypes['EqmTypeFunctionalTestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmTypeFunctionalTestArgs, never>>;
  removeEqmTypeFunctionalTestMany?: Resolver<Maybe<ResolversTypes['EqmTypeFunctionalTestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmTypeFunctionalTestManyArgs, never>>;
  upsertEqmWorkHeader?: Resolver<Maybe<ResolversTypes['EqmWorkHeader']>, ParentType, ContextType, RequireFields<MutationUpsertEqmWorkHeaderArgs, never>>;
  removeEqmWorkHeader?: Resolver<Maybe<ResolversTypes['EqmWorkHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmWorkHeaderArgs, never>>;
  removeEqmWorkHeaderMany?: Resolver<Maybe<ResolversTypes['EqmWorkHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmWorkHeaderManyArgs, never>>;
  upsertEqmWorkLine?: Resolver<Maybe<ResolversTypes['EqmWorkLine']>, ParentType, ContextType, RequireFields<MutationUpsertEqmWorkLineArgs, never>>;
  removeEqmWorkLine?: Resolver<Maybe<ResolversTypes['EqmWorkLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmWorkLineArgs, never>>;
  removeEqmWorkLineMany?: Resolver<Maybe<ResolversTypes['EqmWorkLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmWorkLineManyArgs, never>>;
  upsertEqmTransferHeader?: Resolver<Maybe<ResolversTypes['EqmTransferHeader']>, ParentType, ContextType, RequireFields<MutationUpsertEqmTransferHeaderArgs, never>>;
  removeEqmTransferHeader?: Resolver<Maybe<ResolversTypes['EqmTransferHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmTransferHeaderArgs, never>>;
  removeEqmTransferHeaderMany?: Resolver<Maybe<ResolversTypes['EqmTransferHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmTransferHeaderManyArgs, never>>;
  upsertEqmTransferLine?: Resolver<Maybe<ResolversTypes['EqmTransferLine']>, ParentType, ContextType, RequireFields<MutationUpsertEqmTransferLineArgs, never>>;
  removeEqmTransferLine?: Resolver<Maybe<ResolversTypes['EqmTransferLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmTransferLineArgs, never>>;
  removeEqmTransferLineMany?: Resolver<Maybe<ResolversTypes['EqmTransferLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveEqmTransferLineManyArgs, never>>;
  upsertErrand?: Resolver<Maybe<ResolversTypes['Errand']>, ParentType, ContextType, RequireFields<MutationUpsertErrandArgs, never>>;
  removeErrand?: Resolver<Maybe<ResolversTypes['ErrandDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveErrandArgs, never>>;
  removeErrandMany?: Resolver<Maybe<ResolversTypes['ErrandDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveErrandManyArgs, never>>;
  upsertErrandLine?: Resolver<Maybe<ResolversTypes['ErrandLine']>, ParentType, ContextType, RequireFields<MutationUpsertErrandLineArgs, never>>;
  removeErrandLine?: Resolver<Maybe<ResolversTypes['ErrandLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveErrandLineArgs, never>>;
  removeErrandLineMany?: Resolver<Maybe<ResolversTypes['ErrandLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveErrandLineManyArgs, never>>;
  upsertErrorReport?: Resolver<Maybe<ResolversTypes['ErrorReport']>, ParentType, ContextType, RequireFields<MutationUpsertErrorReportArgs, never>>;
  removeErrorReport?: Resolver<Maybe<ResolversTypes['ErrorReportDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveErrorReportArgs, never>>;
  removeErrorReportMany?: Resolver<Maybe<ResolversTypes['ErrorReportDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveErrorReportManyArgs, never>>;
  upsertFeedback?: Resolver<Maybe<ResolversTypes['Feedback']>, ParentType, ContextType, RequireFields<MutationUpsertFeedbackArgs, never>>;
  removeFeedback?: Resolver<Maybe<ResolversTypes['FeedbackDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFeedbackArgs, never>>;
  removeFeedbackMany?: Resolver<Maybe<ResolversTypes['FeedbackDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFeedbackManyArgs, never>>;
  upsertFeemapping?: Resolver<Maybe<ResolversTypes['Feemapping']>, ParentType, ContextType, RequireFields<MutationUpsertFeemappingArgs, never>>;
  removeFeemapping?: Resolver<Maybe<ResolversTypes['FeemappingDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFeemappingArgs, never>>;
  removeFeemappingMany?: Resolver<Maybe<ResolversTypes['FeemappingDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFeemappingManyArgs, never>>;
  upsertFieldOrder?: Resolver<Maybe<ResolversTypes['FieldOrder']>, ParentType, ContextType, RequireFields<MutationUpsertFieldOrderArgs, never>>;
  removeFieldOrder?: Resolver<Maybe<ResolversTypes['FieldOrderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFieldOrderArgs, never>>;
  removeFieldOrderMany?: Resolver<Maybe<ResolversTypes['FieldOrderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFieldOrderManyArgs, never>>;
  upsertFieldOrderLine?: Resolver<Maybe<ResolversTypes['FieldOrderLine']>, ParentType, ContextType, RequireFields<MutationUpsertFieldOrderLineArgs, never>>;
  removeFieldOrderLine?: Resolver<Maybe<ResolversTypes['FieldOrderLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFieldOrderLineArgs, never>>;
  removeFieldOrderLineMany?: Resolver<Maybe<ResolversTypes['FieldOrderLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFieldOrderLineManyArgs, never>>;
  upsertFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType, RequireFields<MutationUpsertFileArgs, never>>;
  removeFile?: Resolver<Maybe<ResolversTypes['FileDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFileArgs, never>>;
  removeFileMany?: Resolver<Maybe<ResolversTypes['FileDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFileManyArgs, never>>;
  upsertFunctionTestHeader?: Resolver<Maybe<ResolversTypes['FunctionTestHeader']>, ParentType, ContextType, RequireFields<MutationUpsertFunctionTestHeaderArgs, never>>;
  removeFunctionTestHeader?: Resolver<Maybe<ResolversTypes['FunctionTestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFunctionTestHeaderArgs, never>>;
  removeFunctionTestHeaderMany?: Resolver<Maybe<ResolversTypes['FunctionTestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFunctionTestHeaderManyArgs, never>>;
  upsertFunctionTestLine?: Resolver<Maybe<ResolversTypes['FunctionTestLine']>, ParentType, ContextType, RequireFields<MutationUpsertFunctionTestLineArgs, never>>;
  removeFunctionTestLine?: Resolver<Maybe<ResolversTypes['FunctionTestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFunctionTestLineArgs, never>>;
  removeFunctionTestLineMany?: Resolver<Maybe<ResolversTypes['FunctionTestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFunctionTestLineManyArgs, never>>;
  upsertGenBusinessPostingGroup?: Resolver<Maybe<ResolversTypes['GenBusinessPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertGenBusinessPostingGroupArgs, never>>;
  removeGenBusinessPostingGroup?: Resolver<Maybe<ResolversTypes['GenBusinessPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveGenBusinessPostingGroupArgs, never>>;
  removeGenBusinessPostingGroupMany?: Resolver<Maybe<ResolversTypes['GenBusinessPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveGenBusinessPostingGroupManyArgs, never>>;
  upsertGenJournalLine?: Resolver<Maybe<ResolversTypes['GenJournalLine']>, ParentType, ContextType, RequireFields<MutationUpsertGenJournalLineArgs, never>>;
  removeGenJournalLine?: Resolver<Maybe<ResolversTypes['GenJournalLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveGenJournalLineArgs, never>>;
  removeGenJournalLineMany?: Resolver<Maybe<ResolversTypes['GenJournalLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveGenJournalLineManyArgs, never>>;
  upsertGenProductPostingGroup?: Resolver<Maybe<ResolversTypes['GenProductPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertGenProductPostingGroupArgs, never>>;
  removeGenProductPostingGroup?: Resolver<Maybe<ResolversTypes['GenProductPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveGenProductPostingGroupArgs, never>>;
  removeGenProductPostingGroupMany?: Resolver<Maybe<ResolversTypes['GenProductPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveGenProductPostingGroupManyArgs, never>>;
  upsertInspection?: Resolver<Maybe<ResolversTypes['Inspection']>, ParentType, ContextType, RequireFields<MutationUpsertInspectionArgs, never>>;
  removeInspection?: Resolver<Maybe<ResolversTypes['InspectionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInspectionArgs, never>>;
  removeInspectionMany?: Resolver<Maybe<ResolversTypes['InspectionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInspectionManyArgs, never>>;
  upsertInspectionUser?: Resolver<Maybe<ResolversTypes['InspectionUser']>, ParentType, ContextType, RequireFields<MutationUpsertInspectionUserArgs, never>>;
  removeInspectionUser?: Resolver<Maybe<ResolversTypes['InspectionUserDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInspectionUserArgs, never>>;
  removeInspectionUserMany?: Resolver<Maybe<ResolversTypes['InspectionUserDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInspectionUserManyArgs, never>>;
  upsertIntegrationCollection?: Resolver<Maybe<ResolversTypes['IntegrationCollection']>, ParentType, ContextType, RequireFields<MutationUpsertIntegrationCollectionArgs, never>>;
  removeIntegrationCollection?: Resolver<Maybe<ResolversTypes['IntegrationCollectionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveIntegrationCollectionArgs, never>>;
  removeIntegrationCollectionMany?: Resolver<Maybe<ResolversTypes['IntegrationCollectionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveIntegrationCollectionManyArgs, never>>;
  upsertInventoryPostingGroup?: Resolver<Maybe<ResolversTypes['InventoryPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertInventoryPostingGroupArgs, never>>;
  removeInventoryPostingGroup?: Resolver<Maybe<ResolversTypes['InventoryPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInventoryPostingGroupArgs, never>>;
  removeInventoryPostingGroupMany?: Resolver<Maybe<ResolversTypes['InventoryPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInventoryPostingGroupManyArgs, never>>;
  upsertInvoiceHeader?: Resolver<Maybe<ResolversTypes['InvoiceHeader']>, ParentType, ContextType, RequireFields<MutationUpsertInvoiceHeaderArgs, never>>;
  removeInvoiceHeader?: Resolver<Maybe<ResolversTypes['InvoiceHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInvoiceHeaderArgs, never>>;
  removeInvoiceHeaderMany?: Resolver<Maybe<ResolversTypes['InvoiceHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInvoiceHeaderManyArgs, never>>;
  upsertInvoiceLine?: Resolver<Maybe<ResolversTypes['InvoiceLine']>, ParentType, ContextType, RequireFields<MutationUpsertInvoiceLineArgs, never>>;
  removeInvoiceLine?: Resolver<Maybe<ResolversTypes['InvoiceLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInvoiceLineArgs, never>>;
  removeInvoiceLineMany?: Resolver<Maybe<ResolversTypes['InvoiceLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInvoiceLineManyArgs, never>>;
  upsertItem?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType, RequireFields<MutationUpsertItemArgs, never>>;
  removeItem?: Resolver<Maybe<ResolversTypes['ItemDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemArgs, never>>;
  removeItemMany?: Resolver<Maybe<ResolversTypes['ItemDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemManyArgs, never>>;
  upsertItemCategory?: Resolver<Maybe<ResolversTypes['ItemCategory']>, ParentType, ContextType, RequireFields<MutationUpsertItemCategoryArgs, never>>;
  removeItemCategory?: Resolver<Maybe<ResolversTypes['ItemCategoryDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemCategoryArgs, never>>;
  removeItemCategoryMany?: Resolver<Maybe<ResolversTypes['ItemCategoryDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemCategoryManyArgs, never>>;
  upsertItemDiscGroup?: Resolver<Maybe<ResolversTypes['ItemDiscGroup']>, ParentType, ContextType, RequireFields<MutationUpsertItemDiscGroupArgs, never>>;
  removeItemDiscGroup?: Resolver<Maybe<ResolversTypes['ItemDiscGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemDiscGroupArgs, never>>;
  removeItemDiscGroupMany?: Resolver<Maybe<ResolversTypes['ItemDiscGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemDiscGroupManyArgs, never>>;
  upsertItemReference?: Resolver<Maybe<ResolversTypes['ItemReference']>, ParentType, ContextType, RequireFields<MutationUpsertItemReferenceArgs, never>>;
  removeItemReference?: Resolver<Maybe<ResolversTypes['ItemReferenceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemReferenceArgs, never>>;
  removeItemReferenceMany?: Resolver<Maybe<ResolversTypes['ItemReferenceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemReferenceManyArgs, never>>;
  upsertItemRestriction?: Resolver<Maybe<ResolversTypes['ItemRestriction']>, ParentType, ContextType, RequireFields<MutationUpsertItemRestrictionArgs, never>>;
  removeItemRestriction?: Resolver<Maybe<ResolversTypes['ItemRestrictionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemRestrictionArgs, never>>;
  removeItemRestrictionMany?: Resolver<Maybe<ResolversTypes['ItemRestrictionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemRestrictionManyArgs, never>>;
  upsertItemUnitOfMeasure?: Resolver<Maybe<ResolversTypes['ItemUnitOfMeasure']>, ParentType, ContextType, RequireFields<MutationUpsertItemUnitOfMeasureArgs, never>>;
  removeItemUnitOfMeasure?: Resolver<Maybe<ResolversTypes['ItemUnitOfMeasureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemUnitOfMeasureArgs, never>>;
  removeItemUnitOfMeasureMany?: Resolver<Maybe<ResolversTypes['ItemUnitOfMeasureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemUnitOfMeasureManyArgs, never>>;
  upsertItemVendor?: Resolver<Maybe<ResolversTypes['ItemVendor']>, ParentType, ContextType, RequireFields<MutationUpsertItemVendorArgs, never>>;
  removeItemVendor?: Resolver<Maybe<ResolversTypes['ItemVendorDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemVendorArgs, never>>;
  removeItemVendorMany?: Resolver<Maybe<ResolversTypes['ItemVendorDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveItemVendorManyArgs, never>>;
  upsertJobTask?: Resolver<Maybe<ResolversTypes['JobTask']>, ParentType, ContextType, RequireFields<MutationUpsertJobTaskArgs, never>>;
  removeJobTask?: Resolver<Maybe<ResolversTypes['JobTaskDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveJobTaskArgs, never>>;
  removeJobTaskMany?: Resolver<Maybe<ResolversTypes['JobTaskDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveJobTaskManyArgs, never>>;
  upsertKliHeader?: Resolver<Maybe<ResolversTypes['KliHeader']>, ParentType, ContextType, RequireFields<MutationUpsertKliHeaderArgs, never>>;
  removeKliHeader?: Resolver<Maybe<ResolversTypes['KliHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveKliHeaderArgs, never>>;
  removeKliHeaderMany?: Resolver<Maybe<ResolversTypes['KliHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveKliHeaderManyArgs, never>>;
  upsertKliReasonCode?: Resolver<Maybe<ResolversTypes['KliReasonCode']>, ParentType, ContextType, RequireFields<MutationUpsertKliReasonCodeArgs, never>>;
  removeKliReasonCode?: Resolver<Maybe<ResolversTypes['KliReasonCodeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveKliReasonCodeArgs, never>>;
  removeKliReasonCodeMany?: Resolver<Maybe<ResolversTypes['KliReasonCodeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveKliReasonCodeManyArgs, never>>;
  upsertLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<MutationUpsertLocationArgs, never>>;
  removeLocation?: Resolver<Maybe<ResolversTypes['LocationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveLocationArgs, never>>;
  removeLocationMany?: Resolver<Maybe<ResolversTypes['LocationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveLocationManyArgs, never>>;
  upsertLead?: Resolver<Maybe<ResolversTypes['Lead']>, ParentType, ContextType, RequireFields<MutationUpsertLeadArgs, never>>;
  removeLead?: Resolver<Maybe<ResolversTypes['LeadDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveLeadArgs, never>>;
  removeLeadMany?: Resolver<Maybe<ResolversTypes['LeadDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveLeadManyArgs, never>>;
  upsertMagentoOrderStatus?: Resolver<Maybe<ResolversTypes['MagentoOrderStatus']>, ParentType, ContextType, RequireFields<MutationUpsertMagentoOrderStatusArgs, never>>;
  removeMagentoOrderStatus?: Resolver<Maybe<ResolversTypes['MagentoOrderStatusDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveMagentoOrderStatusArgs, never>>;
  removeMagentoOrderStatusMany?: Resolver<Maybe<ResolversTypes['MagentoOrderStatusDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveMagentoOrderStatusManyArgs, never>>;
  upsertMagentoSettings?: Resolver<Maybe<ResolversTypes['MagentoSettings']>, ParentType, ContextType, RequireFields<MutationUpsertMagentoSettingsArgs, never>>;
  removeMagentoSettings?: Resolver<Maybe<ResolversTypes['MagentoSettingsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveMagentoSettingsArgs, never>>;
  removeMagentoSettingsMany?: Resolver<Maybe<ResolversTypes['MagentoSettingsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveMagentoSettingsManyArgs, never>>;
  upsertManufacturer?: Resolver<Maybe<ResolversTypes['Manufacturer']>, ParentType, ContextType, RequireFields<MutationUpsertManufacturerArgs, never>>;
  removeManufacturer?: Resolver<Maybe<ResolversTypes['ManufacturerDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveManufacturerArgs, never>>;
  removeManufacturerMany?: Resolver<Maybe<ResolversTypes['ManufacturerDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveManufacturerManyArgs, never>>;
  upsertNavUser?: Resolver<Maybe<ResolversTypes['NavUser']>, ParentType, ContextType, RequireFields<MutationUpsertNavUserArgs, never>>;
  removeNavUser?: Resolver<Maybe<ResolversTypes['NavUserDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveNavUserArgs, never>>;
  removeNavUserMany?: Resolver<Maybe<ResolversTypes['NavUserDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveNavUserManyArgs, never>>;
  upsertObject?: Resolver<Maybe<ResolversTypes['Object']>, ParentType, ContextType, RequireFields<MutationUpsertObjectArgs, never>>;
  removeObject?: Resolver<Maybe<ResolversTypes['ObjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectArgs, never>>;
  removeObjectMany?: Resolver<Maybe<ResolversTypes['ObjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectManyArgs, never>>;
  upsertObjectCommentLine?: Resolver<Maybe<ResolversTypes['ObjectCommentLine']>, ParentType, ContextType, RequireFields<MutationUpsertObjectCommentLineArgs, never>>;
  removeObjectCommentLine?: Resolver<Maybe<ResolversTypes['ObjectCommentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectCommentLineArgs, never>>;
  removeObjectCommentLineMany?: Resolver<Maybe<ResolversTypes['ObjectCommentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectCommentLineManyArgs, never>>;
  upsertObjectFamily?: Resolver<Maybe<ResolversTypes['ObjectFamily']>, ParentType, ContextType, RequireFields<MutationUpsertObjectFamilyArgs, never>>;
  removeObjectFamily?: Resolver<Maybe<ResolversTypes['ObjectFamilyDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectFamilyArgs, never>>;
  removeObjectFamilyMany?: Resolver<Maybe<ResolversTypes['ObjectFamilyDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectFamilyManyArgs, never>>;
  upsertObjectGroup?: Resolver<Maybe<ResolversTypes['ObjectGroup']>, ParentType, ContextType, RequireFields<MutationUpsertObjectGroupArgs, never>>;
  removeObjectGroup?: Resolver<Maybe<ResolversTypes['ObjectGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectGroupArgs, never>>;
  removeObjectGroupMany?: Resolver<Maybe<ResolversTypes['ObjectGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectGroupManyArgs, never>>;
  upsertObjectReturnHeader?: Resolver<Maybe<ResolversTypes['ObjectReturnHeader']>, ParentType, ContextType, RequireFields<MutationUpsertObjectReturnHeaderArgs, never>>;
  removeObjectReturnHeader?: Resolver<Maybe<ResolversTypes['ObjectReturnHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectReturnHeaderArgs, never>>;
  removeObjectReturnHeaderMany?: Resolver<Maybe<ResolversTypes['ObjectReturnHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectReturnHeaderManyArgs, never>>;
  upsertObjectReturnLine?: Resolver<Maybe<ResolversTypes['ObjectReturnLine']>, ParentType, ContextType, RequireFields<MutationUpsertObjectReturnLineArgs, never>>;
  removeObjectReturnLine?: Resolver<Maybe<ResolversTypes['ObjectReturnLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectReturnLineArgs, never>>;
  removeObjectReturnLineMany?: Resolver<Maybe<ResolversTypes['ObjectReturnLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectReturnLineManyArgs, never>>;
  upsertObjectType?: Resolver<Maybe<ResolversTypes['ObjectType']>, ParentType, ContextType, RequireFields<MutationUpsertObjectTypeArgs, never>>;
  removeObjectType?: Resolver<Maybe<ResolversTypes['ObjectTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectTypeArgs, never>>;
  removeObjectTypeMany?: Resolver<Maybe<ResolversTypes['ObjectTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveObjectTypeManyArgs, never>>;
  upsertOcrAnalysis?: Resolver<Maybe<ResolversTypes['OcrAnalysis']>, ParentType, ContextType, RequireFields<MutationUpsertOcrAnalysisArgs, never>>;
  removeOcrAnalysis?: Resolver<Maybe<ResolversTypes['OcrAnalysisDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveOcrAnalysisArgs, never>>;
  removeOcrAnalysisMany?: Resolver<Maybe<ResolversTypes['OcrAnalysisDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveOcrAnalysisManyArgs, never>>;
  upsertPaymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<MutationUpsertPaymentMethodArgs, never>>;
  removePaymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethodDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePaymentMethodArgs, never>>;
  removePaymentMethodMany?: Resolver<Maybe<ResolversTypes['PaymentMethodDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePaymentMethodManyArgs, never>>;
  upsertPaymentTerms?: Resolver<Maybe<ResolversTypes['PaymentTerms']>, ParentType, ContextType, RequireFields<MutationUpsertPaymentTermsArgs, never>>;
  removePaymentTerms?: Resolver<Maybe<ResolversTypes['PaymentTermsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePaymentTermsArgs, never>>;
  removePaymentTermsMany?: Resolver<Maybe<ResolversTypes['PaymentTermsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePaymentTermsManyArgs, never>>;
  upsertPlatformFeature?: Resolver<Maybe<ResolversTypes['PlatformFeature']>, ParentType, ContextType, RequireFields<MutationUpsertPlatformFeatureArgs, never>>;
  removePlatformFeature?: Resolver<Maybe<ResolversTypes['PlatformFeatureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePlatformFeatureArgs, never>>;
  removePlatformFeatureMany?: Resolver<Maybe<ResolversTypes['PlatformFeatureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePlatformFeatureManyArgs, never>>;
  upsertPostCode?: Resolver<Maybe<ResolversTypes['PostCode']>, ParentType, ContextType, RequireFields<MutationUpsertPostCodeArgs, never>>;
  removePostCode?: Resolver<Maybe<ResolversTypes['PostCodeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePostCodeArgs, never>>;
  removePostCodeMany?: Resolver<Maybe<ResolversTypes['PostCodeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePostCodeManyArgs, never>>;
  upsertProject?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<MutationUpsertProjectArgs, never>>;
  removeProject?: Resolver<Maybe<ResolversTypes['ProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveProjectArgs, never>>;
  removeProjectMany?: Resolver<Maybe<ResolversTypes['ProjectDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveProjectManyArgs, never>>;
  upsertPurchaseHeader?: Resolver<Maybe<ResolversTypes['PurchaseHeader']>, ParentType, ContextType, RequireFields<MutationUpsertPurchaseHeaderArgs, never>>;
  removePurchaseHeader?: Resolver<Maybe<ResolversTypes['PurchaseHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchaseHeaderArgs, never>>;
  removePurchaseHeaderMany?: Resolver<Maybe<ResolversTypes['PurchaseHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchaseHeaderManyArgs, never>>;
  upsertPurchaseLine?: Resolver<Maybe<ResolversTypes['PurchaseLine']>, ParentType, ContextType, RequireFields<MutationUpsertPurchaseLineArgs, never>>;
  removePurchaseLine?: Resolver<Maybe<ResolversTypes['PurchaseLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchaseLineArgs, never>>;
  removePurchaseLineMany?: Resolver<Maybe<ResolversTypes['PurchaseLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchaseLineManyArgs, never>>;
  upsertPurchasing?: Resolver<Maybe<ResolversTypes['Purchasing']>, ParentType, ContextType, RequireFields<MutationUpsertPurchasingArgs, never>>;
  removePurchasing?: Resolver<Maybe<ResolversTypes['PurchasingDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchasingArgs, never>>;
  removePurchasingMany?: Resolver<Maybe<ResolversTypes['PurchasingDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchasingManyArgs, never>>;
  upsertPurchCrMemoHeader?: Resolver<Maybe<ResolversTypes['PurchCrMemoHeader']>, ParentType, ContextType, RequireFields<MutationUpsertPurchCrMemoHeaderArgs, never>>;
  removePurchCrMemoHeader?: Resolver<Maybe<ResolversTypes['PurchCrMemoHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchCrMemoHeaderArgs, never>>;
  removePurchCrMemoHeaderMany?: Resolver<Maybe<ResolversTypes['PurchCrMemoHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchCrMemoHeaderManyArgs, never>>;
  upsertPurchCrMemoLine?: Resolver<Maybe<ResolversTypes['PurchCrMemoLine']>, ParentType, ContextType, RequireFields<MutationUpsertPurchCrMemoLineArgs, never>>;
  removePurchCrMemoLine?: Resolver<Maybe<ResolversTypes['PurchCrMemoLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchCrMemoLineArgs, never>>;
  removePurchCrMemoLineMany?: Resolver<Maybe<ResolversTypes['PurchCrMemoLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchCrMemoLineManyArgs, never>>;
  upsertPurchInvHeader?: Resolver<Maybe<ResolversTypes['PurchInvHeader']>, ParentType, ContextType, RequireFields<MutationUpsertPurchInvHeaderArgs, never>>;
  removePurchInvHeader?: Resolver<Maybe<ResolversTypes['PurchInvHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchInvHeaderArgs, never>>;
  removePurchInvHeaderMany?: Resolver<Maybe<ResolversTypes['PurchInvHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchInvHeaderManyArgs, never>>;
  upsertPurchInvLine?: Resolver<Maybe<ResolversTypes['PurchInvLine']>, ParentType, ContextType, RequireFields<MutationUpsertPurchInvLineArgs, never>>;
  removePurchInvLine?: Resolver<Maybe<ResolversTypes['PurchInvLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchInvLineArgs, never>>;
  removePurchInvLineMany?: Resolver<Maybe<ResolversTypes['PurchInvLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchInvLineManyArgs, never>>;
  upsertPurchRcptHeader?: Resolver<Maybe<ResolversTypes['PurchRcptHeader']>, ParentType, ContextType, RequireFields<MutationUpsertPurchRcptHeaderArgs, never>>;
  removePurchRcptHeader?: Resolver<Maybe<ResolversTypes['PurchRcptHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchRcptHeaderArgs, never>>;
  removePurchRcptHeaderMany?: Resolver<Maybe<ResolversTypes['PurchRcptHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchRcptHeaderManyArgs, never>>;
  upsertPurchRcptLine?: Resolver<Maybe<ResolversTypes['PurchRcptLine']>, ParentType, ContextType, RequireFields<MutationUpsertPurchRcptLineArgs, never>>;
  removePurchRcptLine?: Resolver<Maybe<ResolversTypes['PurchRcptLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchRcptLineArgs, never>>;
  removePurchRcptLineMany?: Resolver<Maybe<ResolversTypes['PurchRcptLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemovePurchRcptLineManyArgs, never>>;
  upsertQuoteHeader?: Resolver<Maybe<ResolversTypes['QuoteHeader']>, ParentType, ContextType, RequireFields<MutationUpsertQuoteHeaderArgs, never>>;
  removeQuoteHeader?: Resolver<Maybe<ResolversTypes['QuoteHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveQuoteHeaderArgs, never>>;
  removeQuoteHeaderMany?: Resolver<Maybe<ResolversTypes['QuoteHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveQuoteHeaderManyArgs, never>>;
  upsertQuoteLine?: Resolver<Maybe<ResolversTypes['QuoteLine']>, ParentType, ContextType, RequireFields<MutationUpsertQuoteLineArgs, never>>;
  removeQuoteLine?: Resolver<Maybe<ResolversTypes['QuoteLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveQuoteLineArgs, never>>;
  removeQuoteLineMany?: Resolver<Maybe<ResolversTypes['QuoteLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveQuoteLineManyArgs, never>>;
  upsertQuoteTaskGroup?: Resolver<Maybe<ResolversTypes['QuoteTaskGroup']>, ParentType, ContextType, RequireFields<MutationUpsertQuoteTaskGroupArgs, never>>;
  removeQuoteTaskGroup?: Resolver<Maybe<ResolversTypes['QuoteTaskGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveQuoteTaskGroupArgs, never>>;
  removeQuoteTaskGroupMany?: Resolver<Maybe<ResolversTypes['QuoteTaskGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveQuoteTaskGroupManyArgs, never>>;
  upsertRentalHeader?: Resolver<Maybe<ResolversTypes['RentalHeader']>, ParentType, ContextType, RequireFields<MutationUpsertRentalHeaderArgs, never>>;
  removeRentalHeader?: Resolver<Maybe<ResolversTypes['RentalHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalHeaderArgs, never>>;
  removeRentalHeaderMany?: Resolver<Maybe<ResolversTypes['RentalHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalHeaderManyArgs, never>>;
  upsertRentalLine?: Resolver<Maybe<ResolversTypes['RentalLine']>, ParentType, ContextType, RequireFields<MutationUpsertRentalLineArgs, never>>;
  removeRentalLine?: Resolver<Maybe<ResolversTypes['RentalLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalLineArgs, never>>;
  removeRentalLineMany?: Resolver<Maybe<ResolversTypes['RentalLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalLineManyArgs, never>>;
  upsertRentalLineSnapshot?: Resolver<Maybe<ResolversTypes['RentalLineSnapshot']>, ParentType, ContextType, RequireFields<MutationUpsertRentalLineSnapshotArgs, never>>;
  removeRentalLineSnapshot?: Resolver<Maybe<ResolversTypes['RentalLineSnapshotDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalLineSnapshotArgs, never>>;
  removeRentalLineSnapshotMany?: Resolver<Maybe<ResolversTypes['RentalLineSnapshotDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalLineSnapshotManyArgs, never>>;
  upsertRentalPostedCollHeader?: Resolver<Maybe<ResolversTypes['RentalPostedCollHeader']>, ParentType, ContextType, RequireFields<MutationUpsertRentalPostedCollHeaderArgs, never>>;
  removeRentalPostedCollHeader?: Resolver<Maybe<ResolversTypes['RentalPostedCollHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedCollHeaderArgs, never>>;
  removeRentalPostedCollHeaderMany?: Resolver<Maybe<ResolversTypes['RentalPostedCollHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedCollHeaderManyArgs, never>>;
  upsertRentalPostedCollLine?: Resolver<Maybe<ResolversTypes['RentalPostedCollLine']>, ParentType, ContextType, RequireFields<MutationUpsertRentalPostedCollLineArgs, never>>;
  removeRentalPostedCollLine?: Resolver<Maybe<ResolversTypes['RentalPostedCollLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedCollLineArgs, never>>;
  removeRentalPostedCollLineMany?: Resolver<Maybe<ResolversTypes['RentalPostedCollLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedCollLineManyArgs, never>>;
  upsertRentalPostedShptHeader?: Resolver<Maybe<ResolversTypes['RentalPostedShptHeader']>, ParentType, ContextType, RequireFields<MutationUpsertRentalPostedShptHeaderArgs, never>>;
  removeRentalPostedShptHeader?: Resolver<Maybe<ResolversTypes['RentalPostedShptHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedShptHeaderArgs, never>>;
  removeRentalPostedShptHeaderMany?: Resolver<Maybe<ResolversTypes['RentalPostedShptHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedShptHeaderManyArgs, never>>;
  upsertRentalPostedShptLine?: Resolver<Maybe<ResolversTypes['RentalPostedShptLine']>, ParentType, ContextType, RequireFields<MutationUpsertRentalPostedShptLineArgs, never>>;
  removeRentalPostedShptLine?: Resolver<Maybe<ResolversTypes['RentalPostedShptLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedShptLineArgs, never>>;
  removeRentalPostedShptLineMany?: Resolver<Maybe<ResolversTypes['RentalPostedShptLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPostedShptLineManyArgs, never>>;
  upsertRentalPriceTerms?: Resolver<Maybe<ResolversTypes['RentalPriceTerms']>, ParentType, ContextType, RequireFields<MutationUpsertRentalPriceTermsArgs, never>>;
  removeRentalPriceTerms?: Resolver<Maybe<ResolversTypes['RentalPriceTermsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPriceTermsArgs, never>>;
  removeRentalPriceTermsMany?: Resolver<Maybe<ResolversTypes['RentalPriceTermsDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalPriceTermsManyArgs, never>>;
  upsertRentalReturnEntry?: Resolver<Maybe<ResolversTypes['RentalReturnEntry']>, ParentType, ContextType, RequireFields<MutationUpsertRentalReturnEntryArgs, never>>;
  removeRentalReturnEntry?: Resolver<Maybe<ResolversTypes['RentalReturnEntryDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalReturnEntryArgs, never>>;
  removeRentalReturnEntryMany?: Resolver<Maybe<ResolversTypes['RentalReturnEntryDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRentalReturnEntryManyArgs, never>>;
  upsertRequestHeader?: Resolver<Maybe<ResolversTypes['RequestHeader']>, ParentType, ContextType, RequireFields<MutationUpsertRequestHeaderArgs, never>>;
  removeRequestHeader?: Resolver<Maybe<ResolversTypes['RequestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRequestHeaderArgs, never>>;
  removeRequestHeaderMany?: Resolver<Maybe<ResolversTypes['RequestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRequestHeaderManyArgs, never>>;
  upsertRequestLine?: Resolver<Maybe<ResolversTypes['RequestLine']>, ParentType, ContextType, RequireFields<MutationUpsertRequestLineArgs, never>>;
  removeRequestLine?: Resolver<Maybe<ResolversTypes['RequestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRequestLineArgs, never>>;
  removeRequestLineMany?: Resolver<Maybe<ResolversTypes['RequestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRequestLineManyArgs, never>>;
  upsertReservationRequestHeader?: Resolver<Maybe<ResolversTypes['ReservationRequestHeader']>, ParentType, ContextType, RequireFields<MutationUpsertReservationRequestHeaderArgs, never>>;
  removeReservationRequestHeader?: Resolver<Maybe<ResolversTypes['ReservationRequestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReservationRequestHeaderArgs, never>>;
  removeReservationRequestHeaderMany?: Resolver<Maybe<ResolversTypes['ReservationRequestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReservationRequestHeaderManyArgs, never>>;
  upsertReservationRequestLine?: Resolver<Maybe<ResolversTypes['ReservationRequestLine']>, ParentType, ContextType, RequireFields<MutationUpsertReservationRequestLineArgs, never>>;
  removeReservationRequestLine?: Resolver<Maybe<ResolversTypes['ReservationRequestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReservationRequestLineArgs, never>>;
  removeReservationRequestLineMany?: Resolver<Maybe<ResolversTypes['ReservationRequestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReservationRequestLineManyArgs, never>>;
  upsertResource?: Resolver<Maybe<ResolversTypes['Resource']>, ParentType, ContextType, RequireFields<MutationUpsertResourceArgs, never>>;
  removeResource?: Resolver<Maybe<ResolversTypes['ResourceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveResourceArgs, never>>;
  removeResourceMany?: Resolver<Maybe<ResolversTypes['ResourceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveResourceManyArgs, never>>;
  upsertResponsibilityCenter?: Resolver<Maybe<ResolversTypes['ResponsibilityCenter']>, ParentType, ContextType, RequireFields<MutationUpsertResponsibilityCenterArgs, never>>;
  removeResponsibilityCenter?: Resolver<Maybe<ResolversTypes['ResponsibilityCenterDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveResponsibilityCenterArgs, never>>;
  removeResponsibilityCenterMany?: Resolver<Maybe<ResolversTypes['ResponsibilityCenterDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveResponsibilityCenterManyArgs, never>>;
  upsertReturnRequestHeader?: Resolver<Maybe<ResolversTypes['ReturnRequestHeader']>, ParentType, ContextType, RequireFields<MutationUpsertReturnRequestHeaderArgs, never>>;
  removeReturnRequestHeader?: Resolver<Maybe<ResolversTypes['ReturnRequestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReturnRequestHeaderArgs, never>>;
  removeReturnRequestHeaderMany?: Resolver<Maybe<ResolversTypes['ReturnRequestHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReturnRequestHeaderManyArgs, never>>;
  upsertReturnRequestLine?: Resolver<Maybe<ResolversTypes['ReturnRequestLine']>, ParentType, ContextType, RequireFields<MutationUpsertReturnRequestLineArgs, never>>;
  removeReturnRequestLine?: Resolver<Maybe<ResolversTypes['ReturnRequestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReturnRequestLineArgs, never>>;
  removeReturnRequestLineMany?: Resolver<Maybe<ResolversTypes['ReturnRequestLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveReturnRequestLineManyArgs, never>>;
  upsertRiskAnalysisHeader?: Resolver<Maybe<ResolversTypes['RiskAnalysisHeader']>, ParentType, ContextType, RequireFields<MutationUpsertRiskAnalysisHeaderArgs, never>>;
  removeRiskAnalysisHeader?: Resolver<Maybe<ResolversTypes['RiskAnalysisHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRiskAnalysisHeaderArgs, never>>;
  removeRiskAnalysisHeaderMany?: Resolver<Maybe<ResolversTypes['RiskAnalysisHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRiskAnalysisHeaderManyArgs, never>>;
  upsertRiskAnalysisLine?: Resolver<Maybe<ResolversTypes['RiskAnalysisLine']>, ParentType, ContextType, RequireFields<MutationUpsertRiskAnalysisLineArgs, never>>;
  removeRiskAnalysisLine?: Resolver<Maybe<ResolversTypes['RiskAnalysisLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRiskAnalysisLineArgs, never>>;
  removeRiskAnalysisLineMany?: Resolver<Maybe<ResolversTypes['RiskAnalysisLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRiskAnalysisLineManyArgs, never>>;
  upsertRiskAnalysisSignature?: Resolver<Maybe<ResolversTypes['RiskAnalysisSignature']>, ParentType, ContextType, RequireFields<MutationUpsertRiskAnalysisSignatureArgs, never>>;
  removeRiskAnalysisSignature?: Resolver<Maybe<ResolversTypes['RiskAnalysisSignatureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRiskAnalysisSignatureArgs, never>>;
  removeRiskAnalysisSignatureMany?: Resolver<Maybe<ResolversTypes['RiskAnalysisSignatureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveRiskAnalysisSignatureManyArgs, never>>;
  upsertSalesHeader?: Resolver<Maybe<ResolversTypes['SalesHeader']>, ParentType, ContextType, RequireFields<MutationUpsertSalesHeaderArgs, never>>;
  removeSalesHeader?: Resolver<Maybe<ResolversTypes['SalesHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderArgs, never>>;
  removeSalesHeaderMany?: Resolver<Maybe<ResolversTypes['SalesHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderManyArgs, never>>;
  upsertSalesHeaderArchive?: Resolver<Maybe<ResolversTypes['SalesHeaderArchive']>, ParentType, ContextType, RequireFields<MutationUpsertSalesHeaderArchiveArgs, never>>;
  removeSalesHeaderArchive?: Resolver<Maybe<ResolversTypes['SalesHeaderArchiveDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderArchiveArgs, never>>;
  removeSalesHeaderArchiveMany?: Resolver<Maybe<ResolversTypes['SalesHeaderArchiveDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderArchiveManyArgs, never>>;
  upsertSalesHeaderHistory?: Resolver<Maybe<ResolversTypes['SalesHeaderHistory']>, ParentType, ContextType, RequireFields<MutationUpsertSalesHeaderHistoryArgs, never>>;
  removeSalesHeaderHistory?: Resolver<Maybe<ResolversTypes['SalesHeaderHistoryDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderHistoryArgs, never>>;
  removeSalesHeaderHistoryMany?: Resolver<Maybe<ResolversTypes['SalesHeaderHistoryDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderHistoryManyArgs, never>>;
  upsertSalesHeaderRequest?: Resolver<Maybe<ResolversTypes['SalesHeaderRequest']>, ParentType, ContextType, RequireFields<MutationUpsertSalesHeaderRequestArgs, never>>;
  removeSalesHeaderRequest?: Resolver<Maybe<ResolversTypes['SalesHeaderRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderRequestArgs, never>>;
  removeSalesHeaderRequestMany?: Resolver<Maybe<ResolversTypes['SalesHeaderRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesHeaderRequestManyArgs, never>>;
  upsertSalesLine?: Resolver<Maybe<ResolversTypes['SalesLine']>, ParentType, ContextType, RequireFields<MutationUpsertSalesLineArgs, never>>;
  removeSalesLine?: Resolver<Maybe<ResolversTypes['SalesLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesLineArgs, never>>;
  removeSalesLineMany?: Resolver<Maybe<ResolversTypes['SalesLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesLineManyArgs, never>>;
  upsertSalesLineArchive?: Resolver<Maybe<ResolversTypes['SalesLineArchive']>, ParentType, ContextType, RequireFields<MutationUpsertSalesLineArchiveArgs, never>>;
  removeSalesLineArchive?: Resolver<Maybe<ResolversTypes['SalesLineArchiveDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesLineArchiveArgs, never>>;
  removeSalesLineArchiveMany?: Resolver<Maybe<ResolversTypes['SalesLineArchiveDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesLineArchiveManyArgs, never>>;
  upsertSalesLineRequest?: Resolver<Maybe<ResolversTypes['SalesLineRequest']>, ParentType, ContextType, RequireFields<MutationUpsertSalesLineRequestArgs, never>>;
  removeSalesLineRequest?: Resolver<Maybe<ResolversTypes['SalesLineRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesLineRequestArgs, never>>;
  removeSalesLineRequestMany?: Resolver<Maybe<ResolversTypes['SalesLineRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesLineRequestManyArgs, never>>;
  upsertSalesperson?: Resolver<Maybe<ResolversTypes['Salesperson']>, ParentType, ContextType, RequireFields<MutationUpsertSalespersonArgs, never>>;
  removeSalesperson?: Resolver<Maybe<ResolversTypes['SalespersonDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalespersonArgs, never>>;
  removeSalespersonMany?: Resolver<Maybe<ResolversTypes['SalespersonDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalespersonManyArgs, never>>;
  upsertSalesPrice?: Resolver<Maybe<ResolversTypes['SalesPrice']>, ParentType, ContextType, RequireFields<MutationUpsertSalesPriceArgs, never>>;
  removeSalesPrice?: Resolver<Maybe<ResolversTypes['SalesPriceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesPriceArgs, never>>;
  removeSalesPriceMany?: Resolver<Maybe<ResolversTypes['SalesPriceDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesPriceManyArgs, never>>;
  upsertSalesShipmentHeader?: Resolver<Maybe<ResolversTypes['SalesShipmentHeader']>, ParentType, ContextType, RequireFields<MutationUpsertSalesShipmentHeaderArgs, never>>;
  removeSalesShipmentHeader?: Resolver<Maybe<ResolversTypes['SalesShipmentHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesShipmentHeaderArgs, never>>;
  removeSalesShipmentHeaderMany?: Resolver<Maybe<ResolversTypes['SalesShipmentHeaderDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesShipmentHeaderManyArgs, never>>;
  upsertSalesShipmentLine?: Resolver<Maybe<ResolversTypes['SalesShipmentLine']>, ParentType, ContextType, RequireFields<MutationUpsertSalesShipmentLineArgs, never>>;
  removeSalesShipmentLine?: Resolver<Maybe<ResolversTypes['SalesShipmentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesShipmentLineArgs, never>>;
  removeSalesShipmentLineMany?: Resolver<Maybe<ResolversTypes['SalesShipmentLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSalesShipmentLineManyArgs, never>>;
  upsertServiceItemGroup?: Resolver<Maybe<ResolversTypes['ServiceItemGroup']>, ParentType, ContextType, RequireFields<MutationUpsertServiceItemGroupArgs, never>>;
  removeServiceItemGroup?: Resolver<Maybe<ResolversTypes['ServiceItemGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveServiceItemGroupArgs, never>>;
  removeServiceItemGroupMany?: Resolver<Maybe<ResolversTypes['ServiceItemGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveServiceItemGroupManyArgs, never>>;
  upsertShipmentMethod?: Resolver<Maybe<ResolversTypes['ShipmentMethod']>, ParentType, ContextType, RequireFields<MutationUpsertShipmentMethodArgs, never>>;
  removeShipmentMethod?: Resolver<Maybe<ResolversTypes['ShipmentMethodDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveShipmentMethodArgs, never>>;
  removeShipmentMethodMany?: Resolver<Maybe<ResolversTypes['ShipmentMethodDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveShipmentMethodManyArgs, never>>;
  upsertShippingAgent?: Resolver<Maybe<ResolversTypes['ShippingAgent']>, ParentType, ContextType, RequireFields<MutationUpsertShippingAgentArgs, never>>;
  removeShippingAgent?: Resolver<Maybe<ResolversTypes['ShippingAgentDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveShippingAgentArgs, never>>;
  removeShippingAgentMany?: Resolver<Maybe<ResolversTypes['ShippingAgentDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveShippingAgentManyArgs, never>>;
  upsertSignature?: Resolver<Maybe<ResolversTypes['Signature']>, ParentType, ContextType, RequireFields<MutationUpsertSignatureArgs, never>>;
  removeSignature?: Resolver<Maybe<ResolversTypes['SignatureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSignatureArgs, never>>;
  removeSignatureMany?: Resolver<Maybe<ResolversTypes['SignatureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSignatureManyArgs, never>>;
  upsertSignRequest?: Resolver<Maybe<ResolversTypes['SignRequest']>, ParentType, ContextType, RequireFields<MutationUpsertSignRequestArgs, never>>;
  removeSignRequest?: Resolver<Maybe<ResolversTypes['SignRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSignRequestArgs, never>>;
  removeSignRequestMany?: Resolver<Maybe<ResolversTypes['SignRequestDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSignRequestManyArgs, never>>;
  upsertSpecialEquipment?: Resolver<Maybe<ResolversTypes['SpecialEquipment']>, ParentType, ContextType, RequireFields<MutationUpsertSpecialEquipmentArgs, never>>;
  removeSpecialEquipment?: Resolver<Maybe<ResolversTypes['SpecialEquipmentDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSpecialEquipmentArgs, never>>;
  removeSpecialEquipmentMany?: Resolver<Maybe<ResolversTypes['SpecialEquipmentDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveSpecialEquipmentManyArgs, never>>;
  upsertStockkeepingUnit?: Resolver<Maybe<ResolversTypes['StockkeepingUnit']>, ParentType, ContextType, RequireFields<MutationUpsertStockkeepingUnitArgs, never>>;
  removeStockkeepingUnit?: Resolver<Maybe<ResolversTypes['StockkeepingUnitDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveStockkeepingUnitArgs, never>>;
  removeStockkeepingUnitMany?: Resolver<Maybe<ResolversTypes['StockkeepingUnitDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveStockkeepingUnitManyArgs, never>>;
  upsertTariffNumber?: Resolver<Maybe<ResolversTypes['TariffNumber']>, ParentType, ContextType, RequireFields<MutationUpsertTariffNumberArgs, never>>;
  removeTariffNumber?: Resolver<Maybe<ResolversTypes['TariffNumberDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTariffNumberArgs, never>>;
  removeTariffNumberMany?: Resolver<Maybe<ResolversTypes['TariffNumberDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTariffNumberManyArgs, never>>;
  upsertTaxGroup?: Resolver<Maybe<ResolversTypes['TaxGroup']>, ParentType, ContextType, RequireFields<MutationUpsertTaxGroupArgs, never>>;
  removeTaxGroup?: Resolver<Maybe<ResolversTypes['TaxGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTaxGroupArgs, never>>;
  removeTaxGroupMany?: Resolver<Maybe<ResolversTypes['TaxGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTaxGroupManyArgs, never>>;
  upsertTimeRegistration?: Resolver<Maybe<ResolversTypes['TimeRegistration']>, ParentType, ContextType, RequireFields<MutationUpsertTimeRegistrationArgs, never>>;
  removeTimeRegistration?: Resolver<Maybe<ResolversTypes['TimeRegistrationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTimeRegistrationArgs, never>>;
  removeTimeRegistrationMany?: Resolver<Maybe<ResolversTypes['TimeRegistrationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTimeRegistrationManyArgs, never>>;
  upsertTimeSheetDetail?: Resolver<Maybe<ResolversTypes['TimeSheetDetail']>, ParentType, ContextType, RequireFields<MutationUpsertTimeSheetDetailArgs, never>>;
  removeTimeSheetDetail?: Resolver<Maybe<ResolversTypes['TimeSheetDetailDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTimeSheetDetailArgs, never>>;
  removeTimeSheetDetailMany?: Resolver<Maybe<ResolversTypes['TimeSheetDetailDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTimeSheetDetailManyArgs, never>>;
  upsertTimeSheetLine?: Resolver<Maybe<ResolversTypes['TimeSheetLine']>, ParentType, ContextType, RequireFields<MutationUpsertTimeSheetLineArgs, never>>;
  removeTimeSheetLine?: Resolver<Maybe<ResolversTypes['TimeSheetLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTimeSheetLineArgs, never>>;
  removeTimeSheetLineMany?: Resolver<Maybe<ResolversTypes['TimeSheetLineDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTimeSheetLineManyArgs, never>>;
  upsertTransactionSpecification?: Resolver<Maybe<ResolversTypes['TransactionSpecification']>, ParentType, ContextType, RequireFields<MutationUpsertTransactionSpecificationArgs, never>>;
  removeTransactionSpecification?: Resolver<Maybe<ResolversTypes['TransactionSpecificationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTransactionSpecificationArgs, never>>;
  removeTransactionSpecificationMany?: Resolver<Maybe<ResolversTypes['TransactionSpecificationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTransactionSpecificationManyArgs, never>>;
  upsertTransactionType?: Resolver<Maybe<ResolversTypes['TransactionType']>, ParentType, ContextType, RequireFields<MutationUpsertTransactionTypeArgs, never>>;
  removeTransactionType?: Resolver<Maybe<ResolversTypes['TransactionTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTransactionTypeArgs, never>>;
  removeTransactionTypeMany?: Resolver<Maybe<ResolversTypes['TransactionTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTransactionTypeManyArgs, never>>;
  upsertTransportMethod?: Resolver<Maybe<ResolversTypes['TransportMethod']>, ParentType, ContextType, RequireFields<MutationUpsertTransportMethodArgs, never>>;
  removeTransportMethod?: Resolver<Maybe<ResolversTypes['TransportMethodDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTransportMethodArgs, never>>;
  removeTransportMethodMany?: Resolver<Maybe<ResolversTypes['TransportMethodDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveTransportMethodManyArgs, never>>;
  upsertUnitOfMeasure?: Resolver<Maybe<ResolversTypes['UnitOfMeasure']>, ParentType, ContextType, RequireFields<MutationUpsertUnitOfMeasureArgs, never>>;
  removeUnitOfMeasure?: Resolver<Maybe<ResolversTypes['UnitOfMeasureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveUnitOfMeasureArgs, never>>;
  removeUnitOfMeasureMany?: Resolver<Maybe<ResolversTypes['UnitOfMeasureDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveUnitOfMeasureManyArgs, never>>;
  upsertUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpsertUserArgs, never>>;
  removeUser?: Resolver<Maybe<ResolversTypes['UserDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveUserArgs, never>>;
  removeUserMany?: Resolver<Maybe<ResolversTypes['UserDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveUserManyArgs, never>>;
  upsertUserFavorites?: Resolver<Maybe<ResolversTypes['UserFavorites']>, ParentType, ContextType, RequireFields<MutationUpsertUserFavoritesArgs, never>>;
  removeUserFavorites?: Resolver<Maybe<ResolversTypes['UserFavoritesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveUserFavoritesArgs, never>>;
  removeUserFavoritesMany?: Resolver<Maybe<ResolversTypes['UserFavoritesDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveUserFavoritesManyArgs, never>>;
  upsertVatBusinessPostingGroup?: Resolver<Maybe<ResolversTypes['VatBusinessPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertVatBusinessPostingGroupArgs, never>>;
  removeVatBusinessPostingGroup?: Resolver<Maybe<ResolversTypes['VatBusinessPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVatBusinessPostingGroupArgs, never>>;
  removeVatBusinessPostingGroupMany?: Resolver<Maybe<ResolversTypes['VatBusinessPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVatBusinessPostingGroupManyArgs, never>>;
  upsertVatProductPostingGroup?: Resolver<Maybe<ResolversTypes['VatProductPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertVatProductPostingGroupArgs, never>>;
  removeVatProductPostingGroup?: Resolver<Maybe<ResolversTypes['VatProductPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVatProductPostingGroupArgs, never>>;
  removeVatProductPostingGroupMany?: Resolver<Maybe<ResolversTypes['VatProductPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVatProductPostingGroupManyArgs, never>>;
  upsertVendor?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<MutationUpsertVendorArgs, never>>;
  removeVendor?: Resolver<Maybe<ResolversTypes['VendorDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVendorArgs, never>>;
  removeVendorMany?: Resolver<Maybe<ResolversTypes['VendorDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVendorManyArgs, never>>;
  upsertVendorBankAccount?: Resolver<Maybe<ResolversTypes['VendorBankAccount']>, ParentType, ContextType, RequireFields<MutationUpsertVendorBankAccountArgs, never>>;
  removeVendorBankAccount?: Resolver<Maybe<ResolversTypes['VendorBankAccountDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVendorBankAccountArgs, never>>;
  removeVendorBankAccountMany?: Resolver<Maybe<ResolversTypes['VendorBankAccountDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVendorBankAccountManyArgs, never>>;
  upsertVendorPostingGroup?: Resolver<Maybe<ResolversTypes['VendorPostingGroup']>, ParentType, ContextType, RequireFields<MutationUpsertVendorPostingGroupArgs, never>>;
  removeVendorPostingGroup?: Resolver<Maybe<ResolversTypes['VendorPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVendorPostingGroupArgs, never>>;
  removeVendorPostingGroupMany?: Resolver<Maybe<ResolversTypes['VendorPostingGroupDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveVendorPostingGroupManyArgs, never>>;
  upsertWarehouseClass?: Resolver<Maybe<ResolversTypes['WarehouseClass']>, ParentType, ContextType, RequireFields<MutationUpsertWarehouseClassArgs, never>>;
  removeWarehouseClass?: Resolver<Maybe<ResolversTypes['WarehouseClassDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWarehouseClassArgs, never>>;
  removeWarehouseClassMany?: Resolver<Maybe<ResolversTypes['WarehouseClassDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWarehouseClassManyArgs, never>>;
  upsertWebBooking?: Resolver<Maybe<ResolversTypes['WebBooking']>, ParentType, ContextType, RequireFields<MutationUpsertWebBookingArgs, never>>;
  removeWebBooking?: Resolver<Maybe<ResolversTypes['WebBookingDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWebBookingArgs, never>>;
  removeWebBookingMany?: Resolver<Maybe<ResolversTypes['WebBookingDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWebBookingManyArgs, never>>;
  upsertWebhookSubscription?: Resolver<Maybe<ResolversTypes['WebhookSubscription']>, ParentType, ContextType, RequireFields<MutationUpsertWebhookSubscriptionArgs, never>>;
  removeWebhookSubscription?: Resolver<Maybe<ResolversTypes['WebhookSubscriptionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWebhookSubscriptionArgs, never>>;
  removeWebhookSubscriptionMany?: Resolver<Maybe<ResolversTypes['WebhookSubscriptionDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWebhookSubscriptionManyArgs, never>>;
  upsertWorkLog?: Resolver<Maybe<ResolversTypes['WorkLog']>, ParentType, ContextType, RequireFields<MutationUpsertWorkLogArgs, never>>;
  removeWorkLog?: Resolver<Maybe<ResolversTypes['WorkLogDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWorkLogArgs, never>>;
  removeWorkLogMany?: Resolver<Maybe<ResolversTypes['WorkLogDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWorkLogManyArgs, never>>;
  upsertWorkType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType, RequireFields<MutationUpsertWorkTypeArgs, never>>;
  removeWorkType?: Resolver<Maybe<ResolversTypes['WorkTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWorkTypeArgs, never>>;
  removeWorkTypeMany?: Resolver<Maybe<ResolversTypes['WorkTypeDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveWorkTypeManyArgs, never>>;
  createAccessRequest?: Resolver<ResolversTypes['AccessRequest'], ParentType, ContextType, RequireFields<MutationCreateAccessRequestArgs, never>>;
  approveRejectAccessRequest?: Resolver<ResolversTypes['AccessRequest'], ParentType, ContextType, RequireFields<MutationApproveRejectAccessRequestArgs, never>>;
  toggleSupplier?: Resolver<ResolversTypes['toggleSuplierResponse'], ParentType, ContextType, RequireFields<MutationToggleSupplierArgs, never>>;
  syncUserDomainIds?: Resolver<ResolversTypes['syncUsersResponse'], ParentType, ContextType, RequireFields<MutationSyncUserDomainIdsArgs, never>>;
  addPushToken?: Resolver<Maybe<ResolversTypes['UserOperationResponse']>, ParentType, ContextType, RequireFields<MutationAddPushTokenArgs, 'token' | 'app'>>;
  clearPushTokens?: Resolver<Maybe<ResolversTypes['UserOperationResponse']>, ParentType, ContextType, RequireFields<MutationClearPushTokensArgs, 'app'>>;
  logout?: Resolver<Maybe<ResolversTypes['UserOperationResponse']>, ParentType, ContextType>;
  upsertMe?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpsertMeArgs, never>>;
  toggleFavoriteProject?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationToggleFavoriteProjectArgs, never>>;
  createServiceAccount?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationCreateServiceAccountArgs, never>>;
  requestToken?: Resolver<Maybe<ResolversTypes['AuthResponse']>, ParentType, ContextType, RequireFields<MutationRequestTokenArgs, 'username' | 'password'>>;
  impersonateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationImpersonateUserArgs, 'Email'>>;
  clearMyImpersonation?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  sendPushNotification?: Resolver<Maybe<Array<Maybe<ResolversTypes['PushNotificationResponse']>>>, ParentType, ContextType, RequireFields<MutationSendPushNotificationArgs, never>>;
  toggleProjectNotifications?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationToggleProjectNotificationsArgs, never>>;
  proxyHeartbeat?: Resolver<Maybe<ResolversTypes['ProxyHeartbeatResponse']>, ParentType, ContextType, RequireFields<MutationProxyHeartbeatArgs, 'GlobalId'>>;
  setCustomerDomainImage?: Resolver<ResolversTypes['CustomerDomain'], ParentType, ContextType, RequireFields<MutationSetCustomerDomainImageArgs, never>>;
  upsertCustomerDomainLimited?: Resolver<Maybe<ResolversTypes['CustomerDomain']>, ParentType, ContextType, RequireFields<MutationUpsertCustomerDomainLimitedArgs, never>>;
  setDomainImage?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<MutationSetDomainImageArgs, 'record' | 'DomainId'>>;
  setFlagImage?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<MutationSetFlagImageArgs, 'record' | 'DomainId'>>;
  switchToSiblingDomain?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationSwitchToSiblingDomainArgs, 'SwitchToDomainId'>>;
  initializeDomain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<MutationInitializeDomainArgs, never>>;
  upsertDomain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<MutationUpsertDomainArgs, never>>;
  removeDomain?: Resolver<Maybe<ResolversTypes['DomainDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDomainArgs, never>>;
  removeDomainMany?: Resolver<Maybe<ResolversTypes['DomainDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDomainManyArgs, never>>;
  upsertDomainIntegration?: Resolver<Maybe<ResolversTypes['DomainIntegration']>, ParentType, ContextType, RequireFields<MutationUpsertDomainIntegrationArgs, never>>;
  testDomainIntegration?: Resolver<Maybe<ResolversTypes['DomainConnectionTestResult']>, ParentType, ContextType, RequireFields<MutationTestDomainIntegrationArgs, 'GlobalId'>>;
  removeDomainIntegration?: Resolver<Maybe<ResolversTypes['DomainIntegrationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveDomainIntegrationArgs, never>>;
  limitedUpsertCustomerProject?: Resolver<Maybe<ResolversTypes['LimitedUpdateCustomerProjectResponse']>, ParentType, ContextType, RequireFields<MutationLimitedUpsertCustomerProjectArgs, never>>;
  upsertDocument?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<MutationUpsertDocumentArgs, never>>;
  createWorkshopOrder?: Resolver<Maybe<ResolversTypes['CreateWorkshopOrderResponse']>, ParentType, ContextType, RequireFields<MutationCreateWorkshopOrderArgs, never>>;
  changeObjectTypeImage?: Resolver<Maybe<ResolversTypes['ObjectType']>, ParentType, ContextType, RequireFields<MutationChangeObjectTypeImageArgs, never>>;
  changeObjectFamilyImage?: Resolver<Maybe<ResolversTypes['ObjectFamily']>, ParentType, ContextType, RequireFields<MutationChangeObjectFamilyImageArgs, never>>;
  changeObjectGroupImage?: Resolver<Maybe<ResolversTypes['ObjectGroup']>, ParentType, ContextType, RequireFields<MutationChangeObjectGroupImageArgs, never>>;
  notifyDailyRentTransactions?: Resolver<Maybe<ResolversTypes['DailyTransactionResponse']>, ParentType, ContextType, RequireFields<MutationNotifyDailyRentTransactionsArgs, 'app'>>;
  sendOnRentListByEmail?: Resolver<Maybe<ResolversTypes['SendOnRentEmailResponse']>, ParentType, ContextType, RequireFields<MutationSendOnRentListByEmailArgs, 'record'>>;
  retrySync?: Resolver<Maybe<ResolversTypes['RetrySyncResponse']>, ParentType, ContextType, RequireFields<MutationRetrySyncArgs, 'record'>>;
  upsertSalesOrder?: Resolver<Maybe<ResolversTypes['SalesOrderResponse']>, ParentType, ContextType, RequireFields<MutationUpsertSalesOrderArgs, never>>;
  upsertCompanyTree?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationUpsertCompanyTreeArgs, never>>;
  removeInvitation?: Resolver<Maybe<ResolversTypes['InvitationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInvitationArgs, never>>;
  removeInvitationsMany?: Resolver<Maybe<ResolversTypes['InvitationDeleteResponse']>, ParentType, ContextType, RequireFields<MutationRemoveInvitationsManyArgs, never>>;
  acceptPersonalInvitation?: Resolver<Maybe<ResolversTypes['AcceptInivitationResponse']>, ParentType, ContextType, RequireFields<MutationAcceptPersonalInvitationArgs, 'Code'>>;
  acceptCompanyInvitation?: Resolver<Maybe<ResolversTypes['AcceptInivitationResponse']>, ParentType, ContextType, RequireFields<MutationAcceptCompanyInvitationArgs, 'Code'>>;
  acceptDomainInvitation?: Resolver<Maybe<ResolversTypes['AcceptInivitationResponse']>, ParentType, ContextType, RequireFields<MutationAcceptDomainInvitationArgs, 'Code'>>;
  createPersonalInvitation?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType, RequireFields<MutationCreatePersonalInvitationArgs, never>>;
  createCompanyInvitation?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType, RequireFields<MutationCreateCompanyInvitationArgs, never>>;
  createDomainInvitation?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType, RequireFields<MutationCreateDomainInvitationArgs, never>>;
  deactivateInvitation?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType, RequireFields<MutationDeactivateInvitationArgs, 'code'>>;
  updateDomainInvitationAppLinks?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType, RequireFields<MutationUpdateDomainInvitationAppLinksArgs, 'record'>>;
  uploadIOSAppCodeFile?: Resolver<Maybe<ResolversTypes['UploadAppCodesResponse']>, ParentType, ContextType, RequireFields<MutationUploadIosAppCodeFileArgs, 'file' | 'InvitationCode'>>;
  clearInvitationCodes?: Resolver<Maybe<ResolversTypes['RemoveCodesResponse']>, ParentType, ContextType, RequireFields<MutationClearInvitationCodesArgs, 'code'>>;
  startStopFuncTest?: Resolver<Maybe<ResolversTypes['FunctionTestHeader']>, ParentType, ContextType, RequireFields<MutationStartStopFuncTestArgs, 'GlobalId'>>;
  clearFinishedFunctionalTests?: Resolver<Maybe<ResolversTypes['ClearFunctionalTestReusult']>, ParentType, ContextType, RequireFields<MutationClearFinishedFunctionalTestsArgs, 'filter'>>;
};

export type NavUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['NavUser'] = ResolversParentTypes['NavUser']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserSecurityID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  State?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StateOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpiryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WindowsSecurityID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChangePassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LicenseType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LicenseTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AuthenticationEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EdgeCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NavUserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['NavUserConnection'] = ResolversParentTypes['NavUserConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['NavUserConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NavUserConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NavUserConnectionEdge'] = ResolversParentTypes['NavUserConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['NavUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NavUserDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['NavUserDeleteResponse'] = ResolversParentTypes['NavUserDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['NavUser']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NavUserManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NavUserManyResult'] = ResolversParentTypes['NavUserManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['NavUser']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Object'] = ResolversParentTypes['Object']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FixedAssetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StatusInventory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpWriteUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpIntRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpExtRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostedInvoiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostingMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StandardCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IndirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastDirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceProfitCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceIncludesVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlockedforRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ServiceItemGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultContractValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultContractDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OwningLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpHire?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpLeasing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplitPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SoldFor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SoldDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UseFixedAsset?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounterType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FirstRented?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastRented?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarrantyDiscParts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDiscLabor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultWarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponseTimeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InPurchaseQuote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InPurchaseOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchaseInvoiced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchaseReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VATBusPostingGrPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedbyUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlockedforSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BlockedforPurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BlockedforReservation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OwnershipStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InclinInvValueReport?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  StdCostAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchasedFor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObtainedDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetCostAmountAdditional?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiscountGiven?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReplacementPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LinkedtoObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Capacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Effect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CylinderVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AutomaticExtendedTexts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CapacityMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EffectMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CylinderMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EngineNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MobilePhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RadioCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChassisNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConstructionYear?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateofPermit?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  InSalesOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InSalesQuote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InSalesInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SoldonInvoiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpAvailableAfterEndDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditonEarlyReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultMaintenance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InDelivery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AdditionalObjectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AdditionalObjectCostDisc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AdditionalObjectSalesPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LengthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WidthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InSalesCreditMemo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  StdRentalChargeDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PolicyNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LeasingContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NewOwnershipstatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NewStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CreateFixedAsset?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Installment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InstallmentDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BonusTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FireInspection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecurringInspection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecurringInspection2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InternalInspection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FireInspectionNext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExcludeVacation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RecurringInspectionNext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecurringInspection2Next?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalInspectionNext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InspectionPeriodold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InspectionPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InspectionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextInspectionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  InspectionSerieNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OwnerStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CEDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EngineCertification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastInventoriedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  InventoryInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinanceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AmortizationRate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurschaseValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceAgreement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Finance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusInventoryBeforeLost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap6?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap7?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap8?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap9?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap10?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap11?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap12?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap13?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap14?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap15?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap16?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap17?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap18?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap19?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap20?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap21?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap22?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap23?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap24?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap25?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap26?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap27?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap28?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap29?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap30?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap31?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Bitmap32?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreviousInventoryStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityHoursFullRentalDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HireSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WorkOrderCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OldObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChangedbyUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChangedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MandatoryCounteronReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RegStatusRental?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarrantyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TrackingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGrpRental?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastServiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastInspectionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastInspDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TerminateFixedAsset?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounterReporting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EstimatedDailyHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedCounterperDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounterType2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Counter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedCounter2perDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MandatoryCounter2onReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DateFilter2?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Inventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentActiveContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CommentObjectGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CommentObjectType?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CommentsinContract?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InventoryRental?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonPurchOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowComponentCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseAddCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowTotalPurchaseCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Invoiceddays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedIntRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedServiceAgrCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedSrvAgrIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedIntRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedInvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLinkedOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkOrderFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkPurchaseFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkOrderLineFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkPurchLineFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedWorkshopEntriesFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastPriceAgreement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PSILink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CaravanList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TechnicalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FunctionalTests?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastRentalStatusChage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ObjectTypeNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofRentalQuoteLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofOpenRentalLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofClosedRentalLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofDefaultRentalPrices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDateFlow?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GPSCoordinates?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PSI?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalObjectStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalObjectStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufactureDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastInspectionBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastInspectionByEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FirstUseDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  IsElectric?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OffRentDateEdge?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Broken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RealBridgeSoldToCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RealBridgeCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RealBridgeProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DekraLno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SentToDekra?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentPlacement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualPlacement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InspectionMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectCommentLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectCommentLine'] = ResolversParentTypes['ObjectCommentLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TableName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TableNameOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectCommentLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectCommentLineConnection'] = ResolversParentTypes['ObjectCommentLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectCommentLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectCommentLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectCommentLineConnectionEdge'] = ResolversParentTypes['ObjectCommentLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ObjectCommentLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectCommentLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectCommentLineDeleteResponse'] = ResolversParentTypes['ObjectCommentLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectCommentLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectCommentLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectCommentLineManyResult'] = ResolversParentTypes['ObjectCommentLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectCommentLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectConnection'] = ResolversParentTypes['ObjectConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectConnectionEdge'] = ResolversParentTypes['ObjectConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Object'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectDeleteResponse'] = ResolversParentTypes['ObjectDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Object']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectFamilyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectFamily'] = ResolversParentTypes['ObjectFamily']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Family?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgUrlSmall?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgUrlLarge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectFamilyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectFamilyConnection'] = ResolversParentTypes['ObjectFamilyConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectFamilyConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectFamilyConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectFamilyConnectionEdge'] = ResolversParentTypes['ObjectFamilyConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ObjectFamily'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectFamilyDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectFamilyDeleteResponse'] = ResolversParentTypes['ObjectFamilyDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectFamily']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectFamilyManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectFamilyManyResult'] = ResolversParentTypes['ObjectFamilyManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectFamily']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectGroup'] = ResolversParentTypes['ObjectGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpIntRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpExtRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAClassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FASubclassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepreciationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpHire?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpLeasing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplitPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpWriteUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceItemGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditonEarlyOffRent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StdRentalChargeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StdRentalChargeDaysOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxDepreciationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDepreciationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxFAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxNoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxNoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxStraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgUrlSmall?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImgUrlLarge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroupFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectGroupConnection'] = ResolversParentTypes['ObjectGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectGroupConnectionEdge'] = ResolversParentTypes['ObjectGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ObjectGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectGroupDeleteResponse'] = ResolversParentTypes['ObjectGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectGroupManyResult'] = ResolversParentTypes['ObjectGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectManyResult'] = ResolversParentTypes['ObjectManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Object']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnHeader'] = ResolversParentTypes['ObjectReturnHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OffRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NumberofRentFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NumberofRentFreeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonforRentFree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditonEarlyReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantitytoSell?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantitytoDamage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceivingLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CounteronDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounteronReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityHoursFullRentalDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQuantityTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CurrentPlacement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EdgePost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OffRentUpdated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMServiceDoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Unitprice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeletedManually?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReqRefill?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReqApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApprovedbyUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CodeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CodeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MotherLineRegUpdateLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MotherLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MotherLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Maintenance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SubmittedByUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubmittedByUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PushNotificationSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnHeaderConnection'] = ResolversParentTypes['ObjectReturnHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectReturnHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnHeaderConnectionEdge'] = ResolversParentTypes['ObjectReturnHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ObjectReturnHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnHeaderDeleteResponse'] = ResolversParentTypes['ObjectReturnHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectReturnHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnHeaderManyResult'] = ResolversParentTypes['ObjectReturnHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectReturnHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnLine'] = ResolversParentTypes['ObjectReturnLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Unitprice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeletedManually?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReqRefill?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReqApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApprovedbyUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CodeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CodeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MotherLineRegUpdateLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MotherLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MotherLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnLineConnection'] = ResolversParentTypes['ObjectReturnLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectReturnLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnLineConnectionEdge'] = ResolversParentTypes['ObjectReturnLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ObjectReturnLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnLineDeleteResponse'] = ResolversParentTypes['ObjectReturnLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectReturnLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReturnLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectReturnLineManyResult'] = ResolversParentTypes['ObjectReturnLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectReturnLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectType'] = ResolversParentTypes['ObjectType']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAClassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FASubclassCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectNos?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostingMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StandardCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IndirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastDirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceProfitCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceIncludesVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounterType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WarrantyDiscParts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDiscLabor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultWarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceItemGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponseTimeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpWriteUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastTimeModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGrPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpHire?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpLeasing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplitPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpExtRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGrpIntRent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReplacementPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Capacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Effect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CylinderVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AutomaticExtendedTexts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CapacityMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EffectMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CylinderMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Document?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebPage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpAvailableAfterEndDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditonEarlyReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StdRentalChargeDays?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultMaintenance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LengthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WidthMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WeightMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuantityHoursFullRentalDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectTypeNoConcern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HireSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FixedCostPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WarrantyDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RetentionLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDepreciationMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxFAPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxNoofDepreciationYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxNoofDepreciationMonths?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxStraightLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaxDecliningBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedDailyHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MandatoryCounteronReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounterType2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MandatoryCounter2onReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EstimatedCounterperDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedCounter2perDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Inventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InventoryRental?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CommentObjectGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FlowSumCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLeasingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowOtherIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGuaranteeCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowHireDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDepreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowUppreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowDownpreciation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowGainbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowLossbySale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowPurchaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowSalesCharge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowServiceAgreementIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowInternalRentalIncome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowValueChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlowRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalReservationsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDeliveriesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExpectedRentalReturnsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalReturnsQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PictureURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PSIDocumentURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NotAvailableForReservation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectTypeConnection'] = ResolversParentTypes['ObjectTypeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ObjectTypeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectTypeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectTypeConnectionEdge'] = ResolversParentTypes['ObjectTypeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ObjectType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectTypeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectTypeDeleteResponse'] = ResolversParentTypes['ObjectTypeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectType']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectTypeLimitedResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectTypeLimited'] = ResolversParentTypes['ObjectTypeLimited']> = {
  Descriptin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PictureUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectTypeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectTypeManyResult'] = ResolversParentTypes['ObjectTypeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectType']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OcrAnalysisResolvers<ContextType = any, ParentType extends ResolversParentTypes['OcrAnalysis'] = ResolversParentTypes['OcrAnalysis']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  textAngle?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  orientation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OcrAnalysisConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OcrAnalysisConnection'] = ResolversParentTypes['OcrAnalysisConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['OcrAnalysisConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OcrAnalysisConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OcrAnalysisConnectionEdge'] = ResolversParentTypes['OcrAnalysisConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['OcrAnalysis'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OcrAnalysisDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OcrAnalysisDeleteResponse'] = ResolversParentTypes['OcrAnalysisDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['OcrAnalysis']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OcrAnalysisManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OcrAnalysisManyResult'] = ResolversParentTypes['OcrAnalysisManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['OcrAnalysis']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OldFunctionTestCountResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OldFunctionTestCountResult'] = ResolversParentTypes['OldFunctionTestCountResult']> = {
  DateUsed?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  header?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageData'] = ResolversParentTypes['PageData']> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endCursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DirectDebit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DirectDebitPmtTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PmtExportLineDefinition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankDataConversionPmtType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseforInvoicing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AMCBankPmtType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCashPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPaymentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMPaymentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethodConnection'] = ResolversParentTypes['PaymentMethodConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PaymentMethodConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethodConnectionEdge'] = ResolversParentTypes['PaymentMethodConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethodDeleteResponse'] = ResolversParentTypes['PaymentMethodDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethodManyResult'] = ResolversParentTypes['PaymentMethodManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTermsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentTerms'] = ResolversParentTypes['PaymentTerms']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDateCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DiscountDateCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CalcPmtDisconCrMemos?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTermsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentTermsConnection'] = ResolversParentTypes['PaymentTermsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PaymentTermsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTermsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentTermsConnectionEdge'] = ResolversParentTypes['PaymentTermsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PaymentTerms'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTermsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentTermsDeleteResponse'] = ResolversParentTypes['PaymentTermsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentTerms']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTermsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentTermsManyResult'] = ResolversParentTypes['PaymentTermsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentTerms']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformFeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformFeature'] = ResolversParentTypes['PlatformFeature']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  basic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  premium?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  enterprise?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformFeatureConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformFeatureConnection'] = ResolversParentTypes['PlatformFeatureConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PlatformFeatureConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformFeatureConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformFeatureConnectionEdge'] = ResolversParentTypes['PlatformFeatureConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PlatformFeature'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformFeatureDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformFeatureDeleteResponse'] = ResolversParentTypes['PlatformFeatureDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlatformFeature']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformFeatureManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlatformFeatureManyResult'] = ResolversParentTypes['PlatformFeatureManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlatformFeature']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCode'] = ResolversParentTypes['PostCode']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostCodeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCodeConnection'] = ResolversParentTypes['PostCodeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PostCodeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostCodeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCodeConnectionEdge'] = ResolversParentTypes['PostCodeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PostCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostCodeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCodeDeleteResponse'] = ResolversParentTypes['PostCodeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PostCode']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostCodeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCodeManyResult'] = ResolversParentTypes['PostCodeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PostCode']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StartingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PersonResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BlockedOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WIPMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WIPPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  InvoiceCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExchCalculationCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExchCalculationCostOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExchCalculationPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExchCalculationPriceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowScheduleContractLines?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Complete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApplyUsageLink?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WIPPostingMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WIPPostingMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverBudget?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ProjectManager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Inactive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NotChargeable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResourceFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceGrFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlanningDateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ScheduledResQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ScheduledResGrQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalWIPCostAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalWIPCostGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WIPEntriesExist?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WIPGLPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RecogSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecogSalesGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecogCostsAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecogCostsGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalWIPSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalWIPSalesGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WIPCompletionCalculated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NextInvoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  WIPWarnings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AppliedCostsGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliedSalesGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcRecogSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcRecogCostsAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcRecogSalesGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcRecogCostsGLAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WIPCompletionPosted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MonthlyValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LinkedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncJobTastTypeWithDevops?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DevopsTeamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DevopsProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectConnection'] = ResolversParentTypes['ProjectConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProjectConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectConnectionEdge'] = ResolversParentTypes['ProjectConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Project'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectDeleteResponse'] = ResolversParentTypes['ProjectDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Project']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectManyResult'] = ResolversParentTypes['ProjectManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Project']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPushNotificationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPushNotifications'] = ResolversParentTypes['ProjectPushNotifications']> = {
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Shipments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Returns?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyHeartbeatResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyHeartbeatResponse'] = ResolversParentTypes['ProxyHeartbeatResponse']> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoHeader'] = ResolversParentTypes['PurchCrMemoHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaserCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderAddressCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtCrMemoNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentCreditMemo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Paid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RemainingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorLedgerEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cancelled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Corrective?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnOrderNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBNoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectPurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceTermNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoHeaderConnection'] = ResolversParentTypes['PurchCrMemoHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchCrMemoHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoHeaderConnectionEdge'] = ResolversParentTypes['PurchCrMemoHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchCrMemoHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoHeaderDeleteResponse'] = ResolversParentTypes['PurchCrMemoHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchCrMemoHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoHeaderManyResult'] = ResolversParentTypes['PurchCrMemoHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchCrMemoHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoLine'] = ResolversParentTypes['PurchCrMemoLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectUnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowCrMemooiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndirectCostPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrMemoDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ICItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PmtDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProdOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FAPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalvageValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeprAcquisitionCost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InsuranceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedFANo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectCostAssignment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFixedAssetSetupGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFixedAssetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReRentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoLineConnection'] = ResolversParentTypes['PurchCrMemoLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchCrMemoLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoLineConnectionEdge'] = ResolversParentTypes['PurchCrMemoLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchCrMemoLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoLineDeleteResponse'] = ResolversParentTypes['PurchCrMemoLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchCrMemoLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchCrMemoLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchCrMemoLineManyResult'] = ResolversParentTypes['PurchCrMemoLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchCrMemoLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvHeader'] = ResolversParentTypes['PurchInvHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaserCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorInvoiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderAddressCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Closed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RemainingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorLedgerEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cancelled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Corrective?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DraftInvoiceSystemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBNoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectPurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceTermNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMWSObjectCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectWarrantyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMerviceStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjWorkPurchFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMWSObjectCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvHeaderConnection'] = ResolversParentTypes['PurchInvHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchInvHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvHeaderConnectionEdge'] = ResolversParentTypes['PurchInvHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchInvHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvHeaderDeleteResponse'] = ResolversParentTypes['PurchInvHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchInvHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvHeaderManyResult'] = ResolversParentTypes['PurchInvHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchInvHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvLine'] = ResolversParentTypes['PurchInvLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectUnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndirectCostPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiptLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ICCrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PmtDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProdOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FAPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalvageValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeprAcquisitionCost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InsuranceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedFANo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectCostAssignment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFixedAssetSetupGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFixedAssetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWorkshop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReRentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoutingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OperationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkCenterNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProdOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverheadRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RoutingReferenceNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExternalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvLineConnection'] = ResolversParentTypes['PurchInvLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchInvLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvLineConnectionEdge'] = ResolversParentTypes['PurchInvLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchInvLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvLineDeleteResponse'] = ResolversParentTypes['PurchInvLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchInvLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchInvLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchInvLineManyResult'] = ResolversParentTypes['PurchInvLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchInvLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptHeader'] = ResolversParentTypes['PurchRcptHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaserCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderAddressCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContactno?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBNoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectPurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptHeaderConnection'] = ResolversParentTypes['PurchRcptHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchRcptHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptHeaderConnectionEdge'] = ResolversParentTypes['PurchRcptHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchRcptHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptHeaderDeleteResponse'] = ResolversParentTypes['PurchRcptHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchRcptHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptHeaderManyResult'] = ResolversParentTypes['PurchRcptHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchRcptHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptLine'] = ResolversParentTypes['PurchRcptLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectUnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemRcptEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndirectCostPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyRcdNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ICItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProdOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FAPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalvageValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeprAcquisitionCost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InsuranceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedFANo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrderSalesNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrderSalesLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ItemChargeBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverReceiptQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverReceiptCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverReceiptCode2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoutingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OperationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkCenterNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProdOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverheadRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RoutingReferenceNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExternalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptLineConnection'] = ResolversParentTypes['PurchRcptLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchRcptLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptLineConnectionEdge'] = ResolversParentTypes['PurchRcptLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchRcptLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptLineDeleteResponse'] = ResolversParentTypes['PurchRcptLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchRcptLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchRcptLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchRcptLineManyResult'] = ResolversParentTypes['PurchRcptLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchRcptLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseHeader'] = ResolversParentTypes['PurchaseHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['PurchaseHeaderDocumentTypeEnum']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaserCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Receive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Invoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrintPostedDocuments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceivingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastReceivingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorInvoiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderAddressCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SendICDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompressPrepayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtCrMemoNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtPaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQueueStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueEntryID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncomingDocumentEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JournalTemplName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ARcdNotInvExVATLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtRcdNotInvoicedLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofArchivedVersions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocNoOccurrence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PartiallyInvoiced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CompletelyReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PostingfromWhseRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RequestedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorAuthorizationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnShipmentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Ship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastReturnShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PendingApprovals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBGrossInvoiceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBNoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBInwardRegistrated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBOCRNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBRecipientBankAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBOfwhichVATAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBInwardVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectPurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceTermNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMWSObjectCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectWarrantyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjWorkPurchFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalCollectionNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMPostedRentalShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPostedRentalCollNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMControlTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseHeaderConnection'] = ResolversParentTypes['PurchaseHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchaseHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseHeaderConnectionEdge'] = ResolversParentTypes['PurchaseHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchaseHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseHeaderDeleteResponse'] = ResolversParentTypes['PurchaseHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchaseHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseHeaderManyResult'] = ResolversParentTypes['PurchaseHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchaseHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseLine'] = ResolversParentTypes['PurchaseLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['PurchaseLineDocumentTypeEnum']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BuyfromVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoReceive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectUnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutstandingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyRcdNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtRcdNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityReceived?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiptLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseTax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutstandingAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtRcdNotInvoicedLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscAmounttoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATBaseAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentVATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentTaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtAmttoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtAmountInvInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtVATAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDifftoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDiffDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutstandingAmtExVATLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ARcdNotInvExVATLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtPmtDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobUnitPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTotalPriceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobLineDiscAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobPlanningLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobRemainingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobRemainingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnsDeferralStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ProdOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyRoundingPrecision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyRoundingPrecisionBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoiceBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoReceiveBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyRcdNotInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyReceivedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FAPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalvageValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeprAcquisitionCost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InsuranceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedFANo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SpecialOrderSalesNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrderSalesLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WhseOutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletelyReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequestedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedReceiptDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AllowItemChargeAssignment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtytoAssign?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyAssigned?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtytoShipBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyShippedNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RetQtyShpdNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnShpdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnShpdNotInvdLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyShippedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Subtype?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SubtypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CopiedFromPostedDoc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceCalculationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceCalculationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedDocCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverReceiptQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverReceiptCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverReceiptApprovalStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverReceiptApprovalStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NBAPreAssignedObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBIsDescription2?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBNotincludedincalc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBNotincludedinVATcalc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectCostAssignment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFixedAssetSetupGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFixedAssetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMORIGPurchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMWorkshop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReRentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAllocateManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAllocateManuModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMItemtoObjectFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RoutingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OperationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkCenterNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Finished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ProdOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverheadRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MPSOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PlanningFlexibility?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlanningFlexibilityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SafetyLeadTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoutingReferenceNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExternalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseLineConnection'] = ResolversParentTypes['PurchaseLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchaseLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseLineConnectionEdge'] = ResolversParentTypes['PurchaseLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PurchaseLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseLineDeleteResponse'] = ResolversParentTypes['PurchaseLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchaseLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseLineManyResult'] = ResolversParentTypes['PurchaseLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchaseLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Purchasing'] = ResolversParentTypes['Purchasing']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SpecialOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasingConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchasingConnection'] = ResolversParentTypes['PurchasingConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PurchasingConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasingConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchasingConnectionEdge'] = ResolversParentTypes['PurchasingConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Purchasing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasingDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchasingDeleteResponse'] = ResolversParentTypes['PurchasingDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Purchasing']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasingManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchasingManyResult'] = ResolversParentTypes['PurchasingManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Purchasing']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PushNotificationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PushNotificationResponse'] = ResolversParentTypes['PushNotificationResponse']> = {
  status?: Resolver<Maybe<ResolversTypes['PushNotificationStatus']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accessRequest?: Resolver<Maybe<ResolversTypes['AccessRequest']>, ParentType, ContextType, RequireFields<QueryAccessRequestArgs, never>>;
  accessRequestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryAccessRequestsCountArgs, never>>;
  accessRequestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessRequest']>>>, ParentType, ContextType, RequireFields<QueryAccessRequestsManyArgs, never>>;
  accessRequestsPage?: Resolver<Maybe<ResolversTypes['AccessRequestManyResult']>, ParentType, ContextType, RequireFields<QueryAccessRequestsPageArgs, never>>;
  accessRequestsConnection?: Resolver<Maybe<ResolversTypes['AccessRequestConnection']>, ParentType, ContextType, RequireFields<QueryAccessRequestsConnectionArgs, never>>;
  additionalItem?: Resolver<Maybe<ResolversTypes['AdditionalItem']>, ParentType, ContextType, RequireFields<QueryAdditionalItemArgs, never>>;
  additionalItemsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryAdditionalItemsCountArgs, never>>;
  additionalItemsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['AdditionalItem']>>>, ParentType, ContextType, RequireFields<QueryAdditionalItemsManyArgs, never>>;
  additionalItemsPage?: Resolver<Maybe<ResolversTypes['AdditionalItemManyResult']>, ParentType, ContextType, RequireFields<QueryAdditionalItemsPageArgs, never>>;
  additionalItemsConnection?: Resolver<Maybe<ResolversTypes['AdditionalItemConnection']>, ParentType, ContextType, RequireFields<QueryAdditionalItemsConnectionArgs, never>>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, RequireFields<QueryAddressArgs, never>>;
  addressesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryAddressesCountArgs, never>>;
  addressesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType, RequireFields<QueryAddressesManyArgs, never>>;
  addressesPage?: Resolver<Maybe<ResolversTypes['AddressManyResult']>, ParentType, ContextType, RequireFields<QueryAddressesPageArgs, never>>;
  addressesConnection?: Resolver<Maybe<ResolversTypes['AddressConnection']>, ParentType, ContextType, RequireFields<QueryAddressesConnectionArgs, never>>;
  agreement?: Resolver<Maybe<ResolversTypes['Agreement']>, ParentType, ContextType, RequireFields<QueryAgreementArgs, never>>;
  agreementsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryAgreementsCountArgs, never>>;
  agreementsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Agreement']>>>, ParentType, ContextType, RequireFields<QueryAgreementsManyArgs, never>>;
  agreementsPage?: Resolver<Maybe<ResolversTypes['AgreementManyResult']>, ParentType, ContextType, RequireFields<QueryAgreementsPageArgs, never>>;
  agreementsConnection?: Resolver<Maybe<ResolversTypes['AgreementConnection']>, ParentType, ContextType, RequireFields<QueryAgreementsConnectionArgs, never>>;
  annotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType, RequireFields<QueryAnnotationArgs, never>>;
  annotationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryAnnotationsCountArgs, never>>;
  annotationsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Annotation']>>>, ParentType, ContextType, RequireFields<QueryAnnotationsManyArgs, never>>;
  annotationsPage?: Resolver<Maybe<ResolversTypes['AnnotationManyResult']>, ParentType, ContextType, RequireFields<QueryAnnotationsPageArgs, never>>;
  annotationsConnection?: Resolver<Maybe<ResolversTypes['AnnotationConnection']>, ParentType, ContextType, RequireFields<QueryAnnotationsConnectionArgs, never>>;
  applicationModule?: Resolver<Maybe<ResolversTypes['ApplicationModule']>, ParentType, ContextType, RequireFields<QueryApplicationModuleArgs, never>>;
  applicationModulesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryApplicationModulesCountArgs, never>>;
  applicationModulesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicationModule']>>>, ParentType, ContextType, RequireFields<QueryApplicationModulesManyArgs, never>>;
  applicationModulesPage?: Resolver<Maybe<ResolversTypes['ApplicationModuleManyResult']>, ParentType, ContextType, RequireFields<QueryApplicationModulesPageArgs, never>>;
  applicationModulesConnection?: Resolver<Maybe<ResolversTypes['ApplicationModuleConnection']>, ParentType, ContextType, RequireFields<QueryApplicationModulesConnectionArgs, never>>;
  bcMappingTable?: Resolver<Maybe<ResolversTypes['BcMappingTable']>, ParentType, ContextType, RequireFields<QueryBcMappingTableArgs, never>>;
  bcMappingTablesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryBcMappingTablesCountArgs, never>>;
  bcMappingTablesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcMappingTable']>>>, ParentType, ContextType, RequireFields<QueryBcMappingTablesManyArgs, never>>;
  bcMappingTablesPage?: Resolver<Maybe<ResolversTypes['BcMappingTableManyResult']>, ParentType, ContextType, RequireFields<QueryBcMappingTablesPageArgs, never>>;
  bcMappingTablesConnection?: Resolver<Maybe<ResolversTypes['BcMappingTableConnection']>, ParentType, ContextType, RequireFields<QueryBcMappingTablesConnectionArgs, never>>;
  bcMappingField?: Resolver<Maybe<ResolversTypes['BcMappingField']>, ParentType, ContextType, RequireFields<QueryBcMappingFieldArgs, never>>;
  bcMappingFieldsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryBcMappingFieldsCountArgs, never>>;
  bcMappingFieldsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcMappingField']>>>, ParentType, ContextType, RequireFields<QueryBcMappingFieldsManyArgs, never>>;
  bcMappingFieldsPage?: Resolver<Maybe<ResolversTypes['BcMappingFieldManyResult']>, ParentType, ContextType, RequireFields<QueryBcMappingFieldsPageArgs, never>>;
  bcMappingFieldsConnection?: Resolver<Maybe<ResolversTypes['BcMappingFieldConnection']>, ParentType, ContextType, RequireFields<QueryBcMappingFieldsConnectionArgs, never>>;
  bcSettings?: Resolver<Maybe<ResolversTypes['BcSettings']>, ParentType, ContextType, RequireFields<QueryBcSettingsArgs, never>>;
  bcSettingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryBcSettingsCountArgs, never>>;
  bcSettingsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['BcSettings']>>>, ParentType, ContextType, RequireFields<QueryBcSettingsManyArgs, never>>;
  bcSettingsPage?: Resolver<Maybe<ResolversTypes['BcSettingsManyResult']>, ParentType, ContextType, RequireFields<QueryBcSettingsPageArgs, never>>;
  bcSettingsConnection?: Resolver<Maybe<ResolversTypes['BcSettingsConnection']>, ParentType, ContextType, RequireFields<QueryBcSettingsConnectionArgs, never>>;
  bulkItemGroup?: Resolver<Maybe<ResolversTypes['BulkItemGroup']>, ParentType, ContextType, RequireFields<QueryBulkItemGroupArgs, never>>;
  bulkItemGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryBulkItemGroupsCountArgs, never>>;
  bulkItemGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkItemGroup']>>>, ParentType, ContextType, RequireFields<QueryBulkItemGroupsManyArgs, never>>;
  bulkItemGroupsPage?: Resolver<Maybe<ResolversTypes['BulkItemGroupManyResult']>, ParentType, ContextType, RequireFields<QueryBulkItemGroupsPageArgs, never>>;
  bulkItemGroupsConnection?: Resolver<Maybe<ResolversTypes['BulkItemGroupConnection']>, ParentType, ContextType, RequireFields<QueryBulkItemGroupsConnectionArgs, never>>;
  causeOfAbsence?: Resolver<Maybe<ResolversTypes['CauseOfAbsence']>, ParentType, ContextType, RequireFields<QueryCauseOfAbsenceArgs, never>>;
  causeOfAbsencesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCauseOfAbsencesCountArgs, never>>;
  causeOfAbsencesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CauseOfAbsence']>>>, ParentType, ContextType, RequireFields<QueryCauseOfAbsencesManyArgs, never>>;
  causeOfAbsencesPage?: Resolver<Maybe<ResolversTypes['CauseOfAbsenceManyResult']>, ParentType, ContextType, RequireFields<QueryCauseOfAbsencesPageArgs, never>>;
  causeOfAbsencesConnection?: Resolver<Maybe<ResolversTypes['CauseOfAbsenceConnection']>, ParentType, ContextType, RequireFields<QueryCauseOfAbsencesConnectionArgs, never>>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<QueryCompanyArgs, never>>;
  companiesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCompaniesCountArgs, never>>;
  companiesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType, RequireFields<QueryCompaniesManyArgs, never>>;
  companiesPage?: Resolver<Maybe<ResolversTypes['CompanyManyResult']>, ParentType, ContextType, RequireFields<QueryCompaniesPageArgs, never>>;
  companiesConnection?: Resolver<Maybe<ResolversTypes['CompanyConnection']>, ParentType, ContextType, RequireFields<QueryCompaniesConnectionArgs, never>>;
  consignmentHeader?: Resolver<Maybe<ResolversTypes['ConsignmentHeader']>, ParentType, ContextType, RequireFields<QueryConsignmentHeaderArgs, never>>;
  consignmentHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryConsignmentHeadersCountArgs, never>>;
  consignmentHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsignmentHeader']>>>, ParentType, ContextType, RequireFields<QueryConsignmentHeadersManyArgs, never>>;
  consignmentHeadersPage?: Resolver<Maybe<ResolversTypes['ConsignmentHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryConsignmentHeadersPageArgs, never>>;
  consignmentHeadersConnection?: Resolver<Maybe<ResolversTypes['ConsignmentHeaderConnection']>, ParentType, ContextType, RequireFields<QueryConsignmentHeadersConnectionArgs, never>>;
  consignmentLine?: Resolver<Maybe<ResolversTypes['ConsignmentLine']>, ParentType, ContextType, RequireFields<QueryConsignmentLineArgs, never>>;
  consignmentLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryConsignmentLinesCountArgs, never>>;
  consignmentLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsignmentLine']>>>, ParentType, ContextType, RequireFields<QueryConsignmentLinesManyArgs, never>>;
  consignmentLinesPage?: Resolver<Maybe<ResolversTypes['ConsignmentLineManyResult']>, ParentType, ContextType, RequireFields<QueryConsignmentLinesPageArgs, never>>;
  consignmentLinesConnection?: Resolver<Maybe<ResolversTypes['ConsignmentLineConnection']>, ParentType, ContextType, RequireFields<QueryConsignmentLinesConnectionArgs, never>>;
  contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType, RequireFields<QueryContactArgs, never>>;
  contactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryContactsCountArgs, never>>;
  contactsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType, RequireFields<QueryContactsManyArgs, never>>;
  contactsPage?: Resolver<Maybe<ResolversTypes['ContactManyResult']>, ParentType, ContextType, RequireFields<QueryContactsPageArgs, never>>;
  contactsConnection?: Resolver<Maybe<ResolversTypes['ContactConnection']>, ParentType, ContextType, RequireFields<QueryContactsConnectionArgs, never>>;
  countryRegion?: Resolver<Maybe<ResolversTypes['CountryRegion']>, ParentType, ContextType, RequireFields<QueryCountryRegionArgs, never>>;
  countryRegionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCountryRegionsCountArgs, never>>;
  countryRegionsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryRegion']>>>, ParentType, ContextType, RequireFields<QueryCountryRegionsManyArgs, never>>;
  countryRegionsPage?: Resolver<Maybe<ResolversTypes['CountryRegionManyResult']>, ParentType, ContextType, RequireFields<QueryCountryRegionsPageArgs, never>>;
  countryRegionsConnection?: Resolver<Maybe<ResolversTypes['CountryRegionConnection']>, ParentType, ContextType, RequireFields<QueryCountryRegionsConnectionArgs, never>>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType, RequireFields<QueryCurrencyArgs, never>>;
  currenciesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCurrenciesCountArgs, never>>;
  currenciesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Currency']>>>, ParentType, ContextType, RequireFields<QueryCurrenciesManyArgs, never>>;
  currenciesPage?: Resolver<Maybe<ResolversTypes['CurrencyManyResult']>, ParentType, ContextType, RequireFields<QueryCurrenciesPageArgs, never>>;
  currenciesConnection?: Resolver<Maybe<ResolversTypes['CurrencyConnection']>, ParentType, ContextType, RequireFields<QueryCurrenciesConnectionArgs, never>>;
  currencyExchangeRate?: Resolver<Maybe<ResolversTypes['CurrencyExchangeRate']>, ParentType, ContextType, RequireFields<QueryCurrencyExchangeRateArgs, never>>;
  currencyExchangeRatesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCurrencyExchangeRatesCountArgs, never>>;
  currencyExchangeRatesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CurrencyExchangeRate']>>>, ParentType, ContextType, RequireFields<QueryCurrencyExchangeRatesManyArgs, never>>;
  currencyExchangeRatesPage?: Resolver<Maybe<ResolversTypes['CurrencyExchangeRateManyResult']>, ParentType, ContextType, RequireFields<QueryCurrencyExchangeRatesPageArgs, never>>;
  currencyExchangeRatesConnection?: Resolver<Maybe<ResolversTypes['CurrencyExchangeRateConnection']>, ParentType, ContextType, RequireFields<QueryCurrencyExchangeRatesConnectionArgs, never>>;
  customerDomain?: Resolver<Maybe<ResolversTypes['CustomerDomain']>, ParentType, ContextType, RequireFields<QueryCustomerDomainArgs, never>>;
  customerDomainsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCustomerDomainsCountArgs, never>>;
  customerDomainsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerDomain']>>>, ParentType, ContextType, RequireFields<QueryCustomerDomainsManyArgs, never>>;
  customerDomainsPage?: Resolver<Maybe<ResolversTypes['CustomerDomainManyResult']>, ParentType, ContextType, RequireFields<QueryCustomerDomainsPageArgs, never>>;
  customerDomainsConnection?: Resolver<Maybe<ResolversTypes['CustomerDomainConnection']>, ParentType, ContextType, RequireFields<QueryCustomerDomainsConnectionArgs, never>>;
  customerPostingGroup?: Resolver<Maybe<ResolversTypes['CustomerPostingGroup']>, ParentType, ContextType, RequireFields<QueryCustomerPostingGroupArgs, never>>;
  customerPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCustomerPostingGroupsCountArgs, never>>;
  customerPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryCustomerPostingGroupsManyArgs, never>>;
  customerPostingGroupsPage?: Resolver<Maybe<ResolversTypes['CustomerPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryCustomerPostingGroupsPageArgs, never>>;
  customerPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['CustomerPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryCustomerPostingGroupsConnectionArgs, never>>;
  customerProject?: Resolver<Maybe<ResolversTypes['CustomerProject']>, ParentType, ContextType, RequireFields<QueryCustomerProjectArgs, never>>;
  customerProjectsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCustomerProjectsCountArgs, never>>;
  customerProjectsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerProject']>>>, ParentType, ContextType, RequireFields<QueryCustomerProjectsManyArgs, never>>;
  customerProjectsPage?: Resolver<Maybe<ResolversTypes['CustomerProjectManyResult']>, ParentType, ContextType, RequireFields<QueryCustomerProjectsPageArgs, never>>;
  customerProjectsConnection?: Resolver<Maybe<ResolversTypes['CustomerProjectConnection']>, ParentType, ContextType, RequireFields<QueryCustomerProjectsConnectionArgs, never>>;
  customerSubProject?: Resolver<Maybe<ResolversTypes['CustomerSubProject']>, ParentType, ContextType, RequireFields<QueryCustomerSubProjectArgs, never>>;
  customerSubProjectsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryCustomerSubProjectsCountArgs, never>>;
  customerSubProjectsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerSubProject']>>>, ParentType, ContextType, RequireFields<QueryCustomerSubProjectsManyArgs, never>>;
  customerSubProjectsPage?: Resolver<Maybe<ResolversTypes['CustomerSubProjectManyResult']>, ParentType, ContextType, RequireFields<QueryCustomerSubProjectsPageArgs, never>>;
  customerSubProjectsConnection?: Resolver<Maybe<ResolversTypes['CustomerSubProjectConnection']>, ParentType, ContextType, RequireFields<QueryCustomerSubProjectsConnectionArgs, never>>;
  deliverytime?: Resolver<Maybe<ResolversTypes['Deliverytime']>, ParentType, ContextType, RequireFields<QueryDeliverytimeArgs, never>>;
  deliverytimesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDeliverytimesCountArgs, never>>;
  deliverytimesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Deliverytime']>>>, ParentType, ContextType, RequireFields<QueryDeliverytimesManyArgs, never>>;
  deliverytimesPage?: Resolver<Maybe<ResolversTypes['DeliverytimeManyResult']>, ParentType, ContextType, RequireFields<QueryDeliverytimesPageArgs, never>>;
  deliverytimesConnection?: Resolver<Maybe<ResolversTypes['DeliverytimeConnection']>, ParentType, ContextType, RequireFields<QueryDeliverytimesConnectionArgs, never>>;
  devopsProject?: Resolver<Maybe<ResolversTypes['DevopsProject']>, ParentType, ContextType, RequireFields<QueryDevopsProjectArgs, never>>;
  devopsProjectsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDevopsProjectsCountArgs, never>>;
  devopsProjectsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsProject']>>>, ParentType, ContextType, RequireFields<QueryDevopsProjectsManyArgs, never>>;
  devopsProjectsPage?: Resolver<Maybe<ResolversTypes['DevopsProjectManyResult']>, ParentType, ContextType, RequireFields<QueryDevopsProjectsPageArgs, never>>;
  devopsProjectsConnection?: Resolver<Maybe<ResolversTypes['DevopsProjectConnection']>, ParentType, ContextType, RequireFields<QueryDevopsProjectsConnectionArgs, never>>;
  devopsTeam?: Resolver<Maybe<ResolversTypes['DevopsTeam']>, ParentType, ContextType, RequireFields<QueryDevopsTeamArgs, never>>;
  devopsTeamsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDevopsTeamsCountArgs, never>>;
  devopsTeamsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsTeam']>>>, ParentType, ContextType, RequireFields<QueryDevopsTeamsManyArgs, never>>;
  devopsTeamsPage?: Resolver<Maybe<ResolversTypes['DevopsTeamManyResult']>, ParentType, ContextType, RequireFields<QueryDevopsTeamsPageArgs, never>>;
  devopsTeamsConnection?: Resolver<Maybe<ResolversTypes['DevopsTeamConnection']>, ParentType, ContextType, RequireFields<QueryDevopsTeamsConnectionArgs, never>>;
  devopsWorkItem?: Resolver<Maybe<ResolversTypes['DevopsWorkItem']>, ParentType, ContextType, RequireFields<QueryDevopsWorkItemArgs, never>>;
  devopsWorkItemsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDevopsWorkItemsCountArgs, never>>;
  devopsWorkItemsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DevopsWorkItem']>>>, ParentType, ContextType, RequireFields<QueryDevopsWorkItemsManyArgs, never>>;
  devopsWorkItemsPage?: Resolver<Maybe<ResolversTypes['DevopsWorkItemManyResult']>, ParentType, ContextType, RequireFields<QueryDevopsWorkItemsPageArgs, never>>;
  devopsWorkItemsConnection?: Resolver<Maybe<ResolversTypes['DevopsWorkItemConnection']>, ParentType, ContextType, RequireFields<QueryDevopsWorkItemsConnectionArgs, never>>;
  dimension?: Resolver<Maybe<ResolversTypes['Dimension']>, ParentType, ContextType, RequireFields<QueryDimensionArgs, never>>;
  dimensionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDimensionsCountArgs, never>>;
  dimensionsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Dimension']>>>, ParentType, ContextType, RequireFields<QueryDimensionsManyArgs, never>>;
  dimensionsPage?: Resolver<Maybe<ResolversTypes['DimensionManyResult']>, ParentType, ContextType, RequireFields<QueryDimensionsPageArgs, never>>;
  dimensionsConnection?: Resolver<Maybe<ResolversTypes['DimensionConnection']>, ParentType, ContextType, RequireFields<QueryDimensionsConnectionArgs, never>>;
  dimensionValue?: Resolver<Maybe<ResolversTypes['DimensionValue']>, ParentType, ContextType, RequireFields<QueryDimensionValueArgs, never>>;
  dimensionValuesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDimensionValuesCountArgs, never>>;
  dimensionValuesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DimensionValue']>>>, ParentType, ContextType, RequireFields<QueryDimensionValuesManyArgs, never>>;
  dimensionValuesPage?: Resolver<Maybe<ResolversTypes['DimensionValueManyResult']>, ParentType, ContextType, RequireFields<QueryDimensionValuesPageArgs, never>>;
  dimensionValuesConnection?: Resolver<Maybe<ResolversTypes['DimensionValueConnection']>, ParentType, ContextType, RequireFields<QueryDimensionValuesConnectionArgs, never>>;
  discussionPost?: Resolver<Maybe<ResolversTypes['DiscussionPost']>, ParentType, ContextType, RequireFields<QueryDiscussionPostArgs, never>>;
  discussionPostsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDiscussionPostsCountArgs, never>>;
  discussionPostsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DiscussionPost']>>>, ParentType, ContextType, RequireFields<QueryDiscussionPostsManyArgs, never>>;
  discussionPostsPage?: Resolver<Maybe<ResolversTypes['DiscussionPostManyResult']>, ParentType, ContextType, RequireFields<QueryDiscussionPostsPageArgs, never>>;
  discussionPostsConnection?: Resolver<Maybe<ResolversTypes['DiscussionPostConnection']>, ParentType, ContextType, RequireFields<QueryDiscussionPostsConnectionArgs, never>>;
  documentClassification?: Resolver<Maybe<ResolversTypes['DocumentClassification']>, ParentType, ContextType, RequireFields<QueryDocumentClassificationArgs, never>>;
  documentClassificationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDocumentClassificationsCountArgs, never>>;
  documentClassificationsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentClassification']>>>, ParentType, ContextType, RequireFields<QueryDocumentClassificationsManyArgs, never>>;
  documentClassificationsPage?: Resolver<Maybe<ResolversTypes['DocumentClassificationManyResult']>, ParentType, ContextType, RequireFields<QueryDocumentClassificationsPageArgs, never>>;
  documentClassificationsConnection?: Resolver<Maybe<ResolversTypes['DocumentClassificationConnection']>, ParentType, ContextType, RequireFields<QueryDocumentClassificationsConnectionArgs, never>>;
  documentRequest?: Resolver<Maybe<ResolversTypes['DocumentRequest']>, ParentType, ContextType, RequireFields<QueryDocumentRequestArgs, never>>;
  documentRequestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDocumentRequestsCountArgs, never>>;
  documentRequestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentRequest']>>>, ParentType, ContextType, RequireFields<QueryDocumentRequestsManyArgs, never>>;
  documentRequestsPage?: Resolver<Maybe<ResolversTypes['DocumentRequestManyResult']>, ParentType, ContextType, RequireFields<QueryDocumentRequestsPageArgs, never>>;
  documentRequestsConnection?: Resolver<Maybe<ResolversTypes['DocumentRequestConnection']>, ParentType, ContextType, RequireFields<QueryDocumentRequestsConnectionArgs, never>>;
  domainSettings?: Resolver<Maybe<ResolversTypes['DomainSettings']>, ParentType, ContextType, RequireFields<QueryDomainSettingsArgs, never>>;
  domainSettingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDomainSettingsCountArgs, never>>;
  domainSettingsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainSettings']>>>, ParentType, ContextType, RequireFields<QueryDomainSettingsManyArgs, never>>;
  domainSettingsPage?: Resolver<Maybe<ResolversTypes['DomainSettingsManyResult']>, ParentType, ContextType, RequireFields<QueryDomainSettingsPageArgs, never>>;
  domainSettingsConnection?: Resolver<Maybe<ResolversTypes['DomainSettingsConnection']>, ParentType, ContextType, RequireFields<QueryDomainSettingsConnectionArgs, never>>;
  eqmBaseCalendar?: Resolver<Maybe<ResolversTypes['EqmBaseCalendar']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarArgs, never>>;
  eqmBaseCalendarsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarsCountArgs, never>>;
  eqmBaseCalendarsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmBaseCalendar']>>>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarsManyArgs, never>>;
  eqmBaseCalendarsPage?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarManyResult']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarsPageArgs, never>>;
  eqmBaseCalendarsConnection?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarConnection']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarsConnectionArgs, never>>;
  eqmBaseCalendarChange?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarChange']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarChangeArgs, never>>;
  eqmBaseCalendarChangesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarChangesCountArgs, never>>;
  eqmBaseCalendarChangesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmBaseCalendarChange']>>>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarChangesManyArgs, never>>;
  eqmBaseCalendarChangesPage?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarChangeManyResult']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarChangesPageArgs, never>>;
  eqmBaseCalendarChangesConnection?: Resolver<Maybe<ResolversTypes['EqmBaseCalendarChangeConnection']>, ParentType, ContextType, RequireFields<QueryEqmBaseCalendarChangesConnectionArgs, never>>;
  eqmCollectionReturnCharges?: Resolver<Maybe<ResolversTypes['EqmCollectionReturnCharges']>, ParentType, ContextType, RequireFields<QueryEqmCollectionReturnChargesArgs, never>>;
  eqmCollectionReturnChargesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmCollectionReturnChargesCountArgs, never>>;
  eqmCollectionReturnChargesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCollectionReturnCharges']>>>, ParentType, ContextType, RequireFields<QueryEqmCollectionReturnChargesManyArgs, never>>;
  eqmCollectionReturnChargesPage?: Resolver<Maybe<ResolversTypes['EqmCollectionReturnChargesManyResult']>, ParentType, ContextType, RequireFields<QueryEqmCollectionReturnChargesPageArgs, never>>;
  eqmCollectionReturnChargesConnection?: Resolver<Maybe<ResolversTypes['EqmCollectionReturnChargesConnection']>, ParentType, ContextType, RequireFields<QueryEqmCollectionReturnChargesConnectionArgs, never>>;
  eqmCommentLine?: Resolver<Maybe<ResolversTypes['EqmCommentLine']>, ParentType, ContextType, RequireFields<QueryEqmCommentLineArgs, never>>;
  eqmCommentLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmCommentLinesCountArgs, never>>;
  eqmCommentLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCommentLine']>>>, ParentType, ContextType, RequireFields<QueryEqmCommentLinesManyArgs, never>>;
  eqmCommentLinesPage?: Resolver<Maybe<ResolversTypes['EqmCommentLineManyResult']>, ParentType, ContextType, RequireFields<QueryEqmCommentLinesPageArgs, never>>;
  eqmCommentLinesConnection?: Resolver<Maybe<ResolversTypes['EqmCommentLineConnection']>, ParentType, ContextType, RequireFields<QueryEqmCommentLinesConnectionArgs, never>>;
  eqmCustomizedCalendarChange?: Resolver<Maybe<ResolversTypes['EqmCustomizedCalendarChange']>, ParentType, ContextType, RequireFields<QueryEqmCustomizedCalendarChangeArgs, never>>;
  eqmCustomizedCalendarChangesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmCustomizedCalendarChangesCountArgs, never>>;
  eqmCustomizedCalendarChangesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmCustomizedCalendarChange']>>>, ParentType, ContextType, RequireFields<QueryEqmCustomizedCalendarChangesManyArgs, never>>;
  eqmCustomizedCalendarChangesPage?: Resolver<Maybe<ResolversTypes['EqmCustomizedCalendarChangeManyResult']>, ParentType, ContextType, RequireFields<QueryEqmCustomizedCalendarChangesPageArgs, never>>;
  eqmCustomizedCalendarChangesConnection?: Resolver<Maybe<ResolversTypes['EqmCustomizedCalendarChangeConnection']>, ParentType, ContextType, RequireFields<QueryEqmCustomizedCalendarChangesConnectionArgs, never>>;
  eqmFncTestReturnCharges?: Resolver<Maybe<ResolversTypes['EqmFncTestReturnCharges']>, ParentType, ContextType, RequireFields<QueryEqmFncTestReturnChargesArgs, never>>;
  eqmFncTestReturnChargesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmFncTestReturnChargesCountArgs, never>>;
  eqmFncTestReturnChargesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmFncTestReturnCharges']>>>, ParentType, ContextType, RequireFields<QueryEqmFncTestReturnChargesManyArgs, never>>;
  eqmFncTestReturnChargesPage?: Resolver<Maybe<ResolversTypes['EqmFncTestReturnChargesManyResult']>, ParentType, ContextType, RequireFields<QueryEqmFncTestReturnChargesPageArgs, never>>;
  eqmFncTestReturnChargesConnection?: Resolver<Maybe<ResolversTypes['EqmFncTestReturnChargesConnection']>, ParentType, ContextType, RequireFields<QueryEqmFncTestReturnChargesConnectionArgs, never>>;
  eqmLocCostCenterComb?: Resolver<Maybe<ResolversTypes['EqmLocCostCenterComb']>, ParentType, ContextType, RequireFields<QueryEqmLocCostCenterCombArgs, never>>;
  eqmLocCostCenterCombsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmLocCostCenterCombsCountArgs, never>>;
  eqmLocCostCenterCombsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmLocCostCenterComb']>>>, ParentType, ContextType, RequireFields<QueryEqmLocCostCenterCombsManyArgs, never>>;
  eqmLocCostCenterCombsPage?: Resolver<Maybe<ResolversTypes['EqmLocCostCenterCombManyResult']>, ParentType, ContextType, RequireFields<QueryEqmLocCostCenterCombsPageArgs, never>>;
  eqmLocCostCenterCombsConnection?: Resolver<Maybe<ResolversTypes['EqmLocCostCenterCombConnection']>, ParentType, ContextType, RequireFields<QueryEqmLocCostCenterCombsConnectionArgs, never>>;
  eqmManufacturer?: Resolver<Maybe<ResolversTypes['EqmManufacturer']>, ParentType, ContextType, RequireFields<QueryEqmManufacturerArgs, never>>;
  eqmManufacturersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmManufacturersCountArgs, never>>;
  eqmManufacturersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmManufacturer']>>>, ParentType, ContextType, RequireFields<QueryEqmManufacturersManyArgs, never>>;
  eqmManufacturersPage?: Resolver<Maybe<ResolversTypes['EqmManufacturerManyResult']>, ParentType, ContextType, RequireFields<QueryEqmManufacturersPageArgs, never>>;
  eqmManufacturersConnection?: Resolver<Maybe<ResolversTypes['EqmManufacturerConnection']>, ParentType, ContextType, RequireFields<QueryEqmManufacturersConnectionArgs, never>>;
  eqmManufacturerModel?: Resolver<Maybe<ResolversTypes['EqmManufacturerModel']>, ParentType, ContextType, RequireFields<QueryEqmManufacturerModelArgs, never>>;
  eqmManufacturerModelsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmManufacturerModelsCountArgs, never>>;
  eqmManufacturerModelsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmManufacturerModel']>>>, ParentType, ContextType, RequireFields<QueryEqmManufacturerModelsManyArgs, never>>;
  eqmManufacturerModelsPage?: Resolver<Maybe<ResolversTypes['EqmManufacturerModelManyResult']>, ParentType, ContextType, RequireFields<QueryEqmManufacturerModelsPageArgs, never>>;
  eqmManufacturerModelsConnection?: Resolver<Maybe<ResolversTypes['EqmManufacturerModelConnection']>, ParentType, ContextType, RequireFields<QueryEqmManufacturerModelsConnectionArgs, never>>;
  eqmObjectCardFlow?: Resolver<Maybe<ResolversTypes['EqmObjectCardFlow']>, ParentType, ContextType, RequireFields<QueryEqmObjectCardFlowArgs, never>>;
  eqmObjectCardFlowsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectCardFlowsCountArgs, never>>;
  eqmObjectCardFlowsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCardFlow']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectCardFlowsManyArgs, never>>;
  eqmObjectCardFlowsPage?: Resolver<Maybe<ResolversTypes['EqmObjectCardFlowManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectCardFlowsPageArgs, never>>;
  eqmObjectCardFlowsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectCardFlowConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectCardFlowsConnectionArgs, never>>;
  eqmObjectComponents?: Resolver<Maybe<ResolversTypes['EqmObjectComponents']>, ParentType, ContextType, RequireFields<QueryEqmObjectComponentsArgs, never>>;
  eqmObjectComponentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectComponentsCountArgs, never>>;
  eqmObjectComponentsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectComponents']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectComponentsManyArgs, never>>;
  eqmObjectComponentsPage?: Resolver<Maybe<ResolversTypes['EqmObjectComponentsManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectComponentsPageArgs, never>>;
  eqmObjectComponentsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectComponentsConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectComponentsConnectionArgs, never>>;
  eqmObjectCountHeader?: Resolver<Maybe<ResolversTypes['EqmObjectCountHeader']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountHeaderArgs, never>>;
  eqmObjectCountHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountHeadersCountArgs, never>>;
  eqmObjectCountHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCountHeader']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectCountHeadersManyArgs, never>>;
  eqmObjectCountHeadersPage?: Resolver<Maybe<ResolversTypes['EqmObjectCountHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountHeadersPageArgs, never>>;
  eqmObjectCountHeadersConnection?: Resolver<Maybe<ResolversTypes['EqmObjectCountHeaderConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountHeadersConnectionArgs, never>>;
  eqmObjectCountLine?: Resolver<Maybe<ResolversTypes['EqmObjectCountLine']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountLineArgs, never>>;
  eqmObjectCountLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountLinesCountArgs, never>>;
  eqmObjectCountLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectCountLine']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectCountLinesManyArgs, never>>;
  eqmObjectCountLinesPage?: Resolver<Maybe<ResolversTypes['EqmObjectCountLineManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountLinesPageArgs, never>>;
  eqmObjectCountLinesConnection?: Resolver<Maybe<ResolversTypes['EqmObjectCountLineConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectCountLinesConnectionArgs, never>>;
  eqmObjectGroupCard?: Resolver<Maybe<ResolversTypes['EqmObjectGroupCard']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupCardArgs, never>>;
  eqmObjectGroupCardsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupCardsCountArgs, never>>;
  eqmObjectGroupCardsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectGroupCard']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupCardsManyArgs, never>>;
  eqmObjectGroupCardsPage?: Resolver<Maybe<ResolversTypes['EqmObjectGroupCardManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupCardsPageArgs, never>>;
  eqmObjectGroupCardsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectGroupCardConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupCardsConnectionArgs, never>>;
  eqmObjectGroupFlow?: Resolver<Maybe<ResolversTypes['EqmObjectGroupFlow']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupFlowArgs, never>>;
  eqmObjectGroupFlowsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupFlowsCountArgs, never>>;
  eqmObjectGroupFlowsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectGroupFlow']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupFlowsManyArgs, never>>;
  eqmObjectGroupFlowsPage?: Resolver<Maybe<ResolversTypes['EqmObjectGroupFlowManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupFlowsPageArgs, never>>;
  eqmObjectGroupFlowsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectGroupFlowConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectGroupFlowsConnectionArgs, never>>;
  eqmObjectServiceInterval?: Resolver<Maybe<ResolversTypes['EqmObjectServiceInterval']>, ParentType, ContextType, RequireFields<QueryEqmObjectServiceIntervalArgs, never>>;
  eqmObjectServiceIntervalsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectServiceIntervalsCountArgs, never>>;
  eqmObjectServiceIntervalsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectServiceInterval']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectServiceIntervalsManyArgs, never>>;
  eqmObjectServiceIntervalsPage?: Resolver<Maybe<ResolversTypes['EqmObjectServiceIntervalManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectServiceIntervalsPageArgs, never>>;
  eqmObjectServiceIntervalsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectServiceIntervalConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectServiceIntervalsConnectionArgs, never>>;
  eqmObjectSrvIntervalAgg?: Resolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAgg']>, ParentType, ContextType, RequireFields<QueryEqmObjectSrvIntervalAggArgs, never>>;
  eqmObjectSrvIntervalAggsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectSrvIntervalAggsCountArgs, never>>;
  eqmObjectSrvIntervalAggsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectSrvIntervalAgg']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectSrvIntervalAggsManyArgs, never>>;
  eqmObjectSrvIntervalAggsPage?: Resolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAggManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectSrvIntervalAggsPageArgs, never>>;
  eqmObjectSrvIntervalAggsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAggConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectSrvIntervalAggsConnectionArgs, never>>;
  eqmObjectStatus?: Resolver<Maybe<ResolversTypes['EqmObjectStatus']>, ParentType, ContextType, RequireFields<QueryEqmObjectStatusArgs, never>>;
  eqmObjectStatusesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectStatusesCountArgs, never>>;
  eqmObjectStatusesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectStatus']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectStatusesManyArgs, never>>;
  eqmObjectStatusesPage?: Resolver<Maybe<ResolversTypes['EqmObjectStatusManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectStatusesPageArgs, never>>;
  eqmObjectStatusesConnection?: Resolver<Maybe<ResolversTypes['EqmObjectStatusConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectStatusesConnectionArgs, never>>;
  eqmObjectTypeCard?: Resolver<Maybe<ResolversTypes['EqmObjectTypeCard']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeCardArgs, never>>;
  eqmObjectTypeCardsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeCardsCountArgs, never>>;
  eqmObjectTypeCardsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeCard']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeCardsManyArgs, never>>;
  eqmObjectTypeCardsPage?: Resolver<Maybe<ResolversTypes['EqmObjectTypeCardManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeCardsPageArgs, never>>;
  eqmObjectTypeCardsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectTypeCardConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeCardsConnectionArgs, never>>;
  eqmObjectTypeComponents?: Resolver<Maybe<ResolversTypes['EqmObjectTypeComponents']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeComponentsArgs, never>>;
  eqmObjectTypeComponentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeComponentsCountArgs, never>>;
  eqmObjectTypeComponentsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeComponents']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeComponentsManyArgs, never>>;
  eqmObjectTypeComponentsPage?: Resolver<Maybe<ResolversTypes['EqmObjectTypeComponentsManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeComponentsPageArgs, never>>;
  eqmObjectTypeComponentsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectTypeComponentsConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeComponentsConnectionArgs, never>>;
  eqmObjectTypeFlow?: Resolver<Maybe<ResolversTypes['EqmObjectTypeFlow']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeFlowArgs, never>>;
  eqmObjectTypeFlowsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeFlowsCountArgs, never>>;
  eqmObjectTypeFlowsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeFlow']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeFlowsManyArgs, never>>;
  eqmObjectTypeFlowsPage?: Resolver<Maybe<ResolversTypes['EqmObjectTypeFlowManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeFlowsPageArgs, never>>;
  eqmObjectTypeFlowsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectTypeFlowConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeFlowsConnectionArgs, never>>;
  eqmObjectTypePriceTerm?: Resolver<Maybe<ResolversTypes['EqmObjectTypePriceTerm']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypePriceTermArgs, never>>;
  eqmObjectTypePriceTermsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypePriceTermsCountArgs, never>>;
  eqmObjectTypePriceTermsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypePriceTerm']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectTypePriceTermsManyArgs, never>>;
  eqmObjectTypePriceTermsPage?: Resolver<Maybe<ResolversTypes['EqmObjectTypePriceTermManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypePriceTermsPageArgs, never>>;
  eqmObjectTypePriceTermsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectTypePriceTermConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypePriceTermsConnectionArgs, never>>;
  eqmObjectTypeServiceInterval?: Resolver<Maybe<ResolversTypes['EqmObjectTypeServiceInterval']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeServiceIntervalArgs, never>>;
  eqmObjectTypeServiceIntervalsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeServiceIntervalsCountArgs, never>>;
  eqmObjectTypeServiceIntervalsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmObjectTypeServiceInterval']>>>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeServiceIntervalsManyArgs, never>>;
  eqmObjectTypeServiceIntervalsPage?: Resolver<Maybe<ResolversTypes['EqmObjectTypeServiceIntervalManyResult']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeServiceIntervalsPageArgs, never>>;
  eqmObjectTypeServiceIntervalsConnection?: Resolver<Maybe<ResolversTypes['EqmObjectTypeServiceIntervalConnection']>, ParentType, ContextType, RequireFields<QueryEqmObjectTypeServiceIntervalsConnectionArgs, never>>;
  eqmRentalGroup?: Resolver<Maybe<ResolversTypes['EqmRentalGroup']>, ParentType, ContextType, RequireFields<QueryEqmRentalGroupArgs, never>>;
  eqmRentalGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmRentalGroupsCountArgs, never>>;
  eqmRentalGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalGroup']>>>, ParentType, ContextType, RequireFields<QueryEqmRentalGroupsManyArgs, never>>;
  eqmRentalGroupsPage?: Resolver<Maybe<ResolversTypes['EqmRentalGroupManyResult']>, ParentType, ContextType, RequireFields<QueryEqmRentalGroupsPageArgs, never>>;
  eqmRentalGroupsConnection?: Resolver<Maybe<ResolversTypes['EqmRentalGroupConnection']>, ParentType, ContextType, RequireFields<QueryEqmRentalGroupsConnectionArgs, never>>;
  eqmRentalKIT?: Resolver<Maybe<ResolversTypes['EqmRentalKIT']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitArgs, never>>;
  eqmRentalKITSCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitsCountArgs, never>>;
  eqmRentalKITSMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKIT']>>>, ParentType, ContextType, RequireFields<QueryEqmRentalKitsManyArgs, never>>;
  eqmRentalKITSPage?: Resolver<Maybe<ResolversTypes['EqmRentalKITManyResult']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitsPageArgs, never>>;
  eqmRentalKITSConnection?: Resolver<Maybe<ResolversTypes['EqmRentalKITConnection']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitsConnectionArgs, never>>;
  eqmRentalKITComponents?: Resolver<Maybe<ResolversTypes['EqmRentalKITComponents']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitComponentsArgs, never>>;
  eqmRentalKITComponentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitComponentsCountArgs, never>>;
  eqmRentalKITComponentsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKITComponents']>>>, ParentType, ContextType, RequireFields<QueryEqmRentalKitComponentsManyArgs, never>>;
  eqmRentalKITComponentsPage?: Resolver<Maybe<ResolversTypes['EqmRentalKITComponentsManyResult']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitComponentsPageArgs, never>>;
  eqmRentalKITComponentsConnection?: Resolver<Maybe<ResolversTypes['EqmRentalKITComponentsConnection']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitComponentsConnectionArgs, never>>;
  eqmRentalKITLines?: Resolver<Maybe<ResolversTypes['EqmRentalKITLines']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitLinesArgs, never>>;
  eqmRentalKITLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitLinesCountArgs, never>>;
  eqmRentalKITLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalKITLines']>>>, ParentType, ContextType, RequireFields<QueryEqmRentalKitLinesManyArgs, never>>;
  eqmRentalKITLinesPage?: Resolver<Maybe<ResolversTypes['EqmRentalKITLinesManyResult']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitLinesPageArgs, never>>;
  eqmRentalKITLinesConnection?: Resolver<Maybe<ResolversTypes['EqmRentalKITLinesConnection']>, ParentType, ContextType, RequireFields<QueryEqmRentalKitLinesConnectionArgs, never>>;
  eqmRentalLineDiscount?: Resolver<Maybe<ResolversTypes['EqmRentalLineDiscount']>, ParentType, ContextType, RequireFields<QueryEqmRentalLineDiscountArgs, never>>;
  eqmRentalLineDiscountsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmRentalLineDiscountsCountArgs, never>>;
  eqmRentalLineDiscountsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalLineDiscount']>>>, ParentType, ContextType, RequireFields<QueryEqmRentalLineDiscountsManyArgs, never>>;
  eqmRentalLineDiscountsPage?: Resolver<Maybe<ResolversTypes['EqmRentalLineDiscountManyResult']>, ParentType, ContextType, RequireFields<QueryEqmRentalLineDiscountsPageArgs, never>>;
  eqmRentalLineDiscountsConnection?: Resolver<Maybe<ResolversTypes['EqmRentalLineDiscountConnection']>, ParentType, ContextType, RequireFields<QueryEqmRentalLineDiscountsConnectionArgs, never>>;
  eqmRentalPrice?: Resolver<Maybe<ResolversTypes['EqmRentalPrice']>, ParentType, ContextType, RequireFields<QueryEqmRentalPriceArgs, never>>;
  eqmRentalPricesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmRentalPricesCountArgs, never>>;
  eqmRentalPricesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmRentalPrice']>>>, ParentType, ContextType, RequireFields<QueryEqmRentalPricesManyArgs, never>>;
  eqmRentalPricesPage?: Resolver<Maybe<ResolversTypes['EqmRentalPriceManyResult']>, ParentType, ContextType, RequireFields<QueryEqmRentalPricesPageArgs, never>>;
  eqmRentalPricesConnection?: Resolver<Maybe<ResolversTypes['EqmRentalPriceConnection']>, ParentType, ContextType, RequireFields<QueryEqmRentalPricesConnectionArgs, never>>;
  eqmReRentCard?: Resolver<Maybe<ResolversTypes['EqmReRentCard']>, ParentType, ContextType, RequireFields<QueryEqmReRentCardArgs, never>>;
  eqmReRentCardsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmReRentCardsCountArgs, never>>;
  eqmReRentCardsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmReRentCard']>>>, ParentType, ContextType, RequireFields<QueryEqmReRentCardsManyArgs, never>>;
  eqmReRentCardsPage?: Resolver<Maybe<ResolversTypes['EqmReRentCardManyResult']>, ParentType, ContextType, RequireFields<QueryEqmReRentCardsPageArgs, never>>;
  eqmReRentCardsConnection?: Resolver<Maybe<ResolversTypes['EqmReRentCardConnection']>, ParentType, ContextType, RequireFields<QueryEqmReRentCardsConnectionArgs, never>>;
  eqmReturnCharges?: Resolver<Maybe<ResolversTypes['EqmReturnCharges']>, ParentType, ContextType, RequireFields<QueryEqmReturnChargesArgs, never>>;
  eqmReturnChargesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmReturnChargesCountArgs, never>>;
  eqmReturnChargesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmReturnCharges']>>>, ParentType, ContextType, RequireFields<QueryEqmReturnChargesManyArgs, never>>;
  eqmReturnChargesPage?: Resolver<Maybe<ResolversTypes['EqmReturnChargesManyResult']>, ParentType, ContextType, RequireFields<QueryEqmReturnChargesPageArgs, never>>;
  eqmReturnChargesConnection?: Resolver<Maybe<ResolversTypes['EqmReturnChargesConnection']>, ParentType, ContextType, RequireFields<QueryEqmReturnChargesConnectionArgs, never>>;
  eqmServiceCard?: Resolver<Maybe<ResolversTypes['EqmServiceCard']>, ParentType, ContextType, RequireFields<QueryEqmServiceCardArgs, never>>;
  eqmServiceCardsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmServiceCardsCountArgs, never>>;
  eqmServiceCardsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmServiceCard']>>>, ParentType, ContextType, RequireFields<QueryEqmServiceCardsManyArgs, never>>;
  eqmServiceCardsPage?: Resolver<Maybe<ResolversTypes['EqmServiceCardManyResult']>, ParentType, ContextType, RequireFields<QueryEqmServiceCardsPageArgs, never>>;
  eqmServiceCardsConnection?: Resolver<Maybe<ResolversTypes['EqmServiceCardConnection']>, ParentType, ContextType, RequireFields<QueryEqmServiceCardsConnectionArgs, never>>;
  eqmServiceType?: Resolver<Maybe<ResolversTypes['EqmServiceType']>, ParentType, ContextType, RequireFields<QueryEqmServiceTypeArgs, never>>;
  eqmServiceTypesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmServiceTypesCountArgs, never>>;
  eqmServiceTypesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmServiceType']>>>, ParentType, ContextType, RequireFields<QueryEqmServiceTypesManyArgs, never>>;
  eqmServiceTypesPage?: Resolver<Maybe<ResolversTypes['EqmServiceTypeManyResult']>, ParentType, ContextType, RequireFields<QueryEqmServiceTypesPageArgs, never>>;
  eqmServiceTypesConnection?: Resolver<Maybe<ResolversTypes['EqmServiceTypeConnection']>, ParentType, ContextType, RequireFields<QueryEqmServiceTypesConnectionArgs, never>>;
  eqmTypeFunctionalTest?: Resolver<Maybe<ResolversTypes['EqmTypeFunctionalTest']>, ParentType, ContextType, RequireFields<QueryEqmTypeFunctionalTestArgs, never>>;
  eqmTypeFunctionalTestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmTypeFunctionalTestsCountArgs, never>>;
  eqmTypeFunctionalTestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTypeFunctionalTest']>>>, ParentType, ContextType, RequireFields<QueryEqmTypeFunctionalTestsManyArgs, never>>;
  eqmTypeFunctionalTestsPage?: Resolver<Maybe<ResolversTypes['EqmTypeFunctionalTestManyResult']>, ParentType, ContextType, RequireFields<QueryEqmTypeFunctionalTestsPageArgs, never>>;
  eqmTypeFunctionalTestsConnection?: Resolver<Maybe<ResolversTypes['EqmTypeFunctionalTestConnection']>, ParentType, ContextType, RequireFields<QueryEqmTypeFunctionalTestsConnectionArgs, never>>;
  eqmWorkHeader?: Resolver<Maybe<ResolversTypes['EqmWorkHeader']>, ParentType, ContextType, RequireFields<QueryEqmWorkHeaderArgs, never>>;
  eqmWorkHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmWorkHeadersCountArgs, never>>;
  eqmWorkHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmWorkHeader']>>>, ParentType, ContextType, RequireFields<QueryEqmWorkHeadersManyArgs, never>>;
  eqmWorkHeadersPage?: Resolver<Maybe<ResolversTypes['EqmWorkHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryEqmWorkHeadersPageArgs, never>>;
  eqmWorkHeadersConnection?: Resolver<Maybe<ResolversTypes['EqmWorkHeaderConnection']>, ParentType, ContextType, RequireFields<QueryEqmWorkHeadersConnectionArgs, never>>;
  eqmWorkLine?: Resolver<Maybe<ResolversTypes['EqmWorkLine']>, ParentType, ContextType, RequireFields<QueryEqmWorkLineArgs, never>>;
  eqmWorkLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmWorkLinesCountArgs, never>>;
  eqmWorkLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmWorkLine']>>>, ParentType, ContextType, RequireFields<QueryEqmWorkLinesManyArgs, never>>;
  eqmWorkLinesPage?: Resolver<Maybe<ResolversTypes['EqmWorkLineManyResult']>, ParentType, ContextType, RequireFields<QueryEqmWorkLinesPageArgs, never>>;
  eqmWorkLinesConnection?: Resolver<Maybe<ResolversTypes['EqmWorkLineConnection']>, ParentType, ContextType, RequireFields<QueryEqmWorkLinesConnectionArgs, never>>;
  eqmTransferHeader?: Resolver<Maybe<ResolversTypes['EqmTransferHeader']>, ParentType, ContextType, RequireFields<QueryEqmTransferHeaderArgs, never>>;
  eqmTransferHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmTransferHeadersCountArgs, never>>;
  eqmTransferHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTransferHeader']>>>, ParentType, ContextType, RequireFields<QueryEqmTransferHeadersManyArgs, never>>;
  eqmTransferHeadersPage?: Resolver<Maybe<ResolversTypes['EqmTransferHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryEqmTransferHeadersPageArgs, never>>;
  eqmTransferHeadersConnection?: Resolver<Maybe<ResolversTypes['EqmTransferHeaderConnection']>, ParentType, ContextType, RequireFields<QueryEqmTransferHeadersConnectionArgs, never>>;
  eqmTransferLine?: Resolver<Maybe<ResolversTypes['EqmTransferLine']>, ParentType, ContextType, RequireFields<QueryEqmTransferLineArgs, never>>;
  eqmTransferLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEqmTransferLinesCountArgs, never>>;
  eqmTransferLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['EqmTransferLine']>>>, ParentType, ContextType, RequireFields<QueryEqmTransferLinesManyArgs, never>>;
  eqmTransferLinesPage?: Resolver<Maybe<ResolversTypes['EqmTransferLineManyResult']>, ParentType, ContextType, RequireFields<QueryEqmTransferLinesPageArgs, never>>;
  eqmTransferLinesConnection?: Resolver<Maybe<ResolversTypes['EqmTransferLineConnection']>, ParentType, ContextType, RequireFields<QueryEqmTransferLinesConnectionArgs, never>>;
  errand?: Resolver<Maybe<ResolversTypes['Errand']>, ParentType, ContextType, RequireFields<QueryErrandArgs, never>>;
  errandsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryErrandsCountArgs, never>>;
  errandsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Errand']>>>, ParentType, ContextType, RequireFields<QueryErrandsManyArgs, never>>;
  errandsPage?: Resolver<Maybe<ResolversTypes['ErrandManyResult']>, ParentType, ContextType, RequireFields<QueryErrandsPageArgs, never>>;
  errandsConnection?: Resolver<Maybe<ResolversTypes['ErrandConnection']>, ParentType, ContextType, RequireFields<QueryErrandsConnectionArgs, never>>;
  errandLine?: Resolver<Maybe<ResolversTypes['ErrandLine']>, ParentType, ContextType, RequireFields<QueryErrandLineArgs, never>>;
  errandLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryErrandLinesCountArgs, never>>;
  errandLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ErrandLine']>>>, ParentType, ContextType, RequireFields<QueryErrandLinesManyArgs, never>>;
  errandLinesPage?: Resolver<Maybe<ResolversTypes['ErrandLineManyResult']>, ParentType, ContextType, RequireFields<QueryErrandLinesPageArgs, never>>;
  errandLinesConnection?: Resolver<Maybe<ResolversTypes['ErrandLineConnection']>, ParentType, ContextType, RequireFields<QueryErrandLinesConnectionArgs, never>>;
  errorReport?: Resolver<Maybe<ResolversTypes['ErrorReport']>, ParentType, ContextType, RequireFields<QueryErrorReportArgs, never>>;
  errorReportsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryErrorReportsCountArgs, never>>;
  errorReportsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ErrorReport']>>>, ParentType, ContextType, RequireFields<QueryErrorReportsManyArgs, never>>;
  errorReportsPage?: Resolver<Maybe<ResolversTypes['ErrorReportManyResult']>, ParentType, ContextType, RequireFields<QueryErrorReportsPageArgs, never>>;
  errorReportsConnection?: Resolver<Maybe<ResolversTypes['ErrorReportConnection']>, ParentType, ContextType, RequireFields<QueryErrorReportsConnectionArgs, never>>;
  feedback?: Resolver<Maybe<ResolversTypes['Feedback']>, ParentType, ContextType, RequireFields<QueryFeedbackArgs, never>>;
  feedbacksCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFeedbacksCountArgs, never>>;
  feedbacksMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feedback']>>>, ParentType, ContextType, RequireFields<QueryFeedbacksManyArgs, never>>;
  feedbacksPage?: Resolver<Maybe<ResolversTypes['FeedbackManyResult']>, ParentType, ContextType, RequireFields<QueryFeedbacksPageArgs, never>>;
  feedbacksConnection?: Resolver<Maybe<ResolversTypes['FeedbackConnection']>, ParentType, ContextType, RequireFields<QueryFeedbacksConnectionArgs, never>>;
  feemapping?: Resolver<Maybe<ResolversTypes['Feemapping']>, ParentType, ContextType, RequireFields<QueryFeemappingArgs, never>>;
  feemappingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFeemappingsCountArgs, never>>;
  feemappingsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Feemapping']>>>, ParentType, ContextType, RequireFields<QueryFeemappingsManyArgs, never>>;
  feemappingsPage?: Resolver<Maybe<ResolversTypes['FeemappingManyResult']>, ParentType, ContextType, RequireFields<QueryFeemappingsPageArgs, never>>;
  feemappingsConnection?: Resolver<Maybe<ResolversTypes['FeemappingConnection']>, ParentType, ContextType, RequireFields<QueryFeemappingsConnectionArgs, never>>;
  fieldOrder?: Resolver<Maybe<ResolversTypes['FieldOrder']>, ParentType, ContextType, RequireFields<QueryFieldOrderArgs, never>>;
  fieldOrdersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFieldOrdersCountArgs, never>>;
  fieldOrdersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldOrder']>>>, ParentType, ContextType, RequireFields<QueryFieldOrdersManyArgs, never>>;
  fieldOrdersPage?: Resolver<Maybe<ResolversTypes['FieldOrderManyResult']>, ParentType, ContextType, RequireFields<QueryFieldOrdersPageArgs, never>>;
  fieldOrdersConnection?: Resolver<Maybe<ResolversTypes['FieldOrderConnection']>, ParentType, ContextType, RequireFields<QueryFieldOrdersConnectionArgs, never>>;
  fieldOrderLine?: Resolver<Maybe<ResolversTypes['FieldOrderLine']>, ParentType, ContextType, RequireFields<QueryFieldOrderLineArgs, never>>;
  fieldOrderLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFieldOrderLinesCountArgs, never>>;
  fieldOrderLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldOrderLine']>>>, ParentType, ContextType, RequireFields<QueryFieldOrderLinesManyArgs, never>>;
  fieldOrderLinesPage?: Resolver<Maybe<ResolversTypes['FieldOrderLineManyResult']>, ParentType, ContextType, RequireFields<QueryFieldOrderLinesPageArgs, never>>;
  fieldOrderLinesConnection?: Resolver<Maybe<ResolversTypes['FieldOrderLineConnection']>, ParentType, ContextType, RequireFields<QueryFieldOrderLinesConnectionArgs, never>>;
  file?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType, RequireFields<QueryFileArgs, never>>;
  filesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFilesCountArgs, never>>;
  filesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['File']>>>, ParentType, ContextType, RequireFields<QueryFilesManyArgs, never>>;
  filesPage?: Resolver<Maybe<ResolversTypes['FileManyResult']>, ParentType, ContextType, RequireFields<QueryFilesPageArgs, never>>;
  filesConnection?: Resolver<Maybe<ResolversTypes['FileConnection']>, ParentType, ContextType, RequireFields<QueryFilesConnectionArgs, never>>;
  functionTestHeader?: Resolver<Maybe<ResolversTypes['FunctionTestHeader']>, ParentType, ContextType, RequireFields<QueryFunctionTestHeaderArgs, never>>;
  functionTestHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFunctionTestHeadersCountArgs, never>>;
  functionTestHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['FunctionTestHeader']>>>, ParentType, ContextType, RequireFields<QueryFunctionTestHeadersManyArgs, never>>;
  functionTestHeadersPage?: Resolver<Maybe<ResolversTypes['FunctionTestHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryFunctionTestHeadersPageArgs, never>>;
  functionTestHeadersConnection?: Resolver<Maybe<ResolversTypes['FunctionTestHeaderConnection']>, ParentType, ContextType, RequireFields<QueryFunctionTestHeadersConnectionArgs, never>>;
  functionTestLine?: Resolver<Maybe<ResolversTypes['FunctionTestLine']>, ParentType, ContextType, RequireFields<QueryFunctionTestLineArgs, never>>;
  functionTestLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryFunctionTestLinesCountArgs, never>>;
  functionTestLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['FunctionTestLine']>>>, ParentType, ContextType, RequireFields<QueryFunctionTestLinesManyArgs, never>>;
  functionTestLinesPage?: Resolver<Maybe<ResolversTypes['FunctionTestLineManyResult']>, ParentType, ContextType, RequireFields<QueryFunctionTestLinesPageArgs, never>>;
  functionTestLinesConnection?: Resolver<Maybe<ResolversTypes['FunctionTestLineConnection']>, ParentType, ContextType, RequireFields<QueryFunctionTestLinesConnectionArgs, never>>;
  genBusinessPostingGroup?: Resolver<Maybe<ResolversTypes['GenBusinessPostingGroup']>, ParentType, ContextType, RequireFields<QueryGenBusinessPostingGroupArgs, never>>;
  genBusinessPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryGenBusinessPostingGroupsCountArgs, never>>;
  genBusinessPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenBusinessPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryGenBusinessPostingGroupsManyArgs, never>>;
  genBusinessPostingGroupsPage?: Resolver<Maybe<ResolversTypes['GenBusinessPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryGenBusinessPostingGroupsPageArgs, never>>;
  genBusinessPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['GenBusinessPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryGenBusinessPostingGroupsConnectionArgs, never>>;
  genJournalLine?: Resolver<Maybe<ResolversTypes['GenJournalLine']>, ParentType, ContextType, RequireFields<QueryGenJournalLineArgs, never>>;
  genJournalLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryGenJournalLinesCountArgs, never>>;
  genJournalLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenJournalLine']>>>, ParentType, ContextType, RequireFields<QueryGenJournalLinesManyArgs, never>>;
  genJournalLinesPage?: Resolver<Maybe<ResolversTypes['GenJournalLineManyResult']>, ParentType, ContextType, RequireFields<QueryGenJournalLinesPageArgs, never>>;
  genJournalLinesConnection?: Resolver<Maybe<ResolversTypes['GenJournalLineConnection']>, ParentType, ContextType, RequireFields<QueryGenJournalLinesConnectionArgs, never>>;
  genProductPostingGroup?: Resolver<Maybe<ResolversTypes['GenProductPostingGroup']>, ParentType, ContextType, RequireFields<QueryGenProductPostingGroupArgs, never>>;
  genProductPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryGenProductPostingGroupsCountArgs, never>>;
  genProductPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['GenProductPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryGenProductPostingGroupsManyArgs, never>>;
  genProductPostingGroupsPage?: Resolver<Maybe<ResolversTypes['GenProductPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryGenProductPostingGroupsPageArgs, never>>;
  genProductPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['GenProductPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryGenProductPostingGroupsConnectionArgs, never>>;
  inspection?: Resolver<Maybe<ResolversTypes['Inspection']>, ParentType, ContextType, RequireFields<QueryInspectionArgs, never>>;
  inspectionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryInspectionsCountArgs, never>>;
  inspectionsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Inspection']>>>, ParentType, ContextType, RequireFields<QueryInspectionsManyArgs, never>>;
  inspectionsPage?: Resolver<Maybe<ResolversTypes['InspectionManyResult']>, ParentType, ContextType, RequireFields<QueryInspectionsPageArgs, never>>;
  inspectionsConnection?: Resolver<Maybe<ResolversTypes['InspectionConnection']>, ParentType, ContextType, RequireFields<QueryInspectionsConnectionArgs, never>>;
  inspectionUser?: Resolver<Maybe<ResolversTypes['InspectionUser']>, ParentType, ContextType, RequireFields<QueryInspectionUserArgs, never>>;
  inspectionUsersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryInspectionUsersCountArgs, never>>;
  inspectionUsersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['InspectionUser']>>>, ParentType, ContextType, RequireFields<QueryInspectionUsersManyArgs, never>>;
  inspectionUsersPage?: Resolver<Maybe<ResolversTypes['InspectionUserManyResult']>, ParentType, ContextType, RequireFields<QueryInspectionUsersPageArgs, never>>;
  inspectionUsersConnection?: Resolver<Maybe<ResolversTypes['InspectionUserConnection']>, ParentType, ContextType, RequireFields<QueryInspectionUsersConnectionArgs, never>>;
  integrationCollection?: Resolver<Maybe<ResolversTypes['IntegrationCollection']>, ParentType, ContextType, RequireFields<QueryIntegrationCollectionArgs, never>>;
  integrationCollectionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryIntegrationCollectionsCountArgs, never>>;
  integrationCollectionsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationCollection']>>>, ParentType, ContextType, RequireFields<QueryIntegrationCollectionsManyArgs, never>>;
  integrationCollectionsPage?: Resolver<Maybe<ResolversTypes['IntegrationCollectionManyResult']>, ParentType, ContextType, RequireFields<QueryIntegrationCollectionsPageArgs, never>>;
  integrationCollectionsConnection?: Resolver<Maybe<ResolversTypes['IntegrationCollectionConnection']>, ParentType, ContextType, RequireFields<QueryIntegrationCollectionsConnectionArgs, never>>;
  inventoryPostingGroup?: Resolver<Maybe<ResolversTypes['InventoryPostingGroup']>, ParentType, ContextType, RequireFields<QueryInventoryPostingGroupArgs, never>>;
  inventoryPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryInventoryPostingGroupsCountArgs, never>>;
  inventoryPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['InventoryPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryInventoryPostingGroupsManyArgs, never>>;
  inventoryPostingGroupsPage?: Resolver<Maybe<ResolversTypes['InventoryPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryInventoryPostingGroupsPageArgs, never>>;
  inventoryPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['InventoryPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryInventoryPostingGroupsConnectionArgs, never>>;
  invoiceHeader?: Resolver<Maybe<ResolversTypes['InvoiceHeader']>, ParentType, ContextType, RequireFields<QueryInvoiceHeaderArgs, never>>;
  invoiceHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryInvoiceHeadersCountArgs, never>>;
  invoiceHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceHeader']>>>, ParentType, ContextType, RequireFields<QueryInvoiceHeadersManyArgs, never>>;
  invoiceHeadersPage?: Resolver<Maybe<ResolversTypes['InvoiceHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryInvoiceHeadersPageArgs, never>>;
  invoiceHeadersConnection?: Resolver<Maybe<ResolversTypes['InvoiceHeaderConnection']>, ParentType, ContextType, RequireFields<QueryInvoiceHeadersConnectionArgs, never>>;
  invoiceLine?: Resolver<Maybe<ResolversTypes['InvoiceLine']>, ParentType, ContextType, RequireFields<QueryInvoiceLineArgs, never>>;
  invoiceLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryInvoiceLinesCountArgs, never>>;
  invoiceLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceLine']>>>, ParentType, ContextType, RequireFields<QueryInvoiceLinesManyArgs, never>>;
  invoiceLinesPage?: Resolver<Maybe<ResolversTypes['InvoiceLineManyResult']>, ParentType, ContextType, RequireFields<QueryInvoiceLinesPageArgs, never>>;
  invoiceLinesConnection?: Resolver<Maybe<ResolversTypes['InvoiceLineConnection']>, ParentType, ContextType, RequireFields<QueryInvoiceLinesConnectionArgs, never>>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType, RequireFields<QueryItemArgs, never>>;
  itemsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemsCountArgs, never>>;
  itemsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Item']>>>, ParentType, ContextType, RequireFields<QueryItemsManyArgs, never>>;
  itemsPage?: Resolver<Maybe<ResolversTypes['ItemManyResult']>, ParentType, ContextType, RequireFields<QueryItemsPageArgs, never>>;
  itemsConnection?: Resolver<Maybe<ResolversTypes['ItemConnection']>, ParentType, ContextType, RequireFields<QueryItemsConnectionArgs, never>>;
  itemCategory?: Resolver<Maybe<ResolversTypes['ItemCategory']>, ParentType, ContextType, RequireFields<QueryItemCategoryArgs, never>>;
  itemCategoriesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemCategoriesCountArgs, never>>;
  itemCategoriesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemCategory']>>>, ParentType, ContextType, RequireFields<QueryItemCategoriesManyArgs, never>>;
  itemCategoriesPage?: Resolver<Maybe<ResolversTypes['ItemCategoryManyResult']>, ParentType, ContextType, RequireFields<QueryItemCategoriesPageArgs, never>>;
  itemCategoriesConnection?: Resolver<Maybe<ResolversTypes['ItemCategoryConnection']>, ParentType, ContextType, RequireFields<QueryItemCategoriesConnectionArgs, never>>;
  itemDiscGroup?: Resolver<Maybe<ResolversTypes['ItemDiscGroup']>, ParentType, ContextType, RequireFields<QueryItemDiscGroupArgs, never>>;
  itemDiscGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemDiscGroupsCountArgs, never>>;
  itemDiscGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemDiscGroup']>>>, ParentType, ContextType, RequireFields<QueryItemDiscGroupsManyArgs, never>>;
  itemDiscGroupsPage?: Resolver<Maybe<ResolversTypes['ItemDiscGroupManyResult']>, ParentType, ContextType, RequireFields<QueryItemDiscGroupsPageArgs, never>>;
  itemDiscGroupsConnection?: Resolver<Maybe<ResolversTypes['ItemDiscGroupConnection']>, ParentType, ContextType, RequireFields<QueryItemDiscGroupsConnectionArgs, never>>;
  itemReference?: Resolver<Maybe<ResolversTypes['ItemReference']>, ParentType, ContextType, RequireFields<QueryItemReferenceArgs, never>>;
  itemReferencesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemReferencesCountArgs, never>>;
  itemReferencesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemReference']>>>, ParentType, ContextType, RequireFields<QueryItemReferencesManyArgs, never>>;
  itemReferencesPage?: Resolver<Maybe<ResolversTypes['ItemReferenceManyResult']>, ParentType, ContextType, RequireFields<QueryItemReferencesPageArgs, never>>;
  itemReferencesConnection?: Resolver<Maybe<ResolversTypes['ItemReferenceConnection']>, ParentType, ContextType, RequireFields<QueryItemReferencesConnectionArgs, never>>;
  itemRestriction?: Resolver<Maybe<ResolversTypes['ItemRestriction']>, ParentType, ContextType, RequireFields<QueryItemRestrictionArgs, never>>;
  itemRestrictionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemRestrictionsCountArgs, never>>;
  itemRestrictionsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemRestriction']>>>, ParentType, ContextType, RequireFields<QueryItemRestrictionsManyArgs, never>>;
  itemRestrictionsPage?: Resolver<Maybe<ResolversTypes['ItemRestrictionManyResult']>, ParentType, ContextType, RequireFields<QueryItemRestrictionsPageArgs, never>>;
  itemRestrictionsConnection?: Resolver<Maybe<ResolversTypes['ItemRestrictionConnection']>, ParentType, ContextType, RequireFields<QueryItemRestrictionsConnectionArgs, never>>;
  itemUnitOfMeasure?: Resolver<Maybe<ResolversTypes['ItemUnitOfMeasure']>, ParentType, ContextType, RequireFields<QueryItemUnitOfMeasureArgs, never>>;
  itemUnitOfMeasuresCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemUnitOfMeasuresCountArgs, never>>;
  itemUnitOfMeasuresMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemUnitOfMeasure']>>>, ParentType, ContextType, RequireFields<QueryItemUnitOfMeasuresManyArgs, never>>;
  itemUnitOfMeasuresPage?: Resolver<Maybe<ResolversTypes['ItemUnitOfMeasureManyResult']>, ParentType, ContextType, RequireFields<QueryItemUnitOfMeasuresPageArgs, never>>;
  itemUnitOfMeasuresConnection?: Resolver<Maybe<ResolversTypes['ItemUnitOfMeasureConnection']>, ParentType, ContextType, RequireFields<QueryItemUnitOfMeasuresConnectionArgs, never>>;
  itemVendor?: Resolver<Maybe<ResolversTypes['ItemVendor']>, ParentType, ContextType, RequireFields<QueryItemVendorArgs, never>>;
  itemVendorsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryItemVendorsCountArgs, never>>;
  itemVendorsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemVendor']>>>, ParentType, ContextType, RequireFields<QueryItemVendorsManyArgs, never>>;
  itemVendorsPage?: Resolver<Maybe<ResolversTypes['ItemVendorManyResult']>, ParentType, ContextType, RequireFields<QueryItemVendorsPageArgs, never>>;
  itemVendorsConnection?: Resolver<Maybe<ResolversTypes['ItemVendorConnection']>, ParentType, ContextType, RequireFields<QueryItemVendorsConnectionArgs, never>>;
  jobTask?: Resolver<Maybe<ResolversTypes['JobTask']>, ParentType, ContextType, RequireFields<QueryJobTaskArgs, never>>;
  jobTasksCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryJobTasksCountArgs, never>>;
  jobTasksMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobTask']>>>, ParentType, ContextType, RequireFields<QueryJobTasksManyArgs, never>>;
  jobTasksPage?: Resolver<Maybe<ResolversTypes['JobTaskManyResult']>, ParentType, ContextType, RequireFields<QueryJobTasksPageArgs, never>>;
  jobTasksConnection?: Resolver<Maybe<ResolversTypes['JobTaskConnection']>, ParentType, ContextType, RequireFields<QueryJobTasksConnectionArgs, never>>;
  kliHeader?: Resolver<Maybe<ResolversTypes['KliHeader']>, ParentType, ContextType, RequireFields<QueryKliHeaderArgs, never>>;
  kliHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryKliHeadersCountArgs, never>>;
  kliHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['KliHeader']>>>, ParentType, ContextType, RequireFields<QueryKliHeadersManyArgs, never>>;
  kliHeadersPage?: Resolver<Maybe<ResolversTypes['KliHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryKliHeadersPageArgs, never>>;
  kliHeadersConnection?: Resolver<Maybe<ResolversTypes['KliHeaderConnection']>, ParentType, ContextType, RequireFields<QueryKliHeadersConnectionArgs, never>>;
  kliReasonCode?: Resolver<Maybe<ResolversTypes['KliReasonCode']>, ParentType, ContextType, RequireFields<QueryKliReasonCodeArgs, never>>;
  kliReasonCodesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryKliReasonCodesCountArgs, never>>;
  kliReasonCodesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['KliReasonCode']>>>, ParentType, ContextType, RequireFields<QueryKliReasonCodesManyArgs, never>>;
  kliReasonCodesPage?: Resolver<Maybe<ResolversTypes['KliReasonCodeManyResult']>, ParentType, ContextType, RequireFields<QueryKliReasonCodesPageArgs, never>>;
  kliReasonCodesConnection?: Resolver<Maybe<ResolversTypes['KliReasonCodeConnection']>, ParentType, ContextType, RequireFields<QueryKliReasonCodesConnectionArgs, never>>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLocationArgs, never>>;
  locationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryLocationsCountArgs, never>>;
  locationsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType, RequireFields<QueryLocationsManyArgs, never>>;
  locationsPage?: Resolver<Maybe<ResolversTypes['LocationManyResult']>, ParentType, ContextType, RequireFields<QueryLocationsPageArgs, never>>;
  locationsConnection?: Resolver<Maybe<ResolversTypes['LocationConnection']>, ParentType, ContextType, RequireFields<QueryLocationsConnectionArgs, never>>;
  lead?: Resolver<Maybe<ResolversTypes['Lead']>, ParentType, ContextType, RequireFields<QueryLeadArgs, never>>;
  leadsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryLeadsCountArgs, never>>;
  leadsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Lead']>>>, ParentType, ContextType, RequireFields<QueryLeadsManyArgs, never>>;
  leadsPage?: Resolver<Maybe<ResolversTypes['LeadManyResult']>, ParentType, ContextType, RequireFields<QueryLeadsPageArgs, never>>;
  leadsConnection?: Resolver<Maybe<ResolversTypes['LeadConnection']>, ParentType, ContextType, RequireFields<QueryLeadsConnectionArgs, never>>;
  magentoOrderStatus?: Resolver<Maybe<ResolversTypes['MagentoOrderStatus']>, ParentType, ContextType, RequireFields<QueryMagentoOrderStatusArgs, never>>;
  magentoOrderStatusesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMagentoOrderStatusesCountArgs, never>>;
  magentoOrderStatusesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['MagentoOrderStatus']>>>, ParentType, ContextType, RequireFields<QueryMagentoOrderStatusesManyArgs, never>>;
  magentoOrderStatusesPage?: Resolver<Maybe<ResolversTypes['MagentoOrderStatusManyResult']>, ParentType, ContextType, RequireFields<QueryMagentoOrderStatusesPageArgs, never>>;
  magentoOrderStatusesConnection?: Resolver<Maybe<ResolversTypes['MagentoOrderStatusConnection']>, ParentType, ContextType, RequireFields<QueryMagentoOrderStatusesConnectionArgs, never>>;
  magentoSettings?: Resolver<Maybe<ResolversTypes['MagentoSettings']>, ParentType, ContextType, RequireFields<QueryMagentoSettingsArgs, never>>;
  magentoSettingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMagentoSettingsCountArgs, never>>;
  magentoSettingsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['MagentoSettings']>>>, ParentType, ContextType, RequireFields<QueryMagentoSettingsManyArgs, never>>;
  magentoSettingsPage?: Resolver<Maybe<ResolversTypes['MagentoSettingsManyResult']>, ParentType, ContextType, RequireFields<QueryMagentoSettingsPageArgs, never>>;
  magentoSettingsConnection?: Resolver<Maybe<ResolversTypes['MagentoSettingsConnection']>, ParentType, ContextType, RequireFields<QueryMagentoSettingsConnectionArgs, never>>;
  manufacturer?: Resolver<Maybe<ResolversTypes['Manufacturer']>, ParentType, ContextType, RequireFields<QueryManufacturerArgs, never>>;
  manufacturersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryManufacturersCountArgs, never>>;
  manufacturersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Manufacturer']>>>, ParentType, ContextType, RequireFields<QueryManufacturersManyArgs, never>>;
  manufacturersPage?: Resolver<Maybe<ResolversTypes['ManufacturerManyResult']>, ParentType, ContextType, RequireFields<QueryManufacturersPageArgs, never>>;
  manufacturersConnection?: Resolver<Maybe<ResolversTypes['ManufacturerConnection']>, ParentType, ContextType, RequireFields<QueryManufacturersConnectionArgs, never>>;
  navUser?: Resolver<Maybe<ResolversTypes['NavUser']>, ParentType, ContextType, RequireFields<QueryNavUserArgs, never>>;
  navUsersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryNavUsersCountArgs, never>>;
  navUsersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['NavUser']>>>, ParentType, ContextType, RequireFields<QueryNavUsersManyArgs, never>>;
  navUsersPage?: Resolver<Maybe<ResolversTypes['NavUserManyResult']>, ParentType, ContextType, RequireFields<QueryNavUsersPageArgs, never>>;
  navUsersConnection?: Resolver<Maybe<ResolversTypes['NavUserConnection']>, ParentType, ContextType, RequireFields<QueryNavUsersConnectionArgs, never>>;
  object?: Resolver<Maybe<ResolversTypes['Object']>, ParentType, ContextType, RequireFields<QueryObjectArgs, never>>;
  objectsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectsCountArgs, never>>;
  objectsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Object']>>>, ParentType, ContextType, RequireFields<QueryObjectsManyArgs, never>>;
  objectsPage?: Resolver<Maybe<ResolversTypes['ObjectManyResult']>, ParentType, ContextType, RequireFields<QueryObjectsPageArgs, never>>;
  objectsConnection?: Resolver<Maybe<ResolversTypes['ObjectConnection']>, ParentType, ContextType, RequireFields<QueryObjectsConnectionArgs, never>>;
  objectCommentLine?: Resolver<Maybe<ResolversTypes['ObjectCommentLine']>, ParentType, ContextType, RequireFields<QueryObjectCommentLineArgs, never>>;
  objectCommentLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectCommentLinesCountArgs, never>>;
  objectCommentLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectCommentLine']>>>, ParentType, ContextType, RequireFields<QueryObjectCommentLinesManyArgs, never>>;
  objectCommentLinesPage?: Resolver<Maybe<ResolversTypes['ObjectCommentLineManyResult']>, ParentType, ContextType, RequireFields<QueryObjectCommentLinesPageArgs, never>>;
  objectCommentLinesConnection?: Resolver<Maybe<ResolversTypes['ObjectCommentLineConnection']>, ParentType, ContextType, RequireFields<QueryObjectCommentLinesConnectionArgs, never>>;
  objectFamily?: Resolver<Maybe<ResolversTypes['ObjectFamily']>, ParentType, ContextType, RequireFields<QueryObjectFamilyArgs, never>>;
  objectFamiliesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectFamiliesCountArgs, never>>;
  objectFamiliesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectFamily']>>>, ParentType, ContextType, RequireFields<QueryObjectFamiliesManyArgs, never>>;
  objectFamiliesPage?: Resolver<Maybe<ResolversTypes['ObjectFamilyManyResult']>, ParentType, ContextType, RequireFields<QueryObjectFamiliesPageArgs, never>>;
  objectFamiliesConnection?: Resolver<Maybe<ResolversTypes['ObjectFamilyConnection']>, ParentType, ContextType, RequireFields<QueryObjectFamiliesConnectionArgs, never>>;
  objectGroup?: Resolver<Maybe<ResolversTypes['ObjectGroup']>, ParentType, ContextType, RequireFields<QueryObjectGroupArgs, never>>;
  objectGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectGroupsCountArgs, never>>;
  objectGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectGroup']>>>, ParentType, ContextType, RequireFields<QueryObjectGroupsManyArgs, never>>;
  objectGroupsPage?: Resolver<Maybe<ResolversTypes['ObjectGroupManyResult']>, ParentType, ContextType, RequireFields<QueryObjectGroupsPageArgs, never>>;
  objectGroupsConnection?: Resolver<Maybe<ResolversTypes['ObjectGroupConnection']>, ParentType, ContextType, RequireFields<QueryObjectGroupsConnectionArgs, never>>;
  objectReturnHeader?: Resolver<Maybe<ResolversTypes['ObjectReturnHeader']>, ParentType, ContextType, RequireFields<QueryObjectReturnHeaderArgs, never>>;
  objectReturnHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectReturnHeadersCountArgs, never>>;
  objectReturnHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectReturnHeader']>>>, ParentType, ContextType, RequireFields<QueryObjectReturnHeadersManyArgs, never>>;
  objectReturnHeadersPage?: Resolver<Maybe<ResolversTypes['ObjectReturnHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryObjectReturnHeadersPageArgs, never>>;
  objectReturnHeadersConnection?: Resolver<Maybe<ResolversTypes['ObjectReturnHeaderConnection']>, ParentType, ContextType, RequireFields<QueryObjectReturnHeadersConnectionArgs, never>>;
  objectReturnLine?: Resolver<Maybe<ResolversTypes['ObjectReturnLine']>, ParentType, ContextType, RequireFields<QueryObjectReturnLineArgs, never>>;
  objectReturnLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectReturnLinesCountArgs, never>>;
  objectReturnLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectReturnLine']>>>, ParentType, ContextType, RequireFields<QueryObjectReturnLinesManyArgs, never>>;
  objectReturnLinesPage?: Resolver<Maybe<ResolversTypes['ObjectReturnLineManyResult']>, ParentType, ContextType, RequireFields<QueryObjectReturnLinesPageArgs, never>>;
  objectReturnLinesConnection?: Resolver<Maybe<ResolversTypes['ObjectReturnLineConnection']>, ParentType, ContextType, RequireFields<QueryObjectReturnLinesConnectionArgs, never>>;
  objectType?: Resolver<Maybe<ResolversTypes['ObjectType']>, ParentType, ContextType, RequireFields<QueryObjectTypeArgs, never>>;
  objectTypesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryObjectTypesCountArgs, never>>;
  objectTypesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ObjectType']>>>, ParentType, ContextType, RequireFields<QueryObjectTypesManyArgs, never>>;
  objectTypesPage?: Resolver<Maybe<ResolversTypes['ObjectTypeManyResult']>, ParentType, ContextType, RequireFields<QueryObjectTypesPageArgs, never>>;
  objectTypesConnection?: Resolver<Maybe<ResolversTypes['ObjectTypeConnection']>, ParentType, ContextType, RequireFields<QueryObjectTypesConnectionArgs, never>>;
  ocrAnalysis?: Resolver<Maybe<ResolversTypes['OcrAnalysis']>, ParentType, ContextType, RequireFields<QueryOcrAnalysisArgs, never>>;
  ocrAnalysesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryOcrAnalysesCountArgs, never>>;
  ocrAnalysesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['OcrAnalysis']>>>, ParentType, ContextType, RequireFields<QueryOcrAnalysesManyArgs, never>>;
  ocrAnalysesPage?: Resolver<Maybe<ResolversTypes['OcrAnalysisManyResult']>, ParentType, ContextType, RequireFields<QueryOcrAnalysesPageArgs, never>>;
  ocrAnalysesConnection?: Resolver<Maybe<ResolversTypes['OcrAnalysisConnection']>, ParentType, ContextType, RequireFields<QueryOcrAnalysesConnectionArgs, never>>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QueryPaymentMethodArgs, never>>;
  paymentMethodsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPaymentMethodsCountArgs, never>>;
  paymentMethodsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>, ParentType, ContextType, RequireFields<QueryPaymentMethodsManyArgs, never>>;
  paymentMethodsPage?: Resolver<Maybe<ResolversTypes['PaymentMethodManyResult']>, ParentType, ContextType, RequireFields<QueryPaymentMethodsPageArgs, never>>;
  paymentMethodsConnection?: Resolver<Maybe<ResolversTypes['PaymentMethodConnection']>, ParentType, ContextType, RequireFields<QueryPaymentMethodsConnectionArgs, never>>;
  paymentTerms?: Resolver<Maybe<ResolversTypes['PaymentTerms']>, ParentType, ContextType, RequireFields<QueryPaymentTermsArgs, never>>;
  paymentTermsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPaymentTermsCountArgs, never>>;
  paymentTermsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentTerms']>>>, ParentType, ContextType, RequireFields<QueryPaymentTermsManyArgs, never>>;
  paymentTermsPage?: Resolver<Maybe<ResolversTypes['PaymentTermsManyResult']>, ParentType, ContextType, RequireFields<QueryPaymentTermsPageArgs, never>>;
  paymentTermsConnection?: Resolver<Maybe<ResolversTypes['PaymentTermsConnection']>, ParentType, ContextType, RequireFields<QueryPaymentTermsConnectionArgs, never>>;
  platformFeature?: Resolver<Maybe<ResolversTypes['PlatformFeature']>, ParentType, ContextType, RequireFields<QueryPlatformFeatureArgs, never>>;
  platformFeaturesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPlatformFeaturesCountArgs, never>>;
  platformFeaturesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlatformFeature']>>>, ParentType, ContextType, RequireFields<QueryPlatformFeaturesManyArgs, never>>;
  platformFeaturesPage?: Resolver<Maybe<ResolversTypes['PlatformFeatureManyResult']>, ParentType, ContextType, RequireFields<QueryPlatformFeaturesPageArgs, never>>;
  platformFeaturesConnection?: Resolver<Maybe<ResolversTypes['PlatformFeatureConnection']>, ParentType, ContextType, RequireFields<QueryPlatformFeaturesConnectionArgs, never>>;
  postCode?: Resolver<Maybe<ResolversTypes['PostCode']>, ParentType, ContextType, RequireFields<QueryPostCodeArgs, never>>;
  postCodesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPostCodesCountArgs, never>>;
  postCodesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PostCode']>>>, ParentType, ContextType, RequireFields<QueryPostCodesManyArgs, never>>;
  postCodesPage?: Resolver<Maybe<ResolversTypes['PostCodeManyResult']>, ParentType, ContextType, RequireFields<QueryPostCodesPageArgs, never>>;
  postCodesConnection?: Resolver<Maybe<ResolversTypes['PostCodeConnection']>, ParentType, ContextType, RequireFields<QueryPostCodesConnectionArgs, never>>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectArgs, never>>;
  projectsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryProjectsCountArgs, never>>;
  projectsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Project']>>>, ParentType, ContextType, RequireFields<QueryProjectsManyArgs, never>>;
  projectsPage?: Resolver<Maybe<ResolversTypes['ProjectManyResult']>, ParentType, ContextType, RequireFields<QueryProjectsPageArgs, never>>;
  projectsConnection?: Resolver<Maybe<ResolversTypes['ProjectConnection']>, ParentType, ContextType, RequireFields<QueryProjectsConnectionArgs, never>>;
  purchaseHeader?: Resolver<Maybe<ResolversTypes['PurchaseHeader']>, ParentType, ContextType, RequireFields<QueryPurchaseHeaderArgs, never>>;
  purchaseHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchaseHeadersCountArgs, never>>;
  purchaseHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchaseHeader']>>>, ParentType, ContextType, RequireFields<QueryPurchaseHeadersManyArgs, never>>;
  purchaseHeadersPage?: Resolver<Maybe<ResolversTypes['PurchaseHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryPurchaseHeadersPageArgs, never>>;
  purchaseHeadersConnection?: Resolver<Maybe<ResolversTypes['PurchaseHeaderConnection']>, ParentType, ContextType, RequireFields<QueryPurchaseHeadersConnectionArgs, never>>;
  purchaseLine?: Resolver<Maybe<ResolversTypes['PurchaseLine']>, ParentType, ContextType, RequireFields<QueryPurchaseLineArgs, never>>;
  purchaseLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchaseLinesCountArgs, never>>;
  purchaseLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchaseLine']>>>, ParentType, ContextType, RequireFields<QueryPurchaseLinesManyArgs, never>>;
  purchaseLinesPage?: Resolver<Maybe<ResolversTypes['PurchaseLineManyResult']>, ParentType, ContextType, RequireFields<QueryPurchaseLinesPageArgs, never>>;
  purchaseLinesConnection?: Resolver<Maybe<ResolversTypes['PurchaseLineConnection']>, ParentType, ContextType, RequireFields<QueryPurchaseLinesConnectionArgs, never>>;
  purchasing?: Resolver<Maybe<ResolversTypes['Purchasing']>, ParentType, ContextType, RequireFields<QueryPurchasingArgs, never>>;
  purchasingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchasingsCountArgs, never>>;
  purchasingsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Purchasing']>>>, ParentType, ContextType, RequireFields<QueryPurchasingsManyArgs, never>>;
  purchasingsPage?: Resolver<Maybe<ResolversTypes['PurchasingManyResult']>, ParentType, ContextType, RequireFields<QueryPurchasingsPageArgs, never>>;
  purchasingsConnection?: Resolver<Maybe<ResolversTypes['PurchasingConnection']>, ParentType, ContextType, RequireFields<QueryPurchasingsConnectionArgs, never>>;
  purchCrMemoHeader?: Resolver<Maybe<ResolversTypes['PurchCrMemoHeader']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoHeaderArgs, never>>;
  purchCrMemoHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoHeadersCountArgs, never>>;
  purchCrMemoHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchCrMemoHeader']>>>, ParentType, ContextType, RequireFields<QueryPurchCrMemoHeadersManyArgs, never>>;
  purchCrMemoHeadersPage?: Resolver<Maybe<ResolversTypes['PurchCrMemoHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoHeadersPageArgs, never>>;
  purchCrMemoHeadersConnection?: Resolver<Maybe<ResolversTypes['PurchCrMemoHeaderConnection']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoHeadersConnectionArgs, never>>;
  purchCrMemoLine?: Resolver<Maybe<ResolversTypes['PurchCrMemoLine']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoLineArgs, never>>;
  purchCrMemoLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoLinesCountArgs, never>>;
  purchCrMemoLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchCrMemoLine']>>>, ParentType, ContextType, RequireFields<QueryPurchCrMemoLinesManyArgs, never>>;
  purchCrMemoLinesPage?: Resolver<Maybe<ResolversTypes['PurchCrMemoLineManyResult']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoLinesPageArgs, never>>;
  purchCrMemoLinesConnection?: Resolver<Maybe<ResolversTypes['PurchCrMemoLineConnection']>, ParentType, ContextType, RequireFields<QueryPurchCrMemoLinesConnectionArgs, never>>;
  purchInvHeader?: Resolver<Maybe<ResolversTypes['PurchInvHeader']>, ParentType, ContextType, RequireFields<QueryPurchInvHeaderArgs, never>>;
  purchInvHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchInvHeadersCountArgs, never>>;
  purchInvHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchInvHeader']>>>, ParentType, ContextType, RequireFields<QueryPurchInvHeadersManyArgs, never>>;
  purchInvHeadersPage?: Resolver<Maybe<ResolversTypes['PurchInvHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryPurchInvHeadersPageArgs, never>>;
  purchInvHeadersConnection?: Resolver<Maybe<ResolversTypes['PurchInvHeaderConnection']>, ParentType, ContextType, RequireFields<QueryPurchInvHeadersConnectionArgs, never>>;
  purchInvLine?: Resolver<Maybe<ResolversTypes['PurchInvLine']>, ParentType, ContextType, RequireFields<QueryPurchInvLineArgs, never>>;
  purchInvLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchInvLinesCountArgs, never>>;
  purchInvLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchInvLine']>>>, ParentType, ContextType, RequireFields<QueryPurchInvLinesManyArgs, never>>;
  purchInvLinesPage?: Resolver<Maybe<ResolversTypes['PurchInvLineManyResult']>, ParentType, ContextType, RequireFields<QueryPurchInvLinesPageArgs, never>>;
  purchInvLinesConnection?: Resolver<Maybe<ResolversTypes['PurchInvLineConnection']>, ParentType, ContextType, RequireFields<QueryPurchInvLinesConnectionArgs, never>>;
  purchRcptHeader?: Resolver<Maybe<ResolversTypes['PurchRcptHeader']>, ParentType, ContextType, RequireFields<QueryPurchRcptHeaderArgs, never>>;
  purchRcptHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchRcptHeadersCountArgs, never>>;
  purchRcptHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchRcptHeader']>>>, ParentType, ContextType, RequireFields<QueryPurchRcptHeadersManyArgs, never>>;
  purchRcptHeadersPage?: Resolver<Maybe<ResolversTypes['PurchRcptHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryPurchRcptHeadersPageArgs, never>>;
  purchRcptHeadersConnection?: Resolver<Maybe<ResolversTypes['PurchRcptHeaderConnection']>, ParentType, ContextType, RequireFields<QueryPurchRcptHeadersConnectionArgs, never>>;
  purchRcptLine?: Resolver<Maybe<ResolversTypes['PurchRcptLine']>, ParentType, ContextType, RequireFields<QueryPurchRcptLineArgs, never>>;
  purchRcptLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPurchRcptLinesCountArgs, never>>;
  purchRcptLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['PurchRcptLine']>>>, ParentType, ContextType, RequireFields<QueryPurchRcptLinesManyArgs, never>>;
  purchRcptLinesPage?: Resolver<Maybe<ResolversTypes['PurchRcptLineManyResult']>, ParentType, ContextType, RequireFields<QueryPurchRcptLinesPageArgs, never>>;
  purchRcptLinesConnection?: Resolver<Maybe<ResolversTypes['PurchRcptLineConnection']>, ParentType, ContextType, RequireFields<QueryPurchRcptLinesConnectionArgs, never>>;
  quoteHeader?: Resolver<Maybe<ResolversTypes['QuoteHeader']>, ParentType, ContextType, RequireFields<QueryQuoteHeaderArgs, never>>;
  quoteHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryQuoteHeadersCountArgs, never>>;
  quoteHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteHeader']>>>, ParentType, ContextType, RequireFields<QueryQuoteHeadersManyArgs, never>>;
  quoteHeadersPage?: Resolver<Maybe<ResolversTypes['QuoteHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryQuoteHeadersPageArgs, never>>;
  quoteHeadersConnection?: Resolver<Maybe<ResolversTypes['QuoteHeaderConnection']>, ParentType, ContextType, RequireFields<QueryQuoteHeadersConnectionArgs, never>>;
  quoteLine?: Resolver<Maybe<ResolversTypes['QuoteLine']>, ParentType, ContextType, RequireFields<QueryQuoteLineArgs, never>>;
  quoteLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryQuoteLinesCountArgs, never>>;
  quoteLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteLine']>>>, ParentType, ContextType, RequireFields<QueryQuoteLinesManyArgs, never>>;
  quoteLinesPage?: Resolver<Maybe<ResolversTypes['QuoteLineManyResult']>, ParentType, ContextType, RequireFields<QueryQuoteLinesPageArgs, never>>;
  quoteLinesConnection?: Resolver<Maybe<ResolversTypes['QuoteLineConnection']>, ParentType, ContextType, RequireFields<QueryQuoteLinesConnectionArgs, never>>;
  quoteTaskGroup?: Resolver<Maybe<ResolversTypes['QuoteTaskGroup']>, ParentType, ContextType, RequireFields<QueryQuoteTaskGroupArgs, never>>;
  quoteTaskGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryQuoteTaskGroupsCountArgs, never>>;
  quoteTaskGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteTaskGroup']>>>, ParentType, ContextType, RequireFields<QueryQuoteTaskGroupsManyArgs, never>>;
  quoteTaskGroupsPage?: Resolver<Maybe<ResolversTypes['QuoteTaskGroupManyResult']>, ParentType, ContextType, RequireFields<QueryQuoteTaskGroupsPageArgs, never>>;
  quoteTaskGroupsConnection?: Resolver<Maybe<ResolversTypes['QuoteTaskGroupConnection']>, ParentType, ContextType, RequireFields<QueryQuoteTaskGroupsConnectionArgs, never>>;
  rentalHeader?: Resolver<Maybe<ResolversTypes['RentalHeader']>, ParentType, ContextType, RequireFields<QueryRentalHeaderArgs, never>>;
  rentalHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalHeadersCountArgs, never>>;
  rentalHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalHeader']>>>, ParentType, ContextType, RequireFields<QueryRentalHeadersManyArgs, never>>;
  rentalHeadersPage?: Resolver<Maybe<ResolversTypes['RentalHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryRentalHeadersPageArgs, never>>;
  rentalHeadersConnection?: Resolver<Maybe<ResolversTypes['RentalHeaderConnection']>, ParentType, ContextType, RequireFields<QueryRentalHeadersConnectionArgs, never>>;
  rentalLine?: Resolver<Maybe<ResolversTypes['RentalLine']>, ParentType, ContextType, RequireFields<QueryRentalLineArgs, never>>;
  rentalLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalLinesCountArgs, never>>;
  rentalLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLine']>>>, ParentType, ContextType, RequireFields<QueryRentalLinesManyArgs, never>>;
  rentalLinesPage?: Resolver<Maybe<ResolversTypes['RentalLineManyResult']>, ParentType, ContextType, RequireFields<QueryRentalLinesPageArgs, never>>;
  rentalLinesConnection?: Resolver<Maybe<ResolversTypes['RentalLineConnection']>, ParentType, ContextType, RequireFields<QueryRentalLinesConnectionArgs, never>>;
  rentalLineSnapshot?: Resolver<Maybe<ResolversTypes['RentalLineSnapshot']>, ParentType, ContextType, RequireFields<QueryRentalLineSnapshotArgs, never>>;
  rentalLineSnapshotsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalLineSnapshotsCountArgs, never>>;
  rentalLineSnapshotsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLineSnapshot']>>>, ParentType, ContextType, RequireFields<QueryRentalLineSnapshotsManyArgs, never>>;
  rentalLineSnapshotsPage?: Resolver<Maybe<ResolversTypes['RentalLineSnapshotManyResult']>, ParentType, ContextType, RequireFields<QueryRentalLineSnapshotsPageArgs, never>>;
  rentalLineSnapshotsConnection?: Resolver<Maybe<ResolversTypes['RentalLineSnapshotConnection']>, ParentType, ContextType, RequireFields<QueryRentalLineSnapshotsConnectionArgs, never>>;
  rentalPostedCollHeader?: Resolver<Maybe<ResolversTypes['RentalPostedCollHeader']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollHeaderArgs, never>>;
  rentalPostedCollHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollHeadersCountArgs, never>>;
  rentalPostedCollHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollHeader']>>>, ParentType, ContextType, RequireFields<QueryRentalPostedCollHeadersManyArgs, never>>;
  rentalPostedCollHeadersPage?: Resolver<Maybe<ResolversTypes['RentalPostedCollHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollHeadersPageArgs, never>>;
  rentalPostedCollHeadersConnection?: Resolver<Maybe<ResolversTypes['RentalPostedCollHeaderConnection']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollHeadersConnectionArgs, never>>;
  rentalPostedCollLine?: Resolver<Maybe<ResolversTypes['RentalPostedCollLine']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollLineArgs, never>>;
  rentalPostedCollLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollLinesCountArgs, never>>;
  rentalPostedCollLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollLine']>>>, ParentType, ContextType, RequireFields<QueryRentalPostedCollLinesManyArgs, never>>;
  rentalPostedCollLinesPage?: Resolver<Maybe<ResolversTypes['RentalPostedCollLineManyResult']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollLinesPageArgs, never>>;
  rentalPostedCollLinesConnection?: Resolver<Maybe<ResolversTypes['RentalPostedCollLineConnection']>, ParentType, ContextType, RequireFields<QueryRentalPostedCollLinesConnectionArgs, never>>;
  rentalPostedShptHeader?: Resolver<Maybe<ResolversTypes['RentalPostedShptHeader']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptHeaderArgs, never>>;
  rentalPostedShptHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptHeadersCountArgs, never>>;
  rentalPostedShptHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptHeader']>>>, ParentType, ContextType, RequireFields<QueryRentalPostedShptHeadersManyArgs, never>>;
  rentalPostedShptHeadersPage?: Resolver<Maybe<ResolversTypes['RentalPostedShptHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptHeadersPageArgs, never>>;
  rentalPostedShptHeadersConnection?: Resolver<Maybe<ResolversTypes['RentalPostedShptHeaderConnection']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptHeadersConnectionArgs, never>>;
  rentalPostedShptLine?: Resolver<Maybe<ResolversTypes['RentalPostedShptLine']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptLineArgs, never>>;
  rentalPostedShptLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptLinesCountArgs, never>>;
  rentalPostedShptLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptLine']>>>, ParentType, ContextType, RequireFields<QueryRentalPostedShptLinesManyArgs, never>>;
  rentalPostedShptLinesPage?: Resolver<Maybe<ResolversTypes['RentalPostedShptLineManyResult']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptLinesPageArgs, never>>;
  rentalPostedShptLinesConnection?: Resolver<Maybe<ResolversTypes['RentalPostedShptLineConnection']>, ParentType, ContextType, RequireFields<QueryRentalPostedShptLinesConnectionArgs, never>>;
  rentalPriceTerms?: Resolver<Maybe<ResolversTypes['RentalPriceTerms']>, ParentType, ContextType, RequireFields<QueryRentalPriceTermsArgs, never>>;
  rentalPriceTermsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalPriceTermsCountArgs, never>>;
  rentalPriceTermsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPriceTerms']>>>, ParentType, ContextType, RequireFields<QueryRentalPriceTermsManyArgs, never>>;
  rentalPriceTermsPage?: Resolver<Maybe<ResolversTypes['RentalPriceTermsManyResult']>, ParentType, ContextType, RequireFields<QueryRentalPriceTermsPageArgs, never>>;
  rentalPriceTermsConnection?: Resolver<Maybe<ResolversTypes['RentalPriceTermsConnection']>, ParentType, ContextType, RequireFields<QueryRentalPriceTermsConnectionArgs, never>>;
  rentalReturnEntry?: Resolver<Maybe<ResolversTypes['RentalReturnEntry']>, ParentType, ContextType, RequireFields<QueryRentalReturnEntryArgs, never>>;
  rentalReturnEntriesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRentalReturnEntriesCountArgs, never>>;
  rentalReturnEntriesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalReturnEntry']>>>, ParentType, ContextType, RequireFields<QueryRentalReturnEntriesManyArgs, never>>;
  rentalReturnEntriesPage?: Resolver<Maybe<ResolversTypes['RentalReturnEntryManyResult']>, ParentType, ContextType, RequireFields<QueryRentalReturnEntriesPageArgs, never>>;
  rentalReturnEntriesConnection?: Resolver<Maybe<ResolversTypes['RentalReturnEntryConnection']>, ParentType, ContextType, RequireFields<QueryRentalReturnEntriesConnectionArgs, never>>;
  requestHeader?: Resolver<Maybe<ResolversTypes['RequestHeader']>, ParentType, ContextType, RequireFields<QueryRequestHeaderArgs, never>>;
  requestHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRequestHeadersCountArgs, never>>;
  requestHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestHeader']>>>, ParentType, ContextType, RequireFields<QueryRequestHeadersManyArgs, never>>;
  requestHeadersPage?: Resolver<Maybe<ResolversTypes['RequestHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryRequestHeadersPageArgs, never>>;
  requestHeadersConnection?: Resolver<Maybe<ResolversTypes['RequestHeaderConnection']>, ParentType, ContextType, RequireFields<QueryRequestHeadersConnectionArgs, never>>;
  requestLine?: Resolver<Maybe<ResolversTypes['RequestLine']>, ParentType, ContextType, RequireFields<QueryRequestLineArgs, never>>;
  requestLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRequestLinesCountArgs, never>>;
  requestLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestLine']>>>, ParentType, ContextType, RequireFields<QueryRequestLinesManyArgs, never>>;
  requestLinesPage?: Resolver<Maybe<ResolversTypes['RequestLineManyResult']>, ParentType, ContextType, RequireFields<QueryRequestLinesPageArgs, never>>;
  requestLinesConnection?: Resolver<Maybe<ResolversTypes['RequestLineConnection']>, ParentType, ContextType, RequireFields<QueryRequestLinesConnectionArgs, never>>;
  reservationRequestHeader?: Resolver<Maybe<ResolversTypes['ReservationRequestHeader']>, ParentType, ContextType, RequireFields<QueryReservationRequestHeaderArgs, never>>;
  reservationRequestHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryReservationRequestHeadersCountArgs, never>>;
  reservationRequestHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestHeader']>>>, ParentType, ContextType, RequireFields<QueryReservationRequestHeadersManyArgs, never>>;
  reservationRequestHeadersPage?: Resolver<Maybe<ResolversTypes['ReservationRequestHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryReservationRequestHeadersPageArgs, never>>;
  reservationRequestHeadersConnection?: Resolver<Maybe<ResolversTypes['ReservationRequestHeaderConnection']>, ParentType, ContextType, RequireFields<QueryReservationRequestHeadersConnectionArgs, never>>;
  reservationRequestLine?: Resolver<Maybe<ResolversTypes['ReservationRequestLine']>, ParentType, ContextType, RequireFields<QueryReservationRequestLineArgs, never>>;
  reservationRequestLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryReservationRequestLinesCountArgs, never>>;
  reservationRequestLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestLine']>>>, ParentType, ContextType, RequireFields<QueryReservationRequestLinesManyArgs, never>>;
  reservationRequestLinesPage?: Resolver<Maybe<ResolversTypes['ReservationRequestLineManyResult']>, ParentType, ContextType, RequireFields<QueryReservationRequestLinesPageArgs, never>>;
  reservationRequestLinesConnection?: Resolver<Maybe<ResolversTypes['ReservationRequestLineConnection']>, ParentType, ContextType, RequireFields<QueryReservationRequestLinesConnectionArgs, never>>;
  resource?: Resolver<Maybe<ResolversTypes['Resource']>, ParentType, ContextType, RequireFields<QueryResourceArgs, never>>;
  resourcesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryResourcesCountArgs, never>>;
  resourcesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resource']>>>, ParentType, ContextType, RequireFields<QueryResourcesManyArgs, never>>;
  resourcesPage?: Resolver<Maybe<ResolversTypes['ResourceManyResult']>, ParentType, ContextType, RequireFields<QueryResourcesPageArgs, never>>;
  resourcesConnection?: Resolver<Maybe<ResolversTypes['ResourceConnection']>, ParentType, ContextType, RequireFields<QueryResourcesConnectionArgs, never>>;
  responsibilityCenter?: Resolver<Maybe<ResolversTypes['ResponsibilityCenter']>, ParentType, ContextType, RequireFields<QueryResponsibilityCenterArgs, never>>;
  responsibilityCentersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryResponsibilityCentersCountArgs, never>>;
  responsibilityCentersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ResponsibilityCenter']>>>, ParentType, ContextType, RequireFields<QueryResponsibilityCentersManyArgs, never>>;
  responsibilityCentersPage?: Resolver<Maybe<ResolversTypes['ResponsibilityCenterManyResult']>, ParentType, ContextType, RequireFields<QueryResponsibilityCentersPageArgs, never>>;
  responsibilityCentersConnection?: Resolver<Maybe<ResolversTypes['ResponsibilityCenterConnection']>, ParentType, ContextType, RequireFields<QueryResponsibilityCentersConnectionArgs, never>>;
  returnRequestHeader?: Resolver<Maybe<ResolversTypes['ReturnRequestHeader']>, ParentType, ContextType, RequireFields<QueryReturnRequestHeaderArgs, never>>;
  returnRequestHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryReturnRequestHeadersCountArgs, never>>;
  returnRequestHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestHeader']>>>, ParentType, ContextType, RequireFields<QueryReturnRequestHeadersManyArgs, never>>;
  returnRequestHeadersPage?: Resolver<Maybe<ResolversTypes['ReturnRequestHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryReturnRequestHeadersPageArgs, never>>;
  returnRequestHeadersConnection?: Resolver<Maybe<ResolversTypes['ReturnRequestHeaderConnection']>, ParentType, ContextType, RequireFields<QueryReturnRequestHeadersConnectionArgs, never>>;
  returnRequestLine?: Resolver<Maybe<ResolversTypes['ReturnRequestLine']>, ParentType, ContextType, RequireFields<QueryReturnRequestLineArgs, never>>;
  returnRequestLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryReturnRequestLinesCountArgs, never>>;
  returnRequestLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestLine']>>>, ParentType, ContextType, RequireFields<QueryReturnRequestLinesManyArgs, never>>;
  returnRequestLinesPage?: Resolver<Maybe<ResolversTypes['ReturnRequestLineManyResult']>, ParentType, ContextType, RequireFields<QueryReturnRequestLinesPageArgs, never>>;
  returnRequestLinesConnection?: Resolver<Maybe<ResolversTypes['ReturnRequestLineConnection']>, ParentType, ContextType, RequireFields<QueryReturnRequestLinesConnectionArgs, never>>;
  riskAnalysisHeader?: Resolver<Maybe<ResolversTypes['RiskAnalysisHeader']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisHeaderArgs, never>>;
  riskAnalysisHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisHeadersCountArgs, never>>;
  riskAnalysisHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisHeader']>>>, ParentType, ContextType, RequireFields<QueryRiskAnalysisHeadersManyArgs, never>>;
  riskAnalysisHeadersPage?: Resolver<Maybe<ResolversTypes['RiskAnalysisHeaderManyResult']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisHeadersPageArgs, never>>;
  riskAnalysisHeadersConnection?: Resolver<Maybe<ResolversTypes['RiskAnalysisHeaderConnection']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisHeadersConnectionArgs, never>>;
  riskAnalysisLine?: Resolver<Maybe<ResolversTypes['RiskAnalysisLine']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisLineArgs, never>>;
  riskAnalysisLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisLinesCountArgs, never>>;
  riskAnalysisLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisLine']>>>, ParentType, ContextType, RequireFields<QueryRiskAnalysisLinesManyArgs, never>>;
  riskAnalysisLinesPage?: Resolver<Maybe<ResolversTypes['RiskAnalysisLineManyResult']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisLinesPageArgs, never>>;
  riskAnalysisLinesConnection?: Resolver<Maybe<ResolversTypes['RiskAnalysisLineConnection']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisLinesConnectionArgs, never>>;
  riskAnalysisSignature?: Resolver<Maybe<ResolversTypes['RiskAnalysisSignature']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisSignatureArgs, never>>;
  riskAnalysisSignaturesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisSignaturesCountArgs, never>>;
  riskAnalysisSignaturesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisSignature']>>>, ParentType, ContextType, RequireFields<QueryRiskAnalysisSignaturesManyArgs, never>>;
  riskAnalysisSignaturesPage?: Resolver<Maybe<ResolversTypes['RiskAnalysisSignatureManyResult']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisSignaturesPageArgs, never>>;
  riskAnalysisSignaturesConnection?: Resolver<Maybe<ResolversTypes['RiskAnalysisSignatureConnection']>, ParentType, ContextType, RequireFields<QueryRiskAnalysisSignaturesConnectionArgs, never>>;
  salesHeader?: Resolver<Maybe<ResolversTypes['SalesHeader']>, ParentType, ContextType, RequireFields<QuerySalesHeaderArgs, never>>;
  salesHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesHeadersCountArgs, never>>;
  salesHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeader']>>>, ParentType, ContextType, RequireFields<QuerySalesHeadersManyArgs, never>>;
  salesHeadersPage?: Resolver<Maybe<ResolversTypes['SalesHeaderManyResult']>, ParentType, ContextType, RequireFields<QuerySalesHeadersPageArgs, never>>;
  salesHeadersConnection?: Resolver<Maybe<ResolversTypes['SalesHeaderConnection']>, ParentType, ContextType, RequireFields<QuerySalesHeadersConnectionArgs, never>>;
  salesHeaderArchive?: Resolver<Maybe<ResolversTypes['SalesHeaderArchive']>, ParentType, ContextType, RequireFields<QuerySalesHeaderArchiveArgs, never>>;
  salesHeaderArchivesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesHeaderArchivesCountArgs, never>>;
  salesHeaderArchivesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderArchive']>>>, ParentType, ContextType, RequireFields<QuerySalesHeaderArchivesManyArgs, never>>;
  salesHeaderArchivesPage?: Resolver<Maybe<ResolversTypes['SalesHeaderArchiveManyResult']>, ParentType, ContextType, RequireFields<QuerySalesHeaderArchivesPageArgs, never>>;
  salesHeaderArchivesConnection?: Resolver<Maybe<ResolversTypes['SalesHeaderArchiveConnection']>, ParentType, ContextType, RequireFields<QuerySalesHeaderArchivesConnectionArgs, never>>;
  salesHeaderHistory?: Resolver<Maybe<ResolversTypes['SalesHeaderHistory']>, ParentType, ContextType, RequireFields<QuerySalesHeaderHistoryArgs, never>>;
  salesHeaderHistoriesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesHeaderHistoriesCountArgs, never>>;
  salesHeaderHistoriesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderHistory']>>>, ParentType, ContextType, RequireFields<QuerySalesHeaderHistoriesManyArgs, never>>;
  salesHeaderHistoriesPage?: Resolver<Maybe<ResolversTypes['SalesHeaderHistoryManyResult']>, ParentType, ContextType, RequireFields<QuerySalesHeaderHistoriesPageArgs, never>>;
  salesHeaderHistoriesConnection?: Resolver<Maybe<ResolversTypes['SalesHeaderHistoryConnection']>, ParentType, ContextType, RequireFields<QuerySalesHeaderHistoriesConnectionArgs, never>>;
  salesHeaderRequest?: Resolver<Maybe<ResolversTypes['SalesHeaderRequest']>, ParentType, ContextType, RequireFields<QuerySalesHeaderRequestArgs, never>>;
  salesHeaderRequestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesHeaderRequestsCountArgs, never>>;
  salesHeaderRequestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderRequest']>>>, ParentType, ContextType, RequireFields<QuerySalesHeaderRequestsManyArgs, never>>;
  salesHeaderRequestsPage?: Resolver<Maybe<ResolversTypes['SalesHeaderRequestManyResult']>, ParentType, ContextType, RequireFields<QuerySalesHeaderRequestsPageArgs, never>>;
  salesHeaderRequestsConnection?: Resolver<Maybe<ResolversTypes['SalesHeaderRequestConnection']>, ParentType, ContextType, RequireFields<QuerySalesHeaderRequestsConnectionArgs, never>>;
  salesLine?: Resolver<Maybe<ResolversTypes['SalesLine']>, ParentType, ContextType, RequireFields<QuerySalesLineArgs, never>>;
  salesLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesLinesCountArgs, never>>;
  salesLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLine']>>>, ParentType, ContextType, RequireFields<QuerySalesLinesManyArgs, never>>;
  salesLinesPage?: Resolver<Maybe<ResolversTypes['SalesLineManyResult']>, ParentType, ContextType, RequireFields<QuerySalesLinesPageArgs, never>>;
  salesLinesConnection?: Resolver<Maybe<ResolversTypes['SalesLineConnection']>, ParentType, ContextType, RequireFields<QuerySalesLinesConnectionArgs, never>>;
  salesLineArchive?: Resolver<Maybe<ResolversTypes['SalesLineArchive']>, ParentType, ContextType, RequireFields<QuerySalesLineArchiveArgs, never>>;
  salesLineArchivesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesLineArchivesCountArgs, never>>;
  salesLineArchivesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineArchive']>>>, ParentType, ContextType, RequireFields<QuerySalesLineArchivesManyArgs, never>>;
  salesLineArchivesPage?: Resolver<Maybe<ResolversTypes['SalesLineArchiveManyResult']>, ParentType, ContextType, RequireFields<QuerySalesLineArchivesPageArgs, never>>;
  salesLineArchivesConnection?: Resolver<Maybe<ResolversTypes['SalesLineArchiveConnection']>, ParentType, ContextType, RequireFields<QuerySalesLineArchivesConnectionArgs, never>>;
  salesLineRequest?: Resolver<Maybe<ResolversTypes['SalesLineRequest']>, ParentType, ContextType, RequireFields<QuerySalesLineRequestArgs, never>>;
  salesLineRequestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesLineRequestsCountArgs, never>>;
  salesLineRequestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineRequest']>>>, ParentType, ContextType, RequireFields<QuerySalesLineRequestsManyArgs, never>>;
  salesLineRequestsPage?: Resolver<Maybe<ResolversTypes['SalesLineRequestManyResult']>, ParentType, ContextType, RequireFields<QuerySalesLineRequestsPageArgs, never>>;
  salesLineRequestsConnection?: Resolver<Maybe<ResolversTypes['SalesLineRequestConnection']>, ParentType, ContextType, RequireFields<QuerySalesLineRequestsConnectionArgs, never>>;
  salesperson?: Resolver<Maybe<ResolversTypes['Salesperson']>, ParentType, ContextType, RequireFields<QuerySalespersonArgs, never>>;
  salespeopleCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalespeopleCountArgs, never>>;
  salespeopleMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Salesperson']>>>, ParentType, ContextType, RequireFields<QuerySalespeopleManyArgs, never>>;
  salespeoplePage?: Resolver<Maybe<ResolversTypes['SalespersonManyResult']>, ParentType, ContextType, RequireFields<QuerySalespeoplePageArgs, never>>;
  salespeopleConnection?: Resolver<Maybe<ResolversTypes['SalespersonConnection']>, ParentType, ContextType, RequireFields<QuerySalespeopleConnectionArgs, never>>;
  salesPrice?: Resolver<Maybe<ResolversTypes['SalesPrice']>, ParentType, ContextType, RequireFields<QuerySalesPriceArgs, never>>;
  salesPricesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesPricesCountArgs, never>>;
  salesPricesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesPrice']>>>, ParentType, ContextType, RequireFields<QuerySalesPricesManyArgs, never>>;
  salesPricesPage?: Resolver<Maybe<ResolversTypes['SalesPriceManyResult']>, ParentType, ContextType, RequireFields<QuerySalesPricesPageArgs, never>>;
  salesPricesConnection?: Resolver<Maybe<ResolversTypes['SalesPriceConnection']>, ParentType, ContextType, RequireFields<QuerySalesPricesConnectionArgs, never>>;
  salesShipmentHeader?: Resolver<Maybe<ResolversTypes['SalesShipmentHeader']>, ParentType, ContextType, RequireFields<QuerySalesShipmentHeaderArgs, never>>;
  salesShipmentHeadersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesShipmentHeadersCountArgs, never>>;
  salesShipmentHeadersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentHeader']>>>, ParentType, ContextType, RequireFields<QuerySalesShipmentHeadersManyArgs, never>>;
  salesShipmentHeadersPage?: Resolver<Maybe<ResolversTypes['SalesShipmentHeaderManyResult']>, ParentType, ContextType, RequireFields<QuerySalesShipmentHeadersPageArgs, never>>;
  salesShipmentHeadersConnection?: Resolver<Maybe<ResolversTypes['SalesShipmentHeaderConnection']>, ParentType, ContextType, RequireFields<QuerySalesShipmentHeadersConnectionArgs, never>>;
  salesShipmentLine?: Resolver<Maybe<ResolversTypes['SalesShipmentLine']>, ParentType, ContextType, RequireFields<QuerySalesShipmentLineArgs, never>>;
  salesShipmentLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySalesShipmentLinesCountArgs, never>>;
  salesShipmentLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentLine']>>>, ParentType, ContextType, RequireFields<QuerySalesShipmentLinesManyArgs, never>>;
  salesShipmentLinesPage?: Resolver<Maybe<ResolversTypes['SalesShipmentLineManyResult']>, ParentType, ContextType, RequireFields<QuerySalesShipmentLinesPageArgs, never>>;
  salesShipmentLinesConnection?: Resolver<Maybe<ResolversTypes['SalesShipmentLineConnection']>, ParentType, ContextType, RequireFields<QuerySalesShipmentLinesConnectionArgs, never>>;
  serviceItemGroup?: Resolver<Maybe<ResolversTypes['ServiceItemGroup']>, ParentType, ContextType, RequireFields<QueryServiceItemGroupArgs, never>>;
  serviceItemGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryServiceItemGroupsCountArgs, never>>;
  serviceItemGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ServiceItemGroup']>>>, ParentType, ContextType, RequireFields<QueryServiceItemGroupsManyArgs, never>>;
  serviceItemGroupsPage?: Resolver<Maybe<ResolversTypes['ServiceItemGroupManyResult']>, ParentType, ContextType, RequireFields<QueryServiceItemGroupsPageArgs, never>>;
  serviceItemGroupsConnection?: Resolver<Maybe<ResolversTypes['ServiceItemGroupConnection']>, ParentType, ContextType, RequireFields<QueryServiceItemGroupsConnectionArgs, never>>;
  shipmentMethod?: Resolver<Maybe<ResolversTypes['ShipmentMethod']>, ParentType, ContextType, RequireFields<QueryShipmentMethodArgs, never>>;
  shipmentMethodsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryShipmentMethodsCountArgs, never>>;
  shipmentMethodsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShipmentMethod']>>>, ParentType, ContextType, RequireFields<QueryShipmentMethodsManyArgs, never>>;
  shipmentMethodsPage?: Resolver<Maybe<ResolversTypes['ShipmentMethodManyResult']>, ParentType, ContextType, RequireFields<QueryShipmentMethodsPageArgs, never>>;
  shipmentMethodsConnection?: Resolver<Maybe<ResolversTypes['ShipmentMethodConnection']>, ParentType, ContextType, RequireFields<QueryShipmentMethodsConnectionArgs, never>>;
  shippingAgent?: Resolver<Maybe<ResolversTypes['ShippingAgent']>, ParentType, ContextType, RequireFields<QueryShippingAgentArgs, never>>;
  shippingAgentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryShippingAgentsCountArgs, never>>;
  shippingAgentsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShippingAgent']>>>, ParentType, ContextType, RequireFields<QueryShippingAgentsManyArgs, never>>;
  shippingAgentsPage?: Resolver<Maybe<ResolversTypes['ShippingAgentManyResult']>, ParentType, ContextType, RequireFields<QueryShippingAgentsPageArgs, never>>;
  shippingAgentsConnection?: Resolver<Maybe<ResolversTypes['ShippingAgentConnection']>, ParentType, ContextType, RequireFields<QueryShippingAgentsConnectionArgs, never>>;
  signature?: Resolver<Maybe<ResolversTypes['Signature']>, ParentType, ContextType, RequireFields<QuerySignatureArgs, never>>;
  signaturesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySignaturesCountArgs, never>>;
  signaturesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Signature']>>>, ParentType, ContextType, RequireFields<QuerySignaturesManyArgs, never>>;
  signaturesPage?: Resolver<Maybe<ResolversTypes['SignatureManyResult']>, ParentType, ContextType, RequireFields<QuerySignaturesPageArgs, never>>;
  signaturesConnection?: Resolver<Maybe<ResolversTypes['SignatureConnection']>, ParentType, ContextType, RequireFields<QuerySignaturesConnectionArgs, never>>;
  signRequest?: Resolver<Maybe<ResolversTypes['SignRequest']>, ParentType, ContextType, RequireFields<QuerySignRequestArgs, never>>;
  signRequestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySignRequestsCountArgs, never>>;
  signRequestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SignRequest']>>>, ParentType, ContextType, RequireFields<QuerySignRequestsManyArgs, never>>;
  signRequestsPage?: Resolver<Maybe<ResolversTypes['SignRequestManyResult']>, ParentType, ContextType, RequireFields<QuerySignRequestsPageArgs, never>>;
  signRequestsConnection?: Resolver<Maybe<ResolversTypes['SignRequestConnection']>, ParentType, ContextType, RequireFields<QuerySignRequestsConnectionArgs, never>>;
  specialEquipment?: Resolver<Maybe<ResolversTypes['SpecialEquipment']>, ParentType, ContextType, RequireFields<QuerySpecialEquipmentArgs, never>>;
  specialEquipmentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QuerySpecialEquipmentsCountArgs, never>>;
  specialEquipmentsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['SpecialEquipment']>>>, ParentType, ContextType, RequireFields<QuerySpecialEquipmentsManyArgs, never>>;
  specialEquipmentsPage?: Resolver<Maybe<ResolversTypes['SpecialEquipmentManyResult']>, ParentType, ContextType, RequireFields<QuerySpecialEquipmentsPageArgs, never>>;
  specialEquipmentsConnection?: Resolver<Maybe<ResolversTypes['SpecialEquipmentConnection']>, ParentType, ContextType, RequireFields<QuerySpecialEquipmentsConnectionArgs, never>>;
  stockkeepingUnit?: Resolver<Maybe<ResolversTypes['StockkeepingUnit']>, ParentType, ContextType, RequireFields<QueryStockkeepingUnitArgs, never>>;
  stockkeepingUnitsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryStockkeepingUnitsCountArgs, never>>;
  stockkeepingUnitsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['StockkeepingUnit']>>>, ParentType, ContextType, RequireFields<QueryStockkeepingUnitsManyArgs, never>>;
  stockkeepingUnitsPage?: Resolver<Maybe<ResolversTypes['StockkeepingUnitManyResult']>, ParentType, ContextType, RequireFields<QueryStockkeepingUnitsPageArgs, never>>;
  stockkeepingUnitsConnection?: Resolver<Maybe<ResolversTypes['StockkeepingUnitConnection']>, ParentType, ContextType, RequireFields<QueryStockkeepingUnitsConnectionArgs, never>>;
  tariffNumber?: Resolver<Maybe<ResolversTypes['TariffNumber']>, ParentType, ContextType, RequireFields<QueryTariffNumberArgs, never>>;
  tariffNumbersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTariffNumbersCountArgs, never>>;
  tariffNumbersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TariffNumber']>>>, ParentType, ContextType, RequireFields<QueryTariffNumbersManyArgs, never>>;
  tariffNumbersPage?: Resolver<Maybe<ResolversTypes['TariffNumberManyResult']>, ParentType, ContextType, RequireFields<QueryTariffNumbersPageArgs, never>>;
  tariffNumbersConnection?: Resolver<Maybe<ResolversTypes['TariffNumberConnection']>, ParentType, ContextType, RequireFields<QueryTariffNumbersConnectionArgs, never>>;
  taxGroup?: Resolver<Maybe<ResolversTypes['TaxGroup']>, ParentType, ContextType, RequireFields<QueryTaxGroupArgs, never>>;
  taxGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTaxGroupsCountArgs, never>>;
  taxGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaxGroup']>>>, ParentType, ContextType, RequireFields<QueryTaxGroupsManyArgs, never>>;
  taxGroupsPage?: Resolver<Maybe<ResolversTypes['TaxGroupManyResult']>, ParentType, ContextType, RequireFields<QueryTaxGroupsPageArgs, never>>;
  taxGroupsConnection?: Resolver<Maybe<ResolversTypes['TaxGroupConnection']>, ParentType, ContextType, RequireFields<QueryTaxGroupsConnectionArgs, never>>;
  timeRegistration?: Resolver<Maybe<ResolversTypes['TimeRegistration']>, ParentType, ContextType, RequireFields<QueryTimeRegistrationArgs, never>>;
  timeRegistrationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTimeRegistrationsCountArgs, never>>;
  timeRegistrationsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeRegistration']>>>, ParentType, ContextType, RequireFields<QueryTimeRegistrationsManyArgs, never>>;
  timeRegistrationsPage?: Resolver<Maybe<ResolversTypes['TimeRegistrationManyResult']>, ParentType, ContextType, RequireFields<QueryTimeRegistrationsPageArgs, never>>;
  timeRegistrationsConnection?: Resolver<Maybe<ResolversTypes['TimeRegistrationConnection']>, ParentType, ContextType, RequireFields<QueryTimeRegistrationsConnectionArgs, never>>;
  timeSheetDetail?: Resolver<Maybe<ResolversTypes['TimeSheetDetail']>, ParentType, ContextType, RequireFields<QueryTimeSheetDetailArgs, never>>;
  timeSheetDetailsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTimeSheetDetailsCountArgs, never>>;
  timeSheetDetailsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeSheetDetail']>>>, ParentType, ContextType, RequireFields<QueryTimeSheetDetailsManyArgs, never>>;
  timeSheetDetailsPage?: Resolver<Maybe<ResolversTypes['TimeSheetDetailManyResult']>, ParentType, ContextType, RequireFields<QueryTimeSheetDetailsPageArgs, never>>;
  timeSheetDetailsConnection?: Resolver<Maybe<ResolversTypes['TimeSheetDetailConnection']>, ParentType, ContextType, RequireFields<QueryTimeSheetDetailsConnectionArgs, never>>;
  timeSheetLine?: Resolver<Maybe<ResolversTypes['TimeSheetLine']>, ParentType, ContextType, RequireFields<QueryTimeSheetLineArgs, never>>;
  timeSheetLinesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTimeSheetLinesCountArgs, never>>;
  timeSheetLinesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeSheetLine']>>>, ParentType, ContextType, RequireFields<QueryTimeSheetLinesManyArgs, never>>;
  timeSheetLinesPage?: Resolver<Maybe<ResolversTypes['TimeSheetLineManyResult']>, ParentType, ContextType, RequireFields<QueryTimeSheetLinesPageArgs, never>>;
  timeSheetLinesConnection?: Resolver<Maybe<ResolversTypes['TimeSheetLineConnection']>, ParentType, ContextType, RequireFields<QueryTimeSheetLinesConnectionArgs, never>>;
  transactionSpecification?: Resolver<Maybe<ResolversTypes['TransactionSpecification']>, ParentType, ContextType, RequireFields<QueryTransactionSpecificationArgs, never>>;
  transactionSpecificationsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTransactionSpecificationsCountArgs, never>>;
  transactionSpecificationsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionSpecification']>>>, ParentType, ContextType, RequireFields<QueryTransactionSpecificationsManyArgs, never>>;
  transactionSpecificationsPage?: Resolver<Maybe<ResolversTypes['TransactionSpecificationManyResult']>, ParentType, ContextType, RequireFields<QueryTransactionSpecificationsPageArgs, never>>;
  transactionSpecificationsConnection?: Resolver<Maybe<ResolversTypes['TransactionSpecificationConnection']>, ParentType, ContextType, RequireFields<QueryTransactionSpecificationsConnectionArgs, never>>;
  transactionType?: Resolver<Maybe<ResolversTypes['TransactionType']>, ParentType, ContextType, RequireFields<QueryTransactionTypeArgs, never>>;
  transactionTypesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTransactionTypesCountArgs, never>>;
  transactionTypesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionType']>>>, ParentType, ContextType, RequireFields<QueryTransactionTypesManyArgs, never>>;
  transactionTypesPage?: Resolver<Maybe<ResolversTypes['TransactionTypeManyResult']>, ParentType, ContextType, RequireFields<QueryTransactionTypesPageArgs, never>>;
  transactionTypesConnection?: Resolver<Maybe<ResolversTypes['TransactionTypeConnection']>, ParentType, ContextType, RequireFields<QueryTransactionTypesConnectionArgs, never>>;
  transportMethod?: Resolver<Maybe<ResolversTypes['TransportMethod']>, ParentType, ContextType, RequireFields<QueryTransportMethodArgs, never>>;
  transportMethodsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryTransportMethodsCountArgs, never>>;
  transportMethodsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportMethod']>>>, ParentType, ContextType, RequireFields<QueryTransportMethodsManyArgs, never>>;
  transportMethodsPage?: Resolver<Maybe<ResolversTypes['TransportMethodManyResult']>, ParentType, ContextType, RequireFields<QueryTransportMethodsPageArgs, never>>;
  transportMethodsConnection?: Resolver<Maybe<ResolversTypes['TransportMethodConnection']>, ParentType, ContextType, RequireFields<QueryTransportMethodsConnectionArgs, never>>;
  unitOfMeasure?: Resolver<Maybe<ResolversTypes['UnitOfMeasure']>, ParentType, ContextType, RequireFields<QueryUnitOfMeasureArgs, never>>;
  unitOfMeasuresCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryUnitOfMeasuresCountArgs, never>>;
  unitOfMeasuresMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['UnitOfMeasure']>>>, ParentType, ContextType, RequireFields<QueryUnitOfMeasuresManyArgs, never>>;
  unitOfMeasuresPage?: Resolver<Maybe<ResolversTypes['UnitOfMeasureManyResult']>, ParentType, ContextType, RequireFields<QueryUnitOfMeasuresPageArgs, never>>;
  unitOfMeasuresConnection?: Resolver<Maybe<ResolversTypes['UnitOfMeasureConnection']>, ParentType, ContextType, RequireFields<QueryUnitOfMeasuresConnectionArgs, never>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, never>>;
  usersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryUsersCountArgs, never>>;
  usersMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryUsersManyArgs, never>>;
  usersPage?: Resolver<Maybe<ResolversTypes['UserManyResult']>, ParentType, ContextType, RequireFields<QueryUsersPageArgs, never>>;
  usersConnection?: Resolver<Maybe<ResolversTypes['UserConnection']>, ParentType, ContextType, RequireFields<QueryUsersConnectionArgs, never>>;
  userFavorites?: Resolver<Maybe<ResolversTypes['UserFavorites']>, ParentType, ContextType, RequireFields<QueryUserFavoritesArgs, never>>;
  userFavoritesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryUserFavoritesCountArgs, never>>;
  userFavoritesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserFavorites']>>>, ParentType, ContextType, RequireFields<QueryUserFavoritesManyArgs, never>>;
  userFavoritesPage?: Resolver<Maybe<ResolversTypes['UserFavoritesManyResult']>, ParentType, ContextType, RequireFields<QueryUserFavoritesPageArgs, never>>;
  userFavoritesConnection?: Resolver<Maybe<ResolversTypes['UserFavoritesConnection']>, ParentType, ContextType, RequireFields<QueryUserFavoritesConnectionArgs, never>>;
  vatBusinessPostingGroup?: Resolver<Maybe<ResolversTypes['VatBusinessPostingGroup']>, ParentType, ContextType, RequireFields<QueryVatBusinessPostingGroupArgs, never>>;
  vatBusinessPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryVatBusinessPostingGroupsCountArgs, never>>;
  vatBusinessPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['VatBusinessPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryVatBusinessPostingGroupsManyArgs, never>>;
  vatBusinessPostingGroupsPage?: Resolver<Maybe<ResolversTypes['VatBusinessPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryVatBusinessPostingGroupsPageArgs, never>>;
  vatBusinessPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['VatBusinessPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryVatBusinessPostingGroupsConnectionArgs, never>>;
  vatProductPostingGroup?: Resolver<Maybe<ResolversTypes['VatProductPostingGroup']>, ParentType, ContextType, RequireFields<QueryVatProductPostingGroupArgs, never>>;
  vatProductPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryVatProductPostingGroupsCountArgs, never>>;
  vatProductPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['VatProductPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryVatProductPostingGroupsManyArgs, never>>;
  vatProductPostingGroupsPage?: Resolver<Maybe<ResolversTypes['VatProductPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryVatProductPostingGroupsPageArgs, never>>;
  vatProductPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['VatProductPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryVatProductPostingGroupsConnectionArgs, never>>;
  vendor?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<QueryVendorArgs, never>>;
  vendorsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryVendorsCountArgs, never>>;
  vendorsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vendor']>>>, ParentType, ContextType, RequireFields<QueryVendorsManyArgs, never>>;
  vendorsPage?: Resolver<Maybe<ResolversTypes['VendorManyResult']>, ParentType, ContextType, RequireFields<QueryVendorsPageArgs, never>>;
  vendorsConnection?: Resolver<Maybe<ResolversTypes['VendorConnection']>, ParentType, ContextType, RequireFields<QueryVendorsConnectionArgs, never>>;
  vendorBankAccount?: Resolver<Maybe<ResolversTypes['VendorBankAccount']>, ParentType, ContextType, RequireFields<QueryVendorBankAccountArgs, never>>;
  vendorBankAccountsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryVendorBankAccountsCountArgs, never>>;
  vendorBankAccountsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorBankAccount']>>>, ParentType, ContextType, RequireFields<QueryVendorBankAccountsManyArgs, never>>;
  vendorBankAccountsPage?: Resolver<Maybe<ResolversTypes['VendorBankAccountManyResult']>, ParentType, ContextType, RequireFields<QueryVendorBankAccountsPageArgs, never>>;
  vendorBankAccountsConnection?: Resolver<Maybe<ResolversTypes['VendorBankAccountConnection']>, ParentType, ContextType, RequireFields<QueryVendorBankAccountsConnectionArgs, never>>;
  vendorPostingGroup?: Resolver<Maybe<ResolversTypes['VendorPostingGroup']>, ParentType, ContextType, RequireFields<QueryVendorPostingGroupArgs, never>>;
  vendorPostingGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryVendorPostingGroupsCountArgs, never>>;
  vendorPostingGroupsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorPostingGroup']>>>, ParentType, ContextType, RequireFields<QueryVendorPostingGroupsManyArgs, never>>;
  vendorPostingGroupsPage?: Resolver<Maybe<ResolversTypes['VendorPostingGroupManyResult']>, ParentType, ContextType, RequireFields<QueryVendorPostingGroupsPageArgs, never>>;
  vendorPostingGroupsConnection?: Resolver<Maybe<ResolversTypes['VendorPostingGroupConnection']>, ParentType, ContextType, RequireFields<QueryVendorPostingGroupsConnectionArgs, never>>;
  warehouseClass?: Resolver<Maybe<ResolversTypes['WarehouseClass']>, ParentType, ContextType, RequireFields<QueryWarehouseClassArgs, never>>;
  warehouseClassesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryWarehouseClassesCountArgs, never>>;
  warehouseClassesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['WarehouseClass']>>>, ParentType, ContextType, RequireFields<QueryWarehouseClassesManyArgs, never>>;
  warehouseClassesPage?: Resolver<Maybe<ResolversTypes['WarehouseClassManyResult']>, ParentType, ContextType, RequireFields<QueryWarehouseClassesPageArgs, never>>;
  warehouseClassesConnection?: Resolver<Maybe<ResolversTypes['WarehouseClassConnection']>, ParentType, ContextType, RequireFields<QueryWarehouseClassesConnectionArgs, never>>;
  webBooking?: Resolver<Maybe<ResolversTypes['WebBooking']>, ParentType, ContextType, RequireFields<QueryWebBookingArgs, never>>;
  webBookingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryWebBookingsCountArgs, never>>;
  webBookingsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebBooking']>>>, ParentType, ContextType, RequireFields<QueryWebBookingsManyArgs, never>>;
  webBookingsPage?: Resolver<Maybe<ResolversTypes['WebBookingManyResult']>, ParentType, ContextType, RequireFields<QueryWebBookingsPageArgs, never>>;
  webBookingsConnection?: Resolver<Maybe<ResolversTypes['WebBookingConnection']>, ParentType, ContextType, RequireFields<QueryWebBookingsConnectionArgs, never>>;
  webhookSubscription?: Resolver<Maybe<ResolversTypes['WebhookSubscription']>, ParentType, ContextType, RequireFields<QueryWebhookSubscriptionArgs, never>>;
  webhookSubscriptionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryWebhookSubscriptionsCountArgs, never>>;
  webhookSubscriptionsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebhookSubscription']>>>, ParentType, ContextType, RequireFields<QueryWebhookSubscriptionsManyArgs, never>>;
  webhookSubscriptionsPage?: Resolver<Maybe<ResolversTypes['WebhookSubscriptionManyResult']>, ParentType, ContextType, RequireFields<QueryWebhookSubscriptionsPageArgs, never>>;
  webhookSubscriptionsConnection?: Resolver<Maybe<ResolversTypes['WebhookSubscriptionConnection']>, ParentType, ContextType, RequireFields<QueryWebhookSubscriptionsConnectionArgs, never>>;
  workLog?: Resolver<Maybe<ResolversTypes['WorkLog']>, ParentType, ContextType, RequireFields<QueryWorkLogArgs, never>>;
  workLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryWorkLogsCountArgs, never>>;
  workLogsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkLog']>>>, ParentType, ContextType, RequireFields<QueryWorkLogsManyArgs, never>>;
  workLogsPage?: Resolver<Maybe<ResolversTypes['WorkLogManyResult']>, ParentType, ContextType, RequireFields<QueryWorkLogsPageArgs, never>>;
  workLogsConnection?: Resolver<Maybe<ResolversTypes['WorkLogConnection']>, ParentType, ContextType, RequireFields<QueryWorkLogsConnectionArgs, never>>;
  workType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType, RequireFields<QueryWorkTypeArgs, never>>;
  workTypesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryWorkTypesCountArgs, never>>;
  workTypesMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkType']>>>, ParentType, ContextType, RequireFields<QueryWorkTypesManyArgs, never>>;
  workTypesPage?: Resolver<Maybe<ResolversTypes['WorkTypeManyResult']>, ParentType, ContextType, RequireFields<QueryWorkTypesPageArgs, never>>;
  workTypesConnection?: Resolver<Maybe<ResolversTypes['WorkTypeConnection']>, ParentType, ContextType, RequireFields<QueryWorkTypesConnectionArgs, never>>;
  myAccessRequestsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessRequest']>>>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  customerDomainsLimited?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerDomainLimited']>>>, ParentType, ContextType, RequireFields<QueryCustomerDomainsLimitedArgs, never>>;
  domainsLimited?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainLimited']>>>, ParentType, ContextType, RequireFields<QueryDomainsLimitedArgs, never>>;
  siblingDomains?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainLimited']>>>, ParentType, ContextType>;
  safetyRespectDomains?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainLimited']>>>, ParentType, ContextType>;
  domain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType, RequireFields<QueryDomainArgs, never>>;
  domainsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Domain']>>>, ParentType, ContextType, RequireFields<QueryDomainsManyArgs, never>>;
  domainsPage?: Resolver<Maybe<ResolversTypes['DomainManyResult']>, ParentType, ContextType, RequireFields<QueryDomainsPageArgs, never>>;
  domainIntegration?: Resolver<Maybe<ResolversTypes['DomainIntegration']>, ParentType, ContextType, RequireFields<QueryDomainIntegrationArgs, never>>;
  domainIntegrationsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomainIntegration']>>>, ParentType, ContextType, RequireFields<QueryDomainIntegrationsManyArgs, never>>;
  myCustomerProjects?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerProject']>>>, ParentType, ContextType, RequireFields<QueryMyCustomerProjectsArgs, never>>;
  documentsMany?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType, RequireFields<QueryDocumentsManyArgs, never>>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<QueryDocumentArgs, never>>;
  documentsPage?: Resolver<Maybe<ResolversTypes['DocumentManyResult']>, ParentType, ContextType, RequireFields<QueryDocumentsPageArgs, never>>;
  objectTypeLimited?: Resolver<Maybe<ResolversTypes['ObjectTypeLimited']>, ParentType, ContextType, RequireFields<QueryObjectTypeLimitedArgs, never>>;
  rentalEventsMany?: Resolver<Maybe<ResolversTypes['RentalEvent']>, ParentType, ContextType, RequireFields<QueryRentalEventsManyArgs, never>>;
  rentalLinesCalculated?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLine']>>>, ParentType, ContextType, RequireFields<QueryRentalLinesCalculatedArgs, 'calculationType'>>;
  rentCost?: Resolver<Maybe<ResolversTypes['RentCost']>, ParentType, ContextType, RequireFields<QueryRentCostArgs, never>>;
  accumulatedRentCost?: Resolver<Maybe<ResolversTypes['AccumulatedRentCost']>, ParentType, ContextType, RequireFields<QueryAccumulatedRentCostArgs, never>>;
  dailyRentalEvents?: Resolver<Maybe<ResolversTypes['DailyRentalEvents']>, ParentType, ContextType>;
  SalesOrderChangeTrackingPage?: Resolver<Maybe<ResolversTypes['SalesOrderChangeTrackingPageResponse']>, ParentType, ContextType, RequireFields<QuerySalesOrderChangeTrackingPageArgs, 'updatedAfter'>>;
  autocomplete?: Resolver<Maybe<Array<Maybe<ResolversTypes['AutoComplete']>>>, ParentType, ContextType, RequireFields<QueryAutocompleteArgs, never>>;
  invitationsPage?: Resolver<Maybe<ResolversTypes['InvitationManyResult']>, ParentType, ContextType, RequireFields<QueryInvitationsPageArgs, never>>;
  invitation?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType, RequireFields<QueryInvitationArgs, never>>;
  myInvitation?: Resolver<Maybe<ResolversTypes['InvitationLimited']>, ParentType, ContextType, RequireFields<QueryMyInvitationArgs, never>>;
  countFinishedFunctiontalTests?: Resolver<Maybe<ResolversTypes['OldFunctionTestCountResult']>, ParentType, ContextType, RequireFields<QueryCountFinishedFunctiontalTestsArgs, 'filter'>>;
};

export type QuoteHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteHeader'] = ResolversParentTypes['QuoteHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesPerson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExternalText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternalText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectSum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LicenseSum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LicenseFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteHeaderConnection'] = ResolversParentTypes['QuoteHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['QuoteHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteHeaderConnectionEdge'] = ResolversParentTypes['QuoteHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['QuoteHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteHeaderDeleteResponse'] = ResolversParentTypes['QuoteHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteHeaderManyResult'] = ResolversParentTypes['QuoteHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteLine'] = ResolversParentTypes['QuoteLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Task?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaskGrouping?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Sum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicedSum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnOrderSum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LinkedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteLineConnection'] = ResolversParentTypes['QuoteLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['QuoteLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteLineConnectionEdge'] = ResolversParentTypes['QuoteLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['QuoteLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteLineDeleteResponse'] = ResolversParentTypes['QuoteLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteLineManyResult'] = ResolversParentTypes['QuoteLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteTaskGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteTaskGroup'] = ResolversParentTypes['QuoteTaskGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Group?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteTaskGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteTaskGroupConnection'] = ResolversParentTypes['QuoteTaskGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['QuoteTaskGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteTaskGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteTaskGroupConnectionEdge'] = ResolversParentTypes['QuoteTaskGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['QuoteTaskGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteTaskGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteTaskGroupDeleteResponse'] = ResolversParentTypes['QuoteTaskGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteTaskGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuoteTaskGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuoteTaskGroupManyResult'] = ResolversParentTypes['QuoteTaskGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuoteTaskGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveCodesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemoveCodesResponse'] = ResolversParentTypes['RemoveCodesResponse']> = {
  codesRemoved?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentCostResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentCost'] = ResolversParentTypes['RentCost']> = {
  TotalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Lines?: Resolver<Maybe<Array<Maybe<ResolversTypes['LineAmount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalEvent'] = ResolversParentTypes['RentalEvent']> = {
  StartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reservations?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLine']>>>, ParentType, ContextType>;
  returnLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollLine']>>>, ParentType, ContextType>;
  shipmentLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptLine']>>>, ParentType, ContextType>;
  reservationRequestLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestLine']>>>, ParentType, ContextType>;
  returnRequestLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestLine']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalHeader'] = ResolversParentTypes['RentalHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChangeStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChangeStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BreakInvoiceatExpDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Printed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrintedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceFrequencyNOTINUSE?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceFrequencyNOTINUSEOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastInvoiceDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextInvoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ContractDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  StartRentalPeriodDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TermofContract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AnnualAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountperPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CombineInvoices?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Prepaid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NextInvoicePeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalZoneCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CombineMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CancelReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalOrderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TemplateNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerRentalPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSalesPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSalesDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Probability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextInvoicePeriodStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextInvoicePeriodEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  FromQuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TempInvoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contractterminatedcomplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NextRentalInvoiceFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextRentalInvoiceTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TempReservation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TempDelivery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TempInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TempDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TempDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoicingStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoicingStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProjectDimension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesHeaderNoSale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DoNotShowDialogPrompt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalStartingFee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TempStartRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FrequenceFixDaysNOTINUSE?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerRentalDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceSequence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoMobilePhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationManagerContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectManagerContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FixedPriceContract?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDepositCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDepositAmttoPay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GrantedDeposit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInvoiceDayofMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MemoText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JournalEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseRentalJnlNos?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FullyReturned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalAmountExclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalLineDiscAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalTotalAmountExclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalAmountInclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalVatAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesAmountExclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesLineDiscAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesTotalAmountExclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesAmountInclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesVatAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesQtyToInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurancAmtExclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurancAmtInclVat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalContractCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompressPrepayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UsePrepmtExpirationDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteExpiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  QuoteStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPONo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextInvoiceDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  TypeFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeFilterOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCodeFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPostedInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofUnpostedInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ContractInvoiceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPickOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofShipmentOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofCollectionOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofWorkOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationManagerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectManagerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerDepositOutstAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CPNextInvoiceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustOrganizationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtPaidAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtUnpaidAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtPaidAmountInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtUnpaidAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalHeaderConnection'] = ResolversParentTypes['RentalHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalHeaderConnectionEdge'] = ResolversParentTypes['RentalHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalHeaderDeleteResponse'] = ResolversParentTypes['RentalHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalHeaderManyResult'] = ResolversParentTypes['RentalHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLine'] = ResolversParentTypes['RentalLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartRentalPeriod?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndRentalPeriod?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoicedToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextInvoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDatesInGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['DateTime']>>>, ParentType, ContextType>;
  RentalPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NextInvoicePeriodStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextInvoicePeriodEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalPeriodInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Credited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalPeriodNow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalChargeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalChargeDaysOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveredTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransferdToOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPriceSale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedSeasonCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedCounterperDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSubProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedCounter2perDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkInprogress?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PctofSalesPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtUseExpirationDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtSalesLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInvoiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInvoiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtCrMemoStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtCrMemoStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtInsuranceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInOrderInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceAmtInOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmttoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmttoDeductVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeductedVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtRentalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceToDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsAmtOrderIncVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtDimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtVATProdPostingGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OldAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedtoSwitchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedStandByLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StandbyStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StandbyStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyonAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITMother?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KITFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITDontShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  KITMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITFactorPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITOriginalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineAmountNet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineAmountGross?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITOriginalSqMeter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SquareMeterperKIT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITPriceperSqMeter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalKITPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultKITPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultKITPriceMarked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DefaultKITFactorPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITComponentUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITComponentRentalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OldTmplMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnlyonFirstOrderInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OffRentQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DebitonReturnDateBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounteronReturnBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReminderBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpdateLineBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnPoleSplitBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnCompleteBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoicedToDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiveLocationCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnTimeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmountCardBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositAmountCashBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReclassQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReclassDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrFromDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrInvToDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrReturnDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrCloseRentalBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CorrCloseRentalBufferOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrUpdEntryBasisBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CorrQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CorrReturnQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReclassFromContractBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReclassToContractBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DepositAmountTnsfBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OffRentTimeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrQtyShippedtoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TempEntryBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturntoSellBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturntoDamageBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onReturnBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CorrOnRentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrNextInvDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CollectionDocNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CollectionLineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliverQuantityNowBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConsumptionatReturnBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditonEarlyBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StartRentalPeriodBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConsumpatDeliveryBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositAmountBackBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTypeBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTypeBufferOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PstDispatchDocNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PstDispatchLineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedInDocumentBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMServiceDocBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPriceBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineMarkedBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PostingDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseInOpenPeriodBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AttachedtoLineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounteronShipmentBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onShipmentBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRentFreeDaysBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRentFreeHourBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonforRentFreeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiveRespCenterBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FullyConsumedAccBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReceivingBinCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SuspendLineNoBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitPriceNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceTermCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPriceprDayNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceprMonthNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmountNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalPeriodNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmountNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPriceCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairDiscountCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Preliminary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReferenceTableID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReferenceUniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReplacementUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReRentObject?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReRentCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceprDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceprMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllocateObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositOrigUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MarkDepositEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DepositUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReplacementPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositReturnPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AgreedToDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DontTransfertoOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveredBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleReserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnQuantityHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndRentalDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReturnDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DoShipmentNow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtyHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnQuantityChargeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TerminPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmountPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IgnorCalendarCheck?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MeasurementatDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MeasurementatReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeMotherRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnAdditionMotherRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPriceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtraHoursReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TmpAccumEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MarkReclassLineNoRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MarkReclassQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingPriority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingPriorityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassFromLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassOrderedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MarkReclassLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FilterReclassLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReclassFromContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassToContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassToLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DiamondBladeItem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IgnoreVaryingCompQty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExploreLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EnvironmentalFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExtraHoursCharged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExtraHoursReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceonExtraHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassDocLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalSplitCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricePriority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricePriorityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalNetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecursiveLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Repurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CPNextInvoiceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CPAdvancePaymentStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CPAdvancePaymentStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CPAdvancePaymentDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  StairDiscountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPriceDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceiveRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiveCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisconExHours?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuantitytoAllocate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AdditionalCharge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AdjforAdditionalCharge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceNoTemp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTask?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTaskOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledDeliveryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledDeliveryTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledPickupDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledPickupTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryDriver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupDriver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Driver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SchedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SchedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualDeliveryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualDeliveryTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualPickupDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualPickupTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualReturnDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRepHoursPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumRentaltoDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultKITPriceCompFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssignedtoKIT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDateFlow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnDateFlow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityShippedtoFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityReturnedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityOffRentedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityPstOffRentedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityPstReturnedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityPstShippedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonPickOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonShipmentOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonCollectionOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UserLocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofWorkOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPickOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofShipmentOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofCollectionOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPostedPickOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdShptOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdCollOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRntlReservSummaries?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRntlReservEntries?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonRntlReservEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonRntlResShptEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonRntlResRetEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonBufferEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MultipleRentalPeriodPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MultipleRentalPeriodEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastErrorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SaleReservedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleReservedQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITNetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReportedRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DispatchDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupOnNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PeriodLineAmountEdge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RemainingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DayNetPriceEdge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Vaccation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ReturnReservationActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UserExpectedReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PSILink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customerProject?: Resolver<Maybe<ResolversTypes['CustomerProject']>, ParentType, ContextType>;
  ActualGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineConnection'] = ResolversParentTypes['RentalLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineConnectionEdge'] = ResolversParentTypes['RentalLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineDeleteResponse'] = ResolversParentTypes['RentalLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineManyResult'] = ResolversParentTypes['RentalLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineSnapshotResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineSnapshot'] = ResolversParentTypes['RentalLineSnapshot']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContractStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartRentalPeriod?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndRentalPeriod?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoicedToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextInvoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDatesInGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['DateTime']>>>, ParentType, ContextType>;
  RentalPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NextInvoicePeriodStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextInvoicePeriodEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalPeriodInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UseHourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Credited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalPeriodNow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalChargeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalChargeDaysOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveredTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransferdToOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Counter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPriceSale?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedSeasonCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedCounterperDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSubProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedCounter2perDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkInprogress?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PctofSalesPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtUseExpirationDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtSalesLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInvoiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInvoiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtCrMemoStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtCrMemoStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtInsuranceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInOrderInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceAmtInOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmttoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmttoDeductVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeductedVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtRentalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceToDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsuranceAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtInsAmtOrderIncVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtDimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtVATProdPostingGrp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OldAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedtoSwitchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedStandByLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StandbyStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StandbyStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyonAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITMother?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KITFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITDontShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  KITMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITFactorPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITOriginalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineAmountNet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineAmountGross?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITOriginalSqMeter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SquareMeterperKIT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITPriceperSqMeter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalKITPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultKITPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultKITPriceMarked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DefaultKITFactorPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITComponentUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITComponentRentalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITLineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OldTmplMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnlyonFirstOrderInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OffRentQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DebitonReturnDateBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounteronReturnBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReminderBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpdateLineBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnPoleSplitBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnCompleteBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoicedToDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiveLocationCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnTimeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmountCardBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositAmountCashBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReclassQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReclassDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrFromDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrInvToDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrReturnDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrCloseRentalBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CorrCloseRentalBufferOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrUpdEntryBasisBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CorrQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CorrReturnQuantityBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReclassFromContractBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReclassToContractBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MaintenanceBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DepositAmountTnsfBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OffRentTimeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrQtyShippedtoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TempEntryBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturntoSellBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturntoDamageBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onReturnBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CorrOnRentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CorrNextInvDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CollectionDocNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CollectionLineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliverQuantityNowBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConsumptionatReturnBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditonEarlyBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StartRentalPeriodBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConsumpatDeliveryBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositAmountBackBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTypeBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTypeBufferOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PstDispatchDocNoBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PstDispatchLineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedInDocumentBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMServiceDocBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPriceBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineMarkedBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PostingDateBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseInOpenPeriodBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AttachedtoLineNoBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounteronShipmentBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onShipmentBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRentFreeDaysBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRentFreeHourBuffer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonforRentFreeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiveRespCenterBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FullyConsumedAccBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReceivingBinCodeBuffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SuspendLineNoBuffer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitPriceNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceTermCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPriceprDayNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceprMonthNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmountNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalPeriodNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmountNew?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPriceCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairDiscountCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCodeNew?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5New?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Preliminary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReferenceTableID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReferenceUniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UniqueID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreatedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReplacementUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReRentObject?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReRentCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceprDay?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPriceprMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllocateObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositOrigUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MarkDepositEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DepositUnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReplacementPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositReturnPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AgreedToDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepositPostingType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DepositPostingTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DontTransfertoOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveredBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SaleLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleReserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnQuantityHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndRentalDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReturnDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DoShipmentNow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtyHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDateTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnQuantityChargeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TerminPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmountPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IgnorCalendarCheck?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MeasurementatDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MeasurementatReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeMotherRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnAdditionMotherRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPriceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtraHoursReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TmpAccumEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MarkReclassLineNoRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MarkReclassQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingPriority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingPriorityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassFromLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassOrderedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MarkReclassLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FilterReclassLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReclassFromContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassToContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassToLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DiamondBladeItem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IgnoreVaryingCompQty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExploreLine?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EnvironmentalFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExtraHoursCharged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExtraHoursReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceonExtraHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReclassDocLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalSplitCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricePriority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PricePriorityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalNetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RecursiveLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Repurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CPNextInvoiceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CPAdvancePaymentStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CPAdvancePaymentStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CPAdvancePaymentDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  StairDiscountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPriceDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceiveRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceiveCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisconExHours?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuantitytoAllocate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ServiceMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AdditionalCharge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AdjforAdditionalCharge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceNoTemp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTask?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchTaskOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledDeliveryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledDeliveryTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledPickupDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledPickupTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryDriver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickupDriver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Driver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SchedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SchedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualDeliveryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualDeliveryTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualPickupDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualPickupTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualReturnDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRepHoursPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DispatchDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumRentaltoDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultKITPriceCompFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssignedtoKIT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OffRentDateFlow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnDateFlow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityShippedtoFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityReturnedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityOffRentedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityPstOffRentedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityPstReturnedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityPstShippedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonPickOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonShipmentOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonCollectionOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UserLocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofWorkOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPickOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofShipmentOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofCollectionOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPostedPickOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdShptOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdCollOrderLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRntlReservSummaries?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofRntlReservEntries?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonRntlReservEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonRntlResShptEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonRntlResRetEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonBufferEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MultipleRentalPeriodPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MultipleRentalPeriodEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastErrorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SaleReservedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SaleReservedQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITNetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReportedRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DispatchDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupOnNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PeriodLineAmountEdge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RemainingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DayNetPriceEdge?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Vaccation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ReturnReservationActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UserExpectedReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PSILink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SnapshotOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rentalLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLine']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineSnapshotConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineSnapshotConnection'] = ResolversParentTypes['RentalLineSnapshotConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalLineSnapshotConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineSnapshotConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineSnapshotConnectionEdge'] = ResolversParentTypes['RentalLineSnapshotConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalLineSnapshot'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineSnapshotDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineSnapshotDeleteResponse'] = ResolversParentTypes['RentalLineSnapshotDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLineSnapshot']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalLineSnapshotManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalLineSnapshotManyResult'] = ResolversParentTypes['RentalLineSnapshotManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalLineSnapshot']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollHeader'] = ResolversParentTypes['RentalPostedCollHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OffRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CollectionStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CollectionStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReceivingLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Requestedby?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Receivedby?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoMobilePhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Broker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Carrier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Driver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BOLSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollHeaderConnection'] = ResolversParentTypes['RentalPostedCollHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalPostedCollHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollHeaderConnectionEdge'] = ResolversParentTypes['RentalPostedCollHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalPostedCollHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollHeaderDeleteResponse'] = ResolversParentTypes['RentalPostedCollHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollHeaderManyResult'] = ResolversParentTypes['RentalPostedCollHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollLine'] = ResolversParentTypes['RentalPostedCollLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExtRefNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSubProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LoadSequenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceStairNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairDiscountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OffRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OffRentUpdated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuantitySold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityDamaged?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityOffRented?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceivingLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CounteronShipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounteronReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Maintenance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMServiceDoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onShipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeasurementatDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MeasurementatReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FullyConsumedAccessory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ScannedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScannedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ScannedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NumberofRentFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NumberofRentFreeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonforRentFree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditonEarlyOffRent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceonExtraHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PstDeliveryEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReceivingBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliedfromDeliverEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRepHoursPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumRentaltoDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollLineConnection'] = ResolversParentTypes['RentalPostedCollLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalPostedCollLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollLineConnectionEdge'] = ResolversParentTypes['RentalPostedCollLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalPostedCollLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollLineDeleteResponse'] = ResolversParentTypes['RentalPostedCollLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedCollLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedCollLineManyResult'] = ResolversParentTypes['RentalPostedCollLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedCollLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptHeader'] = ResolversParentTypes['RentalPostedShptHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoMobilePhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Broker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Carrier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Driver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BOLSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptHeaderConnection'] = ResolversParentTypes['RentalPostedShptHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalPostedShptHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptHeaderConnectionEdge'] = ResolversParentTypes['RentalPostedShptHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalPostedShptHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptHeaderDeleteResponse'] = ResolversParentTypes['RentalPostedShptHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptHeaderManyResult'] = ResolversParentTypes['RentalPostedShptHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptLine'] = ResolversParentTypes['RentalPostedShptLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExtRefNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerSubProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LoadSequenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPriceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairDiscountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CounteronShipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounteronReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceDoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Counter2onShipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MeasurementatDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ScannedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScannedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ScannedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuantityCollected?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CollectionDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CollectionLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreeRentalHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinRepHoursPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StairPrice1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StairPrice5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumRentaltoDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UseAdvancedMgtCalendar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptLineConnection'] = ResolversParentTypes['RentalPostedShptLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalPostedShptLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptLineConnectionEdge'] = ResolversParentTypes['RentalPostedShptLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalPostedShptLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptLineDeleteResponse'] = ResolversParentTypes['RentalPostedShptLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPostedShptLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPostedShptLineManyResult'] = ResolversParentTypes['RentalPostedShptLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPostedShptLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPriceTermsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPriceTerms'] = ResolversParentTypes['RentalPriceTerms']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeFullPeriod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReportedHours?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FixedDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SquareMeter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FullTerm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  HalfTerm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FixedBillingPeriod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPriceTermsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPriceTermsConnection'] = ResolversParentTypes['RentalPriceTermsConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalPriceTermsConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPriceTermsConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPriceTermsConnectionEdge'] = ResolversParentTypes['RentalPriceTermsConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalPriceTerms'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPriceTermsDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPriceTermsDeleteResponse'] = ResolversParentTypes['RentalPriceTermsDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPriceTerms']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalPriceTermsManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalPriceTermsManyResult'] = ResolversParentTypes['RentalPriceTermsManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalPriceTerms']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalReturnEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalReturnEntry'] = ResolversParentTypes['RentalReturnEntry']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExtRentalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EntryType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostedinDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PostingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransferredtoOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartRentalPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  InvoicedToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OffRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  OffRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReturnTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitonReturnDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RemainingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UndoEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DispatchType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PstDispatchDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PstDispatchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DoPostReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoPostOffRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OrigOffRentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KITDontShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  KITMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NumberofRentFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NumberofRentFreeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReasonforRentFree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditonEarlyReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantitySold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityDamaged?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CounteronReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Maintenance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CounteronShipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Counter2onShipment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQuantityTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TmpLocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompleteReturn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MeasurementatDelivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MeasurementatReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondBladeItem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FullyConsumedAccessory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FullReturnSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtyReserved?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoCollect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityReturned?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  HourlyRent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtyHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OnRentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReceivingBinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PriceonExtraHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KITComponent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMServiceDoc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IgnoreVaryingCompQty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QtyPstCollectedFlow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PstdCollOrderDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RentalClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  BaseCalendar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineAmountPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProjectedReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  InvoiceToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiamondQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DontTransfertoOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MonthPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalReturnEntryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalReturnEntryConnection'] = ResolversParentTypes['RentalReturnEntryConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RentalReturnEntryConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalReturnEntryConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalReturnEntryConnectionEdge'] = ResolversParentTypes['RentalReturnEntryConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RentalReturnEntry'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalReturnEntryDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalReturnEntryDeleteResponse'] = ResolversParentTypes['RentalReturnEntryDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalReturnEntry']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalReturnEntryManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RentalReturnEntryManyResult'] = ResolversParentTypes['RentalReturnEntryManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RentalReturnEntry']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestHeader'] = ResolversParentTypes['RequestHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RequestTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchHeader?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DispatchPosted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestHeaderConnection'] = ResolversParentTypes['RequestHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RequestHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestHeaderConnectionEdge'] = ResolversParentTypes['RequestHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RequestHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestHeaderDeleteResponse'] = ResolversParentTypes['RequestHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestHeaderManyResult'] = ResolversParentTypes['RequestHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestLine'] = ResolversParentTypes['RequestLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RequestTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ManufacturerModelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestLineConnection'] = ResolversParentTypes['RequestLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RequestLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestLineConnectionEdge'] = ResolversParentTypes['RequestLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RequestLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestLineDeleteResponse'] = ResolversParentTypes['RequestLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestLineManyResult'] = ResolversParentTypes['RequestLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestHeader'] = ResolversParentTypes['ReservationRequestHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Header?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TypeOfRequest?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Confirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpectedReturnDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DomainName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Executed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Pickup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestHeaderConnection'] = ResolversParentTypes['ReservationRequestHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ReservationRequestHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestHeaderConnectionEdge'] = ResolversParentTypes['ReservationRequestHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ReservationRequestHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestHeaderDeleteResponse'] = ResolversParentTypes['ReservationRequestHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestHeaderManyResult'] = ResolversParentTypes['ReservationRequestHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestLine'] = ResolversParentTypes['ReservationRequestLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Header?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TypeOfRequest?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Confirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Executed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestLineConnection'] = ResolversParentTypes['ReservationRequestLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ReservationRequestLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestLineConnectionEdge'] = ResolversParentTypes['ReservationRequestLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ReservationRequestLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestLineDeleteResponse'] = ResolversParentTypes['ReservationRequestLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationRequestLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservationRequestLineManyResult'] = ResolversParentTypes['ReservationRequestLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservationRequestLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Resource'] = ResolversParentTypes['Resource']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SocialSecurityNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Education?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EmploymentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ResourceGroupNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseUnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DirectUnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  IndirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceProfitCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceProfitCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutomaticExtTexts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerPurchGLAccNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseTimeSheet?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TimeSheetOwnerUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeSheetApproverUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceZoneFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Mounting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeableFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Capacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonOrderJob?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyQuotedJob?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsageQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsageCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UsagePrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonAssemblyOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonServiceOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InCustomerZone?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShowInFieldOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceConnection'] = ResolversParentTypes['ResourceConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ResourceConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceConnectionEdge'] = ResolversParentTypes['ResourceConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Resource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceDeleteResponse'] = ResolversParentTypes['ResourceDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resource']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceManyResult'] = ResolversParentTypes['ResourceManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resource']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponsibilityCenterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponsibilityCenter'] = ResolversParentTypes['ResponsibilityCenter']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMailSignatureText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContractGainLossAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Longitud?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Latitud?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponsibilityCenterConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponsibilityCenterConnection'] = ResolversParentTypes['ResponsibilityCenterConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ResponsibilityCenterConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponsibilityCenterConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponsibilityCenterConnectionEdge'] = ResolversParentTypes['ResponsibilityCenterConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ResponsibilityCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponsibilityCenterDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponsibilityCenterDeleteResponse'] = ResolversParentTypes['ResponsibilityCenterDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ResponsibilityCenter']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponsibilityCenterManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponsibilityCenterManyResult'] = ResolversParentTypes['ResponsibilityCenterManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ResponsibilityCenter']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponsibilityCenterMatrixResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResponsibilityCenterMatrix'] = ResolversParentTypes['ResponsibilityCenterMatrix']> = {
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RetrySyncResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RetrySyncResponse'] = ResolversParentTypes['RetrySyncResponse']> = {
  Success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestHeader'] = ResolversParentTypes['ReturnRequestHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Header?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TypeOfRequest?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Confirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PickUpDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Executed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DropOff?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestHeaderConnection'] = ResolversParentTypes['ReturnRequestHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ReturnRequestHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestHeaderConnectionEdge'] = ResolversParentTypes['ReturnRequestHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ReturnRequestHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestHeaderDeleteResponse'] = ResolversParentTypes['ReturnRequestHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestHeaderManyResult'] = ResolversParentTypes['ReturnRequestHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestLine'] = ResolversParentTypes['ReturnRequestLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Header?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TypeOfRequest?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Confirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Executed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  header?: Resolver<Maybe<ResolversTypes['ReturnRequestHeader']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestLineConnection'] = ResolversParentTypes['ReturnRequestLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ReturnRequestLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestLineConnectionEdge'] = ResolversParentTypes['ReturnRequestLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ReturnRequestLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestLineDeleteResponse'] = ResolversParentTypes['ReturnRequestLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReturnRequestLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReturnRequestLineManyResult'] = ResolversParentTypes['ReturnRequestLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReturnRequestLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisHeader'] = ResolversParentTypes['RiskAnalysisHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkPlace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedByPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Database?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecordID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandler?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandlerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrentHandlerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinishedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisHeaderConnection'] = ResolversParentTypes['RiskAnalysisHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RiskAnalysisHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisHeaderConnectionEdge'] = ResolversParentTypes['RiskAnalysisHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RiskAnalysisHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisHeaderDeleteResponse'] = ResolversParentTypes['RiskAnalysisHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisHeaderManyResult'] = ResolversParentTypes['RiskAnalysisHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisLine'] = ResolversParentTypes['RiskAnalysisLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RiskAnalysisNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RiskCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RiskCodeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Risk?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Probability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProbabilityOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Consequence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConsequenceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Mandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisLineConnection'] = ResolversParentTypes['RiskAnalysisLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RiskAnalysisLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisLineConnectionEdge'] = ResolversParentTypes['RiskAnalysisLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RiskAnalysisLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisLineDeleteResponse'] = ResolversParentTypes['RiskAnalysisLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisLineManyResult'] = ResolversParentTypes['RiskAnalysisLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisSignatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisSignature'] = ResolversParentTypes['RiskAnalysisSignature']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RiskAnalysisNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TypeOfSignature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOfSignatureOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisSignatureConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisSignatureConnection'] = ResolversParentTypes['RiskAnalysisSignatureConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RiskAnalysisSignatureConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisSignatureConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisSignatureConnectionEdge'] = ResolversParentTypes['RiskAnalysisSignatureConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RiskAnalysisSignature'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisSignatureDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisSignatureDeleteResponse'] = ResolversParentTypes['RiskAnalysisSignatureDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisSignature']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskAnalysisSignatureManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RiskAnalysisSignatureManyResult'] = ResolversParentTypes['RiskAnalysisSignatureManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['RiskAnalysisSignature']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeader'] = ResolversParentTypes['SalesHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['SalesHeaderDocumentTypeEnum']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Ship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Invoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrintPostedDocuments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShippingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastShippingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineShipments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SendICDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompressPrepayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtCrMemoNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtPaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteValidUntilDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  QuoteSenttoCustomer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuoteAcceptedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  JobQueueStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQueueStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueEntryID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncomingDocumentEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentServiceSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectDebitMandateID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocNoOccurrence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OpportunityNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['SalesHeaderShippingAdviceEnum']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingfromWhseRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Receive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GetShipmentUsed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceInvNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceInvVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceInvTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMShipmentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMShipmentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCustomerTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMTransferMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMTransferMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContraryDoc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceTermNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCombineCustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMInvoiceDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMInvoiceDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCashSaleDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMUseDeposit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastEmailSentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastEmailSentStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastEmailSentStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SentasEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastEmailNotifCleared?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AmtShipNotInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtShipNotInvLCYBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofArchivedVersions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Shipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LateOrderShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectWarrantyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BaseSubTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BaseSubTotalInclTax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoTelephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipToId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShiptoEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoTelephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreightIncluded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DiscountIncluded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeliveryMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProcessedByEdge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EdgeStartedPollingOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  HandleRelationsInJSONStructure?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WebbComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderEdgeGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodWeb?: Resolver<Maybe<ResolversTypes['SalesHeaderPaymentMethodWebEnum']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalTelephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SellToAddressGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SharkActualPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  salesLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLine']>>>, ParentType, ContextType>;
  shipments?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentHeader']>>>, ParentType, ContextType>;
  aggregatedShipment?: Resolver<Maybe<ResolversTypes['SalesShipmentHeader']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  OrderState?: Resolver<Maybe<ResolversTypes['OrderStateEnum']>, ParentType, ContextType>;
  ActualGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderArchiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderArchive'] = ResolversParentTypes['SalesHeaderArchive']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['SalesHeaderArchiveDocumentTypeEnum']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Ship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Invoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrintPostedDocuments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShippingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastShippingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineShipments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SendICDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompressPrepayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtCrMemoNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtPaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteValidUntilDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  QuoteSenttoCustomer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  QuoteAcceptedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  JobQueueStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQueueStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueEntryID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncomingDocumentEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentServiceSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DirectDebitMandateID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocNoOccurrence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OpportunityNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingfromWhseRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Receive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GetShipmentUsed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceInvNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceInvVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceInvTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMShipmentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMShipmentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCustomerTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMTransferMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMTransferMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContraryDoc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceTermNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMCombineCustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMInvoiceDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMInvoiceDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCashSaleDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMUseDeposit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMJobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMServiceStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSEstimatedEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMWSEstimatedEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceEndTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWSObjectCounter2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastEmailSentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastEmailSentStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastEmailSentStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SentasEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastEmailNotifCleared?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AmtShipNotInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtShipNotInvLCYBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofArchivedVersions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Shipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LateOrderShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectWarrantyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BaseSubTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BaseSubTotalInclTax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoTelephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipToId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShiptoEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoTelephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FreightIncluded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DiscountIncluded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DeliveryMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProcessedByEdge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EdgeStartedPollingOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  HandleRelationsInJSONStructure?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WebbComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderEdgeGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VersionNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeletedReason?: Resolver<Maybe<ResolversTypes['SalesHeaderArchiveDeletedReasonEnum']>, ParentType, ContextType>;
  IsConstructed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PaymentMethodWeb?: Resolver<Maybe<ResolversTypes['SalesHeaderArchivePaymentMethodWebEnum']>, ParentType, ContextType>;
  SharkActualPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  salesLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineArchive']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderArchiveConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderArchiveConnection'] = ResolversParentTypes['SalesHeaderArchiveConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesHeaderArchiveConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderArchiveConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderArchiveConnectionEdge'] = ResolversParentTypes['SalesHeaderArchiveConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesHeaderArchive'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderArchiveDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderArchiveDeleteResponse'] = ResolversParentTypes['SalesHeaderArchiveDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderArchive']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderArchiveManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderArchiveManyResult'] = ResolversParentTypes['SalesHeaderArchiveManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderArchive']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderConnection'] = ResolversParentTypes['SalesHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderConnectionEdge'] = ResolversParentTypes['SalesHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderDeleteResponse'] = ResolversParentTypes['SalesHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderHistory'] = ResolversParentTypes['SalesHeaderHistory']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['SalesHeaderHistoryTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderHistoryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderHistoryConnection'] = ResolversParentTypes['SalesHeaderHistoryConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesHeaderHistoryConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderHistoryConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderHistoryConnectionEdge'] = ResolversParentTypes['SalesHeaderHistoryConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesHeaderHistory'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderHistoryDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderHistoryDeleteResponse'] = ResolversParentTypes['SalesHeaderHistoryDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderHistory']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderHistoryManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderHistoryManyResult'] = ResolversParentTypes['SalesHeaderHistoryManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderHistory']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderManyResult'] = ResolversParentTypes['SalesHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderRequest'] = ResolversParentTypes['SalesHeaderRequest']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Ship?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Invoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrintPostedDocuments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShippingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastShippingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPostingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepaymentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastPrepmtCrMemoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CombineShipments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscountValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SendICDocument?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICDirection?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICDirectionOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompressPrepayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtCrMemoNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPmtDiscountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PrepmtPaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtPaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobQueueStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobQueueEntryID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncomingDocumentEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AuthorizationRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreditCardNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DirectDebitMandateID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocNoOccurrence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OpportunityNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAdvice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippingAdviceOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingfromWhseRef?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Receive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GetShipmentUsed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AssignedUserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceInvNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceInvVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SourceInvTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Ordertype?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrdertypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OurReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ConfirmedbyVendor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceEmailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NAVStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVImportedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NAVOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVErrorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  salesLineRequests?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineRequest']>>>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderRequestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderRequestConnection'] = ResolversParentTypes['SalesHeaderRequestConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesHeaderRequestConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderRequestConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderRequestConnectionEdge'] = ResolversParentTypes['SalesHeaderRequestConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesHeaderRequest'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderRequestDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderRequestDeleteResponse'] = ResolversParentTypes['SalesHeaderRequestDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderRequest']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesHeaderRequestManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesHeaderRequestManyResult'] = ResolversParentTypes['SalesHeaderRequestManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeaderRequest']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLine'] = ResolversParentTypes['SalesLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['SalesLineDocumentTypeEnum']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['SalesLineTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutstandingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchaseOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATClauseCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutstandingAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoicedLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvLCYNoVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscAmounttoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATBaseAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentVATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentTaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtAmttoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtAmountInvInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtVATAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDifftoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDiffDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAssembletoOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAsmtoOrderBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobContractEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnsDeferralStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Planned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoiceBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShipBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutofStockSubstitution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OriginallyOrderedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OriginallyOrderedVarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SpecialOrderPurchaseNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrderPurchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowItemChargeAssignment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnQtytoReceive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtytoReceiveBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyRcdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RetQtyRcdNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnRcdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnRcdNotInvdLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyReceived?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyReceivedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ApplfromItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BOMItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Subtype?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SubtypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Pricedescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TextconnectedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConnectedtoItemLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalFromDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMAttachedtoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMLineUpdated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReturnDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReturnLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalFromDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalToDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceUpwrite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMAllocateObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMUseOfFixPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMUpdateRentalLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWorkshopGuarantee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMWorkshopTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSkipObjectIntoInv?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRefBasisEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMStairPriceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMStairPriceStep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalKITMother?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalKITMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMDepositPosting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMOldTmpRKITLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMQtyonAtttoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReRentObject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMUsedFractionCalculation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalHeaderLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassOrderedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMExtraHoursCharged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMExtraHoursReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSplitCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRePurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMInvoiceFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalPrepaymentEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPrepmtAmtLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectInsurance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMPriceReportedHoursOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFACorrection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ATOWhseOutstandingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ATOWhseOutstdQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReservedQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SubstitutionAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WhseOutstandingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WhseOutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAssign?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyAssigned?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProductType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderLineEdgeGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LinkedToGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineArchiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineArchive'] = ResolversParentTypes['SalesLineArchive']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['SalesLineArchiveDocumentTypeEnum']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['SalesLineArchiveTypeEnum']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutstandingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchaseOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATClauseCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutstandingAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoicedLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvLCYNoVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscAmounttoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATBaseAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentVATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentTaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtAmttoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtAmountInvInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtVATAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDifftoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDiffDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountCalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountCalculationOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAssembletoOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAsmtoOrderBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobContractEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeferralCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnsDeferralStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Planned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoiceBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShipBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutofStockSubstitution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OriginallyOrderedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OriginallyOrderedVarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SpecialOrderPurchaseNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrderPurchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowItemChargeAssignment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnQtytoReceive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtytoReceiveBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyRcdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RetQtyRcdNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnRcdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnRcdNotInvdLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyReceived?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyReceivedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ApplfromItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BOMItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Subtype?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SubtypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Pricedescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMCustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TextconnectedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConnectedtoItemLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRental?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalFromDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalToDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMAttachedtoNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalFreeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMLineUpdated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReturnDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReturnLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMObjectGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalFromDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalToDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EQMRentalDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMServiceUpwrite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMAllocateObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMUseOfFixPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMUpdateRentalLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopObjectNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMWorkshopGuarantee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMWorkshopType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMWorkshopTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMSkipObjectIntoInv?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPriceTermCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRefBasisEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMStairPriceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMStairPriceStep?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalKITMother?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalKITMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMDepositPosting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMOldTmpRKITLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMContractTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMQtyonAtttoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReRentObject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMUsedFractionCalculation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMRentalHeaderLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMSerialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassOrderedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReclassOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMExtraHoursCharged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMExtraHoursReturnNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSplitCostCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMReceivingRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMReceivingCostCenterCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRePurchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMInvoiceFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPriceScheduleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMRentalPrepaymentEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPrepmtAmtLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRentalInsurance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMObjectInsurance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMServiceMotherLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMRepairCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMPriceReportedHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EQMPriceReportedHoursOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EQMFACorrection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EQMPrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReservedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ATOWhseOutstandingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ATOWhseOutstdQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReservedQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SubstitutionAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WhseOutstandingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WhseOutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAssign?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyAssigned?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProductType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesOrderEdgeGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VersionNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocNoOccurrence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesOrderLineEdgeGuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineArchiveConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineArchiveConnection'] = ResolversParentTypes['SalesLineArchiveConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesLineArchiveConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineArchiveConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineArchiveConnectionEdge'] = ResolversParentTypes['SalesLineArchiveConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesLineArchive'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineArchiveDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineArchiveDeleteResponse'] = ResolversParentTypes['SalesLineArchiveDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineArchive']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineArchiveManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineArchiveManyResult'] = ResolversParentTypes['SalesLineArchiveManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineArchive']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineConnection'] = ResolversParentTypes['SalesLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineConnectionEdge'] = ResolversParentTypes['SalesLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineDeleteResponse'] = ResolversParentTypes['SalesLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineManyResult'] = ResolversParentTypes['SalesLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineRequest'] = ResolversParentTypes['SalesLineRequest']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RecalculateInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OutstandingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShipmentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Profit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchaseOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATClauseCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutstandingAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShippedNotInvoicedLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Reserve?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReserveOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SystemCreatedEntry?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscAmounttoInvoice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerRefType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerRefTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ICPartnerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtLineAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInv?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATBaseAmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATCalcTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentVATIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentTaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentTaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtAmttoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmtDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepmtAmountInvInclVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepmtVATAmountInvLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentVATDifference?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDifftoDeduct?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepmtVATDiffDeducted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAssembletoOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAsmtoOrderBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobContractEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Planned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoInvoiceBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoShipBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyShippedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutofStockSubstitution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  OriginallyOrderedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OriginallyOrderedVarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SpecialOrderPurchaseNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SpecialOrderPurchLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletelyShipped?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowItemChargeAssignment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReturnQtytoReceive?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtytoReceiveBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyRcdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RetQtyRcdNotInvdBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnRcdNotInvd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnRcdNotInvdLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyReceived?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnQtyReceivedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ApplfromItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BOMItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReceiptLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PeriodicTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PeriodicStartingDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NAVStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVImportedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NAVOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rubrikrad?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EndofGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ChargeType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TextconnectedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ConnectedtoItemLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FreeTextLine?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ReservedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ATOWhseOutstandingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ATOWhseOutstdQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ReservedQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SubstitutionAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WhseOutstandingQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WhseOutstandingQtyBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtytoAssign?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyAssigned?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVErrorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineRequestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineRequestConnection'] = ResolversParentTypes['SalesLineRequestConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesLineRequestConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineRequestConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineRequestConnectionEdge'] = ResolversParentTypes['SalesLineRequestConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesLineRequest'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineRequestDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineRequestDeleteResponse'] = ResolversParentTypes['SalesLineRequestDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineRequest']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesLineRequestManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesLineRequestManyResult'] = ResolversParentTypes['SalesLineRequestManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLineRequest']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesOrderChangeTrackingPageDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesOrderChangeTrackingPageData'] = ResolversParentTypes['SalesOrderChangeTrackingPageData']> = {
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPreviousPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latestUpdateOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedSalesOrdersTotalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedSalesOrdersTotalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesOrderChangeTrackingPageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesOrderChangeTrackingPageResponse'] = ResolversParentTypes['SalesOrderChangeTrackingPageResponse']> = {
  pageData?: Resolver<Maybe<ResolversTypes['SalesOrderChangeTrackingPageData']>, ParentType, ContextType>;
  updatedSalesOrders?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesHeader']>>>, ParentType, ContextType>;
  deletedSalesOrders?: Resolver<Maybe<Array<Maybe<ResolversTypes['DeletedRecordRef']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesOrderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesOrderResponse'] = ResolversParentTypes['SalesOrderResponse']> = {
  SalesHeader?: Resolver<Maybe<ResolversTypes['SalesHeader']>, ParentType, ContextType>;
  Customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  SalesLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesLine']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesPrice'] = ResolversParentTypes['SalesPrice']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesType?: Resolver<Maybe<ResolversTypes['SalesPriceSalesTypeEnum']>, ParentType, ContextType>;
  SalesTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MinimumQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriceIncludesVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGrPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EndingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitPriceIncVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SalesOrderMultiple?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CarepaPurchPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesPriceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesPriceConnection'] = ResolversParentTypes['SalesPriceConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesPriceConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesPriceConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesPriceConnectionEdge'] = ResolversParentTypes['SalesPriceConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesPrice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesPriceDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesPriceDeleteResponse'] = ResolversParentTypes['SalesPriceDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesPrice']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesPriceManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesPriceManyResult'] = ResolversParentTypes['SalesPriceManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesPrice']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentHeader'] = ResolversParentTypes['SalesShipmentHeader']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  MagentoOrderType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MagentoOrderTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MagentoOrderEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderMobileNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderLinesCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBNoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentServiceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EdgeRetryCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  YourReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PostingDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PaymentDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalespersonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NoPrinted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OnHold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AppliestoDocNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmountIncludingVAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EU3PartyTrade?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCustomerName2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoPostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCounty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShiptoCountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BalAccountTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DocumentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PackageTrackingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreAssignedNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SourceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBaseDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PrepaymentNoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrepaymentInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PrepaymentOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuoteNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentServiceSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentExchangeIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentExchangeStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentExchangeStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocExchOriginalIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DirectDebitMandateID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Closed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  RemainingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustLedgerEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceDiscountAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Cancelled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Corrective?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CampaignNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SelltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BilltoContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OpportunityNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GetShipmentUsed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Preseason?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AdditionalTelephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AdditionalCustomerNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerCommentExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  WebOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WebOrderEntityNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMailOrderConfirmation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMailSalesShipment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMailInvoice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMailShipmentNotification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderCreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderCreated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDocType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDocTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GearsOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NumberofCustomerPriceGroup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EDISetupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDocumentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceSentToCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InvoiceSentToCustomerDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceSentToCustomerTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoteofGoods?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FreightOnlyShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipmentLines?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentLine']>>>, ParentType, ContextType>;
  IsAggregated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentHeaderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentHeaderConnection'] = ResolversParentTypes['SalesShipmentHeaderConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesShipmentHeaderConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentHeaderConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentHeaderConnectionEdge'] = ResolversParentTypes['SalesShipmentHeaderConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesShipmentHeader'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentHeaderDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentHeaderDeleteResponse'] = ResolversParentTypes['SalesShipmentHeaderDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentHeader']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentHeaderManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentHeaderManyResult'] = ResolversParentTypes['SalesShipmentHeaderManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentHeader']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentLine'] = ResolversParentTypes['SalesShipmentLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderLineType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MagentoOrderLineTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MagentoOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MagentoOrderLineQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SelltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VAT?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LineDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AllowInvoiceDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GrossWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitsperParcel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitVolume?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AppltoItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemShptEntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShortcutDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShortcutDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerPriceGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyShippedNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QuantityInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BilltoCustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaseOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATCalculationTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransportMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ExitPoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransactionSpecification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaxGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlanketOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VATBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DimensionSetID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AuthorizedforCreditCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobContractEntryNo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BinCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyperUnitofMeasure?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QuantityBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyInvoicedBase?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FAPostingDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepreciationBookCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCrossRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CrossReferenceTypeNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemCategoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DuplicateinDepreciationBook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchasingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedDeliveryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PromisedDeliveryDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OutboundWhseHandlingTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReturnReasonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDiscGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AutoAccGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOnOrderBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReorderingPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemReorderingPolicyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PeabAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FeeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FeeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ChargeTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepruntilFAPostingDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UseDuplicationList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Nonstock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Correction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AllowLineDisc?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PlannedDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  PlannedShipmentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CreatedOnOrder?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Originaldeliverydate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ApplfromItemEntry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ItemChargeBaseAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalculatedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DisposableStockBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Currentpricecalculation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FeeAttachedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerOrderedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalQuantityShipped?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CurrentOutstandingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TextconnectedtoLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentLineConnection'] = ResolversParentTypes['SalesShipmentLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalesShipmentLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentLineConnectionEdge'] = ResolversParentTypes['SalesShipmentLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SalesShipmentLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentLineDeleteResponse'] = ResolversParentTypes['SalesShipmentLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesShipmentLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesShipmentLineManyResult'] = ResolversParentTypes['SalesShipmentLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SalesShipmentLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalespersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Salesperson'] = ResolversParentTypes['Salesperson']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Commission?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchEMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActionTakenFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ActionTakenFilterOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCycleFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SalesCycleStageFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ProbabilityFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompletedFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ContactFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactCompanyFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CampaignFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EstimatedValueFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcdCurrentValueFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ChancesofSuccessFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaskStatusFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TaskStatusFilterOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ClosedTaskFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PriorityFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PriorityFilterOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TeamFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CloseOpportunityFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTaskDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoofOpportunities?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  EstimatedValueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CalcdCurrentValueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofInteractions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CostLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DurationMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AvgEstimatedValueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AvgCalcdCurrentValueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OpportunityEntryExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TaskEntryExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EdgeRespCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  responsibilityCenter?: Resolver<Maybe<ResolversTypes['ResponsibilityCenter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalespersonConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalespersonConnection'] = ResolversParentTypes['SalespersonConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SalespersonConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalespersonConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalespersonConnectionEdge'] = ResolversParentTypes['SalespersonConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Salesperson'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalespersonDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalespersonDeleteResponse'] = ResolversParentTypes['SalespersonDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Salesperson']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalespersonManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalespersonManyResult'] = ResolversParentTypes['SalespersonManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Salesperson']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOnRentEmailResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOnRentEmailResponse'] = ResolversParentTypes['SendOnRentEmailResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceItemGroup'] = ResolversParentTypes['ServiceItemGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreateServiceItem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DefaultContractDiscountPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DefaultServPriceGroupCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultResponseTimeHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceItemGroupConnection'] = ResolversParentTypes['ServiceItemGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ServiceItemGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceItemGroupConnectionEdge'] = ResolversParentTypes['ServiceItemGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ServiceItemGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceItemGroupDeleteResponse'] = ResolversParentTypes['ServiceItemGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ServiceItemGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceItemGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceItemGroupManyResult'] = ResolversParentTypes['ServiceItemGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ServiceItemGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShipmentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipmentMethod'] = ResolversParentTypes['ShipmentMethod']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShipmentMethodConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipmentMethodConnection'] = ResolversParentTypes['ShipmentMethodConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ShipmentMethodConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShipmentMethodConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipmentMethodConnectionEdge'] = ResolversParentTypes['ShipmentMethodConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ShipmentMethod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShipmentMethodDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipmentMethodDeleteResponse'] = ResolversParentTypes['ShipmentMethodDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShipmentMethod']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShipmentMethodManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShipmentMethodManyResult'] = ResolversParentTypes['ShipmentMethodManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShipmentMethod']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingAgentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingAgent'] = ResolversParentTypes['ShippingAgent']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternetAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShpfyTrackingCompany?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ShpfyTrackingCompanyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DocumentTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingAgentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingAgentConnection'] = ResolversParentTypes['ShippingAgentConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ShippingAgentConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingAgentConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingAgentConnectionEdge'] = ResolversParentTypes['ShippingAgentConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ShippingAgent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingAgentDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingAgentDeleteResponse'] = ResolversParentTypes['ShippingAgentDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShippingAgent']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingAgentManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingAgentManyResult'] = ResolversParentTypes['ShippingAgentManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShippingAgent']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignRequest'] = ResolversParentTypes['SignRequest']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentUri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentEtag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SigningUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SigningUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrintedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  TargetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetDiscriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Base64WriteOnly?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MobileBankIdSSN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  MobileBankIdUserVisibleData?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autoStartToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hintCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endUserIp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignRequestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignRequestConnection'] = ResolversParentTypes['SignRequestConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SignRequestConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignRequestConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignRequestConnectionEdge'] = ResolversParentTypes['SignRequestConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SignRequest'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignRequestDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignRequestDeleteResponse'] = ResolversParentTypes['SignRequestDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SignRequest']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignRequestManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignRequestManyResult'] = ResolversParentTypes['SignRequestManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SignRequest']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['Signature'] = ResolversParentTypes['Signature']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignatureConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignatureConnection'] = ResolversParentTypes['SignatureConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SignatureConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignatureConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignatureConnectionEdge'] = ResolversParentTypes['SignatureConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Signature'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignatureDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignatureDeleteResponse'] = ResolversParentTypes['SignatureDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Signature']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignatureManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignatureManyResult'] = ResolversParentTypes['SignatureManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Signature']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialEquipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecialEquipment'] = ResolversParentTypes['SpecialEquipment']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialEquipmentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecialEquipmentConnection'] = ResolversParentTypes['SpecialEquipmentConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SpecialEquipmentConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialEquipmentConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecialEquipmentConnectionEdge'] = ResolversParentTypes['SpecialEquipmentConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SpecialEquipment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialEquipmentDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecialEquipmentDeleteResponse'] = ResolversParentTypes['SpecialEquipmentDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SpecialEquipment']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialEquipmentManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecialEquipmentManyResult'] = ResolversParentTypes['SpecialEquipmentManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['SpecialEquipment']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockkeepingUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockkeepingUnit'] = ResolversParentTypes['StockkeepingUnit']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VariantCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssemblyBOM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ShelfNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StandardCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LastDirectCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorItemNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReorderPoint?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MaximumInventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReorderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Inventory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonPurchOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonSalesOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DropShipmentFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AssemblyPolicy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssemblyPolicyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyonAssemblyOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonAsmComponent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonJobOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransferLevelCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LotSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DiscreteOrderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MinimumOrderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  MaximumOrderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SafetyStockQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OrderMultiple?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SafetyLeadTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ComponentsatLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FlushingMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FlushingMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReplenishmentSystem?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReplenishmentSystemOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ScheduledReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ScheduledNeedQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BinFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReorderingPolicy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReorderingPolicyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IncludeInventory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ManufacturingPolicy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ManufacturingPolicyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReschedulingPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LotAccumulationPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DampenerPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DampenerQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverflowLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransferfromCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  QtyinTransit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransOrdReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TransOrdShipmentQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonServiceOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SpecialEquipmentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PutawayTemplateCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PutawayUnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhysInvtCountingPeriodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastCountingPeriodUpdate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastPhysInvtDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  UseCrossDocking?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  NextCountingStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  NextCountingEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  RoutingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProductionBOMNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PlannedOrderReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FPOrderReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RelOrderReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PlannedOrderReleaseQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchReqReceiptQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchReqReleaseQty?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonProdOrder?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  QtyonComponentLines?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockkeepingUnitConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockkeepingUnitConnection'] = ResolversParentTypes['StockkeepingUnitConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['StockkeepingUnitConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockkeepingUnitConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockkeepingUnitConnectionEdge'] = ResolversParentTypes['StockkeepingUnitConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['StockkeepingUnit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockkeepingUnitDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockkeepingUnitDeleteResponse'] = ResolversParentTypes['StockkeepingUnitDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['StockkeepingUnit']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockkeepingUnitManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['StockkeepingUnitManyResult'] = ResolversParentTypes['StockkeepingUnitManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['StockkeepingUnit']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StoredFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['StoredFile'] = ResolversParentTypes['StoredFile']> = {
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  ping?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "ping", ParentType, ContextType>;
  AccessRequestUpserted?: SubscriptionResolver<Maybe<ResolversTypes['AccessRequest']>, "AccessRequestUpserted", ParentType, ContextType, RequireFields<SubscriptionAccessRequestUpsertedArgs, never>>;
  AccessRequestRemoved?: SubscriptionResolver<Maybe<ResolversTypes['AccessRequestDeleteResponse']>, "AccessRequestRemoved", ParentType, ContextType, RequireFields<SubscriptionAccessRequestRemovedArgs, never>>;
  AdditionalItemUpserted?: SubscriptionResolver<Maybe<ResolversTypes['AdditionalItem']>, "AdditionalItemUpserted", ParentType, ContextType, RequireFields<SubscriptionAdditionalItemUpsertedArgs, never>>;
  AdditionalItemRemoved?: SubscriptionResolver<Maybe<ResolversTypes['AdditionalItemDeleteResponse']>, "AdditionalItemRemoved", ParentType, ContextType, RequireFields<SubscriptionAdditionalItemRemovedArgs, never>>;
  AddressUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Address']>, "AddressUpserted", ParentType, ContextType, RequireFields<SubscriptionAddressUpsertedArgs, never>>;
  AddressRemoved?: SubscriptionResolver<Maybe<ResolversTypes['AddressDeleteResponse']>, "AddressRemoved", ParentType, ContextType, RequireFields<SubscriptionAddressRemovedArgs, never>>;
  AgreementUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Agreement']>, "AgreementUpserted", ParentType, ContextType, RequireFields<SubscriptionAgreementUpsertedArgs, never>>;
  AgreementRemoved?: SubscriptionResolver<Maybe<ResolversTypes['AgreementDeleteResponse']>, "AgreementRemoved", ParentType, ContextType, RequireFields<SubscriptionAgreementRemovedArgs, never>>;
  AnnotationUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Annotation']>, "AnnotationUpserted", ParentType, ContextType, RequireFields<SubscriptionAnnotationUpsertedArgs, never>>;
  AnnotationRemoved?: SubscriptionResolver<Maybe<ResolversTypes['AnnotationDeleteResponse']>, "AnnotationRemoved", ParentType, ContextType, RequireFields<SubscriptionAnnotationRemovedArgs, never>>;
  ApplicationModuleUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ApplicationModule']>, "ApplicationModuleUpserted", ParentType, ContextType, RequireFields<SubscriptionApplicationModuleUpsertedArgs, never>>;
  ApplicationModuleRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ApplicationModuleDeleteResponse']>, "ApplicationModuleRemoved", ParentType, ContextType, RequireFields<SubscriptionApplicationModuleRemovedArgs, never>>;
  BcMappingTableUpserted?: SubscriptionResolver<Maybe<ResolversTypes['BcMappingTable']>, "BcMappingTableUpserted", ParentType, ContextType, RequireFields<SubscriptionBcMappingTableUpsertedArgs, never>>;
  BcMappingTableRemoved?: SubscriptionResolver<Maybe<ResolversTypes['BcMappingTableDeleteResponse']>, "BcMappingTableRemoved", ParentType, ContextType, RequireFields<SubscriptionBcMappingTableRemovedArgs, never>>;
  BcMappingFieldUpserted?: SubscriptionResolver<Maybe<ResolversTypes['BcMappingField']>, "BcMappingFieldUpserted", ParentType, ContextType, RequireFields<SubscriptionBcMappingFieldUpsertedArgs, never>>;
  BcMappingFieldRemoved?: SubscriptionResolver<Maybe<ResolversTypes['BcMappingFieldDeleteResponse']>, "BcMappingFieldRemoved", ParentType, ContextType, RequireFields<SubscriptionBcMappingFieldRemovedArgs, never>>;
  BcSettingsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['BcSettings']>, "BcSettingsUpserted", ParentType, ContextType, RequireFields<SubscriptionBcSettingsUpsertedArgs, never>>;
  BcSettingsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['BcSettingsDeleteResponse']>, "BcSettingsRemoved", ParentType, ContextType, RequireFields<SubscriptionBcSettingsRemovedArgs, never>>;
  BulkItemGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['BulkItemGroup']>, "BulkItemGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionBulkItemGroupUpsertedArgs, never>>;
  BulkItemGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['BulkItemGroupDeleteResponse']>, "BulkItemGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionBulkItemGroupRemovedArgs, never>>;
  CauseOfAbsenceUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CauseOfAbsence']>, "CauseOfAbsenceUpserted", ParentType, ContextType, RequireFields<SubscriptionCauseOfAbsenceUpsertedArgs, never>>;
  CauseOfAbsenceRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CauseOfAbsenceDeleteResponse']>, "CauseOfAbsenceRemoved", ParentType, ContextType, RequireFields<SubscriptionCauseOfAbsenceRemovedArgs, never>>;
  CompanyUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Company']>, "CompanyUpserted", ParentType, ContextType, RequireFields<SubscriptionCompanyUpsertedArgs, never>>;
  CompanyRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CompanyDeleteResponse']>, "CompanyRemoved", ParentType, ContextType, RequireFields<SubscriptionCompanyRemovedArgs, never>>;
  ConsignmentHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ConsignmentHeader']>, "ConsignmentHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionConsignmentHeaderUpsertedArgs, never>>;
  ConsignmentHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ConsignmentHeaderDeleteResponse']>, "ConsignmentHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionConsignmentHeaderRemovedArgs, never>>;
  ConsignmentLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ConsignmentLine']>, "ConsignmentLineUpserted", ParentType, ContextType, RequireFields<SubscriptionConsignmentLineUpsertedArgs, never>>;
  ConsignmentLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ConsignmentLineDeleteResponse']>, "ConsignmentLineRemoved", ParentType, ContextType, RequireFields<SubscriptionConsignmentLineRemovedArgs, never>>;
  ContactUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Contact']>, "ContactUpserted", ParentType, ContextType, RequireFields<SubscriptionContactUpsertedArgs, never>>;
  ContactRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ContactDeleteResponse']>, "ContactRemoved", ParentType, ContextType, RequireFields<SubscriptionContactRemovedArgs, never>>;
  CountryRegionUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CountryRegion']>, "CountryRegionUpserted", ParentType, ContextType, RequireFields<SubscriptionCountryRegionUpsertedArgs, never>>;
  CountryRegionRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CountryRegionDeleteResponse']>, "CountryRegionRemoved", ParentType, ContextType, RequireFields<SubscriptionCountryRegionRemovedArgs, never>>;
  CurrencyUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Currency']>, "CurrencyUpserted", ParentType, ContextType, RequireFields<SubscriptionCurrencyUpsertedArgs, never>>;
  CurrencyRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CurrencyDeleteResponse']>, "CurrencyRemoved", ParentType, ContextType, RequireFields<SubscriptionCurrencyRemovedArgs, never>>;
  CurrencyExchangeRateUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CurrencyExchangeRate']>, "CurrencyExchangeRateUpserted", ParentType, ContextType, RequireFields<SubscriptionCurrencyExchangeRateUpsertedArgs, never>>;
  CurrencyExchangeRateRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CurrencyExchangeRateDeleteResponse']>, "CurrencyExchangeRateRemoved", ParentType, ContextType, RequireFields<SubscriptionCurrencyExchangeRateRemovedArgs, never>>;
  CustomerDomainUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CustomerDomain']>, "CustomerDomainUpserted", ParentType, ContextType, RequireFields<SubscriptionCustomerDomainUpsertedArgs, never>>;
  CustomerDomainRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CustomerDomainDeleteResponse']>, "CustomerDomainRemoved", ParentType, ContextType, RequireFields<SubscriptionCustomerDomainRemovedArgs, never>>;
  CustomerPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CustomerPostingGroup']>, "CustomerPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionCustomerPostingGroupUpsertedArgs, never>>;
  CustomerPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CustomerPostingGroupDeleteResponse']>, "CustomerPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionCustomerPostingGroupRemovedArgs, never>>;
  CustomerProjectUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CustomerProject']>, "CustomerProjectUpserted", ParentType, ContextType, RequireFields<SubscriptionCustomerProjectUpsertedArgs, never>>;
  CustomerProjectRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CustomerProjectDeleteResponse']>, "CustomerProjectRemoved", ParentType, ContextType, RequireFields<SubscriptionCustomerProjectRemovedArgs, never>>;
  CustomerSubProjectUpserted?: SubscriptionResolver<Maybe<ResolversTypes['CustomerSubProject']>, "CustomerSubProjectUpserted", ParentType, ContextType, RequireFields<SubscriptionCustomerSubProjectUpsertedArgs, never>>;
  CustomerSubProjectRemoved?: SubscriptionResolver<Maybe<ResolversTypes['CustomerSubProjectDeleteResponse']>, "CustomerSubProjectRemoved", ParentType, ContextType, RequireFields<SubscriptionCustomerSubProjectRemovedArgs, never>>;
  DeliverytimeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Deliverytime']>, "DeliverytimeUpserted", ParentType, ContextType, RequireFields<SubscriptionDeliverytimeUpsertedArgs, never>>;
  DeliverytimeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DeliverytimeDeleteResponse']>, "DeliverytimeRemoved", ParentType, ContextType, RequireFields<SubscriptionDeliverytimeRemovedArgs, never>>;
  DevopsProjectUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DevopsProject']>, "DevopsProjectUpserted", ParentType, ContextType, RequireFields<SubscriptionDevopsProjectUpsertedArgs, never>>;
  DevopsProjectRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DevopsProjectDeleteResponse']>, "DevopsProjectRemoved", ParentType, ContextType, RequireFields<SubscriptionDevopsProjectRemovedArgs, never>>;
  DevopsTeamUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DevopsTeam']>, "DevopsTeamUpserted", ParentType, ContextType, RequireFields<SubscriptionDevopsTeamUpsertedArgs, never>>;
  DevopsTeamRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DevopsTeamDeleteResponse']>, "DevopsTeamRemoved", ParentType, ContextType, RequireFields<SubscriptionDevopsTeamRemovedArgs, never>>;
  DevopsWorkItemUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DevopsWorkItem']>, "DevopsWorkItemUpserted", ParentType, ContextType, RequireFields<SubscriptionDevopsWorkItemUpsertedArgs, never>>;
  DevopsWorkItemRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DevopsWorkItemDeleteResponse']>, "DevopsWorkItemRemoved", ParentType, ContextType, RequireFields<SubscriptionDevopsWorkItemRemovedArgs, never>>;
  DimensionUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Dimension']>, "DimensionUpserted", ParentType, ContextType, RequireFields<SubscriptionDimensionUpsertedArgs, never>>;
  DimensionRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DimensionDeleteResponse']>, "DimensionRemoved", ParentType, ContextType, RequireFields<SubscriptionDimensionRemovedArgs, never>>;
  DimensionValueUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DimensionValue']>, "DimensionValueUpserted", ParentType, ContextType, RequireFields<SubscriptionDimensionValueUpsertedArgs, never>>;
  DimensionValueRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DimensionValueDeleteResponse']>, "DimensionValueRemoved", ParentType, ContextType, RequireFields<SubscriptionDimensionValueRemovedArgs, never>>;
  DiscussionPostUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DiscussionPost']>, "DiscussionPostUpserted", ParentType, ContextType, RequireFields<SubscriptionDiscussionPostUpsertedArgs, never>>;
  DiscussionPostRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DiscussionPostDeleteResponse']>, "DiscussionPostRemoved", ParentType, ContextType, RequireFields<SubscriptionDiscussionPostRemovedArgs, never>>;
  DocumentClassificationUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DocumentClassification']>, "DocumentClassificationUpserted", ParentType, ContextType, RequireFields<SubscriptionDocumentClassificationUpsertedArgs, never>>;
  DocumentClassificationRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DocumentClassificationDeleteResponse']>, "DocumentClassificationRemoved", ParentType, ContextType, RequireFields<SubscriptionDocumentClassificationRemovedArgs, never>>;
  DocumentRequestUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DocumentRequest']>, "DocumentRequestUpserted", ParentType, ContextType, RequireFields<SubscriptionDocumentRequestUpsertedArgs, never>>;
  DocumentRequestRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DocumentRequestDeleteResponse']>, "DocumentRequestRemoved", ParentType, ContextType, RequireFields<SubscriptionDocumentRequestRemovedArgs, never>>;
  DomainSettingsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DomainSettings']>, "DomainSettingsUpserted", ParentType, ContextType, RequireFields<SubscriptionDomainSettingsUpsertedArgs, never>>;
  DomainSettingsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DomainSettingsDeleteResponse']>, "DomainSettingsRemoved", ParentType, ContextType, RequireFields<SubscriptionDomainSettingsRemovedArgs, never>>;
  EqmBaseCalendarUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmBaseCalendar']>, "EqmBaseCalendarUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmBaseCalendarUpsertedArgs, never>>;
  EqmBaseCalendarRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmBaseCalendarDeleteResponse']>, "EqmBaseCalendarRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmBaseCalendarRemovedArgs, never>>;
  EqmBaseCalendarChangeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmBaseCalendarChange']>, "EqmBaseCalendarChangeUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmBaseCalendarChangeUpsertedArgs, never>>;
  EqmBaseCalendarChangeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmBaseCalendarChangeDeleteResponse']>, "EqmBaseCalendarChangeRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmBaseCalendarChangeRemovedArgs, never>>;
  EqmCollectionReturnChargesUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmCollectionReturnCharges']>, "EqmCollectionReturnChargesUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmCollectionReturnChargesUpsertedArgs, never>>;
  EqmCollectionReturnChargesRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmCollectionReturnChargesDeleteResponse']>, "EqmCollectionReturnChargesRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmCollectionReturnChargesRemovedArgs, never>>;
  EqmCommentLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmCommentLine']>, "EqmCommentLineUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmCommentLineUpsertedArgs, never>>;
  EqmCommentLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmCommentLineDeleteResponse']>, "EqmCommentLineRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmCommentLineRemovedArgs, never>>;
  EqmCustomizedCalendarChangeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmCustomizedCalendarChange']>, "EqmCustomizedCalendarChangeUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmCustomizedCalendarChangeUpsertedArgs, never>>;
  EqmCustomizedCalendarChangeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmCustomizedCalendarChangeDeleteResponse']>, "EqmCustomizedCalendarChangeRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmCustomizedCalendarChangeRemovedArgs, never>>;
  EqmFncTestReturnChargesUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmFncTestReturnCharges']>, "EqmFncTestReturnChargesUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmFncTestReturnChargesUpsertedArgs, never>>;
  EqmFncTestReturnChargesRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmFncTestReturnChargesDeleteResponse']>, "EqmFncTestReturnChargesRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmFncTestReturnChargesRemovedArgs, never>>;
  EqmLocCostCenterCombUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmLocCostCenterComb']>, "EqmLocCostCenterCombUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmLocCostCenterCombUpsertedArgs, never>>;
  EqmLocCostCenterCombRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmLocCostCenterCombDeleteResponse']>, "EqmLocCostCenterCombRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmLocCostCenterCombRemovedArgs, never>>;
  EqmManufacturerUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmManufacturer']>, "EqmManufacturerUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmManufacturerUpsertedArgs, never>>;
  EqmManufacturerRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmManufacturerDeleteResponse']>, "EqmManufacturerRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmManufacturerRemovedArgs, never>>;
  EqmManufacturerModelUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmManufacturerModel']>, "EqmManufacturerModelUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmManufacturerModelUpsertedArgs, never>>;
  EqmManufacturerModelRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmManufacturerModelDeleteResponse']>, "EqmManufacturerModelRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmManufacturerModelRemovedArgs, never>>;
  EqmObjectCardFlowUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectCardFlow']>, "EqmObjectCardFlowUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectCardFlowUpsertedArgs, never>>;
  EqmObjectCardFlowRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectCardFlowDeleteResponse']>, "EqmObjectCardFlowRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectCardFlowRemovedArgs, never>>;
  EqmObjectComponentsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectComponents']>, "EqmObjectComponentsUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectComponentsUpsertedArgs, never>>;
  EqmObjectComponentsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectComponentsDeleteResponse']>, "EqmObjectComponentsRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectComponentsRemovedArgs, never>>;
  EqmObjectCountHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectCountHeader']>, "EqmObjectCountHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectCountHeaderUpsertedArgs, never>>;
  EqmObjectCountHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectCountHeaderDeleteResponse']>, "EqmObjectCountHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectCountHeaderRemovedArgs, never>>;
  EqmObjectCountLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectCountLine']>, "EqmObjectCountLineUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectCountLineUpsertedArgs, never>>;
  EqmObjectCountLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectCountLineDeleteResponse']>, "EqmObjectCountLineRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectCountLineRemovedArgs, never>>;
  EqmObjectGroupCardUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectGroupCard']>, "EqmObjectGroupCardUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectGroupCardUpsertedArgs, never>>;
  EqmObjectGroupCardRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectGroupCardDeleteResponse']>, "EqmObjectGroupCardRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectGroupCardRemovedArgs, never>>;
  EqmObjectGroupFlowUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectGroupFlow']>, "EqmObjectGroupFlowUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectGroupFlowUpsertedArgs, never>>;
  EqmObjectGroupFlowRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectGroupFlowDeleteResponse']>, "EqmObjectGroupFlowRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectGroupFlowRemovedArgs, never>>;
  EqmObjectServiceIntervalUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectServiceInterval']>, "EqmObjectServiceIntervalUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectServiceIntervalUpsertedArgs, never>>;
  EqmObjectServiceIntervalRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectServiceIntervalDeleteResponse']>, "EqmObjectServiceIntervalRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectServiceIntervalRemovedArgs, never>>;
  EqmObjectSrvIntervalAggUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAgg']>, "EqmObjectSrvIntervalAggUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectSrvIntervalAggUpsertedArgs, never>>;
  EqmObjectSrvIntervalAggRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectSrvIntervalAggDeleteResponse']>, "EqmObjectSrvIntervalAggRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectSrvIntervalAggRemovedArgs, never>>;
  EqmObjectStatusUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectStatus']>, "EqmObjectStatusUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectStatusUpsertedArgs, never>>;
  EqmObjectStatusRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectStatusDeleteResponse']>, "EqmObjectStatusRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectStatusRemovedArgs, never>>;
  EqmObjectTypeCardUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeCard']>, "EqmObjectTypeCardUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeCardUpsertedArgs, never>>;
  EqmObjectTypeCardRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeCardDeleteResponse']>, "EqmObjectTypeCardRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeCardRemovedArgs, never>>;
  EqmObjectTypeComponentsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeComponents']>, "EqmObjectTypeComponentsUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeComponentsUpsertedArgs, never>>;
  EqmObjectTypeComponentsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeComponentsDeleteResponse']>, "EqmObjectTypeComponentsRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeComponentsRemovedArgs, never>>;
  EqmObjectTypeFlowUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeFlow']>, "EqmObjectTypeFlowUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeFlowUpsertedArgs, never>>;
  EqmObjectTypeFlowRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeFlowDeleteResponse']>, "EqmObjectTypeFlowRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeFlowRemovedArgs, never>>;
  EqmObjectTypePriceTermUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypePriceTerm']>, "EqmObjectTypePriceTermUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypePriceTermUpsertedArgs, never>>;
  EqmObjectTypePriceTermRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypePriceTermDeleteResponse']>, "EqmObjectTypePriceTermRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypePriceTermRemovedArgs, never>>;
  EqmObjectTypeServiceIntervalUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeServiceInterval']>, "EqmObjectTypeServiceIntervalUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeServiceIntervalUpsertedArgs, never>>;
  EqmObjectTypeServiceIntervalRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmObjectTypeServiceIntervalDeleteResponse']>, "EqmObjectTypeServiceIntervalRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmObjectTypeServiceIntervalRemovedArgs, never>>;
  EqmRentalGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalGroup']>, "EqmRentalGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmRentalGroupUpsertedArgs, never>>;
  EqmRentalGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalGroupDeleteResponse']>, "EqmRentalGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmRentalGroupRemovedArgs, never>>;
  EqmRentalKITUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalKIT']>, "EqmRentalKITUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmRentalKitUpsertedArgs, never>>;
  EqmRentalKITRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalKITDeleteResponse']>, "EqmRentalKITRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmRentalKitRemovedArgs, never>>;
  EqmRentalKITComponentsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalKITComponents']>, "EqmRentalKITComponentsUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmRentalKitComponentsUpsertedArgs, never>>;
  EqmRentalKITComponentsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalKITComponentsDeleteResponse']>, "EqmRentalKITComponentsRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmRentalKitComponentsRemovedArgs, never>>;
  EqmRentalKITLinesUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalKITLines']>, "EqmRentalKITLinesUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmRentalKitLinesUpsertedArgs, never>>;
  EqmRentalKITLinesRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalKITLinesDeleteResponse']>, "EqmRentalKITLinesRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmRentalKitLinesRemovedArgs, never>>;
  EqmRentalLineDiscountUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalLineDiscount']>, "EqmRentalLineDiscountUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmRentalLineDiscountUpsertedArgs, never>>;
  EqmRentalLineDiscountRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalLineDiscountDeleteResponse']>, "EqmRentalLineDiscountRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmRentalLineDiscountRemovedArgs, never>>;
  EqmRentalPriceUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalPrice']>, "EqmRentalPriceUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmRentalPriceUpsertedArgs, never>>;
  EqmRentalPriceRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmRentalPriceDeleteResponse']>, "EqmRentalPriceRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmRentalPriceRemovedArgs, never>>;
  EqmReRentCardUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmReRentCard']>, "EqmReRentCardUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmReRentCardUpsertedArgs, never>>;
  EqmReRentCardRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmReRentCardDeleteResponse']>, "EqmReRentCardRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmReRentCardRemovedArgs, never>>;
  EqmReturnChargesUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmReturnCharges']>, "EqmReturnChargesUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmReturnChargesUpsertedArgs, never>>;
  EqmReturnChargesRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmReturnChargesDeleteResponse']>, "EqmReturnChargesRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmReturnChargesRemovedArgs, never>>;
  EqmServiceCardUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmServiceCard']>, "EqmServiceCardUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmServiceCardUpsertedArgs, never>>;
  EqmServiceCardRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmServiceCardDeleteResponse']>, "EqmServiceCardRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmServiceCardRemovedArgs, never>>;
  EqmServiceTypeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmServiceType']>, "EqmServiceTypeUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmServiceTypeUpsertedArgs, never>>;
  EqmServiceTypeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmServiceTypeDeleteResponse']>, "EqmServiceTypeRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmServiceTypeRemovedArgs, never>>;
  EqmTypeFunctionalTestUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmTypeFunctionalTest']>, "EqmTypeFunctionalTestUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmTypeFunctionalTestUpsertedArgs, never>>;
  EqmTypeFunctionalTestRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmTypeFunctionalTestDeleteResponse']>, "EqmTypeFunctionalTestRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmTypeFunctionalTestRemovedArgs, never>>;
  EqmWorkHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmWorkHeader']>, "EqmWorkHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmWorkHeaderUpsertedArgs, never>>;
  EqmWorkHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmWorkHeaderDeleteResponse']>, "EqmWorkHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmWorkHeaderRemovedArgs, never>>;
  EqmWorkLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmWorkLine']>, "EqmWorkLineUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmWorkLineUpsertedArgs, never>>;
  EqmWorkLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmWorkLineDeleteResponse']>, "EqmWorkLineRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmWorkLineRemovedArgs, never>>;
  EqmTransferHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmTransferHeader']>, "EqmTransferHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmTransferHeaderUpsertedArgs, never>>;
  EqmTransferHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmTransferHeaderDeleteResponse']>, "EqmTransferHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmTransferHeaderRemovedArgs, never>>;
  EqmTransferLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['EqmTransferLine']>, "EqmTransferLineUpserted", ParentType, ContextType, RequireFields<SubscriptionEqmTransferLineUpsertedArgs, never>>;
  EqmTransferLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['EqmTransferLineDeleteResponse']>, "EqmTransferLineRemoved", ParentType, ContextType, RequireFields<SubscriptionEqmTransferLineRemovedArgs, never>>;
  ErrandUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Errand']>, "ErrandUpserted", ParentType, ContextType, RequireFields<SubscriptionErrandUpsertedArgs, never>>;
  ErrandRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ErrandDeleteResponse']>, "ErrandRemoved", ParentType, ContextType, RequireFields<SubscriptionErrandRemovedArgs, never>>;
  ErrandLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ErrandLine']>, "ErrandLineUpserted", ParentType, ContextType, RequireFields<SubscriptionErrandLineUpsertedArgs, never>>;
  ErrandLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ErrandLineDeleteResponse']>, "ErrandLineRemoved", ParentType, ContextType, RequireFields<SubscriptionErrandLineRemovedArgs, never>>;
  ErrorReportUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ErrorReport']>, "ErrorReportUpserted", ParentType, ContextType, RequireFields<SubscriptionErrorReportUpsertedArgs, never>>;
  ErrorReportRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ErrorReportDeleteResponse']>, "ErrorReportRemoved", ParentType, ContextType, RequireFields<SubscriptionErrorReportRemovedArgs, never>>;
  FeedbackUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Feedback']>, "FeedbackUpserted", ParentType, ContextType, RequireFields<SubscriptionFeedbackUpsertedArgs, never>>;
  FeedbackRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FeedbackDeleteResponse']>, "FeedbackRemoved", ParentType, ContextType, RequireFields<SubscriptionFeedbackRemovedArgs, never>>;
  FeemappingUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Feemapping']>, "FeemappingUpserted", ParentType, ContextType, RequireFields<SubscriptionFeemappingUpsertedArgs, never>>;
  FeemappingRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FeemappingDeleteResponse']>, "FeemappingRemoved", ParentType, ContextType, RequireFields<SubscriptionFeemappingRemovedArgs, never>>;
  FieldOrderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['FieldOrder']>, "FieldOrderUpserted", ParentType, ContextType, RequireFields<SubscriptionFieldOrderUpsertedArgs, never>>;
  FieldOrderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FieldOrderDeleteResponse']>, "FieldOrderRemoved", ParentType, ContextType, RequireFields<SubscriptionFieldOrderRemovedArgs, never>>;
  FieldOrderLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['FieldOrderLine']>, "FieldOrderLineUpserted", ParentType, ContextType, RequireFields<SubscriptionFieldOrderLineUpsertedArgs, never>>;
  FieldOrderLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FieldOrderLineDeleteResponse']>, "FieldOrderLineRemoved", ParentType, ContextType, RequireFields<SubscriptionFieldOrderLineRemovedArgs, never>>;
  FileUpserted?: SubscriptionResolver<Maybe<ResolversTypes['File']>, "FileUpserted", ParentType, ContextType, RequireFields<SubscriptionFileUpsertedArgs, never>>;
  FileRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FileDeleteResponse']>, "FileRemoved", ParentType, ContextType, RequireFields<SubscriptionFileRemovedArgs, never>>;
  FunctionTestHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['FunctionTestHeader']>, "FunctionTestHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionFunctionTestHeaderUpsertedArgs, never>>;
  FunctionTestHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FunctionTestHeaderDeleteResponse']>, "FunctionTestHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionFunctionTestHeaderRemovedArgs, never>>;
  FunctionTestLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['FunctionTestLine']>, "FunctionTestLineUpserted", ParentType, ContextType, RequireFields<SubscriptionFunctionTestLineUpsertedArgs, never>>;
  FunctionTestLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['FunctionTestLineDeleteResponse']>, "FunctionTestLineRemoved", ParentType, ContextType, RequireFields<SubscriptionFunctionTestLineRemovedArgs, never>>;
  GenBusinessPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['GenBusinessPostingGroup']>, "GenBusinessPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionGenBusinessPostingGroupUpsertedArgs, never>>;
  GenBusinessPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['GenBusinessPostingGroupDeleteResponse']>, "GenBusinessPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionGenBusinessPostingGroupRemovedArgs, never>>;
  GenJournalLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['GenJournalLine']>, "GenJournalLineUpserted", ParentType, ContextType, RequireFields<SubscriptionGenJournalLineUpsertedArgs, never>>;
  GenJournalLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['GenJournalLineDeleteResponse']>, "GenJournalLineRemoved", ParentType, ContextType, RequireFields<SubscriptionGenJournalLineRemovedArgs, never>>;
  GenProductPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['GenProductPostingGroup']>, "GenProductPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionGenProductPostingGroupUpsertedArgs, never>>;
  GenProductPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['GenProductPostingGroupDeleteResponse']>, "GenProductPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionGenProductPostingGroupRemovedArgs, never>>;
  InspectionUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Inspection']>, "InspectionUpserted", ParentType, ContextType, RequireFields<SubscriptionInspectionUpsertedArgs, never>>;
  InspectionRemoved?: SubscriptionResolver<Maybe<ResolversTypes['InspectionDeleteResponse']>, "InspectionRemoved", ParentType, ContextType, RequireFields<SubscriptionInspectionRemovedArgs, never>>;
  InspectionUserUpserted?: SubscriptionResolver<Maybe<ResolversTypes['InspectionUser']>, "InspectionUserUpserted", ParentType, ContextType, RequireFields<SubscriptionInspectionUserUpsertedArgs, never>>;
  InspectionUserRemoved?: SubscriptionResolver<Maybe<ResolversTypes['InspectionUserDeleteResponse']>, "InspectionUserRemoved", ParentType, ContextType, RequireFields<SubscriptionInspectionUserRemovedArgs, never>>;
  IntegrationCollectionUpserted?: SubscriptionResolver<Maybe<ResolversTypes['IntegrationCollection']>, "IntegrationCollectionUpserted", ParentType, ContextType, RequireFields<SubscriptionIntegrationCollectionUpsertedArgs, never>>;
  IntegrationCollectionRemoved?: SubscriptionResolver<Maybe<ResolversTypes['IntegrationCollectionDeleteResponse']>, "IntegrationCollectionRemoved", ParentType, ContextType, RequireFields<SubscriptionIntegrationCollectionRemovedArgs, never>>;
  InventoryPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['InventoryPostingGroup']>, "InventoryPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionInventoryPostingGroupUpsertedArgs, never>>;
  InventoryPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['InventoryPostingGroupDeleteResponse']>, "InventoryPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionInventoryPostingGroupRemovedArgs, never>>;
  InvoiceHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['InvoiceHeader']>, "InvoiceHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionInvoiceHeaderUpsertedArgs, never>>;
  InvoiceHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['InvoiceHeaderDeleteResponse']>, "InvoiceHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionInvoiceHeaderRemovedArgs, never>>;
  InvoiceLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['InvoiceLine']>, "InvoiceLineUpserted", ParentType, ContextType, RequireFields<SubscriptionInvoiceLineUpsertedArgs, never>>;
  InvoiceLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['InvoiceLineDeleteResponse']>, "InvoiceLineRemoved", ParentType, ContextType, RequireFields<SubscriptionInvoiceLineRemovedArgs, never>>;
  ItemUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Item']>, "ItemUpserted", ParentType, ContextType, RequireFields<SubscriptionItemUpsertedArgs, never>>;
  ItemRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemDeleteResponse']>, "ItemRemoved", ParentType, ContextType, RequireFields<SubscriptionItemRemovedArgs, never>>;
  ItemCategoryUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ItemCategory']>, "ItemCategoryUpserted", ParentType, ContextType, RequireFields<SubscriptionItemCategoryUpsertedArgs, never>>;
  ItemCategoryRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemCategoryDeleteResponse']>, "ItemCategoryRemoved", ParentType, ContextType, RequireFields<SubscriptionItemCategoryRemovedArgs, never>>;
  ItemDiscGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ItemDiscGroup']>, "ItemDiscGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionItemDiscGroupUpsertedArgs, never>>;
  ItemDiscGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemDiscGroupDeleteResponse']>, "ItemDiscGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionItemDiscGroupRemovedArgs, never>>;
  ItemReferenceUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ItemReference']>, "ItemReferenceUpserted", ParentType, ContextType, RequireFields<SubscriptionItemReferenceUpsertedArgs, never>>;
  ItemReferenceRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemReferenceDeleteResponse']>, "ItemReferenceRemoved", ParentType, ContextType, RequireFields<SubscriptionItemReferenceRemovedArgs, never>>;
  ItemRestrictionUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ItemRestriction']>, "ItemRestrictionUpserted", ParentType, ContextType, RequireFields<SubscriptionItemRestrictionUpsertedArgs, never>>;
  ItemRestrictionRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemRestrictionDeleteResponse']>, "ItemRestrictionRemoved", ParentType, ContextType, RequireFields<SubscriptionItemRestrictionRemovedArgs, never>>;
  ItemUnitOfMeasureUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ItemUnitOfMeasure']>, "ItemUnitOfMeasureUpserted", ParentType, ContextType, RequireFields<SubscriptionItemUnitOfMeasureUpsertedArgs, never>>;
  ItemUnitOfMeasureRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemUnitOfMeasureDeleteResponse']>, "ItemUnitOfMeasureRemoved", ParentType, ContextType, RequireFields<SubscriptionItemUnitOfMeasureRemovedArgs, never>>;
  ItemVendorUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ItemVendor']>, "ItemVendorUpserted", ParentType, ContextType, RequireFields<SubscriptionItemVendorUpsertedArgs, never>>;
  ItemVendorRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ItemVendorDeleteResponse']>, "ItemVendorRemoved", ParentType, ContextType, RequireFields<SubscriptionItemVendorRemovedArgs, never>>;
  JobTaskUpserted?: SubscriptionResolver<Maybe<ResolversTypes['JobTask']>, "JobTaskUpserted", ParentType, ContextType, RequireFields<SubscriptionJobTaskUpsertedArgs, never>>;
  JobTaskRemoved?: SubscriptionResolver<Maybe<ResolversTypes['JobTaskDeleteResponse']>, "JobTaskRemoved", ParentType, ContextType, RequireFields<SubscriptionJobTaskRemovedArgs, never>>;
  KliHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['KliHeader']>, "KliHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionKliHeaderUpsertedArgs, never>>;
  KliHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['KliHeaderDeleteResponse']>, "KliHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionKliHeaderRemovedArgs, never>>;
  KliReasonCodeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['KliReasonCode']>, "KliReasonCodeUpserted", ParentType, ContextType, RequireFields<SubscriptionKliReasonCodeUpsertedArgs, never>>;
  KliReasonCodeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['KliReasonCodeDeleteResponse']>, "KliReasonCodeRemoved", ParentType, ContextType, RequireFields<SubscriptionKliReasonCodeRemovedArgs, never>>;
  LocationUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Location']>, "LocationUpserted", ParentType, ContextType, RequireFields<SubscriptionLocationUpsertedArgs, never>>;
  LocationRemoved?: SubscriptionResolver<Maybe<ResolversTypes['LocationDeleteResponse']>, "LocationRemoved", ParentType, ContextType, RequireFields<SubscriptionLocationRemovedArgs, never>>;
  LeadUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Lead']>, "LeadUpserted", ParentType, ContextType, RequireFields<SubscriptionLeadUpsertedArgs, never>>;
  LeadRemoved?: SubscriptionResolver<Maybe<ResolversTypes['LeadDeleteResponse']>, "LeadRemoved", ParentType, ContextType, RequireFields<SubscriptionLeadRemovedArgs, never>>;
  MagentoOrderStatusUpserted?: SubscriptionResolver<Maybe<ResolversTypes['MagentoOrderStatus']>, "MagentoOrderStatusUpserted", ParentType, ContextType, RequireFields<SubscriptionMagentoOrderStatusUpsertedArgs, never>>;
  MagentoOrderStatusRemoved?: SubscriptionResolver<Maybe<ResolversTypes['MagentoOrderStatusDeleteResponse']>, "MagentoOrderStatusRemoved", ParentType, ContextType, RequireFields<SubscriptionMagentoOrderStatusRemovedArgs, never>>;
  MagentoSettingsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['MagentoSettings']>, "MagentoSettingsUpserted", ParentType, ContextType, RequireFields<SubscriptionMagentoSettingsUpsertedArgs, never>>;
  MagentoSettingsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['MagentoSettingsDeleteResponse']>, "MagentoSettingsRemoved", ParentType, ContextType, RequireFields<SubscriptionMagentoSettingsRemovedArgs, never>>;
  ManufacturerUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Manufacturer']>, "ManufacturerUpserted", ParentType, ContextType, RequireFields<SubscriptionManufacturerUpsertedArgs, never>>;
  ManufacturerRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ManufacturerDeleteResponse']>, "ManufacturerRemoved", ParentType, ContextType, RequireFields<SubscriptionManufacturerRemovedArgs, never>>;
  NavUserUpserted?: SubscriptionResolver<Maybe<ResolversTypes['NavUser']>, "NavUserUpserted", ParentType, ContextType, RequireFields<SubscriptionNavUserUpsertedArgs, never>>;
  NavUserRemoved?: SubscriptionResolver<Maybe<ResolversTypes['NavUserDeleteResponse']>, "NavUserRemoved", ParentType, ContextType, RequireFields<SubscriptionNavUserRemovedArgs, never>>;
  ObjectUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Object']>, "ObjectUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectUpsertedArgs, never>>;
  ObjectRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectDeleteResponse']>, "ObjectRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectRemovedArgs, never>>;
  ObjectCommentLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ObjectCommentLine']>, "ObjectCommentLineUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectCommentLineUpsertedArgs, never>>;
  ObjectCommentLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectCommentLineDeleteResponse']>, "ObjectCommentLineRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectCommentLineRemovedArgs, never>>;
  ObjectFamilyUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ObjectFamily']>, "ObjectFamilyUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectFamilyUpsertedArgs, never>>;
  ObjectFamilyRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectFamilyDeleteResponse']>, "ObjectFamilyRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectFamilyRemovedArgs, never>>;
  ObjectGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ObjectGroup']>, "ObjectGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectGroupUpsertedArgs, never>>;
  ObjectGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectGroupDeleteResponse']>, "ObjectGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectGroupRemovedArgs, never>>;
  ObjectReturnHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ObjectReturnHeader']>, "ObjectReturnHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectReturnHeaderUpsertedArgs, never>>;
  ObjectReturnHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectReturnHeaderDeleteResponse']>, "ObjectReturnHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectReturnHeaderRemovedArgs, never>>;
  ObjectReturnLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ObjectReturnLine']>, "ObjectReturnLineUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectReturnLineUpsertedArgs, never>>;
  ObjectReturnLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectReturnLineDeleteResponse']>, "ObjectReturnLineRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectReturnLineRemovedArgs, never>>;
  ObjectTypeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ObjectType']>, "ObjectTypeUpserted", ParentType, ContextType, RequireFields<SubscriptionObjectTypeUpsertedArgs, never>>;
  ObjectTypeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ObjectTypeDeleteResponse']>, "ObjectTypeRemoved", ParentType, ContextType, RequireFields<SubscriptionObjectTypeRemovedArgs, never>>;
  OcrAnalysisUpserted?: SubscriptionResolver<Maybe<ResolversTypes['OcrAnalysis']>, "OcrAnalysisUpserted", ParentType, ContextType, RequireFields<SubscriptionOcrAnalysisUpsertedArgs, never>>;
  OcrAnalysisRemoved?: SubscriptionResolver<Maybe<ResolversTypes['OcrAnalysisDeleteResponse']>, "OcrAnalysisRemoved", ParentType, ContextType, RequireFields<SubscriptionOcrAnalysisRemovedArgs, never>>;
  PaymentMethodUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PaymentMethod']>, "PaymentMethodUpserted", ParentType, ContextType, RequireFields<SubscriptionPaymentMethodUpsertedArgs, never>>;
  PaymentMethodRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PaymentMethodDeleteResponse']>, "PaymentMethodRemoved", ParentType, ContextType, RequireFields<SubscriptionPaymentMethodRemovedArgs, never>>;
  PaymentTermsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PaymentTerms']>, "PaymentTermsUpserted", ParentType, ContextType, RequireFields<SubscriptionPaymentTermsUpsertedArgs, never>>;
  PaymentTermsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PaymentTermsDeleteResponse']>, "PaymentTermsRemoved", ParentType, ContextType, RequireFields<SubscriptionPaymentTermsRemovedArgs, never>>;
  PlatformFeatureUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PlatformFeature']>, "PlatformFeatureUpserted", ParentType, ContextType, RequireFields<SubscriptionPlatformFeatureUpsertedArgs, never>>;
  PlatformFeatureRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PlatformFeatureDeleteResponse']>, "PlatformFeatureRemoved", ParentType, ContextType, RequireFields<SubscriptionPlatformFeatureRemovedArgs, never>>;
  PostCodeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PostCode']>, "PostCodeUpserted", ParentType, ContextType, RequireFields<SubscriptionPostCodeUpsertedArgs, never>>;
  PostCodeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PostCodeDeleteResponse']>, "PostCodeRemoved", ParentType, ContextType, RequireFields<SubscriptionPostCodeRemovedArgs, never>>;
  ProjectUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Project']>, "ProjectUpserted", ParentType, ContextType, RequireFields<SubscriptionProjectUpsertedArgs, never>>;
  ProjectRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ProjectDeleteResponse']>, "ProjectRemoved", ParentType, ContextType, RequireFields<SubscriptionProjectRemovedArgs, never>>;
  PurchaseHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchaseHeader']>, "PurchaseHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchaseHeaderUpsertedArgs, never>>;
  PurchaseHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchaseHeaderDeleteResponse']>, "PurchaseHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchaseHeaderRemovedArgs, never>>;
  PurchaseLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchaseLine']>, "PurchaseLineUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchaseLineUpsertedArgs, never>>;
  PurchaseLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchaseLineDeleteResponse']>, "PurchaseLineRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchaseLineRemovedArgs, never>>;
  PurchasingUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Purchasing']>, "PurchasingUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchasingUpsertedArgs, never>>;
  PurchasingRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchasingDeleteResponse']>, "PurchasingRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchasingRemovedArgs, never>>;
  PurchCrMemoHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchCrMemoHeader']>, "PurchCrMemoHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchCrMemoHeaderUpsertedArgs, never>>;
  PurchCrMemoHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchCrMemoHeaderDeleteResponse']>, "PurchCrMemoHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchCrMemoHeaderRemovedArgs, never>>;
  PurchCrMemoLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchCrMemoLine']>, "PurchCrMemoLineUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchCrMemoLineUpsertedArgs, never>>;
  PurchCrMemoLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchCrMemoLineDeleteResponse']>, "PurchCrMemoLineRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchCrMemoLineRemovedArgs, never>>;
  PurchInvHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchInvHeader']>, "PurchInvHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchInvHeaderUpsertedArgs, never>>;
  PurchInvHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchInvHeaderDeleteResponse']>, "PurchInvHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchInvHeaderRemovedArgs, never>>;
  PurchInvLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchInvLine']>, "PurchInvLineUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchInvLineUpsertedArgs, never>>;
  PurchInvLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchInvLineDeleteResponse']>, "PurchInvLineRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchInvLineRemovedArgs, never>>;
  PurchRcptHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchRcptHeader']>, "PurchRcptHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchRcptHeaderUpsertedArgs, never>>;
  PurchRcptHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchRcptHeaderDeleteResponse']>, "PurchRcptHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchRcptHeaderRemovedArgs, never>>;
  PurchRcptLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['PurchRcptLine']>, "PurchRcptLineUpserted", ParentType, ContextType, RequireFields<SubscriptionPurchRcptLineUpsertedArgs, never>>;
  PurchRcptLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['PurchRcptLineDeleteResponse']>, "PurchRcptLineRemoved", ParentType, ContextType, RequireFields<SubscriptionPurchRcptLineRemovedArgs, never>>;
  QuoteHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['QuoteHeader']>, "QuoteHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionQuoteHeaderUpsertedArgs, never>>;
  QuoteHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['QuoteHeaderDeleteResponse']>, "QuoteHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionQuoteHeaderRemovedArgs, never>>;
  QuoteLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['QuoteLine']>, "QuoteLineUpserted", ParentType, ContextType, RequireFields<SubscriptionQuoteLineUpsertedArgs, never>>;
  QuoteLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['QuoteLineDeleteResponse']>, "QuoteLineRemoved", ParentType, ContextType, RequireFields<SubscriptionQuoteLineRemovedArgs, never>>;
  QuoteTaskGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['QuoteTaskGroup']>, "QuoteTaskGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionQuoteTaskGroupUpsertedArgs, never>>;
  QuoteTaskGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['QuoteTaskGroupDeleteResponse']>, "QuoteTaskGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionQuoteTaskGroupRemovedArgs, never>>;
  RentalHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalHeader']>, "RentalHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalHeaderUpsertedArgs, never>>;
  RentalHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalHeaderDeleteResponse']>, "RentalHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalHeaderRemovedArgs, never>>;
  RentalLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalLine']>, "RentalLineUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalLineUpsertedArgs, never>>;
  RentalLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalLineDeleteResponse']>, "RentalLineRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalLineRemovedArgs, never>>;
  RentalLineSnapshotUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalLineSnapshot']>, "RentalLineSnapshotUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalLineSnapshotUpsertedArgs, never>>;
  RentalLineSnapshotRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalLineSnapshotDeleteResponse']>, "RentalLineSnapshotRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalLineSnapshotRemovedArgs, never>>;
  RentalPostedCollHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedCollHeader']>, "RentalPostedCollHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalPostedCollHeaderUpsertedArgs, never>>;
  RentalPostedCollHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedCollHeaderDeleteResponse']>, "RentalPostedCollHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalPostedCollHeaderRemovedArgs, never>>;
  RentalPostedCollLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedCollLine']>, "RentalPostedCollLineUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalPostedCollLineUpsertedArgs, never>>;
  RentalPostedCollLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedCollLineDeleteResponse']>, "RentalPostedCollLineRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalPostedCollLineRemovedArgs, never>>;
  RentalPostedShptHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedShptHeader']>, "RentalPostedShptHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalPostedShptHeaderUpsertedArgs, never>>;
  RentalPostedShptHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedShptHeaderDeleteResponse']>, "RentalPostedShptHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalPostedShptHeaderRemovedArgs, never>>;
  RentalPostedShptLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedShptLine']>, "RentalPostedShptLineUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalPostedShptLineUpsertedArgs, never>>;
  RentalPostedShptLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalPostedShptLineDeleteResponse']>, "RentalPostedShptLineRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalPostedShptLineRemovedArgs, never>>;
  RentalPriceTermsUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalPriceTerms']>, "RentalPriceTermsUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalPriceTermsUpsertedArgs, never>>;
  RentalPriceTermsRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalPriceTermsDeleteResponse']>, "RentalPriceTermsRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalPriceTermsRemovedArgs, never>>;
  RentalReturnEntryUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RentalReturnEntry']>, "RentalReturnEntryUpserted", ParentType, ContextType, RequireFields<SubscriptionRentalReturnEntryUpsertedArgs, never>>;
  RentalReturnEntryRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RentalReturnEntryDeleteResponse']>, "RentalReturnEntryRemoved", ParentType, ContextType, RequireFields<SubscriptionRentalReturnEntryRemovedArgs, never>>;
  RequestHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RequestHeader']>, "RequestHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionRequestHeaderUpsertedArgs, never>>;
  RequestHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RequestHeaderDeleteResponse']>, "RequestHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionRequestHeaderRemovedArgs, never>>;
  RequestLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RequestLine']>, "RequestLineUpserted", ParentType, ContextType, RequireFields<SubscriptionRequestLineUpsertedArgs, never>>;
  RequestLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RequestLineDeleteResponse']>, "RequestLineRemoved", ParentType, ContextType, RequireFields<SubscriptionRequestLineRemovedArgs, never>>;
  ReservationRequestHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ReservationRequestHeader']>, "ReservationRequestHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionReservationRequestHeaderUpsertedArgs, never>>;
  ReservationRequestHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ReservationRequestHeaderDeleteResponse']>, "ReservationRequestHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionReservationRequestHeaderRemovedArgs, never>>;
  ReservationRequestLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ReservationRequestLine']>, "ReservationRequestLineUpserted", ParentType, ContextType, RequireFields<SubscriptionReservationRequestLineUpsertedArgs, never>>;
  ReservationRequestLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ReservationRequestLineDeleteResponse']>, "ReservationRequestLineRemoved", ParentType, ContextType, RequireFields<SubscriptionReservationRequestLineRemovedArgs, never>>;
  ResourceUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Resource']>, "ResourceUpserted", ParentType, ContextType, RequireFields<SubscriptionResourceUpsertedArgs, never>>;
  ResourceRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ResourceDeleteResponse']>, "ResourceRemoved", ParentType, ContextType, RequireFields<SubscriptionResourceRemovedArgs, never>>;
  ResponsibilityCenterUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ResponsibilityCenter']>, "ResponsibilityCenterUpserted", ParentType, ContextType, RequireFields<SubscriptionResponsibilityCenterUpsertedArgs, never>>;
  ResponsibilityCenterRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ResponsibilityCenterDeleteResponse']>, "ResponsibilityCenterRemoved", ParentType, ContextType, RequireFields<SubscriptionResponsibilityCenterRemovedArgs, never>>;
  ReturnRequestHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ReturnRequestHeader']>, "ReturnRequestHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionReturnRequestHeaderUpsertedArgs, never>>;
  ReturnRequestHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ReturnRequestHeaderDeleteResponse']>, "ReturnRequestHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionReturnRequestHeaderRemovedArgs, never>>;
  ReturnRequestLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ReturnRequestLine']>, "ReturnRequestLineUpserted", ParentType, ContextType, RequireFields<SubscriptionReturnRequestLineUpsertedArgs, never>>;
  ReturnRequestLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ReturnRequestLineDeleteResponse']>, "ReturnRequestLineRemoved", ParentType, ContextType, RequireFields<SubscriptionReturnRequestLineRemovedArgs, never>>;
  RiskAnalysisHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RiskAnalysisHeader']>, "RiskAnalysisHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionRiskAnalysisHeaderUpsertedArgs, never>>;
  RiskAnalysisHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RiskAnalysisHeaderDeleteResponse']>, "RiskAnalysisHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionRiskAnalysisHeaderRemovedArgs, never>>;
  RiskAnalysisLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RiskAnalysisLine']>, "RiskAnalysisLineUpserted", ParentType, ContextType, RequireFields<SubscriptionRiskAnalysisLineUpsertedArgs, never>>;
  RiskAnalysisLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RiskAnalysisLineDeleteResponse']>, "RiskAnalysisLineRemoved", ParentType, ContextType, RequireFields<SubscriptionRiskAnalysisLineRemovedArgs, never>>;
  RiskAnalysisSignatureUpserted?: SubscriptionResolver<Maybe<ResolversTypes['RiskAnalysisSignature']>, "RiskAnalysisSignatureUpserted", ParentType, ContextType, RequireFields<SubscriptionRiskAnalysisSignatureUpsertedArgs, never>>;
  RiskAnalysisSignatureRemoved?: SubscriptionResolver<Maybe<ResolversTypes['RiskAnalysisSignatureDeleteResponse']>, "RiskAnalysisSignatureRemoved", ParentType, ContextType, RequireFields<SubscriptionRiskAnalysisSignatureRemovedArgs, never>>;
  SalesHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeader']>, "SalesHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderUpsertedArgs, never>>;
  SalesHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderDeleteResponse']>, "SalesHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderRemovedArgs, never>>;
  SalesHeaderArchiveUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderArchive']>, "SalesHeaderArchiveUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderArchiveUpsertedArgs, never>>;
  SalesHeaderArchiveRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderArchiveDeleteResponse']>, "SalesHeaderArchiveRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderArchiveRemovedArgs, never>>;
  SalesHeaderHistoryUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderHistory']>, "SalesHeaderHistoryUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderHistoryUpsertedArgs, never>>;
  SalesHeaderHistoryRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderHistoryDeleteResponse']>, "SalesHeaderHistoryRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderHistoryRemovedArgs, never>>;
  SalesHeaderRequestUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderRequest']>, "SalesHeaderRequestUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderRequestUpsertedArgs, never>>;
  SalesHeaderRequestRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesHeaderRequestDeleteResponse']>, "SalesHeaderRequestRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesHeaderRequestRemovedArgs, never>>;
  SalesLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesLine']>, "SalesLineUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesLineUpsertedArgs, never>>;
  SalesLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesLineDeleteResponse']>, "SalesLineRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesLineRemovedArgs, never>>;
  SalesLineArchiveUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesLineArchive']>, "SalesLineArchiveUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesLineArchiveUpsertedArgs, never>>;
  SalesLineArchiveRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesLineArchiveDeleteResponse']>, "SalesLineArchiveRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesLineArchiveRemovedArgs, never>>;
  SalesLineRequestUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesLineRequest']>, "SalesLineRequestUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesLineRequestUpsertedArgs, never>>;
  SalesLineRequestRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesLineRequestDeleteResponse']>, "SalesLineRequestRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesLineRequestRemovedArgs, never>>;
  SalespersonUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Salesperson']>, "SalespersonUpserted", ParentType, ContextType, RequireFields<SubscriptionSalespersonUpsertedArgs, never>>;
  SalespersonRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalespersonDeleteResponse']>, "SalespersonRemoved", ParentType, ContextType, RequireFields<SubscriptionSalespersonRemovedArgs, never>>;
  SalesPriceUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesPrice']>, "SalesPriceUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesPriceUpsertedArgs, never>>;
  SalesPriceRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesPriceDeleteResponse']>, "SalesPriceRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesPriceRemovedArgs, never>>;
  SalesShipmentHeaderUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesShipmentHeader']>, "SalesShipmentHeaderUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesShipmentHeaderUpsertedArgs, never>>;
  SalesShipmentHeaderRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesShipmentHeaderDeleteResponse']>, "SalesShipmentHeaderRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesShipmentHeaderRemovedArgs, never>>;
  SalesShipmentLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SalesShipmentLine']>, "SalesShipmentLineUpserted", ParentType, ContextType, RequireFields<SubscriptionSalesShipmentLineUpsertedArgs, never>>;
  SalesShipmentLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SalesShipmentLineDeleteResponse']>, "SalesShipmentLineRemoved", ParentType, ContextType, RequireFields<SubscriptionSalesShipmentLineRemovedArgs, never>>;
  ServiceItemGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ServiceItemGroup']>, "ServiceItemGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionServiceItemGroupUpsertedArgs, never>>;
  ServiceItemGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ServiceItemGroupDeleteResponse']>, "ServiceItemGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionServiceItemGroupRemovedArgs, never>>;
  ShipmentMethodUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ShipmentMethod']>, "ShipmentMethodUpserted", ParentType, ContextType, RequireFields<SubscriptionShipmentMethodUpsertedArgs, never>>;
  ShipmentMethodRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ShipmentMethodDeleteResponse']>, "ShipmentMethodRemoved", ParentType, ContextType, RequireFields<SubscriptionShipmentMethodRemovedArgs, never>>;
  ShippingAgentUpserted?: SubscriptionResolver<Maybe<ResolversTypes['ShippingAgent']>, "ShippingAgentUpserted", ParentType, ContextType, RequireFields<SubscriptionShippingAgentUpsertedArgs, never>>;
  ShippingAgentRemoved?: SubscriptionResolver<Maybe<ResolversTypes['ShippingAgentDeleteResponse']>, "ShippingAgentRemoved", ParentType, ContextType, RequireFields<SubscriptionShippingAgentRemovedArgs, never>>;
  SignatureUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Signature']>, "SignatureUpserted", ParentType, ContextType, RequireFields<SubscriptionSignatureUpsertedArgs, never>>;
  SignatureRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SignatureDeleteResponse']>, "SignatureRemoved", ParentType, ContextType, RequireFields<SubscriptionSignatureRemovedArgs, never>>;
  SignRequestUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SignRequest']>, "SignRequestUpserted", ParentType, ContextType, RequireFields<SubscriptionSignRequestUpsertedArgs, never>>;
  SignRequestRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SignRequestDeleteResponse']>, "SignRequestRemoved", ParentType, ContextType, RequireFields<SubscriptionSignRequestRemovedArgs, never>>;
  SpecialEquipmentUpserted?: SubscriptionResolver<Maybe<ResolversTypes['SpecialEquipment']>, "SpecialEquipmentUpserted", ParentType, ContextType, RequireFields<SubscriptionSpecialEquipmentUpsertedArgs, never>>;
  SpecialEquipmentRemoved?: SubscriptionResolver<Maybe<ResolversTypes['SpecialEquipmentDeleteResponse']>, "SpecialEquipmentRemoved", ParentType, ContextType, RequireFields<SubscriptionSpecialEquipmentRemovedArgs, never>>;
  StockkeepingUnitUpserted?: SubscriptionResolver<Maybe<ResolversTypes['StockkeepingUnit']>, "StockkeepingUnitUpserted", ParentType, ContextType, RequireFields<SubscriptionStockkeepingUnitUpsertedArgs, never>>;
  StockkeepingUnitRemoved?: SubscriptionResolver<Maybe<ResolversTypes['StockkeepingUnitDeleteResponse']>, "StockkeepingUnitRemoved", ParentType, ContextType, RequireFields<SubscriptionStockkeepingUnitRemovedArgs, never>>;
  TariffNumberUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TariffNumber']>, "TariffNumberUpserted", ParentType, ContextType, RequireFields<SubscriptionTariffNumberUpsertedArgs, never>>;
  TariffNumberRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TariffNumberDeleteResponse']>, "TariffNumberRemoved", ParentType, ContextType, RequireFields<SubscriptionTariffNumberRemovedArgs, never>>;
  TaxGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TaxGroup']>, "TaxGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionTaxGroupUpsertedArgs, never>>;
  TaxGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TaxGroupDeleteResponse']>, "TaxGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionTaxGroupRemovedArgs, never>>;
  TimeRegistrationUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TimeRegistration']>, "TimeRegistrationUpserted", ParentType, ContextType, RequireFields<SubscriptionTimeRegistrationUpsertedArgs, never>>;
  TimeRegistrationRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TimeRegistrationDeleteResponse']>, "TimeRegistrationRemoved", ParentType, ContextType, RequireFields<SubscriptionTimeRegistrationRemovedArgs, never>>;
  TimeSheetDetailUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TimeSheetDetail']>, "TimeSheetDetailUpserted", ParentType, ContextType, RequireFields<SubscriptionTimeSheetDetailUpsertedArgs, never>>;
  TimeSheetDetailRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TimeSheetDetailDeleteResponse']>, "TimeSheetDetailRemoved", ParentType, ContextType, RequireFields<SubscriptionTimeSheetDetailRemovedArgs, never>>;
  TimeSheetLineUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TimeSheetLine']>, "TimeSheetLineUpserted", ParentType, ContextType, RequireFields<SubscriptionTimeSheetLineUpsertedArgs, never>>;
  TimeSheetLineRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TimeSheetLineDeleteResponse']>, "TimeSheetLineRemoved", ParentType, ContextType, RequireFields<SubscriptionTimeSheetLineRemovedArgs, never>>;
  TransactionSpecificationUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TransactionSpecification']>, "TransactionSpecificationUpserted", ParentType, ContextType, RequireFields<SubscriptionTransactionSpecificationUpsertedArgs, never>>;
  TransactionSpecificationRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TransactionSpecificationDeleteResponse']>, "TransactionSpecificationRemoved", ParentType, ContextType, RequireFields<SubscriptionTransactionSpecificationRemovedArgs, never>>;
  TransactionTypeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TransactionType']>, "TransactionTypeUpserted", ParentType, ContextType, RequireFields<SubscriptionTransactionTypeUpsertedArgs, never>>;
  TransactionTypeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TransactionTypeDeleteResponse']>, "TransactionTypeRemoved", ParentType, ContextType, RequireFields<SubscriptionTransactionTypeRemovedArgs, never>>;
  TransportMethodUpserted?: SubscriptionResolver<Maybe<ResolversTypes['TransportMethod']>, "TransportMethodUpserted", ParentType, ContextType, RequireFields<SubscriptionTransportMethodUpsertedArgs, never>>;
  TransportMethodRemoved?: SubscriptionResolver<Maybe<ResolversTypes['TransportMethodDeleteResponse']>, "TransportMethodRemoved", ParentType, ContextType, RequireFields<SubscriptionTransportMethodRemovedArgs, never>>;
  UnitOfMeasureUpserted?: SubscriptionResolver<Maybe<ResolversTypes['UnitOfMeasure']>, "UnitOfMeasureUpserted", ParentType, ContextType, RequireFields<SubscriptionUnitOfMeasureUpsertedArgs, never>>;
  UnitOfMeasureRemoved?: SubscriptionResolver<Maybe<ResolversTypes['UnitOfMeasureDeleteResponse']>, "UnitOfMeasureRemoved", ParentType, ContextType, RequireFields<SubscriptionUnitOfMeasureRemovedArgs, never>>;
  UserUpserted?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "UserUpserted", ParentType, ContextType, RequireFields<SubscriptionUserUpsertedArgs, never>>;
  UserRemoved?: SubscriptionResolver<Maybe<ResolversTypes['UserDeleteResponse']>, "UserRemoved", ParentType, ContextType, RequireFields<SubscriptionUserRemovedArgs, never>>;
  UserFavoritesUpserted?: SubscriptionResolver<Maybe<ResolversTypes['UserFavorites']>, "UserFavoritesUpserted", ParentType, ContextType, RequireFields<SubscriptionUserFavoritesUpsertedArgs, never>>;
  UserFavoritesRemoved?: SubscriptionResolver<Maybe<ResolversTypes['UserFavoritesDeleteResponse']>, "UserFavoritesRemoved", ParentType, ContextType, RequireFields<SubscriptionUserFavoritesRemovedArgs, never>>;
  VatBusinessPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['VatBusinessPostingGroup']>, "VatBusinessPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionVatBusinessPostingGroupUpsertedArgs, never>>;
  VatBusinessPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['VatBusinessPostingGroupDeleteResponse']>, "VatBusinessPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionVatBusinessPostingGroupRemovedArgs, never>>;
  VatProductPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['VatProductPostingGroup']>, "VatProductPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionVatProductPostingGroupUpsertedArgs, never>>;
  VatProductPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['VatProductPostingGroupDeleteResponse']>, "VatProductPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionVatProductPostingGroupRemovedArgs, never>>;
  VendorUpserted?: SubscriptionResolver<Maybe<ResolversTypes['Vendor']>, "VendorUpserted", ParentType, ContextType, RequireFields<SubscriptionVendorUpsertedArgs, never>>;
  VendorRemoved?: SubscriptionResolver<Maybe<ResolversTypes['VendorDeleteResponse']>, "VendorRemoved", ParentType, ContextType, RequireFields<SubscriptionVendorRemovedArgs, never>>;
  VendorBankAccountUpserted?: SubscriptionResolver<Maybe<ResolversTypes['VendorBankAccount']>, "VendorBankAccountUpserted", ParentType, ContextType, RequireFields<SubscriptionVendorBankAccountUpsertedArgs, never>>;
  VendorBankAccountRemoved?: SubscriptionResolver<Maybe<ResolversTypes['VendorBankAccountDeleteResponse']>, "VendorBankAccountRemoved", ParentType, ContextType, RequireFields<SubscriptionVendorBankAccountRemovedArgs, never>>;
  VendorPostingGroupUpserted?: SubscriptionResolver<Maybe<ResolversTypes['VendorPostingGroup']>, "VendorPostingGroupUpserted", ParentType, ContextType, RequireFields<SubscriptionVendorPostingGroupUpsertedArgs, never>>;
  VendorPostingGroupRemoved?: SubscriptionResolver<Maybe<ResolversTypes['VendorPostingGroupDeleteResponse']>, "VendorPostingGroupRemoved", ParentType, ContextType, RequireFields<SubscriptionVendorPostingGroupRemovedArgs, never>>;
  WarehouseClassUpserted?: SubscriptionResolver<Maybe<ResolversTypes['WarehouseClass']>, "WarehouseClassUpserted", ParentType, ContextType, RequireFields<SubscriptionWarehouseClassUpsertedArgs, never>>;
  WarehouseClassRemoved?: SubscriptionResolver<Maybe<ResolversTypes['WarehouseClassDeleteResponse']>, "WarehouseClassRemoved", ParentType, ContextType, RequireFields<SubscriptionWarehouseClassRemovedArgs, never>>;
  WebBookingUpserted?: SubscriptionResolver<Maybe<ResolversTypes['WebBooking']>, "WebBookingUpserted", ParentType, ContextType, RequireFields<SubscriptionWebBookingUpsertedArgs, never>>;
  WebBookingRemoved?: SubscriptionResolver<Maybe<ResolversTypes['WebBookingDeleteResponse']>, "WebBookingRemoved", ParentType, ContextType, RequireFields<SubscriptionWebBookingRemovedArgs, never>>;
  WebhookSubscriptionUpserted?: SubscriptionResolver<Maybe<ResolversTypes['WebhookSubscription']>, "WebhookSubscriptionUpserted", ParentType, ContextType, RequireFields<SubscriptionWebhookSubscriptionUpsertedArgs, never>>;
  WebhookSubscriptionRemoved?: SubscriptionResolver<Maybe<ResolversTypes['WebhookSubscriptionDeleteResponse']>, "WebhookSubscriptionRemoved", ParentType, ContextType, RequireFields<SubscriptionWebhookSubscriptionRemovedArgs, never>>;
  WorkLogUpserted?: SubscriptionResolver<Maybe<ResolversTypes['WorkLog']>, "WorkLogUpserted", ParentType, ContextType, RequireFields<SubscriptionWorkLogUpsertedArgs, never>>;
  WorkLogRemoved?: SubscriptionResolver<Maybe<ResolversTypes['WorkLogDeleteResponse']>, "WorkLogRemoved", ParentType, ContextType, RequireFields<SubscriptionWorkLogRemovedArgs, never>>;
  WorkTypeUpserted?: SubscriptionResolver<Maybe<ResolversTypes['WorkType']>, "WorkTypeUpserted", ParentType, ContextType, RequireFields<SubscriptionWorkTypeUpsertedArgs, never>>;
  WorkTypeRemoved?: SubscriptionResolver<Maybe<ResolversTypes['WorkTypeDeleteResponse']>, "WorkTypeRemoved", ParentType, ContextType, RequireFields<SubscriptionWorkTypeRemovedArgs, never>>;
  DomainIntegrationUpserted?: SubscriptionResolver<Maybe<ResolversTypes['DomainIntegration']>, "DomainIntegrationUpserted", ParentType, ContextType, RequireFields<SubscriptionDomainIntegrationUpsertedArgs, never>>;
  DomainIntegrationRemoved?: SubscriptionResolver<Maybe<ResolversTypes['DomainIntegrationDeleteResponse']>, "DomainIntegrationRemoved", ParentType, ContextType, RequireFields<SubscriptionDomainIntegrationRemovedArgs, never>>;
};

export type TariffNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['TariffNumber'] = ResolversParentTypes['TariffNumber']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SupplementaryUnits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TariffNumberConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TariffNumberConnection'] = ResolversParentTypes['TariffNumberConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TariffNumberConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TariffNumberConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TariffNumberConnectionEdge'] = ResolversParentTypes['TariffNumberConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TariffNumber'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TariffNumberDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TariffNumberDeleteResponse'] = ResolversParentTypes['TariffNumberDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TariffNumber']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TariffNumberManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TariffNumberManyResult'] = ResolversParentTypes['TariffNumberManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TariffNumber']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroup'] = ResolversParentTypes['TaxGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupConnection'] = ResolversParentTypes['TaxGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TaxGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupConnectionEdge'] = ResolversParentTypes['TaxGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TaxGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupDeleteResponse'] = ResolversParentTypes['TaxGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaxGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxGroupManyResult'] = ResolversParentTypes['TaxGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaxGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeRegistrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeRegistration'] = ResolversParentTypes['TimeRegistration']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TimeSheetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeSheetLineLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TimeSheetDetailLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CauseofAbsenceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Resource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StartDateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  EndDateFilter?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeRegistrationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeRegistrationConnection'] = ResolversParentTypes['TimeRegistrationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TimeRegistrationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeRegistrationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeRegistrationConnectionEdge'] = ResolversParentTypes['TimeRegistrationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TimeRegistration'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeRegistrationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeRegistrationDeleteResponse'] = ResolversParentTypes['TimeRegistrationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeRegistration']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeRegistrationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeRegistrationManyResult'] = ResolversParentTypes['TimeRegistrationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeRegistration']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetDetail'] = ResolversParentTypes['TimeSheetDetail']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeSheetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeSheetLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResourceNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CauseofAbsenceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PostedQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AssemblyOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssemblyOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Posted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExcludeFromTotals?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NotChargeable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetDetailConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetDetailConnection'] = ResolversParentTypes['TimeSheetDetailConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TimeSheetDetailConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetDetailConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetDetailConnectionEdge'] = ResolversParentTypes['TimeSheetDetailConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TimeSheetDetail'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetDetailDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetDetailDeleteResponse'] = ResolversParentTypes['TimeSheetDetailDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeSheetDetail']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetDetailManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetDetailManyResult'] = ResolversParentTypes['TimeSheetDetailManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeSheetDetail']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetLine'] = ResolversParentTypes['TimeSheetLine']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TimeSheetNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TimeSheetStartingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTaskNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CauseofAbsenceCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApproverID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Chargeable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AssemblyOrderNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AssemblyOrderLineNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Posted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UnitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NotChargeable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  TotalQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetLineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetLineConnection'] = ResolversParentTypes['TimeSheetLineConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TimeSheetLineConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetLineConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetLineConnectionEdge'] = ResolversParentTypes['TimeSheetLineConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TimeSheetLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetLineDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetLineDeleteResponse'] = ResolversParentTypes['TimeSheetLineDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeSheetLine']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetLineManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetLineManyResult'] = ResolversParentTypes['TimeSheetLineManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeSheetLine']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionSpecificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionSpecification'] = ResolversParentTypes['TransactionSpecification']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionSpecificationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionSpecificationConnection'] = ResolversParentTypes['TransactionSpecificationConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TransactionSpecificationConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionSpecificationConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionSpecificationConnectionEdge'] = ResolversParentTypes['TransactionSpecificationConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TransactionSpecification'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionSpecificationDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionSpecificationDeleteResponse'] = ResolversParentTypes['TransactionSpecificationDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionSpecification']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionSpecificationManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionSpecificationManyResult'] = ResolversParentTypes['TransactionSpecificationManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionSpecification']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionType'] = ResolversParentTypes['TransactionType']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionTypeConnection'] = ResolversParentTypes['TransactionTypeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TransactionTypeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionTypeConnectionEdge'] = ResolversParentTypes['TransactionTypeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TransactionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionTypeDeleteResponse'] = ResolversParentTypes['TransactionTypeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionType']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionTypeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionTypeManyResult'] = ResolversParentTypes['TransactionTypeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransactionType']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportMethod'] = ResolversParentTypes['TransportMethod']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportMethodConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportMethodConnection'] = ResolversParentTypes['TransportMethodConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TransportMethodConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportMethodConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportMethodConnectionEdge'] = ResolversParentTypes['TransportMethodConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TransportMethod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportMethodDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportMethodDeleteResponse'] = ResolversParentTypes['TransportMethodDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportMethod']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportMethodManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportMethodManyResult'] = ResolversParentTypes['TransportMethodManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportMethod']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitOfMeasureResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitOfMeasure'] = ResolversParentTypes['UnitOfMeasure']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InternationalStandardCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  CoupledtoCRM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExternalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitOfMeasureConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitOfMeasureConnection'] = ResolversParentTypes['UnitOfMeasureConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['UnitOfMeasureConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitOfMeasureConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitOfMeasureConnectionEdge'] = ResolversParentTypes['UnitOfMeasureConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['UnitOfMeasure'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitOfMeasureDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitOfMeasureDeleteResponse'] = ResolversParentTypes['UnitOfMeasureDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['UnitOfMeasure']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitOfMeasureManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitOfMeasureManyResult'] = ResolversParentTypes['UnitOfMeasureManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['UnitOfMeasure']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UnsignedIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedInt'], any> {
  name: 'UnsignedInt';
}

export type UploadAppCodesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadAppCodesResponse'] = ResolversParentTypes['UploadAppCodesResponse']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newCodesAdded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCodeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pushTokens?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  Dyn365Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DynNAVId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ProjectNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  FilterProjectIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  VerifiedIdentity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsDemo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  EmailOnExpiredObjects?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PushNotificationOnShipments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IdentityVerifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PendingAccessRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DefaultSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavApiUserNameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavApiPasswordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserInvitationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserAcceptedInvitationOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ImpersonateUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  AppPushTokens?: Resolver<Maybe<Array<Maybe<ResolversTypes['AppPushtoken']>>>, ParentType, ContextType>;
  favoriteProjects?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerProject']>>>, ParentType, ContextType>;
  domain?: Resolver<Maybe<ResolversTypes['Domain']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  DefaultResponsibilityCenterMatrix?: Resolver<Maybe<Array<Maybe<ResolversTypes['ResponsibilityCenterMatrix']>>>, ParentType, ContextType>;
  NotificationsForProjects?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProjectPushNotifications']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['UserConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnectionEdge'] = ResolversParentTypes['UserConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeleteResponse'] = ResolversParentTypes['UserDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFavoritesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFavorites'] = ResolversParentTypes['UserFavorites']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userGlobalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFavoritesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFavoritesConnection'] = ResolversParentTypes['UserFavoritesConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['UserFavoritesConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFavoritesConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFavoritesConnectionEdge'] = ResolversParentTypes['UserFavoritesConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['UserFavorites'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFavoritesDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFavoritesDeleteResponse'] = ResolversParentTypes['UserFavoritesDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserFavorites']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFavoritesManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFavoritesManyResult'] = ResolversParentTypes['UserFavoritesManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserFavorites']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserManyResult'] = ResolversParentTypes['UserManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOperationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserOperationResponse'] = ResolversParentTypes['UserOperationResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatBusinessPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatBusinessPostingGroup'] = ResolversParentTypes['VatBusinessPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatBusinessPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatBusinessPostingGroupConnection'] = ResolversParentTypes['VatBusinessPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['VatBusinessPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatBusinessPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatBusinessPostingGroupConnectionEdge'] = ResolversParentTypes['VatBusinessPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['VatBusinessPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatBusinessPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatBusinessPostingGroupDeleteResponse'] = ResolversParentTypes['VatBusinessPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VatBusinessPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatBusinessPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatBusinessPostingGroupManyResult'] = ResolversParentTypes['VatBusinessPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VatBusinessPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatProductPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatProductPostingGroup'] = ResolversParentTypes['VatProductPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatProductPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatProductPostingGroupConnection'] = ResolversParentTypes['VatProductPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['VatProductPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatProductPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatProductPostingGroupConnectionEdge'] = ResolversParentTypes['VatProductPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['VatProductPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatProductPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatProductPostingGroupDeleteResponse'] = ResolversParentTypes['VatProductPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VatProductPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VatProductPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VatProductPostingGroupManyResult'] = ResolversParentTypes['VatProductPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VatProductPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vendor'] = ResolversParentTypes['Vendor']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OurAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TerritoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BudgetedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  VendorPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  StatisticsGroup?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FinChargeTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PurchaserCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShipmentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ShippingAgentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceDiscCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Blocked?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BlockedOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaytoVendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentMethodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  LastDateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ApplicationMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ApplicationMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PricesIncludingVAT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexAnswerBack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GLN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NoSeries?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxAreaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxLiable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  VATBusPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BlockPaymentTolerance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ICPartnerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Prepayment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PartnerType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PartnerTypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PreferredBankAccountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CashFlowPaymentTermsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrimaryContactNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponsibilityCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LocationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LeadTimeCalculation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BaseCalendarCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentSendingProfile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ValidateEUVatRegNo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentTermsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentMethodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NocontrolofExtDocNo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ExternalDocumentNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DocumentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension1Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GlobalDimension2Filter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetChange?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NetChangeLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PurchasesLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvDiscountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceDue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BalanceDueLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Payments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvoiceAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrMemoAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FinanceChargeMemoAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaymentsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InvAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CrMemoAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  FinChargeMemoAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtRcdNotInvoiced?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DebitAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DebitAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CreditAmountLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReminderAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ReminderAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingOrdersLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AmtRcdNotInvoicedLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtDiscToleranceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PmtToleranceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Refunds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  RefundsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OtherAmounts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OtherAmountsLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OutstandingInvoicesLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoOfArchivedDoc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BuyfromNoOfArchivedDoc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdReceipts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdReturnShipments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofPstdCreditMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofReturnOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofCreditMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofPstdReceipts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofPstdInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofPstdReturnS?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofPstdCrMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofQuotes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofBlanketOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofInvoices?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofReturnOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofCreditMemos?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofOrderAddresses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofQuotes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PaytoNoofBlanketOrders?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NoofIncomingDocuments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InwardRegBalanceLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  InwardRegNetChangeLCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StateCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  StatusCode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorBankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorBankAccount'] = ResolversParentTypes['VendorBankAccount']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VendorNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  City?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PostCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankBranchNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankAccountNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TransitNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CountryRegionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  County?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FaxNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TelexAnswerBack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LanguageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  HomePage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IBAN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SWIFTCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankClearingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BankClearingStandard?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBPlusGiroNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBPaymentMethod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBPaymentMethodOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBGiroTypeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBBankGiroNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBCodeforBankofSweden?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBAccountPaymentwithAdvis?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PEBChargePaidby?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBChargePaidbyOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBCategoryPurpose?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBCategoryPurposeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PEBStatusOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorBankAccountConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorBankAccountConnection'] = ResolversParentTypes['VendorBankAccountConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['VendorBankAccountConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorBankAccountConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorBankAccountConnectionEdge'] = ResolversParentTypes['VendorBankAccountConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['VendorBankAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorBankAccountDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorBankAccountDeleteResponse'] = ResolversParentTypes['VendorBankAccountDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorBankAccount']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorBankAccountManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorBankAccountManyResult'] = ResolversParentTypes['VendorBankAccountManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorBankAccount']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorConnection'] = ResolversParentTypes['VendorConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['VendorConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorConnectionEdge'] = ResolversParentTypes['VendorConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorDeleteResponse'] = ResolversParentTypes['VendorDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vendor']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorManyResult'] = ResolversParentTypes['VendorManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vendor']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorPostingGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorPostingGroup'] = ResolversParentTypes['VendorPostingGroup']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PayablesAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ServiceChargeAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentDiscDebitAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InvoiceRoundingAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitCurrApplnRndgAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditCurrApplnRndgAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DebitRoundingAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreditRoundingAccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentDiscCreditAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentToleranceDebitAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PaymentToleranceCreditAcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ViewAllAccountsonLookup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  InwardRegBridgingDebt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PEBInwardRegBridgingDebt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorPostingGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorPostingGroupConnection'] = ResolversParentTypes['VendorPostingGroupConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['VendorPostingGroupConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorPostingGroupConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorPostingGroupConnectionEdge'] = ResolversParentTypes['VendorPostingGroupConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['VendorPostingGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorPostingGroupDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorPostingGroupDeleteResponse'] = ResolversParentTypes['VendorPostingGroupDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorPostingGroup']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorPostingGroupManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorPostingGroupManyResult'] = ResolversParentTypes['VendorPostingGroupManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['VendorPostingGroup']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WarehouseClassResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseClass'] = ResolversParentTypes['WarehouseClass']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WarehouseClassConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseClassConnection'] = ResolversParentTypes['WarehouseClassConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['WarehouseClassConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WarehouseClassConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseClassConnectionEdge'] = ResolversParentTypes['WarehouseClassConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['WarehouseClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WarehouseClassDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseClassDeleteResponse'] = ResolversParentTypes['WarehouseClassDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WarehouseClass']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WarehouseClassManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseClassManyResult'] = ResolversParentTypes['WarehouseClassManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WarehouseClass']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebBookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebBooking'] = ResolversParentTypes['WebBooking']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BookingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TypeOPTION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  No?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  DeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DeliveryAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactEMail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Handled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ContractNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebBookingConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebBookingConnection'] = ResolversParentTypes['WebBookingConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['WebBookingConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebBookingConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebBookingConnectionEdge'] = ResolversParentTypes['WebBookingConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['WebBooking'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebBookingDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebBookingDeleteResponse'] = ResolversParentTypes['WebBookingDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebBooking']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebBookingManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebBookingManyResult'] = ResolversParentTypes['WebBookingManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebBooking']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookSubscription'] = ResolversParentTypes['WebhookSubscription']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Resource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EventType?: Resolver<Maybe<ResolversTypes['WebhookSubscriptionEventTypeEnum']>, ParentType, ContextType>;
  Enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastUsed?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookSubscriptionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookSubscriptionConnection'] = ResolversParentTypes['WebhookSubscriptionConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['WebhookSubscriptionConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookSubscriptionConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookSubscriptionConnectionEdge'] = ResolversParentTypes['WebhookSubscriptionConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['WebhookSubscription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookSubscriptionDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookSubscriptionDeleteResponse'] = ResolversParentTypes['WebhookSubscriptionDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebhookSubscription']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookSubscriptionManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookSubscriptionManyResult'] = ResolversParentTypes['WebhookSubscriptionManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebhookSubscription']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLog'] = ResolversParentTypes['WorkLog']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EntryNo?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  CustomerNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerProject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SimplonaEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NAVUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WorkDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  Debited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotDebit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLogConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLogConnection'] = ResolversParentTypes['WorkLogConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['WorkLogConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLogConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLogConnectionEdge'] = ResolversParentTypes['WorkLogConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['WorkLog'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLogDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLogDeleteResponse'] = ResolversParentTypes['WorkLogDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkLog']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLogManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLogManyResult'] = ResolversParentTypes['WorkLogManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkLog']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkType'] = ResolversParentTypes['WorkType']> = {
  discriminator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UnitofMeasureCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GenProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VATProdPostingGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  TotalCountReadOnly?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  KeyValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyRegistrationNoClean?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DomainId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SignStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SearchString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncedToNav?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SyncCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SyncProblems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SyncProblemOrgiginalEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NavRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ConnectedToRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ModifiedOnSales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedOnMagento?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SentToMagento?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToWebhook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToCoredination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  SentToEquipmentLoop?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ForceSave?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  DoNotRetrySaveToNAV?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  GlobalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  InitiatingUserEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  InitiatingUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  SystemModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkTypeConnection'] = ResolversParentTypes['WorkTypeConnection']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['WorkTypeConnectionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeConnectionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkTypeConnectionEdge'] = ResolversParentTypes['WorkTypeConnectionEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkTypeDeleteResponse'] = ResolversParentTypes['WorkTypeDeleteResponse']> = {
  ok?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deletedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkType']>>>, ParentType, ContextType>;
  GlobalIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeManyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkTypeManyResult'] = ResolversParentTypes['WorkTypeManyResult']> = {
  records?: Resolver<Maybe<Array<Maybe<ResolversTypes['WorkType']>>>, ParentType, ContextType>;
  pageData?: Resolver<Maybe<ResolversTypes['PageData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCompanyInvitationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['createCompanyInvitationResponse'] = ResolversParentTypes['createCompanyInvitationResponse']> = {
  invitation?: Resolver<Maybe<ResolversTypes['Invitation']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncUsersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['syncUsersResponse'] = ResolversParentTypes['syncUsersResponse']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleSuplierResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['toggleSuplierResponse'] = ResolversParentTypes['toggleSuplierResponse']> = {
  Error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AcceptInivitationResponse?: AcceptInivitationResponseResolvers<ContextType>;
  AccessRequest?: AccessRequestResolvers<ContextType>;
  AccessRequestConnection?: AccessRequestConnectionResolvers<ContextType>;
  AccessRequestConnectionEdge?: AccessRequestConnectionEdgeResolvers<ContextType>;
  AccessRequestDeleteResponse?: AccessRequestDeleteResponseResolvers<ContextType>;
  AccessRequestManyResult?: AccessRequestManyResultResolvers<ContextType>;
  AccumulatedRentCost?: AccumulatedRentCostResolvers<ContextType>;
  AdditionalItem?: AdditionalItemResolvers<ContextType>;
  AdditionalItemConnection?: AdditionalItemConnectionResolvers<ContextType>;
  AdditionalItemConnectionEdge?: AdditionalItemConnectionEdgeResolvers<ContextType>;
  AdditionalItemDeleteResponse?: AdditionalItemDeleteResponseResolvers<ContextType>;
  AdditionalItemManyResult?: AdditionalItemManyResultResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AddressConnection?: AddressConnectionResolvers<ContextType>;
  AddressConnectionEdge?: AddressConnectionEdgeResolvers<ContextType>;
  AddressDeleteResponse?: AddressDeleteResponseResolvers<ContextType>;
  AddressManyResult?: AddressManyResultResolvers<ContextType>;
  Agreement?: AgreementResolvers<ContextType>;
  AgreementConnection?: AgreementConnectionResolvers<ContextType>;
  AgreementConnectionEdge?: AgreementConnectionEdgeResolvers<ContextType>;
  AgreementDeleteResponse?: AgreementDeleteResponseResolvers<ContextType>;
  AgreementManyResult?: AgreementManyResultResolvers<ContextType>;
  Annotation?: AnnotationResolvers<ContextType>;
  AnnotationConnection?: AnnotationConnectionResolvers<ContextType>;
  AnnotationConnectionEdge?: AnnotationConnectionEdgeResolvers<ContextType>;
  AnnotationDeleteResponse?: AnnotationDeleteResponseResolvers<ContextType>;
  AnnotationManyResult?: AnnotationManyResultResolvers<ContextType>;
  AppPushtoken?: AppPushtokenResolvers<ContextType>;
  ApplicationModule?: ApplicationModuleResolvers<ContextType>;
  ApplicationModuleConnection?: ApplicationModuleConnectionResolvers<ContextType>;
  ApplicationModuleConnectionEdge?: ApplicationModuleConnectionEdgeResolvers<ContextType>;
  ApplicationModuleDeleteResponse?: ApplicationModuleDeleteResponseResolvers<ContextType>;
  ApplicationModuleManyResult?: ApplicationModuleManyResultResolvers<ContextType>;
  AuthError?: AuthErrorResolvers<ContextType>;
  AuthResponse?: AuthResponseResolvers<ContextType>;
  AutoComplete?: AutoCompleteResolvers<ContextType>;
  BcMappingField?: BcMappingFieldResolvers<ContextType>;
  BcMappingFieldConnection?: BcMappingFieldConnectionResolvers<ContextType>;
  BcMappingFieldConnectionEdge?: BcMappingFieldConnectionEdgeResolvers<ContextType>;
  BcMappingFieldDeleteResponse?: BcMappingFieldDeleteResponseResolvers<ContextType>;
  BcMappingFieldManyResult?: BcMappingFieldManyResultResolvers<ContextType>;
  BcMappingTable?: BcMappingTableResolvers<ContextType>;
  BcMappingTableConnection?: BcMappingTableConnectionResolvers<ContextType>;
  BcMappingTableConnectionEdge?: BcMappingTableConnectionEdgeResolvers<ContextType>;
  BcMappingTableDeleteResponse?: BcMappingTableDeleteResponseResolvers<ContextType>;
  BcMappingTableManyResult?: BcMappingTableManyResultResolvers<ContextType>;
  BcSettings?: BcSettingsResolvers<ContextType>;
  BcSettingsConnection?: BcSettingsConnectionResolvers<ContextType>;
  BcSettingsConnectionEdge?: BcSettingsConnectionEdgeResolvers<ContextType>;
  BcSettingsDeleteResponse?: BcSettingsDeleteResponseResolvers<ContextType>;
  BcSettingsManyResult?: BcSettingsManyResultResolvers<ContextType>;
  BulkItemGroup?: BulkItemGroupResolvers<ContextType>;
  BulkItemGroupConnection?: BulkItemGroupConnectionResolvers<ContextType>;
  BulkItemGroupConnectionEdge?: BulkItemGroupConnectionEdgeResolvers<ContextType>;
  BulkItemGroupDeleteResponse?: BulkItemGroupDeleteResponseResolvers<ContextType>;
  BulkItemGroupManyResult?: BulkItemGroupManyResultResolvers<ContextType>;
  CauseOfAbsence?: CauseOfAbsenceResolvers<ContextType>;
  CauseOfAbsenceConnection?: CauseOfAbsenceConnectionResolvers<ContextType>;
  CauseOfAbsenceConnectionEdge?: CauseOfAbsenceConnectionEdgeResolvers<ContextType>;
  CauseOfAbsenceDeleteResponse?: CauseOfAbsenceDeleteResponseResolvers<ContextType>;
  CauseOfAbsenceManyResult?: CauseOfAbsenceManyResultResolvers<ContextType>;
  ClearFunctionalTestReusult?: ClearFunctionalTestReusultResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  CompanyConnection?: CompanyConnectionResolvers<ContextType>;
  CompanyConnectionEdge?: CompanyConnectionEdgeResolvers<ContextType>;
  CompanyDeleteResponse?: CompanyDeleteResponseResolvers<ContextType>;
  CompanyManyResult?: CompanyManyResultResolvers<ContextType>;
  ConsignmentHeader?: ConsignmentHeaderResolvers<ContextType>;
  ConsignmentHeaderConnection?: ConsignmentHeaderConnectionResolvers<ContextType>;
  ConsignmentHeaderConnectionEdge?: ConsignmentHeaderConnectionEdgeResolvers<ContextType>;
  ConsignmentHeaderDeleteResponse?: ConsignmentHeaderDeleteResponseResolvers<ContextType>;
  ConsignmentHeaderManyResult?: ConsignmentHeaderManyResultResolvers<ContextType>;
  ConsignmentLine?: ConsignmentLineResolvers<ContextType>;
  ConsignmentLineConnection?: ConsignmentLineConnectionResolvers<ContextType>;
  ConsignmentLineConnectionEdge?: ConsignmentLineConnectionEdgeResolvers<ContextType>;
  ConsignmentLineDeleteResponse?: ConsignmentLineDeleteResponseResolvers<ContextType>;
  ConsignmentLineManyResult?: ConsignmentLineManyResultResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  ContactConnection?: ContactConnectionResolvers<ContextType>;
  ContactConnectionEdge?: ContactConnectionEdgeResolvers<ContextType>;
  ContactDeleteResponse?: ContactDeleteResponseResolvers<ContextType>;
  ContactManyResult?: ContactManyResultResolvers<ContextType>;
  CountryRegion?: CountryRegionResolvers<ContextType>;
  CountryRegionConnection?: CountryRegionConnectionResolvers<ContextType>;
  CountryRegionConnectionEdge?: CountryRegionConnectionEdgeResolvers<ContextType>;
  CountryRegionDeleteResponse?: CountryRegionDeleteResponseResolvers<ContextType>;
  CountryRegionManyResult?: CountryRegionManyResultResolvers<ContextType>;
  CreateWorkshopOrderResponse?: CreateWorkshopOrderResponseResolvers<ContextType>;
  Currency?: CurrencyResolvers<ContextType>;
  CurrencyConnection?: CurrencyConnectionResolvers<ContextType>;
  CurrencyConnectionEdge?: CurrencyConnectionEdgeResolvers<ContextType>;
  CurrencyDeleteResponse?: CurrencyDeleteResponseResolvers<ContextType>;
  CurrencyExchangeRate?: CurrencyExchangeRateResolvers<ContextType>;
  CurrencyExchangeRateConnection?: CurrencyExchangeRateConnectionResolvers<ContextType>;
  CurrencyExchangeRateConnectionEdge?: CurrencyExchangeRateConnectionEdgeResolvers<ContextType>;
  CurrencyExchangeRateDeleteResponse?: CurrencyExchangeRateDeleteResponseResolvers<ContextType>;
  CurrencyExchangeRateManyResult?: CurrencyExchangeRateManyResultResolvers<ContextType>;
  CurrencyManyResult?: CurrencyManyResultResolvers<ContextType>;
  CustomerDomain?: CustomerDomainResolvers<ContextType>;
  CustomerDomainConnection?: CustomerDomainConnectionResolvers<ContextType>;
  CustomerDomainConnectionEdge?: CustomerDomainConnectionEdgeResolvers<ContextType>;
  CustomerDomainDeleteResponse?: CustomerDomainDeleteResponseResolvers<ContextType>;
  CustomerDomainLimited?: CustomerDomainLimitedResolvers<ContextType>;
  CustomerDomainManyResult?: CustomerDomainManyResultResolvers<ContextType>;
  CustomerPostingGroup?: CustomerPostingGroupResolvers<ContextType>;
  CustomerPostingGroupConnection?: CustomerPostingGroupConnectionResolvers<ContextType>;
  CustomerPostingGroupConnectionEdge?: CustomerPostingGroupConnectionEdgeResolvers<ContextType>;
  CustomerPostingGroupDeleteResponse?: CustomerPostingGroupDeleteResponseResolvers<ContextType>;
  CustomerPostingGroupManyResult?: CustomerPostingGroupManyResultResolvers<ContextType>;
  CustomerProject?: CustomerProjectResolvers<ContextType>;
  CustomerProjectConnection?: CustomerProjectConnectionResolvers<ContextType>;
  CustomerProjectConnectionEdge?: CustomerProjectConnectionEdgeResolvers<ContextType>;
  CustomerProjectDeleteResponse?: CustomerProjectDeleteResponseResolvers<ContextType>;
  CustomerProjectManyResult?: CustomerProjectManyResultResolvers<ContextType>;
  CustomerSubProject?: CustomerSubProjectResolvers<ContextType>;
  CustomerSubProjectConnection?: CustomerSubProjectConnectionResolvers<ContextType>;
  CustomerSubProjectConnectionEdge?: CustomerSubProjectConnectionEdgeResolvers<ContextType>;
  CustomerSubProjectDeleteResponse?: CustomerSubProjectDeleteResponseResolvers<ContextType>;
  CustomerSubProjectManyResult?: CustomerSubProjectManyResultResolvers<ContextType>;
  DailyRentalEvents?: DailyRentalEventsResolvers<ContextType>;
  DailyTransactionResponse?: DailyTransactionResponseResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteResponse?: DeleteResponseResolvers<ContextType>;
  DeletedRecord?: DeletedRecordResolvers<ContextType>;
  DeletedRecordRef?: DeletedRecordRefResolvers<ContextType>;
  Deliverytime?: DeliverytimeResolvers<ContextType>;
  DeliverytimeConnection?: DeliverytimeConnectionResolvers<ContextType>;
  DeliverytimeConnectionEdge?: DeliverytimeConnectionEdgeResolvers<ContextType>;
  DeliverytimeDeleteResponse?: DeliverytimeDeleteResponseResolvers<ContextType>;
  DeliverytimeManyResult?: DeliverytimeManyResultResolvers<ContextType>;
  DevopsProject?: DevopsProjectResolvers<ContextType>;
  DevopsProjectConnection?: DevopsProjectConnectionResolvers<ContextType>;
  DevopsProjectConnectionEdge?: DevopsProjectConnectionEdgeResolvers<ContextType>;
  DevopsProjectDeleteResponse?: DevopsProjectDeleteResponseResolvers<ContextType>;
  DevopsProjectManyResult?: DevopsProjectManyResultResolvers<ContextType>;
  DevopsTeam?: DevopsTeamResolvers<ContextType>;
  DevopsTeamConnection?: DevopsTeamConnectionResolvers<ContextType>;
  DevopsTeamConnectionEdge?: DevopsTeamConnectionEdgeResolvers<ContextType>;
  DevopsTeamDeleteResponse?: DevopsTeamDeleteResponseResolvers<ContextType>;
  DevopsTeamManyResult?: DevopsTeamManyResultResolvers<ContextType>;
  DevopsWorkItem?: DevopsWorkItemResolvers<ContextType>;
  DevopsWorkItemConnection?: DevopsWorkItemConnectionResolvers<ContextType>;
  DevopsWorkItemConnectionEdge?: DevopsWorkItemConnectionEdgeResolvers<ContextType>;
  DevopsWorkItemDeleteResponse?: DevopsWorkItemDeleteResponseResolvers<ContextType>;
  DevopsWorkItemManyResult?: DevopsWorkItemManyResultResolvers<ContextType>;
  Dimension?: DimensionResolvers<ContextType>;
  DimensionConnection?: DimensionConnectionResolvers<ContextType>;
  DimensionConnectionEdge?: DimensionConnectionEdgeResolvers<ContextType>;
  DimensionDeleteResponse?: DimensionDeleteResponseResolvers<ContextType>;
  DimensionManyResult?: DimensionManyResultResolvers<ContextType>;
  DimensionValue?: DimensionValueResolvers<ContextType>;
  DimensionValueConnection?: DimensionValueConnectionResolvers<ContextType>;
  DimensionValueConnectionEdge?: DimensionValueConnectionEdgeResolvers<ContextType>;
  DimensionValueDeleteResponse?: DimensionValueDeleteResponseResolvers<ContextType>;
  DimensionValueManyResult?: DimensionValueManyResultResolvers<ContextType>;
  DiscussionPost?: DiscussionPostResolvers<ContextType>;
  DiscussionPostConnection?: DiscussionPostConnectionResolvers<ContextType>;
  DiscussionPostConnectionEdge?: DiscussionPostConnectionEdgeResolvers<ContextType>;
  DiscussionPostDeleteResponse?: DiscussionPostDeleteResponseResolvers<ContextType>;
  DiscussionPostManyResult?: DiscussionPostManyResultResolvers<ContextType>;
  Document?: DocumentResolvers<ContextType>;
  DocumentClassification?: DocumentClassificationResolvers<ContextType>;
  DocumentClassificationConnection?: DocumentClassificationConnectionResolvers<ContextType>;
  DocumentClassificationConnectionEdge?: DocumentClassificationConnectionEdgeResolvers<ContextType>;
  DocumentClassificationDeleteResponse?: DocumentClassificationDeleteResponseResolvers<ContextType>;
  DocumentClassificationManyResult?: DocumentClassificationManyResultResolvers<ContextType>;
  DocumentConnection?: DocumentConnectionResolvers<ContextType>;
  DocumentConnectionEdge?: DocumentConnectionEdgeResolvers<ContextType>;
  DocumentDeleteResponse?: DocumentDeleteResponseResolvers<ContextType>;
  DocumentManyResult?: DocumentManyResultResolvers<ContextType>;
  DocumentRequest?: DocumentRequestResolvers<ContextType>;
  DocumentRequestConnection?: DocumentRequestConnectionResolvers<ContextType>;
  DocumentRequestConnectionEdge?: DocumentRequestConnectionEdgeResolvers<ContextType>;
  DocumentRequestDeleteResponse?: DocumentRequestDeleteResponseResolvers<ContextType>;
  DocumentRequestManyResult?: DocumentRequestManyResultResolvers<ContextType>;
  Domain?: DomainResolvers<ContextType>;
  DomainConnection?: DomainConnectionResolvers<ContextType>;
  DomainConnectionEdge?: DomainConnectionEdgeResolvers<ContextType>;
  DomainConnectionTestResult?: DomainConnectionTestResultResolvers<ContextType>;
  DomainDeleteResponse?: DomainDeleteResponseResolvers<ContextType>;
  DomainIntegration?: DomainIntegrationResolvers<ContextType>;
  DomainIntegrationConnection?: DomainIntegrationConnectionResolvers<ContextType>;
  DomainIntegrationConnectionEdge?: DomainIntegrationConnectionEdgeResolvers<ContextType>;
  DomainIntegrationDeleteResponse?: DomainIntegrationDeleteResponseResolvers<ContextType>;
  DomainIntegrationManyResult?: DomainIntegrationManyResultResolvers<ContextType>;
  DomainLimited?: DomainLimitedResolvers<ContextType>;
  DomainManyResult?: DomainManyResultResolvers<ContextType>;
  DomainSettings?: DomainSettingsResolvers<ContextType>;
  DomainSettingsConnection?: DomainSettingsConnectionResolvers<ContextType>;
  DomainSettingsConnectionEdge?: DomainSettingsConnectionEdgeResolvers<ContextType>;
  DomainSettingsDeleteResponse?: DomainSettingsDeleteResponseResolvers<ContextType>;
  DomainSettingsManyResult?: DomainSettingsManyResultResolvers<ContextType>;
  EmailAddress?: GraphQLScalarType;
  EqmBaseCalendar?: EqmBaseCalendarResolvers<ContextType>;
  EqmBaseCalendarChange?: EqmBaseCalendarChangeResolvers<ContextType>;
  EqmBaseCalendarChangeConnection?: EqmBaseCalendarChangeConnectionResolvers<ContextType>;
  EqmBaseCalendarChangeConnectionEdge?: EqmBaseCalendarChangeConnectionEdgeResolvers<ContextType>;
  EqmBaseCalendarChangeDeleteResponse?: EqmBaseCalendarChangeDeleteResponseResolvers<ContextType>;
  EqmBaseCalendarChangeManyResult?: EqmBaseCalendarChangeManyResultResolvers<ContextType>;
  EqmBaseCalendarConnection?: EqmBaseCalendarConnectionResolvers<ContextType>;
  EqmBaseCalendarConnectionEdge?: EqmBaseCalendarConnectionEdgeResolvers<ContextType>;
  EqmBaseCalendarDeleteResponse?: EqmBaseCalendarDeleteResponseResolvers<ContextType>;
  EqmBaseCalendarManyResult?: EqmBaseCalendarManyResultResolvers<ContextType>;
  EqmCollectionReturnCharges?: EqmCollectionReturnChargesResolvers<ContextType>;
  EqmCollectionReturnChargesConnection?: EqmCollectionReturnChargesConnectionResolvers<ContextType>;
  EqmCollectionReturnChargesConnectionEdge?: EqmCollectionReturnChargesConnectionEdgeResolvers<ContextType>;
  EqmCollectionReturnChargesDeleteResponse?: EqmCollectionReturnChargesDeleteResponseResolvers<ContextType>;
  EqmCollectionReturnChargesManyResult?: EqmCollectionReturnChargesManyResultResolvers<ContextType>;
  EqmCommentLine?: EqmCommentLineResolvers<ContextType>;
  EqmCommentLineConnection?: EqmCommentLineConnectionResolvers<ContextType>;
  EqmCommentLineConnectionEdge?: EqmCommentLineConnectionEdgeResolvers<ContextType>;
  EqmCommentLineDeleteResponse?: EqmCommentLineDeleteResponseResolvers<ContextType>;
  EqmCommentLineManyResult?: EqmCommentLineManyResultResolvers<ContextType>;
  EqmCustomizedCalendarChange?: EqmCustomizedCalendarChangeResolvers<ContextType>;
  EqmCustomizedCalendarChangeConnection?: EqmCustomizedCalendarChangeConnectionResolvers<ContextType>;
  EqmCustomizedCalendarChangeConnectionEdge?: EqmCustomizedCalendarChangeConnectionEdgeResolvers<ContextType>;
  EqmCustomizedCalendarChangeDeleteResponse?: EqmCustomizedCalendarChangeDeleteResponseResolvers<ContextType>;
  EqmCustomizedCalendarChangeManyResult?: EqmCustomizedCalendarChangeManyResultResolvers<ContextType>;
  EqmFncTestReturnCharges?: EqmFncTestReturnChargesResolvers<ContextType>;
  EqmFncTestReturnChargesConnection?: EqmFncTestReturnChargesConnectionResolvers<ContextType>;
  EqmFncTestReturnChargesConnectionEdge?: EqmFncTestReturnChargesConnectionEdgeResolvers<ContextType>;
  EqmFncTestReturnChargesDeleteResponse?: EqmFncTestReturnChargesDeleteResponseResolvers<ContextType>;
  EqmFncTestReturnChargesManyResult?: EqmFncTestReturnChargesManyResultResolvers<ContextType>;
  EqmLocCostCenterComb?: EqmLocCostCenterCombResolvers<ContextType>;
  EqmLocCostCenterCombConnection?: EqmLocCostCenterCombConnectionResolvers<ContextType>;
  EqmLocCostCenterCombConnectionEdge?: EqmLocCostCenterCombConnectionEdgeResolvers<ContextType>;
  EqmLocCostCenterCombDeleteResponse?: EqmLocCostCenterCombDeleteResponseResolvers<ContextType>;
  EqmLocCostCenterCombManyResult?: EqmLocCostCenterCombManyResultResolvers<ContextType>;
  EqmManufacturer?: EqmManufacturerResolvers<ContextType>;
  EqmManufacturerConnection?: EqmManufacturerConnectionResolvers<ContextType>;
  EqmManufacturerConnectionEdge?: EqmManufacturerConnectionEdgeResolvers<ContextType>;
  EqmManufacturerDeleteResponse?: EqmManufacturerDeleteResponseResolvers<ContextType>;
  EqmManufacturerManyResult?: EqmManufacturerManyResultResolvers<ContextType>;
  EqmManufacturerModel?: EqmManufacturerModelResolvers<ContextType>;
  EqmManufacturerModelConnection?: EqmManufacturerModelConnectionResolvers<ContextType>;
  EqmManufacturerModelConnectionEdge?: EqmManufacturerModelConnectionEdgeResolvers<ContextType>;
  EqmManufacturerModelDeleteResponse?: EqmManufacturerModelDeleteResponseResolvers<ContextType>;
  EqmManufacturerModelManyResult?: EqmManufacturerModelManyResultResolvers<ContextType>;
  EqmObjectCardFlow?: EqmObjectCardFlowResolvers<ContextType>;
  EqmObjectCardFlowConnection?: EqmObjectCardFlowConnectionResolvers<ContextType>;
  EqmObjectCardFlowConnectionEdge?: EqmObjectCardFlowConnectionEdgeResolvers<ContextType>;
  EqmObjectCardFlowDeleteResponse?: EqmObjectCardFlowDeleteResponseResolvers<ContextType>;
  EqmObjectCardFlowManyResult?: EqmObjectCardFlowManyResultResolvers<ContextType>;
  EqmObjectComponents?: EqmObjectComponentsResolvers<ContextType>;
  EqmObjectComponentsConnection?: EqmObjectComponentsConnectionResolvers<ContextType>;
  EqmObjectComponentsConnectionEdge?: EqmObjectComponentsConnectionEdgeResolvers<ContextType>;
  EqmObjectComponentsDeleteResponse?: EqmObjectComponentsDeleteResponseResolvers<ContextType>;
  EqmObjectComponentsManyResult?: EqmObjectComponentsManyResultResolvers<ContextType>;
  EqmObjectCountHeader?: EqmObjectCountHeaderResolvers<ContextType>;
  EqmObjectCountHeaderConnection?: EqmObjectCountHeaderConnectionResolvers<ContextType>;
  EqmObjectCountHeaderConnectionEdge?: EqmObjectCountHeaderConnectionEdgeResolvers<ContextType>;
  EqmObjectCountHeaderDeleteResponse?: EqmObjectCountHeaderDeleteResponseResolvers<ContextType>;
  EqmObjectCountHeaderManyResult?: EqmObjectCountHeaderManyResultResolvers<ContextType>;
  EqmObjectCountLine?: EqmObjectCountLineResolvers<ContextType>;
  EqmObjectCountLineConnection?: EqmObjectCountLineConnectionResolvers<ContextType>;
  EqmObjectCountLineConnectionEdge?: EqmObjectCountLineConnectionEdgeResolvers<ContextType>;
  EqmObjectCountLineDeleteResponse?: EqmObjectCountLineDeleteResponseResolvers<ContextType>;
  EqmObjectCountLineManyResult?: EqmObjectCountLineManyResultResolvers<ContextType>;
  EqmObjectGroupCard?: EqmObjectGroupCardResolvers<ContextType>;
  EqmObjectGroupCardConnection?: EqmObjectGroupCardConnectionResolvers<ContextType>;
  EqmObjectGroupCardConnectionEdge?: EqmObjectGroupCardConnectionEdgeResolvers<ContextType>;
  EqmObjectGroupCardDeleteResponse?: EqmObjectGroupCardDeleteResponseResolvers<ContextType>;
  EqmObjectGroupCardManyResult?: EqmObjectGroupCardManyResultResolvers<ContextType>;
  EqmObjectGroupFlow?: EqmObjectGroupFlowResolvers<ContextType>;
  EqmObjectGroupFlowConnection?: EqmObjectGroupFlowConnectionResolvers<ContextType>;
  EqmObjectGroupFlowConnectionEdge?: EqmObjectGroupFlowConnectionEdgeResolvers<ContextType>;
  EqmObjectGroupFlowDeleteResponse?: EqmObjectGroupFlowDeleteResponseResolvers<ContextType>;
  EqmObjectGroupFlowManyResult?: EqmObjectGroupFlowManyResultResolvers<ContextType>;
  EqmObjectServiceInterval?: EqmObjectServiceIntervalResolvers<ContextType>;
  EqmObjectServiceIntervalConnection?: EqmObjectServiceIntervalConnectionResolvers<ContextType>;
  EqmObjectServiceIntervalConnectionEdge?: EqmObjectServiceIntervalConnectionEdgeResolvers<ContextType>;
  EqmObjectServiceIntervalDeleteResponse?: EqmObjectServiceIntervalDeleteResponseResolvers<ContextType>;
  EqmObjectServiceIntervalManyResult?: EqmObjectServiceIntervalManyResultResolvers<ContextType>;
  EqmObjectSrvIntervalAgg?: EqmObjectSrvIntervalAggResolvers<ContextType>;
  EqmObjectSrvIntervalAggConnection?: EqmObjectSrvIntervalAggConnectionResolvers<ContextType>;
  EqmObjectSrvIntervalAggConnectionEdge?: EqmObjectSrvIntervalAggConnectionEdgeResolvers<ContextType>;
  EqmObjectSrvIntervalAggDeleteResponse?: EqmObjectSrvIntervalAggDeleteResponseResolvers<ContextType>;
  EqmObjectSrvIntervalAggManyResult?: EqmObjectSrvIntervalAggManyResultResolvers<ContextType>;
  EqmObjectStatus?: EqmObjectStatusResolvers<ContextType>;
  EqmObjectStatusConnection?: EqmObjectStatusConnectionResolvers<ContextType>;
  EqmObjectStatusConnectionEdge?: EqmObjectStatusConnectionEdgeResolvers<ContextType>;
  EqmObjectStatusDeleteResponse?: EqmObjectStatusDeleteResponseResolvers<ContextType>;
  EqmObjectStatusManyResult?: EqmObjectStatusManyResultResolvers<ContextType>;
  EqmObjectTypeCard?: EqmObjectTypeCardResolvers<ContextType>;
  EqmObjectTypeCardConnection?: EqmObjectTypeCardConnectionResolvers<ContextType>;
  EqmObjectTypeCardConnectionEdge?: EqmObjectTypeCardConnectionEdgeResolvers<ContextType>;
  EqmObjectTypeCardDeleteResponse?: EqmObjectTypeCardDeleteResponseResolvers<ContextType>;
  EqmObjectTypeCardManyResult?: EqmObjectTypeCardManyResultResolvers<ContextType>;
  EqmObjectTypeComponents?: EqmObjectTypeComponentsResolvers<ContextType>;
  EqmObjectTypeComponentsConnection?: EqmObjectTypeComponentsConnectionResolvers<ContextType>;
  EqmObjectTypeComponentsConnectionEdge?: EqmObjectTypeComponentsConnectionEdgeResolvers<ContextType>;
  EqmObjectTypeComponentsDeleteResponse?: EqmObjectTypeComponentsDeleteResponseResolvers<ContextType>;
  EqmObjectTypeComponentsManyResult?: EqmObjectTypeComponentsManyResultResolvers<ContextType>;
  EqmObjectTypeFlow?: EqmObjectTypeFlowResolvers<ContextType>;
  EqmObjectTypeFlowConnection?: EqmObjectTypeFlowConnectionResolvers<ContextType>;
  EqmObjectTypeFlowConnectionEdge?: EqmObjectTypeFlowConnectionEdgeResolvers<ContextType>;
  EqmObjectTypeFlowDeleteResponse?: EqmObjectTypeFlowDeleteResponseResolvers<ContextType>;
  EqmObjectTypeFlowManyResult?: EqmObjectTypeFlowManyResultResolvers<ContextType>;
  EqmObjectTypePriceTerm?: EqmObjectTypePriceTermResolvers<ContextType>;
  EqmObjectTypePriceTermConnection?: EqmObjectTypePriceTermConnectionResolvers<ContextType>;
  EqmObjectTypePriceTermConnectionEdge?: EqmObjectTypePriceTermConnectionEdgeResolvers<ContextType>;
  EqmObjectTypePriceTermDeleteResponse?: EqmObjectTypePriceTermDeleteResponseResolvers<ContextType>;
  EqmObjectTypePriceTermManyResult?: EqmObjectTypePriceTermManyResultResolvers<ContextType>;
  EqmObjectTypeServiceInterval?: EqmObjectTypeServiceIntervalResolvers<ContextType>;
  EqmObjectTypeServiceIntervalConnection?: EqmObjectTypeServiceIntervalConnectionResolvers<ContextType>;
  EqmObjectTypeServiceIntervalConnectionEdge?: EqmObjectTypeServiceIntervalConnectionEdgeResolvers<ContextType>;
  EqmObjectTypeServiceIntervalDeleteResponse?: EqmObjectTypeServiceIntervalDeleteResponseResolvers<ContextType>;
  EqmObjectTypeServiceIntervalManyResult?: EqmObjectTypeServiceIntervalManyResultResolvers<ContextType>;
  EqmReRentCard?: EqmReRentCardResolvers<ContextType>;
  EqmReRentCardConnection?: EqmReRentCardConnectionResolvers<ContextType>;
  EqmReRentCardConnectionEdge?: EqmReRentCardConnectionEdgeResolvers<ContextType>;
  EqmReRentCardDeleteResponse?: EqmReRentCardDeleteResponseResolvers<ContextType>;
  EqmReRentCardManyResult?: EqmReRentCardManyResultResolvers<ContextType>;
  EqmRentalGroup?: EqmRentalGroupResolvers<ContextType>;
  EqmRentalGroupConnection?: EqmRentalGroupConnectionResolvers<ContextType>;
  EqmRentalGroupConnectionEdge?: EqmRentalGroupConnectionEdgeResolvers<ContextType>;
  EqmRentalGroupDeleteResponse?: EqmRentalGroupDeleteResponseResolvers<ContextType>;
  EqmRentalGroupManyResult?: EqmRentalGroupManyResultResolvers<ContextType>;
  EqmRentalKIT?: EqmRentalKitResolvers<ContextType>;
  EqmRentalKITComponents?: EqmRentalKitComponentsResolvers<ContextType>;
  EqmRentalKITComponentsConnection?: EqmRentalKitComponentsConnectionResolvers<ContextType>;
  EqmRentalKITComponentsConnectionEdge?: EqmRentalKitComponentsConnectionEdgeResolvers<ContextType>;
  EqmRentalKITComponentsDeleteResponse?: EqmRentalKitComponentsDeleteResponseResolvers<ContextType>;
  EqmRentalKITComponentsManyResult?: EqmRentalKitComponentsManyResultResolvers<ContextType>;
  EqmRentalKITConnection?: EqmRentalKitConnectionResolvers<ContextType>;
  EqmRentalKITConnectionEdge?: EqmRentalKitConnectionEdgeResolvers<ContextType>;
  EqmRentalKITDeleteResponse?: EqmRentalKitDeleteResponseResolvers<ContextType>;
  EqmRentalKITLines?: EqmRentalKitLinesResolvers<ContextType>;
  EqmRentalKITLinesConnection?: EqmRentalKitLinesConnectionResolvers<ContextType>;
  EqmRentalKITLinesConnectionEdge?: EqmRentalKitLinesConnectionEdgeResolvers<ContextType>;
  EqmRentalKITLinesDeleteResponse?: EqmRentalKitLinesDeleteResponseResolvers<ContextType>;
  EqmRentalKITLinesManyResult?: EqmRentalKitLinesManyResultResolvers<ContextType>;
  EqmRentalKITManyResult?: EqmRentalKitManyResultResolvers<ContextType>;
  EqmRentalLineDiscount?: EqmRentalLineDiscountResolvers<ContextType>;
  EqmRentalLineDiscountConnection?: EqmRentalLineDiscountConnectionResolvers<ContextType>;
  EqmRentalLineDiscountConnectionEdge?: EqmRentalLineDiscountConnectionEdgeResolvers<ContextType>;
  EqmRentalLineDiscountDeleteResponse?: EqmRentalLineDiscountDeleteResponseResolvers<ContextType>;
  EqmRentalLineDiscountManyResult?: EqmRentalLineDiscountManyResultResolvers<ContextType>;
  EqmRentalPrice?: EqmRentalPriceResolvers<ContextType>;
  EqmRentalPriceConnection?: EqmRentalPriceConnectionResolvers<ContextType>;
  EqmRentalPriceConnectionEdge?: EqmRentalPriceConnectionEdgeResolvers<ContextType>;
  EqmRentalPriceDeleteResponse?: EqmRentalPriceDeleteResponseResolvers<ContextType>;
  EqmRentalPriceManyResult?: EqmRentalPriceManyResultResolvers<ContextType>;
  EqmReturnCharges?: EqmReturnChargesResolvers<ContextType>;
  EqmReturnChargesConnection?: EqmReturnChargesConnectionResolvers<ContextType>;
  EqmReturnChargesConnectionEdge?: EqmReturnChargesConnectionEdgeResolvers<ContextType>;
  EqmReturnChargesDeleteResponse?: EqmReturnChargesDeleteResponseResolvers<ContextType>;
  EqmReturnChargesManyResult?: EqmReturnChargesManyResultResolvers<ContextType>;
  EqmServiceCard?: EqmServiceCardResolvers<ContextType>;
  EqmServiceCardConnection?: EqmServiceCardConnectionResolvers<ContextType>;
  EqmServiceCardConnectionEdge?: EqmServiceCardConnectionEdgeResolvers<ContextType>;
  EqmServiceCardDeleteResponse?: EqmServiceCardDeleteResponseResolvers<ContextType>;
  EqmServiceCardManyResult?: EqmServiceCardManyResultResolvers<ContextType>;
  EqmServiceType?: EqmServiceTypeResolvers<ContextType>;
  EqmServiceTypeConnection?: EqmServiceTypeConnectionResolvers<ContextType>;
  EqmServiceTypeConnectionEdge?: EqmServiceTypeConnectionEdgeResolvers<ContextType>;
  EqmServiceTypeDeleteResponse?: EqmServiceTypeDeleteResponseResolvers<ContextType>;
  EqmServiceTypeManyResult?: EqmServiceTypeManyResultResolvers<ContextType>;
  EqmTransferHeader?: EqmTransferHeaderResolvers<ContextType>;
  EqmTransferHeaderConnection?: EqmTransferHeaderConnectionResolvers<ContextType>;
  EqmTransferHeaderConnectionEdge?: EqmTransferHeaderConnectionEdgeResolvers<ContextType>;
  EqmTransferHeaderDeleteResponse?: EqmTransferHeaderDeleteResponseResolvers<ContextType>;
  EqmTransferHeaderManyResult?: EqmTransferHeaderManyResultResolvers<ContextType>;
  EqmTransferLine?: EqmTransferLineResolvers<ContextType>;
  EqmTransferLineConnection?: EqmTransferLineConnectionResolvers<ContextType>;
  EqmTransferLineConnectionEdge?: EqmTransferLineConnectionEdgeResolvers<ContextType>;
  EqmTransferLineDeleteResponse?: EqmTransferLineDeleteResponseResolvers<ContextType>;
  EqmTransferLineManyResult?: EqmTransferLineManyResultResolvers<ContextType>;
  EqmTypeFunctionalTest?: EqmTypeFunctionalTestResolvers<ContextType>;
  EqmTypeFunctionalTestConnection?: EqmTypeFunctionalTestConnectionResolvers<ContextType>;
  EqmTypeFunctionalTestConnectionEdge?: EqmTypeFunctionalTestConnectionEdgeResolvers<ContextType>;
  EqmTypeFunctionalTestDeleteResponse?: EqmTypeFunctionalTestDeleteResponseResolvers<ContextType>;
  EqmTypeFunctionalTestManyResult?: EqmTypeFunctionalTestManyResultResolvers<ContextType>;
  EqmWorkHeader?: EqmWorkHeaderResolvers<ContextType>;
  EqmWorkHeaderConnection?: EqmWorkHeaderConnectionResolvers<ContextType>;
  EqmWorkHeaderConnectionEdge?: EqmWorkHeaderConnectionEdgeResolvers<ContextType>;
  EqmWorkHeaderDeleteResponse?: EqmWorkHeaderDeleteResponseResolvers<ContextType>;
  EqmWorkHeaderManyResult?: EqmWorkHeaderManyResultResolvers<ContextType>;
  EqmWorkLine?: EqmWorkLineResolvers<ContextType>;
  EqmWorkLineConnection?: EqmWorkLineConnectionResolvers<ContextType>;
  EqmWorkLineConnectionEdge?: EqmWorkLineConnectionEdgeResolvers<ContextType>;
  EqmWorkLineDeleteResponse?: EqmWorkLineDeleteResponseResolvers<ContextType>;
  EqmWorkLineManyResult?: EqmWorkLineManyResultResolvers<ContextType>;
  Errand?: ErrandResolvers<ContextType>;
  ErrandConnection?: ErrandConnectionResolvers<ContextType>;
  ErrandConnectionEdge?: ErrandConnectionEdgeResolvers<ContextType>;
  ErrandDeleteResponse?: ErrandDeleteResponseResolvers<ContextType>;
  ErrandLine?: ErrandLineResolvers<ContextType>;
  ErrandLineConnection?: ErrandLineConnectionResolvers<ContextType>;
  ErrandLineConnectionEdge?: ErrandLineConnectionEdgeResolvers<ContextType>;
  ErrandLineDeleteResponse?: ErrandLineDeleteResponseResolvers<ContextType>;
  ErrandLineManyResult?: ErrandLineManyResultResolvers<ContextType>;
  ErrandManyResult?: ErrandManyResultResolvers<ContextType>;
  ErrorReport?: ErrorReportResolvers<ContextType>;
  ErrorReportConnection?: ErrorReportConnectionResolvers<ContextType>;
  ErrorReportConnectionEdge?: ErrorReportConnectionEdgeResolvers<ContextType>;
  ErrorReportDeleteResponse?: ErrorReportDeleteResponseResolvers<ContextType>;
  ErrorReportManyResult?: ErrorReportManyResultResolvers<ContextType>;
  Feedback?: FeedbackResolvers<ContextType>;
  FeedbackConnection?: FeedbackConnectionResolvers<ContextType>;
  FeedbackConnectionEdge?: FeedbackConnectionEdgeResolvers<ContextType>;
  FeedbackDeleteResponse?: FeedbackDeleteResponseResolvers<ContextType>;
  FeedbackManyResult?: FeedbackManyResultResolvers<ContextType>;
  Feemapping?: FeemappingResolvers<ContextType>;
  FeemappingConnection?: FeemappingConnectionResolvers<ContextType>;
  FeemappingConnectionEdge?: FeemappingConnectionEdgeResolvers<ContextType>;
  FeemappingDeleteResponse?: FeemappingDeleteResponseResolvers<ContextType>;
  FeemappingManyResult?: FeemappingManyResultResolvers<ContextType>;
  FieldOrder?: FieldOrderResolvers<ContextType>;
  FieldOrderConnection?: FieldOrderConnectionResolvers<ContextType>;
  FieldOrderConnectionEdge?: FieldOrderConnectionEdgeResolvers<ContextType>;
  FieldOrderDeleteResponse?: FieldOrderDeleteResponseResolvers<ContextType>;
  FieldOrderLine?: FieldOrderLineResolvers<ContextType>;
  FieldOrderLineConnection?: FieldOrderLineConnectionResolvers<ContextType>;
  FieldOrderLineConnectionEdge?: FieldOrderLineConnectionEdgeResolvers<ContextType>;
  FieldOrderLineDeleteResponse?: FieldOrderLineDeleteResponseResolvers<ContextType>;
  FieldOrderLineManyResult?: FieldOrderLineManyResultResolvers<ContextType>;
  FieldOrderManyResult?: FieldOrderManyResultResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  FileConnection?: FileConnectionResolvers<ContextType>;
  FileConnectionEdge?: FileConnectionEdgeResolvers<ContextType>;
  FileDeleteResponse?: FileDeleteResponseResolvers<ContextType>;
  FileManyResult?: FileManyResultResolvers<ContextType>;
  FunctionTestHeader?: FunctionTestHeaderResolvers<ContextType>;
  FunctionTestHeaderConnection?: FunctionTestHeaderConnectionResolvers<ContextType>;
  FunctionTestHeaderConnectionEdge?: FunctionTestHeaderConnectionEdgeResolvers<ContextType>;
  FunctionTestHeaderDeleteResponse?: FunctionTestHeaderDeleteResponseResolvers<ContextType>;
  FunctionTestHeaderManyResult?: FunctionTestHeaderManyResultResolvers<ContextType>;
  FunctionTestLine?: FunctionTestLineResolvers<ContextType>;
  FunctionTestLineConnection?: FunctionTestLineConnectionResolvers<ContextType>;
  FunctionTestLineConnectionEdge?: FunctionTestLineConnectionEdgeResolvers<ContextType>;
  FunctionTestLineDeleteResponse?: FunctionTestLineDeleteResponseResolvers<ContextType>;
  FunctionTestLineManyResult?: FunctionTestLineManyResultResolvers<ContextType>;
  GenBusinessPostingGroup?: GenBusinessPostingGroupResolvers<ContextType>;
  GenBusinessPostingGroupConnection?: GenBusinessPostingGroupConnectionResolvers<ContextType>;
  GenBusinessPostingGroupConnectionEdge?: GenBusinessPostingGroupConnectionEdgeResolvers<ContextType>;
  GenBusinessPostingGroupDeleteResponse?: GenBusinessPostingGroupDeleteResponseResolvers<ContextType>;
  GenBusinessPostingGroupManyResult?: GenBusinessPostingGroupManyResultResolvers<ContextType>;
  GenJournalLine?: GenJournalLineResolvers<ContextType>;
  GenJournalLineConnection?: GenJournalLineConnectionResolvers<ContextType>;
  GenJournalLineConnectionEdge?: GenJournalLineConnectionEdgeResolvers<ContextType>;
  GenJournalLineDeleteResponse?: GenJournalLineDeleteResponseResolvers<ContextType>;
  GenJournalLineManyResult?: GenJournalLineManyResultResolvers<ContextType>;
  GenProductPostingGroup?: GenProductPostingGroupResolvers<ContextType>;
  GenProductPostingGroupConnection?: GenProductPostingGroupConnectionResolvers<ContextType>;
  GenProductPostingGroupConnectionEdge?: GenProductPostingGroupConnectionEdgeResolvers<ContextType>;
  GenProductPostingGroupDeleteResponse?: GenProductPostingGroupDeleteResponseResolvers<ContextType>;
  GenProductPostingGroupManyResult?: GenProductPostingGroupManyResultResolvers<ContextType>;
  IOSAppCode?: IosAppCodeResolvers<ContextType>;
  Inspection?: InspectionResolvers<ContextType>;
  InspectionConnection?: InspectionConnectionResolvers<ContextType>;
  InspectionConnectionEdge?: InspectionConnectionEdgeResolvers<ContextType>;
  InspectionDeleteResponse?: InspectionDeleteResponseResolvers<ContextType>;
  InspectionManyResult?: InspectionManyResultResolvers<ContextType>;
  InspectionUser?: InspectionUserResolvers<ContextType>;
  InspectionUserConnection?: InspectionUserConnectionResolvers<ContextType>;
  InspectionUserConnectionEdge?: InspectionUserConnectionEdgeResolvers<ContextType>;
  InspectionUserDeleteResponse?: InspectionUserDeleteResponseResolvers<ContextType>;
  InspectionUserManyResult?: InspectionUserManyResultResolvers<ContextType>;
  IntegrationCollection?: IntegrationCollectionResolvers<ContextType>;
  IntegrationCollectionConnection?: IntegrationCollectionConnectionResolvers<ContextType>;
  IntegrationCollectionConnectionEdge?: IntegrationCollectionConnectionEdgeResolvers<ContextType>;
  IntegrationCollectionDeleteResponse?: IntegrationCollectionDeleteResponseResolvers<ContextType>;
  IntegrationCollectionManyResult?: IntegrationCollectionManyResultResolvers<ContextType>;
  InventoryPostingGroup?: InventoryPostingGroupResolvers<ContextType>;
  InventoryPostingGroupConnection?: InventoryPostingGroupConnectionResolvers<ContextType>;
  InventoryPostingGroupConnectionEdge?: InventoryPostingGroupConnectionEdgeResolvers<ContextType>;
  InventoryPostingGroupDeleteResponse?: InventoryPostingGroupDeleteResponseResolvers<ContextType>;
  InventoryPostingGroupManyResult?: InventoryPostingGroupManyResultResolvers<ContextType>;
  Invitation?: InvitationResolvers<ContextType>;
  InvitationConnection?: InvitationConnectionResolvers<ContextType>;
  InvitationConnectionEdge?: InvitationConnectionEdgeResolvers<ContextType>;
  InvitationDeleteResponse?: InvitationDeleteResponseResolvers<ContextType>;
  InvitationLimited?: InvitationLimitedResolvers<ContextType>;
  InvitationManyResult?: InvitationManyResultResolvers<ContextType>;
  InvoiceHeader?: InvoiceHeaderResolvers<ContextType>;
  InvoiceHeaderConnection?: InvoiceHeaderConnectionResolvers<ContextType>;
  InvoiceHeaderConnectionEdge?: InvoiceHeaderConnectionEdgeResolvers<ContextType>;
  InvoiceHeaderDeleteResponse?: InvoiceHeaderDeleteResponseResolvers<ContextType>;
  InvoiceHeaderManyResult?: InvoiceHeaderManyResultResolvers<ContextType>;
  InvoiceLine?: InvoiceLineResolvers<ContextType>;
  InvoiceLineConnection?: InvoiceLineConnectionResolvers<ContextType>;
  InvoiceLineConnectionEdge?: InvoiceLineConnectionEdgeResolvers<ContextType>;
  InvoiceLineDeleteResponse?: InvoiceLineDeleteResponseResolvers<ContextType>;
  InvoiceLineManyResult?: InvoiceLineManyResultResolvers<ContextType>;
  Item?: ItemResolvers<ContextType>;
  ItemCategory?: ItemCategoryResolvers<ContextType>;
  ItemCategoryConnection?: ItemCategoryConnectionResolvers<ContextType>;
  ItemCategoryConnectionEdge?: ItemCategoryConnectionEdgeResolvers<ContextType>;
  ItemCategoryDeleteResponse?: ItemCategoryDeleteResponseResolvers<ContextType>;
  ItemCategoryManyResult?: ItemCategoryManyResultResolvers<ContextType>;
  ItemConnection?: ItemConnectionResolvers<ContextType>;
  ItemConnectionEdge?: ItemConnectionEdgeResolvers<ContextType>;
  ItemDeleteResponse?: ItemDeleteResponseResolvers<ContextType>;
  ItemDiscGroup?: ItemDiscGroupResolvers<ContextType>;
  ItemDiscGroupConnection?: ItemDiscGroupConnectionResolvers<ContextType>;
  ItemDiscGroupConnectionEdge?: ItemDiscGroupConnectionEdgeResolvers<ContextType>;
  ItemDiscGroupDeleteResponse?: ItemDiscGroupDeleteResponseResolvers<ContextType>;
  ItemDiscGroupManyResult?: ItemDiscGroupManyResultResolvers<ContextType>;
  ItemManyResult?: ItemManyResultResolvers<ContextType>;
  ItemReference?: ItemReferenceResolvers<ContextType>;
  ItemReferenceConnection?: ItemReferenceConnectionResolvers<ContextType>;
  ItemReferenceConnectionEdge?: ItemReferenceConnectionEdgeResolvers<ContextType>;
  ItemReferenceDeleteResponse?: ItemReferenceDeleteResponseResolvers<ContextType>;
  ItemReferenceManyResult?: ItemReferenceManyResultResolvers<ContextType>;
  ItemRestriction?: ItemRestrictionResolvers<ContextType>;
  ItemRestrictionConnection?: ItemRestrictionConnectionResolvers<ContextType>;
  ItemRestrictionConnectionEdge?: ItemRestrictionConnectionEdgeResolvers<ContextType>;
  ItemRestrictionDeleteResponse?: ItemRestrictionDeleteResponseResolvers<ContextType>;
  ItemRestrictionManyResult?: ItemRestrictionManyResultResolvers<ContextType>;
  ItemUnitOfMeasure?: ItemUnitOfMeasureResolvers<ContextType>;
  ItemUnitOfMeasureConnection?: ItemUnitOfMeasureConnectionResolvers<ContextType>;
  ItemUnitOfMeasureConnectionEdge?: ItemUnitOfMeasureConnectionEdgeResolvers<ContextType>;
  ItemUnitOfMeasureDeleteResponse?: ItemUnitOfMeasureDeleteResponseResolvers<ContextType>;
  ItemUnitOfMeasureManyResult?: ItemUnitOfMeasureManyResultResolvers<ContextType>;
  ItemVendor?: ItemVendorResolvers<ContextType>;
  ItemVendorConnection?: ItemVendorConnectionResolvers<ContextType>;
  ItemVendorConnectionEdge?: ItemVendorConnectionEdgeResolvers<ContextType>;
  ItemVendorDeleteResponse?: ItemVendorDeleteResponseResolvers<ContextType>;
  ItemVendorManyResult?: ItemVendorManyResultResolvers<ContextType>;
  JobTask?: JobTaskResolvers<ContextType>;
  JobTaskConnection?: JobTaskConnectionResolvers<ContextType>;
  JobTaskConnectionEdge?: JobTaskConnectionEdgeResolvers<ContextType>;
  JobTaskDeleteResponse?: JobTaskDeleteResponseResolvers<ContextType>;
  JobTaskManyResult?: JobTaskManyResultResolvers<ContextType>;
  KliHeader?: KliHeaderResolvers<ContextType>;
  KliHeaderConnection?: KliHeaderConnectionResolvers<ContextType>;
  KliHeaderConnectionEdge?: KliHeaderConnectionEdgeResolvers<ContextType>;
  KliHeaderDeleteResponse?: KliHeaderDeleteResponseResolvers<ContextType>;
  KliHeaderManyResult?: KliHeaderManyResultResolvers<ContextType>;
  KliReasonCode?: KliReasonCodeResolvers<ContextType>;
  KliReasonCodeConnection?: KliReasonCodeConnectionResolvers<ContextType>;
  KliReasonCodeConnectionEdge?: KliReasonCodeConnectionEdgeResolvers<ContextType>;
  KliReasonCodeDeleteResponse?: KliReasonCodeDeleteResponseResolvers<ContextType>;
  KliReasonCodeManyResult?: KliReasonCodeManyResultResolvers<ContextType>;
  Lead?: LeadResolvers<ContextType>;
  LeadConnection?: LeadConnectionResolvers<ContextType>;
  LeadConnectionEdge?: LeadConnectionEdgeResolvers<ContextType>;
  LeadDeleteResponse?: LeadDeleteResponseResolvers<ContextType>;
  LeadManyResult?: LeadManyResultResolvers<ContextType>;
  LimitedCustomerProject?: LimitedCustomerProjectResolvers<ContextType>;
  LimitedUpdateCustomerProjectResponse?: LimitedUpdateCustomerProjectResponseResolvers<ContextType>;
  LineAmount?: LineAmountResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationConnection?: LocationConnectionResolvers<ContextType>;
  LocationConnectionEdge?: LocationConnectionEdgeResolvers<ContextType>;
  LocationDeleteResponse?: LocationDeleteResponseResolvers<ContextType>;
  LocationManyResult?: LocationManyResultResolvers<ContextType>;
  MagentoOrderStatus?: MagentoOrderStatusResolvers<ContextType>;
  MagentoOrderStatusConnection?: MagentoOrderStatusConnectionResolvers<ContextType>;
  MagentoOrderStatusConnectionEdge?: MagentoOrderStatusConnectionEdgeResolvers<ContextType>;
  MagentoOrderStatusDeleteResponse?: MagentoOrderStatusDeleteResponseResolvers<ContextType>;
  MagentoOrderStatusManyResult?: MagentoOrderStatusManyResultResolvers<ContextType>;
  MagentoSettings?: MagentoSettingsResolvers<ContextType>;
  MagentoSettingsConnection?: MagentoSettingsConnectionResolvers<ContextType>;
  MagentoSettingsConnectionEdge?: MagentoSettingsConnectionEdgeResolvers<ContextType>;
  MagentoSettingsDeleteResponse?: MagentoSettingsDeleteResponseResolvers<ContextType>;
  MagentoSettingsManyResult?: MagentoSettingsManyResultResolvers<ContextType>;
  Manufacturer?: ManufacturerResolvers<ContextType>;
  ManufacturerConnection?: ManufacturerConnectionResolvers<ContextType>;
  ManufacturerConnectionEdge?: ManufacturerConnectionEdgeResolvers<ContextType>;
  ManufacturerDeleteResponse?: ManufacturerDeleteResponseResolvers<ContextType>;
  ManufacturerManyResult?: ManufacturerManyResultResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NavUser?: NavUserResolvers<ContextType>;
  NavUserConnection?: NavUserConnectionResolvers<ContextType>;
  NavUserConnectionEdge?: NavUserConnectionEdgeResolvers<ContextType>;
  NavUserDeleteResponse?: NavUserDeleteResponseResolvers<ContextType>;
  NavUserManyResult?: NavUserManyResultResolvers<ContextType>;
  Object?: ObjectResolvers<ContextType>;
  ObjectCommentLine?: ObjectCommentLineResolvers<ContextType>;
  ObjectCommentLineConnection?: ObjectCommentLineConnectionResolvers<ContextType>;
  ObjectCommentLineConnectionEdge?: ObjectCommentLineConnectionEdgeResolvers<ContextType>;
  ObjectCommentLineDeleteResponse?: ObjectCommentLineDeleteResponseResolvers<ContextType>;
  ObjectCommentLineManyResult?: ObjectCommentLineManyResultResolvers<ContextType>;
  ObjectConnection?: ObjectConnectionResolvers<ContextType>;
  ObjectConnectionEdge?: ObjectConnectionEdgeResolvers<ContextType>;
  ObjectDeleteResponse?: ObjectDeleteResponseResolvers<ContextType>;
  ObjectFamily?: ObjectFamilyResolvers<ContextType>;
  ObjectFamilyConnection?: ObjectFamilyConnectionResolvers<ContextType>;
  ObjectFamilyConnectionEdge?: ObjectFamilyConnectionEdgeResolvers<ContextType>;
  ObjectFamilyDeleteResponse?: ObjectFamilyDeleteResponseResolvers<ContextType>;
  ObjectFamilyManyResult?: ObjectFamilyManyResultResolvers<ContextType>;
  ObjectGroup?: ObjectGroupResolvers<ContextType>;
  ObjectGroupConnection?: ObjectGroupConnectionResolvers<ContextType>;
  ObjectGroupConnectionEdge?: ObjectGroupConnectionEdgeResolvers<ContextType>;
  ObjectGroupDeleteResponse?: ObjectGroupDeleteResponseResolvers<ContextType>;
  ObjectGroupManyResult?: ObjectGroupManyResultResolvers<ContextType>;
  ObjectManyResult?: ObjectManyResultResolvers<ContextType>;
  ObjectReturnHeader?: ObjectReturnHeaderResolvers<ContextType>;
  ObjectReturnHeaderConnection?: ObjectReturnHeaderConnectionResolvers<ContextType>;
  ObjectReturnHeaderConnectionEdge?: ObjectReturnHeaderConnectionEdgeResolvers<ContextType>;
  ObjectReturnHeaderDeleteResponse?: ObjectReturnHeaderDeleteResponseResolvers<ContextType>;
  ObjectReturnHeaderManyResult?: ObjectReturnHeaderManyResultResolvers<ContextType>;
  ObjectReturnLine?: ObjectReturnLineResolvers<ContextType>;
  ObjectReturnLineConnection?: ObjectReturnLineConnectionResolvers<ContextType>;
  ObjectReturnLineConnectionEdge?: ObjectReturnLineConnectionEdgeResolvers<ContextType>;
  ObjectReturnLineDeleteResponse?: ObjectReturnLineDeleteResponseResolvers<ContextType>;
  ObjectReturnLineManyResult?: ObjectReturnLineManyResultResolvers<ContextType>;
  ObjectType?: ObjectTypeResolvers<ContextType>;
  ObjectTypeConnection?: ObjectTypeConnectionResolvers<ContextType>;
  ObjectTypeConnectionEdge?: ObjectTypeConnectionEdgeResolvers<ContextType>;
  ObjectTypeDeleteResponse?: ObjectTypeDeleteResponseResolvers<ContextType>;
  ObjectTypeLimited?: ObjectTypeLimitedResolvers<ContextType>;
  ObjectTypeManyResult?: ObjectTypeManyResultResolvers<ContextType>;
  OcrAnalysis?: OcrAnalysisResolvers<ContextType>;
  OcrAnalysisConnection?: OcrAnalysisConnectionResolvers<ContextType>;
  OcrAnalysisConnectionEdge?: OcrAnalysisConnectionEdgeResolvers<ContextType>;
  OcrAnalysisDeleteResponse?: OcrAnalysisDeleteResponseResolvers<ContextType>;
  OcrAnalysisManyResult?: OcrAnalysisManyResultResolvers<ContextType>;
  OldFunctionTestCountResult?: OldFunctionTestCountResultResolvers<ContextType>;
  PageData?: PageDataResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PaymentMethodConnection?: PaymentMethodConnectionResolvers<ContextType>;
  PaymentMethodConnectionEdge?: PaymentMethodConnectionEdgeResolvers<ContextType>;
  PaymentMethodDeleteResponse?: PaymentMethodDeleteResponseResolvers<ContextType>;
  PaymentMethodManyResult?: PaymentMethodManyResultResolvers<ContextType>;
  PaymentTerms?: PaymentTermsResolvers<ContextType>;
  PaymentTermsConnection?: PaymentTermsConnectionResolvers<ContextType>;
  PaymentTermsConnectionEdge?: PaymentTermsConnectionEdgeResolvers<ContextType>;
  PaymentTermsDeleteResponse?: PaymentTermsDeleteResponseResolvers<ContextType>;
  PaymentTermsManyResult?: PaymentTermsManyResultResolvers<ContextType>;
  PlatformFeature?: PlatformFeatureResolvers<ContextType>;
  PlatformFeatureConnection?: PlatformFeatureConnectionResolvers<ContextType>;
  PlatformFeatureConnectionEdge?: PlatformFeatureConnectionEdgeResolvers<ContextType>;
  PlatformFeatureDeleteResponse?: PlatformFeatureDeleteResponseResolvers<ContextType>;
  PlatformFeatureManyResult?: PlatformFeatureManyResultResolvers<ContextType>;
  PostCode?: PostCodeResolvers<ContextType>;
  PostCodeConnection?: PostCodeConnectionResolvers<ContextType>;
  PostCodeConnectionEdge?: PostCodeConnectionEdgeResolvers<ContextType>;
  PostCodeDeleteResponse?: PostCodeDeleteResponseResolvers<ContextType>;
  PostCodeManyResult?: PostCodeManyResultResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectConnection?: ProjectConnectionResolvers<ContextType>;
  ProjectConnectionEdge?: ProjectConnectionEdgeResolvers<ContextType>;
  ProjectDeleteResponse?: ProjectDeleteResponseResolvers<ContextType>;
  ProjectManyResult?: ProjectManyResultResolvers<ContextType>;
  ProjectPushNotifications?: ProjectPushNotificationsResolvers<ContextType>;
  ProxyHeartbeatResponse?: ProxyHeartbeatResponseResolvers<ContextType>;
  PurchCrMemoHeader?: PurchCrMemoHeaderResolvers<ContextType>;
  PurchCrMemoHeaderConnection?: PurchCrMemoHeaderConnectionResolvers<ContextType>;
  PurchCrMemoHeaderConnectionEdge?: PurchCrMemoHeaderConnectionEdgeResolvers<ContextType>;
  PurchCrMemoHeaderDeleteResponse?: PurchCrMemoHeaderDeleteResponseResolvers<ContextType>;
  PurchCrMemoHeaderManyResult?: PurchCrMemoHeaderManyResultResolvers<ContextType>;
  PurchCrMemoLine?: PurchCrMemoLineResolvers<ContextType>;
  PurchCrMemoLineConnection?: PurchCrMemoLineConnectionResolvers<ContextType>;
  PurchCrMemoLineConnectionEdge?: PurchCrMemoLineConnectionEdgeResolvers<ContextType>;
  PurchCrMemoLineDeleteResponse?: PurchCrMemoLineDeleteResponseResolvers<ContextType>;
  PurchCrMemoLineManyResult?: PurchCrMemoLineManyResultResolvers<ContextType>;
  PurchInvHeader?: PurchInvHeaderResolvers<ContextType>;
  PurchInvHeaderConnection?: PurchInvHeaderConnectionResolvers<ContextType>;
  PurchInvHeaderConnectionEdge?: PurchInvHeaderConnectionEdgeResolvers<ContextType>;
  PurchInvHeaderDeleteResponse?: PurchInvHeaderDeleteResponseResolvers<ContextType>;
  PurchInvHeaderManyResult?: PurchInvHeaderManyResultResolvers<ContextType>;
  PurchInvLine?: PurchInvLineResolvers<ContextType>;
  PurchInvLineConnection?: PurchInvLineConnectionResolvers<ContextType>;
  PurchInvLineConnectionEdge?: PurchInvLineConnectionEdgeResolvers<ContextType>;
  PurchInvLineDeleteResponse?: PurchInvLineDeleteResponseResolvers<ContextType>;
  PurchInvLineManyResult?: PurchInvLineManyResultResolvers<ContextType>;
  PurchRcptHeader?: PurchRcptHeaderResolvers<ContextType>;
  PurchRcptHeaderConnection?: PurchRcptHeaderConnectionResolvers<ContextType>;
  PurchRcptHeaderConnectionEdge?: PurchRcptHeaderConnectionEdgeResolvers<ContextType>;
  PurchRcptHeaderDeleteResponse?: PurchRcptHeaderDeleteResponseResolvers<ContextType>;
  PurchRcptHeaderManyResult?: PurchRcptHeaderManyResultResolvers<ContextType>;
  PurchRcptLine?: PurchRcptLineResolvers<ContextType>;
  PurchRcptLineConnection?: PurchRcptLineConnectionResolvers<ContextType>;
  PurchRcptLineConnectionEdge?: PurchRcptLineConnectionEdgeResolvers<ContextType>;
  PurchRcptLineDeleteResponse?: PurchRcptLineDeleteResponseResolvers<ContextType>;
  PurchRcptLineManyResult?: PurchRcptLineManyResultResolvers<ContextType>;
  PurchaseHeader?: PurchaseHeaderResolvers<ContextType>;
  PurchaseHeaderConnection?: PurchaseHeaderConnectionResolvers<ContextType>;
  PurchaseHeaderConnectionEdge?: PurchaseHeaderConnectionEdgeResolvers<ContextType>;
  PurchaseHeaderDeleteResponse?: PurchaseHeaderDeleteResponseResolvers<ContextType>;
  PurchaseHeaderManyResult?: PurchaseHeaderManyResultResolvers<ContextType>;
  PurchaseLine?: PurchaseLineResolvers<ContextType>;
  PurchaseLineConnection?: PurchaseLineConnectionResolvers<ContextType>;
  PurchaseLineConnectionEdge?: PurchaseLineConnectionEdgeResolvers<ContextType>;
  PurchaseLineDeleteResponse?: PurchaseLineDeleteResponseResolvers<ContextType>;
  PurchaseLineManyResult?: PurchaseLineManyResultResolvers<ContextType>;
  Purchasing?: PurchasingResolvers<ContextType>;
  PurchasingConnection?: PurchasingConnectionResolvers<ContextType>;
  PurchasingConnectionEdge?: PurchasingConnectionEdgeResolvers<ContextType>;
  PurchasingDeleteResponse?: PurchasingDeleteResponseResolvers<ContextType>;
  PurchasingManyResult?: PurchasingManyResultResolvers<ContextType>;
  PushNotificationResponse?: PushNotificationResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QuoteHeader?: QuoteHeaderResolvers<ContextType>;
  QuoteHeaderConnection?: QuoteHeaderConnectionResolvers<ContextType>;
  QuoteHeaderConnectionEdge?: QuoteHeaderConnectionEdgeResolvers<ContextType>;
  QuoteHeaderDeleteResponse?: QuoteHeaderDeleteResponseResolvers<ContextType>;
  QuoteHeaderManyResult?: QuoteHeaderManyResultResolvers<ContextType>;
  QuoteLine?: QuoteLineResolvers<ContextType>;
  QuoteLineConnection?: QuoteLineConnectionResolvers<ContextType>;
  QuoteLineConnectionEdge?: QuoteLineConnectionEdgeResolvers<ContextType>;
  QuoteLineDeleteResponse?: QuoteLineDeleteResponseResolvers<ContextType>;
  QuoteLineManyResult?: QuoteLineManyResultResolvers<ContextType>;
  QuoteTaskGroup?: QuoteTaskGroupResolvers<ContextType>;
  QuoteTaskGroupConnection?: QuoteTaskGroupConnectionResolvers<ContextType>;
  QuoteTaskGroupConnectionEdge?: QuoteTaskGroupConnectionEdgeResolvers<ContextType>;
  QuoteTaskGroupDeleteResponse?: QuoteTaskGroupDeleteResponseResolvers<ContextType>;
  QuoteTaskGroupManyResult?: QuoteTaskGroupManyResultResolvers<ContextType>;
  RemoveCodesResponse?: RemoveCodesResponseResolvers<ContextType>;
  RentCost?: RentCostResolvers<ContextType>;
  RentalEvent?: RentalEventResolvers<ContextType>;
  RentalHeader?: RentalHeaderResolvers<ContextType>;
  RentalHeaderConnection?: RentalHeaderConnectionResolvers<ContextType>;
  RentalHeaderConnectionEdge?: RentalHeaderConnectionEdgeResolvers<ContextType>;
  RentalHeaderDeleteResponse?: RentalHeaderDeleteResponseResolvers<ContextType>;
  RentalHeaderManyResult?: RentalHeaderManyResultResolvers<ContextType>;
  RentalLine?: RentalLineResolvers<ContextType>;
  RentalLineConnection?: RentalLineConnectionResolvers<ContextType>;
  RentalLineConnectionEdge?: RentalLineConnectionEdgeResolvers<ContextType>;
  RentalLineDeleteResponse?: RentalLineDeleteResponseResolvers<ContextType>;
  RentalLineManyResult?: RentalLineManyResultResolvers<ContextType>;
  RentalLineSnapshot?: RentalLineSnapshotResolvers<ContextType>;
  RentalLineSnapshotConnection?: RentalLineSnapshotConnectionResolvers<ContextType>;
  RentalLineSnapshotConnectionEdge?: RentalLineSnapshotConnectionEdgeResolvers<ContextType>;
  RentalLineSnapshotDeleteResponse?: RentalLineSnapshotDeleteResponseResolvers<ContextType>;
  RentalLineSnapshotManyResult?: RentalLineSnapshotManyResultResolvers<ContextType>;
  RentalPostedCollHeader?: RentalPostedCollHeaderResolvers<ContextType>;
  RentalPostedCollHeaderConnection?: RentalPostedCollHeaderConnectionResolvers<ContextType>;
  RentalPostedCollHeaderConnectionEdge?: RentalPostedCollHeaderConnectionEdgeResolvers<ContextType>;
  RentalPostedCollHeaderDeleteResponse?: RentalPostedCollHeaderDeleteResponseResolvers<ContextType>;
  RentalPostedCollHeaderManyResult?: RentalPostedCollHeaderManyResultResolvers<ContextType>;
  RentalPostedCollLine?: RentalPostedCollLineResolvers<ContextType>;
  RentalPostedCollLineConnection?: RentalPostedCollLineConnectionResolvers<ContextType>;
  RentalPostedCollLineConnectionEdge?: RentalPostedCollLineConnectionEdgeResolvers<ContextType>;
  RentalPostedCollLineDeleteResponse?: RentalPostedCollLineDeleteResponseResolvers<ContextType>;
  RentalPostedCollLineManyResult?: RentalPostedCollLineManyResultResolvers<ContextType>;
  RentalPostedShptHeader?: RentalPostedShptHeaderResolvers<ContextType>;
  RentalPostedShptHeaderConnection?: RentalPostedShptHeaderConnectionResolvers<ContextType>;
  RentalPostedShptHeaderConnectionEdge?: RentalPostedShptHeaderConnectionEdgeResolvers<ContextType>;
  RentalPostedShptHeaderDeleteResponse?: RentalPostedShptHeaderDeleteResponseResolvers<ContextType>;
  RentalPostedShptHeaderManyResult?: RentalPostedShptHeaderManyResultResolvers<ContextType>;
  RentalPostedShptLine?: RentalPostedShptLineResolvers<ContextType>;
  RentalPostedShptLineConnection?: RentalPostedShptLineConnectionResolvers<ContextType>;
  RentalPostedShptLineConnectionEdge?: RentalPostedShptLineConnectionEdgeResolvers<ContextType>;
  RentalPostedShptLineDeleteResponse?: RentalPostedShptLineDeleteResponseResolvers<ContextType>;
  RentalPostedShptLineManyResult?: RentalPostedShptLineManyResultResolvers<ContextType>;
  RentalPriceTerms?: RentalPriceTermsResolvers<ContextType>;
  RentalPriceTermsConnection?: RentalPriceTermsConnectionResolvers<ContextType>;
  RentalPriceTermsConnectionEdge?: RentalPriceTermsConnectionEdgeResolvers<ContextType>;
  RentalPriceTermsDeleteResponse?: RentalPriceTermsDeleteResponseResolvers<ContextType>;
  RentalPriceTermsManyResult?: RentalPriceTermsManyResultResolvers<ContextType>;
  RentalReturnEntry?: RentalReturnEntryResolvers<ContextType>;
  RentalReturnEntryConnection?: RentalReturnEntryConnectionResolvers<ContextType>;
  RentalReturnEntryConnectionEdge?: RentalReturnEntryConnectionEdgeResolvers<ContextType>;
  RentalReturnEntryDeleteResponse?: RentalReturnEntryDeleteResponseResolvers<ContextType>;
  RentalReturnEntryManyResult?: RentalReturnEntryManyResultResolvers<ContextType>;
  RequestHeader?: RequestHeaderResolvers<ContextType>;
  RequestHeaderConnection?: RequestHeaderConnectionResolvers<ContextType>;
  RequestHeaderConnectionEdge?: RequestHeaderConnectionEdgeResolvers<ContextType>;
  RequestHeaderDeleteResponse?: RequestHeaderDeleteResponseResolvers<ContextType>;
  RequestHeaderManyResult?: RequestHeaderManyResultResolvers<ContextType>;
  RequestLine?: RequestLineResolvers<ContextType>;
  RequestLineConnection?: RequestLineConnectionResolvers<ContextType>;
  RequestLineConnectionEdge?: RequestLineConnectionEdgeResolvers<ContextType>;
  RequestLineDeleteResponse?: RequestLineDeleteResponseResolvers<ContextType>;
  RequestLineManyResult?: RequestLineManyResultResolvers<ContextType>;
  ReservationRequestHeader?: ReservationRequestHeaderResolvers<ContextType>;
  ReservationRequestHeaderConnection?: ReservationRequestHeaderConnectionResolvers<ContextType>;
  ReservationRequestHeaderConnectionEdge?: ReservationRequestHeaderConnectionEdgeResolvers<ContextType>;
  ReservationRequestHeaderDeleteResponse?: ReservationRequestHeaderDeleteResponseResolvers<ContextType>;
  ReservationRequestHeaderManyResult?: ReservationRequestHeaderManyResultResolvers<ContextType>;
  ReservationRequestLine?: ReservationRequestLineResolvers<ContextType>;
  ReservationRequestLineConnection?: ReservationRequestLineConnectionResolvers<ContextType>;
  ReservationRequestLineConnectionEdge?: ReservationRequestLineConnectionEdgeResolvers<ContextType>;
  ReservationRequestLineDeleteResponse?: ReservationRequestLineDeleteResponseResolvers<ContextType>;
  ReservationRequestLineManyResult?: ReservationRequestLineManyResultResolvers<ContextType>;
  Resource?: ResourceResolvers<ContextType>;
  ResourceConnection?: ResourceConnectionResolvers<ContextType>;
  ResourceConnectionEdge?: ResourceConnectionEdgeResolvers<ContextType>;
  ResourceDeleteResponse?: ResourceDeleteResponseResolvers<ContextType>;
  ResourceManyResult?: ResourceManyResultResolvers<ContextType>;
  ResponsibilityCenter?: ResponsibilityCenterResolvers<ContextType>;
  ResponsibilityCenterConnection?: ResponsibilityCenterConnectionResolvers<ContextType>;
  ResponsibilityCenterConnectionEdge?: ResponsibilityCenterConnectionEdgeResolvers<ContextType>;
  ResponsibilityCenterDeleteResponse?: ResponsibilityCenterDeleteResponseResolvers<ContextType>;
  ResponsibilityCenterManyResult?: ResponsibilityCenterManyResultResolvers<ContextType>;
  ResponsibilityCenterMatrix?: ResponsibilityCenterMatrixResolvers<ContextType>;
  RetrySyncResponse?: RetrySyncResponseResolvers<ContextType>;
  ReturnRequestHeader?: ReturnRequestHeaderResolvers<ContextType>;
  ReturnRequestHeaderConnection?: ReturnRequestHeaderConnectionResolvers<ContextType>;
  ReturnRequestHeaderConnectionEdge?: ReturnRequestHeaderConnectionEdgeResolvers<ContextType>;
  ReturnRequestHeaderDeleteResponse?: ReturnRequestHeaderDeleteResponseResolvers<ContextType>;
  ReturnRequestHeaderManyResult?: ReturnRequestHeaderManyResultResolvers<ContextType>;
  ReturnRequestLine?: ReturnRequestLineResolvers<ContextType>;
  ReturnRequestLineConnection?: ReturnRequestLineConnectionResolvers<ContextType>;
  ReturnRequestLineConnectionEdge?: ReturnRequestLineConnectionEdgeResolvers<ContextType>;
  ReturnRequestLineDeleteResponse?: ReturnRequestLineDeleteResponseResolvers<ContextType>;
  ReturnRequestLineManyResult?: ReturnRequestLineManyResultResolvers<ContextType>;
  RiskAnalysisHeader?: RiskAnalysisHeaderResolvers<ContextType>;
  RiskAnalysisHeaderConnection?: RiskAnalysisHeaderConnectionResolvers<ContextType>;
  RiskAnalysisHeaderConnectionEdge?: RiskAnalysisHeaderConnectionEdgeResolvers<ContextType>;
  RiskAnalysisHeaderDeleteResponse?: RiskAnalysisHeaderDeleteResponseResolvers<ContextType>;
  RiskAnalysisHeaderManyResult?: RiskAnalysisHeaderManyResultResolvers<ContextType>;
  RiskAnalysisLine?: RiskAnalysisLineResolvers<ContextType>;
  RiskAnalysisLineConnection?: RiskAnalysisLineConnectionResolvers<ContextType>;
  RiskAnalysisLineConnectionEdge?: RiskAnalysisLineConnectionEdgeResolvers<ContextType>;
  RiskAnalysisLineDeleteResponse?: RiskAnalysisLineDeleteResponseResolvers<ContextType>;
  RiskAnalysisLineManyResult?: RiskAnalysisLineManyResultResolvers<ContextType>;
  RiskAnalysisSignature?: RiskAnalysisSignatureResolvers<ContextType>;
  RiskAnalysisSignatureConnection?: RiskAnalysisSignatureConnectionResolvers<ContextType>;
  RiskAnalysisSignatureConnectionEdge?: RiskAnalysisSignatureConnectionEdgeResolvers<ContextType>;
  RiskAnalysisSignatureDeleteResponse?: RiskAnalysisSignatureDeleteResponseResolvers<ContextType>;
  RiskAnalysisSignatureManyResult?: RiskAnalysisSignatureManyResultResolvers<ContextType>;
  SalesHeader?: SalesHeaderResolvers<ContextType>;
  SalesHeaderArchive?: SalesHeaderArchiveResolvers<ContextType>;
  SalesHeaderArchiveConnection?: SalesHeaderArchiveConnectionResolvers<ContextType>;
  SalesHeaderArchiveConnectionEdge?: SalesHeaderArchiveConnectionEdgeResolvers<ContextType>;
  SalesHeaderArchiveDeleteResponse?: SalesHeaderArchiveDeleteResponseResolvers<ContextType>;
  SalesHeaderArchiveManyResult?: SalesHeaderArchiveManyResultResolvers<ContextType>;
  SalesHeaderConnection?: SalesHeaderConnectionResolvers<ContextType>;
  SalesHeaderConnectionEdge?: SalesHeaderConnectionEdgeResolvers<ContextType>;
  SalesHeaderDeleteResponse?: SalesHeaderDeleteResponseResolvers<ContextType>;
  SalesHeaderHistory?: SalesHeaderHistoryResolvers<ContextType>;
  SalesHeaderHistoryConnection?: SalesHeaderHistoryConnectionResolvers<ContextType>;
  SalesHeaderHistoryConnectionEdge?: SalesHeaderHistoryConnectionEdgeResolvers<ContextType>;
  SalesHeaderHistoryDeleteResponse?: SalesHeaderHistoryDeleteResponseResolvers<ContextType>;
  SalesHeaderHistoryManyResult?: SalesHeaderHistoryManyResultResolvers<ContextType>;
  SalesHeaderManyResult?: SalesHeaderManyResultResolvers<ContextType>;
  SalesHeaderRequest?: SalesHeaderRequestResolvers<ContextType>;
  SalesHeaderRequestConnection?: SalesHeaderRequestConnectionResolvers<ContextType>;
  SalesHeaderRequestConnectionEdge?: SalesHeaderRequestConnectionEdgeResolvers<ContextType>;
  SalesHeaderRequestDeleteResponse?: SalesHeaderRequestDeleteResponseResolvers<ContextType>;
  SalesHeaderRequestManyResult?: SalesHeaderRequestManyResultResolvers<ContextType>;
  SalesLine?: SalesLineResolvers<ContextType>;
  SalesLineArchive?: SalesLineArchiveResolvers<ContextType>;
  SalesLineArchiveConnection?: SalesLineArchiveConnectionResolvers<ContextType>;
  SalesLineArchiveConnectionEdge?: SalesLineArchiveConnectionEdgeResolvers<ContextType>;
  SalesLineArchiveDeleteResponse?: SalesLineArchiveDeleteResponseResolvers<ContextType>;
  SalesLineArchiveManyResult?: SalesLineArchiveManyResultResolvers<ContextType>;
  SalesLineConnection?: SalesLineConnectionResolvers<ContextType>;
  SalesLineConnectionEdge?: SalesLineConnectionEdgeResolvers<ContextType>;
  SalesLineDeleteResponse?: SalesLineDeleteResponseResolvers<ContextType>;
  SalesLineManyResult?: SalesLineManyResultResolvers<ContextType>;
  SalesLineRequest?: SalesLineRequestResolvers<ContextType>;
  SalesLineRequestConnection?: SalesLineRequestConnectionResolvers<ContextType>;
  SalesLineRequestConnectionEdge?: SalesLineRequestConnectionEdgeResolvers<ContextType>;
  SalesLineRequestDeleteResponse?: SalesLineRequestDeleteResponseResolvers<ContextType>;
  SalesLineRequestManyResult?: SalesLineRequestManyResultResolvers<ContextType>;
  SalesOrderChangeTrackingPageData?: SalesOrderChangeTrackingPageDataResolvers<ContextType>;
  SalesOrderChangeTrackingPageResponse?: SalesOrderChangeTrackingPageResponseResolvers<ContextType>;
  SalesOrderResponse?: SalesOrderResponseResolvers<ContextType>;
  SalesPrice?: SalesPriceResolvers<ContextType>;
  SalesPriceConnection?: SalesPriceConnectionResolvers<ContextType>;
  SalesPriceConnectionEdge?: SalesPriceConnectionEdgeResolvers<ContextType>;
  SalesPriceDeleteResponse?: SalesPriceDeleteResponseResolvers<ContextType>;
  SalesPriceManyResult?: SalesPriceManyResultResolvers<ContextType>;
  SalesShipmentHeader?: SalesShipmentHeaderResolvers<ContextType>;
  SalesShipmentHeaderConnection?: SalesShipmentHeaderConnectionResolvers<ContextType>;
  SalesShipmentHeaderConnectionEdge?: SalesShipmentHeaderConnectionEdgeResolvers<ContextType>;
  SalesShipmentHeaderDeleteResponse?: SalesShipmentHeaderDeleteResponseResolvers<ContextType>;
  SalesShipmentHeaderManyResult?: SalesShipmentHeaderManyResultResolvers<ContextType>;
  SalesShipmentLine?: SalesShipmentLineResolvers<ContextType>;
  SalesShipmentLineConnection?: SalesShipmentLineConnectionResolvers<ContextType>;
  SalesShipmentLineConnectionEdge?: SalesShipmentLineConnectionEdgeResolvers<ContextType>;
  SalesShipmentLineDeleteResponse?: SalesShipmentLineDeleteResponseResolvers<ContextType>;
  SalesShipmentLineManyResult?: SalesShipmentLineManyResultResolvers<ContextType>;
  Salesperson?: SalespersonResolvers<ContextType>;
  SalespersonConnection?: SalespersonConnectionResolvers<ContextType>;
  SalespersonConnectionEdge?: SalespersonConnectionEdgeResolvers<ContextType>;
  SalespersonDeleteResponse?: SalespersonDeleteResponseResolvers<ContextType>;
  SalespersonManyResult?: SalespersonManyResultResolvers<ContextType>;
  SendOnRentEmailResponse?: SendOnRentEmailResponseResolvers<ContextType>;
  ServiceItemGroup?: ServiceItemGroupResolvers<ContextType>;
  ServiceItemGroupConnection?: ServiceItemGroupConnectionResolvers<ContextType>;
  ServiceItemGroupConnectionEdge?: ServiceItemGroupConnectionEdgeResolvers<ContextType>;
  ServiceItemGroupDeleteResponse?: ServiceItemGroupDeleteResponseResolvers<ContextType>;
  ServiceItemGroupManyResult?: ServiceItemGroupManyResultResolvers<ContextType>;
  ShipmentMethod?: ShipmentMethodResolvers<ContextType>;
  ShipmentMethodConnection?: ShipmentMethodConnectionResolvers<ContextType>;
  ShipmentMethodConnectionEdge?: ShipmentMethodConnectionEdgeResolvers<ContextType>;
  ShipmentMethodDeleteResponse?: ShipmentMethodDeleteResponseResolvers<ContextType>;
  ShipmentMethodManyResult?: ShipmentMethodManyResultResolvers<ContextType>;
  ShippingAgent?: ShippingAgentResolvers<ContextType>;
  ShippingAgentConnection?: ShippingAgentConnectionResolvers<ContextType>;
  ShippingAgentConnectionEdge?: ShippingAgentConnectionEdgeResolvers<ContextType>;
  ShippingAgentDeleteResponse?: ShippingAgentDeleteResponseResolvers<ContextType>;
  ShippingAgentManyResult?: ShippingAgentManyResultResolvers<ContextType>;
  SignRequest?: SignRequestResolvers<ContextType>;
  SignRequestConnection?: SignRequestConnectionResolvers<ContextType>;
  SignRequestConnectionEdge?: SignRequestConnectionEdgeResolvers<ContextType>;
  SignRequestDeleteResponse?: SignRequestDeleteResponseResolvers<ContextType>;
  SignRequestManyResult?: SignRequestManyResultResolvers<ContextType>;
  Signature?: SignatureResolvers<ContextType>;
  SignatureConnection?: SignatureConnectionResolvers<ContextType>;
  SignatureConnectionEdge?: SignatureConnectionEdgeResolvers<ContextType>;
  SignatureDeleteResponse?: SignatureDeleteResponseResolvers<ContextType>;
  SignatureManyResult?: SignatureManyResultResolvers<ContextType>;
  SpecialEquipment?: SpecialEquipmentResolvers<ContextType>;
  SpecialEquipmentConnection?: SpecialEquipmentConnectionResolvers<ContextType>;
  SpecialEquipmentConnectionEdge?: SpecialEquipmentConnectionEdgeResolvers<ContextType>;
  SpecialEquipmentDeleteResponse?: SpecialEquipmentDeleteResponseResolvers<ContextType>;
  SpecialEquipmentManyResult?: SpecialEquipmentManyResultResolvers<ContextType>;
  StockkeepingUnit?: StockkeepingUnitResolvers<ContextType>;
  StockkeepingUnitConnection?: StockkeepingUnitConnectionResolvers<ContextType>;
  StockkeepingUnitConnectionEdge?: StockkeepingUnitConnectionEdgeResolvers<ContextType>;
  StockkeepingUnitDeleteResponse?: StockkeepingUnitDeleteResponseResolvers<ContextType>;
  StockkeepingUnitManyResult?: StockkeepingUnitManyResultResolvers<ContextType>;
  StoredFile?: StoredFileResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  TariffNumber?: TariffNumberResolvers<ContextType>;
  TariffNumberConnection?: TariffNumberConnectionResolvers<ContextType>;
  TariffNumberConnectionEdge?: TariffNumberConnectionEdgeResolvers<ContextType>;
  TariffNumberDeleteResponse?: TariffNumberDeleteResponseResolvers<ContextType>;
  TariffNumberManyResult?: TariffNumberManyResultResolvers<ContextType>;
  TaxGroup?: TaxGroupResolvers<ContextType>;
  TaxGroupConnection?: TaxGroupConnectionResolvers<ContextType>;
  TaxGroupConnectionEdge?: TaxGroupConnectionEdgeResolvers<ContextType>;
  TaxGroupDeleteResponse?: TaxGroupDeleteResponseResolvers<ContextType>;
  TaxGroupManyResult?: TaxGroupManyResultResolvers<ContextType>;
  TimeRegistration?: TimeRegistrationResolvers<ContextType>;
  TimeRegistrationConnection?: TimeRegistrationConnectionResolvers<ContextType>;
  TimeRegistrationConnectionEdge?: TimeRegistrationConnectionEdgeResolvers<ContextType>;
  TimeRegistrationDeleteResponse?: TimeRegistrationDeleteResponseResolvers<ContextType>;
  TimeRegistrationManyResult?: TimeRegistrationManyResultResolvers<ContextType>;
  TimeSheetDetail?: TimeSheetDetailResolvers<ContextType>;
  TimeSheetDetailConnection?: TimeSheetDetailConnectionResolvers<ContextType>;
  TimeSheetDetailConnectionEdge?: TimeSheetDetailConnectionEdgeResolvers<ContextType>;
  TimeSheetDetailDeleteResponse?: TimeSheetDetailDeleteResponseResolvers<ContextType>;
  TimeSheetDetailManyResult?: TimeSheetDetailManyResultResolvers<ContextType>;
  TimeSheetLine?: TimeSheetLineResolvers<ContextType>;
  TimeSheetLineConnection?: TimeSheetLineConnectionResolvers<ContextType>;
  TimeSheetLineConnectionEdge?: TimeSheetLineConnectionEdgeResolvers<ContextType>;
  TimeSheetLineDeleteResponse?: TimeSheetLineDeleteResponseResolvers<ContextType>;
  TimeSheetLineManyResult?: TimeSheetLineManyResultResolvers<ContextType>;
  TransactionSpecification?: TransactionSpecificationResolvers<ContextType>;
  TransactionSpecificationConnection?: TransactionSpecificationConnectionResolvers<ContextType>;
  TransactionSpecificationConnectionEdge?: TransactionSpecificationConnectionEdgeResolvers<ContextType>;
  TransactionSpecificationDeleteResponse?: TransactionSpecificationDeleteResponseResolvers<ContextType>;
  TransactionSpecificationManyResult?: TransactionSpecificationManyResultResolvers<ContextType>;
  TransactionType?: TransactionTypeResolvers<ContextType>;
  TransactionTypeConnection?: TransactionTypeConnectionResolvers<ContextType>;
  TransactionTypeConnectionEdge?: TransactionTypeConnectionEdgeResolvers<ContextType>;
  TransactionTypeDeleteResponse?: TransactionTypeDeleteResponseResolvers<ContextType>;
  TransactionTypeManyResult?: TransactionTypeManyResultResolvers<ContextType>;
  TransportMethod?: TransportMethodResolvers<ContextType>;
  TransportMethodConnection?: TransportMethodConnectionResolvers<ContextType>;
  TransportMethodConnectionEdge?: TransportMethodConnectionEdgeResolvers<ContextType>;
  TransportMethodDeleteResponse?: TransportMethodDeleteResponseResolvers<ContextType>;
  TransportMethodManyResult?: TransportMethodManyResultResolvers<ContextType>;
  UnitOfMeasure?: UnitOfMeasureResolvers<ContextType>;
  UnitOfMeasureConnection?: UnitOfMeasureConnectionResolvers<ContextType>;
  UnitOfMeasureConnectionEdge?: UnitOfMeasureConnectionEdgeResolvers<ContextType>;
  UnitOfMeasureDeleteResponse?: UnitOfMeasureDeleteResponseResolvers<ContextType>;
  UnitOfMeasureManyResult?: UnitOfMeasureManyResultResolvers<ContextType>;
  UnsignedInt?: GraphQLScalarType;
  UploadAppCodesResponse?: UploadAppCodesResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserConnection?: UserConnectionResolvers<ContextType>;
  UserConnectionEdge?: UserConnectionEdgeResolvers<ContextType>;
  UserDeleteResponse?: UserDeleteResponseResolvers<ContextType>;
  UserFavorites?: UserFavoritesResolvers<ContextType>;
  UserFavoritesConnection?: UserFavoritesConnectionResolvers<ContextType>;
  UserFavoritesConnectionEdge?: UserFavoritesConnectionEdgeResolvers<ContextType>;
  UserFavoritesDeleteResponse?: UserFavoritesDeleteResponseResolvers<ContextType>;
  UserFavoritesManyResult?: UserFavoritesManyResultResolvers<ContextType>;
  UserManyResult?: UserManyResultResolvers<ContextType>;
  UserOperationResponse?: UserOperationResponseResolvers<ContextType>;
  VatBusinessPostingGroup?: VatBusinessPostingGroupResolvers<ContextType>;
  VatBusinessPostingGroupConnection?: VatBusinessPostingGroupConnectionResolvers<ContextType>;
  VatBusinessPostingGroupConnectionEdge?: VatBusinessPostingGroupConnectionEdgeResolvers<ContextType>;
  VatBusinessPostingGroupDeleteResponse?: VatBusinessPostingGroupDeleteResponseResolvers<ContextType>;
  VatBusinessPostingGroupManyResult?: VatBusinessPostingGroupManyResultResolvers<ContextType>;
  VatProductPostingGroup?: VatProductPostingGroupResolvers<ContextType>;
  VatProductPostingGroupConnection?: VatProductPostingGroupConnectionResolvers<ContextType>;
  VatProductPostingGroupConnectionEdge?: VatProductPostingGroupConnectionEdgeResolvers<ContextType>;
  VatProductPostingGroupDeleteResponse?: VatProductPostingGroupDeleteResponseResolvers<ContextType>;
  VatProductPostingGroupManyResult?: VatProductPostingGroupManyResultResolvers<ContextType>;
  Vendor?: VendorResolvers<ContextType>;
  VendorBankAccount?: VendorBankAccountResolvers<ContextType>;
  VendorBankAccountConnection?: VendorBankAccountConnectionResolvers<ContextType>;
  VendorBankAccountConnectionEdge?: VendorBankAccountConnectionEdgeResolvers<ContextType>;
  VendorBankAccountDeleteResponse?: VendorBankAccountDeleteResponseResolvers<ContextType>;
  VendorBankAccountManyResult?: VendorBankAccountManyResultResolvers<ContextType>;
  VendorConnection?: VendorConnectionResolvers<ContextType>;
  VendorConnectionEdge?: VendorConnectionEdgeResolvers<ContextType>;
  VendorDeleteResponse?: VendorDeleteResponseResolvers<ContextType>;
  VendorManyResult?: VendorManyResultResolvers<ContextType>;
  VendorPostingGroup?: VendorPostingGroupResolvers<ContextType>;
  VendorPostingGroupConnection?: VendorPostingGroupConnectionResolvers<ContextType>;
  VendorPostingGroupConnectionEdge?: VendorPostingGroupConnectionEdgeResolvers<ContextType>;
  VendorPostingGroupDeleteResponse?: VendorPostingGroupDeleteResponseResolvers<ContextType>;
  VendorPostingGroupManyResult?: VendorPostingGroupManyResultResolvers<ContextType>;
  WarehouseClass?: WarehouseClassResolvers<ContextType>;
  WarehouseClassConnection?: WarehouseClassConnectionResolvers<ContextType>;
  WarehouseClassConnectionEdge?: WarehouseClassConnectionEdgeResolvers<ContextType>;
  WarehouseClassDeleteResponse?: WarehouseClassDeleteResponseResolvers<ContextType>;
  WarehouseClassManyResult?: WarehouseClassManyResultResolvers<ContextType>;
  WebBooking?: WebBookingResolvers<ContextType>;
  WebBookingConnection?: WebBookingConnectionResolvers<ContextType>;
  WebBookingConnectionEdge?: WebBookingConnectionEdgeResolvers<ContextType>;
  WebBookingDeleteResponse?: WebBookingDeleteResponseResolvers<ContextType>;
  WebBookingManyResult?: WebBookingManyResultResolvers<ContextType>;
  WebhookSubscription?: WebhookSubscriptionResolvers<ContextType>;
  WebhookSubscriptionConnection?: WebhookSubscriptionConnectionResolvers<ContextType>;
  WebhookSubscriptionConnectionEdge?: WebhookSubscriptionConnectionEdgeResolvers<ContextType>;
  WebhookSubscriptionDeleteResponse?: WebhookSubscriptionDeleteResponseResolvers<ContextType>;
  WebhookSubscriptionManyResult?: WebhookSubscriptionManyResultResolvers<ContextType>;
  WorkLog?: WorkLogResolvers<ContextType>;
  WorkLogConnection?: WorkLogConnectionResolvers<ContextType>;
  WorkLogConnectionEdge?: WorkLogConnectionEdgeResolvers<ContextType>;
  WorkLogDeleteResponse?: WorkLogDeleteResponseResolvers<ContextType>;
  WorkLogManyResult?: WorkLogManyResultResolvers<ContextType>;
  WorkType?: WorkTypeResolvers<ContextType>;
  WorkTypeConnection?: WorkTypeConnectionResolvers<ContextType>;
  WorkTypeConnectionEdge?: WorkTypeConnectionEdgeResolvers<ContextType>;
  WorkTypeDeleteResponse?: WorkTypeDeleteResponseResolvers<ContextType>;
  WorkTypeManyResult?: WorkTypeManyResultResolvers<ContextType>;
  createCompanyInvitationResponse?: CreateCompanyInvitationResponseResolvers<ContextType>;
  syncUsersResponse?: SyncUsersResponseResolvers<ContextType>;
  toggleSuplierResponse?: ToggleSuplierResponseResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
