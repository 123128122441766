import moment from 'moment'
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';

export const setupLocales = () => {

    registerLocale('sv', sv)
    setDefaultLocale('sv')

    const months = [
        "Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli",
        "Augusti", "September", "Oktober", "November", "December"
    ]

    const monthsShort = [
        "Jan", "Feb", "Mars", "Apr", "Maj", "Jun", "Jul",
        "Aug", "Sep", "Okt", "Nov", "Dec"
    ]
    const weekdays = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag',]
    const weekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör',]


    moment.locale('sv')
    moment.updateLocale('sv', {
        months,
        weekdays,
        week: {
            dow: 1,
            doy: 4
        }
    });
}