import { useMutation } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Mutations } from '../../queries/mutation'
import { DomainIntegrationSchemaVersionEnum, DomainIntegration, RemoveDomainIntegrationMutationVariables, RemoveDomainIntegrationMutation, UpsertDomainIntegrationMutationVariables, UpsertDomainIntegrationMutation } from '../../types'
import Form from '../Form'
import { FormField } from '../Form/types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'


export enum IntegrationTypes {
    nav = 'nav',
    crm = 'crm',
    webhook = 'webhook',
    bc = 'bc',
    magento = 'magento',
    webcrm = 'webcrm',
    dekra = 'dekra',
    carepapim = 'carepapim',
    carepaweb = 'carepaweb',
    qbsapi = 'qbsapi',
    shopify = 'shopify',
    coredination = 'coredination',
    equipmentloop = 'equipmentloop',
    directBC = 'directBC',
    directNAV = 'directNAV'
}

export const DomainIntegrationForm = ({ domainIntegration, domainId }: { domainId: string, domainIntegration?: DomainIntegration }) => {
    const [removeMutation, { data, loading }] = useMutation<RemoveDomainIntegrationMutation, RemoveDomainIntegrationMutationVariables>(Mutations.removeDomainIntegration, { 
        refetchQueries: ['domainIntegrationsMany'],
        variables: { filter: { GlobalId: domainId } },
        context: { xDomainId: domainId },
    })
    
    if (!domainId) return <Error error='the domain Id cannot be null' />

    const remove = async () => {
        if (!domainIntegration.GlobalId) {
            alert("inget id hittades")
            return
        }
        try {
            await removeMutation({ 
                variables: 
                    { 
                        filter: { DomainId: domainId, GlobalId: domainIntegration.GlobalId } 
                    },
                context: { xDomainId: domainId },
            })
        } catch (e) { console.error(e) }
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                {/* <TryConnectionButton domainIntegration={domainIntegration} /> */}
                {(domainIntegration && domainIntegration.GlobalId) && <Button style={{ marginLeft: '20px' }} disabled={loading} variant="danger" onClick={remove}>{loading ? <Loading /> : 'Ta bort anslutning'}</Button>}
            </div>
            <DCForm DomainId={domainId} domainIntegration={domainIntegration} />

        </div>
    )
}

const DCForm = ({ domainIntegration, DomainId }: { DomainId: string, domainIntegration: DomainIntegration }) => {
    const [mutation, { loading, error }] = useMutation<UpsertDomainIntegrationMutation, UpsertDomainIntegrationMutationVariables>(Mutations.upsertDomainIntegration, { 
        refetchQueries: ['domainIntegrationsMany'],
        context: { xDomainId: DomainId },
    })

    const save = async (values: DomainIntegration) => {
        console.log('values', values)

        try {
            await mutation({
                variables: {
                    record: {
                        GlobalId: domainIntegration ? domainIntegration.GlobalId : '',
                        DomainId,
                        Type: values.Type,
                        Url: values.Url,
                        Username: values.Username,
                        Password: values.Password,
                        Active: (values.Active as any) === 'true' ? true : false,
                        OrganizationId: values.OrganizationId,
                        grant_type: values.grant_type,
                        client_id: values.client_id,
                        client_secret: values.client_secret,
                        commonAuthority: values.commonAuthority,
                        DisableOrderPolling: (values.DisableOrderPolling as any) === 'true' ? true : false,
                        resource: values.resource,
                        PreviousDekraPollingDateTime: values.PreviousDekraPollingDateTime,
                        SchemaVersion: values.SchemaVersion || null,
                        HeartBeartIntervalMinutes: values.HeartBeartIntervalMinutes ? parseInt(values.HeartBeartIntervalMinutes + "") : null,
                        HeartbeatMonitoringEnabled: (values.HeartbeatMonitoringEnabled as any) === 'true' ? true : false,
                    }
                }
            })
        } catch (e) { }
    }

    return (
        <div style={{
            padding: '20px',
        }}>
            {error && <Error type={'mutation'} error={error} />}
            <Form
                values={domainIntegration}
                loading={loading}
                fields={[
                    //default
                    {
                        name: 'Type', type: 'staticPicker', label: "Typ", values: [
                            { label: 'Business Central', value: IntegrationTypes.bc },
                            { label: 'QBS Api', value: IntegrationTypes.qbsapi },
                            { label: 'Dynamics NAV', value: IntegrationTypes.nav },
                            { label: 'Dynamics 365 Sales', value: IntegrationTypes.crm },
                            { label: 'Magento', value: IntegrationTypes.magento },
                            { label: 'Webhook', value: IntegrationTypes.webhook },
                            { label: 'WebCRM', value: IntegrationTypes.webcrm },
                            { label: 'Dekra', value: IntegrationTypes.dekra },
                            { label: 'Carepa EPI webb', value: IntegrationTypes.carepaweb },
                            { label: 'Carepa inRiver PIM', value: IntegrationTypes.carepapim },
                            { label: 'Shopify', value: IntegrationTypes.shopify},
                            { label: 'Coredination', value: IntegrationTypes.coredination},
                            { label: 'EquipmentLoop', value: IntegrationTypes.equipmentloop},
                            { label: 'Direct BC', value: IntegrationTypes.directBC},
                            { label: 'Direct NAV', value: IntegrationTypes.directNAV},
                        ],

                    },
                    { name: 'SubType', label: 'Subtyp', placeholder: 'frivilligt, kan bero på valda typen' },
                    { name: 'Active', type: 'staticPicker', label: "Anslutning aktiv", values: [{ label: 'Aktiv', value: 'true' }, { label: 'Inaktiv', value: 'false' }], },
                    { name: 'Url', label: 'URL', placeholder: 'url till soapservice/odataendpoint' },
                    { name: 'Username', label: "Nytt anvädnarnamn" },
                    { name: 'Password', label: "Nytt lösenord" },
                    {
                        name: 'SchemaVersion', label: 'Schemaversion', type: 'staticPicker', values: [
                            { label: "Basic", value: DomainIntegrationSchemaVersionEnum.Basic },
                            { label: "Meta", value: DomainIntegrationSchemaVersionEnum.Meta },
                            { label: "Array", value: DomainIntegrationSchemaVersionEnum.Array },
                        ]
                    },
                    { name: 'HeartbeatMonitoringEnabled', type: 'staticPicker', label: "Heartbeatövervakning aktiv", values: [{ label: 'Aktiv', value: 'true' }, { label: 'Inaktiv', value: 'false' }], },
                    { name: 'HeartBeartIntervalMinutes', type: 'int', label: "Antal minuter i heartbeat-intervall" },

                    //magento
                    { name: 'DisableOrderPolling', type: 'staticPicker', label: "Order polling inaktiverad", hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.magento, values: [{ label: 'Inaktiverad', value: 'true' }, { label: 'Aktiverad', value: 'false' }] },
                    { name: 'PreviousMagentoOrderId', label: "Senast aktivt magentoid", hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.magento },

                    //dekra
                    { name: 'PreviousDekraPollingDateTime', label: "Senast aktiva pollingtimestamp", hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.dekra },

                    //crm
                    { name: 'OrganizationId', label: "CRM Organization ID", hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.crm },
                    { name: 'grant_type', label: 'grant_type', hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.crm },
                    { name: 'client_id', label: 'nytt client_id', hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.crm },
                    { name: 'client_secret', label: 'nytt client_secret', hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.crm },
                    { name: 'commonAuthority', label: 'commonAuthority', hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.crm },
                    { name: 'resource', label: 'resource', hidden: (state: DomainIntegration) => state.Type !== IntegrationTypes.crm },



                ]}
                onSubmit={save}
            />


        </div>
    )
}
