import React, { useState } from 'react'
import { Queries } from '../../queries/query'
import { FilterCompanyInput, InvitationTypeEnum } from '../../types'
import Form from '../Form'
import Collapsible from 'react-collapsible';
import { ReadableRoles, roledefinitions } from '../../config';
import { useMutation } from '@apollo/client';
import { Mutations } from '../../queries/mutation';
import Error from '../generic/Error';

const CreateInvitationForm = ({ domainId, onRequestClose }: { domainId: string, onRequestClose?: () => void }) => {
    const [mutation, { error, loading }] = useMutation(Mutations.createCompanyInvitation, { 
        refetchQueries: ['invitationsPage'],
        context: { xDomainId: domainId },
    })

    const save = async (record) => {
        await mutation({ 
            variables: { 
                record, 
                filter:  {DomainId: domainId} 
            },
            context: { xDomainId: record.DomainId },
        }).catch(e => {
            console.log('error in create inv', e)
        })
    }

    return (
        <div className="shadow rounded-container">
            {error && <Error error={error} type="mutation" />}
            <Form
                loading={loading}
                onRequestClose={onRequestClose}
                fields={[
                    {
                        name: 'Role', label: "Roll", values: [
                            { label: ReadableRoles.customeruser, value: roledefinitions.customeruser },
                            { label: ReadableRoles.customeradmin, value: roledefinitions.customeradmin },
                        ],
                        type: 'staticPicker',
                        defaultValue: roledefinitions.customeruser
                    },
                    {
                        label: 'Kund', required: true, name: "RegistrationNo", type: 'dynamicPicker', query: Queries.companiesMany, labelprop: "Name", valueprop: 'RegistrationNo',
                        filter: () => ({
                            NOT: { RegistrationNo: "" }
                        } as FilterCompanyInput),
                    },
                    {
                        label: 'Leverantör', required: true, name: "SupplierId", type: 'dynamicPicker', query: Queries.domainsMany, labelprop: "Name", valueprop: 'GlobalId',
                    },
                    {
                        label: 'Projekt', defaultValue: [], required: false, name: "ProjectNos", type: 'multiValue', query: Queries.customerProjectsMany, labelprop: "ProjectNo", valueprop: 'ProjectNo',
                        filter: (state) => {
                            if (!state || !state.SupplierId) return { ProjectNo: '-1' }
                            return { DomainId: state.SupplierId }
                        },
                    },
                    {
                        label: 'typ', name: 'Type', type: 'staticPicker',
                        defaultValue: InvitationTypeEnum.Personal,
                        values: [
                            { label: 'Personlig', value: InvitationTypeEnum.Personal },
                            { label: 'För hela bolaget', value: InvitationTypeEnum.Company },
                        ]
                    }
                ]}
                onSubmit={save}
            />
        </div>
    )
}

export const CreateNewInvitationButton = ({domainId}: {domainId: string}) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <div style={{ width: '100%' }}>
            <div className="full-width-controls">
                <div className="rounded-button-wrapper" onClick={() => setOpen(old => !old)}>
                    <div className="rounded-button blue">
                        Ny inbjudan
                    </div>
                </div>
            </div>
            <Collapsible open={open} trigger={<div></div>}>
                <CreateInvitationForm domainId={domainId} onRequestClose={() => setOpen(false)} />
            </Collapsible>
        </div>
    )
}

export default CreateInvitationForm