import { useQuery } from "@apollo/client"
import { getAuthLevel, roledefinitions } from "../../config"
import { Queries } from '../../queries/query'
import { MeQuery, MeQueryVariables, User } from "../../types"

export const usePermission = (permission: string) => {
    const { me, loading } = useMe()
    if (loading) return { loading: true, permission: false }

    return { loading: false, permission: getAuthLevel(me.role) >= getAuthLevel(permission) }
}

export const useIsUserOfRole = (role: string) => {
    const { me, loading } = useMe()
    if (loading) return { loading: true, permission: false }

    return { loading: false, permission: me.role === role }
}

export type mePayload = {
    me?: User,
    isRental?: boolean,
    isConstruction?: boolean,
    isSales?: boolean
    loading?: boolean,
    error?: any
}

export const useMe = (domainId?: string): mePayload => {
    const { data, loading, error } = useQuery<MeQuery, MeQueryVariables>(Queries.me, { 
        context: { xDomainId: domainId },
    });
    if (loading) return { loading: true }
    if (error) {
        console.log(error)
        return { error }
    }
    const { me } = data

    if (!me) return {
        error: 'Okänt fel har uppstått'
    }
    // me.role === roles.customeradmin.value || me.role === roles.customeruser.value
    const isConstruction = me.role === roledefinitions.customeradmin || me.role === roledefinitions.customeruser
    const isRental = me.role === roledefinitions.domainadmin || me.role === roledefinitions.domainuser
    const isSales = (me.role === roledefinitions.domainadmin || me.role === roledefinitions.domainuser) && me.domain && me.domain.DomainMainOrientation === 'sales'

    return {
        me, loading, error, isRental,
        isConstruction,
        isSales
    }
}