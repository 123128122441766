import React from 'react'
import { Container } from 'react-bootstrap'
import { ReadableRoles } from '../../config'
import { Queries } from '../../queries/query'
import { Document } from '../../types'
import Link from '../generic/Link'
import SearchableTable from '../table/SearchableTable'

const DocumentList = () => {
    return (
        <Container>
            <SearchableTable
                domainId=''
                query={Queries.documentsPage}
                cols={[
                    {
                        Header: 'Namn',
                        Cell: ({ row: { original } }: { row: { original: Document } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.Name}</Link>
                        },
                    },
                    {
                        Header: "Typ",
                        accessor: (row: Document) => {
                            switch (row.DocumentTypeOPTION) {
                                case 'image': return 'Bild'
                                case 'document': return 'Dokument'
                                default: return 'okänd typ'

                            }
                        },
                        id: "DocumentTypeOPTION"
                    },
                ]}
            />
        </Container>
    )
}

export default DocumentList