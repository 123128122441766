import React from 'react'
import { Container } from 'react-bootstrap'
// import PageWrapper from '../generic/PageWrapper'
import ControlPanel from './ContolPanel'
import './index.scss'

const MyProfile = () => {
    return (
        <Container>
            <ControlPanel />
        </Container>

    )
}

export default MyProfile
