import React, { useState } from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import { useParams } from 'react-router'
import shortid from 'shortid'
import './index.scss'
import { colors } from '../generic/colors'
import Link from '../generic/Link'
import Error from '../generic/Error'
import { ReadableRoles } from '../../config'
import { Queries } from '../../queries/query'
import { User } from '../../types'
import SearchableTable from '../table/SearchableTable'
import { UserForm } from './userForm'

const UserList = () => {
	const { domainId } = useParams<{ domainId: string }>()

	const [modalVisible, setModalVisible] = useState(false)
	const handleClose = () => setModalVisible(false)

	if (!domainId) return <Error error='the domain Id cannot be null' />
	
	return (
		<Container>
			<h1>Konto</h1>
			<h3>Domän-Id: {domainId}</h3>
			<SearchableTable
				domainId={ domainId }
				disableSearch
				query={ Queries.usersMany }
				filter={{
					DomainId: domainId
				}}
				cols={[
					{
                        Header: 'Namn',
                        accessor: 'name',
                        Cell: ({ row: { original } }: { row: { original: User } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.name}</Link>
                        },
                    },
                    {
                        Header: 'E-Post',
                        accessor: 'email',
						Cell: ({ row: { original } }: { row: { original: User } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`} ellipsis={true}>{original.email}</Link>
                        },
                    },
                    {
                        Header: 'Roll',
                        accessor: 'role',
                        Cell: ({ row: { original } }: { row: { original: User } }) => {
                            let role = ReadableRoles[original.role]
                            return <span>{role || ''}</span>
                        },
                    },
					{
                        Header: 'Aktiv',
                        accessor: 'active',
                        Cell: ({ row: { original } }: { row: { original: User } }) => {
                            return <span>{original.active ? 'Ja' : 'Nej'}</span>
                        },
                    },
				]}
			>
				<Button key={shortid} variant="primary" onClick={() => setModalVisible(true)}>
					Lägg till domänkonto
				</Button>
			</SearchableTable>


			<Modal animation={false} show={modalVisible} onHide={handleClose} dialogClassName="wide-modal">
				<Modal.Header closeButton>
					<Modal.Title style={{ color: colors.secondary.main.color }}>Lägg till domänkonto</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<UserForm domainId={ domainId }/>
				</Modal.Body>
			</Modal>
		</Container>
	)
}



export default UserList