import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CustomerProject, SendOnRentListByEmailMutation, SendOnRentListByEmailMutationVariables } from '../../types'
import { colors } from '../generic/colors'
import { SiMicrosoftexcel } from "react-icons/si";
import { useMutation } from '@apollo/client';
import { Mutations } from '../../queries/mutation';
import { validate } from 'email-validator';
import { useMe } from '../hooks/permissionHook';
import * as en from 'linq'
import TagInput from '../generic/TagInput';

const EmailOnRent = ({ domainId, customerProject, customerProjects }: { domainId: string, customerProject?: CustomerProject, customerProjects?: CustomerProject[] }) => {
    const [mutation, { loading }] = useMutation<SendOnRentListByEmailMutation, SendOnRentListByEmailMutationVariables>(Mutations.sendOnRentListByEmail, {
        context: { xDomainId: domainId },
    })
    const [includeOwnEmail, setIncludeOwnEmail] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [open, setOpen] = useState<boolean>(false)
    const [emails, setEmails] = useState<string[]>([])
    const { me } = useMe()
    const [projects, setProjects] = useState<CustomerProject[]>(customerProjects || [customerProject])

    const addEmail = email => {
        if (validate(email)) {
            setEmails(old => [...old, email])
            setError(null)
        } else
            setError('Ingen giltig E-postadress')
    }

    const removeEmail = t => {
        setEmails(old => en.from(old).where(x => x !== t).toArray())
    }

    const send = async () => {

        if (en.from(projects).any(project => !project.ProjectId)) {
            setError('Ett valt projekt är inte rätt konfigurerat och saknar ID. Vänligen kontakta din hyresleverantör')
            return
        }
        try {
            let response = await mutation({
                variables: {
                    record: {
                        ProjectIds: projects.map(x => x.ProjectId),
                        Emails: emails.map(e => ({ Email: e, Name: 'user' }))
                    }
                }
            })

            if (!response) {
                setError('Ett fel uppstod')
            }
            else if (response.data.sendOnRentListByEmail.error) {
                setError('Ett fel uppstod ' + response.data.sendOnRentListByEmail.error)
            } else {
                requestClose()
            }
        } catch (e) {
            console.log('error', e)
        }

    }

    const toggleIncludeOwnEmail = (val: boolean) => {
        if (!val) setEmails(old => en.from(old).where(x => x !== me.email).toArray())
        else {
            if (emails.map(x => x.toLowerCase()).indexOf(me.email) < 0)
                setEmails(old => [...old, me.email])
        }
        setIncludeOwnEmail(val)
    }

    const validateEmail = (tag: string) => {
        if (validate(tag)) return { valid: true }
        else return { valid: false, error: 'Ingen giltig epost' }
    }

    useEffect(() => {
        if (me && me.email && emails.indexOf(me.email) < 0)
            addEmail(me.email)
    }, [me])

    const requestClose = () => setOpen(false)
    return (
        <div>
            <button className="rounded-button blue" onClick={() => setOpen(!open)}><span style={{ marginRight: '4px' }}>Maila som excelfil</span><SiMicrosoftexcel size={20} color={'white'} /></button>

            <Modal animation={false} show={open} onHide={requestClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: colors.primary.main.color }}>Maila hyreslista som excelfil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && (<div>{error}</div>)}

                    <p>Skicka till adresser:</p>
                    <TagInput
                        validate={validateEmail}
                        onAddTag={addEmail}
                        onRemoveTag={removeEmail}
                        tags={emails}

                    />


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={requestClose}>
                        Stäng
                    </Button>

                    <Button disabled={loading || emails.length < 1} variant="success" onClick={send}>
                        Skicka
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default EmailOnRent