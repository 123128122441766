import React, { useRef, useState } from 'react'
import './index.scss'
import { GrAdd } from "react-icons/gr";

const TagInput = ({
    tags,
    onAddTag,
    onRemoveTag,
    disableRefocusOnInput,
    validate,
}: {
    validate?: (tag: string) => { valid: boolean, error?: string },
    icon?: JSX.Element,
    placeholder?: string,
    subtitle?: string,
    tags: string[],
    onAddTag: (tag: string) => void,
    onRemoveTag: (tag: string) => void,
    disableRefocusOnInput?: boolean
}) => {
    const textfield = useRef<HTMLInputElement>(null)
    const [input, setInput] = useState<string>('')
    const [error, setError] = useState<string>('')

    const add = () => {
        let tag = input.trim()
        if (!tag) return
        if (validate) {
            let res = validate(tag)
            if (!res.valid) {
                setError(res.error)
                return
            }
        }

        onAddTag(tag)
        setInput('')
        setError('')
        if (!disableRefocusOnInput)
            textfield.current.focus()
    }

    return (
        <div>
            {error && <span className="error">{error}</span>}
            <div className="tag-input-wrapper">
                <input value={input} ref={textfield} type="text" onChange={e => setInput(e.target.value)} onKeyDown={e => { if (e.code === 'Enter') add() }} />
                <button onClick={add}><GrAdd color={'#ffffff'} /></button>
            </div>
            <div className="tags">
                {tags.map((t, i) => <span onClick={() => onRemoveTag(t)} className="tag" key={i}>{t}</span>)}
            </div>
        </div>
    )
}

export default TagInput