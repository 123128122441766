import React from "react"
import { Container } from "react-bootstrap"
import { useParams } from 'react-router'
import Error from '../generic/Error'
import ProjectList from "./ProjectList"
import MyProjects from "./MyProjects"
import ProjectCard from "./ProjectCard"

const OnRent = () => {
    const { domainId } = useParams<{ domainId: string }>()
    if (!domainId) return <Error error='the domain Id cannot be null' />

    return (
        <Container>
            <MyProjects domainId={domainId} />
            <ProjectList domainId={domainId}/>
            {/* <ProjectCard/> */}
        </Container>
    )
}

export default OnRent