import React, { useState, useContext, FunctionComponent } from 'react'
import { Container, Button, Modal, Row } from 'react-bootstrap'
import moment from 'moment'
import { AiOutlineQrcode } from "react-icons/ai";
import { useQuery } from '@apollo/client'
import shortid from 'shortid'
import Loading from './Loading'
import './index.scss'
import { asMoney } from '../../utils'
import { colors } from './colors'
import AuthContext from '../../auth/authContext'

import { Queries } from '../../queries/query'
import Error from './Error'
import * as QRCode from 'qrcode.react'
import { cfg } from '../../config'

export type Section = {
    title?: string,
    data: row[]
}

type row = {
    value: string | JSX.Element,
    title?: string,
    type?: 'text' | 'phone' | 'email' | 'link' | 'date' | 'money' | 'yes/no'
}

const DetailsComponent = ({
    DomainId,
    Headline,
    subtitle,
    sections,
    children,
    renderQRcode,
    controls,
    hideTitleOnSmallScreen,
    hideDomainImageOnSmallScreen,
}: {
    subtitle?: JSX.Element,
    DomainId?: string
    Headline?: string,
    sections?: Section[],
    controls?: [JSX.Element],
    children?: JSX.Element,
    renderQRcode?: boolean,
    hideTitleOnSmallScreen?: boolean,
    hideDomainImageOnSmallScreen?: boolean
}) => {
    return (
        <div className="shadow rounded-container details-component-wrapper">
            <div className="header">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h1 style={{ padding: '20px' }}>{Headline}</h1>
                    {children}
                </div>
                {(DomainId) && <RenderDomainImage DomainId={DomainId} />}
            </div>

            {subtitle && subtitle}
            <div style={{ backgroundColor: colors.background }}>
                <div style={{ padding: '20xp' }}>
                    {sections && sections.map((section, i) => (
                        <div className="details-wrapper" key={shortid()}>
                            <div
                                className="title"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <h4>{section.title || 'Information'}</h4>
                                <div className="controls">
                                    {(i === 0 && renderQRcode) && <RenderQrModal />}
                                    {(i === 0 && controls) && controls}
                                </div>
                            </div>

                            {section.data.map(row => (
                                <div className="details-row" key={shortid()}>
                                    <b>{row.title}</b>
                                    {' '}
                                    <span>{getRowValue(row)}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

const RenderQrModal = () => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            <Button variant="primary" onClick={() => setOpen(true)}>
                <AiOutlineQrcode size={'3em'} />
            </Button>

            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: colors.secondary.main.color }}>QR kod</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <QRCode size={400} value={`${cfg.linking}${window.location.hash.split('?')[0]}`} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpen(false)}>
                        Stäng
                     </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

const getRowValue = (row: row) => {

    switch (row.type) {
        case "email":
        case "link":
        case "phone":
        case "text":
            return row.value;
        case "date":
            if (!row.value) return ''
            return moment(row.value.toString()).format('YYYY-MM-DD')
        case "money":
            if (!row.value) return ''
            return asMoney(row.value.toString())

        case "yes/no":
            return row.value ? 'Ja' : 'Nej'

        default: return row.value
    }
}


const useDomainImage = ({ DomainId }): {
    loading?: boolean
    error?: any
    ImageURL?: string,
    Name?: string
} => {
    const { accountInfo } = useContext(AuthContext)

    const unauthed = useQuery(Queries.domainsLimited, { 
        skip: !!accountInfo, 
        variables: { filter: { GlobalId: DomainId } },
        context: { xDomainId: DomainId },
    })
    const authed = useQuery(Queries.domain, { 
        skip: !accountInfo, 
        variables: { filter: { GlobalId: DomainId } },
        context: { xDomainId: DomainId }, 
    })

    if (accountInfo) {
        if (authed.loading) return { loading: true }
        if (authed.error) return { loading: false, error: authed.error }
        const { domain } = authed.data
        return {
            loading: false,
            error: null,
            ImageURL: domain ? domain.ImageURL : "",
            Name: domain ? domain.Name : "",
        }
    } else {
        if (unauthed.loading) return { loading: true }
        if (unauthed.error) return { loading: false, error: unauthed.error }
        const { domainsLimited } = unauthed.data
        if (!domainsLimited || domainsLimited.length < 1) return {
            loading: false,
            error: null,
            ImageURL: "",
            Name: "",
        }
        const domain = domainsLimited[0]
        return {
            loading: false,
            error: null,
            ImageURL: domain ? domain.ImageURL : "",
            Name: domain ? domain.Name : "",
        }
    }
}

export const RenderDomainImage = ({ DomainId }) => {

    // const { Name, ImageURL, error, loading } = useDomainImage({ DomainId })
    const { Name, ImageURL, error, loading } = {Name: "", ImageURL: "", error: null, loading: null} // disable due to multi usestate/usecontext issue

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!DomainId) return <Error error="missing DomainId in component" />

    return <div>
        {ImageURL ? <img className="domain-image small shadow" src={ImageURL} /> : <div style={{
            border: "1px solid gray",
            width: '600px',
            height: '140px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>Bild saknas ({Name})</div>}
    </div>
}

export default DetailsComponent