import { useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import shortid from 'shortid'
import { Queries } from '../../queries/query'
import Error from '../generic/Error';
import { AutocompleteQueryVariables, AutocompleteQuery } from '../../types'
import './index.scss'

const Autocomplete = ({ domainId, initValue, searchValueChanged, discriminator }: { domainId?: string, initValue?: string, searchValueChanged: (val: string) => void, discriminator: string }) => {

    const [searchvalue, setSearchvalue] = useState('')

    const { data, loading, error } = useQuery<AutocompleteQuery, AutocompleteQueryVariables>(Queries.autocomplete, {
        skip: !searchvalue,
        variables: { filter: { DomainId: domainId ? domainId : 'simplona', SearchString: searchvalue, discriminator } }
    })

    if (error) return <Error error={error} />
    if (!discriminator) return null
    return (
        <Form.Group>
            <AsyncTypeahead
                autofocus
                defaultSelected={initValue ? [{ text: initValue }] : []}
                placeholder="Sök.."
                isLoading={false}
                bsSize={"large"}
                id={shortid()}
                clearButton={true}
                labelKey={option => option.text}
                onSearch={str => setSearchvalue(str)}
                onChange={val => {
                    if (val && val.length > 0)
                        searchValueChanged(val[0].text)
                    else
                        searchValueChanged(null)
                }}
                options={data ? data.autocomplete : []}
            />
        </Form.Group>
    )
}

export default Autocomplete