import React, { useState } from 'react'
import AzureAD, { IdTokenResponse } from 'react-aad-msal'
import { AuthProvider } from './authContext';
import { parse } from 'query-string'
import moment from 'moment'

import { authProvider } from './provider';

const RenderAuth: React.FC = ({ children }) => {
    let qs = window.location.search


    return (
        <AzureAD forceLogin={false} provider={authProvider} >
            {({ authenticationState, accountInfo, ...rest }) => {
                const getJwtToken = async (): Promise<string> => {

                    if (qs && qs.length > 0) {

                        let parsed = parse(qs)

                        if (parsed && parsed.jwt) {
                            return parsed.jwt as string
                        }
                    }

                    if (authenticationState === 'Unauthenticated' || !accountInfo || !accountInfo.jwtIdToken) {
                        return ""
                    }
                    if (accountInfo && accountInfo.account && (moment().isAfter(parseInt(accountInfo.account.idToken.exp) * 1000))) {
                        // let token = await authProvider.getIdToken()
                        // let newtoken = token.idToken.rawIdToken

                        // console.log('newtoken', newtoken)
                        console.log(`token expired`)
                        window.location.href = window.location.href
                        return
                    }

                    return accountInfo.jwtIdToken
                }
                return (
                    <AuthProvider value={{ authenticationState, getJwtToken, ...rest }}>
                        {children}
                    </AuthProvider>
                )
            }}
        </AzureAD >
    )
}

export default RenderAuth