import { useMutation } from '@apollo/client'
import React from 'react'
import { Dropdown, InputGroup } from 'react-bootstrap'
import { Mutations } from '../../queries/mutation'
import Error from '../generic/Error'
import { Domain, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'

const ChangeDomainOrientation = ({ domain }: { domain: Domain }) => {
    const [save, { loading, error }] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, {
        context: { xDomainId: domain.GlobalId },
    })
    if (!domain) return <Error type='notFound' />


    const update = (DomainMainOrientation) => {
        save({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    DomainMainOrientation
                }
            }
        })
    }

    const updateIsDemo = (IsDemo) => {
        save({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    IsDemo
                }
            }
        })
    }

    let label = labels[domain.DomainMainOrientation] || 'inget valt'
    let demolabel = domain.IsDemo ? demolabels.true : demolabels.false

    return (
        <div>
            {error && <Error error={error} type='mutation' />}
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Inriktning</InputGroup.Text>
                </InputGroup.Prepend>

                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {label}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => update('sales')}>Försäljning</Dropdown.Item>
                        <Dropdown.Item onClick={() => update('rental')}>Uthyrning</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Demomiljö</InputGroup.Text>
                </InputGroup.Prepend>


                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {demolabel}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => updateIsDemo(true)}>{demolabels.true}</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateIsDemo(false)}>{demolabels.false}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>
        </div>
    )
}

export const enum DomainOrientation {'sales', 'rental'};

const labels = {
    sales: 'Försäljning',
    rental: 'Uthyrning',
}

const demolabels = {
    true: 'Detta är en demomiljö',
    false: 'Produktion'
}

export default ChangeDomainOrientation