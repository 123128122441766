import React from 'react'
type authContext = {
    accountInfo?: {
        account: any,
        jwtIdToken: string,
    },
    error?: any,
    getJwtToken: () => Promise<string>
    authenticationState: "Authenticated" | "Unauthenticated"
    logout?: () => void
    login?: () => void
}
const AuthContext = React.createContext({} as authContext)
export const AuthProvider = AuthContext.Provider
export const AuthConsumer = AuthContext.Consumer
export default AuthContext
