import gql from 'graphql-tag'
import { Fragments } from './fragments'


export const Subscriptions = {
    DomainIntegrationUpserted: gql` 
        subscription DomainIntegrationUpserted($filter: FilterDomainIntegrationInput) {			
            DomainIntegrationUpserted(filter:$filter)  {			
                ...domainIntegration
            }
        }
        ${Fragments.domainIntegration}
    `,
}