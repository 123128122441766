import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Queries } from '../../queries/query'
import { CustomerProject } from '../../types'
import { asMoney } from '../../utils'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import './index.scss'
import * as en from 'linq'
import Link from '../generic/Link'
import { FiArrowRight } from 'react-icons/fi'
import { colors } from '../generic/colors'

const QuickRentInfo = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {
    const [AccRentCost, setAccRentCost] = useState<number>(0)

    return (
        <div className="quick-rent-info-wrapper shadow rounded-container">
            <RentCountTile domainId={domainId} customerProject={customerProject} />
            <RentAccumulatedTile domainId={domainId} customerProject={customerProject} setAccRentCost={setAccRentCost} />
            <TotalExpiredRentTile domainId={domainId} customerProject={customerProject} accumulatedRentCost={AccRentCost} />
            <RentExpiredTileActive domainId={domainId} customerProject={customerProject} />
        </div>
    )
}

export const QuickRentInfoOverview = ({ domainId, customerProject, last }: { domainId: string, last?: boolean, customerProject: CustomerProject }) => {
    const [AccRentCost, setAccRentCost] = useState<number>(0)

    return (
        <Link type="inline" to={`/${customerProject.ProjectId}`}>
            <div className={`project-overview padding${last ? ' last' : ''}`}>
                <div>
                    <h2>{customerProject.ProjectId}</h2>
                    <RentCountTile domainId={domainId} customerProject={customerProject} />
                    <RentAccumulatedTile domainId={domainId} customerProject={customerProject} setAccRentCost={setAccRentCost} />
                    <RentExpiredTileActive domainId={domainId} customerProject={customerProject} />
                </div>
                <span><FiArrowRight color={colors.primary.main.color} size={20} /></span>
            </div>
        </Link>
    )
}

const Tile = ({ label, value, onPress, values }: { onPress?: () => void, label: string, values?: { label?: string, value?: string }[], value?: string }) => {
    return (
        <div className="tile">
            <span className="headline">{label}</span>:{' '}
            {value && <span className="tile-value">{value}</span>}
            {values && (
                <div className="multi-value">
                    {values.map((value, i) => <Tile key={i} label={value.label} value={value.value} />)}
                </div>
            )}
        </div>
    )
}

const RentCountTile = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {
    const { data, loading, error } = useQuery(Queries.rentalLinesCalculated, {
        skip: !customerProject.ProjectNo,
        variables: {
            aggregate: 'GroupOnNumber',
            calculationType: 'onRent',
            filter: { DomainId: domainId, ProjectId: customerProject.ProjectNo.toLowerCase() }
        },
        context: { xDomainId: domainId },
    })

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data) return <span>ingen data</span>

    const { rentalLinesCalculated } = data

    if (!rentalLinesCalculated) return <Tile label={'Antal rader på hyra just nu'} value={'Inga rader'} />

    return (
        <Tile label={'Antal rader på hyra just nu'} value={`${rentalLinesCalculated.length} st`} />
    )
}

const TotalExpiredRentTile = ({ domainId, customerProject, accumulatedRentCost }) => {
    const { data, loading, error } = useQuery(Queries.rentalLineSnapshotsMany, {
        skip: !customerProject.ProjectNo,
        variables: {
            aggregate: 'SnapshotOn',
            filter: { DomainId: domainId, CustomerProject: customerProject.ProjectNo }
        },
        context: { xDomainId: domainId },
    })

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data) return <span>ingen data</span>

    const { rentalLineSnapshotsMany } = data

    if (!rentalLineSnapshotsMany) return <span>Inga rader</span>

    const noOfLines = rentalLineSnapshotsMany.length
    const totalsum = en.from(rentalLineSnapshotsMany).selectMany((x: any) => x.rentalLines).sum((x: any) => x.DayNetPriceEdge)
    const percentage = (accumulatedRentCost && totalsum) ? ((totalsum / accumulatedRentCost) * 100).toFixed(2) : 0

    return (
        <Tile onPress={() => console.log('ExpiredSnapshots', { customerProject })} label={'Missade återlämningar totalt'} values={[
            {
                label: `${noOfLines} dagar med missade återlämningar`,
                value: `${asMoney(totalsum)}`,
            },
            {
                label: `Procent av hyreskostnad`,
                value: `${percentage} %`
            }
        ]} />
    )
}

const RentExpiredTileActive = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {

    const { data, loading, error } = useQuery(Queries.rentalLinesCalculated, {
        skip: !customerProject.ProjectNo,
        variables: {
            calculationType: 'expiredOnRent',
            filter: { DomainId: domainId, ProjectId: customerProject.ProjectNo.toLowerCase() }
        },
        context: { xDomainId: domainId },
    })

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data) return <span>ingen data</span>

    const { rentalLinesCalculated } = data

    if (!rentalLinesCalculated) return <span>Inga rader</span>

    return (
        <Tile onPress={() => console.log('ExpiredOnRentList', { customerProject })} label={'Antal aktiva rader med utgånget förväntat leveransdatum'} value={`${rentalLinesCalculated.length} st`} />
    )
}

const RentAccumulatedTile = ({ domainId, customerProject, setAccRentCost }: { domainId: string, customerProject: CustomerProject, setAccRentCost: (value: number) => void }) => {

    const [TotalAmount, setTotalAmount] = useState(0)

    const { data, loading, error } = useQuery(Queries.accumulatedRentCost, {
        skip: !customerProject.ProjectNo,
        variables: {
            filter: { ProjectId: customerProject.ProjectNo.toLowerCase() }
        },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (!data) return
        const { accumulatedRentCost } = data
        if (!accumulatedRentCost) return
        setTotalAmount(accumulatedRentCost.TotalAmount)
    }, [data])

    useEffect(() => {
        setAccRentCost(TotalAmount)
    }, [TotalAmount])

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data) return <span>ingen data</span>



    return (
        <Tile onPress={() => console.log('invoices')} label={`Ackulumulerad hyreskostad`} value={asMoney(TotalAmount)} />
    )
}

export default QuickRentInfo