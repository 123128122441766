import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Queries } from '../../queries/query'
import { CustomerProject } from '../../types'
import moment from 'moment';
import { XYPlot, XAxis, YAxis, VerticalBarSeries } from 'react-vis';


const Dashboard = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {
    return (
        <div>
            <BarValues domainId={domainId} ProjectId={customerProject.ProjectId} />
        </div>
    )
}

const BarValues = ({ domainId, ProjectId }: { domainId: string, ProjectId: string }) => {
    const StartDate = useState(moment().startOf('month').subtract(12, 'month').toDate())
    const EndDate = useState(moment().endOf('month').toDate())
    const { data, loading, error } = useQuery(Queries.rentCost, { 
        variables: { filter: { ProjectId: ProjectId.toLowerCase() }},
        context: { xDomainId: domainId },
    })

    const [lines, setLines] = useState(null)
    // const lines = data.rentCost.Lines
    useEffect(() => {
        let lines = null
        if (data && data.rentCost)
            lines = data.rentCost.Lines.map(z => ({
                x: z.PeriodName,
                y: z.Amount,
            }))


        setLines(lines)
    }, [data])

    return (
        <BarChart lines={lines} />
    )
}

const BarChart = ({ lines }) => {
    if (lines != null) {
        console.log(lines)
        return (
            <XYPlot margin={{ bottom: 70, left: 100, top: 70, right: 70 }} xType="ordinal" width={1000} height={500}>
                <XAxis title="Månad" />
                <YAxis title="Hyra" />
                <VerticalBarSeries
                    data=
                    {lines}
                />
            </XYPlot>
        );
    }
    else {
        return (null);
    }
}

export default Dashboard