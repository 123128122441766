
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Alert, InputGroup, Form, Dropdown } from 'react-bootstrap'
import { RiCreativeCommonsZeroLine } from 'react-icons/ri'
import { Mutations } from '../../queries/mutation'
import { Domain, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import ChangeDomainAlternativeImage from './ChangeDomainAlternativeImage'

const SafetyRespectControl = ({ domain }: { domain: Domain }) => {
    const [mutation, { error, loading }] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, {
        context: { xDomainId: domain.GlobalId },
    })
    const [altenvativeName, setAlternativeName] = useState<string>(domain.AlternativeName)
    console.log('SafetyRespectControl')
    if (!domain) return <Error type='notFound' />

    const save = (isSafety) => {
        mutation({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    VisibleInSafetyRespect: isSafety
                }
            }
        })
    }

    const changeAlternativeName = () => {
        mutation({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    AlternativeName: altenvativeName
                }
            }
        })
    }

    const label = domain.VisibleInSafetyRespect ? 'Synlig' : 'Inte synlig'

    return (
        <Alert variant="info">
            <Alert.Heading>SafetyRespect</Alert.Heading>
            <p>Detta styr om domänen är synlig för icke inloggade användare i safety respects egen app</p>

            {error && <Error error={error} type='mutation' />}
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Synlig?</InputGroup.Text>
                </InputGroup.Prepend>

                <Dropdown>
                    <Dropdown.Toggle variant={domain.VisibleInSafetyRespect ? 'success' : 'danger'} id="dropdown-basic">
                        {label}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => save(true)}>{labels.true}</Dropdown.Item>
                        <Dropdown.Item onClick={() => save(false)}>{labels.false}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>

            <ChangeDomainAlternativeImage domain={domain} />

            <Form.Label style={{ color: '#333' }}>{"Landsnamn"}</Form.Label>
            <br />
            <input
                placeholder="saknas"
                value={altenvativeName}
                onChange={e => setAlternativeName(e.target.value)}
            />

            {(altenvativeName !== domain.AlternativeName) && (
                <div>
                    {loading && <Loading />}
                    <button disabled={loading} onClick={changeAlternativeName}>Spara ändring</button>
                </div>
            )}

        </Alert>
    )
}

const labels = {
    undefined: 'Inget aktivt val, domänen är inte synlig',
    true: 'Synlig',
    false: 'Inte synlig',
}


export default SafetyRespectControl