import { useMutation } from '@apollo/client'
import React from 'react'
import { Mutations } from '../../queries/mutation'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { useMe } from '../hooks/permissionHook'

const ClearImpersonationButton = () => {
    const { me } = useMe()
    const [mutation, { error, loading }] = useMutation(Mutations.clearMyImpersonation, { 
        refetchQueries: ['me'], 
        awaitRefetchQueries: true 
    })

    if (!me || !me.ImpersonateUserId) return null

    const clear = async () => {
        try {
            await mutation({})
        } catch (E) { }
    }

    return (
        <div>
            hello {me.name}, du har just nu en aktiv impersonation
            {error && <Error error={error} type={'mutation'} />}
            <button onClick={clear} className="rounded-button">{loading ? <Loading button /> : 'Rensa'}</button>
        </div>
    )
}


export default ClearImpersonationButton