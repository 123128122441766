import { useQuery } from '@apollo/client'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Queries } from '../../queries/query'
import { Domain, DomainsManyQuery, DomainsManyQueryVariables } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import * as en from 'linq'
import './index.scss'

const DomainPicker: FunctionComponent<{
    value: any,
    onValueChanged: (val: any) => void,
    multi?: boolean
}> = ({
    multi = false,
    value,
    onValueChanged
}) => {
        const [selectedItems, setSelectedItems] = useState<Domain[]>([])
        const { data, loading, error } = useQuery<DomainsManyQuery, DomainsManyQueryVariables>(Queries.domainsMany, { 
            variables: { filter: { DomainMainOrientation: 'rental' } },
        })

        const select = (domain: Domain) => {
            if (multi) {
                if (en.from(selectedItems).any(x => x.GlobalId === domain.GlobalId))
                    setSelectedItems(en.from(selectedItems).where(x => x.GlobalId !== domain.GlobalId).toArray())
                else
                    setSelectedItems([...selectedItems, domain])
            } else {
                setSelectedItems([domain])
            }
        }

        useEffect(() => {
            if (multi)
                onValueChanged(selectedItems)
            else
                onValueChanged(selectedItems.length > 0 ? selectedItems[0] : null)
        }, [selectedItems])

        const isDomainSelected = (domain: Domain): boolean => {
            return en.from(selectedItems).any(x => x.GlobalId === domain.GlobalId)
        }


        if (loading) return <Loading />
        if (error) return <Error error={error} />

        return (
            <div className="suppliers selectable">
                {data.domainsMany.map(domain => (
                    <div className={isDomainSelected(domain) ? 'item selected' : 'item'} style={{
                        cursor: 'pointer'
                    }} key={domain.GlobalId} onClick={() => select(domain)}>
                        <img className="domain-image small" src={domain.ImageURL} alt={domain.Name} />
                    </div>
                ))}
            </div>
        )
    }

export default DomainPicker
