import React from 'react'
import { Switch, Route } from "react-router-dom";
import AllImagesCard from '../components/document/AllImagesCard';
import DocumentCard from '../components/document/DocumentCard';
import DocumentList from '../components/document/DocumentList';
import CustomerDomainCardSimplona from '../components/domain/CustomerDomainCardSimplona';
import CustomerDomainListSimplona from '../components/domain/CustomerDomainListSimplona';
import CustomerDomainCard from '../components/customerDomain/CustomerDomainCard';
import CustomerDomainList from '../components/customerDomain/CustomerDomainList';
import DomainCard from '../components/domain/DomainCard';
import DomainIntegrationCard from '../components/domainIntegration/DomainIntegrationCard';
import DomainIntegrationList from '../components/domainIntegration/DomainIntegrationList';
import UserCard from '../components/user/userCard';
import UserList from '../components/user/userList';
import DomainList from '../components/domain/DomainList';
import DynamicCrumb from '../components/generic/DynamicCrumb';
import GuestCard from '../components/guest/GuestCard';
import AcceptInvitation from '../components/invitations/AcceptInvitation';
import InvitationCard from '../components/invitations/InvitationCard';
import InvitationJumbotron from '../components/invitations/InvitationJumbotron';
import InvitationsList from '../components/invitations/InvitationsList';
import InvoiceCard from '../components/invoice/InvoiceCard';
import ObjectFamilyList from '../components/Object/ObjectFamilyList';
import ObjectGroupList from '../components/Object/ObjectGroupList';
import ObjectTypeCard from '../components/Object/ObjectTypeCard';
import ObjectTypeList from '../components/Object/ObjectTypeList';
import OnRent from '../components/onrent/Onrent';
import ProjectCard from '../components/onrent/ProjectCard';
import MyProfile from '../components/profile/MyPages';
import MainPage from '../components/start/MainPage';
import StartPageJumbotron from '../components/start/StartPageJumbotron';
import { Queries } from '../queries/query';
import '../app.scss'
import RentalGroup from '../components/onrent/RentalGroup';
// import HeartbeatOverview from '../components/domainIntegration/HeartbeatOverview';

const MainNavigation = ({ element }) => {
    return (
        <div ref={element}>
            <Switch>

                <Route exact path="/" component={MainPage} />
                <Route exact path="/accept-invitation/:domainId/:invitationId"><AcceptInvitation type='company' /></Route>
                <Route exact path="/accept-domain-invitation/:domainId/:invitationId"><AcceptInvitation type='domain' /></Route>
                <Route exact path="/documents"><DocumentList /></Route>
                <Route exact path="/documents/images/:TargetId/"><AllImagesCard /></Route>
                <Route exact path="/#/documents/images/:TargetId/"><AllImagesCard /></Route>
                <Route exact path="/documents/:DocumentId"><DocumentCard /></Route>

                <div className="main-content">
                    <Route exact path="/mina-sidor" component={MyProfile} />

                    <Route exact path="/mina-sidor/customer-domains" component={CustomerDomainListSimplona} />
                    <Route exact path="/mina-sidor/customer-domains/:customerDomainId" component={CustomerDomainCardSimplona} />

                    <Route exact path="/mina-sidor/domains" component={DomainList} />
                    <Route exact path="/mina-sidor/domains/:domainId" component={DomainCard} />
                    
                    {/* <Route exact path="/mina-sidor/domains/:domainId/heartbeat-overview" component={HeartbeatOverview} />                  */}

                    <Route exact path="/mina-sidor/domains/:domainId/users" component={UserList} />
                    <Route exact path="/mina-sidor/domains/:domainId/users/:userId" component={UserCard} />

                    <Route exact path="/mina-sidor/domains/:domainId/integrations" component={DomainIntegrationList} />
                    <Route exact path="/mina-sidor/domains/:domainId/integrations/:integrationId" component={DomainIntegrationCard} />

                    <Route exact path="/mina-sidor/domains/:domainId/invitations" component={InvitationsList} />
                    <Route exact path="/mina-sidor/domains/:domainId/invitations/:invitationId" component={InvitationCard} />
                    <Route exact path="/mina-sidor/domains/:domainId/invitations/:invitationId/user/:userid" component={UserCard} />

                    <Route exact path="/mina-sidor/domains/:domainId/customer-domains" component={CustomerDomainList} />
                    <Route exact path="/mina-sidor/domains/:domainId/customer-domains/:customerDomainId" component={CustomerDomainCard} />

                    <Route exact path="/mina-sidor/domains/:domainId/object-list/" component={ObjectFamilyList} />
                    <Route exact path="/mina-sidor/domains/:domainId/object-list/:objectFamilyId" component={ObjectGroupList} />
                    <Route exact path="/mina-sidor/domains/:domainId/object-list/:objectFamilyId/:objectGroupId" component={ObjectTypeList} />
                    <Route exact path="/mina-sidor/domains/:domainId/object-list/:objectFamilyId/:objectGroupId/:objectId" component={ObjectTypeCard} />

                    <Route exact path="/mina-sidor/domains/:domainId/on-rent" component={OnRent} />
                    <Route exact path="/mina-sidor/domains/:domainId/on-rent/:customerProjectId" component={ProjectCard} />
                    <Route exact path="/mina-sidor/domains/:domainId/on-rent/:customerProjectId/rent/:GroupOnNumber" component={RentalGroup} />
                    <Route exact path="/mina-sidor/domains/:domainId/on-rent/:customerProjectId/rent/:GroupOnNumber/:rentalLineId" component={RentalGroup} />
                    <Route exact path="/mina-sidor/domains/:domainId/on-rent/:customerProjectId/invoices/:GroupOnNumber" component={InvoiceCard} />

                    <Route exact path="/guest/:domainId/" component={GuestCard} />
                </div>

                <Route>404 - Hittar inte sidan du söker</Route>
            </Switch>
        </div >
    )
}

export const getRoutes = (path: string = "") => ({
    [`${path}/`]: 'Start',

    [`${path}/documents/images/:TargetId`]: '',

    [`${path}/accept-invitation`]: '',
    [`${path}/accept-invitation/:domainId/:invitationId`]: 'Acceptera inbjudan',

    [`${path}/accept-domain-invitation`]: '',
    [`${path}/accept-domain-invitation/:domainId/:invitationId`]: 'Acceptera inbjudan',

    [`${path}/mina-sidor`]: 'Mina sidor',
    // [`${path}/mina-sidor/heartbeat-overview`]: 'Heartbeat-övervakning',

    [`${path}/mina-sidor/customer-domains`]: 'Kunddomäner',
    [`${path}/mina-sidor/customer-domains/:customerDomainId`]: (match, url) => <DynamicCrumb urlProp={'customerDomainId'} labelProperty={"Name"} route={{ match, url }} query={Queries.customerDomain} />,

    [`${path}/mina-sidor/domains`]: 'Domäner',
    [`${path}/mina-sidor/domains/:domainId`]: (match, url) => <DynamicCrumb urlProp={'domainId'} labelProperty={"Name"} route={{ match, url }} query={Queries.domain} />,

    [`${path}/mina-sidor/domains/:domainId/Accounts`]: 'Konto',
    [`${path}/mina-sidor/domains/:domainId/Accounts/:userId`]: (match, url) => <DynamicCrumb urlProp={'userId'} labelProperty={"Name"} route={{ match, url }} query={Queries.user} />,

    [`${path}/mina-sidor/domains/:domainId/integrations`]: 'Anslutningar',
    [`${path}/mina-sidor/domains/:domainId/integrations/:integrationId`]: (match, url) => <DynamicCrumb urlProp={'integrationId'} labelProperty={"Type"} route={{ match, url }} query={Queries.domainIntegration} />,
    
    [`${path}/mina-sidor/domains/:domainId/manage-invitations`]: 'Hantera inbjudningar',
    [`${path}/mina-sidor/domains/:domainId/manage-invitations/:invitationId`]: (match, url) => <DynamicCrumb urlProp={'invitationId'} labelProperty={"CustomerName"} route={{ match, url }} query={Queries.invitation} />,
    [`${path}/mina-sidor/domains/:domainId/manage-invitations/:invitationId/user`]: '',
    [`${path}/mina-sidor/domains/:domainId/manage-invitations/:invitationId/user/:userId`]: (match, url) => <DynamicCrumb urlProp={'userId'} labelProperty={"name"} route={{ match, url }} query={Queries.user} />,

    [`${path}/mina-sidor/domains/:domainId/object-list`]: 'Maskinkatalog',
    [`${path}/mina-sidor/domains/:domainId/object-list/:objectFamilyId`]: (match, url) => <DynamicCrumb urlProp={'objectFamilyId'} labelProperty={"Family"} idProp={"ObjectGroupFamily"} route={{ match, url }} query={Queries.objectGroup} />,
    [`${path}/mina-sidor/domains/:domainId/object-list/:objectFamilyId/:objectGroupId`]: (match, url) => <DynamicCrumb urlProp={'objectGroupId'} idProp={"ObjectGroup"} labelProperty={"ObjectGroup"} route={{ match, url }} query={Queries.objectGroup} />,
    [`${path}/mina-sidor/domains/:domainId/object-list/:objectFamilyId/:objectGroupId/:objectId`]: (match, url) => <DynamicCrumb urlProp={'objectId'} idProp={"ObjectType"} labelProperty={"ObjectType"} route={{ match, url }} query={Queries.object} />,

    // [`${path}/mina-sidor/on-rent`]: 'Maskinhyra',
    // [`${path}/mina-sidor/on-rent/:CustomerProjectId`]: (match, url) => <DynamicCrumb urlProp={'CustomerProjectId'} labelProperty={"ProjectNo"} idProp={'ProjectId'} route={{ match, url }} query={Queries.customerProject} />,
    // [`${path}/mina-sidor/on-rent/:CustomerProjectId/rent`]: '',
    // [`${path}/mina-sidor/on-rent/:CustomerProjectId/rent/:GroupOnNumber`]: (match, url) => <DynamicCrumb urlProp={'GroupOnNumber'} labelProperty={"Description"} idProp='GroupOnNumber' route={{ match, url }} query={Queries.rentalLine} />,
    // [`${path}/mina-sidor/on-rent/:CustomerProjectId/rent/:GroupOnNumber/:rentalLineId`]: (match, url) => <DynamicCrumb urlProp={'rentalLineId'} labelProperty={"No"} route={{ match, url }} query={Queries.rentalLine} />,
    // [`${path}/mina-sidor/on-rent/:CustomerProjectId/invoices`]: '',
    // [`${path}/mina-sidor/on-rent/:CustomerProjectId/invoices/:invoiceId`]: (match, url) => <DynamicCrumb urlProp={'invoiceId'} labelProperty={"No"} route={{ match, url }} query={Queries.invoiceHeader} />,

    [`${path}/mina-sidor/domains/:domainId/on-rent`]: 'Maskinhyra',
    [`${path}/mina-sidor/domains/:domainId/on-rent/:customerProjectId`]: (match, url) => <DynamicCrumb urlProp={'customerProjectId'} labelProperty={"ProjectNo"} idProp={"ProjectId"} route={{ match, url }} query={Queries.customerProject} />,
    [`${path}/mina-sidor/domains/:domainId/on-rent/:CustomerProjectId/rent`]: '',
    [`${path}/mina-sidor/domains/:domainId/on-rent/:customerProjectId/rent/:GroupOnNumber`]: (match, url) => <DynamicCrumb urlProp={'GroupOnNumber'} idProp={"GroupOnNumber"} labelProperty={"Description"} route={{ match, url }} query={Queries.rentalLine} />,
    [`${path}/mina-sidor/domains/:domainId/on-rent/:customerProjectId/rent/:GroupOnNumber/:rentalLineId`]: (match, url) => <DynamicCrumb urlProp={'rentalLineId'} labelProperty={"No"} route={{ match, url }} query={Queries.rentalLine} />,
    [`${path}/mina-sidor/domains/:domainId/on-rent/:CustomerProjectId/invoices`]: '',
    [`${path}/mina-sidor/domains/:domainId/on-rent/:customerProjectId/invoices/:invoiceId`]: (match, url) => <DynamicCrumb urlProp={'invoiceId'} labelProperty={"No"} route={{ match, url }} query={Queries.invoiceHeader} />,

    [`${path}/mina-sidor/domains/:domainId/guest`]: 'Gäster',
})

export const MainJumbotron = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={StartPageJumbotron} />
                <Route exact path="/accept-invitation/:domainId/:invitationid" component={InvitationJumbotron} />
            </Switch>
        </>
    )
}

export default MainNavigation