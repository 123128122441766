import gql from 'graphql-tag'
import { Fragments } from './fragments'


export const Mutations = {

    sendOnRentListByEmail: gql`
		mutation sendOnRentListByEmail($record: SendOnRentEmailInput!) {
			sendOnRentListByEmail(record: $record) {
				ok
				error
			}
		}
	`,

    upsertObjectType: gql`
        mutation upsertObjectType($record: UpdateObjectTypeInput!) {
            upsertObjectType(record: $record) {
                ...objectType
            }
        }
        ${Fragments.objectType}
    `,

    changeObjectTypeImage: gql`
        mutation changeObjectTypeImage($record: ChangeObjectTypeImagePayload!) {
            changeObjectTypeImage(record: $record) {
                ...objectType
            }
        }
        ${Fragments.objectType}
    `,

    changeObjectGroupImage: gql`
        mutation changeObjectGroupImage($record: ChangeObjectTypeImagePayload!) {
            changeObjectGroupImage(record: $record) {
                ...objectGroup
            }
        }
        ${Fragments.objectGroup}
    `,

    changeObjectFamilyImage: gql`
        mutation changeObjectFamilyImage($record: ChangeObjectTypeImagePayload!) {
            changeObjectFamilyImage(record: $record) {
                ...objectFamily
            }
        }
        ${Fragments.objectFamily}
    `,

    impersonateUser: gql`
        mutation impersonateUser($Email: String!) {	
            impersonateUser(Email:$Email) {
                ...me
            }
        }           
        ${Fragments.me}
    `,

    clearMyImpersonation: gql`
        mutation clearMyImpersonation{	
            clearMyImpersonation {
                ...me
            }
        }     
        ${Fragments.me}      
    `,

    clearInvitationCodes: gql`
        mutation clearInvitationCodes($code: String!) {	
            clearInvitationCodes(code:$code) {
                codesRemoved
            }
        }           
    `,

    removeUser: gql`
        mutation removeUser($filter: FilterUserInput!) {	
            removeUser(filter:$filter) {
                ok
            }
        }           
    `,

    uploadIOSAppCodeFile: gql`
        mutation uploadIOSAppCodeFile($file: String!, $InvitationCode: String!) {	
            uploadIOSAppCodeFile(file:$file, InvitationCode: $InvitationCode) {			                
                success
                newCodesAdded
                totalCodeCount
                error
            }
        }               
    `,
    setFlagImage: gql`
        mutation setFlagImage($record: UploadDomainImageInput!, $DomainId: String!) {	
            setFlagImage(record:$record, DomainId: $DomainId) {			                
                ...domain
            }
        }       
        ${Fragments.domain}
    `,
    removeDomainIntegration: gql`
        mutation removeDomainIntegration($filter: FilterDomainIntegrationInput!) {	
            removeDomainIntegration(filter:$filter) {			                
                ok
                deletedCount
                GlobalIds                         
            }
        }        
    `,
    upsertDomainIntegration: gql`
        mutation upsertDomainIntegration($record: UpdateDomainIntegrationInput) {	
            upsertDomainIntegration(record:$record) {			                
                ...domainIntegration
            }
        }
        ${Fragments.domainIntegration}
    `,
    setDomainImage: gql`
        mutation setDomainImage($record: UploadDomainImageInput!, $DomainId: String!) {	
            setDomainImage(record:$record, DomainId: $DomainId) {			                
                ...domain                                
            }
        }
        ${Fragments.domain}
    `,
    removeUserMany: gql`
        mutation removeUserMany($filter: FilterUserInput!) {	
            removeUserMany(filter:$filter) {			                
                ok
                deletedCount
                GlobalIds                         
            }
        }        
    `,
    createServiceAccount: gql`
        mutation createServiceAccount($record: CreateSerciceAccountInput) {
            createServiceAccount(record:$record){
                ...user
            }
        }
        ${Fragments.user}
    `,
    upsertUser: gql`
        mutation upsertUser($record: UpdateUserInput) {	
            upsertUser(record:$record) {			                
                ...user                                
            }
        }
        ${Fragments.user}
    `,
    createCompanyInvitation: gql`
        mutation createCompanyInvitation($record: CompanyInvitationInput) {	
            createCompanyInvitation(record:$record) {			                
                ...invitation                                
            }
        }
        ${Fragments.invitation}
    `,
    createDomainInvitation: gql`
        mutation createDomainInvitation($record: DomainInvitationInput) {	
            createDomainInvitation(record:$record) {			                
                ...invitation                                
            }
        }
        ${Fragments.invitation}
    `,
    acceptCompanyInvitation: gql`
        mutation acceptCompanyInvitation($Code: String!) {	
            acceptCompanyInvitation(Code:$Code) {			                
                status     
                CustomerId
                CustomerName
                UserAcceptedInvitationOn
                UserInvitationId
                role
            }
        }
    `,
    acceptDomainInvitation: gql`
        mutation acceptDomainInvitation($Code: String!) {	
            acceptDomainInvitation(Code:$Code) {			                
                status                   
                DomainId                  
                CustomerName
                UserAcceptedInvitationOn
                UserInvitationId
                role
                IOSAppLink
                AndroidAppLink
                IOSAppCode
            }
        }
    `,
    upsertCustomerDomain: gql`
        mutation upsertCustomerDomain($record: UpdateCustomerDomainInput) {	
            upsertCustomerDomain(record:$record) {			                
                ...customerDomain                                
            }
        }
        ${Fragments.customerDomain}
    `,

    initializeDomain: gql`
        mutation initializeDomain($record: UpdateDomainInput) {	
            initializeDomain(record:$record) {			                
                ...domain                                
            }
        }
        ${Fragments.domain}
    `,
    
    upsertDomain: gql`
        mutation upsertDomain($record: UpdateDomainInput) {	
            upsertDomain(record:$record) {			                
                ...domain                                
            }
        }
        ${Fragments.domain}
    `,
    removeDomainMany: gql`
        mutation removeDomainMany($filter: FilterDomainInput) {	
            removeDomainMany(filter:$filter) {			                
                ok
                deletedCount                
                GlobalIds
            }
        }
    `,
    toggleSupplier: gql`
        mutation toggleSupplier($record: toggleSupplierInput) {	
            toggleSupplier(record:$record) {			                
                Error
                Status
            }
        }
    `,
    removeCustomerDomainMany: gql`
        mutation removeCustomerDomainMany($filter: FilterCustomerDomainInput) {	
            removeCustomerDomainMany(filter:$filter) {			                
                ok
                deletedCount                
                GlobalIds
            }
        }
    `,
    removeInvitationsMany: gql`
        mutation removeInvitationsMany($filter: FilterInvitationInput) {	
            removeInvitationsMany(filter:$filter) {			                
                ok
                deletedCount                
                GlobalIds
            }
        }
    `,
    removeInvitation: gql`
        mutation removeInvitation($filter: FilterInvitationInput) {	
            removeInvitation(filter:$filter) {			                
                ok
                deletedCount                
                GlobalIds
            }
        }
    `,
    updateDomainInvitationAppLinks: gql`
        mutation updateDomainInvitationAppLinks($record: InvitationUpdateInput!) {	
            updateDomainInvitationAppLinks(record:$record) {			                
                ...invitation
            }
        }
        ${Fragments.invitation}
    `,
    deactivateInvitation: gql`
        mutation deactivateInvitation($code: String!) {	
            deactivateInvitation(code:$code) {			                
                ...invitation
            }
        }
        ${Fragments.invitation}
    `,
    toggleFavoriteProject: gql`
        mutation toggleFavoriteProject($record: toggleFavoriteProjectInput) {	
            toggleFavoriteProject(record:$record) {		
                ...me	                
            }
        }
        ${Fragments.me}
    `,
    upsertRentalLine: gql`
        mutation upsertRentalLine($record: UpdateRentalLineInput!) {
            upsertRentalLine(record: $record) {
                ...rentalLine
            }
        }
        ${Fragments.rentalLine}
    `,
}