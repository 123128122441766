import React, { useEffect, useState } from 'react'
import { RentalLine } from '../../types'
import { AiFillTags } from "react-icons/ai";
import { Button, Modal } from 'react-bootstrap'
import { colors } from '../generic/colors';
import { useMutation } from '@apollo/client';
import { Mutations } from '../../queries/mutation';
import * as en from 'linq'
import TagInput from '../generic/TagInput';
import Loading from '../generic/Loading';
import { useRentalLinesFromGroups } from '../hooks/rentHooks';


const RentallineTags = ({ domainId, rentalLines, rentalLine}: { domainId: string, rentalLines?: RentalLine[], rentalLine?: RentalLine}) => {
    const [open, setOpen] = useState<boolean>(false)
    const requestClose = () => setOpen(false)
    const [ProjectId, setProjectId] = useState<string>()
    const [allLinesInSelection, setAllLinesInSelection] = useState<RentalLine[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [modified, setModified] = useState<boolean>(false)
    const [tags, setTags] = useState<string[]>(rentalLine && rentalLine.Tags ? rentalLine.Tags : [])
    const groupquery = useRentalLinesFromGroups({ rentalLines, ProjectId, skip: !!rentalLine })

    const [mutation, { error }] = useMutation(Mutations.upsertRentalLine, {
        refetchQueries: [ 'rentalLinesCalculated' ],
        context: { xDomainId: domainId },
        awaitRefetchQueries: true
    })

    useEffect(() => {
        if (!groupquery || !groupquery.rentalLines) return
        setAllLinesInSelection(groupquery.rentalLines)
    }, [groupquery])

    useEffect(() => {
        if (rentalLine && rentalLine.Tags) setTags(rentalLine.Tags)
    }, [rentalLine])

    useEffect(() => {
        if (rentalLines) {
            let t = en.from(rentalLines).selectMany(x => x.Tags).distinct().toArray()
            setTags(t)
            setModified(true)

            if (rentalLines.length > 0) {
                let first = rentalLines[0]
                setProjectId(first.ProjectId)
            }
        }
    }, [rentalLines])

    const addTag = t => {
        setTags(old => [...old, t])
        setModified(true)
    }

    const removeTag = t => {
        setTags(old => en.from(old).where(x => x !== t).toArray())
        setModified(true)
    }

    const save = async () => {
        if (!modified) return

        if (rentalLine) {
            await mutation({
                variables: {
                    record: {
                        GlobalId: rentalLine.GlobalId,
                        Tags: tags
                    }
                },
                context: { xDomainId: rentalLine.DomainId },
            })
        } else if (allLinesInSelection.length > 0) {
            await Promise.all(allLinesInSelection.map(r => {
                return mutation({
                    variables: {
                        record: {
                            GlobalId: r.GlobalId,
                            Tags: tags
                        }
                    },
                    context: { xDomainId: r.DomainId },
                })
            }))
        } else {
            alert('Något gick fel')
            return
        }


        close()
        setModified(false)
    }

    const close = () => {
        setTags([])
        setOpen(false)
    }

    return (
        <div>
            <button onClick={() => setOpen(!open)} className="rounded-button darkGreen"><span style={{ marginRight: '10px' }}>Hantera taggar</span><AiFillTags size={20} color={'white'} /></button>
            <Modal animation={false} show={open} onHide={requestClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: colors.primary.main.color }}>Hantera taggar</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {groupquery.loading && <Loading />}
                    {!groupquery.loading && <TagInput tags={tags} onAddTag={addTag} onRemoveTag={removeTag} />}

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={requestClose}>
                        Stäng
                    </Button>

                    <Button disabled={loading || !modified} variant="success" onClick={save}>
                        Skicka
                        {loading && <Loading button />}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}


export default RentallineTags