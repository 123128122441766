import React, { useContext, useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import AuthContext from './auth/authContext'
import { getClient } from './apollo';
import MainNavigation, { MainJumbotron } from './navigation';
import {
	BrowserRouter as Router,
} from "react-router-dom";
import MenuBar from './components/menu/MenuBar';
import { Col, Container, Row } from 'react-bootstrap';
import { useSticky } from './components/menu/hooks';
import './app.scss'
import '../node_modules/react-vis/dist/style.css';
import BreadcrumbContainer from './components/breaccrumbs/BreadcrumbContainer';
import BearerButton from './components/menu/BearerButton';
import { parse } from 'query-string'
import ClearImpersonationButton from './components/user/ClearImpersonationButton';
import { Helmet } from "react-helmet";
import { setupLocales } from './locales';

setupLocales()


const App = () => {
	const [minified, setMinified] = useState<boolean>(false)
	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
	const auth = useContext(AuthContext)
	const { isSticky, element } = useSticky()

	console.log('client retrieved from hook:', !!client)

	useEffect(() => {
		const parsed = parse(window.location.search)
		setMinified(!!parsed.minified)
	}, [])


	useEffect(() => {
		if (!auth) {
			console.log('auth missing')
			return
		}

		const { getJwtToken } = auth
		const client = getClient({
			getToken: getJwtToken,
			domainId: ''
		})
		console.log('client created', !!client)
		setClient(client)
	}, [auth])


	const refresh = async () => {
		let test = await auth.getJwtToken()
		console.log('refreshed?', test)
	}

	if (!client) return null

	return (
		<ApolloProvider client={client}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>NavetEdge - Hyrappen</title>
				<link rel="canonical" href="https://edge.navet.se" />
			</Helmet>
			
			<>
			<Router>
				<MenuBar sticky={isSticky} minified={minified} />
				<MainJumbotron />
				<BreadcrumbContainer minified={minified} />
				<MainNavigation element={element} />
			</Router>
			</>

			{!minified && (
				<footer>
					<div id="overlay">
						<Container id="footer-content">
							<Row>
								<Col md={6}>
									<Col md={12}>
										<h2>En stabil, långsiktig partner</h2>
										<p>
											Navet har sedan år 1989 skapat lönsamhet för sina kunder i förändringsprocesser inom våra tre samverkande affärsområden: NAVET Analytics, NAVET Corporate finance och NAVET Affärssystem. Många kundrelationer har varat genom konjunkturnedgångar och -uppgångar. Det förutsätter en ärlig och förtroendefull dialog. God affärsetik, hög moral och integritet är inte bara ord för oss, det är dagliga handlingar som år efter år skapar bättre affärer för kunderna.
                                </p>
									</Col>
									<Col md={8}>
										<div className="rounded-button">Kom i kontakt med oss</div>
									</Col>
								</Col>

								<Col md={4}>
									<BearerButton />
									<ClearImpersonationButton />
								</Col>

							</Row>
						</Container>
					</div>
				</footer>
			)}
		</ApolloProvider>
	);
}

export default App;
