import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { Mutations } from '../../queries/mutation'
import { CustomerDomain, UpsertCustomerDomainMutation, UpsertCustomerDomainMutationVariables } from '../../types'
import Form from '../Form'
import Error from '../generic/Error'

const CustomerDomainForm = ({
    onRequestClose
}: {
    onRequestClose: () => void
}) => {

    const [mutation, { error, loading }] = useMutation<UpsertCustomerDomainMutation, UpsertCustomerDomainMutationVariables>(Mutations.upsertCustomerDomain, { 
        refetchQueries: ['customerDomainsPage'] 
    })

    const save = async (record: CustomerDomain) => {
        await mutation({
            variables: {
                record: {
                    GlobalId: record.GlobalId || "",
                    Name: record.Name,
                    RegistrationNo: record.RegistrationNo,
                    CustomerId: record.RegistrationNo
                }
            },
            context: { xDomainId: record.DomainId },
        }).catch(e => { })
    }

    return (
        <div className="shadow rounded-container">
            {error && <Error error={error} type="mutation" />}
            <Form
                loading={loading}
                onRequestClose={onRequestClose}
                fields={[
                    { name: 'Name', label: "Namn", },                    
                    { name: 'RegistrationNo', label: "Orgnummer", },
                ]}
                onSubmit={save}
            />
        </div>
    )
}

export const CreateNewCustomerDomainButton = () => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <div style={{ width: '100%' }}>
            <div className="full-width-controls">
                <div className="rounded-button-wrapper" onClick={() => setOpen(old => !old)}>
                    <div className="rounded-button blue">
                        Lägg till kunddomän
                    </div>
                </div>
            </div>
            <Collapsible open={open} trigger={<div></div>}>
                <CustomerDomainForm onRequestClose={() => setOpen(false)} />
            </Collapsible>
        </div>
    )
}

export default CustomerDomainForm