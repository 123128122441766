import React, { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as en from 'linq'
import DynamicPicker from './DynamicPicker';
import "./index.scss"
import { FormField } from './types';
import Loading from '../generic/Loading';
import Error from '../generic/Error';

const Form: FunctionComponent<{
    fields: FormField[],
    values?: any,
    headerNote?: any,
    loading?: boolean,
    error?: any,
    submitDisabled?: boolean,
    onSubmit: (values: any) => Promise<void>,
    onRequestClose?: () => void
}> = ({
    headerNote,
    loading,
    error,
    submitDisabled,
    onSubmit,
    values,
    fields,
    onRequestClose
}) => {
        const { control, register, handleSubmit, errors, formState, watch, trigger, setValue, reset } = useForm({
            mode: 'onChange'
        });
        const state = watch()

        useEffect(() => {
            let defaultvalues = en.from(fields).where(x => x.defaultValue)
            defaultvalues.forEach(field => {
                setValue(field.name, field.defaultValue, {
                    shouldDirty: false,
                    shouldValidate: false
                })
            })
        }, [])

        useEffect(() => {
            if (!values || !state) return

            Object.keys(values).forEach(key => {
                let field = en.from(fields).firstOrDefault(x => x.name === key)
                if (!field) {
                    return
                }

                if (state[field.name] === undefined && !isHidden(field) && values[field.name]) {
                    setValue(field.name, values[field.name], {
                        shouldValidate: true,
                        shouldDirty: false
                    })
                }
            })
        }, [values, state])

        const save = async (value) => {
            await onSubmit(value)
            reset()

        }

        const isRequired = (field: FormField): boolean => {
            if (typeof (field.required) === 'function') return field.required(state)
            else return !!field.required
        }

        const isHidden = (field: FormField): boolean => {
            if (typeof (field.hidden) === 'function') return field.hidden(state)
            else return !!field.hidden
        }

        const isReadOnly = (field: FormField): boolean => {
            if (typeof (field.readonly) === 'function') return field.readonly(state)
            else return !!field.readonly
        }

        const handleClose = () => {
            if (onRequestClose) onRequestClose()
        }


        return (
            <form onSubmit={handleSubmit(save)}>
                <div className={`done-wrapper${formState.isSubmitted && !formState.isDirty ? ' done' : ''}`}>
                    Sparat
                </div>
                <div className={`note-wrapper${!headerNote ? ' hidden' : ''}`}>{headerNote}</div>

                {fields.map((field, i) => {
                    const required = isRequired(field)
                    const hidden = isHidden(field)
                    const readonly = isReadOnly(field)
                    if (hidden) return null
                    return (
                        <div key={`${i}-label`} className="input">
                            <label>{required && '*'}{field.label}</label>

                            {field.type === 'staticPicker' && (
                                <select defaultValue={field.defaultValue} key={i} ref={register} name={field.name} disabled={readonly}>
                                    {!field.defaultValue && <option value={null}></option>}
                                    {field.values.map((value, i) => (<option key={i} value={value.value}>{value.label}</option>))}
                                </select>
                            )}

                            {(field.type === 'dynamicPicker' || field.type === 'multiValue') && (
                                <DynamicPicker
                                    multiValue={field.type === 'multiValue'}
                                    state={state}
                                    onChange={setValue}
                                    formField={field}
                                    control={control}
                                />
                            )}

                            {(field.type === 'int') && <input
                                name={field.name}
                                ref={register}
                                type='number'
                                placeholder={field.placeholder || field.label}
                                required={required}
                                readOnly={readonly}
                            />}

                            {(!field.type || field.type === 'string') && <input
                                name={field.name}
                                ref={register}
                                placeholder={field.placeholder || field.label}
                                required={required}
                                readOnly={readonly}
                            />}

                            {(field.type === 'boolean') && <input
                                name={field.name}
                                ref={register}
                                type='checkbox'
                                placeholder={field.placeholder || field.label}
                                required={required}
                                readOnly={readonly}
                            />}

                        </div>
                    )
                })}

                {/* <input name="age" ref={register({ pattern: /\d+/ })} /> */}

                <div className="buttons">
                    {onRequestClose && <div onClick={handleClose}>
                        Stäng
                    </div>}
                    <input className="rounded-button blue" type="submit" disabled={loading || formState.isSubmitting || submitDisabled} /> {loading && <Loading />}

                </div>
                {error && <Error error={error} />}
            </form>
        )
    }



export default Form