import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Card, CardGroup, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Queries } from '../../queries/query'
import DetailsComponent from '../generic/DetailsComponent'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { ObjectTypeImage } from './ObjectTypeList'

const ObjectTypeCard = () => {
    const { domainId, objectId } = useParams<{ domainId: string, objectId: string }>()

    const [headline, setHeadline] = useState<string>('Objekttyp')
    const [objectType, setObjectType] = useState(null)
    const { data, loading, error } = useQuery(Queries.objectType, { 
        skip: !objectId, 
        variables: { filter: { DomainId: domainId, GlobalId: objectId } },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (!data || !data.objectType) return
        const { objectType } = data
        setObjectType(objectType)
        setHeadline(objectType.Description)
    }, [data])

    if (!domainId) return <Error error='the domain Id cannot be null' />
    
    if (loading) return <Loading />
    if (error) return <Error error={error} />

    return (
        <div>
            <DetailsComponent Headline={headline} DomainId={domainId} />

            <Container>
                <CardGroup className="supplier-boxes">
                    <Card text="primary" className="shadow">
                        <Card.Body>
                            {objectType && <ObjectTypeImage objectType={objectType} />}
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Container>
        </div>
    )
}

export default ObjectTypeCard