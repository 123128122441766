import { useMutation } from '@apollo/client'
import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Mutations } from '../../queries/mutation'
import { Domain, SetFlagImageMutationVariables, SetFlagImageMutation } from '../../types'
import { colors } from '../generic/colors'
import Error from '../generic/Error'
import Loading from '../generic/Loading'



const ChangeDomainAlternativeImage = ({ domain }: { domain: Domain }) => {
    const [image, setImage] = useState(domain.FlagImageUrl)
    const [save, { loading, error }] = useMutation<SetFlagImageMutation, SetFlagImageMutationVariables>(Mutations.setFlagImage, {
        context: { xDomainId: domain.GlobalId },
    })

    useEffect(() => {
        if (!domain) return
        setImage(domain.FlagImageUrl)
    }, [domain])

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)

        var file = acceptedFiles[0]
        const reader = new FileReader();
        reader.onload = async (event) => {
            let base64 = event.target.result.toString()

            await save({
                variables: {
                    DomainId: domain.GlobalId,
                    record: {
                        Base64: base64,
                        Name: file.name
                    }
                }
            })
        };
        reader.readAsDataURL(file);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    if (error) return <Error error={error} />

    return (
        <div>
            <p><b>Flagga</b></p>
            <div {...getRootProps()} style={{ padding: "10px", cursor: 'pointer' }}>
                <input {...getInputProps()} />
                {loading && <Loading />}
                {isDragActive && <p>Släpp bilden här (600x140px)</p>}
                {image ? <img src={image} alt={domain.Name} /> : <div style={{
                    border: "1px solid gray",
                    width: '284px',
                    height: '177px',
                    backgroundColor: colors.scale.mid,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white'
                }}>Bild saknas (284x177px)</div>}
            </div>

        </div>
    )
}



export default ChangeDomainAlternativeImage
