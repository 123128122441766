import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Loading from './Loading'
import { Helmet } from 'react-helmet'

const DynamicCrumb = ({
    route,
    labelProperty,
    query,
    idProp,
    urlProp,
    prependLabel = "",
    appendLabel = ""
}:
    {
        route: any,
        labelProperty: string,
        query: any,
        urlProp: string,
        idProp?: string
        prependLabel?: string
        appendLabel?: string
    }): any => {
    let id = route.url[urlProp]

    if (!idProp)
        idProp = 'GlobalId'
    const [label, setLabel] = useState<string>(id)
    const { data, loading, error } = useQuery(query, { 
        skip: !id, 
        variables: { filter: { [idProp]: id } },
    })
    useEffect(() => {
        if (!data) return
        let item = data[Object.keys(data)[0]]
        if (!item) return
        let label = `${prependLabel}${data[Object.keys(data)[0]][labelProperty]}${appendLabel}`
        setLabel(label)
    }, [data])
    if (loading) return <Loading />
    if (error) return <span>Kunde inte ladda</span>

    return (
        <div>
            <Helmet>
                <title>{label}</title>
            </Helmet>
            <span>{label}</span>
        </div>
    )


}


export default DynamicCrumb