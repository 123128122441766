import React, { useEffect, useState } from 'react'
import { Queries } from '../../queries/query'
import { RentalLinesCalculatedQueryVariables, CustomerProject, RentalLine, FilterRentalLineInput, AggregateRentalLineInput } from '../../types'
import { asMoney } from '../../utils'
import Link from '../generic/Link'
import SearchableTable from '../table/SearchableTable'
import notFound from '../generic/no-img.png';
import moment from 'moment'
import { AiOutlineWarning, AiFillTags } from "react-icons/ai";
import { colors } from '../generic/colors'
import * as en from 'linq'
import { Button, Modal } from 'react-bootstrap'
import EmailOnRent from './EmailOnRent'
import ProjectTags from './RentallineTags'
import CheckboxToggle from '../generic/CheckboxToggle'
import { MdDateRange } from 'react-icons/md'
import { RiFilter2Fill } from 'react-icons/ri'
import ReturnDateHandler from './ReturnDateHandler'
import RentallineTags from './RentallineTags'


const OnRentList = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {
    const [filter, setFilter] = useState<FilterRentalLineInput>({})
    const [activeTags, setActiveTags] = useState<string[]>([])
    const [aggregated, setAggregated] = useState<boolean>(true)

    useEffect(() => {
        setFilter({
            DomainId: domainId,
            ProjectId: customerProject.ProjectId,
            HasTags: activeTags.length > 0 ? activeTags : null
        })

    }, [customerProject, activeTags])


    const getLink = (line: RentalLine) => `/rent/${line.GroupOnNumber}`

    return (
        <div>
            <SearchableTable
                domainId={customerProject.DomainId}
                withSelectedRows={({ rows }) => (
                    <div className="selected-rows-controls">
                        <ReturnDateHandler domainId={domainId} rentalLines={rows.map(x => x.original)} />
                        <RentallineTags domainId={domainId} rentalLines={rows.map(x => x.original)} />
                    </div>
                )}
                disablePagination
                disableSearch
                enableStaticSorting
                title={'På hyra'}
                filter={filter}
                cols={[
                    {
                        Header: 'Beskrivning',
                        accessor: 'Description',
                        Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                            return (
                                <div className="description">
                                    <img src={original.ImageUrl || notFound} />
                                    <div>
                                        <div className="title">
                                            <Link type="inline" to={getLink(original)}>{original.Description}</Link>
                                        </div>
                                        <div className="subtitle">
                                            <div className="tags">
                                                {original.Tags && original.Tags.map((t, i) => <span className="tag" onClick={() => setActiveTags(old => [...old, t])} key={i}>{t}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        },
                    },
                    {
                        Header: 'Antal',
                        accessor: 'Quantity',
                        Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                            return <Link type="inline" to={getLink(original)}>{original.Quantity}</Link>
                        },
                    },
                    {
                        Header: 'Pris/dag',
                        accessor: 'DayNetPriceEdge',
                        Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                            return <Link type="inline" to={getLink(original)}>{asMoney(original.DayNetPriceEdge)}</Link>
                        },
                    },
                    {
                        Header: 'Returdatum',
                        accessor: 'UserExpectedReturnDate',
                        Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                            let expired = original.UserExpectedReturnDate && moment(original.UserExpectedReturnDate).isBefore(new Date())
                            return (
                                <div className="returndate">
                                    <Link type="inline" to={getLink(original)}><span className={expired ? 'expired' : ''}>{original.UserExpectedReturnDate ? moment(original.UserExpectedReturnDate).format('YYYY-MM-DD') : null}</span></Link>
                                    {expired && <AiOutlineWarning color={colors.errorColor} />}
                                </div>)
                        },
                    }
                ]}
                queryVariables={{
                    calculationType: 'onRent',
                    aggregate: aggregated ? AggregateRentalLineInput.GroupOnNumber : null
                } as RentalLinesCalculatedQueryVariables}
                query={Queries.rentalLinesCalculated}
            >
                {activeTags.length > 0 && (
                    <div className="tag-filter">
                        <p>Visar rader med tags: </p>
                        {en.from(activeTags).distinct().toArray().map((t, i) => <span className="tag" onClick={() => setActiveTags(en.from(activeTags).where(x => x !== t).toArray())} key={i}>{t}</span>)}
                    </div>
                )}

                <EmailOnRent domainId={domainId} customerProject={customerProject} />

                <CheckboxToggle
                    value={aggregated}
                    onChange={setAggregated}
                    label={'Visa som aggregerad lista'}
                />

            </SearchableTable>


        </div>
    )

}

const FilterOnObjectType = ({
    rentalLines
}: {
    rentalLines?: RentalLine[]
}) => {

    const [open, setOpen] = useState<boolean>(false)
    const requestClose = () => setOpen(false)

    return (
        <div>
            <button onClick={() => setOpen(true)} className="rounded-button purple"><span style={{ marginRight: '10px' }}>Filtrera på maskintyp</span><RiFilter2Fill size={20} color={'white'} /></button>
            <Modal animation={false} show={open} onHide={requestClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: colors.primary.main.color }}>Maila hyreslista som excelfil</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>body</p>



                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={requestClose}>
                        Stäng
                    </Button>

                    {/* <Button disabled={loading || emails.length < 1} variant="success" onClick={send}>
                        Skicka
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OnRentList