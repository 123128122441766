import React from 'react'
import ToggleButton from 'react-toggle-button'
import './index.scss'

const CheckboxToggle = ({ label, value, onChange }: { label: string, value: boolean, onChange: (val: boolean) => void }) => {
    return (
        <div className="checkbox-toggle">
            <span>{label}</span>
            <ToggleButton
                value={value}
                onToggle={val => onChange(!val)} />

        </div>
    )
}

export default CheckboxToggle