import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import phone from './navet_edge_bild_1-605x1024.png'
import './index.scss'

const StartPageJumbotron = () => {
    return (
        <div>
            <div id="main" className="invitation-background">
                <div id="overlay"></div>
            </div>
            <div id="main-header-content">
                <Container className="jumbotron">
                    <Row>
                        <Col className="highlight">
                            <h1>Välkommen till NavetEdge</h1>
                            <h4>Hyrappen tillhandahåller och samlar information om dina byggprojekt, så att du kan visualisera, hantera och granska projekt.</h4>
                        </Col>
                        <Col xs={12} md={6} className="image">
                            <img src={phone} alt="NavetEdge i din hand" className="main-image" />
                        </Col>
                    </Row>
                </Container>
                <div id="bottom-shape">
                    <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"></path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default StartPageJumbotron