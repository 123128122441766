import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../auth/authContext'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BearerButton = () => {
    const [token, setToken] = useState<string>()
    const auth = useContext(AuthContext)
    const [copied, setCopied] = useState(false)

    const copy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    }

    useEffect(() => {
        getToken()
    }, [auth])

    const getToken = async () => {
        let token = await auth.getJwtToken()
        setToken(token)
    }

    if (auth.authenticationState === 'Unauthenticated') return null



    return (
        <div style={{ cursor: 'pointer' }}>
            {copied ? <span style={{ color: 'green' }} > Kopierat</span > : (
                <div>
                    <CopyToClipboard text={`{"Authorization": "Bearer ${token}"}`}
                        onCopy={copy}>
                        <span>Kopiera api token</span>
                    </CopyToClipboard>
                </div>
            )}
        </div>)
}

export default BearerButton