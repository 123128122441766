import React, { useState } from "react"
import { Button, Container } from "react-bootstrap"

export const DevSettings = () => {

    const [graphqlendpoint, setgraphqlendpoint] = useState(localStorage.getItem('graphqlendpoint') || 'https://edge-api-staging.azurewebsites.net/')
    const [wsendpoint, setwsendpoint] = useState(localStorage.getItem('wsendpoint') || 'wss://edge-api-staging.azurewebsites.net/graphql')

    if (window.location.hostname !== 'localhost') return null

    const save = () => {
        localStorage.setItem('graphqlendpoint', graphqlendpoint)
        localStorage.setItem('wsendpoint', wsendpoint)

        window.location.reload();
    }


    const reset = () => {
        localStorage.removeItem('graphqlendpoint')
        localStorage.removeItem('wsendpoint')

        window.location.reload();
    }

    const localhost = () => {
        localStorage.setItem('graphqlendpoint', 'http://localhost:4000/')
        localStorage.setItem('wsendpoint', 'ws://localhost:4000/')

        window.location.reload();
    }

    return (
        <Container>
            <h3>Här kan du ställa in inställningar för din utvecklingsmiljö</h3>

            <Button variant={'dark'} onClick={localhost}>Använd localhost</Button>
            <Button variant={'dark'} onClick={reset}>Använd rentalainen</Button>

            <div>
                <b>graphqlendpoint</b><br />
                <input type="text" style={{ width: '400px' }} value={graphqlendpoint} onChange={(e) => setgraphqlendpoint(e.target.value)} />
            </div>
            <p />
            <div>
                <b>wsendpoint</b><br />
                <input type="text" style={{ width: '400px' }} value={wsendpoint} onChange={(e) => setwsendpoint(e.target.value)} />
            </div>


            <Button onClick={save}>Spara</Button>

        </Container>
    )
}