import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { MdDateRange } from 'react-icons/md'
import { RentalLine, UpsertRentalLineMutation, UpsertRentalLineMutationVariables } from '../../types'
import { colors } from '../generic/colors'
import { useRentalLinesFromGroups } from '../hooks/rentHooks'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as en from 'linq'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { Mutations } from '../../queries/mutation'
import Loading from '../generic/Loading'

const ReturnDateHandler = ({
    domainId,
    rentalLine,
    rentalLines
}: {
    domainId: string,
    rentalLine?: RentalLine,
    rentalLines?: RentalLine[]
}) => {
    const [returnDate, setReturnDate] = useState<Date>(moment().add(1, 'week').toDate())
    const [ProjectId, setProjectId] = useState<string>()
    const [allLinesInSelection, setAllLinesInSelection] = useState<RentalLine[]>([])
    const groupquery = useRentalLinesFromGroups({ rentalLines, ProjectId, skip: !!rentalLine })
    const [upsertRentalLine, { loading, error }] = useMutation<UpsertRentalLineMutation, UpsertRentalLineMutationVariables>(Mutations.upsertRentalLine, { 
        refetchQueries: ['rentalLinesCalculated'],
        context: { xDomainId: domainId },
    })
    
    useEffect(() => {
        if (!groupquery || !groupquery.rentalLines) return
        setAllLinesInSelection(groupquery.rentalLines)
    }, [groupquery])

    useEffect(() => {
        if (rentalLines && rentalLines.length > 0) {
            let first = rentalLines[0]
            setProjectId(first.ProjectId)

            if (en.from(rentalLines).all(x => moment(x.UserExpectedReturnDate).isSame(moment(first.UserExpectedReturnDate))))
                setReturnDate(moment(first.UserExpectedReturnDate).toDate())
        } else {
            setProjectId(null)
        }
    }, [rentalLines])

    const save = async () => {

        await Promise.all(allLinesInSelection.map(line => new Promise<void>(async (resolve) => {
            await upsertRentalLine({
                variables: {
                    record: {
                        GlobalId: line.GlobalId,
                        UserExpectedReturnDate: returnDate
                    }
                },
                context: { xDomainId: line.DomainId },
            })
            resolve()
        })))

        requestClose()
    }

    const [open, setOpen] = useState<boolean>(false)
    const requestClose = () => setOpen(false)

    return (
        <div>
            <button onClick={() => setOpen(true)} className="rounded-button dark-gray"><span style={{ marginRight: '10px' }}>Sätt förväntat returdatum</span><MdDateRange size={20} color='white' /></button>

            <Modal animation={false} show={open} onHide={requestClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: colors.primary.main.color }}>Ange förväntat returdatum</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <DatePicker selected={returnDate} onChange={(date: Date) => setReturnDate(date)} />


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={requestClose}>
                        Stäng
                    </Button>

                    <Button disabled={loading || rentalLines.length < 1} variant="success" onClick={save}>
                        Spara
                        {loading && <Loading button />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReturnDateHandler