import React, { useState, useCallback, useEffect } from 'react'
import { Card, CardGroup, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import './index.scss'
import { useDropzone } from 'react-dropzone'
import { useMutation, useQuery } from '@apollo/client'
import { Queries } from '../../queries/query'
import Loading from '../generic/Loading'
import Error from '../generic/Error'
import DetailsComponent from '../generic/DetailsComponent'
import { Mutations } from '../../queries/mutation'
import Link from '../generic/Link'
import { colors } from '../generic/colors'
import ToggleReservation from './ToggleReservation'

const ObjectTypeList = () => {
    const { domainId, objectFamilyId, objectGroupId } = useParams<{ domainId: string, objectFamilyId: string, objectGroupId: string }>()
    const [headline, setHeadline] = useState<string>('Maskinkatalog')
    const [familyCode, setFamilyCode] = useState<string>(null)
    const { data, loading, error } = useQuery(Queries.objectTypesMany, { 
        skip: !objectGroupId, 
        variables: { filter: { DomainId: domainId, ObjectGroup: objectGroupId } },
        context: { xDomainId: domainId },
    })
    const familystate = useQuery(Queries.objectFamiliesMany, { 
        variables: { filter: { DomainId: domainId, GlobalId: objectFamilyId } },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (familystate && familystate.data && familystate.data.objectFamiliesMany) {
            setHeadline(`Maskinkatalog - ${familystate.data.objectFamiliesMany[0].Family}`)
            setFamilyCode(familystate.data.objectFamiliesMany[0].Family)
        } else {
            setHeadline('Maskinkatalog')
            setFamilyCode(null)

        }
    }, [familystate])

    if (!domainId) return <Error error='the domain Id cannot be null' />
    
    if (loading || !objectGroupId) return <Loading />
    if (error) return <Error error={error} />

    const { objectTypesMany } = data

    return (
        <div>
            <DetailsComponent
                hideTitleOnSmallScreen
                DomainId={domainId}
                Headline={headline}
            />

            <Container>

                <CardGroup className="supplier-boxes">
                    <Card text="primary" className="shadow">
                        <Card.Body>
                            {objectTypesMany.map((item, index) => <ObjectTypeImage objectType={item} key={index} />)}
                        </Card.Body>
                    </Card>
                </CardGroup>


            </Container>
        </div>
    )
}

export const ObjectTypeImage = ({ objectType }: { objectType: any }) => {
    const [image, setImage] = useState(objectType.PictureURL)
    const [save, { loading, error }] = useMutation(Mutations.changeObjectTypeImage, {
        context: { xDomainId: objectType.DomainId },
    })

    useEffect(() => {
        setImage(objectType.PictureURL)
    }, [objectType])

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)

        var file = acceptedFiles[0]
        const reader = new FileReader();
        reader.onload = async (event) => {
            let base64 = event.target.result

            await save({
                variables: {
                    record: {
                        GlobalId: objectType.GlobalId,
                        DomainImageBase64: base64,
                        DomainImageBase64Name: file.name
                    }
                }
            })
        };
        reader.readAsDataURL(file);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    if (error) return <Error error={error} />

    return (

        <div className="catalog-item">
            <div style={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
            }}>
                <Link type="inline" to={`/${objectType.GlobalId}`}><h1>{objectType.Description}</h1></Link>
                <ToggleReservation objectType={objectType} />
            </div>
            <Link type="inline" to={`/${objectType.GlobalId}`}>
                <div {...getRootProps()} style={{ padding: "10px", cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    {loading && <Loading />}
                    {isDragActive && <p>Släpp bilden här</p>}
                    {image ? <img src={image} alt={objectType.Description} style={{width: '360px', height: '180px'}} /> : <div style={{
                        border: "1px solid gray",
                        width: '360px',
                        height: '180px',
                        backgroundColor: colors.scale.mid,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>Bild saknas</div>}
                </div>
            </Link>
        </div>
    )
}

export default ObjectTypeList