import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import { DocumentQuery, DocumentQueryVariables, InvoiceHeader, InvoiceHeaderQuery, InvoiceHeaderQueryVariables, StoredFile } from '../../types'
import DetailsComponent from '../generic/DetailsComponent'
import Loading from '../generic/Loading'
import moment from 'moment'
import { asMoney } from '../../utils'
import * as en from 'linq'
import Error from '../generic/Error'
import { Link } from 'react-router-dom'

const InvoiceCard = () => {
    const { domainId, invoiceId } = useParams<{ domainId: string, invoiceId: string }>()
    const [invoice, setInvoice] = useState<InvoiceHeader>(null)
    const { data, loading, error } = useQuery<InvoiceHeaderQuery, InvoiceHeaderQueryVariables>(Queries.invoiceHeader, { 
        skip: !invoiceId, 
        variables: { 
            filter: { DomainId: domainId, GlobalId: invoiceId } 
        },
        context: { xDomainId: domainId },
    })
    useEffect(() => {
        if (!data || !data.invoiceHeader) {
            setInvoice(null)
            return
        }

        setInvoice(data.invoiceHeader)
    })

    if (!domainId) return <Error error='the domain Id cannot be null' />

    return (
        <Container>
            {loading && <Loading />}
            {invoice && <DetailsComponent DomainId={invoice.DomainId} Headline={`Faktura ${invoice.No}`} sections={[
                {
                    data: [
                        { value: invoice.EQMCombineCustomerProj, title: 'Kundprojekt' },
                        { value: moment(invoice.DueDate).format('YYYY-MM-DD'), title: 'Förfallodatum' },
                        { value: asMoney(invoice.AmountIncludingVAT), title: 'Belopp' },
                        { value: invoice.Closed ? 'Ja' : 'Nej', title: 'Stängd' },
                    ], title: 'Information'
                },
                { data: [{ value: invoice.EQMCombineCustomerProj }], title: 'Kundprojekt' },
                { data: [{ value: moment(invoice.DueDate).format('YYYY-MM-DD') }], title: 'Förfallodatum' },
                { data: [{ value: asMoney(invoice.AmountIncludingVAT) }], title: 'Belopp' },
                { data: [{ value: invoice.Closed ? 'Ja' : 'Nej' }], title: 'Stängd' },
            ]}
            >
                <InvoiceFile domainId={domainId} invoice={invoice} />
            </DetailsComponent>}
        </Container>
    )
}

const InvoiceFile = ({ domainId, invoice }: { domainId: string, invoice: InvoiceHeader }) => {
    const { data, loading, error } = useQuery<DocumentQuery, DocumentQueryVariables>(Queries.document, { 
        variables: { 
            filter: { DomainId: domainId, TargetId: invoice.GlobalId } 
        },
        context: { xDomainId: domainId },
    })
    const [originalFile, setOriginalFile] = useState<StoredFile>()

    useEffect(() => {
        console.log(data)
        if (!data || !data.document) {
            setOriginalFile(null)
            return
        }
        let orignalfile = en.from(data.document.files).where(x => (x as any).name === data.document.OriginalFileName).firstOrDefault();
        setOriginalFile(orignalfile)

    }, [data])

    const open = () => {
        if (!originalFile) {
            alert('Kunde inte läsa in filen')
            return
        }

        // Linking.openURL(originalFile.link)
    }

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!originalFile) return null
    return (
        <a href={originalFile.link} target="_blank">
            <button className="rounded-button blue"
                onClick={open}>
                Ladda ner faktura som pdf
            </button>
        </a>
    )
}


export default InvoiceCard