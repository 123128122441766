import React, { useState, useCallback, useEffect } from 'react'
import { Card, CardGroup, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import './index.scss'
import { useDropzone } from 'react-dropzone'
import { useMutation, useQuery } from '@apollo/client'
import { Queries } from '../../queries/query'
import Loading from '../generic/Loading'
import Error from '../generic/Error'
import DetailsComponent from '../generic/DetailsComponent'
import { Mutations } from '../../queries/mutation'
import Link from '../generic/Link'
import { colors } from '../generic/colors'


const ObjectFamilyList = () => {
    const { domainId } = useParams<{ domainId: string }>()

    const { data, loading, error } = useQuery(Queries.objectFamiliesMany, { 
        variables: { filter: { DomainId: domainId } },
        context: { xDomainId: domainId },
    })
    
    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (loading) return <Loading />
    if (error) return <Error error={error} />

    const { objectFamiliesMany } = data

    return (
        <div>
            <DetailsComponent
                hideTitleOnSmallScreen
                DomainId={domainId}
                Headline={'Maskinkatalog'}
            />

            <Container>

                <CardGroup className="supplier-boxes">
                    <Card text="primary" className="shadow">
                        <Card.Body>
                            {objectFamiliesMany.map((family, index) => <Family family={family} key={index} />)}
                        </Card.Body>
                    </Card>
                </CardGroup>


            </Container>
        </div>
    )
}

const Family = ({ family }) => {
    const [image, setImage] = useState(family.ImgUrlSmall)
    const [save, { loading, error }] = useMutation(Mutations.changeObjectFamilyImage, {
        context: { xDomainId: family.DomainId },
    })

    useEffect(() => {
        setImage(family.ImgUrlSmall)
    }, [family])

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)

        var file = acceptedFiles[0]
        const reader = new FileReader();
        reader.onload = async (event) => {
            let base64 = event.target.result

            await save({
                variables: {
                    record: {
                        GlobalId: family.GlobalId,
                        DomainImageBase64: base64,
                        DomainImageBase64Name: file.name
                    }
                }
            })
        };
        reader.readAsDataURL(file);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    if (error) return <Error error={error} />

    return (
        <Link type="inline" to={`/${family.GlobalId}`}>
            <div className="catalog-item">
                {family.Family}
                <div {...getRootProps()} style={{ padding: "10px", cursor: 'pointer' }}>
                    <input {...getInputProps()} />
                    {loading && <Loading />}
                    {isDragActive && <p>Släpp bilden här</p>}
                    {image ? <img src={image} alt={family.Family} style={{width: '180px', height: '180px'}} /> : <div style={{
                        border: "1px solid gray",
                        width: '180px',
                        height: '180px',
                        backgroundColor: colors.scale.mid,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>Bild saknas</div>}
                </div>
            </div>
        </Link>
    )
}

export default ObjectFamilyList