import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import AuthContext from '../../auth/authContext'
import { Queries } from '../../queries/query'
import './index.scss'

import chevron from './chevron.png'
import googleplay from './google-play-badge.png'
import appStore from './app-store-badge.png'

const MainPage = () => {
    const { data, error, loading } = useQuery(Queries.me)
    const authstate = useContext(AuthContext)

    return (
        <Container>
            <Row>
                <Col>
                    <Row>
                        <h2>Enkel och smart hantering av dina byggprojekt på ett och samma ställe</h2>
                        <h3>Hyrappen gör det enkelt för dig att effektivisera och kontrollera pågående projekt. Samla all din data och prata med andra system på ett och samma ställe samtidigt som du sparar både tid och pengar. </h3>
                    </Row>
                    <Row className="img-points">
                        <Col xs={12} md={4}>
                            <img width="1091" height="1616" src="https://navet.se/wp-content/uploads/2020/06/NavetEdge_front2_smal-2.png" className="attachment-full size-full" alt="" sizes="(max-width: 1091px) 100vw, 1091px" />
                        </Col>
                        <Col xs={12} md={8}>
                            <ul className="elementor-icon-list-items">
                                <li className="elementor-icon-list-item">
                                    <img src={chevron} alt='' className="chevron" />
                                    <span className="elementor-icon-list-text">Se material, uthyrningar och bokningar från alla leverantörer i ett gränssnitt.</span>
                                </li>
                                <li className="elementor-icon-list-item">
                                    <img src={chevron} alt='' className="chevron" />
                                    <span className="elementor-icon-list-text">Ta del av statistik, leveranser, returer, förfakturor och fakturor på ett och samma ställe.</span>
                                </li>
                                <li className="elementor-icon-list-item">
                                    <img src={chevron} alt='' className="chevron" />
                                    <span className="elementor-icon-list-text">Se ditt maskinbehov och planera dina egna och projektets kostnader.</span>
                                </li>
                                <li className="elementor-icon-list-item">
                                    <img src={chevron} alt='' className="chevron" />
                                    <span className="elementor-icon-list-text">Se all kontaktinformation och kommentera direkt in i dina leverantörers system.</span>
                                </li>
                                <li className="elementor-icon-list-item">
                                    <img src={chevron} alt='' className="chevron" />
                                    <span className="elementor-icon-list-text">Bli påmind och få pushnotiser vid förändringar så att du kan fokusera på rätt saker.</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Byggprojekt och arbetsplatser involverar ofta många personer, flera företag och ett stort antal maskiner. Det leder till komplexitet och svårigheter till att få överblick över hela projektet, vilket driver kostnader och sänker produktiviteten. Med NavetEdges Hyrapp ges möjlighet till att få en överblick över just hela projektet. Exempelvis går det att både se vilka maskiner som är på hyra i realtid men även vilka maskiner som är bokade och som kommer att levereras framöver. Därmed ges en tydlig överblick och trovärdig prognos över hur kostnader fördelas och insikter kring hur dessa kan minskas.
                            </Col>
                    </Row>


                </Col>
                <Col xs={12} lg="3">
                    <h3>Ladda hem NavetEdge till din surfplatta eller mobil</h3>
                    <ul className="stores">
                        <li>
                            <a href={'https://play.google.com/store/apps/details?id=se.navet.edge&hl=sv&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'}><img className="android" alt='Ladda ned på Google Play' src={googleplay} /></a>
                        </li>

                        <li>
                            <a href={'https://apps.apple.com/se/app/navet-edge/id1459514644'}><img className="ios" alt='Ladda ned på AppStore' src={appStore} /></a>
                        </li>
                    </ul>

                    <h3>Kontakt</h3>
                    <div className="contact">
                        <img src="https://navet.se/wp-content/uploads/2020/05/Johannes_Lunner_322-150x150.png" alt="Johannes Lunner" />
                        <div className="details">
                            <a href="https://navet.se/coworker/johannes-lunner/" target="_blank"><h6>Johannes Lunner</h6></a>
                            <p>Teamleader Projekt</p>
                            <span>NAVET Affärssystem</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default MainPage