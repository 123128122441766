import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation } from 'react-router';
import './index.scss'

export const Link = ({ to, children, useAbsoluteLink, target, type = 'header', ellipsis }: {
    to: string
    children: any
    useAbsoluteLink?: boolean,
    target?: string
    type?: 'header' | 'inline' | 'block' | 'table'
    ellipsis?: boolean
}) => {

    const location = useLocation();
    let url = useAbsoluteLink ? to : `${location.pathname}${to}`

    if (type === 'header') return (
        <h1 className="link"><RouterLink to={to} className="effect-underline">{children}</RouterLink></h1>
    )
    else if (type === 'block') return (
        <div className="link"><RouterLink target={target} to={url}>{children}</RouterLink></div>
    )

    else if (type === 'inline' && target !== '_blank' && !ellipsis) return (
        <span className="link inline"><RouterLink target={target} to={url}>{children}</RouterLink></span>
    )

    else if (type === 'inline' && target !== '_blank' && ellipsis) return (
        <span className="link inline ellipsis"><RouterLink target={target} to={url}>{children}</RouterLink></span>
    )

    else if (type === 'inline' && target === '_blank' && !ellipsis) return (
        <span className="link inline"><a href={url} target={target}>{children}</a></span>
    )

    else if (type === 'inline' && target === '_blank' && ellipsis) return (
        <span className="link inline ellipsis"><a href={url} target={target}>{children}</a></span>
    )

    else if (type === 'table') return (
        <span className="link table"><RouterLink target={target} to={url}>{children}</RouterLink></span>
    )
}



export default Link