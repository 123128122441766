import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import React, { useContext, useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { useParams, useLocation } from 'react-router'
import AuthContext from '../../auth/authContext'
import { Mutations } from '../../queries/mutation'
import { Queries } from '../../queries/query'
import { MyInvitationQueryVariables, MyInvitationQuery, InvitationLimited, AcceptDomainInvitationMutation, AcceptDomainInvitationMutationVariables, AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables, AcceptInivitationResponse } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { useMe } from '../hooks/permissionHook'
import googleplay from '../../images/google-play-badge.png'
import appStore from '../../images/app-store-badge.png'
import testflight from '../../images/test-flight-badge.png'
import android from '../../images/android-badge.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import { colors } from '../generic/colors'

const AcceptInvitation = ({ type }: { type: 'domain' | 'company' }) => {
    const authstate = useContext(AuthContext)
    const { domainId, invitationId } = useParams<{ domainId: string, invitationId: string }>()
    const { data, error, loading } = useQuery<MyInvitationQuery, MyInvitationQueryVariables>(Queries.myInvitation, {
        skip: !invitationId,
        variables: {
            code: invitationId
        },
        context: { xDomainId: domainId },
    })
    if (!domainId) return <Error error='the domain Id cannot be null' />
    if (loading) return <Loading />
    if (error) return <Error error={error} />
    const { myInvitation } = data
    if (!myInvitation) return <Error type="notFound" />

    return (
        <Container className="invitation-wrapper">
            <h2>Hej, och välkommen</h2>
            <span>Du har blivit inbjuden att använda NavetEdge som {myInvitation.CompanyName}</span>

            {authstate.authenticationState === 'Authenticated' && <Authed domainId={domainId} type={type} Code={invitationId} />}
            {authstate.authenticationState === 'Unauthenticated' && <UnAuthed login={authstate.login} />}

        </Container>
    )
}

const Authed = ({ domainId, Code, type }: { domainId: string, Code: string, type: 'domain' | 'company' }) => {
    const { me } = useMe()
    const client = useApolloClient()
    const [accepted, setAccepted] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    const [invitation, setInvitation] = useState<AcceptInivitationResponse>(null)

    useEffect(() => {
        if (!me) return
        if (me.UserInvitationId && me.UserInvitationId === Code)
            setAccepted(true)
        else
            setAccepted(false)
    }, [me])

    const [mutation, mutationstate] = useMutation<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>(Mutations.acceptCompanyInvitation, {
        context: { xDomainId: domainId }
    })
    const [domainmutation, domainmutationstate] = useMutation<AcceptDomainInvitationMutation, AcceptDomainInvitationMutationVariables>(Mutations.acceptDomainInvitation, {
        context: { xDomainId: domainId }
    })

    useEffect(() => {
        if (domainmutationstate.loading || mutationstate.loading)
            setLoading(true)
        else
            setLoading(false)

        if (domainmutationstate.error)
            setError(domainmutationstate.error)
        else if (mutationstate.error)
            setError(mutationstate.error)
        else
            setError(null)

    }, [domainmutationstate, mutationstate])

    const accept = async () => {
        console.log(client)

        if (type === 'company') {
            try {
                let response = await mutation({
                    variables: { Code },
                    update: (cache, res) => {

                        let user: any = cache.readQuery({ query: Queries.me })

                        let inv = res.data.acceptCompanyInvitation

                        cache.writeQuery({
                            query: Queries.me,
                            data: {
                                me: {
                                    ...user.me,
                                    DomainId: domainId,
                                    CustomerId: inv.CustomerId,
                                    CustomerName: inv.CustomerName,
                                    UserAcceptedInvitationOn: inv.UserAcceptedInvitationOn,
                                    UserInvitationId: inv.UserInvitationId,
                                    role: inv.role
                                }
                            }
                        })
                    }
                })
                setInvitation(response.data.acceptCompanyInvitation)
            } catch (e) { }
        } else {
            try {
                let response = await domainmutation({
                    variables: { Code },
                    update: (cache, res) => {

                        let user: any = cache.readQuery({ query: Queries.me })

                        let inv = res.data.acceptDomainInvitation

                        cache.writeQuery({
                            query: Queries.me,
                            data: {
                                me: {
                                    ...user.me,
                                    DomainId: inv.DomainId,
                                    CustomerName: inv.CustomerName,
                                    UserAcceptedInvitationOn: inv.UserAcceptedInvitationOn,
                                    UserInvitationId: inv.UserInvitationId,
                                    role: inv.role
                                }
                            }
                        })
                    }
                })
                setInvitation(response.data.acceptDomainInvitation)
            } catch (e) { }
        }
    }

    // if (me && me.UserInvitationId && me.UserInvitationId !== Code) return <div>Du har redan accepterat en inbjudan</div>

    const location = window.location.origin;

    return (
        <Container>
            {error && <Error error={error} type="mutation" />}

            {accepted ? (
                <div>
                    <div className="success">
                        Grattis, du har nu accpeterat inbjudan.
                    </div>

                    <Row>
                        <Col md="5" lg="5"><h3>Ladda hem NavetEdge till din surfplatta eller mobil</h3></Col>
                    </Row>
                    
                    <Row>
                        <Col md="5" lg="5">
                            <h3>Android</h3>
                            <p>

                            </p>
                            {(invitation && invitation.AndroidAppLink) && <a href={invitation.AndroidAppLink}><img className="ios" alt='Ladda ned android' src={android} /></a>}
                            {(!invitation || !invitation.AndroidAppLink) && <a href={'https://play.google.com/store/apps/details?id=se.navet.edge&hl=sv&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'}><img className="android" alt='Ladda ned på Google Play' src={googleplay} /></a>}
                        </Col>
                        <Col md="5" lg="5">
                            <h3>iOS</h3>

                            {(invitation && invitation.IOSAppLink) && <a href={invitation.IOSAppLink}><img className="ios" alt='Ladda ned från testflight' src={testflight} /></a>}
                            {(invitation && invitation.IOSAppCode) && (
                                <div>
                                    <p>Appen laddas ner genom att lösa in en kod i App Store</p>
                                    <p>Du kan trycka på länken nedan för att ladda ner appen, men om det inte fungerar så kopiera koden nedan, och lös in den i app store</p>
                                    <CopyAppCode code={invitation.IOSAppCode} />

                                    <a href={invitation.IOSAppCode}><img className="ios" alt='Hämta genom kod' src={appStore} /></a>
                                </div>
                            )}
                            {(!invitation || (!invitation.IOSAppLink && !invitation.IOSAppCode)) && <a href={'https://apps.apple.com/se/app/navet-edge/id1459514644'}><img className="ios" alt='Ladda ned på AppStore' src={appStore} /></a>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="10" lg="5">
                            <h3>Kontakt</h3>
                            <div className="contact">
                                <img src="https://navet.se/wp-content/uploads/2020/05/Johannes_Lunner_322-150x150.png" alt="Johannes Lunner" />
                                <div className="details">
                                    <a href="https://navet.se/coworker/johannes-lunner/" target="_blank"><h6>Johannes Lunner</h6></a>
                                    <p>Teamleader Projekt</p>
                                    <span>NAVET Affärssystem</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                    <button onClick={accept} className="rounded-button blue">Acceptera inbjudan</button>
                )}



        </Container>
    )
}

const UnAuthed = ({ login }) => {
    return (
        <div className="unauthed rounded-container shadow">
            <p><b>Börja med att logga in/skapa konto</b></p>
            <p>Du kan skapa ett nytt konto med användarnamn/lösenord</p>
            <p>Om din organisation har Office 365 kan du också använda detta för att logga in. Klicka i så fall på knappen "logga in med ditt arbetskonto" på nästa bild</p>
            <div>
                <button onClick={login} className="rounded-button blue">Logga in/skapa konto</button>
            </div>
        </div>
    )
}

const CopyAppCode = ({ code }: { code: string }) => {
    const [copied, setCopied] = useState<boolean>(false)
    const [cleanCode, setCleanCode] = useState<string>()

    useEffect(() => {
        setCleanCode(code.split('=')[1])
    }, [code])

    const copy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    }

    return (
        <div>
            {copied ? <span style={{ color: 'green' }} > Kopierat</span > : <b>{cleanCode}</b>}
            {' '}
            <CopyToClipboard text={cleanCode}
                onCopy={copy}>
                <MdContentCopy style={{ cursor: 'pointer' }} size={20} color={colors.primary.main.color} />
            </CopyToClipboard>
        </div>
    )
}

export default AcceptInvitation