export const colors = {
    background: '#f5f6f7',
    primary: {
        main: { name: 'Färg', color: '#00456d' },
        light: { name: 'Ljus', color: '#9aaebd' },
        dark: { name: 'Mörk', color: '#082221' },
        text: { name: 'Text', color: '#ffffff' },
    },
    secondary: {
        main: { name: 'Färg', color: '#00adc3' },
        light: { name: 'Ljus', color: '#9BE7FF' },
        dark: { name: 'Mörk', color: '#2286C3' },
        text: { name: 'Text', color: '#FFFFFF' }
    },
    other: {
        link: { name: 'Länk', color: '#4A90E2' }
    },
    tintColors: {
        lightGreen: { color: '#81c784', textColor: '#fff' },
        purple: { color: '#673ab7', textColor: '#fff' },
        orange: { color: '#ef6c00', textColor: '#fff3e0' },
        darkGreen: { color: '#1b5e20', textColor: '#fff' },
        teal: { color: '#009688', textColor: '#e0f2f1' },
        blueGrey: { color: '#607d8b', textColor: '#eceff1' },
    },
    scale: {
        low: '#636a6e',
        mid: '#375a6e',
        high: '#164d6e',
    },
    scaleSecondary: {
        low: '#aec0c2',
        mid: '#61b6c2',
        hight: '#27b0c2'
    },
    menu: '#9aaebd',
    footer: '#f6f6f6',
    errorColor: '#f98484',
    gray: '#f5f6f7',
    mobileTabColor: '#eeeeee'
}

