import React from 'react'
import { Container } from 'react-bootstrap'
import { Queries } from '../../queries/query'
import { CustomerDomain } from '../../types'
import SearchableTable from '../table/SearchableTable'
import Link from '../generic/Link'
import { Mutations } from '../../queries/mutation'
import { CreateNewCustomerDomainButton } from './CustomerDomainFormSimplona'

const CustomerDomainList = () => {
    return (
        <Container>
            <SearchableTable
                domainId=''
                deleteManyMutation={Mutations.removeCustomerDomainMany}
                query={Queries.customerDomainsPage}
                cols={[
                    {
                        Header: 'Namn',
                        Cell: ({ row: { original } }: { row: { original: CustomerDomain } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.Name}</Link>
                        },
                    },
                ]}
            >
                <CreateNewCustomerDomainButton />
            </SearchableTable>
        </Container>
    )
}


export default CustomerDomainList