import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { Queries } from "../../queries/query"
import { CalculateRentallineInputValues, FilterRentalLineInput, RentalLine, RentalLinesCalculatedQuery, RentalLinesCalculatedQueryVariables } from "../../types"

export const useRentalLinesFromGroups = ({
    rentalLines,
    ProjectId,
    skip,
}: {
    skip?: boolean,
    ProjectId: string
    rentalLines: RentalLine[]
}): {
    error?: any,
    loading?: boolean
    rentalLines?: RentalLine[],
    refetch: () => void,
    filter: any
} => {
    const [rentalLineGroups, setRentalLineGroups] = useState<RentalLine[]>([])
    const [filter, setFilter] = useState<FilterRentalLineInput>(null)
    const { data, loading, error, refetch } = useQuery<RentalLinesCalculatedQuery, RentalLinesCalculatedQueryVariables>(Queries.rentalLinesCalculated, {
        skip: skip || !filter,
        variables: {
            filter, calculationType: CalculateRentallineInputValues.OnRent
        }
    })

    useEffect(() => {
        if (!rentalLines || !ProjectId) {
            setFilter(null)
            return
        }
        setFilter({
            IN: rentalLines.map(x => ({ GroupOnNumber: x.GroupOnNumber })),
            ProjectId: ProjectId
        })
    }, [rentalLines, ProjectId])

    useEffect(() => {
        if (!data) return
        const { rentalLinesCalculated } = data
        if (!rentalLinesCalculated) setRentalLineGroups([])
        else {
            setRentalLineGroups(rentalLinesCalculated)
        }
    }, [data])

    return {
        loading,
        error,
        rentalLines: rentalLineGroups,
        refetch,
        filter
    }
}

export const useRentalLinesFromGroup = ({ rentalLine }): {
    error?: any,
    loading?: boolean
    rentalLines?: RentalLine[],
    refetch: () => void
} => {
    const [rentalLines, setRentalLines] = useState<RentalLine[]>([])
    const [filter, setFilter] = useState<FilterRentalLineInput>(null)
    const { data, loading, error, refetch } = useQuery<RentalLinesCalculatedQuery, RentalLinesCalculatedQueryVariables>(Queries.rentalLinesCalculated, {
        skip: !filter,
        variables: {
            filter, calculationType: CalculateRentallineInputValues.OnRent
        }
    })

    useEffect(() => {
        if (!rentalLine) {
            setFilter(null)
            return
        }
        setFilter({
            GroupOnNumber: rentalLine.GroupOnNumber,
            DomainId: rentalLine.DomainId,
            CustomerNo: rentalLine.CustomerNo,
            CustomerProject: rentalLine.CustomerProject
        })
    }, [rentalLine])

    console.log('filter', filter)

    useEffect(() => {
        if (!data) return
        const { rentalLinesCalculated } = data
        if (!rentalLinesCalculated) setRentalLines([])
        else {
            setRentalLines(rentalLinesCalculated)
        }
    }, [data])

    return {
        loading,
        error,
        rentalLines,
        refetch
    }
}