import { Card } from 'react-bootstrap'
import { roledefinitions } from '../../config'
import * as en from 'linq'
// import { useState, useEffect } from 'react'
import { useMe } from '../hooks/permissionHook'
// import imgConstruction from '../../images/constructions.png'
// import imgUsers from '../../images/users.png'
// import imgObjectList from '../../images/objects.jpg' 
import imgCustomerDomains from '../../images/customerDomains.jpg' 
import imgGetSupport from '../../images/get-support.jpg'
// import imgInvitation from '../../images/invitations.jpg' 
// import imgDashboard from '../../images/dashboard-alt-icon.png'

export type myPanlesPayload = {
    loading?: boolean
    error?: any
    panels: panel[]
}

export const useMyPanels = (): myPanlesPayload => {
    const { me, loading, error } = useMe()
    let panels = []

    if (me && me.role)
        panels = en.from(allPanels).where(x => en.from(x.roles).any(role => role === me.role)).toArray()

    return {
        loading,
        panels,
        error
    }
}

export type panel = {
    title: string,
    image: JSX.Element,
    text: string,
    url: string,
    roles: roledefinitions[]

}


const allPanels: panel[] = [
    // {
    //     title: "Användare",
    //     roles: [roledefinitions.globaladmin, roledefinitions.domainadmin, roledefinitions.customeradmin],
    //     image: <Card.Img variant="top" src={imgUsers} />, text: "Lägg till, ta bort och administra användare", url: "/users"
    // },
    {
        title: "Domäner",
        roles: [roledefinitions.globaladmin],
        image: <Card.Img variant="top" src={imgGetSupport} />, text: "Hantera allt som har med domäner att göra", url: "/domains"
    },
    // {
    //     title: "Inbjudningar",
    //     roles: [roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={ imgInvitation } />, text: "Hantera ibjudningar", url: "/manage-invitations"
    // },
    {
        title: "Kunddomäner",
        roles: [roledefinitions.globaladmin],
        image: <Card.Img variant="top" src={ imgCustomerDomains } />, text: "Hantera bland annat leverantörer. Gäller endast ej migrerade domäner.", url: "/customer-domains"
    },
    // {
    //     title: 'Maskinkatalog',
    //     roles: [roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={imgObjectList} />,
    //     text: 'Hantera bilder på objekttyper',
    //     url: '/object-list'
    // },
    // {
    //     title: 'Maskinhyra',
    //     roles: [roledefinitions.customeradmin, roledefinitions.customeruser, roledefinitions.domainuser, roledefinitions.domainadmin, roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={imgConstruction} />,
    //     text: 'Hantera bilder på objekttyper',
    //     url: '/on-rent'
    // },
    // {
    //     title: 'Övervakningsdashboard',
    //     roles: [roledefinitions.globaladmin],
    //     image: <Card.Img variant="top" src={ imgDashboard } />, text: 'Överblick över alla navkopplingar', url: '/heartbeat-overview'
    // }
]
