import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import { DocumentsManyQueryVariables, DocumentsManyQuery } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'

const AllImagesCard = () => {
    const { TargetId } = useParams<{ TargetId: string }>()

    const { data, loading, error } = useQuery<DocumentsManyQuery, DocumentsManyQueryVariables>(Queries.documentsMany, { skip: !TargetId, variables: { filter: { TargetId } } })


    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!TargetId) return <Error type="notFound" />

    const { documentsMany } = data

    if (documentsMany.length === 1 && documentsMany[0].Name.indexOf('.pdf') > -1) {
        try {
            window.location = documentsMany[0].image.link as any
        } catch (e) {
            return (
                <div>
                    Ett fel uppstod
                    <p>{JSON.stringify(e)}</p>
                </div>
            )
        }

    }


    return (
        <div className="image-container">
            {documentsMany.map((d, i) => (
                <div className="shadow" key={i}>
                    <h1>{d.Name}</h1>
                    <img className="shadow" src={d.image.link} alt={d.Name} />
                </div>
            ))}
        </div>
    )
}


export default AllImagesCard