import React, { Children, FunctionComponent } from 'react'
import { Container } from 'react-bootstrap'
import './index.scss'

const PageWrapper: FunctionComponent<{
    classname?: string
}> = ({
    children,
    classname

}) => {
        return (
            <div>
                <div className={classname}>
                    <div id="overlay-small"></div>
                </div>

                <div id="bottom-shape">
                    <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"></path>
                    </svg>
                </div>

                <div className="content">
                    <Container>
                        {children}
                    </Container>
                </div>
            </div>

        )
    }


export default PageWrapper