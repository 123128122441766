import gql from 'graphql-tag'
import { Fragments } from './fragments'


export const Queries = {
    me: gql`
        query me {
            me {
                ...me
            }
        }
        ${Fragments.me}
    `,
    documentsPage: gql` 
        query documentsPage($filter: FilterDocumentInput, $page: Int, $pageSize: Int, $sort: SortDocumentInput) {	
            documentsPage(filter:$filter, page:$page, pageSize:$pageSize, sort:$sort) {			
                pageData {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                    pageCount
                }
                records {
                    ...document  
                }              
            }
        }
        ${Fragments.document}
    `,
    document: gql`
        query document($filter: FilterDocumentInput) {	
            document(filter:$filter) {			                
                ...documentWithLink                                
            }
        }
        ${Fragments.documentWithLink}
    `,
    documentsMany: gql`
        query documentsMany($filter: FilterDocumentInput, $sort: SortDocumentInput, $skip: Int, $limit: Int) {	
            documentsMany(filter:$filter, sort:$sort, skip:$skip, limit:$limit) {			                
                ...documentWithLink                                
            }
        }
        ${Fragments.documentWithLink}
    `,

    rentalLine: gql` 
        query rentalLine($filter: FilterRentalLineInput ) {	
            rentalLine(filter:$filter) {	
                ...rentalLine
                customerProject{
                    YourReference
                    Name
                    ProjectNo				
                }		
            }
        }
        ${Fragments.rentalLine}
    `,

    usersPage: gql` 
        query usersPage($filter: FilterUserInput, $page: Int, $pageSize: Int, $sort: SortUserInput, $aggregate: AggregateUserInput) {	
            usersPage(filter:$filter, page:$page, pageSize:$pageSize, sort:$sort, aggregate: $aggregate) {			
                pageData {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                    pageCount
                }
                records {
                    ...user  
                }              
            }
        }
        ${Fragments.user}
    `,
    user: gql`
        query user($filter: FilterUserInput) {	
            user(filter:$filter) {			                
                ...user                                
            }
        }
        ${Fragments.user}
    `,
    usersMany: gql`
        query userssMany($filter: FilterUserInput, $skip: Int, $limit: Int, $sort:SortUserInput) {	
            usersMany(filter:$filter, skip:$skip, limit:$limit, sort:$sort) {			
                ...user            
            }
        }
        ${Fragments.user}
    `,
    domainsMany: gql`
        query domainsMany($filter: FilterDomainInput, $skip: Int, $limit: Int, $sort: SortDomainInput) {	
            domainsMany(filter:$filter, skip:$skip, limit:$limit, sort:$sort) {			
                ...domain                               
            }
        }
        ${Fragments.domain}
    `,
    domain: gql`
        query domain($filter: FilterDomainInput) {	
            domain(filter:$filter) {			
                ...domain                               
            }
        }
        ${Fragments.domain}
    `,
    domainsLimited: gql`
        query domainsLimited($filter: domainsLimitedInput) {	
            domainsLimited(filter:$filter) {			
                GlobalId
                Name
                ImageURL
                AlternativeName
                FlagImageUrl
                ReservationsEnabled
                ReturnReservationsEnabled
            }
        }
    `,
    domainsPage: gql`
        query domainsPage($filter: FilterDomainInput, $page: Int, $pageSize: Int, $sort: SortDomainInput, $aggregate: AggregateDomainInput) {	
            domainsPage(filter:$filter, page:$page, pageSize:$pageSize, sort:$sort, aggregate: $aggregate) {			
                pageData {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                    pageCount
                }
                records {
                    ...domain                               
                }
            }
        }
        ${Fragments.domain}
    `,
    domainIntegrationsMany: gql`
        query domainIntegrationsMany($filter: FilterDomainIntegrationInput, $skip: Int, $limit: Int, $sort:SortDomainIntegrationInput) {	
            domainIntegrationsMany(filter:$filter, skip:$skip, limit:$limit, sort:$sort) {			
                ...domainIntegration            
            }
        }
        ${Fragments.domainIntegration}
    `,
    domainIntegration: gql`
        query domainIntegration($filter: FilterDomainIntegrationInput) {	
            domainIntegration(filter:$filter) {			
                ...domainIntegration            
            }
        }
        ${Fragments.domainIntegration}
    `,
    customerDomainsPage: gql`
        query customerDomainsPage($filter: FilterCustomerDomainInput, $page: Int, $pageSize: Int, $sort: SortCustomerDomainInput, $aggregate: AggregateCustomerDomainInput) {	
            customerDomainsPage(filter:$filter, page:$page, pageSize:$pageSize, sort:$sort, aggregate: $aggregate) {			
                pageData {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                    pageCount
                }
                records {
                    ...customerDomain                               
                }
            }
        }
        ${Fragments.customerDomain}
    `,
    customerDomain: gql`
        query customerDomain($filter: FilterCustomerDomainInput) {	
            customerDomain(filter:$filter) {	
                ...customerDomain
            }
        }
        ${Fragments.customerDomain}
    `,
    company: gql`
        query company($filter: FilterCompanyInput) {	
            company(filter:$filter) {			
                ...company                               
            }
        }
        ${Fragments.company}
    `,
    companiesMany: gql`
        query companiesMany($filter: FilterCompanyInput, $skip: Int, $limit: Int, $sort: SortCompanyInput, $aggregate: AggregateCompanyInput) {	
            companiesMany(filter:$filter, skip:$skip, limit:$limit, sort:$sort, aggregate: $aggregate) {			
                ...company                               
            }
        }
        ${Fragments.company}
    `,
    customerProjectsMany: gql`
        query customerProjectsMany($filter: FilterCustomerProjectInput, $skip: Int, $limit: Int, $sort: SortCustomerProjectInput, $aggregate: AggregateCustomerProjectInput) {	
            customerProjectsMany(filter:$filter, skip:$skip, limit:$limit, sort:$sort, aggregate: $aggregate) {			
                ...customerProject                               
            }
        }
        ${Fragments.customerProject}
    `,
    customerProject: gql`
        query customerProject($filter: FilterCustomerProjectInput) {	
            customerProject(filter:$filter) {			
                ...customerProject                               
            }
        }
        ${Fragments.customerProject}
    `,
    customerProjectsPage: gql`
        query customerProjectsPage($filter: FilterCustomerProjectInput, $page: Int, $pageSize: Int, $sort: SortCustomerProjectInput, $aggregate: AggregateCustomerProjectInput) {	
            customerProjectsPage(filter:$filter, page:$page, pageSize:$pageSize, sort:$sort, aggregate: $aggregate) {			
                pageData {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                    pageCount
                }
                records {
                    ...customerProject                               
                }
            }
        }
        ${Fragments.customerProject}
    `,
    autocomplete: gql`
        query autocomplete($filter: AutocompleteInput) {	
            autocomplete(filter:$filter) {			
                text
                queryPlusText                             
            }
        }
    `,
    invitationsPage: gql` 
        query invitationsPage($filter: FilterInvitationInput, $page: Int, $pageSize: Int, $sort: SortInvitationInput, $aggregate: AggregateInvitationInput) {	
            invitationsPage(filter:$filter, page:$page, pageSize:$pageSize, sort:$sort, aggregate: $aggregate) {			
                pageData {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                    pageCount
                }
                records {
                    ...invitation  
                }              
            }
        }
        ${Fragments.invitation}
    `,
    invitation: gql` 
        query invitation($filter: FilterInvitationInput) {	
            invitation(filter:$filter) {			            
                ...invitation                            
            }
        }
        ${Fragments.invitation}
    `,
    myInvitation: gql` 
        query myInvitation($code: String) {	
            myInvitation(code:$code) {			            
                ...invitationLimited                            
            }
        }
        ${Fragments.invitationLimited}
    `,
    object: gql` 
        query object($filter: FilterObjectInput) {		
            object(filter:$filter) {			
                ...object
            }			
        }
        ${Fragments.object}		
    `,
    objectTypesMany: gql` 
        query objectTypesMany($filter: FilterObjectTypeInput, $skip: Int, $limit: Int) {		
            objectTypesMany(filter:$filter, skip:$skip, limit:$limit) {			
                ...objectType
            }			
        }
        ${Fragments.objectType}
    `,
    objectType: gql` 
        query objectType($filter: FilterObjectTypeInput) {		
            objectType(filter:$filter) {			
                ...objectType		
            }			
        }
        ${Fragments.objectType}
    `,
    objectFamiliesMany: gql` 
        query objectFamiliesMany($filter: FilterObjectFamilyInput, $skip: Int, $limit: Int, $sort: SortObjectFamilyInput ){
            objectFamiliesMany	(filter:$filter, skip:$skip, limit:$limit,  sort: $sort) {
               ...objectFamily
            }
		} 
        ${Fragments.objectFamily}
	`,

    objectFamily: gql` 
        query objectFamily($filter: FilterObjectFamilyInput ){
		    objectFamily	(filter:$filter) {
				...objectFamily
			}
		} 
        ${Fragments.objectFamily}
	`,

    objectGroupsMany: gql` 
        query objectGroupsMany($filter: FilterObjectGroupInput, $skip: Int, $limit: Int, $sort: SortObjectGroupInput ){
		    objectGroupsMany	(filter:$filter, skip:$skip, limit:$limit,  sort: $sort) {
			    ...objectGroup
			}
		}  
	    ${Fragments.objectGroup}
    `,

    objectGroup: gql` 
        query objectGroup($filter: FilterObjectGroupInput ){
		objectGroup	(filter:$filter) {
			...objectGroup
			}
		}  
	    ${Fragments.objectGroup}
    `,
    rentalLinesCalculated: gql` query rentalLinesCalculated($filter: FilterRentalLineInput, $skip: Int, $limit: Int, $calculationType: CalculateRentallineInputValues!, $sort: SortRentalLineInput, $aggregate:AggregateRentalLineInput ) {	
        rentalLinesCalculated(filter:$filter, skip:$skip, limit:$limit, sort: $sort,calculationType:$calculationType, aggregate: $aggregate) {			
            ...rentalLine
        }
    }
    ${Fragments.rentalLine}`,

    rentalLinesCalculatedWithProject: gql` query rentalLinesCalculatedWithProject($filter: FilterRentalLineInput, $skip: Int, $limit: Int, $calculationType: CalculateRentallineInputValues!, $sort: SortRentalLineInput, $aggregate:AggregateRentalLineInput ) {	
		rentalLinesCalculated(filter:$filter, skip:$skip, limit:$limit, sort: $sort,calculationType:$calculationType, aggregate: $aggregate) {			
			...rentalLine
			GroupCountReadOnly
			customerProject{
				YourReference
				Name
				ProjectNo				
			}	
		}
	}
	${Fragments.rentalLine}`,

    rentCost: gql`
        query rentCost($filter: RentCostFilter) {
            rentCost(filter:$filter) {	
                TotalAmount
                Lines {
                    StartDate
                    EndDate
                    Amount
                    PeriodName
                }
            }
        }
    `,

    accumulatedRentCost: gql`
        query accumulatedRentCost($filter: AccumulatedRentCostFilter) {
            accumulatedRentCost(filter:$filter) {	
                TotalAmount			
            }
        }
    `,

    rentalLineSnapshotsMany: gql` 
        query rentalLineSnapshotsMany($filter: FilterRentalLineSnapshotInput, $skip: Int, $limit: Int, $sort: SortRentalLineSnapshotInput, $aggregate:AggregateRentalLineSnapshotInput ) {	
            rentalLineSnapshotsMany(filter:$filter, skip:$skip, limit:$limit, sort: $sort,aggregate: $aggregate) {	
                SnapshotOn
                rentalLines{ ...rentalLine }		
            }
        }
        ${Fragments.rentalLine}
    `,

    invoiceHeadersMany: gql`
        query invoiceHeadersMany($filter: FilterInvoiceHeaderInput, $skip: Int, $limit: Int, $sort: SortInvoiceHeaderInput) {
            invoiceHeadersMany(filter:$filter, skip: $skip, limit: $limit, sort:$sort) {
                ...invoiceHeader
            }
        }
        ${Fragments.invoiceHeader}
    `,


    invoiceHeader: gql` 
		query invoiceHeader($filter: FilterInvoiceHeaderInput) {				
			invoiceHeader(filter: $filter) {
				...invoiceHeader
				invoiceLines {
					LineNo
					Type
					No
					Description
					Quantity
					EQMRentalQuantity
					EQMRentalDays
					LineAmount
					EQMRentalFromDate
					EQMRentalToDate
				}
			}		  
		}
		${Fragments.invoiceHeader}
	`,

    rentalEventsMany: gql` 
        query rentalEventsMany($filter: RentalEventFilterInput ){
            rentalEventsMany(filter:$filter) {
                reservationRequestLines {
                    ...reservationRequestLine
                }

                returnRequestLines {
                    ...returnRequestLine
                }

                returnLines {
                    ...rentalPostedCollLine
                }

                reservations {
                    ...rentalLine
                }

                shipmentLines {
                    ...rentalPostedShptLine
                }

            }
        }  
        ${Fragments.reservationRequestLine}
        ${Fragments.returnRequestLine}	
        ${Fragments.rentalPostedCollLine}	
        ${Fragments.rentalLine}
        ${Fragments.rentalPostedShptLine}
    `,
}