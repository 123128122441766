let env = 'dev'
switch (window.location.hostname) {
    case 'edge.navet.se':
        env = 'production'
        break;

    case 'rentalainen-portal-test.azurewebsites.net':
        env = 'test'
        break;

    default:
        break;

}

export const getEnv = () => env

const getcfg = () => {
    switch (env) {
        case 'production':
            return {
                graphqlendpoint: 'https://edge-api-prod.azurewebsites.net/',
                wsendpoint: 'wss://edge-api-prod.azurewebsites.net/graphql',
                linking: 'https://edge.navet.se/',
                cmsToken: 'fI2S_rMRBZUe5moMiOc8oPDP-pi4TfxdmFjFH86fwBQ',
                trackingId: 'G-WJQT0CGJH6'
            }

        case 'test':
            return {
                graphqlendpoint: 'https://edge-api-staging.azurewebsites.net/',
                wsendpoint: 'wss://edge-api-staging.azurewebsites.net/graphql',
                linking: `${window.location.protocol}//${window.location.host}/`,
                cmsToken: 'fI2S_rMRBZUe5moMiOc8oPDP-pi4TfxdmFjFH86fwBQ',
                trackingId: 'G-LZW4C4Q3VM'
            }

        default:
            return {
                graphqlendpoint: localStorage.getItem('graphqlendpoint') || 'http://localhost:8080/',
                wsendpoint: localStorage.getItem('wsendpoint') || 'ws://localhost:8080/graphql',
                linking: `${window.location.protocol}//${window.location.host}/`,
                cmsToken: 'fI2S_rMRBZUe5moMiOc8oPDP-pi4TfxdmFjFH86fwBQ',
                trackingId: null
            }
    }
}

export const cfg = getcfg()

export enum roledefinitions {
    globaladmin = 'globaladmin',
    serviceaccount = 'serviceaccount',
    domainadmin = 'domainadmin',
    domainuser = 'domainuser',
    customeradmin = 'customeradmin',
    customeruser = 'customeruser',
    anonymous = 'anonymous'
}
export const ReadableRoles = {
    globaladmin: 'Globaladmin',
    serviceaccount: 'Servicekonto',
    domainadmin: 'Domänadmin',
    domainuser: 'Domänanvändare',
    customeradmin: 'Kundadmin',
    customeruser: 'Användare',
    anonymous: 'Inte tilldelad'
}
export const getAuthLevel = (role) => {
    switch (role) {
        /*
        // previous role definitions
        case roledefinitions.anonymous: return -1
        case roledefinitions.customeruser: return 0
        case roledefinitions.customeradmin: return 1
        case roledefinitions.domainuser: return 2
        case roledefinitions.domainadmin: return 3
        case roledefinitions.serviceaccount: return 3
        case roledefinitions.globaladmin: return 4
        */

        case roledefinitions.anonymous: return -10
        case roledefinitions.customeruser: return 0
        case roledefinitions.customeradmin: return 10
        case roledefinitions.domainuser: return 20        
        case roledefinitions.serviceaccount: return 30
        case roledefinitions.domainadmin: return 35
        case roledefinitions.globaladmin: return 40
    }
}