import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { cfg, ReadableRoles } from '../../config'
import { Queries } from '../../queries/query'
import { InvitationQueryVariables, InvitationQuery, InvitationTypeEnum, Invitation, FilterUserInput, User, DomainQuery, DomainQueryVariables, CustomerProject, FilterCustomerProjectInput, DeactivateInvitationMutationVariables, DeactivateInvitationMutation } from '../../types'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import { colors } from '../generic/colors'
import SearchableTable from '../table/SearchableTable'
import Link from '../generic/Link'
import { Mutations } from '../../queries/mutation'

const InvitationCard = () => {
    const { domainId, invitationId } = useParams<{ domainId: string, invitationId: string }>()
    const [copied, setCopied] = useState<boolean>(false)
    const { data, loading, error } = useQuery<InvitationQuery, InvitationQueryVariables>(Queries.invitation, { 
        skip: !invitationId, 
        variables: { filter: { DomainId: domainId, GlobalId: invitationId } },
        context: { xDomainId: domainId },
    })
    
    if (!domainId) return <Error error='the domain Id cannot be null' />
    if (loading) return <Loading />
    if (error) return <Error error={error} />
    const { invitation } = data
    if (!invitation) return <Error type={'notFound'} />

    const link = `${cfg.linking}accept-invitation/${domainId}/${invitation.InvitationCode}`

    const copy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    }

    return (
        <Container>
            <div className="header">
                <h1>Inbjudan för {invitation.CustomerName}</h1>
                {!invitation.Deactivated && <DeactivateInvitationButton domainId={domainId} invitation={invitation} />}
            </div>
            {invitation.Deactivated && <div className="deactivated">Inbjdan är deaktiverad</div>}

            <p>
                <b>Typ:</b>
                {' '}
                {invitation.Type === InvitationTypeEnum.Company ? 'Företag' : 'Personlig'}
            </p>
            <p>
                <b>Kod:</b>
                {' '}
                {invitation.InvitationCode}
            </p>
            <p>
                <b>Inbjudningslänk:</b>
                {' '}
                <CopyToClipboard text={link}
                    onCopy={copy}>
                    <MdContentCopy style={{ cursor: 'pointer' }} size={20} color={colors.primary.main.color} />
                </CopyToClipboard>
                <br />
                {copied ? <span style={{ color: 'green' }} > Kopierat</span > : <span>{link}</span>}

            </p>
            <div>
                <b>Leverantör:</b>
                {' '}
                <Supplier domainId={domainId} />
            </div>

            <Projects domainId={domainId} invitation={invitation} />

            <InvitationUsers domainId={domainId} invitation={invitation} />

        </Container>
    )
}

const DeactivateInvitationButton = ({ domainId, invitation }: { domainId: string, invitation: Invitation }) => {
    const [mutation, { loading, error }] = useMutation<DeactivateInvitationMutation, DeactivateInvitationMutationVariables>(Mutations.deactivateInvitation, {
        context: { xDomainId: domainId },
    })
    const deactivate = async () => {
        try {
            await mutation({ variables: { code: invitation.InvitationCode } })
        } catch (e) { }
    }
    return (
        <div>
            {error && <Error error={error} type={'mutation'} />}
            <button onClick={deactivate} disabled={loading} className="rounded-button red">{loading && <Loading button />} <span>Inaktivera inbjudan</span></button>
        </div>
    )
}

const Supplier = ({ domainId }: { domainId: string }) => {
    const { data, loading, error } = useQuery<DomainQuery, DomainQueryVariables>(Queries.domain, { 
        skip: !domainId, 
        variables: { filter: { GlobalId: domainId }} ,
        context: { xDomainId: domainId },
    })
    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data || !data.domain) return <span>saknas</span>
    const { domain } = data
    return (
        <div className="domain">
            {domain.ImageURL ? <img src={domain.ImageURL} alt={domain.Name} /> : <span>{domain.Name}</span>}
        </div>
    )
}

const Projects = ({ domainId, invitation }: { domainId: string, invitation: Invitation }) => {
    return (
        <div className="project rounded-container shadow">
            <h3>Valda projekt</h3>
            {(invitation.ProjectNos && invitation.ProjectNos.length > 0) && <SearchableTable
                domainId={domainId}
                disableSearch
                query={Queries.customerProjectsMany}
                filter={{
                    IN: invitation.ProjectNos.map(x => ({ ProjectNo: x }))
                } as FilterCustomerProjectInput}
                cols={[
                    {
                        Header: 'ProjektNo',
                        Cell: ({ row: { original } }: { row: { original: CustomerProject } }) => {
                            return <Link type="inline" to={`/customer-project/${original.GlobalId}`}>{original.ProjectNo}</Link>
                        },
                    },
                ]}
            />}
        </div>
    )
}

const InvitationUsers = ({ domainId, invitation }: { domainId: string, invitation: Invitation }) => {
    return (
        <div className="rounded-container shadow">
            <h3>Användare som har accepterat inbjudan</h3>
            <SearchableTable
                domainId={domainId}
                disableSearch
                query={Queries.usersPage}
                filter={{
                    UserInvitationId: invitation.InvitationCode
                } as FilterUserInput}
                cols={[
                    {
                        Header: 'Namn',
                        Cell: ({ row: { original } }: { row: { original: User } }) => {
                            return <Link type="inline" to={`/user/${original.GlobalId}`}>{original.name}</Link>
                        },
                    },
                    {
                        Header: 'E-Post',
                        accessor: 'email',
                    },
                    {
                        Header: 'Roll',
                        Cell: ({ row: { original } }: { row: { original: User } }) => {
                            let role = ReadableRoles[original.role]
                            return <span>{role || ''}</span>
                        },
                    },
                ]}
            />
        </div>
    )
}

export default InvitationCard