import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { Mutations } from '../../queries/mutation'
import { Queries } from '../../queries/query'
import { CustomerDomain, UpsertCustomerDomainMutation, UpsertCustomerDomainMutationVariables } from '../../types'
import Form from '../Form'
import ErrorBox from '../generic/Error'

const CustomerDomainForm = ({ domainId, onRequestClose }: { domainId: string, onRequestClose: () => void }) => {
    const [mutation, { error, loading }] = useMutation<UpsertCustomerDomainMutation, UpsertCustomerDomainMutationVariables>(Mutations.upsertCustomerDomain, { 
        refetchQueries: ['customerDomainsPage'],
        context: { xDomainId: domainId },
    })

    // const client = useApolloClient()

    if (!domainId) return <ErrorBox error='the domain Id cannot be null' />

    const save = async (record: CustomerDomain) => {
        // let {data} = await client.query({
        //     query: Queries.company, 
        //     variables: { filter: { DomainId: domainId, RegistrationNo: record.RegistrationNo }},
        //     context: { xDomainId: domainId }
        // })

        // if (!data || !data.company?.Name)
        //     throw new Error(`Kunden med registrationsnr. ${record.RegistrationNo} finns inte i domän ${domainId}!`)

        await mutation({
            variables: {
                record: {
                    GlobalId: record.GlobalId || "",
                    DomainId: domainId,
                    DomainIds: [domainId],
                    Name: record.Name,
                    RegistrationNo: record.RegistrationNo,
                    CustomerId: record.RegistrationNo,
                    // Name: data.company.Name,
                    // InvoiceAddress: data.company.Address,
                    // InvoicePostalCode: data.company.PostCode,
                    // InvoiceCity: data.company.City,
                    // InvoiceNote: '',
                    // InvoiceEmail: data.company.EMail
                },
            },
            context: { xDomainId: domainId },
        }).catch(e => { console.error(e) })
    }

    return (
        <div className="shadow rounded-container">
            {error && <ErrorBox error={error} type="mutation" />}
            <Form
                loading={loading}
                onRequestClose={onRequestClose}
                fields={[
                    { name: 'Name', label: "Namn"},
                    { name: 'RegistrationNo', label: "Orgnummer", },
                    { name: 'DomainId', label: 'Domain-Id', hidden: true}
                ]}
                onSubmit={save}
            />
        </div>
    )
}

export const CreateNewCustomerDomainButton = ({domainId}: {domainId: string}) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <div style={{ width: '100%' }}>
            <div className="full-width-controls">
                <div className="rounded-button-wrapper" onClick={() => setOpen(old => !old)}>
                    <div className="rounded-button blue">
                        Lägg till kunddomän
                    </div>
                </div>
            </div>
            <Collapsible open={open} trigger={<div></div>}>
                <CustomerDomainForm domainId={domainId} onRequestClose={() => setOpen(false)} />
            </Collapsible>
        </div>
    )
}

export default CustomerDomainForm