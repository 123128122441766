import React, { useCallback, useState } from 'react'
import { Invitation, UploadIosAppCodeFileMutation, UploadIosAppCodeFileMutationVariables, UploadAppCodesResponse } from '../../types'
import { useDropzone } from 'react-dropzone'
import { Mutations } from '../../queries/mutation'
import { useMutation } from '@apollo/client'
import Error from '../generic/Error'
import Loading from '../generic/Loading'


const UploadIOSAppCodeFile = ({ invitation }: { invitation: Invitation }) => {
    const [save, { loading, error }] = useMutation<UploadIosAppCodeFileMutation, UploadIosAppCodeFileMutationVariables>(Mutations.uploadIOSAppCodeFile)
    const [response, setResponse] = useState<UploadAppCodesResponse>(null)

    const onDrop = useCallback(acceptedFiles => {
        setResponse(null)

        var file = acceptedFiles[0]
        const reader = new FileReader();
        reader.onload = async (event) => {
            let base64 = event.target.result.toString()

            try {
                let res = await save({
                    variables: {
                        InvitationCode: invitation.InvitationCode,
                        file: base64
                    },
                    update: (cache, { data: { uploadIOSAppCodeFile } }) => {
                        const id = cache.identify(invitation)

                        cache.modify({
                            id,
                            fields: {
                                IOSAppCodeCount(value: number) {
                                    return value + uploadIOSAppCodeFile.newCodesAdded
                                }
                            }
                        })

                    }
                })

                setResponse(res.data.uploadIOSAppCodeFile)

            } catch (e) { }
        };
        reader.readAsDataURL(file);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div>
            {error && <Error error={error} />}
            <div {...getRootProps()} style={{ padding: "10px", cursor: 'pointer', border: '1px solid #ccc', margin: '10px', borderRadius: '10px' }}>
                <input {...getInputProps()} />
                {loading && <Loading />}
            Släpp fil här med ios appkoder för att lägga till.
            <p>Tänk på att du måste spara filen som .xlsx först</p>
            </div>
            <p><b>Tillgängliga koder för ios:</b> {invitation.IOSAppCodeCount} st</p>
        </div>
    )
}



export default UploadIOSAppCodeFile