import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import Error from '../generic/Error'
import { Queries } from '../../queries/query'
import DetailsComponent from '../generic/DetailsComponent'
import { Object, ObjectQuery, ObjectQueryVariables } from '../../types'


const ObjectCard = ({ passedObject }: { passedObject?: Object }) => {
    let { domainId, objectId } = useParams<{ domainId: string, objectId: string }>()
    const printref = useRef(null);

    const [object, setObject] = useState<Object>()
    const { data, error, loading } = useQuery<ObjectQuery, ObjectQueryVariables>(Queries.object, { 
        skip: !!passedObject, 
        variables: { filter: { DomainId: domainId, GlobalId: objectId }},
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (passedObject) setObject(passedObject)
    }, [passedObject])

    useEffect(() => {
        if (data && data.object) setObject(data.object)
    }, [data])

    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (loading || !object) return <span>loading...</span>
    if (error) return <div style={{ backgroundColor: 'red' }}>{error.message}</div>




    return (
        <div>
            <DetailsComponent
                DomainId={object.DomainId}
                Headline={object.Description}
                renderQRcode={true}
                sections={[
                    {
                        title: "Information", data: [
                            { title: "Nummer", value: object.ObjectNo },
                            { title: "Tillverkare", value: object.ManufacturerCode },
                            { title: "Modell", value: object.ManufacturerModelCode },
                            { title: "Typ", value: object.ObjectType },
                        ]
                    },
                    {
                        title: "Mått", data: [
                            { title: "Längd", value: `${object.Length} ${object.LengthMeasure || ''}` },
                            { title: "Bredd", value: `${object.Width} ${object.WidthMeasure || ''}` },
                            { title: "Höjd", value: `${object.Height} ${object.HeightMeasure || ''}` },
                        ]
                    }
                ]}
            />
        </div >
    )
}

export default ObjectCard