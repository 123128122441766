import { useMutation, useSubscription } from '@apollo/client'
import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Mutations } from '../../queries/mutation'
import { Domain, SetDomainImageMutationVariables, SetDomainImageMutation } from '../../types'
import { colors } from '../generic/colors'
import Error from '../generic/Error'
import Loading from '../generic/Loading'



const ChangeDomainImage = ({ domain }: { domain: Domain }) => {
    const [image, setImage] = useState(domain.ImageURL)
    const [save, { loading, error }] = useMutation<SetDomainImageMutation, SetDomainImageMutationVariables>(Mutations.setDomainImage, {
        context: { xDomainId: domain.GlobalId },
    })

    useEffect(() => {
        if (!domain || !domain.GlobalId || !domain.ImageURL) return       
        setImage(domain.ImageURL)
    }, [domain])

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)

        var file = acceptedFiles[0]
        const reader = new FileReader();
        reader.onload = async (event) => {
            let base64 = event.target.result.toString()

            try {
                await save({
                    variables: {
                        DomainId: domain.GlobalId,
                        record: {
                            Base64: base64,
                            Name: file.name
                        }
                    }
                })
            } catch (e) { }
        };
        reader.readAsDataURL(file);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    if (error) return <Error error={error} />

    return (
        <div {...getRootProps()} style={{ padding: "10px", cursor: 'pointer' }}>
            <input {...getInputProps()} />
            {loading && <Loading />}
            {isDragActive && <p>Släpp bilden här (600x140px)</p>}
            {image ? <img src={image} alt={domain.Name} /> : <div style={{
                border: "1px solid gray",
                width: '600px',
                height: '140px',
                backgroundColor: colors.scale.mid,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>Bild saknas (600x140px)</div>}
        </div>
    )
}


export default ChangeDomainImage
