import React from 'react'
import { PropagateLoader, FadeLoader, SquareLoader, BounceLoader } from 'react-spinners'
import { colors } from './colors'

const Loading = ({ loading, size = 'small', button = false }: { loading?: boolean, size?: 'x-small' | 'small' | 'large' | 'digger', button?: boolean }) => {



    if (!button)
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <PropagateLoader
                loading={loading === false ? false : true}                
                size={size === 'x-small' ? 4 : 'small' ? 8 : 15}
                color={colors.secondary.main.color}
            />
        </div>
    else
        return (

            <BounceLoader
                loading={loading === false ? false : true}
                size={20}
                color={'white'}
            />
        )
}

export default Loading