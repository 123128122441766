import React from 'react'
import './index.scss'

export const RenderRow = ({ header, value, type }: {
    header: string,
    value: string,
    type?: 'error' | 'warning' | 'success',
}) => {

    return (
        <div className="div-row">
            <span>{header}</span>
            <span className={`value-${type || ''}`}>{value}</span>
        </div>
    )
}