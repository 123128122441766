import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CustomerProject, ToggleFavoriteProjectMutation, ToggleFavoriteProjectMutationVariables } from "../../types";
import { useMe } from "../hooks/permissionHook";
import { Mutations } from '../../queries/mutation';
import './favoriteButton.scss';


const FavoriteButton = ({ customerProject }: { customerProject: CustomerProject }) => {
    const [ProjectNo, SetProjectNo] = useState<string>(customerProject.ProjectNo)

    useEffect(() => {
        SetProjectNo(customerProject.ProjectNo)
    }, [customerProject])

    const { me } = useMe()
    const [favProj, setFavProj] = useState(false)
    const [save, { loading, error }] = useMutation<ToggleFavoriteProjectMutation, ToggleFavoriteProjectMutationVariables>(Mutations.toggleFavoriteProject, { 
        refetchQueries: ['me'],
        context: { xDomainId: customerProject.DomainId },
    })

    const checkFavorite = () => {
        if (!me) return
        console.log('exec')
        let favoriteProject = me.favoriteProjects.find(x => x.ProjectNo === ProjectNo)

        if (favoriteProject && !favProj)
            setFavProj(true)
        else if (!favoriteProject && favProj)
            setFavProj(false)
    }

    useEffect(() => {
        checkFavorite()
    }, [me])

    const toggleFavorite = async () => {
        save({
            variables: {
                record: {
                    customerProjectNo: ProjectNo,
                }
            },
        })
    }
    return (
        <div className='switch-holder'>
            <input
                checked={favProj}
                onChange={toggleFavorite}
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
            />
            <label
                //Green background when true
                style={{ background : favProj && '#06D6A0' }}
                className="react-switch-label"
                htmlFor={`react-switch-new`}
                
            >
                <div className="react-switch-label-text">Markera som favorit</div>
                <span className={`react-switch-button`}/>
            </label>
        </div>
    )
}

export default FavoriteButton