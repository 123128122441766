import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import { Document, DocumentQuery, DocumentQueryVariables } from '../../types'
import { colors } from '../generic/colors'
import DetailsComponent from '../generic/DetailsComponent'
import Error from '../generic/Error'
import Link from '../generic/Link'
import Loading from '../generic/Loading'
import * as en from 'linq'

const DocumentCard = () => {
    const { DocumentId } = useParams<{ DocumentId: string }>()
    const [document, setDocument] = useState<Document>(null)
    const { data, loading, error } = useQuery<DocumentQuery, DocumentQueryVariables>(Queries.document, { skip: !DocumentId, variables: { filter: { GlobalId: DocumentId } } })

    useEffect(() => {
        if (!data || !data.document) return
        const { document } = data
        setDocument(document)
    }, [data])

    if (loading) return <Loading />
    if (error) return <Error error={error} />

    if (!document && !loading) return <div>Not found</div>



    const RenderDocument = () => {

        let orignalfile = en.from(document.files).where((x) => x.name === document.OriginalFileName).firstOrDefault();
        let signedfile = en.from(document.files).where((x) => x.name === document.SignedFileName).firstOrDefault();

        let status = { color: 'green', label: '' }
        if (document.SignStatus === 'open') status = { color: 'green', label: 'Väntar på signatur' }
        else if (document.SignStatus === 'received') status = { color: 'red', label: 'Signerat dokument inskickat' }
        else if (document.SignStatus === 'signed') status = { color: 'green', label: 'Signerat' }

        return (
            <div>
                <DetailsComponent
                    Headline={document.Name}
                    DomainId={document.DomainId}
                    renderQRcode
                    sections={[
                        {
                            title: 'Information', data: [
                                { title: 'Orginaldokument', value: orignalfile && <Link target="_blank" useAbsoluteLink={true} type='inline' to={orignalfile.link}>Öppna</Link> },
                                { title: 'Signerat dokuemnt', value: signedfile && <Link target="_blank" useAbsoluteLink={true} type='inline' to={signedfile.link}>Öppna</Link> },
                                { title: 'Signerat', value: document.Signed ? <span style={{ color: 'green' }}>Ja</span> : <span style={{ color: 'red' }}>Nej</span> },
                                { title: 'Signerat den', value: document.SignedOn, type: 'date' },
                                { title: 'Namnförtydligande', value: document.SignedPrintedName },
                                { title: 'Signeringsstatus', value: <span style={{ color: status.color }}>{status.label}</span> },
                                { title: 'Signering initierat av', value: document.SignInitiatingUserName },
                                { title: 'Signering skickad till', value: document.SignRequestSentToUserName },
                            ]
                        }
                    ]}
                />
            </div >
        )
    }

    const RenderImage = () => {

        return (
            <Container style={{
                padding: '20px',
                backgroundColor: 'white',
                margin: '20px'
            }}>
                <h2 style={{ color: colors.secondary.main.color }}>{document.Name}</h2>
                <p>
                    Kan du inte se bilden?
                    {' '}
                    <a href={document.image.link} target='_blank' rel="norefferer">Klicka här</a>
                </p>

                {!document.image ? <span>URL saknas</span> : <img src={document.image.link} alt={document.Name} />}
            </Container>
        )
    }

    return (
        <div>
            {document.DocumentTypeOPTION === 'image' && <RenderImage />}
            {document.DocumentTypeOPTION !== 'image' && <RenderDocument />}
        </div>
    )
}


export default DocumentCard