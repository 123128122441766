import { useQuery } from '@apollo/client'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useController, Control } from "react-hook-form";
import Error from '../generic/Error';
import Loading from '../generic/Loading';
import Autocomplete from '../table/Autocomplete';
import { FormField } from './types';
import * as en from 'linq'


const DynamicPicker: FunctionComponent<{
    formField: FormField,
    control: Control<Record<string, any>>,
    onChange: (name: string, value: any, config?: Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
    }>) => void,
    state: any,
    multiValue?: boolean
}> = ({
    multiValue,
    state,
    formField,
    control,
    onChange
}) => {
        const {
            labelprop,
            valueprop,
            name,
            query,
            placeholder,
            filter,
            pluralname
        } = formField
        const [selected, setSelected] = useState<any[]>([])
        const [passedFilter, setPassedFilter] = useState({})
        const [currentFilter, setCurrentFilter] = useState()
        const [searchvalue, setSearchValue] = useState<string>()
        const [discriminator, setDiscriminator] = useState<string>()
        const [label, setLabel] = useState<string>('')
        const [visible, setVisible] = useState<boolean>(false)
        const { data, loading, error } = useQuery(query, { 
            variables: { filter: currentFilter, limit: 50 } 
        })
        const [items, setItmes] = useState<any[]>([])
        const [valueFilter, setValueFilter] = useState<any>()

        const {
            field: { ref, value, ...inputProps },
        } = useController({
            name,
            control,
            rules: { required: true },
            defaultValue: "",
        });

        const valuequerystate = useQuery(query, { 
            skip: !valueFilter || !value, 
            variables: { filter: valueFilter } 
        })

        useEffect(() => {

            if (!valueprop || !value) {
                setValueFilter(null)
                return
            }

            if (multiValue) {
                if (value.length < 1) {
                    setValueFilter(null)
                    return
                }

                let f = value.map(x => ({ [valueprop]: x }))
                setValueFilter({ IN: f })

            } else
                setValueFilter({ [valueprop]: value })
        }, [valueprop, value])

        useEffect(() => {
            let passedFilter = {}
            if (filter && typeof (filter) === 'function') passedFilter = filter(state || {})
            setPassedFilter(passedFilter)
        }, [state])

        useEffect(() => {

            setCurrentFilter({
                ...passedFilter,
                SearchString: searchvalue || null
            } as any)

        }, [passedFilter, searchvalue])

        useEffect(() => {
            if (!data) {
                setItmes([])
                setDiscriminator(null)
                return
            }

            if (Object.keys(data).length < 1) {
                console.log('error while getting data', data)
                return
            }

            let dataobject = data[Object.keys(data)[0]]
            setItmes(dataobject)
            if (dataobject.length > 0)
                setDiscriminator(dataobject[0].discriminator)

        }, [data])




        useEffect(() => {
            const { data } = valuequerystate
            if (!data || !value) {
                setLabel('')
                return
            }
            let dataobject = data[Object.keys(data)[0]]
            if (multiValue) {
                setLabel(en.from(dataobject).distinct(x => x[labelprop]).toArray().map(x => x[labelprop]).join(','))
            } else if (dataobject.length > 0) {
                setLabel(dataobject[0][labelprop])
            }
            else setLabel('')
        }, [valuequerystate, value])

        const acceptSelectedItems = () => {
            if (selected.length < 1)
                onChange(formField.name, null, {
                    shouldDirty: true,
                    shouldValidate: true
                })
            else
                onChange(formField.name, selected.map(x => x[valueprop]), {
                    shouldDirty: true,
                    shouldValidate: true
                })

            close()
        }

        const selectItem = item => {
            console.log(item[valueprop])

            if (multiValue) {
                if (itemSelected(item))
                    setSelected(en.from(selected).where(x => x[valueprop] !== item[valueprop]).toArray())
                else
                    setSelected([...selected, item])
            } else {

                if (!item) {
                    onChange(formField.name, null, {
                        shouldDirty: true,
                        shouldValidate: true
                    })
                    return
                }

                onChange(formField.name, item[valueprop], {
                    shouldDirty: true,
                    shouldValidate: true
                })
                close()
            }
        }

        const itemSelected = (item): boolean => {
            return en.from(selected).any(x => x[valueprop] === item[valueprop])
        }

        const close = () => {
            setVisible(false)
            setSearchValue('')
            setSelected([])
        }

        return (
            <div>

                {valuequerystate.error && <Error error={valuequerystate.error} />}
                {error && <Error error={error} />}

                <Modal size={multiValue ? 'xl' : 'sm'} animation={false} show={visible} onHide={close}>
                    <Autocomplete discriminator={discriminator} searchValueChanged={setSearchValue} />

                    <div className="items-container">


                        <div className="items">
                            {(items.length < 1 && !loading) && <span>Inga resultat hittades</span>}
                            {items.map((item, i) => (
                                <div className={itemSelected(item) ? 'selected' : ''} onClick={() => selectItem(item)} key={i}>{item[labelprop]}</div>
                            ))}
                        </div>



                        <div className={`selection-controls ${multiValue && selected.length > 0 ? 'active' : 'inactive'}`}>
                            <button className="rounded-button" onClick={close}>Avbryt</button>
                            <button className="rounded-button blue" onClick={acceptSelectedItems}>Välj</button>
                        </div>


                    </div>

                </Modal>

                <p><span onClick={() => setVisible(true)}>{label || (placeholder || 'Välj...')}</span></p>
                {(valuequerystate.loading || loading) && <Loading />}
                <div style={{ display: 'none' }}><input disabled {...inputProps} value={value} ref={ref} /></div>
            </div>
        )
    }


export default DynamicPicker