import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import RenderAuth from './auth/AuthRenderer';
import Modal from 'react-modal'

Modal.setAppElement('#root')


ReactDOM.render(
  <React.StrictMode>
    <RenderAuth>
      <App />
    </RenderAuth>
  </React.StrictMode>,
  document.getElementById('root')
);

