import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split
} from '@apollo/client';
import { cfg } from './config';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import * as en from 'linq'

const httpLink = createHttpLink({
    uri: cfg.graphqlendpoint,
});

export const getClient = ({ getToken, domainId }: { getToken: () => Promise<string>, domainId: string}) => {
    const authLink = setContext(async (args, next) => {
        const { headers } = next
        const token = await getToken()

        // let subsription = en.from(args.query.definitions).firstOrDefault((x: any) => x.operation && x.operation.toLowerCase() === 'subscription')

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const wsLink = new WebSocketLink({
        uri: cfg.wsendpoint,
        options: {
            lazy: true,
            reconnect: true,
            connectionParams: async () => {
                const token = await getToken()
                return {
                    authorization: token ? `Bearer ${token}` : ""
                }
            }
        }
    });

    const splitLink = split(
        (ops) => {
            const definition = getMainDefinition(ops.query);
            
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    const domainLink = setContext(async (request, previousContext) => {
        const { headers, xDomainId } = previousContext

        // console.log('xDomainId', xDomainId)

        // console.log('context:', JSON.stringify(request.context))
        // console.log('operation name:', JSON.stringify(request.operationName))
        // console.log('extensions:', JSON.stringify(request.extensions))
        // console.log('query:', JSON.stringify(request.query))
        // console.log('variables:', JSON.stringify(request.variables))

        // WHY IS IT NOT WORKING WITH request.context.headers OR previousContext.headers ?!?!?!?
        // const domainId: string = request.variables?.filter?.DomainId || request.variables?.filter?.GlobalId || request.variables?.record?.GlobalId
        const domainId: string = request.variables?.filter?.DomainId
        // console.log(`xDomainId:${xDomainId} || domainId: ${domainId} || ''`)
        return {
            ...previousContext,
            headers: {
                ...headers,
                'X-Domain-Id': xDomainId || domainId || '',
            }
        }
    })

    const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
        cache: new InMemoryCache({
            dataIdFromObject: object => (object as any).GlobalId,
        }),
        link: ApolloLink.from([domainLink, authLink, splitLink]),
    });

    return client
}