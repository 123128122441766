import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { v4, NIL } from "uuid"
import { Mutations } from '../../queries/mutation'
// import { Domain, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'
import { Domain, InitializeDomainMutation, InitializeDomainMutationVariables } from '../../types'
import Form from '../Form'
import Error from '../generic/Error'

const DomainForm = ({
    onRequestClose
}: {
    onRequestClose: () => void
}) => {

    // const [mutation, { error, loading }] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, { 
    const [mutation, { error, loading }] = useMutation<InitializeDomainMutation, InitializeDomainMutationVariables>(Mutations.initializeDomain, { 
        refetchQueries: ['domainsPage']
    })

    const save = async (record: Domain) => {
        if (!record.GlobalId)
            record.GlobalId = v4()

        await mutation({
            variables: {
                record: {
                    GlobalId: record.GlobalId || "",
                    ...record
                }
            },
            context: { xDomainId: NIL },
        }).catch(e => { console.log(e) })
    }

    return (
        <div className="shadow rounded-container">
            {error && <Error error={error} type="mutation" />}
            <Form
                headerNote = "OBS: processen kan dröja ett par minuter"
                loading={loading}
                onRequestClose={onRequestClose}
                fields={[
                    { name: 'Name', label: "Namn", },
                    { name: 'GlobalId', label: "GlobalId", },
                    { name: 'DomainMainOrientation', label: 'Inriktining', defaultValue: 'sales', hidden: true } 
                ]}
                onSubmit={save}
            />
        </div>
    )
}

export const CreateNewDomainButton = () => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <div style={{ width: '100%' }}>
            <div className="full-width-controls">
                <div className="rounded-button-wrapper" onClick={() => setOpen(old => !old)}>
                    <div className="rounded-button blue">
                        Lägg till domän
                    </div>
                </div>
            </div>
            <Collapsible open={open} trigger={<div></div>}>
                <DomainForm onRequestClose={() => setOpen(false)} />
            </Collapsible>
        </div>
    )
}

export default DomainForm