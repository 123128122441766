import React from 'react'
import { InputGroup, Dropdown } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { Mutations } from '../../queries/mutation'
import { Domain, UpsertDomainMutation, UpsertDomainMutationVariables } from '../../types'
import Error from '../generic/Error'

const ChangeObjectVisibility = ({ domain }: { domain: Domain }) => {
    const [save, { loading, error }] = useMutation<UpsertDomainMutation, UpsertDomainMutationVariables>(Mutations.upsertDomain, {
        context: { xDomainId: domain.GlobalId },
    })
    if (!domain) return <Error type='notFound' />

    const toggleReservations = (ReservationsEnabled) => {
        save({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    ReservationsEnabled
                }
            }
        })
    }

    const toggleReturns = (ReturnReservationsEnabled) => {
        save({
            variables: {
                record: {
                    GlobalId: domain.GlobalId,
                    ReturnReservationsEnabled
                }
            }
        })
    }

    let returnlabel = domain.ReturnReservationsEnabled ? returnlabels.true : returnlabels.false || 'inget valt'
    let reservationLabel = domain.ReservationsEnabled ? reservationLabels.true : reservationLabels.false || 'inget valt'

    return (
        <div>
            {error && <Error error={error} type='mutation' />}

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Returbokningar</InputGroup.Text>
                </InputGroup.Prepend>

                <Dropdown>
                    <Dropdown.Toggle variant={domain.ReturnReservationsEnabled ? 'success' : 'danger'} id="dropdown-basic">
                        {returnlabel}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => toggleReturns(true)}>{returnlabels.true}</Dropdown.Item>
                        <Dropdown.Item onClick={() => toggleReturns(false)}>{returnlabels.false}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Bokningar</InputGroup.Text>
                </InputGroup.Prepend>

                <Dropdown>
                    <Dropdown.Toggle variant={domain.ReservationsEnabled ? 'success' : 'danger'} id="dropdown-basic">
                        {reservationLabel}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => toggleReservations(true)}>{reservationLabels.true}</Dropdown.Item>
                        <Dropdown.Item onClick={() => toggleReservations(false)}>{reservationLabels.false}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>

        </div>
    )
}

const returnlabels = {
    undefined: 'Inget aktivt val, bokning av returer inte tillåtet',
    true: 'Bokning av returer är tillåtet',
    false: 'Bokning av returer är inte tillåtet',
}

const reservationLabels = {
    undefined: 'Inget aktivt val, bokningar är inte tillåtet',
    true: 'Bokningar är tillåtet',
    false: 'Bokningar är inte tillåtet'
}


export default ChangeObjectVisibility