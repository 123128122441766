import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Queries } from '../../queries/query'
import moment from 'moment'
import {
    CustomerProject, RentalLine, RentalPostedCollLine, RentalPostedShptLine, ReservationRequestLine, ReturnRequestLine,
    RentalEventsManyQuery,
    RentalEventsManyQueryVariables
} from '../../types'
import * as en from 'linq'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import './shipments.scss'
import { FaCalendarAlt } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'
import useQueryState from '../hooks/queryStringHooks'
import Loading from '../generic/Loading'
import Error from '../generic/Error'
import { RenderRow } from '../generic/RenderRow'
import Collapsible from 'react-collapsible'
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi'
import { FaTruck } from 'react-icons/fa'
import { colors } from '../generic/colors'
import { Form } from 'react-bootstrap'

const Shipments = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {

    const [dates, setDates] = useQueryState<Date[]>({
        paramName: 'daterange',
        defaultValue: [moment(new Date()).subtract(7, 'days').toDate(), moment(new Date()).add(7, 'days').toDate()],
        serialize: (val) => JSON.stringify({ start: val[0], end: val[1] }),
        deserialize: (val) => {
            let dates = JSON.parse(val)
            return [dates.start, dates.end]
        }
    })
    const [returns, setReturns] = useState<ReturnRequestLine[] | RentalPostedCollLine[]>([])
    const [shipments, setShipments] = useState<RentalLine[] | ReservationRequestLine[] | RentalPostedShptLine[]>([])
    const [typefilter, setTypefilter] = useQueryState<{
        rentalPostedCollLine: boolean,
        rentalPostedShptLine: boolean,
        returnRequestLineConfirmed: boolean,
        returnRequestLineUnconfirmed: boolean,
        rentalLine: boolean,
        reservationRequestLineUnconfirmed: boolean,
    }>({
        paramName: 'typeFilter',
        defaultValue: {
            rentalPostedCollLine: true,
            rentalPostedShptLine: true,
            returnRequestLineConfirmed: true,
            returnRequestLineUnconfirmed: true,
            rentalLine: true,
            reservationRequestLineUnconfirmed: true,
        },
        serialize: JSON.stringify,
        deserialize: JSON.parse
    })

    const { data, loading, error, refetch } = useQuery<RentalEventsManyQuery, RentalEventsManyQueryVariables>(Queries.rentalEventsMany, {
        skip: !dates,
        variables: {
            filter: { ProjectId: customerProject.ProjectId, StartDate: dates ? dates[0] : null, EndDate: dates ? dates[1] : null }
        },
        context: { xDomainId: domainId },
    })

    useEffect(() => {
        if (!data || !data.rentalEventsMany) {
            setShipments([])
            setReturns([])
            return
        }

        let shipments = []
        if (typefilter.rentalPostedShptLine)
            shipments = shipments.concat(data.rentalEventsMany.shipmentLines)

        if (typefilter.rentalLine)
            shipments = shipments.concat(data.rentalEventsMany.reservations)

        if (typefilter.reservationRequestLineUnconfirmed)
            shipments = shipments.concat(data.rentalEventsMany.reservationRequestLines)


        setShipments(shipments as any)

        let returns = []
        if (typefilter.rentalPostedCollLine)
            returns = returns.concat(data.rentalEventsMany.returnLines)

        if (typefilter.returnRequestLineConfirmed)
            returns = returns.concat(en.from<any>(data.rentalEventsMany.returnRequestLines).where(x => x.Confirmed).toArray())
        if (typefilter.returnRequestLineUnconfirmed)
            returns = returns.concat(en.from<any>(data.rentalEventsMany.returnRequestLines).where(x => !x.Confirmed).toArray())

        setReturns(returns as any)
    }, [data, typefilter])


    return (
        <div className="rounded-container shadow">
            <div className="header">
                {loading && <Loading />}
                {error && <Error error={error} />}
                <h1>Leveranser och returer</h1>

                <DateRangePicker
                    onChange={setDates}
                    value={dates}
                    showLeadingZeros
                    rangeDivider={' | '}
                    calendarIcon={<FaCalendarAlt color={'white'} />}
                    clearIcon={<MdClear color={'white'} />}
                />
            </div>

            <div className="lines-wrapper">
                <div className="col">
                    <div className="header">
                        <h1>Leveranser</h1>
                        <FaTruck color={'green'} size={24} />
                    </div>
                    <Form.Check inline onChange={e => setTypefilter(o => ({ ...o, rentalPostedShptLine: (e.target as any).checked }))} checked={typefilter.rentalPostedShptLine} type="checkbox" label="Utförda" />
                    <Form.Check inline onChange={e => setTypefilter(o => ({ ...o, rentalLine: (e.target as any).checked }))} checked={typefilter.rentalLine} type="checkbox" label="Bekräftade bokningar" />
                    <Form.Check inline onChange={e => setTypefilter(o => ({ ...o, reservationRequestLineUnconfirmed: (e.target as any).checked }))} checked={typefilter.reservationRequestLineUnconfirmed} type="checkbox" label="Obekräftade bokningar" />
                    {en.from(shipments).groupBy(x => moment(getItemDate(x)).format('YYYY-MM-DD dddd')).toArray().map((x, i) => (
                        <div key={i} className="day">
                            <h3>{x.key()}</h3>
                            {x.toArray().map((item: any) => (
                                <div key={item.GlobalId} className="line">
                                    <RenderItem item={item} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="col">
                    <div className="header">
                        <h1>Returer</h1>
                        <FaTruck color={colors.errorColor} size={24} />
                    </div>

                    <Form.Check inline onChange={e => setTypefilter(o => ({ ...o, rentalPostedCollLine: (e.target as any).checked }))} checked={typefilter.rentalPostedCollLine} type="checkbox" label="Utförda" />
                    <Form.Check inline onChange={e => setTypefilter(o => ({ ...o, returnRequestLineConfirmed: (e.target as any).checked }))} checked={typefilter.returnRequestLineConfirmed} type="checkbox" label="Bekräftade returbokningar" />
                    <Form.Check inline onChange={e => setTypefilter(o => ({ ...o, returnRequestLineUnconfirmed: (e.target as any).checked }))} checked={typefilter.returnRequestLineUnconfirmed} type="checkbox" label="Obekträftade returbokningar" />

                    {en.from(returns).groupBy(x => moment(getItemDate(x)).format('YYYY-MM-DD dddd')).toArray().map((x, i) => (
                        <div key={i} className="day">
                            <h3>{x.key()}</h3>
                            {x.toArray().map((item: any) => (
                                <div key={item.GlobalId} className="line">
                                    <RenderItem item={item} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

const filtertypes = [
    'rentalPostedCollLine',
    'rentalPostedShptLine',
    'returnRequestLine-confirmed',
    'returnRequestLine-unconfirmed',
    'rentalLine',
    'reservationRequestLine-unconfirmed',
]

enum RentEventStatus {
    unconfirmed = 'Obekräftad',
    exectued = 'Utförd',
    confirmed = 'Bekräftad',
    posted = 'Utförd'
}

const getItemDate = (item: any): Date => {
    switch (item.discriminator) {
        case 'rentalPostedCollLine':
            return moment(item.OffRentDate).toDate()

        case 'rentalPostedShptLine':
            return moment(item.OnRentDate).toDate()

        case 'returnRequestLine':
        case 'reservationRequestLine':
            return moment(item.RequestDate).toDate()

        case 'rentalLine':
            return moment(item.OnRentDate).toDate()
    }
}

const getStatus = (item): RentEventStatus => {
    switch (item.discriminator) {
        case 'rentalPostedCollLine':
        case 'rentalPostedShptLine':
            return RentEventStatus.exectued

        case 'returnRequestLine':
        case 'reservationRequestLine':
            if (item.Confirmed) return RentEventStatus.confirmed
            else return RentEventStatus.unconfirmed

        case 'rentalLine':
            return RentEventStatus.confirmed
    }
}

const RenderItem = ({ item }) => {
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const [content, setContent] = useState<JSX.Element>(null)

    const renderReturnLine = ({ line }: { line: RentalPostedCollLine }) => {
        const status = getStatus(line)
        return (
            <div>
                <RenderRow header={'Nummer'} value={line.No} />
                <RenderRow header={'Hyresperiod'} value={`${moment(line.ShipmentDate).format('YYYY-MM-DD')} - ${moment(line.OffRentDate).format('YYYY-MM-DD')}`} />
                <RenderRow header={'Status'} type={status === RentEventStatus.confirmed || status === RentEventStatus.posted ? 'success' : 'warning'} value={status} />
            </div>
        )
    }

    const renderRentalLine = ({ line }: { line: RentalLine }) => {
        const status = getStatus(line)
        return (
            <div>
                <RenderRow header={'Nummer'} value={line.No} />
                <RenderRow header={'Datum'} value={moment(line.OnRentDate).format('YYYY-MM-DD')} />
                <RenderRow header={'Status'} type={status === RentEventStatus.confirmed || status === RentEventStatus.posted ? 'success' : 'warning'} value={status} />
            </div>
        )
    }

    const renderReservationRequest = ({ line }: { line: ReservationRequestLine }) => {
        const status = getStatus(line)
        return (
            <div>
                <RenderRow header={'Nummer'} value={line.No} />
                <RenderRow header={'Begärt leveransdatum'} value={moment(line.RequestDate).format('YYYY-MM-DD')} />
                <RenderRow header={'Status'} type={status === RentEventStatus.confirmed || status === RentEventStatus.posted ? 'success' : 'warning'} value={status} />
            </div>
        )
    }

    const renderReturnRequest = ({ line }: { line: ReturnRequestLine }) => {
        const status = getStatus(line)
        return (
            <div>
                <RenderRow header={'Nummer'} value={line.No} />
                <RenderRow header={'Begärt upphämtningsdatum'} value={moment(line.RequestDate).format('YYYY-MM-DD')} />
                <RenderRow header={'Status'} type={status === RentEventStatus.confirmed || status === RentEventStatus.posted ? 'success' : 'warning'} value={status} />
            </div>
        )
    }

    const renderShipmentLine = ({ line }: { line: RentalPostedShptLine }) => {
        const status = getStatus(line)
        return (
            <div>
                <RenderRow header={'Nummer'} value={line.No} />
                <RenderRow header={'Datum'} value={moment(line.OnRentDate).format('YYYY-MM-DD')} />
                <RenderRow header={'Status'} type={status === RentEventStatus.confirmed || status === RentEventStatus.posted ? 'success' : 'warning'} value={status} />
            </div>
        )
    }


    useEffect(() => {
        switch (item.discriminator) {
            case 'rentalPostedCollLine':
                setContent(renderReturnLine({ line: item })); break;

            case 'rentalPostedShptLine':
                setContent(renderShipmentLine({ line: item })); break;

            case 'returnRequestLine':
                setContent(renderReturnRequest({ line: item })); break;

            case 'rentalLine':
                setContent(renderRentalLine({ line: item })); break;

            case 'reservationRequestLine':
                setContent(renderReservationRequest({ line: item })); break;

            default:
                console.log('item could not be converted', item)
        }
    }, [item])

    return (
        <div className="item-line">
            <Collapsible easing={'ease'} onOpening={() => setCollapsed(false)} onClosing={() => setCollapsed(true)} trigger={(
                <div className="title">
                    {collapsed ? <FiArrowDownCircle /> : <FiArrowUpCircle />}
                    {item.Description}
                    <span>{item.Quantity} st</span>
                </div>
            )}>
                {content}
            </Collapsible>
        </div >
    )
}

export default Shipments