import React, { useState } from 'react'
import { Button, Container, Modal, Card, CardGroup } from 'react-bootstrap'
import shortid from 'shortid'
import './index.scss'
import { colors } from '../generic/colors'
import Link from '../generic/Link'
import Loading from '../generic/Loading'
import Error from '../generic/Error'
import { useDomainPanels } from './Panels'
import { Domain, DomainIntegration } from '../../types'

const DomainComponents = ({ domain }: { domain: Domain }) => {
    const { panels, loading, error } = useDomainPanels(domain)
    const groups = chunkify(panels, 3)

    return (
        <div>
            {error && <Error error={error} />}
            {loading && <Loading />}

            {groups.map((group, i) => (
                <CardGroup key={i} className="mb-3">
                    {group.map((panel, i) => (
                        <Card className="shadow" key={i} text="primary" style={{ width: '18rem' }}>
                            {panel.image}
                            <Card.Body>
                                <Card.Title>{panel.title}</Card.Title>
                                <Card.Text>
                                    {panel.text}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <Link type="inline" to={panel.url} useAbsoluteLink={panel.useAbsoluteLink}><Button variant="primary">Gå dit</Button></Link>
                            </Card.Footer>
                        </Card>
                    ))}
                </CardGroup>
            ))}
        </div>
    )
}

const chunkify = (arr: any[], numberInEach: Number): any[][] => {
    let out = [[]], i = 0;

    arr.forEach(item => {
        if (!out[i]) out[i] = []
        let current = out[i]
        current.push(item)

        if (current.length >= numberInEach)
            i++
    })

    return out
}

export default DomainComponents