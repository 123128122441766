
import React, { useEffect, useState } from 'react'
import { Queries } from '../../queries/query'
import { CustomerProject, InvoiceHeadersManyQuery, InvoiceHeadersManyQueryVariables, FilterInvoiceHeaderInput, InvoiceHeader } from '../../types'
import { asMoney } from '../../utils'
import Link from '../generic/Link'
import Loading from '../generic/Loading'
import SearchableTable from '../table/SearchableTable'
import './index.scss'
import moment from 'moment'

const InvoiceList = ({ domainId, customerProject }: { domainId: string, customerProject: CustomerProject }) => {
    let [filter, setFilter] = useState<FilterInvoiceHeaderInput>()
    const [showClosed, setShowClosed] = useState<boolean>(false)

    if (filter)
        filter.DomainId = domainId
    else
        filter = {DomainId: domainId}

    useEffect(() => {
        if (!customerProject) {
            setFilter(null)
            return
        }
        let filter: FilterInvoiceHeaderInput = {
            ProjectId: customerProject.ProjectNo ? customerProject.ProjectNo.toLowerCase() : 'null',
            Closed: showClosed
        }

        setFilter(filter)

    }, [customerProject, showClosed])

    if (!filter) return <Loading />

    return (


        <SearchableTable
            domainId={customerProject.DomainId}
            title={'Fakturor'}
            disableSearch
            disablePagination
            query={Queries.invoiceHeadersMany}
            queryVariables={{
                sort: '_DUEDATE_DESC_'
            }}
            filter={filter}
            cols={[
                {
                    Header: 'Fakturanummer',
                    Cell: ({ row: { original } }: { row: { original: InvoiceHeader } }) => {
                        return <Link type="inline" to={`/invoices/${original.GlobalId}`}>{original.No}</Link>
                    },
                },
                {
                    Header: 'Datum',
                    Cell: ({ row: { original } }: { row: { original: InvoiceHeader } }) => {
                        return <Link type="inline" to={`/invoices/${original.GlobalId}`}>{original.DueDate ? moment(original.DueDate).format('YYYY-MM-DD') : ''}</Link>
                    },
                },
                {
                    Header: 'Summa',
                    Cell: ({ row: { original } }: { row: { original: InvoiceHeader } }) => {
                        return <Link type="inline" to={`/invoices/${original.GlobalId}`}>{asMoney(original.AmountIncludingVAT)}</Link>
                    },
                }
            ]}
        >
            <button className={`rounded-button ${showClosed ? 'red' : 'blue'}`} onClick={() => setShowClosed(old => !old)}>{showClosed ? 'Visar stängda fakturor' : 'Visar öppna fakturor'}</button>
        </SearchableTable>


    )
}

export default InvoiceList