import { DocumentNode, TypedDocumentNode, useMutation, useQuery } from '@apollo/client'
import React, { FunctionComponent, useEffect, useState, useMemo } from 'react'
import { useTable, usePagination, useRowSelect } from 'react-table'
import Error from '../generic/Error'
import BTable from 'react-bootstrap/Table';
import Autocomplete from './Autocomplete';
import './index.scss'
import { Button, Modal } from 'react-bootstrap';
import { Mutations } from '../../queries/mutation';
import Loading from '../generic/Loading';
import * as en from 'linq'
import Pagination from './Pagination';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import useQueryState from '../hooks/queryStringHooks';
import ReactTooltip from 'react-tooltip';
import CopyButton from '../generic/CopyButton';

const SearchableTable: FunctionComponent<{
    domainId: string,
    query: DocumentNode | TypedDocumentNode,
    deleteManyMutation?: DocumentNode | TypedDocumentNode,
    cols?: TableColumn[],
    filter?: any,
    disableSearch?: boolean,
    queryVariables?: any,
    title?: string
    disablePagination?: boolean
    enableStaticSorting?: boolean
    rowRenderer?: ({ row, rowProps, cells }: { cells: any[], rowProps: any, row: any }) => JSX.Element
    withSelectedRows?: ({ rows }: { rows: any[] }) => JSX.Element
}> = ({
    domainId, 
    withSelectedRows,
    enableStaticSorting,
    rowRenderer,
    disablePagination,
    title,
    deleteManyMutation,
    queryVariables,
    disableSearch,
    children,
    filter,
    cols = [],
    query,
}) => {
        const [refetchQueries, setRefetchQueries] = useState<string[]>([])
        const [deleteMany, deleteState] = useMutation(deleteManyMutation || Mutations.createCompanyInvitation, { 
            refetchQueries,
            context: { xDomainId: domainId },
        })
        let [currentFilter, setCurrentFilter] = useState(filter)
        const [searchValue, setSearchValue] = useQueryState<string>({ paramName: 'searchValue' })
        const [qsPageIndex, setQsPageIndex] = useQueryState<number>({ paramName: 'pageIndex' })
        const [discriminator, setDiscriminator] = useState<string>()
        const [data, setData] = useState<any[]>([])
        const [noOfPages, setNoOfPages] = useState<number>(0)
        const [totalCount, setTotalCount] = useState<number>(0)
        const [deleteConfirmVisible, setDeleteConfirmVisible] = useState<boolean>(false)
        const columns = useMemo(() => cols, [])

        if (domainId)
            if (currentFilter)
                currentFilter.DomainId = domainId
            else
                currentFilter = {DomainId: domainId}

        useEffect(() => {
            if (query && query.definitions) {
                const pagequery = en.from(query.definitions).where(x => x.kind === "OperationDefinition").firstOrDefault()
                if (pagequery && (pagequery as any).name) {
                    let refetch = (pagequery as any).name.value
                    if (refetchQueries.indexOf(refetch) < 0)
                        setRefetchQueries(old => [...old, refetch])
                }
            }
        }, [query])

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            pageOptions,
            page,
            pageCount,
            selectedFlatRows,
            state: { pageIndex, pageSize, selectedRowIds },
            gotoPage,
            previousPage,
            nextPage,
            setPageSize,
            canPreviousPage,
            canNextPage,
        } = useTable({
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 50
            },
            manualPagination: true,
            pageCount: noOfPages,
        }, usePagination, useRowSelect, hooks => {
            if (!deleteManyMutation && !withSelectedRows) return

            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        })

        useEffect(() => {
            let f = filter || {}
            Object.assign(f, { SearchString: searchValue })
            setCurrentFilter(old => {
                return f
            })

        }, [filter, searchValue])

        useEffect(() => {
            if (pageCount < 1) return

            setQsPageIndex(pageIndex)

        }, [pageIndex])

        useEffect(() => {
            if (!qsPageIndex) return
            if (pageCount < 1) return
            if (qsPageIndex === pageIndex) return

            gotoPage(qsPageIndex)

        }, [qsPageIndex, pageCount])

        const deleteSelected = async () => {
            try {
                await deleteMany({
                    variables: {
                        filter: {
                            IN: selectedFlatRows.map(x => ({
                                GlobalId: x.original.GlobalId
                            }))
                        }
                    }
                })

                setDeleteConfirmVisible(false)
            } catch (e) { }
        }


        const querystate = useQuery(query, {
            variables: {
                pageSize: pageSize,
                page: pageIndex,
                filter: currentFilter,
                ...queryVariables
            },
            context: { xDomainId: domainId },
        })

        useEffect(() => {
            querystate.refetch()
        }, [searchValue])

        useEffect(() => {
            if (data.length > 0 && !discriminator)
                setDiscriminator(data[0].discriminator)
        }, [data])

        useEffect(() => {
            const { data } = querystate

            if (!data) {
                setData([])
                return
            }

            let dataobject = data[Object.keys(data)[0]]

            if (dataobject.pageData) {
                setTotalCount(dataobject.pageData.totalCount)
                setNoOfPages(dataobject.pageData.pageCount)
            }

            if (dataobject.edges) {
                setData(dataobject.edges.map(edge => edge.node))
            } else if (dataobject.records) {
                setData(dataobject.records)
            } else {
                setData(dataobject)
            }

        }, [querystate])

        return (
            <div className="shadow rounded-container">
                {querystate.error && <Error error={querystate.error} />}
                {querystate.loading && <Loading size='digger' />}
                <div className="table-top">
                    {title && <h1>{title}</h1>}
                    <div className="table-controls">
                        {children}
                    </div>
                </div>

                {((deleteManyMutation || withSelectedRows) && selectedFlatRows.length > 0) && (
                    <div className="shadow rounded-container">
                        <h5>Med markerade ({selectedFlatRows.length} st):</h5>
                        {deleteManyMutation && <button onClick={() => setDeleteConfirmVisible(true)} className="rounded-button blue">Ta bort</button>}
                        {withSelectedRows && withSelectedRows({ rows: selectedFlatRows })}
                    </div>
                )}

                {!querystate.loading && (
                    <div>
                        {!disableSearch && <Autocomplete domainId={domainId} initValue={searchValue} discriminator={discriminator} searchValueChanged={val => setSearchValue(val)} />}

                        <BTable striped hover size="sm" {...getTableProps()}>
                            <thead>
                                {
                                    headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => {

                                                    return (
                                                        <th {...column.getHeaderProps()}>
                                                            {column.render('Header')}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? <BsArrowDown color={'white'} />
                                                                        : <BsArrowUp color={'white'} />
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    )
                                                })}
                                        </tr>
                                    ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {
                                    rows.map(row => {
                                        prepareRow(row)

                                        if (rowRenderer) {
                                            return rowRenderer({ cells: row.cells, row: row.original, rowProps: row.getRowProps ? row.getRowProps() : {} })
                                        } else
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {
                                                        row.cells.map(cell => {

                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    <div data-tip={cell.value}>
                                                                        {cell.render('Cell')}

                                                                    </div>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                    })}
                            </tbody>
                        </BTable>
                        {totalCount > 0 && <ReactTooltip
                            getContent={(dataTip) => <CopyButton content={dataTip} />}
                            effect='solid'
                            delayHide={500}
                            delayShow={500}
                            delayUpdate={500}
                            place={'top'}
                        />}

                        {!disablePagination && <Pagination
                            totalCount={totalCount}
                            pageOptions={pageOptions}
                            page={page}
                            pageCount={pageCount}
                            selectedFlatRows={selectedFlatRows}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            selectedRowIds={selectedRowIds}
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            setPageSize={setPageSize}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                        />}
                    </div>
                )}


                <Modal show={deleteConfirmVisible} animation={false} onHide={() => setDeleteConfirmVisible(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ta bort</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Är du säker på att du vill ta bort raderna?.</p>
                        <p>Detta går inte att ångra</p>
                        {deleteState.error && <Error error={deleteState.error} type={'mutation'} />}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => setDeleteConfirmVisible(false)} variant="secondary">Avbryt</Button>
                        <Button disabled={deleteState.loading} onClick={deleteSelected} variant="danger">{deleteState.loading ? <Loading /> : 'Ta bort'}</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }


const IndeterminateCheckbox = React.forwardRef((props, ref) => {
    const { indeterminate, ...rest } = props as any
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        (resolvedRef as any).current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
    )
}
)

export interface TableColumn {
    Header: string,
    Cell?: ({ row }: { row: any }) => JSX.Element,
    id?: string,
    accessor?: ((row: any) => string | JSX.Element) | string,
    defaultCanSort?: boolean,
    disableSortBy?: boolean,
    sortDescFirst?: boolean,
    sortType?: 'string' | 'number' | 'basic' | 'datetime' | 'alhpanumeric' | ((rowA: any, rowB: any, columnId: string, desc: boolean) => 1 | -1)
}
export interface rowDefinition {
    prop: string,
    label?: string
}
export default SearchableTable