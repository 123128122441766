import React from "react"
import { useQuery } from '@apollo/client'
import { CustomerProjectQuery, QueryCustomerProjectArgs } from '../../types'
import { Queries } from '../../queries/query'
import Loading from '../generic/Loading'
import Error from '../generic/Error'
import { useParams } from "react-router"
import PageWrapper from '../generic/PageWrapper'
import DetailsComponent from '../generic/DetailsComponent'
import DashBoard from './Dashboard'
import QuickRentInfo from "./QuickRentInfo"
import InvoiceList from "../invoice/InvoiceList"
import OnRentList from "./OnRentList"
import { Container } from "react-bootstrap"
import Shipments from "./Shipments"
import FavoriteButton from "./FavoriteButton"

const ProjectCard = () => {
    const { domainId, customerProjectId } = useParams<{ domainId: string, customerProjectId: string }>()
    const { data, loading, error } = useQuery<CustomerProjectQuery, QueryCustomerProjectArgs>(Queries.customerProject, { 
        variables: { filter: { DomainId: domainId, ProjectId: customerProjectId } },
        context: { xDomainId: domainId },
    })

    if (!domainId) return <Error error='the domain Id cannot be null' />

    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data.customerProject) return <Error type='notFound' />
    const { customerProject } = data

    if (!customerProject) return <Error error={'404 not found' + customerProject.ProjectId} />

    return (
        <Container>
            <FavoriteButton customerProject={customerProject}/>
            
            <DetailsComponent Headline={customerProject.Name} sections={[
                {
                    data: [
                        { title: 'Projektnummer', value: customerProject.ProjectNo },
                    ]
                },
                {
                    title: 'Kunduppgifter',
                    data: [
                        { title: 'Kund', value: customerProject.CustomerId },
                        { title: 'Kundnummer', value: customerProject.CustomerNo },
                    ]
                },
                {
                    title: 'Hyreskostnad',
                    data: [{ value: <DashBoard domainId={domainId} customerProject={customerProject} /> }]
                    
                }
            ]}

            />
            
            <QuickRentInfo domainId={domainId} customerProject={customerProject} />
            <OnRentList domainId={domainId} customerProject={customerProject} />
            <InvoiceList domainId={domainId} customerProject={customerProject} />
            <Shipments domainId={domainId} customerProject={customerProject} />
        </Container>
            

    )
}

export default ProjectCard