import React from 'react'
import { Queries } from '../../queries/query'
import { CalculateRentallineInputValues, FilterRentalLineInput, RentalLine, RentalLinesCalculatedQueryVariables } from '../../types'
import { asMoney } from '../../utils'
import Link from '../generic/Link'
import SearchableTable from '../table/SearchableTable'

const OnRentChildren = ({ domainId, rentalLine }: { domainId: string, rentalLine: RentalLine }) => {
    return (
        <SearchableTable
            domainId={rentalLine.DomainId}
            disablePagination
            disableSearch
            title={'Tillbehör'}
            query={Queries.rentalLinesCalculated}
            filter={{
                DomainId: rentalLine.DomainId,
                ContractType: rentalLine.ContractType,
                ContractNo: rentalLine.ContractNo,
                KITMotherLineNo: rentalLine.LineNo
            } as FilterRentalLineInput}
            queryVariables={{
                calculationType: CalculateRentallineInputValues.OnRentChildren
            } as RentalLinesCalculatedQueryVariables}
            cols={[
                {
                    Header: 'Beskrivning',
                    Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                        return (
                            <div>{original.Description}</div>
                        )
                    },
                },
                {
                    Header: 'Antal',
                    Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                        return (
                            <div>{original.Quantity}</div>
                        )
                    },
                },
                {
                    Header: 'Pris/dag',
                    Cell: ({ row: { original } }: { row: { original: RentalLine } }) => {
                        return (
                            <div>{asMoney(original.DayNetPriceEdge)}</div>
                        )
                    },
                },
            ]}
        />
    )
}

export default OnRentChildren