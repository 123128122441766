import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyButton = ({ content }: { content: string }) => {
    const [copied, setCopied] = useState(false)

    const copy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    }


    return (
        <div style={{ cursor: 'pointer' }}>
            {copied ? <span style={{ color: 'green' }} > Kopierat</span > : (
                <div>
                    <CopyToClipboard text={content}
                        onCopy={copy}>
                        <span><b>Kopiera: </b>{content}</span>
                    </CopyToClipboard>
                </div>
            )}
        </div>
    )
}

export default CopyButton