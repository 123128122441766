import React from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { ReadableRoles } from '../../config'
import { Mutations } from '../../queries/mutation'
import { Queries } from '../../queries/query'
import { Invitation, InvitationTypeEnum } from '../../types'
import Link from '../generic/Link'
import Error from '../generic/Error'
import SearchableTable from '../table/SearchableTable'
import { CreateNewInvitationButton } from './CreateInvitationForm'

const InvitationsList = () => {
    const { domainId } = useParams<{ domainId: string }>()
    if (!domainId) return <Error error='the domain Id cannot be null' />
    return (
        <Container>
            <SearchableTable
                domainId=''
                query={Queries.invitationsPage}
                deleteManyMutation={Mutations.removeInvitationsMany}
                filter={{
					DomainId: domainId
				}}
                cols={[
                    {
                        Header: 'Namn',
                        Cell: ({ row: { original } }: { row: { original: Invitation } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.CustomerName}</Link>
                        },
                    },
                    {
                        Header: 'E-Post',
                        accessor: 'email',
                    },
                    {
                        Header: 'Roll',
                        Cell: ({ row: { original } }: { row: { original: Invitation } }) => {
                            let role = ReadableRoles[original.Role]
                            return <span>{role || ''}</span>
                        },
                    },
                    {
                        Header: 'Typ',
                        Cell: ({ row: { original } }: { row: { original: Invitation } }) => {
                            return <span>{original.Type === InvitationTypeEnum.Company ? 'Företag' : 'Personlig'}</span>
                        },
                    },
                ]}
            >
                <CreateNewInvitationButton domainId={domainId} />
            </SearchableTable>
        </Container>
    )
}

export default InvitationsList