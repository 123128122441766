import React, { useEffect, useMemo, useState } from 'react'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router';
import { parse } from 'graphql';

const useQueryState = <S>({
    paramName,
    serialize,
    deserialize,
    defaultValue,
}: {
    paramName: string,
    serialize?: ((val: S) => string) | undefined,
    deserialize?: (val: string) => S,
    defaultValue?: S
}): [
        S,
        React.Dispatch<React.SetStateAction<S>>
    ] => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const queryParams = useMemo(() => qs.parse(search), [search]);

    const getDeserializedValue = (val) => {
        if (deserialize) return deserialize(val)
        else if (val === 'true') return true
        else if (val === 'false') return false
        else if (!isNaN(parseInt(val))) return parseInt(val)
        return val
    }

    const getSerializedValue = val => {
        if (!val) return null
        if (serialize) return serialize(val)
        else if (typeof (val) === 'boolean' && val) return 'true'
        else if (typeof (val) === 'boolean' && !val) return 'false'
        else if (!isNaN(val)) return val.toString()
        else if (typeof (val) === 'object') return JSON.stringify(val)
        else if (typeof (val) === 'string') return val

        else throw new Error(`unknown type for ${paramName}, cannot seralize ${val}, defaultValue: ${defaultValue}`)
    }

    const [stateValue, setState] = useState<S>(queryParams[paramName] ? (getDeserializedValue(queryParams[paramName]) as any) : defaultValue);


    useEffect(() => {
        const serializedValue = getSerializedValue(stateValue);
        if (queryParams[paramName] !== serializedValue) {
            const updatedQueryParams = {
                ...queryParams,
            };

            if (serializedValue !== null && typeof serializedValue !== 'undefined') {
                updatedQueryParams[paramName] = serializedValue;
            } else {
                delete updatedQueryParams[paramName];
            }

            const newURL = qs.stringifyUrl({
                url: pathname,
                query: updatedQueryParams,
            });

            history.replace(newURL);
        }
    }, [stateValue, history, paramName, pathname, queryParams])

    return [stateValue, setState];

}


export default useQueryState