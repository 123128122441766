import { useMutation } from '@apollo/client'
import React from 'react'
import { InputGroup, Dropdown } from 'react-bootstrap'
import { Mutations } from '../../queries/mutation'
import Error from '../generic/Error'
import Loading from '../generic/Loading'

const ToggleReservation = ({ objectType }) => {

    const [mutation, { loading, error }] = useMutation(Mutations.upsertObjectType, {
        context: { xDomainId: objectType.DomainlId },
    })

    if (loading) return <Loading />
    if (error) return <Error error={error} />

    const toggle = (NotAvailableForReservation) => {
        mutation({
            variables: {
                record: {
                    _id: objectType._id,
                    NotAvailableForReservation
                }
            }
        })
    }

    let label = labels[objectType.NotAvailableForReservation] || 'Bokningsbar'

    return (
        <div>


            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Bokningsbar via appen</InputGroup.Text>
                </InputGroup.Prepend>

                <Dropdown>
                    <Dropdown.Toggle variant={objectType.NotAvailableForReservation ? 'danger' : 'success'} id="dropdown-basic">
                        {label}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => toggle(true)}>{labels.true}</Dropdown.Item>
                        <Dropdown.Item onClick={() => toggle(false)}>{labels.false}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>
        </div>
    )
}

const labels = {
    undefined: 'Bokningsbar',
    true: 'Ej Bokningsbar',
    false: 'Bokningsbar',
}

export default ToggleReservation