import { useQuery } from '@apollo/client'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'
import { Queries } from '../../queries/query'
import Error from '../generic/Error'
import Loading from '../generic/Loading'
import { DomainIntegrationForm } from './DomainIntegrationForm'
import Heartbeat from './Heartbeat'


const DomainIntegrationCard = () => {
    const { integrationId, domainId } = useParams<{ integrationId: string, domainId: string }>()
    const { data, loading, error } = useQuery(Queries.domainIntegration, { 
        skip: !integrationId, 
        variables: { filter: { DomainId: domainId, GlobalId: integrationId } },
        context: { xDomainId: domainId },
    })
    if (!domainId) return <Error error='the domain Id cannot be null' />
    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!loading && !data.domainIntegration) return <Error error="Posten kunde inte hittas" type="notFound" />
    return (
        <Container>
            <div className="shadow rounded-container">
                <h2>Integration</h2>
                <Heartbeat domainId={domainId} domainIntegration={data ? data.domainIntegration : null} />
                <DomainIntegrationForm domainIntegration={data ? data.domainIntegration : null} domainId={domainId} />
            </div>

        </Container>
    )
}

export default DomainIntegrationCard