import { Container } from "react-bootstrap"
import { AggregateCustomerProjectInput, CustomerProject, FilterCustomerProjectInput, CustomerProjectsPageQueryVariables } from "../../types"
import { Queries } from '../../queries/query'
import SearchableTable from '../table/SearchableTable'
import Link from '../generic/Link'
import CheckboxToggle from "../generic/CheckboxToggle"
import { useEffect, useState } from "react"
import useQueryState from "../hooks/queryStringHooks"

const ProjectList = ({domainId}: {domainId: string}) => {
    const [onlyActive, setOnlyActive] = useQueryState<boolean>({ paramName: 'onlyActive', defaultValue: true })
    let [filter, setFilter] = useState<FilterCustomerProjectInput>()
    if (filter)
        filter.DomainId = domainId
    else
        filter = {DomainId: domainId}

    useEffect(() => {
        if (onlyActive)
            setFilter({
                RentIsActive: true,
                NOT: {
                    ProjectId: null,
                }
            })
        else setFilter({
            NOT: {
                ProjectId: null,
            }
        })
    }, [onlyActive])

    return (
        <Container>
            <SearchableTable
                domainId=""
                title={'Alla Projekt'}
                query={Queries.customerProjectsPage}
                queryVariables={{
                    aggregate: AggregateCustomerProjectInput.ProjectNo
                } as CustomerProjectsPageQueryVariables}
                filter={filter}
                cols={[
                    {
                        Header: 'Namn',
                        Cell: ({ row: { original } }: { row: { original: CustomerProject } }) => {
                            return <Link type="inline" to={`/${original.ProjectId}`}>{original.Name}</Link>
                        },
                    },
                    {
                        Header: 'Id',
                        Cell: ({ row: { original } }: { row: { original: CustomerProject } }) => {
                            return <Link type="inline" to={`/${original.ProjectId}`}>{original.ProjectId}</Link>
                        },
                    },
                    {
                        Header: 'Adress',
                        Cell: ({ row: { original } }: { row: { original: CustomerProject } }) => {
                            return <Link type="inline" to={`/${original.ProjectId}`}>{original.Address}</Link>
                        },
                    },
                ]}
            >
                <CheckboxToggle
                    value={onlyActive}
                    onChange={setOnlyActive}
                    label={'Visa endast projekt som har aktiv hyra'}
                />
            </SearchableTable>
        </Container >
    )
}

export default ProjectList