import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { getEnv } from "../config";

const env = getEnv()

const tenant = "navetedge.onmicrosoft.com";
const signInPolicy = "B2C_1A_SignUpSignInNavetEdge";
const applicationID = env === 'production' ? 'abb258ff-e828-4fbe-86d4-ddd2edb0ea1d' : "c4d0d69b-f111-415e-be43-4e38ca26bd27";
const reactRedirectUri = window.location.origin;
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;

export const authenticationParameters = {
    scopes: [
        'https://navetedge.onmicrosoft.com/navetedge/user_impersonation',
    ],
    extraQueryParameters: {
        ui_locales: 'sv'
    }
}

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
};



export const getMsal = () => {
    switch (getEnv()) {
        case 'production':
            return {
                auth: {
                    authority: 'https://login.microsoftonline.com/tfp/navetedge.onmicrosoft.com/B2C_1A_SignUpSignInNavetEdge',
                    clientId: 'abb258ff-e828-4fbe-86d4-ddd2edb0ea1d',
                    redirectUri: 'https://edge.navet.se/',
                    persistLoginPastSession: true
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: true
                },
            }

        case 'test':
            return {
                auth: {
                    authority: 'https://login.microsoftonline.com/tfp/navetedge.onmicrosoft.com/B2C_1A_SignUpSignInNavetEdge',
                    clientId: 'c4d0d69b-f111-415e-be43-4e38ca26bd27',
                    redirectUri: 'https://rentalainen-portal-test.azurewebsites.net/',
                    persistLoginPastSession: true
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: true
                },
            }

        default:
            return {
                auth: {
                    authority: 'https://login.microsoftonline.com/tfp/navetedge.onmicrosoft.com/B2C_1A_SignUpSignInNavetEdge',
                    clientId: 'c4d0d69b-f111-415e-be43-4e38ca26bd27',
                    redirectUri: 'http://localhost:3000',
                    persistLoginPastSession: true
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: true
                },
            }
    }
}

export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: signInAuthority,
            clientId: applicationID,
            redirectUri: reactRedirectUri,
            validateAuthority: false
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    },
    authenticationParameters,
    options
);
