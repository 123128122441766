
import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Queries } from '../../queries/query'
import {
    CreateDomainInvitationMutation,
    RemoveInvitationMutation,
    RemoveInvitationMutationVariables,
    UpdateDomainInvitationAppLinksMutationVariables,
    ClearInvitationCodesMutation, ClearInvitationCodesMutationVariables,
    CreateDomainInvitationMutationVariables, Domain, Invitation, InvitationQuery, InvitationQueryVariables, InvitationTypeEnum, RemoveInvitationsManyMutation, UpdateDomainInvitationAppLinksMutation
} from '../../types'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import { colors } from '../generic/colors';
import { cfg } from '../../config';
import Loading from '../generic/Loading';
import Error from '../generic/Error'
import { Mutations } from '../../queries/mutation';
import Form from '../Form';
import UploadIOSAppCodeFile from './UploadIOSAppCodeFile';
import { Fragments } from '../../queries/fragments';

const DomainInvitationLink = ({ domain }: { domain: Domain }) => {
    console.log('DomainInvitationLink')
    const { data, loading, error } = useQuery<InvitationQuery, InvitationQueryVariables>(Queries.invitation, {
        variables: {
            filter: {
                Type: InvitationTypeEnum.Domain,
                DomainId: domain.GlobalId,
                Deactivated: false
            }
        },
        context: { xDomainId: domain.GlobalId },
    })
    const [mutation, mutationstate] = useMutation<CreateDomainInvitationMutation, CreateDomainInvitationMutationVariables>(Mutations.createDomainInvitation, { 
        refetchQueries: ['invitation'],
        context: { xDomainId: domain.GlobalId },
    })

    const [link, setLink] = useState<string>()
    const [invitation, setInvitation] = useState<Invitation>(null)
    const [copied, setCopied] = useState<boolean>(false)

    useEffect(() => {
        if (!data || !data.invitation) {
            setLink(null)
            setInvitation(null)
        }
        else {
            setLink(`${cfg.linking}accept-domain-invitation/${data.invitation.InvitationCode}`)
            setInvitation(data.invitation)
        }
    }, [data])

    const createLink = async () => {
        try {
            let response = await mutation({
                variables: {
                    record: {
                        DomainId: domain.GlobalId,
                        DomainName: domain.Name,
                    }
                }
            })


            if (response.data && response.data.createDomainInvitation && response.data.createDomainInvitation.InvitationCode)
                setLink(`${cfg.linking}accept-domain-invitation/${response.data.createDomainInvitation.InvitationCode}`)

        } catch (e) {
            console.log('error', e)
        }
    }

    const copy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    }

    if (error) return <Error error={error} />

    return (
        <div className="rounded-container shadow">
            {loading && <Loading />}
            {mutationstate.error && <Error error={mutationstate.error} type={'mutation'} />}
            {!link && <button disabled={mutationstate.loading} onClick={createLink} className="rounded-button blue">{mutationstate.loading ? <Loading button /> : 'Skapa inbjudningslänk'}</button>}
            {link && (
                <div>
                    <pre>
                        <code>
                            {JSON.stringify({
                                Type: InvitationTypeEnum.Domain,
                                DomainId: domain.GlobalId,
                                Deactivated: false
                            }, null, 2)}
                        </code>
                    </pre>
                    <p>
                        <b>Inbjudningslänk:</b>
                        {' '}
                        <CopyToClipboard text={link}
                            onCopy={copy}>
                            <MdContentCopy style={{ cursor: 'pointer' }} size={20} color={colors.primary.main.color} />
                        </CopyToClipboard>
                        <br />
                        {copied ? <span style={{ color: 'green' }} > Kopierat</span > : <span>{link}</span>}

                    </p>

                    <UploadIOSAppCodeFile invitation={invitation} />
                    <div className="remove-buttons">
                        <RemoveButton invitation={invitation} />
                        <ClearCodes invitation={invitation} />
                    </div>
                    <SetAppLinks invitation={invitation} />
                </div>
            )}
        </div>
    )
}

const ClearCodes = ({ invitation }: { invitation: Invitation }) => {
    const [mutation, { loading, error }] = useMutation<ClearInvitationCodesMutation, ClearInvitationCodesMutationVariables>(Mutations.clearInvitationCodes)

    const clear = async () => {
        mutation({
            variables: { code: invitation.InvitationCode },
            update: (cache) => {
                const id = cache.identify(invitation)

                cache.modify({
                    id,
                    fields: {
                        IOSAppCodeCount(value: number) {
                            return 0
                        }
                    }
                })

            }
        })
    }

    return (
        <div>
            <button onClick={clear} disabled={loading} className="rounded-button red">{loading && <Loading button />} <span>Rensa alla ios appkoder</span></button>
        </div>
    )
}

const RemoveButton = ({ invitation }: { invitation: Invitation }) => {

    const [mutation, { loading, error }] = useMutation<RemoveInvitationMutation, RemoveInvitationMutationVariables>(Mutations.removeInvitation, {
        refetchQueries: ['invitation']
    })

    const remove = () => {
        try {
            mutation({ variables: { filter: { GlobalId: invitation.GlobalId } } })
        } catch (e) { }
    }

    if (!invitation) return null
    if (error) return <Error error={error} type='mutation' />
    return (
        <button disabled={loading} onClick={remove} className="rounded-button red">{loading ? <Loading button /> : 'Ta bort länk'}</button>
    )
}


const SetAppLinks = ({ invitation }: { invitation: Invitation }) => {

    const [mutation, { loading, error }] = useMutation<UpdateDomainInvitationAppLinksMutation, UpdateDomainInvitationAppLinksMutationVariables>(Mutations.updateDomainInvitationAppLinks)

    if (!invitation) return null


    return (
        <div>

            <Form
                onSubmit={async (record: Invitation) => {
                    await mutation({
                        variables: {
                            record: {
                                ...record,
                                GlobalId: invitation.GlobalId,
                                IOSAppLink: record.IOSAppLink,
                                AndroidAppLink: record.AndroidAppLink,

                            }
                        }
                    }).catch(e => { })
                }}
                values={invitation}
                fields={[
                    { name: 'IOSAppLink', label: "Länk för att ladda ner iOS-app" },
                    { name: 'AndroidAppLink', label: "Länk för att ladda ner android-app" },

                ]}
            />

        </div>
    )
}
export default DomainInvitationLink