import React from 'react'
import { useParams } from 'react-router'

import { Container } from 'react-bootstrap'
import { Queries } from '../../queries/query'
import { CustomerDomain } from '../../types'
import SearchableTable from '../table/SearchableTable'
import Link from '../generic/Link'
import Error from '../generic/Error'
import { Mutations } from '../../queries/mutation'
import { CreateNewCustomerDomainButton } from './CustomerDomainForm'

const CustomerDomainList = () => {
    const { domainId } = useParams<{ domainId: string }>()

    if (!domainId) return <Error error='the domain Id cannot be null' />

    return (
        <Container>
            <h1>Kunddomäner</h1>
			<h3>Domän-Id: {domainId}</h3>
            <SearchableTable
                domainId={domainId}
                deleteManyMutation={Mutations.removeCustomerDomainMany}
                query={Queries.customerDomainsPage}
                filter={{
					DomainId: domainId
				}}
                cols={[
                    {
                        Header: 'Namn',
                        Cell: ({ row: { original } }: { row: { original: CustomerDomain } }) => {
                            return <Link type="inline" to={`/${original.GlobalId}`}>{original.Name}</Link>
                        },
                    },
                ]}
            >
                <CreateNewCustomerDomainButton domainId={domainId} />
            </SearchableTable>
        </Container>
    )
}


export default CustomerDomainList